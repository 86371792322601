<template>
    <div class="pt-3" v-if="selectedChart=='BAR'||selectedChart=='LINE'||selectedChart=='PIE' || selectedChart=='FUNNEL'">
        <div>{{$t('chart.figure')}}</div>
        <v-btn-toggle 
            class="d-flex"
            color="primary"
            @change="$emit('onCommandHandler',{
                command: 'update_chartFigure',
                value: $event
            })" 
            v-model="chartFigure" 
            mandatory
        >
            <v-btn class="flex-grow-1" small v-if="selectedChart=='BAR' || selectedChart=='FUNNEL'" value="vertical">
                {{$t('chart.vertical')}}
            </v-btn>
            <v-btn class="flex-grow-1" small v-if="selectedChart=='BAR' || selectedChart=='FUNNEL'" value="horizontal">
                {{$t('chart.horizontal')}}
            </v-btn>
            <v-btn class="flex-grow-1" small v-if="selectedChart=='LINE'" value="broken">
                {{$t('chart.broken')}}
            </v-btn>
            <v-btn class="flex-grow-1" small v-if="selectedChart=='LINE'" value="curve">
                {{$t('chart.curve')}}
            </v-btn>
            <v-btn class="flex-grow-1" small v-if="selectedChart=='LINE'" value="area">
                {{$t('chart.area')}}    
            </v-btn>
            <v-btn class="flex-grow-1" small v-if="selectedChart=='PIE'" value="pie">
                {{$t('chart.pie')}}
            </v-btn>
            <v-btn class="flex-grow-1" small v-if="selectedChart=='PIE'" value="doughnut">
                {{$t('chart.doughnut')}}
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
    export default {
        props:{
            selectedChart: String,
            chartFigure: String
        },
    }
</script>