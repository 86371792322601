<template>
    <v-radio-group
        class="pt-0 mt-0 displayRule-radio"
        v-model="value"
        mandatory
        dense
        :column="false"
    >        
        <v-radio
            v-for="(item, index) in rules.buttons"
            :key="index"
            :ripple="false"
            :label="$t(item.labelTag)"
            :value="item.value"
            :class="(index==rules.buttons.length-1)?'ml-10':''"
        >
        </v-radio>
    </v-radio-group>
</template>

<script>
import mixin from './mixin'
export default { 
    mixins: [mixin],
    mounted(){
        if (this.rules && this.rules.options && this.rules.options.length) {
            this.rule = this.rules.options[0].value;
            this.$emit('updateRule', this.rules.options[0].value);
        }
    },
    // watch: {
    //     rules: function(v) {
    //         console.log('>>>');
    //         if (v && v.options && v.options.length) {
    //             this.rule = v.options[0].value;
    //         }
    //     }
    // },
}
</script>

<style>
.displayRule-radio .v-input--selection-controls__input{
    margin-right: 0px !important
}
/* .v-chip.v-size--default */
.rule-selection-opts .v-chip {
    height: unset !important;
}
</style>