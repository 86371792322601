<template>
<v-dialog
  class="form-selection-menu"
  width="300"

  :absolute="false"
  v-model="showingMenu">
  <v-card class="form-selection-content">
    <v-card-title class="primary white--text">
      <span>{{ $t('calendar.newEvent') }}</span>
    </v-card-title>
    <v-card-text class="pa-0 ma-0">
      <v-list class="menu-item-selection" nav ripple flat>
        <v-list-item-group v-model="selectedIndex">
          <v-list-item
            v-for="menuItem in menu"
            @click="onItemClicked(menuItem)"
            :key="menuItem.value">
            <v-list-item-title v-text="menuItem.label">xx</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: 'formSelectionDialog',
  data () {
    return {
      selectedIndex: -1
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: String,
    menu: Array,
    color: String
  },
  computed: {
    showingMenu: {
      get() {
        return this.value
      },
      set(val) {
        console.log('computed(showingMenu): val: ', val)
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onItemClicked (menuItem) {
      const vm = this
      vm.$emit('click', menuItem.value)
      vm.$nextTick(() => {
        vm.selectedIndex = -1
      })
    }
  }

}
</script>

<style>
.menu-item-selection .v-list-item {
  min-height: 32px;
}
.menu-item-selection .v-list-item:hover {
  background-color: lightgrey;
}
.form-selection-content .v-card__title {
  padding: 10px 18px 4px !important;
}
</style>