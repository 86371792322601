<template>
  <FormElement :name="name" :label="label" :hints="hints">
    <v-layout align-center justify-space-between>
      <ComboBox
        v-bind="boxProps"
        :value="value || null"
        :options="normalizer(subWorkflows)"
        :onlyPrefix="false"
        :onlySuffix="false"
        :isMultiple="false"
        :placeholder="placeholder"
        valueFormat="object"
        :disableBranchNode="false"
        @changed="changed"
        :isBranch="true"
      />
      <v-btn
        color="primary"
        text
        @click="goWorkflow"
        :disabled="!value"
      >
        {{$t('workflow.modules.subWorkflow.label.viewSubWorkflow')}}
      </v-btn>
    </v-layout>
  </FormElement>
</template>

<script>
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';

import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
// import Mixin from './../mixin';
import FormElement from './formElement';
import {
  FETCH_SUBWORKFLOW,
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
import { map, omit } from 'lodash'
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  // mixins: [Mixin],
  props: {
    value: Object,
    placeholder: String,
    label: String,
    workflow: Object,
    appId: String,
    subProcessType: {
      type: String,
      default: 'childProcess'
    },
    name: String,
    fetchForRelationship: Boolean,
    boxProps: Object,
    hints: String,
  },
  mounted() {
    let payload = {
      appId: this.appId,
      type: this.subProcessType,
    };
    if (this.workflow) {
      payload = {
        ...payload,
        workflow: this.workflow._id,
      }
    }
    this.$store.dispatch(FETCH_SUBWORKFLOW, payload);
  },
  computed: {
    subWorkflows() {
      // console.log(this.$store.getters.getSubWorkflows || []);
      return this.$store.getters.getSubWorkflows || [];
    },
  },
  components: {
    ComboBox,
    FormElement,
  },
  methods: {
    goWorkflow() {
      WorkflowSettingUtils.goBlankWindowWorkflow(this.$router, this.value._id);
    },
    changed(subWorkflow) {
      this.$store.dispatch(SET_SETTINGS, {
        subWorkflow: subWorkflow || null,
      });
      this.$emit('changed', subWorkflow || null);
    },
    normalizer(list) {
      return map(list, l => omit(l, ['children']));
    },
  },
};

</script>

<style scoped>
</style>
