<template>
  <div>
    <label>{{ label }}</label>
    <v-select
      :items="items"
      dense
      outlined
      solo
      :placeholder="$t('messages.pleaseSelectTable')"
      :hide-details="true"
      v-model="propertyValue"
      :menu-props="{ offsetY: true, contentClass: 'denseList' }"
    ></v-select>
  </div>
</template>

<script>
import mixin from "./mixin";

export default {
  name: "witTableFieldSelection",
  mixins: [mixin],
  computed: {
    relatedTable() {
      return this.get;
    },
    items() {
      return this.formFieldInfos
        .filter(
          (info) =>
            info.type === "childTable" ||
            (info.type === "relatedRecord" &&
              info.properties.relatedRecordQuantity === "multiple")
        )
        .map((info) => ({
          text: info.label,
          value: info._id,
        }));
    },
  },
};
</script>
