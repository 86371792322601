<template>
  <form-title
    v-bind="attrs"
    @onCommand="(params) => $emit('onCommand', params)"
  >
    <template v-slot:append-title>
      <v-btn
        :ripple="false"
        color="info"
        depressed
        @click="$emit('onCommand', { command: 'addEmployee' })"
      >
        <v-icon left size="18" class="mr-1">mdi-plus</v-icon>
        <span style="font-size: 16px">{{ $t("workAdmin.newEmployee") }}</span>
      </v-btn>
    </template>
  </form-title>
</template>
<script>
import FormTitle from "@/components/FormTitle";
export default {
  props: {
    attrs: Object,
  },
  components: {
    FormTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.to-work-button {
  background-color: #f29e38;
}
</style>
