var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('label',[_vm._v(_vm._s(_vm.label))])]),(_vm.expressionType === 'date')?_c('v-row',{staticClass:" mt-1 formulaGuide",attrs:{"no-gutters":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("messages.dateGuide")))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"unitTooltip"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t("messages.unit")))])]}}],null,false,1099851948)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('messages.unitMap'))}})])],1):(_vm.expressionType === 'number')?_c('v-row',{staticClass:" mt-1 formulaGuide",attrs:{"no-gutters":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("messages.numGuide")))]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"unitTooltip"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t("messages.addFormula"))+" ")])]}}])},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_vm._l((_vm.formulaOptions),function(formula,index){return [_c('v-list-item',{key:index,staticClass:"white",staticStyle:{"min-height":"30px"},attrs:{"active-class":"white","dense":"","ripple":false},on:{"click":function($event){return _vm.addFormula(formula)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-content',[_c('div',[_vm._v(_vm._s(formula))])])]}}],null,true)}),(index < _vm.formFieldInfos.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)],1)],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('codemirror',{ref:"cmEditor",staticClass:"CodeMirror",staticStyle:{"border":"1px solid grey","height":"auto"},attrs:{"options":_vm.cmOptions},on:{"ready":_vm.onCmReady},model:{value:(_vm.propertyValue),callback:function ($$v) {_vm.propertyValue=$$v},expression:"propertyValue"}})],1)]}}])},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":"","max-height":"350px"}},[_vm._l((_vm.filteredFields),function(field,index){return [_c('v-list-item',{key:field.fieldId,staticClass:"white",staticStyle:{"min-height":"30px"},attrs:{"active-class":"white","dense":"","ripple":false},on:{"click":function($event){return _vm.handleListItemClick(field)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-content',[_c('div',[_vm._v(_vm._s(field.properties.fieldName))])])]}}],null,true)}),(index < _vm.formFieldInfos.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }