<template>
  <div style="margin-bottom: 12px;">
    <label>{{ label }}</label>
    <input-checkbox-toggle
      :dense="true"
      :options="propertyConfig.options"
      :attributes="propertyConfig.attributes"
      v-model="propertyValue"
      :propertyKey="propertyKey"
    ></input-checkbox-toggle>
  </div>
</template>

<script>
import inputCheckboxToggle from '../inputFields/InputCheckboxToggle'
import mixin from './mixin'

export default {
  mixins: [mixin],
  components: {inputCheckboxToggle}
}
</script>
