import {
  UPDATE_WORKFLOW_NODE,
} from '@/store/modules/workflow/action_types';

const mixin = {
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    uiBlocked() {
      return this.$store.getters.isUIBlocked;
    },
    workflow() {
      return this.$store.getters.getWorkflowInfo;
    },
    appId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async onEdited(name) {
      this.editing = false;
      if (!!name) {
        await this.$store.dispatch(UPDATE_WORKFLOW_NODE, {
          info: { name },
          node: this.node,
          workflow: this.workflow._id,
          workflowInfo: this.workflow,
          appId: this.appId,
        })
      }
    },
    onEdit(inline) {
      this.editing = !this.uiBlocked || inline;
    }
  },
}

export default mixin
