<template>
  <div>
    <PendingSetup
      v-if="!parentWorkflow || !parentWorkflowNode || !worksheet || !dataSource"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <TableSourceView :node="node" />
      <div class="w-e-statement">
        <v-layout align-center justify-space-between>
          <TriggerStatement :node="node" />
          <v-btn
            color="primary"
            text
            @click="goWorkflow"
          >
            {{$t('workflow.modules.subWorkflow.label.viewParentWorkflow')}}
          </v-btn>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import PendingSetup from './../pending';
import TriggerStatement from './trigger';
import Mixin from './../mixin';
import TableSourceView from './../tableSource';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  computed: {
    dataSource() {
      const { properties } = this.node;
      return get(properties, 'dataSource', '');
    },
    worksheet() {
      const { properties } = this.node;
      return get(properties, 'worksheet', '');
    },
    parentWorkflow() {
      const { properties } = this.node;
      return get(properties, 'parentWorkflow', '');
    },
    parentWorkflowNode() {
      const { properties } = this.node;
      return get(properties, 'parentWorkflowNode', '');
    },
    // dataSize() {
    //   return keys(get(this.node, 'properties.data', {})).length;
    // }
  },
  methods: {
    goWorkflow() {
      WorkflowSettingUtils.goBlankWindowWorkflow(this.$router, this.parentWorkflow._id);
    },
  },
  components: {
    PendingSetup,
    TableSourceView,
    TriggerStatement,
  },
};

</script>

<style scoped>
  .w-e-statement {
    font-size: 11px !important;
    margin: 5px 2px 0 2px;
  }
</style>
