<template>
  <div>
    <label>{{ label }}</label>
    <v-text-field
      :value="displayValue"
      append-outer-icon="mdi-tools"
      dense
      readonly
      :placeholder="placeholder"
      @click:append-outer="openDialog"
      hide-details="auto"
      outlined
    ></v-text-field>
    <multiple-tab-dialog
      ref="multipleTabDialog"
      @confirm="confirmHandler"
    ></multiple-tab-dialog>
  </div>
</template>

<script>
//******************************
// WitTableSelectionSetup
//******************************
// (defined in ~/helpers/FormHelpers.js)
//
// propertyValue =
/* {
      tableConfigs: {
        fixedFieldIds: [],
        nonFixedFieldIds: []
      },
      filterConfigs: {
        filters: [],
        filter
      },
      sortingConfigs: [
        {fieldId: {fieldId1}, order: 'asc'},
        {fieldId: {fieldId2}, order: 'asc'},
        {fieldId: {fieldId3}, order: 'asc'},          
      ]
    }
*/
import mixin from "./mixin";
import relatedRecordInfoMixin from "@/mixins/relatedRecordInfoMixin";
import multipleTabDialog from "@/components/dialogs/MultipleTabDialog";
import TABS from "@/components/dialogs/tabs/constants.js";
import { getCardConfigString } from "@/helpers/CardHelpers";

export default {
  name: 'witRelatedSelectionSetup',
  mixins: [mixin, relatedRecordInfoMixin],
  components: {
    multipleTabDialog
    // relatedRecordSelectionSetupDialog
  },
  watch: {
    displayFormat: function(newVal) {
      if (newVal === "table") {
        this.initPropertyValue();
      }
    },

    relatedTitleFieldId: function(newVal) {
      this.initPropertyValue();
      // vm.propertyValue.selectionTable.fixedFieldIds = [newVal]
      // vm.propertyValue.cardSelectionList.fixedFieldIds = [newVal]
    }
  },
  data () {
    return {
      BLANK_FILTER_CONFIGS: {
        filters: [],
        filterAndOr: 'and'
      }
    }
  },
  computed: {


    selectedFieldIds() {
      const vm = this;
      var result = [];
      if (vm.propertyValue) {
        if (vm.propertyValue.tableConfigs) {
          result = [
            ...vm.propertyValue.tableConfigs.fixedFieldIds,
            ...vm.propertyValue.tableConfigs.nonFixedFieldIds
          ];
        }
      }
      return result;
    },


    placeholder() {
      return this.$t("messages.pleaseSelectFields");
    },

    displayValue() {
      const vm = this;
      if (vm.selectionMode === "table") {
        return vm.displayValueForTable;
      } else {
        return vm.displayValueForCard;
      }
    },

    displayValueForTable() {
      const vm = this;
      var result = [];
      if (vm.selectedFieldIds.length > 0) {
        result.push(
          vm.$t("general.duringSelection") +
            vm.$t("general.display") +
            " " +
            vm.selectedFieldIds.length +
            " " +
            vm.$t("general.field")
        );
      }
      return result.join(", ");
    },

    displayValueForCard() {
      const cardConfigs = this.propertyValue.cardConfigs;
      return cardConfigs ? getCardConfigString(cardConfigs) : ''
    },

    displayFormat() {
      // [card|singleField], default:card
      return this.getPropertyValue("displayFormat", "card");
    },

    defaultCardValue () {
      const vm = this
      return {
        cardConfigs: {
          cardSummaryFieldId: '',
          cardImageFieldId: vm.relatedFirstAttachmentFieldId,
          cardImagePosition: 'left',
          cardImageMode: 'cover',
          cardImageStretch: true,
          cardImageSize: '48',
          cardImagePadding: 0,
          cardDisplayFieldName: false,
          cardDisplayFieldIds: []
        },
        filterConfigs: vm.BLANK_FILTER_CONFIGS,
        sortingConfigs: []
      }
    },

    defaultTableValue () {
      const vm = this
      var nonFixedFieldIds = []
      if (vm.relatedFirstAttachmentFieldId !== '') {
        nonFixedFieldIds.push(vm.relatedFirstAttachmentFieldId)
      }
      return {
        tableConfigs: {
          fixedFieldIds: [vm.relatedTitleFieldId],
          nonFixedFieldIds: nonFixedFieldIds
        },
        filterConfigs: vm.BLANK_FILTER_CONFIGS,
        sortingConfigs: []
      }
    }

  },
  mounted() {
    this.initPropertyValue();
  },
  methods: {


    initPropertyValue() {
      const vm = this;
      console.log("initPropertyValue: selectionMode = " + vm.selectionMode);
      if (!vm.propertyValue) {
        vm.propertyValue = vm.selectionMode === "table" ?
          vm.defaultTableValue :
          vm.defaultCardValue

      }
    },
    openDialog() {
      const vm = this;
      if (vm.dataSource) {
        var tabList =
          vm.selectionMode === "table"
            ? [
                TABS.TABLE_CONFIG, // tableConfig: fixedFieldIds, nonFixedFieldIds
                TABS.FILTER_CONFIGS, // filterConfigs: {filters: [], filterAndOr: 'and'}
                TABS.SORTING_CONFIGS, // sortingConfigs: [{field1: {field1}, order:
                TABS.LIST_QUICK_FILTER
              ]
            : [
                TABS.CARD_CONFIG,
                TABS.CARD_SELECTION_FILTERS,
                TABS.CARD_SELECTION_SORTING,
                TABS.CARD_QUICK_FILTER
              ];
        this.$refs.multipleTabDialog.open(
          vm.propertyValue,
          vm.dataSource,
          tabList,
          this.dataSourceInfo

          /* control display of different tabs in dialog */
          /* For single related record: for selection display only */
          /* For multiple related record: for display in form */

          /*
                    tabCardDisplayFields
                    tabCustomButtons
                    tabFilters
                    tabSorting
                    tabTableDisplayWithFreezing
                    tabTableDisplayWithoutFreezing
                     */
        );
      } else {
        vm.$alert(
          vm.$t("messages.pleaseSpecifyDataSourceFirst"),
          vm.$t("general.warning"),
          "warning",
          {
            confirmButtonText: vm.$t("buttons.ok"),
            cancelButtonText: vm.$t("buttons.cancel")
          }
        );
      }
    },

    confirmHandler(newPropertyValue) {
      this.propertyValue = newPropertyValue;
    }
  }
};
</script>
