<template>
  <div>
    <TableSourceView :node="node" dataSourceMode />
  </div>
</template>

<script>
import { get } from 'lodash';
import Mixin from './../mixin';
import TableSourceView from './../tableSource.vue';
import SettingUtils from '@/pages/admin/workflow/utils/setting';

/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
  },
  components: {
    TableSourceView,
  },
  computed: {
    expression() {
      const { properties } = this.node;
      return get(properties, 'expression') || '';
    },
    propertiesFields() {
      return get(this.node, 'propertiesFields') || [];
    },
    // propertiesParemeters() {
    //   return get(this.node, 'propertiesParemeters') || [];
    // },
    parameters() {
      return this.$store.getters.getWorkflowParameters;
    },
    expressionDisplay() {
      const { propertiesFields=[] } = this;
      return SettingUtils.normalizeFormulaExpression({
        fields: [...propertiesFields, ...this.parameters],
        node: this.node,
        expression: this.expression,
        locale: this.$t('workflow')
      });
    }
  },
};

</script>

<style scoped>
  
</style>
