<template>
  <div>
    <div
      v-for="ic in icons"
      :key="ic" 
      @click="changed(ic, icon === ic)"
      class="w-customized-button-bubble">
      <v-icon v-if="icon === ic" :color="color" v-text="`mdi-${ic}`" />
      <v-icon v-else color="#9e9e9e" v-text="`mdi-${ic}`" />
    </div>
  </div>
</template>

<script>
import {
  WorkflowButtonIcons,
} from '@/pages/admin/workflow/constants/form';
import {
  UPDATE_ACTION_BUTTON_INFO,
} from '@/store/modules/customizedAction/action_types';
export default {
  props: {
    // item: {
    //   type: Object,
    //   default: {},
    // },
    color: String,
    icon: String,
  }, 
  computed: {
    icons() {
      return WorkflowButtonIcons;
    },
  },
  methods: {
    changed(content, unCheck) {
      this.$store.dispatch(UPDATE_ACTION_BUTTON_INFO, {
        field: 'icon',
        content: unCheck ? null : content,
      });
    }
  },
}
</script>

<style scoped>
  .w-customized-button-bubble {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin-right: 14px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
    transition: all .4s ease;
  }
  .w-customized-button-bubble:hover {
    transform: scale(1.22);
  }
</style>