<template>
<v-dialog
    v-model="showingDialog"
    v-if="showingDialog"
    persistent
    width="600"
    max-width="90%">
    <v-card>
        <dialog-header
            :label="$t('general.tableDisplaySetup')"
            @close="showingDialog=false"></dialog-header>
        <v-card-text style="height:540px;"
            class="card-display-setup-content d-flex flex-column fill-height pt-2">
            <field-selection-panes
                :sourceListTitle="$t('view.availableFields')"
                :targetListTitle="$t('view.tableDisplayedColumns')"

                :sourceButton="true"
                :targetButton="true"

                :fixedListEditable="true"

                :fixedListTitle="$t('view.fixedFields')"
                :nonFixedListTitle="$t('view.nonFixedFields')"

                nonFixedPlaceHolder=""
                :fieldInfos="fieldInfos"
                v-model="selectedDualList">
            </field-selection-panes>
            </v-card-text>
        <v-divider>
        </v-divider>
        <dialog-actions @onCommand="onCommandHandler"></dialog-actions>
    </v-card>
</v-dialog>
</template>

<script>
import dialogHeader from './comps/DialogHeader'
import dialogActions from './comps/DialogActions'
import fieldSelectionPanes from '@/components/dialogs/tabs/comps/FieldSelectionPanes'

export default {
    name: 'tableDisplaySetupDialog',
    components: {
        dialogHeader,
        dialogActions,
        fieldSelectionPanes
    },
    data () {
        return {
            showingDialog: false,
            selectedDualList: {
                fixedFieldIds: [],
                nonFixedFieldIds: []              
            },
            callback: null,
            fieldInfos: []
        }
    },
    methods: {
        open( payload, callback) {
            const vm = this
            vm.selectedDualList = JSON.parse(JSON.stringify(payload.tableConfig))
            vm.fieldInfos = payload.fieldInfos
            vm.titleFieldInfoId = payload.titleFieldInfoId
            vm.callback = callback
            vm.showingDialog = true
        },
        onCommandHandler (payload) {
            const vm = this
            switch (payload.command) {
                case 'ok':
                    if(typeof vm.callback === 'function') {
                        vm.callback(vm.selectedDualList)                        
                    }
                    vm.showingDialog = false
                break
                default:
                    vm.showingDialog = false
            }
        }
    }
}
</script>

<style>
.card-display-setup-content .row:nth-child(2n) {
    background-color: #e2f7ef;
}
</style>