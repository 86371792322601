import { appTemplates as types } from "@/store/action-types";

export default {
  [types.FETCH_APP_TEMPLATE_CATEGORIES]({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: "/app_template_categories"
      };
      dispatch("AUTH_GET", getData).then(
        response => {
          resolve(response.result)
        },
        (error) => {
          reject(error);
        }
      )
    })
  },

  [types.INSTALL_TEMPLATE] ({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/app_templates/install',
        data: payload
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }

      )
    })
  },

  [types.FETCH_APP_TEMPLATES]({rootGetters, getters, commit, dispatch}) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: "/app_templates",
        userId: rootGetters.user._id
      };
      dispatch("AUTH_GET", getData).then(
        response => {
          resolve(response.result)
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
}
