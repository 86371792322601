<template>
  <!-- margin cannot be zero, or drop-line cannot appear -->
  <drop-list
      :items="items"
      class="row align-start drop-list-row my-1"
      style="position:relative;"
      @insert="onInsertToRow"
      @reorder="onReorderItem"
      :accepts-type="['item','widget']"
      :accepts-data="d=>acceptData(d)"
      mode="cut"
      :row="true">
    <template v-slot:item="{item}">
      <!--<drag-->
      <!--:form="form"-->
      <!--:widget="item"-->
      <!--:data="item"-->
      <!--type="item"-->
      <!--class="col drag-item py-0"-->
      <!--:class="{'col-3':!changed, 'col-4':changed}"-->
      <!--:key="itemKey(item)"-->
      <!--@cut="removeItem(item)">-->
      <drag

          :data="item"
          type="item"
          style="padding:3px!important;"
          class="col drag-item" :class="'col-'+itemWidth(item)" :key="itemKey(item)"
          @cut="removeItem(item)">
        <!-- $parent.el.getBoundingClientRect().left -->
        <layout-widget @onCommand="onCommandHandler"
                      :ref="item._id"
                       :selectedItem="selectedItem"
                       :widgetItem="item"
                       :idWidthArray="idWidthArray"
                       :rowOccupiedWidth="totalOccupiedWidth"
                       :itemIndex="findItemIndex(item)"
                       :rowIndex="rowIndex"
                       :form="form"></layout-widget>
        <!--<template v-slot:drag-image>-->
        <!--<widget-type-item-->
        <!--:widget="getWidgetByLayoutField(item)"></widget-type-item>-->
        <!--&lt;!&ndash;<div class="drag-image">DRAG</div>&ndash;&gt;-->
        <!--</template>-->
      </drag>
    </template>
    <!--<template v-slot:reordering-feedback="{item}">-->
    <!--<div class="reordering-feedback" key="feedback">reordering feedback</div>-->
    <!--</template>-->
    <template v-slot:feedback="{item}">
      <!--<layout-widget :widgetItem="item" key="feedback"></layout-widget>-->
      <div class="feedback" key="feedback"></div>
    </template>
    <!--<div style="position:absolute;left:465px;top:0;z-index:9999;background-color:blue;">sldfkds</div>-->
  </drop-list>
  <!--<div class="spliiter-list">-->
  <!--<div v-for="(splitter, index) in splitters"-->
  <!--class="blue darkened-4"-->
  <!--:key="index"-->
  <!--sytle="position:relative;top:-100px;"-->
  <!--:style="{'left', splitter}">-->
  <!--XX-->
  <!--</div>-->
  <!--</div>-->
</template>

<script>
import { Drag, Drop, DropList } from 'vue-easy-dnd'

import layoutWidget from './LayoutWidget'
import helpersMixin from '@/mixins/helpersMixin'
import adminFormMixin from '@/mixins/adminFormMixin'
import widgetHelperMixin from '@/mixins/widgetHelperMixin'



export default {
  // data () {
  //   return {
  //     changed: false
  //   }
  // },
  mixins: [
    helpersMixin,
    adminFormMixin,
    widgetHelperMixin
  ],
  name: 'FormLayoutRow',
  components: {
    drag: Drag,
    drop: Drop,
    dropList: DropList,
    layoutWidget,

  },
  props: {
    rowIndex: Number,
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectedItem: {
      type: Object,
      default: null
    },
    colBreakPoints: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    idWidthArray () {
      const vm = this
      var result = []
      vm.items.some(item => {
        const fieldInfo = vm.getFieldInfo(item._id);
        // console.log('FormLayoutRow::computed(idWidthArray): fieldInfo: ', fieldInfo)
        if (fieldInfo.properties && fieldInfo.properties.hasOwnProperty('width')) {
          result.push({
            _id: fieldInfo._id,
            width: parseInt(fieldInfo.properties.width)
          })
        }
      })
      return result
    },
    totalOccupiedWidth () {
      return this.idWidthArray.reduce( (total, item) => {
        return total + item.width
      }, 0)
    },
    totalOccupiedWidthx () {
      const vm = this
      var result = 0

      vm.items.some((item) => {
        const fieldInfo = vm.getFieldInfo(item._id);
        if (!(fieldInfo.properties && fieldInfo.properties.hasOwnProperty('width'))){
          result = 12;
          return true;
        } else {
          result += parseInt(fieldInfo.properties.width)
        }
      });
      return result

    }
  },
  methods: {
    findItemIndex (item) {
      const vm = this
      var result = -1
      if (vm.items && item) {
        result = vm.items.findIndex(loopItem => {
          return loopItem._id === item._id
        })
      }
      return result
    },
    onCommandHandler (payload) {
      // console.log('FormLayoutRow.vue :: onCommandHandler :: payload: ', payload)
      this.$emit('onCommand', payload)
    },
    acceptData (data) {
      const vm = this
      // console.log('acceptData: items: ', vm.items)
      // console.log('acceptData: data: ', data)

      var result = false

      const isWidget = data.key
      var handled = false

      // console.log('acceptData :: vm.items.length = ' + vm.items.length)
      // console.log('acceptData :: vm.items[0]._id = ' + vm.items[0]._id)

      const remainingCols = vm.getRemainingColsOfRow(vm.items)
      // console.log('acceptData: remainingCols = ' + remainingCols)
      // console.log('acceptData: isWidget = ' + (isWidget ? 'yes' : 'no'))
      if (remainingCols > 0) {

        if (!isWidget) {
          if (vm.items.length === 1 &&  vm.items[0]._id===data._id) {
            // console.log('acceptData: (items.length===1 && first item id===data.id) isWidget = ' + (isWidget ? 'yes' : 'no'))
            handled = true
          }
          // if (vm.items.length > 1) {
          //   result = true
          //   handled = true
          // }
        }

        if (!handled) {
          // console.log('acceptData :: data: ', data)
          // console.log('acceptData :: isWidget = ' + (isWidget ? 'yes' : 'no'))

          const hasWidth = isWidget ?
            vm.getHasWidgetProperty(data, 'width') :
            vm.getHasFieldProperty(data, 'width')
          // console.log('acceptData :: hasWidth = ' + (hasWidth ? 'yes' : 'no'))
          result = hasWidth
          // console.log('acceptData :: result = ' + result)
        }

        // console.log('acceptData :: remainingCols = ' + remainingCols)
        // console.log('acceptData :: hasWidth = ' + (result ? 'yes' : 'no'))
        // console.log('acceptData :: items: ', vm.items)
        // console.log('acceptData :: data: ', data)
      } else {
        // console.log('acceptData :: remainingCols <= 0')
      }
      // console.log('acceptData :: result = ' + (result ? 'yes' : 'no'))
      return result
    },
    getHasFieldProperty( field, propertyName) {
      const vm = this
      const fieldInfo = vm.getFieldInfo(field._id)
      const result = fieldInfo && fieldInfo.properties && fieldInfo.properties[propertyName]
      // console.log('getHasFieldProperty :: result = ' + (result ? 'yes' : 'no'))
      return result
    },
    getHasWidgetProperty (widget, propertyKey) {
      const vm = this
      var result = false
      if (widget && widget.properties) {
        const widthPropertyIndex = widget.properties.findIndex(item => item.propertyKey === propertyKey)
        result = widthPropertyIndex >= 0
      }
      return result
    },
    // getFieldInfo (fieldId) {
    //   const vm = this
    //   return vm.form.fields.find(item => item._id === fieldId)
    // },
    getFieldProperty(fieldId, propertyName) {
      const vm = this
      const fieldInfo = vm.getFieldInfo(fieldId)
      var result = ''
      // console.log('getFieldProperty :: fieldInfo: ', fieldInfo)
      if (fieldInfo.properties && fieldInfo.properties[propertyName]) {
        result = fieldInfo.properties[propertyName]
        // console.log('getFieldProperty :: find property: ' + propertyName + ': result = ' + result)
      } else {
        console.log('getFieldProperty :: no property: ' + propertyName)
      }
      // console.log('getFieldProperty (' + propertyName + '): result: ', result)
      return result
    },
    getRemainingColsOfRow (items) {
      return 12 - this.totalOccupiedWidth
    },
    selectItem (layoutItem, fieldInfo) {
      const vm = this
      vm.$emit('onCommand', {
        command: 'selectItem',
        layoutItem: layoutItem,
        fieldInfo: fieldInfo
      })
    },
    onInsertToRow (event) {
      const vm = this
      // console.log('FormLayoutRow :: InsertToRow :: event: ', event)
      // console.log('FormLayoutRow :: InsertToRow :: vm.form: ', vm.form)
      // console.log('FormLayoutRow :: InsertToRow :: vm.items.length = ' + vm.items.length)
      var item = null
      const remainingCols = vm.getRemainingColsOfRow(vm.items)
      // console.log('onInsertToRow :: remainingCols = ' + remainingCols)
      if (event.type === 'widget') {
        const widget = event.data
        const hasWidth = vm.getHasWidgetProperty(widget, 'width')
        if (hasWidth && remainingCols > 0) {

          vm.$store.dispatch('INSERT_LAYOUT_ITEM', {
            rowIndex: vm.rowIndex,
            width: remainingCols,
            data: event.data,
            index: event.index,
            type: event.type,
            widgetProperties: vm.$store.getters.widgetProperties,
          }).then (res => {
            vm.selectItem(res.newLayoutItem, vm.newFieldInfo)
          })
          // var newWidgetItem = JSON.parse(JSON.stringify(widget))
          // const newWidgetItemId = '_' + Date.now().toString()
          // newWidgetItem._id = newWidgetItemId
          // newWidgetItem.type = widget.key
          // newWidgetItem.key = null
          // newWidgetItem.fieldId = vm.getNextFieldId()
          // newWidgetItem.widgetId = widget._id
          // newWidgetItem.properties = vm.getPropertyKeyList(newWidgetItem.properties)
          // newWidgetItem.properties['width'] = remainingCols
          // if (widget.key.substr(0,1)==='_') {
          //   vm.formUIElementInfos.push(newWidgetItem)
          // } else {
          //   vm.formFieldInfos.push(newWidgetItem)
          // }
          // vm.items.splice(event.index, 0, {_id: newWidgetItemId})
        }
      } else {
        var dataWidth = parseInt(vm.getFieldProperty(event.data._id, 'width'))
        // console.log('FormLayoutRow :: InsertToRow :: dataWidth = ' + dataWidth)

        if (dataWidth > remainingCols) {
          dataWidth = remainingCols
        }
        vm.$store.dispatch('SET_FIELD_INFO_PROPERTY', {
          fieldInfoId: event.data._id,
          propertyKey: 'width',
          propertyValue: dataWidth
        })
        // vm.setFieldProperty(event.data._id, 'width', dataWidth)
        // console.log('insertToRow :: widget item: ', event.data)
        // console.log('insertToRow :: items: ', vm.items)
        vm.items.splice(event.index, 0, event.data )
        // console.log('insertToRow :: items: ', vm.items)
      }
      // vm.$emit('onCommand', {
      //   onCommand
      // })
    },
    // setFieldProperty (fieldId, propertyName, propertyValue) {
    //   const vm = this
    //   vm.$store.commit('setFieldProperty', {
    //     fieldId: fieldId,
    //     propertyName: propertyName,
    //     propertyValue: propertyValue
    //   })
    //   // const fieldInfo = vm.getFieldInfo(fieldId)
    //   // fieldInfo[propertyName] = propertyValue
    // },
    getPropertyKeyList (properties) {
      const vm = this
      var result = {}
      for (var i = 0; i < properties.length; i++) {
        const loopProperty = properties[i]
        result[loopProperty.propertyName] = ''
      }
      return result
    },
    onReorderItem (event) {
      const vm = this
      event.apply(vm.items)

      // console.log('FormLayoutRow :: onReorderItem :: event: ', event)
    },
    itemWidth (item) {
      const vm = this
      var result = 12

      // console.log('FormLayoutRow :: itemWidth: vm.formFieldInfos: ', vm.formFieldInfos)
      // console.log('FormLayoutRow :: itemWidth: vm.formUIElementInfos: ', vm.formUIElementInfos)

      var widgetInfo = vm.formFieldInfos.find(info => info._id === item._id)
      if (!widgetInfo) {
        widgetInfo = vm.formUIElementInfos.find(info => info._id === item._id)
      }
      // console.log('itemWidth :: widgetInfo: ', widgetInfo)
      if (widgetInfo && widgetInfo.properties && Object.keys(widgetInfo.properties).indexOf('width')>=0) {
        result = parseInt( widgetInfo.properties.width )
        // console.log('itemWidth (found property width):: result = ' + result)
      }
      return result
    },
    itemKey (item) {
      const vm = this
      // console.log('itemKey = item._id = ' + item._id)
      return item._id
    },
    removeItem (item) {
      const vm = this
      // console.log('removeItem :: item: ', item)
      vm.$store.dispatch('REMOVE_LAYOUT_ITEM', item)
    },

    getWidgetByLayoutField (widgetItem) {
      const vm = this
      const fieldInfo = vm.getFieldInfo(widgetItem._id)
      var result = null
      if (fieldInfo) {
        result = vm.getWidget(fieldInfo.widgetId)
      }
      // console.log('getWidgetByLayoutField :: widgetItem: ', widgetItem)
      return result
    }

    // ,
    // getNextFieldId () {
    //   const vm = this
    //   var count = 1
    //   var fieldId = 'f' + vm.zeroPad(count, 4)
    //   var fieldIds = vm.formFieldInfos.map(field => fieldId)
    //   while (fieldIds.indexOf(fieldId) >= 0) {
    //     count++
    //     fieldId = 'f' + vm.zeroPad(count, 4)
    //   }
    //   // console.log('getNextFieldId : fieldId = ' + fieldId)
    //   return fieldId
    // }
  }
}
</script>

<style>
  /*.drop-list {*/
  /*!*background-color: lightcoral;*!*/
  /*min-height: 32px;*/
  /*}*/

  .drop-list-row .drag-item {
    border: 2px solid transparent;
  }

  .drop-list-row .drag-source {
    border: 2px dashed rgba(0,0,0,.5);
  }

  .drop-list-row .col {
    transition-property: all !important;
    transition-duration: 1s !important;
  }
  /*.drop-list-row .col.col-1,*/
  /*.drop-list-row .col.col-2,*/
  /*.drop-list-row .col.col-3,*/
  /*.drop-list-row .col.col-4,*/
  /*.drop-list-row .col.col-5,*/
  /*.drop-list-row .col.col-6,*/
  /*.drop-list-row .col.col-7,*/
  /*.drop-list-row .col.col-8,*/
  /*.drop-list-row .col.col-9,*/
  /*.drop-list-row .col.col-10,*/
  /*.drop-list-row .col.col-11,*/
  /*.drop-list-row .col.col-12*/
  /*{*/
  /*transition: width 2s !important;*/
  /*}*/
</style>
