const mixin = {
    data(){
        return {
            originalValue: null
        }
    },
    computed:{
        selectOptions(){
            return this.inputOptionsValue && this.inputOptionsValue.options.filter(option => 
              (!option.isDeleted && option.active) ||
              !this.isLayout && (
              Array.isArray(this.originalValue) && this.originalValue.includes(option._id) ||
              typeof this.originalValue === 'string' && option._id === this.originalValue )
            )
        },
        allowAdd(){
            return Boolean(!["PublicForm", "PublicQuery", "PublicEdit"].includes(this.$route.name) && this.fieldInfo && this.fieldInfo.properties.settingsMultiple
                && this.fieldInfo.properties.settingsMultiple.includes("allowUserAdd")) 
        },
        isMultiple(){
            return this.fieldType === 'multipleSelection'
          },

    },
    methods: {
        afterMount(){
            this.originalValue = Array.isArray(this.inputValue) ? [...this.inputValue]:this.inputValue;
        },
        getDefaultValue(){
            if (Array.isArray(this.value) && this.value.length > 0 ||  Boolean(this.value)) return this.value;
            let result = this.fieldType === 'multipleSelection' ? []:"";

            if (this.inputOptionsValue){
                
                const options = this.inputOptionsValue.options.filter(option => (!option.isDeleted && option.active))
                if (this.fieldType === 'multipleSelection') {
                    const optionIds = options.map(item=>item._id)
                    
                    result = this.inputOptionsValue.defaultSelection.filter(selection => optionIds.includes(selection))                    
                }
                else {
                    const defaultValue = options.find(item=>item._id === this.inputOptionsValue.defaultSelection)
                    result = defaultValue ? defaultValue._id: ""
                }
            }

            return result
        }
    }
}

export default mixin