<template>
    <v-card elevation="0" style="overflow: hidden; height:100%">
        <v-card-text style="overflow: auto; height:100%" class="px-5 pt-5">
            <div class="d-flex">
                <div style="width: 4px; height:17px" class="primary"></div>
                <div class="ml-2"> {{$t('shareApp.sharedApp')}}</div>
            </div>
            <div class="py-3" v-if="sharedApps">
                <v-hover v-slot="{ hover }" v-for="(sharedApp,index) in sharedApps" :key="index">
                    <v-card
                        height="80"
                        :class="index===0?'':'mt-3'"
                        elevation="0"
                        outlined
                        style="border-style: dashed; height:100px"
                    >
                        <div class="pl-5 pr-2 d-flex fill-height align-center">
                            <div class="">
                                <div v-if="sharedApp.appId && sharedApp.appId.title">
                                    {{$t('shareApp.appName')}}:
                                    {{sharedApp.appId.title}}
                                </div> 
                                <div>
                                    {{$t('shareApp.sharedDate')}}:
                                    {{sharedApp.createdAt.substr(0,10)}}
                                </div>
                                <div v-if="sharedApp.targetTeamId && sharedApp.targetTeamId.name">
                                    {{$t('shareApp.acceptedTeamName')}}:
                                    {{sharedApp.targetTeamId.name}}
                                </div>
                            </div> 
                        </div>
                        <v-btn @click="unsharedApp(sharedApp)" v-if="hover && !sharedApp.reversed && sharedApp.canUnshare" :ripple="false" x-small icon style="position: absolute; top: 2px; right:2px"> 
                            <v-icon color="primary">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                        <div 
                            v-if="sharedApp.reversed"
                            style="position: absolute; top: 2px; right:2px"
                            class="mr-1 error--text d-flex justify-end flex-column"
                        >
                            <div style="text-align: end;">{{$t('publicEdit.unshared')}}</div>
                            <div>{{sharedApp.reversedAt.substr(0,10)}}</div>
                        </div>
                    </v-card>
                </v-hover>
            </div> 
        </v-card-text>
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </v-card>
</template>

<script>
import confirmDialog from "@/components/dialogs/ConfirmDialog";
export default {
  components:{
    confirmDialog
  },
  name: 'sharedApp',
  data(){
    return{
        sharedApps:[]
    }
  },
  computed:{
    activeTeamId() {
        return this.$store.getters.activeTeamId;
    },
  }, 
  created(){
    this.fetchSharedHistory()
},
  methods:{
    fetchSharedHistory(){
        const getParams = {
            urlCommand: "/teams/" + this.activeTeamId + "/sharedHistory"
        };
        this.$store.dispatch("AUTH_GET", getParams).then(response => {
            this.sharedApps = response
        });
    },
    async unsharedApp(sharedApp){
        let vm = this
        vm.$refs.confirmDialog
            .open(vm.$t("general.warning"), vm.$t("messages.shareLinkWillBeInvalid"), {
                color: "error",
                confirmBtnColor: "error",
                cancelBtnColor: "muted",
                confirmMsg: this.$t("buttons.abandon"),
                cancelMsg: this.$t("buttons.cancel"),
            })
            .then(async (result) => {
            if (result) {
                const sharedId = sharedApp._id
                console.log('unsharedApp::sharedApp', sharedApp)
                const postData = {
                    urlCommand: "/teams/" + this.activeTeamId + "/unshare",
                    data:{
                        sharedId
                    }
                };
                await this.$store.dispatch('AUTH_POST', postData).then(async(response)=>{
                    if(response.status){
                        var updatedRecord = this.sharedApps.find(shared=>shared._id===sharedApp._id)
                        updatedRecord.reversed = response.result.reversed
                        updatedRecord.reversedAt = response.result.reversedAt
                    }else{
                        this.$toast.error('Unshare Failed', {duration: 2000})
                    }
                });
            }
        });
    }
  }
}
</script>
