<template>
  <base-tabs-dialog
    :icon="viewIcon"
    :title="viewConfig.title"
    @submit="onSubmit"
    @input="input"
    v-if="showing"
    v-model="showing"
    :noFooter="!shouldShowFooter"
    :initialIndex="tabIndex"
    @onCommand="onCommandHandler"
    @tabChange="tabChange"
    :tabs="tabs"
  >
    <template>
      <v-tab-item v-for="(tab, index) in tabs" :key="index"
                  :eager="true"
                  :transition="false"
                  :reverse-transition="false"
                  class="fill-height pa-5">
        <component :is="tab.componentName"
                   :ref="tab.refName"
                   :labelTag="tab.labelTag"
                   :view="viewConfig"
                   :tabIndex="tabIndex"
                   :settingNames="tab.settingNames"
                   :noValidation="tab.noValidation"
                   @onCommand="onCommandHandler"></component>
      </v-tab-item>
      <!--sortingConfigs.length = {{ viewConfig.sortingConfigs.length }}-->
    </template>
  </base-tabs-dialog>
</template>

<script>
  import {
    POPUP_ACTION_BUTTON_POPUP,
  } from '@/store/modules/customizedAction/action_types';
  import viewSettingsParentChildSettings from './viewSettingsTabs/ViewSettingsParentChildSettings'
  import viewSettingsPanelSettings from './viewSettingsTabs/ViewSettingsPanelSettings'
  import viewSettingsTableDisplayFields from './viewSettingsTabs/ViewSettingsTableDisplayFields'
  import viewSettingsFilters from './viewSettingsTabs/ViewSettingsFilters'
  import viewSettingsSorting from './viewSettingsTabs/ViewSettingsSorting'
  import viewSettingsCustomButtons from './viewSettingsTabs/ViewSettingsCustomButtons'
  import viewSettingsPanelDisplayFields from './viewSettingsTabs/ViewSettingsPanelDisplayFields'
  import viewSettingsTableGeneralSetting from './viewSettingsTabs/ViewSettingsTableGeneralSetting'
  import viewSettingCustomizedAction from './viewSettingsTabs/ViewSettingCustomizedAction'

  import dialogHeader from '@/components/dialogs/comps/DialogHeader'

  // import clickInput from '@/components/formComps/ClickInput'

  import adminFormMixin from '@/mixins/adminFormMixin'
  import filterHelperMixin from '@/mixins/filterHelperMixin'
  import dialogMixin from '@/mixins/dialogMixin'

  import viewTabConfigs from './viewSettingsTabs/viewTabConfigs'
  import baseTabsDialog from '@/components/dialogs/BaseTabsDialog'

  export default {
    mixins: [adminFormMixin, filterHelperMixin, dialogMixin],
    props: {
      initialTabIndex: {
        type: Number,
        default: 0,
      },
    },
    components: {
      viewSettingsParentChildSettings,
      viewSettingsPanelSettings,
      viewSettingsTableDisplayFields,
      viewSettingsFilters,
      viewSettingsSorting,
      viewSettingsCustomButtons,
      viewSettingsPanelDisplayFields,
      viewSettingsTableGeneralSetting,
      viewSettingCustomizedAction,
      dialogHeader,
      baseTabsDialog
      // ,
      // clickInput
    },
    data () {
      return {
        // TAB_SETTINGS: 0,
        // TAB_FILTERS: 1,
        // TAB_SORTING: 2,
        // TAB_BUTTONS: 3,
        noFooter: false,
        showing: false,
        savedViewConfig: null,
        viewConfig: {
          title: '',
        },
        tabIndex: this.initialTabIndex,
        onSubmisson: () => {
        },
        tabs: []
      }
    },
    computed: {
      defaultViewSettings () {
        return JSON.parse(JSON.stringify(this.$store.getters.defaultViewSettings))
      },
      settingTabName () {
        const vm = this
        var result = ''
        switch (vm.viewConfig.viewType) {
          case 'table':
            result = vm.$t('view.tableSettings')
            break
          case 'panel':
            result = vm.$t('view.panelSettings')
            break
          case 'levelDiagram':
            result = vm.$t('view.levelDiagramSettings')
            break
        }
        return result
      },

      viewIcon () {
        const vm = this
        var result = 'mdi-table'
        if (vm.viewConfig) {
          switch (vm.viewConfig.viewType) {
            case 'table':
              result = 'mdi-table'
              break
          }
        }
        return result
      },

      isValidData () {
        const vm = this
        var result = false
        if (vm.viewConfig && vm.viewConfigTitle !== '') {
          result = true
        }
        return result
      },

      // dialogTitle () {
      //   return this.viewConfig.title
      //   // return this.$t('view.viewSettings')
      // },
      filterInfos () {
        return this.$store.getters.filterInfos
      },

      errorCount () {
        return this.tabs.reduce( (total, tab) => total + tab.errorCount )
      },

      isPopupActionButton() {
        return this.$store.getters.isPopupActionButton;
      },
      shouldShowFooter() {
        return !this.noFooter || (this.tabIndex !== 5 && this.isPopupActionButton !== 5);
      }
    },
    watch: {
      isPopupActionButton: function(v) {
        if (v !== -1) {
          this.tabIndex = v;
        }
      },
    },
    methods: {
      // shouldShowFooter() {
      //   return !this.noFooter || this.tabIndex !== 4;
      // },
      input(v) {
        if (!v) {
          this.$store.dispatch(POPUP_ACTION_BUTTON_POPUP, -1);
        }
      },
      tabChange(index) {
        this.tabIndex = index;
        this.noFooter = index === 5;
      },
      open (viewConfig, onSubmission) {
        const vm = this
        // console.log('ViewSettingsDialog :: open: viewConfig: ', viewConfig)
        vm.savedViewConfig = JSON.parse(JSON.stringify(viewConfig))
        var tabs = []
        switch (viewConfig.viewType) {
          case 'table':
            tabs = [
              viewTabConfigs.DISPLAY_TABLE_SETTING,
              viewTabConfigs.TABLE_DISPLAY_FIELDS,
              viewTabConfigs.FILTERS,
              viewTabConfigs.QUICK_FILTERS,
              viewTabConfigs.SORTING,
              viewTabConfigs.CUSTOMIZEDACTION,
              // ,
              // viewTabConfigs.CUSTOM_BUTTONS
            ]
            break
          case 'panel':
            tabs = [
              viewTabConfigs.PANEL_SETTINGS,
              viewTabConfigs.PANEL_DISPLAY_FIELDS,
              viewTabConfigs.FILTERS,
              viewTabConfigs.SORTING,
              // ,
              // viewTabConfigs.CUSTOM_BUTTONS
            ]
            break
          case 'levelDiagram':
          tabs = [
              viewTabConfigs.PARENT_CHILD_SETTINGS,
              // viewTabConfigs.FILTERS,
              // viewTabConfigs.SORTING
          ]
          break
        }
        vm.tabs = []
        for (let i = 0; i < tabs.length; i++) {
          const loopTab = tabs[i]
          vm.tabs.push({
            // notes:
            // no validation is neceessary for quick-filters, which is mainly used by user
            // if it is specified, the value is made as default
            // (at the moment, used by QuickFilter)
            icon: loopTab.icon,
            labelTag: loopTab.labelTag,
            componentName: loopTab.componentName,
            refName: loopTab.refName,
            settingNames: loopTab.settingNames,
            noValidation: loopTab.noValidation,
            suffix: loopTab.suffix,
            errorCount: 0
          })
        }
        // console.log('ViewSettingsDialog :: open :: vm.tabs: ', vm.tabs)

        // avoid missing updated items
        const defaultSettings = JSON.parse(JSON.stringify(vm.defaultViewSettings))
        // console.log('open: defaultSettings: ', defaultSettings)
        // console.log('open: defaultSettings.parentChildFieldFormList[0].cardConfig: ', defaultSettings.parentChildFieldFormList[0].cardConfig)
        // console.log('open: viewConfig.parentChildFieldFormList[0].cardConfig: ', viewConfig.parentChildFieldFormList[0].cardConfig)
        vm.viewConfig = {...viewConfig}
        // console.log('open: vm.viewConfig: ', vm.viewConfig)
        vm.onSubmission = onSubmission
        if(viewConfig.hasOwnProperty('tabIndex')){
          this.tabIndex = viewConfig.tabIndex
        }
        vm.showing = true
      },

      cancel () {
        const vm = this
        vm.viewConfig = Object.assign(vm.viewConfig, vm.savedViewConfig)
        vm.viewConfig.cardDisplayFieldIds = vm.savedViewConfig.cardDisplayFieldIds
        vm.close()
      },

      close () {
        this.showing = false
      },

      onCommandHandler (payload) {
        console.log("ViewSettingsDialog.vue:onCommandHandler: ", payload.command)
        const vm = this
        switch (payload.command) {
          case 'checkValidity':
            this.noFooter = payload.newIndex === 5;
            this.tabIndex = payload.newIndex;
            break;
          case 'updatePanelCardDisplayFieldsSettings':
            vm.updatePanelCardDisplayFieldsSettings(payload)
            break
          case 'updateTableSettings':
            delete payload.command
            vm.updateTableSettings(payload)
            break
          case 'updateViewSetting':
            vm.updateViewSetting(payload.settingName, payload.val)
            break

          case 'updateSorting':
            console.log('onCommandHandler :: updateSorting payload.sortingConfigs: ', payload.sortingConfigs)
            vm.viewConfig.sortingConfigs = JSON.parse(JSON.stringify(payload.sortingConfigs))

            // vm.$nextTick(() => {
            //   vm.$set(vm.viewConfig, 'sortingConfigs', payload.sortingConfigs)
            // })
            //
            // vm.viewConfig.sortingConfigs = payload.sortingConfigs
            console.log('onCommandHandler :: vm.viewConfig.sortingConfigs: ', vm.viewConfig.sortingConfigs)
            break
          case 'updateFilter':
            // alert('ViewSettingsDialog :: updateFilter')
            // vm.$set(vm.viewConfig.filters, payload.filterIndex, payload.filter)
            vm.viewConfig.filters[payload.filterIndex].filterValue1 = payload.filter.filterValue1
            vm.viewConfig.filters[payload.filterIndex].filterValue2 = payload.filter.filterValue2
            vm.viewConfig.filters[payload.filterIndex].relation = payload.filter.relation
            // vm.viewConfig.filters[payload.filterIndex] = payload.filter
            break
          // case 'updateAndOrOption':
          //   vm.viewConfig.filterAndOr = payload.andOrOption
          //   break
          case 'addFilter':
            vm.addFilter(payload.filtersSettingName, payload.fieldId)
            if (typeof payload.callback === 'function') {
              payload.callback()
            }
            break
          case 'deleteFilter':
            vm.deleteFilter(payload.filtersSettingName, payload.filterIndex)
            break
          case 'deleteAllFilters':
            vm.deleteAllFilters(payload.filtersSettingName)
            break
        }
      },

      updateViewSetting (settingName, val) {
        const vm = this
        console.log('updateViewSetting :: settingName = ' + settingName)
        console.log('updateViewSetting :: val = ' + val)

        vm.$set(vm.viewConfig, settingName, val)
        // vm.viewConfig[settingName] = val
      },

      addFilter (filtersSettingName, fieldId) {
        const vm = this
        console.log('addFilter: filterSettingName = ' + filtersSettingName)
        console.log('addFilter: fieldId = ' + fieldId)
        const widgetMap = vm.$store.getters.widgetMap
        const fieldInfo = vm.form.fieldInfos.find(info => info.fieldId === fieldId)
        var filterKey = vm.getFilterKeyByFieldInfo(widgetMap, fieldInfo)
        const filterInfo = vm.filterInfos[filterKey]
        var relation = ''
        if (filterInfo && filterInfo.options && filterInfo.options.length > 0) {
          relation = filterInfo.options[0]
        }

        var filterValue1 = ''
        if (relation === 'toggle') {
          filterValue1 = 'yes'
        }
        const filterSetting = {
          fieldId: fieldId,
          relation: relation,
          filterValue1: filterValue1,
          filterValue2: ''
        }
console.log('addFilter: filterSetting: ', filterSetting)
        if (vm.viewConfig[filtersSettingName]) {
          vm.viewConfig[filtersSettingName].push(filterSetting)
        } else {
          vm.$set(vm.viewConfig, filtersSettingName, [filterSetting])
        }

console.log('addFilter: viewConfig: ', vm.viewConfig)        
      },

      deleteFilter (filtersSettingName, fieldIndex) {
        this.viewConfig[filtersSettingName].splice(fieldIndex, 1)
      },

      deleteAllFilters (filtersSettingName) {
        this.viewConfig[filtersSettingName] = []
      },

      updatePanelCardDisplayFieldsSettings (payload) {
        const vm = this
        console.log('updateTableDisplayFieldsSettings :: payload: ', payload)
        if (payload.displayFieldIds.length === 0) {
          const n = vm.viewConfig.cardDisplayFieldIds.length
          for (var i = 0; i < n; i++) {
            vm.viewConfig.cardDisplayFieldIds.splice(0, 1)
          }
        } else {
          vm.viewConfig.cardDisplayFieldIds = payload.displayFieldIds
        }
      },

      updateTableSettings (payload) {
        const vm = this
        for (let key in payload) {
          vm.viewConfig[key] = payload[key]
        }
      },

      getTabIndex (refName) {
        const vm = this
        console.log('getTabIndex : refName = ' + refName + ', tabs: ', vm.tabs)

        var result = -1
        for (var i = 0; i < vm.tabs.length; i++) {
          const loopTab = vm.tabs[i]
          if (loopTab.refName === refName) {
            result = i
            break
          }
        }
        console.log('getTabIndex :: result = ' + result)
        return result
      },

      checkValidity () {
        const vm = this
        for (let i = 0; i < vm.tabs.length; i++) {
          const loopTab = vm.tabs[i]
          const refName = loopTab.refName
          const refObj = vm.$refs[refName]
          if (Array.isArray(refObj)) {
            vm.tabs[i].errorCount = refObj[0].checkErrorCount(true)
          } else {
            vm.tabs[i].errorCount = refObj.checkErrorCount(true)
          }
        }
      },

      onSubmit () {
        const vm = this
        if (vm.$refs.viewSettingsFilters) {
          const filterTabIndex = vm.tabs.findIndex(tab => tab.refName === 'viewSettingsFilters')
          if (vm.tabs[filterTabIndex].errorCount > 0) {
            vm.tabIndex = filterTabIndex
            return
          }
        }
        console.log('after check filterdialog => check viewType===panel')

        if (vm.viewConfig.viewType === 'panel') {
          console.log('onSubmit :: viewType is panel')
          if (vm.$refs.viewSettingsPanelSettings) {
            console.log('viewSettingsPanelSettings defined')
            const errorCount = (vm.$refs.viewSettingsPanelSettings instanceof Array) ?
              vm.$refs.viewSettingsPanelSettings[0].checkErrorCount(true) :
              vm.$refs.viewSettingsPanelSettings.checkErrorCount(true)

            console.log('onSubmit :: errorCount = ' + errorCount)
            if (errorCount > 0) {
              vm.tabIndex = vm.getTabIndex('viewSettingsPanelSettings')
              console.log('viewSettingsPanelSettings tabIndex = ' + vm. tabIndex)
              return
            } else {
              console.log('onSubmit: valid')
            }
          } else {
            console.log('onSubmit :: no viewSettingsPanelSettings')
            vm.tabIndex = vm.getTabIndex('viewSettingsPanelSettings')

            const result = (vm.$refs.viewSettingsPanelSettings instanceof Array) ?
              vm.$refs.viewSettingsPanelSettings[0].checkErrorCount(true) :
              vm.$refs.viewSettingsPanelSettings.checkErrorCount(true)

            return
          }
        }

        // console.log('ViewSettingsDialog :: vm.onSubmission: ' + (typeof vm.onSubmission))
        if (typeof vm.onSubmission === 'function') {
          vm.onSubmission(vm.viewConfig, vm.onSubmitted)
        }
      },

      onSubmitted () {
        const vm = this
        if (typeof vm.callback === 'function') {
          vm.callback(vm.viewConfig)
        }
        vm.showing = false
        // alert('callback in callback')
        vm.showSuccess('messages.savedSuccessfully')
      }
    }
  }
</script>

<style>
  .view-settings-dialog .v-tab.v-tab--active {
    background-color: #1976d2 !important;
    color: white !important;
  }

  .view-settings-dialog .v-window {
    background-color: transparent;
  }

  .view-settings-dialog .v-tabs-items {
    border-left: #1976d2 2px solid !important;
    padding-left: 5px !important;
    height: 640px;
  }

  .view-settings-dialog .view-settings-content .v-window__container {
    display: flex;
    flex-direction: column;
  }

  .view-settings-dialog .view-settings-content .v-window__container .v-window-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

</style>
