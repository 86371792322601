<template>
  <div>
    <slot v-bind="{ navigatorInfo, setTitle, setEventType, eventTypeFromIndex, getEventType }" />
  </div>
</template>

<script>
export default {
  inject: ["navigatorInfo", "setTitle", "setEventType", "eventTypeFromIndex", "getEventType"]
};
</script>

<style>
</style>
