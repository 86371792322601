<template>
    <v-menu
        transition="scale-transition"
        content-class="date-grouping-list"
        class="date-grouping-menu"
        offsetY
        nudge-bottom="3"
    >
        <template v-slot:activator="{ on, value }">
            <slot v-bind:on="on" v-bind:opening="value" name="dateRow"></slot>
        </template>
        <v-card tile class="elevation-0" v-if="field">
            <v-card-text class="pa-0">
                <v-list class="pa-0 denseList">
                    <v-list-item-group
                        color="primary"
                        v-model="field.dateGroupingOpt"
                    >
                        <v-list-item 
                            v-for="(option, index) in dateGroupingOptions"
                            :key="index"
                            :value="option"
                        >
                            {{option}}
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        props:{
            value:{
                type: Boolean,
                default: false
            },
            attach:{
                type: String,
                default: undefined
            },
            field:{
                type: Object,
                default: {}
            }
        },
        data(){
            return {
                groupType: 'date',
                dateGroupingOptions: ['Month', 'Quarter', 'Year']
            }
        },
        computed:{
            showingMenu:{
                get(){
                    return this.value
                },
                set(value){
                    this.$emit('input', value)
                }
            }
        }
    }
</script>