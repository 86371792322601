export const CHECK_LOCAL_TOKEN = 'CHECK_LOCAL_TOKEN'
export const FETCH_SETTINGS = 'FETCH_SETTINGS'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE'
export const UPDATE_USER_APPS_LAYOUT = 'UPDATE_USER_APPS_LAYOUT'
export const CLEAR_PROFILE_COUNTER = 'CLEAR_PROFILE_COUNTER';
export const INIT_TEAM = 'INIT_TEAM';
export const UPDATE_TEAM = "UPDATE_TEAM";
export const FETCH_QUICK_FILTER_PRESET = "FETCH_QUICK_FILTER_PRESET";
export const GET_QUICK_FILTER_BY_VIEWID = "GET_QUICK_FILTER_BY_VIEWID";
export const SET_QUICK_FILTER = "SET_QUICK_FILTER";