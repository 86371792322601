<template>
  <v-layout justify-end class="w-arrow-control-wrapper">
    <v-tooltip
      v-for="tool in tools"
      :key="tool.key"
      bottom
      transition="slide-y-reverse-transition"
      content-class="w-arrow-control-tool"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-btn v-if="!tool.component" icon @click="onClick(tool)" elevation="0">
            <v-icon
              small
              size="20"
            >
              {{ tool.icon }}
            </v-icon>
          </v-btn>
          <component :appId="currentApp && currentApp._id" :is="lookup[tool.component]" :item="item" :index="index" :type="type" />
        </span>
      </template>
      <span>{{ tool.label }}</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
import ConfirmRemove from './confirmRemove';
import ConfirmCopy from './confirmCopy';
import SettingUtils from './../../../../utils/setting';
import Mixin from './../../../../mixin';

export default {
  mixins: [Mixin],
  data() {
    let tools = [
      {
        key: 'delete',
        label: this.$t('workflow.btn.delete'),
        component: 'remove',
      },
    ];
    if (!this.notReady) {
      tools = [
        {
          key: 'history',
          label: this.$t('workflow.btn.history'),
          icon: 'mdi-history',
          inspector: 'goHistory',
        },
        {
          key: 'copy',
          label: this.$t('workflow.btn.copy'),
          component: 'copy',
        },
        ...tools,
      ];
    }
    return {
      lookup: {
        remove: 'ConfirmRemove',
        copy: 'ConfirmCopy',
      },
      // tool's pattern lookup
      tools,
    };
  },
  props: {
    item: Object,
    notReady: Boolean,
    index: Number,
    execute: Function,
    type: String,
  },
  methods: {
    goHistory() {
      SettingUtils.editTree(this, this.currentRouteParams, this.item._id, 'history');
    },
    // dynamic execution
    onClick({ inspector }) {
      this[inspector]();
      // this.execute(this.item, inspector);
    },
  },
  components: {
    ConfirmRemove,
    ConfirmCopy,
  },
};

</script>

<style scoped>
  
</style>
