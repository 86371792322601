<template>
    <div>
        <v-layout align-start justify-space-between>
            <div class="workflow-setting-condition-select-root">
                <ComboBox
                    :value="content.rule || null"
                    :options="rules.options"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    :renderLabel="renderConditionLabel"
                    @changed="v => rule = v"
                />
            </div>
            <div class="workflow-setting-columnType-select-root">
                <ComboBox
                    :value="content.type || null"
                    :options="types"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    @changed="v => type = v"
                />
            </div>
        </v-layout>
        <div class="workflow-setting-columnValue-select-root">
            <div v-if="content.type==1">
                <div v-if="!disableInput">
                    <v-text-field
                        v-model="value"
                        type="number"
                        v-if="content.rule!='inRange' && content.rule!='notInRange'"
                        :placeholder="$t('messages.pleaseEnterNumber')"
                        outlined
                        height="36" class="workflow-setting-input-root">
                    </v-text-field>
                    <v-layout align-start justify-space-between v-if="content.rule=='notInRange' || content.rule=='inRange'">
                        <v-text-field type="number" v-model="minVal" :placeholder="$t('widgets.properties.min')" outlined height="36" class="workflow-setting-input-root"></v-text-field>
                        <span class="mx-2 mt-1"> - </span>  
                        <v-text-field type="number" v-model="maxVal" :placeholder="$t('widgets.properties.max')" outlined height="36" class="workflow-setting-input-root"></v-text-field>
                    </v-layout>
                </div>
                <div v-else>
                    <v-layout align-start justify-space-between v-if="content.rule=='notInRange' || content.rule=='inRange'">
                        <v-text-field type="number" v-model="minVal" :placeholder="$t('widgets.properties.min')" outlined height="36" class="workflow-setting-input-root"></v-text-field>
                        <span class="mx-2 mt-1"> - </span>  
                        <v-text-field type="number" v-model="maxVal" :placeholder="$t('widgets.properties.max')" outlined height="36" class="workflow-setting-input-root"></v-text-field>
                    </v-layout>
                    <v-text-field v-else type="number" v-model="minVal" :placeholder="$t('widgets.properties.min')" outlined height="36" class="workflow-setting-input-root"></v-text-field>
                </div>
            </div>
            <div v-else>
                <div class="w-double-line-filter-select-label" v-if="content.rule=='notInRange' || content.rule=='inRange'">
                    {{ $t('workflow.modules.form.label.from') }}
                </div>
                <RuleSelectField
                    :value="minVal"
                    :comboxProps="comboxProps"
                    :dependsOnType="fieldType"
                    valueFormat="id"
                    :formFieldInfos="formFieldInfos"
                    :fieldProperties="fieldProperties"
                    :placeholder="(content.rule=='notInRange' || content.rule=='inRange') ? $t('workflow.modules.form.placeholder.minValue') : null"
                    @changed="v => minVal = v"
                />
                <div v-if="content.rule=='notInRange' || content.rule=='inRange'" class="w-double-line-filter-select">
                    <div class="w-double-line-filter-select-label">
                        {{ $t('workflow.modules.form.label.to') }}
                    </div>
                    <RuleSelectField
                        :value="maxVal"
                        :comboxProps="comboxProps"
                        :dependsOnType="fieldType"
                        valueFormat="id"
                        :formFieldInfos="formFieldInfos"
                        :placeholder="$t('workflow.modules.form.placeholder.maxValue')"
                        @changed="v => maxVal = v"
                        :fieldProperties="fieldProperties"
                    />
                </div>
            </div>
        </div>
    </div>
    <!-- <div class=" d-flex mt-1">
        <div style="width: 120px">
        <v-select
                v-model="rule"
                class="vselect-small"
                style="font-size:12px"
                height="30"
                dense
                outlined
                :items="rules.options"
                hide-details
                :menu-props="{offsetY: true}"
            >
                <template v-slot:item="{item}">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
                <template v-slot:selection="{item}">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
            </v-select>
        </div>
        <div style="width:120px" class="ml-2" v-if="rule!=9 && rule!=10">
        <div style="width:120px" class="ml-2" v-if="rule!='inRange' && rule!='notInRange'">
            <v-select
                v-model="type"
                class="vselect-small"
                height="30"
                style="font-size:12px"
                dense
                outlined
                :items="types"
                item-text="name"
                item-value="id"
                :menu-props="{offsetY: true}"
                single-line
                hide-details
            />
        </div>
        <div v-if="type==1">
            <v-text-field v-model="value" v-if="rule!='inRange' && rule!='notInRange'" placeholder="請輸入數值" outlined height="30" class="ml-2"></v-text-field>
            <div v-if="rule=='notInRange' || rule=='inRange'" class="d-flex align-top ml-2">
                <v-text-field hide-details v-model="minVal" placeholder="最小值" outlined height="30" style="font-size:12px"></v-text-field>
                <span class="mx-2 mt-1">一</span>  
                <v-text-field hide-details v-model="maxVal" placeholder="最大值" outlined height="30" style="font-size:12px"></v-text-field>
            </div>
        </div>
        <RuleSelectField v-else :value="value" :formFieldInfos="formFieldInfos" @input="val=>value=val" class="ml-2"/>
    </div> -->
</template>

<script>
import mixin from './mixin'
import RuleSelectField from '../units/RuleSelectField'
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import SelectableText from './SelectableText';
import { split, includes } from 'lodash';
export default {
    mixins: [mixin],
    components:{
        RuleSelectField,
        ComboBox,
        SelectableText,
    },
    created(){
        if(this.rule=='inRange' || this.rule=='notInRange' ) {
            var ranges = split(this.value, "||",2)
            this.minVal = ranges[0] || 0
            this.maxVal =  ranges[1] || 0
        } else {
            this.minVal = this.value;
            this.maxVal = this.value;
        }
    },
    watch:{
        rule(newVal, oldVal){
            if (!!oldVal && !!newVal) {
                this.type = 1
                this.minVal = this.maxVal = 0
                this.value = 0
                // if( newVal == 'inRange' || newVal=='notInRange' ){
                //     if(oldVal!=null){
                //         this.type = 1
                //         this.minVal = this.maxVal = 0
                //     }
                // }else if(oldVal == 'inRange' || oldVal=='notInRange'){
                //     this.value = 0
                // }
            }
        },
        // type(newVal, oldVal) {
        //     console.log('>>> ', oldVal, newVal);
        //     if (!!oldVal && !!newVal) {
        //         if (newVal === 1) {
        //             this.minVal = 0;
        //             this.maxVal = 0;
        //         } else if (newVal === 2) {
        //             this.minVal = null;
        //             this.maxVal = null;
        //         }
        //     }
        // },
        minVal(val){
            if(this.isRangeRule()) {
                this.rangeToString();
            } else {
                this.value = val;
            }
        },
        maxVal(val){
            if(this.isRangeRule()) {
                this.rangeToString();
            } else {
                this.value = val;
            }
        }
    },
    methods:{
        isRangeRule() {
            const { content={} } = this;
            return includes([
                'notInRange',
                'inRange',
            ], content.rule);
        },
        rangeToString(){
            const { type } = this;
            if (type==1) {
                this.value = (this.minVal || '') + "||" + (this.maxVal || '')
            } else if (type==2) {   // field value
                const { minVal, maxVal } = this;
                const v = [];
                if (minVal) {
                    v[0] = minVal;
                }
                if (maxVal) {
                    v[1] = maxVal;
                }
                this.value = v.join('||');
            }
        }
    }
}
</script>

<style scoped>
    .w-double-line-filter-select {
        margin: 5px 0;
    }
    .w-double-line-filter-select-label {
        color: #666;
        text-align: center;
        margin: 5px 0 7px 0;
        font-size: 10px;
    }
</style>