<template>
  <v-dialog
    v-model="showingDialog"
    width="700"
    persistent
    class="bulk-edit-dialog"
    content-class="bulk-edit-dialog-content"
    scrollable
  >
    <v-card>
      <dialog-header
        :label="$t('bulkEdit.bulkEdit')"
        @close="showingDialog = false"
      />
      <v-card-subtitle class="mt-2 pl-5">
        {{ $t("bulkEdit.bulkEditDescription") }}
      </v-card-subtitle>
      <v-card-text class="pt-5" style="min-height: 220px">
        <div class="d-flex align-end">
          <div style="width: 100px">
            {{ $t("bulkEdit.bulkEditField") }}
          </div>
          <div class="flex-grow-1">
            <v-select
              class=""
              :items="filteredFieldInfos"
              hide-details
              dense
              outlined
              height="25"
              item-text="label"
              item-value="fieldId"
              style="font-size: 13px"
              :menu-props="{ offsetY: true, contentClass: 'denseList' }"
              v-model="selectedField"
            >
            </v-select>
          </div>
        </div>
        <div class="d-flex align-top mt-5">
          <div style="width: 100px">{{ $t("bulkEdit.bulkEditValue") }}</div>
          <div class="d-flex flex-column flex-grow-1">
            <v-radio-group
              mandatory
              dense
              row
              class="bulkEditRadio"
              v-model="editType"
            >
              <v-radio
                :ripple="false"
                :label="$t('bulkEdit.newVal')"
                value="NEW_VALUE"
              ></v-radio>
              <v-radio
                v-if="
                  fieldInfo &&
                  ['multipleSelection', 'members', 'departments'].includes(
                    fieldInfo.type
                  )
                "
                :ripple="false"
                :label="$t('bulkEdit.append')"
                value="APPEND"
                class="ml-5"
                :disabled="
                  (fieldInfo &&
                    fieldInfo.properties.validation &&
                    fieldInfo.properties.validation.includes('required')) ||
                  (fieldInfo && fieldInfo.type == 'owner')
                "
              ></v-radio>
              <v-radio
                :ripple="false"
                :label="$t('bulkEdit.clear')"
                value="CLEAR"
                class="ml-5"
                :disabled="
                  (fieldInfo &&
                    fieldInfo.properties.validation &&
                    fieldInfo.properties.validation.includes('required')) ||
                  (fieldInfo && fieldInfo.type == 'owner')
                "
              ></v-radio>
            </v-radio-group>
            <div v-if="['NEW_VALUE', 'APPEND'].includes(editType) && fieldInfo">
              <input-widget
                id="bulkEditInputWidget"
                :ref="fieldInfo.fieldId"
                :key="fieldInfo._id"
                :isNew="false"
                :editAnyway="true"
                :fullWidth="true"
                :recordData="recordData"
                :relatedTableData="relatedTableData"
                :relatedTableInfos="relatedTableInfos"
                :fieldInfo="fieldInfo"
                :formInfo="currentForm"
                @onCommand="onCommandHandler"
              ></input-widget>
              <!-- <component
                                v-if="inputWidget && selectedField"
                                v-bind:is="inputWidget"
                                :fieldInfo="fieldInfo"
                                :isLayout="false"
                            ></component> -->
            </div>
          </div>
        </div>
      </v-card-text>

      <dialog-actions :buttons="['ok', 'cancel']" @onCommand="onCommandHandler">
      </dialog-actions>
      <popup-record-dialog
        ref="popupRecordDialog"
        @onCommand="onCommandHandler"
      >
      </popup-record-dialog>
      <members-menu
        :fullWidth="true"
        v-if="showingMembersMenu"
        v-model="showingMembersMenu"
        attachId="bulkEditInputWidget"
        ref="membersMenu"
        @memberClick="handleMemberClick"
        :nudgeBottom="rowHeight"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import inputWidgets from "@/components/inputWidgets";
import inputWidget from "@/components/inputWidgets/InputWidget";
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import membersMenu from "@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu";
import { cloneDeep, get } from "lodash";

//TODO: move to DB
const fieldsExclusion = [
  "createdBy",
  "createdAt",
  "updatedAt",
  "attachments",
  "expression",
  "textCombination",
  "autoNumbering",
  "richText",
  "_sectionLine",
  "_remark",
  "capitalizedAmount",
  "parentChildRelation",
  "childTable",
  "otherTableField",
  "summaryField",
];
export default {
  props: {
    value: Boolean,
    fieldInfos: Array,
  },
  components: {
    ...inputWidgets,
    inputWidget,
    popupRecordDialog,
    dialogActions,
    dialogHeader,
    membersMenu,
  },
  computed: {
    filteredFieldInfos() {
      return this.fieldInfos.filter((item) => {
        let isShow = true;
        let authorization = get(item, "properties.authorization", "").split("|");
        if (authorization.includes("readonly") || authorization.includes("hidden"))
          isShow = false;
        if (fieldsExclusion.includes(item.type)) isShow = false;
        if (!this.editableFieldIds.includes(item._id)) isShow = false;
        return isShow;
      }).map(item=> ({
        ...item, canEdit:true
      }));
    },
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    fieldInfo() {
      const fieldInfo = this.filteredFieldInfos.find(
        (item) => item.fieldId === this.selectedField
      );
      let result = fieldInfo;
      if (fieldInfo && fieldInfo.type === "relatedRecord") {
        //PLUS1-388: force related record display as card
        result = cloneDeep(fieldInfo);
        result.properties.displayFormat = "card";
      }
      return result;
    },
    inputWidget() {
      if (this.fieldInfo) {
        return (
          "inputWidget" +
          this.fieldInfo.type.charAt(0).toUpperCase() +
          this.fieldInfo.type.slice(1)
        );
      } else return "";
    },
    currentForm() {
      return this.$store.getters.currentForm;
    },
    relatedTableInfos() {
      return this.$store.getters.relatedTableInfos;
    },
    editableFieldIds() {
      return this.fieldInfos
        .filter((info) => info.canEdit)
        .map((info) => info._id);
    },
  },
  data() {
    return {
      selectedField: null,
      recordData: {},
      relatedTableData: {},
      editType: "NEW_VALUE",
      fieldsExclusion: fieldsExclusion,
      defalutArrayType: [
        "members",
        "departments",
        "multipleSelection",
        "relatedRecord",
      ],
      showingMembersMenu: false,
      membersMenuPayload: null,
    };
  },
  watch: {
    selectedField() {
      this.recordData = {
        [this.fieldInfo._id]: this.defalutArrayType.includes(
          this.fieldInfo.type
        )
          ? []
          : "",
      };
      this.editType = "NEW_VAL";
    },
  },
  methods: {
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "memberInputClick":
          {
            let attachedEle = payload.attachedEle;
            let fieldInfo = payload.fieldInfo;
            vm.membersMenuPayload = payload;
            vm.showingMembersMenu = true;
            vm.$nextTick(() => {
              vm.$refs.membersMenu.type = fieldInfo.properties.fieldType;
              vm.$refs.membersMenu.selectedValue =
                vm.recordData[fieldInfo.fieldId];
              vm.$refs.membersMenu.fieldId = fieldInfo.fieldId;
              attachedEle.id = "";
            });
          }
          break;
        case "newRelatedRecord":
          vm.newRelatedRecord(payload);
          break;
        case "editRelatedRecord":
          vm.editRelatedRecord(payload);
          break;
        case "updateFieldValue":
          vm.updateFieldValue(payload);
          break;
        case "ok":
          this.submit();
          break;
        case "cancel":
          this.showingDialog = false;
          break;
      }
    },
    updateFieldValue(payload) {
      const vm = this;
      vm.$set(vm.recordData, payload.fieldName, payload.fieldValue);
    },
    newRelatedRecord(payload) {
      this.$refs.popupRecordDialog.open(payload);
    },
    editRelatedRecord(payload) {
      this.$refs.popupRecordDialog.open(payload);
    },
    async submit() {
      let validationResult = true;
      if (this.editType != "CLEAR")
        validationResult = await this.$refs[this.selectedField].validate();
      if (this.selectedField && validationResult) {
        this.$emit("submit", {
          type: this.editType,
          data: this.recordData,
          fieldId: this.selectedField,
        });
      }
    },
    handleMemberClick(val) {
      let type = this.membersMenuPayload.fieldInfo.properties.fieldType;
      let fieldId = this.membersMenuPayload.fieldInfo.fieldId;
      if (!this.recordData[fieldId]) {
        this.$set(this.recordData, fieldId, [val]);
      } else {
        if (type == "single") {
          this.recordData[fieldId] = [val];
        } else {
          let pos = this.recordData[fieldId].findIndex(
            (ee) => ee._id == val._id
          );
          if (pos == -1) {
            this.recordData[fieldId].push(val);
          } else {
            this.recordData[fieldId].splice(pos, 1);
          }
        }
      }
    },
  },
};
</script>

<style>
.bulkEditRadio.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.bulk-edit-dialog-content .input-widget-container-title {
  display: none !important;
}
</style>
