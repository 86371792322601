<template>
  <FormElement
    :label="$t('workflow.modules.generateFile.label.format')"
    name="format"
  >
    <v-radio-group
      mandatory
      hide-details
      dense
      :value="value"
      @change="changed"
    >
      <v-radio
        v-for="option in options"
        v-bind="option"
        :key="option.value"
      ></v-radio>
    </v-radio-group>
  </FormElement>
</template>
<script>
import FormElement from "../shared/formElement";
import { SET_SETTINGS } from "@/store/modules/workflow/action_types";

export default {
  props: {
    value: String,
  },
  components: {
    FormElement,
  },
  data() {
    return {
      options: [
        {
          label: "docx",
          value: "docx",
        },
        {
          label: "pdf",
          value: "pdf",
        },
      ],
    };
  },
  methods: {
    changed(v) {
      this.$store.dispatch(SET_SETTINGS, { format: v });
    },
  },
};
</script>
