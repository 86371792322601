<template>
  <div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.selectDate')"
        name="source"
      >
        <DateSelect
          :value="source"
          fieldType="date"
          optionMultipleOutput="single"
          @valueChanged="v => dateChanged('source', v)"
        />
      </FormElement>
    </div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.format')"
        :hints="$t('workflow.modules.operation.remark.format')"
        name="format"
      >
        <ComboBox
          :value="format || null"
          :options="formatOptions"
          :placeholder="$t('workflow.modules.operation.placeholder.selectFormat')"
          valueFormat="id"
          @changed="formatChanged"
        />
      </FormElement>
    </div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.quation')"
      >
        <div>
          <div class="workflow-setting-form-waiting-set-remark">
            <span
              v-html="$t('workflow.modules.operation.remark.dateQuation')"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  :style="{ color: $vuetify.theme.themes.light.primary }"
                  v-text="$t('workflow.modules.operation.label.checkUnit')"
                />
              </template>
              <span v-html="$t('workflow.modules.operation.remark.checkUnitKey')" />
            </v-tooltip>
          </div>
          <FreeInput
            :value="expression"
            :fields="fields"
            fieldType="number"
            noNewLine
            @itemsChanged="itemsChanged"
            @valueChanged="valueChanged"
          />
        </div>
      </FormElement>
    </div>
  </div>
</template>

<script>
import ComboBox from './../../../form/comboBox/';
import FormElement from './../shared/formElement';
import FreeInput from './../shared/freeInput';
import DateSelect from './../shared/dateSelect';
import Mixin from '../mixin';
import SettingMixin from './mixin';
import SettingUtils from '@/pages/admin/workflow/utils/setting';
// import {
//   SET_SETTINGS,
// } from '@/store/modules/workflow/action_types';

export default {
  mixins: [SettingMixin, Mixin],
  props: {
    expression: String,
    fields: Array,
    format: String,
    source: [String, Object]
  },
  data() {
    return {
      unit: 1,
      formatOptions: [
        {
          id: 'datetime',
          label: this.$t('workflow.modules.operation.label.calculateUnit.datetime')
        },
        {
          id: 'date',
          label: this.$t('workflow.modules.operation.label.calculateUnit.date')
        },
        {
          id: 'time',
          label: this.$t('workflow.modules.operation.label.calculateUnit.time')
        }
      ],
    };
  },
  components: {
    ComboBox,
    FormElement,
    FreeInput,
    DateSelect,
  },
  watch: {
    expression: {
      handler(v) {
        const fields = SettingUtils.normalizeFormulaFields(this.fields, this.expression);
        console.log(fields);
        this.changed('fields', fields);
      },
      deep: true
    },
  },
};

</script>

<style scoped>

</style>
