const dialogMixin = {
  methods: {
    showSuccess (msgTag) {
      const vm = this
      vm.$toast.open({
        message: vm.$t(msgTag),
        type: 'success'
      })
    }
  }
}

export default dialogMixin
