<template>
  <div>
     <div class="workflow-shortview-worksheet">
      <span>
        {{ 
          $t(`workflow.modules.actionProcess.label.buttonLabel`, {
            label: actionButton.name,
          })
        }}
      </span>
    </div>
    <div class="w-e-datasource-link" :style="{ color: $vuetify.theme.themes.light.primary }">
      {{ 
          $t(`workflow.modules.actionProcess.label.pressButton`, {
            label: actionButton.name,
          })
        }}
    </div>
   
    <span class="w-e-source">
      <!-- {{ 
        $t('workflow.modules.subWorkflow.label.when', {
          source: parentWorkflowNode.name
        })
      }}
      <b>
        {{ $t('workflow.modules.subWorkflow.label.sourcePoint', {
            source: parentWorkflowNode.name
          })
        }}
      </b>
      {{ $t('workflow.modules.subWorkflow.label.trigger')
      }} -->
    </span>
  </div>
</template>

<script>
import { get } from 'lodash';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    node: Object,
  },
  computed: {
    actionButton() {
      const { properties } = this.node;
      return get(properties, 'fromAction', '');
    },
  },
};

</script>

<style scoped>
  .w-e-datasource-link {
    font-size: 13px;
    margin-top: 5px;
  }
</style>
