<template>
  <div class="number-rule-item">
      <div class="ml-6 mt-3" style="font-size: 13px">{{$t('widgets.properties.'+rule.rulesOption)}}</div>
      <div v-if="rule.rulesOption=='id'">
          <div class="d-flex">
              <v-icon class="drag-handle" size="20">mdi-format-line-spacing</v-icon>
              <v-text-field
                  :value="`${$t('widgets.properties.'+rule.value.numberingOption)}, ${$t('widgets.properties.start')+':'+rule.value.start}, ${$t('widgets.properties.' + rule.value.periodOption)}`"
                  outlined
                  dense
                  hide-details
                  style="pointer: cursor; font-size:  13px"
              >
                  <template v-slot:append>
                      <v-icon size="18" @click="$emit('editIdRule')">
                          mdi-pencil
                      </v-icon>
                  </template>
              </v-text-field>
          </div>
      </div>
      <div v-else-if="rule.rulesOption=='fixedLengthText'">
          <div class="d-flex">
              <v-icon class="drag-handle" size="20">mdi-format-line-spacing</v-icon>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  style="pointer: cursor; font-size:  13px"
                  v-model="rule.value"
              >
              </v-text-field>
               <v-icon :ripple="false" size="20" @click="deleteRule(index)">mdi-delete</v-icon>
          </div>
      </div>
      <div v-else-if="rule.rulesOption=='createdAt'">
          <div class="d-flex">
              <v-icon class="drag-handle" size="20">mdi-format-line-spacing</v-icon>
              <v-select :height="32"
                    v-model="rule.value"
                    dense outlined hide-details
                    :items="dateFormatOptions"
                    item-value="value"
                    item-text="value"
                    :menu-props="{top: false, offsetY: true, contentClass: 'denseList'}"
                />
              <v-icon size="20" @click="deleteRule(index)">mdi-delete</v-icon>
          </div>
      </div>
      <div v-else>
          <div class="d-flex">
              <v-icon class="drag-handle" size="20">mdi-format-line-spacing</v-icon>
              <v-select :height="32"
                        v-model="rule.value"
                        :menu-props="{top: false, offsetY: true, contentClass: 'denseList'}"
                        dense outlined hide-details
                        :items="availableFieldInfos"
                        item-text="label"
                        item-value="fieldId">
              </v-select>
              <v-icon size="20" @click="deleteRule(index)">mdi-delete</v-icon>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    rule: Object,
    index: Number,
    options: Array,
    availableFieldInfos: Array
  },
  computed: {
    dateFormatOptions () {
      return this.options.dateFormatOptions
    }
  },
  methods: {
    deleteRule (index) {
      this.$emit('deleteRule', index)
    }
  }
}
</script>

<style>
.drag-handle {
    cursor: pointer;
}
</style>
