const menu = [
  {captionTag: 'menu.renameAndUpdateIcon', icon: 'mdi-file-document-edit', command: 'showInfo', permission: 100},
  // {caption: '編輯', icon: 'edit', command: 'edit'},
  {captionTag: 'menu.duplicate', icon: 'mdi-content-copy', command: 'duplicate', permission: 100},
  {captionTag: 'menu.delete', icon: 'mdi-trash-can', command: 'delete', permission: 100},
  {captionTag: 'menu.sendApp', icon: 'mdi-rocket', command: 'sendApp', permission: 100}
  // ,
  // {captionTag: 'buttons.publish', icon: 'mdi-publish', command: 'publish', permission: 1000}
]

export default menu
