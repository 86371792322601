
const mixin = {
    methods:{
        updateOption(option){
            this.option = option
        },
        updateChartDom(chartDom){
            this.chartDom = chartDom
        },
        updateChart(chart){
            this.chart = chart
        },
        updateDataX(data){
            this.dataSetups.dataX = data
        },
        updateDataY(data){
            this.dataSetups.dataY = data
        },
        updateDataG(data){
            this.dataSetups.dataG = data
        },
        update_displayCol(val){
            this.styleSetups.total.displayCol = val
        },
        update_bl_displayTotal(val){
            this.styleSetupToggles.bl_displayTotal = val
        },
        update_totalRule(val){
            this.styleSetups.total.totalRule = val
        },
        update_totalTitle(val){
            this.styleSetups.total.totalTitle = val
        },
        update_bl_legend(val){
            this.styleSetupToggles.bl_legend = val
            this.option.legend = {}
            if(!val)
                delete this.option.legend;
            this.option && this.chart.setOption(this.option, true)
        },
        update_legendPos(val, bl_reset = true){
            this.styleSetups.legend.legendPos = val
            if(this.styleSetupToggles.bl_legend){
                delete this.option.legend.top
                delete this.option.legend.left
                delete this.option.legend.orient
                switch(val){
                    case 'top':
                        this.option.legend.top = 'top'
                        this.option.legend.left = 'center'
                        this.option.series[0].top="15%"
                        break
                    case 'topLeft':
                        this.option.legend.top = 'top'
                        this.option.legend.left = 'left'
                        this.option.series[0].top="15%"
                        break
                    case 'topRight':
                        this.option.legend.top = 'top'
                        this.option.legend.left = 'right'
                        this.option.series[0].top="15%"
                        break
                    case 'left':
                        this.option.legend.top = 'middle'
                        this.option.legend.left = 'left'
                        this.option.legend.orient = "vertical"
                        break
                    case 'right':
                        this.option.legend.top = 'middle'
                        this.option.legend.left = 'right'
                        this.option.legend.orient = "vertical"
                        break
                    case 'bottom':
                        this.option.legend.top = 'bottom'
                        this.option.legend.left = 'center'
                        this.option.series[0].top="2%"
                        break
                    case 'bottomLeft':
                        this.option.legend.top = 'bottom'
                        this.option.legend.left = 'left'
                        this.option.series[0].top="2%"
                        break
                    case 'bottomRight':
                        this.option.legend.top = 'bottom'
                        this.option.legend.left = 'right'
                        this.option.series[0].top="2%"
                        break
                }
                this.option.legend.padding = [40,5,20,5]
                this.option.series[0].height = "80%"
                this.option.series[0].width = "100%"

                this.option.legend.itemGap = 5
                if(bl_reset)
                    this.option && this.chart.setOption(this.option, true)
            } else {
                this.option.legend = null
            }
        },
        update_bl_xAxis(val){
            this.styleSetupToggles.bl_xAxis = val
            if(val){
                this.setXAxisLabel(this.styleSetups.xAxis.bl_xLabel)
                this.setXAxisRotate(this.styleSetups.xAxis.bl_xRotate)
                this.setXAxisTitle(this.styleSetups.xAxis.bl_xTitle, this.styleSetups.xAxis.xTitle)
            }else{
                this.setXAxisLabel(false)
                this.setXAxisRotate(false)
                this.setXAxisTitle(false, '')
            }
            this.option && this.chart.setOption(this.option, true)
        },
        update_bl_xLabel(val){
            this.styleSetups.xAxis.bl_xLabel = val
            if(!this.styleSetupToggles.bl_xAxis) return
            this.setXAxisLabel(this.styleSetups.xAxis.bl_xLabel)
            this.option && this.chart.setOption(this.option, true)
        },
        update_bl_xRotate(val){
            this.styleSetups.xAxis.bl_xRotate = val
            if(!this.styleSetupToggles.bl_xAxis) return
            this.setXAxisRotate(this.styleSetups.xAxis.bl_xRotate)
            this.option && this.chart.setOption(this.option, true)
        },
        update_bl_xTitle(val){
            this.styleSetups.xAxis.bl_xTitle = val
            if(!this.styleSetupToggles.bl_xAxis) return
            this.setXAxisTitle(this.styleSetups.xAxis.bl_xTitle, this.styleSetups.xAxis.xTitle)
            this.option && this.chart.setOption(this.option, true)
        },
        update_xTitle(val){
            this.styleSetups.xAxis.xTitle = val
            if(!this.styleSetupToggles.bl_xAxis) return
            if(!this.styleSetups.xAxis.bl_xTitle) return
            this.setXAxisTitle(this.styleSetups.xAxis.bl_xTitle, this.styleSetups.xAxis.xTitle)
            this.option && this.chart.setOption(this.option, true)
            this.option && this.chart.setOption(this.option, true)
        },
        update_bl_yAxis(val){
            this.styleSetupToggles.bl_yAxis = val
            if(val){
                this.setYAxisScale(this.styleSetups.yAxis.bl_displayScale, this.styleSetups.yAxis.lineStyle)
                this.setYAxisTitle(this.styleSetups.yAxis.bl_yTitle, this.styleSetups.yAxis.yTitle)
                this.setYAxisFilter(this.styleSetups.yAxis.yMin, this.styleSetups.yAxis.yMax)
            }else{
                this.setYAxisScale(false, 'solid')
                this.setYAxisTitle(false, '')
                this.setYAxisFilter(null, null)
            }
            this.option && this.chart.setOption(this.option, true)
        },
        update_bl_displayScale(val){
            this.styleSetups.yAxis.bl_displayScale = val
            if(!this.styleSetupToggles.bl_yAxis) return
            this.setYAxisScale(this.styleSetups.yAxis.bl_displayScale, this.styleSetups.yAxis.lineStyle)
            this.option && this.chart.setOption(this.option, true)
        },
        update_lineStyle(val){
            this.styleSetups.yAxis.lineStyle = val
            if(!this.styleSetupToggles.bl_yAxis) return
            if(!this.styleSetups.yAxis.bl_displayScale) return
            this.setYAxisScale(this.styleSetups.yAxis.bl_displayScale, this.styleSetups.yAxis.lineStyle)
            this.option && this.chart.setOption(this.option, true)
        },
        update_bl_yTitle(val){
            this.styleSetups.yAxis.bl_yTitle = val
            if(!this.styleSetupToggles.bl_yAxis) return
            this.setYAxisTitle(this.styleSetups.yAxis.bl_yTitle, this.styleSetups.yAxis.yTitle)
            this.option && this.chart.setOption(this.option, true)
        },
        update_yTitle(val){
            this.styleSetups.yAxis.yTitle = val
            if(!this.styleSetupToggles.bl_yAxis) return
            if(!this.styleSetups.yAxis.bl_yTitle) return
            this.setYAxisTitle(this.styleSetups.yAxis.bl_yTitle, this.styleSetups.yAxis.yTitle)
            this.option && this.chart.setOption(this.option, true)
        },
        update_yMin(val){
            this.yMin = val
            if(!this.styleSetupToggles.bl_yAxis) return
            this.setYAxisFilter(this.styleSetups.yAxis.yMin, this.styleSetups.yAxis.yMax)
            this.option && this.chart.setOption(this.option, true)
        },
        update_yMax(val){
            this.styleSetups.yAxis.yMax = val
            if(!this.styleSetupToggles.bl_yAxis) return
            this.setYAxisFilter(this.styleSetups.yAxis.yMin, this.styleSetups.yAxis.yMax)
            this.option && this.chart.setOption(this.option, true)
        },
        update_bl_label(val){
            this.styleSetupToggles.bl_label = val;
            this.update_bl_showData(val, false);
            this.update_bl_showLabel(val, false);
            this.update_bl_showPercent(val, false);
            this.setStyleTag(val);
            this.option && this.chart.setOption(this.option, true);
        },

        update_bl_showData(val, bl_reset = true){
            this.styleSetups.label.bl_showData = val
            this.setStyleTag(this.styleSetups.label.bl_showData)
            if(bl_reset)
                this.option && this.chart.setOption(this.option, true)
        },
        update_bl_showLabel(val, bl_reset = true){
            this.styleSetups.label.bl_showLabel = val
            this.setStyleTag(this.styleSetups.label.bl_showLabel)
            if(bl_reset)
                this.option && this.chart.setOption(this.option, true)
        },
        update_bl_showPercent(val, bl_reset = true){
            this.styleSetups.label.bl_showPercent = val
            this.setStyleTag(this.styleSetups.label.bl_showPercent)
            if(bl_reset)
                this.option && this.chart.setOption(this.option, true)
        },
        update_bl_filter(val){
            this.styleSetupToggles.bl_filter = val
            if(val){
                let vm = this
                var xData = JSON.parse(JSON.stringify(this.dataSetups.dataX))
                var yData = JSON.parse(JSON.stringify(this.dataSetups.dataY))
        
                let start = 1
                let end = xData.length
                if(vm.styleSetups.filter.dataFilterStart != null){
                    if(vm.styleSetups.filter.dataFilterStart>0 && vm.styleSetups.filter.dataFilterStart<=xData.length)
                        start = vm.styleSetups.filter.dataFilterStart
                }
                if(vm.styleSetups.filter.dataFilterEnd != null){
                    if(vm.styleSetups.filter.dataFilterEnd>0 && vm.styleSetups.filter.dataFilterEnd<=xData.length)
                        end = vm.styleSetups.filter.dataFilterEnd
                }
                this.option.xAxis[0].data = xData.slice(start-1, end)
                this.option.yAxis.forEach((item, index, array)=>{
                    array[index] = yData.slice(start-1, end)
                })
                this.option && this.chart.setOption(this.option, true)
            }else{
                let vm = this
                this.option.xAxis[0].data = vm.dataSetups.dataX
                this.option.yAxis.forEach((item, index, array)=>{
                    array[index] = vm.dataSetups.dataY[index]
                })
                this.option && this.chart.setOption(this.option, true)
            }
        },
        update_dataFilterStart(val){
            this.styleSetups.filter.dataFilterStart = val
            if(this.styleSetupToggles.bl_filter){
                let vm = this
                var xData = JSON.parse(JSON.stringify(this.dataSetups.dataX))
                var yData = JSON.parse(JSON.stringify(this.dataSetups.dataY))
        
                let start = 1
                let end = xData.length
                if(vm.styleSetups.filter.dataFilterStart != null){
                    if(vm.styleSetups.filter.dataFilterStart>0 && vm.styleSetups.filter.dataFilterStart<=xData.length)
                        start = vm.styleSetups.filter.dataFilterStart
                }
                if(vm.styleSetups.filter.dataFilterEnd != null){
                    if(vm.styleSetups.filter.dataFilterEnd>0 && vm.styleSetups.filter.dataFilterEnd<=xData.length)
                        end = vm.styleSetups.filter.dataFilterEnd
                }
                this.option.xAxis[0].data = xData.slice(start-1, end)
                this.option.yAxis.forEach((item, index, array)=>{
                    array[index] = yData.slice(start-1, end)
                })
                this.option && this.chart.setOption(this.option, true)
            }
        },
        update_dataFilterEnd(val){
            this.styleSetups.filter.dataFilterEnd = val
            if(this.styleSetupToggles.bl_filter){
                let vm = this
                var xData = JSON.parse(JSON.stringify(this.dataSetups.dataX))
                var yData = JSON.parse(JSON.stringify(this.dataSetups.dataY))
        
                let start = 0
                let end = xData.length
                if(vm.styleSetups.filter.dataFilterStart != null){
                    if(vm.styleSetups.filter.dataFilterStart>=0 && vm.styleSetups.filter.dataFilterStart<=xData.length)
                        start = vm.styleSetups.filter.dataFilterStart
                }
                if(vm.styleSetups.filter.dataFilterEnd != null){
                    if(vm.styleSetups.filter.dataFilterEnd>=0 && vm.styleSetups.filter.dataFilterEnd<=xData.length)
                        end = vm.styleSetups.filter.dataFilterEnd
                }
                this.option.xAxis[0].data = xData.slice(start-1, end)
                this.option.yAxis.forEach((item, index, array)=>{
                    array[index] = yData.slice(start-1, end)
                })
                this.option && this.chart.setOption(this.option, true)
            }
        },
        update_dateOpt(val){
            this.dateOpt = val
        }, 
        update_dateOptVal(val){
            this.dateOptVal = val
            this.FetchData()
        },
        update_selectedDateField(val){
            this.selectedDateField = val
            this.FetchData()
        },
        update_selectedView(val){
            this.selectedView = val
        },
        update_selectedChart(val){
            if(val != this.selectedChart){
                this.selectedChart = val
                switch(val){
                    case 'BAR':
                        this.styleSetups.chartFigure = 'vertical'
                    break
                    case 'LINE':
                        this.styleSetups.chartFigure = 'curve'
                    break
                    case 'RADAR':
                    break
                    case 'PIE':
                        this.styleSetups.chartFigure = 'pie'
                    break
                    case 'FUNNEL':
                        this.styleSetups.chartFigure = 'vertical'
                    break
                }
                this.FetchData()
            }
        },
        update_chartFigure(val){
            this.styleSetups.chartFigure = val
            this.setChartFigure(val)
        },
        update_selectedHidden(val){
            this.selectedHidden = val
        },
        update_tableFooters(val){
            this.styleSetups.summary.tableFooters = val
            this.FetchData()
        },
        removeY(index){
            this.dataSetups.arrY.splice(index, 1)
            if(this.dataSetups.arrG.length) {
                if(this.selectedChart!='TABLE')
                    this.dataSetups.arrG.splice(0,1)
            }
            this.FetchData()

            let pos = -1
            let vm = this
            if(['childTable', 'relatedRecord'].includes(vm.styleSetups.total.displayCol.type)){
                pos = vm.dataSetups.arrY.findIndex(item=>{
                    return (item._id == vm.styleSetups.total.displayCol._id && 
                        item.relatedId == vm.styleSetups.total.displayCol.relatedId)
                })
            }else{
                pos = vm.dataSetups.arrY.findIndex(item=>item._id == vm.styleSetups.total.displayCol._id)
            }
            if(pos==-1){
                vm.styleSetups.total.displayCol = {
                    _id: 'all', 
                    type: 'all', 
                    label: vm.$t('chart.all')
                }
            }
        },
        removeX(index){
            this.dataSetups.arrX.splice(index, 1)
            this.FetchData()
        },
        removeG(index){
            this.dataSetups.arrG.splice(index, 1)
            this.FetchData()
        },
        remove2Y(index){
            this.dataSetups.arr2Y.splice(index, 1)
            this.FetchData()
        },
        update_filters(val){
            this.dataSetups.filters = JSON.parse(JSON.stringify(val))
        },
        update_bl_summary(val){
            this.styleSetupToggles.bl_summary = val
            this.FetchData()
        }
    }
  }
  
  export default mixin
  