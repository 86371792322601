<template>
  <v-card width="960">
    <v-card-title>
      {{ $t("publicQuery.publicQuery") }}
    </v-card-title>
    <loading-icon v-if="loading" />
    <v-card-text v-else>
      <public-query-row
        v-for="publicQuery in publicQueries"
        :key="publicQuery._id"
        :publicQuery="publicQuery"
        @commandHandler="onCommandHandler"
        @activated="track({
          key:'plus_public_form_activiate',
          data:{ is_default_setting:false }
        })"
      />
      <v-btn
        color="primary"
        rounded
        @click="
          selectedQueryId = '';
          showDialog = true;
        "
        >{{ $t("buttons.new") }}<v-icon>mdi-plus</v-icon></v-btn
      >
    </v-card-text>
    <public-query-setting-dialog
      v-model="showDialog"
      v-if="showDialog"
      :publicQuery="selectedQuery"
      @add="addQuery"
      @update="updateQuery"
    />
    <confirm-delete-dialog ref="ConfirmDeleteDialog" />
    <confirm-dialog ref="confirmDialog" />
  </v-card>
</template>
<script>
import LoadingIcon from "@/components/global/loadingIcon";
import publicQueryRow from "./comp/publicQueryRow";
import PublicQuerySettingDialog from "./comp/publicQuerySettingDialog";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import confirmDialog from "@/components/dialogs/ConfirmDialog";
import mixin from "./mixin";

export default {
  components: {
    LoadingIcon,
    publicQueryRow,
    PublicQuerySettingDialog,
    ConfirmDeleteDialog,
    confirmDialog,
  },
  mixins:[mixin],
  data() {
    return {
      loading: false,
      showDialog: false,
      publicQueries: [],
      selectedQueryId: "",

      type:"QUERY"
    };
  },
  computed: {
    selectedQuery() {
      if (!this.selectedQueryId) return null;
      else
        return this.publicQueries.find(
          (query) => query._id === this.selectedQueryId
        );
    },
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "edit":
          this.selectedQueryId = payload.id;
          this.showDialog = true;
          this.track({key:"plus_public_publishing_function", data:{
            function_button: this.$sensors.events.plus_public_publishing_function.SETTINGS
          }});
          break;
        case "delete":
          this.deleteQuery(payload.id);
          break;
        case "update":
          this.updateQuery(payload);
          break;
        case "refreshURL":
          this.refreshURL(payload.publicQuery);
          break;
        case "copyUrl":
          this.track({key:"plus_public_publishing_function", data:{
            function_button: this.$sensors.events.plus_public_publishing_function.COPY_LINK
          }});
        break;
        case "downloadUrl":
          this.track({key:"plus_public_publishing_function", data:{
            function_button: this.$sensors.events.plus_public_publishing_function.DOWNLOAD
          }});
        break;
        case "openURL":
          this.track({key:"plus_public_publishing_function", data:{
            function_button: this.$sensors.events.plus_public_publishing_function.OPEN_LINK
          }});
        break;
      }
    },
    refreshURL(query) {
      this.$refs.confirmDialog
        .open(
          this.$t("publicForm.refreshURL"),
          this.$t("publicForm.refreshURL")
        )
        .then((confirm) => {
          if (confirm) {
            const postData = {
              urlCommand: "/publicQuery/refreshUrl",
              data: {
                id: query._id,
                appId: this.$route.params.id,
                formId: this.$route.params.itemId,
              },
            };

            this.$store.dispatch("AUTH_POST", postData).then((res) => {
              if (res.result) {
                query.urlId = res.id;
              }
            });
          }
        });
    },
    getQueries() {
      this.loading = true;
      const getParams = {
        urlCommand:
          "/publicQuery/getQueriesByFormId?appId=" +
          this.$route.params.id +
          "&formId=" +
          this.$route.params.itemId,
      };
      this.$store.dispatch("AUTH_GET", getParams).then((res) => {
        this.publicQueries = res;

        this.loading = false;
      });
    },
    addQuery(data) {
      const postData = {
        urlCommand: "/publicQuery/store",
        data: {
          appId: this.$route.params.id,
          formId: this.$route.params.itemId,
          ...data,
        },
      };

      this.$store
        .dispatch("AUTH_POST", postData)
        .then((res) => {
          if (res) {
            this.publicQueries.push(res);
            this.track({key:"plus_add_public_form"});
          }
        })
        .finally(() => {
          this.showDialog = false;
        });
    },
    updateQuery(payload) {
      const postData = {
        urlCommand: "/publicQuery/update",
        data: {
          appId: this.$route.params.id,
          formId: this.$route.params.itemId,
          viewId: payload.viewId,
          queryFields: payload.queryFields,
          title: payload.title,
          id: payload._id,
          active: payload.active,
        },
      };

      this.$store
        .dispatch("AUTH_POST", postData)
        .then((res) => {
          if (res) {
            const index = this.publicQueries.findIndex(
              (query) => query._id === res._id
            );

            if (index > -1) {
              this.publicQueries.splice(index, 1, res);
            }
          }
        })
        .finally(() => {
          this.showDialog = false;
        });
    },
    deleteQuery(id) {
      this.$refs.ConfirmDeleteDialog.confirm(() => {
        const postData = {
          urlCommand: "/publicQuery/delete",
          data: {
            appId: this.$route.params.id,
            formId: this.$route.params.itemId,
            id,
          },
        };

        this.$store.dispatch("AUTH_POST", postData).then((res) => {
          if (res.result) {
            const index = this.publicQueries.findIndex(
              (query) => query._id === id
            );
            if (index > -1) this.publicQueries.splice(index, 1);
          }
        });
      });
    },
  },
  async mounted() {
    this.getQueries();
  },
};
</script>
<style scoped></style>
