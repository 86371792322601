<template>
    <v-dialog v-if="showingDialog" v-model="showingDialog" width="400">
        <v-card>
            <dialog-header
                :icon="icon"
                :label="type=='relatedRecord'?$t('chart.dialog.selectRelatedField'):$t('chart.dialog.selectChildTableField')"
                @close="close"
            />
            <v-card-text class="pt-5" style="height:550px; overflow:scroll">
                <div style="font-size:13px">
                    {{type=='relatedRecord'?
                        $t('chart.dialog.selectFromRelated', {table: label}):
                        $t('chart.dialog.selectFromChild', {table: label})
                    }}
                </div>
                <v-container class="px-0">
                    <v-row>
                        <v-col 
                            cols="6"
                            class="px-1 py-2" 
                            v-for="field in fields" 
                            :key="field.fieldId"
                        >
                            <v-hover v-slot="{hover}">
                                <div 
                                    @click="handleFieldClick(field.fieldId)"
                                    :class="selected.includes(field.fieldId)?'teal lighten-2 white--text':'grey lighten-4'"
                                    class="py-2 px-4" 
                                    style="font-size:12px; cursor:pointer;overflow: hidden;white-space: nowrap; text-overflow: ellipsis"
                                    :style="hover?'font-weight: bold':''"
                                >
                                    {{field.label}}
                                </div>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <dialog-actions
                @onCommand="onCommandHandler"
                :buttons="['close', 'confirm']"
            />
        </v-card>
    </v-dialog>
</template>

<script>
    import dialogHeader from '@/components/dialogs/comps/DialogHeader'
    import dialogActions from '@/components/dialogs/comps/DialogActions'
    export default {
        components:{
            dialogHeader,
            dialogActions
        },
        props:{
            value: Boolean,
        },
        computed:{
            showingDialog:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            }
        },
        data(){
            return{
                fields: [],
                label: "",
                selected: [],
                slot: '', 
                callback: null,
                field: null,
                type: ''
            }
        },
        methods:{
            handleFieldClick(fieldId){
                if(this.selected.includes(fieldId)){
                    var index = this.selected.indexOf(fieldId)
                    this.selected.splice(index,1)
                }else{
                    this.selected.push(fieldId)
                }
            },
            close(){
                this.showingDialog = false
            },
            onCommandHandler(payload){
                // console.log("payload", payload)
                switch(payload.command){
                    case 'close':
                        this.showingDialog = false
                    break
                    case 'confirm':
                        var items = this.selected.map(item=>{
                            return this.fields.find(field=>field.fieldId == item)
                        })
                        this.callback({
                            command: this.slot, 
                            items:items, 
                            field:this.field
                        })
                        this.showingDialog = false
                    break
                }
            }
        },
    }
</script>