<template>
  <div>
    <router-view></router-view>
    <div class="moduleList-wrapper">
      <div class="module" v-for="(_module, index) in modules" :key="index">
        <div class="v-subheader text-subHeader module-title">
          {{ _module.title }}
        </div>
        <v-list
          v-for="menuItem in _module.menu"
          :key="menuItem._id"
          class="py-0"
        >
          <v-list-item class="py-0" @click="selectItem(menuItem)">
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ menuItem.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    modules() {
      return this.$store.getters.currentApp?.modules || [];
    },
    app() {
      return this.$store.getters.currentApp;
    },
  },
  mounted() {
    //const appId = this.$route.params.id;
    //const menuItemId = this.$route.params.itemId;

    //this.loadApp(appId, menuItemId);
  },
  methods: {
    // loadApp(appId, menuItemId) {
    //   const vm = this;
    //   // console.log('Apps.loadApp => FETCH_APP:: appId = ' + appId)
    //   // console.log('Apps.loadApp => FETCH_APP:: menuItemId = ' + menuItemId)
    //   vm.$store
    //     .dispatch("FETCH_APP", { appId: appId, include: "menu" })
    //     .then((response) => {
    //       const app = response;
    //       // console.log('Apps.loadApp FETCH_APP.then  response: ', response)
    //       const moduleIndex = vm.getActiveModuleIndex(app, menuItemId);
    //       // vm.showingModuleIndex = moduleIndex

    //       // console.log('loadApp => SET_CURRENT_MODULE_INDEX: moduleIndex = ' + moduleIndex)
    //       vm.$store.dispatch("SET_CURRENT_MODULE_INDEX", moduleIndex);
    //       // console.log('loadApp before SET_SELECTED_MENU_ITEM_ID: selectedMenuItem = ', vm.selectedMenuItem)

    //       // console.log('loadApp => SET_SELECTED_MENU_ITEM_ID: menuItemId: ', menuItemId)
    //       vm.$store.dispatch("SET_SELECTED_MENU_ITEM_ID", menuItemId);
    //     })
    //     .catch((err) => {
    //       console.log("Apps.loadApp :: err: ", err);
    //     });
    // },
    selectItem(item) {
      this.$store.dispatch("SET_CURRENT_FORM_ICON", item.icon);
      this.$router.push({
        path: `/apps/${this.app._id}/${item.type}s/${item._id}`,
      });
    },
  },
};
</script>
