import {
  TOGGLE_MODULE_MENU,
  PICK_MODULE,
  PICK_EVENTTYPE,
  SET_MODULE_MENU_STATUS,
  PICKING_MODULE,
  COPYING_NODE,
  ADD_COPIED_NODE,
  REMOVE_COPIED_NODE,
  PREVIEW_ORCHESTRATION,
  EDITING_NODE,
  SCALE_WORKFLOW_LAYOUT,
  FETCH_MODULES_LIST,
  OFF_PREVIEW_ORCHESTRATION,
  RESET_SCALE_WORKFLOW_LAYOUT,
  UPDATE_WORKFLOW_EDITING_NODE,
  SHORTVIEW_ORCHESTRATION,
  OFF_SHORTVIEW_ORCHESTRATION,
  // ETCH_MODULES_LIST_SCUEED,
  // FETCH_MODULES_LIST_FAILED,
  RESET_EDITOR,
} from './action_types';
import {
  RESET_ORCHESTRATION_REVIEW,
} from './../orchestration/action_types';
import NodeUtils from './../../../pages/admin/workflow/utils/node';
// import {
//   has,
//   keys,
// } from 'lodash';

const initialState = () => ({
  moduleOpen: false,
  module: null,
  eventType: null,
  previewMode: false, // once release can preview the whole orchestration
  blockAction: false, // block any action in the current stage (e.g. press edit)
  pickingModule: null,  // wait for picking module node
  editingNode: null,
  copyingNode: false,
  shortMode: false,
  scaleSize: 100,
  copiedNode: new Map(), // Vue2 does not support Map()
  modules: {
    loading: false,
    list: [],
    error: null,
  },
});

const getters = {
  isModuleOpen: state => state.moduleOpen,
  selectedModule: state => state.module,
  getPickingModule: state => state.node,
  eventType: state => state.eventType,
  isCopyingNode: state => state.copyingNode,
  layoutScale: state => state.scaleSize,
  copiedNode: state => state.copiedNode,
  isUIBlocked: state => state.previewMode || state.blockAction,
  isPreviewMode: state => state.previewMode,
  isShortMode: state => state.shortMode,
  pickingFromNode: state => state.pickingModule && state.pickingModule.node,
  pickingFromOffset: state => state.pickingModule && state.pickingModule.offset,
  getEditingNode: state => state.editingNode,
  copiedSize: state => state.copiedNode.size,

  getModules: state => state.modules.list,
  fetchingModules: state => state.modules.loading,
  // keys(state.copiedNode).length,
}

const mutations = {
  resetModuleState (state) {
    const wasState = initialState();
    state.moduleOpen = wasState.moduleOpen;
    state.module = wasState.module;
    state.eventType = wasState.eventType;
    state.blockAction = wasState.blockAction;
    state.previewMode = wasState.previewMode;
    state.scaleSize = wasState.scaleSize,
    state.pickingModule = wasState.pickingModule;
    state.editingNode = wasState.editingNode;
    state.copyingNode = wasState.copyingNode;
    state.copiedNode = new Map(wasState.copiedNode);
  },
  onResetScaleLayout (state, scale) {
    state.scaleSize = scale;
  },
  onPreviewMode (state) {
    state.previewMode = true;
  },
  onShortMode (state) {
    state.shortMode = true;
  },
  offShortMode (state) {
    state.shortMode = false;
  },
  onScaleLayout(state, scale) {
    state.scaleSize += scale;
  },
  offPreviewMode (state) {
    state.previewMode = false;
  },
  toggleModulesMenu (state) {
    state.moduleOpen = !state.moduleOpen;
    state.blockAction = !!state.moduleOpen;
  },
  setModulesMenuStatues (state, moduleOpen) {
    state.moduleOpen = moduleOpen;
    state.blockAction = moduleOpen;
  },
  setPickingModule (state, node) {
    state.pickingModule = node;
  },
  // updateEditingNodeChecking (state, errorNodes={}) {
  //   console.log(errorNodes);
  //   // const { editingNode } = state;
  //   // if (editingNode && errorNodes[editingNode._id]) {
  //   //   const { checkingLog } = errorNodes[editingNode._id];
  //   //   editingNode.checkingLog = checkingLog;
  //   //   editingNode.ready = checkingLog.length;
  //   //   state.editingNode = { ...editingNode };
  //   // }
  // },
  setEditingNode (state, node) {
    state.editingNode = node ? { ...node } : null;
    state.blockAction = !!node;
  },
  updateEditingNode (state, info) {
    const { editingNode } = state;
    NodeUtils.referenceUpdate(editingNode, info);
    state.editingNode = null;
    state.blockAction = false;
  },
  setSelectedModule (state, payload) {
    state.module = payload;
  },
  selectEventType (state, info) {
    state.eventType = info;
  },
  setCopyingNode (state, status) {
    state.copyingNode = status;
    state.blockAction = status;
  },
  addCopiedNode (state, node) {
    const { copiedNode } = state;
    const { id } = node;
    const newCopiedNode = new Map(copiedNode);
    if (!newCopiedNode.has(id)) {
      newCopiedNode.set(id, node);
    } else {
      newCopiedNode.delete(id);
    }
    state.copiedNode = newCopiedNode;
    // if (!has(copiedNode, id)) {
    //   state.copiedNode = { ...copiedNode, [id]: node };
    // } else {
    //   const { copiedNode } = state;
    //   delete copiedNode[node.id];
    //   state.copiedNode = { ...copiedNode };
    // }
  },
  removeCopiedNode (state, node) {
    // if (has(state.copiedNode, node.id)) {
    //   delete state.copiedNode[node.id];
    // }
  },
  cleanCopidNode (state) {
    state.copiedNode = new Map();
  },
  requestFetchingModules(state) {
    state.modules.loading = true;
  },
  fetchedModules(state, list) {
    state.modules.loading = false;
    state.modules.list = list;
  },
  fetchModulesFailed(state, error) {
    state.modules.loading = false;
    state.modules.error = error;
  },
}

const actions = {
  [RESET_EDITOR]({commit}) {
    // console.log('Reset Workflow Tree State');
    commit('resetModuleState');  
  },
  [RESET_SCALE_WORKFLOW_LAYOUT] ({commit}, scale) {
    commit('onResetScaleLayout', scale);
  },
  [SCALE_WORKFLOW_LAYOUT] ({commit}, scale) {
    commit('onScaleLayout', scale);
  },
  [SHORTVIEW_ORCHESTRATION] ({commit}) {
    commit('onShortMode');
  },
  [OFF_SHORTVIEW_ORCHESTRATION] ({dispatch, commit}) {
    commit('offShortMode');
  },
  [PREVIEW_ORCHESTRATION] ({commit}) {
    commit('onPreviewMode');
  },
  [OFF_PREVIEW_ORCHESTRATION] ({dispatch, commit}) {
    commit('offPreviewMode');
    dispatch(RESET_ORCHESTRATION_REVIEW);
  },
  [FETCH_MODULES_LIST] ({dispatch, commit}) {
    commit('requestFetchingModules');
    const options = {
      urlCommand: '/workflow/modules',
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedModules', response);
    }).catch(err => {
      commit('fetchModulesFailed', err.message);
    })
  },
  
  [UPDATE_WORKFLOW_EDITING_NODE] ({commit}, info) {
    commit('updateEditingNode', info);
  },
  
  [COPYING_NODE] ({commit}, status) {
    commit('setCopyingNode', status);
    if (!status) {
      commit('cleanCopidNode');
    }
  },
  [ADD_COPIED_NODE] ({commit}, node) {
    commit('addCopiedNode', node);
  },
  [EDITING_NODE] ({commit}, node) {
    commit('setEditingNode', node);
  },
  // [GET_COPIED_NODE] ({commit}, node) {
  //   commit('getCopiedNode', node);
  // },
  [REMOVE_COPIED_NODE] ({commit}, node) {
    commit('removeCopiedNode', node);
  },
  [TOGGLE_MODULE_MENU] ({commit}) {
    commit('toggleModulesMenu');
  },
  [PICKING_MODULE] ({commit}, node) {
    commit('setPickingModule', node);
  },
  [SET_MODULE_MENU_STATUS] ({commit}, status) {
    commit('setModulesMenuStatues', status);
  },
  [PICK_MODULE] ({ commit }, payload) {
    commit('setSelectedModule', payload)
  },
  [PICK_EVENTTYPE] ({ commit }, payload) {
    commit('eventType', payload)
  }
}

export default {
  state: initialState(),
  getters,
  mutations,
  actions
}
