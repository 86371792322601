<template>
<div class="white">
  <v-app-bar
    dense
    dark
    class="app-header flex-grow-0 d-flex flex-row jusstify-space-between elevation-0"
    style="width:100%;"
  >
    <!-- General Pages -->
    <v-toolbar-title
      class="d-flex flex-row align-center px-0"
      style="z-index:1;"
    >
      <v-btn>
        <v-icon medium>mdi-home</v-icon>
        <!-- <h3
          v-if="!currentApp"
          class="font-weight-regular ml-3"
        >
          {{ $t("general.workbench") }}
        </h3> -->
      </v-btn>      
    </v-toolbar-title>

    <v-app-bar-nav-icon
      style="z-index:1;margin-left:auto;"
      @click.stop="toggleDrawer"
    >
      <v-icon>
        mdi-menu
      </v-icon>
    </v-app-bar-nav-icon>
  </v-app-bar>

  <v-row class="mt-16">
    <div class="w-100 text-center">
      <img class="termination-icon" alt="Vue logo" src="../../assets/fa6-solid_link-slash.png">
      <p>This app has been disabled, please contact the app owner</p>
      <p>此應用已經停用，請聯繫應用擁有人</p>
    </div>
  </v-row>
</div>
</template>

<script>
import EventBus from "@/event-bus.js";

export default {
  name: "AppHeader",
  methods: {
    toggleDrawer() {
      EventBus.$emit("showDrawer");
    }
  },
};
</script>

<style>
.app-header {
  z-index: 10 !important;
}

.app-header button {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.app-header .v-toolbar__content {
  display: flex;
  flex-direction: row;
}
</style>
