<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :isHidden="isHidden"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :tooltipText="description">
      <!-- hideInput only involves in workflow by default false, no infect in inputWidget side -->
    <div class="workflow-customized-form-record-element" :class="{ 'workflow-customize-form-record-hide': hideInput }">
      <div
        v-if="isReadOnly"
        class="ql-editor"
        v-html="inputValue"
      ></div>
      <general-quill
          v-else-if="!isLayout"
          :className="'inputRichEditor'+ ((isReadOnly)?' grey lighten-3':'')"
          :appId="formInfo.appId"
          :formId="formInfo._id"
          :fieldId="fieldInfo._id"
          v-model="inputValue"
          :disabled='isReadOnly'
          @input="val=>inputValue=val.html"
      />
      <general-quill :layout="true" v-else className="inputRichEditor2" v-model="inputValue" ref="quillDialog"/>
      <div 
        v-if="errorMessage"
        class="selection-message-line error--text" style="font-size:12px"
      >
        {{ errorMessage }}
      </div>
    </div>
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'
import GeneralQuill from '@/components/public/GeneralQuill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: "inputWidgetRichText",
  mixins: [mixin],
  components: {
    inputWidgetContainer,
    GeneralQuill
  },
  data() {
    return {
      content: '<h2>I am example.</h2>',
    }
  },
  props:{
    hideInput: Boolean,
  },
  watch:{
    value(){
      this.validate()
    }
  },
  methods:{
    validate(){
      if (this.isReadOnly) return true;
      this.errorMessage = ''
      var valid = !this.rules.some((rule) => rule(this.value) !== true);
      if(!valid){
        let msg = ""
        for (let i = 0; i < this.rules.length && msg === ""; i++) {
          const result = this.rules[i](this.value);
          msg = typeof result === "string" ? result : "";
        }
        this.errorMessage = msg;
      }
      this.hasError = !valid
      return valid
    }
  }
}
</script>

<style>
.is-layout {
  padding-bottom: 0 !important;
}
.inputRichEditor .ql-editor {
  overflow-y: auto;
  max-height: 30vh;
}

.inputRichEditor2 .ql-formats {
  margin: 0px !important;
}
.inputRichEditor2 .ql-editor{
  max-height: 10vh;
}
</style>
