import { getFieldPropertyValue } from "@/helpers/ObjectHelpers"
import { getDefaultCardSettings } from '@/helpers/CardHelpers';
import { get } from "lodash"

const mixin = {
  computed: {


    fieldId () {
      return this.fieldInfo.fieldId
    },
    isMultiple () {
      return this.fieldInfo.properties.relatedRecordQuantity === 'multiple'
    },
    selectionMode () {
      return this.fieldInfo.properties.selectionMode
    },
    dataSource () {
      return this.fieldInfo.properties.dataSource
    },
    displayFormat () { 
      return get(this.fieldInfo, 'properties.displayFormat', 'card');
    },
    isPublic(){
      return ['PublicForm', 'PreviewPublicForm', 'adminPublicPublishing', 'PublicQuery', 'PublicEdit'].includes(this.$route.name)
    } ,  
    tableDisplaySettings () {
      return getFieldPropertyValue(
        this.fieldInfo,
        'tableDisplaySettings',
        {
          fixedFieldIds: [],
          nonFixedFieldIds: []
        }
      )
    },

    tableSelectionSettings () {
      const vm = this
      return vm.fieldInfo ? vm.fieldInfo.properties.tableSelectionSettings : null
    },

    cardSelectionSettings () {
      const vm = this
      // notes:
      // getDefaultCardSettings concerned with the titleField info from related form
      const defaultCardSettings = getDefaultCardSettings(vm.relatedFormInfo)
      return vm.fieldInfo ? vm.fieldInfo.properties.cardSelectionSettings : defaultCardSettings
    },



    // relatedTableInfos () {
    //   return this.$store.getters.relatedTableInfos
    // },

    relatedFormInfo () {
      const vm = this
      var result = null

        if (vm.relatedTableInfos && vm.dataSource) {
          result = vm.relatedTableInfos[vm.dataSource]
        }

      return result
    },

    relatedFieldInfos () {
      return this.relatedFormInfo ? this.relatedFormInfo.fieldInfos : []
    },

    relatedTitleFieldId () {
      const vm = this
      var result = ''
      if (vm.relatedFormInfo && vm.relatedFieldInfos.length > 0) {
        const titleFieldInfo = vm.relatedFieldInfos.find(info => info._id === vm.relatedFormInfo.titleFieldInfoId)
        if (titleFieldInfo) {
          result = titleFieldInfo.fieldId
        }
      }
      return result
    }
    // formInfo () {
    //   return this.$store.getters.currentForm
    // },

    // isParentChildRelation () {
    //   const vm = this
    //   var result = false
    //   if (vm.dataSource) {
    //     result = vm.formInfo._id === vm.dataSource
    //   }
    //   return result
    // },
  },
  methods: {
    loadRelatedRecordData(dataSource, recordIds) {
      const vm = this;
      return vm.$store
        .dispatch("FETCH_RELATED_RECORDS", {
          recordIds: recordIds,
          dataSource: dataSource,
          fieldId: vm.fieldId,
          isPublic:this.isPublic
        })
    },

    saveValueHandler(newSelection, callback) {
      const vm = this;
      var changed = false
      var newInputValue = []
      if (vm.isMultiple) {
        newInputValue = [...vm.inputValue, ...newSelection]
        // console.log('newInputValue: ', newInputValue)
        const newValue = newSelection.filter(val => !vm.inputValue.includes(val))
        if (newValue.length > 0) {
          changed = true
        }
      } else {
        if (newSelection.length > 0) {
          if (vm.inputValue.length > 0) {
            if (vm.inputValue[0] !== newSelection[0]) {
              newInputValue = [newSelection[0]]
              changed = true
            }
          } else {
            newInputValue = [newSelection[0]]
            changed = true
          }
        }
      }
      if (changed) {
        if (typeof callback === 'function') {
          callback(vm.dataSource, newSelection, newInputValue)
        } 
        vm.$forceUpdate();
      }

      if (newInputValue && vm.inputValue !== newInputValue) {
        vm.inputValue = newInputValue;
        vm.loadRelatedRecordData(vm.dataSource, newInputValue, response => {
          if (response && Array.isArray(response) && response.length > 0) {
            if (typeof vm.copyRelatedFields === 'function') {
              if (!vm.multiple) vm.copyRelatedFields(response[0]);
            }
          }
        });
        vm.$forceUpdate();
      }
    },





    // saveInputValue (newSelection) {
    //   const newInputValue = 
    // }
  }
}

export default mixin