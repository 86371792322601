<template>
<panel-base
  :admin="false"
  :settingButton="false"
  :title="$t('menu.toDoCalendar')"
  @click:submit="save"
  :loading="loading">
  <template v-slot:content>
    <calendar-settings-form 
      class="flex-grow-1"
      ref="calendarSettingsForm"
      v-model="userPanelSettings"></calendar-settings-form>
  </template>
</panel-base>
</template>

<script>
import panelMixin from '@/mixins/panelMixin'
import panelBase from '@/components/PanelBase'
import calendarSettingsForm from './comps/CalendarSettingsForm'

export default {
  name: 'calendarSetupPage',
  mixins: [panelMixin],
  components: {
    panelBase,
    calendarSettingsForm
  },
  data () {
    return {
      loading: false,
      userPanelSettings: null
    }
  },
  methods: {
    save () {
      const vm = this
      const hasErrors = vm.$refs.calendarSettingsForm.validateForm()
      if (!hasErrors) {
        vm.userPanelSettings.appId = vm.$store.getters.currentApp._id
        vm.userPanelSettings.teamId = vm.$store.getters.activeTeam._id
        vm.$store.dispatch('UPDATE_CALENDAR_PANEL', {
          data: vm.userPanelSettings
        }).then(
          response => {
            vm.$emit('update', response)
            this.$toast.success(this.$t('messages.savedSuccessfully'), {
              position: 'top-right',
              dismissible: true
            })            
          }
        )
      }
    }
  }
}
</script>