<template>
  <div>
    <PendingSetup
      v-if="!fromAction || !worksheet"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
     <div class="w-e-statement" v-else>
      <TriggerStatement :node="node" />
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import PendingSetup from './../pending';
import TriggerStatement from './trigger';
import Mixin from './../mixin';
import TableSourceView from './../tableSource';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  computed: {
    // dataSource() {
    //   const { properties } = this.node;
    //   return get(properties, 'dataSource', '');
    // },
    worksheet() {
      const { properties } = this.node;
      return get(properties, 'worksheet', '');
    },
    fromAction() {
      const { properties } = this.node;
      return get(properties, 'fromAction', '');
    },
    // parentWorkflowNode() {
    //   const { properties } = this.node;
    //   return get(properties, 'parentWorkflowNode', '');
    // },
    // dataSize() {
    //   return keys(get(this.node, 'properties.data', {})).length;
    // }
  },
  methods: {
    goWorkflow() {
      WorkflowSettingUtils.goBlankWindowWorkflow(this.$router, this.parentWorkflow._id);
    },
  },
  components: {
    PendingSetup,
    TableSourceView,
    TriggerStatement,
  },
};

</script>

<style scoped>
  .w-e-statement {
    font-size: 11px !important;
    margin: 5px 2px 0 2px;
  }
</style>
