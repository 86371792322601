<template>
  <!-- <Drag
    class="drag"
    :disabled="!canRePosition"
    :data="{
      node,
      _REPOS_: true,
      tree,
      offset,
    }"
  > -->
    <Drop
      :accepts-data="() => !isYourChildDependency"
      class="workflow-node-box-drop"
      @dragenter="onDropping"
      @drop="onDroped"
      @dragleave="onDropLeft"
    >
      <div v-if="!isNode && offset === 0" class="workflow-clear-left-border" />
      <div v-if="!isNode && offset === lastOffset" class="workflow-clear-right-border" />
      <div class="workflow-node-box" :style="readytoDropAreaStyle()" :class="{ 'box-to-group-branch': !isNode }">
        <div v-if="!!dropTarget" class="workflow-node-drag-hints">
          {{ $t('workflow.label.drapAndDrop') }}
        </div>
        <NodeHeader
          :node="node"
          :isNode="isNode"
          :offset="offset"
          :tree="tree"
          :walkingNode="walkingNode"
          :noIcon="noIcon"
          :cardStyle="cardStyle"
          v-on="$listeners"
        >
          <template #content="{ tree, node, offset }">
            <ModuleView :tree="tree" :node="node" :offset="offset" />
          </template>
          <!-- content -->
        </NodeHeader>
        <NodeFooter
          :node="node"
          :offset="offset"
          :showAddNode="!(isNode && isYourChildDependency)"
          :dropTarget="dropTarget"
          :picking="isAtPicking"
          :layoutStyle="readyToDropStyle"
          v-on="$listeners"
        />
      </div>
    </Drop>
  <!-- </Drag> -->
</template>

<script>
/*
* Single node for the action
* Terry Chan
* 26/05/2021
*/
import DropMixin from './drop/mixin';
import NodeFooter from './section/footer';
import NodeHeader from './section/header';
import ModuleView from './../view/';
import { Drop, Drag } from "vue-easy-dnd";
import NodeUtils from './../../../utils/node';
import {
  PICKING_MODULE,
  PICK_MODULE,
  // ADD_COPIED_NODE,
} from '@/store/modules/workflow/action_types';
// import {
//   WorkflowNodeIdentify,
// } from './../../../constants/node';
// import {
//   includes,
//   has,
//   // keys,
// } from 'lodash';

export default {
  mixins: [DropMixin],
  props: {
    offset: Number,
    tree: Object,
    walkingNode: Object,
    node: Object,
    isYourChildDependency: Boolean,
    lastOffset: Number,
    noIcon: {
      type: Boolean,
      default: false,
    },
    isNode: {
      type: Boolean,
      default: true,
    },
  },
  methods: {  
    onDroped({ data }) {
      this.dropTarget = null;
      this.$emit('drop', { node: this.node, offset: this.offset }, data);
    },
    // isCondition() {
    //   return NodeUtils.isFork(this.node.nodeType);
    // },
    myNextIsDependsOn({ tree, node, offset }) {
      return NodeUtils.nextHasResultCondition(tree, node, offset);
    },
    cardStyle() {
      if (!this.isNode) {
        return {};
      }
      return {
        'background-color': this.node.color,
      };
    },
    isAtPicking() {
      const { waitForPicking, node } = this;
      return waitForPicking && waitForPicking.id === node.id;
    },
    // normalize(node) {
    //   const { updatedNode } = this;
    //   if (updatedNode && updatedNode._id === node._id) {
    //     node.properties = updatedNode.properties;
    //   }
    //   return node;
    // },
  },
  components: {
    Drop,
    Drag,
    NodeFooter,
    NodeHeader,
    ModuleView,
  },
  computed: {
    canRePosition() {
      return NodeUtils.rePositionSupport(this.node);
    },
    updatedNode() {
      return this.$store.getters.updatedNode;
    },
    waitForPicking() {
      return this.$store.getters.pickingFromNode;
    },
    waitForPickingInfo() {
      return this.$store.state.workflowModules.pickingModule;
    },
    modulePicked() {
      return this.$store.state.workflowModules.module;
    },
  },
  watch: {
    // hook for the module selected
    modulePicked: {
      handler(module) {
        // if the current node is waiting for picking the target module
        if (!!module && this.isAtPicking()) {
          this.$emit('drop', this.waitForPickingInfo, module);
          // this.addingNode = null;
          this.$store.dispatch(PICKING_MODULE, null);
          this.$store.dispatch(PICK_MODULE, null);
        } 
        
      },
      deep: true,
    },
  }
};

</script>

<style scoped>
  
</style>
