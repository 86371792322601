<template>
  <div>
    <v-layout 
      align-center
      justify-start
      class="w-e-parameter-row"
      v-if="parameters.length > 0"
    >
      <div class="w-e-parameter-type">
        {{ $t('workflow.modules.subWorkflow.label.paramType') }}
      </div>
      <div class="w-e-parameter-input">
        {{ $t('workflow.modules.subWorkflow.label.paramName') }}
      </div>
      <div class="w-e-parameter-remove" />
    </v-layout>
    <div v-for="(value, index) in parameters"
      :key="index">
      <v-layout 
        v-if="!value || (value && !value.removed)"
        align-center 
        justify-space-between
        class="w-e-parameter-row"
      >
        <div class="w-e-parameter-type">
          <ComboBox
            :value="value.widget || null"
            :options="fields"
            :prefixIcon="false"
            :showField="true"
            :onlyPrefix="false"
            :onlySuffix="false"
            :isMultiple="false"
            :placeholder="$t('workflow.label.selectParameterType')"
            valueFormat="object"
            :disableBranchNode="true"
            @changed="v => change(v, index)"
            :isBranch="true"
          />
        </div>
        <div class="w-e-parameter-input">
          <v-tooltip
            bottom
            :disabled="value && !value.error"
            transition="slide-y-reverse-transition"
            content-class="w-arrow-control-tool"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                class="workflow-customized-form-record-element v-normal-text-input"
                v-model="value.name"
                dense
                :error="value && !!value.error"
                hide-details
                :autocomplete="false"
                single-line
                @keydown.space.prevent
                outlined
                @input="v => input(v, index)"
                :placeholder="$t('workflow.label.inputParameterName')"
              ></v-text-field>
            </template>
            <span>{{$t('workflow.modules.subWorkflow.message.correctVarName')}}</span>
          </v-tooltip>
        </div>
        <div class="w-e-parameter-remove">
          <v-btn icon @click="remove(index)" class="w-e-parameter-icon">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </div>
    <v-btn elevation="0" rounded
      @click="add"
      class="workflow-setting-form-update-add-btn">
      <v-icon size="18">mdi-plus</v-icon>
      <span>
        {{ $t('workflow.modules.subWorkflow.label.addParam') }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';

import {
  FETCH_PARAMETERFIELDS,
  FETCH_WORKFLOW_PARAMETER,
  // REMOVE_WORKFLOW_PARAMETER,
} from '@/store/modules/workflow/action_types';

import Mixin from './tree/setting/mixin';
// import { map, omit } from 'lodash'
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    parameters: {
      type: [Object],
      default: [],
    },
    node: Object,
    targetWorkflow: Object,
  },
  mounted() {
    this.fetch()
  },
  computed: {
    fields() {
      return this.$store.getters.getParameterFields || [];
    },
  },
  components: {
    ComboBox,
  },
  methods: {
    fetch() {
      this.$store.dispatch(FETCH_PARAMETERFIELDS);
      this.$store.dispatch(FETCH_WORKFLOW_PARAMETER, {
        appId: this.appId,
        workflow: this.targetWorkflow._id,
      });
    },
    remove(index) {
      const current = this.parameters[index] || {};
      if (current && !current.id) {
        this.$delete(this.parameters, index);
      } else {
        this.$set(this.parameters, index, { ...current, removed: true });
      }
    },
    add() {
      this.$set(this.parameters, this.parameters.length, {
        widget: null,
        name: null,
      });
    },
    change(widget, index) {
      const current = this.parameters[index] || {};
      this.$set(this.parameters, index, { ...current, widget });
    },
    input(name='', index) {
      const current = this.parameters[index] || {};
      if (!name.match(/^[A-Za-z]+$/)) {
        current.error = true;
      } else {
        current.error = undefined;
      }
      this.$set(this.parameters, index, { ...current, name });
    },
  },
};

</script>

<style scoped>
  .w-e-parameter-row {
    margin: 15px -4px;
  }
  .w-e-parameter-type {
    margin: 0 4px;
    width: 50%;
  }
  .w-e-parameter-input {
    margin: 0 4px;
    display: flex;
    flex: 1;
  }
  .w-e-parameter-icon {
    display: block;
  }
  .w-e-parameter-remove {
    width: 30px;
    margin: 0 4px;
  }
  .v-normal-text-input {
    margin-bottom: 0 !important;
  }
</style>
