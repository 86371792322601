import {app} from '@/main'
import {getStartEndDateByPeriod} from '@/helpers/DateTimeHelper';

const ValidateRule = (hostVal, compareVal, rule, fieldType) => {
    if(fieldType === 'phone' && hostVal && hostVal!=''){
        hostVal = hostVal.replaceAll(" ", "")
    }
    switch(rule){
        case 'empty':
            return hostVal == "" || hostVal == null || hostVal==[]
        case 'notEmpty':
            return hostVal != "" && hostVal != null && hostVal != []
        case 'includes':
            if(!hostVal)hostVal=""
            return hostVal.includes(compareVal)
        case 'notInclude':
            if(!hostVal)hostVal=""
            return !hostVal.includes(compareVal)
        case 'is':
            // if(fieldType=='relatedRecord'){
            //     compareVal = compareVal.map(item=>item._id)
            //     return hostVal.some(item=>compareVal.includes(item))
            // }
            if(!hostVal)hostVal=""
            return hostVal == compareVal
        case 'not':
            if(!hostVal)hostVal=""
            return hostVal != compareVal
        case 'beginsWith':
            if(!hostVal)hostVal=""
            return hostVal.startsWith(compareVal)
        case 'endsWith':
            if(!hostVal)hostVal=""
            return hostVal.endsWith(compareVal)
        case 'toggle':
            if(compareVal == "yes"){
                if (fieldType == "yesNo"){
                    return hostVal == true
                }else if(fieldType == "attachments" || fieldType == "signature"){
                    return hostVal != "" && hostVal != null && hostVal != []
                }
                
            }
            else{
                if (fieldType == "yesNo"){
                    return !hostVal
                }else if(fieldType == "attachments" || fieldType == "signature"){
                    return hostVal == "" || hostVal == null || hostVal==[]
                }
            }
        case 'hasCount':
        case 'atLeast':
        case 'atMost':
            return false
        case 'withinPeriod':
        case 'notWithinPeriod':
        case 'isTheDate':
        case 'notTheDate':
        case 'earlierThan':
        case 'laterThan':{
            let startDate, endDate
            [startDate, endDate] = ["withinPeriod", "notWithinPeriod"].includes(rule)?
                getStartEndDateByPeriod(`WITHIN_${compareVal}`):
                getStartEndDateByPeriod(compareVal)
            
            startDate = new Date(startDate)
            endDate = new Date(endDate)
            hostVal = new Date(Date.parse(hostVal))
            switch(rule){
                case 'isTheDate':
                    return (hostVal>=startDate && hostVal<= endDate)
                case 'notTheDate':
                    return (hostVal<startDate || hostVal>endDate)
                case 'earlierThan':
                    return (hostVal<startDate)
                case 'laterThan':
                    return (hostVal>endDate)
                case "withinPeriod":
                    return (hostVal>=startDate && hostVal<=endDate)
                case "notWithinPeriod":
                    return !(hostVal>=startDate && hostVal<=endDate)
                default:{
                    return false
                }
            }
        }
        case 'inRange':
            if(!hostVal) hostVal = 0
            var [minVal, maxVal] = compareVal.split("||")
            return (parseFloat(hostVal)>=(parseFloat(minVal)||0) && parseFloat(hostVal)<=(parseFloat(maxVal)||0))
        case 'equals':
            if(!hostVal) hostVal = 0
            return parseFloat(hostVal) == parseFloat(compareVal)
        case 'notEqual':
            if(!hostVal) hostVal = 0
            return parseFloat(hostVal) != parseFloat(compareVal)
        case 'greaterThan':
            if(!hostVal) hostVal = 0
            return parseFloat(hostVal) > parseFloat(compareVal)
        case 'smallerThan':
            if(!hostVal) hostVal = 0
            return parseFloat(hostVal) < parseFloat(compareVal)
        case 'greaterOrEqual':
            if(!hostVal) hostVal = 0
            return parseFloat(hostVal) >= parseFloat(compareVal)
        case 'smallerOrEqual':
            if(!hostVal) hostVal = 0
            return parseFloat(hostVal) <= parseFloat(compareVal)
        case 'notInRange':
            if(!hostVal) hostVal = 0
            var [minVal, maxVal] = compareVal.split("||")
            return !(parseFloat(hostVal)>=(parseFloat(minVal)||0) && parseFloat(hostVal)<=(parseFloat(maxVal)||0))
        case 'isTheItem':
           
            return compareVal.includes(hostVal)
        case 'notTheItem':
            return !compareVal.includes(hostVal)
        case 'includeAnItem':
            if(hostVal)
                return hostVal.some(selected=> compareVal.includes(selected))
            else
                return false
        case 'notIncludeTheItems':
            if(hostVal)
                return compareVal.some(selected=> !hostVal.includes(selected))
            else
                return true
        case 'isTheMember':
            if(Array.isArray(hostVal) && Array.isArray(compareVal)){
                return hostVal.some(host=>{
                    return compareVal.findIndex(compare=> compare._id==host._id)!=-1
                })
            }
            else return false
        case 'notTheMember':
            if(Array.isArray(hostVal) && Array.isArray(compareVal)){
                return !hostVal.some(host=>{
                    return compareVal.findIndex(compare=> compare._id==host._id)!=-1
                })
            }else return false
        case 'isTheRegion':
        case 'notTheRegion':
        case 'isTheRating':
            return parseInt(hostVal) == parseInt(compareVal)
        case 'notTheRating':
            return !(parseInt(hostVal) == parseInt(compareVal))
        case 'isRatingTheLeast':
            return parseInt(hostVal) >= parseInt(compareVal)
        case 'isRatingTheMost':
            return parseInt(hostVal) <= parseInt(compareVal)
        case 'isTheDepartment':
            if(typeof hostVal == "object")
                return hostVal.some(host=>compareVal.find(compare=> compare._id==host._id))
            else
                return false
        case 'notTheDepartment':
            if(typeof hostVal == "object")
                return compareVal.some(compare=>!hostVal.find(host=> compare._id==host._id))
            else
                return true
        case 'isTheRelation':
            compareVal = compareVal.map(item=>item._id)
            return hostVal.some(item=>compareVal.includes(item))
        case 'notTheRelation':
            compareVal = compareVal.map(item=>item._id)    
            return !compareVal.every(item=>hostVal.includes(item))
        
    }
    return false 
}

const getContentVal = (val, rule, fieldType, fieldInfo) => {
    // console.log(val, rule, fieldType, fieldInfo, relatedTableInfos)
    switch(fieldType){
        case 'text':
        case 'email':
        case 'phone':
        case 'idCard':
        case 'autoNumbering':
        case 'textCombination':
            return val
        case 'number':
        case 'amount':
        case 'expression':
        case 'capitalizedAmount':
        case 'summaryField':
        case 'rating':
            if(rule == "inRange" || rule == "notInRange"){
                if(typeof val == 'string')
                    val = val.replace("||", " ~ ")
            }
            return val
        case 'date':
        case 'createdAt':
        case 'updatedAt':
            return val
        case 'singleSelection':
        case 'multipleSelection':
            var opts = fieldType=="multipleSelection"? fieldInfo.properties.inputOptionsMultiple.options:fieldInfo.properties.inputOptionsSingle.options
            if(val){
                var res = val.map(item=>{
                    return opts.find(opt=> opt._id == item).label
                })
                return res.join(", ")
            }else return ''
        case 'createdBy':
        case 'owner':
        case 'members':
            if(val)
                return val.map(ee=>ee.employeeName).join(", ")
            return ""
        case 'departments':
            if(val)
                return val.map(department=>department.label).join(", ")
            return ""
        case 'attachments':
        case 'signature':
            return val=="yes"? app.$i18n.t("filters.notEmpty"): app.$i18n.t("filters.empty")
        case 'yesNo':
            return app.$i18n.t(`general.${val|| 'no'}`);
        case 'relatedRecord_text':
            return val.join(', ')
        case 'relatedRecord_members':
            var res = []
            val.forEach(item=>res = res.concat(item.map(item2=>item2.employeeName)))
            return res.join(', ')
        default: return val
    }
}

export{
    ValidateRule,
    getContentVal
}
