<template>
  <v-card max-width="480"
          class="mx-auto my-12">
    <v-app-bar style="background-color:rgba(100,100,100,.5);"
               light
               height="48">
      <v-icon left>mdi-animation-outline</v-icon>
      <v-toolbar-title>{{ $t('cardBoardView.cardBoardSetup')}}</v-toolbar-title>
    </v-app-bar>
    <v-container>
      <!--<v-row>-->
        <!--<v-col cols="12">-->
          <!--relationType: {{ relationType }}<br/>-->
          <!--relationFieldIdPair: {{ relationFieldIdPair }}<br/>-->
          <!--cascadeRelationFieldIds: {{ cascadeRelationFieldIds }}-->
        <!--</v-col>-->
      <!--</v-row>-->
      <v-row dense>
        <v-col cols="12">
          <label>{{ $t('cardBoardView.selectGroupingField') }}</label>
          <input-radio-toggle
              buttonClass="mr-1 mb-1"
              v-model="selectedFieldId"
              valueField="fieldId"
              :isDense="false"
              :options="options"></input-radio-toggle>
        </v-col>
      </v-row>

      <!--&lt;!&ndash;<level-select-relation-field&ndash;&gt;-->
          <!--&lt;!&ndash;v-if="relationType==='singleTable'"&ndash;&gt;-->
          <!--&lt;!&ndash;v-model="relationFieldIdPair"></level-select-relation-field>&ndash;&gt;-->
      <!--&lt;!&ndash;<level-select-cascade-relation-fields&ndash;&gt;-->
          <!--&lt;!&ndash;v-else&ndash;&gt;-->
          <!--&lt;!&ndash;v-model="cascadeRelationFieldids"></level-select-cascade-relation-fields>&ndash;&gt;-->
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary"
             @click="confirm"
             class="min-width-100">
        <v-icon left>mdi-check</v-icon>
        {{ $t('buttons.ok')}}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>

</template>

<script>
import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
// import levelSelectRelationField from './LevelSelectRelationField'
// import levelSelectCascadeRelationFields from './LevelSelectCascadeRelationFields'

export default {
  name: 'cardBoardViewInitialSetup',
  components: {
    inputRadioToggle
    // ,
    // levelSelectRelationField,
    // levelSelectCascadeRelationFields
  },
  data () {
    return {
      selected: null
      // relationTypeOptions: [
      //   {labelTag: 'levelDiagram.thisTableRelation', value: 'singleTable'},
      //   {labelTag: 'levelDiagram.multipleTableRelation', value: 'multipleTable'}
      // ],
      // relationType: 'singleTable',
      // relationFieldIdPair: null, // {parent: {fieldId}, child: {fieldId}}
      // cascadeRelationFieldIds: []
    }
  },
  props: {
    view: Object,
    groupingFieldInfos: Array
  },
  computed: {
    form () {
      const vm = this
      return vm.$store.getters.currentForm
    },
    fieldInfos () {
      const vm = this
      var result = []
      if (vm.form) {
        result = vm.form.fieldInfos
      }
      return result
    },
    options () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.groupingFieldInfos.length; i++) {
        const loopInfo = vm.groupingFieldInfos[i]
        result.push({
          fieldId: loopInfo.fieldId,
          label: loopInfo.label
        })
      }
      return result
      // var result = []
      // if (vm.fieldInfos) {
      //   for (var i = 0; i < vm.fieldInfos.length; i++) {
      //     const loopFieldInfo = vm.fieldInfos[i]
      //     switch (loopFieldInfo.type) {
      //       case 'singleSelection':
      //       case 'multipleSelection':
      //       case 'members':
      //       case 'departments':
      //         result.push({
      //           fieldId: loopFieldInfo.fieldId,
      //           label: loopFieldInfo.label
      //         })
      //         break
      //     }
      //   }
      // }
      // console.log('options: result: ', result)
      // return result
    },
    selectedFieldId: {
      get () {
        const vm = this
        var result = ''
        if (vm.selected !== null) result = vm.selected
        return result
      },
      set (val) {
        const vm = this
        vm.selected = val
      }
    }
    // ,
    // relationTypeOptions () {
    //   return [
    //
    //   ]
    // }
  },
  methods: {
    confirm () {
      console.log('CardBoardViewInitialSetup :: confirm')
      this.$emit('confirm', this.selected)
    }
  }
}
</script>
