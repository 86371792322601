<template>
<v-tooltip :bottom="!button.command.includes('submit')" :left="button.command.includes('submit')">
  <template v-slot:activator="{ on, attrs }">
    <div>
      <v-btn
        :key="button.command"
        class="mx-1 elevation-0"
        dark
        :small="button.text"
        :x-small="!button.text"
        :disabled="disabled"
        :fab="!button.text"
        :color="button.color"
        v-on="on"
        :class="[buttonClass, button.text?'rounded-pill':'']"
        v-bind="attrs"
        style="position:relative;"
        @click.stop.native="onClick(button.command)"
      >
        <!-- Append Icons -->
        <div 
          v-for="(icon,index) in button.appendIcons"
          :key="index"
          style="position:absolute;right:0px;top:0px;margin-top:-6px;margin-right:-3px;display:inline-block;color:white;">
          <v-icon x-small>{{ icon }}</v-icon>
        </div>
        <!-- Foot Icons -->
        <div 
          v-for="(icon,index) in button.footIcons"
          :key="index"
          style="position:absolute;right:0px;top:0px;margin-top:9px;margin-right:-1px;display:inline-block">
          <v-icon x-small>{{ icon }}</v-icon>
        </div>
        <v-icon :style="button.text?'width:15px; min-width:15px; margin-right:4px':''" :size="button.text?18:undefined">{{ button.icon }}</v-icon>
        <span>{{button.text}}</span>
      </v-btn>
    </div>
  </template>
  <span>{{ $t(button.tooltipTag) }}</span>
</v-tooltip>
</template>

<script>
export default {
  name: 'formActionButtonItem',
  props: {
    button: Object,
    buttonClass: String,
    disabled: Boolean
  },
  methods: {
    onClick () {
      this.$emit('onCommand', {command: this.button.command})
    },
    onCommandHandler (payload) {
      this.$emit('onCommand', payload)
    }
  }
}
</script>