<template>
  <v-menu offset-y transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            :disabled="$store.getters.isUIBlocked"
        >
            <v-icon
                small
                :color="isNode ? 'white' : 'rgb(158, 158, 158)'"
            >
                mdi-invert-colors 
            </v-icon>
        </v-btn>
    </template>
    <Chrome v-model="color" @input="update" />
  </v-menu>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  props: {
    color: String,
    isNode: Boolean,
  },
  methods: {
    update(color) {
      // update name
      this.$emit('onColor', color.hex);
    },
  },
  components: {
      Chrome,
  },
};

</script>

<style scoped>

</style>
