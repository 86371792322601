import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import validationMixins from "./validationMixins";
import {formatNumber} from "../../helpers/DataHelpers"
/*
    This mixin includes props/data/methods/getters that are commonly used/shared by most widgets
    By default, inputWidget components will share these functionalities. You can override any of this by
    declaring the same thing in the component. For example, declaring updateValue() method in InputWidgetText
    component will override the updateValue() method here. If any data parse is required before emitting the value,
    it is advised to implement parsing logic in the component-scoped updateValue() methods.
*/
const mixin = {
  mixins: [validationMixins],
  props: {
    isHidden: Boolean,
    isNew: Boolean,
    fieldInfo: Object,
    isLayout: Boolean,
    recordData: Object,
    relatedTableData: Object,
    relatedTableInfos: {
      type: Object,
      default: {}
    },
    value: {
      type: String,
      default: "",
    },
    alias: String,
    customDescription: String,
    isReadonly: {
      type: Boolean,
      default:false
    },
    activeForm: {
      type: Object,
      default: {}
    },
    customizedFormInfo: Object,
    customizedProperties: Object,
    editAnyway: {
      type: Boolean,
      default:false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: String,
      default: 'auto'
    },
    fullWidth: Boolean,
    childRecords:Array,
    rowHeightClass: {
      type: String,
      default: ''
    },
    inRecordDrawer: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      focusing: false
    }
  },
  model: {
    prop: "value",
    event: "input",
  },
  computed: {
    inputValue: {
      get() {
        const vm = this;
        var result = vm.value;
        if (vm.isLayout && !vm.editAnyway && vm.defaultType !== "auto") {
          // return vm.getDefaultValue();
        }
        if (typeof vm._parseValue === "function") {
          result = vm._parseValue(vm.value);
        }
        return result;
      },
      set(val) {
        this.hasError = false;
        this.updateValue(val);
      },
    },
    //authorization
    authorizations() {
      const auth = getFieldPropertyValue(this.fieldInfo, "authorization", "");
      return auth.split("||");
    },
    dataSource() {
      return getFieldPropertyValue(this.fieldInfo, "dataSource", null);
    },
    isReadOnly() {
      return this.isReadonly || this.$route.name !=='PublicQuery' && this.authorizations.indexOf("readonly") >= 0;
    },
    // isHidden(){
    //   return this.authorizations.includes('hidden')
    // },
    //field general attribute
    fieldId() {
      return this.fieldInfo.fieldId;
    },
    fieldType() {
      return this.fieldInfo.type;
    },
    fieldName() {
      const { label } = this.fieldInfo;
      return (
        this.alias || (label !== undefined ? label : null) || 
        getFieldPropertyValue(
          this.fieldInfo,
          "fieldName",
          this.$t("general.noName")
        )
      );
    },
    fieldWidth() {
      return (this.isLayout || this.fullWidth)
        ? 12
        : getFieldPropertyValue(this.fieldInfo, "width", 12);
    },
    placeholder() {
      return getFieldPropertyValue(this.fieldInfo, "placeholder", "");
    },
    prefix() {
      return getFieldPropertyValue(this.fieldInfo, "prefix", "");
    },
    suffix() {
      return getFieldPropertyValue(this.fieldInfo, "suffix", "");
    },
    defaultType() {
      return getFieldPropertyValue(this.fieldInfo, "defaultType", "");
    },
    formInfo() {
      const vm = this;
      if (vm.customizedFormInfo) {
        return vm.customizedFormInfo;
      }
      return vm.isLayout && !this.editAnyway
        ? vm.$store.getters.formInfo
        // : vm.$store.getters.currentForm;
        : vm.activeForm;
    },
    activeFormInfo () {
      const vm = this
      var result = null
      const topFormInfo = {
        ...vm.$store.getters.formInfo,
        layout: vm.$store.getters.formLayout,
        fieldInfos: vm.$store.getters.formFieldInfos
      }
      if (vm.isLayout) {
        result = topFormInfo
      } else {
        if (vm.formInfo) {
          result = vm.formInfo
        } else {
          result = topFormInfo
        }
      }
      return result
    },
    
    // notes:
    // During form building mode,
    // formInfo contains only label, linkedFields, titleFieldInfoId    
    fieldInfos () {
      const vm = this
      return vm.isLayout ?
        vm.$store.getters.formFieldInfos :
        vm.activeFormInfo.fieldInfos
    },
    widget() {
      const vm = this;
      var result = null
      if (vm.fieldInfo) {
        result = vm.$store.getters.allWidgets.find(
          (w) => w.key === vm.fieldInfo.type
        );
      }
      return result
    },
    linkedFields() {
      const vm = this
      // notes:
      // linkedFields: [
      //    {fieldIds: [{fieldId}, {fieldId}]},
      //    {fieldIds: [{fieldId}, {fieldId}]},
      //    ....
      // ]
      return (vm.activeFormInfo && vm.activeFormInfo.linkedFields) ?
        vm.activeFormInfo.linkedFields : []
    },
    linkedFieldInfoItem () {
      const vm = this
      return vm.linkedFields.find(
        item => {
          return item.fieldIds.includes(vm.fieldId)
        }
      )
    },

    linkedFieldIdPair () {
      const vm = this
      try {
      var result = null
        if (vm.linkedFieldInfoItem) {
          result = vm.linkedFieldInfoItem.fieldIds
        }
      } catch (err) {
        console.log('****************** err: ', err)
      }
      return result;
    },

    isParentChildRelation () {      
      return this.linkedFieldIdPair !== null
    },

    parentChildRelatedFieldId () {
      const vm = this
      // console.log('parentChildRelatedFieldId :: fieldId = ' + this.fieldId)
      // for parent/child relationship, remainingFieldIds contains one element
      var result = ''
      if (vm.linkedFieldIdPair) {
        const remainingFieldIds = vm.linkedFieldIdPair.filter(
          fieldId => fieldId !== vm.fieldId)
        if (remainingFieldIds.length > 0) {
          result = remainingFieldIds[0]
        }
      }
      return result
    },

    relatedParentFieldId () {
      return this.parentChildRelatedFieldId
    },

    relatedChildFieldId () {
      return this.parentChildRelatedFieldId
    },

    isParent () {
      return this.fieldInfo.properties.relatedRecordQuantity === 'single'
    },
    isChild () {
      return this.fieldInfo.properties.relatedRecordQuantity === 'multiple'
    },
    description() {
      return this.customDescription
        ? this.customDescription
        : getFieldPropertyValue(this.fieldInfo, "description", "");
    },
    isPublic(){
      return this.$store.getters.isPublicRoute
    }
  },
  methods: {
    updateValue (val) {
      const vm = this
      // console.log('mixin : updateValue val: ', val)
      // console.log('mixin : emit(input)')
      this.$emit('input', val)
      if (typeof vm._updateValue === 'function') {
        this.$nextTick(()=>{
          vm._updateValue(val)
      })
    }
    },
    getDefaultValue() {
      const vm = this;
      // console.log('mixin : getDefaultValue')
      // console.log([typeof vm._getDefaultValue, vm.fieldName])
      if (typeof vm._getDefaultValue === "function") {
        return vm._getDefaultValue();
      }
      var defaultValue = getFieldPropertyValue(this.fieldInfo, "default", "");
      return defaultValue;
    },
    /**
     * For rounding of decimal places
     * 
     * @param {String} val value to be parsed
     * @returns {String} formatted number
     */
    formatNumber(val) {
      return formatNumber(val, parseInt(
        getFieldPropertyValue(this.fieldInfo, "decimals", 0)
      )) || ""
    },
    resetValidation () {
      const vm = this
      if (typeof vm._resetValidation === 'function') {
        vm._resetValidation()
      } else {
        if (
          vm.$refs[vm.fieldInfo.fieldId] &&
          typeof vm.$refs[vm.fieldInfo.fieldId].resetValidation === 'function') {
            
          vm.$refs[vm.fieldInfo.fieldId].resetValidation()
        }
      }
    },
    moneyToString(num) {
      if (num == "" || num == null) num = 0;
      var abs_num = Math.abs(num);
      if (abs_num == 0) return "零整元";
      const digits = [
        "零",
        "壹",
        "貳",
        "叁",
        "肆",
        "伍",
        "陸",
        "柒",
        "捌",
        "玖",
      ];
      const radices = ["", "拾", "佰", "仟", "萬", "億"];
      const bigRadices = ["", "萬", "億"];
      const decimals = ["角", "仙"]; // 这里只精确到分
      const cn_dollar = "元";
      ("");
      const cn_integer = "整";
      const cn_negative = "負";
      // int_str = Math.floor(num).toString();
      // float_str = num % 1;
      const num_arr = abs_num.toString().split(".");
      const int_str = num_arr[0] || "";
      let float_str = num_arr[1] || "";
      if (float_str.length > 2) {
        float_str = float_str.substr(0, 2);
      }
      let outputCharacters = "";
      if (int_str) {
        let zeroCount = 0;
        const int_len = int_str.length;
        for (var i = 0; i < int_len; i++) {
          const p = int_len - i - 1;
          var d = int_str.substr(i, 1);
          const quotient = p / 4;
          var modulus = p % 4;
          if (d === "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              outputCharacters += digits[0];
            }
            zeroCount = 0;
            outputCharacters += digits[d] + radices[modulus];
          }
          if (modulus === 0 && zeroCount < 4) {
            outputCharacters += bigRadices[quotient];
            zeroCount = 0;
          }
        }
        outputCharacters += cn_dollar;
      }

      if (float_str) {
        const float_len = float_str.length;
        for (let i = 0; i < float_len; i++) {
          const d = float_str.substr(i, 1);
          if (d !== "0") {
            outputCharacters += digits[d] + decimals[i];
          }
        }
      }

      if (outputCharacters === "") {
        outputCharacters = digits[0] + cn_dollar;
      }

      if (float_str) {
        outputCharacters += cn_integer;
      }
      return num < 0 ? cn_negative + outputCharacters : outputCharacters;
    },
  },
  async mounted() {
    // if (
    //   !this.inputValue && (this.$route.name === "PreviewPublicForm" ||
    //   this.$route.name === "PublicForm" ||
    //   this.isLayout ||
    //   this.isNew || this.fieldInfo.type=='region') && !this.editAnyway
    // ){
    //   this.inputValue = await this.getDefaultValue()
    // }
    if (typeof this.afterMount === 'function'){
      await this.afterMount()
    }
  },
};

export default mixin;
