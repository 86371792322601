<template>
  <div>
    <label>{{ label }}</label>
    <v-select v-model="propertyValue"
      :items="items"
      outlined
      solo
      dense
      single-line
      hide-details
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
    ></v-select>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'witRelatedRecordView',
  mixins: [mixin],
  computed: {
    dataSourceViews () {
      return this.dataSourceInfo ? this.dataSourceInfo.views : []
    },
    items () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.dataSourceViews.length; i++) {
        const loopView = vm.dataSourceViews[i]
        result.push({
          text: loopView.title,
          value: loopView._id
        })
      }
      return result 
    }
  }
}
</script>

