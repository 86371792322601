import vxeCellAmount from './VxeCellAmount'
import vxeCellAttachments from './VxeCellAttachments'
import vxeCellChildTable from './VxeCellChildTable'
import vxeCellDate from './VxeCellDate'
import vxeCellDateTime from './VxeCellDateTime'
import vxeCellMembers from './VxeCellMembers'
import vxeCellDepartments from './VxeCellDepartments'
import vxeCellMultipleSelection from './VxeCellMultipleSelection'
import vxeCellNumber from './VxeCellNumber'
import vxeCellOtherTableField from './VxeCellOtherTableField'
import vxeCellPerson from './VxeCellPerson'
import vxeCellRelatedRecord from './VxeCellRelatedRecord'
import vxeCellRelatedRecordSimple from './VxeCellRelatedRecordSimple'
import vxeCellSingleSelection from './VxeCellSingleSelection'
import vxeCellSummaryField from './VxeCellSummaryField'
import vxeCellText from './VxeCellText'
import vxeCellYesNo from './VxeCellYesNo'
import vxeCellRegion from './VxeCellRegion'
import vxeCellRichText from './VxeCellRichText'
import vxeCellExpression from './VxeCellExpression'
import vxeCellLocation from './VxeCellLocation'
import VxeCellEmail from './VxeCellEmail'
import VxeCellPhone from './VxeCellPhone'
import VxeCellRating from './VxeCellRating'
import VxeCellSignature from './VxeCellSignature'
import VxeCellIdCard from './VxeCellIdCard'
import VxeCellTextCombination from './VxeCellTextCombination'
import VxeCellCapitalizedAmount from './VxeCellCapitalizedAmount'


export default {
  vxeCellAmount,
  vxeCellAttachments,
  vxeCellChildTable,
  vxeCellDate,
  vxeCellDateTime,
  vxeCellMembers,
  vxeCellDepartments,
  vxeCellMultipleSelection,
  vxeCellNumber,
  vxeCellOtherTableField,
  vxeCellPerson,
  vxeCellRelatedRecord,
  vxeCellRelatedRecordSimple,
  vxeCellSingleSelection,
  vxeCellSummaryField,
  vxeCellText,
  vxeCellYesNo,
  vxeCellRegion,
  vxeCellRichText,
  vxeCellExpression,
  vxeCellLocation,
  VxeCellEmail,
  VxeCellPhone,
  VxeCellRating,
  VxeCellSignature,
  VxeCellIdCard,
  VxeCellTextCombination,
  VxeCellCapitalizedAmount,

}
