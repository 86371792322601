<template>
  <v-container>
    <url-box :url="url">
      <template v-slot:prepend-outer>
        <v-switch
          class="mt-0 px-2"
          :label="$t('publicForm.activate')"
          v-model="publicQuery.active"
          @change="onActiveChange"
          dense
          hide-details
        />
        <url-box
          :url="publicQuery.title"
          class="px-1"
          :canCopy="false"
          maxWidth="100px"
          noQRCode
          @copy="$emit('commandHandler',{command:'copyUrl'})"
          @download="$emit('commandHandler',{command:'downloadUrl'})"
        >
        </url-box>
      </template>
      <template v-slot:prepend-copy>
        <div class="px-2">
          <v-tooltip top>
            <template v-slot:activator="{ attr, on }">
              <v-btn icon small @click="openURL" v-on="on" v-bind="attr">
                <v-icon small>
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </template>
            {{ $t("publicForm.openInNew") }}
          </v-tooltip>
        </div>
      </template>
      <template v-slot:append-copy>
        <div class="px-2">
          <v-tooltip top>
            <template v-slot:activator="{ attr, on }">
              <v-btn icon small @click="onSettingIconClicked" v-on="on" v-bind="attr" color="primary">
                <v-icon small>
                  mdi-cog-outline
                </v-icon>
              </v-btn>
            </template>
            {{$t('general.settings')}}
          </v-tooltip>
        </div> 
        <div class="px-2">
          <v-tooltip top>
            <template v-slot:activator="{ attr, on }">
              <v-btn icon small @click="onDelete" v-on="on" v-bind="attr" color="error">
                <v-icon small>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </template>
            {{$t('buttons.delete')}}
          </v-tooltip>
        </div>    
      </template>
      <template v-slot:append-inner>
            <div class="px-2">
              <v-tooltip top>
                <template v-slot:activator="{on, attr}">
              <v-btn icon small @click="refreshURL" v-on="on" v-bind="attr">
                <v-icon small>
                  mdi-refresh
                </v-icon>
              </v-btn>
                </template>
                {{$t('publicForm.refreshURL')}}
              </v-tooltip>
            </div>
      </template>
    </url-box>
  </v-container>
</template>
<script>
import urlBox from "./urlBox";

export default {
  props: {
    publicQuery: Object,
  },
  components: {
    urlBox,
  },
  computed: {
  url() {
      return window.location.origin + "/public/query/" + this.publicQuery.urlId;
    },
  },
  methods: {
      openURL(){
        window.open(this.url);
        this.$emit('commandHandler', {
          command: 'openURL',
          url:this.url
        })
      },
      onSettingIconClicked(){
        this.$emit('commandHandler', {
          command: 'edit',
          id: this.publicQuery._id
        })
      },
      onDelete(){
        this.$emit('commandHandler', {
          command: 'delete',
          id: this.publicQuery._id
        })
      },
      onActiveChange(){
        this.$emit('commandHandler', {
          command: 'update',
          ...this.publicQuery
        }) 
        this.$emit('activated')
      },
      refreshURL(){
        this.$emit('commandHandler', {
          command: 'refreshURL',
          publicQuery: this.publicQuery
        })
      }
  }
};
</script>
