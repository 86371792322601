<template>
    <div v-if="mailSenders.length" class="sender-card loading-wrapper">
        <v-card
            class="mt-3 mx-3 d-flex senderCard"
            outlined
            style="border-style: dashed;"
            v-for="sender in mailSenders"
            :key="sender._id"
        >
            <v-card-text 
                style="font-size:14px"
            >
                <div class="d-flex flex-column ">
                    <div>{{sender.configs.profileName}}</div>
                    <div class="mt-1">
                        <span class="mr-2 font-weight-bold">from:</span>
                        {{ sender.configs.from}}
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="pa-0">
                <div class="d-flex flex-column" style="height:100%">
                    <div class="d-flex justify-end align-top mt-2 mr-1">
                        <v-btn
                            x-small
                            icon 
                            color="teal lighten-2"
                            @click="editSender(sender)"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn
                            x-small
                            icon 
                            color="teal lighten-2"
                            @click="deleteSender(sender._id)"
                            class="ml-1"
                        >
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="mt-2 mr-2" style="width:40px">
                        <v-switch
                            v-model="sender.visible"
                            @change="setVisible(sender)"
                            class="mt-0 pt-0"
                            dense
                            hide-details
                        >
                        </v-switch>
                        <!-- <label class="switch">
                            <input 
                                type="checkbox"
                                v-model="sender.visible"
                                @input="setVisible(sender)"
                            >
                            <span class="slider"></span>
                        </label>
                        <div v-if="sender.visible" class="grey--text text--darken-2" style="position: absolute; right:26px; top:34px">On</div>
                        <div v-else class="grey--text text--darken-2" style="position: absolute; right:13px; top:34px">Off</div> -->
                    </div>
                </div>
            </v-card-actions>
        </v-card>

        <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    </div>
</template>

<script>
    import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog';

    export default {
        props:{
            mailSenders:{
                type: Array,
                default: []
            },
        },
        components: {
            confirmDeleteDialog,
        },
        methods:{
            deleteSender(senderId, confirmDelete=false){
                let vm = this
                vm.$refs.confirmDeleteDialog.confirm(() => {
                    vm.$emit('onCommand', {
                        command: 'delete',
                        senderId
                    })
                }, {title: vm.$t('buttons.cancelLinking')})
            },
            editSender(sender){
                this.$emit('onCommand', {
                    command: 'edit',
                    sender
                })
            },
            setVisible(sender){
                this.$emit('onCommand', {
                    command: 'setVisible',
                    sender
                })
            },
        }
    }
</script>

<style>
    .sender-card .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        height: 16px;
        width:40px;
    }

    .sender-card .slider:before {
        position: absolute;
        height: 8px;
        width: 10px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .sender-card input:checked + .slider:after{
        content: "aa";
    }

    .sender-card input + .slider:after{
        content: "cc";
    }

    .sender-card input:checked + .slider:before {
        -webkit-transform: translateX(22px);
        -ms-transform: translateX(22px);
        transform: translateX(22px);
    }

    .loading-wrapper {
        position: relative;
    }
</style>