<template>
  <input-widget-container
      class="input-widget-other-table-field"
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :isHidden="isHidden"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :tooltipText="description">
      <field-string
        :fieldInfo="relatedFieldInfo"
        :recordData="relatedRecordData"
        :recordMode="true"
        imageHeight="64px"
        height="32px"
        :border="border"
        :class="otherTableFieldClass"
        imageSize="large"
        :isLayout="isLayout"
        :textOnly="false"></field-string>
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'
import {getFieldPropertyValue} from "../../helpers/ObjectHelpers"
import fieldString from '@/components/fieldStrings/Fs'

export default {
  name: 'inputWidgetOtherTableField',
  mixins: [mixin],
  components: {
    inputWidgetContainer,
    fieldString
  },
  computed: {
    border () {
      return this.relatedFieldInfo && this.relatedFieldInfo.type === 'attachments';
    },
    fieldForRelatedTable () {
      return getFieldPropertyValue(this.fieldInfo,
        'fieldForRelatedTable', '')
    },
    fieldInfoForRelatedTable () {
      const vm = this
      var result = null
      result = vm.fieldInfos.find(info => info._id === vm.fieldForRelatedTable)
      return result
    },
    fieldIdForRelatedTable () {
      return this.fieldInfoForRelatedTable ? 
        this.fieldInfoForRelatedTable.fieldId : 
        ''
    },
    relatedTableInfo () {
      const vm = this
      var result = null
      if (vm.fieldInfoForRelatedTable && 
        vm.fieldInfoForRelatedTable.properties &&
        vm.fieldInfoForRelatedTable.properties.dataSource &&

        vm.relatedTableInfos &&
        vm.relatedTableInfos[vm.fieldInfoForRelatedTable.properties.dataSource]) 
      {
        result = vm.relatedTableInfos[vm.fieldInfoForRelatedTable.properties.dataSource]
      }
      return result
    },

    referFieldValue () {
      const vm = this
      return vm.recordData &&
        vm.recordData[vm.fieldIdForRelatedTable] &&
        vm.recordData[vm.fieldIdForRelatedTable].length > 0 ?
        vm.recordData[vm.fieldIdForRelatedTable][0] : 
        ''
    },

    relatedRecordData () {
      const vm = this
      var result = []
      if (vm.relatedTableData) {
        if (vm.fieldIdForRelatedTable) {
          const relatedRecordId = vm.referFieldValue
          if ( vm.relatedTableData[vm.fieldIdForRelatedTable]) {
            const tableData = vm.relatedTableData[vm.fieldIdForRelatedTable].find(
              table => {
                if(typeof relatedRecordId==='object')
                  return table._id === relatedRecordId._id
                else return table._id === relatedRecordId
              })
            if (tableData) {
              result = tableData
            }
          }
        }
      }
      var relatedTableField = vm.fieldInfo.properties.relatedTableField
      if(result.hasOwnProperty(relatedTableField))
        vm.updateValue(result[relatedTableField])
      else
        vm.updateValue(null)
      
      return result
    },

    // related field Info
    relatedTableFieldId () {
      return getFieldPropertyValue(this.fieldInfo,
        'relatedTableField', '')
    },

    relatedFieldInfo () {
      const vm = this
      var result = null
      if (vm.relatedTableInfo && vm.relatedTableFieldId) {
        result = vm.relatedTableInfo.fieldInfos.find(info => info.fieldId === vm.relatedTableFieldId)
      } 
      return result
    }

  }
}
</script>

<style scoped>
.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}

.input-widget-other-table-field .fs-number,
.input-widget-other-table-field .fs-amount,
.input-widget-other-table-field .fs-text {
  background-color: lightgray;
  border: 1px solid darkgray;
  border-radius: 4px;
  padding: 0 6px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
