<template>
  <v-layout align-center justify-space-between class="workform-clip-dropdown-option">
    <div class="workform-clip-dropdown-source">
      <v-icon v-if="isBranch" small class="workform-clip-dropdown-icon">
        mdi-table
      </v-icon>
      <span v-if="isBranch" class="workform-clip-dropdown-branch-label">
        {{ $t('workflow.label.table') }} {{ item.table }}
      </span>
      <span v-else class="workform-clip-dropdown-label">
        <span class="workform-clip-dropdown-label-prefix">
          <v-chip small class="workform-clip-dropdown-chip" v-if="!!item.fieldType">{{ $t(`widgets.${item.fieldType}`) }}</v-chip>
        </span> 
        <span v-if="item.isSystem">
          {{  $t(`workflow.widgets.${item.fieldId}`) }}
        </span>
        <span v-else>
          {{  item.label }}
        </span>
      </span>
      <!-- <span class="workform-clip-dropdown-count" v-if="shouldShowCount">
        ({{ count }})
      </span> -->
      <span class="workform-clip-dropdown-output-count" v-if="shouldShowOutputNumber && !!item.output">
        [{{ $t(`workflow.label.output.${item.output}`) }}]
      </span>
    </div>
    <div v-if="isBranch || relationship" class="workform-clip-dropdown-table-label">
      {{ $t('workflow.label.table') }} {{ tableName }}
    </div>
    <div v-if="childTable" class="workform-clip-dropdown-table-label">
      {{ $t('workflow.label.childTable') }}
    </div>
  </v-layout>
</template>

<script>
import SettingUtils from "@/pages/admin/workflow/utils/setting";
export default {
  props: {
    item: Object,
    prefixIcon: Boolean,
    shouldShowCount: Boolean,
    count: Number,
    isBranch: Boolean,
    renderLabel: Function,
    shouldShowOutputNumber: Boolean,
    involveRelationshipLabel: Boolean,
  },
  computed: {
    relationship() {
      const { properties } = this.item;
      return (this.involveRelationshipLabel && 
        SettingUtils.relationshipFields(this.item) && 
        properties && 
        properties.dataSource
      ) || this.item.fullRelationOutput;  // the output is the relationship itself
    },
    tableName() {
      const { table, properties, fullRelationOutput } = this.item; 
      if (this.relationship && !fullRelationOutput) {
        return properties.dataSource.dataTableName;
      } else if (fullRelationOutput) {
        return this.item.table;
      }
      return table;
    },
    childTable() {
      const { properties } = this.item;
      return (this.involveRelationshipLabel && 
        SettingUtils.relationshipFields(this.item) && 
        properties && 
        properties.fieldsEditor
      );
    },
  }
}
</script>

<style>
</style>
