<template>
  <component
    v-if="!hiddenIfEmpty && hasValue"
    class="field-string"
    ref="fieldStrings"
    :is="componentName"
    :isLayout="isLayout"
    :fieldInfo="fieldInfo"
    :rowHeightClass="rowHeightClass"
    :inline="inline"
    :recordData="recordData"
    :recordMode="recordMode"
    :useLabel="useLabel"
    :border="border"
    :relatedRecord="relatedRecord"
    :style="{'min-height': fieldHeight}"
    :textOnly="textOnly"></component>
</template>

<script>
import fieldStrings from '@/components/fieldStrings'
import helpers from '@/helpers'

export default {
  name: 'fieldString',
  components: {
    ...fieldStrings
  },
  props: {
    fieldInfo: Object,
    recordData: Object,
    useLabel: {
      type: Boolean,
      default: false
    },
    textOnly: {
      type: Boolean,
      default: true
    },
    hiddenIfEmpty: {
      type: Boolean,
      default: false
    },
    isLayout: {
      type: Boolean,
      default: false
    },
    imageSize: {
      type: String,
      default: '' // 'large' (max: 240px), 'x-large' (max: 320px), 'fit'
    },
    height: {
      type: String,
      default: '28px'
    },
    imageHeight: {
      type: String,
      default: ''
    },
    relatedRecord: { // for relatedRecord widget where the title field is shown by default
      type: Object,
      default () {
        return null
      }
    },
    border: {
      type: Boolean,
      default: false
    },
    recordMode: {
      type: Boolean,
      default: false
    },
    inline: Boolean // at the moment, used by attachment only, for showing more than one attachment on same line
  },
  computed: {
    isAttachment() {
      return this.fieldInfo && this.fieldInfo.type === 'attachments'
    },
    fieldHeight() {
      const vm = this
      return (vm.isAttachment) ?
        'auto' : // vm.imageHeight :
        vm.height
    },
    rowHeightClass() {
      return this.imageSize;
    },

    hasValue() {
      const vm = this;
      var result = false;
      if (vm.fieldInfo && vm.recordData) {
        var fieldValue = vm.recordData[vm.fieldInfo.fieldId];
        result = true;
        if (fieldValue) {
          if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            result = false;
          }
        }
      }
      return result;
    },
    componentName() {
      const vm = this;
      var result = "fsUnspecified";

      if (vm.fieldInfo) {
        switch (vm.fieldInfo.type) {
          case "singleSelection":
          case "multipleSelection":
          case "attachments":
          case "signature":
          case "number":
          case "amount":
          case "region":
          case "location":
          case "relatedRecord":
          case "summaryField":
          case "rating":
          case "members":
          case "departments":
            result = "fs" + helpers.ucFirst(vm.fieldInfo.type);
            break;
          case "createdAt":
          case "updatedAt":
            result = "fsSystemDateTime";
            break;
          case "createdBy":
          case "owner":
            result = "fsUser";
            break;
          default:
            result = "fsText";
            break;
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
$img-heights: (
  "small": 32px,
  "regular": 48px,
  "large": 64px,
  "x-large": 96px,
);

.field-string {
  .field-label {
    white-space: nowrap;
  }
  .card-value img {
    object-fit: contain;
    height: 100%;
    max-width: 100%;
  }
  @each $size, $height in $img-heights {
    &.#{$size}-image {
      height: $height !important;
    }
  }

  &.large-image .card-value img {
    height: 80px;
  }
  &.x-large-image .card-value img {
    height: 240px;
  }
}
</style>
