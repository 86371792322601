<template>
  <FormElementWidget
    :ref="fieldInfo.fieldId"
    :recordData="recordData"
    :idFieldLookup="idFieldLookup"
    :relatedTableData="relatedTableData"
    :fieldInfo="fieldInfo"
    specialType="members"
    :ignoreRecordId="true"
    :enableBranchSelect="enableBranchSelect"
    :supportJobTitleSelection="supportJobTitleSelection"
    :multiple="manySelect"
    :involveRelationshipLabel="involveRelationshipLabel"
    :customizedFormInfo="customizedFormInfo"
    :noIcon="true"
    :editAnyway="true"
    :withHideFields="withHideFields"
    :optionOutput="optionOutput"
    :hideInput="dependency"
    :widgetTypeMapField="widgetTypeMapField"
    @onCommand="onCommandHandler"
  />
</template>

<script>
import Wrapper from './wrapper';
import RecordMxiin from '@/mixins/recordMixin'
import FormElementWidget from "@/components/inputWidgets/InputWidget";
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [RecordMxiin],
  props: {
    worksheet: String,
    fieldInfo: Object,
    optionOutput: String,
    recordData: Object,
    idFieldLookup: String,
    dependency: Boolean,
    involveRelationshipLabel: Boolean,
    customizedFormInfo: Object,
    relatedTableData: Object,
    withHideFields: Boolean,
    widgetTypeMapField: String,
    enableBranchSelect: {
      type: Boolean,
      default: true,
    },
    supportJobTitleSelection: {
      type: Boolean,
      default: true,
    },
    manySelect: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FormElementWidget,
    Wrapper,
  },
};

</script>

<style scoped>
  
</style>
