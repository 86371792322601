import * as mutations from "./mutation-types";
import defaultState from "./state";

export default {
  [mutations.SET_TOKEN](state, { accessToken, refreshToken }) {
    state.accessToken = accessToken || "";
    state.refreshToken = refreshToken || "";
  },
  [mutations.SET_INTERVAL](state, func) {
    if (func) {
      state.refreshTokenInterval = func;
    } else {
      clearInterval(state.refreshTokenInterval);
      state.refreshTokenInterval = null;
    }
  },
  [mutations.SET_EMPLOYEES](state, employees) {
    state.employees = employees;
  },
  [mutations.RESET_YOOVWORK](state){
    Object.assign(state, defaultState);
  }
};
