import Vue from 'vue'
import * as types from './action_types'

const state = {
  currentReport: null
}

const getters = {
  currentReport: (state) => {
    return state.currentReport
  }
}

const mutations = {
  setCurrentReport: (state, payload) => {
    state.currentReport = payload
  }
}

const actions = {

}


export default {
  state,
  getters,
  mutations,
  actions
}
