const actionsBtns = {
    saveAndNew:{
        command: 'saveAndNew',
        labelTag: 'save',
        prependIcon: 'mdi-plus',
        color: 'info',
        icon: 'mdi-content-save'
    },
    saveAndEdit: {
        command: 'saveAndEdit',
        labelTag: 'saveAndEdit',
        // prependIcon: 'mdi-file-edit',
        color: 'primary',
        icon: 'mdi-file-edit',
        className:'',
        hoveredClass: 'success',
        outline: true,
        hover: true
    },
    save: {
        command: 'save',
        labelTag: 'save',
        color: 'primary',
        icon: 'mdi-content-save',
        className:'',
        hoveredClass: 'success',
        outline: true,
        hover: true
    },
    cancel: {
        command: 'cancel',
        labelTag: 'cancel',
        color: '',
        icon: 'mdi-close',
        className:'grey--text',
        hoveredClass: 'error--text',
        outline: true,
        hover: true
    },
    close: {
        command: 'close',
        labelTag: 'close',
        color: '',
        icon: 'mdi-close',
        className:'grey--text',
        hoveredClass: 'error--text',
        outline: true,
        hover: true
    },
    ok: {
        command: 'ok',
        labelTag: 'ok',
        color: 'success',
        icon: 'mdi-check',
        className:'',
        hoveredClass: 'success',
        outline: false,
        hover: true,
    },
    continue: {
        command: 'continue',
        labelTag: 'continue',
        color: 'success',
        icon: 'mdi-check',
        className:'',
        hoveredClass: 'success',
        outline: false,
        hover: true,
    },
    confirm: {
        command: 'confirm',
        labelTag: 'confirm',
        color: 'success',
        icon: 'mdi-check',
        className:'',
        hoveredClass: '',
        outline: false,
        hover: true,
    },
    saveAndLeave: {
        command: 'saveAndLeave',
        labelTag: 'confirm',
        color: 'error',
        icon: 'mdi-check',
        className:'',
        hoveredClass: 'success',
        outline: false,
        hover: true,
    },
    abandonAndLeave:{
        command: 'abandonAndLeave',
        labelTag: 'confirm',
        color: 'error',
        icon: 'mdi-check',
        className:'',
        hoveredClass: 'success',
        outline: false,
        hover: true,
    },
    next:{
        command: 'next',
        labelTag: 'next',
        color: 'primary',
        icon: 'mdi-arrow-right-thin-circle-outline',
        className:'',
        outline: false
    },
    previous:{
        command: 'previous',
        labelTag: 'previous',
        color: 'primary',
        icon: 'mdi-arrow-left-thin-circle-outline',
        className:'',
        outline: false
    },
    import:{
        command: 'import',
        labelTag: 'import',
        color: 'primary',
        icon: 'mdi-import',
        className:'',
        outline: false
    },
    gotoTheApp: {
        command: 'gotoTheApp',
        labelTag: 'gotoTheApp',
        color: 'muted',
        icon: 'mdi-rocket-launch',
        className: '',
        outline: false
    },
    share:{
        command: 'share',
        labelTag: 'share',
        color: 'primary',
        icon: 'mdi-share-variant',
        className: '',
        outline: false
    }
}

export default actionsBtns