var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 d-flex pa-2",staticStyle:{"height":"0"}},[_c('div',{staticClass:"flex-grow-1 page-form d-flex flex-column justify-start"},[_c('v-card',{staticClass:"flex-grow-1 ma-0 d-flex flex-column",staticStyle:{"height":"0"},attrs:{"flat":"","tile":""}},[(_vm.panel)?_c('form-title',{ref:"formTitle",attrs:{"builderButton":true,"builderRouteName":"adminPanels","title":_vm.selectedMenuItem?_vm.selectedMenuItem.title:'',"buttons":[],"loading":_vm.loading},on:{"onCommand":_vm.onCommandHandler},scopedSlots:_vm._u([{key:"prepend-action-button-set",fn:function(ref){
var disabled = ref.disabled;
return [_c('div',{staticClass:"panel-date-filter d-flex align-end",staticStyle:{"font-size":"12px"}},[_c('div',[_vm._v(_vm._s(_vm.$t("panel.filter"))+" :")]),_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{staticClass:"ml-1",staticStyle:{"font-size":"12px"},attrs:{"outlined":"","height":"25","items":_vm.dateFields,"item-value":"value","item-text":"name","hide-details":"","menu-props":{offsetY: true, contentClass:'denseList'}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(("widgets." + (item.name)))))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(("widgets." + (item.name)))))])]}}],null,true),model:{value:(_vm.filterDateField),callback:function ($$v) {_vm.filterDateField=$$v},expression:"filterDateField"}})],1),_c('div',{staticStyle:{"min-width":"220px"}},[_c('dialog-period-date',{attrs:{"rules":[],"isLayout":false,"isReadOnly":false,"isPeriod":true,"hasError":false},on:{"input":function (v){ return _vm.dateRange=v; }},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)]),(_vm.elements.length>0)?_c('v-btn',_vm._g({staticClass:"elevation-0 ml-2",attrs:{"fab":"","dark":"","color":"#AC3E79","width":"32","height":"32"},on:{"click":_vm.exportCharts}},_vm.on),[_c('v-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"size":"18"}},[_vm._v("mdi-export-variant")])],1):_vm._e()]}},{key:"prepend-buttons",fn:function(ref){
var disabled = ref.disabled;
return [(_vm.drawerNotEmpty)?_c('discussion-control-button',{staticClass:"ml-1",attrs:{"disabled":disabled},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}}):_vm._e()]}}],null,false,2821872952)}):_vm._e(),_c('v-card-text',{staticClass:"px-0 d-flex flex-column flex-grow-1 panel-wrapper",staticStyle:{"height":"200px","overflow":"auto","background-color":"#f2f2f2"}},[_c('div',{staticStyle:{"background-color":"#f2f2f2"}},[(!_vm.bl_dialog)?_c('GridCanvas',{key:_vm.gridCanvasUpdateKey,attrs:{"bgColor":"#f2f2f2","elements":_vm.elements,"draggable":false,"resizable":false,"bl_hover":false,"isBuilder":false,"extraDateFilter":_vm.extraDateFilter},on:{"update_elements":_vm.update_elements}}):_vm._e()],1)])],1)],1),(_vm.bl_dialog)?_c('v-dialog',{attrs:{"persistent":"","hide-overlay":"","transition":false},model:{value:(_vm.bl_dialog),callback:function ($$v) {_vm.bl_dialog=$$v},expression:"bl_dialog"}},[(_vm.bl_dialog)?_c('PanelCanvas',{ref:"panelCanvas",attrs:{"bl_dialog":_vm.bl_dialog,"isResizing":_vm.isResizing,"minWidth":_vm.minWidth,"maxWidth":_vm.maxWidth,"minHeight":_vm.minHeight,"maxHeight":_vm.maxHeight},on:{"update_bl_dialog":_vm.update_bl_dialog}}):_vm._e()],1):_vm._e(),(_vm.showingChartExportDialog)?_c('ExportDialog',{attrs:{"fields":_vm.exportBtns},on:{"confirm":_vm.exportChartTable},model:{value:(_vm.showingChartExportDialog),callback:function ($$v) {_vm.showingChartExportDialog=$$v},expression:"showingChartExportDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }