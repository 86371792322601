<template>
  <div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t(`workflow.modules.${notificationType}.label.${total > 0 ? 'informTarget' : 'informTargetNormal'}`, {
          total,
        })"
        :hints="$t(`workflow.modules.${notificationType}.remark.informHints`)"
        name="target"
      >
        <div class="workflow-setting-form-each-item-input-widget">
          <FormElementWidget
            :recordData="pickerValue"
            :fieldInfo="memberField"
            specialType="members"
            ignoreRecordId
            :placeholder="$t(`workflow.modules.${notificationType}.placeholder.regularMember`)"
            enableBranchSelect
            supportJobTitleSelection
            multiple
            noIcon
            editAnyway
            widgetTypeMapField="type"
            @onCommand="onCommand"
          />
        </div>
      </FormElement>
      <div class="workflow-setting-form-each-item-input-widget-combox-middle" v-if="withPhoneSelect">
        <ComboBox
          :value="phoneRecipient || null"
          :options="phoneList"
          dependsOnType="phone"
          :onlyPrefix="true"
          :onlySuffix="true"
          :isMultiple="true"
          :placeholder="$t(`workflow.modules.${notificationType}.placeholder.workflowPhoneTarget`)"
          valueFormat="object"
          disableBranchNode
          @changed="v => changed(v, 'phoneRecipient')"
        />
      </div>
       <div class="workflow-setting-form-each-item-input-widget-combox"
        :class="{ 'input-widget-combox-noMiddle': !withPhoneSelect }"
       >
        <ComboBox
          :value="otherRecipient || null"
          :options="list"
          dependsOnType="members"
          :onlyPrefix="true"
          :onlySuffix="true"
          :isMultiple="true"
          :placeholder="$t(`workflow.modules.${notificationType}.placeholder.workflowTarget`)"
          valueFormat="object"
          disableBranchNode
          @changed="v => changed(v, 'otherRecipient')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import Mixin from '../mixin';
import NoticeMixin from './mixin';
import FormElement from './../shared/formElement';
import OperationSettingMixin from './../operation/mixin';
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import FormElementWidget from "@/components/inputWidgets/InputWidget";
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module View Wrapper Components
* Terry Chan
* 28/05/2021
*/
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import NodeUtils from '@/pages/admin/workflow/utils/node';
import {
  FETCH_WORKFLOW_DEPENDENCIES,
  SET_SETTINGS,
  // FORM_SELECTIONS_FIELDS,
} from '@/store/modules/workflow/action_types';
export default {
  name: 'InAppNotificationSetting',
  mixins: [OperationSettingMixin, NoticeMixin, Mixin],
  components: {
    FormElement,
    FormElementWidget,
    ComboBox,
  },
  mounted() {
    this.fetchFields();
  },
  props: {
    withPhoneSelect: {
      type: Boolean,
      default: false,
    },
    notificationType: {
      type: String,
      default: 'inBrowserNotification',
    },
    acceptances: {
      type: Array,
      default: [],
    },
    recipientField: {
      type: String,
      default: 'employee',
    }
  },
  methods: {
    onCommand(payload) {
      console.log("Record.vue.onCommandHandler :: payload: ", payload);
      switch (payload.command) {
        case 'updateFieldValue': 
          this.$set(this.notice, payload.fieldName, payload.fieldValue);
          break;
      }
    },
    changed(v, field) {
      const { notice={} } = this;
      this.$store.dispatch(SET_SETTINGS, {
        notice: {
          ...notice,
          [field]: v,
        }
      });
    },
    fetchFields() {
      // if (this.worksheet) {
        this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
          workflow: this.workflow._id,
          node: this.node._id,
          appId: this.appId,
        });
        // const { worksheet } = this;
        // const formId = worksheet._id || worksheet;
        // this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
        //   appId: this.appId,
        //   formId,
        // });
      // }
    },
  },
  computed: {
    pickerValue() {
      return this[this.recipientField];
    },
    total() {
      const { notice: { recipient=[], otherRecipient=[], phoneRecipient=[] } } = this;
      return recipient.length + otherRecipient.length + phoneRecipient.length;
    },
    list() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));
      return NodeUtils.withAcceptanceOptions({
        dependsOnType: 'members', 
        list: workflowOutputs
      });
    },
    phoneList() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));
      return NodeUtils.withAcceptanceOptions({
        dependsOnType: 'phone', 
        list: workflowOutputs
      });
    },
    memberField() {
      return {
        fieldType: "members",
        type: "dataset",
        fieldId: "recipient",
        type: "dataset",
        label: ' ',
        properties: {
          fixedFieldId: "createdBy",
          authorization: ""
        },
        canCreate: true,
        canEdit: true,
        canView: true
      }
    }
  },
  watch: {
    worksheet: {
      handler(v, o) {
        const oldId = get(v, '_id', v);
        const newId = get(o, '_id', o);
        if (v && oldId !== newId) {
          this.fetchFields();
        }
      },
      deep: true,
    }
  },
};
</script>

<style scoped>
</style>