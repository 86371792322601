<template>
  <div class="w-root" :style="gridStyle">
    <router-view v-if="isMounted"></router-view>
  </div>
</template>

<script>
export default {
  name: "FormWorkflow",
  data(){
    return {
      isMounted:false
    }
  },
  async mounted() {
    const appId = this.$route.params.id;
    // this.$store.dispatch('SET_CURRENT_APP_ID', appId);
    if (!this.currentApp) {
      await this.$store.dispatch("FETCH_APP", { appId });
    }
    this.isMounted = true
  },
  computed:{
    scale() {
      return this.$store.getters.layoutScale;
    },

    gridStyle() {
      const size = Math.ceil((this.scale / 100) * 10);
      return {
        backgroundSize: `${size}px ${size}px`,
      };
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    // isPreviewMode() {
    //   return this.$store.getters.isPreviewMode;
    // },
    // backdropStyle() {
    //   if (this.isPreviewMode) {
    //     return {
    //       backgroundColor: ''
    //     }
    //   }
    // }
  },
}
</script>

<style scoped>
  .w-root {
    background: #f5f5f9;
    /* margin-top: 48px;
    height: calc(100vh - 48px); */
    height: 100vh;
    background-image:
      linear-gradient(to right, #e6e6e6 1px, transparent 1px),
      linear-gradient(to bottom, #e6e6e6 1px, transparent 1px);
    }
</style>


