
<template>
  <div class="d-flex flex-column">
    <div class="rounded grey darken-1 white--text px-3 py-1 d-flex flex-row align-center"
      style="height:35px;">
      <h4>{{ title }}</h4>
      <v-spacer></v-spacer>
      <v-btn
          style="height:27px; width:27px"
          v-if="button"
          x-small
          class="elevation-0"
          dark
          rounded fab
          :color="buttonColor"
          @click="onButtonClicked">
        <v-icon>{{ buttonIcon }}</v-icon>
      </v-btn>
    </div>
    <div class="flex-grow-1" style="position:relative;width:100%;">
      <div style="height:0;min-height:100%;overflow-y:auto;">
       <v-list dense class="d-flex flex-column flex-grow-1 mr-1" style="min-height:100%;">
        <draggable :list="items"
                     group="field"
                     :move="checkMove"
                     @start="startDrag"
                     @end="endDrag"
                     @change="log"
                     class="flex-grow-1 field-list"
                     style="height:100%;">
            <v-list-item @click="onItemClicked(id)"
                        :disabled="isDragDisabled(id)"
                        class="list-group-item"
                        v-for="id in items"
                        :key="id">
                <v-list-item-icon>
                  <v-icon v-if="!isDragDisabled(id)" size="20">mdi-arrow-all</v-icon>
                  <v-icon v-else size="20" color="grey">mdi-repeat-off</v-icon>
                  <!--<v-icon>mdi-format-line-spacing</v-icon>-->
                </v-list-item-icon>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    <div class="d-flex flex-row align-center">
                      <div class="flex-grow-1"
                        style="overflow-x:hidden; font-size:14px">
                        <slot v-bind:id="id">
                          id: {{id}}
                        </slot>
                      </div>
                      <div class="flex-grow-0">
                        <slot name="option" v-bind:id="id">
                        </slot>
                      </div>
                      <!--{{ fieldIdLabelMap[fieldId] }}-->
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import draggable from 'vuedraggable'
  
  export default {
    name: 'fieldDragList',
    components: {draggable},
    model: {
      prop: 'idList',
      event: 'input'
    },
    data () {
      return {
        dragging: false
      }
    },
    props: {
      idList: {
        type: Array,
        default () {
          return []
        }
      },
      excludeFieldIds:{
        type: Array,
        default: []
      },
      button: {
        type: Boolean,
        default: true
      },
      buttonColor: String,
      buttonIcon: String,
      title: String,
      hiddenList: {
        type: Array,
        default: []
      }
    },
    computed: {
      items(){
        return this.idList.filter(id=>!this.hiddenList.includes(id))
      },
      fieldInfos () {
        return this.$store.getters.currentForm.fieldInfos
      }
    },
    methods: {
      isDragDisabled(fieldId){
        var res = this.fieldInfos.find(item=>item.fieldId==fieldId)
        if(res)
          if(this.excludeFieldIds.includes(res.fieldId))
            return true
        // if(this.type=='sorting')
        //   if(res && res.type=='multipleSelection'){
        //     return true
        //   }
        return false
      },
      startDrag (evt, arg) {
        this.$emit('start')
      },
      endDrag () {
        this.$emit('end')
      },
      checkMove ({relatedContext, draggedContext}) {
        this.$emit('dragStart', draggedContext.element)
        return true
      },
      onButtonClicked () {
        this.$emit('onButtonClicked')
      },
      onItemClicked (id) {
        this.$emit('onItemClicked', id)
      },
      log: function (evt) {
        const vm = this
        vm.$emit('input', vm.items)
      }
    }
  }
  </script>
