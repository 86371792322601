<template>
  <v-row no-gutters class="bottom-border" align="center" justify="center">
    <v-col cols="10" class="pa-0 ma-0 right-border">
      <!--form-->
      <v-row no-gutters align="center" justify="center">
        <v-col cols="4" @click="toggleExpand">
          <v-row no-gutters class="d-flex align-center">
            <v-col cols="2">
              <v-icon v-if="expand">mdi-menu-up</v-icon>
              <v-icon v-else>mdi-menu-down</v-icon>
            </v-col>
            <v-col cols="10">
              {{ form.label || $t('roles.noName')}}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            dense
            hide-details
            v-if="
              Object.values(form.views).filter((view) => view.read).length === 0
            "
            @click="track();checkAllViews('read')"
          />
          <span v-else>{{
            Object.values(form.views).filter((view) => view.read).length
          }}</span>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            dense
            hide-details
            v-if="
              Object.values(form.views).filter((view) => view.edit).length === 0
            "
            @click="track();checkAllViews('edit')"
          />
          <span v-else>{{
            Object.values(form.views).filter((view) => view.edit).length
          }}</span>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            dense
            hide-details
            v-if="
              Object.values(form.views).filter((view) => view.delete).length ===
                0
            "
            @click="track();checkAllViews('delete')"
          />
          <span v-else>{{
            Object.values(form.views).filter((view) => view.delete).length
          }}</span>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            dense
            hide-details
            v-model="form.canShare"
            @click="track"
            @change="checkShareForm"
          />
        </v-col>
      </v-row>
      <!--view-->
      <div v-if="expand">
        <v-row
          v-for="[viewId, view] in Object.entries(form.views)"
          :key="viewId"
          no-gutters
          align="center"
          justify="center"
        >
          <v-col :cols="4">
            <v-row no-gutters class="d-flex align-center">
              <v-col cols="2" class="ml-auto d-flex">
                <v-icon>{{viewTypes[view.type].icon}}</v-icon>
              </v-col>
              <v-col cols="10">
                {{ view.label || $t('roles.noName') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="2">
            <v-checkbox
              ref="viewRead"
              dense
              hide-details
              v-model="view.read"
              @click="track"
              @change="onReadChange($event, view)"
            />
          </v-col>
          <v-col :cols="2">
            <v-checkbox
              dense
              hide-details
              v-model="view.edit"
              @click="track"
              @change="onEditDeleteChange($event, view)"
            />
          </v-col>
          <v-col :cols="2">
            <v-checkbox
              dense
              hide-details
              v-model="view.delete"
              @click="track"
              @change="onEditDeleteChange($event, view)"
            />
          </v-col>
          <v-col :cols="2">
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="2">
      <v-menu
        v-model="menu"
        v-if="canAccessForm"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn small text v-bind="attrs" v-on="on">{{
            $t("roles.setting")
          }}</v-btn>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item dense>
              <v-list-item-action>
                <v-switch dense v-model="form.add"></v-switch>
              </v-list-item-action>
              <v-list-item-subtitle>{{
                $t("roles.addRecord")
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-action>
                <v-switch dense v-model="form.allowEditForm"></v-switch>
              </v-list-item-action>
              <v-list-item-subtitle>{{
                $t("roles.allowEditForm")
              }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.view")
                }}</v-list-item-subtitle>

                <v-radio-group v-model="form.read" dense row hide-details>
                  <v-radio
                    v-for="option in radioOptions"
                    :key="option.text"
                    :value="option.value"
                    :label="option.text"
                  />
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.edit")
                }}</v-list-item-subtitle>

                <v-radio-group v-model="form.edit" dense row hide-details>
                  <v-radio
                    v-for="option in radioOptions"
                    :key="option.text"
                    :value="option.value"
                    :label="option.text"
                  />
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.delete")
                }}</v-list-item-subtitle>

                <v-radio-group v-model="form.delete" dense row hide-details>
                  <v-radio
                    v-for="option in radioOptions"
                    :key="option.text"
                    :value="option.value"
                    :label="option.text"
                  />
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.default")
                }}</v-list-item-subtitle>

                <v-checkbox
                  dense
                  hide-details
                  :label="$t('roles.view')"
                  v-model="form.defaultRead"
                  @change="onFormDefaultReadChange"
                />
                <v-checkbox
                  dense
                  hide-details
                  :label="$t('roles.edit')"
                  v-model="form.defaultEdit"
                  @change="onFormDefaultEditDeleteChange"
                />
                <v-checkbox
                  dense
                  hide-details
                  :label="$t('roles.delete')"
                  v-model="form.defaultDelete"
                  @change="onFormDefaultEditDeleteChange"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row no-gutters>
            <v-btn
              text
              color="primary"
              class=""
              @click="showFieldDialog = true"
              >{{ $t("roles.field") }}</v-btn
            >
          </v-row>
        </v-card>
      </v-menu>
    </v-col>
    <FieldSettingDialog
      v-if="showFieldDialog"
      v-model="showFieldDialog"
      :form="form"
      @commandHandler="onFieldCommandHandler"
    />
  </v-row>
</template>
<script>
import FieldSettingDialog from "../dialogs/FieldSettingDialog";
import viewTypes from "@/const/viewTypes"
export default {
  props: {
    form: Object,
  },
  components: {
    FieldSettingDialog,
  },
  data() {
    return {
      expand: false,
      menu: false,
      showFieldDialog: false,
      radioOptions: [
        {
          text: this.$t("roles.all"),
          value: 100,
        },
        {
          text: this.$t("roles.ownedAndSub"),
          value: 80,
        },
        {
          text: this.$t("roles.owned"),
          value: 60,
        },
      ],
      viewTypes: viewTypes
    };
  },
  mounted() {
    this.emitCommand("setCanAccess", {
      form: this.form,
      value: this.canAccessForm,
    });
  },
  methods: {
    track() {
      this.$sensors.track("plus_authorization_add_role", {
        module_name: this.$store.getters.currentApp.title,
        app_id: this.$store.getters.currentApp._id,
        action: this.$sensors.events.plus_authorization_add_role.EDIT
      });
    },
    toggleExpand() {
      this.expand = !this.expand;
    },
    checkAllViews(type) {
      switch (type) {
        case "read":
          this.emitCommand("checkAllReadViews", { form: this.form });
          break;
        case "edit":
          this.emitCommand("checkAllEditViews", { form: this.form });
          break;
        case "delete":
          this.emitCommand("checkAllDeleteViews", { form: this.form });
          break
      }
    },
    checkShareForm(val){
      this.emitCommand("checkShareForm", { form: this.form, value: val });
    },
    emitCommand(command, params) {
      this.$emit("commandHandler", {
        command,
        params,
      });
    },
    onReadChange(val, view) {
      if (val === false && (view.edit === true || view.delete === true)) {
        this.emitCommand("resetView", { view });
      }
    },
    onEditDeleteChange(val, view) {
      if (val === true && view.read === false) {
        this.emitCommand("checkReadView", { view });
      }
    },
    onFormDefaultEditDeleteChange(val) {
      if (val === true && this.form.defaultRead === false) {
        this.emitCommand("checkDefaultRead", { form: this.form });
      }
    },
    onFormDefaultReadChange(val) {
      if (
        val === false &&
        (this.form.defaultEdit === true || this.form.defaultDelete === true)
      ) {
        this.emitCommand("resetDefault", { form: this.form });
      }
    },
    onFieldCommandHandler(payload) {
      this.emitCommand(payload.command, payload.params);
    },
  },
  computed: {
    canAccessForm() {
      return Object.values(this.form.views).some((view) => view.read === true);
    },
  },
  watch: {
    canAccessForm: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.emitCommand("setCanAccess", { form: this.form, value: newVal });
        }
      },
    },
  },
};
</script>
<style scoped>

.right-border {
  border-right: 1px solid #9e9e9e;
}
</style>
