<template>
  <div class="workform-clip-card-container">
    <v-layout align-center class="workform-clip-card" >
      <span
        class="workform-clip-onlyLabel"
        :style="{ background: $vuetify.theme.themes.light.primary }"
      >
        <v-icon small color="white" class="workform-clip-source-icon">
          {{ item.icon || 'mdi-sitemap' }}
        </v-icon>
        {{  renderLabel ? renderLabel(item) : item.label }}
        <span
          class="workform-clip-readyLevel"
        >
          ({{ item.released ? 
          $t('workflow.modules.subWorkflow.label.released') : 
          $t('workflow.modules.subWorkflow.label.pendingRelease') }})
        </span>
      </span>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      item: Object,
      onlyPrefix: Boolean,
      onlySuffix: Boolean,
      renderLabel: Function,
    },
  }
</script>

<style>
</style>
