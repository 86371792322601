<template>
  <v-layout align-center class="w-e-skip-root">
    <v-checkbox
      v-model="value"
      hide-details
      value="skip"
      @change="onChange"
      :label="$t('workflow.modules.subWorkflow.label.returnType')"
      color="primary"
    ></v-checkbox>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          size="16"
          color="primary"
          class="w-e-skip-help-icon"
          v-bind="attrs"
          v-on="on"
        >
          mdi-help-circle
        </v-icon>
      </template>
      <span>{{ $t('workflow.modules.subWorkflow.remark.returnType') }}</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
import Mixin from './../mixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    value: String,
  },
  methods: {
    onChange(v) {
      this.$store.dispatch(SET_SETTINGS, {
        proceedCondition: v,
      });
    },
  }
};

</script>

<style scoped>
  .w-e-skip-root {
    margin-top: 10px;
  }
  .w-e-skip-help-icon {
    margin-left: 5px;
  }
</style>
