<template>
    <v-menu
        offset-y
        :content-class="fullWidth?'witDefaultValueMenuContent menuFullWidth':'witDefaultValueMenuContent'"
        :close-on-content-click="false"
        v-model="showingMenu"
        :attach="`#${attachId}`"
    >
        <v-card
            width="262"
            tile
            style="overflow: hidden"
        >
            <v-text-field
                hide-details
                height="30"
                v-model="searchText"
                class="pa-0 ma-0"
                style="font-size:14px"
            >
                <template v-slot:prepend-inner>
                    <v-icon size="18">mdi-magnify</v-icon>
                </template>
            </v-text-field>
            <div style="overflow: auto; max-height:200px">
                <v-list class="pa-0">
                    <v-list-item
                        v-for="(field, i) in filteredFieldInfos"
                        :key="i"
                        plain
                        class="pa-0"
                        @click="handleFieldClick(field)"
                    >
                        <div class="pl-2" style="width:100%">
                            <font-awesome-icon :icon="getWidget(field.widgetId).icon" class="fa-fw mr-3"></font-awesome-icon> 
                            <span style="font-size:14px" class="ml-2">{{field.label}}</span>
                        </div>
                    </v-list-item>
                </v-list>
            </div>
        </v-card>
    </v-menu>
</template>

<script>
    import widgetHelperMixin from '@/mixins/widgetHelperMixin'
    import CompatibleType from '../const/compatibleType'
    export default {
        mixins: [widgetHelperMixin],
        props:{
            value: Boolean,
            formFieldInfos: Array,
            attachId: String,
            fullWidth:{
                type: Boolean,
                default: true
            },
            fieldType: String
        },
        data(){
            return{
                searchText: '',
                compatibleType: CompatibleType
            }
        },
        computed:{
            showingMenu:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            filteredFieldInfos(){
                var compatibleTypes = this.compatibleType[this.fieldType]
                if(this.searchText){
                    return this.formFieldInfos.filter(field=>
                            field.label.includes(this.searchText)&&
                            (compatibleTypes && compatibleTypes.includes(field.type) || !this.fieldType))
                }else {
                    return this.formFieldInfos.filter(field=>
                        (compatibleTypes && compatibleTypes.includes(field.type)) || !this.fieldType)
                }
            }
        },
        methods:{
            handleFieldClick(field){
                this.showingMenu = false
                this.$emit('fieldClick', field)
            }
        }
    }
</script>