<template>
  <div>
    <ArgumentVariableRow
      moduleType="updateArgument"
      :variable="setting.data"
      :targetWorkflow="workflow"
    />
  </div>
</template>

<script>
import Mixin from './mixin';
import ArgumentVariableRow from './subWorkflow/variable';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ArgumentVariableRow,
  },
};

</script>

<style scoped>
</style>
