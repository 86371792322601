<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :noIcon="noIcon"
      :isHidden="isHidden"
      :tooltipText="description">
      <div class="d-flex flex-row align-start">
      <v-text-field
        v-if="isReadOnly"
        :value="inputValue"
        disabled
        dense
        class="disabled workflow-customized-form-record-element"
      ></v-text-field>
      <v-text-field @focus="focusing=true"
        :class="{ 'workflow-customize-form-record-hide': hideInput }"
        class="workflow-customized-form-record-element"
        v-else
        @blur="onBlur"
        v-model.number="inputValue"
        dense
        @keydown="onKeyDown"
        :autocomplete="false"
        :readonly="isReadOnly"
        :hide-details="isLayout"
        clearable
        single-line
        :type="focusing? 'number':'text'"
        background-color="white"
        :prefix="prefix"
        :suffix="suffix"
        outlined
        :ref="`input_${fieldId}`"
        :placeholder="placeholder"
        :error="hasError"
        :error-messages="errorMessage"
      ></v-text-field>
    </div>       
  </input-widget-container>
</template>

<script>
import inputWidgetContainer from "./comps/InputWidgetContainer";
import mixin from "./mixin";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

export default {
  name: "inputWidgetNumber",
  mixins: [mixin, ],
  components: {
    inputWidgetContainer,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },


  methods: {
    validate(){
      this.hasError = false;
      this.errorMessage = "";
      if (this.isReadOnly) return true;
      if (this.rules.length > 0) {
        if (
          this.rules.some((rule) => {
            const result = rule(this.value);
            if (result !== true) {
              this.errorMessage = result;
              return true;
            }
            return false;
          })
        ) {
          this.hasError = true;
          return false;
        }
      }
      return true
    },
    onKeyDown(e) {
      return e.keyCode === 69 ? e.preventDefault() : true;
    },
    _parseValue(val) {
      if (this.focusing) return val
      else return (val || typeof val === 'number')? this.formatNumber(val): ""
    },
    onBlur() {
      this.focusing = false;
      this.validate()
    },
    getDefaultValue() {
      const rawData = getFieldPropertyValue(this.fieldInfo, "default", "");
      return rawData? this.formatNumber(rawData) : "";
    },
  },
};
</script>
