<template>
    <div class="w-filter-rule-date">
        <v-layout align-start justify-space-between>
            <div class="workflow-setting-condition-select-root">
                <ComboBox
                    :value="rule || null"
                    :options="rules.options"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    :renderLabel="renderConditionLabel"
                    @changed="v => rule = v"
                />
            </div>
            <div class="workflow-setting-columnType-select-root">
                <ComboBox
                    :value="type || null"
                    :options="types"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    @changed="v => type = v"
                />
            </div>
        </v-layout>
        <div v-if="content.type==1">
            <div v-if="content.rule!='empty' && content.rule!='notEmpty'"> 
                <div v-if="content.type==1" class="workflow-setting-columnValue-select-root">
                    <div v-if="content.rule!='withinPeriod' && content.rule!='notWithinPeriod'">
                        <ComboBox
                            :value="dateOpt || null"
                            :options="dateOpts"
                            :placeholder="$t('messages.pleaseEnterNumber')"
                            @changed="v => dateOpt = v"
                        />
                    </div>
                    <div v-else>
                        <v-menu nudge-top="15" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{on}">
                                <v-text-field
                                    class="workflow-setting-input-root"
                                    outlined
                                    v-on="on"
                                    :value="dateRangeText"
                                    readonly
                                    height="36"
                                ></v-text-field>
                            </template>
                            <v-card class="">
                                <v-date-picker
                                    style="width:100%"
                                    v-model="dates"
                                    range
                                ></v-date-picker>
                            </v-card>
                        </v-menu>
                    </div>
                </div>
                <v-layout align-center justify-space-between v-if="dateOpt=='NEXT_' || dateOpt=='PAST_'">
                    <div class="workflow-setting-condition-select-root">
                        <v-text-field
                            v-model="optVal.value"
                            outlined hide-details
                            height="36"
                            class="workflow-setting-input-root"
                        />
                    </div>
                    <div class="workflow-setting-columnType-select-root">
                        <v-checkbox :ripple="false" hide-details="" class="pa-0 ma-0 ml-2 mt-2" :label="$t('calendar.INCLUDETODAY')"  v-model="optVal.bl_today"/>
                    </div>
                </v-layout>
                <div v-if="dateOpt=='DATE_'">
                    <v-menu nudge-top="15" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{on}">
                            <v-text-field
                                class="workflow-setting-input-root"
                                outlined
                                v-on="on"
                                :value="optVal.value"
                                readonly
                                height="36"
                            ></v-text-field>
                        </template>
                        <v-card class="">
                            <v-date-picker
                                style="width:100%"
                                v-model="optVal.value"
                            ></v-date-picker>
                        </v-card>
                    </v-menu>
                </div>
            </div>
        </div>
        <div v-else class="workflow-setting-select-root">
            <div class="w-double-line-filter-select-label" v-if="content.rule=='notWithinPeriod' || content.rule=='withinPeriod'">
                {{ $t('workflow.modules.form.label.from') }}
            </div>
            <RuleSelectField
                :value="dates[0] || null"
                :comboxProps="comboxProps"
                :dependsOnType="fieldType"
                valueFormat="id"
                :formFieldInfos="formFieldInfos"
                :placeholder="(content.rule=='notWithinPeriod' || content.rule=='withinPeriod') ? $t('workflow.modules.form.placeholder.minDate') : null"
                @changed="v => dates.splice(0, 1, v)"
                :fieldProperties="fieldProperties"
            />
            <div v-if="content.rule=='notWithinPeriod' || content.rule=='withinPeriod'" class="w-double-line-filter-select">
                <div class="w-double-line-filter-select-label">
                    {{ $t('workflow.modules.form.label.to') }}
                </div>
                <RuleSelectField
                    :value="dates[1] || null"
                    :comboxProps="comboxProps"
                    :dependsOnType="fieldType"
                    valueFormat="id"
                    :formFieldInfos="formFieldInfos"
                    :placeholder="$t('workflow.modules.form.placeholder.maxDate')"
                    @changed="v => dates.splice(1, 1, v)"
                    :fieldProperties="fieldProperties"
                />
            </div>
        </div>
    </div>
</template>

<script>
import mixin from './mixin'
import { join, get, split, includes } from 'lodash';
import RuleSelectField from '../units/RuleSelectField';
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
export default {
    mixins: [mixin],
    computed:{
        dateRangeText () {
            return join(this.dates, ' - ')
        },
    },
    components: {
        ComboBox,
        RuleSelectField,
    },
    data(){
        return {
            dateOpt: null,
            optVal: {
                value: '',
                bl_today: false
            },
            dateOpts: [{
                id: "TODAY",
                type: 'label',
                label: this.$t('calendar.TODAY'),
                value: "TODAY"
            },{
                id: "YESTERDAY",
                label: this.$t('calendar.YESTERDAY'),
                type: 'label',
                value: "YESTERDAY"               
            },{
                id: "TOMORROW",
                label: this.$t('calendar.TOMORROW'),
                type: 'label',
                value: "TOMORROW"       
            },{
                id: "THISWEEK",
                label: this.$t('calendar.THISWEEK'),
                type: 'label',
                value: "THISWEEK"           
            },{
                id: "LASTWEEK",
                label: this.$t('calendar.LASTWEEK'),
                type: 'label',
                value: "LASTWEEK"               
            },{
                id: "NEXTWEEK",
                label: this.$t('calendar.NEXTWEEK'),
                type: 'label',
                value: "NEXTWEEK"            
            },{
                id: "THISMONTH",
                label: this.$t('calendar.THISMONTH'),
                type: 'label',
                value: "THISMONTH"            
            },{
                id: "LASTMONTH",
                label: this.$t('calendar.LASTMONTH'),
                type: 'label',
                value: "LASTMONTH"             
            },{
                id: "NEXTMONTH",
                label: this.$t('calendar.NEXTMONTH'),
                type: 'label',
                value: "NEXTMONTH"            
            },{
                id: "THISQUARTER",
                label: this.$t('calendar.THISQUARTER'),
                type: 'label',
                value: "THISQUARTER"                
            },{
                id: "LASTQUARTER",
                label: this.$t('calendar.LASTQUARTER'),
                type: 'label',
                value: "LASTQUARTER"        
            },{
                id: "NEXTQUARTER",
                label: this.$t('calendar.NEXTQUARTER'),
                type: 'label',
                value: "NEXTQUARTER"          
            },{
                id: "THISYEAR",
                label: this.$t('calendar.THISYEAR'),
                type: 'label',
                value: "THISYEAR"             
            },{
                id: "LASTYEAR",
                label: this.$t('calendar.LASTYEAR'),
                type: 'label',
                value: "LASTYEAR"
            },{
                id: "NEXTYEAR",
                label: this.$t('calendar.NEXTYEAR'),
                type: 'label',
                value: "NEXTYEAR"
            },{
                id: "NEXT_",
                label: this.$t('calendar.NEXT_'),
                type: 'label',
                value: "NEXT_"                
            },{
                id: "PAST_",
                label: this.$t('calendar.PAST_'),
                type: 'label',
                value: "PAST_"              
            },{
                id: "DATE_",
                label: this.$t('calendar.DATE_'),
                type: 'label',
                value: "DATE_"            
            }],
            dates: [],
        }
    },
    created(){
        if(this.type === 2 || this.rule=="notWithinPeriod" || this.rule=="withinPeriod"){
            this.dates = split(this.value, " - ")
        }else if (this.type === 1) {
            const targetValue = (this.value || '').substring(0, 5)
            if(includes(["NEXT_", "PAST_"], targetValue)){
              var [tag, days, includeToday] = split(this.value, "_")
              this.dateOpt = tag + "_"
              this.optVal.bl_today = includeToday=="true"? true: false
              this.optVal.value = days
            }else if (includes(["DATE_"], targetValue)){
              var [tag, selecteDate] = split(this.value, "_")
              this.dateOpt = tag + "_"
              this.optVal.value = selecteDate
            }else{
              this.dateOpt = this.value
            }
        }
    },
    watch:{
        dateRangeText(val){
            this.value = val
        },
        dateOpt(newVal, oldVal){
            if(newVal == 'DATE_' || newVal == 'NEXT_' || newVal == 'PAST_'){
                if(newVal == 'DATE_'){
                    if(oldVal != null)
                        this.optVal.value = new Date().toISOString().substr(0, 10)
                    this.value = newVal + this.optVal.value
                }
                else{
                    if(oldVal != null)
                        this.optVal.value = ""
                    this.value = newVal + this.optVal.value + '_' + this.optVal.bl_today
                }
            }
            else{
                this.value = newVal
            }
        },
        optVal:{
            handler(newVal, oldVal){
                if(this.dateOpt == 'DATE_' || this.dateOpt == 'NEXT_' || this.dateOpt == 'PAST_')
                    if(this.dateOpt == 'DATE_')
                        this.value = this.dateOpt + newVal.value
                    else
                        this.value = this.dateOpt + newVal.value + '_' + newVal.bl_today
                else
                    this.value = this.dateOpt
            },
            deep: true
        },
        rule(val){
            if(val=="withinPeriod" || val=="notWithinPeriod"){
                this.dateOpt = null
                this.optVal.value = ""
                this.optVal.bl_today = false
            }
        },
    },
}
</script>


<style scoped>
    .w-filter-rule-date {
        margin-bottom: 10px;
    }
    .w-double-line-filter-select-label {
        color: #666;
        text-align: center;
        margin: 5px 0 7px 0;
        font-size: 10px;
    }
</style>