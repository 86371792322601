<template>
  <vxe-table-column class-name="fullCell vxe-cell-departments" :show-overflow="true" v-bind="colDef" 
  :edit-render="{name: 'input',enabled:!readonly}">
    <template v-slot="{ row }">
      <div style="overflow:hidden" :class="rowHeightClass">
        <selected-department-tag
          v-for="departmentInfo in row[fieldId]"
          :hasCloseButton="false"
          :key="departmentInfo._id"
          :departmentId="departmentInfo._id"
          :name="departmentInfo.label"
          color="light"
        ></selected-department-tag>
      </div>
      <quick-action
        :rowId="row['_id']"
        :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="params">
      <edit-department-modal :params="params" :readonly="readonly" :fieldType="fieldType"/>
    </template>
    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import selectedDepartmentTag from "@/components/dialogs/comps/SelectedDepartmentTag";
import mixin from "./mixin";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import EditDepartmentModal from './comps/EditDepartmentModal.vue'

import quickAction from './comps/QuickAction'
import summaryCell from './comps/SummaryCell'


export default {
  name: 'vxeCellDepartments',
  mixins: [mixin],  
  components:{
    selectedDepartmentTag,
    quickAction,
    summaryCell,
    EditDepartmentModal
  },
  props: ['rowHeightClass'],
  data() {
    return {
      showingDialog: false,
    };
  },
  computed: {
    fieldType() {
      return getFieldPropertyValue(this.fieldInfo, "fieldType", "single") !== 'single';
    },
  },
};
</script>


<style lang="scss">
  .department-chip-list > .v-chip__content{
    width:100% !important;
  }
  .vxe-cell-departments {
    .vxe-cell {
  height: 100%;
  max-height: 100% !important;
  padding: 0 !important;
}
  }
</style>