<template>
  <vxe-table-column
    v-bind="colDef"
    :edit-render="{ name: 'input', autoselect: true, enabled: !readonly }"
    sortable
    class-name="fullCell vxeCellPhone"
  >
    <template v-slot:edit="{ row, column }">
      <div class="edit-phone-modal vxe-table--ignore-clear" style="height:100%">
        <tel-input-field
          style="height:100%"
          id="vxeTelInput"
          v-model="row[fieldId]"
          :hideDetails="true"
          :readonly="readonly"
          :items="countries"
          :showCountryCode="showCountryCode"
          :defaultCountryCode="defaultCountryCode"
          :commonCountryCodes="commonCountryCodes"
        />
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import phoneInputMixin from "@/mixins/phoneInputMixin"
import TelInputField from "@/components/form/TelInputField";
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin, phoneInputMixin],
  components: {
    TelInputField,
    summaryCell
  }
};
</script>

<style>

#vxeTelInput div.telInput .v-input__slot{
  padding: 8px !important;
  font-size: 14px;
}
#vxeTelInput div.telInput .v-input__slot .v-text-field__slot input{
  color: #606266 !important;
}

#vxeTelInput div.telInput.v-text-field--outlined fieldset {
  border: 1px solid #dcdfe6;
  border-width: 1px 0px 0px 1px;
}

#vxeTelInput div.telInput.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid #409eff !important;
}
#vxeTelInput .telInputAutoComplete fieldset {
  border: 1px solid #dcdfe6;
  border-width: 1px 0px 0px 0px !important;
}

#vxeTelInput .telInputAutoComplete.v-input--is-focused fieldset {
  border: 1px solid #409eff !important;
}

#vxeTelInput
  div.v-input__prepend-outer
  div.v-select__slot
  div.v-input__append-inner
  .v-icon {
  font-size: 16px;
  color: #c0c4cc !important;
  caret-color: #c0c4cc !important;
}

#vxeTelInput .primary--text {
  color: #c0c4cc !important;
  caret-color: #c0c4cc !important;
}

.edit-phone-modal .v-input__control,
.edit-phone-modal .v-input__slot,
.edit-phone-modal .v-input__prepend-outer,
.edit-phone-modal .v-input {
  height: 100% !important;
  border-radius: 0px;
}
</style>
