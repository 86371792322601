<template>
  <SelectionOptionsSetting
    class="mb-5"
    :useValue="propertyValue.useValue"
    :useColor="propertyValue.useColor"
    :options="options"
    @onCommand="onCommandHandler"
  >
    <template v-slot:label>
      <label>{{ label }}</label>
      <!-- <v-btn 
        class="ml-1" 
        color="primary" 
        @click="addNewOption" 
        x-small
        :disabled="options.length >= 50"
        ><v-icon small>mdi-plus</v-icon></v-btn
      > -->
    </template>
    <template v-slot:prepend-delete="{ item }">
      <v-btn small icon color="success" v-if="item.active" @click="updateOption(item._id, !item.active, 'active')">
         <v-icon small>mdi-eye</v-icon>
      </v-btn>
       <v-btn small icon v-else @click="updateOption(item._id, !item.active, 'active')">
          <v-icon small>mdi-eye-off</v-icon>
       </v-btn>
       
      <v-icon
        v-if="isDefault(item._id)"
        class="mr-1 is-default"
        @click="updateDefault(item._id)"
        small
        >{{ defaultSelectedIcon }}</v-icon
      >
      <v-icon v-else small class="mr-1" @click="updateDefault(item._id)">{{
        defaultUnselectedIcon
      }}</v-icon>
    </template>
  </SelectionOptionsSetting>
</template>

<script>
import mixin from "./mixin";
import selectionOptionMixin from "@/mixins/selectionOptionMixin";
import SelectionOptionsSetting from "@/components/form/SelectionOptionsSetting";


export default {
  mixins: [mixin, selectionOptionMixin],
  components: {
    SelectionOptionsSetting,
  },
  computed: {
    defaultSelectedIcon() {
      return this.propertyKey === "inputOptionsSingle"
        ? "mdi-circle-slice-8"
        : "mdi-square";
    },
    defaultUnselectedIcon() {
      return this.propertyKey === "inputOptionsSingle"
        ? "mdi-circle-outline"
        : "mdi-square-outline";
    },
    fullOptions: {
      get() {
        return (this.propertyValue && this.propertyValue.options) || [];
      },
      set(val) {
        if (this.propertyValue) {
          this.$set(this.propertyValue, 'options', val)
        }
      },
    },
  },
  data() {
    return {

      ITEMS_PER_ROW: 10,
    };
  },
  mounted() {
    this.initNextColorIndex();
  },

  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "new":
          this.addNewOption();
          break;
        case "bulkAddOptions":
          this.bulkAddOptions(payload.options);
          break;
        case "reorder":
          this.reorder(payload.options);
          break;
        case "remove":
          this.removeOption(payload._id);
          break;
        case "updateColorSet":
          this.updateColorSet(payload._id, payload.colorIndex);
          break;
        case "updateProperty":
          this.propertyValue[payload.key] = payload.val;
          break;
        case "updateOption":
          this.updateOption(payload._id, payload.val, payload.key);
          break;
        case "updateValueAssignment":
          this.updateValueAssignment(payload)
          break;
      }
    },

    updateValueAssignment({val:option}) {
      this.$set(this.propertyValue, 'useValue', option.useValue)
      this.fullOptions = option.options      
    },
    checkAndUpdateOptionColors() {
      const vm = this;
      for (let i = 0; i < vm.propertyValue.options.length; i++) {
        if (!vm.propertyValue.options[i].colorSet) {
          vm.propertyValue.options[i].colorSet = vm.getNextColor();
        }
      }
    },

    isDefault(id) {
      const vm = this;
      var result = false;
      var defaultSelection = vm.propertyValue.defaultSelection;

      switch (vm.propertyKey) {
        case "inputOptionsSingle":
          result = defaultSelection === id;
          break;
        case "inputOptionsMultiple":
          result = defaultSelection.indexOf(id) >= 0;
          break;
      }

      return result;
    },
    updateDefault(id) {
      const vm = this;

      switch (vm.propertyKey) {
        case "inputOptionsSingle":
          if (vm.propertyValue.defaultSelection === id) {
            vm.propertyValue.defaultSelection = null;
          } else {
            vm.propertyValue.defaultSelection = id;
          }
          break;
        case "inputOptionsMultiple":
          const index = vm.propertyValue.defaultSelection.indexOf(id);
          if (index === -1) {
            vm.propertyValue.defaultSelection.push(id);
          } else {
            vm.propertyValue.defaultSelection.splice(index, 1);
          }
          break;
      }
    },

  },
};
</script>
<style>
.visibility-checkbox div.v-input--selection-controls__ripple {
  margin-right:0px;
}
</style>
