<template>
  <div class="workflow-setting-form-update-row">
    <FormElement 
      :hints="hints"
      :label="$t(`workflow.modules.${labelType}.label.method`, {
      size: editingSize,
      overrall: options && options.length || 0,
    })">
      <Fields
        v-for="(id, index) in idList"
        :key="index"
        @fieldChanged="(field, v) => fieldChanged(field, v, index)"
        @valueChanged="valueChanged"
        @removed="id => removed(id, index)"
        :fieldId="id"
        :dataSource="dataSource"
        :labelType="labelType"
        :fieldInfo="fetchInfo(id)"
        :disabledLookup="disabledLookup"
        :value="value[id]"
        :fields="options"
        :optionProps="optionProps"
      />
    </FormElement>
    <v-btn :disabled="remainingSize <= 0" elevation="0" rounded @click="addField" class="workflow-setting-form-update-add-btn">
      <v-icon size="18">mdi-plus</v-icon>
      <span>
        {{
          noEditingField ? $t(`workflow.modules.${labelType}.label.addField`) :
          $t(`workflow.modules.${labelType}.label.addRemainField`, {
            size: remainingSize
          }) 
        }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import Mixin from './../mixin';
import { keys, forEach, has, find } from 'lodash';
import FormElement from './../shared/formElement';
import Fields from './fields';
import SettingUtils from "@/pages/admin/workflow/utils/setting";
// import {
//   SET_SETTINGS,
// } from '@/store/modules/workflow/action_types';

/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    value: {
      type: Object,
      default: {},
    },
    hints: String,
    worksheet: String,
    optionProps: Object,
    dataSource: Object,
    labelType: {
      type: String,
      default: 'updateRecord',
    },
    missingFields: {
      type: Array,
      default: ()=>[],
    },
    missingFieldsSize: {
      type: Number,
      default: 0,
    },
    fields: {
      type: Array,
      default: ()=> [],
    }
  },
  data() {
    this.newFieldNameId = '_addField_';
    return {
      disabledLookup: {},
      editingSize: 0,
      idList: [],
      remainingSize: 0,
      index: -1,
    };
  },
  mounted() {
    this.prepare();
  },
  methods: {
    prepare() {
      const { value } = this;
      this.disabledLookup = { ...value };
      this.idList = keys(value);
      this.updateFieldSize();
    },
    keyList(v) {
      return keys(v);
    },
    reset() {
      this.disabledLookup = {};
      this.editingSize = 0;
      this.idList = [];
      this.remainingSize = 0;
      this.index = -1;
    },
    fieldChanged(field, v, index) {
      const id = v && v._id || '';
      if (field === this.newFieldNameId) {
        this.$delete(this.value, this.newFieldNameId);
      } else if (has(this.value, field) && field !== id) {
        this.$delete(this.value, field);
        this.$delete(this.disabledLookup, field);
      }

      if (v !== undefined) {
        this.$set(this.value, id, this.value[id] ?? null);
        this.$set(this.disabledLookup, id, v);
        this.idList.splice(index, 1, id);
        this.index += 1;
      } else {
        this.value = {};
        this.reset();
      }
    },
    removed(id, index) {
      this.$delete(this.value, id);
      this.$delete(this.disabledLookup, id);
      this.idList.splice(index, 1);
      this.index -= 1;
    },
    addField() {
      // add new field to empty key-pair
      const { index } = this;
      const pendingInput = index > -1 && this.idList[index] === this.newFieldNameId;
      
      if (!pendingInput || index === -1) {
        this.$set(this.value, this.newFieldNameId, null);
        this.index += 1;
        this.idList.splice(this.index, 1, this.newFieldNameId);
      }
    },
    fetchInfo(id) {
      const { options } = this;
      const info = find(options, f => f._id === id);
      if (info) {
        // info.type = info.fieldType;
        // for input widget initial, bypass isHidden condition
        info.canView = true;
        info.canCreate = true;
        return info && { ...info };
      }
      return null;
    },
    updateFieldSize() {
      const { options, missingFieldsSize } = this;
      const idsSize = this.keyList(this.value).length;
      const allIdsSize = options && options.length;
      this.remainingSize = allIdsSize < idsSize ? 0 : allIdsSize - idsSize;
      this.editingSize = idsSize - this.missingFieldsSize;
    }
  },
  computed: {
    noEditingField() {
      const { options } = this;
      return options && this.remainingSize === options.length;
    },
    options() {
      let fields = [];
      forEach(this.fields, field => {
        if (!SettingUtils.isObjectDateType(field.fieldType)) {
          fields.push({
              ...field,
              isDisabled: has(this.disabledLookup, field._id),
            })
        }
      });
      return fields;
    }
  },
  watch: {
    value: {
      handler() {
        this.updateFieldSize();
        this.prepare();
      },
      deep: true,
    },
    fields: {
      handler() {
        this.updateFieldSize();
      },
      deep: true,
    },
    worksheet: {
      handler() {
        this.value = {};
        this.reset();
      },
      deep: true,
    },
  },
  components: {
    FormElement,
    Fields,
  },
  // getFormSelectionsFields
};

</script>

<style scoped>
  
</style>
