<template>
  <div>
    <label>{{ label }}</label>
     <v-textarea
      label=""
      :value="propertyValue"
      @input="val=>{propertyValue=val}"
      dense
      rows="5"
      hide-details="auto"
      outlined></v-textarea>
    <!-- <v-textarea
        label=""
        :value="propertyValue"
        @change="val=>{propertyValue=val}"
        rows="5"
        dense
        hide-details="auto"
        outlined></v-textarea> -->
    <!-- 
        <v-text-field
      label=""
      :value="propertyValue"
      @input="val=>{propertyValue=val}"
      dense
      hide-details="auto"
      outlined></v-text-field> -->
  </div>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin]
}
</script>
