const mixin = {
    methods:{
        setStyleXAxis(bl_xLabel, bl_xRotate, bl_xTitle, xTitle){
            if(this.selectedChart == 'PIE' || this.selectedChart == 'RADAR' || this.selectedChart == 'FUNNEL') return
            this.setXAxisLabel(bl_xLabel)
            this.setXAxisRotate(bl_xRotate)
            this.setXAxisTitle(bl_xTitle, xTitle)
        },
        setStyleYAxis(bl_displayScale, lineStyle, bl_yTitle, yTitle, yMin, yMax){
            if(this.selectedChart == 'PIE' || this.selectedChart == 'RADAR' || this.selectedChart == 'FUNNEL') return
            this.setYAxisScale(bl_displayScale, lineStyle)
            this.setYAxisTitle(bl_yTitle, yTitle)
            this.setYAxisFilter(yMin, yMax)
        },
        setStyleTag(bl_showData){
            let vm = this
            if(this.selectedChart=='LINE' || this.selectedChart=='BAR' || this.selectedChart=='RADAR'){
                if(bl_showData){
                    this.option.series.forEach((item,index,array)=>{
                        array[index].label.show = true
                    })
                }else{
                    this.option.series.forEach((item,index,array)=>{
                        array[index].label.show = false
                    })
                }
            }

            if(this.selectedChart=='PIE'){
                if(this.option.series.length){
                    if(vm.styleSetupToggles.bl_label){
                        this.option.series[0].label.show = true
                            var formatter = ""
                            // if(vm.styleSetups.label.bl_showLabel){
                            //     formatter = "{b} "
                            // }
                            // if(vm.styleSetups.label.bl_showData){
                            //     formatter+= "{c} "
                            // }
                            // if(vm.styleSetups.label.bl_showPercent){
                            //     formatter+= "({d}%)"
                            // }
                            this.option.series[0].label.formatter = (param)=>{
                                var res = ''
                                if(vm.styleSetups.label.bl_showLabel){
                                    res+= param.data.name
                                }
                                if(vm.styleSetups.label.bl_showData){
                                    if(vm.dataSetups.arrY[0].type=='amount')
                                        res+= ' ' + new Intl.NumberFormat().format(param.data.value)
                                    else
                                        res+= ' ' + param.data.value
                                }
                                if(vm.styleSetups.label.bl_showPercent){
                                    res+= ' (' + param.percent + '%)'
                                }
                                return res
                            }
                            // this.option.series[0].label.formatter = formatter
                        
                    }else{
                        this.option.series.forEach((item,index,array)=>{
                            array[index].label.show = false
                            array[index].label.formatter = ""
                        })
                    }
                    this.option.series[0].label.overflow="break"
                }
            }
            
            if(this.selectedChart=='FUNNEL'){
                if(this.option.series.length){
                    if(vm.styleSetups.label.bl_showLabel){
                        this.option.series[0].label.show = true
                    }else{
                        this.option.series.forEach((item,index,array)=>{
                            array[index].label.show = false
                        })
                    }
                }
            }
          
        },
        //X Axis
        setXAxisLabel(bl_xLabel){
            var xAxis = this.styleSetups.chartFigure=="horizontal"? this.option.yAxis:this.option.xAxis
            if(bl_xLabel)
                xAxis[0].data = this.dataSetups.dataX
            else 
                xAxis[0].data = []
        },
        setXAxisRotate(bl_xRotate){
            var xAxis = this.styleSetups.chartFigure=="horizontal"? this.option.yAxis:this.option.xAxis
            if(xAxis && Array.isArray(xAxis)){
                xAxis.forEach((item, index, array)=>{
                    array[index].axisLabel = {}
                    array[index].axisLabel.overflow = 'truncate'
                    array[index].axisLabel.interval = 0
                    array[index].axisLabel.width = 80
                    if(bl_xRotate){
                        array[index].axisLabel.rotate = 45
                        if(this.styleSetups.chartFigure=="horizontal"){
                            array[index].axisLabel.width = this.chart.getWidth()*0.15
                            this.option.grid = [{left:'15%', bottom: '15%'}]
                        }else{
                            array[index].axisLabel.width = this.chart.getHeight()*0.15
                            this.option.grid = [{bottom: '15%'}]
                        }
                    }
                })
            }
            if(!bl_xRotate) delete this.option?.grid
        },
        setXAxisTitle(bl_xTitle, xTitle){
            var xAxis = this.styleSetups.chartFigure=="horizontal"? this.option.yAxis:this.option.xAxis
            if(!bl_xTitle){
                xAxis[0].name = ''
            }else{
                xAxis[0].name = xTitle
                xAxis[0].nameLocation = 'middle'
                xAxis[0].nameTextStyle = {fontWeight:'bold'}
                xAxis[0].nameGap = xAxis[0].nameLocation == 'middle' ? 30:10
            }
        },

        //Y Axis
        setYAxisScale(bl_displayScale, lineStyle){
            var yAxis = this.styleSetups.chartFigure=="horizontal"? this.option.xAxis:this.option.yAxis
            console.log({bl_displayScale, lineStyle, yAxis})
            if(bl_displayScale){
                yAxis.forEach((item,index,array)=>{
                    array[index].splitLine={}
                    array[index].splitLine.show = true
                    array[index].splitLine.lineStyle = {type: lineStyle}
                })
            }else{
                yAxis.forEach((item,index,array)=>{
                    array[index].splitLine={}
                    array[index].splitLine.show = false
                })
            }
        },
        setYAxisTitle(bl_yTitle, yTitle){
            var yAxis = this.styleSetups.chartFigure=="horizontal"? this.option.xAxis:this.option.yAxis
            if(!bl_yTitle){
                yAxis[0].name = ''
            }else{
                yAxis[0].name = yTitle
                yAxis[0].nameLocation = 'middle'
                yAxis[0].nameTextStyle = {fontWeight:'bold'}
                yAxis[0].nameGap = yAxis[0].nameLocation == 'middle' ? 40:10
            }
        },
        setYAxisFilter(yMin, yMax){
            var yAxis = this.styleSetups.chartFigure=="horizontal"? this.option.xAxis:this.option.yAxis
            yAxis[0].min = (yMin)? yMin : null
            yAxis[0].max = (yMax)? yMax : null
            // vm.option.series.forEach((item, index, array)=>{
            //     item.data.forEach((item2,index2,array2)=>{
            //         let bl_hide = false 
            //         var itemValue = typeof item2 == 'object'? item2.value:item2
            //         console.log('itemValue', itemValue)
            //         if(yMax!=null && yMax!=undefined && yMax!="")
            //             bl_hide = (itemValue>yMax)
            //         if(yMin!=null && yMin!=undefined && yMin!="")
            //             if(itemValue<yMin) bl_hide = true
            //         if(bl_hide){
            //             let obj = {
            //                 value: itemValue,
            //                 itemStyle:{
            //                     barBorderColor: 'rgba(0,0,0,0)',
            //                     color: 'rgba(0,0,0,0)'
            //                 },
            //                 label: {
            //                     position: 'bottom',
            //                     offset: [0, -20]
            //                 }, 
            //             }
            //             array2[index2] = obj
            //         }else{
            //             array2[index2] = itemValue
            //         }
            //     })
            // })
        }


       
    }
}

export default mixin