import MediaHelper from '@/helpers/MediaHelper';

const uploadMixin = {
  data () {
    return {
      uploading: false
    }
  },
  computed: {
    appId() {
      return this.$store.getters.currentApp && this.$store.getters.currentApp._id || "";
    },
    formId () {
      return this.$store.getters.currentForm ?
        this.$store.getters.currentForm._id : 
        '';
    }
  },
  methods: {
    removeAttachmentFile (file, callback) {
      MediaHelper.removeMedia(
        {
          mediaId: file._id,
          objectKey: file.objectKey
        },
        () => {
          callback(file);
        }
      );
    },
    uploadFiles(files, callback) {
      const vm = this
      const payload = {
        appId: vm.appId,
        formId: vm.formId,
        files: files,
        isPublic: false
      };
      vm.uploading = true;
      MediaHelper.uploadFiles(payload, (res) => {
        vm.uploading = false;
        if (res.result === false){
          this.storageExceed = true
          vm.$toast(vm.$t('messages.storageExceeded', {
            icon: 'error',
            dismissable: true,
            showClose: true
          }))
        } else {
          if (typeof callback === 'function') {
            callback({
              mediaIds: res.map(media => media._id),
              mediaTypes: res.map(media => media.mediaType),
              objectKeys: res.map(media => media.objectKey),
              originalNames: res.map(media => media.originalName),
              thumbnailUrls: res.map(media => media.thumbnailUrl)
            });
          }
        }
      })
    }
  }
}

export default uploadMixin;
