import { render, staticRenderFns } from "./end.vue?vue&type=template&id=70709d7a&scoped=true&"
import script from "./end.vue?vue&type=script&lang=js&"
export * from "./end.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70709d7a",
  null
  
)

export default component.exports