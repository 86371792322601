<template>
  <v-dialog v-model="showingDialog" persistent width="290">
    <v-card>
      <dialog-header
        @close="cancel"
        label="Move Member"
        :color="options.color"
      />
      <v-card-text>
        Move members to
        <v-select
          dense
          hide-details
          outlined
          :items="roleOptions"
          item-value="_id"
          :item-text="getLabel"
          :menu-props="{offsetY: true, contentClass: 'denseList'}"
          v-model="selectedRoleId"
        />
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>

        <v-btn
          class="min-width-100 muted"
          color="depressed"
          @click.native="cancel"
          >Cancel</v-btn
        >

        <v-btn
          class="min-width-100"
          :color="options.color"
          @click.native="agree"
          >Move</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
  props: {
    roles: Array,
  },
  components: {
    dialogHeader,
  },
  data() {
    return {
      showingDialog: false,
      roleOptions: [],
      options: {
        color: "error",
      },
      selectedRoleId: "",
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open(roleId, options = null) {
      this.roleOptions = this.roles.filter((role) => role._id !== roleId);
      this.selectedRoleId = this.roleOptions[0]._id;
      if (options) Object.assign(this.options, { ...this.options, ...options });
      this.showingDialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getLabel(role) {
      if (!!role.label) return role.label;
      else if (role.permission === 100) return this.$t("roles.admin");
      else return this.$t("roles.noName");
    },
    agree() {
      this.resolve(this.selectedRoleId);
      this.showingDialog = false;
    },
    cancel() {
      this.reject(null);
      this.showingDialog = false;
    },
  },
};
</script>
