<template>
  <v-menu
    v-model="showingMenu"
    :close-on-content-click="true"
    offset-y
    bottom>
    <template v-slot:activator="{on, attrs}">
      <v-btn :color="selectedColor"
             v-bind="attrs"
             v-on="on"
             class="color-circle" fab>
      </v-btn>
    </template>
    <v-card class="pa-1">
      <div v-for="(colorSets, rowIndex) in colorRows"
           class="color-row"
           :key="rowIndex">
        <v-btn v-for="(colorSet, colIndex) in colorSets"
               :key="colIndex"
               fab
               class="color-circle"
               :class="{'selected':value===colorSet}"
               :color="colorSet.split('/')[1]"

               @click="onBtnClicked(rowIndex, colIndex)">
          <v-icon
              :style="{color: colorSet.split('/')[0]}"
              v-if="value===colorSet" x-small>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      ITEMS_PER_ROW: 10,
      showingMenu: false
    }
  },
  props: {
    value: String
  },
  computed: {
    colorRows () {
      const vm = this

      var result = []
      var items = []
      var itemIndex = 0
      for (let i = 0; i < vm.colorSets.length; i++) {
        const loopColorSet = vm.colorSets[i]
        itemIndex = i % vm.ITEMS_PER_ROW
        items.push(loopColorSet)
        if (itemIndex === vm.ITEMS_PER_ROW - 1) {
          result.push(items)
          items = []
        }
      }
      if (itemIndex < vm.ITEMS_PER_ROW - 1) {
        for (let j = itemIndex + 1; j < vm.ITEMS_PER_ROW; j++) {
          items.push(null)
        }
        result.push(items)
      }
      console.log('computed(colorRows) :: result: ', result)
      return result
    },
    colorSets () {
      return this.$store.getters.colorSets
    },
    selectedColor () {
      const vm = this
      const colorPair = vm.value.split('/')
      return colorPair[1]
    }
  },
  methods: {
    onBtnClicked (rowIndex, colIndex) {
      const vm = this
      const colorIndex = rowIndex*vm.ITEMS_PER_ROW + colIndex
      vm.$emit('input', colorIndex)
      // const color = rowIndex === 0 ? '#000000' : '#FFFFFF'
      // const backgroundColor = vm.colorRows[rowIndex, colIndex]
      // const colorSet = color + '/' + backgroundColor
      // vm.$emit('input', colorSet)
    }
  }
}
</script>


<style>
.color-row .color-circle {
  height: 24px !important;
  width: 24px !important;
  min-width: 24px !important;
  border: 2px solid transparent;
}

.color-row .color-circle.selected {
  border-color: blue;
}

.color-row .v-btn {
  margin-right: 5px;
  margin-bottom: 5px;
}

.color-row .v-btn:last-child {
  margin-right: 0;
}

.color-row:last-child .v-btn {
  margin-bottom: 0;
}
</style>
