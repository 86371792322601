<template>
  <loading-icon v-if="loading"/>
  <div v-else class="form-builder flex-grow-1 d-flex flex-row fill-height">
    <!-- Column #0 - widget selection -->
    <div class="widget-list d-flex flex-column">
      <widget-panel 
        :widgetGroups="widgetGroups"
        @onCommand="onCommandHandler"></widget-panel>
    </div>
    <!-- layout design -->
    <div class="d-flex flex-column flex-grow-1" style="width:0px">
      <div class="align-self-stretch pa-2 d-flex flex-row">
        <!-- {{ $t('admin.formDesign')}}
        <v-chip v-if="formFieldInfos && formUIElementInfos"
                class="ml-2"
                small
                color="info">
          {{ formFieldsExcludingSystemFields.length + formUIElementInfos.length }}</v-chip> -->
        <v-spacer></v-spacer>
        <!-- {{(form ? form.label : '') }} -->
        <!-- <v-spacer></v-spacer> -->

        <!-- ADD DEFAULT -->
        <v-btn v-if="formFieldsExcludingSystemFields.length===0"
          class="ml-2"
          small @click="addDefault" color="primary">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('widgets.addDefault') }}
        </v-btn>

        <!-- CLEAR ALL -->
           <!--
           await vm.$store.dispatch('SET_FORM_BUFFER', {form: vm.$store.getters.currentForm})
           -->
        <!-- Reset -->
        <v-btn :disabled="!formChanged"
              class="min-width-100 ml-2"
               small @click="reset" color="warning">
          <v-icon left>mdi-undo-variant</v-icon>
          {{ $t('buttons.reset') }}
        </v-btn>

        <!-- Clear All -->
        <v-btn v-if="formFieldsExcludingSystemFields.length>0"
              class="min-width-100 ml-2"
               small @click="clearAll" color="error">
          <v-icon left>mdi-alert-remove</v-icon>
          {{ $t('widgets.clearAll') }}
        </v-btn>
      </div>
      <div class="d-flex flex-row align-center mb-2 mr-2">
        <h4 class="px-5 pt-3 pb-0 lightgrey">{{ $t('admin.title') }}:
          <v-chip v-if="titleFieldName"
            color="primary" 
            text-color="white"            
            label>
            {{ titleFieldName }}</v-chip>
        </h4>
        <v-spacer/>
        <h4 class="mr-2">
          {{ $t('general.widgets') }}
        </h4>
        <v-chip 
          color="primary"
          text-color="white"
          class="py-0 px-2"
          style="height:24px;min-height:24px;">
          {{ formFieldsExcludingSystemFields.length + formUIElementInfos.length }}
        </v-chip>
      </div>
      <form-layout ref="formLayout"
                   v-if="form"
                   :selectedItem="selectedFieldInfo"
                   @onCommand="onCommandHandler"></form-layout>
    </div>
    <!-- property panel -->
    <div class="property-list d-flex flex-column" style="position:relative;border-left:1px solid rgba(0,0,0,.05); width:300px">
      <field-property-list
          style="position:absolute;width:300px;height:100%;left:0;top:0;background-color:lightskyblue;"
          v-if="selectedFieldInfo"
          :fieldId="selectedFieldInfo.fieldId && initialized"
          :fieldInfoId="selectedFieldInfo._id"
          :fieldType="selectedFieldInfo.type"
          :widgetId="selectedFieldInfo.widgetId"
          :properties="selectedFieldInfo.properties"
          :masterFieldInfos="formFieldInfos"
          :currentFieldInfos="formFieldInfos"
          @onCommand="onCommandHandler"></field-property-list>
      <transition name="slide-fade" v-if="initialized">
        <field-property-list
            v-if="showChildTableFieldProperty"
            style="position:absolute;width:300px;height:100%;left:0;top:0;background-color:lightskyblue;"
            :ofChildTable="true"
            :childTableFieldInfoId="childFieldInfo.childTableFieldInfoId"
            :label="childFieldInfo.parentLabel"

            :fieldInfoId="childFieldInfo.fieldInfoId"
            :fieldType="childFieldInfo.type"
            :widgetId="childFieldInfo.widgetId"
            :properties="childFieldInfo.properties"
            :masterFieldInfos="formFieldInfos"
            :currentFieldInfos="childTableFieldInfos"

            @onCommand="onCommandHandler"
            @close="closeFieldPropertyList"></field-property-list>
        <!--:fieldId="childFieldInfo.fieldId"-->
        <!--<div v-if="showChildTableFieldProperty"-->
        <!--style="position:absolute;width:300px;height:100%;left:0;top:0;background-color:lightskyblue;">-->
        <!--<v-btn @click="showChildTableFieldProperty=false">Back</v-btn>-->
        <!--</div>-->
      </transition>
    </div>

    <confirm-dialog-with-textfield ref="confirmWithTextFieldDialog"></confirm-dialog-with-textfield>

    <child-table-dialog ref="childTableDialog"
                        @onCommand="onCommandHandler"></child-table-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import mixin from '../mixin'
import baseMixin from '@/mixins/baseMixin'
import helpersMixin from '@/mixins/helpersMixin'
import adminFormMixin from '@/mixins/adminFormMixin'
import widgetHelperMixin from '@/mixins/widgetHelperMixin'


import widgetPanel from '@/components/WidgetPanel'

import childTableDialog from './formBuilder/dialogs/ChildTableDialog'
import confirmDialog from '@/components/dialogs/ConfirmDialog'

import formLayout from './formBuilder/FormLayout'
import fieldPropertyList from './formBuilder/FieldPropertyList'
import confirmDialogWithTextfield from '@/components/dialogs/ConfirmDialogWithTextfield'
import LoadingIcon from '../../../components/global/loadingIcon'


export default {
  name: 'formBuilder',
  mixins: [
    mixin,
    baseMixin,
    helpersMixin,
    adminFormMixin,
    widgetHelperMixin
  ],
  components: {

    widgetPanel,

    formLayout,
    fieldPropertyList,
    confirmDialogWithTextfield,
    childTableDialog,
    confirmDialog,
    LoadingIcon
  },
  computed: {
    widgetGroups () {
      var result = this.$store.getters.widgetGroups
      if (this.isHiddenForDeployment('relation')) {
        result = result.filter(item => item.titleTag !== 'relation')
      }
      return result
    },

    isChildTable () {
      return this.childTableFieldInfoId !== ''
    },

    showChildTableFieldProperty () {
      return this.isChildTable
    },

    childTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.isChildTable) {
        result = vm.childTableInfo.properties.fieldsEditor.fieldInfos
      }
      return result
    },
    childFieldInfo () {
      const vm = this
      if (vm.childTableFieldInfoId === '') {
        return {
          parentLabel: '',
          fieldId: '',
          widgetId: '',
          properties: {}
        }
      } else {
        return {
          childTableFieldInfoId: vm.childTableInfoId,
          parentLabel: vm.childTableInfo.label,
          fieldInfoId: vm.childTableFieldInfoId,
          widgetId: vm.childTableFieldInfo.widgetId,
          type: vm.childTableFieldInfo.type,
          properties: vm.childTableFieldInfo.properties
        }
      }
    },
    formChanged() {
      const vm = this;
      var result = false;
      if (
        vm.formUIElementInfos &&
        vm.formFieldInfos &&
        vm.formLayoutConfig &&
        vm.form
      ) {
        const sFormUIElementInfos = JSON.stringify(vm.formUIElementInfos);
        const sFormFieldInfos = JSON.stringify(vm.formFieldInfos);
        const sFormLayoutConfig = JSON.stringify(vm.formLayoutConfig);

        const fieldInfos = vm.form.fieldInfos


        result =
          sFormFieldInfos !== JSON.stringify(fieldInfos) ||
          sFormUIElementInfos !== JSON.stringify(vm.form.uiElementInfos) ||
          sFormLayoutConfig !== JSON.stringify(vm.form.layout) ||
          vm.formInfo.titleFieldInfoId !== vm.form.titleFieldInfoId ||
          JSON.stringify(vm.formInfo.linkedFields) !== JSON.stringify(vm.form.linkedFields)

      } else {
        // if (!vm.formUIElementInfos)
        //   console.log("formUIElementInfos not defined");
        // if (!vm.formFieldInfos)
        //   console.log("formFieldInfos not defined");
        // if (!vm.formLayoutConfig)
        //   console.log("formLayoutConfig not defined");
        // if (!vm.formInfo)
        //   console.log("formInfo not defined");
        // if (!vm.form)
        //   console.log("form not defined");
      }
      return result;
    },
    // as a switch
    // editingChildTableField () {
    //   return this.$store.getters.editingChildTableField
    // },

    childTableInfo () {
      return this.$store.getters.editingChildTableInfo
    },
    childTableFieldInfo () {
      return this.$store.getters.editingChildTableFieldInfo
    },

    childTableInfoId () {
      return this.$store.getters.editingChildTableInfoId
    },
    childTableFieldInfoId () {
      return this.$store.getters.editingChildTableFieldInfoId
    },
    titleFieldName () {
      return this.$store.getters.titleFieldName
    },
    selectedFieldInfo () {
      const vm = this
      let result = null
      if (vm.selectedFormFieldId) {
        // console.log('FormBuilder::selectedFieldInfo: fieldId', vm.selectedFormFieldId)
        result = vm.getFieldInfo(vm.selectedFormFieldId)
        
      }

      return result
    },
    formFieldInfos () {
      return this.$store.getters.formFieldInfos
    },
    formInfo () {
      return this.$store.getters.formInfo
    },
    selectedFormFieldId () {
      return this.$store.getters.selectedFormFieldId
    },
    formFieldsExcludingSystemFields() {
      return this.formFieldInfos.filter(field => !field.isSystem)
    }

  },
  watch: {
    selectedFormFieldId () {
      this.$store.dispatch('RESET_CHILD_TABLE_FIELD')
      this.showChildTableFieldProperty = false
    }

  },
  data () {
    return {
      loading: false,
      row: null,
      titleFieldId: 'firstname',
      initialized: false
    }
  },
  async mounted () {
    this.loading = true;
    const formId = this.$route.params.itemId;
    // await this.$store.dispatch('FETCH_FORM', {formId: formId, appId:this.$route.params.id})
    await this.createFormBuffer(formId, ()=>{
      this.initialized = true
    })
    this.updateTitleFieldNameByInfoId();
    await this.$store.dispatch('RESET_CHILD_TABLE_FIELD')
    this.loading = false
  },
  methods: {
    reset () {
      const vm = this
      vm.$refs.confirmDialog.open(
        vm.$t('general.confirmation'),
        vm.$t('messages.originalFormWillBeRecovered'),
        {
          color: 'warning',
          confirmBtnColor: 'warning'
        }
      ).then(result => {
        if(result) {
          this.$store.dispatch('SET_FORM_BUFFER', {form: this.$store.getters.currentForm})
        }
      })
      
    },
    closeFieldPropertyList () {
      const vm = this
      vm.showChildTableFieldProperty = false
      vm.$store.dispatch('SET_CHILD_TABLE_FIELD_ID', '')
    },
    getFieldInfo (infoId) {
      const vm = this
      var result = null
      if (vm.formFieldInfos) {
        result = vm.formFieldInfos.find(fieldInfo => fieldInfo._id === infoId)
      }
      if (!result && vm.formUIElementInfos) {
        result = vm.formUIElementInfos.find(info => info._id === infoId)
      }
      if (!result && vm.formChildTableInfos) {
        result = vm.formChildTableInfos.find(info => info._id === infoId)
      }

      return result
    },

    addDefault () {
      this.$store.dispatch('ADD_DEFAULT_FIELDS')
    },

    clearAll () {
      const vm = this
      vm.$refs.confirmWithTextFieldDialog.open({
        requiredWord: 'DELETE',
        title: vm.$t('widgets.attention'),
        message: vm.$t('messages.allWidgetsWillBeRemoved'),
        color: 'error',
        callback: () => {
          this.$store.dispatch('CLEAR_ALL')
          // alert('removed')
        }
      })

    },
    updateTitleFieldNameByInfoId (titleFieldInfoId) {
      const vm = this
      var result = ''
      // console.log('updateTitleFieldNameByInfoId :: titleFieldInfoId = ' + titleFieldInfoId)
      if (vm.formInfo && vm.formFieldInfos) {
        // console.log('updateTitleFieldNameByInfoId :: formInfo & formFieldInfos are defined')
        if (typeof titleFieldInfoId === 'undefined') {
          titleFieldInfoId = vm.formInfo.titleFieldInfoId
        }

        const titleFieldInfo = vm.formFieldInfos.find(item => item._id === titleFieldInfoId)
        if (titleFieldInfo) {
          result = titleFieldInfo.properties['fieldName']
        }
      } else {
        // if (!vm.formInfo)
        //   console.log('updateTitleFieldNameByInfoId :: vm.formInfo not defined')
        // if (!vm.formFieldInfos)
        //   console.log('updateTitleFieldNameByInfoId :: vm.formFieldInfos not defined')
      }
      vm.titleFieldName = result
    },

    getTitleFieldName () {
      const vm = this
      var result = ''
      if (vm.formInfo && vm.formInfo.titleFieldInfoId && vm.formFieldInfos) {
        const titleFieldInfo = vm.formFieldInfos.find(item => item._id === vm.formInfo.titleFieldInfoId)
        if (titleFieldInfo) {
          result = titleFieldInfo.properties['fieldName']
        }
      }
      return result
    },

    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'addWidget':
          vm.addWidget(payload.widget)
          break;
        case 'setFieldProperty':
          vm.$store.dispatch('SET_FIELD_INFO_PROPERTY', payload)
          break
        // case 'selectItem':
        //   alert('selectItem')
        //   break
      }
    },
    getRowIndex (selectedItem) {
      const vm = this
      var result = vm.formLayoutConfig.rows.length
      if (selectedItem) {
        var found = false
        const itemId = selectedItem._id
        for (var i = 0; i < vm.formLayoutConfig.rows.length; i++) {
          const loopRow = vm.formLayoutConfig.rows[i]
          for (var j = 0; j < loopRow.length; j++) {
            const loopItem = loopRow[j]
            if (loopItem._id === itemId) {
              result = i + 1
              found = true
              break
            }
          }
          if (found) break
        }
      }
      return result
    },
    addWidget (widget) {
      const vm = this;
      const selectedRowIndex = vm.getRowIndex(vm.$refs.formLayout.selectedItem);
      const widgetProperties = vm.$store.getters.widgetProperties;

      vm.$store.dispatch('INSERT_LAYOUT_ITEM', {
        data: widget,
        index: selectedRowIndex,
        type: 'widget',
        widgetProperties: widgetProperties
      }).then(
        (payload) => {
          const newFieldInfo = payload.newFieldInfo;
          const newFieldInfoId = newFieldInfo._id;

          vm.$refs.formLayout.gotoFirstTab();
          for (let i = 0; i < vm.formLayoutConfig.rows.length; i++) {
            const refName = `formLayoutRow${i}`;
            const layoutRow = vm.$refs.formLayout.$refs[refName];
            if (layoutRow && layoutRow.$refs[newFieldInfoId]) {
              layoutRow.$refs[newFieldInfoId].$el.scrollIntoView();
              this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key:"plus_form_edit",
                data:{
                  field_name:newFieldInfo.label
                }
              });
              break;
            } else {
              console.log(refName + ' not exist: ', layoutRow);
            }
          }
        }
      )
    },

    checkIsRelatedTable (widget) {
      var result = false
      const RELATED_TABLE_WIDGETS = [
        'relatedMultipleRecords'
        // 'childTable',
      ]
      if (RELATED_TABLE_WIDGETS.indexOf(widget.key) >= 0) {
        result = true
      }
      return result
    },

    getNextFieldId () {
      const vm = this
      var count = 1
      var fieldId = 'f' + vm.zeroPad(count, 4)
      var fieldIds = vm.formFields.map(field => field.fieldId)
      while (fieldIds.indexOf(fieldId) >= 0) {
        count++
        fieldId = 'f' + vm.zeroPad(count, 4)
      }
      // console.log('getNextFieldId : fieldId = ' + fieldId)
      return fieldId
    }
  }
}
</script>

<style>
  .form-builder .widget-list {
    background-color: rgba(0, 0, 0, 0.05);
    width: 300px;
    min-width: 300px;
  }

  .property-list {
    width: 300px;
    min-width: 300px;
  }

  .mh-48 {
    min-height: 48px;
  }

  .mh-64 {
    min-height: 64px;
  }

  .abs-right-5 {
    right: 5px;
  }

  .abs-top-0 {
    top: 0;
  }

  .p-relative {
    position: relative;
  }

  .p-absolute {
    position: absolute;
  }

  .layout-row {
    /*border: 1px solid transparent;*/
    position: relative;
    background-color: transparent;
    min-height: 100px;
  }

  .layout-row .row-setting-toolbar button {
    visibility: hidden;
    display: inline-block;
  }

  .layout-row:hover {
    /*border: 1px solid rgba(0,0,0,.1);*/
    background-color: rgba(0, 0, 0, 0.05);
  }

  .layout-row:hover .row-setting-toolbar button {
    visibility: visible;
  }


  .layout-col {
    /*border:  1px solid transparent;*/
    background-color: transparent;
    padding: 1px;
    margin: 0;
  }

  .layout-input-wrapper button {
    display: inline-block;
    visibility: hidden;
  }

  .layout-input-wrapper:hover button {
    visibility: visible;
  }

  .layout-input-wrapper {
    background-color: white;
    position: relative;
  }

  .layout-input-wrapper:hover {
    background-color: rgba(0, 0, 0, .05);
    cursor: pointer;
  }

  .layout-input-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  .row-setting-toolbar {
    padding: 1px;
  }

  .form-builder .slide-fade-enter,
  .form-builder .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
  {
    transform: translateX(300px) !important;
  }
</style>
