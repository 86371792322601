<template>
  <base-tabs-dialog
    v-if="showingDialog"
    v-model="showingDialog"
    :tabs="tabs"
    contentClass="mailSettingDialog"
    noFooter
  >
    <template>
      <v-tab-item
        :eager="true"
        :transition="false"
        :reverse-transition="false"
        class="fill-height pa-0"
        style="overflow: hidden"
      >
        <mail-settings-tab-content></mail-settings-tab-content>
      </v-tab-item>
    </template>
  </base-tabs-dialog>
</template> 

<script>
import baseTabsDialog from '@/components/dialogs/BaseTabsDialog'
import mailSettingsTabContent from './MailSettingsTabContent'
export default {
  name: 'mailSettingsDialog',
  components:{
    baseTabsDialog,
    mailSettingsTabContent
  },
  props:{
      value: {
          type: Boolean,
          default: false
      }
  },
  data(){
    return{
        tabs:[
            {
                icon: 'mdi-mail',
                labelTag: 'mailSetting.mailServerSetup'
            },
        ],
    }
  },
  computed:{
      showingDialog:{
          get(){
              return this.value
          },
          set(val){
              this.$emit('input', val)
          }
      },
  },
}
</script>

<style lang="scss">
.mailSettingDialog .base-tabs-dialog-content .v-tabs-items {
  padding-left: 0px !important;
}
// .mailTextField .v-input__control .v-input__slot {
//   min-height: 22px !important;
// }

// .mailSettingDialog .switch {
//   position: relative;
//   display: inline-block;
// }

// /* Hide default HTML checkbox */
// .mailSettingDialog .switch input {
//   opacity: 0;
//   width: 0;
//   height: 0;
// }

// /* The slider */
// .mailSettingDialog .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #ccc;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
//   height: 22px;
//   width: 58px;
// }

// .mailSettingDialog .slider:before {
//   position: absolute;
//   content: "";
//   height: 14px;
//   width: 25px;
//   left: 4px;
//   bottom: 4px;
//   background-color: white;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// .mailSettingDialog input:checked + .slider {
//   background-color: $success;
// }

// .mailSettingDialog input:focus + .slider {
//   box-shadow: 0 0 1px $success;
// }

// .mailSettingDialog input:checked + .slider:before {
//   -webkit-transform: translateX(25px);
//   -ms-transform: translateX(25px);
//   transform: translateX(25px);
// }

// .senderCard .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #ccc;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
//   height: 16px;
//   width: 40px;
// }

// .senderCard .slider:before {
//   position: absolute;
//   height: 8px;
//   width: 10px;
//   left: 4px;
//   bottom: 4px;
//   background-color: white;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }
// /* .senderCard input:checked + .slider:after{
//         content: "aa";
//     }
//     .senderCard input + .slider:after{
//         content: "cc";
//     } */
// .senderCard input:checked + .slider:before {
//   -webkit-transform: translateX(22px);
//   -ms-transform: translateX(22px);
//   transform: translateX(22px);
// }
</style>