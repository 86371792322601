import { getFieldPropertyValue } from "../helpers/ObjectHelpers";
import { Parser as FormulaParser } from "hot-formula-parser";
import { cloneDeep } from "lodash";
import moment from "moment";
const TIME_UNIT = Object.freeze({
  MS_MINUTE: 60 * 1000,
  MS_HOUR: 60 * 1000 * 60,
  MS_DAY: 60 * 1000 * 60 * 24,
  MS_MONTH: 60 * 1000 * 60 * 24 * 30,
  MS_YEAR: 60 * 1000 * 60 * 24 * 30 * 12,
});
const DATE_FORMULA_REGEX = /([\+\-]{1}\d{1,}[mhdMY]{1})/g;
const DATE_REGEX = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
const TIME_REGEX = /^([0][0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const DEFAULT_EXP_TYPE = "date";

const expressionMixin = {
  methods: {
    getTranslation(unit) {
      return this.$t(`widgets.properties.${unit}`);
    },
    getSelectionFieldValue(recordValue, options, type) {
      if (type === "singleSelection") {
        const option = options.find((item) => item._id === recordValue);
        return (option && option.value) || null;
      } else {
        return !recordValue || recordValue.length === 0
          ? null
          : recordValue.reduce((acc, val) => {
              const option = options.find((item) => item._id === val);
              acc += (option && option.value) || 0;
              return acc;
            }, 0);
      }
    },
    calculateNumber(fieldInfo, selectFields, recordData) {
      let result = 0;
      const calculationMethod = getFieldPropertyValue(
        fieldInfo,
        "calculationMethodNumber",
        "sum"
      );

      if (calculationMethod !== "custom") {
        result = selectFields.reduce((acc, currentVal, i, arr) => {
          let recordValue = recordData[currentVal];
          const selectFieldsFieldInfo = this.fieldInfos.find(
            (info) => info._id === currentVal
          );

          //lookup numeric value of selection
          if (
            selectFieldsFieldInfo &&
            ["singleSelection", "multipleSelection"].includes(
              selectFieldsFieldInfo.type
            )
          ) {
            const options = (
              selectFieldsFieldInfo.properties.inputOptionsMultiple ||
              selectFieldsFieldInfo.properties.inputOptionsSingle
            ).options;
            recordValue = this.getSelectionFieldValue(
              recordValue,
              options,
              selectFieldsFieldInfo.type
            );
          }

          recordValue = this.convertStringToFloat(recordValue);

          switch (calculationMethod) {
            case "sum":
            case "average":
              acc += recordValue;
              break;
            case "product":
              acc = i === 0 ? recordValue : acc * recordValue;
              break;
            case "min":
              acc =
                i === 0 ? recordValue : recordValue < acc ? recordValue : acc;
              break;
            case "max":
              acc =
                i === 0 ? recordValue : recordValue > acc ? recordValue : acc;
              break;
          }

          return acc;
        }, 0);

        if (calculationMethod === "average" && result !== "") {
          result /= selectFields.length;
        }
      } else {
        let tempRecordData = cloneDeep(recordData);

        const parser = new FormulaParser();
        let expression = getFieldPropertyValue(
          fieldInfo,
          "customNumberFormula",
          ""
        );
        expression = this.replaceMergeTag(expression, tempRecordData);
        result = parser.parse(expression).result;
        if (typeof result !== "number") result = 0;
      }

      return result;
    },

    calculateDate(fieldInfo, data) {
      const recordData = {...data}
      if(recordData.createdAt){
        recordData.createdAt = moment(recordData.createdAt).tz("Asia/Hong_Kong").format('YYYY-MM-DD HH:mm')
      }
      if(recordData.updatedAt){
        recordData.updatedAt = moment(recordData.updatedAt).tz("Asia/Hong_Kong").format('YYYY-MM-DD HH:mm');
      }
      let result = "";
      const calculationMethod = getFieldPropertyValue(
        fieldInfo,
        "calculationMethodDate",
        ""
      );
      switch (calculationMethod) {
        case "daysBetweenTwoDates": {
          let startDate = getFieldPropertyValue(fieldInfo, "startDate", "");
          let endDate = getFieldPropertyValue(fieldInfo, "endDate", "");
          let timeUnit = getFieldPropertyValue(fieldInfo, "timeUnit", "day");

          if (typeof startDate === "object")
            startDate = this.getRecordData(recordData, startDate.value);
          if (typeof endDate === "object")
            endDate = this.getRecordData(recordData, endDate.value);
          if (!startDate || !endDate) return result;
          startDate = this.transformToDateObject(startDate);
          endDate = this.transformToDateObject(
            endDate,
            getFieldPropertyValue(fieldInfo, "includeEndDate", "no") === "yes"
          );

          if (!isNaN(startDate.valueOf()) && !isNaN(endDate.valueOf())) {
            let diff = endDate - startDate;
            result = this.roundDate(diff, timeUnit);
          }
          break;
        }

        case "addSubtractTimeFromDate": {
          let fieldValue = getFieldPropertyValue(fieldInfo, "selectDate", "");

          let dateType = DEFAULT_EXP_TYPE;
          if (typeof fieldValue === "object") {
            fieldValue = recordData[fieldValue.value];
          }
          if (fieldValue) {
            if (fieldValue.split(" ").length == 2) {
              dateType = "dateTime";
            } else if (TIME_REGEX.test(fieldValue)) {
              dateType = "time";
              fieldValue = this.transformToDateObject(fieldValue)
            }
            let newDate = moment.tz(fieldValue, "Asia/Hong_Kong") ;

            let customFormula = getFieldPropertyValue(
              fieldInfo,
              "customDateFormula",
              ""
            );
            let operations = this.replaceMergeTag(
              customFormula,
              recordData
            ).match(DATE_FORMULA_REGEX);
            if (operations) {
              operations.forEach((op) => {
                let amount = parseInt(op.slice(0, op.length - 1));
                let unit = op[op.length - 1];
                newDate = this.addSubtractDate(newDate, amount, unit);
              });
              switch (dateType) {
                case "date":
                  result = newDate.format("YYYY-MM-DD")
                  break;
                case "time":
                  result = newDate.format("HH:mm");
                  break;
                case "dateTime":
                  result =
                  newDate.format("YYYY-MM-DD HH:mm");
                  break;
              }
            }
          }
          break;
        }
        case "daysUpToToday": {
          let today = new Date(moment().tz('Asia/Hong_Kong').format("YYYY-MM-DD HH:mm"));
          let selectedDate = getFieldPropertyValue(fieldInfo, "selectDate", "");

          if (typeof selectedDate === "object")
            selectedDate = this.getRecordData(recordData, selectedDate.value);

          selectedDate = this.transformToDateObject(selectedDate);
          let timeUnit = getFieldPropertyValue(fieldInfo, "timeUnit", "day");
          if (!isNaN(selectedDate.valueOf())) {
            let calculationMethod = getFieldPropertyValue(
              fieldInfo,
              "daysUpToTodayCalculation",
              "selectedMinusToday"
            );
            let diff =
              calculationMethod === "selectedMinusToday"
                ? selectedDate - today
                : today - selectedDate;
            result = this.roundDate(diff, timeUnit);
          }
          break;
        }
      }
      return result;
    },
    transformToDateObject(date, includeEndDate = false) {
      let fieldValue = date;
      //includeEndDate only applicable to Date format
      if (includeEndDate && DATE_REGEX.test(fieldValue)) {
        return new Date(fieldValue).setHours(23, 59);
      } else if (TIME_REGEX.test(fieldValue)) {
        fieldValue = new Date().toISOString().split("T")[0] + " " + fieldValue;
        return new Date(fieldValue);
      } else {
        return new Date(fieldValue);
      }
    },
    getRecordData(recordData, fieldId) {
      if (recordData.hasOwnProperty(fieldId)) {
        return recordData[fieldId];
      } else return null;
    },
    roundDate(diff, unit) {
      switch (unit) {
        case "minute":
          return Math.round(diff / TIME_UNIT.MS_MINUTE);
        case "hour":
          return Math.round(diff / TIME_UNIT.MS_HOUR);
        case "day":
          return Math.round(diff / TIME_UNIT.MS_DAY);
        case "month":
          return Math.round(diff / TIME_UNIT.MS_MONTH);
        case "year":
          return Math.round(diff / TIME_UNIT.MS_YEAR);
      }
    },
    addSubtractDate(momentDate, amount, unit) {
      let result = momentDate.clone();
      console.log(
        "addSubtractDate:: date: ",
        result,
        ", amount: ",
        amount,
        ", unit: ",
        unit
      );
      result.add(amount, unit === "Y" ? unit.toLowerCase():unit)
     
      return  result.isValid()? result:momentDate;
    },
    replaceMergeTag(string, recordData) {
      let result = string;
      const regex = /\$\{\w+\}/;
      while (regex.test(result)) {
        let tag = result.match(regex)[0];
        let key = tag.match(/\w+/)[0];
        result = result.replace(tag, this.getValue(recordData[key], key) || 0);
      }
      return result;
    },
    getValue(recordValue, key) {
      let result = recordValue;
      const fieldInfo = this.fieldInfos.find(
        (info) =>
          info.fieldId === key &&
          ["singleSelection", "multipleSelection"].includes(info.type)
      );
      if (fieldInfo && recordValue) {
        const options = (
          fieldInfo.properties.inputOptionsMultiple ||
          fieldInfo.properties.inputOptionsSingle
        ).options;
        result = this.getSelectionFieldValue(
          recordValue,
          options,
          fieldInfo.type
        );
      }
      result = this.convertStringToFloat(result);
      return result;
    },
    convertStringToFloat(value){
      let result = value || 0;
      if (typeof result === "string") {
        result = result.replaceAll(",", "");
        result = parseFloat(result);
        if (isNaN(result)) result = 0;
      } else if (typeof result !== "number") {
        result = 0;
      }
      return result;
    }
  },
  computed: {
    expressionType() {
      return getFieldPropertyValue(this.fieldInfo, "expressionType", "number");
    },
    unit() {
      if (this.expressionType === "number") {
        return getFieldPropertyValue(this.fieldInfo, "unit", null);
      } else if (
        getFieldPropertyValue(this.fieldInfo, "calculationMethodDate", "") !==
        "addSubtractTimeFromDate"
      ) {
        return this.getTranslation(
          getFieldPropertyValue(this.fieldInfo, "timeUnit", null)
        );
      } else {
        return "";
      }
    },
    selectionFieldMap() {
      return this.fieldInfos.reduce((acc, fieldInfo) => {
        if (["singleSelection", "multipleSelection"].includes(fieldInfo.type)) {
          Object.assign(acc, {
            [fieldInfo._id]: {
              options: (
                fieldInfo.properties.inputOptionsSingle ||
                fieldInfo.properties.inputOptionsMultiple
              ).options,
              type: fieldInfo.type,
            },
          });
        }
        return acc;
      }, {});
    },
  },
};

export default expressionMixin;
