import MediaHelper from '../helpers/MediaHelper';

const mediaMixin = {
  methods: {

    getImageSrc_old (mediaId) {
      const vm = this
      var result = ''
      if (mediaId && mediaId !== '') {
        if (Array.isArray(mediaId)) {
          result = vm.appHost + '/medias/' + mediaId[0]
        } else {
          result = vm.appHost + '/medias/' + mediaId
        }

      }
      return result
    }
  },
  computed: {
    appHost () {
      return this.$store.getters.appHost
    }
  }
}

export default mediaMixin
