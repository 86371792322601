<template>
  <div class="workflow-free-input">
    <codemirror
      ref="freeInput"
      @ready="onReady"
      v-model="value"
      :options="inputOptions"
      class="CodeMirror workflow-customized-form-record-element-editor workflow-customized-form-record-element-input-hide"
      :class="{ 'element-large-editor': large }"
      style="line-height: 26px; padding: 0 5px;"
      :style="containerStyle"
    />
    <div class="workflow-customized-form-record-element-editor-math">
      <ComboBox
        v-if="mathSupport"
        :value="null"
        :options="mathMethods"
        :onlyPrefix="false"
        :disabledOnDependencies="true"
        :onlySuffix="true"
        :isMultiple="false"
        :otherProps="{ zIndex: 9999, limit: 1 }"
        placeholder=""
        @selected="selectedMath"
        valueFormat="object"
        :disableBranchNode="true"
      />
      <v-icon size="18px" class="workflow-customized-form-record-element-editor-math-icon">
        mdi-math-integral-box
      </v-icon>
    </div>
    <ComboBox
      :options="list"
      :value="null"
      :onlyPrefix="true"
      :dependsOnType="fieldType"
      :disabledOnDependencies="true"
      :onlySuffix="true"
      :isMultiple="isMultipleDynamic"
      :otherProps="{ zIndex: 9999, limit: 1 }"
      placeholder=""
      @selected="selected"
      valueFormat="object"
      :disableBranchNode="true"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { forEach, findIndex } from 'lodash';
import ComboBox from './../../../form/comboBox/';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import NodeUtils from '@/pages/admin/workflow/utils/node';
import Mixin from './../mixin';

import TableChip from '@/pages/admin/workflow/components/form/comboBox/selected/tableChip';
import EventChip from '@/pages/admin/workflow/components/form/comboBox/selected/eventChip';
import DelegateChip from '@/pages/admin/workflow/components/form/comboBox/selected/delegateChip';
import LabelChip from '@/pages/admin/workflow/components/form/comboBox/selected/labelChip';

import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/base16-dark.css";
import "codemirror/addon/search/search.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/display/placeholder.js";
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ComboBox,
    TableChip,
    EventChip,
    DelegateChip,
    LabelChip,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    readOnly: Boolean,
    large: Boolean,
    placeholder: String,
    // label: String,
    // hints: String,
    // name: String,
    fieldType: String,
    mode: {
      type: String,
      default: 'text',
    },
    isMultipleDynamic: {
      type: Boolean,
      default: true,
    },
    mathSupport: Boolean,
    noNewLine: {
      type: Boolean,
      default: true,
    },
    valueChanged: Function,
    itemsChanged: Function,
    withOperation: {
      type: Boolean,
      default: true
    },
    exclusiveDelegated: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onReady() {
      this.freeInput.setValue(this.value);
      // this.createClip(this.fields, true);
    },
    renderDynamicClip(item) {
      const type = this.selectedComponent(item);
      let TargetComponent = Vue.extend(this.clips[type]);
      TargetComponent = new TargetComponent({
        propsData: {
          isBranch: false,
          item,
          onlyPrefix: true,
          onlySuffix: true,
        },
        parent: this,
      });
      TargetComponent.$mount();
      return TargetComponent.$el;
    },
    selected(item) {
      // this.freeInput.setValue(this.value);
      this.createClip(item);
      const { fields=[] } = this; 
      if (this.isMultipleDynamic) {
        const index = findIndex(this.fields, ({ id }) => item.id === id);
        if (index !== -1) {
          fields[1] = item;
        } else {
          fields.push(item);
        }
      }
        this.$emit('itemsChanged', fields);
    },
    selectedMath({ equation }) {
      this.insertText(equation);
    },
    selectedComponent(info) {
      return NodeUtils.comboBoxSelectedComponent(info);
    },
    insertText(text) {
      const freeInput = this.freeInput;
      const cursor = freeInput.getCursor();
      const line = cursor.line;
      const ch = cursor.ch;
      freeInput.replaceRange(
        text,
        { line, ch },
        { line, ch: ch }
      );
      freeInput.setCursor({line, ch: ch + text.length - 1})
    },
    readyClip() {
      const { list } = this;
      forEach(list, ({ children=[] }) => {
        if (children.length) {
          forEach(children, info => {
            if ((this.value || '').includes(info.id)) {
              this.createClip(info, true);
            }
          });
        }
      });
    },
    createClip(info, replace) {
      const freeInput = this.freeInput;
      let cursor = freeInput.getCursor();
      const line = cursor.line;
      const ch = cursor.ch;
      const dynamicPlacement = `$\{${info.id}\}`;
      // freeInput.setValue(this.value);
      let replacedWith;
      if (info) {
        if (!replace) {
          freeInput.replaceRange(
            dynamicPlacement,
            { line, ch  },
            { line, ch }
          );
          replacedWith = this.renderDynamicClip(info);
          freeInput.markText(
            { line, ch },
            { line, ch: ch + dynamicPlacement.length },
            {
              replacedWith,
            }
          );
          // console.log('>>>>>1>>> ', replacedWith);
        } else {
          cursor = freeInput.getSearchCursor(dynamicPlacement);
          let first;
          let from;
          let to;
          while (cursor.findNext()) {
            replacedWith = this.renderDynamicClip(info);
            from = cursor.from();
            to = cursor.to();
            freeInput.markText(from, to, {
              replacedWith,
            });
            if (first === undefined) {
              first = from;
            }
          }
        }
      }
    },
  },
  mounted() {
    this.readyClip();
    this.freeInput.on('change', (cm, change) => {
      this.$emit('valueChanged', cm.getValue());
    });
  },
  data() {
    return {
      clips: {
        TableChip,
        EventChip,
        DelegateChip,
        LabelChip,
      },
      mathMethods: [
        {
          equation: "SUM()",
          id: 'sum',
          label: this.$t('workflow.modules.operation.label.sum'),
        },
        {
          equation: "AVERAGE()",
          id: 'avg',
          label: this.$t('workflow.modules.operation.label.average'),
        },
        {
          equation: "MAX()",
          id: 'max',
          label: this.$t('workflow.modules.operation.label.max'),
        },
        {
          equation: "MIN()",
          id: 'min',
          label: this.$t('workflow.modules.operation.label.min'),
        },
        {
          equation: "SQRT()",
          id: 'sqrt',
          label: this.$t('workflow.modules.operation.label.sqrt'),
        },
        {
          equation: "ABS()",
          id: 'abs',
          label: this.$t('workflow.modules.operation.label.abs'),
        },
        {
          equation: "MEDIAN()",
          id: 'med',
          label: this.$t('workflow.modules.operation.label.median'),
        },
        {
          equation: "PRODUCT()",
          id: 'pdt',
          label: this.$t('workflow.modules.operation.label.product'),
        },
        {
          equation: "INT()",
          id: 'int',
          label: this.$t('workflow.modules.operation.label.int'),
        },
        {
          equation: "ROUND()",
          id: 'round',
          label: this.$t('workflow.modules.operation.label.round'),
        },
        {
          equation: "ROUNDUP()",
          id: 'roundup',
          label: this.$t('workflow.modules.operation.label.roundup'),
        },
        {
          equation: "ROUNDDOWN()",
          id: 'rounddown',
          label: this.$t('workflow.modules.operation.label.rounddown'),
        },
      ]
    }
  },
  computed: {
    containerStyle() {
      if (!this.mathSupport) {
        return {          
          width: 'calc(100% - 30px)',
        };
      }
      return {
        width: 'calc(100% - 70px)',
      };
    },
    freeInput() {
      return this.$refs.freeInput.codemirror
    },
    list() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      const options = {
        withOperation: this.withOperation,
        exclusiveDelegated: this.exclusiveDelegated
      };
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'), options);
      return NodeUtils.withAcceptanceOptions({
        dependsOnType: this.fieldType,
        list: [...workflowOutputs],
      });
    },
    inputOptions() {
      return {
        tabSize: 1,
        // theme: 'base16-dark',
        lineNumbers: false,
        line: true,
        mode: this.mode,
        lineWrapping: true,
        noNewlines: this.noNewLine,
        placeholder: this.placeholder || '',
        singleCursorHeightPerLine: false
      };
    },
  },
  watch: {
    list: {
      handler() {
        this.readyClip();
      },
      deep: true
    },
  },
};

</script>