<template>
  <v-row class="header" no-gutters>
    <v-col class="text-start">
      <v-btn v-if="$route.name==='LandingHome'" text class="action-btn" href="https://yoov.com/">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t('createTeam.returnToYoov') }}
      </v-btn>
      <v-btn v-else text class="action-btn" @click="back">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t('buttons.back') }}
      </v-btn>
    </v-col>
    <v-col><slot></slot></v-col>
    <v-col class="text-end">
      <v-btn text class="action-btn" :href="whatsapp" target="_blank">
        <v-icon>mdi-headset</v-icon>
        {{ $t('buttons.contactUs') }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      validator: function(val) {
        return ["yoov", "back"].indexOf(val) !== -1;
      },
    },
  },
  data() {
    return {
      whatsapp:
        "https://api.whatsapp.com/send?phone=85253158061&text=%E6%88%91%E9%81%87%E5%88%B0YOOV+%E7%9B%B8%E9%97%9C%E7%9A%84%E5%95%8F%E9%A1%8C",
    };
  },
  methods:{
    back(){
      this.$router.push({
        name:'Landing'
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  background-color: white;
  padding: 24px 40px;

  height: 120px;
  .action-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }
}
</style>
