<template>
  <div class="d-flex flex-row align-content-center justify-start">
    <team-selection-dialog
        ref="teamSelectionDialog"
        v-model="showingTeamSelectionDialog"></team-selection-dialog>
  </div>
</template>

<script>
import teamSelectionDialog from '../dialogs/TeamSelectionDialog'

export default {
  components: {
    teamSelectionDialog
  },
  data () {
    return {
      showingTeamSelectionDialog: false
    }
  },
}
</script>

<style>
  .current-team-name {
    cursor: pointer;
  }
</style>
