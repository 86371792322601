var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-table-column',_vm._b({attrs:{"edit-render":{
    name: '$select',
    options: _vm.selection,
    enabled: !_vm.readonly
  },"sortable":"","class-name":"fullCell"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var row = ref.row;
return [_c('option-chip-list',{attrs:{"value":row[_vm.fieldId],"useColor":_vm.useColor,"toggleType":"single","options":_vm.options}}),(_vm.quickInsert)?_c('quick-action',{attrs:{"rowId":row['_id'],"fieldId":_vm.fieldId}}):_vm._e()]}},{key:"edit",fn:function(ref){
  var row = ref.row;
return [(_vm.readonly)?_c('option-chip-list',{attrs:{"value":row[_vm.fieldId],"useColor":_vm.useColor,"toggleType":"single","options":_vm.options}}):_c('pulldown-chip-selection',{staticClass:"vxecellSelection",attrs:{"readonly":_vm.readonly,"items":_vm.selection,"fullListOptions":_vm.options,"useColor":_vm.useColor,"item-text":"label","item-value":"_id","toggleType":"single","allowAdd":_vm.allowAdd,"fieldId":_vm.fieldId,"multiple":false},on:{"onDelete":function($event){return _vm.onDeleteHandler($event, row[_vm.colDef.field])}},model:{value:(row[_vm.colDef.field]),callback:function ($$v) {_vm.$set(row, _vm.colDef.field, $$v)},expression:"row[colDef.field]"}})]}},{key:"footer",fn:function(ref){
  var column = ref.column;
return [_c('summary-cell',{attrs:{"decimals":_vm.decimals,"column":column,"labelValuePairs":_vm.getSummaryLabelValuePairs(column)},on:{"click":_vm.selectCalcMethod}})]}}])},'vxe-table-column',_vm.colDef,false))}
var staticRenderFns = []

export { render, staticRenderFns }