<template>
  <vxe-table-column class-name="fullCell vxeCellDate" v-bind="colDef"  :edit-render="{name: 'input', autoselect: true, enabled: !readonly}" >
  <template v-slot:edit="{row, column}">
      <div class="d-flex flex-column edit-date-modal fill-height">
        <v-menu offset-overflow min-width="290" :close-on-content-click="['datetime', 'time'].includes(fieldType)?false:true" offset-y :attach="cssSelect">
          <template v-slot:activator="{on}">
            <div v-on="on" style="height:100%;" @click="onCellClick(row)">
              <vxe-input v-model="row[fieldId]" style="height:100%;">
              </vxe-input>
            </div>
          </template>
            <v-card v-if="fieldType == 'date'" width="300" class="vxe-table--ignore-clear">
              <v-date-picker
                :allowed-dates="allowedDates"
                no-title
                :locale="$i18n.locale"
                v-model="dialogDateValue"
                @change="onChange($event, row)"
              />
            </v-card>
            <v-card v-else-if="fieldType == 'time'" width="290" class="vxe-table--ignore-clear">
              <v-time-picker
                class="v-application"
                v-model="dialogTimeValue"
                :min="minTime"
                :max="maxTime"
                :locale="$i18n.locale"
                @input="onChange($event, row)"
              />
            </v-card>
            <v-card v-else width="340" class="v-application vxe-table--ignore-clear" style="flex-direction:column" tile>
              <v-toolbar height="30" dense color="primary" flat>
                <v-tabs fixed-tabs v-model="activeTab">
                  <v-tab key="calendar">
                    <slot name="dateIcon">
                      <v-icon color="white">mdi-calendar</v-icon>
                    </slot>
                  </v-tab>
                  <v-tab key="timer">
                    <slot name="timeIcon">
                      <v-icon color="white">mdi-clock-time-four-outline</v-icon>
                    </slot>
                  </v-tab>
                </v-tabs>
              </v-toolbar>
              <v-tabs-items v-model="activeTab">
                <v-tab-item key="calendar">
                  <v-date-picker
                    :allowed-dates="allowedDates"
                    no-title
                    :locale="$i18n.locale"
                    class="no_radius"
                    v-model="dialogDateValue"
                    scrollable
                    flat
                    no-footer
                    full-width
                    @change="setDateTime($event, row)"
                  >
                  </v-date-picker>
                </v-tab-item>
                <v-tab-item key="timer">
                  <v-time-picker
                    :locale="$i18n.locale"
                    class="no_radius"
                    v-model="dialogTimeValue"
                    scrollable
                    no-footer
                    full-width
                    :min="minTime"
                    :max="maxTime"
                    @input="setDateTime($event, row)"
                  >
                  </v-time-picker>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
        </v-menu>

      </div>
  </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import dateTimeHelperMixin from "@/mixins/dateTimeHelperMixin";
import ValidationsHelper from "@/helpers/ValidationsHelper";
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin, dateTimeHelperMixin],
  components: {
    summaryCell
  },
  data() {
    return {
      activeTab: 0,
      dialogTimeValue: "",
      dialogDateValue: "",
      showingDialog: false
    };
  },
  methods: {
    onCellClick(row){
      if(this.fieldType === 'date')
        this.dialogDateValue = row[this.fieldId]
      else if(this.fieldType === 'time')
        this.dialogTimeValue = row[this.fieldId]
      else{
        this.showDropdown(row)
      }
    },
    onChange(event, row) {
      this.$set(row, this.fieldId, event);
    },
    getNow() {
      let dt = new Date(),
        hour = "" + dt.getHours(),
        min = "" + dt.getMinutes();
      if (hour.length < 2) hour = "0" + hour;
      if (min.length < 2) min = "0" + min;
      return hour + ":" + min;
    },
    showDropdown(row) {
      let vm = this
      if (this.fieldType == "datetime") {
        if (ValidationsHelper.isValidDateTimeString(row[vm.fieldId])) {
          const arr = row[vm.fieldId].split(" ");
          this.dialogDateValue = arr[0];
          this.dialogTimeValue = arr[1];
        }
      }
      this.$refs.xDown1.togglePanel();
    },
    setDateTime(event, row) {
      let vm = this
      let date = this.dialogDateValue;
      let time = this.dialogTimeValue;
      
      if (!ValidationsHelper.isValidDateTime(date)) {
        date = this.getToday();
      }
      if (!ValidationsHelper.isValidTimeString(time)) {
        time = this.getNow();
      }
       this.$set(row, this.fieldId, date + " " + time);
    },
    allowedDates(val) {
      return this.allowedDays.includes(new Date(val).getDay().toString());
    },
  },

  computed: {
    fieldType() {
      return this.fieldInfo.properties.fieldType;
    },
    validations() {
      let propertyKey = '';
        switch(this.fieldType){
          case 'date':
            propertyKey = 'validation_date'
            break;
          case 'time':
            propertyKey = 'validation_time'
            break;
          case 'datetime':
            propertyKey = 'validation_datetime'
            break;
        }
      return this.fieldInfo.properties[propertyKey];
    },
    allowedDays() {
      if (this.validations.search("limitWeekday") > -1) {
        return this.fieldInfo.properties.validation_weekday.split("||");
      }
      return ["0", "1", "2", "3", "4", "5", "6"];
    },
    minTime() {
      if (this.validations.search("timeRange") > -1) {
        return this.fieldInfo.properties.validation_minTime;
      }
      return "";
    },
    maxTime() {
      if (this.validations.search("timeRange") > -1) {
        return this.fieldInfo.properties.validation_maxTime;
      }
      return "";
    },
  },
};
</script>

<style> 
  .vxeCellDate .vxe-input input{
    text-align: center;
  }
</style>