<template>
  <div>
    <div class="w-customized-button-form-user-input">
      <div>
        {{ $t('workflow.modules.actionProcess.label.popup.title') }}
      </div>
      <v-textarea
        class="workflow-setting-input-root"
        v-model="alertInfo.title"
        :placeholder="$t('messages.pleaseInput', [])"
        outlined
        no-resize
        rows="3"
      ></v-textarea>
    </div>
    <div class="w-customized-button-form-user-input">
      <div>
        {{ $t('workflow.modules.actionProcess.label.popup.confirm') }}
      </div>
      <v-text-field
        class="workflow-setting-input-root"
        v-model="alertInfo.confirmLabel"
        :placeholder="$t('messages.pleaseInput', [])"
        outlined
        height="36"
      ></v-text-field>
    </div>
    <div class="w-customized-button-form-user-input">
      <div>
        {{ $t('workflow.modules.actionProcess.label.popup.cancel') }}
      </div>
      <v-text-field
        class="workflow-setting-input-root"
        v-model="alertInfo.cancelLabel"
        :placeholder="$t('messages.pleaseInput', [])"
        outlined
        height="36"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
// import {
//   UPDATE_ACTION_BUTTON_INFO,
// } from '@/store/modules/customizedAction/action_types';
export default {
  props: {
    popup: {
      type: Object,
      default: {
        title: '',
        confirmLabel: '',
        cancelLabel: '',
      },
    },
    buttonName: {
      type: String,
      default: '',
    },
  }, 
  // methods: {
  //   onChanged(content) {
  //     this.$store.dispatch(UPDATE_ACTION_BUTTON_INFO, {
  //       field: 'popup',
  //       content,
  //     });
  //   }
  // },
  computed: {
    alertInfo() {
      const defaultPopup = {
        title: this.$t('workflow.label.popupCustomimzedAction.title', { 
          label: this.buttonName,
        }),
        confirmLabel: this.$t('workflow.label.popupCustomimzedAction.confirmLabel'),
        cancelLabel: this.$t('workflow.label.popupCustomimzedAction.cancelLabel'),
      }
      const { popup={} } = this;
      const lookups = ['title', 'confirmLabel', 'cancelLabel'];
      lookups.forEach(target => {
        if (!popup[target]) {
          popup[target] = defaultPopup[target];
        }
      });
      return popup;
    }
  },
}
</script>

<style scoped>
  .w-v-display-option {
    margin-right: 25px;
  }
  .w-customized-button-form-user-input {
    margin: 0 0 20px 0;
  }
  .w-customized-button-form-user-input > div:first-child {
    margin-bottom: 5px;
  }
</style>