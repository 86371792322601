<template>
  <!-- scrollable -->


  <v-dialog eager v-model="showingDialog"
  class="vxe-table--ignore-clear"
    persistent
    max-width="90%">
    <v-card
      class="record-dialog-card position-relative d-flex flex-column vxe-table--ignore-clear"
      style="height:720px;"
    >
      <v-card-title class="flex-grow-0 pa-0">
        <form-title
          ref="formTitle"
          :formBuilderButton="false"
          class="w-100"
          :title="dialogTitleInfos"
          :buttons="readonly?['back']: ['back', 'submit']"
          :defaultSubmitCommand="submitCommand"
          :hasSearch="false"
          :mode="mode"
          @onCommand="onCommandHandler"
        >
          <template v-slot:prepend-action-button-set>
            <CustomizedActionButtonList
              v-if="viewId"
              :size="1"
              :selected="[recordId]"
            />
            <discussion-control-button v-if="!isNew" v-model="showDrawer" />
            <print-template-button
              v-if="viewId && !loading && printTemplates.length > 0 && !isNew"
              :printTemplates="printTemplates"
              @print="
                print({
                  ...$event,
                  recordId: recordData._id,
                })
              "
            />
          </template>
        </form-title>
      </v-card-title>
      <!--<v-card-title>Select Country</v-card-title>-->
      <!-- <div class="headline px-8 my-0"
            style="border-bottom:1px solid rgba(255,255,255,100);">
          {{ recordTitle }}
        </div> -->
        <v-card-text class="pb-0 flex-grow-1 d-flex fill-height flex-column card-text pt-0 px-3" style="min-height:90%;">
<!--          <div class="d-flex flex-row"> -->
          <div class="d-flex flex-row fill-height">
            <popup-record-form
                class="flex-grow-1"
                ref="recordForm"
                v-if="formInfo"
                :formInfo="formInfo"
                :relatedTableData="relatedTableData"
                :relatedTableInfos="relatedTableInfos"
                @onCommand="onCommandHandler"
                :isNew="isNew"
                v-model="recordData"></popup-record-form>
            <div v-if="!isNew && showDrawer"
              class="d-flex flex-column drawer-container"
              >
              <drawer :parentId="recordId" />
            </div> 
          </div>
<!--          </div>-->
        </v-card-text>

      <full-card-spinner
        style="z-index:4;"
        v-if="saving | loading"
      ></full-card-spinner>
    </v-card>
    <popup-record-dialog
      v-if="showingDialog"
      :parentTitleInfos="dialogTitleInfos"
      @ready="loading = false"
      ref="popupRecordDialog"
    ></popup-record-dialog>
    <child-record-dialog ref="childRecordDialog">
    </child-record-dialog>
    <edit-template-dialog
      v-if="showTemplateDialog"
      v-model="showTemplateDialog"
      :templateId="selectedPrintTemplate"
      :recordId="selectedRecordId"
      :isEdit="isEditPrint"
      :viewId="currentViewId"
      :isPrint="true"
    />
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import printTemplateDialogMixin from "@/mixins/printTemplateDialogMixin";

import formTitle from "@/components/FormTitle";

import popupRecordForm from "./PopupRecordForm";
import { newBlankRecord } from "@/helpers/FormHelpers";
import DiscussionControlButton from "@/components/discussion/discussionControlButton";
import PrintTemplateButton from "@/components/buttons/PrintTemplateButton";
import drawer from "@/components/discussion/drawer";

import fullCardSpinner from "@/components/FullCardSpinner";
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import EditTemplateDialog from "@/pages/admin/form/tableSetup/print/EditTemplateDialog";
import CustomizedActionButtonList from "@/pages/admin/workflow/manualActions/tools/list";

import { isValidObjectId } from '@/helpers/DataHelpers.js';

export default {
  name: "popupRecordDialog",
  mixins: [dialogMixin, printTemplateDialogMixin],
  components: {

    formTitle,

    popupRecordForm,
    DiscussionControlButton,
    drawer,
    fullCardSpinner,
    popupRecordDialog,
    PrintTemplateButton,
    EditTemplateDialog,
    CustomizedActionButtonList
  },

  props: {
    parentTitleInfos: {
      type: Array,
      default() {
        return [];
      },
    },    
  },
  beforeCreate() {
    this.$options.components.childRecordDialog = require("@/components/dialogs/ChildRecordDialogPublic").default;
  },
  data() {
    return {
      saving: false,
      loading: false,

      showingChildDialog: false,
      showingDialog: false,
      showDrawer: false,
      titleInfo: {
        fieldName: "",
        formLabel: "",
      },
      recordData: {},
      relatedTableData: {},
      relatedTableInfos: {},
      formInfo: null,
      onSubmitted: null,
      onCancelled: null,
      onDialogClosed: null,
      defaultValues: null,
      metaData: null,
      isNew: false,
      attach: '',
      cssSelect: '',
      mode: 'new',

      viewId:""
// [edit|new] default: new
    };
  },
  computed: {
    readonly(){
      return this.recordData.canEdit === false
    },
    appId() {
      return this.formInfo ? this.formInfo.appId : "";
    },
    formId() {
      return this.formInfo ? this.formInfo._id : "";
    },
    recordId() {
      return this.recordData._id;
    },
    formButtons() {
      const vm = this;
      return vm.recordId === "" ? ["submit"] : ["refresh", "delete", "submit"];
    },
    dialogTitleInfos() {
      const vm = this;
      // console.log('dialogTitleInfos: vm.parentTitleInfos: ', vm.parentTitleInfos)
      var result = [...vm.parentTitleInfos];
      result.push(vm.titleInfo);
      return result;
    },
    // dialogTitle () {
    //   const vm = this
    //   var result = [...vm.dialogTitleSegs]

    //   result.push('(showingChildDialog:' + (vm.showingChildDialog ? 'yes' : 'no') + ')')
    //   return result && result.length > 0 ? result.join('>') : '(No Title)'
    // },
    currentDialogTitle() {
      const vm = this;
      var result = vm.$t("widgets.relatedRecord");
      return result;
    },
    recordTitle() {
      const vm = this;
      var result = "";
      if (vm.titleFieldInfo) {
        if (vm.recordData && vm.recordData[vm.titleFieldInfo.fieldId]) {
          result = vm.recordData[vm.titleFieldInfo.fieldId];
        } else {
          result = "{" + vm.titleFieldInfo.label + "}";
        }
      }
      return result;
    },
    titleFieldInfo() {
      const vm = this;
      var result = null;
      if (vm.formInfo && vm.formInfo.titleFieldInfoId !== "" && vm.fieldInfos) {
        result = vm.fieldInfos.find(
          (info) => info._id === vm.formInfo.titleFieldInfoId
        );
      }
      return result;
    },
    formTitle() {
      const vm = this;
      var result = "";
      if (vm.formInfo) {
        result = vm.formInfo.label;
        if (vm.recordId === "") {
          result += " (" + vm.$t("general.new") + ")";
        } else {
          result += " (" + vm.$t("general.edit") + ")";
        }
      }
      return result;
    },

    fieldInfos() {
      const vm = this;
      var result = [];
      if (vm.formInfo) {
        result = vm.formInfo.fieldInfos;
      }
      return result;
    },

    debugging() {
      return this.$store.getters.debugging;
    },
  },
  methods: {
    open (payload, onDialogClosed) {
      const vm = this;

      vm.formInfo = payload.formInfo;
      vm.onSubmitted = payload.onSubmitted;
      vm.onCancelled = payload.onCancelled
      vm.recordId = payload.recordId;
      vm.cssSelect = payload.cssSelect
      vm.attach = payload.attach
      vm.mode = payload.mode ? payload.mode : 'new'
      vm.titleInfo = {
        fieldName: payload.fieldLabel,
        formLabel:
          payload.formInfo.label +
          (vm.debugging ? " (" + vm.formInfo._id + ")" : ""),
      };
      if (onDialogClosed) {
        vm.onDialogClosed = onDialogClosed
      }
      if (payload.metaData) {
        vm.metaData = payload.metaData;
      }
      vm.defaultValues = payload.defaultValues;
      if (payload.viewId){
        vm.viewId = payload.viewId
        this.getCustomizedButtons()
      }
      // initialization
      vm.recordData = newBlankRecord(vm.fieldInfos);
      vm.showDrawer = false;
      vm.saving = false;
      vm.showingDialog = true;


      // console.log('RecordDialog2 : open isNew = ' + (vm.isNew ? 'yes' : 'no'))
      vm.init(payload.recordId);
    },

    init(recordId = "", forceNew = false) {
      const vm = this;
      // console.log('RecordDialog2.init :: recordId = ' + recordId)
      if (recordId === "" || recordId === "_" || forceNew) {
        vm.isNew = true;
        vm.initNewRecord()
        .then(()=>{
          vm.prepare();
        });
      } else {
        vm.isNew = false;
        vm.refresh(recordId);
      }
    },

    async getCustomizedButtons(){
      this.$store.dispatch("FETCH_ACTION_BUTTON_OPTIONS", {
        formId:this.formId,
        appId: this.appId,
        viewId: this.viewId
      })
    },
    async initNewRecord() {
      const vm = this;
      // const recordData = newBlankRecord(vm.fieldInfos);
      vm.loading = true
      try  {
        vm.$store
        .dispatch("GENERATE_TEMP_RECORD_ID", {
          appId: vm.appId,
          formId: vm.formId,
        }).then(
          response => {
            vm.initRelatedTableDatas (vm.fieldInfos, vm.defaultValues)
            vm.loadRelatedTableInfos(vm.fieldInfos);
            let defaultData = response.default
            let recordData = defaultData
            recordData._id = response.result;
            for (let key in recordData) {
              const value = recordData[key]
              var field = vm.fieldInfos.find(item=>item.fieldId == key)
              if(field && field.type == 'relatedRecord'){
                var mappedRecordIds = value.map(item=>item._id)
                vm.$set(vm.recordData, key, mappedRecordIds)
                vm.$set(vm.relatedTableData, key, value)
                if(field.properties.displayFormat === "table" && !vm.$store.getters.isPublicRoute){
                  const relatedRecordIds = Array.isArray(mappedRecordIds)
                  ? mappedRecordIds
                  : [mappedRecordIds];

                  const params = {
                    id: recordData._id,
                    formId: vm.formId,
                    fieldId: field._id,
                    relatedRecordIds: relatedRecordIds,
                    linked: true,
                  };
                  vm.$store.dispatch("UPDATE_RELATED_RECORDS", params);
                }
              }else{
                vm.$set(vm.recordData, key, value);
              }
            }
            if (vm.defaultValues) {
                Object.assign(vm.recordData,vm.defaultValues )
            }
        })
      }catch {
        vm.$toast.error(this.$t("errors.load"))
          vm.loading = false;
      } finally{
        this.loading = false
      }
    },

    initRelatedTableDatas(fieldInfos, defaultValues) {
      const vm = this
      const relatedFieldInfos = fieldInfos.filter(info => ['relatedRecord', 'childTable'].includes(info.type))
      vm.relatedTableData = {}
      for (let i = 0; i < relatedFieldInfos.length; i++) {
        const loopInfo = relatedFieldInfos[i]
        vm.$set(vm.relatedTableData, loopInfo.fieldId, [])
      }
      vm.loadRelatedTableData(
        vm.formId,
        vm.fieldInfos,
        defaultValues,
      ).then(response => {
        for (let fieldId in response.relatedTableData) {
          vm.$set(vm.relatedTableData, fieldId, response.relatedTableData[fieldId])
        }
      })
    },

    prepare() {
      const vm = this;
      vm.$nextTick(() => {
        if (vm.$refs.recordForm) {
          vm.$refs.recordForm.resetValidationAll();
          vm.scrollToTop();
          vm.loading = false;
          vm.$emit("ready");
        }
      });
      vm.$refs.formTitle.reset();
    },

    scrollToTop() {
      const vm = this;
      vm.$nextTick(() => {
        // notes:
        // recordForm exists only if formInfo exists
        if (vm.$refs.recordForm) {
          vm.$refs.recordForm.scrollToTop();
        }
      });
    },

    async loadRelatedTableData(formId, fieldInfos, recordData) {
      const vm = this;
      const relationFieldInfos = fieldInfos.filter(
        (info) => info.type === "relatedRecord"
      );

      var criteria = [];
      for (let i = 0; i < relationFieldInfos.length; i++) {
        const loopInfo = relationFieldInfos[i];
        const relatedFormId = loopInfo.properties.dataSource;
        if (
          recordData[loopInfo.fieldId] &&
          Array.isArray(recordData[loopInfo.fieldId]) &&
          recordData[loopInfo.fieldId].length > 0
        ) {
          criteria.push({
            formId: formId,
            relatedFormId: relatedFormId,
            fieldId: loopInfo.fieldId,
            recordIds: recordData[loopInfo.fieldId],
          });
        }
      }
      const payload = {
        criteria: JSON.stringify(criteria),
      };
      const postParams = {
        urlCommand: "/related_records/fetchMultiple",
        data: payload,
      };
      return await vm.$store.dispatch("AUTH_POST", postParams);
    },

    loadRecord(recordId) {
      const vm = this;
      return new Promise((resolve)=> {
        vm.$store
        .dispatch("FETCH_RECORD", {
          appId: vm.appId,
          formId: vm.formId,
          viewId: vm.$viewId,
          recordId: recordId,
        })
        .then((response) => {
          for (var key in response.result) {
            vm.$set(vm.recordData, key, response.result[key]);
          }
          vm.setRelatedTableData(response.relatedTableData);
          vm.setRelatedTableInfos(response.relatedTableInfos);
          resolve(response)
        });
      })
    },

    setRelatedTableData(tableData) {
      const vm = this;
      vm.relatedTableData = {};
      for (var key in tableData) {
        vm.$set(vm.relatedTableData, key, tableData[key]);
      }
    },

    setRelatedTableInfos(tableInfos) {
      const vm = this;
      vm.relatedTableInfos = {};
      for (let tableInfoId in tableInfos) {
        vm.$set(vm.relatedTableInfos, tableInfoId, tableInfos[tableInfoId]);
      }
    },

    async loadRelatedTableInfos() {
      const vm = this;
      const getParams = {
        urlCommand: "/forms/" + vm.formId + "/relatedTableInfos",
      };
      const res = await vm.$store.dispatch("AUTH_GET", getParams)
      vm.setRelatedTableInfos(res.relatedTableInfos);
    },

    close() {
      const vm = this
      vm.showingDialog = false;
      this.viewId = ""
      vm.$emit('onCommand', {command: 'closeDialog'})

      if (typeof vm.onCancelled === 'function') {
        vm.onCancelled()
      } 
      if (typeof vm.onDialogClosed === 'function') {
        vm.onDialogClosed()
      }
    },
    updateFieldValue(payload) {
      const vm = this;
      vm.$set(vm.recordData, payload.fieldName, payload.fieldValue);
    },

    openRecordDialog(payload) {
      const vm = this;
      vm.showingChildDialog = true;
      vm.$nextTick(() => {
        vm.$refs.popupRecordDialog.open(payload);
      });
    },
    openChildRecordDialog(payload) {
      this.$refs.childRecordDialog.open(payload);
    },
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "openChildRecordDialog":
          vm.openChildRecordDialog(payload);
          break;
        case "newRelatedRecord":
        case "editRelatedRecord":
          vm.openRecordDialog(payload.payload);
          break;
        case "updateFieldValue":
          vm.updateFieldValue(payload);
          break;
        case "back":
          vm.close();
          break;
        case "submit":
        case "submitAndNew":
        case "submitAndKeep":
        case "submitAndRefresh":
          vm.onSubmit(payload.command);
          break;
        case "refreshData":
          vm.refresh();
          break;
      }
    },


    onSubmit(command) {
      const vm = this;
      vm.save().then( res => {
        vm.afterSubmission(command);
      });
    },

    afterSubmission(command) {
      const vm = this;
      switch (command) {
        case "submit":
          vm.close()
          break;
        case "submitAndNew":
          vm.recordId = "";
          vm.init();
          break;
        case "submitAndKeep":
          vm.recordId = "";
          vm.recordData._id = "";
          vm.prepare();
          break;
        case "submitAndRefresh":
          break
      }
    },

    async save() {
      const vm = this;
      const errors = await vm.$refs.recordForm.validateAll();
      if (errors === 0) {
        await vm.doSave();
      }
    },


    async doSave() {
      const vm = this;
      vm.saving = true;
      const updatedData = JSON.parse(JSON.stringify(vm.recordData));
      updatedData["formId"] = vm.formId;
      updatedData["appId"] = vm.appId;
      for (const [key, value] of Object.entries(updatedData)) {
          if (typeof value === "string"){
            updatedData[key] = value.trim();
          }
        }
      if ( !isValidObjectId(updatedData["owner"]) ) delete updatedData['owner'];

      const postData = {
        data: updatedData,
        isNew: vm.isNew,
      };
      try {
        const response = await vm.$store.dispatch("SAVE_DATA", postData)
        vm.showSuccess("messages.savedSuccessfully");
        if (typeof vm.onSubmitted === "function") {
          vm.onSubmitted(response, vm.metaData);
        }
      } catch (err) {
        vm.$toast.error(this.$t("errors.save"))
      } finally {
        vm.saving = false;
      }
    },

    async refresh(recordId = "") {
      const vm = this;

      const _recordId = recordId || vm.recordId
      vm.loadRecord(_recordId)
        .then(()=>{
          vm.prepare();
        });
    },

    print(payload){
      this.$emit('onCommand', {
        command:'print',
        ...payload,
        formId: this.formId,
        appId:this.appId,
        viewId:this.viewId
      })
    }
  },
};
</script>

<style>
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-540px) !important;
}
/* .record-dialog-card {
  min-height:90%;
} */
</style>
