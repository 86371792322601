<template>
  <vxe-table-column v-bind="colDef" class="text-right" sortable>
    <template v-slot="{row}">
      <div v-if="typeof row === 'object'" class="text-right">
        {{ checkAndShowValue(row, fieldId)}} {{unit}}
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>

  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import expressionMixin from "../../mixins/expressionMixin";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import summaryCell from './comps/SummaryCell'

export default {
  name: 'vxeCellExpression',
  mixins: [mixin, expressionMixin],
  components: {
    summaryCell
  },
  computed: {
    fieldInfo(){
      return this.colDef.fieldInfo
    },
  },
  methods: {
    checkAndShowValue (row, fieldId) {
      const vm = this
      let result = row[fieldId]
      if (vm.colDef['tableType'] === 'child') {
        result = vm.calculateValue(vm.fieldInfo, row)
       
      }

      result = typeof result === 'number'? this.formatNumber(result): (result || "");

      if (vm.colDef['tableType'] === 'child') {
          row[fieldId] = result
      }
    return result
    },

    calculateValue (fieldInfo, row) {
      const vm = this
      var result;


      let selectFields = getFieldPropertyValue(
        fieldInfo,
        "selectFields",
        []
      );
      selectFields = selectFields.filter(fieldId => this.fieldInfos.find(info=>info.fieldId ===fieldId))
      if (this.expressionType === "number") {
        result = vm.calculateNumber(fieldInfo, selectFields, row);
      } else {
        result = vm.calculateDate(fieldInfo, row);
      }
      return result;
    }
  }
};
</script>
