<template>
  <v-layout align-center justify-end class="workflow-customized-action-button-list-inline">
    <div
      v-for="button in buttonOptions"
      :key="button._id">    
      <component
        :is="targetComponent(button)"
        :button="button"
        :selected="selected"
        @onSelected="onSelected"
        :buttonProps="{
          xSmall: true,
          color: button.color,
          iconColor: 'white',
          elevation: 2,
          iconSize: 17,
        }"
      />
    </div>
  </v-layout>
</template>

<script>
import NormalButton from './../buttons/normal';
import AlertButton from './../buttons/alert';
import Mixin from './../buttons/mixin';
import ButtonsMixin from './mixin';

export default {
  mixins: [Mixin, ButtonsMixin],
  components: {
    AlertButton,
    NormalButton,
  },
}
</script>

<style scoped>
</style>