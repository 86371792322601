<template>
  <div>
    <label>{{ label }}</label>
    <dialog-field-time
      v-model="propertyValue"></dialog-field-time>
  </div>
</template>

<script>
  import mixin from './mixin'
  import dialogFieldTime from '@/components/dialogFields/DialogFieldTime'

  export default {
    mixins: [mixin],
    components: {
      dialogFieldTime
    }
  }
</script>
