<template>
<div v-if="teams.length" class="sender-card">
  <v-card
    class="mt-3 mx-3 d-flex senderCard"
    outlined
    style="border-style: dashed;"
    v-for="team in teams"
    :key="team._id">
    <v-card-text class="pa-3">
      <div class="d-flex flex-row align-start justify-space-between">
        <team-card
          :teamCode="team.teamInfo.code"
          :teamName="team.teamInfo.name"
          :email="team.registeredEmail"></team-card>
        <div class="d-flex flex-column justify-space-between align-items-end" style="height:58px;">
          <div class="d-flex flex-grow-1 justify-end">
            <v-btn
                x-small
                icon 
                color="teal lighten-2"
                @click="editTeam(team)">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              :disabled="team.sharedApps.length>0"
                x-small
                icon 
                color="teal lighten-2"
                @click="deleteTeam(team)"
                class="ml-1">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div> 
          <div class="mr-1 d-flex flex-row justify-end align-center" style="white-text: inherit;">
            <v-icon class="me-1">mdi-text-box</v-icon>
            x
            <div class="ms-1">
            {{ team.sharedApps.length }}
            </div>
          </div>
        </div>
                        
      </div>

    </v-card-text>
    <!-- <v-card-actions class="d-flex flex-column align-end pe-3 pa-0" style="width:200px;">
    </v-card-actions> -->
  </v-card>
  <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
</div>
</template>

<script>
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import teamCard from '../comps/TeamCard';

export default {
  name: 'teamConfigItem',
  props: {
    teams: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  components: {
    confirmDeleteDialog,
    teamCard
  },
  methods: {
    editTeam (team) {
      this.$emit('onCommand', {
        command: 'edit',
        team: team
      });
    },
    deleteTeam (team) {
      this.$emit('onCommand', {
        command: 'delete',
        team: team
      });
    }

  }
}
</script>
