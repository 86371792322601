var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3 d-flex flex-column",staticStyle:{"border-left":"1px solid #eeee","border-right":"1px solid #eeee"}},[_c('div',{staticClass:"font-weight-bold pa-2",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.$t('chart.dataSource')))]),_c('div',{staticClass:"mx-2 mt-2"},[_c('div',[_vm._v(_vm._s(_vm.$t('chart.filterByPeriod')))]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var value = ref.value;
return [_c('div',_vm._g({},on),[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-input',{staticClass:"rounded d-flex dateSelectionInput",staticStyle:{"height":"40px","padding":"0 6px","border":"1px solid #0000006b"},style:(value? 'border: 2px solid #39aba7':hover?'border-color:black':'')},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticStyle:{"padding":"10px 0"}},[_vm._v(_vm._s(_vm.getDateOptString()))])]),_c('div',{staticClass:"v-select",class:value?'':'v-select--is-menu-active'},[_c('div',{staticClass:"v-input__icon--append"},[_c('span',{staticClass:"v-icon theme--light"},[_c('font-awesome-icon',{class:value? 'primary--text':'',attrs:{"icon":"caret-up","size":"sm","transform":"grow-2"}})],1)])])])]}}],null,true)})],1)]}}])},[_c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"px-3 pt-2 pb-3 d-flex flex-column"},[_c('div',{staticClass:"pb-1",staticStyle:{"font-size":"13px","color":"grey"}},[_vm._v(_vm._s(_vm.$t('chart.time')))]),_c('div',[_c('v-select',{staticClass:"dateRangeSelection",staticStyle:{"width":"100%"},attrs:{"item-value":"fieldId","items":_vm.dateWidgets,"outlined":"","dense":"","hide-details":"","height":"30","menu-props":{offsetY: true}},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                                command: 'update_selectedDateField',
                                value: $event
                            })}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(["updatedAt", "createdAt"].includes(item.fieldId)?_vm.$t(("widgets." + (item.fieldId))):item.label)+" ")])]}},{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(["updatedAt", "createdAt"].includes(item.fieldId)?_vm.$t(("widgets." + (item.fieldId))):item.label)+" ")])]}}]),model:{value:(_vm.sourceSetups.selectedDateField),callback:function ($$v) {_vm.$set(_vm.sourceSetups, "selectedDateField", $$v)},expression:"sourceSetups.selectedDateField"}})],1)]),_c('v-divider'),_c('div',{staticStyle:{"max-height":"300px","overflow":"auto"}},[_c('div',{staticClass:" ml-2 pt-2 pb-1",staticStyle:{"font-size":"13px","color":"grey"}},[_vm._v(_vm._s(_vm.$t('chart.selectRange')))]),_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                                command: 'update_dateOpt',
                                value: $event
                            })}},model:{value:(_vm.sourceSetups.dateOpt),callback:function ($$v) {_vm.$set(_vm.sourceSetups, "dateOpt", $$v)},expression:"sourceSetups.dateOpt"}},_vm._l((_vm.dateOpts),function(opt){return _c('div',{key:opt.value,attrs:{"value":opt.id}},[_c('v-list-item',{staticClass:"d-flex align-center",staticStyle:{"min-height":"25px"},attrs:{"value":opt.id}},[_c('div',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(opt.name))])]),(opt.value=='PAST_' && _vm.sourceSetups.dateOpt==16)?_c('div',{staticClass:"black--text px-4 my-2"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","height":"30","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.b4_afterDate),callback:function ($$v) {_vm.b4_afterDate=$$v},expression:"b4_afterDate"}})],1):_vm._e(),(opt.value=='NEXT_' && _vm.sourceSetups.dateOpt==17)?_c('div',{staticClass:"black--text px-4 my-2"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","height":"30","hide-details":""},model:{value:(_vm.b4_afterDate),callback:function ($$v) {_vm.b4_afterDate=$$v},expression:"b4_afterDate"}})],1):_vm._e(),(opt.value=='DATE_' && _vm.sourceSetups.dateOpt==18)?_c('div',{staticClass:"black--text px-4 my-2"},[_c('v-menu',{attrs:{"top":"","offset-x":"","nudge-right":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"readonly":"","outlined":"","dense":"","height":"30","hide-details":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"range":"","tile":"","elevation":"0"},model:{value:(_vm.specifiedDates),callback:function ($$v) {_vm.specifiedDates=$$v},expression:"specifiedDates"}})],1)],1):_vm._e()],1)}),0)],1)],1)],1)],1)],1),_c('div',{staticClass:"mx-2"},[_c('div',[_vm._v(_vm._s(_vm.$t('chart.selectFieldFrom')))]),_c('v-select',{attrs:{"item-text":function (item){ return item.title|| _vm.$t('chart.all'); },"item-value":"_id","items":_vm.views2,"outlined":"","height":"30","dense":"","hide-details":"","menu-props":{offsetY: true}},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_selectedView',
                value: $event
            })}},model:{value:(_vm.sourceSetups.selectedView),callback:function ($$v) {_vm.$set(_vm.sourceSetups, "selectedView", $$v)},expression:"sourceSetups.selectedView"}})],1),_c('div',{staticClass:"mx-2 mt-4 flex-grow-0"},[_vm._v(" "+_vm._s(_vm.$t('chart.field'))+" ")]),_c('div',{staticClass:"flex-grow-1 mb-2",staticStyle:{"overflow":"auto"}},_vm._l((_vm.fields),function(item,i){return _c('div',{key:i,staticClass:"draggablee d-flex dragdemo align-center",class:_vm.selected_id.includes(item._id)? 'info--text':'',attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.startDragging(item, $event)},"dragend":function($event){return _vm.endDragging(item, $event)}}},[_c('font-awesome-icon',{staticClass:"fa-fw mr-2",attrs:{"icon":_vm.getWidgetIcon(item.widgetId)}}),(!_vm.localeTypes.includes(item.type))?_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.label))]):_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('chart.'+item.type)))])],1)}),0),_c('v-snackbar',{attrs:{"timeout":3000,"color":"error","right":"","min-width":"200","top":""},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('chart.unSupportedType')))])],1),_c('select-field-dialog',{ref:"selectFieldDialog",model:{value:(_vm.showSelectFieldDialog),callback:function ($$v) {_vm.showSelectFieldDialog=$$v},expression:"showSelectFieldDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }