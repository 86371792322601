export const apps = {
  FETCH_APPS: "FETCH_APPS",
  FETCH_APP: "FETCH_APP",

  DELETE_APPS: "DELETE_APPS",
  DUPLICATE_APP: "DUPLICATE_APP",
  UPDATE_APP_PROPERTY: "UPDATE_APP_PROPERTY",

  DUPLICATING_APP: "DUPLICATING_APP",
  DUPLICATING_APP_SUCCESS: "DUPLICATING_APP_SUCCESS",

  SET_SELECTED_MENU_ITEM_ID: "SET_SELECTED_MENU_ITEM_ID",
  SET_CURRENT_MODULE_INDEX: "SET_CURRENT_MODULE_INDEX",
  SET_APP_PROPERTY: "SET_APP_PROPERTY",

  INSERT_APP_MODULE: "INSERT_APP_MODULE",
  UPDATE_APP_MODULE: "UPDATE_APP_MODULE",
  REMOVE_APP_MODULE: "REMOVE_APP_MODULE",
  REORDER_MODULES: "REORDER_MODULES",

  APPEND_CURRENT_MODULE_MENU: "APPEND_CURRENT_MODULE_MENU",
  UPDATE_CURRENT_MODULE_MENU: "UPDATE_CURRENT_MODULE_MENU",
  REMOVE_CURRENT_MODULE_MENU: "REMOVE_CURRENT_MODULE_MENU",
  TOGGLE_CURRENT_MODULE_MENU_VISIBILITY:
    "TOGGLE_CURRENT_MODULE_MENU_VISIBILITY",

  STORE_MODULE_MENU: "STORE_MODULE_MENU",

  DUPLICATE_MENU_ITEM: "DUPLICATE_MENU_ITEM",
  MOVE_CURRENT_MODULE_MENU: "MOVE_CURRENT_MODULE_MENU",

  RESET_APPS: "RESET_APPS",
};

export const childTable = {
  FETCH_CHILD_TABLE_INFO: "FETCH_CHILD_TABLE_INFO",
  RESET_CHILD_TABLE: "RESET_CHILD_TABLE",
};

export const common = {
  USER_POST: "USER_POST",
  USER_GET: "USER_GET",

  COMMON_GET: "COMMON_GET",
  COMMON_POST: "COMMON_POST",
  COMMON_PUT: "COMMON_PUT",
  COMMON_DELETE: "COMMON_DELETE",

  AUTH_GET: "AUTH_GET",
  AUTH_POST: "AUTH_POST",
  AUTH_PUT: "AUTH_PUT",
  AUTH_DELETE: "AUTH_DELETE",
  AUTH_REFRESH: "AUTH_REFRESH",
  AUTH_UPLOAD_IMAGE: "AUTH_UPLOAD_IMAGE",

  REFRESH_AUTH_GET: "REFRESH_AUTH_GET",
  REFRESH_AUTH_POST: "REFRESH_AUTH_POST",
  REFRESH_AUTH_PUT: "REFRESH_AUTH_PUT",
  REFRESH_AUTH_DELETE: "REFRESH_AUTH_DELETE",

  FETCH_LOCAL_STORAGE: "FETCH_LOCAL_STORAGE",
  SAVE_LOCAL_STORAGE: "SAVE_LOCAL_STORAGE",

  FETCH_PAGE_SIZE: "FETCH_PAGE_SIZE",
  SAVE_PAGE_SIZE: "SAVE_PAGE_SIZE",

  TRACK_WITH_APPID_FORMID:"TRACK_WITH_APPID_FORMID"
};

export const constants = {
  FETCH_CONSTANTS: "FETCH_CONSTANTS",
  RESET_CONSTANTS: "RESET_CONSTANTS",
};

export const data = {
  FETCH_DATA_LIST: "FETCH_DATA_LIST",
  FETCH_DATA_GROUPING: "FETCH_DATA_GROUPING",
  FETCH_SELECTION_DATA: "FETCH_SELECTION_DATA",
  FETCH_DATA_FIELDS: "FETCH_DATA_FIELDS",
  FETCH_PROJECT_DATA: "FETCH_PROJECT_DATA",
  GET_DATA_FIELD: "GET_DATA_FIELD",
  GET_GANTT_RECORD: "GET_GANTT_RECORD",
  FETCH_DATA_SUMMARY: "FETCH_DATA_SUMMARY",

  SAVE_DATA: "SAVE_DATA",
  SAVE_DATA_BATCH: "SAVE_DATA_BATCH",

  FETCH_RECORD: "FETCH_RECORD",
  GENERATE_TEMP_RECORD_ID: "GENERATE_TEMP_RECORD_ID",

  ATTACH_RELATED_RECORDS: "ATTACH_RELATED_RECORDS",
  UPDATE_RELATED_RECORDS: "UPDATE_RELATED_RECORDS",
  DELETE_RELATED_RECORDS: "DELETE_RELATED_RECORDS",
  DUPLICATE_RELATED_RECORD: "DUPLICATE_RELATED_RECORD",
  FETCH_RELATED_RECORDS: "FETCH_RELATED_RECORDS",
  FETCH_FORMS_RELATED_RECORDS: "FETCH_FORMS_RELATED_RECORDS",
  FETCH_RELATED_DATA_LIST: "FETCH_RELATED_DATA_LIST",
  FETCH_RELATED_RECORD_BY_IDS: "FETCH_RELATED_RECORD_BY_IDS",
  UPDATE_SUMMARY_FIELDS: "UPDATE_SUMMARY_FIELDS",
  UPDATE_CASCADE_SUMMARY: "UPDATE_CASCADE_SUMMARY",
  CHECK_TEMP_ID: "CHECK_TEMP_ID",
  RESET_DATA: "RESET_DATA",
  UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID:
    "UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID",
  COPY_ATTACHMENTS: "COPY_ATTACHMENTS",
};

export const dataTable = {
  FETCH_DATASOURCE_FIELDS: "FETCH_DATASOURCE_FIELDS",
  FETCH_DATASOURCE_INFO: "FETCH_DATASOURCE_INFO",
};

export const filters = {};

export const formConfig = {
  REFRESH_DATASOURCE_INFOS: "REFRESH_DATASOURCE_INFOS",
  SET_FORM_BUFFER: "SET_FORM_BUFFER",

  SET_FIELD_INFO_PROPERTY: "SET_FIELD_INFO_PROPERTY",
  SET_FORM_INFO_PROPERTY: "SET_FORM_INFO_PROPERTY",

  SET_CHILD_TABLE_FIELD_ID: "SET_CHILD_TABLE_FIELD_ID",
  ADD_CHILD_TABLE_FIELD_INFO: "ADD_CHILD_TABLE_FIELD_INFO",
  SET_CHILD_TABLE_INFO_ID: "SET_CHILD_TABLE_INFO_ID",
  RESET_CHILD_TABLE_FIELD: "RESET_CHILD_TABLE_FIELD",
  EDIT_CHILD_TABLE_FIELD: "EDIT_CHILD_TABLE_FIELD",
  SET_SELECTED_FORM_FIELD_ID: "SET_SELECTED_FORM_FIELD_ID",

  ADD_CHILD_TABLE: "ADD_CHILD_TABLE",

  INSERT_LAYOUT_ITEM: "INSERT_LAYOUT_ITEM",
  REMOVE_LAYOUT_ITEM: "REMOVE_LAYOUT_ITEM",
  REMOVE_LAYOUT_ROW: "REMOVE_LAYOUT_ROW",
  REMOVE_FIELD: "REMOVE_FIELD",
  DUPLICATE_LAYOUT_ITEM: "DUPLICATE_LAYOUT_ITEM",
  CLEAR_ALL: "CLEAR_ALL",
  REMOVE_OBSOLATE_FIELD_INFOS: "REMOVE_OBSOLATE_FIELD_INFOS",

  ADD_DEFAULT_FIELDS: "ADD_DEFAULT_FIELDS",
  APPEND_WIDGET_TO_LAYOUT: "APPEND_WIDGET_TO_LAYOUT",

  RESET_FORM_CONFIG: "RESET_FORM_CONFIG",
  ADD_FORM_OPTIONS: "ADD_FORM_OPTIONS",

  SWAP_PAGING_TABLES: "SWAP_PAGING_TABLES",
  // ADJUST_WIDGET_WIDTH_THE_MOST: 'ADJUST_WIDGET_WIDTH_THE_MOST',
};

export const panels = {
  FETCH_PANEL: "FETCH_PANEL",
  SET_CURRENT_PANEL_PROPERTY: "SET_CURRENT_PANEL_PROPERTY",
};

export const inputPanels = {
  FETCH_INPUT_PANEL: "FETCH_INPUT_PANEL",
  SAVE_INPUT_PANEL: "SAVE_INPUT_PANEL",
  UPDATE_INPUT_PANEL: "UPDATE_INPUT_PANEL",
  SET_CURRENT_INPUT_PANEL_PROPERTY: "SET_CURRENT_INPUT_PANEL_PROPERTY",
  FETCH_INPUT_PANEL_DATA: "FETCH_INPUT_PANEL_DATA",
  FETCH_INPUT_PANEL_DATA_BY_MASTER: "FETCH_INPUT_PANEL_DATA_BY_MASTER",
  INPUT_PANEL_SUBMIT_CODE: "INPUT_PANEL_SUBMIT_CODE",
  UPDATE_INPUT_PANEL_PROPERTY: "UPDATE_INPUT_PANEL_PROPERTY",
  INPUT_PANEL_ADD_ENTRY: "INPUT_PANEL_ADD_ENTRY",
  INPUT_PANEL_UPDATE_ENTRY: "INPUT_PANEL_UPDATE_ENTRY",
};

export const calendarPanels = {
  FETCH_CALENDAR_PANEL: "FETCH_CALENDAR_PANEL",
  UPDATE_CALENDAR_PANEL: "UPDATE_CALENDAR_PANEL",
};

export const appTemplates = {
  FETCH_APP_TEMPLATE_CATEGORIES: "FETCH_APP_TEMPLATE_CATEGORIES",
  FETCH_APP_TEMPLATES: "FETCH_APP_TEMPLATES",
  INSTALL_TEMPLATE: "INSTALL_TEMPLATE",
};

export const forms = {
  FETCH_FORM: "FETCH_FORM",
  SAVE_FORM: "SAVE_FORM",

  FETCH_PRINT_TEMPLATES: "FETCH_PRINT_TEMPLATES",

  UPDATE_FORM_SETTING: "UPDATE_FORM_SETTING",

  INSERT_FORM_VIEW: "INSERT_FORM_VIEW",
  UPDATE_FORM_VIEW: "UPDATE_FORM_VIEW",
  REMOVE_FORM_VIEW: "REMOVE_FORM_VIEW",
  COPY_FORM_VIEW: "COPY_FORM_VIEW",

  INIT_LEVEL_DIAGRAM_VIEW: "INIT_LEVEL_DIAGRAM_VIEW",
  REORDER_VIEWS: "REORDER_VIEWS",

  DELETE_FORM_RECORD: "DELETE_FORM_RECORD",

  FETCH_RELATED_TABLE_INFO: "FETCH_RELATED_TABLE_INFO",
  FETCH_CASCADE_OTHER_TABLE_FIELDS: "FETCH_CASCADE_OTHER_TABLE_FIELDS",
  RESET_FORMS: "RESET_FORMS",
  SET_CURRENT_FORM_PROPERTY: "SET_CURRENT_FORM_PROPERTY",
  SET_CURRENT_FORM_ICON: "SET_CURRENT_FORM_ICON",
  CLEAR_FORM_FROM_MEMORY: "CLEAR_FORM_FROM_MEMORY",
  SET_DISPLAY_RULE: "SET_DISPLAY_RULE",
  APPEND_SELECTION_OPTIONS: "APPEND_SELECTION_OPTIONS",
  FETCH_SHAREABLE_TEMPLATES: "FETCH_SHAREABLE_TEMPLATES"
};

export const imports = {
  IMPORT_START: "IMPORT_START",
};

export const routes = {
  SET_DOCX_CACHE: "SET_DOCX_CACHE",
  GET_DOCX_CACHE: "GET_DOCX_CACHE",
};

export const yoovWork = {
  REFRESH_TOKEN: "REFRESH_TOKEN",
  SET_TOKEN: "SET_TOKEN",
  YOOV_REQUEST: "YOOV_REQUEST",
  SCHEDULE_REFRESH: "SCHEDULE_REFRESH",
  RESET_YOOVWORK: "RESET_YOOVWORK",
  GET_YOOVWORK_EMPLOYEES: "GET_YOOVWORK_EMPLOYEES",
  SET_YOOVWORK_EMPLOYEES:"SET_YOOVWORK_EMPLOYEES"
};
