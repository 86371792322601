import {app} from '@/main'
import {calendarPanels as types} from "../../action-types"

export default {
  [types.UPDATE_CALENDAR_PANEL] ({commit, dispatch, getters}, payload) {
    return new Promise((resolve, reject) => {
      const putData = {
        urlCommand: '/calendar_panels/' + payload.data._id,
        data: {...payload.data, _id: null}
      }
      dispatch('AUTH_PUT', putData).then(
        response => {
          dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_calender_mani",
            data: {
              module_name: payload.data.title,
              func_name: app.$sensors.events.plus_calender_mani.SAVE_CALENDER,
              form_id: payload.data._id,
            }
        })
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.FETCH_CALENDAR_PANEL]({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      var panelId = ''
      if (payload && payload.panelId) {
        panelId = payload.panelId
      } else {
        panelId = localStorage.getItem('calendarPanelId')
      }
      if (!panelId || panelId === '') {
        reject('Calendar Panel Id undefinited')
      }

      const getData = {
        urlCommand: '/calendar_panels/' + payload.panelId,
      }

      dispatch('AUTH_GET', getData).then(
        async response => {
          console.log('FETCH_CALENDAR_PANEL : response: ', response)
          // commit(mutations.SET_CURRENT_CALENDAR_PANEL, response.result)
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    
    })
  },

}