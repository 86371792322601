<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{on, attrs}">
        <v-btn
          v-bind="attrs"
          v-on="on">
          {{ label }}
        </v-btn>
      </template>
      <v-card>
        <v-date-picker v-model="pickerDate"></v-date-picker>
      </v-card>
    </v-menu>
  </div>

</template>

<script>
export default {
  name: 'dateMenuButton',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: String,
    value: String
  },
  computed: {
    pickerDate: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }

}
</script>