<template>
  <div class="workform-clip-card-container">
    <v-layout align-center class="workform-clip-card-v2">
      <p v-if="onlyPrefix">
        <v-icon size="13">
          {{ item.icon || 'mdi-sitemap' }}
        </v-icon>
        <span class="first">
          {{ 
            isBranch ? 
            item.label : 
            item.event
          }}
        </span>
      </p>
      <div v-if="onlyPrefix" class="divider" />
      <!-- <div v-if="onlyPrefix && onlySuffix" class="workform-clip-arrow1" />
      <div v-if="onlyPrefix && onlySuffix" class="workform-clip-arrow2" /> -->
      <!-- <div v-if="onlySuffix" class="workform-clip-card-label workform-clip-card-yellow" :class="{
        'workform-clip-card-label-signle': !onlyPrefix && onlySuffix,
      }" :style="{ background: $vuetify.theme.themes.light.primary }"> -->
      <v-layout align-center justify-center v-if="item.isSystem">
        {{  
          renderLabel ? 
          renderLabel(item, true) : 
          $t(`workflow.widgets.${item.fieldId}`) }}
      </v-layout>
      <v-layout align-center justify-center v-else>
        <span v-if="!item.virtual" class="second">
          {{`${$t('workflow.label.table')} ${item.table}`}}
        </span>
        <div v-if="!item.virtual" class="divider" />        
        <span class="third">{{item.virtual ? $t(item.label) : item.label}}</span>
        <div v-if="!!item.fieldType" class="divider" />
        <span class="fourth" v-if="!!item.fieldType">
          {{ $t(`workflow.widgets.${item.fieldType}`) }}
        </span>
      </v-layout>
      <!-- </div> -->
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      item: Object,
      isBranch: Boolean,
      onlyPrefix: Boolean,
      onlySuffix: Boolean,
      renderLabel: Function,
    },
  }
</script>

<style>
</style>
