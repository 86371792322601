<template>
  <div style="z-index:1;">
    <v-app-bar
      color=""
      dense
      dark
      class="flex-grow-0 d-flex flex-row"
      style="z-index:1;width:100%;"
    >
      <v-toolbar-title
        class="pl-1 d-flex flex-row align-center"
      >

        <v-btn 
          @click.stop="onBackButtonClicked">
          <v-icon medium>mdi-chevron-left-circle</v-icon>
        </v-btn>
        <div v-if="currentItem" class="ml-1 d-flex flex-row align-center">
          {{ currentItem.label }}
          <v-chip color="red" class="ml-3" label small>{{
            $t("general.editing")
          }}</v-chip>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Header: Main Tabs -->
      <div
        class="admin-header-tabs d-flex flex-row align-self-end form-section align-center"
        style="position:relative;"
      >
        <div class="d-inline-block" style="position:relative;">
          <v-tabs :value="currentTabIndex" @change="check">
            <v-tab
              v-for="(tab, i) in tabs"
              :key="`tab-${i}`"
              class="min-width-100"
              >{{ tab.name }}</v-tab
            >
          </v-tabs>
          <div class="d-inline-block d-flex flex-row align-center" style="position:absolute;top:0;top:5px;left: 100%;">
            <v-btn 
              v-show="showSaveButton" class="ml-2" color="primary" @click="onSaveClicked">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              {{ $t('buttons.save') }}</v-btn>
            <v-icon
              v-show="contentChanged"
              color="warning"
              class="ml-2"
              style="position:absolute;left:100%;">
              mdi-alert
            </v-icon>
          </div>
        </div>
      </div>
      <v-spacer></v-spacer>

      <!-- User Menu -->
      <user-menu v-if="user"></user-menu>
      <v-btn v-else 
        @click="login">
        {{ $t("general.login") }}
      </v-btn>
      <v-snackbar
        v-model="snackbar"
        :top="true"
        :right="true"
        :timeout="2000"
        color="info"
        >{{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn 
            dark text v-bind="attrs" @click="snackbar = false">
            {{ $t("buttons.close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-app-bar>
    <info-dialog ref="infoDialog"></info-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <v-overlay
      class="menu-overlay"
      v-if="pausingMenu"
      absolute></v-overlay>
  </div>
</template>

<script>
import mixin from "../mixin"
import baseMixin from '@/mixins/baseMixin';

import EventBus from "@/event-bus.js";
import userMenu from "@/components/UserMenu";
import confirmDialog from '@/components/dialogs/ConfirmDialog'
import infoDialog from '@/components/dialogs/InfoDialog'

export default {
  mixins: [mixin, baseMixin],
  components: {
    userMenu,
    confirmDialog,
    infoDialog
  },
  name: "AdminHeader",
  data: function() {
    return {
      currentTabIndex: 0,
      prevTabIndex: 0,
      selectedIndex: 0,

      showPassword: false,
      message: "",
      snackbar: false,

      showDialog: false,
      pausingMenu: false,
    };
  },
  computed: {
    showSaveButton () {
      const vm = this
      return (vm.isAdmin || vm.isAllowEditForm) && (
        vm.atAdminFormBuilderPage ||
        vm.atAdminPanelBuilderPage
      )
    },
    isAllowEditForm() {
      let result = false;
      const currentForm = this.$store.getters.currentForm;
      result = currentForm.canEditForm;
      return result;
    }, 
    contentChanged () {
      const vm = this
      if (vm.atAdminFormBuilderPage) {
        return vm.formChanged
      } else if (vm.atAdminPanelBuilderPage) {
        return vm.panelChanged
      } else return false;
    },
    atAdminFormBuilderPage () {
      return this.$route.name === 'adminFormBuilder'
    },
    atAdminPanelBuilderPage () {
      return this.$route.name === 'adminPanelBuilder'
    },
    tabs () {
      const vm = this
      if (vm.atAdminFormPage) {
        return vm.formTabs
      } else if(vm.atAdminPanelPage) {
        return vm.panelTabs
      }
      return null
    },
    panelTabs () {
      const vm = this
      let result = [
        {
          name: this.$t("admin.panel"),
          route: "adminPanelBuilder",
          isActive: false,
        }
      ]
      return result
    },
    formTabs () {
      const vm = this
      let result = [
        {
          name: this.$t("admin.form"),
          route: "adminFormBuilder",
          isActive: false,
        }
      ]
      if (!vm.isHiddenForDeployment('formbuilder_setup')) {
        result.push({
          name: this.$t("admin.tableSetup"),
          route: "adminTableSetup",
          isActive: false,
        })
      }
      if (!vm.isHiddenForDeployment('formbuilder_publication')) {
        result.push({
          name: this.$t("admin.publicPublishing"),
          route: "adminPublicPublishing",
          isActive: false,
        })
      }
      return result
    },

    titleFieldWidgetTypes(){
      return this.$store.getters.titleFieldWidgetTypes
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    user() {
      return this.$store.getters.user;
    },
    form() {
      return this.$store.getters.currentForm;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },

    formUIElementInfos() {
      return this.$store.getters.formUIElementInfos || [];
    },
    formOptions () {
      return this.$store.getters.formOptions;
    },
    formFieldInfos() {
      return this.$store.getters.formFieldInfos || [];
    },
    formLayoutConfig() {
      return this.$store.getters.formLayout;
    },
    formInfo() {
      return this.$store.getters.formInfo;
    },
    widgetList(){
      return this.formFieldInfos.concat(this.formUIElementInfos).map(info=> info.label).join(", ");
    },
    formLayoutItems () {
      return this.$store.getters.formLayoutItems;
    },
    panelChanged () {
      return this.$store.getters.panelConfigChanged
    },
    formChanged() {
      const vm = this;
      let result = false;
      if (
        vm.formUIElementInfos &&
        vm.formFieldInfos &&
        vm.formLayoutConfig &&
        vm.form
      ) {
        const sFormUIElementInfos = JSON.stringify(vm.formUIElementInfos);
        const sFormFieldInfos = JSON.stringify(vm.formFieldInfos);
        const sFormLayoutConfig = JSON.stringify(vm.formLayoutConfig);

        const fieldInfos = vm.form.fieldInfos
        result =
          sFormFieldInfos !== JSON.stringify(fieldInfos) ||
          sFormUIElementInfos !== JSON.stringify(vm.form.uiElementInfos) ||
          sFormLayoutConfig !== JSON.stringify(vm.form.layout) ||
          vm.formInfo.titleFieldInfoId !== vm.form.titleFieldInfoId ||
          JSON.stringify(vm.formInfo.linkedFields) !== JSON.stringify(vm.form.linkedFields)

      } 
      return result;
    },

    atAdminFormPage() {
      const vm = this;

      return (
        [
          "adminForms",
          "adminFormBuilder",
          "adminPublicPublishing"
        ].indexOf(vm.$route.name) >= 0)
        ||
        vm.$route.name.indexOf('adminTableSetup')===0
    },
    atAdminPanelPage() {
      const vm = this;
      return vm.$route.name === 'adminPanelBuilder' ||
        vm.$route.name === 'adminPanels'
    },
    itemType() {
      const vm = this;
      let result = "";
      switch (vm.$route.name) {
        case "adminPanels":
        case "adminPanelBuilder":
          result = "panel";
          break;
        case "adminForms":
        case "adminFormBuilder":
        case "adminTableSetup":
        case "adminTableSetupFunctionToggle":
        case "adminPublicPublishing":
        case "adminTableSetupPrintTemplate":
        case "adminTableSetupDisplayRules":
          result = "form";
          break;
      }
      return result;
    },
    appItemRouteName() {
      return this.itemType + "s";
    },
    currentItem() {
      const vm = this;
      let result = null;
      switch (vm.itemType) {
        case "panel":
          result = vm.$store.getters.currentPanel;
          break;
        case "form":
          result = vm.$store.getters.currentForm;
          break;
      }
      return result;
    },
    currentSection() {
      const vm = this;
      let result = null;
      switch (vm.itemType) {
        case "panel":
          result = vm.panelTabIndex;
          break;
        case "form":
          result = vm.formTabIndex;
          break;
      }
      return result;
    },
  },
  watch: {
    currentSection: function(newValue) {
      this.$store.dispatch("SET_SELECTED_MENU_ITEM_ID", newValue);
    },
  },
  created() {
    const vm = this;
    EventBus.$on('disableToolbar', this.disableToolbar)
    EventBus.$on('enableToolbarB', this.enableToolbar)

    switch (vm.$route.name) {
      case "adminFormBuilder":
        vm.formTabIndex = vm.TAB_FORM_BUILDER;
        break;
      case "adminTableSetup":
        vm.formTabIndex = vm.TAB_TABLE_SETUP;
        break;
      case "adminPublicPublishing":
        vm.formTabIndex = vm.TAB_PUBLIC_PUBLISHING;
        break;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("RESET_FORM_CONFIG");
    EventBus.$off('disableToolbar')
    EventBus.$off('enableToolbar')
  },
  mounted() {
    this.$store.dispatch("SET_SELECTED_MENU_ITEM_ID", this.currentSection);
    const routeNameArr = this.tabs.map((tab) => {
      return tab.route;
    });
    this.currentTabIndex = this.prevTabIndex = routeNameArr.indexOf(
      this.$route.name
    );
  },
  methods: {
    async check(clickedTab) {
      const vm = this
      vm.currentTabIndex = vm.selectedIndex = clickedTab;
      await vm.$nextTick();
      vm.currentTabIndex = this.prevTabIndex;
      const result = await vm.confirmSaveOrAbandonForAction();
      if (result !== false){
        vm.switchTab()
      }
    },
    switchTab() {
      this.prevTabIndex = this.currentTabIndex = this.selectedIndex;
      let vm = this
      if (vm.currentItem) {
        const routeName = vm.tabs[vm.currentTabIndex].route
        if (vm.$route.name !== routeName) { 
          this.$router.push({
            name: routeName,
            params: {
              id: vm.currentApp._id,
              itemId: vm.currentItem._id,
            },
          });
        }
      }
    },
    async savePanel() {
      EventBus.$emit('savePanel', {
        panelId: this.selectedMenuItemId,
        elements: this.elements
      })
    },

    isValidFieldInfo (fieldInfoId) {
      const vm = this
      let empty = fieldInfoId === null || fieldInfoId === ''
      let result = !empty
      if (!empty) {
        const fieldExisted = vm.formFieldInfos.find(
          info => info._id === vm.formInfo.titleFieldInfoId)
        result = !!fieldExisted
      }
      return result
    },

    getValidFieldInfoIds () {
      const vm = this
      return vm.formLayoutItems.map(item => item._id)
    },

    async clearObsolateFieldInfos () {
      const vm = this
      let validFieldInfoIds = vm.getValidFieldInfoIds()
      await vm.$store.dispatch('REMOVE_OBSOLATE_FIELD_INFOS', {
        validFieldInfoIds: validFieldInfoIds})
    },

    async onSaveClicked() {      
      const vm = this;
      if (vm.atAdminFormBuilderPage) {
        await vm.saveForm()
      } else if(vm.atAdminPanelBuilderPage) {
        await vm.savePanel()
      }
    },
    async saveForm () {
      const vm = this
      await vm.clearObsolateFieldInfos()
      const hasValidTitle = vm.isValidFieldInfo(vm.formInfo.titleFieldInfoId)

      if (!hasValidTitle) {
        // Assign title field automatically the first field if not defined
        const firstFieldInfoId = vm.getFirstFieldInfoId(vm.formLayoutConfig);
        if (firstFieldInfoId) {
          vm.$store.dispatch("SET_FORM_INFO_PROPERTY", {
            propertyKey: "titleFieldInfoId",
            propertyValue: firstFieldInfoId,
          });
        }
      }

      // Error if no titleField
      if (!vm.formInfo.titleFieldInfoId) {
        let color = 'error'
        let message = vm.$t('messages.pleaseSpecifyTitleField')
        let fieldCount = vm.formFieldInfos.filter(
          info => !(['owner','createdBy','createdAt','updatedAt'].includes(info.fieldId))).length

        if (fieldCount === 0) {
          message = vm.$t('messages.noAnyFields')
        }
        vm.$refs.infoDialog.open('warning',
          message,
          {
            buttons: ['close'],
            color: color
          }
        )
        return false;
      }

      EventBus.$emit('showSpinner')
      const payload = {
        formUIElementInfos: vm.formUIElementInfos,
        formFieldInfos: vm.formFieldInfos,
        formLayoutConfig: vm.formLayoutConfig,
        formInfo: vm.formInfo,
        formOptions: vm.formOptions
      };

      try {
        await vm.$store.dispatch("SAVE_FORM", payload)
        EventBus.$emit('hideSpinner')
        vm.message = vm.$t("messages.savedSuccessfully") + ".";
        vm.snackbar = true;
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
          key: "plus_sheet_mani",
          data: {
            module_name: this.form.label,
            func_name: this.$sensors.events.plus_sheet_mani.table_view.SAVE_FORM,
          }
        });
        this.$sensors.track("plus_form_editor_save", {
          app_id: this.currentApp._id,
          widget_list: this.widgetList
        });
      } catch(err) {
        EventBus.$emit('hideSpinner')
        this.$toast.error(this.$t("errors.saveForm"))
        return false
      } 
      return true;
    },
    getFirstFieldInfoId() {
      const vm = this;
      let result = ''
      for (let i = 0; i < vm.formLayoutItems.length; i++) {
        const loopItem = vm.formLayoutItems[i]
        if (loopItem.controlType === 'data') {
          const fieldInfo = this.formFieldInfos.find(info=>info.fieldId === loopItem._id);
          if (fieldInfo && this.titleFieldWidgetTypes.includes(fieldInfo.type))
            result = loopItem._id
            break
        }
      }
      return result
    },
    onBackButtonClicked () {
      const vm = this
      if (vm.atAdminFormPage || vm.atAdminPanelPage) {
        vm.confirmSaveOrAbandonForAction().then((result)=> {
          if (result){
             vm.returnToAppPage()
          }
        })
      } else {
        vm.returnToAppPage()
      }
    },
    async confirmSaveOrAbandonForAction() {
      const vm = this
      if (vm.contentChanged) {
        const dialogResult = await vm.$refs.confirmDialog.open(
          vm.$t('general.warning'),
          vm.$t('messages.changesMadeAreYouSureToSaveOrAbandon'),
          {
            color: 'warning',
            cancelBtnColor: 'muted',
            cancelMsg: vm.$t('buttons.cancel'),
            buttons: [
              {label: vm.$t('buttons.saveAndLeave'), value: 'saveAndLeave', color: 'primary'},
              {label: vm.$t('buttons.abandonAndLeave'), value: 'abandonAndLeave', color: 'error'}
            ],
            width: 480
          }
        );
        if (dialogResult) {
          switch (dialogResult.result) {
            case 'saveAndLeave':
              if (vm.atAdminFormPage) {
               return await vm.saveForm();
              } else if (vm.atAdminPanelPage) {
               vm.savePanel();
               return true
              }
              break;
            case 'abandonAndLeave':
              if (vm.atAdminFormPage) {
                vm.createFormBuffer(this.$route.params.itemId); //reset form config to before modify state
                return true
              } 
              break;
          }
        }
      } 
      return true
    },

    returnToAppPage() {
      const vm = this;
      let url = "";
      if (vm.prevRoute && vm.prevRoute.name in ["forms", "records"]) {
        vm.$router.go(-1);
      } else {
        vm.$router.push({
          name: vm.appItemRouteName,
          params: {
            id: vm.currentApp._id,
            itemId: vm.currentItem._id,
          },
        });
      }
    },
    login() {
      const vm = this;
      const returnUrl =
        window.location.protocol + "//" + window.location.host + "/dashboard";
      const url = vm.$store.getters.authUrl + "/login?returnUrl=" + returnUrl;
      window.location = url;
    },
  },
};
</script>

<style>


.form-section .v-tabs .v-tabs-bar .v-tab--active {
  background-color: red;
}

.form-section .v-tabs .v-tabs-bar {
  border-bottom: transparent !important;
  background-color: transparent !important;
}

.admin-header-tabs .v-tabs .v-tabs-bar {
  height: 48px;
}
</style>
