import {app} from '@/main'
import {forms as mutations} from "../../mutation-types"
import { formConfig } from "../../mutation-types"
import {forms as types} from "../../action-types"

const onSavingWidgetRelatedRecord = (fieldInfo) => {
  // console.log('onSavingWidgetRelatedRecord starts :: fieldInfo: ', fieldInfo)
  // relatedRecordCopyFields
  const properties = fieldInfo.properties
  for (let key in properties) {
    const propertyValue = properties[key]
    switch (key) {
      case 'relatedRecordCopyFields':
        var updatedPropertyValue = {}
        for (let sourceFieldId in propertyValue) {
          if (propertyValue[sourceFieldId].length > 0) {
            updatedPropertyValue[sourceFieldId] = propertyValue[sourceFieldId]
          }
        }
        properties[key] = updatedPropertyValue
        break
    }
  }
  // console.log('onSavingWidgetRelatedRecord ends :: fieldInfo: ', fieldInfo)
}

const onFormSaving = (fieldInfos) => {
  console.log('onFormSaving - sdtup copyFields property for relatedRecord')
  var result = []
  for (let i = 0; i < fieldInfos.length; i++) {
    const loopFieldInfo = fieldInfos[i]
    
    switch (loopFieldInfo.type) {
      case 'relatedRecord':
        console.log('i=' + i + 
          ': loopFieldInfo.label = ' + loopFieldInfo.label + 
          ' (' + loopFieldInfo.type + ')')

        onSavingWidgetRelatedRecord(loopFieldInfo)
        break
    }
    result.push(loopFieldInfo)
  }
  return result
}

const checkAppendSystemField = (formFieldInfos, allWidgets, key, id) => {
    const widget = allWidgets.find(widget => widget.key === key)
    if (widget) {
      const fixedFieldIdProperty = widget.properties.find(
        p => p.propertyKey === 'fixedFieldId')

      const fieldId = key // fixedFieldIdProperty.default

      // if none, append it
      const fieldInfo = formFieldInfos.find(info => info.fieldId === fieldId)
      if (!fieldInfo) {

        const fieldNameTag = 'widgets.properties.' + key
        formFieldInfos.push({
          _id: '_' + id,
          type: widget.key,
          label: app.$i18n.t(fieldNameTag),
          labelTag: fieldNameTag,
          fieldId: fieldId,
          widgetId: widget._id,
          isSystem: widget.isSystem ? widget.isSystem: false,
          properties: {
            fieldNameTag: fieldNameTag,
            fixedFieldId: fixedFieldIdProperty.default
          }

        })
      } 
    }
  }

export default {
  [types.SET_CURRENT_FORM_PROPERTY]({commit}, payload) {
    commit('SET_CURRENT_FORM_PROPERTY', payload)
  },

  [types.DELETE_FORM_RECORD]({ dispatch }, payload) {
    const data = {
      formId: payload.formId,      
    }

    if (payload.appId) Object.assign(data, {appId:payload.appId})
    if (payload.viewId) Object.assign(data, {viewId:payload.viewId})
    if (payload.include) Object.assign(data, {include:payload.include})
    if (payload.exclude) Object.assign(data, {exclude:payload.exclude})

    return new Promise((resolve, reject) => {
      dispatch("AUTH_POST", {
        urlCommand:'/data/deleteRows',
        data
      }).then((response) => {
        resolve(response);
      }).catch(err=> {
        reject(err)
      });
    });
  },
  [types.INIT_LEVEL_DIAGRAM_VIEW] ({commit, getters, dispatch}, payload) {
    const formId = payload.formId
    const viewId = payload.viewId


  },
  [types.REORDER_VIEWS]({ commit, getters, dispatch }, payload) {
    const formId = payload.form._id;
    const oldIndex = payload.oldIndex;
    const newIndex = payload.newIndex;

    return new Promise(async (resolve, reject) => {
await commit(mutations.REORDER_FORM_VIEW, {
        oldIndex,
        newIndex,
      });

      const postData = {
        urlCommand: "/forms/" + formId + "/views/replace",
        data: {
          views: getters.currentForm.views,
        },
      };

      dispatch("AUTH_POST", postData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.INSERT_FORM_VIEW]({ commit, dispatch }, payload) {
    const postData = {
      urlCommand: "/forms/" + payload.id + "/views",
      data: {
        appId: payload.appId,
        newView: {
          title: payload.title,
          viewType: payload.viewType
        }
      },
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_POST", postData).then(
        (response) => {
          commit(mutations.ADD_FORM_VIEW, {
            id: payload.id,
            view: response,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [types.REMOVE_FORM_VIEW] ({ commit, dispatch}, payload) {
    const deleteData = {
      urlCommand: '/forms/' + payload.formId + '/views/' + payload.viewId
    }
    console.log('REMOVE_FORM_VIEW')
    return new Promise((resolve, reject) => {
      console.log('REMOVE_FORM_VIEW :: Promise')
      dispatch('AUTH_DELETE', deleteData).then(
        response => {
          commit(mutations.REMOVE_FORM_VIEW, payload)
          //remove column preference in localStorage
          let localStor = JSON.parse(localStorage.getItem('VXE_TABLE_CUSTOM_COLUMN_WIDTH'));
          if (localStor && localStor.hasOwnProperty(payload.viewId)){
            delete localStor[payload.viewId]
            localStorage.setItem('VXE_TABLE_CUSTOM_COLUMN_WIDTH', JSON.stringify(localStor))
          }
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.UPDATE_FORM_VIEW] ({ commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      console.log('UPDATE_FORM_VIEW: payload: ', payload)
      const putData = {
        urlCommand: '/forms/' + payload.id + '/views/' + payload.viewId,
        data: payload.data
      }

      console.log('AUTH_PUT :: putData: ', putData)
      dispatch('AUTH_PUT', putData).then(
        response => {
          commit(mutations.SET_FORM_VIEW, {
            id: payload.id,
            view: response
          })
          resolve(response)
          //console.log('resolved')
        },
        error => {
          console.log('=> error: ', error)
          reject(error)
          //console.log('rejected')
        }
      )
    })
  },
  [types.COPY_FORM_VIEW] ({commit, dispatch}, payload){
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: "/forms/copyView",
        data: payload
      }

      dispatch('AUTH_POST', postData)
      .then((res)=> {
        commit(mutations.ADD_FORM_VIEW, {
          view: res
        })
        resolve(res)
      })
      .catch((err)=> {
        console.error(err)
        reject(err)
      })
    })
  },
  [types.SAVE_FORM] ({rootGetters, getters, commit, dispatch}, payload) {
    return new Promise(async (resolve, reject) => {
      if (!payload) {
        payload = {
          formUIElementInfos: rootGetters.formUIElementInfos,
          formFieldInfos: rootGetters.formFieldInfos,
          formLayoutConfig: rootGetters.formLayout,
          formInfo: rootGetters.formInfo
        }
      }

      const formId = getters.currentForm._id
      var formFieldInfos = JSON.parse(JSON.stringify(payload.formFieldInfos))

      const now = Date.now().toString()
      const allWidgets = getters.allWidgets

      // for new form, append system fields: 'owner', 'createdBy', 'createdAt', 'updatedAt'
      checkAppendSystemField(formFieldInfos, allWidgets, 'owner', now + '01')
      checkAppendSystemField(formFieldInfos, allWidgets, 'createdBy', now + '02')
      checkAppendSystemField(formFieldInfos, allWidgets, 'createdAt', now + '03')
      checkAppendSystemField(formFieldInfos, allWidgets, 'updatedAt', now + '04')

      // console.log('SAVE_FORM: before onFormSaving :: formFieldInfos.length: ', formFieldInfos.length)

      formFieldInfos = onFormSaving(formFieldInfos)

      // console.log('SAVE_FORM: after onFormSaving :: formFieldInfos.length: ', formFieldInfos.length)


      await commit(mutations.SET_CURRENT_FORM_PROPERTY, {
        propertyKey: 'fieldInfos',
        propertyValue: formFieldInfos
      })

      await commit(mutations.SET_CURRENT_FORM_PROPERTY, {
        propertyKey: 'uiElementInfos',
        propertyValue: payload.formUIElementInfos
      })
  
      await commit(mutations.SET_CURRENT_FORM_PROPERTY, {
        propertyKey: 'layout',
        propertyValue: payload.formLayoutConfig
      })

      await commit(mutations.SET_CURRENT_FORM_PROPERTY, {
        propertyKey: 'titleFieldInfoId',
        propertyValue: payload.formInfo.titleFieldInfoId
      })

      await commit(mutations.SET_CURRENT_FORM_PROPERTY, {
        propertyKey: 'linkedFields',
        propertyValue: payload.formInfo.linkedFields
      })

      const postData = {
        urlCommand: '/forms/' + formId,
        data: {
          form: {
            ...getters.currentForm,
            appId: getters.currentApp._id
          },
          options:payload.formOptions
        }
      }

      // console.log('SAVE_FORM :: postData: ', postData)
      dispatch('AUTH_PUT', postData).then(
        response => {
          // alert('formId = ' + formId)
          const getData = {
            urlCommand: '/forms/' + formId + '?appId=' + getters.currentApp._id
          }
          dispatch('AUTH_GET', getData).then(
            response2 => {
              dispatch("SET_FORM_BUFFER", {form:response2.result});
              commit(mutations.SET_CURRENT_FORM, response2.result)
              commit(mutations.SET_RELATED_TABLE_INFO, response2.relatedTableInfos)
              dispatch(types.FETCH_PRINT_TEMPLATES, {formId})
              resolve(response2.result)
            }
          )
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.FETCH_CASCADE_OTHER_TABLE_FIELDS] ({dispatch}, payload) {
    
    return new Promise((resolve, reject) => {
      var postData = {
        urlCommand: '/forms/other_table_fields',
        data: {
          fieldId: payload.fieldId,
          dataSource: payload.dataSource
        }
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response)
        }
      )
    })
  },

  [types.FETCH_RELATED_TABLE_INFO] ({commit, dispatch}, payload) {
    
    return new Promise((resolve, reject) => {
      const getParams = {
        urlCommand: '/forms/' + payload.dataTableId + '?appId=' + payload.appId
      }
      dispatch('AUTH_GET', getParams).then(
        response => {
          // console.log('FETCH_RELATED_TABLE_INFO : response: ', response)
          if (typeof payload.store === 'undefined' || payload.store===true) {
            commit(mutations.ADD_RELATED_TABLE_INFO_PROPERTY, response.result)
          }
          resolve(response.result)
        }
      ).catch(
        err => {

        }
      )
    })
  },

  
  [types.FETCH_FORM] ({ commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      // console.log('FETCH_FORM :: payload: ', payload)
      let setForm = payload.setForm?? true
      var formId
      if (payload && payload.formId) {
        formId = payload.formId
      } else {
        formId = localStorage.getItem('formId')
      }
      if (!formId || formId === '') {
        reject('Form Id Undefined')
      }
      formId = formId._id || formId;
      const getData = {
        urlCommand: '/forms/' + formId +'?appId=' + payload.appId
      }

      dispatch('AUTH_GET', getData).then(
        async response => {
          if(setForm){
            commit(mutations.SET_CURRENT_FORM, response.result)
            commit(mutations.SET_RELATED_TABLE_INFO, response.relatedTableInfos)
            commit(formConfig.SET_FORM_FIELD_INFOS, response.result.fieldInfos)
            commit(formConfig.SET_FORM_UI_ELEMENT_INFOS, response.result.uiElementInfos)
            dispatch(types.FETCH_PRINT_TEMPLATES, {formId})
          }
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  [types.FETCH_PRINT_TEMPLATES]({commit, dispatch}, {formId, type = null}){
    return new Promise((resolve, reject) => {
      let urlCommand = `/printTemplate/getByFormId?formId=${formId}`;
      if ([0, 1].includes(type)){
        urlCommand += `&type=${type}`
      }
      const getParams = {
        urlCommand
      };

      dispatch("AUTH_GET", getParams).then((res) => {
        commit(mutations.SET_PRINT_TEMPLATE, res);
        resolve(res);
      }).catch(err=> {
        reject(err);
      });
    })
  },
  [types.UPDATE_FORM_SETTING]({commit, dispatch}, payload){
    return new Promise((resolve, reject) => {
    const postData = {
      urlCommand: "/forms/updateSetting",
      data: {
        ...payload,
      },
    };
    dispatch('AUTH_POST', postData).then((res)=> {
      if(res === true){
        commit('SET_CURRENT_FORM_PROPERTY', payload)
        resolve(res)
      }else reject()
    })
  });
  },
  [types.RESET_FORMS]({commit, dispatch}){
    commit(mutations.RESET_FORMS)
  },

  async [types.CLEAR_FORM_FROM_MEMORY]({commit, dispatch}){
    commit(mutations.CLEAR_FORM_FROM_MEMORY)
  },

  [types.SET_DISPLAY_RULE]({commit, dispatch}, payload){
    commit(mutations.SET_DISPLAY_RULE, payload)
  },

  [types.APPEND_SELECTION_OPTIONS]({commit, getters}, {oldData, newData}) {
    const fieldInfos = getters.currentForm && getters.currentForm.fieldInfos
    const selectionFieldIds = fieldInfos.filter(info => ["singleSelection", "multipleSelection"].includes(info.type))
      .map(info=> info.fieldId)
    let newOptions = {}
    selectionFieldIds.forEach(fieldId => {
      if (oldData.hasOwnProperty(fieldId)){
        if (Array.isArray(oldData[fieldId])){ //multiple
          oldData[fieldId].forEach((val, index)=> {
            if (typeof val === 'object') {
              if (!newOptions[fieldId]) Object.assign(newOptions, {[fieldId]:[]})
              newOptions[fieldId].push({...val, _id:newData[fieldId][index], active:true,
                isDeleted:false})
            }
          })
        } else if (oldData[fieldId] && typeof oldData[fieldId] === 'object') { //single
          Object.assign(newOptions, {
            [fieldId]:[{
              ... oldData[fieldId], 
              _id:newData[fieldId],
              active:true,
              isDeleted:false
             }]
          })
        }
      }
    })
    commit('APPEND_SELECTION_OPTIONS', newOptions)
  },
  [types.SET_CURRENT_FORM_ICON]({commit, dispatch}, payload){
    commit(mutations.SET_CURRENT_FORM_ICON, payload)
  },
  [types.FETCH_SHAREABLE_TEMPLATES]({commit, dispatch}, payload){ 
    const postData = {
      urlCommand: "/publicEdit/list",
      data: payload,
    }
    return new Promise((resolve, reject) => {
      dispatch("AUTH_POST",postData).then((response) => {
        resolve(response);
      }).catch(err=> {
        reject(err)
      });
    });
  },
};
