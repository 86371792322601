<template>
  <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import confirmDialog from './ConfirmDialog'

export default {
  name: 'confirmDeleteDialog',
  components: {
    confirmDialog
  },
  methods: {
    confirm (callback, options) {
      const vm = this
      console.log('confirm')
      var title = vm.$t('buttons.delete')
      var message = vm.$t('messages.areYouSure') + '?'

      var dialogOptions = {
        color: 'error',
        confirmBtnColor: 'error'
      }

      if (options) {
        // console.log('options defined :: options: ', options)
        if (options.title) title = options.title
        if (options.message) message = options.message
        if (options.checkbox) dialogOptions['checkbox'] = options.checkbox  
        if (options.checkboxMessage) dialogOptions['checkboxMessage'] = options.checkboxMessage
        if (options.buttons) dialogOptions['buttons'] = options.buttons
        if (options.width) dialogOptions['width'] = options.width
      } else {
        // console.log('options not defined')
      }
      // console.log('ConfirmDeleteDialog :: dialogOptions: ', dialogOptions)
      console.log('confirmDialog.open')
      vm.$refs.confirmDialog.open(
        title,
        message,
        dialogOptions
      ).then( result => {
        // console.log('ConfirmDeleteDialog: result: ', result)
        if (result) {
          console.log('callback')
          if (typeof callback === 'function') {
            console.log('is function')
            callback(result)
          }
        }
      })
    }
  }
}
</script>
