<template>
  <v-card class="border d-flex align-center pl-1 flex-wrap"
          style="border-color:darkgrey;box-shadow:0 2px 1px lightgrey;"
          outlined
          min-height="32"
          max-width="100%"
          :ripple="false"
          :style="{borderColor: hasError ? 'red': ''}"
          @click="showingDialog=true">
    <selected-person-tag
        class="ma-1"
        v-for="(memberInfo, index) in selectedMembers"
        :key="memberInfo._id"
        :index="index"
        :employeeId="memberInfo._id"
        :name="memberInfo.employeeName"
        :teamId="memberInfo.teamId"
        :info="memberInfo"
        :hasCloseButton="true"
        deleteCommand="removeEmployeeByIndex"
        clickCommand="removeEmployeeByIndex"
        @onCommand="onCommandHandler($event, memberInfo)"
    ></selected-person-tag>
    <dialog-button-select-employees
        v-model="showingDialog"
        :multiple="true"
        :addButton="false"
        :selectedValue="selectedMembers"
        @submit="onSubmit"
    ></dialog-button-select-employees>
  </v-card>
</template>

<script>
import dialogButtonSelectEmployees from '@/components/dialogButtons/DialogButtonSelectEmployees'
import selectedPersonTag from '@/components/dialogs/comps/SelectedPersonTag'

export default {
  name: 'multipleMemberSelectionDialog',
  components: {
    dialogButtonSelectEmployees,
    selectedPersonTag
  },
  model: {
    prop: 'filterValue',
    event: 'input'
  },
  props: {
    filterValue: String,
    fieldInfo: Object,
    required: {
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
      showingDialog: false,
      hasError: false
    }
  },
  computed: {
    selectedMembers() {
      let result = [];
      if (this.filterValue) {
        result =  this.getMemberArrayFromFilterStr(this.filterValue)
      }
      return result
    },
    // selectedMembers: {
    //   get () {
    //     const vm = this
    //     var result = []
    //     console.log('Multi... computed(selectedMembers): filterValue=' + vm.filterValue)
    //     if (vm.filterValue && vm.filterValue !== '') {
    //       result = vm.getMemberArrayFromFilterStr(vm.filterValue)
    //     }
    //     return result
    //   },
    //   set (val) {
    //     const vm = this
    //     if (val) {
    //       if (Array.isArray(val)) {
    //         const filterValue = vm.getFilterStrFromMemberArray(val)
    //         vm.$emit('input', filterValue)
    //       }
    //     }
    //   }
    // },
    activeTeam() {
      const vm = this;
      var result = null;
      if (vm.allTeamInfos) {
        result = vm.allTeamInfos.find((info) => info._id === vm.selectedTeamId);
      }
      return result;
    },
    allTeamInfos() {
      return this.$store.getters.allTeamInfos;
    }
  },
  methods: {
    validate (state) {
      const vm = this
      vm.hasError = this.selectedMembers.length === 0
      return !vm.hasError
    },
    getMemberArrayFromFilterStr (s) {
      const vm = this
      var result = []
      const memberInfos = s.split('||')
      for (let i = 0; i < memberInfos.length; i++) {
        const loopMemberInfo = memberInfos[i]
        const dataItems = loopMemberInfo.split('|')
        // '{employeeId}|{employeeName}|{teamId}|{teamName}'
        result.push({
          _id: dataItems[0],
          employeeName: dataItems[1],
          teamId: dataItems[2],
          teamName: dataItems[3]
        })
      }
      return result
    },

    getFilterStrFromMemberArray (ar) {
      const vm = this
      const strSegs = []
      for (let i = 0; i < ar.length; i++) {
        const loopMember = ar[i]
        const memberStr = loopMember._id + '|' +
          loopMember.employeeName + '|' +
          loopMember.teamId + '|' +
          loopMember.teamName
        strSegs.push(memberStr)
      }
      return strSegs.join('||')
    },

    onCommandHandler (payload, row) {
      switch (payload.command) {
        case "removeEmployeeByIndex":
          this.removeEmployeeByIndex(payload.index, row);
          break;
      }
    },
    onSubmit (payload) {
      const vm = this
      const filterValue = vm.getFilterStrFromMemberArray(payload)
      this.$emit('input', filterValue)


    },
    removeEmployeeByIndex (index, row) {
      const vm = this
      const updatedMembers = this.selectedMembers.filter((_, i) => i !== index)
      // console.log('updatedMembers', updatedMembers)
      this.onSubmit(updatedMembers)
      if (this.required){
        this.$nextTick(()=> {
        this.validate() 
      })
      }
    }
  }
}

</script>
