<template>
  <WorkflowConsumer>
    <v-dialog
      v-model="showRelease"
      transition="dialog-top-transition"
      max-width="500"
    >
      <!-- <v-toolbar
        flat
        dark
        :color="!isReleaseError ? 'primary' : 'red'"
      >
        <b>
          {{ 
            $t(
              isReleaseError ? 'workflow.label.workflowNotAlreadyRolledOut' : 'workflow.label.workflowAlreadyRolledOut', {
                workflowName: (workflow && workflow.name) || (releaseInfo && releaseInfo.name),
              }
            )
          }}
        </b>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="showRelease = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar> -->
      <v-card>
        <v-card-text class="workflow-release-info">
          <v-layout column align-center justify-center>
            <Lottie
              v-if="!isReleaseError"
              :options="defaultOptions"
              :height="300"
              :width="300"
              class="workflow-release-info-icon"
            />
            <Lottie
              v-else
              :options="defaultErrorOptions"
              :height="300"
              :width="300"
              class="workflow-release-info-icon"
            />
            <div class="workflow-release-info-title" :style="{ color: isReleaseError ? 'red' : '#333' }">
              {{ 
                $t(!isReleaseError ? 'workflow.label.releaseSucceed' : 'workflow.label.releaseFailed', { 
                  workflowName,
                })
              }}
            </div>
            <!-- <div v-if="!isReleaseError">
              <div class="workflow-release-info-remark">
                {{ $t('workflow.label.releaseRemark') }}
              </div>
            </div> -->
            <!-- <v-icon
              v-else
              size="150"
              color="red"
              class="workflow-release-info-icon"
            >
              {{ !isReleaseError ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon> -->
            <ReleaseDateInfo v-if="!isReleaseError" :popup="true" />
            <NodeValidationLogItem
              v-else
              darkMode="true"
              :list="releasedError" 
              :messageLabel="$t('workflow.message.validationBelowWithRelease')"
            />
            <v-btn
              class="workflow-release-info-btn"
              @click="showRelease = false"
              large
              :color="isReleaseError ? 'secondary' : 'primary'"
              elevation="1">
              {{ 
                isReleaseError ? $t("workflow.btn.fixNow") : $t("workflow.btn.understand")
              }}
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </WorkflowConsumer>
</template>

<script>

import NodeValidationLogItem from './../components/tree/node/section/logItem';
import Mixin from './../mixin';
import SettingMixin from './../components/tree/setting/mixin';
import ReleaseDateInfo from './releaseInfo';
import Lottie from 'vue-lottie';
import ReleaseWorkflowAnimation from '@/pages/admin/workflow/assets/release_workflow.json';
import ReleaseWorkflowErrorAnimation from '@/pages/admin/workflow/assets/release_workflow_error.json';
export default {
  mixins: [Mixin, SettingMixin],
  props: {
    ready: Boolean,
  },
  data() {
    return {
      showRelease: false,
      defaultOptions: {
        animationData: ReleaseWorkflowAnimation,
      },
      defaultErrorOptions: {
        animationData: ReleaseWorkflowErrorAnimation,
      },
      animationSpeed: 0.5,
    }
  },
  components: {
    ReleaseDateInfo,
    NodeValidationLogItem,
    Lottie,
  },
  computed: {
    isReleasing() {
      return this.$store.getters.releasing;
    },
    workflowName() {
      const { workflow, releaseInfo } = this;
      return (workflow && workflow.name) || (releaseInfo && releaseInfo.name);
    },
    isStopping() {
      return this.$store.getters.stopping;
    },
    releaseInfo() {
      const { getReleasingInfo } = this.$store.getters;
      return getReleasingInfo && getReleasingInfo.workflow;
    }
  },
  watch: {
    isReleasing: {
      deep: true,
      handler: function (released, wasReleasing) {
        this.showRelease = released !== wasReleasing && !released;
      },
    },
    isStopping: {
      deep: true,
      handler: function () {
        this.showRelease = false;
      },
    }
  },
};

</script>

<style scoped>
</style>
