<template>
<v-row class="card-buttons" transition="fade-transition">
  <v-col class="d-flex flex-row justify-start py-1">

    <div class="mr-1" v-for="button in buttons" :key="button.command">
      <v-btn v-if="button.show" :color="button.color" @click.prevent.top="onButton(button)" x-small fab circle><v-icon>{{ button.tagType }}</v-icon>
    </v-btn>
    </div>
  </v-col>
</v-row>
</template>

<script>
export default {
  props: {
    canEdit:{
      type: Boolean,
      default: true
    },
    canDelete:{
      type: Boolean,
      default:true
    }
  },
  data () {
    return {
      buttons: [
        {tagType: 'mdi-file-document-edit', command: 'view', show: this.canEdit},
        {tagType: 'mdi-delete', command: 'delete', color: 'error', show: this.canDelete}
      ]
    }
  },
  methods: {
    onButton (payload) {
      const vm = this
      vm.$emit('onCommand', {
        command: payload.command
      })
    }
  }
}
</script>
