<template>
<div class="input-panel-access-denied d-flex flex-column fill-height w-100">
  <div class="flex-grow-0 py-0 px-2 d-flex flex-row align-center"
    style="min-height:44px;">
    <span>{{ title }}</span>
  </div>
  <v-divider></v-divider> 
  <div class="flex-grow-1 mt-10">
    <h1 class="text-center">{{ $t('messages.accessDenied') }}</h1>
  </div>
</div>
</template>

<script>
export default {
  name: 'panelAccessDenied',
  props: {
    title: String
  },
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  }
}
</script>