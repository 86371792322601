<template>
  <v-card flat class="px-12 py-2 fill-height">
    <v-card-title class="tabeSetupTitle pb-0">{{
      $t("printTemplate.printTemplate")
    }}</v-card-title>
    <v-card-text v-show="!showSystemEditDialog" class="pt-0">
      <v-row no-gutters class="pt-0 mb-5">
        <div class="pa-0 grey--text">
          {{ $t("printTemplate.description") }}
        </div>
        <v-spacer />
        <v-menu offset-y left open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="elevation-0 float-right"
              color="primary"
              rounded
              v-on="on"
              v-bind="attrs"
              small
              style="font-size:12px"
            >
              <v-icon small>mdi-plus</v-icon>
              <span class="ml-1">{{
                $t("printTemplate.createTemplates")
              }}</span>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item dense @click="edit('')">
              <v-icon size="19">mdi-printer</v-icon>
              <span class="ml-2" style="font-size: 14px;">{{
                $t("printTemplate.createSystemPrint")
              }}</span>
            </v-list-item>
            <v-list-item dense @click="showDrawer = true">
              <v-icon size="19">mdi-file-word-box</v-icon>
              <span class="ml-2" style="font-size: 14px;">{{
                $t("printTemplate.uploadWord")
              }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <div
        v-if="systemPrintTemplates.length + wordTemplates.length === 0"
        class="print-template-empty-msg fill-height"
      >
        <v-icon color="grey" size="108">mdi-printer</v-icon>
        {{ $t("printTemplate.noTemplate") }}
      </div>
      <div v-if="systemPrintTemplates.length > 0">
        <v-row>
          <v-col class="mr-4">
            <v-subheader class="d-inline label" style="height:25px">{{
              $t("printTemplate.templates")
            }}</v-subheader>
          </v-col>
        </v-row>
        <v-divider class="mx-4" />
        <v-row no-gutters class="mx-2 mt-3">
          <template-box
            v-for="template in systemPrintTemplates"
            :key="template._id"
            :template="template"
            class="ma-2"
            :type="template.type"
            @commandHandler="onCommandHandler"
          />
        </v-row>
      </div>
      <div v-if="wordTemplates.length > 0">
        <v-row>
          <v-col class="mr-4">
            <v-subheader class="d-inline label" style="height:25px">{{
              $t("printTemplate.wordTemplate")
            }}</v-subheader>
          </v-col>
        </v-row>
        <v-divider class="mx-4" />
        <v-row no-gutters class="mx-2 mt-3">
          <template-box
            v-for="template in wordTemplates"
            :key="template._id"
            :template="template"
            class="ma-2"
            :type="template.type"
            @commandHandler="onCommandHandler"
          />
        </v-row>
      </div>
    </v-card-text>
    <edit-template-dialog
      v-if="showSystemEditDialog"
      v-model="showSystemEditDialog"
      :templateId="selectedTemplateId"
      @update="updateTemplate"
      :isEdit="isEdit"
    />
    <preview-docx-dialog
      v-if="showPreviewDocxDialog"
      v-model="showPreviewDocxDialog"
      :info="templateInfo"
    />
    <UploadDocxDrawer
      v-if="showDrawer"
      v-model="showDrawer"
      @onCommand="onCommandHandler"
      :appId="appId"
      :formId="formId"
      :template="selectedWordTemplate"
    />
  </v-card>
</template>

<script>
import TemplateBox from "./TemplateBox";
import EditTemplateDialog from "./EditTemplateDialog";
import UploadDocxDrawer from "./UploadDocxDrawer";
import PreviewDocxDialog from "./PreviewDocxDialog";

export default {
  components: {
    TemplateBox,
    EditTemplateDialog,
    UploadDocxDrawer,
    PreviewDocxDialog,
  },
  data() {
    return {
      templates: [],
      selectedTemplateId: "",
      showSystemEditDialog: false,
      showPreviewDocxDialog: false,
      isEdit: false,
      showDrawer: false,
    };
  },
  watch: {
    showDrawer: function(val) {
      if (!val) this.selectedTemplateId = "";
    },
  },
  computed: {
    appId() {
      return this.$store.getters.currentApp._id;
    },
    formId() {
      return this.$store.getters.currentForm._id;
    },
    systemPrintTemplates() {
      return this.templates.filter((item) => item.type === 0);
    },
    wordTemplates() {
      return this.templates.filter((item) => item.type === 1);
    },
    selectedWordTemplate() {
      const result = this.wordTemplates.find(
        (item) => item._id === this.selectedTemplateId
      );
      return result || null;
    },
    templateInfo() {
      if (!this.selectedWordTemplate) return null;
      else {
        return {
          appId: this.appId,
          templateId: this.selectedWordTemplate._id,
          templateName: this.selectedWordTemplate.templateName,
          formId: this.$store.getters.currentForm._id,
          recordId: "",
          viewId: "",
        };
      }
    },
  },
  created() {
    this.fetchTemplates();
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "delete":
          this.deleteTemplate(payload.templateId);
          break;
        case "saveSetting":
          this.saveSetting(
            payload.template,
            payload.setting,
            payload.value,
            payload.cb
          );
          break;
        case "editSystemPrint":
          this.edit(payload.templateId);
          break;
        case "editTemplatePrint":
          this.selectedTemplateId = payload.templateId;
          this.showDrawer = true;
          break;
        case "previewTemplatePrint":
          this.selectedTemplateId = payload.templateId;
          this.showPreviewDocxDialog = true;
          break;
        case "previewSystemPrint":
          this.selectedTemplateId = payload.templateId;
          this.isEdit = false;
          this.showSystemEditDialog = true;
          break;
        case "goToInstruction":
          this.goToInstruction();
          break;
        case "updateDocxTemplate":
          this.updateDocxTemplate(payload.fileInfo, payload.templateId);
          break;
      }
    },
    saveSetting(template, setting, value, cb) {
      const postData = {
        urlCommand: "/printTemplate/saveSetting",
        data: {
          templateId: template._id,
          formId: this.$store.getters.currentForm._id,
          appId: this.$store.getters.currentForm.appId,
          setting,
          value,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        if (res.result) {
          template[setting] = value;
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
            func_name: this.$sensors.events.plus_sheet_mani.print_template.UPDATE_PRINT_TEMPLATE_SETTING,
            }
          });
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_table_setup_menu",
            data: {
              function_button: this.$sensors.events.plus_sheet_mani.print_template.UPDATE_PRINT_TEMPLATE_SETTING,
            }
          });          
        }


      }).finally(()=> {
        if (typeof cb === "function") {
          cb();
        }
      });
    },
    updateTemplate(newTemplate) {
      const index = this.templates.findIndex(
        (template) => template._id === newTemplate._id
      );
      if (index > -1) this.templates.splice(index, 1, newTemplate);
      else this.templates.push(newTemplate);

      this.showSystemEditDialog = false;
      this.selectedTemplateId = "";
    },
    fetchTemplates() {
      this.$store
        .dispatch("FETCH_PRINT_TEMPLATES", {formId:this.$store.getters.currentForm._id})
        .then((res) => {
          this.templates = res;
        });
    },
    deleteTemplate(templateId) {
      const postData = {
        urlCommand: "/printTemplate/delete",
        data: {
          appId: this.$store.getters.currentForm.appId,
          templateId,
        },
      };
      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        if (res.result) {
          const index = this.templates.findIndex(
            (template) => template._id === templateId
          );
          if (index > -1) {
            this.templates.splice(index, 1);
          }
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.print_template.DELETE_PRINT_TEMPLATE,
            }
          });
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_table_setup_menu",
            data: {
              function_button: this.$sensors.events.plus_sheet_mani.print_template.DELETE_PRINT_TEMPLATE,
            }
          }); 
        }
      });
    },
    edit(templateId) {
      this.selectedTemplateId = templateId || "";
      this.isEdit = true;
      this.showSystemEditDialog = true;
    },
    goToInstruction() {
      const { id: appId, itemId: formId } = this.$route.params;
      let routeData = this.$router.resolve({
        name: "printTemplateInstruction",
        params: { appId, formId },
      });
      window.open(routeData.href, "_blank");
    },
    updateDocxTemplate(fileInfo, templateId) {
      const data = {
        appId: this.appId,
        formId: this.$store.getters.currentForm._id,
        fileInfo: fileInfo,
      };
      if (templateId) {
        Object.assign(data, {
          templateId,
        });
      }
      const postData = {
        urlCommand: "/printTemplate/createWordTemplate",
        data,
      };

      this.$store
        .dispatch("AUTH_POST", postData)
        .then((res) => {
          if (templateId) {
            const index = this.templates.findIndex(
              (item) => item._id === templateId
            );
            this.templates.splice(index, 1, res);
          } else this.templates.push(res);

          this.showDrawer = false;
        })
        .catch((err) => {})
        .finally(() => {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.print_template[`${templateId?'UPDATE':'CREATE'}_PRINT_TEMPLATE_DOCX`]
            }
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  height: 20px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0;
  color: #333;
  font-size: 14px;
  font-weight: 700;
  .step-number {
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #707070;
    text-align: center;
    margin-right: 12px;
    font-size: 12px;
    font-weight: 400;
  }
}

.print-template-empty-msg {
  min-height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
