<template>
    <div class="d-flex align-center" style="height:100%">
        <div style="width:200px;" class="">
            <url-box
                class="px-1"
                v-if="!editing"
                :url="publicEdit.identifier"
                :canCopy="false"
                maxWidth="200px"
                noQRCode
                @dblclick.native="editing = true"
            >
              <template v-slot:append-inner>
                <v-icon small @click="editing = true">mdi-pencil-outline</v-icon>
              </template>
            </url-box>
            <v-text-field
                v-else
                hide-details
                height="36"
                solo
                autofocus
                v-model="publicEdit.identifier"
                style="maxWidth:200px;font-size: 13px"
                filled
                flat
                class="px-1"
                color="white"
                background-color="grey lighten-2"
                @blur="
                saveSetting('identifier', publicEdit.identifier);
                editing = false;
                "
            />
        </div>
        <div class="px-2">
            <v-tooltip top>
              <template v-slot:activator="{ attr, on }">
                <v-btn
                  icon
                  small
                  color="error"
                  @click="deleteForm"
                  v-on="on"
                  v-bind="attr"
                >
                  <v-icon small>
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </template>
              {{ $t("buttons.delete") }}
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ attr, on }">
                    <v-btn
                        icon
                        small
                        color="primary"
                        @click="openBuilder"
                        v-on="on"
                        v-bind="attr"
                    >
                        <v-icon small>
                        mdi-cog-outline
                        </v-icon>
                    </v-btn>
                </template>
                {{ $t("general.settings") }}
            </v-tooltip>
        </div>
        <PublicEditFieldSettingDialog
            v-model="showingFieldSettingDialog"
            v-if="showingFieldSettingDialog"
            :form="form"
            @saveFieldsSetting="saveFieldsSetting"
            ref="publicEditFieldSettingDialog"
        />
        <form-builder
            v-if="showFormBuilder"
            v-model="showFormBuilder"
            :publicForm="publicEdit"
            @change="saveForm"
            @commandHandler="onCommandHandler"
            :isPublicEdit="true"
        >
            <template #settings><div></div></template>
        </form-builder>
    </div>
</template>

<script>
    import urlBox from "./urlBox";
    import PublicEditFieldSettingDialog from "./publicEditFieldSettingDialog";
    import FormBuilder from "../formBuilder";
    export default {
        props:{
            publicEdit: Object
        },
        components: {
            urlBox,
            PublicEditFieldSettingDialog,
            FormBuilder
        },
        data(){
            return{
                editing: false,
                showingFieldSettingDialog: false,
                showFormBuilder: false
            }
        },
        methods:{
            saveFieldsSetting(payload){
                this.saveSetting('fields', payload)
            },
            saveSetting(setting, value) {
                this.$emit("commandHandler", {
                    command: "saveSetting",
                    publicForm: this.publicEdit,
                    setting,
                    value
                });
            },
            deleteForm() {
                this.$emit("commandHandler", {
                    command: "deleteTemplate",
                    publicEdit: this.publicEdit,
                });
            },
            openFieldSettingDialog(){
                this.showingFieldSettingDialog = true
                this.$nextTick(()=>{
                    const fieldSettingRef = this.$refs.publicEditFieldSettingDialog
                    const savedFields = this.publicEdit.fields
                    savedFields.forEach(savedField=>{
                        const field = fieldSettingRef.avaliableFieldInfos.find(fieldInfo=>
                            fieldInfo.fieldId==savedField.fieldId
                        )
                        if(field){
                            field.canEdit = savedField.canEdit
                            field.canRead = savedField.canRead
                        }
                    })
                })
            },
            saveForm() {
                this.$emit("commandHandler", {
                    command: "saveTemplate",
                    publicEdit: this.publicEdit,
                });
            },
            onCommandHandler(payload) {
                this.$emit("commandHandler", payload);
            },
            openBuilder(){
                this.showFormBuilder = true;
                this.onCommandHandler({
                    command:"openSettings"
                });
            }
        },
        computed:{
            form(){
                return this.$store.getters.currentForm
            }
        }
    }
</script>

<style>
    .publicEditLabelInput .v-input__slot{
        min-height: 25px !important;
    }
</style>