<template>
    <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :isHidden="isHidden"
      :required="required"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      class="mt-3 input-widget-color-picker"
      :tooltipText="description"
    >
        <div :class="{ 'workflow-customize-form-record-hide': hideInput }">
            <v-text-field
                v-if="isReadOnly"
                :value="inputValue"
                disabled
                dense
                hide-details
                class="disabled"
        ></v-text-field>
        <dialog-period-date
            v-if="typeof inputValue === 'object' && !Array.isArray(inputValue) && inputValue !== null"
            ref="date"
            :rules="rules"
            :isLayout="isLayout"
            :isReadOnly="isReadOnly"
            :isPeriod="true"
            :fieldInfo="fieldInfo"
            :allowedDays="allowedDays"
            :error="hasError"
            v-model="inputValue"
        >
        </dialog-period-date>
      </div>
    </input-widget-container>
</template>
<script>
import DialogPeriodDate from "../dialogFields/DialogPeriodDate";
import inputWidgetContainer from './comps/InputWidgetContainer'
import mixin from './mixin'
import formFieldMixin from '@/mixins/formFieldMixin'
import helperMixin from '@/mixins/helpersMixin'
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

export default {
    name: 'inputWidgetPeriod',
    mixins: [
        mixin,
        formFieldMixin,
        helperMixin
    ],
    components: {
        inputWidgetContainer,
        DialogPeriodDate,
    },
    mounted(){
        if(!(typeof this.inputValue === 'object' && !Array.isArray(this.inputValue) && this.inputValue !== null)){
            this.inputValue = {
                from: '',
                to: '',
                days: 0,
                milestone: false,
            }
        }
    },
    methods:{
        _getDefaultValue(){
            return {
                from: '',
                to: '',
                days: 0,
                milestone: false,
            }
        }
    }
}
</script>

<style>
</style>