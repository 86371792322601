const adminFormMixin = {

  methods: {
    getFieldInfo (infoId) {
      const vm = this
      var result = null
      result = vm.formFieldInfos.find(fieldInfo => fieldInfo._id === infoId)
      if (!result) {
        result = vm.formUIElementInfos.find(info => info._id === infoId)
      }
      if (!result) {
        result = vm.formChildTableInfos.find(info => info._id === infoId)
      }

      return result
    },

    getFieldProperty (fieldInfoId, propertyName) {
      const vm = this
      const fieldInfo = vm.getFieldInfo(fieldInfoId)
      return fieldInfo.properties[propertyName]
    }
  },
  computed: {
    form () {
      return this.$store.getters.currentForm
    },
    formUIElementInfos () {
      return this.$store.getters.formUIElementInfos
    },
    formFieldInfos () {
      return this.$store.getters.formFieldInfos
    },
    formChildTableInfos () {
      return this.$store.getters.formChildTableInfos
    },
    formLayoutConfig () {
      return this.$store.getters.formLayout
    }
  }
}

export default adminFormMixin
