<template>  
<v-menu   
  offset-overflow
  v-model="showingMenu"  
  min-width="180px"
  absolute
  :position-x="xPos"
  :position-y="yPos"
  >
  <v-card class="pa-0" style="z-index:9999;">
    <v-card-text class="select-calc-method-menu pa-0" style="background-color:red;">
      <v-list class="pa-0 calc-method-option-list" multiple>
        <v-list-item-group>
          <v-list-item
            class="py-0 px-8 calc-method-option"            
            @click.stop='onItemClicked(option)'
            v-for="(option) in calcOptions"
            :key="option.value"
            :class="{'primary white--text':isSelected(option)}">
            {{ option.label }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</v-menu>
</template>

<script>
export default {
  name: 'selectCalcMethodMenu',
  data () {
    return {
      selection: [],
      fieldId: '',
      fieldInfo: null,

      fav: true,
      menu: false,
      message: false,
      hints: true,

      attach: '',
      showingMenu: false,

      callback: null,
      onClosed: null,
      xPos: null,
      yPos: null
    }
  },
  watch: {
    showingMenu: function (newVal) {
      if (newVal === false) {
        this.onOk()
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  // props: {
  //   value: Boolean,
  //   attach: String
  // },
  computed: {
    // userShowingMenu: {
    //   get() {
    //     return this.value
    //   },
    //   set(val) {
    //     this.$emit('input', val)
    //   }
    // },
    allValidMethods () {
      return this.calcOptions.map(option => option.value)
    },
    calcOptions () {
      const vm = this
      var result = [
        {value: 'filled', label: vm.$t('view.filled')},
        {value: 'nonFilledIn', label: vm.$t('view.nonFilledIn')}
      ]
      if (vm.fieldInfo) {
        if (['number','amount','rating','summaryField', 'expression'].indexOf(vm.fieldInfo.type) >= 0) {
          result = result.concat([
            {value: 'sum', label: vm.$t('view.sum')},
            {value: 'average', label: vm.$t('view.average')},
            {value: 'max', label: vm.$t('view.max')},
            {value: 'min', label: vm.$t('view.min')},
          ])
        }
      }
      return result
    }
  },
  methods: {
    onClickOutside () {
      if (this.showingMenu) {
        console.log('onClickOutside')
        this.onOk()
      }
    },
    open (payload, callback, onClosed) {
      const vm =this 
      console.log('selectCalcMethodMenu : payload: ', payload)
      vm.fieldId = payload.fieldId
      vm.fieldInfo = payload.fieldInfo
      const targetId = payload.attach
      vm.attach = '#' + targetId

      vm.$nextTick(() => {
        const obj = document.getElementById(targetId)

        console.log('1 SelectCalcMethodMenu.open obj vm.attach = ' + vm.attach)
        console.log('1 SelectCalcMethodMenu.open obj targetId = ' + targetId)

        console.log('SelectCalcMethodMenu.open obj: ', obj)
        vm.selection = payload.methods.filter(method => vm.allValidMethods.includes(method))
        vm.callback = callback
        vm.onClosed = onClosed
        vm.xPos = payload.xPos
        vm.yPos = payload.yPos
        vm.$nextTick(() => {
          vm.showingMenu = true
        })
        
        // vm.$nextTick(() => {
        //   vm.showingMenu = true
        // })
      })
    },
    onItemClicked (option) {
      console.log('onItemClicked: option: ', option)
      const index = this.selection.indexOf(option.value)
      if (index >= 0) {
        this.selection.splice(index, 1)
      } else {
        this.selection.push(option.value)
      }
      console.log('onItemClicked: this.selection: ', this.selection)
    },
    isSelected (option) {
      return this.selection.indexOf(option.value) >= 0   
   },
    close () {
      this.showingMenu = false
    },
    onOk () {
      if (typeof this.callback === 'function') {
        this.callback({
          fieldId: this.fieldId,
          calcMethods: this.selection
        }, () => {
          this.close()
        })
      }
    },    
    onCancel () {
      this.close()
    }
  }

}
</script>

<style>
.select-calc-method-menu .calc-method-option {
  margin-bottom : 1px;
  background-color: rgba(200,200,200,.2);
}

 .select-calc-method-menu .calc-method-option-list {
   background-color: white;
 }

.calc-method-option.v-list-item:not(.v-list-item--disabled).primary {
  color: white !important;
}
</style>