<template>
  <div>
    <v-overlay :absolute="false" :value="showingDialog" :id="attach">
    </v-overlay>
    <v-dialog
      width="950"
      hide-overlay
      persistent
      v-model="showingDialog"
      :attach="cssSelect?cssSelect:attach?`#${attach}`:undefined"
    >
      <v-card>
        <DialogHeader
          @close="showingDialog = false"
          :label="$t('settings.departments')"
        />
        <v-card-text class="pa-0" style="height:610px;">
          <v-row no-gutters class="fill-height">
            <!-- departments -->
            <v-col cols="8">
              <v-card-text style="height:610px;">
                <loading-icon v-if="loading" />
                <div v-else class="d-flex flex-column fill-height">
                  <div class="flex-grow-0 pa-5 pt-2">
                    <v-text-field
                      solo
                      dense
                      outlined
                      v-model="search"
                      :placeholder="$t('messages.inputTextToSearch')"
                      hide-details
                      clearable
                      height="25"
                      style="font-size:13px; width:80%; border-radius: 0"
                      clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                  </div>     
                  <div class="flex-grow-1">
                    <div style="height:0;min-height:100%;overflow-y:auto;">
                      <v-treeview
                        open-all
                        :search="search"
                        class="department-tree"
                        dense
                        v-model="selections"
                        @input="onInput"
                        :items="items"
                        return-object
                        item-key="_id"
                        item-text="label"
                        selectable
                        selection-type="independent"
                      />
                    </div>
                  </div>             
                </div>
              </v-card-text>
            </v-col>
            <!-- selected employees -->
            <v-col cols="4" style="border-left:3px solid #d9d9d9">
              <v-card-text class="" style="overflow: auto; height:610px;">
                <div v-for="selection in selections" :key="selection._id">
                  <v-chip small class="mt-2 teal white--text">
                    <v-icon size="18" class="mr-1" dark>
                      mdi-account-multiple
                    </v-icon>
                    {{selection.label}}
                  </v-chip>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
        <DialogActions @onCommand="onCommand" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";
import LoadingIcon from "@/components/global/loadingIcon";

export default {
  props: {
    value: Boolean,
    isMultiple: {
      type: Boolean,
      default: false,
    },
    selectedDepts: Array,
    attach: {
      type: String,
      default: undefined,
    },
    cssSelect:{
      type: String,
      default: undefined
    }
  },
  watch: {
    selectedDepts: function(newVal) {
      this.updateSelection()
    }
  },
  components: {
    DialogHeader,
    DialogActions,
    LoadingIcon,
  },
  data() {
    return {
      items: [],
      selections: [],
      search: "",
      loading: false,
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    }
  },
  methods: {
    updateSelection () {
      if (this.selectedDepts) {
        this.selections = this.selectedDepts
      }
    },
    loadDeptOptions() {
      return new Promise((resolve, reject) => {
        const getParams = {
          urlCommand: `/departments/getAllDepartments`,
        };
        this.$store.dispatch("AUTH_GET", getParams).then((res) => {
          resolve(res);
        });
      });
    },
    onInput() {
      if (!this.isMultiple && this.selections.length > 1) {
        this.selections.splice(0, 1);
      }
    },
    onCommand(payload) {
      switch (payload.command) {
        case "cancel":
          this.showingDialog = false;
          break;
        case "ok":
          this.showingDialog = false;
          this.$emit(
            "confirm",
            this.selections.map((selection) => ({
              _id: selection._id,
              label: selection.label,
              teamId: selection.teamId
            }))
          );

          break;
      }
    },
  },
  async mounted() {
    this.loading = true;

    this.items = await this.loadDeptOptions();
    this.updateSelection()

    this.loading = false;
  },
};
</script>

<style>
.department-tree.v-treeview--dense .v-treeview-node__root{
  min-height: 33px !important;
}
.department-tree .v-treeview-node__root button{
  font-size: 18px !important
}
</style>
