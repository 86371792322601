<template>
  <div class="workflow-node-clone">
    <div v-if="!copying">
      {{ $t('workflow.label.pickAction') }}<span class="btn" @click="turnToCopy()">{{ $t('workflow.btn.copy') }}</span>{{ $t('workflow.label.existingNode') }}
    </div>
    <div v-else>
      {{ $t('workflow.label.copyToHere', { count: copiedSize }) }}
    </div>
  </div>
</template>

<script>
import {
  COPYING_NODE,
  SET_MODULE_MENU_STATUS,
} from '@/store/modules/workflow/action_types';

export default {
  methods: {
    turnToCopy() {
      this.$store.dispatch(COPYING_NODE, true);
      this.$store.dispatch(SET_MODULE_MENU_STATUS, false);
    }
  },
  computed: {
    copying() {
      return this.$store.getters.isCopyingNode;
    },
    copiedSize() {
      return this.$store.getters.copiedSize;
    }
  }
};
</script>

<style scoped>
</style>
