<template>
    <div v-if="publicEdits.length">
        <FormElement
            :label="label || $t('workflow.modules.retrieveRecord.label.generateShareableLink')"
            :hints="hints"
            name='worksheet'
        >
            <sharable-card
                v-for="sharableTemplate in publicEdits"
                :key="sharableTemplate._id"
                :sharableTemplate="sharableTemplate"
                class="mt-5"
                :isWorkflowSetting="true"
                @updateShareableSettings="updateShareableSettings"
                :enabledTemplates="setting.shareableTemplates"
            />
        </FormElement>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import ComboBox from './../../../form/comboBox/';
  import FormElement from './formElement';
  import Mixin from './../mixin';
  import {
    FORM_SELECTIONS,
    FORM_SELECTIONS_FIELDS,
    FETCH_WORKFLOW_DEPENDENCIES,
    SET_SETTINGS,
  } from '@/store/modules/workflow/action_types';
  
  import {
    map,
    omit,
    get,
  } from 'lodash';
  import sharableCard from '@/components/sharableRecord/sharableCard'
  export default {
    mixins: [Mixin],
    methods: {
    },
    components: {
      ComboBox,
      FormElement,
      sharableCard
    },
    data(){
        return{
            publicEdits: [],
        }
    },
    watch:{
        'setting.worksheet._id'(){
            this.fetchTemplates()
        },
        'setting.dataSource.form'(){
            this.fetchTemplates()
        }
    },
    created() {
        this.fetchTemplates()
    },
    methods:{
        async fetchTemplates(){
            if (this.setting.sourceType==="retrieveRecordFromDataSource") {
                const formId = (this.setting.worksheet && this.setting.worksheet._id) || 
                                (this.setting.dataSource && this.setting.dataSource.form)
                if(formId){
                    await this.$store.dispatch("FETCH_SHAREABLE_TEMPLATES", {
                        appId: this.appId,
                        formId
                    }).then((res) => {
                        this.publicEdits = res
                    })
                }
            }
        },
        updateShareableSettings(templateId){
            let shareableTemplates = this.setting.shareableTemplates||[]
            if(shareableTemplates.includes(templateId)){
                const deleteIndex = shareableTemplates.indexOf(templateId)
                shareableTemplates.splice(deleteIndex, 1);
            }else{
                shareableTemplates.push(templateId)
            }
        }
    }
  };
  
  </script>
  
  <style scoped>
    .w-control-remove-title {
      color: rgb(244, 67, 54);
      margin: 5px 0 30px 0;
      font-size: 11px;
    }
    .w-control-remove-btn-confirm {
      color: white;
    }
  </style>
  