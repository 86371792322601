<template>
  <WorkflowConsumer>
    <div class="workflow-config-menu mt-8">
      <v-layout 
        align-center
        v-for="item in heading" 
        :key="item.key" class="config-item" 
        :style="{
          color: color(item),
        }"
        :class="{ 
          'config-disabled': item.disabled,
        }">
        <div class="active-line"
          v-if="item.key === tab"
          :style="{ backgroundColor: color(item) }"
        />
        <v-icon
          v-text="item.icon" small
          :color="color(item)"
          class="config-icon"
        />
        <span v-text="item.label" />
      </v-layout>
    </div>
    <div class="workflow-config-content mt-12">
      <v-layout justify-space-between align-center class="workflow-config-title">
        {{ heading[tab].label }}
      </v-layout>
      <div class="workflow-config-container">
        <WorkflowParameter
          v-if="tab === 'parameter'"
        />
      </div>
    </div>
  </WorkflowConsumer>
</template>

<script>
import WorkflowConsumer from './../contexts/workflowConsumer';
import WorkflowParameter from './parameter';
import Mixin from './../../mixin';
// import {
//   REVIEW_ORCHESTRATION,
// } from '@/store/modules/orchestration/action_types';
export default {
  mixins: [Mixin],
  inject: ['info'],
  data() {

    return {
      tab: 'parameter',
      heading: {
        common: {
          key: 'common',
          label: this.$t('workflow.label.commonSetting'),
          icon: 'mdi-cog',
          disabled: true,
        },
        manual: {
          key: 'manual',
          label: this.$t('workflow.label.manualSetting'),
          icon: 'mdi-account-cog',
          disabled: true,
        },
        parameter: {
          key: 'parameter',
          label: this.$t('workflow.label.parameterSetting'),
          icon: 'mdi-tune',
          disabled: false,
        }
      },
    };
  },
  components: {
    WorkflowConsumer,
    WorkflowParameter,
  },
  mounted() {
    
  },
  methods: {
    color(item) {
      return item.key === this.tab ? 
        this.$vuetify.theme.themes.light.primary : 'black';
    }
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
  .workflow-config-content {
    width: 800px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    padding-bottom: 10px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
    margin: 20px auto;
    position: relative;
    .workflow-config-title {
      font-size: 19px;
      padding: 10px 20px;
      text-transform: uppercase;
      border-bottom: 1px solid #eaeaea;
    }
    .workflow-config-container {
      padding: 10px 20px;
    }
  }
  .workflow-config-menu {
    position: fixed;
    width: 200px;
    padding: 10px 0 10px 10px;
    left: 0;
    top: 57px;
    height: 100%;
    border-radius: 3px;
    background: #fff;
    font-size: 16px;
    user-select: none;
    .config-icon {
      margin-right: 10px;
    }
    .config-item {
      color: black;
      margin: 10px 0;
      padding: 5px 0;
      position: relative;
      .active-line {
        position: absolute;
        right: 0;
        top: 0;
        width: 3px;
        height: 100%;
      }
      // &.config-active {
      //   border-right: 3px solid #2196f3;
      //   cursor: pointer;
      // }
      &.config-disabled {
        cursor: not-allowed;
        color: #cdc8c8;
      }
    }
  }
  
</style>
