<template>
  <v-input v-if="readonly" disabled>
    <div class="pa-1 w-100 mb-0" style="border-bottom: 1px dashed darkgray">
      <div v-if="Array.isArray(inputValue)">
        <v-chip v-for="item in inputValue" class="mr-1" :key="item._id">
          <field-string
            height="auto"
            :textOnly="true"
            :fieldInfo="titleFieldInfo"
            :recordData="item"
          ></field-string>
        </v-chip>
      </div>
      <v-chip v-else class="mr-1">
        <field-string
          height="auto"
          :textOnly="true"
          :fieldInfo="titleFieldInfo"
          :recordData="inputValue"
        ></field-string>
      </v-chip>
    </div>
  </v-input>
  <div v-else>
    <v-autocomplete
    :menu-props="{contentClass:'select-from-data-source'}"
      :class="{
        'is-simple-mode': isSimpleSelectionMode,
        'is-card-mode': !isSimpleSelectionMode,
      }"
      outlined
      dense
      :multiple="false"
      :search-input.sync="search"
      v-model="inputValue"
      return-object
      :items="items"
      :item-text="displayFieldId"
      item-value="_id"
      :hide-no-data="loading"
      @focus="onFocus"
      chips
      deletable-chips
      :attach="'#' + id"
      :loading="loading"
      :error="hasError"
      :error-messages="errorMessage"
    >
      <template v-slot:selection="{ item }">
        <div
          v-if="!isSimpleSelectionMode"
          style="line-height: 1"
          class="w-100 py-1"
        >
          <v-hover v-slot="{ hover }">
            <record-card
              :card="item"
              :edit="false"
              :cardConfig="cardConfig"
              :titleFieldId="titleFieldId"
              :buttons="[]"
              :fieldInfos="fieldInfos"
            >
              <template v-slot:card-control>
                <v-btn
                  icon
                  color="primary"
                  v-if="hover"
                  style="align-self: end"
                  @click="clearSelection(item._id)"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
            </record-card>
          </v-hover>
        </div>

        <div v-else>
          <div
            v-if="titleFieldInfo && titleFieldInfo.type === 'members'"
            class="member-tag-wrapper"
          >
            <v-chip
              v-for="(employeeInfo, index) in item[titleFieldId]"
              :key="index"
            >
              {{ employeeInfo.employeeName }}
            </v-chip>
          </div>
          <div
            v-else-if="titleFieldInfo && titleFieldInfo.type === 'departments'"
            class="department-tag-wrapper"
          >
            <v-chip
              v-for="(departmentInfo, index) in item[titleFieldId]"
              :key="index"
            >
              {{ departmentInfo.label }}
            </v-chip>
          </div>
          <v-chip v-else class="mr-1" style="height: 28px; min-height: 28px">
            <!-- <v-chip v-else class="mr-1"> -->
            <field-string
              height="auto"
              :textOnly="true"
              :fieldInfo="titleFieldInfo"
              :recordData="item"
            ></field-string>
          </v-chip>
        </div>
        <v-icon v-if="hover">mdi-icon</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <div
          v-if="!isSimpleSelectionMode"
          style="line-height: 1"
          class="w-100 py-1"
        >
          <record-card
            :card="item"
            :edit="false"
            :cardConfig="cardConfig"
            :titleFieldId="titleFieldId"
            :buttons="[]"
            :fieldInfos="fieldInfos"
          ></record-card>
          <!-- @click="addSelection(item._id)" -->
        </div>
        <div v-else>
          <div
            v-if="titleFieldInfo && titleFieldInfo.type === 'members'"
            class="member-tag-wrapper"
          >
            <v-chip
              v-for="(employeeInfo, index) in item[titleFieldId]"
              :key="index"
            >
              {{ employeeInfo.employeeName }}
            </v-chip>
          </div>
          <div
            v-else-if="titleFieldInfo && titleFieldInfo.type === 'departments'"
            class="department-tag-wrapper"
          >
            <v-chip
              v-for="(departmentInfo, index) in item[titleFieldId]"
              :key="index"
            >
              {{ departmentInfo.label }}
            </v-chip>
          </div>
          <v-chip v-else class="mr-1" style="height: 28px; min-height: 28px">
            <field-string
              height="auto"
              :textOnly="true"
              :fieldInfo="titleFieldInfo"
              :recordData="item"
            ></field-string>
          </v-chip>
        </div>
      </template>
      <template v-slot:append-item>
        <div v-intersect="endIntersect" />
      </template>
      <template v-slot:append-outer>
        <v-btn
          v-if="allowCreation"
          x-small
          depressed
          fab
          color="primary"
          class="ml-1"
          @click="$emit('create')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import recordCard from "@/components/RecordCard";
import fieldString from "@/components/fieldStrings/Fs";

export default {
  name: "selectFromDataSource",
  components: {
    recordCard,
    fieldString,
  },

  props: {
    value: Object,
    selectionMode: {
      type: String,
      default: "simple",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    formInfo: null,
    fieldInfo: Object,
    dataSource: null,
    cardSelectionSettings: null,
    // dataSourceInfo: Object,
    readonly: Boolean,
    parentFormInfo: {
      type: Object,
      default: null,
    },
    recordId: {
      type: String,
      default: "",
    },
    isLayout: Boolean,
    id: String,
    errorMessage:{  
      type: String,
      default: ""
    },
    hasError:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      loading: false,
      searching: false,
      searchValue: "",
      pageIndex: 1,
      pageSize: 20,
      hasNextPage: true,
      timeoutInst: null,
    };
  },
  computed: {
    displayFieldId() {
      const vm = this;
      var result = vm.titleFieldId;
      if (vm.titleFieldInfo) {
        if (vm.titleFieldInfo.type === "members") {
          result = "memberName";
        } else if (vm.titleFieldInfo.type === "departments") {
          result = "departmentName";
        }
      }
      return result;
    },
    isSimpleSelectionMode() {
      return this.selectionMode === "simple";
    },

    cardConfig() {
      return this.cardSelectionSettings.cardConfigs;
    },
    titleFieldInfo() {
      const vm = this;
      var result = null;
      if (vm.fieldInfos.length > 0) {
        if (
          vm.formInfo &&
          vm.formInfo["titleFieldInfoId"] &&
          vm.formInfo["titleFieldInfoId"] !== ""
        ) {
          const fieldInfo = vm.fieldInfos.find(
            (info) => info._id === vm.formInfo["titleFieldInfoId"]
          );
          if (fieldInfo) {
            result = fieldInfo;
          }
        }
      }
      return result;
    },
    titleFieldId() {
      return this.titleFieldInfo ? this.titleFieldInfo.fieldId : "";
    },
    displayFieldIds() {
      const vm = this;
      var result = [];
      if (vm.titleFieldId !== "") {
        result.push(vm.titleFieldId);
      } else {
        if (vm.fieldInfos.length > 0) {
          result.push(vm.fieldInfos[0].fieldId);
        }
      }
      return result;
    },

    inputValue: {
      get() {
        const vm = this;
        var result = [];
        if (Array.isArray(vm.value)) {
          result = this.isMultiple ? vm.value : this.value[0] || null;
        }
        return result;
      },
      set(val) {
        const vm = this;
        if (Array.isArray(val)) {
          vm.$emit("input", val);
        } else if (val) {
          this.$emit("input", [val]);
        } else {
          this.$emit("input", []);
        }
      },
    },
    fieldInfos() {
      return (this.formInfo && this.formInfo.fieldInfos) || [];
    },
    sortingConfigs() {
      const vm = this;
      var result = [{ fieldId: vm.titleFieldId, order: "asc" }];
      if (
        !vm.isSimpleSelectionMode &&
        vm.cardSelectionSettings &&
        vm.cardSelectionSettings.sortingConfigs
      ) {
        result = vm.cardSelectionSettings.sortingConfigs;
      }
      return result;
    },
    filterConfigs() {
      const vm = this;
      var result = {
        filters: [],
        filterAndOr: "and",
      };
      if (vm.cardSelectionSettings && vm.cardSelectionSettings.filterConfigs) {
        result = vm.cardSelectionSettings.filterConfigs;
      }
      return result;
    },
    settings() {
      const vm = this;
      var result = [];
      if (vm.fieldInfo && vm.fieldInfo.properties.settingsMultiple !== "") {
        result = vm.fieldInfo.properties.settingsMultiple.split("||");
      }
      return result;
    },
    allowCreation() {
      return (
        !this.$store.getters.isPublicRoute &&
        this.settings.includes("allowCreation")
      );
    },
    search: {
      get() {
        return this.searchValue;
      },
      set(val, oldVal) {
        this.searching = true;
        this.searchValue = val;
        if (val !== oldVal && !this.isLayout) {
          this.loading = true;
          if (this.timeoutInst) clearTimeout(this.timeoutInst);
          this.timeoutInst = setTimeout(() => {
            this.items = [...this.value];
            this.pageIndex = 1;
            this.hasNextPage = true;
            this.searchSelections(true);
            this.searching = false;
          }, 1000);
        }
      },
    },
    fieldId() {
      return (this.fieldInfo && this.fieldInfo.fieldId) || "";
    },
  },
  watch: {
    value: function (newVal) {
      this.items = newVal;
    }
  },
  mounted() {
    if (!this.isLayout) this.items = [...this.value];
  },
  methods: {
    async searchSelections(replace) {
      clearTimeout(this.timeoutInst);
      if (this.hasNextPage) {
        this.loading = true;
        const requestParams = {
          formId: this.parentFormInfo._id,
          fieldId: this.fieldId,
          relationFormId: this.dataSource,
          recordId: this.recordId,
          displayFieldIds: this.fieldInfos.map((info) => info.fieldId),
          filterConfigs: this.filterConfigs,
          sortingConfigs: this.sortingConfigs,
          isPublic: this.$store.getters.isPublicRoute,
          page: this.pageIndex,
          size: this.pageSize,
        };
        if (this.value.length > 0)
          requestParams["exclusion"] = this.value.map((record) => record._id);
        if (this.search) requestParams["keyword"] = this.search;

        this.$store
          .dispatch("FETCH_SELECTION_DATA", requestParams)
          .then((res) => {
            this.items = replace
              ? [...this.value, ...res.result]
              : this.items.concat(res.result);
            if (res.result.length < this.pageSize) {
              this.hasNextPage = false;
            } else this.pageIndex++;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onFocus() {
      this.clear();
      this.searchSelections(true);
    },
    clear() {
      this.searchValue = "";
      this.items = [...this.value];
      this.pageIndex = 1;
      this.hasNextPage = true;
    },
    endIntersect(entries, observer, isIntersecting) {
      if (
        isIntersecting &&
        this.items.length >= this.pageSize &&
        !this.searching
      ) {
        this.searchSelections(false);
      }
    },
    clearSelection(itemId) {
      if (Array.isArray(this.inputValue)) {
        const index = this.value.findIndex((record) => record._id === itemId);
        if (index > -1) this.inputValue.splice(index, 1);
      } else this.inputValue = [];
    },
  },
};
</script>

<style lang="scss">
/* .member-tag-wrapper .v-chip,
.department-tag-wrapper .v-chip {
  height: 20px !important;
} */
.member-tag-wrapper,
.department-tag-wrapper {
  padding-bottom: 1px !important;
}

.select-from-data-source {
  .v-input__slot {
    background-color: white !important;
  }
  .v-input__append-outer {
    margin-top: 0 !important;
  }
}
.select-from-data-source.v-autocomplete__content {
  left: 5px !important;
  top: 62px !important;
}
</style>
