<template>
  <div
    class="workflow-node-item"
    :class="{
      'flow-error': isError(),
      'flow-editing': isEditingNode(),
      'flow-preview': isPreviewMode,
      'flow-running': isRunning(),
    }">
    <div v-if="copying && !isNode" class="workflow-node-box-block-copy" />
    <NodeValidationLog
      v-if="isError()"
      :checkingLog="validationError"
      :left="true"
      dark
      :bottom="false"
      :messageLabel="$t('workflow.message.validationBelow', { nodeName: node && node.name })"
    />
    <NodeCopyOverLay
      :node="node"
      v-if="!node.dependsNode"
    />
    <v-progress-circular
      v-if="isRunning()"
      indeterminate
      class="w-e-tree-node-running"
      color="primary"
    ></v-progress-circular>
    <div>
      <div class="workflow-node-item-name" v-if="!node.dependsNode" :class="{ 'workflow-node-item-branchName': !isNode }" :style="cardStyle && cardStyle()">
        <div v-if="!noIcon && !node.dependsNode" class="workflow-node-item-avatar">
          <v-icon class="workflow-node-item-avatar-icon">
            {{ node.icon }}
          </v-icon>
        </div>
        <div class="workflow-node-item-nameText">
          <EditableNodeName
            :isNode="isNode"
            :name="node.name"
            :editing="editing"
            @edited="onEdited"
            @edit="onEdit"
          />
        </div>
        <slot name="heading" :tree="tree" :node="node" :offset="offset" />
      </div>
      <NodeTools
        :node="node"
        :isNode="isNode"
        :onEdit="onEdit"
        @remove="removeNode"
        @copy="cloneNode"
      />
    </div>
    <div class="workflow-node-item-content" :style="contentStyle">
      <slot name="content" :node="node" :offset="offset" />
    </div>
  </div>
</template>

<script>
/*
* Single node footer with preview and code node mode
* Terry Chan
* 26/05/2021
*/
import { has, get } from 'lodash';
import EditableNodeName from './../../control/editableName/nodeName';
// import EditableBranchName from './../../control/editableName/branchName';
import NodeCopyOverLay from './copy';
import NodeTools from './tools';
import NodeValidationLog from './log';
// mixins
import EditingMixin from './../../control/editableName/editableMixin';

export default {
  mixins: [EditingMixin],
  props: {
    node: Object,
    offset: Number,
    tree: Object,
    walkingNode: {
      types: Object,
      default: {},
    },
    cardStyle: Function,
    isNode: {
      type: Boolean,
      default: true,
    },
    noIcon: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
    isShortMode() {
      return this.$store.getters.isShortMode;
    },
    contentStyle() {
      return {
        display: this.isShortMode ? 'none' : 'block',
      }
    },
    validationResult() {
      return this.$store.getters.validationNodes;
    },
    fromValidationError() {
      return !has(this.validationResult, this.node._id);
    },
    validationError() {
      return get(this.validationResult, `${this.node._id}.checkingLog`);
    },
    // hasErrorToBeRolledOut() {
    //   return this.errorLog.length > 0;
    // },
  },
  methods: {
    isError() {
      // const { ready } = this.node;
      return !this.isPreviewMode && !this.fromValidationError;  // undefined means it is not being to validate
    },
    removeNode() {
      this.$emit('remove', { node: this.node, offset: this.offset });
    },
    cloneNode() {
      this.$emit('clone', { node: this.node, offset: this.offset })
    },
    isEditingNode() {
      const editingNode = this.$store.getters.getEditingNode;
      return editingNode && editingNode.id === this.node.id;
    },
    isRunning() {
      if (this.isPreviewMode) {
        return this.walkingNode && this.walkingNode[this.node._id];
      }
    },
  },
  components: {
    NodeCopyOverLay,
    EditableNodeName,
    // EditableBranchName,
    NodeTools,
    NodeValidationLog,
  },
};

</script>

<style scoped>
  .w-e-tree-node-running {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -15px;
  }
</style>
