<template>
  <input-widget-container
      :isLayout="isLayout"
      :isHidden="isHidden"
      :widget="widget"
      :required="required"
      :fieldWidth="fieldWidth"
      :noIcon="noIcon"
      :hint="hint"
      :label="fieldName"
      :tooltipText="description">
    <div 
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      v-if="!isLayout && !editAnyway" 
      class="grey--text d-flex align-center justify-center rounded-pill py-2 grey lighten-3" 
      :style="value?'width:100%':'width:100px'"
      style="height:25px;"
    >
      <span>{{value}}</span>
    </div>
      <v-text-field
        v-else
        :class="{ 'workflow-customize-form-record-hide': hideInput }"
        :value="inputValue"
        disabled
        hide-details
        class="disabled workflow-customized-form-record-element"
        dense
      ></v-text-field>
      <!-- {{fieldName}} -->
      <!-- dasds -->
    <!-- <v-text-field v-if="rowType==='single'"
                  :value="value"
                  dense
                  :ref="fieldInfo.fieldId"
                  :rules="rules"
                  clearable
                  :hide-details="isLayout"
                  single-line
                  outlined
                  :placeholder="placeholder"
                  @input="val=>updateValue(val)"></v-text-field>
    <v-textarea
        v-else
        :value="value"
        dense
        :ref="fieldInfo.fieldId"
        :rules="rules"
        outlined
        :hide-details="isLayout"
        no-resize
        :rows="rows"
        :placeholder="placeholder"
        @input="val=>updateValue(val)"></v-textarea> -->
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'
export default {
  name: 'inputWidgetAutoNumbering',
  components:{
    inputWidgetContainer
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data () {
    return {
      userValue: ''
    }
  },
  mixins: [mixin]
}
</script>

<style scoped>
.is-layout {
  padding-bottom: 0 !important;
}

.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
</style>
