<template>
  <div>
    <FormElement
      :label="$t(`workflow.modules.${notificationType}.label.${total > 0 ? notificationCounterLabelType : notificationCounterLabelNormalType}`, {
        total,
      })"
      :hints="!noHints ? $t(`workflow.modules.${notificationType}.remark.${notificationHintsType}`) : limitHint"
      :name="name"
    >
      <div class="workflow-setting-form-each-item-input-widget">
        <FormElementWidget
          :recordData="pickerValue"
          :fieldInfo="memberField"
          specialType="members"
          ignoreRecordId
          :placeholder="$t(`workflow.modules.${notificationType}.placeholder.regularMember`)"
          enableBranchSelect
          supportJobTitleSelection
          multiple
          noIcon
          editAnyway
          widgetTypeMapField="type"
          @onCommand="onCommand"
        />
        <!-- <ComboBox
          :value="pickerValue[recipientField]"
          class="mx-2 mb-3"
          v-else
          :dependsOnType="withTypeSelect"
          :onlyPrefix="true"
          :onlySuffix="true"
          :isMultiple="true"
          :placeholder="$t('workflow.label.inputRecipientEmail')"
          valueFormat="object"
          disableBranchNode
          addable
          enableEnterEvent
          @changed="v => handleRecipientInput(v)"
        /> -->
        
      </div>
    </FormElement>
    <div>
      <div class="workflow-setting-form-each-item-input-widget-combox-middle" v-if="showMiddleComboBox">
        <ComboBox
          :value="emailValue"
          :options="specificTypeList"
          :dependsOnType="withTypeSelect"
          :onlyPrefix="true"
          :onlySuffix="true"
          :isMultiple="true"
          :placeholder="$t(`workflow.modules.${notificationType}.placeholder.workflowPhoneTarget`)"
          valueFormat="object"
          disableBranchNode
          @selected="v => onSystemListSelected(v)"
          @changed="v => changed(v, typeRecipientField)"
        />
      </div>
        <div class="workflow-setting-form-each-item-input-widget-combox"
        :class="{ 'input-widget-combox-noMiddle': !showMiddleComboBox }"
        >
        <ComboBox
          :value="otherValue"
          :options="list"
          dependsOnType="members"
          :onlyPrefix="true"
          :onlySuffix="true"
          :isMultiple="true"
          :placeholder="$t(`workflow.modules.${notificationType}.placeholder.workflowTarget`)"
          valueFormat="object"
          disableBranchNode
          @selected="v => onSystemListSelected(v)"
          @changed="v => changed(v, otherRecipientField)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import Mixin from '../mixin';
import EmailMixin from './mixin';
import FormElement from './../shared/formElement';
import OperationSettingMixin from './../operation/mixin';
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import FormElementWidget from "@/components/inputWidgets/InputWidget";
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module View Wrapper Components
* Terry Chan
* 28/05/2021
*/
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import NodeUtils from '@/pages/admin/workflow/utils/node';
import {
  FETCH_WORKFLOW_DEPENDENCIES,
  SET_SETTINGS,
  // FORM_SELECTIONS_FIELDS,
} from '@/store/modules/workflow/action_types'
export default {
  name: 'EmailNotificationRecipientSetting',
  mixins: [OperationSettingMixin, EmailMixin, Mixin],
  components: {
    FormElement,
    FormElementWidget,
    ComboBox,
  },
  mounted() {
    this.fetchFields();
  },
  props: {
    name: {
      type: String,
      default: 'target',
    },
    noHints: {
      type: Boolean,
      default: false,
    },
    typeRecipientField: {
      type: String,
      default: 'emailRecipient',
    },
    otherRecipientField: {
      type: String,
      default: 'otherRecipient',
    },
    recipientField: {
      type: String,
      default: 'recipient',
    },
    withTypeSelect: {
      type: String,
      default: 'email',
    },
    notificationType: {
      type: String,
      default: 'inBrowserNotification',
    },
    notificationHintsType: {
      type: String,
      default: 'informHints',
    },

    notificationCounterLabelNormalType: {
      type: String,
      default: 'informTargetNormal',
    },
    notificationCounterLabelType: {
      type: String,
      default: 'informTarget',
    },
    acceptances: {
      type: Array,
      default: [],
    },
    limitHint: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 0, // unlimited
    },
  },
  methods: {
    handleRecipientInput(val){
      this.onCommand({
        command: 'updateFieldValue',
        fieldName:this.memberField.fieldId,
        fieldValue: val
      })
    },
    limitValues(values, isPassFullList = false) {
      // not longer limit values return
      // if (
      //   this.limit > 0 && // check limit whether on
      //   (
      //     (isPassFullList && values.length > this.limit) || // full list pass case, member selection case
      //     (!isPassFullList && this.total > this.limit) // partial pass case, workflow selection case
      //   )
      // ) {
      //   let vancacy = this.limit;
      //   if (!isPassFullList){
      //     vancacy = Math.abs((this.limit + 1) - this.total);
      //   }
        
      //   return values.slice(0, vancacy);
      // }

      return values;
    },
    onLimitError(payload) {
      this.$emit('onLimitError', payload);
    },
    // select departments/team/job/title
    onCommand(payload) {
      console.log("Record.vue.onCommandHandler :: payload: ", payload);

      const otherRecipient = this.email[this.otherRecipientField] || [];
      const emailRecipient = this.email[this.typeRecipientField] || [];
      const otherEmailFieldsLength = otherRecipient.concat(emailRecipient).length;
      if (
        this.limit &&
        (
          (payload?.fieldValue?.length + otherEmailFieldsLength > this.limit) || // add values case
          (!payload?.fieldValue?.length && this.total > this.limit) // remove values case
        )
      ) {
        this.onLimitError({ limit: this.limit, values: payload?.fieldValue });
      }

      switch (payload.command) {
        case 'updateFieldValue':
          this.$set(this.email, payload.fieldName, this.limitValues(payload.fieldValue, true));
          break;
      }
    },
    // select member or email content
    changed(v, field) {
      const values = this.limitValues([v].flat());
      // console.log('onChanged values', values);
      
      const emailValue = this.email;
      this.$store.dispatch(SET_SETTINGS, {
        email: {
          ...emailValue,
          [field]: values,
        }
      });

      if (
        this.limit && this.total > this.limit
      ) {
        this.onLimitError({ limit: this.limit, values });
      }
    },
    // select member
    onSystemListSelected(value) {
      // console.log('onSystemListSelected', value, this.total)
      // total added passed in value
      if (this.limit && this.total + 1 > this.limit) {
        this.onLimitError({ limit: this.limit, values: [value] });
        const values = this[this.otherRecipientField] || [];
        // values.push(value);
        this[this.otherRecipientField] = this.limitValues(values);
      }
    },
    fetchFields() {
      // if (this.worksheet) {
        this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
          workflow: this.workflow._id,
          node: this.node._id,
          appId: this.appId,
        });
        // const { worksheet } = this;
        // const formId = worksheet._id || worksheet;
        // this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
        //   appId: this.appId,
        //   formId,
        // });
      // }
    },
  },
  computed: {
    pickerValue() {
      return this[this.recipientField];
    },
    otherValue() {
      return this[this.otherRecipientField] || null;
    },
    emailValue() {
      return this[this.typeRecipientField] || null;
    },
    allRecipients() {
      const recipient = this.email[this.recipientField] || [];
      const otherRecipient = this.email[this.otherRecipientField] || [];
      const emailRecipient = this.email[this.typeRecipientField] || [];

      return recipient.concat(otherRecipient, emailRecipient);
    },
    total() {
      return this.allRecipients.length;
    },
    list() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));

      return NodeUtils.withAcceptanceOptions({
        dependsOnType: 'members', 
        list: workflowOutputs,
      });
    },
    specificTypeList() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));
      return NodeUtils.withAcceptanceOptions({
        dependsOnType: this.withTypeSelect, 
        list: workflowOutputs,
      });
    },
    memberField() {
      return {
        fieldType: "members",
        type: "dataset",
        fieldId: this.recipientField,
        label: ' ',
        properties: {
          fixedFieldId: "createdBy",
          authorization: ""
        },
        canCreate: true,
        canEdit: true,
        canView: true
      }
    },
    sender(){
      return (this.email || {}).emailSender || "";
    },
    showMiddleComboBox(){
      return this.withTypeSelect && this.sender !== "default";
    }
  },
  watch: {
    worksheet: {
      handler(v, o) {
        const oldId = get(v, '_id', v);
        const newId = get(o, '_id', o);
        if (v && oldId !== newId) {
          this.fetchFields();
        }
      },
      deep: true,
    }
  },
};

</script>

<style scoped>

</style>
