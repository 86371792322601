<template>
<div class="view-settings-table-settings flex-grow-1 d-flex flex-column fill-height" style="height:500px">
  <v-row no-gutters class="px-3 py-1">
    <div class="mr-3 teal lighten-2" style="padding: 2px"/>
    <h4>{{ $t("view.fieldDisplay") }}</h4>
  </v-row>
  <div class="mt-3 view-settings-table-settings flex-grow-1 d-flex flex-column px-3 fill-height">
    <!--dragging: {{ dragging ? 'yes' : 'no' }}<br/>-->
    <!--allowDropToFixedList: {{ allowDropToFixedList ? 'yes' : 'no' }}<br/>-->
    <!--draggingFieldId: {{ draggingFieldId }}<br/>-->
    <!--draggingFieldInfo: {{ draggingFieldInfo }}<br/>-->
    <!--draggingFieldType: {{ draggingFieldType }}<br/>-->
    <div class="flex-grow-1 d-flex flex-row" style="position:relative;">
      <field-drag-list
        :title="$t('view.availableFields')"
        style="width:50%;"
        :idList="remainingFieldIds"
        buttonColor="cyan"
        buttonIcon="mdi-playlist-plus"
        @start="dragging=true"
        @end="dragging=false"
        @dragStart="onDragStart"
        @onButtonClicked="addAll"
        @onItemClicked="addField"
        :hiddenList="hiddenList"
      >
        <template v-slot="slotProps">
          {{ fieldIdLabelMap[slotProps.id] }}
        </template>
      </field-drag-list>

      <div style="width:50%;" class="d-flex flex-column ml-1">
        <div class="rounded grey darken-1 white--text px-3 py-1 d-flex flex-row align-center">
          <h4>{{ $t('view.tableDisplayedColumns')}}</h4>
          <v-spacer></v-spacer>
          <v-btn 
                x-small
                dark
                rounded fab
                color="cyan"
                class="elevation-0"
                style="height:27px; width:27px"
                @click="removeAll()">
            <v-icon>mdi-playlist-remove</v-icon>
          </v-btn>
        </div>
        <div class="flex-grow-1" style="position:relative;width:100%;">
           <div style="height:0;min-height:100%;overflow-y:auto;">
            <v-list dense class="d-flex flex-column flex-grow-1" style="min-height:100%;">
                <selected-table-display-fields
                 :hiddenList="hiddenList"
                 :title="$t('view.fixedFields')"
                 :selected="currentSection==='fixed'"
                 :fieldIdLabelMap="fieldIdLabelMap"
                 :summableFieldIds="summableFieldIds"
                 v-model="fixedFieldIds"
                 :columnSummary="true"
                 :isEmpty="fixedFieldIds.length===0&&nonFixedFieldIds.length===0"
                 :summationFieldIds="tableSummationFieldIds"
                 :countingFieldIds="tableCountingFieldIds"
                 @start="dragging=true"
                 @end="dragging=false"
                 @dragStart="onDragStart"
                 @click:title="currentSection='fixed'"
                 @click:titleButton="removeAllInSection('fixed')"
                 @click:toggleUseSum="fieldId=>toggleUseSum(fieldId)"
                 @click:toggleUseCount="fieldId=>toggleUseCount(fieldId)"
                 @click:item="fieldId=>removeItem(fieldId, 'fixed')">
               </selected-table-display-fields>

               <selected-table-display-fields
                 :hiddenList="hiddenList"
                 :title="$t('view.nonFixedFields')"
                 :selected="currentSection==='nonFixed'"
                 :fieldIdLabelMap="fieldIdLabelMap"
                 :summableFieldIds="summableFieldIds"
                 v-model="nonFixedFieldIds"
                 :columnSummary="true"
                 :isEmpty="fixedFieldIds.length===0&&nonFixedFieldIds.length===0"                 
                 :summationFieldIds="tableSummationFieldIds"
                 :countingFieldIds="tableCountingFieldIds"
                 @start="dragging=true"
                 @end="dragging=false"
                 @dragStart="onDragStart"
                 @click:title="currentSection='nonFixed'"
                 @click:titleButton="removeAllInSection('nonFixed')"
                 @click:toggleUseSum="fieldId=>toggleUseSum(fieldId)"
                 @click:toggleUseCount="fieldId=>toggleUseCount(fieldId)"
                 @click:item="fieldId=>removeItem(fieldId, 'nonFixed')"
                 :needPlaceholder="true"
                  :placeholder="$t('general.all')">
               </selected-table-display-fields>
                 <!-- @click:toggleActive="fieldId=>toggleActive(fieldId)" -->

               <div v-if="fixedFieldIds.length+nonFixedFieldIds.length===0"
                    class="text-center pt-3 w-100">
                 <v-avatar color="grey" size="48">
                   <span class="white--text text-h7">
                     {{ $t('general.all') }}
                   </span>
                </v-avatar>
               </div>
              </v-list>
           </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import mixin from './mixin'
import draggable from 'vuedraggable'
import fieldDragList from '@/components/dialogs/comps/FieldDragList'
// import fieldListHeader from './comps/FieldListHeader'
import selectedTableDisplayFields from './comps/SelectedTableDisplayFields'


export default {
  name: 'viewSettingsTableDisplayFields',
  mixins: [mixin],
  components: {
    draggable,
    fieldDragList,
    selectedTableDisplayFields
  },
  props: {
    view: null,
    tabIndex: Number
  },
  data () {
    return {
      allTableDisplayFieldIds: [],
      fixedFieldIds: [],
      nonFixedFieldIds: [],
      tableSummationFieldIds: [],
      tableCountingFieldIds: [],
      currentSection: 'nonFixed', // [fixed|scrollable]
      draggingFieldId: '',
      dragging: false
    }
  },
  computed: {
    hiddenList(){
      return this.fieldInfos.filter(info=>
        (info.properties.authorization|| "").split("||").includes("hidden")
      ).map(info=>info.fieldId)
    },
    remainingFieldIds () {
      const vm = this
      console.log('ViewSettingsTableDisplayFields :: remainingFieldIds :: vm.allTableDisplayFieldIds: ', vm.allTableDisplayFieldIds)
      console.log('ViewSettingsTableDisplayFields :: remainingFieldIds :: fixedFieldIds: ', vm.fixedFieldIds)
      console.log('ViewSettingsTableDisplayFields :: remainingFieldIds :: nonFixedFieldIds: ', vm.nonFixedFieldIds)
      return vm.allTableDisplayFieldIds.filter(fieldId => (
        vm.fixedFieldIds.indexOf(fieldId) === -1 &&
        vm.nonFixedFieldIds.indexOf(fieldId) === -1
      ))
    },
    fieldInfos () {
      return this.$store.getters.currentForm.fieldInfos
    },
    fieldIds () {
      return this.fieldInfos.map(info => info.fieldId).join('\n')
    },
    draggingFieldType () {
      const vm = this
      var result = ''
      if (vm.draggingFieldInfo && vm.dragging) {
        result = vm.draggingFieldInfo.type
      }
      return result
    },
    draggingFieldInfo () {
      const vm = this
      var result = null
      if (vm.fieldInfos) {
        result = vm.fieldInfos.find(info => info.fieldId===vm.draggingFieldId)
      }
      return result
    },
    summableFieldIds () {
      const vm = this
      const NUMERIC_TYPES = ['number', 'amount', 'expression']
      return vm.fieldInfos.filter(info => NUMERIC_TYPES.includes(info.type)).map(info => info.fieldId)
    },
    fieldIdLabelMap () {
      const vm = this
      var result = {}
      for (var i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i]
        result[loopFieldInfo.fieldId] = Object.keys(loopFieldInfo.properties).indexOf('fieldName') >= 0 ?
          loopFieldInfo.properties['fieldName'] :
          '(' + vm.$t('general.unspecified') + ')'
        if (Object.keys(loopFieldInfo.properties).indexOf('fieldNameTag') >= 0) {
          result[loopFieldInfo.fieldId] = vm.$t(loopFieldInfo.properties['fieldNameTag'])
        }
      }
      return result
    }
  },
  watch: {
    view: {
      handler: function (newVal, oldVal) {
        const vm = this
        vm.updateView()
      },
      deep: true
    }
  },
  mounted () {
    const vm = this
    vm.loadAllFieldInfos()
    vm.updateView()
  },
  methods: {
    onDragStart (draggingFieldId) {
      console.log('ViewSettingsTableDisplayFields :: onDragStart :: draggingFieldId = ' + draggingFieldId)
      this.draggingFieldId = draggingFieldId
    },
    toggleUseSum (fieldId) {
      const vm = this
      const index = vm.tableSummationFieldIds.findIndex(id => id === fieldId)
      if (index >= 0) {
        vm.tableSummationFieldIds.splice(index, 1)
      } else {
        vm.tableSummationFieldIds.push(fieldId)
      }
    },

    toggleUseCount (fieldId) {
      const vm = this
      const index = vm.tableCountingFieldIds.findIndex(id => id === fieldId)
      if (index >= 0) {
        vm.tableCountingFieldIds.splice(index, 1)
      } else {
        vm.tableCountingFieldIds.push(fieldId)
      }
    },
    // toggleActive (fieldId) {
    //   const vm = this
    //   console.log('toggleActive :: fieldId = ' + fieldId)
    //   const index = vm.tableSummationFieldIds.findIndex(id => id === fieldId)
    //   if (index >= 0) {
    //     console.log('toggleActive :; index >=0')
    //     vm.tableSummationFieldIds.splice(index, 1)
    //   } else {
    //     console.log('toggleActive :; index === -1')
    //     vm.tableSummationFieldIds.push(fieldId)
    //   }
    // },
    addAll() {
      const vm = this
      var i
      var ids = JSON.parse(JSON.stringify(vm.remainingFieldIds))
      switch (vm.currentSection) {
        case 'fixed':
          for (i = 0; i < ids.length; i++) {
            vm.fixedFieldIds.push(ids[i])
          }
          break
        case 'nonFixed':
          for (i = 0; i < ids.length; i++) {
            vm.nonFixedFieldIds.push(ids[i])
          }
          break
      }
      vm.updateTableSettings()
    },
    removeItem (fieldId, section) {
      const vm = this
      var idx
      console.log('removeItem :: fieldId = ' + fieldId)
      console.log('removeItem :: section = ' + section)
      switch (section) {
        case 'fixed':
          idx = vm.fixedFieldIds.findIndex(id => id===fieldId)
          if (idx >= 0) {
            vm.fixedFieldIds.splice(idx, 1);
          }
          break
        case 'nonFixed':
          idx = vm.nonFixedFieldIds.findIndex(id => id===fieldId)
          if (idx >= 0) {
            vm.nonFixedFieldIds.splice(idx, 1);
          }
          break
      }

      // remove fieldId from tableSummationFieldIds and tableCountingFieldIds
      vm.tableSummationFieldIds = vm.tableSummationFieldIds.filter(id => id !== fieldId)
      vm.tableCountingFieldIds = vm.tableCountingFieldIds.filter(id => id !== fieldId)

      vm.updateTableSettings()
    },
    removeAll() {
      const vm = this
      vm.fixedFieldIds = []
      vm.nonFixedFieldIds = []
      vm.tableSummationFieldIds = []
      vm.tableCountingFieldIds = []
      vm.updateTableSettings()
    },
    removeAllInSection(section) {
      const vm = this
      switch (section) {
        case 'fixed':
          vm.fixedFieldIds = []
          break
        case 'nonFixed':
          vm.nonFixedFieldIds = []
          break
      }
      vm.updateTableSettings()
    },
    addField (fieldId) {
      const vm = this
      if (vm.currentSection === 'fixed') {
        vm.fixedFieldIds.push(fieldId)
      } else if (vm.currentSection === 'nonFixed') {
        vm.nonFixedFieldIds.push(fieldId)
      }
      vm.updateTableSettings()
      // vm.$emit('onCommand', {
      //   command: 'updateTableSettings',
      //   fixedFieldIds: vm.fixedFieldIds,
      //   nonFixedFieldIds: vm.nonFixedFieldIds
      // })
    },

    updateView () {
      const vm = this
      if (vm.view) {
        vm.fixedFieldIds = vm.view.fixedFieldIds
        vm.nonFixedFieldIds = vm.view.nonFixedFieldIds
        vm.tableSummationFieldIds = vm.view.tableSummationFieldIds ? vm.view.tableSummationFieldIds : []
        vm.tableCountingFieldIds = vm.view.tableCountingFieldIds ? vm.view.tableCountingFieldIds : []
        console.log('updateView :: vm.view: ', vm.view)
      }
    },
    loadAllFieldInfos () {
      const vm = this
      vm.allTableDisplayFieldIds = vm.fieldInfos.map(fieldInfo => fieldInfo.fieldId)
    },
    updateTableSettings () {
      const vm = this
      console.log('updateTableSettings :: fixedfieldIds: ', vm.fixedFieldIds)
      console.log('updateTableSettings :: nonFixedFieldIds: ', vm.nonFixedFieldIds)
      vm.$emit('onCommand', {
        command: 'updateTableSettings',
        fixedFieldIds: vm.fixedFieldIds,
        nonFixedFieldIds: vm.nonFixedFieldIds,
        tableSummationFieldIds: vm.tableSummationFieldIds,
        tableCountingFieldIds: vm.tableCountingFieldIds
      })
    },
    // onDropped (items, pathTo) {
    //   const vm = this
    //   vm.updateTableSettings(vm.fixedFieldIds, vm.nonFixedFieldIds)
    //   // vm.$emit('onCommand', {
    //   //   command: 'updateTableSettings',
    //   //   fixedFieldIds: vm.fixedFieldIds,
    //   //   nonFixedFieldIds: vm.nonFixedFieldIds
    //   // })
    // },
    log (evt) {
      alert('log')
      window.console.log(evt);
    }
  }
}
</script>


<style>
  /*.view-settings-table-settings .field-list {*/
  /*min-height: 200px;*/
  /*}*/
  .field-list .v-list-item:nth-child(even) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>
