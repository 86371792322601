<template>
  <div>
    <label :class="labelClass">{{ label }}</label>
    <v-text-field
        class="flex-grow-0"
        placeholder="Title"
        outlined
        dense
        height ="30"
        style="font-size:14px"
        :value="value"
        @input="val=>$emit('input',val)"></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'yoovTextField',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    labelClass: String,
    valueClass: String,
    label: String,
    value: String
  }
}
</script>
