<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :required="required"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :noIcon="noIcon"
    :tooltipText="description"
    :isHidden="isHidden"
  >
    <!-- hideInput only involves in workflow by default false, no infect in inputWidget side -->
    <div :class="{ 'workflow-customize-form-record-hide': hideInput }">
      <v-input v-if="isReadOnly" disabled>
        <div
          class="pa-1 w-100 mb-0 "
          style="border-bottom: 1px dashed darkgray;"
        >
          <optionButton
            v-for="(val, index) in inputValue"
            :option="getOption(val)"
            :useColor="useColor"
            :key="index"
          />
        </div>
      </v-input>
      <input-widget-toggle
        v-else-if="
          selectionFieldType === 'flatMenu' && selectOptions.length > 0
        "
        toggleType="checkbox"
        :ref="fieldInfo.fieldId"
        :rules="rules"
        :direction="fieldInfo.properties.direction"
        :options="selectOptions"
        :useColor="useColor"
        v-model="inputValue"
        :readonly="isReadOnly"
      ></input-widget-toggle>
      <pulldown-chip-selection
        v-else
        :isLayout="isLayout"
        :items="selectOptions"
        :fullListOptions="inputOptionsValue.options"
        item-text="label"
        item-value="_id"
        :useColor="useColor"
        :multiple="isMultiple"
        @onDelete="onDeleteHandler"
        v-model="inputValue"
        :fieldId="fieldId"
        :readonly="isReadOnly"
        :allowAdd="allowAdd"
      ></pulldown-chip-selection>
      <div v-if="errMessage" class="selection-message-line error--text" style="font-size:12px">
        {{ errMessage }}
      </div>
      <div v-else class="selection-message-line caption">{{ hints }}</div>
    </div>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import selectionMixin from "./comps/selectionMixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import inputWidgetToggle from "./comps/InputWidgetToggle";
import pulldownChipSelection from "./comps/PulldownChipSelection";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import optionButton from "@/components/vxeTableCells/comps/OptionButton";


export default {
  name: "inputWidgetMultipleSelection",
  mixins: [mixin, selectionMixin],
  components: {
    inputWidgetToggle,
    inputWidgetContainer,
    pulldownChipSelection,
    optionButton,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data() {
    return {
      //errMessage: '',
      RULE_REQUIRED: (v) =>
        v.length > 0 || this.$t("messages.thisFieldIsRequired"),
      RULE_OPTION_COUNT_LIMIT: (v) =>
        Array.isArray(v) &&
        this.isInRange(v.length, this.atLeast, this.atMost, {
          between: "messages.mustBeWithinCount",
          atLeast: "messages.mustSelectAtLeast",
          atMost: "messages.mustSelectAtMost",
        }),
      errMessage: ''
    };
  },
  computed: {
    useColor() {
      return this.inputOptionsValue.useColor;
    },
    inputOptionsValue() {
      return getFieldPropertyValue(
        this.fieldInfo,
        "inputOptionsMultiple",
        null
      );
    },
    atLeast() {
      return getFieldPropertyValue(this.fieldInfo, "atLeast", null);
    },
    atMost() {
      return getFieldPropertyValue(this.fieldInfo, "atMost", null);
    },
    hints() {
      const vm = this;
      if (!this.isCheckRange) return "";

      if (vm.atLeast && vm.atMost) {
        return this.$t("messages.mustBeWithinCount", {min:this.atLeast, max:this.atMost})
      } else if (this.atLeast)
        return this.$t("messages.mustSelectAtLeast", {min:this.atLeast})
        else if (this.atMost){
          return this.$t("messages.mustSelectAtMost", { max:this.atMost})
        } else return ""
    },
    isCheckRange(){
      return this.validations.includes('optionCountLimited')
    },
    selectionFieldType() {
      return getFieldPropertyValue(this.fieldInfo, "fieldType", "flatMenu"); // or 'pulldownMenu'
    },
    // errMessage() {
    //   let msg = "";

    //   for (let i = 0; i < this.rules.length && msg === ""; i++) {
    //     const result = this.rules[i](this.value);
    //     msg = typeof result === "string" ? result : "";
    //   }
    //   return msg;
    // }
  },
  watch:{
    inputValue: function(newVal) {
      this.validate()
    }
  },
  methods: {
    validate() {
      if (this.isReadOnly) return true;
      this.errMessage = ''
      var valid = !this.rules.some((rule) => rule(this.value) !== true);
      if(!valid){
        let msg = "";
        for (let i = 0; i < this.rules.length && msg === ""; i++) {
          const result = this.rules[i](this.value);
          msg = typeof result === "string" ? result : "";
        }
        this.errMessage = msg;
      }
      this.hasError = !valid
      return valid
    },
    onDeleteHandler(id) {
      const vm = this;
      const index = this.inputValue.findIndex((selection) => selection === id);
      if (index > -1) this.inputValue.splice(index, 1);
    },
    _parseValue(val) {
      const vm = this;
      var result = [];
      if (val && Array.isArray(val)) {
        result = val;
      }
      return result;
    },
    getOption(val){
      if(typeof val ==='object') return val
      else {
        const {options} = this.inputOptionsValue
        const option = options.find(opt => opt._id === val)
        return option
      }
    }
  },
};
</script>
