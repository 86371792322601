<template>
  <div class="workflow-node-clone-control" :style="rootStyle()">
    <div v-if="resultCount > 0" class="workflow-node-clone-control-result">{{ $t('workflow.label.copiedNode', { count: resultCount }) }}</div> 
    <v-layout v-else justify-space-between align-center>
      <div>
        <span v-if="copiedSize > 0">{{ $t('workflow.label.copiedNode', { count: copiedSize }) }}</span>
        <span v-else>{{ $t('workflow.label.copyTo') }}</span>
      </div>
      <v-layout justify-end align-center>
        <v-btn elevation="0" text @click="closeCopy" class="w-node-clone-cancel">
          {{ $t('workflow.btn.cancel') }}
        </v-btn>
        <v-btn elevation="0" @click="submit" :disabled="copiedSize <= 0" class="w-node-clone-submit">
          {{ $t('workflow.btn.copy') }}
        </v-btn>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import {
  COPYING_NODE,
  PICKING_MODULE,
  COPY_WORKFLOW_NODE,
} from '@/store/modules/workflow/action_types';
import { map, find } from 'lodash';
import Tree from './../../../../libs/tree';
import NodeUtils from './../../../../utils/node';

export default {
  data() {
    return {
      resultCount: 0,
      pendingNode: null,
    }
  },
  methods: {
    submit() {
      const {
        copiedNode,
        pickingModule,
      } = this.$store.state.workflowModules;
      // console.log(pickingModule);
      let { tree, node } = pickingModule;
      // if it is under the branch 
      if (Tree.isBranch(node.nodeType)) {
        tree = node.children;
      }
      this.pendingNode = { tree, copiedNode, offset: pickingModule.offset };
      let nodes = [];
      copiedNode.forEach(node => {
        nodes = [
          ...nodes,
          NodeUtils.normalizeCopiedNodeToBeSaved(node, this.$t('workflow')),
        ];
      });
      // console.log(formateds);
      this.$store.dispatch(COPY_WORKFLOW_NODE, {
        nodes,  // normalization
        parent: node._id,
        workflow: this.workflowInfo._id,
        workflowInfo: this.workflowInfo,
      });
      // TODO: async create from server
      // copiedNode.forEach((node) => {
      //   Tree.copy(tree, node, pickingModule.offset + count);
      //   count++;
      // });
      // // show the scueed message
      // this.resultCount = this.copiedSize;
      // this.$store.dispatch(PICKING_MODULE, null);
      // // console.log(this.resultCount);
      // // this.closeCopy();
      // setTimeout(() => {
      //   this.resultCount = 0;
      //   this.$store.dispatch(COPYING_NODE, false);
      // }, 2000);
    },
    closeCopy() {
      this.$store.dispatch(COPYING_NODE, false);
      this.$store.dispatch(PICKING_MODULE, null);
    },
    rootStyle() {
      if (this.copying) {
        return {
          transition: 'top 0.7s ease 0s',
          top: '80px',
          background: this.resultCount < 1 ? '#2196f3' : 'rgb(1, 202, 131)',
        }
      }
      return {
        top: 0,
      }
    }
  },
  computed: {
    workflowInfo() {
      return this.$store.getters.getWorkflowInfo;
    },
    copying() {
      return this.$store.getters.isCopyingNode;
    },
    copiedSize() {
      return this.$store.getters.copiedSize;
    },
    nodesToBeCopied() {
      return this.$store.getters.getCopiedNodes;
    },
  },
  watch: {
    nodesToBeCopied: {
      handler(nodes) {
        console.log(nodes);
        if (nodes && nodes.length > 0 && this.pendingNode) {
          const { copiedNode, tree, offset } = this.pendingNode;
          let response;
          let count = 0;
          let newNode;
          copiedNode.forEach(node => {
            newNode = { ...node };
            response = find(nodes, ({ cloneFrom }) => cloneFrom === newNode._id);
            // console.log(response, nodes, node);
            if (response) {
              NodeUtils.mergeCopiedNodeToBeSaved(response, newNode);
              Tree.copy(tree, newNode, offset + count);
              count++;
            }
          });
          // show the scueed message
          this.resultCount = this.copiedSize;
          this.$store.dispatch(PICKING_MODULE, null);
          this.pendingNode = null;
          setTimeout(() => {
            this.resultCount = 0;
            this.$store.dispatch(COPYING_NODE, false);
          }, 2000);          
        }
      },
      deep: true,
    },
  },
  
};

</script>

<style scoped>
  .w-node-clone-cancel {
    color: white;
  }
  .w-node-clone-submit {
    cursor: pointer;
    height: 28px;
    line-height: 28px;
    padding: 0px 18px;
    border-radius: 28px;
    color: rgb(62, 164, 252);
    background-color: rgb(255, 255, 255);
    font-weight: 600;
  }
  .w-node-clone-cancel {
    border-radius: 28px;
  }
</style>
