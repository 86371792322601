<template>
  <div class="workflow-setting-view-condition-info-line">
    <b>{{fieldName(setting, $t('widgets'))}}</b>
    <span> {{ $t(`filters.${setting.content.rule}`, '=') }} </span>
    <b>{{value}}</b>
  </div>
</template>

<script>
import Mixin from './../mixin';
import { get, includes, isArray } from 'lodash';

export default {
  mixins: [Mixin], 
  props: {
    setting: Object,
  },
  computed: {
    value() {
      const { content={} } = this.setting;
      const rule = get(content, 'rule', 'isTheItem');
      if (includes(['isTheItem', 'notTheItem'], rule)) {
        const { value } = content;
        if (typeof value === 'object' && !isArray(value)) {
          return value.label;
        }
        return `${get(content, 'value.length', 0)} ${this.$t('displayRule.options')}`;
      }
      return '';
    }
  },
};

</script>

<style scoped>

</style>
