<template>
  <div>
    <label>{{ $t('levelDiagram.thisTableRelationField') }}</label>
    <v-select
        v-if="items.length>0"
        dense
        outlined
        solo
        :menu-props="{offsetY: true, contentClass: 'denseList'}"
        v-model="fieldIdPairStr"
        :items="items">
    </v-select>
    <v-text-field
        v-else
      readonly
      dense
      outlined
      solo
      :placeholder="$t('levelDiagram.noValidFieldAndCreateOne')"></v-text-field>
  </div>
</template>

<script>
import {LEVEL_DIAGRAM_CARD_CONFIG} from '@/const/defaults'
import {getFirstAttachmentsFieldId} from '@/helpers/FormHelpers'

export default {
  name: 'levelSelectRelationField',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // no levelType need, it must be "currentTable"
    // levelType: {
    //   type: String,
    //   default: 'currentTable'
    // },
    value: Object
    // value = {
    //    parent: {fieldId},
    //    child: {fieldId}
    // }
  },
  mounted () {
    const vm = this
    if (!vm.value) {
      if (vm.items.length > 0) {
        vm.value = vm.items[0].value
      }
    }
  },
  computed: {
    fieldIdPairStr: {
      get () {
        const vm = this
        console.log('computed(fieldIdPairStar) vm.value: ', vm.value)
        var result = ''

        if (vm.value) {
          result = vm.value.parentFieldId + ',' + vm.value.childFieldId
        }
        if (result !== '') {
          const selectedItem = vm.items.find(item => item.value === result)
          if (!selectedItem) {
            result = ''
          }
        } else {
          if (vm.items.length > 0) {
            result = vm.items[0].value
          }
        }
        console.log('computed(fieldIdPairStar) result: ', result)
        return result
      },
      set (val) {
        this.setInput(val)
      }
    },

    items () {
      const vm = this
      var result = []

      const linkedFields = vm.form.linkedFields
      for (let i = 0; i < linkedFields.length; i++) {
        const loopLinkedFieldPair = linkedFields[i].fieldIds
        const fieldInfo1 = vm.fieldInfos.find(info => info._id === loopLinkedFieldPair[0])
        const fieldInfo2 = vm.fieldInfos.find(info => info._id === loopLinkedFieldPair[1])

        if (fieldInfo1 && fieldInfo2) {
          if (vm.isSingle(fieldInfo1) && vm.isMultiple(fieldInfo2)) {
            result.push({
              text: fieldInfo1.label + '-' + fieldInfo2.label,
              value: fieldInfo1.fieldId + ',' + fieldInfo2.fieldId
            })
          } else if (vm.isSingle(fieldInfo2) && vm.isMultiple(fieldInfo1)) {
            result.push({
              text: fieldInfo2.label + '-' + fieldInfo1.label,
              value: fieldInfo2.fieldId + ',' + fieldInfo1.fieldId
            })
          }
        }
      }
      if (result.length > 0) {
        vm.setInput(result[0].value)
      }
      return result
    },

    items0 () {
      const vm = this
      var validParentFields = []
      var validChildFields = []

      console.log('computed(items): vm.fieldInfos.length = ' + vm.fieldInfos.length)
      console.log('current form id = ' + vm.form._id)
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopInfo = vm.fieldInfos[i]
        console.log('loopInfo.type = ' + loopInfo.type + ': ', loopInfo)
        if (loopInfo.type === 'relatedRecord') {
          console.log('loopInfo.properties.dataSource = ' + loopInfo.properties.dataSource)          
          if (loopInfo.properties.dataSource === vm.form._id) {
            switch (loopInfo.properties.relatedRecordQuantity) {
              case 'single':
                validParentFields.push({
                  fieldId: loopInfo.fieldId,
                  label: loopInfo.label
                })
                break
              case 'multiple':
                validChildFields.push({
                  fieldId: loopInfo.fieldId,
                  label: loopInfo.label
                })
                break
            }
          }
        }
      }
console.log('computed(items): validParentFields.length = ' + validParentFields.length)
console.log('computed(items): validChildFields.length = ' + validChildFields.length)

      var result = []
      for (let j = 0; j < validParentFields.length; j++) {
        const loopParent = validParentFields[j]
        for (let k = 0; k < validChildFields.length; k++) {
          const loopChild = validChildFields[k]
          result.push({
            text: loopParent.label + '-' + loopChild.label,
            value: loopParent.fieldId + ',' + loopChild.fieldId
          })
        }
      }
      if (result.length > 0) {
        vm.setInput(result[0].value)
      }
      return result
    },
    items2 () {
      const vm = this
      var validParentFields = []
      var validChildFields = []


      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopInfo = vm.fieldInfos[i]
        if (loopInfo.properties.dataSource) {
          // console.log('i=' + i + ': loopInfo.properties.dataSource: ', loopInfo.properties.dataSource)
          if (loopInfo.properties.dataSource === vm.form._id) {
            switch (loopInfo.type) {
              case 'relatedRecord':
                validParentFields.push({
                  fieldId: loopInfo.fieldId,
                  label: loopInfo.label
                })
                break
              case 'relatedMultipleRecords':
                validChildFields.push({
                  fieldId: loopInfo.fieldId,
                  label: loopInfo.label
                })
                break
            }
          }
        }
      }
      var result = []
      for (let j = 0; j < validParentFields.length; j++) {
        const loopParent = validParentFields[j]
        for (let k = 0; k < validChildFields.length; k++) {
          const loopChild = validChildFields[k]
          result.push({
            text: loopParent.label + '-' + loopChild.label,
            value: loopParent.fieldId + ',' + loopChild.fieldId
          })
        }
      }
      if (result.length > 0) {
        vm.setInput(result[0].value)
      }
      return result
    },
    form () {
      return this.$store.getters.currentForm
    },
    fieldInfos () {
      const vm = this
      var result = []
      if (vm.form) {
        result = vm.form.fieldInfos
      }
      return result
    }
  },
  methods: {
    setInput (valueStr) {
      const vm = this
      var parentChild = valueStr.split(',')
      var cardConfig = LEVEL_DIAGRAM_CARD_CONFIG
      cardConfig.cardImageFieldId = getFirstAttachmentsFieldId(vm.form.fieldInfos)
      var result = {
        parentFieldId: parentChild[0],
        childFieldId: parentChild[1],
        cardConfig: cardConfig
      }
      vm.$emit('input', result)
    },
    isSingle (fieldInfo) {
      return fieldInfo.properties.relatedRecordQuantity === 'single'
    },
  
    isMultiple (fieldInfo) {
      return fieldInfo.properties.relatedRecordQuantity === 'multiple'
    }
  }
}
</script>
