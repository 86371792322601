/*
* [Hierarchy Unstructured Graph]
* for the real case in Workflow, only only child can be aggreated
* in multiple parent node's children set
* =================
* Properties: 
* =================
* @search: find out the target node (Breath-first Search)
* @build: build each node
*
* =================
* Tree Example: 
* =================
*
*     1 <----- level 1: root
*     |
*     2 <----- level 2: multiple children
*   / | \
*  3  4  5  <---- level 3: same child with node 6 in each of children set
*   \ | /
*     6 <----- level 4: multiple parent nodes, linking node, can be dummy node (nothing to do)
*     |
*     7 <----- level 5: the end node without child
*
* Terry Chan
* 10/05/2021
*/
const DEBUG = false;
class GraphTree {
  constructor(list=[]) {
    // use native map to clone the reference of each of node, aginst watch trigger in tree component
    this.list = list.map(l => ({ ...l }));
    // pop the first node as a root node
    const root = this.list.shift();
    root.children = [];
    this.root = [ root ];
    root.tree = this.root;
    // this.root.children = [ this.root ];
    // this.root.tree = this.root.children;
    // this.list = this.list.slice(0, 2);
  }

  isBranch (type) {
    return type === 'group';
  }
  isCondition (type) {
    return type === 'branch';
  } 
  /* 
  * Traverse Search the tree with breath-first search, native handling
  * [Arguments]
  * @rootNode (GraphNode): target node to be search initially
  * @comparator (Function): The comparator invoked per GraphNode
  * @inpsector (Function): The on-going callback invoked per GraphNode
  * 
  * [Returns]
  * @currentNode (GraphNode)
  */
  search (id) {
    let currentNodes = [...this.root];
    let currentNode;
    while (currentNodes.length > 0) {
      currentNode = currentNodes.shift();
      if (String(currentNode._id) === String(id)) {
        return currentNode;
      }
      currentNodes = [].concat(currentNodes, currentNode.children || []);
    }
    return null;
  }
  buildDenpendency(currentNode) {
    const { parent: parentNode } = currentNode;
    if (parentNode && currentNode.dependsNode) {
      DEBUG && console.log(`${currentNode._id} has dependency parent node.`);
      currentNode.dependsNode = parentNode;
      if (currentNode.children && currentNode.children.length) {
        currentNode.children = currentNode.children.map(n => {
          n.dependsNode = parentNode;
          return n;
        });
      }
    }
  }
  build () {
    if (!this.list.length) return [];
    let currentNode;
    let parentNode;
    // let index = 0;
    while(this.list.length > 0) {
      currentNode = this.list.shift();
      if (currentNode.parent) {
        // find the properly parent node, search from the root node
        parentNode = this.search(currentNode.parent);
        // target parent node found
        if (!!parentNode) {
          DEBUG && console.log(`find parent ${parentNode._id}`);
          currentNode.children = [];
          currentNode.parent = parentNode;
          // link up to tree level list
          if (this.isCondition(currentNode.nodeType)) {
            if (this.isBranch(parentNode.nodeType)) {
              DEBUG && console.log(`${currentNode._id} is condition node and insert under the group branch.`);
              DEBUG && console.log('Group children');
              if (DEBUG) {
                parentNode.children.forEach((n, index) => {
                  console.log(`${index+1} child ${n._id}`);
                })
              }
              parentNode.children.push(currentNode);
              DEBUG && console.log('Tree Level children');
              if (DEBUG) {
                currentNode.children.forEach((n, index) => {
                  console.log(`${index+1} child ${n._id}`);
                })
              }
              
              // the tree level is becoming from the children list
              currentNode.tree = currentNode.children;
              // console.log('>>> buildDenpendency: ', parentNode);
              this.buildDenpendency(parentNode);
            } else {
              DEBUG && console.log(`${currentNode._id} is condition node and cannot insert under regular node directly.`);
              // invalid for regular node under the forking node
            }
          } else {
            DEBUG && console.log(`${currentNode._id} insert to a parent.tree.`);
            DEBUG && console.log('Node Tree');
            if (DEBUG) {
              parentNode.tree.forEach((n, index) => {
                console.log(`${index+1} child ${n._id}`);
              })
            }
            // push to the tree level instead of pushing to the node children level
            parentNode.tree.push(currentNode);

            currentNode.tree = parentNode.tree;         
          } 
        }
      }
    }
    DEBUG && console.log('============================');
    return this.root;
  }
}

module.exports = GraphTree;
