<template>
<v-dialog
    v-model="showingDialog"
    v-if="showingDialog"
    @input="value=>$emit('input',value)"
    activator="#newAppsButton"
    width="500"
  >
  <v-card>
    <!-- <v-card-title class="headline"
                  :class="colorClass"
                  :style="colorStyle">
      {{ dialogTitle }}
    </v-card-title> -->

    <dialog-header
      :label="dialogTitle"
      @close="showingDialog = false"
      :color="colorStyle['background-color']"
      :toolbarClass="colorClass"
    />
    <v-card-text class="app-info-dialog-text d-flex flex-column">

      <div class="d-flex flex-row pt-2">
        <div>
          <v-card-app
              class="mr-5"
              :key="0"
              mode="edit"
              :bounce="false"
              :appInfo="appInfo"></v-card-app>
        </div>

        <div class="flex-grow-1">
          <!-- Title -->
          <form-text-field
            labelClass="label-class"
            valueClass="value-class"
            :label="$t('general.title')"
            v-model="appInfo.title"></form-text-field>

          <!-- Color -->
          <form-color-field
              :value="{color: appInfo.color, textTheme: appInfo.textTheme}"
              @input="colorAndTheme=>updateAppColor(colorAndTheme)"></form-color-field>

          <!-- Logo -->
          <form-logo-field
              :value="appInfo"
              @input="val=>updateAppLogo(val)"></form-logo-field>
          <!-- Icon -->
          <!--<icon-input-field v-model="appInfo.icon"></icon-input-field>-->
        </div>
      </div>
    </v-card-text>
    <!-- <v-divider></v-divider> -->
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="muted"
             class="min-width-80"
             @click="closeDialog">
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn class="min-width-80"
             :style="colorStyle"
             :class="colorClass"
             :disabled="!appInfo.title"
             @click="saveAppsInfo">
        {{ saveButtonTitle }}
      </v-btn>
    </v-card-actions> -->
    <dialog-actions
      :actionDisabled="!appInfo.title"
      :buttons="['cancel', 'ok']"
      @onCommand="onCommandHandler">
    </dialog-actions>
    
  </v-card>
</v-dialog>
</template>

<script>
import appColorMixin from '@/mixins/appColorMixin'
import vCardApp from '../comps/VCardApp'

import formTextField from '@/components/form/FormTextField'
import formColorField from '@/components/form/FormColorField'
import formLogoField from '@/components/form/FormLogoField'
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";

export default {
  name: 'appInfoDialog',
  mixins: [appColorMixin],
  components: {
    vCardApp,

    formTextField,
    formColorField,
    formLogoField,
    dialogActions,
    dialogHeader
  },
  props: {
    value:Boolean
  },
  computed: {
    showingDialog: {
      get(){
        return this.value
      },
      set(val){
        this.$emit('input', val)
      }
    },
    isColorClass () {
      const vm = this
      var result = ''
      if (vm.appInfo.color) {
        result = vm.appInfo.color.indexOf('-')>=0
      }
      return result
    },
    colorClass () {
      const vm = this
      var result = ''
      if (vm.isColorClass) {
        result = vm.appInfo.color
      }
      if (vm.appInfo.textTheme) {
        result += ' ' + (vm.appInfo.textTheme === 'dark' ? 'white--text' : 'black--text')
      }
      return result
    },
    colorStyle () {
      const vm =this
      var result = {}
      if (!vm.isColorClass && vm.appInfo.color) {
        result['background-color'] = vm.appInfo.color
      }
      return result
    },

    color () {
      return this.appInfo.color
    },
    dialogTitle () {
      const vm = this
      var result = []
      if (vm.appInfo.title !== '') {
        result.push(vm.appInfo.title)
      }
      result.push(vm.currentMode)
      return result.join(' ')
    },
    saveButtonTitle () {
      const vm = this
      return vm.appInfo._id === '' ?
        vm.$t('buttons.create') :
        vm.$t('buttons.save')
    },
    currentMode () {
      const vm = this
      const mode = vm.appInfo._id === '' ?
        vm.$t('buttons.new') :
        vm.$t('buttons.edit')
      return '[' + mode + ']'
    },
    bkgdColor () {
      return {
        backgroundColor: 'blue'
      }
    },
    currendtTeamId () {
      const vm = this
      return vm.$store.getters.user.lastTeam
    }
  },
  data () {
    return {
      DEFAULT_APP_INFO: {
        _id: '',
        title: 'New Apps',
        logoType: 'icon',
        logoIcon: '',
        logoMediaId: 0,
        textTheme: 'light',
        color: '#1976d2',
        favourite: false,
        teamId: ''
      },
      appInfo: {
        _id: '',
        title: 'New Apps',
        logoType: 'icon',
        logoIcon: '',
        logoMediaId: 0,
        textTheme: 'light',
        color: '#1976d2',
        favourite: false,
        teamId: ''
      },
      // defaultIconIndex: 0,
      search: '',
      // iconPagination:
      //   {
      //     page: 1,
      //     limit:
      //       140,
      //     totalDocs:
      //       0,
      //     totalPages:
      //       0,
      //     pagingCounter:
      //       0
      //   }
      // ,
      // icons: []
    }
  },

  watch: {
    'iconPagination.page':

      function (newValue) {
        const vm = this
        vm.loadIcons()
      }
  },
  methods: {
    onCommandHandler(payload){
      console.log('AppInfoDialog.onCommandHandler')
      switch(payload.command){
        case 'ok':
          this.saveAppsInfo()
        break
        default: this.closeDialog()
      }
    },
    updateTextTheme (inputTextTheme) {
      const vm = this
      vm.appInfo.textTheme = inputTextTheme
    },
    // getRandomColor2 () {
    //   const rangeSize = 100; // adapt as needed
    //   const parts = [
    //     Math.floor(Math.random()*256),
    //     Math.floor(Math.random()*rangeSize),
    //     Math.floor(Math.random()*rangeSize) + 256-rangeSize
    //   ].sort( (a, b) => Math.random() < 0.5 );

    //   return '#' + parts.map( p => ('0' + p.toString(16)).substr(-2) ).join('') + 'FF';
    // },

    // getRandomColor () {
    //   // Threshold can be between 0 and 127:
    //   //    the higher it is, the more colors are considered to be too grey-like.
    //   const threshold = 50;
    //   // Generate three color parts randomly
    //   const parts = Array.from(Array(3), _ =>
    //     Math.floor(Math.random()*256)
    //   ).sort( (a, b) => a-b );

    //   // Check whether they are too close to the same value:
    //   if (parts[2] - parts[0] < threshold) { // color is too greyish
    //     // Replace the middle one with a random value outside of the "too close" range
    //     const exclude = Math.min(255, parts[0] + threshold)
    //       - Math.max(0, parts[2] - threshold);
    //     parts[1] = Math.floor(Math.random()*(256-exclude));
    //     if (parts[1] >= parts[2] - threshold) parts[1] += exclude;
    //   }
    //   // Shuffle and format the color parts and return the resulting string
    //   return parts
    //     .sort( (a, b) => Math.random() < 0.5 )
    //     .map( p => ('0' + p.toString(16)).substr(-2) )
    //     .join('');
    // },

    // getTextThemeFromBkgd (color) {
    //   const colorHexStr = color.replace('#', '')
    //   var colors = [
    //     parseInt(colorHexStr.substr(0,2), 16),
    //     parseInt(colorHexStr.substr(2,2), 16),
    //     parseInt(colorHexStr.substr(4,2), 16)
    //   ]
    //   const colorSum = colors[0] + colors[1] + colors[2]
    //   return colorSum > 384 ? 'light' : 'dark'
    // },

    updateAppLogo (val) {
      const vm = this
      vm.appInfo.logoType = val.logoType
      vm.appInfo.logoIcon = val.logoIcon
      vm.appInfo.logoMediaId = val.logoMediaId
      vm.appInfo.logoMediaImageUrl = val.logoMediaImageUrl
    },

    updateAppColor (colorSet) {
      const vm = this
      vm.appInfo.color = colorSet.color
      if (colorSet.textTheme) {
        vm.appInfo.textTheme = colorSet.textTheme
      }
    },

    getRandomIcon(callback) {
      const vm = this
      vm.$store.dispatch('GET_RANDOM_LOGO').then(
        response => {
          if (typeof callback === 'function') {
            callback(response)
          }
        }
      )
    },

    init (appInfo) {
      const vm = this
      if (typeof appInfo === 'undefined') {
        const colorAndTheme = vm.getRandomColorAndTheme()
        vm.getRandomIcon( iconInfo => {
        //= JSON.parse(JSON.stringify(vm.DEFAULT_APP_INFO))
          vm.appInfo.title = vm.DEFAULT_APP_INFO.title
          vm.appInfo.logoType = iconInfo.logoType
          vm.appInfo.logoIcon = iconInfo.logoIcon
          vm.appInfo.logoMediaId = iconInfo.logoMediaId
          vm.appInfo.color = colorAndTheme.color
          vm.appInfo.textTheme = colorAndTheme.textTheme
          vm.appInfo.favourite = false
          vm.appInfo.teamId = vm.currentTeamId
        })
        // vm.$set(vm.appInfo, 'teamId', vm.currentTeamId)
        // vm.$set(vm.appInfo, 'color', newColor)
        // vm.$set(vm.appInfo, 'textTheme', vm.getTextThemeFromBkgd(vm.appInfo.color))
      } else {
        vm.appInfo = JSON.parse(JSON.stringify(appInfo))
      }
    },

    saveAppsInfo () {
      const vm = this
      console.log('saveAppsInfo')
      vm.$emit('onCommand', {
        command: 'saveAppsInfo',
        appInfo: vm.appInfo
      })
      vm.closeDialog()
    },

    closeDialog () {
      const vm = this
      vm.$emit('input', false)
    }
  }
}
</script>

<style>
  .app-info-dialog-text .icon-wrapper {
    cursor: pointer;
  }

  .app-info-dialog-text .icon-wrapper:hover {
    background-color: lightgray;
  }

  .app-info-dialog-text .icon-wrapper.selected {
    background-color: lightskyblue;
  }

  .app-info-dialog-text .icon-wrapper.selected:hover {
    background-color: dodgerblue;
  }

  .app-info-dialog-text .label-class {
    width: 100px;
  }

</style>
