<template>
  <v-layout fill-height column>
    <slot />
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        table: 'all',
        keyword: '',
      }
    };
  },
  methods: {
    setKeyword(k) {
      this.filter.keyword = k;
    },
    setTable(t) {
      this.filter.table = t;
    }
  },
  provide() {
    return {
      filterInfo: this.filter,
      setKeyword: this.setKeyword,
      setTable: this.setTable,
    };
  }
};
</script>

<style>
</style>
