<template>
  <div>
    <v-row no-gutters class="px-3 py-1">
      <div class="mr-3 teal lighten-2" style="padding: 2px"/>
      <h4>{{ $t("view.tableSetting") }}</h4>
    </v-row>
    <v-row no-gutters justify="center" align="end" class="pa-5 pt-3">
      <v-col cols="2" class="pa-0 pl-2">
        <label>{{ $t("view.rowHeight") }}:</label>
      </v-col>
      <v-col cols="10" class="pa-0">
        <v-btn-toggle borderless v-model="propertyValue" mandatory dense color="teal">
          <v-btn
            small
            style="width:100px; font-size:13px; margin-left:2px"
            class=""
            :value="option.value"
            v-for="option in rowOptions"
            :key="option.value"
          >
            {{ $t(option.label) }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import mixin from "./mixin";

export default {
  props: {
    view: null,
  },
  mixins: [mixin],
  data() {
    return {
      rowOptions: [
        {
          label: "view.dense",
          value: "xs",
        },
        {
          label: "view.default",
          value: "s",
        },
        {
          label: "view.sparse",
          value: "l",
        },
        {
          label: "view.extraSparse",
          value: "xl",
        },
      ],
    };
  },
  computed: {
    propertyValue: {
      get() {
        return this.view["tableRowHeight"] || "xs";
      },
      set(val) {
        this.$emit("onCommand", {
          command: "updateViewSetting",
          settingName: "tableRowHeight",
          val: val,
        });
      },
    },
  },
  methods: {},
};
</script>
