<template>
  <v-container v-if="loading" fluid class="fill-height pa-0">
    <loading-icon />
  </v-container>
  <v-container v-else fluid class="fill-height pa-0">
    <v-app-bar
      app
      flat
      class="underline"
      style="background-color:white"
    ></v-app-bar>
    <v-navigation-drawer permanent fixed app floating class="api-drawer">
      <template v-slot:prepend>
        <v-toolbar flat class="underline" style="height:64px;">

          {{ $t('api.PageTitle', [appInfo.title]) }} </v-toolbar>
      </template>
      <v-list dense>
        <v-list-item
          v-for="section in generalSections"
          :key="section.value"
          :input-value="selectedSection === section.value"
          link
          active-class="primary lighten-1 white--text"
          @click="
            $vuetify.goTo(`#${section.value}`, {
              duration: 300,
              offset: 0,
              easing: 'easeInOutCubic',
            })
          "
        >
          <v-list-item-content>
            <v-list-item-title>{{ section.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          active-class="no-active"
          no-action
          v-model="formSections.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ formSections.label }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-group
            v-for="(form, index) in formSections.children"
            :key="form._id"
            active-class="no-active"
            no-action
            sub-group
            @click="closeOther(index, formSections.children)"
            v-model="form.active"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ form.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="section in selectedFormApis"
              :key="section.value"
              link
              active-class="primary lighten-1 white--text"
              :input-value="selectedSection === section.value"
              @click="
                $vuetify.goTo(`#${section.value}`, {
                  duration: 300,
                  offset: 0,
                  easing: 'easeInOutCubic',
                });
                selectedSection = section.value;
              "
            >
              <v-list-item-title>{{ section.label }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <div
        v-for="section in generalSections"
        :key="section.value"
        :id="section.value"
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0, 0.3, 0.5, 0.8, 1.0],
          },
        }"
      >
        <api-block v-if="!section.noContent" :section="section">
                    <template
            v-slot:prepend-table
            v-if="section.value === 'authentication'"
          ><a @click="showAuthDialog=true">{{ $t('api.instruction') }}</a></template>
          <template
            v-slot:append-content
            v-if="section.value === 'authentication'"
          >
            <v-btn color="primary" rounded class="mt-2" @click="addApiKey"
              >{{ $t('api.addKey') }}<v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <template v-slot:table-actions="{row, index}" v-if="section.hasActions">
            <v-list dense>
              <v-list-item
                link
                v-for="menuItem in getAuthMenuItems(
                  row.permission
                )"
                :key="menuItem.key"
                @click="onCommandHandler(menuItem.key, {...menuItem.params, appKey:row.appKey, index:index})"
              >
                <v-list-item-title :class="`${menuItem.color}--text`">{{
                  menuItem.label
                }}</v-list-item-title>
              </v-list-item>
            </v-list>            
          </template>
        </api-block>
      </div>
      <div
        v-for="section in selectedFormApis"
        :key="section.value"
        :id="section.value"
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0, 0.3, 1.0],
          },
        }"
      >
        <api-block v-if="!section.noContent" :section="section">
          <template v-slot:prepend-table>
            <url-box
              class="my-2"
              v-if="section.path"
              :url="section.path"
              noQRCode
            >
              <template v-slot:prepend-outer>
                <url-box
                  :url="section.method"
                  noQRCode
                  :canCopy="false"
                  maxWidth="55px"
                  class="mr-2"
                />
              </template>
            </url-box>
            <span v-if="section.path">{{ $t('api.requestParam') }}</span>
          </template>
        </api-block>
      </div>
    </v-main>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <general-dialog v-model="showingRemarkDialog" :dialogTitle="$t('widgets.remark')" 
      :confirmAction="()=>editRemark(selectedAuthKeyIndex, authKeyRemark)">
      <template v-slot:body>
        <v-text-field outlined dense :placeholder="$t('messages.insertText')" v-model="authKeyRemark"/>
      </template>
  </general-dialog>
    <auth-instruction-dialog v-model="showAuthDialog"/>
  </v-container>
</template>

<script>
import ApiBlock from "./comps/ApiBlock";
import LoadingIcon from "@/components/global/loadingIcon";
import UrlBox from "@/pages/admin/form/publicPublishing/comp/urlBox";
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import GeneralDialog from "@/components/dialogs/GeneralDialog"
import AuthInstructionDialog from './comps/authInstructionDialog'

export default {
  components: {
    ApiBlock,
    LoadingIcon,
    UrlBox,
    ConfirmDeleteDialog,
    GeneralDialog,
    AuthInstructionDialog
  },
  data() {
    return {
      WIDGET_DATA_TYPE_MAP: {
        number: {
          remark: "double",
          example: 511.29,
        },
        amount: {
          remark: "double",
          example: 511.29,
        },
        date: {
          remark: "string",
          example: {
            date: "2021-05-11",
            datetime: "2021-05-11 11:29",
            time: "11:29",
          },
        },
        yesNo: {
          remark: "boolean",
          example: true,
        },
        multipleSelection: {
          remark: "[string]",

          example: ["Option1", "Option2"],
          otherFields: {
            type:
              this.$t('api.selectionTypeDescription'),
          },
        },
        singleSelection: {
          remark: "string",
          example: "Option1",
          otherFields: {
            type:
              this.$t('api.selectionTypeDescription')
          },
        },
        default: {
          remark: "string",
          example: "string",
        },
        region: {
          remark: "string",
          example: this.$t('api.regionDefault'),
        },
        rating: {
          remark: "integer",
          example: 3,
        },
        readonly: {
          remark: this.$t('widgets.properties.readOnly'),
        },
      },
      appInfo: {},
      authKeys: [],
      forms: [],
      selectedSection: "",
      selectedForm: "",
      selectedAuthKeyIndex:-1,
      fieldInfos: [],
      selectedFormApis: [],
      authKeyRemark:'',
      loading: false,
      showingRemarkDialog:false,
      showAuthDialog:false
    };
  },
  async mounted() {
    this.loading = true;
    await this.getAppInfo(this.$route.params.appId);
    this.selectedForm = this.forms[0]._id;
    this.loading = false;
  },

  computed: {
    openApiWidgetTypes() {
      return this.$store.getters.openApiWidgetTypes || [];
    },
    generalSections() {
      return [
        {
          label: this.$t('widgets.description'),
          value: "description",
          description:
            this.$t('api.description'),
        },
        {
          label: this.$t('api.authentication'),
          value: "authentication",
          description: "",
          tableHeaders: [
            {
              label: this.$t('api.appKey'),
              value: "appKey",
              width: "25%",
            },
            {
              label: this.$t('api.secret'),
              value: "secret",
              width: "30%",
            },

            {
              label: this.$t('widgets.properties.permission'),
              value: "permission",
              width: "12%",
            },
            {
              label: this.$t("widgets.remark"),
              value: "remark",
              width: "12%",
            },
          ],
          tableData: this.authKeys,
          hasActions: true,
        },
      ];
    },
    formSections() {
      return {
        label: this.$t('menu.form'),
        value: "forms",
        active: false,
        noContent: true,
        children: this.forms,
      };
    },
    requestJsonFields() {
      return (
        (this.selectedFormApis[0] &&
          this.selectedFormApis[0]["tableData"].filter((datum) =>
            this.openApiWidgetTypes.includes(datum.type)
          )) ||
        []
      );
    },
    host() {
      return this.$store.getters.appHost;
    },
  },
  watch: {
    selectedForm: {
      async handler(newFormId, oldFormId) {
        if (newFormId && newFormId !== oldFormId) {
          await this.getFormApis(newFormId, this.appInfo._id, Boolean(oldFormId));
        }
      },
    },
    showingRemarkDialog(val){
      if (!val){
        this.authKeyIndex = -1
        this.authKeyRemark = ""
      } else {
        this.authKeyRemark = this.authKeys[this.selectedAuthKeyIndex] && this.authKeys[this.selectedAuthKeyIndex].remark || ""
      }

    }
  },
  methods: {
    onIntersect(entries) {
      if (entries[0].intersectionRatio >= 0.5) {
        this.selectedSection = entries[0].target.id;
        if (this.selectedSection.startsWith("forms")) {
          const [_, formId, api] = this.selectedSection.split("_");
          this.formSections.active = true;
          this.selectedForm = formId;
          const index = this.forms.findIndex((child) => child._id === formId);
          if (index > -1 && !this.forms[index].active) {
            this.forms[index].active = true;
            this.closeOther(index, this.forms);
          }
        }
      }
    },
    closeOther(val, items) {
      items.forEach((x, i) => {
        if (val !== i) x.active = false;
        else if (this.selectedForm !== x._id) this.selectedForm = x._id;
      });
    },
    getAuthMenuItems(permission) {
      let arr = ["all", "none"];
      const index = arr.findIndex((item) => item === permission);
      if (index > -1) arr.splice(index, 1);
      return arr
        .map((key) => ({
          label: this.$t(`api.${key}Permission`),
          key: "changePermission",
          params: {
            value: key,
          },
        }))
        .concat([
          {
            label: this.$t('api.editRemark'),
            key: "editRemark",
            params:{}
          },
          {
            label: this.$t('api.deleteKey'),
            key: "deleteKey",
            color:'error',
             params:{}
          },
        ]);

    },    
    async getAppInfo(appId) {
      const getParams = {
        urlCommand: `/appApi/getAppInfo?appId=${appId}`,
      };
      const res = await this.$store.dispatch("AUTH_GET", getParams);
      this.appInfo = {
        _id: res._id,
        color: res.color,
        teamId: res.teamId,
        title: res.title,
      };
      this.authKeys = res.apiKeys.map(key=>({
        ...key,
        permission:this.$t(`api.${key.permission}`)
      }));
      this.forms = res.forms.map((form) => ({ ...form, active: false }));
    },
    async getFormApis(formId, appId, jumpToSection = true) {
      const getParams = {
        urlCommand: "/forms/" + formId + "?appId=" + appId,
      };
      const res = await this.$store.dispatch("AUTH_GET", getParams);

      const formSchema = this.getFormSchema(res.result.fieldInfos);
      this.selectedFormApis = [
        {
          label: this.$t('general.field'),
          value: `forms_${formId}_fieldInfos`,
          description: this.$t('api.formSchema'),
          tableHeaders: [
            {
              label: "fieldId",
              value: "fieldId",
              width: "25%",
            },
            {
              label: this.$t('widgets.properties.fieldName'),
              value: "label",
              width: "25%",
            },
            {
              label: this.$t('widgets.properties.fieldType'),
              value: "type",
              width: "25%",
            },
            {
              label: this.$t('widgets.remark'),
              value: "remark",
              width: "25%",
            },
          ],
          tableData: formSchema,
        },
        {
          label: this.$t('api.addNewRecord'),
          value: `forms_${formId}_addRecord`,
          method: "POST",
          path: `${this.host}/openApi/addNewRecord`,
          tableHeaders: [
            {
              label: this.$t('api.param'),
              value: "param",
              width: "25%",
            },
            {
              label: this.$t('widgets.properties.required'),
              value: "required",
              width: "25%",
            },
            {
              label: this.$t('api.type'),
              value: "type",
              width: "25%",
            },
            {
              label: this.$t('widgets.remark'),
              value: "remark",
              width: "25%",
            },
          ],
          tableData: [
            {
              param: "formId",
              required: true,
              type: "string",
              remark: this.selectedForm,
            },
            {
              param: "fields",
              required: true,
              type: "[object]",
              remark: "",
            },
          ],
          requestJson: {
            formId: this.selectedForm,
            fields: [
              ...this.getRequestJson(
                res.result.fieldInfos.filter((info) =>
                  this.openApiWidgetTypes.includes(info.type)
                )
              ),
            ],
          },
          responseJson: {
            added: this.$t('api.added'),
            newRecordId:
              this.$t('api.newRecordId'),
          },
          errors: [
            {
              httpCode: 400,
              description: this.$t('api.emptyFieldsNotAllowed'),
            },
            {
              httpCode: 401,
              description:
                "Unauthorized Access.See returned message for detail",
            },
            {
              httpCode: 404,
              description: "Form not Found",
            },
          ],
        },
      ];
      if (jumpToSection)
      this.$nextTick(() => {
        this.$vuetify.goTo(`#forms_${formId}_fieldInfos`, {
          duration: 300,
          offset: 0,
          easing: "easeInOutCubic",
        });
      });
    },
    getRequestJson(fieldInfos) {
      return fieldInfos.map((field) => {

        return {
          fieldId: field.fieldId,
          value: this.getExample((
            this.WIDGET_DATA_TYPE_MAP[field.type] ||
            this.WIDGET_DATA_TYPE_MAP["default"]
          ).example, field.properties.fieldType),
          ...((
            this.WIDGET_DATA_TYPE_MAP[field.type] ||
            this.WIDGET_DATA_TYPE_MAP["default"]
          ).otherFields || {}),
        };
      });
    },
    getExample(example, type) {
      if (typeof example === "object" && !Array.isArray(example)) {
        return example[type];
      } else return example;
    },
    getFormSchema(fieldInfos) {
      return fieldInfos.map((info) => {
        const result = {
          fieldId: info.fieldId,
          label: info.label,
          type: this.$t(`widgets.${info.type}`),
          remark: this.openApiWidgetTypes.includes(info.type)
            ? (
                this.WIDGET_DATA_TYPE_MAP[info.type] ||
                this.WIDGET_DATA_TYPE_MAP["default"]
              ).remark
            : this.WIDGET_DATA_TYPE_MAP["readonly"].remark,
        };
        if (info.type === "date")
          Object.assign(result, {
            subtype: info.properties.fieldType,
          });
        return result;
      });
    },
    addApiKey(){
      const postData = {
        urlCommand: '/appApi/createApiKey',
        data: {
           appId: this.appInfo._id
        }
      }

      this.$store.dispatch('AUTH_POST', postData)
      .then(res => {
        this.authKeys.push(res)
      })
    },
    onCommandHandler(command, payload){
      switch(command){
        case 'editRemark':
          this.selectedAuthKeyIndex = payload.index
          this.showingRemarkDialog = true
          break;
        case 'deleteKey':
          this.deleteApiKey(payload)
          break;
        case 'changePermission':
          this.changePermission(payload)
          break;
      }
    },
    deleteApiKey({appKey}){
      this.$refs.confirmDeleteDialog.confirm(()=>{
        const postData = {
          urlCommand:'/appApi/deleteKey',
          data: {
            appId:this.appInfo._id,
            appKey
          }
        }
        this.$store.dispatch('AUTH_POST', postData)
        .then(res => {
          if (res.result){
            const index = this.authKeys.findIndex(key => key.appKey === appKey)
            if (index > -1)this.authKeys.splice(index, 1)
          }
        })
      })
    },
    editRemark(authKeyIndex, remark){
      if (remark !== this.authKeys[authKeyIndex].remark){
        const postData = {
          urlCommand:'/appApi/editRemark',
          data: {
            appId:this.appInfo._id,
            appKey: this.authKeys[authKeyIndex].appKey,
            remark
          }
        }
        this.$store.dispatch('AUTH_POST', postData)
        .then(res => {
          if (res.result){
            this.authKeys[authKeyIndex].remark = remark
            this.showingRemarkDialog = false
          }
        })
      }

    },
    resetRemarkDialog(){
      this.authKeyIndex = -1
      this.authKeyRemark = ""
    },
    changePermission({value, appKey, index}){
        const postData = {
          urlCommand:'/appApi/changePermission',
          data: {
            appId:this.appInfo._id,
            appKey: appKey,
            value
          }
        }
        console.log(value, appKey, index)
        this.$store.dispatch('AUTH_POST', postData)
        .then(res => {
          if (res.result){
            this.authKeys[index].permission = value
          }
        })      
    }
  },
};
</script>
<style lang="scss">
.api-drawer {
  .v-navigation-drawer__content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .no-active {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    .v-icon,
    .v-list-item__icon {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.underline {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}

.api-navigation a {
  color: inherit !important;
}
</style>
<style lang="scss" scoped></style>
