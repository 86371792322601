/*
* Terry Chan
* 12/05/2021
*/
const DelegatedDateFieldsSelection = [
    {
        fieldType: "SYSTEM_",
        isDisabled: false,
        isSystem: true,
        label: 'modules.delegated.label.system',
        type: "delegated",
        id: "SYSTEM_",
        children: [
            {
                id: "CURRENTDATE",
                type: "delegated",
                fieldType: "CURRENTDATE",
                isDisabled: false,
                label: 'modules.delegated.label.currentDatetime',
                isSystem: true,
                fieldId: "CURRENTDATE",
            },
            {
                id: "WORKFLOWCREATOR",
                type: "delegated",
                fieldType: "WORKFLOWCREATOR",
                isDisabled: false,
                label: 'modules.delegated.label.currentWorkflowCreator',
                isSystem: true,
                fieldId: "WORKFLOWCREATOR",
            },
            {
                id: "WORKFLOWTRIGGER",
                type: "delegated",
                label: 'modules.delegated.label.currentWorkflowTrigger',
                fieldId: "WORKFLOWTRIGGER",
                fieldType: "WORKFLOWTRIGGER",
                isSystem: true,
                isDisabled: false,
            },
        ],
    },
]
const DelegatedRelationshipFieldsSelection = [ 
    {
        fieldType: "OPERATION_",
        isDisabled: false,
        isSystem: true,
        label: 'modules.delegated.label.operation',
        type: "delegated",
        id: "OPERATION_",
        children: [
            {
                id: "REMOVERELATION",
                type: "delegated",
                fieldType: "REMOVERELATION",
                isDisabled: false,
                label: 'modules.delegated.label.removeRelationship',
                isSystem: true,
                fieldId: "REMOVERELATION",
            },
        ],
    },
];
export {
    DelegatedDateFieldsSelection,
    DelegatedRelationshipFieldsSelection,
};
