<template>
  <div class="workflow-field-element" :class="{ 'workflow-field-sub-element': subItem }">
    <div v-if="!!label" class="workflow-field-label" :class="{ 'workflow-field-sub-label': subItem }">
      {{ label }}
    </div>
    <div v-if="!!hints && hintsPosition === 'top'" class="workflow-field-hints">
      {{ hints }}
    </div>
    <div class="workflow-setting-form-element-root" :class="{ 'workflow-field-error': !!error }" v-if="!noContent">
      <div class="workflow-field-label-content">
        <slot />
      </div>
      <p v-if="!!error" class="workflow-field-error-message">
        <v-icon
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-alert-circle
        </v-icon>
        <span>{{ errorReason(error) }}</span>
      </p>
    </div>
    <div v-if="!!hints && hintsPosition === 'bottom'" class="workflow-field-hints hBottom">
      {{ hints }}
    </div>
  </div>
</template>

<script>
import SettingUtils from './../../../../utils/setting';
import Mixin from './../mixin';
export default {
  mixins: [Mixin],
  data() {
    return {
      error: undefined,
    }
  },
  props: {
    name: String,
    // error: String,
    id: String,
    label: String,
    hintsPosition: {
      type: String,
      default: 'top',
    },
    subItem: Boolean,
    hints: String,
    change: Function,
    noContent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setCheckingError() {
      const { checkingLog=[] } = this;
      this.error = checkingLog.length && SettingUtils.getCheckingError(checkingLog, this.name || this.id);
    }
  },
  mounted() {
    this.setCheckingError();
  },
  computed: {
    checkingLog() {
      return this.node.checkingLog;
    },
  },
  watch: {
    checkingLog: {
      handler() {
        this.setCheckingError();
      },
      deep: true,
    }
  }
};

</script>

<style scoped>
  
</style>
