import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import apps from './modules/apps/index.js'
import appTemplates from './modules/appTemplates/index.js'
import calendarPanel from './modules/calendarPanel/index.js'
import childTable from './modules/childTable/index.js'
import common from './modules/common/index.js'
import constants from './modules/constants/index.js'
import data from './modules/data/index.js'
import dataTable from './modules/dataTable/index.js'
import filters from './modules/filters/index.js'
import form from './modules/forms/index.js'
import formConfig from './modules/formConfig/index.js'
import inputPanel from './modules/inputPanel/index.js'
import media from './modules/media/media.js'
import panel from './modules/panels/index.js'
import report from './modules/reports/report.js'
import routes from './modules/routes/routes.js'
import system from './modules/system/system.js'
import teams from './modules/teams/teams.js'
import templates from './modules/templates/templates.js'
import widgetGroups from './modules/widgetGroups/widgetGroups.js'
import imports from "./modules/imports/index.js"
import widgetProperties from './modules/widgetProperties/widgetProperties.js'

import publics from './modules/publics/index.js'

import yoovWork from './modules/yoovWork/index.js'

import workflowModules from './modules/workflow/modules.js'
import displayRule from './modules/tableSetup/displayRule.js'
import workflowTree from './modules/workflow/tree.js'
import workflowForm from './modules/workflow/form.js'
import orchestration from './modules/orchestration/'
import socketService from './modules/notification/'
import customizedAction from './modules/customizedAction/'
// import workflowSocket from './modules/workflow/socket'
// import displayRule from './modules/tableSetup/displayRule.js'

import mobile from './modules/mobile'
import mail from './modules/mail'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    apps,
    appTemplates,
    calendarPanel,
    childTable,
    common,
    constants,
    data,
    dataTable,
    filters,
    form,
    formConfig,
    inputPanel,
    media,
    panel,
    report,
    routes,
    system,
    teams,
    templates,
    widgetGroups,
    widgetProperties,
    imports,
    workflowModules,
    displayRule,
    workflowTree,
    workflowForm,
    orchestration,
    socketService,
    customizedAction,
    // workflowSocket,
    // displayRule,
    publics,
    mobile,
    mail,
    yoovWork
  }
})
