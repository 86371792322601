<template>
  <div class="fs-text text-no-wrap overflow-hidden ">
    <label v-if="useLabel"
           class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    {{ titleValue }}
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsDepartments',
  mixins: [mixin],
  computed: {
    titleValue () {
      const vm = this
      var result = vm.UNSPECIFIED
      if (vm.dataValue && Array.isArray(vm.dataValue) && vm.dataValue.length > 0) {
        result = []
        for (let i = 0; i < vm.dataValue.length; i++) {
          const loopItem = vm.dataValue[i]
          result.push(loopItem.label)
        }
        if (result.length > 0) result = result.join(',')
      }
      return result
    }
  }
}
</script>
