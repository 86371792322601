<template>
  <div ref="draggableSplitter" class="draggable-splitter"
       :class="{'blue white--text lighten-3':dragging}"
       @mousedown.stop.prevent="dragMouseDown">
  </div>
</template>

<script>
export default {
  name: 'DraggableSplitter',
  props: {
    splitterIndex: {
      type: Number,
      default: 0
    },
    splitterLeft: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      originLeft: 0,
      parentClientX: 0,
      positions: {
        clientX: undefined,
        movementX: 0
      },
      dragging: false,
      currentLeft: 0
    }
  },
  methods: {
    dragMouseDown: function (event) {
      const vm = this
      vm.dragging = true
      event.preventDefault()
      console.log('dragMouseDown :: event: ', event)
      this.positions.clientX = event.clientX
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
      vm.$emit('onCommand', {
        command: 'setDragging',
        splitterIndex: vm.splitterIndex
      })
    },
    elementDrag: function (event) {
      const vm = this
      event.preventDefault()
      vm.positions.movementX = vm.positions.clientX - event.clientX
      vm.positions.clientX = event.clientX
      // set the element's new position:
      //   vm.$refs.draggableSplitter.style.top = (vm.$refs.draggableSplitter.offsetTop - vm.positions.movementY) + 'px'
      vm.currentLeft = vm.currentLeft - vm.positions.movementX

      // const parentLeft = vm.$parent.$el.getBoundingClientRect().x

      // console.log('DraggableSplitter :: elementDrag :: ' +
      //   'event.clientX = ' + event.clientX +
      //   ', currentLeft = ' + vm.currentLeft)

      // console.log('parentLeft = ' + parentLeft +
      //   ', event.clientX = ' + event.clientX +
      //   ', currentLeft = ' + vm.currentLeft)

      const left = vm.$refs.draggableSplitter.offsetLeft - vm.positions.movementX
      vm.$refs.draggableSplitter.style.left = left + 'px'
      vm.$emit('onCommand', {
        command: 'updatePosition',
        splitterIndex: vm.splitterIndex,
        splitterLeft: left + vm.splitterLeft
      })
    },
    closeDragElement () {
      const vm = this
      vm.dragging = false
      // console.log('closeDragElement :: dragging = ' + (vm.dragging ? 'yes' : ' no'))
      document.onmouseup = null
      document.onmousemove = null
      this.$refs.draggableSplitter.style.left = 0
      vm.$emit('onCommand', {
        command: 'cancelDragging'
      })
    }
  }
}
</script>

<style>
.draggable-splitter {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 15px;
  cursor: col-resize;
  border: 2px solid white;
  /*background-color: #f4f9fc;*/
}

.draggable-splitter:before {
  top: 50%;
  height: 30px;
  width: 1px;
  background-color: rgba(0,0,0,.2);
  content: "";
  position: absolute;
  transform: translateY(-50%);
  margin-left: 3px;
  /*margin-left: 5px;*/
}
.draggable-splitter:after {
  top: 50%;
  height: 30px;
  width: 1px;
  background-color: rgba(0,0,0,.2);
  content: "";
  position: absolute;
  transform: translateY(-50%);
  margin-left: 8px;
  /*margin-left: 10px;*/
}
</style>
