const mixin = {
  computed: {
    themeColor() {
      return this.publicForm ? this.publicForm.themeColor : "";
    },
    colorIntensity() {
      return this.publicForm ? this.publicForm.backgroundColorIntensity : 0;
    },
    borderColor() {
      const color = this.color || this.themeColor;
      return this.getAltColor(color, this.colorIntensity);
    },
    publicUrl(){
      if(this.isPublicEdit)
        return (
          window.location.origin + "/public/edit/" + this.publicForm._id
        )
      else
        return (
          window.location.origin + "/public/form/" + this.publicForm.urlId
        )
    },
    showHeader(){
      return Boolean(this.publicForm && this.publicForm.showHeader)
    }
  },
  methods: {
    getAltColor(color, p) {
      let r, g, b;
      if (typeof color === "string") {
        r = parseInt(color.substring(1, 3), 16);
        g = parseInt(color.substring(3, 5), 16);
        b = parseInt(color.substring(5, 7), 16);
      } else {
        r = color.r;
        g = color.g;
        b = color.b;
      }

      var t = p < 0 ? 0 : 255 * p,
        P = p < 0 ? 1 + p : 1 - p;

      r = Math.round(r * P + t);
      g = Math.round(g * P + t);
      b = Math.round(b * P + t);

      return (
        "#" +
        r.toString(16).toUpperCase() +
        g.toString(16).toUpperCase() +
        b.toString(16).toUpperCase()
      );
    },
    openURL(preview = false){
      let url = preview ? this.publicUrl + '/preview' : this.publicUrl;
      window.open(url);
      this.$emit("commandHandler", {
        command:"openURL",
        url
      })
    }
  },
};

export default mixin;
