<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :required="required"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    class="mb-5"
    :isHidden="isHidden"
    :tooltipText="description"
  >
    <v-text-field
      :value="showValue"
      dense
      :hide-details="true"
      single-line
      disabled
      @input="(val) => updateValue(val)"

      class="right-input disabled"
      :suffix="suffix"
    ></v-text-field>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import { formatDateTime,RuleSet } from "../../helpers/DataHelpers";
import { find } from 'lodash';
import _ from 'lodash';

export default {
  name: "inputWidgetSummaryField",
  mixins: [mixin],
  props: {
    editAnyway: Boolean,
  },
  components: {
    inputWidgetContainer,
  },
  computed: {
    filterLogic() {
      const { filterCriteria = {} } = this.fieldInfo.properties;
      return filterCriteria.filterAndOr || "and"
    },
    filters() {
      const { filterCriteria = {} } = this.fieldInfo.properties;
      return filterCriteria.filters || []
    },
    suffix() {
      return this.displayFormat === "percentage" ? "%" : "";
    },
    linkedFieldInfoId() {
      return getFieldPropertyValue(this.fieldInfo, "tableField", "");
    },
    displayFormat() {
      return getFieldPropertyValue(this.fieldInfo, "displayFormat", "number");
    },
    linkedFieldInfo() {
      const vm = this;
      var result = null;
      if (vm.linkedFieldInfoId !== "") {
        result = find(vm.fieldInfos, 
          (info) => info._id === vm.linkedFieldInfoId
        );
      }
      return result;
    },

    relatedFormId() {
      const vm = this;
      var result = "";
      if (vm.linkedFieldInfo) {
        switch (vm.linkedFieldInfo.type) {
          case "relatedRecord":
            result = vm.linkedFieldInfo.properties.dataSource;
            break;
          case "childTable":
            result = vm.linkedFieldInfo.properties.fieldsEditor.formId;
            break;
        }
      }
      return result;
    },

    relatedTableInfos() {
      return this.$store.getters.relatedTableInfos;
    },
    relatedFormInfo() {
      return this.relatedTableInfos && this.relatedFormId !== ""
        ? this.relatedTableInfos[this.relatedFormId]
        : null;
    },
    relatedFieldInfo() {
      return this.relatedFormInfo
        ? find(this.relatedFormInfo.fieldInfos, 
            (info) => info.fieldId === this.summaryFieldId
          )
        : null;
    },
    summaryFieldId() {
      return getFieldPropertyValue(this.fieldInfo, "summaryField", "");
    },
    tableData() {
      var result = [];
      if (this.linkedFieldInfo && this.linkedFieldInfo.type === 'childTable' ){
        result = this.recordData[this.linkedFieldInfoId] || [];
      } else {
        if (this.relatedTableData) {
          if (this.relatedTableData[this.linkedFieldInfoId]) {
            let records = this.relatedTableData[this.linkedFieldInfoId] || [];
            const selectedRecordIds = this.recordData[this.linkedFieldInfoId] || [];
            result = records.filter((record) => selectedRecordIds.includes(record._id));
          }
        }
      }
      return result;
    },
    summaryFunction() {
      return getFieldPropertyValue(this.fieldInfo, "summaryFunction", "");
    },
    // notes:
    //
    // The summary field is calculated during submission/deletion
    // The field info is used to identify the type to determine how to display the value
    //
    showValue() {
      const vm = this;
      let result = "";
      if(this.isLayout){
        return result
      }
      else if (
        (this.linkedFieldInfo && this.linkedFieldInfo.type === 'childTable') ||
        this.isPublic
      ) {
        result = this.getSummaryValue();
        if (typeof result === 'number') result = vm.formatNumber(result);
      } else {
        result = isNaN(vm.inputValue)? (vm.inputValue || "") : (vm.inputValue || 0);

        if (
          vm.relatedFieldInfo &&
          ["amount", "number", "expression", "summaryField", "otherTableField"].includes(vm.relatedFieldInfo.type)
        ) {
          result = vm.formatNumber(result);
        }
      }

      return result;
    },
    isReadOnly(){
      return true
    },
    filteredData() {
      const rules = this.getRules(this.filters)
      return this.tableData.filter(
        row =>
          this.filterLogic === "and" ? 
            rules.every(rule => rule.func(row[rule.fieldId], rule.filterValue1, rule.filterValue2)) :
            rules.some(rule => rule.func(row[rule.fieldId], rule.filterValue1, rule.filterValue2))
      )
    }
  },
  watch:{
    tableData:{
      handler(){
        this.getSummaryValue()
      },
      deep:true
    }
  },
  methods: {
    getSummaryValue() {
      let result = 0;
      if (this.summaryFieldId === "#") {
        //get record length
        result = this.filteredData.length;
      } else {
        switch (this.summaryFunction) {
          case "sum":
            {
              result = this.filteredData.reduce((acc, datum) => {
                let field = _.chain(datum[this.summaryFieldId]).toString().replace(/,/g, "").toNumber().value()
                if (!isNaN(field)) {
                  acc += field;
                }
                return acc;
              }, 0);
              //result = this.formatNumber(result);
            }

            break;
          case "average":
            {
              let total = this.filteredData.reduce((acc, datum) => {
                let field = parseFloat(datum[this.summaryFieldId]);
                if (!isNaN(field)) {
                  acc += field;
                }
                return acc;
              }, 0);

              result = total / this.filteredData.length ;
              //result = this.formatNumber(result);
            }
            break;
          case "max":
            {
              let arr = this.filteredData.reduce((acc, datum) => {
                let field = parseFloat(datum[this.summaryFieldId]);
                if (!isNaN(field)) {
                  acc.push(field);
                }
                return acc;
              }, []);
              if(arr && arr.length){
                result = Math.max(...arr);
              }else{
                result = 0
              }
              //result = this.formatNumber(result);
            }
            break;
          case "min":
            {
              let arr = this.filteredData.reduce((acc, datum) => {
                let field = parseFloat(datum[this.summaryFieldId]);
                if (!isNaN(field)) {
                  acc.push(field);
                }
                return acc;
              }, []);
              if(arr && arr.length){
                result = Math.min(...arr);
              }else{
                result = 0
              }
              //result = this.formatNumber(result);
            }
            break;
          case "filledCount":
            result = this.filteredData.reduce((acc, datum) => {
              if (datum[this.summaryFieldId]) {
                acc++;
              }
              return acc;
            }, 0);
            //result = this.formatNumber(result);
            break;
          case "nonFilledCount":
            result = this.filteredData.reduce((acc, datum) => {
              if (!datum[this.summaryFieldId]) {
                acc++;
              }
              return acc;
            }, 0);
            //result = this.formatNumber(result);
            break;
          case "latest":
            {
              let arr = this.filteredData.filter((datum) =>
                Boolean(datum[this.summaryFieldId])
              );
              result = arr.reduce((acc, datum) => {
                return datum[this.summaryFieldId] > acc
                  ? datum[this.summaryFieldId]
                  : acc;
              }, "");
              result = result ? formatDateTime(new Date(result)) : "";
            }
            break;
          case "earliest":
            {
              let arr = this.filteredData.filter((datum) =>
                Boolean(datum[this.summaryFieldId])
              );
              result = arr.reduce((acc, datum) => {
                return datum[this.summaryFieldId] < acc
                  ? datum[this.summaryFieldId]
                  : acc;
              }, "");
              result = result ? formatDateTime(new Date(result)) : "";
            }
            break;
        }
      }
      this.inputValue = result;
      return result;
    },
    getRules(filters = []) {
      const result = [];
      filters.forEach(filter => {
        const func = RuleSet[filter.relation]
        if (func) result.push({
          fieldId: filter.fieldId,
          filterValue1: filter.filterValue1,
          filterValue2: filter.filterValue2,
          func
        })
      })
      return result
    }

  },
};
</script>

<style scoped>
.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
.right-input >>> input {
  text-align: right;
}
</style>
