import { get, chain } from 'lodash';
import NodeUtils from '@/pages/admin/workflow/utils/node';

const mixin = {
  methods: {
    fieldName(item, locale) {
      const { fields } = this;
      const { fieldId } = item;
      // console.log(this.$t(item.label));
      return get(fields, fieldId) || this.$t(item.label) || item.label || locale[fieldId];
    },
    safeValue(content={}, locale, widgetLocale, mapLabelName='label') {
      const values = NodeUtils.withArray(content.value);
      return chain(values).map(props => {
        let v = props[mapLabelName];
        if (props.isSystem) {
          v = widgetLocale[props.fieldId];
        }
        return v;
      }).join(
        ` ${locale.or} `
      ).value();
    },
  },
  computed: {
    fields() {
      return this.$store.state.workflowTree.tree.fields || {};
    },
    rules() {
      return get(this.node, 'properties.rules', []);
    }
  }
}

export default mixin
