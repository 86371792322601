<template>
    <v-text-field dense outlined :placeholder="placeholder" :label="label" value=" "></v-text-field>
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: {
      type: String,
      default: ''
    }
  }
}
</script>
