<template>
  <div>
    <v-form v-model="valid" class="employee-form" ref="employeeForm">
      <form-title
        class="mx-2"
        :title="newValue.displayName || $t('general.name')"
        :buttons="['back']"
        @onCommand="onCommandHandler"
      >
        <template v-slot:prepend-action-button-set>
          <v-switch
          class="mt-0"
            v-model="newValue['invitation']"
            key="invitation"
            :label="$t('workAdmin.invitation')"
            hide-details
            single-line
          />
        </template>
        <template v-slot:append-action-button-set>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }"
              ><v-btn
                fab
                x-small
                depressed
                color="primary"
                v-on="on"
                @click="submit"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn></template
            >
            {{ $t("buttons.save") }}
          </v-tooltip>
        </template>
      </form-title>
      <v-container v-for="section in sections" :key="section.label">
        <v-card-title class="employee-section-title">{{
          section.label
        }}</v-card-title>
        <v-row class="py-4" no-gutters>
          <v-col
            v-for="field in section.fields"
            :key="field.key"
            class="pa-2"
            cols="12"
            sm="6"
            ><input-widget-container
              :required="
                typeof field.required === 'boolean' ? field.required : true
              "
              v-bind="field"
            >
              <component
                :is="field.type"
                :ref="field.key"
                v-model="newValue[field.key]"
                dense
                single-line
                outlined
                background-color="white"
                :label="field.title || ''"
                :placeholder="$t('messages.pleaseInput', [])"
                :required="
                  typeof field.required === 'boolean' ? field.required : true
                "
                @newDepartment="showingDeptDialog = true"
                v-bind="field"
                v-on="field.on"
              />
            </input-widget-container>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <department-dialog
      v-if="showingDeptDialog"
      v-model="showingDeptDialog"
      :items="departments"
      :flattenItems="departmentList"
      @save="addDept"
    />
    <job-title-dialog
      v-if="showingJobTitleDialog"
      v-model="showingJobTitleDialog"
      @save="addJobTitle"
    />
  </div>
</template>
<script>
import InputWidgetContainer from "@/components/inputWidgets/comps/InputWidgetContainer";
import FormTitle from "@/components/FormTitle";
import DepartmentDialog from "./components/formInput/departmentDialog";
import JobTitleDialog from "./components/formInput/jobTitleDialog";

import editMixin from "./editMixin";

export default {
  name: "workAdminNewEmployee",
  mixins: [editMixin],
  components: {
    InputWidgetContainer,
    FormTitle,
    DepartmentDialog,
    JobTitleDialog,
  },
  mounted() {
    this.fetchDepartments();
    this.fetchJobTitles();
    this.resetPhone();
  },
  data() {
    return {
      commandMap: {
        back: this.back,
      },

      valid: false,

      newValue: {
        gender: "M",
        departmentIds: [],
        mobile: "",
        jobTitleId: "",
        invitation: false,
      },
    };
  },
  computed: {
    sections() {
      return [
        {
          label: this.$t("workAdmin.personalInfo"),
          fields: [
            this.attributes.get("lastName"),
            this.attributes.get("firstName"),
            this.attributes.get("email"),
            this.attributes.get("mobile"),
            this.attributes.get("birth"),
            this.attributes.get("gender"),
          ],
        },
        {
          label: this.$t("workAdmin.workInfo"),
          fields: [
            this.attributes.get("displayName"),
            this.attributes.get("employeeNumber"),
            this.attributes.get("joinedDate"),
            this.attributes.get("departmentIds"),
            {
              ...this.attributes.get("departmentId"),
              items: this.departmentList.filter((dept) =>
                (this.newValue.departmentIds || []).includes(dept.id)
              ),
            },
            this.attributes.get("jobTitleId"),
          ],
        },
      ];
    },
    finalEmployeeObj() {
      return {
        ...this.newValue,
        active: false,
        isInvitation: false,
        jobType: "FULL_TIME",
        status: "ON_DUTY",
        probation: "NONE",
        ...this.extractPhoneInfo(this.newValue.mobile),
      };
    },
  },
  methods: {
    onCommandHandler({ command, ...payload }) {
      return this.commandMap[command]
        ? this.commandMap[command](payload)
        : null;
    },

    submit() {
      this.$refs.employeeForm.validate();
      if (this.valid) {
        const payload = {
          url: "/work/employees",
          method: "post",
          data: this.finalEmployeeObj,
        };

        this.$store
          .dispatch("YOOV_REQUEST", payload)
          .then(() => {
            this.$router.push({
              name: "workAdminEmployees",
            });
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.employee-section-title {
  border-left: 3px solid #5fc5ce;
  padding: 0px 10px;
}
</style>
<style lang="scss">
.employee-form .v-input--is-readonly .v-input__control > .v-input__slot {
  background-color: white !important;
}
</style>
