<template>
  <v-navigation-drawer
    v-model="$store.state.mobile.navigationDrawerOpened"
    app
    clipped
    class="rounded-r-xl pt-3 app-drawer"
    width="85%"
    style="z-index: 1002"
  >
    <v-list>
      <v-list-item>
        <v-btn
          text
          fab
          small
          @click="$store.dispatch('TOGGLE_NAVIGATION_DRAWER')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-list class="flex-grow-1">
      <component
        v-for="(item, index) in menu"
        :key="index"
        :is="item.component"
        :class="item.component === 'VListItem' ? `text-header-2` : ``"
        @click="item.component === 'VListItem' ? selectItem(item) : null"
      >
        <template v-if="item.component === 'VListItem'">
          <v-list-item-avatar>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-else-if="item.component === 'VSubheader'">{{
          $t(item.title)
        }}</template>
      </component>
    </v-list>
     <app-footer />
  </v-navigation-drawer>
</template>

<script>
import appFooter from "@/components/AppFooter";
import { VListItem, VSubheader, VDivider } from "vuetify/lib";
import mixin from "./mixin";

export default {
  name: "MAppDrawer",
  components: {
    appFooter,
    VListItem,
    VSubheader,
    VDivider,
  },
  mixins: [mixin],
  methods: {
    selectItem(item) {
      this.$store.dispatch("TOGGLE_NAVIGATION_DRAWER");
      this.goTo(item);
    },
  },
};
</script>

<style lang="scss">
:root {
  font-size: 13px;
  box-sizing: border-box;
}
</style>
