<template>
    <div class="">
        <div class="d-flex align-end flex-wrap">
            <div style="font-size:12px" class="d-flex align-center">
                <div>{{field.label}}</div>
                <div class="ml-2 grey--text" style="border-bottom: 1px dashed #c5c5c5; line-height:11px">{{field.type}}</div>
            </div>
            <div class="ml-auto">
                <v-menu offsetY>
                    <template v-slot:activator="{on}">
                        <div v-on="on" :class="operatorColor" class="white--text rounded-sm d-inline" style="padding: 2px 10px;cursor: pointer; border: 1px dashed #c5c5c5; font-size: 12px">
                            {{field.operator}}
                            <v-icon dark style="min-width:0px; width:0px" class="pl-2">
                                mdi-chevron-down
                            </v-icon>
                        </div>
                    </template>
                    <v-card style="" tile flat outlined>
                        <v-list dense class="pa-0" style="font-size: 12px;">
                            <v-list-item-group v-model="field.operator" color="info" mandatory>
                                <v-list-item :value="opt.id" v-for="opt in operators" :key="opt.id" dense style="border-bottom: #eeeeee 1px solid; min-height: 22px">
                                    {{opt.label}}
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
        </div>
        <div class="align-end d-flex mt-2" v-if="field.operator!='empty' && field.operator!='notEmpty'" style="width:200px">
            <v-text-field v-model="field.filterVal.minVal" type="number" class="filterTextField" dense hide-details height="20" style="font-size:13px;"></v-text-field>
            <div v-show="field.operator=='range'" class="mx-2" style="line-height: 11px; font-size:12px">至</div>
            <v-text-field v-show="field.operator=='range'" v-model="field.filterVal.maxVal" type="number" class="filterTextField" dense hide-details height="20" style="font-size:13px;"></v-text-field>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            field: Object
        },
        data(){
            return{
                size: 1,
                operator: 'equal',
                operators: [
                    {
                        id: 'equal',
                        label: 'equal',
                        color: 'success'
                    },
                    {
                        id: 'notEqual',
                        label: 'notEqual',
                        color: 'error'
                    },
                    {
                        id: 'greaterThan',
                        label: 'greaterThan',
                        color: 'success'
                    },
                    {
                        id: 'lessThan',
                        label: 'lessThan',
                        color: 'error' 
                    },
                    {
                        id: 'range',
                        label: 'range',
                        color: 'warning'
                    },
                    {
                        id: 'empty',
                        label: 'empty',
                        color: 'secondary'
                    },
                    {
                        id: 'notEmpty',
                        label: 'notEmpty',
                        color: 'secondary'
                    }
                ],
                value: {
                    maxVal: null,
                    minVal: null,
                }
            }
        },
        computed:{
            operatorColor(){
                return this.operators.find(item=>item.id==this.field.operator).color
            }
        },
        methods:{ 
        }
    }
</script>

<style>
    .filterTextField .v-input__control .v-input__slot{
        min-height: 20px !important;
    }
</style>