<template>
  <div>
    <label>{{ label }}</label>
    <draggable :list="propertyValue"
               handle=".drag-handle">
        <number-rule-item
            v-for="(rule,index) in propertyValue"
            :key="index"
            :rule="rule"
            :index="index"
            :options="propertyConfig.customOptions"
            :availableFieldInfos="availableFieldInfos"
            @editIdRule="editIdRuleHandler(index)"
            @deleteRule="deleteRuleHandler"></number-rule-item>
    </draggable>
    <v-menu offset-y>
        <template  v-slot:activator="{ on, attrs }">
            <div
            v-bind="attrs"
            v-on="on"
            class="primary--text mt-1 ml-6"
            small
            style="font-size:12px;"
            >
                <v-icon style="transform: translateX(-5px)" size="15" color="primary">mdi-plus</v-icon>
                <span>{{$t('widgets.properties.addRule')}}</span>
            </div>
        </template>
        <v-card outlined>
            <ul class="ruleList">
                <li @click="addRule(rule.value)"
                    class="px-2 py-1"
                    v-for="(rule) in filteredRules"
                    :key="rule.value">
                  <span style="font-size:13px">{{$t('widgets.properties.'+rule.labelTag)}}</span>
                </li>
            </ul>
        </v-card>
    </v-menu>
    <DialogIdRule
        v-if="bl_dialog_idRule"
        v-model="bl_dialog_idRule"
        :setting="propertyValue[selected_index].value"
        :periodOptions="periodOptions"
        :numberingOptions="numberingOptions"
        v-on:updateIdRule="updateIdRule"
    />
  </div>
</template>

<script>
import mixin from './mixin'
import DialogIdRule from './dialogField/DialogIdRule.vue'
import draggable from 'vuedraggable'
import numberRuleItem from './comps/NumberRuleItem'
import {autoNumberingFieldType} from "@/const/fieldConstants"

export default {
  mixins: [mixin],
  props:{
    propertyConfig: Array
  },
  components:{
    DialogIdRule,
    draggable,
    numberRuleItem
  },
  data () {
   return {
      bl_showCreatedAtOption: true,
      bl_dialog_idRule: false,
      selected_index: 0
    }
  },
  computed:{
    numberingOptions () {
      return this.propertyConfig.customOptions.numberingOptions
    },
    rulesOptions () {
      return this.propertyConfig.customOptions.rulesOptions
    },
    periodOptions () {
      return this.propertyConfig.customOptions.periodOptions
    },
    // filteredWidgets(){
    availableFieldInfos(){
        return this.formFieldInfos.filter(item=> autoNumberingFieldType.includes(item.type))
   }
,    filteredRules(){
        if(this.bl_showCreatedAtOption)
            return this.rulesOptions.filter(item=>item.value!="id")
        else
            return this.rulesOptions.filter(item=>item.value!='createdAt' && item.value!='id')
    },
  },
  methods: {
    addRule(value){
        var rule = this.filteredRules.find(item=>item.value==value)
        this.propertyValue.push({rulesOption: rule.value, value:(rule.hasOwnProperty('default'))?rule.default:null })
        if(value == 'createdAt') this.bl_showCreatedAtOption = false
    },
    editIdRuleHandler(index){
        this.bl_dialog_idRule = true
        this.selected_index = index
    },
    updateIdRule(setting){
        this.bl_dialog_idRule = false
        var res = this.propertyValue.find(item=>item.rulesOption=='id')
        if(res)
          res.value = setting
        // console.log("this.propertyValue",this.propertyValue)
        // this.propertyValue[0].value = setting
    },
    deleteRuleHandler(index){
        this.propertyValue.splice(index, 1)
    },
  }
}
</script>
<style scoped>
.ruleList li:hover{
    background-color: #1976d2;
    cursor: pointer;
    color: white;
}
</style>
