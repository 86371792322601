<template>
  <v-flex>
    <v-row no-gutters>
      <label>{{ label }}</label>
    </v-row>

    <v-row no-gutters align="center" class="mt-0">
        <v-col md="10">
      <v-menu v-model="showMenu">
        <template v-slot:activator="{ on }">
          <v-card
          flat
          height="30"
            class="custom_input py-1 px-1"
            v-on="on"
            @click:append="onClick"
            @click="openSelection"
            >
            {{
              typeof propertyValue === "object"
                ? propertyValue.text
                : propertyValue
            }}
            </v-card>
        </template>
        <v-list>
            <select-item v-for="item in items" :key="item.value" @click="onSelect" :value="item" />
        </v-list>
      </v-menu>
        </v-col>
        <v-col>
      <v-icon @click="onClick">mdi-calendar</v-icon>
        </v-col>

    </v-row>
      <date-time-dialog
        v-if="showDialog"
        v-model="showDialog"
        @confirm="onConfirm"
      />
  </v-flex>
</template>
<script>
import mixin from "./mixin";
import DateTimeDialog from "@/components/dialogs/DateTimeDialog";
import SelectItem from "./comps/SelectItem";
export default {
  mixins: [mixin],
  components: {
    DateTimeDialog,
    SelectItem,
  },
  data() {
    return {
      showDialog: false,
      showMenu: false,
    };
  },
  computed: {
    items() {
      let result = [];
      let systemField = [];
      systemField.push({
        text: this.$t("widgets.properties.createdAt"),
        value: "createdAt",
      });
      systemField.push({
        text: this.$t("widgets.properties.updatedAt"),
        value: "updatedAt",
      });

      result = this.formFieldInfos
        .filter((field) => field.type === "date")
        .map((field) => ({
          text: field.label,
          value: field.fieldId,
        }));

      result = result.concat(systemField);
      return result;
    },
  },
  methods: {
    onClick() {
      this.propertyValue = "";
      this.showDialog = true;
    },
    onConfirm(val) {
      this.propertyValue = val;
      this.showDialog = false;
    },
    onSelect(val) {
      this.propertyValue = val;
      console.log(typeof this.propertyValue);
    },
    openSelection(e) {
      this.showMenu = true;
    },
  },
};
</script>
<style scoped>
.custom_input {
  border: rgba(0, 0, 0, 0.5) 1px solid ;
  border-radius: 4px;
}
</style>
