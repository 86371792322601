<template>
  <div>
    <component
      :is="target"
      :node="node"
      :tree="tree"
      :offset="offset"
      :workflowName="workflowName"
      :pendingLabel="$t('workflow.label.setNodeNow')"
    />
    <div v-if="fieldSize > 0" class="workflow-shortview-remark">
      {{ $t('workflow.modules.createRecord.label.filledField', { size: fieldSize }) }}
    </div>
  </div>
</template>

<script>
import { get, values, compact } from 'lodash';
import PendingSetup from './../pending';
import CreateOne from './createOne';
import CreateFromSource from './createFromSource';
import Mixin from './../mixin';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  data() {
    return {
      lookup: {
        createOne: 'CreateOne',
        createFromSource: 'CreateFromSource',
        empty: 'PendingSetup',
      },
    }
  },
  computed: {
    workflowName() {
      const { properties } = this.node;
      return get(properties, 'worksheet.label', '');
    },
    fieldData() {
      return get(this, 'node.properties.data') || {};
    },
    fieldSize() {
      return compact(values(this.fieldData)).length;
    },
    target() {
      const hookEvent = get(this, 'node.properties.hookEvent');
      return hookEvent ? this.lookup[hookEvent] : this.lookup.empty;
    }
  },
  components: {
    CreateOne,
    CreateFromSource,
    PendingSetup,
  },
};

</script>

<style scoped>
  
</style>
