<template>
    <v-sheet
        tabindex="0"
        title="Click to grap a file from your PC!"
        :color="fileDropColor"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
        width="100%"
        min-height="120"
        :class="{'has-error': hasError}"
        style=";position:relative;"
        class="file-drop pr-2 pt-2 pb-1 pl-1">
        <slot>


          <v-hover v-for="(file,index) in nornmalize(files)" :key="file.mediaId">
            <template v-slot:default="{ hover }">
              <div @click="preview(file)" class="pa-0 ml-1 v-card" 
                style="cursor: pointer; height: 120px; width: 160px; display:inline-block;">
                <img v-if="file.thumbnailUrl&&isAccessibleImage(file)" :src="file.thumbnailUrl" class="show-image"/>
                <!-- <img v-if="file.mediaType.indexOf('image')!= -1" :src="getThumbnailPath(file.mediaId)" class="show-image"/> -->
                <div v-else style="position: relative; float:left; width:100%; height: 100%" class="white d-flex justify-center align-center">
                  <!-- {{(file.mediaType)}} -->
                  <media-type-icon
                    :media="file"
                    :size="100"></media-type-icon>
                  <!-- <v-icon v-if="file.mediaType.indexOf('.document')!= -1 || file.mediaType.indexOf('msword')!= -1" color="primary" size="100">mdi-file-word-outline</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('.sheet')!= -1 || file.mediaType.indexOf('ms-excel')!= -1" color="success" size="100">mdi-file-excel-outline</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('.presentation')!= -1 || file.mediaType.indexOf('ms-powerpoint')!= -1" color="red" size="100">mdi-file-powerpoint-outline</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('/plain')!= -1" color="grey" size="100">mdi-file-document-outline</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('/pdf')!= -1" color="red" size="100">mdi-file-pdf-outline</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('compressed')!= -1" color="orange darken-4" size="100">mdi-folder-zip-outline</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('video/')!= -1" color="purple darken-2" size="110">mdi-video-outline</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('audio/')!= -1" color="yellow darken-2" size="100">mdi-volume-high</v-icon>
                  <v-icon v-else-if="file.mediaType.indexOf('xml')!= -1" color="grey" size="100">mdi-file-code-outline</v-icon>
                  <v-icon v-else color="grey" size="100">mdi-file</v-icon> -->
                </div>

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    class="file-drop-overlay"
                  >
                    <!-- {{fileId}} -->
                    <div class="d-flex align-start flex-column" style="height: 100% !important; width:100%">
                      <div class="pa-1 file-name mb-auto" style="width:100%;">{{file.originalName}}</div>
                      <div class="pa-1 ml-auto">
                        <v-btn v-if="!readonly && deletable" class="ml-1" light height="22" style="min-width: 20px !important; width: 20px" @click.stop.prevent="deleteImage(file.mediaId)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </div>
              <!-- <div class="pa-0 pl-2" style="display:inline-block;">
                <img :src="getThumbnailPath(fileId)"
                  class="show-image"/>
                  <v-btn icon small class="d-none white--text delete-button" @click="deleteImage(fileId)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      :color="(index==1)? 'red': 'green'"
                    >
                      <v-btn>See more info</v-btn>
                    </v-overlay>
                </v-fade-transition>
              </div> -->
            </template>
          </v-hover>
        </slot>
      <!--<input-->
          <!--type="file"-->
          <!--accept="text/xml"-->
          <!--style="display:none"/>-->
      <div class="drag-mask flex-column justify-center align-center"
           :style="dragMaskStyle"
           :class="{'drag-over':dragOver||maskDragOver||(fileIds && fileIds.length===0) || !fileIds, 'has-attachment': (fileIds && fileIds.length>0)}">
        <div
           class="grey--text d-flex flex-column align-center justify-center" style="width:100%; height:100%">
          <v-icon style="font-size: 50px;" class="grey--text">mdi-download</v-icon>
          <h4>{{ $t('messages.dragAndDropHere') }}</h4>
        </div>
      </div>
      <div
        v-if="!readonly"
        ref="dragMask"
        @dragenter="onMaskDragEnter"
        @dragleave="onMaskDragLeave"
        @dragover="onMaskDragOver"
        @drop="onMaskDrop"
        @click="uploadFile"
        class="drag-mask flex-column justify-center align-center"
        :class="{'drag-over':dragOver||maskDragOver, 'has-attachment': (fileIds && fileIds.length>0 )}">
      </div>
      <!--dragOver: {{ dragOver }}<br/>-->
      <!--maskDragOver: {{ maskDragOver }}-->
      <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
      <info-dialog
        ref="infoDialog"></info-dialog>
    </v-sheet>
</template>

<script>
import { isArray } from 'lodash';
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import MediaHelper from '@/helpers/MediaHelper'
import InfoDialog from "../dialogs/InfoDialog";
import mediaTypeIcon from '@/components/MediaTypeIcon';
import EventBus from '@/event-bus.js'

export default {
  components: {
    InfoDialog,
    confirmDeleteDialog,
    mediaTypeIcon
  },
  computed: {
    dragMaskStyle () {
      const vm = this
      var result = { borderRadius: '5px' };
      if (vm.isLayout) {
        result['backgroundColor'] = 'transparent !important'
        result['border'] = 'rgba(0, 0, 0, 0.38) 1px solid !important'
      } else {
        result['backgroundColor'] = 'transparent !important'
      }
      return result
    },
    fileDropColor () {
      return "grey lighten-3"

    },
    isPublic(){
      return this.$store.getters.isPublicRoute
    }
  },
  // components: {DraggableDiv},
  data () {
    return {
      dragOver: false,
      maskDragOver: false,
      count: 0,
    }
  },
  props: {
    fileIds: Array,
    files: Array,
    hasError: Boolean,
    isLayout: Boolean,
    fileTypes: Array,
    acceptExt: {
      type: Array,
      default: ()=> []
    },
    readonly:{
      type: Boolean,
      default: false
    },
    deletable:{
      type: Boolean,
      default: true
    }
  },
  // mounted () {
  //   const vm = this
  //   // vm.initDropZone()
  // },
  methods: {
    isAccessibleImage(media) {
      return MediaHelper.isAccessibleImage(media);
    },
    nornmalize(files) {
      return isArray(files) ? files : ( files ? [ files ] : [] ); 
    },
    preview(media) {
      console.log('FileDCrop.preview: media: ', media);
      if (media && media.objectKey) {
        EventBus.$emit('onLoading');
        MediaHelper.previewMedia(media, this.isPublic)
      } else {
        this.$toast.error(this.$t('messages.fileIsMissing'), {duration: 0})
      }
    },
    uploadFile () {
      this.$emit('onCommand', {command: 'triggerUpload'})
    },
    getThumbnailPath (mediaId) {
      const vm = this
      return vm.$store.getters.appHost + '/medias/' + mediaId + '/thumbnail'
    },
    onDragEnter (e) {
      const vm = this
      // console.log('onDragEnter: dragOver = ' + (vm.dragOver ? 'yes' : 'no') +
      //   ', maskDragOver = ' + (vm.maskDragOver ? 'yes' : 'no'))
      vm.dragOver = true
      e.preventDefault()
    },
    onDragOver (e) {
      e.preventDefault()
    },
    onDragLeave (e) {
      const vm = this
      this.dragOver = false
      console.log('onMaskDragLeave: dragOver = ' + (vm.dragOver ? 'yes' : 'no') +
        ', maskDragOver = ' + (vm.maskDragOver ? 'yes' : 'no'))
      e.preventDefault()
    },
    onMaskDragEnter (e) {
      const vm = this
      console.log('onMaskDragEnter: dragOver = ' + (vm.dragOver ? 'yes' : 'no') +
        ', maskDragOver = ' + (vm.maskDragOver ? 'yes' : 'no'))
      vm.maskDragOver = true
      e.preventDefault()
    },
    onMaskDragOver () {
      const vm = this
      console.log('onMaskDragOver: dragOver = ' + (vm.dragOver ? 'yes' : 'no') +
        ', maskDragOver = ' + (vm.maskDragOver ? 'yes' : 'no'))
    },
    onMaskDragLeave (e) {
      const vm = this
      console.log('onMaskDragLeave: dragOver = ' + (vm.dragOver ? 'yes' : 'no') +
        ', maskDragOver = ' + (vm.maskDragOver ? 'yes' : 'no'))
      vm.maskDragOver = false
      vm.dragOver = false
      e.preventDefault()
    },
    onMaskDrop (e) {
      const vm = this
      e.preventDefault()
      vm.count++
      vm.dragOver = false
      vm.maskDragOver = false
      let files = [];
      //if (!Array.isArray(files)) files =[files];
      //console.log(files)
      var bl_valid = true

console.log('check accepted ext');
      if(vm.acceptExt.length>0){
        for(let i =0; i < e.dataTransfer.files.length && bl_valid;i++){
          if(!vm.acceptExt.includes(e.dataTransfer.files[i].name.split('.').pop().toLowerCase()))  bl_valid = false
          else files.push(e.dataTransfer.files[i])
        }
      }
      if(bl_valid)
        vm.$emit('onCommand', {
          command: 'uploadFiles',
          files
        })
      else{
        this.$emit('input', true)
      }
    },

    deleteImage (fileId) {
      const vm = this
      console.log('deleteImage: fileId = ' + fileId);
      vm.$refs.confirmDeleteDialog.confirm(() => {
        vm.$emit('onCommand', {
          command: 'removeAttachment',
          fileId: fileId
        })
      })
    },
  }
}
</script>

<style>
  .image-item {
    min-width: 24px;
    min-height: 24px;
  }
  .image-item .delete-button {
    display: none;
    position: absolute;
    top: 10%;
    right: 1.2em;
    background-color: rgba(255,0,0,0.5) !important;
  }
  .image-item:hover .delete-button {
    display: inline-block !important;
  }

  .file-drop:hover .drag-mask:not(.has-attachment) {
    cursor: pointer;
  }

  .file-drop {
    border: 0;
    border-radius: 5px;
  }

  .v-application .file-drop {
    border-color: darkgray !important;
  }

  .v-application .file-drop.has-error {
    border-color: red !important;
  }
  .file-drop:hover .drag-mask:not(.has-attachment),
  .drag-mask.drag-over {
    display: block !important;

  }

  .drag-mask {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .show-image {
    /* max-height: 160px; */
    height: 120px;
    /* max-width: 160px; */
    width:160px;
    /* vertical-align: top; */
    object-fit: cover;
  }
  .file-name{
    line-height: 1;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    /* max-width:100%; */
    /* overflow: hidden */
  }
  .file-drop-overlay .v-overlay__content{
    width:100%;
    height: 100%;
  }
</style>
