<template>
    <v-dialog v-model="show" @click:outside="closeDialog" style="z-index: 1030 !important;">
        <v-card class="rounded">
            <v-card-title>{{ $t('widgets.departments') }}</v-card-title>
            <v-text-field class="px-3" outlined small v-model="searchText" dense hide-details="" append-icon="mdi-magnify"></v-text-field>
            <v-card-text style="max-height: 60vh; overflow-y: auto;">
                <v-treeview v-model="selectedIds" :search="searchText" :items="departments" selectable dense item-text='label' item-key='_id'>
                </v-treeview>
            </v-card-text>
            <v-card-actions class="d-flex w-100 justify-end">
                <v-btn text @click="closeDialog">Cancel</v-btn>
                <v-btn color="primary" @click="appendDepartments">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data() {
        return {
            show: false, 
            searchText: '', 
            departments: [],
            selectedDepartments: [],
            selectedIds: [], 
            isLoading: false
        }
    },
    computed: {
        departmentObj() {
            return this.departments.reduce((obj, item) => {
                obj[item._id] = item;
                return obj;
            }, {})
        }
    }, 
    methods: {
        openDialog(_departments) {
            this.selectedDepartments = _departments;
            this.selectedIds = _departments.map(d => d._id);
            this.show = true;
        },
        closeDialog() {
            this.show = false;
            this.searchText = '';
        },
        fetchDepartments() {
            this.isLoading = true;
            const getParms = {
                urlCommand: `/departments/getAllDepartments`
            };

            this.$store.dispatch('AUTH_GET', getParms).then( res => {
                this.departments = res;
                this.isLoading = false;
            });
        },
        appendDepartments() {
            this.selectedDepartments = [];
            this.selectedIds.forEach( id => {
                this.selectedDepartments.push(this.departmentObj[id] );
            });
            this.$parent.updateDepartment(this.selectedDepartments);
            this.closeDialog();
        }
    }, 
    mounted() {
        this.fetchDepartments();
    },
}
</script>