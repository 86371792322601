<template>
  <div>
    <div class="workflow-setting-form-each-item">
      <SourceTypeSelect
        :value="setting.sourceType"
      />
    </div>
    <div v-if="isDirectlyPick">
        <v-layout v-if="!!setting.sourceType" align-center justify-start class="workflow-setting-form-each-item workflow-setting-form-waiting-set">
          {{ $t(`workflow.modules.${setting.sourceType}.message.staticHints`) }}
        </v-layout>
        <div class="workflow-setting-form-each-item">
          <WorksheetSelect
            :worksheet="setting.worksheet"
            fetchByNode
            :label="$t(`workflow.modules.${setting.sourceType}.label.placeholder`)"
            name="worksheet"
            :placeholder="$t('workflow.modules.retrieveRecord.label.placeholder')"
          />
        </div>
        <div v-if="!!setting.worksheet">
          <div class="workflow-setting-form-each-item">
            <FormElement
              :label="$t(`workflow.modules.${setting.sourceType}.label.directData`)"
            >
              <div @click="showSelector" class="workflow-setting-form-static-selector">
                {{ $t(`workflow.modules.${setting.sourceType}.label.selected`, { size: records.length }) }}
              </div>
            </FormElement>
          </div>
        </div>
    </div>
    <div v-else>
      <v-layout v-if="!!setting.sourceType" align-center justify-start class="workflow-setting-form-waiting-set">
        {{ $t('workflow.modules.retrieveRecord.placeholder.remark') }}
      </v-layout>
      
      <div v-if="!!setting.sourceType" class="workflow-setting-form-each-item">
        <DataSourceSelect
          v-if="isDataSourceType"
          :value="setting.dataSource"
          :label="$t(`workflow.modules.${setting.sourceType}.label.placeholder`)"
          name="dataSource"
          :fetchForRelationship="isRelationPick"
          :placeholder="$t('workflow.modules.retrieveRecord.label.placeholder')"
        />
        <WorksheetSelect
          v-else
          :worksheet="setting.worksheet"
          fetchByNode
          :label="$t(`workflow.modules.${setting.sourceType}.label.placeholder`)"
          name="worksheet"
          :placeholder="$t('workflow.modules.retrieveRecord.label.placeholder')"
        />
      </div>
      <div v-if="!!setting.dataSource || !!setting.worksheet">
        <div class="workflow-setting-form-each-item" v-if="isRelationPick">
          <RelationshipFieldSelect
            :field="setting.relationshipField"
            :worksheet="worksheet"
            :dataSource="setting.dataSource"
            :label="$t(`workflow.modules.retrieveRecord.label.selectRelationshipField`)"
            name="relationshipField"
            :placeholder="$t('workflow.modules.retrieveRecord.placeholder.selectRelationshipField')"
          />
        </div>
        <div class="workflow-setting-form-each-item">
          <QuotaTypeSelect :outputMultiple="setting.outputMultiple" />
          <QuotaSelect v-if="setting.outputMultiple !== 'single'" :quota="setting.retrieveQuota" :random="setting.retrieveRandomOne" />
        </div>
        <div class="workflow-setting-form-each-item">
          <FormElement
              :hints="$t('workflow.modules.retrieveRecord.placeholder.randomPick')"
            >
              <v-checkbox
                v-model="setting.randomResult"
                :label="$t('workflow.modules.retrieveRecord.label.randomPick')"
              />
          </FormElement>
        </div>
        <div class="workflow-setting-form-each-item">
          <FormElement
              :hints="$t('workflow.modules.retrieveRecord.placeholder.lockData')"
            >
              <v-checkbox
                v-model="setting.lockData"
                :label="$t('workflow.modules.retrieveRecord.label.lockData')"
              />
          </FormElement>
        </div>
        <div class="workflow-setting-form-each-item">
          <FormElement
            :label="$t('workflow.modules.retrieveRecord.label.filteringRule')"
          >
            <FilterCondition
              :list="list"
              :tableList="list"
              noLabel
              directListSearch
              :worksheet="setting.worksheet"
              hints="workflow.modules.retrieveRecord.placeholder.filteringRule"
            />
          </FormElement>
        </div>
      </div>
      
    </div>
    <div v-if="(isDirectlyPick && !!setting.worksheet) || (!!setting.dataSource || !!setting.worksheet)">
      <div row class="workflow-setting-form-each-item">
        <OrderingSelect :sorting="setting.sorting" :fetchForRelationship="isRelationPick" />
      </div>
      <div class="workflow-setting-form-each-item">
        <ConintueSetting :noCreate="isDirectlyPick" :proceedCondition="setting.proceedCondition" />
      </div>
      <div class="workflow-setting-form-each-item">
        <shareable-link-settings/>
      </div>
    </div>
    <div v-if="!isDirectlyPick && needToCreateNewOne" class="workflow-setting-form-each-item">
      <v-divider />
      <FormElement
        :label="$t('workflow.modules.retrieveRecord.label.createNewOne')"
      >
        <CreateRecordForm :worksheet="getWorksheet()" fetchByNode />
      </FormElement>
    </div>
    <SelectRelatedRecordsDialog
      v-if="!!setting.sourceType"
      ref="recordSelector"
      normalPopup
      :popTitle="$t(`workflow.modules.${setting.sourceType}.label.directData`)"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import FormElement from './../shared/formElement';
import WorksheetSelect from './../shared/worksheetSelect';
import CreateRecordForm from './../createRecord/form/';
import QuotaTypeSelect from './../shared/quotaTypeSelect';
import DataSourceSelect from './../shared/dataSourceSelect';
import ShareableLinkSettings from './../shared/shareableLinkSettings';

import SelectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
import RelationshipFieldSelect from './../shared/relationshipFieldSelect';
import QuotaSelect from './quota';
import SourceTypeSelect from './../shared/sourceTypeSelect';
import FilterCondition from './../shared/filterCondition';
import OrderingSelect from './../shared/ordering';
import ConintueSetting from './proceed';
import Mixin from './../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    DataSourceSelect,
    SourceTypeSelect,
    FilterCondition,
    FormElement,
    QuotaSelect,
    WorksheetSelect,
    OrderingSelect,
    SelectRelatedRecordsDialog,
    CreateRecordForm,
    ConintueSetting,
    QuotaTypeSelect,
    RelationshipFieldSelect,
    ShareableLinkSettings
  },
  computed: {
    isDirectlyPick() {
      return this.setting.sourceType === 'retrieveRecordDirectlyPick';
    },
    records() {
      return this.setting.records || [];
    },
    dataSource() {
      return this.setting.dataSource;
    },
    sourceType() {
      return this.setting.sourceType;
    },
    isRelationPick() {
      const { sourceType } = this.setting;
      // console.log(sourceType && sourceType === 'retrieveRecordFromRelationship');
      return sourceType && sourceType === 'retrieveRecordFromRelationship';
    },
    // worksheet() {
    //   return this.getWorksheet() || this.dataSource.form;
    // },
    worksheet() {
      const { dataSource } = this;
      if (dataSource) {
        if (dataSource.properties && dataSource.properties.worksheet) {
          return dataSource.properties.worksheet;
        } else if (dataSource.form) {
          return dataSource.form
        }
      }
      return this.getWorksheet();
    },
    needToCreateNewOne() {
      return this.setting.proceedCondition === 'createAndContinue';
    },
    list() {
      return this.isRelationPick ? this.$store.getters.getFormRelationshipSelectionsFields.children : 
      this.$store.getters.getFormSelectionsFields.children;
    },
    dataSources() {
      return this.$store.getters.getDependencies || [];
    },
    loading() {
      return this.$store.getters.fetchingFormSelectionsFields;
    },
    isDataSourceType() {
      const { sourceType } = this.setting;
      
      switch (sourceType) {
        case 'retrieveRecordFromTableSource':
          return false;
        default:
          return true;
      }
    },
    sourceTypeName() {
      const { sourceType } = this.setting;
      switch (sourceType) {
        case 'retrieveRecordFromTableSource':
          return 'dataSource';
        default:
          return 'worksheet';
      }
    }
  },
  mounted() {
    // console.log(this.data);
    // if (!!this.setting.dataSource) {
    // this.fetchFieldInfo();
    // }
    // console.log(this.setting.dataSource);
  },
  methods: {
    showSelector() {
      const { worksheet, workflow } = this;
      const dataTableId = worksheet._id || worksheet;
      this.$refs.recordSelector.open(
        { 
          dataSource: {
            dataTableId,
            appId: this.appId,
            teamId: workflow.teamId,
          },
          inclusive: true,
          parentFormId: dataTableId,
          formInfo: worksheet,
          multiple: true,
          selected: this.records,
        },
        this.onSelect,
        this.onCancel,
      );
    },
    onSelect(payload) {
      this.$set(this.setting, 'records', payload)
      this.$refs.recordSelector.close();
    },
    onCancel() {
    },
    // fetchSource() {
    //   const { worksheet, sourceType } = this.setting;
      // switch (sourceType) {
      //   case 'retrieveRecordFromDataSource':
      //     break;
      //   case 'retrieveRecordFromTableSource':
      //     this.fetchFieldInfo();
      //     break;
      //   case 'retrieveRecordFromRelationship':
      //     break;
      //   case 'retrieveRecordsFromManualNode':
      //     break;
      //   default:
          
      // }
    // }
    // server response format
    // fetchFieldInfo() {
    //   const initialFormId = this.getWorksheet();
    //   const formId = this.setting.dataSource.form || initialFormId;
    //   if (formId) {
    //     this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
    //       appId: this.appId,
    //       formId
    //     });
    //   }
    // }
  },
  watch: {
    // dataSource: {
    //   handler(v) {
    //     this.$set(this.setting, 'worksheet', null);
    //   },  
    //   deep: true,
    // },
    sourceType: {
      handler(v, o) {
        if (v !== o) {
          this.$set(this.setting, 'dataSource', null);
          this.$set(this.setting, 'worksheet', null);
        }
      },  
      deep: true,
    },
    // setting: {
    //   handler(v) {
    //     console.log(v);
    //   },
    //   deep: true,
    // },
  }
};

</script>

<style scoped>

</style>
