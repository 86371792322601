<template>
<v-card class="mb-2">
  <v-card-title class="py-1 px-2 grey lighten-2 d-flex flex-row mb-2 align-center">
    <v-icon class="mr-3" v-if="icon">{{ icon }}</v-icon>
    {{ formInfo.label }}
    <v-spacer></v-spacer>
      <!-- color: {{ formConfig.color }}
      class: {{ getColorClass(formConfig.color) }}<br/>
      style: {{ getColorStyle(formConfig.color) }} -->
      <v-btn small class="form-color mr-3 cursor-pointer"
        :disabled="colorBasedOn!=='byForm'"
        :class="getColorClass(formConfig.color)"
        @click="selectColor"
        :style="getColorStyle(formConfig.color)">
        <v-icon :class="getColorClass(formConfig.color)"
        style="margin-top:-20px;">mdi-settings-helper</v-icon>
      </v-btn>
    <v-btn x-small right fab color="error" @click="$emit('delete')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-card-title>
  <v-card-text>

    <v-row>
      <v-col cols="4" v-for="(setting) in settings" :key="setting.key">
        <label>{{setting.label}}</label>
        <field-selection-menu
          class="w-100"  
          :items="setting.items"
          :hasError="hasError[setting.key]"
          :selectedId="formConfig[setting.key]"
          :label="setting.titleKey || 'label'"
          @input="val=>{updateField(setting.key, val)}"
        ></field-selection-menu>
      </v-col>
    </v-row>

  </v-card-text>    
  <color-dialog
    ref="colorDialog"></color-dialog>
</v-card>  
</template>

<script>
import appColorMixin from '@/mixins/appColorMixin'
import fieldSelectionMenu from '@/pages/inputPanel/comps/FieldSelectionMenu'
import colorDialog from '@/components/dialogs/ColorDialog'

export default {  
  name: 'calendarFormConfig',
  mixins: [appColorMixin],
  components: {
    fieldSelectionMenu,
    colorDialog 
  },
  props: {
    colorBasedOn: String,
    formConfig: Object,
    icon: String
  },
  data() {
    return {
      hasError: {
      }
    }
  },
  computed: {
    formInfo () {
      return this.formConfig.formInfo
    },

    fieldInfos () {
      return this.formInfo ? this.formInfo.fieldInfos : []
    },
    dateTimeFields () {
      return this.getFieldOfType('date').filter( info => ['date','datetime'].includes(info.properties.fieldType))
    },
    views(){
      return this.formInfo.views
    },
    settings(){
      return [{
        key:"titleField",
        label: this.$t("calendar.titleField"),
        items:  this.getFieldOfType(['text','textCombination']),
      },{
        key:"startField",
        label: this.$t("calendar.startDateTimeField"),
        items: this.dateTimeFields,        
      },{
        key:"endField",
        label: this.$t("calendar.endDateTimeField"),
        items:  this.dateTimeFields,        
      },{
        key:"categoryField",
        label: this.$t("calendar.categoryField"),
        items:  this.getFieldOfType('singleSelection'),                
      },{
        key:"viewId",
        label: this.$t("calendar.view"),
        items:  this.views,  
        titleKey:"title"  
      }]
    }

  },
  methods: {
    selectColor () {
      const vm = this
      vm.$refs.colorDialog.open({color: vm.formConfig.color}, newColor => {
        vm.$set(vm.formConfig, 'color', newColor)
      })
    },

    updateField (fieldName, val) {
      this.$set(this.formConfig, fieldName, val)
    },

    getFieldOfType(type) {
      const vm = this
      return vm.fieldInfos.filter(info =>
        Array.isArray(type) ?
          type.includes(info.type) :
          (info.type === type)
      )
    },

    validated() {
      let result = true;
      const keysToCheck = ["titleField", "startField"];
      keysToCheck.forEach(key => {
        if (!this.formConfig[key]) {
            this.$set(this.hasError, key, true);
            result = false
        } else {
           this.$set(this.hasError, key, false);
           
        }
      });
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
.form-color {
  height: 24px;
  line-height: 1;
  min-width:50px;
  text-align: center;
}
</style>