<template>
  <v-card class="px-12 pt-2 " flat style="overflow: auto;">
    <v-card-title class="tabeSetupTitle">{{ $t("tableSetup.functionToggle") }}</v-card-title>
    <v-card-text v-if="formSettings">
      <div
        v-for="[category, settings] in Object.entries(formSettings)"
        :key="category"
      >
        <v-subheader class="pa-0 tabeSetupSubtitle font-weight-bold">{{ $t(`functionToggle.${category}`) }}</v-subheader>
        <v-list class="mb-10 pa-0 bottom-border-none" max-width="50%" outlined>
          <FunctionRow
            v-for="[setting, value] in Object.entries(settings)"
            :key="setting"
            :setting="setting"
            :category="category"
            :value="value"
            @update="onUpdate"
          >
            <template v-slot:description>
              <v-tooltip v-if="descriptionMap[setting]" top max-width="240">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="grey lighten-1" v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{descriptionMap[setting]}}</span>
              </v-tooltip>
            </template>
          </FunctionRow>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import FunctionRow from "./comps/FunctionRow";
export default {
  components: {
    FunctionRow,
  },
  data() {
    return {
      descriptionMap: {
        showAddButton: this.$t('functionToggle.showAddButtonDescription'),
        allowInline:this.$t('functionToggle.allowInlineDescription'),
      },
    };
  },
  computed: {
    formSettings() {
      return this.$store.getters.currentForm
        ? {
            formFunction: this.$store.getters.currentForm.formFunction,
            viewFunction: this.$store.getters.currentForm.viewFunction,
            recordFunction: this.$store.getters.currentForm.recordFunction,
          }
        : null;
    },
  },
  methods: {
    onUpdate(payload) {
      const dispatchData = {
        ...payload,
        formId: this.$route.params.itemId,
      };

      this.$store.dispatch("UPDATE_FORM_SETTING", dispatchData).then(()=> {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_table_setup_menu",
            data: {
              function_button: this.$sensors.events.plus_sheet_mani.function_toggle.EDIT_FUNCTION,
            }
          });
      });
    },
  },
};
</script>
<style scoped>
.bottom-border-none {
  border-bottom: none;
}
</style>
