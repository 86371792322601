import Vue from 'vue'
import { routes as types } from "../../action-types";

const state = {
  prevRoute: null,
  name:''
}

const getters = {
  prevRoute: (state) => {
    return state.prevRoute
  },
  isPublicRoute: (state) => {
    return ["PublicForm", "PublicQuery", "PublicEdit"].includes(state.name)
  }
}

const mutations = {
  savePrevRoute: (state, payload) => {
    state.prevRoute = payload
  },
  SET_ROUTE (state, payload){
    state.name = payload
  },
}

const actions = {
  [types.SET_DOCX_CACHE]({ dispatch }, payload){
    return new Promise((resolve, reject)=> {
      const postData = {
        urlCommand:'/cache/setCache',
        data: {
          key: 0,
          value: JSON.stringify({
            ...payload
          })
        }
      }
      dispatch('AUTH_POST', postData)
      .then(res => {
        if(res.result){
          resolve(res.result)
        }else {
          reject(null)
        }
      })
      .catch(err=> {
        reject(err)
      })
    })

  },
  [types.GET_DOCX_CACHE]({ dispatch }, payload){
    return new Promise((resolve, reject)=> {
      const getParam = {
        urlCommand:`/cache/getCache?key=${payload.type}&id=${payload.id}`
      }  
      
      dispatch('AUTH_GET', getParam)
      .then(res => {
        if(res.result) resolve(res.result)
        else resolve(null)
      })
      .catch(err => {
        reject(err)
      })
    })
  }
}


export default {
  state,
  getters,
  mutations,
  actions
}
