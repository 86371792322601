<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="label grey--text text--darken-1">
          {{$t('publicForm.url')}}
        </v-list-item-title>
        <url-box :url="publicUrl">
          <template v-slot:prepend-copy>
            <div class="px-2">
              <v-tooltip top>
                <template v-slot:activator="{on, attr}">
              <v-btn icon small @click="refreshURL" v-on="on" v-bind="attr">
                <v-icon small>
                  mdi-refresh
                </v-icon>
              </v-btn>
                </template>
                {{$t('publicForm.refreshURL')}}
              </v-tooltip>
            </div>
          </template>
        </url-box>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="label grey--text text--darken-1">
          {{$t('publicForm.submitLimit')}}
        </v-list-item-title>
        <v-radio-group
          row
          :value="publicForm.limit"
          @change="save($event, 'limit')"
          dense
          hide-details
          class="mt-0"
        >
          <v-radio
            v-for="option in limitOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="label grey--text text--darken-1">
          {{$t('publicForm.successMsg')}}
        </v-list-item-title>
        <div class="ql-editor pa-0">
          <text-editor
            v-if="showEditor"
            v-model="showEditor"
            :textValue="publicForm.successMsg"
            @change="save($event, 'successMsg')"
          />
          <div
            v-else
            class="successMsg-display"
            @click="showEditor = true"
            v-html="publicForm.successMsg"
          ></div>
        </div>
      </v-list-item-content>
    </v-list-item>
    <confirmDialog ref="confirmDialog" />
  </v-list>
</template>
<script>
import urlBox from "./urlBox";
import textEditor from "./textEditor";
import urlSetting from "./urlSetting";
import mixin from "./mixin";
import publicFormMixin from "@/mixins/publicFormMixin";

import confirmDialog from "@/components/dialogs/ConfirmDialog";

export default {
  mixins: [mixin, publicFormMixin],

  components: {
    urlBox,
    urlSetting,
    textEditor,
    confirmDialog,
  },
  data() {
    return {
      showEditor: false,
      limitOptions: [
        {
          label: this.$t('publicForm.noLimit'),
          value: 0,
        },
        {
          label: this.$t('publicForm.once'),
          value: 1,
        },
        {
          label: this.$t('publicForm.oncePerDay'),
          value: 2,
        },
      ],
    };
  },

  methods: {
    refreshURL() {
      this.$refs.confirmDialog
        .open(this.$t('publicForm.refreshURL'), this.$t('publicForm.refreshURL'))
        .then((confirm) => {
          if (confirm) {
            this.$emit("commandHandler", {
              command: "refreshURL",
              publicForm: this.publicForm
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.successMsg-display {
  height: 340px;
  line-height: 21px;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 90px;
  max-height: 700px;
  border: 1px solid #ddd;
  padding: 4px 12px;
}
</style>
