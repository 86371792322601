<template>
  <input-radio-toggle
      ref="inputRadioToggle"
    :value="processedValue"
    @input="val=>onInput(val)"
    :dense="dense"
      :disabled="disabled"
    :options="inputOptions"></input-radio-toggle>

</template>

<script>
import inputRadioToggle from './InputRadioToggle'

export default {
  name: 'inputYesNo',
  components: {inputRadioToggle},
  data () {
    return {
      YES_NO_OPTIONS: [
        {labelTag: 'yes', value: 'yes'},
        {labelTag: 'no', value: 'no'}
      ],
      TRUE_FALSE_OPTIONS: [
        {labelTag: 'yes', value: 'true'},
        {labelTag: 'no', value: 'false'}
      ]
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {
    processedValue () {
      const vm = this
      const YES_INDEX = 0
      const NO_INDEX = 1
      var result = vm.value

      if (vm.value && vm.inputOptions && vm.inputOptions.length >0) {
        // console.log('computed(processedValue) :: vm.value = ' + vm.value)
        // console.log('computed(processedValue) :: vm.inputOptions: ', vm.inputOptions)
        var result = vm.inputOptions[NO_INDEX].value
        if (vm.value && vm.value !== '') {
          result = vm.value
        }
      }

      return result // this.value? this.value.toString() : 'no'
    },
    inputOptions () {
      const vm = this
      return vm.useTrueFalse ?
        vm.TRUE_FALSE_OPTIONS :
        vm.YES_NO_OPTIONS
    }
  },
  props: {
    value: String,
    dense: {
      type: Boolean,
      default: false
    },
    useTrueFalse: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkIsValid (state) {
      return this.$refs.inputRadioToggle.checkIsValid(state)
    },
    onInput (val) {
      const vm = this
      console.log('InputYesNo :: onInput :: val = ' + val)
      vm.$emit('input', val) // val is still string here
    }
  }
}
</script>
