import { forms as types } from "../../mutation-types";
import Vue from "vue";
import defaultState from './state'

export default {
    [types.SET_FORM_VIEW](state, payload){
        if (state.currentForm && state.currentForm.views.length > 0){
            const updatedView = JSON.parse(JSON.stringify(payload.view))
            const index = state.currentForm.views.findIndex(
                item => item._id === payload.view._id);
            // console.log('SET_FORM_VIEW :: view index = ' + index + ', payload: ', payload)
            if (index !== -1){
                Vue.set(state.currentForm.views, index, updatedView)
            }
        }
    },
    [types.ADD_FORM_VIEW](state, payload){
        if (state.currentForm.hasOwnProperty('views')) {
            state.currentForm.views.push(payload.view)
          } else {
            state.currentForm.views = [payload.view]
          }        
    },
    [types.REMOVE_FORM_VIEW](state, payload){
        if (state.currentForm && state.currentForm.views.length > 0){
            const index = state.currentForm.views.findIndex(item => item._id === payload.viewId)
            state.currentForm.views.splice(index, 1)
        }
    },
    [types.REORDER_FORM_VIEW](state, payload){
        const oldIndex = payload.oldIndex
        const newIndex = payload.newIndex

        const arTargetViews = state.currentForm.views.splice(oldIndex, 1)
        state.currentForm.views.splice(newIndex, 0, arTargetViews[0])
    },
    [types.SET_CURRENT_FORM](state, payload){
        state.currentForm = payload
    },
    [types.SET_CURRENT_FORM_PROPERTY](state, payload){
        if (payload.category && payload.setting){
            payload.key
            ? Vue.set(state.currentForm[payload.category][payload.setting], payload.key, payload.value)
            : Vue.set(state.currentForm[payload.category], payload.setting, payload.value)
        }else 
        Vue.set(state.currentForm, payload.propertyKey, payload.propertyValue)
    },
    [types.SET_PRINT_TEMPLATE](state, payload){
        state.printTemplates = payload
    },
    [types.ADD_RELATED_TABLE_INFO_PROPERTY](state, payload){
        if (!state.relatedTableInfos.hasOwnProperty(payload._id)){
            // console.log('state.relatedTableInfos has no the related table info')
            Vue.set(state.relatedTableInfos, payload._id, payload)
        } else {
            // console.log('state.relatedTableInfos has the related table info already')
        }
    },
    [types.SET_RELATED_TABLE_INFO](state, payload){
        var result = {}
        if (Array.isArray(payload)) {
            for (let i = 0; i < payload.length; i++) {
                const loopItem = payload[i]
                result[loopItem._id] = loopItem
              }
        } else result = payload
        state.relatedTableInfos = result
    },
    [types.RESET_FORMS](state){
        Object.assign(state, defaultState)
    },

    [types.CLEAR_FORM_FROM_MEMORY] (state) {
      state.currentForm = null
    },
    [types.SET_DISPLAY_RULE](state, payload){
        Vue.set(state.currentForm, 'displayRule', payload.rules)
    },

    [types.APPEND_SELECTION_OPTIONS](state, newOptions){
        Object.keys(newOptions).forEach(key=> {
            let fieldInfo = state.currentForm.fieldInfos.find(info => info.fieldId === key)

            if (fieldInfo){
                let inputOptions = fieldInfo.properties.inputOptionsSingle || fieldInfo.properties.inputOptionsMultiple
                inputOptions.options = inputOptions.options.concat(newOptions[key])
            }
        })
    },
    [types.SET_CURRENT_FORM_ICON](state, payload){
        state.currentFormIcon = payload;
    }

}