import inputWidgetAmount from './InputWidgetAmount'
import inputWidgetAttachments from './InputWidgetAttachments'
import inputWidgetAutoNumbering from './InputWidgetAutoNumbering'
import inputWidgetChildTable from './InputWidgetChildTable'
import inputWidgetDate from './InputWidgetDate'
import inputWidgetDepartments from './InputWidgetDepartments'
import inputWidgetEmail from './InputWidgetEmail'
import inputWidgetExpression from './InputWidgetExpression'
import inputWidgetIdCard from './InputWidgetIdCard'
import inputWidgetLocation from './InputWidgetLocation'
import inputWidgetMembers from './InputWidgetMembers'
import inputWidgetMultipleSelection from './InputWidgetMultipleSelection'
import inputWidgetNumber from './InputWidgetNumber'
import inputWidgetOtherTableField from './InputWidgetOtherTableField'
import inputWidgetPhone from './InputWidgetPhone'
import inputWidgetRating from './InputWidgetRating'
import inputWidgetRelatedRecord from './InputWidgetRelatedRecord'
// import inputWidgetRelatedMultipleRecords from './InputWidgetRelatedMultipleRecords'
import inputWidgetRelatedSelection from './InputWidgetRelatedSelection'
import inputWidgetRichText from './InputWidgetRichText'
import inputWidgetSectionLine from './InputWidgetSectionLine'
import inputWidgetSignature from './InputWidgetSignature'
import inputWidgetSingleSelection from './InputWidgetSingleSelection'
import inputWidgetSummaryField from './InputWidgetSummaryField'
import inputWidgetText from './InputWidgetText'
import inputWidgetTextCombination from './InputWidgetTextCombination'
import inputWidgetYesNo from './InputWidgetYesNo'
import inputWidgetRegion from './InputWidgetRegion'
import inputWidgetRemark from './InputWidgetRemark'
import inputWidgetCapitalizedAmount from './InputWidgetCapitalizedAmount'
import inputWidgetColorPicker from './InputWidgetColorPicker'
import inputWidgetPeriod from './InputWidgetPeriod'

export default {
  inputWidgetAmount,
  inputWidgetAttachments,
  inputWidgetAutoNumbering,
  inputWidgetChildTable,
  inputWidgetDate,
  inputWidgetDepartments,
  inputWidgetEmail,
  inputWidgetExpression,
  inputWidgetIdCard,
  inputWidgetLocation,
  inputWidgetMembers,
  inputWidgetMultipleSelection,
  inputWidgetNumber,
  inputWidgetOtherTableField,
  inputWidgetPhone,
  inputWidgetRating,
  inputWidgetRelatedRecord,
  // inputWidgetRelatedMultipleRecords,
  inputWidgetRelatedSelection,
  inputWidgetRichText,
  inputWidgetSectionLine,
  inputWidgetSignature,
  inputWidgetSingleSelection,
  inputWidgetSummaryField,
  inputWidgetText,
  inputWidgetTextCombination,
  inputWidgetYesNo,
  inputWidgetRegion,
  inputWidgetRemark,
  inputWidgetCapitalizedAmount,
  inputWidgetColorPicker,
  inputWidgetPeriod
}
