<template>
    <v-menu 
        offset-y
        :content-class="fullWidth?'witDefaultValueMenuContent menuFullWidth':'witDefaultValueMenuContent'"
        v-model="showingMenu"
        :attach="`#${attachId}`"
    >
        <v-card
            tile
            class="elevation-0"
            style="height:auto"
        >
            <v-list class="pa-0">
                <v-list-item
                    v-for="(option, i) in defaultOptions"
                    :key="i"
                    plain
                    class="pa-0"
                >
                    <div class="pl-2" @click="handleListItemClick(option.id)" style="width:100%">
                        <v-icon size="20" style="height:0px; min-height:0px">{{option.icon}}</v-icon>   
                        <span style="font-size:14px" class="ml-2">{{$t('widgets.properties.' + option.label)}}</span>
                    </div>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        props:{
            value: Boolean,
            attachId: String,
            fullWidth:{
                type: Boolean,
                default: true
            },
            fieldType: String
        },
        components:{
        },
        computed:{
            showingMenu:{
                get(){
                    return this.value
                }, 
                set(val){
                    this.$emit('input', val)
                }
            }
        },
        data (){
            var defaultOptions = [
                {
                    id: "OTHERFIELD",
                    label: 'otherField',
                    icon: 'mdi-playlist-plus'
                },
                {
                    id: "QUERYFORM",
                    label: 'queryForm',
                    icon: 'mdi-text-search'
                }
            ]
            if(this.fieldType=='date'){
                defaultOptions.unshift({
                    id: "CURRENT",
                    label: "currentDate",
                    icon: 'mdi-calendar'
                })
            }
            return {
                defaultOptions
            }
        },
        methods:{
            handleListItemClick(optionId){
                if(optionId=='OTHERFIELD'){
                    this.$emit('openFieldSelectionMenu')
                }else if(optionId=='QUERYFORM'){
                    this.$emit('openQueryDialog')
                }else if(optionId=='CURRENT'){
                    this.$emit('updateDefaultCode', "CURRENT")
                }
            }
        }
    }
</script>