<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions>
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.label')}}
            </div>
        </v-expansion-panel-header>
        <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
            @change="$emit('onCommandHandler',{
                command: 'update_bl_label',
                value: $event
            })"
            v-model="bl_label"
        ></v-switch>
        <v-expansion-panel-content v-if="selectedChart=='LINE'||selectedChart=='BAR'||selectedChart=='RADAR'">
            <v-checkbox 
                v-model="setup.bl_showData" 
                @change="$emit('onCommandHandler',{
                    command: 'update_bl_showData',
                    value: $event
                })" 
                class="pb-1" 
                hide-details 
                dense 
                :ripple="false" 
                :label="$t('chart.showInfo')"
            ></v-checkbox>
        </v-expansion-panel-content>

        <v-expansion-panel-content v-if="selectedChart=='PIE'">
            <v-checkbox 
                v-model="setup.bl_showLabel"
                @change="$emit('onCommandHandler', {
                    command: 'update_bl_showLabel',
                    value: $event
                })" 
                class="pb-1" 
                hide-details 
                dense 
                :ripple="false" 
                :label="$t('chart.showDimensionLabels')"
            ></v-checkbox>
            <v-checkbox 
                v-model="setup.bl_showData" 
                @change="$emit('onCommandHandler',{
                    command: 'update_bl_showData',
                    value: $event
                })" 
                class="pb-1" 
                hide-details 
                dense 
                :ripple="false" 
                :label="$t('chart.showValues')"
            ></v-checkbox>
            <v-checkbox 
                v-model="setup.bl_showPercent" 
                @change="$emit('onCommandHandler', {
                    command: 'update_bl_showPercent',
                    value: $event
                })" 
                class="pb-1" 
                hide-details 
                dense 
                :ripple="false" 
                :label="$t('chart.showPercentage')">
            </v-checkbox>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="selectedChart=='FUNNEL'">
            <v-checkbox 
                v-model="setup.bl_showLabel" 
                @change="$emit('onCommandHandler', {
                    command: 'update_bl_showLabel',
                    value: $event
                })" 
                class="pb-1" 
                hide-details 
                dense 
                :ripple="false" 
                :label="$t('chart.showDimensionLabels')"
            ></v-checkbox>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        props:{
            bl_label: Boolean,
            // bl_showLabel: Boolean,
            // bl_showPercent: Boolean,
            // bl_showData: Boolean,
            setup: Object,
            selectedChart: String
        },
        data(){
            return {
                
            }
        },
        
    }
</script>