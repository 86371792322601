<template>
  <FormElement
    :label="label"
    :hints="hints"
    :subItem="true"
  >
    <ComboBox
      v-if="selected"
      :value="value"
      :options="list"
      :isMultiple="true"
      :placeholder="$t('workflow.modules.form.placeholder.fieldTrigger')"
      @changed="onChange"
      :onlyPrefix="false"
      :onlySuffix="true"
    />
  </FormElement>
</template>

<script>
import ComboBox from './../../../../form/comboBox/';
import FormElement from './../formElement';
export default {
  props: {
    value: {
      type: Array,
      default: [],
    },
    list: Array,
    label: String,
    hints: String,
    selected: Boolean,
  },
  methods: {
    onChange(v) {
      this.$emit('changed', v);
    }
  },
  components: {
    ComboBox,
    FormElement,
  },
};

</script>

<style scoped>
  
</style>
