<template>
<v-chip class="mr-1 mb-1 department-chip"
        :class="cssName"
        :close="hasCloseButton"
        small
        label
        :color="color"
        @click="onClicked"
        @click:close="onCloseClicked">
  <div class="d-flex flex-column" style="width:100% !important; overflow:hidden;">
    <span style="text-overflow: ellipsis; white-space: nowrap; overflow:hidden;">{{ name }}</span>
  </div>
</v-chip>
</template>

<script>
export default {
  name: 'selectedPersonTag',
  props: {
    clickCommand: String,
    deleteCommand: String,
    name: String,
    departmentId: String,
    teamName: String,
    index: Number,
    cssClass: String,
    close: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    readonly:{
      type: Boolean,
      default: false
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    cssName:{
      type: String,
      default: ''
    }
  },
  computed: {
    withMultipleTeams () {
      return this.$store.getters.allTeams.length > 1
    }
  },
  methods: {
    onCloseClicked () {
      const vm = this
      console.log('SelectedDepartmentTag :: onCloseClicked  vm.deleteCommand = ' + vm.deleteCommand)
      if (vm.deleteCommand) {
        vm.$emit('onCommand', {
          command: vm.deleteCommand,
          index: vm.index
        })
      }
    },

    onClicked () {
      const vm = this
      console.log('SelectedDepartmentTag :: onClicked')
      if (vm.clickCommand) {
        vm.$emit('onCommand', {
          command: vm.clickCommand,
          index: vm.index
        })
      }
    }
  }
}
</script>
