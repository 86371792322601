<template>
  <div
    class="input-toggle-toggle"
    :class="{ 'input-toggle-toggle-radio': toggleType === 'radio' }"
  >
  <v-tooltip v-for="option in options"  :key="option._id" top max-width="540px">
    <template v-slot:activator="{on}">
    <v-btn
      dense
      v-on="on"
      small
      class="input-toggle-toggle-btn"
      :style="[colorStyle(option)]"
      :class="buttonClass(option)"
      @click="!readonly ? onClick(option) : ''"
      max-width="100%"
    >
    <span class="text-truncate">
        {{ optionLabel(option) }}
    </span>
    </v-btn>
    </template>
    {{ optionLabel(option) }}
  </v-tooltip>

  </div>
</template>

<script>
export default {
  name: "inputWidgetToggle",
  model: {
    prop: "value",
    event: "input",
  },

  props: {
    options: Array,
    value: [Number, Array],
    dense: {
      type: Boolean,
      default: false,
    },
    toggleType: {
      type: String,
      default: "radio", // [radio|checkbox]
    },
    useColor: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: "row",
    },
    fieldType: {
      type: String,
      default: "flatMenu",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required:{
      type: Boolean,
      default: false,
    }
  },
  methods: {
    buttonClass(option) {
      const vm = this;
      var result = vm.dense ? ["dense pa-1"] : [];
      var selected = false;
      switch (vm.toggleType) {
        case "radio":
          selected = vm.value === option._id;
          break;
        case "checkbox":
          if (vm.value) {
            selected = vm.value.indexOf(option._id) >= 0;
          } else {
            selected = -1;
          }

          break;
      }
      if (!vm.useColor && selected) {
        result.push("primary");
      }
      if (vm.direction == "column") result.push("d-flex");
      return result.join(" ");
    },

    checkIsValid(state) {
      return true;
    },

    validate() {
      return true;
    },
    getBgColor() {
      const vm = this;
      var option = this.options.find((item) => item._id == vm.value);
      var result = null;
      if (vm.useColor) {
        if (vm.toggleType === "radio") {
          if (vm.value === option._id) {
            const colorSet = option.colorSet.split("/");
            result = colorSet[1];
          }
        } else {
          if (vm.value.indexOf(option._id) >= 0) {
            const colorSet = option.colorSet.split("/");
            result = colorSet[1];
          }
        }
      }
      return result;
    },
    colorStyle(option) {
      const vm = this;
      var result = {};
      if (vm.useColor) {
        if (vm.toggleType === "radio") {
          if (vm.value === option._id) {
            const colorSet = option.colorSet.split("/");
            result = { backgroundColor: colorSet[1], color: colorSet[0] };
          }
        } else {
          if (vm.value.indexOf(option._id) >= 0) {
            const colorSet = option.colorSet.split("/");
            result = { backgroundColor: colorSet[1], color: colorSet[0] };
          }
        }
      }
      if (vm.readonly) {
        result.opacity = 0.7;
        result.webkitFilter = "grayScale(50%)";
      }
      return result;
    },

    //
    // colorStyle (option) {
    //   const vm = this
    //   var result = {}
    //   if (vm.values.indexOf(option.value) >= 0) {
    //     if (vm.colors.length > 0) {
    //       const index = vm.options.findIndex(item => item.value === option.value)
    //       if (index >= 0) {
    //         const colorSet = vm.colors[index].split('/')
    //         result = {backgroundColor: colorSet[1], color: colorSet[0]}
    //       }
    //     }
    //   }
    //   return result
    // },
    optionLabel(option) {
      const vm = this;
      var result = option.label;
      if (option.labelTag) {
        result = vm.$t("widgets.properties." + option.labelTag);
      }
      return result;
    },
    onClick(option) {
      const vm = this;
      var newValue = JSON.parse(JSON.stringify(vm.value));

      switch (vm.toggleType) {
        case "radio":
          if (newValue === option._id && !this.required) {
            newValue = null;
          } else {
            newValue = option._id;
          }

          break;
        case "checkbox":
          const index = vm.value.indexOf(option._id);
          if (index === -1) {
            newValue.push(option._id);
          } else {
            newValue.splice(index, 1);
          }
          break;
      }
      // var values = vm.toValues(vm.value)
      //
      // if (vm.toggleType === 'radio') {
      //   newValue = option.value
      // } else {
      // }

      vm.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss">
.input-toggle-toggle {
  button {
    border-radius: 0;
    margin-right: 2px;
    margin-bottom: 2px;
    margin-bottom: 2px;
    .dense {
      height: 24px !important;
    }
  }
  .input-toggle-toggle-radio {
    button {
      margin-right: 1px;
      margin-bottom: 0;
    }
    :first-child:not(.d-flex) {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    :last-child:not(.d-flex) {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
  .input-toggle-toggle-btn {
    margin-bottom: 10px !important;
    > span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>