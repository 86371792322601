var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{attrs:{"title":_vm.title,"description":_vm.description,"card":_vm.step === 1},scopedSlots:_vm._u([{key:"header-content",fn:function(){return [_c('v-stepper',{staticClass:"elevation-0",attrs:{"value":_vm.step}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t("createTeam.createTeam"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t("createTeam.done"))+" ")])],1)],1)]},proxy:true},{key:"card-content",fn:function(){return [_c('v-card-text',{staticClass:"pa-0"},[_c('v-form',{ref:"form",staticClass:"create-team-form text-start",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("createTeam.personalInfo")))]),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.displayName"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-text-field',{attrs:{"counter":100,"rules":[_vm.RULE_REQUIRED, _vm.RULE_TEXTLIMIT(100)],"placeholder":_vm.$t('messages.pleaseInput', [
                _vm.$t('createTeam.displayName').toLowerCase() ]),"validate-on-blur":"","dense":"","outlined":""},model:{value:(_vm.teamInfo.displayName),callback:function ($$v) {_vm.$set(_vm.teamInfo, "displayName", $$v)},expression:"teamInfo.displayName"}})],1),_c('h6',[_vm._v(_vm._s(_vm.$t("createTeam.teamInfo")))]),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.teamName"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-text-field',{attrs:{"counter":100,"rules":[_vm.RULE_REQUIRED, _vm.RULE_TEXTLIMIT(100)],"placeholder":_vm.$t('messages.pleaseInput', [
                _vm.$t('createTeam.teamName').toLowerCase() ]),"validate-on-blur":"","dense":"","outlined":""},model:{value:(_vm.teamInfo.name),callback:function ($$v) {_vm.$set(_vm.teamInfo, "name", $$v)},expression:"teamInfo.name"}})],1),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.employeeNumber"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-text-field',{attrs:{"counter":20,"rules":[_vm.RULE_REQUIRED, _vm.RULE_TEXTLIMIT(20)],"placeholder":_vm.$t('messages.pleaseInput', [
                _vm.$t('createTeam.employeeNumber').toLowerCase() ]),"validate-on-blur":"","dense":"","outlined":""},model:{value:(_vm.teamInfo.employeeNumber),callback:function ($$v) {_vm.$set(_vm.teamInfo, "employeeNumber", $$v)},expression:"teamInfo.employeeNumber"}})],1),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.countries"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-autocomplete',{attrs:{"rules":[_vm.RULE_REQUIRED],"items":_vm.countries,"placeholder":_vm.$t('messages.pleaseChoose', [
                _vm.$t('createTeam.countries').toLowerCase() ]),"item-value":"id","item-text":"name","dense":"","outlined":""},model:{value:(_vm.teamInfo.countryId),callback:function ($$v) {_vm.$set(_vm.teamInfo, "countryId", $$v)},expression:"teamInfo.countryId"}})],1),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.timezone"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-autocomplete',{attrs:{"rules":[_vm.RULE_REQUIRED],"items":_vm.timezones,"item-value":"id","item-text":"name","placeholder":_vm.$t('messages.pleaseChoose', [
                _vm.$t('createTeam.timezone').toLowerCase() ]),"validate-on-blur":"","dense":"","outlined":""},model:{value:(_vm.teamInfo.timezone),callback:function ($$v) {_vm.$set(_vm.teamInfo, "timezone", $$v)},expression:"teamInfo.timezone"}})],1),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.currencies"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-autocomplete',{attrs:{"rules":[_vm.RULE_REQUIRED],"items":_vm.currencies,"item-value":"code","item-text":"name","placeholder":_vm.$t('messages.pleaseChoose', [
                _vm.$t('createTeam.currencies').toLowerCase() ]),"validate-on-blur":"","dense":"","outlined":"","filter":_vm.filterCurrencies},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.teamInfo.currencyCode),callback:function ($$v) {_vm.$set(_vm.teamInfo, "currencyCode", $$v)},expression:"teamInfo.currencyCode"}})],1),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.industry"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-autocomplete',{attrs:{"rules":[_vm.RULE_REQUIRED],"items":_vm.industries,"item-value":"id","item-text":"name","placeholder":_vm.$t('messages.pleaseChoose', [
                _vm.$t('createTeam.industry').toLowerCase() ]),"auto-select-first":"","dense":"","outlined":""},model:{value:(_vm.teamInfo.industryId),callback:function ($$v) {_vm.$set(_vm.teamInfo, "industryId", $$v)},expression:"teamInfo.industryId"}})],1),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("createTeam.scale"))),_c('span',{staticClass:"red--text mb-0"},[_vm._v("*")])]),_c('v-select',{attrs:{"rules":[_vm.RULE_REQUIRED],"items":_vm.scales,"item-value":"key","item-text":"text","placeholder":_vm.$t('messages.pleaseChoose', [
                _vm.$t('createTeam.scale').toLowerCase() ]),"dense":"","outlined":""},model:{value:(_vm.teamInfo.scale),callback:function ($$v) {_vm.$set(_vm.teamInfo, "scale", $$v)},expression:"teamInfo.scale"}})],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#6B7785"},on:{"click":_vm.reset}},[_vm._v(_vm._s(_vm.$t("buttons.reset")))]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t("buttons.next")))])],1)]},proxy:true},(_vm.step === 2)?{key:"prepend-card",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.$t("createTeam.completeSetting")))]),_c('p',[_vm._v(_vm._s(_vm.$t("createTeam.finishMsg")))]),_c('p',[_vm._v(_vm._s(_vm.$t("createTeam.finishMsg2")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goToHome}},[_vm._v(_vm._s(_vm.$t("createTeam.startUsing")))])]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }