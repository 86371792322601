<template>
  <div class="w-100 fill-height d-flex flex-column">
    <div v-if="searchable" class="flex-grow-0 d-flex flex-row align-center">
      <v-text-field dense
                    solo
                    outlined
                    append-icon="mdi-table-search"
                    clearable
                    :hide-details="true"
                    @keydown.enter="search"
                    @click:clear="onClearClicked"
                    @click:append="search"
                    v-model="searchValue"></v-text-field>
      <v-spacer></v-spacer>
      <!--[{{ $refs.xTable ? $refs.xTable.data.length : 'none'}}]-->
      <v-btn class="mb-2" fab small color="primary" @click="refresh">
        <v-icon>mdi-refresh</v-icon></v-btn>
    </div>
    <perfect-scrollbar class="flex-grow-1 fill-height justify-start"
                       @ps-y-reach-end="onEndReached">
      <div class="d-flex flex-column mr-1">
        <record-card v-for="row in tableData"
          :key="row._id"
          class="mb-1"
          colorClass="amber lighten-5"
          :buttons="[]"
          :selected="isSelected(row)"
          :edit="true"
          :card="row"
          :cardConfig="cardConfigs"
          :titleFieldId="titleFieldId"
          :fieldInfos="fieldInfos"
          @click="onRecordCardClicked(row)">
        </record-card>
      </div>
      <!--<infinite-loading @infinite="infiniteHandler"></infinite-loading>-->
    </perfect-scrollbar>
  </div>
</template>

<script>
import recordCard from '@/components/RecordCard'
import {LEVEL_DIAGRAM_CARD_CONFIG} from '@/const/defaults'

export default {
  name: 'selectionCardView',
  components: {
    recordCard,
  },
  data () {
    return {
      searchable: true,
      searchValue: '',
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0
      },
      autoSearchTimer: null
    }
  },
  watch: {
    searchValue: function(newVal) {
      const vm = this
      if (searchValue.length >= 3) {
        console.log('clearTimer')
        clearTimeout(vm.autoSearchTimer)
        console.log('setTimer')
        vm.autoSearchTimer = setTimeout(() => {
          vm.search()
        }, 100)
      } else {
        console.log('clearTimer')
        clearTimeout(vm.autoSearchTimer)
      }
    }
  },
  model: {
    prop: 'selection',
    event: 'input'
  },
  props: {
    selection: {
      type: Array,
      default: null
    },

    dataSource: String,
    formInfo: Object,
    selectionSettings: Object,

    multiple: Boolean,
    exclusion: {
      type: Array,
      default () {
        return []
      }
    },


    recordId: String,
    fieldId:String,
      parentFormId:String
  },
  computed: {
    cardConfigs () {
      return this.listingConfig ? this.listingConfig.cardConfigs : null
    },
    hasAvatar () {
      return this.listingConfig.avatarFieldId && (this.listingConfig.avatarFieldId !== '')
    },

    fieldInfos () {
      return this.formInfo ? this.formInfo.fieldInfos : []
    },

    titleFieldInfoId () {
      return this.formInfo && this.formInfo.titleFieldInfoId ?
        this.formInfo.titleFieldInfoId : ''
    },

    titleFieldId () {
      const vm = this
      var result = ''
      if (vm.fieldInfos && vm.titleFieldInfoId !== '') {
        const titleFieldInfo = vm.fieldInfos.find(info => info._id === vm.titleFieldInfoId)
        result = titleFieldInfo.fieldId
      }
      return result
    },

    listingConfig () {
      const vm = this
      var result = {
        cardConfigs: LEVEL_DIAGRAM_CARD_CONFIG,
        filterConfigs: {},
        sortingConfigs: []
      }
      if (vm.selectionSettings) result = vm.selectionSettings
      return result
    },

    // Ensure the title field is first fixed field if exists
    fixedFieldIds () {
      const vm = this
      var result = []
      if (vm.listingConfig && vm.listingConfig.fixedFieldIds) {
        if (vm.listingConfig.fixedFieldIds.length === 0) {
          if (vm.titleFieldId && (vm.titleFieldId !== '')) {
            result = [vm.titleFieldId]
          }
        } else {
          const titleFieldIndex = vm.listingConfig.fixedFieldIds.indexOf(vm.titleFieldId)
          result = [].concat(vm.listingConfig.fixedFieldIds)

          // place titlefield to front
          if (titleFieldIndex >= 0) result.splice(titleFieldIndex, 1)
          result.unshift(vm.titleFieldId)
        }
      }
      return result
    },

    nonFixedFieldIds () {
      const vm = this
      var result = []
      if (vm.listingConfig) {
        result = this.listingConfig.nonFixedFieldIds
      }
      return result
    },

    fixedFieldInfos () {
      const vm = this
      var result = []
      if (vm.fixedFieldIds) {
        for (let i = 0; i < vm.fixedFieldIds.length; i++) {
          const loopId = vm.fixedFieldIds[i]
          const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopId)
          result.push(fieldInfo)
        }
      }
      return result
    },
    nonFixedFieldInfos () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.nonFixedFieldIds.length; i++) {
        const loopId = vm.nonFixedFieldIds[i]
        const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopId)

        if (!fieldInfo) {
          vm.$alert('Field #' + loopId + ' not found.')
        }
        result.push(fieldInfo)
      }
      return result
    },

    allDisplayFieldIds () {
      const vm = this
      var result = [vm.titleFieldId]

      if (vm.cardConfigs.cardSummaryFieldId && vm.cardConfigs.cardSummaryFieldId !== '')
        result.push(vm.cardConfigs.cardSummaryFieldId)

      if (vm.cardConfigs.cardImageFieldId && vm.cardConfigs.cardImageFieldId !== '')
        result.push(vm.cardConfigs.cardImageFieldId)

      if (vm.cardConfigs.cardDisplayFieldIds && vm.cardConfigs.cardDisplayFieldIds.length > 0) 
        result.push(...vm.cardConfigs.cardDisplayFieldIds)

      // if (vm.fixedFieldIds && vm.nonFixedFieldIds) {
      //   var allFieldIds = vm.fixedFieldIds.concat(
      //     vm.nonFixedFieldIds)

      //   // remove duplication
      //   var result = allFieldIds.filter((item, index) => {
      //     return allFieldIds.indexOf(item) === index
      //   })
      // }
      return result
    },

    allDisplayFieldLabels () {
      const vm = this
      // console.log('computed(allDisplayFieldLabels) :: vm.allDisplayFieldIds: ', vm.allDisplayFieldIds)
      // console.log('computed(allDisplayFieldLabels) :: vm.fieldInfos.length = ' + vm.fieldInfos.length)

      const displayFieldInfos = vm.fieldInfos.filter(
        info => vm.allDisplayFieldIds.includes(info.fieldId)
      )

      // console.log('computed(allDisplayFieldLabels) :: displayFieldInfos: ', displayFieldInfos)
      return displayFieldInfos.map(info => info.label)
    }
  },
  methods: {
    isSelected (row) {
      const vm = this
      return vm.selection.includes(row._id)
    },
    reloadData () {
      const vm = this
      vm.tableData = []
      vm.pagination.currentPage = 1
      vm.loadMore()
    },
    onEndReached () {
      const vm = this
      if (vm.tableData.length < vm.pagination.totalResult) {
        vm.pagination.currentPage++
        vm.loadMore()
      }
    },
    onClearClicked () {
      this.searchValue = ''
      this.reloadData()
    },
    search () {
      this.reloadData()
    },

    refresh () {
      this.reloadData()
    },


    onRecordCardClicked (row) {
      const vm = this
      console.log('onRecordCardClicked row: ', row)
      if (vm.multiple) {
        vm.toggleSelection(row._id)
      } else {
        vm.setSelection(row._id)
      }
    },

    toggleSelection (rowId) {
      const vm = this
      const index = vm.selection.indexOf(rowId)
      const newSelection = JSON.parse(JSON.stringify(vm.selection))
      if (index >= 0) {
        newSelection.splice(index, 1)
      } else {
        newSelection.push(rowId)
      }
      vm.$emit('input', newSelection)
    },

    setSelection (rowId) {
      this.$emit('input', [rowId])
    },

    // infiniteHandler ($state) {
    //   const vm = this
    //   vm.loadMore()
    // },
    combineFilters (searchFilters, listFilterConfigs) {
      return {
        filters: listFilterConfigs.filters,
        filterAndOr: listFilterConfigs.filterAndOr,
        userFilters: searchFilters
      }
    },

    // getUserFilters () {
    //   const vm = this
    //   var result = {}
    //   if (vm.allDisplayFieldIds && vm.allDisplayFieldIds.length>0) {
    //     const firstFieldId = vm.allDisplayFieldIds[0]
    //     if (vm.searchValue && vm.searchValue !== '') {
    //       result[firstFieldId] = vm.searchValue
    //     }
    //   }
    //   return result
    // },

    loadMore () {
      const vm = this
      // console.log('SelectionCardView :: loadMore')
      if (vm.dataSource) {
        // console.log('SelectionCardView :: loadMore :: dataSource exists')
        // const searchFilters = vm.getUserFilters()
        const filterConfigs = vm.listingConfig.filterConfigs
        // vm.combineFilters(searchFilters, vm.listingConfig.filterConfigs)

        var displayFieldIdsWithImage = Object.assign([], vm.allDisplayFieldIds)
        if (vm.hasAvatar) {
          displayFieldIdsWithImage.push(vm.listingConfig.avatarFieldId)
        }
        const params = {
          relationFormId: vm.formInfo._id,

          displayFieldIds: displayFieldIdsWithImage,
          filterConfigs: filterConfigs,
          keyword: vm.searchValue ? vm.searchValue : null,
          sortingConfigs: vm.listingConfig.sortingConfigs,
          page: vm.pagination.currentPage,
          size:vm.pagination.pageSize,

          // exclusion
          exclusion: vm.exclusion,

          recordId: vm.recordId,
          formId:this.parentFormId,
          fieldId:this.fieldId,
          isPublic: this.$store.getters.isPublicRoute,
          
        }
        // console.log('loadMore :: params: ', params)
        vm.$store.dispatch('FETCH_SELECTION_DATA', params).then(
          response => {
            const data = response.result
            // console.log('infiniteHandler :: data: ', data)
            vm.pagination.totalResult = response.pagination.totalResult
            vm.tableData.push(...data)
            // if (data) {
            //   if (data.length > 0) {
            //     vm.pagination.currentPage++
            //     vm.pagination.totalResult = response.pagination.totalResult
            //     vm.tableData.push(...data)
            //     $state.loaded()
            //   } else {
            //     $state.complete()
            //   }
            // }
          }
        )
      } else {
        console.log('SelectionCardView :: loadMore dataSource not defined')
      }
    }
  }
}
</script>
