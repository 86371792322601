<template>
  <v-text-field
    v-bind="{ ...$props, ...$attrs }"
    @blur="onBlur"
    @keyup="onKeyUp"
    v-model="internalValue"
    ref="textField"
  />
</template>
<script>
import { VInput } from "vuetify/lib";
export default {
  extends: VInput,
  methods: {
    onBlur() {
      this.$emit("blur", this.$refs["textField"].internalValue);
    },
    onKeyUp() {
      this.$emit("keyup", this.$refs["textField"].internalValue);
    },
  },
};
</script>
