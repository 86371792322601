<template>
  <v-dialog content-class="userProfileDrawer" class="align-start justify-start w-100 user-profile-drawer"
    v-model="$store.state.mobile.userDrawerOpened"
  >
    <div class="white w-100 pt-14 d-block">
      <div class="main white">
        <v-list light rounded>
          <v-list-item
            v-for="(item, index) in listItems"
            :key="index"
            class="px-6"
            @click="selectItem(item)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ $t(item.title) }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div class="footer primary text-center py-2 white--text">
        {{ teamName }}
      </div>
    </div>
    <user-profile-dialog v-if="user" ref="userProfileDialog"></user-profile-dialog>
    <preference-dialog v-if="showPreferenceDialog" v-model="showPreferenceDialog" @submit="updateUserSetting" />
  </v-dialog>
</template>

<script>
import userProfileDialog from '@/components/dialogs/UserProfileDialog'
import PreferenceDialog from '@/components/dialogs/PreferenceDialog'
export default {
  components: {PreferenceDialog, userProfileDialog},
  data() {
    return {
      showPreferenceDialog: false, 
      listItems: [
        {
          icon: "mdi-account-edit",
          title: "general.profile",
          action: "profile",
        },
        {
          icon: "mdi-translate",
          title: "general.locale",
          action: "locale",
        },
        {
          icon: "mdi-logout",
          title: "general.logout",
          action: "logout",
        },
      ],
    };
  },
  computed: {
    isShowUserDrawer() {
      return this.$store.getters.userDrawerOpened
    },
    user() {
      return this.$store.getters.user
    },
    teamName() {
      return this.$store.getters.activeTeamName
    },
  }, 
  methods: {
    selectItem( item ) {
      switch( item.action ) {
        case "profile": 
          this.$refs.userProfileDialog.open();
          break;
        case "locale":
          this.showPreferenceDialog = true;
          break;
        case "logout":
          this.$store.dispatch( 'LOGOUT' ).then( () => {
            this.$router.push( { path: '/home' } )
          } )
          break;
      }
      this.$store.dispatch('TOGGLE_USER_DRAWER')
    },
    updateUserSetting(locale){
      const postData = {
        urlCommand: '/users/updateGlobalPreference',
        data: {
          locale
        }
      }

      this.$store.dispatch('AUTH_POST', postData)
      .then(res => {
        if (res.result) {
          location.reload()
        }
      })
    }
  },
};
</script>

<style lang="scss">

  .userProfileDrawer {
    align-self: baseline;
  }
  .v-dialog__content {
    // padding-top: 4rem;
    .v-dialog.v-dialog--active {
      margin: 0 !important;
    }
  }
</style>