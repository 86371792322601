<template>
  <div class="input-radio-toggle">
    <v-btn v-if="options" dense v-for="option in options"
           :key="option.value"           
           :small="buttonSize==='small'"
           class="px-2 min-width-50 input-radio-toggle-btn elevation-0 white--text"
           :style="colorStyle(option)"
           :class="[option.value===value&&colors.length===0?'primary':'grey lighten-1',{'dense pa-1':dense}]"
         @click="(!disabled)?onClick(option):''">
      {{ optionLabel(option) }}
    </v-btn>
    <!-- :readonly="disabled" -->
  </div>
</template>

<script>
export default {
  name: 'inputRadioToggle',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    options: Array,
    value: String,
    dense: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    i18nPrefix: {
      type: String,
      default: 'widgets.properties.'
    },
    buttonSize: {
      type: String,
      default: 'small'
    }
  },
  methods: {
    checkIsValid (state) {
      return true
    },

    validate () {
      return true
    },

    colorStyle (option) {
      const vm = this
      var result = {}
      if (option.value===vm.value) {
        if (vm.colors.length > 0) {
          const index = vm.options.findIndex(item => item.value === vm.value)
          if (index >= 0) {
            const colorSet = vm.colors[index].split('/')
            result = {backgroundColor: colorSet[1], color: colorSet[0]}
          }
        }
      }
      if(vm.disabled){
        result.opacity = 0.7
        result.webkitFilter = "grayScale(50%)"
      }
      return result
    },

    optionLabel (option) {
      const vm = this
      var result = option.label
      if (option.labelTag) {
        result = vm.$t(vm.i18nPrefix + option.labelTag)
      }
      return result
    },
    onClick (option) {
      const vm = this
      console.log('InputRadioToggle :: onClick :: option: ', option)
      vm.$emit('input', option.value)
    }
  }
}
</script>

<style>
.input-radio-toggle button.dense {
  height: 24px !important;
}

.input-radio-toggle button {
  margin-right: 1px;
  border-radius: 0;
  background-color:lightgray !important;
}

.input-radio-toggle button:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.input-radio-toggle button:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

  .input-radio-toggle .input-radio-toggle-btn {
    margin-bottom: 5px;
  }
</style>
