<template>
    <div class="d-flex mt-1">
        <div style="width: 80px">
            <v-select
                v-model="rule"
                class="vselect-small"
                style="font-size:12px"
                height="30"
                dense
                outlined
                :items="filteredRules"
                hide-details
                :menu-props="{offsetY: true, contentClass: 'denseList'}"
            >
                <template v-slot:item="{item}" style="">
                    {{$t('filters.' + criteriaConfigs[item.label].t)}}
                </template>
                <template v-slot:selection="{item}">
                    {{$t('filters.' + criteriaConfigs[item.label].t)}}
                </template>
            </v-select>
        </div>
    </div>
</template>

<script>

import mixin from './mixin'
export default {
    mixins: [mixin],
    computed:{
        filteredRules(){
            return this.rules.options.filter(item=>!(['is', 'not'].includes(item)))
        }
    }
}
</script>