<template>
  <v-layout align-center justify-space-between class="workform-clip-dropdown-option">
    <div class="workform-clip-dropdown-source">
      <v-icon small v-if="onlyPrefix && prefixIcon" class="workform-clip-dropdown-icon">
        {{ item.icon || 'mdi-pencil' }}
      </v-icon>
      <span class="workform-clip-dropdown-label" v-if="showField">
        <span class="workform-clip-dropdown-label-prefix">
          <v-chip small class="workform-clip-dropdown-chip">{{ $t(`workflow.widgets.${item.fieldType}`) }}</v-chip>
        </span>
      </span>
      <span class="workform-clip-dropdown-branch-label">
        <span v-if="item.isSystem">
          {{  $t(`workflow.widgets.${item.fieldId}`) }}
        </span>
        <span v-else>
          {{  renderLabel ? renderLabel(item) : item.label }}
        </span>
      </span>
    </div>
    <v-chip small v-if="onlySuffix">{{ $t('widgets.customized') }}</v-chip>
  </v-layout>
</template>

<script>
  export default {
    props: {
      item: Object,
      onlyPrefix: Boolean,
      showField: Boolean,
      prefixIcon: Boolean,
      shouldShowCount: Boolean,
      count: Number,
      isBranch: Boolean,
      renderLabel: Function,
    },
  }
</script>

<style>
</style>
