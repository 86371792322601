<template>
  <WorkflowConsumer>
    <div slot-scope="{ info }">
      <v-progress-linear
        v-if="infoLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-progress-circular
          v-if="!!workflowInfo && treeLoading"
          indeterminate
          class="w-e-tree-loader"
          color="primary"
      ></v-progress-circular>
      <div v-if="!!workflowInfo && !treeLoading">
        <div class="w-e-scaler">
          <div class="w-e-versioning">{{ $t('workflow.label.version', { version }) }}</div>
          <v-layout justify-space-between align-center >
            <div>
              <v-btn outlined class="w-e-scaler-btn scaler-add" :disabled="!canAdd" @click="add" :class="{ active: canAdd }">+</v-btn>
              <v-btn outlined class="w-e-scaler-btn scaler-reduce" :disabled="!canReduce" @click="reduce" :class="{ active: canReduce }">-</v-btn>
              <span class="w-e-scaler-label">{{ scale }}%</span>
            </div>
          </v-layout>
          <div v-if="isPreviewMode" class="w-e-preview-title">{{ $t('workflow.label.previewMode') }}</div>
        </div>
        <div class="w-e-tools">
          <v-layout justify-space-between align-center>
            <div class="w-e-tools-quick-view">
              <toggle-button
                :value="isShortMode"
                @change="onQuickView"
                :color="{
                  checked: $vuetify.theme.themes.light.primary, 
                  unchecked: '#CCCCCC', 
                }"
                width="130"
                height="34"
                :labels="{
                  unchecked: $t('workflow.btn.quickView'), 
                  checked: $t('workflow.btn.quickView')
                }"
              />
            </div>
            <div v-if="canPreview">
              <toggle-button
                :value="isPreviewMode"
                :color="{
                  checked: $vuetify.theme.themes.light.primary, 
                  unchecked: '#CCCCCC', 
                }"
                :disabled="treeLoading || reviewLoading"
                @change="onPreview"
                width="170"
                height="34"
                :labels="{
                  unchecked: $t('workflow.btn.preview'), 
                  checked: $t('workflow.btn.preview')
                }"
              />
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="w-e-preview-help-icon"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('workflow.message.previewDescription') }}</span>
              </v-tooltip>
            </div>
          </v-layout>
        </div>
        <v-layout column align-center justify-center :style="canvasStyle">
          <WorflowTree :walkingNode="walkingNode" :node="isPreviewMode ? previewNodes : node" :tree="tree" />
          <WorkflowEndOfFlowNode v-if="!isLoadingPreviewTree" />
        </v-layout>
        <WorkflowModules v-if="!!workflowTree && !!workflowInfo" />
        <WorkflowForm />
      </div>
      <div v-else>
        <EditorTriggerSetting />
      </div>
    </div>
  </WorkflowConsumer>
</template>

<script>
// import { findIndex } from 'lodash';
import WorkflowConsumer from './contexts/workflowConsumer';
import WorkflowModules from './../components/drawer/modules';
import WorkflowForm from './../components/drawer/form';
import WorflowTree from './../components/tree/';
import Tree from './../libs/tree';
// import WorkflowStartOfFlowNode from './../components/tree/node/start';
// import WorkflowBrancgFlowNode from './../components/tree/node/branch';
import WorkflowEndOfFlowNode from './../components/tree/node/end';
import EditorTriggerSetting from './../components/triggerSelection';
// import {
//   WorkflowNodeIdentify,
// } from './../constants/node';
// import WorkflowEvent from './../constants/event';
import Mixin from './../mixin';
import {
  FETCH_WORKFLOW_INFO,
  FETCH_WORKFLOW_TREE,
  OFF_PREVIEW_ORCHESTRATION,
  PREVIEW_ORCHESTRATION,
  SCALE_WORKFLOW_LAYOUT,
  RESET_SCALE_WORKFLOW_LAYOUT,
  OFF_SHORTVIEW_ORCHESTRATION,
  SHORTVIEW_ORCHESTRATION,
  VALIDATION_UPDATE_EDIT_NODE,
  VALIDATE_WORKFLOW,
  FETCH_WORKFLOW_PARAMETER,
} from '@/store/modules/workflow/action_types';
import {
  REVIEW_ORCHESTRATION,
} from '@/store/modules/orchestration/action_types';
import { WorkflowVersionInfo } from './../constants/form'
// const {
//   walk,
//   walkout,
// } = InbroundCommands;

import GraphTree from './../libs/graphTree';
export default {
  mixins: [Mixin],
  inject: ['info'],
  data() {
    return {
      minScale: 20,
      maxScale: 100,
      canAdd: false,
      walkingNode: {},
      canReduce: true,
      previewNodes: [],
      node: [], // waiting for api nodes list response
      tree: Tree,
    }
  },
  // socket: {
  //   events: {
  //     walkThrough({ taskId, node }) {
  //       console.log('walk in: ', node);
  //       // TODO: multiple task
  //       this.$set(this.walkingNode, node, true);
  //     },
  //     walkOut({ taskId, node }) {
  //       console.log('walk out: ', node);
  //       // const currentIdx = findIndex(this.walkingNode, n => node === n);
  //       // if (currentIdx !== -1) {
  //       // this.walkingNode[node] = undefined;
  //       this.$set(this.walkingNode, node, undefined);
  //       // }
  //     },
  //   },
  // },
  async beforeCreate() {
    const { workflowId } = this.$route.params;
    if (workflowId) {
      this.$store.dispatch(FETCH_WORKFLOW_INFO, workflowId);
      this.$store.dispatch(FETCH_WORKFLOW_TREE, workflowId);
      // 1.0 performance issue, no validation in starting editing mode
      // await this.$store.dispatch(VALIDATE_WORKFLOW, workflowId);
      // this.$store.dispatch(VALIDATION_UPDATE_EDIT_NODE);
      this.$store.dispatch(FETCH_WORKFLOW_PARAMETER, {
        appId: this.currentApp._id,
        workflow: workflowId,
      });
    }
  },
  mounted() {
    // if back button is pressed
    window.onpopstate = () => {
      if (this.isPreviewMode) {
        this.$store.dispatch(OFF_PREVIEW_ORCHESTRATION);
      }
    };
  },
  computed: {
    version() {
      return WorkflowVersionInfo.version;
    },
    canvasStyle() {
      return {
        transition: 'transform .3s',
        transform: `scale(${this.scale / 100})`,
        minWidth: '100%',
        position: 'absolute',
        left: 0,
        top: '20px',
        padding: '40px 480px',  // leave padding with drawer width to avoid the drawer hiding the panel
      }
    },
    reviewLoading() {
      return this.$store.getters.fetchingOrchestration;
    },
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
    isShortMode() {
      return this.$store.getters.isShortMode;
    },
    isLoadingPreviewTree() {
      return this.$store.getters.fetchingOrchestration;
    },
    workflowInfo() {
      return this.$store.getters.getWorkflowInfo;
    },
    workflowTree() {
      return this.$store.getters.getWorkflowTree;
    },
    previewTree() {
      return this.$store.getters.getPreviewOrchestration;
    },
    treeLoading() {
      return this.$store.getters.fetchingWorkflowTree;
    },
    infoLoading() {
      return this.$store.getters.fetchingWorkflowInfo || this.isLoadingPreviewTree;
    },
    canPreview() {
      const { workflowInfo={} } = this;
      return workflowInfo.released;
    },
    scale() {
      return this.$store.getters.layoutScale;
    },
  },
  methods: {
    onScale(scale) {
      this.$store.dispatch(SCALE_WORKFLOW_LAYOUT, scale);
    },
    onPreview({ value: checked }) {
      if (checked) {
        const { workflowInfo={} } = this;
        this.$store.dispatch(REVIEW_ORCHESTRATION, workflowInfo._id);
        this.$store.dispatch(PREVIEW_ORCHESTRATION);
      } else {
        this.$store.dispatch(OFF_PREVIEW_ORCHESTRATION);
      }
    },
    onQuickView({ value: checked }) {
      if (checked) {
        this.$store.dispatch(SHORTVIEW_ORCHESTRATION);
      } else {
        this.$store.dispatch(OFF_SHORTVIEW_ORCHESTRATION);
      }
    },
    add() {
      if (this.scale < this.maxScale) {
        this.canAdd = true;
        this.canReduce = true;
        this.onScale(10);
      } else {
        this.canAdd = false;
        this.canReduce = true;
      }
    },
    reduce() {
      if (this.scale > this.minScale) {
        this.canReduce = true;
        this.canAdd = true;
        this.onScale(-10);
      } else {
        this.canAdd = true;
        this.canReduce = false;
      }
    }
  },
  components: {
    WorkflowConsumer,
    WorkflowModules,
    WorflowTree,
    WorkflowEndOfFlowNode,
    // WorkflowStartOfFlowNode,
    EditorTriggerSetting,
    WorkflowForm,
  },
  watch: {
    node: {
      handler(v) {
        console.log('new tree structure: ', v);
      },
      deep: true,
    },
    isPreviewMode(preview) {
      if (!preview) {
        this.previewNodes = [];
        this.$store.dispatch(RESET_SCALE_WORKFLOW_LAYOUT, 100);
      }
    },
    previewTree: {
      handler(v) {
        if (v.length && this.isPreviewMode) {
          const node = [ ...v ];
          // build up the ui tree from the api nodes list response
          const Tree = new GraphTree(node);
          Tree.build();
          // start for ui tree rendering
          this.previewNodes = Tree.root;
        }
      },
      deep: true,
    },
    workflowTree: {
      handler(v) {
        if (v.length) {
          const node = [ ...v ];
          // build up the ui tree from the api nodes list response
          const Tree = new GraphTree(node);
          Tree.build();
          // start for ui tree rendering
          this.node = Tree.root;
        }
      },
      deep: true,
    },
  }
};

</script>

<style>
  .w-e-tools {
    position: fixed;
    top: 100px;
    right: 90px;
    user-select: none;
    z-index: 995;
    border-radius: 8px;
  }
  .w-e-scaler {
    position: fixed;
    bottom: 10px;
    right: 90px;
    user-select: none;
    z-index: 995;
    padding: 5px;
    background: #efefef;
    border-radius: 8px;
  }
  .v-btn:not(.v-btn--round).v-size--default.w-e-scaler-btn {
    min-width: 32px !important;
  }
  .w-e-tree-loader {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100% !important;
    margin: 50px 0;
  }
  .w-e-tools-quick-view {
    margin-right: 10px;
  }
  .w-e-scaler-btn {
    color: #757575;
    width: 32px;
    height: 32px;
    font-weight: 200;
    border-radius: 3px;
    border: 1px solid #ddd;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    cursor: pointer;
    font-size: 20px !important;
    box-shadow: none !important;
  }
  .w-e-scaler-btn.scaler-add{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }
  .w-e-scaler-btn:not(.active) {
    color: #bdbdbd;
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .w-e-scaler-btn.scaler-reduce {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .w-e-scaler-label {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 15px;
  }
  .w-e-preview-title {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 900;
    letter-spacing: 10px;
  }
  .w-e-versioning {
    color: #666;
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
  }
</style>
