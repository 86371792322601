import {
    POPUP_ACTION_BUTTON_POPUP,
} from '@/store/modules/customizedAction/action_types';
import NormalButton from './../buttons/normal';
import AlertButton from './../buttons/alert';

const mixin = {
    props: {
        size: {
          type: Number,
          default: 0,
        },
        selected: {
          type: Array,
          default: [],
        },
        noHeader: {
          type: Boolean,
          default: false,
        },
        noFooter: {
          type: Boolean,
          default: false,
        }
      },
      components: {
        AlertButton,
        NormalButton,
      },
      methods: {
        targetComponent(button) {
          const buttons = {
            alert: 'AlertButton',
            normal: 'NormalButton',
          };
          const component = buttons[button.type];
          return component ? component : buttons.normal;
        },
        popAddRow() {
          this.$store.dispatch(POPUP_ACTION_BUTTON_POPUP, 5);
        },
        onSelected() {
          this.$emit('onSelected');
        },
    },
}

export default mixin
