<template>
  <v-menu offset-y max-width="340px">
    <template v-slot:activator="{ on: onMenu }" >
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn v-on="{ ...onMenu, ...onTooltip }" x-small fab color="primary" class="ml-2 elevation-0">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        {{$t('buttons.print')}}
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item
        link
        v-for="printTemplate in printTemplates"
        :key="printTemplate.templateId"
        @click="print(printTemplate)"
      >
        {{ printTemplate.label }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    printTemplates: Array,
  },
  methods: {
      print(template){
          this.$emit('print', template)
      }
  }
};
</script>
