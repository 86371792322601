<template>
  <div>
    <div class="workflow-setting-form-each-item">
      <DataSourceSelect
        :value="dataSource"
        :label="$t(`workflow.modules.operation.label.statisticItem`)"
        name="source"
        optionMultipleOutput
        :placeholder="$t('workflow.modules.operation.label.statisticItem')"
      />
    </div>
  </div>
</template>

<script>
import { omit, map } from 'lodash';
import ComboBox from './../../../form/comboBox/';
import FormElement from './../shared/formElement';
import DataSourceSelect from './../shared/dataSourceSelect';
import Mixin from '../mixin';
import SettingMixin from './mixin';
import {
  FETCH_WORKFLOW_DEPENDENCIES,
} from '@/store/modules/workflow/action_types';

export default {
  mixins: [SettingMixin, Mixin],
  props: {
    dataSource: Object
  },
  data() {
    return {
      unit: 1,
      formatOptions: [
        {
          id: 'datetime',
          label: this.$t('workflow.modules.operation.label.calculateUnit.datetime')
        },
        {
          id: 'date',
          label: this.$t('workflow.modules.operation.label.calculateUnit.date')
        },
        {
          id: 'time',
          label: this.$t('workflow.modules.operation.label.calculateUnit.time')
        }
      ],
    };
  },
  mounted() {
    this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
      workflow: this.workflow._id,
      node: this.node._id,
      isBranch: true,
      noFieldFound: true,
      appId: this.appId,
    });
  },
  computed: {
    list() {
      return this.$store.getters.getDependencies || [];
    },
  },
  methods: {
    normalizer(list) {
      return map(list, l => omit(l, ['children']));
    },
  },
  components: {
    ComboBox,
    FormElement,
    DataSourceSelect,
  },
  // watch: {
  //   unit: {
  //     handler(v) {
  //       this.changed('unit', parseInt(v));
  //     },
  //     deep: true
  //   },
  // },
};

</script>

<style scoped>

</style>
