<template>
  <div class="d-flex fill-height justify-start align-center mt-10 flex-column">
    <div class="text-h3 font-weight-bold grey--text text--darken-1">
      {{ $t("messages.noModulesFound") }}
    </div>
    <div v-if="isAdmin" class="mt-5">
      <v-btn color="primary" @click="newMenuItem"
        ><v-icon left>mdi-plus</v-icon>{{ $t("buttons.addNewModule") }}</v-btn
      >
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
export default {
  methods: {
    newMenuItem() {
      EventBus.$emit("openNewMenuItemDialog");
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>
