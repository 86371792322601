<template>
  <div class="selection-table-view d-flex flex-column fill-height">
    <div v-if="searchable" class="flex-grow-0 d-flex flex-row align-center">
      <v-text-field dense
                    solo
                    outlined
                    append-icon="mdi-table-search"
                    clearable
                    :placeholder="$t('messages.atLeast3Chars')"
                    :hide-details="true"
                    @click:append="search"
                    v-model="searchValue"></v-text-field>
      <v-spacer></v-spacer>
      <!--[{{ $refs.xTable ? $refs.xTable.data.length : 'none'}}]-->
      <v-btn class="mb-2" fab small color="primary" @click="refresh">
        <v-icon>mdi-refresh</v-icon></v-btn>
    </div>
    <quick-filter-card v-if="quickFilters && quickFilters.length>0" 
      class="flex-gorw-0"
      :fieldInfos="fieldInfos"
      @updateFilters="updateFiltersHandler"                          
      @updateFilterAndOr="updateFilterAndOrHandler"
      :filters="quickFilters"
      :filterAndOr="quickFilterAndOr"
      @onCommand="onCommandHandler"
      >
    </quick-filter-card>
    <vxe-grid ref="xTable"
      v-bind="gridOptions"
      height="560px"
      :menu-config="null"
      cell-class-name="vxe-table-row-xs"
      :checkbox-config="checkboxConfig"
      @checkbox-all="checkChangeAllEvent"
      @checkbox-change="checkChangeEvent"
      @radio-change="radioChangeEvent"
      @current-change="currentChangeEvent"
      @menu-click="dummyMenuClick"
      class="flex-grow-1 d-flex flex-column fill-height"
      :empty-text="allExcluded? $t('messages.allRecordsAreSelected'): undefined">

      <vxe-table-column
        :type="multiple ? 'checkbox' : 'radio'"
        width="50"
        fixed="left"
        class-name="text-center"
        header-class-name="text-center">
      </vxe-table-column>

      <vxe-table-column type="seq"
                        width="60"
                        fixed="left"
                        class-name="text-center"
                        header-class-name="text-center"></vxe-table-column>

      <template v-for="(colDef, index) in colDefs">
        <component v-if="colDef.componentName!==''"
                   :is="'vxe'+colDef.componentName"
                   :colDef="colDef"
                   :relationData="relationData"
                   :key="index"
                   :tableViewFormInfo="tableViewFormInfo"
                   :editable="false"
                   @onCommand="onCommandHandler"></component>
        <!--@input="value=>updateFieldValue(row._id,colDef,value)"></component>-->
        <vxe-table-column v-else v-bind="colDef" :key="index"></vxe-table-column>
      </template>
      <vxe-table-column v-if="actions.length > 0" :title="$t('general.operation')" width="200" fixed="right">
        <template v-slot="{ row, rowIndex }">
          <v-btn v-if="!hasRowChanged(row)"
                 class="mx-1" dark x-small fab
                 color="primary"
                 @click.native="$refs.xTable.setActiveRow(row)">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn v-if="hasRowChanged(row)"
                 class="mx-1" dark x-small fab
                 :disabled="!$refs.xTable.isUpdateByRow(row)"
                 color="success"
                 @click.native="saveRowEvent(row)">
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
          <v-btn class="mx-1" dark x-small fab
                 color="primary" @click.native="editRow(row)">
            <v-icon>mdi-file-document-edit</v-icon>
          </v-btn>
          <v-btn class="mx-1" dark x-small fab color="error" @click.native="deleteRow(row)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </vxe-table-column>
      <template v-slot:pager>
        <vxe-pager
            perfect
            :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
            :current-page.sync="pagination.currentPage"
            :page-size.sync="pagination.pageSize"
            :total="pagination.totalResult"
            @page-change="handlePageChange"></vxe-pager>
      </template>
    </vxe-grid>
  </div>
</template>

<script>
  import vxeTableHelperMixin from '@/mixins/vxeTableHelperMixin'
  import vxeTableCells from '@/components/vxeTableCells'
  import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";
  import quickFilterCard from '@/pages/forms/views/comps/QuickFilterCard'
  import { get, set } from "lodash"

  export default {
    name: 'selectionTableView',
    mixins: [vxeTableHelperMixin],
    components: {
      ...vxeTableCells,
      quickFilterCard
    },
    data () {
      return {
        // search
        searchable: true,
        searchValue: '',
        autoSearchTimer: null,
        relationData: {
          idDataMapping: {},
        },
        relatedTableInfos: {},
        allExcluded: false,
        quickFilters: [],
        quickFilterAndOr: 'and',
      }
    },
    model: {
      prop: 'selection',
      event: 'input'
    },
    props: {
      valType: String,
      selection: {
        // type: [String, Array],
        type: Array,
        default: []
      },

      dataSource: String,
      formInfo: Object,
      selectionSettings: Object,
      multiple: Boolean,
      actions: {
        type: Array,
        default () {
          return []
        }
      },
      exclusion: {
        type: Array,
        default () {
          return []
        }
      },
      recordId:String,
      fieldId:String,
      parentFormId:String
    },
    watch: {
      searchValue: function(newVal) {
        const vm = this
        clearTimeout(vm.autoSearchTimer)
        if (!vm.searchValue || vm.searchValue.length >= 3) {
          // console.log('clearTimer')
          clearTimeout(vm.autoSearchTimer)
          // console.log('setTimer')
          vm.autoSearchTimer = setTimeout(() => {
            vm.search()
          }, 500)
        }
      }
    },
    computed: {
      tableViewFormInfo () {
        return this.formInfo
      },
      checkboxConfig () {
        const vm = this
        var result = false
        if (vm.multiple) {
          result = {
            trigger: 'row',
            highlight: true,
            range: true
          }
        }
        return result
      },
      //**********************
      // field infos
      //**********************
      fieldInfos () {
        // console.log('SelectionTableView :: fieldInfos')
        return this.formInfo ? this.formInfo.fieldInfos : []
      },

      //**********************
      // title field
      //**********************
      titleFieldInfoId () {
        return this.formInfo && this.formInfo.titleFieldInfoId ?
          this.formInfo.titleFieldInfoId : ''
      },

      titleFieldId () {
        const vm = this
        var result = ''
        if (vm.titleFieldInfoId !== '') {
          const titleFieldInfo = vm.fieldInfos.find(info => info._id === vm.titleFieldInfoId)
          result = titleFieldInfo.fieldId
        }
        return result
      },

      listingConfig () {
        const vm = this
        // console.log('SelectionTableView :: listingConfig')
        var result = {
          tableConfigs: {
            fixedFieldIds: [],
            nonFixedFieldIds: [],
          },
          filterConfigs: {},
          sortingConfigs: []
        }
        if (vm.selectionSettings) result = vm.selectionSettings
        return result
      },

      tableConfigs () {
        const vm = this
        var result = {
          fixedFieldIds: [],
          nonFixedFieldIds: []
        }
        if (vm.listingConfig) {
          result = vm.listingConfig.tableConfigs
        }
        return result
      },

      haveTitleField () {
        return this.tableConfigs.fixedFieldIds.indexOf(this.titleFieldId) >= 0 ||
          this.tableConfigs.nonFixedFieldIds.indexOf(this.titleFieldId) >= 0
      },

      // Ensure the title field is first fixed field if exists
      fixedFieldIds () {
        const vm = this
        // console.log('SelectionTableView :: fixedFieldIds')
        var result = []
        if (vm.tableConfigs.fixedFieldIds && vm.tableConfigs.fixedFieldIds.length >0) {
          result = [].concat(vm.tableConfigs.fixedFieldIds)
        }
        if (!vm.haveTitleField) {
          result.unshift(vm.titleFieldId)
        }

        // if (vm.tableConfigs.fixedFieldIds.length === 0) {
        //   if (vm.titleFieldId && (vm.titleFieldId !== '')) {
        //     result = [vm.titleFieldId]
        //   }
        // } else {
        //   const titleFieldIndex = vm.tableConfigs.fixedFieldIds.indexOf(vm.titleFieldId)
        //   result = [].concat(vm.tableConfigs.fixedFieldIds)

        //   // place titlefield to front
        //   if (titleFieldIndex >= 0) result.splice(titleFieldIndex, 1)
        //   if (!vm.haveTitleField) {
        //     result.unshift(vm.titleFieldId)
        //   }
        // }
        return result
      },

      nonFixedFieldIds () {
        const vm = this
        // console.log('SelectionTableView :: nonFixedFieldIds')
        var result = []
        if (vm.tableConfigs.nonFixedFieldIds) {
          result = vm.tableConfigs.nonFixedFieldIds
        }
        return result
      },

      // infoCount () {
      //   return this.fixedFieldInfos.length + this.nonFixedFieldInfos.length
      // },

      // allFieldInfos () {
      //   return this.fixedFieldInfos.concat(this.nonFixedFieldInfos)
      // },
      
      fixedFieldInfos () {
        const vm = this
        // console.log('SelectionTableView :: fixedFieldInfos :: fieldInfos ids = ' +
        //   vm.fieldInfos.map(info => info.fieldId).join(','))
        var result = []

        if (vm.fixedFieldIds.length > 0 && vm.fieldInfos) {
          for (let i = 0; i < vm.fixedFieldIds.length; i++) {
            const loopId = vm.fixedFieldIds[i]
            // console.log('fixedFieldInfos :: i =' + i + ': loopId = ' +
            //   loopId)
            const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopId)
            if (fieldInfo) {
              fieldInfo.canView = true
              result.push(fieldInfo)
            }
          }
        }
        return result
      },
      nonFixedFieldInfos () {
        const vm = this
        // console.log('SelectionTableView :: nonFixedFieldInfos')
        var result = []

        if (vm.nonFixedFieldIds.length > 0 && vm.fieldInfos) {
          for (let i = 0; i < vm.nonFixedFieldIds.length; i++) {
            const loopId = vm.nonFixedFieldIds[i]
            const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopId)
            if (fieldInfo) {
              fieldInfo.canView = true
              result.push(fieldInfo)
            }
          }
        }
        return result
      },

      //**********************
      // field ids
      //**********************
      effectiveFieldIds () {
        const vm = this
        console.log('SelectionTableView :: effectiveFieldIds')

        var allFieldIds = vm.fixedFieldIds.concat(
          vm.nonFixedFieldIds)

        // remove duplication
        var result = allFieldIds.filter((item, index) => {
          return allFieldIds.indexOf(item) === index
        })
        return result
      },

      allTableDisplayFieldIds () {
        return this.effectiveFieldIds
      }

      // ,
      // allTableFieldIds () {
      //   const vm = this
      //   console.log('SelectionTableView :: allTableFieldIds')
      //   var result = []
      //   for (let i = 0; i < vm.fieldInfos.length; i++) {
      //     const loopFieldInfo = vm.fieldInfos[i]
      //     if (loopFieldInfo) {
      //       result.push(loopFieldInfo.fieldId)
      //     }
      //   }
      //   return result
      // }
    },
    created () {
      const vm = this
      window.addEventListener('resize', vm.onWindowResized)
    },
    beforeDestroy () {
      const vm = this
      window.removeEventListener('resize', vm.onWindowResized)
    },
    mounted () {
      this.fetchRelatedTableInfos()
      this.initQuickFilters();
    },
    methods: {
      onCommandHandler(payload){
        switch (payload.command) {
          case 'refreshData':{
            this.refresh()
            break
          }
          default:
            return
        }
      },
      async initQuickFilters() {
        // const vm = this;
        // const filtersInLS = await this.$store.dispatch(
        //   "GET_QUICK_FILTER_BY_VIEWID",
        //   this.view._id
        // );
        // let result = filtersInLS || cloneDeep(this.view.quickFilters);
        this.quickFilters = this.selectionSettings?.quickFilterConfigs?.filters;
        this.quickFilterAndOr = this.selectionSettings?.quickFilterConfigs?.quickAndOr;
      },
      fetchRelatedTableInfos (callback) {
        const vm = this
        const effectiveFieldInfos = vm.fieldInfos.filter(info => vm.effectiveFieldIds.includes(info.fieldId))
        const effectiveRelatedFieldInfos = effectiveFieldInfos.filter(info => info.type === 'relatedRecord')
        var allPromises = []
        const appId = vm.$store.getters.currentApp._id
        for (let i = 0; i < effectiveRelatedFieldInfos.length; i++) {
          const loopInfo = effectiveRelatedFieldInfos[i]
          
          if (loopInfo.properties && 
            loopInfo.properties.dataSource) {
            const getData = {
              urlCommand: '/forms/' + 
                loopInfo.properties.dataSource + '?appId=' + appId
            }
            allPromises.push(
              vm.$store.dispatch('AUTH_GET', getData)
            )
          }
        }

        Promise.all(allPromises).then(responses => {
          for (let i = 0; i < responses.length; i++) {
            const form = responses[i].result
            const formInfo = {
              _id: form._id,
              label: form._label,
              fieldInfos: form.fieldInfos,
              titleFieldInfoId: form.titleFieldInfoId,
              linkedFields: form.linkedFields,
              appId: form.appId,
              teamId: form.teamId
            }
            vm.$set(vm.relatedTableInfos, form._id, formInfo)
          }
          if (typeof callback === 'function') {
            callback()
          }
        })
      },
      dummyMenuClick () {
        alert('dummyMenuClick')
      },
      checkChangeAllEvent ({records}) {
        const vm = this
        const isAllChecked = vm.$refs.xTable.isAllCheckboxChecked()
        
        if (isAllChecked) 
          if(vm.valType!='object')
            vm.selection = records.map(record => record._id)
          else
            vm.selection = records.map(record => {
              return{
                _id:record._id, 
                title: record[vm.titleFieldId]
              }
            })
        else vm.selection = []
        vm.$emit('input', vm.selection)
      },

      radioChangeEvent ({checked, row}) {
        const vm = this
        vm.$refs.xTable.setCurrentRow(row)
        this.$emit('input', [row._id])
      },

      checkChangeEvent ({ checked, row }) {
        const vm = this
        console.log('checkChangeEvent : rows: ' + row._id + ': ' + (checked ? 'yes' : ' no'))
        if (checked) {
          console.log('addSelection row._id = ' + row._id)
          if(this.valType!='object')
            vm.addSelection(row._id)
          else
            vm.addSelection({_id:row._id, title: row[vm.titleFieldId]})
        } else {
          console.log('removeSelection row._id = ' + row._id)
          vm.removeSelection(row._id)
        }
      },

      addSelection (id) {
        const vm = this
        var selection = []
        if(vm.valType != 'object'){
          selection = vm.selection
          if (selection.indexOf(id) === -1) {
            vm.selection.push(id)
          }
        }else{
          selection = vm.selection.map(item=>item._id)
          if (vm.selection.indexOf(id._id) === -1) {
            vm.selection.push(id)
          }
        }
      },

      removeSelection (id) {
        const vm = this
        var selection = []
        var index
        if(vm.valType != 'object'){
          selection = vm.selection
          index = selection.findIndex(sel => sel === id)
        }
        else{
          selection = vm.selection.map(item=>item._id)
          index = selection.findIndex(sel => sel === id)
        }
        if (index >= 0) {
          vm.selection.splice(index, 1)
        }
      },

      toggleSelection (id) {
        const vm = this
        const index = vm.selection.findIndex(sel => sel === id)
        if (index >= 0) {
          vm.selection.splice(index, 1)
        } else {
          vm.selection.push(id)
        }
      },

      highlightRow (row) {
        const vm = this
        // console.log('highlightERow: row: ', row)
        const tblObjs = document.getElementsByClassName('selection-table-view')
        const rowObjs = tblObjs[0].getElementsByClassName('vxe-body--row')
        // console.log('row._id = ' + row._id)
        // console.log('row count = ' + rowObjs.length)
        vm.$nextTick(() => {
          for (let i = 0; i < rowObjs.length; i++) {
            const loopRowObj = rowObjs[i]
            //console.log('loopRowObj.getAttribute(data-rowid) = ' + (loopRowObj.getAttribute('data-rowid')))
            const highlight = loopRowObj.getAttribute('data-rowid') === row._id
            //console.log('highlight = ' + (highlight ? 'yes' : 'no'))
            if (highlight) {
              rowObjs[i].classList.add('row--current')
            } else {
              rowObjs[i].classList.remove('row--current')
            }
          }
        })
      },

      currentChangeEvent ({row}) {
        const vm = this
        // console.log('currentChangeEvent row: ', row)
        if (!vm.multiple) {
          this.$refs.xTable.toggleCheckboxRow(row)
          // console.log('currentChangeEvent :: row (id=' + row._id + ') : ', row)
          this.$refs.xTable.setRadioRow(row)
          if(this.valType != 'object')
            this.$emit('input', [row._id])
          else 
            this.$emit('input', [{_id: row._id, title: row[vm.titleFieldId]}])
          // console.log('currentChangeEvent :: vm.$refs.xTable: ', vm.$refs.xTable)

          vm.$nextTick(() => {
            vm.highlightRow(row)
          })
          vm.$forceUpdate()
        } else {
          if (!vm.$refs.xTable.isCheckedByCheckboxRow(row)) {
            // console.log('is not checked => set')
            vm.$refs.xTable.setCheckboxRow(row)
          }
        }
      },

      handlePageChange ({currentPage, pageSize}) {
        const vm = this
        vm.pagination.currentPage = currentPage
        vm.pagination.pageSize = pageSize
        vm.$refs.xTable.commitProxy('query')
        // vm.ajaxLoadData()
        // vm.loadData()
      },

      onWindowResized () {
        this.$refs.xTable.resetColumn()
      },
      ajaxLoadData (params) {
        // {page, sorts, filters, form}
        // alert('ajaxLoadData')
        const vm = this
        var searchValue = null
        if (vm.searchValue !== '') {
          searchValue = vm.searchValue
          // userFilters[firstFieldId] = vm.searchValue
        }
        
        const filterConfigs = {
          filters: vm.listingConfig.filterConfigs.filters,
          filterAndOr: vm.listingConfig.filterConfigs.filterAndOr
          // ,
          // userFilters: userFilters
        }
        
        const quickFilterConfigs = {
          filters: get(vm.listingConfig, 'quickFilterConfigs.filters', []),
          filterAndOr: get(vm.listingConfig, 'quickFilterConfigs.filterAndOr', 'and')
        }

        return new Promise((resolve, reject) => {
          var fetchParams = {}
          try {
            fetchParams = {
              relationFormId: vm.formInfo._id,
              displayFieldIds: vm.allTableDisplayFieldIds,
              filterConfigs: filterConfigs,
              quickFilterConfigs: quickFilterConfigs,
              keyword: searchValue ? vm.searchValue : null,
              sortingConfigs: vm.listingConfig.sortingConfigs,
              page: vm.pagination.currentPage,
              size:vm.pagination.pageSize,

              // exclusion
              exclusion: vm.exclusion,

              recordId: vm.recordId,
              formId:this.parentFormId,
              fieldId:this.fieldId,
              isPublic: this.$store.getters.isPublicRoute,
            }
          
          } catch (error) {
            console.log('error: ', error)
          }

          // console.log('ajaxLoadData :: fetchParams: ', fetchParams)

          // url: apiv2/selection/{teamId}/{formId}
          vm.$store.dispatch('FETCH_SELECTION_DATA', fetchParams).then(
            response => {
              vm.allExcluded = (response.excludedCount>0 && response.result.length===0)
              const tableData = response.result
              // set selection
              vm.setSelection(tableData)

              vm.pagination.currentPage = response.pagination.currentPage
              vm.pagination.pageSize = response.pagination.pageSize
              vm.pagination.totalResult = response.pagination.totalResult

              console.log('FETCH_SELECTION_DATA.then: response: ', tableData)
              resolve({
                page: {
                  total: response.pagination.totalResult
                }, 
                result: tableData
              });
              vm.fetchRelationDetails(response.result);              
              //resolve(tableData)
            }
          ).catch(error=>{
            vm.$toast.error(this.$t("errors.load"))
            resolve({
              page: {
                total: 0,
              },
              result: [],
            });
          });
        })
      },

      fetchRelationDetails (rows, extra=null) {
        const vm = this
        if (typeof rows === 'undefined' || rows === null) {
          rows = vm.$refs.xTable.tableData
        }
        const fieldIds = vm.fieldInfos.map(info => info.fieldId)
        fetchRelatedDataFieldsInRows(
          vm,
          rows,
          extra,
          this.$store.getters.isPublicRoute,
          vm.fieldInfos,
          fieldIds,
        ).then((response) => {
            vm.relationData.idDataMapping = response;
        })
      },

      setSelection (tableData) {
        const vm = this
        var selection = []
        if(vm.valType != 'object')
          selection = vm.selection
        else
          selection = vm.selection.map(item=>item._id)
        if (vm.multiple) {
          var selectedRows = []
          if (vm.selection) {
            selectedRows = tableData.filter(
              row => selection.includes(row._id)
            )
            vm.$refs.xTable.setCheckboxRow(selectedRows, true)
            //     if (selected) {
            //       vm.$refs.xTable.setCheckboxRow(row)
            //     }
            //     return selected
            //   }
            // )
          }
          // if (selectedRows.length > 0) {
          //   const firstRow = selectedRows[0]
          //   const rowIndex = tableData.findIndex(row => row._id === firstRow._id)
          //   vm.$refs.xTable.setCurrentRow(rowIndex)
          // }
        } else {
          console.log('setSelection :: vm.selection: ', vm.selection)
          if (vm.selection) {
            // console.log('vm.selection = ' + vm.selection)
            const selectedRow = tableData.find(row => row._id === vm.selection)
            if (selectedRow) {
              vm.$refs.xTable.setRadioRow(selectedRow)
              vm.$refs.xTable.setCurrentRow(selectedRow)
            }
          }
        }

      },

      refresh () {
        const vm = this
        console.log('refresh')
        vm.$refs.xTable.resetColumn()
        // console.log('refresh > call ajaxLoadData')
        // vm.ajaxLoadData()
        console.log('refresh > call xTable.commitProxy')
        vm.$refs.xTable.commitProxy('query')
      },

      search () {
        const vm = this
        console.log('SelectionTableView :: search')
        if (!vm.searchValue || vm.searchValue.length >= 3) {
          vm.$refs.xTable.commitProxy('query')
        }
      },
      updateFiltersHandler (payload) {
        const vm = this
        const filterIndex = payload.filterIndex
        const filter = payload.filter
        if(vm.quickFilters && filterIndex>=0){
          vm.quickFilters[filterIndex].relation = filter.relation
          vm.quickFilters[filterIndex].filterValue1 = filter.filterValue1
          vm.quickFilters[filterIndex].filterValue2 = filter.filterValue2
        }
      },
      updateFilterAndOrHandler (filterAndOr) {
        this.quickFilterAndOr = filterAndOr
      },
    }
  }
</script>

<style>
  .selection-table-view .vxe-grid {
    flex-grow: 1;
    cursor: pointer;
  }

  .selection-table-view .vxe-pager {
    background-color: rgba(128,128,128,.2);
  }


</style>
