const mixin = {
  computed: {
    hiddenItems () {
      return this.$store.getters.hiddenItems
    }
  },
  methods: {
    isHiddenForDeployment (itemName) {
      return this.hiddenItems.includes(itemName)
    },
    isDeploymentHiddenItem () {
      const vm = this
      var result = false
      if (vm.type === 'module' && vm.$store.getters.hiddenItems.includes('module_setup')) {
        result = true
      }
      if (vm.type === 'view' && vm.$store.getters.hiddenItems.includes('view_setup')) {
        result = true
      }
      return result
    }
  }
}

export default mixin
