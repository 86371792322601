<template>
  <v-card flat class="py-2 px-12">
    <v-card-title class="d-flex align-center" style="font-size:18px">
      <div class="black--text">{{ $t("displayRule.displayRule") }}</div>
      <v-btn
        fab
        @click="addRule"
        small
        class="elevation-0 ml-4"
        color="primary"
        style="width:22px; height:22px"
        outlined
      >
        <v-icon size="16" class="" style="min-width:9px; width:20px"
          >mdi-plus</v-icon
        >
      </v-btn>

    </v-card-title>
    <div
      v-if="!rules.length"
      class="grey--text d-flex justify-center align-center"
      style="font-size:15px; height:200px"
    >
      {{ $t("displayRule.emptyMsg") }}
    </div>
    <v-container style="overflow: auto; height: 70vh" class="">
      <v-row class="">
        <v-col cols="12 px-4" v-for="(rule, index) in rules" :key="index">
          <v-hover v-slot="{ hover }">
            <v-card tile outlined style="min-height: 100px; font-size:14px">
              <div
                class="float-right mr-1 mt-1"
                :class="!hover ? 'd-none' : ''"
              >
                <v-hover v-slot="{ hover }">
                  <v-icon
                    @click="editRule(index)"
                    style="cursor: pointer"
                    class="px-1"
                    size="18"
                    :color="hover ? 'info' : ''"
                    >mdi-pencil</v-icon
                  >
                </v-hover>
                <v-hover v-slot="{ hover }">
                  <v-icon
                    @click="deleteRule(index)"
                    style="cursor: pointer"
                    class="px-1"
                    size="18"
                    :color="hover ? 'red' : ''"
                    >mdi-delete</v-icon
                  >
                </v-hover>
                <v-switch
                  color="info"
                  v-model="rule.active"
                  @change="handleActiveChange(rule._id, $event)"
                ></v-switch>
              </div>
              <div class="d-flex align-center">
                <div
                  style="font-size:13px; border: 1px solid grey"
                  :class="rule.active ? 'info' : 'grey'"
                  class="white--text d-inline-block px-1 nowrap-ellipsis"
                >
                  {{ rule.name }}
                </div>
                <div class="ml-2" style="font-size:12px; color: grey">
                  {{ rule.conditions.length }}{{ $t("displayRule.item")
                  }}{{ $t("displayRule.condition") }}，{{ rule.actions.length
                  }}{{ $t("displayRule.set") }}{{ $t("displayRule.action") }}
                </div>
              </div>
              <div class="grey--text px-4 pt-2">
                <span class="primary--text">{{ $t("displayRule.when") }}</span>
                <span v-for="(cond, index2) in rule.conditions" :key="index2">
                  <span class="" v-for="(entry, index3) in cond" :key="index3">
                    {{ getFieldLabel(entry.fieldId) }}
                    <u
                      class=""
                      v-if="
                        entry.fieldType != 'yesNo' &&
                          entry.fieldType != 'signature' &&
                          entry.fieldType != 'attachments'
                      "
                      >{{
                        $t("filters." + criteriaConfigs[entry.content.rule].t)
                      }}</u
                    >
                    <u v-else>{{ $t("displayRule.is") }}</u>
                    {{
                      entry.content.type == 1
                        ? getContentVal(
                            entry.content.value,
                            entry.content.rule,
                            entry.fieldType,
                            entry.fieldId
                          )
                        : getFieldLabel(entry.content.value)
                    }}
                    <span
                      v-if="index3 != cond.length - 1"
                      class="teal white--text"
                      style="padding: 2px 4px"
                      >{{ $t("displayRule.and") }}</span
                    >
                  </span>
                  <span
                    class="warning white--text"
                    style="padding: 2px 5px"
                    v-if="index2 != rule.conditions.length - 1"
                    >{{ $t("displayRule.ok") }}</span
                  >
                </span>
              </div>
              <div class="grey--text px-4 pt-1 pb-3">
                <div v-for="(action, index2) in rule.actions" :key="index2">
                  <span class="primary--text">{{
                    getAction(action.actionId)
                  }}</span>
                  <span v-for="(field, index3) in action.fields" :key="index3">
                    {{ getFieldLabel(field) }}
                    <span v-if="index3 != action.fields.length - 1">,</span>
                  </span>
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
    <EditRuleDialog
      v-if="bl_editRuleDialog"
      :ruleIdArr="ruleIdArr"
      @updateRules="updateRules"
      ref="EditRuleDialog"
      :formFieldInfos="formFieldInfos"
      v-model="bl_editRuleDialog"
      :formData="formData"
      @setSnackbar="setSnackbar"
      :formUIElementInfos="formUIElementInfos"
    />


    <v-snackbar
      min-width="200"
      :color="snackbar.color"
      top
      right
      v-model="snackbar.val"
      >{{ snackbar.text }}</v-snackbar
    >
  </v-card>
</template>

<script>
import EditRuleDialog from "./dialogs/EditRuleDialog";

import { getContentVal } from "@/helpers/RuleHelper";

export default {
  name: "TableSetupDisplayRules",
  props: {
    formFieldInfos: Array,
    formUIElementInfos: Array,
  },
  components: {
    EditRuleDialog,
  },
  data() {
    return {
      bl_editRuleDialog: false,
      rules: [],
      formData: null,
      snackbar: {
        val: false,
        text: "",
        color: "success",
      },
    };
  },
  watch: {
    bl_editRuleDialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.EditRuleDialog.ruleName = "";
          this.$refs.EditRuleDialog.conditions = [];
          this.$refs.EditRuleDialog.actions = [];
        });
      }
    },
  },
  computed: {
    relatedTableInfos() {
      return this.$store.getters.relatedTableInfos;
    },
    ruleIdArr() {
      return this.rules.map((item) => item._id);
    },
    criteriaConfigs() {
      return this.$store.getters.criteriaConfigs;
    },
  },
  methods: {
    setSnackbar(snackbar) {
      this.snackbar = snackbar;
    },
    getContentVal(val, rule, fieldType, fieldId) {
      var field = this.getField(fieldId);

      if (fieldType == "otherTableField") {
        var relatedRecord = this.getField(
          field.properties.fieldForRelatedTable
        );
        var relatedTableId = relatedRecord.properties.dataSource;
        var relatedField = this.relatedTableInfos[
          relatedTableId
        ].fieldInfos.find(
          (item) => item.fieldId == field.properties.relatedTableField
        );
        return getContentVal(
          val,
          rule,
          relatedField.type,
          relatedField,
          this.relatedTableInfos
        );
      } else if (fieldType == "relatedRecord") {
        var relatedTable = this.relatedTableInfos[field.properties.dataSource];
        var titleField = relatedTable.fieldInfos.find(
          (item) => item.fieldId == relatedTable.titleFieldInfoId
        );
        if (!val) val = [];
        val = val.map((item) => item.title);
        if (titleField.type == "text") {
          fieldType = fieldType + "_text";
        } else if (titleField.type == "members") {
          fieldType = fieldType + "_members";
        } else fieldType = titleField.type;
        return getContentVal(val, rule, fieldType, titleField);
      } else
        return getContentVal(
          val,
          rule,
          fieldType,
          field,
          this.relatedTableInfos
        );
    },
    getField(fieldId) {
      return this.formFieldInfos.find((item) => item._id == fieldId);
    },
    updateRules(rules) {
      this.rules = JSON.parse(JSON.stringify(rules));
    },

    async fetchDisplayRule() {
      var formId = this.$route.params.itemId;
      var getParams = {
        urlCommand: "/displayRule/" + formId,
      };
      await this.$store.dispatch("AUTH_GET", getParams).then((response) => {
        this.rules = response;
      });
    },
    getFieldLabel(fieldId) {
      var res = this.formFieldInfos
        .concat(this.formUIElementInfos)
        .find((item) => item._id == fieldId);
      return res ? res.label : "";
    },
    getAction(actionId) {
      switch (parseInt(actionId)) {
        case 1:
          return this.$t("displayRule.show");
        case 2:
          return this.$t("displayRule.hide");
        case 3:
          return this.$t("displayRule.prompt");
      }
    },
    async deleteRule(index) {
      var formId = this.$route.params.itemId;
      var ruleId = this.rules[index]._id;
      const postData = {
        urlCommand: "/displayRule/delete/" + formId + "/" + ruleId,
      };
      await this.$store.dispatch("AUTH_POST", postData).then((response) => {
        if (response.success) {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.display_rule.DELETE_DISPLAY_RULE,
            }
          });
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_table_setup_menu",
            data: {
              function_button: this.$sensors.events.plus_sheet_mani.display_rule.DELETE_DISPLAY_RULE,
            }
          });
          this.snackbar = {
            val: true,
            text: this.$t("displayRule.successDelete"),
            color: "success",
          };
          this.rules.splice(index, 1);
          this.$store.dispatch("SET_DISPLAY_RULE", {
            rules: response.rules,
          });
        } else {
          this.snackbar = {
            val: true,
            text: response.errMsg,
            color: "error",
          };
        }
      });
    },
    async handleActiveChange(ruleId, event) {
      var formId = this.$route.params.itemId;
      const postData = {
        urlCommand:
          "/displayRule/setActive/" + formId + "/" + ruleId + "/" + event,
      };
      await this.$store.dispatch("AUTH_POST", postData).then((response) => {
        if (response.success) {
          this.snackbar = {
            val: true,
            text: this.$t("displayRule.successSave"),
            color: "success",
          };
          this.$store.dispatch("SET_DISPLAY_RULE", {
            rules: response.rules,
          });
        } else {
          this.snackbar = {
            val: true,
            text: response.errMsg,
            color: "error",
          };
        }
      });
    },
    editRule(index) {
      this.formData = JSON.parse(JSON.stringify(this.rules[index]));
      this.bl_editRuleDialog = true;
    },
    addRule() {
      this.formData = null;
      this.bl_editRuleDialog = true;
    },

  },
  mounted() {
    this.fetchDisplayRule();
  },
};
</script>

<style scoped>
.v-menu__content {
  box-shadow: unset !important;
}
</style>
