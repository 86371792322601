<template>
  <drop-list
    :items="items"
    class="row align-start drop-list-row my-1"
    style="position:relative;"
    :accepts-data="(d) => acceptData(d)"
    @insert="onInsertToRow"
    @reorder="$event.apply(items)"
    mode="cut"
    :row="true"
  >
    <template v-slot:item="{ item }">
      <drag
        :key="item._id"
        class="col drag-item"
        :class="'col-' + item.width"
        :data="item"
        type="item"
        @cut="removeItem(item)"
      >
        <layout-widget
          :item="item"
          :fieldInfo="getFieldInfo(item._id)"
          :remainingWidth="remainingWidth"
          :alias="getAlias(item._id)"
          :customDescription="getDescription(item._id)"
          @commandHandler="onCommandHandler"
          :publicForm="publicForm"
        >
          <template v-slot:content>          
            <slot name="content">
            </slot>
          </template>
         
          <template v-slot:controlButtons>
            <slot
              name="controlButtons"
              :item="item"
              :rowIndex="rowIndex"
            ></slot>
          </template>
        </layout-widget>
        <div>
          <slot
            name="appendedButtons"
            :item="item"
            :rowIndex="rowIndex"
          ></slot>
        </div>
      </drag>
    </template>
    <template v-slot:feedback>
      <div class="feedback" key="feedback"></div>
    </template>
  </drop-list>
</template>
<script>
import { Drag, Drop, DropList } from "vue-easy-dnd";
import layoutWidget from "./layoutWidget";
export default {
  props: {
    rowIndex: Number,
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    fieldInfos: {
      type: Array,
    },
    customFieldProperties: {
      type:Object
    }
  },
  components: {
    Drag,
    Drop,
    DropList,
    layoutWidget,
  },
  computed: {
    totalOccupiedWidth() {
      return this.items.reduce((total, item) => {
        return total + item.width;
      }, 0);
    },
    remainingWidth() {
      return 12 - this.totalOccupiedWidth;
    },
  },
  methods: {
    getFieldInfo(fieldId) {
      return this.fieldInfos.find((info) => info.fieldId === fieldId);
    },
    getAlias(fieldId){
      return this.customFieldProperties && this.customFieldProperties[fieldId] ? this.customFieldProperties[fieldId].alias : ""
    },
    getDescription(fieldId){
      return this.customFieldProperties && this.customFieldProperties[fieldId] ? this.customFieldProperties[fieldId].description : ""
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "setWidth": {
          this.$emit("commandHandler", {
            ...payload,
            width: payload.width === 0 ? this.remainingWidth: payload.width,
            rowIndex: this.rowIndex,
          });
        }

          break;
        default:
          this.$emit("commandHandler", payload);
          break;
      }
    },
    removeItem(item) {
      this.$emit("commandHandler", {
        command: "removeItem",
        item,
        rowIndex: this.rowIndex,
      });
    },
    onInsertToRow(event) {
      let dataWidth = event.data.width;
      if (dataWidth > this.remainingWidth) dataWidth = this.remainingWidth;

    this.items.splice(event.index, 0, {...event.data, width:dataWidth});

    },
    acceptData(data) {
      if (this.remainingWidth === 0) return false;
      else {
        const fieldInfo = this.getFieldInfo(data._id);
        return fieldInfo ? fieldInfo.properties.hasOwnProperty("width") : false;
      }
    },
  },
};
</script>
<style scoped></style>
