<template>
  <v-layout align-center justify-space-between class="workform-clip-dropdown-option">
    <div class="workform-clip-dropdown-source">
      <v-icon v-if="isBranch && prefixIcon" small class="workform-clip-dropdown-icon">
        mdi-tune-vertical
      </v-icon>
      <span v-if="isBranch" class="workform-clip-dropdown-branch-label">
        {{ label }}
      </span>
      <span v-else class="workform-clip-dropdown-label">
        <span class="workform-clip-dropdown-label-prefix">
          <v-chip small class="workform-clip-dropdown-chip">
            {{ $t(`widgets.${item.fieldType}`) }}
          </v-chip>
        </span> {{ selectionLabel }}
      </span>
    </div>
  </v-layout>
</template>

<script>
  import SettingUtils from '@/pages/admin/workflow/utils/setting';
  export default {
    props: {
      item: Object,
      prefixIcon: Boolean,
      shouldShowCount: Boolean,
      isBranch: Boolean,
      renderLabel: Function,
    },
    computed: {
      label() {
        return SettingUtils.delegatedComboLabel(this.item, this.$t('workflow'));
      },
      selectionLabel() {
        return SettingUtils.delegatedComboSelectionLabel(this.item, this.$t('workflow'));
      },
    }
  }
</script>

<style>
</style>
