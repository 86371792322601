var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-table-column',_vm._b({attrs:{"className":"fullCell vxeCellMembers","show-overflow":true,"edit-render":{ name: 'input', autoselect: true, enabled: !_vm.readonly }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:" d-flex flex-row flex-wrap align-center",class:_vm.rowHeightClass,staticStyle:{"overflow":"hidden"},style:({
        paddingTop: _vm.rowHeightClass === 'vxe-table-row-xs' ? '0px' : '',
      })},_vm._l((row[_vm.fieldId]),function(employeeInfo){return _c('selected-person-tag',{key:employeeInfo._id,staticClass:"mt-1",attrs:{"employeeId":employeeInfo._id,"name":employeeInfo.employeeName,"teamId":employeeInfo.teamId,"info":employeeInfo,"teamShowing":_vm.teamShowing,"hasCloseButton":false}})}),1),(_vm.quickInsert)?_c('quick-action',{attrs:{"rowId":row['_id'],"fieldId":_vm.fieldId}}):_vm._e()]}},{key:"edit",fn:function(ref){
      var row = ref.row;
return [(_vm.isDialog)?_c('div',{on:{"click":function($event){!_vm.readonly && row.canEdit ? _vm.openEmployeesDialog(row, $event) : ''}}},[_c('v-input',{staticClass:"pa-1 pb-0 w-100 mb-0 vxe-table-input-box",class:(_vm.readonly || !row.canEdit) && row[_vm.fieldId].length == 0
            ? 'd-none'
            : '',staticStyle:{"position":"absolute","top":"0","left":"0","min-height":"100%"},attrs:{"hide-details":""}},[_c('div',_vm._l((row[_vm.fieldId]),function(employeeInfo,index){return _c('selected-person-tag',{key:employeeInfo._id,staticClass:"mt-1",attrs:{"index":index,"employeeId":employeeInfo._id,"name":employeeInfo.employeeName,"teamId":employeeInfo.teamId,"info":employeeInfo,"teamShowing":_vm.teamShowing,"hasCloseButton":!_vm.readonly,"deleteCommand":"removeEmployeeByIndex","clickCommand":"removeEmployeeByIndex","bl_list":!_vm.readonly,"readonly":_vm.readonly},on:{"onCommand":function($event){return _vm.onCommandHandler($event, row)}}})}),1)])],1):_c('MembersMenu',{attrs:{"fieldId":_vm.fieldId,"fullWidth":false,"type":_vm.isMultiple ? 'multiple' : 'single'},on:{"memberClick":function($event){return _vm.onMemberClick(row, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-input',_vm._g({staticClass:"fill-height v-text-field--outlined mb-0",staticStyle:{"background-color":"white"},attrs:{"hide-details":""}},on),_vm._l((row[_vm.fieldId]),function(employeeInfo,index){return _c('selected-person-tag',{key:employeeInfo._id,staticClass:"mt-1",attrs:{"index":index,"employeeId":employeeInfo._id,"name":employeeInfo.employeeName,"teamId":employeeInfo.teamId,"info":employeeInfo,"teamShowing":_vm.teamShowing,"hasCloseButton":!_vm.readonly,"deleteCommand":"removeEmployeeByIndex","clickCommand":"removeEmployeeByIndex","bl_list":!_vm.readonly,"readonly":_vm.readonly},on:{"onCommand":function($event){return _vm.onCommandHandler($event, row)}}})}),1)]}}],null,true)})]}},{key:"footer",fn:function(ref){
            var column = ref.column;
return [_c('summary-cell',{attrs:{"decimals":_vm.decimals,"column":column,"labelValuePairs":_vm.getSummaryLabelValuePairs(column)},on:{"click":_vm.selectCalcMethod}})]}}])},'vxe-table-column',_vm.colDef,false))}
var staticRenderFns = []

export { render, staticRenderFns }