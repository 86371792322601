<template>
    <div>
        <div class="d-flex align-end" style="margin-top: 2px">
            <div style="font-size:12px" class="d-flex align-center">
                <div>{{field.label}}</div>
                <div class="ml-2 grey--text" style="border-bottom: 1px dashed #c5c5c5; line-height:11px">{{field.type}}</div>
            </div>
            <div class="ml-auto">
                <v-menu offsetY>
                    <template v-slot:activator="{on}">
                        <div v-on="on" :class="operatorColor" class="white--text rounded-sm d-inline" style="padding: 2px 10px;cursor: pointer; border: 1px dashed #c5c5c5; font-size: 12px">
                            {{field.operator}}
                            <v-icon dark style="min-width:0px; width:0px" class="pl-2">
                                mdi-chevron-down
                            </v-icon>
                        </div>
                    </template>
                    <v-card style="" tile flat outlined>
                        <v-list dense class="pa-0" style="font-size: 12px;">
                            <v-list-item-group v-model="field.operator" color="info" mandatory>
                                <v-list-item :value="opt.id" v-for="opt in operators" :key="opt.id" dense style="border-bottom: #eeeeee 1px solid; min-height: 22px">
                                    {{opt.label}}
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
        </div>
        <div class="pa-0 mt-2" :class="!field.filterVal.length?'pb-1':''" v-if="field.operator!='empty' && field.operator!='notEmpty'">
            <v-btn small fab elevation="0" @click="showingDialog=true" class="pa-0 grey lighten-3" height="25" width="25" v-if="!field.filterVal.length">
                <span style="font-size:13px" class="grey--text">+</span>
            </v-btn>
            <selected-department-tag
                @click.native="showingDialog=true"
                style="height: 21px !important"
                v-for="(departmentInfo,index) in field.filterVal"
                :key="departmentInfo._id"
                :index="index"
                :hasCloseButton="false"
                :departmentId="departmentInfo._id"
                :name="departmentInfo.label"
                deleteCommand="removeDepartmentByIndex"
                clickCommand="removeDepartmentByIndex"
                color="light"
            ></selected-department-tag>
        </div>
        <DepartmentsDialog
            v-if="showingDialog"
            v-model="showingDialog"
            @confirm="field.filterVal = $event"
            :selectedDepts="field.filterVal"
            :isMultiple="true"
        />
    </div>
</template>

<script>
    import selectedDepartmentTag from "@/components/dialogs/comps/SelectedDepartmentTag";
    import DepartmentsDialog from "@/components/dialogs/DepartmentsDialog";
    export default {
        props:{
            field: Object
        },
        components:{
            selectedDepartmentTag,
            DepartmentsDialog
        },
        data(){
            return{
                size: 1,
                operator: 'is',
                operators: [
                    {
                        id: 'is',
                        label: 'is',
                        color: 'success'
                    },
                    {
                        id: 'not',
                        label: 'not',
                        color: 'error'
                    },
                    {
                        id: 'include',
                        label: 'include',
                        color: 'success'
                    },
                    {
                        id: 'notInclude',
                        label: 'notInclude',
                        color: 'error' 
                    },
                    {
                        id: 'empty',
                        label: 'empty',
                        color: 'secondary'
                    },
                    {
                        id: 'notEmpty',
                        label: 'notEmpty',
                        color: 'secondary'
                    }
                ],
                value: {
                    dept: [],
                },
                showingDialog: false
            }
        },
        computed:{
            operatorColor(){
                return this.operators.find(item=>item.id==this.field.operator).color
            }
        },
        methods:{
            onCommandHandler(event, departmentInfo){
                alert(event.command)
                if(event.command == 'removeDepartmentByIndex'){
                    this.field.filterVal.splice(event.index, 1)
                }
            }
        }
    }
</script>

<style>
    .filterTextField .v-input__control .v-input__slot{
        min-height: 20px !important;
    }
</style>