<template>
  <v-select
    :value="userSelection"
    @change="val=>{update(val)}"
    :items="teams"
    item-text="name"
    item-value="_id"
    deletable-chips
    chips
    class="team-selection"
    :hide-details="true"
    dense
    outlined
    solo
    multiple></v-select>
</template>

<script>
export default {
  name: 'accessibleTeamSelection',
  props: {
    selection: Array,
    teams: Array,
    u: Array
  },
  computed: {
    teamIds () {
      return this.teams.map(team => team._id)
    },
    userSelection () {
      return this.selection.filter(id => this.teamIds.includes(id))
    }
  },
  methods: {
    update (val) {
      const vm = this
      vm.$emit('update', val)
    }
  }
}
</script>

<style>
.team-selection .v-chip--select {
  height: 28px !important;
  min-height: 28px !important;;
  margin-bottom:2px;
}
</style>