<template>
    <v-dialog 
        width="800" 
        v-model="showingDialog" 
        class="editTaskDialog"
        content-class="editTaskDialogContent"
    >
        <v-card v-if="task">
            <!-- $t('gantt.editTask')+ ' - ' +  -->
            <dialog-header
                :label="task.name"
                @close="showingDialog=false"
            />
            <v-card-text class="pa-12">
                <v-container>
                    <v-row no-gutters class="my-2">
                        <v-col cols="4">
                            <div class="d-flex align-end" style="height:100%">
                                <div class="">
                                    {{getFieldById(ganttSetting.col_project).label}}
                                </div>
                                <v-chip 
                                    x-small 
                                    style="font-size:12px"
                                    class="ml-2 mb-1"
                                >
                                    專案欄位
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col class="">
                            <div class="d-flex align-start">
                                <v-text-field
                                    v-model="task.name"
                                    style="font-size:14px"
                                    dense
                                    hide-details
                                    height="24"
                                    filled
                                >
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-2">
                        <v-col cols="4">
                            <div class="d-flex align-end" style="height:100%">
                                <div class="">
                                    {{getFieldById(ganttSetting.col_progress).label}}
                                </div>
                                <v-chip 
                                    x-small 
                                    style="font-size:12px"
                                    class="ml-2 mb-1"
                                >
                                    完成度
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col class="">
                            <div class="d-flex align-start">
                                <v-text-field
                                    v-model="task.progress"
                                    style="font-size:14px"
                                    dense
                                    hide-details
                                    height="24"
                                    filled
                                >
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-2">
                        <v-col cols="4">
                            <div class="d-flex align-end" style="height:100%">
                                <div class="">
                                    {{getFieldById(ganttSetting.col_start).label}}
                                </div>
                                <v-chip 
                                    x-small 
                                    style="font-size:12px"
                                    class="ml-2 mb-1"
                                >
                                    開始時間
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col class="">
                            <div class="d-flex align-start">
                                <v-text-field
                                    style="font-size:14px"
                                    dense
                                    hide-details
                                    height="24"
                                    filled
                                    v-model="task.start"
                                >
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-2">
                        <v-col cols="4">
                            <div class="d-flex align-end" style="height:100%">
                                <div class="">
                                    {{getFieldById(ganttSetting.col_end).label}}
                                </div>
                                <v-chip 
                                    x-small 
                                    style="font-size:12px"
                                    class="ml-2 mb-1"
                                >
                                    結束時間
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col class="">
                            <div class="d-flex align-start">
                                <v-text-field
                                    style="font-size:14px"
                                    dense
                                    hide-details
                                    height="24"
                                    filled
                                    v-model="task.end"
                                >
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <dialog-actions
                :buttons="['cancel','ok']"
                @onCommand="onCommandHandler($event)">
            </dialog-actions>
            <!-- {{task}} -->
            <!-- {{ganttSetting}} -->
        </v-card>
    </v-dialog>
</template>

<script>
    import dialogActions from "@/components/dialogs/comps/DialogActions";
    import dialogHeader from "@/components/dialogs/comps/DialogHeader";
    export default {
        props:{
            value: Boolean,
            ganttSetting: Object,
            form: Object
        },
        components:{
            dialogActions,
            dialogHeader
        },
        computed:{
            showingDialog:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            fieldInfos(){
                return this.form.fieldInfos
            }
        },
        data(){
            return{
                task: null
            }
        },
        methods:{
            onCommandHandler(payload){
                switch(payload.command){
                    case 'cancel':
                        this.showingDialog = false
                        break
                    case 'ok':
                }
            },
            getFieldById(id){
                return this.fieldInfos.find(field=>field.fieldId==id)
            }

        }

    }
</script>

<style>
    .editTaskDialogContent .v-input__control > .v-input__slot{
        min-height: 28px !important
    }
    .editTaskDialogContent .v-text-field > .v-input__control > .v-input__slot:before{
        border-style: none !important
    }
</style>