<template>
<div>
  <!--FIXME: hardcode value-->
    <v-text-field
      :error="error"
      ref="datetime"
      v-model="userValue"
      :label="label"
      :rules="rules"
      class="workflow-customized-form-record-element"
      prepend-inner-icon="mdi-calendar-clock"
      v-bind="textFieldProps"
      hint="e.g. 2020-12-31 13:15"
      :hide-details="isLayout"
      dense
      flat
      outlined
      :readonly="isReadOnly"
      solo
      @click:prepend-inner="showDialog"
      @click.stop="handleFieldClick"
      @focus="handleFieldClick"
      :tabindex="tabindex"
    />
  <v-dialog
      ref="dialog"
      v-model="showing"
      v-if="showing"
      :return-value.sync="dialogValue"
      content-class="auto-width"
     >
    <v-card v-if="showing&&!isReadOnly" class="auto-width">
      <v-card-text class="px-0">
        <v-row no-gutters>
          <v-date-picker
            class="no_radius"
            v-model="dialogDateValue"
            scrollable
            flat
            :locale="$i18n.locale"
            no-footer
            :allowed-dates="allowedDates"
            :min="dateMin"
          /> 
          <v-time-picker
            class="no_radius"
            v-model="dialogTimeValue"
            scrollable
            no-footer
            :locale="$i18n.locale"
            :min="minTime"
            :max="maxTime"
          >
          </v-time-picker>     
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn
              class="min-width-100"
              color="muted"
              @click="close">
            {{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn
              class="min-width-100 ml-1"
              color="primary"
              @click="confirm"
              :disabled="dialogTimeValue =='' || dialogDateValue==''">
            {{ $t('buttons.ok') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
  import mixin from './_mixin'
  import moment from "moment";

  const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
  const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
  const DEFAULT_DIALOG_WIDTH = 340
  const DEFAULT_CLEAR_TEXT = 'CLEAR'
  const DEFAULT_OK_TEXT = 'OK'

  export default {
    name: 'dialogFieldDateTime',
    mixins: [mixin],
    props: {
      rules: Array,
      dialogWidth: {
        type: Number,
        default: DEFAULT_DIALOG_WIDTH
      },
      dateFormat: {
        type: String,
        default: DEFAULT_DATE_FORMAT
      },
      timeFormat: {
        type: String,
        default: 'HH:mm'
      },
      clearText: {
        type: String,
        default: DEFAULT_CLEAR_TEXT
      },
      okText: {
        type: String,
        default: DEFAULT_OK_TEXT
      },
      textFieldProps: {
        type: Object
      },
      datePickerProps: {
        type: Object
      },
      timePickerProps: {
        type: Object
      },
      minTime: String,
      maxTime: String,
      error: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeTab: 0,
        showing: false
        // ,
        // date: DEFAULT_DATE,
        // time: DEFAULT_TIME
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      dialogDateValue: {
        get () {
          console.log('dialogDateValue :: dialogValue: ', this.dialogValue)
          return this.dialogValue.split(' ')[0]
        },
        set (val) {
         this.dialogValue = val + ' ' + this.dialogTimeValue
        }
      },
      dialogTimeValue: {
        get () {
          console.log('dialogTimeValue :: dialogValue: ', this.dialogValue)
          return this.dialogValue.split(' ')[1] || ''
        },
        set (val) {
          this.dialogValue = this.dialogDateValue + ' ' + val
        }
      },
      dateTimeFormat() {
        return this.dateFormat + ' ' + this.timeFormat
      },
      defaultDateTimeFormat() {
        return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
      },
      selectedDatetime() {
        if (this.dialogDateValue && this.dialogTimeValue) {
          return this.dialogDateValue + ' ' + this.dialogTimeValue
        //   let datetimeString = this.date + ' ' + this.time
        //   if (this.time.length === 5) {
        //     datetimeString += ':00'
        //   }
        //   return parse(datetimeString, this.defaultDateTimeFormat, new Date())
        // } else {
        //   return null
        } else {
          return ''
        }
      },
      dateSelected() {
        return !this.date
      }
    },
    methods: {
      resetValidation() {
        this.$refs.datetime.resetValidation()
      },
      getDefaultValue () {
        return this.defaultType==='auto' ? this.formatDate() + ' ' + this.formatTime() : ''
      },
      init() {
        if (!this.datetime) {
          return
        }

        let initDateTime
        if (this.datetime instanceof Date) {
          initDateTime = this.datetime
        } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
          // see https://stackoverflow.com/a/9436948
          initDateTime =  moment(this.datetime);
        }

        this.date = initDateTime.format(DEFAULT_DATE_FORMAT)
        this.time = initDateTime.format(DEFAULT_TIME_FORMAT)
      },
      _confirm() {
        // this.resetPicker()
        //this.userValue = this.selectedDatetime
         this.$emit('input', this.selectedDatetime)
      },
      // clearHandler() {
      //   this.resetPicker()
      //   this.date = DEFAULT_DATE
      //   this.time = DEFAULT_TIME
      //   this.$emit('input', null)
      // },
      resetPicker() {
        this.showing = false
        this.activeTab = 0
        if (this.$refs.timer) {
          this.$refs.timer.selectingHour = true
        }
      },
      showTimePicker() {
        this.activeTab = 1
      }
    },
    watch: {
      datetime: function() {
        this.init()
      }
    }
  }
</script>
