<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :isHidden="isHidden"
      :required="required"
      :fieldWidth="fieldWidth"
      :noIcon="noIcon"
      :label="fieldName"
      :tooltipText="description">
    <div :class="{ 'workflow-customize-form-record-hide': hideInput }">
      <v-input 
        v-if="isReadOnly && 
            (!fieldInfo.properties.usage || 
              (fieldInfo.properties.usage&&fieldInfo.properties.usage!='tab')
            )" 
        disabled
      >
        <div class="pa-1 min-h-36 w-100 mb-0 " style="border-bottom: 1px dashed darkgray; ">
          <optionButton
            v-if="inputValue"
            :useColor="useColor"
            :key="inputValue"
            :value="inputValue"
            :option="selectedOption"
          />
        </div>
      </v-input>
      <input-widget-toggle
          v-else-if="selectionFieldType==='flatMenu' && selectOptions.length>0"
          toggleType="radio"
          :ref="fieldInfo.fieldId"
          :rules="rules"
          :options="selectOptions"
          :direction="fieldInfo.properties.direction"
          :fieldType="fieldInfo.properties.fieldType"
          :useColor="useColor"
          v-model="inputValue"
          :readonly="false"
        ></input-widget-toggle>
      <pulldown-chip-selection
          v-else
          :isLayout="isLayout"
          :class="{ 'workflow-customize-form-record-hide': hideInput }"
          :readonly="isReadOnly"
          :items="selectOptions"
          :multiple="isMultiple"
          :fullListOptions="inputOptionsValue.options"
          :useColor="useColor"
          item-text="label"
          item-value="id"
          @onDelete="onDeleteHandler"
          :fieldId="fieldId"
          :allowAdd="allowAdd"
          v-model="inputValue"></pulldown-chip-selection>
      <div v-if="errorMessage"
          class="selection-message-line error--text" style="font-size:12px">{{ errorMessage }}</div>
      <div v-else
          class="selection-message-line caption">{{ hints }}</div>
         <!-- {{fieldInfo.properties}} -->
    </div>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import selectionMixin from "./comps/selectionMixin"
import inputWidgetContainer from "./comps/InputWidgetContainer";
import inputWidgetToggle from "./comps/InputWidgetToggle";
import pulldownChipSelection from "./comps/PulldownChipSelection";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import optionButton from "@/components/vxeTableCells/comps/OptionButton";

export default {
  name: "inputWidgetSingleSelection",
  mixins: [mixin, selectionMixin],
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  components: {
    inputWidgetToggle,
    inputWidgetContainer,
    pulldownChipSelection,
    optionButton,
  },
  computed: {
    useColor() {
      return this.inputOptionsValue.useColor;
    },
    inputOptionsValue() {
      return getFieldPropertyValue(this.fieldInfo, "inputOptionsSingle", null);
    },
    hints() {
      return "";
    },
    selectionFieldType() {
      return getFieldPropertyValue(this.fieldInfo, "fieldType", "flatMenu"); // or 'pulldownMenu'
    },
    options(){
      return this.inputOptionsValue && this.inputOptionsValue.options || []
    },
    selectedOption(){
      return this.options.find(option => option._id === this.inputValue)
    }
  },
  data() {
    return {
      RULE_REQUIRED: (v) => {
        const vm = this;
        const message = this.$t("messages.thisFieldIsRequired");
        const result = Boolean(v) || message;
        if (result !== true) {
          vm.errorMessage = result;
        }
        return result;
      },
    };
  },
  methods: {
    validate(){
      if (this.isReadOnly) return true;
      if(this.rules.length > 0) {
        if (this.rules.some(rule=> rule(this.value) !== true)) {
          this.hasError = true
          return false
        }
      }
      return true
    },
    _onParseValue(val) {
      const vm = this;
      console.log("_onParseValue : val = " + val);
      var result = val;
      if (vm.isLayout) {
        result = vm.getDefaultPropertyValue();
      }
      return result;
    },

  },
  watch: {
    inputValue: function(newVal) {
      if (!this.isLayout && this.rules.length > 0) {
        for (var i = 0; i < this.rules.length; i++) {
          const rule = this.rules[i];
          const result = rule(newVal);
          if (result !== true) {
            this.errorMessage = result;
            return;
          }
        }
        this.errorMessage = "";
        return;
      }
    },
  },
};
</script>
