<template>
  <notifications
    group="background-job"
    position="bottom left"
    class="bk-job-noti"
  >
    <template slot="body" slot-scope="props">
      <v-card class="ma-4">
        <v-card-title>
          {{ props.item.title }}
          <v-spacer />
          <v-progress-circular
            v-if="!props.item.data"
            indeterminate
            color="primary"
            size="17"
          ></v-progress-circular>
          <v-icon v-else @click="props.close">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div v-html="props.item.text"></div>
        </v-card-text>
      </v-card>
    </template>
  </notifications>
</template>
<script>
export default {
  name: "BackgroundJobNotification",
};
</script>
<style lang="scss">
.bk-job-noti {
  width: 540px !important;
  margin: 16px;
  .v-card {
    border-top: $primary 5px solid;
  }
}
</style>
