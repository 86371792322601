<template>
  <div>
    <v-overlay :absolute="false" :value="showingDialog" :id="attach">
    </v-overlay>
    <v-dialog
      v-model="showingDialog"
      persistent
      hide-overlay
      scrollable
      :attach="cssSelect?cssSelect:attach?`#${attach}`:undefined"
      width="1700"
    >
      <v-card>
        <v-card-subtitle class="px-4 py-2 d-flex align-center">
            <span style="font-size:16px" class="font-weight-bold">{{$t('templates.content')}}</span>
            <v-btn class="ml-auto" icon @click="saveRichText">
                <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon @click="showingDialog=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
           
        </v-card-subtitle>
        <v-card-text class="px-0 py-0">
          <!-- <quill-editor
            class="customerPage-richText"
            style="height:800px"
            v-model="editorContent"
          /> -->
          <general-quill
            className="customerPage-richText"
            style="height:800px"
            v-model="editorContent"
            @input="(val)=>editorContent = val.html"
            :isPanel="true"
          />
          
        </v-card-text>
        <!-- <DialogActions @onCommand="onCommand" /> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import DialogHeader from "./comps/DialogHeader";
// import DialogActions from "./comps/DialogActions";
import { quillEditor } from "vue-quill-editor";
import GeneralQuill from '@/components/public/GeneralQuill'

export default {
  props: {
    value: Boolean,
    attach: {
      type: String,
      default: undefined,
    },
    content: String,
    cssSelect:{
      type: String,
      default: undefined
    }
  },
  components: {
    // DialogHeader,
    // DialogActions,
    quillEditor,
    GeneralQuill
  },
  data() {
    return {
      editorContent: "",
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    saveRichText(){
        this.showingDialog = false
        this.$emit('update_elements', {type:'add', itemType: 'richText', val:this.editorContent})
    },
    onCommand(payload) {
      switch (payload.command) {
        case "cancel":
          this.showingDialog = false
          break;
        case "ok":
          this.showingDialog = false
          this.$emit("confirm", this.editorContent);
          break;
      }
    },
  },
  mounted() {
    this.editorContent = this.content;
  },
};
</script>

<style>
.customerPage-richText .ql-container.ql-snow {
    height: auto;
}
.customerPage-richText .ql-editor {
    height: 757px;
    overflow-y: scroll;
}
</style>