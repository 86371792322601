<template>
        <v-simple-table fixed-header height="100%" v-if="files.length > 0" style="width:100%">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('drawer.fileName')}}
                </th>
                <th class="text-left">
                  {{$t('drawer.createdAt')}}
                </th>
                <th class="text-right" style="width:120px">{{$t('drawer.action')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in files" :key="file._id" >
                <td v-if="canPreview(file.mediaType)" @click="preview(file)"><a>{{ file.originalName }}</a></td>
                <td v-else>{{ file.originalName }}</td> 
                <td>{{ createTime(file.createdAt)}}</td>
                <td class="text-right">
                    <v-btn depressed fab x-small class="mx-2" @click="downloadAttachmentFile(file)"><v-icon>mdi-download-outline</v-icon></v-btn>
                    <v-btn depressed fab x-small @click="removeAttachmentFile(file)"><v-icon>mdi-delete-outline</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>  
</template>
<script>
import MediaHelper from '@/helpers/MediaHelper'
export default {
    props: {
        files: Array
    },
    methods: {
        removeAttachmentFile(file) {
          this.$emit('onCommand', {
            command: 'removeAttachmentFile',
            file: file
          })
        },
        // removeAttachment_old(id){
        //     this.$emit('onCommand', {
        //         command: 'removeAttachment',
        //         id:id
        //     })
        // },
        downloadAttachment(id){
          MediaHelper.getMediaAccess(id).then(result => {
            const accessInfo = {
              accessId: result.accessId
            }
            MediaHelper.download(accessInfo)
          })
          
        },
        downloadAttachmentFile(media) {
          MediaHelper.downloadMedia({
            mediaId: media._id,
            objectKey: media.objectKey,
            mediaType: media.mediaType
          });
        },
        preview(media) {
          MediaHelper.previewMedia({
            mediaId: media._id,
            objectKey: media.objectKey,
            mediaType: media.mediaType
          });
        },
        preview_old(id){
          MediaHelper.getMediaAccess(id).then(result => {
            const accessInfo = {
              accessId: result.accessId
            }
            if (result.mediaType.startsWith('image/')){
              const url = MediaHelper.getMediaAccessUrl(accessInfo, 'show');
              this.$store.dispatch('SET_IMAGES_URLS', [url]);
              this.$store.dispatch('TOGGLE_FS_LIGHTBOX');
            } else
            MediaHelper.show(accessInfo)
          })
        },
        createTime(createdAt){
          return new Date(createdAt).toLocaleString()
        },
        canPreview(type){
          return type.startsWith('image/') || type === 'application/pdf'
        }
    },
}
</script>