<template>
  <v-menu
    open-on-hover
    top
    left
    offset-y
    transition="slide-y-transition"
    content-class="main-menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <AvatarShortcut :user="user" />
      </div>
    </template>
    <v-layout fill-height justify-space-between align-start class="main-popover">
      <div :style="avatarStyle">
        <AvatarShortcut :size="size" :user="user" no-label />
      </div>
      <v-layout fill-height column class="avatar-info" justify-start>
        <span class="name" >{{ user.name }}</span>
        <span class="company">{{ user.company }}</span>
        <span class="email">{{ user.email }}</span>
      </v-layout>
    </v-layout>
  </v-menu>
</template>

<script scoped>
import AvatarShortcut from './label';

export default {
  components: {
    AvatarShortcut,
  },
  props: {
    user: Object,
    size: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    avatarStyle() {
      return {
        width: this.size + 10,
      };
    }
  },
};

</script>

<style scoped>
  .main-menu {
    contain: initial;
    overflow: visible;
  }
  .main-menu::before {
    position: absolute;
    content: "";
    bottom: 0px;
    right: 50px;
    transform: translateY(100%);
    width: 8px; 
    height: 8px; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
  }
  .name {
    color: black;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 14px;
  }
  .avatar-info {
    margin-left: 15px;
    color: #999;
    font-size: 13px;
    line-height: 25px;
  }
  .main-popover {
    padding: 20px;
    min-width: 350px;
    background: white;
  }
</style>
