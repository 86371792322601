<template>
  <div class="workflow-notification-content-root">
    <v-layout justify-space-between align-start>
      <v-icon v-if="isFinished" small :color="iconColor">
        {{ !hasError ? 'mdi-check-circle' : 'mdi-close-circle' }}
      </v-icon>
      <div class="notification-content-title" v-text="popTitle" />
    </v-layout>
    <div class="notification-content-progress-bar" v-if="!isFinished">
      <div class="notification-content-bar">
        <v-progress-linear
          :value="progress"
          color="primary"
          height="25"
          striped
        >
          <template v-slot:default="{ value }">
            <div class="notification-content-progress">{{ value }}%</div>
          </template>
        </v-progress-linear>
      </div>
      <div class="notification-content-statistic">
        <span v-text="finished" /> / <span v-text="total" />
      </div>
      <!-- <div class="notification-content-time" v-if="taskStartAt">
        {{ $t('notification.background.triggerTime') }}: 
        <i>{{ $locale.m(taskStartAt).fromNow() }}</i>
      </div> -->
    </div>
    <div v-else>
      <div class="notification-content-result"
        :style="{ color: hasError ? 'red' : $vuetify.theme.themes.light.secondary }"
        v-html="remark"
      />
      <!-- <div class="notification-content-time" v-if="taskStartAt">
        {{ $t('notification.background.finishTime') }}: 
        <i>{{ $locale.m(taskEndAt).fromNow() }}</i>
      </div> -->
      <!-- <Lottie
        class="notification-content-result-animation"
        :options="!hasError ? defaultFailOptions : defaultDoneOptions"
        :height="50"
        :width="50"
      /> -->
    </div>
  </div>
</template>

<script>
// import Lottie from 'vue-lottie';
// import DoneFinishAnimation from '@/pages/admin/workflow/assets/process_done.json';
// import FailFinishAnimation from '@/pages/admin/workflow/assets/process_fail.json';
export default {
  data() {
    return {
      alert: true,
      // defaultDoneOptions: {
      //   animationData: DoneFinishAnimation,
      // },
      // defaultFailOptions: {
      //   animationData: FailFinishAnimation,
      // },
    }
  },
  // components: {
  //   Lottie,
  // },
  props: {
    messageId: Number,
    event: {
      type: Object,
      default: {
        progress: 0,
        finish: 0,
        error: 0,
        total: 0,
        startAt: null,
        endAt: null,
      },
    },
  },
  computed:{
    taskStartAt() {
      return this.event.startAt;
    },
    taskEndAt() {
      return this.event.endAt;
    },
    progress() {
      return this.event.progress;
    },
    iconColor() {
      return this.hasError ? 'red' : 'primary';
    },
    error() {
      return this.event.error;
    },
    total() {
      return this.event.total;
    },
    buttonName() {
      return this.event.name || this.event.buttonName || this.$t('workflow.events.actionProcess');
    },
    finished() {
      return this.event.finish;
    },
    isFinished() {
      return this.progress >= 100;
    },
    hasError() {
      return this.error > 0;
    },
    remark() {
      if (this.isFinished) {
        if (this.hasError) {
          return this.$t('notification.customAction.batchFinishWithError', {
            error: this.error,
          });
        }
        return this.$t('notification.customAction.batchFinish', {
          done: this.finished,
        });
      }
      return '';
    },
    popTitle() {
      if (this.isFinished) {
        return this.$t('notification.customAction.batchTitle', {
          name: this.buttonName,
        });
      }
      return this.$t('notification.customAction.processing', {
        // finish: this.finished,
        // total: this.total,
        name: this.buttonName,
      });
    },
  },
  // watch: {
  //   event(info, wasInfo) {
  //     if (info.progress !== wasInfo.progress && info)
  //   }
  // },
};

</script>

<style scoped lang="scss">
  .workflow-notification-content-root {
    padding: 15px 0;
    position: relative;
    overflow: hidden;
    .notification-content-result-animation {
      position: absolute;
      right: -30px;
      bottom: -30px;
    }
    .notification-content-title {
      font-size: 15px;
      font-weight: 900;
      flex: 1;
      line-height: 24px;
      margin: 0 3px;
      padding-right: 10px;
    }
    .notification-content-time {
      font-size: 10px;
      padding: 0 26px;
    }
    .notification-content-bar {
      border-radius: 20px;
      overflow: hidden;
    }
    .notification-content-statistic {
      margin-top: 2px;
      text-align: right;
      padding-right: 5px;
      > span {
        &:nth-child(1) {
          font-weight: 400;
        }
        &:nth-child(2) {
          font-weight: bolder;
        }
      }
    }
    .notification-content-close-icon {
      // margin-top: -6px;
      // margin-right: -12px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .notification-content-progress-bar {
      margin-top: 20px;
    }
    .notification-content-progress {
      font-weight: bolder;
      font-size: 12px;
    }
    .notification-content-result {
      margin: 5px 0;
      padding: 0 26px;
      font-size: 10px;
    }
  }
</style>