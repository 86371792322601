<template>
  <vxe-table-column :class-name="tdClass + ' vxeCellSignature'" v-bind="colDef" :edit-render="{name: 'EditSignatureModal', props:{ readonly, updateTdClass, formInfo }, enabled: !readonly}">
    <template v-slot="{row}">
      <img
        ref="signatureImg"
        v-if="row[fieldId]"
        style="height: 100%; width:80px"
        :src="row[fieldId].thumbnailUrl" 
        class="signature-image"
        @error="evt=>reloadImage(evt, row[fieldId].thumbnailUrl)"/>
    </template>


    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>

  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import EditSignatureModal from './comps/EditSignatureModal.vue'
import VXETable from 'vxe-table'
import Vue from 'vue'
import summaryCell from './comps/SummaryCell'

Vue.component(EditSignatureModal.name, EditSignatureModal)
VXETable.renderer.add('EditSignatureModal', {
renderEdit (h, renderOpts, params) {
    return [
      <edit-signature-modal params={ params } formInfo={renderOpts.props.formInfo} readonly={ renderOpts.props.readonly } v-on:updateTdClass={ renderOpts.props.updateTdClass }></edit-signature-modal>
    ]
  },
})

export default {
  mixins: [mixin],
  data () {
    return {
      count: 0,
      maxCount: 100,
      showSignature: true
    }
  },
  components: {
    summaryCell
  },
  methods:{
    // getMediaSrc(row){
    //   if(row[this.fieldId]){
    //     return row[this.fieldId].thumbnailUrl;
    //     // return this.$store.getters.appHost + "/medias/" + row[this.fieldId] + "/thumbnail"
    //   } else return null
    // },
    reloadImage(evt, imageUrl) {
      const vm = this;
      if (this.count < this.maxCount) {
        this.count++;
        setTimeout(() => {
          console.log('reloadImage imageUrl = ' + imageUrl);
            //         console.log('reloadImage src = ' +          
            // evt.target.src);
          vm.$refs.signatureImg.src = imageUrl;
          // evt.target.src = evt.target.src;
          // vm.$refs.signatureSrc.)
          // vm.$refs.signatureSrc = 
          //   vm.$refs.signatureSrc;
        }, 500);
      }
    }
  }
}
</script>
<style>
  .vxeCellSignature.col--actived{
    background-color:white
  }
</style>