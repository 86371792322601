<template>
  <div class="app-section">
      <div class="d-flex flex-column" v-if="Object.keys(apps).length > 0 || title === 'others' || forceDisplay">
        <slot name="header">
            <div class="d-flex justify-space-between align-center">
                <div class="py-2 text-header-2 w-100 text-truncate">
                    <!-- translate static title -->
                    <span v-if="['favourite', 'others'].includes(title)">{{ $t(`general.${ title }`) }}</span>
                    
                    <!-- current team -->
                    <span v-else-if="title === 'currentTeam'"><select-team-header class="mobile-team-selector" /></span>

                    <!-- dynamic title -->
                    <span v-else>{{ title }}</span>
                </div>
                <div v-if="!(['favourite', 'others'].includes(title))">
                    <v-icon>mdi-chevron-down-circle</v-icon>
                </div>
            </div>
            <v-divider />
        </slot>
        <slot name="apps-wrapper">
            <div class="py-5 appsContainer" v-if="appsWithOrdering.length > 0 && Object.keys(apps).length > 0 ">
                <li v-for="app in appsWithOrdering.filter( _app => Object.keys(apps).includes( _app))" :key="apps[app]._id" @click="openApp({title:apps[app].title,appId:apps[app]._id})" class="d-flex flex-column w-100 align-center cursor-pointer mb-4">
                    <div class="rounded-circle appAvatar" :style="`background: ${ apps[app].color }`">
                        <v-icon :dark="(apps[app].textTheme === 'dark')? true: false" large>{{ apps[app].logoIcon }}</v-icon>
                    </div>
                    <div class="my-2 text-truncate w-100 text-center app-title">{{ apps[app].title }}</div>
                    <div v-if="['others', 'favourite'].includes(title)" class="w-100 d-block text-truncate app-teamname-wrapper rounded-pill px-3"><small>{{ apps[app].teamName }}</small></div>
                </li>
            </div>
            <div v-else class="grey--text my-5 text-caption">
                No Apps Found
            </div>
        </slot>
    </div>
  </div>
  
</template>

<script>
import EventBus from "@/event-bus.js";
import selectTeamHeader from '@/pages/myapps/comps/SelectTeamHeader'
export default {
    name: 'myAppsSection',
    components: { selectTeamHeader }, 
    props: {
        apps: {
            type: Array, 
            default: () => []
        },
        title: {
            type: String,
            default: ""
        },
        preference: {
            type: Object, 
            default: () => ({})
        },
        forceDisplay: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        appsWithOrdering() {
            let _unionApps = [];
            try {
                if ( this.apps ) {
                    _unionApps = [...new Set([...this.preference.ordering, ...Object.keys(this.apps).map( app => app )])].filter( item => item);
                }
            } catch (err) {
                console.error( err )
            }
            return _unionApps;
        }
    }, 
    methods: {
        openApp: async function ( {title,appId} ) {
            console.log( `[SIMON] :: MyAppsSection.vue :: openApp() :: `, appId );
            if ( appId ) {
                EventBus.$emit('showSpinner');
                // update store.currentApp
                await this.$store.dispatch('FETCH_APP', { appId } );
                EventBus.$emit('hideSpinner');
                // change page
                this.$sensors.setProfile({
                    company_id:this.$store.getters.activeTeamCode,
                     company_name: this.$store.getters.activeTeamName
                });
                this.$sensors.track("plus_myapps_click", {
                    btn_name:title
                });
                this.$router.push({ name: 'apps', params: { id: appId }  });
            }
        }
    }
}
</script>

<style lang="scss">
.appsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 110px));
    justify-content: flex-start;
    grid-gap: .5rem;
    li {
        list-style: none;
        .appAvatar {
            width: 6rem;
            height: 6rem;
            display: flex; 
            place-content: center;
            background: #333333;
        }
    }
}

.mobile-team-selector {
    button {
        margin: 0;
        padding: 0 !important;
        .v-btn__content {
            justify-content: flex-start !important;
        }
        .text-h6 {
            font-size: 16px !important;
            font-weight: 500 !important;
            line-height: 25.6px !important;
        }
    }
    i {
        display: none !important;
    }
}
</style>