import {app} from '@/main'
import { formConfig as types } from "../../action-types";
import { formConfig as mutations } from "../../mutation-types";
import {
  getDataSourceTableIds,
  getTempId,
  getNextFieldId,
  createFieldObjects,
  addParentChildRelation,
} from "./helpers";

import { getFormConfig, getWidgetPropertyValues } from "@/helpers/FormHelpers";
import i18n from '@/lang/lang.js'

export default {
  [types.SET_FIELD_INFO_PROPERTY]({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      commit(mutations.SET_FIELD_INFO_PROPERTY, {...payload, rootGetters });
      resolve()
    })
  },
  [types.SET_FORM_INFO_PROPERTY]({ commit }, payload) {
    commit(mutations.SET_FORM_INFO_PROPERTY, payload);
  },
  [types.REFRESH_DATASOURCE_INFOS](
    { commit, dispatch, state },
    payload
  ) {
    return new Promise((resolve, reject) => {
      const reloadAll = payload.reloadAll ? payload.reloadAll : false;
      const existingDataSourceInfos = state.dataSources; // where state.dataSources is array of formInfo
      const existingDataSourceTableIds = existingDataSourceInfos.map(
        (formInfo) => formInfo._id
      );
      const requiredDataSourceTableIds = getDataSourceTableIds(
        state.formFieldInfos
      );
        if (payload.newDataSource) requiredDataSourceTableIds.push(payload.newDataSource)
      const obsolateIds = existingDataSourceTableIds.filter(
        (id) => requiredDataSourceTableIds.indexOf(id) === -1
      );
      const missingIds = requiredDataSourceTableIds.filter(
        (id) => existingDataSourceTableIds.indexOf(id) === -1
      );
      // remove obslateIds
      commit(mutations.REMOVE_DATA_SOURCES, { ids: obsolateIds });

      const reloadIds = reloadAll ? requiredDataSourceTableIds : missingIds;
        if (reloadIds.length > 0){
          const getParams = {
            urlCommand: "/forms",
            options: {
              params: {
                dataTableIds: reloadIds.join(","),
                appId:payload.appId
              },
            },
          };
    
          dispatch("AUTH_GET", getParams)
            .then((response) => {
              // console.log('REFRESH_DATASOURCE_INFOS :: AUTH_GET: response: ', response)
              commit(mutations.ADD_DATA_SOURCES, response.result);
              resolve(response.result);
            })
            .catch((err) => {
              console.log("err: ", err);
              reject(err);
            });
        }else {
          resolve([]);
        }

    });
  },
  [types.APPEND_WIDGET_TO_LAYOUT] ({commit, dispatch, state, rootGetters}, payload) {
    const widget = rootGetters.allWidgets.find(widget => widget.key === payload.widgetKey)
    const options = payload.options

    const { newFieldInfo, newLayoutItem } = createFieldObjects(state, {
      data: widget,
      index: 0,
      type: 'widget',
      widgetProperties: payload.widgetProperties ? payload.widgetProperties: rootGetters.widgetProperties
    })

    newFieldInfo['label'] = options.label
    newFieldInfo['properties'] = {
      ...newFieldInfo['properties'],
      ...options.properties
    }

    commit(mutations.INSERT_LAYOUT_ITEM, {
      type: 'widget',
      data: {
        newFieldInfo,
        newLayoutItem
      }
    })
  },

  [types.ADD_DEFAULT_FIELDS] ({ commit, dispatch, state, rootGetters }) {
    dispatch('APPEND_WIDGET_TO_LAYOUT', {
      widgetKey: 'text',
      widgetProperties: rootGetters.widgetProperties,
      options: {
        width: 12,
        label: app.$i18n.t('widgets.name'),
        properties: {
          width: 12,
          fieldName: app.$i18n.t('widgets.name'),
          rowCount: 1,
          rowType: 'single'
        }
      }
    })

    dispatch('APPEND_WIDGET_TO_LAYOUT', {
      widgetKey: 'text',
      widgetProperties: rootGetters.widgetProperties,
      options: {
        width: 12,
        label: app.$i18n.t('widgets.description'),
        properties: {
          width: 12,
          fieldName: app.$i18n.t('widgets.description'),
          rowCount: 5,
          rowType: 'multiple'
        }
      }
    })

    dispatch('APPEND_WIDGET_TO_LAYOUT', {
      widgetKey: 'attachments',
      widgetProperties: rootGetters.widgetProperties,
      options: {
        width: 12,
        label: app.$i18n.t('widgets.attachment'),
        properties: {
          width: 12,
          fieldName: app.$i18n.t('widgets.attachment'),
          minAttachmentCount: '1',
          maxAttachmentCount: '1',
          width: 6
        }
      }
    })
  },

  [types.ADD_FORM_OPTIONS] ({commit}, payload) {
    commit(mutations.ADD_FORM_OPTIONS, payload)
  },

  async [types.SET_FORM_BUFFER]({ commit, dispatch, state }, payload) {
    const formConfig = getFormConfig(payload.form);
    commit(mutations.SET_FORM_FIELD_INFOS, formConfig.fieldInfos);
    commit(mutations.SET_FORM_CHILD_TABLE_INFOS, formConfig.childTableInfos);
    commit(mutations.SET_FORM_UI_ELEMENT_INFOS, formConfig.uiElementInfos);
    commit(mutations.SET_FORM_LAYOUT, formConfig.layout);

    // console.log('SET_FORM_BUFFER :: 1st field rowType = ' + formConfig.fieldInfos[0].properties['rowType'])
    commit(mutations.SET_FORM_INFO_PROPERTY, [
      {propertyKey: "titleFieldInfoId", propertyValue: formConfig.titleFieldInfoId},
      {propertyKey: "linkedFields", propertyValue: formConfig.linkedFields},
      {propertyKey: "label", propertyValue: formConfig.label}
    ]);

    commit(mutations.SET_FORM_OPTIONS, {})
    await dispatch("REFRESH_DATASOURCE_INFOS", { reloadAll: false, appId: payload.form.appId});

    if (state.formLayout && state.formLayout.rows.length > 0) {
      commit(
        mutations.SET_SELECTED_FORM_FIELD_ID,
        state.formLayout.rows[0][0]._id
      );
    }

    // fix for inconsistency of paging tables
    commit(mutations.FIX_PAGING_TABLES)
  },
  [types.SWAP_PAGING_TABLES]({commit}, payload) {
    commit(mutations.SWAP_PAGING_TABLES, payload)
  },
  [types.SET_CHILD_TABLE_FIELD_ID]({ commit }, payload) {
    commit(mutations.SET_EDITING_CHILD_TABLE_FIELD_INFO_ID, payload);
  },
  [types.ADD_CHILD_TABLE_FIELD_INFO]({ commit }, payload) {
    commit(mutations.ADD_CHILD_TABLE_FIELD_INFO, payload);
  },
  [types.SET_CHILD_TABLE_INFO_ID]({ commit }, payload) {
    commit(mutations.SET_EDITING_CHILD_TABLE_INFO_ID, payload);
  },
  [types.RESET_CHILD_TABLE_FIELD]({ commit }) {
    commit(mutations.SET_EDITING_CHILD_TABLE_FIELD_INFO_ID, "");
    commit(mutations.SET_EDITING_CHILD_TABLE_INFO_ID, "");
  },
  [types.EDIT_CHILD_TABLE_FIELD]({ commit }, payload) {
    commit(
      mutations.SET_EDITING_CHILD_TABLE_FIELD_INFO_ID,
      payload.childTableFieldInfoId
    );
    commit(mutations.SET_EDITING_CHILD_TABLE_INFO_ID, payload.childTableInfoId);
  },
  [types.SET_SELECTED_FORM_FIELD_ID]({ commit, dispatch }, payload) {
    commit(mutations.SET_SELECTED_FORM_FIELD_ID, payload);
  },
  [types.ADD_CHILD_TABLE]({ commit }, payload) {
    const widget = payload.data;
    const fieldId = getNextFieldId(state.formFieldInfos);
    const newWidgetItemId = "_" + Date.now().toString();
    const widgetLabel = app.$i18n.t("widgets." + widget.labelTag);
    const propertyValues = getWidgetPropertyValues(
      widget,
      widgetLabel,
      payload.widgetProperties
    );

    const newChildTableInfo = {
      _id: newWidgetItemId,
      type: widget.key,
      label: widgetLabel,
      fieldId: fieldId,
      labelTag: widget.labelTag,
      widgetId: widget._id,
      properties: propertyValues,
    };

    commit(mutations.ADD_CHILD_TABLE, newChildTableInfo);
    if (typeof payload.callback === "function") {
      payload.callback(newItem, newChildTableInfo);
    }
  },
  [types.INSERT_LAYOUT_ITEM]({ dispatch, commit, state, rootGetters },payload) {
    //************************
    // parent-child relation
    //************************
    if (
      payload.type === "widget" &&
      payload.data.key === "parentChildRelation"
    ) {
      return addParentChildRelation(
        dispatch,
        commit,
        state,
        rootGetters,
        payload
      );
    } else {
      return new Promise((resolve, reject) => {
        if (payload.type == "widget") {
          // if (payload.data.key === 'currentTableRelation') {
          //
          // } else {
          //   addWidget(state, commit, payload)
          // }
          // console.log('INSERT_LAYOUT_ITEM :: payload: ', payload)
          const { newFieldInfo, newLayoutItem } = createFieldObjects(
            state,
            payload
          );
          let params = {
            type: payload.type,
            data: {
              newFieldInfo,
              newLayoutItem,
            },
            options: {
              index: payload.index,
            },
          };
          if (payload.hasOwnProperty("rowIndex")) {
            Object.assign(params.options, { rowIndex: payload.rowIndex });
          }
          // console.log("PARAM", params)
          commit(mutations.INSERT_LAYOUT_ITEM, params);
          resolve({
            newFieldInfo,
            newLayoutItem,
          });
        } else {
          // move between row or col
          commit(mutations.INSERT_LAYOUT_ITEM, payload);
        }
      });
    }
  },
  [types.REMOVE_LAYOUT_ITEM]({ commit }, payload) {
    commit(mutations.REMOVE_LAYOUT_ITEM, payload);
  },
  [types.REMOVE_LAYOUT_ROW]({ commit, payload }) {
    commit(mutations.REMOVE_LAYOUT_ROW, payload);
  },
  async [types.REMOVE_OBSOLATE_FIELD_INFOS]({commit}, payload) {
    commit(mutations.REMOVE_OBSOLATE_FIELD_INFOS, payload)
  },
  [types.DUPLICATE_LAYOUT_ITEM]({ commit }, payload) {
    let fieldInfo = JSON.parse(JSON.stringify(payload.fieldInfo ))
    let layoutItem = JSON.parse(JSON.stringify(payload.layoutItem ))

    // let fieldInfo = { ...payload.fieldInfo };
    // let layoutItem = { ...payload.layoutItem };

    fieldInfo._id = getTempId();
    fieldInfo.properties.fieldName = fieldInfo.properties.fieldName + ` - ${i18n.t('general.copy')}`
    fieldInfo.fieldId = fieldInfo._id;
    layoutItem._id = fieldInfo._id;

    if (fieldInfo.type === 'childTable') {
      fieldInfo.properties.fieldsEditor.formId = null;
    }

    const data = {
      type: "widget",
      data: {
        newFieldInfo: fieldInfo,
        newLayoutItem: layoutItem,
      },
      options: {
        index: payload.rowIndex,
      },
    };
    commit(mutations.INSERT_LAYOUT_ITEM, data);
  },
  [types.CLEAR_ALL]({ commit }) {
    commit(mutations.SET_FORM_LAYOUT_PROPERTY, {
      propertyKey: "rows",
      propertyValue: [],
    });
    commit(mutations.SET_FORM_LAYOUT_PROPERTY, {
      propertyKey: "childTables",
      propertyValue: [],
    });
    commit(mutations.CLEAR_FORM_FIELD_INFOS);
    commit(mutations.SET_FORM_CHILD_TABLE_INFOS, []);
    commit(mutations.SET_FORM_UI_ELEMENT_INFOS, []);
    commit(mutations.SET_FORM_INFO_PROPERTY, {
      propertyKey: "titleFieldInfoId",
      propertyValue: "",
    });
    commit(mutations.SET_FORM_INFO_PROPERTY, {
      propertyKey: "linkedFields",
      propertyValue: [],
    });
    commit(mutations.CLEAR_FORM_DATASOURCES);
  },
  [types.REMOVE_FIELD]({ commit }, payload) {
    commit(mutations.REMOVE_FIELD, payload);
  },
  [types.RESET_FORM_CONFIG]({ commit }) {
    commit(mutations.RESET_FORM_CONFIG);
  },

};
