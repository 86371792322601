<template>
<div class="pa-0 d-flex flex-row align-center w-100"
  :style="eventLineStyle">
  <!-- <div>
    <v-icon small class="ma-0"
      style="min-width:16px;min-height:16px;"
      :dark="textTheme==='dark'">mdi-circle-slice-8</v-icon>
  </div> -->
  <!-- <div v-if="!event.allDay" class="text-caption line-height-1 flex-grow-0 mr-1">
    {{ event.start.toTimeString().substr(0,5) }}
  </div> -->


  <v-chip x-small
    :dark="textTheme==='dark'"
    v-if="!event.allDay" class="text-caption line-height-1 flex-grow-0 time-tag">
    {{ event.start.toTimeString().substr(0,5) }}
  </v-chip>
  <div
    class="ml-1 event-title flex-grow-1 overflow-y-visible overflow-ellipsis position-relative"
    @click.stop.prevent="editEvent"
    :style="{color: (textTheme==='dark'?'white':'black')}">   
    {{ event.title === '' ? $t('messages.noContent') : event.title }}
    <!-- <div class="position-absolute yellow lighten-3"
      style="top:50%;width:400px;height:300px;">
      xx
    </div> -->
  </div>
  <event-menu-button
    v-if="canDelete"
    :event="event"
    :color="textColor"
    class="pa-0 menu-button"
    @onCommand="onCommandHandler"></event-menu-button>
</div>

</template>

<script>
import eventMenuButton from './EventMenuButton'

export default {
  name: 'eventLine',
  components: {
    eventMenuButton
  },  
  props: {
    event: Object,
    loading: Boolean,
    // {
    //    title: 'xxx',
    //    start: '2021-11-01',
    //    id: '..'
    //    backgroundColor:
    //    borderColor:
    //    textColor:
    //    extendedProps: {
    //        name: '..'
    //        timed: false,
    //        textTheme: 'dark',
    //        formId: '...',
    //        data: {  // it is the master data record
    //          ...
    //        }
    //    }
    // }
  },
  computed: {
    canDelete () {
      return this.event.extendedProps.data.canDelete
    },
    eventLineStyle () {
      const vm = this
      var result = {}
      result['color'] = vm.textColor
      result['backgroundColor'] = vm.event.backgroundColor
      return result
    },
    extendedProps () {
      return this.event.extendedProps
    },
    textColor () {
      return  this.textTheme==='dark' ? 'white' : 'black'
    },
    textTheme() {
      return this.extendedProps && this.extendedProps.textTheme ?
        this.extendedProps.textTheme : 
        'light'
    }
  },
  methods: {
    editEvent () {
      console.log('EventLine: editEvent')
      this.$emit('onCommand', {
        command: 'editEvent',
        event: this.event
      })
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'delete':
          payload.command = 'deleteEvent'
          this.$emit('onCommand', payload)
          break
      }
    }
  }

}
</script>

<style>
.time-tag {
  height: 14px !important;
  padding: 0 4px;
  min-width: 40px;

  background-color: rgba(200,200,200,.5) !important;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.overflow-y-visible {
  overflow-y: visible;
}
.event-title {
  min-height: 20px;
}
</style>