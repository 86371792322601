<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { countBy } from 'lodash';
import {
  UPDATE_WORKFLOW_EDITING_NODE,
  UPDATE_WORKFLOW_NODE,
  SET_SETTINGS,
  VALIDATE_WORKFLOW,
} from '@/store/modules/workflow/action_types';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';

export default {
  data() {
    return {
      info: {
        error: [],
        canSubmit: false,
      },
    };
  },
  computed:{
    appId() {
      return this.$route.params.id;
    },
  },
  methods: {
    hasError() {
      return this.info.error.length > 0 || countBy(this.info.error, 'failed').true > 0;
    },  
    setError(error, field) {
      this.info.error.splice(this.info.error.length, 0, field);
    },
    async saveSetting(node, workflow, setting) {
      // if (this.hasError()) {
      //   return this.info.error;
      // }
      if (node && node._id) {
        this.$store.dispatch(SET_SETTINGS, setting);
        // this.$set(node, 'properties', this.setting);
        WorkflowSettingUtils.removeOptionSelected(setting, 'rules');
        let info = {
          properties: setting,
        };
        const { response } = await this.$store.dispatch(UPDATE_WORKFLOW_NODE, {
          node,
          workflow: workflow._id,
          workflowInfo: workflow,
          info,
          appId: this.appId,
          // locale: this.$t('workflow.events')
        });
        if (response) {
          const { subWorkflow, properties } = response;
          if (subWorkflow) {
            subWorkflow.id = subWorkflow._id;
            // setting.subWorkflow = subWorkflow;
            info.properties.subWorkflow = subWorkflow;
          }
          if (properties.worksheet) {
            const { worksheet } = properties;
            worksheet.id = worksheet._id;
            // setting.worksheet = worksheet;
            info.properties.worksheet = worksheet;
          }
        } else {
          this.$store.dispatch(UPDATE_WORKFLOW_EDITING_NODE, info);
          info = null;
        }
        
        return info;
      }
      return null;
    }
  },
  provide() {
    return {
      hasError: this.hasError,
      errorInfo: this.info.error,
      info: this.info,
      saveSetting: this.saveSetting,
      setError: this.setError,
      canSubmit: this.info.canSubmit,
    };
  },
};
</script>

<style scoped>
</style>
