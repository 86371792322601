<template>
<div @click.stop="onClick" ref="summaryCell"
  class="cursor-pointer summary-cell d-flex align-center">
  <div v-for="(pair, index) in labelValuePairs"
    :key="index"
    class="mr-2 table-summary-field d-flex flex-row align-center justify-space-between">
    <div class="summary-label">{{ pair.label }}</div>
    <div class="summary-value">{{ pair.value.toFixed(decimals) }}</div>
  </div>
  <div class="arrow-down ml-auto"></div>
</div>
</template>

<script>
export default {
  name: 'summaryCell',
  props: {
    labelValuePairs: Array,
    decimals: Number,
    column: Object
  },
  methods: {
    onClick () {
      // console.log('this.$refs: ', this.$refs)
      // console.log('this.$refs.summaryCell: ', this.$refs.summaryCell)
      // console.log('this.$refs.summaryCell.$el: ', this.$refs.summaryCell.$el)
      const payload = {
        column: this.column,
        target: this.$refs.summaryCell
      }
      // console.log('SummaryCell.onClick: payload: ', payload)
      this.$emit('click', payload)
    }
  }
}
</script>

<style>
.summary-cell {
  height: 100%;
  width: 100%;
  min-height: 20px;
}
.summary-cell .summary-label {
  /* color: rgba(100,100,100,.5); */
}
</style>