<template>
  <!-- style="height:0;" -->
  <div class="table-view flex-grow-1 my-0 ma-0 d-flex flex-column"
    :class="{'footer-overflow-visible': footerOverflowVisible}">
    <div style="display: none">
      <ListMenu ref="customerButtons" size="1" :selected="selectedActionData" @onSelected="selectGlobalActionToCustomizedButton" />
    </div>
    <quick-filter-card v-if="quickFilters && quickFilters.length>0" 
      class="flex-gorw-0"
      :fieldInfos="fieldInfos"
      @updateFilters="updateFiltersHandler"                          
      @updateFilterAndOr="updateFilterAndOrHandler"
      :filters="quickFilters"
      :filterAndOr="quickFilterAndOr"
      @onCommand="onCommandHandler"
      >
    </quick-filter-card>
    <div 
      style="width:0;min-width:100%; max-height: 100%;"
      class="flex-grow-1"
      :class="{'quick-insert-active': isQuickInsertMode}">
    <ExportDialog
      v-if="showExportDialog"
      v-model="showExportDialog"
      :fields="exportFields"
      :order="effectiveFieldIds"
      :showAllFields="isAdmin"
      @confirm="exportData"
    />
    <ImportDialog v-if="showImportDialog" v-model="showImportDialog" />
    <BulkEditDialog 
      :fieldInfos="fieldInfos"
      @submit="bulkEdit"
      v-if="showBulkEditDialog"
      v-model="showBulkEditDialog"
    />
    <div style="width:0;min-width:100%; height:100%;  max-height: 100%;">
      <!-- <div v-if="true" class="green" style="height:0; overflow: auto; min-height: 100%;">
        <div v-for="i in 10" :key="i" class="mb-2 yellow" style="height:100px">test</div>
      </div> -->
      <vxe-grid
        ref="xTable"
        :edit-config="{
          trigger: isQuickInsertMode ? 'click' : 'dblclick',
          mode: isQuickInsertMode ? 'cell' : 'row',
          activeMethod: activeRowMethod,
          showStatus: false,
          showAsterisk: true
        }"
        :show-footer="true"
        :footer-method="footerMethod"
        :merge-footer-items="mergeFooterItems"
        :footer-cell-class-name="footerCellClassName"
        :menu-config="tableMenu"
        :cell-class-name="rowClasses"
        v-bind="gridOptions"
        :id="view._id"
        :loading="loading"
        :page-config="pageConfig"
        @edit-closed="editClosedEvent"
        @showMenu="onShowMenuHandler"
        @cell-menu="cellContextMenuEvent"
        @edit-actived="editActiveEvent"
        @resizable-change="resizableChanged"
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectChangeEvent"
        @menu-click="contextMenuClickEvent"
        :edit-rules="validRules"
        keep-source
        auto-resize
        min-width="100%"
        style="height:0; overflow: auto; min-height: 100%;"
        show-header-overflow
        class="tableViewGrid"
      >
      <!-- height="auto" -->
        <vxe-table-column
          type="checkbox"
          width="30"
          fixed="left"
          class-name="text-center position-relative vxeCellEdit"
          header-class-name="text-center vxeCellEdit"
          :resizable="false"
        >
        </vxe-table-column>

        <vxe-table-column
          type="seq"
          min-width="40"
          width="40"
          fixed="left"
          class-name="text-center position-relative vxeCellEdit"
          header-class-name="text-center vxeCellEdit"
        >
        </vxe-table-column>
        <vxe-table-column
          v-if="!isQuickInsertMode"
          min-width="30"
          width="30"
          class-name="text-center vxeCellEdit"
          header-class-name="text-center"
          fixed="left"
          :resizable="false"
        >
          <template v-slot="{ row }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    size="18" 
                    v-if="row.canEdit" 
                    @click.native="editRow(row)"
                    style="cursor: pointer"
                    v-on="on"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                  <v-icon  
                    @click.native="editRow(row)"
                    v-else
                    size="18" 
                    style="cursor: pointer"
                    v-on="on"
                  >
                    mdi-eye-outline
                  </v-icon>
              </template>
              <span>{{ $t("buttons.edit") }}</span>
            </v-tooltip>
          </template>
        </vxe-table-column>

  

        <template v-for="(colDef, index) in colDefs">
          <component
            v-if="colDef.componentName !== ''"
            :is="'vxe' + colDef.componentName"
            :colDef="colDef"
            :relationData="relationData"
            :key="index"
            :rowHeightClass="rowHeightClass"
            :quickInsert="isQuickInsertMode"
            @onCommand="onCommandHandler"
          ></component>
          <vxe-table-column
            v-else
            v-bind="colDef"
            :key="index"
          ></vxe-table-column>
        </template>
        <template #pager_left>
          <div class="pager-left d-flex">
            <div v-if="numberOfSelections > 0 && editableFieldIds.length>0">
              <v-btn
                color="#4E5969"
                class="elevation-0 ml-2 select-button" 
                dark
                small
                @click="resetSelection">
                {{ $t('buttons.reset')}}
              </v-btn>
              <v-btn 
                color="#AC3E79"
                class="elevation-0 ml-2 select-button" 
                dark
                small
                @click="selectAllItems({isAllPages:true})"
              >
                {{ $t('buttons.selectAllPages') }} 
              </v-btn>
              <v-btn 
                color="#F19E38"
                class="elevation-0 ml-2 select-button" 
                dark
                small
                @click="selectAllItems({isAllPages:false})"
              >
                {{ $t('buttons.selectThisPage') }}
              </v-btn>
              <span class="ml-2"> 
                {{isCurrentPage?
                  $t("messages.selectedCurrentPageRecords", { num: numberOfSelections })
                  :$t("messages.selectedAllPageRecords", { num: numberOfSelections })
                }}
              </span>
            </div>
          </div>
        </template>
        
      </vxe-grid>
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>

    <popup-record-dialog 
      ref="popupRecordDialog"
      @onCommand="onCommandHandler"
    ></popup-record-dialog>

    <select-related-records-dialog
      ref="selectRelatedRecordsDialog"
      @click:new="newRelatedRecord"
    ></select-related-records-dialog>

    <!-- Dialog: Card Selection -->
    <select-related-cards-dialog
      ref="selectRelatedCardsDialog"
      @click:new="newRelatedRecord"
    ></select-related-cards-dialog>

    <dialog-button-select-employees
      class="pa-0"
      :addButton="false"
      ref="tableEesDialog"
      v-model="showEesDialog"
    ></dialog-button-select-employees>
      <!-- v-model="showingDialog"
      :multiple="isMultiple"
      :addButton="false"
      :selectedValue="row[fieldId]"
      @submit="onSubmit($event, row)" -->

    <popupRecordDialog
      @ready="loading=false"
      ref="popupRecordDialog">
    </popupRecordDialog>

    <select-calc-method-menu
      ref="selectCalcMethodMenu"></select-calc-method-menu>    
    
    <sharable-record 
      @input="v=>showSharableRecord=v" 
      v-model="showSharableRecord" 
      v-if="showSharableRecord"
      :recordId="sharableRecordId"
    />
  </div>

  <v-snackbar
    v-model="showingSnackbar"
    top
    centered
    elevation="0"
    timeout="2000"
    content-class="d-flex justify-center"
    :color="snackbarColor"
  >
    <div class="d-flex justify-center" style="font-size:14px"> {{snackbarContent}} </div>
  </v-snackbar>
    <members-menu
      :fullWidth="true"
      v-if="showingMembersMenu"
      v-model="showingMembersMenu"
      :attachId="membersMenuPayload.attachedId"
      ref="membersMenu"
      @memberClick="handleMemberClick"
      :nudgeBottom="rowHeight"
  />
</div>
</template>

<script>
import { map, keyBy, chain, pick, cloneDeep } from 'lodash';
import { isValid } from "bson-objectid";
import baseMixin from "@/mixins/baseMixin";
import appsFormsMixin from "@/mixins/appsFormsMixin";
import propertyHelperMixin from "@/mixins/propertyHelperMixin";
import vxeTableHelperMixin from "@/mixins/vxeTableHelperMixin";
import validationsMixin from "./validationsMixin";

import fieldHelperMixin from "@/mixins/fieldHelperMixin";
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import selectCalcMethodMenu from '@/components/dialogs/SelectCalcMethodMenu';
import quickFilterCard from './comps/QuickFilterCard'

// for quick insert
import recordMixin from "@/mixins/recordMixin";
import quickInsertUndoMixin from "@/mixins/quickInsertUndoMixin";

import ListMenu from '@/pages/admin/workflow/manualActions/tools/menu';

import vxeTableCells from "@/components/vxeTableCells";
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import confirmDialog from "@/components/dialogs/ConfirmDialog";
import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
import selectRelatedCardsDialog from "@/components/dialogs/SelectRelatedCardsDialog";
import PrintTemplateButton from "@/components/buttons/PrintTemplateButton";
import otherActionMenu from "./comps/OtherActionMenu";
import membersMenu from '@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu'

import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";

import EventBus from "@/event-bus.js";
import { newBlankRecord, getRelatedRecordFieldInfo } from "@/helpers/FormHelpers";
import dialogButtonSelectEmployees from '@/components/dialogButtons/DialogButtonSelectEmployees'
import XEUtils from 'xe-utils'
import sharableRecord from '@/components/sharableRecord'


const TRANSFERRABLE_MAP = {
  text: ["text", "richText"],
  number: ["text", "number", "amount"],
  amount: ["text", "number", "amount"],
  email: ["text", "email"],
  date: ["text", "date"],
  phone: ["text", "phone"],
  singleSelection: ["text"],
  multipleSelection: ["text"],
  attachments: ["text", "attachments"],
  region: ["text", "region"],
  location: ["text", "location"],
  expression: ["text"],
  checkbox: ["text", "checkbox"],
  rating: ["text", "rating"],
  textCombination: [],
  autoNumbering: [],
  richText: ["text", "richText"],
  idCard: ["text", "idCard"],
  members: ["text", "members"],
  departments: ["text", "departments"],
  signature: [],
};

const MENUS = [
  {
    code: "copyRow",
    nameTag: "contextMenu.duplicateRow",
    prefixIcon: "mdi mdi-content-copy tableMenuItemIcon",
    visible: true,
    disabled: false
    ,
    className: "tableMenuItem",
  },
  {
    code: "deleteRow",
    nameTag: "contextMenu.deleteRow",
    prefixIcon: "mdi mdi-table-row-remove tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },

  {
    code: "moveToTop",
    nameTag: "contextMenu.moveToTop",
    prefixIcon: "mdi mdi-arrow-collapse-up tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "moveUp",
    nameTag: "contextMenu.moveUp",
    prefixIcon: "mdi mdi-arrow-up tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "moveDown",
    nameTag: "contextMenu.moveDown",
    prefixIcon: "mdi mdi-arrow-down tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "moveToBottom",
    nameTag: "contextMenu.moveToBottom",
    prefixIcon: "mdi mdi-arrow-collapse-down tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },

  {
    code: "insertOneRowAbove",
    nameTag: "contextMenu.insertOneRowAbove",
    prefixIcon: "mdi mdi-numeric-1-box tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "insertFiveRowsAbove",
    nameTag: "contextMenu.insertFiveRowsAbove",
    prefixIcon: "mdi mdi-numeric-5-box tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "insertTenRowsAbove",
    nameTag: "contextMenu.insertTenRowsAbove",
    prefixIcon: "mdi mdi-numeric-10-box tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },

  {
    code: "addOneRowBelow",
    nameTag: "contextMenu.addOneRowBelow",
    prefixIcon: "mdi mdi-numeric-1-box tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "addFiveRowsBelow",
    nameTag: "contextMenu.addFiveRowsBelow",
    prefixIcon: "mdi mdi-numeric-5-box tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "addTenRowsBelow",
    nameTag: "contextMenu.addTenRowsBelow",
    prefixIcon: "mdi mdi-numeric-10-box tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },

  {
    code: "undo",
    nameTag: "contextMenu.undo",
    prefixIcon: "mdi mdi-undo tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "redo",
    nameTag: "contextMenu.redo",
    prefixIcon: "mdi mdi-redo tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },

  {
    code: "resetAllRows",
    nameTag: "contextMenu.resetAllRows",
    prefixIcon: "mdi mdi-alert-remove tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
  {
    code: "resetCurrentRow",
    nameTag: "contextMenu.resetCurrentRow",
    prefixIcon: "mdi mdi-alert-remove tableMenuItemIcon",
    visible: true,
    disabled: false,
    className: "tableMenuItem",
  },
];

const ADD_BELOW = true;
const INSERT_ABOVE = false;
import ExportDialog from "@/components/dialogs/ExportDialog";
import ImportDialog from "../dialogs/ImportDialog";
import BulkEditDialog from "../dialogs/BulkEditDialog";
import {
  FETCH_ACTION_BUTTON_OPTIONS,
  SET_GLOBAL_ACTION,
  INTERACT_ACTION_BUTTOIN,
} from '@/store/modules/customizedAction/action_types';
export default {
  name: "tableView",
  mixins: [
    appsFormsMixin,
    baseMixin,
    propertyHelperMixin,
    vxeTableHelperMixin,
    validationsMixin,
	  recordMixin,
    fieldHelperMixin,
    quickInsertUndoMixin
  ],
  components: {
    ...vxeTableCells,
    confirmDeleteDialog,
    confirmDialog,
    selectRelatedRecordsDialog,
    selectRelatedCardsDialog,
    PrintTemplateButton,
    otherActionMenu,
    popupRecordDialog,
    selectCalcMethodMenu,
    ExportDialog,
    ImportDialog,
    BulkEditDialog,
    dialogButtonSelectEmployees,
    quickFilterCard,
    membersMenu,
    ListMenu,
    sharableRecord
  },
  data () {
    return {
      mergeFooterItems: [
        { row: 0, col: 0, rowspan: 1, colspan: 3 }
      ],
      showingMenu: false,
      attach: '',
      showingTestingDialog: false,
      tableType: "master", // as identifier for vxeTableHelperMixin to handle table height differently
      renderComponent: true,
      tableHeight: 280,
      loading: true,
      // colDefs: [],

      tableData: [],
      displayValues: null,

      showOverlay: false,
      showExportDialog: false,
      showImportDialog: false,
      active: true,
      filterValue: "",
      selectedAction: [],
      keyword: "",
      relationData: {
        idDataMapping: {},
      },
      savedPageSize: 20,
      loadingPageSize: false,


      // for quick insert
      recordData: {
        _id: "",
      },
      quickInsertModeRowHeight: "s",
      blankRecordDataStr: "",
      clipboardValue: null,
      clipboardFieldInfo: null,


      savedCellInfo: null,
      disableContextMenu: false,

      showEesDialog: false,
      pageSizeAvailable: false,
      summaryData: [],
      
      quickFilters: [],
      quickFilterAndOr: 'and',
      showBulkEditDialog: false,
      snackbarContent: '',
      showingSnackbar: false,
      snackbarColor: "",
      showingMembersMenu: false,
      membersMenuPayload: null,
      footerOverflowVisible: false,
      shareLinks: [],
      showSharableRecord: false,
      sharableRecordId: null,

      // Selection Handling
      //
      // in case select in all pages, includeRecords is empty
      currentPageDataIds: [],
      isAllRecords: false, // 
      isCurrentPage: true, // current page or all pages
      excludeRecords: [], // used only when isAllRecords = false

      // Table row event
      // selectAllEvent
      // seldctChangeEvent
    };
  },
  props: {
    view: Object,
    printTemplates: Array,
    canImport: Boolean,
    canExport: Boolean,
    canCopy: Boolean,
    canInlineEdit: Boolean,
    canPrint: Boolean,
    isQuickInsertMode: Boolean,
  },
  beforeDestroy() {
    EventBus.$off("selectRelatedCards");
    EventBus.$off("selectRelatedRecords");

    EventBus.$off('copyToClipboard')
    EventBus.$off('pasteFromClipboard')
  },

  async mounted() {
    const vm = this;
    EventBus.$on("selectRelatedCards", vm.selectRelatedCards);
    EventBus.$on("selectRelatedRecords", vm.selectRelatedRecords);

    EventBus.$on("copyToClipboard", vm.copyToClipboard);
    EventBus.$on("pasteFromClipboard", vm.pasteFromClipboard);

    vm.fetchCustomizedButtons();
    vm.getPageSize();

    if (vm.$refs.xTable && vm.lastPagination) {
      vm.$refs.xTable.tablePage.currentPage = vm.lastPagination.currentPage;
    }
    vm.$emit("onCommand", {
      command: "viewReady",
      viewName: "table",
    });
    vm.$refs.xTable.resetColumn();
    vm.onWindowResized();
    vm.initQuickFilters();
  },

  watch: {
    isAllRecords: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calcSummaryData()
        this.$emit('update_isAllChecked', newVal);
        this.$emit('update_totalRecordNumber', this.$refs.xTable.tablePage.total);
      }
    },
    'view.summaryConfigs': function () {
      this.calcSummaryData(this.$refs.xTable.tableData)
    },
    isQuickInsertMode: function(newVal) {
      const vm = this;
      console.log('isQuickInsertMode = newVal = ' + (newVal ? 'yes' : 'no'))
      if (newVal) {
        vm.initQuickInsertMode();
      }
    },
    "pagination.pageSize": function() {
      const vm = this;
      // console.log('watch(pagination.pageSize')
      if (!vm.loadingPageSize) {

        if (vm.$route.params.viewId) {
          // console.log('TableView.watch(pagination.pageSize) viewId valid => check diff')
          // if (newVal !== vm.savedPageSize) {
          if (vm.$refs.xTable.tablePage.pageSize !== vm.savedPageSize) {
            // console.log('watch(pagination.pageSize): this.$route.params.viewId = ' + this.$route.params.viewId)
            this.savePageSize();
            vm.savedPageSize = vm.$refs.xTable.tablePage.pageSize;
          }
        }
      }
    },
    // "$route.params.itemId": function() {
    //   this.fetchCustomizedButtons();
    // },
    "$route.params.viewId": function() {
      // console.log('TableView.watch($route.params.viewId): view: ', vm.view)
      // alert('watch(viewId)')
      const vm = this;
      // console.log('TableView.watch($route.params.viewId): this.$route.params.viewId = ' + this.$route.params.viewId)
      if (vm.$route.params.viewId) {
        vm.getPageSize();
        this.fetchCustomizedButtons();
      }
    },
    view: function(newVal, oldVal) {
      const vm = this;
      // alert('watch(view)')
      if (newVal !== oldVal) {
        // console.log('watch(view) => refresh')
        //alert('watch(view)')
        vm.$refs.xTable.tablePage.currentPage = 1;
        this.resetTableState();
        // console.log('watch(view) => diff view => resetTableState => refresh')

        //  vm.refresh();
      }
      vm.initQuickFilters()
    },
  },
  computed: {
    rowHeight(){
      const height = this.isQuickInsertMode
        ? this.quickInsertModeRowHeight
        : this.view.tableRowHeight || "xs";
      switch(height){
        case "xs":
          return 40
        case "s":
          return 64
        case "l":
          return 88
        case "xl":
          return 112
      }
      return 40
	},
    buttonOptions() {
      return this.$store.getters.getActionButtonOptions;
    },
    hasCustomizedButtons() {
      return this.$store.getters.anyButtons;
    },
    criteriaConfigs () {
      return this.$store.getters.criteriaConfigs
    },

    filteredColDefs(){
      return this.colDefs.filter(colDef=>{
        return !['createdBy', 'createdAt', 'updatedAt', 'owner'].includes(colDef.fieldInfo.type)
      })
    },
    summaryConfigs () {
      return this.view.summaryConfigs ? 
        this.view.summaryConfigs : 
        []
    },
    haveParentChildFields () {
      const vm = this
      var result = false
      for (let i = 0; i < vm.effectiveFieldIds.length; i++) {
        if (vm.allLinkedFieldIds.includes(vm.effectiveFieldIds[i])) {
          result = true
          break
        }
      }
      return result
    },

    allLinkedFieldIds () {
      const vm = this
      var result = []
      if (vm.form && vm.form.linkedFields) {
        for (let i = 0; i < vm.form.linkedFields.length; i++) {
          const loopLinkedFields = vm.form.linkedFields[i]
          result = result.concat(loopLinkedFields.fieldIds)
        }
      }
      return result
    },
    clipboardValueString() {
      return this.getFieldString(this.clipboardFieldInfo, this.clipboardValue);
    },
    quickInsertUnusedRecordIds() {
      const vm = this;
      let result = [];
      for (let i = 0; i < vm.$refs.xTable.tableData.length; i++) {
        const loopRow = vm.$refs.xTable.tableData[i];
        const rowId = loopRow._id;
        const rowWithoutId = { ...loopRow, _id: "" };
        const rowStr = JSON.stringify(rowWithoutId);
        if (rowStr === vm.blankRecordDataStr) {
          result.push(rowId);
        }
      }
      return result;
    },
    quickInsertDataRowCount() {
      const vm = this;

      return (
        vm.$refs.xTable.tableData.length - vm.quickInsertUnusedRecordIds.length
      );
    },
    quickInsertTotalRowCount() {
      return this.$refs.xTable.tableData.length;
    },
    appId() {
      return this.currentApp._id;
    },
    formId() {
      return this.form._id;
    },
    relatedTableInfos() {
      return this.$store.getters.relatedTableInfos;
    },
    menuSection1() {
      const vm = this;
      let result = vm.createMenu(["copyRow", "deleteRow"]);
      result.push(this.openInNewTabMenuOption)
      if (!vm.isQuickInsertMode) {
        result.push(vm.printMenuOption);
        result.push(vm.shareableOption);
        if (this.hasCustomizedButtons) {
          const customizedMenu = vm.customizedButtonMenuSelection;
          if (customizedMenu) {
            result.push(customizedMenu);
          }
        }
        
      }
      return result;
    },
    customizedButtonMenuSelection() {
      if (this.hasCustomizedButtons) {
        const result = {
          code: "moreAction",
          name: this.$t('contextMenu.moreAction'),
          visible: true,
          disabled: false,
          className: "tableMenuItem",
          type: 0,
          children: [],
        };
        const templates = map(this.buttonOptions, b => ({
          name: b.name,
          visible: true,
          disabled: false,
          prefixIcon: b.icon ? `mdi mdi-${b.icon} tableMenuItemIcon` : null,
          code: 'customizedAction',
          templateId: b._id,
          type: 0
        }));
        if (templates.length >= 1) {
          result["children"] = templates;
          result["code"] = "";
        }
        return result;
      }

      return null;
    },
    menuSectionQuickInsert() {
      return [
        // this.createMenu(['undo','redo']),
        this.createMenu(["moveToTop", "moveUp", "moveDown", "moveToBottom"]),
        this.createMenu([
          "insertOneRowAbove",
          "insertFiveRowsAbove",
          "insertTenRowsAbove",
          "addOneRowBelow",
          "addFiveRowsBelow",
          "addTenRowsBelow",
        ]),
        this.createMenu(["resetCurrentRow", "resetAllRows"]),
      ];
    },

    tableMenuOptions() {
      const vm = this;
      let menuSections = [];
      if (vm.isQuickInsertMode) {
        menuSections = [...vm.menuSectionQuickInsert, vm.menuSection1];
      } else {
        menuSections = [vm.menuSection1];
      }

      return menuSections;
    },


    tableMenu() {
      const vm = this;
      return {
        body: {
          options: vm.tableMenuOptions,
        },
        className: "tableMenu",
        visibleMethod: this.visibleMethod,
      };
    },
    openInNewTabMenuOption(){
      return {
        code: "openInNewTab",
        name: this.$t("contextMenu.openInNewTab"),
        prefixIcon: "mdi mdi-arrow-top-right tableMenuItemIcon",
        visible: true,
        disabled: false,
        className: "tableMenuItem"
      }
    },
    shareableOption(){
      return {
        code: "shareable",
        name: this.$t("publicEdit.shareableSettings"),
        prefixIcon: "mdi mdi-cog tableMenuItemIcon",
        visible: true,
        disabled: false,
        className: "tableMenuItem",
        type:  0
      }
    },
    printMenuOption() {
      let result = {
        code: "print",
        name: this.$t('contextMenu.systemPrint'),
        prefixIcon: "mdi mdi-printer tableMenuItemIcon",
        visible: true,
        disabled: false,
        className: "tableMenuItem",
        type:  0
      };
      var templates = [];
      if (this.printTemplates.length === 0) templates = [];
      else if ( this.printTemplates.length === 2 ) {
        const _template = this.printTemplates.shift();
        result = {
          code: 'print',
          name: _template.label, 
          visible: true,
          disabled: false,
          templateId: _template.templateId,
          prefixIcon: 'mdi mdi-printer tableMenuItemIcon', 
          type: _template.type || 0
        }
      }
      else
        templates = this.printTemplates.filter( _template => _template.templateId != '' ).map((template) => ({
          name: template.label,
          visible: true,
          disabled: false,
          code: "print",
          templateId: template.templateId,
          type: template.type || 0
        }));
      if (templates.length > 1) {
        result["children"] = templates;
        result["code"] = "";
      }
      return result;
    },
    lastPagination() {
      const vm = this;
      const paginations = JSON.parse(localStorage.getItem("LAST_PAGINATIONS"));
      return paginations && paginations[vm.form._id]
        ? paginations[vm.form._id]
        : null;
    },

    isIndeterminate() {
      return (
        this.numberOfSelections > 0 &&
        this.numberOfSelections < this.$refs.xTable.tablePage.total
      );
    },
    isAllChecked() {
      return (
        this.numberOfSelections > 0 &&
        this.numberOfSelections === this.$refs.xTable.tablePage.total
      );
    },
    
    selectedCount() {
      return this.$refs.xTable.tableData.length - this.excludeRecords.length;
    },

    includeRecords () {
      return this.isCurrentPage 
        ? this.currentPageDataIds.filter(id=>!this.excludeRecords.includes(id))
        : [];
    },

    numberOfSelections() {
      let selectionCount = 0;
      if (this.isCurrentPage) {
        selectionCount = this.includeRecords.length;
        this.$emit("update_numberOfSelections", selectionCount);
        this.$emit("update_ofSelections", this.includeRecords);
      } else {
        selectionCount = this.$refs.xTable.tablePage.total - this.excludeRecords.length;
        this.$emit("update_numberOfSelections", selectionCount);
        // notes: for excluding records when isAllChecked
        const checkedItemIds = this.$refs.xTable.getCheckboxRecords().map( item => item._id);
        const fulLDataIds = this.$refs.xTable.getTableData().fullData.map( item => item._id);
        this.$emit("update_ofSelections", fulLDataIds.filter( item => !(checkedItemIds.includes(item)) ));
      }
      return selectionCount;
    },
    exportFields() {
      let result = []
      const visibleFields = this.effectiveFixedFieldIds.concat(
        this.effectiveNonFixedFieldIds
      );
       this.fieldInfos.forEach(info => {
        if (this.$store.getters.exportableWidgets.includes(info.type) && 
          !(info.properties.authorization|| "").split("||").includes("hidden")
         ){
            result.push({
            _id: info.fieldId,
            label: info.label,
            type: info.type === 'childTable' || (info.type === 'relatedRecord' &&
              info.properties.relatedRecordQuantity === 'multiple' && info.properties.displayFormat === 'table'
            ) ? 'associate': 'data',
            visible:visibleFields.includes(info.fieldId)
          });
        }
      })

      return result;
    },
    tableSummationFieldIds() {
      const vm = this;
      var result = [];
      if (vm.view) {
        result = vm.view.tableSummationFieldIds;
      }
      return result;
    },

    selectedActionData() {
      return this.$store.getters.actionSelectedData;
    },
    selectedActionId() {
      return this.$store.getters.actionType;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    form() {
      return this.$store.getters.currentForm;
    },
    isAdmin(){
      return this.$store.getters.isAdmin
    },
    titleFieldId() {
      const vm = this;
      var result = "";
      if (vm.fieldInfos && vm.form.titleFieldInfoId) {
        const titleFieldInfo = vm.fieldInfos.find(
          (info) => info._id === vm.form.titleFieldInfoId
        );
        if (titleFieldInfo) {
          result = titleFieldInfo.fieldId;
        }
      }
      return result;
    },
    fieldInfos() {
      return this.form.fieldInfos;
    },
    fieldInfoFieldIds() {
      return this.fieldInfos.map((info) => info.fieldId);
    },
    fixedFieldInfos() {
      return this.getFieldInfos(this.effectiveFixedFieldIds);
    },
    nonFixedFieldInfos() {
      return this.getFieldInfos(this.effectiveNonFixedFieldIds);
    },
    effectiveFieldInfos () {
      return this.getFieldInfos(this.effectiveFieldIds)
    },
    effectiveFieldIds() {
      return [
        ...this.effectiveFixedFieldIds,
        ...this.effectiveNonFixedFieldIds,
      ];
    },
    displayFieldCount() {
      if (this.view.fixedFieldIds && this.view.nonFixedFieldIds) {
        return (
          this.view.fixedFieldIds.length + this.view.nonFixedFieldIds.length
        );
      } else return 0;
    },
    effectiveFixedFieldIds() {
      const vm = this;
      var result = [];
      if (vm.displayFieldCount === 0) {
        if (vm.titleFieldId !== "") {
          result.push(vm.titleFieldId);
        }
      } else {
        result = vm.view.fixedFieldIds;
      }
      // console.log("effectiveFixedFieldIds: result: ", result);
      return result;
    },
    effectiveNonFixedFieldIds() {
      const vm = this;
      var result = [];
      if (vm.displayFieldCount === 0) {
        if (vm.titleFieldId !== "") {
          result = vm.fieldInfoFieldIds.filter((id) => id !== vm.titleFieldId);
        } else {
          result = vm.fieldInfoFieldIds;
        }
      } else {
        result = vm.view.nonFixedFieldIds;
      }
      // console.log("effectiveNonFixedFieldIds: result: ", result);
      return result;
    },
    rowHeightClass() {
      return this.rowClasses();
    },
    tableActions() {
      const vm = this;
      return [
        {
          label: "Copy",
          action: "copy",
          enabled: true,
          icon: "mdi-content-copy",
          color: "warning",
          tooltip: vm.$t("buttons.copy"),
          submenu: [],
        },
        {
          label: "Other",
          action: "other",
          enabled: true,
          icon: "mdi-shape-plus",
          color: "primary",
          tooltip: "Other Buttons",
          submenu: [
            { label: "item 1", value: "111" },
            { label: "item 2", value: "112" },
            { label: "item 3", value: "113" },
            { label: "item 4", value: "114" },
          ],
        },
      ];
    },
    editableFieldIds() {
      var exclusion = ['createdAt','createdBy','updatedAt']
      return this.form.fieldInfos
        .filter((info) => info.canEdit&&!exclusion.includes(info.type))
        .map((info) => info._id);
    }
  },
  methods: {
    /**
     * Match view setting with personalized setting. filterValue1, filterValue2, relation in local storage precedes view setting value.
     */
    async initQuickFilters() {
      const vm = this;
      const filtersInLS = await this.$store.dispatch(
        "GET_QUICK_FILTER_BY_VIEWID",
        this.view._id
      );
      let result = cloneDeep(this.view.quickFilters);
      if (filtersInLS) {
        const quickFilterMap = chain(result).keyBy("_id");
        result = quickFilterMap
          .mergeWith(
            pick(keyBy(filtersInLS, "_id"), quickFilterMap.keys().value()),
            (obj, src, key) => {
              if (["filterValue1", "filterValue2", "relation"].includes(key)) return src;
              else if (!isValid(key)) return obj;
            }
          )
          .values()
          .value();
      }

      vm.quickFilters = result;
      vm.quickFilterAndOr = vm.view.quickAndOr;
    },

    updateFiltersHandler (payload) {
      const vm = this
      const filterIndex = payload.filterIndex
      const filter = payload.filter
      vm.quickFilters[filterIndex].relation = filter.relation
      vm.quickFilters[filterIndex].filterValue1 = filter.filterValue1
      vm.quickFilters[filterIndex].filterValue2 = filter.filterValue2
    },

    updateFilterAndOrHandler (filterAndOr) {
      this.quickFilterAndOr = filterAndOr
    },

    footerMethod ({columns}) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return ''
          }else if(_columnIndex === 1 || _columnIndex === 2){
            return null
          }else
            return ''
        })
      ]
      return footerData
    },

    cellContextMenuEvent({ row }) {
      const recordShareLinks = this.shareLinks.filter(shareLink=>{
        return shareLink.recordId == row._id
      })
      const shareLinkOptIndex = this.tableMenu.body.options[0].findIndex(option=>{
        return option.code == "shareLink"
      })
      if(shareLinkOptIndex !== -1){
        this.tableMenu.body.options[0].splice(shareLinkOptIndex, 1)
      }
      
      if(recordShareLinks.length>=1){
        var shareLinkMenu = {
          className: "tableMenuItem",
          code: "shareLink",
          disabled: false,
          name: this.$t('publicEdit.shareLink'),
          prefixIcon: "mdi mdi-share-variant tableMenuItemIcon",
          visible: true,
          urlId:  "",
          children: []
        }
        if(recordShareLinks.length==1){
          shareLinkMenu.urlId = recordShareLinks[0].url
        }else{
          for(var i = 0; i<recordShareLinks.length; i++){
            shareLinkMenu.children.push({
              className: "tableMenuItem",
              code: "shareLink",
              disabled: false,
              name:  this.$t('publicEdit.copyOf', {templateName:recordShareLinks[i].templateId.identifier}),
              prefixIcon: "mdi mdi-content-copy tableMenuItemIcon",
              urlId: recordShareLinks[i].url,
              visible: true
            })
          }
        }
        this.tableMenu.body.options[0].push(shareLinkMenu)
      }
      this.$refs.xTable.setCurrentRow(row);
    },
    createMenu(menuItemCodeList) {
      const vm = this;
      let result = [];
      for (let i = 0; i < menuItemCodeList.length; i++) {
        const loopCode = menuItemCodeList[i];
        const MENU = MENUS.find((m) => m.code === loopCode);
        if (MENU) {
          let menu = Object.assign(
            {
              name: vm.$t(MENU.nameTag),
            },
            MENU
          );
          delete menu.nameTag;
          result.push(menu);
        }
      }
      // console.log('createeMeu :: result: ', result)

      return result;
    },
    saveQuickInsertData() {
      const vm = this;
      const tableData = vm.$refs.xTable.tableData;
      const obsolateIds = vm.quickInsertUnusedRecordIds;
      const dataRows = tableData.filter(
        (row) => !obsolateIds.includes(row._id)
      );

      EventBus.$emit('showSpinner')
      vm.$store
        .dispatch("SAVE_DATA_BATCH", {
          obsolateIds: obsolateIds,
          data: dataRows,
        })
        .then((response) => {
          const savedCount = response.length;
          const msg = vm.$t("messages.nRecordsSavedSuccessfully", {
            count: savedCount,
          });
          vm.$XModal.message({
            message: msg,
            status: "success",
          }).then(
            () => {
              vm.initQuickInsertMode()
            }
          )
          EventBus.$emit('hideSpinner')
        }).catch(err=>{
          this.$toast.error(this.$t("errors.save"))
          EventBus.$emit('hideSpinner')
        }).finally(()=> {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.form.label,
              func_name: this.$sensors.events.plus_sheet_mani.table_view.QUICK_CREATE_RECORDS
            }
          });
        });
    },

    checkAndSaveQuickInsertData() {
      const vm = this;
      if (vm.quickInsertDataRowCount === 0) {
        this.$XModal.message({
          content: vm.$t("messages.noData"),
          status: "warning",
        });
      } else {
        this.fullValidEvent(() => {
          vm.saveQuickInsertData();
        });
      }
      // alert('saveAllQuickInsertData')
    },

    getColDuplications (fieldId) {
      const vm = this
      const rows = vm.$refs.xTable.tableData
      let result = []
      for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        const loopRow = rows[rowIndex]
        if (loopRow[fieldId]) {
          const index = result.findIndex(item => item.value === loopRow[fieldId])
          if (index >= 0) {
            result[index].rowIndices.push(rowIndex)
          } else {
            result.push({
              rowIndices: [rowIndex],
              value: loopRow[fieldId]
            })
          }
        }
      }
      return result.filter(item => item.rowIndices.length > 1)
    },

    getLocalDuplication () {
      const vm = this
      vm.loadingPageSize = true
      const colDefsWithNoDup = vm.colDefs.filter(colDef => {
        const properties = colDef.fieldInfo.properties
        return properties.validation && properties.validation.split('||').includes('noDuplication')
      })
      // console.log('getLocalDuplication: colDefsWithNoDup: ', colDefsWithNoDup)
      let result = {}
      for (let i = 0; i < colDefsWithNoDup.length; i++) {
        const loopColDef = colDefsWithNoDup[i]
        const fieldId = loopColDef.fieldInfo.fieldId
        const duplications = vm.getColDuplications(fieldId)
        // [
        //    {
        //      rowIndices: [],
        //      value: ''
        //    }
        // ]
        for (let j = 0; j < duplications.length; j++) {
          const loopDup = duplications[j]
          for (let k = 0; k < loopDup.rowIndices.length; k++) {
            const dummyKey = j + '-' + k
            result[dummyKey] = [{
              rowIndex: loopDup.rowIndices[k],
              column: loopColDef,
              rules: [{
                message: vm.$t('messages.hasDuplicate') + ': ' + loopDup.value
              }]
            }]
          }
        }
      }
      return Object.keys(result).length === 0 ? null : result
    },

    async fullValidEvent (successHandler) {
      const vm = this
      let errMap = null
      const tableData = vm.$refs.xTable.tableData;
      const obsolateIds = vm.quickInsertUnusedRecordIds;
      const dataRows = tableData.filter(
        (row) => !obsolateIds.includes(row._id)
      );
      let errMap1 = await this.$refs.xTable.fullValidate(dataRows).catch(errMap => errMap)
      let errMapNoDup = vm.getLocalDuplication()

      if (errMap1) {
        errMap = {...errMap1}
        if (errMapNoDup) {
          errMap = Object.assign(errMap, errMapNoDup)
        }
      } else {
        if (errMapNoDup) {
          errMap = {...errMapNoDup}
        }
      }
      if (errMap) {
        let msgList = [];
        Object.values(errMap).forEach((errList) => {
          errList.forEach((params) => {
            let { rowIndex, column, rules } = params;
            rules.forEach((rule) => {
              // msgList.push(`第 ${rowIndex+1} 行: ${column.title} 校验错误：${rule.message}`)
              msgList.push(
                vm.$t("messages.vxeError", {
                  rowNo: rowIndex + 1,
                  fieldName: column.title,
                  message: rule.message,
                })
              );
            });
          });
        });
        vm.showMessagePanel(msgList);

      } else {
        vm.hideMessagePanel();
        if (typeof successHandler === "function") {
          successHandler();
        } else {
          //this.$XModal.message({ status: "success", message: "校验成功！" });
        }
      }
    },

    showMessagePanel(msgList) {
      EventBus.$emit("showMessagePanel", {
        messages: msgList,
      });
    },

    hideMessagePanel() {
      EventBus.$emit("showMessagePanel", {
        messages: null,
      });
    },

    copyToClipboard(payload) {
      const vm = this;

      // payload = {
      //    rowId,
      //    fieldId
      // }

      // this.clipboardRowId = payload.rowId
      // this.clipboardFieldId = payload.fieldId

      // console.log("copyToClipboard: payload.rowId = " + payload.rowId);
      // console.log("copyToClipboard: payload.fieldId = " + payload.fieldId);
      vm.$refs.xTable.clearActived()
      const tableData = vm.$refs.xTable.tableData;
      const row = tableData.find((d) => d._id === payload.rowId);
      if (row) {
        // console.log('copyToClipboard: row: ', row)
        this.clipboardValue = row[payload.fieldId];
        this.clipboardFieldInfo = vm.getFieldInfo(payload.fieldId);
      } else {
        this.clipboardValue = null;
        this.clipboardFieldInfo = null;
      }
    },

    checkCanTransfer(sourceFieldInfo, targetFieldInfo) {
      let result = sourceFieldInfo.fieldId === targetFieldInfo.fieldId;
      if (!result) {
        const validTargetTypes = TRANSFERRABLE_MAP[sourceFieldInfo.type];
        result = validTargetTypes.includes(targetFieldInfo.type);
      }
      return result;
    },

    pasteFromClipboard(payload) {
      const vm = this;
      // console.log('pasteFromClipboard : payload: ', payload)

      // const sourceFieldId = vm.clipboardFieldInfo.fieldId
      // const targetFieldId = payload.fieldId
      vm.$refs.xTable.clearActived()
      
      const sourceFieldInfo = vm.clipboardFieldInfo;
      const targetFieldInfo = vm.getFieldInfo(payload.fieldId);

      // console.log("sourceFieldInfo: ", sourceFieldInfo);
      // console.log("targetFieldInfo: ", targetFieldInfo);
      const transferrable = vm.checkCanTransfer(
        sourceFieldInfo,
        targetFieldInfo
      );
      // console.log("pasteFromClipboard : transferrable: ", transferrable);
      if (transferrable) {
        const tableData = vm.$refs.xTable.tableData;

        // const sourceRowIndex = tableData.findIndex(row => row._id === vm.clipboardRowId)
        const targetRowIndex = tableData.findIndex(
          (row) => row._id === payload.rowId
        );

        const target = {
          rowIndex: targetRowIndex,
          fieldInfo: targetFieldInfo,
        };
        console.log("pasteFromClipboard :: target: ", target);
        vm.pasteCellValue(tableData, target);
      }
      // this.clipboardRowId = payload.rowId
      // this.clipboardFieldId = payload.fieldId
    },

    pasteCellValue(tableData, target) {
      const vm = this;
      if (vm.clipboardFieldInfo.fieldId === target.fieldInfo.fieldId) {
        // if same field, direct copy
        vm.directCopy(tableData, target);
      } else {
        if (target.rowIndex >= 0) {  
          if (target.fieldInfo.type === 'text') {
            vm.directCopy(tableData, target, vm.clipboardValueString)
          } else {
            vm.directCopy(tableData, target);
          }
        }
      }
    },

    directCopy(tableData, target, value) {
      const vm = this;
      if (typeof value === "undefined") {
        value = vm.clipboardValue;
      }
      tableData[target.rowIndex][target.fieldInfo.fieldId] = JSON.parse(
        JSON.stringify(value)
      );
    },

    getFieldInfo(fieldId) {
      const vm = this;
      const fieldInfo = vm.fieldInfos.find((info) => info.fieldId === fieldId);
      return fieldInfo;
    },
    fetchCustomizedButtons() {
      const appId = this.$route.params.id || this.appId;
      const formId = this.$route.params.itemId  || this.formId;
      const { viewId } = this.$route.params;
      if (this.currentApp && this.form) {
        this.$store.dispatch(FETCH_ACTION_BUTTON_OPTIONS, {
          appId: appId,
          formId: formId,
          viewId
        });
      }
    },
    getPageSize() {
      const vm = this;
      vm.loadingPageSize = true;
       vm.pageSizeAvailable = false
      // console.log('getPageSize : this.$route.params.viewId = ' + this.$route.params.viewId)
      const prefKey = vm.$route.params.itemId + ":" + vm.$route.params.viewId;
      // console.log('getPageSize : prefKey = ' + prefKey)

      vm.$store
        .dispatch("FETCH_PAGE_SIZE", {
          key: prefKey,
        })
        .then((response) => {
          // console.log('TableView :; getPageSize: response: ', response)
          vm.savedPageSize = response ? response : 20;
          vm.pageSizeAvailable = true

          vm.$refs.xTable.tablePage.pageSize = vm.savedPageSize;
          vm.pagination.pageSize = vm.savedPageSize;

          console.log('getPageSize => refresh')
          vm.refresh();
          vm.$nextTick(() => {
            vm.loadingPageSize = false;
          });
        });
    },
    savePageSize() {
      const vm = this;

      vm.$store
        .dispatch("SAVE_PAGE_SIZE", {
          key: vm.$route.params.itemId + ":" + vm.$route.params.viewId,
          value: vm.pagination.pageSize,
        })
        .then(() => {
          // console.log('TableView :; savePageSize: response: ', response)
          // if (response) {
          //   vm.gridOptions.pagerConfig.pageSize = response
          // }
          // console.log('savePageSize: vm.gridOptions.pagerConfig.pageSize = ' + vm.gridOptions.pagerConfig.pageSize)
        });
    },
    activeRowMethod({ row }) {
      return this.canInlineEdit && row.canEdit || false;
    },
    onShowMenuHandler(args) {
      alert("onShowMenuHandler = " + JSON.stringify(args));
    },
    visibleMethod({ options, column }) {
      let isDisabled = !column;
      options.forEach((list) => {
        list.forEach((item) => {
          item.disabled = isDisabled;
        });
      });
      return !this.disableContextMenu;
    },
    moveToTop(tableData, rowIndex) {
      if (rowIndex > 0) {
        const rows = tableData.splice(rowIndex, 1);
        tableData.unshift(rows[0]);
      }
    },

    moveUp(tableData, rowIndex) {
      if (rowIndex > 0) {
        const rows = tableData.splice(rowIndex, 1);
        tableData.splice(rowIndex - 1, 0, rows[0]);
      }
    },

    moveDown(tableData, rowIndex) {
      if (rowIndex < tableData.length - 1) {
        const rows = tableData.splice(rowIndex, 1);
        tableData.splice(rowIndex + 1, 0, rows[0]);
      }
    },

    moveToBottom(tableData, rowIndex) {
      if (rowIndex < tableData.length - 1) {
        const rows = tableData.splice(rowIndex, 1);
        tableData.push(rows[0]);
      }
    },

    addBelow(tableData, rowIndex, newRecord) {
      if (rowIndex < tableData.length) {
        tableData.splice(rowIndex + 1, 0, newRecord);
      } else {
        tableData.push(newRecord);
      }
    },

    insertAbove(tableData, rowIndex, newRecord) {
      if (rowIndex < tableData.length) {
        tableData.splice(rowIndex, 0, newRecord);
      } else {
        tableData.push(newRecord);
      }
    },

    resetAllRows(xTable, tableData) {
      for (let i = 0; i < tableData.length; i++) {
        xTable.revertData(tableData[i]);
      }
    },

    executeGlobalActionToCustomizedButton(actionId, data) {
      this.$store.dispatch(SET_GLOBAL_ACTION, {
        type: actionId,
        data: data._id || data,
      });
      // this.$refs.customerButtons.$refs['61728d4d56f5dfbfa87c3df0'][0].$el.click
    },
    selectGlobalActionToCustomizedButton(data=[]) {
      // this.$store.dispatch(RESET_GLOBAL_ACTION);
      if (this.selectedActionId && data.length) {
        this.$store.dispatch(INTERACT_ACTION_BUTTOIN, {
          buttonId: this.selectedActionId,
          appId: this.appId,
          formId: this.formId,
          data,
        });
      }
    },

    resetCurrentRow(xTable, rowIndex) {
      const vm = this;
      const rowId = xTable.tableData[rowIndex]._id
      xTable.tableData[rowIndex] = Object.assign(
        xTable.tableData[rowIndex],
        JSON.parse(vm.blankRecordDataStr),
        {_id: rowId}
      )
    },

    contextMenuClickEvent({ menu, row, rowIndex }) {
      let xTable = this.$refs.xTable;
      let tableData = xTable.tableData;
      switch (menu.code) {
        case "customizedAction": 
          this.executeGlobalActionToCustomizedButton(menu.templateId, row);
          break;
        case "copyRow":
          this.copyRow(row, rowIndex);
          break;
        case "deleteRow":
          this.deleteRow(row);
          break;
        case "showPrintPreview":
          this.print(row, "");
          break;
        case "print":
          this.print(row, {templateId: menu.templateId, type:menu.type, templateName:menu.name});
          break;
        case "moveToTop":
          this.moveToTop(tableData, rowIndex);
          break;
        case "moveToBottom":
          this.moveToBottom(tableData, rowIndex);
          break;
        case "moveUp":
          this.moveUp(tableData, rowIndex);
          break;
        case "moveDown":
          this.moveDown(tableData, rowIndex);
          break;
        case "insertOneRowAbove":
          this.createRows(1, INSERT_ABOVE, rowIndex);
          break;
        case "insertFiveRowsAbove":
          this.createRows(5, INSERT_ABOVE, rowIndex);
          break;
        case "insertTenRowsAbove":
          this.createRows(10, INSERT_ABOVE, rowIndex);
          break;
        case "addOneRowBelow":
          this.createRows(1, ADD_BELOW, rowIndex);
          break;
        case "resetAllRows":
          this.resetAllRows(xTable, tableData);
          break;
        case "resetCurrentRow":
          this.resetCurrentRow(xTable, tableData, row, rowIndex);
          break;
        case "addFiveRowsBelow":
          this.createRows(5, ADD_BELOW, rowIndex);
          break;
        case "addTenRowsBelow":
          this.createRows(10, ADD_BELOW, rowIndex);
          break;
        case "shareLink":
          this.copyShareLink(menu.urlId)
          break
        case "shareable":
          this.openSharebleSettings(row._id)
          break
        case "openInNewTab": 
          this.openInNewTab(row._id);
          break;
      }
    },
    openInNewTab(rowId){
      const routeData = this.$router.resolve({
        name: 'records',
        params: {
          ...this.$route.params,
          recordId: rowId
        }
      });
      window.open(routeData.href, '_blank');
    },
    copyShareLink(urlId){
      if(urlId){
        navigator.clipboard.writeText(window.location.origin + "/public/edit/" + urlId)
        this.$toast.success(this.$t('messages.copied'));
      }
    },
    newRelatedRecord(payload) {
      const vm = this;
      vm.showOverlay = true;
      if (payload) {
        vm.attach = payload.attach;
        this.$refs.popupRecordDialog.open(payload, () => {        
          vm.disableContextMenu = false;
          vm.showOverlay = false;
        })
      }
    },
    print(row, {templateId, type, templateName}) {
      const payload = {
        command: "print",
        templateId: templateId,
        type,
        recordId: row._id,
      }
      if (type === 1){
        Object.assign(payload, {
          appId: this.appId,
          formId: this.form._id,
          viewId: this.view._id,
          templateName
        })
      }
      this.$emit("onCommand", payload);
      this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.table_view[`PRINT_RECORD_${type ===0 ? 'NORMAL':'DOCX'}`],
            }
      });
    },
    selectRelatedCards(payload) {
      const vm = this;
      vm.showOverlay = true;
      vm.attach = payload.attach;
      // console.log('TableView :: selectRelatedCards :: payload: ', payload)
      vm.disableContextMenu = true;
      vm.$refs.selectRelatedCardsDialog.open(
        payload,
        vm.onRelatedRecordSelected,
        vm.onRelatedRecordCancelled
      );
    },

    selectRelatedRecords(payload) {
      const vm = this;
      vm.showOverlay = true;
      vm.attach = payload.attach;
      // console.log('TableView :: selectRelatedRecords :: payload: ', payload)
      vm.disableContextMenu = true;
      vm.$refs.selectRelatedRecordsDialog.open(
        payload,
        vm.onRelatedRecordSelected,
        vm.onRelatedRecordCancelled
      );
    },

    onRelatedRecordSelected(payload, callbackBundle) {
      const vm = this;
      vm.disableContextMenu = false;
      vm.showOverlay = false;
      if (typeof callbackBundle.onSelected === "function") {
        callbackBundle.onSelected(payload);
      }
    },

    onRelatedRecordCancelled(callbackBundle) {
      const vm = this;
      vm.showOverlay = false;
      vm.disableContextMenu = false;
      if (typeof callbackBundle.onCancelled === "function") {
        callbackBundle.onCancelled();
      }
    },

    rowClasses() {
      const height = this.isQuickInsertMode
        ? this.quickInsertModeRowHeight
        : this.view.tableRowHeight || "xs";
      return `vxe-table-row-${height}`;
    },
    search(payload) {
      const vm = this;
      vm.keyword = payload;
      vm.filterValue = [{
        fieldId: '',
        value: payload.searchValue
      }]
      vm.$refs.xTable.tablePage.currentPage = 1;
      vm.refresh();
    },

    getTableOptions() {
      const vm = this;
      return {
        tableSummationFieldIds: vm.view.tableSummationFieldIds,
        summaryConfigs: vm.summaryConfigs,
        summaryData: vm.summaryData
      };
    },

    showFooter() {
      return true;
    },
    // hiddenButtonList () {
    //   return
    // },
    isEditing(row) {
      return this.$refs.xTable.isActiveByRow(row);
    },
    isUpdated(row) {
      return this.$refs.xTable.isUpdateByRow(row);
    },
    editDisabledEvent() {
      return true;
    },
    async onRefreshClicked() {
      const vm = this;
      //console.log('onRefreshClicked => FETCH_DATA')
      vm.$refs.xTable.tablePage.currentPage = 1;
      this.resetTableState();
      this.refresh();
    },
    resizableChanged({ column, $table }, e) {
      e.preventDefault();
      const renderWidth = column.renderWidth;
      const fieldId = column.property
        ? column.property
        : column.type
        ? `type=${column.type}`
        : undefined;
      const tableId = $table.id;
      let localstor = JSON.parse(
        localStorage.getItem("VXE_TABLE_CUSTOM_COLUMN_WIDTH")
      );
      if (localstor !== null) {
        //update existing value
        Object.assign(localstor[tableId], {
          [fieldId]: renderWidth,
        });
      } else {
        //create new localStorage item
        localstor = {
          [tableId]: {
            [fieldId]: renderWidth,
          },
          _v: 0,
        };
      }
      localStorage.setItem(
        "VXE_TABLE_CUSTOM_COLUMN_WIDTH",
        JSON.stringify(localstor)
      );
    },
    toggleSelectAllInPage(){
      if(this.excludeRecords.length!=0)
        this.selectAllEvent({
          checked: true, records: this.$refs.xTable.tableData, checkedByBtn:true
        })
      else{
        if(this.isAllRecords)
          this.selectAllEvent({
            checked: false, records: [], checkedByBtn:true
          })
        else 
          this.selectAllEvent({
            checked: true, records: this.$refs.xTable.tableData, checkedByBtn:true
          }) 
      }
    },
    toggleSelectAllRows({ checked }) {
      this.isAllRecords = !this.isAllRecords
      this.includeRecords = [];
      this.excludeRecords = [];
      this.$refs.xTable.setAllCheckboxRow(this.isAllRecords);
      this.$emit("update_ofSelections", 
        this.isAllRecords ? this.$refs.xTable.tableData.map((datum) => datum._id) : []);
      if(this.numberOfSelections>this.pagination.pageSize)
        this.isCurrentPage = false
      else
        this.isCurrentPage = true
    },
    resetSelection() {
      this.isCurrentPage = true;
      this.excludeRecords = [...this.currentPageDataIds];
      this.$refs.xTable.setAllCheckboxRow(false);
    },

    selectAllItems({isAllPages=false}) {
      this.isCurrentPage = !isAllPages;
      this.isAllRecords = isAllPages;
      this.selectAllEvent({
          checked: true, 
          records: this.$refs.xTable.tableData, 
          checkedByBtn:true
      }) 
    },

    selectAllEvent({ checked, checkedByBtn=false }) {
      // notes: if not triggered by table view, set checkbox manually
      if(checkedByBtn){
        this.$refs.xTable.setAllCheckboxRow(true);
      } 
      if (checked) {
        this.excludeRecords = [];
      } else {
        this.excludeRecords = this.$refs.xTable.tableData.map((datum) => datum._id);
      }
    },

    selectChangeEvent({ checked, row }) {
      if (checked) {
        const currentPageDataIds = this.$refs.xTable.tableData.map(item => item._id);
        const checkedItemIds = this.$refs.xTable.getCheckboxRecords().map( item => item._id);
        this.excludeRecords = currentPageDataIds.filter(id => !checkedItemIds.includes(id));
      } else {
        this.excludeRecords.push(row._id);
      }
      if (this.excludeRecords.length === this.$refs.xTable.tablePage.total) {
        this.clearSelection();
      }
    },

    initQuickInsertMode() {
      const vm = this;
      vm.$refs.xTable.tableData = [];      
      vm.createRows(5);
    },

    createRows(count, addBelow = true, rowIndex = -1, callback) {
      const vm = this;
      const blankRecordData = newBlankRecord(vm.fieldInfos);
      vm.$emit("onDataLoading");
      blankRecordData["formId"] = vm.formId;
      blankRecordData["canEdit"] = true;
      blankRecordData["canDelete"] = true;
      blankRecordData["owner"] = vm.$store.getters.userFieldValue;
      blankRecordData["createdBy"] = vm.$store.getters.userFieldValue;

      vm.blankRecordDataStr = JSON.stringify(blankRecordData);

      vm.loading = true;
      vm.$store
        .dispatch("GENERATE_TEMP_RECORD_ID", {
          appId: vm.appId,
          formId: vm.formId,
          count: count,
        })
        .then((response) => {
          const newRecordIds = response.result
          const tableData = vm.$refs.xTable.tableData;

          console.log('newRecordIds: ' + newRecordIds.join(', '))
          for (let i = 0; i < newRecordIds.length; i++) {
            const loopId = newRecordIds[i];
            console.log('i=' + i + ': loopId = ' + loopId)
            const newRecord = JSON.parse(vm.blankRecordDataStr);
            console.log('i=' + i + ': newRecord: ', newRecord)
            newRecord._id = loopId;

            if (rowIndex === -1) {
              vm.$refs.xTable.tableData.push(newRecord);
            } else {
              if (addBelow) {
                vm.addBelow(tableData, rowIndex, newRecord);
              } else {
                vm.insertAbove(tableData, rowIndex, newRecord);
              }
            }
            vm.$refs.xTable.tablePage.total++;
          }
          if (typeof callback === "function") {
            callback();
          }
          vm.$emit("onDataLoaded");
          vm.loading = false;
        }).catch(error=>{
          this.$toast.error(this.$t("errors.create"))
          vm.loading = false;
        });;
    },

    newRecord() {
      const vm = this;
      var result = {
        _id: "",
        canEdit: true,
      };

      if (vm.fieldInfos) {
        for (let i = 0; i < vm.fieldInfoss.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i];
          result[loopFieldInfo.fieldId] = vm.getFieldDefault(loopFieldInfo);
        }
      }
      return result;
    },
    exportData(fieldIds) {
      let data = {
        appId: this.$route.params.id,
        formId: this.form._id,
        fieldIds: fieldIds,
        viewId: this.view._id,
        exclude: this.excludeRecords,
        include: this.includeRecords,
      };
      const postData = {
        urlCommand: "/data/export",
        data,
      };
      this.$store.dispatch("AUTH_POST", postData).then(res=> {
      if (res.status === 'queued'){
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
              key: "plus_sheet_mani",
              data: {
                module_name: this.form.label,
                func_name: this.$sensors.events.plus_sheet_mani.table_view.EXPORT_RECORD
              }
        });
        this.$notify({
          group: "background-job",
          title: this.$t('buttons.export'),
          text: this.$t('messages.exportStarted'),
          id: res.id,
          duration:-1
        });
        }
      });
    },

    calcSummaryData (rows) {
      console.log('calcSummaryData: isAllRecords: ' + (this.isAllRecords ? 'yes' : 'no'))
      if (this.isAllRecords) {
        this.calcSummaryDataAll()
      } else {
        this.calcSummaryDataLocal(rows)
      }
    },

    calcSummaryDataAll () {
      const vm = this
      console.log('calcSummaryData: calcSummaryDataAll: ')
      const params = {
        appId: vm.appId,
        formId: vm.form._id,
        viewId: vm.view._id
      }

      const quickFilters = vm.verifyQuickFilters()
      if (quickFilters.length > 0 ) {
        Object.assign(params, {
          filter: JSON.stringify(quickFilters)
        });        
      }

      if (vm.keyword) {
        Object.assign(params, {
          keyword: vm.keyword,
        });
      }

      var result = {}
      vm.$store.dispatch('FETCH_DATA_SUMMARY', params).then(
        response => {
          const summaryData = response
          console.log('summaryData: ', summaryData)
          vm.summaryData = []

          for (let i = 0; i < vm.summaryConfigs.length; i++) {
            const loopConfig = vm.summaryConfigs[i]
            const fieldId = loopConfig.fieldId    
            var labelValuePairs = []   
            for (let j = 0; j < loopConfig.methods.length; j++) {
              const loopMethod = loopConfig.methods[j]
              const summaryFieldName = fieldId + '_' + loopMethod
              labelValuePairs.push({
                label: vm.$t('view.' + loopMethod),
                value: summaryData[summaryFieldName] ? summaryData[summaryFieldName] : 0
              })
            }
            vm.summaryData.push({
              fieldId: fieldId,
              labelValuePairs: labelValuePairs
            })
          }
        }
      )

      return result
    },

    calcSummaryDataLocal (rows) {

      const vm = this
      // vm.summaryConfigs = [
      //    {fieldId, methods: []},
      //    {fieldId, methods: []},
      //    {fieldId, methods: []},
      //    {fieldId, methods: []},
      // ]     
      //
      const data = rows ? rows : vm.$refs.xTable.tableData
      var result = []
      for (let i = 0; i < vm.summaryConfigs.length; i++) {
        const loopConfig = vm.summaryConfigs[i]
        const fieldId = loopConfig.fieldId
        var labelValuePairs = []
        for (let j = 0; j < loopConfig.methods.length; j++) {
          const loopMethod = loopConfig.methods[j]
          var value = 0
          switch (loopMethod) {
            case 'filled':
              value = data.filter(item => {
                return !(item[loopConfig.fieldId]===null ||
                  item[loopConfig.fieldId]==='' ||
                  (Array.isArray(item[loopConfig.fieldId]) && item[loopConfig.fieldId].length===0))
              }).length
              break
            case 'nonFilledIn':
              value = data.filter(item => {
                return item[loopConfig.fieldId]===null ||
                  item[loopConfig.fieldId]==='' ||
                  (Array.isArray(item[loopConfig.fieldId]) && item[loopConfig.fieldId].length===0)
              }).length
              break
            case 'sum':
              value = XEUtils.sum(data, loopConfig.fieldId)
              break
            case 'average':
              value = XEUtils.mean(data, loopConfig.fieldId)
              break
            case 'max':
              value = data.reduce((max, item) => {
                let val = item[loopConfig.fieldId] ? 
                  parseFloat(item[loopConfig.fieldId]) : 0
                  
                if (max) {
                  return val > max ? val : max
                } else {
                  return val
                }
              }, null)
              break
            case 'min':
              value = data.reduce((min, item) => {
                let val = parseFloat(item[loopConfig.fieldId])
                if (min) {
                  return val < min ? val : min
                } else {
                  return val
                }
              }, null)
              break
          }
          // console.log(loopMethod + ' (' + data.length + ') : ', value)
          labelValuePairs.push({
            label: vm.$t('view.' + loopMethod),
            value: value
          })
        }
        result.push({
          fieldId: fieldId,
          labelValuePairs: labelValuePairs
        })
      }
      vm.summaryData = result
    },
    ajaxLoadData(page, sort) {
      const vm = this;
      if (!vm.pageSizeAvailable) {
        return Promise.resolve({
          page: {
            total: 0,
          },
          result: [],
        });
      }
      
      var tableFieldIds = this.colDefs.map(item=>item.fieldInfo.fieldId)
      var tableFields = this.fieldInfos.filter(field=>{
        return tableFieldIds.includes(field.fieldId)
      })
      
      // var defalutFieldsVal = newBlankRecord(tableFields)
      // In quick-insert mode, no auto-loading necessary
      if (vm.isQuickInsertMode) return;
      
      vm.$emit("onDataLoading");
      vm.loading = true;
      this.clearSelection();

      return new Promise((resolve, reject) => {
        if (vm.currentApp && vm.form) {
          const params = {
            appId: vm.currentApp._id,
            formId: vm.form._id,
            viewId: vm.view._id,
            pagination: {
              currentPage: page.currentPage,
              pageSize: page.pageSize,
            },
          };
          let quickFilters = [...vm.verifyQuickFilters()]
          if (quickFilters.length > 0 ) {
            Object.assign(params, {
              filter: JSON.stringify(quickFilters)
            });
          }

          if (vm.keyword) {
            Object.assign(params, {
              keyword: vm.keyword,
            });
          }

          if (Object.keys(sort).length > 0) {
            Object.assign(params, {
              sortingFieldId: sort.field,
              sortingOrder: sort.order,
            });
          }
          
          vm.$store.dispatch("FETCH_DATA_LIST", params).then((response) => {
            vm.shareLinks = response.shareLinks
            response.result = response.result.map(row=>{
              var obj = {
                ...row
              }
              // console.log("tableFields", tableFields)
              tableFields.forEach(field=>{
                if(!row.hasOwnProperty(field.fieldId)||row[field.fieldId]==null){
                  obj[field.fieldId] = null
                  switch(field.type){
                    case 'region':
                      obj[field.fieldId] = {province: null, city: null, state: null, regionText: ""}
                      break

                    default:
                      obj[field.fieldId] = null
                  }
                }
                // if(!row[fieldId])
                  // obj[fieldId] = defalutFieldsVal[fieldId]
              })
              return obj
            })
            vm.pagination = response.pagination;
            if (vm.$refs.xTable) {
              vm.$refs.xTable.resetColumn();
            }
            // console.log("response.result", response.result)
            resolve({
              page: {
                total: response.pagination.totalResult,
              },
              result: response.result,
            });
            this.currentPageDataIds = response.result.map(row => row._id);
            this.excludeRecords = [...this.currentPageDataIds];
            this.isCurrentPage = true;
            // vm.tableData = response.result
            // vm.$refs.xTable.reloadData(response.result)
            // vm.$refs.xTable.clearAll()

            // fetch relation field titles
            vm.fetchRelationDetails(response.result);
            vm.calcSummaryData(response.result);
            vm.$emit("onDataLoaded");
            vm.loading = false;
          }).catch(error=>{
            vm.$emit("onDataLoaded");
            vm.loading = false
            this.$toast.error(this.$t("errors.load"))
            resolve({
              page: {
                total: 0,
              },
              result: [],
            });
          });
        } else {
          reject("App or Form not defined");
        }
        this.$refs.xTable.setMergeFooterItems(this.mergeFooterItems)
      });
    },
    refreshRelationData(payload) {
      const vm = this;
      const extra = payload.addition;
      if (vm.$refs.xTable) {
        // console.log('refreshRelationData :: vm.$refs.xTable.tableData: ', vm.$refs.xTable.tableData)
        vm.fetchRelationDetails(vm.$refs.xTable.tableData, extra);
      }
    },

    verifyQuickFilters () {
      const vm = this
      var result = []
      if (vm.quickFilters) {
        for (let i = 0; i < vm.quickFilters.length; i++) {
          const loopFilter = vm.quickFilters[i]
          const filterConfig = vm.criteriaConfigs[loopFilter.relation]

          // console.log('i=' + i + ': relation: ' + loopFilter.relation)
          // console.log('i=' + i + ': relation: ' + loopFilter.relation)
          // console.log('i=' + i + ': inputCount = ' + filterConfig.inputCount)

          if (filterConfig.inputCount) {
            if (filterConfig.inputCount === 1) {
              // console.log('filterValue1: ', loopFilter.filterValue1)
              if (loopFilter.filterValue1) {
                result.push(loopFilter)
              }
            } else if(filterConfig.inputCount === 2) {
              if (loopFilter.filterValue1 && loopFilter.filterValue2) {
                result.push(loopFilter) 
              } 
              else if (loopFilter.filterValue1 || loopFilter.filterValue2) {
                switch (loopFilter.relation) {
                  case 'withinPeriod':
                    if (loopFilter.filterValue1) {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'laterThan',
                        filterValue1: loopFilter.filterValue1,
                        filterValue2: ''
                      })
                    } else {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'earlierThan',
                        filterValue1: loopFilter.filterValue2,
                        filterValue2: ''
                      })
                    }
                    break
                  case 'notWithinPeriod':
                    if (loopFilter.filterValue1) {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'earlierThan',
                        filterValue1: loopFilter.filterValue1,
                        filterValue2: ''
                      })
                    } else {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'laterThan',
                        filterValue1: loopFilter.filterValue2,
                        filterValue2: ''
                      })
                    }
                    break
                  case 'inRange':
                    if (loopFilter.filterValue1) {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'greaterOrEqual',
                        filterValue1: loopFilter.filterValue1,
                        filterValue2: ''
                      })
                    } else {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'smallerOrEqual',
                        filterValue1: loopFilter.filterValue2,
                        filterValue2: ''
                      })
                    }
                    break
                  case 'notInRange':
                    if (loopFilter.filterValue1) {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'smallerThan',
                        filterValue1: loopFilter.filterValue1,
                        filterValue2: ''
                      })
                    } else {
                      result.push({
                        fieldId: loopFilter.fieldId,
                        relation: 'greaterThan',
                        filterValue1: loopFilter.filterValue2,
                        filterValue2: ''
                      })
                    }
                    break
                }
              }
            }
          } else {
            result.push(loopFilter)
          }
          // console.log('i=' + i + ': result: ', result)
        }        
      }
      // console.log('verifyQuickFilters ends: result: ', result)
      return result
    },

    fetchRelationDetails(rows, extra = null) {
      const vm = this;
      if (typeof rows === "undefined") rows = vm.$refs.xTable.tableData;
      try {
        fetchRelatedDataFieldsInRows(
          vm,
          rows,
          extra,
          false,
          vm.fieldInfos,
          vm.effectiveFieldIds,
        ).then(response => {
          vm.relationData.idDataMapping = response;
        });
      } catch (err) {
        console.log("fetchRelationDetails :: err: ", err);
      }


    },
    checkColumnMethod() {
      // console.log("checkColumnMethod: not defined yet");
    },
    reloadData() {
      this.$refs.xTable.reloadData();
    },
    updateStatus() {
      const vm = this;
      vm.$refs.xTable.updateStatus();
    },
    hasRowChanged() {
      return;
      //vm.$refs.xTable.isActiveByRow(row) || vm.$refs.xTable.isUpdateByRow(row);
    },
    editActiveEvent({
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
    }) {
      const vm = this;
      vm.savedCellInfo = {
        rowId: row._id,
        fieldId: column.prop,
        value: row[column.prop],
      };
      // console.log(payload.$table.collectColumn)
      // payload.column = payload.$table.collectColumn[20]
      // payload.columnIndex = 20
    },
    editClosedEvent({ row }) {
      const vm = this;

      // for (let key in column) {
      // console.log('editClosedEvent key = ' + key)
      // console.log('editClosedEvent column[prop]: ', column['prop'])
      // console.log('editClosedEvent column[property]: ', column['property'])

      // }

      if (vm.isQuickInsertMode) return;
      if (!vm.loading) {
        const xTable = vm.$refs.xTable;
        // console.log('editClosedEvent :: row: ', row)
        if (xTable.isUpdateByRow(row)) {
          // const errMap = await xTable.validate(row).catch((errMap) => errMap);
          // if (errMap) {
          //   vm.cancelRowEvent(row);
          //   return;
          // }
          vm.saveRowEvent(row);
        } else {
          console.log("editClosedEvent :: not isUpdatedByRow(row)");
        }
      }
    },
    async cancelRowEvent(row) {
      const xTable = this.$refs.xTable;
      await xTable.revertData(row);
      //await xTable.clearActived()

      // type === cancel
      // xTable.revertData() // Data(row)
      // vm.$refs.xTable.revertData(row)
      // vm.refresh()
      // xTable.reloadRow(row)
    },


    confirmAbandon(confirmHandler, cancelHandler) {
      const vm = this

      vm.confirmEither({
        title: vm.$t('general.warning'),
        message: vm.$t('messages.errorsOccurred'),
        color: 'error',

        confirmBtnColor: 'error',
        confirmMsg: vm.$t('buttons.abandon'),
        confirmHandler: confirmHandler,
        
        cancelBtnColor: 'muted',
        cancelMsg: vm.$t('buttons.continueEditing'),
        cancelHandler: cancelHandler
      })
    },

    confirmEither(option) {
      const vm = this;

      console.log('confirmEither: option: ', option)
      vm.$refs.confirmDialog
        .open(option.title, option.message, {
          color: option.color,
          confirmBtnColor: option.confirmBtnColor,
          confirmMsg: option.confirmMsg,
          cancelBtnColor: option.cancelBtnColor,
          cancelMsg: option.cancelMsg,
          buttons: []
        })
        .then((result) => {
          if (result) {
            // console.log('confirm')
            option.confirmHandler()
          } else {
            // console.log('cancel')
            if (typeof option.cancelHandler === "function") {
              option.cancelHandler()
            }
          }
        });
    },
  
    saveRowEvent(row) {
      // console.log('this.$refs.xTable', this.$refs.xTable)
      const vm = this;
      const xTable = vm.$refs.xTable;
      // console.log(row)
      if (xTable.isUpdateByRow(row)) {
        vm.loading = true;
        xTable.validate(row, (errMap) => {
          if (errMap) {
            xTable.clearValidate();
            vm.confirmAbandon(
              () => {
                // console.log('confirmAbandon')
                xTable.revertData(row);
                // Object.keys(errMap).forEach(fieldId => {
                //   xTable.revertData(row, fieldId)
                // })
                // console.log('clearActived')
                xTable.clearActived().then(() => {
                  vm.$nextTick(() => {
                    // console.log("clearValidate");
                    xTable.clearValidate();
                    xTable.clostTooltip();
                  });
                });
                vm.loading = false;
              },
              () => {
                vm.$nextTick(() => {
                  // console.log('clearValidate')
                  xTable.clearValidate();
                  vm.setActiveRow(row);
                  // console.log('cancelAbandon')
                  vm.loading = false;
                });
                // alert('cancelAbandon')
              }
            );
            return;
          } else {
            row["appId"] = vm.currentApp._id;
            const postData = {
              data: row,
            };
            // console.log("TableView => save_data: postData: ", postData);
            vm.$store.dispatch("SAVE_DATA", postData).then((response) => {
              vm.loading = false;
              // console.log("TableView :: SAVE_DATA: response: ", response);
              Object.keys(row).forEach((fieldId) => {
                this.$set(row, fieldId, response[fieldId]);
              });
              if (vm.haveParentChildFields) {
                vm.refresh()
              } else {
                xTable.reloadRow(row);
                //xTable.reloadRow(row, response);
                xTable.clearActived().then(() => {
                  vm.$XModal.message({
                    message: vm.$t("messages.savedSuccessfully"),
                    status: "success",
                  });
                });
                vm.fetchRelationDetails();
              }
              vm.calcSummaryData()
            }).catch(error=>{
              vm.$toast.error(this.$t("errors.save"))
              vm.loading = false;
            }).finally(()=>{
              this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: "plus_sheet_mani",
                data: {
                  module_name: this.form.label,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.UPDATE_RECORD_INLINE
                }
              });
            });
          }

        });
      } else {
        xTable.clearActived();
      }
    },

    onCalcMethodsSelected (payload, callback) {
      const vm = this
      const summaryConfigs = JSON.parse(JSON.stringify(vm.summaryConfigs))
      const index = summaryConfigs.findIndex(config => config.fieldId === payload.fieldId)
      var hasChange = true
      if (index >= 0) {
        // console.log('onCalcMethodsSelected: summaryConfigs[index].methods: ', summaryConfigs[index].methods)
        // console.log('onCalcMethodsSelected: payload.calcMethods: ', payload.calcMethods)
        const newItems = payload.calcMethods.filter(method=>!summaryConfigs[index].methods.includes(method))
        const oldItems = summaryConfigs[index].methods.filter(method=>!payload.calcMethods.includes(method))
        if (newItems.length === 0 && oldItems.length === 0) {
          hasChange = false
        }
        summaryConfigs[index].methods = payload.calcMethods
      } else {
        summaryConfigs.push({
          fieldId: payload.fieldId,
          methods: payload.calcMethods
        })
      }
      if (hasChange) {
        EventBus.$emit('showSpinner')
        vm.$store.dispatch('UPDATE_FORM_VIEW', {
          id: vm.form._id,
          viewId: vm.view._id,
          data: {summaryConfigs}        
        }).then(
          () => {
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: "plus_sheet_mani",
                data: {
                  module_name: this.form.label,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.UPDATE_CALC_METHOD
                }
            });
            EventBus.$emit('hideSpinner')
            if (typeof callback === 'function') {
              callback()
            }
          }
        ).catch(err=>{
          this.$toast.error(err)
          EventBus.$emit('hideSpinner')
        })
      }
    },

    getRecord (recordId) {
      const vm = this
      return new Promise ((resolve, reject) => {
        vm.$store.dispatch('FETCH_RECORD', {
          appId: vm.appId,
          formId: vm.$route.params.itemId,
          viewId: vm.$route.params.viewId,
          recordId: recordId
        }).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    gotoRelatedRecord (recordId, fieldId, relatedRecordId) {
      const vm = this
      const relatedRecordFieldInfo = vm.getFieldInfo(fieldId)

      vm.gotoRelatedRecordById(recordId, relatedRecordFieldInfo, relatedRecordId)
    },

    gotoRelatedRecordFromOtherTableField (recordId, fieldId) {
      const vm = this
      const fieldInfo = vm.getFieldInfo(fieldId)
      const relatedRecordFieldInfo = getRelatedRecordFieldInfo(vm.fieldInfos, fieldInfo)

      vm.gotoRelatedRecordById(recordId, relatedRecordFieldInfo)
    },

    gotoRelatedRecordById (recordId, relatedRecordFieldInfo, relatedRecordId) {
      const vm = this
      // console.log('gotoRelatedRecordById recordId = ' + recordId)
      // console.log('gotoRelatedRecordById relatedRecordFieldInfo: ', relatedRecordFieldInfo)
      
      const relatedFormId = relatedRecordFieldInfo.properties.dataSource  
      vm.getRecord(recordId).then(        
        response => {
          const relatedFormInfo = response.relatedTableInfos[relatedFormId]          
          const recordIds = response.result[relatedRecordFieldInfo.fieldId]
          if (typeof relatedRecordId === 'undefined') {
            relatedRecordId = recordIds[0]
          }
          vm.$emit('onCommand', {
            command: 'gotoRelatedRecord',
            params: {
              itemId: relatedFormId,
              viewId: relatedFormInfo.views[0]._id,
              recordId: relatedRecordId
            }
          })
        }
      )
    },

    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case 'showChildTableDrawer':
          vm.$emit('onCommand', {
            ...payload,
            rowHeightClass: vm.rowHeightClass})
          break
        case 'selectRelatedCards':
          vm.selectRelatedCards(payload)
          break
        case 'selectRelatedRecords':
          vm.selectRelatedRecords(payload)
          break
        case 'gotoRelatedRecord':
          vm.gotoRelatedRecord(payload.recordId, payload.fieldId, payload.relatedRecordId)
          break
        case 'gotoRelatedRecordFromOtherTableField':
          vm.gotoRelatedRecordFromOtherTableField(payload.recordId, payload.fieldId)
          break
        case 'selectCalcMethodx': 
        // const fieldId = payload.data.column.property
          // console.log('selectCalcMethod: payload: ', payload)          
          const target = payload.data.target
          const targetId =  'a' + new Date().getTime().toString()
           target.setAttribute('id', targetId)
          vm.attach = '#' + targetId
          vm.showingMenu = true

          // console.log('vm.attach = ' + vm.attach)
          // console.log('vm.showingMenu: ', vm.showingMenu)
          break
        case 'selectCalcMethod': {
          const fieldId = payload.data.column.property
          const target = payload.data.target
          const targetId = 'a' + new Date().getTime().toString()
          target.setAttribute('id', targetId)
          // console.log('TableView :: selectCalcMethod : target.id = ' + target.getAttribute('id'))

          const obj = document.getElementById(targetId)
          // console.log('nextTick: obj: ', obj)

          var summaryConfig = []
          if (vm.summaryConfigs) {
            // console.log('onCommandHandler :: vm.summaryConfigs: ', vm.summaryConfigs)
            summaryConfig = vm.summaryConfigs.find(config => config.fieldId === fieldId)
          }
          if (!vm.$refs.selectCalcMethodMenu.showingMenu) {
            // vm.makeFooterOverflowVisible()
            var attachEle = document.getElementById(targetId)
            var xPos = attachEle.getBoundingClientRect().left
            var yPos = attachEle.getBoundingClientRect().top
            vm.$refs.selectCalcMethodMenu.open(
              {
                fieldId: fieldId,
                attach: targetId,
                fieldInfo: vm.getFieldInfo(fieldId),
                methods: summaryConfig ? summaryConfig.methods : [],
                xPos,
                yPos
              }, 
              vm.onCalcMethodsSelected,
              vm.onCalcMethodMenuClosed              
            )
          }
          // vm.$refs.selectCalcMethodDialog.open({
          //   fieldId: fieldId,
          //   attach: '#' + targetId,
          //   fieldInfo: vm.getFieldInfo(fieldId),
          //   methods: summaryConfig ? summaryConfig.methods : []
          // }, vm.onCalcMethodsSelected)
        }

          break
        case "updateFieldValue":
          vm.updateFieldValue(
            payload.rowId,
            payload.fieldId,
            payload.fieldValue
          );
          break;
        case "selectUploadFile": {
          const xTable = vm.$refs.xTable;
          if (payload.opts.types.length == 0) payload.opts.types = null;
          xTable.readFile(payload.opts).then((params) => {
            const { files } = params
            const fileObjs = Array.from(files);

            // console.log('TableView :: params ( ' + (typeof params) + '): ', params)
            // console.log('TableView :: isArray = ' + (Array.isArray(params) ? 'yes' : 'no'));            

            console.log('TableView :: params ( ' + (typeof fileObjs) + '): ', fileObjs)
            console.log('TableView :: isArray = ' + (Array.isArray(fileObjs) ? 'yes' : 'no'));            

            if (typeof payload.callback === "function") {
              payload.callback(fileObjs);
            }
          });
        }

          break;
        case "showEmployeesDialog":
          vm.$refs.tableEesDialog.addButton = payload.addButton
          vm.$refs.tableEesDialog.multiple = payload.multiple
          vm.$refs.tableEesDialog.selectedValue = payload.selectedValue
          vm.$refs.tableEesDialog.callback = payload.onSubmit
          vm.$refs.tableEesDialog.row = payload.row
          // console.log("payload.row", payload.row)
          this.showEesDialog = true
          // console.log(vm.$refs.tableEesDialog)
          break;
        case "showMembersMenu":
          var attachedEle = payload.attachedEle
          var fieldInfo = payload.fieldInfo
          vm.membersMenuPayload = payload
          vm.showingMembersMenu = true
          vm.$nextTick(()=>{
            vm.$refs.membersMenu.type= fieldInfo.properties.fieldType
            vm.$refs.membersMenu.selectedValue= vm.recordData[fieldInfo.fieldId]
            vm.$refs.membersMenu.fieldId= fieldInfo.fieldId
            attachedEle.id = ""
          })
        break
        case 'newRelatedRecord':
          vm.newRelatedRecord(payload)
          break
        case 'refreshRelationData':
          vm.refreshRelationData(payload)
          break
        case 'refreshData':
          this.refresh();
          break
      }
    },
    onCalcMethodMenuClosed () {
      this.makeFooterOverflowHidden()
    },
    makeFooterOverflowHidden () {
      this.footerOverflowVisible = false
    },
    makeFooterOverflowVisible() {
      this.footerOverflowVisible = true
    },
    updateFieldValue(rowId, fieldId, value) {
      const vm = this;
      for (let i = 0; i < vm.tableData.length; i++) {
        const loopRow = vm.tableData[i];
        // console.log("loopRow._id = " + loopRow._id);
        if (loopRow._id === rowId) {
          // console.log("found");
          vm.tableData[i][fieldId] = value;
          vm.updateRow(vm.tableData[i]);
          // conste fieldIndex = loopRow
          // console.log('rowId=' + rowId + ': colDef: ', colDef)
          break;
        }
      }
    },

    updateRow(row) {
      const vm = this;
      // console.log("TableView.vue :: updateRow :: row: ", row);
      vm.$store.dispatch("SAVE_DATA", row).then(() => {
        vm.fetchRelationDetails();
        // console.log("updateRow row: ", row);
      }).catch(error=>{
        vm.$toast.error(this.$t("errors.update"))
      });
    },

    editRow(row) {
      const vm = this;
      vm.$emit("onCommand", {
        command: "editRow",
        record: row,
      });
    },
    deleteRow(row) {
      const vm = this;
      if (vm.isQuickInsertMode) {
        vm.deleteInQuickInsertMode(row);
      } else {
        vm.deleteFromDb(row);
      }
    },
    deleteInQuickInsertMode(row) {
      const vm = this;
      const rowIndex = vm.$refs.xTable.tableData.findIndex(
        (data) => data._id === row._id
      );
      // console.log("deleteInQuickInsertMode :: row._id = " + row._id);
      // console.log("deleteInQuickInsertMode :: rowIndex = " + rowIndex);

      if (rowIndex >= 0) {
        vm.$refs.xTable.tableData.splice(rowIndex, 1);
      }
    },
    deleteFromDb(row) {
      const vm = this;
      vm.$refs.confirmDeleteDialog.confirm(() => {
        vm.$store
          .dispatch("DELETE_FORM_RECORD", {
            appId: vm.currentApp._id,
            formId: this.form._id,
            include: [row._id],
          })
          .then(() => {
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: "plus_sheet_mani",
                data: {
                  module_name: this.form.label,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.DELETE_RECORD
                }
            });
            vm.refresh();
          });
      });
    },
    copyRow(row, rowIndex) {
      const vm = this;
      if (vm.isQuickInsertMode) {
        vm.createRows(1, ADD_BELOW, rowIndex, () => {
          const tableData = vm.$refs.xTable.tableData;
          // console.log("form.copyRow :: .... rowIndex = " + rowIndex);
          for (let key in row) {
            if (key !== "_id") {
              tableData[rowIndex + 1][key] = row[key];
            }
          }
        });
      } else {
        const postData = {
          urlCommand: "/data/duplicateRecord",
          data: {
            recordId: row._id,
            formId: this.form._id,
            viewId: this.view._id,
          },
        };
        this.$store.dispatch("AUTH_POST", postData).then((res) => {
          if (res.copied) {
            this.$toast.success(this.$t('messages.recordCopied'));
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: "plus_sheet_mani",
                data: {
                  module_name: this.form.label,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.COPY_RECORD
                }
            });
            this.$refs.xTable.tableData.splice(rowIndex + 1, 0, res.data);

            this.$refs.xTable.tablePage.total++;
            this.$refs.xTable.setCurrentRow(res.data);
            this.$refs.xTable.setActiveRow(res.data);
            if (this.isAllRecords){
              this.$refs.xTable.setCheckboxRow(res.data, true)
            }
          } else if (res.problematicField) {
            this.$toast.error(this.$t("messages.noDupFieldExist"), {
              duration: 0,
            });
          } else {
            this.$toast.error(this.$t("messages.cannotCopy"), {
              duration: 0,
            });
          }
        });
      }

    },
    openBulkEditDialog(){
      if(this.isAllChecked || this.excludeRecords.length>0)
        if(this.pagination.totalResult-this.excludeRecords.length>1000){
          this.snackbarContent = this.$t('snackbar.exceedEditCount')
          this.snackbarColor = 'error' 
          this.showingSnackbar = true
          return
        }
      this.showBulkEditDialog = true
    },
    bulkEdit({type,data,fieldId}){
      const postData = {
        appId: this.currentApp._id,
        formId: this.form._id,
        viewId: this.view._id,
        exclude: this.excludeRecords,
        include: this.includeRecords,
        all: this.isAllChecked,
        type,
        data,
        fieldId
      };

      let quickFilters = [...this.verifyQuickFilters()];
      if (quickFilters.length > 0 ) {
        Object.assign(postData, {
          filter: quickFilters
        });
      }

      if (this.keyword) postData["keyword"] = this.keyword;

      this.$store.dispatch("AUTH_POST", {data:postData, urlCommand:"/data/bulk"}).then(()=> {
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.form.label,
              func_name: this.$sensors.events.plus_sheet_mani.table_view.BULK_EDIT
            }
        });
        this.showBulkEditDialog = false;
        this.refresh();
      });
    },
    deleteItems() {
      const vm = this;
      this.$refs.confirmDeleteDialog.confirm(() => {
        if (vm.isQuickInsertMode) {
          vm.deleteItemsFromVxeTable();
        } else {
          vm.deleteItemsFromDB();
        }
      });
    },
    deleteItemsFromVxeTable() {
      const vm = this;
      for (let i = 0; i < vm.includeRecords.length; i++) {
        const rowId = vm.includeRecords[i];
        const rowIndex = vm.$refs.xTable.tableData.findIndex(
          (data) => data._id === rowId
        );
        if (rowIndex >= 0) {
          vm.$refs.xTable.tableData.splice(rowIndex, 1);
        }
      }
      vm.includeRecords = [];
    },
    deleteItemsFromDB() {
      let data = {
        appId: this.form.appId,
        formId: this.form._id,
        viewId: this.view._id,
        exclude: this.excludeRecords,
        include: this.includeRecords,
      };
      const postData = {
        urlCommand: "/data/deleteRows",
        data,
      };

        this.$store.dispatch("AUTH_POST", postData).then(() => {
          this.refresh();
          this.clearSelection();
        }).finally(()=> {

        });
    },

    clearSelection() {
      this.includeRecords = [];
      this.excludeRecords = [...this.currentPageDataIds];
      this.isAllRecords = false;
    },

    handlePageChange() {
      // alert('handlePageChnage')
      // console.log('TableView :: handlePageChange')
      this.clearSelection();
      // this.savePageSize()
    },

    onCellClick({ row, column }) {
      const vm = this;
      // console.log('Form.vue :: onCellClick row=' + row + ', column=' + column)
      vm.selectRow = row;
      vm.selectColumn = column;
    },

    refresh() {
      const vm = this;
      console.log("TableView.refresh :: vm.isQuickInsertMode = " + vm.isQuickInsertMode);
      if (!this.isQuickInsertMode) this.$refs.xTable.commitProxy("query");
    },

    getFieldInfos(fieldIds) {
      const vm = this;
      var result = [];
      for (var i = 0; i < fieldIds.length; i++) {
        const loopFieldId = fieldIds[i];
        const fieldInfo = vm.fieldInfos.find(
          (info) => info.fieldId === loopFieldId
        );
        if (fieldInfo) {
          result.push(fieldInfo);
        }
      }
      return result;
    },

    toPaginationStr(pagination) {
      return (
        "currentPage:" +
        pagination.currentPage +
        ";" +
        "pageSize:" +
        pagination.pageSize +
        ";" +
        "totalResult:" +
        pagination.totalResult
      );
    },


    async setActiveRow(row) {
      var scrollObj = this.$refs.xTable.getScroll();
      await this.$refs.xTable.setActiveRow(row);
      this.$refs.xTable.scrollTo(scrollObj.scrollLeft, scrollObj.scrollTop);
      this.$refs.xTable.refreshScroll();
    },

    async fetchRegions() {
      const vm = this;
      var locale = this.$store.getters.user.locale;
      const getParams = {
        urlCommand: "/widgets/getRegions/" + locale,
      };
      await vm.$store
        .dispatch("AUTH_GET", getParams)
        .then((response) => {
          this.regions = response.options;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetTableState() {
      this.keyword = "";
      this.$refs.xTable.clearAll();
      Object.keys(this.$refs.xTable.sortData).forEach((key) => {
        delete this.$refs.xTable.sortData[key];
      });
    },

    setQIModeRowHeight(height) {
      this.quickInsertModeRowHeight = height;
    },
    openExportDialog(){
      this.showExportDialog=true
    },
    openImportDialog(){
      this.showImportDialog=true
    },
    handleMemberClick(val){
      var type = this.membersMenuPayload.fieldInfo.properties.fieldType
      var fieldId = this.membersMenuPayload.fieldInfo.fieldId
      var recordData = this.membersMenuPayload.row
      if(!recordData[fieldId]){
        this.$set(recordData, fieldId, [val])
      }else{
        if(type=='single'){
          recordData[fieldId] = [val]
        }else{
          var pos = recordData[fieldId].findIndex(ee=>ee._id==val._id)
          if(pos==-1){
            recordData[fieldId].push(val)
          }else{
            recordData[fieldId].splice(pos,1)
          } 
        }
      }
    },
    footerCellClassName ({ $rowIndex, column, columnIndex }) {
      if (columnIndex === 0) {
        if ($rowIndex === 0) {
          return 'grey--text'
        }
      }
    },
    openSharebleSettings(recordId){
      this.sharableRecordId = recordId
      this.showSharableRecord = true
    }
  },
};
</script>

<style lang="scss">

.popModal.col--actived {
  z-index: 4 !important;
}

.tableMenuItemIcon{
  top: 0px !important
}


.vxe-body--column .quick-action,
.vxe-body--column:hover .quick-action {
  display: none !important;
}

.quick-insert-active
  .vxe-body--column
  .v-dialog
  .vxe-body--column
  .quick-action,
.quick-insert-active
  .vxe-body--column
  .v-dialog
  .vxe-body--column:hover
  .quick-action {
  display: none !important;
}
.quick-insert-active .vxe-body--column:hover .quick-action {
  display: block !important;
}
.quick-insert-active .vxe-pager .vxe-pager--prev-btn,
.quick-insert-active .vxe-pager .vxe-pager--btn-wrapper,
.quick-insert-active .vxe-pager .vxe-pager--next-btn,
.quick-insert-active .vxe-pager .vxe-pager--jump,
.quick-insert-active .vxe-pager .vxe-pager--sizes,
.quick-insert-active .vxe-pager .vxe-pager--total {
  display: none;
}


.tableViewGrid .vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell{
  max-height: unset !important;
}

.tableViewGrid{
  display: flex;
  flex-direction: column;
}
.tableViewGrid .vxe-table.vxe-table--render-default{
  flex-grow: 1;
}

.tableViewGrid .vxe-table--main-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tableViewGrid .vxe-table--footer-wrapper.body--wrapper{
  flex-grow: 0;
}

.tableViewGrid .vxe-table--fixed-left-wrapper{
  display: flex ;
  flex-direction: column;
}

.tableViewGrid .vxe-table--body-wrapper{
  overflow-y: auto;
  flex-grow: 1;
  height:0px;
}

.tableViewGrid .vxe-table--body-wrapper.fixed-left--wrapper{
  height: calc(100% - 64px) !important
}

.tableViewGrid .vxe-table--render-default .vxe-header--column.col--ellipsis{
  height:23px
}
.tableViewGrid .vxe-table--render-default .vxe-footer--column.col--ellipsis{
  height:23px
}
.tableViewGrid .vxe-table--footer-wrapper .fixed-left--wrapper{
  top: 720px !important
}

.table-view {
  &.footer-overflow-visible .fixed-left--wrapper {
    overflow: visible !important;
  }
  .vxe-table--footer-wrapper {
    overflow: hidden;
  }
  table.vxe-table--footer .vxe-footer--row {
    .vxe-footer--column {
      border: 1px solid #E5E6EB;
    }
    td.vxe-footer--column {
      padding-top:5px !important;
      padding-bottom: 5px !important;
    }

  }
  .vxe-table--body-wrapper.fixed-left--wrapper {
    overflow-x: hidden !important;
  }
  .select-button {
    font-size:12px
  }
}
</style>
