<template>
  <div class="pa-0 overflow-hidden">
    <v-app-bar app clipped-right>
      {{
        $t("printTemplate.instruction_title", [
          form && "label" in form ? form.label : "" || "",
        ])
      }}
    </v-app-bar>
    <v-main class="overflow-y-auto" style="height: 100vh">
      <v-container style="max-width: 880px" v-if="loading">
        <loading-icon />
      </v-container>
      <v-container style="max-width: 880px" v-else>
        <v-row no-gutters class="my-2">
          <h2>{{ $t("printTemplate.instruction") }}</h2>
        </v-row>
        <v-row v-for="i in 5" :key="i" no-gutters class="body-1 grey--text">
          <p>
            {{ i }}.<i18n :path="`printTemplate.instruction_${i}`">
              <p>&emsp;{{ $t("printTemplate.instruction_4_1") }}</p>
              <p>&emsp;{{ $t("printTemplate.instruction_4_2") }}</p>
              <p>&emsp;{{ $t("printTemplate.instruction_4_3") }}</p>
            </i18n>
          </p>
        </v-row>
        <v-row no-gutters class="my-2">
          <h2>{{ $t("printTemplate.mapping") }}</h2>
          <v-spacer />
          <v-btn
            text
            color="primary"
            class="text-decoration-underline"
            @click="getDocxSample"
            >{{ $t("printTemplate.downloadDocxDemo") }}</v-btn
          >
        </v-row>

        <v-row no-gutters class="field-table-header">
          <v-col cols="4" class="text-left">
            {{ $t("general.field") }}
          </v-col>
          <v-col cols="8" class="text-left">
            {{ $t("general.key") }}
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="field-table-title" no-gutters>{{
          $t("printTemplate.systemField")
        }}</v-row>
        <v-row
          class="field-table-body"
          no-gutters
          v-for="(item, index) in systemFields"
          :key="index"
        >
          <v-col cols="3">{{ item.label }}</v-col>
          <v-col cols="3" class="click-to-copy">
            <v-hover v-slot="{ hover }">
              <span @click="copyToClipboard(item.mergeLabel)">
                <span>{{ item.mergeLabel }}</span>
                <v-icon v-if="hover" dense x-small>mdi-content-copy</v-icon>
              </span>
            </v-hover>
          </v-col>
          <v-col cols="6" class="click-to-copy">
            <v-hover v-slot="{ hover }">
              <span @click="copyToClipboard(item.mergeTag)">
                <span>{{ item.mergeTag }}</span>
                <v-icon v-if="hover" dense x-small>mdi-content-copy</v-icon>
              </span>
            </v-hover>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="field-table-title" no-gutters>{{
          $t("printTemplate.formField")
        }}</v-row>
        <v-row
          class="field-table-body"
          no-gutters
          v-for="(info, index) in formGeneralFields"
          :key="index"
        >
          <v-col cols="3">{{ info.label }}</v-col>
          <v-col cols="3" class="click-to-copy">
            <v-hover v-slot="{ hover }">
              <span @click="copyToClipboard(info.mergeLabel)">
                <span>{{ info.mergeLabel }}</span>
                <v-icon v-if="hover" dense x-small>mdi-content-copy</v-icon>
              </span>
            </v-hover>
          </v-col>
          <v-col cols="6" class="click-to-copy">
            <v-hover v-slot="{ hover }">
              <span @click="copyToClipboard(info.mergeTag)">
                <span>{{ info.mergeTag }}</span>
                <v-icon v-if="hover" dense x-small>mdi-content-copy</v-icon>
              </span>
            </v-hover>
          </v-col>
        </v-row>
        <v-divider />
        <div v-if="multiRelationFields.length > 0">
          <v-row class="field-table-title" no-gutters>{{
            $t("printTemplate.relationField")
          }}</v-row>
          <v-row class="info-box my-2" no-gutters align="center"
            >#PanelPrint#
            <span class="ml-5 grey--text">{{
              $t("printTemplate.panelPrint")
            }}</span></v-row
          >
          <div v-for="(form, formKey) in multiRelationFields" :key="form._id">
            <v-row no-gutters class="field-table-subtitle">
              {{ form.label }}
            </v-row>
            <v-row
              no-gutters
              v-for="(info, index) in form.mergeTags"
              :key="index"
              class="field-table-body"
            >
              <v-col cols="3">{{ info.label }}</v-col>
              <v-col cols="3">
                <v-hover v-slot="{ hover }">
                  <span
                    @click="
                      copyToClipboard(
                        multiRelationFields[formKey].mergeLabels[index]
                          .mergeLabel
                      )
                    "
                  >
                    <span>{{
                      multiRelationFields[formKey].mergeLabels[index].mergeLabel
                    }}</span>
                    <v-icon v-if="hover" dense x-small>mdi-content-copy</v-icon>
                  </span>
                </v-hover>
              </v-col>
              <v-col cols="6">
                <v-hover v-slot="{ hover }">
                  <span @click="copyToClipboard(info.mergeTag)">
                    <span>{{ info.mergeTag }}</span>
                    <v-icon v-if="hover" dense x-small>mdi-content-copy</v-icon>
                  </span>
                </v-hover>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { systemFieldIds } from "@/const/fieldConstants";
import LoadingIcon from "@/components/global/loadingIcon";
import MediaHelper from "@/helpers/MediaHelper";
export default {
  components: {
    LoadingIcon,
  },
  data() {
    return {
      loading: false,
      form: null,
      relatedForms: [],
      systemFields: [],
      formGeneralFields: [],
      multiRelationFields: [],
    };
  },
  computed: {},
  methods: {
    getMergeTag(fieldId, parentFieldId, type) {
      const mediaString = ["signature", "attachments"].includes(type)
        ? "[40_auto]"
        : "";
      return parentFieldId
        ? `$\{${parentFieldId}.${fieldId}${mediaString}}`
        : `$\{${fieldId}${mediaString}}`;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.info(this.$t("messages.copied"), {
          duration: 3000,
        });
      });
    },
    prepareData() {
      this.form.fieldInfos.forEach((info) => {
        if (systemFieldIds.includes(info.fieldId)) {
          this.systemFields.push({
            label: info.label,
            mergeLabel: this.getMergeTag(info.label),
            mergeTag: this.getMergeTag(info.fieldId),
          });
        } else if (
          info.type === "childTable" ||
          (info.type === "relatedRecord" &&
            info.properties.relatedRecordQuantity === "multiple")
        ) {
          const formId =
            (info.properties &&
              ((info.type === "relatedRecord" && info.properties.dataSource) ||
                info.properties.fieldsEditor.formId)) ||
            "";
          const form =
            Object.keys(this.relatedForms).length > 0 &&
            formId in this.relatedForms
              ? this.relatedForms[formId]
              : null;
          if (form) {
            this.multiRelationFields.push({
              label: form.label,
              mergeLabels: form.fieldInfos.map((childInfo) => ({
                label: childInfo.label,
                mergeLabel: this.getMergeTag(
                  childInfo.label,
                  info.label,
                  childInfo.type
                ),
              })),
              mergeTags: form.fieldInfos.map((childInfo) => ({
                label: childInfo.label,
                mergeTag: this.getMergeTag(
                  childInfo.fieldId,
                  info.fieldId,
                  childInfo.type
                ),
              })),
            });
          }
        } else {
          this.formGeneralFields.push({
            label: info.label,
            mergeLabel: this.getMergeTag(info.label, null, info.type),
            mergeTag: this.getMergeTag(info.fieldId, null, info.type),
          });
        }
      });
      this.systemFields.push({
        label:this.$t("printTemplate.printingTime"),
        mergeLabel: "${printTime}",
        mergeTag: "${printTime}"
      })
    },
    getDocxSample() {
      const getParams = {
        urlCommand: `/printTemplate/getDocx?formId=${this.form._id}`,
         options: {
           responseType: "blob",
         },
      };

      this.$store.dispatch("AUTH_GET", getParams).then((res) => {
        MediaHelper.downloadFile(document,[res], "sample.docx")

      });
    },
  },
  mounted() {
    this.loading = true;
    const urlCommand = `/forms/${this.$route.params.formId}?appId=${this.$route.params.appId}`;

    this.$store.dispatch("AUTH_GET", { urlCommand }).then((res) => {
      this.form = res.result;
      this.relatedForms = res.relatedTableInfos;
      if (this.form) this.prepareData();
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 18px;
  color: #333;
  font-size: 15px !important;
  font-weight: 700;
}
</style>
<style lang="scss">
.field-table-header > .col {
  font-size: 0.75rem;
  height: 48px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 0px 16px !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.field-table-body > .col {
  padding: 0px 16px !important;
  height: 48px;
  line-height: 1.5;
  font-size: 13px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  span {
    width: fit-content;
    height: 24px;
  }
}

.field-table-title {
  padding: 0px 16px !important;
  margin-top: 18px !important;
  color: #333;
  font-size: 15px;
  font-weight: 700;
}

.field-table-subtitle {
  padding: 8px 16px !important;
  line-height: 1.5;
  font-size: 13px;
  font-weight: bold;
}

.info-box {
  background: #f5f5f5 0 0 no-repeat padding-box;
  border-radius: 3px;
  padding: 15px;
  font-size: 13px !important;
  line-height: 23px;
}

.click-to-copy {
  cursor: pointer;
}
</style>
