<template>
    <v-dialog v-model="showingDialog" :width="configs.width" scrollable>
        <v-card>
            <dialog-header
                :label="title"
                @close="showingDialog = false"
                :color="configs.headerColor"
                :toolbarClass="configs.headerClass"
            />
            <v-card-text class="px-4 py-6" :style="configs.bodyStyle">
                <slot name="body">
                    <div v-html="body" class=""/>
                </slot>
            </v-card-text>
            <dialog-actions 
            v-if="!noAction"
                :buttons="buttons"
                @onCommand="commandHandler">
            </dialog-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
    components:{
        dialogActions,
        dialogHeader
    },
    props: {
        value:Boolean,
        dialogTitle:{type:String, default:''},
        confirmAction:{type:Function},
        noAction: {
            type:Boolean,
            default:false
        },
        dialogConfigs: {
            type:Object,
            default:()=>({
                width: '450px',
                headerBackgroundColor: '',
                headerClass: 'primary',
                bodyClass: '',
                bodyStyle: 'font-size: 14px'
            })
        }
    },
    data(){
        return{
            title: this.dialogTitle,
            body: '',
            buttons: [
                'cancel',
                'ok'
            ],
            configs:this.dialogConfigs,
            showingDialog: false
        }
    },
    // computed: {
    //     showingDialog: {
    //         get(){
    //             return this.value
    //         },
    //         set(val){
    //             this.$emit('input', val)
    //         }
    //     }
    // },
    methods:{
        open(title, body, buttons, configs={}){
            let vm = this
            this.showingDialog = true
            this.title = title
            this.body = body
            if (buttons.length > 0) this.buttons = buttons
            Object.keys(configs).forEach(key=>{
                if(vm.configs.hasOwnProperty(key))
                    vm.configs[key] = configs[key]
            })
        },
        commandHandler(payload){
            switch(payload.command){
                case 'ok':
                    this.confirmAction()
                    break;
                default: this.showingDialog = false
            }
        }
    }
    
}
</script>