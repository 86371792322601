<template>
  <div>
    <div
      class="w-editor-header-release-mode"
      v-html="$t('workflow.label.releasedAt', { date: releasedAt })"
      v-if="releasedAt"
    />
    <div>{{ $t('workflow.message.canStopRelease') }}</div>
  </div>
</template>

<script>
import { get } from 'lodash';
import Mixin from './../mixin';
import SettingMixin from './../components/tree/setting/mixin';
export default {
  mixins: [Mixin, SettingMixin],
  props: {
    popup: Boolean,
  },
  computed: {
    releasedAt() {
      const { workflow={}, $locale: { m } } = this;
      const releasedAt = get(workflow, 'releasedAt');
      if (releasedAt) {
        const mDate = m(releasedAt);
        return this.popup ? mDate.calendar() : `${mDate.calendar()} (${mDate.fromNow()})`;
      }
      return '';
    },
  },
};

</script>

<style scoped>
  
</style>
