<template>
  <div class="grey lighten-2">
    <v-toolbar>
      <v-btn icon class="hidden-xs-only">
        <v-icon @click="backToApp">mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ $t("roles.permission") }}</v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom max-width="320">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-switch
              v-if="isAdmin"
              @change="toggleVisibility"
              v-model="isVisible"
              dense
              hide-details
              :ripple="false"
              :label="$t('roles.hideFromUser')"
            />
          </div>
        </template>
        <span>{{$t('roles.hideFromUserDescription')}}</span>
      </v-tooltip>
    </v-toolbar>
    <LoadingIcon v-if="loading" />
    <v-container
      v-else
      class="align-start align-content-start justify-center container-height "
      style="width:100%"
      fluid
    >
      <perfect-scrollbar style="height:100%;">
        <div
          class="d-flex align-start align-content-start justify-center text-center"
        >
          <div>
            <v-expansion-panels max="1" tile class="white" style="width:1080px">
              <RolePanel
                v-for="role in roles"
                :key="role._id"
                :role="role"
                :canMove="roles.length > 1"
                @commandHandler="onCommandHandler"
              />
            </v-expansion-panels>
            <v-btn
              v-if="isAdmin"
              class="primary mt-2"
              rounded
              @click="onAddRoleClicked"
              >{{ $t("roles.addRole") }}
            </v-btn>
          </div>
        </div>
      </perfect-scrollbar>
    </v-container>

    <confirm-dialog ref="ConfirmDialog" />
    <RoleSettingDialog
      isNew
      v-if="showSettingDialog"
      v-model="showSettingDialog"
      @create="createRole"
    />
    <RoleMoveMembersDialog ref="RoleMoveMembersDialog" :roles="roles" />
  </div>
</template>
<script>
import RolePanel from "./comps/RolePanel";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import RoleSettingDialog from "./dialogs/RoleSettingDialog";
import RoleMoveMembersDialog from "./dialogs/RoleMoveMembersDialog";
import LoadingIcon from "@/components/global/loadingIcon";

export default {
  components: {
    RolePanel,
    ConfirmDialog,
    RoleSettingDialog,
    RoleMoveMembersDialog,
    LoadingIcon,
  },
  async mounted() {
    this.loading = true;
    if (!this.$store.getters.currentApp || this.$store.getters.currentApp._id !== this.$route.params.id) {
        await this.$store
          .dispatch("FETCH_APP", { appId: this.$route.params.id })
    }
    await this.loadRoles();
    this.loading = false
  },
  data() {
    return {
      roles: [],

      selectedRoleId: "",

      showSettingDialog: false,
      loading: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isVisible() {
      return this.$store.getters.currentApp
        ? this.$store.getters.currentApp.options.roleVisibleToUser
        : false;
    },
    currentEmployeeId() {
      return this.$store.getters.activeEmployeeId;
    },
    appId(){
      return this.$route.params.id;
    }
  },
  methods: {
    onAddRoleClicked(){
      this.showSettingDialog = true;
      this.$sensors.track("plus_click_authorization", {
        app_id: this.appId,
        action: this.$sensors.events.plus_click_authorization.ADD_ROLE
      });
    },
    backToApp() {
      this.$router.go(-1);
    },
    async loadRoles() {
      const appId = this.$route.params.id;

      const getParams = {
        urlCommand: `/role/getRoles?id=${appId}`,
      };
      const res = await this.$store.dispatch("AUTH_GET", getParams)
      this.roles = res;
    },
    toggleVisibility(val) {
      const postData = {
        urlCommand: "/role/setRoleVisibility",
        data: {
          appId: this.$route.params.id,
          value: val,
        },
      };

      this.$store.dispatch("AUTH_POST", postData);
    },

    onCommandHandler(item) {
      switch (item.command) {
        case "addMember":
          this.addMember(item.params);
          break;
        case "removeMember":
          this.removeMember(item.params, item.callback);
          break;
        case "transferOwner":
          this.transferOwner(item.params);
          break;
        case "moveMember":
          this.moveMember(item.params);
          break;
        case "updateRole":
          this.updateRole(item.params);
          break;
        case "deleteRole":
          this.deleteRole(item.params);
          break;
        case "copyRole":
          this.copyRole(item.params);
          break;
        case "leaveRole":
          this.leaveRole(item.params);
      }
    },
    leaveRole(params) {
      this.$refs.ConfirmDialog.open(
        this.$t("roles.leaveRole"),
        this.$t("roles.confirmMsg"),
        {
          color: "error",
          confirmBtnColor: "error",
        }
      ).then((confirm) => {
        if (confirm) {
          const postData = {
            urlCommand: "/role/leaveRole",
            data: {
              appId: this.$route.params.id,
              roleId: params.roleId,
            },
          };
          this.$store.dispatch("AUTH_POST", postData).then(() => {
            // notes:
            // after leaving role, no permission exists for the Apps, return to My Apps page
            this.$router.replace({name: 'myApps'});
          });
        }
      });
    },
    removeUserFromNonAdminRole(userId) {
      // console.log('removeUserFromNonAdminRole: userId = ' + userId);
      // console.log('removeUserFromNonAdminRole: this.roles: ', this.roles);

      const index = this.roles.findIndex(role => {
        const userIds = role.users.map(user => user._id);
        // console.log('loop: userIds: ', userIds);
        return role.permission!==100 && userIds.includes(userId);
      });
      if (index >= 0) {
        this.roles[index].users = this.roles[index].users.filter(user => {
          return user._id !== userId;
        })
      }
    },
    transferOwner(params) {
      const postData = {
        urlCommand: "/role/transferOwner",
        data: {
          appId: this.$route.params.id,
          newOwner: params.newOwner._id,
        },
      };
      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        if (res.result === true) {
          const index = params.role.users.findIndex(
            (user) => user._id === params.newOwner._id
          );
          if (index === -1) {
            params.role.users.push(params.newOwner);
          }
          // remove the newOwner from non-admin role if exists
          this.removeUserFromNonAdminRole(params.newOwner._id);
          this.$store.dispatch("SET_APP_PROPERTY", {
            id: this.$route.params.id,
            propertyKey: "ownerId",
            propertyValue: params.newOwner._id,
          });
          this.$router.replace({name: 'myApps'})
        }
      });
    },
    copyRole(params) {
      const postData = {
        urlCommand: "/role/copyRole",
        data: {
          roleId: params.roleId,
        },
      };
      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        this.roles.push(res);
      });
    },
    createRole(role) {
      const postData = {
        urlCommand: "/role/saveRole",
        data: {
          role,
        },
      };
      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        this.roles.push(res);
        this.$sensors.track("plus_add_authorization", {
          app_id: this.appId,
          action: this.$sensors.events.plus_click_authorization.ADD_ROLE
        });
      });
    },
    deleteRole(params) {
      const roleIndex = this.roles.findIndex(
        (item) => item._id === params.roleId
      );
      if (roleIndex === -1) {
        return;
      }

      const role = this.roles[roleIndex];
      const postData = {
        urlCommand: "/role/deleteRole",
        data: {
          ...params,
        },
      };

      if (role.users.length > 0 || role.departments.length > 0) {
        this.$refs.RoleMoveMembersDialog.open(params.roleId)
          .then((targetId) => {
            Object.assign(postData.data, {
              targetId,
            });
            this.$store.dispatch("AUTH_POST", postData).then(() => {
              this.loading = true;
              this.loadRoles()
                .then(() => (this.loading = false));
            });
          })
          .catch(() => {
            return;
          });
      } else {
        this.$refs.ConfirmDialog.open(
          this.$t("roles.delete"),
          this.$t("roles.confirmMsg"),
          {
            color: "error",
            confirmBtnColor: "error",
          }
        ).then((confirm) => {
          if (confirm) {
            this.$store.dispatch("AUTH_POST", postData).then(() => {
              this.loading = true;
              this.loadRoles()
                .then(() => (this.loading = false));
            });
          }
        });
      }
    },
    updateRole(params) {
      const postData = {
        urlCommand: "/role/saveRole",
        data: {
          role: params.data,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        const role = this.roles.find((role) => role._id === res._id);
        if (role) {
          Object.assign(role, {
            permission: res.permission,
            description: res.description,
            label: res.label,
          });
        }
      });
    },
    addMember(params) {
      const postDept = params.departments
        ? params.departments.map((dept) => dept._id)
        : [];
      const postUser = params.users ? params.users.map((user) => user._id) : [];

      const postData = {
        urlCommand: "/role/addMember",
        data: {
          roleId: params.role._id,
          users: postUser,
          departments: postDept,
        },
      };
      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        if (res.result === true) {
          if (params.users && params.users.length > 0) {
            params.role.users.push(...params.users);
          } else if (params.departments && params.departments.length > 0) {
            params.departments.forEach((dept) => {
              const existingDept = params.role.departments.find(
                (item) => item._id === dept._id
              );
              if (!existingDept) {
                params.role.departments.push(dept);
              }
            });
          }
          this.$sensors.track("plus_add_authorization", {
              app_id: this.appId,
              action: this.$sensors.events.plus_click_authorization[params.users? "ADD_USER":"ADD_DEPT"]
          });
        }
      });
    },

    removeMember(params, callback) {
      this.$refs.ConfirmDialog.open(
        this.$t("roles.removeMember"),
        this.$t("roles.confirmMsg"),
        {
          color: "error",
          confirmBtnColor: "error",
        }
      ).then((confirm) => {
        if (confirm) {
          const postData = {
            urlCommand: "/role/removeMember",
            data: {
              roleId: params.role._id,
              users: params.users,
              departments: params.departments,
            },
          };

          this.$store.dispatch("AUTH_POST", postData).then((res) => {
            if (res.result === true) {
              params.role.users = params.role.users.filter(
                (user) => !params.users.includes(user._id)
              );
              params.role.departments = params.role.departments.filter(
                (dept) => !params.departments.includes(dept._id)
              );

              console.log('removeMember: params.users: ', params.users);
              console.log('removeMember: this.currentEmployeeId = ' + this.currentEmployeeId);

              if (params.users.includes(this.currentEmployeeId)) {
                this.$router.replace({name: 'myApps'});
              }
              callback();
            }
          });
        }
      });
    },
    moveMember(params) {
      this.$refs.RoleMoveMembersDialog.open(params.roleId, {
        color: "primary",
      }).then((targetId) => {
        const postData = {
          urlCommand: "/role/moveToRole",
          data: {
            ...params,
            targetId,
          },
        };

        this.$store.dispatch("AUTH_POST", postData).then(() => {
          this.roles = [];
          this.loadRoles();
        });
      });
    },
  },
};
</script>
<style scoped>
.container-height {
  height: calc(100vh - 144px);
}
</style>
