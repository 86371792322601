import PhoneNumber from 'awesome-phonenumber'

const validid = require('validid')
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.ffffff[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const TIME_REGEX = /^([0][0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const DATE_REGEX = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;


/**
 * Check if value is empty
 * @param {*} val 
 * @returns True if null / undefined / empty trimmed string/ empty array / empty object
 */
function isEmpty(val){
  return !val || (typeof val === 'string' && !val.trim()) || val.length === 0 || (typeof val === 'object' && Object.keys(val).length === 0);
}
/**
 * Check email format according to RFC5321
 * @param {String} val string
 * @returns True if not empty and correct email format.
 */
function isValidEmail(val){
        let result = EMAIL_REGEX.test(val);
        if (!result) return false;
        else if (val.split('@')[0].length > 64 || val.length > 254 ) return false
        return true
}

/**
 * Check if value is a valid Date Object
 * @param {*} val 
 */
function isValidDateTime(val){
    return new Date(val).valueOf() ? true: false
}

/**
 * Check if string is in format of hh:mm
 * @param {String} val 
 * @returns True if not empty and correct time format.
 */
function isValidTimeString(val){
    return TIME_REGEX.test(val);
}

/**
 * Check if string is in format of YYYY-MM-DD hh:mm
 * @param {String} val 
 * @returns True if not empty and correct datetime format.
 */
function isValidDateTimeString(val){
    if (!val) return false
    // dependency options
    if (typeof val !== 'string') return true;
    const dateTimeArr= val.split(" ");
    if (dateTimeArr.length !== 2) return false;
    else return (isValidDate(dateTimeArr[0]) && TIME_REGEX.test(dateTimeArr[1]))
}

/**
 * Check if date string is in format of YYYY-MM-DD and date is of legal date
 * @param {*} val 
 * @returns  True if correct date format and legal date
 */
function isValidDate(val){
  if (!DATE_REGEX.test(val)) {
    return false
  }

  const [year, month, day] = val.split('-');
  const dateObj = new Date(val);

  if (dateObj.getFullYear() != year || dateObj.getMonth() + 1 != month || dateObj.getDate() != day){
    return false
  }
 
  return true
}

/**
 * Check if time string is in allowed range. Simple string comparison. Does not check empty or format.
 * @param {String} val time to be tested in 24-hour format
 * @param {String} min minTime in 24-hour format
 * @param {String} max maxTime in 24-hour format
 * @returns True if within range or no range is provided
 * @todo check validity of params
 */
function isInTimeRange(val, min, max){
    if (min && max){
        return (val >= min && val <= max);
      } else if (min){
        return (val >= min);
      } else if (max){
        return (val <= max);
      } else {
        return true
      }   
}

/**
 * Check if date is allowed day of week
 * @param {String} val date to be tested
 * @param {[String]} allowedDays allowed days of week. 0 is Sunday.
 * @returns True if date is allowed day of week
 */
function isAllowedDay(val, allowedDays){
    let date = new Date(val).getDay().toString();
    return allowedDays.includes(date);
}

/**
 * Check if number is within range
 * @param {String} val number (can be integer or float)
 * @param {String} min min value
 * @param {String} max max value
 * @returns True if within range or no range is provided
 */
function isInNumberRange(val, min, max){
    if (min && max) {
        return (
          (parseFloat(val) >= parseFloat(min) &&
            parseFloat(val) <= parseFloat(max))
        );
      } else if (min) {
        return (
          parseFloat(val) >= parseFloat(min) 
        );
      } else if (max) {
        return (
          parseFloat(val) <= parseFloat(max)
        );
      }
      else return true    
}

/**
 * Check if is valid phone number
 * @param {String} val phone string
 */
function isValidPhone(val) {
  try {
    return new PhoneNumber(val).isValid();
  } catch (err) {
    return false;
  }
}

function isValidIdCard(val, idCardType=""){
  var cardType = idCardType.split('||')
  return cardType.some(type=>{
    switch(type){
      case 'hkid':
        if (val.trim() && validid.default.hkid(val)) return true
        break
      case 'cnid':
        if (val.trim() && validid.default.cnid(val)) return true
        break
      case 'twid':
        if (val.trim() && validid.default.twid(val)) return true
        break
      case 'passport':
        if (val.trim() && val.trim().length<=20 ) return true
        break
    }
  })
  // return validid.default.hkid(val) 
}

function isProvinceEmpty(val){
  return typeof val === 'object' && val.province === null
}

function isStateEmpty(val){
  return typeof val === 'object' && val.state === null
}

function isCityEmpty(val){
  return typeof val === 'object' && val.city === null
}

export default {
    isEmpty,
    isValidEmail,
    isValidDateTime,
    isValidTimeString,
    isValidDate,
    isValidDateTimeString,
    isInTimeRange,
    isAllowedDay,
    isInNumberRange,
    isValidPhone,
    isValidIdCard,
    isProvinceEmpty,
    isCityEmpty,
    isStateEmpty
}


