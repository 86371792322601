<template>
  <message-base :item="item" :index="index" :options="options">
    <template v-slot:icon></template>
    <template v-slot:content-body>
      <span v-html="item.info.content"></span>
    </template>
    <template v-slot:content-suffix><div></div></template>
    <template v-slot:content-createTime="{ createTime }">{{
      calculateRelativeTime(createTime)
    }}</template>
  </message-base>
</template>
<script>
import MessageBase from "./base";
import { getRelativeTime } from "@/helpers/DataHelpers";

export default {
  components: {
    MessageBase,
  },
  props: {
    ...MessageBase.props,
    options: {
      type: Object,
      default: () => ({
        iconBackgroundColor: "rgb(255, 163, 64)",
        icon: "mdi-grid",
      }),
    },
  },
  methods: {
    calculateRelativeTime(time) {
      return getRelativeTime(time);
    },
  },
};
</script>
