import { getMenuItem } from "./helpers";

const isAdmin = (state) => {
  if (state.currentApp) return state.currentApp.permission === 100;
  else return false;
};
const currentModuleIndex = (state) => {
  return state.currentModuleIndex;
};

const currentModule = (state, getters) => {
  var result = null;
  if (
    state.currentApp &&
    state.currentApp.modules &&
    state.currentModuleIndex !== -1
  ) {
    result = state.currentApp.modules[state.currentModuleIndex];
  }
  return result;
};

const currentEditingSection = (state) => {
  return state.currentEditingSection;
};
const selectedMenuItemId = (state) => {
  return state.selectedMenuItemId;
};
const duplicatingAppMapping = (state) => {
  return state.duplicatingMap;
};
const selectedMenuItem = (state, getters) => {
  const app = state.currentApp;
  var result = null;
  if (app && app.modules) {
    for (var i = 0; i < app.modules.length; i++) {
      result = getMenuItem(
        app.modules[i].menu,
        state.selectedMenuItemId
      );
      if (result) {
        break;
      }
    }
  }
  return result;
};

const currentApp = (state)=> {
  return state.currentApp
}
export default {
  isAdmin,
  currentModuleIndex,
  currentModule,
  currentApp,
  currentEditingSection,
  selectedMenuItemId,
  selectedMenuItem,
  duplicatingAppMapping,
};
