import { getFieldDefault } from "@/helpers/FormHelpers";

const formFieldMixin = {
  methods: {
    getFieldInfoById(fieldInfos, fieldInfoId) {
      const vm = this;
      var result = null;
      if (fieldInfos) {
        result = fieldInfos.find((info) => info._id === fieldInfoId);
      }
      return result;
    },
    getFieldDefault
    // getFieldDefault(fieldInfo) {
    //   let result = null;
    //   switch (fieldInfo.type) {
    //     case "singleSelection":
    //       {
    //         const { inputOptionsSingle = {} } = fieldInfo.properties;
    //         result = inputOptionsSingle.defaultSelection || "";
    //       }
    //       break;
    //     case "multipleSelection":
    //       {
    //         const { inputOptionsMultiple = {} } = fieldInfo.properties;
    //         result = inputOptionsMultiple.defaultSelection || [];
    //       }
    //       break;
    //     case "attachments":
    //     case "childTable":
    //       result = [];
    //       break;
    //     case "departments":
    //     case "members":
    //       result =  fieldInfo.properties["defaultMember"]
    //         || fieldInfo.properties["defaultDepartment"] || [];
    //       break;
    //     case "relatedRecord":
    //       // array of record id
    //       result = [];
    //       break;
    //     case "number":
    //     case "amount":
    //       result = fieldInfo.properties["default"] || "";
    //       if (result) result = parseFloat(result);
    //       break;
    //     case "region":
    //       result = {province: null, city: null, state: null, regionText: ''}
    //       break;
    //     default:
    //       result = fieldInfo.properties["default"]
    //         ? fieldInfo.properties["default"]
    //         : null;
    //       break;
    //   }
    //   return result;
    // },
  },
};

export default formFieldMixin;
