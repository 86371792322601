<template>
  <v-dialog fullscreen v-model="showDialog" transition="dialog-bottom-transition">
    <print-docx :infoFromProps="info">
        <template v-slot:toolbar-control>
      <v-btn
        closeicon
        small
        icon
        plain
        :ripple="false"
        @click="showDialog = false"
        class="ma-0"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
        </template>
    </print-docx>
  </v-dialog>
</template>
<script>
import printDocx from "./PrintDocx.vue";
export default {
  components: {
    printDocx
  },
  props: {
    value: Boolean,
    info: Object
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
