
<template>
    <div>
        <v-navigation-drawer
            v-model="showChartDrawer"
            @input="$emit('update_showChartDrawer', $event)"
            absolute
            :temporary="!bl_addChartDialog"
            right
            :style="[{width : bl_fullScreen?'100%':'40%'}]"
            class="elevation-0 formchart-drawer fill-height"
            transition="scroll-x-transition"
        >
            <div v-if="showChartDrawer" class="d-flex flex-column fill-height" style="">
                <div class="d-flex align-end white--text font-weight-bold" style="font-size:16px; top:16px; left:12px; z-index:1; position: absolute">
                    <v-icon dark>mdi-chart-bar</v-icon>
                    <span class="ml-2">{{$t('chart.statistics')}}</span>
                </div>
                <div class="white--text" style="font-size:16px; top:8px; right:5px; z-index:1; position: absolute">
                <v-btn @click="bl_addChartDialog = true" icon plain color="white">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                    <v-btn icon plain @click="bl_fullScreen=!bl_fullScreen">
                    <v-icon color="white">mdi-arrow-expand-all</v-icon>
                </v-btn>
                </div>
          
                <v-tabs
                    height="50"
                    v-model="tab"
                    background-color="primary"
                    centered
                    dark
                    icons-and-text
                    style=""
                    class="formchart-tabs"
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#public">
                        {{$t('chart.public')}}
                    </v-tab>

                    <v-tab href="#private"> 
                        {{$t('chart.private')}}
                    </v-tab>
                </v-tabs>
                <v-tabs-items class="" :id="hookId" v-scroll.self="onScroll" v-model="tab" style="height:100%; width:100%; overflow: auto" v-if="charts.length && !bl_addChartDialog">
                    <draggable
                        v-if="tab=='public'"
                        handle=".handle"
                        class="list-group"
                        v-model="charts"
                        tag="ul"
                        ghost-class="chartdrawer-ghost" 
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = true"
                        :move="checkMove"
                    >
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <li class="mb-3" v-for="chart in charts" :key="chart.publicOrder" value="public" v-if="tab=='public'" handle=".handle">
                                <v-hover v-slot="{ hover }" class="d-flex flex-column list-group-item">
                                    <v-card outlined tile flat class="chartContainer d-flex flex-column">
                                        <ActionBtns
                                            @handleDeleteChart="handleDeleteChart(chart)"
                                            @editChart="editChart(chart)"
                                            @modifyGroup="modifyGroup($event)"
                                            @refreshChart="refreshChart(chart)"
                                            @exportChart="exportChart(chart)"
                                            :chart="chart"
                                            :hover="hover"
                                            :group="tab"
                                        />
                                        <ChartCanvas
                                            style="height:400px"
                                            :ref="`formChart-${chart._id}`"
                                            :dataSetups="chart.dataSetups"
                                            :sourceSetups="chart.sourceSetups"
                                            :styleSetupToggles="chart.styleSetupToggles"
                                            :styleSetups="chart.styleSetups"
                                            :selectedChart="chart.selectedChart"
                                            :chartId="chart._id"
                                            :appId="chart.appId"
                                            :formId="chart.formId"
                                            :tab="tab" 
                                            hookId="public-chartHook"
                                            :bl_fullScreen="bl_fullScreen"
                                        />
                                    </v-card>
                                </v-hover>
                            </li>
                        </transition-group>
                    </draggable>

                    <draggable
                        v-if="tab=='private'"
                        handle=".handle"
                        class="list-group"
                        v-model="charts"
                        tag="ul"
                        ghost-class="chartdrawer-ghost" 
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = true"
                        :move="checkMove"
                    >
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <li class="mb-3" v-for="chart in cloneDeep(charts)" :key="chart.order" value="private" v-if="tab=='private'" style="height:400px" handle=".handle">
                                <v-hover v-slot="{ hover }" class="d-flex flex-column list-group-item" style="height:100%">
                                    <v-card outlined tile flat class="chartContainer d-flex flex-column">
                                        <ActionBtns
                                            @handleDeleteChart="handleDeleteChart(chart)"
                                            @editChart="editChart(chart)"
                                            @modifyGroup="modifyGroup($event)"
                                            @refreshChart="refreshChart(chart)"
                                            @exportChart="exportChart(chart)"
                                            :chart="chart"
                                            :hover="hover"
                                            :group="tab"
                                        />
                                        <ChartCanvas
                                            style="height: 100%;"
                                            :ref="`formChart-${chart._id}`"
                                            :dataSetups="chart.dataSetups"
                                            :sourceSetups="chart.sourceSetups"
                                            :styleSetupToggles="chart.styleSetupToggles"
                                            :styleSetups="chart.styleSetups"
                                            :selectedChart="chart.selectedChart"
                                            :chartId="chart._id"
                                            :appId="chart.appId"
                                            :formId="chart.formId"
                                            :tab="tab" 
                                            hookId="private-chartHook"
                                            :bl_fullScreen="bl_fullScreen"
                                        />
                                    </v-card>
                                </v-hover>
                            </li>
                        </transition-group>
                    </draggable>
                </v-tabs-items>
            </div>
        </v-navigation-drawer>
        <ChartBuilder 
            v-if="bl_addChartDialog" 
            :currentChart="currentChart" 
            :tab="tab" 
            class="chart-builder" 
            :views="views" 
            v-model="bl_addChartDialog"
            :appId="appId"
            :formId="formId"
        />
    </div>
</template>

<script>
    import ChartBuilder from './chart/ChartBuilder'
    import ChartCanvas from './chart/panes/ChartCanvas'
    import draggable from 'vuedraggable'
    import ActionBtns from '@/components/chart/drawer/ActionBtns'
    import EventBus from '@/event-bus.js'
    import { cloneDeep } from "lodash"

    export default {
        components:{
            ChartBuilder,
            ChartCanvas,
            draggable,
            ActionBtns
        },
        props:{
            views: Array,
            showChartDrawer: Boolean
        },
        computed:{
            userId(){
                return this.$store.getters.activeEmployeeId
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            dragOptions() {
                return {
                    animation: 200,
                    group: "charts",
                    disabled: false,
                    ghostClass: "chartdrawer-ghost"
                };
            },
            appId(){
                return this.$store.getters.currentForm.appId
            },
            formId(){
                return this.$store.getters.currentForm._id
            },
            fieldInfos(){
                return this.$store.getters.currentForm.fieldInfos
            }
        },
        watch:{
            bl_fullScreen(val){
                // if(val){
                //     var parent = document.querySelectorAll('.splitpanes__pane')
                //     parent[0].style.position = "absolute"
                //     parent[0].style.width = "100%"
                //     parent[1].style.width = "100%"
                // }else{
                //     var parent = document.querySelectorAll('.splitpanes__pane')
                //     parent[0].style.position = "relative"
                //     parent[0].style.width = "20%"
                //     parent[1].style.width = "80%"
                // }
                this.charts.forEach(chart=>{
                    var ref = this.$refs['formChart-' + chart._id][0]
                    if(ref){
                        ref.resize()
                    }
                })
            },
            tab(val){
                this.charts=[]
                this.fetchCharts(val)
            },
            bl_addChartDialog(val){
                if(!val){
                    this.currentChart = null
                    this.fetchCharts(this.tab)
                }
            },
            showChartDrawer(val){
                if(val){
                    if(this.tab)
                        this.fetchCharts(this.tab)
                }
            }
        },
        data(){
            return {
                tab: undefined,
                bl_fullScreen: false,
                bl_chartExpand: false,
                bl_addChartDialog: false,
                charts: [],
                bl_fetching: false,
                currentChart: null,
                bottom: false,
                drag: false,
            }
        },
        methods:{
            cloneDeep,
            exportChart(chart){
                EventBus.$emit(`exportTable-${chart._id}`)
            },
            checkMove({relatedContext, draggedContext}){
                this.swapHandler(
                    {group: this.tab, from: draggedContext.element._id, to: relatedContext.element._id}
                )
            },
            onScroll(e) {
                // console.log('scroll', e)
                const {target} = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {  
                    this.bottom = true;
                }
            },
            async fetchCharts(val){
                const getParams = {
                    urlCommand: '/chart/get/'+ val +'/'+ this.appId + '/' + this.formId
                }
                const checkIsUpdated = (fieldId) => {
                    const field = this.fieldInfos.find(info=>{
                        return info.fieldId == fieldId
                    })
                    return !(field && field.canView)
                }
                try{
                    const result = await this.$store.dispatch('AUTH_GET', getParams)
                    this.charts = result.map(chart=>{
                        chart.isUpdated = false
                        chart.setupIsUpdated = {
                            arrX: false,
                            arrY: false,
                            arr2Y: false,
                            arrG: false,
                            filters: false
                        }
                        if(chart.userId === this.userId){
                            Object.entries(chart.dataSetups).forEach(([key, value]) => {
                                if(['arrX', 'arrY', 'arr2Y', 'arrG'].includes(key)){
                                    chart.dataSetups[key] = value.filter(item=>{
                                        if(item._id === 'recordCount') return true
                                        if(checkIsUpdated(item._id)){
                                            chart.isUpdated = true
                                            chart.setupIsUpdated[key] = true
                                            return false
                                        }else{
                                            return true
                                        }
                                    })
                                }else if(key === "filters"){
                                    chart.dataSetups[key] = value.map(filterGroup=>{
                                        return filterGroup.filter(conditon=>{
                                            if(checkIsUpdated(conditon.fieldId)){
                                                chart.isUpdated = true
                                                chart.setupIsUpdated[key] = true
                                                return false
                                            }else{
                                                return true
                                            }
                                        })
                                    })
                                }
                            });
                        }
                        return chart
                    })
                }catch(error){
                    console.error('Fetch charts error:', error)
                }

            },
            async modifyGroup(payload){
                var group = payload.group
                var chart = payload.chart
                console.log('modifyGroup', {group, chart})
                const postData = {
                    urlCommand:'/chart/update/group',
                    data: {
                        chartId:chart._id,
                        group
                    }
                }
                await this.$store.dispatch('AUTH_POST', postData).then(async(response)=>{
                    if(group=='private'){
                        console.log('modifyGroup::fetchCharts')
                        await this.fetchCharts('public')
                    }
                })
            },
            async handleDeleteChart(chart){
                const postData = {
                    urlCommand:'/chart/delete/' + chart._id,
                    data: {
                        chartId:chart._id
                    }
                }
                await this.$store.dispatch('AUTH_POST', postData).then(async(response)=>{
                    if(response.result)
                        this.charts = this.charts.filter(item=>item._id != chart._id)
                    this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                        key: "plus_sheet_mani",
                        data: {
                            module_name: this.$store.getters.formLabel,
                            func_name: this.$sensors.events.plus_sheet_mani.chart.DELETE_CHART,
                        }
                    });
                })
            },
            editChart(chart){
                this.currentChart = chart
                this.bl_addChartDialog = true
            },
            refreshChart(chart){
                var ref = this.$refs['formChart-' + chart._id][0]
                if(ref){
                    ref.FetchData()
                }
            
            },
            async swapHandler({group, from, to}){
                let vm = this
                const getParams = {
                    urlCommand: '/chart/swap/' + group + '/' + from + '/' + to,
                    options: {}
                }
                await vm.$store.dispatch('AUTH_GET', getParams).then(response=>{
                    console.log(response)
                })
            }
        }
    }
</script>

<style lang="scss">
/* .splitpanes__pane{
    width:100%;
    position: absolute
} */
/* .chartContainer:hover .chartActions{  
    display: block !important;
 } */
.chartContainer{
    border: 1px #eeee solid;
    margin: 10px;
}

.formchart-drawer{
    z-index:4;
    top: 48px !important;
    bottom: 0px !important;
    height: auto !important;
}
.formchart-tabs.v-tabs{
    flex: unset;
}

.chartdrawer-ghost{
        opacity: 0 !important;
    }
    .theme--light.sortable-chosen[draggable=true] {
        background-color: white!important;
    }
    .button {
        margin-top: 35px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
</style>
