<template>
<div class="level-block-item d-flex flex-row justify-start">
  <level-data-card :item="value" class="mr-5"></level-data-card>

  <!--<ul v-if="open" class="ml-10">-->
    <draggable
      :list="items"
      handle=".handle"
      class="d-flex flex-column justify-start level-block-list mt-5"
      ghost-class="ghost"
      @change="log"
      @end="onDragEnded"
      :key="id">

        <level-block-item
            v-for="item,index in items"
            class="mb-5"
            :key="item.id"
            :value="item"
            @input="updateChildValue"
            :group="group"
            :rowKey="rowKey">
         <span>{{item.label}}</span>
        </level-block-item>
    </draggable>
  <!--</ul>-->
</div>
</template>

<script>
import Draggable from "vuedraggable";
import levelDataCard from './LevelDataCard'

export default {
  name: "levelBlockItem",
  components: {
    Draggable,
    levelDataCard
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        id: 0,
        name: "",
        children: []
      })
    }
    // ,
    // root: {
    //   type: Boolean,
    //   default: () => false
    // },
    // group: {
    //   type: String,
    //   default: null
    // },
    // rowKey: {
    //   type: String,
    //   default: 'label'
    // },
  },
  data () {
    return {
      open: true,
      drag: false,
      localValue: Object.assign({}, this.value)
    };
  },
  computed: {
    items: {
      get () {
        const vm = this
        var result = []
        if (vm.value && vm.value.children) {
          result = vm.value.children
        }
        return result
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    hasChildren () {
      return this.value.children != null && this.value.children.length > 0;
    },
    isDark () {
      return "";
    },
    hasDefaultSlot () {
      return this.$scopedSlots.hasOwnProperty("body");
    },
    dragOptions () {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    value (value) {
      this.localValue = Object.assign({}, value);
    }
  },
  methods: {
    // updateValue (value) {
    //   if (value.constructor == Array) {
    //     this.localValue.children = [...value];
    //     this.$emit("input", this.localValue);
    //   }
    // },
    updateChildValue (value) {
      this.$emit('input', value)
      // const index = this.localValue.children.findIndex(c => c[this.rowKey] === value[this.rowKey]);
      // this.$set(this.localValue.children, index, value);
      // this.$emit("input", this.localValue);
    },
    handleChange () {
      if (this.isFolder) {
        //
        console.log("test");
      }
      return;
    },
    log: function (evt) {
      const vm = this
      window.console.log(evt);
      // vm.$emit('input', vm.items)
      vm.$emit('onCommand', {
        command: 'detachFromPanZoom'
      })
    },
    onDragEnded (evt) {
      console.log('onDragEnded :: evt: ', evt)
      this.$emit('onCommand', {
        command: 'detachFromPanZoom'})
    }
  }
};
</script>

<style scoped>

.cmp-node {
  display: flex;
  align-items: center;
}

.cmp-node:hover {
  background-color: #ecf0f1;
}

.cmp-drag-node {
  background-color: #1abc9c;
  opacity: 0.7;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
  background: #bdc3c7;
}

.arrow-right {
  width: 0;
  height: 0;
  margin-left: 5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #666;
  transition: 0.3s ease-in-out;
}

.active {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.ghost {
  color: blue !important;
}

.sortable-chosen {
  color: red !important;
}
</style>
