<template>
  <div
    v-if="app"
    class="module-tab-bar"
    :style="tabBarStyle"
    style="width:100%;min-height:26px;"
  >
    <div class="d-flex">
      <div class="" :style="hideButtons?'max-width:calc(100% - 50px)':'max-width:calc(100% - 100px)'">
        <v-tabs
          align-with-title
          v-if="tabs.length"
          ref="tabs"
          :dark="isAppDark && !useSystemColor"
          :color="useAppColorInFont? (isAppDark?app.color:shadeColor(app.color, -40)):'white'"
          v-model="showingModuleIndex"
          class="module-tab-bar elevation-0 d-flex"
          :centered="false"
          :mobile-breakpoint="0"
          :grow="true"
          :height="tabsHeight"
          :active-class="activeClass"
          :slider-size="useAppColorInFont?4:2"
        >
          <draggable
            v-if="(isAdmin && tabs && tabs.length > 0)"
            :value="tabs"
            @input="(value) => updateTabs(value)"
            class="v-tabs__container d-flex flex-row align-center"
            @update="onDraggedUpdate"
          >
            <v-tab
              v-for="(tab, index) in tabs"
              style="text-transform:none; min-width: 80px; max-width:200px; height: 100%"
              :style="`color: ${useAppColorInFont? app.color: ''}; opacity: ${useAppColorInFont&&showingModuleIndex!=index? 0.6:1}`"
              :key="index"
              class="pa-0"
            >
              <div
                :id="'tab_' + tab._id"
                class="d-inline-block"
                style="position:relative; width:100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
              >
                <v-menu offsetY>
                  <template v-slot:activator="{ on, value }">
                    <v-hover v-slot="{ hover }">
                      <div
                        :id="'tab_' + tab._id"
                        class="d-flex align-center d-inline-block px-3"
                        style="position:relative; width:100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                        :style="useAppColorInFont? 'color:#272E3B': ''"
                      >
                        <v-icon
                          v-if="tab.hidden"
                          size="18"
                          :style="`color: ${useAppColorInFont? '#272E3B': (isAppDark && !useSystemColor)?'white':'#272E3B'}`"
                          >mdi-eye-off-outline</v-icon
                        >
                        <v-icon
                          v-if="useIcon"
                          class="mr-1"
                          :style="`color: ${useAppColorInFont? '#272E3B': (isAppDark && !useSystemColor)?'white':'#272E3B'}`"
                          >{{ tab["icon"] }}</v-icon
                        >
                        <div 
                          style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
                          <span 
                            :class="useAppColorInFont? '':'pl-2'"
                          >
                          {{
                            tab[labelField]
                              ? tab[labelField]
                              : "(" + $t("roles.noName") + ")"
                          }}
                          </span>
                        </div>
                        <div style="width:20px">
                          <v-btn
                            v-if="isAdmin && menu && menu.length > 0 && (hover || showingModuleIndex==index)"
                            :dark="isSelected && isAppDark"
                            icon
                            class="ml-1 action-ellipsis pa-0 gereen"
                            x-small
                            v-on="on"
                            :ripple="false"
                            plain
                          >
                            <!-- <font-awesome-icon
                              class="fa-lg"
                              icon="ellipsis-h"
                            ></font-awesome-icon> -->
                            <div v-if="!value" class="arrow-down" :style="`border-top-color: ${useAppColorInFont? '#272E3B': (isAppDark && !useSystemColor)?'white':'#272E3B'}`"></div>
                            <div v-else class="arrow-up" :style="`border-bottom-color: ${useAppColorInFont? '#272E3B': (isAppDark && !useSystemColor)?'white':'#272E3B'}`"></div>
                          </v-btn>
                        </div>
                      </div>
                    </v-hover>
                  </template>
                  <popup-menu
                    :dataId="tab._id"
                    :title="tab.title"
                    :menu="menu"
                    :hidden="tab.hidden"
                    @onCommand="onCommandHandler"
                  ></popup-menu>
                </v-menu>
              </div>
            </v-tab>
          </draggable>
          
          <div v-else class="v-tabs__container d-flex flex-row align-center">
            <v-tab
              v-for="(tab, index) in tabs"
              style="text-transform:none; min-width: 80px; max-width:200px; height:100%"
              :style="`color: ${useAppColorInFont? app.color: ''}; opacity: ${useAppColorInFont&&showingModuleIndex!=index? 0.6:1}`"
              :key="index"
              class="pa-0"
             
            >
              <v-menu offsetY>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot="{ hover }">
                    <div
                      class="d-flex align-center px-3"
                      :style="useAppColorInFont? 'color:#272E3B': ''"
                      style="position:relative; width:100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                    >
                      <!-- <v-icon
                        v-if="tab.hidden"
                        size="14"
                        :style="`color: ${useAppColorInFont? '#272E3B': (isAppDark && !useSystemColor)?'white':'#272E3B'}`"
                        >mdi-eye-off-outline</v-icon
                      > -->
                      <v-icon
                        v-if="useIcon"
                        class="mr-1"
                        :style="`color: ${useAppColorInFont? '#272E3B': (isAppDark && !useSystemColor)?'white':'#272E3B'}`"
                        >{{ tab["icon"] }}</v-icon
                      >
                      <div 
                        style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
                        <span>{{
                          tab[labelField]
                            ? tab[labelField]
                            : "(" + $t("roles.noName") + ")"
                        }}</span>
                      </div>
                    </div>
                  </v-hover>
                </template>
                <popup-menu
                  :dataId="tab._id"
                  :title="tab.title"
                  :menu="menu"
                  :hidden="tab.hidden"
                  @onCommand="onCommandHandler"
                ></popup-menu>
              </v-menu>
            </v-tab>        
          </div>
          <tab-setting-dialog
            v-if="!hideButtons && showTabSettingDialog"
            ref="tabSettingDialog"
            :tabs="tabs"
            :app="app"
            @onCommand="onCommandHandler"
          ></tab-setting-dialog>
          <info-dialog ref="infoDialog"></info-dialog>
        </v-tabs>
      </div>
      <div class="d-flex">
        <div 
          class="d-flex flex-row align-center" 
          v-if="tabs.length"
          
        >
          <v-btn
            v-if="!hideButtons"
            :class="[addBtnClass, tabs.length ? 'ml-1' : '']"
            :style="appTextStyle"
            @click="newTab"
            icon
            style="height:24px;width:24px;"
            :dark="isAppDark"
            :color="useAppColorInFont?(isAppDark?app.color:shadeColor(app.color, -40)):''"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-menu offset-y v-if="showingDots">        
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              :dark="isAppDark"
              :color="useAppColorInFont?(isAppDark?app.color:shadeColor(app.color, -40)):''"
              outlined
              style="border:0;height:100%;"
              class="no-border"
              x-small
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list class="denseList">
            <v-list-item
              v-for="(tab, index) in tabs"
              @click="$emit('input', index)"
              :key="tab._id">
              <v-list-item-title>{{ tab[labelField] }}</v-list-item-title></v-list-item>
          </v-list>
        </v-menu>
        <v-card
          v-if="rightNote"
          :dark="app.textTheme === 'dark'"
          flat
          style="background-color:transparent;position:relative;"
          class="text-subtitle-2 rounded-0 flex-grow-0 px-4 tabbar-team-name d-flex flex-column justify-center"
        >
          <div>{{ rightNote }}</div>
        </v-card>
      </div>
      <div class="ml-auto align-start d-flex" style="padding-bottom:2px">
        <slot name="append-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Drop } from "vue-easy-dnd";
import draggable from "vuedraggable";

import appMixin from "@/mixins/appMixin";
import appStyleMixin from "@/mixins/appStyleMixin";
import baseMixin from "@/mixins/baseMixin";
import helpersMixin from "@/mixins/helpersMixin";

import popupMenu from "@/components/PopupMenu";
// import tabBarMenu from '@/menus/TabBarMenu'

import infoDialog from "@/components/dialogs/InfoDialog";
import tabSettingDialog from "@/components/dialogs/TabSettingDialog";

export default {
  mixins: [appMixin, appStyleMixin, baseMixin, helpersMixin],
  name: "moduleTabBar",
  data() {
    return {
      // tabBarMenu: tabBarMenu,
      tabs: [],
      showingDots: false
    };
  },
  components: {
    Drop,
    // tabBartabBarMenu,
    popupMenu,

    draggable,
    infoDialog,
    // editTabDialog,
    tabSettingDialog,
  },
  watch: {
    // '$refs.tabs': function(newVal) {
    //   console.log('watch($refs.tabs): newVal: ', newVal)
    // },
    availableTabs: {
      handler: function(val) {
        console.log("ModuleTabBar :: watch(availableTabs)");
        const vm = this;
        // console.log('watch(availableTabs)')
        vm.fetchTabs(val);
        vm.$nextTick(()=>{
          vm.checkShowingDots()
        })
        // vm.checkIfMenuRequired();
      },
      deep: true,
    },
    // tabContainerWidth: function (newVal) {
    //   const vm = this

    //   alert'tabContainerWidtdh = ' + tabContainerWidth)
    //   vm.$nextTick(() => {
    //     vm.checkIfMenuRequired()
    //   })        
    // }
  },
  mounted() {
    const vm = this;
    vm.fetchTabs()
  },
  computed: {
    showTabSettingDialog() {
      const vm = this;
      var result = true;
      if (vm.type === "module" && vm.isHiddenForDeployment("module_setup")) {
        result = false;
      }
      if (vm.type === "view" && vm.isHiddenForDeployment("view_setup")) {
        result = false;
      }
      return result;
    },

    useSystemColor() {
      return this.type === "childTable" || this.isLayout;
    },

    tabBarStyle() {
      const vm = this;
      var result = {};
      if (vm.useSystemColor) {
        result["background-color"] = "lightgray";
      } else {
        result["background-color"] = vm.appColor(vm.app.color, 0.8);
      }
      if (vm.useAppColorInFont) {
        result["background-color"] = "white";
      }
      return result;
    },
    app() {
      const vm = this;
      return vm.$store.getters.currentApp;
    },

    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    showingModuleIndex: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  props: {
    isLayout: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: "",
    },
    availableTabs: {
      type: Array,
      default() {
        return [];
      },
    },
    labelField: {
      type: String,
      default: "title",
    },
    useIcon: Boolean,
    rightNote: String,
    hideButtons: Boolean,
    tabsHeight: {
      type: Number,
      default: undefined,
    },
    addBtnClass: {
      type: String,
      default: "",
    },
    inFont: {
      type: Boolean,
      default: false,
    },
    value: Number,
    useAppColorInFont:{
      type: Boolean,
      default: false
    },
    activeClass:{
      type: String,
      default: undefined
    }
  },
  methods: {
    shadeColor(color, percent) {
      var R = parseInt(color.substring(1,3),16);
      var G = parseInt(color.substring(3,5),16);
      var B = parseInt(color.substring(5,7),16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R<255)?R:255;  
      G = (G<255)?G:255;  
      B = (B<255)?B:255;  

      var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
      var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
      var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

      return "#"+RR+GG+BB;
    },
    checkShowingDots(){
      var eleWidth = this.$el.children[0].offsetWidth
      var tabsContainers = this.$el.getElementsByClassName('v-tabs__container')[0]
      var tabsWidth = 0
      if(tabsContainers.hasChildNodes()){
        let tabs = tabsContainers.childNodes
        tabs.forEach(tab => {
          tabsWidth+= tab.offsetWidth
        })
      }
      this.showingDots = tabsWidth>eleWidth
    },
    selectFromMenu (index) {
      this.$emit('input', index)
    },
    onTabClicked(index) {
      const vm = this;
      vm.$emit("onCommand", {
        command: "selectTab",
        type: vm.type,
        tabIndex: index,
      });
    },
    newTab() {
      const vm = this;
      vm.$emit("onCommand", {
        command: "newTab",
      });
      this.$sensors.track("plus_add_module_block_view_button", {
        app_id: this.app._id,
        function_button: this.type
      })
    },
    fetchTabs(availableTabs) {
      const vm = this;
      var result = [];

      if (typeof availableTabs === "undefined") {
        availableTabs = vm.availableTabs;
      }
      console.log("ModuleTabBar.fetchTabs : availableTabs: ", availableTabs);
      for (var i = 0; i < availableTabs.length; i++) {
        result.push(availableTabs[i]);
      }
      vm.tabs = result;
      if (vm.$refs.tabs) {
        if (Array.isArray(vm.$refs.tabs) && vm.$refs.tabs.length > 0) {
          //vm.$refs.tabs[0].callSlider();
        }
        // if (vm.$refs.tabs instanceof Array) {
        //   vm.$refs.tabs[0].callSlider()
        // } else {
        //   vm.$refs.tabs.callSlider()
        // }
      }
    },
    updateTabs(reorderedTabs) {
      const vm = this;
      const tabNames = reorderedTabs.map((item) => item[vm.labelField]);
    },
    onDraggedUpdate(evt) {
      const vm = this;
      let oldIndex = evt.oldIndex; // Old index number of tab we are moving
      let newIndex = evt.newIndex; // New index number of tab we are moving
      if (oldIndex !== newIndex) {
        vm.$emit("onCommand", {
          command: "moveTabs",
          type: vm.type,
          oldIndex: oldIndex,
          newIndex: newIndex,
        });
      }
      /**
       * This is description for each if condition with corresponding number
       * 1. Check if tab moved is the active one
       * 2. Check if tab moved is placed on active tab from right side
       * 3. Check if tab moved is placed on active tab from left side
       * 4. Check if tab moved to right side of active tab
       * 5. Check if tab moved to left side of active tab
       */
      let oldShowingIndex = vm.showingModuleIndex; // this.getTabNumber(); // The active tab number before udpate
      let newShowingIndex = null; // The new tab which can be set as active tab

      // console.log('onDraggedUpdate :: oldIndex = ' + oldIndex)
      // console.log('onDraggedUpdate :: newIndex = ' + newIndex)
      // console.log('onDraggedUpdate :: oldTabIndex = ' + oldShowingIndex)

      if (oldShowingIndex === oldIndex) {
        newShowingIndex = newIndex;
      } else {
        newShowingIndex = oldShowingIndex;
      }

      vm.showingModuleIndex = newShowingIndex; // model = "tab-" + tabActive;
    },
    onCommandHandler(payload) {
      const vm = this;
      console.log("ModuleTabBar.onCommandHandler :: payload: ", payload);
      const selectedTab = vm.availableTabs.find(
        (tab) => tab._id === payload.id
      );
      // alert('ModuleTabBar.onCommandHandler: selectedTab: ' + JSON.stringify(selectedTab))
      if (selectedTab) {
        vm.$emit("onCommand", {
          command: payload.command + "Tab",
          data: JSON.parse(JSON.stringify(selectedTab)),
          type: vm.type,
        });
      } else {
        vm.$emit("onCommand", payload);
      }
    },
  },
};
</script>

<style lang="scss">
/* .v-tab {
  padding: 0 30px;
} */
/* .module-tab-bar .v-tabs-bar__content {
  flex-grow: 0 !important;
} */

/* .module-tab-bar .v-slide-group__wrapper {
  flex-grow: 0 !important;
} */

.module-tab-bar .v-slide-group__prev > span,
.module-tab-bar .v-slide-group__next > span {
  font-size: 13px !important;
}
.module-tab-bar .v-slide-group__prev .theme--dark.v-icon,
.module-tab-bar .v-slide-group__next .theme--dark.v-icon {
  color: unset !important
}


.module-tab-bar .theme--light .sortable-chosen {
  background-color: white;
  color: black;
}

.module-tab-bar .theme--dark .sortable-chosen {
  background-color: black;
  color: white;
}

.module-tab-bar .theme--dark .v-tab:not(.v-tab--active){
  color: rgba(255,255,255, 1) !important;
}
.module-tab-bar .theme--light .v-tab:not(.v-tab--active){
  color: rgba(0,0,0,0.8) !important;
}
/*
.module-tab-bar .v-tab {
  height: 19px;
} */

/* .v-tabs{
  flex: unset !important;
  width: auto !important;
} */
</style>
