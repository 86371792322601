<template>
    <v-hover v-slot="{hover}">
        <div
            class="markline"
            :style="{  left: getPosition() + 'px' }"
        >
          <div class="secondary" style="width:6px; height: 6px; position:absolute; left:-2px"></div>
          <div class="markline-label" v-show="hover">
            Today
          </div> 
      </div>
    </v-hover> 
  </template>
  <script>
//   import dayjs from "dayjs"
  export default {
    name: "TestMarkLine",
      props:['getPosition','timeConfig'],
  }
  </script>
  
  <style lang="scss" scoped>
    .markline {
      position: absolute;
      z-index: 100;
      width: 2px;
      height: 100vh;
      background: #747e80;
      &-label {
        padding: 3px;
        width: 4rem;
        margin-left: -2rem;
        margin-top: -0rem;
        color: #fff;
        background: #747e80;
        text-align: center;
        border-radius: 5px;
        font-size: 0.7rem;
    }
    }
  </style>