<template>
    <v-dialog 
        v-model="showingDialog" 
        width="800"
        persistent
    >
        <v-card style="overflow: hidden;">
            <dialog-header
                :label="dialogTitle"
                @close="showingDialog = false"
            />
            <!-- :color="colorStyle['background-color']"
            :toolbarClass="colorClass" -->
            <v-card-text style="height:500px; overflow: auto;" class="px-5 pt-5">
                <div class="d-flex">
                    <div style="width: 4px; height:17px" class="primary"></div>
                    <div class="ml-2">{{$t('shareApp.selectConnectedTeams')}}</div>
                </div>
                <div class="py-3">
                    <v-card 
                        v-for="(connectedTeam,index) in connectedTeams" 
                        :key="index" 
                        :class="index===0?'':'mt-3'"
                        elevation="0"
                        outlined
                        style="border-style: dashed;"
                    >
                        <div class="py-3 pl-5 pr-2 d-flex fill-height align-center" style="min-height:80px">
                            <div>
                                <div>
                                    {{$t('shareApp.teamCode')}}: {{connectedTeam.teamInfo.code}}
                                </div>
                                <div>
                                    {{$t('shareApp.teamName')}}: {{connectedTeam.teamInfo.name}}
                                </div> 
                                <div>
                                    {{$t('shareApp.passportEmail')}} : {{connectedTeam.registeredEmail}}
                                </div>
                                <div>
                                    <selected-person-tag
                                        v-for="employeeInfo in connectedTeam.admins"
                                        :key="employeeInfo._id"
                                        :info="employeeInfo"
                                        :employeeId="employeeInfo._id"
                                        :index="index"
                                        :teamShowing="false"
                                        class="workflow-customized-form-dept-element-clip"
                                        :name="employeeInfo.employeeName"
                                        :teamId="employeeInfo.teamId"
                                        :hasCloseButton="false"
                                    ></selected-person-tag>
                                </div>
                            </div> 
                            <div class="ml-auto pl-5">
                                <v-checkbox 
                                    @change="handleTeamChecked(connectedTeam, $event)" 
                                    :ripple="false" 
                                    dense 
                                    hide-details 
                                    class="pa-0 ma-0"
                                    v-model="connectedTeam.selected"
                                    v-if="!connectedTeam.isAppShared"
                                >
                                </v-checkbox>
                                <div v-else style="font-weight: bold;" class="primary--text">Shared</div>
                            </div>
                        </div>
                    </v-card>
                </div> 
            </v-card-text>
            <!-- :actionDisabled="!appInfo.title" -->
            <dialog-actions
                :buttons="['cancel', 'share']"
                @onCommand="onCommandHandler"
                :actionDisabled="!selectedCount"
            >
          </dialog-actions>
        </v-card>
        <dialog-button-select-employees
            ref="dialogButtonSelectEmployees"
            v-model="showingEmployeeDialog"
            :multiple="true"
            :enableBranchSelect="true"
            :supportJobTitleSelection="true"
            :addButton="false"
            :multipleTeams="false"
        />
    </v-dialog>
</template>

<script>
    import dialogHeader from "@/components/dialogs/comps/DialogHeader";
    import dialogActions from "@/components/dialogs/comps/DialogActions";
    import dialogButtonSelectEmployees from "@/components/dialogButtons/DialogButtonSelectEmployees";
    import selectedPersonTag from "@/components/dialogs/comps/SelectedPersonTag";
    export default {
        components:{
            dialogHeader,
            dialogActions,
            dialogButtonSelectEmployees,
            selectedPersonTag
        },
        props:{
            value: Boolean
        },
        computed:{
            showingDialog:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            activeTeamId() {
                return this.$store.getters.activeTeamId;
            },
            selectedCount(){
                return this.connectedTeams.filter(item=>item.selected).length
            }
        },
        data(){
            return {
                showingEmployeeDialog: false,
                dialogTitle: this.$t('shareApp.shareApp'),
                connectedTeams: [],
                appInfo: null
            }
        },
        methods:{
            loadShareAppSetting() {
                const getParams = {
                    urlCommand: "/teams/" + this.activeTeamId + "/shareAppSetting?appId=" + this.appInfo._id
                };
                this.$store.dispatch("AUTH_GET", getParams).then(response => {
                    console.log('loadShareAppSetting::response',  response)
                    this.connectedTeams = response.shareAppSetting? response.shareAppSetting.teams||[]:[];
                    this.connectedTeams = this.connectedTeams.map(team=>{
                        return {
                            ...team,
                            admins: [],
                            selected: false
                        }
                    })
                });
            },
            onCommandHandler(payload){
                switch(payload.command){
                    case 'share':
                        this.shareApp()
                    break  
                    case 'cancel':
                        this.showingDialog = false
                    break
                     
                }
            },
            async handleTeamChecked(connectedTeam, val){
                let vm = this
                if(val){
                    this.$refs.dialogButtonSelectEmployees.selectedEmployees = []
                    this.$refs.dialogButtonSelectEmployees.offset = 0 
                    this.$refs.dialogButtonSelectEmployees.teamId = connectedTeam. _id
                    this.$refs.dialogButtonSelectEmployees.selectedTeamId = connectedTeam._id
                    this.$refs.dialogButtonSelectEmployees.refreshSelection()
                    this.showingEmployeeDialog = true
                    await this.$nextTick(() => { 
                        // this.$refs.dialogButtonSelectEmployees.refreshSelection()
                        var invoker = (v) => {
                            vm.onAdminSubmit(v, connectedTeam)
                        }
                        this.$refs.dialogButtonSelectEmployees._events.submit = [invoker]

                        var invoker = (v) => {
                            vm.closeEmployeeDialog(v, connectedTeam)
                        }
                        this.$refs.dialogButtonSelectEmployees._events.input = [invoker]

                    })
                }else{
                    this.$set(connectedTeam, 'admins', [])
                }
                
            },
            onAdminSubmit(admins, team){
                this.$set(team, 'admins', admins)
            },
            closeEmployeeDialog(val, team){
                this.showingEmployeeDialog = false
                if(team.admins && team.admins.length>0){
                }else{    
                    team.selected = false
                }
            },
            async shareApp(){
                const selectedTeams = this.connectedTeams.filter(team=>team.selected === true)
                console.log('shareApp:selectedTeams', selectedTeams)

                const postData = {
                    urlCommand: "/role/shareApp",
                    data: {
                        teams: selectedTeams,
                        appId: this.appInfo._id,
                        teamId: this.activeTeamId,
                        appName: this.appInfo.title
                    },
                };
                await this.$store.dispatch("AUTH_POST", postData).then((res) => {
                    if (res.result === true) {
                        this.$emit('onCommand', {command:'sharedSuccess'})
                        this.showingDialog = false
                    }
                });
            }
        }
    }
</script>