<template>
    <div>
        <v-layout align-start justify-space-between>
            <div class="workflow-setting-condition-select-root">
                <ComboBox
                    :value="rule || null"
                    :options="rules.options"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    :renderLabel="renderConditionLabel"
                    @changed="v => rule = v"
                />
            </div>
            <div class="workflow-setting-columnType-select-root">
                <ComboBox
                    :value="type || null"
                    :options="types"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    @changed="v => type = v"
                />
            </div>
        </v-layout>
        <v-layout align-start justify-space-between class="workflow-setting-select-root">
            <ComboBox
                :value="value || null"
                :options="selections"
                v-if="content.type==1"
                :isMultiple="true"
                :placeholder="$t('messages.pleaseChoose', [])"
                @changed="v => value = v"
            />
            <RuleSelectField
                :value="value"
                v-else
                :comboxProps="comboxProps"
                :dependsOnType="fieldType"
                :formFieldInfos="formFieldInfos"
                @changed="v => value = v"
                :fieldProperties="fieldProperties"
            />
        </v-layout>
    </div>
    <!-- <div class=" d-flex mt-1">
        <div style="width: 120px">
            <v-select
                v-model="rule"
                class="vselect-small"
                style="font-size:12px"
                height="30"
                dense
                outlined
                :items="rules.options"
                :menu-props="{offsetY: true}"
                hide-details
            >
                <template v-slot:item="{item}">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
                <template v-slot:selection="{item}">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
            </v-select>
        </div>
        <div style="width:120px" class="ml-2" v-if="rule!='notEmpty' && rule!='empty'">
            <v-select
                v-model="type"
                class="vselect-small"
                height="30"
                style="font-size:12px"
                dense
                outlined
                :items="types"
                item-text="name"
                item-value="id"
                :menu-props="{offsetY: true}"
                single-line
                hide-details
            />
        </div>
        <div v-if="rule!='notEmpty' && rule!='isEmpty'">
            <v-select
                dense
                v-model="value"
                :items="items"
                chips
                multiple
                outlined
                v-if="type==1"
                class="ml-2 vselect-small rule-selection-opts"
                height="30"
                :menu-props="{offsetY: true}"
                item-text="label"
                item-value="id"
                hide-details
            ></v-select>
            <RuleSelectField v-else :value="value" :formFieldInfos="formFieldInfos" @input="val=>value=val" class="ml-2"/>
        </div>
        
    </div> -->
</template>

<script>
import RuleSelectField from '../units/RuleSelectField';
import { map } from 'lodash';
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import mixin from './mixin'
export default {
    mixins: [mixin],
    components:{
        RuleSelectField,
        ComboBox,
    },
    computed:{
        selections() {
            const { inputOptionsSingle={}, inputOptionsMultiple={} } = this.fieldProperties || {};

            let options = inputOptionsSingle.options;
            if (this.fieldType === 'multipleSelection') {
                options = inputOptionsMultiple.options;
            }
            return map(options, ({ _id, ...opt  }) => ({
                id: _id,
                value: _id,
                type: 'label',
                label: opt.label,
            }));
            // console.log(this.fieldProperties);
            // return this.fieldProperties.hasOwnProperty('inputOptionsSingle')?
            //     this.fieldProperties.inputOptionsSingle.options : this.fieldProperties.inputOptionsMultiple.options
        },
    },
}
</script>

<style>
/* .v-chip.v-size--default */
.rule-selection-opts .v-chip {
    height: unset !important;
}
</style>