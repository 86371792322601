<template>
  <input-widget-container
      class="mt-3"
      :class="{'mb-3':!isLayout}"
      :isLayout="isLayout"
      :widget="widget"
      :isHidden="isHidden"
      :required="required"
      :noIcon="noIcon"
      :fieldWidth="fieldWidth"
      :label="title">
    <hr />
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'
import {getFieldPropertyValue} from '../../helpers/ObjectHelpers'

export default {
  name: 'inputWidgetSectionLine',
  mixins: [mixin],
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  components: {
    inputWidgetContainer
  },
  computed: {
    title () {
      return getFieldPropertyValue(this.fieldInfo, 'title', ' ')
    }
  }
}
</script>

<style>
  .is-layout {
    padding-bottom: 0 !important;
  }
</style>
