<template>
  <div class="flex-grow-1 d-flex">
    <div class="red flex-grow-1 page-form d-flex flex-column justify-start">
      <v-card flat tile class="flex-grow-1 ma-0 d-flex flex-column">
        <form-title
            v-if="publicFormPanel"
            ref="formTitle"
            :title="selectedMenuItem?selectedMenuItem.title:''"
            :buttons="[]"
            :loading="loading"
            @onCommand="onCommandHandler"
        >
          <template v-slot:prepend-buttons="{disabled}">
            <discussion-control-button
            class="ml-1"
              :disabled="disabled"
              v-if="drawerNotEmpty"
              v-model="showDrawer"
            />
          </template>
        </form-title>

       <v-card-text class="px-0 d-flex flex-column flex-grow-1" style="height:200px; overflow:scroll; background-color:#f2f2f2">
              <div class="" style="background-color:#f2f2f2">
                <GridCanvas
                  bgColor="#f2f2f2"
                  v-if="!bl_dialog"
                  :elements="elements"
                  @update_elements="update_elements"
                  :draggable="false"
                  :resizable="false"
                  :bl_hover="false"
                />
              </div>
          </v-card-text>
      </v-card>
    </div>
  </div>
</template>


<script>
import formTitle from '@/components/FormTitle'

export default {
  components:{
    formTitle
  },
  data(){
    return {
      publicFormPanel: null
    }
  },
  mounted() {
    const vm = this;
    vm.loadPublicFormPanel()
  },
  watch: {
    "$route.params.itemId": function(newVal, oldVal) {
      const vm = this;
      if (newVal && newVal !== oldVal) {
        vm.loadPublicFormPanel()
      }
    }
  },
  computed:{
    selectedMenuItem () {
      return this.$store.getters.selectedMenuItem
    },
    selectedMenuItemId () {
      return this.$store.getters.selectedMenuItemId
    }
  },
  methods:{
    async loadPublicFormPanel(){
      var panelId = this.$route.params.itemId
      const getParams = {
          urlCommand: '/public_form_panels/get/' + panelId
      }
      await this.$store.dispatch('AUTH_GET', getParams)
        .then(response => {
          this.panel.elements = response.elements
        })
        .catch(err => {
          console.log(err)
      })
    }
  }
}
</script>
