import Vue from "vue";
import { apps as types } from "../../mutation-types";

import defaultState from "./state";

const getMenuItem = (app, moduleId, parentId) => {
  const module = app.modules.find((item) => item._id === moduleId);
  if (!module) return null;

  if (parentId) {
    let parent = getMenuItemRecusion(module.menu, parentId);
    return (parent && parent.children) || null;
  } else return module.menu;
};

const getMenuItemRecusion = (menuItems, itemId) => {
  let result = null;

  for (let i = 0; i < menuItems.length; i++) {
    let loopItem = menuItems[i];
    if (loopItem._id === itemId) {
      result = loopItem;
      break;
    } else {
      if (loopItem.children && loopItem.children.length > 0) {
        result = getMenuItemRecusion(loopItem.children, itemId);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
};

export default {
  [types.UPDATE_APP_PROPERTY](state, payload) {
    Vue.set(state.currentApp, payload.propertyKey, payload.propertyValue);
  },
  [types.DUPLICATING_APP](state, id) {
    const { duplicatingMap } = state;
    if (!duplicatingMap[id]) {
      duplicatingMap[id] = true;
      state.duplicatingMap = { ...duplicatingMap };
    }
  },
  [types.DUPLICATING_APP_SUCCESS](state, id) {
    const { duplicatingMap } = state;
    if (duplicatingMap[id]) {
      delete duplicatingMap[id];
      state.duplicatingMap = { ...duplicatingMap };
    }
  },
  [types.ADD_APP_MODULE](state, payload) {
    const app = state.currentApp;
    if (app["modules"]) {
      app.modules.push(payload.module);
    } else {
      app.modules = [payload.module];
    }
    if (state.currentModuleIndex === -1) {
      state.currentModuleIndex = 0;
    }
  },
  [types.UPDATE_APP_MODULE](state, payload) {
    console.log('UPDATE_APP_MODULE: payload: ', payload)
    const moduleIndex = state.currentApp.modules.findIndex(
      (m) => m._id === payload.module._id
    );
    console.log('UPDATE_APP_MODULE :: moduleIndex = ' + moduleIndex)
    if (moduleIndex !== -1) {
      Vue.set(state.currentApp.modules, moduleIndex, payload.module);
    }
  },
  [types.REMOVE_APP_MODULE](state, payload) {
    const { moduleId, targetModuleId } = payload;
    const app = state.currentApp;

    const moduleIndex = app.modules.findIndex((item) => item._id === moduleId);

    if (targetModuleId) {
      const targetModule = app.modules.find(
        (item) => item._id === targetModuleId
      );
      targetModule.menu = targetModule.menu.concat(
        app.modules[moduleIndex].menu
      );
      targetModule.menu.map((item, index) => (item.order = index));
    }

    app.modules.splice(moduleIndex, 1);
  },

  [types.SET_CURRENT_APP](state, payload) {
    state.currentApp = payload;
  },
  [types.SET_CURRENT_MODULE_INDEX](state, payload) {
    state.currentModuleIndex = payload;
  },
  [types.SET_CURRENT_EDITING_SECTION](state, payload) {
    state.currentEditingSection = payload;
  },
  [types.SET_SELECTED_MENU_ITEM_ID](state, payload) {
    state.selectedMenuItemId = payload;
  },
  [types.ADD_MENU_ITEM](state, payload) {
    const { moduleId, parentId, item } = payload;
    const arr = getMenuItem(state.currentApp, moduleId, parentId);
    arr.splice(item.order, 0, item);
    arr.map((child, index) => (child.order = index));
  },
  [types.UPDATE_MENU_ITEM](state, payload) {
    const { appId, moduleId, parentId, item } = payload;

    const arr = getMenuItem(state.currentApp, moduleId, parentId);

    const menuItem = arr.find((elem) => elem._id === item._id);

    Vue.set(menuItem, "title", item.title);
    Vue.set(menuItem, "icon", item.icon);
    Vue.set(menuItem, "hidden", item.hidden);
  },
  [types.REMOVE_MENU_ITEM](state, payload) {
    const { moduleId, parentId, menuItemId } = payload;
    const arr = getMenuItem(state.currentApp, moduleId, parentId);
    const index = arr.findIndex((elem) => elem._id === menuItemId);

    if (index > -1) {
      arr.splice(index, 1);
      arr.map((item, index) => (item.order = index));
    }
  },
  [types.RESET_APPS](state) {
    Object.assign(state, defaultState);
  },
};
