<template>
  <div class="pa-0 w-100 fill-height position-relative input-panel-data-column">
    <div style="height:0;min-height:100%;overflow-y:scroll;">
      <div v-for="(row,index) in rows"
        class="record-card-wrapper w-100 rounded py-1 px-1 mb-1"
        style="background-color:lightgrey;"
        :key="index">

        <record-card
          :card="row.masterRecord"
          :selected="true"
          colorClass="primary"
          :edit="false"
          style="min-height:auto;"
          :cardConfig="inputPanel.cardConfig"
          :titleFieldId="masterFormTitleFieldId"
          :fieldInfos="masterFormFieldInfos"
          :buttons="[]"
          @onCommand="onCommandHandler"
        >
          <template v-slot:rightNote>
            <div class="position-relative">
              <input-panel-time-list
                :inputPanel="inputPanel"
                :masterForm="masterForm"
                :targetForm="targetForm"
                :rowId="row._id"
                @onCommand="onCommandHandler"
                @onClick="itemIndex=>{editAttendanceInfo(row,itemIndex)}"
                :targetRecords="[toTargetRecord(row)]"></input-panel-time-list>
              <!-- <div class="mask-add position-absolute d-flex flex-row justify-end">
                <v-btn
                  height="24" 
                  @click="addAttendanceInfo(row)">
                    <v-icon small>mdi-alarm-plus</v-icon>
                </v-btn>
              </div>                 -->
            </div>

          </template>
        </record-card>
        <!-- {{ row.masterTitle }}
        <field-string
          height="auto"
          class="pb-1 white--text"
          :textOnly="false"
          :fieldInfo="titleFieldInfo"
          :recordData="row.masterRecord"></field-string>
        <v-chip height="24" small
          color="success" v-for="(fieldId, index) in dateTimeFields"
          :key="index">
          {{ row[fieldId] }}
        </v-chip> -->
      </div>
      <div v-if="rows.length===0"
        class="grey lighten-4 text-center mt-4">
        {{ $t('messages.noData') }}
      </div>
    </div>
    <div v-if="loading"
      class="w-100 fill-height d-flex flex-column position-absolute justify-center align-center"
      style="left:0;top:0;background-color:rgba(128,128,128,.5);">

      <v-icon class="text-h1">mdi-spin mdi-loading</v-icon>
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <attendance-info-dialog ref="attendanceInfoDialog"></attendance-info-dialog>
  </div>
</template>

<script>
import inputPanelDataMixin from './InputPanelDataMixin'
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import attendanceInfoDialog from '../dialogs/AttendanceInfoDialog'
import recordCard from '@/components/RecordCard'
import inputPanelTimeList from './InputPanelTimeList'
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'

export default {
  name: 'inputPanelDataColumn',
  mixins: [
    inputPanelDataMixin,
    dateTimeHelperMixin],
  components: {
    confirmDeleteDialog,
    recordCard,
    inputPanelTimeList,
    attendanceInfoDialog
  },
  methods: {
    toTargetRecord (targetRow) {
      var result = {
        _id: targetRow._id,
        createdAt: targetRow.createdAt
      }

      result[this.inputPanel.targetFieldId] = targetRow[this.inputPanel.targetFieldId]

      return result

      //  {
      //    _id
      //    label
      //    value
      //  }
      
    },
    prependRecord (record) {
      this.rows.unshift(record)
    },
    loadMore() {
      // amPmFilter = ['fullDay'|'am'|'pm']
      const vm = this
      vm.pagination.currentPage++
      vm.loading = true
      console.log('loadMore :: vm.pagination: ', vm.pagination)
      vm.$store.dispatch('FETCH_INPUT_PANEL_DATA', {
        inputPanelId: vm.inputPanel._id,
        pagination: vm.pagination,
        date: vm.criteria.activeDate,
        amPmFilter: vm.criteria.amPmFilter,
        sortingFieldId: '',
        sortingOrder: 'desc'
      }).then(
        response => {
          console.log('InputPanelDataColumn.loadMore FETCH_INPUT_DATA.then')
          vm.loading = false
          // response = {
          //    result: [],
          //    total: ...,
          // }
          console.log('response.total = ' + response.total)
          console.log('response.result.length = ' + response.result.length)
          console.log('vm.rows.length = ' + vm.rows.length)
          if (vm.startOfData) {
            vm.startOfData = false
            vm.rows = response.result
            if (response.total === 0) {
              vm.endOfData = true
            }
          } else if (response.total > vm.rows.length) {
            vm.rows = vm.pagination.currentPage === 1 ?
              response.result :
              vm.rows.concat(response.result)
          } else {
            vm.endOfData = true
          }
        }
      )
    }

    
  }

}
</script>

<style>
.input-panel-data-column .record-card-wrapper:hover .mask-add {
  display: flex!important;
}
.input-panel-data-column .record-card-wrapper .mask-add {
  display: none!important;
  cursor: pointer;
  padding: 0;
  right: 0;
  top: 100%;
  margin-top: 0px;
  width: 24px;
  z-index:1000;
}
</style>