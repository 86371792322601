<template>
  <v-dialog persistent scrollable v-model="showingDialog" width="540">
    <v-card height="500">
      <DialogHeader :label="$t('roles.field')" @close="showingDialog = false" />
      <v-card-text>
        <v-row no-gutters class="grey lighten-2">
          <v-col cols="12" class="pa-1 ma-0">
            <v-row no-gutters align="center">
              <v-col cols="6">
                {{ $t('roles.field') }}
              </v-col>
              <v-col cols="2">
                <v-row no-gutters align="center">
              <v-checkbox hide-details dense :disabled="!canViewRecord" :input-value="isAllChecked('read')" :indeterminate="isIndeterminate('read')" @click="toggleAll('read')"/>
                {{ $t('roles.view') }}
              </v-row>
              </v-col>
              <v-col cols="2">
                <v-row no-gutters align="center">
              <v-checkbox hide-details dense :disabled="!canAddRecord" :input-value="isAllChecked('create')" :indeterminate="isIndeterminate('create')" @click="toggleAll('create')"/>
                {{ $t('roles.create') }}
              </v-row>
              </v-col>
              <v-col cols="2">
                <v-row no-gutters align="center">
              <v-checkbox hide-details dense :disabled="!canEditForm" :input-value="isAllChecked('edit')" :indeterminate="isIndeterminate('edit')" @click="toggleAll('edit')"/>
                {{ $t('roles.edit') }}
              </v-row>
              </v-col>
              <!-- <v-col
                v-for="header in headers"
                :key="header.label"
                :cols="header.cols"
              >
              <v-row no-gutters align="center">
              <v-checkbox v-if="header.hasCheckBox" hide-details dense/>
                {{ header.label }}
              </v-row>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          justify="center"
          v-for="[fieldId, field] in Object.entries(fields)"
          :key="fieldId"
          class="bottom-border py-1"
        >
        <v-col :cols="1">
          <font-awesome-icon :icon="getIcon(field.type)" class="fa-fw"></font-awesome-icon>

        </v-col>
          <v-col :cols="5">
            {{ field.label }}
          </v-col>
          <v-col :cols="2">
            <v-checkbox
              ref="viewRead"
              dense
              hide-details
              :disabled="!canViewRecord"
              :input-value="canViewRecord ? field.read : false"
              @change="changeField($event, field, 'read')"
            />
          </v-col>
          <v-col :cols="2">
            <v-checkbox
              dense
              hide-details
              v-if="field.canBeCreated"
              :disabled="!canAddRecord"
              :input-value="canAddRecord ? field.create : false"
              @change="changeField($event, field, 'create')"
            />
          </v-col>
          <v-col :cols="2">
            <v-checkbox
                v-if="!field.readonly"
              dense
              hide-details
              :disabled="!canEditForm"
              :input-value="canEditForm ? field.edit : false"
              @change="changeField($event, field, 'edit')"
            />
          </v-col>

        </v-row>
      </v-card-text>
      <DialogActions @onCommand="onCommandHandler" />
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from "@/components/dialogs/comps/DialogHeader";
import DialogActions from "@/components/dialogs/comps/DialogActions";
export default {
  props: {
    value: Boolean,
    form: Object,
  },
  components: {
    DialogHeader,
    DialogActions,
  },
  data() {
    return {
      headers: [
        {
          label: this.$t('roles.field'),
          cols: 6,
          hasCheckBox: false
        },
        {
          label: this.$t('roles.view'),
          cols: 2,
          hasCheckBox: true
        },
        {
          label: this.$t('roles.create'),
          cols: 2,
          hasCheckBox: true
        },
        {
          label: this.$t('roles.edit'),
          cols: 2,
          hasCheckBox: true
        },
      ],
      fields: {}
    };
  },
  mounted(){
      this.fields = JSON.parse(JSON.stringify(this.form.fields))
  },
  computed: {
    showingDialog: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
    canEditForm() {
      return this.canViewRecord && Object.values(this.form.views).some((view) => view.edit === true);
    },
    canCreateForm() {
      return Object.values(this.form.views).some((view) => view.edit === true);
    },
    canAddRecord() {
      return this.form.add;
    },
    canViewRecord() {
      return Object.values(this.form.views).some((view) => view.read === true);
    },
  },
  methods: {
    getIcon(type){
      return this.$store.getters.widgetIconMap? this.$store.getters.widgetIconMap[type]: '';
    },
    changeField(val, field, type) {
        field[type]= val;
        if (type === 'read' && val === false) field['edit'] = false
        else if (type === 'edit' && val === true) field['read'] = true 
    },
    onCommandHandler(payload){
        switch(payload.command){
            case 'ok':
                this.$emit('commandHandler', {
                    command:'updateFields',
                    params: {
                        form: this.form,
                        fields: this.fields
                    }
                })
                this.showingDialog = false
                break;
            case 'cancel':
                this.showingDialog = false
                break;
        }
    },
    isAllChecked(type){
      let hasUnchecked = true
      switch(type){
        case 'read':
          if (this.canViewRecord)
          hasUnchecked = Object.values(this.fields).some(field => field.read === false)
          break;
        case 'create':
          if (this.canAddRecord)
          hasUnchecked = Object.values(this.fields).some(field => field.canBeCreated && field.create === false)
          break;
        case 'edit':
          if (this.canEditForm)
          hasUnchecked = Object.values(this.fields).some(field => !field.readonly && field.edit === false)
          break;
      }
      return !hasUnchecked
    },
    isIndeterminate(type){
      let fieldLength = Object.keys(this.fields).length;
      let filtered = []
      switch(type){
        case 'read':
          if (!this.canViewRecord) return false
          filtered = Object.values(this.fields).filter(field => field.read)
          break;
        case 'create':
          if (!this.canAddRecord) return false
          fieldLength = Object.values(this.fields).filter(field => field.canBeCreated).length;
          filtered = Object.values(this.fields).filter(field => field.canBeCreated && field.create)
          break;
        case 'edit':
          if (!this.canEditForm) return false
          fieldLength = Object.values(this.fields).filter(field => !field.readonly).length;
          filtered = Object.values(this.fields).filter(field => !field.readonly && field.edit)
          break;
      }
      return filtered.length > 0 && filtered.length < fieldLength
    },
    toggleAll(type){
          if (this.isAllChecked(type)) {
            Object.keys(this.fields).forEach(field=> {
              this.fields[field][type] = false
            })
          }else {
            Object.keys(this.fields).forEach(field=> {
              this.fields[field][type] = true
            })
          }

    }
  },
};
</script>
