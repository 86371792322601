<template>
  <v-combobox
    v-if="allowAdd"
    :items="isLayout? items:options"
    v-model="values"
    class="vxe-table--ignore-clear workflow-customized-form-record-element"
    :ref="`input_wid_selection_${fieldId}`"
    :hide-no-data="!search"
    :search-input.sync="search"
    small-chips
    outlined
    hide-details
    dense
    :menu-props="{ contentClass: 'vxe-table--ignore-clear' }"
    auto-select-first
    item-text="label"
    item-value="_id"
    background-color="white"
    :multiple="multiple"
  >
    <template v-slot:no-data>
      <v-list-item link @click="addNew">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('messages.noResultMatching',{search}) }}
            <i18n path="messages.pressKey">
              <kbd>enter</kbd>
            </i18n>

          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <option-button
        :option="getOption(item)"
        deletableChips
        :useColor="useColor"
        class="cursor-pointer pa-1"
        :toggleType="multiple ? 'multiple' : 'single'"
        @onDelete="onDelete"
      />
    </template>
    <template v-slot:item="{ item }">
      <option-button
        :option="getOption(item)"
        :useColor="useColor"
        class="cursor-pointer"
        :toggleType="multiple ? 'multiple' : 'single'"
      />
    </template>
    <template v-slot:append-item>
      <v-divider v-if="showAppend"></v-divider>
      <v-list-item
        v-if="showAppend"
        link
        @click="addNew"
      >
        {{ $t('messages.addNewOptions', {search}) }}

      </v-list-item>
    </template>
  </v-combobox>
  <v-autocomplete v-else
    ref="autoComplete"
    v-model="values"
    v-click-outside="onClickOutside"
    :items="isLayout? items:options"
    item-text="label"
    item-value="_id"
    small-chips
    outlined
    :search-input.sync="search"
    dense
    hide-details
    background-color="white"
    :multiple="multiple"
    :menu-props="{ contentClass: 'vxe-table--ignore-clear' }"
  >
    <template v-slot:no-data>
      <v-list-item >
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('messages.noResultMatching',{search}) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <option-button
        :option="getOption(item)"
        deletableChips
        :useColor="useColor"
        class="cursor-pointer pa-1"
        style="width: -webkit-fill-available"
        :toggleType="multiple ? 'multiple' : 'single'"
        @onDelete="onDelete"
      />
    </template>
    <template v-slot:item="{ item }">
      <option-button
        :option="getOption(item)"
        :useColor="useColor"
        class="cursor-pointer"
        :toggleType="multiple ? 'multiple' : 'single'"
      />
    </template>  
  </v-autocomplete>
</template>

<script>
import optionButton from "@/components/vxeTableCells/comps/OptionButton";
export default {
  components: {
    optionButton,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    useColor: Boolean,
    items: Array,
    itemText: String,
    itemValue: String,
    value: [String, Array],
    multiple: Boolean,
    isLayout: {
      type: Boolean,
      default: false,
    },
    fullListOptions: Array,
    allowAdd: {
      type: Boolean,
      default: false,
    },
    fieldId: String
  },
  data() {
    return {
      showingMenu: false,
      search: "",
      options: [],
      nextColorIndex: 0,
    };
  },
  mounted() {
    this.options = JSON.parse(JSON.stringify(this.items));
  },
  computed: {
    values: {
      get() {
        return this.value ? this.value: (this.multiple?[]:null)
      },
      set(val) {
        let result = this.multiple ? [] : null;
          
        if (this.multiple) {
          if (!Array.isArray(this.value)) this.value = []
          if (val.length === this.value.length) return;
          result = val.map((v) => {
            if (typeof v === "string") {
              const option = this.options.find((option) => option._id === v);
              v = (option && option._id) || {
                label: v,
                colorSet: this.getNextColor(),
                _id: `_${this.options.length}`,
              };
              if (!option) this.options.push(v);
            } else if (typeof v === "object") {
              v = v._id.startsWith("_") ? v : v._id;
            }
            return v;
          });
        }else {
          if (typeof val === "string" && val) {
            const option = this.options.find((option) => option._id === val);
              result = (option && option._id) || {
                label: val,
                colorSet: this.getNextColor(),
                _id: `_${this.options.length}`,
              };
              if (!option) this.options.push(result);            
          } else if (typeof val === "object") {
              result = val? (val._id.startsWith("_") ? val : val._id):null;
          }
        }

        this.$emit("input", result);
      },
    },
    optionTexts() {
      return this.options.map((item) => item.label.toLowerCase());
    },
    colorSets() {
      return this.$store.getters.colorSets;
    },
    showAppend() {
      return Boolean(
          this.search &&
          !this.optionTexts.includes(this.search.toLowerCase()) &&
          this.$refs[`input_wid_selection_${this.fieldId}`] &&
          this.$refs[`input_wid_selection_${this.fieldId}`].filteredItems
            .length > 0
      );
    },
  },

  methods: {
    onClickOutside (evt) {
      if (this.$refs.autoComplete)
        this.$refs.autoComplete.blur()
    },
    getOption(val) {
      if (typeof val === "object") return val;
      else {
        const result = this.fullListOptions.find(
          (option) => option._id === val
        );
        return result;
      }
    },
    addNew() {
      if (!this.search) return;
      this.values = this.multiple ? this.values.concat([this.search]): this.search;
      this.search = "";
    },
    onDelete(id) {
      console.log("PulldownChipSelection::onDelete, ", id);
      if (this.multiple) {
      const index = this.values.findIndex((val) => {
        if (typeof val === "string") return val === id;
        else {
          return val._id === id;
        }
      });

      if (index > -1) this.values.splice(index, 1);
      } else {
        this.values = null
      }

    },
    getNextColor() {
      const result = this.colorSets[this.nextColorIndex];
      this.nextColorIndex = (this.nextColorIndex + 1) % this.colorSets.length;
      return result;
    },
  },
};
</script>

<style lang="scss">
.pulldown-chip-selection-list
  .pulldown-chip-selection-item.v-list-item.selected {
  background-color: $primary-lighten !important;
}

.option-button-wrapper {
  min-height: 36px;
}
.option-button-wrapper .option-button {
  margin: 2px;
}
</style>
