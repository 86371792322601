var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('url-box',{attrs:{"url":_vm.url},scopedSlots:_vm._u([{key:"prepend-outer",fn:function(){return [_c('v-switch',{staticClass:"mt-0 px-2",attrs:{"label":_vm.$t('publicForm.activate'),"dense":"","hide-details":""},on:{"change":_vm.onActiveChange},model:{value:(_vm.publicQuery.active),callback:function ($$v) {_vm.$set(_vm.publicQuery, "active", $$v)},expression:"publicQuery.active"}}),_c('url-box',{staticClass:"px-1",attrs:{"url":_vm.publicQuery.title,"canCopy":false,"maxWidth":"100px","noQRCode":""},on:{"copy":function($event){return _vm.$emit('commandHandler',{command:'copyUrl'})},"download":function($event){return _vm.$emit('commandHandler',{command:'downloadUrl'})}}})]},proxy:true},{key:"prepend-copy",fn:function(){return [_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.openURL}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("publicForm.openInNew"))+" ")])],1)]},proxy:true},{key:"append-copy",fn:function(){return [_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":_vm.onSettingIconClicked}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cog-outline ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('general.settings'))+" ")])],1),_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.onDelete}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('buttons.delete'))+" ")])],1)]},proxy:true},{key:"append-inner",fn:function(){return [_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.refreshURL}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('publicForm.refreshURL'))+" ")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }