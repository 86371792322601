<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions>
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.xAxis')}}
            </div>
        </v-expansion-panel-header>
        <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
            v-model="bl_xAxis"
            @change="$emit('onCommandHandler',{
                command: 'update_bl_xAxis',
                value: $event
            })"
        ></v-switch>
        <v-expansion-panel-content class="px-0">
            <v-checkbox 
                v-model="setup.bl_xLabel" 
                @change="$emit('onCommandHandler',{
                    command: 'update_bl_xLabel',
                    value: $event
                })"
                :ripple="false" 
                class="ma-0" 
                dense 
                hide-details 
                :label="$t('chart.showScaleLabel')"
            >    
            </v-checkbox>
            <v-checkbox 
                v-model="setup.bl_xRotate"
                @change="$emit('onCommandHandler', {
                    command: 'update_bl_xRotate',
                    value: $event
                })"
                :ripple="false" 
                class="ma-0"  
                dense 
                hide-details 
                :label="$t('chart.xAutoTilt')"
            >
            </v-checkbox>
            <v-checkbox 
                v-model="setup.bl_xTitle" 
                @change="$emit('onCommandHandler', {
                    command: 'update_bl_xTitle',
                    value: $event
                })" 
                :ripple="false" 
                class="ma-0" 
                dense 
                hide-details 
                :label="$t('chart.showTitle')"
            >
            </v-checkbox>
             <v-text-field 
                v-show="setup.bl_xTitle" 
                v-model="setup.xTitle" 
                @change="$emit('onCommandHandler', {
                    command: 'update_xTitle',
                    value: $event
                })" 
                outlined 
                height="30"
            ></v-text-field>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    props:{
        bl_xAxis: Boolean,
        setup: Object
    }
}
</script>