<template>
  <div class="menu-item-line"
    v-on="on"
    ref="menuItemLine"
  >
  <div class="cursor-pointer d-inline-block black--text pa-0"
       :class="itemClass">
    <div class="selection-mask">
       <!--:class="{'selected': selectedItem&&(item._id===selectedItem._id)}">-->
      <div 
        class="d-inline-block menu-item-context d-flex flex-row align-center rounded"
        :style="selectedAppStyle"
        @click="selectMenuItem(item)"
        :class="foldedMenu?'justify-center':''"
      >
        <v-icon 
          :style="selectedTextStyle"
          :class="!foldedMenu?'mr-3':''"
        >{{itemIcon}}</v-icon>
        <div class="d-inline-block" style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
          <span v-if="!foldedMenu">{{item.title}}</span>
          <v-icon v-if="item.hidden && !foldedMenu" size="18" class="ml-2" style="min-height:0px; height: 0px;">mdi-eye-off-outline</v-icon>
        </div>

        <div v-if="isAdmin && !foldedMenu" class="menu-item-actions flex-grow-1 toolbar pl-1 d-inline-block">
          <!--<menu-item-menu :item="item"-->
          <!--@onCommand="onCommandHandler"></menu-item-menu>-->
          <div class="d-flex justify-end">
            <!-- :dark="selected && isAppDark" -->
            <v-btn 
              v-if="showEditButton(item.type)"
              style="height:24px"
              icon 
              small
              :dark="false"
              plain
              @click="goToEditor(app._id, item._id, item.type)">
              <v-icon size="20" style="min-height:0px; height:0px">mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-menu>
              <template v-slot:activator="{on}">
                <v-btn 
                  icon 
                  class="ml-1 action-ellipsis pa-0"
                  x-small
                  v-on="on"
                  :dark="false"
                  :ripple="false"
                  plain
                >
                  <font-awesome-icon class="fa-lg" icon="ellipsis-h"></font-awesome-icon>
                </v-btn>
              </template>
              <popup-menu
                :title="item.title"
                :menu="itemMenu"
                :selected="selected"
                :hidden="item.hidden"
                @onCommand="onCommandHandler">
              </popup-menu>
            </v-menu>
            <!-- <popup-menu :dataId="item._id" class=""
              :title="item.title"
              :menu="itemMenu"
              :selected="selected"
              :hidden="item.hidden"
              @onCommand="onCommandHandler">
            </popup-menu> -->
          </div>
        </div>
        <div 
          v-if="selected"
          :style="`background-color:${isAppDark?app.color:shadeColor(app.color, -40)}`"
          style="position: absolute; border-radius:5px; top:-0.5px; right:-4px; width:8px; height: 100%;">
        </div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>
// import menuItemMenu from './MenuItemMenu'
import popupMenu from '@/components/PopupMenu'
import appMixin from '@/mixins/appMixin'
import appStyleMixin from '@/mixins/appStyleMixin'
import itemMenu from '@/menus/MenuItemMenu'

export default {
  mixins: [appMixin, appStyleMixin],
  components: {
    popupMenu,
  },
  props: {
    item: null,
    selectedItem: null,
    addBtn:{
      type: Boolean,
      default: true
    },
    on:Object,
    foldedMenu: Boolean
  },
  computed: {
    itemMenu(){
      if(!this.isAdmin){
        return []
      }

      let result = itemMenu;
      let filter = []
      if (this.app && this.app.modules.length < 2) {
        filter.push('move')
      }
      result = result.filter(menuItem => !filter.includes(menuItem.value))
      return result
    },

    itemClass () {
      const vm = this
      var result = ''

      if (vm.selectedItem) {
        if (vm.item._id === vm.selectedItem._id) {
          result = 'selected'
        }
      }
      return result
    },

    currentModule () {
      return this.$store.getters.currentModule
    },
    itemIcon () {
      const vm = this
      var result = 'mdi-checkbox-blank-circle-outline'
       if(vm.item.icon) {
        result = vm.item.icon
      }else if (vm.item.type === 'panel') {
        return 'mdi-view-dashboard-variant'
      }
      return result
    },
    selected () {
      const vm = this
      var result = false
      if (vm.selectedItem) {
        result = vm.item._id === vm.selectedItem._id
      }
      return result
    },
    selectedTextStyle () {
      const vm = this
      var result = {}
      if (vm.selected) {
        // result = vm.appTextStyle()
        result={
          color: this.isAppDark?vm.app.color:this.shadeColor(vm.app.color, -40),
        }
      }
      return result
    },
    selectedAppStyle () {
      const vm = this
      var result = {}
      // console.log('MenuItemLine :: selectedAppStyle :: app.textTheme = ' + vm.app.textTheme)
      // console.log('MenuItemLine :: selectedAppStyle :: selected: ' + (vm.selected ? 'yes' : 'no'))
      if (vm.selected) {
          result={
          color: this.isAppDark?vm.app.color:this.shadeColor(vm.app.color, -40),
            "font-weight": "bold"
          }
      }
      return result
    },
    app () {
      return this.$store.getters.currentApp
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  methods: {
    showEditButton(type){
      const validItemType = ["form", "panel"];
      return Boolean(validItemType.includes(type) && this.isAdmin);
    },
    goToEditor(appId, itemId, type){
      let routeName = "";
      switch(type){
        case "form":
          routeName = "adminFormBuilder"
        break;
        case "panel":
          routeName = "adminPanelBuilder"
        break;
      }
      if (routeName){
        this.$router.push({name: routeName, params: {id: appId, itemId: itemId}})
      }
    },
    shadeColor(color, percent) {
      var R = parseInt(color.substring(1,3),16);
      var G = parseInt(color.substring(3,5),16);
      var B = parseInt(color.substring(5,7),16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R<255)?R:255;  
      G = (G<255)?G:255;  
      B = (B<255)?B:255;  

      var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
      var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
      var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

      return "#"+RR+GG+BB;
    },
    selectMenuItem (item) {
      this.$refs.menuItemLine.click()
      const vm = this
      // vm.$emit('onCommand', {
      //   command: 'selectMenuFolder',
      //   item: item.type === 'submenu' ? item : null
      // })
      vm.$emit('onCommand', {
        command: 'selectMenuItem',
        item: item
      })
    },
    onCommandHandler (payload) {
      const vm = this
      this.$emit('onCommand', {
        command: payload.command + 'MenuItem',
        item: vm.item,
        moduleId:vm.currentModule._id,
      })
    }
  }
}
</script>

<style>
      .menu-item-line div.cursor-pointer {
        padding-right: 5px;
        position: relative;
        width: 100%;
      }

      .menu-item-line div.cursor-pointer .toolbar {
        display: none !important;
        white-space: nowrap;
        overflow-x: visible;
        text-overflow: initial;
      }

      .menu-item-line div.cursor-pointer:hover .selection-mask {
        background-color: #f9f9f9;
      }
      .menu-item-line div.cursor-pointer:hover .toolbar {
        display: inline-block !important;
      }

      /*.menu-item-line div.cursor-pointer.selected:hover > div:first-child i,*/
      /* .menu-item-line div.cursor-pointer.selected:hover > div:first-child {
        background-color: darkgrey !important;
      }

      .menu-item-line div.cursor-pointer:hover > div:first-child {
        background-color: lightgrey;
      } */
/* 
      .menu-item-line div.cursor-pointer.in-item-path {
        background-color: lightgray;
      } */

      /*.menu-item-line div.cursor-pointer.selected > div:first-child i,*/
      .menu-item-line div.cursor-pointer.selected > div:first-child {
        /*background-color: royalblue;*/
        /*color: white !important;*/
        /* border-radius: 0.2rem; */
      }

      .menu-item-line div{
         border-radius: 0.2rem;
      }


</style>
