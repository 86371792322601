<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :required="!isReadOnly && required"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :noIcon="noIcon"
    :isHidden="isHidden"
    class="workflow-customized-form-withPlaceholder"
  >
    <div>
      <div
        class="workflow-customized-form-placeholder"
        @click="handleMembersInputClick"
        v-if="
          (!inputEmployees || !inputEmployees.length) && customizedPlaceholder
        "
      >
        <div>{{ customizedPlaceholder }}</div>
      </div>
      <v-input v-if="isReadOnly" disabled>
        <div
          class="pa-1 w-100 mb-0"
          style="border-bottom: 1px dashed darkgray;min-height: 32px;"
        >
          <selected-person-tag
            v-for="(employeeInfo, index) in inputEmployees"
            :key="employeeInfo._id"
            :employeeId="employeeInfo._id"
            :index="index"
            :info="employeeInfo"
            class="workflow-customized-form-dept-element-clip"
            :hasCloseButton="false"
            :teamShowing="fieldInfo.properties.teamShowing"
            :name="employeeInfo.employeeName"
            :teamId="employeeInfo.teamId"
          ></selected-person-tag>
        </div>
      </v-input>
      <!-- @click="handleInputClick" -->

      <v-input
        v-else-if="isDialog"
        @click="handleMembersInputClick"
        ref="inputWidgetMembers"
        :value="inputValue"
        :rules="rules"
        :error="hasError"
        class="iw-members-vinput"
        :class="{ 'workflow-customize-form-record-hide': hideInput }"
        v-on="on"
      >
        <div
          class="pa-1 v-text-field--outlined w-100 mb-0 workflow-customized-form-dept-element"
          style="background-color:white"
        >
          <selected-person-tag
            v-for="(employeeInfo, index) in inputEmployees"
            :key="employeeInfo._id"
            :info="employeeInfo"
            :employeeId="employeeInfo._id"
            :index="index"
            :teamShowing="fieldInfo.properties.teamShowing"
            class="workflow-customized-form-dept-element-clip"
            deleteCommand="removeEmployeeByIndex"
            clickCommand="removeEmployeeByIndex"
            @onCommand="onCommandHandler"
            :name="employeeInfo.employeeName"
            :teamId="employeeInfo.teamId"
          ></selected-person-tag>
        </div>
      </v-input>

      <MembersMenu
        v-else
        :fieldId="fieldId"
        :fullWidth="false"
        :activeOnly="true"
        :type="isMultiple ? 'multiple' : 'single'"
        :value="showingMenu"
        @memberClick="onMemberClick"
      >
        <template v-slot:activator="{ on }">
          <v-input
            ref="inputWidgetMembers"
            :value="inputValue"
            :rules="rules"
            :error="hasError"
            class="iw-members-vinput"
            :class="{ 'workflow-customize-form-record-hide': hideInput }"
            v-on="on"
          >
            <div
              class="pa-1 v-text-field--outlined w-100 mb-0 workflow-customized-form-dept-element"
              style="background-color:white"
            >
              <selected-person-tag
                v-for="(employeeInfo, index) in inputEmployees"
                :key="employeeInfo._id"
                :info="employeeInfo"
                :employeeId="employeeInfo._id"
                :index="index"
                :teamShowing="fieldInfo.properties.teamShowing"
                class="workflow-customized-form-dept-element-clip"
                deleteCommand="removeEmployeeByIndex"
                clickCommand="removeEmployeeByIndex"
                @onCommand="onCommandHandler"
                :name="employeeInfo.employeeName"
                :teamId="employeeInfo.teamId"
              ></selected-person-tag>
            </div>
          </v-input>
        </template>
      </MembersMenu>
    </div>
    <dialog-button-select-employees
      ref="dialogButtonSelectEmployees"
      v-model="showingDialog"
      :multiple="isMultiple"
      :enableBranchSelect="enableBranchSelect"
      :supportJobTitleSelection="supportJobTitleSelection"
      :addButton="false"
      @submit="onSubmit"
      :selectedValue="inputValue"
      v-if="isDialog"
    >
      <!-- v-if="" -->
    </dialog-button-select-employees>

    <!-- <members-menu
      :fullWidth="false"
      v-if="showingDialog"
      v-model="showingDialog"
      :type="fieldInfo.properties.fieldType"
      :selectedValue="value"
      attachId="memberMenuAttPt"
      @input="handleMembersMenuInput"
    /> -->
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import dialogButtonSelectEmployees from "@/components/dialogButtons/DialogButtonSelectEmployees";
import selectedPersonTag from "@/components/dialogs/comps/SelectedPersonTag";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import MembersMenu from "@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu";

export default {
  name: "inputWidgetMembers",
  mixins: [mixin],
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
    customizedPlaceholder: String,
    enableBranchSelect: {
      type: Boolean,
      default: false,
    },
    supportJobTitleSelection: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    dialogButtonSelectEmployees,
    selectedPersonTag,
    inputWidgetContainer,
    MembersMenu,
  },
  data() {
    return {
      RULE_REQUIRED: (v) =>
        v.length > 0 || this.$t("messages.thisFieldIsRequired"),
      showingDialog: false,
      attachedEle: null,
    };
  },
  computed: {
    isDialog() {
      return (
        (this.fieldInfo.properties.selectionMode &&
          this.fieldInfo.properties.selectionMode == "dialog") ||
        !this.fieldInfo.properties.selectionMode
      );
    },
    inputEmployees() {
      const vm = this;
      if (vm.isLayout) {
        return vm.defaultMembers;
      } else {
        return vm.inputValue;
      }
    },
    isMultiple() {
      return (
        getFieldPropertyValue(this.fieldInfo, "fieldType", "single") ===
          "multiple" || this.multiple
      );
    },
    defaultMembers() {
      return getFieldPropertyValue(this.fieldInfo, "defaultMember", []);
    },
    // ,
    // teamIdNameHash () {
    //   return this.$store.getters.teamIdNameHash
    // },
  },
  methods: {
    // handleMembersMenuInput(val){
    //   if(!val){
    //     if(this.attachedEle){
    //       this.attachedEle = null
    //     }
    //   }
    // },
    handleMembersInputClick(e) {
      if (this.isDialog) this.showingDialog = true;
    },
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "removeEmployeeByIndex":
          vm.removeEmployeeByIndex(payload.index);
          break;
      }
    },

    removeEmployeeByIndex(index) {
      const vm = this;
      vm.inputValue.splice(index, 1);
      vm.updateValue(vm.inputValue);
    },
    onMemberClick(val) {
      const index = this.inputValue.findIndex(
        (employee) => employee._id === val._id
      );
      if (index > -1) this.removeEmployeeByIndex(index);
      else if (this.isMultiple){
        this.inputValue.push(val)
      }else this.inputValue = [].concat(val)
    },
    _onParseValue(val) {
      const vm = this;
      if (typeof val === "undefined") {
        val = vm.value;
      }
      var result = val;
      if (
        result === null ||
        result === "" ||
        (result instanceof Array && result.length === 0)
      ) {
        console.log("_onParseValue :: val: ", val);
        result = [];
      }

      if (vm.isMultiple) {
        if (!Array.isArray(result)) {
          result = [];
        }
      } else {
        if (Array.isArray(result)) {
          result = "";
        }
      }
      console.log("setInputValue :: typeof result = " + typeof result);
      return result;
    },
    onSubmit(payload) {
      this.inputValue = payload;
    },
    getDefaultValue() {
      return getFieldPropertyValue(this.fieldInfo, "defaultMember", []);
    },
  },
  mounted() {
    if (this.$refs.inputWidgetMembers)
      this.$refs.inputWidgetMembers.resetValidation();

    if (!Array.isArray(this.inputValue)) this.inputValue = []
  },
};
</script>

<style>
.is-layout {
  padding-bottom: 0 !important;
}
.iw-members-vinput.v-input--is-readonly .v-input__control > .v-input__slot {
  padding: 0px !important;
}
</style>
