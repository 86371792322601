import { pick, map, has, isArray, includes, filter, chain, find, forEach, forOwn, get, findIndex } from 'lodash';
import {
  WorkflowNodeIdentify,
} from './../constants/node';
import SettingUtils from './setting';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import { WidgetSelectionAcceptance } from '@/pages/admin/workflow/constants/form';
const {
  condition,
} = WorkflowNodeIdentify;
/*
* Tree Node related untilities helper
* Terry Chan
* 12/05/2021
*/
class NodeUtils {
    constructor() {
        this.comboBoxSelectedLookup = {
            'dataset': 'TableChip',
            'eventset': 'EventChip',
            'nodeset': "EventChip",
            'delegated': 'DelegateChip',
            'label': 'LabelChip',
            'newTag': 'LabelChip',
            'processset': 'ProcessChip',
        };
        this.comboBoxOptionLookup = {
            'dataset': 'TableOption',
            'eventset': 'EventOption',
            'nodeset': "EventOption",
            'delegated': 'DelegateOption',
            'label': 'LabelOption',
            'newTag': 'LabelOption',
            'processset': 'ProcessOption',
        };
    }
    rejectRePosition(node, current) {
        const { parent } = current;
        // the next parent is the current parent, then the position does not change
        return parent && node._id === parent._id;
    }
    rePositionSupport(node) {
        const { parent, nodeType } = node;
        const nodeCorrect = !!parent && nodeType === 'node';
        let isUnderInvalidBranch = false;
        if (!!parent && parent.nodeType === 'branch') {
            // only left one node, not allowed to move the current node
            isUnderInvalidBranch = parent.children.length < 2;
        }
        return nodeCorrect && !isUnderInvalidBranch;
    }
    findStoreListItem(list, item, comparer) {
        const index = findIndex(list, l => {
            return comparer(l, item);
        });
        if (index !== -1) {
            return {
                index,
                item: list[index],
            };
        }
        return {
            index,
        }
    }
    comboBoxSelectedComponent({ type, isSystem }) {
        if (isSystem && !type) {
          return this.comboBoxSelectedLookup.delegated;
        }
        const target = this.comboBoxSelectedLookup[type];
        if (!target) {
          return this.comboBoxSelectedLookup.label;
        }
        return target;
    }
    // withDisabledAcceptanceOptions(dependsOnType, list) {
    //     const options = this.normalizeComboxOptions(list);
    //     if (dependsOnType) {
    //         return this.normalizeDependencyOption(options, WidgetSelectionAcceptance, dependsOnType);        
    //     }
    //     return options;
    // }
    
    withAcceptanceOptions({
        dependsOnType, list, properties, formId, fieldLabel
    }) {
        const options = this.normalizeComboxOptions(list, [], dependsOnType, fieldLabel);
        if (dependsOnType) {
            return this.normalizeDependencyOption(options, WidgetSelectionAcceptance, dependsOnType, properties, formId, fieldLabel);        
        }
        return options;
    }
    withArray(arr) {
        let target = arr;
        if (!isArray(arr) && arr !== undefined && arr !== null) {
          target = [ arr ];
        }
        return target || [];
    }
    getRelationFormId(fieldType, properties) {
        const isRelationField = SettingUtils.relationshipFields({ fieldType });
        if (isRelationField) {
            const { dataSource, fieldsEditor } = properties;
            if (dataSource) {
                return dataSource;
            } else if (fieldsEditor) {
                return fieldsEditor.formId;
            }
        }
        return null;
    }
    matchingType(acceptance, type, setting, dependsOnType, properties={}) {
        let matched = includes(acceptance, type);
        const selectionTypes = [
            'singleSelection',
            'multipleSelection',
        ];
        let dataTableId = get(properties, 'dataSource.dataTableId') || properties.dataSource;
        dataTableId = dataTableId || get(properties, 'fieldsEditor.formId');
        if (dataTableId) {
            switch (dependsOnType) {
                case 'relatedRecord':
                case 'childTable':
                    if (!includes(['REMOVERELATION'], type)) {
                        // from the options list tableId
                        let currentDataTableId = get(setting, 'properties.dataSource');
                        currentDataTableId = currentDataTableId || get(setting, 'fieldsEditor.formId');
                        matched = matched && currentDataTableId === dataTableId;
                    }
                    // console.log(type === 'relatedRecord', currentDataTableId, dataTableId)
                    break;
            }
        // must be match to the same selection options list
        } else if (includes([
            'singleSelection',
            'multipleSelection',
        ], dependsOnType)) {
            if (includes(selectionTypes, type)) {
                const { properties: settingProperties={} } = setting;
                const { selectionDataSource } = settingProperties;
                matched = matched && (
                    settingProperties._id === properties._id || 
                    // global option compare
                    selectionDataSource && selectionDataSource === properties.selectionDataSource
                );
            }
        }
        return matched;
    }
    normalizeDependencyOption(options, acceptanceLookup=[], dependsOnType='', properties, relationFormId, fieldLabel) {
        let acceptance;
        let childrens;
        let list = [];
        // let dependencies = !isArray(dependsOnType) && dependsOnType ? [dependsOnType] : dependsOnType;
        forEach(options, ({ children=[], ...rest }) => {

            if (relationFormId && rest.form === relationFormId) {
                list.push({
                    ...rest,
                    children: undefined,
                    fullRelationOutput: true,
                    fieldType: 'relatedRecord',
                });
                return;
            }
            acceptance = acceptanceLookup[dependsOnType];
            // chain(acceptanceLookup).pick(dependencies).flattenDeep().compact().value();
            // if no specify, then 
            if (!acceptance) {
              acceptance = [dependsOnType];
            }
            if (rest.nodeType === "generateFile" ){
                if (acceptance.includes("attachments") ) {
                    list.push({
                        id:rest.id,
                        label:rest.label,
                        nodeType: rest.nodeType,
                        suffix: get(rest, "properties.template.label", "")
                    })
                }
                return
            }
            if (rest.type === 'newTag') {
                list.push(rest);
              // return { ...rest, fieldType };
            }  
            else if (children.length > 0) {
                childrens = filter(children, c => this.matchingType(
                    acceptance, 
                    c.fieldType || c.type, 
                    c, 
                    dependsOnType, 
                    properties
                ));
                if (childrens.length > 0) {
                    rest.children = childrens;
                    list.push(rest);
                }
                return;
            } else if (this.matchingType(acceptance, rest.fieldType || rest.type, rest, dependsOnType, properties)) {
                list.push(rest);
            }
            return;
        });
        return list;

    }
    shouldDisableOptions(c, disabledLookup) {
        return has(disabledLookup, c._id);
    }
    // make sure the options have id field
    normalizeComboxOptions(options, disabledLookup=[], fieldType, fieldLabel) {
        // const relationField = SettingUtils.relationshipFields({ fieldType });
        let child;
        let parent;
        let list = [];
        let children;
        forEach(options, o => {
            children = [];
            if (o.children && o.children.length) {
              forEach(o.children, c => {
                if (!c.id) {
                    child = { ...c, id: c._id };
                } else {
                    child = { ...c };
                };
                child.isDisabled = this.shouldDisableOptions(child, disabledLookup);
                children.push(child);
              });
            }
            
            if (!o.id) {
                parent = { ...o, id: o._id };
            } else {
                parent = { ...o };
            }
            parent.children = children;
            parent.isDisabled = this.shouldDisableOptions(parent, disabledLookup);
            list.push(parent);
            // return { ...parent, isDisabled: this.shouldDisableOptions(o, disabledLookup) };
        });
        return list;
    }
    prevHasResultCondition(node) {
        return this.hasResultCondition(node.type);
    }
    nextHasResultCondition(tree, node, offset, remark) {
        const nextOffset = offset + 1;
        const nextForkingNode = get(tree, `[${nextOffset}]`);
        return nextForkingNode 
            && this.hasResultCondition(node.type) !== -1
            && this.isFork(nextForkingNode.nodeType) // the next node is forking node
            && !!nextForkingNode.dependsNode    // required dependsOn node
            && nextForkingNode.dependsNode._id === node._id;  // the dependsOn node is the current node
    }
    // there is a forking node is depending on the parent node type
    // use Boolean true false as the conditional branch node
    hasResultCondition(type) {
        return findIndex([
            'retrieveRecord',
            'retrieveRecordFromDataSource',
            'retrieveRecordFromDataSources',
            'retrieveRecordFromRelationship',
            'retrieveRecords',
            'retrieveRecordsFromDataSource',
            'retrieveRecordsFromRelationship',
            'retrieveRecordsFromCreateRecord',
            'retrieveRecordsFromManualNode',
            'retrieveRecordsFromWebhook',
            'retrieveRecordsFromProgram',
            'retrieveMember',
            'retrieveMemberFromRoleField',
            'retrieveMemberFromDepartmentField',
            'retrieveMemberFromRole',
            'retrieveMemberFromField',
            'retrieveMembers',
            'retrieveMembersFromRoleField',
            'retrieveMembersFromDepartmentField',
            'retrieveMembersFromRole',
            'retrieveMembersFromField',
            'approval',
        ], e => e === type);
    }
    isDenpendencyRelied(nextNode, currentNode) {
        return nextNode && this.isFork(nextNode.nodeType) && nextNode.dependsNode && nextNode.dependsNode._id === currentNode._id;
    }
    isFork(type) {
        return !!type && [
            WorkflowNodeIdentify.fork,
        ].indexOf(type) !== -1;
    }
    isBranch (type) {
        return !!type && [
            WorkflowNodeIdentify.condition,
        ].indexOf(type) !== -1;
    }
    needToMergeToBranch({ tree, node, offset, newNode }) {
        // [IMPORTANT] branch node children is only the node except itself, so the length is incorrect to reduce 1
        // once greter than offset index, means there is one node is next to the node to be insert
        if (this.isBranch(node.nodeType)) {
            return tree.length > offset && this.isFork(newNode.nodeType);
        }
        return tree.length - 1 > offset && this.isFork(newNode.nodeType);
    }
    pickRegularNode(node) {
        return pick(node, [
            'children',
            'id',
            'module',
            'name',
            'remark',
            'nodeType',
            // 'parent',
        ]);
    }
    normalizeCopiedNodeToBeSaved(node, translator) {
        let normalized = { ...node };
        normalized.name = `${node.name} - ${translator.label.copy}`;
        return pick(normalized, ['name', '_id']);
    }
    normalizeNewNodeToBeSaved(node) {
        const normalized = this.pickRegularNode(node);
        const { children } = normalized;
        const { parent } = node;
        if (parent && parent._id) {
            normalized.parent = parent._id;
        }
        if (children && children.length > 0) {
            normalized.children = map(children, (child, index) => {
                child.outputRequired = !index;
                return this.normalizeNewNodeToBeSaved(child)
            });
        }
        return normalized;
    }

    mergeNewNodeToBeSaved(node, target) {
        const fields = ['_id', 'status', 'dependencies', 'setting'];
        const info = pick(node, fields);
        // console.log('>>>> ', node, info);
        const { children=[] } = node;
        const { children: currentChildren=[] } = target;
        forEach(fields, field => {
            target[field] = info[field];
        });
        if (children.length === currentChildren.length) {
            forEach(children, (child, index) => this.mergeNewNodeToBeSaved(child, currentChildren[index]));
        }
    }
    mergeCopiedNodeToBeSaved(node, target) {
        const fields = ['name', '_id'];
        const info = pick(node, fields);
        forEach(fields, field => {
            target[field] = info[field];
        });
    }
    referenceUpdate(target, info) {
        if (target) {
            const fields = [ 'name', 'remark', 'properties' ];
            forEach(fields, field => {
                if (has(info, field)) {
                    target[field] = info[field];
                    if (field === 'properties') {
                        target.properties = { ...info.properties };
                    }
                }
            });
        }
    }   
    referenceSettingUpdate(target, info) {
        if (target) {
            forOwn(info, (v, field) => {
                target[field] = v;
            });
        }
    }   
}

export default new NodeUtils();
