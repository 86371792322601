<template>
  <v-card width="960" class="pa-2">
    <v-card-title>
      {{ $t("publicForm.publishForm") }}
    </v-card-title>
    <loading-icon v-if="loading" />
    <v-card-text v-else>
      <v-row no-gutters>
        {{ $t("publicForm.description") }}
        <span @click="showDialog = true"><a>{{
          selectedField ? 
          $t('publicForm.publicFieldIdSelected', {
            label:selectedFieldLabel
          }):
          $t('publicForm.selectTextField')
          
          }}</a></span>
      </v-row>
      <public-form-row
        v-for="publicForm in publicForms"
        :key="publicForm._id"
        :publicForm="publicForm"
        @commandHandler="onCommandHandler"
      />
      <v-btn color="primary" rounded @click="openDialog('newFormDialog')"
        >{{ $t("buttons.new") }}<v-icon>mdi-plus</v-icon></v-btn
      >
    </v-card-text>
    <confirm-delete-dialog ref="ConfirmDeleteDialog" />
    <new-field-dialog
      ref="newFormDialog"
      @confirm="addForm"
      :title="$t('buttons.new')"
      :placeholder="$t('general.title')"
    />
    <new-field-dialog ref="newFieldDialog" @confirm="addTextField" />
    <v-dialog v-if="showDialog" v-model="showDialog" width="340" persistent>
      <v-card>
        <dialogHeader :label="$t('publicForm.selectTextField')" @close="showDialog = false" />
        <v-card-text>
          <select-field
            class="py-2"
            :items="fieldOptions"
            :value="selectedField"
            @input="dialogValue = $event"
            @clear="dialogValue = ''"
            @add="openDialog('newFieldDialog')"
          />
        </v-card-text>
        <dialog-actions
          :buttons="['cancel', 'save']"
          @onCommand="onCommandHandler"
        ></dialog-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import LoadingIcon from "@/components/global/loadingIcon";
import PublicFormRow from "./comp/publicFormRow.vue";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import newFieldDialog from "./comp/newFieldDialog";
import selectField from "./comp/selectField";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import dialogActions from "@/components/dialogs/comps/DialogActions";
import MediaHelper from '@/helpers/MediaHelper';
import mixin from "./mixin";

export default {
  components: {
    LoadingIcon,
    PublicFormRow,
    ConfirmDeleteDialog,
    newFieldDialog,
    selectField,
    dialogHeader,
    dialogActions,
  },
  mixins:[mixin],
  data() {
    return {
      showFormBuilder: false,
      publicForms: [],
      qrcode: "",
      dialogValue: "",
      loading: false,
      showDialog: false,

      type:"FORM"
    };
  },
  mounted() {
    this.getForm();
  },
  computed: {
    fieldOptions() {
      const fieldInfos = this.$store.getters.formFieldInfos;
      if (!fieldInfos) return [];
      else {
        let allHiddenFields = [];
        this.publicForms.forEach((form) => {
          allHiddenFields = allHiddenFields.concat(form.hiddenFieldIds);
        });
        allHiddenFields = Array.from(new Set(allHiddenFields));
        return fieldInfos.filter(
          (info) =>
            info.type === "text" &&
            (info._id === this.selectedField ||
              !allHiddenFields.includes(info._id))
        );
      }
    },
    selectedField() {
      const form = this.$store.getters.currentForm;
      return form ? form.publicFormId : "";
    },
    selectedFieldLabel() {
      if (this.selectedField){
        const result = this.fieldOptions.find(info => info.fieldId === this.selectedField)
        return (result && result.label)|| ""
      }else return ""
    }
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "saveForm":
          this.saveForm(payload.publicForm);
          break;
        case "deleteForm":
          this.deleteForm(payload.publicForm);
          break;
        case "refreshURL":
          this.refreshUrl(payload.publicForm);
          break;
        case "saveSetting":
          this.saveSetting(
            payload.publicForm,
            payload.setting,
            payload.value,
            payload.cb
          );
          break;
        case "saveCustomProps":
          this.saveCustomProps(
            payload.publicForm,
            payload.fieldId,
            payload.value
          );
          break;
        case "saveFieldId":
          this.saveFieldId(payload.publicForm, payload.propKey, payload.val);
          break;
        case "setLogo":
          this.setLogo(payload.publicForm, payload.file);
          break;
        case "setImage":
          this.setImage(payload.publicForm, payload.file);
          break;
        case "reset":
          this.reset(payload.publicForm);
          break;
        case "save":
          this.updateForm(this.dialogValue);
          break;
        case "cancel":
          this.dialogValue = this.selectField;
          this.showDialog = false;
          break;
        case "openSettings":
          this.track({key:"plus_public_publishing_function", data:{
            function_button: this.$sensors.events.plus_public_publishing_function.SETTINGS
          }});
        break;
      }
    },
    updateForm(value) {
      this.loading = true;
      const postData = {
        urlCommand: "/publicForm/updatePublicFormId",
        data: {
          formId: this.$store.getters.currentForm._id,
          appId: this.$store.getters.currentForm.appId,
          value,
        },
      };

      this.$store
        .dispatch("AUTH_POST", postData)
        .then((res) => {
          this.publicForms = res;
          this.$store.dispatch("SET_CURRENT_FORM_PROPERTY", {
            propertyKey: "publicFormId",
            propertyValue: value,
          });
        })
        .finally(() => {
          this.loading = false;
          this.showDialog = false;
        });
    },
    reset(publicForm) {
      const postData = {
        urlCommand: "/publicForm/reset",
        data: {
          appId: publicForm.appId,
          formId: publicForm.formId,
          publicFormId: publicForm._id,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        publicForm.layout = res;
      });
    },
    async uploadImage(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("formId", this.$route.params.itemId);
      formData.append("appId", this.$route.params.id);
      const postData = {
        urlCommand: "/publicForm/uploadLogo",
        options: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        data: formData,
      };
      const res = await this.$store.dispatch("AUTH_POST", postData);
      return res && res.path;
    },
    setLogo(publicForm, file) {
      if (file) {
        const payload = {
          appId: publicForm.appId,
          formId: publicForm.formId,
          file: file,
          isPublic: true,
          useUploadBucket: false
        }
        MediaHelper.uploadFile(payload, tempMedia => {
          MediaHelper.createMedia({
            ...tempMedia,
            isPublic: true,
            appId: publicForm.appId,
            formId: publicForm.formId
          }, updatedMedia => {
            this.saveSetting(publicForm, "logo", {
              mediaId: updatedMedia._id,
              objectKey: updatedMedia.objectKey,
              imageUrl: updatedMedia.imageUrl,
              mediaType: updatedMedia.mediaType
            });
          })
        });
      } else {
        this.saveSetting(publicForm, "logo", null);
      }
    },
    setImage(publicForm, file) {
       if (file) {
        const payload = {
          appId: publicForm.appId,
          formId: publicForm.formId,
          file: file,
          isPublic: true,
          useUploadBucket: false
        }
        MediaHelper.uploadFile(payload, tempMedia => {
          MediaHelper.createMedia({
            ...tempMedia,
            isPublic: true,
            appId: publicForm.appId,
            formId: publicForm.formId            
          }, updatedMedia => {
            this.saveSetting(publicForm, "backgroundImage", {
              mediaId: updatedMedia._id,
              objectKey: updatedMedia.objectKey,
              imageUrl: updatedMedia.imageUrl,
              mediaType: updatedMedia.mediaType
            });
          });
        });
      } else {
        this.saveSetting(publicForm, "backgroundImage", null);
      }
    },
    saveForm(publicForm) {
      const postData = {
        urlCommand: "/publicForm/saveForm",
        data: {
          appId: publicForm.appId,
          formId: publicForm.formId,
          hiddenFieldIds: publicForm.hiddenFieldIds,
          layout: publicForm.layout,
          publicFormId: publicForm._id,
        },
      };

      this.$store.dispatch("AUTH_POST", postData);
    },
    deleteForm(publicForm) {
      this.$refs.ConfirmDeleteDialog.confirm(() => {
        const postData = {
          urlCommand: "/publicForm/deleteForm",
          data: {
            appId: publicForm.appId,
            formId: publicForm.formId,
            publicFormId: publicForm._id,
          },
        };
        this.$store.dispatch("AUTH_POST", postData).then((res) => {
          if (res.result) {
            const index = this.publicForms.findIndex(
              (form) => form._id === publicForm._id
            );

            if (index > -1) {
              this.publicForms.splice(index, 1);
              this.track({key:"plus_public_publishing_function", data:{
                function_button: this.$sensors.events.plus_public_publishing_function.DELETE
              }});
            }
          }
        });
      });
    },
    getForm() {
      this.loading = true;

      const postData = {
        urlCommand: "/publicForm/getPublicForm",
        data: {
          appId: this.$route.params.id,
          formId: this.$route.params.itemId,
        },
      };
      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        this.publicForms = res;

        this.loading = false;
      });
    },
    refreshUrl(publicForm) {
      const postData = {
        urlCommand: "/publicForm/refreshURL",
        data: {
          appId: publicForm.appId,
          formId: publicForm.formId,
          publicFormId: publicForm._id,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        publicForm.urlId = res.id;
      });
    },
    sendSaveSettingRequest(publicForm, data) {
      return new Promise((resolve, reject) => {
        const postData = {
          urlCommand: "/publicForm/saveSetting",
          data: {
            appId: publicForm.appId,
            formId: publicForm.formId,
            publicFormId: publicForm._id,
            ...data,
          },
        };

        this.$store.dispatch("AUTH_POST", postData).then((res) => {
          resolve(res);
        });
      });
    },
    async saveSetting(publicForm, setting, value, cb) {
      const res = await this.sendSaveSettingRequest(publicForm, {
        setting,
        value,
      });
      if (res.result === true) {
        this.$set(publicForm, setting, value)
          if (setting === "active"){
            this.track({
              key:"plus_public_form_activiate",
              data:{ is_default_setting:false }
            });
          }
      }

      if (typeof cb === "function") {
        cb();
      }
    },
    async saveCustomProps(publicForm, fieldId, value) {
      const res = await this.sendSaveSettingRequest(publicForm, {
        setting: "customFieldProperties",
        fieldId,
        value,
      });
      if (res.result === true) {
        if (!publicForm.customFieldProperties){
          this.$set(publicForm, 'customFieldProperties', {});
        }
        if (!publicForm.customFieldProperties[fieldId]) {
          this.$set(publicForm.customFieldProperties, fieldId, {});
        }
        this.$set(
          publicForm.customFieldProperties[fieldId],
          "alias",
          value.alias
        );
        this.$set(
          publicForm.customFieldProperties[fieldId],
          "description",
          value.description
        );
        this.$set(
          publicForm.customFieldProperties[fieldId],
          "editable",
          value.editable||false
        );
        this.$set(
          publicForm.customFieldProperties[fieldId],
          "deletable",
          value.deletable||false
        );
        this.$set(
          publicForm.customFieldProperties[fieldId],
          "uploadable",
          value.uploadable||false
        );
      }
    },
    getLayoutPosition(layout, fieldId) {
      let row = -1,
        col = -1;

      for (let i = 0; i < layout.length && col === -1; i++) {
        col = layout[i].findIndex((field) => field._id === fieldId);
        if (col !== -1) row = i;
      }

      return { row, col };
    },
    saveFieldId(publicForm, setting, value) {
      const oldId = publicForm[setting];

      if (oldId) {
        const index = publicForm.hiddenFieldIds.findIndex((id) => id === oldId);
        if (index > -1) {
          publicForm.hiddenFieldIds.splice(index, 1);
        }
      }

      this.saveSetting(publicForm, setting, value, () => {
        if (value) {
          //remove from layout, add to hidden

          if (publicForm.hiddenFieldIds.indexOf(value) === -1) {
            publicForm.hiddenFieldIds.push(value);
          }

          const { row, col } = this.getLayoutPosition(publicForm.layout, value);
          if (row !== -1 && col !== -1) {
            publicForm.layout[row].splice(col, 1);
            if (publicForm.layout[row].length === 0) {
              publicForm.layout.splice(row, 1);
            }
          }
        }
        this.saveForm(publicForm);
      });
    },
    addForm({ label }) {
      const postData = {
        urlCommand: "/publicForm/addForm",
        data: {
          appId: this.$store.getters.currentForm.appId,
          formId: this.$store.getters.currentForm._id,
          label,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        if (res) {
          this.publicForms.push(res);
          this.track({key:"plus_add_public_form", type:"FORM"});
        }
      });
    },
    openDialog(ref) {
      this.$refs[ref].open();
    },
    addTextField({label}){
      this.$store.dispatch("APPEND_WIDGET_TO_LAYOUT", {
        widgetKey: "text",
        options: {
          label,
          properties: {
            fieldName: label,
          },
        },
      });

      this.$store.dispatch("SAVE_FORM").then(() => {
        const field = this.$store.getters.formFieldInfos.find(
          (field) => field.label === label
        );
        if (field) {
          this.updateForm(field._id);
        }
      });
    }
  },
};
</script>
