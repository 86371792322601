<template>
  <div class="d-flex flex-column team-config" style="height:100%; overflow:hidden">
    <div class="py-3 d-flex align-center grey lighten-4">
      <v-btn class="ml-3" icon @click="closeConfig()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span
        v-if="!configId"
        class="ml-3"
        style="font-size:15px"
      >{{ $t('settings.editTeamConnection') }}</span>
      <span v-else class="ml-3" style="font-size:15px">{{$t('setting.viewTeamConnection')}}</span>
    </div>
    <v-form ref="teamConfigForm" style="overflow: auto" class>
      <div class="my-4 px-5">
        <div class="pt-3 d-flex flex-column" style="width:100%;">
          <team-card
            :teamCode="team.teamInfo.code"
            :teamName="team.teamInfo.name"
            :email="team.registeredEmail"
          ></team-card>
          <div class="mt-5">
            <h3 class="mt-2">{{ $t('settings.optionalRefInfoBelow') }}</h3>
            <hr class="theme-light mt-1 mb-5" style="border-color:rgba(100,100,100,.1);" />            
          </div>

          <div>
            <table class="flex-grow-0">
              <tr v-for="(row) in optionalRows" :key="row.key">
                <td class="pe-3" style="text-align:right;vertical-align:top;">{{ $t(row.labelTag) }}</td>
                <td :style="row.inputWidth?'width:'+row.inputWidth+'px':''">
                  <v-text-field
                    :rules="getRules(row)"
                    outlined
                    tile
                    height="20"
                    class="rounded-0 mailTextField"
                    :class="fieldIndex!=0? '':''"
                    dense
                    v-model="teamSetting[row.key]"
                    :placeholder="$t(row.placeholderTag)"
                    style="font-size:12px;"
                  />
                </td>
                <td :rowspan="optionalRows.length"></td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </v-form>
    <div class="mt-auto grey lighten-4 d-flex justify-end">
      <div class="d-flex mr-5">
        <div>
          <v-btn text @click="closeConfig">{{$t('buttons.cancel')}}</v-btn>
        </div>
        <div>
          <v-btn text :disabled="!changed" color="teal" @click="saveConfig">
            <span v-if="configId==0">{{$t('general.new')}}</span>
            <span v-else>{{$t('buttons.save')}}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import teamCard from '../comps/TeamCard';

export default {
  name: "teamConfigEdit",
  components: {
    teamCard
  },
  data() {
    return {
      teamSetting: {
        contact: "",
        contactTelNo: ""
      },
      optionalRows: [
        {
          labelTag: "settings.contact",
          key: "contact",
          value: "",
          type: "text",
          placeholderTag: "settings.contact",
          required: false,
          inputWidth: 320
        },
        {
          labelTag: "settings.contactTelNo",
          key: "contactTelNo",
          value: "",
          type: "text",
          placeholderTag: "settings.contactTelNo",
          required: false,
          inputWidth: 320
        }
      ],
      visible: true,
      verifying: false,
      validInput: false,
      validTeam: false
    };
  },

  // watch: {
  //   team: {
  //     handler: function(newVal) {
  //       console.log('watch(team) newval: ', newVal);
  //       this.teamSetting.contact = newVal ? newVal.contact : "";
  //       this.teamSetting.contactTelNo = newVal ? newVal.contactTelNo : "";
  //     },
  //     deep: true
  //   }
  // },
  mounted() {
    this.teamSetting.contact = this.team ? this.team.contact : "";
    this.teamSetting.contactTelNo = this.team ? this.team.contactTelNo : "";
    console.log('mounted');
  },
  props: {
    team: null
  },
  computed: {
    changed () {
      return this.team.contact !== this.teamSetting.contact ||
        this.team.contactTelNo !== this.teamSetting.contactTelNo;
    },
    activeTeamId() {
      return this.$store.getters.activeTeamId;
    },
    RULE_NUMBER() {
      return v =>
        Number.isInteger(Number(v)) || this.$t("mailSetting.ruleInteger");
    },

    RULE_TEXT() {
      return v =>
        (v && v.trim().length <= 50) || this.$t("mailSetting.ruleText");
    },

    RULE_EMAIL() {
      return v => /.+@.+\..+/.test(v) || !v || this.$t("mailSetting.ruleEmail");
    },

    requiredAuth() {
      return this.rows
        .flat()
        .find(field => field.key == "requireAuthentication").value;
    },
    inputChanged() {
      return (
        this.teamSetting.companyName !== this.lastTeamSetting.companyName ||
        this.teamSetting.registeredEmail !==
          this.lastTeamSetting.registeredEmail ||
        this.teamSetting.teamCode !== this.lastTeamSetting.teamCode
      );
    }
  },
  methods: {
    getRegisteredContact(teamInfo) {
      var result = null;
      if (teamInfo.employees && teamInfo.employees.length > 0) {
        result = teamInfo.employees[0];
      }
      return result;
    },

    // getContactEmail (employee) {
    //   return employee ? employee.email : '';
    // },

    getContactName(employee) {
      let resultSegs = [];
      console.log(
        "getContactName: employee.employeePersonalInfo: ",
        employee.employeePersonalInfo
      );
      if (employee.employeePersonalInfo.firstName)
        resultSegs.push(employee.employeePersonalInfo.firstName.trim());

      if (employee.employeePersonalInfo.lastName)
        resultSegs.push(employee.employeePersonalInfo.lastName.trim());

      console.log("getContactName: resultSegs: ", resultSegs);
      const result = resultSegs.join(" ");
      console.log("getContactName result: ", result);

      return result;
    },

    getContactTelNo(employee) {
      var result = "";
      if (employee.officePhone && employee.officePhone !== "") {
        result = employee.officePhone;
      } else if (employee.mobile && employee.mobile !== "") {
        result = employee.mobile;
      }
      console.log("getContactTelNo: result: ", result);
      return result;
    },

    closeConfig() {
      this.$emit("onCommand", {
        command: "cancel"
      });
    },

    saveConfig() {
      this.$emit("onCommand", {
        command: "updateContact",
        data: {
          _id: this.team._id,
          contact: this.teamSetting.contact,
          contactTelNo: this.teamSetting.contactTelNo
        }
      });
    },
    getRules(field) {
      var rules = [];
      let vm = this;
      if (field.required) {
        rules.push(v => !!v || vm.$t("mailSetting.ruleRequired"));
      }
      switch (field.type) {
        case "number":
          rules.push(this.RULE_NUMBER);
          break;
        case "text":
          rules.push(this.RULE_TEXT);
          break;
        case "email":
          rules.push(this.RULE_EMAIL);
          break;
      }
      return rules;
    }
  }
};
</script>

<style lang="scss">
.team-config .switch {
  position: relative;
  display: inline-block;
}

/* Hide default HTML checkbox */
.team-config .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.team-config .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 22px;
  width: 58px;
}

.team-config .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 25px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-config input:checked + .slider {
  background-color: $success;
}

.team-config input:focus + .slider {
  box-shadow: 0 0 1px $success;
}

.team-config input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.team-config .mailTextField .v-input__control .v-input__slot {
  min-height: 22px !important;
}
</style>