<template>
  <div>
    <div v-if="displayMode === 'list'" class="workflow-customized-action-buttons-list view-settings-table-settings fill-height flex-grow-1 d-flex flex-column">
      <div class="px-3 py-1">
        <div class="d-flex">
          <div class="mr-3 teal lighten-2" style="padding: 2px"/>
          <h4>{{ $t('workflow.events.actionProcess') }}</h4>
        </div>
        <div class="workflow-customized-action-buttons-list-remark">
          {{ $t('workflow.modules.actionProcess.remark.intro') }}
        </div>
        <div>
          <DropList
            :items="buttons"
            class="list"
            @reorder="$event.apply(buttons)"
          >
            <template v-slot:item="{ item }">
              <Drag class="item" :key="item">
                <ListItem
                  :item="item"
                  :form="form"
                  @onSelect="onSelect"
                  :view="view"
                />
              </Drag>
            </template>
          </DropList>
          <!-- <ListItem
            v-for="item in buttons"
            :key="item._id"
            :item="item"
            :form="form"
            @onSelect="onSelect"
          /> -->
        </div>
        <v-btn
          text color="accent"
          class="workflow-customized-action-button-list-add"
          id="addCustomizedActionBtn"
          v-bind="attrs"
          v-on="on"
          @click="allButtons&&allButtons.length!==0?showingSelectButtonDialog=true:onSelect()"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          <span :style="{ color: $vuetify.theme.themes.light.primary }">
            {{ $t('workflow.modules.actionProcess.label.insertNew') }}
          </span>
        </v-btn>
        <v-menu
          attach="#addCustomizedActionBtn" 
          offset-y 
          elevation-0 
          v-model="showingSelectButtonDialog"
          content-class="actionButtonList"

        >
          <v-list dense class="pa-0">
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-for="btn in allButtons"
                :key="btn._id"
                @click="handleListItemClicked(btn)"
              >
                
                <v-list-item-title v-if="btn._id!=='NEW_BUTTON'">{{btn.name}}</v-list-item-title>
                <v-list-item-title v-else>
                  <v-icon size="20" left color="primary">
                    mdi-plus
                  </v-icon>
                  <span :style="{ color: $vuetify.theme.themes.light.primary }">
                    {{ $t('workflow.modules.actionProcess.label.insertNew') }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div 
      v-if="displayMode === 'setting'"
      class="fill-height flex-grow-1 d-flex flex-column"
      :style="{ margin: '-22px -22px 0 -25px' }"
    >
      <v-app-bar
        flat
        style="width:100%; position: sticky; z-index: 999; top: 0;"
      >
        <v-btn icon @click="onBackToList">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t(`workflow.modules.actionProcess.label.${!!selected ? 'editOne' : 'insertNew'}`) }}
        </v-toolbar-title>
      </v-app-bar>
      <ButtonSetting 
        :form="form"
        :item="selected"
        @back="onBackToList"
      /> 
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus.js'
import { Drag, DropList } from "vue-easy-dnd";
import ListItem from './listItem';
import ButtonSetting from './../setting/';
import {
  FETCH_ACTION_BUTTON,
  REORDER_ACTION_BUTTON,
  APPEND_ACTION_BUTTON
} from '@/store/modules/customizedAction/action_types';
export default {
  props: {
    form: Object,
    mode: {
      type: String,
      default: 'list',
    },
    selectedItem: Object,
    view: Object
  },
  data() {
    return {
      selected: this.selectedItem,
      displayMode: this.mode,
      showingSelectButtonDialog: false
    };
  },
  components: {
    ListItem,
    DropList,
    ButtonSetting,
    Drag,
  },
  computed: {
    loading() {
      return this.$store.getters.interacting;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    buttons() {
      return this.$store.getters.getActionButtons;
    },
    allButtons(){
      let buttons = []
      buttons =  this.$store.getters.getAllActionButtons
      if(buttons){
        if(this.buttons){
          const viewBtnIds = this.buttons.map(btn=>btn._id)
          buttons = buttons.filter(btn=>!viewBtnIds.includes(btn._id))
        }
      }
      if(buttons.length>0){
        buttons.push({
          _id: 'NEW_BUTTON',
          name: 'new_btn'
        })
      }
      return buttons
    }
  },
  methods: {
    // onChangeOrdering({ from, to }) {
    //   // const { buttons } = this;
    //   // const toEle = buttons[to];
    //   // buttons.splice(to, 1, buttons[from]);
    //   // buttons.splice(from, 1, toEle);
    //   console.log(this.$event);
    // },
    async handleListItemClicked(button){
      if (button._id === 'NEW_BUTTON'){
        this.onSelect()
      }else{
        const { viewId } = this.$route.params;
        this.$store.dispatch(APPEND_ACTION_BUTTON, {
          appId: this.currentApp._id,
          formId: this.form._id,
          viewId,
          button
        }).then(res=>{
          EventBus.$emit('appendActionButton', res)
          this.showingSelectButtonDialog = false
        })
      }
    },
    onSelect(item) {
      this.selected = item;
      this.displayMode = 'setting';
    },
    onBackToList() {
      this.selected = null;
      this.displayMode = 'list';
    },
  },
  created() {
    const { viewId } = this.$route.params;
    this.$store.dispatch(FETCH_ACTION_BUTTON, {
      appId: this.currentApp._id,
      formId: this.form._id,
      viewId,
      withHide: true,
    });
    this.$store.dispatch(FETCH_ACTION_BUTTON, {
      appId: this.currentApp._id,
      formId: this.form._id,
      viewId: null,
      withHide: true,
    });
  },
  watch: {
    buttons: {
      deep: true,
      handler(buttons) {
        this.$store.dispatch(REORDER_ACTION_BUTTON, {
          appId: this.currentApp._id,
          formId: this.form._id,
          buttons,
        });
      },
    },
  }
}
</script>

<style scoped>
  .actionButtonList{
    max-width: 100% !important;
    width: 100% !important;
  }
</style>