<template>
    <v-navigation-drawer app temporary v-model="$store.state.mobile.sortingDrawerOpened" height="auto"  bottom style="z-index:1002" class="rounded-t-xl">
        <div class="py-3 px-5 sorting-drawer-header d-flex justify-space-between">
            <div>{{ $t('view.sorting') }}</div>
            <div><v-btn small text @click="closeDialog">{{ $t('buttons.close') }}</v-btn></div>
        </div>
        <div class="px-5 py-3 sorting-drawer-body">
            <div v-if="sortableFields.length === 0" class="my-5 text-center">
                {{ $t('view.noSortingOptionsFound') }}
            </div>
            <div v-else>
                <div v-for="(field, index) in sortableFields" :key="field._id" class="mb-1">
                    <v-btn rounded :outlined="field.sortMode === ''" @click="sortField(index)" class="elevation-0" :color="(field.sortMode !== '') ? '#5FC5CE': ''" :dark="(field.sortMode !== '')?true:false">
                        {{ field.label }} 
                        <span v-if="field.sortMode != ''">: {{ $t(`view.sorting${field.sortMode}`) }}</span>
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="sorting-drawer-footer py-3 px-5 d-flex w-100 justify-space-between">
            <v-btn block outlined @click="reset">{{ $t('buttons.reset') }}</v-btn>
            <v-btn block class="elevation-0" dark color="#2B425E" @click="apply">{{ $t('buttons.apply') }}</v-btn>
        </div>
    </v-navigation-drawer>
</template>

<script>
    export default {
        props: [ 'sortableFields' ],
        computed: {
            targetField() {
                let _result = this.sortableFields.find(field => field.sortMode !== '');
                if ( _result ) {
                    return {
                        fieldId: _result._id,
                        sortMode: _result.sortMode
                    }
                } else {
                    return {
                        fieldId: undefined,
                        sortMode: undefined
                    }
                }
            }
        }, 
        methods: {
            sortField(index) {
                let _fields = [ ...this.sortableFields ];
                let targetFieldId = _fields[index].fieldId;
                switch( _fields[index].sortMode ) {
                    case 'asc':
                        _fields[index].sortMode = 'desc';
                        break;
                    case 'desc':
                        _fields[index].sortMode = '';
                        break;
                    default:
                        _fields[index].sortMode = 'asc';
                }
                _fields = _fields.map( field => {
                    if ( field._id != targetFieldId ) {
                        field.sortMode = '';
                    }
                    return field;
                })
                this.sortableFields = [ ..._fields ]
            },
            reset() {
                let _fields = [ ...this.sortableFields ];
                _fields.map( field => {
                    field.sortMode = '';
                    return field;
                } )
                this.sortableFields = [ ..._fields ];
                this.apply();
            },
            apply() {
                this.$parent.sortingField = this.targetField;
                this.$parent.data = [];
                this.$parent.currentPage = 1;
                this.$parent.fetchData({ appId: '', formId: '', viewId: ''});
                this.$store.state.mobile.sortingDrawerOpened = false;
            },
            closeDialog() {
                this.$store.state.mobile.sortingDrawerOpened = false
            }
        }
    }
</script>

<style lang="scss">
    .sorting-drawer-header {
        background: $grey2;
        line-height: 25.6px;
        font-weight: 500;
        font-size: 16px;
    }
    .sorting-drawer-footer {
        background: $grey2;
        gap: 1rem;
    }
    .sorting-drawer-body {
        max-height: 30vh;
        overflow-y: auto;
    }
</style>