<template>
<div class="image-wrapper"
    :style="wrapperStyle">
    <v-img
        v-if="mediaId"
        class="card-image position-relative"
        width="100%!important"
        height="100%!important"
        :src="imageSrc"
        :class="imageClass"
        :contain="cardConfig.cardImageMode!=='cover'">
      <zoom-preview
        :mediaId="mediaId"
        style="position:absolute;"
        class="fill-height w-100"></zoom-preview>
    </v-img>
    <div v-else class="fill-height w-100 d-flex flex-column align-center pt-1">
    <v-icon large style="opacity:0.5;">mdi-file-question</v-icon>
    </div>
</div>    
</template>

<script>
import zoomPreview from '@/components/zoomPreview'

export default {
  name: 'cardImage',
  components: {
    zoomPreview
  },
  props: {
    mediaId: String,
    cardConfig: Object
  },
  computed: {
    imageSrc() {
      const vm = this
      var result = ''
      if (vm.mediaId) {
        result = vm.$store.getters.appHost + '/medias/' + vm.mediaId
      }
      return result
    },
    isRegular () {
      const vm = this
      const pos = vm.cardConfig.cardImagePosition
      const stretch = vm.cardConfig.cardImageStretch
      var result = 
        (pos !== 'top') &&
        !(pos === 'left' && stretch) &&
        !(pos === 'right' && stretch)
      return result
    },
    imageClass () {
            const vm = this
            var result = ''
            if (vm.isRegular) {
                switch (vm.cardConfig.cardImageMode) {
                    case 'circle':
                        result = 'circle-image'
                        break
                    case 'square':
                        result = 'square-image'
                        break
                }
            }
            return result
        },
        wrapperStyle () {
            const vm = this
            var result = {}
            const imageSizePx = vm.cardConfig.cardImageSize + 'px'
            switch (vm.cardConfig.cardImagePosition) {
                case 'left':
                    result['width'] = imageSizePx
                    result['height'] = vm.cardConfig.cardImageStretch ? '100%' : imageSizePx
                    result['margin-right'] = vm.cardConfig.cardImageStretch ? '0' : '4px'
                    break
                case 'top':
                    result['width'] = '100%'
                    result['height'] = imageSizePx
                    break
                case 'right':
                    result['width'] = imageSizePx
                    result['height'] = vm.cardConfig.cardImageStretch ? '100%' : imageSizePx
                    result['margin-left'] = vm.cardConfig.cardImageStretch ? '0' : '4px'
                    break
            }
            if (vm.cardConfig.cardImagePadding && vm.cardConfig.cardImagePadding>0) {
                result['padding'] = vm.cardConfig.cardImagePadding + 'px'
            }

            return result
        }
        // ,
        // hasCardImage () {
        //     return this.cardConfig.cardImageFieldId && this.cardConfig.cardImageFieldId !== ''
        // },

        // topImage () {
        //     return this.hasCardImage && this.cardConfig.cardImagePosition === 'top'
        // },

        // leftImage () {
        //     return this.hasCardImage && this.cardConfig.cardImagePosition === 'left'
        // },

        // rightImage () {
        //     return this.hasCardImage && this.cardConfig.cardImagePosition === 'right'
        // },

        // stretchImage () {
        //     return this.hasCardImage && this.cardConfig.cardImageStretch
        // }
    }
}
</script>
<style>
  .card-image {
    background-color: rgba(0, 0, 0, .05);
  }

  .circle-image {
/*     width: 80px !important;
    height: 80px !important; */
    border-radius: 50% !important;
  }

  .square-image {
/*     width: 80px !important;
    height: 80px !important;
 */    border-radius: 0 !important;
  }

</style>