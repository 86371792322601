<template>
    <v-layout align-center justify-center>
        <div class="w-pending-trigger-setup-btn"
            :class="{ blocked: isBlockedUI }"
            @click="openEdit"
            :style="{
                color: $vuetify.theme.themes.light.primary,
                border: `1px solid ${$vuetify.theme.themes.light.primary}`,
            }"
        >
            {{ $t(pendingLabel) }}
        </div>
    </v-layout>
</template>

<script>

import {
  EDITING_NODE,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms Copncrete View Components
* Terry Chan
* 28/05/2021
*/
export default {
    props: {
        pendingLabel: String,
        node: Object,
        // root: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    computed: {
        isBlockedUI() {
            return this.$store.getters.isUIBlocked;
        },
        editingNode() {
            return this.$store.getters.getEditingNode;
        }
    },
    methods: {
        openEdit() {
            if (!this.isBlockedUI && !this.editingNode) {
                this.$store.dispatch(EDITING_NODE, this.node);
            }
        }
    },
};

</script>

<style scoped>
    .w-pending-trigger-setup-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        height: 30px;
        font-weight: 700;
        border-radius: 18px;
        margin: 6px auto;
        font-size: 13px;
        width: 200px;
        box-sizing: border-box;
        background: #e6f4ff;
        cursor: pointer;
    }
    /* .w-pending-trigger-setup-btn:hover {
        color: #fff;
        background: #2196f3;
    } */
</style>
