import {app} from '@/main'
import {panels as mutations} from "../../mutation-types"
import {panels as types} from "../../action-types"

export default {
  [types.SET_CURRENT_PANEL_PROPERTY]({commit}, payload) {
    commit('SET_CURRENT_PANEL_PROPERTY', payload)
  },

  [types.FETCH_PANEL] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      // console.log('FETCH_FORM :: payload: ', payload)
      var panelId
      if (payload && payload.panelId) {
        panelId = payload.panelId
      } else {
        panelId = localStorage.getItem('panelId')
      }
      if (!panelId || panelId === '') {
        reject('Panel Id Undefined')
      }

      const getData = {
        urlCommand: '/panels/' + panelId
      }

      dispatch('AUTH_GET', getData).then(
        async response => {
          commit(mutations.SET_CURRENT_PANEL, response.result)
          console.log('FETCH_PANEL: response: ', response)
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  }
}
