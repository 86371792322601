import moment from "moment";
import CountryCodes from "@/const/countryCodes";
import PhoneNumber from "awesome-phonenumber";
import formInputMixin from "./components/formInput/mixin";
import ValidationsHelper from "@/helpers/ValidationsHelper";

import { VTextField, VSelect, VSwitch } from "vuetify/lib";
import DialogFieldDate from "@/components/dialogFields/DialogFieldDate";
import InputWidgetToggle from "@/components/inputWidgets/comps/InputWidgetToggle";
import Selection from "./components/formInput/selection";
import Department from "./components/formInput/department";
import TextField from "./components/formInput/textField";
import TelInputField from "@/components/form/TelInputField";
const dialCodes = [
  "hk",
  "cn",
  "mo",
  "tw",
  "jp",
  "kr",
  "sg",
  "th",
  "my",
  "us",
  "ca",
  "au",
  "gb",
  "de",
  "fr",
  "ru",
  "in",
  "nz",
  "it",
  "nl",
  "es",
  "se",
  "at",
  "ch",
  "lt",
  "ph",
  "id",
  "vn",
  "ae",
  "lu",
  "kh",
];

const mixin = {
  components: {
    VTextField,
    VSelect,
    Selection,
    Department,
    TextField,
    InputWidgetToggle,
    DialogFieldDate,
    TelInputField,
    VSwitch,
  },
  mixins: [formInputMixin],
  data() {
    return {
      phoneItems: CountryCodes.filter((country) =>
        dialCodes.includes(country.iso2)
      ),

      existingEmail:()=> true || this.emailValidation["error-messages"],
      departments: [],
      jobTitles: [],

      showingDeptDialog: false,
      showingJobTitleDialog: false,

      phoneValidation: { hasError: false, errorMsg: "" },
      emailValidation: { error: false, "error-messages": "" },
      employeeNumberValidation: { error: false, "error-messages": "" },
    };
  },
  computed: {
    departmentList() {
      const result = [];
      this.departments.forEach((dept) => {
        this.flattenDeptRecursion(dept, result);
      });
      return result;
    },
    attributes() {
      return new Map([
        [
          "lastName",
          {
            key: "lastName",
            label: this.$t("workAdmin.lastName"),
            type: "VTextField",
            rules: [
              (v) => this.RULE_REQUIRED(v),
              (v) => this.RULE_LENGTH_LIMIT(v, 50),
            ],
            counter: 50,
          },
        ],
        [
          "firstName",
          {
            key: "firstName",
            label: this.$t("workAdmin.firstName"),
            type: "VTextField",
            rules: [
              (v) => this.RULE_REQUIRED(v),
              (v) => this.RULE_LENGTH_LIMIT(v, 50),
            ],
            counter: 50,
          },
        ],
        [
          "email",
          {
            key: "email",
            label: this.$t("workAdmin.email"),
            type: "TextField",
            rules: [
              (v) => this.RULE_REQUIRED(v),
              (v) => this.RULE_EMAIL(v),
              (v) => this.RULE_LENGTH_LIMIT(v, 100),
              this.existingEmail
            ],
            counter: 100,
            messages:this.emailValidation['error-messages'],
            on: {
              input: this.resetEmail,
              keyup: this.checkExistEmail
            },
          },
        ],
        [
          "mobile",
          {
            key: "mobile",
            label: this.$t("widgets.phone"),
            type: "TelInputField",
            showCountryCode: true,
            isWorkflow: false,
            items: this.phoneItems,
            on: {
              blur: this.validatePhone,
              input: this.resetPhone,
            },
            errorMsg: this.phoneValidation.errorMsg,
            rules: [(v) => this.validatePhone(v)],
          },
        ],
        [
          "birth",
          {
            key: "birth",
            label: this.$t("workAdmin.dob"),
            type: "DialogFieldDate",
            isWorkflow: false,
            attrs: {
              max: moment().format("yyyy-MM-DD"),
            },
            rules: [(v) => this.RULE_REQUIRED(v)],
          },
        ],
        [
          "gender",
          {
            key: "gender",
            label: this.$t("workAdmin.gender"),
            type: "InputWidgetToggle",
            options: [
              {
                _id: "M",
                label: this.$t("general.male"),
              },
              {
                _id: "F",
                label: this.$t("general.female"),
              },
            ],
          },
        ],
        [
          "displayName",
          {
            key: "displayName",
            label: this.$t("workAdmin.employeeName"),
            type: "VTextField",
            rules: [
              (v) => this.RULE_REQUIRED(v),
              (v) => this.RULE_LENGTH_LIMIT(v, 100),
            ],
            counter: 100,
          },
        ],
        [
          "employeeNumber",
          {
            key: "employeeNumber",
            label: this.$t("workAdmin.employeeNumber"),
            type: "TextField",
            rules: [
              (v) => this.RULE_REQUIRED(v),
              (v) => this.RULE_LENGTH_LIMIT(v, 20),
            ],
            counter: 20,
            error: this.employeeNumberValidation.error,
            "error-messages": this.employeeNumberValidation["error-messages"],
            on: {
              blur: this.validateEmployeeNumber,
              input: this.resetEmployeeNumber,
            },
          },
        ],
        [
          "joinedDate",
          {
            key: "joinedDate",
            label: this.$t("workAdmin.joinedDate"),
            type: "DialogFieldDate",
            isWorkflow: false,
            attrs: {
              max: moment().format("yyyy-MM-DD"),
            },
            rules: [(v) => this.RULE_REQUIRED(v)],
          },
        ],
        [
          "departmentIds",
          {
            key: "departmentIds",
            label: this.$t("widgets.department"),
            type: "Department",
            items: this.departments,
            hasAdd: true,
            multiple: true,
            rules: [(v) => this.RULE_REQUIRED(v)],
          },
        ],
        [
          "departmentId",
          {
            key: "departmentId",
            label: this.$t("workAdmin.mainDept"),
            type: "VSelect",
            items: [],
            "item-value": "id",
            "item-text": "name",
            "menu-props": { offsetY: true },
            rules: [(v) => this.RULE_REQUIRED(v)],
          },
        ],
        [
          "jobTitleId",
          {
            key: "jobTitleId",
            label: this.$t("workAdmin.position"),
            type: "Selection",
            items: this.jobTitles,
            "item-value": "id",
            "item-text": "name",
            "menu-props": { offsetY: true },
            hasAdd: true,
            rules: [(v) => this.RULE_REQUIRED(v)],
            on: {
              new: () => (this.showingJobTitleDialog = true),
            },
          },
        ],
        [
          "invitation",
          {
            key: "invitation",
            title: this.$t("workAdmin.invitation"),
            type: "VSwitch",
            required: false,
            hideDetails: true,
          },
        ],
      ]);
    },
  },
  methods: {
    fetchDepartments() {
      const payload = {
        url: "/work/departments/department_tree",
        method: "get",
      };
      this.$store.dispatch("YOOV_REQUEST", payload).then((res) => {
        if (res.data) {
          this.departments = res.data;
        }
      });
    },
    fetchJobTitles() {
      const payload = {
        url: "/work/job_titles/all",
        method: "get",
      };
      this.$store.dispatch("YOOV_REQUEST", payload).then((res) => {
        if (res.data) {
          this.jobTitles = res.data;
        }
      });
    },
    flattenDeptRecursion(dept, result = []) {
      result.push(dept);
      if (dept.children.length > 0) {
        dept.children.forEach((child) => {
          this.flattenDeptRecursion(child, result);
        });
      }
    },
    addDept(newDept) {
      const payload = {
        url: "/work/departments",
        method: "post",
        data: newDept,
      };

      this.$store.dispatch("YOOV_REQUEST", payload).then(async () => {
        await this.fetchDepartments();
        this.showingDeptDialog = false;
      });
    },
    addJobTitle(newTitle) {
      const payload = {
        url: "/work/job_titles",
        method: "post",
        data: newTitle,
      };

      this.$store
        .dispatch("YOOV_REQUEST", payload)
        .then(async () => {
          await this.fetchJobTitles();
          this.showingJobTitleDialog = false;
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message);
        });
    },
    extractPhoneInfo(val) {
      const result = {
        mobile: "",
        mobilePrefix: "",
      };
      const phoneNumber = new PhoneNumber(val);
      if (phoneNumber.isValid()) {
        result["mobile"] = phoneNumber.getNumber("significant");
        result["mobilePrefix"] = phoneNumber.getCountryCode().toString();
      }
      return result;
    },
    back() {
      this.$router.push({
        name: "workAdminEmployees",
      });
    },
    resetEmail() {
      this.existingEmail = true
      this.emailValidation["error-messages"] = ""
    },
    resetPhone() {
      this.phoneValidation["hasError"] = false;
      this.phoneValidation["errorMsg"] = "";
    },
    validatePhone(v) {
      //check format validity
      if (this.readonly && this.readonly["contactInfo"]) {
        return true;
      }
      if (!v) {
        this.phoneValidation["hasError"] = true;
        this.phoneValidation["errorMsg"] = this.$t(
          "messages.thisFieldIsRequired"
        );
        return false;
      }
      const result = ValidationsHelper.isValidPhone(v);
      if (!result) {
        this.phoneValidation["hasError"] = true;
        this.phoneValidation["errorMsg"] = this.$t("messages.invalidPhone");
        return false;
      }
      //check duplicate
      const phoneNumber = new PhoneNumber(v);

      const payload = {
        url: "/work/employees/check_telephone",
        method: "get",
        params: {
          mobilePhone: phoneNumber.getNumber("national").replace(" ", ""),
        },
      };
      if (this.employeeId) {
        payload["params"]["employeeId"] = this.employeeId;
      }
      this.$store.dispatch("YOOV_REQUEST", payload).then((res) => {
        if (res.data === false) {
          this.phoneValidation["hasError"] = true;
          this.phoneValidation["errorMsg"] = this.$t("messages.hasDuplicate");
          return false;
        } else {
          return true;
        }
      });
    },
    async checkExistEmail(v){
      if (this.$refs["email"][0].hasError) return false
      const payload = {
        url: "/work/employees/check_email",
        method: "get",
        params: {
          email: v,
        },
      };
      if (this.employeeId) {
        payload["params"]["employeeId"] = this.employeeId;
      }

      const res = await this.$store.dispatch("YOOV_REQUEST", payload)
      if (res.data === false) {
        this.existingEmail = false
        this.emailValidation["error-messages"] = this.$t(
          "messages.hasDuplicate"
        );
      } else {
        this.existingEmail = true
        this.emailValidation["error-messages"] = ""
      }
      return res.data
    },

    validateEmployeeNumber(v) {
      if (
        (this.readonly && this.readonly["workInfo"]) ||
        !this.$refs.employeeNumber[0].$refs["textField"].valid
      ) {
        return;
      }
      const payload = {
        url: "/work/employees/check_employee_no",
        method: "get",
        params: {
          employeeNumber: v,
        },
      };
      if (this.employeeId) {
        payload["params"]["employeeId"] = this.employeeId;
      }
      this.$store.dispatch("YOOV_REQUEST", payload).then((res) => {
        if (res.data === false) {
          this.employeeNumberValidation["error"] = true;
          this.employeeNumberValidation["error-messages"] = this.$t(
            "messages.hasDuplicate"
          );
          return;
        }
      });
    },
    resetEmployeeNumber() {
      this.employeeNumberValidation["error"] = false;
      this.employeeNumberValidation["error-messages"] = "";
    },
  },
};

export default mixin;
