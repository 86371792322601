<template>
  <v-navigation-drawer
    v-model="isOpen"
    fixed
    left
    :width="drawerWidth"
    class="w-scrolling-form-drawer"
    hide-overlay
  >
    <v-app-bar
      :color="node.color"
      absolute
      dense
      flat
      height="65"
      class="w-scrolling-form-scrol-header"
      scroll-target="#scrolling-form-edit"
    >
      <v-layout justify-space-between align-center class="w-scrolling-form-scrol-header-wrapper">
        <v-icon color="white" class="w-scrolling-form-scrol-header-icon">{{ node.icon }}</v-icon>
        <div class="w-modules-header">
          <EditableNodeName :inline="true" :name="node.name" :editing="editing" @edited="onEdited" @edit="onEdit"  />
        </div>
        <div>
          <v-layout justify-end align-center>
            <!-- <v-btn icon>
              <v-icon color="white" small>mdi-help-circle </v-icon>
            </v-btn> -->
            <v-icon class="w-module-close-btn" color="white" @click="toggle">mdi-close</v-icon>
          </v-layout>
        </div>
      </v-layout>
    </v-app-bar>
    <v-sheet
      id="scrolling-form-edit"
      class="overflow-y-auto w-scrolling-form-edit"
      ref="scrollArea"
      max-height="100%"
    >
      <v-container v-if="isOpen" class="w-module-container">
        <SettingProvider>
          <SettingConsumer>
            <SettingForm />
          </SettingConsumer>
        </SettingProvider>
      </v-container>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import {
  EDITING_NODE,
} from '@/store/modules/workflow/action_types';
import SettingProvider from './../tree/setting/contexts/provider';
import SettingConsumer from './../tree/setting/contexts/consumer';
import Mixin from './mixin';
import EditingMixin from './../tree/control/editableName/editableMixin';
// import ComboBox from './../form/comboBox/';
import SettingForm from './../tree/setting';
// import {
//   WorkflowNodeIdentify,
// } from './../../constants/node';
import EditableNodeName from './../tree/control/editableName/nodeName';

export default {
  mixins: [Mixin, EditingMixin],
  // inject: ['getEventType'],
  components: {
    EditableNodeName,
    // ComboBox,
    SettingForm,
    SettingProvider,
    SettingConsumer,
  },
  methods: {
    toggle() {
      this.$store.dispatch(EDITING_NODE, null);
    },
    keyDownHandler (event) {
      if (event.code === 'Escape' && this.isOpen) {
        this.toggle();
      }
    }
  },
  computed: {
    drawerWidth() {
      const { size='normal' } = this.node;
      const lookup = {
        normal: '650',
        large: '50%',
        small: '350',
      }
      return lookup[size] || lookup.normal;
    },
    node() {
      return this.$store.state.workflowModules.editingNode || {};
    },
    isOpen() {
      return !!this.$store.state.workflowModules.editingNode;
    },
  },
  watch: {
    isOpen: {
      handler(opened) {
        if (!opened) {
          this.$nextTick(() => {
            this.$refs.scrollArea.$el.scrollTo({ behavior: 'smooth', top: 0 });
          });
        }
      },
      deep: true,
    }
  },
};

</script>

<style scoped>
  .v-navigation-drawer {
    z-index: 9999 !important;
    margin-right: 66px;
    box-shadow: 0 10px 23px 2px rgb(0 0 0 / 20%), 0 3px 6px rgb(0 0 0 / 15%);
  }
  .w-scrolling-form-edit {
    margin-right: -20px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
  .w-module-container {
    padding: 65px 20px 55px 0;
    margin-right: 20px;
  }
  .w-modules-header {
    font-size: 18px;
    color: white;
    margin-left: 10px;
    text-align: left;
    flex: 1;
  }
  .w-module-close-btn {
    transition: all .25s ease-out;
    cursor: pointer;
  }
  .w-module-close-btn:hover {
    transform: rotate(90deg);
  }
  #scrolling-form-edit {
    min-height: 100%;
  }
  .w-scrolling-form-scrol-header {
    z-index: 99999;
    border-top-right-radius: 20px;
    overflow: hidden;
  }
  .w-scrolling-form-scrol-header-wrapper {
    position: relative;
  }
  .w-scrolling-form-scrol-header-icon {
    position: absolute;
    opacity: 0.25;
    font-size: 120px;
    left: -10px;
    z-index: -1;
    transform: rotate(330deg);
  }
  .w-scrolling-form-drawer {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
</style>
