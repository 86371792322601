<template>
  <v-menu offset-y transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        :disabled="$store.getters.isUIBlocked"
        small
      >
        <v-icon
          :color="isNode ? 'white' : 'rgb(158, 158, 158)'"
        >
          mdi-dots-horizontal
        </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.key"
        @click="$emit(item.inspector)"
      >
        <v-list-item-icon>
          <v-icon small :color="item.color">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :style="{ color: item.color }" class="w-control-menu-title">{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    isNode: {
      type: Boolean,
      default: false,
    },
    canRename: {
      type: Boolean,
      default: true,
    },
    canCopy: {
      type: Boolean,
      default: true,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let items = [
      {
        key: 'remark_edit',
        inspector: 'updateRemark',
          color: '#333',
        icon: 'mdi-square-edit-outline',
        label: this.$t('workflow.control.editRemark'),
      }
    ];
    if (this.canRename) {
      items = [
        {
          key: 'name_edit',
          inspector: 'editName',
          icon: 'mdi-pencil',
          color: '#333',
          label: this.$t('workflow.control.editName'),
        },
        ...items,
      ];
    }
    if (this.canCopy) {
      items = [
        ...items,
        {
          key: 'copy_node',
          inspector: 'copy',
          color: '#333',
          icon: 'mdi-content-copy',
          label: this.$t('workflow.control.copyNode'),
        },
      ];
    }
    if (this.canRemove) {
      items = [
        ...items,
        {
          key: 'remove_node',
          inspector: 'remove',
          icon: 'mdi-delete',
          color: '#f44336',
          label: this.$t('workflow.control.removeNode'),
        }
      ];
    }
    return {
      items,
    };
  },
  computed: {
  },
};

</script>

<style scoped>
  .v-list-item__title.w-control-menu-title {
    font-size: 13px !important;
  }
  .v-list-item__icon {
    margin-right: 5px !important;
  }
</style>
