<template>
  <div>
    <FormElementWidget
      :ref="fieldInfo.fieldId"
      :idFieldLookup="idFieldLookup"
      :recordData="recordData"
      :relatedTableData="relatedTableData"
      :fieldInfo="fieldInfo"
      :customizedFormInfo="customizedFormInfo"
      :ignoreRecordId="true"
      :involveRelationshipLabel="involveRelationshipLabel"
      :noIcon="true"
      :optionOutput="optionOutput"
      :editAnyway="true"
      :enableBranchSelect="enableBranchSelect"
      :supportJobTitleSelection="supportJobTitleSelection"
      :manySelect="manySelect"
      :hideInput="dependency"
      :withHideFields="withHideFields"
      :widgetTypeMapField="widgetTypeMapField"
      @onCommand="onCommandHandler"
    />
  </div>
</template>

<script>
import Wrapper from './wrapper';
import RecordMxiin from '@/mixins/recordMixin'
import FormElementWidget from "@/components/inputWidgets/InputWidget";
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [RecordMxiin],
  props: {
    worksheet: String,
    fieldInfo: Object,
    idFieldLookup: String,
    optionOutput: String,
    customizedFormInfo: Object,
    recordData: Object,
    involveRelationshipLabel: Boolean,
    dependency: Boolean,
    relatedTableData: Object,
    withHideFields: Boolean,
    widgetTypeMapField: String,
    enableBranchSelect: {
      type: Boolean,
      default: false,
    },
    supportJobTitleSelection: {
      type: Boolean,
      default: false,
    },
    manySelect: {
      type: Boolean,
      default: false,
    },
    // onCommandHandler: Function,
  },
  components: {
    FormElementWidget,
    // ComboBox,
    Wrapper,
  },
  // mounted() {
  //   console.log(this.recordData);
  // },
  // watch: {
  //   recordData: {
  //     handler() {
  //       console.log('>>>>>>>>>>>> ', v);
  //     },
  //     deep: true,
  //   },
  // },
};

</script>

<style scoped>
  
</style>
