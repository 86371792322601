const mixin = {
  computed: {
    relatedFormInfo () {
      const vm = this
      var result = null
      if (vm.isParentChildRelation) {
        result = {
          ...vm.formInfo,
          fieldInfos: vm.formFieldInfos
        }
      } else {
        if (vm.dataSource && vm.dataSources) {
          result = this.dataSources.find(ds => ds._id === vm.dataSource)
        }
      }
      // console.log('WitRelatedRecordDisplayFields :: computed(relatedFormInfo) :: result: ', result)
      return result
    },

    relatedTitleFieldInfo () {
      const vm = this
      var result = null
      if (vm.relatedFormInfo && vm.relatedFormInfo.titleFieldInfoId !== '') {
        const fieldInfo = vm.relatedFormInfo.fieldInfos.find(
          info => info._id === vm.relatedFormInfo.titleFieldInfoId
        )
        if (fieldInfo) {
          result = fieldInfo
        }
      }
      return result
    },

    relatedTitleFieldId () {
      const vm = this
      var result = ''

      if (vm.relatedTitleFieldInfo) {
        result = vm.relatedTitleFieldInfo.fieldId
      }
      return result
    },

    dataSources () {
      return this.$store.getters.dataSources
    },

    isRelatedMultiple () {
      return this.relatedRecordQuantity === 'multiple'
    },

    relatedRecordQuantity () {
      return this.getPropertyValue('relatedRecordQuantity', 'single')
    },

    displayFormat () { 
      let result = this.getPropertyValue('displayFormat', 'card');
      if (this.isPublic && this.isMultiple) result = 'card'
      return result
    },

    // multipleDisplayFormat () {
    //   return this.getPropertyValue('multipleDisplayFormat', 'card')        
    // },

    selectionMode () { // [popup|pulldown], default: popup
      return this.getPropertyValue('selectionMode', 'popup')
    },

    // cardSelectionMode () { // [table|card], default:table
    //   return this.getPropertyValue('cardSelectionMode', 'table')
    // },

    isParentChildRelation () {
      return this.dataSource && this.dataSource === this.formId
    }

  }
}

export default mixin
