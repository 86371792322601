<template>
  <v-dialog persistent v-model="showDialog" width="540">
    <v-card>
      <dialogHeader :label="$t('publicForm.editCustom')" @close="cancel" />

      <v-card-text>
        <v-text-field :label="$t('publicForm.fieldName')" v-model="alias">
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{on, attr}">
              <v-btn small icon @click="alias=defaultName" v-on="on" v-bind="attr">
                <v-icon small>
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>
            {{$t('publicForm.resetName')}}
          </v-tooltip>
        </template>          
        </v-text-field>
        <v-textarea
          :label="$t('publicForm.customDescription')"
          v-model="description"
          dense
          hide-details
          rows="5"
          outlined
        >
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{on, attr}">
              <v-btn small icon @click="description=defaultDescription" v-on="on" v-bind="attr">
                <v-icon small>
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>
            {{$t('publicForm.resetDescription')}}
          </v-tooltip>
        </template>
        </v-textarea>
      </v-card-text>
      <dialog-actions
        :buttons="['cancel', 'save']"
        @onCommand="onCommandHandler"
      ></dialog-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import dialogActions from "@/components/dialogs/comps/DialogActions";
export default {
  components: {
    dialogHeader,
    dialogActions,
  },
  data() {
    return {
      alias: "",
      description: "",
      fieldId: "",
      defaultDescription: "",
      defaultName:"",
      showDialog: false,
    };
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "save":
          this.confirm();
          break;
        case "cancel":
          this.cancel();
          break;
      }
    },
    refresh(){
      this.description = this.defaultDescription
    },
    open(fieldId, alias = "",defaultName="", description = "", defaultDescription="") {
      this.fieldId = fieldId;
      this.alias = alias;
      this.defaultName = defaultName
      this.description = description;
      this.defaultDescription= defaultDescription
      this.showDialog = true;
    },
    confirm() {
      this.$emit("confirm", {
        alias: this.alias,
        description: this.description,
        fieldId: this.fieldId
      });
      this.showDialog = false;
    },
    cancel() {
      this.showDialog = false;
    },
  },
};
</script>
