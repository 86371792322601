<template>
<div>
<v-btn small class="text-center"
      text relative
      style="min-width:100px !important;"
      @click="showingDialog = true">
  <div class="text-h6">{{ currentTeamName }}</div>
          <!--selectedTeamIndex = {{ selectedTeamIndex }}-->
  <v-icon small class="ml-2">mdi-arrow-down-drop-circle</v-icon>
</v-btn>
  <v-dialog
      @input="val=>$emit('input',val)"
      width="640"
      v-model="showingDialog"
      v-if="showingDialog"
      persistent
      style="max-width:98%;">
    <v-card>
      <dialog-header
        :label="dialogTitle"
        @close="showingDialog = false"
        color="#E0E0E0"
        toolbarClass="grey--text"
      />
      <!-- <v-card-title class="headline grey lighten-2"> -->
        <!-- {{ dialogTitle }} -->
      <!-- </v-card-title> -->
      <v-card-text class="d-flex flex-column pa-3">
        <v-list
            :two-line="twoLine"
            :avatar="avatar"
            dense
            style="height:480px;overflow-y:auto;">
          <!--<v-list-item-group :v-model="selectedTeamIndex" color="primary">-->
          <v-list-item-group v-model="selectedTeamIndex"
                             color="primary">
          <v-list-item v-for="(team, index) in teams"
                         :id="'item_'+index"
                         :key="index">
              <v-list-item-avatar>
                <v-img :src="getTeamLogoSrc(team)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="d-flex flex-row align-center">
                  <div v-html="team.name"></div>
                  <v-spacer></v-spacer>
                  <div v-if="userTeamIds.includes(team._id)">
                    <v-icon v-on="on" v-bind="attrs"
                      :style="{'opacity':(team.terminated ? '0.5':'1')}">mdi-account</v-icon>
                  </div>
                </div>
                <v-chip 
                  v-if="team.terminated"
                  color="error"
                  class="position-absolute"
                  label
                  style="opacity:0.8;right:50px;min-height:20px;height:20px;">{{ $t('messages.terminated')}}</v-chip>
                
                <!-- <v-list-item-title v-html="team.name"></v-list-item-title> -->
                <!-- <v-list-item-subtitle v-html="team._id"></v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <dialog-actions 
        :buttons="['close', 'confirm']"
        @onCommand="onCommandHandler($event)"
      />
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        
        <v-btn color="muted"
               @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn class="min-width-80"
               color="primary"
               @click="confirm">
          OK
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
  </div>
</template>


<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";

export default {
  components: { dialogHeader , dialogActions},
  data () {
    return {
      dialogTitle: this.$t("permissions.selectTeam"),
      showingDialog: false,
      disabled: false,
      dense: false,
      twoLine: false,
      threeLine: false,
      shaped: false,
      flat: false,
      subheader: false,
      inactive: false,
      subGroup: false,
      nav: false,
      avatar: true,
      rounded: false,
      selectedTeamIndex: -1
    }
  },
  watch: {
    'user.lastTeam': function () {
      this.updateSelectedTeamIndex()
    }
  },
  mounted () {
    const vm = this
    vm.updateSelectedTeamIndex()
    // var selectedItemRef = 'item_' + vm.selectedTeamIndex
    // console.log('mounted :: refs: ', vm.$refs)
    // vm.$nextTick(() => {
    //   var selectedTeamItem = document.getElementById('item_' + vm.selectedTeamIndex)
    //   if (selectedTeamItem) {
    //     selectedTeamItem.scrollIntoView()
    //   } else {
    //     console.log('selectedTeamItem not available')
    //   }
    // })

    // alert('team count = ' + vm.teams.length)
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentTeamName () {
      return this.$store.getters.activeTeamName
    },
    activeTeamId(){
      return this.$store.getters.activeTeamId
    },
    selectedTeam(){
      return this.selectedTeamIndex > -1 ? this.teams[this.selectedTeamIndex]: null
    },
    user () {
      return this.$store.getters.user
    },
    userTeamIds () {
      return this.user.teams.map(team => team._id)
    },
    teams () {
      return this.$store.getters.allTeamsIncludingTerminated;
    }
  },
  updated: function () {
    const vm = this

    // console.log('updated :: selectedTeamIndex = ' + vm.selectedTeamIndex)
    var elementId = 'item_' + vm.selectedTeamIndex
    // console.log('init::elementId = ' + elementId)
    var selectedTeamItem = document.getElementById(elementId)
    // console.log('updated :: selectedTeamItem: ', selectedTeamItem)
    // if (selectedTeamItem) {
    //   // console.log('=> scrollIntoView')
    //   vm.$nextTick(() => {
    //     selectedTeamItem.scrollIntoView()
    //   })
    // } else {
    //   console.log('updated :: selectedTeamItem not available')
    // }

  },
  methods: {
    onCommandHandler(payload){
      if(payload.command == 'confirm')
        this.confirm()
      this.closeDialog()
    },
    updateSelectedTeamIndex () {
      const vm = this
      vm.selectedTeamIndex = vm.user ? 
        vm.teams.findIndex(team => team._id === vm.user.lastTeam) :
        -1
    },
    getTeamLogoSrc (team) {
      const vm = this
      var result = require('@/assets/images/blank_image.jpg')
      if (team.logo && team.logo !== '') {
        result = team.logo
      }
      return result
    },
    async confirm () {
      const teamId = this.selectedTeam._id
      if (teamId && teamId !== this.activeTeamId) {
        await this.$store.dispatch("UPDATE_TEAM", teamId)
      } 
      this.closeDialog()
    },
    closeDialog () {
      const vm = this
      vm.showingDialog = false
    }
  }
}
</script>
