<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :noIcon="noIcon"
    :required="required"
    :isHidden="isHidden"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :tooltipText="description"
    class=""
  >
    <v-input v-if="isReadOnly" disabled>
      <div
        style="border-bottom: 1px dashed darkgray;min-height: 32px;"
        class="pa-1 w-100 mb-0"
      >
        <selected-department-tag
          v-for="(dept, index) in inputValue"
          :key="dept._id"
          :departmentId="dept._id"
           class="workflow-customized-form-dept-element-clip"
          :name="dept.label"
          :hasCloseButton="false"
          :index="index"
          @onCommand="onCommandHandler"
        ></selected-department-tag>
      </div>
    </v-input>
    <v-input
      v-else
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      ref="inputWidgetDepartments"
      :readonly="isReadOnly"
      @click="!isReadOnly ? (showingDialog = true) : ''"
      :rules="rules"
      :error="hasError"
      :value="inputValue"
      class="iw-departments-vinput"
    >
      <div
        class="pa-1 v-text-field--outlined w-100 mb-0 workflow-customized-form-dept-element"
        style="background-color:white"
      >
        <selected-department-tag
          v-for="(dept, index) in inputValue"
          :key="dept._id"
          :departmentId="dept._id"
          :name="dept.label"
          :index="index"
           class="workflow-customized-form-dept-element-clip"
          close
          deleteCommand="onCloseClicked"
          @onCommand="onCommandHandler"
        ></selected-department-tag>

      </div>
    </v-input>
    <DepartmentsDialog
      v-if="showingDialog"
      v-model="showingDialog"
      @confirm="inputValue = $event"
      :selectedDepts="inputValue"
      :isMultiple="isMultiple"
    />
    <!-- <pulldown-department-selection
         v-model="inputValue"
         :toggleType="fieldType">
     </pulldown-department-selection> -->
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
//import pulldownDepartmentSelection from "./comps/PulldownDepartmentSelection";
import DepartmentsDialog from "@/components/dialogs/DepartmentsDialog";
import selectedDepartmentTag from "@/components/dialogs/comps/SelectedDepartmentTag";

export default {
  name: "inputWidgetDepartments",
  mixins: [mixin],
  data() {
    return {
      RULE_REQUIRED: (v) =>
        v.length > 0 || this.$t("messages.thisFieldIsRequired"),
      showingDialog: false,
    };
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  components: {
    inputWidgetContainer,
    selectedDepartmentTag,
    //pulldownDepartmentSelection,
    DepartmentsDialog,
  },
  computed: {
    isMultiple() {
      return (
        getFieldPropertyValue(this.fieldInfo, "fieldType", "single") ===
        "multiple"
      );
    },
  },
  methods: {
    _getDefaultValue() {
      return getFieldPropertyValue(this.fieldInfo, "defaultDepartment", []);
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "onCloseClicked":
          this.onCloseClicked(payload.index);
          break;
      }
    },
    onCloseClicked(index) {
      this.inputValue.splice(index, 1);
      this.updateValue(this.inputValue);
    },
  },
  mounted() {
    if (this.$refs.inputWidgetDepartments) this.$refs.inputWidgetDepartments.resetValidation();
  },
};
</script>

<style>
.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
.iw-departments-vinput.v-input--is-readonly .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>
