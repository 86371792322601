<template>
  <div>
    <treeselect
      v-model="value"
      v-bind="otherProps"
      :multiple="isMultiple"
      :options="options"
      :searchable="isSearchable"
      :clearable="isClearable"
      :show-count="isShowCount"
      :clearOnSelect="true"
      :valueFormat="valueFormat"
      :disable-branch-nodes="disableBranchNode"
      :value-consists-of="valueConsistsOf"
      :placeholder="placeholder || $t('workflow.label.selectOrSearch')"
      :default-expand-level="options && options.length === 1 ? 1 : 0"
      @search-change="onSearch"
      @input="changed"
      @select="selected"
      :noResultsText="$t('messages.noData')"
      :noOptionsText="$t('messages.noData')"
      :retryText="$t('workflow.label.retry')"
      :retryTitle="$t('workflow.label.retryTitle')"
      @close="onClose"
    >
      <div slot="value-label" slot-scope="{ node }">
        <div>
          <component 
            :isBranch="node.isBranch|| isBranch"
            :is="selectedComponent(node.raw)"
            :renderLabel="renderLabel"
            :item="node.raw"
            :prefixIcon="prefixIcon"
            :onlyPrefix="onlyPrefix"
            :onlySuffix="onlySuffix"
          />
        </div> 
      </div>
      <div slot="option-label" slot-scope="{ node, shouldShowCount, count }" :class="labelClassName">
        <div>
          <component
            :is="optionComponent(node.raw.type)"
            :item="node.raw"
            :shouldShowCount="shouldShowCount"
            :count="count"
            :showField="showField"
            :shouldShowOutputNumber="shouldShowOutputNumber"
            :isBranch="node.isBranch || isBranch"
            :prefixIcon="prefixIcon"
            :onlyPrefix="onlyPrefix"
            :onlySuffix="onlySuffix"
            :renderLabel="renderLabel"
            :involveRelationshipLabel="involveRelationshipLabel"
          />
        </div>
      </div>
      <slot name="after-list" slot="after-list">
      </slot>
    </treeselect>
  </div>
</template>

<script>
  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  import UUID from 'uuid';
  import { has, forEach, trim } from 'lodash';
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import TableChip from './selected/tableChip';
  import EventChip from './selected/eventChip';
  import DelegateChip from './selected/delegateChip';
  import LabelChip from './selected/labelChip';
  import ProcessChip from './selected/processChip';
  import TableOption from './options/tableOptions';
  import ProcessOption from './options/processOptions';
  import LabelOption from './options/labelOptions';
  import EventOption from './options/eventOptions';
  import DelegateOption from './options/delegateOptions';
  import NodeUtils from '@/pages/admin/workflow/utils/node';
  export default {
    props: {
      isMultiple: {
        type: Boolean,
        default: false,
      },
      showField: Boolean,
      isBranch: {
        type: Boolean,
        default: false,
      },
      validator: Function,
      otherProps: Object,
      optionProps: Object,
      renderLabel: Function,
      involveRelationshipLabel: Boolean,
      disabledOnSelect: Boolean,
      disabledOnDependencies: Boolean,
      // changed: Function,
      name: String,
      loading: Boolean,
      placeholder: String,
      valueConsistsOf: {
        type: String,
        default: 'LEAF_PRIORITY',
      },
      shouldShowOutputNumber: {
        type: Boolean,
        default: true,
      },
      isShowCount: {
        type: Boolean,
        default: true,
      },
      disabledLookup: {
        type: Object,
        default: {},
      },
      disableBranchNode: {
        type: Boolean,
        default: false,
      },
      onlyPrefix: {
        type: Boolean,
        default: true,
      },
      onlySuffix: {  // only show children label without table label
        type: Boolean,
        default: false,
      },
      prefixIcon: {
        type: Boolean,
        default: false,
      },
      isSearchable: {
        type: Boolean,
        default: true,
      },
      isClearable: {
        type: Boolean,
        default: true,
      },
      optionOutput: String, // either multiple or signle, for filter the options with output type
      addable: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [ Array, String ],
        default: ()=> [],
      },
      options: {
        type: Array,
        default:()=> [],
      },
      valueFormat: {
        type: String,
        default: 'id',
      },
      enableEnterEvent: {
        type: Boolean,
        default: false
      },
      dependsOnType: String,
      dependsOnSource: String,  // with output filter and the source id will be kept
    },
    // register the component
    components: {
      Treeselect,
      TableChip,
      EventChip,
      DelegateChip,
      LabelChip,
      ProcessChip,
      TableOption,
      LabelOption,
      EventOption,
      DelegateOption,
      ProcessOption,
    },
    data() {
      return {
        newTag: null,
        rendered: false,
        // selectedLookup: {
        //   'dataset': 'TableChip',
        //   'eventset': 'EventChip',
        //   'nodeset': "EventChip",
        //   'delegated': 'DelegateChip',
        //   'label': 'LabelChip',
        //   'newTag': 'LabelChip',
        // },
        optionLookup: {
          'dataset': 'TableOption',
          'eventset': 'EventOption',
          'nodeset': "EventOption",
          'delegated': 'DelegateOption',
          'label': 'LabelOption',
          'newTag': 'LabelOption',
          'processset': 'ProcessOption',
        },
        list: [],
      }
    },
    methods: {
      normalize(list) {
        let newList = [];
        forEach(list, node => {
          if (has(node, 'children')) {
            newList.push(this.normalizer(node));
          } else {
            newList.push(node);
          }
        });
        return newList;
      },
      normalizer(node) {
        let isDisabled = false;
        const { optionOutput } = this;
        // the delegated options ignore
        // only signle output option needs concern for muliple output selection
        // while, optionOutput=multiple options can contain optionOutput=single options
        // console.log(!!optionOutput, node.type !== 'delegated', !!node.children, node.label, node);
        if (optionOutput && node.type !== 'delegated') {
          isDisabled = optionOutput === 'single' && node.output !== optionOutput;
          // console.log(isDisabled, node.output, optionOutput, node.label);
          if (this.dependsOnSource && optionOutput !== 'single') {
            const { dependsOnSource } = this;
            isDisabled = isDisabled && dependsOnSource !== node._id && dependsOnSource !== node.node;
          }
        }

        node.isDisabled = node.isDisabled || isDisabled;
        return node;
        // console.log('> ', node.label, isDisabled);
        // return {
        //   isDisabled: node.isDisabled || isDisabled,
        // }
      },
      onSelecting() {
        // console.log(n);
        // this.$emit('selecting');
      },
      addNewOption() {
        if (this.newTag && this.addable) {
          if (!this.options) {
            this.options = [];
          }
          if (this.isMultiple && !this.value) {
            this.value = [];
          }
          // this.$set(this.options, this.options.length, this.newTag);
          // this.list.push(this.newTag);
          // this.list.splice(this.list.length - 1, 0, this.newTag);
          // console.log(this.list);
          if (this.isMultiple) {
            // directly selected the new tag
            if (this.valueFormat === 'object') {
              this.value.push(this.newTag);
              this.value = JSON.parse(JSON.stringify(this.value))
            } else {
              this.value.push(this.newTag.id);
              this.value = JSON.parse(JSON.stringify(this.value))
            }
          } else if (this.valueFormat === 'object') {
            // this.value = this.newTag;
            this.$emit('update:value', this.newTag);
          } else {

            this.$emit('update:value', this.newTag.id);
            // this.value = this.newTag.id;
          }
          // clear it
          this.newTag = null;
        }
      },
      onSearch(searchQuery) {
        if (this.addable) {
          let query = searchQuery;
          if (!query) {
            this.newTag = null;
          } else {
            const beingNextTag = query.endsWith('  ') || query.endsWith('   ');
            query = beingNextTag ? query.replace(/\s\s+/g, '') : query;
            query = query.replace(/\.$/g, '');
            const valid = !this.validator || this.validator(query);
            if (valid) {
              this.newTag = {
                id: UUID(),
                label: trim(query),
                type: 'newTag',
              }
              if (beingNextTag) {
                this.addNewOption();
              }
            }
          }
        }
      },
      onClose() {
        this.addNewOption();
      },
      // classifiedOptions(options) {
      //   // let { options } = this;
      //   const list = NodeUtils.normalizeComboxOptions(options);
      //   if (this.dependsOnType) {
      //     return NodeUtils.normalizeDependencyOption(list, WidgetSelectionAcceptance, this.dependsOnType);        
      //   }
      //   return list;
      // },
      // normalizer(node) {
      //   let isDisabled = false;
      //   // skip branch node, check for the children only
      //   if (this.disabledOnDependencies && this.dependsOnType && !has(node, 'children')) {
      //     // console.log(node);
      //     const type = node.fieldType || node.type;
      //     if (type === 'newTag') {
      //       return node;
      //     }
      //     let acceptance = WidgetSelectionAcceptance[this.dependsOnType];
      //     // if no specify, then 
      //     if (!acceptance) {
      //       acceptance = [this.dependsOnType];
      //     }
      //     // node.isDisabled = isDisabled;
      //     isDisabled = !includes(acceptance, type);
      //   }
      //   return {
      //     isDisabled,
      //   };
      // },
      // cleanCache() {
      //   this.disabledLookup = {};
      // },
      // setCache(v) {
      //   const values = NodeUtils.withArray(v);
      //   this.disabledLookup = {};
      //   forEach(values, ({ id }) => this.disabledLookup[id] = true);
      //   // console.log(values, this.disabledLookup);
      // },
      changed(v) {
        if (v && v.fullRelationOutput) {
          this.$emit('changed', {
            ...v,
            properties: undefined,
            takeOutputDirect: true, // directly take the target as a input for relation data
          });
        } else if (v) { // v is clicked value
          this.$emit('changed', v);
        } else { // v is undefined
          // add for on cross button clear case
          this.$emit('selected', v);
        }
      },
      selected(v) {
        this.$emit('selected', v);
      },
      selectedComponent(info) {
        return NodeUtils.comboBoxSelectedComponent(info);
        // if (isSystem) {
        //   return this.selectedLookup.delegated;
        // }
        // const target = this.selectedLookup[type];
        // if (!target) {
        //   return this.selectedLookup.label;
        // }
        // return target;
      },
      optionComponent(type) {
        const target = this.optionLookup[type];
        if (!target) {
          return this.optionLookup.label;
        }
        return target;
      },
      handleEnterKeyEvent(e){
        if(e.key == 'Enter'){
          const treeInputContainer = this.$el.querySelector('.vue-treeselect__input-container input')
          if(treeInputContainer){
            if(/.+@.+\..+/.test(treeInputContainer.value)){
              this.onClose()
              treeInputContainer.value = ""
            }
          }
        }
      }
    },
    mounted(){
      if(this.enableEnterEvent){
        this.$vnode.elm.addEventListener('keyup', this.handleEnterKeyEvent)
      }
    },
    beforeDestroy(){
      if(this.enableEnterEvent){
        this.$vnode.elm.removeEventListener('keyup', this.handleEnterKeyEvent)
      }
    },
    // mounted() {
    //   console.log('>>>> ',this.dependsOnType);
    //   // this.classifiedOptions();
    // },
    // updated() {
    //   if (!this.rendered) {
    //     // this.classifiedOptions();
    //     this.rendered = true;
    //   }
    // },
    watch: {
      // dependsOnType: {
      //   handler(v) {
      //     console.log('> dependsOnType');
      //   },
      //   deep: true,
      // },
      loading: {
        handler(v, o) {
          // console.log('> ', v, o);
          if (o && !v) {
            // this.classifiedOptions();
          }
        },
        deep: true,
      }
    }
  }
</script>
