import { render, staticRenderFns } from "./WitAmountFieldSelection.vue?vue&type=template&id=f5666e56&"
import script from "./WitAmountFieldSelection.vue?vue&type=script&lang=js&"
export * from "./WitAmountFieldSelection.vue?vue&type=script&lang=js&"
import style0 from "./WitAmountFieldSelection.vue?vue&type=style&index=0&id=f5666e56&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
