
const mixin = {
  computed: {

    // Relational tables
    relationPagingFieldInfos () {
      const vm = this
      var result = []
      result = vm.fieldInfos.filter(
        info => {
          return (
            (
              (info.type === 'childTable') ||
              (
                (info.type === 'relatedRecord') &&
                (info.properties.relatedRecordQuantity==='multiple')
              )
            ) &&
            (info.properties.usePaging === 'yes')
          )
        }
      )
      return result
    },

    // Top paging - field Infos
    //
    topPagingTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.formLayout && vm.formLayout.topPagingTables) {
        const pagingInfos = vm.formLayout.topPagingTables.map(item => {
          return vm.relationPagingFieldInfos.find(info => info._id === item._id)
        })
        result = pagingInfos.filter(info => {
          if(info._id === '') return true
          var authorization = []
          if (info.properties.authorization) {
            authorization = info.properties.authorization.split('||')
          }
          if (
            authorization.includes('hidden')||
            (
              authorization.includes('hiddenDuringCreation') && 
              vm.isNew
            )
          ){
            return false
          }
          return true
        })
      }
      return result
    },

    topPagingTableLabels () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.topPagingTableFieldInfos.length; i++) {       
        const loopFieldInfo = vm.topPagingTableFieldInfos[i];
        result.push({
          _id: loopFieldInfo._id,
          label: loopFieldInfo.label
        });
      }
      return result
    },

    topPagingTabs () {
      const vm = this
      return [
        {
          _id: '',
          label: vm.$t('general.mainPage')
        },
        ...vm.topPagingTableLabels
      ]
    },

    // Embedded paging - field infos
    //
    embeddedPagingTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.formLayout && vm.formLayout.embeddedPagingTables) {
        result = vm.getPagingTableFieldInfos(vm.formLayout.embeddedPagingTables);
      }
      return result
    },

    embeddedPagingTableLabels () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.embeddedPagingTableFieldInfos.length; i++) {
        const loopFieldInfo = vm.embeddedPagingTableFieldInfos[i]
          result.push({
            _id: loopFieldInfo._id,
            label: loopFieldInfo.label
          })
        // }
      }
      return result
    },

    embeddedPagingTabs(){
      return this.embeddedPagingTableLabels
    },

    // embeddedPagingTableWidgetItems () {
    //   const vm = this
    //   var result = []
    //   for (let i = 0; i < vm.embeddedPagingTableLabels.length; i++) {
    //     const loopItem = vm.embeddedPagingTableLabels[i]
    //     result.push({
    //       _id: loopItem._id,
    //       controlType: 'data'
    //     })
    //   }
    //   return result
    // }
  },

  methods: {
    shouldFieldBeHidden (fieldInfo) {
      var authorization = [];
      if (fieldInfo.properties && fieldInfo.properties.authorization) {
        authorization = fieldInfo.properties.authorization.split('||');
      }
      var show = true;
      if (authorization.includes('hidden')) {
        show = false
      } else if (authorization.includes('hiddenDuringCreation') && this.isNew) {
        show = false
      }
      return !show;
    },

    getPagingTableFieldInfos (tableFields) {
      const vm = this;
      const pagingInfos = tableFields.map(tableField => {
        return vm.relationPagingFieldInfos.find(info => info._id === tableField._id)
      })
      const result = pagingInfos.filter(info => {
        const shouldFieldBeHidden = vm.shouldFieldBeHidden(info);
        return !shouldFieldBeHidden;
      });
      return result;
    }
  }

}

export default mixin
