const CompatibleType = {
    text: ["text", "date", "amount", "email", "date", "phone", "singleSelection",
            "multipleSelection", "region", "location", "expression", "yesNo",
            "rating", "textCombination", "idCard", "members", "departments", "createdBy", "owner"],
    number: ["number", "amount", "expression", "rating"],
    amount: ["number", "amount", "expression", "rating"],
    rating: ["number", "amount", "expression", "rating"],
    members: ["members", "createdBy", "owner"],
    departments: ["departments"],
    date: ["date"],
    relatedRecord: ["relatedRecord"]
}

export default CompatibleType