<template>
  <vxe-table-column
    v-bind="colDef"
    :edit-render="{ name: '$select', options: selection, enabled: !readonly }"
    class-name="fullCell vxeCellMultipleSelection"
  >
    <template v-slot="{ row }">
      <option-chip-list
        :value="row[colDef.field]"
        :useColor="useColor"
        toggleType="multiple"
        :options="options"
      ></option-chip-list>
      <quick-action v-if="quickInsert" :rowId="row['_id']" :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{ row }">
      <option-chip-list
      v-if="readonly"
        :value="row[colDef.field]"
        :useColor="useColor"
        toggleType="multiple"
        :options="options"
      ></option-chip-list>
      <pulldown-chip-selection
      v-else
      class="vxecellSelection"
        :readonly="readonly"
        :items="selection"
        :fullListOptions="options"
        :useColor="useColor"
        item-text="label"
        item-value="_id"
        @onDelete="onDeleteHandler($event, row[colDef.field])"
        v-model="row[colDef.field]"
        :allowAdd="allowAdd"
        :fieldId="fieldId"
        :multiple="true"
      ></pulldown-chip-selection>
    </template>

    <template v-slot:footer="{ column }">
      <summary-cell
        :decimals="decimals"
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"
      ></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import optionChipList from "./comps/OptionChipList";
import quickAction from "./comps/QuickAction";
import PulldownChipSelection from "@/components/inputWidgets/comps/PulldownChipSelection";
import summaryCell from "./comps/SummaryCell";

export default {
  name: "vxeCellMultipleSelection",
  mixins: [mixin],
  components: {
    optionChipList,
    quickAction,
    PulldownChipSelection,
    summaryCell,
  },
  methods: {
    getOption(value) {
      return this.options.filter(
        (option) =>
          (!option.isDeleted && option.active) ||
          (Array.isArray(value) && value.includes(option._id))
      );
    },
    onDeleteHandler(id, value) {
      const index = value.findIndex((selection) => selection === id);
      if (index > -1) value.splice(index, 1);
    },
  },
  computed: {
    allowAdd() {
      return (
        this.fieldInfo &&
        this.fieldInfo.properties.settingsMultiple &&
        this.fieldInfo.properties.settingsMultiple.includes("allowUserAdd")
      );
    },
  },
};
</script>
<style>
.vxeCellMultipleSelection .vxe-cell {
  height: 100%;
  max-height: 100% !important;
}
</style>
