<template>
  <div style="height:40px;" class="elevation-2 pa-2">
      <div class="d-flex align-center justify-end" style="height:100%">
        <slot></slot>
        <v-spacer></v-spacer>
        <v-hover v-slot="{hover}" v-for="button in activeButtons" :key="button.command">
          <v-btn
            :loading="loading"
            class="min-width-100 elevation-0 ml-2"
            :disabled="checkActionDisabled(button.command)"
            :color="button.color"
            :type="getButtonType(button.command)"
            @click="onButtonClicked(button.command)"
            small
            :outlined="button.outlined"
          >

            <v-icon left>{{button.icon}}</v-icon>
            <span>{{$t(`buttons.${button.labelTag}`)}}</span>
            <v-icon v-if="button.prependIcon">{{ button.prependIcon }}</v-icon>
          </v-btn>
        </v-hover>
      </div>
  </div>
</template>

<script>
import actionBtns from './actionBtns' 
export default {
  data () {
    return {
      actionBtns: actionBtns,
    }
  },
  props: {
    buttons: {
      type: Array,
      default () {
        return [
          'cancel',
          'ok'
        ]
      }
    },
    actionDisabled: Boolean,
    loading: Boolean,
    closeButton: {
      type: [Array, String],
      default: ()=>['cancel','close']
    },
    defaultButton: {
      type: String,
      default: ''
    },
    disabledButtons: {
      type: Array,
      default () {
        return []
      }
    },
  },
  computed: {
    activeButtons () {
      const vm = this
      var result = []
      //console.log(vm.buttons)
      for (var i = 0; i < vm.buttons.length; i++) {
        if(typeof vm.buttons[i] == 'object'){
          var tar = vm.actionBtns[vm.buttons[i].value]
          if(vm.buttons[i].labelTag){
            tar.labelTag = vm.buttons[i].label
          }
          result.push(tar)
        }
        else result.push(vm.actionBtns[vm.buttons[i]])
      }
      return result
    }
  },
  methods: {
    getButtonType (command) {
      return this.defaultButton===command ? 'submit' : 'button'
    },
    checkActionDisabled (command) {
      const vm = this
      var result = (command !== 'cancel')
        && (command !== 'close')
        && vm.actionDisabled
      if (vm.disabledButtons.includes(command)) {
        result = true
      }
      return result
    },
    onButtonClicked (command) {
      const vm = this
      if (command !== vm.defaultButton) {
        vm.$emit('onCommand', {
          command: command
        })
      }
    }
  }
}
</script>

