<template>
  <WorkflowConsumer>
    <div>
      <div class="workflow-config-parameter-title">
        {{$t('workflow.label.settingParameterWarning')}}
      </div>
      <div class="workflow-config-parameter-container">
        <ParameterAddSelect :parameters="parameters" :targetWorkflow="workflow" />
      </div>
      <div class="workflow-config-parameter-submit">
        <v-btn
          elevation="0"
          color="primary"
          @click="onSave">
          {{ $t('workflow.btn.save') }}
        </v-btn>
      </div>
    </div>
  </WorkflowConsumer>
</template>

<script>
import ParameterAddSelect from './../../components/parameter';
import { countBy } from 'lodash';
import Mixin from './../../mixin';
import {
  ADD_WORKFLOW_PARAMETER,
  // FETCH_WORKFLOW_PARAMETER,
  // REMOVE_WORKFLOW_PARAMETER,
} from '@/store/modules/workflow/action_types';

export default {
  mixins: [Mixin],
  // data() {
  //   return {
  //     parameters: [],
  //   };
  // },
  components: {
    ParameterAddSelect,
  },
  methods: {
    onSave() {
      const { workflow, parameters, currentApp } = this;
      const anyError = countBy(parameters, ({ error }) => !!error);
      if (!anyError.true) {
        this.$store.dispatch(ADD_WORKFLOW_PARAMETER, {
          appId: currentApp._id,
          workflow: workflow._id || workflow,
          parameters,
        });
      }
    }
  },
  computed: {
    parameters() {
      // get: function () {
        return this.$store.getters.getWorkflowParameters
      // },
      // set: function (newParameters) {
      //   var names = newValue.split(' ')
      //   this.firstName = names[0]
      //   this.lastName = names[names.length - 1]
      // }
    },
  }
};
</script>

<style lang="scss" scoped>
  .workflow-config-parameter-container {
    width: 500px;
  }
  .workflow-config-parameter-title {
    color: #757575;
    margin: 5px 0 10px 0;
    font-size: 10px;
    text-align: justify;
  }
  .workflow-config-parameter-submit {
    margin: 30px 0 0 0;
    text-align: center;
  }
</style>
