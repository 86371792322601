<template>
<!--<div class="child-block-list d-flex flex-column fill-height justify-start">-->
  <!--
  .child-block-list.parent > .parent-child-dual-column > .parent-column > .data-block > .block-prepend > .block-prepend-bottom {
    border-top-color: transparent;
  }
  -->
  <!-- :list="userChildList"
  -->
<draggable v-model="userChildList"
           handle=".handle"
           ghost-class="ghost"
           chosen-class="chosen"
           tag="div"
           class="child-block-list zoomable d-flex flex-column fill-height justify-start"
           :class="{'non-parent':level>1,'no-child':childList.length===0}"
           @start="onDragStarted"
           @change="evt=>onChange(parent, evt)"
           @add="onAdded"
           @move="onMove"
           @end="onDragEnded"
           @unchoose="onDragAbandoned"
           :group="currentFormId"
           :key="id">
    <div v-for="(child, index) in childList"
         :key="index"
         class="d-flex flex-row parent-child-dual-column zoomable child-column">
      <data-block-column
        ref="dataBlockColumn"
        :dragging="dragging"
        :levelType="levelType"
        :item="child"
        :level="level"
        :parent="parent"
        :formInfoDict="formInfoDict"
        :parentChildInfos="currentParentChildInfos"
        @toggle="state=>toggleItem(child,index,state)"
        @checkSubmit="onCheckSubmitHandler"
        @unlinkItem="unlinkItemHandler"
        @openRecord="openRecord"
        @click:newNode="payload=>addNode(child,index,payload)"      
        @click:selectNode="payload=>selectNode(child,index,payload)"
        @click:deleteNode="deleteNode(child,index)"></data-block-column>
<!--          c.c.len={{child.children.length}},dragging:{{dragging}} -->
      <div v-if="child.loading" class="ml-3 mt-3">
        <font-awesome-icon icon="spinner" class="fa-3x fa-spin"></font-awesome-icon>
      </div>
      <child-block-list 
        v-else-if="child.children && child.children.length>0"
        ref="childBlockList"
        :levelType="levelType"
        :level="level+1"
        :parent="child"
        :dragging="dragging"
        :childList="child.children"                          
        :formInfoDict="formInfoDict"
        :currentParentChildInfos="subParentChildInfos"
        @onSubmit="onSubmitHandler"
        @openRecord="openRecord"
        @toggleChild="payload=>toggleChildWithIndex(payload, index)"
        @onCommand="onCommandHandler"></child-block-list>
      <draggable
        v-model="dummyList"
        v-else-if="dragging"
        handle=".handle"
        ghost-class="ghost"
         @change="evt=>onChange(child, evt)"
        :style="dummyDropZoneStyle"
        tag="div"
        :group="childFormId"></draggable>

<!--        <child-block-list 
          ref="childBlockList"
          :dragging="dragging"
          :levelType="levelType"
          :parent="child"
          :childList="child.children"                          
          :formInfos="formInfos"
          :currentParentChildInfos="subParentChildInfos"
          @toggleChild="payload=>toggleChildWithIndex(payload, index)"
          @onCommand="onCommandHandler"></child-block-list> -->
    </div>
</draggable>
  <!--</div>-->
</template>

<script>
import dataBlockColumn from './DataBlockColumn'
import dataBlock from './DataBlock'
import emptyBlock from './EmptyBlock'
// import childBlockList from './ChildBlockList'
import draggable from 'vuedraggable'
import EventBus from '@/event-bus.js'

export default {
  name: 'childBlockList',
  components: {
    dataBlockColumn,
    dataBlock,
    emptyBlock,
    draggable
  },
  props: {
    level: Number,
    dragging: Boolean,
    levelType: {
      type: String,
      default: 'currentTable'
    },
    parent: Object,
    childList: {
      type: Array,
      default () {
        return []
      }
    },
    formInfoDict: Object,
    currentParentChildInfos: {
      type: Array,
      default () {
        return []
      }
    }

    //  {
    //   type: String,
    //   default: 'root'
    // },
    // // childList: {
    // //   type: Array,
    // //   default () {
    // //     return []
    // //   }
    // // },
    // childrenData: {
    //   type: Object,
    //   default () {
    //     return {}
    //   }
    // }
  },
  data () {
    return {
      dataBlockWidth: 340,
      dummyList: []
    }
  },
  computed: {
    dummyDropZoneStyle () {
      const vm = this
      var result = {}
      result['width'] = vm.dataBlockWidth + 'px'
      result['height'] = vm.$refs.dataBlockColumn.offsetHeight + 'px'
      result['backgroundColor'] = '#edf5fb'
      return result
    },
    form () {
      return this.$store.getters.currentForm
    },
    currentFormId () {
      const vm = this
      var result = ''
      switch (vm.levelType) {
        case 'currentTable':
          result = vm.form._id
          break
        case 'multipleTables':
          if (vm.currentParentChildInfos && vm.currentParentChildInfos.length>0) {
            result = vm.currentParentChildInfos[0].currentFormId
          }
          break
      }
      return result
    },
    childFormId () {
      const vm = this
      var result = ''
      if (vm.subParentChildInfos.length > 0) {
        result = vm.subParentChildInfos[0].currentFormId
      }
      return result
    },
    subParentChildInfos () {
      const vm = this
      var result = []
      switch (vm.levelType) {
        case 'multipleTables':
          if (vm.currentParentChildInfos) {
            result = JSON.parse(JSON.stringify(vm.currentParentChildInfos))
            result.shift(1)
          }      
          break
        case 'currentTable':
          // for currentTable
          // same parentChildInfo for all layers
          result = vm.currentParentChildInfos
          break
      }
      return result
    },
    userChildList: {
      get () {
        return this.childList
      },
      set (val) {
        // alert('computed(userChildList) : set')
      }
    },
    subChildList: {
      get () {
        return this.child && this.child.open ? this.child.children : []
      },
      set (val) {
        alert('computed(subChildList) : set')
      }
    }
    // childIdList () {
    //   const vm = this
    //   var result = []
    //   if (vm.childrenData && vm.parentId) {
    //     result = vm.childrenData[vm.parentId].childrenIds
    //   }
    //   return result
    // },
    // parentData () {
    //   const vm = this
    //   var result = null
    //   if (vm.parentId && vm.childrenData) {
    //     result = vm.childrenData[vm.parentId]        
    //   }
    //   return result
    // },

    // parentData () {
    //   const vm = this
    //   var result = null
    //   if (vm.parent) {
    //     result = vm.childData[vm.parent._id]
    //   }
    //   return result
    // },
    // expandedChildList () {
    //   const vm = this
    //   var result = []
    //   if (vm.parentData) {
    //     if (vm.parentData.open) {
    //       result = vm.childList
    //     }
    //   } else {
    //     // if no parent (i.e.root), always open
    //     result = vm.childList
    //   }
    //   return result
    // }
  },
  methods: {
    openRecord (payload) {
      this.$emit('openRecord', payload)
    },
    unlinkItemHandler (payload) {
      // console.log('ChildBlockList :: unlinkItemHandler :: payload: ', JSON.stringify(payload))
      this.$emit('onCommand', {
        command: 'unlink',
        parent: payload.parent,
        item: payload.item
      })
    },
    onCheckSubmitHandler (payload) {
      const vm = this
      // console.log('ChildBlockList :: onCheckSubmitHandler :: parent: ', vm.parent)
      // console.log('ChildBlockList :: onCheckSubmitHandler :: currentParentChildInfos: ', vm.currentParentChildInfos)
      // console.log('ChildBlockList :: onCheckSubmitHandler: payload: ', payload)
      EventBus.$emit('submitNewItem', payload)
      // vm.$emit('onSubmit', payload)
    },
    onSubmitHandler (payload) {
      this.$emit('onSubmit', payload)
    },
    // getChildList (parentId) {
    //   const vm = this
    //   var result = []
    //   console.log('getChildList parentId = ' + parentId)
    //   if (vm.childrenData) {
    //     result = vm.childrenData[parentId].children
    //   }
    //   return result
    // },
    onMove  (evt) {
      // console.log('drag: onMove :: evt: ', evt)
      // console.log('drag: onMove :: evt.draggedContext: ', evt.draggedContext)
      // console.log('drag: onMove :: evt.relatedContext: ', evt.relatedContext)
    },
    onChange (parent, evt) {
      // console.log('drag: onChange :: parent: ', parent)
      // console.log('drag: onChange :: evt: ', evt)
      const vm = this

      if (evt.moved) {
        // evt: {
        //  moved: {
        //    element,
        //    oldIndex,
        //    newIndex
        //  }
        // }
        // console.log('onChange :: evt: ', evt)
        vm.$emit('onCommand', {
          command: 'movePosition',
          item: evt.moved.element,
          parent: vm.parent,
          level: vm.level,
          oldIndex: evt.moved.oldIndex,
          newIndex: evt.moved.newIndex
          // command: 'move',

        })
      }
      if (evt.added) {
        // console.log('childBlockList :: parent: ', parent)
        if (parent) {
          if (Array.isArray(parent.children)) {
            parent.children.push(evt.added.element)
          } else {
            parent.children = [evt.added.element]
          }
          // console.log('ChildBlockList :: onChange :: added: ', evt.added)
          // console.log('ChildBlockList :: onChange :: parent: ', parent)
        }
        vm.$emit('onCommand', {
          command: 'attach',
          parent: parent,
          index: evt.added.newIndex,
          item: evt.added.element
        })
      }
      if (evt.removed) {
        if (parent) {
          parent.children.splice(evt.removed.oldIndex, 1)
        }
//           const parentId = parent._id
        vm.$emit('onCommand', {
          command: 'detachFromParent',
          parent: parent,
          item: evt.removed.element
        })
      }
      // if (evt.moved) {
      //   console.log('ChildBlockList :: onChange :: moved: ', evt.moved)
      //   vm.$emit('onCommand', {
      //     command: 'move',
      //     parent: parent,
      //     item: evt.moved.element
      //   })
      // }

      // )
      // console.log('onChange: parent: ', parent)
      // if (evt.added) console.log('onChange :: evt.added: ', evt.added)
      // if (evt.removed) console.log('onChange :: evt.removed: ', evt.removed)
      // if (evt.moved) console.log('onChange :: evt.moved: ', evt.moved)
    },
    onAdded (evt) {
      // console.log('drag: onAdded: evt: ', evt)
      // this.$emit('onCommand', {
      //   command: 'onDraggingItemEnded'
      // })
    },
    setFocus (refName) {
      const vm = this
      // console.log('childBlockList :: setFocus ::  refName = ' + refName)
      // console.log('childBlockList :: setFocus :: refs: ', vm.$refs)
      // console.log('childBlockList :: setFocus :: parent._id = ' + (vm.parent ? vm.parent._id : '(top level)'))

      var result = false
      if (vm.$refs['dataBlockColumn']) {
        // console.log('childBlockList :: found dataBlockColumn: ' + vm.$refs['dataBlockColumn'].length)
        for (let i =0; i < vm.$refs['dataBlockColumn'].length; i++) {
          if (vm.$refs['dataBlockColumn'][i].setFocus(refName)) {
            result = true
            break
          }
        }
      }
      if (vm.$refs['childBlockList']) {
        if (!result) {
          for (let j=0; j < vm.$refs['childBlockList'].length; j++) {
            // console.log('j=' + j + ': childBlockList')
            if (vm.$refs['childBlockList'][j].setFocus(refName)) {
              result = true
              break
            }
          }
        }
      }
      return result
    },
    addNode (node, index, payload) {
      this.$emit('onCommand', {
        command: 'addChildNode',
        parentChildInfo: payload.parentChildInfo,
        parentNode: node,
        index: index
      })
    },
    selectNode (node, index, payload) {
      this.$emit('onCommand', {
        command: 'selectChildNode',
        parentChildInfo: payload.parentChildInfo,
        parentNode: node,
        index: index
      })
    },

    deleteNode (node, index) {
      console.log('childBlockList :; deleteNode: node(index=' + index + '): ', node)
      this.$emit('onCommand', {
        command: 'deleteNode',
        node: node,
        index: index
      })
    },

    onCommandHandler (payload) {
      const vm = this
      // console.log('ChildBlockList :; onCommandHandler :; payload: ', payload)
      switch (payload.command) {
        // case 'addChildNode':
        //   break
        // case 'deleteNode':
        //   break
        default:
          this.$emit('onCommand', payload)
      }
    },
    onDragAbandoned (evt) {
      this.$emit('onCommand', {
        command: 'onDragAbandoned'
      })
    },
    onDragStarted (evt) {
      this.$emit('onCommand', {
        command: 'onDraggingItemStarted'
      })
      // console.log('drag: onDragStarted :: evt: ', evt)
    },
    onDragEnded (evt) {
      // console.log('drag: onDragEnded evt: ', evt)
      // console.log('LevelBlockList :: onDragEnded :: evt: ', evt)
      this.$emit('onCommand', {
        command: 'onDraggingItemEnded'
      })
    },

    // from dataBlock
    toggleItem (item, index, state) {
      // console.log('ChildBlockList :: toggleItem :: index=' + index + ': item: ', item)
      this.$emit('toggleChild', {
        item: item,
        state: state,
        indices: [index]
      })
    },

    // from ChildBlockList
    toggleChildWithIndex (payload, index) {
      // payload = {
      //    item,
      //    state,
      //    indices
      // }      
      // console.log('ChildBlockList :: toggleChildWithIndex :: paylaod.indices=' + JSON.stringify(payload.indices) + ', index=' + index)
      payload.indices.unshift(index)
      payload['parent'] = this.parent
      this.$emit('toggleChild', payload)
    },

    // openChildBlockList (child) {
    //   const vm = this
    //   var result = false
    //   if (child) {
    //     if (vm.childrenData[child._id].children && vm.childrenData[child._id].children.length > 0) {
    //       result = true
    //     }
    //     if (vm.childrenData[child._id].open !== 'undefined') {
    //       if (vm.childrenData[child._id].open === false) {
    //         return false
    //       }
    //     }
    //   }
    //   return result
    // },
    getChildClass (node) {
      const vm = this
      var result = []
      // console.log('getChildClass node: ', node)
      // console.log('getChildClass vm.childFieldID = ' + vm.childFieldId)


      if (Array.isArray(node[vm.childFieldId]) && node[vm.childFieldId].length > 0) {
        // console.log('getChildClass: node: ', node)
        result.push('has-child')
      }
      return result.join(' ')
    }
  }
}
</script>

<style>
.child-block-list {
  min-width: 272px;
  min-height: 120px;
  /* min-height: 118px; */
}
    /*.parent-child-dual-column .empty-block:last-child .block-prepend {*/
    /*border-left-color: black;*/
    /*}*/

    .child-block-list .parent-child-dual-column:has-child > .parent-column.has-child > .empty-block > .block-append > .block-append-bottom {
      border-top-color: black;
    }
    .child-block-list .parent-child-dual-column:not(:last-child) > .parent-column > .empty-block > .block-prepend {
      border-left-color: black;
    }

    .child-block-list .parent-child-dual-column:not(:last-child):not(:first-child) > .parent-column > .data-block > .block-prepend > .block-prepend-top,
    .child-block-list .parent-child-dual-column:not(:last-child):not(:first-child) > .parent-column > .data-block > .block-prepend > .block-prepend-bottom,
    .child-block-list .parent-child-dual-column:first-child > .parent-column > .data-block > .block-prepend > .block-prepend-bottom,
    .child-block-list .parent-child-dual-column:last-child > .parent-column > .data-block > .block-prepend > .block-prepend-top {
      border-left-color: black;
    }

    .child-block-list .parent-child-dual-column:first-child:last-child > .parent-column > .data-block > .block-prepend > .block-prepend-bottom,
    .child-block-list .parent-child-dual-column:first-child:last-child > .parent-column > .data-block > .block-prepend > .block-prepend-top {
      border-left-color: transparent;
    }

    .child-block-list .parent-child-dual-column > .parent-column > .data-block:not(.has-child) > .block-append > .block-append-bottom {
      border-top-color: transparent;
    }

    /* hide level line before the topmost nodes */
    /*.child-block-list.parent > .parent-child-dual-column > .parent-column > .data-block > .block-prepend > .block-prepend-bottom {*/
    /*border-top-color: transparent;*/
    /*border-left-color: transparent;*/
    /*}*/

    .child-block-list.parent-node > .parent-child-dual-column > .parent-column > .data-block > .block-prepend > .block-prepend-bottom {
      border-top-color: transparent !important;
    }

    .child-block-list.parent-node > .parent-child-dual-column > .parent-column > .data-block > .block-prepend > .block-prepend-bottom,
    .child-block-list.parent-node > .parent-child-dual-column > .parent-column > .data-block > .block-prepend > .block-prepend-top,
    .child-block-list.parent-node > .parent-child-dual-column > .parent-column > .empty-block > .block-prepend {
      border-left-color: transparent !important;
    }

    .child-block-list.parent-node > .parent-child-dual-column > .parent-column > .data-block > .block-prepend,
    .child-block-list.parent-node > .parent-child-dual-column > .parent-column > .empty-block > .block-prepend {
      width: 10px;
    }

/* Dragging Style */

    .parent-child-dual-column.ghost > .parent-column > .data-block >  .block-prepend > .block-prepend-top,
    .parent-child-dual-column.ghost > .parent-column > .data-block >  .block-prepend > .block-prepend-bottom {
      /*border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;*/
    }

    /* Drag & Drop appearance */
    .parent-child-dual-column.chosen:not(.ghost) > .parent-column > .data-block > .block-prepend > .block-prepend-top,
    .parent-child-dual-column.chosen:not(.ghost) > .parent-column > .data-block > .block-prepend > .block-prepend-bottom {
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
    }

.parent-child-dual-column.ghost:not(.chosen) > .parent-column > .data-block {
  background-color: green;
}

/* Being dragged */
.parent-child-dual-column.chosen:not(.ghost) .parent-column,
.parent-child-dual-column.chosen:not(.ghost) .child-block-list:not(.no-child)  {

/*> .parent-column > .data-block*/ 
  background-color: rgba(250,235,215,0.5);
}
.parent-child-dual-column.chosen:not(.ghost) .child-block-list.no-child {
  min-width: 0 !important;
  min-height: 0 !important;
  display: none !important;
}

/* Ghost at original position */
.parent-child-dual-column.chosen.ghost /*> .parent-column*/ {
  opacity: 0.3;
  /*background-color: lightgrey;*/
}


    .parent-child-dual-column.chosen:not(.ghost) > .parent-column > .empty-block > .block-prepend {
      border-left-color: transparent !important;
    }

</style>

