<template>
  <v-dialog width="560">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" plain :ripple="false">
        <v-icon size="20">mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card max-height="560" min-height="360">
      <v-tabs
        vertical
        grow
        hide-slider
        style="min-height:inherit"
        active-class="teal lighten-4 white--text"
      >
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab.label }}
        </v-tab>
        <v-tab-item v-for="tab in tabs" :key="tab">
          <v-card flat>
            <v-card-text class="px-0 py-2">
              <div>
                <v-data-table
                  :headers="tab.headers || headers"
                  :items="tab.items"
                  hide-default-footer
                  class="elevation-0 formatTable"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  dense
                >
                  <template v-slot:item.description="{ item }">
                    {{ $t(`timeFormatInstruction.${item.input}`) }}
                  </template>
                  <template v-slot:item.example="{ item }">
                    {{ getExample(item.input) }}
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <!-- <v-card-text class="pa-0">
                <div class="d-flex">
                    <div class="d-flex flex-column flex-grow-0" style="width:80px">
                        <div style="cursor:pointer" @click="tab=1" :class="tab==1?'teal lighten-4 white--text':''" class="flex-grow-1 d-flex align-center justify-center">日期</div>
                        <div style="cursor:pointer" @click="tab=2" :class="tab==2?'teal lighten-4 white--text':''" class="flex-grow-1 d-flex align-center justify-center">時間</div>
                    </div>
                    <div class="flex-grow-1">
                        <v-data-table
                            :headers="headers"
                            :items="tab==1?dateFormats:timeFormats"
                            hide-default-footer
                            class="elevation-0 formatTable"
                            disable-filtering	
                            disable-pagination	
                            disable-sort
                            dense
                        ></v-data-table>
                    </div>
                </div>
            </v-card-text> -->
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

const exampleString = "2014-08-06 13:07:04";

export default {
  data() {
    return {
      tab: 1,
      headers: [
        {
          text: this.$t("timeFormatInstruction.input"),
          value: "input",
          width: 80,
        },
        {
          text: this.$t("timeFormatInstruction.description"),
          value: "description",
          width: 120,
        },
        {
          text: this.$t("timeFormatInstruction.example", [
            ` (${exampleString})`,
          ]),
          value: "example",
          width: 80,
        },
      ],
      tabs: [
        {
          label: this.$t("timeFormatInstruction.date"),
          items: [
            { input: "YYYY" },
            { input: "YY" },
            { input: "Q" },
            { input: "M" },
            { input: "MM" },
            { input: "MMM" },
            { input: "MMMM" },
            { input: "D" },
            { input: "DD" },
            { input: "DDD" },
            { input: "DDDD" },
          ],
        },
        {
          label: this.$t("timeFormatInstruction.time"),
          items: [
            { input: "H" },
            { input: "HH" },
            { input: "h" },
            { input: "hh" },
            { input: "a" },
            { input: "A" },
            { input: "m" },
            { input: "mm" },
            { input: "s" },
            { input: "ss" },
          ],
        },
        {
          label: this.$t("timeFormatInstruction.example", []),
          headers: [
            {
              text: this.$t("timeFormatInstruction.input"),
              value: "input",
              width:120
            },
            {
              text: this.$t("timeFormatInstruction.example", [
                ` (${exampleString})`,
              ]),
              value: "example",
              width:120
            },
          ],
          items: [
            {
              input: "YYYY/MM/DD HH:mm:ss",
            },
            {
              input: "D MMM, YYYY",
            },
            {
              input: "h:mm A",
            },
            {
              input: "D MMMM, YYYY, h:mm:ss A",
            },
          ],
        },
      ],
    };
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    },
  },
  methods: {
    getExample(format) {
      return new moment(exampleString).locale(this.locale).format(format);
    },
  },
};
</script>
<style>
/* .momentFormatDialog table, th, td {
    border: 1px solid black;
    }

    .momentFormatDialog th, td {
    padding: 10px;
    } */
.formatTable {
  table-layout: fixed !important;
  box-shadow: 0 !important;
  border-radius: 0 !important;
  /* font-size: 12px !important; */
}
.formatTable td {
  font-size: 12px !important;
  color: #676767 !important;
}
/* .testClass{
        background-color: red!important;
        paddingeft: 32px !important
    } */
</style>
