<template>
  <div>
    <v-btn
      x-small
      fab
      class="white"
      @click="$emit('updateShowingDialog', true)"
      outlined
      style="width: 25px; height: 25px;"
      :disabled="readonly"
    >
      <v-icon color="red">mdi-google-maps</v-icon>
    </v-btn>
    <!-- {{attach}} -->
    <!-- <v-overlay
      :absolute="false"
      :value="showingDialog"
      :id="attach"
    >
    </v-overlay> -->
    <v-dialog v-model="showingDialog" v-if="showingDialog" width="1024" persistent :attach="(cssSelect)?cssSelect:`#${attach}`" hide-overlay>
      <v-card
        class="d-flex flex-column dialog-button-map"
        style="height:60vh; overflow: hidden"
      >
      <v-btn class="" @click="$emit('updateShowingDialog', false)" icon style="position:absolute; top: 5px; right: 5px; z-index:1" small :ripple="false"><v-icon>mdi-close</v-icon></v-btn>
        <v-card-text class="d-flex pa-2" style="height: 100%">
          <div style="width:40%; height: 100%" class="d-flex flex-column">
            <v-text-field 
              prepend-inner-icon="mdi-magnify" 
              filled rounded 
              hide-details 
              dense 
              class="mx-3 mb-2 mt-3 d-inline-block" 
              height="35" 
              style="width:350px;"
              placeholder="搜尋地點"
              v-on:keyup.enter="onEnter"
              v-model="tempSearch"
            >
            </v-text-field>
            <perfect-scrollbar style="height: 100%">
              <v-list class="pa-0 mr-3" dense>
                <v-list-item-group color="primary" v-model="selected" v-if="search==''">
                  <v-list-item v-if="bl_location_permission" @click="saveOwnLoc()">
                    <div class="flex-column d-flex py-2">
                      <div class="">
                        <span style="line-height: 1.5" class="mr-2">{{ownLoc.name}}</span>
                         <span class="currLabel rounded-pill success lighten-2 px-2 py-1" style="">我的位置</span>
                      </div>
                      <span style="font-size:12px; line-height: 1; color: #c5c5c5">{{ownLoc.formatted_address}}</span>
                    </div>
                  </v-list-item>
                  <v-divider v-if="bl_location_permission"/>
                  <v-list-item @click="saveCurrLoc()">
                    <div class="flex-column d-flex py-2">
                      <div class="">
                        <span style="line-height: 1.5" class="mr-2">{{currLoc.name}}</span>
                         <span class="currLabel rounded-pill success lighten-2 px-2 py-1" style="">座標位置</span>
                      </div>
                      <span style="font-size:12px; line-height: 1; color: #c5c5c5">{{currLoc.formatted_address}}</span>
                    </div>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item @click="savePos(item)" v-for="item in nearByPlaces" :key="item.place_id" class="py-1" :value="item">
                    <v-btn class="white" outlined small icon @click.stop.prevent="SetPos(item)" style="position:absolute; right: 1px">
                      <v-icon color="red darken-1">mdi-google-maps</v-icon>
                    </v-btn>
                    <div class="flex-column d-flex" style="width:95%">
                      <span>{{item.name}}</span>
                      <span style="font-size:12px; line-height: 1; color: #c5c5c5">{{item.vicinity}}</span>
                    </div>
                  </v-list-item>
                </v-list-item-group>
                
                <v-list-item-group color="primary" v-model="selected" v-else>
                  <v-list-item v-for="(item,index) in searchResult" :key="index" @click="savePos(item)">
                    <v-btn class="white" outlined small icon @click.stop.prevent="SetSearchPos(item)" style="position:absolute; right: 1px">
                      <v-icon color="red darken-1">mdi-google-maps</v-icon>
                    </v-btn>
                    <div class="flex-column d-flex" style="width:95%">
                      <span>{{item.name}}</span>
                      <span style="font-size:12px; line-height: 1; color: #c5c5c5">{{item.formatted_address}}</span>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </perfect-scrollbar>
          </div>
          <div class="green lighten-4" style="width:60%">
            <widget-map
              ref="widgetMap"
              v-on:updateNearByPlaces="updateNearByPlaces"
              v-on:updateSearch="updateSearch"
              :bl_location_permission="bl_location_permission"
              v-on:updateCurrLoc="updateCurrLoc"
              :pos="pos"
            ></widget-map>
            <v-btn class="white" outlined small icon @click="ToMarker" style="position:absolute; bottom:160px; right: 25px">
              <v-icon color="red darken-1">mdi-google-maps</v-icon>
            </v-btn>
            <v-btn class="white" small icon @click="ToOwnLocation" style="position:absolute; bottom:125px; right: 25px">
              <v-icon size="20" color="success">mdi-crosshairs-gps</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import mixin from "@/components/dialogButtons/mixin";
import widgetMap from "../inputWidgets/comps/WidgetMap";

export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: Object,

    attach: String,
    showingDialog: Boolean,
    readonly:{
      type: Boolean,
      default: false
    },
    cssSelect:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userValue: {
        zoom: 14,
        pos: {
          lat: 22.3193,
          lng: 114.1694
        },
        own: {
          lat: null,
          lng: null,
          name: null,
          address: null
        }
      },
      bl_location_permission: false,
      nearByPlaces: [],
      selected: null,

      tempSearch: "",
      search: "",
      currLoc: {},
      ownLoc: {},
      searchResult: [],
      pos:{},
      HK_POS: {
        lat: 22.3193,
        lng: 114.1694
      },
    };
  },

  mixins: [mixin],
  components: {

    widgetMap,
  },
  computed:{
    fields () {
      if (!this.model) return []

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    isPublic(){
      return this.$store.getters.isPublicRoute
    }
  },
  watch: {
    showingDialog: async function(newVal, oldVal) {
      if(newVal){
        this.locatorButtonPressed()
        const vm = this;
        let bl_set = false
        if(typeof vm.value == "object" && vm.value != null){
          if(vm.value.hasOwnProperty('geometry')){
            if(vm.value.geometry.location){
              vm.pos.lat = this.value.geometry.location.lat
              vm.pos.lng = this.value.geometry.location.lng
              vm.currLoc = JSON.parse(JSON.stringify(vm.value))
              bl_set = true
            }
          }  
        }
        if(!bl_set){
          vm.pos = vm.HK_POS
          vm.currLoc = await vm.geocodeToAddress(vm.HK_POS)
        }
      }
    },
    async search (val) {
      if(val=='') return
      let vm = this
      const urlCommand = '/map/place/autocomplete/' +
        val

      if (this.isPublic){
        this.axios.get(this.$store.getters.appHost + urlCommand)
         .then(response => {
          this.count = response.data.length
          this.searchResult = response.data
        })
        .catch(err => {
            console.log(err)
      })       
      }else {
      const getParams = {
        urlCommand
      }

      await vm.$store.dispatch('AUTH_GET', getParams)
        .then(response => {
          this.count = response.length
          this.searchResult = response
        })
        .catch(err => {
            console.log(err)
      })
      }

    },
  },
  
  methods: {
    saveOwnLoc(){
      // alert("saveOwnPos")
      // console.log(this.userValue.own)
      this.$emit('input', this.ownLoc)
      // this.showingDialog = false
      this.$emit('updateShowingDialog', false)
    },
    saveCurrLoc(){
      this.$emit('input', this.currLoc)
      this.$emit('updateShowingDialog', false)
    },
    async savePos(pos){
      console.log(pos)
      let vm = this
      let urlCommand = '/map/place/details/' +
        pos.place_id;


      if (this.isPublic){
        this.axios.get(this.$store.getters.appHost + urlCommand)
        .then(res => {
          pos = res.data.result
        })
      }else {
      var getParams = {
        urlCommand
      }
      await vm.$store.dispatch('AUTH_GET', getParams)
      .then(response => {
        pos = response.result
      })
      }


      this.$emit('input', pos)
      // this.showingDialog = false
      this.$emit('updateShowingDialog', false)
    },
    updateSearch(str){
      this.search = str
      this.tempSearch = str
    },
    SetSearchPos(item){
      let vm = this
      this.pos = item.geometry.location
      this.ToMarker()
    },
    onEnter: function(event) {
      this.search = this.tempSearch
      //  this.search = event;
    },
    SetPos(item){
      this.pos = item.geometry.location
      this.ToMarker()
    },
    async ToOwnLocation(){
      if(this.bl_location_permission){
        this.currLoc = JSON.parse(JSON.stringify(this.ownLoc))
        this.pos = this.currLoc.geometry.location
        this.$refs.widgetMap.panToTarget(this.pos)
      }
    },
    ToMarker(){
      this.$refs.widgetMap.panToTarget(this.pos)
    },
    async locatorButtonPressed() {
      let vm = this
      await navigator.geolocation.getCurrentPosition(
        async function(position) {
          console.log(position) 
          vm.ownLoc = await vm.geocodeToAddress({lng:position.coords.longitude, lat: position.coords.latitude})
          vm.bl_location_permission = true
          let bl_set = false
          console.log(typeof vm.value)
          if(typeof vm.value == "object" && vm.value != null){
            if(vm.value.hasOwnProperty('geometry'))
              if(vm.value.geometry.location != vm.HK_POS)
              {
                // vm.pos = vm.value.geometry.location
                // vm.currLoc = vm.value
                bl_set = true
              }
          }
          if(!bl_set){
            vm.pos = vm.ownLoc.geometry.location
            vm.currLoc = vm.ownLoc
          }
          vm.$refs.widgetMap.panToTarget(vm.pos)
        },
        async error => {
          console.log(error.message);
          // vm.bl_location_permission = false
          // if(vm.value!=""){
          //   vm.pos = vm.value.geometry.location
          //   vm.currLoc = vm.value
          // }else{
          //   vm.pos = vm.HK_POS
          //   vm.currLoc = await vm.geocodeToAddress(vm.HK_POS)
          // }
        },
      )
      // vm.ToMarker()
      // vm.$refs.widgetMap.setMapCenter(vm.pos)
    },
    async geocodeToAddress(pos){
      let vm = this
      const urlCommand = '/map/geocode/' +
        pos.lat + ',' + pos.lng
        var res = {}

        if (this.isPublic){
          await this.axios.get(this.$store.getters.appHost + urlCommand)
          .then(async res => {
            const url = this.$store.getters.appHost + '/map/place/details/' + 
            res.data.results[0].place_id

            await this.axios.get(url)
            .then(response => {
              console.log(response)
              res = response.data.result
              // this.ownLoc = response.result
            })
          })
          .catch(err => {
          console.log(err)
      })
          
        }else {
      const getParams = {
        urlCommand
      }
      
      await vm.$store.dispatch('AUTH_GET', getParams)
        .then(async (response) => {
          console.log('geocodeToAddress :: response: ', response.results[0])

          var getParams2 = {
            urlCommand: '/map/place/details/' + 
            response.results[0].place_id
          }
          await vm.$store.dispatch('AUTH_GET', getParams2)
          .then(response => {
            console.log(response)
            res = response.result
            // this.ownLoc = response.result
          })
        })
        .catch(err => {
          console.log(err)
      })
        }

      return res
    },
    updateNearByPlaces(nearByPlaces){
      this.nearByPlaces = nearByPlaces
      console.log('nearByPlaces',this.nearByPlaces)
    },
    updateValue(val) {
      const vm = this;
      vm.userValue.pos = val.pos;
      vm.userValue.zoom = val.zoom;
    },
    onCommandHandler(payload) {
      const vm = this;
      console.log("onCommandHandler :: payload: ", JSON.stringify(payload));
      switch (payload.command) {
        case "ok":
          vm.$emit("input", vm.userValue);
          vm.$emit("updateShowingDialog", false);
          // vm.showingDialog = false;
          break;
        case "cancel":
          // vm.showingDialog = false;
          vm.$emit("updateShowingDialog", false);
          break;
      }
    },
    updateCurrLoc(loc){
      this.currLoc = loc
      console.log(this.currLoc)
    }
  },
};
</script>

<style lang="scss">
/*.dialogButtonMap {*/
/*height: 90%;*/
/*}*/
.dialog-button-map {
  height: 480px;
}

.reset-button {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 36px;
  width: 36px;
  padding: 0;
  margin: 0;
  min-width: 36px;
}
.currLabel{
  font-size: 12px !important;
}
</style>
