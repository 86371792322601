<template>
  <div>
    <div class="d-flex">
      <label>{{ label }}</label>
    </div>
    <div class="pa-1 v-text-field--outlined w-100"
         @click="showingDialog=true"
          style="border: 1px solid darkgray;min-height: 50px;">

          <dialog-button-select-employees
        ref="dialogButtonSelectEmployees"
        v-model="showingDialog"
        :multiple="isMultiple"
        :addButton="false"
        @submit="setDefaultValue"
        :selectedValue="propertyValues.defaultMember"></dialog-button-select-employees>

      <selected-person-tag
          v-for="(employeeInfo,index) in propertyValue"
          :key="employeeInfo._id"
          :employeeId="employeeInfo._id"
          :index="index"
          :info="employeeInfo"
          deleteCommand="removeMemberByIndex"
          clickCommand="removeMemberByIndex"
          @onCommand="onCommandHandler"
          :name="employeeInfo.employeeName"
          :teamId="employeeInfo.teamId"></selected-person-tag>

    </div>

  </div>
</template>
<script>
  /*
  employeeInfo: {
    employeeID: 2343,
    employee
*/

  // propertyValue = [
  //  {
  //    employeeId: .............,
  //    employeeName: ................
  //    teamName: .............
  // }
  import mixin from './mixin'
  import dialogButtonSelectEmployees from '@/components/dialogButtons/DialogButtonSelectEmployees'

  import selectedPersonTag from '@/components/dialogs/comps/SelectedPersonTag'

  export default {
    mixins: [mixin],
    components: {
      dialogButtonSelectEmployees,
      selectedPersonTag
    },
    data(){
      return {
        showingDialog: false
      }
    },
    computed: {
      isMultiple () {
        return this.getPropertyValue('fieldType', 'single') === 'multiple'
      }
    },
    methods: {
      onCommandHandler (payload) {
        const vm = this
        switch (payload.command) {
          case 'removeMemberByIndex':
            vm.removeMemberByIndex(payload.index)
            break
        }
      },

      removeMemberByIndex (index) {
        const vm = this
        var newValue = JSON.parse(JSON.stringify(vm.propertyValue))
        newValue.splice(index, 1)
        vm.propertyValue = newValue
      },

      removeMember (employeeId) {
        console.log('removeMember : employeeId = ' + employeeId)
        const vm = this
        const index = vm.propertyValue.findIndex(val => val._id === employeeId)
        if (index >= 0) {
          var newValue = JSON.parse(JSON.stringify(vm.propertyValue))
          newValue.splice(index, 1)
          vm.propertyValue = newValue
        }
      },
      setDefaultValue(payload){
        this.propertyValue = payload
      }
    },
    watch: {
      isMultiple(){
        this.propertyValue = []
      }
    }
  }
</script>

<style>
</style>
