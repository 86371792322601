<template>
  <div class="w-e-radio-inline">
    <FormElement
      :label="label"
      :subItem="true"
      :noContent="true"
    />
    <DataSourceSelect
      v-if="selected"
      :value="setting.dataSource"
      name="dataSource"
      shouldShowOutputNumber
      :placeholder="$t('workflow.modules.createRecord.placeholder.dataSource')"
    />
  </div>
</template>

<script>
import FormElement from './../../shared/formElement';
import DataSourceSelect from './../../shared/dataSourceSelect';
import Mixin from './../../mixin';
export default {
  mixins: [Mixin],
  props: {
    label: String,
    selected: Boolean,
  },
  components: {
    FormElement,
    DataSourceSelect,
  },
};

</script>

<style scoped>
  .w-e-radio-inline {
    margin: 10px 0;
  }
</style>
