<template>
  <NavigatorProvider>
    <TableFilterProvider>
      <Navigator @edit="goToEdit" />
      <Table @edit="goToEdit" />
    </TableFilterProvider>
  </NavigatorProvider>
</template>

<script>
import NavigatorProvider from "./../components/navigator/contexts/provider";
import Navigator from "./../components/navigator";
import TableFilterProvider from "./../components/tree/table/filter/contexts/provider";
import Table from "./table";
import Routes from '@/const/workflow/routes';
import Mixin from './../mixin';

export default {
  mixins: [Mixin],
  name: 'RecordListWorkflows',
  components: {
    NavigatorProvider,
    Navigator,
    TableFilterProvider,
    Table,
  },
  methods: {
    goToEdit () {
      this.$store.commit('savePrevRoute', this.$route);
      this.$router.push({
        name: Routes.workflowEditor,
        params: this.currentRouteParams,
      });
    }
  },
}
</script>

<style scoped>
  .wrapper {
    margin: 20px auto;
    background: #fff;
    padding: 0;
  }
</style>