<template>
  <div class="flex-grow-1 d-flex pa-2" style="height: 0;">
    <div class="flex-grow-1 page-form d-flex flex-column justify-start">
      <v-card flat tile class="flex-grow-1 ma-0 d-flex flex-column" style="height: 0;">
        <form-title
            v-if="panel"
            ref="formTitle"
            :builderButton="true"
            builderRouteName="adminPanels"
            :title="selectedMenuItem?selectedMenuItem.title:''"
            :buttons="[]"
            :loading="loading"
            @onCommand="onCommandHandler"
        >
        <template v-slot:prepend-action-button-set="{disabled}">
          <div class="panel-date-filter d-flex align-end" style="font-size:12px">
            <div>{{$t("panel.filter")}} :</div>
            <div style="width:150px">
              <v-select
                outlined
                height="25"
                class="ml-1" 
                :items="dateFields"
                item-value="value"
                item-text="name"
                v-model="filterDateField"
                hide-details
                style="font-size:12px"
                :menu-props="{offsetY: true, contentClass:'denseList'}"
              >
                <template v-slot:item="{item}">
                  <div>{{$t(`widgets.${item.name}`)}}</div>
                </template>
                <template v-slot:selection="{item}">
                  <div>{{$t(`widgets.${item.name}`)}}</div>
                </template>
              </v-select>
            </div>
            <div style="min-width:220px">
              <dialog-period-date
                :rules="[]"
                :isLayout="false"
                :isReadOnly="false"
                :isPeriod="true"
                :hasError="false"
                v-model="dateRange"
                @input="(v)=>dateRange=v"
              />
            </div>
          </div>
          <v-btn
            v-if="elements.length>0"
            v-on="on"
            fab 
            class="elevation-0 ml-2"
            dark 
            color="#AC3E79"
            width="32"
            height="32"
            @click="exportCharts"
          > 
          <v-icon size="18" style="margin-bottom:2px;">mdi-export-variant</v-icon>
        </v-btn>
        </template>
        <template v-slot:prepend-buttons="{disabled}">
            <discussion-control-button
              class="ml-1"
              :disabled="disabled"
              v-if="drawerNotEmpty"
              v-model="showDrawer"
            />
          </template>
        </form-title>
        <v-card-text class="px-0 d-flex flex-column flex-grow-1 panel-wrapper" style="height:200px; overflow:auto; background-color:#f2f2f2">
            <div style="background-color:#f2f2f2;">
              <GridCanvas
                :key="gridCanvasUpdateKey"
                bgColor="#f2f2f2"
                v-if="!bl_dialog"
                :elements="elements"
                @update_elements="update_elements"
                :draggable="false"
                :resizable="false"
                :bl_hover="false"
                :isBuilder="false"
                :extraDateFilter="extraDateFilter"
              />
            </div>
          </v-card-text>
      </v-card>
    </div>
    <v-dialog
        persistent
        hide-overlay
        v-model="bl_dialog"
        v-if="bl_dialog"
        :transition="false"
      >
        <PanelCanvas
          ref="panelCanvas"
          :bl_dialog="bl_dialog"
          v-if="bl_dialog"
          @update_bl_dialog="update_bl_dialog"
          :isResizing="isResizing" 
          :minWidth="minWidth" 
          :maxWidth="maxWidth" 
          :minHeight="minHeight"
          :maxHeight="maxHeight"
        />
      </v-dialog>
      <ExportDialog
        v-if="showingChartExportDialog"
        v-model="showingChartExportDialog"
        :fields="exportBtns"
        @confirm="exportChartTable"
      />
  </div>
</template>

<script>
import formTitle from '@/components/FormTitle'
  import PanelCanvas from './PanelCanvas'
  import GridCanvas from './GridCanvas'
  import ExportDialog from '@/components/dialogs/ExportDialog'
  import {dateOptions} from '@/const/dateFilter'
  import DialogPeriodDate from '@/components/dialogFields/DialogPeriodDate'
  
  export default {
    components:{
      PanelCanvas,
      GridCanvas,
      formTitle,
      ExportDialog,
      DialogPeriodDate
    },
    data(){
      let vm = this
      return{
        bl_dialog: false,
        // drawer: false,
        dragged: null,
        mousex: 0,
        mousey: 0,
        coordinates:[],
        panel: {
          elements: []
        },
        showingChartExportDialog: false,
        exportBtns: [],
        dateFields: [
          {name: 'createdAt', value: 'createdAt'},
          {name: 'updatedAt', value: 'updatedAt'}
        ],
        filterDateField: 'createdAt',
        filterDateOption: '',
        dateOptions: dateOptions,
        dateRange: [],
        showingDateRangeMenu: false,
        gridCanvasUpdateKey: 1
      }
    },
    activated(){
    },
    mounted() {
      const vm = this;
      vm.loadPanel()
    
    },
    watch: {
      "$route.params.itemId": function(newVal, oldVal) {
        const vm = this
        this.panel = {
          elements: []
        }
        if (newVal && newVal !== oldVal) {
          vm.loadPanel()
        }
      },
      bl_dialog(val){
        if(val){
          this.$nextTick(function () {
            this.$refs.panelCanvas.elements = JSON.parse(JSON.stringify(this.elements))
            if(this.elements.length > 0)
              this.$refs.panelCanvas.count = Math.max(...this.elements.map(item=>item.i))
          })
        }else{
          this.elements = []
          this.loadPanel()
        }
      },
      dateRange(val){
        this.loadPanel()
      },
      filterDateField(val){
        this.loadPanel()
      }
    },
    computed:{
      selectedMenuItem () {
        return this.$store.getters.selectedMenuItem
      },
      selectedMenuItemId () {
        return this.$store.getters.selectedMenuItemId
      },
      elements () {
        return this.panel ? this.panel.elements : []
      },
      extraDateFilter(){
        if(this.dateRange.length){
          return {
            field: this.filterDateField,
            startDate: this.dateRange[0],
            endDate: this.dateRange.length===2? this.dateRange[1]:this.dateRange[0]
          }
        }else{
          return {}
        }
      }
    },
    methods:{
      exportChartTable(tables){
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
          key: "plus_panel_mani",
          data: {
            module_name: this.selectedMenuItem.title,
            func_name: this.$sensors.events.plus_panel_mani.EXPORT_PANEL,
            form_id: this.selectedMenuItem._id
          }
        });
        tables.forEach(item=>{
          try{
            document.querySelector(`#${item}`).click()
          }catch(e){
            console.error('Element Not Found!')
          }
        })
      },
      exportCharts(){
        const exportBtns = document.querySelectorAll(`[id^="export-"]`)
        if(exportBtns.length==1){
          exportBtns[0].click()
        }else{
          this.exportBtns = [...exportBtns].map(item=>{
            return {
              visible: true,
              el: item,
              type: 'data',
              label: item.dataset.caption,
              _id: item.id
            }
          })
          this.showingChartExportDialog = true
        }
      },
      onCommandHandler (payload) {
        const vm = this
        switch (payload.command) {
          case 'showPanelBuilder':
            vm.bl_dialog = true
            break
        }
      },
      async loadPanel(){
        var panelId = this.$route.params.itemId
        const getParams = {
          urlCommand: '/panels/' + panelId
        }
        if(this.dateRange.length == 2){
          getParams.urlCommand = `${getParams.urlCommand}?field=${this.filterDateField}&startDate=${this.dateRange[0]}&endDate=${this.dateRange[1]}`
        }else if(this.dateRange.length == 1){
          getParams.urlCommand = `${getParams.urlCommand}?field=${this.filterDateField}&startDate=${this.dateRange[0]}&endDate=${this.dateRange[0]}`
        }
        await this.$store.dispatch('AUTH_GET', getParams)
          .then(response => {
            this.panel.elements = response.result.elements
            this.gridCanvasUpdateKey++
          })
          .catch(err => {
            console.log(err)
        })
      },
      continueDragging(e) {
        let vm = this
       
        vm.dragged.style.left = (e.pageX - (vm.dragged.offsetWidth / 2))  + 'px'
        vm.dragged.style.top = (e.pageY - (vm.dragged.offsetHeight / 2))  + 'px'
        
        this.mousex = e.pageX;
        this.mousey = e.pageY;
      },

      endDragging(e) {
        document.querySelector("#PanelCanvas").removeEventListener("mousemove", this.continueDragging)
        document.querySelector("#PanelCanvas").removeEventListener("mouseup", this.endDragging)
        this.mousex = 0;
        this.mousey = 0;
        this.dragged = null;
        this.coordinates = [];
      },
      startDragging(e) {
        if (e.type == "mousedown") {
          let vm = this
          vm.dragged = e.target
          vm.dragged.style.left = (e.pageX - (vm.dragged.offsetWidth / 2)) + 'px'
          vm.dragged.style.top = (e.pageY - (vm.dragged.offsetHeight / 2)) + 'px'
          document.querySelector("#PanelCanvas").addEventListener("mousemove", this.continueDragging)
          document.querySelector("#PanelCanvas").addEventListener("mouseup", this.endDragging)
        }
      },
      update_bl_dialog(val){
        this.bl_dialog = val
      },
      handleDatePickerChange(value){
        if(value[0]<value[1])
          this.dateRange = [value[0],value[1]]
        else
          this.dateRange = [value[1],value[0]]
      }
  }
}
</script>

<style>
.draggable2 {
    width:100px;
    height:100px;
    background-color: #FC0;
    margin: 5px;
    cursor: pointer;
    position: absolute;
}
.panel-date-filter .v-input__slot{
  min-height: 25px !important;
}
.panel-date-filter .v-input__append-inner{
  margin-top: 0px !important;
}
.panel-date-filter  .v-input__prepend-outer{
  margin: 0 !important;
}
</style>


