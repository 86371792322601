<template>
<div class="selected-table-display-fields">
  <target-list-header
       @click:header="$emit('click:title')"
       @click:button="$emit('click:titleButton')"
       :editable="true"
       :selected="selected"
       :message="message"
       :title="title"></target-list-header>
  <draggable :list="fieldIds"
             :group="message===''?'field':''"
             :move="checkMove"
             @start="$emit('start')"
             @end="$emit('end')"
             @change="$evt => log($evt)"
             class="field-list">
     <!--<div v-if="placeholder && isEmpty"-->
          <!--slot="header"-->
          <!--class="text-center"-->
     <!--&gt;-->
       <!--&lt;!&ndash;style="position:absolute;width:100%;top:20px;left:0;"&ndash;&gt;-->
          <!--<v-avatar color="grey"-->
                    <!--size="48">-->
            <!--<span class="white&#45;&#45;text text-h7">-->
            <!--{{ $t('general.all') }}-->
            <!--</span>-->
          <!--</v-avatar>-->
     <!--</div>-->
    <v-list-item
        class="list-group-item"
        v-for="fieldId in visibleFieldIds"
        :key="fieldId"
        @click.native="$emit('click:item', fieldId)">
      <v-list-item-icon>
        <v-icon size="20">mdi-arrow-all</v-icon>
      </v-list-item-icon>
        <v-list-item-title>
          <span style="font-size:14px">{{ fieldIdLabelMap[fieldId]}}</span>
        </v-list-item-title>

        <v-list-item-action
          v-if="columnSummary"
          class="ma-0 text-right justify-end pr-0">
          <!-- <div class="d-flex flex-row justify-end align-center">
            <v-btn v-if="isSummableFieldId(fieldId)"
                class="pa-0 tiny-button mr-1" tile
                @click.prevent.stop="onSummationIconClicked(fieldId)"
                x-small :color="useSum(fieldId) ? 'primary' : 'muted'">
              <v-icon class="pa-0" small>mdi-sigma</v-icon>
            </v-btn>
            <v-btn 
                class="pa-0 tiny-button" tile
                @click.prevent.stop="onCountingIconClicked(fieldId)"
                x-small :color="useCount(fieldId) ? 'primary' : 'muted'">
              <v-icon class="pa-0" small>mdi-counter</v-icon>
            </v-btn>
          </div> -->
        </v-list-item-action>
    </v-list-item>
  </draggable>
</div>
</template>

<script>
  import draggable from 'vuedraggable'
  import targetListHeader from '@/components/dialogs/tabs/comps/TargetListHeader'

  export default {
    components: {
      draggable,
      targetListHeader
    },
    model: {
      prop: 'fieldIds',
      event: 'input'
    },
    props: {
      title: String,
      selected: Boolean,
      fieldIdLabelMap: Array,
      summableFieldIds: {
        type: Array,
        default () {
          return []
        }
      },
      fieldIds: Array,
      columnSummary: Boolean,
      placeholder: String,
      isEmpty: Boolean,
      summationFieldIds: {
        type: Array,
        default () {
          return []
        }
      },
      countingFieldIds: {
        type: Array,
        default () {
          return []
        }
      },
      sourceType: String,
      hiddenList: {
        type: Array,
        default: []
      }
    },
    data () {
      return {
        ALLOWED_TYPES: ['text', 'number', 'amount']
      }
    },
    computed: {
      visibleFieldIds(){
        return this.fieldIds.filter(id=>!this.hiddenList.includes(id))
      },
      // if dropping is not allowed
      // message show what's wrong.
      message () {
        const vm = this
        var result = ''
        if (vm.sourceType) {
          if (!vm.ALLOWED_TYPES.includes(vm.sourceType)) {
            result = vm.$t('messages.onlyAllowTypes')
            var types = []
            for (let i = 0; i < vm.ALLOWED_TYPES.length; i++) {
              const loopType = vm.ALLOWED_TYPES[i]
              types.push(vm.$t('widgets.' + loopType))
            }
            result += types.join(', ')
          }
        }
        return result
      },
      itemIds: {
        get () {
          return this.fieldIds
        },
        set (val) {
          const vm = this
          console.log('SelectedTableDisplayFields :: set val: ', val)
          console.log('SelectedTableDisplayFields (title=' + vm.title + ') set(itemIds): ', val)
          // vm.$emit('input', val)
        }
      },
      fieldInfos () {
        return this.$store.getters.currentForm.fieldInfos
      }
    },
    methods: {
      checkMove ({relatedContext, draggedContext}) {
        this.$emit('dragStart', draggedContext.element)
        return true
        // const relatedElement = relatedContext.element;
        // const draggedElement = draggedContext.element;
        // return null
      },

      // fields can be sum.
      // i.e. number, amount, expression
      isSummableFieldId (fieldId) {
        return this.summableFieldIds.includes(fieldId)
      },

      onSummationIconClicked (fieldId) {
        this.$emit('click:toggleUseSum', fieldId)
      },
      onCountingIconClicked (fieldId) {
        this.$emit('click:toggleUseCount', fieldId)
      },
      // isActive (fieldId) {
      //   return this.activeIds.includes(fieldId)
      // },
      useSum (fieldId) {
        return this.summationFieldIds.includes(fieldId)
      },
      useCount (fieldId) {
        return this.countingFieldIds.includes(fieldId)
      },
      isNumeric (fieldId) {
        return true
      },
      log: function (evt) {
        const vm = this
        console.log('log: (title=' + vm.title + ') evt: ', evt)
        console.log('log: vm.fieldIds = ' + vm.fieldIds.join(','))
        // vm.$emit('input', vm.fieldIds)
      }
    }
  }
</script>

<style>
.selected-table-display-fields .tiny-button {
  min-width: 24px !important;
}
</style>
