export default {
    childTableFields(state) {
        var result = []
        // console.log('childTable.js childTableFields: state.tableInfo: ', state.tableInfo)
        if (state.tableInfo) {
          for (let i = 0; i < state.tableInfo.fieldInfos; i++) {
            const loopFieldInfo = state.tableInfo.fieldInfos[i]
            result.push({
              fieldInfoId: loopFieldInfo._id,
              fieldName: loopFieldInfo.label,
              fieldId: loopFieldInfo.fieldId,
              fieldType: loopFieldInfo.type,
              widgetId: loopFieldInfo.widgetId
            })
          }
        }
        return result
      }
}