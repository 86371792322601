<template>
    <v-dialog 
        v-model="showingDialog"
        width="500"
        @click:outside="saveSetting"
    >
        <v-card>
            <v-card-text class="pa-12">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-end mb-4">
                        <div style="width:40%" class="d-flex justify-space-between">
                            <div class="">Read</div>
                            <div class="">Edit</div>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div v-for="(field, index) in avaliableFieldInfos" :key="field.fieldId" class="d-flex align-center" :class="index!=0?'mt-4':''">
                            <div style="width:60%" class="d-flex align-center">
                                <font-awesome-icon :icon="getIcon(field.type)" class="fa-fw"></font-awesome-icon>
                                <div class="ml-1">{{field.label}}</div>
                                <div class="ml-1">
                                    <v-chip color="success" x-small v-if="checkVisible(field.properties.authorization)">
                                        visible
                                    </v-chip>
                                    <v-chip color="warning" x-small v-else>
                                        hidden
                                    </v-chip>
                                </div>
                            </div>
                            <div class="d-flex flex-grow-1 justify-space-between">
                                <div>
                                    <v-checkbox 
                                        class="ma-0 pa-0 fieldCheckbox"
                                        hide-details
                                        :ripple="false"
                                        v-model="field.canRead"
                                    >
                                    </v-checkbox>
                                </div> 
                                <div>
                                    <v-checkbox 
                                        class="ma-0 pa-0 fieldCheckbox"
                                        hide-details
                                        :ripple="false"
                                        v-model="field.canEdit"
                                    >
                                    </v-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props:{
            value:  Boolean,
            form: Object
        },
        data(){
            return {
                excludedFields:[
                    'updatedAt',
                    'createdAt',
                    'createdBy',
                    'owner',
                ],
                avaliableFieldInfos: []
            }
        },
        computed:{
            showingDialog:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            }        
        },
        mounted(){
            this.avaliableFieldInfos =  
                this.form.fieldInfos
                    .filter(field=>!this.excludedFields.includes(field.type))
                        .map(field=>{
                            return{
                                fieldId: field.fieldId,
                                type: field.type,
                                label: field.label,
                                properties: field.properties,
                                canRead: true,
                                canEdit: true
                            }
                        })
        },
        methods:{
            getIcon(type){
                return this.$store.getters.widgetIconMap? this.$store.getters.widgetIconMap[type]: '';
            },
            checkVisible(authorization){
                if(!authorization || authorization=='') return true
                else return !authorization.split('||').includes('hidden')
            },
            saveSetting(val){
                const saveFields = this.avaliableFieldInfos.map(
                    field=>{
                        return {
                            fieldId: field.fieldId,
                            canRead: field.canRead,
                            canEdit: field.canEdit
                        }
                    }
                )
                this.$emit('saveFieldsSetting', saveFields)
            }
        }
    }
</script>
<style>
    .fieldCheckbox .v-input--selection-controls__input{
        margin-right: 0px !important;
    }
</style>