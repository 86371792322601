<template>
<v-menu offset-y
  class="white"
  :close-on-content-click="false"
  :attach="cssSelect?cssSelect:attach?`#${attach}`:undefined">
  <template v-slot:activator="{on, attrs}">
    <v-text-field
      dense
      solo
      outlined
      :hide-details="true"
      readonly

      v-bind="attrs"
      v-on="on"

      :value="recordTitle"
      ref="relatedTitle"></v-text-field>
  </template>
  <div class="d-flex flex-column" style="height:400px;width:100%;background-color:lightblue;">
    <v-text-field
      class="flex-grow-0 white"
      dense
      solo
      outlined
      append-icon="mdi-table-search"
      clearable
      :hide-details="true"
      @keypress.enter="search"
      @click:clear="clearSearch"
      @click:append="search"
      v-model="searchValue"></v-text-field>
    <v-list dense class="flex-grow-1" style="overflow-y:auto;">
      <v-list-item-group v-model="selectedIndex">
        <v-list-item v-for="item in relatedRecords" 
          :key="item._id">
          <!-- <v-list-item-content> -->
            {{ item ? item[relatedTitleFieldId] : JSON.stringify(item) }}            
          <!-- </v-list-item-content> -->
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
<!-- <div :id="attach" -->
</v-menu>
</template>
<script>

export default {
  name: 'pulldownRelatedTitleField',
  props: {
    readonly: Boolean,
    fieldId: String,
    value: String,
    errorMsg: String,
    hasError: Boolean,
    hideDetails: {
        type: Boolean,
        default: false
    },
    placeholder:{
      type: String,
      default: ''
    },
    attach:{
      type: String,
      default: undefined
    },
    cssSelect:{
      type: String,
      default: undefined
    },
    dataSource: {
      type: String,
      default: ""
    },
    relatedTitleFieldId: {
      type: String,
      default: ''
    },
    idDataMapping: {
      type: Array,
      default () {
        return {}
      }
    }
  },
  model: {
    prop: "value",
    event: "input",
  },
  data () {
    return {
      showingSelection: false,
      relatedRecords: [],
      searchValue: '',
      // countries: countryCodes,
      // countryCode: "hk",
      isValid: true,
      selectedIndex: -1
    };
  },  
  mounted() {
    console.log('mounted')
    this.loadData()
  },
  watch: {
    selectedRecord: function (newVal) {
      const vm = this
      console.log('watch(selectedrecord) : newVal: ', newVal)
      if (newVal) {
        vm.$emit('input', [newVal._id])
      }
    }
  },
  methods: {
    clearSearch () {
      this.searchValue = ''
      this.loadData()
    },
    search () {
      this.loadData()
    },
    onSelectRecord (record) {
      const vm = this
      vm.selectedRecord = record
      vm.$emit('input', record._id)
    },
    loadData () {
      const vm = this
      console.log('PulldownRelatedTitleField :: loadData')

      // search
      const searchValue = vm.searchValue !== '' ? vm.searchValue : null
    
      // sorting configs
      var sortingConfigs = {}
      sortingConfigs[vm.relatedTitleFieldId] = 1

      // filter configs
      const filterConfigs = {
        filters: [],
        filterAndOr: 'and'
      }

      const payload = {
        formId: vm.dataSource,

        displayFieldIds: [vm.relatedTitleFieldId],
        filterConfigs: filterConfigs,
        keyword: vm.searchValue,
        sortingConfigs: sortingConfigs,
        displayFields: [vm.relatedTitleFieldId]
      }

      vm.$store.dispatch('FETCH_SELECTION_DATA', payload).then(
        response => {
          vm.relatedRecords = response.result
        }
        
      ).catch(
        err =>
          console.log('err: ', err)
      )
    },
    onBlur() {
      if (!this.hasError) {
        this.$emit("blur");
      }
    },
    filterOptions(item, queryText, itemText){
        
         const searchText = queryText.toLocaleLowerCase()
         const region = item.name.toLocaleLowerCase()
         return region.indexOf(searchText) > -1 || itemText.indexOf(searchText) > -1
    }
  },
  computed: {
    selectedRecord () {
      const vm = this
      var result = null
      if (vm.selectedIndex >= 0) {
        result = vm.relatedRecords[vm.selectedIndex]
      }
      return result
    },
    recordTitle () {
      const vm = this      
      var result = ''
      if (vm.selectedRecord) {
        console.log('PulldownRelatedTitleField.computed(recordTitle): vm.selectedRecord: ', vm.selectedRecord)
        result = vm.selectedRecord[vm.relatedTitleFieldId]
      } else {
        console.log('recordTitle :: vm.value (' + (typeof vm.value) + ')= ' + JSON.stringify(vm.value))
        if (vm.value && Array.isArray(vm.value) && vm.value.length>0) {
          const idArray = vm.value
          result = vm.idDataMapping[vm.value[0]].title
        }
        // console.log('PUlldownRelatedTitleField :; recordTitle result= ' + result)
      }
      // if (vm.selectedRecord) {
      //   result += ' (' + vm.selectedRecord._id + ')'
      // }
      return result
    }
  },
};
</script>
<style>
div.pulldown-related-title-field.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__prepend-outer {
  margin: 0px !important;
  margin-top: 0px !important;
}

.record-title {
    font-size: 12px
}

.search-field input {
  background-color: white !important;
}
</style>
