<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :fieldWidth="fieldWidth"
      :isHidden="isHidden"
      :noIcon="noIcon"
      :label="fieldName"
      :tooltipText="description">
    <v-btn small @click="onClicked" :class="{ 'workflow-customize-form-record-hide': hideInput }" color="muted"
      class="w-100">
      {{ showValue }}
    </v-btn>
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'

export default {
  name: 'inputWidgetText',
  mixins: [mixin],
  components: {
    inputWidgetContainer
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  computed: {
    showValue () {
      return '{hello world}'
    }
  }
}
</script>

<style scoped>
.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
</style>
