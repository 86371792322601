<template>
<div class="flex-grow-1 d-flex flex-column fill-height mt-0 w-100" ref="form">
  <!-- Panel Setup -->
  <v-row class="flex-grow-0 mt-1 mb-2"
    style="border-bottom: 1px solid lightgrey;">
    <v-col cols="3">
      <label>{{ $t('general.title') }}</label>
      <v-text-field 
        ref="title"
        solo
        dense
        :rules="[()=>!!panelSettingsTitle || $t('messages.thisFieldIsRequired')]"
        outlined
        :value="panelSettingsTitle"
        @change="val=>{panelSettings.title=val}"/>   
    </v-col>
    <v-col cols="6">
      <label>{{ $t('general.subTitle') }}</label>
      <v-text-field 
        ref="subTitle"
        solo
        dense
        outlined
        :value="panelSettings?panelSettings.subTitle:''"
        @change="val=>{panelSettings.subTitle=val}"/>  
    </v-col>
    <v-col cols="3">
      <label>{{ $t('calendar.colorSetup') }}</label>       
      <input-radio-toggle
        :value="panelSettingsColorBasedOn"
        @input="val=>{updateColorBasedOn(val)}"
        dense
        :options="calendarColorOptions"></input-radio-toggle>
    </v-col>
  </v-row>
 
  <!-- Form Selection and Settings -->
  <div class="d-flex flex-row flex-grow-1">

    <!-- Form Selection -->
    <div class="flex-grow-0 d-flex flex-column pr-2 fill-height">
      <div class="d-flex flex-row align-start fill-height">
        <div class="fill-height d-flex flex-column"
          style="min-width:300px;">          
          <label>{{ $t('calendar.formSelection') }}</label>          
          <v-text-field
            flat
            clearable
            class="w-100 flex-grow-0"
            dense solo outlined
            :placeholder="$t('calendar.inputSearch')"
            :hide-details="true"
            v-model="searchValue"></v-text-field>
          <div class="flex-grow-1">
            <v-list subheader
              class="module-menu-list rounded"
              style="overflow-y:auto;height:0;min-height:100%;border:1px solid lightgrey;">
                <div v-for="(moduleMenu, index) in filteredModuleMenus"
                  :key="index">
                  <v-list-item-group
                    v-model="selectedIndex"
                    v-if="moduleMenu.menus.length>0"
                    :key="index">
                    <v-subheader class="w-100">
                      <div class="w-100 line-height-1" style="border-bottom:1px solid lightgrey;">
                        {{ moduleMenu.label }}
                      </div>
                    </v-subheader>
                    <v-list-item v-for="(menu, index) in moduleMenu.menus"
                      :key="index"
                      @click="addForm(menu)">
                      <v-icon left>{{ menu.icon }}</v-icon>
                      {{ menu.label }}
                      <v-icon v-if="loading && processingFormId===menu.formId"
                        class="ml-1">mdi-spin mdi-loading</v-icon>
                    </v-list-item>
                  </v-list-item-group>
                </div>
              <!-- </template> -->
            </v-list>
          </div>
        </div>
      </div>
    </div>
    <!-- Form Settings -->
    <div class="flex-grow-1 ml-2 mr-2 fill-height d-flex flex-column position-relative">
      <div v-if="formConfigs.length>0" class="flex-grow-0 d-flex flex-row"
        style="margin-bottom:1px;">
        <label>{{ $t('calendar.selectedForms') }}</label>
        <v-spacer></v-spacer>
        <v-btn v-if="formConfigs && formConfigs.length>1"
          @click="removeAllForms"
          x-small color="error">
          <v-icon left>mdi-close</v-icon>
          {{ $t('buttons.clearAll') }}
        </v-btn>
      </div>
      <div class="flex-grow-1 d-flex fill-height flex-column">
        <div ref="calendarFormConfigContainer"
          style="height:0;min-height:100%;overflow-y:auto;">
          <calendar-form-config
            ref="calendarFormConfigs"
            v-for="(formConfig, index) in formConfigs"
            :key="index"
            :icon="getFormIcon(formConfig.formId)"
            :formConfig="formConfig"
            :colorBasedOn="panelSettings.colorBasedOn"
            @delete="removeFormConfigByIndex(index)"
            @update="updateFormConfig"></calendar-form-config>
          </div>
      </div>
    </div>
  </div>
  <confirm-delete-dialog 
  ref="confirmDeleteDialog"></confirm-delete-dialog>
</div>
</template>

<script>
import appColorMixin from '@/mixins/appColorMixin'
import calendarFormConfig from './CalendarFormConfig'
import inputRadioToggle from '@/components/formComps/InputRadioToggle'
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import {getTitleFieldId, getFieldInfosByType} from '@/helpers/FormHelpers'

export default {
  name: 'calendarSettingsForm',
  mixins: [appColorMixin],
  components: {
    calendarFormConfig,
    inputRadioToggle,
    confirmDeleteDialog
  },
  data () {
    return {
      searchValue: '',
      selectedIndex: -1,
      loading: false,
      processingFormId: ''
    }
  },
  model: {
    prop: 'panelSettings',
    event: 'input'
  },
  props: {
    panelSettings: Object
  },
  computed: {
    panelSettingsTitle () {
      return this.panelSettings ? 
        this.panelSettings.title : ''
    },

    panelSettingsSubTitle () {
      return this.panelSettings ? 
        this.panelSettings.subTitle : ''
    },

    calendarColorOptions () {
      const vm = this
      return [
        {label: vm.$t('calendar.random'), value: 'random'},
        {label: vm.$t('calendar.byForm'), value: 'byForm'},
        {label: vm.$t('calendar.byCategoryField'), value: 'byCategoryField'},
      ]
    },

    panelSettingsColorBasedOn () {
      const vm = this
      let result = this.calendarColorOptions[0].value
      if (this.panelSettings && this.panelSettings.colorBasedOn) {
        const selectedOption = vm.calendarColorOptions.find(option => option.value === vm.panelSettings.colorBasedOn)
        if (selectedOption) {
          result = selectedOption.value
        }
      }
      return result
    },
    selectedFormIds () {
      return this.panelSettings && this.panelSettings.formConfigs ?
        this.panelSettings.formConfigs.map(config => config.formId) : []
    },
    formConfigs () {
      return this.panelSettings ? this.panelSettings.formConfigs: []
    },
    modules () {
      return this.$store.getters.currentApp ?
        this.$store.getters.currentApp.modules :
        []
    },
    filteredModuleMenus () {
      const vm = this
      // [
      //    {
      //      type: ['module'|'form'],
      //      label: '...',
      //      formId: '...'
      //    }
      //  ]
      let result = [
        // { label: 'module 1', menus: [...] }
      ]
      for (let i = 0; i < vm.modules.length; i++) {
        const loopModule = vm.modules[i]
        const menus = vm.getModuleMenu(loopModule.menu);
        const module = {
          label: loopModule.title,
          menus: menus,
          type: 'module'
        }
        result.push(module)
      }
      return result
    },
    appId () {
      return this.$store.getters.currentApp._id
    }
  },
  methods: {
    validateForm () {
      let formWithErrors = 0;
      for (let i = 0; i < this.panelSettings.formConfigs.length; i++) {
        if (!this.$refs.calendarFormConfigs[i].validated()) {
          formWithErrors++;
        }
      }
      return formWithErrors;
    },
    removeAllForms () {
      const vm = this
      vm.$refs.confirmDeleteDialog.confirm(() => {
        this.panelSettings.formConfigs = []                
      })
    },
    removeFormConfigByIndex (index) {
      this.panelSettings.formConfigs.splice(index, 1)
    },
    getFormIcon (formId) {
      const vm = this
      let result = ''      
      for (let i = 0; i < vm.modules.length; i++) {
        const loopModule = vm.modules[i]
        const menus = vm.getModuleMenu(loopModule.menu)
        const menuOfForm = menus.find(menu => menu.formId === formId)
        if (menuOfForm) {
          result = menuOfForm.icon
          break
        }
      }
      return result
    },

    updateColorBasedOn (colorBasedOn) {
      this.$set(this.panelSettings, 'colorBasedOn', colorBasedOn)
    },

    getDarkColor(payload) {
      const vm = this      
      let result = ''
      for (let i = 0; i < vm.TEXT_THEMES.dark.length; i++) {
        const loopColor = vm.TEXT_THEMES.dark[i]
        if (!payload.exceptions.includes(loopColor)) {
          result = loopColor
          break
        }
      }
      return result
    },

    getLightColor (payload) {
      const vm = this
      let result = ''
      for (let i = 0; i < vm.TEXT_THEMES.light.length; i++) {
        const loopColor = vm.TEXT_THEMES.light[i]
        if (!payload.exceptions.includes(loopColor)) {
          result = loopColor
          break
        }
      }
      return result
    },

    getNextColor () {
      const vm = this
      const colorsUsed = vm.panelSettings.formConfigs.map(config => config.color)
      let result = ''
      for (let i = 0; i < vm.BASIC_COLORS.length; i++) {
        const loopColor = vm.BASIC_COLORS[i]
        if (!colorsUsed.includes(loopColor)) {
          result = loopColor
          break
        }
      }
      if (result === '') {
        result = vm.getDarkColor({exceptions: colorsUsed})
        if (!result) {
          result = vm.getLightColor({exceptions: colorsUsed})
          if (!result) {
            result = vm.getRandomColor()
          }
        }
      }
      return result
    },
    
    getDefaultTitleFieldId (formInfo) {
      let result = '';
      const formTitleFieldId = getTitleFieldId(formInfo);
      const fieldInfo = formInfo.fieldInfos.find(info => info.fieldId === formTitleFieldId);
      if (['text','textCombination'].includes(fieldInfo.type)) {
        result = fieldInfo.fieldId;
      } else {
        const fieldInfos = getFieldInfosByType(formInfo, 'text');
        if (fieldInfos.length > 0) result = fieldInfos[0].fieldId;
      }
      if (!result) {
        const fieldInfos = getFieldInfosByType(formInfo, 'textCombination');
        if (fieldInfos.length > 0) result = fieldInfos[0].fieldId;
      }
      return result;
    },

    addForm (menu) {
      const vm = this
      if (vm.loading) return

      vm.loading = true

      const getData = {
        urlCommand: '/forms/' + menu.formId + '?appId=' + vm.appId
      }
      vm.processingFormId = menu.formId
      vm.$store.dispatch('AUTH_GET', getData).then(
        response => {
          const formInfo = response.result
          const titleFieldId = this.getDefaultTitleFieldId(formInfo); // getTitleFieldId(formInfo)

          let startFieldId = ''
          let endFieldId = ''
          let dateTimeFieldInfos =  getFieldInfosByType(formInfo, 'date')
          dateTimeFieldInfos = dateTimeFieldInfos.filter(info => ['date','datetime'].includes(info.properties.fieldType))
          if (dateTimeFieldInfos.length > 0) {
            startFieldId = dateTimeFieldInfos[0].fieldId
            if (dateTimeFieldInfos.length > 1) {
              endFieldId = dateTimeFieldInfos[1].fieldId
            }
          }
          let singleSelectionFieldId = ''
          const singleSelectionFieldInfos = getFieldInfosByType(formInfo, 'singleSelection')
          if (singleSelectionFieldInfos.length > 0) {
            singleSelectionFieldId = singleSelectionFieldInfos[0].fieldId
          }


          this.panelSettings.formConfigs.push({
            formId: menu.formId,
            titleField: titleFieldId,
            startField: startFieldId,
            endField: endFieldId,
            colorField: '',
            categoryField: singleSelectionFieldId,
            color: vm.getNextColor(),
            formInfo: {
              label: formInfo.label,
              fieldInfos: formInfo.fieldInfos,
              views : formInfo.views.map(view => ({_id:view._id, title:view.title}))
            },
            viewId:(formInfo.views[0] || {})._id || ""
          })
          vm.loading = false
          vm.processingFormId = ''
          vm.selectedIndex = -1

          // scroll into view
          vm.$nextTick(() => {
            vm.$refs.calendarFormConfigs[vm.$refs.calendarFormConfigs.length-1].$el.scrollIntoView()
          })
          
        }
      )
    },
    getModuleMenu (menuItems) {
      const vm = this
      let menus = []
      for (let i = 0; i < menuItems.length; i++) {
        const loopMenu = menuItems[i]
        if (loopMenu.type === 'form') {
          let addForm = false
          if (vm.searchValue) {
            if (loopMenu.title.toLowerCase().includes(vm.searchValue.toLowerCase()))
              addForm = true
          } else {
            addForm = true
          }
    
          if (addForm) {
            menus.push({
              _id: loopMenu._id,
              label: loopMenu.title,
              formId: loopMenu._id,
              icon: loopMenu.icon,
              type: 'form'
            })
          } else if (loopMenu.type==='submenu') {
            // if (loopMenu.children && loopMenu.children.length > 0) {
            //   const childMenu = vm.getModuleMenu(loopMenu.children)
            //   console.log('childMenu ' + loopMenu.title + ' : ', childMenu)
            //   if (childMenu.length > 0) {
            //     menus = menus.concat(childMenu)
            //   }
            // }
          }
        }
      }
      return menus
    }

  }
}
</script>

<style>
.module-menu-list .v-subheader > div {
  border-bottom: 1px solid lightgrey;
  padding-bottom:5px;
}
.module-menu-list .v-list-item {
  min-height: 36px;
}
</style>