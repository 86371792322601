<template>
  <div>
    <DataSourceSelect
      :value="setting.dataSource"
      :label="$t(`workflow.modules.${setting.sourceType}.label.placeholder`)"
      name="dataSource"
      :fetchForRelationship="isRelationPick"
      :placeholder="$t('workflow.modules.retrieveRecord.label.placeholder')"
    />
    <PrintTemplateSelect 
        :value="setting.template"
    />
    <FormatSelect :value="setting.format"/>
  </div>
</template>
<script>
import Mixin from "./../mixin";
import DataSourceSelect from "./../shared/dataSourceSelect";
import PrintTemplateSelect from "./PrintTemplateSelect";
import FormatSelect from "./FormatSelect"
export default {
  mixins: [Mixin],
  components: {
    DataSourceSelect,
    PrintTemplateSelect,
    FormatSelect
  },

  mounted() {
    this.$set(this.setting, "sourceType", "retrieveRecordFromDataSource");
  },
};
</script>
