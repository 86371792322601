var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-2 px-12",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex align-center",staticStyle:{"font-size":"18px"}},[_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t("displayRule.displayRule")))]),_c('v-btn',{staticClass:"elevation-0 ml-4",staticStyle:{"width":"22px","height":"22px"},attrs:{"fab":"","small":"","color":"primary","outlined":""},on:{"click":_vm.addRule}},[_c('v-icon',{staticStyle:{"min-width":"9px","width":"20px"},attrs:{"size":"16"}},[_vm._v("mdi-plus")])],1)],1),(!_vm.rules.length)?_c('div',{staticClass:"grey--text d-flex justify-center align-center",staticStyle:{"font-size":"15px","height":"200px"}},[_vm._v(" "+_vm._s(_vm.$t("displayRule.emptyMsg"))+" ")]):_vm._e(),_c('v-container',{staticStyle:{"overflow":"auto","height":"70vh"}},[_c('v-row',{},_vm._l((_vm.rules),function(rule,index){return _c('v-col',{key:index,attrs:{"cols":"12 px-4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"min-height":"100px","font-size":"14px"},attrs:{"tile":"","outlined":""}},[_c('div',{staticClass:"float-right mr-1 mt-1",class:!hover ? 'd-none' : ''},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{staticClass:"px-1",staticStyle:{"cursor":"pointer"},attrs:{"size":"18","color":hover ? 'info' : ''},on:{"click":function($event){return _vm.editRule(index)}}},[_vm._v("mdi-pencil")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{staticClass:"px-1",staticStyle:{"cursor":"pointer"},attrs:{"size":"18","color":hover ? 'red' : ''},on:{"click":function($event){return _vm.deleteRule(index)}}},[_vm._v("mdi-delete")])]}}],null,true)}),_c('v-switch',{attrs:{"color":"info"},on:{"change":function($event){return _vm.handleActiveChange(rule._id, $event)}},model:{value:(rule.active),callback:function ($$v) {_vm.$set(rule, "active", $$v)},expression:"rule.active"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"white--text d-inline-block px-1 nowrap-ellipsis",class:rule.active ? 'info' : 'grey',staticStyle:{"font-size":"13px","border":"1px solid grey"}},[_vm._v(" "+_vm._s(rule.name)+" ")]),_c('div',{staticClass:"ml-2",staticStyle:{"font-size":"12px","color":"grey"}},[_vm._v(" "+_vm._s(rule.conditions.length)+_vm._s(_vm.$t("displayRule.item"))+_vm._s(_vm.$t("displayRule.condition"))+"，"+_vm._s(rule.actions.length)+_vm._s(_vm.$t("displayRule.set"))+_vm._s(_vm.$t("displayRule.action"))+" ")])]),_c('div',{staticClass:"grey--text px-4 pt-2"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t("displayRule.when")))]),_vm._l((rule.conditions),function(cond,index2){return _c('span',{key:index2},[_vm._l((cond),function(entry,index3){return _c('span',{key:index3},[_vm._v(" "+_vm._s(_vm.getFieldLabel(entry.fieldId))+" "),(
                      entry.fieldType != 'yesNo' &&
                        entry.fieldType != 'signature' &&
                        entry.fieldType != 'attachments'
                    )?_c('u',{},[_vm._v(_vm._s(_vm.$t("filters." + _vm.criteriaConfigs[entry.content.rule].t)))]):_c('u',[_vm._v(_vm._s(_vm.$t("displayRule.is")))]),_vm._v(" "+_vm._s(entry.content.type == 1 ? _vm.getContentVal( entry.content.value, entry.content.rule, entry.fieldType, entry.fieldId ) : _vm.getFieldLabel(entry.content.value))+" "),(index3 != cond.length - 1)?_c('span',{staticClass:"teal white--text",staticStyle:{"padding":"2px 4px"}},[_vm._v(_vm._s(_vm.$t("displayRule.and")))]):_vm._e()])}),(index2 != rule.conditions.length - 1)?_c('span',{staticClass:"warning white--text",staticStyle:{"padding":"2px 5px"}},[_vm._v(_vm._s(_vm.$t("displayRule.ok")))]):_vm._e()],2)})],2),_c('div',{staticClass:"grey--text px-4 pt-1 pb-3"},_vm._l((rule.actions),function(action,index2){return _c('div',{key:index2},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.getAction(action.actionId)))]),_vm._l((action.fields),function(field,index3){return _c('span',{key:index3},[_vm._v(" "+_vm._s(_vm.getFieldLabel(field))+" "),(index3 != action.fields.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])})],2)}),0)])]}}],null,true)})],1)}),1)],1),(_vm.bl_editRuleDialog)?_c('EditRuleDialog',{ref:"EditRuleDialog",attrs:{"ruleIdArr":_vm.ruleIdArr,"formFieldInfos":_vm.formFieldInfos,"formData":_vm.formData,"formUIElementInfos":_vm.formUIElementInfos},on:{"updateRules":_vm.updateRules,"setSnackbar":_vm.setSnackbar},model:{value:(_vm.bl_editRuleDialog),callback:function ($$v) {_vm.bl_editRuleDialog=$$v},expression:"bl_editRuleDialog"}}):_vm._e(),_c('v-snackbar',{attrs:{"min-width":"200","color":_vm.snackbar.color,"top":"","right":""},model:{value:(_vm.snackbar.val),callback:function ($$v) {_vm.$set(_vm.snackbar, "val", $$v)},expression:"snackbar.val"}},[_vm._v(_vm._s(_vm.snackbar.text))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }