<template>
  <v-menu right :close-on-content-click="false" offset-y>
    <template v-slot:activator="{on: menu, attrs}">
      <v-btn        
            color="muted"
            v-bind="attrs"
            v-on="{...menu}"
            class="mx-1"
            dark
            x-small
            fab>
            <v-icon>mdi-shape-plus</v-icon>
      </v-btn>
    </template>

        <!-- :prepend-icon="actionInfo.icon"> -->

    <v-list>
      <v-list-group
        v-for="actionInfo in actions"
        :key="actionInfo.action"
        :prepend-icon="actionInfo.icon"
        no-action>
        <template v-slot:activator>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>{{ actionInfo.label }}</v-list-tile-title>
            </v-list-tile-content>
            <!-- <v-list-tile-action>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-tile-action> -->

          </v-list-tile>
        </template>
        <v-list-Item
          class="d-block"
          v-for="subItem in actionInfo.submenu"
          :key="subItem.value"
          @click="onItemClicked(actionInfo, subItem)">
          <v-list-item-icon></v-list-item-icon>
          <v-list-tile-content>
            <v-list-tile-title>{{ subItem.label }}</v-list-tile-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-icon></v-icon>
          </v-list-tile-action>
        </v-list-Item>

          <!-- <v-list-item-icon>
            <v-icon>{{ actionInfo.icon }}</v-icon>
          </v-list-item-icon>
        {{ actionInfo.label }} -->

        <!-- <v-btn
          :color="actionInfo.color"
          dark
          :disabled="!actionInfo.enabled"
          x-small
          fab
          @click.native="onClickHandler(actionInfo)"
          >
          <v-icon>{{ actionInfo.icon }}</v-icon>
        </v-btn> -->
      </v-list-group>
    </v-list>      
  </v-menu>
<!-- <v-btn
    v-on="on"
    v-bind="attrs"
    class="mx-1"
    dark
    x-small
    fab
    color="error"
    v-if="row.canDelete"
    @click.native="deleteRow(row)"
  >
    <v-icon>mdi-delete</v-icon>
  </v-btn> -->
</template>

<script>
export default {
  name: 'otherActionMenu',
  props: {
    actions: Array
    /*
    otherActions = [
        {label: 'Copy', enabled: true, icon:'mdi-content-copy', color='warning', tooltip: $t("buttons.copy") },
        {label: 'Other', enabled: true, icon: 'mdi-shape-plus', color='primary', tooltip: 'Other Buttons', submenu: []}
    ]
    */
  },

  methods: {
    onActionClicked(actionInfo) {      
      const vm = this
      if (!actionInfo.submenu) {
        const payload = {
          action: actionInfo.action,
          arg: ''
        }
        vm.$emit('onClick', payload)
      }
    },

    onItemClicked(actionInfo, item) {

    }
  }

}
</script>