<template>
<div class="level-block-item d-flex flex-row justify-start pl-2 pr-5">
  <level-data-card
      :item="value"
      class="mr-3"
      :level="level"
      :dragging="dragging"
      @onCommand="onCommandHandler"></level-data-card>

  <draggable
      tag="div"
      :list="items"
      handle=".handle"
      class="d-flex flex-column justify-start level-block-list py-5 pa-1"
      ghost-class="ghost"
      @change="log"
      group="levelItem"
      @start="onDragStarted"
      @end="onDragEnded"
      :key="id">

      <level-block-item
          v-for="(item, index) in items"
          class="mb-0"
          :level="level+1"
          :key="index"
          :dragging="dragging"
          :value="item"
          @input="val=>updateItem(index,val)"
          @onCommand="onCommandHandler">
      </level-block-item>
  </draggable>
  <!--<level-block-list-->
      <!--class="ml-8"-->
      <!--:items="item.children"-->
      <!--@onCommand="onCommandHandler"></level-block-list>-->

</div>
</template>

<script>
import draggable from "vuedraggable";
// import levelBlockList from './LevelBlockList'
import levelDataCard from './LevelDataCard'

export default {
  name: 'levelBlockItem',
  components: {
    draggable,
    levelDataCard
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      DUMMY_LABEL: ''
    }
  },
  props: {
    level: {
      type: Number,
      default: 1
    },
    value: {
      type: Object,
      default: () => ({
        id: 0,
        label: '(Drag Here)',
        children: []
      })
    },
    dragging: Boolean

    // item: {
    //   type: Object,
    //   default () {
    //     return {
    //       children: []
    //     }
    //   }
    // }
  },
  computed: {
    items: {
      get () {
        const vm = this
        var result = []
        if (vm.value) {
          if (vm.value.children && (vm.value.children.length > 0)) {
            // if (vm.value.label !== vm.DUMMY_LABEL) {
              result = vm.value.children
            // }
          } else {
            result = []
            // if (vm.value.label !== vm.DUMMY_LABEL) {
            //   if (!vm.value.children || (vm.value.children.length === 0)) {
            //     result.push({
            //       id: 0,
            //       label: vm.DUMMY_LABEL,
            //       children: []
            //     })
            //   }
            // }
          }
        }
        return result
      },
      set (val) {
        const vm = this
        // console.log('LevelBlockItem :: computed(items) set(val) vm.value: ', JSON.stringify(vm.value))
        var updatedValue = JSON.parse(JSON.stringify(vm.value))
        updatedValue.children = val
        // console.log('LevelBlockItem :: computed(items) set(val): ', JSON.stringify(updatedValue))
        vm.$emit('input', updatedValue)
      }
    }
    // ,
    // item: {
    //   get () {
    //     return this.value
    //   },
    //   set (val) {
    //     this.$emit('input', val)
    //   }
    // }
  },
  methods: {
    updateItem (index, val) {
      const vm = this
      // vm.items[index] = val
      var updatedValue = JSON.parse(JSON.stringify(vm.value))
      updatedValue.children[index] = val
      // console.log('LevelBlockItem :: updateItem Index = ' + index)
      // console.log('LevelBlockItem :: updateItem val = ' + JSON.stringify(val))
      vm.$emit('input', updatedValue)

    },
    onCommandHandler (payload) {
      // console.log('LevelBlockItem :: onCommandHandler :: payload: ', payload)
      this.$emit('onCommand', payload)
    },
    log: function (evt) {
      const vm = this
      window.console.log(evt);
      // vm.$emit('input', vm.items)
      // console.log('LevelBlockItem :: Log => detachFromPanZoom')
      if (evt.added) {
        console.log('log.added id=' + evt.added.element.id)
      } else if (evt.removed) {
        console.log('log.removed id=' + evt.removed.element.id)
      }
      vm.$emit('onCommand', {
        command: 'detachFromPanZoom'
      })
    },
    onDragStarted (evt) {
      console.log('LevelBlockItem :: onDragStarted :: evt: ', evt)
      this.$emit('onCommand', {
        command: 'onDraggingItemStarted'})
    },
    onDragEnded (evt) {
      console.log('LevelBlockItem :: onDragEnded :: evt: ', evt)
      this.$emit('onCommand', {
        command: 'onDraggingItemEnded'})
    }
  }
}
</script>

<style>
  .level-block-item {
    display: inline-block;
  }

  .block-card {
    min-width: 280px;
    border: 2px solid transparent;
  }

  .block-card:hover {
    border-color: royalblue;
  }
</style>
