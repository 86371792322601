import {
  FORM_SELECTIONS,
  FORM_SELECTIONS_FIELDS,
  RESET_EDITOR,
  FETCH_WORKFLOW_DEPENDENCIES,
  FETCH_SUBWORKFLOW,
  FORM_RELATIONSHIP_SELECTIONS_FIELDS,
  FORM_RELATIONSHIP_FIELDS,
  REPLACE_WORKFLOW_DEPENDENCIES,
  FETCH_PARAMETERFIELDS,
  FETCH_WORKFLOW_PARAMETER,
  ADD_WORKFLOW_PARAMETER,
  REMOVE_WORKFLOW_PARAMETER,
} from './action_types';
import { filter, map } from 'lodash';
import SettingUtils from '@/pages/admin/workflow/utils/setting';
const initialState = () => ({
  formSelection: {
    loading: false,
    list: [],
    error: null,
  },
  formFields: {
    loading: false,
    info: {},
    error: null,
  },
  relationshipFormFields: {
    loading: false,
    info: {},
    error: null,
  },
  relationshipFields: {
    loading: false,
    info: {},
    error: null,
  },
  dependencies: {
    loading: false,
    list: [],
    error: null,
  },
  subWorkflows: {
    loading: false,
    list: [],
    error: null,
  },
  parameterFields: {
    loading: false,
    list: [],
    error: null,
  },
  parameterOptions: {
    loading: false,
    list: [],
    error: null,
  },
  addParameter: {
    loading: false,
    // list: [],
    error: null,
  },
  removeParameter: {
    loading: false,
    list: [],
    error: null,
  },
});

const getters = {
  getFormRelationshipFields: state => state.relationshipFields.info,
  fetchingFormRelationshipFields: state => state.relationshipFields.loading,

  getFormSelections: state => state.formSelection.list,
  fetchingFormSelections: state => state.formSelection.loading,

  getFormRelationshipSelectionsFields: state => state.relationshipFormFields.info,
  fetchingFormRelationshipSelectionsFields: state => state.relationshipFormFields.loading,


  getFormSelectionsFields: state => state.formFields.info,
  fetchingFormSelectionsFields: state => state.formFields.loading,

  getDependencies: state => state.dependencies.list,
  fetchingDependenciesFields: state => state.dependencies.loading,

  getParameterFields: state => state.parameterFields.list,
  fetchingParameterFields: state => state.parameterFields.loading,

  getSubWorkflows: state => state.subWorkflows.list,
  fetchingSubWorkflows: state => state.subWorkflows.loading,

  getWorkflowParameters: state => state.parameterOptions.list,
  fetchingWorkflowParameters: state => state.parameterOptions.loading,

  getAddedWorkflowParameters: state => state.addParameter.list,
  addingWorkflowParameters: state => state.addParameter.loading,

  getRemovedWorkflowParameters: state => state.removeParameter.list,
  removingWorkflowParameters: state => state.removeParameter.loading,

  isParameterLoading: state => state.addParameter.loading || state.removeParameter.loading || state.addParameter.loading,
}

const mutations = {
  resetFormState(state) {
    const wasState = initialState();
    state.formSelection = { ...wasState.formSelection };
    state.formFields = { ...wasState.formFields };
    state.dependencies = { ...wasState.dependencies };
  },
  replaceDependencies(state, info) {
    state.dependencies.info = info;
  },

  requestFetchingWorkflowParameterOptions(state) {
    state.parameterOptions.loading = true;
  },
  fetchedWorkflowParameterOptions(state, list) {
    state.parameterOptions.loading = false;
    state.parameterOptions.list = list;
  },
  fetchWorkflowParameterOptionsFailed(state, error) {
    state.parameterOptions.loading = false;
    state.parameterOptions.error = error;
  },

  requestAddingWorkflowParameters(state) {
    state.addParameter.loading = true;
  },
  addedWorkflowParameters(state, list) {
    state.addParameter.loading = false;
    // state.addParameter.list = list;
    state.parameterOptions.list = list;
  },
  addWorkflowParametersFailed(state, error) {
    state.addParameter.loading = false;
    state.addParameter.error = error;
  },

  requestRemovingWorkflowParameters(state) {
    state.removeParameter.loading = true;
  },
  removedWorkflowParameters(state, list) {
    state.removeParameter.loading = false;
    state.removeParameter.list = list;
  },
  removeWorkflowParametersFailed(state, error) {
    state.removeParameter.loading = false;
    state.removeParameter.error = error;
  },


  requestFetchingDependencies(state) {
    state.dependencies.loading = true;
  },
  fetchedDependencies(state, list) {
    state.dependencies.loading = false;
    state.dependencies.list = list;
  },
  fetchDependenciesFailed(state, error) {
    state.dependencies.loading = false;
    state.dependencies.error = error;
  },

  requestFetchingSubWorkflows(state) {
    state.subWorkflows.loading = true;
  },
  fetchedSubWorkflows(state, list) {
    state.subWorkflows.loading = false;
    state.subWorkflows.list = list;
  },
  fetchSubWorkflowsFailed(state, error) {
    state.subWorkflows.loading = false;
    state.subWorkflows.error = error;
  },

  requestFetchingParameterFields(state) {
    state.parameterFields.loading = true;
  },
  fetchedParameterFields(state, list) {
    state.parameterFields.loading = false;
    state.parameterFields.list = list;
  },
  fetchParameterFieldsFailed(state, error) {
    state.parameterFields.loading = false;
    state.parameterFields.error = error;
  },

  requestFetchingFormSelections(state) {
    state.formSelection.loading = true;
  },
  fetchedFormSelections(state, list) {
    state.formSelection.loading = false;
    state.formSelection.list = list;
  },
  fetchFormSelectionsFailed(state, error) {
    state.formSelection.loading = false;
    state.formSelection.error = error;
  },

  requestFetchingFormRelationshipFields(state) {
    state.relationshipFields.loading = true;
  },
  fetchedFormRelationshipFields(state, list) {
    state.relationshipFields.loading = false;
    state.relationshipFields.info = list.length > 0 ? list[0] : {};
  },
  fetchFormRelationshipFieldsFailed(state, error) {
    state.relationshipFields.loading = false;
    state.relationshipFields.error = error;
  },

  requestFetchingFormRelationshipSelectionsFields(state) {
    state.relationshipFormFields.loading = true;
  },
  fetchedFormRelationshipSelectionsFields(state, list) {
    state.relationshipFormFields.loading = false;
    state.relationshipFormFields.info = list.length > 0 ? list[0] : {};
  },
  fetchFormRelationshipSelectionsFieldsFailed(state, error) {
    state.relationshipFormFields.loading = false;
    state.relationshipFormFields.error = error;
  },

  requestFetchingFormSelectionsFields(state) {
    state.formFields.loading = true;
  },
  fetchedFormSelectionsFields(state, list) {
    state.formFields.loading = false;
    let info = list.length > 0 ? list[0] : {};
    info.children = SettingUtils.normalizeHiddenFields(info.children);
    state.formFields.info = info;
  },
  fetchFormSelectionsFieldsFailed(state, error) {
    state.formFields.loading = false;
    state.formFields.error = error;
  },
}

const actions = {
  [RESET_EDITOR] ({commit}) {
    // console.log('Reset Workflow Form State');
    commit('resetFormState');
  },

  [FETCH_WORKFLOW_PARAMETER] ({dispatch, commit}, { appId, workflow }) {
    commit('requestFetchingWorkflowParameterOptions');
    const options = {
      urlCommand: `/workflow/tree/${workflow}/parameteres?appId=${appId}`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedWorkflowParameterOptions', response);
    }).catch(err => {
      commit('fetchWorkflowParameterOptionsFailed', err.message);
    })
  },

  [ADD_WORKFLOW_PARAMETER] ({dispatch, commit}, { workflow, appId, parameters }) {
    commit('requestAddingWorkflowParameters');
    const options = {
      urlCommand: `/workflow/tree/${workflow}/parameteres?appId=${appId}`,
      data: {
        parameters,
      },
    };
    dispatch('AUTH_POST', options).then(response => {
      commit('addedWorkflowParameters', response);
    }).catch(err => {
      commit('addWorkflowParametersFailed', err.message);
    })
  },

  [REMOVE_WORKFLOW_PARAMETER] ({dispatch, commit}, { workflow, appId, removed }) {
    commit('requestRemovingWorkflowParameters');
    const options = {
      urlCommand: `/workflow/tree/${workflow}/parameteres`,
      data: {
        removed,
        appId,
      },
    };
    console.log({
      removed,
      appId,
    });
    dispatch('AUTH_DELETE', options).then(response => {
      commit('removedWorkflowParameters', response);
    }).catch(err => {
      commit('removeWorkflowParametersFailed', err.message);
    })
  },

  [FORM_SELECTIONS] ({dispatch, commit}, appId) {
    commit('requestFetchingFormSelections');
    const options = {
      urlCommand: `/forms/selections?appId=${appId}`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedFormSelections', response);
    }).catch(err => {
      commit('fetchFormSelectionsFailed', err.message);
    })
  },
  [FORM_RELATIONSHIP_FIELDS] ({dispatch, commit}, { appId, formId }) {
    commit('requestFetchingFormRelationshipFields');
    const options = {
      urlCommand: `/forms/selections/relationship/${appId}/${formId}`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedFormRelationshipFields', response ? [response] : []);
    }).catch(err => {
      commit('fetchFormRelationshipFieldsFailed', err.message);
    })
  },
  [FORM_RELATIONSHIP_SELECTIONS_FIELDS] ({dispatch, commit}, { appId, fieldId }) {
    commit('requestFetchingFormRelationshipSelectionsFields');
    const options = {
      urlCommand: `/forms/selections/relationship/${appId}/${fieldId}/fields`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedFormRelationshipSelectionsFields', response ? [response] : []);
    }).catch(err => {
      commit('fetchFormRelationshipSelectionsFieldsFailed', err.message);
    })
  },
  [FORM_SELECTIONS_FIELDS] ({dispatch, commit}, { appId, formId }) {
    return new Promise(done => {
      commit('requestFetchingFormSelectionsFields');
      const options = {
        urlCommand: `/forms/selections/fields/${appId}/${formId._id || formId}`,
      };
      dispatch('AUTH_GET', options).then(response => {
        commit('fetchedFormSelectionsFields', response ? [response] : []);
        done(response);
      }).catch(err => {
        commit('fetchFormSelectionsFieldsFailed', err.message);
        done();
      })
    })
  },
  [REPLACE_WORKFLOW_DEPENDENCIES] ({commit}, list) {
    commit('replaceDependencies');
  },
  [FETCH_PARAMETERFIELDS] ({dispatch, commit}) {
    commit('requestFetchingParameterFields');
    const urlCommand = `/workflow/tree/parameter/fields`;
    const options = {
      urlCommand,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedParameterFields', response);
    }).catch(err => {
      commit('fetchParameterFieldsFailed', err.message);
    })
  },
  [FETCH_SUBWORKFLOW] ({dispatch, commit}, { workflow, ...params }) {
    commit('requestFetchingSubWorkflows');
    let urlCommand = `/workflow/tree/${workflow}/childProcess`;
    if (!workflow) {
      urlCommand = `/workflow/tree/processes`;
    }
    const options = {
      urlCommand,
      options: {
        params,
      },
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedSubWorkflows', response);
    }).catch(err => {
      commit('fetchSubWorkflowsFailed', err.message);
    })
  },
  async [FETCH_WORKFLOW_DEPENDENCIES] ({dispatch, commit}, { workflow, node, form, including=false, isBranch=false, noFieldFound=false, ...params }) {
    commit('requestFetchingDependencies');
    const urlCommand = `/workflow/tree/${workflow}/dependencies`;
    const options = {
      urlCommand,
      options: {
        params: {
          node,
          form,
          including,
          isBranch,
          ...params,
        },
      },
    };
    await dispatch('AUTH_GET', options).then(response => {
      commit('fetchedDependencies', response);
      if (!noFieldFound) {
        let target = response;
        if (form) {
          target = filter(response, ({ form: dForm }) => form === dForm);
        }
        commit('fetchedFormSelectionsFields', target);
      }
    }).catch(err => {
      commit('fetchDependenciesFailed', err.message);
    })
  },
}

export default {
  state: initialState(),
  getters,
  mutations,
  actions
}
