<template>
  <v-card width="300px" height="140">
    <v-toolbar dense flat class="primary white--text">
      <v-icon small color="white">mdi-printer</v-icon>
      <v-text-field
        class="py-2"
        v-if="editingName"
        background-color="white"
        v-model="name"
        solo
        flat
        autofocus
        hide-details
        dense
        @blur="onTitleBlur"
      ></v-text-field>

      <span v-else class="nowrap-ellipsis" @dblclick="editName">{{
        template.templateName
      }}</span>
      <v-spacer />
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon color="white" v-on="on" v-bind="attrs">
            <v-icon small>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="editName" link>
            <v-list-item-title>{{
              $t("printTemplate.rename")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="onDelete" link>
            <v-list-item-title class="red--text">{{
              $t("buttons.delete")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-card-text>{{
      template.viewType === 0
        ? $t("functionToggle.allViews")
        : $t("functionToggle.someViews", { num: template.views.length })
    }}</v-card-text>
    <v-card-actions>
      <!--pop up setting menu-->
      <v-menu
        offset-x
        offset-overflow
        :close-on-content-click="false"
        max-width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="primary" small v-on="on" v-bind="attrs">{{
            $t("printTemplate.range")
          }}</v-btn>
        </template>
        <v-card class="d-flex flex-column" max-height="240px">
          <v-card-text class="px-1 flex-grow-1 overflow-auto">
            <div v-if="type === 1">
              <v-card-subtitle class="py-1">
                {{ $t("printTemplate.fileName") }}
                <v-tooltip top max-width="340">
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("printTemplate.fileNameDescription") }}</span>
                </v-tooltip>
              </v-card-subtitle>
              <v-divider />
              <v-select
                class="pa-1"
                full-width
                dense
                outlined
                hide-details
                :items="textFields"
                clearable
                v-model="template.fileNameField"
                clear-icon="mdi-close"
                @change="onChange('fileNameField', $event)"
              />
            </div>
            <v-card-subtitle class="py-1">{{
              $t("functionToggle.view")
            }}</v-card-subtitle>
            <v-divider />

            <v-radio-group
              v-model="template.viewType"
              dense
              row
              hide-details
              @change="onChange('viewType', $event)"
            >
              <v-radio :value="0" :label="$t('functionToggle.allViews')" />
              <v-radio :value="1" :label="$t('functionToggle.specificView')" />
            </v-radio-group>

            <div v-if="template.viewType === 1" class="px-1">
              <v-checkbox
                v-for="view in views"
                :key="view._id"
                :value="view._id"
                :label="view.title"
                hide-details
                dense
                v-model="template.views"
                @change="onChange('views', template.views)"
              />
            </div>

            <v-checkbox
              v-model="template.pdfOnly"
              dense
              hide-detail
              :label="$t('printTemplate.pdfOnly')"
              @change="onChange('pdfOnly', $event)"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-spacer />
      <v-btn text color="primary" small @click="preview">{{
        $t("printTemplate.preview")
      }}</v-btn>
      <v-btn text color="primary" small @click="edit">{{
        $t("printTemplate.edit")
      }}</v-btn>
    </v-card-actions>
    <confirm-delete-dialog ref="ConfirmDeleteDialog" />
  </v-card>
</template>
<script>
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import { importRelatedRecordFieldType } from "@/const/fieldConstants";

export default {
  props: {
    template: Object,
    type: Number,
  },
  components: {
    ConfirmDeleteDialog,
  },
  data() {
    return {
      editingName: false,
      name: "",
    };
  },
  computed: {
    views() {
      return this.$store.getters.currentForm.views;
    },
    textFields() {
      return (
        this.$store.getters.currentForm &&
        this.$store.getters.currentForm.fieldInfos
          .filter((item) => importRelatedRecordFieldType.includes(item.type))
          .map((item) => ({
            text: item.label,
            value: item.fieldId,
          }))
      );
    },
  },
  methods: {
    editName() {
      this.name = this.template.templateName;
      this.editingName = true;
    },
    onTitleBlur() {
      if (this.name && this.name !== this.template.templateName) {
        this.saveSetting("templateName", this.name, () => {
          this.editingName = false;
        });
      } else {
        this.editingName = false;
      }
    },
    onChange(type, value) {
      this.saveSetting(type, value);
    },
    onDelete() {
      this.$refs.ConfirmDeleteDialog.confirm(() => {
        this.$emit("commandHandler", {
          command: "delete",
          templateId: this.template._id,
        });
      });
    },
    saveSetting(setting, value, cb) {
      this.$emit("commandHandler", {
        command: "saveSetting",
        template: this.template,
        setting,
        value,
        cb,
      });
    },
    edit() {
      this.$emit("commandHandler", {
        command: this.type === 0 ? "editSystemPrint" : "editTemplatePrint",
        templateId: this.template._id,
      });
    },
    preview() {
      this.$emit("commandHandler", {
        command:
          this.type === 0 ? "previewSystemPrint" : "previewTemplatePrint",
        templateId: this.template._id,
      });
    },
  },
};
</script>
<style scoped>
.title-input {
  margin: 2px;
  padding: 6px;
}
</style>
