<template>
    <div class="edit-rich-text-modal" style="height:100%">
      <!-- <span v-if="readonly || !params.row.canEdit">{{extractContent(params.row[params.column.property])}}</span> -->
      <div class ="" v-if="readonly || !params.row.canEdit" style="height:!100%">{{extractContent(params.row[params.column.property])}}</div>
      <div v-else class="d-flex" style="height:100%">
        <v-input
          @click="(!readonly)? showingDialog = true:''"
          hide-details
          class="pb-0 w-100 mb-0 vxe-table-input-box px-2"
          style="font-size:14px; word-wrap: break-word; color: #606266"
        >
          <!-- <div style="overflow: hidden; white-space:nowrap; text-overflow: ellipsis">{{extractContent(params.row[params.column.property])}}</div> -->
          <div style="">{{extractContent(params.row[params.column.property])}}</div>
          <!-- <v-btn @click="showingDialog=true" :ripple="false" icon x-small>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn> -->
        </v-input>
        <quill-editor-dialog
          :attach="attach"
          :cssSelect="cssSelect"
          v-model="showingDialog"
          :content="params.row[params.column.property]"
          @confirm="params.row[params.column.property]=$event"
        />
      </div>
    </div>
</template>

<script>
import QuillEditorDialog from '@/components/dialogs/QuillEditorDialog'
export default {
  name: 'EditRichTextModal',
  components : {
    QuillEditorDialog
  },
  props:{
    params: Object,
    readonly: Boolean,
  },
  data(){
    return {
      showingDialog: false
    }
  },
  watch:{
    showingDialog(val){
      this.$emit('updateTdClass', val)
    }
  },
  computed:{
    attach(){
        return 'tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex
    },
    cssSelect(){
      var path = '#tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex
      if(this.params.fixed!= undefined){
        if(document.querySelector('.vxe-table--fixed-left-wrapper')){
          return '.vxe-table--fixed-left-wrapper' + ' ' + path
        }
      }
      return path
    },
  },
  methods:{
    extractContent(s) {
      if (!s) return ""
      var span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    }
  }
};
</script>
<style>
  .edit-rich-text-modal .v-input__slot, .edit-rich-text-modal .v-input__control{
    height:100% !important;
  }
</style>
