<template>
    <v-menu
        v-model="showing"
        offset-y
        min-width="312"
        :close-on-content-click="false">
        <template v-slot:activator="{ attrs, on }">
            <v-input class="w-100 border pa-1 pb-0"
                    hide-details
                    min-height="32"
                    max-width="100%"
                    v-on="on"
                    v-bind="attrs"
                    plain>
              <v-chip v-for="(userRegion, index) in userRegions"
                      small
                      :key="index"
                      color="muted"
                      class="mr-1 mb-1"
                      @click:close="removeRegionByIndex(index)"
                      close>{{ userRegion }}</v-chip>
            </v-input>
        </template>
        <v-card v-if="fieldInfo" min-height="250" width="312">
            <div class="d-flex flex-row">
                <v-text-field readonly v-model="selectedRegionStr"
                              dense solo
                              :hide-details="true"></v-text-field>
                <v-btn small height="26"
                       @click="showing=false"
                       class="muted">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn small height="26"
                       @click="confirm"
                       class="primary">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </div>
            <v-tabs v-model="tab" height="42">
            <v-tabs-slider color="teal"></v-tabs-slider>
            <v-tab>
                {{$t('regionMenu.area')}}
            </v-tab>
            <v-tab :disabled="fieldInfo.properties.regionType=='province'">
                {{$t('regionMenu.district')}}
            </v-tab>
            <v-tab :disabled="fieldInfo.properties.regionType!='provinceCityState'">
                {{$t('regionMenu.subDistrict')}}
            </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item :transition="false" :reverse-transition="false">
                    <v-card flat>
                        <v-card-text>
                            <v-chip class="ml-2" :class="(selectedAreaId==area._id)?'success':''" @click="setArea(area)"
                                    v-for="area in regions" :key="area._id">
                                {{area.name}}
                            </v-chip>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item :transition="false" :reverse-transition="false">
                    <v-card flat>
                        <v-card-text>
                            <v-chip class="ml-2 mt-1" :class="(selectedDistrictId==district._id)?'success':''"
                                    @click="setDistrict(district)" v-for="district in filteredDistricts"
                                    :key="district._id">
                                {{district.name}}
                            </v-chip>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item :transition="false" :reverse-transition="false">
                    <v-card flat>
                        <v-card-text>
                            <v-chip class="ml-2 mt-1" :class="(selectedSubDistrictId==subDistrict._id)?'success':''"
                                    @click="setSubDistrict(subDistrict)" v-for="subDistrict in filteredSubDistricts"
                                    :key="subDistrict._id">
                                {{subDistrict.name}}
                            </v-chip>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-menu>
</template>

<script>
export default {
  model: {
    prop: 'userRegions',
    event: 'input'
  },
  props: {
    userRegions: {
      type: Array,
      default () {
        return []
      }
    },
    fieldInfo: Object
  },
  data () {
    return {
      showing: false,
      // regions: null,
      tab: 0,
      selectedAreaId: null,
      selectedDistrictId: null,
      selectedSubDistrictId: null,
      regions: Array,
      RegionText: ''
      // ,
      // selectedRegion: []
    }
  },
  computed: {
    fieldId () {
      return this.fieldInfo ? this.fieldInfo.fieldId : ''
    },
    filteredDistricts () {
      let vm = this
      if (this.selectedAreaId) {
        var districts = this.regions.find(item => item._id == vm.selectedAreaId).districts
        if (districts)
          return districts
      }
      return []
    },
    filteredSubDistricts () {
      let vm = this
      if (this.selectedDistrict && this.selectedAreaId) {
        var districts = this.regions.find(item => item._id == vm.selectedAreaId).districts
        console.log(districts)
        if (districts) {
          var subDistricts = districts.find(item => item._id == vm.selectedDistrict).subDistricts
          if (subDistricts)
            return subDistricts
        }
      }
      return []
    },
    selectedRegionStr ( index, name) {
      const vm = this
      var result = []
      if (vm.selectedAreaId) {
        const area = vm.regions.find(item => item._id === vm.selectedAreaId)
        if (area) {
          result.push(area.name)
          if (vm.selectedDistrictId) {
            const district = vm.filteredDistricts.find(item => item._id === vm.selectedDistrictId)
            if (district) {
              result.push(district.name)
              if (vm.selectedSubDistrict) {
                const subDistrict = vm.filteredSubDistricts.find(item => item._id === vm.selectedSubDistrictId)
                result.push(subDistrict.name)
              }
            }
          }
        }
      }
      return result.length > 0 ? result.join(' - ') : ''
    }
  },
  watch: {
    showing (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.tab = 0
      }
    }
  },
  // watch: {
  //   selectedArea (val) {
  //     this.$emit("updateProvince", val)
  //   },
  //   selectedDistrict (val) {
  //     this.$emit("updateState", val)
  //   },
  //   selectedSubDistrict (val) {
  //     this.$emit("updateCity", val)
  //   },
  //   RegionText (val) {
  //     this.$emit("updateRegionText", val)
  //   }
  // },
  mounted () {
    this.fetchRegions()
  },
  methods: {
    resetSelection () {
      this.selectedAreaId = null
      this.selectedDistrictId = null
      this.selectedSubDistrictId = null
      this.tab = 0
    },
    removeRegionByIndex (index) {
      const vm = this
      vm.$nextTick(() => {
        const updatedUserRegions = JSON.parse(JSON.stringify(vm.userRegions))
        updatedUserRegions.splice(index, 1)
        vm.$emit('input', updatedUserRegions)
      })
    },
    confirm () {
      const vm = this
      const updatedUserRegions = JSON.parse(JSON.stringify(vm.userRegions))
      updatedUserRegions.push(vm.selectedRegionStr)
      vm.$emit('input', updatedUserRegions)
      vm.resetSelection()
    },
    async fetchRegions(){
      const vm = this
      var locale = this.$store.getters.user.locale
      const getParams = {
        urlCommand: '/widgets/getRegions/' + locale
      }
      await vm.$store.dispatch('AUTH_GET', getParams)
        .then(response => {
          console.log(response)
          this.regions = response.options
        })
        .catch(err => {
          console.log(err)
        })
    },
    resetValidation () {
      this.$refs.regionText.resetValidation()
    },
    validate () {
      return this.$refs.regionText.validate(true)
    },
    // async updateRegionText () {
    //   var str = ''
    //   let vm = this
    //
    //   var filteredDistrict = await this.filteredDistrict()
    //   var filteredSubDistrict = await this.filteredSubDistrict()
    //   if (vm.selectedArea) {
    //     var area = vm.regions.find(item => item._id == vm.selectedArea)
    //     if (area)
    //       str = area.name
    //     if (vm.selectedDistrict) {
    //       var district = filteredDistrict.find(item => item._id == vm.selectedDistrict)
    //       if (district)
    //         str += ' - ' + district.name
    //       if (vm.selectedSubDistrict) {
    //         var subDistrict = filteredSubDistrict.find(item => item._id == vm.selectedSubDistrict)
    //         if (subDistrict)
    //           str += ' - ' + subDistrict.name
    //       }
    //     }
    //   }
    //   this.RegionText = str
    // },
    setArea (area) {
      const vm = this

      this.selectedSubDistrictId = null
      this.selectedDistrictIdId = null
      this.selectedAreaId = area._id

      if (this.fieldInfo.properties.regionType != 'province')
        this.tab++

      // this.updateRegionText()

      // vm.updateSelectedRegion(0, area.name)
    },
    setDistrict (district) {
      const vm = this

      this.selectedSubDistrictId = null
      this.selectedDistrictId = district._id

      if (this.fieldInfo.properties.regionType == 'provinceCityState')
        this.tab++
      // this.updateRegionText()

      // vm.updateSelectedRegion(1, district.name)
    },
    setSubDistrict (subDistrict) {
      this.selectedSubDistrictId = subDistrict._id

      // vm.updateSelectedRegion(2, subDistrict.name)
      // this.updateRegionText()
    }
  }
}

</script>

<style>
    .region-textfield .v-input--is-readonly .v-input__control > .v-input__slot {
      background-color: white !important;
    }

    .border {
      border: 1px solid #9e9e9e;
      border-radius: 4px;
    }
    .v-slider__thumb-container, .v-slider__track-background, .v-slider__track-fill {
      transition: none !important;
    }
</style>
