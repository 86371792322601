<template>
  <div>
    <PendingSetup
      v-if="pendingState"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <TableSourceView :node="node" dataSourceMode />
      <v-layout justify-space-between align-center class="workflow-shortview-remark">
        {{ $t('workflow.modules.subWorkflow.label.executeView') }}: {{ workflowName }} 
        <span v-if="!released">({{ $t('workflow.modules.subWorkflow.label.waitingRelease') }})</span>
        <v-btn @click="goWorkflow" size="13" text>
          {{ $t('workflow.modules.subWorkflow.label.viewSubWorkflow') }}
        </v-btn>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { get, keys } from 'lodash';
import PendingSetup from './pending';
import Mixin from './mixin';
import TableSourceView from './tableSource';
import WorkflowRoutesName from '@/const/workflow/routes';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  // data() {
  //   return {
  //     updatedWorksheet: null,
  //     updatedSubWorkflow: null,
  //   };
  // },
  methods: {
    goWorkflow() {
      WorkflowSettingUtils.goBlankWindowWorkflow(this.$router, this.subWorkflow);
      // const { current: { params } } = this.$router.history;
      // params.workflowId = this.workflow;
      // const routeData = this.$router.resolve({
      //   name: WorkflowRoutesName.formWorkflowsTree, 
      //   params,
      // });
      // // console.log(window.location.origin, routeData.href);
      // window.open(`${window.location.origin}${routeData.href}`, '_blank');
    }
  },
  computed: {
     pendingState() {
      return ((!this.dataSource && !this.worksheet) || !this.subWorkflow)
    },
    // isUpdatedNode() {
    //   const { updatedNode: v } = this;
    //   if (v._id === this.node._id && v.properties) {
    //     if (v.properties.worksheet) {
    //       this.updatedWorksheet = v.properties.worksheet;
    //     }
    //     if (v.properties.subWorkflow) {
    //       this.updatedSubWorkflow = v.properties.subWorkflow;
    //     }
    //   }
    // },
    released() {
      const { properties } = this.node;
      return properties.subWorkflow && properties.subWorkflow.released;
    },
    dataSource() {

      const { properties } = this.node;
      return get(properties, 'dataSource', '');
    },
    worksheet() {
      const { properties } = this.node;
      return get(properties, 'worksheet', '')
    },
    subWorkflow() {
      const { properties } = this.node;
      return get(properties, 'subWorkflow._id') || 
      get(properties, 'subWorkflow')
    },
    workflowName() {
      const { properties } = this.node;
      return get(properties, 'subWorkflow.name') || 
      this.$t('workflow.modules.subWorkflow.label.unknownSubWorkflow');
    },
    sourceType() {
      return get(setting, 'sourceType', '');
    },
    // updatedNode() {
    //   return this.$store.getters.updatedNode;
    // },
  },
  components: {
    PendingSetup,
    TableSourceView,
  },
};

</script>

<style scoped>
  
</style>
