<template>
  <vxe-table-column v-bind="colDef" class-name="fullCell vxeCellPerson" :show-overflow="false" :edit-render="{name: 'input', autoselect: true, enabled: !readonly}">
    <template v-slot="{row}">
      <simple-person-tag v-if="row[colDef.field]" :name="row[colDef.field].employeeName"></simple-person-tag>
      <simple-person-tag v-else-if="fieldInfo.type === 'createdBy'" :name="$t('publicForm.publicForm')"></simple-person-tag>
    </template>
    <template v-slot:edit="{row, column}">
      <div 
        :class="fieldInfo.type !== 'createdBy'?`edit-person-modal`:``"
        style="width:100%; height:100%;"
        @click="fieldInfo.type !== 'createdBy' && (!readonly && row.canEdit)? openEmployeesDialog(row):''"
      >
        <simple-person-tag v-if="row[fieldId]" :name="row[fieldId].employeeName"></simple-person-tag>
        <simple-person-tag v-else-if="fieldInfo.type === 'createdBy'" :name="$t('publicForm.publicForm')"></simple-person-tag>
      </div>
    </template>
    
    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import simplePersonTag from '@/components/dialogs/comps/SimplePersonTag'
import dialogButtonSelectEmployees from '@/components/dialogButtons/DialogButtonSelectEmployees'
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  components: {
    simplePersonTag,
    dialogButtonSelectEmployees,
    summaryCell
  },
  data(){
    return{
      showingDialog: false,
    }
  },
  methods:{
    onSubmit(payload, row) {
      row[this.fieldId] = payload[0];
    },

    openEmployeesDialog(row){
      let vm = this
      console.log(row[vm.fieldId])
      var payload = {
        command: 'showEmployeesDialog',
        multiple: false,
        addButton: false,
        selectedValue: row[vm.fieldId]? [row[vm.fieldId]]:[],
        onSubmit: vm.onSubmit,
        row: row
      }
      this.$emit('onCommand', payload)
    }
  }

}
</script>

<style>
  .col--actived.fullCell.vxeCellPerson .vxe-cell{
    width:100% !important;
    height:100% !important;
    padding: 0px !important;
  }
  .edit-person-modal{
    background-color: white;
    border: 1px solid #dcdfe6;
  }
</style>
