<template>
            <!-- :attach="cssSelect?cssSelect:(attach)?`#${attach}`:undefined" -->

  <v-dialog v-model="showing"
            v-if="showing"
            :width="width"
            persistent
            :height="height"
            @click:outside="showing=false"
            class="d-flex flex-column">
    <v-card 
      class="selectRelatedRecordDialog d-flex flex-column flex-grow-1 vxe-table--ignore-clear">
      <dialog-header
          icon="mdi-window-restore"
          :label="title"
          @close="close"></dialog-header>
      <v-card-text class="d-flex flex-column flex-grow-1 py-1" style="height:620px;">
        <selection-card-view
          ref="selectionCardView"
          v-model="value"
          :multiple="multiple"
          :exclusion="exclusion"
          :isParentChild="isParentChild"
          :parentChildFields="parentChildFields"
          :recordId="recordId"
          :fieldId="fieldId"
          :parentFormId="parentFormId"
          :dataSource="dataSource"
          :formInfo="formInfo"
          :checkDbForExclusion="checkDbForExclusion"
          :parentRecordInfo="parentRecordInfo"
          :selectionSettings="selectionSettings"></selection-card-view>
      </v-card-text>
      <v-divider></v-divider>
      <dialog-actions :actionDisabled="!actionEnabled"
                      :loading="loading"
                      @onCommand="onCommandHandler">
        <!-- <template>
          <v-btn x-small fab color="primary"
            @click="addRelatedRecord">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template> -->
      </dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogHeader from '@/components/dialogs/comps/DialogHeader'
import dialogActions from '@/components/dialogs/comps/DialogActions'
import selectionCardView from '@/components/SelectionCardView'

export default {
  components: {
    dialogHeader,
    dialogActions,
    selectionCardView
  },
  props: {
    width: {
      type: String,
      default: '1200'
    },
    height: {
      type: String,
      default: '90%'
    }
  },
  data () {
    return {
      showing: false,

      // cardSelectionListConfig: null,
      dataSource: null,
      formInfo: null,
      selectionSettings: null,

      // in case selection for embedded table
      // all data update occur instantly
      // exclusion requires access to database      
      // recordId: '',
      checkDbForExclusion: false,
      parentRecordInfo: null,
      isParentChild: false,
      parentChildFields: null,
      recordId: '',
      fieldId:'',
      parentFormId:'',
      
      callback: null,
      onSelected: null,
      onCancelled: null,

      multiple: false,
      exclusion: [],
      value: [],

      callbackBundle: {
        id: '',
        onSelected: null,
        onCancelled: null
      },

      attach: '',
      cssSelect: ''
    }
  },
  computed: {
    // if not multiple
    // there must be one item selected
    actionEnabled () {
      const vm = this
      var result = true
      if (!vm.multiple) {
        result = vm.value.length > 0
      }
      return result
    },
    title () {
      return this.$t('messages.relationSelection') + ' - ' + 
        this.relatedFormLabel
        //this.relatedFormLabel
    },
    relatedFormLabel () {
      const vm = this
      return (vm.formInfo||{}).label
    }
  },
  methods: {
    // open (value, selectionConfig, callback, multiple) {
    open (payload, onSelected=null, onCancelled=null) {
      const vm = this

      vm.attach = payload.attach
      vm.cssSelect = payload.cssSelect
      //
      // handle exclusion
      //
      vm.value = []
      // exclude aleady selected items      
      vm.exclusion = payload.value && !payload.inclusive ?  payload.value:[]
      // extra exclusion
      if (Array.isArray(payload.exclusion) && !payload.inclusive) {
        vm.exclusion = vm.exclusion.concat(payload.exclusion)
      }
      // for parent/child records selection during exclusion processed in server


      //
      // relation parameters
      //
      vm.multiple =  payload.multiple || false
      vm.dataSource = payload.dataSource
      vm.formInfo = payload.formInfo
      vm.selectionSettings = payload.selectionSettings
      this.recordId = payload.recordId,
      this.fieldId = payload.fieldId,
      this.parentFormId = payload.parentFormId
      // if (payload.recordId) vm.recordId = payload.recordId
      // if (payload.fieldId) vm.fieldId = payload.fieldId

      //
      // callback handling
      //
      if (payload.callbackBundle) vm.callbackBundle = payload.callbackBundle
      if (onSelected) vm.onSelected = onSelected
      if (onCancelled) vm.onCancelled = onCancelled
      this.showing = true

      vm.$nextTick(() => {
        vm.$refs.selectionCardView.reloadData()
      })
    },

    close () {
      const vm = this
      vm.showing = false
      if (typeof vm.onCancelled === 'function') {
        vm.onCancelled(vm.callbackBundle)
      }
    },

    okHandler () {
      const vm = this
      console.log('SelecxtRelatedCardsDialog :; okHandler')
      if (typeof vm.onSelected === 'function') {
        console.log('SelecxtRelatedCardsDialog :; onSelected = function')
        vm.onSelected(vm.value, vm.callbackBundle)
        vm.showing = false
      } else {
        console.log('SelecxtRelatedCardsDialog :; onSelected not function')

      }
      vm.close()
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'ok':
          vm.okHandler()
          break
        case 'cancel':
          vm.close()
          break
      }
    },
    addRelatedRecord () {
      this.showing = false
      this.$emit('click:new')
    }
  }
}
</script>
