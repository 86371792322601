<template>
  <FilterCondition
    :list="dependencies"
    :tableList="tableList"
    :rootComboxProps="rootComboxProps"
    hints="workflow.modules.form.hints.condition"
  />
</template>

<script>
import FilterCondition from './shared/filterCondition';
import Mixin from './mixin';
import { get } from 'lodash';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  computed: {
    dependencies() {
      const list = this.$store.getters.getDependencies;
      return WorkflowSettingUtils.delegatedOptionWithDependsOn({
        locale: this.$t('workflow'), 
        list,
        exclusiveDelegated: true,
      });
    },
    tableList() {
      return this.$store.getters.getFormSelectionsFields.children;
    },
    rootComboxProps() {
      return {
        onlyPrefix: true,
        onlySuffix: true,
        disableBranchNode: true,
      };
    },
  },
  components: {
    FilterCondition,
  },
};

</script>

<style scoped>
  
</style>
