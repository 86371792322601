<template>
  <div style="position:relative;">
    <slot name="header">
      <div class="px-2 text-h6">
        {{ label }}
        <hr style="border-color:rgba(0,0,0,.03);" />
      </div>
    </slot>
    <slot name="firstCard">      
    </slot>
    <draggable
      :delay-on-touch-only="true"
      delay="250"
      v-model="appInfos"
      handle=".v-card-app"
      @start="$emit('startDrag')"
      @end="$emit('endDrag')"
      @change="onChange"
      group="apps"
      ghostClass="ghost-class"
      dragClass="drag-class"
      chosenClass="chosen-class"
      fallbackClass="fallback-class"
      animation="300"
      class="draggable v-card-container d-inline"
      :class="{'min-height-100':!isFavApp}"
    >
      <!--:forceFallback="true">-->
      <transition-group class="d-inline">
        <v-card-app
          v-for="appInfo in appInfos"
          :key="appInfo._id"
          :isFavApp="isFavApp"          
          :dragging="dragging"
          :bounce="!( (appInfo.status&&appInfo.status.length>0) || appInfo.locked )"
          :showTeamName="showTeamName"
          :showingMenu="showingMenu"
          :withMenu="withMenu"
          @click="payload=>$emit('click',payload)"
          @click:menu="payload=>$emit('click:menu',payload)"
          @click:toggle="payload=>$emit('click:toggle',payload)"
          :appInfo="appInfo"
        ></v-card-app>
      </transition-group>
    </draggable>
    <div class="ghostAppContainer" v-if="dragging && draggingSection!=section">
      <div class='ghostAppContent'>
        {{$t('messages.dragAppFor')}}{{$t('messages.'+section)}}
      </div>
    </div>
  </div>
</template>

<script>
import vCardApp from "./VCardApp";
import draggable from "vuedraggable";

export default {
  name: 'myAppsSection',
  components: {
    vCardApp,
    draggable
  },
  props: {
    label: String,
    appInfos: Array,
    showTeamName: Boolean,
    isFavApp: {
      type: Boolean,
      default: false
    },
    
    showingMenu: Boolean,
    withMenu: Boolean,
    dragging: Boolean,
    section: String,
    draggingSection: String
  },
  methods: {
    onChange (payload) {
      // console.log('MyAppsSection: onChange: payload: ', payload)
      this.$emit('change', payload)
    }
  }

}
</script>

<style>
.v-card-container {
  min-height: 100px !important;
}
.draggable.min-height-100 > span {
  min-height: 100px;
}
.ghostAppContainer{
  margin:4px;
  width:120px;
  height: 156px;
  display: inline-block;
  padding: 0px 15px;

  background: #F7F8FA;
  border: 1px dashed #A9AEB8;
  box-sizing: border-box;

  font-family: SF Compact Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;

  text-align: center;
  color: #A9AEB8;
  vertical-align:top;
}

.ghostAppContent{
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
}
</style>