<template>
  <Consumer>
    <div v-if="!isFetchingModule">
      <div>
        <div class="v-modules-list-items" v-for="section in modules" :key="section.name">
          <h1>{{ $t(`workflow.label.${section.name}`) }}</h1>
          <DragItem
            :section="section.item"
            :isSelection="isSelection"
            @select="onSelectModule"
          />
        </div> 
      </div>
      <div class="w-workflow-slider-block" :class="{
        'w-workflow-slider-block-out': !subSection,
        'w-workflow-slider-block-in': !!subSection,
      }">
        <v-app-bar
          dense
          flat
          class="v-modules-title-app-bar"
        >
          <div class="v-modules-title">
            {{ $t(`workflow.label.${subSection && subSection.name}`) }}
            <span>({{ subSection && subSection.selections.length }})</span>
          </div>
          <v-icon medium @click="pollDeepSection">mdi-chevron-left</v-icon>
        </v-app-bar>
        <div class="v-modules-list-sub-items">
          <DragItem
            :section="subSection && subSection.selections"
            :isSelection="isSelection"
            @select="onSelectModule"
            :showDescription="true"
          />
        </div> 
      </div>
    </div>
    <v-progress-linear
      v-else
      indeterminate
      color="primary"
    ></v-progress-linear>
  </Consumer>
</template>

<script>
// import { Drag } from "vue-easy-dnd";
import DragItem from './dragItem';
import Consumer from './../../editor/contexts/workflowConsumer';
// TODO: controled and returned from api?
// import ModuleData from './../../constants/module';
import {
  FETCH_MODULES_LIST,
} from '@/store/modules/workflow/action_types';

export default {
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      subSection: null,
    };
  },
  beforeCreate() {
    // console.log(this.$store);
    this.$store.dispatch(FETCH_MODULES_LIST);
  },
  methods: {
    pollDeepSection() {
      this.subSection = null;
      this.$emit('deepSection');
    },
    onSelectModule(item) {
      if (!this.isSelection(item)) {
        this.$emit('choose', item)
      } else {
        this.$emit('deepSection');
        this.subSection = item;
      }
    },
    slideStyle() {
      return {
        left: '0',
      };
    },
    isSelection(item) {
      return item && item.selections && item.selections.length > 0;
    },
  },
  computed: {
    isFetchingModule() {
      return this.$store.getters.fetchingModules;
    },
    modules() {
      return this.$store.getters.getModules;
    },
  },
  components: {
    DragItem,
    Consumer,
  },
  watch: {
    isOpen: {
      handler(opened) {
        if (!opened) {
          this.subSection = null;
        }
      },
      deep: true,
    }
  },
};

</script>

<style scoped>
  .v-modules-title {
    text-align: center;
    flex: 1;
    padding-right: 42px;
  }
  .v-modules-title-app-bar {
    padding-right: 10px;
  }
  .v-modules-list-items {
    margin-bottom: 27px;
  }
  .v-modules-list-items-back {
    color: #333;
  }
  .v-modules-list-items h1 {
    font-size: 14px;
    color: #757575;
    margin-top: 12px;
  }
  .v-modules-list-sub-items {
    padding: 5px 20px;
  }
  .v-modules-list-selection {
    position: absolute;
    left: 100%;
    width: 100%;
    height: 100%;
    transition: left 1s;
    background: blue;
  }
</style>
