<template>
  <div slot-scope="{ info }">
    <Consumer>
      <div v-if="info.index === 2">
        <EditorHistory />
      </div>
      <div v-else-if="info.index === 1">
        <EditorSetting />
      </div>
      <div v-else>
        <EditorTree />
      </div>
    </Consumer>
  </div>
</template>

<script>
import Consumer from './contexts/workflowConsumer';
import EditorTree from './tree';
import EditorHistory from './history/';
import EditorSetting from './setting/';
export default {
  inject: ['info'],
  components: {
    Consumer,
    EditorTree,
    EditorHistory,
    EditorSetting,
  },
}
</script>

<style>
</style>