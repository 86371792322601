<template>
  <v-footer height="40" dark flat
            class="app-footer font-weight-medium">
      <div class="text-center w-100">
        {{ new Date().getFullYear() }} - <strong>YOOV</strong> - {{ version }}
      </div>
  </v-footer>
  <!--<v-card height="40" dark flat class="app-footer">-->
    <!--<v-footer-->
        <!--fixed-->
        <!--class="font-weight-medium">-->
      <!--<div class="text-center w-100">-->
        <!--{{ new Date().getFullYear() }} - <strong>YOOV</strong>-->
      <!--</div>-->
    <!--</v-footer>-->
  <!--</v-card>-->
</template>

<script>
  export default {
    computed: {
      version () {
        return `Ver. ${ process.env.VUE_APP_VERSION }`
      }
    }
  }
</script>

<style>
  .app-footer {
    z-index: 6;
  }
</style>
