<template>
  <v-dialog
    v-model="showingDialog"
    :dialogTitle="$t('api.authentication')"
    width="840px"
    no-action
    scrollable
  >
    <v-card>
      <dialog-header
        :label="$t('api.authentication')"
        @close="showingDialog = false"
      />
      <v-card-text>
        <v-list class="pt-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("api.prerequisites")
              }}</v-list-item-title>
              <v-list-item-subtitle>
                <ol>
                  <li>{{ $t("api.appKey") }}</li>
                  <li>{{ $t("api.secret") }}</li>
                </ol>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t("api.httpHeaders") }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-simple-table
                  fixed-header
                  dense
                  style="table-layout: fixed; width: 100%"
                >
                  <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 70%" />
                  </colgroup>
                  <thead>
                    <th class="text-left">{{ $t("api.httpHeaders") }}</th>
                    <th class="text-left">{{ $t("widgets.description") }}</th>
                  </thead>
                  <tbody>
                    <tr v-for="header in headers" :key="header.header">
                      <td>{{ header.header }}</td>
                      <td
                        style="word-wrap: break-word; white-space: break-spaces"
                      >
                        {{ header.description }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t("api.authHeader") }}</v-list-item-title>
              <h4>{{ $t("api.syntax") }}</h4>
              <url-box
                url="Authorization: HMAC-SHA256 ApiKey=<appKey>&Signature=<signature>"
                :can-copy="false"
                noQRCode
              />
              <v-list-item-subtitle>
                <v-simple-table
                  fixed-header
                  dense
                  style="table-layout: fixed; width: 100%"
                >
                  <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 70%" />
                  </colgroup>
                  <thead>
                    <th class="text-left">{{ $t("api.arguments") }}</th>
                    <th class="text-left">{{ $t("widgets.description") }}</th>
                  </thead>
                  <tbody>
                    <tr v-for="header in authHeaderArgs" :key="header.header">
                      <td>{{ header.arg }}</td>
                      <td
                        style="word-wrap: break-word; white-space: break-spaces"
                      >
                        {{ header.description }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t("api.signature") }}</v-list-item-title>
              <v-list-item-subtitle
                >base64_encode(HMACSHA256(String-To-Sign, Secret))
              </v-list-item-subtitle>
              <h4>{{ $t("api.syntax") }}</h4>
              <url-box
                url="String-To-Sign= HTTP_METHOD + '\n' + path + '\n' + timestamp + '\n' + contentHash"
                :can-copy="false"
                noQRCode
              />
              <v-list-item-subtitle>
                <v-simple-table
                  fixed-header
                  dense
                  style="table-layout: fixed; width: 100%"
                >
                  <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 70%" />
                  </colgroup>
                  <thead>
                    <th class="text-left">{{ $t("api.arguments") }}</th>
                    <th class="text-left">{{ $t("widgets.description") }}</th>
                  </thead>
                  <tbody>
                    <tr v-for="header in signatures" :key="header.header">
                      <td>{{ header.arg }}</td>
                      <td
                        style="word-wrap: break-word; white-space: break-spaces"
                      >
                        {{ header.description }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t("api.example") }}</v-list-item-title>
              <a
                v-for="code in exampleCodes"
                :key="code.language"
                class="py-2"
                :href="code.url"
                target="_blank"
                >{{ code.language }}</a
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import UrlBox from "@/pages/admin/form/publicPublishing/comp/urlBox";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";

export default {
  components: {
    UrlBox,
    dialogHeader,
  },
  props: {
    value: Boolean,
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      headers: [
        {
          header: "Date",
          description: this.$t("api.dateHeaderDescription"),
        },
        {
          header: "x-yoov-date",
          description: this.$t("api.xYoovDateDescription"),
        },
        {
          header: "Authorization",
          description: this.$t("api.authHeaderDescription"),
        },
      ],
      authHeaderArgs: [
        {
          arg: "HMAC-SHA256",
          description: this.$t("api.hmacDescription"),
        },
        {
          arg: "appKey",
          description: this.$t("api.appKeyDescription"),
        },
        {
          arg: "signature",
          description: this.$t("api.signatureDescription"),
        },
      ],
      signatures: [
        {
          arg: "HTTP_METHOD",
          description: "GET/POST/DELETE/PUT",
        },
        {
          arg: "path",
          description: this.$t("api.pathDescription"),
        },
        {
          arg: "timestamp",
          description: this.$t("api.timestampDescription"),
        },
        {
          arg: "contentHash",
          description: this.$t("api.contentHashDescription"),
        },
      ],
      exampleCodes: [
        {
          url: "https://github.com/yoovplus/api_signature_example_codes/blob/main/signature.js",
          language: "Javascript",
        },
        {
          url: "https://github.com/yoovplus/api_signature_example_codes/blob/main/signature.py",
          language: "Python",
        },
        {
          url: "https://github.com/yoovplus/api_signature_example_codes/blob/main/signature.go",
          language: "Go",
        },
      ],
    };
  },
};
</script>
