<template>
    <div>
        <div class="d-flex gap-10">
            <FormElement name="timer.mode" :label="$t(`workflow.modules.${labelType}.label.startTime`)">
                <ComboBox
                    :value="value['mode'] || null"
                    :options="startTimeOptions"
                    :onlyPrefix="false"
                    :onlySuffix="true"
                    :isMultiple="false"
                    :disabledOnSelect="false"
                    :disabledLookup="disabledLookup"
                    :placeholder="$t(`workflow.modules.${labelType}.placeholder.selectStartTime`)"
                    involveRelationshipLabel
                    :isClearable="false"
                    :disableBranchNode="true"
                    @changed="changeStartTime"
                />
            </FormElement>
            <div v-if="value.mode !== 'exact'" class="d-flex gap-10">
                <FormElement name="timer.interval" :label="$t(`workflow.modules.${labelType}.label.interval`)">
                    <v-text-field outlined dense v-model="setting.timer['interval']" class="startTimeVal" hide-details=""></v-text-field>
                </FormElement>
                <FormElement name="timer.intervalUnit" :label="$t(`workflow.modules.${labelType}.label.intervalUnit`)">
                    <ComboBox
                        v-if="!showTimeSelector"
                        :value="value['intervalUnit'] || null"
                        :options="intervalUnit"
                        :onlyPrefix="false"
                        :onlySuffix="true"
                        :isMultiple="false"
                        :disabledOnSelect="false"
                        :disabledLookup="disabledLookup"
                        :placeholder="$t(`workflow.modules.${labelType}.placeholder.selectUnit`)"
                        involveRelationshipLabel
                        :isClearable="false"
                        :disableBranchNode="true"
                        @changed="changeIntervalUnit"
                        class="unit-selector"
                    />
                    <div v-else class="align-self-center">
                        {{ $t('workflow.modules.date.options.unit.days')}}
                    </div>
                </FormElement>
            </div> 
        </div>
        <div v-if="showTimeSelector" class="d-flex align-center gap-10 mt-2">
            <FormElement name="timer.startTime" :label="$t(`workflow.modules.${labelType}.label.execute`)">
                <v-menu
                    ref="startTimePicker"
                    :close-on-content-click="false"
                    :return-value.sync="startTime"
                    transition="scale-transition"
                    offset-y
                    v-model="startTimePickerMenu"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startTime"
                            outlined
                            dense
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="startTimePickerMenu"
                        v-model="startTime"
                        @click:minute="$refs.startTimePicker.save(startTime)"
                    ></v-time-picker>
                </v-menu>
            </FormElement>
            <!-- <div>
                {{ $t(`workflow.modules.${ labelType }.label.execute`) }}
            </div> -->
        </div>
    </div>
</template>

<script>
    import Mixin from '../mixin';
    import FormElement from './../shared/formElement';
    import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
    export default {
        components: { FormElement, ComboBox },
        mixins: [Mixin],
        props: {
            value: Object,
            labelType: String,
            showTimeSelector: {
                type: Boolean,
                default: false
            }
        }, 
        data() {
            return {
                startTimePickerMenu: false, 
                startTime: '00:00',
                interval: 15,
                intervalUnit: [
                    { label: this.$t('workflow.modules.date.options.unit.minutes'), id: 'minutes' },
                    { label: this.$t('workflow.modules.date.options.unit.hours'), id: 'hours' },
                    { label: this.$t('workflow.modules.date.options.unit.days'), id: 'days' },
                ], 
                startTimeOptions: [
                    { label: this.$t('workflow.modules.date.options.startTime.exact'), id: 'exact' },
                    { label: this.$t('workflow.modules.date.options.startTime.before'), id: 'before' },
                    { label: this.$t('workflow.modules.date.options.startTime.after'), id: 'after' },
                ]
            }
        },
        mounted() {
            this.startTime = this.setting.timer.startTime || '00:00'
        },
        methods: {
            changeStartTime( val ) {
                this.$set( this.setting.timer, 'mode', val);
                if ( val !== 'exact' ) {
                    this.$set( this.setting.timer, 'interval', this.showTimeSelector ? 1 : 15 );
                    this.$set( this.setting.timer, 'intervalUnit', this.showTimeSelector ? 'days' : 'minutes' );
                    if ( this.showTimeSelector && !this.timer.startTime ) {
                        this.$set( this.setting.timer, 'startTime', '00:00' );
                    }
                }
            },
            changeIntervalUnit( val ) {
                this.$set( this.setting.timer, 'intervalUnit', val);
            }
        },
        watch: {
            startTime( newVal, oldVal ) {
                this.$set(this.setting.timer, 'startTime', newVal)
            }
        }
    }
</script>

<style lang="scss">
    .gap-10 {
        gap: 10px;
        .startTimeVal {
            min-width: 150px !important;
        }
        .unit-selector {
            width: fit-content !important;
        }
    }
</style>