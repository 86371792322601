<template>
  <v-subheader class="pt-0 px-0">
    <v-card @click="onHeaderClicked"
            :disable="!editable"
            style="height:34px;"
            class="px-3 d-flex flex-row align-center w-100"
            rounded dark
            :color="headerColor">
      <h3>{{ title }}</h3><div class="ml-2">{{ message }}</div>
      <v-spacer></v-spacer>
      <v-btn x-small
             v-if="editable"
             dark
             rounded fab
             style="height:27px; width:27px"
             color="cyan"
             class="elevation-0"
             @click="onButtonClicked">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </v-card>
  </v-subheader>
</template>

<script>
  export default {
    name: 'targetListHeader',
    props: {
      title: {
        type: String,
        default: ''
      },
      selected: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'grey darken-1'
      },
      selectedColor: {
        type: String,
        default: 'blue darken-3'
      },
      buttonColor: {
        type: String,
        default: 'cyan'
      },
      icon: {
        type: String,
        default: 'mdi-playlist-remove'
      },
      editable: {
        type: Boolean,
        default: true
      },
      message: {
        type: String,
        default: ''
      }
    },
    computed: {
      headerColor () {
        const vm = this
        var result = 'grey lighten-1'
        if (vm.editable) {
          result = vm.selected ? vm.selectedColor : vm.color
        }
        if (vm.message !== '') {
          result = 'error'
        }
        return result
      }
    },
    methods: {
      onHeaderClicked () {
        this.$emit('click:header')
      },
      onButtonClicked () {
        console.log('TargetListHeader :: onButtonClicked')
        this.$emit('click:button')
      }
    }
  }
</script>
