<template>
<div class="edit-pulldown-related-record-modal">
  <div class="d-flex flex-row white" v-if="readonly || !params.row.canEdit || !hasDataSource">
    {{ recordTitle }}
  </div>

  <!-- <div v-else class="white pa-1">
    <v-btn
      class="white">{{ recordTitle }}</v-btn>
      <pulldown-related-title-dialog
      id="vxeRelatedRecordSimpleInput"
      v-model="params.row[params.column.property]"
      :hideDetails="true"
      :readonly="readonly"
      :attach="attach"
      :cssSelect="cssSelect"
      :dataSource="dataSource"
      :relatedTitleFieldId="relatedTitleFieldId"
      :idDataMapping="idDataMapping"
    />
  </div> -->

  <div v-else :id="attach" class="w-100">
    <pulldown-related-title-field
      id="vxeRelatedRecordSimpleInput"
      v-model="params.row[params.column.property]"
      :hideDetails="true"
      :readonly="readonly"
      :attach="attach"
      :cssSelect="cssSelect"
      :dataSource="dataSource"
      :relatedTitleFieldId="relatedTitleFieldId"
      :idDataMapping="idDataMapping"
    />
  </div>
    <!-- <v-overlay
    :absolute="false"
    :value="showingOverlay"
    :id="attach"></v-overlay> -->
</div>
</template>

<script>
import pulldownRelatedTitleField from "@/components/form/PulldownRelatedTitleField"
// import pulldownRelatedTitleDialog from "@/components/form/PulldownRelatedTitleDialog"

export default {
  name: 'editPulldownRelatedRecordModal',
  props:{
    params: Object,
    readonly: Boolean,
    dataSource: Object,
    fieldInfo: Object,
    relatedTitleFieldId: String,
    idDataMapping: Object
  },
  components: {
    pulldownRelatedTitleField,
    // pulldownRelatedTitleDialog
  },
  computed:{
    hasDataSource () {
      return Boolean(this.dataSource)
    },
    recordTitle () {
      const vm = this
      var result = ''
      const idArray = vm.params.row[vm.params.column.property]
      if (idArray && vm.idDataMapping) {
        result = idDataMapping[idArray[0]].title
      }
      return result
    },
    attach(){
        return 'tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex
    },
    cssSelect(){
      var path = '#' + this.attach
      if(this.params.fixed!= undefined){
        if(document.querySelector('.vxe-table--fixed-left-wrapper')){
          return '.vxe-table--fixed-left-wrapper' + ' ' + path
        }
      }
      return path
    },
  }
};
</script>

<style>
.edit-pulldown-related-record-moda {
  display: flex;
  align-items: center;
}
  
</style>