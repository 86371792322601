<template>
  <v-navigation-drawer
    v-model="isOpen"
    fixed
    width="410"
    :style="{ zIndex: '9999 !important', }"
  >
    <v-app-bar
      :color="$vuetify.theme.themes.light.secondary"
      absolute
      dense
      flat
      height="65"
      scroll-target="#scrolling-modules-list"
    >
      <v-layout justify-space-between align-center>
        <div class="w-modules-header">{{ $t('workflow.label.startYourFlow') }}</div>
        <v-layout justify-end align-center>
          <!-- <v-btn
            :color="$vuetify.theme.themes.light.secondary"
            class="w-modules-help"
            elevation="0"
          >
            <v-icon color="white" small>mdi-help-circle </v-icon>
            {{ $t('workflow.label.understandAction') }}
          </v-btn> -->
          <v-icon class="w-module-close-btn" color="white" @click="toggle">mdi-close</v-icon>
        </v-layout>
      </v-layout>
    </v-app-bar>
    <v-sheet
      id="scrolling-modules-list"
      class="w-module-container-list"
      ref="scrollArea"
      max-height="100%"
      :class="{ 'overflow-y-auto': !showDeepSection }"
    >
      <v-container class="w-module-container">
        <ModuleList @choose="select" :isOpen="isOpen" @deepSection="onDeepSection" />
      </v-container>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import {
  TOGGLE_MODULE_MENU,
  PICK_MODULE,
  PICKING_MODULE,
} from '@/store/modules/workflow/action_types';
import Mixin from './mixin';
import ModuleList from './list';
// import {
//   WorkflowNodeIdentify,
// } from './../../constants/node';
// import Tree from './../../libs/tree';

export default {
  mixins: [Mixin],
  // inject: ['getEventType'],
  data() {
    return {
      showDeepSection: false,
    };
  },
  components: {
    ModuleList,
  },
  methods: {
    onDeepSection() {
      this.$refs.scrollArea.$el.scrollTo({ behavior: 'smooth', top: 0 });
      this.showDeepSection = !this.showDeepSection;
    },
    toggle() {
      if (this.waitToBeInserted) {
        this.$store.dispatch(PICKING_MODULE, false);
      }
      this.$store.dispatch(TOGGLE_MODULE_MENU);
      this.$store.dispatch(PICK_MODULE, null);
    },
    select(config) {
      if (this.waitToBeInserted) {
        this.$store.dispatch(PICK_MODULE, config);
        // this.$store.dispatch(TOGGLE_MODULE_MENU);
      }
    },
    keyDownHandler (event) {
      if (event.code === 'Escape' && this.isOpen) {
        this.toggle();
      }
    }
  },
  computed: {
    waitToBeInserted() {
      return this.$store.state.workflowModules.pickingModule;
    },
    isOpen() {
      return this.$store.state.workflowModules.moduleOpen;
    },
    // nodeType() {
    //   return WorkflowNodeIdentify.common;
    // },
    // groupType() {
    //   return WorkflowNodeIdentify.fork;
    // },
  },
  watch: {
    isOpen: {
      handler(opened) {
        if (!opened) {
          this.$nextTick(() => {
            this.$refs.scrollArea.$el.scrollTo({ behavior: 'smooth', top: 0 });
          });
        }
      },
      deep: true,
    }
  },
};

</script>

<style scoped>
  .v-navigation-drawer {
    z-index: 999 !important;
    user-select: none;
    margin-right: 66px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 10px 23px 2px rgb(0 0 0 / 20%), 0 3px 6px rgb(0 0 0 / 15%);
  }
  .w-module-container {
    padding: 65px 24px 60px 24px;
    user-select: none;
    /* position: relative; */
    overflow: hidden;
    /* min-height: 100vh; */
  }
  .w-module-container-list {
    position: relative;
    overflow: hidden;
    margin-right: -22px;
  }
  .w-modules-header {
    font-size: 18px;
    color: white;
  }
  .w-module-close-btn {
    transition: all .25s ease-out;
    cursor: pointer;
  }
  .w-module-close-btn:hover {
    transform: rotate(90deg);
  }
  .w-modules-help {
    padding: 0 10px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    color: white;
    font-weight: 700;
    font-size: 13px;
    margin-right: 15px;
  }
</style>
