<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-row
        no-gutters
        style="border-top: 0px;border-bottom:0px;height:100% !important"
        :class="`${hover ? 'drag-border' : ''}`"
      >
        <v-col>
          <print-field
            :fieldInfo="fieldInfo"
            :item="item"
            :value="value"
            :childTableFields="childTableFields"

            :fontSize="fontSize"

          >
            <template v-slot:child-table-control>
              <v-btn-toggle
                dense
                mandatory
                :value="childTableFields[fieldInfo._id].type"
                style="float:right"
                @change="childTableFields[fieldInfo._id].type = $event"
              >
                <v-btn value="table" text small>
                  {{ $t("printTemplate.table") }}
                </v-btn>
                <v-btn value="panel" text small>
                  {{ $t("printTemplate.panel") }}
                </v-btn>
              </v-btn-toggle>
            </template>
          </print-field>
          <v-row no-gutters v-if="hover" align="center" class="hide">
            <div v-if="hasAdjustableWidth">
              <v-btn
                v-for="width in widthOptions"
                :key="width.value"
                x-small
                text
                color="primary"
                @click="setWidth(width.value)"
              >
                {{ width.label }}
              </v-btn>
            </div>
            <div v-if="fieldInfo.type === 'childTable'">
              <v-btn text x-small color="primary" @click="editChildTable">
                <v-icon x-small>mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
            <v-btn text x-small color="error" @click="removeItem">
              <v-icon x-small>mdi-delete</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-hover>
</template>
<script>
import PrintField from "./printLayout/printField";

export default {
  components: {

    PrintField,
  },
  props: {
    remainingWidth: {
      type: Number,
      default: 12,
    },
    fieldInfo: Object,
    item: Object,
    value: String,
    colIndex: Number,

    printColIndex: Number,

    childTableFields: Object,

    fontSize: Number,

  },
  data() {
    return {
      widthOptions: [
        {
          label: "1/4",
          value: 3,
        },
        {
          label: "1/2",
          value: 6,
        },
        {
          label: "3/4",
          value: 9,
        },
        {
          label: "1",
          value: 12,
        },
      ],
    };
  },
  computed: {
    width() {
      return this.item.width;
    },

    hasAdjustableWidth() {
      return this.fieldInfo
        ? this.fieldInfo.properties.hasOwnProperty("width")
        : false;
    },

    isUIElement() {
      return this.item.controlType === "static";
    },
  },
  methods: {
    setWidth(width) {
      if (width - this.width > this.remainingWidth) return;
      this.$emit("commandHandler", {
        command: "setWidth",
        fieldId: this.item._id,
        width,
      });
    },
    removeItem() {
      this.$emit("commandHandler", {
        command: "removeItem",
        item: this.item,
        colIndex: this.colIndex,
      });
    },
    editChildTable() {
      this.$emit("commandHandler", {
        command: "editChildTable",
        fieldId: this.item._id,
      });
    },
  },
};
</script>
<style scoped>
.drag-border {
  border: grey 2px dashed;
}
</style>
