<template>
<v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="buttonDisabled"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
         {{ selectedItem ? selectedItem.title : 'no selection' }}
        </v-btn>
      </template>
      <perfect-scrollbar class="fill-height" @ps-y-reach-end="loadMore">
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item._id"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      </perfect-scrollbar>
    </v-menu>
</template>

<script>
export default {
  data () {
    return {
      items: [],
      pagination: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0
      },
      atEnd: false,
      selectedItem: null
    }
  },
  mounted () {
    this.pagination.currentPage = 1
    this.loadMore()
  },
  computed: {
    buttonDisabled () {
      return !this.hasDataSourceDefined
    },
    hasDataSourceDefined () {
      return Boolean(this.fieldInfo.properties.dataSource)
    }
  },
  methods: {
    loadMore () {
      const vm = this
      if (!vm.atEnd) {
        this.loadData({
          pageNo: vm.pagination.currentPage + 1,
          pageSize: vm.pagination.pageSize
        }).then(
          response => {
            // response = {
            //    pagination,
            //    result
            // }
            if (result.length === 0) {
              vm.atEnd = true
            } else {
              vm.items.concat(response.result)
              vm.pagination = response.pagination
            }
          }
        )
      }
    },

    loadData (pagination) {
      const vm = this
      const TOTAL = 111
      var result = []
      const lastPage = Math.ceil(TOTAL / pagination.pageSize)
      if (pagination.pageNo <= lastPage) {
        return new Promise((resolve, reject) => {
          const start = (pagination.pageNo - 1)*pagination.pageSize + 1
          for (let i = 0; i < pagination.pageSize; i++) {
            result.push({
              _id: start + i,
              title: 'Item #' + (start + i)
            })
          }
        })
      } else {
        return Promise.resolve({
          pagination: pagination,
          result: result
        })
      }
    }
  }

}
</script>