<template>
  <div class="mx-5">
    <div class="d-flex flex-column">
        <my-apps-section 
            v-for="(app, sectionKey) in Object.keys( appSections )" 
            :key="sectionKey" 
            :title="appSections[app].caption || app" 
            :apps="appSections[app].apps"
            :preference="appSections[app].preference"
            :forceDisplay="appSections[app].forceDisplay" />
    </div>
    <!-- ARCHIVE BUTTON -->
    <div class="" style="position: fixed; right: 1.5rem; bottom: 1.5rem" @click="openArchivePage">
        <v-btn fab color="primary">
            <v-icon>mdi-folder</v-icon>
        </v-btn>
    </div>
  </div>
</template>

<script>
// components
import myAppsSection from "./comps/MyAppsSection";
export default {
    name: 'myApps', 
    components: {
        myAppsSection
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        userTeams() {
            return this.user.teams;
        },
        currentTeam() {
            let _team = null; 
            if ( this.user ) _team = this.userTeams.find( team => team._id.toString() === this.$store.getters.user.lastTeam );
            return _team;
        }, 
        preference() {
            let _preference = null;
            if ( this.user ) _preference = this.user?.preference;
            return _preference;
        }, 
        appSections() {
            return {
                "favourite": {
                    apps: this.apps.filter( app => this.preference?.appsLayout.orderedFavAppIds.includes( app._id )).reduce((obj, app) => {
                        obj[app._id] = app;
                        return obj;
                    }, {}),
                    preference: {
                        ordering: this.preference?.appsLayout?.orderedFavAppIds || []
                    },
                }, 
                "currentTeam": {
                    apps: this.apps.filter( app => app.teamId === this.currentTeam._id.toString() && !this.preference?.appsLayout.orderedFavAppIds.includes( app._id ) ).reduce((obj, app) => {
                        obj[app._id] = app;
                        return obj;
                    }, {}),
                    preference: {
                        ordering: this.preference?.appsLayout?.allTeamsAppIdSets.find( layoutSet => layoutSet.teamId === this.currentTeam?._id )?.orderedAppIds || []
                    },
                    forceDisplay: true
                }, 
                "others": {
                    apps: this.apps.filter( app => app.teamId !== this.currentTeam._id && !this.preference?.appsLayout.orderedFavAppIds.includes( app._id ) ),
                    preference: {
                        ordering:  []
                    },
                }
            }
        }
        
    }, 
    data() {
        return {
            apps: [],
        }
    }, 
    methods: {
        loadApp: function () {
            this.$store.dispatch('FETCH_APPS').then((response) => {
                this.apps = response;
            })
        },
        openArchivePage: function () {
            this.$router.push({ name: 'archivedFolder' })
        }
    },
    created() {
        this.loadApp();
    },
    mounted(){
        this.$sensors.track("plus_myapps_view");
    }
};
</script>
