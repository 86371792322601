<template>
  <v-card
    :max-height="maxHeight"
    :width="width"
    class="d-flex flex-column "
    style="height: fit-content"
    :flat="flat"
    :outlined="outlined"
  >
    <v-toolbar v-if="!noToolbar" class="flex-grow-0" flat dense>
      <span class="nowrap-ellipsis">{{ option.title }}</span>
      <v-spacer />
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon v-on="on" v-bind="attrs"
            ><v-icon small>mdi-menu</v-icon></v-btn
          >
        </template>
        <v-list dense>
          <v-list-item @click="edit" link>
            <v-list-item-title>{{
              $t("buttons.edit")
            }}</v-list-item-title></v-list-item
          >
          <v-list-item v-if="hasDelete" @click="onDelete" link
            ><v-list-item-title class="red--text">{{
              $t("buttons.delete")
            }}</v-list-item-title></v-list-item
          >
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-divider v-if="!noToolbar"/>
    <v-card-text style="overflow:auto" class="pa-0">
      <v-list dense>
        <v-list-item v-for="item in displayOptions" :key="item.id">
          <div
          v-if="option.useColor"
            class="color-circle"
            :style="`background-color: ${getColor(item.colorSet)}`"
          ></div>
          <span class="option-label" :class="item.active?``:`text--disabled`">
            {{ item.label }}
            <v-icon x-small color="grey" v-if="!item.active">mdi-eye-off</v-icon>
            
            </span>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    option: Object,
    flat: {
      type:Boolean,
      default:false
    },
    outlined: {
      type:Boolean,
      default:false
    },
    width: {
      type:String,
      default:"160"
    },
    hasDelete: {
      type:Boolean,
      default:true
    },
    'max-height': {
      type:String,
      default: "540"
    },
    'no-toolbar': {
      type:Boolean,
      default:false
    }
  },
  data() {
    return {};
  },
  computed:{
    displayOptions(){
      return this.option.options && this.option.options.filter(item=> !item.isDeleted) || []
    }
  },
  methods: {
    getColor(colorSets) {
      return colorSets.split("/")[1];
    },
    edit(){
      this.$emit("edit", this.option._id)
    },
    onDelete(){
      this.$emit("delete", this.option._id)
    }
  },
};
</script>
