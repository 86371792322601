<template>
  <div>
    <slot v-bind="{ info, setTitle, setType, setEditType, setDescription }" />
  </div>
</template>

<script>
export default {
  inject: [
    'info',
    'setTitle',
    'setType',
    'setEditType',
    'setDescription',
  ]
};
</script>

<style>
</style>
