<template>
  <div>
    <SettingHeader :label="$t('workflow.label.table')" :node="node" />
    <WorksheetSelect
      :worksheet="worksheetInfo"
      :warning="true"
      :placeholder="$t('workflow.modules.form.placeholder.selectWorksheet')"
    />
    <HookEventSelect v-if="!!worksheet" />
    <FilterCondition
      :list="list"
      :tableList="list"
      directListSearch
      :worksheet="worksheet"
      v-if="!!worksheet"
      hints="workflow.modules.form.hints.condition"
    />
  </div>
</template>

<script>
import SettingHeader from './shared/header';
import WorksheetSelect from './shared/worksheetSelect';
import HookEventSelect from './shared/hookEventCheckbox/';
import FilterCondition from './shared/filterCondition';
import Mixin from './mixin';
import NodeUtils from '@/pages/admin/workflow/utils/node';
import { WidgetSelectionAcceptance } from './../../../constants/form';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  computed: {
    list() {
      // let { children: options } = this.$store.getters.getFormSelectionsFields;
      // const list = NodeUtils.normalizeComboxOptions(options);
      // if (this.dependsOnType) {
      //   return NodeUtils.normalizeDependencyOption(list, WidgetSelectionAcceptance, this.dependsOnType);        
      // }
      // return list;
      return this.$store.getters.getFormSelectionsFields.children;
    },
    loading() {
      return this.$store.getters.fetchingFormSelectionsFields;
    },
    worksheet() {
      return this.setting.worksheet;
    },
    worksheetInfo() {
      const { worksheet } = this.setting;
      if (worksheet && !worksheet.id) {
        return { id: worksheet };
      }
      return worksheet;
    },
  },
  components: {
    SettingHeader,
    WorksheetSelect,
    HookEventSelect,
    FilterCondition,
  },
};

</script>

<style scoped>
  
</style>
