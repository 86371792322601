<template>
  <v-navigation-drawer
    right
    absolute
    temporary
    hide-overlay
    v-model="showDrawer"
    width="540"
    class="elevation-0"
  >
    <v-toolbar flat
      >{{ $t('printTemplate.createTemplate')}}
      <v-spacer />
      <v-btn icon @click="showDrawer = false"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-divider />
    <v-row no-gutters class="title ma-2">
      <span class="step-number">1.</span>
      {{ $t('printTemplate.createTemplate')}}
    </v-row>
    <v-row no-gutters class="pa-2">
      <v-btn block color="primary" @click="goToInstruction">{{ $t('printTemplate.instruction')}}</v-btn>
    </v-row>
    <v-row no-gutters class="title ma-2">
      <span class="step-number">2.</span>
      {{ $t('printTemplate.upload' )}}
    </v-row>
    <v-row no-gutters>
      <file-drop
        :fileIds="files"
        :isLayout="false"
        @onCommand="onCommandHandler"
        @input="onInput"
        :hasError="hasError"
        :fullHeight="true"
        :acceptExt="acceptExts"
        class="d-flex fill-height mx-2"
        style="padding:0px !important;"
      >
        <template>
          <v-simple-table
            fixed-header
            v-if="files.length > 0"
            height="100%"
            style="width:100%"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("drawer.fileName") }}
                  </th>
                  <th class="text-right" style="width:120px">
                    {{ $t("drawer.action") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file in files" :key="file._id">
                  <td>{{ file.originalName }}</td>
                  <td class="text-right">
                    <v-btn
                      depressed
                      fab
                      x-small
                      class="mx-2"
                      @click="downloadAttachment"
                      ><v-icon>mdi-download-outline</v-icon></v-btn
                    >
                    <v-btn depressed fab x-small @click="replaceAttachment"
                      ><v-icon>mdi-file-replace-outline</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </file-drop>
    </v-row>
    <template v-slot:append>
      <v-divider />
      <v-row no-gutters justify="end" class="pa-4">
        <v-btn rounded depressed @click="showDrawer = false">Cancel</v-btn>
        <v-btn
          rounded
          color="primary"
          class="ml-2"
          :disabled="confirmBtnDisabled"
          @click="onConfirm"
          >Confirm</v-btn
        >
      </v-row>
    </template>

    <input
      type="file"
      ref="uploader"
      :multiple="false"
      @input="onInputFileChanged"
      accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      class="d-none"
    />
  </v-navigation-drawer>
</template>
<script>
import fileDrop from "@/components/dropUpload/FileDrop";
import MediaHelper from "@/helpers/MediaHelper";

export default {
  components: {
    fileDrop,
  },
  props: {
    value: Boolean,
    appId: String,
    formId: String,
    template: Object,
  },
  data() {
    return {
      files: [],
      acceptExts: ["docx"],
      isSelecting: false,
      isEditMode: false,
      oldMediaId: "",
    };
  },
  mounted() {
    if (this.template) {
      this.isEditMode = true;
      this.oldMediaId = this.template.media.id;
      this.files.push(this.template.media);
    }
  },
  computed: {
    showDrawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    confirmBtnDisabled() {
      return (
        (this.isEditMode && this.files[0].id === this.oldMediaId) ||
        this.files.length === 0
      );
    },
  },
  methods: {
    goToInstruction() {
      this.$emit("onCommand", {
        command: "goToInstruction",
      });
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "triggerUpload":
          this.onUploadClick();
          break;
        case "uploadFiles":
          this.uploadFile(payload.files);
          break;
        case "removeAttachment":
          this.removeAttachment();
          break;
      }
    },
    onUploadClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    uploadFile(files) {
      const vm = this

      if (files.length > 1) {
        this.$alert("Cannot have >1");
        return;
      }

      if (this.checkExtension(files[0]) === false) {
        console.error("Invalid Extension");
        this.$alert("only docx allowed");
        return;
      }

      const file = files[0];
      const payload = {
        appId: this.appId,
        formId: this.formId,
        file: file,
        isPublic: true,
        useUploadBucket: true
      };

      console.log('file.size = ' + file.size);
      MediaHelper.uploadFile(payload, tempMedia => {
        console.log('tempMedia: ', tempMedia);
        const fileUrl = tempMedia.thumbnail;
        if (this.files.length > 0) {
          const file = this.files[0];
          MediaHelper.removeMedia({
            mediaId: file.id,
            objectKey: file.objectKey
          });
        }
        this.files.splice(0, 1, {
          id: tempMedia._id,
          originalName: tempMedia.originalName,
          objectKey: tempMedia.objectKey,
          mediaType: tempMedia.mediaType,
          size: file.size,
          fileUrl: fileUrl
        })
        // MediaHelper.createMedia({
        //   ...tempMedia,
        //   appId: vm.appId,
        //   formId: vm.formId
        // }, updatedMedia => {
        //    console.log('createMedia: ', updatedMedia);          
        //   this.files.splice(0, 1, {
        //     id: updatedMedia._id,
        //     originalName: updatedMedia.originalName,
        //     size: updatedMedia.size,
        //     fileUrl: fileUrl
        //   });
        // });

        // this.files = [
        //    {
        //        id: string;
        //        originalName: string;
        //        size: number;
        //        fileUrl: string;
        //    }
        // ] 
       // this.files.splice(0, 1, res);

      })

      // const formData = new FormData();
      // formData.append("file", files[0]);
      // formData.append("appId", this.appId);

      // const postPayload = {
      //   urlCommand: "/printTemplate/upload",
      //   options: {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   },
      //   data: formData,
      // };

      // this.$store.dispatch("AUTH_POST", postPayload).then((res) => {
      //   console.log('uploadFile: AUTH_POST: res: ', res);
      //   this.files.splice(0, 1, res);
      //   console.log('uploadFile: AUTH_POST: this.files: ', this.files);
      // });
    },

    uploadFile_old(files) {
      if (files.length > 1) {
        this.$alert("Cannot have >1");
        return;
      }

      if (this.checkExtension(files[0]) === false) {
        console.error("Invalid Extension");
        this.$alert("only docx allowed");
        return;
      }

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("appId", this.appId);

      const postPayload = {
        urlCommand: "/printTemplate/upload",
        options: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        data: formData,
      };

      this.$store.dispatch("AUTH_POST", postPayload).then((res) => {
        console.log('uploadFile: AUTH_POST: res: ', res);
        console.log('uploadFile: AUTH_POST: this.files: ', this.files);
        this.files.splice(0, 1, res);
        console.log('uploadFile: AUTH_POST: this.files: ', this.files);
      });
    },
    checkExtension(file) {
      const VALID_FILE_TYPES = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!VALID_FILE_TYPES.includes(file.type)) {
        return false;
      } else return true;
    },
    onInputFileChanged(e) {
      let files = [];
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }
      this.uploadFile(files);
      this.$refs.uploader.value = "";
    },
    replaceAttachment() {
      this.$refs.uploader.click();
    },
    onConfirm() {
      if (this.files.length == 0) return;

      const data = {
        command: "updateDocxTemplate",
        fileInfo: this.files[0],
      };

      if (this.template) {
        Object.assign(data, {
          templateId: this.template._id,
        });
      }
      this.$emit("onCommand", data);
    },
    onConfirm_old() {
      if (this.files.length == 0) return;

      const data = {
        command: "updateDocxTemplate",
        id: this.files[0].id,
      };

      if (this.template) {
        Object.assign(data, {
          templateId: this.template._id,
        });
      }
      this.$emit("onCommand", data);
    },
    downloadAttachment() {
      console.log('downloadAttachment: file: ', this.files[0]);
      MediaHelper.downloadMedia({
        mediaId: this.files[0].id,
        objectKey: this.files[0].objectKey
      });
      // MediaHelper.getMediaAccess(this.files[0].id).then((result) => {
      //   const accessInfo = {
      //     accessId: result.accessId,
      //   };
      //   MediaHelper.download(accessInfo);
      // });
    },
  },
};
</script>
