<template>
  <div class="workflow-setting-view-condition-info-line">
    <b>{{fieldName(setting, $t('widgets'))}}</b>
    <span> {{ $t(`filters.${setting.content.rule}`, '=') }} </span>
  </div>
</template>

<script>
import Mixin from './../mixin';

export default {
  mixins: [Mixin], 
  props: {
    setting: Object,
  },
};

</script>

<style scoped>

</style>
