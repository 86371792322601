const mixin = {
    mounted () {
        window.addEventListener('keydown', this.keyDownHandler)
    },
    destroyed () {
        window.removeEventListener('keydown', this.keyDownHandler)
    },
}

export default mixin
