const viewTypes = {
  table : {
    labelTag: 'general.table',
    icon: 'mdi-view-list-outline',
  },
  panel: {
    labelTag: 'general.panel',
    icon: 'mdi-view-dashboard-variant-outline',
  },
  levelDiagram: {
    labelTag: 'general.cascade',
    icon: 'mdi-animation-outline',    
  },
  project: {
    labelTag: 'general.project',
    icon: 'mdi-alpha-p-circle-outline',    
  }
}

export default viewTypes
