<template>
  <v-menu
    :rounded="true"
    offset-y>
    <template v-slot:activator="{attrs, on}">
      <div
        v-bind="attrs"
        v-on="on">
        <v-btn
          class="w-100 justify-start text-body-2"
          outlined
          :color="hasError ? 'error' : ''"
          small>
          {{ selectedMenuItem ? selectedMenuItem.label : $t('inputPanel.pleaseSelect') }}
          <span v-if="selectedMenuItem && (selectedMenuItem.label!==selectedMenuItem.masterFormFieldName)"
            class="ml-1 grey--text">
            ({{ selectedMenuItem.masterFormFieldName }})</span>

        </v-btn>
        <error-message
          v-if="hasError"
          :message="errorMessage"></error-message>
      </div>
    </template>
    <v-list subheader>
      <v-list-item v-for="(formInfo, index) in relatedFormInfos"
        @click="selectMenu(formInfo)"
        :key="index">
        {{ formInfo.label }}
        <span v-if="formInfo.Label!==formInfo.masterFormFieldName"
          class="ml-1 grey--text">({{ formInfo.masterFormFieldName }})</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import errorMessage from './ErrorMessage'

export default {
  name: 'relatedFormSelectionMenu',
  components: {
    errorMessage
  },
  model: {
    prop: 'selectedMenuId',
    event: 'input'
  },
  props: {
    selectedMenuId: String,
    masterForm: null
  },
  data () {
    return {
      hasError: false
    }
  },
  computed: {
    relatedFormInfos () {
      const vm = this
      var result = []
      // result = [ 
      //    {
      //        _id: '...',
      //        label: '...'
      //    }
      //    ...
      // ]
      if (vm.masterForm) {
        const fieldInfos = vm.masterForm.fieldInfos.filter(info => info.type === 'relatedRecord')
        for (let i = 0; i < fieldInfos.length; i++) {
          const loopInfo = fieldInfos[i]
          if (loopInfo.properties.dataSource) {
            result.push({
              _id: loopInfo.properties.dataSource,
              label: loopInfo.properties.dataSource.dataTableName,
              masterFormFieldName: loopInfo.label
            })
          }
        }
      }
      return result
    },
    selectedMenuItem () {
      const vm = this
      var result = null
      if (vm.selectedMenuId) {
        for (let i = 0; i < vm.relatedFormInfos.length; i++) {
          const loopInfo = vm.relatedFormInfos[i]
          if (loopInfo._id === vm.selectedMenuId) {
            result = loopInfo
            break
          }
        }
      }
      return result
    },
    modules () {
      return this.$store.getters.currentApp ?
        this.$store.getters.currentApp.modules :
        []
    },
    moduleMenus () {
      const vm = this
      // [
      //    {
      //      type: ['module'|'form'],
      //      label: '...',
      //      formId: '...'
      //    }
      //  ]
      var result = [
        // { label: 'module 1', menus: [...] }
      ]
      for (let i = 0; i < vm.modules.length; i++) {
        const loopModule = vm.modules[i]
        var module = {}
        module = {
           label: loopModule.title,
           menus: vm.getModuleMenu(loopModule.menu),
           type: 'module'
        }
        result.push(module)
    
      }
      return result
    },
    errorMessage () {
      return this.hasError ? this.$t('messages.thisFieldIsRequired') : ''
    }
  },
  methods: {   
    validate () {
      this.hasError = this.selectedMenuId === ''
      return !this.hasError
    },
    resetValidation () {
      this.hasError = ''
    },
    selectMenu (menu) {
      this.$emit('input', menu._id)
      this.$nextTick(() => {
        this.validate()
      })
      
    },
    getModuleMenu (menuItems) {
      const vm = this
      var menus = []
      for (let i = 0; i < menuItems.length; i++) {
        const loopMenu = menuItems[i]
        if (loopMenu.type === 'form') {
          menus.push({
            _id: loopMenu._id,
            label: loopMenu.title,
            formId: loopMenu._id,
            type: 'form'
          })
        } else if (loopMenu.type==='submenu') {
          if (loopMenu.children && loopMenu.children.length > 0) {
            const childMenu = vm.getModuleMenu(loopMenu.children)
            console.log('childMenu ' + loopMenu.title + ' : ', childMenu)
            if (childMenu.length > 0) {
              menus = menus.concat(childMenu)
            }
          }
        }
      }
      return menus
    }
  }
}
</script>