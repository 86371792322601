<template>
  <div>
    <label>{{ $t('widgets.properties.summaryField')}}</label>
    <v-select
      :items="items"
      dense
      outlined
      solo
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
      :placeholder="placeholder"
      :hide-details="true"
      v-model="propertyValue"></v-select>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  // property key: summaryField
  name: 'witSummaryFieldSelection',
  mixins: [mixin],
  data(){
    return{
      acceptedFieldTypes:[
        "text", "number", "amount", "email", "date", "phone", 
        "singleSelection", "multipleSelection", "attachments",
        "yesNo", "rating", "members", "expression"
      ]
    }
  },
  computed: {
    widgetIdSummaryTypeMapping () {
      const vm = this
      var result = {}
      for (let i = 0; i < vm.$store.getters.allWidgets.length; i++) {
        const loopWidget = vm.$store.getters.allWidgets[i]
        result[loopWidget._id] = loopWidget.summaryType
      }
      return result
    },
    items () {
      const vm = this
      var result = []
      if (vm.summaryFieldInfos.length > 0) {
        // for (let i = 0; i < vm.otherOptions.length; i++) {
        //   console.log("vm.otherOptions", vm.otherOptions)
        //   result.push({
        //     text: '{' + vm.$t('widgets.properties.' + vm.otherOptions[i].labelTag) + '}',
        //     value: vm.otherOptions[i].value
        //   })
        // }
        for (let i = 0; i < vm.summaryFieldInfos.length; i++) {
          const loopFieldInfo = vm.summaryFieldInfos[i]
          if(this.acceptedFieldTypes.includes(loopFieldInfo.type)){
            result.push({
              text: loopFieldInfo.label,
              value: loopFieldInfo.fieldId
            })
          }
        }
      }
      return result
    },
    widgetType () {
      const vm = this
      var result = '(unknown)'
      console.log('vm.propertyValue = ' + vm.propertyValue)
      console.log('vm.summaryFieldInfos: ', vm.summaryFieldInfos)
      if (vm.summaryFieldInfos && vm.propertyValue) {

        const fieldInfo = vm.summaryFieldInfos.find(info => info.fieldId === vm.propertyValue)
        const widgetId = fieldInfo.widgetId
        result = vm.widgetIdSummaryTypeMapping[widgetId]
      }
      return result
    },
    placeholder () {
      const vm = this
      if (vm.relatedTable === '') {
        return vm.$t('messages.pleaseSelectRelatedTableFirst')
      } else {
        return vm.$t('messages.pleaseSelectField')
      }
    },
    relatedTable () {
      return this.getPropertyValue('relatedTable', '')
    },

    tableFieldInfoId () {
      // get the field info id of the relation 
      return this.getPropertyValue('tableField', '')
    },

    tableFieldInfo () {
      const vm = this
      var result = null
      if (this.tableFieldInfoId !== '') {
        const fieldInfo =  vm.formFieldInfos.find(info => info._id === vm.tableFieldInfoId)
        if (fieldInfo) {
          result = fieldInfo
        }
      }
      return result
    },

    //**********************************
    // for summary to related records
    //**********************************
    summaryTableId () {
      if(this.tableFieldInfo)
        if(this.tableFieldInfo.type === "childTable")
          return this.tableFieldInfo.properties.fieldsEditor.formId || ""
        else
          return this.tableFieldInfo.properties.dataSource || ""
      return ""
    },

    summaryTableInfo () {
      const vm = this
      var result = null
      if (vm.summaryTableId !== '') {
        const formInfo = vm.dataSources.find(ds => ds._id === vm.summaryTableId)
        result = formInfo
      }
      return result
    },

    //**********************************
    // for summary to child table
    //**********************************
    summaryChildTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.tableFieldInfo && vm.tableFieldInfo.properties.fieldsEditor) {
        result = vm.tableFieldInfo.properties.fieldsEditor.fieldInfos
      }
      return result
    },

    //**********************************
    // Available fieldInfos for summary
    //**********************************
    summaryFieldInfos () {
      const vm = this
      var result = []
      if (vm.tableFieldInfo && vm.summaryTableInfo) {
        switch (vm.tableFieldInfo.type) {
          case 'relatedRecord':
            result = vm.summaryTableInfo.fieldInfos
            break
          case 'childTable':
            result = vm.summaryChildTableFieldInfos
            break
        }
      }
      return result
      // const tableField = vm.fieldInfo.properties.tableField
      // if (tableField) {
      //   console.log('WitSummaryFieldSelection : fieldInfos: vm.fieldInfo: ', vm.fieldInfo)
      //   console.log('WitSummaryFieldSelection : tableField = ' + tableField)
      //   // console.log('WitSummaryFieldSelection : tableField = ' + (tableField ? 'yes' : 'no'))

      //   const fieldInfo = vm.getFieldInfoByInfoId(tableField)
      //   console.log('WitSummaryFieldSelection :; fieldInfo: ', fieldInfo)
      //   switch (fieldInfo.type) {
      //     case 'childTable':
      //       result = fieldInfo.properties.fieldsEditor
      //       break
      //     case 'relatedMultipleRecords':
      //       const relatedFormId = fieldInfo.properties.dataSource
      //       const relatedFormInfo = vm.getFormInfo(relatedFormId)
      //       result = relatedFormInfo.fieldInfos
      //       break
      //   }
      // }
      // return result
    }

  }
}
</script>
