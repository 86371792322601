<template>
  <div class="widget-banner full-width d-flex flex-column px-0 pt-1 pb-3 justify-start"
       style="background-color:transparent;border: none;">
    <div class="d-flex flex-row align-center">
      <widget-type-item style="background-color:rgba(200,200,200,.05);"
                        :widget="widget"
                        :itemsPerRow="1"
        :appendChip="appendChip"></widget-type-item>
    </div>
    <v-divider></v-divider>
    <!--<h3>-->

    <!--<font-awesome-icon :icon="widget.icon" class="mr-2"></font-awesome-icon>-->
    <!--{{ widget.label }}-->
    <!--</h3>-->
  </div>
</template>

<script>
import widgetTypeItem from '@/components/WidgetTypeItem'

export default {
  components: {
    widgetTypeItem
  },
  props: {
    widget: null,
    appendChip: String
  }
}
</script>

<style>
  .widget-banner .widget-wrapper {
    padding: 1px;
  }
</style>
