<template>
  <v-dialog
    eager
    v-model="showingDialog"
    :width="width"
    :height="height"
    persistent
    class="d-flex flex-column vxe-table--ignore-clear"
  >
    <v-card class="base-tabs-dialog d-flex flex-column flex-grow-1">
      <form-title
        :formBuilderButton="false"
        :title="formTitle"
        :buttons="['back', 'submit']"
        :mode="isNew ? 'new' : 'edit'"
        :defaultSubmitCommand="submitCommand"
        :hasSearch="false"
        @onCommand="onCommandHandler"
      >
      </form-title>
      <div
        style="height:480px;"
        class="flex-grow-1 d-flex flex-column fill-height px-2"
      >
      <loading-icon v-if="loading"/>
        <perfect-scrollbar
           v-else
          class="perfect-scrollbar d-flex flex-column flex-grow-1 pa-0 pt-2 pr-5"
          style="height:0;position:relative;"
        >
          <div>
            <v-form
              ref="form"
              class="flex-grow-1 d-flex flex-column"
              @submit="onSubmit"
              onSubmit="return false;"
            >
              <input-widget
                v-for="(fieldInfo, index) in fieldInfos"
                :key="index"
                class="flex-grow-0"
                :ref="fieldInfo.fieldId"
                :isNew="isNew"
                :recordData="recordData"
                :childRecords="childRecords"
                :relatedTableData="relatedTableData"
                :relatedTableInfos="relatedTableInfos"
                :formInfo="formInfo"
                :fieldInfo="fieldInfo"
                @onCommand="onCommandHandler"
              ></input-widget>
            </v-form>
          </div>
        </perfect-scrollbar>
      </div>
      <v-divider class="flex-grow-0"></v-divider>
    </v-card>
    <popupRecordDialog
      v-if="showingRelatedRecordDialog"
      @onCommand="onCommandHandler"
      @ready="loading = false"
      ref="popupRecordDialog"
    ></popupRecordDialog>
  </v-dialog>
</template>

<script>
import recordMixin from "@/mixins/recordMixin";
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import inputWidget from "@/components/inputWidgets/InputWidget";
import formTitle from "@/components/FormTitle";
import ObjectID from "bson-objectid";
import loadingIcon from "@/components/global/loadingIcon"

export default {
  name: "childRecordDialog",
  mixins: [recordMixin],

  props: {
    icon: String,
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "800",
    },
  },
  components: {
    inputWidget,
    popupRecordDialog,
    formTitle,
    loadingIcon
  },
  data() {
    return {
      showingRecordDialog: false,
      title: "",
      isNew: false,
      showingDialog: false,
      showingRelatedRecordDialog: false,
      parentFormFieldId: "",

      recordData: {
        _id: "",
      },
      defaultRecordData: {},
      relatedTableData: {},
      relatedTableInfos: [],

      formInfo: {},
      childTableFieldInfo: {},
      fieldInfos: [],
      onSubmitted: null,
      submitCommand: "submit",
      childRecords: [],
      loading:false
    };
  },
  computed: {
    recordId() {
      return this.recordData ? this.recordData._id : "";
    },
    formTitle() {
      const vm = this;
      var result = (this.childTableFieldInfo || {}).label;
      if (vm.isNew) {
        result += " (" + vm.$t("general.new") + ")";
      } else {
        result += " (" + vm.$t("general.edit") + ")";
      }
      return result;
    },
    appId() {
      return this.formInfo.appId || "";
    },
    formId() {
      return this.formInfo._id || "";
    },
    titleFieldInfoId() {
      return this.formInfo.titleFieldInfoId || "";
    },
  },

  methods: {
    async open(payload) {
      this.loading = true
      this.childRecords = payload.allChildRecords;
      this.defaultRecordData = { ...payload.record, _id: "" };
      this.recordData = { ...payload.record };
      this.isNew = payload.isNew;
      this.childTableFieldInfo = payload.fieldInfo;
      this.formInfo = payload.formInfo;
      this.fieldInfos = payload.fieldInfos;
      this.relatedTableInfos = payload.relatedTableInfos;
      this.showingDialog = true;
      this.onSubmitted = payload.onSubmitted;
      this.relatedTableData = {}
      try {
        await this.loadRelatedTableData(this.formInfo._id, this.fieldInfos, this.recordData)
      } catch (err) {
        console.error(err)
      } finally{
        this.loading = false
      }
     
    
    },
    
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "newRelatedRecord":
          vm.openRelatedRecord(payload);
          break;
        case "editRelatedRecord":
          vm.openRelatedRecord(payload);
          break;
        case "updateFieldValue":
          vm.$set(vm.recordData, payload.fieldName, payload.fieldValue);
          // vm.recordData[payload.fieldName] = payload.fieldValue
          break;

        case "back":
          vm.close();
          break;
        case "submit":
        case "submitAndNew":
        case "submitAndKeep":
          vm.onSubmit(payload.command);
          break;
      }
    },
    openRelatedRecord(payload) {
      this.showingRelatedRecordDialog = true;
      this.$nextTick(() => {
        this.$refs.popupRecordDialog.open(payload);
      });
    },
    close() {
      this.$emit("onCommand", { command: "closeDialog" });
      this.showingDialog = false;
    },

    onSubmit(command) {
      const vm = this;
      vm.save(() => {
        vm.afterSubmission(command);
      });
    },
    trimRecordData(){
      for (const [key, value] of Object.entries(this.recordData)) {
          if (typeof value === "string"){
            this.recordData[key] = value.trim();
          }
        }
    },
    async save(callback) {
      const vm = this;
      const errors = await vm.validateAll();
      if (errors === 0) {
        this.trimRecordData();
        if (typeof vm.onSubmitted === "function") {
          vm.onSubmitted({
            recordData: vm.recordData,
            isNew: this.isNew,
          });
        }
        if (typeof callback === "function") callback();
      }
    },

    afterSubmission(command) {
      const vm = this;
      switch (command) {
        case "submit":
          vm.submitCommand = "submit";
          vm.showingDialog = false;
          break;
        case "submitAndNew":
          vm.submitCommand = "submitAndNew";
          this.recordData = {
            ...this.defaultRecordData,
            _id: "_" + ObjectID().toHexString(),
          };
          break;
        case "submitAndKeep":
          vm.submitCommand = "submitAndKeep";
          vm.isNew = false;
          break;
      }
    },
    async loadRelatedTableData(formId, fieldInfos, recordData){
      const relationFieldInfos = fieldInfos.filter(
        (info) => info.type === "relatedRecord"
      );

      const criteria = [];
      for (let i = 0; i < relationFieldInfos.length; i++) {
        const loopInfo = relationFieldInfos[i];
        const relatedFormId = loopInfo.properties.dataSource;
        if (
          recordData[loopInfo.fieldId] &&
          Array.isArray(recordData[loopInfo.fieldId]) &&
          recordData[loopInfo.fieldId].length > 0
        ) {
          criteria.push({
            formId: formId,
            relatedFormId: relatedFormId,
            fieldId: loopInfo.fieldId,
            recordIds: recordData[loopInfo.fieldId],
          });
        }
      }
      if (criteria.length > 0) {
        const payload = {
        criteria: JSON.stringify(criteria),
      };
      const postParams = {
        urlCommand: "/related_records/fetchMultiple",
        data: payload,
      };

      let res = {};
      if (this.$store.getters.isPublicRoute){
        res = await this.$store.dispatch('COMMON_POST', {
            url: `${this.$store.getters.appHost}/public${postParams.urlCommand}`,
            data: payload,
      })
      } else {
        res = await this.$store.dispatch("AUTH_POST", postParams)
      }
      this.relatedTableData = {...res.relatedTableData}
      }


    }
  },
};
</script>
