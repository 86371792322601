<template>
  <div class="fs-number pa-0 text-no-wrap overflow-hidden d-flex flex-row align-center">
    <label v-if="useLabel"
           class="grey--text text--lighten-1 mr-2">{{ label }}</label>
           <div class="flex-grow-1">
    {{ formattedValue }}
           </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsNumber',
  mixins: [mixin]
}
</script>
