import Vue from 'vue'
import * as types from './action_types'

const state = {
  loaded: false,
  widgetProperties: []
}

const getters = {
  widgetPropertiesLoaded: state => {
    return state.loaded
  },
  widgetProperties: state => {
    return state.widgetProperties
  }
}

const mutations = {
  setWidgetProperties (state, payload) {
    state.widgetProperties = payload
    state.loaded = true
  }
}

const actions = {
  [types.FETCH_WIDGET_PROPERTIES] ({dispatch, commit}) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: '/widget_properties'
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          commit('setWidgetProperties', response)
          resolve(response)
        }
      )
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
