<template>
  <input-widget-container
    style="position: relative"
    :isLayout="isLayout"
    :widget="widget"
    :isHidden="isHidden"
    :required="required"
    :hideLabel="hideLabel"
    :fieldWidth="inRecordDrawer?12:fieldWidth"
    :label="fieldName"
    :labelClass="isTable ? 'my-0 offset-label' : ''"
    :stretch="stretch"
    :class="{
      'mb-2 mt-3': isTable && stretch === 'auto',
      'd-flex flex-column fill-height': stretch === 'true',
    }"
    :tooltipText="description"
    :key="errorUpdateKey"
    v-if="(isPublic && isMounted) || !isPublic"
  >
    <!-- CARD VIEW -->
    <div v-if="isRelatedRecordNew" class="mb-3"
      :id="'field_'+fieldInfo._id">
      <v-chip>{{ $t("general.newRecord") }}</v-chip>
    </div>
    <div
      v-else-if="
        (relatedFormInfo && relatedFormInfo.canAccess) ||
        isLayout ||
        isNew ||
        isPublic
      "
      :class="stretch === 'true' ? 'd-flex flex-column fill-height' : ''"
      :id="'field_'+fieldInfo._id"
    >
      <template v-if="displayFormat === 'card'">
        <v-input
          class="w-100"
          :value="inputValue"
          :rules="rules"
          :error="hasError"
        >
          <v-icon v-if="loading">mdi-loading mdi-spin</v-icon>
          <div v-else class="d-flex flex-column w-100">
            <v-container v-if="relatedRecords.length > 0" fluid class="mt-1">
              <v-row>
                <div
                  :class="recordCardClass"
                  class="mb-1"
                  style="padding: 0 8px 2px 0"
                  v-for="relatedRecord in relatedRecords"
                  :key="relatedRecord._id"
                >
                  <record-card
                    :card="relatedRecord"
                    :edit="!isReadOnly"
                    colorClass="blue lighten-5"
                    :isLayout="isLayout"
                    :cardConfig="cardConfigs"
                    :titleFieldId="relatedTitleFieldId"
                    :fieldInfos="relatedFieldInfos"
                    :buttons="buttons"
                    @click="selectRecord(relatedRecord)"
                    @onCommand="onCommandHandler"
                  ></record-card>
                </div>
              </v-row>
            </v-container>
            <relation-buttons
              v-if="!isReadOnly"
              :fieldInfo="fieldInfo"
              :selection="inputValue"
              class="mb-1"
              @select="selectRelatedRecord"
              @create="newRelatedRecord"
              @batchCreate="batchCreate"
            ></relation-buttons>
          </div>
        </v-input>
      </template>

      <!-- TABLE VIEW -->
      <template v-else-if="isTable">
        <common-table-view
          v-if="isPublic"
          ref="commonTableView"
          :tableConfig="tableDisplaySettings"
          :fieldInfos="(relatedFormInfo || {}).fieldInfos"
          :isLayout="isLayout"
          :readonly="isReadOnly"
          :value="relatedRecordData"
          :parentFieldInfo="fieldInfo"
          :tableFormInfo="relatedFormInfo"
          :hasControl="!isReadOnly"
          :relatedTableInfos="relatedTableInfos"
          :relationData="relationData"
          :canInlineEdit="false"
          @onCommand="onCommandHandler"
        >
          <template v-slot:tools>
            <div></div>
          </template>
          <template v-slot:control="{ row }">
            <v-btn
              class="mx-1"
              dark
              :elevation="false"
              x-small
              fab
              color="error"
              @click.native="deleteRelatedRecord(row)"
            >
              <v-icon>mdi-link-variant-remove</v-icon>
            </v-btn>
          </template>
        </common-table-view>
        <relation-table-view
          v-else
          ref="relationTableView"
          :class="stretch === 'true' ? 'flex-grow-1' : 'mt-3'"
          @updateSummary="onUpdateSummary"
          @updated="onRelatedTableUpdated"
          :isLayout="isLayout"
          :stretch="stretch"
          :isReadOnly="isReadOnly"
          :tableDisplaySettings="tableDisplaySettings"
          :parentFieldId="fieldId"
          :parentFormInfo="activeFormInfo"
          :parentFieldInfo="fieldInfo"
          :parentRecordId="recordId"
          :dataSource="fieldInfo.properties.dataSource"
          :hasControl="!isReadOnly"
          :relatedTableInfos="relatedTableInfos"
          :childFormInfo="relatedFormInfo"
          @onCommand="onCommandHandler"
        ></relation-table-view>
        <v-input
          class="w-100 flex-grow-0"
          :hide-details="stretch === 'true'"
          :value="inputValue"
          :rules="rules"
          :error="hasError"
        >
          <v-icon v-if="loading">mdi-loading mdi-spin</v-icon>

          <relation-buttons
            v-else-if="!isReadOnly"
            :fieldInfo="fieldInfo"
            :disabled="refreshing"
            :selection="inputValue"
            :isReadOnly="isReadOnly"
            class="my-1"
            @create="newRelatedRecord"
            @batchCreate="batchCreate"
            @select="selectRelatedRecord"
          ></relation-buttons>
        </v-input>
      </template>
      <!-- PULL-DOWN VIEW -->

      <select-from-data-source
        v-else
        :id="'field_'+fieldInfo._id"
        ref="selectFromDataSource"
        v-model="relatedRecordData"
        :fieldId="fieldId"
        :readonly="isReadOnly"
        @create="newRelatedRecord"
        @batchCreate="batchCreate"
        :formInfo="relatedFormInfo"
        :dataSource="dataSource"
        :parentFormInfo="activeFormInfo"
        :selectionMode="selectionMode"
        :exclusion="extraExclusion"
        :multiple="isMultiple"
        :isLayout="isLayout"
        :cardSelectionSettings="cardSelectionSettings"
        :fieldInfo="fieldInfo"
        :hasError="hasError"
        :errorMessage="errorMessage"
      ></select-from-data-source>
      <!-- Dialog: Selection Table -->
      <select-related-records-dialog
        ref="selectRelatedRecordsDialog"
        @click:new="newRelatedRecord"
      ></select-related-records-dialog>

      <!-- Dialog: Card Selection -->
      <select-related-cards-dialog
        ref="selectRelatedCardsDialog"
        @click:new="newRelatedRecord"
      ></select-related-cards-dialog>

    </div>
    <div v-else>
      <v-chip small v-if="value">
        <v-icon left dense small style="margin-right: 0px"
          >mdi-link-variant</v-icon
        >
        <div v-if="isTable">{{ value }}</div>
        <div v-else>{{ value.length }}</div>
      </v-chip>
    </div>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import { uniqBy, concat, isArray, findIndex } from "lodash";
import relatedRecordSelectionMixin from "@/mixins/relatedRecordSelectionMixin";

import inputWidgetContainer from "./comps/InputWidgetContainer";
import commonTableView from "@/components/CommonTableView";
// dialogs
import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
import selectRelatedCardsDialog from "@/components/dialogs/SelectRelatedCardsDialog";

import recordCard from "@/components/RecordCard";
import selectFromDataSource from "./comps/SelectFromDataSource";

// functions
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import { getDefaultPropertyValue } from "@/helpers/FormHelpers";

import relationTableView from "./comps/RelationTableView";

import relationButtons from "./comps/RelationButtons";
import { newBlankRecord } from "@/helpers/FormHelpers";
import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";
export default {
  name: "inputWidgetRelatedRecord",
  mixins: [mixin, relatedRecordSelectionMixin],
  components: {
    inputWidgetContainer,

    selectRelatedRecordsDialog,
    selectRelatedCardsDialog,
    commonTableView,
    recordCard,
    selectFromDataSource,
    relationTableView,
    relationButtons,
  },
  data() {
    return {
      items: [],
      isRelatedRecordNew: false,
      RULE_REQUIRED: (v) => {
        let result = this.$t("messages.thisFieldIsRequired");
        if (this.isTable) {
          if(!this.isPublic){
            result =
            (!!v && Array.isArray(v) && v.length > 0) ||
            this.$t("messages.thisFieldIsRequired");
          }else{
            result =
            (!!v && Array.isArray(v) && v.length > 0) ||
            this.$t("messages.thisFieldIsRequired");
          }
        } else {
          result =
            (!!v && Array.isArray(v) && v.length > 0) ||
            this.$t("messages.thisFieldIsRequired");
        }
        return result;
      },
      relationData: {
        idDataMapping: {},
      },
      refreshing: false,
      loading:false,
      relatedTableData: [],
      errorUpdateKey: 0,
      isMounted: false
    };
  },
  watch: {
    relatedFormInfo: function () {
      this.checkIsNew();
    },
    inputValue: function (newVal) {
      if (newVal) {
        if (isArray(newVal)) {
          if (newVal.length) {
            this.updateChangesRelated();
          // no selected in array
          } else {
            this.removeChangesRelated();
          }
        } else {
          this.updateChangesRelated();
        }
      } else {
        this.removeChangesRelated();
      }
      this.validate()
    },
  },
  computed: {
    popupSelectPayload() {
      const vm = this;
      const selectionSettings =
        vm.selectionMode === "table"
          ? vm.tableSelectionSettings
          : vm.cardSelectionSettings;
      return {
        value: vm.isTable && !this.isPublic ? [] : vm.inputValue,
        dataSource: vm.dataSource,
        formInfo: vm.relatedFormInfo,
        selectionSettings: selectionSettings,
        multiple: vm.isMultiple,
        exclusion: vm.extraExclusion,

        callbackBundle: {
          id: "",
          onSelected: null,
        },

        recordId: (vm.recordData && vm.recordData._id) || "",
        fieldId: vm.fieldId,
        parentFormId: this.formInfo._id,
      };
    },
    isTable() {
      return this.displayFormat === "table";
    },

    relatedFormId() {
      return this.dataSource;
    },
    tableColumns() {
      const vm = this;
      let result = [];
      if (vm.tableDisplaySettings) {
        if (vm.tableDisplaySettings.fixedFieldIds) {

          for (
            let i = 0;
            i < vm.tableDisplaySettings.fixedFieldIds.length;
            i++
          ) {
            const fieldId = vm.tableDisplaySettings.fixedFieldIds[i];
            const fieldInfo = vm.fieldInfos.find(
              (info) => info.fieldId === fieldId
            );
            if (fieldInfo) {
              result.push(fieldInfo);
            }
          }
        }
        if (vm.tableDisplaySettings.nonFixedFieldIds) {

          for (
            let j = 0;
            j < vm.tableDisplaySettings.nonFixedFieldIds.length;
            j++
          ) {
            const fieldId = vm.tableDisplaySettings.nonFixedFieldIds[j];
            const fieldInfo = vm.fieldInfos.find(
              (info) => info.fieldId === fieldId
            );
            if (fieldInfo) {
              result.push(fieldInfo);
            }
          }
        }
      }
      return result;
    },
    fieldInfos() {
      return this.isLayout
        ? this.$store.getters.formFieldInfos
        : this.formInfo.fieldInfos;
    },
    recordId() {
      const vm = this;
      let result = "";
      if (vm.recordData) {
        result = vm.recordData._id;
      }
      return result;
    },
    relatedRecordData: {
      get() {
        const vm = this;
        let result = [];
        if (vm.relatedTableData) {
          result = this.relatedTableData[this.fieldId]
            ? this.relatedTableData[this.fieldId]
            : [];
        }
        result = result.filter((record) => this.value.includes(record._id));
        // if (Object.keys(result).length === 0) {
        //   vm.checkIsNew()
        // }
        return result;
      },
      set(val) {
        this.inputValue = val.map((record) => record._id);
        val.forEach((record) => {
          if (
            !this.relatedTableData[this.fieldId].find(
              (val) => val._id === record._id
            )
          ) {
            this.relatedTableData[this.fieldId].push(record);
          }
        });
      },
    },
    extraExclusion() {
      const vm = this;
      let result = [];
      // for parent/child field
      // a record cannot be both
      //
      // self is excluded in all selection
      if (vm.isParentChildRelation) {
        //
        // requires checking to ensure proper display
        // (in layout mode, recordData is undefined)
        //
        if (vm.recordData && vm.recordData._id) {
          result.push(vm.recordData._id);
          // if this is of parent/child group,
          // there is another field in same group
          if (vm.parentChildRelatedFieldId) {
            const fieldValue = vm.recordData[vm.parentChildRelatedFieldId];
            if (
              fieldValue &&
              Array.isArray(fieldValue) &&
              fieldValue.length > 0
            ) {
              result = result.concat(fieldValue);
            }
          }
        }
      }

      return result;
    },
    recordCardClass() {
      const vm = this;
      let result = "small";
      if (this.$route.name === "PublicQuery") result = "large";
      else if (vm.cardConfigs && vm.cardConfigs.cardWidthSize) {
        result = vm.cardConfigs.cardWidthSize;
      }
      switch (result) {
        case "small":
          result = "col-4";
          break;
        case "middle":
          result = "col-6";
          break;
        case "large":
          result = "col-12";
          break;
      }
      return result;
    },

    //*************************
    // form info for form entry
    //*************************
    dataRelatedTableIds() {
      return this.$store.getters.relatedTableIds;
    },

    //*************************
    // form info for form builder
    //*************************
    dataSources() {
      return this.$store.getters.dataSources;
    },

    //**************************
    // form info
    //**************************
    relatedFieldInfos() {
      const vm = this;
      let result = [];
      if (vm.relatedFormInfo) {
        result = vm.relatedFormInfo.fieldInfos;
      }
      return result;
    },
    //**************************
    // All related data loaded in store
    //**************************
    allRelatedRecordData() {
      return this.relatedTableData ? this.relatedTableData : [];
      // const vm = this
      // const result = this.$store.getters.relatedTableData
      // return result
    },
    relatedRecords() {
      const vm = this;
      // console.log('relatedRecords:')
      let result = [];
      let inputValueIds = vm.inputValue.map((item) => {
        return typeof item === "object" ? item._id : item;
      });
      if (!vm.isLayout) {
        if (vm.allRelatedRecordData && vm.allRelatedRecordData[vm.fieldId]) {
          // console.log('relatedRecords: vm.allRelatedRecordData[' + vm.fieldId + ']')
          result = vm.allRelatedRecordData[vm.fieldId].filter((record) => {
            let result = false;
            if (record) {
              result = inputValueIds.includes(record._id);
            }
            return result;
          });
          // if (Object.keys(result).length === 0) {
          //   vm.checkIsNew()
          // }
          // console.log('relatedRecords: not isLayout result: ', result)
        }
      } else {
        // is layout, show samples
        if (vm.cardConfigs) {
          let recordCount = 1;
          if (vm.multiple) {
            switch (vm.cardConfigs.cardWidthSize) {
              case "small":
                recordCount = 3;
                break;
              case "normal":
                recordCount = 2;
                break;
            }
          }
          for (let i = 0; i < recordCount; i++) {
            result.push(newBlankRecord(vm.relatedFieldInfos));
          }
        }
      }
      // console.log('relatedRecords: result: ', result)
      return result;
    },

    //**************************
    // Related data for this input
    //**************************
    relatedRecordDataExists() {
      return this.relatedRecords !== null;
    },

    twoWayRelated() {
      return getFieldPropertyValue(this.fieldInfo, "twoWayRelated", "");
    },

    relatedCardFieldIds() {
      const vm = this;
      let result = [];
      if (vm.relatedTitleFieldInfo) {
        result.push(vm.relatedTitleFieldInfo.fieldId);
      }

      let selectedCardFields = [];
      if (
        vm.relatedRecordDisplayFields &&
        vm.relatedRecordDisplayFields.cardFields
      ) {
        selectedCardFields = vm.relatedRecordDisplayFields.cardFields.filter(
          (field) => field.active && field.id !== vm.relatedTitleFieldId
        );
      }

      return result.concat(selectedCardFields.map((field) => field.id));
    },

    cardConfigs() {
      const vm = this;
      let result = null;
      if (vm.displayFormat === "card") {
        const cardDisplaySettings = getFieldPropertyValue(
          vm.fieldInfo,
          "cardDisplaySettings",
          null
        );

        if (cardDisplaySettings) {
          result = cardDisplaySettings;
        }
      }
      return result;
    },
    selectionMode() {
      return getFieldPropertyValue(this.fieldInfo, "selectionMode", "table");
    },
    relatedRecordDisplayFields() {
      const vm = this;
      const propertyValue = getFieldPropertyValue(
        vm.fieldInfo,
        "relatedRecordDisplayFields",
        null
      );
      let defaultPropertyValue = getDefaultPropertyValue(propertyValue);
      return getFieldPropertyValue(
        vm.fieldInfo,
        "relatedRecordDisplayFields",
        defaultPropertyValue
      );
    },
    relatedTitleFieldLabel() {
      const vm = this;
      let result = "{" + vm.$t("general.unspecified") + "}";
      if (vm.relatedTitleFieldInfo) {
        result = vm.relatedTitleFieldInfo.label;
      }
      return result;
    },
    relatedTitleFieldInfo() {
      const vm = this;
      let result = null;
      if (vm.relatedFormInfo && vm.relatedFormInfo.titleFieldInfoId !== "") {
        const titleFieldInfoId = vm.relatedFormInfo.titleFieldInfoId;
        const fieldInfo = vm.relatedFormInfo.fieldInfos.find(
          (info) => info._id === titleFieldInfoId
        );
        if (fieldInfo) {
          result = fieldInfo;
        }
      }
      return result;
    },
    relatedTitleFieldId() {
      const vm = this;
      let result = "";
      if (vm.relatedTitleFieldInfo) {
        result = vm.relatedTitleFieldInfo.fieldId;
      }
      return result;
    },
    allDisplayFieldIds() {
      const vm = this;
      const selectedDisplayFieldIds = vm.fixedFieldIds.concat(
        vm.nonFixedFieldIds
      );
      if (vm.relatedTitleFieldId && vm.relatedTitleFieldId !== "") {
        const titleFieldIndex = selectedDisplayFieldIds.indexOf(
          vm.relatedTitleFieldId
        );
        if (titleFieldIndex >= 0) {
          selectedDisplayFieldIds.splice(titleFieldIndex, 1);
        }
      }
      return [vm.relatedTitleFieldId, ...selectedDisplayFieldIds];
    },
    nonFixedFieldIds() {
      return (
        (this.fieldInfo.properties.tableDisplaySettings &&
          this.fieldInfo.properties.tableDisplaySettings.nonFixedFieldIds) ||
        []
      );
    },
    fixedFieldIds() {
      return (
        (this.fieldInfo.properties.tableDisplaySettings &&
          this.fieldInfo.properties.tableDisplaySettings.fixedFieldIds) ||
        []
      );
    },
    avatarFieldId() {
      const vm = this;
      return getFieldPropertyValue(
        vm.fieldInfo,
        "relatedRecordAvatarField",
        ""
      );
    },
    hasAvatar() {
      const vm = this;
      return vm.avatarFieldId !== "";
    },
    avatarValue() {
      const vm = this;
      let result = "";
      if (vm.avatarFieldId !== "" && vm.relatedRecordData) {
        // attachment field is an array
        // if only one attachment allowed, i.e. 1st element
        result = vm.relatedRecordData[vm.avatarFieldId][0];
      }
      return result;
    },
    buttons() {
      return this.isPublic
        ? [{ key: "unlink", command: "unlinkRelatedRecord" }]
        : [
            { key: "edit", command: "editRelatedRecord" },
            { key: "unlink", command: "unlinkRelatedRecord" },
          ];
    },
    currentApp() {
      return this.$store.getters.currentApp;
    }
  },
  methods: {
    batchCreate(e){
      this.$emit("onCommand", {
        command: "openBatchInsertDialog", data: {...e}
      })
    },
    validate(){
      if (this.isReadOnly) return true;
      this.errorMessage = "";
      // if (this.rules.length > 0) {
      //   if (this.rules.some(rule=> rule(this.value) !== true)) {
      //     this.hasError = true
      //     return false
      //   }
      // }
      if (this.rules.length > 0) {
        if (
          this.rules.some((rule) => {
            const result = rule(this.value);
            if (result !== true) {
              this.errorMessage = result;
              return true;
            }
            return false;
          })
        ) {
          this.hasError = true;
        }else this.hasError = false;
      }else{
        this.hasError = false;
      }
      if(this.displayFormat === 'table')
        this.errorUpdateKey++
      return !this.hasError
    },
    checkIsNew() {
      const vm = this;

      if (vm.fieldInfo.properties.displayFormat !== "table" && !this.isPublic) {
        if (vm.relatedFormInfo) {
          if (
            Array.isArray(vm.inputValue) &&
            vm.inputValue.length === 1 &&
            vm.inputValue[0] !== null
          ) {
            const params = {
              id:
                typeof vm.inputValue[0] === "object"
                  ? vm.inputValue[0]._id
                  : vm.inputValue[0],
              formId: vm.relatedFormInfo._id,
            };
            if(vm.$route.name === "PublicEdit"){
              vm.isRelatedRecordNew = false;
            }else{
              this.$store
                .dispatch("CHECK_TEMP_ID", params)
                .then((validTempId) => {
                  vm.isRelatedRecordNew = validTempId;
                });
            }
          }
        }
      }
    },
    clear() {
      this.$refs.commonTableView.tableData = [];
    },
    updateTableModeValidation() {
      const vm = this;
      if (vm.isTable) {
        // vm.inputValue = Array(vm.$refs.relationTableView.dataCount).fill('')
        // console.log('===========================', vm.inputValue);
      }
    },
    onUpdateSummary() {
      this.updateSummaryField();
    },

    onRelatedTableUpdated(payload) {
      this.updateSummaryField(payload);
    },
    _parseValue(val) {
      let result = [];
      // console.log('_parseValue : val: ', val)
      if (!Array.isArray(val)) {
        if (val !== "" && val !== "0" && val !== 0) {
          result = [val];
        }
      } else {
        result = val;
      }
      return result;
    },

    _getDefaultValue() {
      // console.log('InputWidgetRelatedRecord :: _getDefaultValue')
      return [];
    },

    editRelatedRecord(payload) {
      const vm = this;

      const relatedRecord = payload.record;

      vm.$emit("onCommand", {
        command: "editRelatedRecord",
        // titleInfo: {
        //   fieldName: vm.fieldInfo.label,
        //   formLabel: vm.relatedFormInfo.label
        // },
        fieldLabel: vm.fieldInfo.label,
        mode: "edit",
        recordId: relatedRecord._id,
        formInfo: vm.relatedFormInfo,
        onSubmitted: vm.onEditRecordSubmitted,
      });
    },
    newRelatedRecord() {
      // console.log("InputWidgetRelatedRecord :: newRelatedRecord");


      const vm = this;
      let defaultValues = {};
      if (vm.twoWayRelated[0] === "1") {
        // Notes: no default value for the relation pointing back to current record is quired
        //
        // The relation will be updated in 'onNewRecordSubmitted'
        // after returm from popup dialog
        //

        // The codes below is unnecessary
        //
        const relatedFieldId = vm.twoWayRelated.split("|")[1];
        defaultValues[relatedFieldId] = [vm.recordData._id];
      } else if (vm.isParentChildRelation) {
        defaultValues[vm.parentChildRelatedFieldId] = [vm.recordData._id];
      }


      vm.$emit("onCommand", {
        command: "newRelatedRecord",
        // titleInfo: {
        //   fieldName: vm.fieldInfo.label,
        //   formLabel: vm.relatedFormInfo.label
        // },
        fieldLabel: vm.fieldInfo.label,
        fieldId: vm.fieldInfo.fieldId,
        recordId: "",
        formInfo: vm.relatedFormInfo,
        defaultValues: defaultValues,
        onSubmitted: vm.onNewRecordSubmitted,
      });
    },

    onNewRecordSubmitted(newRecord) {
      const vm = this;
      if (vm.isTable) {
        const linked = true; // [true=add | false=remove]
        vm.updateRelatedRecordsForTable(newRecord._id, linked, (v) => {
          if (vm.isNew) {
            vm.$refs.relationTableView.fetchRelatedTableInfos();
          }
          vm.inputValue.push(newRecord._id);
          vm.$refs.relationTableView.refresh();
          this.updateSummaryField();
        });
      } else {
        if (vm.isMultiple) {
          vm.inputValue = [...vm.inputValue, newRecord._id];
          if(vm.relatedTableData[vm.fieldId])
            vm.relatedTableData[vm.fieldId].push(newRecord);
          else 
            vm.relatedTableData[vm.fieldId] = [newRecord]
        } else {
          vm.inputValue = [newRecord._id];
          vm.relatedTableData[vm.fieldId] = [newRecord];
        }
        
        if(vm.displayFormat === 'pulldown')
          vm.$refs.selectFromDataSource.appendItem(newRecord);
        
        // if pulldown menu, refresh selection list
        if (vm.selectionMode === "simple") {
          if(vm.displayFormat === 'pulldown')
            if(typeof vm.$refs.selectFromDataSource.loadData === 'function')
              vm.$refs.selectFromDataSource.loadData();
        }
        vm.updateSummaryField();
        // vm.saveSelectionHandler([newRecord._id]);
      }
    },

    onEditRecordSubmitted(record) {
      const vm = this;
      // no change to relatioin
      if (vm.isTable) {
        vm.$refs.relationTableView.refresh();
        vm.$emit("onCommand", {
          command: "reloadRelatedRecords",
          fieldId: vm.fieldId,
        });
      } else {
        vm.relatedTableData[vm.fieldId] = [record];
      }
      // => nothing to do after record updated and submitted
      //
      this.updateSummaryField();
    },

    deleteRelatedRecord(recordData) {
      this.inputValue = this.inputValue.filter((id) => id !== recordData._id);
    },
    selectRelatedRecord() {
      const vm = this;
      switch (vm.selectionMode) {
        case "table":
          vm.popupSelectRelatedTable();
          break;
       default:
          vm.popupSelectRelatedInCards();
          break;
      }
    },
    popupSelectRelatedTable() {
      const vm = this;
      const payload = vm.popupSelectPayload;
      vm.$refs.selectRelatedRecordsDialog.open(payload, vm.onSelectedHandler);
    },

    popupSelectRelatedInCards() {
      const vm = this;
      const payload = vm.popupSelectPayload;
      vm.$refs.selectRelatedCardsDialog.open(payload, vm.onSelectedHandler);
    },

    handlePullDownClick(payload) {
      if (!this.isPublic) {
        this.updateRelatedRecordsForTable(payload.id, payload.isAdd);
        this.updateSummaryField();
      }
    },
    async onSelectedHandler(newSelection) {
      if (this.isMultiple) {
        this.inputValue = this.inputValue.concat(newSelection);
      } else {
        this.inputValue = [...newSelection];
      }
      // if (!this.isPublic && this.displayFormat === "table") {
      if (!this.isPublic) {
        await this.onSaved(this.dataSource, newSelection, this.inputValue);
      }
      let vm = this
      this.$nextTick(()=> {
        vm.validate()
      })
    },

    async onSaved(dataSource, newSelection, allSelection, linked=true) {
      if(allSelection===undefined||allSelection===null) 
        allSelection = this.inputValue
      const vm = this;
      // if table format, update database at once
      // if (!this.isPublic && vm.displayFormat === "table") {
      if (
        !this.isPublic &&
        this.displayFormat === "table" &&
        ((this.recordData && this.recordData._id))
      ) {
        vm.updateRelatedRecordsForTable(
          newSelection,
          linked,
          () => {
                vm.$refs.relationTableView.refresh();
                vm.updateSummaryField();
              }
        );
      } else {
        await vm.loadRelatedRecordData(vm.dataSource, allSelection)
        if (this.isPublic) {
          const response = await fetchRelatedDataFieldsInRows(
            vm,
            this.relatedRecordData,
            null,
            this.isPublic,
            this.relatedFieldInfos,
            this.allDisplayFieldIds,
          )
          vm.relationData.idDataMapping = response;
        }
        if (vm.isMultiple && this.recordData && this.recordData._id) {
          vm.updateSummaryField();
        }
        vm.copyRelatedFields();
      }
    },

    updateSummaryField() {
      const vm = this;
      vm.updateTableModeValidation();
      if (!vm.isMultiple || this.isPublic) return;

      // let recordIds = vm.displayFormat === "table" ? [] : vm.inputValue;
      let recordIds = vm.inputValue;
      //console.log("updateSummaryField :: recordIds: ", recordIds);
      // const updateDb = vm.displayFormat === "table" && !vm.isNew;
      const updateDb = !vm.isNew;
      // pass to server for summary calcuation
      const params = {
        id: vm.recordData._id,
        formId: vm.activeFormInfo._id,
        fieldIds: vm.fieldInfos
          .filter((info) => info.properties.tableField === vm.fieldInfo.fieldId)
          .map((info) => info.fieldId),

        summaryRecordIds: recordIds, // these fields not required if input widget is table

        updateDb: updateDb,
      };
      if(vm.displayFormat  === "table"){
        vm.$store.dispatch("UPDATE_SUMMARY_FIELDS", params).then((response) => {
          if (response) {
            for (let fieldId in response) {
              const fieldValue = response[fieldId];
              vm.$emit("onCommand", {
                command: "updateFieldValue",
                fieldName: fieldId,
                fieldValue: fieldValue,
              });
              vm.updateChangesRelated(fieldId);
            }
          }
          vm.$nextTick(() => {
            vm.$store
              .dispatch("UPDATE_CASCADE_SUMMARY", {
                formId: vm.formInfo._id,
                recordId: vm.recordData._id,
              })
              .then((response) => {
                console.log(
                  "UPADTE_CASCADE_SUMMARY starting :: response: ",
                  response
                );
              });
          });
        });
      }
    },

    async copyRelatedFields() {
      const vm = this;
      // current field is of type Array
      // vm.relatedRecordData contains all related records of record ids in current field
      // => search
      const currFieldValue = vm.recordData[vm.fieldId];
      if (currFieldValue.length > 0) {
        // const relatedRecordId = currFieldValue[0];
        const relatedRecordId = currFieldValue;
        const relatedRecordData = vm.relatedRecordData.filter((record) =>
          relatedRecordId.includes(record._id)
        );
        // const relatedRecordData = vm.relatedRecordData.find(
        //   (record) => record._id === relatedRecordId
        // );
        const fieldCopyingItems = getFieldPropertyValue(
          vm.fieldInfo,
          "relatedRecordCopyFields",
          {}
        );

        const forceCopy =
          getFieldPropertyValue(vm.fieldInfo, "forceCopy", "") === "true";

        for (let relatedFieldId in fieldCopyingItems) {
          const targetFieldIds = fieldCopyingItems[relatedFieldId];
          for (let i = 0; i < targetFieldIds.length; i++) {
            let localFieldId = targetFieldIds[i];
            if (
              forceCopy ||
              !vm.recordData[localFieldId] ||
              vm.recordData[localFieldId] === null ||
              Array.isArray(vm.recordData[localFieldId])
            ) {
              let field = vm.fieldInfos.find(
                (item) => item.fieldId == localFieldId
              );
              if (field.type == "relatedRecord") {
                let recordIds = [];
                recordIds = relatedRecordData.reduce((a, c) => {
                  if (
                    Array.isArray(c[relatedFieldId]) &&
                    c[relatedFieldId].length
                  ) {
                    let arr = c[relatedFieldId].map((item) => {
                      return typeof item === "object" ? item._id : item;
                    });
                    a = a.concat(arr);
                  }
                  return a;
                }, []);
                await vm.$store
                  .dispatch("FETCH_RELATED_RECORDS", {
                    recordIds,
                    dataSource: field.properties.dataSource,
                    fieldId: localFieldId,
                    isPublic: vm.isPublic,
                  })
                  .then((response) => {
                    vm.$set(vm.relatedTableData, localFieldId, response);
                    vm.$set(vm.recordData, localFieldId, recordIds);
                    if(field.properties.displayFormat  === "table"){
                      vm.$emit("onCommand", {
                        command: "updateforceCopyRelatedRecords",
                        fieldId: field._id,
                        dataSource: field.properties.dataSource,
                        newSelection: recordIds,
                        allSelection:[]
                      });
                    }
                  });
              } else {
                if (
                  relatedRecordData &&
                  relatedRecordData[0] &&
                  relatedRecordData[0][relatedFieldId] !== null && relatedRecordData[0][relatedFieldId] !== undefined
                )
                  vm.$set(
                    vm.recordData,
                    localFieldId,
                    relatedRecordData[0][relatedFieldId]
                  );
              }
            }

            /**
             *  handle attachments
             */
            const { type: fieldType } = vm.fieldInfos.find(
              (_field) => _field._id === localFieldId
            );
            if (
              fieldType === "attachments" &&
              (forceCopy || vm.recordData[localFieldId].length === 0)
            ) {
              if (relatedRecordData[relatedFieldId]) {
                const ids = relatedRecordData[relatedFieldId].map(
                  (_item) => _item.mediaId
                );
                // console.log( `FEATURE #991 :: IDS`, ids )
                // console.log( `FEATURE #991 :: currentApp _id`, vm.currentApp._id  );
                try {
                  let { newAttachments } = await vm.$store.dispatch(
                    "COPY_ATTACHMENTS",
                    {
                      appId: vm.currentApp._id,
                      teamId: vm.currentApp.teamId,
                      employeeId: vm.$store.getters.activeEmployeeId,
                      ids: ids,
                    }
                  );
                  vm.recordData[localFieldId] = newAttachments;
                } catch (error) {
                  console.error(`COPYING ATTACHMENTS ERROR`, error);
                }
              }
            }
          }
        }
      }
    },
    async updateValue(val) {
      const vm = this;
      this.$emit('input', val)
      if (this.isPublic || vm.displayFormat != "table") {
        await vm.onSaved(vm.dataSource, val, val);
      }
    },
    async loadRelatedRecordData(dataSource, recordIds) {
      const vm = this;
      if (recordIds.length > 0) {
        const response = await vm.$store
          .dispatch("FETCH_RELATED_RECORDS", {
            recordIds: recordIds,
            dataSource: dataSource,
            fieldId: vm.fieldId,
            isPublic: this.isPublic,
          })
        vm.$set(vm.relatedTableData, vm.fieldId, response);
      } else {
        vm.$set(vm.relatedTableData, vm.fieldId, []);
      }
    },

    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "startRefreshing":
          vm.refreshing = true;
          break;
        case "stopRefreshing":
          vm.refreshing = false;
          break;
        case "editRelatedRecord":
          vm.editRelatedRecord(payload);
          break;
        case "unlinkRelatedRecord": {
          const dataId = payload.record._id;
          if (!this.isPublic && this.recordData && this.recordData._id) {
            vm.updateRelatedRecordsForTable(dataId, false);
          }
          const valueIndex = vm.inputValue.indexOf(dataId);
          vm.inputValue.splice(valueIndex, 1);
          this.$set(this.relatedTableData, this.fieldId, []);
          if (vm.$refs.relationTableView) {
            vm.$refs.relationTableView.refresh();
          }
          vm.saveValueHandler([], this.onSaved);
          vm.updateSummaryField();
        }

          break;
        case "duplicateRecordForTable":
          vm.duplicateRecordForTable(payload.recordId, () => {
            if (typeof payload.callback === "function") {
              payload.callback();
            }
          });
          break;
        case "unlinkRelatedRecordsForTable":
          vm.updateRelatedRecordsForTable(payload.recordIds, false, (v) => {
            if (typeof payload.callback === "function") {
              payload.callback();
            }
          });
          vm.updateSummaryField();
          break;
        case "deleteRelatedRecordsForTable":
          vm.deleteRelatedRecordsForTable(payload.recordIds)
          break;
        case "selectRecord":
          this.selectRecord(payload.record);
          break;
        default:
          this.$emit("onCommand", payload);
          break;
      }
    },

    duplicateRecordForTable(recordId, callback) {
      const vm = this;
      const data = {
        formId: vm.dataSource,
        recordId,
        parentFormId: this.formInfo._id,
        parentRecordId: this.recordData._id,
        parentFieldId: this.fieldId,
      };
      const postData = {
        urlCommand: "/data/duplicateRecord",
        data,
      };
      this.$store.dispatch("AUTH_POST", postData).then((response) => {
        //vm.inputValue = response;
        if (typeof callback === "function") {
          callback(response);
        }
      });
    },

   async deleteRelatedRecordsForTable(recordIds) {
      let data = {
        formId: this.relatedFormInfo._id,
        include: recordIds,
        appId:this.currentApp._id
      };
      const postData = {
        urlCommand: "/data/deleteRows",
        data,
      };

     await this.$store.dispatch("AUTH_POST", postData);
     this.updateRelatedRecordsForTable(recordIds, false)
      this.updateSummaryField();

    },
    updateChangesRelated() {
      if (this.recordData) {
        let { changes = [] } = this.recordData;
        changes = uniqBy(concat([], changes, this.fieldId), (f) => String(f));
        this.$set(this.recordData, "changes", changes);
      }
    },
    removeChangesRelated() {
      if (this.recordData) {
        const { changes = [] } = this.recordData;
        const targetIndex = findIndex(changes, f => String(f) === this.fieldId);
        if (targetIndex !== -1) {
          changes.splice(targetIndex, 1);
          this.$set(this.recordData, "changes", changes);
        }
      }
    },
    updateRelatedRecordsForTable(relatedRecordId, linked, callback) {
      if (
        (!Array.isArray(relatedRecordId) && relatedRecordId) ||
        relatedRecordId.length > 0
      ) {
        const vm = this;
        const relatedRecordIds = Array.isArray(relatedRecordId)
          ? relatedRecordId
          : [relatedRecordId];

        const params = {
          id: vm.recordData._id,
          formId: vm.activeFormInfo._id,
          fieldId: vm.fieldId,
          relatedRecordIds: relatedRecordIds,
          linked: linked,
        };
        vm.$store
          .dispatch("UPDATE_RELATED_RECORDS", params)
          .then((response) => {
            if (!linked) {
              //delete from inputValue
              this.inputValue = this.inputValue.filter(
                (id) => !response.includes(id)
              );
            }
            vm.updateChangesRelated(vm.fieldId);
            if (typeof callback === "function") {
              callback();
            }
          });
      }
    },
    selectRecord(record) {
      this.$emit("onCommand", {
        command: "selectRecord",
        recordId: record._id,
        form: this.relatedFormInfo,
      });
    },
    async fetchRelatedRecord() {
      if (
        !this.relatedTableData[this.fieldId] ||
        !this.relatedTableData[this.fieldId].find((doc) =>
          this.value.includes(doc._id)
        )
      ) {
        await this.loadRelatedRecordData(this.dataSource, this.value)
        if (this.isPublic) {
          const response = await fetchRelatedDataFieldsInRows(
            this,
            this.relatedRecordData,
            null,
            this.isPublic,
            this.relatedFieldInfos,
            this.allDisplayFieldIds,
          )
          this.relationData.idDataMapping = response;
        }
      }
    },
  },
  async mounted() {
    if (this.isPublic) {
      if (!this.relatedTableInfos[this.relatedFormId]) {
        const result = await this.$store.dispatch(
          "FETCH_RELATED_FORM",
          this.relatedFormId
        );
        this.$emit("onCommand", {
          command: "setRelatedTable",
          form: result,
          cb: () => {
            this.fetchRelatedRecord();
          },
        });
      } else {
        this.fetchRelatedRecord();
      }
    }
    this.checkIsNew();
    this.isMounted = true
  },
};
</script>

<style>
.v-select-list .v-list-item .data-block-card {
  min-height: 20px !important;
}

.offset-label {
  margin-bottom: -30px;
}
</style>
