<template>
  <div class="tab-table-display-settings flex-grow-1 d-flex flex-column px-2 fill-height">
    <field-selection-panes
        :sourceListTitle="$t('view.availableFields')"
        :targetListTitle="$t('view.displayFields')"

        :sourceButton="false"
        :targetButton="false"

        :fixedListEditable="false"

        :fixedListTitle="$t('view.basicField')"
        :nonFixedListTitle="$t('view.extraFields')"

        :nonFixedPlaceHolder="$t('view.none')"
        
        :fieldInfos="fieldInfos"
        v-model="selectedFieldIds">
    </field-selection-panes>
  </div>
</template>

<script>
  import mixin from './mixin'
  import fieldSelectionPanes from './comps/FieldSelectionPanes'

  export default {
    name: 'tabCardDisplayFields',
    mixins: [mixin],
    components: {
      fieldSelectionPanes
    },
    computed: {
      selectedFieldIds: {
        get () {
          const vm = this
          return {
            fixedFieldIds: vm.fixedFieldIds,
            nonFixedFieldIds: vm.nonFixedFieldIds
          }
        },
        set (val) {
          const vm = this
          vm.tabData[vm.tabKey]['fixedFieldIds'] = val.fixedFieldIds
          vm.tabData[vm.tabKey]['nonFixedFieldIds'] = val.nonFixedFieldIds
        }
      },
      fixedFieldIds: {
        get () {
          const vm = this
          return vm.tabData[vm.tabKey]['fixedFieldIds']
        },
        set (val) {
          const vm = this
          vm.tabData[vm.tabKey]['fixedFieldIds'] = val
        }
      },
      nonFixedFieldIds: {
        get () {
          const vm = this
          return vm.tabData[vm.tabKey]['nonFixedFieldIds']
        },
        set (val) {
          const vm = this
          vm.tabData[vm.tabKey]['nonFixedFieldIds'] = val
        }
      }
    }
  }
</script>


<style>
  /*.view-settings-table-settings .field-list {*/
  /*min-height: 200px;*/
  /*}*/
  .field-list .v-list-item:nth-child(even) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>
