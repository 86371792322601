import Vue from "vue";
import { app } from "@/main";
import { v4 } from "uuid";
import { io, Manager } from "socket.io-client";
import { keys, omit, pick, values } from "lodash";
import {
  CONNECT_TO_SERVER,
  EVENT_LEAVEROOMS,
  EVENT_JOINROOMS,
  OUTBOUND_EVENT_LEAVEROOMS,
  OUTBOUND_EVENT_JOINROOMS,
  EVENT_WORKFLOWINSITEMESSAGE,

  // event listen
  EVENT_CUSTOMACTIONBUTTONSHOWPROCESS,
  OFF_EVENT_CUSTOMACTIONBUTTONSHOWPROCESS,
  SHOW_AUTOMATETASK_POPUP,
  SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION,
  HIDE_BELL,
  UPDATE_COUNTER,
  CLEAR_COUNTER,
  TOGGLE_PANEL,
  FETCH_NOTIFICATION_LIST,
  BOOKMARK_NOTIFICATION,
  HIDE_POPUP_ALERT,
  UNBOOKMARK_NOTIFICATION,
  DISCONNECT_FROM_SERVER,
} from "./action_types";
let SocketIO, importExportSocket;
const initialNotificationList = {
  docs: [],
  hasNextPage: true,
  hasPrevPage: false,
  limit: 20,
  nextPage: 0,
  offset: 0,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 0,
  totalPages: 1,
  fetched: false,
  type: "workflow",
};

const initialState = () => ({
  socket: null, // socket service
  connecting: false,
  disconnecting: false,
  connected: false,
  joinedRoomAppId: null,
  error: null,
  data: null,
  message: null,
  customActionButton: {},
  socketEventCache: {},
  customActionNotification: false,

  automatedTaskPopup: false,
  panel: false,
  bellUpdate: false,
  popupAlert: false,
  notification: null,
  notificationCounter: 0,
  // automatedTaskPopupTargetId: null,

  notifications: {
    info: initialNotificationList,
    loading: false,
    error: null,
  },
  bookmark: {
    notification: null,
    loading: false,
    error: null,
  },
  unBookmark: {
    notification: null,
    loading: false,
    error: null,
  },
  connectionId: null,
});

const getters = {
  getAllNotifications: (state) => state.notifications.info.docs,
  hasNextNotificationPage: (state) => state.notifications.info.hasNextPage,
  fetchingAllNotifications: (state) => state.notifications.loading,
  isInitialFethedNotifications: (state) => state.notifications.info.fetched,
  getNotificationsCurrentPage: (state) => state.notifications.info.page,

  bookmarkNotification: (state) => state.bookmark.notification,
  bookmarkingNotification: (state) => state.bookmark.loading,

  unBookmarkNotification: (state) => state.unBookmark.notification,
  unBookmarkingNotification: (state) => state.unBookmark.loading,

  notificationService: (state) => state.socket,
  isNotificationConnecting: (state) => state.connecting,
  isNotificationDisconnecting: (state) => state.disconnecting,
  isConnectedNotification: (state) => state.connected,
  connectionNotificationError: (state) => state.error,
  notificationData: (state) => state.data,
  isOpenNotificationPanel: (state) => state.panel,
  isOpenNotificationPopupAlert: (state) => state.popupAlert,

  socketConnectionId: (state) => state.connectionId,

  shouldShowAutomatedTaskPopup: (state) => state.automatedTaskPopup,

  shouldBellShow: (state) => state.bellUpdate,
  getNotificationInfo: (state) => state.notification,
  getNotificationCounter: (state) => state.notificationCounter,
  // getPopTargetSourceId: state => state.automatedTaskPopupTargetId,

  eventCustomActionButtonProgress: (state) => values(state.customActionButton),
  eventCustomActionButton: (state) => state.customActionButton,
  shouldShowAutomatedTaskPopupNotification: (state) =>
    state.customActionNotification,
};

const mutations = {
  requestFetchingAllNotifications(state, { type: nextType, offset = 0 }) {
    state.notifications.loading = true;
    const {
      info: { type = "" },
    } = state.notifications;
    if (offset < 1 || type !== nextType) {
      state.notifications.info = {
        ...initialNotificationList,
        type: nextType,
      };
    }
  },
  fetchedAllNotifications(state, { response: info, type }) {
    state.notifications.loading = false;
    let {
      info: { docs = [] },
    } = state.notifications;
    const { docs: nextList = [] } = info;
    docs = [...docs, ...nextList];
    state.notifications.info = {
      ...info,
      docs,
      type,
      fetched: true,
    };
    state.notificationCounter = info.notificationCounter || 0;
  },
  fetchAllNotificationsFailed(state, error) {
    state.notifications.loading = false;
    state.notifications.error = error;
  },

  requestBookmarkNotification(state) {
    state.bookmark.loading = true;
  },
  bookmarkedNotification(state, { response, index }) {
    state.bookmark.loading = false;
    state.bookmark.notification = info;
    const {
      info,
      info: { docs = [] },
    } = state.notifications;
    if (index >= 0 && index < docs.length) {
      docs[index].bookmark = true;
      docs[index].bookmarkAt = response.bookmarkAt;
      state.notifications.info = {
        ...info,
        docs,
      };
    }
  },
  bookmarkNotificationFailed(state, error) {
    state.bookmark.loading = false;
    state.bookmark.error = error;
  },

  requestUnBookmarkNotification(state) {
    state.unBookmark.loading = true;
  },
  unBookmarkedNotification(state, { response, index }) {
    state.unBookmark.loading = false;
    state.unBookmark.notification = info;
    const {
      info,
      info: { docs = [] },
    } = state.notifications;
    if (index >= 0 && index < docs.length) {
      docs[index].bookmark = false;
      docs[index].bookmarkAt = undefined;
      state.notifications.info = {
        ...info,
        docs,
      };
    }
  },
  unBookmarkNotificationFailed(state, error) {
    state.unBookmark.loading = false;
    state.unBookmark.error = error;
  },

  // requestFetchingAllNotifications(state) {
  //   state.notifications.loading = true;
  // },
  // fetchedAllNotifications(state, list) {
  //   state.notifications.loading = false;
  //   state.notifications.list = list;
  // },
  // fetchAllNotificationsFailed(state, error) {
  //   state.notifications.loading = false;
  //   state.notifications.error = error;
  // },

  gotData(state, message) {
    let data = null;
    try {
      data = JSON.parse(message);
    } catch (err) {}
    state.data = data;
  },
  sendMessage(state, data) {
    let message = null;
    try {
      message = JSON.stringify(data);
    } catch (err) {}
    state.message = message;
  },
  onCreating(state, id) {
    state.connected = false;

    state.connectionId = id;
    state.connecting = true;
    state.disconnecting = false;
    state.error = null;
  },
  onErrorCreate(state, error) {
    state.connecting = false;
    state.error = error;
  },
  onInitial(state) {
    const wasState = initialState();
    state.socket = wasState.socket;
    state.connected = wasState.connected;
    state.connecting = wasState.connecting;
    state.disconnecting = wasState.disconnecting;
    state.data = wasState.data;
    state.error = wasState.error;
    state.message = wasState.message;
  },
  onCreated(state, socket) {
    state.socket = socket;
    state.connected = true;
    state.connecting = false;
  },
  onRevoking(state) {
    state.disconnecting = true;
  },
  onRevoked(state) {
    state.connected = false;
    state.connecting = false;
    state.disconnecting = false;
    state.error = null;
  },

  onJoinAppRoom(state, appId) {
    state.joinedRoomAppId = appId;
  },
  onLeaveAppRoom(state, appId) {
    state.joinedRoomAppId = null;
  },
  onTogglePanel(state, status) {
    state.panel = status;
    if (!status) {
      state.notifications.info = {
        ...initialNotificationList,
      };
    }
  },
  /*
   ** Event store commit
   */
  onCustomActionButtonMessage(state, data) {
    const { id } = data;
    const { customActionButton } = state;
    customActionButton[id] = { ...data };
    state.customActionButton = { ...customActionButton };
  },
  offCustomActionButtonMessage(state, id) {
    let { customActionButton } = state;
    customActionButton = omit(customActionButton, id);
    state.customActionButton = { ...customActionButton };
  },
  onUpdateAutomatedTaskPopup(state, { show }) {
    state.automatedTaskPopup = show;
    // state.automatedTaskPopupTargetId = id;
  },
  onUpdateNotificationCustomAction(state, status) {
    state.customActionNotification = status;
  },

  onUpdateBellStatus(state, status) {
    state.bellUpdate = status;
  },
  onUpdatePopupAlertStatus(state, status) {
    state.popupAlert = status;
  },

  onUpdateBellContent(state, info) {
    const { id } = info;
    // TODO: keep config
    let { socketEventCache = {} } = state;
    if (id && !socketEventCache[id]) {
      state.notification = info;
      state.notificationCounter += 1;
      // always keep max 10 cache
      if (keys(socketEventCache).length > 9) {
        const cachedKeys = keys(socketEventCache).slice(0, 9);
        socketEventCache = pick(socketEventCache, cachedKeys);
      }
      // prevent twice push with the same id
      state.socketEventCache = { ...socketEventCache, [id]: true };
    }
    // console.log(state.socketEventCache);
  },

  onUpdateCounter(state, notificationCounter) {
    state.notificationCounter = notificationCounter;
  },
  onIncreaseCounter(state, increment) {
    state.notificationCounter = state.notificationCounter + increment;
  },
};

const socketEventAction = (commit) => {
  SocketIO.on(EVENT_CUSTOMACTIONBUTTONSHOWPROCESS, (data) => {
    commit("onCustomActionButtonMessage", data);
    commit("onUpdateNotificationCustomAction", true);
  });
  SocketIO.on(EVENT_WORKFLOWINSITEMESSAGE, (data) => {
    commit("onUpdateBellStatus", true);
    commit("onUpdatePopupAlertStatus", true);
    commit("onUpdateBellContent", data);
  });
};

const importSocketEventAction = (commit) => {
  importExportSocket.on("connect", () => {
    //console.log("importExportSocket", importExportSocket.id);
  });
  importExportSocket.on("ExportComplete", ({ url, id }) => {
    setTimeout(() => {
      app.$notify.close(id);

      app.$notify({
        group: "background-job",
        title: app.$i18n.t("buttons.export"),
        text: app.$i18n.t("messages.exportAvailable", { url }),
        id,
        duration: 5000,
        data: {
          url,
        },
      });
    }, 1000);

    commit("onUpdateBellStatus", true);
    commit("onIncreaseCounter", 1);
  });
  importExportSocket.on("ImportProgress", ({ progress }) => {
    Vue.$toast.success(
      app.$i18n.t("messages.importProgress", {
        progress: (progress * 100).toFixed(2),
      })
    );
  });
  importExportSocket.on("ImportComplete", ({ formName, appName }) => {
    Vue.$toast.success(
      app.$i18n.t("messages.importFinished", {
        formName,
        appName,
      })
    );
  });
};

const actions = {
  [CONNECT_TO_SERVER](
    { rootGetters, rootState, state, commit, dispatch },
    token,
    appId
  ) {
    commit("onInitial");
    return new Promise((done) => {
      try {
        const connectionId = v4();
        commit("onCreating", connectionId);
        const url = process.env.VUE_APP_SOCKET_APP_HOST;
        SocketIO = io(url, {
          transports: ["websocket"],
          query: {
            token,
            socketId: connectionId,
          },
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization: `Bearer ${token}`,
              },
            },
          },
          extraHeaders: { Authorization: `Bearer ${token}` },
          reconnection: true,
          withCredentials: true,
          allowEIO3: true,
        });
        // const manager = new Manager(url, {
        //   reconnectionDelayMax: 10000,
        //   transports: ['websocket'],
        //   query: {
        //     token,
        //   },
        //   extraHeaders: { 'Authorization': `Bearer ${token}` },
        //   reconnection: true,
        //   withCredentials: true,
        //   allowEIO3: true,
        // });
        // SocketIO = manager.socket("/");
        // SocketIO.io.on("error", (error) => {
        //   console.log(error);
        // });
        SocketIO.on("connect", function () {
          const { currentApp } = rootState.apps;
          // for the connection from the disconnection
          if (currentApp && !state.joinedRoomAppId) {
            dispatch(OUTBOUND_EVENT_JOINROOMS, currentApp._id);
          }
          setTimeout(() => {
            commit("onCreated", SocketIO);
          }, 3000);
        });
        SocketIO.on("disconnect", function () {
          commit("onRevoking");
          commit("onLeaveAppRoom");
          // trace the ui process
          setTimeout(() => {
            commit("onRevoked");
          }, 2000);
        });
        socketEventAction(commit);
        importExportSocket = io(process.env.VUE_APP_IMPORT_EXPORT_SOCKET_HOST, {
          transports: ["websocket"],
          query: {
            token,
          },
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization: `Bearer ${token}`,
              },
            },
          },
          extraHeaders: { Authorization: `Bearer ${token}` },
          reconnection: true,
          withCredentials: true,
          allowEIO3: true,
        });

        importSocketEventAction(commit);
      } catch (err) {
        console.error(err);
        commit("onErrorCreate", err.message);
      }
      done();
    });
  },
  [OUTBOUND_EVENT_JOINROOMS]({ commit }, appId) {
    commit("onJoinAppRoom", appId);
    SocketIO.emit(EVENT_JOINROOMS, { appId });
  },
  [OUTBOUND_EVENT_LEAVEROOMS]({ commit }, appId) {
    SocketIO.emit(EVENT_LEAVEROOMS, { appId });
  },
  [SHOW_AUTOMATETASK_POPUP]({ commit }, data) {
    commit("onUpdateAutomatedTaskPopup", data);
  },
  [SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION](
    { commit },
    status = false
  ) {
    commit("onUpdateNotificationCustomAction", status);
  },
  [HIDE_BELL]({ commit }, status = false) {
    commit("onUpdateBellStatus", status);
  },
  [HIDE_POPUP_ALERT]({ commit }, status = false) {
    commit("onUpdatePopupAlertStatus", status);
  },
  [UPDATE_COUNTER]({ commit }, counter) {
    commit("onUpdateCounter", counter);
  },
  [TOGGLE_PANEL]({ commit }, status) {
    commit("onTogglePanel", status);
  },

  // API
  [FETCH_NOTIFICATION_LIST]({ dispatch, commit }, { type, appId, ...data }) {
    commit("requestFetchingAllNotifications", { type, ...data });
    return new Promise((done, reject) => {
      const urlCommand = `/notification/list/${type}?appId=${appId}`;
      const options = {
        urlCommand,
        data,
      };
      dispatch("AUTH_POST", options)
        .then((response) => {
          commit("fetchedAllNotifications", { response, type });
          done(response);
        })
        .catch((err) => {
          console.log(err);
          commit("fetchAllNotificationsFailed", err.message);
          reject(err.message);
        });
    });
  },

  [BOOKMARK_NOTIFICATION](
    { dispatch, commit },
    { notificationId, appId, index }
  ) {
    commit("requestBookmarkNotification");
    const urlCommand = `/notification/bookmark/${notificationId}?appId=${appId}`;
    const options = {
      urlCommand,
    };
    dispatch("AUTH_POST", options)
      .then((response) => {
        commit("bookmarkedNotification", { response, index });
      })
      .catch((err) => {
        commit("bookmarkNotificationFailed", err.message);
      });
  },

  [UNBOOKMARK_NOTIFICATION](
    { dispatch, commit },
    { notificationId, appId, index }
  ) {
    commit("requestUnBookmarkNotification");
    const urlCommand = `/notification/bookmark/${notificationId}?appId=${appId}`;
    const options = {
      urlCommand,
    };
    dispatch("AUTH_DELETE", options)
      .then((response) => {
        commit("unBookmarkedNotification", { response, index });
      })
      .catch((err) => {
        commit("unBookmarkNotificationFailed", err.message);
      });
  },
  [DISCONNECT_FROM_SERVER]({ commit }) {
    commit("onInitial");
    SocketIO.disconnect();
    importExportSocket.disconnect()
  },
};

export default {
  state: initialState(),
  getters,
  mutations,
  actions,
};
