<template>
  <vxe-table-column
    v-bind="colDef"
    :edit-render="{ name: 'input', enabled: !readonly }"
    class-name="fullCell vxeCellYesNo"
  >
    <template v-slot="{ row }">
      <v-icon>{{
        row[fieldId] ? "mdi-check-box-outline" : "mdi-square-outline"
      }}</v-icon>
      <quick-action v-if="quickInsert" :rowId="row['_id']" :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{ row }">
      <v-checkbox 
      class="align-center"
        :disabled="readonly"
         v-model="row[fieldId]"
      />

      <!--<vxe-input type="text"-->
      <!--v-model="row[fieldId]"-->
      <!--@blur="updateVal(row)"/>-->
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import inputYesNo from "@/components/formComps/InputYesNo";
import quickAction from "./comps/QuickAction";
import summaryCell from './comps/SummaryCell'

export default {
  name: "vxeCellYesNo",
  mixins: [mixin],
  components: {
    inputYesNo,
    quickAction,
    summaryCell
  },
  methods: {
    updateVal(rowId, fieldId, val) {
      const vm = this;
      console.log("VxeCellYesNo :: updateVal :: rowId = " + rowId);
      console.log("VxeCellYesNo :: updateVal :: fieldId = " + fieldId);
      console.log("VxeCellYesNo :: updateVal :: val = " + val);

      // vm.$emit('input', val)
      vm.$emit("onCommand", {
        command: "updateFieldValue",
        rowId: rowId,
        fieldId: fieldId,
        fieldValue: val,
      });
      console.log("VxeCellYesNo :: after updateVal");
    },
  },
};
</script>

<style>
.vxeCellYesNo.col--actived .vxe-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  border: 1px solid #dcdfe6;
}
</style>
