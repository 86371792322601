<template>
  <div>
    <div v-if="isCompleteState(status)" class="workflow-execution-history-status-step-completed">
      <v-icon left color="white" small class="workflow-execution-history-status-step-icon workflow-execution-history-status-step-completed-icon">
        mdi-check-bold
      </v-icon>
      {{ $t(`workflow.status.${set}.complete`) }}
    </div>
    <div v-else-if="isFailState(status)" class="workflow-execution-history-status-failed">
      <v-icon left color="white" small class="workflow-execution-history-status-step-icon workflow-execution-history-status-step-failed-icon">
        mdi-close-thick
      </v-icon>
      {{ $t(`workflow.status.${set}.fail`) }}
    </div>
    <div v-else-if="isWaitingState(status)" class="workflow-execution-history-status-step-waiting">
      <v-icon left color="#666666" large>
        mdi-timelapse
      </v-icon>
      {{ $t(`workflow.status.${set}.waiting`) }}
    </div>
    <div v-else class="workflow-execution-history-status-running">
      <v-progress-circular size="25" indeterminate color="primary"></v-progress-circular>
      <div class="workflow-execution-history-status-running-label">{{ $t('workflow.label.runningProcess') }}</div>
    </div>
  </div>
</template>

<script>
import TableMixin from './mixin';
export default {
  mixins: [TableMixin],
  props: {
    status: String,
    set: {
      type: String,
      default: 'step',
    },
  },
};

</script>

<style>

</style>
