<template>
  <div class="wit-selectfieldcombination">
    <v-menu
      attach="#witSelectCombineFields"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <codemirror
            class="wit-selectfieldcombination-select"
            ref="cmEditor"
            v-model="propertyValue"
            :options="cmOptions"
            @ready="onCmReady"
            @focus="onCmFocus"
            @input="onCmCodeChange"
            
            style="border: 1px solid grey;"
            id="witSelectCombineFields"
          />
        </div>
      </template>
      <v-card outlined>
        <v-card-text class="pa-0">
          <v-list dense class="pa-0">
              <template v-for="(field, index) in filteredFields">
                <v-list-item class="white" active-class="white" dense :ripple="false" @click="handleListItemClick(field)" :key="field.fieldId" style="min-height: 30px">
                  <template v-slot:default="{ }">
                    <v-list-item-content>
                      <div>{{field.label}}</div>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < formFieldInfos.length - 1"
                  :key="index"
                ></v-divider>
              </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <div class="mt-2" v-if="selectedNumberFields.length">
      <div class="" style="font-size:14px; color: #00000080">{{$t('widgets.properties.fixedLengthSettings')}}</div>
      <div class="d-flex flex-column">
        <div v-for="(field,index) in selectedNumberFields" :key="field.fieldId" class="d-flex mt-1 align-end">
          <div class="flex-grow-1">
            <div style="font-size:13px" class="d-inline-block info white--text rounded-pill px-2">{{field.label}}</div>
          </div>
          <div style="width:50px;">
            <v-text-field style="font-size:14px" @input="updateFixedLength(field.fieldId,$event)" v-model="fixedLengthSettings[field.fieldId]" class="rounded-0" outlined dense hide-details height="25"></v-text-field>
            <!-- v-model="fixedLengthSettings[field.fieldId]" -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2" v-if="selectedDateFields.length">
      <div class="d-flex align-end" style="font-size:14px; color: #00000080">
        <div>
          {{$t('widgets.properties.dateFormatSettings')}}
        </div>
        <div>
          <DialogMomentFormat/>
        </div>
        
      </div>
      <div class="d-flex flex-column">
        <div v-for="(field,index) in selectedDateFields" :key="field.fieldId" class="d-flex mt-1 align-end">
          <div class="flex-grow-1">
            <div style="font-size:13px" class="d-inline-block info white--text rounded-pill px-2">{{field.label}}</div>
          </div>
          <div style="width:100px;">
            <v-text-field style="font-size:14px" @input="updateDateFormat(field.fieldId,$event)" v-model="dateFormatSettings[field.fieldId]" class="rounded-0" outlined dense hide-details height="25"></v-text-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/theme/base16-dark.css'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/search/searchcursor.js'
import DialogMomentFormat from './dialogField/DialogMomentFormat'

export default {
  mixins: [mixin],
  components:{
    DialogMomentFormat
  },
  data () {
    return {
      code: '',
      cmOptions: {
        tabSize: 4,
        mode: 'text/javascript',
        // theme: 'base16-dark',
        lineNumbers: false,
        line: true,
        lineWrapping: true,
      },
      typeExclude: ['attachments', 'signature', 'richText','_sectionLine',
        '_remark', 'childTable', 'yesNo', 'region'
      ],
      count: 0,
      bl_init: false,
      fixedLengthSettings: {},
      dateFormatSettings: {}
    }
  },
  computed: {
    codemirror() {
      return this.$refs.cmEditor.codemirror
    },
    selectedNumberFields(){
      var selected = this.filteredFields.filter(item=> {
        return this.propertyValue.includes(item.fieldId)
      })
      if(this.bl_init){
        this.fixedLengthSettings = {}
        selected.forEach(field=>{
          if(typeof this.fieldInfo.properties.fixedLengthSettings == 'object')
            if(this.fieldInfo.properties.fixedLengthSettings[field.fieldId])
              this.fixedLengthSettings[field.fieldId] = this.fieldInfo.properties.fixedLengthSettings[field.fieldId]
            else 
              this.fixedLengthSettings[field.fieldId] = ""
          else this.fixedLengthSettings[field.fieldId] = ""
        })
      }

      return selected.filter(item=>{
        return ['number', 'amount', 'summaryField'].includes(item.type) ||( item.type === 'expression' && item.properties.expressionType !=="date")
      })
    },
    selectedDateFields(){
      var selected = this.filteredFields.filter(item=> {
        return this.propertyValue.includes(item.fieldId)
      })
      if(this.bl_init){
        this.dateFormatSettings = {}
        selected.forEach(field=>{
          if(typeof this.fieldInfo.properties.dateFormatSettings == 'object')
            if(this.fieldInfo.properties.dateFormatSettings[field.fieldId])
              this.dateFormatSettings[field.fieldId] = this.fieldInfo.properties.dateFormatSettings[field.fieldId]
            else 
              this.dateFormatSettings[field.fieldId] = ""
          else this.dateFormatSettings[field.fieldId] = ""
        })
      }

      return selected.filter(item=>{
        return ['date', 'createdAt', 'updatedAt'].includes(item.type) || ( item.type === 'expression' && item.properties.expressionType ==="date")
      })

    },
    filteredFields(){
      let vm = this
      var res = this.formFieldInfos.filter(item=>!vm.typeExclude.includes(item.type) && item.fieldId !== this.fieldInfoId)
      return (res)? res:[]
    }
  },
  methods: {
    updateFixedLength(length, e){
      this.fieldInfo.properties.fixedLengthSettings = JSON.parse(JSON.stringify(this.fixedLengthSettings))
    },
    updateDateFormat(length, e){
      this.fieldInfo.properties.dateFormatSettings = JSON.parse(JSON.stringify(this.dateFormatSettings))
    },
    onCmReady(){
      this.codemirror.setValue(this.propertyValue)
      this.CodeMapping()
    },
    addWidget(id, name, bl_replace = 1){
      console.log({
        id, name
      })
      this.count++
      var cm = this.$refs.cmEditor.codemirror
      var cursor = cm.getCursor();

      var lineNumber = cursor.line;
      var charNumber = cursor.ch;
      // var snippet = "[[tag" + this.count + "]]"
      var snippet = "${" + id + "}"
      if(bl_replace)
        cm.replaceRange(snippet, {line:lineNumber, ch: charNumber},{line:lineNumber, ch: charNumber})
      if(bl_replace){
        const div = document.createElement('div',);
        div.innerHTML = name
        div.className = "rounded-pill info white--text pa-2 py-0 d-inline-block"
        cm.markText({line: lineNumber,ch: charNumber}, {line: lineNumber,ch: charNumber + snippet.length}, {
          replacedWith: div
        })
      }else{
          this.bl_init = true
          var cursor = cm.getSearchCursor(snippet);
          var first, from, to;
          while (cursor.findNext()) {
            const div = document.createElement("div");
            div.innerHTML = name;
            div.className =
              "rounded-pill info white--text pa-2 py-0 d-inline-block";
            from = cursor.from();
            to = cursor.to();
            cm.markText(from, to, {
              replacedWith: div,
            });
            if (first === undefined) {
              first = from;
            }
          }
      }
      // cm.setBookmark({line: lineNumber,ch: charNumber}, div)
    },
    handleListItemClick(field){
      this.addWidget(field.fieldId, field.label)
    },
    CodeMapping(){
      let vm = this
      var idArr = this.filteredFields.forEach(item=> {
        if(vm.propertyValue.includes(item.fieldId)){
          vm.addWidget(item.fieldId, item.label,0)
        }
      })
    },
  },
}
</script>

<style>
  .wit-selectfieldcombination-select .CodeMirror-wrap{
    height: 120px !important;
  }
  .wit-selectfieldcombination .v-btn--icon.v-size--default{
    height: unset !important;
    width: unset !important;
  }
  .CodeMirror-widget > div{
    margin-top:4px !important;
  }
</style>
