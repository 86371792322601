<template>
  <div>
    <NodeRemoteAsk
      :type="isNode ? 'removeNode' : 'removeBranch'" 
      @confirm="confirmRemove"
      @cancel="cancelRemove"
      v-if="!!askRemove"
    />
    <EditableNodeRemark
      @onRemarked="onRemarked"
      @offRemark="offRemark"
      :remark="node.remark"
      :remarking="remarking"
    />
    <v-layout align-center justify-space-between class="workflow-node-item-tools">
      <div class="workflow-node-item-edit" v-if="!node.dependsNode">
        <NodeEditIcon :node="node" :isNode="isNode" />
      </div>
      <div class="workflow-node-item-notes">
        <NodeRemarkIcon
          @onRemark="onRemark"
          :remark="node.remark"
          :isNode="isNode"
        />
      </div>
      <div class="workflow-node-item-operation" v-if="!node.dependsNode && !isCondition">
        <NodeColorPicker
          @onColor="onColor"
          :color="node.color"
          :isNode="isNode"
        />
      </div>
      <div class="workflow-node-item-operation">
        <NodeControlMenu
          @editName="onEdit"
          @updateRemark="onRemark"
          @remove="beforeRemove"
          @copy="$emit('copy')"
          :isNode="isNode"
          :canRename="!node.dependsNode"
          :canEdit="!node.dependsNode"
          :canCopy="!!node.parent && !node.dependsNode"
          :canRemove="!!node.parent"
        />
      </div>
    </v-layout>
  </div>
</template>

<script>
import EditableNodeRemark from './../../control/editableRemark/nodeRemark';
import NodeEditIcon from './../../control/nodeEdit';
import NodeRemoteAsk from './../../control/nodeRemote/';
import NodeColorPicker from './../../control/nodeColor/';
import NodeControlMenu from './../../control/nodeControlMenu';
import NodeRemarkIcon from './../../control/editableRemark/remarkIcon';
// mixins
// import EditingMixin from './../../control/editableName/editableMixin';
import ToggleMixin from './../../control/nodeRemote/toggleMixin';
import RemarkingMixin from './../../control/editableRemark/editableMixin';
import ColorPickerMixin from './../../control/nodeColor/editableMixin';
import NodeMixin from './../mixin';
import NodeUtils from './../../../../utils/node';
import WMixin from "@/pages/admin/workflow/mixin";
/*
* Single node copy overlay
* Terry Chan
* 26/05/2021
*/
export default {
  mixins: [NodeMixin, ToggleMixin, RemarkingMixin, ColorPickerMixin, WMixin],
  props: {
    node: Object,
    offset: Number,
    isNode: {
      type: Boolean,
      default: true,
    },
    onEdit: Function,
    // copy: Function,
  },
  computed: {  
    isCondition() {
      return NodeUtils.isBranch(this.node.nodeType);
    },
  },
  components: {
    NodeEditIcon,
    NodeControlMenu,
    NodeRemarkIcon,
    EditableNodeRemark,
    NodeRemoteAsk,
    NodeColorPicker,
  },
};

</script>

<style scoped>
  
</style>
