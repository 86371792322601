<template>
  <div>
    <Drop
      class="workflow-node-box-drop"
      @dragenter="onDropping"
      @drop="onDroped"
      @dragleave="onDropLeft"
      :accepts-data="() => !myNextIsDependsOn({ tree: parent, node: parent[offset], offset })"
    >
      <div class="workflow-node-branch">
        <v-tooltip bottom v-if="!parent[offset].dependsNode">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              class="workflow-node-branch-btn"
              :disabled="copying || $store.getters.isUIBlocked"
              @click="$emit('insertCondition', { node: parent[offset], offset })"
            >
              <v-icon>
                mdi-plus-circle
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t('workflow.message.addConditional') }}
          </span>
        </v-tooltip>
        <div class="workflow-node-root">
          <div class="workflow-node-col" v-for="(n, index) in node" :key="n._id">
            <TreeNode
              :node="n"
              :isNode="false"
              :noIcon="true"
              :isYourChildDependency="myNextIsDependsOn({ tree: node, node: n, offset: index })"
              :tree="tree"
              :walkingNode="walkingNode"
              :offset="index"
              :lastOffset="node.length - 1"
              @insert="askForNode"
              @drop="insertNode"
              @remove="removeNode"
              @clone="cloneNode"
            />
            <workflow-tree :walkingNode="walkingNode" :tree="tree" :node="n.children" :parent="parent" />
          </div>
        </div>
        <!-- Sub Tree for the condition branch -->
        <!-- <workflow-tree :node="node" /> -->
      </div>
      <div class="workflow-node-branch-line-btn" :style="readytoDropAreaStyle()">
        <div v-if="!!dropTarget" class="workflow-node-drag-hints bottom">
          {{ $t('workflow.label.drapAndDrop') }}
        </div>
        <NodeFooter
          :dropTarget="dropTarget"
          :picking="isAtPicking"
          :showAddNode="true"
          :fromGroup="true"
          :copying="copying"
          @insert="$emit('insert', { node: parent[offset], offset })"
        />
      </div>
    </Drop>
    <MergeBox
      :show="true"
      :info="mergeInfo"
      v-if="!!mergeInfo"
      @close="closeMerge"
      @merge="confirmMerge"
    />
  </div>
</template>

<script>
/*
* Fork node holds at least two branch nodes
* Terry Chan
* 26/05/2021
*/
import TreeNode from './basic';
// import PreviewNode from './preview/';
import { Drop } from "vue-easy-dnd";
import DropMixin from './drop/mixin';
import MergeBox from './mergeToBranch';
// import CloneNode from './clone';
import NodeFooter from './section/footer';
import {
  PICKING_MODULE,
  PICK_MODULE,
  SET_MODULE_MENU_STATUS,
} from '@/store/modules/workflow/action_types';
import NodeUtils from './../../../utils/node';
import WMixin from "@/pages/admin/workflow/mixin";
import NodeMixin from './nodeMixin';

export default {
  mixins: [DropMixin, NodeMixin, WMixin],
  name: 'tree-group',
  props: {
    offset: Number,
    node: Array,
    walkingNode: Object,
    forkNode: Object,
    insertCondition: Function,
    parent: Array,
    tree: Object,
  },
  components: {
    TreeNode,
    Drop,
    NodeFooter,
    MergeBox,
  },
  beforeCreate() {
    this.$options.components.WorkflowTree = require('./../').default;
  },
  methods: {
    isAtPicking() {
      const { waitForPicking, forkNode } = this;
      return waitForPicking && forkNode && waitForPicking.id === forkNode.id;
    },
    askForNode (payload) {
      // this.addingNode = payload;
      this.$store.dispatch(PICKING_MODULE, {
        ...payload,
        tree: this.parent[payload.offset],
      });
      this.$store.dispatch(SET_MODULE_MENU_STATUS, true);
    },
    myNextIsDependsOn({ remark, tree, node, offset }) {
      // console.log(remark, NodeUtils.nextHasResultCondition(tree, node, offset))
      return NodeUtils.nextHasResultCondition(tree, node, offset, remark);
    },
    insertNode ({ node, offset }, config) {
      // ask for merge if it is a new forking node
      const tree = this.node[offset].children;
      if (!NodeUtils.rejectRePosition(node, config) &&
        !config._REPOS_
      ) {

        const askMerge = this.node.length - 1 > offset;
        const askDependsOn = NodeUtils.hasResultCondition(node.type);
        if (NodeUtils.needToMergeToBranch({ newNode: config, node, tree, offset })) {
          this.mergeInfo = { tree, node, offset, config, askMerge, askDependsOn };
        } else {
          // direct execute the insert behavior
          this.executePreInsertNode({ tree, node, offset, config });
        }
      } else {
        // this.tree.insertNode(tree, { ...config.node }, offset, false, false);
        // this.tree.remove(config.node.tree, config.node, config.offset);
      }
    },
    onDroped({ data }) {
      const { offset } = this;
      this.dropTarget = null;
      this.$emit('drop', { node: this.parent[offset], offset }, data);
    },
  },
  computed: {
    modulePicked() {
      return this.$store.state.workflowModules.module;
    },
    waitForPicking() {
      return this.$store.getters.pickingFromNode;
      // return this.$store.state.workflowModules.pickingModule;
    },
    waitForPickingInfo() {
      return this.$store.state.workflowModules.pickingModule;
    },
    copying() {
      return this.$store.state.workflowModules.copyingNode;
    },
  },
  watch: {
    // hook for the module selected
    modulePicked: {
      handler(module) {
        // if the current node is waiting for picking the target module
        if (!!module && this.isAtPicking()) {
          const { offset } = this.waitForPickingInfo;
          this.$emit('drop', { node: this.parent[offset], offset }, module)
          this.$store.dispatch(PICKING_MODULE, null);
          this.$store.dispatch(PICK_MODULE, null);
        } 
      },
      deep: true,
    },
  }
};

</script>

<style scoped>
  
</style>
