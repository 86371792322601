<template>
  <div class="fs-text text-no-wrap overflow-hidden">
    <label v-if="useLabel"
           class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    {{ titleValue }}
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsText',
  mixins: [mixin],
  computed: {
    titleValue () {
      const vm = this
      var result = vm.UNSPECIFIED
      if (vm.dataValue !== null && vm.dataValue !== '') {
        result = vm.dataValue + ' ' + vm.$t('general.star')
      }
      return result
    }
  }
}
</script>
