import * as types from "./action_types";

const state = {
  loaded: false,
  widgetGroups: [],
};

const getters = {
  widgetGroupsLoaded: (state) => {
    return state.loaded;
  },
  widgetGroups: (state) => {
    return state.widgetGroups;
  },
  allWidgets: (state) => {
    var result = [];
    for (let i = 0; i < state.widgetGroups.length; i++) {
      const loopGroup = state.widgetGroups[i];
      for (let j = 0; j < loopGroup.widgets.length; j++) {
       result.push(loopGroup.widgets[j]);
      }
    }
    return result;
  },
  widgetMap: (state) => {
    var result = {};

    if (state.widgetGroups) {
      for (var i = 0; i < state.widgetGroups.length; i++) {
        const widgetGroup = state.widgetGroups[i];
        for (var j = 0; j < widgetGroup.widgets.length; j++) {
          const loopWidget = widgetGroup.widgets[j];
          result[loopWidget._id] = loopWidget;
        }
      }
    }
    return result;
  },
  widgetIconMap: (state) => {
    let result = {};
    if (Array.isArray(state.widgetGroups)) {
      state.widgetGroups.forEach((group) => {
        const obj = group.widgets.reduce((acc, currentValue) => {
          acc[currentValue.key] = currentValue.icon;
          return acc;
        }, {});
        Object.assign(result, { ...obj });
      });
    }
    return result;
  },
  exportableWidgets: (state, getters) => {
    return getters.allWidgets
      .filter((widget) => widget.exportable)
      .map((widget) => widget.key);
  },
  publicQueryWidgetTypes: (_, getters) => {
    return getters.allWidgets
      .filter((widget) => widget.isPublicQuery)
      .map((widget) => widget.key);
  },
  openApiWidgetTypes: (_, getters) => {
    return getters.allWidgets
      .filter(widget=> !widget.readonly && widget.allowApiAdd)
      .map(widget=>widget.key)
  },
  titleFieldWidgetTypes: (_, getters) => {
    return getters.allWidgets.filter(widget=> widget.title === true && !widget.isSystem).map(widget=>widget.key);
  }
};

const mutations = {
  setWidgetGroups(state, payload) {
    state.widgetGroups = payload;
    state.loaded = true;
  },
};

const actions = {
  [types.GET_WIDGET]({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const widgetId = payload;
      const widgetGroups = rootGetters.widgetGroups;
      for (var i = 0; i < widgetGroups.length; i++) {
        const loopWidgetGroup = widgetGroups[i];
        for (var j = 0; j < loopWidgetGroup.widgets.length; j++) {
          const loopWidget = loopWidgetGroup.widgets[j];
          if (loopWidget._id === widgetId) {
            resolve(loopWidget);
          }
        }
      }
    });
  },

  [types.FETCH_WIDGET_GROUPS]({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: "/widget_groups",
        // ,
        // param: {
        //   appId: payload.appId
        // }
      };
      dispatch("AUTH_GET", getData).then((response) => {
        // console.log('FETCH_WIDGET_GROUPS: response: ', response)
        commit("setWidgetGroups", response);
        resolve(response);
      });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
