<template>    
<div class="d-flex flex-row justify-start align-center parent-child-settings-title"
    :class="{'fixed':!deletable}">
    <div class="level-no py-0">
        <small v-if="levelType==='currentTable'">
        {{ $t('levelDiagram.thisTableRelation')}}
        </small>
        <small v-else>
        {{ $t('levelDiagram.leveln', {levelNo: levelNo}) }}
        </small>
    </div>
    <v-chip pill :ripple="false" class="border pr-2">
        <font-awesome-icon :icon="icon" class="fa-fw mr-1"></font-awesome-icon>
        {{ fieldName }}
        <v-chip class="ml-2" small color="warning">
        ({{ formLabelString }})
        </v-chip>
     <v-btn v-if="deletable"
        color="error"
        class="ml-2 pa-1"
        fab
        max-width="24"
        max-height="24"
        x-small
        @click.stop="removeItem(item)">
        <v-icon small>mdi-delete</v-icon>
    </v-btn>
   </v-chip>
</div>
</template>

<script>
export default {
    name: 'parentChildSettingsTitle',
    props: {
        levelType: String,
        levelNo: Number,
        item: Object,
        outlined: Boolean,
        icon: {
            type: String,
            default: 'compress-alt'
        },
        deletable: Boolean
    },
    computed: {
        // for self info, fieldName showing form name,
        // and form label showing (this table)
        fieldName () {
            return this.item.fieldId==='' ?
                this.item.relatedFormName :
                this.item.fieldLabel
        },
        formLabelString () {
            const vm = this
            var result = vm.$t('general.thisTable')
            if (vm.item.fieldId !== '') {
                result = vm.item.relatedFormName
            }
            return result
        }
    },
    methods: {
        removeItem (item) {
            console.log('removItem :: item: ', item)
            this.$emit('onDelete', item)
        }
    }
}
</script>

<style>
.parent-child-settings-title .level-no {
    width: 80px;
}
.v-expansion-panel-header .parent-child-settings-title:not(.fixed) .v-chip--pill {
     background-color: white !important;
}
 .v-expansion-panel .parent-child-settings-title .level-no {
     color: grey;
 }
 .v-expansion-panel--active .parent-child-settings-title .level-no {
     color: white !important;
 }

 .v-expansion-panel--active .parent-child-settings-title button {
    height: 24px !important;
    max-height: 24px !important;
    min-height: 24px !important;
}
</style>
