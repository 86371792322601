<template>
  <v-select
    :items="filteredItems"
    :item-text="itemText"
    v-model="inputValue"
    hide-details
    :height="!multiple ? '34px' : ''"
    dense
    background-color="white"
    :outlined="!readonly"
    :small-chips="smallChips"
    :deletable-chips="smallChips"
    :clearable="clearable"
    :multiple="multiple"
    :readonly="readonly"
    :disabled="readonly"
    clear-icon="mdi-close"
    menu-props="offset-y"
    item-value="iso2"
  >
    <template v-slot:prepend-item>
      <v-text-field
        class="px-2 vxe-table--ignore-clear"
        hide-details
        dense
        autofocus
        flat
        solo
        :placeholder="$t('phone.searchHere')"
        v-model="searchText"
      />
      <v-divider />
      <!-- <v-list dense v-if="commonCountryCodes.length > 0">
        <v-list-item-group :value="inputValue" color="primary">
        <v-list-item dense v-for="item in commonCountryCodes" :key="item.iso2" link @click="inputValue=item.iso2" :input-value="inputValue === item.iso2">
          <div class="w-100" >
        <span class="countryName nowrap-ellipsis">{{ item.name }}</span>
        <span class="ml-2 dialCode">+{{ item.dialCode }}</span>
      </div>
        </v-list-item>
        </v-list-item-group>
      </v-list> -->
      <v-divider />
    </template>
    <template v-slot:item="{ item }">
      <div class="vxe-table--ignore-clear w-100" style="display: contents">
        <span class="countryName nowrap-ellipsis" style="width: 250px">{{
          item.name
        }}</span>
        <v-spacer />
        <span class="ml-2 dialCode">+{{ item.dialCode }}</span>
      </div>
    </template>
  </v-select>
</template>
<script>
export default {
  props: {
    items: Array,
    clearable: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    itemText: String,
    value: [String, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    commonCountryCodes: {
      type: Array,
      default: () => [],
    },
    readonly: Boolean,
  },
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    filteredItems() {
      const searchText = this.searchText.toLocaleLowerCase();
      return searchText
        ? this.options.filter((option) => {
            return (
              option.divider ||
              (
                (Array.isArray(this.value) && this.value) || [this.value]
              ).includes(option.iso2) ||
              option.name.toLocaleLowerCase().indexOf(searchText) > -1 ||
              option.dialCode.indexOf(searchText) > -1
            );
          })
        : this.options;
    },
    options() {
      let result = this.items;

      if (this.commonCountryCodes.length > 0) {
        result = this.commonCountryCodes
          .concat({ divider: true })
          .concat(result);
      }
      return result;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
