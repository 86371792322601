const mixin = {
  data () {
    return {
      SYSTEM_FIELD_IDS: [
        'ownedBy',
        'createdBy',
        'updatedAt',
        'createdAt'
      ],
      loading: false,

      rows: [],
      pagination: {
        currentPage: 1,
        pageSize: 50
      },
      startOfData: true,
      endOfData: false,
      
      masterFieldIds: [],
      targetFieldIds: []
    }
  },

  watch: {
    inputPanel () {
      this.refresh()
    }
  },

  props: {
    inputPanel: Object,
    masterForm: Object,
    targetForm: Object,
    userInput: String,
    criteria: Object
  },

  computed: {
    masterFormFieldInfos () {
      return this.masterForm ? this.masterForm.fieldInfos : []
    },
    targetFieldInfo () {
      const vm = this
      var result = null
      if (vm.targetFormFieldInfos) {
        result = vm.targetFormFieldInfos.find(info => info.fieldId === vm.inputPanel.targetFieldId)
      }
      return result
    },
    targetFormFieldInfos () {
      return this.targetForm ? this.targetForm.fieldInfos: []
    },
    masterFormTitleFieldInfo () {
      const vm = this
      var result = null
      if (vm.masterForm && vm.masterForm.titleFieldInfoId && vm.masterFormFieldInfos.length > 0) {
        result = vm.masterFormFieldInfos.find(info => info._id === vm.masterForm.titleFieldInfoId)        
      }
      return result
    },
    masterFormTitleFieldId () {
      return this.masterFormTitleFieldInfo ? this.masterFormTitleFieldInfo.fieldId : ''
    },
    displayConfig () {
      const vm = this
      return {
        masterFieldInfos: vm.masterForm ? vm.masterForm.fieldInfos : [],
        targetFieldInfos: vm.targetForm ? vm.targetForm.fieldInfos : [],
        titleFieldId: vm.getTitleFieldId(vm.masterForm),
        masterFieldIds: vm.getDisplayMasterFieldIds(),
        timeSegments: vm.inputPanel.timeSegments
      }
    },
    fieldIdToTimeRangeMap () {
      const vm = this
      var result = {}
      if (vm.inputPanel && vm.inputPanel.timeSegments) {
        for (let i = 0; i < vm.inputPanel.timeSegments.length; i++) {
          const loopTimeSegment = vm.inputPanel.timeSegments[i]
          for (let j = 0; j < loopTimeSegment.actions.length; j++) {
            const loopAction = loopTimeSegment.actions[j]
            if (loopAction.fieldId) {
              result[loopAction.fieldId] = {
                type: loopAction.dataType,
                startTime: loopTimeSegment.startTime,
                endTime: loopTimeSegment.endTime
              }
            }
          }
        }
      }
      return result
    },
    dateTimeFields () {
      return Object.keys(this.fieldIdToTimeRangeMap)
    }
  },

  mounted () {
    this.refresh()
  },

  methods: {
    editAttendanceInfo(row, index) {
      const vm = this
      console.log('editAttendanceInfo : row (index=' + index + '): ', row)
      var targetRecord = null
      var targetRecordId = ''
      var value = ''
      switch (vm.inputPanel.displayType) {
        case 'cells':
          if (row.targetRecords && index < row.targetRecords.length) {
            targetRecord = row.targetRecords[index]
          }
          break
            targetRecordId = targetRecord._id
        case 'column':
          targetRecord = row
          break
      }
      if (targetRecord) {
        targetRecordId = targetRecord._id
        value = targetRecord[vm.targetFieldInfo.fieldId]
      }
      // console.log('editAttendanceInfo :: targetRecord: ', targetRecord)
      // var value = targetRecord[this.inputPanel.targetFieldId]
      // switch (this.targetFieldInfo.properties.fieldType) {
      //   case 'date':
      //     value += ' 00:00'
      //     break
      //   case 'time':
      //     const createdAt = targetRecord['createdAt']
      //     value = this.formatDateTime(createdAt, 'YYYY-MM-DD') + ' ' + value
      //     break
      // }

      if (value) {
        const payload = {
          masterRecordId: row._id,
          targetRecordId: targetRecord ? targetRecord._id : '',
          targetFieldInfo: vm.targetFieldInfo,
          value: value
        }
        vm.$refs.attendanceInfoDialog.open(
          payload,
          vm.onAttendanceUpdated)
      }
    },
    onAttendanceUpdated(payload) {
      const vm = this
      if (payload.targetRecordId === '') {
        vm.addAttendanceEntry(payload)
      } else {
        vm.updateAttendanceEntry(payload)
      }
    },
    addAttendanceEntry(payload) {
      const vm = this
      console.log('addAttendanceEntry: payload: ', payload)
      const params = {
        inputPanelId: vm.inputPanel._id,
        masterRecordId: payload.masterRecordId,
        date: vm.criteria.activeDate,
        amPmFilter: vm.criteria.amPmFilter,
        value: new Date(payload.value)
      }
      vm.$store.dispatch('INPUT_PANEL_ADD_ENTRY', params).then(
        response => {
          console.log('INPUT_PANEL_ADD_ENTRY : response: ', response)
          const updatedRows = response.result
          vm.updateRecord(updatedRows)
        }
      )
    },
    updateAttendanceEntry(payload) {
      const vm = this
      console.log('updateAttendanceEntry :: payload: ', payload)
      const params = {
        inputPanelId: vm.inputPanel._id,
        masterRecordId: payload.masterRecordId,
        targetRecordId: payload.targetRecordId,
        date: vm.criteria.activeDate,
        amPmFilter: vm.criteria.amPmFilter,
        value: new Date(payload.value)
      }
      console.log('updateAttendanceEntry : params: ', params)
      vm.$store.dispatch('INPUT_PANEL_UPDATE_ENTRY', params).then(
        response => {
          const updatedRows = response.result
          console.log('updateAttendanceEntry :: updatedrows: ', updatedRows)
          switch (vm.inputPanel.displayType) {
            case 'cells':
              vm.updateRecord(updatedRows)
              break
            case 'column':
              vm.refresh()
              break
          }
        }
      )
    },

    addAttendanceInfo(row) {
      const payload = {
        masterRecordId: row._id,
        targetRecordId: '',
        targetFieldInfo: this.targetFieldInfo,
        value: this.formatDateTime(new Date(), 'YYYY-MM-DD HH:mm')
      }
      console.log('addAttendanceInfo : payload: ', payload)
      this.$refs.attendanceInfoDialog.open(
        payload,
        this.onAttendanceUpdated)
    },
    refresh () {
      const vm = this

      console.log('InputPanelDataMixin :: inputPanel.displayType = ' + vm.inputPanel.displayType)
      this.startOfData = true
      this.pagination.currentPage = 0
      this.endOfData = false
      this.loadMore()
    },

    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'deleteTimeItem':
          vm.clearTargetRecordField(payload)
          break
      }
    },

    clearTargetRecordField (payload) {
      const vm = this;
      console.log('clearTargetRecordField: payload: ', payload)
      vm.$refs.confirmDeleteDialog.confirm(() => {
        const masterRecordId = payload.masterRecordId
        const targetRecordId = payload.targetRecordId
        const targetFieldId = payload.targetFieldId

        if (vm.inputPanel.displayType === 'cells') {
          const masterRecord = vm.rows.find(row => row._id === masterRecordId)
          const targetRecordIndex = masterRecord.targetRecords.findIndex(record => record._id === targetRecordId)
          const targetRecord = masterRecord.targetRecords[targetRecordIndex]
          
          targetRecord[targetFieldId] = ''

          console.log('clearTargetRecordField => deleteTargetRecord: masterRecord: ', masterRecord)
          console.log('clearTargetRecordField => deleteTargetRecord: masterRecordId: ', masterRecordId)
          console.log('clearTargetRecordField => deleteTargetRecord: targetRecordId: ', targetRecordId)
          console.log('clearTargetRecordField => deleteTargetRecord: targetRecordIndex: ', targetRecordIndex)
          vm.deleteTargetRecord(masterRecordId, targetRecordId, () => {
            masterRecord.targetRecords.splice(targetRecordIndex, 1)
          })

          // if (vm.isAllActionFieldsEmpty(targetRecord)) {
          //   console.log('isAllActionFieldsEmpty')
          //   vm.deleteTargetRecord(masterRecordId, targetRecordId, () => {
          //     masterRecord.targetRecords.splice(targetRecordIndex, 1)
          //   })
          // } else {
          //   console.log('not llActionFieldsEmpty')
          //   vm.updateTargetRecord(targetRecordId, targetFieldId, '')
          // }
          vm.$emit('onCommand', {command: 'setFocus'})
        } else {
          const targetRecord = vm.rows.find(row => row._id === targetRecordId)
          const masterRecord = targetRecord.masterRecord
          const masterRecordId = masterRecord._id

          console.log('clearTargetRecordField :: payload: ', payload)
          vm.deleteTargetRecord(masterRecordId, targetRecordId, () => {
            const targetIndex = vm.rows.findIndex(row => row._id === targetRecordId)
            vm.rows.splice(targetIndex, 1)  
          })
        }
      })
    },

    isAllActionFieldsEmpty (record) {
      const vm = this
      var allActionFieldIds = []
      for (let i = 0; i < vm.inputPanel.timeSegments.length; i++) {
        const loopSegment = vm.inputPanel.timeSegments[i]
        const actionFieldIds = loopSegment.actions.map(action => action.fieldId)
        allActionFieldIds = allActionFieldIds.concat(actionFieldIds)
      }
      console.log('allActionFieldIds: ', allActionFieldIds)
      var result = true
      for (let j = 0; j < allActionFieldIds.length; j++) {
        const loopFieldId = allActionFieldIds[j]
        if (record[loopFieldId] === null ||record[loopFieldId] === '') {
          result = false
          break
        }
      }
      return result
    },

    deleteTargetRecord (masterRecordId, targetRecordId, callback) {
      const vm = this
      const params = {
        id: masterRecordId,
        formId: vm.masterForm._id,
        fieldId: vm.inputPanel.masterFieldForTarget,
        relatedFormId: vm.targetForm._id,
        relatedRecordIds: [targetRecordId]
      }
      console.log("deleteTargetRecord: params: ", params);
      vm.$store.dispatch("DELETE_RELATED_RECORDS", params).then((response) => {
        if (typeof callback === "function") {
          callback();
        }
      });
    },

    getTitleFieldId (form) {
      const vm = this
      var result = ''
      if (form && form.fieldInfos) {
        const fieldInfo = form.fieldInfos.find(info => info._id === form.titleFieldInfoId)
        if (fieldInfo) {
          result = fieldInfo.fieldId
        }
      }
      return result
    },

    getDisplayMasterFieldIds () {
      const vm = this
      var result = []
      console.log('vm.masterForm: ', vm.masterForm)
      if (vm.masterForm && vm.masterForm.fieldInfos) {
        result = vm.masterForm.fieldInfos.map(info => {
          var result = false
          if (!vm.SYSTEM_FIELD_IDS.includes(info.fieldId)) {
            result = true
          }
        })
      }
      if (vm.inputPanel.displayConfig && vm.inputPanel.displayConfig.masterFieldIds) {
        result = vm.inputPanel.displayConfig.masterFieldIds
      }
      return result
    },
  }

}

export default mixin
