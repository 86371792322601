var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',{staticClass:"py-0 pl-3 pr-1 pt-1 white form-title-wrapper",staticStyle:{"z-index":"1","height":"62px"}},[(_vm.isTitleString)?_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.title))]):_c('span',{staticClass:"mr-3"},[_c('div',{staticClass:"d-flex flex-row align-center"},_vm._l((_vm.title),function(titleInfo,index){return _c('div',{key:index,staticClass:"line-height-1 my-1 d-flex flex-row align-center"},[(index > 0)?_c('v-icon',[_vm._v("mdi-share")]):_vm._e(),(titleInfo.fieldName && titleInfo.fieldName !== '')?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h5',{class:{ 'primary--text': index === _vm.title.length - 1 }},[_vm._v(" "+_vm._s(titleInfo.fieldName)+" ")]),_c('v-chip',{attrs:{"x-small":"","color":index === _vm.title.length - 1 ? 'primary' : 'grey white--text'}},[_vm._v(_vm._s(titleInfo.formLabel))])],1):_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_vm._v(" "+_vm._s(titleInfo.formLabel)+" ")])],1)}),0)]),((_vm.isAllowEditForm || _vm.isAdmin) && _vm.builderButton)?_c('v-btn',{staticClass:"mr-2 d-flex flex-column justify-center success",staticStyle:{"height":"38px","border-radius":"4px"},attrs:{"to":{
      name: _vm.builderRouteName,
      params: { id: _vm.$route.params.id, itemId: _vm.$route.params.itemId },
    },"ripple":false,"text":"","x-small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","size":"16"}},[_vm._v("mdi-pencil")]),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.$t('buttons.formEditor')))])],1):_vm._e(),_vm._t("append-title",function(){return [(_vm.buttons.includes('new'))?_c('v-menu',{attrs:{"offset-y":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',{staticClass:"d-flex",attrs:{"id":"formInsertButton"}},[_c('v-btn',{staticClass:"d-flex flex-column justify-center info",staticStyle:{"height":"38px","border-radius":"4px 0 0 4px"},attrs:{"ripple":false,"text":"","x-small":""},on:{"click":function($event){return _vm.$emit('onCommand', {command: 'newRecord'})}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","size":"18"}},[_vm._v("mdi-plus")]),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.$t('buttons.insertRecord')))])],1),_c('v-btn',_vm._g({staticClass:"pa-0 info",staticStyle:{"border-radius":"0 4px 4px 0","height":"38px","width":"28px"},attrs:{"text":""}},on),[(!_vm.showingInsertMenu)?_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chevron-down")]):_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chevron-up")])],1)],1)]}}],null,false,73498219),model:{value:(_vm.showingInsertMenu),callback:function ($$v) {_vm.showingInsertMenu=$$v},expression:"showingInsertMenu"}},[_c('v-card',{staticStyle:{"width":"100%","font-size":"16px"}},[_c('v-list',{staticClass:"pa-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-list-item',{staticClass:"px-3",style:(hover?'background-color: #F9F9F9;':''),on:{"click":function($event){return _vm.$emit('onCommand', {command: 'newRecord'})}}},[_c('v-icon',{attrs:{"color":"#272E3B"}},[_vm._v("mdi-dock-top")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('buttons.singleRecordInsert')))])],1)]}}],null,false,1057185629)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-list-item',{staticClass:"px-3",style:(hover?'background-color: #F9F9F9;':''),on:{"click":function($event){return _vm.$emit('enableQuickInsert')}}},[_c('v-icon',{attrs:{"color":"#272E3B"}},[_vm._v("mdi-table")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('buttons.quickRecordInsert')))])],1)]}}],null,false,2580635626)})],1)],1)],1):_vm._e()]}),_c('v-spacer'),_vm._t("prepend-action-button-set"),(!_vm.isQuickInsertMode)?_c('div',{staticClass:"d-flex flex-row align-center",staticStyle:{"margin-left":"4px"}},_vm._l((_vm.activeButtonSets),function(buttonSet,index){return _c('form-action-button-set',{key:index,ref:"formActionButtonSet",refInFor:true,attrs:{"disabled":_vm.loading,"buttonSet":buttonSet},on:{"onCommand":_vm.onCommandHandler}})}),1):_vm._e(),_vm._t("append-action-button-set")],2)}
var staticRenderFns = []

export { render, staticRenderFns }