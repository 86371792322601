<template>
  <div class="input-checkbox-toggle pb-2">
    <v-btn v-for="option in options" 
          class="min-width-50 elevation-0"
          :key="option[valueField]"
          :class="getButtonClass(option)"
          :style="getButtonStyle(option)"
          @click="onClick(option)"
          LevelDiagramView
    >
      <v-icon v-if="icon" left>{{ option.icon }}</v-icon>
      <!-- {{ option[valueField] }} -->
      {{ optionLabel(option) }}
    </v-btn>
  </div>
</template>

<script>
import appColorMixin from '@/mixins/appColorMixin'

export default {  
  name: 'formToggle',
  mixins: [appColorMixin],

  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    icon: Boolean,
    options: Array,
    value: String,
    valueField: {
      type: String,
      default: 'value'
    },
    selection: Array,
    isDense: {
      type: Boolean,
      default: true
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: ''
    },
    useFormColor: Boolean,
    activeColor: {
      type: String,
      default: 'primary'
    },
    activeTheme: {
      type: String,
      default: 'dark'
    },
    defaultColor: {
      type: String,
      default: ''
    },
    defaultTheme: {
      type: String,
      default: 'light'
    }
  },
  computed: {
  },
  methods: {
    getButtonStyle (option) {
      const vm = this
      var result = {
        height: (vm.isDense ? '24px' : '32px') + ' !important'
      }
      if (vm.value.includes(option[vm.valueField])) {
        if (vm.useFormColor) {
          result['color'] = vm.getTextColor(option.color)
          result['backgroundColor'] = option.color
        }
      }
      return result
    },
    getButtonClass (option) {
      const vm = this
      var result = [vm.buttonClass]
      if (vm.isReadOnly) {
        result.push('read-only')
      } else if (vm.value) {
        if (vm.value.includes(option[vm.valueField])) {
          if (!vm.useFormColor) {
            result.push(vm.activeColor)
            result.push(vm.activeTheme)
          }
        } else {
          result.push(vm.defaultColor)
          result.push(vm.defaultTheme)
        }
      }
      return result.join(' ')
    },
    optionLabel (option) {
      const vm = this
      var result = option.label
      if (option.labelTag) {
        result = vm.$t(option.labelTag)
      }
      return result
    },
    onClick (option) {
      const vm = this
      if (!vm.isReadOnly) {
        var updatedSelection = JSON.parse(JSON.stringify(vm.value))
        const index = updatedSelection.indexOf(option.value)
        if (index >= 0) {
          // if (updatedSelection.length > 1) {
            updatedSelection.splice(index, 1)
            vm.$emit('input', updatedSelection)
          // }
        } else {
          updatedSelection.push(option.value)
          vm.$emit('input', updatedSelection)
        }
      }
    }
  }
}
</script>

<style>
.input-checkbox-toggle button {
  margin-right: 1px;
  margin-bottom: 1px;
}
.input-checkbox-toggle button.read-only {
  cursor: default;
}
.input-checkbox-toggle button {
  border: 1px solid darkgrey;
  border-radius: 0.2rem;
}
</style>
