<template>
  <FormElement
    :label="$t('workflow.modules.retrieveRecord.label.orderingRule')"
    :hints="$t('workflow.modules.retrieveRecord.placeholder.orderingRule')"
    name="sorting"
  >
    <v-layout justify-space-between align-center class="w-e-ordering-selector">
      <div>
        <ComboBox
          :value="field || null"
          :options="list"
          :onlyPrefix="false"
          :onlySuffix="true"
          :placeholder="$t('workflow.modules.retrieveRecord.label.selectColumn')"
          valueFormat="object"
          @changed="fieldChanged"
        />
      </div>
      <div>
        <ComboBox
          :value="ordering || null"
          :options="sortingList"
          :placeholder="$t('workflow.modules.retrieveRecord.label.selectOrderingRule')"
          valueFormat="id"
          :otherProps="{ disabled: !field }"
          @changed="directionChanged"
        />
      </div>
    </v-layout>
  </FormElement>
</template>

<script>
// import Validator from 'validator';
import FormElement from './../shared/formElement';
import ComboBox from './../../../form/comboBox/';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import Mixin from './../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ComboBox,
    FormElement,
  },
  props: {
    sorting: {
      type: Object,
      default: {},
    },
    fetchForRelationship: Boolean,
    hints: String,
  },
  methods: {
    fieldChanged(field) {
      this.$set(this.sorting, 'field', field);
      this.$store.dispatch(SET_SETTINGS, {
        sorting: {
          ...this.sorting,
          field,
        },
      });
    },
    convertSortingList() {
      const { field } = this;
      if (field && WorkflowSettingUtils.numericSortingFields(field.fieldType)) {
        this.sortingList = [
          {
            id: 1,
            label: this.$t('workflow.modules.retrieveRecord.label.ordering.acending.numeric')
          },
          {
            id: -1,
            label: this.$t('workflow.modules.retrieveRecord.label.ordering.decending.numeric')
          }
        ];
      } else if (field && WorkflowSettingUtils.stringSortingFields(field.fieldType)) {
        this.sortingList = [
          {
            id: 1,
            label: this.$t('workflow.modules.retrieveRecord.label.ordering.acending.string')
          },
          {
            id: -1,
            label: this.$t('workflow.modules.retrieveRecord.label.ordering.decending.string')
          }
        ];
      }else if(field && WorkflowSettingUtils.settingSortingFields(field.fieldType)) {
        this.sortingList = [
          {
            id: 1,
            label: this.$t('workflow.modules.retrieveRecord.label.ordering.acending.setting')
          },
          {
            id: -1,
            label: this.$t('workflow.modules.retrieveRecord.label.ordering.decending.setting')
          }
        ];
      }else {
        this.sortingList = this.initialSortingList;
      }
    },
    directionChanged(direction) {
      this.$set(this.sorting, 'direction', direction);
      this.$store.dispatch(SET_SETTINGS, {
        sorting: {
          ...this.sorting,
          direction,
        },
      });
    }
  },
  mounted() {
    this.convertSortingList();
  },
  data() {
    this.initialSortingList = [
      {
        id: 1,
        label: this.$t('workflow.modules.retrieveRecord.label.ordering.acending.date')
      },
      {
        id: -1,
        label: this.$t('workflow.modules.retrieveRecord.label.ordering.decending.date')
      }
    ];
    return {
      sortingList: this.initialSortingList,
    }
  },
  computed: {
    list() {
      return this.fetchForRelationship ? this.$store.getters.getFormRelationshipSelectionsFields.children : 
      this.$store.getters.getFormSelectionsFields.children;
    },
    field() {
      return this.sorting.field;
    },
    ordering() {
      return this.sorting.direction;
    }
  },
  watch: {
    field: {
      handler(v) {
        this.convertSortingList();
      },
      deep: true,
    }
  },
};

</script>

<style scoped>
  .w-e-ordering-selector {
    margin: 0 -5px;
  }
  .w-e-ordering-selector > div {
    margin: 0 5px;
  }
</style>
