<template>
    <div v-if="headers && data" class="d-flex flex-column fill-height ">
        <perfect-scrollbar>
        <div class="header d-flex flex-row">
            <div class="cell-flex grey-border header-cell" v-for="(header, i) in displayHeaders" :key="i">
                {{ header}}
            </div>
        </div>
        <div  class="header d-flex flex-column fill-height ">
        <div class=" d-flex flex-row " v-for="(datum, i) in displayData" :key="i">
            <div class="cell-flex grey-border" v-for="(header, j) in displayHeaders" :key="j">
                <div v-if="i%2===0" class="cell-flex white px-2">{{ datum[header]}}</div>
                <div v-else class="px-2 grey lighten-4 cell-flex">{{ datum[header]}}</div>
            </div>
            
        </div>
        </div>
        </perfect-scrollbar>
    </div>
    <div v-else class="d-flex fill-height align-center justify-center">
        {{$t('importDialog.noData')}}
    </div>
</template>
<script>
export default {
    props: {
        headers: Array,
        data: Array
    },
    computed: {
        displayHeaders(){
            let result = [...this.headers];

                while(result.length < 4){
                    result.push("")
                }

            return result;
        },
        displayData(){
            let result = [...this.data];

                while(result.length < 10){
                    result.push({})
                }

            return result;
        }
    }
}
</script>
<style lang="scss" scoped>
.white-bg {
    background-color: white;
}

.cell-flex {
    flex: 0 0 150px;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
}

.header-cell {
    background-color: $primary;
    text-align: center;
    line-height: 30px;
    height: 30px;
    font-size: 13px;
    color: white;
}

</style>
