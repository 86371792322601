<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-1"
        dark
        depressed
        :disabled="disabled"
        x-small
        fab
        :color="customColor"
        v-on="on"
        v-bind="attrs"
        @click="showDrawer = !showDrawer"
      >
        <!-- <v-icon v-if="!showDrawer">mdi-arrow-expand-horizontal</v-icon> -->
        <!-- <v-icon v-else>mdi-arrow-collapse-horizontal</v-icon> -->
        <v-icon>{{customIcon}}</v-icon>
      </v-btn>
    </template>
    <span>{{
      showDrawer ? $t("buttons.hideDrawer") : $t("buttons.expandDrawer")
    }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    value: Boolean,
    disabled: Boolean,
    customIcon:{
      type: String,
      default: 'mdi-comment'
    },
    customColor:{
      type: String,
      default: '#26A83B'
    }
  },
  computed: {
    showDrawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
