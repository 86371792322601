<template>
  <v-dialog
    fullscreen
    v-model="showDialog"
    transition="dialog-bottom-transition"
  >
    <v-card class="fill-height" :class="isPublicEdit?'publicEditFormBuilder':''">
      <v-row no-gutters class="fill-height">
        <v-col cols="2" class="right-border">
          <div class="pa-4">
            <v-row no-gutters class="my-2">
              <v-btn rounded color="primary" @click="showDialog = false">{{
                $t("buttons.close")
              }}</v-btn>
            </v-row>
            <!-- <v-row no-gutters>
              <v-col class="pa-0"> -->
              <slot name="settings">
                <v-row no-gutters class="my-2">
                  <span class="text-subtitle-1">{{ $t("general.settings") }}</span>
                </v-row>
                <v-row no-gutters class="my-2">
                  <urlBox :url="publicUrl" />
                </v-row>
                <v-row no-gutters class="my-2">
                  <v-btn block color="primary" @click="showSettingDialog = true">{{
                    $t("general.settings")
                  }}</v-btn>
                </v-row>
              </slot>
              <!-- </v-col>
            </v-row> -->
          </div>
          <v-divider />
          <perfect-scrollbar>
            <field-options-list
              :fieldOptions="fieldOptions"
              :publicForm="publicForm"
              @commandHandler="onCommandHandler"
              :isPublicEdit="isPublicEdit"
              :editableTypes="editableTypes"
            />
          </perfect-scrollbar>
        </v-col>

        <v-col cols="10">
          <background
            :color="borderColor"
            :headerColor="color"
            isEditMode
            :showHeader="showHeader"
            :imageUrl="publicForm.backgroundImage ? publicForm.backgroundImage.imageUrl : ''"
          >
            <template v-slot:header-control>
              <v-row no-gutters class="header-control">
                <v-btn
                  rounded
                  color="primary"
                  @click="showDrawer = true"
                  class="mr-1"
                  >{{ $t("publicForm.themeSetting") }}</v-btn
                >
                <v-btn v-if="!isPublicEdit" rounded color="primary" @click="openURL(true)">{{
                  $t("publicForm.preview")
                }}</v-btn>
              </v-row>
            </template>
            <template v-slot:card-content>
              <formCard
                :isEdit="true"
                :publicForm="publicForm"
                @commandHandler="onCommandHandler"
                :color="color"
              >
                <template v-slot:card-content>
                  <drop-list
                    :items="publicForm.layout"
                    class="pt-1 col drop-list-col"
                    :column="true"
                    @insert="onInsertToCol"
                    mode="cut"
                    @reorder="$event.apply(publicForm.layout)"
                  >
                    <drop-zone
                      style="background-color:transparent;height:50px;"
                    ></drop-zone>
                    <template v-slot:item="{ item, index }">
                      <drag
                        class="d-flex flex-row align-center"
                        :id="'row' + getRowIndex(item)"
                        :key="getRowKey(item)"
                        :data="item"
                        @cut="removeRow(item)"
                      >
                        <div class="row-holder px-3">
                          <v-icon class="justify-self-start">
                            mdi-format-line-spacing
                          </v-icon>
                        </div>

                        <form-layout-row
                          ref="splitterList"
                          :rowIndex="getRowIndex(item)"
                          :colBreakPoints="positions.colBreakPoints"
                          @commandHandler="onCommandHandler"
                          :items="item"
                          :customFieldProperties="
                            publicForm.customFieldProperties || {}
                          "
                          :fieldInfos="allFieldInfos"
                        >
                          <template v-slot:controlButtons="{ item, rowIndex }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attr }">
                                <v-btn
                                  class="lightgrey px-0 mr-2"
                                  v-if="item.controlType === 'data'"
                                  x-small
                                  dense
                                  v-on="on"
                                  v-bind="attr"
                                  style="min-width:24px;"
                                  @click="
                                    openEditCustomPropsDialog(
                                      item._id,
                                      getLabel(item._id),
                                      getLabel(item._id, true),
                                      getDescription(item._id),
                                      getDefaultDescription(item._id)
                                    )
                                  "
                                >
                                  <v-icon x-small>mdi-pencil-outline</v-icon>
                                </v-btn>
                              </template>
                              {{ $t("publicForm.alias") }}
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attr }">
                                <v-btn
                                  class="lightgrey px-0"
                                  x-small
                                  dense
                                  v-on="on"
                                  v-bind="attr"
                                  style="min-width:24px;"
                                  @click="removeItem(item, rowIndex)"
                                >
                                  <v-icon x-small>mdi-eye-off-outline</v-icon>
                                </v-btn>
                              </template>
                              {{ $t("buttons.hide") }}
                            </v-tooltip>
                          </template>
                          <template v-slot:appendedButtons="{ item, rowIndex }">
                            <div class="pr-1 d-flex justify-end align-center" v-if="isPublicEdit" style="border: 1px solid #eeee">
                              <div v-if="getFieldType(item._id)=='attachments'||editableTypes.includes(getFieldType(item._id))" class="grey--text ml-4">{{$t('publicEdit.allow')}}: </div>
                              <div class="ml-2" v-if="getFieldType(item._id)=='attachments'">
                                <v-checkbox
                                  :input-value="getDeletable(item._id)" 
                                  :ripple="false" 
                                  hide-details 
                                  dense
                                  class="ma-0 pa-0" 
                                  :label="$t('publicForm.deletable')"
                                  @change="handleCustomPropertyUpdate('deletable', item._id, $event)"
                                ></v-checkbox>
                              </div>
                              <div class="ml-2" v-if="getFieldType(item._id)=='attachments'">
                                <v-checkbox 
                                  :input-value="getUploadable(item._id)"
                                  :ripple="false" 
                                  hide-details 
                                  dense
                                  class="ma-0 pa-0" 
                                  :label="$t('publicForm.uploadable')"
                                  @change="handleCustomPropertyUpdate('uploadable', item._id, $event)"
                                >
                                </v-checkbox>
                              </div>
                              <div class="ml-2" v-if="editableTypes.includes(getFieldType(item._id))">
                                <v-checkbox
                                  :input-value="getEditable(item._id)" 
                                  :ripple="false" 
                                  hide-details 
                                  dense 
                                  class="ma-0 pa-0" 
                                  :label="$t('publicForm.editable')"
                                  @change="handleCustomPropertyUpdate('editable', item._id, $event)"
                                ></v-checkbox>
                              </div>
                            </div>
                          </template>
                        </form-layout-row>
                        <template v-slot:drag-image>
                          <v-sheet
                            :color="`grey lighten-4`"
                            class="pa-3"
                          >
                        <v-skeleton-loader
                          boilerplate
                          min-width="300"
                          type="list-item"
                        ></v-skeleton-loader>
                      </v-sheet>
                        </template>
                      </drag>
                    </template>

                    <template v-slot:feedback>
                      <div class="feedback" key="feedback2"></div> </template
                    ><!-- feedback slot -->
                    <template v-slot:reordering-feedback ><div class="feedback" key="feedback2"></div></template>
                  </drop-list>
                </template>
              </formCard>
            </template>
          </background>

          <v-navigation-drawer
            v-if="showDrawer"
            v-model="showDrawer"
            width="340"
            right
            absolute
            temporary
            hide-overlay
          >
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-switch class="pl-4 mt-0" 
                    v-model="showHeader" 
                    :label="$t('publicForm.showHeader')" 
                    hide-details 
                    @change="saveSetting('showHeader', $event)"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("publicForm.headerColor")
                  }}</v-list-item-title>
                  <div v-if="!toggleCustomColor">
                    <v-row no-gutters>
                      <colorButton
                        v-for="(option, index) in BASIC_COLORS"
                        :key="index"
                        :color="option"
                        @select="
                          color = $event;
                          saveSetting('themeColor', color);
                        "
                      >
                        <v-icon
                          x-small
                          v-if="color === option"
                          class="white--text"
                          >mdi-check</v-icon
                        >
                      </colorButton>
                    </v-row>
                    <v-row no-gutters>
                      <colorButton
                        v-for="(option, index) in customColor"
                        :key="index"
                        :color="option"
                        @select="
                          color = $event;
                          saveSetting('themeColor', color);
                        "
                      >
                        <v-icon
                          x-small
                          v-if="color === option"
                          class="white--text"
                          >mdi-check</v-icon
                        >
                      </colorButton>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attr }">
                          <v-btn
                            class="grey lighten-3 ma-1"
                            x-small
                            depressed
                            fab
                            v-on="on"
                            v-bind="attr"
                            @click="toggleCustomColor = true"
                          >
                            <v-icon class="grey--text">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("publicForm.addCustomColor") }}
                      </v-tooltip>
                    </v-row>
                  </div>
                  <v-row no-gutters v-else align="center" justify="center">
                    <v-col>
                      <chrome-picker
                        :value="color"
                        @input="color = $event.hex"
                        disableAlpha
                        class="background-color-picker"
                        style="width:100%"
                      />
                      <v-row no-gutters>
                        <v-spacer />
                        <v-btn
                          class="my-2 mr-4"
                          text
                          @click="
                            color = themeColor;
                            toggleCustomColor = false;
                          "
                          >{{ $t("buttons.cancel") }}</v-btn
                        >

                        <v-btn
                          class="my-2 mr-4"
                          color="primary"
                          text
                          @click="saveCustomColor"
                          >{{ $t("buttons.confirm") }}</v-btn
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("publicForm.backgroundColor")
                  }}</v-list-item-title>
                  <div class="flex-grow-0">
                    <colorButton
                      v-for="(intensity, index) in colorIntensityOptions"
                      :key="index"
                      :color="getAltColor(color, intensity)"
                      @select="
                        saveSetting('backgroundColorIntensity', intensity)
                      "
                    >
                      <v-icon
                        x-small
                        v-if="publicForm.backgroundColorIntensity === intensity"
                        >mdi-check</v-icon
                      >
                    </colorButton>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("publicForm.uploadImage")
                  }}</v-list-item-title>
                  <v-btn
                    block
                    color="primary"
                    @click="openFileBrowserImage"
                    class="my-2"
                    >{{ $t("publicForm.uploadImage") }}</v-btn
                  >
                  <v-btn
                    v-if="publicForm.backgroundImage"
                    block
                    color="error"
                    @click="saveSetting('backgroundImage', null)"
                    >{{ $t("publicForm.deleteImage") }}</v-btn
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-col>
      </v-row>
    </v-card>
    <settingDialog
      v-model="showSettingDialog"
      :info="publicForm"
      :url="publicUrl"
      @commandHandler="onCommandHandler"
    />
    <confirmDialog ref="confirmDialog" />
    <EditCustomPropsDialog
      ref="editCustomPropsDialog"
      @confirm="saveCustomProps"
    />
    <input
      ref="uploaderLogo"
      type="file"
      accept="image/png, image/jpeg, image/gif, image/jpg"
      class="d-none"
      @change="onLogoUpload"
    />
    <input
      ref="uploaderImage"
      type="file"
      accept="image/png, image/jpeg, image/gif, image/jpg"
      class="d-none"
      @change="onImageUpload"
    />
  </v-dialog>
</template>
<script>
import { Drag, DropList } from "vue-easy-dnd";
import { Chrome } from "vue-color";
import { has, get } from "lodash";

import confirmDialog from "@/components/dialogs/ConfirmDialog";
import background from "@/components/public/background";
import formCard from "@/components/public/formCard";

import formLayoutRow from "./formLayoutRow";
import urlBox from "./comp/urlBox";
import fieldOptionsList from "./comp/fieldOptionsList";
import settingDialog from "./comp/settingDialog";
import EditCustomPropsDialog from "./comp/EditCustomPropsDialog";

import colorButton from "./comp/colorButton";

import publicFormMixin from "@/mixins/publicFormMixin";
import appColorMixin from "@/mixins/appColorMixin";

export default {
  mixins: [publicFormMixin, appColorMixin],
  props: {
    value: Boolean,
    publicForm: Object,
    isPublicEdit:{
      type: Boolean,
      default: false
    }
  },
  components: {
    Drag,
    DropList,

    formLayoutRow,
    urlBox,
    settingDialog,

    "chrome-picker": Chrome,
    confirmDialog,
    background,
    formCard,
    colorButton,
    EditCustomPropsDialog,
    fieldOptionsList,
  },
  data() {
    return {
      positions: {
        rowLeft: 0,
        colBreakPoints: [],
      },
      colorIntensityOptions: [0.9, 0.8, 0.7, 1],
      customColor: [],
      showDrawer: false,
      showSettingDialog: false,
      toggleCustomColor: false,
      color: this.publicForm.themeColor,
      editableTypes: ['text', 'number', 'amount', 'date', 'phone', "email",
      'singleSelection', 'multipleSelection', 'region', 'location', 
      'yesNo', 'rating', 'richText', 'idCard', 'signature', 'relatedRecord', 
      'parentChildRelation', 'childTable'],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    fieldOptions() {
      const displayedFieldIds = this.publicForm.layout
        .flat(2)
        .map((field) => field._id);
      const excludedFieldIds = this.publicForm.hiddenFieldIds.concat(
        displayedFieldIds
      );
      const filteredField = this.allFieldInfos.filter(
        (info) => !excludedFieldIds.includes(info.fieldId)
      );

      return filteredField.map((field) => ({
        _id: field._id,
        controlType: field.type.startsWith("_") ? "static" : "data",
        dataType: field.type,
        label: field.label,
        alias: get(this.publicForm, `customFieldProperties.${field._id}.alias`, ""),
        editable: get(this.publicForm, `customFieldProperties.${field._id}.editable`, false),
        uploadable: get(this.publicForm, `customFieldProperties.${field._id}.uploadable`, false),
        deletable: get(this.publicForm, `customFieldProperties.${field._id}.deletable`, false),
        description: this.getDescription(field._id),
        width: 12,
      }));
    },
    allFieldInfos() {
      return this.$store.getters.formFieldInfos.concat(
        this.$store.getters.formUIElementInfos
      );
    },

    layout() {
      return this.publicForm.layout;
    },
  },
  watch: {
    layout: {
      handler(newVal, oldVal) {
        if (oldVal) this.saveForm();
      },
      deep: true,
    },

    showDrawer: function() {
      this.color = this.publicForm.themeColor;
    },
  },
  methods: {
    reset() {
      this.$refs.confirmDialog
        .open(
          this.$t("publicForm.resetLayout"),
          this.$t("publicForm.resetLayoutDescription")
        )
        .then((confirm) => {
          if (confirm) {
            this.$emit("commandHandler", {
              command: "reset",
              publicForm: this.publicForm,
            });
          }
        });
    },
    saveSetting(setting, value, cb) {
      this.$emit("commandHandler", {
        command: "saveSetting",
        publicForm: this.publicForm,
        setting,
        value,
        cb,
      });
    },
    openFileBrowserLogo() {
      this.$refs.uploaderLogo.value = "";
      this.$refs.uploaderLogo.click();
    },
    openFileBrowserImage() {
      this.$refs.uploaderImage.value = "";
      this.$refs.uploaderImage.click();
    },
    onLogoUpload(e) {
      const file = e.target.files[0];
      this.$emit("commandHandler", {
        command: "setLogo",
        publicForm: this.publicForm,
        file,
      });
    },
    onImageUpload(e) {
      const file = e.target.files[0];
      this.$emit("commandHandler", {
        command: "setImage",
        publicForm: this.publicForm,
        file,
      });
    },
    removeLogo() {
      this.$emit("commandHandler", {
        command: "setLogo",
        publicForm: this.publicForm,
        file: null,
      });
    },
    getIcon(type) {
      return this.$store.getters.widgetIconMap[type];
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "setWidth":
          this.setWidth(payload.fieldId, payload.rowIndex, payload.width);
          break;
        case "removeItem":
          this.removeItem(payload.item, payload.rowIndex);
          break;
        case "openFileBrowser":
          this.openFileBrowserLogo();
          break;
        case "appendToLayout":
          this.appendToLayout(payload.field);
          break;
        case "openEditCustomPropsDialog":
          this.openEditCustomPropsDialog(
            payload.fieldId,
            this.getLabel(payload.fieldId),
            this.getLabel(payload.fieldId, true),
            this.getDescription(payload.fieldId),
            this.getDefaultDescription(payload.fieldId)
          );
          break;
        case "reset":
          this.reset();
          break;
        case "hideAll":
          this.hideAll();
          break;
        default:
          this.$emit("commandHandler", {
            ...payload,
            publicForm: this.publicForm,
          });
          break;
      }
    },
    removeItem(item, rowIndex) {
      const colIndex = this.publicForm.layout[rowIndex].findIndex(
        (field) => field._id === item._id
      );
      if (colIndex > -1) this.publicForm.layout[rowIndex].splice(colIndex, 1);
      if (this.publicForm.layout[rowIndex].length === 0)
        this.publicForm.layout.splice(rowIndex, 1);
    },
    onInsertToCol(event) {
      this.publicForm.layout.splice(event.index, 0, [
        {
          _id: event.data._id,
          controlType: event.data.controlType,
          width: event.data.width,
        },
      ]);
    },
    getRowIndex(item) {
      const vm = this;
      return vm.publicForm.layout.indexOf(item);
    },
    getRowKey(row) {
      const index = this.publicForm.layout.indexOf(row);
      let result = `row_${index}`;
      if (this.publicForm.layout[index].length > 0) {
        result = `row_${this.publicForm.layout[index][0]._id}`;
      }
      return result;
    },
    onReorderRow(event) {
      event.apply(this.publicForm.layout);
    },
    setWidth(fieldId, rowIndex, width) {
      let row = this.publicForm.layout[rowIndex];
      let field = row.find((item) => item._id === fieldId);
      if (field) {
        field.width = width;
      }
    },
    saveForm() {
      this.$emit("commandHandler", {
        command: "saveForm",
        publicForm: this.publicForm,
      });
    },
    saveCustomColor() {
      if (!this.BASIC_COLORS.includes(this.color))
        this.customColor.push(this.color);
      this.saveSetting("themeColor", this.color);

      this.toggleCustomColor = false;
    },
    appendToLayout({ _id, controlType }) {
      let item = [
        {
          _id,
          controlType,
          width: 12,
        },
      ];
      this.publicForm.layout.push(item);
    },
    hideAll() {
      this.publicForm.layout = [];
    },
    getLabel(fieldId, isDefaultLabel=false) {
      if (
        !isDefaultLabel &&
        has(this.publicForm, `customFieldProperties.${fieldId}.alias`)
      ) {
        return this.publicForm.customFieldProperties[fieldId].alias;
      } else {
        const fieldInfo = this.$store.getters.formFieldInfos.find(
          (field) => field._id === fieldId
        );
        return get(fieldInfo, "label", "");
      }
    },
    getDescription(fieldId) {
      return get(this.publicForm, `customFieldProperties.${fieldId}.description`, this.getDefaultDescription(fieldId));
    },
    getDefaultDescription(fieldId) {
      const fieldInfo = this.$store.getters.formFieldInfos.find(
        (field) => field._id === fieldId
      );
      if (fieldInfo) {
        return fieldInfo.properties.description;
      } else return "";
    },
    getEditable(fieldId){
      return get(this.publicForm, `customFieldProperties.${fieldId}.editable`, false); 
    },
    getUploadable(fieldId){
      return get(this.publicForm, `customFieldProperties.${fieldId}.uploadable`, false); 
    },
    getDeletable(fieldId){
      return get(this.publicForm, `customFieldProperties.${fieldId}.deletable`, false); 
    },
    openEditCustomPropsDialog(fieldId, alias,defaultName, description, defaultDescription) {
      this.$refs.editCustomPropsDialog.open(
        fieldId,
        alias,
        defaultName,
        description,
        defaultDescription
      );
    },
    saveCustomProps(payload) {
      this.$emit("commandHandler", {
        command: "saveCustomProps",
        publicForm: this.publicForm,
        fieldId: payload.fieldId,
        value: {
          alias: payload.alias,
          description: payload.description,
          editable: this.getEditable(payload.fieldId),
          uploadable: this.getUploadable(payload.fieldId),
          deletable: this.getDeletable(payload.fieldId)
        },
      });
    },
    handleCustomPropertyUpdate(key, fieldId, val){
      var data = this.publicForm.customFieldProperties && this.publicForm.customFieldProperties[fieldId]?
        {...this.publicForm.customFieldProperties[fieldId]}:{}
      
      data[key] = val 
      this.$emit("commandHandler", {
        command: "saveCustomProps",
        publicForm: this.publicForm,
        fieldId: fieldId,
        value: data
      });
    },
    getFieldType(fieldId){
      const res = this.allFieldInfos.find(fieldInfo=>fieldInfo.fieldId === fieldId)
      if(res)
        return res.type
      else
        return ''
    }
  },
  mounted() {
    if (!this.BASIC_COLORS.includes(this.publicForm.themeColor)) {
      this.customColor.push(this.publicForm.themeColor);
    }
  },
};
</script>
<style scoped>
.right-border {
  border-right: 1px #9e9e9e solid;
}

.header-control {
  position: absolute;
  top: 17px;
  right: 24px;
}

.background-color-picker {
  box-shadow: none;
  border: 1px solid #9e9e9e;
  border-radius: 3px;
}

.ps {
  height: calc(100vh - 230px);
}

</style>
<style>
  .publicEditFormBuilder .v-input--selection-controls__input{
    margin-right: 2px !important;
  }
  .publicEditFormBuilder .input-widget-container-title{
    height: 22px !important
  }
</style>