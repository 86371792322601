import defaultState from './state'
import Vue from "vue"
import {constants as types} from "../../mutation-types"

export default {
    [types.SET_CONSTANTS](state, payload){
        Vue.set(state, constants, payload)
    },
    [types.RESET_CONSTANTS](state){
        Object.assign(state, defaultState);
    }
}