<template>
    <v-card tile class="black--text" style="height:100%; position: absolute; width:100%; top:0; left:0">
        <v-toolbar style="height:6%">
            <v-btn
            icon
            @click="$emit('update_bl_dialog', false)"
            >
            <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title style="font-size:16px">編輯自定義頁面:</v-toolbar-title>
            <div style="width:180px" class="ml-4">
            <v-text-field dense height="30" hide-details/>
            </div>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-items class="text--center">
            <v-btn
                text
                @click="$emit('update_bl_dialog', false)"
            >
                關閉
            </v-btn>
            </v-toolbar-items>
            <v-toolbar-items>
            <v-btn
                text
               @click="saveCustomerPage"
            >
                儲存
            </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <div class="d-flex" style="height:94%">
            <div style="width:15%; border-right: solid 1px #eeee" class="">
                <div class="mt-3 d-felx flex-column">
                    <div class="px-4" style="font-size:12px">新增元件</div>
                    <v-list dense class="pt-1">
                    <v-list-item @click="openDrawer(1)" style="border: 1px solid #eeee">
                        <v-icon>
                            mdi-chart-bar
                        </v-icon>
                        <span class="ml-3">統計圖</span>
                    </v-list-item>
                    <v-list-item @click="openDrawer(2)" style="border: 1px solid #eeee">
                         <v-icon>
                            mdi-alpha-b-box-outline
                        </v-icon>
                        <span class="ml-3">按鈕</span>
                    </v-list-item>
                    <v-list-item @click="openDrawer(3)" style="border: 1px solid #eeee">
                        <v-icon>
                            mdi-format-font
                        </v-icon>
                        <span class="ml-3">富文字</span>
                    </v-list-item>
                    <v-list-item @click="openDrawer(4)" style="border: 1px solid #eeee">
                         <v-icon>
                            mdi-code-tags
                        </v-icon>
                        <span class="ml-3">嵌入URL</span>
                    </v-list-item>
                    </v-list>
                </div>
            </div>
            <div style="width:85%; overflow:auto;">
                <GridCanvas v-if="bl_dialog" :elements="elements" @update_elements="update_elements"/>
            </div>
        </div>

        <v-navigation-drawer
            v-model="drawer"
            absolute
            right
            width="650"
            class=''
            style="height:100%"
            temporary
        >
            <v-card tile style="height:100%" elevation="0">
            <v-card-subtitle class="px-5 py-4" style="border-bottom: 1px solid #eeee; font-size:16px">{{$t('dashboard.buildChart')}}</v-card-subtitle>
            <div class="d-flex flex-column">
                <div class="ml-10 mt-5" style="font-size:15px;">{{$t('dashboard.form')}}</div>
                <div class="mx-10">
                    <v-menu offset-y :close-on-content-click="false" :close-on-click="false">
                        <template v-slot:activator="{on}">
                            <v-text-field v-on="on" hide-details outlined dense height="30" :placeholder="$t('dashboard.selectForm')" readonly :value="selectedFormName">
                                <template>
                                </template>
                            </v-text-field>
                        </template>
                        <v-card class="" elevation="0">
                            <v-text-field
                              prepend-inner-icon="mdi-magnify"
                              placeholder="搜尋工作表"
                              hide-details
                              height="30"
                              dense
                              v-model="searchString"
                            >
                                <tmeplate v-slot:>
                                </tmeplate>
                            </v-text-field>
                            <v-list class="pa-0" dense>
                                <v-list-item-group v-model="selectedForm" color="primary">
                                    <v-list-item :value="menuItem._id" v-for="menuItem in filteredModuleMenu" :key="menuItem._id">
                                        {{menuItem.title}}
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- <div>選擇其他應用下的工作表</div> -->
                        </v-card>
                    </v-menu>
                </div>
                <div class="ml-10 mt-12" style="font-size:15px;">選擇圖形</div>
                <div id="form-chartHook" class="px-10 pb-12">
                    <v-hover @click.native="selectChart(chart)" v-slot="{ hover }" v-for="(chart,index) in charts" :key="index" class="d-flex flex-column">
                        <v-card outlined tile flat class="chartContainer d-flex flex-column">
                            <div class="" :id="`chart-${chart._id}`" style="height: 250px; width:100%"></div>
                        </v-card>
                    </v-hover>
                </div> 
            </div>
            </v-card>
        </v-navigation-drawer>

        <RichTextDialog
          v-model="bl_richTextDialog"
          :content="''"
          @update_elements="update_elements"
        />
        <ButtonDrawer
            v-model="bl_buttonDrawer"
            @update_elements="update_elements"
        />
        <EmbeddedUrlDialog
            v-model="bl_embeddedUrlDialog"
            @update_elements="update_elements" 
        />
    </v-card>
</template>

<script>
import GridCanvas from './GridCanvas'
import RichTextDialog from './RichTextDialog'
import EmbeddedUrlDialog from './EmbeddedUrlDialog'
import ButtonDrawer from './ButtonDrawer'
import * as echarts from 'echarts';
export default {
    props:{
        bl_dialog: Boolean,
    },
    components:{
        GridCanvas,
        RichTextDialog,
        EmbeddedUrlDialog,
        ButtonDrawer
    },
    data(){
        return {
            drawer: false,
            bl_embeddedUrlDialog: false,
            elements: [],
            isResizing: false,
            minWidth: 100,
            minHeight: 100,
            maxWidth: 900,
            maxHeight: 900,
            slots: [],
            activeEleIndex: null,
            searchString: '',
            selectedForm: null,
            charts: [],
            selectedApp: null,
            selectedCharts: [],
            count: 0,
            bl_richTextDialog: false,
            bl_buttonDrawer: false
        }
    },
    mounted() {
        for(var i = 1; i<=12; i++){
            var slot = document.querySelector('#slot-' + i)
            // console.log('slot'+i, slot)
            if(slot){
                // console.log({top:slot.offsetTop, left: slot.offsetLeft})
                let obj = {
                    top: slot.offsetTop,
                    left: slot.offsetLeft
                }
                
                this.slots.push(obj)
            }
        }
    },
    watch:{
        selectedForm(val){
            var appId = this.$store.getters.currentApp._id
            this.fetchCharts(appId, val)
        },
    },
    computed:{
        currentModule () {
            const vm = this
            return this.$store.getters.currentModule
        },
        filteredModuleMenu(){
            if(this.searchString=='')
                return this.currentModule.menu.filter(item=>item.type=='form')
            else
                return this.currentModule.menu.filter(item=>item.type=='form' && (item.title.toUpperCase()).includes(this.searchString.toUpperCase()))
        },
        selectedFormName(){
            if(this.selectedForm){
                return this.currentModule.menu.find(item=>item._id == this.selectedForm).title
            }else{
                return ''
            }
        },
        selectedMenuItemId () {
            return this.$store.getters.selectedMenuItemId
        },
    },
    methods:{
        async saveCustomerPage(){
            // this.$emit('update_bl_dialog', false)
            const postData = {
                urlCommand: '/panels/save',
                data:{
                    panelId: this.selectedMenuItemId,
                    elements: this.elements
                }
            }
            console.log(postData)
            await this.$store.dispatch('AUTH_POST', postData).then(res => {
                console.log('updated')
            })
            this.$emit('update_bl_dialog', false)
            
        },
        mousedown(index, e) {
            //get the initial mouse corrdinates and the position coordinates of the elementdiv = document.querySelector('.item'),
            let vm = this
            this.activeEleIndex = index
            // console.log(this.activeEleIndex)
            const mousemove = function(e) {
                //get horizontal and vertical distance of the mouse move
                let newX = prevX - e.clientX, //negative to the right, positive to the left
                    newY = prevY - e.clientY; //negative to the bottom, positive to the top
                let newWidth = div.offsetWidth
                let newHeight = div.offsetHeight
                let newStartX = prevLeft - newX
                let newStartY = prevTop - newY
                let newEndX = prevLeft - newX + newWidth
                let newEndY = prevTop - newY + newHeight

                var offsetX = 0
                var offsetY = 0
                vm.elements.forEach((item, index, array)=>{
                    if(index == vm.activeEleIndex) return
                    let startX = item.left
                    let endX = item.left + item.width
                    let startY = item.top
                    let endY = item.top + item.height

                    if((newStartX>=startX && newStartX<=endX || newEndX >=startX && newEndX<=endX)&&
                        (newStartY>=startY && newStartY<=endY || newEndY >=startY && newEndY<=endY)
                    ){
                        console.log('hit')

                    }
                })
                
                div.style.left = prevLeft - newX + 'px';
                div.style.top = prevTop - newY + 'px';
            }

            const mouseup = function(e) {
                // console.log('mouseup', e)
                
                window.removeEventListener('mousemove', mousemove);
                window.removeEventListener('mouseup', mouseup);
                // console.log(div)
                var endLeft = div.offsetLeft
                var endTop = div.offsetTop
                
                console.log(endLeft)
                var lefts = vm.slots.map(item=>item.left)

                var closest = lefts.reduce(function(prev, curr) {
                    return (Math.abs(curr - endLeft) < Math.abs(prev - endLeft) ? curr : prev);
                })
                
                console.log(closest)
                
                // check top area
                let left = div.offsetLeft
                let top = div.offsetTop
                let height = div.offsetHeight
                let width = div.offsetWidth

                let move2Top = vm.slots[0].top

                let SortedEles =  JSON.parse(JSON.stringify(vm.elements))
                SortedEles.splice(vm.activeEleIndex,1)
                SortedEles = SortedEles.sort((a, b) => a.top > b.top && 1 || -1)
 
                SortedEles.forEach((item, index, array)=>{
                    if((closest>=item.left && closest<=item.left+item.width || closest+width >=item.left && closest+width<=item.left+item.width)
                    ||(item.left>=closest && item.left+item.width<=closest+width)
                    ){
                       if(move2Top>=item.top && move2Top<=item.top+height || move2Top+height >=item.top && move2Top+height<=item.top+height){
                            move2Top = item.top + item.height + 10
                       }

                        // if(top>=item.top && top<=item.top+height || top+height >=item.top && top+height<=item.top+height){
                            // move2Top = item.top + item.height + 10
                            // console.log(move2Top)
                        // }
                    }
                })
                
                console.log(move2Top)

                div.style.left = closest + 'px';
                // div.style.top = endTop + 'px';
                div.style.top = move2Top + 'px';
                
                vm.elements[vm.activeEleIndex].left = div.offsetLeft
                // vm.elements[vm.activeEleIndex].top = div.offsetTop
                vm.elements[vm.activeEleIndex].top = move2Top
                vm.elements[vm.activeEleIndex].height = div.offsetHeight
                vm.elements[vm.activeEleIndex].width = div.offsetWidth
            }


            var div =  e.target
            let prevX = e.clientX,
                prevY = e.clientY,
                rect = div.getBoundingClientRect(),
                prevLeft = rect.left,
                prevTop  = rect.top;

            // console.log(e.target.classList)
            // if (!this.isResizing && !e.target.classList.contains('content')) {
            if(!this.isResizing && !e.target.classList.contains('content')) {
                window.addEventListener('mousemove', mousemove);
                window.addEventListener('mouseup', mouseup);
            }
            
        },
        mousedown2(e){
            let vm =this
            var div = e.target.parentNode
            let prevX = e.clientX,
                    prevY = e.clientY,
                    currentResizer = e.target,
                    rect = div.getBoundingClientRect(),
                    prevLeft = rect.left,
                    prevTop  = rect.top,
                    newWidth,
                    newHeight;
                
            this.isResizing = true;
            
            const mousemove = function(e){
                let newX = prevX - e.clientX,
                newY = prevY - e.clientY;
                if (currentResizer.classList.contains('bottom-right')) {
                    newWidth = rect.width - newX;
                    newHeight = rect.height - newY;
                    if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                        div.style.width = newWidth + 'px';
                    }
                    if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                        div.style.height = newHeight + 'px';
                    }
                    
                }
                else if (currentResizer.classList.contains('bottom-left')) {
                    newWidth = rect.width + newX;
                    newHeight = rect.height - newY;

                    if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                        div.style.left = prevLeft - newX + 'px';
                        div.style.width = newWidth + 'px';
                    } 
                    if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                        div.style.height = newHeight + 'px';
                    }
                }
                else if (currentResizer.classList.contains('top-right')) {
                    newWidth = rect.width - newX;
                    newHeight = rect.height + newY;
                    
                    if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                        div.style.width = newWidth + 'px';
                    }
                    if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                        div.style.top = prevTop - newY + 'px';
                        div.style.height = newHeight + 'px';
                    }
                    
                }
                else if (currentResizer.classList.contains('top-left')) {
                    newWidth = rect.width + newX;
                    newHeight = rect.height + newY;
                    
                    if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                        div.style.left = prevLeft - newX + 'px';
                        div.style.width = newWidth + 'px';
                    }
                    if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                        div.style.top = prevTop - newY + 'px';
                        div.style.height = newHeight + 'px';
                    }
                }
            }

            const mouseup = function(){
                vm.isResizing = false;
                window.removeEventListener('mousemove', mousemove);
                window.removeEventListener('mouseup', mouseup);
                
                var endLeft = div.offsetLeft
                var endRight = div.offsetLeft + div.offsetWidth

                var lefts = vm.slots.map(item=>item.left)

                var closestLeft = lefts.reduce(function(prev, curr) {
                    return (Math.abs(curr - endLeft) < Math.abs(prev - endLeft) ? curr : prev);
                })
                var closestRight= lefts.reduce(function(prev, curr) {
                    return (Math.abs(curr - endRight) < Math.abs(prev - endRight) ? curr : prev);
                })

                console.log('closestLeft', closestLeft)
                // console.log('closestRight', closestRight)

                
                let left = closestLeft
                let top = div.offsetTop
                let height = div.offsetHeight
                let width = closestRight - closestLeft

                div.style.left = left + 'px';
                div.style.top = top + 'px';
                div.style.height = height + 'px';
                div.style.width = width + 'px';
            }

            window.addEventListener('mousemove', mousemove);
            window.addEventListener('mouseup', mouseup);
             
        },
        openDrawer(type){
            switch(type){
                case 1:
                    this.drawer = true
                    break
                case 2:
                    this.bl_buttonDrawer = true
                    break
                case 3:
                    this.bl_richTextDialog = true
                    break
                case 4:
                    this.bl_embeddedUrlDialog = true
                    break
            }
        },
        addRect(type){
            // let obj = {
            //     type: type,
            //     x: 100,
            //     y: 100,
            //     width: 180,
            //     height: 150
            // }
            let obj = {"x":0,"y":0,"w":2,"h":2,"i":type, static: false}
            this.elements.push(obj)
            this.drawer = false
        },
        async fetchCharts(appId, formId){
            const getParams = {
                urlCommand: '/chart/get/'+ 'private' +'/'+ appId + '/' + formId
            }
            await this.$store.dispatch('AUTH_GET', getParams)
                .then(response => {
                    console.log(response)
                    this.charts = response
                    this.$nextTick(function () {
                        this.charts.forEach((item, index)=>{
                            let chartDom = document.getElementById('chart-' + item._id )
                            console.log(chartDom)
                            if(chartDom){
                                echarts.dispose(chartDom)
                                let chart = echarts.init(chartDom)
                                item.option && chart.setOption(item.option);
                            }
                        })
                    })
                })
                .catch(err => {
                    console.log(err)
            })
        },
        selectChart(chart){
            this.count = this.count + 1
            let obj = {
                "x":0,"y":0,"w":5,"h":8,"i":this.count, 
                "type":"chart", 
                "formId":chart._id, 
                "chart": chart, 
                "bl_title": false,
                static: false
            }
            this.elements.push(obj)
            this.drawer = false
        },
        update_elements(payload){
            let obj
            if(payload.type == 'copy'){
                var ele = payload.val
                this.count++
                ele.i = this.count
                this.elements.push(ele)
            }else if(payload.type == 'delete'){
                var i = payload.i
                this.elements = this.elements.filter(item=>item.i!=i)
            }else if(payload.type == 'add'){
                switch(payload.itemType){
                    case 'richText':
                        this.count++
                         obj = {
                            "x":0,"y":0,"w":5,"h":8,"i":this.count, 
                            "type":"richText",
                            "val": payload.val,
                            "bl_title": false,
                            static: false
                        }
                        this.elements.push(obj)
                    break
                    case 'btn':
                        this.count++
                        obj = {
                            "x":0,"y":0,"w":5,"h":8,"i":this.count, 
                            "type":"btn",
                            "btns": payload.btns,
                            "numPerRow": payload.numPerRow, 
                            "align": payload.align, 
                            "title": payload.title,
                            "bl_title": false,
                            "static": false
                        }
                        this.elements.push(obj)
                    break
                    case 'embeddedUrl':
                        this.count++
                        obj = {
                            "x":0,"y":0,"w":5,"h":8,"i":this.count, 
                            "type":"embeddedUrl",
                            "iframeUrl": payload.iframeUrl,
                            "bl_title": false,
                            "static": false
                        }
                        this.elements.push(obj)
                    break
                }
            }
        }
    }
}
</script>
