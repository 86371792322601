<template>
   <div class="empty-block d-flex flex-row justify-start">
      <div class="block-prepend block-connect d-flex flex-column"></div>
      <div class="d-flex flex-row empty-content-block-wrapper zoomable">
          <div class="content-block">
          </div>
      </div>
      <div class="block-append block-connect d-flex flex-column"></div>
  </div>
</template>

<script>
export default {
  name: 'emptyBlock'
}
</script>

<style>
  .empty-block .content-block {
    width: 12rem;
    max-height: 6rem;
  }
  .empty-block {
    background-color: transparent;
    /*background-color: lightgoldenrodyellow;;*/
    width: 100%;
  }
  .empty-block .block-prepend {
    border: 1px solid transparent;
  }
  .empty-block .block-append {
    border: 1px solid transparent;
  }
</style>
