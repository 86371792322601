<template>
  <v-card :hover="true" rounded class="ma-0 panel-card d-flex align-stretch flex-row justify-start cursor-move data-block-card"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
          :class="{'align-stretch':cardConfig.cardImageMode==='contain' || cardConfig.cardImageMode==='cover'}">

    <!-- LEFT IMAGE FOR WHOLE COLUMN -->
    <card-image
      v-if="leftImage && stretchImage"
      :cardConfig="cardConfig"
      :mediaId="mediaId"></card-image>

    <div class="d-flex flex-column flex-grow-1" style="overflow-x: hidden; position: relative; width:100%">
      <!-- TOP IMAGE FOR WHOLE ROW -->
      <card-image
        v-if="topImage"
        :cardConfig="cardConfig"
        :mediaId="mediaId"></card-image>

      <v-card-title class="line-height-1 px-2 pt-2 mb-1 pb-0">
        <div class="d-flex flex-row flex-start">
          <card-image
            v-if="leftImage&&!stretchImage"
            :cardConfig="cardConfig"
            :mediaId="mediaId"></card-image>

          <div class="d-flex flex-column">
          <field-string v-if="titleFieldInfo"
            style="height:auto;"
            :fieldInfo="titleFieldInfo"
            :recordData="card"></field-string>
          <div v-else>{{ title }}</div>

          <!-- Summary Field -->
            <v-row v-if="summaryContent"
              class="my-1 text-caption"
              :class="isSummaryOptionSelection?'':'card-summary-height-restricted'">
              <v-col class="py-0">
                <field-string :fieldInfo="summaryFieldInfo"
                              :textOnly="false"
                              :recordData="card"></field-string>
              </v-col>
            </v-row>
          </div>

          <card-image
            v-if="rightImage&&!stretchImage"
            :cardConfig="cardConfig"
            :mediaId="mediaId"></card-image>

        </div>
      </v-card-title>

      <v-card-text class="px-2 d-flex flex-column">

        <hr class="section-line"/>

        <v-row class="card-field"
               v-for="fieldInfo in cardDisplayFieldInfos"
               :key="fieldInfo.fieldId">
          <card-field :view="view"
                          :card="card"
                          :fieldInfo="fieldInfo"></card-field>
        </v-row>

        <!--<v-row class="card-field"-->
        <!--v-for="cardField in cardFields"-->
        <!--:key="cardField.fieldId">-->
        <!--<v-col class="d-flex flex-row align-center">-->
        <!--<label>{{ cardField.fieldName }}</label>-->
        <!--<card-display-field-->
        <!--:field="cardField"></card-display-field>-->
        <!--</v-col>-->
        <!--</v-row>-->

      </v-card-text>
      <card-buttons
          v-if="hovering"
          class="panel-card-buttons"
          @onCommand="onCommandHandler"></card-buttons>

    </div>
    <card-image
      v-if="rightImage&&stretchImage"
      :cardConfig="cardConfig"
      :mediaId="mediaId"></card-image>
<!--

    <v-img
        class="col-image card-image"
        :class="{'circle-image':cardConfig.cardImageMode==='circle','square-image':cardConfig.cardImageMode==='square'}"
        v-if="rightImage"
        :width="cardConfig.cardImageSize"
        :style="imageStyle"
        :contain="cardConfig.cardImageMode!=='cover'"
        :src="imageSrc"></v-img>-->
        
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </v-card>
</template>
<script>
import helpers from '@/helpers'
import mediaHelperMixin from '@/mixins/mediaHelperMixin'
import fieldHelperMixin from '@/mixins/fieldHelperMixin'
import cardButtons from '../cardBoard/CardButtons'
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import cardField from '../cardBoard/CardField'
import fieldString from '@/components/fieldStrings/Fs'
import zoomPreview from '@/components/zoomPreview'
import cardImage from './CardImage'

export default {
  name: 'dataBlockCard',
  mixins: [
    mediaHelperMixin,
    fieldHelperMixin
  ],
  components: {
    cardButtons,
    confirmDeleteDialog,
    cardField,
    zoomPreview,
    fieldString,
    cardImage
  },
  props: {
      dragging: Boolean,
      cardConfig: Object,
      titleFieldId: String,
      fieldInfos: {
          type: Array,
          default () {
              return []
          }
      },
        card: Object
  },
  data () {
    return {
      hovering: false
    }
  },
  methods: {
    onMouseEnter () {
      this.hovering = true
    },
    onMouseLeave () {
      this.hovering = false
    },
    onCommandHandler (payload) {
      const vm = this
      console.log('DataBlockCard :: onCommandHandler :: payload: ', payload)
      switch (payload.command) {
        case 'delete':
          vm.$refs.confirmDeleteDialog.confirm(() => {
            console.log('DataBlockCard :: confirmDeleteDialog :: confirm and callback')
            vm.$emit('onCommand', {
              command: 'deleteRecord',
              card: vm.card
            })
          })
          break

        case 'view':
          vm.$emit('onCommand', {
            command: 'viewRecord',
            card: vm.card
          })
          break

        default:
          vm.$emit('onCommand', {
            command: payload.command + 'Record',
            card: vm.card
          })
      }
    },
    getFieldName (id, idType) {
      const vm = this
      var result = ''
      if (typeof idType === 'undefined') {
        idType === 'fieldId'
      }
      const fieldInfo = idType === 'fieldId' ?
        vm.fieldInfos.find(info => info.fieldId === id) :
        vm.fieldInfos.find(info => info._id === id)
      if (fieldInfo) {
        result = vm.getFieldNameByInfo(fieldInfo)
      }
      return result
    },
    getFieldNameByInfo (fieldInfo) {
      const vm = this
      var result = ''
      const properties = fieldInfo.properties
      const propertyKeys = Object.keys(properties)
      result = propertyKeys.indexOf('fieldNameTag') >= 0 ?
        vm.$t(properties['fieldNameTag']) :
        properties['fieldName']
      return result
    },
    getFieldNameByInfoId (infoId) {
      return this.getFieldName(infoId, 'infoId')
    },
    getFieldNameByFieldId (fieldId) {
      return this.getFieldName(fieldId, 'fieldId')
    },
  },

  computed: {
    cardDisplayFieldInfos () {
      const vm = this
      let result = []
      if (vm.cardConfig && vm.cardConfig.cardDisplayFieldIds) {
        for (let i = 0; i < vm.cardConfig.cardDisplayFieldIds.length; i++) {
          const loopFieldId = vm.cardConfig.cardDisplayFieldIds[i]
          const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopFieldId)
          result.push(fieldInfo)
        }
      }
      return result
    },
    imageStyle () {
      const vm = this
      var result = {}
      result['width'] = vm.cardConfig.cardImageSize + 'px'

      if (vm.cardConfig.cardImagePadding && vm.cardConfig.cardImagePadding>0) {
        result['padding'] = vm.cardConfig.cardImagePadding + 'px'
      }
      return result
    },
    isSummaryOptionSelection () {
      const vm = this
      var result = false
      if (vm.summaryFieldInfo) {
        result = vm.summaryFieldInfo.type === 'singleSelection' || vm.summaryFieldInfo.type === 'multipleSelection'
      }
      return result
    },
    summaryFieldInfo () {
      const vm = this
      var result = null
      if (vm.cardConfig.cardSummaryFieldId && vm.cardConfig.cardSummaryFieldId !== '') {
        return this.fieldInfos.find(info => info.fieldId === vm.cardConfig.cardSummaryFieldId)
      }
      return result
    },
    summaryContent () {
      const vm = this
      var result = ''
      if (vm.cardConfig.cardSummaryFieldId !== '') {
        result = vm.card[vm.cardConfig.cardSummaryFieldId]
      }
      return result
    },
    titleFieldInfo () {
      const vm = this
      var result = null
      if (vm.titleFieldId !== '') {
        return vm.fieldInfos.find(info => info.fieldId === vm.titleFieldId)
      }
      return result
    },
    title () {
        return this.card[this.titleFieldId]
    },
    mediaId () {
      const vm = this
      var result = ''
      if (vm.card && vm.cardConfig.cardImageFieldId &&
        (vm.cardConfig.cardImageFieldId !== '' && vm.card[vm.cardConfig.cardImageFieldId]) &&
        (vm.card[vm.cardConfig.cardImageFieldId].length > 0)
      ) {
        result = vm.card[vm.cardConfig.cardImageFieldId][0].mediaId
      }
      return result
    },

            hasCardImage () {
            return this.cardConfig.cardImageFieldId && this.cardConfig.cardImageFieldId !== ''
        },

        topImage () {
            return this.hasCardImage && this.cardConfig.cardImagePosition === 'top'
        },

        leftImage () {
            return this.hasCardImage && this.cardConfig.cardImagePosition === 'left'
        },

        rightImage () {
            return this.hasCardImage && this.cardConfig.cardImagePosition === 'right'
        },

        stretchImage () {
            return this.hasCardImage && this.cardConfig.cardImageStretch
        }

  }
}
</script>

<style>
  /*.panel-card:hover .panel-card-buttons {*/
    /*display: block;*/
  /*}*/
  .panel-card {
    width: 100%;
  }
  .panel-card .panel-card-buttons {
    position: absolute;
    top: 10px;
    right: 2px;
    /*display: none;*/
  }
/* .col-image {
     width: 80px;
     max-width: 80px;
   }*/



  .card-summary-height-restricted {
    max-height: 36px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    line-height: 1 !important;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .card-field.row {
    margin: 0;
  }
  .panel-card .section-line {
    margin-bottom: 5px;
    border-color: rgba(0,0,0,.05);
  }

  .panel-card .v-card__text {
    padding-bottom: 4px;
  }

 .data-block-card .field-string {
  height: auto;
}  

  .card-image {
    background-color: rgba(0, 0, 0, .05);
  }

  .circle-image {
/*     width: 80px !important;
    height: 80px !important; */
    border-radius: 50% !important;
  }

  .square-image {
/*     width: 80px !important;
    height: 80px !important;
 */    border-radius: 0 !important;
  }

  .data-block-card {
    min-height: 120px;
  }

</style>
