<template>
    <div>
        <v-layout align-start justify-space-between class="">
            <div class="workflow-setting-condition-select-root">
                <ComboBox
                    :value="rule || null"
                    :options="rules.options"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    :renderLabel="renderConditionLabel"
                    @changed="v => rule = v"
                />
            </div>
            <div class="workflow-setting-columnType-select-root">
                <ComboBox
                    :value="type || null"
                    :options="types"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    @changed="v => type = v"
                />
            </div>
        </v-layout>
        <div class="workflow-setting-columnValue-select-root">
            <div v-if="content.type===1">
                <v-text-field
                    class="workflow-setting-input-root"
                    v-model="value"
                    v-if="!disableInput"
                    :placeholder="$t('messages.pleaseInput', [])"
                    outlined
                    height="36"
                ></v-text-field>
                <SelectableText
                    v-else
                    :value="value"
                    :placeholder="$t('messages.pleaseInput', [])"
                    @changed="v => value = v"
                    @remove="remove(row, column)"
                    :selectInputProps="selectInputProps"
                    :fieldProperties="fieldProperties"
                />
                <!-- <div v-else>

                    <ComboBox
                        :value="value || null"
                        :placeholder="$t('messages.pleaseInput')"
                        @changed="v => value = v"
                        v-bind="selectInputProps"
                    />
                    <v-btn icon @click="remove(row, column)" class="workflow-filter-icon-hints">
                        <v-icon small>mdi-exclamation-thick</v-icon>
                    </v-btn>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small>
                                mdi-exclamation-thick
                            </v-icon>
                        </template>
                        <span>{{ $t('workflow.modules.form.hints.inputOrGate') }}</span>
                    </v-tooltip>
                </div> -->
            </div>
            <RuleSelectField
                v-else
                :value="value || null"
                :dependsOnType="fieldType"
                :comboxProps="comboxProps"
                :formFieldInfos="formFieldInfos"
                @changed="v => value = v"
                :fieldProperties="fieldProperties"
            />
        </div>
    </div>
    <!-- <div class=" d-flex mt-1">
        <div>
    <div class="d-flex mt-1">
        <div style="width: 80px">
            <v-select
                v-model="rule"
                class="vselect-small"
                style="font-size:12px"
                height="30"
                dense
                outlined
                :items="rules.options"
                :menu-props="{offsetY: true}"
                hide-details
            >
                <template v-slot:item="{item}" style="">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
                <template v-slot:selection="{item}">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
            </v-select>
        </div>
        <div style="width:120px" class="ml-2" v-if="rule!='empty' && rule!='notEmpty'">
            <v-select
                class="vselect-small workflow-select"
                height="30"
                style="font-size:12px"
                dense
                outlined
                :items="types"
                item-text="name"
                item-value="id"
                :menu-props="{offsetY: true}"
                single-line
                v-model="type"
                hide-details
            />
        </div>
        <div v-if="rule!='empty'&&rule!='notEmpty'">
            <v-text-field hide-details v-model="value" v-if="type==1" placeholder="請輸入" outlined height="30" class="ml-2"></v-text-field>
            <RuleSelectField v-else :value="value" :formFieldInfos="formFieldInfos" @input="val=>value=val" class="ml-2"/>
        </div>
    </div> -->
</template>

<script>

import RuleSelectField from '../units/RuleSelectField'
import mixin from './mixin'
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import SelectableText from './SelectableText';
export default {
    mixins: [mixin],
    components:{
        RuleSelectField,
        ComboBox,
        SelectableText,
    },
}
</script>

<style>
    /* .v-list.v-select-list{
        padding: 0px !important;
        font-size:12px;
    } */
    /* .v-list-item{
        min-height: 25px !important;
    }
    .v-list-item{
        color: red!important;
    } */
/* .vselect-small .v-select-select option{
    color: red
} */
</style>