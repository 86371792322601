<template>
  <div>
    <DataSourceSelect
      :value="setting.dataSource"
      :label="$t('workflow.modules.updateRecord.label.selectDataSource')"
      name="dataSource"
      :fetchByNode="true"
      :normalize="normalize"
      :placeholder="$t('workflow.modules.updateRecord.placeholder.currentNodeOutput')"
    />
    <v-alert
      border="left"
      colored-border
      v-if="missingFields.length"
      class="workflow-setting-updateRecord-missing-setting"
      type="warning"
      elevation="1"
    >
      <v-layout align-center justify-space-between>
        {{ $t('workflow.modules.updateRecord.message.missingFields', { size: missingFields.length }) }}
        <v-btn
          small
          icon
          color="primary"
          class="updateRecord-missing-setting-btn"
          @click="removeMissing"
        >{{ $t('workflow.btn.delete') }}</v-btn>
      </v-layout>
    </v-alert>
    <DataRow
      v-if="setting.dataSource && !loading"
      :dataSource="setting.dataSource"
      :worksheet="worksheet"
      :missingFields="missingFields"
      :missingFieldsSize="missingFields.length"
      :value="data"
      :fields="allFields"
    />
  </div>
</template>

<script>
import { filter, forEach, find, get } from 'lodash';
import DataSourceSelect from './../shared/dataSourceSelect';
import DataRow from './row';
import Mixin from './../mixin';
import RecordMxin from '@/mixins/recordMixin';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin, RecordMxin],
  components: {
    DataSourceSelect,
    DataRow,
  },
  data(){
    return {
      loading:false
    }
  },
  computed: {
    worksheet() {
      return this.getWorksheet() || this.dataSource.form;
    },
    allFields() {
      return this.$store.getters.getFormSelectionsFields.children || [];
      // return [];
    },
    missingFields() {
      let missing = [];
      forEach(this.data, (v, id) => {
        if (id && id !== '_addField_') {
          const existing = find(this.allFields, ({ _id }) => _id === id);
          if (!existing) {
            missing = [ ...missing, id ];
            // this.$delete(this.data, id);
          }
        }
      });
      return missing;
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchFieldInfo();
    this.loading = false
  },
  methods: {
    getWorksheet() {
      const { dataSource } = this.setting;
      return get(dataSource, 'form');
    },
    removeMissing() {
      if (this.missingFields.length) {
        forEach(this.missingFields, id => {
          this.$delete(this.data, id);
        });
      }
    },
    normalize(list=[]) {
      const target = filter([...list], ({ form }) => !!form);
      // !includes([
      //   'ARGUMENT_',
      // ], fieldType) && !virtual);
      return target;
    }
  },
  watch: {
    "dataSource.form": {
       handler(v) {
        if (v) {
           this.fetchFieldInfo();
        }
      }
    },
  }
};

</script>

<style scoped lang="scss">
  .workflow-setting-updateRecord-missing-setting {
    font-size: 12px;
    color: #666;
    margin-top: 20px;
    margin-right: 3px;
    .updateRecord-missing-setting-btn {
      margin-right: 20px;
    }
  }
</style>
