import { Quill } from "vue-quill-editor";
var InlineBlot = Quill.import('blots/block');

class ImageBlot extends InlineBlot {
  static create(data) {
    const node = super.create(data);
    node.setAttribute('data-media-id', data.mediaId || '');
    node.setAttribute('src', data.src);
    node.setAttribute('data-object-key', data.objectKey || '');
    node.setAttribute('data-original-name', data.originalName || '');
    node.setAttribute('data-media-type', data.mediaType || '');
    return node;
  }
  static value(domNode) {
    // console.log('value: domNode.dataset: ', domNode.dataset);
		// const { mediaId, src, objectKey, originalName, mediaType } = domNode.dataset;
		// return { mediaId, src, objectKey, originalName, mediaType };
    return domNode.dataset;
	}
}

ImageBlot.blotName = 'imageBlot';
ImageBlot.className = 'image-blot';
ImageBlot.tagName = 'img';

// Quill.register({ 'formats/imageBlot': ImageBlot});

export default ImageBlot
