<template>
  <v-dialog
    v-model="showingDialog"
    transition="dialog-bottom-transition"
    width="840"
    persistent
    scrollable
  >
    <v-card tile>
      <DialogHeader :label="$t('roles.setting')" @close="showingDialog = false" />
      <v-card-text>
        <v-list three-line subheader>
          <v-subheader>{{$t('roles.general')}}</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('roles.name')}}</v-list-item-title>
              <v-text-field dense hide-details outlined v-model="role.label" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('roles.description')}}</v-list-item-title>
              <v-textarea
                rows="3"
                v-model="role.description"
                outlined
                hide-details
              ></v-textarea>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list subheader>
          <v-subheader>{{$t('roles.permission')}}</v-subheader>
          <v-list-item v-if="role&&role.calendars&&Object.keys(role.calendars).length > 0">
            <CalendarPermissionTable :calendars="role.calendars" />
          </v-list-item>
          <v-list-item v-if="role&&role.panels&&Object.keys(role.panels).length > 0">
            <PanelPermissionTable :panels="role.panels" />
          </v-list-item>
          <v-list-item>
            <v-switch
              v-model="applyToAll"
              dense
              :label="$t('roles.applyToAll')"
              hide-details
              @change="onApplyToAllChange"
            />
          </v-list-item>
          <v-list-content v-if="applyToAll">
            <v-list-item>
              <v-select
                outlined
                dense
                v-model="role.permission"
                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                :items="generalPermissionOptions"
                hide-details
              >
              </v-select>
            </v-list-item>
            <v-list-item v-if="role.permission ===60 || role.permission ===40 ">
              <v-checkbox
                dense
                :label="$t('roles.extendToSub')"
                v-model="role.extendToSubordinates"
              />
            </v-list-item>
            <v-list-item v-if="role.permission ===60 || role.permission ===40 ">
              <v-checkbox
                dense
                :label="$t('roles.allowEditForm')"
                v-model="role.allowEditForm"
              />
            </v-list-item>
          </v-list-content>
          <v-list-item v-else>
            <div class="d-flex flex-column w-100">
              <PermissionTable :forms="role.forms" />
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
      <DialogActions @onCommand="onCommandHandler" :buttons="['cancel', 'save']"/>
    </v-card>
  </v-dialog>
</template>
<script>
import { get } from "lodash";

import DialogHeader from "@/components/dialogs/comps/DialogHeader";
import DialogActions from "@/components/dialogs/comps/DialogActions";
import PermissionTable from "../comps/PermissionTable";
import CalendarPermissionTable from "../comps/CalendarPermissionTable";
import PanelPermissionTable from '../comps/PanelPermissionTable'

export default {
  props: {
    roleId: String,
    value: Boolean,
    isNew: Boolean,
  },
  components: {
    PermissionTable,
    CalendarPermissionTable,
    PanelPermissionTable,
    DialogHeader,
    DialogActions,
  },
  data() {
    return {
      role: {
        label: '',
        description: '',
        calendars: null,
        panels: null,
        forms: null,
        permission: 0,
        extendToSubordinates: false,
        allowEditForm: false
      },
      //applyToAll: false,
      generalPermissionOptions: [
        {
          text: this.$t(`roles.PERMISSION_LEVEL_80`),
          value: 80,
        },
        {
          text: this.$t(`roles.PERMISSION_LEVEL_60`),
          value: 60,
        },
        {
          text: this.$t(`roles.PERMISSION_LEVEL_40`),
          value: 40,
        },
        {
          text: this.$t(`roles.PERMISSION_LEVEL_20`),
          value: 20,
        },
      ],
    };
  },
  computed: {
    showingDialog: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
    applyToAll() {
      return this.role.permission !== 50;
    },
    appName(){
      return get(this.$store.getters.currentApp, "title", "");
    },
    appId(){
      return get(this.$store.getters.currentApp, "_id", "");
    }
  },
  mounted() {
    if (this.isNew) {
      this.getTemplate();
    } else {
      this.getRole();
    }
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "save":
          this.submit();
          break;
        case "cancel":
          this.showingDialog = false;
          break;
      }
    },
    onApplyToAllChange(val) {
      if (val === false) {
        this.role.permission = 50;
      } else {
        this.role.permission = 60;
      }
      this.$sensors.track("plus_authorization_add_role", {
        app_id:this.appId,
        action: this.$sensors.events.plus_authorization_add_role[val?"APPLICATE":"UNAPPLICATE"],
        module_name: this.appName
      })
    },
    getTemplate() {
      const appId = this.$route.params.id;
      if (appId) {
        const getParams = {
          urlCommand: `/role/getRoleTemplate?id=${appId}`,
        };
        this.$store.dispatch("AUTH_GET", getParams).then((res) => {
          this.role = { ...this.role, ...res };
        });
      }
    },
    getRole() {
      const getParams = {
        urlCommand: `/role/getRoleDetail?id=${this.roleId}&appId=${this.$route.params.id}`,
      };
      this.$store.dispatch("AUTH_GET", getParams).then((res) => {
        this.role = { ...this.role, ...res };
      });
    },
    submit() {
      if (this.isNew) {
        this.$emit("create", this.role);
      } else {
        this.$emit("update", this.role);
      }
      this.$sensors.track("plus_authorization_add_role", {
        app_id:this.appId,
        action: this.$sensors.events.plus_authorization_add_role.SAVE,
        module_name: this.appName
      })      
      this.showingDialog = false;
    },
  },
};
</script>
<style scoped></style>
