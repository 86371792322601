import {formConfig as types} from "../../mutation-types";
import defaultState from "./state";
import Vue from "vue";
import {app} from "@/main";
import {findFormItemIndex, getNextFieldId, getFieldInfoById} from "./helpers";
import {getWidgetPropertyValues} from "@/helpers/FormHelpers";
import {systemFieldIds }from "@/const/fieldConstants";

const removeEmbeddedPagingItem = (state, fieldInfoId) => {
  var index = -1;
  index = state.formLayout.embeddedPagingTables.findIndex(
    (item) => item._id === fieldInfoId
  );
  if (index >= 0) state.formLayout.embeddedPagingTables.splice(index, 1);
};

const removeTopPagingItem = (state, fieldInfoId) => {
  var index = -1;
  index = state.formLayout.topPagingTables.findIndex(
    (item) => item._id === fieldInfoId
  );
  if (index >= 0) state.formLayout.topPagingTables.splice(index, 1);
};

const removeLayoutItem = (state, fieldInfoId) => {
  const indexPair = findFormItemIndex(state.formLayout.rows, fieldInfoId);
  if (indexPair) {
    state.formLayout.rows[indexPair.i].splice(indexPair.j, 1);
    if (state.formLayout.rows[indexPair.i].length === 0) {
      state.formLayout.rows.splice(indexPair.i, 1);
    }
  }
};

const appendLayoutItem = (state, fieldInfoId) => {
  const indexPair = findFormItemIndex(state.formLayout.rows, fieldInfoId);
  if (indexPair) {
  } else {
    state.formLayout.rows.push([
      {
        _id: fieldInfoId,
        controlType: "data",
      },
    ]);
  }
};

const getParentChildRelatedFieldInfoIds = (state, fieldInfo) => {
  const fieldId = fieldInfo.fieldId
  var result = []
  if (state.formInfo.linkedFields && state.formInfo.linkedFields.length > 0) {
    var relatedFieldIds = []
    for (let i = 0; i < state.formInfo.linkedFields.length; i++) {
      const loopLinkedFieldIds = state.formInfo.linkedFields[i].fieldIds
      if (loopLinkedFieldIds.includes(fieldId)) {
        relatedFieldIds = loopLinkedFieldIds
        break
      }
    }
    const relatedFieldInfos = state.formFieldInfos.filter(info => relatedFieldIds.includes(info.fieldId))
    if (relatedFieldInfos.length > 0) {
      result = relatedFieldInfos.map(info => info._id)
    }
  }
  return result
}

const getAllLinkedFieldInfoIds = (state, fieldInfoId) => {
  var result = [fieldInfoId]
  console.log('getAllLinkedFieldInfoIds :: fieldInfoId = ' + fieldInfoId)
  const fieldInfo = state.formFieldInfos.find(info => info._id === fieldInfoId)

  // Check parent-child records
  if (fieldInfo && (fieldInfo.type === 'relatedRecord' || fieldInfo.type === 'relatedMultipleRecords')) {
    const linkedFieldIds = getParentChildRelatedFieldInfoIds(state, fieldInfo)
    if (linkedFieldIds.length > 0) {
      result = linkedFieldIds
    }
  }
  return result
};

const getFieldInfosByType = (fieldInfos, fieldTypes) => {
  if (!Array.isArray(fieldTypes)) {
    fieldTypes = [fieldTypes]
  }
  var result = []
  for (let i = 0; i < fieldInfos.length; i++) {
    const loopInfo = fieldInfos[i]
    if (fieldTypes.includes(loopInfo.type)) {
      result.push(loopInfo)
    }
  }
  return result
}

const removeFromInfo = (state, fieldInfoId, controlType) => {
  if (controlType === "data") {
    // check and remove from fieldInfos
    const formFieldInfosIndex = state.formFieldInfos.findIndex(
      (item) => item._id === fieldInfoId
    );
    if (formFieldInfosIndex !== -1)
      state.formFieldInfos.splice(formFieldInfosIndex, 1);
  } else {
    // check and remove from UIElementInfos
    const formUIIndex = state.formUIElementInfos.findIndex(
      (item) => item._id === fieldInfoId
    );
    if (formUIIndex !== -1)
      state.formUIElementInfos.splice(formUIIndex, 1);
  }

  // update title field info id if title field is removed
  if (state.formInfo.titleFieldInfoId === fieldInfoId) {
    state.formInfo.titleFieldInfoId = "";
    const fieldInfosOfText = getFieldInfosByType(state.formFieldInfos, 'text')
    if (fieldInfosOfText.length >0) {
      state.formInfo.titleFieldInfoId = fieldInfosOfText[0]._id
    } else {
      const fieldInfosOfNumber = getFieldInfosByType(state.formFieldInfos, ['number', 'amount'])
      if (fieldInfosOfNumber.length > 0) {
        state.formInfo.titleFieldInfoId = fieldInfosOfNumber[0]._id
      }
    }
  }

  // if the remove field is being selected, set the first field as selected
  if (state.selectedFormFieldId === fieldInfoId) {
    const nonSystemFieldInfos = state.formFieldInfos.filter(info =>
      !['owner','createdBy','createdAt','updatedAt'].includes(info.fieldId)
    )
    state.selectedFormFieldId =
      nonSystemFieldInfos.length > 0
        ? nonSystemFieldInfos._id
        : null;
  }
}

const removeFromLayout = (state, fieldInfoId) => {
  var controlType = 'data'
  var found = false
  if (state.formLayout) {
    for (let i = 0; i < state.formLayout.rows.length; i++) {
      const row = state.formLayout.rows[i];
      const rowIndex = row.findIndex((item) => item._id == fieldInfoId);
      if (rowIndex !== -1) {
        controlType = row[rowIndex].controlType
        state.formLayout.rows[i].splice(rowIndex, 1);
        if (state.formLayout.rows[i].length === 0) {
          state.formLayout.rows.splice(i, 1);
        }
        found = true;
        break;
      }
    }
    for (let i = 0; i < state.formLayout.topPagingTables.length; i++) {
      const loopTable = state.formLayout.topPagingTables[i]
      if (loopTable._id === fieldInfoId) {
        state.formLayout.topPagingTables.splice(i, 1)
        found = true
        break
      }
    }
    for (let i = 0; i < state.formLayout.embeddedPagingTables.length; i++) {
      const loopTable = state.formLayout.embeddedPagingTables[i]
      if (loopTable._id === fieldInfoId) {
        state.formLayout.embeddedPagingTables.splice(i,1)
        found = true
        break
      }
    }
  }
  return {
    found: found,
    controlType: controlType
  }
}

const addEmbeddedPagingItem = (state, fieldInfoId) => {
  var index = -1;
  index = state.formLayout.embeddedPagingTables.findIndex(
    (item) => item._id === fieldInfoId
  );
  if (index === -1) {
    state.formLayout.embeddedPagingTables.push(
      {
        _id: fieldInfoId,
        controlType: "data",
      },
    );
  }

  console.log('addEmbeddedPagingItem :: state.formLayout.embeddedPagingTables.push: _id: ' + fieldInfoId)
  console.log('addEmbeddedPagingItem :: state.formLayout.embeddedPagingTables.push: controlType: data')
  console.log('addEmbeddedPagingItem :: state.formLayout.embeddedPagingTables: ', state.formLayout.embeddedPagingTables)
  
};

const addTopPagingItem = (state, fieldInfoId) => {
  var index = -1;
  index = state.formLayout.topPagingTables.findIndex(
    (item) => item._id === fieldInfoId
  );
  if (index === -1) {
    state.formLayout.topPagingTables.push(
      {
        _id: fieldInfoId,
        controlType: "data",
      },
    );
  }
};
const createFieldInfo = (state, newFieldInfo, newLayoutItem, options) => {
  console.log('createFieldInfo :: state: ', state)
  console.log('createFieldInfo :: newFieldInfo: ', newFieldInfo)
  console.log('createFieldInfo :: newLayoutItem: ', newLayoutItem)
  console.log('createFieldInfo :: options: ', options)
  const isUIElement = newFieldInfo.type.indexOf("_") === 0;

  console.log('createFieldInfo :: isUIElement: ', isUIElement)
  const propertyValues = newFieldInfo.properties;
  if (isUIElement) {
    state.formUIElementInfos.push(newFieldInfo);
  } else {
    state.formFieldInfos.push(newFieldInfo);
  }
  var positioned = false;
  if(newFieldInfo.type=='relatedRecord'){
    
    newFieldInfo.properties.twoWayRelated = '0'
  }
  console.log('createFieldInfo :: newFieldInfo.type = ' + newFieldInfo.type)
  if (newFieldInfo.type === "relatedMultipleRecords") {
    console.log('createFieldInfo :: is relatedMultipleRecords')
    if (propertyValues.usePaging === 'yes') {
      console.log('createFieldInfo :: usePaging = true')
      if (propertyValues.pagingPosition === "embedded") {
        console.log('createFieldInfo :: usePaging = embedded')
        state.formLayout.embeddedPagingTables.push(newLayoutItem);
      } else {
        console.log('createFieldInfo :: usePaging = NOT embedded')
        state.formLayout.topPagingTables.push(newLayoutItem);
      }
      positioned = true;
    } else {
      console.log('createFieldInfo :: usePaging = false')
    }
  }


  if (!positioned) {
    console.log('createFieldInfo :: not positioned')
    if (options) {
      if (options.hasOwnProperty("rowIndex")) {
        console.log('createFieldInfo :: has rowIndex')
        state.formLayout.rows[options.rowIndex].splice(
          options.index,
          0,
          newLayoutItem
        );
      } else if (options.hasOwnProperty('index')) {
        console.log('createFieldInfo :: has no rowIndex but with index = ' + options.index)
        state.formLayout.rows.splice(options.index, 0, [newLayoutItem]);
      } else {
        state.formLayout.rows.push([newLayoutItem])
      }
    } else {
      state.formLayout.rows.push([newLayoutItem])
    }
  }
};

const getWidgetPropertyDefault = (allWidgets, widgetType, propertyKey, propertyValue, defaultValue) => {
  var result = defaultValue
  const widget = allWidgets.find(widget => widget.key === widgetType)
  const property = widget.properties.find(p => p.propertyKey === propertyKey)
  if (property && property.default) {
    result = property.default
  }
  return result
}

const removeObsolatePagingTables = (state, pagingTableInfoIds) => {
  state.formLayout.topPagingTables = state.formLayout.topPagingTables ?
    state.formLayout.topPagingTables.filter(
      table => pagingTableInfoIds.includes(table._id)
    ) :
    []
  state.formLayout.embeddedPagingTables = state.formLayout.embeddedPagingTables ?
    state.formLayout.embeddedPagingTables.filter(
      table => pagingTableInfoIds.includes(table._id)
    ) :
    []
}

const checkAndUpdatePagingTables = (state, loopInfo) => {
  if (loopInfo.properties.pagingPosition === 'atTop') {
    const index = state.formLayout.topPagingTables.findIndex(table => table._id === loopInfo._id)
    if (index === -1) {
      addTopPagingItem(state, loopInfo._id)
    }
  } else {
    const index = state.formLayout.embeddedPagingTables.findIndex(table => table._id === loopInfo._id)
    if (index === -1) {
      addEmbeddedPagingItem(state, loopInfo._id)
    }
  }

}
const initFieldInfo = (widget, widgetProperties) => {
  const isUIElement = widget.key.indexOf("_") === 0;
  const fieldId = isUIElement ? "" : getNextFieldId(state.formFieldInfos);
  const tempId = "_" + Date.now().toString();
  const label = app.$i18n.t("widgets." + widget.labelTag);
  const propertyValues = getWidgetPropertyValues(
    widget,
    label,
    widgetProperties
  );

  const newFieldInfo = {
    _id: tempId,
    type: widget.key,
    label: label,
    labelTag: widget.labelTag,
    fieldId: fieldId,
    widgetId: widget._id,
    isSystem: widget.isSystem ? widget.isSystem : false,
    properties: propertyValues,
  };

  return newFieldInfo
}

export default {
  [types.SET_DATA_SOURCE] (state, payload) {
    // console.log('SET_DATA_SOURCE: payload: ', payload)
    state.dataSources = payload;
  },
  [types.ADD_DATA_SOURCES] (state, payload) {
    // console.log('ADD_DATA_SOURCES: payload: ', payload)
    // console.log('ADD_DATA_SOURCES: typeof state.dataSources = ' + (typeof state.dataSources))
    //
    // console.log('ADD_DATA_SOURCES: Array.isArray(state.dataSources) = ' + (Array.isArray(state.dataSources) ? 'yes' : 'no'))
    // console.log('ADD_DATA_SOURCES: Array.isArray(payload) = ' + (Array.isArray(payload) ? 'yes' : 'no'))
    // console.log('ADD_DATA_SOURCES: state.dataSources: ', state.dataSources)
    for (let i = 0; i < payload.length; i++) {
      const inputDataSource = payload[i]
      const index = state.dataSources.findIndex(ds => ds._id === inputDataSource._id)
      if (index >= 0) {
        state.dataSources[i] = inputDataSource
      } else {
        state.dataSources.push(inputDataSource)
      }
    }
    //
    // state.dataSources = state.dataSources.concat(payload);
    // console.log('ADD_DATA_SOURCES.then: state.dataSources: ', state.dataSources)
  },
  [types.REMOVE_DATA_SOURCES] (state, payload) {
    state.dataSources = state.dataSources.filter(
      (ds) => !payload.ids.includes(ds.dataTableId)
    );
    // console.log('REMOVE_DATA_SOURCES: state.dataSources: ', state.dataSources)
  },
  [types.SET_EDITING_CHILD_TABLE_INFO_ID] (state, payload) {
    state.editingChildTableInfoId = payload;
  },
  [types.SET_EDITING_CHILD_TABLE_FIELD_INFO_ID] (state, payload) {
    state.editingChildTableFieldInfoId = payload;
  },
  [types.SET_SELECTED_FORM_FIELD_ID] (state, payload) {
    state.selectedFormFieldId = payload;
  },

  [types.SWAP_PAGING_TABLES] (state, payload) {
    const oldIndex = payload.oldIndex
    const newIndex = payload.newIndex
    var item = null
    switch (payload.type) {
      case 'top':
        item = state.formLayout.topPagingTables.splice(oldIndex, 1)
        state.formLayout.topPagingTables.splice(newIndex, 0, item[0])
        break
      case 'embedded':
        console.log('SWAP_PAGING_TABLES: embeddedPagingTables: ', state.formLayout.embeddedPagingTables)
        item = state.formLayout.embeddedPagingTables.splice(oldIndex, 1)
        state.formLayout.embeddedPagingTables.splice(newIndex, 0, item[0])
        console.log('SWAP_PAGING_TABLES: embeddedPagingTables: ', state.formLayout.embeddedPagingTables)
        break
    }
  },

  [types.FIX_PAGING_TABLES] (state, payload) {
    var pagingTableInfoIds = []
    for (let i = 0; i < state.formFieldInfos.length; i++) {
      const loopInfo = state.formFieldInfos[i]
      if (
        (loopInfo.type === 'relatedRecord' && loopInfo.properties.relatedRecordQuantity==='multiple') ||
        (loopInfo.type === 'childTable')
       ) {
        if (loopInfo.properties.usePaging === 'yes') {
          pagingTableInfoIds.push(loopInfo._id)
          checkAndUpdatePagingTables(state, loopInfo)
        }
      }
    }
    removeObsolatePagingTables(state, pagingTableInfoIds)
  },
  [types.SET_FORM_FIELD_INFOS] (state, payload) {
    state.formFieldInfos = JSON.parse(JSON.stringify(payload));
  },
  [types.CLEAR_FORM_FIELD_INFOS] (state) {
    state.formFieldInfos = state.formFieldInfos.filter(info => systemFieldIds.includes(info.fieldId))
  },
  [types.SET_FORM_CHILD_TABLE_INFOS] (state, payload) {
    state.formChildTableInfos = JSON.parse(JSON.stringify(payload));
  },
  [types.SET_FORM_UI_ELEMENT_INFOS] (state, payload) {
    state.formUIElementInfos = JSON.parse(JSON.stringify(payload));
  },
  [types.SET_FORM_LAYOUT] (state, payload) {
    state.formLayout = JSON.parse(JSON.stringify(payload));
  },
  [types.SET_FORM_OPTIONS] (state, payload) {
    state.formOptions = JSON.parse(JSON.stringify(payload))
  },
  [types.ADD_FORM_OPTIONS] (state, payload) {
    console.log('mutations :: ADD_FORM_OPTIONS: payload: ', payload)
    if (payload.command) {
      if (state.formOptions['commands']) {
        state.formOptions['commands'].push(payload)
      } else {
        state.formOptions['commands'] = [payload]
      }
    }
  },
  [types.SET_FORM_INFO_PROPERTY] (state, payload) {
    if (Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        Vue.set(state.formInfo, item.propertyKey, item.propertyValue);  
      }
    } else {
      Vue.set(state.formInfo, payload.propertyKey, payload.propertyValue);
    }
  },
  [types.ADD_FORM_INFO_LINKED_FIELDS] (state, payload) {
    if (state.formInfo.linkedFields) {
      state.formInfo.linkedFields.push({
        fieldIds: payload
      })
    } else {
      state.formInfo['linkedFields'] = [{
        fieldIds: payload
      }]
    }
  },
  [types.REMOVE_FORM_INFO_LINKED_FIELDS] (state, payload) {
    const first = payload[0]
    if (state.formInfo.linkedFields) {
      const index = state.formInfo.linkedFields.findIndex(
        item => item.fieldIds.includes(first)
      )
      if (index >= 0) {
        state.formInfo.linkedFields.splice(index, 1)
      }
    }
  },
  [types.SET_FIELD_INFO_PROPERTY] (state, payload) {
    const propertyKey = payload.propertyKey;
    const propertyValue = payload.propertyValue;
    const fieldInfoId = payload.fieldInfoId;

    // console.log('SET_FIELD_INFO_PROPERTY :: payload.rootGetters: ', payload.rootGetters)
    // console.log('SET_FIELD_INFO_PROPERTY :: payload.rootGetters.widgets: ', payload.rootGetters.widgets)
    // find the fieldInfo in data-fieldinfo list
    var index = state.formFieldInfos.findIndex(
      (item) => item._id === fieldInfoId
    );
    const fieldInfo = state.formFieldInfos[index]
    // if found
    if (index >= 0) {
      const fieldProperties = state.formFieldInfos[index].properties;
      switch (propertyKey) {
        case "width":
          Vue.set(
            state.formFieldInfos[index].properties,
            propertyKey,
            parseInt(propertyValue)
          );
          break;
        case "fieldName":
          Vue.set(state.formFieldInfos[index].properties, "fieldName", propertyValue);
          Vue.set(state.formFieldInfos[index], 'label', propertyValue);
          break;
        case "usePaging":
          console.log('SET_FIELD_INFO_PROPERTY case usePaging: fieldProperties.pagingPosition = ' + fieldProperties.pagingPosition)
          console.log('SET_FIELD_INFO_PROPERTY case usePaging: fieldProperties.pagingPosition = ' + fieldProperties.pagingPosition)
          if (propertyValue === "yes") {
            let pagingPosition = getWidgetPropertyDefault(
              payload.rootGetters.allWidgets, 
              fieldInfo.type, 
              'pagingPosition', 
              'embedded'
            )
            console.log('SET_FIELD_INFO_PROPERTY case usePaging: pagingPosition: ' + pagingPosition)
            
            if (fieldProperties.pagingPosition) {
              pagingPosition = fieldProperties.pagingPosition
            } else {
              Vue.set(
                state.formFieldInfos[index].properties,
                'pagingPosition',
                pagingPosition
              );
            }

            console.log('SET_FIELD_INFO_PROPERTY case usePaging: pagingPosition (after fieldProperties.pagingPosition): ' + pagingPosition)

            if (pagingPosition === "atTop") {
              // clear instance embedded
              removeEmbeddedPagingItem(state, fieldInfoId);
              removeLayoutItem(state, fieldInfoId);
              addTopPagingItem(state, fieldInfoId);
            } else {
              console.log('SET_FIELD_INFO_PROPERTY case usePaging: fieldInfoId = ' + fieldInfoId)
              removeTopPagingItem(state, fieldInfoId);
              removeLayoutItem(state, fieldInfoId);
              addEmbeddedPagingItem(state, fieldInfoId);
            }
          } else {
            removeTopPagingItem(state, fieldInfoId);
            removeEmbeddedPagingItem(state, fieldInfoId);
            appendLayoutItem(state, fieldInfoId);
          }
          Vue.set(
            state.formFieldInfos[index].properties,
            propertyKey,
            propertyValue
          );
          break;
        case "pagingPosition":
          if (propertyValue === "atTop") {
            // clear instance embedded
            removeEmbeddedPagingItem(state, fieldInfoId);
            removeLayoutItem(state, fieldInfoId);
            addTopPagingItem(state, fieldInfoId);
          } else {
            console.log('formLayout.embeddedPagingTables.length = ' + state.formLayout.embeddedPagingTables.length)
            removeTopPagingItem(state, fieldInfoId);
            console.log('formLayout.embeddedPagingTables.length = ' + state.formLayout.embeddedPagingTables.length)
            removeLayoutItem(state, fieldInfoId);
            console.log('formLayout.embeddedPagingTables.length = ' + state.formLayout.embeddedPagingTables.length)
            addEmbeddedPagingItem(state, fieldInfoId);
          }
          Vue.set(
            state.formFieldInfos[index].properties,
            propertyKey,
            propertyValue
          );
          break;
        default:
          // console.log('SET_FIELD_INFO_PROPERTY : default: starts')
          Vue.set(
            state.formFieldInfos[index].properties,
            propertyKey,
            propertyValue
          );
          // console.log('SET_FIELD_INFO_PROPERTY : default: ends')
      }
    }

    // if not found in data-fieldinfos, try to find in ui-fieldinfos
    if (index === -1 && state.formUIElementInfos.length > 0) {
      index = state.formUIElementInfos.findIndex(
        (info) => info._id === fieldInfoId
      );
      if (index !== -1) {
        Vue.set(
          state.formUIElementInfos[index].properties,
          payload.propertyKey,
          payload.propertyValue
        );
        if(propertyKey=='fieldName')
          Vue.set(
            state.formUIElementInfos[index],
            'label',
            payload.propertyValue
          );
      }
    }

    if (index === -1 && state.formChildTableInfos.length > 0) {
      index = state.formChildTableInfos.findIndex(
        (info) => info._id === payload.fieldInfoId
      );
      if (index >= 0) {
        Vue.set(
          state.formChildTableInfos[index].properties,
          payload.propertyKey,
          payload.propertyValue
        );
      }
    }
    // console.log(
    //   "SET_FIELD_INFO_PROPERTY : found = " + (index !== -1 ? "yes" : "no")
    // );
  },
  [types.CLEAR_FORM_DATASOURCES] (state) {
    state.dataSources = []
  },
  [types.SET_FORM_LAYOUT_PROPERTY] (state, payload) {
    Vue.set(state.formLayout, payload.propertyKey, payload.propertyValue);
  },
  [types.ADD_CHILD_TABLE] (state, payload) {
    state.formChildTableInfos.push(payload);
  },
  [types.ADD_CHILD_TABLE_FIELD_INFO] (state, payload) {
    const newFieldInfo = payload.fieldInfo;
    console.log('ADD_CHILD_TABLE_FIELD_INFO: payload: ', payload)
    var fieldInfo = getFieldInfoById(state.formFieldInfos, payload.fieldInfoId);
    fieldInfo["properties"]["fieldsEditor"].fieldInfos.push(newFieldInfo);
    if (fieldInfo["properties"]["fieldsEditor"].fieldInfos.length > 0 &&
      !fieldInfo["properties"]["fieldsEditor"].titleFieldInfoId) {

      console.log('ADD_CHILD_TABLE_FIELD_INFO: set TitleFieldInfoId')
   
      fieldInfo["properties"]["fieldsEditor"].titleFieldInfoId = newFieldInfo._id
    } else {
      console.log('ADD_CHILD_TABLE_FIELD_INFO: not set TitleFieldInfoId')
    }

    // console.log('ADD_CHILD_TABLE_FIELD_INFO :: fieldInfo[properties][fieldsEditor]: ',
    //   fieldInfo['properties']['fieldsEditor'])
    if (payload.isFixed) {
      fieldInfo["properties"]["fieldsEditor"].fixedFieldIds.push(
        newFieldInfo.fieldId
      );
    } else {
      fieldInfo["properties"]["fieldsEditor"].nonFixedFieldIds.push(
        newFieldInfo.fieldId
      );
    }
  },
  // [types.INSERT_LAYOUT_ITEM_TO_ROW](state, payload) {
  //   //FIXME: to be implemented
  // },
  [types.INSERT_LAYOUT_ITEM] (state, payload) {
    if (state.formLayout && state.formLayout.rows) {

      console.log('mutation :: state.formLaout: ', state.formLayout)
      console.log('mutation :: payload.type = ' + payload.type)
      console.log('mutation :: payload.index = ' + payload.index)

      switch (payload.type) {
        // by drag in layout screen
        case "row":
          state.formLayout.rows.splice(payload.index, 0, payload.data);
          break;
        case "item":
          state.formLayout.rows.splice(payload.index, 0, [payload.data]);
          break;

        // by click or drag from widget list
        case "widget":
          const newFieldInfo = payload.data.newFieldInfo;
          const newLayoutItem = payload.data.newLayoutItem;
          if(newFieldInfo.properties && newFieldInfo.properties.placeholder){
            if(newFieldInfo.properties.placeholder!==""){
              newFieldInfo.properties.placeholder = app.$i18n.t(`widgetPlaceholder.${newFieldInfo.type}`)
            }
          }
          createFieldInfo(state, newFieldInfo, newLayoutItem, payload.options);
          //set selected
          state.selectedFormFieldId = newFieldInfo._id;
          break;
      }
    }
  },
  [types.INSERT_WIDGET_TO_ROW] (state, payload) {
    if (state.formLayout && state.formLayout.rows) {
      const widget = payload.data
      const isUIElement = widget.key.indexOf("_") === 0;

      const newFieldInfo = initFieldInfo(widget, state.widgetProperties)

      if (isUIElement) {
        state.formUIElementInfos.push(newFieldInfo);
      } else {
        state.formFieldInfos.push(newFieldInfo);
      }

      console.log('INSERT_WIDGET_TO_ROW : isUIElement = ' + (isUIElement ? 'yes' : 'no'))
      const newLayoutItem = {
        _id: tempId,
        controlType: isUIElement ? "static" : "data",
      };

      var positioned = false;
      if (widget.key === "relatedMultipleRecords") {
        if (propertyValues.usePaging === 'yes') {
          if (propertyValues.pagingPosition === "embedded") {
            state.formLayout.embeddedPagingTables.push(newLayoutItem);
          } else {
            state.formLayout.topPagingTables.push(newLayoutItem);
          }
          positioned = true;
        }
      }

      if (!positioned) {
        if (payload.rowIndex) {
          state.formLayout.rows[payload.rowIndex].splice(
            payload.index,
            0,
            newItem
          );
        } else {
          state.formLayout.rows.splice(payload.index, 0, [newLayoutItem]);
        }
      }

      return {newLayoutItem, newFieldInfo};
    }
  },
  [types.REMOVE_LAYOUT_ROW] (state, payload) {
    if (state.formLayout && state.formLayout.rows) {
      const firstCell = payload.length > 0 ? payload[0] : null;
      if (firstCell) {
        const index = state.formLayout.rows.findIndex(
          (row) => row[0]._id === firstCell._id
        );
        if (index !== -1) {
          state.formLayout.rows.splice(index, 1);
        }
      }
    }
  },

  [types.REMOVE_OBSOLATE_FIELD_INFOS] (state, payload) {
    state.formFieldInfos = state.formFieldInfos.filter(
      info => (payload.validFieldInfoIds.includes(info._id) || info.isSystem)

    )
    state.formUIElementInfos = state.formUIElementInfos.filter(
      info => (payload.validFieldInfoIds.includes(info._id) || info.isSystem)
    )
  },

  [types.REMOVE_LAYOUT_ITEM] (state, payload) {
    const item = payload;
    if (state.formLayout) {
      for (var i = 0; i < state.formLayout.rows.length; i++) {
        const row = state.formLayout.rows[i];
        for (var j = 0; j < row.length; j++) {
          if (row[j]._id === item._id) {
            state.formLayout.rows[i].splice(j, 1);
            break;
          }
        }
        if (state.formLayout.rows[i].length === 0) {
          state.formLayout.rows.splice(i, 1);
          break;
        }
      }
    }
  },

  [types.REMOVE_FIELD] (state, payload) {
    const fieldInfoId = payload.fieldInfoId;
    // Check if it is one of parent-child field (self-related)
    // if allLinkedFieldInfoIds = [], none
    const fieldInfoIdsIncludingLinkedFields = getAllLinkedFieldInfoIds(state, fieldInfoId)
    console.log('REMOVE_FIELD :: fieldInfoIdsIncludingLinkedFields: ', fieldInfoIdsIncludingLinkedFields)

    for(let i = 0; i < fieldInfoIdsIncludingLinkedFields.length; i++) {
      const infoId = fieldInfoIdsIncludingLinkedFields[i]
      var result = removeFromLayout(state, infoId)
      if (result.found) {
        removeFromInfo(state, infoId, result.controlType)
      }
    }
  },

  [types.RESET_FORM_CONFIG] (state) {
    Object.assign(state, defaultState);
  },

  [types.UPDATE_DATA_SOURCES] (state, dataSources) {
    for (let i = 0; i < state.dataSources.length; i++) {
      const loopDataSource = state.dataSources[i];
      const updatedDataSource = dataSources[loopDataSource._id]
      if (updatedDataSource) {
        state.dataSources[i] = updatedDataSource;
      }
    }
  }
  // [types.ADJUST_WIDGET_WIDTH_THE_MOST] (state, payload) {
  // }
};
