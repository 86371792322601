<template>
  <div>
    <label>{{ label }}</label>
  <v-text-field
        label=""
        :value="displayValue"
        dense
        readonly
        :placeholder="placeholder"
        append-outer-icon="mdi-tools"
        @click:append-outer="openDialog"
        hide-details="auto"
        outlined></v-text-field>
    <table-display-setup-dialog
        ref="tableDisplaySetupDialog"></table-display-setup-dialog>
  </div>
</template>

<script>
import mixin from './mixin'
import tableDisplaySetupDialog from '@/components/dialogs/TableDisplaySetupDialog'
import { getDefaultPropertyValue } from "@/helpers/FormHelpers";

export default {
  name: 'witTableDisplaySetup',
  mixins: [mixin],
  components: {
    tableDisplaySetupDialog
  },
  watch: {
    dataTableId: function (newVal, oldVal) {
      const vm = this
      if (vm.displayFieldCount === 0) {
        vm.propertyValue = getDefaultPropertyValue(vm.propertyKey)
      }
    }
  },
  computed: {
    selectedFieldIds () {
      const vm = this
      var result = []
      if (vm.propertyValue) {
          result = [
              ...vm.propertyValue.fixedFieldIds,
              ...vm.propertyValue.nonFixedFieldIds
          ]
      }
      return result
    },
    selectedFieldCount () {
      return this.selectedFieldIds ? this.selectedFieldIds.length : 0
    },

    displayValue () {
      const vm = this
      var result = []
      var totalFields = 1
      if (vm.propertyValue.fixedFieldIds.length > 0) {
        result.push(
            vm.$t('view.fixedFields') + ' x ' + 
            vm.propertyValue.fixedFieldIds.length)        
      }
      if (vm.propertyValue.nonFixedFieldIds.length > 0) {
        result.push(
            vm.$t('view.nonFixedFields') + ' x ' +
            vm.propertyValue.nonFixedFieldIds.length)
      }
      return result.join(', ')
    }
  },
  mounted () {
    this.initPropertyValue()
  },
  methods: {
    confirmHandler (updatedSettings) {
      console.log('WitTableDisplaySetup :: confirmHandler :: updateSettings: ', updatedSettings)
    },
    _parseValue (val) {
      const vm = this

      if (val.fixedFieldIds && val.nonFixedFieldIds) {
        return val
      } else {
        console.log('_parseValue: val.fixedFieldIds: ', val.fixedFieldIds)
        console.log('_parseValue: val.nonFixedFieldIds: ', val.nonFixedFieldIds)

        console.log('vm.relatedTitleFieldId = ' + vm.relatedTitleFieldId)
        console.log('vm.relatedFirstAttachmentFieldId = ' + vm.relatedFirstAttachmentFieldId)
              
        var fixedFieldIds = [vm.relatedTitleFieldId]
        var nonFixedFieldIds = []
        if (vm.relatedFirstAttachmentFieldId !== '') {
          nonFixedFieldIds.push(vm.relatedFirstAttachmentFieldId)
        }
      console.log('_parseValue: fixedFieldIds: ', fixedFieldIds)
      console.log('_parseValue: nonFixedFieldIds: ', nonFixedFieldIds)

        return {
          fixedFieldIds: fixedFieldIds,
          nonFixedFieldIds: nonFixedFieldIds
        }
      }
    },

    initPropertyValue () {
      const vm = this
      var defaultPropertyValue = JSON.parse(JSON.stringify(vm.propertyValue))
      console.log('witTableDisplaySetup :: initPropertyVAlue : defaultPropertyValue: ', defaultPropertyValue)
      console.log('witTableDisplaySetup :: selectedFieldCount = ' + vm.selectedFieldCount)
      
      if (!defaultPropertyValue) {
        defaultPropertyValue = getDefaultPropertyValue(vm.propertyKey)
      }
      if (vm.selectedFieldCount === 0) {
        console.log('vm.relatedTitleFieldId = ' + vm.relatedTitleFieldId)
          if (vm.relatedTitleFieldId) {
              defaultPropertyValue.fixedFieldIds = [vm.relatedTitleFieldId]
          }
      }
      console.log('witTableDisplaySetup :: initPropertyVAlue : defaultPropertyValue: ', defaultPropertyValue)

      vm.propertyValue = defaultPropertyValue
    },
    openDialog () {
      const vm = this
      console.log('openDialog : propertyValue: ', vm.propertyValue)
      vm.$refs.tableDisplaySetupDialog.open(
        {
          tableConfig: vm.propertyValue,
          fieldInfos: vm.relatedFieldInfos,
          titleFieldInfoId: vm.relatedTitleFieldInfoId
        },
        (result) => {
          vm.propertyValue = result
        }
      )
    }
  }
}
</script>
