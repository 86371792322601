<template>
  <div>
    <label>{{ label }}</label>
    <v-checkbox
      dense
      hide-details

      v-model="propertyValue.enabled"
    >
    <template v-slot:label>
      {{$t('general.activate')}}
      <v-tooltip top max-width="250px">
        <template v-slot:activator="{on}">
          <v-icon small v-on="on" >mdi-information-outline</v-icon>
        </template>
        {{$t('phone.activate_description')}}
      </v-tooltip>
    </template>
    </v-checkbox>
    <div v-if="propertyValue.enabled">
      <label> {{$t('phone.allowedCountries')}}</label>
      <DialogFieldCountryCodes
        :value="propertyValue.allowedCountries"
        :options="countries"
        :placeholder="propertyValue.allowedCountries.length || $t('phone.allowedCountriesPlaceholder')"
        @confirm="onAllowedCountriesConfirm"
      />
      <label>{{$t('phone.default')}}</label>
      <CountryCodeSelect 
        :items="filteredCountries"
        item-text="name"
        v-model="propertyValue.default"
      />
      <label>{{$t('phone.favourites')}}</label>
      <DialogFieldCountryCodes
        :value="propertyValue.favourites"
        :options="filteredCountries"
        :placeholder="propertyValue.favourites.length || $t('phone.favouritePlaceholder')"
        @confirm="$set(propertyValue, 'favourites', $event)"
      />      

    </div>
  </div>
</template>
<script>
import mixin from "./mixin";
import countryCodes from "@/const/countryCodes";

import DialogFieldCountryCodes from "@/components/dialogFields/DialogFieldCountryCodes";
import CountryCodeSelect from "@/components/form/CountryCodeSelect"

export default {
  components: {
    DialogFieldCountryCodes,
    CountryCodeSelect
  },
  mixins: [mixin],
  data() {
    return {
      countries: countryCodes,
    };
  },
  computed: {
      allowedCountries(){
          return this.propertyValue && this.propertyValue.allowedCountries || []
      },
      defaultCountry(){
        return this.propertyValue && this.propertyValue.default
      },
      filteredCountries(){
          return this.allowedCountries.length > 0 ? 
            this.countries.filter(item => this.allowedCountries.includes(item.iso2)) :
            this.countries
      }
  },
  methods: {
      onAllowedCountriesConfirm(val){
          this.$set(this.propertyValue, 'allowedCountries', val)
          if (this.allowedCountries.length > 0){
            const updatedFav = this.propertyValue.favourites.filter(item => this.allowedCountries.includes(item))
            this.$set(this.propertyValue, 'favourites', updatedFav)

            const defaultValue = this.allowedCountries.find(country => country === this.defaultCountry)
            if (!defaultValue){
              this.$set(this.propertyValue, 'default', this.allowedCountries[0])
            }
          }

      }
  },
  mounted(){
    if (typeof this.value !== 'object'){
          this.propertyValue = {
            allowedCountries:[],
            enabled:false,
            favourites:[],
            default:'hk'
          }
    }
  }
};
</script>
