<template>
  <WorkflowConsumer>
    <v-row no-gutters class="w-editor-header" slot-scope="{ info }" :style="rootStyle()">
      <v-col
        cols="12"
        sm="3"
      >
        <ReleaseDialog />
        <Backward
          :onClick="returnToList"
          editable
          dark
          :onEdit="onEditWorflowTitle"
          :onRemark="onRemarkWorflow"
          :onTitleBlur="onTitleBlur"
          :info="info"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        v-if="ready"
      >
        <v-tabs
          v-model="info.index"
          align-with-title
          v-if="!!info.type"
          class="editor-tabs-wrapper"
          centered
          height="56"
          @change="onChange"
        >
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab
            active-class="v-tab-actived-dark tab-actived"
            v-for="(type) in tabs"
            :disabled="blockAction"
            :key="type.key"
            class="v-tab-item-dark tab-item customized"
          >
            {{ type.label }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        v-if="!!info.type && ready"
      >
        <v-row justify="end" v-if="info.index === 0 && !validatingWorkflow">
          <v-layout align-center justify-end>
            <NodeValidationLog
              v-if="deniedRelease"
              :checkingLog="errorLog"
              direction=""
              :left="false"
              :bottom="true"
              :messageLabel="$t('workflow.message.validationBelowWithRelease')"
            />
            <v-btn
              v-if="isBeingReleased"
              color="white"
              elevation="0"
              small
              @click="release"
              outlined
              :disabled="blockAction"
              class="w-editor-header-save-btn"
            >
              <span>{{ isReleasing ? $t('workflow.status.updateingRelease') : $t('workflow.btn.updateRelease') }}</span>
            </v-btn>
            <v-btn
              v-if="!released"
              color="secondary"
              elevation="0"
              small
              @click="release"
              :disabled="blockAction"
              class="w-editor-header-create-btn"
            >
              <span>{{ isReleasing ? $t('workflow.status.doingRelease') : $t('workflow.btn.release') }}</span>
            </v-btn>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-layout
                  align-center
                  justify-end
                  class="w-editor-header-stop-panel"
                  v-bind="attrs"
                  v-on="on"
                >
                  <div
                    class="w-editor-header-stop-icon"
                  >
                  <Lottie
                    class="w-editor-header-stop-btn-icon"
                    :options="defaultOptions"
                    :height="100"
                    :width="100"
                  />
                  </div>
                  <v-btn
                    color="primary"
                    elevation="0"
                    small
                    @click="stopRelease"
                    :disabled="blockAction"
                    class="w-editor-header-stop-btn"
                  >
                    <!-- <v-icon size="25">
                      mdi-check
                    </v-icon> -->
                    <span>
                      {{ isStopping ? $t('workflow.status.stopping') : $t('workflow.status.releasing') }}
                    </span>
                  </v-btn>
                </v-layout>
              </template>
              <ReleaseDateInfo />
            </v-tooltip>
          </v-layout>
        </v-row>
      </v-col>
    </v-row>
  </WorkflowConsumer>
</template>

<script>
import { includes } from 'lodash';
import WorkflowConsumer from './contexts/workflowConsumer';
import Backward from './../components/header/backward';
import Routes from '@/const/workflow/routes';

import Lottie from 'vue-lottie';
import ReleaseIconAnimation from '@/pages/admin/workflow/assets/released.json';
import NodeValidationLog from './../components/tree/node/section/log';
import Mixin from './../mixin';
import SettingMixin from './../components/tree/setting/mixin';
import SettingUtils from './../utils/setting';
import ReleaseDateInfo from './releaseInfo';
import ReleaseDialog from './releaseDialog';
import {
  RESET_EDITOR,
} from '@/store/modules/workflow/action_types';
import {
  STOP_ORCHESTRATION,
  RELEASE_TO_ORCHESTRATION,
} from '@/store/modules/orchestration/action_types';
export default {
  mixins: [Mixin, SettingMixin],
  inject: ['info', 'setTitle', 'setDescription'],
  props: {
    ready: Boolean,
  },
  data() {
    return {
      showRelease: false,
      defaultOptions: {
        animationData: ReleaseIconAnimation,
      },
      tabs: [
        {
          key: 'flow',
          label: this.$t('workflow.label.flow'),
        },
        {
          key: 'spec',
          label: this.$t('workflow.label.specification'),
        },
        {
          key: 'history',
          label: this.$t('workflow.label.history'),
        }
      ],
    }
  },
  components: {
    WorkflowConsumer,
    Backward,
    NodeValidationLog,
    ReleaseDateInfo,
    ReleaseDialog,
    Lottie,
  },
  methods: {
    async release() {
      await this.$store.dispatch(RELEASE_TO_ORCHESTRATION, this.workflow._id);
      this.$sensors.track("plus_workflow_release_execute", {
        app_id:this.workflow.appId
      });
    },
    async stopRelease() {
      await this.$store.dispatch(STOP_ORCHESTRATION, this.workflow._id);
    }, 
    rootStyle() {
      return {
        width: '100%',
        backgroundColor: this.$vuetify.theme.themes.light.primary,
      }
    },
    onEditWorflowTitle({ target: { value } }) {
      this.setTitle(value);
    },
    onRemarkWorflow({ target: { value } }) {
      this.setDescription(value);
    },
    onChange(t) {
      this.info.editType = t;
    },
    onTitleBlur(){
      if (!this.info.title){
        this.info.title = this.workflow.name
      }
    },
    returnToList() {
      const self = this;
      if (self.prevRoute && includes([Routes.formWorkflows], self.prevRoute.name)) {
        self.$router.push({
            name: Routes.formWorkflows,
            params: self.prevRoute.params,
        });
      } else {
        self.$router.push({
            name: Routes.formWorkflows,
            params: this.currentRouteParams,
        });
      }
      this.$store.dispatch(RESET_EDITOR);
    }
  },
  computed: {
    deniedRelease() {
      return this.errorLog && this.errorLog.length > 0;
    },
    isReleasing() {
      return this.$store.getters.releasing;
    },
    isStopping() {
      return this.$store.getters.stopping;
    },
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
    released() {
      const { workflow={} } = this;
      return workflow && workflow.released;
    },
    blockRelease() {
      return this.$store.getters.isBlockRelease;
    },
    blockAction() {
      return this.blockRelease || this.isPreviewMode || this.isUIBlocked;
    },
    isUIBlocked() {
      return this.$store.getters.isUIBlocked;
    },

    isBeingReleased() {
        return SettingUtils.isWorkflowBeingReleased(this.workflow);
    },
  },
};

</script>

<style scoped>
  .w-editor-header-stop-panel {
    flex: initial !important;
  }
  .w-editor-header-stop-icon {
    position: relative;
    width: 50px;
    height: 50px;
  }
  .w-editor-header-stop-btn-icon {
    position: absolute;
    top: -28px;
    left: 2px;
    z-index: 99;
  }
  .w-editor-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 90px;
    width: 100%;
    /* width: 100%; */
    padding: 0 24px 0 0px;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    box-shadow: 0 3px 6px rgb(0 0 0 / 35%);
  }
  .w-editor-header-save-btn {
    border-color: white;
  }
  .w-editor-header-save-btn span {
    color: white !important;
    font-weight: bolder;
  }
  .w-editor-header-save-btn, .w-editor-header-create-btn, .w-editor-header-stop-btn {
    padding: 5px 20px !important;
    border-radius: 20px;
    font-size: 16px;
    margin-left: 15px;
    height: auto !important;
  }
  .w-editor-header-create-btn {
    color: white;
  }
  .w-editor-header-stop-btn {
    color: white;
    font-weight: bolder;
    padding: 0px !important;
  }
  .w-editor-header-stop-btn span {
    margin-left: 10px;
  }
  .w-editor-header-release-mode {
    margin-right: 10px;
    font-size: 13px;
  }
  .v-tab-item-dark.v-tab-actived-dark {
    color: white !important;
  }
  .v-tab-item-dark {
    color: #eaeaea !important;
    font-size: 16px;
    margin-right: 15px;
  }
</style>
