<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attr }">
      <v-btn
        x-small
        :ripple="false"
        depressed
        outlined
        fab
        v-on="on"
        v-bind="attr"
        class="ma-1 outlined-button "
        :style="`backgroundColor:${color};`"
        @click="onClick"
      >
        <slot></slot>
      </v-btn>
    </template>
    {{color}}
  </v-tooltip>
</template>
<script>
export default {
  props: {
    color: String,
  },
  methods: {
    onClick() {
      this.$emit("select", this.color);
    },
  },
};
</script>
<style scoped>
.outlined-button {
  border: 1px solid #9e9e9e;
}
</style>
