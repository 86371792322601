<template>
  <div class="layout-widget-width-button d-inline-block line-height-1 primary white--text py-1"
       :class="{'selected': selected}"
       @click="onClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    selected: Boolean
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style>
  .layout-widget-width-button.selected {
    opacity: 1;
  }

  .layout-widget-width-button  {
    min-width: 20px;
    opacity: 0.5;
    font-size: 11px;
  }
</style>
