<template>
  <div class="workform-clip-card-container">
    <v-layout align-center class="workform-clip-card" >
      <span class="workform-clip-onlyLabel" :style="{ background: $vuetify.theme.themes.light.primary }">
        {{  renderLabel ? renderLabel(item) : item.label }}
      </span>
        <span class="px-2" v-if="item.suffix">
        {{  item.suffix }}
      </span>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      item: Object,
      onlyPrefix: Boolean,
      onlySuffix: Boolean,
      renderLabel: Function,
    },
  }
</script>

<style>
</style>
