<template>
  <div class="view-settings-table-settings fill-height flex-grow-1 d-flex flex-column">
    <form-filter-list
        class="px-0"
        ref="formFilterList"
        :fieldInfos="fieldInfos"
        @addFilter="addFilterHandler"
        @deleteFilter="deleteFilterHandler"
        @deleteAllFilters="deleteAllFiltersHandler"
        @updateFilters="updateFiltersHandler"
        @updateFilterAndOr="updateFilterAndOrHandler"
        :filters="filters"
        :filterAndOr="filterAndOr"></form-filter-list>
  </div>
</template>

<script>
import formFilterList from '@/components/FormFilterList'

export default {
  name: 'inputPanelFilterSetting',
  components: {
    formFilterList
  },
  props: {
    inputPanel: Object,
    fieldInfos: Array
  },
  computed: {
    filters: {
      get () {
        return this.inputPanel.filterConfig.filters
      },
      set (val) {
        this.inputPanel.filterConfig.filters = val
      }
    },
    filterAndOr: {
      get() {
        return this.inputPanel.filterConfig.filterAndOr
      },
      set (val) {
        this.inputPanel.filterConfig.filterAndOr = val
      }
    }
  },
  methods: {
    addFilterHandler (payload) {
      this.$emit('onCommand', {
        command: 'addFilter',
        fieldId: payload.fieldId,
        callback: payload.callback
      })
    },
    updateFiltersHandler (filters) {
      this.inputPanel.filterConfig.filters = filters
    },
    updateFilterAndOrHandler (filterAndOr) {
      this.inputPanel.filterConfig.filterAndOr = filterAndOr
    },
    deleteFilterHandler (filterIndex) {
      this.$emit('onCommand', {
        command: 'deleteFilter',
        filterIndex: filterIndex
      })
    },
    deleteAllFiltersHandler () {
      this.$emit('onCommand', {
        command: 'deleteAllFilters'
      })
    },
  }
}
</script>