
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import ValidationsHelper from "@/helpers/ValidationsHelper"

const REQUIRED = 'required'
const WITHIN_RANGE = 'withinRange'
const TIME_RANGE = 'timeRange'
const NO_DUPLICATE = 'noDuplication'
const LIMIT_WEEKDAYS = 'limitWeekday'
const OPTION_COUNT_LIMIT = 'optionCountLimited'

const EMAIL = 'email'
const DATE = 'date'
const TIME = 'time'
const DATETIME = 'datetime'
const IDCARD = 'idCard'
const PHONE = 'phone'
/**
 *  This mixins includes all validation across widgets. Add new rules here
*/

const validationMixins = {
  data() {
    return {
        //rules
      RULE_REQUIRED: (v) => {
        return (v && Boolean(v.toString().trim())) || this.$t("messages.thisFieldIsRequired")
      },
      RULE_RANGE: (v) =>
      (!v && v !== 0) || this.isInRange(v, this.minimumValue, this.maximumValue, {between:"messages.outOfRange", atLeast:"messages.mustBeAtLeast", atMost:"messages.mustNotExceed"}),
      RULE_EMAIL: (v) => !v || ValidationsHelper.isValidEmail(v) || this.$t("messages.invalidEmail"),
      RULE_PHONE: (v) => !v || ValidationsHelper.isValidPhone(v)|| this.$t("messages.invalidPhone"),
      RULE_DATE: (v) => !v || ValidationsHelper.isValidDate(v) || this.$t("messages.invalidDate"),
      RULE_TIME: (v) => !v || ValidationsHelper.isValidTimeString(v) || this.$t("messages.invalidTime"),
      RULE_DATETIME: (v) => !v || ValidationsHelper.isValidDateTimeString(v) || this.$t("messages.invalidDateTime"),
      RULE_ALLOWED_DAYS: (v)=> !v || this.allowedDays.includes(new Date(v).getDay().toString()) || this.$t("messages.dayNotAllowed"),
      RULE_TIME_RANGE: (v)=> !v || this.isInTimeRange(this.getTimeFromDateTime(v), this.minimumTime, this.maximumTime),
      RULE_IDCARD: (v) => !v || ValidationsHelper.isValidIdCard(v, this.fieldInfo.properties.idCardType)|| this.$t("messages.invalidIdCard"),
      //validationStatus
      hasError: false,
      errorMessage: ''
    };
  },
  methods: {
    // checkRuleRequired (v) {
    //   const vm = this
    //   console.log('checkRuleRequired :: vm.fieldInfo: ', vm.fieldInfo)
    //   return (v) => (v && !!v.trim()) || this.$t("messages.thisFieldIsRequired")
    // },

    getTimeFromDateTime(val){
      const dateTimeArr= val.trim().split(" ");
      if (dateTimeArr.length === 2){
        return dateTimeArr[1];
      } else return dateTimeArr[0];
    },
    isInRange(val, min, max, {between, atLeast, atMost}) {
      if (typeof val === "string") {
        val = val.replaceAll(",", "");
      }
      let error =
        min && max
          ? this.$t(between, {
              min: min,
              max: max,
            })
          : min
          ? this.$t(atLeast, { min: min })
          : this.$t(atMost, { max: max });
      return ValidationsHelper.isInNumberRange(val, min, max) || error;
    },
    isInTimeRange(val, min, max){
      if (min && max){
        return (val >= min && val <= max) || this.$t('messages.mustBeWithinTime', {min, max})
      } else if (min){
        return (val >= min) ||this.$t('messages.mustBeLater', {min})
      } else if (max){
        return (val <= max) || this.$t('meesages.mustBeEarlier', {max})
      } else {
        return true
      }
    },
    validate(){
      const vm = this
      return new Promise(async (resolve, reject) => {
        if (this.errorMessage){
          console.log('validate fieldId (' + vm.fieldId + ') this.errorMessagte = ' + this.errorMessage + ' => resolve(false)')
          resolve(false)
        }
        else if (this.rules.length > 0) {
          //console.log('validate fieldId = ' + vm.fieldId + ' => this.rules.length > 0')

          if (this.rules.some(rule=> rule(this.value) !== true)) {
            console.log('validate fieldId = ' + vm.fieldId + ' => this.rule = false')

            //console.log(this.$refs)
            //this.$refs[this.fieldInfo.fieldId].error = true
            this.hasError = true
            resolve(false)
          }
        }
        if (this.noDuplicate){
          let result = await this.checkDuplicate()
          if (result) resolve(false);
        }
        resolve(true)
      })
    },
    /**
     * 
     * @returns True if requires uniqueness and not empty and has duplicate. False otherwise.
     */
    async checkDuplicate(){
      if (!this.inputValue) return false
      if (this.noDuplicate){
        if (Array.isArray(this.childRecords)){
          const exist = this.childRecords.find(
            (record) =>
              this.recordData._id !== record._id &&
              typeof record[this.fieldInfo.fieldId] === "string" &&
              record[this.fieldInfo.fieldId].trim() === this.inputValue
          );
          if (exist){
            this.errorMessage = this.$t('messages.hasDuplicate');
            this.hasError = true;
          }
          return exist
        }else {
          let res = null
          if (this.$route.name === 'PublicForm' || this.$route.name === 'PreviewPublicForm'){
            const formId = this.formInfo._id;
            const url = this.$store.getters.appHost + "/public/checkDuplicate";
            res = await this.axios.post(url, {
              formId,
              field : {
                id: this.fieldId,
                value: this.inputValue
              }
            })
            
          }else {
            const formId =this.formInfo._id;
            const postData = {
              urlCommand:'/data/checkDuplicate',
              data: {
                formId,
                field : {
                  id: this.fieldId,
                  value: this.inputValue
                }
              }
            }
            if (this.recordData._id){
              Object.assign(postData.data, {
                recordId : this.recordData._id
              })
            }
            res = await this.$store.dispatch('AUTH_POST', postData)
          }
          
          if (res === true || res.data === true || res.result === true){
            this.errorMessage = this.$t('messages.hasDuplicate');
            this.hasError = true;
            return true
          }else {
            return false
          }
        }
      } else {
        return false;
      }
    }
  },
  computed: {
    validations() {
      if (this.$route.name === 'PublicQuery') return [REQUIRED];
      else {
        const validation = getFieldPropertyValue(
          this.fieldInfo,
          "validation",
          ''
        );
        return validation && validation.split("||") || [];
      }

    },
    required() {
      return !this.isReadonly && this.validations.length > 0
        ? this.validations.indexOf(REQUIRED) > -1
        : false;
    },
    noDuplicate(){
      return this.validations.length > 0
        ? this.validations.indexOf(NO_DUPLICATE) > -1
        : false;
    },
    withinRange() {
      return this.validations.length > 0
        ? this.validations.indexOf(WITHIN_RANGE) > -1
        : false;
    },
    timeRange(){
      return this.validations.length > 0
      ? this.validations.indexOf(TIME_RANGE) > -1
      : false;
    },
    minimumValue() {
      return this.withinRange ? this.fieldInfo.properties.minValue : "";
    },
    maximumValue() {
      return this.withinRange ? this.fieldInfo.properties.maxValue : "";
    },
    minimumTime(){
      if (this.timeRange){
        return getFieldPropertyValue(this.fieldInfo, 'validation_minTime', '')
      }
      else return '';
    },
    maximumTime(){
      if (this.timeRange){
        return getFieldPropertyValue(this.fieldInfo, 'validation_maxTime', '')
      }
      else return '';
    },
    allowedDays() {
      if (this.validations.includes('limitWeekday')){
        const days = getFieldPropertyValue(this.fieldInfo, 'validation_weekday', '');
        return days.split("||");
      }
      return  ['0', '1', '2', '3', '4', '5', '6'] ;
    },
    rules(){
        var result = [];
        if (this.isLayout) return result;
        if (this.required){
          result.push(this.RULE_REQUIRED)
        }

        switch(this.fieldType){
          case EMAIL:
            result.push(this.RULE_EMAIL);
            break;
          case PHONE:
            result.push(this.RULE_PHONE);
            break;
          case DATE:
            result.push(this.RULE_DATE)
            break;
          case TIME:
            result.push(this.RULE_TIME)
            break;
          case DATETIME:
            result.push(this.RULE_DATETIME)
            break;
          case IDCARD:
            result.push(this.RULE_IDCARD)
            break
        }
        this.validations.forEach(validation => {
            switch(validation) {
                case REQUIRED:
                    break;
                case WITHIN_RANGE:
                    result.push(this.RULE_RANGE)
                    break;

                case LIMIT_WEEKDAYS:
                  result.push(this.RULE_ALLOWED_DAYS)
                  break;
                case TIME_RANGE:
                  result.push(this.RULE_TIME_RANGE)
                  break;
                case OPTION_COUNT_LIMIT:
                  result.push(this.RULE_OPTION_COUNT_LIMIT)
            }
        });
        return result;
    }
  },
};

export default validationMixins;
