<template>
  <div class="input-radio-toggle pb-2">
    <v-btn v-for="option in options" class="min-width-50 elevation-0"
          :key="option[valueField]"
          :class="optionButtonClass(option)"
          :style="{height: (isDense ? '24px' : '32px')+' !important'}"
          @click="onClick(option)"
          small
    >
      <v-icon v-if="icon" left>{{ option.icon }}</v-icon>
      {{ optionLabel(option) }}
    </v-btn>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    icon: Boolean,
    options: Array,
    value: String,
    valueField: {
      type: String,
      default: 'value'
    },
    isDense: {
      type: Boolean,
      default: true
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    optionButtonClass (option) {
      const vm = this
      var result = [vm.buttonClass]
      if (vm.value && option[vm.valueField]===vm.value) {
        result.push('white--text primary darken-r')
      } else if (vm.isReadOnly) {
        result.push('read-only')
      }
      return result.join(' ')
    },
    optionLabel (option) {
      const vm = this
      var result = option.label
      if (option.labelTag) {
        result = vm.$t(option.labelTag)
      }
      return result
    },
    onClick (option) {
      const vm = this
      console.log('InputRadioToggle :: onClick :: option: ', option)
      if (!vm.isReadOnly) {
        console.log('InputRadioToggle vm.valueField = ' + vm.valueField)
        console.log('InputRadioToggle emit(input) option[vm.valueField] = ' + option[vm.valueField])
        vm.$emit('input', option[vm.valueField])
      }
    }
  }
}
</script>

<style>
.input-radio-toggle button {
  border-radius: 0;
}

.input-radio-toggle button:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.input-radio-toggle button:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.input-radio-toggle button.read-only {
  cursor: default;
}

</style>
