const mixin = {
  methods: {
    checkErrorCount(state) {
      const vm = this
      var result = 0
      if (typeof vm._checkErrorCount === 'function') {
        result = vm._checkErrorCount(state)
      }
      return result
    }
  }
}

export default mixin
