<template>
  <div class="mt-0 d-flex flex-column align-start">

    <!-- <div style="min-width:60px;"
      class="flex-grow-0 line-height-1 text-left caption date-label">{{dateLabel}}</div>       -->

    <div class="flex-grow-1">
      <div class="ml-0 time-item caption pa-0 line-height-1 white--text cursor-pointer" >
      <!-- <div class="ml-0 time-item caption pa-0 line-height-1 white--text cursor-pointer" 
        v-for="(fieldInfo,index) in item.fields"
        :key="index"> -->
        <div class="d-flex flex-row align-center w-100">
          <div v-if="item.value" style="white-space:nowrap;"
            @click="onItemClicked">
            {{ item.label }}: {{ item.value }}
          </div>
          <v-btn fab x-small 
            color="error" 
            class="mx-1 delete-button"
            height="12" width="12"
            @click="removeTimeRecord(index)">
            <v-icon x-small>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'

export default {
  name: 'actionRecordItem',
  mixins: [dateTimeHelperMixin],
  props: {
    item: Object,
    index: Number
  },
  computed: {
    dateLabel () {
      const vm = this
      const today = vm.getToday()
      const yesterday = vm.getYesterday()
      const dt = new Date(vm.item.createdAt)
      const formatted = vm.formatDateTime(dt, 'YYYY-MM-DD')
      var result = formatted
      switch (result) {
        case today:
          result = vm.$t('inputPanel.today')
          break
        case yesterday:
          result = vm.$t('inputPanel.yesterday')
          break
      }
      return result
    }
  },
  methods: {
    removeTimeRecord () {
      console.log('ActionRecordItem.removeTimeRecord')
      this.$emit('onCommand', {
        command: 'deleteAction',
        _id: this.item._id
      })
    },
    onItemClicked () {
      console.log('ActionRecordItem.onItemClicked')
      this.$emit('onCommand', {
        command: 'selectAction',
        index: this.index
      })
    }

  }
}
</script>

<style>
.date-label {
  color: rgba(255,255,255,.5);
}
.time-item .delete-button {
  visibility: hidden;
}
.time-item:hover .delete-button {
  visibility: visible;
}
</style>