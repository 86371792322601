<template>
  <div id="form-component" class="pt-3" v-if="view">
    <nav class="view-navigation">
      <ul>
        <li v-for="_view in views" :key="_view._id" @click="selectedView( _view )" :class="{ active: (_view._id === view._id ) }">
            {{ _view.title }}
        </li>
      </ul>
    </nav>
    
    <div v-if="view.viewType === 'table'">
      <div id="form-action-wrapper" class="d-flex justify-space-between ma-3 align-center" v-if="!isMultipleSelecting">
        <v-text-field dense placeholder="SEARCH" outlined hide-details  v-model="searchingKeyword" class="searchInput">
          <template v-slot:append>
            <v-icon @click="clickToSearch()" dark>mdi-magnify</v-icon>
          </template>
        </v-text-field>
        <v-btn dark fab tile small class="rounded elevation-0" @click="showFilterDrawer">
            <v-icon small>$vuetify.icons.filter</v-icon>
        </v-btn>
        <v-btn dark fab tile small class="rounded elevation-0" @click="showSortingDrawer">
            <v-icon small>$vuetify.icons.sorting</v-icon>
        </v-btn>
      </div>
      <div id="multiple-select-action-wrapper" class="d-flex justify-space-between align-center mx-3" v-if="isMultipleSelecting">
        <div class="display-2">
          <v-checkbox :label="$t('settings.selectAll')" v-model="checkedAll"></v-checkbox>
        </div>
        <div>
          <v-btn rounded dark color="#76B43D" @click="showCustomActionDraw">
            <v-icon left>$vuetify.icons.customizedAction</v-icon> <span>{{ $t('view.customizedAction') }}</span> <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- style="overflow: auto !important; height:65vh" -->
      <div id="data-wrapper" v-if="data.length > 0 && !isLoading" style="overflow: auto !important; height:70vh" >
        <div v-for="item in data">
          <div 
            :key="item._id" 
            class="data-item-wrapper mb-2"
            :data-id="item._id"
          >
            <div v-if="isMultipleSelecting" style="position: sticky; top: 10px">
              <v-checkbox v-model="selectedRecords" :value="item._id" @click.stop="changeStatus($event)"></v-checkbox>
            </div>
            <div class="dataItem rounded white flex-grow-1" :data-id="item._id" >
                <div class="header text-header-2 text-truncate" @click="viewRecord(item)">{{ item[titleField._id] | formatTitledField }}</div>
                <div class="main">
                    <div class="data-content-wrapper" @click="viewRecord(item)">
                        <li v-for="field in fieldInfos.filter( field => fieldIds.includes(field._id) )" :key="field.fieldId" class="data-column" :class="`columnType-${ field.type }`">
                            <div class="column-label">{{ field.label }}</div>
                            <div class="column-value">
                                <value-handler :content="item[field.fieldId]" :type="field.type" :fieldInfo="field" :relationData="relationData" mode="view" />
                            </div>
                        </li>
                    </div>
                    <div class="collapse-expand-icon" @click="toggleCollapseExpand(item._id)">
                        <v-icon>mdi-chevron-down</v-icon>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center pa-3" v-if="isLoading">
        <font-awesome-icon icon="spinner" class="spinner fa-spin text-h5 ma-auto" />
      </div>
      <!-- floating action button group -->
      <div id="floating-action-button-group">
        <!-- MULTIPLE SELECTION -->
        <div>
          <v-btn rounded dark class="btnMultipleSelection" @click="toggleMultipleSelection()">
            <span v-if="!isMultipleSelecting">
              <v-icon left small>$vuetify.icons.multipleSelect</v-icon> <span>{{ $t('bulkEdit.multipleSelect') }}</span>
            </span>
            <span v-else>
              <v-icon left>mdi-chevron-left</v-icon> <span>{{ $t('bulkEdit.exitMultipleSelect') }}</span>
            </span>
            
          </v-btn>
        </div>
        <!-- CREATE RECORD -->
        <div v-if="!isMultipleSelecting && canAdd">
          <v-btn fab dark class="btnCreate" small @click="createRecord">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <!-- REMOVE BUTTON -->
        <div v-if="isMultipleSelecting">
           <v-btn fab color="error" dark class="btnRemove" small @click="showConfirmDialog = true" :disabled="!(selectedRecords.length > 0)">
            <v-icon large>$vuetify.icons.trash</v-icon>
          </v-btn>
        </div>
      </div>

      <custom-action-drawer v-if="isMounted" :key="updateCustomActionDrawerKey" :viewId="view._id" :formId="form._id" :appId="currentApp._id" :selected.sync="selectedRecords" :cb="afterAction" />
      <filter-drawer :filters="filterFields" />
      <sorting-drawer :sortableFields="sortableFields" />
    </div>

    <card-board-view
            ref="cardBoardView"
            v-else-if="view.viewType === 'panel'"
            :view="view"
            :printTemplates="printTemplates"
            @onCommand="onCommandHandler"
    ></card-board-view>
    <level-diagram-view
            ref="levelDiagramView"
            v-else-if="view.viewType === 'levelDiagram'"
            :view="view"
            :printTemplates="printTemplates"
            @onCommand="onCommandHandler"
    ></level-diagram-view>
    <project-view
            ref="projectView"
            class="projectView"
            v-else-if="view.viewType === 'project'"
            :view="view"
            @onCommand="onCommandHandler"
    ></project-view>

    <v-dialog v-model="showConfirmDialog">
      <v-card class="pa-7 rounded-lg text-center">
        <v-card-title class="text-center d-block">{{ $t('inputPanel.deleteRecord') }}</v-card-title>
        <v-card-text>
          {{ $t('dialog.deleteMultipleConfirmMessage', { recordCount: selectedRecords.length }) }}
        </v-card-text>
        <v-card-actions class="d-block">
          <v-btn outlined @click="showConfirmDialog = false">{{ $t('buttons.cancel') }}</v-btn>
          <v-btn color="error" @click="confirmDelete()" :loading="isRemoving">{{ $t('buttons.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import appsFormsMixin from "@/mixins/appsFormsMixin";
import ValueHandler from '@/components/form/mobile/comps/valueHandler';
import CustomActionDrawer from './comps/CustomActionDrawer';
import filterDrawer from './comps/filterDrawer';
import sortingDrawer from './comps/sortingDrawer';
import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";

import cardBoardView from "@/pages/forms/views/CardBoardView";
import levelDiagramView from "@/pages/forms/views/LevelDiagramView";
import projectView from "@/pages/forms/views/project/ProjectView";

import EventBus from '@/event-bus.js'

export default {
  mixins: [appsFormsMixin],
  components: { ValueHandler, CustomActionDrawer, filterDrawer, sortingDrawer, cardBoardView, levelDiagramView, projectView }, 
  data() {
      return {
        checkedAll: false, 
        searchingKeyword: '', 
        isLoading: false,
        isRemoving: false,
        savedPageSize: 20, 
        currentPage: 1,
        pagination: undefined,
        data: [], 
        relationData: {},
        observer: null,
        isMultipleSelecting: false,
        selectedRecords: [],
        sortingField: {},
        filter: [],
        showConfirmDialog: false,
        updateCustomActionDrawerKey: 1,
        isMounted: false
      }
  }, 
  async mounted() {
    EventBus.$emit('hideSpinner')
    const { itemId, viewId } = this.$route.params;
    await this.loadForm(itemId, async () => {
      const index = this.views.findIndex((view) => view._id === viewId);
      this.currentViewIndex = index > -1 ? index : 0;
      await this.getPageSize();
      this.$nextTick( () => {
        let params = {
            appId: this.$route.params.id,
            formId: itemId, 
            viewId: this.views[this.currentViewIndex]._id
          };
          this.fetchData(params);
          this.isMounted = true
      });
      this.$store.dispatch('SET_CURRENT_FORM_ICON', this.$store.getters.currentModule.menu[this.$store.getters.currentModuleIndex].icon);
    });    
    
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          this.observer.unobserve( entry.target )
          this.fetchData({}, true);
        }
      });
    }, {})
  },
  computed: {
    currentApp() {
        return this.$store.getters.currentApp;
    },
    fieldInfos() {
        return this.form.fieldInfos;
    },
    fieldInfoObject() {
        let result = {};
        if ( this.form ) {
            result =  this.form.fieldInfos.reduce((obj, field) => {
            obj[field.fieldId] = field; 
            return obj;
        }, {});
        }
        return result;
    }, 
    form() {
      return this.$store.getters.currentForm;
    },
    view() {
        let targetView = undefined;
        const { viewId } = this.$route.params;
        if ( !viewId ) targetView = this.views[0];
        else targetView = this.views.find((view) => view._id === viewId);
        return targetView;
    }, 
    views() {
      const vm = this;
      var result = [];
      if (vm.form && vm.form.views) {
        result = vm.form.views;
        for (var i = 0; i < result.length; i++) {
          switch (result[i].viewType) {
            case "table":
              result[i].icon = "mdi-view-list-outline";
              break;
            case "panel":
              result[i].icon = "mdi-view-dashboard-variant-outline";
              break;
            case "levelDiagram":
              result[i].icon = "mdi-animation-outline";
              break;
            case "project":
              result[i].icon = "mdi-alpha-p-circle-outline";
              break;
          }
        }
      }
      return result;
    },
    titleField() {
        let result;
        if ( this.form.fieldInfos && this.form.titleFieldInfoId ) {
            const titleFieldInfo = this.form.fieldInfos.find( info => info._id === this.form.titleFieldInfoId )
            return titleFieldInfo;
        }
        return result;
    },
    fieldIds() {
      let _fieldIds = [ ...this.view.fixedFieldIds, ...this.view.nonFixedFieldIds ];
      if ( _fieldIds.length === 0 ) {
        _fieldIds = this.fieldInfos.map( info => info.fieldId );
        const formFieldInfoObj = this.form.fieldInfos.reduce( (obj, field) => {
          obj[field._id] = field
          return obj;
        }, {});
        _fieldIds = _fieldIds.filter( id => ( id in formFieldInfoObj && formFieldInfoObj[id].canView ) )
      }
      return _fieldIds;
    },
    sortableFields() {
      let sortableWidgetIds = this.$store.getters.allWidgets.filter(widget => widget.sortable).map( widget => widget._id );
      let sortableFields = this.fieldInfos.filter( field => sortableWidgetIds.includes( field.widgetId ) && this.fieldIds.includes( field._id ));
      sortableFields = sortableFields.map( field => {
        field.sortMode = '';
        return field;
      })
      return sortableFields;
    },
    filterFields() {
      let _fields = [];
      _fields = this.fieldInfos.filter( field => ['singleSelection', 'multipleSelection'].includes( field.type ) && this.fieldIds.includes( field._id ));
      _fields = _fields.map( field => {
        field._options = ( field.type === 'singleSelection') ? field.properties.inputOptionsSingle : field.properties.inputOptionsMultiple;
        field._options.options = field._options.options.map(opt => {
          opt.backgroundColor = '';
          opt.textColor = '';
          if ( field._options.useColor ) {
            try {
              let _colorSet = opt.colorSet.split( '/' );
              opt.backgroundColor = _colorSet[1];
              opt.textColor = _colorSet[0];
            } catch (err) {
              console.warn('CANNOT_MAP_COLOR_SET')
            }
          }
          return opt;
        })
        return field;
      })
      return _fields;
    },
    canAdd() {
      return this.form.canAdd;
    },
    actionButtonsCount(){
      let count = 0;
      count = (this.$store.getters.getActionButtons || []).length;
      return count;
    }
  },
  methods: {
    async getPageSize() {
        const { itemId, viewId } = this.$route.params;
        const prefKey = `${ itemId }:${ viewId || this.view._id }`
        try {
            let _pageSize = await this.$store.dispatch( 'FETCH_PAGE_SIZE', { key: prefKey }) || 20;
            this.savedPageSize = _pageSize;
        } catch (err) {
            console.error( err );
        }
    },
    async fetchData( { appId, formId, viewId }, isCont = false ) {
        if (this.pagination && !(
          this.currentPage <= 
          Math.ceil(this.pagination.totalResult/this.pagination.pageSize)
        )) return
        const vm = this;
        if(!isCont)
          vm.isLoading = true;
        let fetchRes = undefined;
        const params = {
            appId: appId || this.currentApp._id, 
            formId: formId || this.form._id, 
            viewId: viewId || this.view._id,
            pagination: {
              currentPage: this.currentPage, 
              pageSize: this.savedPageSize
            },
            keyword: this.searchingKeyword,
            sortingFieldId: ( this.sortingField.fieldId !== '' ) ? this.sortingField.fieldId : undefined,
            sortingOrder: ( this.sortingField.sortMode !== '' ) ? this.sortingField.sortMode : undefined,
            filter: JSON.stringify(this.filter)
        };
        try {
            fetchRes = await this.$store.dispatch( 'FETCH_DATA_LIST', params);
            this.currentPage++;
        } catch (err) {}
        const { result, pagination } = fetchRes;
        this.pagination = pagination;
        this.data = [...this.data, ...result ];

        if ( this.form.fieldInfos.filter( field => field.type === 'relatedRecord').length > 0 ) {
          const uniqueIds= [ ...new Set([this.form.titleFieldInfoId, ...this.view.fixedFieldIds, ...this.form.fieldInfos.map( info => info.fieldId )])]
          const response = await fetchRelatedDataFieldsInRows(
            vm, 
            this.data, 
            null, 
            false, 
            this.form.fieldInfos, 
            [ ...new Set([this.form.titleFieldInfoId, ...this.view.fixedFieldIds, ...this.form.fieldInfos.map( info => info.fieldId )])]
          )
          this.relationData.idDataMapping = response;
          this.pagination = pagination;
          // observe the last element, data-item-wrapper
          vm.isLoading = false;
          this.$nextTick( () => {
            // remove the observer when the API does not return more data
            if ( this.data.length < pagination.totalResult ) {
              const lastEl = document.querySelector("#data-wrapper > div:last-child");
              if ( lastEl ) {
                this.observer.observe( lastEl )
              }
            }
          })
        } else {
          vm.isLoading = false;
          this.updateObserver()
        }
    },
    updateObserver(){
      // observe the last element, data-item-wrapper
      this.$nextTick(()=>{
        const lastEl = document.querySelector("#data-wrapper > div:last-child");
        if ( lastEl ) {
          this.observer.observe( lastEl )
        }
      })
    },
    clickToSearch() {
      this.data = [];
      this.fetchData({ appId: this.currentApp._id, formId: this.form._id, viewId: this.view._id })
    }, 
    selectedView(_view) {
        const url = `/apps/${ this.currentApp._id }/forms/${ this.form._id }/${ _view._id }`;
        this.$router.push( url);
        this.isMultipleSelecting = false;
        this.data = [];
        this.currentPage = 1;
        this.fetchData({
            appId: this.currentApp._id, formId: this.form._id, viewId: _view._id,
        })
        this.updateCustomActionDrawerKey++
    },
    viewRecord(_item) {
      if ( this.isMultipleSelecting ) {
        if ( this.selectedRecords.includes( _item._id ) ) {
          this.selectedRecords = this.selectedRecords.filter( id => id !== _item._id );
        } else {
          this.selectedRecords.push( _item._id );
        }
      } else {
        this.$store.dispatch('SET_RECORD_PAGE_MODE', 'view');
        this.$store.dispatch('SET_FORM_TITLED_VALUE', this.formatTitledField(_item[this.titleField._id]) );
        const url = `/apps/${ this.currentApp._id }/forms/${ this.form._id }/${ this.view._id }/${ _item._id }`;
        this.$router.push({
          name: "records",
          params: {
            appId: this.currentApp._id,
            formId: this.form._id,
            viewId: this.view._id,
            recordId: _item._id,
          },
        });
        // this.$router.push( url);
      }
    }, 
    toggleCollapseExpand(_id) {
        const el = document.querySelector(`.data-item-wrapper [data-id="${ _id }"]`);
        el.classList.toggle( 'expand' )
    },
    toggleMultipleSelection() {
      this.selectedRecords = [];
      this.isMultipleSelecting = !this.isMultipleSelecting;
    },
    showCustomActionDraw() {
      this.$store.dispatch('TOGGLE_CUSTOM_ACTION_DRAWER');
    },
    createRecord() {
      this.$store.dispatch('SET_RECORD_PAGE_MODE', 'create');
      const url = `/apps/${ this.currentApp._id }/forms/${ this.form._id }/${ this.view._id }/_`;
      this.$router.push( url );
    },
    afterAction() {
      this.isMultipleSelecting = false;
      this.selectedRecords = [];
    },
    showFilterDrawer() {
      this.$store.state.mobile.filterDrawerOpened = true;
    },
    showSortingDrawer() {
      this.$store.state.mobile.sortingDrawerOpened = true;
    },
    async confirmDelete() {
      this.isRemoving = true;
      const ids = this.selectedRecords;
      const data = {
        appId: this.form.appId, 
        formId: this.form._id, 
        viewId: this.view._id, 
        include: ids,
        exclude: [],
      };
      const postData = {
        urlCommand: '/data/deleteRows', 
        data
      };
      this.$store.dispatch('AUTH_POST', postData).then(() => {
        this.selectedRecords = [];
        this.data = [];
        this.currentPage = 1;
        this.showConfirmDialog = false;
        this.isMultipleSelecting = false;
        this.fetchData({
          appId: this.form.appId, 
          formId: this.form._id, 
          viewId: this.view._id
        });
        this.isRemoving = false;  
      });
    },
    formatTitledField(val) {
      if ( Array.isArray(val) ) val = val[0];
      if ( val && typeof val === 'object' && 'label' in val) val = val.label 
      if ( val && typeof val === 'object' && 'employeeName' in val ) val = val.employeeName 
      return val;
    }
  },
  watch: {
    checkedAll(val) {
      if ( val ) {
        this.selectedRecords = this.data.map( item => item._id );
      } else {
        this.selectedRecords = [];
      }
    },
    selectedRecords(val){
      if ( val.length >= this.data.length ) {
        this.checkedAll = true; 
      } else {
        this.checkedAll = false
      }
    }
  }, 
  filters: {
    formatTitledField(val) {
      // check is array, pick the first one
      if ( Array.isArray(val) ) {
        val = val[0];
      }
      if ( val && typeof val === 'object' && 'label' in val ) val = val.label 
      if ( val && typeof val === 'object' && 'employeeName' in val ) val = val.employeeName 
      return val;
    }
  }
};
</script>
