<template>
  <div style="margin-bottom: 20px;">
    <div class="d-flex flex-row align-center">
      <div>
        <label>{{ label }}</label>
        <input-radio-toggle
            :dense="true"
            :options="propertyConfig.options"
            v-model="inputValue">
            <!--:value="inputRowType"-->
            <!--@input="val=>updateValue(val)">-->
        </input-radio-toggle>
      </div>
      <div v-if="inputValue==='multiple'" style="width:30%;" class="ml-3">
        <label>{{ $t('widgets.properties.rowCount') }}</label>
        <v-text-field
            dense
            style="with:auto;"
            label=""
            outlined
            hide-details="auto"
            single
            v-model="rowCount"
          type="number"
          :rules="numberRules"/>
      </div>
    </div>
  </div>
</template>

<script>
import inputRadioToggle from '@/components/formComps/InputRadioToggle'

import mixin from './mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      useColor: 0,
      default: 0,
      options: [],
      inputRowType: '',
      rowCount: 1,
      numberRules: [v=> (!isNaN(parseFloat(v)) && v >= 0)]

    }
  },
  components: {inputRadioToggle},
  watch: {
    value: function (newVal, oldVal) {
      const vm = this
      if (newVal !== oldVal) {
        vm.parseValue(newVal)
      }
    },
    inputRowType: function(newVal, oldVal) {
      this.updatePropertyValue()
    },
    rowCount: function(newVal, oldVal) {
      this.updatePropertyValue()
    }
  },
  methods: {
    _parseValue (val) {
      const vm = this
      if (typeof val === 'undefined') {
        val = vm.value
      }
      console.log('parseValue val = ' + val)
      if (val.indexOf(':') >= 0) {
        var keyValue = val.split(':')
        vm.inputRowType = keyValue[0]
        vm.rowCount = keyValue[1]
      } else {
        vm.inputRowType = val
      }
      if (vm.inputRowType === 'multiple' && vm.rowCount === '') {
        vm.rowCount = 1
      }
      console.log('parseValue :: inputRowType = ' + vm.inputRowType)
      console.log('parseValue :: rowCount= ' + vm.rowCount)
    },
    _buildValue () {
      const vm = this
      var result = vm.inputRowType
      if (vm.inputRowType === 'multiple') {
        result += ':' + vm.rowCount
      }
      return result
    }
  }
}
</script>
