export default {
    state() {
        return {
            navigationDrawerOpened: false,
            showRecordActionDrawer: false,
            showCustomActionDrawer: false,
            userDrawerOpened: false, 
            formTitledValue: undefined,
            recordPageMode: 'view',
            sortingDrawerOpened: false,
            filterDrawerOpened: false
        }
    }, 
    actions: {
        TOGGLE_NAVIGATION_DRAWER({commit}) {
            commit('TOGGLE_NAVIGATION_DRAWER');
        },
        TOGGLE_USER_DRAWER({commit}) {
            commit('TOGGLE_USER_DRAWER');
        },
        TOGGLE_RECORD_ACTION_DRAWER({commit}){
            commit('TOGGLE_RECORD_ACTION_DRAWER');
        }, 
        TOGGLE_CUSTOM_ACTION_DRAWER({commit}){
            commit('TOGGLE_CUSTOM_ACTION_DRAWER');
        }, 
        SET_FORM_TITLED_VALUE({commit}, value) {
            commit('SET_FORM_TITLED_VALUE', value);
        },
        SET_RECORD_PAGE_MODE({commit}, value) {
            commit('SET_RECORD_PAGE_MODE', value);
        }
    },
    getters: {
        navigationDrawerOpened: (state) => {
            return state.navigationDrawerOpened;
        },
        userDrawerOpened: (state) => {
            return state.userDrawerOpened;
        },
        formTitledValue: (state) => {
            return state.formTitledValue;
        },
        showRecordActionDrawer: (state) => {
            return state.showRecordActionDrawer;
        },
        showCustomActionDrawer: (state) => {
            return state.showCustomActionDrawer;
        },
        recordPageMode: (state) => {
            return state.recordPageMode;
        },
    }, 
    mutations: {
        TOGGLE_NAVIGATION_DRAWER(state) {
            state.navigationDrawerOpened = !state.navigationDrawerOpened;
        },
        TOGGLE_USER_DRAWER(state) {
            state.userDrawerOpened = !state.userDrawerOpened;
        },
        TOGGLE_RECORD_ACTION_DRAWER(state) {
            state.showRecordActionDrawer = !state.showRecordActionDrawer
        }, 
        TOGGLE_CUSTOM_ACTION_DRAWER(state) {
            state.showCustomActionDrawer = !state.showCustomActionDrawer;
        }, 
        SET_FORM_TITLED_VALUE( state, val ) {
            state.formTitledValue = val;
        },
        SET_RECORD_PAGE_MODE(state, val) {
            state.recordPageMode = val;
        }
    }
}