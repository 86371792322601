var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('v-container',{staticClass:"fill-height pa-0",attrs:{"fluid":""}},[_c('loading-icon')],1):_c('v-container',{staticClass:"fill-height pa-0",attrs:{"fluid":""}},[_c('v-app-bar',{staticClass:"underline",staticStyle:{"background-color":"white"},attrs:{"app":"","flat":""}}),_c('v-navigation-drawer',{staticClass:"api-drawer",attrs:{"permanent":"","fixed":"","app":"","floating":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-toolbar',{staticClass:"underline",staticStyle:{"height":"64px"},attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.$t('api.PageTitle', [_vm.appInfo.title]))+" ")])]},proxy:true}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.generalSections),function(section){return _c('v-list-item',{key:section.value,attrs:{"input-value":_vm.selectedSection === section.value,"link":"","active-class":"primary lighten-1 white--text"},on:{"click":function($event){return _vm.$vuetify.goTo(("#" + (section.value)), {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
          })}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(section.label))])],1)],1)}),_c('v-list-group',{attrs:{"active-class":"no-active","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.formSections.label))])],1)]},proxy:true}]),model:{value:(_vm.formSections.active),callback:function ($$v) {_vm.$set(_vm.formSections, "active", $$v)},expression:"formSections.active"}},_vm._l((_vm.formSections.children),function(form,index){return _c('v-list-group',{key:form._id,attrs:{"active-class":"no-active","no-action":"","sub-group":""},on:{"click":function($event){return _vm.closeOther(index, _vm.formSections.children)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(form.title))])],1)]},proxy:true}],null,true),model:{value:(form.active),callback:function ($$v) {_vm.$set(form, "active", $$v)},expression:"form.active"}},_vm._l((_vm.selectedFormApis),function(section){return _c('v-list-item',{key:section.value,attrs:{"link":"","active-class":"primary lighten-1 white--text","input-value":_vm.selectedSection === section.value},on:{"click":function($event){_vm.$vuetify.goTo(("#" + (section.value)), {
                duration: 300,
                offset: 0,
                easing: 'easeInOutCubic',
              });
              _vm.selectedSection = section.value;}}},[_c('v-list-item-title',[_vm._v(_vm._s(section.label))])],1)}),1)}),1)],2)],1),_c('v-main',[_vm._l((_vm.generalSections),function(section){return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersect,
        options: {
          threshold: [0, 0.3, 0.5, 0.8, 1.0],
        },
      }),expression:"{\n        handler: onIntersect,\n        options: {\n          threshold: [0, 0.3, 0.5, 0.8, 1.0],\n        },\n      }"}],key:section.value,attrs:{"id":section.value}},[(!section.noContent)?_c('api-block',{attrs:{"section":section},scopedSlots:_vm._u([(section.value === 'authentication')?{key:"prepend-table",fn:function(){return [_c('a',{on:{"click":function($event){_vm.showAuthDialog=true}}},[_vm._v(_vm._s(_vm.$t('api.instruction')))])]},proxy:true}:null,(section.value === 'authentication')?{key:"append-content",fn:function(){return [_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","rounded":""},on:{"click":_vm.addApiKey}},[_vm._v(_vm._s(_vm.$t('api.addKey'))),_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}:null,(section.hasActions)?{key:"table-actions",fn:function(ref){
      var row = ref.row;
      var index = ref.index;
return [_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getAuthMenuItems(
                row.permission
              )),function(menuItem){return _c('v-list-item',{key:menuItem.key,attrs:{"link":""},on:{"click":function($event){return _vm.onCommandHandler(menuItem.key, Object.assign({}, menuItem.params, {appKey:row.appKey, index:index}))}}},[_c('v-list-item-title',{class:((menuItem.color) + "--text")},[_vm._v(_vm._s(menuItem.label))])],1)}),1)]}}:null],null,true)}):_vm._e()],1)}),_vm._l((_vm.selectedFormApis),function(section){return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersect,
        options: {
          threshold: [0, 0.3, 1.0],
        },
      }),expression:"{\n        handler: onIntersect,\n        options: {\n          threshold: [0, 0.3, 1.0],\n        },\n      }"}],key:section.value,attrs:{"id":section.value}},[(!section.noContent)?_c('api-block',{attrs:{"section":section},scopedSlots:_vm._u([{key:"prepend-table",fn:function(){return [(section.path)?_c('url-box',{staticClass:"my-2",attrs:{"url":section.path,"noQRCode":""},scopedSlots:_vm._u([{key:"prepend-outer",fn:function(){return [_c('url-box',{staticClass:"mr-2",attrs:{"url":section.method,"noQRCode":"","canCopy":false,"maxWidth":"55px"}})]},proxy:true}],null,true)}):_vm._e(),(section.path)?_c('span',[_vm._v(_vm._s(_vm.$t('api.requestParam')))]):_vm._e()]},proxy:true}],null,true)}):_vm._e()],1)})],2),_c('confirm-delete-dialog',{ref:"confirmDeleteDialog"}),_c('general-dialog',{attrs:{"dialogTitle":_vm.$t('widgets.remark'),"confirmAction":function (){ return _vm.editRemark(_vm.selectedAuthKeyIndex, _vm.authKeyRemark); }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":_vm.$t('messages.insertText')},model:{value:(_vm.authKeyRemark),callback:function ($$v) {_vm.authKeyRemark=$$v},expression:"authKeyRemark"}})]},proxy:true}]),model:{value:(_vm.showingRemarkDialog),callback:function ($$v) {_vm.showingRemarkDialog=$$v},expression:"showingRemarkDialog"}}),_c('auth-instruction-dialog',{model:{value:(_vm.showAuthDialog),callback:function ($$v) {_vm.showAuthDialog=$$v},expression:"showAuthDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }