<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-progress-circular indeterminate :color="color" :size="size"></v-progress-circular>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
      color: {
          type: String,
          default: 'primary'
      },
      size: {
          type: Number || String,
          default: 32
      }
  },
};
</script>
