<template>
    <div>
        <div>
            <div class="d-flex flex-column">
                <v-list>
                    <v-list-item v-for="folder in folders" :key="folder._id" @click="$router.push({ name: 'archivedFolderApps', params: { id: folder._id } })">
                        <v-list-item-avatar>
                            <v-icon>mdi-folder</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>{{ folder.label }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>
        </div>
        <!-- ARCHIVE BUTTON -->
        <div class="" style="position: fixed; right: 1.5rem; bottom: 1.5rem" @click="closeArchiveFolder">
            <v-btn fab color="primary">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'archivedFolder', 
    data() {
        return {}
    }, 
    computed: {
        user() {
            return this.$store.getters.user;
        },
        folders() {
            let folders = [];
            if ( this.user ) folders = this.user?.preference?.appsLayout?.archives || [];
            return folders;
        }
    },
    methods: {
        closeArchiveFolder: function () {
            this.$router.push({ name: 'myApps' })
        }
    }
}
</script>
