<template>
    <div>
        <defaultBox
            :label="label"
            v-model="value"
            @input="updateFromDefaultBox"
            :fieldInfo="fieldInfo"
        >
        </defaultBox>
    </div>
</template>
  
<script>
    import defaultBox from '../widgetDefaults'
    import mixin from './mixin'
    import {
        FORM_SELECTIONS,
        SET_SETTINGS,
        FORM_SELECTIONS_FIELDS,
    } from '@/store/modules/workflow/action_types';
    import NodeUtils from '@/pages/admin/workflow/utils/node';
    export default {
        mixins: [mixin],
        props:{
            formFieldInfos: Array
        },
        components:{
            defaultBox
        },
        created(){
            const { id } = this.$route.params;
            this.$store.dispatch(FORM_SELECTIONS, id);
        },
        mounted(){
            this.$nextTick(async()=>{
                await this.loadRelatedTableInfos()
                if(this.formData){
                    this.ruleName = this.formData.name
                    this.conditions = this.formData.conditions
                    this.actions = this.formData.actions
                    this.active = this.formData.active
                }
            })
        },
        data () {
            return {
                showingQueryDialog: false,
                copyField: null,
                conditions:[],
                actions: [],
                ruleName: '',
                active: true,
                relatedTableInfos: [],
                value: false,
                formData: null,
            }
        },
        watch:{
            worksheet:{
                handler(){
                    if(this.showingQueryDialog){
                        this.conditions = []
                        this.copyField = null
                    }
                },
                immediate: true
            }
        },
        computed:{
            list() {
                return this.$store.getters.getFormSelectionsFields.children || []
            },
            worksheet(){
                return this.$store.state.workflowTree.setting.worksheet
            },
        },
        methods:{
            updateFromDefaultBox(val){
                this.value = val
                this.$emit('input', val)
            },
            async loadRelatedTableInfos(fieldInfos, callback) {
                const vm = this;
                var formId = this.$route.params.itemId
                const getParams = {
                    urlCommand: "/forms/" + formId + "/relatedTableInfos",
                };
                await vm.$store.dispatch("AUTH_GET", getParams).then((response) => {
                    vm.relatedTableInfos = response.relatedTableInfos
                    vm.list.forEach((item, index, array)=>{
                        if(item.type == 'otherTableField'){
                            var relatedRecordId = item.properties.fieldForRelatedTable
                            var relatedFieldId = item.properties.relatedTableField
                            var relatedRecord = vm.list.find(item=>item._id == relatedRecordId)
                            if(relatedRecord){
                                var relatedTableId = relatedRecord.properties.dataSource
                                var relatedTable = vm.relatedTableInfos[relatedTableId]
                                var relatedField = relatedTable.fieldInfos.find(item=>item._id == relatedFieldId)
                                array[index]['relatedField'] = relatedField
                            }
                        }
                    })

                })
            }
        }
    }
</script>

<style lang="scss">
    .witDefaultValue .v-input__append-outer{
        margin: 0 !important;
    }
    .witDefaultValue .v-input .v-input__control .v-input__slot{
        padding: 0 !important;
    }
    .witDefaultValue .v-text-field .v-input__slot .v-text-field__slot{
        padding-left:6px
    }
    /* .witDefaultValueMenuContent .v-text-field .v-input__slot .v-text-field__slot{
        padding-left:6px
    } */
    .witDefaultValueMenuContent .v-list-item:hover{
        background-color: $primary;
        span{
            color: white;
        }
        .v-icon{
            color: white;
        }
        svg{
            color: white;
        }
    }
    .witDefaultValueMenuContent{
        /* transform: translate(-11px); */
    }
    .witDefaultValueMenuContent .v-list-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height:10px;
        height: auto;
        /* padding: 0px 15px !important; */
    }

    .v-picker__body{
        padding-bottom:8px !important;
        border-bottom: 1px solid #d9d9d9;
    }
    .v-picker__actions{
        padding: 0px !important;
    }
    .v-date-picker-table{
        height: auto !important;
    }
    .v-date-picker-table--date .v-btn{
        height: 25px !important
    }
    .v-date-picker-table--date th{
        padding: 0px !important;
    }
    .ps__rail-y.ps--clicking .ps__thumb-y{
        width: 6px !important
    }
    .ps__rail-y .ps--clicking{
        background-color: transparent !important;
    }
</style>
  