<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
    max-width="400px"
  >
    <v-card>
      <v-card-title>
        {{$t('notification.background.popupTitle')}}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text ref="scrollArea" style="height: 500px;">
      <div class="workflow-notification-progress-list">
        <div v-for="event in events" :key="event.id">
          <component
            :is="targetComponent(event.pushType)"
            :event="event"
            :id="event.id"
          />
          <v-divider />
        </div>
      </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          right
          @click="onClick"
        >
          {{ $t('workflow.btn.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  // import { values } from 'lodash';
  import {
    SHOW_AUTOMATETASK_POPUP,
  } from '@/store/modules/notification/action_types';
  import CustomizedActionTaskManagerContent from './../../customActionButton/manager';
  export default {
    components: {
      CustomizedActionTaskManagerContent,
    },
    computed:{
      events() {
        return this.$store.getters.eventCustomActionButtonProgress;
      },
      show() {
        return this.$store.getters.shouldShowAutomatedTaskPopup;
      },
    },
    methods: {
      targetComponent(type) {
        const lookup = {
          customizedActionProgress: 'CustomizedActionTaskManagerContent',
        };
        return lookup[type];
      },
      onClick() {
        this.$store.dispatch(SHOW_AUTOMATETASK_POPUP, {
          show: false
        });
      },
    },
    watch: {
      show: function(toShow) {
        
        if (toShow) {
          this.$nextTick(() => {
            this.$refs.scrollArea.scrollTo({
              behavior: 'smooth',
              top: this.$refs.scrollArea.scrollHeight,
            });
          });
          // this.$refs.scrollArea.scrollTo({
          //   behavior: 'smooth',
          //   top: 0,
          // });
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  // .workflow-notification-progress-list {
    
  // }
</style>
