<template>
  <div>
    <label>{{ label }}</label>
    <v-select
      :items="items"
      dense
      outlined
      solo
      :placeholder="$t('messages.pleaseSelectTable')"
      :hide-details="true"
      v-model="propertyValue"
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
    >
    </v-select>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'witRelatedTableSelection',
  mixins: [mixin],
  computed: {
    items () {
      return this.formFieldInfos.filter(info => info.type === 'relatedRecord' && info.properties.relatedRecordQuantity==='single')
      .map(info => ({
        text:info.label,
        value:info.fieldId
      }))

    }
  },
  methods: {

  }
}
</script>
