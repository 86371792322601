<template>
  <background
    v-if="publicForm"
    :color="borderColor"
    :headerColor="themeColor"
    :showHeader="showHeader"
    :imageUrl="publicForm.backgroundImage ? publicForm.backgroundImage.imageUrl : ''"
  >
    <template v-slot:header-control>
      <v-row no-gutters class="header-control">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attr }">
            <v-btn
              v-if="isPreview"
              v-on="on"
              v-bind="attr"
              @click="submitted = !submitted"
              color="primary"
            >
              <v-icon>mdi-swap-horizontal</v-icon>
            </v-btn>
          </template>
          {{
            submitted
              ? $t("publicForm.swapToNotSubmitted")
              : $t("publicForm.swapToSubmitted")
          }}
        </v-tooltip>
      </v-row>
    </template>
    <template v-slot:card-content>
      <formCard
        v-if="publicForm"
        :publicForm="publicForm"
        :isEdit="false"
        :color="themeColor"
        :showSubmit="!submitted && (isPreview || isActive)"
        :isSubmitting="isSubmitting"
        :isSubmitDisabled="uploading"
        @commandHandler="onCommandHandler"
      >
        <template v-slot:card-header>
          <v-row
            v-if="!(isPreview || isActive)"
            no-gutters
            class="pa-4"
            align="center"
            justify="center"
            style="min-height:540px"
          >
            <v-col style="text-align:center">
              <div>
                <v-icon size="108" color="error"
                  >mdi-information-outline</v-icon
                >
              </div>
              {{ $t("publicForm.notActive") }}
            </v-col>
          </v-row>
          <v-row
            v-else-if="submitted"
            no-gutters
            class="pa-4"
            align="center"
            justify="center"
          >
            <v-col style="text-align:center">
              <div>
                <v-icon size="108" color="primary"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
              </div>
              <div>
                {{ $t("publicForm.submitted") }}
              </div>
              <v-btn
                v-if="publicForm.limit === 0"
                text
                color="primary"
                @click="reset"
                >{{ $t("publicForm.submitAgain") }}</v-btn
              >
              <div v-if="publicForm.successMsg">
                <v-divider />
                <div class="ql-editor pa-4">
                  <div v-html="publicForm.successMsg"></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:card-content>
          <v-form
            ref="form"
            class="flex-grow-1 d-flex flex-column"
            v-if="!submitted && (isPreview || isActive)"
          >
            <div class="mr-3 mb-3 flex-grow-1 px-5">
              <v-row
                no-gutters
                v-for="(row, index) in publicForm.layout"
                :key="index"
              >
                <template v-for="fieldInfo in getFieldInfosInRow(row)">
                  <input-widget
                    :ref="fieldInfo.fieldId"
                    :key="fieldInfo._id"
                    :formInfo="form"
                    class="py-3"
                    :alias="getAlias(fieldInfo._id)"
                    :customDescription="getDescription(fieldInfo._id)"
                    :recordData="recordData"
                    :relatedTableInfos="relatedTableInfos"
                    :relatedTableData="relatedTableData"
                    :fieldInfo="fieldInfo"
                    :isNew="true"
                    @onCommand="onCommandHandler"
                  ></input-widget>
                </template>
              </v-row>
            </div>
          </v-form>
        </template>
      </formCard>
      <span class="caption">
        <i18n path="general.powerBy">
          <a href="https://yoov.com/" target="_blank">YOOV</a>
        </i18n>
      </span>
    </template>
    <confirmDialog ref="confirmDialog" />
    <FsLightbox
      :toggler="toggler"
      :sources="imageSource"
      :onClose="onLightBoxClose"
      :exitFullscreenOnClose="true"
    />
    <v-menu top offset-y>
      <template v-slot:activator="{ on }">
        <v-btn color="primary" icon fab  v-on="on" fixed dark bottom right
          ><v-icon>mdi-translate</v-icon></v-btn
        >
      </template>
      <v-list>
        <v-list-item-group v-model="$i18n.locale">
        <v-list-item v-for="locale in locales" :key="locale" link :value="locale.value">
          {{locale.text}}
        </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <child-record-dialog ref="childRecordDialog"></child-record-dialog>
    <div v-if="isSubmitting"
      class="d-flex flex-column align-center justify-center"
      style="z-index:9999;position:fixed;width:100%;height:100%;background-color:rgba(100,100,100,.2);">
      <full-card-spinner
        style="z-index:4;"></full-card-spinner>
    </div>
  </background>
</template>
<script>
import FsLightbox from "fslightbox-vue";
import fullCardSpinner from "@/components/FullCardSpinner";
import background from "@/components/public/background";
import formCard from "@/components/public/formCard";
import publicFormMixin from "@/mixins/publicFormMixin";
import inputWidget from "@/components/inputWidgets/InputWidget";
import confirmDialog from "@/components/dialogs/ConfirmDialog";
import childRecordDialog from "@/components/dialogs/ChildRecordDialogPublic";
import { ValidateRule } from "@/helpers/RuleHelper";
import { newBlankRecord } from "@/helpers/FormHelpers";
import locales from "@/const/locales"
import _ from "lodash";

const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

export default {
  mixins: [publicFormMixin],
  components: {
    fullCardSpinner,
    background,
    formCard,
    inputWidget,
    confirmDialog,
    FsLightbox,
    childRecordDialog
  },
  metaInfo() {
    return {
      title: (this.publicForm && this.publicForm.formName) || "",
      titleTemplate: "%s | YOOV+",
      meta: [
        {
          property: "og:title",
          content: (this.publicForm && this.publicForm.formName) || "",
        },
        { property: "og:image", content: this.imageUrl },
        {
          property: "og:url",
          content: this.$store.getters.appHost + this.$route.path,
        },
        { property: "og:type", content: "website" },
      ],
    };
  },
  data() {
    return {
      recordData: {
        _id: "",
      },
      publicForm: null,
      submitted: false,
      relatedTableData: {},
      locales: locales,
      isSubmitting: false,
      uploading: false
    };
  },
  async mounted() {
    const [_, {result, formDefaultValue}] = await Promise.all([
      this.$store.dispatch("FETCH_PUBLIC_WIDGETS"),
      this.$store.dispatch("FETCH_PUBLIC_FORM", {
        urlId: this.$route.params.urlId,
      }),
    ]);
    if (result) {
      this.publicForm = result;
      if (this.$route.name === "PublicForm" && this.publicForm.limit !== 0) {
        let lastSubmittedTime = localStorage.getItem(
          `${this.$route.params.urlId}_lastSubmitted`
        );
        if (lastSubmittedTime) {
          if (this.publicForm.limit === 1) this.submitted = true;
          else if (this.publicForm.limit === 2) {
            let now = new Date();
            lastSubmittedTime = new Date(lastSubmittedTime);
            this.submitted = now - lastSubmittedTime < MILLISECONDS_IN_ONE_DAY;
          }
        }
      }

      if (!this.submitted) {
        this.assignDefaultValue(formDefaultValue);
      }
    } else {
      this.$toast.error(this.$t("publicForm.notFound"), {
        duration: 0,
      });
    }
  },
  computed: {
    form() {
      return this.$store.getters.currentForm;
    },
    imageUrl() {
      return _.has(this.publicForm, "backgroundImage")
        ? `${ this.$store.getters.appHost}/medias/${this.publicForm.backgroundImage}/thumbnail` : ""
    },
    fieldInfos() {
      return _.get(this.form, "fieldInfos", []);
    },
    uiElementInfos() {
      return _.get(this.form, "uiElementInfos", []);
    },
    isPreview() {
      return this.$route.name === "PreviewPublicForm";
    },
    editableFieldIds() {
      let result = [];
      if (!this.publicForm) return result;
      else {
        const visibleFields = this.publicForm.layout.flat(2);
        visibleFields.forEach((layoutItem) => {
          const fieldInfo = this.fieldInfos.find(
            (info) => info._id === layoutItem._id
          );
          if (fieldInfo){
            const authorization = _.get(fieldInfo, "properties.authorization", "").split("|");
            if (
              (!authorization.includes("readonly") &&  !authorization.includes("hidden")) ||
              _.get(fieldInfo, "properties.validation", "").includes("required") ||
              _.get(fieldInfo, "properties.minAttachmentCount", 0) > 0 ||
            _.get(fieldInfo, "properties.maxAttachmentCount", 0) > 0 
            ) {
              result.push(fieldInfo._id);
            }
          }

        });
        return result;
      }
    },
    isActive() {
      return (
        _.get(this.publicForm, "active", false) &&
        _.get(this.publicForm, "form", null) !== null
      );
    },
    relatedTableInfos() {
      return this.$store.getters.relatedTableInfos;
    },
    toggler() {
      return _.get(this.$store.state.media, "toggle"); 
    },
    imageSource() {
      return  _.get(this.$store.state.media, "imageUrls");
    },
    displayRule() {
      return _.get(this.$store.getters.currentForm, "displayRule");
    },
  },
  watch: {
    "$i18n.locale"(val){
      this.setValidationsLocale()
    },
    recordData: {
      handler() {
        let vm = this;
        if (this.displayRule)
          this.displayRule
            .filter((item) => item.active)
            .forEach((rule) => {
              let bl_pass = false;
              var conditions = rule.conditions;
              bl_pass = conditions.some((orConds) => {
                var res = orConds.every((andCond) => {
                  var hostField = vm.recordData[andCond.fieldId];
                  var compareField =
                    andCond.content.type == 1
                      ? andCond.content.value
                      : vm.recordData[andCond.content.value];
                  return ValidateRule(
                    hostField,
                    compareField,
                    andCond.content.rule,
                    andCond.fieldType
                  );
                });
                if (res) return true;
              });
              // action implement if rule return true
              rule.actions.forEach((action) => {
                switch (action.actionId) {
                  case "1":
                    vm.handleActionVisible(action.fields, bl_pass);
                    break;
                  case "2":
                    vm.handleActionHidden(action.fields, bl_pass);
                    break;
                  case "3":
                    //vm.handleActionAlert(action.fields, bl_pass);
                    break;
                }
              });
            });
      },
      deep: true,
    },
  },
  methods: {
    setValidationsLocale(){
      const vm = this;
      const fieldIds = vm.editableFieldIds;
      fieldIds.forEach((f) => {
        if(vm.$refs[f] && vm.$refs[f][0]) {
          // console.log('f::'+f, vm.$refs[f][0])
          // console.log(vm.$refs[f][0].$refs.inputWidget.hasError)
          if(vm.$refs[f][0].$refs.inputWidget.hasError){
            vm.$refs[f][0].validate()
          }
        }
      });
    },
    onLightBoxClose() {
      this.$store.dispatch("RESET_MEDIA");
    },
    getFieldInfosInRow(row) {
      const result = row.map((item) => {
        let result =
          item.controlType === "static"
            ? this.uiElementInfos.find((info) => info._id === item._id)
            : this.fieldInfos.find((info) => info._id === item._id);

        result.properties.width = item.width;
        return result;
      });
      return result;
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "openChildRecordDialog":
          this.openChildRecordDialog(payload);
          break;
        case "updateFieldValue":
          this.updateFieldValue(payload);
          break;
        case "confirm":
          this.onSubmit();
          break;
        case "startUploading":
          this.uploading = true;
          break;
        case "finishUploading":
          this.uploading = false;
          break;
        case "setRelatedTable":
        if (typeof payload.cb === 'function'){
          payload.cb()
        }
        break;
      }
    },
    updateFieldValue(payload) {
      this.$set(this.recordData, payload.fieldName, payload.fieldValue);
    },
    async onSubmit() {
      if (this.$route.name === "PreviewPublicForm") {
        this.$refs.confirmDialog.open(
          this.$t("publicForm.preview"),
          this.$t("publicForm.previewError")
        );
      } else {
        await this.save();
      }
    },
    async reset() {    
      const {formDefaultValue} = await this.$store.dispatch("FETCH_PUBLIC_FORM", {
        urlId: this.$route.params.urlId,
      })
      this.assignDefaultValue(formDefaultValue);
      this.submitted = false;
    },
    async validateAll() {
      const vm = this;
      const fieldIds = vm.editableFieldIds;
      let errors = 0;
      let promises = [];
      fieldIds.forEach((f) => {
        if (vm.$refs[f] && vm.$refs[f][0]) {
          promises.push(() => vm.$refs[f][0].validate());
        }
      });
      const arrayOfPromises = promises.map((promise) => promise());
      const results = await Promise.all(arrayOfPromises);
      errors = results.filter((result) => !result).length;
      return errors;
    },
    async save() {
      const errors = await this.validateAll();
      if (errors === 0) {
        this.isSubmitting = true
        const url =
          this.$store.getters.appHost +
          "/public/form/" +
          this.$route.params.urlId;
        const data = JSON.parse(JSON.stringify(this.recordData));
        Object.assign(data, {
          formId: this.publicForm.formId,
          appId: this.publicForm.appId,
        });
        const postData = {
          data,
        };
        if (this.$route.query.source) {
          Object.assign(postData, {
            source: this.$route.query.source,
          });
        }

        const res = await this.axios.post(url, postData);
        if (res) {
          this.submitted = true;
          localStorage.setItem(
            `${this.$route.params.urlId}_lastSubmitted`,
            new Date().toISOString()
          );
        }
        this.isSubmitting = false
      }
    },
    getAlias(fieldId) {
      return this.publicForm.customFieldProperties &&
        this.publicForm.customFieldProperties[fieldId]
        ? this.publicForm.customFieldProperties[fieldId].alias
        : "";
    },
    getDescription(fieldId) {
      if (
        this.publicForm.customFieldProperties &&
        this.publicForm.customFieldProperties[fieldId] &&
        this.publicForm.customFieldProperties[fieldId].hasOwnProperty(
          "description"
        )
      ) {
        return this.publicForm.customFieldProperties[fieldId].description;
      } else null;
    },
    handleActionVisible(fields, bl_pass) {
      let vm = this;
      fields.forEach((fieldId) => {
        var res = vm.fieldInfos.concat(vm.uiElementInfos).find((field) => field._id == fieldId);
        if (res) {
          var authorization = [];
          if (res.properties.authorization)
            authorization = res.properties.authorization.split("||");
          if (authorization.length == 1 && authorization[0] == "")
            authorization = [];
          if (bl_pass) {
            var pos = authorization.indexOf("hidden");
            if (pos != -1) {
              authorization.splice(pos, 1);
            }
          } else {
            if (!authorization.includes("hidden")) authorization.push("hidden");
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, "authorization", authorization.join("||"));
        }
      });
    },
    handleActionHidden(fields, bl_pass) {
      let vm = this;
      fields.forEach((fieldId) => {
        var res = vm.fieldInfos.concat(vm.uiElementInfos).find((field) => field._id == fieldId);
        if (res) {
          var authorization = [];
          if (res.properties.authorization)
            authorization = res.properties.authorization.split("||");
          // var authorization = res.properties.authorization.split("||")
          if (authorization.length == 1 && authorization[0] == "")
            authorization = [];
          if (bl_pass) {
            if (!authorization.includes("hidden")) authorization.push("hidden");
          } else {
            var pos = authorization.indexOf("hidden");
            if (pos != -1) {
              authorization.splice(pos, 1);
            }
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, "authorization", authorization.join("||"));
        }
      });
    },
    openChildRecordDialog(payload) {
      this.$refs.childRecordDialog.open(payload);
    },
    assignDefaultValue(defaultValues = {}) {
      const relatedRecordFields = this.fieldInfos.filter(info => info.type === "relatedRecord").map(info => info.fieldId);
      relatedRecordFields.forEach(fieldId => {
        const defaultValue = _.chain(defaultValues[fieldId]).map(record=> _.get(record, "_id")).compact().value();
        defaultValues[fieldId] = defaultValue;
      })
       Object.assign(this.recordData, defaultValues);
       this.recordData = newBlankRecord(this.fieldInfos, this.recordData);
    }
  },
};
</script>
<style scoped>
.header-control {
  position: absolute;
  top: 17px;
  right: 24px;
}
</style>
