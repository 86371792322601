<template>
  <div class="pa-4">
    <slot name="top-content">
    <v-row no-gutters class="my-2">
      <span class="text-subtitle-1">{{ $t("general.field") }}</span>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on, attr }">
          <v-btn icon small @click="hideAll" v-on="on" v-bind="attr">
            <v-icon small>mdi-eye-off-outline</v-icon>
          </v-btn>
        </template>
        {{ $t("publicForm.hideAll") }}
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attr }">
          <v-btn icon small @click="reset" v-on="on" v-bind="attr">
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </template>
        {{ $t("publicForm.resetLayout") }}
      </v-tooltip>
    </v-row>
    <v-row no-gutters class="my-2">
      <span class="text-body-2 grey--text">{{
        $t("publicForm.layoutDescription")
      }}</span>
    </v-row>
    </slot>
    <div>
      <drag
        v-for="field in fieldOptions"
        :data="field"
        :key="field._id"
        @cut="() => {}"
      >
        <div class="pa-1">
          <v-row no-gutters class="option-box pr-1" align="center" justify="center">
            <v-col cols="12" class="pa-0">
              <v-row no-gutters>
                <slot name="option-content" :field="field">
                  <v-col
                    cols="1"
                    class="pr-1"
                    v-if="field.description !== getDefaultDescription(field._id)"
                  >
                    <v-icon small class="descriptionIndicator">mdi-information-outline</v-icon>
                  </v-col>
                  <v-col cols="1">
                    <font-awesome-icon
                      :icon="getIcon(field.dataType)"
                      class="mr-2"
                    ></font-awesome-icon>
                  </v-col>
                  <v-col :cols="isPublicEdit?4:undefined" align-self="center">
                    <div style="line-height:normal;overflow: hidden;
                    text-overflow: ellipsis;white-space: nowrap;">
                      <span
                        >{{ field.label
                        }}<span v-if="field.alias && field.alias !== field.label">
                          ({{ field.alias }})
                        </span></span
                      >
                    </div>
                  </v-col>
                  <v-col :cols="isPublicEdit?(field.description !== getDefaultDescription(field._id)?6:7):(field.description !== getDefaultDescription(field._id)?2:3)" class="d-flex justify-end align-center">
                    <v-tooltip top v-if="field.controlType === 'data'">
                      <template v-slot:activator="{ on, attr }">
                        <v-btn icon v-on="on" v-bind="attr" small>
                          <v-icon
                            small
                            @click="openEditCustomPropsDialog(field._id)"
                            >mdi-pencil-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      {{ $t("publicForm.alias") }}
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attr }">
                        <v-btn icon v-on="on" v-bind="attr" small>
                          <v-icon @click="appendToLayout(field)" small
                            >mdi-plus</v-icon
                          >
                        </v-btn>
                      </template>
                      {{ $t("publicForm.append") }}
                    </v-tooltip>
                  </v-col>
                </slot>
              </v-row>
              <v-row no-gutters v-if="field.dataType === 'attachments' && isPublicEdit" class="pb-2">
                <v-col class="pa-0" style="height: 100%;line-height: 1.9;">
                  <div class="grey--text">{{$t('publicEdit.allow')}}:</div>
                </v-col>
                <v-spacer/>
                <v-col class="pa-0 mr-2">
                  <v-checkbox @change="handleCustomPropertyUpdate('deletable', field, $event)" v-model="field.deletable" :ripple="false" class="ma-0 pa-0" :label="$t('publicForm.deletable')" dense hide-details>
                  </v-checkbox> 
                </v-col>
                <v-col class="pa-0 pr-3 d-flex justify-end align-center">
                  <v-checkbox @change="handleCustomPropertyUpdate('uploadable', field, $event)" v-model="field.uploadable" :ripple="false" class="ma-0 pa-0" :label="$t('publicForm.uploadable')" dense hide-details>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="isPublicEdit && editableTypes.includes(field.dataType)" class="pb-2 d-flex align-start">
                <v-col class="pa-0" style="height: 100%;line-height: 1.9;">
                  <div class="grey--text">{{$t('publicEdit.allow')}}:</div>
                </v-col>
                <v-col class="pa-0 d-flex justify-end">
                  <v-checkbox
                    @change="handleCustomPropertyUpdate('editable', field, $event)" 
                    v-model="field.editable" 
                    :ripple="false" 
                    class="ma-0 pa-0 pr-1" 
                    hide-details 
                    :label="$t('publicForm.editable')" 
                    dense
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </drag>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fieldOptions: Array,
    publicForm: Object,
    'content-class': String,
    isPublicEdit:{
      type: Boolean,
      default: false
    },
    editableTypes:{
      type: Array,
      default: []
    }
  },
  methods: {
    handleCustomPropertyUpdate(key, field, val){
      var data = {
        alias: field.alias,
        description: field.description,
        editable: field.editable,
        deletable: field.deletable,
        uploadable: field.uploadable
      }
      data[key] = val 
      this.$emit("commandHandler", {
        command: "saveCustomProps",
        publicForm: this.publicForm,
        fieldId: field._id,
        value: data
      });
    },
    appendToLayout(field) {
      this.$emit("commandHandler", {
        command: "appendToLayout",
        field,
      });
    },
    getDefaultDescription(fieldId) {
      const fieldInfo = this.$store.getters.formFieldInfos.find(
        (field) => field._id === fieldId
      );
      if (fieldInfo) {
        return fieldInfo.properties.description;
      } else return "";
    },
    getIcon(type) {
      return this.$store.getters.widgetIconMap[type];
    },
    openEditCustomPropsDialog(fieldId) {
      this.$emit("commandHandler", {
        command: "openEditCustomPropsDialog",
        fieldId,
      });
    },
    reset() {
      this.$emit("commandHandler", {
        command: "reset",
      });
    },
    hideAll() {
      this.$emit("commandHandler", {
        command: "hideAll",
      });
    },
  },
};
</script>

<style>
  .descriptionIndicator{
    justify-content: start !important;
  }
</style>