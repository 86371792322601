<template>
  <div>
    <FormElement
      :label="label || $t('workflow.modules.retrieveRecord.label.method')"
      name='sourceType'
    >
      <ComboBox
        :value="value || null"
        :options="list"
        :placeholder="$t('workflow.modules.retrieveRecord.label.placeholder')"
        valueFormat="id"
        @changed="changed"
      />
    </FormElement>
  </div>
</template>

<script>
import ComboBox from './../../../form/comboBox/';
import FormElement from './formElement';
import Mixin from './../mixin';
import {
  FORM_SELECTIONS,
  FORM_SELECTIONS_FIELDS,
  FETCH_WORKFLOW_DEPENDENCIES,
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';

import {
  map,
  omit,
  get,
} from 'lodash';
export default {
  mixins: [Mixin],
  data() {
    return {
      list: [
        {
          id: 'retrieveRecordFromTableSource',
          label: this.$t('workflow.label.retrieveRecordFromTableSource'),
        },
        {
          id: 'retrieveRecordFromDataSource',
          label: this.$t('workflow.label.retrieveRecordFromDataSource'),
        },
        {
          id: 'retrieveRecordFromRelationship',
          label: this.$t('workflow.label.retrieveRecordFromRelationship'),
        },
        {
          id: 'retrieveRecordDirectlyPick',
          label: this.$t('workflow.label.retrieveRecordDirectlyPick'),
        },
        // {  // use dependencies can do this
        //   id: 'retrieveRecordsFromCreateRecord',
        //   label: this.$t('workflow.label.retrieveRecordsFromCreateRecord'),
        // },
        // {  // use dependencies can do this
        //   id: 'retrieveRecordsFromManualNode',
        //   label: this.$t('workflow.label.retrieveRecordsFromManualNode'),
        // },
      ],
    };
  },
  props: {
    // setting: Object,
    // worksheet: String,
    value: String,
  },
  computed: {
    list() {
      return this.$store.getters.getFormSelections;
    },
    dependencies() {
      const dependencies = get(this.$store.getters.getDependencies, '[0]');
      return dependencies ? dependencies.children : [];
    },
  },
  methods: {
    changed(v) {
      // this.worksheet = this.selected;
      this.$store.dispatch(SET_SETTINGS, {
        sourceType: v,
      });
      // this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
      //   workflow: this.workflow._id,
      //   form: v._id,
      // });

      // if (v) {
      //   this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
      //     appId: this.appId,
      //     formId: v._id,
      //   });
      // }
      // this.cancelChange();
    }
  },
  components: {
    ComboBox,
    FormElement,
  },
  // beforeCreate() {
  //   if (!this.dependencies || !this.dependencies.length) {
  //     this.$store.dispatch(FORM_SELECTIONS);
  //   }
  // },
  created() {
    if (this.setting.worksheet) {
      this.$store.dispatch(FORM_SELECTIONS_FIELDS, { appId: this.appId, formId: this.setting.worksheet._id });
    }
  },
  // watch: {
  //   worksheet: {
  //     handler(wasId, id) {
  //       console.log('>>>>>');
  //     },
  //     deep: true,
  //   }
  // },
};

</script>

<style scoped>
  .w-control-remove-title {
    color: rgb(244, 67, 54);
    margin: 5px 0 30px 0;
    font-size: 11px;
  }
  .w-control-remove-btn-confirm {
    color: white;
  }
</style>
