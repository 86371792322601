<template>
  <div
    style="position: relative;"
    class="layout-widget"
    @mouseenter="showingWidthSettingBar = true"
    @mouseleave="showingWidthSettingBar = false"
    :class="{ selected: selectedItem && selectedItem._id === widgetItem._id }"
  >
    <component
      v-if="inputWidget && fieldInfo"
      v-bind:is="inputWidget"
      :fieldInfo="fieldInfo"
      :formInfo="formInfo"
      :isLayout="true"
      :relatedTableInfos="relatedTableInfos"
      :isHidden="isHidden"
    ></component>
    <div style="width:0; min-width:100%; word-wrap: break-word">
      <div class="px-2 grey--text" style="font-size: 12px" v-text="fieldDescription"></div>
    </div>
    
    <div class="widget-mask" @click="selectWidget"></div>

    <div v-if="hasAdjustableWidth && showingWidthSettingBar"
         class="width-button-wrapper w-100 widget-mask-over d-flex flex-row align-center justify-center"
         style="cursor:pointer;position:absolute;right:0;bottom:0;left:0;text-align:center;">
      <layout-widget-width-button
          v-for="widthButton in WIDTH_BUTTONS"
          :key="widthButton.width"
          :selected="widthButton.width===width"
          @click="setWidth(widthButton.param)"
          v-html="widthButton.label"></layout-widget-width-button>
    </div>

    <div class="widget-toolbuttons widget-mask-over" style="position:absolute;right:5px;top:0;">
      <div class="d-flex flex-row justify-end">
        <v-spacer v-if="debugging"></v-spacer>
        <v-chip v-if="debugging" class="py-0 mr-1" height="6" small color="primary">{{ fieldInfo.fieldId }}</v-chip>
        <v-spacer></v-spacer>

        <v-btn 
          v-if="canCopy" 
          class="lightgrey px-0"  x-small dense style="min-width:24px;" @click="duplicateWidget">
          <v-icon x-small>mdi-content-copy</v-icon>
        </v-btn>
        <!-- Set Title -->
        <v-btn v-if="canBeTitle"
             :class="widgetItem.controlType==='data'&&widgetItem._id===formInfo.titleFieldInfoId ? 'primary white--text' : 'lightgrey'"
             class="px-1"
             x-small
             dense
             @click="setTitle"
             style="min-width:24px;font-size:14px;">{{$t('general.title')}}</v-btn>
        <!-- Remove -->
        <v-btn class="lightgrey px-0" x-small dense @click="deleteWidgetItem" style="min-width:24px;">
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <!--<draggable-splitter v-if="hasAdjustableWidth"></draggable-splitter>-->
  </div>
</template>

<script>
import draggableSplitter from './comps/DraggableSplitter'
import layoutWidgets from './comps/layoutWidgets'
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import inputWidgets from '@/components/inputWidgets'
import layoutWidgetWidthButton from './LayoutWidgetWidthButton'
// import {ucFirst} from '@/helpers'

export default {
  components: {
    draggableSplitter,
    ...layoutWidgets,
    ...inputWidgets,
    ConfirmDeleteDialog,
    layoutWidgetWidthButton
  },
  props: {
    rowOccupiedWidth: {
      type: Number,
      default: 12
    },
    
    idWidthArray: {
      type: Array,
      default () {
        return [] 
        // array of {
        //   id: ''
        //   width: 11
        // }
      }
    },

    form: {
      type: Object,
      default: null
    },
    widgetItem: {
      type: Object,
      default: null
    },
    selectedItem: {
      type: Object,
      default: null
    },
    rowIndex: {
      type: Number,
      default: null
    },
    itemIndex: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      showingWidthSettingBar: false,
      WIDTH_BUTTONS: [
        {width: 12, param: 0, label: '&harr;'},
        {width: 9, param: 9, label: '3/4'},
        {width: 8, param: 8, label: '2/3'},
        {width: 6, param: 6, label: '1/2'},
        {width: 4, param: 4, label: '1/3'},
        {width: 3, param: 3, label: '1/4'},
        {width: 2, param: 2, label: '1/6'}
      ]
    }
  },
  methods: {
    setWidth (width) {
      const vm = this
      // if passed 0, stretch to the most
      if (width === 0) {
        vm.stretchTheMost()
      } else {
        // increase widget width
        if (width > vm.width) {
          var diff = width - vm.width
          if (diff <= vm.remainingWidth) {
            // if remaining width enought for the increase
            // update widget item with new width
            vm.setFieldInfoWidth(width)
          } else if (diff <= vm.maxAvailWidthOnRight) {
            var required = diff - vm.remainingWidth
            // if remainig width is enougth 
            // after reduce some widget widths redued to min 2
            //
            // check one by one
            for (let i = vm.itemIndex + 1; i < vm.idWidthArray.length; i++) {
              const itemId = vm.idWidthArray[i]._id
              const itemWidth = vm.idWidthArray[i].width
              const avail = itemWidth - 2
              if (required <= avail) {
                vm.setFieldInfoWidth(itemWidth - required, itemId)
                break
              } else {
                vm.setFieldInfoWidth(2, itemId)
                required -= avail
              }
            }
            vm.setFieldInfoWidth(width)
          }
        } else {
          // decrease widget width
          vm.setFieldInfoWidth(width)
        }
      }
    },
    setFieldInfoWidth( width, itemId) {
      const vm = this
      if (typeof itemId === 'undefined') {
        itemId = vm.widgetItem._id
      }
      vm.$store.dispatch('SET_FIELD_INFO_PROPERTY', {
        fieldInfoId: itemId,
        propertyKey: 'width',
        propertyValue: width
      })
    },
    stretchTheMost () {
      const vm = this
      console.log('stretchTheMost :: remainingWidth = ' + vm.remainingWidth)
      if (vm.remainingWidth > 0) {
        vm.setFieldInfoWidth(vm.width + vm.remainingWidth)
      } else {
        console.log('stretchTheMost :: vm.maxAvailWidthOnRight = ' + vm.maxAvailWidthOnRight)
        if (vm.maxAvailWidthOnRight > 0) {
          vm.setFieldInfoWidth(vm.width + vm.maxAvailWidthOnRight)
          for (let i = vm.itemIndex + 1; i < vm.idWidthArray.length; i++) {
            vm.setFieldInfoWidth(2, vm.idWidthArray[i]._id)
          }
        } else if (vm.maxAvailWidthOnLeft > 0) {
          for (let i = 0; i < vm.itemIndex; i++) {
            const itemId = vm.idWidthArray[i]._id
            console.log('stretchTheMost i=' + i + ': => 2')
            vm.setFieldInfoWidth(2, itemId)
          }
          vm.setFieldInfoWidth(vm.width + vm.maxAvailWidthOnLeft)
        }
      }
      // vm.$store.dispatch(
      //   'ADJUST_WIDGET_WIDTH_THE_MOST',
      //   {fieldInfoId: vm.widgetItem._id}
      // )
        // .then(
        // () => {
        //   // alert('ADJUST_WIDGET_WIDTH_THE_MOST')
        //   vm.$emit('onCommand', {
        //     command: 'updateSplitters',
        //     rowIndex: vm.rowIndex
        //   })
        // }
      // )
    },
    ucFirst (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    deleteWidgetItem () {
      const vm = this
      var hasTwoWayRelation = false
      if (vm.widget && 
        (
          vm.widget.key === 'relatedRecord' || 
          vm.widget.key === 'relatedMultipleRecords'
        )
      ) {
        if (vm.fieldInfo.properties.twoWayRelated.split('|')[0] === '1') {
          hasTwoWayRelation = true
        }
      }

      if (hasTwoWayRelation) {
        vm.$refs.confirmDeleteDialog.confirm((res) => {
          console.log('deleteWidgetItem (relatedRecord) res: ', res)
          if (res.result === 'both') {
            console.log('deleteWidgetItem (relatedRecord) res.result===both')
            this.$store.dispatch('ADD_FORM_OPTIONS', {
              command: 'deleteFormItem',
              formId: vm.fieldInfo.properties.dataSource,
              fieldId: vm.fieldInfo.properties.twoWayRelated.split('|')[1]
            })                          
          } else {
            this.$store.dispatch('ADD_FORM_OPTIONS', {
              command: 'updateFieldProperty',
              formId: vm.fieldInfo.properties.dataSource,
              fieldId: vm.fieldInfo.properties.twoWayRelated.split('|')[1],
              propertyKey: 'twoWayRelated',
              propertyValue: '0|' + vm.fieldInfo.fieldId
            })                          
          }
          vm.$store.dispatch('REMOVE_FIELD', {fieldInfoId: vm.widgetItem._id})
        }, {
          message: vm.$t('messages.allLinkedRelatedFieldsWillBeAlsoRemoved'),
          buttons: [
            {label: vm.$t('buttons.deleteThisComponentOnly'), value: 'this'},
            {label: vm.$t('buttons.deleteAlsoRelatedComponent'), value: 'both'}
          ],
          width: '560'
        })
      } else {
        vm.$refs.confirmDeleteDialog.confirm(()=>{
          vm.$store.dispatch('REMOVE_FIELD', {fieldInfoId: vm.widgetItem._id})
        }, {
          message: vm.$t('messages.relatedDataCannotBeRecoveredAfterDeletion')
        })
      }
    },
    setTitle () {
      const vm = this
      vm.$store.dispatch('SET_FORM_INFO_PROPERTY', {
        propertyKey: 'titleFieldInfoId',
        propertyValue: vm.widgetItem._id
      })
    },
    selectWidget () {
      const vm = this
      // console.log('LayoutWidget.vue :: selectWidget : vm.widgetItem :', vm.widgetItem)
      vm.$store.dispatch('SET_SELECTED_FORM_FIELD_ID', vm.widgetItem._id)
    },
    duplicateWidget() {
      this.$store.dispatch('DUPLICATE_LAYOUT_ITEM', {
        fieldInfo: this.fieldInfo,
        layoutItem: this.widgetItem,
        rowIndex: this.rowIndex + 1
      })
    }

  },
  computed: {
    authorization() {
      const vm = this;
      var result = [];
      if (vm.fieldInfo.properties && vm.fieldInfo.properties.authorization) {
        var auth = this.fieldInfo.properties.authorization.split("||");
        if (auth.length > 0 && auth[0] !== "") {
          result = auth;
        }
      }
      return result;
    },
    isHidden() {
      return this.authorization.includes("hidden")
    },
    debugging () {
      return this.$store.getters.debugging
    },
    fieldDescription () {
      const vm = this
      var result = ''
      if (vm.fieldInfo) {
        result = vm.fieldInfo.properties.description
      }
      return result
    },

    maxAvailWidthOnLeft () {
      const vm = this
      const minOccupiedWidthOnLeft = vm.itemIndex*2
      
      var leftOccupiedWidth = 0
      for (let i =0; i < vm.itemIndex; i++) {
        leftOccupiedWidth += vm.idWidthArray[i].width
      }      
      return leftOccupiedWidth - minOccupiedWidthOnLeft
    },

    maxAvailWidthOnRight () {
      const vm = this
      const minOccupiedWidthOnRight = (vm.idWidthArray.length - vm.itemIndex - 1)*2
      
      var leftOccupiedWidth = 0
      for (let i =0; i <= vm.itemIndex; i++) {
        leftOccupiedWidth += vm.idWidthArray[i].width
      }      
      return 12 - minOccupiedWidthOnRight - leftOccupiedWidth
    },

    remainingWidth () {
      const vm = this
      return 12 - vm.rowOccupiedWidth
    },
    canCopy(){
      return( this.fieldInfo.type!=='relatedRecord' ||  this.fieldInfo.properties.dataSource!==this.form._id) && !this.widget.disabled
    },
    canBeTitle () {
      const vm = this
      var result = true
      if (vm.widgetItem && vm.widget) {
        if (vm.widgetItem.controlType!=='data'|| (vm.widget.hasOwnProperty('title') && (vm.widget.title === false))) {
          result = false
        }
      }
      return result
    },
    widget () {
      const vm = this
      var result = null
      if (vm.fieldInfo) {
        result = vm.$store.getters.allWidgets.find(w => w._id === vm.fieldInfo.widgetId)
      }
      return result
    },
    formUIElementInfos () {
      const vm = this
      return vm.$store.getters.formUIElementInfos
    },

    formFieldInfos () {
      const vm = this
      return vm.$store.getters.formFieldInfos
    },

    formInfo () {
      const vm = this
      return vm.$store.getters.formInfo
    },

    inputWidget () {
      const vm = this
      var result = ''
      if (vm.fieldInfo) {
        result = 'inputWidget' + vm.ucFirst(vm.fieldInfo.type.replace('_', ''))
      }
      // const widgetName = 'layoutWidget' + vm.ucFirst(vm.fieldInfo.type)
      // console.log('LayoutWidget :: inputWidget :: widgetName = ' + widgetName)
      return result
    },
    width () {
      const vm = this
      var result = null
      if (vm.fieldInfo) {
        if (Object.keys(vm.fieldInfo.properties).indexOf('width') >= 0) {
          result = vm.fieldInfo.properties['width']
        }
      }
      return result
    },
    hasAdjustableWidth () {
      return this.width !== null
    },
    fieldInfo () {
      const vm = this
      var result = vm.formFieldInfos.find(info => info._id === vm.widgetItem._id)
      if (!result) {
        result = vm.formUIElementInfos.find(info => info._id === vm.widgetItem._id)
      }
      return result
    },
    widgetLabel () {
      const vm = this
      var result = ''
      if (vm.fieldInfo) {
        result = vm.fieldInfo.label
      }
      return result
    },
    relatedTableInfos() {
      return this.$store.getters.relatedTableInfos;
    },
    placeholder () {
      const vm = this
      var result = ''
      if (vm.fieldInfo) {
        if (vm.fieldInfo.properties && vm.fieldInfo.properties.placeholder) {
          result = vm.fieldInfo.properties.placeholder
        }
      }
      return result
    }
  }
}
</script>

<style>
.layout-widget .widget-mask {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
  /* rgba(13, 71, 161, .5); */
  color: white;
  cursor: pointer;
  z-index: 0;
}

.layout-widget .widget-mask-over {
  z-index: 1;
}

.layout-widget {
  background-color: #f5f5f5;
  padding: 1px;
  min-height: 70px;
  /*3px 5px 5px 5px;*/
}


.layout-widget:hover .widget-mask {
  background-color: rgba(207,231,252, .3);
}
.layout-widget.selected:hover .widget-mask {
  background-color: rgba(187,222,251,.3);
}
.layout-widget.selected {
  background-color: #BBDEFB;
}

/*.layout-widget .v-text-field__details {*/
  /*display: none;*/

/*}*/

.form-layout .is-layout .layout-widget {
  padding-bottom: 0 !important;
}
.width-button-wrapper .layout-widget-width-button:last-child {
  margin-right: 0;
}
.width-button-wrapper .layout-widget-width-button {
  margin-right: 1px;
}

.layout-widget:hover .width-button-wrapper,
.layout-widget:hover .widget-toolbuttons {
  display: flex !important;
}


.layout-widget .width-button-wrapper,
.layout-widget .widget-toolbuttons {
  display: none !important;
}
</style>
