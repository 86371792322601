<template>
  <div class="view-settings-panel-settings flex-grow-1 d-flex flex-column px-2">
    <settings-header>{{ $t('view.grouping') }}</settings-header>
    <v-container>
      <!--singleSelectionFields-->
      <settings-select-row
          ref="groupingFieldId"
          labelTag="view.groupingField"
          :options="groupingFields"
          :value="view.groupingFieldId"
          @input="val=>updateSetting('groupingFieldId', val)"></settings-select-row>
      <settings-yes-no-row
          ref="groupingIncludeNoDataGroup"
          :disabled="!isSingleSelection"
          labelTag="view.includeNoDataGroup"
          :value="view.groupingIncludeNoDataGroup"
          @input="val=>updateSetting('groupingIncludeNoDataGroup', val)"></settings-yes-no-row>
    </v-container>

    <settings-header>{{ $t('view.cardOutlook') }}</settings-header>
    <v-container>
      <settings-select-row
          ref="cardSummaryFieldId"
          labelTag="view.summaryField"
          :options="allEntryFields"
          :value="view.cardSummaryFieldId"
          @input="val=>updateSetting('cardSummaryFieldId', val)"></settings-select-row>
      <settings-select-row
          ref="cardImageFieldId"
          labelTag="view.imageField"
          :options="imageFields"
          :value="view.cardImageFieldId"
          @input="val=>updateSetting('cardImageFieldId', val)"></settings-select-row>
      <settings-select-row
          ref="cardImagePosition"
          labelTag="view.imagePosition"
          :options="imagePositionOptions"
          :value="view.cardImagePosition"
          @input="val=>updateSetting('cardImagePosition', val)"></settings-select-row>
      <settings-select-row
          ref="cardImageMode"
          labelTag="view.imageDisplayMode"
          :options="displayModeOptions"
          :value="view.cardImageMode"
          @input="val=>updateSetting('cardImageMode', val)"></settings-select-row>
      <settings-yes-no-row
          ref="cardDisplayFieldName"
          labelTag="view.displayFieldName"
          :value="view.cardDisplayFieldName"
          @input="val=>updateSetting('cardDisplayFieldName', val)"></settings-yes-no-row>

    </v-container>
  </div>
</template>

<script>
  import settingsHeader from './comps/SettingsHeader'
  import settingsYesNoRow from './comps/SettingsYesNoRow'
  import settingsSelectRow from './comps/SettingsSelectRow'
  import {getFieldPropertyValue} from "@/helpers/ObjectHelpers";
  import mixin from './mixin'

  export default {
    name: 'viewSettingsPanelSettings',
    mixins: [mixin],
    components: {
      settingsHeader,
      settingsYesNoRow,
      settingsSelectRow
    },
    props: {
      view: null
    },
    computed: {
      isSingleSelection () {
        return this.groupingFieldInfo.type === 'singleSelection'
      },
      groupingFieldInfo () {
        const vm = this
        return vm.fieldInfos.find(info => info.fieldId === vm.view.groupingFieldId)
      },

      allEntryFields () {
        const vm = this
        var result = [{
          text: '(' + vm.$t('view.noNeed') + ')',
          value: ''
        }]
        for (var i = 0; i < vm.fieldInfos.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i]
          if (loopFieldInfo.widgetId && loopFieldInfo.widgetId !== '') {
            result.push({
              text: loopFieldInfo.label,
              value: loopFieldInfo.fieldId
            })
          }
        }
        return result
      },

      imageFields () {
        const vm = this
        var result = [{
          text: '(' + vm.$t('view.noNeed') + ')',
          value: ''
        }]
        for (var i = 0; i < vm.fieldInfos.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i]
          if (loopFieldInfo.type === 'attachments') {
            result.push({
              text: loopFieldInfo.properties['fieldName'],
              value: loopFieldInfo.fieldId
            })
          }
        }
        return result
      },

      imagePositionOptions () {
        const vm = this
        return [
          {
            text: vm.$t('view.top'), value: 'top', modes: [
              {text: vm.$t('view.cover'), value: 'cover'},
              {text: vm.$t('view.contain'), value: 'contain'}
            ]
          },
          {
            text: vm.$t('view.left'), value: 'left', modes: [
              {text: vm.$t('view.cover'), value: 'cover'},
              {text: vm.$t('view.contain'), value: 'contain'},
              {text: vm.$t('view.circle'), value: 'circle'},
              {text: vm.$t('view.square'), value: 'square'}
            ]
          },
          {
            text: vm.$t('view.right'), value: 'right', modes: [
              {text: vm.$t('view.cover'), value: 'cover'},
              {text: vm.$t('view.contain'), value: 'contain'},
              {text: vm.$t('view.circle'), value: 'circle'},
              {text: vm.$t('view.square'), value: 'square'}
            ]
          }
        ]
      },

      groupingFields () {
        const vm = this
        var result = []
        for (var i = 0; i < vm.fieldInfos.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i]
          switch (loopFieldInfo.type) {
            case 'singleSelection':
              result.push({
                text: loopFieldInfo.properties['fieldName'],
                value: loopFieldInfo.fieldId
              })
              break
            case 'members':
            case 'departments':
              const singleOrMultiple = getFieldPropertyValue(loopFieldInfo, 'fieldType', '')
              if (singleOrMultiple === 'single') {
                result.push({
                  text: loopFieldInfo.label,
                  value: loopFieldInfo.fieldId
                })
              }
              break
          }
        }
        return result
        // const options = vm.groupingFieldInfo.properties['inputOptionsSingle'].options
        // for (let i = 0; i < vm.groupingSeq.length; i++) {
        //   const loopId = vm.groupingSeq[i]
        //   const option = options.find(item => item.id === loopId)
        //   if (option) {
        //     result.push(option)
        //   }
        // }
        // const remainingOptions = options.filter(item => !vm.groupingSeq.includes(item.id))
        // for (let j = 0; j < remainingOptions.length; j++) {
        //   result.push(remainingOptions[j])
        // }
        // return result
      },

      singleSelectionFields () {
        const vm = this
        var result = []
        for (var i = 0; i < vm.fieldInfos.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i]
          if (loopFieldInfo.type === 'singleSelection') {
            result.push({
              text: loopFieldInfo.properties['fieldName'],
              value: loopFieldInfo.fieldId
            })
          }
        }
        return result
      },

      displayModeOptions () {
        const vm = this
        var result = []
        console.log('displayModeOptions :: view: ', vm.view)
        console.log('displayModeOptions :: vm.imagePositionOptions: ', vm.imagePositionOptions)

        const selectedImagePosition = vm.imagePositionOptions.find(
          option => option.value === vm.view.cardImagePosition
        )
        console.log('displayModeOptions :: selectedImagePosition: ', selectedImagePosition)

        if (selectedImagePosition) {
          result = selectedImagePosition.modes
        }

        const currentSelectedMode = result.filter(
          mode => mode.value === vm.view['cardImageMode'])

        if (currentSelectedMode.length === 0) {
          const currentMode = result[0]
          vm.$emit('onCommand', {
            command: 'updateViewSetting',
            settingName: 'cardImageMode',
            val: currentMode.value
          })
        }
        return result
      },
      remainingFieldIds () {
        const vm = this
        return vm.allTableDisplayFieldIds.filter(fieldId => (
          vm.fixedFieldIds.indexOf(fieldId) === -1 &&
          vm.nonFixedFieldIds.indexOf(fieldId) === -1
        ))
      },
      fieldInfos () {
        return this.$store.getters.currentForm.fieldInfos
      },
      fieldIdLabelMap () {
        const vm = this
        var result = {}
        for (var i = 0; i < vm.fieldInfos.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i]
          result[loopFieldInfo.fieldId] = Object.keys(loopFieldInfo.properties).indexOf('fieldName') >= 0 ?
            loopFieldInfo.properties['fieldName'] :
            '(' + vm.$t('general.unspecified') + ')'
          if (Object.keys(loopFieldInfo.properties).indexOf('fieldNameTag') >= 0) {
            result[loopFieldInfo.fieldId] = vm.$t(loopFieldInfo.properties['fieldNameTag'])
          }
        }
        return result
      }
    },
    watch: {
      groupingFieldId: function (newVal) {
        const vm = this
        if (!vm.isSingleSelection) {
          vm.updateSetting('groupingIncludeNoDataGroup', false)
        }
      },
      view: function (newVal, oldVal) {
        const vm = this
        if (newVal !== oldVal) {
          vm.updateFields()
        }
      }
      // ,
      // groupingFieldId: function (newVal, oldVal) {
      //
      // },
      // groupingIncludeNoDataGroup: function (newVal, oldVal) {
      //
      // },
      // cardSummaryFieldId: function (newVal, oldVal) {
      //
      // },
      //
    },
    mounted () {
      const vm = this
      vm.loadAllFieldInfos()
      vm.updateFields()
    },
    methods: {
      _checkErrorCount (state) {
        const vm = this
        var errorCount = 0
        console.log('panelSettings :: _checkErrorCount : ' + errorCount)
        if (!vm.$refs.groupingFieldId.checkIsValid()) errorCount++
        console.log('panelSettings :: groupingFieldId :: errorCount = ' + errorCount)
        if (!vm.$refs.groupingIncludeNoDataGroup.checkIsValid()) errorCount++
        console.log('panelSettings :: groupingIncludeNoDataGroup :: errorCount = ' + errorCount)
        if (!vm.$refs.cardSummaryFieldId.checkIsValid()) errorCount++
        console.log('panelSettings :: cardSummaryFieldId :: errorCount = ' + errorCount)
        if (!vm.$refs.cardImageFieldId.checkIsValid()) errorCount++
        console.log('panelSettings :: cardImageFieldId :: errorCount = ' + errorCount)
        if (!vm.$refs.cardImagePosition.checkIsValid()) errorCount++
        console.log('panelSettings :: cardImagePosition :: errorCount = ' + errorCount)
        if (!vm.$refs.cardImageMode.checkIsValid()) errorCount++
        console.log('panelSettings :: cardImageMode :: errorCount = ' + errorCount)
        if (!vm.$refs.cardDisplayFieldName.checkIsValid()) errorCount++
        console.log('panelSettings :: cardDisplayFieldName :: errorCount = ' + errorCount)

        return errorCount
      },

      updateSetting (settingName, value) {
        console.log('ViewSettingsPanelSettings :: updateSetting :: value(' + (typeof value) + '): ', value)
        var val = value
        switch (settingName) {
          case 'groupingIncludeNoDataGroup':
          case 'cardDisplayFieldName':
          case 'allowCustomSettings':
            val = value === 'true'
            break
        }
        this.$emit('onCommand', {
          command: 'updateViewSetting',
          settingName: settingName,
          val: val
        })
      },
      addAll () {
        const vm = this
        var i
        var ids = JSON.parse(JSON.stringify(vm.remainingFieldIds))
        switch (vm.currentSection) {
          case 'fixed':
            for (i = 0; i < ids.length; i++) {
              vm.fixedFieldIds.push(ids[i])
            }
            break
          case 'nonFixed':
            for (i = 0; i < ids.length; i++) {
              vm.nonFixedFieldIds.push(ids[i])
            }
            break
        }
        vm.updateTableSettings(vm.fixedFieldIds, vm.nonFixedFieldIds)
      },
      removeItem (fieldId, section) {
        const vm = this
        var idx
        console.log('removeItem :: fieldId = ' + fieldId)
        console.log('removeItem :: section = ' + section)
        switch (section) {
          case 'fixed':
            idx = vm.fixedFieldIds.findIndex(id => id === fieldId)
            if (idx >= 0) {
              vm.fixedFieldIds.splice(idx, 1);
            }
            break
          case 'nonFixed':
            idx = vm.nonFixedFieldIds.findIndex(id => id === fieldId)
            if (idx >= 0) {
              vm.nonFixedFieldIds.splice(idx, 1);
            }
            break
        }
        vm.updateTableSettings(vm.fixedFieldIds, vm.nonFixedFieldIds)
      },
      removeAll () {
        const vm = this
        vm.updateTableSettings([], [])
      },
      removeAllInSection (section) {
        const vm = this
        switch (section) {
          case 'fixed':
            vm.fixedFieldIds = []
            break
          case 'nonFixed':
            vm.nonFixedFieldIds = []
            break
        }
        vm.updateTableSettings(vm.fixedFieldIds, vm.nonFixedFieldIds)
      },
      addField (fieldId) {
        const vm = this
        if (vm.currentSection === 'fixed') {
          vm.fixedFieldIds.push(fieldId)
        } else if (vm.currentSection === 'nonFixed') {
          vm.nonFixedFieldIds.push(fieldId)
        }
        vm.updateTableSettings(vm.fixedFieldIds, vm.nonFixedFieldIds)
        // vm.$emit('onCommand', {
        //   command: 'updateTableSettings',
        //   fixedFieldIds: vm.fixedFieldIds,
        //   scrollableFieldIds: vm.scrollableFieldIds
        // })
      },

      updateFields () {
        const vm = this
        if (vm.view) {
          vm.fixedFieldIds = vm.view.fixedFieldIds
          vm.nonFixedFieldIds = vm.view.nonFixedFieldIds
          console.log('updateFields :: vm.view: ', vm.view)
        }
      },
      loadAllFieldInfos () {
        const vm = this
        vm.allTableDisplayFieldIds = vm.fieldInfos.map(fieldInfo => fieldInfo.fieldId)
      },
      updateTableSettings (fixedFieldIds, nonFixedFieldIds) {
        const vm = this
        vm.$emit('onCommand', {
          command: 'updateTableSettings',
          fixedFieldIds: fixedFieldIds,
          nonFixedFieldIds: nonFixedFieldIds
        })
      },
      onDropped (items, pathTo) {
        const vm = this
        vm.updateTableSettings(vm.fixedFieldIds, vm.nonFixedFieldIds)
        // vm.$emit('onCommand', {
        //   command: 'updateTableSettings',
        //   fixedFieldIds: vm.fixedFieldIds,
        //   scrollableFieldIds: vm.scrollableFieldIds
        // })
      },
      log: function (evt) {
        window.console.log(evt);
      },
      getFieldNameByFieldId (fieldId) {

      }
    }
  }
</script>


<style>

  .view-settings-panel-settings .v-data-table table > tbody > tr > td:first-child {
    text-align: right;
  }

  .view-settings-panel-settings .v-data-table table > tbody > tr > td {
    vertical-align: middle;
    border: none !important;
  }

  /*.view-settings-table-settings .field-list {*/
  /*min-height: 200px;*/
  /*}*/
  .field-list .v-list-item:nth-child(even) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>
