import compBtn from './CompBtn'
import compChart from './CompChart'
import compEmbeddedUrl from './CompEmbeddedUrl'
import compRichText from './CompRichText'

export default {
  compBtn,
  compChart,
  compEmbeddedUrl,
  compRichText
}
