<template>
  <vxe-table-column v-bind="colDef"
                    :edit-render="{name: 'input', enabled:!readonly}" 
                    sortable 
                    class-name="fullCell vxeCellAmount">
    <template v-slot="{row}">
       <div>
        <span v-if="prefix" class="pa-2" style="color: #b5b5b5">{{prefix}}</span>
        <span>{{ formatNumber(row[fieldId])  }}</span>
        <span v-if="suffix" class="pa-2" style="color: #b5b5b5">{{ suffix }}</span>
      </div>
      <!-- {{prefix}}&nbsp;{{ row[fieldId] | round(decimals) }}<span v-if="suffix">&nbsp;{{ suffix }}</span> -->
       <quick-action
        :rowId="row['_id']"
        :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{row}">
      <div class="d-flex flex-row align-center" style="height:100%">
        <!-- <div class="text-no-wrap" v-if="prefix">{{ prefix }}</div> -->
        <vxe-input 
          style="height:100%" 
          type="float"
          :digits="decimals"
          v-model.number="row[fieldId]"
        />
        <!-- <div class="ml-1 text-no-wrap" v-if="suffix">{{ suffix }}</div> -->
      </div>

    </template>

    <template v-if="inSummationFieldIds"
      v-slot:footer="{items, _columnIndex}">
      {{prefix}}&nbsp;{{ 2000 | round(decimals) }}<span v-if="suffix">&nbsp;{{ suffix }}</span>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import quickAction from './comps/QuickAction'
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  data () {
    return {
      showingMenu: false
    }
  },
  components: {
    quickAction,
    summaryCell
  },

}

</script>

<style>
  .vxeCellAmount .vxe-input input{
    text-align: end
  }
</style>