import { get, split, includes } from 'lodash';
const mixin = {
    props:{
        fieldProperties: Object,
        rules:{
          type: Array,
          default: [],
        },
        formFieldInfos: Array,
        content: {
          type: Object,
          default: {},
        },
        fieldType: String,
        comboxProps: {
          type: Object,
          default: {
            onlySuffix: true,
            onlyPrefix: false,
          }
        },
        selectInputProps: {
          type: Object,
          default: {
            addable: true,
            isMultiple: true,
            valueConsistsOf: "object",
          }
        },
        disableInput: Boolean,
        addable: Boolean,
        formInfo: Object,
        types: Array
    },
    computed:{
      criteriaConfigs () {
          return this.$store.getters.criteriaConfigs
      },
      value:{
        get(){
          return this.content.value
        },
        set(val){
          this.$emit('updateValue', val)
        }
      },
      rule:{
        get(){
          return this.content.rule
        },
        set(val){
          this.$emit('updateRule', val)
        }
      },
      type:{
        get(){
          return this.content.type
        },
        set(val){
          this.$emit('updateValue', null)
          this.$emit('updateType', val)
        }
      }
    },
    data(){
      return{
        rendered: false,
        minVal: 0,
        maxVal: 0,
        // rule: null,
        // type: 1,
        dependsOnType: null,
      }
    },
    methods: {
        renderConditionLabel(item) {
          const label = this.criteriaConfigs[item.label];
          if (label && label.t) {
            return this.$t(`filters.${label.t}`);
          }
          return '';
        },
        loadData (urlCommand, params) {
            const vm = this
            const getData = {
              urlCommand: urlCommand,
              options: {
                params: params
              }
            }
            return new Promise((resolve, reject) => {
              vm.$store.dispatch('AUTH_GET', getData).then(
                response => {
                  resolve(response)
                }
              ).catch(
                err => {
                  reject(err)
                }
              )
            })
        } 
    }

}
export default mixin
