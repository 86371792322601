<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions>
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.dataFilter')}}
            </div>
        </v-expansion-panel-header>
        <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
            @change="$emit('onCommandHandler',{
                command: 'update_bl_filter',
                value: $event
            })"
            v-model="bl_filter"
        ></v-switch>
        <v-expansion-panel-content>
            <div>{{$t('chart.showXAxis')}}:</div>
            <div class="d-flex align-center">
                <v-text-field 
                    @change="$emit('onCommandHandler',{
                        command: 'update_dataFilterStart',
                        value: $event
                    })" 
                    v-model="setup.dataFilterStart" 
                    outlined
                    height="20" 
                    dense 
                    hide-details
                />
                <span class="mx-2">一</span> 
                <v-text-field 
                    @change="$emit('onCommandHandler',{
                        command: 'update_dataFilterEnd',
                        value: $event
                    })"
                    v-model="setup.dataFilterEnd" 
                    outlined 
                    height="20" 
                    dense 
                    hide-details
                />
                <span class="ml-2">{{$t('chart.item')}}</span>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        props:{
            bl_filter: Boolean,
            setup: Object
            // dataFilterStart: Number,
            // dataFilterEnd: Number
        },
        data(){
            return {
                
            }
        },
        
    }
</script>