<template>
  <div class="employees-container">
    <div class="header">
      <div class="px-4">
        <employee-header :attrs="headerAttrs" @onCommand="onCommandHandler" />
      </div>
      <v-divider />
      <v-tabs v-model="selectedTabIndex">
        <v-tab v-for="tab in tabs" :key="tab.key">{{ tab.value }}</v-tab>
      </v-tabs>
      <v-divider />
      <div class="d-flex employees-container-row">
        <v-text-field
          style="max-width: 188px"
          class="mx-2 my-1"
          outlined
          hide-details
          height="40"
          dense
          plain
          append-icon="mdi-magnify"
          :placeholder="$t('workAdmin.searchText')"
          v-model="search"
          clearable
          @keypress.enter="searchData"
          @click:append="searchData"
          @click:clear="clearSearch"
        />
      </div>
    </div>
    <div class="content">
      <employee-table
        :attrs="tableAttrs"
        ref="employeeTable"
        @onCommand="onCommandHandler"
      />
    </div>
      <bind-dialog
      v-if="showBindDialog"
      v-model="showBindDialog"
      v-bind="bindDialogAttrs"
      @submit="updateBind"
    />
  </div>
</template>
<script>
import EmployeeHeader from "./components/header";
import EmployeeTable from "./components/table";
import BindDialog from "./components/formInput/bindDialog";
export default {
  name: "WorkAdminEmployees",
  components: {
    EmployeeHeader,
    EmployeeTable,
    BindDialog
  },
  data() {
    return {
      tabs: [{ key: "all", value: this.$t("workAdmin.allEmployees") }],
      commandMap: {
        refreshData: this.refreshData,
        addEmployee: () => this.$router.push({ name: "workAdminNewEmployee" }),
        edit:this.editEmployee,
        invite:this.inviteEmployee,
        editBind: this.editBind
      },
      tableAttrs: {
        height: "100%",
        stripe: true,
        columns: [
          { type: "seq", width: 40 },
          { slots: { default: "icon" }, width: 40 },
          {
            field: "employeeWorkInfo.displayName",
            title: this.$t("workAdmin.employeeName"),
            sortable: true,
            showOverflow: true,
          },
          {
            field: "employeeWorkInfo.employeeNumber",
            title: this.$t("workAdmin.employeeNumber"),
            sortable: true,
          },
          {
            field: "employeeWorkInfo.joinedDate",
            title: this.$t("workAdmin.joinedDate"),
            sortable: true,
          },
          {
            field: "employeeWorkInfo.jobType",
            title: this.$t("workAdmin.jobType"),
            formatter: this.translate,
          },
          {
            field: "employeeWorkInfo.title.name",
            title: this.$t("workAdmin.position"),
            showOverflow: true,
          },
          {
            field: "register",
            title: this.$t("workAdmin.registrationStatus"),
            type:"html",
            formatter: this.formatRegister,
          },
          {
            field: "employeeWorkInfo.status",
            title: this.$t("workAdmin.employeeStatus"),
            formatter: this.translate,
          },
          {
            field: "employeePersonalInfo.gender",
            title: this.$t("workAdmin.gender"),
          },
          {
            field: "email",
            title: this.$t("workAdmin.email"),
          },
          { slots: { default: "action" }, fixed:"right" },
        ],
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 20,
          align: "right",
          pageSizes: [10, 20, 30, 40],
          layouts: [
            "Sizes",
            "PrevJump",
            "PrevPage",
            "Number",
            "NextPage",
            "NextJump",
            "FullJump",
            "Total",
          ],
          perfect: true,
        },
        proxyConfig: {
          seq: true,
          sort: true,
          props: {
            result: "content",
            total: "totalElements",
          },
          ajax: {
            query: ({ page, sorts }) => this.getEmployees({ page, sorts }),
          },
        },
        cellClassName: "vxe-table-row-xs",
        sortConfig: {
          remote: true,
        },
      },

      selectedTabIndex: 0,
      search: "",

      showBindDialog:false,
      bindDialogAttrs:{
        user:{},
        emails:[],
        phones:[],
        employeeId:""
      }
    };
  },
  computed: {
    teamName() {
      return this.$store.getters.activeTeamName;
    },
    activeTeam() {
      return this.$store.getters.activeTeam;
    },
    activeTeamWorkId() {
      return this.activeTeam ? this.activeTeam.id : "";
    },
    headerAttrs() {
      return {
        title: this.teamName,
        buttons: ["refresh"],
      };
    },
  },
  methods: {
    onCommandHandler({ command, ...payload }) {
      return this.commandMap[command]
        ? this.commandMap[command](payload)
        : null;
    },
    editEmployee({row:{id}  }) {
      this.$router.push({
        name: "workAdminEditEmployee",
        params: { id },
      });
    },
    inviteEmployee({row:{id, user:{email}}  }){
      const payload = {
          url: `/work/employees/send_invitation`,
          method: "post",
          params: {
            employeeId: id,
          },
          data: {
            email
          }
        };

        this.$store
          .dispatch("YOOV_REQUEST", payload)
          .then(() => {
            this.$toast.success(this.$t('workAdmin.sendSuccess'));
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
          });
    },
    editBind({row}){
      //cleanup
      this.bindDialogAttrs.emails.splice(0); 
      this.bindDialogAttrs.phones.splice(0);

      this.$set(this.bindDialogAttrs,"user", row.user);
      this.bindDialogAttrs.employeeId = row.id
      const { email, workEmail,mobile } = row.employeeContactInfo;
      if (email) {
        this.bindDialogAttrs.emails.push({
          text: this.$t("workAdmin.personalEmailOption", {
            email: email,
          }),
          value: email,
        });
      }
      if (workEmail) {
        this.bindDialogAttrs.emails.push({
          text: this.$t("workAdmin.workEmailOption", {
            email: workEmail,
          }),
          value: workEmail,
        });
      }
      if (mobile) {
        this.bindDialogAttrs.phones.push({
          text: this.$t("workAdmin.mobileOption", {
            phone: mobile,
          }),
          value: mobile,
        });
      }
      this.showBindDialog = true
    },
    updateBind(contact){
      const payload = {
        url: `/work/employees/${contact.employeeId}/unbind`,
        method: "put",
        data: { ...contact, update: true },
      };
      this.$store
        .dispatch("YOOV_REQUEST", payload)
        .catch((err) => {
          this.$toast.error(err.response.data.message);
        })
        .finally(() => {
          this.showBindDialog = false;
          this.refreshData();
        });
    },
    translate({ cellValue }) {
      return this.$t(`workAdmin.${cellValue}`);
    },
    formatRegister({ cellValue }){
      return `<span ${cellValue === 'UNREGISTERED'? 'style="color: #f54b45"':''}>${this.translate({cellValue})}</span>`;
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async getEmployees({ page, sorts }) {
      let sorting = "";
      if (Array.isArray(sorts) && sorts.length > 0) {
        const { order, field } = sorts[0];
        sorting = `${field.split(".").pop()}${this.capitalizeFirstLetter(
          order
        )}`;
      }
      const requestOptions = {
        page: page.currentPage - 1,
        size: page.pageSize,
        include: [
          "employeeWorkInfo",
          "department",
          "employeePersonalInfo",
          "departments",
          "resignations",
          "user",
          "employeeContactInfo",
        ].join(","),
        status: ["ON_DUTY", "PROBATION", "PRE_RESIGN"].join(","),
        tenantId: this.activeTeamWorkId,
      };
      if (sorting) {
        Object.assign(requestOptions, {
          sorting,
        });
      }
      if (this.search) {
        Object.assign(requestOptions, {
          search: this.search,
        });
      }
      const result = await this.$store.dispatch(
        "GET_YOOVWORK_EMPLOYEES",
        requestOptions
      );
      return result;
    },
    searchData() {
      this.$refs.employeeTable.$refs.vxeTable.commitProxy("query");
    },
    async refreshData() {
      const vxeTable = this.$refs.employeeTable.$refs.vxeTable;
      await vxeTable.clearAll();
      Object.keys(vxeTable.sortData).forEach((key) => {
        delete vxeTable.sortData[key];
      });
      this.search = "";
      await vxeTable.commitProxy("reload");
    },
    clearSearch() {
      this.search = "";
      this.searchData();
    },
  },
};
</script>
<style lang="scss" scoped>
.employees-container {
  display: flex;
  flex-flow: column;
  height: 100%;

  .header {
    flex: 0 1 auto;
  }
  .content {
    flex: 1 1 auto;
  }
  .employees-container-row {
    flex-direction: row-reverse;
  }
}
</style>
