<template>
  <div>
    <PendingSetup
      v-if="!sourceType"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <div class="workflow-shortview-worksheet">
        <span>
          {{ type }}
        </span>
      </div>
      <component
        :is="targetComponent"
        :node="node"
      />
    </div>
    
  </div>
</template>

<script>
import { get } from 'lodash';
import PendingSetup from './../pending';
import Mixin from './../mixin';
import MathView from './math';
import StatisticView from './statistic';
import DateDiffView from './dateDiff';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  computed: {
    targetComponent() {
      return {
        math: 'MathView',
        dateDiff: 'DateDiffView',
        dateCalculate: 'MathView',
        statistic: 'StatisticView',
      }[this.sourceType];
    },
    sourceType() {
      const { properties } = this.node;
      return get(properties, 'sourceType', '');
    },
    type() {
      const { sourceType } = this;
      return this.$t(`workflow.modules.operation.label.type.${sourceType}`);
    },
    // dataSize() {
    //   return keys(get(this.node, 'properties.data', {})).length;
    // }
  },
  components: {
    PendingSetup,
    MathView,
    DateDiffView,
    StatisticView,
  },
};

</script>

<style scoped>
  
</style>
