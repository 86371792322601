<template>
    <v-dialog persistent v-model="showDialog" width="340">
        <v-card>
            <dialogHeader :label="title" @close="cancel"/>

            <v-card-text>
                <v-text-field :label="placeholder" v-model="label"/>
            </v-card-text>
        <dialog-actions :buttons="['cancel', 'save']"
                        :actionDisabled="requireLabel && !label"
                        @onCommand="onCommandHandler"></dialog-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import dialogActions from '@/components/dialogs/comps/DialogActions';

export default {
    props: {
        requireLabel: {
            type:Boolean,
            default:true
        },
        title: {
            type:String,
            default: function(){
                return this.$t('publicForm.addNewField')
            }
        },
        placeholder: {
            type:String,
            default: function(){
                return this.$t('publicForm.fieldName')
            }    
        }
    },
    data(){
        return {
            label:'',
            type: '',
            showDialog:false
        }
    },
    components:{
        dialogHeader,
        dialogActions
    },
    methods: {
        onCommandHandler(payload){
            switch(payload.command){
                case "save":
                    this.confirm()
                break;
                case "cancel":
                    this.cancel()
                break;
            }
        },
        confirm(){
            this.$emit('confirm', {
                label:this.label,
                type:this.type
            });
            this.showDialog = false
        },
        cancel(){
            this.showDialog = false
        },
        open(defaultValue = "", type){
            this.type = type;
            if (defaultValue) this.label = defaultValue;
            this.showDialog = true
        }
    }
}
</script>