<template>
  <v-tooltip
    v-if="checkingLog.length > 0"
    :left="left"
    :bottom="bottom"
    transition="slide-x-reverse-transition"
    :content-class="`w-arrow-control-tool ${direction}`"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        class="workflow-node-box-block-alert-error"
        :color="dark ? 'red' : 'white'"
        large
      >mdi-alert-circle
      </v-icon>
    </template>
    <LogItem :list="checkingLog" :darkMode="false" :messageLabel="messageLabel" />
  </v-tooltip>
</template>

<script>
import Mixin from './../../setting/mixin';
import NodeMixin from './../../../../mixin';
import LogItem from './logItem';
import WMixin from "@/pages/admin/workflow/mixin";
// mixins
export default {
  mixins: [Mixin, NodeMixin, WMixin],
  props: {
    // node: Object,
    direction: {
      type: String,
      default: 'fromLeft',
    },
    dark: Boolean,
    bottom: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    checkingLog: {
      type: Array,
      default: [],
    },
    messageLabel: String,
  },
  components: {
    LogItem,
  },
  // computed: {
  //   checkingLog() {
  //     return this.node.checkingLog || [];
  //   }
  // },
};

</script>

<style scoped>

</style>
