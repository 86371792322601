import { render, staticRenderFns } from "./valueHandler.vue?vue&type=template&id=c56c515a&scoped=true&"
import script from "./valueHandler.vue?vue&type=script&lang=js&"
export * from "./valueHandler.vue?vue&type=script&lang=js&"
import style0 from "./valueHandler.vue?vue&type=style&index=0&id=c56c515a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c56c515a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VChip,VIcon,VRating,VSelect,VTextField})
