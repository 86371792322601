<template>
  <div style="margin-bottom: 12px;">
    <label>{{ label }}</label>
    <input-radio-toggle
        :dense="true"
        :disabled="propertyDisabled"
        :options="options"
        v-model="userPropertyValue">
    </input-radio-toggle>
    <div class="note--text" v-if="propertyConfig.noteTag">
      * {{ $t('widgets.properties.' + propertyConfig.noteTag)}}
    </div>
  </div>
</template>

<script>
import inputRadioToggle from '@/components/formComps/InputRadioToggle'
import mixin from './mixin'

export default {
  mixins: [mixin],
  components: {inputRadioToggle},
  computed: {
    optionValues () {
      return this.options.map(op => op.value)
    },
    userPropertyValue: {
      get() {
        const vm = this
        // console.log('WitSelectionSingle :: c(userPropertyValue) :: optionValues: ', vm.optionValues)
        // console.log('WitSelectionSingle :: c(userPropertyValue) :: vm.propertyValue = ' + vm.propertyValue)
        // console.log('WitSelectionSingle :: c(userPropertyValue) :: vm.propertyConfig: ', vm.propertyConfig)
        // console.log('WitSelectionSingle :: c(userPropertyValue) :: vm.propertyConfig[default]: ' + vm.propertyConfig['default'])        
        
        var result = vm.propertyValue
        // vm.propertyValue may be null because of timing issue

        // adjustment is required for invalid entry after change of some dependent values
        // no adjustment required for blank value
        if (result) {
          if (vm.optionValues.indexOf(result) === -1) {
            // get default property value
            // console.log('get(userPropertyValue): propertyDefault: ', vm.propertyDefault)
            result = vm.propertyConfig['default']
            // console.log('get(userPropertyValue): vm.result: ' + JSON.stringify(vm.propretyConfig))
            // console.log('get(userPropertyValue): vm.propertyConfig[default] = ' + vm.propertyConfig['default'])
            // console.log('get(userPropertyValue): vm.result = ' + result)
            // if also not in valid option list, use first option
            if (vm.optionValues.indexOf(result) === -1) {
              result = vm.optionValues[0]
            }
            vm.propertyValue = result
          }
        }
        return result
      },
      set (val) {
        this.propertyValue = val
      }
    }
  }
}
</script>

