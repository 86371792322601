<template>
  <div>
    <slot v-bind="{ validatorInfo }" />
  </div>
</template>

<script>
export default {
  inject: ["errorInfo", "canSubmit", "info", "saveSetting", "hasError", "setError"]
};
</script>

<style>
</style>
