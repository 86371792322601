<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions>
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.legend')}}
            </div>
        </v-expansion-panel-header>
        <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
            v-model="bl_legend"
            @change="$emit('onCommandHandler', {
                command: 'update_bl_legend', 
                value: $event
            })"
        ></v-switch>
        <!-- v-if="displayCol!='all'" @change="$emit('update_totalRule', $event)" v-model="totalRule"  -->
        <!-- <v-btn-toggle mandatory dense style="overflow: scroll"> -->
        <!-- </v-btn-toggle> -->
        <v-expansion-panel-content>
            <div class="px-3">
                <v-btn @click="selected=position.label" class="" :class="selected==position.label?'primary white--text':'grey--text'" elevation="0" outlined tile small v-for="position in positions" :key="position.id">
                    {{$t('chart.styleConfig.' + position.label)}}
                </v-btn>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        props:{
            bl_legend: Boolean,
            setup: Object
            // legendPos: String
        },
        data(){
            return{
                positions:[
                    {
                        id: 1,
                        name: 'topLeft',
                        label: 'topLeft'
                    },
                    {
                        id: 2,
                        name: 'top',
                        label: 'top'
                    },
                    {
                        id: 3,
                        name: 'topRight',
                        label: 'topRight'
                    },
                    {
                        id: 4,
                        name: 'left',
                        label: 'left'
                    },
                    {
                        id: 5,
                        name: 'right',
                        label: 'right'
                    },
                    {
                        id: 6,
                        name: 'bottomLeft',
                        label: 'bottomLeft'
                    },
                    {
                        id: 7,
                        name: 'bottom',
                        label: 'bottom'
                    },
                    {
                        id: 8,
                        name: 'bottomRight',
                        label: 'bottomRight'
                    },
                ],
                selected: 'top'
            }
        },
        mounted(){
            this.selected = this.setup.legendPos
        },
        watch:{
            selected(val){
                this.$emit('onCommandHandler',{
                    command: 'update_legendPos',
                    value: val
                })
                // this.$emit('update_legendPos', val)
            }
        }
    }
</script>
