<template>
  <div class="level-select-cascade-relation-fields">
    <!-- <label>{{ $t('levelDiagram.selectChildRelation') }}</label> -->
<!--     <parent-child-settings-title
                    :levelNo="1"
                    class="px-3 mb-2"
                    :item="dummyItem"></parent-child-settings-title>
-->
    <parent-child-settings-title
      v-for="(item, index) in relatedFieldItems" 
      :key="index"
      class="px-3 mb-2 mt-2"
      :levelNo="index+1"
      :outlined="true"
      :deletable="index>0"
      @onDelete="onDeleteHandler"
      :item="item">
    </parent-child-settings-title>

    <div class="px-3">
      <parent-child-selection-menu
        :relationFieldItems="childRelationFieldItems"
        @onSelect="selectItem">    
      </parent-child-selection-menu>
            <!-- FormInfos.length = {{ Object.keys(formInfos).length }}<br/>
      lastFormInfo.id = {{ lastFormInfo ? lastFormInfo._id : '(undefined)' }} -->
    </div>
  </div>
</template>

<script>
import parentChildFieldFormMixin from '@/mixins/parentChildFieldFormMixin'

import parentChildSelectionMenu from '@/components/dialogs/viewSettingsTabs/comps/ParentChildSelectionMenu'
import parentChildSettingsTitle from '@/components/dialogs/viewSettingsTabs/comps/ParentChildSettingsTitle'

export default {  
  name: 'levelSelectCascadeRelationFields',
  mixins: [parentChildFieldFormMixin],
  components: {
    parentChildSelectionMenu,
    parentChildSettingsTitle
  },
  model: {
    prop: 'parentChildFieldFormList',
    event: 'input'
  },
  props: {
    parentChildFieldFormList: {
      type: Array,
      default () {
        return []
      }
    },
    levelType: {
      type: String,
      default: 'multipleTables'
    },
    view: Object // used for parentChildFieldFormMixin which to be used in view settings
  }
}
</script>

