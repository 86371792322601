<template>
  <vxe-table-column v-bind="colDef"
                    :edit-render="{name: 'input', autoselect: true, enabled:!readonly}" sortable class-name="fullCell vxeCellEmail">
    <template v-slot:edit="{row}">
      <div style="height:100%">
        <vxe-input type="text" style="height:100%" v-model="row[fieldId]"/>
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>

  </vxe-table-column>
</template>
<script>
import mixin from './mixin'
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  components: {
    summaryCell
  },  
  methods: {
    updateVal (val) {
      const vm = this
      vm.$emit('onCommand', {
        command: 'updateFieldValue',
        fieldId: vm.fieldId,
        fieldValue: val,
      })
      // console.log('val = ' + JSON.stringify(val))
    }
  }
}
</script>
<style>
  .vxeCellEmail .vxe-input input{
    padding: 8px !important;
  }
</style>