import { app } from "@/main";
import { imports as types } from "../../action-types";
import Vue from "vue";

export default {
  [types.IMPORT_START]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const postPayload = {
        urlCommand: "/import/startImport",
        data: payload,
      };

      dispatch("AUTH_POST", postPayload)
        .then((response) => {
          if (response.status === "queued")
            Vue.$toast.success(app.$i18n.t("importDialog.importStart"), {
              duration: 10000,
            });
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
