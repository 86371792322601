<template>
    <v-list-item @click="onClick">
    <v-list-item-title >{{value.text}}</v-list-item-title>
    </v-list-item>
</template>
<script>
export default {
    props: {
        value: Object
    },
    methods: {
        onClick(){
            this.$emit('click', this.value)
        }
    }
}
</script>