<template>
  <v-dialog v-model="showingDialog" v-if="showingDialog" width="400">
    <v-card>
      <dialog-header
        :label="dialogTitle"
        @close="showingDialog = false"
      />
      <v-form @submit="onSubmit" onSubmit="return false;">
        <v-card-text class="pt-3 pb-5 d-flex flex-column">
          <label>{{ $t('general.title')}}</label>
          <v-text-field autofocus
            @focus="onFocus($event)"
            dense
            height="30"
            v-model="data.title"
            outlined
            hide-details
          />
        </v-card-text>
        <dialog-actions 
          :buttons="['cancel', 'ok']"
          @onCommand="onCommandHandler">
        </dialog-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
  export default {
    components:{
      dialogActions,
      dialogHeader
    },
    data () {
      return {
        showingDialog: false,
        data: { // maybe module or view
          _id: '',
          title: ''
        }
      }
    },
    props: {
      dialogName: {
        type: String,
        default: 'Module'
      },
      type: {
        type: String,
        default: ''
      },
      command: {
        type: String,
        default: ''
      }
    },
    watch: {
      dialogName: function (newVal) {
        const vm = this
        vm.data.title = newVal
      }
    },
    mounted () {
      const vm = this
      vm.data.title = vm.dialogName
    },
    computed: {
      dialogTitle () {
        const vm = this
        if (vm.data._id === '') {
          var title = []
          if (vm.data.title !== '') title.push(vm.data.title)
          title.push('(' + vm.$t('general.new') + ')')
          return title.join(' ')
        } else {
          return vm.data.title + ' (' + vm.$t('general.edit') + ')'
        }
      }
    },
    methods: {
      onCommandHandler(payload){
        switch(payload.command){
          case 'ok':
            this.onSubmit()
            break
          default: this.closeDialog()
        }
      },
      onFocus (evt) {
        evt.target.select()
      },
      open (tabInfo) {
        const vm = this
        if (typeof tabInfo === 'undefined') {
          vm.data = {
            _id: '',
            title: ''
          }
        } else {
          vm.data = {
            _id: tabInfo._id,
            title: tabInfo.title
          }
        }
        vm.showingDialog = true
      },
      onSubmit () {
        const vm = this
        vm.$emit('onCommand', {
          command: vm.command,
          id: vm.data._id,
          title: vm.data.title.trim()
        })
        vm.showingDialog = false
        return false
      },
      closeDialog () {
        const vm = this
        vm.showingDialog = false
      }
    }
  }
</script>
