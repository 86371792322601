<template>
  <v-layout fill-height column>
    <slot />
  </v-layout>
</template>

<script>
import { keys, get, indexOf } from 'lodash';

export default {
  data() {
    const { eventType } = this.$route.params;
    const tabs = keys(this.$t('workflow.events'));

    return {
      tabs,
      info: {
        index: eventType && this.indexFromEventType(tabs, eventType), // default tab control
        title: this.$t('workflow.fullTitle'),
      },

    };
  },
  methods: {
    setTitle(t) {
      this.info.title = t;
    },
    setEventType(type) {
      this.info.index = type;
    },
    getEventType() {
      const type = this.eventTypeFromIndex(this.info.index);
      return type;
    },
    // must call from the context children
    eventTypeFromIndex(index) {
      return get(this.tabs, `[${index}]`, 0);
    },
    // first call in data, then pass tabs array as parameter
    indexFromEventType(tabs, type) {
      const index = indexOf(tabs, type);
      return index !== -1 ? index : 0;
    },
  },
  provide() {
    return {
      navigatorInfo: this.info,
      setTitle: this.setTitle,
      setEventType: this.setEventType,
      eventTypeFromIndex: this.eventTypeFromIndex,
      getEventType: this.getEventType,
    };
  },
};
</script>

<style scoped>
  /* .navigator-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  } */
</style>
