<template>
  <v-btn
    icon
    @click="openEdit"
    small
  >
    <v-icon
      small
      :color="isNode ? 'white' : 'rgb(158, 158, 158)'"
    >
      mdi-cog
    </v-icon>
  </v-btn>
</template>

<script>
import {
  EDITING_NODE,
} from '@/store/modules/workflow/action_types';
export default {
  props: {
    node: Object,
    isNode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openEdit() {
      this.$store.dispatch(EDITING_NODE, this.node);
    }
  },
};

</script>

<style scoped>
</style>
