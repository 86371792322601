<template>
  <div>
    <label>{{ label }}</label>
    <div class="d-flex flex-column align-content-between pa-2"
      style="border: 1px solid lightgray;">
      <!--<div class="d-flex flex-row align-center copy-field-row">-->
        <!--propertyValue: {{ propertyValue }}-->
        <!--<div v-for="(targetFieldIds, sourceFieldId, index) in propertyValue"-->
             <!--:key="sourceFieldId">-->
          <!--#{{ index }}: {{ sourceFieldId }}: {{ targetFieldIds }}-->
        <!--</div>-->
      <!--targetFieldIds, sourceFieldId, index) -->
      <copy-field-item
          v-for="sourceFieldId in selectedSourceFieldIds"
          :key="sourceFieldId"
          :sourceFieldInfo="getRelatedTableFieldInfoByFieldId(sourceFieldId)"
          :targetFieldIds="propertyValue[sourceFieldId]"
          :allSelectedCurrentFormFieldIds="allSelectedCurrentFormFieldIds"
          @input="val=>updateInput(sourceFieldId,val)"
          @click:delete="removeField"
          :fieldInfos="formFieldInfos"></copy-field-item>
      <!--</div>-->
      <field-selection-button
          ref="fieldSelectionButton"
          :disabled="!dataSourceInfo"
          :fieldInfos="copyableFieldInfos"
          @click="addField"></field-selection-button>
    </div>
    <!--<div class="px-1 my-1 d-flex flex-row align-center justify-space-between">-->
      <!--<field-selection-button-->
          <!--ref="fieldSelectionButton"-->
          <!--:fieldInfos="copyableFieldInfos"-->
          <!--@click="addField"></field-selection-button>-->
      <!--<field-selection-button-->
      <!--ref="fieldSelectionButton"-->
      <!--:fieldInfos="relatedTableFieldInfos"-->
      <!--@onCommand="onCommandHandler"></field-selection-button>-->

      <!--<div v-if="filters.length > 0">-->
      <!--<input-radio-toggle :options="andOrOptions"-->
      <!--v-model="filterAndOr"/>-->
      <!--</div>-->
    <!--</div>-->
    <!--<v-text-field-->
    <!--label=""-->
    <!--v-model="displayValue"-->
    <!--append-outer-icon="mdi-window-restore"-->
    <!--dense-->
    <!--readonly-->
    <!--clearable-->
    <!--:placeholder="placeholder"-->
    <!--@click:append-outer="openDialog"-->
    <!--hide-details="auto"-->
    <!--outlined></v-text-field>-->
    <!--<copy-related-record-fields-dialog-->
    <!--ref="copyRelatedRecordFieldsDialog"-->
    <!--@confirm="confirmHandler"></copy-related-record-fields-dialog>-->
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </div>
</template>

<script>
  import mixin from './mixin'
  import copyRelatedRecordFieldsDialog from '@/components/dialogs/CopyRelatedRecordFieldsDialog'
  import TABS from '@/components/dialogs/tabs/constants.js'
  import fieldSelectionButton from '@/components/buttons/FieldSelectionButton'
  import copyFieldItem from './comps/CopyFieldItem'
  import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'

  export default {
    name: 'witRelatedRecordCopyFields',
    mixins: [mixin],
    components: {
      copyRelatedRecordFieldsDialog,
      fieldSelectionButton,
      copyFieldItem,
      ConfirmDeleteDialog
    },
    data () {
      return {
        items: ['foo', 'bar', 'fizz', 'buzz'],
        selectedItems: ['foo', 'bar', 'fizz', 'buzz']
      }
    },
    computed: {
      allSelectedCurrentFormFieldIds () {
        const vm = this
        var result = []
        for (let key in vm.propertyValue) {
          result = result.concat(vm.propertyValue[key])
        }
        return result
      },
      selectedSourceFieldIds () {
        return Object.keys(this.propertyValue)
      },
      fieldCount () {
        const vm = this
        var result = 0
        if (vm.propertyValue) {
          if (vm.propertyValue.relatedToLocalFields) {
            result = Object.keys(vm.propertyValue.relatedToLocalFields).length
          }
        }
        return result
      },
      placeholder () {
        return this.$t('messages.pleaseSelectFields')
      },
      copyableWidgetTypes () {
        return this.$store.getters.copyableWidgetTypes ?
          this.$store.getters.copyableWidgetTypes :
          []
      },

      copyableFieldInfos () {
        const vm = this
        var isMultiple = vm.fieldInfo.properties.relatedRecordQuantity == 'multiple'
        var result = []
        for (let i = 0; i < vm.relatedTableFieldInfos.length; i++) {
          const loopFieldInfo = vm.relatedTableFieldInfos[i]
          if (this.copyableWidgetTypes.includes(loopFieldInfo.type) &&
            !this.selectedSourceFieldIds.includes(loopFieldInfo.fieldId)) {
            if(!isMultiple)
              result.push(loopFieldInfo)
            else{
              if(loopFieldInfo.type=='relatedRecord'){
                result.push(loopFieldInfo)
              }
            }
          }
        }
        return result
      },

      relatedTableFieldIds () {
        return this.dataSourceFieldInfos.map(info => info.fieldId)
      },

      relatedTableFieldInfos () {
        return this.dataSourceFieldInfos ? this.dataSourceFieldInfos : []
      },
      
      displayValue: {
        get () {
          const vm = this
          var msg = ''
          if (vm.fieldCount > 0) {
            msg = vm.$t('general.copy') + ' ' +
              vm.fieldCount + ' ' + vm.$t('general.field')
          }
          return msg
        },
        set (val) {
          const vm = this
          vm.propertyValue.relatedToLocalFields = {}
        }
      }
    },
    watch:{
        'fieldInfo.properties.relatedRecordQuantity'(val){
          this.value = {}
        }
    },
    methods: {
      updateInput (relatedFieldId, localFieldIds) {
        const vm = this
        console.log('WitRelatedrecordcopyfield : updateInput : relatedFieldId = ' + relatedFieldId)
        console.log('WitRelatedrecordcopyfield : updateInput : localFieldIds: ', localFieldIds)
        console.log('WitRelatedrecordcopyfield : updateInput 1: propertyValue: ', vm.propertyValue)
        localFieldIds.forEach(id => {
          const index = vm.propertyValue[relatedFieldId].indexOf(id)
          if (index === -1) {
            vm.propertyValue[relatedFieldId].push(id)
          }
        })
        const deleted = vm.propertyValue[relatedFieldId].filter(id => !localFieldIds.includes(id))
        deleted.forEach(id => {
          const index = vm.propertyValue[relatedFieldId].indexOf(id)
          if (index >= 0) {
            vm.propertyValue[relatedFieldId].splice(index, 1)
          }
        })

//        vm.propertyValue[relatedFieldId] = localFieldIds
        //vm.$set(vm.propertyValue, relatedFieldId, localFieldIds)
        console.log('WitRelatedrecordcopyfield : updateInput 2: propertyValue: ', vm.propertyValue)
      },
      _parseValue (value) {
        const vm = this
        var result = {}
        if (!value) {
          result = {}
        } else {
          for (let relatedFieldId in value) {
            console.log('_parseValue: vm.relatedTableFieldIds: ', vm.relatedTableFieldIds)
            if (vm.relatedTableFieldIds.includes(relatedFieldId)) {
              result[relatedFieldId] = value[relatedFieldId]
            }
          }
        }

        console.log('WitRelatedRecordCopyFields._parseValue: result: ', result)
        return result
      },
      addField (fieldInfo) {
        const vm = this
        var updatedPropertyValue = JSON.parse(JSON.stringify(vm.propertyValue))
        updatedPropertyValue[fieldInfo.fieldId] = []
        vm.propertyValue = updatedPropertyValue
        console.log('WitRelatedRecordCopyFields.addField fieldInfo: ', fieldInfo)
        console.log('WitRelatedRecordCopyFields.addField vm.propertyValue: ', vm.propertyValue)
      },
      removeField (fieldInfo) {
        const vm = this
        vm.$refs.confirmDeleteDialog.confirm(()=> {
           vm.doRemoveField(fieldInfo)
        })
      },

      doRemoveField (fieldInfo) {
        const vm = this
        var updatedPropertyValue = JSON.parse(JSON.stringify(vm.propertyValue))
        delete updatedPropertyValue[fieldInfo.fieldId]
        vm.propertyValue = updatedPropertyValue
        console.log('vm.propertyValue: ', vm.propertyValue)
      },
      getRelatedTableFieldInfoByFieldId (fieldId) {
        const vm = this
        const fieldInfo = vm.relatedTableFieldInfos.find(info => info.fieldId === fieldId)
        return fieldInfo
      },
      openDialog () {
        const vm = this
        const tabs = [
          TABS.COPY_RELATED_FIELDS
        ]
        if (vm.dataSource) {
          this.$refs.copyRelatedRecordFieldsDialog.open(
            vm.propertyValue,
            vm.dataSource,
            vm.formFieldInfos,
            tabs
          )
        } else {
          vm.$alert(
            vm.$t('messages.pleaseSpecifyDataSourceFirst'),
            vm.$t('general.warning'),
            'warning',
            {
              confirmButtonText: vm.$t('buttons.ok'),
              cancelButtonText: vm.$t('buttons.cancel')
            }
          )
        }
      },
      confirmHandler (newPropertyValue) {
        this.propertyValue = newPropertyValue
      }
    }
  }
</script>

<style>
</style>
