<template>
  <v-app v-if="isMobile">
    <router-view />
  </v-app>
  <v-app v-else>
    <div class="env_watermark_label" v-if="showWaterMark">
      {{watermark}} 
    </div>
    <app-drawer ref="appDrawer"
    v-if="!hideDrawer"
                :showDrawer="showDrawer"
                @onCommand="onCommandHandler"></app-drawer>
    <v-layout>
      <div class="flex-grow-1 d-flex flex-column" style="z-index:6; min-width: 0px">
        <!--<app-header :showDrawer="showDrawer" @onCommand="onCommandHandler"></app-header>-->
        <router-view class="flex-grow-1"></router-view>
        
      </div>
      <!-- <div v-if="atSidePage" class="globalize-right-panel">
        <div>
          <NotificationBell />
        </div>
      </div> -->
    </v-layout>
    <div class="workflow-service-connect" v-if="socketConnecting">
      <Lottie
        class="workflow-service-connect-icon"
        :options="defaultOptions"
        :height="40"
        :width="40"
      />
      <div class="workflow-service-connect-label">
        {{ $t('notification.socket.events.connecting') }}
      </div>
    </div>
    <CustomizedActionNotificationAlert />
    <NotificationPopupAlert />
    <BackgroundJobNotification />
    <div
      v-if="loading"
      class="loading-mask d-flex white--text flex-column justify-center align-center"
    >
      <font-awesome-icon
        icon="spinner"
        class="fa-5x fa-spin"
      ></font-awesome-icon>
    </div>
  </v-app>
</template>

<script>
  import { includes } from 'lodash';
  import Lottie from 'vue-lottie';
  import appDrawer from '@/components/appDrawer/AppDrawer'
  import EventBus from '@/event-bus.js'
  import BackgroundJobNotification from "@/components/notifications/BackgroundJobNotification";
  import {
    CONNECT_TO_SERVER,
    OUTBOUND_EVENT_LEAVEROOMS,
    OUTBOUND_EVENT_JOINROOMS,
  } from '@/store/modules/notification/action_types';
  import EstablishAnimation from '@/pages/admin/workflow/assets/connect_service.json';
  import CustomizedActionNotificationAlert from '@/pages/admin/workflow/components/notification/customActionButton/';
  import NotificationPopupAlert from '@/pages/admin/workflow/components/notification/alert';

  export default {
    name: 'App',
    components: {
      appDrawer,
      BackgroundJobNotification,
      Lottie,  
      CustomizedActionNotificationAlert,
      NotificationPopupAlert,
    },
    computed: {
      showWaterMark(){
        const { VUE_APP_ENV_VARIABLE } = process.env;
        const path = `workflow.env.${VUE_APP_ENV_VARIABLE}`;
        return includes([
          'uat',
          'development',
        ], VUE_APP_ENV_VARIABLE)
      },
      watermark() {
        const { VUE_APP_ENV_VARIABLE, VUE_APP_VERSION } = process.env;
        const path = `workflow.env.${VUE_APP_ENV_VARIABLE}`;
        return includes([
          'uat',
          'development',
        ], VUE_APP_ENV_VARIABLE) ? this.$t(path) + ' : ' + VUE_APP_VERSION : null;
      },
      currentApp() {
        return this.$store.getters.currentApp;
      },
      ajaxError () {
        return this.$store.getters.ajaxError
      },
      socketConnecting () {
        return this.$store.getters.isNotificationConnecting;
      },
      accessToken () {
        return this.$store.getters.accessToken;
      },
      user () {
        return this.$store.getters.user
      },
      hideDrawer() {
        return ["PublicForm", "PreviewPublicForm", "PublicQuery", "PublicEdit"].includes(this.$route.name)
      },
      atSidePage() {
        return this.$route.path.indexOf("/workflows") !== -1;
      }
    },
    watch: {
      accessToken: function() {
        this.establishSocketService();
      },
      ajaxError: function (newVal) {
        if (newVal) {
          alert(newVal.message)
        }
      },
      currentApp: {
        deep: true,
        handler(newApp, oldApp) {
          if (!newApp) {
            // left the socket room
            console.log('> leave all apps', oldApp);
            if (oldApp) {
              this.$store.dispatch(OUTBOUND_EVENT_LEAVEROOMS, oldApp._id);
            }
          } else if (!oldApp) {
            // being changed app related room
            console.log('> go to first app');
            this.$store.dispatch(OUTBOUND_EVENT_JOINROOMS, newApp._id);
          } else if (newApp._id !== oldApp._id) {
            // being changed app related room
            console.log('> change app');
            this.$store.dispatch(OUTBOUND_EVENT_JOINROOMS, newApp._id);
          }
        },
      },
    },
    data: () => {
      return {
        loading: false,
        defaultOptions: {
          animationData: EstablishAnimation,
        },
        showPassword: false,
        items: [],
        showDrawer: false,
        isMobile: false,
      }
      //
    },
    async created () {
      const vm = this
      vm.checkAjaxErrors()
      vm.establishSocketService();
      EventBus.$on('onLoading', () => {
        // alert('onLoading')
        console.log('EventBus.$on(onLoading)');
        vm.loading = true
      })
      EventBus.$on('offLoading', () => {
        vm.loading= false;
      })
      EventBus.$on('showDrawer', () => {
        vm.$refs.appDrawer.show()
      })

      if (vm.user) {
        if (localStorage.getItem('VXE_TABLE_CUSTOM_COLUMN_WIDTH') == null) {
          await this.$store.dispatch('FETCH_LOCAL_STORAGE');
        }
      }
      window.addEventListener('beforeunload', this.beforeUnload);

      // check using mobile or not
      try {
        this.isMobile = /mobi/i.test( window.navigator.userAgent ) && 
                        ! /ipad/i.test( window.navigator.userAgent );
      } catch (err) {
        console.warn('CANNOT DETECT MOBILE DEVICE');
      }
    },
    async beforeDestroy () {
      EventBus.$off('onLoading')
      EventBus.$off('offLoading');
      window.removeEventListener('beforeunload', this.beforeUnload);
    },
    methods: {
      establishSocketService() {
        if (this.accessToken) {
          this.$store.dispatch(CONNECT_TO_SERVER, this.accessToken);
        }
      },
      checkAjaxErrors () {
        const vm = this
        vm.axios.interceptors.response.use(
          function (responseData) {
            // console.log('arg0: ', responseData)
            return responseData
          },
          function (error) {
            if(error.response){
              const { response: { data: { message }, status } } = error; 
              if ( status === 403 && (message.toLowerCase().startsWith('no token') || message === "token expired")) {
                vm.$store.dispatch("LOGOUT").then(()=> {

                  vm.$router.push({ name: 'root', query: { tokenExpired: true } } );
                })
                // try {
                //   localStorage.removeItem('accessToken');
                //   vm.$router.push({ name: 'root', query: { tokenExpired: true } } );
                //   return;
                // } catch (err) {
                //   console.warn('CANNOT REMOVE FROM STORAGE');
                // }
              }
              switch(error.response.status){
                case '403':
                  if (vm.$route.name !== 'errors') {
                    vm.$router.replace({
                      name: 'errors',
                      params: {
                        errorCode: error.response.status
                      }
                    })
                  }
                break
                case '500':
                  this.$router.push('/my_apps')
                break
              }
            }
            return Promise.reject(error)
          }
        )
      },
      onCommandHandler (payload) {
        // console.log('onCommandHandler :: payload: ', payload)
        const vm = this
        const command = payload.command
        switch (command) {
          case 'setShowDrawer':
            vm.$refs.appDrawer.show()
            // vm.showDrawer = payload.value
            break
        }
      },
      async beforeUnload () {
        const vm = this
        if (vm.user) {
          await vm.$store.dispatch('SAVE_LOCAL_STORAGE')
        }
      }
    }
  };
</script>


<style>


  .loading-mask {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  .env_watermark_label {
    position: fixed;
    bottom: 10px;
    background: black;
    padding: 2px 15px;
    border-radius: 10px;
    opacity: 0.35;
    left: 2px;
    z-index: 999999;
    color: white;
    pointer-events: none;
  }
</style>
