<template>
  <v-dialog v-model="showDialog" persistent scrollable width="640">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>{{ $t("general.settings") }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <template v-slot:extension>
            <v-tabs v-model="tab" fixed-tabs>
              <v-tab href="#url">
                {{ $t("publicForm.url") }}
              </v-tab>
              <v-tab href="#source">
                {{ $t("publicForm.source") }}
              </v-tab>
              <v-tab href="#html">
                {{ $t("publicForm.HTML") }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
      </v-card-title>
      <v-card-text style="height:80vh">
        <div v-if="tab === 'url'">
          <urlSetting :publicForm="info" @commandHandler="onCommandHandler" />
        </div>

        <div v-else-if="tab === 'source'">
          <sourceSetting :publicForm="info" @commandHandler="onCommandHandler" />
        </div>

        <div v-if="tab === 'html'">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="label grey--text">
                  {{ $t("publicForm.HTML") }}
                </v-list-item-title>
                <div class="d-flex flex-row align-center">
                  <div class="htmlBox d-flex flex-grow-0">
                    {{ htmlCode }}
                  </div>
                  <div class="px-2 d-flex flex-grow-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attr }">
                        <v-btn icon small @click="copy" v-on="on" v-bind="attr">
                          <v-icon small>
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      {{$t('buttons.copy')}}
                    </v-tooltip>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import urlBox from "./urlBox";
import textEditor from "./textEditor";
import urlSetting from "./urlSetting";
import sourceSetting from "./sourceSetting";

export default {
  props: {
    value: Boolean,
    info: Object,
    url: String,
  },
  components: {
    urlBox,
    urlSetting,
    textEditor,
    sourceSetting,
  },
  data() {
    return {
      tab: "",
      limitOptions: [
        {
          label: "no limit",
          value: 0,
        },
        {
          label: "once",
          value: 1,
        },
        {
          label: "once per day",
          value: 2,
        },
      ],
      showEditor: false,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    htmlCode() {
      return `<iframe width="100%" height="100%" style="border: none; margin: 0; padding: 0;" src="${this.url}"></iframe>`;
    },
  },
  methods: {
    onCommandHandler(payload) {
      this.$emit("commandHandler", payload);
    },
    copy() {
      navigator.clipboard.writeText(this.htmlCode).then(() => {
        this.$toast.info(this.$t('messages.copied'), {
          duration: 3000,
        });
      });
    },
  },
};
</script>
<style scoped>
.htmlBox {
  background-color: #eeeeee;
  font-size: 13px;
  border-radius: 3px;
  padding: 12px;
}
</style>
