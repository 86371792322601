<template>
  <div class="wit-field-selection">
    <label>{{ label }}</label>
    <v-select 
        dense
        outlined
        solo        
        clearable
        v-model="propertyValue"
        item-color="primary"
        :items="items"
        item-text="properties.fieldName"
        item-value="fieldId"
        :menu-props="{top: false, offsetY: true, contentClass: 'denseList'}">
      <template #selection="{item}">
        <div>{{ item.properties.fieldName }}</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'witDateFieldSelection',
  mixins: [mixin],
  computed: {
    items () {
      const vm = this
      var result = vm.$store.getters.formFieldInfos.filter(info => {
        return info.type === 'date' && info.properties.fieldType==='date'
      })
      return result
    },
    formFieldInfos () {
      const vm = this
      return vm.$store.getters.formFieldInfos
    },
  }
}
</script>

<style>
  .wit-field-selection .v-select__selections {
    padding-top: 4px;
  }
</style>
