<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="!isReadOnly && required"
      :isHidden="isHidden"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :noIcon="noIcon"
      :tooltipText="description">
    <div :class="{ 'workflow-customize-form-record-hide': hideInput }">
    <v-text-field
      v-show="isReadOnly"
      :value="inputValue"
      disabled
      dense
      hide-details
      class="disabled"
    ></v-text-field>
    <dialog-field-date
      v-show="fieldType==='date' && !isReadOnly"
      :key="componentKey"
      ref="date"
      :rules="rules"
      :isLayout="isLayout"
      :isReadOnly="isReadOnly"
      :fieldInfo="fieldInfo"
      :allowedDays="allowedDays"
      :error="hasError"
      :allowDateBack="allowDateBack"
      v-model="inputValue"></dialog-field-date>
    <dialog-field-time
      v-show="fieldType==='time' && !isReadOnly"
      :key="componentKey"
      ref="time"
      :rules="rules"
      :isLayout="isLayout"
      :isReadOnly="isReadOnly"
      :fieldInfo="fieldInfo"
      :minTime="minimumTime"
      :maxTime="maximumTime"
      :error="hasError"
      v-model="inputValue"></dialog-field-time>
    <dialog-field-date-time
      v-show="fieldType==='datetime' && !isReadOnly"
      :key="componentKey"
      ref="datetime"
      :rules="rules"
      :isLayout="isLayout"
      :isReadOnly="isReadOnly"
      :fieldInfo="fieldInfo"
      :allowedDays="allowedDays"
      :minTime="minimumTime"
      :maxTime="maximumTime"
      :error="hasError"
      :allowDateBack="allowDateBack"
      v-model="inputValue"></dialog-field-date-time>
    </div>
  </input-widget-container>
</template>

<script>
  import inputWidgetContainer from './comps/InputWidgetContainer'
  import mixin from './mixin'
  import DialogFieldDate from "../dialogFields/DialogFieldDate";
  import DialogFieldTime from "../dialogFields/DialogFieldTime";
  import DialogFieldDateTime from "../dialogFields/DialogFieldDateTime";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

  export default {
    name: 'inputWidgetDate',
    mixins: [mixin],
    components: {
      inputWidgetContainer,
      DialogFieldDate,
      DialogFieldTime,
      DialogFieldDateTime
    },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data(){
    return{
      componentKey: 0
    }
  },
    computed: {
      fieldType () {
        return getFieldPropertyValue(this.fieldInfo, 'fieldType', 'date')
      },
      validations() {
        const validation = getFieldPropertyValue(
          this.fieldInfo,
          `validation_${this.fieldType}`,
          ''
        );
        return validation.split("||");
      },
      allowDateBack(){
        const allowDateBack = getFieldPropertyValue(
          this.fieldInfo,
          'allowDateBack',
          'true'
        );
        return allowDateBack==='true'
      }
    },
    methods: {
      async validate(){
        if (this.isReadOnly) return true;

        const valid = this.$refs[this.fieldType].$refs[this.fieldType].validate()
        this.hasError = !valid
        this.componentKey++
        return valid
      },
      onBlurred () {
        this.validate(true)
      },
      getDefaultValue () {
        let defaultValueProp = '';
        switch(this.fieldType){
          case 'date':
            defaultValueProp = 'defaultDate';
            break;
          case 'time':
            defaultValueProp = 'defaultTime';
            break;
          case 'datetime':
            defaultValueProp = 'defaultDateTime';
            break;
        }
        if (!this.isLayout && this.defaultType === 'auto' && this.$refs[this.fieldType]){
          return this.$refs[this.fieldType].getDefaultValue()
        }
        else return getFieldPropertyValue(this.fieldInfo,defaultValueProp, '')
      }
    }
  }
</script>