<template>
  <vxe-table-column
    className="fullCell vxeCellMembers"
    :show-overflow="true"
    v-bind="colDef"
    :edit-render="{ name: 'input', autoselect: true, enabled: !readonly }"
  >
    <template v-slot="{ row }">
      <div
        style="overflow:hidden"
        class=" d-flex flex-row flex-wrap align-center"
        :class="rowHeightClass"
        :style="{
          paddingTop: rowHeightClass === 'vxe-table-row-xs' ? '0px' : '',
        }"
      >
        <selected-person-tag
          v-for="employeeInfo in row[fieldId]"
          :key="employeeInfo._id"
          :employeeId="employeeInfo._id"
          :name="employeeInfo.employeeName"
          :teamId="employeeInfo.teamId"
          :info="employeeInfo"
          :teamShowing="teamShowing"
          :hasCloseButton="false"
          class="mt-1"
        ></selected-person-tag>
      </div>
      <quick-action v-if="quickInsert" :rowId="row['_id']" :fieldId="fieldId"> </quick-action>
    </template>
    <template v-slot:edit="{ row }">
      <!-- showingDialog = true:'' -->
      <div
        v-if="isDialog"
        @click="
          !readonly && row.canEdit ? openEmployeesDialog(row, $event) : ''
        "
      >
        <v-input
          hide-details
          class="pa-1 pb-0 w-100 mb-0 vxe-table-input-box"
          style="position: absolute; top: 0; left:0; min-height: 100%;"
          :class="
            (readonly || !row.canEdit) && row[fieldId].length == 0
              ? 'd-none'
              : ''
          "
        >
          <div>
            <selected-person-tag
              v-for="(employeeInfo, index) in row[fieldId]"
              :key="employeeInfo._id"
              :index="index"
              class="mt-1"
              :employeeId="employeeInfo._id"
              :name="employeeInfo.employeeName"
              :teamId="employeeInfo.teamId"
              :info="employeeInfo"
              :teamShowing="teamShowing"
              :hasCloseButton="!readonly"
              deleteCommand="removeEmployeeByIndex"
              clickCommand="removeEmployeeByIndex"
              @onCommand="onCommandHandler($event, row)"
              :bl_list="!readonly"
              :readonly="readonly"
            ></selected-person-tag>
          </div>
        </v-input>
      </div>
      <MembersMenu
        v-else
        :fieldId="fieldId"
        :fullWidth="false"
        :type="isMultiple ? 'multiple' : 'single'"
        @memberClick="onMemberClick(row, $event)"
      >
        <template v-slot:activator="{ on }">
          <v-input
            v-on="on"
            style="background-color:white"
            hide-details
            class="fill-height v-text-field--outlined mb-0"
          >
            <selected-person-tag
              v-for="(employeeInfo, index) in row[fieldId]"
              :key="employeeInfo._id"
              :index="index"
              class="mt-1"
              :employeeId="employeeInfo._id"
              :name="employeeInfo.employeeName"
              :teamId="employeeInfo.teamId"
              :info="employeeInfo"
              :teamShowing="teamShowing"
              :hasCloseButton="!readonly"
              deleteCommand="removeEmployeeByIndex"
              clickCommand="removeEmployeeByIndex"
              @onCommand="onCommandHandler($event, row)"
              :bl_list="!readonly"
              :readonly="readonly"
            ></selected-person-tag>
          </v-input>
        </template>
      </MembersMenu>
      <!-- <dialog-button-select-employees
        class="pa-0"
        v-model="showingDialog"
        :multiple="isMultiple"
        :addButton="false"
        :selectedValue="row[fieldId]"
        @submit="onSubmit($event, row)"
      ></dialog-button-select-employees> -->
    </template>

    <template v-slot:footer="{ column }">
      <summary-cell
        :decimals="decimals"
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"
      ></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
// import mixin from './mixin'
import selectedPersonTag from "@/components/dialogs/comps/SelectedPersonTag";
import mixin from "./mixin";
import quickAction from "./comps/QuickAction";
import summaryCell from "./comps/SummaryCell";
import MembersMenu from "@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu";

export default {
  mixins: [mixin],
  props: ["rowHeightClass"],
  components: {
    selectedPersonTag,
    // dialogButtonSelectEmployees,
    quickAction,
    summaryCell,
    MembersMenu,
  },
  data() {
    return {
      showingDialog: false,
    };
  },
  computed: {
    teamShowing() {
      return this.fieldInfo.properties.teamShowing
        ? this.fieldInfo.properties.teamShowing
        : "";
    },
    isDialog() {
      return (
        (this.fieldInfo.properties.selectionMode &&
          this.fieldInfo.properties.selectionMode == "dialog") ||
        !this.fieldInfo.properties.selectionMode
      );
    },
    isMultiple() {
      return this.fieldInfo.properties.fieldType === "multiple";
    },
    activeTeam() {
      const vm = this;
      var result = null;
      if (vm.allTeamInfos) {
        result = vm.allTeamInfos.find((info) => info._id === vm.selectedTeamId);
      }
      return result;
    },
    allTeamInfos() {
      return this.$store.getters.allTeamInfos;
    },
  },
  methods: {
    updateShowingDialog(val) {
      this.showingDialog = val;
    },
    onCommandHandler(payload, row) {
      switch (payload.command) {
        case "removeEmployeeByIndex":
          this.removeEmployeeByIndex(payload.index, row);
          break;
      }
    },
    onSubmit(payload, row) {
      row[this.fieldId] = payload;
    },
    removeEmployeeByIndex(index, row) {
      row[this.fieldId].splice(index, 1);
    },
    onMemberClick(row, val) {
      if (Array.isArray(row[this.fieldId])) {
        const index = row[this.fieldId].findIndex(
          (employee) => employee._id === val._id
        );
        if (index > -1) this.removeEmployeeByIndex(index, row);
        else row[this.fieldId].push(val);
      } else row[this.fieldId] = [];
    },
    openEmployeesDialog(row) {
      let vm = this;
      var properties = vm.colDef.fieldInfo.properties;
      var payload;
      if (properties.selectionMode && properties.selectionMode == "pulldown") {
        // var attachEle
      } else {
        payload = {
          command: "showEmployeesDialog",
          multiple: vm.isMultiple,
          addButton: false,
          selectedValue: row[vm.fieldId],
          onSubmit: vm.onSubmit,
          row: row,
        };
      }
      this.$emit("onCommand", payload);
    },
  },
};
</script>

<style lang="scss">
.edit-members-modal .v-input {
  border: 1px #dcdfe6 solid !important;
}

.vxeCellMembers {
  .vxe-cell {
    padding: 0px;
    height: 100%;
  }
  .v-text-field--outlined {
    border: 1px solid darkgray;
    border-radius: 0px;
  }
}

.vxeCellMembers.col--actived {
  >.vxe-cell {
    max-height: 100% !important;
  }
}
</style>
