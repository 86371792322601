export default [
  {
    type: 'image',
    exts: ['jpg','jpeg','png','gif','tiff','psd','eps','ai','indd','raw','bmp'],
    icon: 'mdi-image',
    color: 'primary'
  },
  {
    type: 'word',
    exts: ['doc','docm','docx','dot','dotx', 'odt'],
    icon:  'mdi-file-word-outline',
    color: 'primary'
  },
  {
    type: 'excel',
    exts: ['xlsx','xls','xlsm','xlt','xltm','xltx','xla','xlam','xll'],
    icon: 'mdi-file-excel-outline',
    color: 'success'   
  },
  {
    type: 'powerpoint',
    exts: ['pot','potm','potx','ppam','pps','ppsm','ppsx','ppt','pptm','pptx'],
    icon: 'mdi-file-powerpoint-outline',
    color: 'red'
  },
  {
    type: 'file',
    exts: ['csv', 'txt', 'rtf'],
    icon: 'mdi-file-document-outline',
    color: 'grey'
  },
  {
    type: 'pdf',
    exts: ['pdf'],
    icon: 'mdi-file-pdf-outline',     
    color: 'red'                   
  },
  {
    type: 'compressed',
    exts: ['zip','gz'],
    icon: 'mdi-folder-zip-outline',
    color: 'orange darken-4',
  },
  {
    type: 'video',
    exts: ['mp4','mov','wmv','flv','avi','avchd','webm','mkv'],
    icon: 'mdi-video-outline',
    color: 'purple darken-2',
  },
  {
    type: 'audio',
    exts: ['aac','ac3','aiff','flac','m4a','mp3','mp4','ogg','opus','ts','wav','wma'],
    icon: 'mdi-volume-high',
    color: 'yellow darken-2',
  },
  {
    type: 'file-code',
    exts: ['xml'],
    icon: 'mdi-file-code-outline',
    color: 'grey',
  },
  {
    type: '',
    exts: [],
    icon: 'mdi-file',
    color: 'grey'
  }
]
