<template>
<div class="flex-grow-1 white">
  <v-hover v-slot="{ hover }" class="pa-0 ma-0" style="width:100%; height:100%;" >
      <div class="">
          <div class="embeddedUrlActions" style="position: absolute; left: 50%;; z-index:1" v-show="hover && blHover">   
              <v-card style="position: relative; left: -50%;">
                  <v-btn :class="item.bl_title?'info--text':''" @click="item.bl_title=!item.bl_title" small icon:ripple="false" plain><v-icon size="18">mdi-format-title</v-icon></v-btn>
                  <v-btn small icon :ripple="false" plain @click="cloneItem(item)"><v-icon size="18">mdi-content-copy</v-icon></v-btn>
                  <v-btn small icon :ripple="false" plain @click="deleteItem(item)"><v-icon size="18">mdi-delete</v-icon></v-btn>
              </v-card>
          </div>
          <iframe loading="lazy" v-if="item.value!=''" id="ifrm" :src="item.value" style="height: 100%; width:100%"></iframe>
      </div>
  </v-hover>
</div>
</template>


<script>
export default {
  name: 'compEmbeddedUrl',
  props: {
    blHover: Boolean,
    item: Object
  },
  methods: {
    cloneItem (item) {
      this.$emit('cloneItem', item)
    },
    deleteItem (item) {
      this.$emit('deleteItem', item)
    }
  }
}
</script>