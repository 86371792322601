<template>
<div class="pa-1"
     :style="wrapperStyle" v-if="value" style="min-height:100%; overflow: hidden">
     <!--style="background-color:white;"-->
     <!--style="border:1px solid transparent;background-color:transparent;">-->
  <option-button
      v-if="toggleType==='single'"
      :option="getOption(value)"
      :useColor="useColor"
      :toggleType="toggleType"
  ></option-button>
  <option-button
      v-else
      v-for="optionId in value"
      :key="optionId"
      :option="getOption(optionId)"
      :useColor="useColor"
      :toggleType="toggleType"
></option-button>
</div>
</template>

<script>
import optionButton from './OptionButton'

export default {
  name: 'optionChipList',
  components: {
    optionButton
  },
  props: {
    value: Number,
    useColor: {
      type: Boolean,
      default: false
    },
    toggleType: {
      type: String,
      default: 'single'
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick (optionId) {
      console.log('OptionChipList :: onClick :: optionId = ' + optionId)
      this.$emit('click', optionId)
    },
    getOption(id){
        const result = this.options.find(option => option._id ===id)
        return result
    }
  },
  computed: {
    wrapperStyle () {
      return this.editing ?
        {
          border: '1px solid drakgray',
          backgroundColor: 'white'
        }:
        {
          border: '1px solid transparent',
          backgroundColor: 'transparent',
          height: this.toggleType=='multiple'?'0':''
        }
    }

  }
}
</script>
