<template>
  <v-container fluid style="position:relative;height:100%">
    <v-row class="text-center">
      <v-col cols="12"></v-col>
    </v-row>

    <div class="banner-grayscale banner-blur" style="width:100%;height:100%;top:0;left:0;position:absolute;"
         :style="bannerStyle">
    </div>

    <div style="width:100%;height:100%;top:0;left:0;position:absolute;"
         class="logo-bkgd d-flex flex-column align-center justify-center">
      <img :src="require('../assets/yoov_plus.svg')"
        style="object-fit:contain;height:120px;width:auto;"/>
    </div>

  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',
    // mounted () {
    //   alert(  process.env.VUE_APP_API_HOST)
    // },
    data () {
      return {
        bannerStyle: {
          // background: 'url(/lego2.jpg)',
          backgroundRepeat:'no-repeat',
          backgroundPosition:'center center',
          backgroundSize:'cover',
          // backgroundColor:'red',
          opacity:'0.8'
        }
      }
    }
  }
</script>

<style>
  .banner-blur {
    filter: 'blur(1px)';
  }
  .banner-grayscale {
    filter: grayscale(.5);
  }
  .logo-wrapper {
    height: 120px;
  }
</style>
