<template>
  <v-card :hover="true" rounded class="mb-2 panel-card d-flex flex-row justify-start cursor-move"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
     
          :class="{'align-stretch':view.cardImageMode==='contain' || view.cardImageMode==='cover'}">
    <v-img
        class="col-image card-image position-relative"
        :class="{'circle-image':view.cardImageMode==='circle','square-image':view.cardImageMode==='square'}"
        v-if="leftImage"
        width="80"
        :contain="view.cardImageMode!=='cover'"
        :src="imageSrc">
      <zoom-preview
        :mediaId="mediaId"
        style="position:absolute;"
        class="fill-height w-100"></zoom-preview>
    </v-img>
    <div class="d-flex flex-column flex-grow-1" style="position: relative; width:100%">
      <v-img
          class="top-image card-image"
          v-if="topImage"
          :contain="view.cardImageMode!=='cover'"
          height="120"
          max-width="300"
          :src="imageSrc"></v-img>

      <v-card-title
        class="line-height-1 px-2 pt-2 mb-2 pb-0"
      >
        <field-string v-if="titleFieldInfo"          
                      :fieldInfo="titleFieldInfo"
                      :recordData="card"></field-string>
        <div v-else>{{ title }}</div>
      </v-card-title>

      <v-card-text class="px-2 d-flex flex-column">

        <v-row v-if="summaryContent"
               class="mt-0 mb-1 text-caption"
               :class="isSummaryOptionSelection?'':'card-summary-height-restricted'">
          <v-col class="py-0">
            <field-string :fieldInfo="summaryFieldInfo"
                          :textOnly="false"
                          height="20px"
                          :recordData="card"></field-string>
          </v-col>
        </v-row>
        <hr class="section-line"/>

        <v-row class="card-field"
               v-for="fieldInfo in viewCardDisplayFieldInfos"
               :key="fieldInfo.fieldId">
          <card-field :view="view"
                          :card="card"
                          :fieldInfo="fieldInfo"></card-field>
        </v-row>
        <!-- <v-row class="card-field"
               v-for="fieldId in view.cardDisplayFieldIds"
               :key="fieldId">
          <card-field :view="view"
                          :card="card"
                          :fieldId="fieldId"></card-field>
        </v-row> -->

        <!--<v-row class="card-field"-->
        <!--v-for="cardField in cardFields"-->
        <!--:key="cardField.fieldId">-->
        <!--<v-col class="d-flex flex-row align-center">-->
        <!--<label>{{ cardField.fieldName }}</label>-->
        <!--<card-display-field-->
        <!--:field="cardField"></card-display-field>-->
        <!--</v-col>-->
        <!--</v-row>-->

      </v-card-text>
      <card-buttons
          v-if="hovering"
          class="panel-card-buttons"
          :canEdit="card.canEdit"
          :canDelete="card.canDelete"
          @onCommand="onCommandHandler">



      </card-buttons>

    </div>
    <v-img
        class="col-image card-image"
        :class="{'circle-image':view.cardImageMode==='circle','square-image':view.cardImageMode==='square'}"
        v-if="rightImage"
        width="80"
        :contain="view.cardImageMode!=='cover'"
        :src="imageSrc"></v-img>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
      <v-menu v-model="showMenu" 
        :position-x="x"
        :position-y="y" absolute
        offset-y>

                <v-list dense>
                  <v-list-item link 
                  v-for="printTemplate in printTemplates" 
                  :key="printTemplate.templateId"
                  @click="print(printTemplate.templateId)"
                  >
                    <span  style="font-size: 14px;">
                    {{printTemplate.label}}
                    </span>
                    </v-list-item>
                </v-list>
              </v-menu>
  </v-card>
</template>
<script>
import helpers from '@/helpers'
import mediaHelperMixin from '@/mixins/mediaHelperMixin'
import fieldHelperMixin from '@/mixins/fieldHelperMixin'
import cardButtons from './CardButtons'
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import cardField from './CardField'
import zoomPreview from '@/components/zoomPreview'
import fieldString from '@/components/fieldStrings/Fs'

export default {
  name: 'cardBoardCard',
  mixins: [
    mediaHelperMixin,
    fieldHelperMixin
  ],
  components: {
    cardButtons,
    confirmDeleteDialog,
    cardField,
    zoomPreview,
    fieldString
  },
  props: {
    view: Object,
    card: Object,
    printTemplates:Array,
  },
  data () {
    return {
      hovering: false,
      showMenu: false,
      x: 0,
      y: 0,
    }
  },
  methods: {
    show(e){
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
    },
    print(templateId){
      this.$emit('onCommand', {
        command:'print',
        templateId: templateId,
        recordId: this.card._id
      })
    },
    onMouseEnter () {
      this.hovering = true
    },
    onMouseLeave () {
      this.hovering = false
    },
    onCommandHandler (payload) {
      const vm = this
      // console.log('PanelCard :: onCommandHandler :: payload: ', payload)
      switch (payload.command) {
        case 'delete':
          vm.$refs.confirmDeleteDialog.confirm(() => {
            vm.$emit('onCommand', {
              command: payload.command + 'Card',
              card: vm.card
            })
          })
          break

        case 'edit':
          vm.$emit('onCommand', {
            command: 'editCard',
            card: vm.card
          })
          break

        case 'print':
          this.$emit('onCommand', {
            command:'print',

          })
          break;
        default:
          vm.$emit('onCommand', {
            command: payload.command + 'Card',
            card: vm.card
          })
      }
    },
    getFieldName (id, idType) {
      const vm = this
      var result = ''
      if (typeof idType === 'undefined') {
        idType === 'fieldId'
      }
      const fieldInfo = idType === 'fieldId' ?
        vm.fieldInfos.find(info => info.fieldId === id) :
        vm.fieldInfos.find(info => info._id === id)
      if (fieldInfo) {
        result = vm.getFieldNameByInfo(fieldInfo)
      }
      return result
    },
    getFieldNameByInfo (fieldInfo) {
      const vm = this
      var result = ''
      const properties = fieldInfo.properties
      const propertyKeys = Object.keys(properties)
      result = propertyKeys.indexOf('fieldNameTag') >= 0 ?
        vm.$t(properties['fieldNameTag']) :
        properties['fieldName']
      return result
    },
    getFieldNameByInfoId (infoId) {
      return this.getFieldName(infoId, 'infoId')
    },
    getFieldNameByFieldId (fieldId) {
      return this.getFieldName(fieldId, 'fieldId')
    },
  },

  computed: {
    viewCardDisplayFieldInfos () {
      const vm = this
      let result = []
      for (let i = 0; i < vm.view.cardDisplayFieldIds.length; i++) {
        const loopFieldId = vm.view.cardDisplayFieldIds[i]
        const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopFieldId)
        result.push(fieldInfo)
      }
      return result
    },
    isSummaryOptionSelection () {
      const vm = this
      var result = false
      if (vm.summaryFieldInfo) {
        result = vm.summaryFieldInfo.type === 'singleSelection' || vm.summaryFieldInfo.type === 'multipleSelection'
      }
      return result
    },
    summaryFieldInfo () {
      const vm = this
      var result = null
      if (vm.view.cardSummaryFieldId && vm.view.cardSummaryFieldId !== '') {
        return this.form.fieldInfos.find(info => info.fieldId === vm.view.cardSummaryFieldId)
      }
      return result
    },
    summaryContent () {
      const vm = this
      var result = ''
      if (vm.view.cardSummaryFieldId !== '') {
        result = vm.card[vm.view.cardSummaryFieldId]
      }
      return result
    },
    fieldInfos () {
      return this.form.fieldInfos
    },
    form () {
      return this.$store.getters.currentForm
    },
    titleFieldInfo () {
      const vm = this
      var result = null
      if (vm.form.titleFieldInfoId !== '') {
        return this.form.fieldInfos.find(info => info._id === this.form.titleFieldInfoId)
      }
      return result
    },
    title () {
      const vm = this
      var result = ''
      if (vm.titleFieldInfo) {
        result = vm.card[vm.titleFieldInfo.fieldId]
      } else {
        result = '{' + vm.$t('messages.titleFieldNotSpecified') + '}'
      }
      return result
    },
    mediaId () {
      const vm = this
      var result = ''
      if (vm.card && vm.view.cardImageFieldId &&
        (vm.view.cardImageFieldId !== '' && vm.card[vm.view.cardImageFieldId]) &&
        (vm.card[vm.view.cardImageFieldId].length > 0)
      ) {
        result = vm.card[vm.view.cardImageFieldId][0].mediaId
      }
      return result
    },

    imageSrc () {
      const vm = this
      var result = ''
      if (vm.mediaId) {
        result = vm.$store.getters.appHost + '/medias/' + vm.mediaId
      }
      return result
    },

    hasCardImage () {
      return this.view.cardImageFieldId && this.view.cardImageFieldId !== ''
    },

    topImage () {
      return this.hasCardImage && this.view.cardImagePosition === 'top'
    },
    leftImage () {
      return this.hasCardImage && this.view.cardImagePosition === 'left'
    },
    rightImage () {
      return this.hasCardImage && this.view.cardImagePosition === 'right'
    }
  }
}
</script>

<style>
  /*.panel-card:hover .panel-card-buttons {*/
    /*display: block;*/
  /*}*/
  .panel-card {
    width: 100%;
  }
  .panel-card .panel-card-buttons {
    position: absolute;
    top: 10px;
    right: 2px;
    /*display: none;*/
  }
  .col-image {
    width: 80px;
    max-width: 80px;
  }

  .card-image {
    background-color: rgba(0, 0, 0, .05);
  }

  .circle-image {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50% !important;
  }

  .square-image {
    width: 80px !important;
    height: 80px !important;
    border-radius: 0 !important;
  }

  .card-summary-height-restricted {
    max-height: 36px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    line-height: 1 !important;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .card-field.row {
    margin: 0;
  }
  .panel-card .section-line {
    margin-bottom: 5px;
    border-color: rgba(0,0,0,.05);
  }

  .panel-card .v-card__text {
    padding-bottom: 4px;
  }
</style>
