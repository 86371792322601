<template>
  <div class="root d-flex flex-column dark">
    <app-header @onCommand="onCommandHandler">
      <template v-slot:suffix-user>
        <div
          v-if="(atAppsPage && currentApp) || atSidePage"
          class="d-flex flex-row align-center"
        >
          <NotificationBell />
        </div>
      </template>
    </app-header>

    <router-view class="flex-grow-1"></router-view>
    <v-navigation-drawer
      right
      app
      absolute
      temporary
      width="800"
      style="z-index:99999"
      v-model="showDrawer"
    >
      <options-drawer v-if="showOptionsDrawer" />
    </v-navigation-drawer>
    <v-overlay v-if="loading">
      <font-awesome-icon
        icon="spinner"
        class="spinner fa-5x fa-spin"
      ></font-awesome-icon>
    </v-overlay>
    <NotificationPanel />
  </div>
</template>

<script>
import appHeader from "@/components/AppHeader";
import optionsDrawer from "@/pages/apps/comps/OptionsDrawer";
import NotificationBell from "@/pages/admin/workflow/components/notification/bell";

import WorkflowRoutesName from "@/const/workflow/routes";
import NotificationPanel from "@/pages/admin/workflow/components/notification/panel/";

import EventBus from "@/event-bus.js";

export default {
  name: "appRoot",
  components: {
    appHeader,
    optionsDrawer,
    NotificationBell,
    NotificationPanel,
  },
  data() {
    return {
      loading: false,
      showDrawer: false,
      showOptionsDrawer: false,
    };
  },
  created() {
    EventBus.$on("showSpinner", this.showSpinner);
    EventBus.$on("hideSpinner", this.hideSpinner);
  },
  beforeDestroy() {
    EventBus.$off("showSpinner");
    EventBus.$off("hideSpinner");
  },
  watch: {
    showDrawer: function(val) {
      if (!val) {
        this.showOptionsDrawer = false;
      }
    },
  },
  methods: {
    showSpinner() {
      this.loading = true;
    },
    hideSpinner() {
      this.loading = false;
    },
    onCommandHandler(payload) {
      console.log("Root: onCommandHandler : payload: ", payload);
      const vm = this;
      switch (payload.command) {
        case "gotoWorkflow":
          vm.gotoWorkflow();
          break;

        case "gotoMyApps":
          this.$router.push({ name: "myApps" });

          break;
        case "gotoRoleSetup":
          this.gotoRole();
          break;
        case "openAddOptionsDrawer":
          this.showDrawer = true;
          this.showOptionsDrawer = true;
          break;

        case "goToApiDoc":
          {
            const routeData = this.$router.resolve({
              name: "ApiDoc",
              params: {
                appId: this.currentApp._id,
              },
            });
            window.open(routeData.href, "_blank");
          }
          break;
        default:
          console.log(
            "Apps :: onCommandHandler :: no handler: payload: ",
            payload
          );
          break;
      }
    },
    gotoRole() {
      this.$router.push(
        {
          name: "roles",
          params: {
            id: this.currentApp._id,
          },
        }
      );
    },
    gotoWorkflow() {
      const vm = this;
      const { params: query } = vm.$route;
      // vm.$store.dispatch('SET_CURRENT_APP_ID', '')
      let params = {
        id: query.id,
      };

      vm.$router.push(
        {
          name: WorkflowRoutesName.workflows,
          params,
        }
      );
    },
  },
  computed: {
    atAppsPage() {
      const { path } = this.$route;
      return path.indexOf("/apps") === 0 && path.indexOf("/workflows") === -1;
    },
    atSidePage() {
      return this.$route.path.indexOf("/workflows") !== -1;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    appId() {
      return (this.currentApp && this.currentApp._id) || null;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>
