<template>
 <v-menu offset-y>        
  <template v-slot:activator="{on, attrs}">
    <v-btn 
      outlined
      style="border:0;height:100%;"
      class="no-border"
      x-small
      v-bind="attrs"
      v-on="on">
      <v-icon>mdi-dots-horizontal</v-icon>
    </v-btn>
  </template>
  <v-list>
    <v-list-item
      v-for="(tab, index) in tabs"
      @click="$emit('select', index)"
      :key="tab._id">
      <v-list-item-title>{{ tab[labelField] }}</v-list-item-title></v-list-item>
  </v-list>
</v-menu>  
</template>

<script>
export default {
  name: 'tabMenu',
  props: {
    tabs: Array,
    labelField: {
      type: String,
      default: 'label'
    }
  }
}
</script>