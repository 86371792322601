<template>
    <v-navigation-drawer app temporary v-model="$store.state.mobile.filterDrawerOpened" height="auto"  bottom style="z-index:1002" class="rounded-t-xl">
        <div class="py-3 px-5 sorting-drawer-header d-flex justify-space-between">
            <div>{{ $t('view.filter') }}</div>
            <div><v-btn small text @click="closeDialog">{{ $t('buttons.close') }}</v-btn></div>
        </div>
        <div class="px-5 py-3">
            <div v-if="filters.length === 0" class="text-center my-5">
                {{ $t('view.noFiltersFound') }}
            </div>
            <div v-else>
                <div v-for="(field) in filters" :key="field._id" class="mb-1 filter-group">
                    <div class="mb-2">{{ field.label }}</div>
                    <v-chip 
                        v-for="opt in field._options.options" 
                        :key="opt._id" 
                        class="mr-1 mb-1" 
                        :outlined="selectedFilter.fieldId != field._id || selectedFilter.optionId != opt._id"
                        @click="selectItem(field._id, opt._id)"
                        :color="(selectedFilter.fieldId == field._id && selectedFilter.optionId == opt._id)?opt.backgroundColor:''"
                        :text-color="(selectedFilter.fieldId == field._id && selectedFilter.optionId == opt._id)?opt.textColor:''"
                    >
                        {{ opt.label }} 
                    </v-chip>
                </div>
            </div>
        </div>
        <div class="sorting-drawer-footer py-3 px-5 d-flex w-100 justify-space-between">
            <v-btn block outlined @click="reset">{{ $t('buttons.reset') }}</v-btn>
            <v-btn block class="elevation-0" dark color="#2B425E" @click="apply">{{ $t('buttons.apply') }}</v-btn>
        </div>
    </v-navigation-drawer>
</template>

<script>
    export default {
        props: [ 'filters' ],
        data() {
            return {
                selectedFilter: {
                    fieldId: undefined,
                    optionId: undefined
                }
            }
        }, 
        methods: {
            selectItem(_field, _opt) {
                this.selectedFilter.fieldId = _field;
                this.selectedFilter.optionId = _opt;
            }, 
            reset() {
                this.selectedFilter.fieldId = undefined;
                this.selectedFilter.optionId = undefined;
                this.$parent.filter = [];
                this.apply();
            },
            apply() {
                if ( this.selectedFilter.fieldId && this.selectedFilter.optionId ) {
                    this.$parent.filter = [
                        {
                            fieldId: this.selectedFilter.fieldId,
                            relation: "isTheItem",
                            filterValue1: this.selectedFilter.optionId,
                            filterValue2: '',
                        }
                    ];
                }
                this.$parent.data = [];
                this.$parent.currentPage = 1;
                this.$parent.fetchData({ appId: '', formId: '', viewId: ''});
                this.$store.state.mobile.filterDrawerOpened = false;
            },
            closeDialog() {
                this.$store.state.mobile.filterDrawerOpened = false
            }
        }
    }
</script>

<style lang="scss">
    .sorting-drawer-header {
        background: $grey2;
        line-height: 25.6px;
        font-weight: 500;
        font-size: 16px;
    }
    .sorting-drawer-footer {
        background: $grey2;
        gap: 1rem;
    }
    .filter-group {
        border-bottom: 1px solid $grey2;
        padding-bottom: 12px;
        margin-bottom: 12px;
    }
</style>