<template>
  <v-dialog v-model="showDialog" persistent width="540">
    <v-card>
      <dialogHeader
        :label="$t('general.settings')"
        @close="showDialog = false"
      />
      <v-card-text>
        <v-list>
          <v-subheader>{{ $t("publicQuery.selectView") }}</v-subheader>
          <v-list-item>
            <v-list-item-content class="grey--text text--darken-1">
              {{ $t("publicQuery.selectViewDescription") }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-select
              :rules="[rules.required]"
              outlined
              :items="viewOptions"
              item-text="title"
              item-value="_id"
              v-model="selectedView"
              dense
              :menu-props="{offsetY: true, contentClass: 'denseList'}"
            />
          </v-list-item>

          <v-subheader>{{ $t("publicQuery.selectField") }}</v-subheader>
          <v-list-item>
            <v-list-item-content class="grey--text text--darken-1">
              {{ $t("publicQuery.selectFieldDescription") }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-select
              multiple
              outlined
              dense
              :menu-props="{offsetY: true, contentClass: 'denseList'}"
              deletable-chips
              :items="fieldOptions"
              item-text="label"
              item-value="_id"
              v-model="queryFields"
              chips
              :rules="[rules.empty]"
            />
          </v-list-item>
          <v-subheader>{{ $t("publicQuery.pageTitle") }}</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="title"
                outlined
                dense
                :rules="[rules.required]"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <dialog-actions
        :buttons="['cancel', 'save']"
        :actionDisabled="queryFields.length === 0 || !selectedView || !title"
        @onCommand="onCommandHandler"
      ></dialog-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import dialogActions from "@/components/dialogs/comps/DialogActions";

export default {
  components: {
    dialogHeader,
    dialogActions,
  },
  props: {
    value: Boolean,
    publicQuery: Object,
  },
  data() {
    return {
      selectedView: "",
      queryFields: [],
      title: "",
      rules: {
        required: (v) =>
          (v !== null && !!v.trim()) || this.$t("messages.thisFieldIsRequired"),
        empty: (v) =>
          (Array.isArray(v) && v.length > 0) ||
          this.$t("messages.thisFieldIsRequired"),
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    viewOptions() {
      return (
        (this.$store.getters.currentForm &&
          this.$store.getters.currentForm.views) ||
        []
      );
    },
    fieldOptions() {
      const widgets = this.$store.getters.publicQueryWidgetTypes;
      const fieldInfos = this.$store.getters.formFieldInfos;
      if (!fieldInfos) return [];
      else {
        return fieldInfos.filter((info) => widgets.includes(info.type));
      }
    },
  },
  mounted() {
    if (!this.publicQuery) {
      this.selectedView =
        this.viewOptions.length > 0 ? this.viewOptions[0]._id : "";
      this.title = this.$t("publicQuery.defaultTitle", {
        title: this.$store.getters.currentForm.label,
      });
    } else {
      this.selectedView = this.publicQuery.viewId;
      this.queryFields = this.publicQuery.queryFields;
      this.title = this.publicQuery.title;
    }
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "save":
          if (this.queryFields.length === 0 || !this.selectedView || !this.title) return;
          if (this.publicQuery) {
            this.$emit("update", {
              ...this.publicQuery,
              viewId: this.selectedView,
              queryFields: this.queryFields,
              title: this.title,
            });
          } else {
            this.$emit("add", {
              viewId: this.selectedView,
              queryFields: this.queryFields,
              title: this.title,
            });
          }

          break;
        case "cancel":
          this.showDialog = false;
          break;
      }
    },
  },
};
</script>
