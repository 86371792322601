<template>
  <div>
    <v-layout align-center justify-start class="workflow-setting-form-each-item workflow-setting-form-waiting-set">
      {{ $t(`workflow.modules.whatsappNotification.remark.limit`) }}
    </v-layout>
    <RecipientSelect withTypeSelect='phone' withPhoneSelect notificationType="whatsappNotification" />
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.whatsappNotification.label.content')"
        name="content"
      >
        <FreeInput
          :value="notice.content"
          :fields="setting.fields"
          fieldType="text"
          noNewLine
          large
          @itemsChanged="itemsChanged"
          @valueChanged="contentChanged"
        />
      </FormElement>
    </div>
  </div>
</template>

<script>

import FreeInput from './../shared/freeInput';
import Mixin from './../mixin';
import NoticeMixin from './../inBrowserNotification/mixin';
import FormElement from './../shared/formElement';
import RecipientSelect from './../inBrowserNotification/recipient';
import OperationSettingMixin from './../operation/mixin';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module View Wrapper Components
* Terry Chan
* 28/05/2021
*/
// import {
//   SET_SETTINGS,
// } from '@/store/modules/workflow/action_types';

export default {
  name: 'WhatsappNotificationSetting',
  mixins: [OperationSettingMixin, NoticeMixin, Mixin],
  components: {
    FreeInput,
    FormElement,
    RecipientSelect,
  },
  methods: {
    contentChanged(v) {
      const { notice={} } = this;
      this.changed('notice.content', v, { notice })
    },
  },
  // watch: {
  //   setting: {
  //     handler(v) {
  //       console.log(v);
  //     },
  //     deep: true,
  //   }
  // },
};

</script>

<style scoped>

</style>
