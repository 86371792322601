<template>
    <div>
        <PendingSetup
            v-if="!dateSetting.targetField"
            :pendingLabel="$t('workflow.label.setNodeNow')"
            :node="node"
        />
        <div v-else>
            <div class="workflow-shortview-remark">
                <div v-html="$t(`workflow.modules.date.label.formField`, { worksheetName, selectedFieldName })"></div>
                <div>
                    {{ $t(`workflow.modules.date.options.startTime.exact`) }} 
                    <span v-if="dateSetting.mode !== 'exact'">
                        {{ $t(`workflow.modules.date.options.startTime.${ dateSetting.mode }`) }} {{ dateSetting.interval }} {{ $t(`workflow.modules.date.options.unit.${ dateSetting.intervalUnit }`) }}
                    </span>
                    <span v-if="dateSetting.startTime">
                        {{ dateSetting.startTime }}
                    </span>
                    {{ $t(`workflow.modules.date.label.execute`) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import PendingSetup from './../pending';
import Mixin from './../mixin';
export default {
    mixins: [Mixin],
    props: {
        node: Object,
        tree: Object,
        offset: Number,
    },
    computed: {
        dateSetting() {
            const { properties } = this.node;
            return get(properties, 'timer', '');
        },
        worksheetName() {
            const { properties } = this.node;
            return get(properties, 'worksheet.label', '');
        },
        selectedFieldName() {
            const { properties } = this.node;
            let fieldName = '';
            const fieldId = get( properties, 'timer.targetField', '');
            if ( fieldId ) {
                const fieldInfos = get( properties, 'worksheet.fieldInfos', []);
                const field = fieldInfos.find( _field => _field._id === fieldId );
                if ( field ) fieldName = field.label;
            }
            return fieldName;
        }
    },
    components: {
        PendingSetup,
    },
}
</script>
