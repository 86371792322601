<template>
  <div class="pa-0 w-100 fill-height position-relative input-panel-data-cells">
    <div style="height:0;min-height:100%;overflow-y:scroll;">
      <div class="d-flex flex-wrap fill-height">
        <!-- <div v-if="loading"
          class="w-100 d-flex flex-column fill-height justify-center align-center">
          <v-icon class="text-h1" x-large>mdi-spin mdi-loading</v-icon>
        </div> -->
        <v-container>
        <v-row >
            <div
              :class="recordCardClass"
              class="mb-1 position-relative rounded record-card-wrapper"
              style="padding: 0 8px 2px 0;"
              v-for="(row,index) in rows"
              :key="index"
            >
                <!-- @click="selectRecord(relatedRecord)" -->
              <record-card
                :card="row"
                :selected="row.targetRecords&&row.targetRecords.length>0"
                :colorClass="row.targetRecords&&row.targetRecords.length>0?'primary':''"
                :edit="false"
                style="min-height:auto;"
                :cardConfig="inputPanel.cardConfig"
                :titleFieldId="masterFormTitleFieldId"
                :fieldInfos="masterFormFieldInfos"
                :buttons="[]"
                @onCommand="onCommandHandler"
              >
                <template v-slot:rightNote>
                  <div class="time-list-wrapper position-relative">
                    <input-panel-time-list
                      :inputPanel="inputPanel"
                      :masterForm="masterForm"
                      :targetForm="targetForm"
                      :rowId="row._id"
                      @onCommand="onCommandHandler"
                      @onClick="itemIndex=>{editAttendanceInfo(row,itemIndex)}"
                      :targetRecords="row.targetRecords"></input-panel-time-list>
                    <div class="mask-add position-absolute d-flex flex-row justify-end">
                      <v-btn
                        height="24" 
                        @click="addAttendanceInfo(row)">
                          <v-icon small>mdi-alarm-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
              </record-card>
            </div>
          </v-row>
          </v-container>
        <!-- <input-panel-item
          v-else
          v-for="(row,index) in rows"
          :key="index"
          :data="row"
          :inputPanel="inputPanel"
          @onCommand="onCommandHandler"
          :displayConfig="displayConfig"></input-panel-item> -->
      </div>
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <attendance-info-dialog ref="attendanceInfoDialog"></attendance-info-dialog>
    <div v-if="loading"
      class="w-100 fill-height d-flex flex-column position-absolute justify-center align-center"
      style="left:0;top:0;background-color:rgba(128,128,128,.5);">
      <v-icon class="text-h1">mdi-spin mdi-loading</v-icon>
    </div>
  </div>
</template>

<script>
import inputPanelDataMixin from './InputPanelDataMixin'
// import inputPanelItem from './InputPanelItem'
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import attendanceInfoDialog from '../dialogs/AttendanceInfoDialog'
import recordCard from '@/components/RecordCard'
import inputPanelTimeList from './InputPanelTimeList'
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'

// const SYSTEM_FIELD_IDS = [
//   'ownedBy',
//   'createdBy',
//   'updatedAt',
//   'createdAt'
// ]

export default {  
  name: 'inputPanelDataCells',
  mixins: [inputPanelDataMixin, dateTimeHelperMixin],
  components: {
    // inputPanelItem,
    confirmDeleteDialog,
    attendanceInfoDialog,
    recordCard,
    inputPanelTimeList
  },
  computed: {
    recordCardClass () {
      const vm = this
      var result = (vm.inputPanel.cardConfig && vm.inputPanel.cardConfig.cardWidthSize) ?      
        result = vm.inputPanel.cardConfig.cardWidthSize :
        'small'

      switch (result) {
        case 'x-small':
          result = 'col-3';
          break;
        case "small":
          result = "col-4";
          break;
        case "middle":
          result = "col-6";
          break;
        case "large":
          result = "col-12";
          break;
      }
      return result;
    }
  },
  methods: {
    loadMore() {
      // amPmFilter = ['fullDay'|'am'|'pm']
      const vm = this
      vm.pagination.currentPage++
      vm.loading = true
      console.log('loadMore :: vm.pagination: ', vm.pagination)
      vm.$store.dispatch('FETCH_INPUT_PANEL_DATA_BY_MASTER', {
        inputPanelId: vm.inputPanel._id,
        pagination: vm.pagination,
        date: vm.criteria.activeDate,
        amPmFilter: vm.criteria.amPmFilter
      }).then(
        response => {
          console.log('loadMore FETCH_INPUT_PANEL.then')
          vm.loading = false
          // response = {
          //    result: [],
          //    total: ...,
          // }
          if (vm.startOfData) {
            vm.startOfData = false
            vm.rows = response.result
            if (response.total === 0) {
              vm.endOfData = true
            }
          } else if (response.total > vm.rows.length) {
            vm.rows = vm.pagination.currentPage === 1 ?
              response.result :
              vm.rows.concat(response.result)
          } else {
            vm.endOfData = true
          }
        }
      )
    },
    updateRecord (updatedRows) {
      const vm = this
      for (let i = 0; i < updatedRows.length; i++) {
        const loopUpdatedRow = updatedRows[i]
        console.log('updateRecord updateRow.: ', loopUpdatedRow)
        console.log('updateRecord updateRow._id = ' + loopUpdatedRow._id)
        for (let i = 0; i < vm.rows.length; i++) {
          const loopRow = vm.rows[i]
          if (loopRow._id === loopUpdatedRow._id) {
            console.log('found: i=' + i)

            vm.$set(vm.rows[i], 'targetRecords', loopUpdatedRow.targetRecords)
          }
        }
      }
    },
  }
}
</script>

<style>
.input-panel-data-cells .record-card-wrapper:hover .mask-add {
  display: flex!important;
}
.input-panel-data-cells .record-card-wrapper .mask-add {
  display: none!important;
  cursor: pointer;
  padding: 0;
  right: 0;
  top: 100%;
  margin-top: 0px;
  width: 24px;
  z-index:1000;
}
</style>