<template>
<div class="quick-action position-absolute"
  style="right:2px; top: 2px; bottom: 2px; opacity:0.5 !important;">
  <div class="d-flex flex-row fill-height align-center">
  <v-btn x-small class="pa-1 ml-1" color="primary" fab
    @click.stop="copyClicked">
    <v-icon>mdi-content-copy</v-icon></v-btn>  
  <v-btn x-small class="pa-1 ml-1" color="warning" fab
    @click.stop="pasteClicked">
    <v-icon>mdi-content-paste</v-icon></v-btn>  
  </div>
</div>
</template>

<script>
import EventBus from "@/event-bus.js";

export default {
  name: 'quickAction',
  props: {
    rowId: String,
    fieldId: String
  },
  methods: {
    copyClicked () {
      EventBus.$emit('copyToClipboard', {
        rowId: this.rowId,
        fieldId: this.fieldId
      })
    },
    pasteClicked () {
      EventBus.$emit('pasteFromClipboard', {
        rowId: this.rowId,
        fieldId: this.fieldId
      })
    }
  }
}
</script>

<style>

.quick-action button .v-icon {
  min-width: 18px !important;
  min-height: 18px !important;
}

.quick-action .v-btn--fab.v-size--x-small {
  height: 28px !important;
  width: 28px !important;
}


</style>