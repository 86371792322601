<template>
  <div
    class="fill-height d-flex flex-column justify-start level-diagram-view overflow-hidden">
    <div style="position-absolute"></div>
    <div v-if="debugging">
    levelType [{{ levelType }}], isViewDefined [{{ isViewDefined }}],
    isParentChildFieldFormListValid [{{ isParentChildFieldFormListValid }}]<br/>>
    parentChildFieldFormList.length = {{ parentChildFieldFormList.length }}<br/>>
    formInfoDict: {{ Object.keys(formInfoDict).length}}</div>

    <!-- if no view defined -->
    <div v-cloak v-if="!isViewDefined" class="">
      <level-diagram-initial-setup
        :view="view"
        @confirm="onConfirmHandler"></level-diagram-initial-setup>
    </div>

    <div
      v-else
      class="w-100 flex-grow-1 level-diagram-canvas d-flex flex-row justify-stretch">
      <!-- vertical toolbar -->
      <div class="d-flex flex-column flex-grow-0 fill-height align-center">
        <!-- reset button -->
        <v-btn
          x-small
          fab
          @click.stop.prevent="resetPosition"
          color="primary"
          :disabled="dragging"
          class="reset-pan-zoom-button ma-1">
          <v-icon>mdi-circle-double</v-icon>
        </v-btn>
        <v-btn 
          x-small
          fab
          @click.stop.prevent="expandAll"
          color="primary"
          :disabled="dragging"
          class="ma-1">
          <v-icon>mdi-arrow-expand-all</v-icon>
        </v-btn>

        <v-btn 
          x-small
          fab
          @click.stop.prevent="collapseAll"
          color="primary"
          :disabled="dragging"
          class="ma-1">
          <v-icon>mdi-arrow-collapse-all</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <!-- add root node -->
        <v-btn color="primary" fab x-small @click="addRootNode">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex flex-column align-center">
          <v-btn
            :color="size === 'x-small' ? 'primary' : ''"
            class="mb-1"
            x-small
            fab
            @click="size = 'x-small'"
            ><v-icon x-small>mdi-square</v-icon></v-btn
          >

          <v-btn
            :color="size === 'small' ? 'primary' : ''"
            class="mb-1"
            x-small
            fab
            @click="size = 'small'"
            ><v-icon small>mdi-square</v-icon></v-btn
          >

          <v-btn
            :color="size === 'normal' ? 'primary' : ''"
            class="mb-1"
            x-small
            fab
            @click="size = 'normal'"
            ><v-icon>mdi-square</v-icon></v-btn
          >
        </div>
      </div>

      <div class="flex-grow-1 d-flex flex-column fill-height overflow-hidden">
        <!--
        *****************
        toolbar 
        *****************
        -->
        <div class="flex-grow-0 d-flex flex-row align-center pa-1">
          <v-spacer></v-spacer>
          <!-- <v-btn small color="error" @click="resetDemoData" class="mr-1">
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("buttons.reset") }}(debug)
          </v-btn> -->
          <v-btn small color="primary" @click="refresh" class="mr-1">
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("buttons.refresh") }}
          </v-btn>
        </div>
        <!--
        *****************
        headings 
        *****************
        -->
        <div class="flex-grow-0 pa-1 w-100 d-flex flex-row header-wrapper">
          <div
            ref="headerScrollor"
            :style="zoomStyle"
            class="w-100 pa-0 header-row header-scrollor overflow-visible">
            <div class="header-column" :style="{ left: '10px' }">
              <div
                v-for="(heading, index) in headings"
                :key="index"
                class="header-cell"
              >
                <div class="d-flex flex-row justify-center align-center">

                  <!-- multiple relation heading -->
                  <div
                    v-if="typeof heading === 'object'"
                    class="py-0 d-flex flex-row align-center"
                  >
                    <h4
                      v-if="heading.fieldName !== ''"
                      class="text-center d-inline"
                    >
                      {{ heading.fieldName }}
                    </h4>
                    <v-chip color="warning" class="ml-2" small>{{
                      heading.relatedFormLabel
                    }}</v-chip>
                  </div>

                  <!-- parent/child heading -->
                  <div v-else class="py-0 text-center d-flex flex-row align-center w-100">
                    <h4 class="mx-auto d-inline">{{ heading }}</h4>
                    <!-- <v-icon style="min-height:16px;float:right;"
                      @click="refreshLevel(index)"
                      class="cursor-pointer py-0" small>mdi-refresh</v-icon> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width:16px;">&nbsp;</div>
          <div style="height:18px;">&nbsp; &nbsp;</div>
        </div>

        <!-- Data Canvas -->
        <div
          class="flex-grow-1 w-100 fill-height d-flex flex-column white pa-1 pb-3"
          style="background-color:red;"
        >
          <div
            ref="contentScrollor"
            @scroll="onContentScrolled"
            class="flex-grow-1 w-100 fill-height content-scrollor pan-zoom-wrapper pa-0 ma-0 solid-scrollbar"
            style="min-width:100%;width:0;min-height:100%;height:0;overflow:scroll;"
          >
            <div
              ref="levelDiagramViewContent"
              class="level-diagram-view-content"
              style="background-color:white;"
              :style="zoomStyle"
            >
              <div class="child-block-list-wrapper">
                <child-block-list
                  ref="childBlockList"
                  :levelType="levelType"
                  :level="level"
                  :parent="null"
                  :childList="items"
                  :formInfoDict="formInfoDict"
                  :dragging="dragging"
                  :currentParentChildInfos="parentChildInfos"
                  @onSubmit="onSubmitHandler"
                  @openRecord="openRecord"
                  @toggleChild="payload => toggleChild(payload, true)"
                  @onCommand="onCommandHandler"
                  class="parent-node"
                ></child-block-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup-record-dialog
     ref="popupRecordDialog"
     @ready="loading=false"
     @onCommand="onCommandHandler"></popup-record-dialog>
    <!-- <record-dialog ref="recordDialog"></record-dialog> -->
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <!--
    <div style="position:absolute;right:0;width:1000px;bottom: 0;">
      maxDepth: {{ maxDepth }}<br/>
      levelType:: {{ view.levelType }}<br/>
      headings: {{ headings }}<br/>
      parentChildFieldFormList: {{ parentChildFieldFormList }}
    </div>
-->
    <!-- Dialog: Selection Table -->
    <select-related-records-dialog
      ref="selectRelatedRecordsDialog" 
      @click:new="newRelatedRecord"></select-related-records-dialog>

    <!-- Dialog: Card Selection -->
    <select-related-cards-dialog 
      ref="selectRelatedCardsDialog" 
      @click:new="newRelatedRecord"></select-related-cards-dialog>
    <v-overlay v-if="loading||saving">
      <font-awesome-icon 
        icon="spinner"
        class="spinner fa-5x fa-spin"></font-awesome-icon>
    </v-overlay>
  </div>
</template>

<script>
import mixin from "./mixin";
import dialogMixin from "@/mixins/dialogMixin";

import helpers from '@/helpers'

import levelBlockList from "./comps/LevelBlockList";
import tree from "./comps/Tree";
import MediaHelper from "@/helpers/MediaHelper";
import FsLightbox from "fslightbox-vue";
import levelDiagramInitialSetup from "./comps/LevelDiagramInitialSetup";
import childBlockList from "./comps/ChildBlockList";
import popupRecordDialog from '@/components/dialogs/PopupRecordDialog'

import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
import selectRelatedCardsDialog from "@/components/dialogs/SelectRelatedCardsDialog";

import { newBlankRecord } from "@/helpers/FormHelpers";

import { LEVEL_DIAGRAM_CARD_CONFIG } from "@/const/defaults";
import { getTitleFieldId, getFirstAttachmentsFieldId } from "@/helpers/FormHelpers";

import EventBus from "@/event-bus.js";

const INSERT = true
const UPDATE = false
const RECURSIVE = true

// Notes
//
// levelType = 'currentTable':
// parentChildFieldIdPair = {
//    parentFieldId: {parentFieldId},
//    childFieldId: {childFieldId}
// }
//
// levelType = 'multipleTables':
// parentChildFieldFormList = [
//    {fieldId: {fieldId}, formId: {formId}},
//    {fieldId: {fieldId}, formId: {formId}},
//    {fieldId: {fieldId}, formId: {formId}},
//    {fieldId: {fieldId}, formId: {formId}},
//    {fieldId: {fieldId}, formId: {formId}},
//    {fieldId: {fieldId}, formId: {formId}},
// ]
//

export default {
  name: "levelDiagramView",
  mixins: [mixin, dialogMixin],
  components: {
    levelBlockList,
    tree,
    FsLightbox,
    levelDiagramInitialSetup,
    childBlockList,

    popupRecordDialog,
    confirmDeleteDialog,
    selectRelatedCardsDialog,
    selectRelatedRecordsDialog
  },
  computed: {
    debugging () {
      return this.$store.getters.debugging
    },
    zoomStyle () {
      const vm = this
      var result = {}
      switch (vm.size) {
        case 'x-small':
          result['zoom'] = '40%'
          break
        case 'small':
          result['zoom'] = '70%'
          break
        case 'normal':
          result['zoom'] = '100%'
          break
      }
      return result
    },
    levelType () {
      return this.view.levelType
    },
    isMultiple () {
      // console.log('computed(isMultiple)')
      return this.levelType === 'multipleTables'
    },
    maxDepth() {
      return this.getDepth(this.items);
    },
    headings() {
      const vm = this
      // console.log('headings: vm.isMultiple = ' + (vm.isMultiple ? 'yes' : 'no'))
      if (vm.isMultiple) {
        // console.log('headings: vm.isMultiple')
        result = vm.multipleTablesHeadings
        vm.tempOutputFormInfoCount()
      } else {
        var result = vm.currentTableHeadings
        // console.log('headings : vm.currentTableHeadings : ', vm.currentTableHeadings)
      }
      return result
    },
    multipleTablesHeadings () {
      const vm = this;
      var result = [];
      if (vm.isMultiple) {
        // console.log("computed(multipleTablesHeadings) vm.levelTytpe = " + vm.levelType);
        // console.log("computed(multipleTablesHeadings) case multipleTables");
        if (vm.headingsForMultipleTable) {
          // console.log("computed(multipleTablesHeadings) vm.headingsForMultipleTable is available :: vm.headingsForMultipleTable: ",
          //   vm.headingsForMultipleTable
          // );
          result = vm.headingsForMultipleTable;
        } else {
          // console.log('computed(multipleTablesHeadings) vm.headingsForMultipleTable not available')
          // console.log('computed(multipleTablesHeadings) loadMultipleFormInfos(..)')

          vm.loadMultipleFormInfos(formInfoDict => {
            // console.log('loadMultipleFormInfos.then :: formInfoDict: ', formInfoDict)
            vm.getHeadingsForMultipleTables();
          });
        }
      }
      return result
    },
    currentTableHeadings () {
      const vm = this
      var result = []
      if (!vm.isMultiple) {
        for (let i = 0; i < vm.maxDepth; i++) {
          result.push(vm.$t("messages.theNthLevel", { number: i + 1 }));
        }
        vm.formInfoDict = {};
        vm.formInfoDict[vm.form._id] = vm.form;
      }
      return result
    },
    // parentChildInfos =
    // all nodes storing:
    // 1. current form id
    // 2. current form's field storing child record ids
    // 3. child form id
    // 4. cardConfig
    //

    parentChildInfos() {
      const vm = this
      // console.log('parentChildInfo: vm.view.levelType = ' + vm.view.levelType)
      // console.log('parentChildInfo: levelType = ' + vm.levelType)
      // console.log('parentChildInfo: vm.levelType===multipleTables: ' + (vm.levelType === 'multipleTables'))
      // console.log('parentChildInfo: isMultiple = ' + vm.isMultiple)
      return this.isMultiple
        ? this.parentChildInfosForMultipleTable
        : this.parentChildInfosForCurrentTable
    },

    parentChildInfosForCurrentTable() {
      const vm = this;
      // console.log('parentChildInfosForCurrentTable :: vm.view.parentChildFieldIdPair: ', vm.view.parentChildFieldIdPair)
      // console.log('parentChildInfosForCurrentTable :: vm.view.parentChildFieldIdPair.parentFieldId: ', vm.view.parentChildFieldIdPair.parentFieldId)
      // console.log('parentChildInfosForCurrentTable :: vm.view.parentChildFieldIdPair.childFieldId: ', vm.view.parentChildFieldIdPair.childFieldId)
      return [
        {
          currentFormId: vm.form._id,
          fieldContainsParentRecordId: vm.parentChildFieldIdPair.parentFieldId,
          fieldContainsChildRecordIds: vm.parentChildFieldIdPair.childFieldId,
          childFormId: vm.form._id,
          cardConfig: vm.parentChildFieldIdPair.cardConfig
        }
      ];
    },

    parentChildInfosForMultipleTable() {
      const vm = this;
      var result = [];

      // console.log('parentChildInfosForMultipleTable')
      // console.log(
      //   "parentChildInfos :: vm.parentChildFieldFormList: ",
      //   JSON.stringify(vm.parentChildFieldFormList)
      // );

      if (
        vm.parentChildFieldFormList &&
        vm.parentChildFieldFormList.length > 0
      ) {
        var lastFormId = vm.parentChildFieldFormList[0].relatedFormId;
        var lastCardConfig = vm.parentChildFieldFormList[0].cardConfig;
        for (let i = 1; i < vm.parentChildFieldFormList.length; i++) {
          const loopItem = vm.parentChildFieldFormList[i];
          result.push({
            currentFormId: lastFormId,
            fieldContainsParentRecordIds: "",
            fieldContainsChildRecordIds: loopItem.fieldId,
            childFormId: loopItem.relatedFormId,
            cardConfig: lastCardConfig
          });
          lastFormId = loopItem.relatedFormId;
          lastCardConfig = loopItem.cardConfig;
        }
        result.push({
          currentFormId: lastFormId,
          fieldContainsParentRecordIds: "",
          fieldContainsChildRecordIds: "",
          childFormId: "",
          cardConfig: lastCardConfig
        });
      }
      return result;
    },
    parentChildFieldFormList() {
      return this.view ? this.view.parentChildFieldFormList : null;
    },
    parentChildFieldIdPair() {
      return this.view ? this.view.parentChildFieldIdPair : null;
    },
    parentFieldId() {
      return this.parentChildFieldIdPair
        ? this.parentChildFieldIdPair.parentFieldId
        : "";
    },
    childFieldId() {
      return this.parentChildFieldIdPair
        ? this.parentChildFieldIdPair.childFieldId
        : "";
      // sample: childrenIds"
    },

    isParentChildFieldFormListValid () {
      const vm = this
      let result = true
      if (vm.view && vm.view.parentChildFieldFormList) {
        result = vm.view.parentChildFieldFormList.length > 0
      }
      return result
    },

    isParentChildFieldIdPairValid () {
      const vm = this
      let result = true
      if (vm.validParentChildFieldIdPair) {
        result = vm.validParentChildFieldIdPair.length === 2
      }
      return result
    },

    isViewDefined() {
      const vm = this;
      var result = true;

      console.log('isViewDefined :; vm.levelType = ' + vm.levelType)
      if (vm.levelType) {
        if (vm.view) {
          // console.log('isViewDefined')
          switch (vm.levelType) {
            case "currentTable":
              result = vm.isParentChildFieldIdPairValid;
              break;
            case "multipleTables":
              result = vm.isParentChildFieldFormListValid;
              break;
            default:
              result = false;
          }
        } else {
          console.log('view not defined')
        }
      } else {
        result = false;
      }
      return result;

      // remove all obsolate field ids
      // console.log('LevelDiagramView :: isViewDefined :: view: ', vm.view)
      // view.levelType = [currentTable | multipleTables]
      //
      // only one entry for levelType=currentTable
      // view.parentChildFormInfos = [
      //    '23423432423423432432', // first level or current table
      //    '23423432432423234324', // second level
      //    '23432432432432432432',
      //    .....
      // ]
    },
    zoomScale() {
      const vm = this;
      var result = 1;
      if (vm.$refs.panZoom) {
        result = vm.$refs.panZoom.$panZoomInstance.getTransform().scale;
      }
      return result;
    },
    // isPanZoomPaused () {
    //   const vm = this
    //   var result = true
    //   if (vm.$refs.panZoom) {
    //     result = vm.$refs.panZoom.$panZoomInstance.isPaused()
    //   }
    //   return result
    // },
    items2: {
      get() {
        return this.value;
      },
      set(val) {
        // console.log(
        //   "LevelDiagramView : computed(items) set : val: " + JSON.stringify(val)
        // );
        this.value = val;
      }
    }
  },
  data () {
    return {
      size: 'normal',
      START_X: 5,
      START_Y: 0,
      show: true,
      items: [],
      dragging: false,
      resetStatus: false,
      x: 0,
      y: 0,
      panZoom: null,
      currentZoom: 1,
      isPanZoomPaused: false,
      // isParentChildFieldIdPairValid: false,
      // isParentChildFieldFormListValid: false,
      validParentChildFieldIdPair: null,
      activeRecord: null,
      activeFormId: "",
      formInfoDict: {},
      headingsForMultipleTable: null,
      level: 0,
      loading: false,
      saving: false
    };
  },
  mounted() {
    this.reloadData();
  },
  async created() {
    const vm = this;
    EventBus.$on("submitNewItem", payload => {
      vm.onSubmitHandler(payload);
    });
    EventBus.$on('cancelNewItem', payload => {
      // console.log('eventbus on(cancelNewItem)')
      vm.onCancelHandler(payload)
    })
    EventBus.$on('viewRecord', payload => {
      vm.onViewRecordHandler(payload)
    })
    EventBus.$on('selectRecord', payload => {
      vm.onSelectRecordHandler(payload)
    })
  },
  async beforeDestroy() {
    EventBus.$off('selectRecord')
    EventBus.$off('viewRecord')
    EventBus.$off('cancelNewItem')
    EventBus.$off("submitNewItem");
  },
  watch: {
    "view._id": function(newVal, oldVal) {
      const vm = this
      vm.reloadData();
      // console.log('watch after reload Data')
      vm.tempOutputFormInfoCount()
      // alert('view changed: newVal=' + newVal + "\n" +
      // 'oldValue=' + oldVal)
    }
  },
  // '$refs.contentScrollor.scrollLeft': function(newVal) {
  //   console.log('watch(contentScrollor.scrollLeft) newVal = ' + newVal)
  //   this.$refs.headerScrollor.style.left = -this.$refs.contentScrollbar.scrollLeft + 'px'
  // },
  methods: {
    openRecord (payload) {
      console.log('LevelDiagramView :: openRecord: payload: ', payload)
      this.onViewRecordHandler(payload)
    },
    expandAll () {
      const vm = this
      console.log('expandAll')
      for (let i = 0; i < vm.items.length; i++) {
        const loopItem = vm.items[i]
        vm.refreshOpenChildren(loopItem, null, RECURSIVE)
      }
    },

    collapseAll() {
      const vm = this
      for (let i = 0; i < vm.items.length; i++) {
        vm.items[i].children = []
        vm.items[i].open = false
      }
    },

    tempOutputFormInfoCount() {
      // console.log('*** formInfoDict count = ' + Object.keys(this.formInfoDict).length)
    },

    resize(size) {

    },

    reloadData() {
      const vm = this;
      // console.log('reloadData: view.parentChildFieldIdPair: ', JSON.stringify(vm.view.parentChildFieldIdPair))
      // console.log('reloadData: view.parentChildFieldFormList: ', JSON.stringify(vm.view.parentChildFieldFormList))
      
      vm.checkFieldValidity(() => {
        vm.refresh();
      });
    },

    getMaxDepth() {},

    getDepth(nodes) {
      const vm = this;
      var result = 1;
      var maxChildDepth = 0;
      for (let i = 0; i < nodes.length; i++) {
        const loopNode = nodes[i];
        if (loopNode.open) {
          const depth = vm.getDepth(loopNode.children);
          if (depth > maxChildDepth) maxChildDepth = depth;
        }
      }
      return result + maxChildDepth;
    },

    loadMultipleFormInfos(callback) {
      const vm = this;
      var promises = [];
      vm.formInfoDict = {};
      vm.formInfoDict[vm.form._id] = vm.form;

      if (
        vm.parentChildFieldFormList &&
        vm.parentChildFieldFormList.length > 0
      ) {

        // console.log('vm.parentChildFieldFormList: ', vm.parentChildFieldFormList)        
        // console.log('vm.parentChildFieldFormList && vm.parentChildFieldFormList.length > 0')
        for (let i = 1; i < vm.parentChildFieldFormList.length; i++) {
          const loopItem = vm.parentChildFieldFormList[i];
          const ds = {
            teamId: loopItem.relatedTeamId,
            appId: loopItem.relatedAppId,
            formId: loopItem.relatedFormId
          }
          // console.log('loadMultipleFormInfos: ds: ', ds)
          promises.push(
            vm.$store.dispatch("FETCH_DATASOURCE_INFO", ds)
          );
        }
        // console.log('loadMultipleFormInfos => Promise')
        Promise.all(promises).then(infos => {
          // console.log('FETCH_DATASOURCE_INFO : infos.length = ' + infos.length)
          for (let j = 0; j < infos.length; j++) {
            const loopInfo = infos[j];
            // console.log('j=' + j + ': loopInfo: ', loopInfo)
            vm.formInfoDict[loopInfo._id] = loopInfo;
          }
          // console.log('loadMultipleFormInfos :: after FETCH_DATASOURCE_INFO : vm.formInfoDict: ', vm.formInfoDict)
          if (typeof callback === "function") {
            // console.log('loadMultipleFormInfos :: before callback is function')
            vm.tempOutputFormInfoCount()
            callback(vm.formInfoDict);
            // console.log('loadMultipleFormInfos :: after callback is function')
            vm.tempOutputFormInfoCount()
          }
        });
      } else {
        // console.log('loadMultipleFormInfos => resolve({})')
        Promise.resolve({})
      }
    },
    
    getHeadingsForMultipleTables() {
      const vm = this;
      // console.log("getHeadingsForMultipleTables");
      var result = [
        {
          fieldId: "",
          fieldName: "",
          relatedFormId: vm.form._id,
          relatedFormLabel: vm.form.label
          // vm.$t('general.thisTable')
        }
      ];
      // console.log("getHeadingsForMultipleTables: formInfoDict: ", vm.formInfoDict);
      if (
        vm.parentChildFieldFormList &&
        vm.parentChildFieldFormList.length > 0
      ) {
        var lastFormInfo = vm.form;
        for (let i = 1; i < vm.parentChildFieldFormList.length; i++) {
          const loopItem = vm.parentChildFieldFormList[i];
          const formInfo = lastFormInfo;

          // console.log('getHeadingsForMultipleTables :: i=' + i + ': ' + formInfo.label)
          lastFormInfo = vm.getFormInfo(loopItem.relatedFormId, vm.formInfoDict);
          console.log({
            "formInfo.fieldInfos": formInfo.fieldInfos, 
            "loopItem.fieldId": loopItem.fieldId
          })
          result.push({
            fieldId: loopItem.fieldId,
            fieldName: vm.getFieldName(formInfo.fieldInfos, loopItem.fieldId),
            relatedFormId: loopItem.relatedFormId,
            relatedFormLabel: lastFormInfo.label
          });
        }
      }
      if (JSON.stringify(vm.headingsForMultipleTable) !== JSON.stringify(result)) {
        vm.headingsForMultipleTable = result;
      }
      // console.log('getHeadingsForMultipleTables ends')
    },

    getFieldInfo (fieldId, formId, formInfo=null) {
      const vm = this
      var result = null
      if (!formInfo) {
        // console.log('getFieldInfo: formInfo not defined => getFormInfo')
        const formInfo = vm.getFormInfo(formId)      
      }
      if (formInfo) {
        // console.log('getFieldInfo : formInfo defined')
        result = formInfo.fieldInfos.find(info => info.fieldId === fieldId)
        // console.log('getFieldInfo : result: ', result)
      }
      return result ? result : null
    },
    getFormInfo(formId, formInfoDict = null) {
      const vm = this;
      if (formInfoDict === null) {
        formInfoDict = vm.formInfoDict;
      }
      var result = null;
      // console.log('getFormInfo :: formId = ' + formId)


      if (vm.formInfoDict[formId]) {
        result = vm.formInfoDict[formId];
      }

      // console.log('getFormInfo :: result: ', result)
      return result;
    },
    getHeadingsForMultipleTables2() {
      const vm = this;
      // console.log("getHeadingsForMultipleTables");
      var promises = [];
      var result = [
        {
          fieldId: "",
          fieldName: "",
          relatedFormId: "",
          relatedFormLabel: vm.$t("general.thisTable")
        }
      ];
      console.log(
        "getHeadingsForMultipleTables :: vm.parentChildFieldFormList.length = " +
          vm.parentChildFieldFormList.length
      );

      if (
        vm.parentChildFieldFormList &&
        vm.parentChildFieldFormList.length > 0
      ) {
        for (let i = 1; i < vm.parentChildFieldFormList.length; i++) {
          const loopItem = vm.parentChildFieldFormList[i];
          // console.log("loopItem: ", loopItem);
          // console.log(
          //   "getHeadingsForMultipleTables :: i=" +
          //     i +
          //     ": " +
          //     " relatedFormId = " +
          //     loopItem.relatdFormId
          // );

          const ds = {
            teamId: loopItem.relatedTeamId,
            appId: loopItem.relatedAppId,
            formId: loopItem.relatedFormId
          }
          result.push({
            fieldId: loopItem.fieldId,
            fieldName: vm.getFieldName(vm.fieldInfos, loopItem.fieldId),
            relatedFormId: loopItem.relatedFormId,
            relatedFormLabel: ""
          });
          promises.push(
            vm.$store.dispatch("FETCH_DATASOURCE_INFO", ds)
          );
        }
        Promise.all(promises).then(infos => {
          for (let j = 0; j < infos.length; j++) {
            const loopInfo = infos[j];
            result[j + 1].relatedFormLabel = loopInfo.label;
          }
        });
      }
      return result;
    },

    onContentScrolled(evt) {
      const vm = this;
      const hScroll = evt.target.scrollLeft;
      vm.$refs.headerScrollor.style.left = -hScroll + "px";
      // console.log("LevelDiagramView :: onContentScrolled :: evt: ", evt);
    },
    pan() {
      return false;
    },
    checkFieldValidity(callback) {
      const vm = this;
      // console.log(
      //   "checkFieldValidity(callback) :: vm.view.levelType = " +
      //     vm.view.levelType
      // );
      //  alert('checkFieldValidity :: vm.view.levelType = ' + vm.view.levelType)
      switch (vm.levelType) {
        case "currentTable":
          console.log(
            "checkFieldValidity(callback) :: switch(levelType) case currentTable"
          );
          vm.checkParentChildFieldIdPair(callback);
          break;
        case "multipleTables":
          // console.log(
          //   "checkFieldValidity(callback) :: JSON(vm.view): " +
          //     JSON.stringify(vm.view)
          // );
          console.log(
            "checkFieldValidity(callback) :: switch(levelType) case multipleTables"
          );
          vm.checkParentChildFieldFormList(callback);
          break;
      }
    },
    checkParentChildFieldIdPair(callback) {
      // Check any non-existing field and update the config
      const vm = this;
      console.log("checkParentChildFieldIdPair ... ");
      var promises = [];
      promises.push(
        vm.checkFieldIdExists(vm.view.parentChildFieldIdPair.parentFieldId)
      );
      promises.push(
        vm.checkFieldIdExists(vm.view.parentChildFieldIdPair.childFieldId)
      );
      Promise.all(promises).then(response => {
        console.log("checkParentChildIdPair: response: ", response);
        var result = response.reduce((item, res) => {
          return res && item;
        }, true);
        if (!result) {
          vm.view.parentChildFieldIdPair = null;
          vm.updateFormView(vm.form, vm.view);
          vm.validParentChildFieldIdPair = []
        } else {
          vm.validParentChildFieldIdPair = [
            vm.view.parentChildFieldIdPair.parentFieldId,
            vm.view.parentChildFieldIdPair.childFieldId
          ]
        }

        console.log(
          "checkParentChildFieldIdPair ... isParentChildFieldIdPairValid = " +
            (result ? "yes" : "no")
        );

        // vm.isParentChildFieldIdPairValid = result;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    checkParentChildFieldFormList(callback) {
      // Check any non-existing field and update the config
      const vm = this;
      console.log(
        "checkParentChildFieldFormList :: vm.view.parentChildFieldFormList: ",
        vm.view.parentChildFieldFormList
      );

      var promises = [];
      for (let i = 0; i < vm.view.parentChildFieldFormList.length; i++) {
        const loopItem = vm.view.parentChildFieldFormList[i];
        const ds = {
          teamId: loopItem.relatedTeamId,
          appId: loopItem.relatedAppId,
          formId: loopItem.relatedFormId
        }
        promises.push(
          vm.checkFormFieldExists(ds)
        );
      }
      console.log("checkParentChildFieldFormList :: promises: ", promises);
      Promise.all(promises).then(response => {
        console.log(
          "checkParentChildFieldFormList :: promises all . then: response: ",
          response
        );
        var validFormFieldSet = [];
        for (let j = 0; j < response.length; j++) {
          if (response[j]) {
            validFormFieldSet.push(vm.view.parentChildFieldFormList[j]);
          }
        }
        console.log(
          "checkParentChildFieldFormList :: validFormFieldSet: ",
          validFormFieldSet
        );
        // vm.isParentChildFieldFormListValid = validFormFieldSet.length > 0;
        if (
          validFormFieldSet.length < vm.view.parentChildFieldFormList.length
        ) {
          vm.view.parentChildFieldFormList = validFormFieldSet;
          vm.updateFormView(vm.form, vm.view);
        }
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    updateFormView(form, view) {},
    checkFormFieldExists(ds) {
      return new Promise((resolve, reject) => {
        const vm = this;
        // console.log("formFieldExists :: payload: ", payload);
        vm.$store
          .dispatch("FETCH_DATASOURCE_INFO", ds)
          .then(response => {
            console.log(
              "LevelDiagramView :: formFieldExists: response: ",
              response
            );
            if (response) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(err => {
            resolve(false);
          });
      });
    },

    checkFieldIdExists(fieldId) {
      const vm = this;
      const result = vm.fieldIdExists(fieldId);
      return Promise.resolve(result);
    },

    getItem(nodes, nodeId, level=0) {
      const vm = this;
      var result = null;
      // console.log('getItem :: node id s: ', nodes.map(n => n._id).join(','))
      console.log('getItem :: nodeId = ' + JSON.stringify(nodeId))

      for (let i = 0; i < nodes.length; i++) {
        const loopNode = nodes[i];
        console.log('i=' + i + ': loopNode: ', loopNode)
        console.log('i=' + i + ': loopNode._id = ' + loopNode._id)
        if (loopNode._id === nodeId) {
          result = {
            level: level,
            node: loopNode
          }
          break;
        } else {
          const res = vm.getItem(loopNode.children, nodeId, level+1)
          if (res) {
            result = res
            break
          }
          if (result) break;
        }
      }
      // console.log('getItem(nodes,nodeId,' + level + ') result: ', result)
      return result;
    },

    refresh() {
      const vm = this;
      if (vm.isViewDefined) {
        // alert('LevelDiagramView :: refresh (isViewDedfined) => isViewDefined => loadData')
        // vm.loadAllRelatedTableInfos()
        // alert('refresh')
        // alert('refresh :: Object.keys(vm.formInfoDict).length = ' + Object.keys(vm.formInfoDict).length)
        vm.loadData( () => {
          console.log('refresh :; ')
          vm.tempOutputFormInfoCount()
        });
      } else {
        // alert('LevelDiagramView :: refresh (!isViewDedfined) => isViewDefined => loadData')
      }
    },
    resetDemoData() {
      const vm = this;
      // alert('resetDemoData')
      if (vm.isViewDefined) {
        vm.$store
          .dispatch("AUTH_POST", {
            urlCommand: "/test/reset_demo_data/60877662854989477fbd9801"
          })
          .then(() => {
            vm.loadData(() => {
              console.log('resetDemoData ::: ')
              vm.tempOutputFormInfoCount()
            });
          });
      }
    },
    getFieldName(fieldInfos, fieldId) {
      const vm = this;
      const fieldInfo = fieldInfos.find(info => info.fieldId === fieldId);
      return fieldInfo ? fieldInfo.label : "???";
    },

    loadData(callback) {
      const vm = this;
      vm.loading = true
      console.log('LevelDiagramView :: loadData :: vm.view: ', vm.view)
      if (vm.isMultiple) {
        console.log('LevelDiagramView :: loadData : vm.isMultiple')
        vm.loadMultipleFormInfos(formInfoDict => {
          console.log('loadData :: loadMultipleFormInfos.then :: formInfoDict: ', formInfoDict)
          vm.getHeadingsForMultipleTables()
          vm.getChildData(null, children => {
            vm.items = children;
            vm.loading = false
            if (typeof callback === 'function') {
              callback()
            }
          });
          console.log('loadData :: Object.keys(vm.formInfoDict).length = ' + Object.keys(vm.formInfoDict).length)
          
        })
      } else {
        vm.getChildData(null, children => {
          vm.items = children;
          vm.loading = false
        });
      }
    },

    getChildData(parent, callback) {
      const vm = this;
      console.log('getChildData starts')
      switch (vm.levelType) {
        case "currentTable":
          console.log('LevelDiagramView :: getChildData :: currentTable: parent: ', parent)
          // alert('call getChildDataOfCurrentTable')
          vm.getChildrenOfCurrentTable(parent, callback);
          break;
        case "multipleTables":
          console.log('LevelDiagramView :: getChildData :: multipleTables: ');
          vm.tempOutputFormInfoCount()
          // alert('call getChildrenOfMultipleTables')
          vm.getChildrenOfMultipleTables(parent, callback);
          break;
      }
      console.log('getChildData ends')
    },

    getChildrenOfMultipleTables(parent, callback) {
      const vm = this;
      console.log('getChildrenOfMultipleTables : parent: ', parent)

      var params = {
        appId: vm.currentApp._id,
        formId: vm.form._id,
        viewId: vm.view._id
        // filter: JSON.stringify(filter)
      };

      var requiresFetching = true;

      console.log('getChildrenOfMultipleTables: parent: ', parent)
      if (parent) {
        console.log('getChildrenOfMultipleTables : vm.parentChildInfos: ', vm.parentChildInfos)

        const parentChildInfo = vm.parentChildInfos.find(
          info => info.currentFormId === parent.formId
        );

        console.log('getChildrenOfMultipleTables :: parentChildInfo: ', parentChildInfo)
        const childFormInfo = vm.getFormInfo(parentChildInfo.childFormId)
        params["formId"] = parentChildInfo.childFormId;
        params["inclusion"] = parent[parentChildInfo.fieldContainsChildRecordIds];
        console.log('getChildrenOfMultipleTables 1:: params: ', params)
        params['masterFormId'] = vm.form._id
        params['sortingFieldId'] = getTitleFieldId(childFormInfo)
        console.log('getChildrenOfMultipleTables 2:: params: ', params)
        params['sortingOrder'] = 'asc'
        console.log('getChildrenOfMultipleTables 3:: params: ', params)

        requiresFetching = params["inclusion"] && params["inclusion"].length > 0;
      } else {
        console.log('no parent')
      }

      if (requiresFetching) {
        console.log('getChildrenOfMultipleTables > before FETCH_DATA_LIST: params: ', params)
        vm.$store.dispatch("FETCH_DATA_LIST", params).then(response => {
          console.log('getChildrenOfMultipleTables > FETCH_DATA_LIST: response: ', response)
          const items = response.result.map(item => {
            return {
              ...item,
              open: false,
              loading: false,
              children: []
            };
          });
          if (typeof callback === "function") {
            callback(items);
          }
        });
      } else {
        if (typeof callback === "function") {
          callback([]);
        }
      }
    },

    getChildrenOfCurrentTable(parent, callback) {
      const vm = this;
      const parentId = parent ? parent._id : "";
      var filter = [{
        fieldId:vm.parentFieldId,
        value: parentId ? [{_id:parentId}]: false,
        relation: parentId ? "isTheRelation" : "hasParent"
      }];
      const params = {
        appId: vm.currentApp._id,
        formId: vm.form._id,
        viewId: vm.view._id,
        filter: JSON.stringify(filter)
      };
      if (parent) {
        parent.loading = true
      }
      // console.log('getChildrenOfCurrentTable > before FETCH_DATA_LIST: params: ', params)
      vm.$store.dispatch("FETCH_DATA_LIST", params).then(response => {
        // console.log('getChildrenOfCurrentTable > FETCH_DATA_LIST: response: ', response)
        const items = response.result.map(item => {
          return {
            ...item,
            open: false,
            loading: false,
            children: []
          };
        });
        if (typeof callback === "function") {
          callback(items);
        }
      });
    },

    // getChildren2(parentId, callback) {
    //   const vm = this;
    //   const response = JSON.parse(JSON.stringify(vm.data));
    //   var items = response.filter(item => item.parent === parentId);
    //   items = items.map(item => {
    //     return {
    //       ...item,
    //       open: false,
    //       children: []
    //     };
    //   });
    //   if (typeof callback === "function") {
    //     console.log("getChildren : callback is function");
    //     callback(items);
    //   }
    // },
    setFieldValues(nodes, nodeId, fieldValues, callback) {
      const vm = this;
      var result = false;
      console.log({nodes, nodeId, fieldValues})
      console.log("nodes.length", nodes.length)
      for (let i = 0; i < nodes.length; i++) {
        console.log("nodes::"+i, nodes[i])
        const loopNode = nodes[i];
        // console.log("i=" + i + ": loopNode.id = " + loopNode._id);
        // console.log("i=" + i + ": nodeId = " + nodeId);
        if (loopNode._id === nodeId) {
          // console.log(
          //   "equals: loopNode.id=" + loopNode._id + ", nodeId =" + nodeId
          // );
          Object.assign(nodes[i], fieldValues)
          console.log("nodes[i]::result", nodes[i])
          // for (let key in fieldValues) {
          //   nodes[i][key] = fieldValues[key];
          // }
          // console.log("i=" + i + ": updated nodes: ", nodes)
          // console.log('i=' + i + ': vm.items: ', vm.items)
          if (typeof callback === "function") {
            callback(nodes);
          }
          result = true;
          break;
        } else {
          // console.log(
          //   "not equal: loopNode.id=" + loopNode._id + ", nodeId =" + nodeId
          // );
          if (loopNode.children && loopNode.children.length > 0) {
            result = vm.setFieldValues(loopNode.children, nodeId, fieldValues);
            if (result) break;
          }
        }
      }
      return result;
    },

    toggleChild(payload, reload = false) {
      console.log('toggleChild: payload: ', payload)
      const item = payload.item;
      const child = payload.child
      const state = payload.state;
      console.log('toggleChild :: payload: ', payload)
      console.log('toggleChild :: reload:  ' + (reload ? 'yes' : 'no'))

      // payload: {
      //    indices:
      //    item:
      //    state
      //    parent
      // }
      const vm = this;
      if (item) {
        vm.toggleNonTopLevel(item, state, reload, child);
      } else {
        // only "show" state available for topmost level
        vm.toggleTopLevel(state, reload, child);
      }
    },

    toggleTopLevel(state, reload, child) {
      alert("TOGGLE TOP LEVEL (pending)");
    },

    toggleNonTopLevel(item, state, reload = false, child) {
      const vm = this;
      console.log('toggleNonTopLevel :: item: ', item)
      console.log('toggleNonTopLevel :: state = ' + state)
      console.log('toggleNonTopLevel :: reload: ' + reload)
      
      if (state) {
          console.log('toggleNonTopLevel => getChldren 1')
        if (reload) {
          console.log('toggleNonTopLevel => getChldren 2')
          item.loading = true
          console.log('toggleNonTopLevel => getChldren 3')
          vm.refreshOpenChildren(item, child)
        }
      } else {
        console.log( 'state is false')
        // vm.itemsData[item._id]['open'] = false

        vm.setFieldValues(vm.items, item._id, {
          open: false,
          loading: false,
          children: []
        });
      }
      console.log('toggleNonTopLevel => getChldren 4')

      // var lastParentItems = null
      // var parentItems = vm.items
      // var loopItem = null
      // var index = -1
      // for (let i = 0; i < indices.length; i++) {
      //   index = indices[i]
      //   loopItem = parentItems[index]
      //   lastParentItems = parentItems
      //   parentItems = loopItem.children
      // }
      // if (loopItem && lastParentItems) {
      //   var open = true
      //   if (typeof loopItem.open === 'undefined') {
      //     console.log('loopItem.open is undefined')
      //   } else {
      //     if (!loopItem.open) {
      //       console.log('loopItem.open === false')
      //       open = false
      //     }
      //   }
      //   // console.log('after loop lastParentItems[index]: ' + JSON.stringify(lastParentItems[index]))
      //   vm.$set(lastParentItems[index], 'open', !open)
      //   // console.log('after loop lastParentItems[index]: ' + JSON.stringify(lastParentItems[index]))
      // }
    },

    refreshOpenChildren(item, childInfoInMem, recursive) {
      const vm = this

      if (typeof recursive === 'undefined') {
        recursive = false
      }

      // get level => get parentChildInfo
      const {level, node} =  vm.getItem(vm.items, item._id)
      const levelNo = level

      console.log('refreshOpenChildren : getItem: levelNo = ' + levelNo)
      console.log('refreshOpenChildren : getItem: node: ', node)

      const parent = node
      console.log('refreshOpenChildren : item: ', item)
      console.log('refreshOpenChildren : parent (open: ' + parent.open + '): ', parent)
      console.log('refreshOpenChildren : child exists: ' + (childInfoInMem ? 'yes' : 'no'))

      vm.getChildData(item, children => {

        console.log('refreshOpenChildren :: vm.getChildData .. levelNo = ' + levelNo)
        vm.tempOutputFormInfoCount()

        console.log('refreshOpenChildren :: vm.getChildData .. children.length = ' + children.length)
        // if (vm.levelType === 'multipleTables') {
        //   const parentChildInfo = vm.parentChildInfos[levelNo]

        //   console.log('refreshOpenChildren :: vm.getChildData .. parentChildInfo: ', parentChildInfo)
        //   const childValue = parent[parentChildInfo.fieldContainsChildRecordIds]
        //   var result = []
        //   var remaining = []
        //   for (let i = 0; i < childValue.length; i++) {
        //     const childId = childValue[i]
        //     const childIndex = children.findIndex(c => c._id === childId)
        //     if (childIndex >= 0) {
        //       result.push(children[childIndex])
        //       children.splice(childIndex, 1)
        //     }
        //   }
        //   result = result.concat(children)
        //   children = result
        // }

        // vm.setFieldValues(vm.items, parent._id, {
        //   children: children,
        //   loading: false,
        //   open: true
        // });
        // const {level, node} = vm.getItem(vm.items, parent._id) 
        // const parentNode = node
        if (parent.open) {
          console.log('after parentNode.open :: ' + (parent.open))
          parent.loading = false
        } else {
          let updatedChildren = []
          console.log('parentNode.open = false => children.length = ' + children.length)
          for (let j = 0; j < children.length; j++) {
            const loopChild = children[j]

            console.log('refreshOpenChildren: childInfoInMem._id = ' + (childInfoInMem ? childInfoInMem._id : 'null'))
            console.log('refreshOpenChildren: loopChild._id = ' + loopChild._id)
            if (childInfoInMem && (childInfoInMem._id === loopChild._id)) {
              updatedChildren.push(Object.assign(childInfoInMem, loopChild))
            } else {
              const memChild = parent['children'].find(c => c._id === loopChild._id)
              console.log('j=' + j + ': loopChild: ', loopChild)
              console.log('j=' + j + ': memChild: ', memChild)

              if (memChild) {
                updatedChildren.push(Object.assign(memChild, loopChild))
              } else {
                updatedChildren.push(loopChild)
              }
            }
          }
          parent['children'] = updatedChildren
          parent.open = true
          parent.loading = false

          // Check if recursive and expand children
          console.log('refreshOpenChildren :: recursive = ' + (recursive ? 'yes' : 'no'))
          console.log('refreshOpenChildren :: updatedChildren.length: ', updatedChildren.length)
          if (recursive && updatedChildren && updatedChildren.length >0) {
            for (let k = 0; k < updatedChildren.length; k++) {
              const loopChild = updatedChildren[k]
              vm.refreshOpenChildren(loopChild, null, recursive)
            }
          }
        }
      });
    },

 
    addRootNode() {
      this.newNode(null);
      // const vm = this
      // vm.$refs.recordDialog.open({
      //   record: null,
      //   formInfo: vm.form,
      //   onSubmitted: vm.onSubmitted
      // });
      // alert("addRootNode");
    },

    onSubmitted(newRecord) {},

    appendParentChildWidgets(initViewCallback) {
      const vm = this;
      console.log("LevelDiagramView :: appendParentChildWidgets");
      vm.$store
        .dispatch("SET_FORM_BUFFER", { form: vm.$store.getters.currentForm })
        .then(() => {
          console.log(
            "LevelDiagramView :: appendParentChildWidgets SET_FORM_BUFFER.then"
          );
          const parentChildWidget = vm.getWidgetByKey("parentChildRelation");
          const lastRowIndex = vm.form.layout.rows.length - 1;
          const widgetProperties = vm.$store.getters.widgetProperties;
          console.log(
            "LevelDiagramView :: appendParentChildWidgets :: INSERT_LAYOUT_ITEM"
          );
          vm.$store
            .dispatch("INSERT_LAYOUT_ITEM", {
              type: "widget",
              data: parentChildWidget,
              index: lastRowIndex,
              widgetProperties: widgetProperties
            })
            .then(() => {
              vm.$store.dispatch("SAVE_FORM").then(response => {
                console.log(
                  "LevelDiagramView :: appendParentChildWidgets :: response: ",
                  response
                );
                if (typeof initViewCallback === "function") {
                  const linkedFields = vm.form.linkedFields;
                  if (linkedFields.length > 0) {
                    const parentFieldId = linkedFields[0].fieldIds[0];
                    const childFieldId = linkedFields[0].fieldIds[1];
                    var cardConfig = LEVEL_DIAGRAM_CARD_CONFIG;
                    cardConfig.cardImageFieldId = getFirstAttachmentsFieldId(
                      vm.form.fieldInfos
                    );
                    initViewCallback("currentTable", {
                      parentChildFieldIdPair: {
                        parentFieldId: parentFieldId,
                        childFieldId: childFieldId,
                        cardConfig: cardConfig
                      }
                    });
                  }
                }
              });
              console.log("INSERT_LAYOUT_ITEM.then");
            });
          console.log("vm.fieldInfos: ", vm.fieldInfos);
        });
    },

    updateFormView(levelType, payload) {
      const vm = this;
      const view = JSON.parse(JSON.stringify(vm.view));
      var data = {
        levelType: levelType
      };

      console.log("updateFormView before check levelType: data: ", data);
      switch (levelType) {
        case "currentTable":
          data["parentChildFieldIdPair"] = payload.parentChildFieldIdPair;
          break;
        case "multipleTables":
          data["parentChildFieldFormList"] = payload.parentChildFieldFormList;
          break;
      }

      // console.log("before dispatch: data: ", data);
      vm.$store
        .dispatch("UPDATE_FORM_VIEW", {
          id: vm.form._id,
          viewId: view._id,
          data: data
        })
        .then(() => {
          // console.log('=> checkFieldValidity')
          vm.checkFieldValidity(() => {
            vm.refresh();
          });
        });
      console.log("levelType = " + levelType + ", payload: ", payload);
    },

    onConfirmHandler(payload) {
      const vm = this;
      console.log("onConfirmHandler ::payload: ", payload);
      switch (payload.levelType) {
        case "currentTable":
          if (!payload.parentChildFieldIdPair) {
            // if auto-create parent-child is selected
            vm.appendParentChildWidgets(vm.updateFormView);
          } else {
            vm.updateFormView("currentTable", {
              parentChildFieldIdPair: payload.parentChildFieldIdPair
            });
          }
          console.log(
            "currentTable: vm.parentChildFieldIdPair: ",
            payload.parentChildFieldIdPair
          );
          break;
        case "multipleTables":
          vm.updateFormView("multipleTables", {
            parentChildFieldFormList: payload.parentChildFieldFormList
          });
          // vm.$store
          //   .dispatch("INIT_LEVEL_DIAGRAM_VIEW", {
          //     viewId: vm.view._id,
          //     levelType: payload.levelType,
          //     parentChildFieldFormList: payload.parentChildFieldFormList
          //   })
          //   .then(() => {
          //     vm.refresh();
          //   });
          console.log(
            "multipleTables: vm.parentChildFieldFormList: ",
            payload.parentChildFieldFormList
          );
          break;
      }
    },
    onPanEnd() {
      const vm = this;
      if (vm.resetStatus) {
        vm.panZoom.moveTo(vm.START_X, vm.START_Y);
        vm.panZoom.zoomAbs(0, 0, 1);
        vm.resetStatus = false;
      }
      console.log("onPanEnd");
      if (vm.panZoom) {
        console.log("onPanEnd :: setxy");
        const transform = vm.panZoom.getTransform();
        vm.x = transform.x;
        vm.y = transform.y;
      }
    },
    onZoom(panzoomInst) {
      const vm = this;
      const transform = panzoomInst.getTransform();
      console.log("onZoom :: transform.scale = " + transform.scale);
      vm.currentZoom = Math.floor(transform.scale * 100, 0);
    },
    onZoomEnd(panzoomInst) {
      const transform = panzoomInst.getTransform();
      console.log("onZoomEjnd :: transform.scale = " + transform.scale);
    },
    pausePanZoom() {
      // const vm = this;
      // const panZoomInst = vm.$refs.panZoom.$panZoomInstance;
      // // console.log('LevelDiagramView :: pausePanZoom :: panZoomInst: ', panZoomInst)
      // panZoomInst.pause();
      // vm.isPanZoomPaused = vm.$refs.panZoom.$panZoomInstance.isPaused();
    },
    resumePanZoom() {
      const vm = this;
      const panZoomInst = vm.$refs.panZoom.$panZoomInstance;
      // console.log('LevelDiagramView :: resumePanZoom :: panZoomInst: ', panZoomInst)
      panZoomInst.resume();
      vm.isPanZoomPaused = vm.$refs.panZoom.$panZoomInstance.isPaused();
    },
    detachFromPanZoom() {
      const vm = this;
      const panZoomInst = vm.$refs.panZoom.$panZoomInstance;
      panZoomInst.pause();
      panZoomInst.resume();
      console.log("detachFromPanZoom");
    },

    resetPosition() {
      const vm = this;

      vm.$refs.contentScrollor.scrollTop = 0;
      vm.$refs.contentScrollor.scrollLeft = 0;

      // used for pan-zoom plugin
      // vm.resetPanZoom();
      // console.log("resetPosition :: setTimeout");
      // vm.$forceUpdate();
    },
    resetPanZoom() {
      const vm = this;
      // console.log("resetPanZoom");
      // this.onInit(this.$refs.panZoom.$panZoomInstance)
      // const panzoomInst = this.$refs.panZoom.$panZoomInstance

      // const result = vm.panZoom.moveTo(0,0)
      // console.log('resetPanZoom :: result: ', result)
      // vm.panZoom.zoomAbs(0,0,1)
      vm.panZoom.moveTo(vm.START_X, vm.START_Y);
      vm.panZoom.zoomAbs(0, 0, 1);

      const x = vm.panZoom.getTransform().x;
      const y = vm.panZoom.getTransform().y;
      // console.log("resetPanZoom x = " + x);
      // console.log("resetPanZoom y = " + y);
      // console.log("isPause = " + (vm.panZoom.isPaused() ? "yes" : "no"));
      if (x > 0.1 || y > 0.1) {
        vm.resetStatus = true;
        console.log(
          "x or 7 > 0.1 => resetStatus = " + (vm.resetStatus ? "yes" : "no")
        );
      } else {
        console.log("x and y < 0.1");
      }
      vm.x = vm.START_X;
      vm.y = vm.START_Y;
      // vm.onPanEnd()
      // setTimeout(() => {
      //   panzoomInst.smoothMoveTo(0, 10)
      //   panzoomInst.smoothMoveTo(0, 10)
      // }, 1000)
    },

    onInit(panzoomInst, id) {
      const vm = this;
      // console.log("onInit");

      vm.setZoom(1, panzoomInst, vm.START_X, vm.START_Y); // panzoomInst.initialX = 0

      if (vm.$refs.panZoom) {
        console.log("onInit :: vm.$refs.panZoom defined");
        vm.panZoom = vm.$refs.panZoom.$panZoomInstance;
      } else {
        console.log("onInit :: vm.$refs.panZoom not defined");
      }

      // panzoomInst.initialY = 0

      // panzoomInst.applyTransform(transform)
      // panzoomInst.zoom(1, {animate: true})

      // vm.$forceUpdate()
      //     .on('')
      // this.$refs.panZoom
    },
    setZoom(zoomLevel, panzoomInst, startX, startY) {
      const vm = this;
      if (typeof panzoomInst === "undefined") {
        panzoomInst = vm.$refs.panZoom.$panZoomInstance;
      }
      const transform = panzoomInst.getTransform();
      if (typeof startX === "undefined") {
        startX = transform.x;
      }
      if (typeof startY === "undefined") {
        startY = transform.y;
      }
      // console.log("move to x=" + startX);
      // console.log("move to y=" + startY);
      // console.log("panzoomInst.smoothMoveTo(" + startX + "," + startY + ")");
      panzoomInst.smoothMoveTo(startX, startY);

      transform.scale = zoomLevel;
      vm.currentZoom = Math.floor(zoomLevel * 100, 0);

      // panzoomInst.smoothMoveTo(startX, startY)
    },

    attachToParent(newParent, index, item) {
      console.log('attachToParent', {
        newParent, index, item
      })
      const vm = this;
      // relation ids are stored as array (for easy single/multiple conversion)
      // i.e.
      // for relation to single record: one element in array:
      // [{recordId}]
      // for relation to multiple records: multiple elements in array:
      // [{recordId1},{recordId2},....]
      
      // console.log('attachToParent :: newParent: ', newParent)
      // console.log('attachToParent :: index= '+index)
      // console.log('attachToParent :: item: ', item)

      const parentIds = item[vm.parentFieldId];
      const oldParentIds = Array.isArray(parentIds) ? parentIds : [];
      var newParentIds = [];
      // console.log("attachToParent :: oldParentIds: ", oldParentIds);
      switch (vm.levelType) {
        case "currentTable":
          if (newParent) {
            newParentIds = [newParent._id];
          }
          console.log('setChildParentField', {
            item, newParentIds
          })
          console.log('setOldParentChildField', {
            oldParentIds, item
          })
          console.log('addToParent', {
            newParent, index, item
          })
          vm.setChildParentField(item, newParentIds);
          vm.setOldParentChildField(oldParentIds, item);

          vm.addToParent(newParent, index, item, saveResult => {
            console.log('attachToParent :: addToParent :: newParent: ', newParent)
            console.log('attachToParent :: addToParent :: callback()')

            let force = newParent[vm.childFieldId].length > 1 && !newParent.open
            vm.toggleChild({
              item: newParent,
              child: item,
              state: true
            }, force)
            // !newParent.open /* reload */);
          });

          break;
        case "multipleTables":          
          // if (newParent !== null) {
          //   newParent = oldParentIds.concat(newParent);
          // }

          if (newParent) {
            newParentIds = [newParent._id]
          }
          vm.addToParentChildField(newParent, item._id)
          vm.setChildParentField(item, newParentIds);

          // vm.addToParent(newParent, index, item, saveResult => {
          //   console.log('attachToParent :: addToParent :: newParent: ', newParent)
          //   console.log('attachToParent :: addToParent :: callback()')
          //   vm.toggleChild({
          //     item: newParent,
          //     state: true
          //   }, !newParent.open /* reload */);
          // });
          break;
      }
      // Move from top to child
      //    set child parent
      //    add to parent
      //
      // Move from top to top
      //
      // Move from child to top
      //    set child parent => null
      //    remove from parent
      //
      // Move from child to child
      //    set child parent
      //    add to parent
      //    remove from parent
      //

      // add only child id to parent's child field,
      // UI auto-update by plugin

      // console.log('attachToParent :: setOldParentChildField  item: ' + JSON.stringify(item))
      // console.log('attachToParent :: setOldParentChildField  oldParentId = ' + JSON.stringify(oldParentId))


      // vm.addToParent(newParent, index, item, saveResult => {
      //   console.log('attachToParent :: addToParent :: newParent: ', newParent)
      //   console.log('attachToParent :: addToParent :: callback()')
      //   vm.toggleChild({
      //     item: newParent,
      //     state: true
      //   }, !newParent.open /* reload */);
      // });

      // expand parent

      // vm.updateChildParent(item._id, {
      //   parent: newParentId
      // })
      // // update old parent children
      // vm.getChildData(oldParentId, (children) => {
      //   vm.setFieldValues(vm.items, oldParentId, {
      //     open: true,
      //     children: children
      //   })
      // })

      // // update new parent children
      // vm.getChildData(newParentId, (children) => {
      //   vm.setFieldValues(vm.items, newParentId, {
      //     open: true,
      //     children: children
      //   })
      // })
    },

    saveNode(item, isNew, callback) {
      const vm = this;
      console.log("saveNode: item: ", item);
      const updated = JSON.parse(JSON.stringify(item));
      delete updated.children;
      delete updated.loading;
      delete updated.open;
      const postData = {
        data: updated,
        isNew: isNew
      }
      vm.$store.dispatch("SAVE_DATA", postData).then(response => {
        console.log("saveNode: ok");
        if (typeof callback === "function") {
          console.log("saveNode => callback");
          callback(response);
        }
      }).catch(error=>{
        this.$toast.error(this.$t("errors.save"))
      });
    },

    // for levelType=mutliple reords
    getParentChildInfo (item) {
      const vm = this
      // console.log('getParentChildInfo: item: ', item)
      const {level, node} = vm.getItem(vm.items, item._id)
      // console.log('getParentChildInfo: level = ' + level)
      // console.log('getParentChildInfo: node: ', node)
      // console.log('getParentChildInfo: vm.parentChildInfos: ', vm.parentChildInfos)

      return vm.parentChildInfos[level]
    },

    addChildrenToParent(parentId, children, childFieldId) {
      console.log('addChildrenToParent',{
        parentId, children, childFieldId
      })
      const vm = this
      const {level, node} = vm.getItem(vm.items, parentId)
      const parent = node

      // get existing children ids
      const existingChildIds = parent[childFieldId] ? parent[childFieldId] : []

      // extract new children
      const newChildren = children.filter(child => !existingChildIds.includes(child._id))

      for (let i = 0; i < newChildren.length; i++) {
        const loopChild = newChildren[i]
        vm.addToParentChildField(parent, loopChild._id, false)        
        newChildren[i].children = []
        newChildren[i].open = false
        newChildren[i].loading = false
      }
      // console.log('addChildrenToParent: parent.children: ', parent.children)
      // console.log('addChildrenToParent: newChildren: ', newChildren)

      parent.children = parent.children.concat(newChildren)
    },

    // for levelType=multipleTables
    addToParentChildField (parent, childId, needSaveToDb=true) {
      const vm = this
      const parentChildInfo = vm.getParentChildInfo(parent)
      const childFieldId = parentChildInfo.fieldContainsChildRecordIds
      // console.log('addToParentChildField :: childFieldid = ' + childFieldId)
      // console.log('addToParentChildField :: parent: ', parent)

      if (parent[childFieldId]) {
        if (!parent[childFieldId].includes(childId)) {
          parent[childFieldId].push(childId)
        }
      } else {
        vm.$set(parent, childFieldId, [childId])
      }
      if (needSaveToDb) {
        vm.saveNode(parent, UPDATE, () => {
          vm.refreshOpenChildren(parent)
        })
      }
    },

    setChildParentField(item, parentIds) {
      const vm = this;
      // console.log("setChildParentField :: parentIds: ", parentIds);
      console.log("setChildParentField",{
        item, parentIds
      })
      var parentFieldId = ''
      var childFieldId = ''

      // console.log('setChildParentField :: vm.levelType = ' + vm.levelType)
      switch (vm.levelType) {
        case 'currentTable':
          parentFieldId = vm.parentFieldId
          childFieldId = vm.childFieldId
          break
        case 'multipleTables':
          // console.log('setChildParentField :: item: ', item)
          const parentChildInfo = vm.getParentChildInfo(item)
          parentFieldId = parentChildInfo.fieldContainsParentRecordIds            
          childFieldId = parentChildInfo.fieldContainsChildRecordIds            
          break
      }
      // console.log('setChildParentField :: parentFieldId = ' + parentFieldId)
      // console.log('setChildParentField :: childFieldId = ' + childFieldId)
      // console.log("setChildParentField :: parentFieldId = " + vm.parentFieldId);
      // console.log(
      //   "setChildParentField :: item[vm.parentFieldId]: " +
      //     JSON.stringify(item[vm.parentFieldId])
      // );
        
      console.log("setChildParentField::parentChildId",{
        'parentFieldId': parentFieldId,
        'childFieldId' : childFieldId
      })
      if (parentFieldId !== '') {
        if (parentIds.length > 0) {
          const parentId = parentIds[0]._id;
          // console.log("setChildParentField :: parentId = " + parentId);
          item[parentFieldId] = parentIds;
        } else {
          item[parentFieldId] = [];
        }
        
        console.log("setChildParentField::saveNode::item", item)
        vm.saveNode(item, UPDATE, response => {
          // console.log("setChildParentField :: SAVE_NODE :: response: ", response);
        });
      }
    },

    addToParent(parent, index, item, callback) {
      const vm = this;
      const parentId = parent ? parent._id : null;
      var parentFieldId = ''
      var childFieldId = ''

      switch (vm.levelType) {
        case 'currentTable':
          parentFieldId = vm.parentFieldId
          childFieldId = vm.childFieldId
          break
        case 'multipleTables':
          const parentChildInfo = vm.getParentChildInfo(parent)
          parentFieldId = parentChildInfo.fieldContainsParentRecordIds
          childFieldId = parentChildInfo.fieldContainsChildRecordIds
          break          
      }
      // console.log('addToParent :: parent: ', parent)
      // console.log('addToParent :: index =' + index)
      // console.log('addToParent :: item: ', item)
      if (parent) {
        if (parent[childFieldId] && Array.isArray(parent[childFieldId])) {
          if (!parent[childFieldId].includes(item._id)) {
            parent[childFieldId].splice(index, 0, item._id);
          }
        } else {
          parent[childFieldId] = [item._id];
        }
        console.log('addToParent :: saveNode', parent)
        vm.saveNode(parent, UPDATE, response => {
          if (typeof callback === "function") {
            callback(response);
          }
        });
        vm.attachToParentInItemList(parent, item, index);
      } else {
        // console.log('addToParent :: attachToParentInItemList')
        vm.attachToParentInItemList(null, item, index);
        // vm.items.splice(index, 0, item)
      }
    },

    // removeChildren (parent, itemId) {
    //   const vm = this
    //   if (parent) {
    //     const childIndex = parent.children.findIndex(child => child._id === itemId)
    //     if (childIndex >= 0) {
    //       parent.children.splice(childIndex, 1)
    //       alert('children removed from parent')
    //     }
    //   } else {

    //   }
    // },

    setOldParentChildField(parentIds, item) {
      console.log("setOldParentChildField", {
        parentIds, item
      })
      const vm = this;
      console.log("setOldParentChildField :: parentIds: ", parentIds);
      switch (vm.levelType) {
        case "currentTable":
          //***************
          // currentTable
          // each node can belongs to only one parent
          //***************

          console.log('setOldParentChildField :: parentIds.legth = ' + parentIds.length)
          if (parentIds.length > 0) {
            console.log("setOldParentChildField: parentIds: ", parentIds);
            const {level, node} = vm.getItem(vm.items, parentIds[0]);
            const parent = node
            const itemIndex = parent[vm.childFieldId].findIndex(
              a => a === item._id
            );
            console.log('setOldParentChildField :: parent[vm.childFieldId]: ', parent[vm.childFieldId])
            console.log('setOldParentChildField :: search itemIndex = ' + itemIndex)
            if (itemIndex >= 0) {
              parent[vm.childFieldId].splice(itemIndex, 1);
              // vm.detachFromParentChildrenList(parent, item);
            }

            // update db
            vm.saveNode(parent, UPDATE, response => {
              // console.log(
              //   "setOldParentChildField :: SAVE_DATA : response: ",
              //   response
              // );
            });
          } else {
            // console.log("setOldParentChildField :: parentIds.length === 0");
            // vm.detachFromParentChildrenList(null, item);
            // const itemIndex = vm.items.findIndex(i => i._id === item._id)

            // if (itemIndex >= 0) {
            //   vm.items.splice(itemIndex, 1)
            //   alert('removed from root list')
            // }
          }
          break;
        case "multipleTables":
          break;
      }
    },

    // updateChildParent(itemId, content) {
    //   const vm = this;
    //   const oldParentId = content.parent;
    //   const newParentId = content.parent;

    //   const itemIndex = vm.data.findIndex(item => item._id === itemId);
    //   if (itemIndex >= 0) {
    //     for (let key in content) {
    //       vm.data[itemIndex][key] = content[key];
    //     }
    //   }

    //   vm.updateParent(oldParentId);
    //   vm.updateParent(newParentId);
    // },

    // updateParent(parentId) {
    //   const vm = this;
    //   console.log("updateParent :: parentId = " + parentId);

    //   var parentItemIndex = vm.data.findIndex(item => item._id === parentId);
    //   console.log("updateParent :: vm.data: ", vm.data);
    //   console.log("updateParent :: parentItemIndex = " + parentItemIndex);
    //   const child = vm.data.find(item => item.parent === parentId);
    //   const childIds = child.map(item => item._id);

    //   if (parentItemIndex >= 0) {
    //     vm.data[parentItemIndex]["childrenIds"] = childIds;
    //   }
    // },

    detachFromParent(parent, item) {
      const vm = this
      // console.log('detachFromParent :: parent: ', parent)
      // console.log('detachFromParent :: item: ', item)
      switch (vm.levelType) {
        case 'currentTable':
          break
        case 'multipleTables':
          const parentChildInfo = vm.getParentChildInfo(parent)
          const childFieldId = parentChildInfo.fieldContainsChildRecordIds
          // console.log('childFieldId = ' + childFieldId)
          const childIndex = parent[childFieldId].indexOf(item._id)
          // console.log('detachFromParent :: childIndex = ' + childIndex)
          if (childIndex >= 0) {
            parent[childFieldId].splice(childIndex, 1)
            vm.saveNode(parent, UPDATE)
          }
          break
      }
    },

    checkBlankInputExists(parentId) {
      const vm = this;
      // console.log("clearOtherBlankNodesExcept :: parentId = " + parentId);
      return vm.doCheckBlankInputExists(vm.items, parentId);
    },
    doCheckBlankInputExists(nodes, parentId) {
      const vm = this;
      // console.log('doClearAllBlankNodes : nodes: ', nodes)
      // console.log('doClearAllBlankNodes : parentId = ' + parentId)
      var result = false;
      for (let i = 0; i < nodes.length; i++) {
        const blankNodeIndex = nodes[i].children.findIndex(
          child => child._id === ""
        );
        // console.log('i=' + i + ': blankNodeIndex = ' + blankNodeIndex)
        if (blankNodeIndex >= 0) {
          if (nodes[i]._id !== parentId) {
            // if not the node concerned,
            // go ahead to remove it.
            nodes[i].children.splice(blankNodeIndex, 1);
            // console.log('removed: nodes[i].children: ', nodes[i].children)
            break;
          } else {
            // if found, mark it.
            result = true;
          }
        } else {
          // console.log("no blank => go deeper nodes[i]: ", nodes[i]);
          result =
            result || vm.doCheckBlankInputExists(nodes[i].children, parentId);
        }
      }
      return result;
    },
    focusOnUserInput() {
      this.$nextTick(() => {
        this.$refs.childBlockList.setFocus("userInput");
      });
    },

    checkAndRemoveFromTopList(item) {
      console.log('checkAndRemoveFromTopList', item)
      // alert('checkAndRemoveFromTopList')
      const vm = this
      for (let i = 0; i < vm.items.length; i++) {
        if (vm.items[i] === item) {
          vm.items.splice(i, 1)
          break
        }
      }
    },

    onCommandHandler(payload) {
      const vm = this;
      var parentNode = null
      // console.log("LevelDiagramView.onCommandHandler :: payload: ", payload);
      // console.log(
      //   "LevelDiagramView.onCommandHandler :: payload.command = " +
      //     payload.command
      // );

      // Drag between nodes
      // => attach event
      // => detachFromParent event
      switch (payload.command) {
        case "unlink": // not drag & drop action => no auto update of UI
          // alert('onCommandHandler :: unlink')
          vm.unlinkItem(payload.parent, payload.item);
          vm.detachFromParentChildrenList(payload.parent, payload.item)
          break;
        case "attach": 
          // alert('onCommandHandler :: attach')
          // console.log('LevelDiagramView :: onCommandHandler :: payload.parent.children.length = ' + payload.parent.children.length)
          vm.attachToParent(payload.parent, payload.index, payload.item);
          if (payload.parent !== null) {
            vm.checkAndRemoveFromTopList(payload.item) // check and remove from top list
          }
          break;
        case "detachFromParent":
          // alert('onCommandHandler :: detachFromParent')
          vm.detachFromParent(payload.parent, payload.item);
          break;
        case "movePosition":
          vm.movePosition(
            payload.item,
            payload.parent,
            payload.level,
            payload.oldIndex,
            payload.newIndex);
          break;
        // case "xxxxcheckSubmit":
        //   // console.log("checkSubmit vm.titleFieldId = " + vm.titleFieldId);
        //   // User press <enter> in user input
        //   const newItem = payload.item;
        //   const continueInput = payload.continueInput;
        //   const newRecord = newBlankRecord(vm.fieldInfos);

        //   newRecord[vm.titleFieldId] = newItem.userInput;

        //   // console.log("checkSubmit newRecord: ", newRecord);
        //   // console.log("checkSubmit vm.activeFormId = " + vm.activeFormId);
        //   switch (vm.levelType) {
        //     case "currentTable":
        //       newRecord[vm.parentFieldId] = newItem.parentId;
        //       newRecord[vm.childFieldId] = [];
        //       newRecord["formId"] = newItem.formId;
        //       break;
        //     case "multipleTables":
        //       newRecord["formId"] = newItem.formId;
        //       break;
        //   }

        //   vm.saveNode(newRecord, savedChildRecord => {
        //     vm.showSuccess("messages.savedSuccessfully");
        //     const {level, node} = vm.getItem(vm.items, newItem.parentId);
        //     const parent = node
        //     const newChildId = savedChildRecord._id;

        //     // console.log("saveNode:callback parent: ", parent._id);
        //     // console.log("saveNode:callback newChildId = " + newChildId);
        //     // console.log(
        //     //   "saveNode:callback vm.childFieldId = " + vm.childFieldId
        //     // );

        //     parent[vm.childFieldId].push(newChildId);
        //     vm.saveNode(parent);

        //     //savedChildRecord["open"] = false;
        //     savedChildRecord['loading'] = false
        //     // savedChildRecord["children"] = [];
        //     parent.children[parent.children.length - 1] = savedChildRecord;

        //     if (continueInput) vm.newNode(parent);
        //   });
        //   break;
        case "addChildNode":
          vm.newNode(payload);
          break;
        case "selectChildNode":
          vm.selectNode(payload)
           break
        case "deleteNode":
          vm.deleteNode(payload.node, payload.index);
          console.log(
            "LevelDiagramView :: onCommandHandler :: deleteNode: payload: ",
            payload
          );
          break;
        case "onDragAbandoned":
          vm.pausePanZoom();
          break;
        case "onDraggingItemStarted":
          vm.dragging = true;
          // console.log('onDraggingItemStarted')
          // vm.pausePanZoom()
          // vm.detachFromPanZoom()
          break;
        case "onDraggingItemEnded":
          vm.dragging = false;
          // console.log('onDraggingItemEnded')
          // vm.pausePanZoom()
          // vm.detachFromPanZoom()
          vm.pausePanZoom();
          break;
        case "pausePanZoom":
          vm.pausePanZoom();
          break;
        case "resumePanZoom":
          vm.resumePanZoom();
          break;
      }
    },

    cancelInputByParentId (parentId) {
      const vm = this
      // console.log('cancelInputByParentId :: parentId = ' + parentId)
      var parent = null
      if (parentId != '') {
        const {level, node} = vm.getItem(vm.items, parentId);
        parent = node
      }
 
      // console.log('cancelInputByParentId :: parent: ', parent)
      if (parent) {
        // console.log('cancelInputByParentId :: parent.children.length 1 = ' + parent.children.length)
        parent.children.pop();
        // console.log('cancelInputByParentId :: parent.children.length 2 = ' + parent.children.length)
      } else {
        vm.items.pop();
      }
    },

    onViewRecordHandler (payload) {
      const vm = this
      console.log('LevelDiagramView :: onViewRecordHandler :: payload: ', payload)
      vm.loading = true
      vm.activeRecord = payload.record
      const formInfo = vm.formInfoDict[vm.activeRecord.formId]

      // console.log('onViewRecordHandler :: vm.activeRecord._id = ' + vm.activeRecord._id)
      // console.log('onViewRecordHandler :: formInfo: ', formInfo)
      // console.log('onViewRecordHandler :: vm.onRecordSubmitted: ', vm.onRecordSubmitted)

      vm.$refs.popupRecordDialog.open({
        recordId: vm.activeRecord._id,
        formInfo: formInfo,
        onSubmitted: vm.onRecordSubmitted,
        mode: 'edit'
      })
    },

    onRecordSubmitted (payload) {
      const vm = this
      // console.log('onRecordsubmitted>: payload: ', payload)
      Object.assign(vm.activeRecord, payload)
      vm.activeRecord = null
    },

    onSelectRecordHandler (payload) {
      const vm = this
      vm.$refs.selectRelatedRecordDialog.open(
        {
          value: vm.inputValue,
          dataSource: vm.dataSource,
          formInfo: vm.relatedFormInfo,
          selectionSettings: tableSelectionSettings,
          multiple: vm.multiple,
          exclusion: vm.extraExclusion
        },
        vm.saveValueHandler
      )
    },

    saveValueHandler (selection, callbackId) {
      const vm = this
      // console.log('saveValueHandler :: callbackId: ', callbackId)
      const parentId = callbackId
      const {level, node} = vm.getItem(vm.items, parentId)
      const parent = node
      const parentChildInfo = vm.parentChildInfos[level]
      const fieldId = parentChildInfo.fieldContainsChildRecordIds

      parent[fieldId] = parent[fieldId].concat(selection)
      vm.saveNode(parent, UPDATE, () => {
        vm.refreshOpenChildren(parent)
      })
    },

    onCancelHandler (payload) {
      this.cancelInputByParentId(payload.item.parentId)
    },

    onSubmitHandler(payload) {
      const vm = this;

      console.log("LevelDiagramView :: onSubmitHandler :: payload: ", payload);
      // User press <enter> in user input
      const newItem = payload.item;

      console.log('onSubmitHandler :: newItem: ', newItem)
      if (newItem.userInput === "") {
        vm.cancelInputByParentId(newItem.parentId)
        return;
      }
      vm.saving = true
      console.log('onSubmitHandler continueInput')
      
      const continueInput = payload.continueInput;

      const parentId = newItem.parentId
      const formId = newItem.formId

      let newRecord = null
      if (vm.isMultiple) {
        // newRecord
        const formInfo = vm.getFormInfo(formId)
        const titleFieldId = getTitleFieldId(formInfo)
        newRecord = vm.newBlankRecordFromFormId(formId)
        newRecord["formId"] = formId;
        newRecord[titleFieldId] = newItem.userInput
      } else {
        console.log("LevelDiagramView :: onSubmitHanlder :; vm.titleFieldId = " + vm.titleFieldId);

        newRecord = newBlankRecord(vm.fieldInfos);
        newRecord["formId"] = formId;
        newRecord[vm.titleFieldId] = newItem.userInput;

        console.log('checkSubmit vm.titleFieldId = ' + vm.titleFieldId)
        console.log("checkSubmit newRecord: ", newRecord);
        console.log('onSubmitHandler :: newRecord: ', newRecord) 

        newRecord[vm.parentFieldId] = parentId?[parentId]:[]
        newRecord[vm.childFieldId] = [];
      }
      console.log('onSubmitHandler :: saveNode')      
      vm.saveNode(newRecord, INSERT, savedChildRecord => {
        vm.showSuccess("messages.savedSuccessfully");
        console.log('saveNode.then callback savedChildRecord = ' + JSON.stringify(savedChildRecord))
        var parent = null
        var parentAtLevel = 0
        if (newItem.parentId !== "") {
          const {level, node} = vm.getItem(vm.items, newItem.parentId);
          parent = node
          parentAtLevel = level
        }
        const newChildId = savedChildRecord._id;

        // console.log(
        //   "saveNode:callback parent: ",
        //   parent ? parent._id : "(no parent)"
        // );
        // console.log("saveNode:callback newChildId = " + newChildId);
        // console.log("saveNode:callback vm.childFieldId = " + vm.childFieldId);

        var parentChildInfo = null
        var currentChildCount = 0
        if (parent) {
          if (vm.isMultiple) {
            parentChildInfo = vm.parentChildInfos[parentAtLevel]
            const fieldId = parentChildInfo.fieldContainsChildRecordIds
            console.log('LevelDiagramView : onSubmitHandler : fieldId = ' + fieldId)
            console.log('LevelDiagramView : onSubmitHandler : parent[fieldId]: ', parent[fieldId])
            console.log('LevelDiagramView : onSubmitHandler : parent: ', parent)
            if (parent[fieldId]) {             
              parent[fieldId].push(newChildId)
            } else {
              parent[fieldId] = [newChildId]
            }
            // console.log('LevelDiagramView : onSubmitHandler : after update to parent data: parent[fieldId]: ', parent[fieldId])
            currentChildCount = parent[fieldId]

          } else {
            // console.log('LevelDiagramView : onSubmitHandler : (childFieldId=' + vm.childFieldId + ') parent = ' + JSON.stringify(parent))
            parent[vm.childFieldId].push(newChildId);
            currentChildCount = parent[vm.childFieldId].length
          }
          vm.saveNode(parent, UPDATE, () => {
            vm.saving = false
          });
        } else {
          vm.saving = false
        }
        // else {
        //   vm.items.push(Object.assign(savedChildRecord, {
        //     open: false,
        //     children: [],
        //     loading: false
        //   }))
        // }

        //        savedChildRecord["open"] = false;
        console.log('onSubmitHandler :: savedChildRecord._id = ' + savedChildRecord._id)

        savedChildRecord = Object.assign(savedChildRecord, {
          open: false,
          children: [],
          loading: false
        })

        // update children
        if (parent) {
          console.log('saveNode :: update parent node children parent.children.length = ' + parent.children.length)
          // non-top level
          parent.children[parent.children.length - 1] = Object.assign(
            parent.children[parent.children.length - 1],
            savedChildRecord
          )
        } else {
          // top level
          Object.assign(
            vm.items[vm.items.length - 1],
            savedChildRecord
          )
        }

        if (typeof payload.callback === 'function') {
          payload.callback()
        }
        console.log('onSubmitHandler >> saveNode.then continueInput: ' + continueInput)
        if (continueInput) {
          if (parent) {
            if (vm.isMultiple) {
              const parentInfo = {
                parentNode: parent,
                index: currentChildCount,
                parentChildInfo: parentChildInfo,
              }              
              vm.newNode(parentInfo)
            } else {
              vm.newNode({
                parentNode: parent,
                index: currentChildCount,
                parentChildInfo: vm.parentChildInfos[0]
              })
            }
          } else {
            vm.newNode(null);
          }
        }
      })
    },

    findParentNode(nodes, nodeId, parentNode, callback) {
      const vm = this;
      var result = false;
      for (let i = 0; i < nodes.length; i++) {
        const loopNode = nodes[i];
        if (loopNode._id === nodeId) {
          if (typeof callback === "function") {
            callback(parentNode);
            result = true;
            break;
          }
        } else {
          result = vm.findParentNode(
            loopNode.children,
            nodeId,
            loopNode,
            callback
          );
          if (result) break;
        }
      }
      return result;
    },

    getItemParent(item) {
      const vm = this;
      var result = null;
      switch (vm.levelType) {
        case 'currentTable':
          const idArray = item[vm.parentFieldId];
          // console.log("getItemParent parent id array: ", idArray);
          if (idArray && idArray.length > 0) {
            const parentId = idArray[0];
            const {level, node} = vm.getItem(vm.items, parentId);
            result = node
          }
          // console.log("getItemParent result: ", result);
          break
        case 'multipleTables':

          break
      }
      return result;
    },

    detachFromParentChildrenList(parent, item) {
      console.log('detachFromParentChildrenList',{
        parent, item
      })
      const vm = this;


      if (parent) {
        // console.log(
        //   "detachFromParentChildrenList: parent.children(" +
        //     parent.children.length +
        //     "): ",
        //   parent.children
        // );
        // console.log("detachFromParentChildrenList: item._id = " + item._id);

        var filtered = [];
        for (let i = 0; i < parent.children.length; i++) {
          if (parent.children[i]._id !== item._id) {
            filtered.push(parent.children[i]);
          }
        }
        parent.children = filtered;
      } else {
        const index = vm.items.findIndex(i => i._id === item._id);
        // console.log("detachFromParentChildrenList :: index = " + index);

        if (index >= 0) {
          vm.items.splice(index, 1);
        }
      }
    },

    getTopMostNode (node, parentNode) {
      const vm = this
      var result = null
      let nodeList = []
      if (parentNode) {
        nodeList = parentNode.children
      } else {
        nodeList = vm.items
      }
      for (let i = 0; i < nodeList.length; i++) {
        const loopNode = nodeList[i]
        if (loopNode._id === node._id) {
          if (parentNode) {
            result = parentNode
          } else {
            result = loopNode
          }
          break
        } else {
          result = vm.getTopMostNode(node, loopNode)
          if (result) {
            if (parentNode) {
              result = parentNode
            }
            break
          }
        }
      }
      return result
    },

    attachAfterNodeInItemList(node, item) {
      const vm = this
      const nodeIndex = vm.items.findIndex(m => m._id === node._id)
      if (nodeIndex >= 0) {
        vm.items.splice(nodeIndex + 1, 0, item)
      } else {
        vm.items.push(item)
      }
    },

    attachToParentInItemList(parent, item, index) {
      const vm = this;
      // console.log("attachToParentInItemList: parent: ", parent);
      if (parent) {
        if (index) {
          parent.children.splice(index, 0, item);
        } else {
          parent.children.push(item);
        }
      } else {
        if (index) {
          vm.items.splice(index, 0, item);
        } else {
          vm.items.push(item);
        }
      }
    },

    movePosition(item, parent, level, oldIndex, newIndex) {
      const vm = this;
      // console.log('movePosition (levelType=' + vm.levelType + '): item: ', item);
      // console.log('movePosition level = ' + level)
      const itemId = item._id;
      // const parent = vm.getItemParent(item);
    
      // console.log('movePosition : childValue: ', childValue)      
      switch (vm.levelType) {
        case "currentTable":
          if (parent) {
            parent[vm.childFieldId].splice(oldIndex, 1);
            parent[vm.childFieldId].splice(newIndex, 0, itemId);

            parent.children.splice(oldIndex, 1);
            parent.children.splice(newIndex, 0, item);
          } else {
            // console.log('movePosition :: oldIndex = ' + oldIndex)
            // console.log('movePosition :: newIndex = ' + newIndex)

            vm.items.splice(oldIndex, 1);
            vm.items.splice(newIndex, 0, item);

            // if (oldIndex < newIndex) {
            //   vm.items.splice(newIndex + 1, 0, item)
            //   vm.items.splice(oldIndex, 1)
            // } else {
            //   vm.items.splice(newIndex, 0, item)
            //   vm.items.splice(oldIndex + 1, 1)
            // }
          }
          break;
        case 'multipleTables':
            const parentChildInfo = vm.parentChildInfos[level-1]
            const childFieldId = parentChildInfo.fieldContainsChildRecordIds
            const childValue = parent[childFieldId]
            if (parent) {
              parent[childFieldId].splice(oldIndex, 1);
              parent[childFieldId].splice(newIndex, 0, itemId);

              parent.children.splice(oldIndex, 1);
              parent.children.splice(newIndex, 0, item);
            } else {
            // console.log('movePosition :: oldIndex = ' + oldIndex)
            // console.log('movePosition :: newIndex = ' + newIndex)
              vm.items.splice(oldIndex, 1);
              vm.items.splice(newIndex, 0, item);
            }
            // console.log('movePosition(multipleTable): parent: ', parent)
            break
      }
    },

    unlinkItem(parent, item) {
      const vm = this;
      switch (vm.levelType) {
        case "currentTable":
          // parent is original parent of item

          // var itemParent = vm.getItemParent(item);
          // console.log('unlinkItem : parent: ', parent)
          // console.log('unlinkItem : itemParent: ', itemParent)
          // if (parent._id !== itemParent._id) {
          //   alert('unlinkItem :: parent._id !== itemParent._id')
          //   parent = itemParent
          // }
          
          console.log("setChildParentField", {
            from: item,
            to: []
          })
          console.log("setOldParentChildField", {
            from: [parent._id],
            to: item
          })
          vm.setChildParentField(item, []);
          vm.setOldParentChildField([parent._id], item);
          // update ui element (.children)
          // vm.detachFromParentChildrenList(parent, item)
          // console.log(
          //   "000 vm.items[0].children.length = " + vm.items[0].children.length
          // );


          // vm.attachToParentInItemList(null, item);
          const topMostNode = vm.getTopMostNode(parent)
          vm.attachAfterNodeInItemList(topMostNode, item);

          // console.log(
          //   "001 vm.items[0].children.length = " + vm.items[0].children.length
          // );
          break
        case "multipleTables":
      // console.log('LevelDiagramView :: unlinkItem : vm.levelType = ' + vm.levelType)
      // console.log('LevelDiagramView :: unlinkItem : parent: ', parent)
      // console.log('LevelDiagramView :: unlinkItem : item: ', item)
          const parentChildInfo = vm.getParentChildInfo(parent)
          // console.log('unlinkItem : LevelDiagramView :: parentChildInfo: ', parentChildInfo)
          const childFieldId = parentChildInfo.fieldContainsChildRecordIds
          // console.log('unlinkItem : LevelDiagramView :: childFieldId = ' + childFieldId)
          const childIndex = parent[childFieldId].indexOf(item._id)
          if (childIndex >= 0) {
            parent[childFieldId].splice(childIndex, 1)
            vm.saveNode(parent, UPDATE, () => {
              vm.refreshOpenChildren(parent)
            })
          }
          break;
      }
    },

    deleteNode(node, indexInParent) {
      const vm = this;
      // console.log("deleteNode :: node: ", node);
      // console.log("deleteNode :: vm.childFieldId = " + vm.childFieldId);

      // delete data item
      // alert('node.formId = ' + node.formId)
      var childOfDeleted = node.children
      const formInfo = vm.formInfoDict[node.formId]
      const params = {
        appId: formInfo.appId,
        formId: formInfo._id,
        include: [node._id]
      };
      EventBus.$emit('showSpinner')
      vm.$store.dispatch("DELETE_FORM_RECORD", params).then(response => {        
        vm.findParentNode(vm.items, node._id, null, parent => {
          console.log('parent', parent)
          if (vm.isMultiple) {
            if (parent) {
              const parentChildInfo = vm.parentChildInfos.find(
                info => info.currentFormId === parent.formId
              );

              console.log('deleteNode: parent.children.length = ' + parent.children.length)
              console.log('deleteNode: vm.childFieldId = ' + vm.childFieldId)
              console.log('deleteNode: indexInParent = ' + indexInParent)


              console.log('deleteNode :: parentChildInfo.fieldContainChildRecordIds = ' + 
                parentChildInfo.fieldContainsChildRecordIds)

              console.log('deleteNode :: parent[fieldContainChildRecordIds] = ' +
                parent[parentChildInfo.fieldContainsChildRecordIds])

              parent.children.splice(indexInParent, 1)
              parent[parentChildInfo.fieldContainsChildRecordIds] =
                parent[parentChildInfo.fieldContainsChildRecordIds].filter(
                  id => id != node._id
                )
            }  else {
              const nodeIndex = vm.items.findIndex(item => item._id === node._id);
              if (nodeIndex >= 0) {
                vm.items.splice(nodeIndex, 1);
              }              
            } 
          } else {
            // remove from UI
            if (parent) {
              console.log("deleteNode: parent.children: " + JSON.stringify(parent.children));
              console.log('deleteNode: vm.childFieldId = ' + vm.childFieldId)
              console.log('deleteNode: indexInParent = ' + indexInParent)

              parent.children.splice(indexInParent, 1);
              parent[vm.childFieldId] = parent[vm.childFieldId].filter(
                id => id != node._id
              );
              
              // console.log("parent.children: " + JSON.stringify(parent.children));
            } else {
              // console.log("deleteNode :: vm.items: ", vm.items);
              // console.log("node._id = " + node._id);
              const nodeIndex = vm.items.findIndex(item => item._id === node._id);
              if (nodeIndex >= 0) {
                vm.items.splice(nodeIndex, 1);
              }
            }
          }
        });
        if(childOfDeleted.length){
          childOfDeleted.forEach(item=>{
            item[vm.parentFieldId] = []
            vm.items.push(item)
          })
        }
        EventBus.$emit('hideSpinner')
        vm.showSuccess("messages.savedSuccessfully");
      }).catch(err=>{
        this.$toast.error(this.$t("errors.deleteNode"))
        EventBus.$emit('hideSpinner')
      })
    },

    newNodeAtRootLevel() {
      const vm = this;
      // console.log("newNodeAtRootLevel");
      const newNodeExists = vm.items.findIndex(item => item._id === "") >= 0;
      if (!newNodeExists) {
        const blankItem = {
          _id: "",
          userInput: "",
          formId: vm.form._id,
          parentId: "",
          open:false,
          loading: false,
          children: []
        };
        vm.items.push(blankItem);
        vm.focusOnUserInput();
      } else {
        vm.focusOnUserInput();
      }
    },

    selectNode (payload) {
      const vm = this
      console.log('LevelDiagramView :: selectNode :: payload: ', payload)
      const parentNode = payload.parentNode
      const index = payload.index
      const parentChildInfo = payload.parentChildInfo
      const formId = parentChildInfo.currentFormId

      // Get formInfo
      const formInfo = vm.getFormInfo(formId)
      if (!formInfo) {
        console.log('Form "#' + formId + '" not in memory.')
        return
      }

      // Get fieldInfo
      const fieldId = parentChildInfo.fieldContainsChildRecordIds
      const fieldInfo = vm.getFieldInfo(fieldId, formId, formInfo)
      if (!fieldInfo) {
        return
      }

      // get related formInfo
      const ds = fieldInfo.properties.dataSource
      const relatedFormId = ds
      const relatedFormInfo = vm.formInfoDict[relatedFormId]
      if (!relatedFormInfo) {
        // console.log('Related Form #' + relatedformId + ' not in memory')
        return
      }
      // console.log('selectNode: fieldInfo: ', fieldInfo)

      // Popup dialog
      const selectionMode = fieldInfo.properties.selectionMode
      const selectionSettings = 
        selectionMode === 'table' ?
        fieldInfo.properties.tableSelectionSettings :
        fieldInfo.properties.cardSelectionSettings

      var payload = {
        dataSource: fieldInfo.properties.dataSource,
        formInfo: relatedFormInfo,
        selectionSettings: selectionSettings,
        multiple: fieldInfo.properties.relatedRecordQuantity,
        exclusion: parentNode[fieldId],

        callbackBundle: {
          parentChildInfo: parentChildInfo,
          recordId: parentNode._id,
          onSelected: null
        },

        checkDbForExclusion: true,
        parentRecordInfo: {
          formId: formId,
          fieldId: fieldId,
          recordId: parentNode._id,
        },
        parentFormId: parentChildInfo.currentFormId
        // callbackId: parentNode._id
      }
      console.log('selectPopup :: payload: ', payload)
      switch (selectionMode) {
        case 'table':
          vm.$refs.selectRelatedRecordsDialog.open(
            payload,
            vm.onSelectedHandler
          )
          break
        default:
          vm.$refs.selectRelatedCardsDialog.open(
            payload,
            vm.onSelectedHandler
          )
          break
      }
    },

    parseParentChildInfo (parentChildInfo) {
      const vm = this
      const formId = parentChildInfo.currentFormId
      const formInfo = vm.getFormInfo(formId)
      const fieldId = parentChildInfo.fieldContainsChildRecordIds
      const fieldInfo = vm.getFieldInfo(fieldId, formId, formInfo)
      const ds = fieldInfo.properties.dataSource
      return {
        formId: formId,
        formInfo: formInfo,
        appId: formInfo.appId,
        teamId: formInfo.teamId,
        fieldId: fieldId,
        fieldInfo: fieldInfo,
        dataSource: ds
      }
    },

    onSelectedHandler (newSelection, callbackBundle) {
      const vm = this
      console.log('onSelectedHandler :: newSelection: ', newSelection)
      console.log('onSelectedHandler :: newSelection: ', callbackBundle)
      
      var childFormId = callbackBundle.parentChildInfo.childFormId
      var currentFormId = callbackBundle.parentChildInfo.currentFormId
    
      if (!newSelection || newSelection.length === 0) return

      if(childFormId==currentFormId){
        var fieldContainsChildRecordIds = callbackBundle.parentChildInfo.fieldContainsChildRecordIds
        var fieldContainsParentRecordId = callbackBundle.parentChildInfo.fieldContainsParentRecordId
        // parentNode[fieldContainsChildRecordIds] = parentNode[fieldContainsChildRecordIds].concat(newSelection)
        if(newSelection){
          newSelection.forEach(selection=>{
            var parentNode = vm.getItem(vm.items, callbackBundle.recordId).node
            var node = vm.getItem(vm.items, selection).node
            var oldParentId = node[fieldContainsParentRecordId]

            if(oldParentId.length){
              var oldParent = vm.getItem(vm.items, oldParentId[0]).node
              vm.detachFromParentChildrenList(oldParent, node)
            }
            vm.attachToParent(parentNode, 0, node)
            vm.checkAndRemoveFromTopList(node)
          })
        }
        
        return
      }

      const parentChildInfo = callbackBundle.parentChildInfo
      const parentId = callbackBundle.recordId
      const {
        formInfo,
        dataSource
      } = vm.parseParentChildInfo(parentChildInfo)

      const payload = {
        appId: formInfo.appId,
        teamId: formInfo.teamId,
        formId: formInfo._id,
        recordId: parentId,
        dataSource: JSON.stringify(dataSource),
        relatedRecordIds: newSelection
      }
      vm.$store.dispatch('ATTACH_RELATED_RECORDS', payload).then(
        response => {
          const childFieldId =parentChildInfo.fieldContainsChildRecordIds
          console.log('ATTACH_RELATED_RECORDS: response: ', response)
          if (response.length > 0) {
            vm.addChildrenToParent(parentId, response, childFieldId)
          }
        }
      )
      // this.saveValueHandler(newSelection, this.onSaved)
    },
    // popupSelectRelatedTable(payload) {
    //   // payload = {
    //   //    value: Object, (optional)
    //   //    dataSource: Object,
    //   //    formInfo: Object,
    //   //    selectionSettings,
    //   //    multiple: boolean
    //   //    exclusion: [],
    //   //    recordId: String, (optional)
    //   //    checkDbForExclusion: Boolean (optional)
    //   // }
    //   const vm = this;

    //   // const tableSelectionSettings = getFieldPropertyValue(
    //   //   vm.fieldInfo,
    //   //   'tableSelectionSettings',
    //   //   null
    //   // )
    //   vm.$refs.selectRelatedRecordsDialog.open(
    //     payload, 
    //     vm.saveValueHandler
    //     // {
    //     //   dataSource: vm.dataSource,
    //     //   formInfo: vm.relatedFormInfo,
    //     //   selectionSettings: tableSelectionSettings,
    //     //   multiple: vm.multiple,
    //     //   exclusion: vm.extraExclusion
    //     // },
    //   )
    // },

    newBlankRecordFromFormId (formId) {
      const vm = this
      const formInfo = vm.getFormInfo(formId)
      const fieldInfos = formInfo.fieldInfos
      return newBlankRecord(fieldInfos)
    },

    newNode(payload) {
      /*
      payload = {
        parentChildInfo: payload.parentChildInfo,
        parentNode: node,
        index: index
      }
      */
      const vm = this;
      if (payload === null) {
        vm.newNodeAtRootLevel();
        return;
      }
      console.log('newNode :: payload: ', payload)
      const parentNode = payload.parentNode;
      const index = payload.index;
      const parentChildInfo = payload.parentChildInfo;

      // console.log("LevelDiagramView::newNode: parentNode: ", parentNode);

      // if clearOtherBlankNodesExcept returns true,
      // previous blank nodes not belongs to parentNode is cleared
      // go ahead to create new one
      // otherwise, skip
      if (!vm.checkBlankInputExists(parentNode._id)) {
        // console.log("LevelDiagramView :: newNode :: no blank input exists");
        const blankItem = {
          _id: "",
          userInput: "",
          formId: parentChildInfo.childFormId,
          parentId: parentNode._id,
          children: [],
          open: false,
          loading: false
        };
        // console.log("LevelDiagramView :: newNode :: blankItem: ", blankItem);
        if (parentNode.open) {
          // console.log("LevelDiagramView :: newNode :: parentNode.open");
          parentNode.children.push(blankItem);
          vm.focusOnUserInput();
        } else {
          // console.log("LevelDiagramView :: newNode :: not parentNode.open");
          // console.log("newNode => getchildren");
          vm.getChildData(parentNode, children => {
            parentNode.open = true;
            parentNode.loading = false
            this.$set(parentNode, "children", [...children, blankItem])
            // parentNode.children = [...children, blankItem];
            vm.focusOnUserInput();
          });
        }
      }
      console.log("addChildNode :: parentNode: ", parentNode);
    },
    showMediaInfo() {
      const vm = this;
      var mediaId = "603852b971ed2542782678f2";
      mediaId = "6038695928e262124ce06a68";
      const outputMethod = "download"; // or 'show'
      MediaHelper.getMediaAccess(mediaId).then(accessInfo => {
        // accessInfo = {
        //    _id: "603852b971ed2542782678f2",
        //    fileName: "20210226_014529_592486199.pdf",
        //    isSystem: true,
        //    isPublic: false,
        //    mediaType: "officeDocuments", // [officeDocuments | images]
        //    ext: ".pdf",
        //    accessId: "6038606fbad11d1ea0e753dd"

        if (accessInfo.mediaType === "officeDocuments") {
          // MediaHelper.output(accessInfo, outputMethod)
          // or
          // MediaHelper.show(accessInfo)
          // or
          // MediaHelper.download(accessInfo)
          // MediaHelper.download(accessInfo)
        } else {
          vm.imageUrls = [MediaHelper.getMediaAccessUrl(accessInfo)];
          vm.toggler = !vm.toggler;
        }
      });
    }
  }
};
</script>

<style>
/*.level-diagram-view {*/
/*font-size: 1vw;*/
/*}*/
/*.block-width: 280rem;*/

.level-diagram-view .header-wrapper {
  background-color: white;
  line-height: 1;
  padding: 8px 10px 5px 15px;
}

.level-diagram-view .header-wrapper .header-row {
  /* background-color: green;*/
  position: relative;
  overflow: hidden;
}

.level-diagram-view .header-wrapper .header-row .header-column .header-cell {
  border: 2px solid transparent;
  border-radius: 0.5rem;
  width: 288px;
  margin-right: 80px;
  display: inline-block;
  /*background-color:rgba(128,128,128,.1);*/
  background-color: rgba(200, 200, 200, 0.3);

  color: black;
}

.level-diagram-view .header-wrapper .header-row .header-column {
  width: auto;
  white-space: nowrap;
  position: absolute;
}

.level-diagram-canvas {
  /* overflow: hidden; */
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  height: 0;
}
/* .level-diagram-view .vue-pan-zoom-scene {
   height: 100%;
} */

.vue-pan-zoom-item {
  /*  overflow: hidden;*/
  height: 0;
  min-height: 100%;
}

.vue-pan-zoom-scene {
  border-color: transparent !important;
}

.vue-pan-zoom-scene .level-block-list {
  display: inline-block;
}

.vue-pan-zoom-scene > .level-block-list {
  display: block !important;
}

.vue-pan-zoom-scene:focus {
  outline: transparent auto 0px;
}

/*.reset-pan-zoom-button {*/
/*position: absolute;*/
/*top: 5px;*/
/*left: 5px;*/
/*}*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.ghost {
  background-color: transparent;
}

.level-diagram-view .ps__rail-x,
.level-diagram-view .ps__rail-y,
.level-diagram-view .ps-container > .ps-scrollbar-x-rail,
.level-diagram-view .ps-container > .ps-scrollbar-y-rail {
  background-color: yellow;
  opacity: 1 !important;
}

/* .pan-zoom-wrapper::-webkit-scrollbar {
   all: unset;
 }*/
.solid-scrollbar::-webkit-scrollbar {
  width: auto;
  /* width: auto; /* 12px !important;               /* width of the entire scrollbar */
}

.solid-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: auto;
}

/* Works on Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}*/

/* Works on Chrome, Edge, and Safari */
/**::-webkit-scrollbar {
  width: 12px;
}*/

.solid-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: auto;
  -webkit-box-shadow: auto;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.solid-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4c95ff;
  border-radius: 20px;
  border: 1px solid white;
}
</style>
