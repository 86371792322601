
/*
* Node identify lookup
* Terry Chan
* 12/05/2021
*/
const WorkflowNodeIdentify = {
    common: 'node', // regular node
    fork: 'group',  // fork to horizontal condition opertions
    condition: 'branch' // nodes under the forked branch
}

const WrokflowHookEvent = [
    'onCreate',
    'onUpdate',
    'onRemove',
    'onCreateOrUpdate',
];

const WorkflowTaskExecutionStatus = {
    running: 'running',
    wait: 'waiting',
    complete: 'complete',
    fail: 'fail'
}

export {
    WorkflowNodeIdentify,
    WrokflowHookEvent,
    WorkflowTaskExecutionStatus,
};
