<template>
<div>


  <div class="signature-wrapper" @click="showingDialog=true" :disabled="readonly">
    <div>
      <v-icon>mdi-pencil</v-icon> 
      {{ $t('general.clickToSignature') }}
    </div>
  </div>

  <v-overlay
    :absolute="false"
    :value="showingDialog"
    :id="attach"
  >
  </v-overlay>
  <v-dialog
      v-model="showingDialog"
      v-if="showingDialog"
      width="800"
      :attach="cssSelect?cssSelect:attach?`#${attach}`:undefined"
      hide-overlay
    >
    <v-card class="d-flex flex-column dialog-button-map vxe-table--ignore-clear" style="height:360px;">
      <dialog-header :label="$t('general.signature')" style="flex:0 1;"
                     @close="showingDialog=false"></dialog-header>
      <v-card-text class="flex-grow-1 d-flex flex-column pa-2">
        <div style="height:0;min-height:100%;position:relative;">
          <VueSignaturePad
              ref="signaturePad"
              width="100%"
              height="100%"
              :options="options"
          />
          <div style="position:absolute;top:5px;left:5px;">
            <v-btn fab x-small @click="undo" color="error" class="mr-2">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
            <v-btn fab x-small @click="clear" color="error">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <dialog-actions 
        @onCommand="onCommandHandler"
        :buttons="['cancel', 'ok']"
      />
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import dialogActions from '@/components/dialogs/comps/DialogActions'
import dialogHeader from '@/components/dialogs/comps/DialogHeader'
import mixin from '@/components/dialogButtons/mixin'
import MediaHelper from '@/helpers/MediaHelper'

export default {
  name: 'dialogButtonSignature',
  mixins: [mixin],
  components: {
    dialogActions,
    dialogHeader,
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    formInfo: {
      type: Object,
      default: ()=>({}),
    },
    attach: {
      type: String,
      default: undefined
    },
    readonly:{
      type: Boolean,
      default: false
    },
    cssSelect:{
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      showingDialog: false,
      userValue: '',
      options: {
        penColor: '#c0f'
      }
    }
  },
  mounted () {
  },
  watch: {
    showingDialog: function (newVal, oldVal) {
      this.$emit('updateShowingDialog', newVal)
      const vm = this

      if (newVal) {
        vm.resetSignature()
      }
    }
  },
  methods: {
    resetSignature () {
      const vm = this
      vm.$nextTick(() => {
        vm.$refs.signaturePad.resizeCanvas()
          vm.$refs.signaturePad.clearSignature()
      })
    },
    undo () {
      this.$refs.signaturePad.undoSignature()
    },
    clear () {
      this.$refs.signaturePad.clearSignature()
    },
    // updateValue (val) {
    //   const vm = this
    //   vm.userValue.pos = val.pos
    //   vm.userValue.zoom = val.zoom
    // },
    async onCommandHandler (payload) {
      const vm = this
      // console.log('onCommandHandler :: payload: ', JSON.stringify(payload))
      switch (payload.command) {
        case 'ok': {
          const {isEmpty, data} = vm.$refs.signaturePad.saveSignature()
          if (!isEmpty) {
            // const images = vm.$refs.signaturePad.toData()
            const { formInfo } = this;
            const { appId, _id } = formInfo;
            var file = this.dataURLtoFile(data, 'signature')

            const payload = {
              appId,
              formId: _id,
              files: [file],
              isPublic: this.$store.getters.isPublicRoute
            }
            MediaHelper.uploadFiles(payload, (res) => {
              console.log('DialogButtonSignature.onCommandHandler (ok): MediaHelper.uploadFiles.then: res: ', res);
              if(res.length){
                // var url  = vm.$store.getters.appHost + "/medias/" + res[0]._id + "/thumbnail"
                // vm.$emit('input', url)
                const newValue = {
                  mediaId: res[0]._id,
                  originalName: 'signature.' + res[0].mediaType.split('/')[1],
                  objectKey: res[0].objectKey,
                  thumbnailUrl: res[0].thumbnailUrl,
                  mediaType: res[0].mediaType
                }
                console.log('DialogButtonSignature: newValue: ', newValue)
                vm.$emit('input', newValue);
                vm.showingDialog = false 
              }
            })
          }
          break
        }
        case 'cancel':
          vm.showingDialog = false
          break
      }
    },
    dataURLtoFile(dataurl, baseName) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      const fileName = baseName + '.' + mime.split('/')[1]
      console.log('dataURLtoFile filename = ' + fileName);
      return new File([u8arr], fileName, {type:mime});
    }
  }
}
</script>

<style lang="scss">
  /*.dialogButtonMap {*/
  /*height: 90%;*/
  /*}*/
  .dialog-button-map {
    height: 480px;
  }

  .signature-wrapper {
    display: grid;
    place-items: center;
    border: 2px dashed rgb(92, 169, 166);
    padding: 1rem;
    cursor: pointer;
    height: clamp( 100px, 10vh , 20rem);
    font-size: 1.125rem;
    color: rgb(92, 169, 166);
    font-weight: bolder;
    transition: all 0.5s ease;
    border-radius: 4px;
    i {
      color: rgb(92, 169, 166) !important;
    }
    &:hover {
      background: rgb(92, 169, 166);
      color: #FFF;
      i {
        color: #FFFF !important;
      }
    }
  }
</style>

