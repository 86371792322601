<template>
  <div>
    <div class="d-flex flex-row align-center">
      <label>{{ label }}</label>
    </div>
    <div v-if="isParentChildRelation">
      <v-chip class="min-width-100" dark color="grey lighten-1">
        <v-icon class="mr-2">mdi-file-table</v-icon>
        <div class="d-flex flex-column line-height-1">
          {{ dataName }}
        </div>
      </v-chip>
    </div>
    <div v-else class="rounded-1 d-flex flex-row pa-1">
      <v-btn v-if="!dataSource" color="primary" small fab @click="edit">
        <v-icon>mdi-table-edit</v-icon>
      </v-btn>
      <div v-else-if="loading" style="min-height: 108px">
        <v-icon x-large>mdi-spin mdi-loading</v-icon>
      </div>
      <div
        v-else
        class="d-flex flex-column ml-1 data-source-wrapper"
        :class="{ 'is-invalid': !dataSource }"
      >
        <v-chip
          color="secondary"
          class="ml-0 mr-1 mb-1 min-width-100 team-chip"
        >
          <v-icon class="mr-2">mdi-account-group</v-icon>
          <div class="d-flex flex-column line-height-1 item-name">
            {{ teamName }}
            <small class="footnote">{{ $t("general.team") }}</small>
          </div>
        </v-chip>
        <v-chip color="info" class="ml-2 mr-1 mb-1 min-width-100 app-chip">
          <v-icon class="mr-2">mdi-seal</v-icon>
          <div class="d-flex flex-column line-height-1">
            {{ appName }}
            <small class="footnote">{{ $t("general.application") }}</small>
          </div>
        </v-chip>
        <v-chip
          color="orange darken-3"
          class="data-table-name ml-4 mr-1 mb-1 min-width-100 data-table-chip"
          @click="useDataTableName"
          dark
        >
          <v-icon class="mr-2">mdi-file-table</v-icon>
          <div class="d-flex flex-column line-height-1">
            {{ dataName }}
            <small class="footnote">{{ $t("general.dataTable") }}</small>
          </div>
        </v-chip>
      </div>
    </div>
    <data-source-dialog
      ref="dataSourceDialog"
      v-model="propertyValue"
    ></data-source-dialog>
  </div>
</template>

<script>
//**************************
// WitDataSource
//**************************
// Property Value:
// {
//    teamId:
//    teamName:
//    appId:
//    appName:
//    dataTableId:
//    dataTableName:
//    related
// }
//**************************
//
import mixin from "./mixin";
import dataSourceDialog from "@/components/dialogs/DataSourceDialog";
import { get } from "lodash";

export default {
  mixins: [mixin],
  components: {
    dataSourceDialog,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    teamName() {
      return get(this.dataSourceInfo, "team.name", "");
    },
    appName() {
      return get(this.dataSourceInfo, "app.title", "");
    },
    dataName() {
      return get(this.dataSourceInfo, "label", "???");
    },
    teamAppData() {
      return this.$refs.dataSourceDialog &&
        this.$refs.dataSourceDialog.teamAppData
        ? this.$refs.dataSourceDialog.teamAppData
        : [];
    },
    appId() {
      return this.$store.getters.currentApp._id;
    },
  },
  mounted() {
    this.getDataSourceInfos(this.value);
  },
  methods: {
    edit() {
      this.$refs.dataSourceDialog.open();
    },
    useDataTableName() {
      const vm = this;
      if (vm.dataSource) {
        vm.$emit("onCommand", {
          command: "setFieldProperty",
          fieldInfoId: vm.fieldInfo._id,
          propertyKey: "fieldName",
          propertyValue: vm.dataName,
        });
      }
    },
    updatePropertyValue(val) {
      this.$emit("input", val);
      this.getDataSourceInfos(val);
    },
    getDataSourceInfos(val) {
      this.loading = true;
      this.$store
        .dispatch("REFRESH_DATASOURCE_INFOS", {
          reloadAll: false,
          newDataSource: val,
          appId: this.appId,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  // watch: {
  //   value: {
  //     handler(newVal) {
  //       if (newVal) {
  //         this.loading = true;
  //         this.$store
  //           .dispatch("REFRESH_DATASOURCE_INFOS", { reloadAll: false, newDataSource: newVal, appId:this.appId})
  //           // .then(() => {
  //           //   if (this.fieldInfoId.startsWith("_")) {
  //           //     this.$store.dispatch("SET_FIELD_INFO_PROPERTY", {
  //           //       fieldInfoId: this.fieldInfoId,
  //           //       propertyKey: "fieldName",
  //           //       propertyValue:
  //           //         this.$t("widgets.relation") + ": " + this.dataName,
  //           //     });
  //           //   }
  //           //   this.loading = false;
  //           // });
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.data-table-name {
  cursor: pointer;
}
.footnote {
  color: lightgrey;
}

.data-source-wrapper.is-invalid {
  .team-chip,
  .app-chip,
  .data-table-chip {
    background-color: lightgrey !important;
  }
  .item-name,
  .footnote {
    color: white !important;
  }
}
</style>
>
