<template>
  <div>
    <FormElement
      :label="label"
      :name='name'
    >
      <ComboBox
        :value="field || null"
        :options="list"
        :placeholder="placeholder"
        valueFormat="object"
        @changed="changed"
        :onlyPrefix="false"
        :onlySuffix="true"
        :involveRelationshipLabel="true"
      />
    </FormElement>
  </div>
</template>

<script>
import Vue from 'vue';
import ComboBox from './../../../form/comboBox/';
import FormElement from './formElement';
import Mixin from './../mixin';
import { get } from 'lodash'; 
import {
  FORM_SELECTIONS,
  FORM_RELATIONSHIP_FIELDS,
  FETCH_WORKFLOW_DEPENDENCIES,
  FORM_RELATIONSHIP_SELECTIONS_FIELDS,
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';

// import {
//   map,
//   omit,
//   get,
// } from 'lodash';
export default {
  mixins: [Mixin],
  props: {
    field: String,
    placeholder: String,
    worksheet: String,
    dataSource: [String, Object],
    name: String,
    label: String,
  },
  computed: {
    list() {
      return this.$store.getters.getFormRelationshipFields.children || [];
    },
    isLoading() {
      return this.$store.getters.fetchingFormRelationshipFields;
    },
    fieldId() {
      const { field } = this;
      if (field) {
        return field.fieldId;
        // if (field.properties && field.properties.worksheet) {
        //   return field.properties.worksheet;
        // } else if (field.form) {
        //   return field.form
        // }
      }
      return null;
    }
  },
  methods: {
    fetch() {
      if (this.worksheet) {
        this.$store.dispatch(FORM_RELATIONSHIP_FIELDS, {
          appId: this.appId,
          formId: this.worksheet._id || this.worksheet,
        });
      }
    },
    fetchFields() {
      if (this.fieldId) {
        this.$store.dispatch(FORM_RELATIONSHIP_SELECTIONS_FIELDS, {
          appId: this.appId,
          fieldId: this.fieldId,
        });
      }
    },
    changed(v) {
      this.$store.dispatch(SET_SETTINGS, {
        relationshipField: v,
        hookFields: [],
        rules: [],
      });
    }
  },
  components: {
    ComboBox,
    FormElement,
  },
  created() {
    this.fetch();
    this.fetchFields();
  },
  watch: {
    field() {
      this.fetchFields();
    },
    dataSource: {
      handler(v, o) {
        const oldId = get(v, '_id', v);
        const newId = get(o, '_id', o);
        if (v && oldId !== newId) {
          this.fetch();
          this.fetchFields();
        }
        
      },
      deep: true,
    }
  }
};

</script>

<style scoped>
</style>
