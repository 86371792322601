<template>
    <div class="workflow-shortview-worksheet">
        <span>
          {{ 
            $t(`workflow.modules.form.label.${labelType}`, {
              label: name,
            })
          }}
        </span>
    </div>
</template>

<script>
import { get } from 'lodash';
/*
* Target Module Sub-Forms Copncrete View Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    node: Object,
    dataSourceMode: Boolean,
    labelType: {
      type: String,
      default: 'worksheetOrDataSrouce',
    },
  },
  computed: {
    worksheet() {
      const { properties } = this.node;
      return get(properties, 'worksheet.label');
    },
    sourceName() {
      const { properties } = this.node;
      return get(properties, 'dataSource.name', '') || get(properties, 'dataSource.label', '');
    },
    name() {
      if (this.dataSourceMode) {
        return this.sourceName;
      }
      return this.$t('workflow.modules.form.label.pureWorksheet', {
        label: this.worksheet,
      });
    }
  },
};

</script>

<style scoped>
    .w-pending-trigger-setup-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        height: 36px;
        text-align: center;
        border: 2px solid #2196f3;
        font-weight: 700;
        border-radius: 18px;
        margin: 6px auto;
        color: #2196f3;
        font-size: 14px;
        width: 200px;
        box-sizing: border-box;
        background: #e6f4ff;
        cursor: pointer;
    }
    .w-pending-trigger-setup-btn:hover {
        color: #fff;
        background: #2196f3;
    }

    .w-pending-trigger-setup-btn.blocked {
        cursor: default !important;
    }
    .w-pending-trigger-setup-btn.blocked:hover {
        color: #2196f3 !important;
        background: #e6f4ff !important;
    }
    .w-pending-trigger-setup-text {
        color: #2196f3;
        font-size: 13px;
    }
</style>
