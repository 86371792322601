<template>
  <div>
    <label>{{ label }}</label>
    <v-text-field
        v-if="this.propertyKey === 'decimals'"
        type="number"
        min="0"
        max="9"
        label=""
        v-model="propertyValue"
        @input="onInput"
        @keypress="isNumber"
        dense
        :hint="note"
        :persistent-hint="note"
        hide-details="auto"
        outlined/>
        <v-text-field v-else 
        type="number"
        min="0"
        label=""
        :value="propertyValue"
        @input="val => propertyValue = val"
        dense
        :hint="note"
        :persistent-hint="note"
        hide-details="auto"
        outlined/>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  methods: {
    updateValue (val) {
      const vm = this
      vm.$emit('input', val)
    },
    onInput(val){
      if (val < 0 || val > 9 || val.length > 1){
                this.$nextTick(() => {
          this.propertyValue = val[val.length-1]
        })
        
      } else {
        this.propertyValue = val
      }
      
    },
    isNumber(e){
      const charCode = e.charCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault();
      } else {
        return true;
      }
    }
  }
}
</script>
