<template>
  <GmapMap
      ref="mapRef"
      @zoom_changed="onMapZoomed"
      @click="onMapClicked"
      :center="mapCenter"
      :zoom="14"
      :options='{ fullscreenControl: false, mapTypeControl: false, streetViewControl:false }'
      map-type-id="roadmap"
      style="width: 100%; height: 100%"
    >
    <GmapMarker
        :position="pos"
        :clickable="true"
        :draggable="true"
    />
            <!-- @click="center=value.pos" -->
  </GmapMap>
</template>

<script>
import {gmapApi} from 'gmap-vue'
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // value: Object,
    bl_location_permission: Boolean,
    pos: Object
  },
  watch: {
    pos:{
       async handler(newVal, oldVal){
        this.getNearBy()
      },
      deep: true
    }
  },
  computed: {
    google: gmapApi,
    marker () {
      const vm = this
      var result = null
      if (vm.pos) {
        result = {
          location: new vm.google.maps.LatLng(vm.pos),
          weight: 100
        }
      }
      return result
    }
  },
  data () {
    return {
      addressDescription: '',
      DEFAULT_ZOOM: 14,
      HK_POS: {
        lat: 22.3193,
        lng: 114.1694
      },
      mapCenter: {
        lat: 22.3193,
        lng: 114.1694
      },
      position:{
        lat: 22.3193,
        lng: 114.1694
      }
    }
  },
  mounted () {
    const vm = this
    vm.panToTarget(vm.pos)
    this.getNearBy()
    // if(vm.value.hasOwnProperty('own')){
    //     if(vm.value.own.lat)
    //       vm.mapCenter = vm.value.own
    // }else{
    // vm.mapCenter.lat  = vm.value.pos.lat
    // vm.mapCenter.lng  = vm.value.pos.lng
    
    // vm.position = vm.pos
    // vm.position = vm.value.pos
    // var pos = JSON.parse(JSON.stringify(vm.value.pos))
    // vm.mapCenter = vm.pos
    // }
  },
  methods: {
    setMapCenter(pos){
      pos = JSON.parse(JSON.stringify(pos))
      let vm = this
      vm.mapCenter.lat  = pos.lat
      vm.mapCenter.lng  = pos.lng
    },
    panToTarget (pos) {
      const vm = this
      console.log('panToTarget :: pos: ', pos)
      if(vm.pos.hasOwnProperty('lat'))
        try {
          vm.$refs.mapRef.$mapPromise.then((map) => {
            map.panTo(pos)
          })
        } catch (err) {
          console.log('panToTarget :: err: ', err)
        }
    },
    onMapClicked (val) {
      const vm = this
      console.log('onMapClicked :: val.latLng: ', JSON.stringify(val.latLng))
      vm.pos.lat = val.latLng.lat()
      vm.pos.lng = val.latLng.lng()
      vm.$emit('updateSearch', '')
      const urlCommand = '/map/geocode/' + 
        vm.pos.lat + ',' + vm.pos.lng

      if (this.$route.name === 'PublicForm' || this.$route.name === 'PublicEdit'){
        this.axios.get(this.$store.getters.appHost + urlCommand).then(res => {
          if(res.data){
            const url = this.$store.getters.appHost + '/map/place/details/' + 
            res.data.results[0].place_id

            this.axios.get(url).then(res => {
              this.$emit('updateCurrLoc', res.data.result)
            })
          }
        })

      }else {
      const getParams = {
        urlCommand
      }

      vm.$store.dispatch('AUTH_GET', getParams)
        .then(response => {
          console.log(response)
          // vm.value.formatted_address = response.results[0].formatted_address
          // vm.value.place_id = response.results[0].place_id
          
          var getParams2 = {
            urlCommand: '/map/place/details/' + 
            response.results[0].place_id
          }
          vm.$store.dispatch('AUTH_GET', getParams2)
          .then(response => {
            console.log(response)
            this.$emit('updateCurrLoc', response.result)
          })

        })
        .catch(err => {
            console.log(err)
        })
      }

    },
    async getNearBy(){
      let vm = this
      if(!vm.pos.hasOwnProperty('lat')) return

      if (this.$route.name === 'PublicForm' || this.$route.name === 'PublicEdit'){
        const url = this.$store.getters.appHost + '/map/nearby/' + 
        vm.pos.lat + ',' + vm.pos.lng + '/' +
        "400"
        this.axios.get(url)
        .then(res => {
          if (res.data){
             this.$emit('updateNearByPlaces', res.data)
          }
        })
        .catch(err => {
          console.log(err)
        })
      }else {
      const getParams = {
        urlCommand: '/map/nearby/' + 
        vm.pos.lat + ',' + vm.pos.lng + '/' +
        "400"
      }
      await vm.$store.dispatch('AUTH_GET', getParams)
        .then(response => {
          // console.log('onMapClicked :: response: ', response)
          var res = response
          // res.pop()
          // res.shift()
          this.$emit('updateNearByPlaces', res)
        })
        .catch(err => {
          console.log(err)
      })
      }


    },
    onMapZoomed (val) {
      // const vm = this
      // vm.value.zoom = val
    },
    reset () {
      const vm = this
      vm.panToTarget(vm.pos)
    },
    resetMap (val) {
      const vm = this
      vm.panToTarget(val.pos)
    }
  }

}
</script>
