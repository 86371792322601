export const apps = {


  UPDATE_APP_PROPERTY: "UPDATE_APP_PROPERTY",
  DUPLICATING_APP: "DUPLICATING_APP",
  DUPLICATING_APP_SUCCESS: "DUPLICATING_APP_SUCCESS",
  ADD_APP_MODULE: "ADD_APP_MODULE",
  UPDATE_APP_MODULE: "UPDATE_APP_MODULE",
  REMOVE_APP_MODULE: "REMOVE_APP_MODULE",

  ADD_MENU_ITEM:"ADD_MENU_ITEM",
  UPDATE_MENU_ITEM:"UPDATE_MENU_ITEM",
  REMOVE_MENU_ITEM:"REMOVE_MENU_ITEM",

  SET_CURRENT_APP: "SET_CURRENT_APP",
  SET_CURRENT_MODULE_INDEX: "SET_CURRENT_MODULE_INDEX",
  SET_SELECTED_MENU_ITEM_ID: "SET_SELECTED_MENU_ITEM_ID",
  SET_CURRENT_EDITING_SECTION: "SET_CURRENT_EDITING_SECTION",

  RESET_APPS: "RESET_APPS",
};

export const childTable = {
  SET_TABLE_INFO: "SET_TABLE_INFO",
  RESET_CHILD_TABLE: "RESET_CHILD_TABLE",
};

export const common = {};

export const constants = {
  SET_CONSTANTS: "SET_CONSTANTS",
  RESET_CONSTANTS: "RESET_CONSTANTS",
};

export const data = {
  SET_RECORD_DATA : 'SET_RECORD_DATA',
  SET_RELATED_TABLE_DATA : 'SET_RELATED_TABLE_DATA',
  UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID : 'UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID',
  RESET_DATA:'RESET_DATA'
}

export const dataTable = {

}

export const filters = {

}

export const formConfig = {
  SET_DATA_SOURCE: 'SET_DATA_SOURCE',
  ADD_DATA_SOURCES : 'ADD_DATA_SOURCES',
  REMOVE_DATA_SOURCES: 'REMOVE_DATA_SOURCES',
  CLEAR_FORM_FIELD_INFOS: 'CLEAR_FORM_FIELD_INFOS',
  SET_FORM_FIELD_INFOS : 'SET_FORM_FIELD_INFOS',
  SET_FORM_CHILD_TABLE_INFOS : 'SET_FORM_CHILD_TABLE_INFOS',
  SET_FORM_UI_ELEMENT_INFOS : 'SET_FORM_UI_ELEMENT_INFOS',
  SET_FORM_LAYOUT : 'SET_FORM_LAYOUT',
  SET_FORM_OPTIONS: 'SET_FORM_OPTIONS',
  ADD_FORM_OPTIONS : 'ADD_FORM_OPTIONS',

  SET_SELECTED_FORM_FIELD_ID : 'SET_SELECTED_FORM_FIELD_ID',
  SET_EDITING_CHILD_TABLE_INFO_ID:'SET_EDITING_CHILD_TABLE_INFO',
  SET_EDITING_CHILD_TABLE_FIELD_INFO_ID:'SET_EDITING_CHILD_TABLE_FIELD_INFO_ID',

  SET_FORM_INFO_PROPERTY : 'SET_FORM_INFO_PROPERTY',
  SET_FIELD_INFO_PROPERTY : 'SET_FIELD_INFO_PROPERTY',
  SET_FORM_LAYOUT_PROPERTY: 'SET_FORM_LAYOUT_PROPERTY',
  CLEAR_FORM_DATASOURCES: 'CLEAR_FORM_DATASOURCES',

  ADD_FORM_INFO_LINKED_FIELDS: 'ADD_FORM_INFO_LINKED_FIELDS',
  REMOVE_FORM_INFO_LINKED_FIELDS: 'REMOVE_FORM_INFO_LINKED_FIELDS',

  ADD_CHILD_TABLE : 'ADD_CHILD_TABLE',
  ADD_CHILD_TABLE_FIELD_INFO:'ADD_CHILD_TABLE_FIELD_INFO',

  INSERT_WIDGET_TO_ROW: 'INSERT_WIDGET_TO_ROW',
  INSERT_LAYOUT_ITEM:'INSERT_LAYOUT_ITEM',
  REMOVE_LAYOUT_ITEM : 'REMOVE_LAYOUT_ITEM',
  REMOVE_LAYOUT_ROW : 'REMOVE_LAYOUT_ROW',
  REMOVE_FIELD : 'REMOVE_FIELD',
  REMOVE_OBSOLATE_FIELD_INFOS: "REMOVE_OBSOLATE_FIELD_INFOS",

  RESET_FORM_CONFIG: 'RESET_FORM_CONFIG',

  FIX_PAGING_TABLES: 'FIX_PAGING_TABLES',
  SWAP_PAGING_TABLES: 'SWAP_PAGING_TABLES',

  UPDATE_DATA_SOURCES: 'UPDATE_DATA_SOURCES'
  // ADJUST_WIDGET_WIDTH_THE_MOST: 'ADJUST_WIDGET_WIDTH_THE_MOST'
}

export const panels = {
  SET_CURRENT_PANEL: 'SET_CURRENT_PANEL',
  SET_CURRENT_PANEL_PROPERTY:  'SET_CURRENT_PANEL_PROPERTY',
  UPDATE_PANEL_CONFIG_CHANGED: 'UPDATE_PANEL_CONFIG_CHANGED'
}

export const inputPanels = {
  SET_CURRENT_INPUT_PANEL: 'SET_CURRENT_INPUT_PANEL',
  SET_CURRENT_INPUT_PANEL_PROPERTY: 'SET_CURRENT_INPUT_PANEL_PROPERTY',
  UPDATE_INPUT_PANEL_CONFIG_COAHNGED: 'UPDATE_INPUT_PANEL_CONFIG_CHANGED'
}

export const calendarPanels = {
  
}

export const forms = {
  SET_FORM_VIEW: 'SET_FORM_VIEW',
  ADD_FORM_VIEW: 'ADD_FORM_VIEW',
  REMOVE_FORM_VIEW: 'REMOVE_FORM_VIEW',
  REORDER_FORM_VIEW: 'REORDER_FORM_VIEW',
  SET_CURRENT_FORM: 'SET_CURRENT_FORM',
  SET_PRINT_TEMPLATE: 'SET_PRINT_TEMPLATE',
  SET_CURRENT_FORM_PROPERTY: 'SET_CURRENT_FORM_PROPERTY',
  SET_CURRENT_FORM_ICON: 'SET_CURRENT_FORM_ICON',
  ADD_RELATED_TABLE_INFO_PROPERTY: 'ADD_RELATED_TABLE_INFO_PROPERTY',
  SET_RELATED_TABLE_INFO:'SET_RELATED_TABLE_INFO',
  RESET_FORMS :'RESET_FORMS',
  CLEAR_FORM_FROM_MEMORY: 'CLEAR_FORM_FROM_MEMORY',
  SET_DISPLAY_RULE: 'SET_DISPLAY_RULE',
  APPEND_SELECTION_OPTIONS:'APPEND_SELECTION_OPTIONS'
}
