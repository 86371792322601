<template>
  <v-container fill-height>
    <v-row align="center" justify="end">
      <div>
        <v-text-field 
          prepend-inner-icon="mdi-magnify" 
          filled
          rounded 
          dense 
          class="w-table-search-input"
          :placeholder="$t('workflow.searchPlaceholder')"
          v-model="filterInfo.keyword"
        >
        </v-text-field>
      </div>
    </v-row>
  </v-container>
</template>

<script>

export default {
  inject: ['filterInfo'],
  props: {
    onChange: Function,
    value: String,
  },
  components: {
  },
};

</script>

<style>
  .w-table-search-input {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 36px;
    width: 208px;
  }
  .w-table-search-input .v-input__slot {
    background: #fff !important;
    padding: 0 10px 0 5px !important;
  }
  .w-table-search-input .v-input__prepend-inner {
    margin-top: 5px !important;
  }
  .w-table-search-input .v-input__icon {
    min-width: 15px;
    width: 15px;
  }
  .w-table-search-input .v-icon {
    font-size: 15px;
  }
  .w-table-search-input .v-text-field__slot > input[type='text'] {
    padding: 6px 0 2px;
    font-size: 13px;
  }
</style>
