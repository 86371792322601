import { APP_DRAWER_CONTENT } from "@/const/defaults";

export default {
  data() {
    return {
      menu: APP_DRAWER_CONTENT,
    };
  },
  methods: {
    goTo({ inApp, url, trackId }) {
      if (!url) return;
      else {
        if(trackId==="helpCenter"){
          const locale = this.$store.getters.locale;
          let langMaps = {
            'en': 'en',
            'zh-Hans':'zh-CN',
            'zh-Hant': 'zh-TW'
          }
          url = url.replace('en', langMaps[locale])
        }
        this.$sensors.track("plus_menu_click", {
          btn_name: trackId,
        });
        inApp
          ? this.$route.path !== url
            ? this.$router.push("/my_apps")
            : null
          : window.open(url, "_blank");
      }
    },
  },
};
