<template>
  <div class="input-checkbox-toggle">
    <input-checkbox-toggle-btn
      v-for="(option, index) in options"
      :key="index"
      :option="option"
      :checked="isOptionSelected(option)"
      :inputValues="inputValues(option)"
      :isVertical="isVertical"
      @onCommand="onCommandHandler"
      :propertyKey="propertyKey"
      >
    </input-checkbox-toggle-btn>
  </div>
</template>

<script>
import inputCheckboxToggleBtn from './InputCheckboxToggleBtn'

export default {
  components: {
    inputCheckboxToggleBtn
  },
  data () {
    return {
      userOptionValues: [],

      // userOptionInputs (deprecated)
      // it is used as extra input for each option value
      userOptionInputs: [],

      selectedOption: null
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    options: Array,
    value: String,
    attributes: Object,
    propertyKey:{
      type: String,
      default: undefined
    }
  },
  watch: {
    value: function (newValue) {
      const vm = this
      vm.parseValues(newValue)
    }
  },
  computed: {
    inputFields () {

      const vm = this
      var result = []
      if (vm.selectedOption && vm.selectedOption.inputFields.length >0) {
        for (let i = 0; i < vm.selectedOption.inputFields.length; i++) {
          const loopItem = vm.selectedOption.inputFields[i]
          result.push(vm.$t('widgets.properties.' + loopItem))
        }
      }
      return result
    },
    preparedOptions () {
      const vm = this
      var result = []
      // vm.inputFields = []
      for (var i = 0; i < vm.options.length; i++) {
        const loopOption = vm.options[i]
        const label = loopOption.labelTag ? vm.$t('widgets.properties.' + loopOption.labelTag) : loopOption.label
        const valueAndInputs = loopOption.value.split(':')
        result.push({label: label, value: valueAndInputs[0]})
        // vm.inputFields.push(valueAndInputs.length > 1 ? valueAndInputs[1].split('|') : [])
      }
      return result
    },
    isVertical () {
      const vm = this
      var result = false
      if (vm.attributes && vm.attributes['direction']) {
        result = vm.attributes['direction'] === 'vertical'
      }
      return result
    }
  },
  mounted()
  {
    const vm = this
    // console.log('InputCheckboxToggle :: mounted')
    vm.parseValues()
  },
  methods: {
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'updateUserOptionAndInput':
          const userValueIndex = vm.userOptionValues.indexOf(payload.optionValue)
          if (payload.checked) {
            if (userValueIndex === -1) {
              vm.userOptionValues.push(payload.optionValue)
              vm.userOptionInputs.push(payload.optionInputs)
            } else {
              vm.userOptionInputs[userValueIndex] = payload.optionInputs
            }
          } else {
            var canBeRemoved = true

            if (vm.attributes && vm.attributes.required &&
              vm.userOptionValues.length <= 1) {
              canBeRemoved = false
            }

            if (canBeRemoved) {
              vm.userOptionValues.splice(userValueIndex, 1)
              vm.userOptionInputs.splice(userValueIndex, 1)
            }
          }
          vm.updateFromUserInput()
          break
      }
    },
    updateFromUserInput () {
      const vm = this
      var result = []
      // console.log('InputcheckboxToggle :: updateFromUserInput :: vm.userOptionValues: ', vm.userOptionValues)
      // console.log('InputcheckboxToggle :: updateFromUserInput :: vm.userOptionInputs: ', vm.userOptionInputs)

      for (let i = 0; i < vm.userOptionValues.length; i++) {
        var optionInput = ''
        const userOptionInput = vm.userOptionInputs[i]
        if (userOptionInput instanceof Array) {
          optionInput = userOptionInput.join('|')
        } else {
          optionInput = userOptionInput
        }
        const resultValue = optionInput === '' ?
          vm.userOptionValues[i] :
          vm.userOptionValues[i] + ':' + optionInput

        result.push(resultValue)
        // console.log('i=' + i + ': result: ', result)
      }
      // console.log('InputcheckboxToggle :: result: ', result)
      // console.log('InputcheckboxToggle :: updateFromUserInput :: vm.userOptionValues: ', vm.userOptionValues)
      // console.log('InputcheckboxToggle :: updateFromUserInput :: vm.userOptionInputs: ', vm.userOptionInputs)
      vm.$emit('input', result.join('||'))
    },
    getBlankInputValues (option) {
      const vm = this
      var result = []
      if (option.inputFields && option.inputFields.length > 0) {
        for (let i = 0; i < option.inputFields.length; i++) {
          result.push('')
        }
      }
      return result
    },
    inputValues (option) {
      const vm = this
      var result = vm.getBlankInputValues(option)

      // console.log('inputValues :: option: ', option)
      if (vm.isOptionSelected(option)) {
        // console.log('inputValues :: isOptionSelected === true')
        // console.log('inputValues :: userOptionValues: ', vm.userOptionValues)
        // console.log('inputValues :: userOptionInputs: ', vm.userOptionInputs)
        const userValueIndex = vm.getUserValueIndex(option)
        const userOptionInputs = vm.userOptionInputs[userValueIndex]
        // console.log('inputValues :: userValueIndex = ' + userValueIndex)
        for (let i = 0; i < userOptionInputs.length; i++) {
          result[i] = userOptionInputs[i]
        }
        // console.log('inputValues :: result: ', result)
      } else {
        // console.log('inputValues :: isOptionSelected not true')
      }
      return result
    },
    getUserValueIndex (option) {
      const vm = this
      // console.log('getUserValueIndex :: vm.userOptionValues: ', vm.userOptionValues)
      // console.log('getUserValueIndex :: option: ', option)
      return vm.userOptionValues.findIndex(item => item === option.value)
    },
    isOptionSelected (option) {
      // option = {
      //    labelTag: 'required',
      //    value: 'required',
      //    _id: 'sdflksdjdsfsd'
      // }
      const vm = this
      return vm.userOptionValues.indexOf(option.value) >= 0
    },
    // getOptionValue (option) {
    //   console.log('InputCheckboxToggle :: getOptionValue : option: ', option)
    //   const optionAndInputFields = option.value
    //   return optionAndInputFields[0]
    // },
    updateUserInput (index, val) {
      const vm = this
      const inputlen = vm.inputFields.length
    },
    parseValues(val)
    {
      const vm = this
      vm.userOptionValues = []
      vm.userOptionInputs = []

      if (typeof val === 'undefined') {
        val = vm.value
      }
      // console.log('parseValues :: val: ', val)

      if (val) {
        // console.log('InputCheckboxToggle :: updateValues :: val: ', val)
        // console.log('InputCheckboxToggle :: val = ' + val)
        var result = val === '' ? [] : val.split('||')
        // console.log('InputCheckboxToggle :: updateValues :: result: ', result)
        for (var i = 0; i < result.length; i++) {
          // console.log('InputCheckboxToggle :: updateValues :: i=' + i + ':')
          const segs = result[i].split(':')
          // console.log('InputCheckboxToggle :: updateValues :: segs: ', segs)
          vm.userOptionValues.push(segs[0])
          // console.log('InputCheckboxToglge :: updateValues :: vm.userOptionValues: ', vm.userOptionValues)
          var values = []
          if ((segs.length) > 1 && (segs[1].length > 0)) {
            values = segs[1].split('|')
          }
          // console.log('InputCheckboxToglge :: updateValues :: values: ', values)
          // console.log('InputCheckboxToggle :: updateValues :: segs: ', segs)
          // console.log('InputCheckboxToggle :: updateValues :: values.length = ' + values.length)
          vm.userOptionInputs.push(values)
          // console.log('InputCheckboxToggle :: updateValues :: vm.userOptionInputs: ', vm.userOptionInputs)
        }
        // console.log('InputCheckboxToggle :: vm.userOptionValues: ', vm.userOptionValues)
        // console.log('InputCheckboxToggle :: vm.userOptionInputs: ', vm.userOptionInputs)
      }


      // if (vm.values.toString() !== result.toString()) {
      //   vm.values = result
      // }
    }
    // ,

    // optionLabel (option) {
    //   const vm = this
    //   var result = option.label
    //   // console.log('inputRadioToggle :: optionLabel :: option: ', option)
    //   if (option.labelTag) {
    //     result = vm.$t('widgets.properties.' + option.labelTag)
    //   }
    //   return result
    // },
    // onClick(option)
    // {
    //   // e.g.
    //   // userValues = ['required', 'optionCountLimited']
    //   //
    //   const vm = this
    //   vm.selectedOption = vm.options.find(item => item.value === option.value)
    //   const idx = vm.userOptionValues.indexOf(option.value)
    //   if (idx >= 0) {
    //     // remove
    //     vm.userOptionValues.splice(idx, 1)
    //   } else {
    //     // add
    //     var optionValue = option.value
    //     console.log('onClick :: vm.selectedOption: ', vm.selectedOption)
    //     const inputFieldLength = vm.selectedOption.inputFields.length
    //     if (inputFieldLength > 0) {
    //       var inputs = []
    //       for (let i = 0; i < inputFieldLength; i++) {
    //         inputs.push('')
    //       }
    //       optionValue += ':' + inputs.join('|')
    //     }
    //     vm.userOptionValues.push(optionValue)
    //   }
    //   vm.$emit('input', vm.userOptionValues.join('||'))
    // }
  }
}
</script>

<style>
.input-checkbox-toggle button {
  height: 24px !important;
  border-radius: 0;
  margin: 1px;
  margin-right: 2px;
  margin-bottom: 2px;
}

/*.input-checkbox-toggle button:first-child {*/
  /*margin-right: 1px;*/
/*}*/

/*.input-checkbox-toggle button:last-child {*/
  /*margin-left: 1px;*/
/*}*/

</style>
