<template>
    <div style="height:100%;">
        <div v-if="styleSetupToggles.bl_displayTotal && selectedChart!='TABLE' && total!=null" class="pt-2 pl-5 d-flex align-center" style="height: 26px; position: absolute">
            <span style="font-size: 14px">{{styleSetups.total.totalTitle}}:</span>
            <b style="font-size: 20px" class="mb-1 ml-2">{{total}}</b>
        </div>
        <div v-show="selectedChart!='TABLE'" :id="divId" style="height:100%;" class="">
            <div v-if="dataSetups && (!dataSetups.arrX.length || !dataSetups.arrY.length)" style="font-size:16px; position: absolute; top:50%; left: 27%;">{{$t('chart.dragToAdd')}}</div>
        </div>
        <div v-if="selectedChart=='TABLE'" style="height:100%; width:100%">
            <ChartTable
                style="height:100%"
                :bl_fetching="bl_fetching"
                :dataSetups="dataSetups"
                :titleTree="titleTree"
                :tableData="tableData"
                :mergeCells="mergeCells"
                :tableFooters="styleSetups.summary.tableFooters"
                :bl_summary="styleSetupToggles.bl_summary"
                class="flex-grow-1"
                :chartId="chartId"
                :appId="appId"
                :formId="formId"
                :chartName="chartName"
            />
        </div>
    </div>
</template>
<script>
    import * as echarts from 'echarts';
    import {FetchAxisData} from '@/helpers/ChartHelper'
    import updateMixin from '../updateMixin'
    import dataConfigMixin from '../dataConfigMixin'
    import styleConfigMixin from '../styleConfigMixin'
    import ChartTable from './ChartTable'
    import { range } from 'lodash'
    import uuid from 'uuid';
    export default {
        mixins: [updateMixin, dataConfigMixin, styleConfigMixin],
        props: {
            dataSetups: Object,
            sourceSetups: Object,
            styleSetupToggles: Object,
            styleSetups: Object,
            selectedChart: String,
            tab: String,
            bl_fullScreen: Boolean,
            hookId: String,
            chartId: String,
            appId:{
                type: String,
                default: null
            },
            formId: {
                type: String,
                default: null
            },
            chartName:{
                type: String,
                default: null
            },
            extraDateFilter: Object
        },
        components:{
            ChartTable
        },
        mounted(){
            this.FetchData()
        },
        beforeDestroy(){
            let vm = this
            if(vm.chart)
                vm.chart.clear()
            if(vm.chartDom)
                echarts.dispose(vm.chartDom)
            window.removeEventListener('resize', vm.resize)
        },
        data(){
            return{
                instanceId:uuid.v4(),
                bl_fetching: false,
                chart: null,
                chartDom: null,
                option: null,
                drag: false,
                direction: '',
                lastY: 0,
                relatedTableInfos: [],
                fieldInfos: [],
                tableData: [],
                titleTree: [],
                mergeCells: [],
                isMounted: false
            }
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "charts",
                    disabled: false,
                    ghostClass: "chartdrawer-ghost"
                };
            },
            total(){
                if(!this.isMounted) return null
                let total = 0
                let vm = this
                if(vm.dataSetups.dataY.length){
                    if(vm.styleSetups.total.displayCol.type == "all"){
                        vm.dataSetups.dataY.forEach(item=>{
                            total+= item.reduce((a,b) => {
                                return (typeof b == 'object')? a+b.value: a+b
                            }, 0)
                        })
                    }else{
                        let pos 
                        if(['childTable', 'relatedRecord'].includes(vm.styleSetups.total.displayCol.type)){
                            pos = vm.dataSetups.arrY.findIndex(item=>
                                (item._id == vm.styleSetups.total.displayCol._id && 
                                item.relatedId == vm.styleSetups.total.displayCol.relatedId)
                            )
                        }else{
                            pos = vm.dataSetups.arrY.findIndex(item=>item._id == vm.styleSetups.total.displayCol._id)
                        }
                        if(pos==-1) return '-'
                        switch(vm.styleSetups.total.totalRule){
                            case 'sum':
                                total = vm.dataSetups.dataY[pos].reduce((a,b) => {
                                    return (typeof b == 'object')? a+b.value: a+b
                                }, 0)
                            break
                            case 'max':{
                                let maxData = vm.dataSetups.dataY[pos].map(item=>(typeof item == 'object')?item.value:item)
                                total = Math.max(...maxData)
                            }
                            break
                            case 'min': {
                                let minData = vm.dataSetups.dataY[pos].map(item=>(typeof item == 'object')?item.value:item)
                                total = Math.min(...minData)
                            }
                            break
                            case 'avg': {
                                let avgData = vm.dataSetups.dataY[pos].map(item=>(typeof item == 'object')?item.value:item)
                                total = avgData.reduce((a,b) => a + b, 0) / avgData.length
                            }
                            break
                        }
                    }
                    
                    if(vm.styleSetups.total.displayCol.type=='amount')
                        return new Intl.NumberFormat().format(total)
                    else if (
                        vm.styleSetups.total.displayCol._id == 'all' && 
                        vm.dataSetups.arrY[0].type=='amount'
                    ) {
                        return new Intl.NumberFormat().format(total)
                    }
                    return total.toFixed(2)
                    
                } else return null;
            },
            divId(){
                return `chart-${this.chartId}-${this.instanceId}`
            }
        },
        methods:{
            onCommandHandler(payload){
                this[payload.command](payload.value)
            },
            resize(){
                this.chart.resize()
            },
            async FetchData(){
                let vm = this
                if(vm.selectedChart!="TABLE"){
                    if(!vm.dataSetups.arrX.length || (!vm.dataSetups.arrY.length && !vm.dataSetups.arr2Y.length)) {
                        if(vm.chart)
                            vm.chart.clear()
                        return
                    }
                }else if(!vm.dataSetups.arrG.length || (!vm.dataSetups.arrX.length && !vm.dataSetups.arrY.length)) {
                    return
                }
                
                let xFields = vm.dataSetups.arrX
                let yFields = vm.dataSetups.arrY
                let y2Fields = vm.dataSetups.arr2Y
                let gFields = vm.dataSetups.arrG
            
                vm.bl_fetching = true
                
                const {x, y, g, y2} = await FetchAxisData({
                    selected:{
                        x: xFields,
                        y: yFields,
                        y2: y2Fields,
                        g: gFields
                    },
                    dateFilter: {
                        field: vm.sourceSetups.selectedDateField,
                        range: vm.sourceSetups.dateOptVal
                    },
                    dataFilter: vm.dataSetups.filters,
                    view: vm.sourceSetups.selectedView,
                    chartType: vm.selectedChart,
                    formId: vm.formId||vm.$route.params.itemId,
                    appId: vm.appId||vm.$route.params.id,
                    extraDateFilter: vm.extraDateFilter,
                    chartId: vm.chartId
                })

                this.setDataXY(x, y, g, y2);

                vm.bl_fetching = false
            },
            setDataXY(x, y, g, y2){
                if (this.selectedChart == 'LINE'){
                  let sortedIndices = range(x.length)
                    .sort((a, b) => x[a] < x[b] ? -1 : Number(x[a] > x[b]))
                  x = sortedIndices.map(i => x[i])
                  y = y.map(ys => sortedIndices.map(i => ys[i]))
                }
                let vm = this
                this.resetOption()
                this.$set(this.dataSetups, 'dataX', x)
                this.$set(this.dataSetups, 'dataY', y)
                this.$set(this.dataSetups, 'data2Y', y2)
                this.$set(this.dataSetups, 'dataG', g)
                this.isMounted = true

                this.selectedHidden = []

                if(this.selectedChart == 'TABLE'){
                    this.titleTree = y
                    this.tableData = x
                    this.mergeCells = g
                    return
                }

                // this.option.xAxis[0].axisLabel.overflow = "truncate"
                if(this.selectedChart=='BAR' && this.dataSetups.dataX.length > 100){
                    this.option.dataZoom = [
                        {
                            show: true,
                            start: 0,
                            end: 10
                        },
                        {
                            type: 'inside',
                            start: 0,
                            end: 10
                        },
                        {
                            show: true,
                            yAxisIndex: 0,
                            filterMode: 'empty',
                            width: 30,
                            height: '80%',
                            showDataShadow: false,
                            left: '93%'
                        }
                    ]
                }

                if(vm.selectedChart!='TABLE'){
                    if(!vm.chart){
                        vm.chartDom = document.getElementById(this.divId)
                        if(!vm.chartDom) return
                        vm.chart = echarts.init(vm.chartDom)
                        window.addEventListener('resize', vm.resize)
                    } 
                    vm.chart.clear()
                }
                //set data
                this.setOptionSeries(y, g, y2)
                if(this.selectedChart == 'BAR' || this.selectedChart=='LINE' || this.selectedChart=='COMBO'){
                    this.setOptionYAxis()
                    this.setOptionXAxis(x)
                }
                if(this.selectedChart != 'RADAR')
                    this.setChartFigure(this.styleSetups.chartFigure, false)
                if(this.selectedChart == 'RADAR')
                    this.setOptionRadar(y)
                if(this.styleSetupToggles.bl_xAxis){
                    this.setStyleXAxis(vm.styleSetups.xAxis.bl_xLabel, vm.styleSetups.xAxis.bl_xRotate, vm.styleSetups.xAxis.bl_xTitle, vm.styleSetups.xAxis.xTitle)
                }

                if(this.styleSetupToggles.bl_yAxis)
                    this.setStyleYAxis(vm.styleSetups.yAxis.bl_displayScale, vm.styleSetups.yAxis.lineStyle, vm.styleSetups.yAxis.bl_yTitle, vm.styleSetups.yAxis.yTitle, vm.styleSetups.yAxis.yMin, vm.styleSetups.yAxis.yMax)
                // if(this.styleSetupToggles.bl_label)
                // this.setStyleTag(vm.bl_showData)

                if(vm.selectedChart!='TABLE'){
                    this.update_legendPos(this.styleSetups.legend.legendPos, false)
                    this.setStyleTag(this.styleSetupToggles.bl_label&&this.styleSetups.label.bl_showData)
                    vm.option && vm.chart.setOption(vm.option);
                }   
            },
            resetOption(){
                this.option = {}
                this.option.xAxis = []
                this.option.yAxis = []
                this.option.series = []
                this.option.legend = {}
                this.option.radar = {}
                if(!(this.selectedChart == 'BAR' || this.selectedChart=='LINE' || this.selectedChart=='COMBO')){
                    delete this.option.xAxis
                    delete this.option.yAxis
                }
                this.option.tooltip = {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            },
            checkMove({relatedContext, draggedContext}){
                this.$emit('swap',{group: this.group, from: draggedContext.element._id, to: relatedContext.element._id})
            },
            getLabel(field){
                if(field.type=='recordCount')
                    return 'Record Count'
                let res = this.fieldInfos.find(item=>item.fieldId==field._id)
                if(res){
                    if(res.type=='relatedRecord'){
                        let dataTableId = res.properties.dataSource
                        let relatedField = this.relatedTableInfos[dataTableId].fieldInfos.find(item=>item._id==field.relatedId)
                        if(relatedField)
                            return res.label + ' - ' + relatedField.label
                    }else{
                        if(res.type=='childTable'){
                            let childFieldInfos = res.properties.fieldsEditor.fieldInfos
                            let childField = childFieldInfos.find(item=>item._id==field.relatedId)
                            if(childField) return res.label + ' - ' + childField.label
                        }
                    }
                    return res.label   
                }
            }
            
        }
    }
</script>
