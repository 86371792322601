<template>
  <v-dialog
    persistent
    v-model="showingDialog"
    v-if="showingDialog"
    max-width="360">
    <v-card>
      <dialog-header
        :label="dialogTitle"
        @close="cancel"
        color="primary"/>
      <v-card-text class="pa-4 apps-category-info-dialog d-flex flex-column">
        <v-text-field v-model="label" 
          ref="label"
          dense
          solo
          @keypress.enter="submit"
          outlined
          :hide-details="true"
          class="w-100"></v-text-field>
      </v-card-text>
      <v-card-actions class="pa-2 elevation-2">
        <v-spacer></v-spacer>
        <v-btn
          class="min-width-100 muted elevation=0"
          @click.native="cancel"
          small>
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn
          :disabled="label===''"
          class="min-width-100"
          color="primary"
          @click.native="ok"
          small>
          {{ $t('buttons.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";

export default {
  name: 'appsCategoryInfoDialog',
  components: {
    dialogActions,
    dialogHeader
  },
  data () {
    return {
      index: -1,
      showingDialog: false,
      callbacK: null,
      label: ''
    }
  },
  computed: {
    dialogTitle () {
      return this.index === -1 ?
        this.$t('myapps.newCategory') :
        this.$t('myapps.editCategory')
    }
  },
  methods: {
    open (categoryInfo, callback) {
      const vm = this
      vm.callback = callback
      if (categoryInfo === null) {
        vm.index = -1,
        vm.label = ''
      } else {
        vm.index = categoryInfo.index,
        vm.label = categoryInfo.label
      }
      vm.showingDialog = true
      vm.$nextTick(() => {
        const textFieldEl = vm.$refs.label.$el
        if (textFieldEl) {
          const inputEl = textFieldEl.querySelector('input:not([type=hidden])')
          if (inputEl) {
            inputEl.select()
          }
        }
      })
    },
    cancel () {
      this.showingDialog = false
    },
    submit () {
      if (this.label !== '') this.ok()
    },
    ok () {
      const vm = this
      if (typeof vm.callback === 'function') {
        vm.callback({
          index: vm.index,
          label: vm.label
        })
      }
      vm.showingDialog = false
    }
  }
}
</script>