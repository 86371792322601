import {
  WorkflowNodeIdentify,
} from './../../../../constants/node';

const mixin = {
  data() {
    return {
      dropTarget: null,
    }
  },
  methods: {
    onDropping({ data }) {
      const { node } = data;
      this.dropTarget = node || data;
    },
    onDropLeft() {
      this.dropTarget = null;
    },
    readyToDropStyle() {
      if (!!this.dropTarget) {
        return {
          transition: 'height 0.3s ease-out',
          height: this.dropTarget.nodeType === this.nodeType ? '205px' : '350px',
          padding: 0,
        };
      }
      return {
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
    },
    readytoDropAreaStyle() {
      // if (!this.node.initial) {
      //   return {
      //     backgroundColor: 'transparent',
      //     transition: 'opacity 5s',
      //     opacity: 1,
      //   };
      // }
      if (!!this.dropTarget) {
        return {
          position: 'relative',
          paddingTop: '40px',
          transition: 'background-color .4s linear',
          backgroundColor: '#ececec',
          borderRadius: '10px',
          border: '1px dashed #ccc',
          opacity: 1,
        };
      }
      return {
        backgroundColor: 'transparent',
      };
    },
  },
  computed: {
    nodeType() {
      return WorkflowNodeIdentify.common;
    }
  },
}

export default mixin
