import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'
  

export default {
    state() {
        return {...state}
    },
    actions,
    getters,
    mutations
}

