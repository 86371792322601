<template>
  <v-col :cols="fieldWidth"
      v-if="!isHidden || isLayout"
      class="px-2 input-widget-container d-flex flex-column py-0"
      :class="{'is-layout my-0': isLayout,'my-0 flex-grow-1':stretch==='true'}"
    >
    <div v-if="label && (label!=='')"
         class="flex-grow-0 d-flex flex-row align-center input-widget-container-title">
      <v-icon size="md" v-if="isLayout&&widget&&!noIcon&&widget.mdiIcon" :style="{ color: 'rgba(0, 0, 0, 0.38)' }" class="mr-1">{{ widget.mdiIcon }}</v-icon>
      <font-awesome-icon size="md" v-else-if="isLayout&&widget&&!noIcon" :icon="widget.icon" :style="{ color: 'rgba(0, 0, 0, 0.38)' }" class="fa-fw mr-1"></font-awesome-icon>
      <label :class="labelClass">{{ hideLabel ? '&nbsp;' : label }}</label> 
      <v-tooltip v-if="tooltipText" top>
        <template v-slot:activator="{ attrs, on }">
            <v-icon small color="grey lighten-1" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
        </template>
        <div>{{tooltipText}}</div>
      </v-tooltip>
      <p v-show="required" class="red--text mb-0" >*</p>
       <v-icon v-if="isLayout && isHidden" color="warning" small >mdi-eye-off-outline</v-icon>
      <v-spacer></v-spacer>
      <label>{{ hint }}</label>
      <v-btn v-if="actionIcon!==''"
             @click="$emit('click:action')"
             x-small
             outlined
             color="primary">
        <v-icon small>{{ actionIcon }}</v-icon>
      </v-btn>
      <slot name="append-label"></slot>
    </div>
    <div style="width:0;min-width:100%;"
      class="slot-wrapper"
      :class="stretch==='true'?'flex-grow-1':''">
      <slot></slot>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'inputWidgetContainer',
  props: {
    noIcon: Boolean,
    tooltipIcon:{
      type: String,
      default: ''
    },
    tooltipText:{
      type: String,
      default: ''
    },
    isLayout: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    fieldWidth: {
      type: Number,
      default: 12
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    actionIcon: {
      type: String,
      default: ''
    },
    widget: {
      type: Object,
      default: null
    },
    isHidden: {
      type: Boolean,
      default:false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: String,
      default: 'auto'
    },
    labelClass: {
      type: String,
      default: ''
    }
  },
}
</script>

<style>
.is-layout {
  padding-bottom: 0 !important;
}

.input-widget-container .input-widget-container-title {
  margin-bottom: 2px;
}
.input-widget-container .input-widget-container-title label {
  color: #333;
  font-size: 13px;
}
.input-widget-container .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 6px !important;
}

.input-widget-container .mdi-image-filter-vintage {
  min-width: 20px !important;
  min-height: 20px !important;
}

</style>
