<template>
   <div>
       <!-- EMPLOYEE NAME -->
        <div v-if="['owner', 'createdBy'].includes(type)">
            <v-chip color="primary">
                <v-icon left>mdi-account-circle</v-icon>
                {{ content.employeeName }}
            </v-chip>
        </div>
        <!-- ATTACHMENT -->
        <div v-if="['attachments'].includes(type)" class="attachment-container">
            <li v-for="item in content" :key="item.mediaId">
                <picture v-if="item.mediaType.startsWith('image')" class="attachment-thumbnail"
                    @click="preview(item)">
                    <img :src="item.thumbnailUrl" />
                </picture>
                <div v-else>
                    <div class="text-center others-format-thumbnail" >
                        <span>
                            <v-icon dark>{{ fileIconMapping[item.mediaType] }}</v-icon>
                            <div>{{ item.originalName }} {{ item.originalName }}</div>
                        </span>
                    </div>
                </div>
            </li>
        </div>
        <!-- NORMAL TEXT -->
        <div v-if="['text'].includes(type) && (['view', 'edit'].includes(mode) || ( mode === 'create' && fieldInfo.canCreate ))" class="value-handler-text" >
            <span v-if="mode === 'view'">{{ content || '-' }}</span>
            <span v-else>
               <v-text-field :placeholder="fieldInfo.properties.placeholder" v-model="$parent.data[id]" outlined dense :readonly="fieldInfo.canEdit === false" />
            </span>
        </div>
        <!-- EMAIL -->
        <div v-if="['email'].includes(type)" >
            <span v-if="mode === 'view'">{{ content || "-" }}</span>
            <span v-else>
               <v-text-field :placeholder="fieldInfo.properties.placeholder" v-model="$parent.data[id]" outlined dense :readonly="fieldInfo.canEdit === false" />
            </span>
        </div>
        <!-- NUMBER, AMOUNT -->
        <div v-if="['number', 'amount'].includes(type) " >
            <!-- {{ fieldInfo }} -->
            <span v-if="mode === 'view'">{{ numberFormatter(content, fieldInfo) }}</span>
            <v-text-field :placeholder="fieldInfo.properties.placeholder" v-else outlined v-model="$parent.data[id]" dense :prefix="fieldInfo.properties.prefix" :suffix="fieldInfo.properties.suffix" type="number" />
        </div>
        <!-- DATETIME -->
        <div v-if="['createdAt', 'updatedAt'].includes(type)">
            {{ content | datetimeFormat }}
        </div>
        <!-- SELECTION, SINGLE / MULTIPLE -->
        <div v-if="['singleSelection', 'multipleSelection'].includes(type)">
            
            <div v-if="mode === 'view'">
                <v-chip v-for="(item, index) in selections" :key="index" :color="item.backgroundColor" :text-color="item.textColor" class="mr-1 mb-1" label>{{ item.label }}</v-chip>
            </div>
            <div v-else>
                <v-select outlined dense small-chips v-model="$parent.data[id]" :items="selectOptions" item-text="caption" item-value="key" :multiple="( this.type === 'multipleSelection')?true: false">
                    <template #selection="{ item }">
                        <v-chip :color="item.backgroundColor" :text-color="item.textColor" small dark>{{item.caption}}</v-chip>
                    </template>
                </v-select>
            </div>
        </div>
        <!-- SIGNATURE -->
        <div v-if="['signature'].includes(type) && content">
            <picture class="signature-thumbnail">
                <img :src="content.thumbnailUrl" />
            </picture>
        </div>
        <!-- RATING -->
        <div v-if="['rating'].includes(type)">
            <v-rating background-color="primary" color="primary" readonly v-model="content" small v-if="mode === 'view'" :length="fieldInfo.properties.ratingLevel"></v-rating>
            <v-rating background-color="primary" color="primary" v-model="$parent.data[id]" small v-else :length="fieldInfo.properties.ratingLevel"></v-rating>
        </div>
        <!-- idCard -->
        <div v-if="['idCard'].includes(type)">
            <span v-if="mode === 'view'">{{ content || '-' }}</span>
            <v-text-field :placeholder="fieldInfo.properties.placeholder" v-else v-model="$parent.data[id]" outlined dense></v-text-field>
        </div>
        <!-- DEPARTMENTS -->
        <div v-if="['departments'].includes(type)">
            <v-chip v-for="(item, index) in content" :key="index" label class="mr-1 mb-1" :close="(['edit','create'].includes(mode))" @click:close="removeItem( item._id )">
                {{ item.label }}
            </v-chip>
            <v-btn v-if="['edit','create'].includes(mode)" icon @click="showDepartmentSelector">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <department-selector v-if="['edit','create'].includes(mode)" :ref="id" />
            <div v-if="mode === 'view' && content">-</div>
        </div>
        <!-- MEMBERS -->
        <div v-if="['members'].includes(type)">
            <v-chip v-for="(item, index) in content" :key="index" class="mr-1 mb-1" :close="(['edit', 'create'].includes(mode))" @click:close="removeItem( item._id )">
                {{ item.employeeName }} 
                <div v-if="fieldInfo.properties.teamShowing === 'showAlways'">
                    <small> ( {{ item.teamName }} )</small>
                </div>
            </v-chip>
            <v-btn icon @click="showMemberSelector" v-if="['edit', 'create'].includes(mode)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <member-selector v-if="['edit', 'create'].includes(mode)" :show="isShowMemberSelector" />
            <div v-if="mode === 'view' && !content.length">-</div>
            
        </div>
        <!-- RELATED RECORD -->
        <div v-if="['relatedRecord'].includes(type)">
            <div v-if="content && content.length > 0 && Object.keys(relationData).length > 0">
                <div v-if="fieldInfo.properties.displayFormat === 'table'">
                    <v-icon>mdi-table</v-icon><v-chip small>{{ content.length || 0 }}</v-chip>
                </div>
                <div v-else-if="$route.name === 'forms'">
                    <v-chip label v-for="item in content" :key="item" class="mr-1 mb-1">
                        <div>
                            {{ relationData[item].title || '-' }}
                        </div>
                    </v-chip>
                </div>
                <div v-else>
                    <v-chip label v-for="item in content" :key="item" class="mr-1 mb-1">
                        <div>
                            <div v-if="mapRelatedRecordTitleFieldType(item) === 'members'">
                                <div v-for="member in relationData[id].find(_item => _item._id == item )[mapRelatedRecord( item )]" :key="member._id">
                                    {{ member.employeeName }}
                                </div>
                            </div>
                            <div v-else>
                                {{ relationData[id].find(_item => _item._id == item )[mapRelatedRecord( item )] || '-' }}
                            </div>
                        </div>
                    </v-chip>
                </div>
            </div>
            <div v-else>
                -
            </div>
        </div>
        <!-- CAPITALIZED AMOUNT -->
        <div v-if="['capitalizedAmount'].includes(type)">
            {{ content }}
        </div>
        <!-- EXPRESSION -->
        <div v-if="['expression'].includes(type)">
            <span v-if="content!=undefined">{{ numberFormatter(content, fieldInfo) }}</span>
            <span v-else>-</span>
        </div>
        <!-- DATE -->
        <div v-if="['date'].includes(type)">
            {{ content || '-' }}
        </div>
        <!-- AUTO NUMBER -->
        <div v-if="['autoNumbering'].includes(type)">
            {{ content || '-' }}
        </div>
        <!-- SUMMARY FIELD -->
        <div v-if="['summaryField'].includes(type)">
            <span v-if="content!=undefined">{{ numberFormatter(content, fieldInfo) }}</span>
            <span v-else>-</span>
        </div>
        <!-- PHONE -->
        <div v-if="['phone'].includes(type)">
            {{ content || '-' }}
        </div>
        <!-- YESNO -->
        <div v-if="['yesNo'].includes(type)">
            <v-checkbox v-model="content" disabled dense></v-checkbox>
        </div>
        <!-- TEXT COMBINATION -->
        <div v-if="['textCombination'].includes(type)">
            {{ content }}
        </div>
        <!-- REGION -->
        <div v-if="['region'].includes(type)">
            {{ content && content.regionText || '-' }}
        </div>
        <!-- LOCATION -->
        <div v-if="['location'].includes(type)">
            {{ content && content.name || '-' }}
        </div>
        <!-- CHILDTABLE -->
        <div v-if="['childTable'].includes(type)">
            <div>
                <v-icon>mdi-table</v-icon> <v-chip small>{{ content.length || 0 }}</v-chip>
            </div>
        </div>
        <!-- OTHERTABLEFIELD -->
        <div v-if="['otherTableField'].includes(type)">
            <div>
                {{ content || '-' }}
            </div>
        </div>
        <!-- RICHTEXT -->
        <div v-if="['richText'].includes(type)">
            <div v-if="content" v-html="content"></div>
            <div v-else>-</div>
        </div>
   </div>
</template>

<script>
    import moment from 'moment-timezone';
    import mixin from '@/components/inputWidgets/mixin';
    import MemberSelector from '@/components/inputWidgets/mobile/memberSelector';
    import DepartmentSelector from '@/components/inputWidgets/mobile/departmentSelector';
    import MediaHelper from '@/helpers/MediaHelper'

    export default {
        mixins: [mixin],
        components: {
            MemberSelector, DepartmentSelector
        },
        props: [ 'content', 'type', 'fieldInfo', 'relationData', 'mode', 'id', 'relatedTableInfos' ],
        // mixins: [ fieldHelperMixin ],
        computed: {
            selectOptions() {
                let _options = [];
                if ( ['singleSelection', 'multipleSelection'].includes(this.type) ) {
                    let _fieldOptions = [];
                    switch( this.type ) {
                        case 'singleSelection':
                            _fieldOptions = this.fieldInfo.properties.inputOptionsSingle.options;
                            break;
                        case 'multipleSelection':
                            _fieldOptions = this.fieldInfo.properties.inputOptionsMultiple.options;
                            break;
                    }
                    _fieldOptions.forEach( item => {
                        if ( item.colorSet ) {
                            const _colorSet = item.colorSet.split('/')
                            _options.push({
                                key: item._id, caption: item.label,
                                backgroundColor: _colorSet[1], textColor: _colorSet[0]
                            })
                        }
                    })
                }
                return _options;
            },
            fieldValidations() {
                let validations = [];
                validations = this.fieldInfo.validation.split('||');
                return validations;
            },
            availableTeams() {
                return this.$store.getters.user.teams;
            },
            currentForm() {
                return this.$store.getters.currentForm;
            },
        }, 
        data() {
            return {
                fileIconMapping: {
                    'application/pdf': 'mdi-file-pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'mdi-file-word',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'mdi-file-excel',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'mdi-file-powerpoint',
                    'application/vnd.ms-excel': 'mdi-file-excel',
                    'application/vnd.ms-powerpoint': 'mdi-file-powerpoint',
                    'application/msword': 'mdi-file-word',
                    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'mdi-file-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.template': 'mdi-file-powerpoint',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'mdi-file-excel',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'mdi-file-word',
                    'application/vnd.ms-excel.sheet.macroEnabled.12': 'mdi-file-excel',
                    'application/vnd.ms-excel.template.macroEnabled.12': 'mdi-file-excel',
                    'application/vnd.ms-powerpoint.template.macroEnabled.12': 'mdi-file-powerpoint',
                    'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'mdi-file-powerpoint',
                    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'mdi-file-powerpoint',
                },
                selections: [],
                isShowMemberSelector: false,
                isShowDepartmentSelector: false
            }
        },
        methods: {
            preview(media) {
                console.log('FileDCrop.preview: media: ', media);
                if (media && media.objectKey) {
                    MediaHelper.previewMedia(media, false);
                }
            },
            selectionFormatter: (content, fieldInfo) => {
                const { useColor, useValue, options } = fieldInfo?.properties?.inputOptionsSingle || fieldInfo?.properties?.inputOptionsMultiple;
                let _label, _backgroundColor, _textColor;
                if ( content ) {
                    const targetOption = options.find( opt => opt._id === content ); 
                    const colorSet = targetOption.colorSet.split('/');
                    _label = targetOption.label;
                    _backgroundColor = (useColor)? colorSet[1]: '';
                    _textColor = (useColor)?colorSet[0]: '';
                }
                return {
                    label: _label,
                    backgroundColor: _backgroundColor,
                    textColor: _textColor
                }
            },
            numberFormatter: (val, fieldInfo) => {
                val = val?? 0
                let { prefix, suffix, decimals, unit } = fieldInfo.properties;
                prefix = prefix || '';
                suffix = suffix || '';
                unit = unit || '';
                let _formattedVal = new Intl.NumberFormat('en', {
                    minimumFractionDigits: parseInt(decimals),
                    maximumFractionDigits: parseInt(decimals),
                }).format(val);
                return `${prefix}\t${_formattedVal}${unit}\t${suffix}`;
            },
            showMemberSelector() {
                this.isShowMemberSelector = true;
            },
            showDepartmentSelector() {
                console.log( this.content)
                this.$refs[this.id].openDialog( this.content )
            }, 
            removeItem( itemId ) {
                this.$parent.data[this.id] = this.$parent.data[this.id].filter( item => item._id !== itemId );
            },
            appendMember(_member) {
                console.log( _member )
                this.content.push( _member );
            },
            updateDepartment(_departments) {
                this.content = _departments;
            },
            mapRelatedRecord( item ) {
                let titleFieldInfoId = "";
                let formId = this.relationData[this.id].find( _item => _item._id === item).formId
                if ( !formId ) titleFieldInfoId = this.currentForm.titleFieldInfoId;
                else {
                    titleFieldInfoId = this.relatedTableInfos[formId].titleFieldInfoId;
                }
                return titleFieldInfoId;
            },
            mapRelatedRecordTitleFieldType( item ) {
                let fieldType = "";
                let formId = this.relationData[this.id].find( _item => _item._id === item).formId
                if ( !formId ) {
                    fieldType = this.currentForm.fieldInfos.find( _field => _field._id === this.currentForm.titleFieldInfoId).type;
                } else {
                    
                    fieldType = this.relatedTableInfos[formId].fieldInfos.find( _field => _field._id === this.relatedTableInfos[formId].titleFieldInfoId).type;
                }
                return fieldType;
            }
        }, 
        mounted() {
            switch( this.type ) {
                case 'rating': 
                    this.fieldInfo.properties.ratingLevel = parseInt(this.fieldInfo.properties.ratingLevel)
                    break;
                case 'singleSelection':
                    if ( this.content ) {
                        this.selections.push(this.selectionFormatter( this.content, this.fieldInfo ))
                    }
                    break;
                case 'multipleSelection':
                    if ( this.content ) {
                        this.content.forEach(item => {
                            this.selections.push( this.selectionFormatter( item, this.fieldInfo ) );
                        })
                    }
                    break;
            }
        }, 
        filters: {
            datetimeFormat: (val) => {
                return moment(val).tz('Asia/Hong_Kong').format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .attachment-container {
        display: flex;
        gap: 1rem;
        li {
            list-style: none;
        }
        .attachment-thumbnail img {
            aspect-ratio: 1;
            width: 50vw;
            object-fit: cover;
            border-radius: 4px;
            transition: all 0.5s ease;
        }
        .others-format-thumbnail {
            aspect-ratio: 1;
            width: 20vw;
            background: #333333;
            color: #FFF;
            border-radius: 4px;
            font-size: 0.785rem;
            padding: .5rem;
            line-height: 1;
            place-items: center;
            display: grid;
            place-items: center;
            div {
                display: -webkit-box;
                width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
    .signature-thumbnail img {
        aspect-ratio: 1;
        width: 10vw;
        max-height:8rem;
        object-fit: contain;
        border-radius: 4px;
        transition: all 0.5s ease;
        border: 1px solid rgba(0,0,0,.1)
    }

    .value-handler-text {
        max-height: 50vh;
        overflow-y: scroll !important;
    }
</style>