<template>
  <v-row no-gutters class="publicFormURL px-0" :style="`max-width:${maxWidth}`">
    <slot name="prepend-outer"></slot>
    <div class="grey lighten-2 px-2 urlBox d-flex flex-grow-1">
      <input
        :id="urlId"
        :value="url"
        readonly="true"
        class="urlInput"
        @dblclick="selectUrl"
      />
      <slot name="append-inner"></slot>
    </div>
    <slot name="prepend-copy"></slot>
    <div class="px-2" v-if="canCopy">
      <v-tooltip top>
        <template v-slot:activator="{ attr, on }">
          <v-btn icon small @click="copyURL" v-on="on" v-bind="attr">
            <v-icon small> mdi-content-copy </v-icon>
          </v-btn>
        </template>
        {{ $t("publicForm.copyURL") }}
      </v-tooltip>
    </div>
    <slot name="append-copy"></slot>
    <div class="px-2">
      <v-menu
        open-on-hover
        bottom
        :close-on-content-click="false"
        v-if="!noQRCode"
      >
        <template v-slot:activator="{ on, attr }">
          <v-btn icon small v-on="on" v-bind="attr">
            <v-icon small> mdi-qrcode </v-icon>
          </v-btn>
        </template>
        <v-card>
          <img :src="qrcode" />
          <v-row no-gutters align="center" justify="center">
            <v-btn text @click="downloadQR" color="primary">{{
              $t("buttons.download")
            }}</v-btn>
          </v-row>
        </v-card>
      </v-menu>
    </div>
  </v-row>
</template>
<script>
import QRCode from "qrcode";

export default {
  props: {
    url: String,
    canCopy: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
    noQRCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlId: Math.random()
        .toString(36)
        .replace("0.", "url_" || ""),
      qrcode: "",
    };
  },
  mounted() {
    QRCode.toDataURL(this.url, (err, url) => {
      this.qrcode = url;
    });
  },
  methods: {
    selectUrl() {
      const input = document.getElementById(this.urlId);
      input.focus();
      input.select();
    },
    copyURL() {
      navigator.clipboard.writeText(this.url).then(() => {
        this.$toast.info(this.$t("messages.copied"), {
          duration: 3000,
        });
      });
      this.$emit("copy", this.url);
    },
    downloadQR() {
      QRCode.toDataURL(this.url, { width: 500 }, (err, url) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${new Date().toISOString()}.png`);
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
      });
      this.$emit("download", this.url);
    },
  },
  watch: {
    url: function (newVal) {
      QRCode.toDataURL(newVal, (err, url) => {
        this.qrcode = url;
      });
    },
  },
};
</script>
<style scoped>
.publicFormURL {
  height: 36px;
  line-height: 36px;
  padding: 0 4px;
}

.urlInput {
  width: 100%;
}
.urlInput:focus {
  outline: none;
}
.urlBox {
  border-radius: 3px;
  height: 36px;
  line-height: 36px;
  margin-bottom: 10px;
  font-size: 13px;
}
</style>
