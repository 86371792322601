<template>
  <FormElement
    :label="$t('workflow.modules.retrieveRecord.label.quota')"
    name='retrieveQuota'
  >
    <ComboBox
      :value="quota || null"
      :options="list"
      disableBranchNode
      disabledOnDependencies
      dependsOnType="number"
      @update:value="quota = $event"
      addable
      :onlyPrefix="true"
      @changed="changed"
      :onlySuffix="true"
      optionMultipleOutput="single"
      :placeholder="$t('workflow.modules.retrieveRecord.placeholder.pleaseEnterQuota')"
      valueFormat="object"
      :otherProps="{ disabled: random }"
    />
    <!-- <v-checkbox
      v-model="random"
      :value="true"
      :label="$t('workflow.modules.retrieveRecord.label.setting.randomOne')"
    ></v-checkbox> -->
  </FormElement>
</template>

<script>
import Validator from 'validator';
import FormElement from './../shared/formElement';
import ComboBox from './../../../form/comboBox/';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import Mixin from './../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ComboBox,
    FormElement,
  },
  props: {
    quota: {
      type: Object,
      default: {
        label: '1',
        id: '1',
        type: 'newTag',
      },
    },
    random: Boolean,
  },
  // mounted() {
  //   this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
  //     workflow: this.workflow._id,
  //     node: this.node._id
  //   });
  // },
  methods: {
    validation(value) {
      return Validator.isInt(value);
    },
    changed(retrieveQuota) {
      this.$store.dispatch(SET_SETTINGS, {
        retrieveQuota: retrieveQuota || null,
      });
    }
  }, 
  computed: {
    list() {
      return WorkflowSettingUtils.delegatedOptionWithDependsOn({
        locale: this.$t('workflow'), 
        dependsOn: 'number',
        list: this.$store.getters.getDependencies,
      });
    },
  },
  watch: {
    quota: {
      handler(v) {
        this.$store.dispatch(SET_SETTINGS, {
          retrieveQuota: v,
        });
      },
      deep: true,
    },
    // random: {
    //   handler(v) {
    //     this.$store.dispatch(SET_SETTINGS, {
    //       retrieveRandomOne: v,
    //     });
    //   },
    //   deep: true,
    // }
  }
};

</script>

<style scoped>

</style>
