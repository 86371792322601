<template>
  <div>
    <v-radio-group 
      hide-details
      dense
      :column="false"
      v-model="type"
      @change="onChanged"
    >
      <v-radio 
        dense 
        v-for="option in options"
        :key="option.id" 
        :label="$t(option.label)"
        :value="option.id"
        class="w-v-display-option"
      />
    </v-radio-group>
    <AlertSetting
      v-if="type === 'alert'"
      :buttonName="buttonName"
      :popup="popup"
    />
  </div>
</template>

<script>
import {
  UPDATE_ACTION_BUTTON_INFO,
} from '@/store/modules/customizedAction/action_types';
import AlertSetting from './alert/';

export default {
  props: {
    type: {
      type: String,
      default: 'normal',
    },
    buttonName: String,
    popup: Object,
  }, 
  components: {
    AlertSetting,
  },
  data() {
    return {
      options: [
        {
          id: 'normal',
          label: 'workflow.modules.actionProcess.label.buttonTrigger.normal',
        },
        {
          id: 'alert',
          label: 'workflow.modules.actionProcess.label.buttonTrigger.confirm',
        }
      ],
    }
  },
  methods: {
    onChanged(content) {
      this.$store.dispatch(UPDATE_ACTION_BUTTON_INFO, {
        field: 'type',
        content,
      });
    }
  },
}
</script>

<style scoped>
  .w-v-display-option {
    margin-right: 25px;
  }
</style>