<template>
  <div>
    <SettingHeader :label="$t('workflow.label.childProcess')" :node="node" />
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.widgets.properties.dataSource')"
        name='worksheet'
      >
        <div class="w-e-datasource">
          {{ $t('workflow.label.table') }}: <span>{{ worksheet.label }}</span>
        </div>
      </FormElement>
    </div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.subWorkflow.label.fromFollowingWorkflow')"
        name='dataSource'
      >
        <div class="w-e-fromWhen">
          <v-layout align-center justify-space-between>
            <TriggerStatement :node="node" />
            <v-btn
              color="primary"
              text
              @click="goWorkflow"
            >
              {{$t('workflow.modules.subWorkflow.label.viewParentWorkflow')}}
            </v-btn>
          </v-layout>
        </div>
      </FormElement>
    </div>
  </div>
</template>

<script>
import SettingHeader from './shared/header';
import FormElement from './shared/formElement';
import TriggerStatement from './../view/childProcess/trigger';
import Mixin from './mixin';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  computed: {
    worksheet() {
      return this.setting.worksheet;
    },
    parentWorkflow() {
      return this.setting.parentWorkflow;
    },
    parentWorkflowNode() {
      return this.setting.parentWorkflowNode;
    },
  },
  methods: {
    goWorkflow() {
      WorkflowSettingUtils.goBlankWindowWorkflow(this.$router, this.parentWorkflow._id);
    },
  },
  components: {
    SettingHeader,
    FormElement,
    TriggerStatement,
  },
};

</script>

<style scoped>
  .w-e-datasource {
    margin: 10px 0 0 0;
    font-size: 13px;
    font-weight: normal;
  }
  .w-e-datasource span {
    font-weight: 700;
    margin: 0 5px;
  }
  .w-e-fromWhen {
    border: 1px solid #ebebeb;
    padding: 8px 12px;
    background: #f5f5f5;
    border-radius: 3px;
    margin: 10px 0;
  }
</style>
