<template>
<div class="flex-grow-1 white">
    <v-hover v-slot="{ hover }" class="pa-0 ma-0" style="width:100%; height:100%;" >
        <div class="">
            <div class="btnActions" style="position: absolute; left: 50%;; z-index:1" v-show="hover && blHover">   
                <v-card style="position: relative; left: -50%;">
                    <v-btn :class="item.bl_title?'info--text':''" @click="item.bl_title=!item.bl_title" small icon :ripple="false" plain><v-icon size="18">mdi-format-title</v-icon></v-btn>
                    <v-btn small icon :ripple="false" plain @click="cloneItem(item)"><v-icon size="18">mdi-content-copy</v-icon></v-btn>
                    <v-btn small icon :ripple="false" plain @click="deleteItem(item)"><v-icon size="18">mdi-delete</v-icon></v-btn>
                </v-card>
            </div>
            <div class="d-flex flex-column justify-center" style="height:100%">
                <div v-for="i in Math.ceil(item.btns.length/item.numPerRow)" :key="i" class="d-flex justify-center align-center px-3" style="overflow: hidden">
                    <div v-for="(btn,index) in getSlicedBtns(i, item.btns, item.numPerRow)" :key="btn.id" class="" :class="item.align=='justify'?'flex-grow-1':''">
                        <div :class="[index>0?'ml-2':'', i>1?'mt-2':'']" class="justify-center d-flex">
                            <v-btn elevation="0" style="width:100%" :outlined="btn.type=='outlined'" :class="btn.type" :color="btn.bgColor">
                                <span :class="btn.whiteText?'white--text':''" style="overflow:hidden; white-space:nowrap; text-overflow:ellipsis">{{btn.text}}</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-hover>
</div>
</template>

<script>
export default {
  name: 'compBtn',
  props: {
    blHover: Boolean,
    item: Object
  },
  methods: {
    cloneItem (item) {
      this.$emit('cloneItem', item)
    },
    deleteItem (item) {
      this.$emit('deleteItem', item)
    },
    getSlicedBtns(index, btns, numPerRow){
        return btns.slice((index-1)*(numPerRow), numPerRow*index)
    }
  }
}
</script>