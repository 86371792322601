import { apps as types } from "@/store/action-types";
import { get } from "lodash";
import { apps as mutations } from "../../mutation-types";
import { app } from "@/main";
import {
  getAppModule,
  getAllMenuItemIds,
} from "./helpers";

export default {
  [types.REORDER_MODULES]({ getters, dispatch }, payload) {
    const appId = payload.app._id;
    const oldIndex = payload.oldIndex;
    const newIndex = payload.newIndex;

    return new Promise((resolve, reject) => {
      var app = getters.currentApp;
      if (app) {
        const arTargetModules = app.modules.splice(oldIndex, 1);
        app.modules.splice(newIndex, 0, arTargetModules[0]);
        const postData = {
          urlCommand: "/apps/" + appId + "/modules_update",
          data: {
            modules: app.modules,
          },
        };
        dispatch("AUTH_POST", postData)
          .then((response) => {

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject("App Undefined!");
      }
    });
  },

  [types.STORE_MODULE_MENU]({ commit, dispatch }, payload) {
    const postData = {
      urlCommand:
        "/apps/" + payload.appId + "/modules/" + payload.module._id + "/menu",
      data: {
        menu: payload.menu,
        menuItemId: payload.item._id,
      },
    };
    return new Promise((resolve, reject) => {
      console.log("STORE_MODULE_MENU :: postData: ", postData);
      dispatch("AUTH_POST", postData).then((response) => {
        //console.log('STORE_MODULE_MENU :: reseponse: ', response)
        commit(mutations.UPDATE_APP_MODULE, {module: payload.module});
        resolve(response);
      })
      .catch(err=> {
        //console.error(err)
        reject (err)
      });
    });
  },

  [types.REMOVE_APP_MODULE]({ getters, commit, dispatch }, payload) {
    const postData = {
      urlCommand: "/apps/" + getters.currentApp._id + "/deleteModule",
      data: {
        targetModuleId: payload.targetModuleId,
        moduleId: payload.module._id,
        appId: getters.currentApp._id,
      },
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_POST", postData).then(
        (response) => {
          if (response.result) {
            commit(mutations.REMOVE_APP_MODULE, {
              appId: getters.currentApp._id,
              moduleId: payload.module._id,
              targetModuleId: payload.targetModuleId,
            });
          }
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.UPDATE_APP_MODULE]({ getters, commit, dispatch }, payload) {
    const appId = payload.id;
    const moduleId = payload.moduleId;

    console.log("UPDATE_APP_MODULE : payload: ", payload);
    let module = getAppModule(getters.currentApp, moduleId);
    if (payload.title) module.title = payload.title;
    if (payload.menu) module.menu = payload.menu;
    const putData = {
      urlCommand: "/apps/" + appId + "/modules/" + moduleId,
      data: {
        ...module,
      },
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_PUT", putData).then(
        (response) => {
          commit(mutations.UPDATE_APP_MODULE, {
            id: payload.id,
            module: module,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.INSERT_APP_MODULE]({ commit, dispatch }, payload) {
    // console.log('INSERT_APP_MODULE : payload: ', payload)
    const postData = {
      urlCommand: "/apps/" + payload.id + "/modules",
      data: {
        title: payload.title,
      },
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_POST", postData).then(
        (response) => {
          commit(mutations.ADD_APP_MODULE, {
            id: payload.id,
            module: response,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.UPDATE_APP_PROPERTY]({ commit, dispatch }, payload) {
    const data = {
      propertyValue: payload.propertyValue,
    };
    const putData = {
      urlCommand: "/apps/" + payload.id + "/" + payload.propertyKey,
      data: data,
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_PUT", putData).then(
        (response) => {
          // console.log('FETCH_APPS :: response: ', response)
          commit(mutations.UPDATE_APP_PROPERTY, payload);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [types.FETCH_APP]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('actions.js  FETCH_APP: payload: ', payload)
      var appId;
      // console.log('types.FETCH_APP')
      if (payload && payload.appId) {
        appId = payload.appId;
      } else {
        appId = localStorage.getItem("appId");
      }
      // console.log('FETCH_APP :: appId = ' + appId)
      if (!appId || appId === "") {
        reject("App ID undefined!");
      }
      
      const getData = {
        urlCommand: "/apps/" + appId,
        options: {
          params: {
            include: "menu",  
          },
        },
      };
      // console.log('FETCH_APP :: getData: ', JSON.stringify(getData))
      dispatch("AUTH_GET", getData).then(
        (response) => {
          // console.log('FETCH_APP => AUTH_GET :: response: ', response)
          commit(mutations.SET_CURRENT_APP, response)

          getters.currentApp.modules && getters.currentApp.modules.length > 0
            ? commit(mutations.SET_CURRENT_MODULE_INDEX, 0)
            : commit(mutations.SET_CURRENT_MODULE_INDEX, -1);

          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.FETCH_APPS]({dispatch }) {
    const getData = {
      urlCommand: "/apps",
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_GET", getData).then(
        (response) => {
          // console.log('FETCH_APPS :: response: ', response)
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.DUPLICATE_APP]({ dispatch, commit }, payload) {
    const { appId } = payload;
    const postData = {
      urlCommand: "/apps/duplicate",
      data: {
        appId,
        appName: payload.appName,
      },
      options: {
        timeout: 300000,
      },
    };
    return new Promise((resolve, reject) => {
      commit(types.DUPLICATING_APP, appId);
      dispatch("AUTH_POST", postData).then(
        (response) => {
          if (get(response, "status") === "failed") {
            app.$toast.error(app.$i18n.t(`messages.${response.reason}`))
            resolve(null)
          } else {
            resolve(response);
          }
          commit(types.DUPLICATING_APP_SUCCESS, appId);
        },
        (error) => {
          commit(types.DUPLICATING_APP_SUCCESS, appId);
          reject(error);
        }
      );
    });
  },
  [types.DUPLICATE_MENU_ITEM]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let postData = {};
      let urlCommand = "";
      switch (payload.type) {
        case "form":
          postData = {
            formId: payload.formId,
            appId: getters.currentApp._id,
            moduleId: getters.currentModule._id,
          };
          urlCommand = "/forms/duplicate"
          break;
        case "panel":
          postData = {
            panelId: payload.panelId,
            appId: getters.currentApp._id,
            moduleId: getters.currentModule._id,
          };
          urlCommand = "/panels/duplicate"
          break;
        case "calendar":
          postData = {
            calendarId: payload.calendarId,
            appId: getters.currentApp._id,
            moduleId: getters.currentModule._id,
          };
          urlCommand = "/calendar_panels/duplicate"
          break;
        default:
          break;
      }
      dispatch("AUTH_POST", {
        urlCommand,
        data: postData,
      })
        .then((res) => {
          if (res.newMenuItem) {
            commit(mutations.ADD_MENU_ITEM, {
              appId: getters.currentApp._id,
              moduleId: getters.currentModule._id,
              parentId: res.newMenuItem.parent,
              item: res.newMenuItem,
            });
          }
          resolve(res.newMenuItem);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [types.DELETE_APPS]({ dispatch }, payload) {
    const deleteData = {
      urlCommand: "/apps/" + payload.appsId,
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_DELETE", deleteData).then(
        (response) => {

          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.SET_SELECTED_MENU_ITEM_ID]({ commit }, payload) {
    commit(mutations.SET_SELECTED_MENU_ITEM_ID, payload);
  },

  async [types.SET_CURRENT_MODULE_INDEX](
    { getters, commit, state },
    payload
  ) {
    const app = getters.currentApp;
    if (app) {
      commit(types.SET_CURRENT_MODULE_INDEX, payload);
      if (app.modules && app.modules.length > 0) {
        const rootMenu = app.modules[state.currentModuleIndex].menu;
        if (rootMenu.length > 0) {
          const allMenuItemIds = getAllMenuItemIds(rootMenu);
          if (!allMenuItemIds.includes(state.selectedMenuItemId)) {
            var menuItemId = allMenuItemIds[0]
            commit(types.SET_SELECTED_MENU_ITEM_ID, menuItemId);
          }
        }
      } else {
        // console.log(
        //   "apps/SET_CURRENT_MODULE_INDEX :: getters.currentApp is null"
        // );
      }
    }
  },
  [types.SET_APP_PROPERTY]({ commit }, payload) {
    commit(mutations.UPDATE_APP_PROPERTY, payload);
  },
  [types.UPDATE_CURRENT_MODULE_MENU]({ getters, commit, dispatch }, payload) {
    const app = getters.currentApp;
    const moduleId = payload.moduleId;

    const putData = {
      urlCommand:
        "/apps/" +
        app._id +
        "/modules/" +
        moduleId +
        "/menu_items/" +
        payload.menuItemInfo._id,
      data: {
        menuItemInfo: payload.menuItemInfo
      },
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_PUT", putData).then(
        (response) => {
          if (response.result)
            commit(mutations.UPDATE_MENU_ITEM, {
              appId: app._id,
              moduleId,
              parentId: payload.menuItemInfo.parent,
              item: payload.menuItemInfo,
            });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [types.APPEND_CURRENT_MODULE_MENU]({ getters, commit, dispatch }, payload) {
    const app = getters.currentApp;
    const module = getters.currentModule;
    const postData = {
      urlCommand: "/apps/" + app._id + "/modules/" + module._id + "/menu_items",
      data: {
        menuItemInfo: payload.menuItemInfo,
        parent: payload.parent,
        siblingId: (payload.sibling && payload.sibling._id) || null,
      },
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_POST", postData).then(
        (response) => {
          commit(mutations.ADD_MENU_ITEM, {
            appId: app._id,
            moduleId: module._id,
            parentId: response.parent,
            item: response,
          });

          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [types.REMOVE_CURRENT_MODULE_MENU]({ getters, commit, dispatch }, payload) {
    const app = getters.currentApp;
    const module = getters.currentModule;
    const menuItemId = payload._id;
    const parent = payload.parent;

    const deleteData = {
      urlCommand:
        "/apps/" +
        app._id +
        "/modules/" +
        module._id +
        "/menu_items/" +
        menuItemId,
    };

    return new Promise((resolve, reject) => {
      dispatch("AUTH_DELETE", deleteData).then(
        (response) => {
          dispatch("RESET_FORMS")
          commit(mutations.REMOVE_MENU_ITEM, {
            appId: app._id,
            moduleId: module._id,
            parentId: parent,
            menuItemId,
          });

          resolve(response.result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [types.MOVE_CURRENT_MODULE_MENU]({ getters, commit, dispatch }, payload) {
    const app = getters.currentApp;
    const module = getters.currentModule;
    const postData = {
      urlCommand: "/apps/" + app._id + "/moveMenuItem",
      data: {
        menuItemId: payload.menuItem._id,
        targetModuleId: payload.targetModuleId,
      },
    };
    return new Promise((resolve, reject) => {
      dispatch("AUTH_POST", postData).then(
        (res) => {
          if (res.result) {
            commit(mutations.REMOVE_MENU_ITEM, {
              appId: app._id,
              moduleId: module._id,
              parentId: payload.menuItem.parent,
              menuItemId: payload.menuItem._id,
            });

            let newItem = payload.menuItem;
            Object.assign(newItem, {
              parent: null,
              order: 999,
            });
            commit(mutations.ADD_MENU_ITEM, {
              appId: app._id,
              moduleId: payload.targetModuleId,
              parentId: null,
              item: newItem,
            });
          }
          resolve(res.result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [types.TOGGLE_CURRENT_MODULE_MENU_VISIBILITY]({getters, commit, dispatch}, payload){
    const app = getters.currentApp;
    const module = getters.currentModule;
    const postData = {
      urlCommand: `/apps/${app._id}/setMenuItemVisibility`,
      data: {
        moduleId: module._id,
        id: payload.menuItem._id,
        hidden: payload.hidden
      }
    }

    dispatch('AUTH_POST', postData).then(res=> {
      if (res.result){
        commit(mutations.UPDATE_MENU_ITEM, {
          appId: app._id,
          moduleId: module._id,
          parentId: payload.menuItem.parent,
          item: {
            ...payload.menuItem,
            hidden: payload.hidden
          },
        });
      }
    })
  },

  [types.RESET_APPS]({ commit }) {
    commit(mutations.RESET_APPS);
  },
};
