<template>
  <v-list :style="{ paddingBottom: 0 }">
    <div v-if="!noHeader" class="workflow-customized-action-title">
      {{ $t('workflow.label.selectCustomizedAction', { size }) }}
    </div>
    <v-divider />
    <v-list-item
      v-for="button in buttonOptions"
      :key="button._id"
      class="workflow-customized-action-button-list"
    >
      <v-layout column>
        <component
          :is="targetComponent(button)"
          :button="button"
          :ref="button._id"
          :selected="selected"
          @onSelected="onSelected"
          :buttonProps="{
            depressed: true,
            rounded: false,
          }"
        />
        <v-divider />
      </v-layout>
    </v-list-item>
    <v-list-item v-if="!noFooter" class="workflow-customized-action-button-list">
      <v-btn
        text color="accent"
        @click="popAddRow"
        size="30"
        class="workflow-customized-action-button-list-add">
        <span :style="{ color: $vuetify.theme.themes.light.primary }">
          {{ $t('workflow.modules.actionProcess.label.insertNew') }}
        </span>
      </v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
import NormalButton from './../buttons/normal';
import AlertButton from './../buttons/alert';
import Mixin from './../buttons/mixin';
import ButtonsMixin from './mixin';
import { get } from 'lodash';

export default {
  mixins: [Mixin, ButtonsMixin],
  components: {
    AlertButton,
    NormalButton,
  },
  computed: {
    selectedActionData() {
      return this.$store.getters.actionSelectedData;
    },
    selectedActionId() {
      return this.$store.getters.actionType;
    },
    clickableTS() {
      return this.$store.getters.actionClickTs;
    },
  },
  watch: {
    clickableTS: function(v) {
      if (this.selectedActionId) {
        const el = get(this.$refs, `[${this.selectedActionId}][0]`);
        if (el && el.onClick) {
          el.onClick();
        }
      }
    }
  },
}
</script>

<style scoped>
</style>