var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-table-column',_vm._b({attrs:{"show-overflow":"hidden"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"w-100 cursor-pointer blue--text d-inline-block",class:{ 'text-right': _vm.isNumeric },on:{"click":function($event){return _vm.gotoRelatedRecord(row, _vm.colDef.field)}}},[(
          [
            'text',
            'number',
            'summaryField',
            'expression',
            'textCombination' ].includes(_vm.activeFieldInfo.type)
        )?_c('field-string',{attrs:{"textOnly":false,"height":"100%","rowSize":_vm.rowSize,"fieldInfo":_vm.activeFieldInfo,"recordData":_vm.getRelatedRecordData(row)}}):_c('div',{style:(_vm.specialRowStyle)},[_c('field-string',{attrs:{"textOnly":false,"height":"100%","rowSize":_vm.rowSize,"fieldInfo":_vm.activeFieldInfo,"recordData":_vm.getRelatedRecordData(row)}})],1)],1)]}},{key:"footer",fn:function(ref){
        var column = ref.column;
return [_c('summary-cell',{attrs:{"decimals":_vm.decimals,"column":column,"labelValuePairs":_vm.getSummaryLabelValuePairs(column)},on:{"click":_vm.selectCalcMethod}})]}}])},'vxe-table-column',_vm.colDef,false))}
var staticRenderFns = []

export { render, staticRenderFns }