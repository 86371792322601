
import ValidationsHelper from "../helpers/ValidationsHelper"
const rulesMixin = {
  computed: {
    rules () {
      const vm = this
      return {
        required: v => !ValidationsHelper.isEmpty(v) || vm.$t('messages.thisFieldIsRequired'),
        email: v => {
          return ValidationsHelper.isValidEmail(v) || this.$t("messages.invalidEmail")
        }
      }
    }
  }
}

export default rulesMixin
