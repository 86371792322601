<template>
  <v-dialog
    v-model="showConfirm"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
        v-bind="attrs"
        v-on="on"
        elevation="0"
      >
        <v-icon
          small
          size="20"
        >
          mdi-content-copy
        </v-icon>
      </v-btn>
    </template>
    <v-card class="workform-confirm-box">
      <v-layout justify-space-between align-center>
        <v-card-title class="workform-confirm-box-title w-control-remove-title">
          {{ $t('workflow.label.copyHeader', { name: item.name }) }}
        </v-card-title>
        <v-btn icon @click="closeDialog()">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <v-card-text>
        <span class="workform-confirm-box-message">{{ $t('workflow.label.copyConfirmHints') }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="workform-confirm-box-btn"
          @click="closeDialog()"
        >
          {{ $t('workflow.btn.cancel') }}
        </v-btn>
        <v-btn
          color="#1e88e5"
          class="workform-confirm-box-btn w-control-remove-btn-confirm"
          @click="copy"
        >
          {{ $t('workflow.btn.copy') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  DUPLICATE_WORKFLOW,
} from '@/store/modules/workflow/action_types';
export default {
  data() {
    return {
      showConfirm: false,
    };
  },
  props: {
    item: Object,
    index: Number,
    type: String,
    appId: String,
  },
  methods: {
    closeDialog() {
      this.showConfirm = false;
    },
    copy() {
      this.$store.dispatch(DUPLICATE_WORKFLOW, {
        workflow: this.item,
        index: this.index,
        type: this.type,
        appId: this.appId,
        name: `${this.item.name} - ${this.$t('workflow.label.copy')}`,
      });
      this.closeDialog();
    },
  },
};

</script>

<style scoped>
  .w-control-remove-btn-confirm {
    color: white;
  }
</style>
