<template>
<div>
  <v-dialog v-model="showingDialog"
  v-if="showingDialog"
            :max-width="options.width"
            :style="{ zIndex: options.zIndex }"
            @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn v-for="btn in buttons"
               :key="btn.command"
               :class="btn.class"
               class="min-width-100"
               text
               @click.native="onClick(btn.command)">
          {{ $t(btn.labelTag) }}
        </v-btn>
        <!--<v-btn -->
            <!--class="primary darken-1" text @click.native="agree">{{ $vuetify.lang.t('$vuetify.buttons.ok') }}</v-btn>-->
        <!--<v-btn class="muted" text @click.native="cancel">{{ $vuetify.lang.t('$vuetify.buttons.cancel')}}</v-btn>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>


<script>
  /*
  buttons = {
    'ok': { labelTag: 'buttons.ok', command: 'agree', class: 'primary darkne-1'},
    'cancel': { labelTag: 'buttons.cancel', command: 'cancel', class: 'muted'},
    'close': { labelTag: 'buttons.close', command: 'close', class: 'primary darken-1'}
  }

  */
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  name: 'infoDialog',
  data: () => ({
    showingDialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      buttons: ['ok', 'cancel'],
      color: 'primary',
      width: 290,
      zIndex: 200
    },
    buttons: [],
    BUTTON_DEFS: {
      'ok': { labelTag: 'buttons.ok', command: 'agree', class: 'primary darknen-1'},
      'cancel': { labelTag: 'buttons.cancel', command: 'cancel', class: 'muted'},
      'close': { labelTag: 'buttons.close', command: 'close', class: 'muted'}
    }

  }),
  methods: {
    open (title, message, options) {
      const vm = this
      console.log('infoDialog.open')
      vm.showingDialog = true
      switch (title) {
        case 'warning':
          vm.title = vm.$t('general.warning')
          break
        default:
          vm.title = title
      }
      vm.message = message
      vm.options = Object.assign(vm.options, options)
      vm.buttons = []
      for (let i = 0; i < vm.options.buttons.length; i++) {
        const loopButton = vm.options.buttons[i]
        vm.buttons.push(vm.BUTTON_DEFS[loopButton])
      }
      return new Promise((resolve, reject) => {
        vm.resolve = resolve
        vm.reject = reject
      })
    },
    onClick (command) {
      const vm = this
      console.log('onClick command = ' + command)
      switch (command) {
        case 'ok':
        case 'close':
          vm.resolve(true)
          vm.showingDialog = false
          break
        case 'cancel':
          vm.resolve(false)
          vm.showingDialog = false
          break
      }
    }
    // agree() {
    //   this.resolve(true)
    //   this.showingDialog = false
    // },
    // cancel() {
    //   this.resolve(false)
    //   this.showingDialog = false
    // }
  }
}
</script>
