<template>
  <div>
    <div v-if="showAccessDenied">
      <div class="font-weight-black" style="transform: translate(0, 80%)">
        <div class="py-4" style="text-align: center">
          <img width="90px" :src="require('@/assets/timeout.png')" />
        </div>
        <div style="white-space: pre-line; text-align: center; font-size: 16px">
          <div>System timeout, please reload</div>
          <div>系統超時，請重新載入</div>
        </div>
      </div>
    </div>
    <div v-else-if="notFound">
      <div class="font-weight-black" style="transform: translate(0, 80%)">
        <div class="py-4" style="text-align: center">
          <img width="90px" :src="require('@/assets/invalidShareLink.png')" />
        </div>
        <div style="white-space: pre-line; text-align: center; font-size: 16px">
          <div>This link is expired, please ask the owner for a new link</div>
          <div>此連結已過期，請向所有者索取新連結</div>
        </div>
      </div>
    </div>
    <div v-else-if="havePassword && !verified">
      <password-input-dialog
        v-model="havePassword"
        @onCommand="onCommandHandler"
      >
      </password-input-dialog>
    </div>
    <div v-if="verified">
      <background
        v-if="publicForm"
        :color="borderColor"
        :headerColor="themeColor"
        :showHeader="showHeader"
        :imageUrl="
          publicForm.backgroundImage ? publicForm.backgroundImage.imageUrl : ''
        "
      >
        <template v-slot:header-control>
          <v-row no-gutters class="header-control">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attr }">
                <v-btn
                  v-if="isPreview"
                  v-on="on"
                  v-bind="attr"
                  @click="submitted = !submitted"
                  color="primary"
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                </v-btn>
              </template>
              {{
                submitted
                  ? $t("publicForm.swapToNotSubmitted")
                  : $t("publicForm.swapToSubmitted")
              }}
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:card-content>
          <formCard
            v-if="publicForm"
            :publicForm="publicForm"
            :isEdit="false"
            :color="themeColor"
            :showSubmit="!submitted && (isPreview || isActive)"
            :isSubmitting="isSubmitting"
            :isSubmitDisabled="uploading"
            @commandHandler="onCommandHandler"
          >
            <template v-slot:card-header>
              <v-row
                v-if="!(isPreview || isActive)"
                no-gutters
                class="pa-4"
                align="center"
                justify="center"
                style="min-height: 540px"
              >
                <v-col style="text-align: center">
                  <div>
                    <v-icon size="108" color="error"
                      >mdi-information-outline</v-icon
                    >
                  </div>
                  {{ $t("publicForm.notActive") }}
                </v-col>
              </v-row>
              <v-row
                v-else-if="submitted"
                no-gutters
                class="pa-4"
                align="center"
                justify="center"
              >
                <v-col style="text-align: center">
                  <div>
                    <v-icon size="108" color="primary"
                      >mdi-checkbox-marked-circle-outline</v-icon
                    >
                  </div>
                  <div>
                    {{ $t("publicForm.submitted") }}
                  </div>
                  <v-btn text color="primary" @click="reset">{{
                    $t("publicForm.submitAgain")
                  }}</v-btn>
                  <div v-if="publicForm.successMsg">
                    <v-divider />
                    <div class="ql-editor pa-4">
                      <div v-html="publicForm.successMsg"></div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:card-content>
              <v-form
                ref="form"
                class="flex-grow-1 d-flex flex-column"
                v-if="!submitted && (isPreview || isActive)"
              >
                <div class="mr-3 mb-3 flex-grow-1 px-5">
                  <v-row
                    no-gutters
                    v-for="(row, index) in publicForm.layout"
                    :key="index"
                  >
                    <template v-for="fieldInfo in getFieldInfosInRow(row)">
                      <input-widget
                        :ref="fieldInfo.fieldId"
                        :key="fieldInfo._id"
                        :formInfo="form"
                        class="py-3"
                        :alias="getAlias(fieldInfo._id)"
                        :customDescription="getDescription(fieldInfo._id)"
                        :customizedProperties="
                          getCustomFieldProperties(fieldInfo._id)
                        "
                        :recordData="recordData"
                        :relatedTableInfos="relatedTableInfos"
                        :relatedTableData="relatedTableData"
                        :fieldInfo="fieldInfo"
                        @onCommand="onCommandHandler"
                      ></input-widget>
                    </template>
                  </v-row>
                </div>
              </v-form>
            </template>
          </formCard>
          <span class="caption">
            <i18n path="general.powerBy">
              <a href="https://yoov.com/" target="_blank">YOOV</a>
            </i18n>
          </span>
        </template>
        <confirmDialog ref="confirmDialog" />
        <FsLightbox
          :toggler="toggler"
          :sources="imageSource"
          :onClose="onLightBoxClose"
          :exitFullscreenOnClose="true"
        />
        <v-menu top offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon fab v-on="on" fixed dark bottom right
              ><v-icon>mdi-translate</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item-group v-model="$i18n.locale">
              <v-list-item
                v-for="locale in locales"
                :key="locale"
                link
                :value="locale.value"
              >
                {{ locale.text }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <child-record-dialog ref="childRecordDialog"></child-record-dialog>
      </background>
    </div>
    <v-snackbar
      v-model="showToast"
      color="error"
      top
      elevation="0"
      timeout="2000"
      id="passwordToast"
    >
      <div class="d-flex justify-center" style="font-size: 14px">
        {{ toastMsg }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue";

import background from "@/components/public/background";
import formCard from "@/components/public/formCard";
import inputWidget from "@/components/inputWidgets/InputWidget";
import confirmDialog from "@/components/dialogs/ConfirmDialog";
import childRecordDialog from "@/components/dialogs/ChildRecordDialogPublic";
import { ValidateRule } from "@/helpers/RuleHelper";
import { newBlankRecord } from "@/helpers/FormHelpers";
import locales from "@/const/locales";

import PasswordInputDialog from "../forms/dialogs/PasswordInputDialog";
import publicFormMixin from "@/mixins/publicFormMixin";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
  mixins: [publicFormMixin],
  components: {
    background,
    formCard,
    inputWidget,
    confirmDialog,
    FsLightbox,
    childRecordDialog,
    PasswordInputDialog,
    dialogHeader,
  },
  data() {
    return {
      loading: false,
      havePassword: false,
      verified: false,
      publicForm: null,
      isPreview: false,
      recordData: {
        _id: "",
      },
      submitted: false,
      relatedTableData: {},
      locales: locales,
      isSubmitting: false,
      uploading: false,
      isActive: true,
      notFound: false,
      showToast: false,
      toastMsg: "",
      showAccessDenied: false,
      isPublicEdit: true,
    };
  },
  computed: {
    form() {
      return this.$store.getters.currentForm;
    },
    imageUrl() {
      return this.publicForm && this.publicForm.backgroundImage
        ? this.$store.getters.appHost +
            "/medias/" +
            this.publicForm.backgroundImage +
            "/thumbnail"
        : "";
    },
    fieldInfos() {
      return this.form ? this.form.fieldInfos : [];
    },
    uiElementInfos() {
      return this.form ? this.form.uiElementInfos : [];
    },
    isPreview() {
      return this.$route.name === "PreviewPublicForm";
    },
    editableFieldIds() {
      let result = [];
      if (!this.publicForm) return result;
      else {
        const visibleFields = this.publicForm.layout.flat(2);
        visibleFields.forEach((layoutItem) => {
          const fieldInfo = this.fieldInfos.find(
            (info) => info._id === layoutItem._id
          );

                    if (
                        fieldInfo &&
                        ((fieldInfo.properties.hasOwnProperty("authorization") &&
                        !fieldInfo.properties.authorization.includes("readonly") &&
                        !fieldInfo.properties.authorization.includes("hidden"))|| 
                        (fieldInfo.properties.validation &&
                        fieldInfo.properties.validation.includes('required')))
                    ) {
                        result.push(fieldInfo._id);
                    }
                    });
                    return result;
                }
            },
            isActive() {
                return (
                    this.publicForm &&
                    this.publicForm.active &&
                    this.publicForm.form !== null
            );
            },
            relatedTableInfos() {
                return this.$store.getters.relatedTableInfos;
            },
            toggler() {
                return this.$store.state.media.toggle;
            },
            imageSource() {
                return this.$store.state.media.imageUrls;
            },
            displayRule() {
                return this.$store.getters.currentForm.displayRule;
            },
        },
        async mounted(){
            await this.init()
        },
        watch: {
            recordData: {
                handler() {
                    let vm = this;
                    if (this.displayRule)
                    this.displayRule
                        .filter((item) => item.active)
                        .forEach((rule) => {
                        let bl_pass = false;
                        var conditions = rule.conditions;
                        bl_pass = conditions.some((orConds) => {
                            var res = orConds.every((andCond) => {
                            var hostField = vm.recordData[andCond.fieldId];
                            var compareField =
                                andCond.content.type == 1
                                ? andCond.content.value
                                : vm.recordData[andCond.content.value];
                            return ValidateRule(
                                hostField,
                                compareField,
                                andCond.content.rule,
                                andCond.fieldType
                            );
                            });
                            if (res) return true;
                        });
                        // action implement if rule return true
                        rule.actions.forEach((action) => {
                            switch (action.actionId) {
                            case "1":
                                vm.handleActionVisible(action.fields, bl_pass);
                                break;
                            case "2":
                                vm.handleActionHidden(action.fields, bl_pass);
                                break;
                            case "3":
                                //vm.handleActionAlert(action.fields, bl_pass);
                                break;
                            }
                        });
                        });
                },
                deep: true,
            },
        },
  methods: {
    async init() {
      this.loading = true;
      sessionStorage.removeItem("token");
      const result = await this.$store.dispatch("FETCH_PUBLIC_EDIT_ACCESS", {
        urlId: this.$route.params.urlId,
      });
      if (result) {
        this.havePassword = result.havePassword || false;
        if (!this.havePassword)
          this.submitPassword({
            password: "",
            urlId: this.$route.params.urlId,
          });
      } else {
        this.notFound = true;
      }
      this.loading = false;
    },
    async reset() {
      this.submitted = false;
      this.havePassword = false;
      this.verified = false;
      this.$nextTick(async () => {
        await this.init();
      });
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "submitPassword":
          console.log("payload", payload);
          this.submitPassword({
            password: payload.value,
            urlId: this.$route.params.urlId,
          });
          break;
        case "openChildRecordDialog":
          this.openChildRecordDialog(payload);
          break;
        case "updateFieldValue":
          this.updateFieldValue(payload);
          break;
        case "confirm":
          this.onSubmit();
          break;
        case "startUploading":
          this.uploading = true;
          break;
        case "finishUploading":
          this.uploading = false;
          break;
      }
    },
    async submitPassword(value) {
      const { verified, editTemplate, result, accessToken } =
        await this.$store.dispatch("SUBMIT_PUBLIC_EDIT_PASSWORD", value);
      if (verified) {
        await this.$store.dispatch("FETCH_PUBLIC_WIDGETS");
        this.verified = verified;
        this.publicForm = editTemplate;
        this.recordData = result;
        sessionStorage.token = accessToken;
      } else {
        this.toastMsg = this.$t("publicEdit.incorrectPassword");
        this.showToast = true;
        this.$nextTick(() => {
          const app = document.getElementById("app");
          const passwordToast = document.getElementById("passwordToast");
          app.append(passwordToast);
        });
      }
    },
    getFieldInfosInRow(row) {
      const result = row.map((item) => {
        let result =
          item.controlType === "static"
            ? this.uiElementInfos.find((info) => info._id === item._id)
            : this.fieldInfos.find((info) => info._id === item._id);

        result.properties.width = item.width;
        return result;
      });
      return result;
    },
    getAlias(fieldId) {
      return this.publicForm.customFieldProperties &&
        this.publicForm.customFieldProperties[fieldId]
        ? this.publicForm.customFieldProperties[fieldId].alias
        : "";
    },
    getDescription(fieldId) {
      if (
        this.publicForm.customFieldProperties &&
        this.publicForm.customFieldProperties[fieldId] &&
        this.publicForm.customFieldProperties[fieldId].hasOwnProperty(
          "description"
        )
      ) {
        return this.publicForm.customFieldProperties[fieldId].description;
      } else null;
    },
    getCustomFieldProperties(fieldId) {
      return this.publicForm.customFieldProperties &&
        this.publicForm.customFieldProperties[fieldId]
        ? this.publicForm.customFieldProperties[fieldId]
        : {};
    },
    async validateAll() {
      const vm = this;
      const fieldIds = vm.editableFieldIds;
      let errors = 0;

      let promises = [];
      fieldIds.forEach((f) => {
        if (vm.$refs[f] && vm.$refs[f][0]) {
          promises.push(() => vm.$refs[f][0].validate());
        }
      });

      const arrayOfPromises = promises.map((promise) => promise());
      const results = await Promise.all(arrayOfPromises);
      errors = results.filter((result) => !result).length;
      return errors;
    },
    updateFieldValue(payload) {
      this.$set(this.recordData, payload.fieldName, payload.fieldValue);
    },
    async onSubmit() {
      if (this.$route.name === "PreviewPublicForm") {
        this.$refs.confirmDialog.open(
          this.$t("publicForm.preview"),
          this.$t("publicForm.previewError")
        );
      } else {
        await this.save();
      }
    },
    async save() {
      const errors = await this.validateAll();
      if (errors === 0) {
        this.isSubmitting = true;
        const url =
          this.$store.getters.appHost +
          "/public/edit/save/" +
          this.$route.params.urlId;
        const data = JSON.parse(JSON.stringify(this.recordData));
        Object.assign(data, {
          formId: this.publicForm.formId,
          appId: this.publicForm.appId,
        });
        const headers = {
          headers: {
            Authorization: `Bearer ${sessionStorage.token}`,
          },
        };
        const postData = { data };
        await this.axios
          .post(url, postData, headers)
          .then((res) => {
            if (res) {
              this.submitted = true;
              localStorage.setItem(
                `${this.$route.params.urlId}_lastSubmitted`,
                new Date().toISOString()
              );
            }
          })
          .catch((error) => {
            const errorCode = error.response.status;
            if (errorCode) {
              if (this.$route.name !== "errors") {
                this.showAccessDenied = true;
                // this.$router.replace({
                //   name: 'errors',
                //   params: {
                //     errorCode
                //   }
                // })
              }
            }
          });
        this.isSubmitting = false;
      }
    },
    handleActionVisible(fields, bl_pass) {
      let vm = this;
      fields.forEach((fieldId) => {
        var res = vm.fieldInfos
          .concat(vm.uiElementInfos)
          .find((field) => field._id == fieldId);
        if (res) {
          var authorization = [];
          if (res.properties.authorization)
            authorization = res.properties.authorization.split("||");
          if (authorization.length == 1 && authorization[0] == "")
            authorization = [];
          if (bl_pass) {
            var pos = authorization.indexOf("hidden");
            if (pos != -1) {
              authorization.splice(pos, 1);
            }
          } else {
            if (!authorization.includes("hidden")) authorization.push("hidden");
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, "authorization", authorization.join("||"));
        }
      });
    },
    handleActionHidden(fields, bl_pass) {
      let vm = this;
      fields.forEach((fieldId) => {
        console.log(fieldId);
        var res = vm.fieldInfos
          .concat(vm.uiElementInfos)
          .find((field) => field._id == fieldId);
        if (res) {
          var authorization = [];
          if (res.properties.authorization)
            authorization = res.properties.authorization.split("||");
          // var authorization = res.properties.authorization.split("||")
          if (authorization.length == 1 && authorization[0] == "")
            authorization = [];
          if (bl_pass) {
            if (!authorization.includes("hidden")) authorization.push("hidden");
          } else {
            var pos = authorization.indexOf("hidden");
            if (pos != -1) {
              authorization.splice(pos, 1);
            }
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, "authorization", authorization.join("||"));
        }
      });
    },
    openChildRecordDialog(payload) {
      this.$refs.childRecordDialog.open(payload);
    },
  },
};
</script>
