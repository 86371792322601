var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.allowAdd)?_c('v-combobox',{ref:("input_wid_selection_" + _vm.fieldId),staticClass:"vxe-table--ignore-clear workflow-customized-form-record-element",attrs:{"items":_vm.isLayout? _vm.items:_vm.options,"hide-no-data":!_vm.search,"search-input":_vm.search,"small-chips":"","outlined":"","hide-details":"","dense":"","menu-props":{ contentClass: 'vxe-table--ignore-clear' },"auto-select-first":"","item-text":"label","item-value":"_id","background-color":"white","multiple":_vm.multiple},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.addNew}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('messages.noResultMatching',{search: _vm.search}))+" "),_c('i18n',{attrs:{"path":"messages.pressKey"}},[_c('kbd',[_vm._v("enter")])])],1)],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('option-button',{staticClass:"cursor-pointer pa-1",attrs:{"option":_vm.getOption(item),"deletableChips":"","useColor":_vm.useColor,"toggleType":_vm.multiple ? 'multiple' : 'single'},on:{"onDelete":_vm.onDelete}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('option-button',{staticClass:"cursor-pointer",attrs:{"option":_vm.getOption(item),"useColor":_vm.useColor,"toggleType":_vm.multiple ? 'multiple' : 'single'}})]}},{key:"append-item",fn:function(){return [(_vm.showAppend)?_c('v-divider'):_vm._e(),(_vm.showAppend)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('messages.addNewOptions', {search: _vm.search}))+" ")]):_vm._e()]},proxy:true}],null,false,3719901520),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}):_c('v-autocomplete',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],ref:"autoComplete",attrs:{"items":_vm.isLayout? _vm.items:_vm.options,"item-text":"label","item-value":"_id","small-chips":"","outlined":"","search-input":_vm.search,"dense":"","hide-details":"","background-color":"white","multiple":_vm.multiple,"menu-props":{ contentClass: 'vxe-table--ignore-clear' }},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('messages.noResultMatching',{search: _vm.search}))+" ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('option-button',{staticClass:"cursor-pointer pa-1",staticStyle:{"width":"-webkit-fill-available"},attrs:{"option":_vm.getOption(item),"deletableChips":"","useColor":_vm.useColor,"toggleType":_vm.multiple ? 'multiple' : 'single'},on:{"onDelete":_vm.onDelete}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('option-button',{staticClass:"cursor-pointer",attrs:{"option":_vm.getOption(item),"useColor":_vm.useColor,"toggleType":_vm.multiple ? 'multiple' : 'single'}})]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})}
var staticRenderFns = []

export { render, staticRenderFns }