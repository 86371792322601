import Vue from 'vue'
import VueGtm from 'vue-gtm'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
// import * as VueGoogleMaps from 'gmap-vue'
import * as GmapVue from 'gmap-vue';
import VueSignaturePad from 'vue-signature-pad'
import VueSimpleAlert from 'vue-simple-alert'
import VueFlashMessage from '@smartweb/vue-flash-message';
import VueMeta from 'vue-meta'
import { VPopover } from 'v-tooltip'
import Notifications from 'vue-notification'
import {Sensors} from './plugins/sensors';
import Quill from 'quill'

import CustomActionProgressNotificationContent from '@/pages/admin/workflow/components/notification/customActionButton/content';

import PerfectScrollbar from "vue2-perfect-scrollbar";
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

// moment locale pack
require('moment/locale/es');
require('moment/locale/zh-cn');
require('moment/locale/zh-hk');

// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

// import VueSocketIO from 'vue-socket.io'

// window.$ = window.jQuery = $;

// Internationalization
import i18n from './lang/lang.js'
// import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
// import zhHK from 'vxe-table/lib/locale/lang/zh-HK'
// import enUS from 'vxe-table/lib/locale/lang/en-US'

import VueGoodTablePlugin from 'vue-good-table'
import ToggleButton from 'vue-js-toggle-button'

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

import 'vue-swatches/dist/vue-swatches.css'

import vueSelect from 'vue-select'
import 'vue-select/src/scss/vue-select.scss'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'



// Global Styles
import '@/assets/scss/global.scss';
import '@/assets/scss/easy_dnd.scss';
import '@/assets/scss/workflow.scss';
import '@/assets/scss/workform.scss';
import '@/assets/scss/mobile/global.scss';
import '@/assets/scss/utilities.scss';
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
Vue.use(VueCodemirror)
Vue.use(VueGoodTablePlugin)
Vue.use(ToggleButton)
Vue.use(VPopover)
Vue.use(Notifications)
Vue.use(VueFlashMessage, {
  strategy: 'multiple',
});

Vue.component('CustomActionProgressNotificationContent', CustomActionProgressNotificationContent);
// fort awesome fonts
// import { library, dom } from '@fortawesome/fontawesome-svg-core'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
//
// dom.watch()
// library.add(fas)
// library.add(far)

Vue.config.productionTip = false
// Vue.use(VueClickoutside)
// Vue.use(elSelect)
Vue.use(VueAxios, axios)
// Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-select', vueSelect)
Vue.use(PerfectScrollbar);

Vue.use(VueMoment, { moment })
Vue.use(vuetify, {
  // icons: {
  //   iconfont: 'md',
  //   'checkboxOn': 'far fa-check-square',
  //   'checkboxOff': 'far fa-square'
  // },
  options: {
    customProperties: true
  }
})

Vue.use(VueSimpleAlert)

if ( process.env.VUE_APP_GTM_ID ) {
  Vue.use(VueGtm, {
    id: `GTM-${process.env.VUE_APP_GTM_ID}`,
    debug: false, 
    vueRouter: router,
    defer: false,
    loadScript: true,
    enabled: true
  })
}

// Vue.use(panZoom, {
//   selector:'.zoomable',
//   minZoom: 1,
//   maxZoom: 1,
//   initialX: 0,
//   initialY: 0,
//   initialZoom: 1,
//   zoomSpeed: 0.00001,
//   transformOrigin: {x: 0, y: 0},
//   boundsPadding: 0,
//   smoothScroll: false,
//   animate: false

// })

// Vue.use(VueDragscroll)

/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
Vue.filter('round', function(value, decimals) {
  // alert('filter(round)')
  var result = value
  if(!value) {
    value = 0;
  }

  if(decimals) {
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    result = value.toFixed(decimals)
  }
  return result
});
VXETable.setup({
  keepSource: true
})

Vue.use(VXETable, {
  i18n: (key, args) => i18n.t(key, args),
  translate: (key, args)=>{
    if (key && key.indexOf('widgets.properties') > -1){
      return i18n.t(key, args)
    }
    return key 
  }
  // translate: (key, args) => i18n.t(key, args)
})

Vue.use(VueToast, {
  position: 'top-right',
  dismissible: true,
  pauseOnHover: true
})


Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places',
    region: 'HK',
    // language: 'zh-HK',
    language: i18n.locale
  },
  installComponents: true
})

Vue.use(VueSignaturePad)

Vue.use(VueMeta, {
  refreshOnceOnNavigation:true
})
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}

Vue.prototype.$sensors = Sensors;

store.dispatch('FETCH_CONSTANTS')

let Link = Quill.import('formats/link');
class CustomLink extends Link {
  static sanitize(url) {
    let value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
        if(value.startsWith(this.PROTOCOL_WHITELIST[i]))
          return value;
        return `http://${value}`
    }
    return value;
  }
}
Quill.register(CustomLink);

export const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
