<template>
  <v-list>
    <v-subheader>{{ $t("publicForm.queryParam") }}</v-subheader>
    <v-list-item>
      <v-list-item-content class="grey--text text--darken-1">
        {{ $t("publicForm.queryDescription") }}
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="label grey--text text--darken-1">
          {{ $t("publicForm.selectTextField") }}
        </v-list-item-title>
        <selectField
          :items="getTextFields(publicForm.sourceFieldId)"
          :value="publicForm.sourceFieldId"
          @clear="saveFieldId(null, 'sourceFieldId')"
          @input="saveFieldId($event, 'sourceFieldId')"
          @add="openNewFieldDialog($t('publicForm.source'), 'sourceFieldId')"
        />
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="label grey--text text--darken-1">
          {{ $t("publicForm.generateLink") }}
        </v-list-item-title>
        <v-col class="pa-0">
          <v-row no-gutters align="center">
            <v-text-field
              hide-details
              dense
              outlined
              v-model="source"
              style="max-width:200px"
              class="my-2"
            ></v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on, attr }">
                <v-btn
                  color="primary"
                  icon
                  @click="addSource"
                  v-on="on"
                  v-bind="attr"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              {{ $t("publicForm.generateLink") }}
            </v-tooltip>
          </v-row>

          <v-list>
            <v-list-item
              v-for="(source, index) in publicForm.sources"
              :key="source"
              class="px-0"
            >
              {{ index + 1 }}
              <url-box :url="generateURL(source)">
                <template v-slot:prepend-outer>
                  <url-box
                    :url="source"
                    :canCopy="false"
                    maxWidth="100px"
                    class="mx-1"
                    noQRCode
                  />
                </template>
                <template v-slot:prepend-copy>
                  <div class="px-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attr }">
                        <v-btn
                          v-on="on"
                          v-bind="attr"
                          icon
                          small
                          @click="deleteSource(index)"
                          color="error"
                        >
                          <v-icon small>
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t("buttons.delete") }}
                    </v-tooltip>
                  </div>
                </template>
              </url-box>
            </v-list-item>
          </v-list>
        </v-col>
      </v-list-item-content>
    </v-list-item>
    <v-divider />

    <v-subheader>{{ $t("publicForm.deviceInfo") }}</v-subheader>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ $t("publicForm.deviceDescription") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-for="select in selectFields" :key="select.key">
      <v-list-item-content>
        <v-list-item-title class="label grey--text text--darken-1">
          {{ select.label }}
        </v-list-item-title>
        <selectField
          :items="getTextFields(publicForm[select.key])"
          :value="publicForm[select.key]"
          @input="saveFieldId($event, select.key)"
          @add="openNewFieldDialog(select.default, select.key)"
        />
      </v-list-item-content>
    </v-list-item>

    <newFieldDialog ref="newFieldDialog" @confirm="addTextField" />
  </v-list>
</template>
<script>
import newFieldDialog from "./newFieldDialog";
import mixin from "./mixin";
import selectField from "./selectField";
import urlBox from "./urlBox";
import publicFormMixin from "@/mixins/publicFormMixin";

export default {
  mixins: [mixin, publicFormMixin],

  components: {
    newFieldDialog,
    selectField,
    urlBox,
  },
  data() {
    return {
      showNewFieldDialog: false,
      source: "",
      selectFields: [
        {
          key: "ipFieldId",
          label: this.$t("publicForm.ip"),
          default: this.$t("publicForm.ip"),
        },
        {
          key: "browserFieldId",
          label: this.$t("publicForm.browser"),
          default: this.$t("publicForm.browser"),
        },
        {
          key: "deviceFieldId",
          label: this.$t("publicForm.device"),
          default: this.$t("publicForm.device"),
        },
        {
          key: "osFieldId",
          label: this.$t("publicForm.os"),
          default: this.$t("publicForm.os"),
        },
      ],
    };
  },
  computed: {
    textFields() {
      const fieldInfos = this.$store.getters.formFieldInfos;
      if (!fieldInfos) return [];
      else {
        return fieldInfos.filter(
          (info) =>
            info.type === "text" &&
            !this.publicForm.hiddenFieldIds.includes(info._id)
        );
      }
    },
  },
  methods: {
    getTextFields(id) {
      const fieldInfos = this.$store.getters.formFieldInfos;
      if (id) {
        return fieldInfos.filter(
          (info) =>
            info.type === "text" &&
            (info._id === id ||
              !this.publicForm.hiddenFieldIds.includes(info._id))
        );
      } else {
        return fieldInfos.filter(
          (info) =>
            info.type === "text" &&
            !this.publicForm.hiddenFieldIds.includes(info._id)
        );
      }
    },
    openNewFieldDialog(defaultValue = "", type) {
      this.$refs.newFieldDialog.open(defaultValue, type);
    },
    addTextField({ label, type }) {
      this.$store.dispatch("APPEND_WIDGET_TO_LAYOUT", {
        widgetKey: "text",
        options: {
          label,
          properties: {
            fieldName: label,
          },
        },
      });

      this.$store.dispatch("SAVE_FORM").then(() => {
        const field = this.$store.getters.formFieldInfos.find(
          (field) => field.label === label
        );
        if (field) {
          this.saveFieldId(field._id, type);
        }
      });
    },
    saveFieldId(val, propKey) {
      this.$emit("commandHandler", {
        command: "saveFieldId",
        propKey,
        val,
      });
    },
    generateURL(source) {
      return this.publicUrl + `?source=${encodeURIComponent(source)}`;
    },
    addSource() {
      if (this.source) {
        this.save([...this.publicForm.sources, this.source], "sources");
        this.source = "";
      }
    },
    deleteSource(index) {
      this.save(
        this.publicForm.sources.filter((_, arrIndex) => arrIndex !== index),
        "sources"
      );
    },
  },
};
</script>

<style scoped></style>
