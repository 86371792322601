<template>
    <div class="">
        <quill-editor 
            :ref="'quill_'+fieldId" 
            class="witRichTextEditor"
            :class="className"
            :value="value"
            @change="$emit('input',$event)"
            :options="editorOption"
            :disabled="disabled"
        />
        <!-- id="getFile" -->
        <input v-if="!layout" type="file" accept="image/gif, image/png, image/jpeg" :id="'input_'+fieldId" :ref="'input_'+fieldId" @change="uploadFunction($event)" style="display: none;"/>
    </div>
</template>

<script>

import { Quill, quillEditor } from "vue-quill-editor";
import MediaHelper from '@/helpers/MediaHelper';
import ImageBlot from './quillExtension/ImageBlot';

export default {
    components: {
        quillEditor,
    },
    props:{
        value: String,
        className: String,
        layout:{
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isPanel:{
            type: Boolean,
            default: false
        },
        isWorkflow:{
            type: Boolean,
            default: false
        },
        appId: {
            type: String,
            default: ''
        },
        formId: {
            type: String,
            default: ''
        },
        fieldId:{
            type: String,
            default: '1'
        }
    },
    data(){
        let vm = this
        return{
            editorOption: {
                modules: {
                    toolbar: {
                        container: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'align': [] }],
                        [{ 'font': [] }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'color': [] }, { 'background': [] }],  
                        [{ 'header': 1 }, { 'header': 2 }],              
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],    
                        [{ 'indent': '-1'}, { 'indent': '+1' }],        
                        [{ 'direction': 'rtl' }],                    
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['link', 'image'],
                        ],
                        handlers: {
                            'image': function(){
                                vm.$refs['input_'+vm.fieldId].value = "";
                                vm.$refs['input_'+vm.fieldId].click()
                            },
                            'fieldlist': function() {
                                vm.showFieldList();
                            }
                        }
                    } 
                }
            },

        }
    },

    mounted () {
        Quill.register({'formats/imageBlot': ImageBlot});
        let editor = this.$refs['quill_' + this.fieldId].quill;
        editor.setContents(editor.clipboard.convert(this.value || ""), 'silent');
    },
    methods:{
        showFieldList() {
            const vm = this;
            this.$emit('onCommand', {
                command: 'showFieldSelection',
                onSuccess: (text) => {
                    console.log('onSuccess: text = ' + text);
                    vm.insertText(text);
                }
            })
        },
        getTempId () {
            return "_" + Date.now().toString();
        },

        insertText(text) {
            console.log('insertText text = ' + text);
            var editor = this.$refs['quill_'+this.fieldId].quill
            const range = editor.getSelection();
            editor.insertText(range.index, text);        
        },

        uploadFunction(e){
            const file = e.target.files[0];
            let vm = this
            if (file) {
                this.uploadImage(file).then(result => {
                    var editor = this.$refs['quill_'+vm.fieldId].quill
                    const range = editor.getSelection();
                    editor.insertEmbed(range.index, 'imageBlot', {
                        src: result.thumbnailUrl,
                        mediaId: vm.getTempId(),
                        objectKey: result.objectKey,
                        originalName: result.originalName,
                        mediaType: result.mediaType
                    });
                });
            }
        },
        async uploadImage(file) {
            return new Promise((resolve, reject) => {
                MediaHelper.uploadFile({
                    appId: this.appId,
                    formId: this.formId,
                    file,
                    isPublic: true
                }, result => {
                    resolve(result);
                })
            })
        },

    }
}
</script>

<style scoped>
.general-quil {
    font-weight: normal !important;
}
</style>
