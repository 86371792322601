<template>
<v-row dense>
    <v-col cols="4" class="d-flex flex-row align-center">
        <label>{{ $t(labelTag) }}</label>
    </v-col>
    <v-col cols="8" class="d-flex flex-row align-center">
        <v-slider
        :hide-details="true"
        :value="value"
        min="0"
        ticks="always"
        max="10"
        @input="val=>$emit('input',val)"
        ></v-slider>
    </v-col>
</v-row>
</template>

<script>
export default {
    name: 'settingsSlider',
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        labelTag: String,
        value: Number
    }
}
</script>