const menu = [
  // {captionTag: 'edit', tagType: 'mdi-file-document-edit-outline', value: 'edit'},
  {captionTag: 'renameAndUpdateIcon', tagType: 'mdi-file-document-edit-outline', value: 'update'},
  {captionTag: 'duplicate', tagType: 'mdi-content-copy', value: 'duplicate'},
  {captionTag: 'moveTo', tagType: 'mdi-file-move-outline', value: 'move'},
  {captionTag: 'hiddenFromNavigation', tagType: 'mdi-eye-off-outline', value: 'hide'},
  {captionTag: 'delete', tagType: 'mdi-trash-can-outline', value: 'delete'}
]

export default menu
