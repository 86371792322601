<template>
  <div class="view-settings-table-settings fill-height flex-grow-1 d-flex flex-column">
    <div class="px-3 py-1">
      <div class="d-flex">
        <div class="mr-3 teal lighten-2" style="padding: 2px"/>
        <h4>{{ title }}</h4>
      </div>
    </div>
    <form-filter-list
        class="px-6"
        ref="formFilterList"
        :fieldInfos="fieldInfos"
        @addFilter="addFilterHandler"
        @deleteFilter="deleteFilterHandler"
        @deleteAllFilters="deleteAllFiltersHandler"
        @updateFilters="updateFiltersHandler"
        @updateFilterAndOr="updateFilterAndOrHandler"
        :withAndOrOption="filtersSettingName==='filters'"
        :filters="view[filtersSettingName]"
        :filterAndOr="view[filterAndOrSettingName]"></form-filter-list>
  </div>
</template>

<script>
import formFilterList from '@/components/FormFilterList'
import mixin from './mixin'

export default {
  name: 'viewSettingsFilters',
  mixins: [mixin],
  components: {
    formFilterList
  },
  props: {
    view: null,
    noValidation: {
      type: Boolean,
      default: false
    },
    labelTag: {
      type: String,
      default: ''
    },
    settingNames: {
      type: Array,
      default () {
        return [
          'filters',
          'filterAndOr'
        ]
      }
      // for normal filters (used by admin)
      // settingNames: {
      //    filters,
      //    filterAndOr
      // }
      //
      // for quick filters (used by user)
      // settingNames: {
      //    quickFilters,
      //    quickFilterAndOr
      // }
    }
  },
  computed: {
    title () {
      return this.labelTag ?
        this.$t(this.labelTag) :
        this.$t('view.filter')
    },
    fieldInfos () {
      return this.$store.getters.currentForm.fieldInfos
    },
    filtersSettingName () {
      return this.settingNames[0]
    },
    filterAndOrSettingName () {
      return this.settingNames[1]
    }

  },
  methods: {
    addFilterHandler (payload) {
      console.log('addFilterHandler: payload: ', payload)
      this.$emit('onCommand', {
        command: 'addFilter',
        fieldId: payload.fieldId,
        callback: payload.callback,
        filtersSettingName: this.filtersSettingName
      })
    },
    updateFiltersHandler (filters) {
      this.view[this.filtersSettingName] = filters
    },
    updateFilterAndOrHandler (filterAndOr) {
      this.$emit('onCommand', {
        command: 'updateViewSetting',
        settingName: this.filterAndOrSettingName,
        val: filterAndOr
      })
      // this.view[this.filterAndOrSettingName] = filterAndOr
    },
    deleteFilterHandler (filterIndex) {
      this.$emit('onCommand', {
        command: 'deleteFilter',
        filterIndex: filterIndex,
        filtersSettingName: this.filtersSettingName
      })
    },
    deleteAllFiltersHandler () {
      this.$emit('onCommand', {
        command: 'deleteAllFilters',
        filtersSettingName: this.filtersSettingName
      })
    },
    _checkErrorCount (state) {
      return this.noValidation ? 0 :
        this.$refs.formFilterList._checkErrorCount(state)
    }
  }
}
</script>
