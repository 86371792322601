<template>
  <div class="workflow-node-col">
    <div class="workflow-node-end">
      <div class="workflow-node-image" />
      <div class="workflow-node-title">
        {{ $t('workflow.label.endOfFlow') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>