import Vue from 'vue'
import * as types from './action_types'

const state = {
  INVALID_FILENAME_EXTENSION: [
    "json", "exe", "js"
  ],
  imageUrls:[],
  toggle: false
}

const getters = {
}

const mutations = {
  SET_IMAGES_URLS(state, payload){
    state.imageUrls = payload;
  },
  TOGGLE_FS_LIGHTBOX(state){
    state.toggle = !state.toggle
    var appWrapper = document.getElementsByClassName('v-application--wrap')[0]
    appWrapper.style.zIndex = (state.toggle) ? 999:null
  },
  RESET_MEDIA(state) {
    var appWrapper = document.getElementsByClassName('v-application--wrap')[0]
    appWrapper.style.zIndex = null
    Object.assign(state, {
      imageUrls:[]
    })
  }
}

const actions = {
  [types.UPLOAD_MEDIA] ({rootGetters, dispatch, state}, payload) {
    return new Promise((resolve, reject) => {


      var formData = new FormData()
      formData.append('appId', payload.appId)
      if (payload.parentId) formData.append('parentId', payload.parentId)
      for (var i= 0; i < payload.files.length; i++) {
        const loopFile = payload.files[i]
        const splitFileName = loopFile.name.split(".");
        
        if (state.INVALID_FILENAME_EXTENSION.includes(splitFileName[splitFileName.length - 1])){
          continue
        }
        formData.append('files', loopFile);
      }
      
      if (payload.isPublic){
        const url = rootGetters.appHost + "/public/uploadMedia";
        Vue.axios.post(url, formData, {
          headers : {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          }
        )
      }else {
        const postData = {
          urlCommand: '/medias',
          options: {
            'headers': {
              'Content-Type': 'multipart/form-data'
            }
          },
          data: formData
        }
        dispatch('AUTH_POST', postData).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      }

    })
  },

  [types.GET_RANDOM_LOGO] ({dispatch}) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: '/logo_images/random_one'
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  [types.SET_IMAGES_URLS]({commit}, payload){
    commit('SET_IMAGES_URLS', payload)
  },
  [types.TOGGLE_FS_LIGHTBOX]({commit}){
    commit('TOGGLE_FS_LIGHTBOX')
  },
  [types.RESET_MEDIA]({commit}){
    commit('RESET_MEDIA');
  },
}


export default {
  state,
  getters,
  mutations,
  actions
}
