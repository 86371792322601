<template>
<div>
  <div v-for="(timeSegment,index) in timeSegments"
    class="mb-2 pa-2 blue lighten-5"
    :key="index">
    <time-segment-comp
      ref="timeSegmentComp"
      :targetForm="targetForm"
      :timeSegment="timeSegment"
      @update="val=>updateTimeSegment(index,val)"></time-segment-comp>
  </div>
  <v-btn fab x-small color="primary"
    @click="addTimeSegment">
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</div>
</template>

<script>
import timeSegmentComp from './TimeSegmentComp'
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'

export default {
  name: 'timeSegmentList',
  mixins: [dateTimeHelperMixin],
  components: {
    timeSegmentComp
  },
  model: {
    prop: 'timeSegments',
    event: 'input'
  },
  props: {
    targetForm: Object,
    timeSegments: Array
  },
  computed: {
    DEFAULT_TIME_SEGMENT() {
      return {
        startTime: this.todayTime('YYYY-MM-DD 08:00'),
        endTime: this.todayTime('YYYY-MM-DD 20:00'),
        actions: [
          {
            dataType: 'time',
            fieldId: ''
          }
        ]
      }
    }
  },
  data () {
    return {
      datetime: ''
    }
  },
  methods: {
    addTimeSegment () {
      const vm = this
      const updated = JSON.parse(JSON.stringify(vm.timeSegments))
      updated.push(vm.DEFAULT_TIME_SEGMENT)
      vm.$emit('input', updated)
    },
    updateTimeSegment (index, val) {
      const updated = JSON.parse(JSON.stringify(this.timeSegments))
      updated[index] = val
      this.$emit('input', updated)
    },
    validate () {
      const vm = this
      var result = this.timeSegments.length > 0
      console.log('refs.timeSegmentComp.length = ' + this.$refs.timeSegmentComp.length)
      console.log('refs.timeSegmentComp: isArray: ' + Array.isArray(vm.$refs.timeSegmentComp))

      this.$refs.timeSegmentComp.forEach(f => {
        return result && !f.validate()
      })
      return result
    },
    resetValidation () {
      this.$refs.timeSegmentComp.forEach(f => {
        f.resetValidation()
      })
    }
  }
}
</script>