<template>
  <v-dialog
    v-model="showingDialog"
    scrollable
    width="1024"
    max-width="90%">
    <v-card min-height="700">
      <!-- <v-card-title class="headline grey lighten-2">
        {{ $t('myapps.appsSummary') }}
      </v-card-title> -->
      <dialog-header
        :label="$t('myapps.appsSummary')"
        @close="showingDialog = false"
        color="#E0E0E0"
        toolbarClass="grey--text"
      />
      <v-card-text class="px-0 team-authorization-setup-dialog-body position-relative">
        <LoadingIcon v-if="loading"/>
        <div v-else class="d-flex flex-column fill-height">
          <div class="flex-grow-0 w-100">
            <div class="d-flex flex-column">
              <div class="white d-flex flex-row justify-start justify-space-between px-2 mt-2">
                <v-card v-for="(info, index) in totalSummary" :key="index"
                  class="flex-grow-1 ma-2"
                  outlined
                  :color="info.color"
                  elevation="2">
                  <v-list-item two-line>            
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-h2 mb-1">
                        {{ info.value }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="white--text text-h5">
                        {{ info.title }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <div class="px-4">
                <div v-if="teams.length===1 && selectedTeam" class="d-flex flex-row justify-start align-center">
                  <v-img
                    color="primary"
                    :src="selectedTeam.logo"
                    width="36"
                    style="border: 1px solid rgba(240,240,240,.8);"
                    max-width="36"
                    class="mr-2 flex-grow-0"
                    height="36"
                    contain></v-img> 
                  <!-- <v-img :src="selectedTeam.logo"
                  width="36"
                  class="mr-2 flex-grow-0 rounded-0"
                  height="36"
                  contain></v-img> -->
                  <div class="flex-grow-1">
                    {{ selectedTeam.name }}
                  </div>
                </div>
                <v-select v-else v-model="selectedTeamId"
                  :items="teams"
                  solo
                  class="mt-2 white flex-grow-1"
                  style="z-index:1;"
                  height="48"
                  item-text="name"
                  :menu-props="{offsetY: true, contentClass: 'denseList'}"
                  item-value="_id"
                  :hide-details="true"
                  outlined>
                  <template v-slot:selection>
                    <div v-if="selectedTeam" class="d-flex flex-row align-center">
                      <v-img :src="selectedTeam.logo"
                      width="36"
                      class="mr-2"
                      height="36"
                      contain></v-img>
                      {{ selectedTeam.name }}
                    </div>
                  </template>
                  <template v-slot:item="{item, on, attrs}">
                    <div class="d-flex flex-row align-center">
                        <v-img :src="item.logo"
                        width="36"
                        class="mr-2"
                        height="36"
                        contain></v-img>
                      {{ item.name  }}
                    </div>
                  </template>
                </v-select>
                <div v-if="selectedTeamId" class="my-2">
                  <label>{{ $t('app.storage', [formatStorage(this.planInfo.usedStorage), formatStorage(this.planInfo.maxStorage)]) }} </label>
                 <v-progress-linear :value="storagePercent"></v-progress-linear>
                </div>
                <div v-if="selectedTeamId" class="d-flex flex-row justify-start justify-space-between mt-2">
                 
                  <v-card v-for="(info, index) in teamSummaryBlocks" :key="index"
                    class="flex-grow-1 ma-1"
                    outlined
                    :color="info.color"
                    elevation="2">
                      <div class="py-1 d-flex flex-row justify-end">
                        <v-list-item-title class="text-right pr-5 white--text text-h6">
                          {{ info.title }}
                        </v-list-item-title>                
                        <v-list-item-title class="text-left pl-5 white--text text-h3 mb-0 pa-0">
                          {{ info.count }}
                        </v-list-item-title>
                      </div>
                  </v-card>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-grow-1 px-4 fill-height" style="overflow-y: scroll;">
            <v-simple-table v-if="teamAppList.length > 0">
              <tr>
                <td v-for="col in summaryCols" :key="col.field"
                  class="text-center max-width-100 min-width-100">{{ col.title }}</td>
              </tr>
              <tr v-for="appInfo in teamAppList" :key="appInfo._id">
                <td v-for="col in summaryCols" :key="col.field" 
                  class="pa-1 max-width-100 min-width-100"
                  :class="{'text-center':col.align==='center'}">
                  <div v-if="col.type==='text'"
                    class="w-100">
                    {{ appInfo[col.field] }}
                  </div>
                  <div v-else-if="col.type==='creator'"
                    class="w-100">
                    <v-chip small color="primary">
                      <v-avatar left>
                        <v-icon>mdi-account</v-icon>
                      </v-avatar>
                      {{ appInfo[col.field].label }}
                    </v-chip>
                  </div>
                  <div v-else-if="col.type==='summary'"
                    class="w-100">
                    {{ appInfo['summaryInfo'][col.field] }}
                    <v-card class="white--text caption ma-0 w-100" style="background-color:#5d6372
;">
                      <div v-for="(label,index) in appInfo['summaryInfo'][col.labelField]"
                        class="px-1 w-100 text-left overflow-ellipsis"
                        :key="index">
                        <div class="d-inline-block text-right mr-1 lightgrey--text"
                          style="width:20px;">{{ index+1 }}.</div>
                          {{ label }}
                      </div>
                    </v-card>
                  </div>
                  <div class="w-100" 
                  v-else>undefined type: {{ col.type }}</div>
                </td>              
              </tr>
            </v-simple-table>
          </div>
        </div>
      </v-card-text>
      <!-- <v-divider class=""></v-divider> -->
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="min-width-80 mr-1" @click="close">{{ $t('buttons.close') }}</v-btn>
      </v-card-actions> -->
      <dialog-actions 
        :buttons="['close']"
        @onCommand="close">
      </dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import LoadingIcon from "@/components/global/loadingIcon";

export default {
  name: 'appsSummaryDialog',
  props: {
    value:Boolean
  },
  components: {
    dialogActions,
    dialogHeader,
    LoadingIcon
  },
  data () {
    return {
      selectedTeamId: '',
      totalSummary:[
        {title: this.$t('general.team'), color: 'primary', value: 0},
        {title: this.$t('general.apps'), color: 'success', value: 0},
        {title: this.$t('general.forms'), color: 'warning', value: 0},
      ],
      teamAppList: [],
      planInfo: {
        maxStorage:0,
        usedStorage:0
      },
      summaryCols:[
        {
          title: this.$t('general.application'),
          field: 'title',
          type: 'text',
          align: 'left'
        },
        {
          title: this.$t('general.creator'),
          field: 'owner',
          type: 'creator',
          align: 'center'
        },
        {
          title: this.$t('general.modules'),
          field: 'modules',
          type: 'text',
          align: 'center'
        },
        {
          title: this.$t('general.forms'),
          field: 'forms',
          labelField: 'formNames',
          type: 'text',
          align: 'center'
        },
        // {
        //   title: this.$t('general.dashboards'),
        //   field: 'dashboards',
        //   labelField: 'dashboardNames',
        //   type: 'summary',
        //   align: 'center'
        // },
        // {
        //   title: this.$t('general.backdrops'),
        //   field: 'backdrops',
        //   labelField: 'backdropNames',
        //   type: 'summary',
        //   align: 'center'
        // },
        // {
        //   title: this.$t('general.mapPanels'),
        //   field: 'mapPanels',
        //   labelField: 'mapPanelNames',
        //   type: 'summary',
        //   align: 'center'
        // },
        {
          title: this.$t('general.panels'),
          field: 'panels',
          labelField: 'panelNames',
          type: 'text',
          align: 'center'
        }
      ],
      teams:[],
      loading: false
    }
  },
  async mounted () {
    this.loading = true
    await this.getUserSummary();

    if (this.teams.find(team=>team._id ===this.lastTeamId))
      this.selectedTeamId = this.lastTeamId
    else this.selectedTeamId = this.teams.length > 0 ? this.teams[0]._id: null
    this.loading = false
  },
  computed: {
    lastTeamId () {
      return this.$store.getters.user.lastTeam
    },
    teamSummaryBlocks () {
      const vm = this      
      var result = []

        const appCount = this.teamAppList.length
        const moduleCount = this.teamAppList.reduce((acc, app)=> {
          acc += app.modules
          return acc
        }, 0)
        const formCount = this.teamAppList.reduce((acc, app)=> {
          acc += app.forms
          return acc
        }, 0)
        const panelCount = this.teamAppList.reduce((acc, app)=> {
          acc += app.panels
          return acc
        }, 0)
        result = [
          {title: vm.$t('general.apps'), count: appCount, color: 'success'},
          {title: vm.$t('general.modules'), count: moduleCount, color: 'error'},
          {title: vm.$t('general.forms'), count: formCount, color: 'warning'},
          {title: vm.$t('general.panels'), count: panelCount, color: 'secondary'}
        ]

      return result
    },
    selectedTeam () {
      return this.teams.find(team => team._id === this.selectedTeamId)
    },
    teamSummary () {
      const vm = this
      let result = null
      if (vm.allTeamSummary && vm.selectedTeamId) {
        result = vm.allTeamSummary.teams.find(team => team._id === vm.selectedTeamId)
      }
      return result
    },
    showingDialog: {
      get(){
        return this.value
      },
      set(val){
        this.$emit('input', val)
      }
    },
    storagePercent(){
      return this.planInfo.maxStorage !== 0 ? Math.ceil(this.planInfo.usedStorage / this.planInfo.maxStorage) : 0
    }
  },
  watch: {
    selectedTeamId: function(value){
      if (value){
        this.loadTeamSummary(value)
      }
    }
  },
  methods: {
    async getUserSummary(){
      const getParams = {
        urlCommand: '/teams/accountSummary'
      }

      const res = await this.$store.dispatch('AUTH_GET', getParams)
        if (res.result){
          this.totalSummary[0].value = res.result.teams.length
          this.totalSummary[1].value = res.result.apps
          this.totalSummary[2].value = res.result.forms
          this.teams = res.result.teams
        }
    },
    loadTeamSummary (teamId) {
      if (!teamId) return
      const getParams = {
        urlCommand: `/teams/summary?teamId=${teamId}`
      }
      this.$store.dispatch('AUTH_GET', getParams)
      .then(res => {
        this.teamAppList = res.apps
        this.$set(this.planInfo, "maxStorage", res.maxStorage)
         this.$set(this.planInfo, "usedStorage", res.usedStorage)
      })
    },
    close () {
      this.showingDialog = false
    },
    formatStorage(size){
      if (size < 1024) {
        return `${size} B`
      } else if (size < Math.pow(1024, 2)) {
        return `${size/1024} KB`
      } else if (size  < Math.pow(1024, 3)) {
        return `${size/Math.pow(1024, 2)} MB`
      } else return `${size/Math.pow(1024, 3)} GB`
    }
  }
}
</script>

<style>

.team-authorization-setup-dialog-body .v-data-table tr:nth-child(2n) {
  background-color: rgba(244,244,244);
}

.team-authorization-setup-dialog-body .team-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.team-authorization-setup-dialog-body .team-logo {
  width: 32px;
  height: 32px;  
}

.team-authorization-setup-dialog-body .overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

</style>