const mixin = {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    parentLabel: {
      type: String,
      default: ''
    },
    label: String,
    propertyConfig: Object,
    value: [Object, Array, String],
    propertyValues: Array,
    propertyKey: String,
    propertyDefault: [Object, Array, String],
    fieldInfoId: String, // for expression finding relevant fields, other than itself
    fieldType: String,

    masterFieldInfos: {
      type: Array,
      default () {
        return []
      }
    },

    currentFieldInfos: {
      type: Array,
      default () {
        return []
      }
    },
    ofChildTable: Boolean
  },
  computed: {
    parentChildRelationFieldIds () {
      return this.$store.getters.parentChildRelationFieldIds
    },
    isParentChildRelation () {
      const vm = this
      var result = false
      if (vm.fieldInfo) {
        result = vm.parentChildRelationFieldIds.includes(vm.fieldInfo.fieldId)
      }
      return result
    },
    formId () {
      return this.$route.params.itemId
    },
    fieldInfo () {
      const vm = this
      return vm.formFieldInfos.find(info => info._id === vm.fieldInfoId)
    },
    propertyDisabled () {
      const vm = this
      var result = false

      if (vm.isParentChildRelation && vm.propertyKey==='relatedRecordQuantity') {
        result = true
      }
      
      return result
    },
    widget () {
      const vm = this
      var result = null
      if (vm.fieldInfo) {
        result = this.$store.getters.allWidgets.find(w => w._id === vm.fieldInfo.widgetId)
      }
      return result
    },

    formInfo () {
      return this.$store.getters.formInfo
    },

    linkedFields () {
      return this.formInfo && this.formInfo.linkedFields &&
        this.formInfo.linkedFields.length > 0 ?
        this.formInfo.linkedFields :
        []
    },

    allLinkedFieldIds () {
      var result = []
      for (let i = 0; i < this.linkedFields.length; i++) {
        result = result.concat(this.linkedFields[i].fieldIds)
      }
      return result
    },

    formFieldInfos () {
      // return this.$store.getters.formFieldInfos
      return this.currentFieldInfos
    },

    formTitleFieldInfoId () {
      return this.$store.getters.formInfo.titleFieldInfoId
    },

    formTitleFieldInfo () {
      return this.formTitleFieldInfoId ? 
        this.formFieldInfos.find(info => info._id === this.formTitleFieldInfoId) :
        null
    },

    formTitleFieldId () {
      return this.formTitleFieldInfo ?
        this.formTitleFieldInfo.fieldId :
        ''
    },

    formDataSources () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.formFieldInfos.length; i++) {
        const loopFieldInfo = vm.formFieldInfos[i]
        switch (loopFieldInfo.type) {
          case 'relatedRecord':
            if (loopFieldInfo.properties.dataSource) {
              result.push(loopFieldInfo.properties.dataSource)
            }
            break      
        }
      }
      return result
    },
    dataSources () {
      return this.$store.getters.dataSources
    },
    dataTableId () {
      const vm = this
      var result = ''
      if (vm.dataSource) {
        result = vm.dataSource
      }
      return result
    },
    dataSource () {
      return this.propertyValues && this.propertyValues['dataSource'] || null
    },
    dataSourceInfo () {
      const vm = this
      var result = null
      if (vm.dataSources && vm.dataSource) {
        result = vm.dataSources.find(
          ds => ds._id === vm.dataSource
        )
      }
      return result
    },
    dataSourceFieldInfos () {
      return this.relatedFieldInfos
    },
    relatedFieldIds () {
      return this.relatedFieldInfos.map(info => info.fieldId)
    },
    relatedFieldInfos () {
      const vm = this
      var result = []
      if (vm.dataSourceInfo) {
        result = vm.dataSourceInfo.fieldInfos
      }
      return result
    },
    relatedTitleFieldInfoId () {
      const vm = this
      var result = ''
      if (vm.dataSourceInfo) {
        result = vm.dataSourceInfo.titleFieldInfoId
      }
      return result
    },
    relatedTitleFieldInfo () {
      const vm = this
      return vm.relatedFieldInfos && vm.relatedTitleFieldInfoId ?
        vm.relatedFieldInfos.find(info => info._id === vm.relatedTitleFieldInfoId) :
        null        
    },

    relatedTitleFieldId () {
      return this.relatedTitleFieldInfo ? this.relatedTitleFieldInfo.fieldId : ''
    },

    relatedAttachmentFieldInfos () {
      return this.relatedFieldInfos.filter(info => info.type === 'attachments')
    },

    relatedFirstAttachmentFieldId () {
      return this.relatedAttachmentFieldInfos.length > 0 ?
        this.relatedAttachmentFieldInfos[0].fieldId : 
        ''
    },

    note () {
      const vm = this
      var result = null
      if (vm.propertyConfig.noteTag && vm.propertyConfig.noteTag !== '') {
        result = vm.$t('widgets.properties.' + vm.propertyConfig.noteTag)
      }
      return result
    },
    propertyValue: {
      get: function () {
        const vm = this
        if (typeof vm._parseValue === 'function') {
          return vm._parseValue(vm.value)
        } else {
          return vm.value
        }
      },
      set: function (val) {
        console.log('mixin: set val: ', val)
        const vm = this
        vm.updatePropertyValue(val)
      }
    },
    options () {
      const vm = this
      var result = []
      // console.log('optoins starts: result.length = ' + result.length)
      for (let i = 0; i < vm.propertyConfig.options.length; i++) {
        const loopOption = vm.propertyConfig.options[i]

        // Ignore "table" display format if current property belongs to Parent/Child fields        
        // if (vm.allLinkedFieldIds.includes(vm.fieldInfoId) && 
        //   (loopOption.value === 'table') && 
        //   (vm.propertyKey==='displayFormat')) {
        //   continue
        // }

        if (
            loopOption.showIf && 
            Array.isArray(loopOption.showIf) && 
            (loopOption.showIf.length > 0) 
        ) {
          if (vm.showIfFulfilled(loopOption.showIf)) {
            // console.log('0 i=' + i + ': push')
            result.push(loopOption)
          }        
        } else if (
          loopOption.showIfEither && 
          Array.isArray(loopOption.showIfEither) && 
          (loopOption.showIfEither.length>0)
        ) {
          if (vm.showIfEitherFulfilled(loopOption.showIfEither)) {
            // console.log('1 i=' + i + ': push')
            result.push(loopOption)
          }
        } else {
          // console.log('2 i=' + i + ': push')
          result.push(loopOption)
        }
        // console.log('3 i=' + i + ': current result.length = ' + result.length)
      }
      if(this.ofChildTable && this.propertyKey=="displayFormat")
        result = result.filter(opt=>opt.value!="table")
      return result
    },

    otherOptions () {
      const vm = this
      var result = []
      if (vm.propertyConfig.otherOptions) {
        result = vm.propertyConfig.otherOptions
      }
      return result
    }

  },

  methods: {

    optionConditionArrayFulfilled (conditionArray) {
      const vm = this
      var result = false
      // console.log('mixin: optionConditionArrayFulfilled: conditionArray: ', conditionArray)
      var propertyName = conditionArray[0]
      var expectedValue = conditionArray[1]
      // console.log('optionConditionArrayFulfilled : expectedValue = ' + expectedValue)
      const negation = propertyName.substr(-1) === '!'
      if (negation) propertyName = propertyName.substr(0, propertyName.length-1)

      const propertyValue = vm.getPropertyValue(propertyName, '')

      // console.log('optionConditionArrayFulfilled : propertyValue = ' + propertyValue + ', negation=' + (negation ? 'yes' : 'no'))
      if (Array.isArray(expectedValue)) {
        // console.log('expectedValue is array')
        result = negation ?
          !expectedValue.includes(propertyValue) :
          expectedValue.includes(propertyValue)
      } else {
        // console.log('expectedValue is not array')
        result = negation ?
          expectedValue !== propertyValue :
          expectedValue === propertyValue
      }
      // console.log('optionConditionArrayFulfilled : result = ' + (result ? 'yes' : 'no'))
      return result
    },

    optionConditionFulfilled (conditionArray) {
      const vm = this
      var result = false
      if (Array.isArray(conditionArray) && conditionArray.length >= 2) {
        if (!Array.isArray(conditionArray[0])) {
          result = vm.optionConditionArrayFulfilled(conditionArray)
        } else {
          result = true
          for (let i = 0; i < conditionArray.length; i++) {
            const loopCondition = conditionArray[i]
            if (!vm.optionConditionFulfilled(loopCondition)) {
              result = false
              break
            }
          }
        } 
      }
      return result
    },

    showIfEitherFulfilled (showIfEither) {
      const vm = this
      var result = false
      if (Array.isArray(showIfEither) && showIfEither.length > 0) {
        if (!Array.isArray(showIfEither[0])) {
          
          result = vm.optionConditionArrayFulfilled(showIfEither)
        } else {
          for (let i = 0; i < showIfEither.length; i++) {
            const loopItem = showIfEither[i]

            if (vm.optionConditionFulfilled(loopItem)) {
              result = true
              break
            }
          }
        }
      }
      return result
    },

    showIfFulfilled (showIf) {
      // [{propertyName}, {value1}] 
      // [
      //    [{propertyName1}, {value1}], 
      //    [{propertyName2}, {!value2}],
      //    [{propertyName3}, {!value3}],
      //    [{propertyName4}, {value4}]
      // ]
      //
      const vm = this
      // console.log('showIfFulfilled : showIf: ', showIf)
      var result = false
      if (Array.isArray(showIf)) {
        if (!Array.isArray(showIf[0])) {
          result = vm.optionConditionArrayFulfilled(showIf)
          // console.log('after elementFulfiled: result = ' + (result ? 'yes' : 'no'))
        } else {
          result = true
          for (let i = 0; i < showIf.length; i++) {
            const loopItem = showIf[i]
            if (!this.optionConditionFulfilled(loopItem)) {
              result = false
              break
            }
          }
        }
      }
      // console.log('showIfFulfilled ends result = ' + (result ? 'yes' : 'no'))
      return result
    },

    getFormInfo (formId) {
      const vm = this
      var result = null
      if (vm.dataSources) {
        result = vm.dataSources.find(ds => ds._id === formId)
      }
      return result
    },
    getPropertyValue (propertyKey, defaultValue) {
      const vm = this
      var result = defaultValue
      if (vm.propertyValues && vm.propertyValues[propertyKey]) {
        result = vm.propertyValues[propertyKey]
      }
      return result
    },
    getFieldInfosByType(types){
      var typeArr = []
      if(!Array.isArray(types)){
        typeArr.push(types)
      }else{
        typeArr = types
      }
      var res = this.currentFieldInfos.filter(item=>typeArr.includes(item.type))
      return res
    },

    // buildValue (newVal) {
    //   const vm = this
    //   var result = newVal
    //   if (typeof newVal === 'undefined') {
    //     result = vm.propertyValue
    //   }
    //   if (typeof vm._buildValue === 'function') {
    //     result = vm._buildValue(newVal)
    //   }
    //   return result
    // },
    updatePropertyValue (val) {
      const vm = this
      // console.log('mixin: updatePropertyValue : emit(input)')
      console.log('mixin: updatePropertyValue: val: ', val)
      vm.$emit('input', val)
    },
    strToArray (value, separator) {
      if (typeof separator === 'undefined') {
        separator = ','
      }
      var result = []
      if (value !== '') {
        result = value.split(separator)
      }
      return result
    },
    arrayToStr (ar, separator) {
      if (typeof separator === 'undefined') {
        separator = ','
      }
      var result = ''
      if (ar.length > 0) {
        result = ar.join(separator)
      }
      return result
    }
  }
}

export default mixin
