var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workform-clip-card-container"},[_c('v-layout',{staticClass:"workform-clip-card",attrs:{"align-center":""}},[(_vm.onlyPrefix)?_c('div',{staticClass:"workform-clip-source"},[_c('v-icon',{staticClass:"workform-clip-source-icon",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.item.icon || 'mdi-table')+" ")]),_vm._v(" "+_vm._s(_vm.$t('workflow.label.table'))+" "+_vm._s(_vm.item.table)+" ")],1):_vm._e(),(_vm.onlyPrefix && _vm.onlySuffix)?_c('div',{staticClass:"workform-clip-arrow1"}):_vm._e(),(_vm.onlyPrefix && _vm.onlySuffix)?_c('div',{staticClass:"workform-clip-arrow2"}):_vm._e(),(_vm.onlySuffix)?_c('div',{staticClass:"workform-clip-card-label",class:{
      'workform-clip-card-label-signle': !_vm.onlyPrefix && _vm.onlySuffix,
    },style:({
      background:
        [
          'WORKFLOWTRIGGER' ].includes(_vm.item.fieldType) ?
        _vm.$vuetify.theme.themes.light.color.system :
        _vm.$vuetify.theme.themes.light.primary,
      maxWidth: '160px',
    })},[(_vm.item.isSystem)?_c('span',[_vm._v(" "+_vm._s(_vm.renderLabel ? _vm.renderLabel(_vm.item, true) : _vm.$t(("workflow.widgets." + (_vm.item.fieldId))))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.renderLabel ? _vm.renderLabel(_vm.item) : _vm.item.label)+" ")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }