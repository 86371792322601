<template>
    <div>
        <v-dialog v-model="bl_dialog" width="1800">
            <v-card>
                <v-card-title style="d-flex">
                    {{$t('dashboard.button')}}
                     <v-btn @click="$emit('saveBtn')" icon small class="ml-auto">
                        <v-icon>
                            mdi-content-save-outline
                        </v-icon>
                    </v-btn>
                    <v-btn @click="bl_dialog=false" icon small class="ml-5">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>

                <v-card-text style="height:800px" class="pa-0">
                    <div class="d-flex" style="height:100%">
                        <div class="flex-grow-1" style="background-color: #eeee; width:80%">
                            <div class="d-flex flex-column">
                                <div class="px-7 d-flex">
                                    <v-btn @click="addBtn" rounded class="white info--text my-3">
                                        <v-icon style="width:15px; min-width:0px;" small>mdi-plus</v-icon>
                                        {{$t('dashboard.addButton')}}
                                    </v-btn>
                                    <div class="d-flex ml-auto align-center">
                                        <!-- <div class="red">
                                            <v-icon>mdi-sort</v-icon>
                                            排序
                                        </div> -->
                                        <div class="ml-4" v-if="selectedBtn">
                                            {{$t('dashboard.style')}}
                                            <v-btn-toggle color="info" v-model="selectedBtn.type">
                                                <v-btn small value="rounded-sm">
                                                    <div class="grey rounded-sm" style="padding: 5px 9px"/>
                                                </v-btn>
                                                <v-btn value="rounded-pill"  small>
                                                    <div class="grey rounded-pill" style="padding: 5px 10px"/>
                                                </v-btn>
                                                <v-btn value="outlined" small>
                                                    <div class="rounded-sm" style="border: 2px dashed grey; padding: 3.5px 8px"/>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </div>
                                        <div class="ml-4">
                                            {{$t('dashboard.width')}}
                                            <v-btn-toggle color="info" v-model="align" @change="$emit('update_align', align)">
                                                <v-btn small value="center">
                                                    <v-icon size="17" color="grey">mdi-format-align-center</v-icon>
                                                </v-btn>
                                                <v-btn small value="justify">
                                                    <v-icon size="17" color="grey">mdi-format-align-justify</v-icon>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </div>
                                        <div class="d-flex align-center ml-4">
                                            {{$t('dashboard.maxRowItem1')}}
                                          <div style="width:200px;" class="d-flex align-center ml-1">
                                            <v-text-field v-model="numPerRow" @change="$emit('update_numPerRow', $event)" hide-details height="29" outlined class="white maxRowInput" style="width:30px; border-radius: unset; margin-top: 2px">
                                            </v-text-field>
                                            <div class="d-flex flex-column rounded-0" style="border: 1px solid #cccccc">
                                                <v-btn @click="$emit('update_numPerRow',numPerRow+1)" :ripple="false" style="height:14px" small icon><v-icon small>mdi-chevron-up</v-icon></v-btn>
                                                <v-btn @click="$emit('update_numPerRow',numPerRow-1)" :ripple="false" style="height:14px" small icon><v-icon small>mdi-chevron-down</v-icon></v-btn>
                                            </div>
                                            <span class="ml-1">{{$t('dashboard.maxRowItem2')}}</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <v-divider class="mx-7"/>
                                <div class="px-7">
                                    <div class="my-6 text-center">{{$t('dashboard.selectToEdit')}}</div>
                                    <v-card elevation="0" tile class="py-5 text-center">
                                        <v-card-text style="overflow: auto; max-height:600px;" class="pa-0">
                                            <div class="mb-2">{{title}}</div>
                                            <div v-if="btns.length" class="mr-2">
                                                <div v-for="i in Math.ceil(btns.length/numPerRow)" :key="i" class="mt-2">
                                                    <div class="d-flex justify-center" style="width:100%">
                                                        <v-hover close-delay="100" v-slot="{ hover }" v-for="(btn,index) in getSlicedBtns(i)" :key="btn.id">
                                                            <div :class="align=='justify'?'flex-grow-1':''">
                                                                <div class="" style="position: relative">
                                                                    <v-btn @click="deleteBtn(btn.id)" v-show="hover" color="error" small fab style="position:absolute; right:0; z-index:1; height:18px; width:18px">
                                                                        <v-icon size="17" class="">mdi-close</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                                <div @click="selectBtn(btn)" class="ml-2 pa-1" :style="selectedBtnId==btn.id?'border: 1px grey solid':hover?'border: 1px grey dashed':'border: 1px white dashed'">
                                                                    <v-btn elevation="0" class="" :outlined="btn.type=='outlined'" :class="btn.type" style="width:100%" :color="btn.bgColor">
                                                                        <span :class="btn.whiteText?'white--text':''" style="overflow:hidden; white-space:nowrap; text-overflow:ellipsis">{{btn.text}}</span>
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-hover>
                                                    </div> 
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div> 
                        </div> 
                        <div style="background-color: #f8f8f8; width:20%" class="flex-grow-0 flex-shrink-1 flex-column">
                            <v-tabs                                
                                v-model="tab"
                                fixed-tabs
                                centered
                                height="50"
                            >
                                <v-tabs-slider color="info"></v-tabs-slider>
                                <v-tab
                                    class="primary--text"
                                >
                                    {{$t('dashboard.setButton')}}
                                </v-tab>
                                <v-tab
                                    class="primary--text"
                                >
                                    {{$t('dashboard.cardDescription')}}
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab" style="">
                                <v-tab-item>
                                    <div class="mx-5 my-5" v-if="selectedBtn">
                                        {{$t('dashboard.buttonName')}}
                                        <v-text-field
                                            dense
                                            height="30"
                                            outlined
                                            hide-details
                                            v-model="selectedBtn.text"
                                        />
                                        <div>
                                            <v-checkbox dense plain :ripple="false" v-model="selectedBtn.whiteText" :label="$t('dashboard.white')"></v-checkbox>
                                        </div>
                                    </div>
                                    <div class="mx-5 my-5" v-if="selectedBtn">
                                        {{$t('dashboard.buttonColor')}}
                                       <div>
                                           <v-color-picker
                                                v-model="selectedBtn.bgColor"
                                                class="ma-2"
                                                hide-canvas
                                                hide-inputs
                                                hide-sliders
                                                show-swatches
                                                swatches-max-height="300px"
                                                mode="hex"
                                            ></v-color-picker>
                                       </div>
                                    </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <div class="mx-5 my-5">
                                        {{$t('dashboard.text')}}
                                        <v-text-field
                                            dense
                                            height="30"
                                            outlined
                                            hide-details
                                            v-model="title"
                                            @change="$emit('update_title', $event)"
                                        />
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                            <v-divider/>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props:{
            value: Boolean,
            btns: Array,
            btnCount: Number,
            numPerRow: Number,
            align: String,
            title: String
        },
        computed:{
            bl_dialog:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            }
        },
        watch:{
        //   numPerRow(newVal, oldVal){
        //     if(newVal>8){
        //         this.numPerRow = 8               
        //     }
        //     if(newVal<1){
        //         this.numPerRow = 1
        //     }
        //   }  
        },
        data(){
            return{
                tab: 0, 
                selectedBtnId: null,
                selectedBtn: null,
                selectedColor: null
            }
        },
        methods:{
            addBtn(){
                let btn = {
                    id: this.btnCount+1,
                    type: 'rounded-sm',
                    text: this.$t('dashboard.button'),
                    bgColor: '#EEEEEE',
                    whiteText: false
                }
                this.btns.push(btn)
                this.$emit('addbtnCount')
            },
            getSlicedBtns(index){
                return this.btns.slice((index-1)*(this.numPerRow), this.numPerRow*index)
            },
            selectBtn(btn){
                this.selectedBtnId = btn.id
                this.selectedBtn = btn
            },
            deleteBtn(id){
                this.btns = this.btns.filter(item=>item.id!=id)
            },
        }
    }
</script>

<style>
    .maxRowInput .v-input__slot{
        min-height: 20px !important;
    }
</style>
