<template>
  <div>
    <PendingSetup
      v-if="dataSize < 1"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <div class="workflow-shortview-remark">
        {{ $t('workflow.modules.updateArgument.label.updated', { size: dataSize }) }}
      </div>
    </div>
  </div>
</template>

<script>
import { keys, get } from 'lodash';
import PendingSetup from './pending';
import Mixin from './mixin';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
  },
  computed: {
    dataSize() {
      return keys(get(this.node, 'properties.data', {})).length;
    }
  },
  components:{
    PendingSetup,
  },
};

</script>

<style scoped>
</style>
