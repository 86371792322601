<template>
    <!-- <v-hover v-slot="{hover}"> -->
        <!-- :class="hover?'grey lighten-5':''" -->
        <!-- :class="hover?'grey lighten-5':''" -->
            <v-treeview
                :items="slotProps.data.gtArray"
                dense
                :openAll="!initialized"
                :open="openArr"
                :showCurrentTime="true"
                item-key="_id"
                hoverable
            >
            <!-- v-if="(slotProps.isInRenderingTimeRange(item.start)||slotProps.isAcrossRenderingTimeRange(item.start,item.end)
            ||slotProps.isInRenderingTimeRange(item.end))" -->
                <template v-slot:label="{item}">
                    <!-- <v-hover v-slot:default="{ hover }"> -->
                        <div 
                            v-if="item.milestone && (slotProps.isInRenderingTimeRange(item.milestone+' 00:00:00')||slotProps.isAcrossRenderingTimeRange(item.milestone,item.milestone+' 23:59:59')
                            ||slotProps.isInRenderingTimeRange(item.milestone+' 23:59:59'))"  
                            v-on="on"
                            class="d-flex align-center" 
                            style="width:20px; height:20px"
                            :style="{position:'absolute',
                                left:slotProps.getPositonOffset(item.milestone+' 00:00:00')-2+'px',
                                top: item.children.length? '10px':'9px',
                                width:slotProps.getWidthAbout2Times(item.milestone+' 00:00:00',item.milestone+' 23:59:59')+'px',
                            }"
                            @click="editTask(item, $event)"
                        >
                            <v-icon :color="groupColor" size="25">
                                mdi-label {{item.milestone}}
                            </v-icon>
                            <div style="font-size:13px" class="font-weight-bold pl-2 secondary--text">
                                Milestone - {{item.name}}
                            </div>
                        </div>
                        <div
                            v-else-if="(slotProps.isInRenderingTimeRange(item.start)||slotProps.isAcrossRenderingTimeRange(item.start,item.end)
                            ||slotProps.isInRenderingTimeRange(item.end))"
                            :id="'gantt-block-'+item._id"
                            :key="item._id"
                            :style="{position:'absolute',
                                    left:slotProps.getPositonOffset(item.start)+'px',
                                    top: item.children.length? '22px':'9px',
                                    width:slotProps.getWidthAbout2Times(item.start,item.end)+'px',
                                    }"
                            @mousedown="editTask(item, $event)"
                        > 
                            <v-tooltip left :disabled="isResizing">
                                <template v-slot:activator="{ on, value }">
                                    <div 
                                        class="gantt-block-children-task" 
                                        v-on="on"
                                        v-show="item.children.length==0 && !item.milestone"
                                        :style= "item.dateProgress>=100&&item.progress<100?'box-shadow: 0px 0px 3px 0px red inset':''"
                                    >
                                    <!-- :style="item.dateProgress==100&&item.progress<100? 'box-shadow: 2px 2px #FF6666':''" -->
                                        <!-- :style="box-shadow: 3px 10px red;" -->
                                        <div style="z-index:1; float:right" class="gantt-block-task-text px-2">
                                            <!-- {{item.name}} -->
                                            {{item.label}}
                                        </div>
                                        <div 
                                            :id="'gantt-block-progress-'+item._id"
                                            style="position: absolute; 
                                                    border-right: 0px #d9d9d9 solid;
                                                "
                                            :style="`
                                                width:${fillBy=='PROGRESS'?Math.min(item.progress, 100):item.dateProgress}%;
                                                border-radius: ${(fillBy=='PROGRESS'?item.progress:item.dateProgress)<100?'4px 0 0 4px':'4px'};
                                                background-color: ${groupColor};
                                                height: ${item.dateProgress>=100&&item.progress<100?'100%':'100%'};
                                                box-shadow:${item.dateProgress>=100&&item.progress<100?'0px 0px 3px 0px red inset':''};
                                            `"
                                        >
                                            <div 
                                                v-if="(value||(resizingItem==item && isProgressResizing))&&fillBy=='PROGRESS'" 
                                                @mousedown.stop="resizeProgressStart(item, $event)" 
                                                @click.stop=""
                                                class="" style="cursor: ew-resize; width:6px; position: absolute; background-color:#FF9999; right:0; height:100%; z-index:1"
                                            >
                                            </div>
                                        </div>
                                        <div
                                            v-if="!isProgressResizing && (value||(resizingItem==item && isResizing))"
                                            style="border-radius:0px 5px 5px 0px; height:22px; width:6px; position: absolute; right:-10px; top:0px; z-index:1; cursor: ew-resize" 
                                            class="right-resizer pa-1"
                                            @mousedown.stop.prevent="resizeStart(item._id, 'right', $event, item)"
                                        > 
                                        </div>
                                        <div 
                                            style="height:22px; width:3px; position: absolute; right:-5px; top:0px; z-index:0;" 
                                            class="pa-1 transparent"
                                        > 
                                        </div>
                                        <div 
                                            style="height:22px; width:3px; position: absolute; left:-5px; top:0px; z-index:0;" 
                                            class="pa-1 transparent"
                                        > 
                                        </div>
                                        <div 
                                            v-if="!isProgressResizing && (value||(resizingItem==item && isResizing && !isProgressResizing))"
                                            style="border-radius:5px 0px 0px 5px; height:22px; width:6px; position: absolute; left:-10px; top:0px; z-index:1; cursor: ew-resize" 
                                            class="left-resizer pa-1"
                                            @mousedown.stop.prevent="resizeStart(item._id, 'left', $event, item)"
                                        > 
                                        </div>
                                    </div>
                                    <div v-if="item.children.length>0">
                                        <div class="gantt-block-parent-task" v-if="showGroupSummary">
                                            <div style="z-index:1; position: absolute; top:-15px; left:-6px" 
                                                class="gantt-block-task-text px-2 font-weight-bold"
                                            >
                                                {{item.name}}
                                            </div>
                                            <div 
                                                style="position: absolute; height:100%; border-right: 1px #d9d9d9 solid"
                                                :style="`
                                                    width:${fillBy=='PROGRESS'?Math.min(item.progress, 100):item.dateProgress}%;
                                                    border-radius: ${(fillBy=='PROGRESS'?item.progress:item.dateProgress)<100?'4px 0 0 4px':'4px'};
                                                    background-color: ${groupColor};
                                                `"
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="d-flex flex-column" style="font-size:13px">
                                    <div class="d-flex align-center"> 
                                        <div
                                            style="border-radius: 50%; width:10px; height:10px"
                                            :style="`background-color: ${groupColor}`"
                                        >

                                        </div>
                                        <div class="ml-2">
                                            {{item.name}}
                                        </div>
                                    </div>
                                    <div>
                                        <div v-if="!customHover">
                                            <div v-if="item.start">Start: {{item.start.substr(0,10)}}</div>
                                            <div v-if="item.end">End: {{item.end.substr(0,10)}}</div>
                                            <div>Progress: {{item.progress.toFixed(2)}}</div>
                                            <!-- <div>Date Progress: {{item.dateProgress.toFixed(2)}}</div> -->
                                        </div>
                                        <div v-else>
                                            <div v-if="item.hoverInfos && item.hoverInfos.length">
                                                <div v-for="(info,index) in item.hoverInfos" :key="index">
                                                    <div>
                                                        <span>{{info.key}}: </span>
                                                        <span>{{info.value}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-tooltip>
                        </div>
                        <div v-else>
                        </div>
                    <!-- </v-hover> -->
                </template>
            </v-treeview>
    <!-- </v-hover> -->
</template>

<script>
    import moment from 'moment'
    export default {
        props:{
            slotProps: Object,
            cellWidth: Number,
            scale: Number,
            fillBy: String,
            labelBy: String,
            showGroupSummary: Boolean,
            customHover: Boolean,
            hoverInfos: Array,
            groupStyle: Object,
            projectCascade: [ String, Array ],
            groupBy: [ String, Array ]
        },
        data(){
            return{
                openArr: [],
                isResizing: false,
                isProgressResizing: false,
                resizingId: null,
                resizingItem: null,
                resizingRow: null,
                initialized: false,
                resizeType: '',
            }
        },
        mounted(){
            this.initialized = true
        },
        computed:{
            groupColor(){
                if(this.groupStyle.hasOwnProperty(this.slotProps.data.gtArray[0]._id))
                    return this.groupStyle[this.slotProps.data.gtArray[0]._id]
                else 
                    return '#b2dfdb'
                // var equalCol = false
                // if(Array.isArray(this.groupBy) && Array.isArray(this.col_projectCascade)){
                //     equalCol = this.groupBy.every(item=> this.col_projectCascade.includes(item))
                // }else
                // {
                //     equalCol = this.groupBy ==this.col_projectCascade
                // }
                // if(equalCol){
                //     if(this.groupStyle.hasOwnProperty(this.slotProps.data.gtArray[0]._id))
                //         return this.groupStyle[this.slotProps.data.gtArray[0]._id]
                //     else 
                //         return '#b2dfdb'
                // }else{
                //     if(this.slotProps.data.gtArray[0].color)
                //         return this.slotProps.data.gtArray[0].color
                //     else 
                //         return '#b2dfdb'
                // }
            }
        },
        methods:{
            resizeProgressStart(item,e){
                e.preventDefault()
                e.stopPropagation()
                this.isProgressResizing = true
                this.resizingItem = item
                this.resizingId = item._id
                var tarRow = document.getElementById('gantt-block-' + this.resizingId)
                var taskName = tarRow.querySelector('.gantt-block-task-text')
                var progressStr = document.createElement("div")
                progressStr.style.position = 'absolute'
                progressStr.style.bottom = '-19px'
                progressStr.style.borderRadius = '5px'
                progressStr.style.padding = '0px 5px'
                progressStr.style.backgroundColor = '#FF9999'
                progressStr.style.color = 'white'
                progressStr.style.fontWeight = 'bold'
                progressStr.setAttribute('id', 'gantt-block-progressStr-' + this.resizingId)
                taskName.appendChild(progressStr)
                this.resizingRow = window
                let vm = this
                this.resizingRow.addEventListener('mousemove', vm.resizeProgressMove)
                this.resizingRow.addEventListener('mouseup', vm.resizeProgressEnd)

            },
            resizeProgressEnd(e){
                console.log('resizeProgressEnd---', e)
                e.preventDefault()
                e.stopPropagation()
                this.isProgressResizing = false
                console.log('resizeProgressEnd---isProgressResizing', this.isProgressResizing)
                this.resizingRow.removeEventListener('mousemove', this.resizeProgressMove)
                this.resizingRow.removeEventListener('mouseup', this.resizeProgressEnd)
                var progressBar = document.getElementById('gantt-block-progress-' + this.resizingId)
                var block = document.getElementById('gantt-block-' + this.resizingId)
                this.resizingItem.progress = (parseFloat(progressBar.style.width.replace('px',''))/parseFloat(block.style.width.replace('px','')))*100
                var progressStr = document.getElementById('gantt-block-progressStr-' + this.resizingId)
                progressStr.remove()
                this.$emit('updateRecord', this.resizingItem)
            },
            resizeProgressMove(e){
                e.preventDefault()
                e.stopPropagation()
                if(!this.isProgressResizing) return
                var progressBar = document.getElementById('gantt-block-progress-' + this.resizingId)
                var block = document.getElementById('gantt-block-' + this.resizingId)
                var cursorX = e.clientX
                var progressBarRect = progressBar.getClientRects()[0]
                var blockRect = block.getClientRects()[0]
                var progressBarLeft = progressBarRect.left
                var blockLeft = blockRect.left
                var blockRight = blockRect.right
                // console.log({blockLeft,progressBarLeft,blockRight,cursorX})
                if(cursorX<=blockRight && cursorX>blockLeft)
                    progressBar.style.width= cursorX-blockLeft+'px'
                else if(cursorX<=blockLeft){
                    progressBar.style.width = '0px'
                }
                else
                    progressBar.style.width= block.style.width
                
                var progressStr = document.getElementById('gantt-block-progressStr-' + this.resizingId)
                progressStr.innerHTML = ((parseFloat(progressBar.style.width.replace('px',''))/parseFloat(block.style.width.replace('px','')))*100).toFixed(2) + '%'
                progressStr.style.left = progressBar.style.left.replace('px','')  + progressBar.style.width.replace('px','') -24 + 'px'
                // console.log({progressBarWidth: progressBar.style.width, blockWidth: block.style.width})
                // console.log((parseFloat(progressBar.style.width.replace('px',''))/parseFloat(block.style.width.replace('px','')))*100)
                // console.log('progressBarRect', progressBarRect)
            
                // var progressBarWidth = progressBarRect.width
                // console.log({left,cursorX})
                // console.log("progressBar", progressBar)
            },
            editTask(item, e){
                console.log('editTask::item', item)
                console.log('editTask::event', e)
                console.log('this.isProgressResizing----', this.isProgressResizing)
                if(!this.isResizing && !this.isProgressResizing){
                    this.$emit('openEditTaskDialog', {task: item})
                }
            },
            getWidthAbout2Times(start, end) {
                // const { scale, cellWidth } = arg;
                // const pStart = this.cacheParseTime("pStart", start)();
                const pStart = new Date(start);
                const pEnd =  new Date(end);
                const dayDiff = (pEnd.getTime() - pStart.getTime())/1000/60/60/24;
                return dayDiff*this.cellWidth
            },
            resizeStart(blockId, type, e, blockItem){  
                e.preventDefault() 
                this.isResizing = true
                this.resizingItem = blockItem
                this.resizeType = type
                this.resizingId = blockId
                var tarRow = document.getElementById('gantt-block-' + this.resizingId)
                console.log(tarRow.style.left)
                var tarRect = tarRow.getClientRects()[0]
                var day = parseInt(Math.round(tarRect.width)/this.cellWidth)
                console.log("tarRect.width", tarRect.width)
                var ghostStart = document.createElement("div");
                var ghostEnd = document.createElement("div");
                var ghostAppend = document.createElement("div");
                ghostStart.setAttribute("id","ghost-block-start" + blockId)
                ghostEnd.setAttribute("id","ghost-block-end" + blockId)
                ghostAppend.setAttribute("id","ghost-block-append" + blockId)
                ghostStart.innerText = 'start'
                ghostEnd.innerText = 'end'
                ghostAppend.innerText = day + ' d'
                ghostStart.innerText = moment(blockItem.start).format('DD/MM')
                ghostEnd.innerText = moment(blockItem.end).format('DD/MM')
    
                ghostAppend.style.position = 'absolute'
                ghostAppend.style.right = '-60px'
                ghostAppend.style.color = 'black'
                ghostAppend.style.zIndex = 999
                ghostEnd.style.marginLeft = "auto"
                var ghostBar = document.createElement("div");
                ghostBar.style.width = tarRect.width + 'px'
                ghostBar.style.minWidth = '100px'
                ghostBar.style.height = tarRect.height + 'px'
                ghostBar.style.position = 'absolute'
                // ghostBar.style.left = tarRect.left + 'px'
                ghostBar.style.left = tarRow.style.left
                ghostBar.style.top = (tarRow.style.top.replace('px','') -25) + 'px'
                ghostBar.style.backgroundColor = '#8c9cbe'
                ghostBar.style.zIndex = 999
                ghostBar.style.fontSize = '13px'
                ghostBar.style.borderRadius = '5px'
                ghostBar.style.color = 'white'
                ghostBar.style.fontWeight = 'bold'
                ghostBar.style.padding = '0px 10px'
                ghostBar.style.display = 'flex'
                ghostBar.setAttribute("id","ghost-block-" + blockId)
                // console.log("tarRow", tarRow.style.left)
                ghostBar.appendChild(ghostStart)
                ghostBar.appendChild(ghostEnd)
                ghostBar.appendChild(ghostAppend)
                tarRow.closest('.v-treeview-node__label').append(ghostBar)
                // document.getElementsByClassName('gantt-blocks-wrapper')[0].appendChild(ghostBar)
                // document.body.appendChild(ghostBar)
                // console.log('ghostBar', ghostBar)
                this.resizingRow = window
                // var container = document.getElementsByClassName("gantt-container")
                // container[0].style.cursor="ew-resize"
                let vm = this
                this.resizingRow.addEventListener('mousemove', vm.resizeMove)
                this.resizingRow.addEventListener('mouseup', vm.resizeEnd)
                // e.preventDefault()
            },
            resizeMove(e){
                e.preventDefault()
                if(!this.isResizing) return


                var tarBlock =  document.getElementById('gantt-block-' + this.resizingId)
                var ghostBlock =  document.getElementById('ghost-block-' + this.resizingId)
                // console.log(tarBlock.offsetWidth)
                // console.log(tarBlock.getClientRects()[0])
                var blockStartX = tarBlock.getClientRects()[0].x
                var blockEndX = tarBlock.getClientRects()[0].right 
                var blockWidth = tarBlock.getClientRects()[0].width 
                var cursorX = e.clientX
                var newWidth
                var day = 1
                if(this.resizeType=='right'){
                    newWidth = cursorX - blockStartX
                    if(parseInt(newWidth/this.cellWidth)>0){
                        if((newWidth - (parseInt(newWidth/this.cellWidth)*this.cellWidth))>(this.cellWidth/2))
                            newWidth = (parseInt(newWidth/this.cellWidth)+1)*(this.cellWidth)
                        else
                            newWidth = parseInt(newWidth/this.cellWidth)*this.cellWidth
                        day = newWidth/this.cellWidth
                        tarBlock.style.width = newWidth + 'px'
                        ghostBlock.style.width = tarBlock.style.width
                    }
                }else if(this.resizeType=='left'){
                    newWidth = blockEndX - cursorX
                    if(parseInt(newWidth/this.cellWidth)>0){
                        if((newWidth - (parseInt(newWidth/this.cellWidth)*this.cellWidth))>(this.cellWidth/2))
                            newWidth = (parseInt(newWidth/this.cellWidth)+1)*(this.cellWidth)
                        else
                            newWidth = parseInt(newWidth/this.cellWidth)*this.cellWidth
                        
                        if(blockWidth!=newWidth)
                            if(blockWidth>newWidth){
                                tarBlock.style.left =  parseInt(tarBlock.style.left.replace('px','')) + (blockWidth-newWidth) + 'px'
                                ghostBlock.style.left = tarBlock.style.left
                            }else{
                                tarBlock.style.left =  parseInt(tarBlock.style.left.replace('px', '')) - (newWidth-blockWidth) + 'px'
                                ghostBlock.style.left =  tarBlock.style.left
                            }
                        
                        tarBlock.style.width = newWidth + 'px'
                        ghostBlock.style.width = tarBlock.style.width
                        day = newWidth/this.cellWidth
                    }
                }else if(this.resizeType=='drag'){
                    newWidth = blockEndX - cursorX
                    if((newWidth - (parseInt(newWidth/this.cellWidth)*this.cellWidth))>(this.cellWidth/2))
                        newWidth = (parseInt(newWidth/this.cellWidth)+1)*(this.cellWidth)
                    else
                        newWidth = parseInt(newWidth/this.cellWidth)*this.cellWidth
                    
                    if(blockWidth!=newWidth)
                        if(blockWidth>newWidth){
                            tarBlock.style.left =  parseInt(tarBlock.style.left.replace('px','')) + (blockWidth-newWidth) + 'px'
                            ghostBlock.style.left =  tarBlock.style.left
                        }else{
                            tarBlock.style.left =  parseInt(tarBlock.style.left.replace('px', '')) - (newWidth-blockWidth) + 'px'
                            ghostBlock.style.left =  tarBlock.style.left
                        }
                }
                var appendBlock =  document.getElementById('ghost-block-append' + this.resizingId)
                appendBlock.innerText = day + ' d'

                var blockWidth = tarBlock.getClientRects()[0].width
                var ganttScale = 1440
                if(this.resizeType=='right'){
                    var blockStartTime = new Date(this.resizingItem.start)
                    var blockEndTime = new Date(((parseInt(blockWidth/this.cellWidth)-1)*1440*1000*60)+blockStartTime.getTime())
                }
                else if(this.resizeType=='left')
                {
                    var blockEndTime = new Date(this.resizingItem.end)
                    var blockStartTime = new Date(blockEndTime.getTime()-((parseInt(blockWidth/this.cellWidth)-1)*1440*1000*60))
                }
                // console.log({
                //     blockEndTime,
                //     blockStartTime
                // })
                blockStartTime = moment(blockStartTime)
                blockEndTime = moment(blockEndTime)
                var startBlock =  document.getElementById('ghost-block-start' + this.resizingId)
                var endBlock =  document.getElementById('ghost-block-end' + this.resizingId)
                this.resizingItem.start = blockStartTime.format('YYYY-MM-DD 00:00:00')
                this.resizingItem.end = blockEndTime.format('YYYY-MM-DD 23:59:59')
                startBlock.innerText = blockStartTime.format('DD/MM')
                endBlock.innerText = day>1? blockEndTime.format('DD/MM'):''
            },
            resizeEnd(e){
                e.preventDefault()
                var tarBlock = document.getElementById('gantt-block-' + this.resizingId)
                var ghostBlock = document.getElementById('ghost-block-' + this.resizingId)
                ghostBlock.remove()
                // var blockWidth = tarBlock.offsetWidth
                // var blockStartTime = this.resizingItem.start
                // var cellWidth = 50
                // var ganttScale = 1440
                // var blockEndTime = new Date(((blockWidth/cellWidth)*1440*1000*60)+(new Date(blockStartTime).getTime()))
                // this.resizingItem.end = blockEndTime
                this.clearResizeEvents()
                this.$emit('updateRecord', this.resizingItem)
                this.isResizing = false
            },
            clearResizeEvents(){
                this.resizingRow.removeEventListener('mousemove', this.resizeMove)
                this.resizingRow.removeEventListener('mouseup', this.resizeEnd)
            }
        }
    }
</script>

<style>
    .gantt-blocks{
        /* background-image: none !important; */
        background-image: linear-gradient(#ececec 0px,transparent 0),linear-gradient(90deg,#ececec 1px,transparent 0) !important;
    }
    .gantt-block{
        height: auto !important;
        /* background-repeat: repeat !important; */
        background-image: linear-gradient(#ececec 1px,transparent 0),linear-gradient(90deg,#ececec 1px,transparent 0) !important;
    }
    .gantt-block-parent-task{
        display: flex;
        align-items: center;
        height: 5px !important;
        /* border: 1px solid #d9d9d9; */
        font-size:12px;
        /* background-color: white !important; */
        background: repeating-linear-gradient(
            #f0f0f0 10px,
            #f0f0f0 11px
        );
    }
    .gantt-block-children-task{
        display: flex;
        align-items: center;
        height: 22px !important;
        /* border: 1px solid #d9d9d9; */
        border-radius: 5px;
        font-size:12px;
        background-color: #efefef !important;
        /* background: repeating-linear-gradient(
            45deg,
            white,
            white 10px,
            #f0f0f0 10px,
            #f0f0f0 11px
        ); */
    }
    .gantt-block-task-text{
        color: #777777
    }
    .gantt-block .v-treeview button{
        display: none !important;
    }
    /* .gantt-block .v-tooltip__content{
        color: #424242 !important;
        background-color: white !important;
        border: 1px solid grey;
        border-radius: 0 !important
    }  */

    .line{
        width: 112px;
        height: 47px;
        border-bottom: 1px solid black;
        position: absolute;
    }
    .left-resizer,
    .right-resizer{
        background-color: #8c9cbe
    }
</style>
