<template>
    <div 
        style="height: 100%; min-height: 100%;" 
        class="pa-2 gantt-chart-container d-flex flex-grow-1"
    >
        <v-gantt-chart
            v-if="startTime&&endTime&&datas"
            ref="gantt"
            :startTime="startTime"
            :endTime="endTime"
            :datas="datas"
            :scale="1440"
            id="vGanttChart"
            :customGenerateBlocks="true"
            :timeLines="timeLines"
            :cellWidth="ganttSetting.cellWidth"
            titleWidth="320"
            titleHeight="50"
        >
            <template v-slot:timeline="slotProps">
                <GanttTimeline
                    :slotProps="slotProps"
                    :timeView="ganttSetting.timeView"
                    :cellWidth="ganttSetting.cellWidth"
                />
            </template>
            <template v-slot:markLine="{timeConfig, getPosition}">
                <GanttMarkline :timeConfig="timeConfig" :getPosition="getPosition"></GanttMarkline>
            </template>
            <template v-slot:block="slotProps">
                <GanttBlock
                    @updateRecord="updateRecord"
                    :ref="'ganttBlockTree_'+slotProps.data._id"
                    :slotProps="slotProps"
                    :scale="1440"
                    :cellWidth="ganttSetting.cellWidth"
                    :fillBy="ganttSetting.fillBy"
                    :labelBy="ganttSetting.labelBy"
                    :customHover="ganttSetting.customHover"
                    :hoverInfos="ganttSetting.hoverInfos"
                    :showGroupSummary="ganttSetting.showGroupSummary"
                    @openEditTaskDialog="openEditTaskDialog"
                    :groupStyle="ganttSetting.groupStyle"
                    :groupBy="ganttSetting.groupBy"
                    :projectCascade="ganttSetting.projectCascade"
                />
            </template>
            <template v-slot:left="{data}">
                <GanttLeft 
                    :slotData="data"
                    @showContextMenu="showContextMenuHandler"
                    @getGanttBlockTree="getGanttBlockTree"
                    :groupStyle="ganttSetting.groupStyle"
                    @updateSetting="updateSetting"
                    :groupBy="ganttSetting.groupBy"
                    :projectCascade="ganttSetting.projectCascade"
                />
            </template>
            <template v-slot:title style="width:500px">
                <GanttTitle
                    :title="form.label"
                    @addRecord="addRecord"
                />
            </template>
        </v-gantt-chart>
        <v-menu 
            nudge-bottom="40"
            v-if="showContextMenu"
            :attach="contextMenuAttach"
            v-model="showContextMenu"
            absolute
            content-class="ganttTaskContextMenu"
            transition="scroll-y-transition"
            tile
            style="z-index:200"
            offset-overflow
            z-index="999"
        >
            <v-card tile class="elevation-0">
                <v-card-text class="pa-0">
                    <v-list class="denseList">
                        <v-list-item-group>
                            <v-list-item
                                @click="openEditTaskDialog({task: currentItem})"
                            >
                                <v-icon class="flex-grow-0 pr-1">mdi-pencil</v-icon>
                                Edit Item
                            </v-list-item>
                            <v-list-item @click="contextMenuActionHandler({command: 'setCompleted'})">
                                <v-icon class="flex-grow-0 pr-1">mdi-gate-and</v-icon>
                                Set to completed
                            </v-list-item>
                            <v-list-item v-if="isMilestoneSet" @click="contextMenuActionHandler({command: 'setMilestone'})">
                                <v-icon class="flex-grow-0 pr-1">mdi-gate-and</v-icon>
                                Set/Remove milestone
                            </v-list-item>
                            <!-- <v-list-item style="border-bottom: 1px solid#e9e9e9; border-top: 1px solid#e9e9e9">
                                <v-icon class="flex-grow-0 pr-1">mdi-content-duplicate</v-icon>
                                Duplicate
                            </v-list-item>
                            <v-list-item>
                                <v-icon class="flex-grow-0 pr-1">mdi-delete</v-icon>
                                Delete
                            </v-list-item>
                            <v-list-item>
                                <v-icon class="flex-grow-0 pr-1">mdi-archive</v-icon>
                                Archive
                            </v-list-item> -->
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-menu>
        <EditTaskDialog 
            v-model="showEditTaskDialog"
            ref="editTaskDialog"
            :ganttSetting="ganttSetting"
            :form="form"
        />
        <popupRecordDialog
            ref="gantt-popupRecordDialog"
        />
        <gantt-setting-drawer
            v-if="showingDrawer" 
            v-model="showingDrawer"
            :form="form"
            :ganttSetting="ganttSetting"
            @updateSetting="updateSetting"
            :formInfoDict="formInfoDict"
        />
    </div>
    <!-- <div v-else class="" style="width:100%; height:50vh; border: 1px solid #d9d9d9">
        <div>
            test
        </div>
    </div> -->
</template>

<script>
    import vGanttChart from 'v-gantt-chart';
    import GanttLeft from './GanttLeft'
    import GanttBlock from './GanttBlock'
    import GanttTimeline from './GanttTimeline'
    import GanttMarkline from './GanttMarkline'
    import GanttTitle from './GanttTitle'
    import EditTaskDialog from '../dialog/EditTaskDialog'
    import moment from 'moment' 
    import popupRecordDialog from "@/components/dialogs/PopupRecordDialog"
    import GanttSettingDrawer from "../dialog/GanttSettingDrawer"
    export default {
        props:{
            datas: Array,
            currentApp: Object,
            form: Object,
            view: Object,
            ganttSetting: Object,
            showingDrawer: Boolean,
            searchText: String,
            isMilestoneSet: Boolean
        },
        data(){
            // var startTime = moment().add(-1, 'years').format("yyyy-MM-DD 00:00:00")
            // var endTime = mommoment().add(1, 'years').format("yyyy-MM-DD 23:59:59")
            var startTime = moment().add(-1, 'years').set("hour", 0).set("minute", 0).set('second', 0).toString();
            var endTime = moment().add(1, 'years').set("hour", 23).set("minute", 59).set('second', 59).toString();
            return{
                // timeLines: [{title:'now',time: moment().toDate().format("yyyy mm dd HH:MM:ss"),color:'#00000'}],
                startTime: startTime,
                endTime: endTime,
                // timeLines: [{title:'now',time: moment().toDate().format("yyyy-mm-dd 23:59:59"),color:'#00000'}],
                currDate: null,
                timer: null,
                // cellWidth: { label: 'thumb-color', val: 80, color: 'teal' },
                showContextMenu: false,
                contextMenuAttach: '',
                currentItemId: '',
                showEditTaskDialog: false,
                tasks: {},
                formInfoDict: {},
                parentTaskIds: []
            }
            
        },
        components:{
            vGanttChart,
            GanttLeft,
            GanttBlock,
            GanttTimeline,
            GanttMarkline,
            GanttTitle,
            EditTaskDialog,
            popupRecordDialog,
            GanttSettingDrawer
        },
        created(){
            this.loadMultipleFormInfos();
            // let vm = this
            // this.timer = setInterval(function(){
            //     if(vm.timeLines[0]){
            //         vm.timeLines[0].time =  moment().toDate().format("yyyy mm dd HH:MM:ss")
            //     }
            // }, 1000);
        },
        computed:{
            currentItem(){
                if(this.currentItemId){
                    var task = this.tasks[this.currentItemId]
                    return task
                }
                return task
            },
            today(){
                return moment().toDate().format("yyyy-mm-dd 23:59:59")
            },
            timeLines(){
                return this.ganttSetting.showTodayIndication?
                    // [{title:'now',time: moment().toDate().format("yyyy-mm-dd 23:59:59"),color:'#00000'}]
                    [{title:'now',time: moment().set('hour', 23).set('minute', 59).set('second', 59).toString(),color:'#00000'}]
                    :[]
            }
        },
        watch:{
            // 'ganttSetting.timeView'(val){
            //     var defaultWidth = {
            //         DAY: 80,
            //         WEEK: 50,
            //         MONTH: 20,
            //         QUARTER: 5,
            //     }
            //     this.ganttSetting.cellWidth = defaultWidth[val]
            // },
            searchText(val){
                let vm = this
                this.fetchData()
                return
                var res = Object.keys(this.tasks).filter(key=>{
                    return this.tasks[key].name.includes(this.searchText) && !this.tasks[key].children.length
                    // return this.tasks[key].name.includes(this.searchText) && !this.tasks[key].children.length
                })
                
                console.log('searchText::res', res)
                res = res.map(item=>this.tasks[item])
                var treeArr = []
                var parentCount = 0
                const buildDataTree = (arr, type)=>{
                    if(type=='groupChildren'){
                        if(!arr.length) return
                        var parentList = {}
                        arr.forEach(item=>{
                            if(item.parent){
                                parentList[item.parent] = parentList[item.parent] || []
                                parentList[item.parent].push(item)
                            }else{
                                parentCount++
                                treeArr.push({
                                    _id: parentCount,
                                    gtArray: [item]
                                })
                            }
                        })
                        buildDataTree(parentList,'mapParent')
                    }else if(type=='mapParent'){
                        console.log ('mapParent', arr)
                        var newTree = Object.keys(arr).map(key=>{
                            var task = vm.tasks[key]
                            task.children = arr[key]
                            return task
                        })
                        newTree.forEach(item=>{
                            vm.updateParentTask(item.children[0]._id)    
                        })
                        buildDataTree(newTree, 'groupChildren')
                    }
                }
                buildDataTree(res,'groupChildren')
                this.datas = treeArr
            }
        },
        methods:{
            testMouseup(){
                alert('testMouseup')
            },
            wrapperClick(){
                alert('wrapperClick')
            },
            contentClick(){
    
            },
            addRecord(){
                let vm = this
                var formId = vm.form._id
                var form = vm.form
                var recordPayload = {
                    formInfo: form,
                    mode: 'new',
                    onSubmitted: this.updateFromEditDialog
                }
                this.$refs['gantt-popupRecordDialog'].open(recordPayload)
                return
                this.$refs.editTaskDialog.task = JSON.parse(JSON.stringify(payload.task))
                this.showEditTaskDialog = true
            },
            updateSetting(payload){
              this.$emit('updateSetting', payload)  
            },
            updateRecord(payload){
                let vm = this
                var ganttSetting = this.ganttSetting.projectCascade.find(item=>{
                    return item.relatedFormId == payload.formId
                })
                var obj = {
                    data:{
                        data: {
                            [ganttSetting.col_start]: payload.start.substring(0,10),
                            [ganttSetting.col_end]: payload.end.substring(0,10),
                            [ganttSetting.col_milestone]: payload.milestone,
                            [ganttSetting.col_progress]: payload.progress,
                            _id: payload._id,
                            formId: payload.formId
                        },
                        isNew: false,
                    },
                    urlCommand: '/data'
                }
                this.$store.dispatch('AUTH_POST', obj).then(
                    response => {
                        // console.log("response", response)
                        // var currentTask = this.tasks[response._id]
                        // currentTask.dateProgress = this.calculateDateProgress(currentTask.start, currentTask.end)
                        // this.updateParentTask(response._id)
                        vm.updateFromEditDialog(response, true)
                    }
                )
            },
            calculateDateProgress(start, end){
                var tDate = new Date(moment().format('YYYY-MM-DD 23:59:59'))
                var sDate = new Date(start)
                var eDate = new Date(end)
                if(tDate>=eDate)
                    return 100
                else if(tDate<sDate)
                    return 0
                else{
                    return ((tDate - sDate)/(eDate - sDate))*100
                }
            },
            updateParentTask(id){
                var parent =  this.tasks[id].parent || null
                if(parent){
                    let parentId = parent
                    parent = this.tasks[parentId]
                    let parentStart = parent.start
                    let parentEnd = parent.end    
                    let minStart, maxEnd
                    let progress = 0
                    let childCount = 0
                    var milestoneCount = 0
                    parent.children.forEach(child=>{
                        childCount++                        
                        if(child.milestone!='' && child.milestone!=null){
                            if(!minStart || new Date(child.milestone + ' 00:00:00') < new Date(minStart))
                                minStart = child.milestone + ' 00:00:00'
                            if(!maxEnd || new Date(child.milestone+' 23:59:59') > new Date(maxEnd))
                                maxEnd = child.milestone + ' 23:59:59'
                            milestoneCount++
                        }else{
                            if(!minStart || new Date(child.start) < new Date(minStart))
                                minStart = child.start
                            if(!maxEnd || new Date(child.end) > new Date(maxEnd))
                                maxEnd = child.end
                            progress+= child.progress
                        }
                    }) 
                    console.log({
                        task: this.tasks[parentId].label,
                        minStart,
                        maxEnd,
                        progress,
                        'childCount-milestoneCount': childCount-milestoneCount
                    })
                    if(childCount-milestoneCount==0)
                        progress = 0
                    else
                        progress = progress/(childCount-milestoneCount)
                    this.tasks[parentId].start = minStart
                    this.tasks[parentId].end = maxEnd
                    this.tasks[parentId].progress = progress
                    this.tasks[parentId].dateProgress = this.calculateDateProgress(minStart, maxEnd)
                    
                    this.tasks[parentId].children.sort(function(a,b){
                        return new Date(a.start) - new Date(b.start);
                    })
                    this.updateParentTask(parent._id)
                }
            },
            openEditTaskDialog(payload){
                var formId = payload.task.formId
                var form = this.formInfoDict[formId]
                
                var recordPayload = {
                    formInfo: form,
                    mode: 'edit',
                    onSubmitted: this.updateFromEditDialog,
                    recordId: payload.task._id
                }
                this.$refs['gantt-popupRecordDialog'].open(recordPayload)
                
                return
                this.$refs.editTaskDialog.task = JSON.parse(JSON.stringify(payload.task))
                this.showEditTaskDialog = true
            }, 

            async getGanttRecord(record){
                let vm = this
                // const params = {
                //     appId: record.appId,
                //     formId: record.formId,
                //     recordId: record._id, 
                //     viewId: vm.view._id,
                //     searchText: vm.searchText
                // }
                // var res = await vm.$store.dispatch("GET_GANTT_RECORD", params)
                const params = {
                    appId: record.appId,
                    formId: record.formId,
                    recordId: record._id, 
                    viewId: vm.view._id,
                    searchText: vm.searchText,
                    viewId: vm.view._id,
                    ...vm.ganttSetting,
                }
                console.log('params', params)
                var res
                await vm.$store.dispatch("FETCH_PROJECT_DATA", params).then((response) => {
                    res = response
                })
                return res[0]
            },
            
            nestedPushTasks(record){
                let vm = this
                var recordId = record._id
                vm.$set(vm.tasks, recordId, record)
                if(record.children.length){
                    record.children.forEach(item=>{
                        vm.nestedPushTasks(item)
                    })
                }
            },
            getRoot(recordId){
                var task = this.tasks[recordId]
                if(task.parent)
                    return this.getRoot(task.parent)
                else
                    return recordId
                
            },
            async updateFromEditDialog(payload, isOtherUpdate=false){
                let vm = this
                var mode = vm.$refs['gantt-popupRecordDialog'].mode || ''
                var rootRecord
                if(mode=='new' && !isOtherUpdate){
                    //new record
                    rootRecord = payload
                }else{
                    //edit record
                    var rootId = vm.getRoot(payload._id)
                    rootRecord = vm.tasks[rootId]
                }
                await vm.getGanttRecord(rootRecord).then((response)=>{
                    console.log('getGanttRecord::response', response)
                    var updatedRecord = response
                    vm.nestedPushTasks(updatedRecord)
                    
                    if(!vm.parentTaskIds.includes(updatedRecord._id)){
                        vm.parentTaskIds.push(updatedRecord._id)
                        vm.datas.splice(vm.datas.length-1, 0, {
                            _id: vm.datas.length+1,
                            gtArray: [updatedRecord]
                        });
                        // vm.datas.push({
                        //     _id: vm.datas.length+1,
                        //     gtArray: [updatedRecord]
                        // })
                    }else{
                        
                        var pos = vm.parentTaskIds.indexOf(updatedRecord._id)
                        vm.datas[pos].gtArray = [updatedRecord]
                    }
                })
                return
                console.log('updateFromEditDialog::payload', payload)
                // let vm = this
                let level = vm.ganttSetting.projectCascade.findIndex(cascade=>{
                    return cascade.relatedFormId == payload.formId
                })
                var recordSetting = vm.ganttSetting.projectCascade[level]
                var isLeaf = false
                if(level==vm.ganttSetting.projectCascade.length-1)
                    isLeaf = true
                
                if(isLeaf){
                    var parentSetting = vm.ganttSetting.projectCascade[level-1]
                    var obj = {
                        _id: payload._id,
                        formId: payload.formId,
                        appId: payload.appId,
                        label: payload[recordSetting.col_project],
                        name: payload[recordSetting.col_project],
                        start: payload[recordSetting.col_start]+ ' 00:00:00',
                        end: payload[recordSetting.col_end] + ' 23:59:59',
                        progress: parseFloat(payload[recordSetting.col_progress]),
                        milestone: payload[recordSetting.col_milestone],
                        parent: parentSetting.relatedFormId,
                        children: []
                    }
                    obj.dateProgress = this.calculateDateProgress(obj.start, obj.end)
                    console.log('obj---', obj)
                }else{
                    console.log('$refs---', this.$refs['gantt-popupRecordDialog'])
                    return
                    var relatedFieldId = this.ganttSetting.projectCascade[1].fieldId
                    
                    var relatedData = this.$refs['gantt-popupRecordDialog'].relatedTableData[relatedFieldId]
                    console.log('relatedData---', relatedData)
                    
                    relatedData = relatedData.map(item=>{
                        var currentGanttSettingIndex = vm.ganttSetting.projectCascade.findIndex(cascade=>{
                            return cascade.relatedFormId == item.formId
                        })
                        var currentGanttSetting = vm.ganttSetting.projectCascade[currentGanttSettingIndex]
                        if(currentGanttSettingIndex!=vm.ganttSetting.projectCascade.length-1){
                            return {
                                _id: item._id,
                                formId: item.formId,
                                appId: item.appId,
                                label: item[currentGanttSetting.col_project],
                                name: item[currentGanttSetting.col_project],
                                start: item[currentGanttSetting.col_start]+ ' 00:00:00',
                                end: item[currentGanttSetting.col_end] + '23:59:59',
                                progress: parseFloat(item[currentGanttSetting.col_progress]),
                                milestone: item[currentGanttSetting.col_milestone],
                                parent: payload._id,
                                children: [],
                            }
                        }else{
                            return {
                                
                            }
                        }
                    })

                    // var obj = {
                    //     _id: payload._id,
                    //     formId: payload.formId,
                    //     appId: payload.appId,
                    //     label: payload[recordSetting.col_project],
                    //     name: payload[recordSetting.col_project],
                    //     start: payload[recordSetting.col_start]+ ' 00:00:00',
                    //     end: payload[recordSetting.col_end] + ' 23:59:59',
                    //     progress: parseFloat(payload[recordSetting.col_progress]),
                    //     milestone: payload[recordSetting.col_milestone],
                    //     parent: parentSetting.relatedFormId,
                    //     children: []
                    // }
                }
                return
                var relatedFieldId = this.ganttSetting.projectCascade[1].fieldId
                // console.log('relatedTableId---', relatedFieldId)
                var relatedData = this.$refs['gantt-popupRecordDialog'].relatedTableData[relatedFieldId]
                console.log('relatedData---', relatedData)
                relatedData = relatedData.map(item=>{
                    var currentGanttSetting = vm.ganttSetting.projectCascade.find(cascade=>{
                        return cascade.relatedFormId == item.formId
                    })
                    return {
                        _id: item._id,
                        formId: item.formId,
                        appId: item.appId,
                        label: item[currentGanttSetting.col_project],
                        name: item[currentGanttSetting.col_project],
                        start: item[currentGanttSetting.col_start]+ ' 00:00:00',
                        end: item[currentGanttSetting.col_end] + '23:59:59',
                        progress: parseFloat(item[currentGanttSetting.col_progress]),
                        milestone: item[currentGanttSetting.col_milestone],
                        parent: payload._id,
                        children: [],
                    }
                })
                relatedData.forEach(item=>{
                    if(!this.tasks.hasOwnProperty(item._id)){
                        this.tasks[item._id] = item
                    }
                })
                this.tasks[payload._id].children = relatedData
                var ganttSetting = this.ganttSetting.projectCascade.find(item=>{
                    return item.relatedFormId == payload.formId
                })
                // var label = payload[ganttSetting.labelBy]||''
                var label = payload[ganttSetting.col_project]
                var name = payload[ganttSetting.col_project]
                var start = payload[ganttSetting.col_start]
                var end = payload[ganttSetting.col_end]
                var progress = parseFloat(payload[ganttSetting.col_progress])
                var milestone = payload[ganttSetting.col_milestone]
                var task = this.tasks[payload._id]
                task.start = start + ' 00:00:00'
                task.end = end + ' 23:59:59'
                task.progress = progress
                task.milestone = milestone
                task.name = name
                task.label = label
                task.dateProgress = this.calculateDateProgress(task.start, task.end)
                this.updateParentTask(payload._id)
            },
            OnScroll(div) {
                console.log('scroll')
                // var div1 = document.getElementById("col1");
                // div1.scrollTop = div.scrollTop;
            },
            getCurrentTask(){
                const loopThrough = (arr)=>{
                    if(arr._id==this.currentItemId)
                        return arr
                    if(arr.children && arr.children.length){
                        try{
                            arr.children.forEach(child=>{
                                var res = loopThrough(child)
                                if(res) {
                                    throw(res)
                                }
                            })
                        }catch(res){
                            return res
                        }
                    }
                }
                try{
                    this.datas.forEach(data=>{
                        try{
                            data.gtArray.forEach((gt,index,array)=>{
                                var res = loopThrough(gt)
                                if(res) throw(res)
                            })
                        }catch(res){
                            throw(res)
                        }
                    })
                }catch(res){
                    return res
                }
            },
            contextMenuActionHandler(payload){
                var currentTask = this.getCurrentTask()
                switch(payload.command){
                    case 'setCompleted':
                        currentTask.progress = 100
                    break
                    case 'setMilestone':
                        if(currentTask.milestone!=''&&currentTask.milestone!=null){
                            currentTask.start = currentTask.milestone.substr(0,10) + ' 00:00:00'
                            currentTask.end = currentTask.milestone.substr(0,10) + ' 23:59:59'
                            currentTask.milestone = ''
                        }
                        else{
                            currentTask.milestone = currentTask.end.substr(0,10)
                            currentTask.start = ''
                            currentTask.end = ''
                        }
                    break
                }
                this.updateRecord(currentTask)
            },
            showContextMenuHandler(itemId){
                var attach = '#contextMenuIcon_'+itemId
                this.currentItemId = itemId
                this.contextMenuAttach = attach
                
                this.showContextMenu = true

                //-180
            },
            async fetchData(recordId){
                let vm = this
                vm.tasks = []
                vm.parentTaskIds = []
                vm.datas = []
                const params = {
                    appId: vm.currentApp._id,
                    formId: vm.form._id,
                    viewId: vm.view._id,
                    ...vm.ganttSetting,
                    searchText: vm.searchText,
                    recordId
                }
                await vm.$store.dispatch("FETCH_PROJECT_DATA", params).then((response) => {
                    console.log("response", response)
                    var res = response
                    if(recordId) return res
                    if(!res.length) {
                        vm.datas = []
                        return
                    }
                    var minStart = ""
                    var maxEnd = ""
                    
                    this.datas = res.map((item,index)=>{
                        var start = moment(item.start)
                        var end = moment(item.end)
                        if(minStart=="" || minStart.isAfter(start))
                            minStart = start
                        if(maxEnd=="" || end.isAfter(maxEnd))
                            maxEnd = end
                        var obj = {}
                        obj._id = index+1,
                        // item.start = moment(item.start)
                        // item.end = moment(item.end)
                        obj.gtArray = [item]
                        vm.parentTaskIds.push(item._id)
                        return obj
                    })
                    
                    console.log('minStart-minEnd', {minStart, maxEnd})
                    // this.startTime = minStart.add(-1, 'years').format("yyyy-MM-DD 00:00:00")
                    // this.endTime = maxEnd.add(1, 'years').format("yyyy-MM-DD 23:59:59")
                    console.log({
                        start: this.startTime,
                        end: this.endTime
                    })
                    this.$emit('update_datas', this.datas)
                    
                    vm.tasks = {}
                    const loopTasks = (task)=>{  
                        task.show = true
                        if(![null, undefined, ""].includes(task.start))
                            task.start = moment(task.start).toString()
                        if(![null, undefined, ""].includes(task.end))
                            task.end = moment(task.end).toString()
    
                        vm.tasks[task._id] = task
                        if(task.children && task.children.length){
                            task.children.sort(function(a,b){
                                return moment(a.start) - moment(b.start);
                            })
                            task.children.forEach(c=>loopTasks(c))
                        }
                    }
                    this.datas.forEach(item=>{
                        loopTasks(item.gtArray[0])
                    })
                    this.datas.push({
                        _id: 'empty', 
                        gtArray:[]
                    })
                    this.$emit('doScrollToTime')
                }).catch(error=>{
                    console.log(error)
                    return
                })
            },
            async updateLabelBy(fieldIds){
                let vm = this
                var taskIds = Object.keys(this.tasks).filter(item=>item!="UNASSIGNED")
                var formId = vm.ganttSetting.projectRelation=="MULTIPLE_RELATED"?
                    vm.ganttSetting.projectCascade[vm.ganttSetting.projectCascade.length-1].relatedFormId:
                    vm.form._id
               
                const params = {
                    appId: vm.currentApp._id,
                    formId: formId,
                    viewId: vm.view._id,
                    ...vm.ganttSetting,
                    ids:taskIds, 
                    fieldIds
                }
                await vm.$store.dispatch("GET_DATA_FIELD", params).then((response) => {
                    console.log("response", response)
                    var updateSet = response
                    updateSet.forEach(item=>{
                        this.tasks[item._id].label = item[this.ganttSetting.labelBy]
                    })
                })
            },
            async updateHoverInfos(fieldIds){
                var taskIds = Object.keys(this.tasks).filter(item=>item!="UNASSIGNED")
                let vm = this
                const params = {
                    appId: vm.currentApp._id,
                    formId: vm.form._id,
                    viewId: vm.view._id,
                    ...vm.ganttSetting,
                    ids:taskIds, 
                    fieldIds
                }
                await vm.$store.dispatch("GET_DATA_FIELD", params).then((response) => {
                    console.log("response", response)
                    var updateSet = response
                    console.log("this.ganttSetting.hoverInfos", this.ganttSetting.hoverInfos)
                    updateSet.forEach(item=>{
                        var hoverInfos = []
                        this.ganttSetting.hoverInfos.map(info=>{
                            if(info.fieldId){
                                var obj = {}
                                obj.key = this.form.fieldInfos.find(field=>field.fieldId == info.fieldId).label
                                obj.value = item[info.fieldId]
                                hoverInfos.push(obj)
                            }
                        })
                        this.tasks[item._id].hoverInfos = JSON.parse(JSON.stringify(hoverInfos))
                    })
                })
            },
            getGanttBlockTree(payload){
                // console.log('ganttBlockTree_'+payload._id)
                payload.callback(this.$refs['ganttBlockTree_'+payload._id])
            },
            loadMultipleFormInfos(callback) {
                const vm = this;
                var promises = [];
                vm.formInfoDict = {};
                vm.formInfoDict[vm.form._id] = vm.form;

                if (
                    vm.ganttSetting.projectCascade &&
                    vm.ganttSetting.projectCascade.length > 0
                ) {

                    // console.log('vm.parentChildFieldFormList: ', vm.parentChildFieldFormList)        
                    // console.log('vm.parentChildFieldFormList && vm.parentChildFieldFormList.length > 0')
                    for (let i = 1; i < vm.ganttSetting.projectCascade.length; i++) {
                    const loopItem = vm.ganttSetting.projectCascade[i];
                    const ds = {
                        teamId: loopItem.relatedTeamId,
                        appId: loopItem.relatedAppId,
                        formId: loopItem.relatedFormId
                    }
                    // console.log('loadMultipleFormInfos: ds: ', ds)
                    promises.push(
                        vm.$store.dispatch("FETCH_DATASOURCE_INFO", ds)
                    );
                    }
                    Promise.all(promises).then(infos => {
                    for (let j = 0; j < infos.length; j++) {
                        const loopInfo = infos[j];
                        vm.formInfoDict[loopInfo._id] = loopInfo;
                    }
                    if (typeof callback === "function") {
                        callback(vm.formInfoDict);
                    }
                    });
                } else {
                    Promise.resolve({})
                }
            },
        }
    }
</script>
<style>
    .gantt-header-title{
        border-right: 1px solid #f0f0f0;
    }
    .ganttTaskContextMenu{
        max-width:100% !important;
        width:100%;
    }
    .gantt-table{
        overflow: auto;
        /* height: 680px; */
        min-height:100% !important;
        height: 100% !important;
    }
    .gantt-leftbar-wrapper{
        height:fit-content !important;
        /* min-height: 680px !important; */
        min-height:100% !important;
        /* display: flex !important;
        flex-direction: column !important; */
        /* height: 1000px !important */
        /* overflow: auto !important */

    }
    .gantt-blocks-wrapper{
        height: fit-content !important;
        /* min-height: 680px !important; */
        min-height:100% !important;
        display: flex;
        flex-direction: column;
        /* display: flex !important;
        flex-direction: column !important;
        height: auto !important */
        /* overflow: auto !important */
        /* height: 1000px !important */
    }
    .gantt-leftbar{
        height: auto !important;
        /* min-height:700px !important; */
        min-height:100% !important;
        display: flex;
        flex-direction: column;
    }
    .gantt-blocks {
        height: auto !important;
        background-repeat: repeat !important;
        /* min-height:700px !important; */
        min-height:100% !important;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .gantt-container{
        width:100% !important;
        height:100% !important;
    }
    .gantt-leftbar-item :last-child{
        flex-grow: 1
    }
    .gantt-block :last-child {
        flex-grow: 1
    }
</style>