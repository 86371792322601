<template>
<div class="splitter-list"
  v-resize="onResize">
  <div v-for="(splitter, index) in splitterPositions"
       class="splitter-container py-2"
      :style="{'left':(splitter - positions.rowLeft - adjustment)+'px', 'height':rowHeight+'px'}">
      <div class="splitter-wrapper yellow">
        <draggable-splitter
            :style="{display:(draggingIndex===-1||draggingIndex===index ? 'block' : 'none')}"
            class="draggable-splitter"
            :splitterIndex="index"
            :splitterLeft="splitter"
            @onCommand="onCommandHandler"
            style="height: 100%;"></draggable-splitter>
        <!--<div style="position:absolute;top:-5px;;left:0;">{{ splitter }}</div>-->
      </div>
  </div>
  <!--<div style="position:absolute;top:-20px;">-->
    <!--splitterLeft: {{ showSplitterLeft }} dragging: {{ dragging ? 'yes' : 'no' }} currRowWidths: {{ currRowWidths }}-->
    <!--&lt;!&ndash;<v-btn @click="decColWidth(0)">decColWidth</v-btn>&ndash;&gt;-->
  <!--</div>-->
</div>
</template>

<script>
import adminFormMixin from '@/mixins/adminFormMixin'
import draggableSplitter from './DraggableSplitter'

export default {
  components: {
    draggableSplitter
  },
  mixins: [adminFormMixin],
  props: {
    row: Array,
    rowId: String,
    positions: Object // {rowLeft, colBreakPoints}
  },
  data () {
    return {
      draggingIndex: -1,
      // breakPoints: [],
      rowLeft: 0,
      adjustment: 18,
      left: 0,
      showSplitterLeft: 0,
      splitterPositions: [],
      rowHeight: 100
    }
  },
  watch: {
    positions: {
      handler: function (newVal) {
        // console.log('watch(positions)')
        const vm = this
        vm.rowLeft = newVal.rowLeft
        // vm.updateBreakPoints(newVal.colBreakPoints)
        vm.updateSplitterPositions()
      },
      deep: true
    },
    totalWidth: function(newVal) {
      this.updateSplitterPositions()
    },
    row: {
      handler: function(newVal) {
        const vm = this
        // vm.updateSplitterPositions()
        vm.updateRowHeight()
      },
      deep: true
    }
  },
  methods: {
    updateRowHeight () {
      const vm = this
      // console.log('mounted :: vm.$parent: ', vm.$parent)
      // console.log('mounted :: vm.$el.parent: ', vm.$el.parent)
      vm.rowHeight = document.getElementById(vm.rowId).offsetHeight
    },
    onResize () {
      const vm = this
      vm.$nextTick(() => {
        this.updateSplitterPositions()
      })
    },
    getNextPosition (p) {
      const vm = this
      const breakPoints = vm.positions.colBreakPoints
      // console.log('getNextPosition p = ' + p + ', positions.colBreakPoints.length = ' + breakPoints.length)
      // console.log('getNextPosition vm.positions: ', breakPoints)
      var result = -1
      var targetIndex = -1
      for (var i = 0; i < breakPoints.length; i++) {
        if (breakPoints[i] === p) {
          targetIndex = i + 1
          break
        }
      }
      // console.log('getNextPosition targetIndex = ' + targetIndex)
      if (targetIndex <= 12) {
        result = breakPoints[targetIndex]
      }
      return result
    },

    getPrevPosition (p) {
      const vm = this
      const breakPoints = vm.positions.colBreakPoints
      var result = -1
      var targetIndex = -1
      for (var i = 0; i < breakPoints.length; i++) {
        if (breakPoints[i] === p) {
          targetIndex = i - 1
          break
        }
      }
      if (targetIndex > 0) {
        result = breakPoints[targetIndex]
      }
      return result
    },

    getColWidthChanged (splitterLeft, splitterIndex) {
      const vm = this
      // console.log('getColWidthChanged :: splitterLeft=' + splitterLeft + ',  splitterIndex=' + splitterIndex)

      const oldColWidth = vm.getFieldProperty(vm.row[splitterIndex]._id, 'width')
      var colWidthChanged = 0

      // console.log('getColWidthChanged :: oldColWidth = ' + oldColWidth)

      const nextPosition = vm.getNextPosition(vm.currColLefts[splitterIndex])
      // const nextPosition = vm.getNextPosition(vm.splitterPositions[splitterIndex])

      // console.log('getColWidthChanged :: nextPosition = ' + nextPosition)
      if (nextPosition !== -1 && splitterLeft > nextPosition) {
        colWidthChanged = 1
      } else {
        const prevPosition = vm.getPrevPosition(vm.currColLefts[splitterIndex])
        // const prevPosition = vm.getPrevPosition(vm.splitterPositions[splitterIndex])
        // console.log('getColWidthChanged :; prevPosition = ' + prevPosition)
        if (prevPosition !== -1 && splitterLeft < prevPosition) {
          colWidthChanged = -1
        }
      }
      return colWidthChanged
    },

    updatePosition (payload) {
      const vm = this
      // console.log('SplitterList :: updatePosition :: payload: ', payload)
      const splitterIndex = payload.splitterIndex
      const splitterLeft = payload.splitterLeft
      vm.showSplitterLeft = splitterLeft

      const colWidthChanged = vm.getColWidthChanged(splitterLeft, splitterIndex)
      // console.log('updatePosition :: splitter index=' + splitterIndex + ', left=' + splitterLeft)
      // console.log('updatePosition :: colWidthChanged = ' + colWidthChanged)
      if (colWidthChanged > 0) {
        vm.incColWidth(splitterIndex)
      } else if(colWidthChanged < 0) {
        vm.decColWidth(splitterIndex)
      }
    },

    decColWidth (splitterIndex) {
      const vm = this
      var rowWidths = vm.getRowOccupiedWidths().map(item => parseInt(item))
      // console.log('decColWidth :: before action: rowWidths: ', rowWidths)
      // if prev cell exists
      const currCellWidth = rowWidths[splitterIndex]
      if (currCellWidth > 1) {
        rowWidths[splitterIndex]--
      } else {
        for (var i = splitterIndex - 1; i >= 0; i--) {
          if (rowWidths[i] > 1) {
            rowWidths[i]--
            break
          }
        }
      }
      // rowWidths = rowWidths.map(item => item.toString())
      // console.log('decColWidth :: after actions: rowWidths: ', rowWidths)
      for (var j = 0; j < vm.row.length; j++) {
        vm.$store.dispatch('SET_FIELD_INFO_PROPERTY', {
          fieldInfoId: vm.row[j]._id,
          propertyKey: 'width',
          propertyValue: rowWidths[j]
        })
      }
    },

    incColWidth (splitterIndex) {
      const vm = this
      const initial = 0
      console.log('incColWidth :: splitterIndex = ' + splitterIndex)
      var rowFieldWidths = vm.getRowOccupiedWidths().map(width => parseInt(width))
      const totalOccupiedWidth = rowFieldWidths.reduce( (total, rowWidth) => total + rowWidth, 0)

      // if next cell exists
      const nextCellIndex = splitterIndex + 1
      if (rowFieldWidths.length > nextCellIndex) {
        const nextCellWidth = rowFieldWidths[nextCellIndex]
        console.log('incCellWidth : nextCell (index=' + nextCellIndex + '): width=' + nextCellWidth)
        // if next cell resizable (occupy >1 col)
        if (totalOccupiedWidth === 12) {
          console.log('incCellWidth : totalOccupiedWidth === 12')
          for (var i = splitterIndex +1; i < rowFieldWidths.length; i++) {
            console.log('incCellWidth : i=' + i + ': rowFieldWidth[' + i + '] = ' + rowFieldWidths[i])
            if (rowFieldWidths[i] > 2) {
              console.log('incCellWidth : i=' + i + ': rowFieldWidth[' + i + '] > 1')
              rowFieldWidths[splitterIndex]++
              rowFieldWidths[i]--
              console.log('incCellWidth : i=' + i + ': rowFieldWidth[' + i + '] = ' + rowFieldWidths[i])              
              console.log('incCellWidth : i=' + i + ': rowFieldWidth[' + (splitterIndex+1) + '] = ' + rowFieldWidths[splitterIndex+1])      
              break
            } else {
              console.log('incCellWidth : i=' + i + ': rowFieldWidth[' + i + '] <=1')
            }
          }
        } else if (nextCellWidth > 1) {
          console.log('incCellWidth : totalOccupiedWidth !== 12')
          rowFieldWidths[splitterIndex]++
        }
      } else {
        if (totalOccupiedWidth < 12) {
          rowFieldWidths[splitterIndex]++
        }
      }
      // console.log('incColWidth :: rowFieldWidths: ', rowFieldWidths)
      for (var j = 0; j < vm.row.length; j++) {
        vm.$store.dispatch('SET_FIELD_INFO_PROPERTY', {
          fieldInfoId: vm.row[j]._id,
          propertyKey: 'width',
          propertyValue: rowFieldWidths[j]
        })
      }
    },

    onCommandHandler (payload) {
      const vm = this
      // console.log('SplitterList :: onCommandHandler :: payload: ', payload)
      switch (payload.command) {
        case 'updatePosition':
          vm.updatePosition(payload)
          break
        case 'setDragging':
          vm.draggingIndex = payload.splitterIndex
          break
        case 'cancelDragging':
          vm.draggingIndex = -1
          vm.updateSplitterPositions()
          break
      }
    },

    // updateBreakPoints (breakPoints) {
    //   const vm = this
    //   vm.breakPoints = []
    //   if (breakPoints) {
    //     for (var i = 0; i < breakPoints.length; i++) {
    //       vm.breakPoints.push(breakPoints[i])
    //     }
    //   }
    // },

    getRowOccupiedWidths () {
      const vm = this
      var result = []
      for (var i = 0; i < vm.row.length; i++) {
        const fieldId = vm.row[i]._id
        const fieldWidth = vm.getFieldProperty(fieldId, 'width')
        result.push(fieldWidth)
      }
      return result
    },

    updateSplitterPositions (row=null) {
      const vm = this
      if (!row) {
        // console.log('SplitterList :: updateSplitterPositions : no row defined')
        row = vm.row
      }
      // else {
      //   console.log('SplitterList :: updateSplitterPositions : row: ', row)
      // }
      // console.log('updateSplitterPositions :: vm.row.length = ' + vm.row.length)
      // console.log('updateSplitterPositions :: vm.draggingIndex = ' + vm.draggingIndex)
      // for (var i = 0; i < vm.breakPoints.length; i++) {
      //   console.log('i=' + i + ': ' + vm.breakPoints[i])
      // }
      // console.log('updateSplitterPositions :: colBreakPoints: ', vm.breakPoints)

      if (vm.draggingIndex===-1) {
        var result = []
        // console.log('updateSplitterPositions :: vm.row: ', vm.row)
        if (row) {
          var startLeft = vm.breakPoints[0]
          // var lastEnd = startLeft
          var accWidthUnit = 0

          // console.log('updateSplitterPositions :: startLeft = ' + startLeft)
          // console.log('updateSplitterPositions :: accWidthUnit = ' + accWidthUnit)
          // console.log('updateSplitterPositions :: vm.row.length = ' + vm.row.length)

          for (var i = 0; i < row.length; i++) {
            var cell = row[i]
            var fieldInfo = vm.getFieldInfo(cell._id)
            if (fieldInfo) {
              // console.log('updateSplitterPositions :: i=' + i + ': fieldInfo: ', fieldInfo)
              if (Object.keys(fieldInfo.properties).indexOf('width') >= 0) {

                var widthUnit = fieldInfo.properties.width ?
                  parseInt(fieldInfo.properties.width) :
                  12
                accWidthUnit += widthUnit

                // console.log('accWidthUnit = ' + accWidthUnit + ', breakPoints: ', vm.breakPoints)

                const lastEnd = vm.breakPoints[accWidthUnit]
                result.push(lastEnd)

                // console.log('getSplittersFromRow ***')
                // console.log('getSplittersFromRow :: startLeft = ' + startLeft)
                // console.log('getSplittersFromRow :: lastEnd = ' + lastEnd)
                // console.log('getSplittersFromRow :: accWidthUnit = ' + accWidthUnit)

                // console.log('getSplittersFromRow :: width = ' + fieldInfo.properties.width)
                // accWidth += vm.colBreakPointsfieldInfo.properties.width
              }
            }
          }
        }
        // console.log('updateSplitterPositions :: result: ', result)
        vm.splitterPositions = result
        // console.log('SplitterList.updateSplitterPositions :: splitterPositions: ', vm.splitterPositions)
      }
    }
    //,
    // init (breakPoints) {
    //   const vm = this
    //   vm.updateBreakPoints(breakPoints)
    // },
  },
  computed: {
    totalWidth () {
      const vm = this
      var result = 0
      for (let i = 0; i < vm.currColLefts.length; i++) {
        result += vm.currColLefts[i]
      }
      return result
    },
    currColLefts () {
      const vm = this
      var result = []
      if (vm.row) {
        var startLeft = vm.breakPoints[0]
        // var lastEnd = startLeft
        var accWidthUnit = 0
        for (var i = 0; i < vm.row.length; i++) {
          var cell = vm.row[i]
          var fieldInfo = vm.getFieldInfo(cell._id)
          if (fieldInfo && fieldInfo.properties) {
            if (fieldInfo.properties.width) {
              var widthUnit = parseInt(fieldInfo.properties.width)
              accWidthUnit += widthUnit
              const lastEnd = vm.breakPoints[accWidthUnit]
              result.push(lastEnd)
            }
          }
        }
      }
      return result
    },

    // currRowWidths () {
    //   const vm = this
    //   var result = []
    //   for (var i = 0; i < vm.row.length; i++) {
    //     const fieldId = vm.row[i]._id
    //     const fieldWidth = vm.getFieldProperty(fieldId, 'width')
    //     result.push(fieldWidth)
    //   }
    //   return result
    // },

    breakPoints () {
      const vm = this
      var result = []
      if (vm.positions.colBreakPoints) {
        for (var i = 0; i < vm.positions.colBreakPoints.length; i++) {
          result.push(vm.positions.colBreakPoints[i])
        }
      }
      return result
    }
  },
  mounted () {
    this.updateRowHeight()
  }

}
</script>


<style>
  .splitter-wrapper {
    position:relative;
    width:0;
    height:100%;
    margin-left:-1px;
  }
  .splitter-container {
    position:absolute;
    width:15px;
    height:100px;
    background-color:transparent;
  }

  /*.splitter-container .draggable-splitter {*/
    /*display: none;*/
  /*}*/
  /*.splitter-container:hover .draggable-splitter {*/
    /*display: block;*/
  /*}*/
  .splitter-list {
    height:0;
    width:100%;
    position:absolute;
    color:red;
    top:0;
  }
</style>
