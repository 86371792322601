<template>
  <input-widget-container
    :isLayout="isLayout"
    :isHidden="isHidden"
    :widget="widget"
    :required="!isReadOnly && required"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :tooltipText="description"
    class="position-relative"
  >
    <div class="d-flex flex-row align-start">
      <v-text-field
        v-if="isReadOnly"
        :value="inputValue"
        :prefix="prefix"
        :suffix="suffix"
        disabled
        dense
        class="disabled"
      ></v-text-field>
      <v-text-field
        v-else
        :class="{ 'workflow-customize-form-record-hide': hideInput }"
        class="workflow-customized-form-record-element"
        @focus="focusing = true"
        @blur="onBlur"
        @keydown="onKeyDown"
        v-model.number="inputValue"
        dense
        :readonly="isReadOnly"
        :ref="fieldInfo.fieldId"
        :hide-details="isLayout"
        clearable
        single-line 
        clear-icon='mdi-close'
        background-color="white"
        :type="focusing? 'number':'text'"
        :prefix="prefix"
        :suffix="suffix"
        outlined      
        :error="hasError"
        :error-messages="errorMessage"
        :placeholder="placeholder"
      ></v-text-field>

    </div>
    <!-- <div v-if="showingCurrencyList"
      style="max-width:400px;height:300px;bottom:100%;left:0;margin-bottom:-23px;"
      class="currency-list blue lighten-4 position-absolute">
        sfdlkdsfdslkj
    </div> -->
  </input-widget-container>
</template>

<script>
import inputWidgetContainer from "./comps/InputWidgetContainer";
import mixin from "./mixin";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

export default {
  name: "inputWidgetAmount",
  mixins: [mixin],

  components: {
    inputWidgetContainer,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },

  methods: {
    validate(){
      this.hasError = false;
      this.errorMessage = "";
      if (this.isReadOnly) return true;
      if (this.rules.length > 0) {
        if (
          this.rules.some((rule) => {
            const result = rule(this.value);
            if (result !== true) {
              this.errorMessage = result;
              return true;
            }
            return false;
          })
        ) {
          this.hasError = true;
          return false;
        }
      }
      return true
    },
    onKeyDown(e) {
      //prevent inputting e
      return e.keyCode === 69 ? e.preventDefault() : true;
    },
    _parseValue(val) {
      if (this.focusing) return val
      else return (val || typeof val === 'number')? this.formatNumber(val): ""
    },
    onBlur() {
      this.focusing = false;
      this.validate()
    },
    getDefaultValue() {
      const rawData = getFieldPropertyValue(this.fieldInfo, "default", "");
      return rawData? this.formatNumber(rawData) : "";
    },

    
  },
};
</script>

<style lang="scss">
.is-layout {
  padding-bottom: 0 !important;
}
.currency-item .v-list-item__title {
  color: lightgray;
}

.currency-item:hover .v-list-item__title {
  color: black;
}

.currency-item:hover .v-chip {
  background-color: $primary;
  color: white;
}
</style>
