<template>
<!-- mode==='main'" -->
  <div class="input-panel px-3 pb-3 fill-height w-100" style="overflow-y:hidden;">    
    <input-panel-main-page v-if="settingsOk && authorized"
      :inputPanel="inputPanel"></input-panel-main-page>
    <panel-access-denied v-else-if="!authorized && !isAdmin"
      :title="inputPanel ? inputPanel.title : ''">
    </panel-access-denied>
    <input-panel-setup-page v-else
      ref="inputPanelSetupPage"
      :panelSettings="inputPanel"
      @update="updateInputPanel"></input-panel-setup-page>      
  </div>
</template>


<script>
import inputPanelMainPage from './InputPanelMainPage'
import inputPanelSetupPage from './InputPanelSetupPage'
import panelAccessDenied from '@/components/PanelAccessDenied'
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'

const DEFAULT_PANEL = {
  _id: '',
  appId: '',
  teamId: '',
  title: '',

  masterFormId: '',
  codeFieldId: '',

  cardConfig: {
    cardContentColumns: 1,
    cardDisplayFieldIds: [],
    cardDisplayFieldName: false,
    cardImageFieldId: '',
    cardImageMode: 'cover',
    cardImagePadding: 2,
    cardImagePosition: 'left',
    cardImageSize: '48',
    cardImageStretch: true,
    cardSummaryFieldId: '',
    cardWidthSize: 'large'
  },

  masterFieldForTarget: '',
  targetFormId: '',
  targetFieldId: '',
  // timeSegments: [
  //   this.DEFAULT_TIME_SEGMENT
  // ],

  displayType: 'cells', // ['cells'|'column']
  columnWidthType: 'normal',
  displayConfig: null,
  // displayConfig: {
  //    masterFieldIds: [],
  //    targetFieldIds: []
  // }
  hideIfNoRecord: false,
  filterConfig: {
    filters: [],
    filterAndOr: 'and'
  }
}

export default {
  name: 'inputPanel',
  mixins: [
    dateTimeHelperMixin
  ],
  components: {
    inputPanelMainPage,
    inputPanelSetupPage,
    panelAccessDenied
  },
  data() {
    return {
      inputPanel: null,
      authorized: false
    }
  },
  watch: {
    appId: function(newVal) {
      console.log('watch(appId)')
      this.loadSettings()
    },
    '$route.params.itemId': function(newVal) {
      console.log('watch($route.params.itemId)')
      this.loadSettings()
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    appId () {      
      return this.$store.getters.currentApp ?
        this.$store.getters.currentApp._id : ''
    },
    teamId () {
      return this.$store.getters.activeTeam ?
      this.$store.getters.activeTeam._id : ''
    },
    settingsOk () {
      const vm = this
      var result = false
      if (vm.inputPanel &&
        vm.inputPanel.masterFormId !== '' &&
        vm.inputPanel.masterFieldForTarget !== '' && 
        vm.inputPanel.targetFieldId !== '' &&
        vm.inputPanel.codeFieldId !== '') {
        result = true
      }
      return result
    },
    // DEFAULT_TIME_SEGMENT() {
    //   return {
    //     startTime: this.todayTime('YYYY-MM-DD 08:00'),
    //     endTime: this.todayTime('YYYY-MM-DD 20:00'),
    //     actions: [
    //       {
    //         dataType: 'time',
    //         fieldId: ''
    //       }
    //     ]
    //   }
    // },
    
  },
  mounted () {
    console.log('mounted')
    this.loadSettings()
  },
  methods: {
    updateInputPanel (updatedPanel) {
      console.log('updateInputPanel')
      this.checkAndAssign(updatedPanel)
      // this.inputPanel = JSON.parse(JSON.stringify(updated))
    },
    // onCreated (inputPanel) {
    //   alert('onCreated')
    //   this.inputPanel = inputPanel
    // },
    loadSettings () {
      const vm = this
      console.log('loadSettings')

      if (!vm.loading && vm.appId && vm.teamId) {
        console.log('loadSettings : appId & teamId')
        vm.loading = true
        const inputPanelId = vm.$route.params.itemId
        vm.$store.dispatch('FETCH_INPUT_PANEL', {
          inputPanelId: inputPanelId
        }).then(
          response => {
            console.log('InputPanel.loadSettings')
            vm.loading = false
            var inputPanel = JSON.parse(JSON.stringify(DEFAULT_PANEL))
            inputPanel._id = vm.$route.params.itemId
            inputPanel.appId = vm.$store.getters.currentApp._id
            inputPanel.teamId = vm.$store.getters.activeTeam._id

            if (response) {
              inputPanel = Object.assign(inputPanel, response)
              if (inputPanel.title === '') {
                inputPanel.title = inputPanel.label 
              }

              // if (inputPanel.timeSegments.length === 0) {
              //   inputPanel.timeSegments.push(
              //     vm.DEFAULT_TIME_SEGMENT
              //   )
              // }

              vm.checkAndAssign(inputPanel)
            }
          },
          error => {
            vm.loading = false
          }
        )
        console.log('loadSettings ends')
      }
    },

    checkAndAssign (inputPanel) {
      const vm = this
      console.log('InputPanel.check InputPanel.check')

      // vm.authorized = true
      // vm.inputPanel = inputPanel

      if (inputPanel.masterFormId && inputPanel.masterFormId !== '') {
        vm.checkAuth(inputPanel.masterFormId).then(
          form => {
            if  (form) {
              const targetFormId = vm.getTargetFormId(form, inputPanel.masterFieldForTarget)
              console.log('checkAndAssign: targetFormId = ' + targetFormId)
              vm.checkAuth(targetFormId).then(
                result => {
                  if (result) {
                    vm.authorized = true
                  }
                  vm.inputPanel = inputPanel
                }
              )
            } else {
              vm.authorized = false
              vm.inputPanel = inputPanel
            }
          }
        )
      } else {
        vm.authorized = false
        vm.inputPanel = inputPanel
      }
    },
    getTargetFormId(masterForm, masterFieldForTarget) {
      const vm = this
      var result = ''
      const relatedFieldInfo = masterForm.fieldInfos.find(info => info.fieldId === masterFieldForTarget)      
      if (relatedFieldInfo) {
        const targetFormId = relatedFieldInfo.properties.dataSource
        if (targetFormId && targetFormId !== '') {
          result = targetFormId
        }
      }
      return result
    },

    checkAuth (formId) {
      const vm = this
      console.log('checkAuth: formId = ' + formId)
      return new Promise(resolve => {
        vm.$store.dispatch('AUTH_GET', {
          urlCommand: '/forms/' + formId + '/permissions'
        }).then(
          response => {
            resolve(response.result)
          }
        )

      })
    },

    checkAuth2 (masterFormId, targetFormId) {
      const vm = this
      console.log('checkAuth :: masterFormId = ' + masterFormId)
      console.log('checkAuth :: targetFormId = ' + targetFormId)
      var allPromises = []
      allPromises.push(
        new Promise(resolve => {
          vm.$store.dispatch('AUTH_GET', {
            urlCommand: '/forms/' + masterFormId + '/permissions'
          })
        })
      )
      allPromises.push(
        new Promise(resolve => {
          vm.$store.dispatch('AUTH_GET', {
            urlCommand: '/forms/' + targetFormId + '/permissions'
          })
        })
      )
      promise.all(allPromises).then(responses => {
        console.log('checkAuth :: repsonses: ', responses)
        var result = true
        for (let i = 0; i < responses.length; i++) {
          if (!responses[i]) {
            result = false
            break
          }
        }
        resolve(result)
      })

/*
.then(
            response => {
              console.log('checkAuth :: responseS: ', response)
              resolve(response)
            }
          )
          */
    }
  }
}
</script>

<style>
.config-attribute-list tr td {
  line-height:1;
}
  
.config-attribute-list tr td:first-child {
  text-align: right;
  padding-right: 10px;
}

.config-attribute-list tr td:nth-child(n+1) {
  width: 100%;
}

.detection-time-table tr td:first-child {
  color: darkgrey;
  padding-right: 10px;
}
</style>