<template>
<div class="popup-menu">
  <v-list class="py-0 popup-menu-list denseList" style="top:200px">
    <!-- <v-subheader class="justify-center menu-header" dark>
      {{ title }}
    </v-subheader> -->
    <template v-for="(item, index) in menu">
      <v-divider v-if="index===menu.length-1"></v-divider>
      <v-list-item
          :color="index===menu.length-1 ? 'red' : ''"
          :key="item.value"
          class="d-flex flex-row align-center"
          @click="onFunctionMenuClicked(item.value)">
        <v-list-item-icon class="">
          <v-icon v-if="(item.value=='hide') && hidden">mdi-eye</v-icon>
          <v-icon v-else :color="item.value==='delete'?'error':''">{{ item.tagType }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title :class="index===menu.length-1 ? 'error--text': ''" class="ml-2">
          <div v-if="(item.value=='hide')">
            <span v-if="!hidden">{{ $t('menu.' + item.captionTag) }}</span>
            <span v-else>{{ $t('menu.' + 'visibleFromNavigation') }}</span>
          </div>
          <div v-else-if="item.value=='move'">{{ $t('menu.' + item.captionTag) }}</div>
          <div v-else>{{ $t('menu.' + item.captionTag) }}</div>
          
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</div>
</template>

<script>
import appStyleMixin from '@/mixins/appStyleMixin'

export default {
  mixins: [appStyleMixin],
  props: {
    menu: {
      type: Array,
      default () {
        return []
      }
    },
    dataId: String,
    title: String,
    selected: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    app () {
      return this.$store.getters.currentApp
    },
  },
  methods: {
    onFunctionMenuClicked (command) {
      const vm = this
      // console.log('onFunctionMenuClicked :: dataId = ' + vm.dataId)
      // console.log('onFunctionMenuClicked :: command = ', command)
      vm.$emit('onCommand', {
        command: command,
        id: vm.dataId
      })
      // switch (command) {
      //   case 'rename':
      //     break
      //   case 'duplicate':
      //     break
      //   case 'move':
      //     break
      //   case 'hide':
      //     break
      //   case 'delete':
      //     break
      //   default:
      //     if (vm.module.menu.length === 0) {
      //       vm.$emit('onCommand', {
      //         command: 'deleteModule',
      //         moduleId: vm.module._id
      //       })
      //     }
      //
      // }
    }
    // ,
    // showTabMenu () {
    //   const vm = this
    //   vm.$emit('onCommand', {
    //     command: 'showTabMenu',
    //     module: vm.module
    //   })
    // }
  }
}
</script>

<style lang="scss">


/* .popup-menu-list .v-list-item__icon {
  margin: 8px 0;
} */

/* .popup-menu-list .v-list-item {
  min-height: 24px;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
} */



.popup-menu-list .menu-header {
  background-color: $primary !important;
  color: white;
  height: 24px;
}

/* .popup-menu-list .v-list-item__icon {
  margin: 0 8px 0 0 !important;
  align-self: center;
} */
// .module-tab-bar-content{
//   transform: translate(-100px, 10px) !important;
// }
</style>
