export default {
  currentForm: (state) => {
    return state.currentForm;
  },
  currentFormIcon: (state) => {
    return state.currentFormIcon;
  },
  formLabel: (state) => {
    return state.currentForm ? state.currentForm.label: ''
  },
  relatedTableInfos: (state) => {
    return state.relatedTableInfos ? state.relatedTableInfos : [];
  },
  relatedTableIds: (state) => {
    return state.relatedTableInfos ? Object.keys(state.relatedTableInfos) : []
  },
  showFormLog:(_, getters) => {
    const form = getters.currentForm;
    if (!form || !form.formFunction) return false;
    else return form.formFunction.showLog.roleType === 1 ? getters.isAdmin :true
  },
  showFormDiscussion: (_, getters)=> {
    const form = getters.currentForm;
    if (!form || !form.formFunction) return false;
    else return form.formFunction.showDiscussion
  },
  printTemplates: (state) => {
    return state.printTemplates
  }
};
