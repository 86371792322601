<template>
  <div>
    <div v-if="appInfo.logoType==='icon' || !appInfo.logoMediaId">
      <v-icon>{{ appInfo.logoIcon ? appInfo.logoIcon : 'mdi-file-question' }}</v-icon>
    </div>
    <div v-else class="logo-image">
   <img :src="logoImgSrc"></img>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appInfo: Object
  },
  computed: {
    logoImgSrc () {
      const vm = this
      return vm.appInfo.logoType === 'image'
        ? vm.appInfo.logoMediaImageUrl
        : '';

      // var url = vm.$store.getters.appHost + '/medias/'
      // var mediaId = 0
      // if (vm.appInfo.logoMediaId) {
      //   mediaId = vm.appInfo.logoMediaId
      // }
      // return url + mediaId
    }
  }
}
</script>

<style>
.logo-image {
  width: 32px;
  height: 32px;
}

.logo-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>
