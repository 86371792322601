<template>
  <form-card :isEdit="false" :showSubmit="false" class="mt-4">
    <template v-slot:card-header>
      <v-row no-gutters class="pa-2 label">
        {{ recordData[titleFieldInfoId] }}
      </v-row>
    </template>
    <template v-slot:card-content>
      <LoadingIcon v-if="loading"/>
      <div class="mr-3 mb-3 flex-grow-1 px-5" v-else>
        <v-row no-gutters v-for="(row, index) in layout" :key="index">
          <template v-for="fieldInfo in getFieldInfosInRow(row)">
            <input-widget
              :ref="fieldInfo.fieldId"
              :key="fieldInfo._id"
              class="py-3"
              :readonly="true"
              :recordData="recordData"
              :fieldInfo="fieldInfo"
              :relatedTableData="relatedRecords"
              :formInfo="form"
              :relatedTableInfos="relatedTableInfos"
              @onCommand="onCommandHandler"
            ></input-widget>
          </template>
        </v-row>
      </div>
    </template>
  </form-card>
</template>
<script>
import formCard from "@/components/public/formCard";
import inputWidget from "@/components/inputWidgets/InputWidget";
import { systemFieldIds } from "@/const/fieldConstants";
import LoadingIcon from "@/components/global/loadingIcon";

export default {
  components: {
    formCard,
    inputWidget,
    LoadingIcon
  },
  props: {
    publicQuery: Object,
    selectedRecord: Object,
    recordId:String,
    form: Object,
    relatedRecords: Object,
    relatedTableInfos: Object,
  },

  data() {
    return {
      localRecord: {},
      loading:false
    };
  },
  computed: {
    recordData(){
      return this.recordId ? this.localRecord:this.selectedRecord
    },
    view() {
      if (!this.form || !this.publicQuery) return null;
      else
        return this.form.views && this.form.views.find(
          (view) => view._id === this.publicQuery.viewId
        );
    },

    titleFieldInfoId() {
      if (!this.form) return "";
      else return this.form.titleFieldInfoId;
    },
    displayFieldIds() {
      if (!this.form ) return [];
      else if (!this.view) {
        return this.form.fieldInfos.concat(this.form.uiElementInfos)
          .map(info => info._id)
      }
      else {
        let result = []; //this.form.titleFieldInfoId
        switch (this.view.viewType) {
          case "table":
            {
              let displayFields = this.view.fixedFieldIds.concat(
                this.view.nonFixedFieldIds
              );

              displayFields = displayFields.filter(
                (id) => id !== this.form.titleFieldInfoId
              );
              result = result.concat(displayFields);
            }
            break;
          case "panel":
            {
              const displayFields = this.view.cardDisplayFieldIds.filter(
                (id) => id !== this.form.titleFieldInfoId
              );
              result = result.concat(displayFields);
            }
            break;
        }
        if (result.length === 0) {
          result = this.form.fieldInfos
            .filter(
              (info) =>
                !systemFieldIds.includes(info.type) &&
                info._id !== this.form.titleFieldInfoId
            )
            .map((info) => info._id);
        }
        result.unshift(this.form.titleFieldInfoId);
        return result;
      }
    },
    displayFieldInfos() {
      return this.displayFieldIds.map((fieldId) => {
        return this.form.fieldInfos.find((info) => info._id === fieldId);
      });
    },
    layout() {
      if (!this.form) return [];
      else {
        let result = [];
        this.form.layout.rows.forEach((row) => {
          let filteredRow = row.filter(
            (field) =>
              this.displayFieldIds.includes(field._id) ||
              field.controlType === "static"
          );
          if (filteredRow.length > 0) result.push(filteredRow);
        });
        return result;
      }
    },
  },
  methods: {
    onCommandHandler(payload) {
      switch(payload.command){
        case "updateFieldValue":
          this.updateFieldValue(payload)
          break;
          default:
            this.$emit("onCommand", { ...payload });
            break;
      }
    },
    updateFieldValue(payload) {
      this.$set(this.localRecord, payload.fieldName, payload.fieldValue);
    },
    getFieldInfosInRow(row) {
      const result = row.map((item) => {
        let result =
          item.controlType === "static"
            ? this.form.uiElementInfos.find((info) => info._id === item._id)
            : this.form.fieldInfos.find((info) => info._id === item._id);
        return result;
      });
      return result;
    },
  },

  mounted(){
    if (this.recordId) {
      this.loading = true
      this.$store.dispatch('FETCH_RELATION_RECORD', {
        id: this.recordId,
        formId: this.form._id
      })
      .then(res => {
        this.localRecord = res
        
      })
      .finally(()=> {
        this.loading = false
      })
    }
  }
}
</script>
