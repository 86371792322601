<template>
  <div>
    <v-toolbar outlined dense flat absolute width="100%">
      {{ $t("app.newOptions") }}
      <v-spacer />
      <v-btn color="primary" rounded @click="newOption">
        <v-icon>mdi-plus</v-icon>
        {{ $t("buttons.new") }}
      </v-btn>
    </v-toolbar>
    <v-row no-gutters class="pa-2 flex-wrap" style="margin-top:48px">
      <options-card
        class="ma-1"
        v-for="item in options"
        :key="item._id"
        :option="item"
        @edit="edit"
        @delete="onDelete"
      ></options-card>
    </v-row>
    <EditOptionsGroupDialog
      v-if="showDialog"
      v-model="showDialog"
      :selectedOption="selectedOption"
      @submit="submit"
    />
     <confirm-delete-dialog ref="ConfirmDeleteDialog" />
  </div>
</template>
<script>
import OptionsCard from "./OptionsCard";
import EditOptionsGroupDialog from "@/components/dialogs/EditOptionsGroupDialog";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";

export default {
  components: {
    OptionsCard,
    EditOptionsGroupDialog,
    ConfirmDeleteDialog
  },
  data() {
    return {
      options: [],
      showDialog: false,

      selectedId: "",
    };
  },
  computed: {
    selectedOption() {
      const result = this.options.find((item) => item._id === this.selectedId);
      return result;
    },
  },
  mounted() {
    this.getOptionGroups();
  },
  methods: {
    newOption(){
      this.selectedId = ""
      this.showDialog = true;
    },
    edit(id){
      this.selectedId = id;
      this.showDialog = true;
    },
    getOptionGroups() {
      const getParam = {
        urlCommand:
          "/optionGroup/getByAppId?appId=" + this.$store.getters.currentApp._id,
      };
      this.$store.dispatch("AUTH_GET", getParam).then((res) => {
        this.options = res;
      });
    },
    submit(data) {
      const postData = {
        urlCommand: "/optionGroup/save",
        data: {
          ...data,
          appId: this.$store.getters.currentApp._id,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        const index = this.options.findIndex(option => option._id === res._id)

        if (index > -1) {
          this.options.splice(index, 1, res);
        }
        else this.options.push(res);
      });
    },
    onDelete(id){
      this.$refs.ConfirmDeleteDialog.confirm(()=> {
      const postData = {
        urlCommand: '/optionGroup/delete',
        data: {
          appId: this.$store.getters.currentApp._id,
          id
        }
      }

      this.$store.dispatch("AUTH_POST", postData)
        .then(res => {
          if (res) {
            const index = this.options.findIndex(option=>option._id===id);
            if (index > -1) this.options.splice(index,1)
          }
        })
      })

    }
  },
};
</script>
