<template>
  <v-dialog
    v-model="showingDialog"
    scrollable
    width="640"
    max-width="90%">
    <v-card min-height="450"
>
      <dialog-header
        :label="dialogTitle"
        @close="showingDialog=false"
        color="#E0E0E0"
        toolbarClass="grey--text"/>
      <v-card-text class="text-center px-0 pt-2 team-authorization-setup-dialog-body position-relative"
              style="background-color:rgba(128,128,128,.1);"
>
        <div class="mx-auto d-flex flex-row justify-space-around">
          <v-date-picker
            class="mx-0"
            :locale="$i18n.locale"
            v-model="date"
            width="290"></v-date-picker>            
          <v-time-picker
            class="mx-0"
            format="ampm"
            :locale="$i18n.locale"
            v-model="time"
            width="290"></v-time-picker>
        </div>
      </v-card-text>
      <dialog-actions 
        :buttons="['ok', 'cancel']"
        @onCommand="onCommandHandler">
      </dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import LoadingIcon from "@/components/global/loadingIcon";

export default {
  name: 'attendanceInfoDialog',
  components: {
    dialogActions,
    dialogHeader,
    LoadingIcon,
  },
  data () {
    return {
      userInput: '',
      showingDialog: false,
      callback: null,
      date: null,
      time: null,
      masterRecordId: '',
      targetRecordId: '',
      targetFieldId: '',
      value: '' 
    }
  },
  computed: {
    dialogTitle () {
      var result = this.$t('inputPanel.attendance')
      if (this.payload && this.payload.targetRecordId) {
        result += (this.payload.targetRecordId === '' ? 
        '(' + this.$t('general.new') + ')' :
        '(' + this.$t('general.edit') + ')')
      }
      return result
    }
  },
  methods: {
    open (payload, callback) {
      this.payload = JSON.parse(JSON.stringify(payload))
      this.callback = callback
      this.showingDialog = true      

      this.masterRecordId = payload.masterRecordId
      this.targetRecordId = payload.targetRecordId
      this.targetFieldInfo = payload.targetFieldInfo

      this.parseDateTime(payload.value)
    },
    parseDateTime (value) {
      const dateTime = value.trim().split(' ')
      this.date = dateTime[0]
      this.time = dateTime[1]
      console.log('parseDateTime : dateTime: ', dateTime)
    },
    close () {
      this.showingDialog = false
      if (typeof callback === 'function') {
        callback()
      }
    },

    onCommandHandler (payload) {
      const vm = this
      if (payload.command === 'ok') {
        vm.showingDialog = false
        if (typeof vm.callback === 'function') {  
          const res = {
            masterRecordId: this.masterRecordId,
            targetRecordId: this.targetRecordId,
            targetFieldInfo: this.targetFieldInfo,
            value: this.date + ' ' + this.time
          }
          vm.callback(res)
        }
      } else {
        vm.showingDialog = false
      }
      // console.log('onCommandHandler :: payload: ', payload)
      // this.showingDialog = false
      // if (typeof vm.callback === 'function') {  
      //   vm.callback(payload)
      // }
    }
  }
}
</script>