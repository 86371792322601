const InbroundCommands = {
    walk: 'walkThrough',
    walkout: 'walkOut',
};

const OutbroundCommands = {
    joinWorkflow: 'joinOrchestration',
    leaveWorkflow: 'leaveOrchestration',
}

export {
    OutbroundCommands,
    InbroundCommands,
};
