<template>
  <input-widget-container
      :widget="widget"
      :isLayout="isLayout"
      :required="required"
      :isHidden="isHidden"
      :fieldWidth="fieldWidth"
      :noIcon="noIcon"
      :label="fieldName" class="">
    <v-text-field
      v-if="isReadOnly"
      :value="inputValue"
      disabled
      dense
      class="disabled"
    ></v-text-field>
    <v-text-field
      v-else
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      :value="value"
      dense
      :ref="fieldInfo.fieldId"
      class="workflow-customized-form-record-element"
      :rules="rules"
      :clearable="focusing"
      :hide-details="isLayout"
      single-line
      outlined
      clear-icon="mdi-close"
      @focus="focusing=true"
      @blur="focusing=false"
      :placeholder="placeholder"
      :readonly="isReadOnly"
      background-color="white"
      @input="val=>updateValue(val)"
      :error="hasError"
      :error-messages="errorMessage"
    >
    </v-text-field>
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'
export default {
  name: 'inputWidgetIdCard',
  mixins: [mixin],
  components: {
    inputWidgetContainer
  },
  props: {
    noIcon: Boolean,
  },
  data () {
  return {
      userValue: ''
    }
  },
  methods:{
    async validate(){
      if (this.isReadOnly) return true;
      if (this.$refs[this.fieldInfo.fieldId]){
      const valid = this.$refs[this.fieldInfo.fieldId].validate()
      const isDuplicated = await this.checkDuplicate()
      this.hasError = !valid || isDuplicated
      return valid&&!isDuplicated
      } else return true
    }
  }

}
</script>
