<template>
    <v-dialog
        v-model="showingBatchInsertDialog"
        width="1200"
        persistent
    >
        <v-card>
            <dialog-header
                :icon="icon"
                :label="$t('batchInsert.batchInsert')"
                @close="close"
            />
            <v-card-text style="height:550px;" class="py-2"> 
                <batch-insert-table-view
                    style="height: 100%; max-height: 100%;"
                    ref="tableView"
                    :canImport="true"
                    :canExport="true"
                    :isQuickInsertMode="true"
                    :printTemplates="[]"
                    :canCopy="true"
                    :canInlineEdit="true"
                    :canPrint="true"
                    :settings="settings"
                    @onDataLoading="onDataLoadingHandler"
                    @onDataLoaded="onDataLoadedHandler"
                    @close="close"
                    :rowCount="rowCount"
                    @batchInsert="batchInsert"
                ></batch-insert-table-view>
            </v-card-text>
            <dialog-actions
                @onCommand="onCommandHandler"
                :buttons="['close', 'save']"
                :actionDisabled="loading"
            />
            <!-- <v-btn @click="test">test</v-btn> -->
        </v-card>
    </v-dialog>
</template>

<script>
    import baseMixin from "@/mixins/baseMixin";
    import appsFormsMixin from "@/mixins/appsFormsMixin";
    import propertyHelperMixin from "@/mixins/propertyHelperMixin";
    import vxeTableHelperMixin from "@/mixins/vxeTableHelperMixin";
    import validationsMixin from "@/pages/forms/views/validationsMixin";
    import fieldHelperMixin from "@/mixins/fieldHelperMixin";
    import recordMixin from "@/mixins/recordMixin";

    import dialogHeader from '@/components/dialogs/comps/DialogHeader'
    import dialogActions from '@/components/dialogs/comps/DialogActions'

    import vxeTableCells from "@/components/vxeTableCells";
    import { newBlankRecord, getRelatedRecordFieldInfo } from "@/helpers/FormHelpers";
    import batchInsertTableView from "@/components/inputWidgets/comps/BatchInsertTableView";

    export default {
        mixins: [
            baseMixin,
            appsFormsMixin,
            propertyHelperMixin,
            vxeTableHelperMixin,
            validationsMixin,
            fieldHelperMixin,
            recordMixin
        ],
        components:{
            dialogHeader,
            dialogActions,
            ...vxeTableCells,
            batchInsertTableView
        },
        props:{
            value: Boolean,
            settings: {
                type: Object,
                default: {}
            }
        },
        computed:{
            showingBatchInsertDialog:{
                get(){
                    return this.value
                },
                set(v){
                    this.$emit('input', v)
                }
            },
            colDefs() {
                console.log("colDefs", {
                    fieldIds: this.fieldInfos.map(item=>item.fieldId),
                    fieldInfos: this.fieldInfos,
                    fixed: true
                })
                let nonFixed = []
                this.setColDefsGroup(nonFixed, this.fieldInfos, true)
                console.log("nonFixed::", nonFixed)
                nonFixed=nonFixed
                    .filter(item=>!item.fieldInfo.isSystem)
                    .map(item=>{
                        delete item.width
                        delete item.minWidth
                        delete item.pagerConfig
                        delete item.formConfig
                        return {...item}
                    })
                return nonFixed
            },
            relatedTableInfos () {
                return this.$store.getters.relatedTableInfos
            },
            form(){
                return this.relatedTableInfos[this.formId]
            },
            fieldInfos(){
                return this.form.fieldInfos
            }
        },
        data(){
            return {
                loading:false,
                data: [],
                rowCount: 10,
                formId: null,
                relationData: {
                    idDataMapping: {},
                },
                fieldId: null
            }
        },
        created(){
            delete this.gridOptions.proxyConfig
            this.isQuickInsertMode = true
            this.rowCount = this.settings.count
            this.formId = this.settings.dataSource
            this.fieldId = this.settings.fieldId
        },
        mounted(){
            console.log('gridOptions', this.gridOptions)
            // this.createRows(this.rowCount)
        },
        methods:{
            test(){
                this.createRows(5)
            },
            batchInsert(payload){
                console.log("BatchInsertDialog::updateFieldValue::payload", payload)
                this.$emit("onCommand", {
                    command: "batchInsert",
                    fieldId: this.fieldId,
                    dataSource: this.formId,
                    newSelection: payload
                })
            },
            onCommandHandler(payload){
                switch(payload.command){
                    case "save":
                        this.saveQuickInsertData()
                        break
                    case "close":
                        this.showingBatchInsertDialog = false
                        break
                }
            },
            close(){
                this.showingBatchInsertDialog = false
            },

            onDataLoadingHandler() {
                this.loading = true;
            },
            onDataLoadedHandler() {
                this.loading = false;
            },
            isHidden(info) {
                const { authorization } = info.properties;
                return Boolean(authorization && authorization.includes("hidden"));
            },
            isReadonly(info) {
                const { authorization } = info.properties;
                return Boolean(authorization && authorization.includes("readonly"));
            },
            ucFirst(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            saveQuickInsertData() {
                this.$refs.tableView.checkAndSaveQuickInsertData()
            }
        }
    }
</script>