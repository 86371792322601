<template>
    <v-dialog content-class="chartBuilderDialogContent" :scrollable="true" v-model="value" @input="event=>$emit('input', value)" persistent>
        <v-card tile class="" v-if="value" style="width:90vw">
            <v-card-title class="px-5 py-2">
                <span class="" v-if="!is_editChartName" style="font-size:16px;">{{chartName}}</span>
                <div style="width:200px;" class="" v-else>
                    <v-text-field v-model="chartName" @blur="is_editChartName=false" hide-details dense autofocus></v-text-field>
                </div>
                <v-btn
                    text
                    icon
                    dense
                    :ripple="false"
                    plain
                    @click="is_editChartName=!is_editChartName"
                >
                    <v-icon small class="ml-1" style="margin-top: 2px">mdi-pencil</v-icon>
                </v-btn>
                <v-chip label class="ml-5 primary" style="height:20px; font-size: 12px">{{$t('chart.' + tab)}}</v-chip>
                <v-chip label class="ml-2 error" style="height:20px; font-size: 12px" v-if="currentChart && currentChart.isUpdated">
                    {{$t('chart.fieldLost')}}
                </v-chip>
                <v-btn
                    text
                    icon
                    dense
                    :ripple="false"
                    plain
                    @click="$emit('input', false)"
                    class="ml-auto"
                >
                    <v-icon class="ml-1" style="margin-top: 2px">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text class="d-flex pa-0 ma-0" style="width:90vw; height:80vh;">
                <!-- <div v-if="styleSetupToggles.bl_displayTotal && selectedChart!='TABLE'" class="pt-2 pl-5 d-flex align-center" style="height: 26px; position: absolute">
                    <span style="font-size: 14px" v-if="total">{{styleSetups.total.totalTitle}}:</span>
                    <b style="font-size: 20px" class="mb-1 ml-2"> {{total}}</b>
                </div> -->
                <ChartCanvas
                    style="height:100%; width:60vw"
                    class="chart-canvas"
                    ref="chartBuilerCanvas"
                    :dataSetups="dataSetups"
                    :sourceSetups="sourceSetups"
                    :styleSetupToggles="styleSetupToggles"
                    :styleSetups="styleSetups"
                    :selectedChart="selectedChart"
                    :tab="tab"
                    :appId="appId"
                    :formId="formId"
                    :chartId="currentChart?currentChart._id:'unsaved'"
                />
                <ChartSource
                    style="width:15vw; height:100%"
                    :dataSetups="dataSetups"
                    v-if="selectedChart"
                    :views="views"
                    :selectedChart="selectedChart"
                    @onCommandHandler="onCommandHandler"
                    :sourceSetups="sourceSetups"
                />
                <ChartSetup
                    style="width:15vw; height:100%"
                    :dataSetups="dataSetups"
                    :styleSetupToggles="styleSetupToggles"
                    :styleSetups="styleSetups"
                    v-if="selectedChart"
                    :selectedChart="selectedChart"
                    :chartTypes="chartTypes"
                    @onCommandHandler="onCommandHandler"
                    :setupIsUpdated="currentChart?currentChart.setupIsUpdated:undefined"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import ChartCanvas from './panes/ChartCanvas'
    import ChartSource from './panes/ChartSource'
    import ChartSetup from './panes/ChartSetup'
    import updateMixin from './updateMixin'
    import dataConfigMixin from './dataConfigMixin'
    import styleConfigMixin from './styleConfigMixin'
    import {chartTypes} from './chartData' 

    export default {
        mixins: [updateMixin, dataConfigMixin, styleConfigMixin],
        props:{
            value: Boolean,
            views: Array,
            tab: String,
            currentChart: Object,
            formId: String,
            appId: String
        },
        components:{
            ChartSource,
            ChartSetup,
            ChartCanvas
        },
        computed:{
            chartCanvas(){
                return this.$refs.chartBuilerCanvas
            },
            fields(){
                return this.$store.getters.currentForm.fieldInfos
            },
            FieldSelection(){
                return `${this.arrX}|${this.arrY}|${this.arrG}`
            },
            type(){
                return (this.selectedChart)? this.chartTypes.find(item=>item.id==this.selectedChart).type:''
            }
        },
        watch:{
            selectedChart(newVal, oldVal){
                this.setChartType(newVal, oldVal)
            },
            dateOptVal(val){
                let key  = val.substring(0, 5)
                if(key=='NEXT_' || key=='PAST_' || key=='DATE_'){
                    let additionVal = parseInt(val.substring(5))
                    if(additionVal==0 || Number.isNaN(additionVal)) return
                }
                this.FetchData()
            },
            selectedDateField(){
                this.FetchData()
            }
        },
        data(){
            return{
                bl_fetching: false,
                is_editChartName: false,
                option: {},
                chart: null,
                chartDom: null,
                chartName: this.$t('chart.unnamedChart'),
                chartTypes: chartTypes,
                selectedChart: undefined,
                sourceSetups:{
                    selectedView: 'all',
                    dateOpt: 99,
                    dateOptVal: 'all',
                    selectedDateField: 'createdAt',
                },
                dataSetups:{
                    arrX: [],
                    arrY: [], 
                    arr2Y: [],
                    arrG: [],
                    dataX: [],
                    dataY: [],
                    data2Y: [],
                    dataG: [],
                    filters:[],
                },
                styleSetupToggles:{
                    bl_displayTotal: true,
                    bl_legend: true,
                    bl_xAxis: true,
                    bl_yAxis: true,
                    bl_label: true,
                    bl_filter: false,
                    bl_summary: false,
                },
                styleSetups:{
                    chartFigure: 'vertical',
                    total: {
                        totalRule: 'sum',
                        totalTitle: this.$t('chart.styleConfig.total'),
                        displayCol: {
                            _id:"all",
                            label: this.$t("chart.all"),
                            type:"all"
                        }
                    },
                    legend:{
                        legendPos: 'top',
                    },
                    xAxis:{
                        bl_xLabel: true,
                        bl_xRotate: false,
                        bl_xTitle: false,
                        xTitle: ''
                    },
                    yAxis:{
                        bl_displayScale: true,
                        lineStyle: 'solid',
                        bl_yTitle: false,
                        yTitle: '',
                        yMin: null,
                        yMax: null,
                    },
                    label:{
                        bl_showLabel: true,
                        bl_showData: true,
                        bl_showPercent: true,
                    },
                    filter:{
                        dataFilterStart: null,
                        dataFilterEnd: null,      
                    },
                    summary:{
                        tableFooters:[],
                        selectedHidden:[],
                    }
                }
            }
        },
        created(){
            if(this.currentChart){
                this.selectedChart = this.currentChart.selectedChart
                this.sourceSetups = this.currentChart.sourceSetups
                this.dataSetups = this.currentChart.dataSetups
                this.styleSetupToggles = this.currentChart.styleSetupToggles
                this.styleSetups = this.currentChart.styleSetups
            }else{
                this.selectedChart ='BAR'
            }
        },
        mounted(){
            if(this.currentChart){
                this.chartName = this.currentChart.chartName
            }
        },
        methods:{
            exportTable(){
                var chartId = this.currentChart?this.currentChart._id:'unsaved'
            },
            onCommandHandler(payload){
                console.log('onCommandHandler', payload)
                switch(payload.command){
                    case 'update_selectedChart':
                        this.selectedChart = payload.value
                    break
                    case 'saveChart':
                        this.saveChart()
                        return
                    break
                }
                this.chartCanvas.onCommandHandler(payload)
            },
            update_filters(val){
                this.dataSetups.filters = JSON.parse(JSON.stringify(val))
            },
            async saveChart(){
                let vm= this
                var appId = this.$store.getters.currentForm.appId;
                var formId = this.$store.getters.currentForm._id
                
            
                var chartId = (this.currentChart)?this.currentChart._id:'new'
                var dataSetups = {}
                Object.keys(this.dataSetups).forEach((key)=>{
                    if (key.includes('arr') || key == 'filters')
                        dataSetups[key] = this.dataSetups[key]
                })
                const postData = {
                    urlCommand:'/chart/save',
                    data: {
                        chartId,
                        group: vm.tab,
                        appId,
                        formId,
                        chartName: this.chartName,
                        selectedChart: this.selectedChart,
                        sourceSetups: this.sourceSetups,
                        dataSetups: dataSetups,
                        styleSetupToggles: this.styleSetupToggles,
                        styleSetups: this.styleSetups
                    }
                }
                await this.$store.dispatch('AUTH_POST', postData).then((response)=>{
                    this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                        key: "plus_sheet_mani",
                        data: {
                            module_name: this.$store.getters.formLabel,
                            func_name: this.$sensors.events.plus_sheet_mani.chart.CREATE_CHART,
                        }
                    });
                    this.$emit('input', false)
                    this.$emit('updateChart', response.chart)
                })
            },
            async FetchData(){
                this.chartCanvas.FetchData()
            },
            setDataXY(x, y, g, y2){
                let vm = this
                this.resetOption()
                this.dataSetups.dataX =  x
                this.dataSetups.dataY = y
                this.dataSetups.data2Y = y2
                this.dataSetups.dataG = g
                this.selectedHidden = []
                if(this.selectedChart == 'TABLE'){
                    this.titleTree = y
                    this.tableData = x
                    this.mergeCells = g
                    return
                }
                //set data
                if(this.selectedChart == 'BAR' || this.selectedChart=='LINE' || this.selectedChart=='COMBO'){
                    this.setOptionYAxis()
                    this.setOptionXAxis(x)
                }
                this.setOptionSeries(y, g, y2)
                if(this.selectedChart != 'RADAR')
                    this.setChartFigure(this.styleSetups.chartFigure)
                if(this.selectedChart == 'RADAR')
                    this.setOptionRadar(y)
                
                //set style
                if(this.bl_xAxis){
                    //x axis
                    this.setStyleXAxis(vm.bl_xLabel, vm.bl_xRotate, vm.bl_xTitle, vm.xTitle)
                }

                if(this.bl_yAxis)
                    this.setStyleYAxis(vm.bl_displayScale, vm.lineStyle, vm.bl_yTitle, vm.yTitle, vm.yMin, vm.yMax)
                if(this.bl_label)
                    this.setStyleTag(vm.bl_showData)

                // this.option.xAxis[0].axisLabel.overflow = "truncate"
                if(this.selectedChart=='BAR' && this.dataSetups.dataX.length > 100){
                    this.option.dataZoom = [
                        {
                            show: true,
                            start: 0,
                            end: 10
                        },
                        {
                            type: 'inside',
                            start: 0,
                            end: 10
                        },
                        {
                            show: true,
                            yAxisIndex: 0,
                            filterMode: 'empty',
                            width: 30,
                            height: '80%',
                            showDataShadow: false,
                            left: '93%'
                        }
                    ]
                }
                // setTimeout(() => {
                //     this.chart.setOption(this.option, true)
                // }, 500)
                console.log('option', this.option)
                this.chart.resize()
                this.option && this.chart.setOption(this.option, true);
            },
            resetOption(){
                console.log('this.option', this.option)
                this.option.xAxis = []
                this.option.yAxis = []
                this.option.series = []
                this.option.legend = {}
                this.option.radar = {}
                if(!(this.selectedChart == 'BAR' || this.selectedChart=='LINE' || this.selectedChart=='COMBO')){
                    delete this.option.xAxis
                    delete this.option.yAxis
                }
                this.option.tooltip = {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            },
            removeY(index){
                this.dataSetups.arrY.splice(index, 1)
                if(this.dataSetups.arrG.length) {
                    if(this.selectedChart!='TABLE')
                        this.dataSetups.arrG.splice(0,1)
                }
                this.FetchData()
            },
            removeX(index){
                this.dataSetups.arrX.splice(index, 1)
                this.FetchData()
            },
            removeG(index){
                this.dataSetups.arrG.splice(index, 1)
                this.FetchData()
            },
            remove2Y(index){
                this.dataSetups.arr2Y.splice(index, 1)
                this.FetchData()
            },
        }
    }
</script>
<style>
.ghost {
  opacity: 0.5;
  background: #c8ebfb !important;
}
.draggablee {
    margin: 5px;
    cursor: pointer;
}

.dropped {
    background-color: #FC0;
}

.chartBuilderDialogContent{
    width: 90vw !important;
}
/* .v-dialog{
    overflow: hidden !important;
} */
</style>
