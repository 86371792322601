<template>
  <v-select
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :value="value"
    outlined
    @input="onInput"
    @click:clear="onClear"
    clearable
    :menu-props="{closeOnContentClick: true, offsetY: true, contentClass: 'denseList'}"
    hide-details
    dense
  >
    <template v-slot:append-item>
      <v-divider />
      <v-list-item ripple @click="$emit('add')">
        <v-list-item-action>
          <v-icon color="primary">
            mdi-plus
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          {{$t('publicForm.addNewField')}}
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
export default {
  props: {
    items: Array,
    "item-text": {
      type: String,
      default: "label",
    },
    "item-value": {
      type: String,
      default: "_id",
    },
    value: {},
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
    },
    onClear() {
      this.$emit("clear");
    },
  },
};
</script>
