<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import {
  UPDATE_WORKFLOW,
} from '@/store/modules/workflow/action_types';
/*
* Workflow related props for the editor screen
* centralize all of workflow event
* Terry Chan
* 13/05/2021
*/
export default {
  data() {
    // console.log(this.$router);
    const { params={} } = this.$router.history.current;
    let editType = 0;
    if (params.tabIndex) {
      editType = this.matchTabIndex(params.tabIndex);
    }
    return {
      fetched: false,
      info: {
        type: 'form',
        title: this.$t('workflow.unknown'),
        description: this.$t('workflow.message.addWorkflowRemark'),
        editType: 0,
        index: editType,
      }
    };
  },
  mounted() {
    this.initialTitle = this.title;
    this.initialDescription = this.description;
  },
  computed: {
    workflow() {
      return this.$store.getters.getWorkflowInfo;
    },
    editType() {
      return this.info.editType;
    },
    title() {
      return this.info.title;
    },
    description() {
      return this.info.description;
    }
  },
  methods: {
    matchTabIndex(tab='flow') {
      const lookups = ['flow', 'spec', 'history'];
      const index = lookups.indexOf(tab);
      return index !== -1 ? index : 0;
    },
    setTitle(v) {
      this.info.title = v;
    },
    setDescription(v) {
      this.info.description = v;
    },
    setType(t) {
      this.info.type = t;
    },
    setEditType(t) {
      this.info.editType = t;
    },
    saveWorkflowInfo: debounce(function() {
      const i = this.info;
      if (this.workflow && i.title) {
        this.$store.dispatch(UPDATE_WORKFLOW, {
          info: {
            name: i.title,
            remark: i.description,
          },
          workflow: this.workflow._id,
        });
      }
    }, 1000),
  },
  provide() {
    return {
      info: this.info,
      setTitle: this.setTitle,
      setDescription: this.setDescription,
      setType: this.setType,
      setEditType: this.setEditType,
    };
  },
  watch: {
    workflow: function (w) {
      if (w && w._id) {
        this.info.title = w.name || "";
        this.info.description = w.remark || "";

        this.initialTitle = this.info.title;
        this.initialDescription = this.info.description;
        // console.log(w);
        this.info.type = w.rootNode.type;
        setTimeout(() => {
          this.fetched = true;
        }, 1000);
      }
    },
    title: function (v) {
      if (this.fetched && v && v !== this.initialTitle) {
        this.saveWorkflowInfo();
      }
    },
    description: function (v) {
      if (this.fetched && v !== this.initialDescription) {
        this.saveWorkflowInfo();
      }
    },
  }
};
</script>
