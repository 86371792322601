<template>
<div class="ml-3 quick-filter-item d-flex flex-row align-center mx-1">
  <div class="ml-1 text-no-wrap">{{ fieldName }}</div>
  <v-menu offset-y
          v-if="filterRelationOptions.length > 0"
          open-on-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          x-small
          dense
          color="primary"
          dark
          rounded
          class="ml-1"
          v-bind="attrs"
          v-on="on">
        {{ currentRelationLabel }}
      </v-btn>
    </template>
    <v-list class="menu-sm">
      <v-list-item
          small
          @click="updateFilterRelation(criteriaKey)"
          v-for="(criteriaKey, index) in filterRelationOptions"
          :key="index">
        <v-list-item-title v-if="criteriaConfigs && criteriaConfigs[criteriaKey]">
          {{ $t('filters.'+ criteriaConfigs[criteriaKey].t)}}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <template v-if="filterInfo && (filterInfo.buttons || filterRelationOptions.length > 0)">
    <div class="d-flex flex-row align-start w-100">
      <div v-if="criteriaConfig.type==='radio'" class="filter-input flex-grow-1">
        <input-radio-toggle
            v-model="userFilter.filterValue1"
            class="ml-1"
            :options="filterInfo.buttons"
            @input="fetchData" ></input-radio-toggle>
      </div>
      <div v-if="criteriaConfig.type==='text'" class="filter-input flex-grow-1">
        <v-text-field
            width="100px"
            ref="filterWidget0"
            v-model="userFilter.filterValue1"
            dense
            class="ml-1"
            :hideDetails="true"
            :rules="[rules.required]"
            required
            type="text"
            solo
            outlined
            @keypress.enter="fetchData" />
      </div>
      <div v-else-if="criteriaConfig.type==='number'" class="filter-input flex-grow-1 d-flex flex-row">
        <v-text-field
            ref="filterWidget0"
            dense
            v-model="userFilter.filterValue1"
            :rules="[rules.required]"
            required
            :hideDetails="true"
            solo
            clearable
            type="number"
            class="ml-1"
            outlined
            @keypress.enter="fetchData" />
        <v-text-field
            ref="filterWidget1"
            v-if="criteriaConfig.inputCount>1"
            dense
            v-model="userFilter.filterValue2"
            :rules="[rules.required]"
            required
            :hideDetails="true"
            solo
            type="number"
            class="ml-1"
            outlined
            @keypress.enter="fetchData" />
      </div>
      <div v-else-if="criteriaConfig.type==='date'" class="filter-input flex-grow-1 d-flex flex-row">
          <date-dialog-field
              style="width:160px;"
              class="ml-1"
              ref="filterWidget0"
              :rules="[rules.required]"
              :required="false"
              :value="userFilter.filterValue1"
              @input="val=>updateDateFilter(0,val)"
              ></date-dialog-field>

          <date-dialog-field
              style="width:160px;"
              class="ml-1"
              v-if="criteriaConfig.inputCount>1"
              ref="filterWidget1"
              :rules="[rules.required]"
              :required="false"
              :value="userFilter.filterValue2"
              @input="val=>updateDateFilter(1,val)"></date-dialog-field>

      </div>
      <div v-else-if="criteriaConfig.type==='items'" class="filter-input flex-grow-1">
        <v-select
          ref="filterWidget0"
          v-model="itemSelection"
          class="ml-1"
          dense
          :rules="[rules.required]"
          required
          item-color="primary"
          deletable-chips
          :items="selectionItems"
          chips
          :hideDetails="true"
          small-chips
          solo
          multiple
          outlined
          :menu-props="{offsetY: true, contentClass: 'denseList'}"
          @blur="fetchData"
        />
      </div>

      <div v-else-if="criteriaConfig.type==='department'" class="filter-input flex-grow-1">
        <multiple-department-selection-dialog
            ref="filterWidget0"
            :fieldInfo="fieldInfo"
            v-model="userFilter.filterValue1"
            @change="fetchData">
        </multiple-department-selection-dialog>

        <!--<v-text-field-->
            <!--ref="filterWidget0"-->
            <!--dense-->
            <!--:rules="[rules.required]"-->
            <!--required-->
            <!--hide-details="auto"-->
            <!--solo-->
            <!--outlined/>-->
      </div>

      <div 
        v-else-if="criteriaConfig.type==='member'" 
        class="filter-input flex-grow-1 ml-1"
        style="min-width:200px"
      >
        <multiple-member-selection-dialog
            ref="filterWidget0"
            :fieldInfo="fieldInfo"
            v-model="userFilter.filterValue1"
            @input="fetchData">
        </multiple-member-selection-dialog>

        <!--<v-text-field-->
        <!--ref="filterWidget0"-->
        <!--dense-->
        <!--:rules="[rules.required]"-->
        <!--required-->
        <!--hide-details="auto"-->
        <!--solo-->
        <!--outlined/>-->
      </div>
      <div 
        v-else-if="criteriaConfig.type==='region'" 
        class="filter-input flex-grow-1"
      >
        <multiple-region-selection-menu
            :fieldInfo="fieldInfo"
            v-model="userRegions" @input="fetchData"></multiple-region-selection-menu>
        <!--<v-text-field-->
        <!--ref="filterWidget0"-->
        <!--dense-->
        <!--:rules="[rules.required]"-->
        <!--required-->
        <!--hide-details="auto"-->
        <!--solo-->
        <!--outlined/>-->
      </div>
      <div v-else-if="criteriaConfig.type==='rating'" class="filter-input flex-grow-1">
        <v-text-field
            ref="filterWidget0"
            dense
            v-model="userFilter.filterValue1"
            :rules="[rules.required]"
            required
            :hideDetails="true"
            solo
            clearable
            type="number"
            class="ml-1"
            outlined
            @keypress.enter="fetchData" />
      </div>
      <!--:rules="[rules.required]"-->

      <div v-else-if="criteriaConfig.type==='none'" class="filter-input flex-grow-1">
        <v-text-field
            ref="filterWidget0"
            required
            filled
            disabled
            dense
            :hideDetails="true"
            outlined/>
      </div>

      <div v-else-if="criteriaConfig.type==='label'" class="filter-input flex-grow-1">
        <v-chip><span class="mr-2 grey--text">{{ $t('filters.'+ criteriaConfig.t) }}:</span> {{ getDateLabelByCriteria(criteriaConfig.t) }}</v-chip>
      </div>

      <div v-else-if="criteriaConfig.type==='relatedRecord'" class="filter-input flex-grow-1">
        <div style="height:30px;min-width:200px;border: 1px solid grey; cursor: pointer" @click="openRelatedDialog('relatedRecord', filter.fieldId)" class="ml-2 rounded">
            <div class="pl-1" style="padding: 0px; height:100%;">
              <v-chip small v-for="val in filter.filterValue1" :key="val" class="" style="margin:2px; max-width:120px; cursor: pointer">
                  <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{val.title}}</span>
              </v-chip>
            </div>
            <select-related-records-dialog ref="selectRelatedRecordsDialog" valType="object" @input="fetchData"></select-related-records-dialog>
        </div>
      </div>
    </div>
  </template>
  <div v-else>
    <h4>no options</h4>
  </div>
  <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
</div>
</template>

<script>
import { get } from "lodash"
  import adminFormMixin from '@/mixins/adminFormMixin'
  import filterHelperMixin from '@/mixins/filterHelperMixin'
  import propertyHelperMixin from '@/mixins/propertyHelperMixin'
  import dialogMixin from '@/mixins/dialogMixin'
  import rulesMixin from '@/mixins/rulesMixin'
  import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'

  import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
  import multipleRegionSelectionMenu from '@/components/dialogs/MultipleRegionSelectionMenu'
  import multipleMemberSelectionDialog from '@/components/dialogs/MultipleMemberSelectionDialog'
  import multipleDepartmentSelectionDialog from '@/components/dialogs/MultipleDepartmentSelectionDialog'

  import dateDialogField from '@/components/formComps/DateDialogField'
  import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
  import selectRelatedRecordsDialog from '@/components/dialogs/SelectRelatedRecordsDialog'

  export default {
    name: 'quickFilterItem',
    mixins: [
      adminFormMixin,
      filterHelperMixin,
      propertyHelperMixin,
      dialogMixin,
      rulesMixin,
      dateTimeHelperMixin
    ],
    components: {
      confirmDeleteDialog,
      multipleRegionSelectionMenu,
      multipleMemberSelectionDialog,
      multipleDepartmentSelectionDialog,
      dateDialogField,
      inputRadioToggle,
      selectRelatedRecordsDialog
    },
    data () {
      return {
      }
    },

    model: {
      prop: 'filter',
      event: 'input'
    },
    props: {
      filter: Object,
      // {
      //       fieldId: 'f00004',
      //       relation: 'isEmpty',
      //       filterValue1: '',
      //       filterValue2: ''
      // }
      fields: Array,
      filterIndex: Number,
    },
    computed: {
      relatedTableInfos(){
        return this.$store.getters.relatedTableInfos
      },
      userRegions: {
        get () {
          const vm = this
          var result = []
          if (vm.userFilter.filterValue1) {
            if (Array.isArray(vm.userFilter.filterValue1)) {
              result = vm.userFilter.filterValue1
            } else {
              if (vm.userFilter.filterValue1 !== '') {
                result = vm.userFilter.filterValue1.split('|')
              }
            }
          }
          return result
        },
        set (val) {
          const vm = this
          if (val) {
            if (Array.isArray(val)) {
              vm.userFilter.filterValue1 = val.join('|')
            } else {
              vm.userFilter.filterValue1 = val
            }
          }
        }
        // const vm = this
        // return [
        //   'hk-kowloon-000001',
        //   'hk-kowloon-000002',
        //   'hk-kowloon-000003',
        //   'hk-kowloon-000004',
        //   'hk-kowloon-000005',
        //   'hk-kowloon-000006',
        //   'hk-kowloon-000007',
        // ]
      },

      filterRelationOptions () {
        if(this.filterInfo){
          if (['createdBy','owner'].includes(this.fieldInfo.type))
            return this.filterInfo.options.filter(opt => !(['toggle','empty','notEmpty'].includes(opt)))
          else
            return this.filterInfo.options.filter(opt => opt !== 'toggle')
        }else{
          return []
        }
        // return this.filterInfo ?
        //   this.filterInfo.options.filter(opt => opt !== 'toggle') :
        //   []
      },
      itemSelection: {
        get () {
          const vm = this
          var result = null
          if (vm.userFilter.filterValue1 && vm.userFilter.filterValue1 !== '') {
            const arStr = this.userFilter.filterValue1.split('|')
            result = arStr
            // result = arStr.map(s => parseInt(s))
          }
          return result
        },
        set (val) {
          const vm = this
          var result = ''
          if (val.length > 0) {
            const arStr = val.map(val => val.toString())
            result = arStr.join('|')
          }
          this.userFilter.filterValue1 = result
        }
      },
      fieldName () {
        const vm = this
        var result = ''
        if (vm.field) {
          result = vm.field.label
        }
        return result
      },
      field () {
        const vm = this
        var result = null
        if (vm.filter) {
          result = vm.fields.find(field => field.id === vm.filter.fieldId)
        }
        return result
      },
      criteriaConfig () {
        const vm = this
        var result = {}
        if (Object.keys(vm.criteriaConfigs).indexOf(vm.userFilter.relation) >= 0) {
          result = vm.criteriaConfigs[vm.userFilter.relation]
        }
        return result
      },
      fieldInfo () {
        const vm = this
        var result = null
        if (vm.field && vm.field.fieldInfo) {
          result = vm.field.fieldInfo
        }
        return result
      },
      selectionItems () {
        const vm = this
        var result = []
        if (vm.fieldInfo) {
          result = vm.getSelectionItems(vm.fieldInfo)
        }
        return result
        //   switch (vm.field.filterType) {
        //     case 'SI':
        //     case 'MI':
        //       result.filterValue1 = vm.itemString2Array(vm.filter.filterValue1)
        //       break
        //     default:
        //       result.filterValue1 = val.filterValue1
        //       result.filterValue2 = val.filterValue2
        //   }
        // }
      },
      userFilter: {
        get () {
          const vm = this
          var result = vm.filter
          // var result = {
          //   fieldId: '',
          //   relation: '',
          //   filterValue1: '',
          //   filterValue2: ''
          // }
          // if (vm.filter && vm.field && vm.field.fieldInfo) {
          //   result.fieldId = vm.filter.fieldId
          //   result.relation = vm.filter.relation
          //
          //   switch (vm.field.filterType) {
          //     case 'SI':
          //     case 'MI':
          //       result.filterValue1 = vm.itemString2Array(vm.filter.filterValue1)
          //       break
          //     default:
          //       result.filterValue1 = vm.filter.filterValue1
          //       result.filterValue2 = vm.filter.filterValue2
          //   }
          // }
          return result
        },
        set (val) {
          this.$emit('input', val)
        }
      },
      currentRelationLabel () {
        const vm = this
        return vm.$t('filters.' + vm.criteriaConfig.t)
      },
      filterInfo () {
        const vm = this
        var result = null
        if (vm.filterInfos && vm.field) {
          result = vm.filterInfos[vm.field.filterType]
        }
        return result
      },
      filterInfos () {
        return this.$store.getters.filterInfos
      },
      criteriaConfigs () {
        return this.$store.getters.criteriaConfigs
      },
    },
    methods: {
      getDateLabelByCriteria(dateCriteriaType) {
        switch(dateCriteriaType){
          case 'today':
            return this.getToday()
          case 'currentMonth':
            return this.getMonthStartDate() + ' - ' + this.getMonthEndDate()
          case 'nextMonth':
            return this.getNextMonthStartDate() + ' - ' + this.getNextMonthEndDate()
          case 'thisWeek':
            return this.getWeekStartDate() + ' - ' + this.getWeekEndDate()
          case 'nextWeek':
            return this.getNextWeekStartDate() + ' - ' + this.getNextWeekEndDate()
          default:
            return
        }
      },
      selectRegion () {
        const vm = this
        vm.$refs.regionSelectionDialog.open((result) => {
          if (result) {
            vm.regions.push(result)
          }
        })
      },
      checkErrorCount (state) {
        const vm = this

        const filterWidgetNames = Object.keys(vm.$refs).filter(item => item.indexOf('filterWidget') === 0)
        var errorCount = 0
        for (var i = 0; i < filterWidgetNames.length; i++) {
          const loopName = filterWidgetNames[i]
          // console.log('FilterWidget :: i=' + i + ': ' + loopName)
          if (vm.$refs[loopName]) {
            // console.log('FilterWidget :: ' + loopName + ' exists.')
            // console.log('vm.$refs[' + loopName + ']: ', vm.$refs[loopName])
            if (!vm.$refs[loopName].validate(true)) {
              // console.log('FilterWidget :: ' + loopName + ' not valid.')
              errorCount++
            } else {
              // console.log('FilterWidget :: ' + loopName + ' is valid.')
            }
          }
        }
        // console.log('FilterWidget :: errorCount = ' + errorCount)
        return errorCount
      },

      checkIsValid (state) { // return true or false
      return this.checkErrorCount(state) === 0
      },
      updateFilterRelation (key) {
        const vm = this
        // console.log('updateFilterRelation :: key = ' + key)
        vm.userFilter.relation = key
        vm.fetchData()
      }
      ,

      updateDateFilter (dateIndex, val) {
        const vm = this
        // console.log('updateDateFilter: dateIndex = ' + dateIndex)
        // console.log('updateDateFilter: val = ' + val)

        if (dateIndex === 0) {
          vm.userFilter.filterValue1 = val
        } else {
          vm.userFilter.filterValue2 = val
        }
        // vm.userFilter.filterValue1 = val
        // vm.updateFilter()
        // vm.$emit('onCommand', {
        //   command: 'updateFilter'
        // })
        //
        // console.log('FilterWidget :: updateDateField :: evt; ', evt)
        this.fetchData();
      },

      deleteFilter () {
        const vm = this
        vm.$emit('onCommand', {
          command: 'deleteFilter',
          filterIndex: vm.filterIndex
        })

      }
      ,
      itemArray2String (itemArray) {
        const vm = this
        return itemArray.join('|')
      }
      ,
      itemString2Array (itemsStr) {
        const vm = this
        var result = []
        if (itemsStr !== '') {
          result = itemsStr.split('|')
        }
        return result
        // return vm.selectionItems.filter(item => itemValues.indexOf(item.value) >= 0)
      },
      getSelectionItems (fieldInfo) {
        let result = [];
        let propertyValue = get(fieldInfo, "properties.inputOptionsSingle", get(fieldInfo, "properties.inputOptionsMultiple", null));
        if (propertyValue) {
          result = get(propertyValue, "options", []).filter(option => !option.isDeleted && option.active).map(option => ({
            text: option.label,
            value: option._id
          }));
        }
        return result;
      },
      getFilterInfo (filterKey) {
        const vm = this
        var result = null
        if (vm.filterInfos && filterKey !== '') {
          result = vm.filterInfos[filterKey]
        }
        return result
      },

      getFieldName (fieldInfo) {
        const vm = this
        var result = fieldInfo.properties['fieldName']
        if (fieldInfo.properties['fieldNameTag']) {
          result = vm.$t(vm.fieldInfo.properties['fieldNameTag'])
        }
        return result
      },

      getFilterKey (fieldInfo) {
        const vm = this
        var widgetId = ''
        var result = ''
        if (fieldInfo) {
          widgetId = fieldInfo.widgetId
          if (widgetId === '') {
            result = vm.getSystemFilterWidgetKey(fieldInfo.fieldId)
          } else {
            result = vm.getFilterWidgetKey(vm.widgetMap, widgetId)
          }
        }
        return result
      },

      openRelatedDialog(fieldType, fieldId){
        let vm = this
        var formInfo = this.getRelatedTableInfo(fieldType, fieldId)
        var payload = {
            dataSource: vm.fieldInfo.properties.dataSource,
            formInfo: formInfo,
            multiple: true,
            selected: vm.filter.filterValue1,
            parentFormId: this.form._id
        }
        var onSelected = this.updateSelected
        console.log('refs: ', this.$refs)
        this.$refs.selectRelatedRecordsDialog.open(payload, onSelected)
      },

      updateSelected(payload){
        if ( Array.isArray(payload) ) {
          payload = payload.map( item => {
            if ( Array.isArray(item.title) ) {
              const firstItem = item.title.pop();
              if ( typeof firstItem === 'object' && 'employeeName' in firstItem ) {
                item.title = firstItem.employeeName
              }
            }
            return item;
          })
        }
        this.userFilter.filterValue1 = payload
      },

      getRelatedTableInfo(fieldType, fieldId){
        var field = this.getFieldById(fieldType, fieldId)
        if(field.type == 'relatedRecord'){
          return  this.relatedTableInfos[field.properties.dataSource]||{}
        }
      },

      getFieldById(fieldType, fieldId){
        if(fieldType == 'otherTableField'){
            var field = this.form.fieldInfos.find(item=>item.fieldId==fieldId)
            var relatedField = field.relatedField
            relatedField.label = field.label
            return relatedField
        }else{
          return this.form.fieldInfos.find(item=>item.fieldId == fieldId)
        }
      },
      fetchData() {
        this.$emit('onCommand', {
          command: 'refreshData'
        })
      }
    }
  }
</script>

<style>
  .quick-filter-item .v-input__control {
    min-height: auto !important;
  }
</style>