var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-table-column',_vm._b({attrs:{"class-name":"fullCell vxeCellPerson","show-overflow":false,"edit-render":{name: 'input', autoselect: true, enabled: !_vm.readonly}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row[_vm.colDef.field])?_c('simple-person-tag',{attrs:{"name":row[_vm.colDef.field].employeeName}}):(_vm.fieldInfo.type === 'createdBy')?_c('simple-person-tag',{attrs:{"name":_vm.$t('publicForm.publicForm')}}):_vm._e()]}},{key:"edit",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('div',{class:_vm.fieldInfo.type !== 'createdBy'?"edit-person-modal":"",staticStyle:{"width":"100%","height":"100%"},on:{"click":function($event){_vm.fieldInfo.type !== 'createdBy' && (!_vm.readonly && row.canEdit)? _vm.openEmployeesDialog(row):''}}},[(row[_vm.fieldId])?_c('simple-person-tag',{attrs:{"name":row[_vm.fieldId].employeeName}}):(_vm.fieldInfo.type === 'createdBy')?_c('simple-person-tag',{attrs:{"name":_vm.$t('publicForm.publicForm')}}):_vm._e()],1)]}},{key:"footer",fn:function(ref){
var column = ref.column;
return [_c('summary-cell',{attrs:{"decimals":_vm.decimals,"column":column,"labelValuePairs":_vm.getSummaryLabelValuePairs(column)},on:{"click":_vm.selectCalcMethod}})]}}])},'vxe-table-column',_vm.colDef,false))}
var staticRenderFns = []

export { render, staticRenderFns }