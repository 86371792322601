<template>
  <Consumer>
    <v-container fill-height slot-scope="{ filterInfo, setTable, setKeyword }">
      <v-row no-gutters class="wrapper" >
        <v-col
          cols="12"
          sm="6"
        >
          <SelectionBox :value="filterInfo.table" :list="list" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="search"
        >
          <SearchBox :value="filterInfo.keyword" />
        </v-col>
      </v-row>
    </v-container>
  </Consumer>
</template>

<script>
import Consumer from "./contexts/consumer";
import SelectionBox from "./../selectBox";
import SearchBox from "./../searchBox";

export default {
  inject: ['filterInfo', 'setTable', 'setKeyword'],
  props: {
    list: {
      type: Array,
      default: [],
    },
    loading: Boolean,
  },
  // data() {
  //   return {
  //     defaultOption: {
  //       value: "all",
  //       label: this.$t('workflow.options.all'),
  //     },
  //   };
  // },
  components: {
    Consumer,
    SelectionBox,
    SearchBox,
  },
  // computed: {
  //   tables() {
  //     const list = this.$store.getters.getWorkflowStatisticTable || [];
  //     return [
  //       this.defaultOption,
  //       ...list,
  //     ];
  //   },
  //   loading() {
  //     return this.$store.getters.fetchingWorkflowStatisticTable;
  //   },
  // }
};

</script>

<style scoped>
  .search {
    padding-right: 12px;
  }
</style>
