<template>
  <h3>LayoutWidgetSingleSelection</h3>
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: {
      type: String,
      default: ''
    }
  }
}
</script>
