import store from '@/store'

const FetchAxisData = async function(reqObj){
    const {
        appId, 
        formId, 
        chartId, 
        extraDateFilter, 
        chartType, 
        view, 
        dataFilter
    } = reqObj

    const postData = {
        urlCommand: '/data/' + appId + '/' + formId + '/fields',
        data: {
            x: reqObj.selected.x,
            y: reqObj.selected.y,
            g: reqObj.selected.g,
            y2: reqObj.selected.y2,
            dateFilterField: reqObj.dateFilter.field,
            dateFilterRange: reqObj.dateFilter.range,
            dataFilter,
            view,
            chartType,
            formId,
            appId,
            extraDateFilter,
            chartId
        }, 
        options:{
        }
    };
    const data = await store.dispatch("AUTH_POST", postData)
    return {x:data.x_data, y:data.y_data, g:data.g_data, y2:data.y2_data}
}

export {
    FetchAxisData
}
