<template>
  <simple-dialog ref="simpleDialog" @click:ok="confirm"
    :title="$t('widgets.properties.twoWayRelationSetup')">
    <label>{{ $t('widgets.properties.fieldName') }}</label>
    <v-text-field
        v-model="fieldName"
        solo
        outlined
        class="mb-3"
        :hide-details="true"
        dense></v-text-field>

    <label>{{ $t('widgets.properties.relationType') }}</label>
    <input-radio-toggle 
        v-model="relatedRecordQuantity"
        class="mb-3"
        :options="RELATION_TYPES"
        :dense="true"/>

    <label class="mt-3">{{ $t('widgets.properties.displayStyle')}}</label>
    <input-radio-toggle 
        class="mb-3"
        v-model="displayStyle"
        :options="DISPLAY_STYLES[relatedRecordQuantity]"
        :dense="true"/>
</simple-dialog>
</template>

<script>
import simpleDialog from "@/components/dialogs/SimpleDialog";
import inputRadioToggle from '@/components/formComps/InputRadioToggle'

export default {
  name: "twoWayRelationSetupDialog",
  components: {
    simpleDialog,
    inputRadioToggle
  },
  data() {
    return {
      fieldName: "",
      relatedRecordQuantity: 'single',
      displayStyle: 'card',
      RELATION_TYPES: [
        { labelTag: "singleRecord", value: "single" },
        { labelTag: "multipleRecords", value: "multiple" }
      ],
      DISPLAY_STYLES: {
        'multiple': [
            {labelTag: 'listing', value: 'table'},
            {labelTag: 'card', value: 'card'}
        ],
        'single': [
            {labelTag: 'card', value: 'card'},
            {labelTag: 'pulldownBox', value: 'pulldown'}
        ] 
      },
      callback: null
    };
  },
  methods: {
    open(payload) {
      const vm = this;
      vm.fieldName = payload.fieldName;
      vm.callback = payload.callback;
      vm.$refs.simpleDialog.open();
    },
    confirm() {
      const vm = this;
      if (typeof vm.callback === "function") {
        vm.callback({
          fieldName: vm.fieldName,
          relatedRecordQuantity: vm.relatedRecordQuantity,
          // relationType: vm.relationType,
          displayStyle: vm.displayStyle
        });
        vm.$refs.simpleDialog.close()
      }
    }
  }
};
</script>