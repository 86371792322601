<template>
  <div v-if="showable && fieldInfo" class="d-flex flex-row">
    <div class="d-flex" :class="isBatchInsert && fieldInfo.properties.displayFormat=='table'?'flex-column':''">
      <div v-if="isBatchInsert && fieldInfo.properties.displayFormat=='table'" style="font-size:13px">
        {{$t("batchInsert.numOfRecord")}}
      </div>
      <div class="d-flex">
        <v-text-field
          outlined
          height="25"
          class="batchInsertInput"
          v-if="isBatchInsert && fieldInfo.properties.displayFormat=='table'"
          v-model="rowNumInput"
          type="number"
          :rules="rowNumInputRules"
          ref="rowNumInput"
        >
        </v-text-field>
        <v-tooltip bottom v-if="allowCreation">
          <template v-slot:activator="{on}">
            <v-btn
              fab x-small color="primary"
              :disabled="buttonDisabled"
              @click="handleAddClick"
              class="mr-1 elevation-0"
              v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <div>{{$t("general.new")}}</div>
        </v-tooltip>
      </div>
      <div v-if="isBatchInsert && fieldInfo.properties.displayFormat=='table'" style="font-size:13px">
        {{$t("batchInsert.searchExisting")}}
      </div>
      <v-tooltip bottom v-if="allowSelection">
        <template v-slot:activator="{on}">
          <v-btn
            :disabled="buttonDisabled"
            fab x-small color="primary"
            class="elevation-0"
            @click="$emit('select')"
            v-on="on">
            <v-icon>mdi-link-variant-plus</v-icon>
          </v-btn>
        </template>
        <div>{{$t("buttons.search")}}</div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'relationButtons',
  props: {
    fieldInfo: Object,
    selection: Array,
    isReadOnly: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      rowNumInput: 10,
      rowNumInputRules: [
        (v)=> v>=1 && v<=50 || '1-50'
      ]
    }
  },
  computed: {
    isBatchInsert(){
      return (this.fieldInfo.properties.batchInsert=='true')
    },
    buttonDisabled () {
      return !this.hasDataSourceDefined || this.isReadOnly || this.disabled
    },
    hasDataSourceDefined () {
      return this.fieldInfo.properties.dataSource
    },
    showable () {
      const vm = this
      return (vm.multiple||vm.selection.length===0) &&
        (vm.allowCreation || vm.allowSelection)
    },
    multiple () {
      return this.fieldInfo.properties.relatedRecordQuantity === 'multiple'
    },
    settings () {
      const vm = this
      var result = []
      if (vm.fieldInfo.properties.settingsMultiple !== '') {
        result = vm.fieldInfo.properties.settingsMultiple.split('||')
      }
      return result
    },
    allowCreation () {
      return !this.isPublic && this.settings.includes('allowCreation')
    },
    allowSelection () {
      return this.settings.includes('allowSelection')
    },
    isPublic(){
      return this.$store.getters.isPublicRoute
    }
  },
  methods:{
    handleAddClick(){
      if(this.isBatchInsert && this.fieldInfo.properties.displayFormat=='table'){
        if(this.$refs.rowNumInput.validate())
          this.$emit('batchCreate', {
            count: this.rowNumInput,
            dataSource: this.fieldInfo.properties.dataSource,
            fieldId: this.fieldInfo.fieldId
          })
      }else
        this.$emit('create')
    }
  }
}
</script>

<style>
  .batchInsertInput .v-input__slot{
    min-height: 30px !important;
  }
  .batchInsertInput{
    margin: 2px 4px 0px 0px !important;
  }
</style>