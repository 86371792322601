<template>
    <div class="mx-5">
        <my-apps-section :title="currentFolder.label" :apps="folderApps" :preference="{ ordering: currentFolder.orderedAppIds }" :forceDisplay="true" />
        <!-- ARCHIVE BUTTON -->
        <div class="" style="position: fixed; right: 1.5rem; bottom: 1.5rem" @click="closeArchiveFolder">
            <v-btn fab color="primary">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import myAppsSection from "../myapps/mobile/comps/MyAppsSection";
    export default {
        components: { myAppsSection }, 
        data() {
            return {
                apps: []
            }
        }, 
        computed: {
            user() {
                return this.$store.getters.user;
            }, 
            folderId() {
                return this.$route.params?.id;
            }, 
            folderApps() {
                let apps = [];
                if ( this.user ) {
                    apps = this.apps.filter(app => this.currentFolder.orderedAppIds.includes(app._id) ).reduce((obj, app) => {
                        obj[app._id] = app;
                        return obj;
                    }, {})
                }
                return apps;
            },
            currentFolder() {
                let folder = null;
                if ( this.user ) {
                    folder = this.user?.preference?.appsLayout?.archives.find( folder => folder._id === this.folderId );
                }
                return folder;
            }
        },
        methods: {
            loadApps: function () {
                this.$store.dispatch('FETCH_APPS').then((response) => {
                    this.apps = response;
                })
            },
            closeArchiveFolder: function () {
                this.$router.push({ name: 'myApps' });
            }
        },
        created() {
            this.loadApps();
        }
    }
</script>