import {dataTable as types} from "../../action-types"

export default {
  [types.FETCH_DATASOURCE_INFO] ({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      console.log('FETCH_DATASOURCE_INFO :: payload: ', payload)
      const dataTableId = payload.formId

      // const dataTableId = payload.dataSource
      const getParams = {
        urlCommand: '/forms/' + dataTableId,
        options: {
          params: {
            appId: payload.appId,
            teamId: payload.teamId
          }
        }
      }
      console.log('FETCH_DATASOURCE_INFO: getParams: ', getParams)
      dispatch('AUTH_GET', getParams).then(
        response => {
          resolve(response.result)
        }
      ).catch(
        err => {
          console.log('dataTable/FETCH_DATASOURCE_INFO/AUTH_GET: err: ', err)
          reject(err)
        }
      )
    })
  },

  [types.FETCH_DATASOURCE_FIELDS] ({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const teamId = payload.dataSource.teamId
      const appId = payload.dataSource.appId
      const dataTableId = payload.dataSource

      const getParams = {
        urlCommand: '/data_tables/' + teamId + '/' + appId + '/' + dataTableId
      }
      dispatch('AUTH_GET', getParams).then(
        response => {
          console.log('dataTable/FETCH_DATASOURCE_FIELDS/AUTH_GET: response: ', response)
          resolve(response.result)
        }
      ).catch(
        err => {
          console.log('dataTable/FETCH_DATASOURCE_FIELDS/AUTH_GET: err: ', err)
          reject(err)
        }
      )
    })
  }
}
