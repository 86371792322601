<template>
  <div>
    <FormElement
      :label="label || $t('workflow.modules.operation.label.method')"
      name='sourceType'
    >
      <ComboBox
        :value="value || null"
        :options="list"
        :placeholder="$t('workflow.modules.operation.placeholder.selectType')"
        valueFormat="id"
        @changed="changed"
      />
    </FormElement>
  </div>
</template>

<script>
import ComboBox from './../../../form/comboBox/';
import FormElement from './../shared/formElement';
import Mixin from './../mixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';

export default {
  mixins: [Mixin],
  data() {
    return {
      list: [
        {
          id: 'math',
          label: this.$t('workflow.modules.operation.label.type.math'),
        },
        {
          id: 'dateCalculate',
          label: this.$t('workflow.modules.operation.label.type.dateCalculate'),
        },
        {
          id: 'dateDiff',
          label: this.$t('workflow.modules.operation.label.type.dateDiff'),
        }
      ],
    };
  },
  props: {
    value: String,
  },
  methods: {
    changed(v) {
      this.$store.dispatch(SET_SETTINGS, {
        sourceType: v,
      });
    }
  },
  components: {
    ComboBox,
    FormElement,
  },
};

</script>

<style scoped>

</style>
