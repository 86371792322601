<template>
  <v-select v-bind="$attrs" v-model="inputValue" class="employee-form-select">
    <template v-slot:append-outer>
      <v-btn
        v-if="hasAdd"
        fab
        x-small
        depressed
        color="primary"
        class="ma-1"
        @click="$emit('new')"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </template>
  </v-select>
</template>
<script>
export default {
  props: {
    hasAdd: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss">
.employee-form-select .v-input__append-outer {
  margin: 0px !important;
}
</style>
