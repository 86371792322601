import { data as types } from "../../action-types";
import { data as mutations } from "../../mutation-types";
export default {


  [types.SAVE_DATA_BATCH]({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/data/batch',
        data: payload
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.SAVE_DATA]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      // Changed: 2021-07-01
      // new or updated record will have the record id
      // distinguished by "isNew" (which is based on record id segment in url)
      const postData = {
        urlCommand: "/data",
        data: payload
      }
      dispatch("AUTH_POST", postData).then(
        (response) => {
          dispatch("APPEND_SELECTION_OPTIONS", {oldData:payload.data, newData:response})
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );

    });
  },


  [types.GENERATE_TEMP_RECORD_ID] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/data_ids/' + payload.appId + '/' + payload.formId ,
        data: {
          count: payload.count     
        }
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          console.log('GENERATE_TEMP_RECORD_ID :: response: ', response)
          resolve(response)
        },
        error => {
          reject(error);
        }
      )
    })
  },

  [types.FETCH_RECORD]({ commit, dispatch }, {appId, formId, viewId, recordId, showTitle=false, skipRelatedTableInfos=false}) {
    if(typeof recordId==='object'){
      recordId = recordId._id
    }
    return new Promise((resolve, reject) => {
      let urlCommand = `/data/${appId}/${formId}/${recordId}`
      if (viewId) urlCommand+=`?viewId=${viewId}&showTitle=${showTitle}&skipRelatedTableInfos=${skipRelatedTableInfos}`

      const getData = {
        urlCommand
      };

      dispatch("AUTH_GET", getData).then(
        (response) => {
          commit(mutations.SET_RECORD_DATA, response.result);
          commit(mutations.SET_RELATED_TABLE_DATA, response.relatedTableData);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.DUPLICATE_RELATED_RECORD] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/data/' + payload.id + '/' + payload.fieldId + '/duplicateRelatedRecords',
        data: {
          relatedFormId: payload.relatedFormId,
          relatedRecordIds: payload.relatedRecordIds
        }
      }
      dispatch('AUTH_POST', postData).then( 
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.DELETE_RELATED_RECORDS] ({commit, dispatch}, payload) {
    if(payload.relatedRecordIds&&Array.isArray(payload.relatedRecordIds)){
      payload.relatedRecordIds = payload.relatedRecordIds.map(item=>{
        return typeof item === 'object'? item._id:item
      })
    }
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/data/' + payload.id + '/' + payload.fieldId + '/deleteRelatedRecords',
        data: {
          formId: payload.formId,
          relatedFormId: payload.relatedFormId,
          relatedRecordIds: payload.relatedRecordIds
        }
      }
      console.log('DELETE_RELATED_RECORDS: postData: ', postData)

      dispatch('AUTH_POST', postData).then( 
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.UPDATE_CASCADE_SUMMARY] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/data/' + payload.recordId + '/updateCascadeSummary',
        data: {
          formId: payload.formId
        }
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response.status)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.UPDATE_SUMMARY_FIELDS] ({commit, dispatch}, payload) {
    // notes:
    //
    // payload: {
    //    id: // record id
    //    summaryFormId:
    //    summaryRecordIds:
    //    summaryConfigs: [
    //        {
    //            fieldId: {field to set}
    //            summaryFieldId: {fieldId of summary data}
    //            summaryFunction: 'sum'
    //        },
    //        {
    //            fieldId: {field to set}
    //            summaryFieldId: {fieldId of summary data}
    //            summaryFunction: 'sum'
    //        }
    //    ]
    // }
    if(payload.summaryRecordIds && Array.isArray(payload.summaryRecordIds)){
      payload.summaryRecordIds = payload.summaryRecordIds.map(item=>{
        return typeof item === 'object'? item._id:item
      })
    }
    return new Promise((resolve, reject) => {
      const putData = {
        urlCommand: '/data/' + payload.id + '/updateSummaryFields',
        data: {
          formId: payload.formId,
          fieldIds:payload.fieldIds,
          summaryRecordIds: payload.summaryRecordIds,
          updateDb: payload.updateDb,
        }
      }

      console.log('UPDATE_SUMMARY_FIELDS > AUTH_PUT: putData: ', putData)
      dispatch('AUTH_PUT', putData).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.CHECK_TEMP_ID] ({commit, dispatch}, payload) {
    console.log('CHECK_TEMP_ID')
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/data_ids/' + payload.id + '/check',
        data: {
          formId: payload.formId
        }
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.ATTACH_RELATED_RECORDS] ({commit, dispatch}, payload) {
    if(payload.relatedRecordIds&&Array.isArray(payload.relatedRecordIds)){
      payload.relatedRecordIds = payload.relatedRecordIds.map(item=>{
        return typeof item === 'object'? item._id:item
      })
    }
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/related_records/attach',
        data: payload
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.UPDATE_RELATED_RECORDS] ({commit, dispatch}, payload) {
    // notes: 
    //
    // router.post('/:id/:fieldId/updateRelatedRecords', DataController.updateRelatedRows)
    // body: {relatedFormId: 'xxxx', recordIds: [], linked: Boolean, }
    //
    // linked=true => attach
    // linked=false => detach
    //
    if(payload.relatedRecordIds&&Array.isArray(payload.relatedRecordIds)){
      payload.relatedRecordIds = payload.relatedRecordIds.map(item=>{
        return typeof item === 'object'? item._id:item
      })
    }
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/data/' + payload.id + '/' + payload.fieldId + '/updateRelatedRecords',
        data: {
          formId: payload.formId,
          relatedFormId: payload.relatedFormId,
          relatedRecordIds: payload.relatedRecordIds,
          linked: payload.linked
        }
      }
      dispatch('AUTH_POST', postData).then( 
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  [types.FETCH_FORMS_RELATED_RECORDS]({ rootGetters, commit, dispatch }, payload) {    
    return new Promise((resolve, reject) => {
      var urlCommand = '/related_records'
      if (payload.isPublic){
        // const getParams = {
        //   url: rootGetters.appHost + '/public' + urlCommand,
        //   options: {
        //     params: params
        //   },
        // };

        // dispatch('COMMON_GET', getParams).then(
        //   (response) => {
        //     commit(mutations.UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID, {
        //         fieldId: payload.fieldId,
        //         data: response.result
        //     })
        //     resolve(response.result);
        //   },
        //   (error) => {
        //     reject(error);
        //   }
        // );
      }else {
        const postData = {
          urlCommand,
          data: payload
        }
        dispatch("AUTH_POST", postData).then(
          (response) => {
            let relatedTableData = {}
            response.forEach(field=>{
              relatedTableData[field.fieldId] = field.result
              commit(mutations.UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID, {
                fieldId: field.fieldId,
                data: field.result
              })
            })
            resolve(relatedTableData);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  },
  [types.FETCH_RELATED_RECORDS]({ rootGetters, commit, dispatch }, payload) {
    console.log('FETCH_RELATED_RECORDS: payload: ', payload)
    if(payload.recordIds&&Array.isArray(payload.recordIds)){
      payload.recordIds = payload.recordIds.map(item=>{
        return typeof item === 'object'? item._id:item
      })
    }
    return new Promise((resolve, reject) => {

      var urlCommand = '/related_records'
      var params = {
        dataSource: payload.dataSource
      }

      // for single record
      // if (payload.recordId) {
      //   urlCommand += '/' + payload.recordId
      // }
      // for multiple records
      if (payload.recordIds) {
        params['recordIds'] = JSON.stringify(payload.recordIds)
      }

      if (payload.isPublic){
        const getParams = {
          url: rootGetters.appHost + '/public' + urlCommand,
          options: {
            params: params
          },
        };

        dispatch('COMMON_GET', getParams).then(
          (response) => {
            commit(mutations.UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID, {
                fieldId: payload.fieldId,
                data: response.result
            })
            resolve(response.result);
          },
          (error) => {
            reject(error);
          }
        );
      }else {
        const getParams = {
          urlCommand: urlCommand,
          options: {
            params: params
          },
        };
  
        dispatch("AUTH_GET", getParams).then(
          (response) => {
            commit(mutations.UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID, {
                fieldId: payload.fieldId,
                data: response.result
            })
            resolve(response.result);
          },
          (error) => {
            reject(error);
          }
        );
      }

    });
  },

  [types.FETCH_SELECTION_DATA]({rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let params = {
        ...payload
      };
      // pagination
      // if ( payload.page && payload.size) {
      //   // console.log("FETCH_DATA with pagination");
      //   params["page"] = payload.page;
      //   params["size"] = payload.size;
      // }
      // // } else if (payload.count) {
      // //   params["pagination"] = {
      // //     pageSize: payload.count,
      // //     currentPage: 1,
      // //   };
      // // }

      // // console.log('FETCH_DATA :: params: ', params)
      // if (payload.filterConfigs) {
      //   params["filterConfigs"] = payload.filterConfigs;
      // }
      // if (payload.sortingConfigs) {
      //   params["sortingConfigs"] = payload.sortingConfigs;
      // }
      // if (payload.displayFieldIds) {
      //   params["displayFieldIds"] = payload.displayFieldIds;
      // }
      // if (payload.keyword) {
      //   params['keyword'] = payload.keyword
      // }
      // if (payload.exclusion) {
      //   params['exclusion'] = payload.exclusion
      // }


      // if (payload.recordId) {
      //   params['recordId'] = payload.recordId
      // }

      // console.log('FETCH_SELECTION_DATA: params: ', params)
      // console.log("before getData");
      const urlCommand = "/selection"
      if(params.exclusion && Array.isArray(params.exclusion)){
        params.exclusion = params.exclusion.map(item=>{
          return typeof item === 'object'? item._id:item
        })
      }
      if (payload.isPublic){
        const getData = {
          url: rootGetters.appHost + "/public" + urlCommand,
          options: {
            params: params,
          }
        }

        dispatch('COMMON_GET', getData).then(
          (response) => {
             console.log("FETCH_DATA :: response: ", response);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        const getData = {
          urlCommand,
          options: {
            params: params,
          }
        };
  
        // console.log('before AUTH_GET: getData: ', JSON.stringify(getData))
        dispatch("AUTH_GET", getData).then(
          (response) => {
            // console.log("FETCH_DATA :: response: ", response);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }

    });
  },

  [types.FETCH_DATA_GROUPING]({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand:
        '/data/' + payload.appId +
        '/' + payload.formId +
        '/' + payload.viewId +
        '/grouping',
        options: {
          params: {
            groupingFieldId: payload.groupingFieldId
          }
        }
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          if (response.result) {
            resolve(response.result)
          } else {
            resolve([])
          }
        }
      )
    })
  },

  [types.FETCH_DATA_FIELDS]({rootGetters, dispatch}, payload) {
    return new Promise((resolve, reject) => {      
      const reqParams = JSON.stringify(payload.reqParams)
      // console.log('FETCH_DATA_FIELDS :: reqParams: ', reqParams)
      
      if (payload.isPublic){
        const postData = {
          url: rootGetters.appHost + '/public/data/get_fields',
          data: {
            reqParams: reqParams
          }          
        }
        dispatch('COMMON_POST', postData).then(
          response => {
            resolve(response.result)
          }
        )
      }else {
        const postData = {
          urlCommand: '/data/get_fields',
          data: {
            reqParams: reqParams
          }
        }
  
        // console.log('FETCH_DATA_FIELDS: postData: ', postData)
        dispatch('AUTH_POST', postData).then(
          response => {
            // console.log('FETCH_DATA_FIELDS : AUTH_GET :: response: ', response)
            resolve(response.result)
          }
        )
      }

    })
  },
  
  [types.FETCH_RELATED_RECORDS_BY_IDS]({commit, dispatch}, payload) {
    // payload = {
    //  appId,
    //  teamId,
    //  formId,
    //  recordIds
    // }
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: '/related_records',
        options: {
          params: payload
        }
      }
      dispatch('AUTH_GET', getData).then(
        resolve(response)
      ),
      error => {
        reject(error)
      }
    })
  },

  [types.FETCH_RELATED_DATA_LIST]({ rootGetters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('FETCH_DATA :: payload: ', payload)
      var params = {...payload};
      // if (payload.pagination) {
      //   params["pagination"] = payload.pagination;
      // } else if (payload.count) {
      //   params["pagination"] = {
      //     pageSize: payload.count,
      //     currentPage: payload.currentPage ? payload.currentPage : 1,
      //   };
      // }
      // params = {...params, ...payload}

      if (payload.isPublic){
        const getData = {
          url: rootGetters.appHost + "/public/related_data_list",
          options: {
            params: params,
          }
        }
        dispatch('COMMON_GET', getData).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );        
      }else {
        const getData = {
          urlCommand: "/related_data_list",
          options: {
            params: params,
          },
        };
        // console.log('FETCH_DATA :: getData: ', getData)
  
        dispatch("AUTH_GET", getData).then(
          (response) => {
            // console.log('FETCH_DATA_LIST :: response: ', response)
            // commit(mutations.SET_RECORD_DATA, response.result)
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }

    });
  },
  [types.GET_GANTT_RECORD]({ commit, dispatch }, payload) {
    console.log('GET_GANTT_RECORD::payload', payload)
    // appId: record.appId,
    // formId: record.formId,
    // recordId: record._id, 
    // viewId: vm.view._id
    return new Promise((resolve, reject)=>{
      const getData = {
        urlCommand: "/data/gantt/record/" + payload.formId + '/' + payload.viewId + '/' + payload.recordId+'?searchText='+payload.searchText
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  [types.GET_DATA_FIELD]({ commit, dispatch }, payload) {
    return new Promise((resolve, reject)=>{
      var urlCommand =  "/data/" + payload.appId +
                        "/" + payload.formId +
                        "/" + payload.viewId + "/get_data_by_fields"
      var data = {
        ids: payload.ids,
        fieldIds: payload.fieldIds
      }

      const postData = {
        urlCommand,
        data
      };
      dispatch('AUTH_POST', postData).then(
        (response)=>{
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  },
  [types.FETCH_PROJECT_DATA]({ commit, dispatch }, payload) {
    console.log('payload---', payload)
    return new Promise((resolve, reject)=>{
      var urlCommand =  "/data/tree/" + payload.appId +
                        "/" + payload.formId +
                        "/" + payload.viewId
      var data = {
        // col_project: payload.col_project,
        // col_start: payload.col_start,
        // col_end: payload.col_end,
        // col_milestone: payload.col_milestone,
        // col_progress: payload.col_progress,
        projectCascade: payload.projectCascade,
        // col_color: payload.col_color,
        groupBy: payload.groupBy || '',
        labelBy: payload.labelBy || '',
        hoverInfos: payload.hoverInfos,
        projectRelation: payload.projectRelation,
        searchText: payload.searchText,
        recordId: payload.recordId||null
      }

      const postData = {
        urlCommand,
        data
      };

      dispatch('AUTH_POST', postData).then(
        (response)=>{
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  },

  [types.FETCH_DATA_SUMMARY] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand:
          "/data/" +
          payload.appId +
          "/" +
          payload.formId +
          "/" +
          payload.viewId +
          "/summary"
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },


  [types.FETCH_DATA_LIST]({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('FETCH_DATA_LIST :: payload: ', payload)
      var params = {};
      if (payload.pagination) {
        // console.log('pagination exists')
        params["page"] = payload.pagination.currentPage;
        params["size"] = payload.pagination.pageSize;
      } else if (payload.count) {
        // console.log('count = ' + payload.count)
        params["page"] = payload.currentPage ? payload.currentPage : 1;
        params["size"] = payload.count;

      }
      // console.log('FETCH_DATA :: params: ', params)
      if (payload.filter) {
        params["filter"] = payload.filter;
      }
      if (payload.keyword) {
        params["keyword"] = payload.keyword;
      }
      if (payload.sortingFieldId) {
        params["sortingFieldId"] = payload.sortingFieldId;
      }
      if (payload.sortingOrder) {
        params["sortingOrder"] = payload.sortingOrder;
      }
      if (payload.inclusion) {
        params['inclusion'] = payload.inclusion;
      }
      if (payload.masterFormId) {
        params['masterFormId'] = payload.masterFormId
      }
      if ( payload.skipDependantFields ) {
        params['skipDependantFields'] = payload.skipDependantFields
      }
      
      const getData = {
        urlCommand:
          "/data/" +
          payload.appId +
          "/" +
          payload.formId +
          "/" +
          payload.viewId +
          "/list",
        options: {
          params: params,
        },
      };

      dispatch("AUTH_GET", getData).then(
        (response) => {
          // console.log('FETCH_DATA_LIST :: response: ', response)
          // commit(mutations.SET_RECORD_DATA, response.result)
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [types.RESET_DATA]({commit}){
    commit(mutations.RESET_DATA);
  },
  [types.UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID] ({commit}, payload) {
    commit(mutations.UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID, payload)
  },
  [types.COPY_ATTACHMENTS] ({ commit, dispatch }, payload ) {
    const { appId, ids, teamId, employeeId } = payload;
    console.log( `FEATURE #991 :: store.data :: copy attachments :: INIT`, payload );
    const response = new Promise(( resolve, reject ) => {
      const postData = {
        urlCommand: `/data/${appId}/duplicateAttachments`,
        data: {
          ids: ids,
          teamId: teamId,
          employeeId: employeeId
        }
      };

      try {
        dispatch('AUTH_POST', postData).then(( res ) => {
          resolve( res );
        }); 
      } catch (err) {
        reject( err );
      }

    } );
    console.log( `FEATURE #991 :: store.data :: copy attachments :: COMPLETED`, response );
    return response;
  }
  // ,
  // [types.SET_DATA_FIELD_VALUE] ({commit}, payload) {
  //   commit(mutations.SET_DATA_FIELD_VALUE, payload)
  // }
};
