<template>
  <div>
    <v-layout alignItems="center" justifyContent="center" class="workflow-global-notification-bell">
      <v-btn depressed @click.stop="clean" class="workflow-global-notification-bell-btn">
        <div v-if="!!counter" class="workflow-global-notification-bell-icon">
          <Lottie
            :options="defaultOptions"
            :height="30"
            :width="30"
          />
        </div>
        <div v-else>
          <v-icon :color="light ? 'white' : null" size="23">mdi-bell</v-icon>
        </div>
        <div v-if="!!counter" class="workflow-global-notification-bell-counter">{{counter}}</div>
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import {
  HIDE_BELL,
  TOGGLE_PANEL,
} from '@/store/modules/notification/action_types';
import {
  CLEAR_PROFILE_COUNTER,
} from '@/store/modules/system/action_types';
import Lottie from 'vue-lottie';
import NotificationAnimation from '@/pages/admin/workflow/assets/notification.json';
// import NotificationPanel from './panel/';
  export default {
    components: {
      Lottie,
      // NotificationPanel,
    },
    props: {
      light: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        defaultOptions: {
          animationData: NotificationAnimation,
        },
        animationSpeed: 0.1,
      };
    },
    methods: {
      showPopup() {
        const vm = this;
        setTimeout(() => {
          vm.$store.dispatch(HIDE_BELL, false);
        }, 7000);
      },
      clean() {
        this.$store.dispatch(TOGGLE_PANEL, true);
        this.$store.dispatch(CLEAR_PROFILE_COUNTER);
      },
    },
    computed: {
      show() {
        return this.$store.getters.shouldBellShow;
      },
      counter() {
        return this.$store.getters.getNotificationCounter;
      },
    },
    watch: {
      show: function(toShow) {
        if (toShow) {
          this.showPopup();
        }
      }
    }
  }
</script>

