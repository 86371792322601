<template>
 <v-dialog
        v-model="showingDialog"
        v-if="showingDialog"
        width="600">
      <v-card>
        <!-- <v-card-title class="headline grey lighten-2">
          {{ $t('general.colorSelection')}}
        </v-card-title> -->
        <dialog-header
          :label="$t('general.colorSelection')"
          @close="showingDialog = false"
          color="#E0E0E0"
          toolbarClass="grey--text"
        />
        <v-card-text class="pa-5">
          <!--<div class="mb-2 d-flex flex-row">-->
          <!--<div class="text-h6 d-inline mr-2">Text</div>-->
          <!--<v-btn-toggle v-model="textThemeNo" mandatory>-->
          <!--<v-btn small class="black min-width-50" style="border:1px solid dark-grey;">-->
          <!--<v-icon v-if="textThemeNo===0" class="grey&#45;&#45;text">mdi-check</v-icon>-->
          <!--</v-btn>-->
          <!--<v-btn small class="white min-width-50" style="border:1px solid dark-grey;">-->
          <!--<v-icon v-if="textThemeNo===1" class="grey&#45;&#45;text">mdi-check</v-icon>-->
          <!--</v-btn>-->
          <!--</v-btn-toggle>-->
          <!--</div>-->
          <!--<div class="text-h6">Background</div>-->
          <div class="d-flex flex-row align-start"
               :class="'theme--'+getTextTheme(color)">
            <!--<sketch-picker :value="inputColor" @input="color=>updateColor(color)" />-->
            <swatches-picker :value="inputColor"
                             @input="color=>updateColor(color)"
                             class="ml-3 color-picker"
                             style="height:316px;width:100%;"/>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions><v-spacer></v-spacer>
          <v-btn class="min-width-80" color="muted" @click="showingDialog = false">
            {{ $t('buttons.cancel') }}</v-btn>
          <v-btn class="min-width-80" color="primary" @click="confirm">
            {{ $t('buttons.ok') }}</v-btn>
        </v-card-actions> -->
        <dialog-actions 
          :buttons="['cancel', 'ok']"
          @onCommand="onCommandHandler($event)">
        </dialog-actions>
      </v-card>
    </v-dialog>  

</template>

<script>
import appColorMixin from '@/mixins/appColorMixin'
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import helpers from '@/helpers'
import vuetifyColors from 'vuetify/lib/util/colors'
import {Swatches} from 'vue-color'

export default {
  name: 'colorDialog',
  mixins: [appColorMixin],
  components: {
    dialogActions,
    dialogHeader,
    'swatchesPicker': Swatches
  },
  data () {
    return {
      showingDialog: false,
      color: '',
      inputTextTheme: 'dark',
      callback: null
    }
  },
  watch: {
    color: function (newVal) {
      const vm = this
      if (newVal) {
        vm.assignInputColor(newVal.color)
      }
    }
  },
  methods: {
    assignInputColor (color) {
      const vm = this
      if (color && color !== '') {
        if (color.indexOf(' ') >= 0) {
          const colorSegs = color.split(' ')
          const seg0 = colorSegs[0]
          const seg1 = colorSegs[1].replace('-', '')
          vm.inputColor = vuetifyColors[seg0][seg1.replace('-','')]+'FF'
        } else {
          if (color.indexOf('#') === 0) {
            vm.inputColor = color.length <= 7 ? color + 'FF' : color
          } else {
            const colorCode = helpers.colourNameToHex(color) + 'FF'
            vm.inputColor = colorCode
          }
        }
        vm.inputColor = vm.inputColor.toUpperCase()
        vm.color = color
      }
    },
    updateColor (color) {
      console.log('updateColor: color: ', color)
      this.color = color.hex8
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'ok':
          vm.confirm()
          break
        case 'cancel':
          vm.close()
          break
      }
    },
    open (payload, callback) {
      const vm = this
      vm.color = payload.color
      vm.assignInputColor(vm.color)
      vm.callback = callback
      vm.showingDialog = true
    },
    confirm() {
      const vm = this
      if (typeof vm.callback === 'function') {
        vm.callback(vm.color)
      }
      vm.close()
    },
    close() {
      this.showingDialog = false
    }
  }
}
</script>