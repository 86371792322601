 <template>
    <codemirror
        v-model="code"
        :options="cmOptions" 
        ref="cmEditor"
        style="width:100%;height:100%; border: 1px solid grey"
        @ready="onCmReady"
    />
    <!-- @ready="onCmReady"
    @input="onCmCodeChange"
    @focus="onCmFocus" -->
 </template>

 <script>
    import 'codemirror/mode/javascript/javascript.js'
    import 'codemirror/theme/base16-dark.css'
    import 'codemirror/addon/search/search.js'
    import 'codemirror/addon/search/searchcursor.js'
    
    export default {
        props:{
            value: Object
        },
        data(){
            return{
                cmOptions: {
                    tabSize: 4,
                    mode: 'text/javascript',
                    // theme: 'base16-dark',
                    lineNumbers: false,
                    line: true,
                    lineWrapping: true,
                    readOnly: ''
                },
            }
        },
        computed:{
            codemirror() {
                return this.$refs.cmEditor.codemirror
            },
            code:{
                get(){
                    return this.value.code
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            formFieldInfos(){
                return this.$store.getters.formFieldInfos
            }
        },
        created(){
            console.log('this.value', this.value)
        },
        methods:{
            insertField(field){
                var cm = this.$refs.cmEditor.codemirror
                var cursor = cm.getCursor();

                var lineNumber = cursor.line;
                var charNumber = cursor.ch;
                    
                var snippet = "${" + field.fieldId + "}"
                cm.replaceRange(snippet, {line:lineNumber, ch: charNumber},{line:lineNumber, ch: charNumber})
                const div = this.getReplacement(field)
                cm.markText(
                    {line: lineNumber,ch: charNumber}, 
                    {line: lineNumber,ch: charNumber + snippet.length}, 
                    {replacedWith: div}
                )
            },
            onCmReady(){
                if(!this.code) return
                var cm = this.$refs.cmEditor.codemirror
                console.log('this.code', this.code)
                cm.setValue(this.code)
                var result = this.code
                const regex = /\$\{\w+\}/;
                while (regex.test(result)) {
                    let tag = result.match(regex)[0];
                    let key = tag.match(/\w+/)[0];
                    let snippet = "${" + key + "}"
                    var cursor = cm.getSearchCursor(snippet);
                    var first, from, to;
                    var field = this.formFieldInfos.find(item=>item.fieldId == key)
                    var mappedVal = field? field.label:''
                    while (cursor.findNext()) {
                        const div = this.getReplacement(field)
                        from = cursor.from();
                        to = cursor.to();
                        cm.markText(from, to, {
                            replacedWith: div,
                        });
                        if (first === undefined) {
                            first = from;
                        }
                    }
                    result = result.replace(snippet, '');
                }
            },
            getReplacement(field){
                let vm = this
                const div = document.createElement("div");
                div.className = "rounded-pill info white--text pa-2 py-0 d-inline-block";
                var numberTypes = ['number', 'amount', 'summary', 'rating']
                if(!numberTypes.includes(this.fieldType)){
                    div.innerHTML = field.label
                }else{
                    vm.cmOptions.readOnly = 'nocursor'
                    const flexWrapper = document.createElement('div')
                    flexWrapper.className = "d-flex align-center"
                    const fieldLabel = document.createElement('span')
                    fieldLabel.innerHTML = field.label
                    const closeBtn =  document.createElement('i')
                    closeBtn.innerHTML = "close"
                    closeBtn.className = "ml-1 material-icons"
                    closeBtn.style = "font-size:15px"
                    flexWrapper.append(fieldLabel)
                    flexWrapper.append(closeBtn)
                    div.append(flexWrapper)
                    
                    var fieldId = field._id
                    closeBtn.addEventListener('click', function(event) {
                        var replaceStr = '${' + fieldId + '}'
                        vm.code = vm.code.replace(replaceStr, '')
                        if(vm.code==''){
                            vm.cmOptions.readOnly = ''
                        }
                    })
                }
                return div
            }
            
        }
    }
 </script>