const mixin = {
  data() {
    return {
      askRemove: false,
    };
  },
  computed: {
    appId() {
      return this.$route.params.id;
    },
  },
  methods: {
    beforeRemove() {
      this.askRemove = true;
    },
    confirmRemove() {
      this.cancelRemove();
      this.$emit('remove');
    },
    cancelRemove() {
      this.askRemove = false;
    }
  },
}

export default mixin
