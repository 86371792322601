<template>
  <div>
    <label>{{ label }}</label>
    <!-- persistent -->
      <v-dialog
        content-class="richTextDialog"
        width="60%"
        v-model="dialog"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            :ripple="false"
            plain
            class="ml-10"
          >
            <v-icon size="19">
              mdi-pen
            </v-icon>
          </v-btn>
        </template>
        <!-- <template v-slot:default="dialog"> -->
          <v-card class="">
            <dialog-header @close="close" :closable="true" :label="$t('widgets.properties.' + propertyConfig.labelTag)"/>

            <v-card-text id="editor" class="px-0 py-0">
                <general-quill
                  className="witRichTextEditor"
                  ref="witQuill"
                  :appId="appId"
                  :formId="formId"
                  :fieldId="fieldInfoId"
                  v-model="propertyValue"
                  @input="(val) => propertyValue = val.html"
                />
            </v-card-text>
          </v-card>
        <!-- </template> -->
    </v-dialog>
  </div>
</template>

<script>
import  { get } from "lodash";
import mixin from './mixin'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import GeneralQuill from '@/components/public/GeneralQuill'

export default {
  mixins: [mixin],
  components:{
    dialogHeader,
    GeneralQuill
  },
  data(){
    return{
      dialog: false
    }
  },
  methods:{
    close(){
      this.dialog = false
    }
  },
  computed:{
    appId(){
      return get(this.$store.getters.currentApp, "_id")
    }
  }
}
</script>

<style>
/* .ql-container.ql-snow {
  height: auto;
} */
/* .witRichTextEditor{
  overflow-y: hidden;
} */
.witRichTextEditor .ql-editor{
  overflow-y: auto;
  height: 40vh;
}
.witRichTextEditor .ql-tooltip{
  box-shadow: 0px !important;
}
.richTextDialog{
  overflow: unset;
}
</style>