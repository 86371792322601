<template>
  <v-toolbar-title
    class="pl-1 d-flex flex-row align-center"
  >
    <v-btn @click.stop="onBack" class="w-header-back-btn">
      <v-icon medium :color="!dark ? 'primary' : 'white'">mdi-chevron-left</v-icon>
    </v-btn>
    <v-layout column align-content-center justify-start>
      <div v-if="!editable">
        <h1 class="w-header-title">{{ title }}</h1>
      </div>
      <input
        v-else
        v-model="info.title"
        @change="onEdit"
        @blur="onTitleBlur"
        class="w-real-edit header"
        :disabled="blockAction"
        :placeholder="$t('workflow.unknown')"
      />
      <v-tooltip
        bottom
        v-if="editable"
        transition="slide-y-reverse-transition"
        content-class="w-arrow-control-tool"
      >
        <template v-slot:activator="{ on, attrs }">
          <input 
            v-bind="attrs"
            v-on="on"
            v-model="info.description"
            @change="onRemark"
            :disabled="blockAction"
            class="w-real-edit desc"
            :placeholder=" $t('workflow.message.addWorkflowRemark')"
          />
        </template>
        <span>{{ $t('workflow.message.addWorkflowRemark') }}</span>
      </v-tooltip>
    </v-layout>
    <!-- <v-layout align-center v-else class="w-header-editable"> -->
    
    <!-- </v-layout> -->
  </v-toolbar-title>
</template>

<script>
import {
  OFF_PREVIEW_ORCHESTRATION,
} from '@/store/modules/workflow/action_types';
// import { debounce } from 'lodash';
export default {
  props: {
    onClick: Function,
    onEdit: Function,
    onRemark: Function,
    onTitleBlur: Function,
    info: Object,
    dark: Boolean,
    // description: String,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // TODO: mixin handling
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
    isUIBlocked() {
      return this.$store.getters.isUIBlocked;
    },
    blockRelease() {
      return this.$store.getters.isBlockRelease;
    },
    blockAction() {
      return this.blockRelease || this.isPreviewMode || this.isUIBlocked;
    },
  },
  methods: {
    onBack() {
      this.onClick();
      if (this.isPreviewMode) {
        this.$store.dispatch(OFF_PREVIEW_ORCHESTRATION);
      }
    }
  },
  // watch: {
  //   info: debounce(function(info) {
  //     this.saveWorkflowInfo(info);
  //   }, 800),
  // }
};

</script>

<style scoped lang="scss">
  .w-header-back-btn {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .w-header-title {
    font-weight: 700;
    font-size: 17px;
    color: white;
  }
  .w-header-desc {
    font-size: 12px;
    color: #e2e2e2;
  }
  .w-real-edit,
  .w-real-edit:hover,
  .w-real-edit:active,
  .w-real-edit:focus,
  .w-real-edit:visited {
    outline: none;
    color: white;
    border: 0;
    padding: 0 10px;
  }
  .w-real-edit:hover,
  .w-real-edit:active,
  .w-real-edit:focus,
  .w-real-edit:visited {
    border: 1px solid #ccc;
  }
  .w-real-edit.header:hover,
  .w-real-edit.header:active,
  .w-real-edit.header:focus,
  .w-real-edit.header:visited {
    max-width: 250px;
  }
  .w-real-edit.desc {
    font-size: 13px;
    color: #e2e2e2;
  }

  .w-real-edit.desc::placeholder {
       color: #e2e2e2;
  }
  .w-real-edit.header {
    font-size: 18px;
    
  }
  /* .w-header-input:hover {
    border: 1px solid #1e88e5;
  } */
</style>
