<template>
  <div>
    <label>{{ label }}</label>
    <v-select
        :items="items"
        dense
        outlined
        solo
        :menu-props="{offsetY: true, contentClass: 'denseList'}"
        :placeholder="placeholder"
        :hide-details="true"
        v-model="propertyValue"></v-select>
    <!--widgetType: {{ widgetType }}-->
    <!--&lt;!&ndash;targetFieldInfo.label: {{ targetFieldInfo ? targetFieldInfo.label : 'not selected' }}&ndash;&gt;-->
    <!--summaryFieldId = {{ summaryFieldId }}-->
    <!--_ids: {{ targetTableFieldInfos.map(info => info.fieldId) }}-->


    <!--targetFieldInfo._id = {{ targetFieldInfo ? targetFieldInfo._id : 'not selected' }}-->
    <!--targetFieldInfo.fieldId = {{ targetFieldInfo ? targetFieldInfo.fieldId : 'not selected' }}-->
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'witSummaryFunctionSelection',
  mixins: [mixin],
  data () {
    return {
      otherTableFieldInfoArray: []
    }
  },
  computed: {
    placeholder () {
      const vm = this
      var result = vm.$t('messages.pleaseSelectRelatedTableFirst')
      if (vm.tableField) {
        result = vm.$t('messages.pleaseSelectSummaryType')
      }
      return result
    },

    // field info id of relatedMultipleRecords or childTable
    tableFieldInfoId () {
      return this.getPropertyValue('tableField', '')
    },

    // fieldInfo of relatedMultipleRecords or childTable
    tableFieldInfo () {
      const vm = this
      return vm.formFieldInfos.find(info => info._id === vm.tableFieldInfoId)
    },
    ds () {
      return this.tableFieldInfo.properties.dataSource
    },
    relatedTableInfos () {      
      return this.$store.getters.relatedTableInfos
    },
    targetTableInfo () {
      const vm = this
      var result = null
      switch (vm.tableFieldInfo.type) {
        case 'childTable':
          const dataTableId = vm.tableFieldInfo.properties.fieldsEditor.formId

          break
        case 'relatedRecord':
          const ds = vm.tableFieldInfo.properties.dataSource
          result = vm.relatedTableInfos[ds.dataTableId]
          break
      }
      return result
    },
    //**************************************
    // function applied for related records
    //**************************************
    targetTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.tableFieldInfo) {
        const tableFieldType = vm.tableFieldInfo.type
        switch (tableFieldType) {
          case 'childTable':
            result = vm.tableFieldInfo.properties.fieldsEditor.fieldInfos
            break
          case 'relatedRecord':
            const relatedFormId = vm.tableFieldInfo.properties.dataSource
            if (relatedFormId) {
              const relatedFormInfo = vm.dataSources.find(ds => ds._id === relatedFormId)
              if (relatedFormInfo) {
                result = relatedFormInfo.fieldInfos
              }
              

            }
            break
        }
      }
      return result
    },

    summaryFieldId () {
      return this.getPropertyValue('summaryField', '#')
    },

    targetFieldRelatedField () {
      const vm = this
      var result = null
      if (vm.targetFieldInfo && vm.targetFieldInfo.type === 'otherTableField' &&
        vm.targetFieldInfo.properties.fieldForRelatedTable) {

        result = vm.targetTableFieldInfos.find(
          info => info._id === vm.targetFieldInfo.properties.fieldForRelatedTable
        )
      }
      return result
    },

    targetFieldInfo () {
      const vm = this
      var result = null
      if (vm.targetTableFieldInfos.length > 0) {
        const fieldInfo = vm.targetTableFieldInfos.find(info => info.fieldId === vm.summaryFieldId)
        if (fieldInfo) {
          result = fieldInfo
        }
      }
      return JSON.parse(JSON.stringify(result))
    },

    // for current selected target field for summary
    allWidgets () {
      return this.$store.getters.allWidgets
    },
    widgetIdSummaryTypeMapping () {
      const vm = this
      var result = {}
      for (let i = 0; i < vm.allWidgets.length; i++) {
        const loopWidget = vm.allWidgets[i]
        // for otherTableField
        // summaryType should be that of the summary type of the target field
        if (loopWidget.key === 'otherTableField') {
          console.log('widgetIdSummaryTypeMapping : loopWidget: ', loopWidget)
          console.log('widgetIdSummaryTypeMapping : vm.fieldInfoId = ' + vm.fieldInfoId)
          const targetFieldInfo = vm.targetFieldInfo
          console.log('targetFieldInfo: ', targetFieldInfo)          

          // fieldInfo = {
          // ...
          //    fieldId: 61401f4b348c8124ba7f4116,
          //    summaryField: 6142f199f5e6923a471d542e
          //    tableField: 60716f8c7bdbf84aef259d14
          // ...
          // }
          const targetWidget = vm.$store.getters.allWidgets.find(widget => widget._id === targetFieldInfo.widgetId)
          console.log('targetWidget: ', targetWidget)
          result[loopWidget._id] = targetWidget ? targetWidget.summaryType : null
        } else {
          result[loopWidget._id] = loopWidget.summaryType
        }
      }
      return result
    },
    targetWidget () {
      return this.$store.getters.allWidgets.find(widget => widget._id === this.targetWidgetId)
    },
    targetWidgetId () {
      const vm = this
      var result = null
      if (vm.targetFieldInfo) {
        result = vm.targetFieldInfo.widgetId
      }
      return result
      // return formInfo ? formInfo.widgetId : ''
    },

    effectiveTargetWidget () {
      const vm = this
      var result = null
      if (vm.targetWidget) {
        switch (vm.targetWidget.key) {
          case 'otherTableField':
            result = vm.cascadeTargetWidget
            // result = vm.targetFieldFromOtherTableField
            break
          default:
            result = vm.targetWidget
        }
      }
      return result
    },    
    cascadeTargetWidget () {
      const vm = this
      var result = null
      if (vm.otherTableFieldInfoArray.length > 0) {
        const lastFieldInfo = vm.otherTableFieldInfoArray[vm.otherTableFieldInfoArray.length -1]
        if (lastFieldInfo) {
          result = vm.allWidgets.find(widget => widget._id === lastFieldInfo.widgetId)
        }
      }
      return result
    },
    summaryValueType () {
      return this.effectiveTargetWidget ?
        this.effectiveTargetWidget.summaryType : 
        (this.cascadeTargetWidget ? this.cascadeTargetWidget.summaryType : null)
      // const vm = this
      // var result = ''
      // if (vm.targetWidgetId && vm.targetWidgetId !== '') {
      //   result = this.widgetIdSummaryTypeMapping[vm.targetWidgetId]
      // }
      // return result
    },
    items () {
      const vm = this
      var result = []
      if (vm.tableFieldInfoId) {
        switch (vm.summaryValueType) {
          case 'fillOnly':
            result = [
              {text: vm.$t('widgets.properties.filledCount'), value: 'filledCount'},
              {text: vm.$t('widgets.properties.nonFilledCount'), value: 'nonFilledCount'}
            ]
            break
          case 'number':
            result = [
              {text: vm.$t('widgets.properties.sum'), value: 'sum'},
              {text: vm.$t('widgets.properties.average'), value: 'average'},
              {text: vm.$t('widgets.properties.max'), value: 'max'},
              {text: vm.$t('widgets.properties.min'), value: 'min'}
            ]
            if(this.targetWidget.key!="expression"){
              result = [
                ...result,
                {text: vm.$t('widgets.properties.filledCount'), value: 'filledCount'},
                {text: vm.$t('widgets.properties.nonFilledCount'), value: 'nonFilledCount'}
              ]
            }
            break
          case 'date':
            result = [
              {text: vm.$t('widgets.properties.filledCount'), value: 'filledCount'},
              {text: vm.$t('widgets.properties.nonFilledCount'), value: 'nonFilledCount'},
              {text: vm.$t('widgets.properties.earliest'), value: 'earliest'},
              {text: vm.$t('widgets.properties.latest'), value: 'latest'}
            ]
            break
        }
      }
      return result
    },

      
    targetWidgetFromOtherTableField () {
      const vm = this
      const fieldInfo = vm.targetFieldInfo  
      const summaryFieldId = ''
      var result = null
      const fieldInfoForRelatedTable = vm.targetTableFieldInfos.find(
        info => info.fieldId === fieldInfo.fieldForRelatedTable
      )

      // the field where the data to fetch
      const relatedFieldId = fieldInfo.relatedTableField

      const ds = fieldInfoForRelatedTable.properties.dataSource
      switch (vm.tableFieldInfo.type) {
        case 'childTable':
          break
        case 'relatedRecord':
          result = getWidgetFromRelatedFieldId(relatedFieldId)
          // get widget of the field ()
          break
      }
      return result
    },
  },
  methods: {
    loadOtherTableFields () {
      const vm = this

      if (vm.targetFieldRelatedField) {
        const ds = vm.targetFieldRelatedField.properties.dataSource

        try {
       
          vm.$store.dispatch('FETCH_CASCADE_OTHER_TABLE_FIELDS', 
          {
            dataSource: ds,
            fieldId: vm.targetFieldInfo.properties.relatedTableField
          }).then(
            response => {
              vm.otherTableFieldInfoArray = response.result
             
            }
          )
        } catch(err) {
          console.log('err: ', err)
        }
      }
    }
  },
  mounted () {
    const vm = this

    if (vm.targetFieldInfo && vm.targetFieldInfo.type === 'otherTableField') {
      vm.loadOtherTableFields()
    }
  },
  watch: {
    items: {
      deep: true,
      handler(newVal) {
        const result = newVal.find(
          (option) => option.value === this.propertyValue
        );

        if (!result) {
          this.propertyValue = newVal && newVal.length>0 ? newVal[0].value : null;
        }
      },
    },
    'targetFieldInfo.type': function(newVal) {
      const vm = this
      if (newVal === 'otherTableField') {
        vm.loadOtherTableFields()
        // vm.$store.dispatch('FETCH_')
        // alert('targetFieldInfo.type === otherTableField')
      }
    }
  }
}
</script>

