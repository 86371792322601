var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-0 d-flex pl-2",attrs:{"hide-actions":""}},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t('chart.styleConfig.yAxis'))+" ")])]),_c('v-switch',{staticStyle:{"position":"absolute","right":"0px","top":"-7px"},attrs:{"dense":"","inset":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
            command: 'update_bl_yAxis',
            value: $event
        })}},model:{value:(_vm.bl_yAxis),callback:function ($$v) {_vm.bl_yAxis=$$v},expression:"bl_yAxis"}}),_c('v-expansion-panel-content',[_c('v-checkbox',{staticClass:"ma-0",attrs:{"hide-details":"","dense":"","ripple":false,"label":_vm.$t('chart.showScaleLabel')},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_bl_displayScale',
                value: $event
            })}},model:{value:(_vm.setup.bl_displayScale),callback:function ($$v) {_vm.$set(_vm.setup, "bl_displayScale", $$v)},expression:"setup.bl_displayScale"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.setup.bl_displayScale),expression:"setup.bl_displayScale"}],staticClass:"pt-2"},[_vm._v(_vm._s(_vm.$t('chart.lineStyle')))]),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.setup.bl_displayScale),expression:"setup.bl_displayScale"}],attrs:{"item-value":"label","items":_vm.lineStyles,"outlined":"","height":"30","hide-details":"","menu-props":{offsetY: true, contentClass: 'denseList'},"item-text":function (item){ return _vm.$t('chart.styleConfig.'+item.label); }},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_lineStyle',
                value: $event
            })}},model:{value:(_vm.setup.lineStyle),callback:function ($$v) {_vm.$set(_vm.setup, "lineStyle", $$v)},expression:"setup.lineStyle"}}),_c('v-checkbox',{staticClass:"pb-1",attrs:{"hide-details":"","dense":"","ripple":false,"label":_vm.$t('chart.showTitle')},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_bl_yTitle',
                value: $event
            })}},model:{value:(_vm.setup.bl_yTitle),callback:function ($$v) {_vm.$set(_vm.setup, "bl_yTitle", $$v)},expression:"setup.bl_yTitle"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.setup.bl_yTitle),expression:"setup.bl_yTitle"}],staticClass:"pb-3",attrs:{"hide-details":"","placeholder":"","outlined":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_yTitle',
                value: $event
            })}},model:{value:(_vm.setup.yTitle),callback:function ($$v) {_vm.$set(_vm.setup, "yTitle", $$v)},expression:"setup.yTitle"}}),_c('div',[_vm._v(_vm._s(_vm.$t('chart.styleConfig.max')))]),_c('v-text-field',{attrs:{"hide-details":"","placeholder":_vm.$t('chart.auto'),"outlined":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_yMax',
                value: $event
            })}},model:{value:(_vm.setup.yMax),callback:function ($$v) {_vm.$set(_vm.setup, "yMax", $$v)},expression:"setup.yMax"}}),_c('div',{staticClass:"pt-2"},[_vm._v(_vm._s(_vm.$t('chart.styleConfig.min')))]),_c('v-text-field',{attrs:{"hide-details":"","placeholder":_vm.$t('chart.auto'),"outlined":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_yMin',
                value: $event
            })}},model:{value:(_vm.setup.yMin),callback:function ($$v) {_vm.$set(_vm.setup, "yMin", $$v)},expression:"setup.yMin"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }