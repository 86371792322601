<template>
  <div class="">
    <v-overlay :absolute="false" :value="showingDialog" :id="attach">
    </v-overlay>
    <v-dialog
      v-model="showingDialog"
      persistent
      hide-overlay
      scrollable
      :attach="cssSelect?cssSelect:attach?`#${attach}`:undefined"
      width="540"
    >
      <v-card>
        <DialogHeader
          @close="showingDialog = false"
          :label="$t('widgets.richText')"
        />
        <v-card-text class="px-0 py-0">
          <general-quill className="witRichTextEditor" ref="quillDialog" v-model="editorContent" @input="val=>editorContent=val.html"></general-quill>
        </v-card-text>
        <DialogActions @onCommand="onCommand" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";
import GeneralQuill from "@/components/public/GeneralQuill";
// import { quillEditor } from "vue-quill-editor";

export default {
  props: {
    value: Boolean,
    attach: {
      type: String,
      default: undefined,
    },
    content: String,
    cssSelect:{
      type: String,
      default: undefined
    }
  },
  components: {
    DialogHeader,
    DialogActions,
    // quillEditor,
    GeneralQuill
  },
  data() {
    return {
      editorContent: "",
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onCommand(payload) {
      switch (payload.command) {
        case "cancel":
          this.showingDialog = false
          break;
        case "ok":
          this.showingDialog = false
          this.$emit("confirm", this.editorContent);
          break;
      }
    },
  },
  mounted() {
    this.editorContent = this.content;
  },
};
</script>
