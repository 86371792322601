<template>
  <div class="common-table-view d-flex flex-column mt-1 height-auto">
    <div style="width:0;min-width:100%;" class="d-flex flex-column flex-grow-1">
      <vxe-toolbar
        size="small"
        v-if="hasControl"
        :style="{ 'margin-top': stretch === 'true' ? '-32px' : '-40px' }"
      >
        <template v-slot:tools>
          <slot name="tools">
            <v-btn
              v-if="selectedRecords.length > 0"
              small
              color="error"
              @click="onDeleteSelected"
              class="mr-1"
            >
              <v-icon left>mdi-delete</v-icon>{{ $t("buttons.delete") }}</v-btn
            >
          </slot>
        </template></vxe-toolbar
      >
      <vxe-grid
        border
        size="small"
        row-id="_id"
        @edit-closed="onEditClosed"
        show-header-overflow
        resizable
        auto-resize
        :resizable-config="{ minWidth: '50px' }"
        max-height="500px"
        ref="xTable"
        keep-source
        cell-class-name="vxe-table-row-xs"
        :loading="loading"
        :data="tableData"
        :edit-rules="validRules"
        :edit-config="canInlineEdit ? { trigger: 'click', mode: 'row' } : null"
      >
        <vxe-table-column
          v-if="hasControl"
          type="checkbox"
          width="50"
          fixed="left"
          class-name="text-center"
          header-class-name="text-center"
        ></vxe-table-column>
        <vxe-table-column
          type="seq"
          width="60"
          fixed="left"
          class-name="text-center"
          header-class-name="text-center"
        ></vxe-table-column>
        <template v-for="(colDef, index) in colDefs">
          <component
            v-if="colDef.componentName !== ''"
            :is="'vxeCell' + colDef.componentName"
            :colDef="colDef"
            :relationData="relationData"
            :key="index"
            @onCommand="onCommandHandler"
          >
          </component>
          <vxe-table-column
            v-else
            v-bind="colDef"
            :key="index"
          ></vxe-table-column>
        </template>
        <vxe-table-column
          v-if="hasControl"
          :title="$t('general.operation')"
          :width="100"
          fixed="right"
          :show-overflow="false"
          class-name="common-table-controls"
        >
          <template v-slot="{ row, rowIndex }">
            <slot name="control" :row="row" :rowIndex="rowIndex">
              <v-btn
                v-if="!isReadOnly"
                class="mx-1"
                dark
                :elevation="false"
                x-small
                fab
                color="primary"
                @click.native="popupEdit(row, rowIndex)"
              >
                <v-icon>mdi-file-document-edit</v-icon>
              </v-btn>
              <v-btn
                class="mx-1"
                dark
                :elevation="false"
                x-small
                fab
                color="error"
                @click.native="deleteRow(row, rowIndex)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </slot>
          </template>
        </vxe-table-column>
      </vxe-grid>
    </div>

    <select-related-records-dialog
      ref="selectRelatedRecordsDialog"
    ></select-related-records-dialog>

    <!-- Dialog: Card Selection -->
    <select-related-cards-dialog
      ref="selectRelatedCardsDialog"
    ></select-related-cards-dialog>
    <dialog-button-select-employees
      class="pa-0"
      :addButton="false"
      ref="tableEesDialog"
      v-model="showEmployeesDialog"
    ></dialog-button-select-employees>
  </div>
</template>
<script>
import vxeTableCells from "@/components/vxeTableCells";
import dialogButtonSelectEmployees from "@/components/dialogButtons/DialogButtonSelectEmployees";
import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
import selectRelatedCardsDialog from "@/components/dialogs/SelectRelatedCardsDialog";

import validationsMixin from "@/pages/forms/views/validationsMixin";

import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";
function TableConfig(fixedFieldIds, nonFixedFieldIds) {
  this.fixedFieldIds = fixedFieldIds;
  this.nonFixedFieldIds = nonFixedFieldIds;
}

export default {
  name: "commonTableView",
  mixins: [validationsMixin],
  props: {
    hasControl: Boolean,
    tableConfig: {
      type: TableConfig,
      default: () => ({
        fixedFieldIds: [],
        nonFixedFieldIds: [],
      }),
    },
    fieldInfos: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    canInlineEdit: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    parentFieldInfo: Object,
    tableFormInfo: Object,
    relatedTableInfos: Object,
    relationData: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ...vxeTableCells,
    dialogButtonSelectEmployees,
    selectRelatedCardsDialog,
    selectRelatedRecordsDialog,
  },
  data() {
    return {
      showEmployeesDialog: false,
      uniqueValid: ({ cellValue, column, row }) => {
        const fieldId = column["property"];
        const exist = this.value.find(
          (record) =>
            row._id !== record._id &&
            typeof record[fieldId] === "string" &&
            record[fieldId].trim() === cellValue
        );
        if (exist) {
          return new Error(this.$t("messages.hasDuplicate"));
        }
      },
    };
  },
  computed: {
    colDefs() {
      let [fixed, nonFixed] = [
        this.getColDefsGroup(
          this.tableConfig.fixedFieldIds,
          this.fieldInfos,
          true
        ),
        this.getColDefsGroup(
          this.tableConfig.nonFixedFieldIds,
          this.fieldInfos,
          false
        ),
      ];
      return fixed.concat(nonFixed);
    },
    tableData: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    effectiveFieldIds() {
      const { fixedFieldIds = [], nonFixedFieldIds = [] } = this.tableConfig;
      return [...fixedFieldIds, ...nonFixedFieldIds];
    },
    selectedRecords() {
      return (
        (this.$refs.xTable && this.$refs.xTable.getCheckboxRecords()) || []
      );
    },
    form() {
      return this.tableFormInfo;
    },
  },
  methods: {
    getColDefsGroup(fieldIds, fieldInfos, isFixed) {
      let result = [];
      fieldIds.forEach((fieldId) => {
        const info = fieldInfos.find(
          (fieldInfo) => fieldInfo.fieldId === fieldId
        );
        if (info && !this.isHidden(info)) {
          let componentName = this.ucFirst(info.type);
          let align = "left";
          let width = 200;
          let editRender = "";
          switch (info.type) {
            case "summaryField":
            case "amount":
              align = "right";
              break;
            case "number":
              align = info.decimals === "" ? "center" : "right";
              break;
            case "date":
            case "yesNo":
              align = "center";
              break;
            case "attachments":
            case "singleSelection":
            case "multipleSelection":
              break;
            case "expression":
              if (info.properties.expressionType === "number") {
                align = "right";
              }
              break;
            case "owner":
            case "createdBy":
              align = "center";
              componentName = "CellPerson";
              break;
            case "createdAt":
            case "updatedAt":
              align = "center";
              componentName = "CellDateTime";
              break;
          }
          const colDef = {
            title: this.getFieldTitle(info),
            width: width,
            minWidth: 100,
            align: align,
            fixed: isFixed ? "left" : null,
            componentName: componentName,
            editRender: editRender,
            field: info.fieldId,
            showOverflow: "ellipsis",
            tableType: "child",
            fieldInfo: {
              ...info,
              canEdit: !this.readonly && !this.isReadonly(info),
            },
            titleHelp: info.properties.description
              ? { message: info.properties.description }
              : null,
            fieldInfos: fieldInfos,
          };
          result.push(colDef);
        }
      });
      return result;
    },
    isHidden(info) {
      const { authorization } = info.properties;
      return Boolean(authorization && authorization.includes("hidden"));
    },
    isReadonly(info) {
      const { authorization } = info.properties;
      return Boolean(authorization && authorization.includes("readonly"));
    },
    ucFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getFieldTitle(fieldInfo) {
      const vm = this;
      var result = "(" + vm.$t("general.unspecified") + ")";
      if (fieldInfo.properties) {
        if (fieldInfo.properties.fieldNameTag) {
          result = vm.$t(fieldInfo.properties.fieldNameTag);
        } else if (fieldInfo.properties.fieldName) {
          result = fieldInfo.properties.fieldName;
        }
      }
      return result;
    },
    insertEvent(newRecord, isNew = true) {
      if (isNew) {
        this.tableData.push(newRecord);
      } else {
        const recordId = newRecord._id;
        const index = this.tableData.findIndex((row) => row._id === recordId);
        if (index > -1) {
          Object.assign(this.tableData[index], newRecord);
        }
      }
      fetchRelatedDataFieldsInRows(
        this,
        this.tableData,
        null,
        this.$route.name === "PublicQuery" || this.$route.name === "PublicForm",
        this.fieldInfos,
        this.effectiveFieldIds,
      ).then((response) => {
          this.relationData.idDataMapping = response;
        })
    },
    onEditClosed({ row, column }) {
      this.$refs.xTable.validate(row, async (errMap) => {
        if (errMap) await this.$refs.xTable.revertData(row, column.property);
        else await this.$refs.xTable.reloadData(this.tableData);
      });
    },
    async onDeleteSelected() {
      const records = this.$refs.xTable.getCheckboxRecords(); //getCheckboxRecords().map(record=>record._id)
      for (let i = 0; i < records.length; i++) {
        const index = this.tableData.findIndex(
          (row) => row._id === records[i]._id
        );
        if (index > -1) this.tableData.splice(index, 1);
      }
    },
    insertRow({ recordData, isNew }) {
      this.insertEvent(recordData, isNew);
    },
    deleteRow(row, rowIndex) {
      this.tableData.splice(rowIndex, 1);
    },
    popupEdit(row, rowIndex) {
      this.popupNew({
        record: row,
        isNew: false,
        fieldInfos: this.fieldInfos.map((info) => ({
          ...info,
          canEdit: true,
          canCreate: true,
        })),
        relatedTableInfos: this.relatedTableInfos,
        fieldInfo: this.parentFieldInfo,
        formInfo: this.tableFormInfo,
        onSubmitted: this.insertRow,
      });
    },
    popupNew(payload) {
      this.$emit("openChildRecordDialog", payload);
    },
    selectRelatedCards(payload) {
      const vm = this;
      vm.showOverlay = true;
      vm.attach = payload.attach;
      vm.$refs.selectRelatedCardsDialog.open(
        payload,
        vm.onRelatedRecordSelected,
        vm.onRelatedRecordCancelled
      );
    },

    selectRelatedRecords(payload) {
      const vm = this;
      vm.showOverlay = true;
      vm.attach = payload.attach;

      vm.$refs.selectRelatedRecordsDialog.open(
        payload,
        vm.onRelatedRecordSelected,
        vm.onRelatedRecordCancelled
      );
    },
    onRelatedRecordSelected(payload, callbackBundle, callback) {
      if (typeof callbackBundle.onSelected === "function") {
        callbackBundle.onSelected(payload);

        fetchRelatedDataFieldsInRows(
          this,
          this.tableData,
          null,
          this.$route.name === "PublicQuery" ||
            this.$route.name === "PublicForm" ||
            this.$route.name === "PublicEdit",
          this.fieldInfos,
          this.effectiveFieldIds,
        ).then((response) => {
          this.relationData.idDataMapping = response;
          if (typeof callback === "function") callback();
        })
      }
    },

    onRelatedRecordCancelled(callbackBundle) {
      if (typeof callbackBundle.onCancelled === "function") {
        callbackBundle.onCancelled();
      }
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "showEmployeesDialog":
          this.$refs.tableEesDialog.multiple = payload.multiple;
          this.$refs.tableEesDialog.selectedValue = payload.selectedValue;
          this.$refs.tableEesDialog.callback = payload.onSubmit;
          this.$refs.tableEesDialog.row = payload.row;
          this.showEmployeesDialog = true;
          break;
        case "selectRelatedCards":
          this.selectRelatedCards(payload);
          break;
        case "selectRelatedRecords":
          this.selectRelatedRecords(payload);
          break;
        case "refreshRelationData":
          {
            var ids = payload.addition.relatedRecordIds.map((item) => {
              return typeof item === "object" ? item._id : item;
            });
            const reqParams = [
              {
                dataSource: payload.dataSource,
                ids: ids,
                fieldIds: this.effectiveFieldIds,
              },
            ];
            this.$store
              .dispatch("FETCH_DATA_FIELDS", {
                reqParams: reqParams,
                isPublic: this.$store.getters.isPublicRoute,
              })
              .then((response) => {
                if (this.relationData.idDataMapping) {
                  this.relationData.idDataMapping = {
                    ...this.relationData.idDataMapping,
                    ...response,
                  };
                } else {
                  this.$set(this.relationData, "idDataMapping", response);
                }
              });
          }
          break;
        default:
          this.$emit("onCommand", payload);
          break;
      }
    },
  },
};
</script>

<style>
.common-table-controls .vxe-cell {
  min-height: 40px !important;
}
</style>
