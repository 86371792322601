<template>
  <component
    :is="target"
    :node="node"
    :tree="tree"
    :offset="offset"
  />
</template>

<script>
// import { get } from 'lodash';
import DependencyView from './dependency';
import DefaultView from './default';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  data() {
    return {
      lookup: {
        dependency: 'DependencyView',
        empty: 'DefaultView',
      },
    }
  },
  computed: {
    target() {
      const { node } = this;
      return node.parent && node.dependsNode ? this.lookup.dependency : this.lookup.empty;
    }
  },
  components: {
    DependencyView,
    DefaultView,
  },
};

</script>

<style scoped>
  
</style>
