<template>
  <div>
    <v-radio-group 
      hide-details
      dense
      :column="false"
      v-model="noWorkflow"
      @change="onChanged"
    >
      <v-radio 
        dense 
        v-for="option in options"
        :key="option.id" 
        :label="$t(option.label)"
        :value="option.value"
        class="w-v-display-option"
      />
    </v-radio-group>
    <div class="w-customized-button-workflow">
      <SubWorkflowSelect
        v-if="!noWorkflow"
        subProcessType="actionProcess"
        :appId="currentApp._id"
        :value="workflow"
        @changed="onChangeWorkflow"
        :placeholder="$t('workflow.modules.subWorkflow.label.selectSub')"
        :hints="$t('workflow.modules.actionProcess.remark.selectSub')"
      />
    </div>
  </div>
</template>

<script>
import {
  UPDATE_ACTION_BUTTON_INFO,
} from '@/store/modules/customizedAction/action_types';
import AlertSetting from './alert/';

import SubWorkflowSelect from '@/pages/admin/workflow/components/tree/setting/shared/subWorkflowSelect';

export default {
  props: {
    noWorkflow: {
      type: Boolean,
      default: true,
    },
    buttonFlow: Object,

  }, 
  components: {
    AlertSetting,
    SubWorkflowSelect,
  },
  computed: {
    currentApp() {
      return this.$store.getters.currentApp;
    },
    workflow() {
      const { buttonFlow } = this;
      if (buttonFlow) {
        return {
          ...buttonFlow,
          id: buttonFlow._id,
        };
      }
      return undefined;
    },
  },
  data() {
    return {
      options: [
        {
          id: 'noWorkflow',
          value: true,
          label: 'workflow.modules.actionProcess.label.buttonWorkflow.without',
        },
        {
          id: 'withWorkflow',
          value: false,
          label: 'workflow.modules.actionProcess.label.buttonWorkflow.normal',
        },
      ],
    }
  },
  methods: {
    onChanged(content) {
      this.$store.dispatch(UPDATE_ACTION_BUTTON_INFO, {
        field: 'noWorkflow',
        content,
      });
    },
    onChangeWorkflow(workflow) {
      this.buttonFlow = workflow;
      this.$store.dispatch(UPDATE_ACTION_BUTTON_INFO, {
        field: 'workflow',
        content: workflow,
      });
      // this.$set(this.buttonInfo, 'workflow', workflow);
    },
  },
}
</script>

<style scoped>
  .w-v-display-option {
    margin-right: 25px;
  }
  .w-customized-button-workflow {
    margin-top: 10px;
  }
</style>