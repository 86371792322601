<template>
  <v-dialog v-model="showingDialog"
    :width="500"
    scrollable
    persistent>
    <v-card>
      <dialog-header
        icon="mdi-filter"
        :label="$t('messages.filterSetup')"
        @close="close"/>
      <v-card-text class="px-0 pb-0" style="height:500px;">
        <filter-setup
          :form="form"
          v-model="filterConfigs"
          :fieldInfos="fieldInfos"></filter-setup>
      </v-card-text>
      <dialog-actions
        @onCommand="onCommandHandler"/>
    </v-card>
  </v-dialog>
</template>

<script>
import widgetHelperMixin from '@/mixins/widgetHelperMixin'

import dialogHeader from '@/components/dialogs/comps/DialogHeader'
import dialogActions from '@/components/dialogs/comps/DialogActions'
import filterSetup from './comps/FilterSetup'

export default {
  name: 'tableFilterSetupDialog',
  mixins: [
    widgetHelperMixin
  ],
  components: {
    dialogHeader,
    dialogActions,
    filterSetup
  },
  data () {
    return {
      showingDialog: false,

      form: null,
      filterConfigs: null,
      fieldInfos: [],
      titleFieldInfoId: '',

      callback: null,
      loading: false,
    }
  },
  computed: {
    titleFieldInfo() {
      const vm = this
      var result = null
      if (vm.fieldInfos && vm.titleFieldInfoId) {
        result = vm.fieldInfos.find(info => info._id === vm.titleFieldInfoId)
      }
      return result
    }
  },
  methods: {
    onCommandHandler (payload) {
      const vm = this
      console.log('relatedRecordSelectionSetupDialog :: onCommandHandler :: payload: ', payload)
      var tabIndex = 0
      switch (payload.command) {
        case 'updateErrorCount':
          // console.log('onCommandHandler :: updateErrorCount tabId=' + payload.tabId + ', count=' + payload.errorCount)
          tabIndex = vm.tabs.findIndex(tab => tab.id === payload.tabId)
          if (tabIndex >= 0) {
            vm.tabs[tabIndex].errorCount = payload.errorCount
          }
          break
        case 'checkValidity':
          tabIndex = payload.tabIndex
          const refObj = vm.getTabRefByIndex(tabIndex)
          if (refObj) {
            // console.log('relatedRecordSelectionSetupDialog :: onCommandHandler command=checkValidity :: refObj: ', refObj)
            // console.log('relatedRecordSelectionSetupDialog :: onCommandHandler command=checkValidity :: tabIndex = ' + tabIndex)
            refObj.checkIsValid(true)
          }
          break
        case 'ok':
          if (typeof vm.callback === 'function') {
            vm.callback(vm.filterConfigs)
          }
          vm.close()
          break
        case 'cancel':
          vm.close()
          break
      }
    },

    open (payload, callback) {
      const vm = this
      vm.form = payload.form
      vm.filterConfigs = payload.filterConfigs
      vm.fieldInfos = payload.fieldInfos
      vm.titleFieldInfoId = payload.titleFieldInfoId
      vm.callback = callback
      vm.showingDialog = true
    },

    close () {
      this.showingDialog = false
    },

  }
}
</script>
