<template>
  <div>
    <PendingSetup
      v-if="!dataSource"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <TableSourceView :node="node" dataSourceMode />
      <div class="workflow-shortview-remark">
        {{ $t('workflow.modules.updateRecord.label.edited', { size: dataSize }) }}
      </div>
    </div>
  </div>
</template>

<script>
import { get, keys } from 'lodash';
import PendingSetup from './../pending';
import Mixin from './../mixin';
import TableSourceView from './../tableSource';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  computed: {
    dataSource() {
      const { properties } = this.node;
      return get(properties, 'dataSource', '');
    },
    dataSize() {
      return keys(get(this.node, 'properties.data', {})).length;
    }
  },
  components: {
    PendingSetup,
    TableSourceView,
  },
};

</script>

<style scoped>
  
</style>
