<template>
    <v-row no-gutters class="bottom-border" align="center" justify="center">
        <v-col cols="10" class="pa-0 ma-0 right-border">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="6">
            <v-row no-gutters>
              {{ panel.label || $t('roles.noName')}}
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              dense
              hide-details
              v-model="readPanel"
            />
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
      </v-col>
    </v-row>
  </template>
  <script>

  export default {
    props: {
      panel: Object,
      panelId: String
    },
    computed: {
      readPanel: {
        get() {
          return this.panel.read;
        },
        set(val) {
          try {
            this.$emit('onCommand', {
              command: 'setReadPanel',
              panelId: this.panelId,
              value: val
            })
          } catch(err) {
            console.log('err: ', err);
          }
        }
      }
    }
  };
  </script>
  <style scoped>
  
  .right-border {
    border-right: 1px solid #9e9e9e;
  }
  </style>
  