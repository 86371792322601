<template>
    <div v-if="initialized">
        <v-dialog v-if="showDialog" v-model="showDialog" width="800">
            <v-card style="overflow: hidden;">
                <dialog-header
                    :label="dialogTitle"
                    @close="showDialog = false"
                />
                <v-card-text style="height:600px; overflow: scroll;">
                    <div class="px-8" v-if="publicEdits.length">
                        <sharable-card
                            v-for="sharableTemplate in publicEdits"
                            :key="sharableTemplate._id"
                            :sharableTemplate="sharableTemplate"
                            class="mt-5"
                            @onCommand="onCommandHandler"
                            :shareLink="getshareLink(sharableTemplate._id)"
                        />
                    </div>
                    <div v-else class="d-flex flex-column justify-center align-center" style=" height:100%">
                        <div style="font-size:14px">{{$t('publicEdit.emptyPublicEdit')}}</div>
                        <v-btn class="mt-2 primary" elevation="0" @click="toPublicPublishing">{{$t('publicEdit.toPublicEdit')}}</v-btn>
                    </div>
                </v-card-text> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import dialogHeader from "@/components/dialogs/comps/DialogHeader"
    import RandExp from 'randexp'
    import sharableCard from './sharableCard'
    export default {
        name: 'sharableRecord',
        props:{
            value:{
                type: Boolean,
                default: false
            },
            recordId:{
                type: String,
                default: null
            }
        },
        components:{
            dialogHeader,
            sharableCard
        },
        data(){
            return{
                copying: false,
                havePassword: false,
                dialogTitle: this.$t('publicEdit.shareableLink'),
                password: '',
                publicEdits: [],
                appId: null,
                formId: null,
                shareLinks: [],
                initialized: false
            }
        },
        async mounted(){
            this.appId = this.$route.params.id
            this.formId = this.$route.params.itemId
            this.recordId = this.recordId||this.$route.params.recordId
            await Promise.all([this.fetchTemplates(),this.fetchSharedLink()])
            this.initialized = true
        },
        computed:{
            appId() {
                const { params, currentForm } = this;
                return currentForm ? currentForm.appId : params ? params.id : '';
            },
            formId() {
                const { params, currentForm } = this;
                return currentForm ? currentForm._id : params ? params.itemId : '';
            },
            showDialog:{
                get(){
                    return this.value 
                },
                set(val){
                    this.$emit('input', val)
                }
            }
        },
        watch:{
            havePassword(val){
                this.password = ''
            }
        },
        methods:{
            toPublicPublishing(){
                this.$router.push({ name: 'adminPublicPublishing', params: { id: this.appId, itemId: this.formId} })
            },
            getshareLink(templateId){
                var result = this.shareLinks.find(item=>item.templateId===templateId)
                if(!result){
                    result = {
                        active:false,
                        url: "",
                        havePassword: false,
                        password: ""
                    }
                }
                return result
            },
            async fetchSharedLink(){
                const getData = {
                    urlCommand: `/shareLink/${this.appId}/${this.formId}/${this.recordId}`,
                }
                await this.$store.dispatch("AUTH_GET", getData).then((res) => {
                    this.shareLinks=res
                });
            },
            async save(data, cb){
                const postData = {
                    urlCommand: `/shareLink/${this.appId}/${this.formId}/${this.recordId}`,
                    data
                }
                await this.$store.dispatch("AUTH_POST", postData).then((res) => {
                    this.shareLinks = res
                    if(typeof cb === 'function'){
                        cb()
                    }
                });
            },
            async fetchTemplates(){
                const postData = {
                    urlCommand: "/publicEdit/list",
                    data: {
                        appId: this.$route.params.id,
                        formId: this.$route.params.itemId
                    },
                }
                await this.$store.dispatch("AUTH_POST", postData).then((res) => {
                    this.publicEdits = res
                });
            },
            genPassword(){
                let vm = this
                this.password = new RandExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,8}$/).gen()
            },
            _getRandomByte()
            {
                if(window.crypto && window.crypto.getRandomValues) {
                    var result = new Uint8Array(1);
                    window.crypto.getRandomValues(result);
                    return result[0];
                }
                else if(window.msCrypto && window.msCrypto.getRandomValues){
                    var result = new Uint8Array(1);
                    window.msCrypto.getRandomValues(result);
                    return result[0];
                }
                else{
                    return Math.floor(Math.random() * 256);
                }
            },
            onCommandHandler(payload){
                switch(payload.command){
                    case 'save':
                        this.save(payload.data, payload.cb)
                    break
                }
            }
        }
    }
</script>

<style>
    .passwordInput .v-input__slot{
        min-height: 25px !important;
        border-radius: 0px !important;
    }
    .passwordInput .v-input__append-inner{
        margin-top: 3px !important;
    }
    .passwordInput{
        margin-top:5px !important
    }
</style>
