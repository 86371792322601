<template>
<div class="level-data-card">
  <div class="pb-5">
    <v-card class="block-card handle"
            v-if="(item.label!=='')||(item.label===''&&dragging)"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave">
      <v-chip
          style="position:absolute;top:50%;left:100%;margin-top:-15px;margin-left:-15px;"
          color="primary">{{ item.children ? item.children.length : 0}}</v-chip>

      <v-card-text>
      <h1 class="display-1 text-primary my-0">
        {{ item.label }} (id={{ item.id }}) level={{ level }}
      </h1>
      <p>{{ item.label }}</p>
      </v-card-text>

      <v-card-content>

      </v-card-content>
    </v-card>
  </div>
</div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    item: Object,
    dragging: Boolean,
    level: Number
  },
  methods: {
    onMouseEnter () {
      this.$emit('onCommand', {command: 'pausePanZoom'})
      console.log('onMouseEnter')
    },
    onMouseLeave () {
      this.$emit('onCommand', {command: 'resumePanZoom'})
      console.log('onMouseLeave')
    }
  }
}
</script>

<style>
.level-data-card {
  display:inline-block;
  min-width: 280px;
}
</style>
