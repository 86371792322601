<template>
  <input-widget-container
    :isLayout="isLayout"
    :required="!isReadonly && required"
    :widget="widget"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :noIcon="noIcon"
    :isHidden="isHidden"
    :tooltipText="description">
    <v-text-field
      v-if="isReadOnly"
      :value="inputValue"
      disabled
      hide-details
      class="disabled"
      dense
    ></v-text-field>
    <v-text-field v-else-if="rowType==='single'"
      class="workflow-customized-form-record-element v-normal-text-input"
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      v-model="inputValue"
      clear-icon='mdi-close'
      dense
      :autocomplete="false"
      :readonly="isReadOnly"
      :ref="fieldInfo.fieldId"
      :rules="rules"
      clearable
      :hide-details="hideDetails"
      single-line
      outlined
      :prefix="prefix"
      :suffix="suffix"
      :placeholder="placeholder"
      :error-messages="errorMessage"
      :error="hasError"
      background-color="white"
      @blur="checkDuplicate"
      @input="errorMessage = ''"
    ></v-text-field>
    <v-textarea
      background-color="white"
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      class="workflow-customized-form-record-element v-normal-text-input"
      v-else
      v-model="inputValue"
      clear-icon='mdi-close'
      dense
      :ref="fieldInfo.fieldId"
      :rules="rules"
      :readonly="isReadOnly"
      outlined
      :hide-details="hideDetails"
      no-resize
      :rows="rows"
      :placeholder="placeholder"
      :prefix="prefix"
      :suffix="suffix"
      :error="hasError"
      :error-messages="errorMessage"
      @blur="checkDuplicate"
      @input="errorMessage = ''"
      @focus="focused=true"
 		  :clearable="hover||focused"
    ></v-textarea>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

export default {
  name: "inputWidgetText",
  mixins: [mixin],
  components: {
    inputWidgetContainer,
  },
  data(){
    return{
      focused: false
    }
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  computed: {
    hideDetails() {
      const vm = this;
      var result = "auto";
      if (vm.isLayout) result = true;
      return result;
    },
    rowType() {
      return getFieldPropertyValue(this.fieldInfo, "rowType", "single");
    },
    rows() {
      return getFieldPropertyValue(this.fieldInfo, "rowCount", 5);
    },
  },
  methods: {
    async validate(){
      if (this.isReadOnly) return true;
      if (this.$refs[this.fieldInfo.fieldId]){
        const valid = this.$refs[this.fieldInfo.fieldId].validate()
        const isDuplicated = await this.checkDuplicate()
        this.hasError = !valid || isDuplicated
        return valid && !isDuplicated
      } else return true
    },
    updateValue(val){
     this.$emit('input', val ? val.replace("\r\n", "\n"): "")
    },

  },
};
</script>

<style scoped>
.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
.v-normal-text-input {
  margin-bottom: 15px;
}
</style>
