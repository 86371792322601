<template>
  <Consumer>
    <v-row justify="center" slot-scope="{ navigatorInfo }">
      <v-tabs
        v-model="navigatorInfo.index"
        align-with-title
        class="editor-tabs-wrapper workflow-editor-tabs-wrapper"
        centered
        height="90"
        @change="onChangeTab"
      >
        <v-tabs-slider :color="$vuetify.theme.themes.light.primary"></v-tabs-slider>
        <v-tab
          active-class="tab-actived"
          v-for="({ key, label, total }) in list"
          :key="key"
          class="tab-item customized"
        >
          <span>
            {{ label }}
            <span v-if="total > 0 && !loading">({{ total }})</span>
          </span>
        </v-tab>
      </v-tabs>
    </v-row>
  </Consumer>
</template>

<script>
import Consumer from "./contexts/consumer";
import Routes from '@/const/workflow/routes';
import Mixin from  './../../mixin';

export default {
  mixins: [Mixin],
  props: {
    list: Array,
  },
  methods: {
    onChangeTab(i) {
      const eventType = this.eventTypeFromIndex(i);
      this.$router.replace({
        name: Routes.formWorkflows,
        params: {
          ...this.currentRouteParams,
          eventType,
        },
      });
    },
  },
  computed: {
    loading() {
      return this.$store.getters.fetchingWorkflowStatistic;
    },
  },
  inject: ["navigatorInfo", "setTitle", "setEventType", "eventTypeFromIndex"],
  components: {
    Consumer,
  },
};

</script>

<style scoped>
</style>
