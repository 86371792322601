<template>
  <div class="workflow-shortview">
    <component :is="target" :node="node" :tree="tree" :offset="offset" />
  </div>
</template>

<script>

import FormRootView from './form/';
import ConditionView from './condition/';
import CreateRecordView from './createRecord/';
import DeleteRecordView from './deleteRecord/';
import UpdateRecordView from './updateRecord/';
import RetrieveRecordView from './retrieveRecord';
import OperationView from './operation/';
import SubWorkflowView from './subWorkflow';
import ChildProcessView from './childProcess/';
import ActionProcessView from './actionProcess/';
import UpdateArgumentView from './updateArgument';
import InBrowserNotificationView from './notification/inBrowser';
import WhatsappNotificationView from './notification/whatsapp';
import SMSNotificationView from './notification/sms';
import TimerWorkflowView from './timer/';
import DateWorkflowView from './date/';
import EmailWorkflowView from './notification/email';
import GenerateFile from "./generateFile";
/*
* Target Module Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  data() {
    return {
      lookup: {
        form: 'FormRootView',
        branch: 'ConditionView',
        createRecord: 'CreateRecordView',
        deleteRecord: 'DeleteRecordView',
        updateRecord: 'UpdateRecordView',
        retrieveRecord: 'RetrieveRecordView',
        operation: 'OperationView',
        subWorkflow: 'SubWorkflowView',
        childProcess: 'ChildProcessView',
        actionProcess: 'ActionProcessView',
        updateArgument: 'UpdateArgumentView',
        inBrowserNotification: 'InBrowserNotificationView',
        whatsappNotification: 'WhatsappNotificationView',
        smsNotification: 'SMSNotificationView',
        timer: 'TimerWorkflowView',
        date: 'DateWorkflowView',
        email: 'EmailWorkflowView',
        generateFile:"GenerateFile"
      },
    }
  },
  computed: {
    target() {
      const target = this.lookup[this.node.type];
      // test only
      return !target ? this.lookup.form : target;
    },
  },
  components: {
    FormRootView,
    CreateRecordView,
    ConditionView,
    DeleteRecordView,
    UpdateRecordView,
    RetrieveRecordView,
    SubWorkflowView,
    OperationView,
    ChildProcessView,
    UpdateArgumentView,
    ActionProcessView,
    InBrowserNotificationView,
    WhatsappNotificationView,
    SMSNotificationView,
    TimerWorkflowView,
    DateWorkflowView,
    EmailWorkflowView,
    GenerateFile
  },
};

</script>

<style scoped>
  
</style>
