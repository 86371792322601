<template>
<div class="">
  <v-textarea
    class="commment-textarea mt-0 pt-0"
    id="commenter"
    rows="1"
    v-model="inputValue"
    auto-grow

  >
    <template v-slot:append>

      <v-icon @click="onUploadClick">mdi-paperclip</v-icon>

    </template>
  </v-textarea>
        <input
        type="file"
        ref="uploader"
        :multiple="true"
        @change="onInputFileChanged"
        class="d-none"
      />
</div>
</template>
<script>

export default {
  props: {
    value: String
  },

  computed: {
    inputValue: {
      set(val){
        this.$emit('input', val)
      },
      get(){
        return this.value
      }
    }
  },
  methods: {
    onUploadClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onInputFileChanged(e) {
      const vm = this;
      var files = [];
      for (var i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }
      vm.$emit("onCommand", {
        command: "uploadFiles",
        files
      })
    },


  },
};
</script>

<style scoped>
.comment-textarea {
  max-height: 200px;
  overflow-y: auto;
}
</style>
