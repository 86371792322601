<template>
    <div>
        <ComboBox
            :value="boxValue"
            :options="list"
            :valueFormat="valueFormat"
            :dependsOnType="dependsOnType"
            :placeholder="placeholder || $t('messages.pleaseChoose', [])"
            @changed="v => $emit('changed', v)"
            v-bind="comboxProps"
        />
    </div>
</template>

<script>
import { isArray } from 'lodash';
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
//   import { WidgetSelectionAcceptance } from '@/pages/admin/workflow/constants/form';
import NodeUtils from '@/pages/admin/workflow/utils/node';
export default {
    name: 'RuleSelectField',
    props:{
        formFieldInfos: Array,
        value: [String, Object],
        dependsOnType: String,
        fieldProperties: Object,
        valueFormat: {
            type: String,
            default: 'object',
        },
        comboxProps: Object,
        placeholder: String,
    },
    components: {
        ComboBox,
    },
    computed: {
        boxValue() {
            const { value } = this;
            return isArray(value) && !value.length ? null : (value || null)
        },
        list() {
            return NodeUtils.withAcceptanceOptions({
                dependsOnType: this.dependsOnType, 
                list: this.formFieldInfos, 
                properties: this.fieldProperties,
            });
        }
    }
}
</script>
