const { includes } = require('lodash');
const  {
    WorkflowTaskExecutionStatus,
} = require('./../../../../constants/node');
const mixin = {
    computed: {
        workflow() {
          return this.$store.getters.getWorkflowInfo;
        },
    },
    methods: {
        infoHeaderColor(status) {
            // const colors = {
            //     running: '#2196f3',
            //     complete: '#01ca83',
            //     fail: 'red',
            //     waiting: 'black',
            // }
            return 'white';
        },
        getInterval(interval) {
            return `${Math.round((interval / 1000) * 1000) / 1000 } ${this.$t('workflow.label.secondUnit')}`;
          },
          getHumanDate(date, status) {
            if (this.isCompleteState(status) || this.isFailState(status)) {
              return this.$locale.m(date).fromNow();
            }
            return '';
          },
          getFullDate(date, status) {
            if (this.isCompleteState(status) || this.isFailState(status)) {
              return this.$locale.m(date).calendar();
            }
            return '';
          },
          isCompleteState(status) {
            return includes([
              WorkflowTaskExecutionStatus.complete,
            ], status)
          },
          isFailState(status) {
            return includes([
              WorkflowTaskExecutionStatus.fail,
            ], status)
          },
          isRunningState(status) {
            return includes([
              WorkflowTaskExecutionStatus.running,
            ], status)
          },
          isWaitingState(status) {
            return includes([
              WorkflowTaskExecutionStatus.wating,
            ], status)
          },
    },
}

export default mixin
