<template>
<div id="canvas-chartHook" class="flex-grow-1">
    <v-hover v-slot="{ hover }" class="pa-0 ma-0" style="width:100%; height:100%;" >
        <v-card outlined tile flat class="chartContainer pa-0 ma-0 d-flex flex-column">
          <div class="chartActions" style="position: absolute; left: 50%;; z-index:1" v-show="hover && blHover">   
                <v-card style="position: relative; left: -50%;">
                    <v-btn :class="item.bl_title?'info--text':''" @click="item.bl_title=!item.bl_title" small icon :ripple="false" plain><v-icon size="18">mdi-format-title</v-icon></v-btn>
                    <v-btn small icon :ripple="false" plain @click="cloneItem(item)"><v-icon size="18">mdi-content-copy</v-icon></v-btn>
                    <v-btn small icon :ripple="false" plain @click="deleteItem(item)"><v-icon size="18">mdi-delete</v-icon></v-btn>
                </v-card>
            </div>
            <div class="pt-2 d-flex align-start">
                <div class="d-inline pl-3" style="font-size: 15px">{{item.value.chartName}}</div>
                <v-btn
                  class="ml-auto pa-0 ma-0 mr-1" 
                  small 
                  icon 
                  :ripple="false" 
                  plain
                  @click="editChart"
                  style="height: 18px"
                  v-if="hover && item.canEdit"
                >
                  <v-icon size="18">mdi-cog</v-icon>
                </v-btn>
            </div>
            <ChartCanvas
              class="chart-canvas"
              ref="chartBuilerCanvas"
              :dataSetups="item.value.dataSetups"
              :sourceSetups="item.value.sourceSetups"
              :styleSetupToggles="item.value.styleSetupToggles"
              :styleSetups="item.value.styleSetups"
              :selectedChart="item.value.selectedChart"
              :chartId="item.value._id"
              :chartName="item.value.chartName"
              :appId="item.value.appId"
              :formId="item.value.formId"
              :extraDateFilter="extraDateFilter"
              :key="chartCanvasUpdateKey"
            />
        </v-card>
    </v-hover>
  <ChartBuilder 
    v-if="showingChartEditor" 
    :currentChart="currentChart" 
    class="chart-builder" 
    @input="val=>showingChartEditor=val" 
    v-model="showingChartEditor"
    :appId="item.value.appId"
    :formId="item.value.formId"
    :views="views"
    tab="private"
    @updateChart="updateChart"
  />
</div>
</template>

<script>
import ChartCanvas from '@/components/chart/panes/ChartCanvas'
import ChartBuilder from '@/components/chart/ChartBuilder'
import { cloneDeep } from "lodash"

export default {
  name: 'compChart',
  components:{
    ChartCanvas,
    ChartBuilder
  },
  props: {
    blHover: Boolean,
    item: Object,
    extraDateFilter: Object
  },
  data(){
    return {
      showingChartEditor: false,
      editingChart: null,
      views: [],
      chartCanvasUpdateKey: 1,
      currentChart: null
    }
  },
  methods: {
    cloneItem (item) {
      this.$emit('cloneItem', item)
    },
    deleteItem (item) {
      this.$emit('deleteItem', item)
    },
    async editChart(){
      const payload = {
        formId: this.item.value.formId,
        appId: this.item.value.appId
      }
      await this.$store.dispatch('FETCH_FORM', payload).then(
        response => {
          this.views = response.views
          this.currentChart = cloneDeep(this.item.value)
          this.showingChartEditor = true
        }
      )
    },
    updateChart(chart){
      this.item.value = chart
      this.chartCanvasUpdateKey++
    }
  }
}
</script>
