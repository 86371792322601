 <template>
    <div class="my-4 queryContent">
        <WorksheetSelect
            v-if="normalizedConfig.worksheetInput"
            :worksheet="worksheet"
            ref="worksheetSelect"
        />
        <div class="mt-5 d-flex align-end">
            <div>{{$t('defaultValue.rulesSetup')}}</div>
            <div class="ml-auto" v-if="type=='form'">
                <v-checkbox
                    v-model="bl_lastRecord"
                    @change="handleLastRecordChange"
                    :label="$t('defaultValue.lastRecord')"
                    dense
                    :ripple="false"
                    hide-details
                ></v-checkbox>
            </div>
        </div>
        <v-container class="mt-5" v-if="(type=='form' && !bl_lastRecord) || type=='current'">
            <v-row style="font-size: 13px">
                <v-col cols="12" class="py-0">
                   <v-row v-if="!conditions.length">
                        <v-col cols="12" class="d-flex flex-column py-0 mt-1 px-0">
                            <v-btn
                                style="width:120px"
                                small
                                @click="showingFieldSelectionMenu=!showingFieldSelectionMenu" r
                                ounded 
                                color="info" 
                                outlined
                            >
                                {{$t('displayRule.setCondition')}}
                            </v-btn>
                            <div style="position: relative" :id="`${type}_setConditionBtn`"></div>
                            <field-selection-menu
                                v-if="showingFieldSelectionMenu"
                                v-model="showingFieldSelectionMenu"
                                :formFieldInfos="filteredFormFieldInfos"
                                :attachId="`${type}_setConditionBtn`"
                                :fullWidth="false"
                                @fieldClick="addCond"
                            />
                        </v-col>
                        <v-col cols="12" class="py-0 mt-1 px-0">
                            <span style="font-size:12px" class="grey--text">{{$t('displayRule.description')}}</span>
                        </v-col>
                    </v-row>
                     <v-row v-else style="font-size: 13px" class="">
                        <v-col cols="12" class="px-0 pl-1">
                            <v-row v-for="items, index in conditions" :key="index">
                                <v-col class="py-0 start-node-drawer-filter" cols="12"  v-for="cond, index2 in items" :key="index2">
                                    <v-hover v-slot="{hover}">
                                        <div class="d-flex flex-column px-2" :class="hover?'grey lighten-4':''" style="border-left: #2296f3 1px solid">
                                            <v-btn class="ml-auto" small color="error" fab :style="`visibility:${hover?'visible':'hidden'}`" style="height:17px; width:17px; transform: translate(8px,-8px)">
                                                <v-icon 
                                                    @click="deleteCond(cond.id, index, index2)"
                                                    class="start-node-drawer-icon-bin"
                                                    size="13"
                                                    fab
                                                >
                                                    mdi-close
                                                </v-icon>
                                            </v-btn>
                                            <div class="pl-3 pt-2">{{getFieldById(cond.fieldType, cond.fieldId).label}}</div>
                                            <div class="d-flex align-center pb-3 px-3">
                                                <div class="flex-grow-1">
                                                    <component
                                                        ref="conditions"
                                                        :content="cond.content"
                                                        :fieldType="cond.fieldType"
                                                        :formFieldInfos="list"
                                                        :rules="getFieldRules(cond.fieldId)"
                                                        :is="getFieldTypeComponent(cond.fieldType, cond.fieldId)"
                                                        :fieldProperties="getFieldById(cond.fieldType,cond.fieldId).properties"
                                                        :formInfo="getRelatedTableInfo(cond.fieldType, cond.fieldId)"
                                                        @updateType="$set(cond.content, 'type', $event)"
                                                        @updateRule="$set(cond.content, 'rule', $event)"
                                                        @updateValue="$set(cond.content, 'value', $event)"
                                                    >
                                                    </component>
                                                </div>
                                            </div>
                                        </div>
                                    </v-hover>
                                    <v-btn fab outlined v-if="index2!=items.length-1" @click="toOrHandler(index, index2)" class="white--text info" style="height: 25px; width:25px; font-size:13px; left:-12px">
                                        {{$t('displayRule.and')}}
                                    </v-btn>
                                    <div v-if="index2==items.length-1">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{on}">
                                                <div v-on="on" class="d-inline py-2 px-3 ml-5" style="cursor: pointer;">
                                                    <span style="font-size: 12px;">+ {{$t('displayRule.addCondition')}}</span>
                                                </div>       
                                            </template>
                                            <v-card outlined width="200" class="pa-3" style="max-height:250px; overflow:scroll">
                                                <v-list dense class="pa-0 align-center">
                                                    <v-list-item
                                                        class="pa-0 py-1"
                                                        dense
                                                        :ripple="true" 
                                                        v-for="(item) in filteredFormFieldInfos"
                                                        :key="item._id"
                                                        style="min-height:20px; font-size:12px"
                                                        :style="index!=(filteredFormFieldInfos.length-1)?'border-bottom: 1px solid #eeee':''"
                                                        @click="addCond(item, index)"
                                                    >
                                                        <font-awesome-icon :icon="getWidget(item.widgetId).icon" class="fa-fw mr-2"></font-awesome-icon>
                                                        <span class="">{{item.label}}</span>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                </v-col>
                                <v-col v-if="index!=conditions.length-1" cols="12" class="d-flex align-center">
                                    <div style="border-top:1px solid #d9d9d9;" class="flex-grow-1"/>
                                    <div>
                                        <div @click="toAndHandler(index)" style="font-size:14px; cursor: pointer" class="px-4 mt-1">
                                            <v-btn outlined class="warning white--text" fab style="width: 25px; height: 25px; font-size: 13px">{{$t('displayRule.or')}}</v-btn>
                                        </div>
                                    </div>
                                    <div style="border-top:1px solid #d9d9d9;" class="flex-grow-1"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <div class="mt-8">
            {{$t('defaultValue.copyValueFrom')}}
        </div>
        <div>
            <ComboBox
                :options="list"
                @changed="handleCopyFieldChange"
                :value="copyField"
                v-bind="comboxProps"
                :valueFormat="valueFormat"
                :dependsOnType="dependsOnType"
            />
        </div>
    </div>
 </template>


 <script>
    import widgetHelperMixin from '@/mixins/widgetHelperMixin'
    import filterHelperMixin from '@/mixins/filterHelperMixin'

    import RuleText from '@/pages/admin/form/tableSetup/rules/RuleText'
    import RuleNumber from '@/pages/admin/form/tableSetup/rules/RuleNumber'
    import RuleDate from '@/pages/admin/form/tableSetup/rules/RuleDate'
    import RuleSelection from '@/pages/admin/form/tableSetup/rules/RuleSelection'
    import RuleMembers from '@/pages/admin/form/tableSetup/rules/RuleMembers'
    import RuleDepartments from '@/pages/admin/form/tableSetup/rules/RuleDepartments'
    import RuleRadio from '@/pages/admin/form/tableSetup/rules/RuleRadio'
    import RuleRelatedRecord from '@/pages/admin/form/tableSetup/rules/RuleRelatedRecord'
    import RuleChildTable from '@/pages/admin/form/tableSetup/rules/RuleChildTable'
    import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
    import WorksheetSelect from '@/pages/admin/workflow/components/tree/setting/shared/worksheetSelect'
    import fieldSelectionMenu from '../menus/fieldSelectionMenu'

    import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
    import { upperFirst } from 'lodash';
    export default {
        mixins:[widgetHelperMixin, filterHelperMixin],
        props:{
            type: String,
            config:{
                type: Object,
                default:{}
            },
            copyField:{
                type: Object,
                default: null
            },
            bl_lastRecord: Boolean,
            conditions: {
                type: Array,
                default: []
            }
        },
        components:{
            WorksheetSelect,
            fieldSelectionMenu,
            RuleText,
            RuleNumber,
            RuleDate, 
            RuleSelection,
            RuleMembers,
            RuleDepartments,
            RuleRadio,
            RuleRelatedRecord,
            RuleChildTable,
            ComboBox
        },
        data(){
            return{
                defaultConfig:{
                    worksheetInput: true
                },
                showingFieldSelectionMenu: false,
                valueFormat: 'object',
                comboxProps:{onlySuffix: true,onlyPrefix: false},
                dependsOnType: null,
            }
        },
        computed:{
            relatedTableInfos(){
                return this.$store.getters.relatedTableInfos
            },
            normalizedConfig(){
                var res = Object.assign({}, this.defaultConfig, this.config)
                return res
            },
            worksheet(){
                return this.$store.state.workflowTree.setting.worksheet
            },
            list() {
                return this.$store.getters.getFormSelectionsFields.children || []    
            },
            filteredFormFieldInfos(){
                var excludeArr = ["owner", "updatedAt", "createdAt", "createdBy", "capitalizedAmount",  "autoNumbering", "location", "richText", "region"] 
                return this.list.filter(item=> !excludeArr.includes(item.fieldType))
            },
            fieldIdsWithProperties () {
                const vm = this
                var result = []
                for (let i = 0; i < vm.list.length; i++) {
                    var loopFieldInfo = vm.list[i]
                    
                    if(loopFieldInfo.fieldType != 'otherTableField')
                        var filterType = vm.getFilterKeyByFieldInfo(vm.$store.getters.widgetMap, loopFieldInfo)
                    else{
                        // var filterType = vm.getFilterKeyByFieldInfo(vm.$store.getters.widgetMap, loopFieldInfo.relatedField)
                        var relatedField = this.getOtherTableField(loopFieldInfo)
                        if(relatedField){
                            var filterType = vm.getFilterKeyByFieldInfo(vm.$store.getters.widgetMap, relatedField)
                        }else{
                            var filterType = null
                        }
                    }

                    if (filterType) {
                        result.push({
                            id: loopFieldInfo.fieldId,
                            label: loopFieldInfo.label,
                            filterType: filterType,
                            fieldInfo: loopFieldInfo
                        })
                    }
                }
                return result
            },
            filterInfos () {
                return this.$store.getters.filterInfos
            }
        },
        methods:{
            handleLastRecordChange(val){
                this.$emit('onCommand',{
                    command: 'updateLastRecord',
                    data: val
                })
            },
            onCommandHandler(payload){
                this.$emit('onCommand', payload)
            },
            handleCopyFieldChange(val){
                if(val != undefined)
                    this.$emit('onCommand', {
                        command: 'updateCopyField',
                        data: val
                    })
                    // this.copyField = val
            },
            toAndHandler(index1){
                this.conditions[index1+1].forEach(item=>{
                    this.conditions[index1].push(item)
                })
                this.conditions.splice(index1+1,1)
            },
            toOrHandler(index1, index2){
                var arr2 = this.conditions[index1]
                var arr1 = []
                arr1 = arr2.splice(0, index2+1)
                this.conditions.splice(index1, 0, arr1)
            },
            getRelatedTableInfo(fieldType, fieldId){
                var field = this.getFieldById(fieldType, fieldId)
                if(field.fieldType == 'relatedRecord'){
                    return this.relatedTableInfos[field.properties.dataSource]||{}
                }
            },
            getFieldTypeComponent(type, fieldId){
                if(type == 'otherTableField'){
                    // var relatedField = this.list.find(item=>item.fieldId==fieldId).relatedField
                    var field = this.list.find(item=>item.fieldId==fieldId)
                    var relatedField = this.getOtherTableField(field)
                    type = relatedField.type
                }
                const target = WorkflowSettingUtils.getFilteringFieldTypeComponent(type);
                return `Rule${upperFirst(target)}`;
            },
            getFieldById(fieldType, fieldId){
                if(fieldType == 'otherTableField'){
                    var field = this.list.find(item=>item.fieldId==fieldId)
                    // var relatedField = field.relatedField
                    var relatedField = this.getOtherTableField(field)
                    relatedField.label = field.label
                    return relatedField
                }else
                return this.list.find(item=>item.fieldId == fieldId)
            },
            deleteCond(id, pos, pos2){
                this.$emit('onCommand',{
                    command: 'deleteCond',
                    index1: pos,
                    index2: pos2
                })
            },
            addCond(item, index=0){
                var rules = this.getFieldRules(item.fieldId)
                var cond = {
                    id: this.getTempId(),
                    fieldType: item.fieldType,
                    fieldId: item.fieldId,
                    content: {
                        rule: rules.options[0].value,
                        value: null,
                        type: 1
                    }
                }
                this.$emit('onCommand',{
                    command: 'addCond',
                    data: cond,
                    index
                })
            },
            getFieldRules(fieldId){
                let vm = this
                var type = this.fieldIdsWithProperties.find(item=>item.id == fieldId).filterType
                var filterInfos = JSON.parse(JSON.stringify(this.filterInfos[type]))
                filterInfos.options = filterInfos.options.map((item,index)=>{
                    return{
                        id: item,
                        label: item,
                        type: 'label',
                        value: item
                    }
                })
                return filterInfos
            },
            getTempId() {
                return "_" + Date.now().toString();
            },
            getOtherTableField(field){
                var fromField = this.list.find(item=>item.fieldId == field.properties.fieldForRelatedTable)
                if(fromField){
                    var dataTableId = fromField.properties.dataSource
                    var relatedForm = this.relatedTableInfos[dataTableId]
                    var relatedField = relatedForm.fieldInfos.find(item=>item.fieldId==field.properties.relatedTableField)
                    return relatedField
                }
                return null
            }
        }
    }
 </script>

<style>
    .queryContent .v-input__slot{
        align-items: end !important;
    }
</style>