<template>
  <div>
    <label>{{ label }}</label>
    <div class="d-flex flex-column align-content-between pa-2" style="border: 1px solid lightgray;">
      <v-btn
        v-if="!useTwoWayRelation"
        :disabled="!dataSourceInfo"
        @click="setTwoWayRelation"
        fab
        color="primary"
        x-small
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div v-else class="d-flex flex-row justify-start position-relative">
        <div class="d-flex flex-column">
          <label>{{ $t('messages.inForm') }}</label>
          <v-chip small color="primary">{{ dataSourceInfo.label }}</v-chip>
          <label>{{ $t('messages.relatedFieldDisplayedAs') }}</label>
          <v-chip small color="primary">{{ summary.fieldName }}</v-chip>
          <label>{{ $t('widgets.properties.displayStyle') }}</label>
          <v-chip
            small
            color="primary"
          >{{ getDisplayStyleStr(summary.displayStyle, summary.relatedRecordQuantity) }}</v-chip>
        </div>
        <div class="position-absolute d-flex flex-row justify-start align-items-stretch align-center" 
          style="left:0;top:0;width:100%;height:100%;"
          :style="{backgroundColor:twoWayCorrupted?'rgba(200,200,200,.5)':'transparent'}">
          <div class="flex-grow-1 pa-2 text-center">
            <v-icon v-if="twoWayCorrupted" class="red--text text-h1 mx-auto align-self-center"
              style="opacity:0.5;">mdi-cancel</v-icon></h1>
          </div>
          <v-btn class="flex-grow-0 align-self-start"
            fab color="error" x-small @click="removeTwoWayRelation">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
      <!--      <field-selection-button
          ref="fieldSelectionButton"
          :fieldInfos="copyableFieldInfos"
      @click="addField"></field-selection-button>-->
    </div>
    <two-way-relation-setup-dialog ref="twoWayRelationSetupDialog"></two-way-relation-setup-dialog>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </div>
</template>

<script>
import mixin from "./mixin";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import TwoWayRelationSetupDialog from "@/components/dialogs/TwoWayRelationSetupDialog";

export default {
  name: "witTwoWayRelated",
  mixins: [mixin],
  components: {
    ConfirmDeleteDialog,
    TwoWayRelationSetupDialog
  },
  data () {
    return {
      tempTwoWayRelationSummary: {
        displayStyle: "card",
        fieldName: "",
        relatedRecordQuantity: 'single'
        // relationType: "single"
      },
      twoWayCorrupted: false
    };
  },
  watch: {
    value: function(newVal){
      if(newVal[0]=="1")
        this.checkValidity()
    },
    dataSourceInfo: function(newVal) {
      this.checkValidity();
    },
    dataSource: function(newVal) {
      const vm = this

      if (newVal) {
        if (vm.propertyValue) {
          var segs = vm.propertyValue.split('|')
          if (segs.length > 1) {
            if (segs[1] === '') {
              segs[1] = vm.getTempId()

              vm.propertyValue = segs.join('|')              

            } else {
              // if segs[1] is not blank,
              // check if two-way defined
              // if so, check validity
              if (segs[0] === '1') {
                vm.checkValidity()
              }
            }
          } else {

            vm.propertyValue = '0|' + vm.getTempId()  
          }
        } else {

          vm.propertyValue = '0|' + vm.getTempId()
        }
      }
    }
  },
  mounted () {
    this.checkValidity()
  },
  computed: {
    isNewTwoWayRelation() {
      return this.relatedFieldId.startsWith("_");
    },
    
    summary() {
      const vm = this;
      console.log('summary: propertyValue: ', vm.propertyValue)
          // 0: '0' or '1' // use twoWayRelation
          // 1: related field id
          // 2: field name
          // 3: relation type
          // 4: display style

      console.log('summary: relatedTableFieldInfos: ', vm.relatedTableFieldInfos)
      console.log('summary: relatedFieldInfo: ', vm.relatedFieldInfo)

      var result = {
        fieldName: vm.propertyValueSegments[2],
        relatedRecordQuantity: vm.propertyValueSegments[3],
        // relationType: vm.propertyValueSegments[3],
        displayStyle: vm.propertyValueSegments[4]
      };

      if (vm.relatedFieldInfo) {
        result['fieldName'] = vm.relatedFieldInfo.label
        result['relatedRecordQuantity'] = vm.relatedFieldInfo.relatedRecordQuantity
        // result['relationType'] = vm.relatedFieldInfo.type === "relatedRecord"
        //     ? "single"
        //     : "multiple"
        result['displayStyle'] = vm.relatedFieldInfo.properties.displayStyle
      }
      return result
    },

    // TwoWayRelated:
    // propertyValue =
    //  "0:5fa36e32bfe0013874b067fc"
    //  "1:5fa36e32bfe0013874b067fc"
    //
    propertyValueSegments() {
      const vm = this;
      var result = [
        '0', // useTwoWayRelation
        '', // relatedFieldId
        '', // relatedFieldName
        '', // relationType
        '' // displayStyle
      ]
    
      if (vm.propertyValue && vm.propertyValue !== "") {
          console.log('propertyValueSegments: :: vm.propertyValue = ' + JSON.stringify(vm.propertyValue))
        const segs = vm.propertyValue.split("|");
        result[0] = segs[0];
        if (segs.length > 1) result[1] = segs[1]; // field id
        if (segs.length > 2) result[2] = segs[2]; // field name
        if (segs.length > 3) result[3] = segs[3]; // relation type
        if (segs.length > 4) result[4] = segs[4]; // display style
      }
      return result;
    },
    useTwoWayRelation() {
      return this.propertyValueSegments[0] === "1";
    },
    relatedFieldId() {
      return this.propertyValueSegments[1];
    },
    relatedFieldInfo() {
      return this.relatedTableFieldInfos.find(
        info => info.fieldId === this.relatedFieldId
      );
    },


    fieldCount() {
      const vm = this;
      var result = 0;
      if (vm.propertyValue) {
        if (vm.propertyValue.relatedToLocalFields) {
          result = Object.keys(vm.propertyValue.relatedToLocalFields).length;
        }
      }
      return result;
    },

    relatedTableFieldInfos() {
      const vm = this;
      var result = vm.dataSourceFieldInfos;
      return result;

    },

  },
  methods: {
      checkValidity () {
        const vm = this
        let valid = false
        const isTwoWay = vm.propertyValue[0]
        if (isTwoWay === '1' && this.dataSourceInfo){
          const twoWayRelatedFieldId = vm.propertyValue.split('|')[1]
          if(twoWayRelatedFieldId[0]!='_'){
            const relatedFieldInfo = this.dataSourceInfo.fieldInfos.find(info => info.fieldId === twoWayRelatedFieldId)
            if (relatedFieldInfo){
              const {dataSource, twoWayRelated} = relatedFieldInfo.properties
              valid = dataSource === this.formId && twoWayRelated.split('|')[1] === this.fieldInfo.fieldId
            }
          }else valid = true
        }
        vm.twoWayCorrupted = !valid

      },
      // getDisplayStyleStr (displayStyle, relationType) {
      getDisplayStyleStr (displayStyle, relatedRecordQuantity) {
          const vm = this
          var result = ''
          switch (displayStyle) {
              case 'table':
                result += vm.$t('widgets.properties.table')
                break
            case 'card':
                result += vm.$t('widgets.properties.card')
                break
            case 'pulldown':
                result += vm.$t('widgets.properties.pulldown')
                break
          }
          result += ' (' + 
            (
                relatedRecordQuantity === 'single' ?
                vm.$t('widgets.properties.singleRecord') :
                vm.$t('widgets.properties.multipleRecords')
            ) + 
            ')'
        return result
      },
    removeTwoWayRelation() {
        const vm = this
        if (vm.isNewTwoWayRelation) {
          vm.disableTwoWayRelation()
        } else {
            vm.$refs.confirmDeleteDialog.confirm(() => {
              vm.disableTwoWayRelation()
            }, {
              message: vm.$t('messages.allLinkedRelatedFieldsInTableXXXWillBeRemovedAreYouSure', {formName: vm.dataSourceInfo.label})
            })
        }
    },
    disableTwoWayRelation () {
      const vm = this
      var segs = vm.propertyValue.split('|')
      segs[0] = '0'
      // console.log('disableTwoWayRelation')
      vm.propertyValue = segs.join('|')
      // console.log('disableTwoWayRelation: vm.propertyValue: ' + vm.propertyValue)
    },
    getTempId() {
      return "_" + Date.now().toString();
    },
    setTwoWayRelation() {
      const vm = this;
      vm.$refs.twoWayRelationSetupDialog.open({
        fieldName: vm.formInfo.label,
        callback: payload => {
          // console.log('witTwoWayRelated :; callback: payload: ', payload)
          const segs = vm.propertyValue.split('|')
          const tempId = segs.length >1 ? segs[1] : vm.getTempId()
          while (segs.length < 5) segs.push('')

          segs[0] = '1'
          segs[1] = tempId
          segs[2] = payload.fieldName
          segs[3] = payload.relatedRecordQuantity
          // segs[3] = payload.relationType
          segs[4] = payload.displayStyle
          
          // console.log('setTwoWayRelation')
          vm.propertyValue = segs.join('|')
          vm.tempTwoWayRelationSummary = {
            id: tempId,
            ...vm.tempTwoWayRelationSummary,
            fieldName: payload.fieldName,
            relatedRecordQuantity: payload.relatedRecordQuantity,
            // relationType: payload.relationType,
            displayStyle: payload.displayStyle,
            ...payload
          };
          console.log('witTwoWayRelated :; tempTwoWayRelationSummary: ', vm.tempTwoWayRelationSummary)
        }
      });
    },

    _parseValue(value) {
      // console.log('_parseValue : value: ', value)
      const vm = this;
      var result = value;
      if (!result) {        
        // console.log('not result')
        result = '0|' + vm.getTempId()
      } else {
        // console.log('previuosly has data')
        if (result === '0') {
          result = '0|' + vm.getTempId()
        } else {
          console.log('result not 0. is : ', result)
        }
      }
      // console.log('_parseValue :: value: ', value)
      // console.log('_parseValue :: result: ', result)
      return result;
    },
    addField(fieldInfo) {
      const vm = this;
      var updatedPropertyValue = JSON.parse(JSON.stringify(vm.propertyValue));
      updatedPropertyValue[fieldInfo.fieldId] = [];
      // console.log('addField fieldInfo')
      vm.propertyValue = updatedPropertyValue;

    },


    confirmHandler(newPropertyValue) {
      // console.log('confirmHandler')
      this.propertyValue = newPropertyValue;
    }
  }
};
</script>
