<template>
    <div style="border-right: 1px solid #eeee; overflow:auto" class="px-4">
        <div style="font-size: 16px" class="font-weight-bold py-2">{{$t('chart.chart')}}</div>
        <div>
            <v-btn 
                @click="$emit('onCommandHandler',{
                    command: 'update_selectedChart',
                    value: type.id
                })" 
                :class="selectedChart==type.id?'primary white--text':''" 
                outlined 
                elevation="0" 
                v-for="(type,index) in chartTypes.filter(item=> item.hidden==false)" 
                :key="index" 
                small 
                class="pa-0 mr-2 mt-2" 
                style="min-width:35px;"
            >
                <v-icon>{{type.icon}}</v-icon>
            </v-btn>
        </div>
        <div class="mt-5">
            <v-tabs
                height="30"
                class="white"
                centered
                dark
                icons-and-text
                fixed-tabs
            >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab class="grey--text">
                    {{$t('chart.configure')}}
                </v-tab>
                <v-tab class="grey--text">
                    {{$t('chart.style')}}
                </v-tab>
                <v-tab-item class="">
                    <ChartDataConfig 
                        :chartFigure="styleSetups.chartFigure"
                        :arrX="arrX" 
                        :arrY="arrY" 
                        :arrG="arrG" 
                        :arr2Y="arr2Y"
                        :filters="filters"
                        :selectedChart="selectedChart"
                        @onCommandHandler="onCommandHandler"
                        :setupIsUpdated="setupIsUpdated"
                    />
                    <div class="py-3 white">
                       <v-btn 
                        @click="$emit('onCommandHandler', {
                            command: 'saveChart'
                        })" 
                        rounded 
                        color="primary" 
                        small 
                        elevation="0" 
                        class="px-1" 
                        style="width:120px; height:22px;"
                    >
                        {{$t('chart.save')}}
                    </v-btn>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <ChartStyleConfig
                        :styleSetupToggles="styleSetupToggles"
                        :styleSetups="styleSetups"
                        :arrX="arrX"
                        :arrY="arrY"
                        :selectedChart="selectedChart"
                        :titleTree="titleTree"
                        :selectedHidden="selectedHidden"
                        @onCommandHandler="onCommandHandler"
                    />
                    <div class="py-3 white">
                        <v-btn 
                            @click="$emit('onCommandHandler', {
                                command: 'saveChart'
                            })" 
                            rounded 
                            color="primary" 
                            small 
                            elevation="0" 
                            class="px-1" 
                            style="width:120px; height:22px;"
                        >
                            {{$t('chart.save')}}
                        </v-btn>
                    </div>
                </v-tab-item>
            </v-tabs>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin'
    import ChartStyleConfig from './config/ChartStyleConfig'
    import ChartDataConfig from './config/ChartDataConfig'
    export default {
        mixins: [mixin],
        props:{
            styleSetupToggles: Object,
            styleSetups: Object,
            // chartFigure: String,
            selectedChart: String,
            chartTypes: Array,
            titleTree:{
                type: Array,
                default: []
            },
            selectedHidden:{
                type: Array,
                default: []
            },
            setupIsUpdated:{
                type: Object,
                default:{
                    arrX: false,
                    arrY: false,
                    arr2Y: false,
                    arrG: false,
                    filters: false,
                }
            }
        },
        components:{
            ChartStyleConfig,
            ChartDataConfig
        },
        computed:{
            toggles(){
                return this.styleSetupToggles
            },
            arrX(){
                return this.dataSetups.arrX
            },
            arrY(){
                return this.dataSetups.arrY
            },
            arr2Y(){
                return this.dataSetups.arr2Y
            },
            arrG(){
                return this.dataSetups.arrG
            },
            filters(){
                return this.dataSetups.filters
            },
        },
        methods:{
            onCommandHandler(payload){
                this.$emit('onCommandHandler', payload)
            },
        }
    }
</script>