<template>
<div class="d-flex align-center" style="width:100%">
    <div style="width:100px">
        <v-select
            v-model="actionId"
            @change="$emit('updateAction', actionId)"
            :items="actionArr"
            item-value="id"
            item-text="name"
            :menu-props="{offsetY: true, contentClass: 'denseList'}"
            class="vselect-small"
            style="font-size:12px;"
            height="30"
            dense
            outlined
            hide-details
        ></v-select>
    </div>
    <div class="ml-2 d-flex flex-wrap align-start" style="width:400px;" v-if="actionId!='3'">
        <v-select
            hide-details
            v-model="actionFields"
            @change="$emit('updateActionFields', actionFields)"
            :items="fields"
            multiple
            item-value="_id"
            item-text="label"
            :menu-props="{offsetY: true, contentClass: 'denseList'}"
            style="font-size:12px;"
            dense
            outlined
        >
            <template v-slot:selection="{ item, index }">
                <v-chip class="ma-1" style="height: 20px !important">
                    <span style="font-size:12px ">{{ item.label }}</span>
                </v-chip>
            </template>
        </v-select>
    </div>

    <div v-else class="d-flex align-center ml-2 workflow-setting-columnType-select-root" style="width:100%">
        <v-select
            hide-details
            v-model="actionFields"
            @change="$emit('updateActionFields', actionFields)"
            :items="numberFields"
            item-value="_id"
            item-text="label"
            :menu-props="{offsetY: true, contentClass: 'denseList'}"
            style="font-size:12px; min-width:200px; width:150px"
            dense
            outlined
            height="30"
        >
            <template v-slot:selection="{ item, index }">
                <v-chip class="ma-1" style="height: 20px !important; overflow: hidden; text-overflow:ellipsis;">
                    <span style="overflow: hidden; text-overflow:ellipsis;font-size:12px ">{{ item.label }}</span>
                </v-chip>
            </template>
        </v-select>
        <v-text-field
            v-if=""
            outlined 
            class="ml-2"
            style="font-size:12px"
            hide-details
            v-model="actionValue"
            @change="$emit('updateActionValue', $event)"
            type='number'
        ></v-text-field>
        <!-- <ComboBox
            style="min-width:250px"
            class="ml-2"
            :value="type || null"
            :options="types"
            :placeholder="$t('messages.pleaseChoose')"
            @changed="v => type = v"
        /> -->

    </div>
</div>
</template>

<script>
    import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
    export default {
        name: 'ActionSelectField',
        props:{
            formFieldInfos: Array,
            actionFields:{
                type: Array,
                default: []
            },
            actionId:{
                type: String,
                default: null
            },
            action: Object,
            formUIElementInfos: Array,
            types: Array,
            actionValue:{
                type: String,
                default: null
            }
        },
        components:{
            ComboBox
        },
        computed:{
            fields(){
                return this.formFieldInfos.concat(this.formUIElementInfos)
            },
            numberFields(){
                return this.formFieldInfos.filter(item=>['amount', 'number'].includes(item.type))
            }
        },
        data(){
            return {
                actionArr: [
                    {
                        id: "1",
                        name: this.$t('displayRule.show')
                    },
                    {
                        id: "2",
                        name: this.$t('displayRule.hide')
                    },
                    {
                        id: "3",
                        name: this.$t('displayRule.assign')
                    },
                    // {
                    //     id: "3",
                    //     name: '提示錯誤'
                    // }
                ]
            }
        }
    }
</script>