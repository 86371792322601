<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :isHidden="isHidden"
      :noIcon="noIcon"
      :tooltipText="description">
    <v-checkbox dense 
      class="mt-0"
      v-model="inputValue" 
      :label="checkboxLabel"
      :disabled="isReadOnly"
      :rules="rules"
      :error-messages="errorMessage"
      :error="hasError"
    />
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

export default {
  name: "inputWidgetYesNo",
  mixins: [mixin],
  components: {

    inputWidgetContainer,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data() {
    return {
      RULE_REQUIRED: (v) => v||this.$t("messages.thisFieldIsRequired")
    };
  },
  computed: {
    checkboxLabel(){
      return getFieldPropertyValue(this.fieldInfo, "checkboxLabel", "");
    }
  },
  methods: {
    // validate(){
    //   if (this.isReadOnly) return true;
    //   if (this.rules.length > 0){
    //     if(
    //       this.rules.some(rule=> {
    //         const result = rule(this.value)
    //         if (result !== true) {
    //           alert(result)
    //           this.errorMessage = result;
    //           this.hasError = true
    //           return true;
    //         }
    //       })
    //     ){
    //       return false
    //     }
    //   }
    //   this.hasError = false
    //   return true
    // },
    getDefaultValue() {
      return getFieldPropertyValue(this.fieldInfo, "yesNoDefault", false);
    },
  },
};
</script>

<style scoped>

.v-boolean-yesno-input{
  margin-bottom: 15px;
}
</style>
