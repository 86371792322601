<template>
  <div>
    <h1>AdminForm</h1>
    currentApp: {{ currentApp.title }}
  </div>
</template>

<script>
import appsFormsMixin from '@/mixins/appsFormsMixin'

export default {
  mixins: [appsFormsMixin],
  computed: {
    currentApp () {
      return this.$store.getters.currentApp
    }
  },
  mounted () {
    const vm = this
    const formId = vm.$route.params.formId
    vm.loadForm(formId)
  },
  watch: {
    '$route.params.formId': function (newVal) {
      const vm = this
      vm.loadForm(newVal)
    }
  }
}
</script>
