<template>
  <!-- just using number template temporary -->
  <vxe-table-column
    v-bind="colDef"
    :show-overflow="false"
    class="text-right"
    sortable
  >
    <template v-slot="{ row }">
      <div
        style="overflow:hidden"
        :class="rowHeightClass"
        class="d-flex align-center"
        v-if="typeof row === 'object'"
      >
        <pre
          style="overflow: hidden; white-space: no-wrap; text-overflow: ellipsis"
          >{{ checkAndShowValue(row, fieldId) }}</pre
        >
      </div>
    </template>

    <template v-slot:footer="{ column }">
      <summary-cell
        :decimals="decimals"
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"
      ></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import summaryCell from "./comps/SummaryCell";
import textCombinationMixin from "@/mixins/textCombinationMixin";

export default {
  mixins: [mixin, textCombinationMixin],
  components: {
    summaryCell,
  },
  props: ["rowHeightClass"],
  methods: {
    checkAndShowValue(row, fieldId) {
      let result = row[fieldId];

      if (this.colDef["tableType"] === "child") {
        result = this.getCombinedField(
          this.fieldInfo.properties.selectFieldCombination,
          row,
          this.fieldInfo,
          this.colDef.fieldInfos,
          this.relatedTableData,
          this.relatedTableInfos
        );
      }
      return result;
    },
  },
};
</script>

<style>
.vxeTextCombination {
  padding: 0px !important;
}
</style>
