<template>
  <div class="d-flex flex-column copy-field-row">
    <div>{{ sourceFieldInfo.label }}</div>
    <div class="d-flex flex-row align-center">
      <v-select 
        chips
        deletable-chips
        small-chips
        dense
        :hide-details="true"
        class="flex-grow-1"
        :items="allowedFieldInfos"
        :placeholder="$t('messages.selectThisFormFields')"
        multiple
        solo
        item-text="label"
        item-value="fieldId"
        outlined
        v-model="selectedItems"
        :menu-props="{offsetY: true, contentClass: 'denseList'}"
      ></v-select>
      <v-btn small icon color="error" class="ml-1"
             @click="onDelete">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'copyFieldItem',
    model: {
      prop: 'targetFieldIds',
      event: 'input'
    },
    props: {
      targetFieldIds: {
        type: Array,
        default () {
          return []
        }
      },

      sourceFieldInfo: {
        type: Object,
        default () {
          return {
            label: ''
          }
        }
      },
      fieldInfos: {
        type: Array,
        default () {
          return []
        }
      },
      allSelectedCurrentFormFieldIds: {
        type: Array,
        default () {
          return []
        }
      }
    },
    methods: {
      onDelete () {
        this.$emit('click:delete', this.sourceFieldInfo)
      },
    },
    computed: {
      idMappings () {
        const vm = this
        var result = {}
        const sourceFieldType = vm.sourceFieldInfo.type
        for (let i = 0; i < vm.fieldInfos.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i]
          if (loopFieldInfo.type === sourceFieldType) {
            result[loopFieldInfo.fieldId] = loopFieldInfo.label
          }
        }
        return result
      },
      copyableWidgetTypes () {
        return this.$store.getters.copyableWidgetTypes
      },
      copyableWidgets () {
        return this.$store.getters.copyableWidgets
      },
      selectedFieldIds () {
        const vm = this
        return vm.targetFieldIds.filter(fieldId => vm.allowedFieldIds.includes(fieldId))
      },
      allowedTargetWidgetTypes () {
        const vm = this
        var result = []
        if (vm.copyableWidgets && vm.sourceFieldInfo) {
          result = vm.copyableWidgets[vm.sourceFieldInfo.type]
        }
        return result
      },
      allowedFieldInfos () {
        const vm = this
        var result = []
        if (vm.fieldInfos && vm.allowedTargetWidgetTypes) {
          result = vm.fieldInfos.filter(info =>{
            var isAllowedType = vm.allowedTargetWidgetTypes.includes(info.type)
            // var isRelatedTableMatched = vm.fieldInfos.
            var isRelatedTableMatched = ((info.type=='relatedRecord') && 
              (info.properties.dataSource == vm.sourceFieldInfo.properties.dataSource))||
              !(info.type=='relatedRecord')
            return isAllowedType && isRelatedTableMatched
          })
        }
        // if (vm.fieldInfos && vm.allowedTargetWidgetTypes) {
        //   result = vm.fieldInfos.filter(info =>
        //     (
        //       vm.allowedTargetWidgetTypes.includes(info.type) &&
        //       !vm.allSelectedCurrentFormFieldIds.includes(info.fieldId)
        //     )
        //   )
        // }
        // const notSelectedFieldIds = result.map(info => info.fieldId)
        // if (vm.targetFieldIds.length > 0) {
        //   for (let i = 0; i < vm.targetFieldIds.length; i++) {
        //     const loopFieldId = vm.targetFieldIds[i]
        //     if (!notSelectedFieldIds.includes(vm.targetFieldIds[i])) {
        //       const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopFieldId)
        //       if (fieldInfo) {
        //         result.push(fieldInfo)
        //       }
        //     }
        //   }
        // }
        return result
      },
      allowedFieldIds () {
        const vm = this
        return vm.allowedFieldInfos.map(info => info.fieldId)
      },

      sourceFieldType () {
        return this.sourceFieldInfo.type
      },

      selectableLocalFieldInfos () {

      },
      selectedItems: {
        get () {
          const vm = this
          var result = []
          for (let i = 0; i < vm.targetFieldIds.length; i++) {
            const loopFieldId = vm.targetFieldIds[i]
            if (vm.allowedFieldIds.includes(loopFieldId)) {
              result.push(loopFieldId)
            }
          }
          return result
        },
        set (val) {
          const vm = this
          const updatedVal = JSON.parse(JSON.stringify(val))
          vm.$emit('input', updatedVal)
        }
      }
    }
  }
</script>

<style>
.copy-field-row .v-input.v-select .v-select__slot .v-chip {
  margin-bottom: 1px;
  margin-left: 0 !important;
}

.copy-field-row .v-input.v-select .v-select__slot {
  padding: 5px 0 !important;
}

.copy-field-row .v-input.v-select .v-select__slot .v-select__selections > input {
  padding: 2px 0;
}
</style>
