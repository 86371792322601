<template>
    <div>
        <WorkSheetSelect
            :worksheet="setting.worksheet"
            :placeholder="$t('workflow.modules.date.placeholder.selectWorksheet')"
        />
        <div v-if="selectedWorkSheet">
            <FormElement name="timer.targetField" :label="$t('workflow.modules.date.label.selectDateField')">
                <ComboBox 
                    :options="formFields" 
                    :value="setting.timer['targetField'] || null" 
                    @changed="selectDateTimeField"
                    :placeholder="$t('workflow.modules.date.placeholder.selectField')"
                />
            </FormElement>
            <Reminder :value="setting.timer" v-if="setting.timer.targetField" labelType="date" :showTimeSelector="showTimeSelector" />
        </div>
    </div>
</template>

<script>
    import mixin from './../mixin'
    import WorkSheetSelect from './../shared/worksheetSelect'
    import FormElement from './../shared/formElement'
    import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
    import Reminder from './reminder'
    export default {
        mixins: [ mixin ], 
        components: { WorkSheetSelect, Reminder, ComboBox, FormElement }, 
        computed: {
            selectedWorkSheet() {
                return this.setting.worksheet;
            },
            formFields() {
                const { children: fields } = this.$store.getters.getFormSelectionsFields;
                let result = [];
                if ( fields && fields.length > 0  ) {
                    fields.filter( _field => _field.fieldType === 'date' ).forEach( _field => result.push( {
                        label: _field.label,
                        id: _field._id,
                        fieldType: _field.properties.fieldType
                    } ))
                }
                return result;
            },
            showTimeSelector() {
                let result = false;
                try {
                    result = this.formFields.find( _field => _field.id === this.setting.timer.targetField ).fieldType === 'date';   
                } catch (error) {
                    console.warn( 'Field not found')
                }
                return result;
            }
        },
        methods: {
           selectDateTimeField( val ) {
               this.$set( this.setting.timer, 'targetField', val );
               this.$set( this.setting.timer, 'mode', 'exact' );
               if ( this.showTimeSelector ) {
                   this.$set( this.setting.timer, 'startTime', '00:00')
               } else {
                   this.$delete( this.setting.timer, 'startTime')
               }
           }
        },
        mounted() {
            if ( !('timer' in this.setting) ) this.setting.timer = {};
        }
    }
</script>