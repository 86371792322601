<template>
  <div class="click-input" style="height: 24px; margin-top: -4px !important;">
    <v-text-field ref="input"
                  :value="value"
                  @click="edit"
                  @blur="editing=false"
                  :class="{'input-editing': editing}"
                  solo
                  text
                  :readonly="!editing"
                  class="py-0"
                  :hide-details="true"
                  dense
                  style="background-color:transparent;"
                  outlined
                  @input="val=>updateValue(val)"></v-text-field>
    <!--<div v-else @click="edit">{{ value }}</div>-->
  </div>
</template>

<script>
export default {
  data () {
    return {
      editing: false
    }
  },
  methods: {
    edit () {
      const vm = this
      vm.editing = true
      vm.$refs.input.$el.querySelector('input').select()
      // setTimeout(()=>{
      //   vm.$refs.input.$el.querySelector('input').select()
      // },200)
    },
    updateValue (val) {
      const vm = this
      vm.$emit('input', val)
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: String
  }
}
</script>

<style>
  .click-input .v-input .v-input__control > .v-input__slot > .v-text-field__slot input {
    font-size: 18px;
  }

  .click-input .v-input .v-input__control > .v-input__slot {
    min-height:32px;
  }

  .click-input .v-input.input-editing .v-input__slot fieldset {
    border-color: white !important;
  }

  .click-input .v-input:not(.input-editing) .v-input__slot fieldset {
    border-color: transparent !important;
  }

  .click-input .v-input:not(.input-editing) .v-input__slot .v-text-field-slot input {
    padding: 4px 0 4px;
  }

  .click-input .v-input.input-editing .v-input__slot {
    background-color: transparent !important;
    box-shadow: 0px 3px 1px -2px transparent, 0px 2px 2px 0px transparent, 0px 1px 5px 0px transparent !important;
  }
  .click-input .v-input:not(.input-editing) .v-input__slot {
    background-color: transparent !important;
    box-shadow: 0px 3px 1px -2px transparent, 0px 2px 2px 0px transparent, 0px 1px 5px 0px transparent !important;
  }

  .click-input .v-input:not(.input-editing) {
    border-color: transparent !important;
    background-color: transparent !important;
  }
</style>
