<template>
  <div
    style="position: relative;"
    class="layout-widget"
    @mouseenter="showingWidthSettingBar = true"
    @mouseleave="showingWidthSettingBar = false"
  >
    <slot name="content">
    <component
      v-if="inputWidget && fieldInfo"
      v-bind:is="inputWidget"
      :fieldInfo="fieldInfo"
      :alias="alias"
      :customDescription="customDescription"
      :isLayout="true"
    ></component>
    </slot>
    <div class="widget-mask"></div>
    <div v-if="hasAdjustableWidth && showingWidthSettingBar"
         class="width-button-wrapper w-100 widget-mask-over d-flex flex-row align-center justify-center"
         style="cursor:pointer;position:absolute;right:0;bottom:0;left:0;text-align:center;">
      <layout-widget-width-button
          v-for="widthButton in WIDTH_BUTTONS"
          :key="widthButton.width"
          :selected="widthButton.width===width"
          @click="setWidth(widthButton.param)"
          v-html="widthButton.label"></layout-widget-width-button>
    </div>
      <div class="widget-toolbuttons widget-mask-over" style="position:absolute;right:5px;top:0;">
          <slot name="controlButtons"></slot>
      </div>
  </div>
</template>
<script>
import inputWidgets from "@/components/inputWidgets";
import layoutWidgetWidthButton from '../formBuilder/LayoutWidgetWidthButton'

export default {
  components: {
    ...inputWidgets,
    layoutWidgetWidthButton
  },
  props: {
    remainingWidth: {
      type: Number,
      default: 12,
    },

    idWidthArray: {
      type: Array,
      default() {
        return [];
      },
    },
    fieldInfo: {
      type: Object,
    },
    item: {
        type:Object
    },
    alias: {
      type: String
    },
    customDescription: {
      type:String
    }
  },
  data() {
    return {
      showingWidthSettingBar: false,
      WIDTH_BUTTONS: [
        { width: 12, param: 0, label: "&harr;" },
        { width: 9, param: 9, label: "3/4" },
        { width: 8, param: 8, label: "2/3" },
        { width: 6, param: 6, label: "1/2" },
        { width: 4, param: 4, label: "1/3" },
        { width: 3, param: 3, label: "1/4" },
        { width: 2, param: 2, label: "1/6" },
      ],
    };
  },
  computed: {
    inputWidget() {
        if (this.fieldInfo){
            return 'inputWidget' + this.ucFirst(this.fieldInfo.type.replace('_', ''))
        }else return ''
    },
    width () {
      return this.item.width
    },
    hasAdjustableWidth () {
      return this.fieldInfo ? this.fieldInfo.properties.hasOwnProperty('width'): false
    },
  },
  methods: {
    ucFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    setWidth(width) {
        let parsedWidth = width;

        if (parsedWidth === 0 || parsedWidth - this.width > this.remainingWidth) {
            parsedWidth = this.width + this.remainingWidth
        }

        this.$emit('commandHandler', {
            command: 'setWidth',
            fieldId: this.item._id,
            width: parsedWidth
        })
    }
  },
};
</script>
<style scoped></style>
