<template>
  <div>    
    <div class="workflow-setting-form-each-item">
      <DataSourceSelect
        :value="setting.dataSource"
        :label="$t(`workflow.modules.subWorkflow.label.dataSource`)"
        name="dataSource"
        shouldShowOutputNumber
        :boxProps="{ prefixIcon: true, isBranch: true  }"
        :placeholder="$t('workflow.modules.subWorkflow.label.dataSource')"
        :hints="$t('workflow.modules.subWorkflow.remark.dataSource')"
      />
    </div>
    <div v-if="!!setting.dataSource">
      <div class="workflow-setting-form-each-item">
        <ExecutionModeOptions :executionMode="setting.executionMode" />
      </div>
      <div class="workflow-setting-form-each-item">
        <SubWorkflowSelect
          :workflow="workflow"
          :appId="appId"
          :value="setting.subWorkflow"
          :label="$t(`workflow.modules.subWorkflow.label.executeSub`)"
          name="subWorkflow"
          :placeholder="$t('workflow.modules.subWorkflow.label.selectSub')"
          :hints="$t('workflow.modules.subWorkflow.remark.selectSub')"
        />
          <FormElement name="setting.loopMaximum" 
            :hints="$t(`workflow.modules.subWorkflow.remark.limit`)" :label="$t(`workflow.modules.subWorkflow.label.limit`)">
            
            <v-layout row align-center style="margin: 0">
              <div style="width: 100px; margin-right: 8px">
                <v-text-field type="number" max="100" outlined dense v-model="setting.loopMaximum" width="100" hide-details=""></v-text-field>
              </div>
              <b>{{$t(`workflow.modules.subWorkflow.label.times`)}}</b>
            </v-layout>
          </FormElement>    
        <AsyncSkipSetting :value="setting.proceedCondition" />
      </div>
      <div v-if="setting.subWorkflow" class="workflow-setting-form-each-item">
        <VariableSetting
          :variable="setting.data"
          :targetWorkflow="setting.subWorkflow"
          :worksheet="setting.worksheet"
          :dataSource="setting.dataSource"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { get } from 'lodash';
import FormElement from './../shared/formElement';
import DataSourceSelect from './../shared/dataSourceSelect';
import ExecutionModeOptions from './executionMode';
import VariableSetting from './variable';
import AsyncSkipSetting from './skip';
import SubWorkflowSelect from './../shared/subWorkflowSelect';

// import ParameterFieldsInput from './../../../parameter';
import Mixin from './../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    DataSourceSelect,
    ExecutionModeOptions,
    FormElement,
    AsyncSkipSetting,
    SubWorkflowSelect,
    VariableSetting,
  },
  data() {
    return {
      loopMaximum: this.setting ? this.setting.loopMaximum || 10 : 10,
    }
  },
  computed: {
    dataSource() {
      return this.setting.dataSource;
    },
  },
  mounted() {
    // if (isNaN(this.setting.loopMaximum)) {
    //   this.setting.loopMaximum = 10;
    // }
  },
  method: {
    validateLoopMaximumTimes(v) {
      if (!v || isNaN(v)) {
        return 10;
      } else if (v) {
        const executionTimes = parseInt(v);
        if (executionTimes <= 0 || executionTimes >= 100) {
          return 10;
        } else {
          return executionTimes;
        }
      }
      return 10;
    },
    // validation() {
    //   const { setting } = this;
    //   setting.loopMaximum = this.validateLoopMaximumTimes(setting.loopMaximum);
    //   return setting;
    // },
  },
  watch: {
    // 'setting.loopMaximum': {
    //   handler(v) {
    //     console.log(this);
    //     this.setting.loopMaximum = this.validateLoopMaximumTimes(v);
    //   },
    //   deep: true,
    // },
  }
};

</script>

<style scoped>
</style>
