<template>
  <v-dialog scrollable width="540" v-model="showDialog">
    <v-card>
      <DialogHeader @close="showDialog = false" :label="$t('general.locale')" />
      <v-card-title>
        <v-list three-line subheader width="100%">
          <v-subheader>{{ $t("general.general") }}</v-subheader>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <label>{{ $t("general.locale") }}</label>
              <v-select
                hide-details
                dense
                outlined
                :items="locales"
                v-model="locale"
                :menu-props="{offsetY: true, contentClass: 'denseList'}"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-title>
      <DialogActions @onCommand="onCommandHandler" />
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";
import locales from "@/const/locales"

export default {
  components: {
    DialogHeader,
    DialogActions,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      locales: locales,
      locale: "",
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentLocale() {
      return this.$store.getters.locale;
    },
  },
  mounted() {
    this.locale = this.currentLocale;
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "cancel":
          this.showDialog = false;
          break;
        case "ok":
          this.$emit("submit", this.locale);
          this.showDialog = false;
          break;
      }
    },
  },
};
</script>
