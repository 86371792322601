<template>
  <vxe-table-column 
    v-bind="colDef" 
    class-name="fullCell" 
    :edit-render="{ name: 'input', enabled: !readonly }"
  >
    <template v-slot="{ row }">
      <vxe-cell-attachment-images
        :colDef="colDef"
        :editable="editable"
        :value="row[fieldId]"
      ></vxe-cell-attachment-images>
      <quick-action v-if="quickInsert" :rowId="row['_id']" :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{ row }">
      <div
        class="d-flex justify-end align-center vxeCellAttachment"
        style="height:100%; width:100%;"
      >
        <vxe-cell-attachment-images
          :colDef="colDef"
          :editable="editable"
          :value="row[fieldId]"
          :minAttachmentCount="minAttachmentCount"
          :maxAttachmentCount="maxAttachmentCount"
          @click:add="addImage(row, fieldId)"
          @click:delete="(mediaId) => deleteImage(row, fieldId, mediaId)"
          :mode="readonly || !row.canEdit ? 'view' : 'edit'"
        ></vxe-cell-attachment-images>
      </div>
    </template>

    <template v-slot:footer="{ column }">
      <summary-cell
        :decimals="decimals"
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"
      ></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import vxeCellAttachmentImages from "./comps/VxeCellAttachmentImages";
import MediaHelper from "@/helpers/MediaHelper";
import fileTypeExtensions from "@/const/fileTypeExtensions";
import quickAction from "./comps/QuickAction";
import summaryCell from "./comps/SummaryCell";

export default {
  mixins: [mixin],
  components: {
    vxeCellAttachmentImages,
    quickAction,
    summaryCell,
  },
  computed: {
    minAttachmentCount() {
      return this.getPropertyValue("minAttachmentCount", 1);
    },
    maxAttachmentCount() {
      return this.getPropertyValue("maxAttachmentCount", 1);
    },
    fileTypes() {
      var fileType = this.getPropertyValue("fileType", "").split("||");

      var acceptExt = [];

      fileType.forEach((item) => {
        acceptExt = acceptExt.concat(this.fileTypeExtensions[item].exts);
      });
      // if(acceptExt.length == 1 && acceptExt[0] == "") acceptExt = []
      return acceptExt;
    },
  },
  data() {
    return {
      row: null,
      fileTypeExtensions: fileTypeExtensions,
      sizeExceed: false,
      uploading: false,
    };
  },
  methods: {
    addImage(row, fieldId) {
      const vm = this;
      this.row = row;
      this.fieldId = fieldId;
      this.$emit("onCommand", {
        command: "selectUploadFile",
        opts: { types: vm.fileTypes },
        callback: vm.onFileSelected,
      });
    },
    onFileSelected(files) {
      this.sizeExceed = false;
      const vm = this;
      // const files = params.target.files;

      console.log('onFileSelected: isArray = ' + (Array.isArray(files)));
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 20 * Math.pow(1024, 2)) {
          this.sizeExceed = true;
          this.$XModal.message({
            status: "error",
            message: this.$t("messages.fileSizeExceed"),
          });
          break;
        }
      }

      if (!this.sizeExceed) {
        const payload = {
          appId: vm.currentApp._id,
          formId: vm.formInfo._id,
          files,
          isPublic: false
        }
        MediaHelper.uploadFiles(payload, (res) => {
          vm.uploading = false;
          if (res.result === false) {
            this.storageExceed = true
          } else {
            const mediaIds = res.map(media => media._id);
            const mediaTypes = res.map(media => media.mediaType);
            const objectKeys = res.map(media => media.objectKey);
            const originalNames = res.map(media => media.originalName);
            const thumbnailUrls = res.map(media => media.thumbnailUrl);

            var arr = [];
            for (var i = 0; i < mediaIds.length; i++) {
              var obj = {
                mediaId: mediaIds[i],
                mediaType: mediaTypes[i],
                objectKey: objectKeys[i],
                originalName: originalNames[i],
                thumbnailUrl: thumbnailUrls[i]
              };
              arr.push(obj);
            }

            if (!Array.isArray(vm.row[vm.fieldId])) vm.row[vm.fieldId] = [];
            vm.row[vm.fieldId] = vm.row[vm.fieldId].concat(arr);
          }
        });
      }
    },

    onFileSelected_old(params) {
      this.sizeExceed = false;
      const vm = this;
      const files = params.target.files;
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 20 * Math.pow(1024, 2)) {
          this.sizeExceed = true;
          this.$XModal.message({
            status: "error",
            message: this.$t("messages.fileSizeExceed"),
          });
          break;
        }
      }

      if (!this.sizeExceed) {
        const payload = {
          appId: vm.currentApp._id,
          formId: vm.formInfo._id,
          files,
          isPublic: false
        }
        MediaHelper.uploadFiles(payload, (res) => {
          if (res.result === false) {
            this.$XModal.message({
              status: "error",
              message: this.$t("messages.totalStorageExceed"),
            });
          } else {
            const mediaIds = res.map((item) => item._id);
            const mediaTypes = res.map((item) => item.mediaType);
            const originalNames = res.map((item) => item.originalName);

            var arr = [];
            for (var i = 0; i < mediaIds.length; i++) {
              var obj = {
                mediaId: mediaIds[i],
                mediaType: mediaTypes[i],
                originalName: originalNames[i],
              };
              arr.push(obj);
            }

            if (!Array.isArray(vm.row[vm.fieldId])) vm.row[vm.fieldId] = [];
            vm.row[vm.fieldId] = vm.row[vm.fieldId].concat(arr);
          }
        });
      }
    },

    deleteImage(row, fieldId, mediaId) {
      console.log("deleteImage :: fieldId = " + fieldId);
      console.log("deleteImage :: mediaId = " + mediaId);
      console.log("deleteImage :: row: ", row);
      row[fieldId] = row[fieldId].filter((item) => item.mediaId !== mediaId);
      console.log("deleteImage :: row: ", row);
    },
  },
};
</script>

<style>
.vxeCellAttachment {
  border: 1px solid #dcdfe6 !important;
  background-color: white;
}
</style>
