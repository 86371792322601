<template>
  <div>
    <SettingHeader :label="$t('workflow.modules.timer.label.title')" :node="node" />
    <div class="workflow-setting-form-each-item">
      <FormElement name="executionDate" :label="$t('workflow.modules.timer.label.startDate')">
        <DialogFieldDateTime v-model="timer.executionDate" />
      </FormElement>
      <FormElement name="repeatMode" :label="$t('workflow.modules.timer.label.mode')">
        <ComboBox
          :value="timer.repeatMode || null"
          :options="repeatList"
          :placeholder="$t('workflow.modules.timer.placeholder.select')"
          @changed="v => changed('repeatMode', v)"
        />
      </FormElement>
    </div>
    <div v-if="timer.repeatMode === 'custom'" class="workflow-setting-form-each-item">
      <FormElement name="hourly" :label="$t('workflow.modules.timer.label.hour')">
        <ComboBox
          :value="timer.hourly || null"
          :options="hourlyPatternList"
          :placeholder="$t('workflow.modules.timer.placeholder.select')"
          @changed="v => changed('hourly', v)"
        />
      </FormElement>
      <v-layout class="w-timer-subRow" v-if="timer.hourly === 'range'" justify-space-between align-center>
        <VueNumberInput
          v-model="timer.hourFrom"
          class="workflow-setting-form-numeric-input"
          :min="1"
          :max="23"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter"> - </span>
        <VueNumberInput
          v-model="timer.hourTo"
          class="workflow-setting-form-numeric-input"
          :min="1"
          readonly
          inputClass="w-numeric-input"
          :max="23"
        ></VueNumberInput>
      </v-layout>
      <ComboBox
        v-if="timer.hourly === 'fixed'"
        isMultiple
        class="w-timer-subRow"
        :value="timer.hourFixed || null"
        :options="hourList"
        :placeholder="$t('workflow.modules.timer.placeholder.select')"
        @changed="v => changed('hourFixed', v)"
      />
      <v-layout class="w-timer-subRow" v-if="timer.hourly === 'interval'" justify-space-between align-center>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.fromThe')}}
        </span>
        <VueNumberInput
          v-model="timer.hourFrom"
          class="workflow-setting-form-numeric-input"
          :min="1"
          :max="23"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.hourStart')}}
        </span>
        <VueNumberInput
          v-model="timer.hourTo"
          class="workflow-setting-form-numeric-input"
          :min="1"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.hour')}}
        </span>
      </v-layout>

      <FormElement name="dayWeekTrigger" :label="$t('workflow.modules.timer.label.dayWeek')">
        <v-radio-group
          v-model="timer.dayWeekTrigger"
          row
          hide-details
        >
          <v-radio
            :label="$t('workflow.modules.timer.label.day')"
            value="day"
          ></v-radio>
          <v-radio
            :label="$t('workflow.modules.timer.label.week')"
            value="week"
          ></v-radio>
        </v-radio-group>
      </FormElement>
      <FormElement name="dayWeek" v-if="timer.dayWeekTrigger !== 'week'" :label="$t('workflow.modules.timer.label.day')">
        <ComboBox
          :value="timer.dayWeek || null"
          :options="dayWeekPatternList"
          :placeholder="$t('workflow.modules.timer.placeholder.select')"
          @changed="v => changed('dayWeek', v)"
        />
      </FormElement>
      <ComboBox
        v-if="timer.dayWeekTrigger === 'week'"
        isMultiple
        class="w-timer-subRow"
        :value="timer.dayWeekFixed || null"
        :options="weekList"
        :placeholder="$t('workflow.modules.timer.placeholder.select')"
        @changed="v => changed('dayWeekFixed', v)"
      />

      <v-layout class="w-timer-subRow" v-if="timer.dayWeek === 'range'" justify-space-between align-center>
        <VueNumberInput
          v-model="timer.dayWeekFrom"
          class="workflow-setting-form-numeric-input"
          :min="1"
          :max="31"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter"> - </span>
        <VueNumberInput
          v-model="timer.dayWeekTo"
          class="workflow-setting-form-numeric-input"
          :min="1"
          readonly
          inputClass="w-numeric-input"
          :max="31"
        ></VueNumberInput>
      </v-layout>
      <ComboBox
        v-if="timer.dayWeek === 'fixed'"
        isMultiple
        class="w-timer-subRow"
        :value="timer.dayWeekFixed || null"
        :options="dayList"
        :placeholder="$t('workflow.modules.timer.placeholder.select')"
        @changed="v => changed('dayWeekFixed', v)"
      />
      <v-layout class="w-timer-subRow" v-if="timer.dayWeek === 'interval'" justify-space-between align-center>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.fromThe')}}
        </span>
        <VueNumberInput
          v-model="timer.dayWeekFrom"
          class="workflow-setting-form-numeric-input"
          :min="1"
          :max="31"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.dayStart')}}
        </span>
        <VueNumberInput
          v-model="timer.dayWeekTo"
          class="workflow-setting-form-numeric-input"
          :min="1"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.day')}}
        </span>
      </v-layout>





      <FormElement name="monthly" :label="$t('workflow.modules.timer.label.month')">
        <ComboBox
          :value="timer.monthly || null"
          :options="monthlyPatternList"
          :placeholder="$t('workflow.modules.timer.placeholder.select')"
          @changed="v => changed('monthly', v)"
        />
      </FormElement>
      <v-layout class="w-timer-subRow" v-if="timer.monthly === 'range'" justify-space-between align-center>
        <VueNumberInput
          v-model="timer.monthFrom"
          class="workflow-setting-form-numeric-input"
          :min="1"
          :max="12"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter"> - </span>
        <VueNumberInput
          v-model="timer.monthTo"
          class="workflow-setting-form-numeric-input"
          :min="1"
          readonly
          inputClass="w-numeric-input"
          :max="12"
        ></VueNumberInput>
      </v-layout>
      <ComboBox
        v-if="timer.monthly === 'fixed'"
        isMultiple
        class="w-timer-subRow"
        :value="timer.monthFixed || null"
        :options="monthList"
        :placeholder="$t('workflow.modules.timer.placeholder.select')"
        @changed="v => changed('monthFixed', v)"
      />
      <v-layout class="w-timer-subRow" v-if="timer.monthly === 'interval'" justify-space-between align-center>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.fromThe')}}
        </span>
        <VueNumberInput
          v-model="timer.monthFrom"
          class="workflow-setting-form-numeric-input"
          :min="1"
          :max="12"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.monthStart')}}
        </span>
        <VueNumberInput
          v-model="timer.monthTo"
          class="workflow-setting-form-numeric-input"
          :min="1"
          readonly
          inputClass="w-numeric-input"
        ></VueNumberInput>
        <span class="w-delimiter">
          {{$t('workflow.modules.timer.label.month')}}
        </span>
      </v-layout>
    </div>
    <v-btn @click.stop="getNextDate" color="secondary" small>
      {{$t('workflow.modules.timer.label.generate')}}
    </v-btn>
    <v-simple-table dense :style="{
      margin: '10px 0',
      border: '1px solid #ccc',
    }" v-if="mockList && mockList.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              {{$t('workflow.modules.timer.label.number')}}
            </th>
            <th class="text-center">
              {{$t('workflow.modules.timer.label.triggerDate')}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in mockList"
            :key="item.row"
          >
            <td>{{ item.row }}</td>
            <td>{{ item.date }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

import VueNumberInput from '@smartweb/vue-number-input';
import { map, times, forEach, uniq } from 'lodash';
import SettingHeader from './../shared/header';
import Mixin from './../mixin';
import ComboBox from './../../../form/comboBox/';
import FormElement from './../shared/formElement';
import DialogFieldDateTime from '@/components/dialogFields/DialogFieldDateTime'

// import CronParser from 'cron-parser';
import CronParser from "cron-parser";
import CronBuilder from "cron-builder";
import moment from 'moment';

/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  data() {
    // const { timer={} } = this.setting;
    return {
      timezone: 'Asia/Hong_Kong',
      showing: true,
      timer: {},
      mockList: [],
      repeatList: this.constructList(
        this.$t('workflow.modules.timer.label.pattern')
      ),
      hourlyPatternList: this.constructList(
        this.$t('workflow.modules.timer.label.hourPattern')
      ),
      monthlyPatternList: this.constructList(
        this.$t('workflow.modules.timer.label.monthPattern')
      ),
      dayWeekPatternList: this.constructList(
        this.$t('workflow.modules.timer.label.dayPattern')
      ),
      hourList: this.constrcutRangeList(0, 23),
      monthList: this.constrcutRangeList(1, 12),
      dayList: this.constrcutRangeList(1, 31),
      weekList: this.constructList(
        this.$t('workflow.modules.timer.label.weekText')
      ),
    };
  },
  methods: {
    constrcutRangeList(from, to) {
      return times(to - from + 1, num => ({
        id: String(from + num),
        value: String(from + num),
        label: from + num,
      }));
    },
    constructList(list) {
      return map(list, (label, value) => ({
        id: value,
        value,
        label,
      }));
    },
    changed(path, value) {
      this.$set(this.timer, path, value);
      this.timer[path] = value;
    },
    withWild(cronExp, considerDayWeek) {
      if (cronExp[2] === '*' && cronExp[4] === '*') {
        cronExp[4] = '?';
      } else if (cronExp[2] !== '*' && cronExp[4] === '*') {
        cronExp[4] = '?';
      } else if (cronExp[2] === '*' && cronExp[4] !== '*') {
        cronExp[2] = '?';
      } else if (cronExp[2] !== '*' && cronExp[4] !== '*') {
        if (considerDayWeek) {
          cronExp[2] = '?';
        } else {
          cronExp[4] = '?';
        }
      }
    },
    getNextDate() {
      const {
        setting: {
          timer={},
        },
        timezone,
      } = this;
      const { executionDate } = timer;
      if (executionDate) {
        const currentDate = this.$moment().tz(timezone);
        let _executionDate = this.$moment.tz(executionDate, timezone);
  
        if (_executionDate.isBefore(currentDate)) {
          _executionDate = currentDate;
        }
        this.mockList = [];
    
        const cronParser = CronParser.parseExpression(this.cronExpression().join(' '), {
          currentDate: _executionDate.toDate(),
          iterator: true
        });
        let occurrence;
        if (_executionDate.isBefore(currentDate)) {
          occurrence = { value: _executionDate.toDate() };
        } else {
          occurrence = cronParser.next();
        }

        // console.log(occurrence);
        // else {
        //   occurrence = CronParser.next(cronParser, 
        //     _executionDate.toDate()
        //   );
        // }
        // parseExpression(expression, moment(executionDate).toDate());
        times(7, i => {
          this.mockList.push({
            date: this.$moment(occurrence.value.toString()).format('LLLL'),
            row: i + 1,
          });
          occurrence = cronParser.next();
        });
      }
    },
    validation() {
      let { setting } = this;
      const expr = this.cronExpression().join(' ');
      setting.timer.cronUtcExpression = expr;
      // this.utcExpression.join(' ');
      setting.timer.cronExpression = expr;
      setting.timer.triggerCronExpression = expr;
      // this.triggerCronExpression;
      return setting;
    },
    cronExpression() {
      
      const {
        setting: {
          timer={},
        },
        timezone,
      } = this;
      const {
        executionDate, 
        repeatMode,
        hourly,
        dayWeek,
        monthly,
        hourFixed=[],
        monthFixed=[],
        dayWeekFixed=[],
      } = timer;
      let {
        hourFrom,
        hourTo,
        dayWeekTrigger,
        dayWeekFrom,
        dayWeekTo,
        monthFrom,
        monthTo,
      } = timer;
      // const cronExp = new CronBuilder();
      const cronExp = [
        '*', '*', '*', '*', '*'
      ];
      const executionDateMoment = this.$moment.tz(executionDate, timezone);
      // console.log('>>>>> ', executionDateMoment.format('LLLL'));
      const regularTemplate = {
        hourly: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = '*/1';
          // cronExp[2] = '*';
          // cronExp[3] = '*';
          // cronExp[4] = '*';
        },
        daily: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = executionDateMoment.hours().toString();
          // cronExp[2] = '*';
          // cronExp[3] = '*';
          // cronExp[4] = '*';
        },
        workHour: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = executionDateMoment.hours().toString();
          // cronExp[2] = '*';
          // cronExp[3] = '*';
          cronExp[4] = '1-5';
        },
        workend: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = executionDateMoment.hours().toString();
          // cronExp[2] = '*';
          // cronExp[3] = '*';
          cronExp[4] = '6,7';
        },
        weekly: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = executionDateMoment.hours().toString();
          // cronExp[2] = '*';
          // cronExp[3] = '*';
          cronExp[4] = '7';
        },
        monthly: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = executionDateMoment.hours().toString();
          cronExp[2] = executionDateMoment.days().toString();
          // cronExp[3] = '*';
          // cronExp[4] = '*';
        },
        yearly: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = executionDateMoment.hours().toString();
          cronExp[2] = executionDateMoment.days().toString();
          cronExp[3] = executionDateMoment.months().toString();
          // cronExp[4] = '*';
        },
        quarterly: () => {
          cronExp[0] = executionDateMoment.minutes().toString();
          cronExp[1] = executionDateMoment.hours().toString();
          cronExp[2] = executionDateMoment.days().toString();
          cronExp[3] = '*/3';
          // cronExp[4] = '*';
        },
      };
      // execute the regular template expression
      if (!!regularTemplate[repeatMode]) {
        regularTemplate[repeatMode]();
      } else if (repeatMode === 'custom') {
        // regular mode for the template (e.g. every hour, every month, every year)
        if (!!regularTemplate[monthly]) {
          regularTemplate[monthly]();
        } else if (monthly === 'range'){
          if (!isNaN(monthFrom) && !isNaN(monthTo)) {
            cronExp[3] = `${monthFrom}-${monthTo}`;
          }
        } else if (monthly === 'fixed'){
          if (!!monthFixed.length) {
            cronExp[3] = monthFixed.join(',');
          }
        } else if (monthly === 'interval'){
          if (!isNaN(monthFrom) && !isNaN(monthTo)
            && monthTo !== 0
          ) {
            // trun to minute
            cronExp[3] = `${monthFrom}-12/${monthTo}`;
          }
        } else if (monthly === 'endOfLast'){
            cronExp[2] = '31';
            cronExp[3] = '12';
        }
        // day of week cron expression
        if (dayWeekTrigger === 'week') {
          cronExp[4] = dayWeekFixed.join(',');
        } else {
          if (!!regularTemplate[dayWeek]) {
            regularTemplate[dayWeek]();
          } else if (dayWeek === 'range'){
            if (!isNaN(dayWeekFrom) && !isNaN(dayWeekTo)) {
              cronExp[2] = `${dayWeekFrom}-${dayWeekTo}`;
            }
          } else if (dayWeek === 'fixed'){
            if (!!dayWeekFixed.length) {
              cronExp[2] = dayWeekFixed.join(',');
            }
          } else if (dayWeek === 'interval'){
            if (!isNaN(dayWeekFrom) && !isNaN(dayWeekTo)
              && dayWeekTo !== 0
            ) {
              // trun to minute
              cronExp[2] = `${dayWeekFrom}-31/${dayWeekTo}`;
            }
          } else if (dayWeek === 'endOfLast'){
              cronExp[2] = 'L';
          }
        }

        if (!!regularTemplate[hourly]) {
          regularTemplate[hourly]();
        } else if (hourly === 'range'){
          if (!isNaN(hourFrom) && !isNaN(hourTo)) {
            cronExp[1] = `${hourFrom}-${hourTo}`;
          }
        } else if (hourly === 'fixed'){
          if (!!hourFixed.length) {
            cronExp[1] = hourFixed.join(',');
          }
        } else if (hourly === 'interval'){
          if (!isNaN(hourFrom) && !isNaN(hourTo)
            && hourTo !== 0
          ) {
            // trun to minute
            cronExp[1] = `${hourFrom}-23/${hourTo}`;
          }
        } else if (hourly === 'endOfLast'){
          cronExp[1] = '23';
          cronExp[0] = '59';
        }
      }

      // const considerDayWeek = dayWeekFixed && !!dayWeekFixed.length;
      // this.withWild(cronExp, considerDayWeek);
      // hours = head(hours);
      return cronExp;
    },
  },
  mounted() {
    if (!this.setting.timer) {
      this.setting.timer = {};
      this.$set(this.setting, 'timer', {});
    }
    const { timer={} } = this.setting;
    this.timer = timer;
    this.getNextDate();

    // console.log(this.$moment().tz(this.timezone).utc().format('LLLL'));
  },
  computed: {
    // triggerCronExpression() {
    //   const {
    //     setting: {
    //       timer={},
    //     },
    //   } = this;
    //   const { executionDate } = timer;
    //   // for aws align to utc+8
    //   // const _executionDate = moment(executionDate).utc();
    //   const awsCronParser = CronParser.parse( this.cronExpression().join(' ') );
    //   let occurrence = CronParser.next( awsCronParser, this.$moment().toDate());
    //   const mDate = this.$moment(occurrence).subtract( 1, 'minutes').utc();
    //   const cronExp = [
    //     String(mDate.minutes()), 
    //     String(mDate.hour()),
    //     String(mDate.date()),
    //     String(mDate.month() + 1),
    //     '?',
    //     String(mDate.year()),
    //   ];

    //   return cronExp.join(' ');
    // },

    // utcExpression() {
    //   const {
    //     setting: {
    //       timer={},
    //     },
    //   } = this;
    //   const { repeatMode } = timer;
    //   const cronExp = this.cronExpression();
    //   // regular mode, directly return cron expression in utc format
    //   if (repeatMode !== 'custom') {
    //     return cronExp;
    //   }
    //   const awsCronParser = CronParser.parse(cronExp.join(' '));
    //   const { hours=[], months=[], daysOfMonth=[] } = awsCronParser;
    //   const nHours = [ ...hours ];
    //   const nDayOfMonth = [ ...daysOfMonth ];
    //   const nMonth = [ ...months ];
    //   let hourLayback = false;
    //   let dayLayback = false;
    //   forEach(hours, (h, hi) => {
    //     let currentHour = h - 8;
    //     if (currentHour < 0) {
    //       hourLayback = true;
    //       currentHour += 24;
    //     }
    //     nHours[hi] = currentHour;
    //   });
    //   if (hourLayback) {
    //     forEach(daysOfMonth, (dm, dmi) => {
    //       let currentDayMonth = dm - 1;
    //       // layback to previous month
    //       if (currentDayMonth <= 0) {
    //         nDayOfMonth[dmi] = 28;
    //         nDayOfMonth.push(30);
    //         nDayOfMonth.push(31);
    //         dayLayback = true;
    //       } else {
    //         nDayOfMonth[dmi] = currentDayMonth;
    //       }
    //     });
    //     if (dayLayback) {
    //       forEach(months, (m, mi) => {
    //         let currentMonth = m - 1;
    //         // layback to previous month
    //         if (currentMonth <= 0) {
    //           nMonth[mi] = 12;
    //         } else {
    //           nMonth[mi] = currentMonth;
    //         }
    //       });
    //     }
    //   }
    //   cronExp[1] = uniq(nHours).join(',');
    //   cronExp[2] = uniq(nDayOfMonth).join(',');
    //   cronExp[3] = uniq(nMonth).join(',');
    //   return cronExp;
    // },
    list() {
      return this.$store.getters.getFormSelectionsFields.children;
    },
    loading() {
      return this.$store.getters.fetchingFormSelectionsFields;
    },
    worksheet() {
      return this.setting.worksheet;
    },
    worksheetInfo() {
      const { worksheet } = this.setting;
      if (worksheet && !worksheet.id) {
        return { id: worksheet };
      }
      return worksheet;
    },
  },
  components: {
    SettingHeader,
    DialogFieldDateTime,
    FormElement,
    ComboBox,
    VueNumberInput,
  },
  watch: {
    timer: {
      deep: true,
      handler (v) {
        this.setting.timer = v;
        this.$set(this.setting, 'timer', v);
      },
    },
  }
};

</script>

<style scoped>
  .w-delimiter {
    margin: 0 10px;
    
  }
  .w-numeric-input {
    width: 100%;
  }
  .w-timer-subRow {
    margin: 5px 0;
    width: fit-content;
  }
</style>
