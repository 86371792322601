import {app} from '@/main'
import {inputPanels as mutations} from "../../mutation-types"
import {inputPanels as types} from "../../action-types"

export default {
  [types.FETCH_INPUT_PANEL_DATA]({dispatch}, payload) {
    console.log('FETCH_INPUT_PANEL_DATA : payload: ', payload)
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: '/input_panel_data/' + payload.inputPanelId,
        options: {
          params: {
            pagination: JSON.stringify(payload.pagination),
            date: payload.date,
            amPmFilter: payload.amPmFilter
          }
        }
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.FETCH_INPUT_PANEL_DATA_BY_MASTER]({dispatch}, payload) {
    console.log('FETCH_INPUT_PANEL_DATA_BY_MASTER : payload: ', payload)
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: '/input_panel_data/' + payload.inputPanelId + '/by_master',
        options: {
          params: {
            pagination: JSON.stringify(payload.pagination),
            date: payload.date,
            amPmFilter: payload.amPmFilter
          }
        }
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.UPDATE_INPUT_PANEL_PROPERTY] ({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/input_panels/' + payload.inputPanelId,
        data: {
          appId: payload.appId,
          teamId: payload.teamId,
          displayType: payload.displayType
        }
      }
      dispatch('AUTH_PUT', postData).then(
        response => {
          resolve(response)
        }
      )
    })
  },

  [types.INPUT_PANEL_ADD_ENTRY] ({dispatch}, payload) {
    console.log('actions.INPUT_PANEL_ADD_ENTRY')
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/input_panel_data/' + payload.inputPanelId + '/add_entry',
        data: {
          masterRecordId: payload.masterRecordId,
          date: payload.date,
          amPmFilter: payload.amPmFilter,
          value: payload.value
        }
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response)
        }
      )
    })
  },

  [types.INPUT_PANEL_UPDATE_ENTRY] ({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/input_panel_data/' + payload.inputPanelId + '/update_entry',
        data: {
          masterRecordId: payload.masterRecordId,
          targetRecordId: payload.targetRecordId,
          date: payload.date,
          amPmFilter: payload.amPmFilter,
          value: payload.value
        }
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response)
        }
      )
    })
  },

  [types.INPUT_PANEL_SUBMIT_CODE]({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/input_panel_data/' + payload.inputPanelId + '/submit_codes',
        data: {
          codes: payload.codes,
          date: payload.date,
          amPmFilter: payload.amPmFilter
        }
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response)
        }
      )
    })
  },

  [types.SET_CURRENT_INPUT_PANEL_PROPERTY]({commit}, payload) {
    commit('SET_CURRENT_PANEL_PROPERTY', payload)
  },

  [types.SAVE_INPUT_PANEL] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/input_panels',
        data: payload.data
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.UPDATE_INPUT_PANEL] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      const putData = {
        urlCommand: '/input_panels/' + payload.data._id,
        data: {...payload.data, _id: null}
      }
      dispatch('AUTH_PUT', putData).then(
        response => {
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  },

  [types.FETCH_INPUT_PANEL] ({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      var inputPanelId
      if (payload && payload.inputPanelId) {
        inputPanelId = payload.inputPanelId
      } else {
        inputPanelId = localStorage.getItem('inputPanelId')
      }
      if (!inputPanelId || inputPanelId === '') {
        reject('Input Panel Id Undefined')
      }

      console.log('FETCH_INPUT_PANEL: payload: ', payload)
      console.log('FETCH_INPUT_PANEL: inputPanelId: ', inputPanelId)

      const getData = {
        urlCommand: '/input_panels/' + inputPanelId
      }
      
      dispatch('AUTH_GET', getData).then(
        response => {
          console.log('FETCH_INPUT_PANEL : response: ', response)
          commit(mutations.SET_CURRENT_INPUT_PANEL, response.result)
          resolve(response.result)
        },
        error => {
          reject(error)
        }
      )
    })
  }
}