<template>
  <div class="d-inline-block" style="float: left; max-width: 320px;width: -webkit-fill-available">
    <v-tooltip top max-width="540px">
      <template v-slot:activator="{ on }">
        <v-chip
          v-on="on"
          :close="deletableChips && hovering"
          @mouseenter="hovering = true"
          @mouseleave="hovering = false"
          :style="colorStyle"
          @click="onClick"
          @click:close="onCloseClicked"
          label
          class="min-width-80 justify-center option-button"
          small
        >
          <span class="text-truncate">{{ displayValue }}</span>
        </v-chip>
      </template>
      {{ displayValue }}
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'optionButton',
  data () {
    return {
      hovering: false
    }
  },
  props: {
    option:Object,

    // fieldInfo: Object,
    // disabled: {
    //   type: Boolean,
    //   default: false
    // },
    useColor: {
      type: Boolean,
      default: false
    },
    toggleType: {
      type: String,
      default: 'single'
    },

    deletableChips: Boolean
  },
  computed: {

    displayValue () {
      return this.option ? this.option.label : ''
    },

    colorSet () {
      return this.option && this.option.colorSet || ""

    },
    colorSetObj () {
      const vm = this
      var result = {color: '', backgroundColor: ''}
      if (vm.colorSet !== '') {
        const colorSegs = vm.colorSet.split('/')
        result.color = colorSegs[0]
        result.backgroundColor = colorSegs[1]
      }
      return result
    },
    colorStyle () {
      return this.useColor ? this.colorSetObjectToStyle(this.colorSetObj) : {
        
      }
    }
  },
  methods: {
    onClick () {
      console.log('OptionButton :: onClick')
      this.$emit('click', this.option._id)
    },
    onCloseClicked () {
      this.$emit('onDelete', this.option._id)
    },
    colorSetObjectToStyle (colorSetObj) {
      return {
        color: colorSetObj.color + ' !important',
        backgroundColor: colorSetObj.backgroundColor + ' !important'
      }
    }
  }
}
</script>

<style>
  .option-button {
    margin: 0 2px 2px 0;
  }
</style>
