<template>
  <div class="wit-currency-selection">
    <label>{{ label }}</label>
    <div class="d-flex flex-row align-start">
      <v-card outlined
      :min-height="40"
        class="flex-grow-1">
        <v-chip-group
          :column="true"
          class="pl-1"
          v-model="propertyValue">
          <v-chip small close v-for="item in propertyValue"
            @click:close="onRemoveItem(item)"
            style="margin-right:1px;margin-bottom:1px;"
            :key="item._id">
            {{ item }}
          </v-chip>
        </v-chip-group>      
      </v-card>
      <v-menu
        ref="currencyMenu"
        :max-height="400"
        offset-y
        v-click-outside="onClickOutsideMenu"
        v-model="showingMenu"
        :close-on-click="false"
        class="currency-list white">
        <template v-slot:activator="{attrs, on}">
          <v-btn
            v-bind="attrs"
            elevation="0"
            class="ml-1 flex-grow-0"
            v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <div>
          <!-- <div class="ma-0 position-static d-flex flex-row justify-end"
            style="top:0;left:0;">
            <v-icon class="mt-2 mr-4">mdi-close</v-icon>
          </div> -->
          <v-list dense>
            <v-list-item dense
              @click.prevent.stop="toggleCurrency(currency)"
              class="currency-item"
              :class="{'primary':isSelected(currency)}"
              v-for="currency in commonCurrencies"         
              :key="currency.cc">
              <v-list-item-title>{{ currency.cc }}
                <v-chip class="cursor-pointer">{{ currency.name }}</v-chip>
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense
              @click.prevent.stop="toggleCurrency(currency)"
              class="currency-item"
              :class="{'primary':isSelected(currency)}"
              v-for="currency in nonCommonCurrencies"         
              :key="currency.cc">
              <v-list-item-title>{{ currency.cc }}
                <v-chip class="cursor-pointer">{{ currency.name }}</v-chip>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </div>


    <!-- <v-select 
        deletable-chips
        small-chips
        dense
        outlined
        solo  
        multiple      
        clearable
        v-model="propertyValue"
        item-color="primary"
        :items="currencies"
        item-text="name"
        item-value="cc"
        :menu-props="{top: false, offsetY: true, contentClass: 'denseList'}">
        <template #item="{item}">
          <v-chip small><div class="d-inline-block mr-1">{{item.cc}}</div>
            <div class="d-inline-block primary--text">{{ item.name }}</div>
          </v-chip>
        </template>
        <template #selection="{item}">
          <v-chip small class="ma-0 mr-1 mb-1">
            <div class="d-inline-block mr-1">{{item.cc}}</div>       
            <v-icon @click.stop="removeItem(item)" class="cursor-pointer" right small>mdi-close-circle</v-icon>
          </v-chip>
        </template>
    </v-select> -->
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'witCurrencySelection',
  mixins: [mixin],
  // data () {
  //   return {
  //   }
  // },
  watch: {
    showingMenu: function (newVal) {
      const vm = this
      if (newVal) {
        vm.openingMenu = true
        setTimeout(() => {
          vm.openingMenu = false
        }, 1000)
      }
    }
  },
  methods: {
    onRemoveItem(item) {
      console.log('onRemoveItem: item: ', item)
      const index = this.propertyValue.findIndex(value => value===item)
      if (index >= 0) {
        this.propertyValue.splice(index, 1)
      }
    },
    onClickOutsideMenu (event) {
      if (!this.togglingMenu && !this.openingMenu && this.showingMenu) {
        this.$refs.currencyMenu.save()
      }
      console.log('onClickOutsideMenu : togglingMenu = ' + (this.togglingMenu ? 'yes' : 'no'))
      console.log('onClickOutsideMenu : openingMenu = ' + (this.openingMenu ? 'yes' : 'no'))

      console.log('onClickOutsideMenu : showingMenu = ' + (this.showingMenu ? 'yes' : 'no'))
      console.log('onClickOutsideMenu event: ', event)
    },
    // onCurrencyRequested (event) {
    //   console.log('onCurrencyRequested: event: ', event)
    //   this.showingMenu = true
    // },
    isSelected (currency) {
      // console.log('isSelected: typeof currency = ' + (typeof this.propertyValue))
      // console.log('isSelected: currency: ', currency)
      // console.log('isSelected: this.propertyValue: ', this.propertyValue)

      return this.propertyValue && 
        Array.isArray(this.propertyValue) &&
        this.propertyValue.includes(currency.cc)
    },
    toggleCurrency (currency) {
      const vm = this
      vm.togglingMenu = true
      console.log('toggleMenu starts')
      if (Array.isArray(vm.propertyValue)) {
        const index = vm.propertyValue.findIndex( value => value===currency.cc)
        if (index >= 0) {
          vm.propertyValue.splice(index, 1)
        } else {
          vm.propertyValue.push(currency.cc)
        }
      } else {
        vm.propertyValue = [currency.cc]
      }
      setTimeout(() => {
        console.log('togglingMenu ends')
        vm.togglingMenu = false
      }, 1000)
    },
    removeItem (item) {
      const vm = this
      console.log('removeItem: item: ', item)
      console.log('removeItem: vm.propertyValue: ', vm.propertyValue)
      if (vm.propertyValue && Array.isArray(vm.propertyValue)) {
        const index = vm.propertyValue.findIndex(a => a === item.cc)
        if (index >= 0) {
          vm.propertyValue.splice(index, 1)
        }
      }
    }
  },
  data () {
    return {
      currencies: [],
      showingMenu: false,
      openingMenu: false,
      togglingCurrency: false
    }
  },
  mounted () {
    const vm = this
    vm.$store.dispatch('AUTH_GET', {urlCommand: '/currencies'}).then(
      response => {
        vm.currencies = response.result
      }
    )
  },
  computed: {
    // selfFieldId () {
    //   const vm = this
    //   var result = ''
    //   for (let i = 0; i < vm.formFieldInfos.length; i++) {
    //     if (vm.formFieldInfos[i]._id === vm.fieldInfoId) {
    //       result = vm.formFieldInfos[i].fieldId
    //       break
    //     }
    //   }
    //   return result
    // },
    commonCurrencies () {
      return this.currencies ?
        this.currencies.filter(currency => currency.common) : 
        []
    },
    nonCommonCurrencies () {
      return this.currencies ?
        this.currencies.filter(currency => !currency.common) :
        []
    },
    formFieldInfos () {
      const vm = this
      return vm.$store.getters.formFieldInfos
    }
  }
}
</script>

<style>
  .wit-currency-selection .v-select__selections {
    padding-top: 4px;
    margin-bottom: 1px;
  }

  .wit-currency-selection .v-select.v-input--dense .v-chip {
    margin-bottom: 1px;
  }

  .wit-currency-selection .v-select.v-input--dense [readonly=readonly] {
    height: 24px;
  }

  .wit-currency-selection .v-input__slot {
    padding-left: 0 !important;
  }

  .wit-currency-selection .v-card {
    border-color: black !important;
  }
</style>
