<template>
  <v-dialog v-model="showing" width="320" overlay-opacity="0.7">
    <v-card>
      <v-toolbar dark color="error" height="32">
        <v-toolbar-title>{{ $t('messages.teamExpireSoon') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="showing=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-body>
        <div class="w-100 pa-5" style="line-height:1.1;">
        {{ $t('messages.teamExpiryReminderMessage') }}:<br/>
        yoov+@yoov.com
        </div>
      </v-card-body>
    </v-card>
  </v-dialog>  
</template>

<script>
export default {
  name: 'reminderDialog',
  data () {
    return {
      showing: false
    }
  },
  methods: {
    show() {
      this.showing = true;
    }
  }
}
</script>