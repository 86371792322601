var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relation-table-view d-flex flex-column",class:_vm.stretch==='true'?'flex-grow-1 pt-1':'mt-1 height-auto'},[_c('div',{staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"width":"0","min-width":"100%"}},[_c('vxe-toolbar',{class:_vm.stretch==='true'?'flex-grow-0':'',staticStyle:{"height":"40px"},style:({'margin-top':(_vm.stretch==='true'?'-32px':'-40px')}),attrs:{"size":"small"},scopedSlots:_vm._u([(_vm.hasControl)?{key:"tools",fn:function(){return [(_vm.allowDeletion && _vm.numberOfSelections > 0)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"error"},on:{"click":_vm.deleteItems}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(_vm._s(_vm.$t("buttons.delete")))],1):_vm._e(),(_vm.allowUnlinking && _vm.numberOfSelections > 0)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"error"},on:{"click":_vm.unlinkItems}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link-variant-remove")]),_vm._v(" "+_vm._s(_vm.$t("buttons.unlink")))],1):_vm._e(),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.onRefreshClicked()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t('buttons.refresh')))],1)]},proxy:true}:null,{key:"pager",fn:function(){return [_c('vxe-pager',{attrs:{"perfect":"","layouts":[
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'FullJump',
              'Sizes',
              'Total' ],"current-page":_vm.pagination.currentPage,"page-size":_vm.pagination.pageSize,"total":_vm.pagination.totalResult},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"page-change":_vm.handlePageChange}})]},proxy:true}],null,true)}),(_vm.formInfo)?_c('div',{staticClass:"flex-grow-1"},[_c('vxe-grid',_vm._b({ref:"xTable",attrs:{"title":"relationTableView","edit-config":_vm.editConfig,"cell-class-name":_vm.rowHeightClass,"id":"relationTableView","loading":_vm.loading,"keep-source":"","show-header-overflow":true,"show-overflow":true,"size":"small"},on:{"edit-closed":_vm.editClosedEvent,"edit-actived":_vm.editActiveEvent},scopedSlots:_vm._u([{key:"pager_left",fn:function(){return undefined},proxy:true}],null,false,1965067052)},'vxe-grid',_vm.gridOptions,false),[_c('vxe-table-column',{attrs:{"type":"seq","width":"60","fixed":"left","class-name":"text-center","header-class-name":"text-center"}}),_vm._l((_vm.colDefs),function(colDef,index){return [(colDef.componentName !== '')?_c('vxe' + colDef.componentName,{key:index,tag:"component",attrs:{"colDef":colDef,"relationData":_vm.relationData},on:{"onCommand":_vm.onCommandHandler}}):_c('vxe-table-column',_vm._b({key:index},'vxe-table-column',colDef,false))]}),(_vm.hasControl)?_c('vxe-table-column',{attrs:{"title":_vm.$t('general.operation'),"min-width":"220","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!_vm.isEditing(row) && !_vm.isReadOnly)?_c('v-btn',{staticClass:"ml-1",attrs:{"dark":"","x-small":"","elevation":false,"fab":"","color":"primary"},nativeOn:{"click":function($event){return _vm.setActiveRow(row)}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1):_vm._e(),(_vm.isEditing(row) && !_vm.isReadOnly)?_c('v-btn',{staticClass:"ml-1",attrs:{"dark":"","elevation":false,"x-small":"","fab":"","color":"success"},nativeOn:{"click":function($event){return _vm.saveRowEvent(row)}}},[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1):_vm._e(),(!_vm.isReadOnly)?_c('v-btn',{staticClass:"ml-1",attrs:{"dark":"","x-small":"","elevation":false,"fab":"","color":"primary"},nativeOn:{"click":function($event){return _vm.editRow(row)}}},[_c('v-icon',[_vm._v("mdi-file-document-edit")])],1):_vm._e(),(_vm.allowDeletion && !_vm.isReadOnly)?_c('v-btn',{staticClass:"ml-1",attrs:{"dark":"","x-small":"","elevation":false,"fab":"","color":"error"},nativeOn:{"click":function($event){return _vm.deleteRow(row)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.allowUnlinking && !_vm.isReadOnly)?_c('v-btn',{staticClass:"ml-1",attrs:{"dark":"","x-small":"","elevation":false,"fab":"","color":"error"},nativeOn:{"click":function($event){return _vm.unlinkRow(row)}}},[_c('v-icon',[_vm._v("mdi-link-variant-remove")])],1):_vm._e()]}}],null,false,75040872)}):_c('vxe-table-column',{attrs:{"title":_vm.$t('general.operation'),"min-width":"220","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"dark":"","x-small":"","elevation":false,"fab":"","color":"primary"},on:{"click":function($event){return _vm.selectRecord(row)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("buttons.detail")))])])]}}],null,false,2795804700)})],2)],1):_vm._e()],1),_c('confirm-delete-dialog',{ref:"confirmDeleteDialog"}),_c('confirm-dialog',{ref:"confirmDialog"}),_c('select-related-records-dialog',{ref:"selectRelatedRecordsDialog"}),_c('select-related-cards-dialog',{ref:"selectRelatedCardsDialog"}),_c('dialog-button-select-employees',{ref:"tableEesDialog",staticClass:"pa-0",attrs:{"addButton":false},model:{value:(_vm.showEesDialog),callback:function ($$v) {_vm.showEesDialog=$$v},expression:"showEesDialog"}}),(_vm.showingMembersMenu)?_c('members-menu',{ref:"membersMenu",attrs:{"fullWidth":true,"attachId":_vm.membersMenuPayload.attachedId,"nudgeBottom":_vm.rowHeight},on:{"memberClick":_vm.handleMemberClick},model:{value:(_vm.showingMembersMenu),callback:function ($$v) {_vm.showingMembersMenu=$$v},expression:"showingMembersMenu"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }