<template>
  <div class="white calendar px-3 fill-height w-100">    
    <calendar-main-page v-if="authorized"
      ref="calendarMainPage"
      @onCommand="onCommandHandler"
      :panelSettings="calendarPanel"></calendar-main-page>
    <panel-access-denied v-else-if="!loading&&!authorized && !isAdmin"
      :panelSettings="calendarPanel">
    </panel-access-denied>
    <calendar-setup-page v-else
      ref="calendarSetupPage"
      :panelSettings="calendarPanel"
      @update="updateCalendarPanel"></calendar-setup-page>  
  </div>
</template>

<script>
import calendarMainPage from './CalendarMainPage'
import calendarSetupPage from './CalendarSetupPage'
import panelAccessDenied from '@/components/PanelAccessDenied'
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'
import {getFieldInfosByType} from '@/helpers/FormHelpers'
import  {cloneDeep} from "lodash"
const DEFAULT_PANEL = {
  _id: '',
  teamId: '',
  appId: '',
  title: '',
  subTitle: '',
  formConfigs: [],
  colorBasedOn: 'random'
}

export default {
  name: 'calendar',
  mixins: [
    dateTimeHelperMixin
  ],
  components: {
    calendarMainPage,
    calendarSetupPage,
    panelAccessDenied
  },
  data () {
    return {
      calendarPanel: null,
      loading: true
    }
  },
  watch: {
    appId: function () {
      this.loadSettings()
    },
    '$route.params.itemId': function () {
      this.loadSettings()
    }
  },
  computed: {
    authorized () {
      const vm = this
      var result = true
      if (vm.calendarPanel && vm.calendarPanel.formConfigs.length <= 0) {
        result = false
      }
      return result
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    appId () {      
      return this.$store.getters.currentApp ?
        this.$store.getters.currentApp._id : ''
    },
    teamId () {
      return this.$store.getters.activeTeam ?
      this.$store.getters.activeTeam._id : ''
    },

  },
  mounted () {
    this.loadSettings()
  },
  methods: {
    onCommandHandler (payload) {
      switch (payload.command) {
        case 'refresh':
          this.refresh()       
          break;
      }
    },
    refresh(){
      this.loadSettings().then(()=> {
        this.$refs.calendarMainPage.refreshData();
      })
    },
    updatePanel (updatedPanel) {
      this.checkAndAssign(updatedPanel)
    },
    async loadSettings () {
      const vm = this
      if (vm.appId && vm.teamId) {

        vm.loading = true
        vm.$store.dispatch('FETCH_CALENDAR_PANEL', {
          panelId: vm.$route.params.itemId
        }).then(
          response => {
            vm.loading = false
            const calendarPanel = cloneDeep(DEFAULT_PANEL);
            calendarPanel._id = vm.$route.params.itemId
            calendarPanel.appId = vm.$store.getters.currentApp._id
            calendarPanel.teamId = vm.$store.getters.activeTeam._id

            if (response) {
              Object.assign(calendarPanel, response)
              if (calendarPanel.title === '') {
                calendarPanel.title = calendarPanel.label 
              }

              // if (inputPanel.timeSegments.length === 0) {
              //   inputPanel.timeSegments.push(
              //     vm.DEFAULT_TIME_SEGMENT
              //   )
              // }

              // Check validity of titleField, startField, endField
              vm.checkAndUpdateField(calendarPanel)

              // Check Authorization
              vm.checkAndAssign(calendarPanel)
            }
          },
          error => {
            vm.loading = false
          }
        )
      }
    },  

    checkAndUpdateField (calendarPanel) {
      const vm = this
      // console.log('checkAndUpdateField : calendarPanel: ', JSON.stringify(calendarPanel))
      for (let i = 0; i < calendarPanel.formConfigs.length; i++) {
        const loopFormConfig = calendarPanel.formConfigs[i]
        const formInfo = loopFormConfig.formInfo

        const textFieldInfos = getFieldInfosByType(formInfo, ['text', 'textCombination'])
        const dateTimeFieldInfos = getFieldInfosByType(formInfo, 'date')
        
        const textFieldIds = textFieldInfos.map(info => info.fieldId)
        // console.log('textFieldIds = ' + textFieldIds.join(','))
        const dateTimeFieldIds = dateTimeFieldInfos.map(info => info.fieldId)
        // console.log('dateTimeFieldIds = ' + dateTimeFieldIds.join(','))

        if (!textFieldIds.includes(loopFormConfig.titleField))
          loopFormConfig.titleField = ''

        if (!dateTimeFieldIds.includes(loopFormConfig.startField))
          loopFormConfig.startField = ''

        if (!dateTimeFieldIds.includes(loopFormConfig.endField))
          loopFormConfig.endField = ''
      }
    },

    checkAndAssign (panelSettings) {
      const vm = this
      if (panelSettings.formConfigs && panelSettings.formConfigs.length >0) {
        vm.calendarPanel = panelSettings
      } else {
        vm.calendarPanel = panelSettings
      }
    },

    checkAuth (formId) {
      const vm = this
      return new Promise(resolve => {
        vm.$store.dispatch('AUTH_GET', {
          urlCommand: '/forms/' + formId + '/permissions'
        }).then(
          response => {
            resolve(response.result)
          }
        )

      })
    },

    updateCalendarPanel (updatedSettings) {
      this.calendarPanel = updatedSettings
    }
  }
}
</script>