<template>
    <v-dialog
        v-model="showingDialog"
        width="800"
        ref="queryDialog"
    >  
        <v-card class="px-0" style="overflow: hidden">
            <v-card-title style="font-size:17px">
                {{$t('defaultValue.queryForm')}}
            </v-card-title>
            <v-card-text 
                class="d-flex flex-column px-5 py-0" 
                style="min-height:600px; overflow: auto; max-height:0px;"
            >
                <!-- <v-tabs
                    v-if="type"
                    v-model="tab"
                    background-color="transparent"
                    color="teal lighten-2"
                    grow
                    class="flex-grow-0 px-5"
                    @change="handleTabChange"
                >
                    <v-tab
                        v-for="item in queryTypes"
                        :key="item"
                        style="border-bottom:1px solid #d9d9d9"
                        :value="item.id"
                        :tag="item.id"
                    >
                        {{$t('displayRule.'+item.label)}}
                    </v-tab>
                </v-tabs>
                <v-tabs-items 
                    v-model="tab" 
                    class="flex-grow-1 px-5 mt-1"
                    style="height:100%; overflow: auto;"
                >
                    <v-tab-item class="">
                        <query-content
                            type="current"
                            :config="{
                                worksheetInput: false
                            }"
                            :copyField="copyField"
                            :conditions="conditions"
                            :bl_lastRecord="bl_lastRecord"
                            ref="currentQueryContent"
                            @onCommand="onCommandHandler"
                        >
                        </query-content>   
                    </v-tab-item>
                    <v-tab-item>
                        <query-content
                            type="form"
                            :config="{}"
                            :copyField="copyField"
                            :conditions="conditions"
                            :bl_lastRecord="bl_lastRecord"
                            ref="formQueryContent"
                            @onCommand="onCommandHandler"
                        >
                        </query-content>   
                    </v-tab-item>
                </v-tabs-items> -->
                <query-content
                    type="form"
                    :config="{}"
                    :copyField="copyField"
                    :conditions="conditions"
                    :bl_lastRecord="bl_lastRecord"
                    ref="formQueryContent"
                    @onCommand="onCommandHandler"
                >
                </query-content>   
            </v-card-text>
        <dialog-actions 
            @onCommand="onCommandHandler"
            :buttons="['close', 'save']"
        ></dialog-actions>
        </v-card> 
    </v-dialog>
</template>

<script>
    import dialogActions from "@/components/dialogs/comps/DialogActions";
    import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
    import WorksheetSelect from '@/pages/admin/workflow/components/tree/setting/shared/worksheetSelect';
    import widgetHelperMixin from '@/mixins/widgetHelperMixin'
    import filterHelperMixin from '@/mixins/filterHelperMixin'
    import fieldSelectionMenu from '../menus/fieldSelectionMenu'


    import RuleText from '@/pages/admin/form/tableSetup/rules/RuleText'
    import RuleNumber from '@/pages/admin/form/tableSetup/rules/RuleNumber'
    import RuleDate from '@/pages/admin/form/tableSetup/rules/RuleDate'
    import RuleSelection from '@/pages/admin/form/tableSetup/rules/RuleSelection'
    import RuleMembers from '@/pages/admin/form/tableSetup/rules/RuleMembers'
    import RuleDepartments from '@/pages/admin/form/tableSetup/rules/RuleDepartments'
    import RuleRadio from '@/pages/admin/form/tableSetup/rules/RuleRadio'
    import RuleRelatedRecord from '@/pages/admin/form/tableSetup/rules/RuleRelatedRecord'
    import RuleChildTable from '@/pages/admin/form/tableSetup/rules/RuleChildTable'

    import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
    import { upperFirst } from 'lodash';
    import NodeUtils from '@/pages/admin/workflow/utils/node';
    import FilterCondition from '@/pages/admin/workflow/components/tree/setting/shared/filterCondition';
    import queryContent from './queryContent'
    
    import {
        FORM_SELECTIONS,
        SET_SETTINGS,
        FORM_SELECTIONS_FIELDS,
    } from '@/store/modules/workflow/action_types';
    export default {
        mixins:[widgetHelperMixin, filterHelperMixin],
        props:{
            value: Boolean,
            formData: Object,
        },
        components:{
            ComboBox,
            WorksheetSelect,
            dialogActions,
            fieldSelectionMenu,
            RuleText,
            RuleNumber,
            RuleDate, 
            RuleSelection,
            RuleMembers,
            RuleDepartments,
            RuleRadio,
            RuleRelatedRecord,
            RuleChildTable,
            queryContent
        },
        data(){
            return{
                showingFieldSelectionMenu: false,
                // relatedTableInfos: [],
                valueFormat: 'object',
                comboxProps:{onlySuffix: true,onlyPrefix: false},
                dependsOnType: null,
                queryTypes: [
                    // {
                    //     id: 'CURRENT',
                    //     label: 'currentRecord'    
                    // },
                    {
                        id: 'FORM',
                        label: 'formRecord'
                    }
                ],
                tab: null,
                conditions: [],
                copyField: null,
                bl_lastRecord: false,
                type: null
            }
        },
        created(){
            this.conditions = this.formData.conditions
            this.copyField = this.formData.copyField
            this.bl_lastRecord = this.formData.bl_lastRecord
            this.type = this.formData.type
            this.tab = this.queryTypes.findIndex(item=>item.id == this.type)
        },
        computed:{
            relatedTableInfos(){
                return this.$store.getters.relatedTableInfos
            },
            showingDialog:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            list() {
                return this.$store.getters.getFormSelectionsFields.children || []
            },
            filteredFormFieldInfos(){
                var excludeArr = ["owner", "updatedAt", "createdAt", "createdBy", "capitalizedAmount",  "autoNumbering", "location", "richText", "region"] 
                return this.list.filter(item=> !excludeArr.includes(item.fieldType))
            },
            worksheet(){
                return this.$store.state.workflowTree.setting.worksheet
            },
            fieldIdsWithProperties () {
                const vm = this
                var result = []
                for (let i = 0; i < vm.list.length; i++) {
                    var loopFieldInfo = vm.list[i]
                    
                    if(loopFieldInfo.fieldType != 'otherTableField')
                        var filterType = vm.getFilterKeyByFieldInfo(vm.$store.getters.widgetMap, loopFieldInfo)
                    else
                        var filterType = vm.getFilterKeyByFieldInfo(vm.$store.getters.widgetMap, loopFieldInfo.relatedField)

                    if (filterType) {
                        result.push({
                            id: loopFieldInfo.fieldId,
                            label: loopFieldInfo.label,
                            filterType: filterType,
                            fieldInfo: loopFieldInfo
                        })
                    }
                }
                return result
            },
            filterInfos () {
                return this.$store.getters.filterInfos
            }
        },
        methods:{
            handleTabChange(){
                this.conditions = []
                this.copyField = null
                this.bl_lastRecord = false
                this.$emit('setWorkSheet')
            },
            onCommandHandler(payload){
                // var tarRef = !this.queryTypes? this.$refs['currentQueryContent']:this.$refs['formQueryContent']
                console.log('onCommandHandler---', payload)
                switch(payload.command){
                    case 'save':
                        var queryType = this.queryTypes[parseInt(this.tab)||0].id
                        var formData = {
                            conditions: this.conditions,
                            copyField: this.copyField,
                            worksheet: this.worksheet._id,
                            queryType,
                            bl_lastRecord: this.bl_lastRecord,
                            type: 'QUERY'
                        }
                        this.$emit('submit', formData)
                        this.showingDialog = false
                    break
                    case 'close':
                        this.showingDialog = false
                    break
                    case 'addCond':
                        var length = this.conditions.length
                        if(length==0)
                        {
                            this.conditions.splice(0, 0, [payload.data])
                        }else{ 
                            this.conditions[payload.index].push(payload.data)
                        }
                    break
                    case 'deleteCond':
                        if(this.conditions[payload.index1].length==1){
                            this.conditions.splice(payload.index1, 1)
                        }else{
                            this.conditions[payload.index1].splice(payload.index2, 1)
                        }
                    break
                    case 'updateCopyField':
                        console.log('payload.data')
                        this.copyField = payload.data
                    break
                    case 'updateLastRecord':
                        this.bl_lastRecord = payload.data
                    break
                }
            }
        }
    }
</script>