<template>
  <v-dialog
    v-model="showingDialog"
    scrollable
    width="1280"
    max-width="90%">
    <v-card min-height="700">
      <dialog-header
        :label="$t('inputPanel.inputPanelSetup')"
        @close="showingDialog=false"
        color="#E0E0E0"
        toolbarClass="grey--text"/>
      <v-card-text class="px-5 team-authorization-setup-dialog-body position-relative">
        <LoadingIcon v-if="loading"/>
        <div v-else class="d-flex flex-column fill-height justify-start">
          <div class="fill-height">
            <input-panel-settings-form v-if="inputPanel"
              ref="inputPanelSettingsForm"
              class="fill-height"
              v-model="inputPanel"></input-panel-settings-form>      
          </div>
        </div>
      </v-card-text>
      <dialog-actions 
        :buttons="['ok', 'cancel']"
        @onCommand="onCommandHandler">
      </dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import LoadingIcon from "@/components/global/loadingIcon";
import inputPanelSettingsForm from '@/pages/inputPanel/comps/InputPanelSettingsForm'

export default {
  name: 'inputPanelSetupDialog',
  components: {
    dialogActions,
    dialogHeader,
    LoadingIcon,
    inputPanelSettingsForm
  },
  data () {
    return {
      userInput: '',
      showingDialog: false,
      callback: null,
      inputPanel: null
    }
  },
  methods: {
    open (payload, callback) {
      this.inputPanel = JSON.parse(JSON.stringify(payload.inputPanel))
      this.callback = callback
      this.showingDialog = true      
    },
    close () {
      this.showingDialog = false
      if (typeof callback === 'function') {
        callback()
      }
    },

    save (callback) {
      const vm = this
      console.log('InputPanelSetupPage.save')
      const hasErrors = vm.$refs.inputPanelSettingsForm.validateForm()
      console.log('InputPanelSetupPage :: hasErrors: ', hasErrors)
      if (!hasErrors) {
        vm.inputPanel.appId = vm.$store.getters.currentApp._id
        vm.inputPanel.teamId = vm.$store.getters.activeTeam._id
        
        console.log('InputPanelSetup')
        vm.$store.dispatch('UPDATE_INPUT_PANEL', {
          data: vm.inputPanel
        }).then(
          response => {
            console.log('InputPanelSetupDialog :: save :: response: ', response)
            if (typeof callback === 'function') {
              callback({
                command: 'ok',
                inputPanel: response
              })
            }
          }
        )
      }
    },

    onCommandHandler (payload) {
      const vm = this
      console.log('InputPanelSetupDialog.onCommandHandler :: payload: ', payload)
      if (payload.command === 'ok') {
        vm.save(saveResult => {
          vm.showingDialog = false
          if (typeof vm.callback === 'function') {  
            console.log('InputPanelSetupDialog => callback: res: ', saveResult)
            vm.callback(saveResult)
          }
        })
        // const hasErrors = vm.$refs.inputPanelSettingsForm.validateForm()
        // console.log('InputPanelSetupPage :: hasErrors: ', hasErrors)
        // try {
        //   if (!hasErrors) {
        //     console.log('no error')
        //     payload = {
        //       ...payload,
        //       inputPanel: vm.inputPanel
        //     }
        //   } else {
        //     console.log('has error')
        //     return
        //   }
        // } catch(err) {
        //   console.log('err: ', err)
        // }
      } else {
        vm.showingDialog = false
      }
      // console.log('onCommandHandler :: payload: ', payload)
      // this.showingDialog = false
      // if (typeof vm.callback === 'function') {  
      //   vm.callback(payload)
      // }
    }
  }
}
</script>