<template>
  <FormElement
    :label="$t('workflow.modules.subWorkflow.label.executionMode')"
    :hints="$t('workflow.modules.subWorkflow.remark.executionMode')"
    name='executionMode'
  >
    <v-radio-group hide-details v-model="executionMode" @change="onChange">
      <v-radio
        v-for="item in selections"
        :key="item.key"
        :value="item.key"
        class="w-f-radio-field"
      >
        <template v-slot:label>
          <FormElement
            :hints="item.hints"
            subItem
            hintsPosition="bottom"
          >
            <span class="w-f-radio-field-text">{{ item.label }}</span>
          </FormElement>
        </template>
      </v-radio>
    </v-radio-group>
  </FormElement>
</template>

<script>
// import Validator from 'validator';
import FormElement from './../shared/formElement';
import ComboBox from './../../../form/comboBox/';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import Mixin from '../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ComboBox,
    FormElement,
  },
  props: {
    executionMode: String,
  },
  data() {
    return {
      selections: [
        {
          key: 'async',
          label: this.$t('workflow.modules.subWorkflow.label.mode.async'),
          hints: this.$t('workflow.modules.subWorkflow.remark.mode.async'),
        },
        {
          key: 'sync',
          label: this.$t('workflow.modules.subWorkflow.label.mode.sync'),
          hints: this.$t('workflow.modules.subWorkflow.remark.mode.sync'),
        },
      ],
    }
  },
  methods: {
    onChange(v) {
      this.$store.dispatch(SET_SETTINGS, {
        executionMode: v,
      });
    },
  }, 
};

</script>

<style scoped>
  .w-f-radio-field {
    padding: 5px 0 !important;
    align-items: flex-start;
  }
  .w-f-radio-field-text {
    font-size: 14px;
  }
</style>
