<template>
  <div class="table-setup-menu-item d-flex flex-row justify-space-between align-center"
       @click="selectMenuItem"
       :class="{'primary white--text':isSelected}">
    <v-icon class="mr-3" :class="{'white--text':isSelected}">{{ menuItem.icon }}</v-icon>
    <small class="flex-grow-1">{{ $t(menuItem.labelTag) }}</small>
  </div>
</template>

<script>
export default {
  props: {
    menuItem: Object,
    selected: Object
  },
  computed: {
    isSelected () {
      return this.selected === this.menuItem
    }
  },
  methods: {
    selectMenuItem () {
      this.$router.push(this.menuItem.route)
      const vm = this
      vm.$emit('onCommand', {
        command: 'selectMenuItem',
        menuItem: vm.menuItem
      })
    }
  }
}
</script>

<style>
  .table-setup-menu-item {
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, .05);
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 0.2rem;
    cursor: pointer;
    min-width: 130px;
  }
  .table-setup-menu-item:hover {
    background-color: rgba(0,0,0,.1);
  }

  /*.table-setup-menu-item.selected {*/
 /**/
  /*border-color: #1565C0;*/
  /*color: #1565C0;*/
/*}*/
</style>
