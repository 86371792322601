<template>
  <v-navigation-drawer class="ma-0" permanent hide-overlay app fixed width="300">
    <v-container fluid class="pl-4" style="padding-top:80px">
      <v-row align="center" justify="space-between">
        <v-col class="pb-2 font-weight-bold" style="font-size:14px">
          {{$t('printTemplate.systemField')}}
        </v-col>
        <v-col class="pa-0" cols="3">
          <v-btn text small v-if="isAllSelected" color="grey" @click="removeAll"> {{ $t('printTemplate.removeAll') }}</v-btn>
          <v-btn text small v-else color="grey" @click="selectAll"> {{ $t('printTemplate.selectAll') }}</v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col class="py-0">
          <v-list dense nav class="pl-0">
            <v-list-item
              dense
              v-for="systemField in systemFields"
              :key="systemField.key"
              class="pa-0 ma-0"
              style="min-height:20px !important; height: 30px;"
            >
              <v-checkbox
                v-model="template[systemField.key]"
                class="pa-0 ma-0 leftbar-cbx-txt"
                :ripple="false"
                dense
                hide-details
                :label="systemField.label"
              ></v-checkbox>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-2 font-weight-bold" style="font-size:14px">
          {{$t('printTemplate.formField')}}
        </v-col>
      </v-row>
      <v-row class="mb-3 mt-3">
        <v-col class="pa-0" >
          <field-options-list :fieldOptions="fieldOptions">
            <template v-slot:top-content><div></div></template>
            <template v-slot:option-content="{ field }">
              <v-col style="white-space: nowrap;overflow:hidden;text-overflow:ellipsis">
              <span >{{ field.label }}</span>
              </v-col>
              <v-col v-if="field.dataType === 'childTable'" cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attr }">
                  <v-btn icon v-on="on" v-bind="attr" small>
                    <v-icon @click="editChildTable(field._id)" small
                      >mdi-pencil-outline</v-icon
                    >
                  </v-btn>
                </template>
                {{ $t("general.field") }}
              </v-tooltip>
              </v-col>
              <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attr }">
                  <v-btn icon v-on="on" v-bind="attr" small>
                    <v-icon @click="appendToLayout(field)" small
                      >mdi-plus</v-icon
                    >
                  </v-btn>
                </template>
                {{ $t("publicForm.append") }}
              </v-tooltip>
              </v-col>
            </template>
          </field-options-list>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col class="pb-2 font-weight-bold" style="font-size:14px">
            {{$t('general.signature')}}
          </v-col>
        </v-row>
        <v-row class="mb-3 mt-3">
          <v-col class="pa-0">
            <field-options-list :fieldOptions="signatureFields">
              <template v-slot:top-content><div></div></template>
              <template v-slot:option-content="{ field }">
                {{ field.label }}
              <v-spacer />
              <v-tooltip top>
                <template v-slot:activator="{ on, attr }">
                  <v-btn icon v-on="on" v-bind="attr" small>
                    <v-icon @click="addSignature(field)" small
                      >mdi-plus</v-icon
                    >
                  </v-btn>
                </template>
                {{ $t("publicForm.append") }}
              </v-tooltip>
              </template>
            </field-options-list>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="pb-2 font-weight-bold" style="font-size:14px">
          {{ $t('printTemplate.others') }}
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col class="py-0">
          <v-list dense nav class="pl-0">
            <v-list-item
              dense
              v-for="field in extraFields"
              :key="field.key"
              class="pa-0 ma-0"
              style="min-height:30px !important; "
            >
              <v-col class="pa-0">
                <v-checkbox
                  v-model="template[field.key]"
                  class="pa-0 ma-0 leftbar-cbx-txt"
                  :ripple="false"
                  dense
                  hide-details
                  :label="field.label"
                ></v-checkbox>

                <div class="extra-option-container" v-if="field.key === 'showFormName' && template.showFormName">
                  <v-text-field
                  v-model="template.formName"
                  dense
                  outlined
                  hide-details
                  single-line
                />
                </div>


                  <!-- display upload element -->
                  <div v-if="field.key === 'showLogo' && template[field.key]" class="extra-option-container">
                    <v-file-input v-if="!template.logoUrl || template.logoUrl == ''"
                      show-size                      
                      dense
                      accept="image/*"
                      :label="$t('printTemplate.chooseFile')"
                      :loadind="isUploadingLogo"
                      @change="uploadLogo"
                      flat
                    ></v-file-input>
                    <div v-else>
                      <!-- display logo thumbnail -->
                      <div class="d-flex justify-space-between align-center">
                        <img :src="template.logoUrl" width="100" />
                        <v-btn fab depressed color="error" small @click="removeLogo"><v-icon>mdi-delete</v-icon></v-btn>
                      </div>
                    </div>
                  </div>

              </v-col>
            </v-list-item>
          </v-list>
          
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import fieldOptionsList from "@/pages/admin/form/publicPublishing/comp/fieldOptionsList";
import MediaHelper from '@/helpers/MediaHelper';
export default {
  props: {
    fieldOptions: Array,
    signatureFields: Array,
    template: Object,
    isAllSelected:Boolean,
    isPrint: Boolean,
  },
  components: {
    fieldOptionsList,
  },
  data() {
    return {
      systemFields: [
        {
          label: "title",
          key: "showTitle",
        },
        {
          label: "owner",
          key: "showOwner",
        },
        {
          label: "createdBy",
          key: "showCreatedBy",
        },
        {
          label: "createdAt",
          key: "showCreatedAt",
        },
        {
          label: "updatedAt",
          key: "showUpdatedAt",
        },
      ],
      extraFields: [
        {
          label: "formName",
          key: "showFormName",
        },
        {
          label: "teamName",
          key: "showTeamName",
        },
        {
          label: "logo",
          key: "showLogo",
        },
        {
          label: "printTime",
          key: "showPrintTime",
        },
      ],
      isUploadingLogo: false
    };
  },
  methods: {
    appendToLayout(field) {
      this.$emit("commandHandler", {
        command: "appendToLayout",
        field,
      });
    },
    addSignature(field){
      this.$emit("commandHandler", {
        command: "addSignature",
        field:field._id,
      });      
    },
    selectAll(){
      this.$emit("commandHandler", {
        command: "selectAll"
      });      
    },
    removeAll(){
      this.$emit("commandHandler", {
        command: "removeAll"
      });       
    },
    editChildTable(fieldId){
      this.$emit("commandHandler", {
        command: "editChildTable",
        fieldId
      })
    },
    async saveSetting ({ refId, type, field, value}) {
      let payload = {
        urlCommand: '', 
        data: {}
      };
      switch ( type ) {
        case 'form': 
          payload.data = {
            formId: refId,
            key: field, 
            value: value, 
            tab: 'recordTitle'
          }
          payload.urlCommand = '/forms/updateSetting'
          break;
      }
      await this.$store.dispatch('AUTH_POST', payload);
    },
    async saveMedia (tempMedia, callback) {
      
      MediaHelper.createMedia({
        ...tempMedia,
        isPublic: true,
        appId: this.template.appId,
        formId: this.template.formId
      }, updatedMedia => {
        console.log('saveMedia updatedMediaa: ', updatedMedia);
        this.template.showLogo = false;
        this.template.logoUrl = updatedMedia.imageUrl;
        this.saveSetting({
          refId: this.template.formId,
          type: 'form',
          field: 'templateLogoMedia',
          value: {
            _id: updatedMedia._id,
            objectKey: updatedMedia.objectKey,
            mediaType: updatedMedia.mediaType,
            imageUrl: updatedMedia.imageUrl
          }
        })
        this.template.showLogo = true;
        if (typeof callback === 'function') {
          callback(updatedMedia)
        }
      });
    },

    assignTemplateLogoMedia (tempMedia) {
      this.$set(this.template,"templateLogoMedia", {
        _id: tempMedia._id,
        objectKey: tempMedia.objectKey,
        mediaType: tempMedia.mediaType,
        imageUrl: tempMedia.thumbnailUrl,
        originalName: tempMedia.originalName
      })
    },

    async uploadLogo(e) {
      this.isUploadingLogo = true;
      this.template.showLogo = false;
      this.template.logoUrl = '';
      if ( e ) { 
        this.template.logoUrl = URL.createObjectURL(e);

          // Notes:
          //
          // if isPrint=true
          // the template logo media will be saved in form
          // thus use storage-bucket directly, instead of upload-bucket
          // => useUploadBucket: false
          // 
          // Then call MediaHelper.createFile
          // to create media record

          MediaHelper.uploadFile({
            appId: this.template.appId, 
            formId: this.template.formId, 
            file: e, 
            isPublic: true, 
            useUploadBucket: !this.isPrint
          }, (tempMedia) => {
            if (this.isPrint) {
              this.saveMedia(tempMedia, this.assignTemplateLogoMedia);
            } else {
              this.assignTemplateLogoMedia(tempMedia);
            }
            this.template.showLogo = true;
          })
      }
      this.template.showLogo = true;
      this.isUploadingLogo = false;
    },
    async removeLogo() {
      this.template.showLogo = false;
      try {
        const { _id: mediaId, objectKey } = this.template.templateLogoMedia;
        if (this.isPrint) {
          MediaHelper.removeMedia({
            mediaId, objectKey
          }, ( res ) => {
            this.template.showLogo = false;
            this.template.logoUrl = '';
            if ( this.isPrint ) {
              this.saveSetting({
                  refId: this.template.formId,
                  type: 'form', 
                  field: 'templateLogoMedia', 
                  value: {}
              })
            } 
            this.$set(this.template, "templateLogoMedia", {})
            this.template.showLogo = true;
          })
        } else {
          this.template.logoUrl = '';
          this.$set(this.template, "templateLogoMedia", {})
        }
      } catch (err) {
        console.error( err )
      }

      this.template.showLogo = true;
    }
  },
};
</script>
<style lang="scss">
  .extra-option-container  {
    margin: 1rem 0;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid #EFEFEF;
  }
</style>
