<template>
  <div>
    <v-btn
      v-if="addButton"
      x-small
      fab
      color="primary"
      class="mr-1"
      @click="showingDialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="showingDialog"
      v-if="showingDialog"
      width="1200"
      :attach="cssSelect?cssSelect:(attach)?`#${attach}`:undefined"
      scrollable
      persistent 
    >
      <v-card class="vxe-table--ignore-clear">
        <dialog-header
          :label="dialogTitle"
          @close="showingDialog = false"
        />
        <v-card-text style="height:540px;">
          <div class="fill-height d-flex flex-row pt-2">
            <v-card class="flex-grow-1 d-flex flex-column mr-2" flat>
              <div v-if="multipleTeams" class="caption">{{ $t("general.company") }}</div>
              <v-layout align-content-start justify-space-between>
                <div class="flex-grow-1" v-if="multipleTeams">
                  <v-select
                    height="30"
                    style="font-size:13px"
                    v-model="selectedTeamId"
                    solo
                    attach
                    outlined
                    dense
                    flat
                    :items="teamSelection"
                    hide-details
                  ></v-select>
                </div>
                <div style="font-size:14px;" v-else>{{selectedTeamName}}</div> 
                <div v-if="enableBranchSelect">
                  <v-btn
                    small
                    text
                    color="primary"
                    @click="selectTeamAll"
                  >{{ $t('workflow.btn.selectAllOne') }}</v-btn>
                </div>
              </v-layout>
              <div class="d-flex flex-row align-center">
                <v-btn-toggle
                  color="primary"
                  v-model="tabIndex"
                  dense
                  tile
                  borderless
                  class="py-0 d-flex vue; popover-empolyee-selector-tab"
                >
                  <v-btn
                    small
                    v-for="type in selectionTypes"
                    :key="type.value"
                  >
                    <v-icon left>{{ type.tagType }}</v-icon>
                    {{ $t(type.labelTag) }}
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-text-field
                solo
                class="ee-dialog-text-field"
                prepend-inner-icon="mdi-account-search"
                :placeholder="$t('settings.searchEmployee')"
                :hide-details="true"
                dense
                height="25"
                style="font-size: 13px"
                v-model="searchText"
                clearable
                @keydown.enter="searchEmployees"
                :append-icon="searchText.length > 0 ? 'mdi-magnify' : ''"
                @click:append="searchEmployees"
                @click:clear="clearSearch"
              >
              </v-text-field>
              <v-window
                v-model="tabIndex"
                class="fill-height flex-grow-1 d-flex flex-column"
              >

                <v-window-item
                  transition="none"
                  reverse-transition="none"
                  style="display:flex;"
                  :class="{ 'd-flex': tabIndex === TAB_EMPLOYEE }"
                  class="fill-height flex-grow-1 flex-column grey lighten-4 pa-2 pt-0 align-start"
                >
                  <div
                    v-if="tabIndex === TAB_EMPLOYEE"
                    class="flex-grow-1 d-flex flex-row fill-height w-100"
                  >
                    <div
                      v-if="loading"
                      class="flex-grow-1 d-flex flex-column w-100 fill-height justify-center align-center"
                    >
                      <font-awesome-icon
                        icon="spinner"
                        class="fa-3x fa-spin"
                      ></font-awesome-icon>
                    </div>

                    <div v-else class="flex-fixed-height w-100">
                      <perfect-scrollbar
                        class="fill-height"
                        style="width:100%;"
                        @ps-y-reach-end="loadMoreEmployees"
                      >
                        <v-row no-gutters v-for="(row, index) in rows" :key="'row' + index" class="mt-1" style="overflow: hidden">
                          <v-col
                            class="py-1 d-flex justify-center pa-1"
                            v-for="(employee, index) in row"
                            :key="'col' + index"
                            md="6"
                            lg="6"
                            sm="6"
                            xl="6"
                          >
                              <v-card  
                                @click="!existedInSelection(employee)?onCommandHandler({
                                
                                tagType:'employee',
                                value: employee, 
                                command:'addEmployee'}):''"
                                flat style="width:100%" :style="existedInSelection(employee)?'background-color:#d4e8e7':''"
                              >
                                <v-card-text class="" style="width:100%">
                                  <div style="font-size:14px; width:100%" class="d-flex flex-column">
                                    <div class="d-flex">
                                      <div class="text-start">
                                        <!-- <v-icon class="rounded-pill teal white--text" size="16">mdi-account</v-icon> -->
                                        <v-avatar v-if="employee.avatar" size="25px">
                                          <img :src="employee.avatar" width="25px" />
                                        </v-avatar>
                                        <v-icon v-else class="rounded-pill teal white--text" small size="16">
                                          {{displayIcon(employee)}}
                                        </v-icon>
                                        <span class="ml-3">{{employee.employeeWorkInfo.displayName}}</span>
                                      </div>
                                      <div class="ml-auto grey--text" style="font-size:12px; transform: translate(5px,-12px)">{{employee.email}}</div>
                                    </div>
                                    <div class="mt-2 d-flex flex-wrap" style="width:100%">
                                      <div v-for="department in employee.departments" :key="department.id">
                                        <v-chip
                                            label
                                            x-small
                                            class="mr-1 mb-1 teal white--text pa-2 pt-3"
                                            style="max-width:180px;"
                                          >
                                          <span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ department.name }}</span>
                                        </v-chip>
                                      </div>
                                    </div>
                                  </div>
                                </v-card-text>
                              </v-card>
                          </v-col>
                        </v-row>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </v-window-item>
                <v-window-item
                  transition="none"
                  reverse-transition="none"
                  style="display:flex;"
                  :class="{ 'd-flex': tabIndex === TAB_DEPARTMENT }"
                  class="fill-height flex-grow-1 flex-column grey lighten-4 pa-2 align-start"
                >
                  <div
                    v-if="tabIndex === TAB_DEPARTMENT"
                    class="flex-grow-1 d-flex flex-row fill-height w-100"
                  >
                    <div
                      v-if="loading"
                      class="flex-grow-1 d-flex flex-column w-100 fill-height justify-center align-center"
                    >
                      <font-awesome-icon
                        icon="spinner"
                        class="fa-3x fa-spin"
                      ></font-awesome-icon>
                    </div>
                    <div
                      v-else
                      class="flex-grow-1 d-flex flex-row fill-height w-100"
                    >
                      <div
                        class="mx-2 department-list flex-fixed-height fill-height"
                        style="width:80%"
                      >
                        <perfect-scrollbar class="fill-height mr-1 w-100" style="width:100%">
                          <v-btn
                            @click="onCommandHandler({
                              command: 'selectDepartment', 
                              tagType: 'department',
                              value: department.id
                            })"
                            v-for="(department, index) in departments"
                            :key="index"
                            class="pa-0 mt-2 elevation-0"
                            style="width:100%"
                          >
                            <v-card
                              flat
                              style="width:100%"
                              :style="selectedDepartmentId === department.id?'background-color:#d4e8e7':''"
                            >
                              <v-card-text class="py-1 d-flex justify-start align-center flex-wrap popover-empolyee-selector-text" style="width:100%">
                                <v-layout align-start justify-space-between>
                                  <div>
                                    <span style="">{{department.fullName}}</span>
                                    <v-chip class="ml-3">{{department.employeeCount}}</v-chip>
                                  </div>
                                  <div v-if="enableBranchSelect">
                                    <v-btn
                                      text
                                      small
                                      color="primary"
                                      @click="selectDepartmentAll(department)"
                                    >{{ $t('workflow.btn.selectAllOne') }}</v-btn>
                                  </div>
                                </v-layout>
                              </v-card-text>

                            </v-card>
                          </v-btn>
                        </perfect-scrollbar>
                      </div>
                      <div
                        class="department-employee-list d-flex flex-column fill-height py-2"
                        style="width:200px;"
                      >
                        <div style="height:0;min-height:100%;width:100%;">
                          <perfect-scrollbar
                            class="fill-height"
                            style="width:100%;"
                            @ps-y-reach-end="loadMoreEmployees"
                          >
                            <div>
                              <person-tag
                                v-for="(employee, index) in employeeOptions"
                                :key="index"
                                :name="employee.employeeWorkInfo.displayName"
                                :disabled="existedInSelection(employee)"
                                color="primary"
                                class="w-100"
                                :value="employee"
                                tagType="employee"
                                @onCommand="onCommandHandler"
                                clickCommand="addEmployee"
                              ></person-tag>
                            </div>
                          </perfect-scrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-window-item>
                <v-window-item
                v-if="supportJobTitleSelection"
                  transition="none"
                  reverse-transition="none"
                  style="display:flex;"
                  :class="{ 'd-flex': tabIndex === TAB_JOBTITLE }"
                  class="fill-height flex-grow-1 flex-column grey lighten-4 pa-2 align-start"
                >
                  <div
                    v-if="tabIndex === TAB_JOBTITLE"
                    class="flex-grow-1 d-flex flex-row fill-height w-100"
                  >
                    <div
                      v-if="loading"
                      class="flex-grow-1 d-flex flex-column w-100 fill-height justify-center align-center"
                    >
                      <font-awesome-icon
                        icon="spinner"
                        class="fa-3x fa-spin"
                      ></font-awesome-icon>
                    </div>
                    <div
                      v-else
                      class="flex-grow-1 d-flex flex-row fill-height w-100"
                    >
                      <div
                        class="mx-2 department-list flex-fixed-height fill-height"
                        style="width:80%"
                      >
                        <perfect-scrollbar class="fill-height mr-1 w-100" style="width:100%">
                          <v-btn
                            @click="onCommandHandler({
                              command: 'selectJobTitle', 
                              tagType: 'jobTitle',
                              value: jobTitle.id
                            })"
                            v-for="(jobTitle, index) in jobTitles"
                            :key="index"
                            class="v-for-plainDepartments pa-0 mt-2 elevation-0"
                            style="width:100%"
                          >
                            <v-card
                              flat
                              style="width:100%"
                              :style="existedInSelection(jobTitle)?'background-color:#d4e8e7':''"
                            >
                              <v-card-text class="py-1 d-flex justify-start align-center flex-wrap" style="width:100%">
                                <v-layout align-start justify-space-between>
                                  <div>
                                    <span style="">{{jobTitle.label}}</span>
                                    <v-chip class="ml-3">{{jobTitle.jobCount}}</v-chip>
                                  </div>
                                  <div v-if="enableBranchSelect">
                                    <v-btn
                                      small
                                      text
                                      color="primary"
                                      @click="selectJobTitleAll(jobTitle)"
                                    >{{ $t('workflow.btn.selectAllOne') }}</v-btn>
                                  </div>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </v-btn>
                        </perfect-scrollbar>
                      </div>
                      <div
                        class="department-employee-list d-flex flex-column fill-height py-2"
                        style="width:200px;"
                      >
                        <div style="height:0;min-height:100%;width:100%;">
                          <perfect-scrollbar
                            class="fill-height"
                            style="width:100%;"
                            @ps-y-reach-end="loadMoreEmployees"
                          >
                            <div>
                              <person-tag
                                v-for="(employee, index) in employeeOptions"
                                :key="index"
                                :name="employee.employeeWorkInfo.displayName"
                                :disabled="existedInSelection(employee)"
                                color="primary"
                                class="w-100"
                                :value="employee"
                                tagType="employee"
                                @onCommand="onCommandHandler"
                                clickCommand="addEmployee"
                              ></person-tag>
                            </div>
                          </perfect-scrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-window-item>
              </v-window>
              
            </v-card>
            <v-card
              class="fill-height d-flex flex-column"
              flat
              tile
              style="min-width:200px;width:200px;"
            >
              <!--------------->
              <!-- SELECTED --->
              <!-- ------------>
              <v-card-subtitle height="16" class="pa-0" style="font-size:13px">
                {{
                  selectedEmployees.length > 0
                    ? $t("settings.noSelected", { number: selectedEmployees.length })
                    : $t("settings.noSelection")
                }}
              </v-card-subtitle>
              <v-card-text class="flex-grow-1 pa-2 primary">
                <div
                  id="allEmmployees"
                  style="height:0;min-height:100%;width:100%;"
                >
                  <perfect-scrollbar
                    class="fill-height"
                    style="width:100%;"
                  >
                    <div>
                      <v-hover
                        v-for="(employee, index) in selectedEmployees"
                        :key="index"
                        v-slot="{hover}"
                      >
                        <v-card
                          flat
                          class="mt-1"
                        >
                          <v-btn 
                            v-show="hover"
                            icon
                            fab 
                            x-small 
                            class="error white--text" 
                            style="height:20px; width:20px; position:absolute; right:5px; top:5px"
                            @click="onCommandHandler({command:'removeEmployee', tag:'employee',value:employee._id})"
                          >
                            <v-icon style="" small>
                              mdi-close
                            </v-icon>
                          </v-btn>
                          <v-card-text class="pa-1 px-2">
                            <v-layout>
                              <div>
                                <v-avatar v-if="employee.avatar" size="25px">
                                  <img :src="employee.avatar" width="25px" />
                                </v-avatar>
                                <v-icon v-else class="rounded-pill teal white--text" small size="16">
                                  {{displayIcon(employee)}}
                                </v-icon>
                              </div>
                              <div style="font-size: 12px" class="ml-2">
                                <b>{{displayName(employee)}}</b>
                                <div v-if="enableBranchSelect">
                                  {{displayNameType(employee)}}
                                </div>
                              </div>
                            </v-layout>
                          </v-card-text>
                        </v-card>
                      </v-hover>

                    </div>
                  </perfect-scrollbar>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <dialog-actions 
          :buttons="['cancel', 'ok']"
          @onCommand="onCommandHandler">
        </dialog-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isArray, concat } from 'lodash';
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import personTag from "@/components/dialogs/comps/PersonTag";

import SettingUtils from '@/pages/admin/workflow/utils/setting';
import mixin from "@/components/dialogButtons/mixin";

export default {
  name: 'dialogButtonSelectEmployees',
  mixins: [mixin],
  components: {
    dialogActions,
    dialogHeader,
    personTag,
  },
  computed: {
    dialogTitle () {
      return this.title ? this.title : this.$t('general.selectMember')
    },
    showingDialog: {
      get(){
        return this.value
      },
      set(val){
        this.$emit("input", val)
      }
    },
    activeTeam() {
      return this.$store.getters.activeTeam;
    },

    teamSelection() {
      const vm = this;

      // exclude terminated teams
      const teams = vm.allTeams;
      const result = teams.map(teamInfo=>({
                    _id: teamInfo._id,
                    text: teamInfo.name,
            value: teamInfo._id,
      }))

      if (result.length > 0) {
        if (vm.selectedTeamId === "") {
          // if teamid is specified, use it
          if (vm.teamId) {
            vm.selectedTeamId = vm.teamId
          } else {
            vm.selectedTeamId = result.find(team=>team.value === vm.activeTeam._id) ? vm.activeTeam._id:result[0].value;
          }                
        }
        const validTeamIds = result.map(team => team._id)
        if (!validTeamIds.includes(vm.selectedTeamId)) {
          // vm.selectedTeamId = result[0]._id
        }
      }
      return result;
    },
    selectedDepartment() {
      return this.departments.find(
        (dept) => dept.id === this.selectedDepartmentId
      );
    },
    rows() {
      let result = [];
      let numOfRows = Math.ceil(this.employeeOptions.length / 2);
      for (let i = 0; i < numOfRows; i++) {
        result.push(
          this.employeeOptions.slice(i * 2, i * 2 + 2)
        );
      }
      return result;
    },
    selectedTeamName(){
      if(this.selectedTeamId){
        const result = this.teamSelection.find(team=>team._id===this.selectedTeamId)
        if(result)
          return result.text
      }
      return ""
    }
  },
  data() {
    const { selectedValue } = this;
    let selectedEmployees = selectedValue;
    if (!isArray && selectedValue) {
      selectedEmployees = [ selectedValue ];
    }
    const selectionTypes = [
      // {labelTag: 'settings.userRoles', value: 'role', tagType: 'mdi-account-multiple'},
      {
        labelTag: "settings.staff",
        value: "employee",
        tagType: "mdi-account",
      },{
        labelTag: "settings.departments",
        value: "department",
        tagType: "mdi-account-group-outline",
      },
    ];
    if (this.supportJobTitleSelection) {
      selectionTypes.push({
        labelTag: "settings.jobTitle",
        value: "jobTitle",
        tagType: "mdi-briefcase",
      });
    }
    return {
      searchText: "",
      allTeams: [],
      loading: false,
      jobTitles: [],
      //teams: [],
      selectedTeamId: "",
      selectedDepartmentId: "",
      selectedJobTitleId: "",
      selectedEmployees: selectedEmployees || [],

      tabIndex: 0,
      selectionTypes,
      // TAB_ROLE: 0,
      TAB_EMPLOYEE: 0,
      TAB_DEPARTMENT: 1,
      TAB_JOBTITLE: 2,

      //roles: [],
      //employees: [],
      departments: [],
      employeeOptions: [],
      jobTitleOptions: [],

      limit: 10,
      offset: 0,
      hasNextPage: false,
      row: null,
      callback: null
    };
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    supportJobTitleSelection: {
      type: Boolean,
      default: false,
    },
    enableBranchSelect: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    },
    cssSelect:{
      type: String,
      default: undefined
    },
    addButton: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnCommand: String,
    filter: Array,
    selectedValue: {
      type : Array,
      default:()=>[]
    },
    value: {
      type: Boolean,
      default: false
    },
    attach: {
      type: String,
      default: undefined
    },
    teamId: {
      type: String,
      default: ''
    },
    multipleTeams:{
      type: Boolean,
      default: true
    }
  },
  watch: {
    showingDialog: {
      immediate: true,
      handler (newVal) {
        const vm = this
        if (vm.teamId) {
          vm.loadTeamInfo(vm.teamId)
        } else {
          vm.allTeams = (vm.$store.getters.user.isAdmin
            || vm.$store.getters.activeTeam.allowDeployment)
            ? vm.$store.getters.allTeams
            : vm.$store.getters.user.teams
        }
      }
    },
    tabIndex: async function() {
      const vm = this;
      await vm.refreshSelection();
    },
    selectedTeamId: function(newVal) {
      const vm = this;
      const index = this.teamSelection.findIndex(
        (team) => team.value === newVal
      );
      if (index > -1) {
        vm.refreshSelection();
      }
    },

    selectedDepartmentId: function(newVal) {
      this.offset = 0;
      this.employeeOptions = [];
      if (typeof newVal !== "number") {
        return;
      } else {
        this.fetchEmployees();
      }
    },
    selectedJobTitleId: function() {
      this.offset = 0;
      this.employeeOptions = [];
      this.fetchEmployees();
    },
    selectedValue: function(newVal){
      if (newVal) {
        this.selectedEmployees = [...newVal]
      } else {
        this.selectedEmployees = []
      }
    },
    // selectedEmployees: function(newVal) {
    //   console.log('>>>> ', newVal);
    // }
  },
  methods: {
    // TODO: revamp
    selectTeamAll() {
      const selected = SettingUtils.selectFromPickingListByIndex(
        this.teamSelection,
        this.selectedEmployees,
        '_id',
        i => i._id === this.selectedTeamId,
      );
      if (selected) {
        this.addEmployee(selected, 'team');
      }
    },
    selectDepartmentAll(department) {
      const selected = SettingUtils.selectFromPickingListByIndex(
        this.departments,
        this.selectedEmployees,
        'id',
        i => i.id === department.id,
      );
      if (selected) {
        this.addEmployee(selected, 'department');
      }
    },
    selectJobTitleAll(jobTitle) {
      const selected = SettingUtils.selectFromPickingListByIndex(
        this.jobTitles,
        this.selectedEmployees,
        '_id',
        i => i._id === jobTitle.id,
      );
      if (selected) {
        this.addEmployee(selected, 'jobTitle');
      }
    },
    displayIcon(user){
      return SettingUtils.userPopupDisplayIcon(user);
    },
    displayName(user){

      return SettingUtils.userPopupDisplayName(user);
    },
    displayNameType(user){

      return SettingUtils.userPopupDisplayNameType(user, this.$t('workflow.label'));
    },
    
    clearSearch() {
      this.$nextTick().then(() => {
        this.searchText = "";
        this.searchEmployees();
      });
    },
    loadMoreEmployees() {
      if (this.hasNextPage) {
        this.offset += this.limit;
        this.fetchEmployees();
      }
    },
    getAllDeptChildrens(deptArr) {
      let result = [];

      deptArr.forEach((id) => {
        const child = this.departments.find(dept => dept.id === id);
        if (child){
          result.push(child.id);
          if (child.children.length > 0)
            result = result.concat(this.getAllDeptChildrens(child.children))
        }
      });
      return result;
    },
    fetchEmployees(isSearch) {
      const data = {
        teamId: this.teamId ? this.teamId : this.selectedTeamId,
        limit: this.limit,
        offset:this.offset,
        search : this.searchText.trim(),
        filter: this.filter
      }
      if (this.tabIndex === this.TAB_DEPARTMENT) {
        const dept = this.departments.find(
          (dept) => dept.id === this.selectedDepartmentId
        );
        if (dept) {
        let result = [dept.id];
          result = result.concat(this.getAllDeptChildrens(dept.children));
          Object.assign(data, {
            deptIds: result
          })
        }
      } else if (this.tabIndex === this.TAB_JOBTITLE) {
        const titleIds = concat([], this.selectedJobTitleId); // must array
        Object.assign(data, {
          titleIds,
        })
      }
      // console.log('fetchEmployees: data: ', data)

      const postData = {
        urlCommand: '/employees',
        data
      }

      this.$store.dispatch('AUTH_POST', postData).then(res => {
        this.employeeOptions = isSearch ? res.docs:this.employeeOptions.concat(res.docs);
        this.hasNextPage = res.hasNextPage;
      })
    },
    searchEmployees() {
      this.offset = 0;
      this.employeeOptions = [];
      this.fetchEmployees(true);
    },
    existedInSelection(employee) {
      return (
        this.selectedEmployees.findIndex(
          (selected) => selected._id === employee.id || selected._id === employee._id
        ) > -1
      );
    },
    addEmployee(target, type='employee') {
      const vm = this;
      let data = {
        _id: target._id,
        id: target.id,
        teamId: this.selectedTeamId,
        type,
        avatar: target.avatar,
      }
      switch(type) {
        case 'jobTitle':
          data = {
            ...data,
            name: target.label,
            titleName: target.label,
          }
          break;
        
        case 'team':
          data = {
            ...data,
            name: target.text,
            teamName: target.text,
          }
          break;
        case 'department':
          data = {
            ...data,
            name: target.label,
            teamName: target.label,
          }
          break;
        default:
          data = {
            ...data,
            name: target.employeeWorkInfo.displayName,
            employeeName: target.employeeWorkInfo.displayName,
          }
      }
      //console.log('addEmployee :: employeeId = ' + employeeId)
      if (vm.multiple) {
        vm.selectedEmployees.push(data);
      } else {
        vm.selectedEmployees = [data];
      }
    },


     refreshSelection() {
      const vm = this;
      vm.loading = true;
      //reset data
      vm.employeeOptions = [];
      vm.selectedDepartmentId = "";
      vm.selectedJobTitleId = "";
      vm.offset = 0;
      vm.hasNextPage = false
      vm.searchText = ''
      switch (vm.tabIndex) {
        // case vm.TAB_ROLE:
        //   console.log('refreshSelection :: TAB_ROLE')
        //   vm.loadRoles()
        //   break
        case vm.TAB_JOBTITLE:
          // console.log('refreshSelection :: vm.TAB_JOBTITLE')
           vm.loadJobTitle();

          break;
        case vm.TAB_DEPARTMENT:
          // console.log('refreshSelection :: TAB_DEPARTMENT')
           vm.loadDepartments();

          break;
        case vm.TAB_EMPLOYEE:
          // console.log('refreshSelection :: TAB_EMPLOYEE')
          // vm.loadEmployees()
           vm.fetchEmployees();

          break;
      }
      vm.loading = false;
    },
    // TODO: revamp it, just follow your current structure
    loadJobTitle() {
      const vm = this;

       vm
        .loadData("/employees/jobTitle", { teamId: vm.selectedTeamId })
        .then((response) => {
          vm.jobTitles = response;
          if (response.length > 0){
             vm.selectedJobTitleId = vm.jobTitles[0].id;
          }
        });
    },
    loadDepartments() {
      const vm = this;
       vm
        .loadData("/departments/departments", { teamId: vm.selectedTeamId })
        .then((response) => {
          vm.departments = response;
          if (response.length > 0){
             vm.selectedDepartmentId = vm.departments[0].id
          }
         
        });
    },
    loadTeamInfo () {
      const vm = this
      vm.loadData('/teams/getByTeamId/' + vm.teamId).then(
        response => {
          vm.allTeams = [response]
        }
      )
    },
    onCommandHandler(payload) {
      const vm = this;
      // console.log(
      //   "DialogButtonSelectEmployees :: onCommandHandler :: payload: ",
      //   payload
      // );
      switch (payload.command) {
        case "addEmployee":
          vm.addEmployee(payload.value);
          break;
        case "removeEmployee":
          const index = vm.selectedEmployees.findIndex(
            (employee) => employee._id === payload.value
          );
          if (index >= 0) {
            vm.selectedEmployees.splice(index, 1);
          }
          break;
        case "selectDepartment":
          vm.selectedDepartmentId = payload.value;
          // console.log('onCommandHandler :: selectDepartment :: payload: ', payload)
          break;
        case "selectJobTitle":
          vm.selectedJobTitleId = payload.value;
          // console.log('onCommandHandler :: selectJobTitle :: payload: ', payload)
          break;
        case "ok":
          vm.onSubmit();
          break;
        // vm.$emit("input", vm.selectedEmployees);
        case "cancel":
          vm.selectedEmployees = this.selectedValue ?
            [...this.selectedValue] : 
            []
          vm.refreshSelection()
          // console.log('cancel: vm.showingDialog => false')
          vm.showingDialog = false;
          break;
        case "addRole":
          break;
      }
    },
    onSubmit() {
      let vm = this
      if (this.callback && typeof this.callback === 'function') {
        this.callback(this.selectedEmployees, vm.row)
      } else {
        this.$emit("submit", this.selectedEmployees)
      }
      this.showingDialog = false;
    },
  }
};
</script>

<style scoped lang="scss">
  .ee-dialog-text-field .v-input__icon .v-icon{
    font-size:18px !important;
    
  }
  .ee-dialog-text-field .v-input__prepend-inner{
    margin: 0 !important;
    margin-right: 5px !important;
    /* margin-bottom: 3px !important; */
    /* background-color: info; */
    /* padding: 2px 10px !important; */
  }

  .ee-dialog-text-field .v-input__control{
    min-height: 0px !important;
  }
  .ee-dialog-text-field{
    border-radius: 0 !important;
  }

  .popover-empolyee-selector-text {

    font-size: 12px !important;
    .v-btn.v-size--default {
      .v-btn__content {
      
        font-size: 11px !important;
      }
    }
  }
  .popover-empolyee-selector-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > button {
      display: flex;
      flex: 1;
    }
  }

</style>