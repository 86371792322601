<template>
    <v-expansion-panel>
      <v-expansion-panel-header dense class="pl-3 py-1">
        {{ widgetGroupTitle }}
        <template v-slot:actions>
          <v-icon>mdi-menu-down</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-2">
        <widget-panel-item
            v-for="widget in orderBy(userWidgets, 'order')"
            :key="widget._id"
            :selected="selected"
            :command="command"
            @onCommand="onCommandHandler"
            :itemsPerRow="itemsPerRow"
            :widgetGroup="widgetGroup"
            :widget="widget"></widget-panel-item>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import widgetPanelItem from './WidgetPanelItem'
  import Vue2Filters from 'vue2-filters'

  export default {
    name: 'widgetPanelGroup',
    mixins: [Vue2Filters.mixin],
    components: {
      widgetPanelItem
    },
    props: {
      command: String,
      widgetGroup: null,
      selected: null,
      itemsPerRow: {
        type: Number,
        default: 2
      }
    },
    computed: {
      widgetGroupTitle () {
        const vm = this
        let title = vm.widgetGroup.title
        if (vm.widgetGroup.titleTag) {
          title = vm.$t('widgets.' + vm.widgetGroup.titleTag)
        }
        return title
      },
      userWidgets () {
        return this.widgetGroup.widgets.filter(widget => !widget.isSystem && !widget.disabled)
      }
    },
    methods: {
      onCommandHandler (payload) {
        this.$emit('onCommand', payload)
      }
    }


  }
</script>

<style lang="scss">
  .widget-panel .v-expansion-panel--active button {
    min-height: 24px !important;
  }

  .widget-panel .v-expansion-panel-header {
    min-height: 24px !important;

  }

  .v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding-left: 5px;
    padding-right: 5px;
  }
</style>
