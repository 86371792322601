<template>
  <v-row no-gutters class="underlined" style="min-height:320px">
    <v-col cols="6">
      <slot name="content">
        <v-card flat class="fill-height">
          <v-card-title>{{ section.label }}</v-card-title>
          <v-card-text class="pt-0">
            <div class="pa-2 grey--text text--darken-2">
              {{ section.description }}
            </div>
            <slot name="prepend-table"></slot>
            <v-simple-table
              fixed-header
              v-if="section.tableHeaders"
              style="width:100%"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="header in section.tableHeaders"
                      :key="header.value"
                      class="text-left"
                      :style="`width:${header.width}`"
                    >
                      {{ header.label }}
                    </th>
                    <th v-if="section.hasActions" style="width:8%">
                      {{ $t("drawer.action") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(datum, index) in section.tableData" :key="datum._id">
                    <td
                      v-for="header in section.tableHeaders"
                      :key="header.value"
                      class="text-body-2"
                      style="overflow-wrap:anywhere"
                      v-html="getDisplayValue(datum[header.value])"
                    >

                    </td>
                    <td v-if="section.hasActions">
                      <v-menu v-if="!datum.isDefault">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon small>
                            <v-icon small>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <slot name="table-actions" :row="datum" :index="index"></slot>

                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <slot name="append-content"> </slot>
          </v-card-text>
        </v-card>
      </slot>
    </v-col>
    <v-col cols="6">
      <div
        v-if="!jsonData && !section.requestJson && !section.responseJson"
        class="empty-json-wrapper fill-height"
      ></div>
      <div v-else class="empty-json-wrapper grey--text fill-height">
        <json-viewer
          v-if="jsonData"
          :value="jsonData"
          style="min-height:100%"
          theme="json-dark-theme"
          copyable
          :expanded="true"
          expand-depth="3"
        ></json-viewer>
        <div v-if="section.requestJson">
          <span class="px-2">Request</span>
          <json-viewer
            :value="section.requestJson"
            style="min-height:100%"
            theme="json-dark-theme"
            copyable
            :expanded="true"
            expand-depth="3"
          ></json-viewer>
        </div>
        <div v-if="section.responseJson">
          <span class="px-2">Response</span>
          <json-viewer
            :value="section.responseJson"
            style="min-height:100%"
            theme="json-dark-theme"
            
            :expanded="true"
            expand-depth="3"
          ></json-viewer>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import JsonViewer from "vue-json-viewer";

export default {
  components: {
    JsonViewer,
  },
  props: {
    jsonData: { type: Object, default: null },

    section: { type: Object, default: () => ({}) },
  },
  methods: {
    getDisplayValue(val){
      if (typeof val === 'boolean'){
        return val? '&#10003;': 'x'
      } else return val
    }
  }
};
</script>
<style lang="scss" scoped>
.underlined {
  border-bottom: thin solid #eee;
}
.empty-json-wrapper {
  background: #111111;
}
</style>
<style lang="scss">
.json-dark-theme {
  background: #111111;
  white-space: nowrap;
  color: #eee;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: rgba(238, 238, 238, 0.493);
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #a7d7fa;
    &.copied {
      color: #a7d7fa;
      opacity: 0.8;
    }
  }
  .jv-key {
    color: #eee;
  }
  .jv-item {
    &.jv-array {
      color: #eee;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-number-float {
      color: #fc1e70;
    }
    &.jv-number-integer {
      color: #fc1e70;
    }
    &.jv-object {
      color: #eee;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
</style>
