<template>
  <div class="form-action-button-set"
         @mouseover="showingDropDown=true"
          style="display:inline-block:background-color:red;position:relative;">
    <form-action-button-item
    class="ml-3"
      v-if="buttonSet.length===1"  
      :button="buttonSet[0]"
      :disabled="disabled"
      @onCommand="onCommandHandler"></form-action-button-item>
    <div v-else class="form-action-dropdown mt-1 pa-0"
      :class="{'dropdown-active':showingDropDown}"
      style="position:relative;width:45px;height:35px;margin-right:-4px;">
        <div
          @mouseover="showingDropDown=true"
          @mouseleave="showingDropDown=false"   
          style="position:relative;width:100%;">
        <form-action-button-item
          v-for="(button,index) in showButtons"
          :key="index"
          buttonClass="mb-1"
          :button="button"
          :disabled="disabled"
          @onCommand="onCommandHandler"></form-action-button-item>
        </div>
    </div>
    <!-- <v-hover v-else v-slot="{ hover }">
      <div class="d-flex flex-column red">
        <div v-for="(button,index) in showButtons" :key="index">
          <form-action-button-item
            v-show="hover"
            buttonClass="mb-1"
            :button="button"
            @onCommand="onCommandHandler">
          </form-action-button-item>
        </div>
      </div>
    </v-hover> -->
  </div>
</template>

<script>
import formActionButtonItem from './FormActionButtonItem'

export default {
  name: 'formActionButtonSet',
  components: {
    formActionButtonItem
  },
  data () {
    return {
      // rearrangedButtons: [],
      showingDropDown: false,
      // showButtons: []
    }
  },
  props: {
    buttonSet: Array,
    disabled: Boolean
  },
  computed: {
    // showButtons () {
    //   return this.buttonSet
    // }
    // showButtons () {
    //   const vm = this
    //   if (vm.rearrangedButtons.length === 0)
    //     vm.updateButtons(vm.buttonSet)
    //   return vm.rearrangedButtons
    // },
    // button () {
    //   return this.showButtons[0]
    // },
    // altButtons () {
    //   return this.showButtons.filter(btn => btn.command !== this.button.command)
    // }
    showButtons () {
      const vm = this
      var result = []
       for (let i = 0; i < vm.buttonSet.length; i++) {
         result.push(vm.buttonSet[i])
      }
      return result
    }
  },
  // mounted () {
  //    const vm = this
  // //   console.log('mounrws')    
  //    vm.updateButtons(vm.buttonSet)
  // },
  // watch: {
  //   buttonSet: function(newVal) {
  //     this.updateButtons(newVal)
  //   }
  // },
  methods: {
    // updateButtons (buttons) {
    //   const vm = this
    //   var result = []
    //   vm.showButtons = vm.showButtons.splice(0)
    //   console.log('updateButtons: vm.showButtons: ', vm.showButtons)
    //   for (let i = 0; i < buttons.length; i++) {
    //     vm.showButtons.push(buttons[i])
    //   }
    //   vm.showButtons
    // },

    // updateButtons (buttonSet) {
    //   const vm = this
    //   vm.rearrangedButtons = []
    //   console.log('updateButtons. buttonSet.length = ' + buttonSet.length)
    //   for (let i = 0; i < buttonSet.length; i++) {
    //     vm.rearrangedButtons.push(buttonSet[i])
    //     // vm.$set(vm.rearrangedButtons, i, buttonSet[i])
    //   }
    //   console.log('updateButtons: vm.rearrangeButtons')
    // },
    onCommandHandler (payload) {
      const vm = this
      // if (vm.buttonSet.length > 1) {
      //   console.log('FormActionButtonSet :: payload: ', payload)
      //   console.log('FormActionButtonSet :: command list: ' + vm.rearrangedButtons.map(btn => btn.command).join(','))
      //   var updated = JSON.parse(JSON.stringify(vm.rearrangedButtons))


      //   const activeButtonIndex = updated.findIndex(b => b.command === payload.command)
      //   console.log('FormActionButtonSet :: activeButtonIndex = ' + activeButtonIndex)
      //   if (activeButtonIndex > 0) {
      //     const activeButton = updated.splice(activeButtonIndex, 1)

      //     console.log('activeButton: ', activeButton[0])
      //     console.log('buttonSet: ', vm.buttonSet)
      //     updated.unshift(activeButton[0])

      //     // updated.push(activeButton)
      //     // updated = updated.concat(vm.rearrangedButtons)
      //     vm.updateButtons(updated)
      //     console.log('after unshift: buttonSet: ', vm.buttonSet)
      //     console.log('after unshift: rearrangedButtons: ', vm.rearrangedButtons)
      //   }
      // }
      vm.$emit('onCommand', payload)
    }
  }
}
</script>

<style>

/* .form-action-button-set .form-action-dropdown {
  display: none;
} */
/* .form-action-button-set {
  margin-left: -4px;
} */
.form-action-button-set:hover .form-action-dropdown,
.form-action-button-set .form-action-dropdown:hover {
  display: block;
}
.form-action-dropdown {
  overflow-y: hidden;
}
.form-action-dropdown.dropdown-active {
  overflow-y: visible;
}
</style>