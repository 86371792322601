<template>
  <v-menu
    :rounded="true"
    offset-y>
    <template v-slot:activator="{attrs, on}">
      <div
        v-bind="attrs"
        v-on="on">
        <v-btn
          outlined
          class="w-100 justify-start text-body-2"
          :color="hasError ? 'error' : ''"
          small>
          {{ selectedItem ? selectedItem[label] : $t('inputPanel.pleaseSelect') }}
        </v-btn>
        <error-message
          v-if="hasError"
          :message="errorMessage"></error-message>
      </div>
    </template>
    <v-list subheader>
      <v-list-item v-for="(item, index) in items"
        @click="selectItem(item)"
        :key="index">
        {{ item[label] }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import errorMessage from './ErrorMessage'

export default {
  name: 'fieldSelectionMenu',
  components: {
    errorMessage
  },
  model: {
    prop: 'selectedId',
    event: 'input'
  },
  data () {
    return {
    }
  },
  props: {
    items: Array,
    selectedId: String,
    hasError: {
      type: Boolean,
      default: false
    },
    label: {
      type:String,
      default:"label"
    }
  },
  computed: {
    selectedItem () {
      const item = (this.items || []).find(item=> item._id === this.selectedId)

      return item
    },
    errorMessage () {
      return this.hasError ? this.$t('messages.thisFieldIsRequired') : ''
    }
  },
  methods: {   
    validate () {
      this.hasError = this.selectedId === ''
      return !this.hasError
    },
    resetValidation () {
      this.hasError = false
    },
    selectItem (item) {
      this.$emit('input', item._id)
      this.$nextTick(() => {
        this.validate()
      })
      
    },
  }
}
</script>