<template>
  <div>
    <label>{{ label }}</label>
   <!-- <pulldown-department-selection
           v-model="propertyValue"
           :toggleType="toggleType">
   </pulldown-department-selection> -->
   <div class="pa-1 v-text-field--outlined w-100"
         @click="showingDialog=true"
          style="border: 1px solid darkgray;min-height: 50px;">
          <v-chip
         v-for="(dept, index) in propertyValue"
          :close="true"
          @click:close="onCloseClicked(index)"
          small
          :key="dept._id"
          class="mr-1 mb-1"
        >
          <span>{{ dept.label }}</span>
        </v-chip>
   <DepartmentsDialog
      v-if="showingDialog"
      v-model="showingDialog"
      @confirm="propertyValue = $event"
      :selectedDepts="propertyValue"
      :isMultiple="toggleType !== 'single'"
    />
   </div>
    <!--<pulldown-selection-->
      <!--:items="departments"-->
      <!--item-text="label"-->
      <!--item-value="id"-->
      <!--:toggleType="fieldType"-->
      <!--v-model="propertyValue">-->
    <!--</pulldown-selection>-->
  </div>
</template>

<script>
  import mixin from './mixin'
import DepartmentsDialog from "@/components/dialogs/DepartmentsDialog";
  //import pulldownDepartmentSelection from '@/components/inputWidgets/comps/PulldownDepartmentSelection'
  // import pulldownSelection from '@/components/InputWidgets/comps/PulldownSelection'

  export default {
    mixins: [mixin],
    components: {
      DepartmentsDialog
    },
    data(){
      return {
        showingDialog: false
      }
    },
    computed: {
      toggleType () {
        return this.fieldType
      },
      // propertyValue: {
      //   get () {
      //     const vm = this
      //     var result = []
      //     if (vm.isMultiple) {
      //       if (Array.isArray(vm.propertyValue)) {
      //         result = vm.propertyValue
      //       } else {
      //         result = (vm.propertyValue && (vm.propertyValue !== '')) ?
      //           [vm.propertyValue] :
      //           []
      //       }
      //     } else {
      //       console.log('computed(propertyValue) :: vm.propertyValue: ', vm.propertyValue)
      //       result = vm.propertyValue.length > 0 ? [vm.propertyValue[0]] : []
      //     }
      //     return result
      //   },
      //   set (val) {
      //     console.log('WitDefaultMember :: val.length = ' + val.length)
      //     this.propertyValue = val
      //     // this.$emit('input', val)
      //   }
      // },
      fieldType () {
        return this.getPropertyValue('fieldType', 'single')
      }
    },
    methods: {
      onCommandHandler (payload) {
        const vm = this
        switch (payload.command) {
          case 'removeMemberByIndex':
            vm.removeMemberByIndex(payload.index)
            break
        }
      },

      onCloseClicked(index){
      this.propertyValue.splice(index, 1)
    },

      removeMember (employeeId) {
        // console.log('removeMember : employeeId = ' + employeeId)
        const vm = this
        const index = vm.propertyValue.findIndex(val => val._id === employeeId)
        if (index >= 0) {
          var newValue = JSON.parse(JSON.stringify(vm.propertyValue))
          newValue.splice(index, 1)
          vm.propertyValue = newValue
        }
      }
    }
  }
</script>

<style>
</style>
