<template>
  <div class="workflow-setting-form-withDelegated">
    <div v-if="fetched" class="workflow-setting-form-withDelegated-switch">
      <v-layout>
        <v-select
          v-if="shouldManageRelation"
          v-model="relationAction"
          :items="adjustedRelationManageList"
          @change="onRelationActionChange"
          hide-details
          dense
          chips
          class="v-w-forminput-relation-select"
          solo
        ></v-select>
        <v-spacer/>
        <v-checkbox
          v-if="canSwitch && !shouldHide"
          v-model="dependency"
          @change="onDependency"
          :label="$t('workflow.modules.delegated.label.flowOuputDependency')"
          hide-details
          dense
        />
      </v-layout>
    </div>
    <FormElement v-if="fetched" :id="fieldInfo._id">
      <div v-if="!dependency">
        <slot :dependency="dependency" />
      </div>
      <div v-else class="workflow-setting-form-withDelegated-denp">
        <div class="w-input-widget-container-title">
          {{ fieldInfo.label }}
        </div>
        <ComboBox
          :value="normalize(value)"
          :options="list"
          :onlyPrefix="true"
          :dependsOnType="fieldType"
          :disabledOnDependencies="true"
          :onlySuffix="true"
          :addable="true"
          :optionOutput="optionOutput"
          :dependsOnSource="dependsOnSource"
          :loading="dependenciesLoading"
          :isMultiple="this.isMultipleSelection"
          :placeholder="$t('messages.pleaseChoose', [])"
          valueFormat="object"
          :disableBranchNode="true"
          @changed="changed"
        />
      </div>
    </FormElement>
  </div>
</template>

<script>
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import { get, isArray, flattenDeep, includes, head } from 'lodash';
// import RecordMxin from '@/mixins/recordMixin'
import FormElement from './../../../shared/formElement';
import Mixin from './../../../mixin';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import NodeUtils from '@/pages/admin/workflow/utils/node';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  // mixins: [Mixin],
  data() {
    // console.log(this.value, !this.canSwitch || this.isOuputDependencyNode || this.isCustomizedTag);
    return {
      fetched: false,
      dependency: false,
      
    };
  },
  computed: {
    // value() {
    //   const { _id } = this.fieldInfo || {};
    //   return this.initialRecord[_id];
    // },
    shouldManageRelation() {
      let valid = this.dependency || !this.canSwitch;
      if (this.showRelationManagement) {
        const { type, fieldType } = this.fieldInfo;
        valid = this.isRelation(fieldType || type);
      }
      return valid;
    },

    adjustedRelationManageList() {
      const { fieldInfo } = this;
      const relationManageList = this.getActionList();
      if (!this.isMuplitpleRelation(fieldInfo)) {
        relationManageList.shift();
      }
      return [ ...relationManageList ];
    },
    
    shouldHide() {
      const { properties={} } = this.fieldInfo;
      return properties.shouldHide;
    },
    fieldType() {
      return this.fieldInfo[this.widgetTypeMapField];
    }, 
    dependenciesLoading() {
      return this.$store.getters.fetchingDependenciesFields;
    },
    list() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(
        workflowOutputs, 
        this.$t('workflow'),
        {
          withOperation: this.withOperation,
          addOnInfo: this.addOnInfo,
        },
      );
      const relationFormId = NodeUtils.getRelationFormId(this.fieldType, this.fieldInfo.properties);

      return NodeUtils.withAcceptanceOptions(
        {
          dependsOnType: this.fieldType, 
          list: workflowOutputs, 
          properties: this.fieldInfo.properties,
          formId: relationFormId, // additional form id matching
          fieldLabel: this.fieldInfo.label,
        }
      );
    },
    isOuputDependencyNode() {
      const { value } = this;
      return get(value, 'relied');
      // return value && typeof value === 'object' && !!value.node;
    },
    // isCustomizedTags() {
    //   const { value } = this;
    //   if (isArray(value) && value.length > 0) {
    //     return this.isCustomizedTag(value[0]);
    //   }
    //   return false
    // },
    isMultipleSelection() {
      return WorkflowSettingUtils.canMultipleDependencies(this.fieldInfo);
    },
    // dependency() {
    //   return !this.canSwitch || this.isOuputDependencyNode || this.isCustomizedTag;
    // },
  },
  props: {
    // options: {
    //   type: Array,
    //   default: [],
    // },
    value: [Object, String, Array],
    // worksheet: String,
    fieldInfo: Object,
    canSwitch: {
      type: Boolean,
      default: true,
    },
    withOperation: Boolean,
    initialDependency: {
      type: Boolean,
      default: false,
    },
    addOnInfo: {
      type: Object,
      default: {},
    },
    optionOutput: String,
    dependsOnSource: String,
    initialRecord : {
      type: Object,
      default: {},
    },
    widgetTypeMapField: {
      type: String,
      default: 'type',
    },
    showRelationManagement: {
      type: Boolean,
      default: false,
    }
    // relatedTableData: Object,
    // onCommandHandler: Function,
  },
  mounted() {
    this.dependency = this.initialDependency || !this.canSwitch || !!get(this.value, 'relied');
    this.fetched = true;
    this.relationAction = (this.value && this.value.action) || head(this.adjustedRelationManageList).value;
  },
  components: {
    ComboBox,
    FormElement,
  },
  methods: {
    getActionList() {
      return [
        {
          value: 'append',
          text: this.$t('workflow.options.relation.append')
        },
        {
          value: 'update',
          text: this.$t('workflow.options.relation.update')
        },
        {
          value: 'remove',
          text: this.$t('workflow.options.relation.delete')
        }
      ];
    },
    // isCheckingError() {

    // },
    onRelationActionChange(action) {
      let fieldValue = this.value;
      if (!!fieldValue) {
        if (this.shouldManageRelation) {
          fieldValue = {
            ...fieldValue,
            action,
          }
        }
        this.$emit('onCommand', {
          command: 'updateFieldValue',
          fieldName: this.fieldInfo._id,
          fieldValue,
        })
      }
    },
    isMuplitpleRelation(info) {
      const { type, fieldType, properties } = info;
      const targetFieldType = fieldType || type;
      return this.isRelation(targetFieldType) && properties && 
        (properties.relatedRecordQuantity === 'multiple' || includes([
          'childTable',
        ], targetFieldType));
    },
    isRelation(fieldType) {
      return includes([
        'relatedRecord',
        'childTable',
      ], fieldType);
    },
    // isCustomizedTag(value) {
    //   // const { value } = this;
    //   // console.log('>>>> ', this.fieldInfo.label, targetValue);
    //   return value && typeof value === 'object' && value.id && value.type === 'newTag';
    // },
    normalize(v) {
      let value = v;
      if (typeof v === 'object') {
        if (!isArray(v)) {
          if (v && v.relied) {
            return v.options || null;
          } 
          // console.log(this.fieldInfo.label, ': ', this.isMultipleSelection ? v : (get(v, '[0]') || []));
          // return this.isMultipleSelection ? v : (get(v, '[0]') || []);
          // return v && v.relied ? (v.options || null) : null;
        }
        // console.log(this.fieldInfo.label, ': ', v && v.relied ? (v.options || null) : null);
        // which is mulitple dependencies data
        // return v && v.relied ? (v.options || null) : null;
      }
      // console.log(this.fieldInfo.label, ': ', this.isMultipleSelection ? (value || []) : (value || null));
      return null;
      // return isArray(v) ? 
      // (this.isMultipleSelection ? v : null) : 
      // (typeof v !== 'object' ? null : v);
    },
    onDependency(dep) {
      this.changed(null);
      if (!dep) {
        this.$emit('onCommand', {
          command: 'updateFieldValue',
          fieldName: this.fieldInfo._id,
          fieldValue: null,
        });
      }
    },
    changed(v) {
      let fieldValue = {
        relied: true,
        options: v,
      };

      if (this.shouldManageRelation) {
        fieldValue = {
          ...fieldValue,
          action: this.relationAction,
        }
      }

      if (v === null) {
        fieldValue = this.shouldManageRelation ? [] : null;
      }

      this.$emit('onCommand', {
        command: 'updateFieldValue',
        fieldName: this.fieldInfo._id,
        fieldValue,
      })
    }
  },
  watch: {
    value: {
      handler(v) {
        console.log(v);
      },
      deep: true,
    },
  },
};

</script>

<style>
  .w-input-widget-container-title {
    margin-bottom: 3px;
    color: #333;
    min-height: 28px;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.375rem !important;
    letter-spacing: 0.0071428571em !important;
  }

  .v-w-forminput-relation-select {
    margin-right: 15px;
    width: 150px !important;
  }
  .v-w-forminput-relation-select .v-input__control, .v-w-forminput-relation-select input {
    min-height: 25px !important;
      height: 25px;
  }
  .v-w-forminput-relation-select .v-input__slot {
    box-shadow: none !important;
    background: transparent !important;
  }

  .v-w-forminput-relation-select .v-select__selections {
    justify-content: flex-end;
  }
  .v-w-forminput-relation-select .v-select__selections input {
    display: none;
  }
</style>
