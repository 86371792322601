<template>
<v-row dense>
    <v-col cols="4" class="d-flex flex-row align-start">
        <label>{{ $t(labelTag) }}</label>
    </v-col>

    <v-col :cols="cols" class="d-flex flex-row align-center">
        <draggable class="w-100"
            v-list="options">
            <v-chip small deletable class="mb-1 mr-1"
            :class="{'primary':value.includes(option.value)}"
                @click="toggle(option)"
                v-for="option in rearrangedOptions"
                :key="option.value">
                {{ option.label }}
                <v-chip v-if="option.isTitle" 
                class="ml-1 py-1 px-2 flat black white--text"
                    x-small>{{ $t('general.title') }}</v-chip>
            </v-chip>
        </draggable> 
    </v-col>
 </v-row>

<!--
//     <v-chip-group color="success" :column="true" active-class="primary" multiple
//   :value="selection" @change="onChange">
//   <v-chip close draggable small>chip1</v-chip>
//   <v-chip close draggable small>chip2</v-chip>
//   <v-chip close draggable small>chip3</v-chip>
//   <v-chip close draggable small>chip4</v-chip>
//   <v-chip close draggable small>chip5</v-chip>
//   <v-chip close draggable small>chip6</v-chip>
//   <v-chip close draggable small>chip7</v-chip>
//   </v-chip-group>
    //   selection: {{ selection }}
-->
</template>

<script>
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: Array,
        options: {
            type: Array,
            default () {
                return []
            }
        },
        labelTag: String
    },
    computed: {
        rearrangedOptions () {
            const vm = this
            var result = []
            console.log('total options = ' + vm.options.length)
            console.log('value.length = ' + vm.value.length)
            for (let i = 0; i < vm.value.length; i++) {
                const option = vm.options.find(opt => opt.value === vm.value[i])
                if (option) {
                    result.push(option)
                }
            }
            console.log('rearrangedOptions: selected count =' + result.length)
            const remainingOptions = vm.options.filter(opt => !vm.value.includes(opt.value))

            console.log('rearrangedOPtions: remaing: ' + remainingOptions.length)
            for (let j = 0; j < remainingOptions.length; j++) {
                result.push(remainingOptions[j])
            }
            return result
        }
    },
    methods: {  
        toggle (option) {
            const vm = this
            const index = vm.value.findIndex(val => val === option.value)
            if (index >= 0) {
                vm.value.splice(index, 1)
            } else {
                vm.value.push(option.value)
            }
        }
    }
}
</script>