<template>
<v-row class="card-buttons" transition="fade-transition">
  <v-col class="d-flex flex-row justify-start py-1">
    <v-btn v-for="button in buttonInfos"
           :color="button.color"
           :key="button.command"
           class="mr-1"
           @click.prevent.top="onButton(button)"
           x-small fab circle>
      <v-icon>{{ button.icon }}</v-icon>
    </v-btn>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: 'cardButtons',
  props: {
    buttons: {
      type: Array,
      default () {
        return [
          {key:'edit', command: 'edit'},
          {key:'delete', command: 'delete'},
          {key:'unlink', command: 'unlink'}
        ]
      }
    }
  },
  computed: {
    buttonInfos () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.buttons.length; i++) {
        const loopButton = vm.buttons[i]
        var buttonInfo = vm.AVAILABLE_BUTTONS[loopButton.key]
        if (buttonInfo) {
          result.push({
            key: loopButton.key,
            icon: buttonInfo.icon,
            color: buttonInfo.color,
            command: loopButton.command,
            requiresConfirm: buttonInfo.requiresConfirm
          })
        }
      }
      return result
    }
  },
  data () {
    return {
      AVAILABLE_BUTTONS: {
        'edit': {icon: 'mdi-file-document-edit'},
        'delete': {icon: 'mdi-delete', color: 'error', requiresConfirm: true},
        'unlink': {icon: 'mdi-link-variant-remove', color: 'error', requiresConfirm: true},
      }
    }
  },
  methods: {
    onButton (button) {
      // console.log('CardButtons :: button: ', button)
      const vm = this
      vm.$emit('onCommand', {
        command: button.command,
        button: button
      })
    }
  }
}
</script>
