<template>
  <div class="workform-clip-card-container">
    <v-layout align-center class="workform-clip-card">
      <div v-if="onlyPrefix" class="workform-clip-source">
        <v-icon small class="workform-clip-source-icon">
          {{ item.icon || 'mdi-table' }}
        </v-icon>
        {{ $t('workflow.label.table') }} {{ item.table }}
      </div>
      <div v-if="onlyPrefix && onlySuffix" class="workform-clip-arrow1" />
      <div v-if="onlyPrefix && onlySuffix" class="workform-clip-arrow2" />
      <div v-if="onlySuffix" class="workform-clip-card-label" :class="{
        'workform-clip-card-label-signle': !onlyPrefix && onlySuffix,
      }" :style="{
        background:
          [
            'WORKFLOWTRIGGER',
          ].includes(item.fieldType) ?
          $vuetify.theme.themes.light.color.system :
          $vuetify.theme.themes.light.primary,
        maxWidth: '160px',
      }">
        <span v-if="item.isSystem">
          {{  renderLabel ? renderLabel(item, true) : $t(`workflow.widgets.${item.fieldId}`) }}
        </span>
        <span v-else>
          {{  renderLabel ? renderLabel(item) : item.label }}
        </span>
      </div>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      item: Object,
      onlyPrefix: Boolean,
      onlySuffix: Boolean,
      renderLabel: Function,
    },
  }
</script>

<style>
</style>
