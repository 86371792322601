<template>
  <v-app-bar
    dense
    :style="appInfoColorStyle"
    :class="appInfoColorClass"
    :dark="!currentApp || currentApp.textTheme === 'dark'"
    class="app-header flex-grow-0 d-flex flex-row elevation-0"
    style="width:100%;"
  >
    <div
      v-if="!terminated && (atMyAppsPage || atTemplateListPage || atPublishAppPage) && user"
      class="d-flex flex-row justify-center"
      style="width:100%;position:fixed;top:0;left:0;text-align:center;height:48px;"
    >
      <div class="d-flex flex-row align-center btn-group">
        <v-btn to="/my_apps" exact-active-class="primary">{{
          $t("myapps.myApps")
        }}</v-btn>
      </div>
    </div>
    <v-toolbar-title
      v-if="atPublishAppToCompanyPage"
      class="pl-1 d-flex flex-row align-center pa-0"
      style="margin-left:-22px;width:345px;z-index:1;"
    >
      <v-btn @click.stop="$router.back()">
        <v-icon medium>mdi-chevron-left-circle</v-icon>
      </v-btn>
      <div v-if="currentItem" class="ml-1 d-flex flex-row align-center">
        {{ currentItem.title }}
      </div>
    </v-toolbar-title>
    <v-toolbar-title
      v-else-if="atAdminPage"
      class="pl-1 d-flex flex-row align-center"
      style="margin-left:-22px;z-index:1;"
    >
      <v-btn @click.stop="returnToAppPage">
        <v-icon medium>mdi-chevron-left-circle</v-icon>
      </v-btn>
      <div v-if="currentItem" class="ml-1 d-flex flex-row align-center">
        {{ currentItem.title }}
        <v-chip color="" class="ml-3" label small>{{
          $t("general.editing")
        }}</v-chip>
      </div>
    </v-toolbar-title>

    <!-- General Pages -->
    <v-toolbar-title
      v-else
      class="d-flex flex-row align-center px-0"
      style="z-index:1;"
    >
      <v-btn @click="gotoMyApps">
        <v-icon medium>mdi-home</v-icon>
        <h3
          v-if="!currentApp"
          class="font-weight-regular ml-3"
        >
          {{ $t("general.workbench") }}
        </h3>
      </v-btn>      
      <!-- if app selected -->
      <div v-if="currentApp" class="d-flex flex-row align-center">
        <v-icon>mdi-chevron-right</v-icon>
        <!-- <font-awesome-icon icon="angle-right" class=" mx-2"></font-awesome-icon> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <app-icon :appInfo="currentApp"></app-icon>
              <!--<v-icon medium>{{ currentApp.icon }}</v-icon>-->
              <span class="ml-2 nowrap-ellipsis max-width-140">{{ currentApp.title }}</span>
              <v-icon right>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <list-menu v-if="appMenu.length > 0" 
            :menu="appMenu"
            @click="onAppMenuItemClick"></list-menu>
        </v-menu>
      </div>

      <div
        v-if="atAdminPage && currentItem"
        class="ml-3 d-flex flex-row align-center"
      >
        <v-icon>mdi-chevron-right</v-icon>
        <!-- <font-awesome-icon
          icon="angle-right"
          class="grey--text mr-3"
        ></font-awesome-icon> -->
        {{ currentItem.title }}
      </div>
    </v-toolbar-title>
    <div class="flex-grow-1 ml-5" style="width:0px">
      <slot name="content"></slot>
    </div>
    <div
      v-if="!terminated && (atMyAppsPage || atTemplateListPage) && user"
      class="d-flex flex-row justify-center"
      style="width:100%;position:fixed;top:0;left:0;text-align:center;height:48px;"
    >
      <div class="d-flex flex-row align-center btn-group">
        <v-btn to="/my_apps" exact-active-class="primary">{{
          $t("myapps.myApps")
        }}</v-btn>
        <!-- <v-btn to="/template_list" exact-active-class="primary">{{
          $t("myapps.templateLibrary")
        }}</v-btn> -->
        <!-- <v-btn to="/test_page" style="text-decoration:line-through;" exact-active-class="primary">{{ $t('myapps.testingPage') }}</v-btn> -->
      </div>
      <!-- <dialog-button-global-settings
          @onCommand="onCommandHandler"></dialog-button-global-settings> -->
    </div>

    <div
      v-if="atPublishAppToCompanyPage && user"
      class="d-flex flex-row justify-center"
      style="width:100%;position:fixed;top:0;left:0;text-align:center;height:48px;"
    >
      <div class="d-flex flex-row align-center btn-group">
        <v-btn
          :to="'/publish_app/' + $route.params.id"
          exact-active-class="primary"
          >{{ $t("myapps.publishApp") }}</v-btn
        >
        <!-- <v-btn to="/test_page" style="text-decoration:line-through;" exact-active-class="primary">{{ $t('myapps.testingPage') }}</v-btn> -->
      </div>
      <!-- <dialog-button-global-settings
          @onCommand="onCommandHandler"></dialog-button-global-settings> -->
    </div>

    <!-- <div
      v-if="false && atPublishAppPage && user"
      class="d-flex flex-row justify-center"
      style="width:100%;position:fixed;top:0;left:0;text-align:center;height:48px;"
    >
      <div class="d-flex flex-row align-center btn-group">
        <v-btn to="/publish_app" exact-active-class="primary">{{
          $t("myapps.publishApp")
        }}</v-btn>
       <v-btn to="/test_page" style="text-decoration:line-through;" exact-active-class="primary">{{ $t('myapps.testingPage') }}</v-btn> 
      </div>
      <dialog-button-global-settings
          @onCommand="onCommandHandler"></dialog-button-global-settings>
    </div> -->

    <!-- <v-spacer></v-spacer> -->
    <div class="flex-grow-0 ml-auto d-flex flex-column">
      <div class="d-flex">

        <div
          v-if="((atAppsPage && !app) || atMyAppsPage) && isTeamAdmin && activeTeam"
          class="d-flex flex-row align-center"
        >
          <v-btn @click="teamAuthorizationSetup">
            {{ $t("myapps.availableTeams") }}
            <v-icon class="pl-1">mdi-account-group</v-icon>
          </v-btn>
        </div>

        <!-- <div v-if="currentApp && routeName !=='roles'" class="d-flex flex-row align-center"> -->
        <slot name="prepend-user"> </slot>

        <div v-if="activeTeam&&(!terminated)&&teamExpireSoon&&(atAppsPage||atMyAppsPage)"
          @click="showReminderDialog"
          class="d-flex flex-row align-center">
          <v-chip 
            :light="useLightTheme"
            :dark="!useLightTheme"
            class="cursor-pointer"
            label >{{ $t('messages.teamExpireSoon') }}</v-chip></div>
        <!-- user menu or login -->
        <user-menu v-if="user"></user-menu>
        <div v-else class="p-relative">
          <v-btn @click.stop="login">
            <v-icon left>mdi-account</v-icon>
            {{ $t("general.login") }}
          </v-btn>
          <v-btn v-if="enableGuestLogin" class="rounded p-absolute black--text"
            @click.stop="guestLoginOrRegister"
            style="right:0;top:100%;">
            <v-icon left>mdi-account-tie</v-icon>
            {{ $t('guest.guestLoginOrSignup') }}
          </v-btn>
        </div>
      </div>
    </div>
    <slot name="suffix-user"> </slot>
    <v-btn 
      v-if="!terminated && user && ( isActiveTeamAdmin || (activeTeam && activeTeam.allowDeployment))"
      @click="teamSettingDialog=true"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>
    <v-app-bar-nav-icon
      v-if="!atAdminPage"
      style="z-index:1;"
      @click.stop="toggleDrawer"
    >
      <v-icon>
        mdi-menu
      </v-icon>
    </v-app-bar-nav-icon>
    <v-overlay class="menu-overlay" v-if="pausingMenu" absolute></v-overlay>

    <team-authorization-setup-dialog
      ref="teamAuthorizationSetupDialog"
    ></team-authorization-setup-dialog>

    <guest-login-dialog
      ref="guestLoginDialog"></guest-login-dialog>

    <guest-signup-dialog
      ref="guestSignupDialog"></guest-signup-dialog>

    <team-settings-dialog
      v-if="teamSettingDialog"
      v-model="teamSettingDialog"
      @input="v=>teamSettingDialog=v"
    />
    <reminder-dialog 
      ref="reminderDialog">
    </reminder-dialog>
  </v-app-bar>
</template>

<script>
import EventBus from "@/event-bus.js";
import userMenu from "@/components/UserMenu";
import appIcon from "@/components/AppIcon";
import listMenu from "@/components/ListMenu";
import teamAuthorizationSetupDialog from "@/components/dialogs/TeamAuthorizationSetupDialog";

import guestLoginDialog from '@/components/dialogs/GuestLoginDialog';
import guestSignupDialog from '@/components/dialogs/GuestSignupDialog';
import teamSettingsDialog from '@/components/dialogs/teamSettings/TeamSettingsDialog'

import reminderDialog from '@/components/dialogs/ReminderDialog';

export default {
  components: {
    userMenu,
    appIcon,
    listMenu,
    teamAuthorizationSetupDialog,
    guestLoginDialog,
    guestSignupDialog,
    teamSettingsDialog,
    reminderDialog,
    // teamSettingDialog
  },
  name: "AppHeader",

  data: () => {
    return {
      formSection: 0,
      showPassword: false,
      pausingMenu: false,
      teamSettingDialog: false,
      menu: [
        {
          admin: true,
          label: "optionGroup.optionGroup",
          icon: "mdi-plus-box-outline",
          command: "openAddOptionsDrawer",
          trackId:"選項組"
        },
        {
          admin: false,
          label: 'roles.permission',
          icon: 'mdi-account-multiple',
          command: 'gotoRoleSetup',
          trackId:"權限"
        },
        {
          admin: true,
          label: 'menu.workflow',
          icon: 'mdi-sitemap',
          command: 'gotoWorkflow',
          trackId:"工作流程"
        },
        {
          admin: true,
          label: "api.apiDoc",
          icon: "mdi-api",
          command: "goToApiDoc",
          trackId:"API文檔"
        }
      ],
      expiryInfo: {
        expiryDate: null,
        gracePeriod: 0,
        advancedNotification: 0
      }
    };
  },
  computed: {
    terminated () {
      var result = false;
      if (this.activeTeam)
        result = this.activeTeam.terminated;
      return result;
    },
    activeTeam () {
      return this.$store.getters.activeTeam;
    },
    useLightTheme () {
      return (this.app && this.app.textTheme === 'dark' || this.atMyAppsPage);
    },
    enableGuestLogin () {
      return !this.$store.getters.hiddenItems.includes('guest_login')
    },
    isTeamAdmin() {
      const vm = this;
      let result = false;
      if (vm.user) {
        result = vm.user.employees.find(
          (employee) => employee.role && employee.role.type === "ADMINISTRATOR"
        );
      }
      return result
    },
    activeEmployee(){
      return this.$store.getters.activeEmployee
    },
    isActiveTeamAdmin(){
      return (this.activeEmployee && 
        this.activeEmployee.role && 
        this.activeEmployee.role.type === "ADMINISTRATOR")
    },
    app() {
      return this.$store.getters.currentApp;
    },

    appMenu() {
      if (this.isAdmin) {
        return this.menu;
      } else {
        return this.menu.filter((item) => item.admin === false);
      }
    },
    routeName() {
      return this.$route.name;
    },
    isColorClass() {
      const vm = this;
      var result = false;
      if (vm.currentApp.color) {
        result = vm.currentApp.color.indexOf("-") >= 0;
      }
      return result;
    },
    appInfoColorClass() {
      const vm = this;
      var result = [];
      if (vm.currentApp) {
        if (vm.isColorClass) {
          result.push(vm.currentApp.color);
        }
        result.push("theme--" + vm.currentApp.textTheme);
      }

      return result.join(" ");
    },
    appInfoColorStyle() {
      const vm = this;
      var result = {
        "background-color": "black",
      };
      if (vm.currentApp) {
        if (!vm.isColorClass) {
          result["background-color"] = vm.currentApp.color
            ? vm.currentApp.color
            : "rgba(128,128,128,.2)";
        }
        result["color"] =
          vm.currentApp.textTheme === "light" ? "black" : "white";
      }
      return result;
    },

    atMyAppsPage() {
      return this.$route.path.indexOf("/my_apps") === 0;
    },
    atAppsPage() {
      return this.$route.path.indexOf("/apps") === 0;
    },

    atAdminPage() {
      return this.$route.path.indexOf("/admin") === 0;
    },
    atTemplateListPage() {
      return this.$route.path.indexOf("/template_list") === 0;
    },
    atPublishAppPage() {
      return this.$route.name === "publishApp";
    },
    atPublishAppToCompanyPage() {
      return this.$route.name === "publishAppToCompany";
    },
    itemType() {
      const vm = this;
      var result = "";
      switch (vm.$route.name) {
        case "adminPanels":
          result = "panel";
          break;
        case "adminForms":
          result = "form";
          break;
        case "adminReports":
          result = "report";
          break;
        case "publishAppToCompany":
          result = "app";
          break;
      }
      return result;
    },

    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    user() {
      return this.$store.getters.user;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },

    appItemRouteName() {
      const vm = this;
      return vm.itemType + "s";
    },
    currentItem() {
      const vm = this;
      var result = null;
      switch (vm.itemType) {
        case "panel":
          result = vm.$store.getters.currentPanel;
          break;
        case "form":
          result = vm.$store.getters.currentForm;
          break;
        case "report":
          result = vm.$store.getters.currentReport;
          break;
        case "app":
          result = vm.$store.getters.currentApp;
          break;
      }
      return result;
    },
    teamExpireSoon () {
      var result = false;
      if (this.expiryInfo) {
        if (this.expiryInfo.expiryDate) {
          const notificationDate = new Date(this.expiryInfo.expiryDate);
          notificationDate.setDate(notificationDate.getDate() - this.expiryInfo.advancedNotification);
          const today = new Date();
          if (notificationDate < today) result = true;
        }
      }
      return result;
    }
    // ,
    // isEditing () {
    //   return this.$store.getters.isEditing
    // }
  },
  created() {
    EventBus.$on("disableToolbar", this.disableToolbar);
    EventBus.$on("enableToolbar", this.enableToolbar);
  },
  beforeDestroy() {
    EventBus.$off("disableToolbar");
    EventBus.$off("enableToolbar");
  },
  watch: {
    'activeTeam.name': function(newVal) {
      if (newVal) {
        this.checkTeamExpiryStatus();``
      }
    }
  },
  mounted () {
    this.checkTeamExpiryStatus();
  },
  methods: {
    checkTeamExpiryStatus() {
      if (this.activeTeam) {
        this.$store.dispatch('CHECK_TEAM_EXPIRY_STATUS', {teamId: this.activeTeam._id}).then(
          response => {
            this.expiryInfo = response.result;
          }
        )
      }
    },
    showReminderDialog () {
      this.$refs.reminderDialog.show();
    },
    guestLoginOrRegister () {
      console.log('AppHeader: guestLogion')
      this.$refs.guestLoginDialog.open(res => {
        console.log('callback: res: ', res)
        switch (res.command) {
          case 'loginAsGuest':
            this.loginAsGuest(res.payload)
            break
          case 'registerAsGuest':
            this.registerAsGuest(res.payload)
            break
          case 'gotoSignup':
            this.guestSignup()
            this.$refs.guestLoginDialog.close()
            break
        }
      })
    },

    registerAsGuest (credential) {
      const postData = {
        urlCommand: '/guests/register',
        data: credential
      }
      this.$store.dispatch('COMMON_POST', postData).then(
        response => {
          this.$toast.success(this.$t('guest.registerSuccessfully'))
          this.$refs.guestLoginDialog.switchMode('login')
        },
        error => {
          console.log('error: ', JSON.stringify(error))
          this.$toast.error(this.$t(error) + '!', {duration: 0})
        }
      )
    },

    loginAsGuest (credential) {
      const vm = this
      const postData = {
        urlCommand: '/guests/login',
        data: credential
      }
      console.log('login as guest call COMMON_POST api')
      this.$store.dispatch('COMMON_POST', postData).then(
        response => {
          if (response.status) {
            console.log('then => setAccessToken: ' + response.accessToken)
            vm.$store.commit("setAccessToken", response.accessToken);
            vm.$refs.guestLoginDialog.close()
            console.log('push myApps')
            vm.$router.push({name: 'myApps'})
          } else {
            vm.$toast.error(vm.$t('guest.somethingWentWrongCallAdmin'), {duration:0})
          }
        },
        error => {
          console.log('error: ', JSON.stringify(error))
          this.$toast.error(this.$t(error) + '!', {duration: 0})
        }
      )
    },

    guestSignup () {
      console.log('AppHeader : guestSignup')
      this.$refs.guestSignupDialog.open(res => {
        console.log('callback: res: ', res)
        switch (res.command) {
          case 'signup':
            break
          case 'gotoLogin':
            this.guestLogin()
            this.$refs.guestSignupDialog.close()
            break
        }
      })
    },

    disableToolbar() {
      this.pausingMenu = true;
    },
    enableToolbarButtons() {
      this.pausingMenu = false;
    },
    teamAuthorizationSetup() {
      const vm = this;
      vm.$refs.teamAuthorizationSetupDialog.open();
    },

    returnToAppPage() {
      const vm = this;
      var url = "";
      vm.$router.push({
        name: vm.appItemRouteName,
        params: {
          id: vm.currentApp._id,
          itemId: vm.currentItem._id,
        },
      });
    },
    gotoMyApps() {
      const vm = this;

      // vm.$store.dispatch('SET_CURRENT_APP_ID', '')
      if (vm.$route.name !== "myApps") {
        this.$emit('onCommand', {
          command: 'gotoMyApps'
        })
      }
    },
    login() {
      const vm = this;
      EventBus.$emit("showSpinner");
      const returnUrl =
        window.location.protocol + "//" + window.location.host + "/dashboard";
      const url = vm.$store.getters.authUrl + "/login?returnUrl=" + returnUrl;
      window.location = url;
    },
    toggleDrawer() {
      const vm = this;
      EventBus.$emit("showDrawer");
    },
    showZoom() {
      alert("zoom");
    },
    onAppMenuItemClick({command, trackId}) {
      this.$sensors.track("plus_navi_click", {
        btn_name:trackId,
        appId: this.app._id
      });
      this.$emit("onCommand", {
        command,
        params:{appId:this.app._id}
      });
    },
  },
};
</script>

<style lang="scss">
.app-header {
  z-index: 10 !important;
}

.app-header button {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.app-header .v-toolbar__content {
  display: flex;
  flex-direction: row;
}

/*.v-toolbar__content, .v-toolbar__extension{
  padding: 0px !important
}*/
</style>
