<template>
<div class="flex-grow-1 d-flex flex-column">
  <admin-header></admin-header>
  <router-view class="admin-router-view flex-grow-1"></router-view>
  <v-overlay v-if="loading">
    <font-awesome-icon 
      icon="spinner"
      class="spinner fa-5x fa-spin"></font-awesome-icon>
  </v-overlay>
</div>
</template>

<script>
import adminHeader from './comps/AdminHeader'
import EventBus from '@/event-bus.js'

export default {
  components: {adminHeader},
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    EventBus.$on('showSpinner', this.showSpinner)
    EventBus.$on('hideSpinner', this.hideSpinner)
  },
  beforeDestroy () {
    EventBus.$off('showSpinner')
    EventBus.$off('hideSpinner')
  },
  methods: {
    showSpinner () {
      this.loading = true
    },
    hideSpinner () {
      this.loading = false
    }
  }
}
</script>
