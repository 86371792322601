<template>
    <div class="d-flex mt-1">
        <div style="width: 100px">
            <v-select
                v-model="rule"
                class="vselect-small"
                style="font-size:12px"
                height="30"
                dense
                outlined
                :items="filteredRules"
                item-value="id"
                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                hide-details
            >
                <template v-slot:item="{item}" style="">
                    {{$t('filters.' + criteriaConfigs[item.label].t)}}
                </template>
                <template v-slot:selection="{item}">
                    {{$t('filters.' + criteriaConfigs[item.label].t)}}
                </template>
            </v-select>
        </div>
        <div style="width:400px; border: 1px solid grey; cursor: pointer" @click="openRelatedDialog()" class="ml-2 rounded-sm" v-if="rule!='empty' && rule!='notEmpty'">
            <!-- v-if="fieldProperties.relatedRecordQuantity=='single'" -->
            <div> 
                <div class="pl-1 pt-1 pb-1" style="padding: 0px; height:100%;">
                    <v-chip small v-for="val in content.value" :key="val" class="" style="margin:2px 2px 2px; 2px; max-width:120px; cursor: pointer">
                        <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{getTitle(val.title)}}</span>
                    </v-chip>
                </div>
                <SelectRelatedRecordsDialog
                    ref="SelectRelatedRecordsDialog" 
                    valType="object"
                />
            </div>
        </div>
    </div>
</template>

<script>

import RuleSelectField from '../units/RuleSelectField'
import SelectRelatedRecordsDialog from '@/components/dialogs/SelectRelatedRecordsDialog'
import mixin from './mixin'
export default {
    mixins: [mixin],
    components:{
        RuleSelectField,
        SelectRelatedRecordsDialog
    },
    computed:{
        filteredRules(){
            if(this.fieldProperties.relatedRecordQuantity=='single')
                return this.rules.options
            else 
                return this.rules.options.filter(item=>!(['is', 'not'].includes(item)))
        },
        titleType(){
            var titleFieldId = this.formInfo.titleFieldInfoId
            return this.formInfo.fieldInfos.find(item=> item.fieldId == titleFieldId).type
        },
        currentForm(){
            return this.$store.getters.currentForm
        }
    },
    created(){
        // }else if(!this.content.hasOwnProperty('_id')){
        //       if((this.fieldType=='relatedRecord' && this.fieldProperties.relatedRecordQuantity=='multiple')||this.fieldType=='childTable')
        //         this.rule = get(this, 'rules.options.[2].id')
        //       else
        //         this.rule = get(this, 'rules.options.[0].id')
        //       this.type = 1
        //       if(this.fieldType == "multipleSelection"){
        //         this.value = []
        //       }else if(this.fieldType=='relatedRecord'){
        //         this.value = []
        //       }else{
        //         this.value = ""
        //       }
        //     }
    },
    methods:{
        openRelatedDialog(){
            let vm = this
            console.log('vm.formInfo', vm.formInfo)
            var payload = {
                dataSource: vm.fieldProperties.dataSource,
                formInfo: vm.formInfo,
                multiple: true,
                selected: vm.content.value,
                parentFormId: vm.currentForm._id
                // tableConfigs: 
                // exclusion: vm.content.value
            }
            var onSelected = this.updateSelected
            this.$refs.SelectRelatedRecordsDialog.open(payload, onSelected)
        },
        updateSelected(val){
            this.$emit('updateValue',val)
        },
        getTitle(title){
            switch(this.titleType){
                case 'members':
                    return title.map(item=>item.employeeName).join(",")
                default: return title
            }
        }
    }
}
</script>

<style>
</style>