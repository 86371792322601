<template>
  <div class="w-e-add-parameter-container">
    <UpdateRow
      :dataSource="dataSource"
      :worksheet="worksheet"
      :value="variable"
      :fields="parameters"
      :hints="$t(`workflow.modules.${moduleType}.remark.parameters`)"
      :labelType="moduleType"
      :optionProps="{ showField: true }"
    />
    <div class="w-e-add-parameter">
      <ParameterAddSelect :targetWorkflow="targetWorkflow" />
    </div>
  </div>
</template>

<script>
import Mixin from './../mixin';
import UpdateRow from './../updateRecord/row';
import ParameterAddSelect from './parameterAdd';
import {
  FETCH_WORKFLOW_PARAMETER,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    variable: {
      type: Object,
      default: {},
    },
    dataSource: {
      type: Object,
      default: {},
    },
    worksheet: {
      type: Object,
      default: {},
    },
    moduleType: {
      type: String,
      default: 'subWorkflow',
    },
    targetWorkflow: Object,
    // targetNode: Object,
  },
  // data() {
  // },
  mounted() {
    this.$store.dispatch(FETCH_WORKFLOW_PARAMETER, {
      appId: this.appId,
      workflow: this.targetWorkflow._id,
      // nodeId: this.targetNode._id,
    });
  },
  // methods: {
    
  // },
  computed: {
    parameters() {
      return this.$store.getters.getWorkflowParameters;
    },
  },
  components: {
    UpdateRow,
    ParameterAddSelect,
  },
  // getFormSelectionsFields
};

</script>

<style scoped>
  .w-e-add-parameter-container {
    position: relative;
    right: 0;
    top: 0;
  }
  .w-e-add-parameter {
    position: absolute;
    right: 5px;
    top: -5px;
  }
</style>
