<template>
  <v-layout
    align-start
    justify-space-between
    class="global-panel-notification-content-row g-content-row-workflow"
  >
  <!-- :class="{ 
      'g-content-row-unread': !item.read 
      }" -->
    <div class="global-panel-notification-content-row-icon" :style="{ background: workflow.color }">
      <v-icon color="white" small>{{ workflow.icon || 'mdi-sitemap' }}</v-icon>
      <!-- <div v-if="!item.read" class="g-unread-icon" /> -->
    </div>
    <div class="global-panel-notification-content-row-content">
      <div class="g-content-row-prefix">
        {{$t('notification.panel.tabs.workflow')}}: 
        <router-link :to="workflowParams" target="_blank"><b>{{workflowName}}</b></router-link>
      </div>
      <div class="g-content-row-body" v-html="htmlContent" />
      <div class="g-content-row-link">
        <span v-for="(record, index) in records"
            :key="record._id">
          <router-link
            :to="recordParams(record)"
            target="_blank"
            class="g-content-row-linker"
          >
            <span>
              {{$t('notification.panel.record')}}: 
            </span>
            {{record.title !== '' ? record.title : $t('notification.panel.noDataTitle')}}
          </router-link>
          <span v-if="index < records.length && index !== records.length - 1"
            class="g-content-row-delimiter">|</span>
        </span>
      </div>
      
      <div class="global-panel-notification-content-row-suffix">
        <span v-if="!!appInfo">
          {{$t('notification.panel.fromApp')}}: <router-link
            :to="appParams()"
            target="_blank"
            class="g-content-row-linker"
          >{{appInfo.name}}</router-link>
        </span>
        <span>{{$locale.m(item.createdAt).fromNow()}}</span>
      </div>
    </div>
    <div class="global-panel-notification-content-row-bookmark">
      <v-btn icon>
        <v-icon size="20" color="#1565c0" @click="bookmarkIt">
          {{item.bookmark ? 'mdi-bookmark' : 'mdi-bookmark-outline'}}
        </v-icon>
      </v-btn>
    </div>
  </v-layout>
</template>

<script>


import WorkflowRoutesName from '@/const/workflow/routes';
import {
  UNBOOKMARK_NOTIFICATION,
  BOOKMARK_NOTIFICATION,
} from '@/store/modules/notification/action_types';
  export default {
    props: {
      item: {
        type: Object,
        default: {},
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      bookmarkIt() {
        const { bookmark, _id } = this.item;
        this.$store.dispatch(bookmark ? UNBOOKMARK_NOTIFICATION : BOOKMARK_NOTIFICATION, {
          notificationId: _id,
          appId: this.app._id,
          index: this.index,
        });
      },
      isValidLink() {
        return this.item.form && this.item.view;
      },
      appParams() {
        return {
          name: 'apps', 
          params: {
            id: this.appInfo._id,
            // formId: this.item.form._id,
            // viewId: this.item.view._id,
          },
        };
      },
      recordParams(record) {
        return {
          name: 'records', 
          params: {
            id: this.appInfo._id,
            itemId: this.item.form._id,
            viewId: this.item.view._id,
            recordId: record._id,
          },
        };
      }
    },
    computed: {
      workflowParams() {
        // WorkflowRoutesName.formWorkflowsTree
        return {
          name: WorkflowRoutesName.formWorkflowsTree, 
          params: {
            workflowId: this.workflow._id,
            id: this.appInfo._id,
          },
        };
      },
      app() {
        return this.$store.getters.currentApp;
      },
      appInfo() {
        return this.item.appInfo;
      },
      workflow() {
        const { workflow } = this.item;
        return workflow || {};
      },
      workflowName() {
        const { workflow } = this;
        return workflow.name || '---';
      },
      records() {
        return this.item.data || [];
      },
      htmlContent() {
        const { content='' } = this.item.info || {};
        return content.replaceAll(/(?:\r\n|\r|\n)/g, '<br />');
      },
      // data() {
      //   const { data=[] } = this.item;
      //   if (!data.length) {
      //     return null;
      //   }
      //   // return 
      // },
    },
  }
</script>

