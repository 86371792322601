<template>
  <div class="d-flex flex-start align-start" style="border-top:1px solid #f2f3f5">
    <div 
      class="fill-height d-flex align-center justify-center" 
      style="background-color: #F7F8FA; color:#A9AEB8; padding: 8px 10px 4px 10px; width: max-content">
      <span>{{ $t("buttons.quickFilter") }} ( {{ currentView && $t(`general.${currentView.quickFilterAndOr}`) }} )</span>
    </div>
    <div class="flex-grow-1 d-flex flex-wrap">
      <quick-filter-item
        class="py-2"
        v-for="(filter, index) in userFilters"
        :key="index"
        :fields="fieldIdsWithProperties"
        :filterIndex="index"
        :filter="filter"
        @input="val=>updateFilter(index,val)"
        @onCommand="onCommandHandler"></quick-filter-item>
      <div class="pa-1 d-flex align-center rounded">
        <v-btn small class="min-width-100" color="#CB272D" dark
          @click="clearQuickFilters" style="height: 28px; padding: 6px 8px 6px 8px">
          <v-icon left>mdi-close</v-icon>{{ $t('buttons.clearAll') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import quickFilterItem from './QuickFilterItem'
import adminFormMixin from '@/mixins/adminFormMixin'
import filterHelperMixin from '@/mixins/filterHelperMixin'

export default {
  mixins: [adminFormMixin, filterHelperMixin],
  components: {
    quickFilterItem,
  },
  props: {
    filters: {
      type: Array,
      default () {
        return []
      }
    },
    filterAndOr: {
      type: String,
      default: 'and'
    },
    fieldInfos: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    clearQuickFilters () {
      for (let i = 0; i < this.filters.length; i++) {
        const loopFilter = this.filters[i]
        this.$emit('updateFilters', {
          filterIndex: i,
          filter: {
            fieldId: loopFilter.fieldId,
            relation: loopFilter.relation,
            filterValue1: '',
            filterValue2: ''
          }
        })
        // this.quickFilters[i].filterValue1 = ''
        // this.quickFilters[i].filterValue2 = ''
      }
      this.onCommandHandler({
        command: 'refreshData'
      })
    },
    updateFilters (index, val) {

    },
    deleteAllFilters () {
      const vm = this
      vm.$refs.confirmDeleteDialog.confirm(() => {
        vm.$emit('deleteAllFilters')
      }, {
        title: vm.$t('view.deleteAllFilterConditions')
      })
    },

    _checkErrorCount (state) {
      const vm = this
      var errorCount = 0
      const filterFields = Object.keys(vm.$refs).filter(item => item.indexOf('filter_') === 0)

      // console.log('ViewSettingsFilters :: filterFields count = ' + filterFields.length)
      for (var i = 0; i < filterFields.length; i++) {
        const loopFilterFieldName = filterFields[i]
        var refComp = vm.$refs[loopFilterFieldName]
        if (refComp instanceof Array) {
          // console.log('ViewSettingsFilters :: validate :: refComp is instance of array')
          refComp = refComp[0]
        }
        if (refComp) {
          // console.log('checkErrorCount :: refComp: ', refComp)
          if (!refComp.checkIsValid(true)) {
            errorCount++
          }
        }
      }
      // console.log('ViewSettingsFilters :: errorCount = ' + errorCount)
      return errorCount
    },
    getAndOrOptionLabel (andOr) {
      const vm = this
      var result = 'and'
      const andOrOption = vm.andOrOptions.find(item => item.value === andOr)
      if (andOrOption) {
        result = vm.$t(andOrOption.labelTag)
      }
      return result
    },
    getFilterWidgetByFieldId (fieldId) {
      const vm = this
      return 'filterWidgetString'
    },
    scrollToLastFilter () {
      const vm = this
      vm.$nextTick(() => {
        const container = vm.$refs.filterListContainer
        container.scrollTop = container.scrollHeight
      })
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'deleteFilter':
          vm.$emit('deleteFilter', payload.filterIndex)
          break
        case "refreshData":
          this.$store.dispatch("SET_QUICK_FILTER", {
              viewId: this.currentView._id, 
              value: this.filters
            });
          this.$emit('onCommand', payload);
          break;
        default:
          vm.$emit('onCommand', payload);
          break;
      }
    },
    addFilterHandler (fieldId) {
      const vm = this
      vm.$emit('addFilter', {
        fieldId: fieldId,
        callback: () => {
          vm.scrollToLastFilter()
        }
      })
    }
  },
  computed: {
    userFilters: {
      get () {
        const vm = this
        var result = []
        if (vm.filters) {
          result = vm.filters
        }
        return result
      },
      set (val) {
        const vm = this
        vm.$emit('updateFilters', val)
      }
    },
    userFilterAndOr: {
      get () {
        return this.filterAndOr
      },
      set (val) {
        this.$emit('updateFilterAndOr', val)
      }
    },
    fieldIdsWithProperties () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i]
        // console.log('ViewSettingsFilters :: loopFieldInfo = ' + loopFieldInfo.label)
        const filterType = vm.getFilterKeyByFieldInfo(
          vm.$store.getters.widgetMap,
          loopFieldInfo)

        if (filterType) {
          result.push({
            id: loopFieldInfo.fieldId,
            label: loopFieldInfo.label,
            filterType: filterType,
            fieldInfo: loopFieldInfo
          })
        }
      }
      return result
    },
    currentView() {
      const viewId = this.$route.params.viewId;
      return this.$store.getters.currentForm.views.find( v => v._id === viewId );
    }
  },
  data () {
    return {
      andOrOptions: [
        {labelTag: 'general.and', value: 'and'},
        {labelTag: 'general.or', value: 'or'}
      ]
    }
  }

}
</script>
