var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-0 d-flex pl-2",attrs:{"hide-actions":""}},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t('chart.styleConfig.total'))+" ")])]),_c('v-switch',{staticStyle:{"position":"absolute","right":"0px","top":"-7px"},attrs:{"dense":"","inset":""},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
            command: 'update_bl_displayTotal',
            value: $event
        })}},model:{value:(_vm.bl_displayTotal),callback:function ($$v) {_vm.bl_displayTotal=$$v},expression:"bl_displayTotal"}}),_c('v-expansion-panel-content',[_c('div',[_vm._v(_vm._s(_vm.$t('chart.displayFields')))]),_c('v-select',{staticClass:"thin-select",attrs:{"items":_vm.colOpts,"item-text":"label","return-object":"","height":"30","outlined":"","menu-props":{offsetY: true, contentClass: 'denseList'}},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_displayCol',
                value: $event
            })}},model:{value:(_vm.setup.displayCol),callback:function ($$v) {_vm.$set(_vm.setup, "displayCol", $$v)},expression:"setup.displayCol"}}),(_vm.setup.displayCol.type!='all')?_c('div',[_vm._v(_vm._s(_vm.$t('chart.rollupMethod')))]):_vm._e(),(_vm.setup.displayCol.type!='all')?_c('v-btn-toggle',{attrs:{"mandatory":"","dense":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_totalRule',
                value: $event
            })}},model:{value:(_vm.setup.totalRule),callback:function ($$v) {_vm.$set(_vm.setup, "totalRule", $$v)},expression:"setup.totalRule"}},_vm._l((_vm.calMethods),function(method){return _c('v-btn',{key:method.id,attrs:{"value":method.label,"small":""}},[_vm._v(" "+_vm._s(_vm.$t('chart.styleConfig.' + method.label))+" ")])}),1):_vm._e(),_c('div',{class:_vm.setup.displayCol.type!='all'?'mt-3':''},[_vm._v(_vm._s(_vm.$t('chart.name')))]),_c('v-text-field',{attrs:{"outlined":"","height":"30"},on:{"input":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_totalTitle',
                value: $event
            })}},model:{value:(_vm.setup.totalTitle),callback:function ($$v) {_vm.$set(_vm.setup, "totalTitle", $$v)},expression:"setup.totalTitle"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }