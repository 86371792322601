<template>
  <v-card max-width="480"
    class="mx-auto my-12">
    <v-app-bar color="blue lighten-1"
               dark
               height="48">
      <v-icon left>mdi-animation-outline</v-icon>
      <v-toolbar-title>{{ $t('levelDiagram.levelSetup')}}</v-toolbar-title>
    </v-app-bar>
    <v-container style="min-height:170px;">

      <v-row dense>
        <v-col cols="12" class="mb-2">
          <label>{{ $t('levelDiagram.levelStructureRelation') }}</label>
          <input-radio-toggle
              v-model="levelType"
              :isDense="false"
              :options="levelTypeOptions"></input-radio-toggle>
        </v-col>
      </v-row>

      <!-- General relationship -->
      <level-select-cascade-relation-fields
          v-if="levelType==='multipleTables'"
          v-model="parentChildFieldFormList"
          :view="view"></level-select-cascade-relation-fields>

      <!-- Parent/Child Relationship -->
      <level-select-relation-field
          v-else
          v-model="parentChildFieldIdPair"></level-select-relation-field>

    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary"
             :disabled="actionDisabled"
             @click="confirm"
             class="min-width-100">
        <v-icon left>mdi-check</v-icon>
        {{ $t('buttons.ok')}}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>

</template>

<script>
import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
import levelSelectRelationField from './LevelSelectRelationField'
import levelSelectCascadeRelationFields from './LevelSelectCascadeRelationFields'
import {LEVEL_DIAGRAM_CARD_CONFIG} from '@/const/defaults'
import {getFirstAttachmentsFieldId} from '@/helpers/FormHelpers'

export default {
  name: 'levelDiagramInitialSetup',
  components: {
    inputRadioToggle,
    levelSelectRelationField,
    levelSelectCascadeRelationFields
  },
  computed: {
    actionDisabled () {
      return this.levelType === 'multipleTables' && this.parentChildFieldFormList.length <= 1
    },
    form () {
      return this.$store.getters.currentForm
    }
  },
  props: {
    view: Object
  },
  watch: {
    'view': function(changedView) {
      const vm = this
      vm.checkUpdateViewConfig(changedView)      
    }
    // ,
    // 'levelType': function(newVal) {
    //   switch(newVal) {
    //     case 'currentTable':
    //       if (vm.parentChildFieldIdPair === null)
    //         vm.parentChildFieldIdPair = vm.initialParentChildFieldIdPair
    //       break
    //     case 'multipleTables':
    //       if (!vm.parentChildFieldFormList || !Array.isArray(vm.parentChildFieldFormList) || 
    //         vm.parentChildFieldFormList.length === 0)
    //         vm.parentChildFieldFormList = vm.initialParentChildFieldFormList
    //       break
    //   }
    // }
  },
  mounted () {
    // console.log('LevelDiagramInitialSetup :: mounted :; view: ', this.view)
    this.checkUpdateViewConfig(this.view)
  },
  data () {
    return {
      levelTypeOptions: [
        {labelTag: 'levelDiagram.thisTableRelation', value: 'currentTable'},
        {labelTag: 'levelDiagram.multipleTableRelation', value: 'multipleTables'}
      ],
      levelType: 'currentTable',

      parentChildFieldIdPair: null,
      // {parentFieldId: '', childFieldId: ''}: null, //  {parent: {fieldId}, child: {fieldId}}

      parentChildFieldFormList: []
      // [
      // {fieldId: fieldId, relatedFormId: formId},
      // {fieldId: fieldId, relatedFormId: formId},
      // {fieldId: fieldId, relatedFormId: formId},
      // ...
      // ]
    }
  },
  methods: {
    checkUpdateViewConfig (view) {
      const vm = this
      vm.levelType = view.levelType ? view.levelType : 'currentTable'
      vm.parentChildFieldIdPair = view.parentChildFieldIdPair
      vm.parentChildFieldFormList = vm.checkInitParentChildFieldFormListFromView(view)
    },

    checkInitParentChildFieldFormListFromView (view) {
      const vm = this
      var result = vm.getDefaultParentChildFieldFormList()
      if (view.parentChildFieldFormList && view.parentChildFieldFormList.length > 0) {
        result = view.parentChildFieldFormList
      }
      return result
    },

    getDefaultParentChildFieldFormList () {
      const vm = this
      var cardConfig = JSON.parse(JSON.stringify(LEVEL_DIAGRAM_CARD_CONFIG))
      cardConfig.cardImageFieldId = getFirstAttachmentsFieldId(vm.form.fieldInfos)
      return [{
        fieldId: '',
        relatedTeamId: vm.form.teamId,
        relatedFormId: vm.form._id,
        relatedAppId: vm.form.appId,
        cardConfig: cardConfig
      }]
    },

    confirm () {
      const vm = this
      // console.log('LevelDiagramInitialSetup :: confirm() levelType = ' + vm.levelType)
      if (vm.levelType === 'currentTable') {
        vm.$emit('confirm', {
          levelType: vm.levelType,
          parentChildFieldIdPair: vm.parentChildFieldIdPair
        })
      } else {
        vm.$emit('confirm', {
          levelType: vm.levelType,
          parentChildFieldFormList: vm.parentChildFieldFormList
        })
      }
    }
  }
}
</script>
