<template>
  <div class="mx-1 form-layout d-flex fill-height flex-column flex-grow-1 pb-2">
    <module-tab-bar
        v-if="topPagingTableFieldInfos.length>0"
        class="flex-grow-0 top-paging-table-tabs"
        v-model="topPagingIndex"
        type="topPagingTab"
        labelField="label"
        :isLayout="true"
        :hideButtons="true"
        :availableTabs="topPagingTabs"
        @onCommand="onCommandHandler"></module-tab-bar>
    <div v-if="topPagingIndex===0" class="widget-layout fill-height d-flex flex-column flex-grow-1">
      <div class="flex-grow-1 pr-8">
        <div  style="height:0;min-height:100%;">

          <div class="fill-height" style="width:100%;overflow-y:scroll;padding-right:32px;">
            <v-container class="pt-5 fill-height align-start">
              <v-row v-if="formLayoutConfig">
                <drop-list
                  :items="formLayoutConfig.rows"
                  class="pt-1 col drop-list-col"
                  @insert="onInsertToCol"
                  @reorder="onReorderRow"
                  :accepts-type="['item','widget']"
                  mode="cut"
                  :column="true">

                  <!-- This drop zone will be placed at bottom -->
                  <drop-zone style="background-color:transparent;height:50px;"></drop-zone>
                  <template v-slot:item="{item, index}">
                        <!--:ref="'row'+formLayoutConfig.rows.indexOf(item)"-->
                    <drag
                        tag="div"
                        :id="'row'+getRowIndex(item)"
                        class="d-flex flex-row align-center"
                        :key="getRowKey(item)"
                        type="row"
                        style="position:relative;"
                        :data="item"
                        @cut="removeRow(item)">
                      <div class="row-holder px-3">
                        <v-icon class="justify-self-start">
                          mdi-format-line-spacing
                        </v-icon>
                      </div>
                      <form-layout-row
                          :ref="'formLayoutRow'+getRowIndex(item)"
                          :rowIndex="getRowIndex(item)"
                          :colBreakPoints="positions.colBreakPoints"
                          @onCommand="onCommandHandler"
                          :selectedItem="selectedItem"
                          :items="item"></form-layout-row>
                      <splitter-list ref="splitterList"
                                    :positions="positions"
                                    :rowId="'row'+formLayoutConfig.rows.indexOf(item)"
                                    :row="item"></splitter-list>
                      <template v-slot:drag-image></template>
                    </drag>
                  </template><!-- item slot -->
                  <template v-slot:feedback="{item}">
                    <div class="feedback" key="feedback2"></div>
                  </template><!-- feedback slot -->
                </drop-list>
              </v-row>

              <!-- Reference Row -->
              <v-row ref="refRow" class="ref-row">
                <div class="col drop-list-col">
                  <div class="d-flex flex-row align-center">
                    <div class="row-holder px-3">
                      <v-icon class="justify-self-start">
                        mdi-format-line-spacing
                      </v-icon>
                    </div>
                    <v-row>
                      <v-col ref="col0" cols="1"><div class="col-content">1</div></v-col>
                      <v-col ref="col1" cols="1"><div class="col-content">2</div></v-col>
                      <v-col ref="col2" cols="1"><div class="col-content">3</div></v-col>
                      <v-col ref="col3" cols="1"><div class="col-content">4</div></v-col>
                      <v-col ref="col4" cols="1"><div class="col-content">5</div></v-col>
                      <v-col ref="col5" cols="1"><div class="col-content">6</div></v-col>
                      <v-col ref="col6" cols="1"><div class="col-content">7</div></v-col>
                      <v-col ref="col7" cols="1"><div class="col-content">8</div></v-col>
                      <v-col ref="col8" cols="1"><div class="col-content">9</div></v-col>
                      <v-col ref="col9" cols="1"><div class="col-content">10</div></v-col>
                      <v-col ref="col10" cols="1"><div class="col-content">11</div></v-col>
                      <v-col ref="col11" cols="1"><div class="col-content">12</div></v-col>
                    </v-row>
                  </div>
                </div>
              </v-row>

              <v-row class="ref-row">
                <div class="col drop-list-col">
                  <div class="d-flex flex-row align-center">
                    <div class="row-holder px-3">
                      <v-icon class="justify-self-start">
                        mdi-format-line-spacing
                      </v-icon>
                    </div>
                    <v-row>
                      <v-col v-for="i in 12" :cols="1" :key="i">
                        <small>{{ positions.colBreakPoints[i-1]}}</small>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-row>

            </v-container>
              <!--<v-btn @click.stop.prevent="changeWidth">Change Width</v-btn>-->

          </div>

        </div>
      </div>
      <div
          v-if="embeddedPagingTableFieldInfos.length>0"
          class="embedded-paging-table flex-grow-0 d-flex flex-column justify-start drop-container px-3 mt-1"
          style="min-height:100px;background-color:white;position:relative;"
          accepts-type="widget-relation"
          :class="{'drag-over':draggingOver}">
        <module-tab-bar
            :isSelected="isEmbeddedPagingTableSelected"
            style="height:24px;"
            class="flex-grow-0 embedded-paging-table-tabs"
            v-model="embeddedPagingIndex"
            labelField="label"
            type="embeddedPagingTab"
            :hideButtons="true"
            :isLayout="true"
            :availableTabs="embeddedPagingTabs"
            @onCommand="onCommandHandler"></module-tab-bar>
        <template v-for="(embeddedPagingTableFieldInfo, index) in embeddedPagingTableFieldInfos">
          <div :key="index" v-if="embeddedPagingIndex===index" class="mt-0">
            <v-container>
              <layout-widget :key="index"
                              @onCommand="onCommandHandler"
                              :selectedItem="selectedItem"
                              :widgetItem="embeddedPagingTableWidgetItems[index]"
                              :form="form"></layout-widget>
            </v-container>
          </div>
        </template>
      </div>
    </div>
    <template v-for="(topPagingTableFieldInfo, index) in topPagingTableFieldInfos">
      <div :key="index" v-if="topPagingIndex===index+1" class="mt-0">
        <v-container>
          <layout-widget :key="index"
                          @onCommand="onCommandHandler"
                          :selectedItem="selectedItem"
                          :widgetItem="topPagingTableWidgetItems[index]"
                          :form="form"></layout-widget>
        </v-container>
      </div>
    </template>
  </div>
</template>

<script>
import {Drag, Drop, DropList} from 'vue-easy-dnd'
import dropZone from '@/components/DropZone'
import formLayoutRow from './FormLayoutRow'
import adminFormMixin from '@/mixins/adminFormMixin'

import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import relatedTableMenu from '@/menus/RelatedTableMenu'

import draggableDiv from './comps/DraggableDiv'
// import VueGridLayout from 'vue-grid-layout'
import splitterList from './comps/SplitterList'
import moduleTabBar from '@/components/ModuleTabBar'
import layoutWidget from './LayoutWidget'

export default {
  mixins: [
    adminFormMixin
    // ,
    // paneBreakPointMixin
  ],
  name: "FormLayout",
  components: {
    Splitpanes,
    Pane,
    drag: Drag,
    drop: Drop,
    dropList: DropList,
    dropZone,
    formLayoutRow,
    adminFormMixin,
    // Splitpanes,
    // Pane,
    draggableDiv,
    // gridLayout: VueGridLayout.GridLayout,
    // gridItem: VueGridLayout.GridItem,
    splitterList,
    moduleTabBar,
    layoutWidget
  },
  props: {
    items: null,
    compType: String,
    selectedItem: null
  },
  created () {
    const vm = this
    // console.log('FormLayout.created :: set window.onresize')
    window.addEventListener('resize', vm.onWindowResized)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onWindowResized)
  },
  mounted () {
    const vm = this
    vm.fetchColLeft()
  },
  watch: {
    topPagingIndex: function(newVal, oldVal) {
      const vm = this
      console.log('topPagingIndex :: newVal = ' + newVal)
      console.log('topPagingIndex :: oldVal = ' + oldVal)

      // ensure trigger from user
      if (newVal > 0 && oldVal !== -1) {
        vm.selectLayoutItem(vm.topPagingTableWidgetItems[newVal-1])
      } else {
        vm.selectFirstLayoutItemOnFirstPage()
      }
    },
    embeddedPagingIndex: function(newVal, oldVal) {
      const vm = this
      console.log('embeddedPagingIndex :: newVal = ' + newVal)
      console.log('embeddedPagingIndex :: oldVal = ' + oldVal)

      // eusure trigger from user
      if (newVal >= 0 && oldVal !== -1) {        
        vm.selectLayoutItem(vm.embeddedPagingTableWidgetItems[newVal])
      }
    },
    'topPagingTabs.length': function () {
      this.updateForRelatedMultipleRecords()
    },
    'embeddedPagingTabs.length': function () {
      this.updateForRelatedMultipleRecords()
    },
    'selectedItemId': function (newVal) {
      const vm = this
      if (newVal && (newVal!=='')) {
        // console.log('watch(selectedItem._id) newVal=' + newVal)
        // console.log('watch(selectedItem._id) vm.formFieldInfos: ', vm.formFieldInfos)
        const fieldInfo = vm.formFieldInfos.find(info => info._id === newVal)
        // console.log('watch(selectedItem :: fieldInfo: ', fieldInfo)
        if (
          fieldInfo && 
          fieldInfo.type === 'relatedRecord' && 
          fieldInfo.properties.relatedRecordQuantity==='multiple' 
        ) {
          vm.updateForRelatedMultipleRecords()
        }
      }
    }
  },
  computed: {
    selectedItemId () {
      const vm = this
      var result = ''
      // console.log('computed(selectedItemId) selectedItem: ', vm.selectedItem)
      // console.log('computed(selectedItemId) selectedItem: ', (vm.selectedItem===null ? 'is null':'not null'))
      // console.log('computed(selectedItemId) selectedItem: ', (vm.selectedItem ? 'yes' : 'no'))
      if (vm.selectedItem) {
        // console.log('vm.selectedItem: ', vm.selectedItem)
        result = vm.selectedItem._id
      }
      // console.log('computed(selectedItemId) selectedItem: result: ', result)

      return result
    },
    // fieldInfos () {
    //   const vm = this
    //   var result = []
    //   if (vm.formFieldInfos) {
    //     result = vm.formFieldInfos
    //   }
    //   return result
    // },

    relationPagingFieldInfos () {
      const vm = this
      var result = []
      if (vm.formFieldInfos) {
        result = vm.formFieldInfos.filter(
          info => {
            return (
              (
                (info.type === 'childTable') ||
                (
                  (info.type === 'relatedRecord') && 
                  (info.properties.relatedRecordQuantity === 'multiple')
                )
              ) && 
              (info.properties.usePaging==='yes')
            )
          }
        )
      }
      return result
    },
    //
    // relatedMultipleRecordFieldInfos () {
    //   const vm = this
    //   var result = []
    //   return vm.relationPagingFieldInfos.filter()
    //   var relatedFieldInfos = vm.fieldInfos.filter(
    //     info => info.type === 'relatedMultipleRecords')
    //   return relatedFieldInfos.filter(info => info.properties.usePaging)
    //   if (vm.formFieldConfigs) {
    //     if ()
    //       result = vm.formFieldConfigs.fieldInfos.filter(info => info.type === 'relatedMultipleRecords')
    //   }
    //   return result
    //
    // },
    //
    // }
    topPagingTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.relationPagingFieldInfos.length > 0) {
        for (let i = 0; i < vm.topPagingTables.length; i++) {
          const loopTopPagingTable = vm.topPagingTables[i]
          const fieldInfo = vm.relationPagingFieldInfos.find(info => info._id === loopTopPagingTable._id)
          if (fieldInfo) {
            result.push(fieldInfo)
          }
        }
      }
      return result
    },
    // topPagingTableFieldInfos () {
    //   const vm = this
    //   return vm.relationPagingFieldInfos.filter(
    //     info => (
    //       info.properties.usePaging === 'yes' &&
    //       info.properties.pagingPosition === 'atTop'
    //     )
    //   )
    // },
    topPagingTableLabels () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.topPagingTableFieldInfos.length; i++) {
        const loopInfo = vm.topPagingTableFieldInfos[i]
        result.push({
          _id: loopInfo._id,
          label: loopInfo.label
        })
      }
      return result
    },

    topPagingTableWidgetItems () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.topPagingTableLabels.length; i++) {
        const loopItem = vm.topPagingTableLabels[i]
        result.push({
          _id: loopItem._id,
          controlType: 'data'
        })
      }
      return result
    },

    topPagingTabs () {
      const vm = this
      return [
        {
          _id: '',
          label: vm.$t('general.mainPage')
        },
        ...vm.topPagingTableLabels
      ]
    },

    embeddedPagingTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.relationPagingFieldInfos.length >0) {
        result = vm.embeddedPagingTables.map(t => {
          return vm.relationPagingFieldInfos.find(info => info._id === t._id)
        })
      }
      return result
    },
    // embeddedPagingTableFieldInfos () {
    //   const vm = this
    //   return vm.relationPagingFieldInfos.filter(
    //     info => (
    //       info.properties.usePaging === 'yes' &&
    //       info.properties.pagingPosition === 'embedded'
    //     )
    //   )
    // },

    embeddedPagingTableLabels () {
      const vm = this
      var result = []
      console.log('FormLayout :: embeddedPagingTableLabels :: vm.embeddedPagingTableFieldInfos: ', 
        vm.embeddedPagingTableFieldInfos)
      for (let i = 0; i < vm.embeddedPagingTableFieldInfos.length; i++) {
        const loopInfo = vm.embeddedPagingTableFieldInfos[i]
        result.push({
          _id: loopInfo._id,
          label: loopInfo.label
        })
      }
      return result
    },

    embeddedPagingTableWidgetItems () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.embeddedPagingTableLabels.length; i++) {
        const loopItem = vm.embeddedPagingTableLabels[i]
        result.push({
          _id: loopItem._id,
          controlType: 'data'
        })
      }
      return result
    },

    embeddedPagingTabs () {
      return this.embeddedPagingTableLabels
    },

    isTopPagingTableSelected () {
      const vm = this
      var result = false
      if (vm.selectedItem) {
        const widgetId = vm.selectedItem._id
        const relatedTableIds = vm.topPagingTableFieldInfos.map(
          tbl => tbl._id
        )
        result = relatedTableIds.indexOf(widgetId) >= 0
      }
      return result
    },

    isEmbeddedPagingTableSelected () {
      const vm = this
      var result = false
      if (vm.selectedItem) {
        const widgetId = vm.selectedItem._id
        const relatedTableIds = vm.embeddedPagingTableFieldInfos.map(
          tbl => tbl._id
        )
        result = relatedTableIds.indexOf(widgetId) >= 0
      }
      return result
    },

    // relatedTableTabs () {
    //   const vm = this
    //   var result = []
    //   if (vm.formLayoutConfig && vm.formLayoutConfig.relatedTables) {
    //     console.log('FormLayout :: relatedTableTabs :: vm.formLayoutConfig.relatedTables: ',
    //       vm.formLayoutConfig)
    //     for (var i = 0; i < vm.formLayoutConfig.relatedTables.length; i++) {
    //       const loopTable = vm.formLayoutConfig.relatedTables[i]
    //       console.log('i=' + i + ': loopTable._id = ' + loopTable._id)
    //       const relatedTableInfo = vm.formFieldInfos.find( info => info._id === loopTable._id)
    //       console.log('i=' + i + ': relatedTableInfo: ', relatedTableInfo)
    //       result.push({
    //         id: i + 1,
    //         title: relatedTableInfo.label
    //       })
    //     }
    //   }
    //   return result
    // },
    form () {
      return this.$store.getters.currentForm
    },
    formLayoutConfig () {
      return this.$store.getters.formLayout
    },
    formLayoutItems () {
      return this.$store.getters.formLayoutItems
    },
    formUIElementInfos () {
      return this.$store.getters.formUIElementInfos
    },
    formFieldInfos () {
      return this.$store.getters.formFieldInfos
    },
    topPagingTables () {
      const vm = this
      var result = []
      if (vm.formLayoutConfig && vm.formLayoutConfig.topPagingTables &&
          Array.isArray(vm.formLayoutConfig.topPagingTables)) {
        result = vm.formLayoutConfig.topPagingTables
      }
      return result
    },
    embeddedPagingTables () {
      const vm = this
      var result = []
      if (vm.formLayoutConfig && vm.formLayoutConfig.embeddedPagingTables &&
        Array.isArray(vm.formLayoutConfig.embeddedPagingTables)) {
        result = vm.formLayoutConfig.embeddedPagingTables
      }
      return result
    }
    // ,
    // formChildTableInfos () {
    //   return this.$store.getters.formChildTableInfos
    // }
  },
  data () {
    return {
      topPagingIndex: 0,
      embeddedPagingIndex: -1,
      draggingOver: false,


      relatedTableMenu: relatedTableMenu,
      relatedTableIndex: 0,
      positions: {
        rowLeft: 0,
        colBreakPoints: [],
      },

      paneSizes: [50, 20, 30],
      paneSize0: 50,
      paneSize1: 20,
      paneSize2: 30
    }
  },
  methods: {
    selectLayoutItem (layoutItem) {
      const vm = this
      vm.$store.dispatch('SET_SELECTED_FORM_FIELD_ID', layoutItem._id)       
    },
    gotoFirstTab() {
      this.topPagingIndex = 0
    },
    // updateSplitters (rowIndex) {
    //   const vm = this
    //   const row = vm.formLayoutConfig.rows[rowIndex]
    //   // console.log('FormLayout :: updateSplitters :: rowIndex = ' + rowIndex + ': row: ', row)
    //   this.$refs.splitterList.updateSplitterPositions(row)
    // },
    updateForRelatedMultipleRecords () {
      const vm = this

      if (vm.selectedItem) {
        const fieldInfoId = vm.selectedItem._id
        const fieldInfo = vm.formFieldInfos.find(info => info._id === fieldInfoId)

        // console.log('FormLayout :: updateForRelatedMultipleRecords :: fieldInfoId =' + fieldInfoId)
        // console.log('FormLayout :: updateForRelatedMultipleRecords :: fieldInfo: ', fieldInfo)

        if (fieldInfo.properties.usePaging === 'yes') {
          if (fieldInfo.properties.pagingPosition === 'atTop') {
            vm.topPagingIndex = vm.topPagingTabs.findIndex(info => info._id === fieldInfoId)
            vm.embeddedPagingIndex = -1

            const layoutItem = vm.topPagingTableWidgetItems.find(item => item._id === fieldInfoId)
            const fieldInfo = vm.topPagingTableFieldInfos.find(info => info._id === fieldInfoId)
            vm.selectItem(layoutItem, fieldInfo)
          } else {
            vm.topPagingIndex = 0
            vm.embeddedPagingIndex = vm.embeddedPagingTabs.findIndex(info => info._id === fieldInfoId)
            const layoutItem = vm.embeddedPagingTableWidgetItems.find(item => item._id === fieldInfoId)
            const fieldInfo = vm.embeddedPagingTableFieldInfos.find(info => info._id === fieldInfoId)
            vm.selectItem(layoutItem, fieldInfo)
          }
        } else {
          vm.topPagingIndex = 0
          vm.embeddedPagingIndex = -1
        }
      }
    },
    onWindowResized () {
      this.fetchColLeft()
    },

    fetchColLeft () {
      const vm = this
      vm.$nextTick(() => {
        vm.doFetchColLeft()
      })
    },

    doFetchColLeft () {
      const vm = this
      if (Object.keys(vm.$refs).length >0) {
        if (vm.$refs.refRow) {
          const rowLeft = vm.$refs.refRow.getBoundingClientRect().x
          var colBreakPoints = []
          var startLeft = 0;
          for (var i = 0; i < 12; i++) {
            startLeft = vm.$refs['col' + i].getBoundingClientRect().x
            colBreakPoints.push(parseInt(startLeft))
          }
          startLeft = parseInt(startLeft + vm.$refs['col11'].getBoundingClientRect().width)
          colBreakPoints.push(startLeft)
          vm.positions = {
            rowLeft: rowLeft,
            colBreakPoints: colBreakPoints
          }
        }
      }
    },
    setPaneSize (paneNo, sizeW) {
      const vm = this
      vm.$set(vm.paneSizes, paneNo, sizeW)
      // vm.$nextTick( () => {
      //   vm.paneSizes[paneNo] = sizeW
      // })
    },
    onResize (panes) {
      const vm = this
      for (var i = 0; i < vm.paneSizes.length; i++) {
        vm.$set(vm.paneSizes, i, vm.getClosestBreakPoint(Math.round(panes[i].size)))
      }
      vm.$nextTick(() => {
        for (var i = 0; i < vm.paneSizes.length; i++) {
          vm.setPaneSize(i, vm.paneSizes[i])
        }
      })
    },

    selectItem (layoutItem, fieldInfo) {
      const vm = this
      console.log('FormLayout :: selectItem :: layoutItem = ' + JSON.stringify(layoutItem))
      fieldInfo = vm.formFieldInfos.find(info => info._id === layoutItem._id)

      console.log('selectItem: fieldInfo: ', fieldInfo)
      vm.$emit('onCommand', {
        command: 'selectItem',
        layoutItem: layoutItem,
        fieldInfo: fieldInfo
      })
    },

    selectFirstLayoutItemOnFirstPage () {
      const vm = this
      // console.log('selectFirstLayoutItemOnFirstPage')
      const layoutItem = vm.formLayoutItems.length > 0 ? vm.formLayoutItems[0] : null
      const fieldInfo = vm.formFieldInfos.find(info => info._id === layoutItem._id)
      vm.selectLayoutItem(fieldInfo)
//      vm.selectItem(layoutItem, fieldInfo)
    },

    onCommandHandler (payload) {
      // console.log('FormLayout.vue :: onCommandHandler :: payload: ', payload)
      const vm = this
      switch (payload.command) {
        // case 'updateSplitters':
        //   vm.updateSplitters(payload.rowIndex)
        //   break
        case 'selectTab':
          // console.log('onCommandHandler :: selectTab')
          const tabbarType = payload.type
          const tabIndex = payload.tabIndex
          if (tabbarType === 'topPagingTab') {
            if (tabIndex === 0) {
              vm.selectFirstLayoutItemOnFirstPage()
            } else { // tabIndex > 0
              const layoutItem = vm.topPagingTableWidgetItems[tabIndex-1]
              const fieldInfo = vm.formFieldInfos.find(info => info._id === layoutItem._id)
              vm.selectItem(layoutItem, fieldInfo)
            }
          } else if (tabbarType === 'embeddedPagingTab') { // tabbarType === 'embeddedPagingTab'
            const layoutItem = vm.embeddedPagingTableWidgetItems[tabIndex]
            const fieldInfo = vm.formFieldInfos.find(info => info._id === layoutItem._id)
            vm.selectItem(layoutItem, fieldInfo)
          }
          // vm.selectedItem = vm.formLayoutConfig.relatedTables[vm.relatedTableIndex]
          //
          // vm.$emit('onCommand', {
          //   command: 'showFieldProperty',
          //   fieldInfoId: vm.selectedItem._id
          // })
          break
        case 'selectItem':
          vm.selectItem(payload.layoutItem, payload.fieldInfo)
          break
        case 'showChildTableFieldProperty':
          alert('showChildTableFieldProperty')
          break
        case 'moveTabs':
          vm.moveTabs(payload.type, payload.oldIndex, payload.newIndex)
          break
        default:
          this.$emit('onCommand', payload)
      }
    },
    moveTabs (type, oldIndex, newIndex) {
      const isTopPagingTab = type === 'topPagingTab'

      this.$store.dispatch('SWAP_PAGING_TABLES', {
        type: isTopPagingTab ? 'top' : 'embedded',
        oldIndex: isTopPagingTab ? oldIndex - 1 : oldIndex,
        newIndex: isTopPagingTab ? newIndex - 1 : newIndex
      })
    },

    acceptType (type) {
      const vm = this
      // console.log('FormLayout :: acceptType ::type = ' + type)
      return true
    },

    // When a widget move from a row into a new col
    onInsertToCol (event) {
      const vm = this
      // alert('onInsertToCol')
      // console.log('FormLayout :: InsertToCol :: event: ', event)
      vm.$store.dispatch('INSERT_LAYOUT_ITEM', {
        data: event.data,
        index: event.index,
        type: event.type,
        widgetProperties: vm.$store.getters.widgetProperties,
      }).then((res)=> {
        // console.log(res)
        vm.selectItem(res.newFieldInfo, res.newLayoutItem)
      })
      vm.fetchColLeft()
    },
    onReorderRow (event) {
      const vm = this
      event.apply(this.formLayoutConfig.rows)
      // vm.fetchColLeft()
      // console.log('FormLayout :: onReorderRow :: event: ', event)
    },
    removeRow (item) {
      const vm = this
       // console.log('FormLayout :: removeRow :: item: ', item)
      this.$store.dispatch('REMOVE_LAYOUT_ROW', item)
    },


    onOneDrop (e) {
      this.oneDropped = true;
    },
    onTwoDrop (e) {
      this.twoDropped = true;
    },
    onADrop (e) {
      this.aDropped = true;
    },
    onBDrop (e) {
      this.bDropped = true;
    },
    onAnyDrop (e) {
      this.anyDropped = true
    },
    acceptTypes () {
      return true;
    },
    getRowIndex (item) {
      const vm = this
      // var result = 0
      // if ()
      return vm.formLayoutConfig.rows.indexOf(item)
    },
    getRowKey (row) {
      const vm = this
      // console.log('getRowKey :: vm.formLayoutConfir: ', vm.formLayoutConfig)
      var rowIndex = vm.getRowIndex(row)
      // console.log('getRowKey :: rowIndex = ' + rowIndex)
      // vm.formLayoutConfig.rows.findIndex(loopItem => loopItem === row)
      var result = 'row_' + rowIndex
      if (vm.formLayoutConfig.rows[rowIndex].length > 0) {
        // console.log('vm.formLayoutConfig.rows[rowIndex] : ', vm.formLayoutConfig.rows[rowIndex])
        result = 'row_' + vm.formLayoutConfig.rows[rowIndex][0]._id
        // console.log('vm.formLayoutConfig.rows[rowIndex].length : ', vm.formLayoutConfig.rows[rowIndex].length)
      }
      // console.log('getRowKey ends')
      return result
    }
  }
};
</script>

<!--<style src="splitpanes/dist/splitpanes.css">-->
<!--</style>-->


<style>

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}

.form-layout .col.col-1,
.form-layout .col.col-1,
.form-layout .col.col-2,
.form-layout .col.col-3,
.form-layout .col.col-4,
.form-layout .col.col-5,
.form-layout .col.col-6,
.form-layout .col.col-7,
.form-layout .col.col-8,
.form-layout .col.col-9,
.form-layout .col.col-10,
.form-layout .col.col-11,
.form-layout .col.col-12 {
  padding: 6px !important;
}

.form-layout .card-field.col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.drop-list-col .row {
  border: 2px solid transparent;
}

/*.drop-list-col > .feedback {*/
/*background-color: blue;*/
/*height: 2px; width: 100%;*/
/*}*/
.drop-list-col .drag-source {
  border: 2px dashed rgba(0, 0, 0, .5);
}

/*.reordering-feedback,*/
/*.feedback {*/
/*flex: 0 0 0;*/
/*outline: 1px solid blue;*/
/*align-self: stretch;*/
/*}*/

/* splitpanes */
/*.splitpanes__pane {*/
/*box-shadow: 0 0 3px rgba(0, 0, 0, .2) inset;*/
/*justify-content: center;*/
/*align-items: center;*/
/*display: flex;*/
/*position: relative;*/
/*color: red;*/
/*}*/

.ref-row {
  margin-top: 0;
  /* margin-top: -100%; */
  /*margin-top: 0;*/
  height: 0px !important;
  overflow: hidden;
}

.ref-row .col {
  background-color: lightskyblue;
  text-align: center;
}

.ref-row .col .col-content {
  background-color: lightcoral;
}

.ref-row .col {
  padding-top: 0;
  padding-bottom: 0;
}

.form-layout .v-tabs-bar {
  height: 24px;
}

.form-layout .drag-over {
  background-color: rgba(0,0,0,.3);
}

.drop-container .child-table-mask {
  display: flex !important;
  background-color: rgba(0,0,0,.5);
  /*display: none !important;*/
}
.drop-container.drag-over .child-table-mask {
  display: flex !important;
  background-color: rgba(0,0,0,.5);
}
  .drop-list-col .feedback {
   background-color: blue;
  }
</style>
