<template>
  <div class="field-selection-panes flex-grow-1 d-flex flex-row" style="position:relative;">
    <field-drag-list
        class="mr-1"
        :title="sourceListTitle"
        style="width:50%;"
        :idList="remainingFieldIds"
        buttonColor="cyan"
        buttonIcon="mdi-playlist-plus"
        :button="sourceButton"
        @onButtonClicked="addAll"
        @onItemClicked="addField">
      <template v-slot="slotProps">
        {{ fieldIdLabelMap[slotProps.id] }}
      </template>
    </field-drag-list>

    <div style="width:50%;" class="d-flex flex-column">
      <div class="rounded grey darken-1 white--text px-3 py-1 d-flex flex-row align-center"
        style="height:48px;">
        <h3>{{ targetListTitle }}</h3>
        <v-spacer></v-spacer>
        <v-btn small
               v-if="targetButton"
               dark
               rounded fab
               color="cyan"
               @click="removeAll()">
          <v-icon>mdi-playlist-remove</v-icon>
        </v-btn>
      </div>
      <div class="flex-grow-1" style="position:relative;width:100%;">
        <div style="height:0;min-height:100%;overflow-y:auto;">
          <v-list dense class="d-flex flex-column flex-grow-1 mr-1" style="min-height:100%;">
            <target-list-header
              @click:header="onFixedListHeaderClicked"
              @click:button="removeAllInSection('fixed')"
              :editable="fixedListEditable"
              :selected="currentSection==='fixed'"
              :title="finalFixedListTitle"></target-list-header>

            <div v-if="!fixedListEditable">
              <v-list-item class="list-group-item"
                           v-for="fieldId in fixedFieldIds"
                           :key="fieldId">
                <v-list-item-icon>
                  <v-icon>mdi-arrow-all</v-icon>
                  <!--<v-icon>mdi-format-line-spacing</v-icon>-->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ fieldIdLabelMap[fieldId] }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <draggable v-else
                       :list="fixedFieldIds"
                       group="field"
                       @change="log"
                       class="field-list draggable-list">
              <v-list-item class="list-group-item"
                           v-for="fieldId in fixedFieldIds"
                           :key="fieldId"
                           @click="removeItem(fieldId, 'fixed')">
                <v-list-item-icon>
                  <v-icon>mdi-arrow-all</v-icon>
                  <!--<v-icon>mdi-format-line-spacing</v-icon>-->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ fieldIdLabelMap[fieldId] }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </draggable>

            <target-list-header
                @click:header="currentSection='nonFixed'"
                @click:button="removeAllInSection('nonFixed')"
                :selected="currentSection==='nonFixed'"
                :title="finalNonFixedListTitle"></target-list-header>

            <draggable :list="nonFixedFieldIds"
                       group="field"
                       @change="log"
                       class="field-list draggable-list"
                       style="min-height:30px;position:relative;">

              <v-list-item class="list-group-item"
                           v-for="fieldId in nonFixedFieldIds"
                           :key="fieldId"
                           @click="removeItem(fieldId, 'nonFixed')">
                <v-list-item-icon>
                  <v-icon>mdi-arrow-all</v-icon>
                  <!--<v-icon>mdi-format-line-spacing</v-icon>-->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ fieldIdLabelMap[fieldId] }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </draggable>
             <div v-if="nonFixedFieldIds.length===0"
                  class="text-center no-selection-message w-100">
                <v-avatar v-if="nonFixedPlaceHolder!==''" 
                  color="grey"
                  size="48">
                  <span class="white--text text-h7">
                  {{ $t(nonFixedPlaceHolder) }}
                  </span>
                </v-avatar>
               <!--<div style="border-radius:0.5rem;"-->
               <!--class="text-h5 mx-auto lightgray">-->
               <!---->
               <!--</div>-->
              </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import fieldDragList from '@/components/dialogs/comps/FieldDragList'
import targetListHeader from './TargetListHeader'

export default {
  name: 'fieldSelectionPanes',
  components: {
    draggable,
    fieldDragList,
    targetListHeader
  },
  data () {
    return {
      currentSection: 'nonFixed'
      // nonFixedFieldIds2: ['1','2','3','4','5'],
      // fieldInfos: [
      //   {id: '1', label: 'info 1'},
      //   {id: '1', label: 'info 1'},
      //   {id: '1', label: 'info 1'},
      //   {id: '1', label: 'info 1'},
      //   {id: '1', label: 'info 1'},
      //   {id: '1', label: 'info 1'},
      //   {id: '1', label: 'info 1'},
      //
      // ]
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    sourceListTitle: String,
    targetListTitle: String,
    sourceButton: {type: Boolean,default: true},
    targetButton: {type: Boolean,default: true},

    fixedListEditable: {type: Boolean, default: true},

    fixedListTitle: {
      type: String,
      default: ''
    },
    nonFixedListTitle: {
      type: String,
      default: ''
    },
    nonFixedPlaceHolder: {
      type: String,
      default: 'general.all'
    },

    fieldInfos: Array,
    value: Object,
    numericFieldIds: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    // fixedFieldIds: {
    //   get () {
    //     return this.value.fixedFieldIds
    //   },
    //   set (val) {
    //     this.value.fixedFieldIds = val
    //   }
    // },
    // nonFixedFieldIds: {
    //   get () {
    //     return this.value.nonFixedFieldIds
    //   },
    //   set (val) {
    //     this.value.nonFixedFieldIds = val
    //   }
    // },
    finalFixedListTitle () {
      return this.fixedListTitle === '' ?
        this.$t('view.fixedFields') :
        this.fixedListTitle
    },
    finalNonFixedListTitle () {
      return this.nonFixedListTitle === '' ?
        this.$t('view.nonFixedFields') :
        this.nonFixedListTitle
    },
    // selectedFieldIds: {
    //   get () {
    //     const vm = this
    //     var result = []
    //     if (vm.value) {
    //       result = vm.value
    //     }
    //     return result
    //   },
    //   set (val) {
    //     const vm = this
    //     console.log('FieldSelectionPanes :: computed(selectedFieldIds) :: set (val): ', val)
    //     vm.$emit('input', val)
    //   }
    // },
    fixedFieldIds: {
      get () {
        const vm = this
        var result = []
        if (vm.value && vm.value.fixedFieldIds) {
          result = vm.value.fixedFieldIds
        }
        return result
      },
      set (val) {
        const vm = this
        // console.log('FieldSelectionPanes :: computed(fixedFieldIds) :: set(val): ', val)
        vm.$emit('input', {
          fixedFieldIds: val,
          nonFixedFieldIds: vm.nonFixedFieldIds
        })
      }
    },

    nonFixedFieldIds: {
      get () {
        const vm = this
        // console.log('FieldSelectionPanes :: nonFixedFieldIds :: get')
        var result = []
        if (vm.value && vm.value.nonFixedFieldIds) {
          result = vm.value.nonFixedFieldIds
        }
        return result
      },
      set (val) {
        const vm = this
        vm.$emit('input', {
          fixedFieldIds: vm.fixedFieldIds,
          nonFixedFieldIds: val
        })
      }
    },

    allFieldIds () {
      const vm = this
      var result = []
      if (vm.fieldInfos) {
        result = vm.fieldInfos.map(fieldInfo => fieldInfo.fieldId)
      }
      return result
    },

    remainingFieldIds () {
      const vm = this
      return vm.allFieldIds.filter(id => (
        !vm.fixedFieldIds.includes(id) &&
        !vm.nonFixedFieldIds.includes(id)
      ))
    },

    fieldIdLabelMap () {
      // mappings: [
      //    {fieldId: {fieldName}},
      //    {fieldId: {fieldName}},
      //    {fieldId: {fieldName}}
      // ]
      const vm = this
      var result = {}
      for (var i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i]
        result[loopFieldInfo.fieldId] = Object.keys(loopFieldInfo.properties).indexOf('fieldName') >= 0 ?
          loopFieldInfo.properties['fieldName'] :
          '(' + vm.$t('general.unspecified') + ')'
        if (Object.keys(loopFieldInfo.properties).indexOf('fieldNameTag') >= 0) {
          result[loopFieldInfo.fieldId] = vm.$t(loopFieldInfo.properties['fieldNameTag'])
        }
      }
      return result
    }
  },
  created () {

  },
  methods: {
    onFixedListHeaderClicked () {
      const vm = this
      if (vm.fixedListEditable) {
        vm.currentSection='fixed'
      }
    },    
    isNumericField (fieldId) {
      return this.numericFieldIds.includes(fieldId)
    },
    addAll () {
      const vm = this
      var ids = JSON.parse(JSON.stringify(vm.remainingFieldIds))
      switch (vm.currentSection) {
        case 'fixed':
          vm.fixedFieldIds = vm.fixedFieldIds.concat(ids)
          break
        case 'nonFixed':
          vm.nonFixedFieldIds = vm.nonFixedFieldIds.concat(ids)
          break
      }
    },
    removeItem (fieldId, section) {
      const vm = this
      var idx
      // console.log('removeItem :: fieldId = ' + fieldId)
      // console.log('removeItem :: section = ' + section)
      switch (section) {
        case 'fixed':
          idx = vm.fixedFieldIds.findIndex(id => id === fieldId)
          if (idx >= 0) {
            vm.fixedFieldIds.splice(idx, 1)
          }
          break
        case 'nonFixed':
          idx = vm.nonFixedFieldIds.findIndex(id => id === fieldId)
          if (idx >= 0) {
            vm.nonFixedFieldIds.splice(idx, 1)
          }
          break
      }
      // vm.updateTableSettings(vm.fixedFieldIds, vm.nonFixedFieldIds)
    },
    removeAll () {
      const vm = this
      // console.log('removeAll')
      vm.fixedFieldIds = []
      vm.nonFixedFieldIds = []
    },
    removeAllInSection (section) {
      const vm = this
      // console.log('FieldSelectionPanes :: removeAllInSection :: section : ' + section)
      switch (section) {
        case 'fixed':
          vm.fixedFieldIds = []
          break
        case 'nonFixed':
          vm.nonFixedFieldIds = []
          break
      }
    },
    addField (fieldId) {
      const vm = this
      if (vm.currentSection === 'fixed') {
        vm.fixedFieldIds.push(fieldId)
      } else if (vm.currentSection === 'nonFixed') {
        vm.nonFixedFieldIds.push(fieldId)
      }
    },
    log: function (evt) {
      console.log('log: ', evt);
    }
  }

}

</script>


