const propertyHelperMixin = {
  methods: {
    getFieldInfoByFieldId (fieldId, fieldInfos) {
      return fieldInfos.find(info => info.fieldId === fieldId)
    },
    getAttrColorSetObjects (attrColorsStr) {
      const vm = this
      // console.log('getAttrColors: attrColorsStr = ' + attrColorsStr)
      const colorPairs = attrColorsStr.split(',')
      const result = []
      for(var i = 0; i < colorPairs.length; i++) {
        const loopColorPair = colorPairs[i].split('/')
        result.push({
          color: loopColorPair[0],
          backgroundColor: loopColorPair[1]
        })
      }
      return result
    },

    getFieldOptionsAttrsIds (fieldInfo) {
      switch (fieldInfo.type) {
        case 'singleSelection':
          return fieldInfo.properties['inputOptionsSingle']
        case 'multipleSelection':
          return fieldInfo.properties['inputOptionsMultiple']
      }
    },

    getFieldOptionsAttrsIdsx (fieldInfo) {
      const vm = this
      console.log('getFieldOptionsAttrsIds: fieldInfo: ', fieldInfo)
      var resultOptions = []
      var resultIds = []
      var resultAttributes = {}
      switch (fieldInfo.type) {
        case 'singleSelection':
          // return {
          //      options
          //      attributes
          // }
          const optionsAttrsIdsStr = fieldInfo.properties['inputOptionsSingle']
          if (optionsAttrsIdsStr !== '') {
            const optionsAttrsIds = optionsAttrsIdsStr.split('||')

            // console.log('optionsAttrsIdsStr !== empty')
            // options
            resultOptions = optionsAttrsIds[0].split('|')
            for (var j = 0; j < resultOptions.length; j++) {
              resultIds.push(j)
            }

            // console.log('optionsAttrsIds.length > 1')

            // attributes
            if (optionsAttrsIds.length > 1) {
              const attributes = optionsAttrsIds[1].split('|')
              for (var i = 0; i < attributes.length; i++) {
                const attribute = attributes[i]
                const keyValue = attribute.split(':')
                if (keyValue.length > 1) {
                  switch (keyValue[0]) {
                    case 'colors':
                      resultAttributes[keyValue[0]] = vm.getAttrColorSetObjects(keyValue[1])
                      break
                    default:
                      resultAttributes[keyValue[0]] = keyValue[1]
                  }
                } else {
                  resultAttributes[keyValue[0]] = ''
                }
              }
            }

            // ids
            if (optionsAttrsIds.length > 2) {
              const ids = optionsAttrsIds[2].split(',')
              for (var k = 0; k < ids.length; k++) {
                resultIds[k] = ids[k].toString()
              }
            }
          }
          break
      }
      const result = {
        options: resultOptions,
        attributes: resultAttributes,
        ids: resultIds
      }
      return result
    }
  }
}

export default propertyHelperMixin
