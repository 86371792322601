<template>
  <FormElement
    :label="$t('workflow.modules.generateFile.label.selectTemplate')"
    name="template"
  >
    <ComboBox
      :value="value || null"
      :options="list"
      valueFormat="object"
      :placeholder="
        $t('workflow.modules.generateFile.label.selectTemplatePlaceholder')
      "
      @selected="changed"
    />
  </FormElement>
</template>
<script>
import ComboBox from "../../../form/comboBox";
import FormElement from "../shared/formElement";
import Mixin from "../mixin";
import { pick, get } from "lodash";
import { SET_SETTINGS } from "@/store/modules/workflow/action_types";

export default {
  props: {
    value: String,
  },
  mixins: [Mixin],
  components: {
    ComboBox,
    FormElement,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    selectedFormId() {
      return get(this.dataSource, "form", "")  || get(this.dataSource, "properties.worksheet", "")
    },
  },
  watch: {
    selectedFormId(val, old) {
      if (val && val !== old) {
        this.clear()
        this.fetchPrintTemplates(val);
      }
    },
  },
  mounted() {
    if (this.selectedFormId) {
      this.fetchPrintTemplates(this.selectedFormId);
    }
  },
  methods: {
    fetchPrintTemplates(formId) {
      this.printTemplates = [];
      this.$store
        .dispatch("FETCH_PRINT_TEMPLATES", { formId, type: 1 })
        .then((res) => {
          this.list = this.normalize(res);
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    normalize(list = []) {
      return list.map((option) => {
        const { _id: id, templateName: label } = pick(option, [
          "_id",
          "templateName",
        ]);
        return { id, label };
      });
    },
    clear(){
      this.$store.dispatch(SET_SETTINGS, { template: null });
    },
    changed(v) {
      this.$store.dispatch(SET_SETTINGS, { template: v });
    },
  },
};
</script>
