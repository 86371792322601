<template>
  <v-menu
    v-if="regions"
    offset-y
    :close-on-content-click="false"
    min-width="312"
    :disabled="readonly"
    :nudge-top="bl_tableCell ? '0' : '13'"
    class="regionMenu"
    offset-overflow
  >
    <template v-slot:activator="{ on }">
      <div style="height: 100%" v-on="on">
        <v-text-field
          v-if="!readonly"
          outlined
          dense
          v-model="RegionText"
          class="region-textfield"
          background-color="white"
          style="height: 100%"
          readonly
          clearable
          @click:clear="onClear"
          clear-icon="mdi-close"
          :required="required"
          :rules="rules"
          ref="regionText"
          :hide-details="bl_tableCell"
          :class="textFieldClass"
          :error="hasError"
        />
        <div v-else>{{ RegionText }}</div>
      </div>
    </template>
    <v-card min-height="250" width="312" tile class="vxe-table--ignore-clear">
      <v-tabs v-model="tab" height="42" centered :fixed-tabs="tabs.length < 3">
        <v-tabs-slider color="teal"></v-tabs-slider>
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :disabled="isTabDisabled(tab)"
        >
          {{ $t("regionMenu." + tab) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" v-if="value">
        <v-tab-item :transition="false" :reverse-transition="false">
          <v-card flat>
            <v-card-text>
              <v-chip
                class="ml-2"
                :class="
                  value.province && value.province == area._id ? 'success' : ''
                "
                @click="setArea(area)"
                v-for="area in regions"
                :key="area._id"
              >
                {{ area.name }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <v-card flat>
            <v-card-text>
              <v-chip
                class="ml-2 mt-1"
                :class="
                  value.city && value.city == district._id ? 'success' : ''
                "
                @click="setDistrict(district)"
                v-for="district in filteredDistrict"
                :key="district._id"
              >
                {{ district.name }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <v-card flat>
            <v-card-text>
              <v-chip
                class="ml-2 mt-1"
                :class="
                  value.state && value.state == subDistrict._id ? 'success' : ''
                "
                @click="setSubDistrict(subDistrict)"
                v-for="subDistrict in filteredSubDistrict"
                :key="subDistrict._id"
              >
                {{ subDistrict.name }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    fieldInfo: Array,
    value: Object,
    regions: Array,
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: [],
    },
    bl_tableCell: {
      type: Array,
      default: false,
    },
    textFieldClass: {
      type: String,
      default: "",
    },
    hasError:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  computed: {
    tabs() {
      var TabsMapping = {
        province: ["area"],
        provinceCity: ["area", "district"],
        provinceCityState: ["area", "district", "subDistrict"],
      };
      return TabsMapping[this.fieldInfo.properties.regionType];
    },
    fieldId() {
      return this.fieldInfo.fieldId;
    },

    filteredDistrict() {
      let vm = this;
      if (vm.value) {
        if (vm.value.province) {
          var area = vm.regions.find((item) => item._id == vm.value.province);
          if (area) return area.districts;
        }
      }
      return [];
    },
    filteredSubDistrict() {
      let vm = this;
      if (vm.value) {
        if (vm.value.province && vm.value.city) {
          var area = vm.regions.find((item) => item._id == vm.value.province);
          if (area) {
            var district = area.districts.find(
              (item) => item._id == vm.value.city
            );
            if (district) return district.subDistricts;
          }
        }
      }
      return [];
    },
    RegionText: {
      get() {
        let str = "";
        if (this.value) {
          if (this.value.province) {
            var area = this.regions.find(
              (item) => item._id == this.value.province
            );
            if (area) {
              str = area.name;
              var district = area.districts.find(
                (item) => item._id == this.value.city
              );
              if (district) {
                str += " - " + district.name;
                var subDistrict = district.subDistricts.find(
                  (item) => item._id == this.value.state
                );
                if (subDistrict) str += " - " + subDistrict.name;
              }
            }
          }
        }
        return str;
      },
      set(val) {
        if (!val) this.value.regionText = "";
        else this.value.regionText = val;
      },
    },
  },
  methods: {
    resetValidation() {
      this.$refs.regionText.resetValidation();
    },
    validate(){
      const valid = this.$refs.regionText.validate()
      return valid
    },
    setArea(area) {
      this.value.state = null;
      this.value.city = null;
      this.value.province = area._id;
      if (this.fieldInfo.properties.regionType != "province") this.tab++;
    },
    setDistrict(district) {
      this.value.state = null;
      this.value.city = district._id;
      if (this.fieldInfo.properties.regionType == "provinceCityState")
        this.tab++;
    },
    setSubDistrict(subDistrict) {
      this.value.state = subDistrict._id;
    },
    isTabDisabled(tab) {
      let result = false;
      switch (tab) {
        case "area":
          result = false;
          break;
        case "district":
          result = Boolean(!this.value || !this.value.province);
          break;
        case "subDistrict":
          result = Boolean(
            !this.value || !this.value.province || !this.value.city
          );
          break;
      }
      return result;
    },
    onClear() {
      Object.assign(this.value, {
        city: null,
        province: null,
        state: null,
        regionText: "",
      });
      this.tab = 0;
    },
  },
};
</script>

<style>
.region-textfield.v-input--is-readonly > .v-input__control > .v-input__slot {
  background-color: #fff !important;
}
.v-slider__thumb-container,
.v-slider__track-background,
.v-slider__track-fill {
  transition: none !important;
}
.region-textfield .v-input__control,
.region-textfield .v-input__slot {
  height: 100%;
}
.edit-region-textfield .v-input__slot {
  border-radius: 0px !important;
  border: 0px solid black !important;
}
.edit-region-textfield .v-input__slot fieldset {
  border: 1px solid #dcdfe6;
}
.edit-region-textfield.v-input--is-focused .v-input__slot fieldset {
  border: 1px solid #409eff !important;
}
</style>
