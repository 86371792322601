<template>
    <v-dialog 
        v-model="value" 
        width="400"
        persistent
    >
        <dialog-header
            :icon="icon"
            label="Verification"
            :closable="false"
        />
        <v-card class="">
            <v-card-text class="pa-3 px-8">
                {{$t('publicEdit.password')}}
                <div class="d-flex">
                    <v-text-field
                        outlined
                        dense
                        height="30"
                        type="password"
                        class="passwordInput"
                        @keydown="handlePasswordKeyDown"
                        v-model="password" 
                    >
                    </v-text-field>
                    <v-btn @click="submit" class="ml-3" fab x-small elevation="0" color="primary" dark>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </v-card-text>
        </v-card> 
    </v-dialog>
</template>

<script>
    import dialogHeader from '@/components/dialogs/comps/DialogHeader'
    export default {
        props:{
            value: Boolean
        },
        components:{
            dialogHeader
        },
        data(){
            return{
                icon: 'mdi-lock',
                password: ''
            }
        },
        methods:{
            submit(){
                this.$emit('onCommand',{
                    command: 'submitPassword',
                    value: this.password
                })
            },
            handlePasswordKeyDown(e){
                if(e.key === 'Enter'){
                    this.submit()
                }
            }
        }
    }
</script>

<style>
    .passwordInput .v-input__slot{
        min-height: 30px !important;
    }
</style>