<template>
  <div>
    <v-text-field
      v-model="date"
      solo
      hide-details="auto"
      outlined
      dense
      :append-icon="required?'':'mdi-close'"
      prepend-inner-icon="mdi-calendar"
      readonly
      @click:append="clearDate"
      @click="showing = true"
    ></v-text-field>
    <v-dialog
      ref="dialog"
      v-model="showing"
      v-if="showing"
      :close-on-content-click="false"
      :return-value.sync="date"
      width="290px"
    >
      <v-date-picker
        v-model="date"
        scrollable
        no-title
        no-footer
        @change="onChanged"
      >
        <template v-if="range">
          <v-spacer></v-spacer>ssssss
          <v-btn text color="primary" @click="showing = false">
            {{ $t("buttons.cancel") }}
          </v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)">
            {{ $t("buttons.ok") }}
          </v-btn>
        </template>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import dateTimeHelperMixin from "@/mixins/dateTimeHelperMixin";

export default {
  name: 'dateDialogField',
  mixins: [dateTimeHelperMixin],
  data() {
    return {
      date: "",
      showing: false,
    };
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: String,
    range: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.loadDate()
  },
  watch: {
    'value': function(newVal, oldVal) {
      const vm = this;
      vm.loadDate()
    },
  },
  methods: {
    clearDate () {
      this.$emit("input", '');
    },
    loadDate () {
      const vm = this
      if (vm.value === "" && vm.required) {
        vm.date = vm.getToday();
        vm.$emit('input', vm.date)
      } else {
        vm.date = vm.value;
      }
    },
    validate (state) {
      return this.date!==null && this.date!==''
    },
    onChanged(val) {
      const vm = this;
      // console.log("DateDialogField :; onChanged val = " + val);
      vm.$refs.dialog.save(val);
      vm.$emit("input", val);
      vm.showing = false;
    },
  },
};
</script>
