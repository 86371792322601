<template>
<div class="image-wrapper"
    :style="wrapperStyle">
    <v-img
        class="card-image position-relative"
        width="100%!important"
        height="100%!important"
        :src="imageSrc"
        :class="imageClass"
        :contain="cardConfig.cardImageMode!=='cover'">
      <zoom-preview
        :mediaId="mediaId"
        style="position:absolute;"
        class="fill-height w-100"></zoom-preview>
    </v-img>
</div>    
</template>

<script>
import zoomPreview from '@/components/zoomPreview'

export default {
    name: 'cardImage',
    components: {
        zoomPreview
    },
    props: {
        imageSrc: String,
        cardConfig: Object
    },
    computed: {
      adjustedCardConfig () {
        const vm = this
        var result = {
          cardSummaryFieldId: '',
          cardImageFieldId: '',
          cardImagePosition: 'left',
          cardImageMode: 'cover',
          cardImageStretch: true,
          cardImageSize: '48',
          cardImagePadding: 0,
          cardDisplayFieldName: false,
          cardDisplayFieldIds: []
        }
        return {...result, ...vm.cardConfig}
      },
        isRegular () {
            const vm = this
            const pos = vm.adjustedCardConfig.cardImagePosition
            const stretch = vm.adjustedCardConfig.cardImageStretch
            var result = 
                (pos !== 'top') &&
                !(pos === 'left' && stretch) &&
                !(pos === 'right' && stretch)
            return result
        },
        imageClass () {
            const vm = this
            var result = ''
            if (vm.isRegular) {
                switch (vm.adjustedCardConfig.cardImageMode) {
                    case 'circle':
                        result = 'circle-image'
                        break
                    case 'square':
                        result = 'square-image'
                        break
                }
            }
            return result
        },
        wrapperStyle () {
            const vm = this
            var result = {}
            const imageSizePx = vm.adjustedCardConfig.cardImageSize + 'px'
            switch (vm.adjustedCardConfig.cardImagePosition) {
                case 'left':
                    result['width'] = imageSizePx
                    result['height'] = vm.adjustedCardConfig.cardImageStretch ? '100%' : imageSizePx
                    result['margin-right'] = vm.adjustedCardConfig.cardImageStretch ? '0' : '4px'
                    break
                case 'top':
                    result['width'] = '100%'
                    result['height'] = imageSizePx
                    break
                case 'right':
                    result['width'] = imageSizePx
                    result['height'] = vm.adjustedCardConfig.cardImageStretch ? '100%' : imageSizePx
                    result['margin-left'] = vm.adjustedCardConfig.cardImageStretch ? '0' : '4px'
                    break
            }
            if (
              vm.adjustedCardConfig.cardImagePadding &&
              vm.adjustedCardConfig.cardImagePadding>0) {
              result['padding'] = vm.adjustedCardConfig.cardImagePadding + 'px'
            }

            return result
        }
    }
}
</script>
<style>
  .card-image {
    background-color: rgba(0, 0, 0, .05);
  }

  .circle-image {
/*     width: 80px !important;
    height: 80px !important; */
    border-radius: 50% !important;
  }

  .square-image {
/*     width: 80px !important;
    height: 80px !important;
 */    border-radius: 0 !important;
  }

  .card-image .v-image__image--contain {
    background-position: top center !important;
  }
</style>