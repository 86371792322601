<template>
  <div class="workflow-setting-form-view-dependency" :style="fontColor">
    <v-icon :color="color">{{ icon }}</v-icon>
    <span class="workflow-setting-form-view-dependency-label">{{ label }}</span>
  </div>
</template>

<script>


/*
* Target Module Sub-Forms Copncrete View Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    node: Object,
  },
  computed: {
    icon() {
      const { node } = this;
      return node.outputRequired ? 'mdi-check-circle' : 'mdi-close-circle';
    },
    label() {
      const { node, node: { dependsNode } } = this;
      const label = dependsNode ? this.$t(`workflow.modules.${dependsNode.type}.label`) : {};
      return node.outputRequired ? label.pass : label.fail;
    },
    color() {
      const { node } = this;
      return node.outputRequired ? '#01ca86' : '#f44336';
    },
    fontColor() {
      return {
        color: this.color,
      }
    },
  },
};

</script>

<style scoped>
  
</style>
