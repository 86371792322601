<template>
  <v-dialog v-model="showingDialog" width="380px" persistent>
    <v-card>
      <dialog-header
        @close="showingDialog = false"
        :label="$t('workAdmin.bindTitle')"
      />
      <v-card-text class="mt-2">
        <div class="current-account">
          <p class="account-title">{{ $t("workAdmin.currentLogin") }}</p>
          <p>
            <span class="label">{{ $t("workAdmin.email") }}:</span
            ><span> {{ (user && user.email) || "-" }} </span>
          </p>
          <p>
            <span class="label">{{ $t("workAdmin.phone") }}:</span
            ><span> {{ mobile }} </span>
          </p>
        </div>
        <p class="account-title">{{ $t("workAdmin.newLogin") }}</p>
        <v-select
          :label="$t('workAdmin.email')"
          outlined
          dense
          :items="emails"
          :menu-props="{ 'offset-y': true }"
          v-model="result.email"
        /><v-select
          :label="$t('workAdmin.phone')"
          outlined
          dense
          :items="phones"
          :menu-props="{ 'offset-y': true }"
          v-model="result.mobile"
        />
      </v-card-text>
      <dialog-actions
        :buttons="['cancel', 'save']"
        @onCommand="onCommandHandler"
        :actionDisabled="disabled"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from "@/components/dialogs/comps/DialogHeader";
import DialogActions from "@/components/dialogs/comps/DialogActions";
import PhoneNumber from "awesome-phonenumber";
export default {
  props: {
    value: Boolean,
    user: Object,
    emails: Array,
    phones: Array,
    employeeId:Number
  },
  components: {
    DialogHeader,
    DialogActions,
  },
  data() {
    return {
      commandMap: {
        cancel: () => (this.showingDialog = false),
        save: this.save,
      },
      result: {
        email: "",
        mobile: "",
      },
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    mobile() {
      const { mobilePrefix, mobile } = this.user || {};
      if (mobile) {
        return `+${mobilePrefix} - ${mobile}`;
      } else return "-";
    },
    disabled() {
      return this.loading || (!this.result.email && !this.result.mobile);
    },
  },
  methods: {
    onCommandHandler({ command, ...payload }) {
      return this.commandMap[command]
        ? this.commandMap[command](payload)
        : null;
    },
    save() {
      const result = {
        ...this.result,
        ...this.extractPhoneInfo(this.result.mobile),
        employeeId:this.employeeId
      };
      this.$emit("submit", result);
    },
    extractPhoneInfo(val) {
      const result = {
        mobile: null,
        mobilePrefix: null,
      };
      const phoneNumber = new PhoneNumber(val);
      if (phoneNumber.isValid()) {
        result["mobile"] = phoneNumber.getNumber("significant");
        result["mobilePrefix"] = phoneNumber.getCountryCode().toString();
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.current-account {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 20px;
  p {
    margin-bottom: 0px;
  }
}
.account-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
}
</style>
