<template>
  <base-tabs-dialog
      ref="baseTabsDialog"
      icon="mdi-window-restore"
      :title="$t('messages.selectCopyFields')"
      @submit="onSubmitHandler"
      @onCommand="onCommandHandler"
      :loading="loading"
      :actionDisabled="!isValidData"
      v-model="showing"
      :tabs="tabs">
    <!--:isValidData="isValidData"-->
    <template>
      <v-tab-item v-for="(tab, index) in tabs" :key="tab.refName"
                  :eager="true"
                  :transition="false"
                  :reverse-transition="false"
                  class="fill-height">
        <component :is="tab.componentName"
                   :tabId="tab.id"
                   :ref="tab.refName"
                   :fieldInfos="relatedFieldInfos"
                   :tabKey="tab.key"
                   :options="options"
                   :errorCount="tab.errorCount"
                   v-model="tabData"
                   @onCommand="onCommandHandler"></component>
      </v-tab-item>
    </template>
  </base-tabs-dialog>
</template>

<script>
import widgetHelperMixin from '@/mixins/widgetHelperMixin'
import baseTabsDialog from './BaseTabsDialog'
import tabs from '@/components/dialogs/tabs'

export default {
  name: 'copyRelatedRecordFieldsDialog',
  mixins: [
    widgetHelperMixin
  ],
  components: {
    baseTabsDialog,
    // ,
  },
  data () {
    return {
      tabData: {
        relatedToLocalFields: []
        // cardFields: [],
        // selectionTable: {
        //   fixedFieldIds: [],
        //   nonFixedFieldIds: [],
        //   filterInfo: {},
        //   sortInfo: {}
        // },
        // displayTable: {
        //   fixedFieldIds: [],
        //   nonFixedFieldIds: [],
        //   filterInfo: {},
        //   sortInfo: {}
        // }
      },
      tabDataxxx: {
        fields: [
          {id: 'f0001', label: 'field 1', active: false},
          {id: 'f0002', label: 'field 2', active: false},
          {id: 'f0003', label: 'field 3', active: false},
          {id: 'f0004', label: 'field 4', active: false},
          {id: 'f0005', label: 'field 5', active: false},
          {id: 'f0006', label: 'field 6', active: false},
        ],
        selectionTable: {
          fixedFieldIds: [],
          nonFixedFieldIds: [],
          filters: [],
          filterAndOr: 'and' // [and|or]
        }
      },
      showing: false,
      loading: false,
      localFieldInfos: [],
      relatedFieldInfos: [],

      tabs: []
    }
  },
  watch: {
    showing: function (newVal) {
      if (newVal === false) {
        this.loading = false
      }
    }
  },
  computed: {
    isValidData () {
      const vm = this
      return vm.checkValidity()
    },
    cardNonDisplayFieldTypes () {
      return this.$store.getters.cardNonDisplayFieldTypes
    },
    dataSources () {
      return this.$store.getters.dataSources
    },
    allWidgets () {
      return this.$store.getters.allWidgets
    }
  },
  methods: {
    checkTabData () {
      const vm = this
      if (vm.tabData === '') {
        vm.tabData = {
          relatedToLocalFields: {}
        }
      }
    },
    open (params, dataSource, localFieldInfos, tabs) {
      const vm = this
      vm.tabs = tabs
      vm.tabData = JSON.parse(JSON.stringify(params))
      vm.checkTabData()
      vm.$refs.baseTabsDialog.tabIndex = 0
      vm.options = {
        localFieldInfos: localFieldInfos,
        relatedFieldInfos: vm.getRelatedFieldInfos(dataSource)
      }
      vm.showing = true
    },
    checkValidity () {
      const vm = this
      var result = true
      console.log('isValidData :: refs: ', vm.$refs)
      const tabCount = vm.tabs.length
      const refCount = Object.keys(vm.$refs).length
      console.log('isValidData :: tabCount= ' + tabCount)
      console.log('isValidData :: refCount = ' + refCount)
      if (refCount >= tabCount) {
        for (let i = 0; i < tabCount; i++) {
          const refObj = vm.getTabRefByIndex(i)
          console.log('refObj: ', refObj)
          if (refObj) {
            if (typeof refObj.checkErrorCount === 'function') {
              console.log('is function')
              if (!refObj.checkErrorCount(true)) {
                result = false
              }
            }
          }
        }
      }
      return result
    },

    onCommandHandler (payload) {
      const vm = this
      console.log('copyRelatedRecordFieldsDialog :: onCommandHandler :: payload: ', payload)
      var tabIndex = 0
      switch (payload.command) {
        case 'updateErrorCount':
          console.log('onCommandHandler :: updateErrorCount tabId=' + payload.tabId + ', count=' + payload.errorCount)
          tabIndex = vm.tabs.findIndex(tab => tab.id === payload.tabId)
          if (tabIndex >= 0) {
            vm.tabs[tabIndex].errorCount = payload.errorCount
          }
          break
        case 'checkValidity':
          tabIndex = payload.tabIndex
          const refObj = vm.getTabRefByIndex(tabIndex)
          console.log('copyRelatedRecordFieldsDialog :: onCommandHandler command=checkValidity :: refObj: ', refObj)
          console.log('copyRelatedRecordFieldsDialog :: onCommandHandler command=checkValidity :: tabIndex = ' + tabIndex)
          refObj.checkErrorCount(true)
          break
      }
    },

    getTabRefByIndex (tabIndex) {
      const vm = this
      var result = null
      console.log('getTaRefByIndex: tabIndex: ' + tabIndex + ': vm.tabs: ', vm.tabs)

      const refName = vm.tabs[tabIndex].refName
      console.log('getTabRefByIndex :: refName = ' + refName)
      const refObj = vm.$refs[refName]
      if (refObj) {
        console.log('refObj exists')
        if (Array.isArray(refObj)) {
          console.log('refObj is array')
          result = refObj[0]
        } else {
          console.log('refObj is not array')
          result = refObj
        }
      }
      return result
    },

    getRelatedFieldInfos (dataSource) {
      const vm = this
      var result = []
      const dataSourceSummary = vm.dataSources.find(
        ds => ds.dataTableId === dataSource
      )
      if (dataSourceSummary) {
        result = dataSourceSummary.fieldInfos
      }
      return result
    },

    getTitleFieldId () {
      const vm = this
      const titleFieldInfoId = vm.$store.getters
        .formInfo.titleFieldInfoId
      const fieldInfo = vm.$store.getters.formFieldInfos.find(
        info => info._id === titleFieldInfoId)
      return fieldInfo ? fieldInfo.fieldId : ''
    },

    onSubmitHandler () {
      const vm = this
      var fields = []
      for (let i = 0; i < vm.tabData.relatedToLocalFields.length; i++) {
        const loopField = vm.tabData.relatedToLocalFields[i]
        fields.push({
          id: loopField.id,
          label: loopField.label,
          active: loopField.active
        })
      }
      vm.$emit('confirm', vm.tabData)
      vm.showing = false
    }
  }
}
</script>
