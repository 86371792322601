<template>
 <v-dialog
     v-model="showingDialog"
     v-if="showingDialog"
     persistent
     width="800"
     max-width="90%">
      <v-card>
        <dialog-header :label="$t('widgets.properties.dataSourceSelection')"
                       @close="showingDialog=false"></dialog-header>
        <v-card-text style="height: 540px;" class="d-flex mt-2 flex-column fill-height">
          <v-row class="flex-grow-1">
            <v-col cols="4" class="d-flex flex-column rounded">
              <div>{{ $t('general.team') }}</div>
              <v-card outlined class="flex-grow-1" style="height:0;min-height:100%;overflow-y:auto;">
                  <v-list dense class="pa-0">
                    <v-list-item-group v-model="selectedTeamIndex" color="primary" mandatory>
                      <v-list-item v-for="team in teamAppData" :key="team._id" class="px-2">
                        <v-list-item-avatar>
                          <v-img :src="team.logo ? team.logo : ''"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ team.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
              </v-card>
            </v-col>
            <v-col cols="4">
              <div>{{ $t('general.application') }}</div>
              <v-card outlined class="flex-grow-1" style="height:0;min-height:100%;overflow-y:auto;">
                  <v-list v-if="selectedTeam" dense class="pa-0">
                    <v-list-item-group v-model="selectedAppIndex" color="primary">
                      <v-list-item v-for="app in selectedTeam.apps" :key="app._id" class="px-2">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ app.title }}<br/>
                            
                              <v-chip x-small>
                                <span class="grey--text mr-2">{{ $t('roles.owner') }}</span>
                                {{ app.owner }}</v-chip>
                            </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
              </v-card>
            </v-col>
            <v-col cols="4">
              <div>{{ $t('general.dataTable') }}</div>
              <v-card outlined class="flex-grow-1" style="height:0;min-height:100%;overflow-y:auto;">
                  <v-list v-if="selectedApp" dense class="pa-0">
                    <v-list-item-group v-model="selectedDataTableIndex" color="primary" mandatory>
                      <v-list-item 
                        :disabled="dataTable._id===currentForm._id"
                        v-for="dataTable in excludeSelf(selectedApp.dataTables)" :key="dataTable._id" class="px-2">
                        <v-list-item-content>
                          <v-list-item-title>{{ dataTable.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <dialog-actions 
          class="mt-2"
          :actionDisabled="!selectedDataTable"
          @onCommand="onCommandHandler"
          :buttons="['cancel', 'ok']"
        ></dialog-actions>
        <v-overlay v-if="loading">
          <v-icon class="align-self-center"
          x-large>mdi-spin mdi-loading</v-icon>
        </v-overlay>
      </v-card>
    </v-dialog>
</template>

<script>
import dialogHeader from './comps/DialogHeader'
import dialogActions from './comps/DialogActions'

export default {
  name: 'dataSourceDialog',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: Object
  },
  components: {
    dialogHeader,
    dialogActions,
  },

  data () {
    return {
      showingDialog: false,
      teamAppData: [],
      selectedTeamIndex: 0,
      selectedAppIndex: -1,
      selectedDataTableIndex: -1,
      loading: false
    }
  },
  watch: {
    selectedTeamIndex: function(newVal) {
      const vm = this
      if (newVal) {
        const apps = vm.teamAppData[newVal].apps
        vm.selectedAppIndex = apps.length > 0 ? 0 : -1
      }
    },
    selectedAppIndex: function (newVal) {
      const vm = this
      if (newVal) {
        const dataTables = vm.selectedTeam.apps[newVal].dataTables
        vm.selectedDataTableIndex = dataTables.findIndex(t => t._id !== vm.currentForm._id)
      }
      // vm.$nextTick(() => {
      //   vm.selectedDataTableIndex = vm.selectedApp && vm.selectedApp.dataTables.length > 0 ? 0 : -1
      //   // console.log('selectedAppIndex :: vm.selectedDataTableIndex = ' + vm.selectedDataTableIndex)
      // })
    }
  },
  computed: {
    activeTeam () {
      return this.$store.getters.activeTeam
    },
    currentApp () {
      return this.$store.getters.currentApp
    },
    selectedTeam () {
      const vm = this
      return vm.teamAppData[vm.selectedTeamIndex]
    },
    selectedApp () {
      const vm = this
      var result = null
      if (vm.selectedTeam && vm.selectedAppIndex >= 0) {
        result = vm.selectedTeam.apps[vm.selectedAppIndex]
      }
      return result
    },
    selectedDataTable () {
      const vm = this
      var result = null
      if (vm.selectedApp && vm.selectedDataTableIndex >= 0) {
        const dataTablesShown = vm.excludeSelf(vm.selectedApp.dataTables)
        result =  dataTablesShown[vm.selectedDataTableIndex]
      }
      return result
    },
    currentForm () {
      return this.$store.getters.currentForm
    }
  },
  mounted () {
    // alert('mounted')
  },
  created () {
    this.loadData()
  },
  methods: {
    excludeSelf (forms) {
      return forms.filter(form => form._id !== this.currentForm._id)
    },
    loadData () {
      const vm = this
      const getParams = {
        urlCommand: '/data_tables'
      }
      vm.loading = true
      vm.$emit('loading')
      vm.$store.dispatch('AUTH_GET', getParams).then(
        response => {
          vm.loading = false
          vm.teamAppData = response.filter(team => team._id === vm.activeTeam._id)
          vm.$emit('loaded', vm.teamAppData)
          console.log('vm.teamAppData: ', vm.teamAppData)
          vm.selectedTeamIndex = vm.teamAppData.findIndex(team => team._id === vm.activeTeam._id)
          if (vm.selectedTeamIndex >= 0) {
            vm.$nextTick( () => {
              vm.selectedAppIndex = vm.teamAppData[vm.selectedTeamIndex].apps.findIndex(app => app._id === vm.currentApp._id)
              console.log('team found selectedAppIndex = ' + vm.selectedAppIndex)
            })
          } else {
            console.log('team not found')
            vm.selectedTeamIndex = vm.teamAppData.length > 0 ? 0 : -1
          }
          vm.loading = false          
        }
      )
    },
    open () {
      const vm = this
      vm.showingDialog = true
      if (typeof vm.value === 'object') {
        if (vm.value.teamId) {
          vm.selectedTeamIndex = vm.teamAppData.findIndex(team => team._id === vm.value.teamId)
          if (vm.selectedTeamIndex >= 0) {

            if (vm.value.appId) {
              vm.selectedAppIndex = vm.teamAppData[vm.selectedTeamIndex].apps.findIndex(app => app._id === vm.value.appId)
              if (vm.selectedAppIndex >= 0) {

                if (vm.value.dataTableId) {
                  vm.selectedDataTableIndex = vm.teamAppData[vm.selectedTeamIndex].apps[vm.selectedAppIndex].dataTables.findIndex(table => table._id === vm.value.dataTableId)
                }
              }
            }
          }
        }
      }
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'ok':

          vm.$emit('input', vm.selectedDataTable._id)
          vm.showingDialog = false
          break
        default:
          vm.showingDialog = false
      }
    }
  }
}
</script>
