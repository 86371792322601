<template>
  <WorkflowConsumer>
    <div slot-scope="{ info }" v-if="!loading">
      <v-layout align-center column class="w-trigger-wrapper">
        <h1 class="w-trigger-header">{{ $t('workflow.label.startYourFlow') }}</h1>
        <v-layout align-start column v-for="event in allEvents" :key="event.name" class="w-trigger-options">
          <h2 class="w-trigger-subTitle">{{ $t(event.name) }}</h2>
          <div v-for="item in event.trigger" class="w-trigger-event" :key="item.name">
            <v-progress-linear
              v-if="creating && info.type === item.type"
              indeterminate
              class="w-trigger-event-loading"
              color="primary"
            ></v-progress-linear>
            <v-layout @click="pickEventType(item)" :class="{ 'w-trigger-method-disabled': item.disabled }" align-start justify-space-between>
              <div class="w-trigger-icon" :style="iconColor(item.color)">
                <v-icon large color="white">{{ item.icon || 'mdi-table-large' }}</v-icon>
              </div>
              <v-layout column class="w-trigger-event-info">
                <div class="w-trigger-event-title">{{ title(item) }}</div>
                <div class="w-trigger-event-desc">{{ description(item) }}</div>
              </v-layout>
            </v-layout>
          </div>
        </v-layout>
      </v-layout>
    </div>
  </WorkflowConsumer>
</template>

<script>

import EventSettings from './../constants/event';
import WorkflowConsumer from './../editor/contexts/workflowConsumer';
import {
  // PICK_EVENTTYPE,
  CREATE_WORKFLOW,
} from '@/store/modules/workflow/action_types';
import Mixin from "@/pages/admin/workflow/mixin";

import SettingUtils from '@/pages/admin/workflow/utils/setting';

export default {
  mixins: [Mixin],
  inject: ['setType', 'info'],
  components: {
    WorkflowConsumer,
  },
  data() {
    return {
      allEvents: EventSettings,
    };
  },
  computed: {
    creating() {
      return this.$store.getters.creatingWorkflow;
    },
    treeLoading() {
      return this.$store.getters.fetchingWorkflowTree;
    },
    infoLoading() {
      return this.$store.getters.fetchingWorkflowInfo;
    },
    loading() {
      return this.infoLoading || this.treeLoading;
    }
  },
  methods: {
    iconColor(color) {
      return {
        background: color,
      };
    },
    title({ name }) {
      return this.$t(`${name}.title`);
    },
    description({ name }) {
      return this.$t(`${name}.description`);
    },
    async pickEventType({ disabled, type, name }) {
      if (disabled){
        return false;
      }
      if (this.loading) {
        return false;
      }
      const newWorkflowId = await this.$store.dispatch(CREATE_WORKFLOW, {
        info: {
          type,
          name: this.info.title,
          remark: this.info.description,
        },
        node: {
          type,
          name: this.$t(name).title,
          remark: this.$t(name).description,
        },
        appId: this.params.appId,
      });
      if (newWorkflowId) {
        const vm = this;
        SettingUtils.editTree(vm, this.currentRouteParams, newWorkflowId);
      }
      this.info.type = type;
      return true;
      // this.$store.dispatch(PICK_EVENTTYPE, type);
    }
  },
};

</script>

<style scoped>
  .w-trigger-header {
    font-size: 22px;
    text-align: center;
    margin-bottom: .5em;
    color: rgba(0,0,0,.85);
    font-weight: 500;
  }
  .w-trigger-event-loading {
    width: 96%;
    margin: 0 0 20px 0;
  }
  .w-trigger-wrapper {
    width: 640px;
    margin: 80px auto 0 auto;
    padding: 30px 26px 30px 36px;
    background-color: #fff;
    border-radius: 3px;
  }
  .w-trigger-subTitle {
    margin: 24px 0 16px;
    color: #757575;
    font-weight: 700px;
    font-size: 13px;
  }
  .w-trigger-icon {
    width: 56px;
    height: 56px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .w-trigger-event {
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
    padding: 16px 0 16px 16px;
    border: 1px solid transparent;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }
  .w-trigger-event:hover {
    background-color: #f5f5f5;
  }
  .w-trigger-options {
    width: 100%;
  }
  .w-trigger-event-title {
    font-size: 15px;
    margin-top: 4px;
    color: #333;
  }
  .w-trigger-event-desc {
    font-size: 13px;
    color: #757575;
    margin-top: 10px;
  }
  .w-trigger-event-info {
    margin-left: 12px;
  }
  .w-trigger-method-disabled {
    cursor: not-allowed;
  }
</style>
