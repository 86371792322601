<template>
  <v-container fluid class="pb-0 form-log-container">
    <v-row no-gutters align="center">
      <v-icon size="15">{{icon}}</v-icon>
      <span v-if="item.workflowName" class="workflow-label">
        <b>{{ $t('menu.workflow')}}</b> 
        <span class="workflow-label-link">
          <router-link :to="workflowParams" target="_blank">{{item.workflowName}}</router-link>
        </span>

      </span>
      <span v-else-if="item.user" class="username">
        {{ item.user.employeeWorkInfo.displayName || $t('publicForm.publicForm')}}
      </span>
      <v-spacer />
      <span class="log-time">{{
        item.relativeTime
      }}</span>
    </v-row>
    <v-row no-gutters class="mt-1 pl-6">
          <i18n v-if="item.templateName === 'CREATE_IMPORT'" path="log.CREATE_IMPORT" :places="mergeTag">
            <span v-if="mergeTag.errors">
              <a @click="$emit('openImportErrorDialog', mergeTag.importId)">{{ $t('drawer.openErrorDetail')}}</a>
            </span>
          </i18n>
          <span v-else>{{ $t(`log.${item.templateName}`, mergeTag) }}</span>
          <v-spacer/>
          <span v-if="item.viewId" class="float-right" style="font-size:12px">{{item.viewId}}</span>
    </v-row>
  </v-container>
</template>
<script>

import WorkflowRoutesName from '@/const/workflow/routes';
export default {
  props: ["item"],
  computed: {
    currentApp () {
      return this.$store.getters.currentApp;
    },
    workflowParams(item) {
      // WorkflowRoutesName.formWorkflowsTree
      return {
        name: WorkflowRoutesName.formWorkflowsTree, 
        params: {
          workflowId: this.item.workflow,
          id: this.currentApp._id,
        },
      };
    },
      icon() {
          const action = this.item.templateName.split("_")[0]
          switch (action) {
                case "CREATE":
                    return "mdi-pencil-plus-outline";
                case "EDIT":
                    return "mdi-pencil-outline";
                case "DELETE":
                    return "mdi-pencil-minus-outline";
                case "WORKFLOW":
                    return "mdi-sitemap";
                default:
                    return ""
          }
      },
      mergeTag(){
        if (this.item.templateName === 'EDIT_RECORD_FIELD_DETAILED') {
          if (this.item.mergeTag.oldVal == '') {

            return {
              ...this.item.mergeTag,
               oldVal : this.$t('messages.empty'),
            }
          }
          else if (this.item.mergeTag.newVal == ''){
            return {
              ...this.item.mergeTag,
              newVal : this.$t('messages.empty')
            }
          }

        }

        return this.item.mergeTag
      },
  }
};
</script>
<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";

.form-log-container {
  font-size: 13px;
}
.username {
  font-weight: 700;
}
.workflow-label {
  > b {
    font-weight: 700;
  }
  .workflow-label-link {
    color: #1e88e5;
    margin: 0 5px;
  }
}
.log-time {
  font-size: 10px;
  color: map-get($grey, base);
}
</style>
