export default {
  CARD_DISPLAY_FIELDS: {
    id: 1,
    icon: 'mdi-table-settings',
    labelTag: 'view.displaySetting',
    componentName: 'tabCardDisplayFields',
    refName: 'tabCardDisplayFields',
    key: 'displayFields',
    errorCount: 0
  },

  DISPLAY_TABLE_SETTING: {
    id: 2,
    icon: 'mdi-table-settings',
    labelTag: 'view.displaySetting',
    componentName: 'tabTableDisplaySetting',
    refName: 'tabDisplayTableSetting',
    key: 'displayTable',
    errorCount: 0
  },

  DISPLAY_TABLE_SORTING: {
    id: 3,
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.displaySorting',
    componentName: 'tabSorting',
    refName: 'tabDisplayTableSorting',
    key: 'displayTable',
    errorCount: 0
  },

  //*****************************
  // Card Selection
  //*****************************
  CARD_SELECTION_LIST: {
    id: 4,
    icon: 'mdi-table-settings',
    labelTag: 'view.cardSelectionDisplay',
    componentName: 'tabCardDisplayFields',
    refName: 'tabSelectionCard',
    key: 'cardSelectionList',
    errorCount: 0
  },

  CARD_SELECTION_FILTERS: {
    id: 5,
    icon: 'mdi-filter',
    labelTag: 'view.cardSelectionFilter',
    componentName: 'tabFilters',
    refName: 'cardSelectionFilters',
    key: 'filterConfigs', // 'cardSelectionList',
    errorCount: 0,
    type: 'filter'
  },

  CARD_SELECTION_SORTING: {
    id: 6,
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.cardSelectionSorting',
    componentName: 'tabSorting',
    refName: 'tabSorting',
    key: 'cardSelectionList',
    errorCount: 0
  },

  //***************************
  // selection table
  //***************************
  // POPUP_MULTIPLE_SELECTION_TABLE: {
  //   id: 6,
  //   icon: 'mdi-table-settings',
  //   labelTag: 'view.selectionTable',
  //   componentName: 'tabTableDisplayWithFreezing',
  //   refName: 'tabSelectionTable',
  //   key: 'selectionTable',
  //   errorCount: 0
  // },

  SELECTION_TABLE: {
    id: 7,
    icon: 'mdi-table-settings',
    labelTag: 'view.selectionTableSetting',
    componentName: 'tabTableDisplayWithFreezing',
    refName: 'tabSelectionTable',
    key: 'selectionTable',
    errorCount: 0
  },

  SELECTION_FILTERS: {
    id: 8,
    icon: 'mdi-filter',
    labelTag: 'view.selectionTableFilter',
    componentName: 'tabFilters',
    refName: 'tabSelectionFilters',
    key: 'selectionTable',
    errorCount: 0
  },

  SELECTION_SORTING: {
    id: 9,
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.selectionTableSorting',
    componentName: 'tabSorting',
    refName: 'tabSelectionSorting',
    key: 'selectionTable',
    errorCount: 0
  },

  //****************************
  DISPLAY_TABLE: {
    id: 10,
    icon: 'mdi-table-settings',
    labelTag: 'view.displayTableSettings',
    componentName: 'tabTableDisplayWithFreezing',
    refName: 'tabDisplayTable',
    key: 'displayTable',
    errorCount: 0
  },

  DISPLAY_FILTERS: {
    id: 11,
    icon: 'mdi-filter',
    labelTag: 'view.filter',
    componentName: 'tabFilters',
    refName: 'tabFilters',
    key: 'displayTable',
    errorCount: 0
  },

  // DISPLAY_FIELDS: {
  //   icon: 'mdi-focus-field-horizontal',
  //   labelTag: 'view.displayFields',
  //   componentName: 'tabDisplayFields',
  //   refName: 'tabDisplayFields'
  // },

  // CARD_FIELDS: {
  //   id: 12,
  //   icon: 'mdi-order-alphabetical-ascending',
  //   labelTag: 'view.cardFieldsSetting',
  //   componentName: 'tabCardFields',
  //   refName: 'tabCardFields',
  //   key: 'cardFields',
  //   errorCount: 0
  // },
  SORTING: {
    id: 13,
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.sorting',
    componentName: 'tabSorting',
    refName: 'tabSorting',
    key: 'sorting',
    errorCount: 0
  },

  CUSTOM_BUTTONS: {
    id: 14,
    icon: 'mdi-gesture-tap',
    labelTag: 'view.customButtons',
    componentName: 'tabCustomButtons',
    refName: 'tabCustomButtons',
    key: 'customButtons',
    errorCount: 0
  },

  COPY_RELATED_FIELDS: {
    id: 15,
    icon: 'mdi-table-settings',
    labelTag: 'widgets.properties.copyFields',
    componentName: 'tabCopyRelatedFields',
    refName: 'tabCopyRelatedFields',
    key: 'relatedToLocalFields',
    errorCount: 0
  },

  CARD_CONFIG: {
    id: 16,
    icon: 'mdi-tools',
    labelTag: 'general.cardDisplaySetup',
    componentName: 'tabCardDisplaySetup',
    refName: 'tabCardDisplaySetup',
    key: '',
    errorCount: 0
  },


  TABLE_CONFIG: {
    id: 17,
    icon: 'mdi-table-settings',
    labelTag: 'view.selectionTableSetting',
    componentName: 'tabTableDisplayWithFreezing',
    refName: 'tabSelectionTable',
    key: 'tableConfigs',
    errorCount: 0
  },

  FILTER_CONFIGS: {
    id: 18,
    icon: 'mdi-filter',
    labelTag: 'view.selectionTableFilter',
    componentName: 'tabFilters',
    refName: 'tabSelectionFilters',
    key: 'filterConfigs',
    errorCount: 0,
    type: 'filter'
  },

  SORTING_CONFIGS: {
    id: 19,
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.selectionTableSorting',
    componentName: 'tabSorting',
    refName: 'tabSelectionSorting',
    key: 'sortingConfigs',
    errorCount: 0
  },

  LIST_QUICK_FILTER: {
    id: 20,
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.selectionTableQuickFilter',
    componentName: 'tabFilters',
    refName: 'tabListQuickFilter',
    key: 'quickFilterConfigs',
    errorCount: 0,
    type: 'quickFilter'
  },

  CARD_QUICK_FILTER: {
    id: 21,
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.cardSelectionQuickFilter',
    componentName: 'tabFilters',
    refName: 'tabCardQuickFilter',
    key: 'quickFilterConfigs',
    errorCount: 0,
    type: 'quickFilter'
  }
}
