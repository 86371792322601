<template>
  <v-container class="workflow-table-record-main-table">
    <vue-good-table
      :columns="headers"
      :rows="list"
      :fixed-header="true"
      styleClass="vgt-table"
      :sort-options="{
        enabled: false
      }"
      :group-options="{
        enabled: true
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'name'">
          <div>
            <v-layout align-center class="record name link link-space" @click="goRow(props.row)">
              <span class="table-icon" :style="iconStyle(props.row)">
                <v-icon color="white" meidum>{{ props.row.icon || 'mdi-sitemap' }}</v-icon>
              </span>
              <div>
                {{ props.row.name || $t('workflow.unknown') }}
                <div v-if="props.row.remark" class="table-remark-desc">
                  {{ props.row.remark }}
                </div>
              </div>
            </v-layout>
          </div>
        </div>
        <div v-else-if="props.column.field == 'type'">
          <div class="record link"
            @click="goDetail(props.row)"
            v-if="!unClassified(props.row.targetFormId)"
          >
            <span v-if="!!props.row.parentWorkflow && props.row.type === 'childProcess'">
              <span
                :style="{
                  color: $vuetify.theme.themes.light.primary,
                  marginRight: '5px',
                }"
              >
                {{ $t(`workflow.trigger.parentWorkflow`) }}
              </span>
              {{
                props.row.parentWorkflow && props.row.parentWorkflow.name
              }}
            </span>
            <span v-else>
              {{ $t(`workflow.trigger.${props.row.type}`) }}
            </span>
          </div>
          <div class="record" v-else>
            ----
          </div>
        </div>
        <v-layout justify-space-between v-else-if="props.column.field == 'creator'">
          <AvatarWithPopover :user="props.row.user" />
          <RowControl :item="props.row" :notReady="unClassified(props.row.targetFormId)" :index="props.index" :type="type" />
        </v-layout>
        <div v-else-if="props.column.field == 'status'">
          <div class="record">
            <toggle-button
              :value="isEnableWorkflow(props.row)"
              :color="{
                checked: switchStyle(props.row), 
                unchecked: '#CCCCCC', 
                disabled: '#666666',
              }"
              @change="v => onSwitchMode(v, props.row)"
              :disabled="unClassified(props.row.targetFormId)"
              width="120"
              height="35"
              :labels="{
                checked: $t('workflow.btn.released'), 
                unchecked: $t('workflow.btn.unRelease')
              }"
            />
            <span v-if="!props.row.released && !unClassified(props.row.targetFormId)" class="workflow-table-record-main-table-remark">
              {{ 
                $t(`workflow.message.rolloutNot.${props.row.status}`, {
                  date: date(props.row),
                }),
              }}
            </span>
            <span
              v-if="!!props.row.beingReleased && !unClassified(props.row.targetFormId)"
              class="workflow-table-record-main-table-warning"
              v-html="
                $t(`workflow.message.beingReleased`, {
                  date: lastSettingAt(props.row),
                })
              "
            />
            <span
              v-else-if="!!props.row.released"
              class="workflow-table-record-main-table-remark"
              v-html="$t(`workflow.message.rollout.lastUpdate`, {
                date: lastSettingAt(props.row),
              })"
              :style="{ color: $vuetify.theme.themes.light.primary, fontStyle: 'italic' }"
            />
          </div>
        </div>
        <div v-else>
          {{props.formattedRow[props.column.field]}}
        </div>
      </template>
      <template slot="table-header-row" slot-scope="props">
        <div  class="workflow-table-list-header-clip">
          <v-layout align-center class="workflow-table-list-header-noClassified" 
          v-if="unClassified(props.row._id)">
            {{ $t('workflow.label.unClassified') }}
          </v-layout>
          <v-layout class="workflow-table-list-header-classified" align-center
            v-else-if="isClassified(props.row._id, props.row)"
          >
            <v-icon meidum>{{classifiedIcon(props.row)}}</v-icon>
            <span>
              {{classifiedLabel(props.row, props.column)}}
            </span>
          </v-layout>
        </div>
        <!-- <div v-else /> -->
      </template>
    </vue-good-table>
    <ReleaseDialog />
  </v-container>
</template>

<script>
import AvatarWithPopover from './../../../avatar';
import RowControl from './control';

import ReleaseDialog from './../../../../editor/releaseDialog';
import {
  includes,
  get,
} from 'lodash';
import StringUtils from './../../../../utils/string';
import SettingUtils from './../../../../utils/setting';
import Mixin from './../../../../mixin';
// import Routes from '@/const/workflow/routes';

import {
  STOP_ORCHESTRATION,
  RELEASE_TO_ORCHESTRATION,
} from '@/store/modules/orchestration/action_types';
import {
  VALIDATE_WORKFLOW,
} from '@/store/modules/workflow/action_types';
export default {
  mixins: [Mixin],
  inject: ['getEventType'],
  props: {
    list: Array,
    headers: Array,
  },
  computed: {
    type() {
      return this.getEventType();
    },
    event() {
      return StringUtils.getEvent(this.type) || { color: "#bdbdbd" };
    },
  },
  methods: {
    isClassified(id, row) {
      const firstRowType = get(row, 'children.[0].type');
      let valid = id !== '1';
      if (!valid) {
        valid = includes([
          'timer',
        ], firstRowType);
      }
      return valid;
    },
    classifiedIcon(row) {
      const firstRowType = get(row, 'children.[0].type');
      let icon;
      switch(firstRowType) {
        case 'timer':
          icon = 'mdi-timer';
          break;
        default:
          icon = 'mdi-table';
      }
      return icon;
    },
    classifiedLabel(row) {
      const firstRowType = get(row, 'children.[0].type');
      const { label, children } = row;
      const size = `(${children.length})`;
      switch(firstRowType) {
        case 'timer':
          return `${this.$t('workflow.modules.timer.label.title')} ${size}`;
          break;
        default:
          return `${this.$t('workflow.modules.form.label.worksheet', { label })} ${size}`;
      }
    },
    clipColor(row) {
      return this.unClassified(row._id) ? 'default' : '#5cbbf6';
    },
    lastSettingAt({ lastSetAt }) {
      return lastSetAt && this.$locale.m(lastSetAt).fromNow();
    },
    unClassified(id, props) {
      return includes(['-1'], String(id));
    },
    goDetail(row) {
      const { parentWorkflow, type  } = row;
      const vm = this;
      if (type === 'childProcess') {
        SettingUtils.editTree(vm, this.currentRouteParams, parentWorkflow._id);
      } else {
        SettingUtils.editTree(vm, this.currentRouteParams, row._id);
      }
    },
    goRow(row) {
      const vm = this;
      SettingUtils.editTree(vm, this.currentRouteParams, row._id);
    },
    // editTree({ _id }) {
    //   // console.log('> ', this.$route.name);
    //   this.$store.commit('savePrevRoute', this.$route);
    //   // console.log(this.currentRouteParams);
    //   this.$router.replace({
    //     name: Routes.formWorkflowsTree,
    //     params: {
    //       ...this.currentRouteParams,
    //       workflowId: _id,
    //     },
    //   });
    // },
    iconStyle(row) {
      return {
        backgroundColor: this.isEnableWorkflow(row) && this.event ? 
          (row.color || this.event.color) : "#bdbdbd",
      }
    },
    switchStyle(row) {
      return this.isEnableWorkflow(row) && this.event ? 
      (row.color || this.event.color)  : "#bdbdbd";
    },
    setSelected(v) {
      this.onChange(v);
    },
    isEnableWorkflow({ released }) {
      return !!released;
      // return StringUtils.isEnableWorkflow(v);
    },
    date(v) {
      StringUtils.getEvent(this.type);
      let date = 'updatedAt';
      const lookup = {
        finished: 'finishedAt',
        terminated: 'terminatedAt',
        failed: 'failedAt',
      };
      date = v[lookup[v.status] || date];
      return this.$locale.m(date).fromNow();
    },
    onSwitchMode({ value: checked }, row) {
      if (checked) {
        this.release(row._id);
      } else {
        this.stopRelease(row._id);
      }
    },
    async release(id) {
      // await this.$store.dispatch(VALIDATE_WORKFLOW, id);
      this.$store.dispatch(RELEASE_TO_ORCHESTRATION, id);
    },
    stopRelease(id) {
      this.$store.dispatch(STOP_ORCHESTRATION, id);
    },
  },
  components: {
    AvatarWithPopover,
    RowControl,
    ReleaseDialog,
  },
  watch: {
    type: () => {
      this.event = StringUtils.getEvent(this.type) || { color: "#bdbdbd" };
    },
  },
};

</script>

<style>
  
</style>
