<template>
  <div>
    <FlashMessage :position="'left bottom'" />
    <AutomatedTaskManager />
  </div>
</template>

<script>
  import AutomatedTaskManager from './../manager/automatedTask/';
  import {
    SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION,
  } from '@/store/modules/notification/action_types';
  export default {
    components: {
      AutomatedTaskManager,
    },
    methods: {
      showPopup() {
        this.messageId = this.flashMessage.show({
          componentName: 'CustomActionProgressNotificationContent',
          clickable: false,
          time: 10000,
          blockClass: 'workflow-notification-alert',
        });
        setTimeout(() => {
          this.$store.dispatch(SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION, false);
        }, 10000);
      },
    },
    computed: {
      show() {
        return this.$store.getters.shouldShowAutomatedTaskPopupNotification;
      },
    },
    watch: {
      show: function(toShow) {
        if (toShow) {
          this.showPopup();
        } 
        // else if (this.messageId) {
        //   // hide the popup
        //   this.flashMessage.deleteMessage(this.messageId);
        // }
      }
    }
  }
</script>
