<template>
  <FormElement
    :label="label"
    :hints="hints"
    :subItem="true"
  />
</template>

<script>
import FormElement from './../formElement';
export default {
  props: {
    label: String,
    hints: String,
  },
  components: {
    FormElement,
  },
};

</script>

<style scoped>
  
</style>
