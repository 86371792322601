<template>
    <v-card width="960" class="pa-2">
      <v-card-title>
        {{ $t("publicEdit.publicEdit") }}
      </v-card-title>
      <loading-icon v-if="loading" />
      <v-card-text v-else>
        <public-edit-row 
            v-for="publicEdit in publicEdits"
            :key="publicEdit._id"
            :publicEdit="publicEdit"
            @commandHandler="onCommandHandler"
            class="mb-6"
        />
        <v-btn color="primary" rounded @click="openDialog('newFormDialog')"
          >{{ $t("buttons.new") }}<v-icon>mdi-plus</v-icon></v-btn 
        >
      </v-card-text>
      <confirm-delete-dialog ref="ConfirmDeleteDialog" />
      <new-field-dialog
        ref="newFormDialog"
        @confirm="addForm"
        :title="$t('buttons.new')"
        :placeholder="$t('general.title')"
      />
      <new-field-dialog ref="newFieldDialog" @confirm="addTextField" />
      <v-dialog v-if="showDialog" v-model="showDialog" width="340" persistent>
        <v-card>
          <dialogHeader :label="$t('publicForm.selectTextField')" @close="showDialog = false" />
          <v-card-text>
            <select-field
              class="py-2"
              :items="fieldOptions"
              :value="selectedField"
              @input="dialogValue = $event"
              @clear="dialogValue = ''"
              @add="openDialog('newFieldDialog')"
            />
          </v-card-text>
          <dialog-actions
            :buttons="['cancel', 'save']"
            @onCommand="onCommandHandler"
          ></dialog-actions>
        </v-card>
      </v-dialog>
    </v-card>
</template>
  <script>
  import LoadingIcon from "@/components/global/loadingIcon";
  import publicEditRow from "./comp/publicEditRow.vue";
  import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
  import newFieldDialog from "./comp/newFieldDialog";
  import selectField from "./comp/selectField";
  import dialogHeader from "@/components/dialogs/comps/DialogHeader";
  import dialogActions from "@/components/dialogs/comps/DialogActions";
  import MediaHelper from '@/helpers/MediaHelper';
  import mixin from "./mixin";

  export default {
    components: {
      LoadingIcon,
      publicEditRow,
      ConfirmDeleteDialog,
      newFieldDialog,
      selectField,
      dialogHeader,
      dialogActions,
    },
    mixins:[mixin],
    data() {
      return {
        showFormBuilder: false,
        publicEdits: [],
        qrcode: "",
        dialogValue: "",
        loading: false,
        showDialog: false,

        type:"RECORD"
      };
    },
    mounted() {
        this.fetchTemplates()
    },
    computed: {
      fieldOptions() {
        const fieldInfos = this.$store.getters.formFieldInfos;
        if (!fieldInfos) return [];
        else {
          let allHiddenFields = [];
          this.publicEdits.forEach((form) => {
            allHiddenFields = allHiddenFields.concat(form.hiddenFieldIds);
          });
          allHiddenFields = Array.from(new Set(allHiddenFields));
          return fieldInfos.filter(
            (info) =>
              info.type === "text" &&
              (info._id === this.selectedField ||
                !allHiddenFields.includes(info._id))
          );
        }
      },
      selectedField() {
        const form = this.$store.getters.currentForm;
        return form ? form.publicFormId : "";
      },
    },
    methods: {
      onCommandHandler(payload) {
        switch (payload.command) {
          case 'reset':
            this.reset(payload.publicForm);
          break
          case "saveForm":
            this.saveTemplate(payload.publicForm);
            break;
          case "deleteTemplate":
            this.deleteTemplate(payload.publicEdit);
            this.track({
              key:"plus_public_publishing_function", 
              data:{
                function_button: this.$sensors.events.plus_public_publishing_function.DELETE
              }
            });
            break;
          case "saveSetting":
            this.saveSetting(
              payload.publicForm,
              payload.setting,
              payload.value,
              payload.cb
            );
            break;
          case "saveCustomProps":
            this.saveCustomProps(
              payload.publicForm,
              payload.fieldId,
              payload.value
            );
            break;
          case "saveFieldId":
            this.saveFieldId(payload.publicForm, payload.propKey, payload.val);
            break;
          case "setLogo":
            this.setLogo(payload.publicForm, payload.file);
            break;
          case "setImage":
            this.setImage(payload.publicForm, payload.file);
            break;
          case "reset":
            this.reset(payload.publicForm);
            break;
          case "cancel":
            this.dialogValue = this.selectField;
            this.showDialog = false;
            break;
          case "openSettings":
            this.track({key:"plus_public_publishing_function", data:{
              function_button: this.$sensors.events.plus_public_publishing_function.SETTINGS
            }});
          break;
        }
      },
      reset(publicForm) {
        const postData = {
          urlCommand: "/publicEdit/reset",
          data: {
            appId: publicForm.appId,
            formId: publicForm.formId,
            publicFormId: publicForm._id,
          },
        };
  
        this.$store.dispatch("AUTH_POST", postData).then((res) => {
          publicForm.layout = res;
        });
      },
      setLogo(publicForm, file) {
        console.log('setLog: file: ', file);
        if (file) {
          const payload = {
            appId: publicForm.appId,
            formId: publicForm.formId,
            file: file,
            isPublic: true,
            useUploadBucket: false
          }
          console.log('payload: ', payload);
          MediaHelper.uploadFile(payload, tempMedia => {
            // console.log('MediaHelper.uploadFile : media: ', tempMedia);
            // const thumbnailUrl = tempMedia.thumbnailUrl;
            MediaHelper.createMedia({
              ...tempMedia,
              isPublic: true,
              appId: publicForm.appId,
              formId: publicForm.formId
            }, updatedMedia => {
              console.log('publicform : setLog : updatedMedia: ', updatedMedia);
              this.saveSetting(publicForm, "logo", {
                mediaId: updatedMedia._id,
                objectKey: updatedMedia.objectKey,
                imageUrl: updatedMedia.imageUrl,
                mediaType: updatedMedia.mediaType
              });
            })
          });
        } else {
          this.saveSetting(publicForm, "logo", null);
        }
      },
      setImage(publicForm, file) {
         if (file) {
          const payload = {
            appId: publicForm.appId,
            formId: publicForm.formId,
            file: file,
            isPublic: true,
            useUploadBucket: false
          }
          console.log('payload: ', payload);
          MediaHelper.uploadFile(payload, tempMedia => {
            // console.log('MediaHelper.uploadFile : tempMedia: ', tempMedia);
            // const thumbnailUrl = tempMedia.thumbnailUrl;
            MediaHelper.createMedia({
              ...tempMedia,
              isPublic: true,
              appId: publicForm.appId,
              formId: publicForm.formId            
            }, updatedMedia => {
              console.log('setImage : updatedMedia: ', updatedMedia);
              this.saveSetting(publicForm, "backgroundImage", {
                mediaId: updatedMedia._id,
                objectKey: updatedMedia.objectKey,
                imageUrl: updatedMedia.imageUrl,
                mediaType: updatedMedia.mediaType
              });
            });
          });
        } else {
          this.saveSetting(publicForm, "backgroundImage", null);
        }
      },
      saveTemplate(publicEdit) {
        const postData = {
          urlCommand: "/publicEdit/saveTemplate",
          data: {
            appId: publicEdit.appId,
            formId: publicEdit.formId,
            hiddenFieldIds: publicEdit.hiddenFieldIds,
            layout: publicEdit.layout,
            publicEditId: publicEdit._id,
          },
        };
  
        this.$store.dispatch("AUTH_POST", postData);
      },
      deleteTemplate(publicEdit) {
        this.$refs.ConfirmDeleteDialog.confirm(() => {
          const postData = {
            urlCommand: "/publicEdit/deleteTemplate",
            data: {
              appId: publicEdit.appId,
              formId: publicEdit.formId,
              publicEditId: publicEdit._id,
            },
          };
          this.$store.dispatch("AUTH_POST", postData).then((res) => {
            if (res.result) {
              const index = this.publicEdits.findIndex(
                (form) => form._id === publicEdit._id
              );
              if (index > -1) {
                this.publicEdits.splice(index, 1);
              }
            }
          });
        });
      },

      sendSaveSettingRequest(publicEdit, data) {
        return new Promise((resolve, reject) => {
          const postData = {
            urlCommand: "/publicEdit/saveSetting",
            data: {
              appId: publicEdit.appId,
              formId: publicEdit.formId,
              publicEditId: publicEdit._id,
              ...data,
            },
          };
  
          this.$store.dispatch("AUTH_POST", postData).then((res) => {
            resolve(res);
          });
        });
      },
      async saveSetting(publicEdit, setting, value, cb) {
        const res = await this.sendSaveSettingRequest(publicEdit, {
          setting,
          value,
        });
        if (res.result === true) {
          this.$set(publicEdit, setting, value);
          if (setting === "active"){
            this.track({
              key:"plus_public_form_activiate",
              data:{ is_default_setting:false }
            });
          }
        }
        if (typeof cb === "function") {
          cb();
        }
      },
      async saveCustomProps(publicForm, fieldId, value) {
        const res = await this.sendSaveSettingRequest(publicForm, {
          setting: "customFieldProperties",
          fieldId,
          value,
        });
        if (res.result === true) {
          if (!publicForm.customFieldProperties){
            this.$set(publicForm, 'customFieldProperties', {});
          }
          if (!publicForm.customFieldProperties[fieldId]) {
            this.$set(publicForm.customFieldProperties, fieldId, {});
          }
          this.$set(
            publicForm.customFieldProperties[fieldId],
            "alias",
            value.alias
          );
          this.$set(
            publicForm.customFieldProperties[fieldId],
            "description",
            value.description
          );
          this.$set(
            publicForm.customFieldProperties[fieldId],
            "editable",
            value.editable
          );
          this.$set(
            publicForm.customFieldProperties[fieldId],
            "deletable",
            value.deletable
          );
          this.$set(
            publicForm.customFieldProperties[fieldId],
            "uploadable",
            value.uploadable
          );
        }
      },
      getLayoutPosition(layout, fieldId) {
        let row = -1,
          col = -1;
  
        for (let i = 0; i < layout.length && col === -1; i++) {
          col = layout[i].findIndex((field) => field._id === fieldId);
          if (col !== -1) row = i;
        }
  
        return { row, col };
      },
      saveFieldId(publicForm, setting, value) {
        const oldId = publicForm[setting];
  
        if (oldId) {
          const index = publicForm.hiddenFieldIds.findIndex((id) => id === oldId);
          if (index > -1) {
            publicForm.hiddenFieldIds.splice(index, 1);
          }
        }
  
        this.saveSetting(publicForm, setting, value, () => {
          if (value) {
            //remove from layout, add to hidden
  
            if (publicForm.hiddenFieldIds.indexOf(value) === -1) {
              publicForm.hiddenFieldIds.push(value);
            }
  
            const { row, col } = this.getLayoutPosition(publicForm.layout, value);
            if (row !== -1 && col !== -1) {
              publicForm.layout[row].splice(col, 1);
              if (publicForm.layout[row].length === 0) {
                publicForm.layout.splice(row, 1);
              }
            }
          }
          this.saveForm(publicForm);
        });
      },
      fetchTemplates(){
        const postData = {
            urlCommand: "/publicEdit/list",
            data: {
                appId: this.$route.params.id,
                formId: this.$route.params.itemId
            },
        }
        this.$store.dispatch("AUTH_POST", postData).then((res) => {
            this.publicEdits = res
        });
      },
      addForm({ label }) {
        const postData = {
          urlCommand: "/publicEdit/addTemplate",
          data: {
            appId: this.$store.getters.currentForm.appId,
            formId: this.$store.getters.currentForm._id,
            identifier: label,
          },
        };
  
        this.$store.dispatch("AUTH_POST", postData).then((res) => {
          if (res) {
            this.publicEdits.push(res);
            this.track({key:"plus_add_public_form"});
          }
        });
      },
      openDialog(ref) {
        this.$refs[ref].open();
      },
      addTextField({label}){
        this.$store.dispatch("APPEND_WIDGET_TO_LAYOUT", {
          widgetKey: "text",
          options: {
            label,
            properties: {
              fieldName: label,
            },
          },
        });
  
        this.$store.dispatch("SAVE_FORM")
      }
    },
  };
  </script>
  