<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :required="required"
    :fieldWidth="fieldWidth"
    :isHidden="isHidden"
    :label="fieldName"
    :noIcon="noIcon"
    :tooltipText="description"
  >
    <div :class="{ 'workflow-customize-form-record-hide': hideInput }">
      <!-- v-model="inputValue" -->
      <v-text-field
        v-if="isLayout || editAnyway"
        dense
        hide-details
        background-color="white"
        class="workflow-customized-form-record-element"
        outlined
        :value="inputValue"
        @input="(val) => updateValue(val)"
      ></v-text-field>
      <div v-else>
        <v-text-field
          :value="amountInString || ''"
          disabled
          dense
          class="disabled"
        ></v-text-field>
      </div>
    </div>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import inputWidgetToggle from "./comps/InputWidgetToggle";

export default {
  name: "InputWidgetCapitalizedAmount",
  mixins: [mixin],
  components: {
    inputWidgetToggle,
    inputWidgetContainer,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data() {
    return {
      regions: null,
      tab: 0,
      selectedArea: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
    };
  },
  watch: {
    amountInString(val) {
      this.inputValue = val;
    },
  },
  computed: {
    amountInString() {
      if (this.isLayout) return "";
      return this.moneyToString(
        this.recordData[this.fieldInfo.properties.relatedField]
      );
    },
    isReadOnly() {
      return true;
    },
  },
  mounted() {},
  methods: {
   
  },
};
</script>

<style>
.message-line {
  min-height: 14px;
  color: lightskyblue;
}
</style>
