<template>
     <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions>
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.yAxis')}}
            </div>
        </v-expansion-panel-header>
        <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
            v-model="bl_yAxis"
            @change="$emit('onCommandHandler',{
                command: 'update_bl_yAxis',
                value: $event
            })"
        ></v-switch>
        <v-expansion-panel-content>
            <v-checkbox 
                v-model="setup.bl_displayScale" 
                @change="$emit('onCommandHandler',{
                    command: 'update_bl_displayScale',
                    value: $event
                })" 
                class="ma-0" 
                hide-details 
                dense 
                :ripple="false" 
                :label="$t('chart.showScaleLabel')"
            ></v-checkbox>
            <div v-show="setup.bl_displayScale" class="pt-2">{{$t('chart.lineStyle')}}</div>
            <v-select 
                item-value="label" 
                v-show="setup.bl_displayScale" 
                v-model="setup.lineStyle" 
                @change="$emit('onCommandHandler',{
                    command: 'update_lineStyle',
                    value: $event
                })" 
                :items="lineStyles"
                outlined 
                height="30" 
                hide-details 
                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                :item-text="(item)=>$t('chart.styleConfig.'+item.label)">
            </v-select>
            <v-checkbox 
                v-model="setup.bl_yTitle" 
                @change="$emit('onCommandHandler',{
                    command: 'update_bl_yTitle',
                    value: $event
                })" 
                class="pb-1" 
                hide-details 
                dense 
                :ripple="false" 
                :label="$t('chart.showTitle')"
            ></v-checkbox>
            <v-text-field 
                class="pb-3" 
                v-show="setup.bl_yTitle" 
                v-model="setup.yTitle" 
                @change="$emit('onCommandHandler',{
                    command: 'update_yTitle',
                    value: $event
                })" 
                hide-details 
                placeholder="" 
                outlined/>
            <div>{{$t('chart.styleConfig.max')}}</div>
            <v-text-field 
                v-model="setup.yMax" 
                @change="$emit('onCommandHandler',{
                    command: 'update_yMax',
                    value: $event
                })" 
                hide-details 
                :placeholder="$t('chart.auto')"
                outlined
            />
            <div class="pt-2">{{$t('chart.styleConfig.min')}}</div>
            <v-text-field 
                v-model="setup.yMin" 
                @change="$emit('onCommandHandler',{
                    command: 'update_yMin',
                    value: $event
                })"
                hide-details 
                :placeholder="$t('chart.auto')" 
                outlined
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    props:{
        bl_yAxis: Boolean,
        setup: Object
        // bl_displayScale: Boolean,
        // lineStyle: String,
        // bl_yTitle: Boolean,
        // yTitle: String,
        // yMin: Number,
        // yMax: Number
    },
    data(){
        return{
            lineStyles:[{
                id: 1,
                name: this.$t('chart.styleConfig.solid'),
                label: 'solid'
            },
            {
                id: 2,
                name: this.$t('chart.styleConfig.dashed'),
                label: 'dashed'
            }]
        }
    }
}
</script>