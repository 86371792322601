<template>
  <CustomizedActionSettingList />
</template>

<script>
import CustomizedActionSettingList from "@/pages/admin/workflow/manualActions/list/";
export default {
  components: {
    CustomizedActionSettingList,
  },
}
</script>

<script>

export default {
  name: 'viewSettingsCustomButtons',
}
</script>
