<template>
  <v-card :style="`border-top: 10px solid ${color};`">
    <slot name="card-header">
      <v-row v-if="publicForm" no-gutters class="pa-4">
        <v-col cols="12">
          <v-row no-gutters>
            <div
              v-if="isEdit && !publicForm.logo"
              class="icon-box my-2"
              @click="openFileBrowser"
            >
              LOGO
            </div>
            <div v-else>
              <img
                v-if="publicForm.logo && publicForm.logo.imageUrl"
                :src="publicForm.logo.imageUrl"
               class="logo-image"
              />
              <!-- {{ publicForm.logo.imageUrl }} -->
              <!-- getThumbnailPath(publicForm.logo)" -->
              <v-btn
                v-if="isEdit"
                style="vertical-align:top"
                icon
                @click="removeLogo"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </v-row>

          <v-row no-gutters class="form-title">
            <div v-if="!isEdit" style="width:100%">
              {{ publicForm.formName }}
            </div>
            <div
              v-else-if="!editingTitle"
              style="width:100%"
              @click="editTitle"
              class="form-title-edit"
            >
              {{ publicForm.formName }}
            </div>
            <v-text-field
              v-else
              class="form-title mt-0"
              autofocus
              dense
              hide-details
              v-model="titleText"
              @blur="onTitleBlur"
            >
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attr }">
                    <v-btn
                      small
                      icon
                      @click="refreshTitle"
                      v-on="on"
                      v-bind="attr"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("publicForm.resetFormName") }}
                </v-tooltip>
              </template>
            </v-text-field>
          </v-row>

          <v-row no-gutters style="width:100%" class="ql-snow">
            <div v-if="!isEdit" class="ql-editor" style="width:100%">
              <div v-html="publicForm.description"></div>
            </div>
            <div
              v-else-if="!editingDescription"
              class="description-box ql-editor"
              style="width:100%"
              @click="editingDescription = true"
            >
              <div
                v-if="publicForm.description"
                v-html="publicForm.description"
              />
              <div v-else>{{ $t("publicForm.emptyDescription") }}</div>
            </div>
            <textEditor
              v-else
              v-model="editingDescription"
              :textValue="publicForm.description"
              @change="saveSetting('description', $event)"
            />
          </v-row>
        </v-col>
      </v-row>
    </slot>
    <v-divider />
    <slot name="card-content"></slot>
    <v-divider />
    <v-row
      no-gutters
      class="px-2 py-10"
      align="center"
      justify="center"
      v-if="isEdit || showSubmit"
    >
      <v-btn v-if="!isEdit" :disabled="isSubmitting||isSubmitDisabled" color="primary" @click="confirm">{{
        publicForm.confirmText
      }}</v-btn>
      <v-btn v-else-if="!editingButton" color="primary" @click="editButton">{{
        publicForm.confirmText
      }}</v-btn>
      <div v-else class="button-input">
        <v-text-field
          hide-details
          solo
          autofocus
          v-model="buttonText"
          height="48"
          @blur="onButtonBlur"
        />
      </div>
    </v-row>
  </v-card>
</template>
<script>
import textEditor from "@/pages/admin/form/publicPublishing/comp/textEditor";

export default {
  components: {
    textEditor,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    publicForm: Object,
    color: String,
    showSubmit: Boolean,
    isSubmitting: {      
      type:Boolean,
      default:false
    },
    isSubmitDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      titleText: "",
      buttonText: "",
      editingDescription: false,
      editingTitle: false,
      editingButton: false,
    };
  },
  methods: {
    refreshTitle() {
      this.titleText = this.$store.getters.currentForm.label;
    },
    openFileBrowser() {
      this.$emit("commandHandler", {
        command: "openFileBrowser",
      });
    },
    removeLogo() {
      this.$emit("commandHandler", {
        command: "setLogo",
        file: null,
      });
    },
    // getThumbnailPath(media) {
    //   const vm = this;
    //   return vm.$store.getters.appHost + "/medias/" + media + "/thumbnail";
    // },
    editTitle() {
      this.titleText = this.publicForm.formName;
      this.editingTitle = true;
    },
    editButton() {
      this.buttonText = this.publicForm.confirmText;
      this.editingButton = true;
    },
    onTitleBlur() {
      if (this.titleText) {
        this.saveSetting("formName", this.titleText, () => {
          this.editingTitle = false;
        });
      } else {
        this.editingTitle = false;
      }
    },
    onButtonBlur() {
      if (this.buttonText) {
        this.saveSetting("confirmText", this.buttonText, () => {
          this.editingButton = false;
        });
      } else {
        this.editingButton = false;
      }
    },
    saveSetting(setting, value, cb) {
      this.$emit("commandHandler", {
        command: "saveSetting",
        setting,
        value,
        cb,
      });
    },
    confirm() {
      this.$emit("commandHandler", {
        command: "confirm",
      });
    },
  },
};
</script>
<style scoped>
.icon-box {
  cursor: pointer;
  width: 140px;
  height: 60px;
  background: rgb(247, 247, 247);
  border: 2px dashed rgb(224, 224, 224);
  border-radius: 4px;
  margin-top: 32px;
  color: rgb(189, 189, 189);
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  letter-spacing: 3px;
  user-select: none;
}

.form-title {
  line-height: 1.2em;
  font-size: 36px;
  font-weight: 500 !important;
  margin: 20px 0 6px;
}

.form-title-edit:hover {
  color: #1e88e5;
}

.description-box {
  min-height: 60px;
  color: #cccccc;
}

.description-box:hover {
  color: #1e88e5;
}

.button-input {
  background-color: #1e88e5;
  padding: 6px;
  border-radius: 3px;
}

.logo-image {
  height: 120px;
  width: auto;
  max-width: 100%;
}
</style>
