<template>
  <input-widget-container
    :isLayout="isLayout"
    :isHidden="isHidden"
    :widget="widget"
    :required="required"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :tooltipIcon="description"
  >
    <div :class="{ 'workflow-customize-form-record-hide': hideInput }">
      <v-text-field
        v-if="isReadOnly && inputValue.length === 0"
        disabled
        dense
        class="disabled"
      ></v-text-field>
      <div class="d-flex flex-column" v-else>
        <drop-upload
          :fieldName="fieldName"
          :fileIds="inputValue"
          :files="inputValue"
          :isLayout="isLayout"
          :multiple="fileCount > 1"
          :hasError="hasError"
          :hint="hint"
          :fileTypes="fileTypes"
          @onCommand="onCommandHandler"
          :acceptExt="acceptExt"
          :readonly="isReadOnly"
          :appId="formInfo && formInfo.appId"
          :deletable="customizedProperties?customizedProperties.deletable:true"
          :uploadable="customizedProperties?customizedProperties.uploadable:true"
        ></drop-upload>
      </div>
    </div>
  </input-widget-container>
</template>

<script>
import inputWidgetContainer from "./comps/InputWidgetContainer";
import mixin from "./mixin";

// import UploadService from '@/services/UploadFileService'
import dropUpload from "@/components/dropUpload/DropUpload";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import fileTypeExtensions from '@/const/fileTypeExtensions'

export default {
  name: "InputWidgetAttachments",
  mixins: [mixin],
  components: {
    dropUpload,
    inputWidgetContainer,
  },
  props: {
    hideInput: Boolean,
  },
  data() {
    return {
      // progressInfos: [],
      selectedFiles: [],
      message: "",
      fileInfos: [],
      // fileIds: [],
      hasError: false,
      fileTypes: [],
      fileTypeExtensions: fileTypeExtensions
    }
  },
  mounted() {
    if (!this.inputValue) {
      this.inputValue = [];
    }
  },
  computed: {
      acceptExt() {
      const vm = this;
      var result = [];
      if (vm.fieldInfo.properties.fileType) {
        var fileTypes = vm.fieldInfo.properties.fileType.split('||');
        fileTypes.forEach(item=>result = result.concat(fileTypeExtensions[item].exts))
        // var arr = vm.fieldInfo.properties.fileType.split('||')
        // console.log('InputWidgetAttachments :: computed(acceptExt) :; arr: ', arr)
        // arr.forEach(item => {
        //   var ext = item.split('|')
        //   result = result.concat(ext)
        //   if (result.length === 1 && result[0] === '')
        //     result = []
        // })
      }
      result = result.map(t => t.toLowerCase());
      return result;
    },
    hint() {
      const vm = this;
      var result = "";
      const min = parseInt(vm.minAttachmentCount);
      const max = parseInt(vm.maxAttachmentCount);

      if (min === max && max!==0) {
        result = this.$t('messages.attachmentCountExact', {num:max});;
      } else if (min > 0 && max > 0) {
        result = this.$t('messages.attachmentCountMustBeInRange', {max, min});
      } else if (min > 0) {
        result = this.$t('messages.attachmentCountAtLeast', {min});
      } else if (max!==0){
        result = this.$t('messages.attachmentCountAtMost', {max}) 
      }

      return result;
    },
    attachmentTypes() {
      return getFieldPropertyValue(this.fieldInfo, "attachmentTypes", "image");
    },
    minAttachmentCount() {
      return getFieldPropertyValue(this.fieldInfo, "minAttachmentCount", 0);
    },
    maxAttachmentCount() {
      return getFieldPropertyValue(this.fieldInfo, "maxAttachmentCount", 0);
    },
    acceptedFileTypes() {
      return this.attachmentTypes + "/*";
    },
    fileCount() {
      return getFieldPropertyValue(this.fieldInfo, "fileCount", 2);
    },
  },
  methods: {
    clearAll() {
      alert("clearAll");
    },
    getDefaultValue() {
      return [];
    },
    _onParseValue(value) {
      const vm = this;
      var result = [];
      if (Array.isArray(value)) {
        const objectIdRegex = new RegExp("^[0-9a-fA-F]{24}$");
        for (let i = 0; i < value.length; i++) {
          const loopValue = value[i];
          if (objectIdRegex.test(loopValue)) {
            result.push(loopValue);
          }
        }
      }
      return result;
    },
    validate(state) {
      const vm = this;
      const min = vm.minAttachmentCount;
      const max = vm.maxAttachmentCount;
      const fileCount = vm.inputValue.length;
      var resultOk = true;

      if (min > 0 && max > 0) {
        resultOk = fileCount >= min && fileCount <= max;
      } else if (min > 0) {
        resultOk = fileCount >= min;
      } else {
        resultOk = max==0 || fileCount <= max;
      }

      vm.hasError = !resultOk;

      return resultOk;
    },

    checkThumbnailUrl (thumbnailUrl) {
      var existed = false
      const axios = this.axios.create();
      let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
      var triggerReload = '';
      console.log('checkThumbnailUrl');
      // while (!existed) {
        console.log('while loop existed: ' + (existed ? 'yes' : 'no'));
        triggerReload = '?' + Date.now().toString();
        axios.get(thumbnailUrl + triggerReload).then(
          () => {
            existed = true
            alert('ok');
          },
          () => {
            console.log( new Date().toISOString());
          }
        )
        // if (!existed) await sleep(1000);
        triggerReload = '?' + Date.now().toString();
        axios.get(thumbnailUrl + triggerReload).then(
          () => {
            existed = true
            alert('ok');
          },
          () => {
            console.log( '1. ' + new Date().toISOString());
          }
        )
        // if (!existed) await sleep(1000);
        triggerReload = '?' + Date.now().toString();
        axios.get(thumbnailUrl + triggerReload).then(
          () => {
            existed = true
            alert('ok');
          },
          () => {            
            console.log( '2. ' + new Date().toISOString());
          }
        )
        // if (!existed) await sleep(1000);
        triggerReload = '?' + Date.now().toString();
        axios.get(thumbnailUrl + triggerReload).then(
          () => {
            existed = true
            alert('ok');
          },
          () => {
            console.log( '3. ' + new Date().toISOString());
          }
        )
        // if (!existed) await sleep(1000);
      // }
    },

    // checkThumbnailExists(arr, thumbnailUrls) {
    //   const axios = this.axios.create();

    //   const maxRetry = 5;
    //   var tryCount = 0;
    //   const t = setInterval( () => {
    //     console.log('tryCount = ' + tryCount);
    //     for (let i = thumbnailUrls.length-1; i >= 0; i--) {
    //       const loopThumbnailUrl = thumbnailUrls[i]
    //       const triggerReload = '?' + Date.now().toString();
    //       axios.get(loopThumbnailUrl + triggerReload).then(
    //         () => {
    //           const index = arr.findIndex( item => {
    //             return item.thumbnailUrl.indexOf(loopThumbnailUrl) === 0
    //           })
    //           if (index >= 0) {
    //             arr[index].thumbnailUrl = loopThumbnailUrl;
    //           } else {
    //             console.log('index = ' + index + ': internal error');
    //           }
    //           console.log('thumbnail succed');
    //           thumbnailUrls.splice(i, 1);
    //         }
    //       )
    //     }
    //     tryCount++;
    //     if (thumbnailUrls.length === 0 || tryCount>maxRetry) {
    //       console.log('no more thumbnail or retry exceeded');
    //       clearInterval(t);
    //     }
    //   }, 1000);
    //   console.log('checkThumbnailExists ends');
    // },

    // checkThumbnailExists2(arr, callback) {
    //   const axios = this.axios.create();

    //   const thumbnailUrls = arr
    //     .filter(item => (item.thumbnailUrl && item.thumbnailUrl !== ''))
    //     .map(item => item.thumbnailUrl)
      
    //   const maxRetry = 5;
    //   var tryCount = 0;
    //   const t = setInterval( () => {
    //     console.log('tryCount = ' + tryCount);
    //     for (let i = thumbnailUrls.length-1; i >= 0; i--) {
    //       const loopThumbnailUrl = thumbnailUrls[i]
    //       const triggerReload = '?' + Date.now().toString();
    //       axios.get(loopThumbnailUrl + triggerReload).then(
    //         () => {
    //           console.log('thumbnail succed');
    //           thumbnailUrls.splice(i, 1);
    //         }
    //       )
    //     }
    //     tryCount++;
    //     if (thumbnailUrls.length === 0 || tryCount>maxRetry) {
    //       console.log('no more thumbnail or retry exceeded');
    //       clearInterval(t);
    //       if (typeof callback === 'function') {
    //         console.log('callback');
    //         callback();
    //       }
    //     }
    //   }, 3000);
    //   console.log('checkThumbnailExists: thumbnailUrls: ', thumbnailUrls);

    // },

    onCommandHandler(payload) {
      const vm = this;
      // console.log('InputWidgetAttachments :: onCommandHandler: payload: ', payload)
      switch (payload.command) {
        case "addAttachments":
          // if(this.customizedProperties && !this.customizedProperties.uploadable)
          //   return
          var arr = [];
          for (var i = 0; i < payload.mediaIds.length; i++) {
            var obj = {
              mediaId: payload.mediaIds[i],
              mediaType: payload.mediaTypes[i],
              objectKey: payload.objectKeys[i],
              originalName: payload.originalNames[i],
              thumbnailUrl: payload.thumbnailUrls[i]
            };
            arr.push(obj);
          }
          if (!Array.isArray(vm.inputValue)) vm.inputValue = [];
          vm.inputValue = vm.inputValue.concat(arr);
            vm.validate(true);
            vm.$nextTick(() => {
              vm.validate(true);
          });
          vm.$emit('onCommand', {command: 'finishUploading'});
          break;
        case "removeAttachment":
          // if(this.customizedProperties && !this.customizedProperties.deletable)
          //   return
          console.log('removeAttachment :: payload.fileId = ' + payload.fileId);
          if (payload.fileId && payload.fileId !== "") {
            vm.inputValue = vm.inputValue.filter(
              (item) => item.mediaId !== payload.fileId
            );
          }
          vm.$nextTick(() => {
            vm.validate(true);
          });
          break;
        case "startUploading":
          vm.$emit('onCommand', {command: 'startUploading'});
          break;
      }
    }
  },
};
</script>
