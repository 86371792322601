<template>
<div class="d-flex flex-column ">
  <div
    v-for="(dataRow,index) in dataRows" 
    :key="index"
    class=" d-flex flex-row justify-start align-center">
    <div class="black--text mr-3">{{ $t(dataRow.labelTag) }}:</div>
    <div class="grey--text">{{ dataRow.value }}</div>
  </div>  
</div>
</template>

<script>
export default {
  name: 'teamCard',
  props: {
    teamCode: String,
    teamName: String,
    email: String
  },
  computed: {
    dataRows () {
      return [
        {
          labelTag: 'settings.teamCode',
          value: this.teamCode
        },
        {
          labelTag: 'settings.companyName',
          value: this.teamName
        },
        {
          labelTag: 'settings.yoovPassportEmail',
          value: this.email
        }
      ];
    }
  }
}
</script>