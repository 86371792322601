<template>
  <div>
      <Drag
        :type="item.nodeType"
        :disabled="$store.state.workflowModules.pickingModule || isSelection(item) || !item.selectable"
        :data="item"
        class="v-modules-list-item"
        v-for="item in section"
        :key="item.type"
        :style="selectionBox(item)"
        @click="$emit('select', item)"
      >
        <v-tooltip bottom :disabled="!description(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-layout align-center column v-bind="attrs" v-on="on">
              <div class="v-modules-list-item-icon" :style="iconBox(item)">
                <v-icon color="white" size="25">{{ item.icon }}</v-icon>
              </div>
              <h2>{{ $t(`workflow.label.${item.name}`) }}</h2>
              <v-icon small class="v-modules-list-item-move-icon">
                {{ isSelection(item) ? 'mdi-dots-vertical' : waitForPicking ? 'mdi-plus' : 'mdi-cursor-move' }}
              </v-icon>
            </v-layout>
          </template>
          <span>{{ description(item) }}</span>
        </v-tooltip>
      </Drag>
  </div>
</template>

<script>
import { Drag } from "vue-easy-dnd";
// TODO: controled and returned from api?
// import ModuleData from './../../constants/module';

export default {
  props: {
    section: Array,
    select: Function,
    isSelection: Function,
  },
  methods: {
    iconBox({ color }) {
      return {
        'background-color': color,
        width: '45px',
        height: '45px',
        'border-radius': '50%',
        'align-items': 'center',
        display: 'flex',
        'justify-content': 'center',
        // boxShadow: 'rgb(0 0 0 / 12%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 0%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 0%) 0px 32px 16px',
      }
    },
    selectionBox(item) {
      return {
        cursor: this.isSelection(item) ? 'pointer' : this.waitForPicking ? 'pointer' : 'move', 
      };
    },
    description(item={}) {
      // console.log(this.$t(`worflow.description.${item.type}`));
      return !!item.description && this.$t(`workflow.description.${item.description}`);
    }
  },
  computed: {
    waitForPicking() {
      return this.$store.state.workflowModules.pickingModule;
    },
  },
  components: {
    Drag,
  },
};

</script>

<style scoped>
  .v-modules-list-item-move-icon {
    position: absolute;
    right: -9px;
    top: 5px;
    color: #ccc;
    transition: all .25s ease-out;
  }
  .v-modules-list-item:hover .v-modules-list-item-move-icon {
    transform: rotate(90deg);
  }
  .v-modules-list-item-icon {
    margin-bottom: 8px;
  }
  .v-modules-list-item h2 {
    font-size: 13px;
    color: #333;
    font-weight: bolder;
  }
</style>
