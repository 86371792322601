import store from '@/store'
import {app} from '@/main'
import {
  FORM_CARD_DISPLAY_CONFIG,
  FORM_TABLE_DISPLAY_CONFIG,
  FORM_CARD_SELECTION_CONFIG,
  FORM_TABLE_SELECTION_CONFIG  
} from '@/const/defaults'
import {ValidateRule} from "@/helpers/RuleHelper" 
import ObjectID from 'bson-objectid'
import moment from "moment";

const getRelatedRecordFieldInfo = (fieldInfos, fieldInfo) => {
  var result = null
  if (fieldInfo.type === "otherTableField") {
      const referToFieldInfoId = fieldInfo.properties.fieldForRelatedTable
      if (referToFieldInfoId) {
        const referToFieldInfo = fieldInfos.find(info => info._id === referToFieldInfoId)
        if (referToFieldInfo) {
          result = referToFieldInfo
        }
      }
  }
  return result
}

const getFirstAttachmentsFieldId = fieldInfos => {
  var result = ''
  for (let i = 0; i < fieldInfos.length; i++)  {
    const loopFieldInfo = fieldInfos[i]
    if (loopFieldInfo.type === 'attachments')
      result = loopFieldInfo.fieldId
  }
  return result
}

const newBlankRecord = (fieldInfos, defaultData={})=> {
  var result = {
    _id: '',
    displayValues: {}
  }
  Object.assign(result, defaultData)
  var fieldSeq = []
  const getSeq = (field) => {
    if(fieldSeq.includes(field.fieldId)) return
    if(field.properties.default && field.properties.default.type=='COPY'){
      var ids = getIdsFromStr(field.properties.default.code)
      ids.forEach(id=>{
        var dpField = fieldInfos.find(fieldInfo=>fieldInfo.fieldId==id)
        getSeq(dpField)
      })
    }
    fieldSeq.push(field.fieldId)
  }
  fieldInfos.forEach(field=>{
    getSeq(field)
  })
  
  for (var i = 0; i < fieldSeq.length; i++) {
    if(!result.hasOwnProperty(fieldSeq[i])){
      const loopFieldInfo = fieldInfos.find(field=>field.fieldId==fieldSeq[i])
      const fieldId = loopFieldInfo.fieldId
      result[fieldId] = getFieldDefault(loopFieldInfo, fieldInfos, result)
    }
  }

  // handle current record query
  fieldInfos.forEach(field=>{
    if(field.properties.default && field.properties.default.type=='QUERY' && field.properties.default.queryType!='FORM'){
      var conditions = field.properties.default.conditions
      let bl_pass = false
      bl_pass = conditions.some(orConds=>{
        var res = orConds.every(andCond=>{
          var hostField = result[andCond.fieldId]
          var compareField = (andCond.content.type==1)? andCond.content.value:result[andCond.content.value]
          return ValidateRule(hostField, compareField, andCond.content.rule, andCond.fieldType)
        })
        if(res) return true
      })
      if(bl_pass){
        result[field.fieldId] = result[field.properties.default.copyField.fieldId]
      }
    }
  })
  return result
}

const getUsedInfoIdsInLayout = layout => {
  // console.log('getUsedInfoIdsInLayout')
  var result = []
  if (layout) {
    for (var i = 0; i < layout.rows.length; i++) {
      const row = layout.rows[i]
      // Main Page
      for (var j1 = 0; j1 < row.length; j1++) {
        result.push(row[j1]._id)
      }
      // Top Relation Tables
      for (var j2 = 0; j2 < layout.topPagingTables.length; j2++) {
        const loopItem = layout.topPagingTables[j2]
        result.push(loopItem._id)

      }
      // Embedded Relation Tables
      for (var j3 = 0; j3 < layout.embeddedPagingTables.length; j3++) {
        const loopItem = layout.embeddedPagingTables[j3]
        result.push(loopItem._id)

      }
    }
  }
  // console.log('getUsedInfoIdsInLayout ends')
  return result
}



const getDefaultPropertyValue = (propertyKey, defaultValue, fieldName, widgetKey=null) => {
  var result = null
  // const propertyKey = property.propertyKey
  // console.log('getDefaultPropertyValue :: propertyKey = ' + propertyKey)
  // console.log('getDefaultPropertyValue :: defaultValue = ' + defaultValue)

  if (typeof fieldName === 'undefined') fieldName = ''
  if (typeof defaultValue === 'undefined') defaultValue = null
  switch (propertyKey) {
    case 'cardDisplaySettings':
      result = FORM_CARD_DISPLAY_CONFIG
      break
    case 'cardSelectionSettings':
      result = FORM_CARD_SELECTION_CONFIG
      break

    case 'tableDisplaySettings':
      result = FORM_TABLE_DISPLAY_CONFIG
      break
    case 'tableSelectionSettings':
      result = FORM_TABLE_SELECTION_CONFIG
      break

    case 'width':
      result = defaultValue ? defaultValue : '12'
      break
    case 'fieldsEditor':
      result = {
        fieldInfos: [],
        fixedFieldIds: [],
        nonFixedFieldIds: [],
        titleFieldInfoId: ''
      }
      break
    case 'fieldName':
      result = fieldName
      break

    case 'inputOptionsSingle':
    case 'inputOptionsMultiple':
      // console.log('getWidgetPropertyValues :: case inputOptionsMultiple: property: ', property)
      // default option has labeltag, translate it to current language
      // check and give default ids for each option
      result = prepareInputOptionDefault(defaultValue, propertyKey)
      break
    case 'copyRelatedRecordFields':
      result = {
        relatedToLocalFields: {}
      }
      break
    case 'default':{
      // switch(widgetKey){
      //   // case 'members':
      //   //   result = {...defaultValue, ...{members: [], code: ''}}
      //   // break
      //   // case 'departments':
      //   //   result = {...defaultValue, ...{departments: [], code: ''}}
      //   // break
      //   // case 'relatedRecord':
      //   //   result = {...defaultValue, ...{records: [], code: ''}}
      //   // break
      // }
      break
    }
    case 'defaultMember':
      result = []
      break
    case 'relatedRecordDisplayFields':
      result = {
        fields: [],
        displayFields: {
          fixedFieldIds: [],
          nonFixedFieldIds: []
        },
        displayTable: {
          fixedFieldIds: [],
          nonFixedFieldIds: [],
          sortingConfigs: [],
          filterConfigs: {
            filters: [],
            filterAndOr: 'and' // [and|or]
          }
        },
        selectionCardList: {
          cardConfig: {},
          // fixedFieldIds: [],
          // nonFixedFieldIds: [],
          sortingConfigs: [],
          filterConfigs: {
            filters: [],
            filterAndOr: 'and' // [and|or]
          }
        },
        selectionTable: {
          fixedFieldIds: [],
          nonFixedFieldIds: [],
          sortingConfigs: [],
          filterConfigs: {
            filters: [],
            filterAndOr: 'and' // [and|or]
          }
        },
        cardFields: []

      }
      break
    case "numberRule" :
        result = JSON.parse(JSON.stringify(defaultValue))
      break
    default:
      result = defaultValue
  }
  // console.log('getDefaultPropertyValue :: result: ', result)
  return result
}

const getWidgetPropertyValues = (widget, fieldName, widgetProperties) => {
  var result = {}


  for (var i = 0; i < widget.properties.length; i++) {
    const property = widget.properties[i]
    const propertyKey = property.propertyKey


    const generalWidgetProperty = widgetProperties.find(p => p.key === propertyKey)


    // default property value
    var propertyValueDefault = ''
    if (generalWidgetProperty && generalWidgetProperty.default && (generalWidgetProperty.default !== ''))
      propertyValueDefault = generalWidgetProperty.default
    if (property.default)
      propertyValueDefault = property.default

    result[propertyKey] = getDefaultPropertyValue(
      propertyKey,
      propertyValueDefault,
      fieldName,
      widget.key
    )

    // console.log('*****************************')
  }
  // console.log('getWidgetPropertyValues :: fieldName = ' + fieldName + ': result (' + (typeof result) + ': ', result)
  return result
}

const prepareInputOptionDefault = (defaultValue, propertyKey) => {
  var result = null
  // console.log('prepareInputOptionDefault :: defaultValue: ', defaultValue)
  var options = []
  for (let i = 0; i < defaultValue.options.length; i++) {
    const loopOption = defaultValue.options[i]
    var label = loopOption.label
    if (loopOption.label.substr(0,1) === '_') {
      label = app.$i18n.t('widgets.properties.' + loopOption.label.substr(1))
    }
    options.push({
      ...loopOption,
      label: label,
      order: i,
      colorSet: store.getters.colorSets[i],
      isDeleted:false,
      active:true,
      _id: ObjectID().toHexString()
    })
  }
  // console.log('prepareInputOptionDefault :: defaultValue: ', defaultValue)
  // console.log('prepareInputOptionDefault :: options: ', options)
  result = {
    ...defaultValue,
    defaultSelection: propertyKey === 'inputOptionsSingle' ? options[0]._id : [ options[0]._id],
    options: options,
    useColor: false
  }
  // console.log('prepareInputOptionDefault :: result: ', result)
  return result

}

const getUIElementInfosInLayout = (usedInfoIds, form) => {
  var allUIElementInfos = form.uiElementInfos
  var result = []
  if (allUIElementInfos) {
    result = allUIElementInfos.filter(info => usedInfoIds.indexOf(info._id) >= 0)
  }
  return result
}

const getFieldDefault = (fieldInfo, fieldInfos, recordData) => {
  var result = ''
  // console.log('getFieldDefault  fieldInfo (type=' + fieldInfo.type + ') : ', fieldInfo)
  let defaultObj;
  switch (fieldInfo.type) {
    case 'singleSelection': {
      const inputOptionsValue = fieldInfo.properties['inputOptionsSingle']
      const options = inputOptionsValue.options.filter(item=> !item.isDeleted && item.active)
      const defaultValue = options.find(
        (item) => item._id === inputOptionsValue.defaultSelection
      );
      result = defaultValue ? defaultValue._id : "";
      break
    }
    case 'multipleSelection': {
      const inputOptionsValue = fieldInfo.properties['inputOptionsMultiple']
      const options = inputOptionsValue.options.filter(item=> !item.isDeleted && item.active)
      const optionIds = options.map((item) => item._id);
      result = inputOptionsValue.defaultSelection.filter((selection) =>
        optionIds.includes(selection)
      );
      break
    }
    case 'attachments':{
      result = []
      break
    }
    case 'yesNo':{
      defaultObj = "dsa"
      result = fieldInfo.properties['yesNoDefault']
      break
    }
    case 'childTable':
    case 'relatedRecord':{
      // array of record id
      defaultObj = fieldInfo.properties['default']
      if(defaultObj && defaultObj.type=='COPY'){
        result = defaultObj.records.map(item=>item._id)
      }
      break
    }
    case 'region':{
      result = {province: null, city: null, state: null, regionText: ''}
      break
    }
    case 'text':{
      result = ''  
      defaultObj = fieldInfo.properties['default']
      if(defaultObj){
        if(defaultObj.type=='COPY'){
          result = getCombinedField(defaultObj.code, fieldInfos, recordData)
        }
      }
      break
    }
    case 'number':
    case 'amount':
    case 'rating':
    case 'date':{
      result = ''
      defaultObj = fieldInfo.properties['default']
      if(defaultObj){
        if(defaultObj.type=='COPY'){
          if(defaultObj.code=='CURRENT' && fieldInfo.type=='date'){
            const today = new Date();
            var [month, day, year]       = [today.getMonth(), today.getDate(), today.getFullYear()];
            var [hour, minutes] = [today.getHours(), today.getMinutes(), today.getSeconds()];
            month = (month+1).toString().padStart(2, '0')
            day = day.toString().padStart(2, '0')
            hour = hour.toString().padStart(2, '0')
            minutes = minutes.toString().padStart(2, '0')
            switch(fieldInfo.properties.fieldType){
              case 'date':
                result = `${year}-${month}-${day}` 
                break
              case 'time':
                result = `${hour}:${minutes}` 
                break
              case 'datetime':
                result = `${year}-${month}-${day} ${hour}:${minutes}`
                break
            }
          }else
            result = getCombinedField(defaultObj.code, fieldInfos, recordData)
        }
      }
      break
    }
    case 'members':
    case 'departments':{
      result = []
      defaultObj = fieldInfo.properties['default']
      if(defaultObj){
        if(defaultObj.type=='COPY'){
          if(defaultObj.code){
            var ids = getIdsFromStr(defaultObj.code)
            if(ids.length){
              result = JSON.parse(JSON.stringify(recordData[ids[0]]))
            }
          }else{
            if(fieldInfo.type=='members')
              result = JSON.parse(JSON.stringify(defaultObj.members||[]))
            else if(fieldInfo.type=='departments')
              result = JSON.parse(JSON.stringify(defaultObj.departments||[]))
          }
        }
      }
      break
    }
    default:
      result = fieldInfo.properties['default'] ?
        fieldInfo.properties['default'] :
        ''
      // result = ''
      // if (result !== '') {
      //   switch (fieldInfo.type) {
      //     case 'number':
      //     case 'amount':
      //       // console.log('getFieldDefault :: fieldInfo.type === number result is empty')
      //       result = parseFloat(result)
      //       break
      //   }
      // }


    //
    // // format number based on decimals
    // if (fieldInfo.type === 'number') {
    //   console.log('getFieldDefault :: fieldInfo.type === number result = ' + result)
    //   if (result) {
    //     const decimals = fieldInfo.properties['decimals']
    //     if (decimals !== '') {
    //       console.log('getFieldDefault :: fieldInfo.type === number :: decimals not empty')
    //       const decimalsVal = parseInt(decimals)
    //       console.log('getFieldDefault :: fieldInfo.type === number :: decimalsVal = ' + decimalsVal)
    //       result = result.toFixed(decimalsVal)
    //       console.log('getFieldDefault :: fieldInfo.type === number :: result = ' + result)
    //     }
    //   }
    // }
  }
  // console.log('getFieldDefault fieldInfo.type=' + fieldInfo.type + ':: result: ', result)
  // console.log('getFieldDefault::'+fieldInfo.label, result)
  return result
}


const getFormConfig = form => {
  var result = null
  if (form) {
    const layout = form.layout
    const allUsedInfoIds = getUsedInfoIdsInLayout(layout)

    // data fields in layout
    const usedFieldInfos = form.fieldInfos // getFieldInfosInLayout(allUsedInfoIds, form)

    // child tables in layouts
    const usedChildTableInfos = form.childTableInfos

    // non-data fields in layout (e.g. section line)
    const usedUIElementInfos = getUIElementInfosInLayout(allUsedInfoIds, form)
    result = {
      fieldInfos: JSON.parse(JSON.stringify(usedFieldInfos)),
      childTableInfos: JSON.parse(JSON.stringify(usedChildTableInfos)),
      uiElementInfos: JSON.parse(JSON.stringify(usedUIElementInfos)),
      layout: JSON.parse(JSON.stringify(layout)),
      titleFieldInfoId: form.titleFieldInfoId,
      linkedFields: form.linkedFields,
      label: form.label
    }
  }
  return result
}

const getIdsFromStr = (combineText) => {
  var ids = []
  const regex = /\$\{\w+\}/;
  while (regex.test(combineText)) {
    let tag = combineText.match(regex)[0];
    let key = tag.match(/\w+/)[0];
    combineText = combineText.replace(tag, '');
    ids.push(key)
  }
  return ids;
}

const getCombinedField = (combineText, fieldInfos, recordData)=>{
  const regex = /\$\{\w+\}/;
  while (regex.test(combineText)) {
    let tag = combineText.match(regex)[0];
    let key = tag.match(/\w+/)[0];
    let replaceString = recordData[key];
    combineText = combineText.replace(
      tag,
      getObjectValue(key, replaceString, fieldInfos)
    );
    combineText = combineText.trim()
  }
  return combineText;
}

const getObjectValue = (fieldId, obj, fieldInfos)=>{
  let field = fieldInfos.find((item) => item.fieldId == fieldId);
  if (field) {
    let type = field.type;
    switch (type) {
      case "region":
        if (typeof obj == "object" && obj) return obj.regionText;
        else return "";
      case "departments":
        if (Array.isArray(obj)) return obj.map((item) => item.label) || [];
        else return [];
      case "members":
        if (Array.isArray(obj)) {
          return obj.map((item) => item.employeeName) || [];
        } else {
          return [];
        }
      case "singleSelection": {
        const option = field.properties.inputOptionsSingle.options.find(
          (item) => item._id === obj
        );
        return (option && option.label) || "";
      }
      case "multipleSelection":
        if (Array.isArray(obj))
          return field.properties.inputOptionsMultiple.options
            .filter((item) => obj.includes(item._id))
            .map((item) => item.label);
        else return [];
      case "location":
        if (typeof obj == "object" && obj)
          return (obj.name ? obj.name + ", " : "") + obj.formatted_address;
        else return "";
      case "relatedRecord": {
        // let relatedRecord = this.relatedTableData[fieldId];
        // let relatedDataTable = this.relatedTableInfos[
        //   field.properties.dataSource.dataTableId
        // ];
        // if (
        //   Array.isArray(relatedRecord) &&
        //   relatedRecord.length > 0 &&
        //   relatedDataTable
        // ) {
        //   let titleFieldInfoId = relatedDataTable.titleFieldInfoId;
        //   return relatedRecord[0][titleFieldInfoId] || "";
        // } else return "";
        break
      }
      case "otherTableField": {
        // const {
        //   fieldForRelatedTable,
        //   relatedTableField,
        // } = field.properties;
        // const relatedRecord = this.relatedTableData[fieldForRelatedTable];
        // if (Array.isArray(relatedRecord) && relatedRecord.length > 0) {
        //   return relatedRecord[0][relatedTableField] || "";
        // } else return "";
        break
      }
      case "number":
      case "amount":
      case "expression":
      case "summaryField":{
        if (obj == "") return "";
        if (field.properties.decimals) {
          var decimals = parseInt(field.properties.decimals);
          if (decimals >= 0) {
            if (decimals > 0)
              obj = parseFloat(obj)
                .toFixed(decimals)
                .toString();
            else if (decimals == 0) {
              obj = parseInt(obj).toString();
            }
          }
        }

        const { fixedLengthSettings } = field.properties;
        if (fixedLengthSettings && fixedLengthSettings[fieldId]) {
          obj = obj.padStart(
            parseInt(fixedLengthSettings[fieldId]),
            "0"
          );
        }
        return obj;
      }
      case "date":{
        if (
          field.properties.dateFormatSettings &&
          field.properties.dateFormatSettings != ""
        ) {
          if (field.properties.dateFormatSettings[fieldId]) {
            if (obj && obj != "") {
              let format = field.properties.dateFormatSettings[
                fieldId
              ];
              return new moment(obj).format(format);
            }
          }
        }
        return obj;
      }
      case "createdAt":
      case "updatedAt":{
        if (
          field.properties.dateFormatSettings &&
          field.properties.dateFormatSettings != ""
        ) {
          if (field.properties.dateFormatSettings[fieldId]) {
            if (obj && obj != "") {
              let format = field.properties.dateFormatSettings[
                fieldId
              ];
              return new moment(obj).tz("Asia/Hong_Kong").format(format);
            }
          }
        }
        return obj.tz("Asia/Hong_Kong");
      }
      case "createdBy":
      case "owner":
        return obj.employeeName
    }
    return obj;
  }
}
// const updateSummaryFields = async(payload, callback) => {
//   // payload = {
//   //    context,
//   //    recordId
//   //    relatedRecordIds
//   //    currentFormInfo
//   //    relatedFormInfo
//   // }
//   const vm = payload.context
//   const recordId = payload.recordId
//   const fieldInfoId = payload.fieldInfoId
//   const relatedRecordIds = payload.relatedRecordIds
//   const currentFormInfo = payload.currentFormInfo
//   const relatedFormInfo = payload.relatedFormInfo

//   const thisFieldInfoId = vm.fieldInfo._id
//   var recordIds = vm.params.row[vm.params.column.property]
//   var relatedFormId = vm.relatedFormInfo._id

//   // Find all other field pointing to this field
//   var summaryFieldInfos = vm.currentFormInfo.fieldInfos.filter(info => info.properties.tableField === fieldInfoId)

//   console.log('updateSummaryField :: summaryFieldInfos: ', summaryFieldInfos)
//   var summaryConfigs = []
//   for (let i =0; i < summaryFieldInfos.length; i++) {
//       const loopInfo = summaryFieldInfos[i]
//       summaryConfigs.push({
//           fieldId: loopInfo.fieldId,
//           summaryFieldId: loopInfo.properties.summaryField,
//           summaryFunction: loopInfo.properties.summaryFunction
//       })
//   }

//   const params = {
//       id: recordId,
//       formId: currentFormInfo._id,
//       summaryFormId: relatedFormInfo._id,
//       summaryRecordIds: relatedRecordIds,
//       summaryConfigs: summaryConfigs,
//   }

//   vm.$store.dispatch('UPDATE_SUMMARY_FIELDS', params).then(
//       response => {
//       // response.result = [
//       //    {fieldId: 'xxxxxx', value: ''},
//       //    {fieldId: 'yyyyyy', value: ''},
//       //    ...
//       //    {fieldId: 'yyyyyy', value: ''},
//       // ]
//         if (response) {
//           if (typeof callback === 'function') {
//             callback(response)
//           }
//         }
//       }
//   )
// }
const getTitleFieldId = (formInfo) => {
  var result = ''
  if (formInfo && formInfo.titleFieldInfoId && formInfo.titleFieldInfoId !== '' && formInfo.fieldInfos) {
    const titleFieldInfo = formInfo.fieldInfos.find(info => info._id === formInfo.titleFieldInfoId)
    if (titleFieldInfo) {
      result = titleFieldInfo.fieldId
    }
  }
  return result
}

const getFieldInfosByType = (formInfo, fieldTypes) => {
  if (!Array.isArray(fieldTypes)) {
    fieldTypes = [fieldTypes]
  }
  var result = []
  for (let i = 0; i < formInfo.fieldInfos.length; i++) {
    const loopInfo = formInfo.fieldInfos[i]
    if (fieldTypes.includes(loopInfo.type)) {
      result.push(loopInfo)
    }
  }
  return result
}

export {
  newBlankRecord,
  getFormConfig,
  getWidgetPropertyValues,
  getDefaultPropertyValue,
  getFieldDefault,
  getRelatedRecordFieldInfo,
  getFirstAttachmentsFieldId,
  getTitleFieldId,
  getFieldInfosByType
  // ,
  // updateSummaryFields
  // ,
  // getWidget
}
