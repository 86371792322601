<template>
  <v-container class="workflow-table-record-main-table workflow-execution-histories">
    <vue-good-table
      :columns="headers"
      :rows="list"
      :fixed-header="true"
      styleClass="vgt-table"
      :totalRows="totalRows"
      @on-page-change="onPageChange"
      @on-per-page-change="onPageChange"

      :sort-options="{
        enabled: false,
      }"
      :pagination-options="pagingOptions">
    >
      <div slot="emptystate" class="workflow-execution-history-status-loading-data">
        <div v-if="!firstLoading" class="workflow-execution-history-status-loading-label">
          {{ $t('workflow.label.loadingHistory') }}
        </div>
        <div v-else class="workflow-execution-history-status-loading-label">
          {{ $t('workflow.label.loadingHistoryPre') }}
        </div>
      </div>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'name'" class="workflow-execution-history-status-name">
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                @click="fetchDetail(props.row._id)"
              >
                {{ props.row.name || $t('workflow.label.noWorkflowName') }}
              </span>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar
                  :color="infoHeaderColor(props.row.status)"
                >
                  <v-layout justify-space-between align-center>
                    <div>{{ props.row.name || $t('workflow.label.noWorkflowName') }}</div>
                    <div class="workflow-execution-history-status-step">
                      <StatusBlock :status="props.row.status" />
                    </div>
                  </v-layout>
                </v-toolbar>
                <v-card-text class="workflow-execution-history-info-content">
                  <InfoContent />
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <div v-if="props.column.field == 'message'" class="workflow-execution-history-status-message">
          <MessageBlock
            :status="props.row.status"
            :error="props.row.error"
            :systemError="props.row.systemError"
            :checkingLog="props.row.checkingLog"
          />
        </div>
        <div v-if="props.column.field == 'status'" class="workflow-execution-history-status-step">
          <StatusBlock :status="props.row.status" />
        </div>
        <div v-if="props.column.field == 'startTime'" class="workflow-execution-history-status-time">
          <DateBlock :status="props.row.status" :startTime="props.row.startTime" :finishedTime="props.row.finishedTime" />
        </div>
        <div v-if="props.column.field == 'interval'">
          <IntervalBlock :status="props.row.status" :interval="props.row.interval" />
        </div>
      </template>
    </vue-good-table>
  </v-container>
</template>

<script>
import Mixin from './../../../../mixin';
import TableMixin from './mixin';
import InfoContent from './info';
import StatusBlock from './status';
import DateBlock from './date';
import IntervalBlock from './interval';
import MessageBlock from './message';
// import {
//   FETCH_WORKFLOW_INFO,
import {
  FETCH_WORKFLOW_RELEASE_HISTORY_LIST,
  FETCH_WORKFLOW_RELEASE_HISTORY_INFO,
} from '@/store/modules/workflow/action_types';

// } from '@/store/modules/workflow/action_types';
export default {
  mixins: [Mixin, TableMixin],
  inject: ['getEventType', 'filterInfo'],
  props: {
    list: Array,
    headers: Array,
  },
  data() {
    return {
      perPage: 10,
      page: 1,
    }
  },
  computed: {
    pagingOptions() {
      return {
        enabled: true,
        nextLabel: this.$t('workflow.label.nextPage'),
        perPage: this.perPage,
        mode: 'pages',
        prevLabel: this.$t('workflow.label.prevPage'),
        rowsPerPageLabel: this.$t('workflow.label.rowPerPage'),
        ofLabel: this.$t('workflow.label.ofLabel'),
        pageLabel: this.$t('workflow.label.page'),
        allLabel: this.$t('workflow.label.allTableRecord'),
      }
    },
    totalRows () {
      return this.$store.getters.getEventHistoriesListTotal;
    },
    firstLoading() {
      return this.$store.getters.isWorkflowHistoryFirstLoading;
    }
  },
  
  mounted() {
    this.onPageChange({ currentPage: this.page, currentPerPage: this.perPage });
  },
  components: {
    InfoContent,
    DateBlock,
    IntervalBlock,
    StatusBlock,
    MessageBlock,
    // TableFilterConsumer,
  },
  methods: {
    fetchDetail(log) {
      if (this.workflow) {
        this.$store.dispatch(FETCH_WORKFLOW_RELEASE_HISTORY_INFO, {
          workflow: this.workflow._id,
          log,
        });
      }
    },
    onPageChange({ currentPage, currentPerPage }) {
      if (this.workflow) {
        this.$store.dispatch(FETCH_WORKFLOW_RELEASE_HISTORY_LIST, {
          workflow: this.workflow._id,
          params: {
            perPage: currentPerPage,
            page: currentPage,
            keyword: this.filterInfo.keyword,
            status: this.filterInfo.table,
          },
        });
      }
    }
  },
  watch: {
    filterInfo: {
      deep: true,
      handler(v) {
        this.onPageChange({ currentPage: this.page, currentPerPage: this.perPage });
      }
    },
    workflow: {
      handler(v) {
        this.onPageChange({
          currentPage: 1, 
          currentPerPage: this.perPage,
        });
      },
      deep: true,
    },
  },
};

</script>

<style>

</style>
