<template>
  <div class="wit-field-selection">
    <label>{{ label }}</label>
    <v-select 
        dense
        outlined
        solo
        
        cleararble
        v-model="propertyValue"
        item-color="primary"
        :items="getFieldInfosByType('amount')"
        item-text="properties.fieldName"
        item-value="fieldId"
        :menu-props="{top: false, offsetY: true, contentClass: 'denseList'}"
    >
      <template #selection="{item}">
        <div>{{ item.properties.fieldName }}</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  data () {
    return {
    }
  },
  computed: {
    selfFieldId () {
      const vm = this
      var result = ''
      for (let i = 0; i < vm.formFieldInfos.length; i++) {
        if (vm.formFieldInfos[i]._id === vm.fieldInfoId) {
          result = vm.formFieldInfos[i].fieldId
          break
        }
      }
      return result
    },
    formFieldInfos () {
      const vm = this
      return vm.$store.getters.formFieldInfos
    },
    usingNumberFields () {
      const vm = this
      var result = false
      if (vm.propertyValues['expressionType']) {
        result = vm.propertyValues['expressionType'] === 'number'
      }
      return result
    },
    usingDateFields () {
      const vm = this
      var result = false
      if (vm.propertyValues['expressionType']) {
        result = vm.propertyValues['expressionType'] === 'date'
      }
      return result
    },
    items () {
      const vm = this
      var result = []
      var append = []
      var fieldTypes = []

      console.log('WidgetInputTypeFieldSelection :: items: ')
      console.log('WidgetInputTypeFieldSelection :: usingNumberFields = ' + (vm.usingNumberFields ? 'yes' : 'no'))
      console.log('WidgetInputTypeFieldSelection :: usingDateFields = ' + (vm.usingDateFields ? 'yes' : 'no'))

      if (vm.usingNumberFields) {
        fieldTypes = ['number', 'amount', 'expression']
      } else if (vm.usingDateFields) {
        fieldTypes = ['date']
        append.push({
          text: vm.$t('widgets.properties.createdAt'),
          value: 'createdAt'
        })
        append.push({
          text: vm.$t('widgets.properties.updatedAt'),
          value: 'updatedAt'
        })
      }
      // console.log('WidgetInputTypeFieldSelection :: append: ', append)
      // console.log('WidgetInputTypeFieldSelection :: fieldTypes: ', fieldTypes.join(', '))

      if (fieldTypes.length > 0) {
        for (let i = 0; i < vm.formFieldInfos.length; i++) {
          const loopItem = vm.formFieldInfos[i]
          // console.log('i=' + i + ': vm.selfFieldId = ' + vm.selfFieldId + '  vs   ' + loopItem.fieldId + ' loopItem.fieldId')
          if (loopItem.fieldId !== vm.selfFieldId) {
            if (fieldTypes.indexOf(loopItem.type) >= 0) {
              const fieldName = loopItem.label
              const fieldId = loopItem.fieldId
              result.push({
                text: fieldName,
                value: fieldId
              })
            }
          }
        }
      }
      result = result.concat(append)
      // console.log('WidgetInputTypeFieldSelection :: result: ', result)
      return result
    }

  }
}
</script>

<style>
  .wit-field-selection .v-select__selections {
    padding-top: 4px;
  }
</style>
