import { render, staticRenderFns } from "./Apps.vue?vue&type=template&id=f4cc50b2&"
import script from "./Apps.vue?vue&type=script&lang=js&"
export * from "./Apps.vue?vue&type=script&lang=js&"
import style0 from "./Apps.vue?vue&type=style&index=0&id=f4cc50b2&prod&lang=css&"
import style1 from "@/assets/scss/vue-nestable.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VProgressCircular,VRow})
