var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('url-box',{attrs:{"url":_vm.publicUrl},scopedSlots:_vm._u([{key:"prepend-outer",fn:function(){return [_c('v-switch',{staticClass:"mt-0 px-2",attrs:{"label":_vm.$t('publicForm.activate'),"dense":"","hide-details":""},on:{"change":function($event){return _vm.saveSetting('active', $event)}},model:{value:(_vm.publicForm.active),callback:function ($$v) {_vm.$set(_vm.publicForm, "active", $$v)},expression:"publicForm.active"}}),(!_vm.editing)?_c('url-box',{staticClass:"px-1",attrs:{"url":_vm.publicForm.identifier,"canCopy":false,"maxWidth":"100px","noQRCode":""},on:{"copy":function($event){return _vm.onCommandHandler({command:'copyUrl'})},"download":function($event){return _vm.onCommandHandler({command:'downloadUrl'})}},nativeOn:{"dblclick":function($event){_vm.editing = true}},scopedSlots:_vm._u([{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.editing = true}}},[_vm._v("mdi-pencil-outline")])]},proxy:true}],null,false,967688470)}):_c('v-text-field',{staticClass:"px-1",staticStyle:{"maxWidth":"100px","font-size":"13px"},attrs:{"hide-details":"","height":"36","solo":"","autofocus":"","filled":"","flat":"","color":"white","background-color":"grey lighten-2"},on:{"blur":function($event){_vm.saveSetting('identifier', _vm.publicForm.identifier);
          _vm.editing = false;}},model:{value:(_vm.publicForm.identifier),callback:function ($$v) {_vm.$set(_vm.publicForm, "identifier", $$v)},expression:"publicForm.identifier"}})]},proxy:true},{key:"prepend-copy",fn:function(){return [_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attr = ref.attr;
          var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openURL(false)}}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("publicForm.openInNew"))+" ")])],1)]},proxy:true},{key:"append-copy",fn:function(){return [_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attr = ref.attr;
          var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":_vm.openBuilder}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cog-outline ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("general.settings"))+" ")])],1),_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attr = ref.attr;
          var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.deleteForm}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("buttons.delete"))+" ")])],1)]},proxy:true}])}),(_vm.showFormBuilder)?_c('form-builder',{attrs:{"publicForm":_vm.publicForm},on:{"change":_vm.saveForm,"commandHandler":_vm.onCommandHandler},model:{value:(_vm.showFormBuilder),callback:function ($$v) {_vm.showFormBuilder=$$v},expression:"showFormBuilder"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }