export const dateOptions = 
[
    {
        id: "TODAY",
        type: 'label',
        label: 'TODAY',
        value: "TODAY"
    },{
        id: "YESTERDAY",
        label: 'YESTERDAY',
        type: 'label',
        value: "YESTERDAY"               
    },{
        id: "THISWEEK",
        label: 'THISWEEK',
        type: 'label',
        value: "THISWEEK"           
    },{
        id: "LASTWEEK",
        label: 'LASTWEEK',
        type: 'label',
        value: "LASTWEEK"               
    },{
        id: "THISMONTH",
        label: 'THISMONTH',
        type: 'label',
        value: "THISMONTH"            
    },{
        id: "LASTMONTH",
        label: 'LASTMONTH',
        type: 'label',
        value: "LASTMONTH"             
    },{
        id: "THISQUARTER",
        label: 'THISQUARTER',
        type: 'label',
        value: "THISQUARTER"                
    },{
        id: "LASTQUARTER",
        label: 'LASTQUARTER',
        type: 'label',
        value: "LASTQUARTER"        
    },{
        id: "THISYEAR",
        label: 'THISYEAR',
        type: 'label',
        value: "THISYEAR"             
    },{
        id: "LASTYEAR",
        label: 'LASTYEAR',
        type: 'label',
        value: "LASTYEAR"
    }
]