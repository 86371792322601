<template>
<ErrorPage>
    <v-btn color="error" class="my-5 min-width-100" @click="back">{{$t('buttons.back')}}</v-btn>
</ErrorPage>

</template>
<script>
import ErrorPage from '@/pages/errors/ErrorPage';
export default {
    props: {
        backAction:{
            type: Function,
            default: undefined
        } 
    },
    components: {
        ErrorPage
    },
    methods:{
        back(){
            if (this.backAction){
                this.backAction()
            }else {
                const appId = this.$route.params.id;
                this.$router.push({
                    name:'apps',
                    params: {
                        id: appId
                    }
                })
            }
        }
    }
};
</script>
