<template>
  <v-dialog width="360" 
    v-model="showingDialog">
    <v-card>
      <v-card-title class="px-0 justify-center">
        <div>{{ $t('guest.changePassword') }}</div>
      </v-card-title>
      <v-card-text style="height:320px;" class="pt-2">
        <div>
          <v-form ref="passwordForm" v-model="isFormValid">
            <v-row no-gutters class="mb-1">
              <div class="d-flex flex-column w-100 align-items-stretch">
                <label>{{ $t('guest.oldPassword') }}*</label>
                <v-text-field 
                  v-model="oldPassword"
                  :append-icon="showOldPassword?'mdi-eye':'mdi-eye-off'"
                  :rules="[RULE_REQUIRED]"
                  prepend-inner-icon="mdi-key"
                  solo dense outlined
                  :type="showOldPassword?'text':'password'"
                  @click:append="showOldPassword=!showOldPassword"></v-text-field>
              </div>
            </v-row>
            <v-row no-gutters class="mb-1">
              <div class="d-flex flex-column w-100 align-items-stretch">
                <label>{{ $t('guest.newPassword') }}*</label>
                <v-text-field
                  v-model="newPassword"
                  :append-icon="showNewPassword?'mdi-eye':'mdi-eye-off'"
                  :rules="[RULE_REQUIRED]"
                  prepend-inner-icon="mdi-key"
                  solo dense outlined
                  :type="showNewPassword?'text':'password'"
                  @click:append="showNewPassword=!showNewPassword"></v-text-field>
              </div>
            </v-row>
            <v-row no-gutters class="mb-1">
              <div class="d-flex flex-column w-100 align-items-stretch">
                <label>{{ $t('guest.newPasswordConfirm') }}*</label>
                <v-text-field
                  v-model="newPasswordConfirm"
                  :append-icon="showNewPasswordConfirm?'mdi-eye':'mdi-eye-off'"
                  :rules="[RULE_REQUIRED, passwordConfirmationRule]"
                  prepend-inner-icon="mdi-key"
                  solo dense outlined
                  :type="showNewPasswordConfirm?'text':'password'"
                  @click:append="showNewPasswordConfirm=!showNewPasswordConfirm"
                  ></v-text-field>
              </div>
            </v-row>

            <v-row class="my-1">
              <v-col cols="6">
                <v-btn
                  class="elevation-0 w-100"
                  color="grey lighten-2"
                  @click="cancel">{{ $t('buttons.cancel')  }}</v-btn>
              </v-col>

              <v-col cols="6">
                <v-btn
                  class="elevation-0 w-100"
                  :disabled="buttonDisabled"
                  color="primary"
                  @click="submit">{{ $t('buttons.ok')  }}</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
   
    </v-card>   
  </v-dialog>
</template>

<script>
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";
import ValidationsHelper from "@/helpers/ValidationsHelper";

// import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'guestLoginDialog',
  components: {
    DialogHeader,
    DialogActions,
  },
  data () {
    return {
      showingDialog: false,

      showOldPassword: false,
      showNewPassword: false,
      showNewPasswordConfirm: false,
      callback: null,

      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',

      RULE_REQUIRED: (v) =>
        !ValidationsHelper.isEmpty(v) ||
        this.$t("messages.thisFieldIsRequired"),
      isFormValid: false
    }
  },
  watch: {
    'oldPassword': function() {
      this.checkValidity()
    },
    'newPassword': function() {
      this.checkValidity()
    },
    'newPasswordConfirm': function() {
      this.checkValidity()
    },
  },
  computed: {
    buttonDisabled () {
      return !this.isFormValid
    },
    passwordConfirmationRule() {
      return () => (this.credential.password === this.credential.passwordConfirm) || this.$t('guest.passwordMustMatch')
    }
  },
  methods: {
    checkValidity () {
      this.$refs.passwordForm.validate()
    },
    emptyInput() {
      this.oldPassword = ''
      this.newPassword = ''
      this.newPasswordConfirm = ''
    },
    cancel() {
      this.showingDialog = false
    },
    submit() {
      const vm = this
      const postData = {
        urlCommand: '/guests/' + vm.user._id + '/update_password',
        data: {
          oldPassword: vm.oldPassword,
          newPassword: vm.newPassword
        }
      }
      vm.$store.dispatch('AUTH_POST', postData).then(
        () => {
          vm.$toast.show(vm.$t('guest.passwordChangeSuccessfully'))
          vm.showingDialog = false
        },
        error => {
          vm.$toast.error(error.message)
        }
      )
    },
    // setupTest () {
    //   this.credential.userId = 'peterchan@gmail.com'
    //   this.credential.password = 'asdf1234'
    //   this.credential.passwordConfirm = 'asdf1234'
    // },
    onSwitchModeClicked () {
      this.switchMode()
    },
    switchMode (mode) {
      this.emptyInput()

      if (typeof mode === 'undefined') {
        mode = this.mode === 'login' ?
          'register' :
          'login'
      }
      // console.log('switchMode mode = ' + mode)
      console.log('switchMode mode = ' + mode)
      try {
        console.log('mode = ' + mode)
        this.mode = mode
        console.log('mode1 = ' + mode)
        this.$refs.passwordForm.resetValidation()
        console.log('mode2 = ' + mode)
        this.resetCaptcha()
        console.log('mode3 = ' + mode)
      } catch (err) {
        console.log('switchMode :: err: ', err)
      }

      // this.setupTest()
      // if (this.mode === 'login') {
      //   this.mode = 'register'        
      // } else {
      //   this.mode = 'login'
      // }
    },
    open (callback) {
      this.showingDialog = true
      this.callback = callback
      this.emptyInput()
      this.$refs.passwordForm.resetValidation()
    },
    close (payload) {
      this.showingDialog = false
    },
    gotoSignup () {
      console.log('gotoSignup')
      if (typeof this.callback === 'function') {
        this.callback({command: 'gotoSignup'})
      }
    },
    login () {
      if (typeof this.callback === 'function') {
        this.callback({
          command: 'loginAsGuest',
          payload: {
            userId: this.credential.userId,
            password: this.credential.password
          }
        })
      }
    },
    register () {
      if (typeof this.callback === 'function') {
        this.callback({
          command: 'registerAsGuest',
          payload: {
            userId: this.credential.userId,
            password: this.credential.password
          }
        })
      }
    }
  }

}
</script>