<template>
<v-container fluid fill-height class="pa-0 mb-1 position-relative">
  <file-drop
    :fileIds="fileIds"
    :files="files"
    :isLayout="isLayout"
    @onCommand="onCommandHandler"
    :hasError="hasError"
    :fileTypes="fileTypes"
    @input="(val)=> bl_fileTypeAlert = val"
    :acceptExt="acceptExt"
    :readonly="readonly"
    :deletable="deletable"
  >
  </file-drop>
  <div class="d-flex flex-row align-start w-100 justify-space-between"  v-if="!readonly">
  <!-- <v-btn @click="onUploadClick"
    :loading="isSelecting">
    <v-icon>mdi-upload</v-icon>
    {{ $t('buttons.upload') }}
  </v-btn> -->
    <v-btn @click="onUploadClick" text class="pa-0 drop-upload-browser" samll :ripple="false" :disabled="readonly && !uploadable" style="height: 22px">
      <v-icon color="primary" size="21" style="min-height:0px; height:0px">
        mdi-folder-plus-outline
      </v-icon>
      <span :class="(readonly && !uploadable)?'grey--text':'primary--text'" style="font-size: 13px">{{$t('buttons.browse')}}</span>
    </v-btn>
    <v-spacer></v-spacer>
    <v-tooltip bottom max-width="300" >
      <template v-slot:activator="{ attrs, on }">
         <span class="primary--text" v-bind="attrs" v-on="on">{{ hint }}</span>
      </template>
      <div v-if="acceptExt.length>0">{{$t('widgets.properties.supportFileFormat')+ ': ' + acceptExt.join(' / ')}}</div>
      <div v-else>{{$t('widgets.properties.supportAllFileFormat')}}</div>
    </v-tooltip>

  </div>
  <input
      type="file"
      ref="uploader"
      :multiple="multiple"
      @change="onInputFileChanged"
      class="d-none"
      :accept="acceptStr"
  />
  <v-dialog v-model="isInvalid" width="300">
    <v-card>
      <v-card-subtitle class="d-flex flex-row align-center grey lighten-3 py-1 px-3">
        <v-icon color="red" size="20" left>
          mdi-alert-circle-outline
        </v-icon>
        {{ $t('buttons.upload') }}
      </v-card-subtitle>
      <v-card-text class="py-3 px-3" >
        <div v-if="bl_fileTypeAlert">
          <label>{{ $t('messages.thisFieldAcceptFilesBelow', {
            fieldName: fieldName}) }}:</label>
          <div>
            <v-chip dense x-small label
                    color="primary"
                    :key="ext"
                    class="mr-1"
                    v-for="ext in acceptExt">
                {{ ext }}
            </v-chip>
          </div>
        </div>
        <div v-else-if="fileSizeExceeded">
          <label>{{ $t('messages.fileSizeExceed') }}!</label>
          <v-chip color="info" style="min-height:16px;height:16px;">{{ $t('messages.maxSizePerFile') }}: {{ formatSize(errorBag.maxSizePerFile) }}</v-chip>
          <table>
            <tr v-for="(file,index) in errorBag.files"
              :key="index">
              <td class="pe-5">{{ file.name }}</td>
              <td>{{ formatSize(file.size) }}</td>
            </tr>
          </table>
        </div>
        <div v-else-if="storageExceeded">
          <label>{{ $t('messages.totalStorageExceed') }}</label>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <div v-if="uploading" class="position-absolute w-100 fill-height d-flex justify-center flex-row text-center align-center"
    style="left:0;top:0;background-color:rgba(0, 0, 0, .5);">
     <v-icon dark x-large>mdi-spin mdi-loading</v-icon>
  </div>
</v-container>
</template>

<script>
import fileDrop from './FileDrop'
import MediaHelper from '@/helpers/MediaHelper'
import helper from '@/helpers';

export default {
  components: {
    fileDrop
  },
  data () {
    return {
      isSelecting: false,
      bl_fileTypeAlert: false,
      fileSizeExceeded:false,
      storageExceeded:false,
      uploading: false
    }
  },
  methods: {
    formatSize(bytes) {
      return helper.formatByteToHumanReadableString(bytes);
    },
    onUploadClick () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})
      this.$refs.uploader.value = '';
      this.$refs.uploader.click()
    },
    onInputFileChanged (e) {
      const vm = this
      var files = []
      for (var i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i])
      }

      this.bl_fileTypeAlert = false;
      if (vm.acceptExt.length > 0) {
        for (let i = 0; i < files.length; i++){
          if(!vm.acceptExt.includes(files[i].name.split('.').pop().toLowerCase())) {
            this.bl_fileTypeAlert = true
            break;
          }
          // else if (files[i].size > ((20 * Math.pow(1024, 2)))){
          //   this.sizeExceed = true
          //   break;
          // }
        }
      }
      
      if(!this.bl_fileTypeAlert)
        vm.uploadFiles(files)
      // if(!this.bl_fileTypeAlert && !this.sizeExceed)
      //   vm.uploadFiles(files)

    },

    onCommandHandler (payload) {
      console.log('onCommandHandler : payload: ', payload);
      const vm = this
      switch (payload.command) {
        case 'triggerUpload':
          vm.onUploadClick()
          break
        case 'uploadFiles':
          vm.uploadFiles(payload.files)
          break
        default:
          vm.$emit('onCommand', payload)
          break;
      }
    },

    uploadFiles (files) {
      console.log('DropUpload: uploadFiles: files: ', files);
      const vm = this
      const payload = {
        appId: vm.appId,
        formId: vm.formId,
        files,
        isPublic: this.$store.getters.isPublicRoute
      }
      vm.uploading = true;
      vm.$emit('onCommand', {command: 'startUploading'});
      
      MediaHelper.uploadFiles(payload, (res) => {
        vm.uploading = false;
        if (res.result === false){
          switch (res.code) {
            case 'filesize-exceeded':
              this.fileSizeExceeded = true;
              this.errorBag = {
                files: res.files,
                maxSizePerFile: res.maxSizePerFile
              }
              break;
            case 'storage-exceeded':
              this.storageExceeded = true;
              this.errorBag = {
                teamStorageStatus: res.teamStorageStatus,
                sizeRequested: res.sizeRequested
              }
              break;
          }
          
        } else 
        vm.$emit('onCommand', {
          command: 'addAttachments',
          mediaIds: res.map(media => media._id),
          mediaTypes: res.map(media => media.mediaType),
          objectKeys: res.map(media => media.objectKey),
          originalNames: res.map(media => media.originalName),
          thumbnailUrls: res.map(media => media.thumbnailUrl)
        })
      })
    },


    // ,
    // uploadFiles (files) {
    //   const vm = this
    //   const postPayload = {
    //     appId: vm.currentApp._id,
    //     files: files
    //   }
    //   vm.$store.dispatch('UPLOAD_MEDIA', postPayload).then(medias => {
    //     console.log('UPLOAD_MEDIA :: medias: ', medias)
    //     vm.$emit('onCommand', {
    //       command: 'addAttachments',
    //       mediaIds: medias.map(media => media._id)
    //     })
    //   })
    // }
  },
  computed: {
    acceptStr(){
      var res = this.acceptExt.map(item=>('.'+item))
      return res.join(',')
    },
    currentApp () {
      return this.$store.getters.currentApp
    },
    formId () {
      return this.$store.getters.currentForm ?
        this.$store.getters.currentForm._id : 
        '';
    },
    isInvalid:{
      get(){
        return this.bl_fileTypeAlert || this.fileSizeExceeded || this.storageExceeded
      },
      set(val){
        if (!val){
          this.bl_fileTypeAlert = false
          this.fileSizeExceeded = false
          this.storageExceeded = false
        }
      }
      
    }
  },
  props: {
    fieldName: String,
    fileIds: Array,
    files: Array,
    multiple: Boolean,
    hasError: Boolean,
    isLayout: Boolean,
    hint: String,
    fileTypes: Array,
    acceptExt: Array,
    readonly:{
      type: Boolean,
      default: false
    },
    appId:String,
    deletable:{
      type: Boolean,
      default: true
    },
    uploadable:{
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
  .drop-upload-browser:before {
    content: none!important;
}

</style>
