<template>
  <vxe-table-column v-bind="colDef" show-overflow="hidden">
    <!-- </vxe-table-column>style="max-height:100%;"> -->
    <template v-slot="{ row }">
      <!-- row[fieldId]: {{ row[fieldId] }}
      recordData: {{ getRelatedRecordData(row) }} -->
      <div
        class="w-100 cursor-pointer blue--text d-inline-block"
        :class="{ 'text-right': isNumeric }"
        @click="gotoRelatedRecord(row, colDef.field)"
      >
        <field-string
          v-if="
            [
              'text',
              'number',
              'summaryField',
              'expression',
              'textCombination',
            ].includes(activeFieldInfo.type)
          "
          :textOnly="false"
          height="100%"
          :rowSize="rowSize"
          :fieldInfo="activeFieldInfo"
          :recordData="getRelatedRecordData(row)"
        ></field-string>
        <div
          v-else
          :style="specialRowStyle">
          <field-string
            :textOnly="false"
            height="100%"
            :rowSize="rowSize"
            :fieldInfo="activeFieldInfo"
            :recordData="getRelatedRecordData(row)"
          ></field-string>
        </div>
      </div>
      <!-- <field-string
        :textOnly="false"
        :fieldInfo="relatedTableFieldInfo"
        :recordData="getRelatedRecordData(row)"></field-string> -->
    </template>

    <template v-slot:footer="{ column }">
      <summary-cell
        :decimals="decimals"
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"
      ></summary-cell>
    </template>
  </vxe-table-column>
</template>
<script>
import mixin from "./mixin";
import fieldString from "@/components/fieldStrings/Fs";
import summaryCell from "./comps/SummaryCell";

export default {
  name: "vxeCellOtherTableField",
  mixins: [mixin],
  props: ["rowHeightClass"],
  components: {
    fieldString,
    summaryCell,
  },
  computed: {
    isNumeric() {
      return (
        ["number", "amount", "summaryField"].includes(
          this.activeFieldInfo.type
        ) ||
        (this.activeFieldInfo.type === "expression" &&
          this.activeFieldInfo.properties.expressionType === "number")
      );
    },
    activeFieldInfo() {
      return this.relatedTableFieldInfo
        ? this.relatedTableFieldInfo
        : this.fieldInfo;
    },

    specialRowStyle () {
      var height = 'auto';

      switch(this.rowHeightClass) {
        case "vxe-table-row-xs":
          height = '40px';
          break;
        case "vxe-table-row-s":
          height = '64px';
          break;
        case "vxe-table-row-l":
          height = '88px';
          break;
        case "vxe-table-row-xl":
          height = '112px';
          break;
      }
      return {height: height + ' !important'};
    },

    rowSize() {
      switch (this.rowHeightClass) {
        case "vxe-table-row-xs":
          return "small";
        // case "vxe-table-row-s":
        //   return "regular";
        case "vxe-table-row-l":
          return "large";
        case "vxe-table-row-xl":
          return "x-large";
      }
      return "regular";
    },
    referToFieldInfoId() {
      // console.log('computed(referToFieldInfoId): this.fieldInfo.label = ' + this.fieldInfo.label)
      return this.fieldInfo.properties.fieldForRelatedTable;
    },
    referToFieldInfo() {
      const vm = this;
      var result = null;
      const referToFieldInfo = vm.fieldInfos.find(
        (info) => info._id === vm.referToFieldInfoId
      );
      if (referToFieldInfo) {
        result = referToFieldInfo;
      }
      return result;
    },

    referToFieldId() {
      return this.referToFieldInfo ? this.referToFieldInfo.fieldId : "";
    },

    relatedFormId() {
      return this.referToFieldInfo
        ? this.referToFieldInfo.properties.dataSource
        : "";
    },

    relatedFormInfo() {
      const vm = this;
      var result = null;
      if (vm.relatedTableInfos) {
        if (vm.relatedFormId) {
          result = vm.relatedTableInfos[vm.relatedFormId];
        }
      }
      return result;
    },

    relatedTableFieldId() {
      return this.fieldInfo.properties.relatedTableField;
    },
    relatedTableFieldInfo() {
      const vm = this;
      var result = null;
      if (vm.relatedFormInfo && vm.relatedTableFieldId) {
        result = vm.relatedFormInfo.fieldInfos.find(
          (info) => info.fieldId === vm.relatedTableFieldId
        );
      }
      return result;
    },
  },
  methods: {
    gotoRelatedRecord(row, fieldId) {
      if (this.relatedTableFieldInfo.type !== 'attachments' && row[fieldId]) {
        this.$emit("onCommand", {
          command: "gotoRelatedRecordFromOtherTableField",
          recordId: row._id,
          fieldId: fieldId,
        });
      }
    },
    getRelatedRecordData(row) {
      const vm = this;
      var result = null;
      // if (row[vm.fieldId]) {
      //   if (Array.isArray(row[vm.fieldId]) && row[vm.fieldId].length > 0) {
      //     result = {};
      //     result[vm.relatedTableFieldId] = row[vm.fieldId].length
      //   } else if (row[vm.fieldId] !== "") {
      //     result = {};
      //     if (vm.relatedTableFieldInfo) {
      //       result[vm.relatedTableFieldId] = row[vm.fieldId];
      //     } else {
      //       result[vm.fieldId] = row[vm.fieldId];
      //     }
      //   }
      // } else {
        const relatedRecordIds = row[vm.referToFieldId];
        if (relatedRecordIds) {
          const relatedRecordId = relatedRecordIds[0];

          if (vm.idDataMapping && relatedRecordId) {
            result = vm.idDataMapping[relatedRecordId];
          } else {
            result = row[vm.fieldId];
          }
        } else {
          result = row[vm.fieldId];
        }
        // console.log('getRelatedRecordData: result: ', result)
      // }
      return result;
    },
  },
};
</script>
