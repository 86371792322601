<template>
  <div class="workflow-free-input">
    <div
      :style="containerStyle"
      class="workflow-customized-form-record-element-editor workflow-customized-form-record-element-input"
      :class="{ 'workflow-customized-form-record-element-input-hide': isValue }"
    >
      <DialogFieldDateTime
        ref="datetime"
        v-model="value"
        disabled
      />
    </div>
    <!-- <codemirror
      ref="freeInput"
      @ready="onReady"
      v-model="value"
      :options="{ inputOptions }"
      class="CodeMirror workflow-customized-form-record-element-editor"
      style="height: auto; line-height: 26px; padding: 0 5px;"
      :style="containerStyle"
    /> -->
    <ComboBox
      :options="list"
      :value="normalize(value) || null"
      :onlyPrefix="true"
      :dependsOnType="fieldType"
      :disabledOnDependencies="true"
      :onlySuffix="true"
      :isMultiple="false"
      :otherProps="{ zIndex: 9999, limit: 1 }"
      placeholder=""
      @selected="selected"
      @changed="changed"
      valueFormat="object"
      :disableBranchNode="true"
    />
  </div>
</template>

<script>
// import Validator from 'validator';
import FormElement from './../shared/formElement';
import DialogFieldDateTime from "@/components/dialogFields/DialogFieldDateTime";
import ComboBox from './../../../form/comboBox/';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import NodeUtils from '@/pages/admin/workflow/utils/node';
import Mixin from './../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   SET_SETTINGS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ComboBox,
    FormElement,
    DialogFieldDateTime,
  },
  props: {
    value: [String, Object],
    fieldType: String,
    valueChanged: Function,
    itemsChanged: Function,
  },
  methods: {
    changed(v) {
      this.value = v;
    },
    normalize(v) {
      return typeof v === 'string' ? null : v;
    }
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  computed: {
    isValue() {
      return !this.value || typeof this.value === 'string';
    },
    containerStyle() {
      return {          
        width: 'calc(100% - 30px)',
      };
    },
    list() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));
      return NodeUtils.withAcceptanceOptions({
        dependsOnType: this.fieldType, 
        list: workflowOutputs,
      });
    },
  },
  watch: {
    value: {
      handler(v) {
        this.$emit('valueChanged', v);
      },
      deep: true
    },
  },
};

</script>

<style scoped>

</style>
