/**
 * Get fieldInfo.properties value. Return default value if not found
 * @param {Object} fieldInfo fieldInfo object
 * @param {String} propertyKey Object key of fieldInfo.properties
 * @param {any} defaultValue default value to return if property no found
 */
const getFieldPropertyValue = (fieldInfo, propertyKey, defaultValue) => {
    var result = defaultValue
    if (fieldInfo && fieldInfo.properties && fieldInfo.properties[propertyKey]) {
      result = fieldInfo.properties[propertyKey]
    }
    return result
}

export {
  getFieldPropertyValue
}
