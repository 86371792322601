import ValidationsHelper from "@/helpers/ValidationsHelper";
export default {
  methods: {
    RULE_REQUIRED(v) {
      return (
        !ValidationsHelper.isEmpty(v) || this.$t("messages.thisFieldIsRequired")
      );
    },
    RULE_LENGTH_LIMIT(v, max) {
      return (
        (v && v.length <= max) || this.$t("messages.mustNotExceed", { max })
      );
    },
    RULE_EMAIL(v) {
      return (
        ValidationsHelper.isValidEmail(v) || this.$t("messages.invalidEmail")
      );
    },
  },
};
