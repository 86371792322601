export default {
  authUrl: (state) => {
    return state.constants ? state.constants.appHost + "/auth" : "";
  },
  apiUrl: (state) => {
    return state.constants ? state.constants.appHost + "/apiv2" : "";
  },
  appHost: (state) => {
    return state.constants ? state.constants.appHost : "";
  },
  cardNonDisplayFieldTypes: (state) => {
    return state.cardNonDisplayFieldTypes;
  },
  constants: (state) => {
    return state.constants;
  },
  colorSets: (state) => {
    return state.colorSets;
  },
  copyableWidgetTypes: (state) => {
    return Object.keys(state.copyableWidgets)
  },
  copyableWidgets: (state) => {
    return state.copyableWidgets
  },
  defaultViewSettings: (state) => {
    return state.defaultViewSettings
  },
  industries: (state) => {
    return state.industries
  },
  countries: (state) => {
    return state.countries
  },
  currencies: (state) => {
    return state.currencies
  }

};
