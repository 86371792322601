<template>
  <v-dialog
    v-model="showingDialog"
    scrollable
    persistent
    width="960"
    content-class="import-dialog"
  >
    <div style="background-color:white;height:90vh " class="d-flex flex-column">
      <v-row no-gutters class="flex-grow-0">
        <v-col>
          <dialog-header :label="$t('buttons.import')" @close="cancelImport" />
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-0 flex-grow-1">
        <loading-icon v-if="loading" />
        <v-stepper
          v-else
          v-model="step"
          style="width:100%"
          class="d-flex flex-column fill-height elevation-0 importStepper"
        >
          <v-stepper-header class="" style="height:50px">
            <v-stepper-step
              :complete="files.length > 0"
              step="1"
              class="py-0"
              :style="step == '1' ? 'border-bottom: #3aaba7 2px solid' : ''"
              edit-icon="mdi-close"
            >
              {{ $t("importDialog.step_1_title") }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              step="2"
              :complete="selectedSheetName !== ''"
              class="py-0"
              :style="step == '2' ? 'border-bottom: #3aaba7 2px solid' : ''"
              edit-icon="mdi-close"
            >
              {{ $t("importDialog.step_2_title") }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              step="3"
              class="py-0"
              :style="step == '3' ? 'border-bottom: #3aaba7 2px solid' : ''"
            >
              {{ $t("importDialog.step_3_title") }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="d-flex flex-column fill-height">
            <v-stepper-content
              v-if="step === 1"
              step="1"
              class="d-flex flex-column fill-height px-5 pb-0"
            >
              <v-row no-gutters class="instruction-box px-5 mb-4">
                <div class="mb-4">
                  {{ $t("importDialog.step_1_instruction") }}
                </div>
                <file-drop
                  :fileIds="files"
                  :isLayout="false"
                  @onCommand="onCommandHandler"
                  @input="onInput"
                  :hasError="hasError"
                  :fullHeight="true"
                  :acceptExt="acceptExts"
                  class="d-flex fill-height"
                  style="padding:0px !important"
                >
                  <template>
                    <v-simple-table
                      fixed-header
                      v-if="files.length > 0"
                      height="100%"
                      style="width:100%"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("drawer.fileName") }}
                            </th>
                            <th class="text-right" style="width:120px">
                              {{ $t("drawer.action") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(file, index) in files" :key="index">
                            <td>{{ file.originalName }}</td>
                            <td class="text-right">
                              <v-btn
                                depressed
                                fab
                                x-small
                                @click="removeAttachment"
                                ><v-icon>mdi-delete-outline</v-icon></v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </file-drop>
              </v-row>
              <v-row no-gutters align="end" class="">
                <v-col>
                  <dialog-actions
                    :buttons="['cancel', 'next']"
                    @onCommand="onCommandHandler($event)"
                    :actionDisabled="files.length === 0"
                  >
                  </dialog-actions>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content
              v-else-if="step === 2"
              step="2"
              class="d-flex flex-column fill-height pb-0"
            >
              <v-row no-gutters class="instruction-box pa-2">
                {{ $t("importDialog.step_2_instruction") }}
              </v-row>
              <v-row no-gutters class="mt-2 fill-height mt-0">
                <v-col
                  cols="3"
                  class="fill-height"
                  style="border-right: 5px solid #f2f2f2"
                >
                  <perfect-scrollbar
                    class="flex-grow-1 d-flex flex-column"
                    style="overflow-y:scroll;"
                    :options="{ suppressScrollX: true }"
                  >
                    <v-radio-group
                      dense
                      v-model="selectedSheetName"
                      mandatory
                      class="mt-0"
                    >
                      <v-row
                        no-gutters
                        align="center"
                        v-for="sheetInfo in sheetInfos"
                        :key="sheetInfo.sheetName"
                      >
                        <v-radio
                          class="pa-2 mb-0"
                          :value="sheetInfo.sheetName"
                          :label="sheetInfo.sheetName"
                          :disabled="sheetInfo.headers.length === 0"
                        >
                        </v-radio>
                        <div v-if="sheetInfo.error">
                          <v-tooltip bottom max-width="180px">
                            <template v-slot:activator="{ on }">
                              <v-icon dense v-on="on"
                                >mdi-information-outline</v-icon
                              >
                            </template>
                            {{
                              $t("importDialog.rowExceed", sheetInfo.mergeTags)
                            }}
                          </v-tooltip>
                        </div>
                        <v-divider />
                      </v-row>
                    </v-radio-group>
                  </perfect-scrollbar>
                </v-col>
                <v-col cols="9" class="pl-0">
                  <SimpleTable
                    v-if="headers.length > 0"
                    :headers="headers"
                    :data="tableData"
                  />
                  <div v-else class="fill-height center">
                    {{ $t(`importDialog.noSheet`) }}
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters align="end" class="mt-0">
                <v-col>
                  <dialog-actions
                    :buttons="['cancel', 'next']"
                    @onCommand="onCommandHandler($event)"
                    :actionDisabled="!headers || headers.length === 0"
                  >
                  </dialog-actions>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content
              v-else-if="step === 3"
              step="3"
              class="d-flex flex-column fill-height pb-0"
            >
              <v-row no-gutters class="instruction-box pa-2">
                {{ $t("importDialog.step_3_instruction") }}
              </v-row>
              <v-row no-gutters class="mt-0">
                <v-col cols="8" class="">
                  <perfect-scrollbar
                    class="d-flex flex-column "
                    style="overflow-y:scroll;"
                    :options="{ suppressScrollX: true }"
                  >
                    <v-row
                      no-gutters
                      class="pa-2 flex-grow-0"
                      style="font-size: 13px"
                    >
                      <v-col cols="5" class="text-end px-0 grey--text">
                        {{ $t("importDialog.formFields") }}
                      </v-col>
                      <v-col cols="6" offset="1" class="grey--text">
                        {{ $t("importDialog.excelFields") }}
                      </v-col>
                    </v-row>
                    <v-row
                      no-gutters
                      v-for="fieldInfo in filteredFieldInfos"
                      :key="fieldInfo.fieldId"
                      class="pa-2 flex-grow-0"
                    >
                      <v-col
                        cols="5"
                        align-self="center"
                        class=" d-flex flex-grow-1 px-2  option-box"
                        style="min-height: 30px;"
                      >
                        <v-row style="height: 100%;" no-gutters>
                          <v-col cols="12" class="pa-0">
                            <v-row
                              no-gutters
                              class="text-end"
                              justify="end"
                              align="center"
                              style="height:30px"
                            >
                              <font-awesome-icon
                                :icon="getWidgetIcon(fieldInfo.type)"
                                class="fa-fw mr-1"
                                style="color:grey"
                              ></font-awesome-icon>
                              {{ fieldInfo.properties.fieldName }}
                            </v-row>
                            <v-row
                              no-gutters
                              class="pb-2 text-end"
                              v-if="
                                [
                                  'singleSelection',
                                  'multipleSelection',
                                ].includes(fieldInfo.type)
                              "
                              justify="end"
                              align="center"
                            >
                              <v-checkbox
                                class="add-option-checkbox"
                                dense
                                hide-details
                                :label="$t('widgets.properties.allowUserAdd')"
                                @change="
                                  onAllowAddChanged($event, fieldInfo.fieldId)
                                "
                              />
                            </v-row>
                            <v-row
                              no-gutters
                              class="pb-2"
                              v-if="
                                fieldInfo.type === 'relatedRecord' &&
                                  relatedFieldMap[fieldInfo.fieldId]
                              "
                            >
                              <div
                                class="mx-2 float-right"
                                style="line-height:1.3"
                              >
                                {{ $t("importDialog.selectField") }}
                              </div>
                              <v-select
                                class="mx-2"
                                :menu-props="{
                                  offsetY: true,
                                  contentClass: 'denseList',
                                }"
                                background-color="white"
                                :value="
                                  relatedFieldMap[fieldInfo.fieldId]
                                    .checkFieldId
                                "
                                outlined
                                dense
                                style="font-size:13px"
                                height="25"
                                single-line
                                hide-details
                                :items="
                                  getRelatedFormFieldInfos(
                                    fieldInfo.properties.dataSource
                                  )
                                "
                                item-text="label"
                                item-value="_id"
                                @change="
                                  onSelectRelatedField(
                                    $event,
                                    fieldInfo.fieldId
                                  )
                                "
                              />
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="1" align-self="center" class="flex-grow-1">
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="5"
                        align-self="center"
                        class="d-flex align-center flex-grow-1"
                        style="font-size:13px; height:100%"
                      >
                        <v-row no-gutters class="" style="height:100%">
                          <v-hover
                            v-slot="{ hover }"
                            v-if="canBeImport(fieldInfo.type, fieldInfo)"
                          >
                            <v-select
                              :items="headers"
                              :clearable="hover"
                              class="importSelect"
                              :menu-props="{
                                offsetY: true,
                                contentClass: 'denseList',
                              }"
                              :value="fieldMappings[fieldInfo.fieldId]"
                              @click:clear="
                                onClickClear($event, fieldInfo.fieldId)
                              "
                              outlined
                              dense
                              height="30"
                              hide-details
                              single-line
                              @change="onSelectField($event, fieldInfo.fieldId)"
                            />
                          </v-hover>
                          <div v-else class="d-flex align-center">
                            {{ $t("importDialog.inapplicable") }}
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </perfect-scrollbar>
                </v-col>
                <v-col
                  col="4"
                  class="flex-grow-1 pa-2"
                  style="border-left: 5px solid #f2f2f2"
                >
                  <v-row class="px-5" no-gutters align="center" justify="start">
                    <v-switch
                      dense
                      class="ma-0 pt-0 pb-1"
                      hide-details
                      v-model="skipDuplicate"
                      :label="$t('importDialog.skipDup')"
                    />
                  </v-row>
                  <div no-gutters v-if="skipDuplicate" class="px-2">
                    <v-row no-gutters class="px-4">
                      <v-radio-group
                        mandatory
                        v-model="skipDupMode"
                        :column="false"
                        dense
                      >
                        <v-radio
                          value="ignore"
                          :label="$t('importDialog.ignore')"
                          class="pr-2"
                        ></v-radio>
                        <v-radio
                          value="replace"
                          :label="$t('importDialog.overwrite')"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                    <v-row no-gutters justify="center" align="end" class="pl-4">
                      <v-col cols="3" style="font-size:14px">
                        {{ $t("importDialog.selectField") }}
                      </v-col>
                      <v-col>
                        <v-select
                          :items="checkDupFieldInfos"
                          v-model="skipDupField"
                          dense
                          :menu-props="{
                            offsetY: true,
                            offsetOverflow: true,
                            contentClass: 'denseList',
                          }"
                          hide-details
                          single-line
                          height="25"
                          style="font-size:13px"
                          item-text="properties.fieldName"
                          item-value="fieldId"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters align="end" class="mt-0">
                <v-col>
                  <dialog-actions
                    :buttons="['previous', 'import']"
                    @onCommand="onCommandHandler($event)"
                    :actionDisabled="emptyMap"
                  >
                  </dialog-actions>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-row>
    </div>
    <input
      type="file"
      ref="uploader"
      :multiple="false"
      accept=".xlsx, .xls"
      @input="onInputFileChanged"
      class="d-none"
    />
    <confirm-dialog ref="ConfirmDialog" />
  </v-dialog>
</template>
<script>
import fileDrop from "@/components/dropUpload/FileDrop";
import SimpleTable from "../comps/SimpleTable";
import {
  systemFieldIds,
  checkDupFieldType,
  importRelatedRecordFieldType,
} from "@/const/fieldConstants";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import Vue from "vue";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import dialogActions from "@/components/dialogs/comps/DialogActions";
import loadingIcon from "@/components/global/loadingIcon";
import MediaHelper from "@/helpers/MediaHelper";
import {
  imports as IMPORT_ACTIONS,
  common as COMMON_ACTIONS,
} from "@/store/action-types";


export default {
  props: {
    value: Boolean,
  },
  components: {
    SimpleTable,
    fileDrop,
    ConfirmDialog,
    dialogHeader,
    dialogActions,
    loadingIcon,
  },
  data() {
    return {
      loading: false,
      isSelecting: false,
      files: [],
      sheetInfos: [],
      step: 1,
      acceptExts: ["xlsx", "xls"],

      selectedSheetName: "",
      fieldMappings: {},
      relatedFieldMap: {},
      addNewOptionsFields: [],
      skipDuplicate: false,
      skipDupMode: 0,
      skipDupField: "",
    };
  },
  computed: {
    showingDialog: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
    sheetNames() {
      return this.sheetInfos.map((sheet) => sheet.sheetName);
    },
    selectedSheet() {
      return this.sheetInfos.find(
        (sheet) => sheet.sheetName === this.selectedSheetName
      );
    },
    headers() {
      return this.selectedSheet ? this.selectedSheet.headers : [];
    },
    tableData() {
      return this.selectedSheet ? this.selectedSheet.data : [];
    },

    filteredFieldInfos() {
      const fieldInfos = this.$store.getters.currentForm
        ? this.$store.getters.currentForm.fieldInfos
        : [];
      return fieldInfos.filter(
        (info) => !systemFieldIds.includes(info.type) && info.canCreate
      );
    },
    checkDupFieldInfos() {
      return this.filteredFieldInfos.filter((info) =>
        checkDupFieldType.includes(info.type)
      );
    },
    emptyMap() {
      return Object.keys(this.fieldMappings).length === 0;
    },
    appId() {
      return this.$store.getters.currentApp._id;
    },
    formId() {
      return this.$store.getters.currentForm._id;
    },
  },
  watch: {
    selectedSheetName: function(newVal, oldVal) {
      if (newVal !== oldVal) this.fieldMappings = {};
    },
  },
  mounted() {
    const relatedFields = this.filteredFieldInfos.filter(
      (info) =>
        info.type === "relatedRecord" &&
        info.properties.relatedRecordQuantity === "single"
    );
    relatedFields.forEach((relatedFieldInfo) => {
      const formId = relatedFieldInfo.properties.dataSource;
      const options = this.getRelatedFormFieldInfos(formId);
      if (options.length > 0) {
        this.$set(this.relatedFieldMap, relatedFieldInfo.fieldId, {
          formId,
          checkFieldId: "",
        });
        this.onSelectRelatedField(options[0].fieldId, relatedFieldInfo.fieldId);
      }
    });
  },
  methods: {
    submit() {
      const data = {
        appId: this.appId,
        formId: this.formId,
        filePath: this.files[0].filePath,
        sheetName: this.selectedSheetName,
        fieldMap: this.fieldMappings,
        addNewOptionsFields: this.addNewOptionsFields,
      };

      if (Object.keys(this.relatedFieldMap).length > 0) {
        Object.assign(data, { relatedFieldMap: this.relatedFieldMap });
      }
      if (this.skipDuplicate) {
        if (!this.skipDupField || !this.skipDupMode) {
          return;
        } else {
          Object.assign(data, {
            duplicateConfig: {
              mode: this.skipDupMode,
              fieldId: this.skipDupField,
            },
          });
        }
      }
      this.$store.dispatch(IMPORT_ACTIONS.IMPORT_START, data).then(() => {
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
          key: "plus_sheet_mani",
          data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.table_view.IMPORT_RECORD,
          }
        });
      });
      this.showingDialog = false;
    },
    cancelImport() {
      if (this.files.length > 0) {
        this.$refs.ConfirmDialog.open(
          this.$t("importDialog.close"),
          this.$t("importDialog.cancelMsg"),
          {
            color: "error",
            confirmBtnColor: "error",
          }
        ).then((confirm) => {
          if (confirm) {
            this.showingDialog = false;
          }
        });
      } else {
        this.showingDialog = false;
      }
    },
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "triggerUpload":
          vm.onUploadClick();
          break;
        case "uploadFiles":
          vm.uploadFile(payload.files);
          break;
        case "removeAttachment":
          vm.removeAttachment();
          break;
        case "cancel":
          this.cancelImport();
          break;
        case "next":
          if (this.step == "1") this.getHeaders();
          if (this.step == "2") this.mapFieldLabelToHeader();
          break;
        case "previous":
          this.step = 2;
          break;
        case "import":
          this.submit();
          break;
      }
    },
    removeAttachment() {
      this.files = [];
    },
    onUploadClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onInput(e) {
      if (e === true) {
        this.$alert(this.$t("importDialog.invalidExt"));
        return;
      }
    },
    onInputFileChanged(e) {
      const vm = this;
      var files = [];
      for (var i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }
      vm.uploadFile(files);
    },
    checkExtension(file) {
      const VALID_FILE_TYPES = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (!VALID_FILE_TYPES.includes(file.type)) {
        return false;
      } else return true;
    },
    uploadFile(files) {
      if (files.length > 1) {
        this.$alert(this.$t("messages.attachmentCountAtMost", { max: 1 }));
        return;
      }

      if (this.checkExtension(files[0]) === false) {
        this.$alert(this.$t("importDialog.invalidExt"));
        return;
      }
      if (files[0].size > 2097152) {
        this.$alert(this.$t("importDialog.sizeExceed", { size: "2mb" }));
        return;
      }
    const formData = new FormData();
    formData.append('appId', this.appId)
    formData.append('formId', this.formId)
    formData.append('file',files[0])
      this.$store.dispatch("AUTH_POST", {
        urlCommand: "/medias/upload",
        data:formData,
        options:{
          headers:{
            "Content-Type":"multipart/form-data"
          }
        }
      }).then(res=> {
        const file = {
          ...res,
          originalName: files[0].name
        }
        this.files.splice(0, 1, file);
      })

    },
    canBeImport(type, fieldInfo) {
      let result = false;
      const widget = this.$store.getters.allWidgets.find(
        (item) => item.key === type
      );

      result = Boolean(widget && widget.importable);

      if (widget.key === "relatedRecord") {
        result = Boolean(this.relatedFieldMap[fieldInfo.fieldId]);
      }
      return result;
    },

    getWidgetIcon(type) {
      const widget = this.$store.getters.allWidgets.find(
        (item) => item.key === type
      );

      return widget.icon ? widget.icon : "";
    },
    getHeaders() {
      if (this.files.length !== 1) {
        return;
      }
      this.loading = true;
      const postPayload = {
        urlCommand: "/import/getHeaders",
        data: {
          filePath: this.files[0].filePath,
        },
      };

      this.$store
        .dispatch(COMMON_ACTIONS.AUTH_POST, postPayload)
        .then((res) => {
          this.sheetInfos = res;
          this.step += 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapFieldLabelToHeader() {
      this.filteredFieldInfos.forEach((info) => {
        if (this.canBeImport(info.type, info)) {
          const header = this.headers.find(
            (item) => item === info.properties.fieldName
          );
          if (header) {
            Vue.set(this.fieldMappings, info.fieldId, header);
          }
        }
      });
      this.step = 3;
    },
    onSelectField(header, fieldId) {
      Vue.set(this.fieldMappings, fieldId, header);
    },
    onSelectRelatedField(relatedFormCheckFieldId, currentFormFieldId) {
      Vue.set(
        this.relatedFieldMap[currentFormFieldId],
        "checkFieldId",
        relatedFormCheckFieldId
      );
    },
    onClickClear(e, fieldId) {
      e.preventDefault();

      this.$nextTick(() => {
        Vue.delete(this.fieldMappings, fieldId);
      });
    },
    getRelatedFormFieldInfos(formId) {
      const relatedFormMap = this.$store.getters.relatedTableInfos;
      if (relatedFormMap && relatedFormMap[formId]) {
        return relatedFormMap[formId].fieldInfos.filter((info) =>
          importRelatedRecordFieldType.includes(info.type)
        );
      } else {
        return [];
      }
    },
    onAllowAddChanged(e, fieldId) {
      if (e) {
        this.addNewOptionsFields.push(fieldId);
      } else {
        const index = this.addNewOptionsFields.findIndex(
          (id) => id === fieldId
        );
        if (index > -1) this.addNewOptionsFields.splice(index, 1);
      }
    },
  },
};
</script>
<style>
.add-option-checkbox .v-label {
  font-size: 12px !important;
}

div.import-dialog .v-stepper__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.importStepper .v-stepper__step {
  display: flex;
  justify-content: center;
  font-size: 14px;
  flex-grow: 1;
}
.importStepper .v-stepper__header {
  height: 50px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 1px -2px rgb(0 0 0 / 20%),
    0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.importStepper hr {
  display: none;
}

.importSelect .v-input__icon .v-icon {
  font-size: 17px !important;
}

.importSelect {
  font-size: 13px !important;
}
</style>
<style lang="scss" scoped>
.ps {
  height: calc(90vh - 246px);
}
.instruction-box {
  font-size: 13px;
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
