export const LEVEL_DIAGRAM_CARD_CONFIG = {
  cardSummaryFieldId: '',
  cardImageFieldId: '',
  cardImagePosition: 'left',
  cardImageMode: 'cover',
  cardImageStretch: true,
  cardImageSize: '48',
  cardImagePadding: 0,
  cardDisplayFieldName: false,
  cardDisplayFieldIds: []
}

export const FORM_CARD_DISPLAY_CONFIG = {
  cardSummaryFieldId: '',
  cardImageFieldId: '',
  cardImagePosition: 'left',
  cardImageMode: 'cover',
  cardImageStretch: true,
  cardImageSize: '48',
  cardImagePadding: 2,
  cardDisplayFieldName: false,
  cardDisplayFieldIds: [],
  cardWidthSize: 'large', // ['small', 'middle', 'large']
  // small = 4: columns = [1]
  // middle = 6: columns = [1,2]
  // large = 12: columns = [1,2,3]
  cardContentColumns: 1
}

export const FORM_TABLE_DISPLAY_CONFIG = {
  fixedFieldIds: [],
  nonFixedFieldIds: []
}

export const FORM_CARD_SELECTION_CONFIG = {
  cardConfigs: {
    cardSummaryFieldId: '',
    cardImageFieldId: '',
    cardImagePosition: 'left',
    cardImageMode: 'cover',
    cardImageStretch: true,
    cardImageSize: '48',
    cardImagePadding: 0,
    cardDisplayFieldName: false,
    cardDisplayFieldIds: []
  },
  filterConfigs: {
    filters: [],
    filterAndOr: 'and'
  },
  sortingConfigs: [
  ],
  quickFilterConfigs: {
    filters: [],
    filterAndOr: 'and'
  },
}

export const FORM_TABLE_SELECTION_CONFIG = {
  tableConfigs: {
    fixedFieldIds: [],
    nonFixedFieldIds: []
  },
  filterConfigs: {
    filters: [],
    filterAndOr: 'and'
  },
  sortingConfigs: [],
  quickFilterConfigs: {
    filters: [],
    filterAndOr: 'and'
  },
}

export const APP_DRAWER_CONTENT = [{
  component:'VListItem',
  title:'general.workbench',
  inApp: true,
  url:'/my_apps',
  icon:'mdi-home',
  trackId: "工作台"
},{
  component:'VSubheader',
  title:'general.relatedLink'
},{
  component:'VDivider'
},{
  component:'VListItem',
  title:'general.userCenter',
  inApp: false,
  url:'https://passport.yoov.com/auth/realms/yoov/account/',
  icon:'mdi-account',
  trackId: "用戶中心"
},{
  component:'VListItem',
  title:'general.teamInfo',
  inApp: false,
  url:'https://usercenter.yoov.com/company?tab=company',
  icon:'mdi-widgets-outline',
  trackId: "JOA"
},{
  component:'VListItem',
  title:'general.email',
  inApp: false,
  url:'https://webmail.yoov.com/',
  icon:'mdi-email',
  trackId: "電郵"
},{
  component:'VListItem',
  title:'YOOV.COM',
  inApp: false,
  url:'https://yoov.com',
  icon:'mdi-y-combinator',
  trackId: "YOOV.COM"
},{
  component:'VListItem',
  title:'general.helpCenter',
  inApp: false,
  url:'https://help.yoov.com/en/support/solutions/73000344914',
  icon:'mdi-help-rhombus',
  trackId: "幫助中心"
}]