<template>
  <div>
    <PendingSetup
      v-if="isPending"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <div class="workflow-shortview-remark">
        {{
          $t('workflow.modules.email.label.informTargetNormal')
        }}: {{ totalRecipient }}
      </div>
      <div class="workflow-shortview-remark">
        {{
          $t('workflow.modules.email.label.informForwardTargetNormal')
        }}: {{ totalForwardRecipient }}
      </div>
      <div class="workflow-shortview-remark">
        <b>{{
          $t('workflow.modules.email.label.subject')
        }}</b> <div v-html="expressionDisplay" />
      </div>
    </div>
    
  </div>
</template>

<script>
import PendingSetup from './../pending';
import Mixin from '../mixin';
import { get, replace } from 'lodash';
import SettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  components: {
    PendingSetup,
  },
  computed: {
    email() {
      const { properties } = this.node;
      return get(properties, 'email', {});
    },
    totalRecipient() {
      const { email: { otherRecipient=[], recipient=[], emailRecipient=[] } } = this;
      return otherRecipient.length + recipient.length + emailRecipient.length;
    },
    totalForwardRecipient() {
      const { email: { otherForwardRecipient=[], forwardRecipient=[], forwardEmailRecipient=[] } } = this;
      return otherForwardRecipient.length + forwardRecipient.length + forwardEmailRecipient.length;
    },
    isPending() {
      const { email: { content, htmlContent }, totalRecipient } = this;
      return totalRecipient > 0 && !content && !htmlContent;
    },
    parameters() {
      return this.$store.getters.getWorkflowParameters;
    },
    subject() {
      const { email } = this;
      return get(email, 'subject') || '';
    },
    propertiesFields() {
      return get(this.node, 'propertiesFields') || [];
    },
    regularFields() {
      return get(this.node, 'properties.fields') || [];
    },
    // propertiesParemeters() {
    //   return get(this.node, 'propertiesParemeters') || [];
    // },
    expressionDisplay() {
      const { propertiesFields=[], regularFields=[] } = this;
      const display = SettingUtils.normalizeFormulaExpression({
        fields: [...regularFields, ...propertiesFields, ...this.parameters],
        node: this.node,
        expression: this.subject,
        locale: this.$t('workflow')
      });
      return replace(display, /(?:\r\n|\r|\n)/g, '<br />');
    }
  },
};


</script>

<style scoped>
  
</style>
