<template>
  <div class="radio-button-toggle">
    <label v-if="label" l>{{ label }}</label>
    <div class="btn-group">
      <button
          type="button"
          :disabled="disabled"
          v-for="option in options"
          :key="option.value"
          :class="option.value===value?'primary':'muted'"
          class="mb-1 v-btn v-btn--contained theme--light v-size--default"
          style="width:80px; font-size:12px; height:30px"
          @click="onOptionSelected(option)">{{ option.caption }}</button>
      <!--<v-btn-->
          <!--:disabled="disabled"-->
          <!--v-for="option in options"-->
          <!--:key="option.value"-->
          <!--:class="option.value===value?'primary':''"-->
          <!--class="mb-1"-->
          <!--@click="onOptionSelected(option)">{{ option.caption }} {{ option.value===value ? 'yes' : 'no' }}</v-btn>-->
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    onOptionSelected (option) {
      this.$emit('input', option.value)
    }
  }
}
</script>

<style>
  .radio-button-toggle label {
    font-size: 0.875rem;
  }
</style>
