<template>
  <div>
    <!-- <FreeInput value="" fieldType="number" mathSupport placeholder="test" /> -->
    <div class="workflow-setting-form-each-item">
      <SourceTypeSelect
        :value="setting.sourceType"
      />
    </div>
    <div v-if="!!setting.sourceType">
      <v-layout align-center justify-start class="workflow-setting-form-waiting-set">
        {{ $t(`workflow.modules.operation.placeholder.type.${setting.sourceType}`) }}
      </v-layout>
      <component
        :is="settingComponent(setting.sourceType)"
        v-bind="setting"
      />
    </div>
  </div>
</template>

<script>
// import { get } from 'lodash';
import FormElement from './../shared/formElement';
import Mixin from './../mixin';
import SourceTypeSelect from './type';
import MathSetting from './math';
import DateCalculateSetting from './dateCalculate';
import StatisticSetting from './statistic';
import DateDiffSetting from './dateDiff';

import SettingUtils from '@/pages/admin/workflow/utils/setting';
// import FreeInput from './../shared/freeInput';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  name: 'OperationSetting',
  mixins: [Mixin],
  components: {
    FormElement,
    // FreeInput,
    SourceTypeSelect,
    DateCalculateSetting,
    MathSetting,
    StatisticSetting,
    DateDiffSetting,
  },
  methods: {
    settingComponent(sourceType) {
      const lookups = {
        math: 'MathSetting',
        dateCalculate: 'DateCalculateSetting',
        statistic: 'StatisticSetting',
        dateDiff: 'DateDiffSetting',
      }
      return lookups[sourceType];
    },
    validation() {
      let { setting } = this;
      const fields = SettingUtils.expressionContractFields(setting.fields, setting.expression);
      setting = { ...setting, fields };
      return setting;
    },
  },
  computed: {
    list() {
      return this.$store.getters.getFormSelectionsFields.children;
    },
  },
  watch: {
    sourceType: {
      handler(v, o) {
        if (v !== o) {
          this.$set(this.setting, 'expression', '');
          this.$delete(this.setting, 'source');
          this.$delete(this.setting, 'otherSource');
        }
      },  
      deep: true,
    },
  }
};

</script>

<style scoped>

</style>
