<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions>
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.total')}}
            </div>
        </v-expansion-panel-header>
        <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
            @change="$emit('onCommandHandler', {
                command: 'update_bl_displayTotal',
                value: $event
            })"
            v-model="bl_displayTotal"
        ></v-switch>
        <v-expansion-panel-content>
            <div>{{$t('chart.displayFields')}}</div>
            <v-select
                class="thin-select"
                :items="colOpts"
                item-text="label"
                return-object
                height="30"
                outlined
                @change="$emit('onCommandHandler',{
                    command: 'update_displayCol',
                    value: $event
                })"
                v-model="setup.displayCol"
                :menu-props="{offsetY: true, contentClass: 'denseList'}"
            >
            <!-- item-value="_id" -->
            <!-- v-model="displayCol" -->
            </v-select>
            <div v-if="setup.displayCol.type!='all'">{{$t('chart.rollupMethod')}}</div>
            <v-btn-toggle 
                v-if="setup.displayCol.type!='all'" 
                @change="$emit('onCommandHandler',{
                    command: 'update_totalRule',
                    value: $event
                })" 
                v-model="setup.totalRule" 
                mandatory 
                dense
            >
                <v-btn :value="method.label" small v-for="method in calMethods" :key="method.id">
                    {{$t('chart.styleConfig.' + method.label)}}
                </v-btn>
            </v-btn-toggle>
            <div :class="setup.displayCol.type!='all'?'mt-3':''">{{$t('chart.name')}}</div>
            <v-text-field 
                v-model="setup.totalTitle" 
                @input="$emit('onCommandHandler',{
                    command: 'update_totalTitle',
                    value: $event
                })" 
                outlined 
                height="30"
            ></v-text-field>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        props:{
            arrY: Array,
            bl_displayTotal: Boolean,
            setup: Object
            // totalRule: String,
            // totalTitle: String
        },
        data(){
            return{
                method: 1,
                calMethods:[
                    {
                        id: 1,
                        label: 'sum'
                    },
                    {
                        id: 2,
                        label: 'max'
                    },
                    {
                        id: 3,
                        label: 'min'
                    }, 
                    {
                        id: 4,
                        label: 'avg'
                    }
                ],
                value: 0,
                title: ''
            }
        },
        computed:{
            colOpts(){
                let allOpt = {
                    _id: 'all',
                    label: this.$t('chart.all'),
                    type: 'all'
                }
                var arrY = this.arrY.map(item=>{
                    var label = item.label
                    return {
                        ...item,
                        label
                    }
                })
                if(this.arrY.length <=1){
                    return [allOpt]
                }else{
                    return [allOpt].concat(arrY)
                }
            },
            relatedTableInfos(){
                return this.$store.getters.relatedTableInfos
            },
            fieldInfos(){
                return this.$store.getters.currentForm.fieldInfos
            }
        },
        methods:{
            getLabel(field){
                if(field.fieldId=='recordCount')
                    return 'Record Count'
                var res = this.fieldInfos.find(item=>item.fieldId==field._id)
                if(res){
                    if(res.type=='relatedRecord'){
                        var dataTableId = res.properties.dataSource
                        var relatedField = this.relatedTableInfos[dataTableId].fieldInfos.find(item=>item._id==field.relatedId)
                        if(relatedField)
                            return res.label + ' - ' + relatedField.label
                    }else{
                        if(res.type=='childTable'){
                            var childFieldInfos = res.properties.fieldsEditor.fieldInfos
                            var childField = childFieldInfos.find(item=>item._id==field.relatedId)
                            if(childField) return res.label + ' - ' + childField.label
                        }
                    }
                    return res.label   
                }
            }
        }
        // mounted(){
        //     this.displayCol = this.colOpts[0]._id
        // }
    }
</script>