
import {
    INTERACT_ACTION_BUTTOIN,
} from '@/store/modules/customizedAction/action_types';
const mixin = {
    computed: {
        processing() {
            return this.$store.getters.interacting;
        },
        currentApp() {
            return this.$store.getters.currentApp;
        },
        buttons() {
            return this.$store.getters.getActionButtons;
        },
        buttonOptions() {
            return this.$store.getters.getActionButtonOptions;
        },
        form() {
            return this.$store.getters.currentForm;
        },
    },
    methods: {
        onSubmit(data) {
            if (this.button.workflow){
                this.$sensors.track("plus_workflow_trigger", {
                  workflow_name:this.button.workflow.name,
                  workflow_type:"手動",
                  appId: this.currentApp._id,
                  formId: this.form._id,
                })
            }
            this.$store.dispatch(INTERACT_ACTION_BUTTOIN, {
              buttonId: this.button._id,
              appId: this.currentApp._id,
              formId: this.form._id,
              data,
            });
        }
    },
}

export default mixin
