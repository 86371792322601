<template>
  <v-dialog persistent scrollable v-model="showingDialog" width="540">
    <v-card height="180">
    <dialog-header
      color="error"
      :label="$t('roles.transferOwner')" @close="showingDialog = false" />
      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="pa-1 ma-0">
            <p style="font-size:18px;">
              {{ $t('roles.accessToAppDataIsSuspendedDuringOwnershipTransfer') }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <dialog-actions
        @onCommand="onCommandHandler"
        :buttons="['cancel', 'continue']"/>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import dialogActions from "@/components/dialogs/comps/DialogActions";

export default {
  name: 'dialogTransferOwnerWarning',
  props: {
    value: Boolean,
    form: Object
  },
  computed: {
    showingDialog: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  components: {
    dialogHeader,
    dialogActions,
  },
  methods: {
    onSubmit() {
      let vm = this
      this.$emit("submit", this.selectedEmployees)
      this.showingDialog = false;
    },

    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case "continue":
          vm.onSubmit();
          break;
        case "cancel":
          vm.showingDialog = false;
          break;
      }
    }
  }
}
</script>