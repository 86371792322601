import { app } from "@/main";
import ValidationsHelper from '@/helpers/ValidationsHelper'
import moment from "moment";
import {pick} from "lodash"

const isValidObjectId = function(val) {
  var checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
  return checkForHexRegExp.test(val);
};

/**
 * Format date object to YYYY-MM-DD format
 * @param {Date} val
 * @returns date in YYYY-MM-DD or empty string
 */
const formatDate = function(val) {
  if(ValidationsHelper.isValidDateTime(val)){
    let month = "" + (val.getMonth() + 1),
      day = "" + val.getDate(),
      year = val.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return ""
};

/**
 * Format date object to HH:mm format
 * @param {Date} val
 * @returns date in HH:mm
 */
const formatTime = function(val) {
  if(ValidationsHelper.isValidDateTime(val)){
    let hour = "" + val.getHours(),
      min = "" + val.getMinutes();
    if (hour.length < 2) hour = "0" + hour;
    if (min.length < 2) min = "0" + min;
    return hour + ":" + min;
  }
  return ""
};

/**
 * Format date object to YYYY-MM-DD HH:mm format
 * @param {Date} val
 * @returns date in YYYY-MM-DD HH:mm
 */
const formatDateTime = function(val) {
  let date = formatDate(val),
    time = formatTime(val);
  return [date, time].join(" ");
};

//TODO: combine with getObjectValue function
const getDisplayValue = function(rawDatum, fieldInfo, Vue) {
  let result = "";
  if (rawDatum) {
    switch (fieldInfo.type) {
      case "attachments":
        result = rawDatum.map((media) => media.originalName).join(", ");
        break;
      case "relatedRecord":
        {
          if (
            Array.isArray(rawDatum) &&
            fieldInfo.properties.relatedRecordQuantity === "multiple"
          ) {
            result = "";
            const { dataSource } = fieldInfo.properties;
            const formId = dataSource && dataSource;
            const relatedForm = Vue.$store.getters.relatedTableInfos[formId];
            if (relatedForm) {
              const { titleFieldInfoId } = relatedForm;
              const titleFieldInfo = relatedForm.fieldInfos.find(
                (info) => info.fieldId === titleFieldInfoId
              );
              if (titleFieldInfo) {
                const titleFields = rawDatum
                  .map((record) => {
                    return getDisplayValue(
                      record[titleFieldInfoId],
                      titleFieldInfo,
                      Vue
                    );
                  })
                  .filter((val) => val);
                result = titleFields.join(",");
              }
            }
          } else {
            result = rawDatum;
          }
        }

        break;
      case "amount":
      case "number":
      case "expression":
      case "summaryField":
        {
          result = rawDatum;
          let { prefix, suffix, decimals } = fieldInfo && fieldInfo.properties;
          decimals = decimals ? parseInt(decimals) : 0;
          if (typeof result === "number") {
            result = formatNumber(result, decimals);
          }

          result = (prefix || "").concat(result).concat(suffix || "");
        }
        break;
      default:
        {
          const prefix =
            fieldInfo && fieldInfo.properties.prefix
              ? `${fieldInfo.properties.prefix} `
              : "";
          const suffix =
            fieldInfo && fieldInfo.properties.suffix
              ? ` ${fieldInfo.properties.suffix}`
              : "";
          result = prefix.concat(rawDatum).concat(suffix);
        }
        break;
    }
  }

  return result;
};

const fetchRelatedDataFieldsInRows = (
  vm,
  rows,
  extra = null,
  isPublic = false,
  fieldInfos,
  effectiveFieldIds
) => {
  return new Promise((resolve, reject) => {
  // get relation fields
  const effectiveRelationFieldInfos = fieldInfos.filter((info) => {
    const {relatedRecordQuantity, displayFormat} = pick(info.properties, ["relatedRecordQuantity", "displayFormat"]);
    return (
      effectiveFieldIds.includes(info.fieldId) && (relatedRecordQuantity === "single" || displayFormat === "card")
    );
  });
  var reqParams = [];
  for (let i = 0; i < effectiveRelationFieldInfos.length; i++) {
    const loopInfo = effectiveRelationFieldInfos[i];
    const fieldId = loopInfo.fieldId;
    // console.log("i=" + i + ": fieldId = " + fieldId);
    let ids = [];

    const otherTableFieldInfos = fieldInfos.filter((info) => {
      return (
        info.type === "otherTableField" &&
        info.properties.fieldForRelatedTable === loopInfo._id
      );
    });
    const requiredFieldIds = otherTableFieldInfos.map(
      (info) => info.properties.relatedTableField
    );

    // Append extra record ids required
    for (let j = 0; j < rows.length; j++) {
      const loopRow = rows[j];
      // console.log("j=" + j + ": loopRow: ", loopRow);
      if (loopRow[fieldId]) ids = ids.concat(loopRow[fieldId]);
      ids = ids.map((item) => {
        return typeof item === "object" ? item._id : item;
      });
      if (extra && extra.fieldId === fieldId) {
        ids = ids.concat(extra.relatedRecordIds);
      }
    }
    //console.log("ids: ", ids.join(", "));
    if (ids.length > 0) {
      reqParams.push({
        dataSource: loopInfo.properties.dataSource,
        ids: ids.filter((id, index) => id && ids.indexOf(id) === index),
        fieldIds: requiredFieldIds,
      });
    }
  }
  if (reqParams.length > 0) {
    vm.$store
      .dispatch("FETCH_DATA_FIELDS", { reqParams: reqParams, isPublic })
      .then((response) => {
        resolve(response)
      });
  }else{
    resolve()
  }
  })

};

const formatNumber = (val, decimals = 0) => {
  if (typeof val !== "number" && !val) return "";
  else {
    let result = "";
    result = parseFloat(val);

    result =
      Math.round((result + Number.EPSILON) * Math.pow(10, decimals)) /
      Math.pow(10, decimals);

    result = result.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });

    return result;
  }
};

const getRelativeTime = (time) => {
  const millisecond = new Date() - new Date(time);
  if (millisecond < 60000) {
    //less than 1 min
    const second = millisecond / 1000;
    return parseInt(second) + " " + app.$i18n.t("drawer.timeUnit.secondBefore");
  } else if (millisecond > 60000 && millisecond < 60000 * 60) {
    //less than 1 hr
    const minute = millisecond / 60000;
    return parseInt(minute) + " " + app.$i18n.t("drawer.timeUnit.minuteBefore");
  } else {
    if(app.$i18n.locale=='en')
      return new Date(time).toLocaleString('en')
    else
      return new Date(time).toLocaleString('cn')
  }
};

const RuleSet = {
  empty: (v) => ValidationsHelper.isEmpty(v),
  notEmpty: (v) => !ValidationsHelper.isEmpty(v),
  includes: (v, regex) => new RegExp(regex, "i").test(v),
  notInclude: (v, regex) => !new RegExp(regex, "i").test(v),
  is: (v, compareString) => v === compareString,
  not: (v, compareString) => v !== compareString,
  beginsWith: (v, compareString) =>
    new RegExp(`^${compareString}`, "i").test(v),
  endsWith: (v, compareString) => new RegExp(`${compareString}$`, "i").test(v),
  toggle: (v, compareString) => (compareString === "yes" ? Boolean(v) : !v),
  hasCount: (v, count) => Array.isArray(v) && v.length === parseInt(count),
  atLeast: (v, count) => Array.isArray(v) && v.length >= parseInt(count),
  atMost: (v, count) => Array.isArray(v) && v.length <= parseInt(count),
  isTheDate: (v, filterValue) => {
    const dateInfoFromV = extractDateInfo(v);
    const dateInfoFromFilter = extractDateInfo(filterValue);
    return Object.entries(dateInfoFromFilter).every(
      ([key, value]) => dateInfoFromV[key] === value
    );
  },
  notTheDate: (v, filterValue) => {
    const dateInfoFromV = extractDateInfo(v);
    const dateInfoFromFilter = extractDateInfo(filterValue);
    return Object.entries(dateInfoFromFilter).some(
      ([key, value]) => dateInfoFromV[key] !== value
    );
  },
  withinPeriod: (v, start, end) => {
    const startDate = new Date(`${start} 00:00:00`);
    const endDate = new Date(`${end} 23:59:59`);
    const date = new Date(v);
    if (
      !ValidationsHelper.isValidDateTime(startDate) ||
      !ValidationsHelper.isValidDateTime(endDate) ||
      !ValidationsHelper.isValidDateTime(date)
    ) {
      return false;
    } else {
      return date >= startDate && date <= endDate
    }
  },
  notWithinPeriod: (v, start, end) => {
    const startDate = new Date(`${start} 00:00:00`);
    const endDate = new Date(`${end} 23:59:59`);
    const date = new Date(v);
    if (
      !ValidationsHelper.isValidDateTime(startDate) ||
      !ValidationsHelper.isValidDateTime(endDate) ||
      !ValidationsHelper.isValidDateTime(date)
    ) {
      return false;
    } else {
      return date < startDate || date > endDate
    }
  },
  today: (v) => {
    return v && moment().isSame(v, "day");
  },
  currentMonth: (v) => {
    return v && moment().isSame(v, "month");
  },
  earlierThan: (v, end) => {
    return v && moment(v).isBefore(end)
  },
  laterThan: (v, start) => {
    return v && moment(v).isAfter(start);
  },
  inRange: (v, min, max) =>
    !isNaN(v) && ValidationsHelper.isInNumberRange(v, min, max),
  notInRange: (v, min, max) =>
    !isNaN(v) && !ValidationsHelper.isInNumberRange(v, min, max),
  equals: (v, compareValue) =>
    (typeof v === "string" ? parseFloat(v) : v) === parseFloat(compareValue),
  notEqual: (v, compareValue) =>
    (typeof v === "string" ? parseFloat(v) : v) !== parseFloat(compareValue),
  greaterThan: (v, compareValue) =>
    (typeof v === "string" ? parseFloat(v) : v) > parseFloat(compareValue),
  smallerThan: (v, compareValue) =>
    (typeof v === "string" ? parseFloat(v) : v) < parseFloat(compareValue),
  greaterOrEqual: (v, compareValue) =>
    (typeof v === "string" ? parseFloat(v) : v) >= parseFloat(compareValue),
  smallerOrEqual: (v, compareValue) =>
    (typeof v === "string" ? parseFloat(v) : v) <= parseFloat(compareValue),
  isTheItem: (v, compareString) => v === compareString,
  notTheItem: (v, compareString) => v !== compareString,
  includeAnItem: (v, compareString) => {
    const options = (compareString || "").split("|");
    return Array.isArray(v) && v.some((val) => options.includes(val));
  },
  notIncludeTheItems: (v, compareString) => {
    const options = (compareString || "").split("|");
    return Array.isArray(v) && !v.some((val) => options.includes(val));
  },
  isTheDepartment: (v, filterValue) => {
    const depts = Array.isArray(filterValue)
      ? filterValue.map((dept) => dept._id)
      : filterValue.split("||").map((str) => str.split("|")[0]);
    return (
      (Array.isArray(v) && v.some((val) => depts.includes(val))) ||
      depts.includes(v)
    );
  },
  notTheDepartment: (v, filterValue) => {
    const depts = Array.isArray(filterValue)
      ? filterValue.map((dept) => dept._id)
      : filterValue.split("||").map((str) => str.split("|")[0]);
    return (
      (Array.isArray(v) && !v.some((val) => depts.includes(val))) ||
      !depts.includes(v)
    );
  },
  isTheMember: (v, filterValue) => {
    const members = Array.isArray(filterValue)
      ? filterValue.map((member) => member._id)
      : filterValue.split("||").map((str) => str.split("|")[0]);
    return (
      (Array.isArray(v) && v.some((val) => members.includes(val))) ||
      members.includes(v)
    );
  },
  notTheMember: (v, filterValue) => {
    const members = Array.isArray(filterValue)
      ? filterValue.map((member) => member._id)
      : filterValue.split("||").map((str) => str.split("|")[0]);
    return (
      (Array.isArray(v) && !v.some((val) => members.includes(val))) ||
      !members.includes(v)
    );
  },
  isTheRegion: (v, filterValue) => {
    const regionFromV = extractRegion(v)
    const regionFromFilter = extractRegion(filterValue)
    return Object.entries(regionFromFilter).every(
      ([key, value]) => regionFromV[key] === value
    );
  },
  notTheRegion: (v, filterValue) => {
    const regionFromV = extractRegion(v)
    const regionFromFilter = extractRegion(filterValue)
    return Object.entries(regionFromFilter).some(
      ([key, value]) => regionFromV[key] !== value
    );
  } ,
  isTheRating: (v, compareValue) =>
    (typeof v === "string" ? parseInt(v) : v) === parseInt(compareValue),
  notTheRating: (v, compareValue) =>
    (typeof v === "string" ? parseInt(v) : v) !== parseInt(compareValue),
  isRatingTheLeast: (v, compareValue) =>
    (typeof v === "string" ? parseInt(v) : v) >= parseInt(compareValue),
  isRatingTheMost: (v, compareValue) =>
    (typeof v === "string" ? parseInt(v) : v) <= parseInt(compareValue),
  isTheRelation: (v, filterValue) => {
    const ids = Array.isArray(filterValue) && filterValue.map(item=>item._id) || [];
    return  (Array.isArray(v) && v.some((val) => ids.includes(val)))
  },
  notTheRelation: (v, filterValue) => {
    const ids = Array.isArray(filterValue) && filterValue.map(item=>item._id) || [];
    return  (Array.isArray(v) && !v.some((val) => ids.includes(val)))
  },
};

function extractDateInfo(str){
  if (!str) return {};
  const DATE_REGEX = /^(?<year>[12]\d{3})-(?<month>0[1-9]|1[0-2])-(?<date>0[1-9]|[12]\d|3[01])/;
  return DATE_REGEX.exec(str).groups
}

function extractRegion(val) {
  const result = {
    city: "",
    province: "",
    state: "",
  };
  val
    ? Object.assign(result, {
        city: val["city"],
        province: val["province"],
        state: val["state"],
      })
    : null;
  return result;
}

export {
  isValidObjectId,
  formatDate,
  formatTime,
  formatDateTime,
  getDisplayValue,
  fetchRelatedDataFieldsInRows,
  formatNumber,
  getRelativeTime,
  RuleSet
};
