import Vue from 'vue'
import * as types from './action_types'

const state = {
}
const getters = {
}

const mutations = {
}

const actions = {
    [types.TEST_SENDER_CONFIG] ({ rootGetters, getters, commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            const postData = {
                urlCommand: '/mail/test',
                data: {
                    form: payload.form,
                    configId: payload.configId,
                },
                options: {
                    timeout: 10000, // 10s
                },
            }
            dispatch('AUTH_POST', postData).then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
        })
    },
    [types.SAVE_SENDER_CONFIG] ({rootGetters, getters, commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            console.log('SAVE_SENDER_CONFIG::payload', payload)  
            const postData = {
                urlCommand: '/mail/save',
                data: {
                    configId: payload.configId,
                    form: payload.form,
                    teamId: payload.teamId,
                    visible: payload.visible
                }
            }
            dispatch('AUTH_POST', postData).then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
        })
    },
    [types.DELETE_SENDER_CONFIG] ({rootGetters, getters, commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            const postData = {
                urlCommand: '/mail/delete',
                data: {
                    configId: payload.configId
                }
            }
            dispatch('AUTH_POST', postData).then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
        })
    }
}


export default {
  state,
  getters,
  mutations,
  actions
}
