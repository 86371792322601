<template>
  <v-navigation-drawer app v-model="drawer" right temporary class="app-drawer">
    <v-list class="flex-grow-1">
      <component
        v-for="(item, index) in menu"
        :key="index"
        :is="item.component"
        :class="item.component === 'VListItem' ? `text-header-2` : ``"
         @click="item.component === 'VListItem' ? goTo(item):null"
      >
        <template v-if="item.component === 'VListItem'">
          <v-list-item-avatar>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-else-if="item.component === 'VSubheader'">{{
          $t(item.title)
        }}</template>
      </component>
    </v-list>
    <app-footer></app-footer>
  </v-navigation-drawer>
</template>

<script>
import appFooter from "@/components/AppFooter";
import { VListItem, VSubheader, VDivider } from "vuetify/lib";
import mixin from "./mixin"

export default {
  name: "appDrawer",
  components: {
    appFooter,
    VListItem,
    VSubheader,
    VDivider,
  },
  mixins:[mixin],
  data() {
    return {
      drawer: null,
    };
  },

  methods: {
    show() {
      const vm = this;
      vm.drawer = true;
    },
    hideDrawer() {
      const vm = this;
      vm.drawer = false;
    },
  },
};
</script>
<style lang="scss">
.app-drawer {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
