<template>
  <div
    class="v-card-app d-inline-block ma-1 text-center py-1"
    @mouseenter="hover = true && bounce && !dragging"
    @mouseleave="hover = false"
  >
    <div class="v-cart-app-duplicating" v-if="isDuplicating">
      <font-awesome-icon icon="spinner" class="fa-3x fa-spin"></font-awesome-icon>
    </div>
    <apps-logo :autoBounce="autoBounce"
     :hover="hover||autoBounce" :appInfo="appInfo"></apps-logo>

    <!-- Mask -->
    <div v-if="busyMessage" class="busy-message">
      <div class="p-1">
        {{ busyMessage }}...
      </div>
    </div>
    <div v-else class="mask" @click="openApps()"></div>    

    <!-- top button: favourite & right menu -->
    <div class="action-row">
      <!-- top left button -->
      <v-btn
        v-if="(isFavApp || hover) && withMenu"
        icon
        plain
        @click.stop="toggle()"
        class="far-icon top-left-icon fixed-icon"
      >
        <v-icon
          :class="{
            'favourite-star': isFavApp||isArchivedApp,
            'grey--text lighten-4': !isFavApp&&!isArchivedApp,
          }"
        >
          {{ isArchivedApp ? 'mdi-pin' : (isFavApp ? 'mdi-star' : 'mdi-star-outline') }}

          <!--<v-icon :class="{'favourite-star':appInfo.favourite, 'grey&#45;&#45;text lighten-4':!appInfo.favourite}">-->
          <!--{{ appInfo.favourite ? 'mdi-star': 'mdi-star-outline' }}-->
        </v-icon>
      </v-btn>

      <!-- top right menu -->
      <!--withMenu: {{ withMenu }}<br/>-->
      <!--hover: {{ hover ? 'yes' : 'no'}}-->
      <v-menu v-model="showingMenu" v-if="contextMenu.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-show="withMenu && hover && isAppAdmin"
            class="top-right-icon fixed-icon"
            v-bind="attrs"
            v-on="on"
          >
            <font-awesome-icon icon="ellipsis-h"></font-awesome-icon>
          </v-btn>
        </template>
        <v-list dense class="denseList">
          <v-list-item
            @click="onCommandHandler({ command: menu.command })"
            v-for="(menu, index) in contextMenu"
            :key="index"
          >
            <v-list-item-icon class="align-center mr-1 grey--text">
              <v-icon left>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(menu.captionTag) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- App Title -->
    <div v-if="!hideTitle"
      style="position:absolute;bottom:0;left:0;width:100%;height:40px;line-height:1;"
    >
      <div
        v-if="appInfo && appInfo.title !== ''"
        @click="openApps()"
        class="d-flex flex-column justify-center title-row text-center fill-height"
      >
        <small class="nowrap-ellipsis">&nbsp;{{ appTitle }}&nbsp;</small>

<!-- <br/>
<small>{{ appInfo._id }}</small> -->

        <!--{{appInfo._id}}-->
        <v-chip
          v-if="showTeamName && teamName!==''"
          class="darken-3 w-100 team-name-wrapper grey white--text"
          x-small
          ><div class="w-100 team-name text-center">{{ teamName }}</div></v-chip
        >
      </div>
    </div>
  </div>
</template>

<script>
import appsLogo from "./AppsLogo";
import AppMenu from "@/menus/AppMenu";

export default {
  name: "vCardApp",
  components: { appsLogo },
  data() {
    return {
      hover: false,
      //contextMenu: AppMenu,
      menu: false,
      showingMenu: false,
    };
  },
  watch: {
    dragging: function(newVal) {
      this.showingMenu = false;
    },
  },
  computed: {
    busyMessage () {
      var result = this.appInfo.status && this.appInfo.status.includes('transferring') 
        ? this.$t('app.transferring') : null;
      if (!result && this.appInfo.locked) {
        result = this.$t('app.stopped');
      }
      return result;
    },
    isArchivedApp () {
      return this.appInfo.type === 'archivedApp'
    },
    isAppAdmin () {
      return this.appInfo.permission=100
    },
    teamName() {
      const vm = this;
      var result = "";
      if (this.appInfo.teamName) result = this.appInfo.teamName;
      else if (vm.appInfo.teamId && vm.appInfo.teamId !== "") {
        if (!vm.isActionButton) {
          const team = vm.teams.find((t) => t._id === vm.appInfo.teamId);
          if (team.name !== "") {
            result = team.name;
          }
        }
      }
      return result;
    },
    isDuplicating() {
      return this.$store.getters.duplicatingAppMapping[this.appInfo._id];
    },
    teams() {
      return this.$store.getters.allTeams;
    },
    isActionButton() {
      return !this.withMenu;
    },
    appTitle() {
      const vm = this;
      var result = "";
      if (vm.appInfo.title) {
        result = vm.appInfo.title;
      } else {
        result = "(" + vm.$t("general.noName") + ")";
      }
      return result;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    currTeamId() {
      const vm = this;
      var result = "";
      if (vm.$store.getters.user) {
        result = vm.$store.getters.user.lastTeam;
      }
      return result;
    },

    user () {
      return this.$store.getters.user
    },
    
    contextMenu(){
      // console.log('contextMenu :: AppMenu: ', JSON.stringify(AppMenu))
      const vm = this
      let result = []
      if (AppMenu && vm.appInfo) {
        for (let i = 0; i < AppMenu.length; i++) {
          const item = AppMenu[i]
          // console.log('i=' + i + ': item: ' + JSON.stringify(item))
        if ((item.permission <= vm.appInfo.permission) ||
          (
              Object.values(vm.user && vm.user.teamEmployeeIds || []).includes(vm.appInfo.ownerId) && 
              item.permission===1000 && 
              vm.$store.getters.user.canPublish
          )) {
          if(item.command === 'sendApp'){
            if(this.activeTeam && this.activeTeam.allowDeployment)
              result.push(item)
          }else if(item.command==='delete'){
            if(Object.values(vm.user && vm.user.teamEmployeeIds || []).includes(vm.appInfo.ownerId))
              result.push(item)
          }else{
            result.push(item)
          }
        }
        }
      }
      return result
    },
    activeTeam () {
      return this.$store.getters.activeTeam
    }
  },
  methods: {
    isDisabled(item) {
      switch (item.command) {
        case 'duplicate': 
          item.disabled = !!this.isDuplicating;
          break;
        default:
          // ignore
      }
    },
    onMouseLeave(arg) {
      console.log("onMouseLeave, arg: ", arg);
    },
    showMenu() {
      return "showMenu";
    },
    toggle() {
      const vm = this;
      vm.$emit("click:toggle", { 
        appId: vm.appInfo._id,
        isArchivedApp: vm.isArchivedApp,
        isFavApp: vm.isFavApp });
      // console.log('VCardApp :: toggleFavourite')
      // vm.$emit('onCommand', {
      //   command: 'toggleFavourite',
      //   appId: vm.appInfo._id
      // })
      // vm.$store.dispatch('UPDATE_APP_PROPERTY', {
      //   id: vm.appInfo._id,
      //   propertyKey: 'favourite',
      //   propertyValue: !vm.appInfo.favourite
      // })
      // vm.$emit('onCommand', {
      //   command: 'toggleFavourite',
      //   appInfo: vm.appInfo
      // })
    },
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "showInfo":
          vm.$emit("click:menu", {
            command: "showAppsInfoDialog",
            appInfo: vm.appInfo,
          });
          // vm.showAppsInfoDialog()
          break;
        case "duplicate":
          vm.$emit("click:menu", {
            command: "duplicateApp",
            appInfo: vm.appInfo,
          });
          // vm.duplicateApp(payload)
          break;
        case "delete":
          vm.$emit("click:menu", { command: "deleteApp", appInfo: vm.appInfo });
          vm.deleteApp();
          break;

        case 'publish':
          vm.$router.push('/publish_app/' + vm.appInfo._id)
          break

        case "edit":
          vm.editApp();
          break;
        // case 'openApps':
        //   vm.openApps()
        //   break
        case "pulish":
          vm.publishApp();
          break;
        case 'sendApp':
          vm.$emit("click:menu", {
            command: "showSendAppDialog",
            appInfo: vm.appInfo,
          });
          break
        default:
          vm.$emit("onCommand", payload);
      }
    },

    // duplicateApp (payload) {
    //   const vm = this
    //   alert('Not Implemented Yet!')
    //   return
    //   vm.$store.dispatch('DUPLICATE_APP', {appId: payload.appInfo._id})
    // },

    // showAppsInfoDialog () {
    //   const vm = this
    //   vm.$emit('onCommand', {
    //     command: 'showAppsInfoDialog',
    //     appInfo: vm.appInfo
    //   })
    // },

    editApp() {
      const vm = this;
      vm.$emit("onCommand", {
        command: "editApp",
        appInfo: vm.appInfo,
      });
    },

    duplicateApps() {
      const vm = this;
      vm.$emit("onCommand", {
        command: "duplicateApps",
        appInfo: vm.appInfo,
      });
    },

    deleteApp() {
      const vm = this;
      vm.$emit("onCommand", {
        command: "deleteApp",
        appInfo: vm.appInfo,
      });
    },

    openApps() {
      const vm = this;
      vm.$emit("click", { command: "openOrNewApp", appInfo: vm.appInfo });
      // if (vm.appInfo._id === '') {
      //   vm.$emit('onCommand', {
      //     command: 'addNewApps'
      //   })
      // } else {
      //   vm.$emit('onCommand', {
      //     command: 'openApp',
      //     appInfo: vm.appInfo
      //   })
      // }
    },
  },
  props: {
    appInfo: {
      type: Object,
      default: null,
    },
    bounce: {
      type: Boolean,
      default: true,
    },
    autoBounce: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "operation",
    },
    withMenu: {
      type: Boolean,
      default: true,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    isFavApp: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    showTeamName: {
      type: Boolean,
      default: false
    }
    // themeType: {
    //   type: String,
    //   default: 'dark'
    // },
    // color: {
    //   type: String,
    //   default: 'blue lighten-1'
    // },
    // icon: {
    //   type: String,
    //   default: ''
    // },
    // title: {
    //   type: String,
    //   default: ''
    // }
  },
};
</script>

<style>
.v-card-app {
  cursor: pointer;
  height: 156px;
  width: 120px;
  position: relative;
  /*border:3px solid white;*/
  /*border-radius: 0.5rem;*/
}

.ghost-class .title-row,
.ghost-class .app-logo,
.ghost-class .action-row {
  display: none !important;
}

.ghost-class {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.drag-class {
  background-color: white;
}

.action-row {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}

.v-cart-app-duplicating {
  position: absolute;
  top: 6px;
  right: 0px;
  z-index: 99;
  font-size: 8px;
  width: 36px;
  height: 36px;
}

/*.v-card-app:hover {*/
/*background-color: lightgray;*/
/*}*/

/*.v-card-app .action-row {*/
/*display: none;*/
/*}*/

/*.v-card-app .action-row.is-fav-app {*/
/*display: block;*/
/*}*/

.v-card-app:hover .action-row {
  display: block;
}

.v-card-app:hover .mask {
  display: block;
  z-index: 400;
}

.favourite-star {
  color: #ffdb1a !important;
  text-shadow: 1px 1px black;
}

.v-card-app .fixed-icon {
  background-color: transparent !important;
}

.v-card-app .busy-message {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.v-card-app .busy-message div {
  background-color: rgba(100,100,100,.6);
  border: 1px solid rgba(100,100,100,.6);
  border-radius: 0.5rem;
  color: white;
}

.v-card-app .mask {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  /*border:3px solid rgba(0, 0, 0, .05);*/
  /*border-radius: 0.5rem;*/
}

.fixed-icon {
  position: absolute;
  font-size: 80%;
}

.top-left-icon {
  left: 0px;
  top: 0px;
}

.top-right-icon {
  right: 0px;
  top: 0px;
}

.v-card-app .team-name-wrapper {
  padding: 0 6px;
}
.v-card-app .team-name-wrapper .v-chip__content .team-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x:hidden;
}
.v-card-app .team-name-wrapper .v-chip__content {
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  text-overflow:ellipsis;
}
</style>
