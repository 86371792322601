<template>
  <div>
    <CustomizedActionSettingList :form="form" :view="view"/>
  </div>
</template>

<script>
import EventBus from '@/event-bus.js'
import CustomizedActionSettingList from "@/pages/admin/workflow/manualActions/list/";
import mixin from './mixin'
// import {
//   FETCH_ACTION_BUTTON,
// } from '@/store/modules/customizedAction/action_types';
export default {
  mixins: [mixin],
  props:{
    view:  Object
  },
  components: {
    CustomizedActionSettingList,
  },
  computed: {
    form () {
      return this.$store.getters.currentForm;
    },
  },
  created(){
    let vm = this
    EventBus.$on('removeActionButton', (payload) => {
      vm.$emit('onCommand', {
        command: 'updateViewSetting',
        settingName: 'customizedActionButtons',
        val: payload
      })
    })
    EventBus.$on('appendActionButton', (payload) => {
      vm.$emit('onCommand', {
        command: 'updateViewSetting',
        settingName: 'customizedActionButtons',
        val: payload
      })
    })
    EventBus.$on('updateViewActionButton', (payload) => {
      vm.$emit('onCommand', {
        command: 'updateViewSetting',
        settingName: 'customizedActionButtons',
        val: payload
      })
    })
  },
  beforeDestroy () {
    EventBus.$off('removeActionButton')
    EventBus.$off('appendActionButton')
    EventBus.$off('updateViewActionButton')
  }
  // watch: {
  //   tabIndex: {
  //     immediate: true, 
  //     handler (val) {
  //       if (val === 4) {
  //         this.$store.dispatch(FETCH_ACTION_BUTTON, {
  //           appId: this.currentApp._id,
  //           formId: this.form._id,
  //           withHide: true,
  //         });
  //       }
  //     }
  //   }
  // }
}
</script>


<style scoped>
</style>
