<template>
  <div>
    <div v-for="c in colors" :key="c" 
      :style="{ background: c }"
      @click="changed(c)"
      class="w-customized-button-bubble">
      <v-icon v-if="color === c" color="white">
        mdi-check
      </v-icon>
    </div>
  </div>
</template>

<script>
import {
  WorkflowButtonColorCode,
} from '@/pages/admin/workflow/constants/form';
import {
  UPDATE_ACTION_BUTTON_INFO,
} from '@/store/modules/customizedAction/action_types';
export default {
  props: {
    // item: {
    //   type: Object,
    //   default: {},
    // },
    color: String,
  }, 
  computed: {
    colors() {
      return WorkflowButtonColorCode;
    },
  },
  methods: {
    changed(content) {
      this.$store.dispatch(UPDATE_ACTION_BUTTON_INFO, {
        field: 'color',
        content,
      });
    }
  },
}
</script>

<style scoped>
  .w-customized-button-bubble {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 14px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    vertical-align: top;
    transition: all .4s ease;
  }
  .w-customized-button-bubble:hover {
    transform: scale(1.22);
  }
</style>