<template>
  <WorkflowConsumer class="pt-10">
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
      height="3"
    ></v-progress-linear>
    <TableFilterProvider>
      <v-container class="wrapper w-list-table-root">
        
        <div>
          <TableFilterConsumer>
            <TableFilter :list="filterOptions" />
            <HistoriesList :headers="heading" :list="records" />
          </TableFilterConsumer>
        </div>
      </v-container>
    </TableFilterProvider>
  </WorkflowConsumer>
</template>

<script>
import TableFilterProvider from "./../../components/tree/table/filter/contexts/provider";
import TableFilterConsumer from "./../../components/tree/table/filter/contexts/consumer";
import WorkflowConsumer from './../contexts/workflowConsumer';
import HistoriesList from './../../components/tree/table/historyTable/';

import TableFilter from "./../../components/tree/table/filter";
import { map } from 'lodash';
import {
  FETCH_WORKFLOW_INFO,
  FETCH_WORKFLOW_TREE,
  FETCH_WORKFLOW_PARAMETER
} from '@/store/modules/workflow/action_types';
// import {
//   REVIEW_ORCHESTRATION,
// } from '@/store/modules/orchestration/action_types';
export default {
  inject: ['info'],
  data() {
    const lookup = ["name", "status", "message", "startTime", "interval"];
    const filterLookups = [
      "all",
      "running", 
      "complete",
      "fail",
      "waiting",
    ];
    return {
      heading: map(lookup, field => ({
        label: this.$t(`workflow.table.${field}`),
        field,
      })),
      filterOptions: map(filterLookups, field => ({
        label: this.$t(`workflow.status.step.${field}`),
        value: field,
      })),
    };
  },
  components: {
    WorkflowConsumer,
    HistoriesList,
    TableFilter,
    TableFilterProvider,
    TableFilterConsumer,
  },
  mounted() {
    const { workflowId } = this.$route.params;
    if (workflowId) {
      this.$store.dispatch(FETCH_WORKFLOW_INFO, workflowId);
      this.$store.dispatch(FETCH_WORKFLOW_TREE, workflowId);
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loadingEventHistories;
      // || this.$store.getters.loadingEventHistory;
    },
    records () {
      return this.$store.getters.getEventHistoriesList;
    },
  },
};
</script>

<style>
  
</style>
