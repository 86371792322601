<template>
<div class="d-flex flex-column justify-start parent-column data-block-column"
        :class="hasChildClass">
    <data-block class="flex-grow-0" 
            ref="dataBlock"
                :item="item"
                :parent="parent"
                :dragging="dragging"
                :levelType="levelType"
                :parentChildInfos="parentChildInfos"
                :formInfoDict="formInfoDict"
                @toggle="state=>toggleHandler(state)"
                @click:newNode="addNodeHandler"
                @click:selectNode="selectNodeHandler"
                @click:deleteNode="deleteNodeHandler"
                @newItem="newItemHandler"
                @unlinkItem="unlinkItemHandler"
                @openRecord="openRecord"
                @checkSubmit="checkSubmitHandler"
                :class="hasChildClass"></data-block>
    <empty-block class="flex-grow-1 empty-space"
                    :class="hasChildClass"></empty-block>
</div>    
</template>

<script>
import dataBlock from './DataBlock'
import emptyBlock from './EmptyBlock'

export default {
    name: 'dataBlockColumn',
    components: {
        dataBlock,
        emptyBlock
    },
    props: {
        dragging: Boolean,
        item: Object,
        parent: Object,
        parentChildInfos: {
            type: Array,
            default () {
                return []
            }
        },
        formInfoDict: Object,
        levelType: String,
        level: Number
    },
    computed: {
        hasChildClass () {
            console.log("hasChildClass::item", this.item)
            const vm = this
            var result = []
            // console.log('DataBlockColumn :: hasChildClass : vm.parentChildInfos: ', vm.parentChildInfos)
            if (vm.parentChildInfos.length > 0 && vm.item.children) {
                const fieldId = vm.parentChildInfos[0].fieldContainsChildRecordIds
                if (vm.item[fieldId] && Array.isArray(vm.item[fieldId]) && vm.item[fieldId].length > 0) {
                    result.push('has-child')
                }
            }
            return result.join(' ')
        }
    },
    methods: {
        openRecord (payload) {
            this.$emit('openRecord', payload)
        },
        unlinkItemHandler (payload) {
            // console.log('DataBlockColumn :: unlinkItemHandler: payload: ' + JSON.stringify(payload))
            this.$emit('unlinkItem', payload)
        },
        checkSubmitHandler (payload) {
            // console.log('DataBlockColumn :: checkSubmitHandler :: payload: ', payload)
            this.$emit('checkSubmit',payload)
        },
        newItemHandler (item) {
            this.$emit('onNewInput', payload)
        },
        setFocus (refName) {
            const vm = this
            // console.log('DataBlockColumn :: setFocus :: refName = ' + refName)
            // console.log('DataBlockColumn :: setFocus :: item._id = ' + vm.item._id)
            return this.$refs['dataBlock'].setFocus(refName)
        },
        toggleHandler (state) {
            this.$emit('toggle', state)
        },
        addNodeHandler (payload) {
            // console.log('DataBlockColumn :: addNodeHandler :: payload: ', payload)
            this.$emit('click:newNode', payload)
        },
        selectNodeHandler (payload) {
            this.$emit('click:selectNode', payload)
        },
        deleteNodeHandler () {
            // console.log('DataBlockColumn :: deleteNodeHandler')
            this.$emit('click:deleteNode')
        },
        onCommandHandler (payload) {
            console.log('DataBlockColumn :: onCommandHandler')
            this.$emit('onCommand', payload)
        }
    }
}
</script>

<style>
/* .data-block-column:hover {
     background-color: lightred;
 }*/

</style>