<template>
  <v-row no-gutters class="bottom-border" align="center" justify="center">
    <v-col cols="10" class="pa-0 ma-0 right-border">
      <!--form-->
      <v-row no-gutters align="center" justify="center">
        <v-col cols="6">
          <v-row no-gutters>
            {{ calendar.label || $t('roles.noName')}}
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            dense
            hide-details
            v-model="readCalendar"
          />
        </v-col>
        <v-col cols="2">
          <!-- <v-checkbox
            dense
            hide-details
            v-model="editCalendar"
          /> -->
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-col>
    <v-col cols="2">
      <v-menu
        v-model="menu"
        v-if="false"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn small text v-bind="attrs" v-on="on">{{
            $t("roles.setting")
          }}</v-btn>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item dense>
              <v-list-item-action>
                <v-switch dense v-model="form.add"></v-switch>
              </v-list-item-action>
              <v-list-item-subtitle>{{
                $t("roles.addRecord")
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-action>
                <v-switch dense v-model="form.allowEditForm"></v-switch>
              </v-list-item-action>
              <v-list-item-subtitle>{{
                $t("roles.allowEditForm")
              }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.view")
                }}</v-list-item-subtitle>

                <v-radio-group v-model="form.read" dense row hide-details>
                  <v-radio
                    v-for="option in radioOptions"
                    :key="option.text"
                    :value="option.value"
                    :label="option.text"
                  />
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.edit")
                }}</v-list-item-subtitle>

                <v-radio-group v-model="form.edit" dense row hide-details>
                  <v-radio
                    v-for="option in radioOptions"
                    :key="option.text"
                    :value="option.value"
                    :label="option.text"
                  />
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.delete")
                }}</v-list-item-subtitle>

                <v-radio-group v-model="form.delete" dense row hide-details>
                  <v-radio
                    v-for="option in radioOptions"
                    :key="option.text"
                    :value="option.value"
                    :label="option.text"
                  />
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("roles.default")
                }}</v-list-item-subtitle>

                <v-checkbox
                  dense
                  hide-details
                  :label="$t('roles.view')"
                  v-model="form.defaultRead"
                />
                <v-checkbox
                  dense
                  hide-details
                  :label="$t('roles.edit')"
                  v-model="form.defaultEdit"
                />
                <v-checkbox
                  dense
                  hide-details
                  :label="$t('roles.delete')"
                  v-model="form.defaultDelete"
                  @change="onFormDefaultEditDeleteChange"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row no-gutters>
            <v-btn
              text
              color="primary"
              class=""
              @click="showFieldDialog = true"
              >{{ $t("roles.field") }}</v-btn
            >
          </v-row>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import viewTypes from "@/const/viewTypes"
export default {
  props: {
    calendar: Object,
    calendarId: String
  },
  computed: {
    readCalendar: {
      get() {
        return this.calendar.read;
      },
      set(val) {
        console.log('computed(readCalendar).set val=' + (val ? 'yes' : 'no'));
        try {
          this.$emit('onCommand', {
            command: 'setReadCalendar',
            calendarId: this.calendarId,
            value: val
          })
        } catch(err) {
          console.log('err: ', err);
        }
        console.log('computed(readCalendar).set ends');
      }
    },
    editCalendar: {
      get () {
        return this.calendar.edit;
      },
      set(val) {
        console.log('computed(editCalendar).set val=' + (val ?  'yes' : 'no'));
        try {
          this.$emit('onCommand', {
            command: 'setEditCalendar',
            calendarId: this.calendarId,
            value: val
          })
        } catch(err) {
          console.log('err: ', err);
        }
        console.log('computed(editCalendar).set ends');
      }
    }
  },
  data() {
    return {
      expand: false,
      menu: false,
      showFieldDialog: false,
      radioOptions: [
        {
          text: this.$t("roles.all"),
          value: 100,
        },
        {
          text: this.$t("roles.ownedAndSub"),
          value: 80,
        },
        {
          text: this.$t("roles.owned"),
          value: 60,
        },
      ],
      viewTypes: viewTypes
    };
  },
  methods: {
    onCalendarReadChange() {

    },
    onCalendarEditChange() {

    }
  },
  mounted() {
  },
};
</script>
<style scoped>

.right-border {
  border-right: 1px solid #9e9e9e;
}
</style>
