<template>
  <div>
    <div v-if="isFailState(status)">
      <div v-if="systemError">
        {{ $t('workflow.message.systemError') }}: {{ error }}
      </div>
      <div v-else class="workflow-execution-history-status-message-list">
        <div 
          class="workflow-execution-history-status-message-list-item"
          v-for="(log, index) in checkingLog"
          :key="index"
        >
          <v-icon small color="red">mdi-close</v-icon>
          {{ $t(`workflow.message.dynamicValue.${log.errorKey}`) }}
          <span v-if="log.label" class="workflow-execution-history-status-message-list-field">
            ({{ $t(`workflow.label.fieldItem`, { label: log.label }) }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableMixin from './mixin';
export default {
  mixins: [TableMixin],
  props: {
    status: String,
    checkingLog: Array,
    systemError: Boolean,
    error: String,
  },
};

</script>

<style>

</style>
