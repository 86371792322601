<template>
<div 
  :style="tabBarStyle"
  style="height:24px;"
  class="w-100 position-relative d-flex flex-row align-center justify-space-between">

  <!-- right: clipboard status -->
  <div class="d-flex flex-row align-center white--text px-1"
    style="height:24px;white-space:nowrap;">
    {{ $t('general.dataRows') }} &nbsp;:&nbsp;
    {{ dataRowCount }}/{{ totalRowCount }}
    <v-icon v-if="clipboardRowId">mdi-clipboard</v-icon>
  </div>

  <!-- middle: subject -->
  <div 
    class="w-100 text-center white--text"
    style="left:0;top:0;">{{ $t('general.quickInsertMode') }}</div>

  <!-- Data Row Count Status -->
</div>  
</template>

<script>

export default {
  name: 'quickInsertModeToolbar',
  props: {
    tabBarStyle: {
      type: Object,
      default () {
        return {}
      }
    },
    rowHeight: {
      type: String,
      default: 's'
    },
    dataRowCount: {
      type: Number,
      default: 0
    },
    totalRowCount: {
      type: Number,
      default: 0
    }
  }

}
</script>