<template>
  <input-widget-container
      :isLayout="isLayout"
      :isHidden="isHidden"
      :widget="widget"
      :required="required"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :noIcon="noIcon"
      :tooltipText="description">
    <div v-if="value==='' || !value" class="mb-1" :class="{ 'workflow-customize-form-record-hide': hideInput }">
      <v-text-field
        v-if="isReadOnly"
        disabled
        dense
        class="disabled workflow-customized-form-record-element"
      ></v-text-field>
      <dialog-button-signature
        v-else
        :readonly="isReadOnly"
        :value="value"
        @input="update"
        :formInfo="formInfo"
        :attach="`iw-${fieldId}`"
        ref="dialogButtonMap"
      >
      </dialog-button-signature>
    </div>
    <v-hover v-slot="{hover}" v-else class="w-100">
      <div class="w-100" :class="{ 'workflow-customize-form-record-hide': hideInput }">
        <div class="signature-image-container">
          <img :src="mediaSrc" class="signature-image" />
          <div style="position:absolute;top:5px;right:5px;" v-if="!isReadOnly">
            <v-btn fab x-small color="error" @click="clear">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-hover>
    <div v-if="errorMessage"
    class="selection-message-line error--text" style="font-size:12px">{{ errorMessage }}</div>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import dialogButtonSignature from "@/components/dialogButtons/DialogButtonSignature";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import MediaHelper from '@/helpers/MediaHelper';

export default {
  name: "inputWidgetSignature",
  mixins: [mixin],
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  components: {
    dialogButtonSignature,
    inputWidgetContainer,
  },
  data() {
    return {
      RULE_REQUIRED: (v) => {
        const vm = this;
        const message = this.$t("messages.thisFieldIsRequired");
        const result = Boolean(v) || message;
        if (result !== true) {
          vm.errorMessage = result;
        }
        return result;
      },
      mediaSrc: ''
    };
  },
  // computed:{
  //   mediaSrc(){
  //     if(this.value){
  //       return this.$store.getters.appHost + "/medias/" + this.value + "/thumbnail"
  //     }else return null
  //   }
  // },
  watch: {
    value(val){
      console.log('InputWidgetSignature: watch(value): val: ', val);
      this.errorMessage = "";
      this.validate();
      this.loadMediaUrl();
    },    
  },
  mounted () {
    this.loadMediaUrl()
  },
  methods: {
    validate(){
      if (this.isReadOnly) return true;
      this.errorMessage = ''
      var valid = !this.rules.some((rule) => rule(this.value) !== true);
      if(!valid){
        let msg = ""
        for (let i = 0; i < this.rules.length && msg === ""; i++) {
          const result = this.rules[i](this.value);
          msg = typeof result === "string" ? result : "";
        }
        this.errorMessage = msg;
      }
      this.hasError = !valid
      return valid
    },
    loadMediaUrl () {      
      // Updated
      // thumbnailUrl is the final url
      //
      // old version
      // thumbnailUrl contains '-thumbnail'
      const includeThumbnail = this.value.thumbnailUrl &&
        this.value.thumbnailUrl.includes('-thumbnail');

      if (includeThumbnail) {
        if (this.value && this.value.objectKey) {
          if (this.value.mediaId.startsWith('_')) {
            this.mediaSrc = this.value.thumbnailUrl
          } else {
            MediaHelper.getSignedUrl(
              this.value.objectKey,
              {isPublic: true}).then(
              response => {              
                this.mediaSrc = response;
              }
            )
          }
        } else {
          this.mediaSrc = '';
        }
      } else {
        this.mediaSrc = this.value.thumbnailUrl 
          ? this.value.thumbnailUrl
          :  '';
      }
    },
    clear() {
      const vm = this;
      vm.updateValue("");
      this.validate()
    },
    _isBlank () {
      const vm = this
      // console.log('InputWidgetSignature :: _isBlank :: vm.value == (' + vm.value + ')')
      var result = true

      if (vm.value !== "") {
        result = false;
      }
      return result;
    },
    update(val) {
      if (val) this.errorMessage = ""
      this.updateValue(val);
    }
  },
};
</script>

<style>
.is-layout {
  padding-bottom: 0 !important;
}
.signature-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.signature-image-container {
  position: relative;
  height: 120px;
  min-width: 280px;
  display: inline-block;
  /* border: 1px solid darkgray; */
  /* border-radius: 0.2rem; */
}
</style>
