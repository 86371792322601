<template>

  <!-- <vxe-table-column v-bind="colDef">
    <template v-slot="{row}">
        <div class="d-flex align-center">
        <dialog-button-map
            :value="row[fieldId]"
            @input="val=>(row[fieldId] = val)"
            ref="dialogButtonMap"
        ></dialog-button-map>
        <div v-if="row[fieldId]">
            <div>{{row[fieldId].name + row[fieldId].address}}</div>
        </div>
        </div>
    </template>
  </vxe-table-column> -->
  <vxe-table-column :class-name="tdClass + ' fullCell'" v-bind="colDef" :edit-render="{name: 'EditLocationModal', props: { readonly, updateTdClass }, enabled: !readonly}">
    <template v-slot="{row}">
      <div v-if="row[fieldId]">
        <div>{{row[fieldId].name + ", " + row[fieldId].formatted_address}}</div>
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import VXETable from 'vxe-table'
import EditLocationModal from './comps/EditLocationModal.vue'
import summaryCell from './comps/SummaryCell'

import Vue from 'vue'
Vue.component(EditLocationModal.name, EditLocationModal)

VXETable.renderer.add('EditLocationModal', {
  renderEdit (h, renderOpts, params) {
    return [
      <edit-location-modal params={ params } readonly={ renderOpts.props.readonly } v-on:updateTdClass={ renderOpts.props.updateTdClass }></edit-location-modal>
    ]
  },
  renderCell (h, renderOpts, { row, column }) {
    return [
      <span>{ row[column.property] }</span>
    ]
  },
  exportMethod (params) {
    const { row, column } = params
    return 'custom'
  }
})

export default {
  mixins: [mixin],
  components: {
    summaryCell
  }
}
</script>
