<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :isHidden="isHidden"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :noIcon="noIcon"
      :tooltipText="description">
  <table class="w-100" :class="{ 'workflow-customize-form-record-hide': hideInput }">
    <tr>
      <!-- <td width="50" v-if="!embeddedMap"
          style="vertical-align:top;"
          class="pt-1">
        <dialog-button-map
          :value="value"
          @input="val=>updateValue(val)"
          ref="dialogButtonMap"
          :attach="`iw-${fieldId}`"
        ></dialog-button-map>
      </td> -->
      <td>
        <!--embeddedMap: {{ embeddedMap }}-->
        <div style="width:100%;"
             :style="{'height': (embeddedMap ? '360px' : 'auto')}"
             class="d-flex flex-column">
          <!-- :value="location" -->
                  <!-- <dialog-button-map
                    @updateShowingDialog="updateShowingDialog"
                    :showingDialog="showingDialog"
                    :value="value"
                    @input="val=>updateValue(val)"
                    ref="dialogButtonMap"
                    :attach="`iw-${fieldId}`"
                ></dialog-button-map> -->
          <v-overlay
            :absolute="false"
            :value="showingDialog"
            :id="`iw-${fieldId}`"
          >
          </v-overlay>
          <v-text-field v-if="isReadOnly" disabled class="disabled" :value="(!value)?'':(value.name + ' ' + ((value.vicinity)?value.vicinity:value.formatted_address))"/>
          <v-text-field
            v-else
            class="input-location-txtf workflow-customized-form-record-element"
            :ref="fieldInfo.fieldId"
            @click:clear="resetField"
            @click="showingDialog=true"
            :value="(!value)?'':(value.name + ' ' + ((value.vicinity)?value.vicinity:value.formatted_address))"
            clearable
            dense
            flat
            :rules="rules"
            solo
            :hide-details="false"
            :placeholder="(!isReadOnly)? $t('inputPanel.pleaseSelect'):''"
            :disabled="isReadOnly"
            :readonly="isReadOnly"
            outlined
          >
            <template v-slot:append>
                <dialog-button-map
                    @updateShowingDialog="updateShowingDialog"
                    :showingDialog="showingDialog"
                    :value="value"
                    @input="val=>updateValue(val)"
                    ref="dialogButtonMap"
                    :attach="`iw-${fieldId}`"
                    :readonly="isReadOnly"
                ></dialog-button-map>
            </template>
          </v-text-field>
          <!-- <div v-if="embeddedMap" class="flex-grow-1">
            <div style="height:0;min-height:100%;position:relative;">
              <div v-if="isLayout" class="map-sample"></div>
              <widget-map v-else
                  :nearByPlaces="nearByPlaces"
                  v-on:updateNearByPlaces="updateNearByPlaces"
                  ref="widgetMap"
                  :value="value"
                  @input="val=>updateValue(val)"></widget-map>
              <v-btn @click="reset" class="reset-button">
                <v-icon>mdi-google-maps</v-icon>
              </v-btn>
            </div>
          </div> -->
        </div>
      </td>
    </tr>
  </table>
  <div class="v-text-field__details" :class="{ 'workflow-customize-form-record-hide': hideInput }">
    <div class="v-messages theme--light">
      <div class="v-messages__wrapper red--text">{{ errorMessage }}</div>
    </div>
  </div>
</input-widget-container>
</template>

<script>
import inputWidgetContainer from './comps/InputWidgetContainer'
import dialogButtonMap from '@/components/dialogButtons/DialogButtonMap'
import mixin from './mixin'
import widgetMap from './comps/WidgetMap'
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

export default {
  name: 'inputWidgetLocation',
  mixins: [mixin],
  components: {
    inputWidgetContainer,
    dialogButtonMap,
    widgetMap
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  computed: {
    // location () {
    //   const vm = this
    //   var result = ''
    //   if (vm.inputValue.pos) {
    //     result = '(' + vm.inputValue.pos.lat + ',' + vm.inputValue.pos.lng + ')'
    //   }
    //   return result
    // },
    embeddedMap () {
      return false
      return getFieldPropertyValue(this.fieldInfo, 'mapShowing', 'embedded') === 'embedded'
    }
  },
  data () {
    return {
      showingDialog: false
    }
  },
  methods: {
    validate(){
      if (this.isReadOnly) return true;
      if(this.required && this.$refs[this.fieldInfo.fieldId]){
        const valid = this.$refs[this.fieldInfo.fieldId].validate(true)
        this.$refs[this.fieldInfo.fieldId].error = !valid
        this.hasError = !valid
        return valid
      }
      this.hasError = false
      return true
    },
    reset () {
      const vm = this
      vm.$refs.widgetMap.reset()
    },
    onCommandHandler (payload) {

    },
    // _isBlank() {
    //   const vm = this
    //   var result = (vm.value === '') ||
    //     (vm.value.pos === null) ||
    //     (vm.value.pos.lat === null) ||
    //     (vm.value.pos.lng === null)
    //   return result
    // },
    resetField(){
      this.inputValue = '';
      this.reset()
    },
    updateShowingDialog(val){
      this.showingDialog = val
    },
    // ,
    // _validate (state) {
    //   const vm = this
    //   var result = true
    //   alert('InputWidgetLocation :: validate')
    //   return false
    // }
  }
}
</script>

<style>
  .map-sample {
    background: url("/assets/images/map_sample.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  .has-error {
    border: 1px solid red;
  }

  .input-location-txtf .v-text-field__slot{
    min-height: 36px !important;
    padding-left: 5px;
  }

</style>
