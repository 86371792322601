<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    max-width="600"
    scrollable
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        small
        v-bind="attrs"
        v-on="on"
      >{{$t('workflow.modules.subWorkflow.label.settingParam')}}</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card class="w-e-parameter-popup-root">
        <v-card-title class="text-h5">
          {{$t('workflow.modules.subWorkflow.label.settingParam')}}
          <span v-if="parameters.length > 0"> ({{ parameters.length }})</span>
        </v-card-title>
        <v-card-text v-if="show">
          <ParameterFieldsInput 
            :targetWorkflow="targetWorkflow" 
            :parameters="parameters" 
            @remove="remove"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            :disabled="loading"
            @click="dialog.value = false"
          >
            {{$t('workflow.btn.cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="submit"
            :loading="loading"
            class="w-e-parameter-popup-submit"
          >
            {{$t('workflow.btn.confirm')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { countBy } from 'lodash';
import ParameterFieldsInput from './../../../parameter';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   SET_SETTINGS,
// } from '@/store/modules/workflow/action_types';
import {
  ADD_WORKFLOW_PARAMETER,
  // REMOVE_WORKFLOW_PARAMETER,
} from '@/store/modules/workflow/action_types';
import Mixin from './../mixin';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    targetWorkflow: Object,
  },
  components: {
    ParameterFieldsInput,
  },
  methods: {
    submit() {
      const { targetWorkflow, parameters, appId } = this;
      const anyError = countBy(parameters, ({ error }) => !!error);
      if (!anyError.true) {
        this.$store.dispatch(ADD_WORKFLOW_PARAMETER, {
          appId,
          workflow: targetWorkflow._id || targetWorkflow,
          parameters,
        });
      }

      // if (this.removed.length > 0) {
      //   this.$store.dispatch(REMOVE_WORKFLOW_PARAMETER, {
      //     appId,
      //     workflow: _id,
      //     removed: this.removed,
      //   });
      // }
    },
    // remove(id) {
    //   this.removed.push(id);
    //   // console.log('> ', this.removed, id);
    // },
  },
  computed: {
    parameters() {
      return this.$store.getters.getWorkflowParameters;
    },
    loading() {
      return this.$store.getters.isParameterLoading;
    },
  },
  data() {
    return {
      show: false,
      // removed: [],
    };
  },
  watch: {
    loading: {
      deep: true,
      handler(loading, wasLoading) {
        if (!loading && wasLoading) {
          this.show = false;
        }
      },
    }
  }, 
};

</script>

<style scoped>
  .w-e-parameter-popup-root {
    padding: 10px 0 15px 0;
    min-height: 400px;
  }
  .w-e-parameter-popup-submit {
    padding: 0 30px !important;
    margin-left: 20px !important;
  }
</style>
