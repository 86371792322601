var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.readonly)?_c('v-input',{attrs:{"disabled":""}},[_c('div',{staticClass:"pa-1 w-100 mb-0",staticStyle:{"border-bottom":"1px dashed darkgray"}},[(Array.isArray(_vm.inputValue))?_c('div',_vm._l((_vm.inputValue),function(item){return _c('v-chip',{key:item._id,staticClass:"mr-1"},[_c('field-string',{attrs:{"height":"auto","textOnly":true,"fieldInfo":_vm.titleFieldInfo,"recordData":item}})],1)}),1):_c('v-chip',{staticClass:"mr-1"},[_c('field-string',{attrs:{"height":"auto","textOnly":true,"fieldInfo":_vm.titleFieldInfo,"recordData":_vm.inputValue}})],1)],1)]):_c('div',[_c('v-autocomplete',{class:{
      'is-simple-mode': _vm.isSimpleSelectionMode,
      'is-card-mode': !_vm.isSimpleSelectionMode,
    },attrs:{"menu-props":{contentClass:'select-from-data-source'},"outlined":"","dense":"","multiple":false,"search-input":_vm.search,"return-object":"","items":_vm.items,"item-text":_vm.displayFieldId,"item-value":"_id","hide-no-data":_vm.loading,"chips":"","deletable-chips":"","attach":'#' + _vm.id,"loading":_vm.loading,"error":_vm.hasError,"error-messages":_vm.errorMessage},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"focus":_vm.onFocus},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [(!_vm.isSimpleSelectionMode)?_c('div',{staticClass:"w-100 py-1",staticStyle:{"line-height":"1"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('record-card',{attrs:{"card":item,"edit":false,"cardConfig":_vm.cardConfig,"titleFieldId":_vm.titleFieldId,"buttons":[],"fieldInfos":_vm.fieldInfos},scopedSlots:_vm._u([{key:"card-control",fn:function(){return [(hover)?_c('v-btn',{staticStyle:{"align-self":"end"},attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.clearSelection(item._id)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()]},proxy:true}],null,true)})]}}],null,true)})],1):_c('div',[(_vm.titleFieldInfo && _vm.titleFieldInfo.type === 'members')?_c('div',{staticClass:"member-tag-wrapper"},_vm._l((item[_vm.titleFieldId]),function(employeeInfo,index){return _c('v-chip',{key:index},[_vm._v(" "+_vm._s(employeeInfo.employeeName)+" ")])}),1):(_vm.titleFieldInfo && _vm.titleFieldInfo.type === 'departments')?_c('div',{staticClass:"department-tag-wrapper"},_vm._l((item[_vm.titleFieldId]),function(departmentInfo,index){return _c('v-chip',{key:index},[_vm._v(" "+_vm._s(departmentInfo.label)+" ")])}),1):_c('v-chip',{staticClass:"mr-1",staticStyle:{"height":"28px","min-height":"28px"}},[_c('field-string',{attrs:{"height":"auto","textOnly":true,"fieldInfo":_vm.titleFieldInfo,"recordData":item}})],1)],1),(_vm.hover)?_c('v-icon',[_vm._v("mdi-icon")]):_vm._e()]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [(!_vm.isSimpleSelectionMode)?_c('div',{staticClass:"w-100 py-1",staticStyle:{"line-height":"1"}},[_c('record-card',{attrs:{"card":item,"edit":false,"cardConfig":_vm.cardConfig,"titleFieldId":_vm.titleFieldId,"buttons":[],"fieldInfos":_vm.fieldInfos}})],1):_c('div',[(_vm.titleFieldInfo && _vm.titleFieldInfo.type === 'members')?_c('div',{staticClass:"member-tag-wrapper"},_vm._l((item[_vm.titleFieldId]),function(employeeInfo,index){return _c('v-chip',{key:index},[_vm._v(" "+_vm._s(employeeInfo.employeeName)+" ")])}),1):(_vm.titleFieldInfo && _vm.titleFieldInfo.type === 'departments')?_c('div',{staticClass:"department-tag-wrapper"},_vm._l((item[_vm.titleFieldId]),function(departmentInfo,index){return _c('v-chip',{key:index},[_vm._v(" "+_vm._s(departmentInfo.label)+" ")])}),1):_c('v-chip',{staticClass:"mr-1",staticStyle:{"height":"28px","min-height":"28px"}},[_c('field-string',{attrs:{"height":"auto","textOnly":true,"fieldInfo":_vm.titleFieldInfo,"recordData":item}})],1)],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true},{key:"append-outer",fn:function(){return [(_vm.allowCreation)?_c('v-btn',{staticClass:"ml-1",attrs:{"x-small":"","depressed":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.$emit('create')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }