<template>
  <div v-if="isLoading">
    <div class="text-center pa-3" v-if="isLoading">
        <font-awesome-icon icon="spinner" class="spinner fa-spin text-h5 ma-auto" />
    </div>
  </div>
  <div v-else>
      <div id="chartPage" class="mt-3 mx-3">
        <div v-for="tab in tabs" :key="tab">
            <!-- <div>{{ $t(`chart.others.${tab}`) }}</div> -->
            <li v-for="chart in charts.filter( chart => chart.group === tab)" :key="( tcb === 'public' ) ? chart.publicOrder : chart.order" :value="tab" class="mb-3">
                <v-card class="pa-3">
                    {{ chart.chartName }}
                    <ChartCanvas
                        style="height:30vh"
                        v-if="chart"
                        :ref="`formChart-${chart._id}`"
                        :dataSetups="chart.dataSetups"
                        :sourceSetups="chart.sourceSetups"
                        :styleSetupToggles="chart.styleSetupToggles"
                        :styleSetups="chart.styleSetups"
                        :selectedChart="chart.selectedChart"
                        :chartId="chart._id"
                        :appId="chart.appId"
                        :formId="chart.formId"
                        :tab="tab" 
                        :hookId="`${tab}-chartHook`"
                        :bl_fullScreen="bl_fullScreen"
                    />
                </v-card>
            </li>
        </div>
    </div>
  </div>
</template>

<script>
    import ChartCanvas from '@/components/chart/panes/ChartCanvas'
    export default {
        components: {
            ChartCanvas,
            // ActionBtns
        },
        computed: {
            userId(){
                return this.$store.getters.activeEmployeeId
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            appId() {
                return this.$route.params.id;
            }, 
            formId() {
                return this.$route.params.itemId;
            }
        }, 
        data() {
            return {
                isLoading: false, 
                charts: [],
                tabs: [ 'public', 'private' ]
            }
        },
        async mounted() {
            this.isLoading = true;
            for( let i of this.tabs ) {
                await this.fetchCharts( i );
            }
            this.isLoading = false;
        },
        methods: {
            async fetchCharts(val) {
                const getParams = {
                    urlCommand: '/chart/get/'+ val +'/'+ this.appId + '/' + this.formId
                };
                await this.$store.dispatch('AUTH_GET', getParams).then(response => {
                    console.log( 'MOBILE FETCH CHARTS:: RESPONSE', response );
                    response.forEach( chart => {
                        this.charts.push( chart );
                    })
                })
            }
        }
    }
</script>