<template>
  <vxe-table-column v-bind="colDef"
                    class="text-right"
                    :edit-render="{name: 'input', enabled: !readonly}" 
                    sortable class-name="fullCell vxeCellNumber">
    <template v-slot="{row}">
      <div>
        <span v-if="prefix" class="pa-2" style="color: #b5b5b5">{{prefix}}</span>
        <span>{{ formatNumber(row[fieldId]) }}</span>
        <span v-if="suffix" class="pa-2" style="color: #b5b5b5">{{ suffix }}</span>
      </div>
      <quick-action
        :rowId="row['_id']"
        :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{row}">
      <div style="height:100%" class="d-flex flex-row"z>
        <vxe-input 
          style="height:100%" 
          type="float"
          :digits="decimals"
          v-model.number="row[fieldId]"
        />
      </div>
    </template>

    <template v-slot:footer="{column, columnIndex, $columnIndex, _columnIndex, $rowIndex, items}">      
      <div style="height:100%;width:100%;position:relative;"
        @click="selectCalcMethod(column, columnIndex, $columnIndex, _columnIndex, $rowIndex, items)">
        {{ summaryValue }}
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import quickAction from './comps/QuickAction'
import summaryCell from './comps/SummaryCell'


export default {
  name: 'vxeCellNumber',
  mixins: [mixin],
  components: {
    quickAction,
    summaryCell
  },

  computed: {

    NumberType() {
      return (this.decimals === "0" || this.decimals === "") ? "integer": "float"
    },
    validations() {
      return this.fieldInfo.properties.validation;
    },
    min(){
      if (this.validations.search("withinRange") > -1) {
        return this.fieldInfo.properties.minValue
      }
      return ""
    },
    max(){
       if (this.validations.search("withinRange") > -1) {
        return this.fieldInfo.properties.maxValue
      }
      return ""     
    }
  }
}

</script>

<style>
  .vxeCellNumber .vxe-input input{
    text-align: end
  }
</style>
