<template>
  <div v-if="isMobile" class="view-record" id="form-component">
    <div class="record-header" id="data-wrapper" v-if="recordData._id != '' ">
      <div v-if="formLayoutMobile" class="data-item-wrapper">
        <CustomizedActionButtonList
          :size="1"
          :selected="[$route.params.recordId]"
          class="mt-2 mr-2 flex-wrap d-flex"
          v-if="!isNew"
        />
        <v-form ref="form"  @submit="onSubmit">
        <div v-for="fieldInfo in mobileMapFieldInfos(formLayoutMobile)" :key="fieldInfo._id">
          <div v-if="fieldInfo.controlType === 'data'"  class="dataItem">
            <input-widget v-if="['edit', 'create'].includes(mobileMode) || fieldInfo.type === 'childTable'" :ref="fieldInfo._id" :key="fieldInfo._id"
                        :isNew="isNew" :recordData="recordData"
                        :relatedTableData="relatedTableData"
                        :relatedTableInfos="relatedTableInfos"
                        :fieldInfo="fieldInfo"
                        :formInfo="currentForm"
                        @onCommand="onCommandHandler"></input-widget>
            <div v-else>
              <div class="fieldCaption">{{ fieldInfo.label }}</div>
              <div class="fieldValue">
                <value-handler v-if="fieldInfo.controlType === 'data'" :content="recordData[fieldInfo._id]" :id="fieldInfo._id" 
                  :type="fieldInfo.type" 
                              :fieldInfo="fieldInfo" 
                              :relationData="relatedTableData" 
                              :relatedTableInfos="relatedTableInfos"
                              mode="view" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="_sectionLine" v-if="fieldInfo.type === '_sectionLine'">{{ fieldInfo.label }}</div>
            <div class="_remark"  v-if="fieldInfo.type === '_remark'" v-html="fieldInfo.properties.remark"></div>
          </div>
        </div>
        </v-form>

         <!-- SAVE BUTTON -->
        <div class="px-3 d-block">
          <v-btn large block :loading="isSaving" color="primary" v-if="mobileMode === 'edit'" @click="onSubmit">UPDATE</v-btn>
          <v-btn large block :loading="isSaving" color="primary" v-if="mobileMode === 'create'" @click="onSubmit">CREATE</v-btn>
        </div>

        <div id="floating-action-button-group" v-if="!(['edit', 'create'].includes(mobileMode) ) && actionButtonsCount > 0">
            <div></div>
            <div>
              <v-btn rounded dark color="#76B43D" @click="showCustomActionDraw()">
                <v-icon left>$vuetify.icons.customizedAction</v-icon> <span>{{ $t('view.customizedAction') }}</span> <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </div>
        </div>
      </div>
      
    </div>
    <div v-else class="loading-wrapper">
      <font-awesome-icon icon="spinner" class="spinner fa-spin text-h5 ma-auto" />
    </div>

    <!-- EDIT / REMOVE button container -->
    <v-navigation-drawer bottom app v-model="$store.state.mobile.showRecordActionDrawer" temporary class="rounded-t-xl" height="auto" style="z-index:1002" id="recordActionDrawer">
      <v-list rounded>
          <v-list-item @click="editRecord()">
              <v-list-item-icon>
                  <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>{{ $t('buttons.edit') }}</v-list-item-content>
          </v-list-item>
          <v-list-item class="red--text" @click="removeRecord()">
                  <v-list-item-icon>
                      <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{ $t('buttons.remove') }}</v-list-item-content>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <custom-action-drawer v-if="formId&&appId" :formId="formId" :appId="appId" :selected="[ recordData._id ]" />
    <popup-record-dialog ref="popupRecordDialog" @onCommand="onCommandHandler"></popup-record-dialog>
    <child-record-dialog ref="childRecordDialog"></child-record-dialog>
    <!-- confirm Dialog -->
    <v-dialog v-model="showConfirmDialog">
      <v-card class="pa-7 rounded-lg text-center">
        <v-card-title class="text-center d-block">{{ $t('inputPanel.deleteRecord') }}</v-card-title>
        <v-card-text>
          {{ $t('dialog.deleteConfirmMessage', { recordTitle: recordData[titleFieldInfoId] }) }}
        </v-card-text>
        <v-card-actions class="d-block">
          <v-btn outlined @click="showConfirmDialog = false">{{ $t('buttons.cancel') }}</v-btn>
          <v-btn color="error" @click="confirmDelete()">{{ $t('buttons.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else
    class="record flex-grow-1 page-form d-flex flex-column justify-start record"
  >
    <v-card class="flex-grow-1 ma-0 d-flex flex-column" tile flat>
      <!-- FORM TITLE -->
      <div class="d-flex flex-column">
        <form-title
          v-if="currentForm"
          :title="formTitle"
          :plus="['submit']"
          :buttons="recordButtons"
          :formBuilderButton="true"
          :defaultSubmitCommand="submitCommand"
          :hasSearch="false"
          :hasChart="false"
          @onCommand="onCommandHandler"
          class="flex-grow-1"
          :mode="isNew?'new':'edit'"
        >
          <template v-slot:prepend-action-button-set>
            <CustomizedActionButtonList
              :size="1"
              :selected="[$route.params.recordId]"
              v-if="!isNew"
            />
            <v-btn v-if="!isNew && recordData.canShare" @click="showSharableRecord=true" x-small fab dark icon class="primary">
              <v-icon size="18">mdi-share-variant</v-icon>
            </v-btn>
            <sharable-record @input="v=>showSharableRecord=v" v-model="showSharableRecord" v-if="showSharableRecord"/>
            <discussion-control-button v-if="!isNew" v-model="showDrawer" customColor="grey" customIcon="mdi-format-horizontal-align-center" />
            <print-template-button
              v-if="!loading && printTemplates.length > 0 && !isNew"
              :printTemplates="printTemplates"
              @print="
                print({
                  templateId: $event.templateId,
                  recordId: recordData._id,
                  type:$event.type || 0,
                  appId,
                  formId: form._id,
                  viewId,
                  templateName: $event.label
                })
              "
            />
          </template>
        </form-title>
        <!-- <div style="position: absolute; top:40px; left: 13px; z-index:1" class="d-flex align-end">
          <div style="font-size:12px">標題:</div>
            <v-chip label tile flat class="ml-3 secondary" small style="height: 20px">
                <field-string
                  class="d-flex align-center"
                  :fieldInfo="titleFieldInfo"
                  :recordData="recordData"
                ></field-string>
            </v-chip>
        </div> -->
      </div>
      <div class="d-flex flex-row fill-height">
        <div class="d-flex flex-column flex-grow-1">
          <v-card-text
            class="d-flex flex-column flex-grow-1 pt-0 pb-3 px-0 justify-center"
          >
            <v-icon v-if="loading">mdi-spin mdi-loading mdi-36px</v-icon>

            <v-form
              v-else-if="formLayout"
              ref="form"
              class="flex-grow-1 d-flex flex-column"
              @submit="onSubmit"
            >
              <!-- RECORD TITLE -->
              <div class="px-2 pb-2">
                <div class="row py-0">
                  <div class="col pb-0">
                    <div
                      class="headline my-2 px-5"
                    >
                      <field-string
                        :fieldInfo="titleFieldInfo"
                        :recordData="recordData"
                      ></field-string>
                    </div>
                  </div>
                </div>
              </div>

              <!-- RECORD TAB PAGE NAVIGATION -->
              <module-tab-bar
                v-if="relationPagingFieldInfos.length > 0"
                class="flex-grow-0 rounded mt-2"
                v-model="topPagingIndex"
                type="topPagingTab"
                labelField="label"
                :hideButtons="true"
                :availableTabs="topPagingTabs"
                @onCommand="onCommandHandler"
              ></module-tab-bar>

              <!-- RECORD TOP/BOTTOM PANES -->
              <splitpanes
                v-if="topPagingIndex === 0"
                horizontal
                style="overflow-y:auto;height:0;width:0px; min-width:100%"
                class="default-theme flex-grow-1"
              >
              <pane
                  class="white d-flex flex-column pt-2"
                  size="60"
                  min-size="20"
                >
                  <perfect-scrollbar
                    class="flex-grow-1 d-flex flex-column pb-12"
                    style="overflow-y:scroll;"
                  >
                    <!-- class="mr-3 mb-3 flex-grow-1" -->
                    <div class="mt-4 mr-3 mb-3 flex-grow-1 px-5 px-12">
                      <v-row
                        no-gutters
                        v-for="row in formLayout.rows"
                        :key="row._id"
                      >
                        <template v-for="fieldInfo in getFieldInfosInRow(row)">
                          <input-widget
                            :ref="fieldInfo.fieldId"
                            :key="fieldInfo._id"
                            :isNew="isNew"
                            :recordData="recordData"
                            :relatedTableData="relatedTableData"
                            :relatedTableInfos="relatedTableInfos"
                            :fieldInfo="fieldInfo"
                            :formInfo="currentForm"
                            @onCommand="onCommandHandler"
                          ></input-widget>
                        </template>
                      </v-row>
                    </div>
                  </perfect-scrollbar>
                </pane>

                <pane
                  v-if="currentForm && recordData && embeddedPagingTableFieldInfos.length >0"
                  class="d-flex flex-column fill-height"
                  size="40"
                  min-size="20"
                >
                  <div
                    class="flex-grow-1 d-flex flex-column justify-start drop-container px-0"
                    style="min-height:100%;height:0;background-color:transparent;position:relative;"
                  >
                    <!-- <v-tabs
                      v-model="embeddedPagingIndex">
                      <v-tab v-for="(tab,index) in embeddedPagingTabs"
                        :key="index">
                        {{ tab.label }}
                      </v-tab>>
                    </v-tabs> -->
                    <module-tab-bar
                      style="height:24px;"
                      class="flex-grow-0 rounded"
                      v-model="embeddedPagingIndex"
                      labelField="label"
                      type="embeddedPagingTab"
                      :hideButtons="true"
                      :availableTabs="embeddedPagingTabs"
                      @onCommand="onCommandHandler"
                    ></module-tab-bar>

                    <div
                      v-for="(fieldInfo,
                      index) in embeddedPagingTableFieldInfos"
                      :key="fieldInfo._id"
                      v-if="embeddedPagingIndex === index"
                      class="flex-grow-1 fill-height py-3 d-flex flex-column"
                    >
                      <!--<inputWidget-->
                      <!--class="fill-height"-->
                      <!--:ref="fieldInfo.fieldId"-->
                      <!--:key="fieldInfo._id"-->
                      <!--:recordData="recordData"-->
                      <!--:fieldInfo="fieldInfo"-->
                      <!--@onCommand="onCommandHandler"></inputWidget>-->
                      <perfect-scrollbar
                        class="flex-grow-1 d-flex flex-column pb-0"
                        style="overflow-y:scroll;"
                      >
                        <input-widget-child-table
                          v-if="fieldInfo.type==='childTable'"
                          ref="fieldInfo.fieldId"
                          :isNew="isNew"
                          :hideLabel="true"
                          :recordData="recordData"
                          :relatedTableData="relatedTableData"
                          :relatedTableInfos="relatedTableInfos"
                          :fieldInfo="fieldInfo"
                          :activeForm="currentForm"
                          :value="recordData[fieldInfo.fieldId]"
                          :isReadonly="getIsReadOnly(fieldInfo)"
                          @onCommand="onCommandHandler"
                          @input="val=>updateFieldValue({
                                        fieldName: fieldInfo.fieldId,
                                        fieldValue: val,
                            })"></input-widget-child-table>
                        <input-widget-related-record
                          v-if="fieldInfo.type==='relatedRecord'"
                          :ref="fieldInfo.fieldId"
                          :isNew="isNew"
                          :fieldInfo="fieldInfo"
                          :recordData="recordData"
                          :relatedTableData="relatedTableData"
                          :relatedTableInfos="relatedTableInfos"
                          :value="recordData[fieldInfo.fieldId]"
                          :isReadonly="getIsReadOnly(fieldInfo)"
                          :activeForm="currentForm"
                          @onCommand="onCommandHandler"
                          @input="
                            (val) =>
                              updateFieldValue({
                                fieldName: fieldInfo.fieldId,
                                fieldValue: val,
                              })
                          "
                        ></input-widget-related-record>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </pane>
              </splitpanes>

              <div
                v-for="(fieldInfo, index) in topPagingTableFieldInfos"
                v-if="topPagingIndex === index + 1"
                :key="fieldInfo._id"
                class="d-flex flex-column fill-height py-2"
              >
                <perfect-scrollbar
                  class="flex-grow-1 d-flex flex-column pb-12"
                  style="overflow-y:scroll;height:0;min-height:100%;"
                >
                  <div class="mr-3 mb-3 px-5">
                    <input-widget-child-table
                      v-if="fieldInfo.type==='childTable'"
                      ref="fieldInfo.fieldId"
                      :isNew="isNew"
                      :hideLabel="true"
                      :recordData="recordData"
                      :relatedTableData="relatedTableData"
                      :relatedTableInfos="relatedTableInfos"
                      :fieldInfo="fieldInfo"
                      :activeForm="currentForm"
                      :value="recordData[fieldInfo.fieldId]"
                      :isReadonly="getIsReadOnly(fieldInfo)"
                      @onCommand="onCommandHandler"
                      @input="val=>updateFieldValue({
                                    fieldName: fieldInfo.fieldId,
                                    fieldValue: val,
                            })"></input-widget-child-table>
                    <input-widget-related-record
                      v-if="fieldInfo.type==='relatedRecord'"
                      :ref="fieldInfo.fieldId"
                      :isNew="isNew"
                      :recordData="recordData"
                      :relatedTableData="relatedTableData"
                      :relatedTableInfos="relatedTableInfos"
                      :fieldInfo="fieldInfo"
                      :activeForm="currentForm"
                      :value="recordData[fieldInfo.fieldId]"
                      :isReadonly="getIsReadOnly(fieldInfo)"
                      @onCommand="onCommandHandler"
                      @input="(val) =>
                          updateFieldValue({
                            fieldName: fieldInfo.fieldId,
                            fieldValue: val,
                          })"></input-widget-related-record>
                  </div>
                </perfect-scrollbar>
              </div>
            </v-form>
          </v-card-text>
        </div>
        <!-- <transition name="slide-fade"> -->
        <div
          v-if="!isNew && showDrawer"
          class="d-flex flex-column drawer-container" style="border-top:1px solid #9e9e9e">
          <drawer :parentId="recordId"/>
        </div>
        <!-- </transition> -->
      </div>
    </v-card>
    <!-- New Related Record -->
    <popup-record-dialog ref="popupRecordDialog" @onCommand="onCommandHandler"></popup-record-dialog>
    <child-record-dialog ref="childRecordDialog"></child-record-dialog>
    <edit-template-dialog
      v-if="showTemplateDialog"
      v-model="showTemplateDialog"
      :templateId="selectedPrintTemplate"
      :recordId="selectedRecordId"
      :isEdit="isEditPrint"
      :viewId="currentViewId"
      :isPrint="true"
    />
    
    <members-menu
      :fullWidth="true"
      v-if="showingMembersMenu"
      v-model="showingMembersMenu"
      :attachId="membersMenuAttachId"
      ref="membersMenu"
      @memberClick="handleMemberClick"
    />

    <batch-insert-dialog  
      v-model="showingBatchInsertDialog"
      v-if="showingBatchInsertDialog"
      :settings="batchInsertSettings"
      @onCommand="onCommandHandler"
    />

  </div>
</template>

<script>
// mixins
import baseMixin from "@/mixins/baseMixin";
import appsFormsMixin from "@/mixins/appsFormsMixin";
import formFieldMixin from "@/mixins/formFieldMixin";
import dialogMixin from "@/mixins/dialogMixin";
import CustomizedActionButtonList from "@/pages/admin/workflow/manualActions/tools/list";
import relatedTableLayoutMixin from "@/mixins/relatedTableLayoutMixin";
import printTemplateDialogMixin from "@/mixins/printTemplateDialogMixin";
import recordMixin from "@/mixins/recordMixin";


import inputWidget from "@/components/inputWidgets/InputWidget";

import inputWidgetRelatedRecord from "@/components/inputWidgets/InputWidgetRelatedRecord";
import inputWidgetChildTable from "@/components/inputWidgets/InputWidgetChildTable";
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import childRecordDialog from "@/components/dialogs/ChildRecordDialogPublic";
import EditTemplateDialog from "@/pages/admin/form/tableSetup/print/EditTemplateDialog";
import fieldString from '@/components/fieldStrings/Fs'

import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import {
  FETCH_ACTION_BUTTON_OPTIONS,
} from '@/store/modules/customizedAction/action_types';

import moduleTabBar from "@/components/ModuleTabBar";
import formTitle from "@/components/FormTitle";

import drawer from "@/components/discussion/drawer";
import DiscussionControlButton from "@/components/discussion/discussionControlButton";
import PrintTemplateButton from "@/components/buttons/PrintTemplateButton";

import {ValidateRule} from "@/helpers/RuleHelper" 
import EventBus from '@/event-bus.js'
import membersMenu from '@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu'

import valueHandler from '@/components/form/mobile/comps/valueHandler';
import CustomActionDrawer from '@/pages/forms/mobile/comps/CustomActionDrawer';
import sharableRecord from '@/components/sharableRecord'
import batchInsertDialog from "@/components/dialogs/BatchInsertDialog";

export default {
  name: "record",
  mixins: [
    baseMixin,
    appsFormsMixin,
    formFieldMixin,
    dialogMixin,
    relatedTableLayoutMixin,
    recordMixin,
    printTemplateDialogMixin,
  ],
  components:{
    inputWidget,
    Splitpanes,
    Pane,
    moduleTabBar,
    formTitle,
    inputWidgetRelatedRecord,
    inputWidgetChildTable,
    popupRecordDialog,
    childRecordDialog,

    EditTemplateDialog,

    drawer,
    DiscussionControlButton,
    PrintTemplateButton,
    fieldString,
    CustomizedActionButtonList,
    membersMenu,
    valueHandler,
    CustomActionDrawer,
    sharableRecord,
    batchInsertDialog
  },
  data(){
    return {
      loading: false,
      isSaving: false,
      retainSummaryField: false,

      mode: "new",
      errors: 0,
      recordData: {
        _id: "",
      },
      oldRecordData: {}, 
      relatedTableData: {},

      // error handling
      errorMessages: "",
      formHasErrors: false,
      // callback: null,

      childTableTabs: [
      ],
      selectedChildTableIndex: 0,

      topPagingIndex: 0, // if no top related tables, it is always 0
      embeddedPagingIndex: 0,
      showDrawer: false,
      saveAndContinue: false,
      activeRecord: null,
      
      submitCommand: "submit",
      showingMembersMenu: false,
      membersMenuAttachId: null,
      showConfirmDialog: false,
      saveCount: 0,
      showSharableRecord: false,
      showingBatchInsertDialog: false,
      batchInsertSettings: {}
    };
  },
  computed: {
    actionButtonCount() {
      let count = 0;
      count = (this.$store.getters.getActionButtons || []).length;
      return count;
    }, 
    recordId() {
      return this.recordData._id;
    },
    isNew() {
      const vm = this;
      var result = true;
      if (vm.$route.params.recordId !== "_" || vm.saveCount>0) {
        result = false;
      }
      return result;
    },
    relatedTableInfos(){
      return this.$store.getters.relatedTableInfos
    },
    currentForm() {
      return this.$store.getters.currentForm;
    },

    appId() {
      const { params, currentForm } = this;
      return currentForm ? currentForm.appId : params ? params.id : '';
    },

    formId() {
      const { params, currentForm } = this;
      return currentForm ? currentForm._id : params ? params.itemId : '';
    },

    viewId() {
      const { params } = this;
      return this.$route.params.viewId || (params && params.viewId) || '';
      // return this.$route.params.viewId;
    },
    formData() {
      const vm = this;
      const SYSTEM_FIELD_IDS = ["owner", "createdBy", "createdAt", "updatedAt"];
      var result = {};
      if (vm.recordData) {
        const fieldIds = Object.keys(vm.recordData).filter(
          (d) => d.indexOf("_") < 0 && vm.fieldIds.includes(d)
        );
        for (var i = 0; i < fieldIds.length; i++) {
          const loopFieldId = fieldIds[i];
          if (!SYSTEM_FIELD_IDS.includes(loopFieldId)) {
            var fieldInfo = vm.fieldInfos.find(field=>field.fieldId==loopFieldId)
            if(fieldInfo&&fieldInfo.type=='relatedRecord'){
              if(vm.recordData[loopFieldId]&&Array.isArray(vm.recordData[loopFieldId]))
                result[loopFieldId] = vm.recordData[loopFieldId].map(item=>{
                  return typeof item === 'object'? item._id:item
                })
            }else
              result[loopFieldId] = vm.recordData[loopFieldId];
          }
        }
      }
      return result;
    },

    fieldInfos() {
      return this.currentForm ? this.currentForm.fieldInfos : [];
    },
    uiElementInfos() {
      return this.currentForm ? this.currentForm.uiElementInfos : [];
    },
    fieldIds () {
      return this.fieldInfos.map(info => info.fieldId)
    },

    formLayout() {
      return this.currentForm ? this.currentForm.layout : null;
    },

    formLayoutMobile() {
      let _layout = null;
      const formFieldInfoObj = this.fieldInfos.concat(this.uiElementInfos).reduce( (obj, field) => {
        obj[field._id] = field
        return obj;
      }, {});
      if ( this.currentForm ) {
        _layout = this.currentForm.layout;
        _layout = _layout.rows.reduce((ar, item) => {
            item = item.filter( _item => ( formFieldInfoObj[_item._id] && formFieldInfoObj[_item._id].canView ) )
            ar = [ ...ar, ...item ]
            return ar;
          }, []);
      }
      return _layout;
    },

    dataRelatedTableIds() {
      return this.$store.getters.relatedTableIds;
    },
    dataSources() {
      return this.$store.getters.dataSources;
    },
    editableFieldIds() {
      return this.fieldInfos
        .filter((info) => info.canEdit)
        .map((info) => info._id);
    },
    recordButtons() {
      const vm = this;
      var result = ["back"];
      if (!vm.isHiddenForDeployment("record_refresh")) result.push("refresh");
      if (!vm.isHiddenForDeployment("record_delete")) result.push("delete");
      if (this.isNew || this.canEditRecord) {
        // result.push('submitAndKeep')
        // result.push('submitAndNew')
        result.push("submit");
      }
      return result;
    },
    canEditRecord() {
      return this.recordData.canEdit || false;
    },


    firstFieldInfo() {
      const vm = this;
      var result = null;
      if (vm.fieldInfos && vm.fieldInfos.length > 0) {
        result = vm.fieldInfos[0];
      }
      return result;
    },

    newOrEdit() {
      const vm = this;
      return (vm.$route.params.recordId === "_" && vm.saveCount==0)
        ? " (" + vm.$t("general.new") + ")"
        : " (" + vm.$t("general.edit") + ")";
    },

    formTitle() {
      const vm = this;
      var result = vm.currentForm.label;
      if (vm.$route.params.recordId === "_" && vm.saveCount==0) {
        result += " (" + vm.$t("general.new") + ")";
      } else {
        result += " (" + vm.$t("general.edit") + ")";
      }
      return result;
    },

    fieldInfoId2FieldIdMapping() {
      const vm = this;
      var result = {};
      for (var i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i];
        result[loopFieldInfo._id] = loopFieldInfo.fieldId;
      }
      return result;
    },

    titleFieldInfoId() {
      return this.currentForm ? this.currentForm.titleFieldInfoId : "";
    },

    recordTitle() {
      const vm = this;
      var result = "";
      const titleFieldInfo = vm.titleFieldInfo;
      // const fieldInfoId = vm.currentForm.titleFieldInfoId;
      // const fieldInfo = vm.getFieldInfoById(vm.fieldInfos, fieldInfoId);
      if (titleFieldInfo) {
        result = "{" + titleFieldInfo.label + "}";
        const titleFieldId = titleFieldInfo["fieldId"];
        if (
          vm.recordData[titleFieldId] &&
          !(vm.recordData[titleFieldId] instanceof Object)
        ) {
          if (vm.recordData[titleFieldId] !== "") {
            result = vm.recordData[titleFieldId];
          }
        }
      } else {
        result = "(" + vm.$t("messages.titleFieldNotSpecified") + ")";
      }
      return result;
    },

    currentApp() {
      return this.$store.getters.currentApp;
    },
    displayRule(){
      return this.$store.getters.currentForm.displayRule
    },
    isMobile() {
      return /mobi/i.test( window.navigator.userAgent ) && ! /ipad/i.test( window.navigator.userAgent );
    },
    mobileMode() {
      return this.$store.getters.recordPageMode;
    },
  },

  methods: {
    openMembersMenu(attachedEle, fieldInfo){
      let vm = this
      vm.membersMenuAttachId = attachedEle.id
      vm.showingMembersMenu = true
      vm.$nextTick(()=>{
        vm.$refs.membersMenu.type= fieldInfo.properties.fieldType
        vm.$refs.membersMenu.selectedValue= vm.recordData[fieldInfo.fieldId]
        vm.$refs.membersMenu.fieldId= fieldInfo.fieldId
        attachedEle.id = ""
      })
    },
    handleMemberClick(val){
      if(this.$refs.membersMenu.fieldId){
        var fieldId = this.$refs.membersMenu.fieldId
        var type = this.$refs.membersMenu.type
        var pos = this.recordData[fieldId].findIndex(item=>item._id == val._id)
        if(pos!=-1){
          this.recordData[fieldId].splice(pos,1)
        }else{
          if(type=='multiple')
            this.recordData[fieldId].push(val)
          else
            this.recordData[fieldId] = [val]
        }
      }
    },
    initialData() {
      const vm = this;
      vm.retainSummaryField = false;
      if (vm.currentApp && vm.currentApp._id===vm.$route.params.appId) {
        if (vm.currentForm && vm.currentForm._id===vm.$route.params.itemId) {
          if (vm.$route.params.recordId) {
            vm._loadData();
          }
        } else {
          vm._loadFormAndChild();
        }
      } else {
        vm._loadAppAndChild();
      }
      vm.$store.dispatch(FETCH_ACTION_BUTTON_OPTIONS, {
        appId: vm.$route.params.appId || vm.$route.params.id,
        formId: vm.$route.params.formId || vm.$route.params.itemId,
        viewId: vm.$route.params.viewId
      });
    },
    getIsReadOnly(fieldInfo) {
      const vm = this;
      return !vm.isNew && (!vm.recordData.canEdit || !fieldInfo.canEdit);
    },
    mobileMapFieldInfos(_layout) {
      let _mappedFieldInfos = [], _fieldInfo = undefined;
      _layout.forEach( _item => {
        if ( _item.controlType === 'static') {
          _fieldInfo = this.uiElementInfos.find((info) => info._id === _item._id);
        } else {
          _fieldInfo = this.fieldInfos.find((info) => info._id === _item._id);
          if ( ['singleSelection', 'multipleSelection' ].includes(_fieldInfo.type )) {
            if ( _fieldInfo.properties.fieldType !== 'pulldownMenu' ) _fieldInfo.properties.fieldType = 'pulldownMenu';
          }
        }
        if ( _fieldInfo ) _mappedFieldInfos.push({ ..._fieldInfo, controlType: _item.controlType });
      } )
      return _mappedFieldInfos;
    }, 

    getFieldInfosInRow(row) {
      const vm = this;
      var result = [];
      // console.log(
      //   "getFieldInfosInRow :; vm.uiElementInfos: ",
      //   vm.uiElementInfos.map((info) => info._id).join(", ")
      // );
      for (let i = 0; i < row.length; i++) {
        const loopId = row[i]._id;
        const controlType = row[i].controlType;
        var fieldInfo = null;
        // console.log(
        //   "getFieldInfosInRow :: i=" +
        //     i +
        //     ": loopId=" +
        //     loopId +
        //     ", controlType=" +
        //     controlType
        // );
        if (controlType === "static") {
          fieldInfo = vm.uiElementInfos.find((info) => info._id === loopId);
        } else {
          fieldInfo = vm.fieldInfos.find((info) => info._id === loopId);
        }
        if (fieldInfo) {
          result.push(fieldInfo);
          // console.log("result.length = " + result.length);
        }
      }
      // console.log("getFieldInfosInRow :: result: ", result);
      return result;
    },
    getFieldInfoById(fieldInfos, fieldInfoId) {

      var result = null;
      if (fieldInfos) {
        result = fieldInfos.find((info) => info._id === fieldInfoId);
      }
      return result;
    },
    getFieldInfo(fieldInfoId) {
      const vm = this;
      var result = null;
      if (vm.fieldInfos) {
        result = vm.fieldInfos.find((info) => info._id === fieldInfoId);
      }
      return result;
    },
    _loadAppAndChild() {
      const vm = this;
      // console.log("Record.vue :: _loadAppAndChild");
      const appId = vm.$route.params.id;
      vm.$store.dispatch("FETCH_APP", { appId: appId }).then(() => {
        vm._loadFormAndChild();
      });
    },

    _loadFormAndChild() {
      const vm = this;
      // console.log("Record.vue :: _loadFormAndChild");
      const formId = vm.$route.params.itemId;
      vm.$store
        .dispatch("FETCH_FORM", { formId: formId, appId: this.currentApp._id })
        .then(() => {
          vm._loadData();
        });
    },

    _loadData(forceNew = false) {
      const vm = this;
      const recordId = vm.$route.params.recordId || this.params.recordId;
      vm.init(recordId, {}, forceNew);
      
    },


    getFieldId(fieldInfoId) {
      const vm = this;
      // console.log('Record.vue :: getFieldId :: fieldInfoId = ' + fieldInfoId)
      return vm.fieldInfoId2FieldIdMapping[fieldInfoId];
    },

    getFieldNameByFieldId(fieldId) {
      const vm = this;
      var result = "";
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const info = vm.fieldInfos[i];
        if (info.fieldId === fieldId) {
          result = info.label;
          break;
        }
      }
      return result;
    },
    updateFieldValue(payload){
      const vm = this;
      vm.$set(vm.recordData, payload.fieldName, payload.fieldValue);
  },
    closeDialog() {
      this.showingDialog = false;
    },


    onFocus(evt) {
      evt.target.select();
    },

    onSubmit() {
      const vm = this;
      vm.save(() => {
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
            module_name: this.$store.getters.formLabel,
            func_name: this.$sensors.events.plus_sheet_mani.table_view.SAVE_AND_EXIT,
            }
        });
        EventBus.$emit('hideSpinner')
        vm.returnToFormViewPage();
      });
      return false;
    },

    saveAndNew() {
      const vm = this;
      // console.log("RecordCard2 :: saveAndNew");
      vm.save(() => {
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
            module_name: this.$store.getters.formLabel,
            func_name: this.$sensors.events.plus_sheet_mani.table_view.SAVE_AND_NEW,
            }
        });
        vm._loadData(true);
        const updatedUrl = vm.getNewRecordUrl();
        history.replaceState(null, null, updatedUrl);
      });
    },
    saveAndKeep() {
      const vm = this;
      vm.isSaving = true;
      // console.log("RecordCard2 :: saveAndNew");
      vm.save(() => {
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
            module_name: this.$store.getters.formLabel,
            func_name: this.$sensors.events.plus_sheet_mani.table_view.SAVE_AND_CONTINUE,
            }
        });
        vm.isSaving = false;
        vm.$set(vm.recordData, "_id", "");
        const updatedUrl = vm.getNewRecordUrl();
        history.replaceState(null, null, updatedUrl);
      });

    },

    getNewRecordUrl() {
      const vm = this;
      const appId = vm.$route.params.appId;
      const itemId = vm.$route.params.itemId;
      const viewId = vm.$route.params.viewId;
      const updatedUrl = vm.$router.resolve({
        name: "records",
        params: {
          appId: appId,
          itemId: itemId,
          viewId: viewId,
          recordId: "_",
        },
      }).href;
      return history.replaceState(null, null, updatedUrl);
    },

    returnToFormViewPage() {
      const vm = this;
      // console.log("returnToFormViewPage");
      if (vm.prevRoute && vm.prevRoute.name in ["forms"]) {
        // console.log(
        //   "returnToFormViewPage :: vm.prevRoute.name = " + vm.prevRoute.name
        // );
        vm.$router.go(-1);
      } else {
        // console.log(
        //   "returnToFormViewPage :: vm.appItemRouteName = " + vm.appItemRouteName
        // );
        vm.$router.push({
          name: "forms",
          params: {
            id: vm.currentApp._id,
            itemId: vm.$route.params.itemId, // vm.currentItem._id
          },
        });
      }
    },

    resetValidationAll() {
      const vm = this;
      // alert('resetValidationAll')
      const fieldIds = Object.keys(vm.formData);

      fieldIds.forEach((f) => {
        if (vm.$refs[f]) {
          if (typeof vm.$refs[f][0].resetValidation === "function") {
            // console.log('field Id #' + f + ': has resetValidation function')
            vm.$refs[f][0].resetValidation();
          } else {
            // console.log('field Id #' + f + ': has no resetValidation function')
          }
        }
      });
    },

    // for debug purpose to show which field has error
    getFieldLabel (fieldId) {
      const vm = this
      const fieldInfo = vm.fieldInfos.find(info => info.fieldId === fieldId)
      return fieldInfo ? fieldInfo.label : '(no label, ' + fieldId + ')'
    },


    async validateAll() {
      const vm = this;
      const fieldIds = vm.editableFieldIds;
      var errors = 0;

      let promises = [];
      fieldIds.forEach((f) => {
        try {
          console.log('check field id = ' + f)
        } catch(err) {
          console.log('check field id = ' + f + 'err: ', err)
        }
        if (vm.$refs[f] && vm.$refs[f][0]) {
           promises.push(() => {
            const validationResult = vm.$refs[f][0].validate()
            return validationResult
          })

          // promises.push(() => vm.$refs[f][0].validate());
        } else {
          // console.log('check field id = ' + f + ' has no ref')
        }
      });

      const arrayOfPromises = promises.map((promise) => promise());
      const results = await Promise.all(arrayOfPromises);
      errors = results.filter((result) => !result).length;
      vm.errors = errors;
      return errors;
    },
    async save(callback) {
      const vm = this;
      vm.formHasErrors = false;
      const errors = await vm.validateAll();
      
      if (errors === 0) {
        const updatedData = JSON.parse(JSON.stringify(vm.recordData));
        updatedData["formId"] = vm.currentForm._id;
        updatedData["appId"] = vm.currentApp._id;
        for (const [key, value] of Object.entries(updatedData)) {
          if (typeof value === "string"){
            updatedData[key] = value.trim();
          }
        }

        var otherTableFieldIds = vm.fieldInfos
          .filter(item=>item.type=='otherTableField')
          .map(item=>item.fieldId)

          otherTableFieldIds.forEach(fieldId=>{
          if(Array.isArray(updatedData[fieldId]) && updatedData[fieldId].length){
            updatedData[fieldId] = updatedData[fieldId].map(related=>{
              return (typeof related=='object')? related._id:related 
            })
          }
        })
        
        const postData = {
          data: updatedData,
          isNew: vm.isNew
        }
        EventBus.$emit('showSpinner')
        vm.$store.dispatch("SAVE_DATA", postData).then((response) => {
          EventBus.$emit('hideSpinner')
          vm.showSuccess("messages.savedSuccessfully");
          if (typeof callback === "function") {
            callback(postData, response);
          }
        }).catch(err=>{
          this.$toast.error(this.$t("errors.save"))
          EventBus.$emit('hideSpinner')
        });
        // vm.$emit('onCommand', {
        //   command: 'submitForm',
        //   id: vm.data._id,
        //   data: vm.data
        // })
      } else {
        console.log("save errors exists = " + errors);
      }
      vm.showingDialog = false;
    },


    showTooltipMethod({ type, column, row, items, _columnIndex }) {
      const { property } = column;
      // console.log('Record.vue :: column: ', column)
      // console.log('Record.vue :: row: ', row)
      // console.table([
      //   ['type: ', type],
      //   ['column: ', column],
      //   ['row: ', column],
      //   ['columnIndex', _columnIndex]
      // ])

      // 重写默认的提示内容
      // if (property === 'sex') {
      //   return 'xxxxxxxxxxxxxxx'
      // }
      if (property === "sex" || property === "date") {
        if (type === "header") {
          return column.title ? "自定义标题提示内容：" + column.title : "";
        } else if (type === "footer") {
          return items[_columnIndex]
            ? "自定义表尾提示内容：" + items[_columnIndex]
            : "";
        }
        return row[property] ? "自定义提示内容：" + row[property] : "";
      }
      // 其余的单元格使用默认行为
      return null;
    },

    updateLang(locale) {
      const vm = this;
      // console.log('Record.vue :: updateLang :: locale = ' + locale)
      vm.$locale.change(locale);
      // (val)val=>$locale.change(val)
    },

    onRelatedRecordUpdated() {
      const vm = this;
      // console.log(
      //   "onRelatedRecordUpdated :: updatedRecord.formId = " +
      //     updatedRecord.formId
      // );
      // process updatedRecord
      vm.activeRecord = null;
    },

    openChildRecordDialog(payload) {
      // NOtes:
      //
      // title: vm.fieldName,
      // fieldId: vm.fieldId,
      // rowIndex: newRowIndex,
      // row: newRow,
      // fieldInfos: vm.childTableFieldInfos,
      // onSubmitted: vm.childRecordSubmitted

      this.$refs.childRecordDialog.open(payload);
    },

    newRelatedRecord(payload) {
      let vm = this;
      this.$refs.popupRecordDialog.open(payload, () => {
        // notes: no need to load selection data for v-autocomplete in advance
        // vm.$refs[payload.fieldId][0].$refs.inputWidget.$refs.selectFromDataSource.searchSelections(true);        
        // vm.$refs[payload.fieldId][0].$refs.inputWidget.$refs.selectFromDataSource.loadData();
      } );
    },

    editRelatedRecord(payload) {
      this.$refs.popupRecordDialog.open(payload);
    },

    onCommandHandler(payload) {
      const vm = this;
      console.log("onCommandHandler::payload::!", payload)
      switch (payload.command) {
        case "reloadRelatedRecords":
          vm.reloadRelatedTableData(payload);
          break;
        case "openChildRecordDialog":
          vm.openChildRecordDialog(payload);
          break;
        case "newRelatedRecord":
          vm.newRelatedRecord(payload);
          // alert('onCommandHandler :: newRelatedRecord')
          break;
        case "editRelatedRecord":
          vm.editRelatedRecord(payload);
          break;
        case "updateFieldValue": 
          vm.updateFieldValue(payload);
          break;
        case "addChildRow":
          vm.addChildRow(payload);
          break;
        case "unlinkRelatedRecord":
          vm.unlinkRelatedRecord(payload);
          break;
        case "refreshData":
          break;
        case "submit":
          vm.onSubmit();
          vm.submitCommand = "submit";
          break;
        case "submitAndNew":
          vm.saveAndNew();
          vm.submitCommand = "submitAndNew";
          break;
        case "submitAndKeep":
          vm.saveAndKeep();
          vm.submitCommand = "submitAndKeep";
          break;
        // if (payload.plus) {
        //   vm.saveAndNew()
        // } else {
        //   vm.onSubmit();
        // }
        // break;
        case "submitAndRefresh":
          vm.submitAndRefresh();
          vm.submitCommand = "submitAndRefresh";
          break;
        case "delete":
          break;
        case 'print':
          this.print(payload)
          break;
        case "back":
          vm.returnToFormViewPage();
          break;
        case "memberInputClick":
          if(!this.showingMembersMenu){
            vm.openMembersMenu(payload.attachedEle, payload.fieldInfo)
          }
        break;
        case "openBatchInsertDialog":
          this.batchInsertSettings = payload.data
          this.showingBatchInsertDialog = true
        break;
        case "batchInsert":
          var updateFieldComponent = this.$refs[payload.fieldId][0].$refs.inputWidget || this.$refs[payload.fieldId][0]
          this.$set(this.recordData, payload.fieldId, (this.recordData[payload.fieldId]||[]).concat(payload.newSelection))
          updateFieldComponent.onSaved(payload.dataSource, payload.newSelection, null)
        break;
        case "updateforceCopyRelatedRecords":
          var updateFieldComponent = this.$refs[payload.fieldId][0].$refs.inputWidget
          updateFieldComponent.onSaved(payload.dataSource, updateFieldComponent.inputValue, null, false)
          updateFieldComponent.onSaved(payload.dataSource, payload.newSelection, null)
        break
      }
    },

    unlinkRelatedRecord(payload) {
      const vm = this;
      // Notes:
      // payload: {
      //    data: {remote data record},
      //    fieldId: {local field id}
      // }
      const childData = payload.data;
      // console.log("unlinkRelatedRecord: child data id = " + childData._id);
      const fieldId = payload.fieldId;
      if (vm.recordData[fieldId] instanceof Array) {
        // console.log(
        //   "unlinkRelatedRecord :: local field with ids: " +
        //     vm.recordData[fieldId].join(",")
        // );
        const idIndex = vm.recordData[fieldId].findIndex(
          (id) => id === childData._id
        );
        if (idIndex >= 0) {
          vm.recordData[fieldId].splice(idIndex, 1);
        }
        // vm.recordData[fieldId].push(payload.row);
      } else {
        vm.recordData[fieldId] = [];
      }
    },

    addChildRow(payload) {
      const vm = this;
      const fieldInfoId = payload.fieldInfoId;
      const fieldId = vm.getFieldId(fieldInfoId);
      if (vm.recordData[fieldId] instanceof Array) {
        vm.recordData[fieldId].push(payload.row);
      } else {
        vm.recordData[fieldId] = [payload.row];
      }
    },
    handleActionVisible(fields, bl_pass){
      let vm = this
      fields.forEach(fieldId=>{
        var res = vm.fieldInfos.concat(vm.uiElementInfos).find(field=> field._id == fieldId)
        if (res){
          var authorization = []
          if(res.properties.authorization)
            authorization = res.properties.authorization.split("||")
          if(authorization.length == 1 && authorization[0] == "") authorization = []
          if(bl_pass){
            var pos = authorization.indexOf('hidden')
            if(pos!=-1){
              authorization.splice(pos,1)
            }
          }else{
            if(!authorization.includes('hidden'))
              authorization.push('hidden')
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, 'authorization', authorization.join("||"))
        }
      })
    },
    handleActionHidden(fields, bl_pass){
      let vm = this
      fields.forEach(fieldId=>{
        var res = vm.fieldInfos.concat(vm.uiElementInfos).find(field=> field._id == fieldId)
        if (res){
          var authorization = []
          if(res.properties.authorization)
            authorization = res.properties.authorization.split("||")
          // var authorization = res.properties.authorization.split("||")
          if(authorization.length == 1 && authorization[0] == "") authorization = []
          if(bl_pass){
            if(!authorization.includes('hidden'))
              authorization.push('hidden')
          }else{
            var pos = authorization.indexOf('hidden')
            if(pos!=-1){
              authorization.splice(pos,1)
            }
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, 'authorization', authorization.join("||"))
        }
      })
    },
    handleActionAlert(fields){
      console.log('handleActionAlert')
      console.log(fields)
    },
    async confirmDelete() {
      this.showConfirmDialog = false;
      // this.isLoading = true;
      await this.$store.dispatch('DELETE_DATA', {
        appId: this.appId, formId: this.formId, recordId: this.recordData._id
      });
      // this.isLoading = false;
      const url = `/apps/${this.appId}/forms/${this.form._id}/${ this.viewId }`;
      this.$router.push( url )
    }, 
    editRecord() {
      this.$store.dispatch('SET_RECORD_PAGE_MODE', 'edit');
      this.$store.dispatch('TOGGLE_RECORD_ACTION_DRAWER');
    },
    removeRecord() {
      // show confirm dialog
      this.$store.dispatch('TOGGLE_RECORD_ACTION_DRAWER')
      this.showConfirmDialog = true;
    }, 
    showCustomActionDraw() {
      this.$store.dispatch('TOGGLE_CUSTOM_ACTION_DRAWER');
    },
    formatTitledField(val) {
      if ( Array.isArray(val) ) val = val[0];
      if (val &&  typeof val === 'object' && 'label' in val ) val = val.label 
      if ( val && typeof val === 'object' && 'employeeName' in val ) val = val.employeeName 
      return val;
    },
    submitAndRefresh(){
      const vm = this;
      vm.isSaving = true;
      vm.save((postData, response) => {
        EventBus.$emit('hideSpinner')
        const { href } = vm.$router.resolve(vm.recordData._id); 
        window.history.pushState({}, null, href);
        vm.saveCount++
        vm.recordData = response
      });
    }
  },

  beforeDestroy () {
    this.retainSummaryField = false;
    EventBus.$off('newRelatedRecordInChildTableView')
    EventBus.$off('newRelatedRecordInRelationTableView')
  },
  mounted() {
    const vm = this;
    EventBus.$on('newRelatedRecordInChildTableView', this.newRelatedRecord)
    EventBus.$on('newRelatedRecordInRelationTableView', this.newRelatedRecord)
    vm.loading = true;
    vm.isNew = vm.$route.params.recordId === "_";
    vm.params = vm.$route.params;
    this.initialData();
    this.$nextTick(() => {
      // scroll to top
      const _main = document.querySelector('.v-main');
      if (_main) _main.scrollTop = 0;
    })
  },

  watch: {

    "$route.params.appId": function(newVal, oldVal) {
      // console.log("Record.vue :: watch($route.params.appId");
      const vm = this;
      vm.retainSummaryField = false;
      if (newVal !== oldVal) {
        vm._loadAppAndChild();
      }
    },
    "$route.params.itemId": function() {
      const vm = this;

      vm.retainSummaryField = false;
      // console.log(
      //   "Record.vue :: Record.vue :: watch($route.params.ItemId): newVal = " +
      //     newVal
      // );
      // alert('=> commit(setCurrentMenuItemId)')
      // vm.$store.commit('setCurrentMenuItemId', newVal)
      vm._loadFormAndChild();
    },
    recordData:{
      handler(val){
        // console.log(this.recordData)
        let vm = this
        vm.retainSummaryField = false;
        if(this.displayRule)
          this.displayRule.filter(item=>item.active).forEach(rule=>{
            let bl_pass = false
            var conditions = rule.conditions
            // check conditions
            bl_pass = conditions.some(orConds=>{
              var res = orConds.every(andCond=>{
                var hostField = vm.recordData[andCond.fieldId]
                var compareField = (andCond.content.type==1)? andCond.content.value:vm.recordData[andCond.content.value]
                // console.log(ValidateRule(hostField, compareField, andCond.content.rule, andCond.fieldType))            
                return ValidateRule(hostField, compareField, andCond.content.rule, andCond.fieldType)
              })
              if(res) return true
            })
            // action implement if rule return true
            rule.actions.forEach(action=>{
              switch(action.actionId){
                case "1":
                  vm.handleActionVisible(action.fields, bl_pass)
                  break 
                case "2":
                  vm.handleActionHidden(action.fields, bl_pass)
                  break
              }
            })
          })
        if ( this.isMobile ) {
          this.$store.dispatch('SET_FORM_TITLED_VALUE', this.formatTitledField(this.recordData[this.form.titleFieldInfoId]))
        }
      },
      deep: true
    },
    mobileMode: function (newVal, oldVal) {
      if ( oldVal === 'view' && newVal === 'edit' ) {
        this.oldRecordData = { ...this.recordData }
      } else if ( oldVal === 'edit' && newVal === 'view' ) {
        this.recordData = { ...this.oldRecordData }
      }
    }
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

$blockquote-font-size: 24px;
$grey: #9e9e9e;
.headline {
  font-size: $blockquote-font-size;
  color: $grey;
}

.drawer-container {
  width: 540px;
  position: relative;
}

.no-radius {
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}

.tab-title {
  font-size: 12px;
}
/*.record .col {*/
/*padding-top: 0;*/
/*padding-bottom: 0;*/
/*}*/

.record .slide-fade-enter,
.record .slide-fade-leave-to {
  width: 0 !important;
  // transition: transitionX(-540px) !important;
}
</style>
