<template>
  <div />
</template>

<script>
  import {
    HIDE_POPUP_ALERT,
  } from '@/store/modules/notification/action_types';
  export default {
    methods: {
      showPopup() {
        const instance = this.$toast.open({
          message: this.info?.translate ? this.$t(this.info.translate.content, this.info.translate.param) : `${this.type}: ${this.info.content}`,
          type: this.info?.type || 'info',
          duration: this.interval,
        });
        setTimeout(() => {
          this.$store.dispatch(HIDE_POPUP_ALERT);
          instance.dismiss();
        }, this.interval + 500);
      },
    },
    data() {
      return {
        interval: 6000,
      };
    },
    computed: {
      type() {
        const { pushType } = this.info || {};
        const lookup = {
          "workflowInsiteMessage": 'workflow'
        };
        return this.$t(`notification.panel.tabs.${lookup[pushType]}`);
      },
      show() {
        return this.$store.getters.isOpenNotificationPopupAlert;
      },
      info() {
        return this.$store.getters.getNotificationInfo;
      },
    },
    watch: {
      show: function(toShow) {
        if (toShow) {
          this.showPopup();
        }
      }
    }
  }
</script>
