<template>
  <loading-icon v-if="loading" />
  <div v-else class="comment-drawer fill-height d-flex flex-column">
    <perfect-scrollbar @ps-y-reach-end="loadMore">
      <div>
        <commentRow
          v-for="(item, index) in items"
          :item="item"
          :key="index"
          @delete="deleteComment"
          @onCommand="onCommandHandler"
        />
      </div>
    </perfect-scrollbar>
    <div class="d-flex flex-column comment-input">
      <v-row no-gutters class="pa-2">
        <v-col cols="1" class="avatar">
          <v-avatar :color="avatarColor" size="38"
            ><span :class="avatarClass">{{ avatarName }}</span></v-avatar
          >
        </v-col>
        <v-col cols="11">
          <comment-input v-model="comment" @onCommand="onCommandHandler" />
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-0">
        <v-col offset="1" cols="11">
          <file-table :files="files" @onCommand="onCommandHandler" />
        </v-col>
      </v-row>
      <v-row class="mt-0 pb-2 pr-2" justify="end" no-gutters>
        <v-btn @click="submitComment" :disabled="submitting">{{
          $t("buttons.submit")
        }}</v-btn>
      </v-row>
    </div>
  </div>
</template>
<script>
import commentRow from "./commentRow";
import commentInput from "./commentInput";
import FileTable from "../attachment/fileTable";
import LoadingIcon from "@/components/global/loadingIcon";

import mixin from "../mixin.js";
import CommentMixin from "./commentMixin.js";
import uploadMixin from "../uploadMixin.js";


export default {
  mixins: [mixin, CommentMixin, uploadMixin],
  components: {
    commentRow,
    commentInput,
    LoadingIcon,
    FileTable,
  },
  data() {
    return {
      comment: "",
      files: [],
      submitting: false,
      showEmoji: false,
      cursorPosition: 0,
    };
  },
  computed: {
    avatarName() {
      let firstName = this.$store.getters.user.firstName;
      let lastName = this.$store.getters.user.lastName;
      return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    },
    avatarColor() {
      return this.$store.getters.currentApp.color;
    },
    avatarClass() {
      return {
        headline: true,
        "white--text": this.$store.getters.currentApp.textTheme == "dark",
        "black--text": this.$store.getters.currentApp.textTheme == "light",
      };
    },
  },
  async mounted() {
    this.loading = true;
    await this.getComments();
    this.loading = false;
  },
  methods: {
    loadMore() {
      if (this.hasNextPage) {
        this.offset += this.limit;
        this.getComments();
      }
    },
    onFilesUploaded(payload) {
      const vm = this;
      vm.files = [];
      for (let i = 0; i < payload.mediaIds.length; i++) {
        vm.files.push({
          _id: payload.mediaIds[i],
          mediaType: payload.mediaTypes[i],
          objectKey: payload.objectKeys[i],
          originalName: payload.originalNames[i],
          thumbnailUrl: payload.thumbnailUrls[i],
          createdAt: new Date()
        });
      }
    },
    onFileRemoved(file) {
      const index = this.files.findIndex(
        (item) => item.mediaId === file.mediaId
      );
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },

    updateComment(comment) {
      const putData = {
        urlCommand: "/comment/" + comment._id,
        data: comment,
      };
      this.$store.dispatch("AUTH_PUT", putData).then((response) => {
        console.log("put comment: response: ", response);
      });
    },

    onCommentFileRemoved(commentId, file) {
      const index = this.items.findIndex((item) => item._id === commentId);
      if (index > -1) {
        const mediaIndex = this.items[index].medias.findIndex(
          (media) => media._id === file._id
        );
        if (mediaIndex > -1) {
          this.items[index].medias.splice(mediaIndex, 1);
          this.updateComment(this.items[index]);
        }
      }
    },

    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "uploadFiles": {
          vm.uploadFiles(payload.files, vm.onFilesUploaded);
          break;
        }
        case "removeAttachmentFile": {
          vm.removeAttachmentFile(payload.file, vm.onFileRemoved);
          break;
        }
        case "removeAttachmentInComment": {
          vm.removeAttachmentFile(payload.file, (file) => {
            vm.onCommentFileRemoved(payload.commentId, file);
          });

          break;
        }
        case "submitReply": {
          vm.submitReply(payload);
        }
      }
    },

    async getComments() {
      const urlCommand = `/comment?parentId=${this.parentId}&appId=${this.appId}&limit=${this.limit}&offset=${this.offset}`;
      const getParams = {
        urlCommand,
      };
      const results = await this.$store.dispatch("AUTH_GET", getParams);

      this.items = this.items.concat(results.docs);

      this.resetRelativeTime();
      this.hasNextPage = results.hasNextPage;
    },
    async submitComment() {
      this.submitting = true;

      let content = this.comment;
      if (!content && this.files.length > 0) {
        const mediaNames = this.files
          .map((media) => media.originalName)
          .join(",");
        content = this.$t("drawer.uploadAttachment", { target: mediaNames });
      }
      const data = {
        content: content,
        medias: [...this.files],
      };
      // const data = {
      //   content: content,
      //   mediaIds: this.files.map(file => file._id)
      // };

      await this.submit(data);
      this.submitting = false;
    },
    async submitReply(payload) {
      const data = {
        content: payload.content,
        replyId: payload.replyId,
        medias: payload.medias,
      };
      await this.submit(data);
      payload.callback();
    },
    async submit(data) {
      if (!data.content && data.mediaIds.length === 0) return;
      const postData = {
        urlCommand: "/comment",
        data: {
          ...data,
          appId: this.appId,
          parentId: this.parentId,
        },
      };

      const result = await this.$store.dispatch("AUTH_POST", postData);

      if (result) {
        this.items.unshift(result);
        this.resetRelativeTime();
        this.comment = "";
        this.files = [];
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
          key: "plus_sheet_mani",
          data: {
            module_name: this.$store.getters.formLabel,
            func_name: this.$sensors.events.plus_sheet_mani.discussion.CREATE_COMMENT,
          }
        });
      }
    },
    deleteComment(id) {
      this.loading = true;

      const postData = {
        urlCommand: "/comment/deleteComment",
        data: {
          commentId: id,
          appId: this.appId,
        },
      };

      this.$store
        .dispatch("AUTH_POST", postData)
        .then((result) => {
          if (result.deleted == true) {
            this.removeCommentFromArr(id);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.discussion.DELETE_COMMENT,
            }
          });
          this.loading = false;
        });
    },
    removeCommentFromArr(id) {
      const index = this.items.findIndex((item) => item._id === id);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    },
  },
};
</script>
<style scoped>
.avatar {
  text-align: center;
}
</style>
<style lang="scss">
.comment-drawer {
  .ps,
  .wrapper {
    height: 100%;
  }
  .comment-input {
    border-top: 1px solid #9e9e9e;
  }
}
</style>
