<template>
  <router-view />
</template>
<script>
import { yoovWork } from "@/store/action-types";
export default {
  name: "workAdminPage",
  mounted() {
    if (!this.$store.getters.canAccessWorkAdminPage) {
      this.$toast.open({
        message: this.$t("workAdmin.unauthorized"),
        type: "error",
      });
      this.$router.push("/my_apps");
    }
  },
  beforeDestroy(){
      this.$store.dispatch("AUTH_POST", {
        urlCommand:"/users/sync"
      });
      this.$store.dispatch(yoovWork.SET_YOOVWORK_EMPLOYEES, []);
  },
  methods: {},
};
</script>
