import { findIndex, map, uniqBy, uniq, concat, find, chain, get, replace, includes, forEach, omit, isArray } from 'lodash';
import {
    DelegatedDateFieldsSelection,
    DelegatedRelationshipFieldsSelection,
} from '@/pages/admin/workflow/constants/fields';

import WorkflowRoutesName from '@/const/workflow/routes';
import WorkflowNodeUtils from '@/pages/admin/workflow/utils/node';

/*
* Tree Node related untilities helper
* Terry Chan
* 12/05/2021
*/
class SettingUtils {
    constructor() {
        this.regexLookup = {
            noMathSymbol: /[-\/\\^$*+?.()|[\]{}]/g,
            dynamicId: /\$\{\w{24}\_\w{24}\}/g,
            pureDynamicdId: /\$\{\w{24}\_\w{24}\}|\$\{\w{24}\}/g,
            // remove ${} symbol to be a pure object id
            dynamicPureId: /\$\{(.*)\}[mhdMY]{0,}/,
        }
        this.delegatedLookup = [
            'CURRENTDATE',
            'WORKFLOWTRIGGER',
            'WORKFLOWCREATOR',
        ];
    }

    editTree(vue, params, workflowId, tabIndex='view') {
        // vue.$store.commit('savePrevRoute', vue.$route);
        vue.$router.replace({
          name: WorkflowRoutesName.formWorkflowsTree,
          params: {
            ...params,
            workflowId,
            tabIndex,
          },
        });
    }

    userPopupDisplayName({ type='employee', ...rest }) {
        switch(type) {
          case 'employee':
            return rest.employeeName;
          default: 
            return rest.name;
        }
    }
    userPopupDisplayNameType({ type='employee' }, locale) {
        switch(type) {
          case 'jobTitle':
            return locale.theJobTitle;
          case 'team':
            return locale.theCompany;
            case 'department':
            return locale.theDepartment;
          default:
            return locale.theEmployee;
        }
    }
    userPopupDisplayIcon({ type='employee', avatar }) {
        switch(type) {
          case 'jobTitle':
            return 'mdi-briefcase';
          case 'team':
            return 'mdi-domain';
          case 'department':
            return 'mdi-account-group';
          default: 
            return 'mdi-account';
        }
    }
    selectFromPickingListByIndex(sourceList=[], selectedList=[], field='_id', comparer) {
        const target = find(sourceList, i => comparer(i));
        if(target) {
            const beingSelected = find(selectedList, 
                i => i[field] === target[field]
            ); 
            if (!beingSelected) {
                return target;
            }
        }
        return null;
    }


    delegatedComboLabel(item, locale) {
        if (item.isSystem) {
            // TODO: revamp this checking
            if (includes([
                'CURRENTDATE',
                'SYSTEM_',
            ], item.id)) {
                return locale.modules.delegated.label.system;
            } else if (includes([
                'OPERATION_',
                'REMOVERELATION',
            ], item.id)) {
                return locale.modules.delegated.label.operation;
            }
            return locale.modules.delegated.label.parameter;
        }
    }

    delegatedComboSelectionLabel(item, locale) {
        const language = get(locale.widgets, item.fieldId);
        return language ? language : item.label;
    }

    goBlankWindowWorkflow(router, workflow) {
        const { current: { params } } = router.history;
        params.workflowId = workflow;
        const routeData = router.resolve({
          name: WorkflowRoutesName.formWorkflowsTree, 
          params,
        });
        // console.log(window.location.origin, routeData.href);
        window.open(`${window.location.origin}${routeData.href}`, '_blank');
    }

    withoutMathSymbol(value, replacement) {
        return replace(value, this.regexLookup.noMathSymbol, replacement);
    }
    expressionLabelDisplay(field, locale) {
        // console.log(field.name);
        let label = field ? field.label || field.name : '????'
        label = label.replaceAll('workflow.', '');
        return get(locale, label) || label;
    }
    matchingExpression(fields=[], node, expression='', dynamicIds, locale={}) {
        const { regexLookup, delegatedLookup } = this;
        let fullExpression = expression;
        if (dynamicIds && dynamicIds.length) {
            let pureId;
            let fieldFound;
            forEach(dynamicIds, ids => {
                pureId = ids.match(regexLookup.dynamicPureId);
                if (pureId && pureId.length > 1) {
                    let settingId = pureId[1];
                    settingId = String(settingId).split('_');
                    if (settingId.length > 1) {
                        // TODO: revamp
                        forEach(fields, ({ fieldInfos }) => {
                            const idx = findIndex(fieldInfos, ({ _id }) => _id === settingId[1]);
                            // console.log(idx);
                            if (idx !== -1) {
                                fieldFound = fieldInfos[idx];
                                return;
                            }
                        });
                        // real time add field, just use the original fields list label
                        if (!fieldFound) {
                            forEach(node.properties.fields, field => {
                                // console.log(idx);
                                if (field.fieldId === settingId[1]) {
                                    fieldFound = field;
                                    return;
                                }
                            });
                        }
                    } else if (settingId.length === 1) {
                        fieldFound = find(fields, ({ _id }) => _id === settingId[0]);
                    }
                    // if (fieldFound) {
                    fullExpression = replace(
                        fullExpression,
                        new RegExp(this.withoutMathSymbol(pureId[0], '\\$&'), 'g'),
                        `[${this.expressionLabelDisplay(fieldFound, locale)}]`,
                    );
                }
            });
        }
        forEach(delegatedLookup, lookup => {
            const expr = `\$\{${lookup}\}`;
            const exists = includes(fullExpression, expr);
            if (exists) {
                fullExpression = replace(
                    fullExpression,
                    new RegExp(this.withoutMathSymbol(expr, '\\$&'), 'g'),
                    `[${locale.widgets[lookup]}]`,
                );
            }
        })
        return fullExpression;
    }
    expressionContractFields(fields=[], expression='') {
        const { regexLookup } = this;
        const dynamicIds = expression.match(regexLookup.pureDynamicdId);
        let fullFields = [...fields];
        forEach(fields, (field, index) => {
            const exists = includes(dynamicIds, `\$\{${field.id}\}`);
            if (!exists) {
                fullFields.splice(index, 1);
            }
        });
        return uniqBy(fullFields, f => f._id);
    }
        
    normalizeFormulaExpression({
        fields=[], node, expression='', locale,
    }) {
        const { regexLookup } = this;
        let dynamicIds = expression.match(regexLookup.dynamicId);
        let fullExpression = this.matchingExpression(fields, node, expression, dynamicIds, locale);

        dynamicIds = fullExpression.match(regexLookup.pureDynamicdId);
        if (dynamicIds && dynamicIds.length) {
            fullExpression = this.matchingExpression(fields, node, fullExpression, dynamicIds, locale);
        }
        return fullExpression;
    }
    normalizeFormulaFields(fields=[], expression='') {
        const expressionFields = [ ...fields ];
        // const { regexLookup } = this;
        // const dynamicIds = expression.match(regexLookup.dynamicId);
        let found = false;
        forEach(fields, (field, index) => {
            found = expression.includes(field.id);
            if (!found) {
                expressionFields.splice(index, 1);
            }
        })
        // if (dynamicIds.length) {
        //     let pureId;
        //     let fieldIdx;
        //     forEach(dynamicIds, ids => {
        //         pureId = ids.match(regexLookup.dynamicPureId);
        //         if (pureId.length > 1) {
        //             pureId = pureId[1];
        //             fieldIdx = findIndex(fields, ({ id }) => id === pureId);
        //             if (fieldIdx !== -1) {
        //                 fields.splice(fieldIdx, 1);
        //             }
        //         }
        //     });
        // }
        return expressionFields;
    }
    getCheckingError (errors, currentField) {
        return find(errors, ({ field }) => field === currentField)
    }
    comboBoxDataSourceProperties(dataSource) {
      if (dataSource) {
        const optionOutput = dataSource.output || 
            (dataSource.properties && dataSource.properties.outputMultiple);
        return {
          optionOutput,
          dependsOnSource: dataSource._id,
        };
      }
      return {};
    }
    systemFields(fieldInfo={}) {
        const lookup = [
            'createdAt', 'updatedAt'
        ];
        return includes(lookup, fieldInfo.type);
    }
    relationshipFields(fieldInfo={}) {
        const lookup = [
            // 'owner', 'createdBy', 'createdAt', 
            'relatedRecord', 'childTable', 'relatedMultipleRecords', 'parentChildRelation', 'otherTableField'
        ];
        return includes(lookup, fieldInfo.fieldType || fieldInfo.type);
    }
    notSupportDynamicReferenceFields(fieldInfo={}) {
        const lookup = [
            // 'owner', 'createdBy', 'createdAt', 
            'autoNumbering'
        ];
        return includes(lookup, fieldInfo.fieldType || fieldInfo.type);
    }
    isWorkflowBeingReleased = (workflow={}) => {
        return workflow && workflow.beingReleased && !!workflow.releasedAt;
    }
    canMultipleDependencies(fieldInfo={}) {
        const lookup = [
            'text',
            'email',
            'multipleSelection',
            'textCombination',
            'richText',
        ];
        return includes(lookup, fieldInfo.type);
    }
    normalizeHiddenFields(infos) {
        const shouldHideLookup = [
            'textCombination',
            'summaryField',
            'capitalizedAmount',
        ];
        
        return map(infos, c => {
            const shouldHide = includes(shouldHideLookup, c.fieldType || c.type);
            return {
                ...c,
                canCreate: true,
                canEdit: true,
                canView: true,
                properties: {
                    ...c.properties,
                    shouldHide,
                    authorization: shouldHide ? 'hidden' : '',  // show the hidden fields
                },
            };
        });
        // return infos;
    }
    settingSortingFields(type){
        const lookup = [
            'singleSelection',
        ];
        return includes(lookup, type);
    }
    stringSortingFields(type) {
        const lookup = [
            'text',
            'email',
            'phone',
            'yesNo',
            'textCombination',
            'autoNumbering',
            'richText',
            'idCard',
            'capitalizedAmount'
        ];
        return includes(lookup, type);
    }
    numericSortingFields(type) {
        const lookup = [
            'number',
            'amount',
            'rating',
            'autoNumbering',
        ];
        return includes(lookup, type);
    }
    // getFieldInfosInRow(uiElementInfos, fieldInfos, row) {
    //   const result = [];
    //   forEach(row, r => {
    //     const loopId = r._id;
    //     const controlType = r.controlType;
    //     let fieldInfo = null;
    //     if (controlType === "static") {
    //       fieldInfo = find(uiElementInfos, info => info._id === loopId);
    //     } else {
    //       fieldInfo = find(fieldInfos, info => info._id === loopId);
    //     }
    //     if (fieldInfo) {
    //       result.push(fieldInfo);
    //     }
    //   });
    //   return result;
    // }
    retainChangesFields(data={}, payload) {
        const { fieldName } = payload;
        return uniq(
            concat([], data.changes || [], fieldName),
        );
    }
    delegatedOptionWithDependsOn({ locale, list=[], dependsOn, ...options }) {
        let workflowOutputs = list;
        if (locale) {
            workflowOutputs = this.getFullyDependenciesList(workflowOutputs, locale, options);
        }
        return WorkflowNodeUtils.withAcceptanceOptions(
            { dependsOnType: dependsOn, list: workflowOutputs }
        );
        // return workflowOutputs;
    }
    /*
    ** Combines the workflow output list with all of delegated list
    ** Terry Chan
    ** 22/06/2021
    */
    translateTheDelegatedList(delegatedList, translator, options={}) {
        const { addOnInfo={} } = options;
        return map(delegatedList, d => ({
            ...d,
            label: get(translator, d.label),
            children: map(d.children, c => ({ ...c, label: get(translator, c.label), ...addOnInfo })),
            ...addOnInfo,
        }))
    }
    isObjectDateType(fieldType){
        return includes([
          'createdAt',
          'updatedAt',
        ], fieldType);
    }
    getFullyDependenciesList(dependencies, translator, options={}) {
        const { withOperation, exclusiveDelegated } = options;
        let list = [];
        if (dependencies && !isArray(dependencies)) {
            list = [ dependencies ];
        } else if (dependencies) {
            list = dependencies;
        }
        let delegated = [];
        if (!exclusiveDelegated) {
            delegated = this.translateTheDelegatedList(
                DelegatedDateFieldsSelection,
                translator,
            );
        }
        let operationDelegated = [];
        if (withOperation) {
            operationDelegated = this.translateTheDelegatedList(
                DelegatedRelationshipFieldsSelection,
                translator,
                options,
            );
        }
        // console.log('>>> ', [ ...list, ...delegated, ...operationDelegated ]);
        return [ ...list, ...delegated, ...operationDelegated ];
    }
    // return the target filtering component
    getFilteringFieldTypeComponent(type){
        const lookup = {
            number: 'number',
            amount: 'number',
            expression: 'number',
            capitalizedAmount: 'number',
            rating: 'number',
            summaryField: 'number',

            childTable: 'childTable',
            relatedRecord: 'relatedRecord',
            otherTableField: 'otherTableField',

            date: 'date',
            createdAt: 'date',
            updatedAt: 'date',

            singleSelection: 'selection',
            multipleSelection: 'selection',

            members: 'members',
            createdBy: 'members',
            owner: 'members',

            departments: 'departments',

            attachments: 'radio',
            signature: 'radio',
            yesNo: 'radio',
            freeLink: 'radio',

            phone:"phone"

                    
        };
        return lookup[type] || 'text';
    }
    removeOptionSelected(target, field) {
        let gate;
        target[field] = chain(target[field]).map(row => {
            gate = chain(row).map(col => omit(col, ['selected'])).compact().value();
            if (gate.length < 1) {
                return null;
            }
            return gate;
        }).compact().value();
    }
    getFormatedFormFieldInfos(info) {
        return chain(info).map(v => ({
            type: 'dataset',
            id: v._id,
            value: v,
            label: v.label,
            fieldType: v.type,
        })).value(); 
        // return this.formFieldInfos;
        // this.filterInfo.options.filter(opt => opt !== 'toggle')
    }
    // TODO: local cache?
    getFieldRules(properties, info, fieldId, fieldKey, isVirtual) {
        let type = find(properties, item => item.id === fieldId || item.id === fieldKey);
        const lookup = {
            date: 'D',
            text: 'T',
            number: 'N',
            members: 'M',
            departments: 'D',
        };
        if (!type) {
            if (!!fieldKey && lookup[fieldKey]) {
                type = {
                    filterType: lookup[fieldKey],
                };
            }
            if (!type) {
                return { options: [], buttons: [] };
            }
        }
        return {
            buttons: info[type.filterType].buttons,
            options: chain(info[type.filterType]).get('options').map(v => ({
                type: 'label',
                id: v,
                value: v,
                label: v,
            })).value(),
        };
    }

    useDelegated(type) {
        const lookup = {
          text: 'DelegatedWidget',  // use the original form widget to present it
          number: 'DelegatedWidget',
          amount: 'DelegatedWidget',
          email: 'DelegatedWidget',
          date: 'DelegatedWidget',
          phone: 'DelegatedWidget',
          singleSelection: 'DelegatedWidget',
          multipleSelection: 'DelegatedWidget',
          attachments: 'DelegatedWidget',
          region: 'DelegatedWidget',
          location: 'DelegatedWidget',
          expression: 'InputWidget',
          yesNo: 'DelegatedWidget',
          rating: 'DelegatedWidget',
          richText: 'DelegatedWidget',
          idCard: 'DelegatedWidget',
          members: 'DelegatedWidget',
          departments: 'DelegatedWidget',
          signature: 'DelegatedWidget',
          freeLink: 'DelegatedWidget',
          relatedRecord: 'RelationshipWidget',  // use customized dropdown with workflow dependencies output handling widget
          relatedMultipleRecords: 'RelationshipWidget',
          parentChildRelation: 'RelationshipWidget',
          childTable: 'RelationshipWidget',
          otherTableField: 'RelationshipWidget',
          autoNumbering: 'DelegatedWidget',
          textCombination: 'InputWidget',
          cascadeSelection: 'RelationshipWidget',
          capitalizedAmount: 'InputWidget',
          summaryField: 'DelegatedWidget',
          owner: 'MembersWidget',
          createdBy: 'MembersWidget',
          createdAt: 'DateWidget',
        };
        return lookup[type];
    }
}

export default new SettingUtils();
