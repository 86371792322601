const mixin = {
  data () {
    return {
      showing: false,
      tempValue: ''
    }
  },
  props: {
    value: String,
    isLayout: Boolean,
    isReadOnly: Boolean,
    fieldInfo: Object,
    allowedDays: {
      type: Array,
      default: ['0', '1', '2', '3', '4', '5', '6']
    },
    allowDateBack:{
      type: Boolean,
      default: true
    }
},
  computed: {
    defaultType () {
      return this.getFieldPropertyValue('defaultType', 'auto')
    },
    userValue: {
      get () {
        const vm = this
        return vm.value
      },
      set (val) {
        const vm = this
        vm.$emit('input',val.trim())
      }
    },
    dialogValue: {
      get () {
        const vm = this
        if (vm.tempValue === '') {
          return vm.userValue || '';
        } else {
          return vm.tempValue || '';
        }
      },
      set (val) {
        const vm = this
        vm.tempValue = val
      }
    },
    dateMin(){
      return !this.allowDateBack?
              new Date().toISOString().substr(0, 10):
              undefined
    },
    tabindex(){
      return this.allowDateBack? 0:-1
    }
  },
  methods: {
    getFieldPropertyValue (propertyKey, defaultValue) {
      const vm = this
      var result = defaultValue
      if (vm.fieldInfo) {
        if (vm.fieldInfo.properties && vm.fieldInfo.properties[propertyKey]) {
          result = vm.fieldInfo.properties[propertyKey]
        }
      }
      return result
    },
    close () {
      this.tempValue = ''
      this.showing = false
    },
    confirm () {
      const vm = this
      if (typeof vm._confirm === 'function') {
        vm._confirm()
      }
      vm.close()
    },
    showDialog () {
      if (!this.isReadOnly)
        this.showing = true
    },
    formatTime () {
      var dt = new Date(),
        hour = '' + dt.getHours(),
        min = '' + dt.getMinutes()
      if (hour.length < 2) hour = '0' + hour
      if (min.length < 2) min = '0' + min
      return hour + ':' + min
    },
    formatDate () {
      var dt = new Date(),
        month = '' + (dt.getMonth() + 1),
        day = '' + dt.getDate(),
        year = dt.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    allowedDates(val) {
      return this.allowedDays.includes(new Date(val).getDay().toString())
    },
    handleFieldClick(){
      if(!this.allowDateBack)
        this.showDialog()
    },
  }
}

export default mixin
