<template>
  <v-dialog v-model="showingDialog" width="600" persistent>
      
    <template v-slot:activator="{ on, attrs }">
      <v-slot name="activator" :on="on" :attrs="attrs">
        <v-input v-on="on" v-bind="attrs" >
          <div
            role="button"
            class="pa-1 v-text-field--outlined grey--text w-100 mb-0 workflow-customized-form-dept-element"
            style="background-color:white"
          >{{placeholder}}
          </div>

        </v-input>
      </v-slot>
    </template>
    <v-card v-if="showingDialog">
        <dialog-header @close="showingDialog=false" :label="$t('widgets.properties.phoneCountrySelection')"/>

      <v-card-text class="py-2 px-5">
          <country-code-select 
            :items="options"
            clearable
            small-chips
            item-text="name"
            v-model="inputValue"
            multiple
          />

      </v-card-text>
              <dialog-actions @onCommand="onCommandHandler"/>
    </v-card>
  </v-dialog>
</template>
<script>
import CountryCodeSelect from "@/components/form/CountryCodeSelect"
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";

export default {
    components: {
        CountryCodeSelect,
        dialogHeader,
        dialogActions
    },
  props: {
    options: Array,
    value: Array,
    placeholder: String
  },
  data() {
    return {
      inputValue: [...this.value],
      showingDialog:false
    };
  },
  watch:{
    showingDialog: function(val){
      if (val) this.inputValue = [...this.value]
    }
  },
  methods: {
  onCommandHandler(payload){
      switch(payload.command){
          case "ok":
              this.$emit('confirm', this.inputValue);
              this.showingDialog=false
              break;
        case "cancel":
           this.showingDialog=false
            break;
      }
  }
  }

};
</script>
