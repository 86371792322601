<template>
  <div class="fs-text text-no-wrap overflow-hidden" style="text-overflow: ellipsis">{{ displayValue }}</div>
</template>

<script>
import mixin from './mixin'
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'

export default {
  name: 'fsSystemDateTime',
  mixins: [mixin, dateTimeHelperMixin],
  computed: {
    displayValue () {
      const vm = this
      return vm.dataValue ? vm.formatDateTime(vm.dataValue) : ""
    }
  }
}
</script>
