<template>
  <v-dialog v-if="showing" v-model="showing" width="400">
    <!--<template v-slot:activator="{on, attrs}">-->
    <!--<v-btn @click="newModule" icon-->
    <!--style="height:24px;"-->
    <!--v-bind="attrs">-->
    <!--<font-awesome-icon icon="plus"></font-awesome-icon>-->
    <!--</v-btn>-->
    <!--</template>-->
    <v-card>
      <v-form @submit="onSubmit" onSubmit="return false;">
        <dialog-header
          :label="dialogTitle"
          @close="showing = false"
          color="#E0E0E0"
          toolbarClass="grey--text"
        />
        <!-- <v-card-title class="headline grey lighten-2">
          {{ dialogTitle }}
        </v-card-title> -->
        <v-card-text class="pt-2 d-flex flex-column">
          <label>{{ $t('general.type')}}</label>
          <input-radio-toggle icon
                              v-model="data.viewType"
                              :isDense="true"
                              :isReadOnly="isReadOnly"
                              :options="viewTypeOptions"></input-radio-toggle>
          <label>{{ $t('general.title')}}</label>
          <v-text-field autofocus
                        @focus="onFocus($event)"
                        height="30"
                        style="font-size:14px"
                        dense v-model="data.title" outlined></v-text-field>
        </v-card-text>
        <!-- <v-divider/> -->
        <!-- <v-card-actions>
          <v-spacer/>

          <v-btn class="min-width-100" @click="closeDialog">
            {{ $t('buttons.cancel') }}</v-btn>
          <v-btn :disabled="!data.title" color="primary" type="submit" class="min-width-100">
            {{ $t('buttons.ok') }}</v-btn>
        </v-card-actions> -->
        <dialog-actions 
          :buttons="['cancel', 'ok']"
          :actionDisabled="!data.title"
          @onCommand="onCommandHandler($event)">
      </dialog-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import baseMixin from '@/mixins/baseMixin'
  import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
  import viewTypes from '@/const/viewTypes'
  import dialogActions from "@/components/dialogs/comps/DialogActions";
  import dialogHeader from "@/components/dialogs/comps/DialogHeader";

  export default {
    name: 'viewBasicInfoDialog',
    mixins: [baseMixin],
    components: {inputRadioToggle, dialogActions, dialogHeader},
    data () {
      return {
        showing: false,
        isReadOnly: false,
        data: { // maybe module or view
          _id: '',
          title: '',
          viewType: 'table'
        },
        labelTags: {
          'table': 'general.table',
          'panel': 'general.panel',
          'levelDiagram': 'general.cascade',
          'project': 'general.project'
        },
        defaultTitles: {
          'table': '',
          'panel': '',
          'levelDiagram': ''
        }
        // ,
        // viewTypeOptions: [
        //   {
        //     labelTag: 'general.table',
        //     icon: 'mdi-view-list-outline',
        //     value:'table'
        //   },
        //   {
        //     labelTag: 'general.panel',
        //     icon: 'mdi-view-dashboard-variant-outline',
        //     value:'panel'
        //   },
        //   {
        //     labelTag: 'general.cascade',
        //     icon: 'mdi-animation-outline',
        //     value:'levelDiagram'
        //   }
        // ]
      }
    },
    props: {
      dialogName: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      command: {
        type: String,
        default: ''
      }
    },
    watch: {
      // dialogName: function (newVal) {
      //   this.data.title = newVal
      // },
      'data.viewType': function (newVal) {
        console.log('watch(data.viewType')
        this.getNextTitle(newVal)
      }
    },
    mounted () {
      this.loadDefaultTitles()
    },
    computed: {
      viewTitles () {
        return Object.values(this.viewTitleMapping)
      },
      viewTitleMapping () {
        const vm = this
        var result = {}
        
        for (let viewType in vm.labelTags) result[viewType] = []

        for (let viewType in vm.labelTags) {
          const labelTag = vm.labelTags[viewType]
          const viewsOfType = vm.views.filter(view => view.viewType === viewType)
          const viewTitles = viewsOfType.map(view => view.title).filter(item => item !== '')
          const startNo = viewTitles.length > 0 ? 2 : 1
          result[viewType] = vm.getNextViewTitle(labelTag, viewTitles, startNo)
        }
        return result
      },
      viewTypeOptions () {
        var result = JSON.parse(JSON.stringify(viewTypes));
        if (this.isHiddenForDeployment('cascade_view')) {
          delete result.levelDiagram
          //result = result.filter(item => item.value !== 'levelDiagram')
        }
        result = Object.keys(result).map(key => {
          return {...result[key], value: key}
        })
        return result
      },
      dialogTitle () {
        const vm = this
        if (vm.data._id === '') {
          var title = []
          if (vm.data.title !== '') title.push(vm.data.title)
          title.push('(' + vm.$t('general.new') + ')')
          return title.join(' ')
        } else {
          return vm.data.title + ' (' + vm.$t('general.edit') + ')'
        }
      },
      currentForm () {
        return this.$store.getters.currentForm
      },
      views () {
        return this.currentForm ? this.currentForm.views : []
      }
    },
    methods: {
      onCommandHandler(payload){
        if(payload.command == 'ok'){
          this.onSubmit()
        }
        this.closeDialog()
      },
      getNextTitle (viewType) {
        const vm = this
        console.log('getNextTitle : viewType = ' + viewType)
        console.log('getNextTitle : vm.data.title = ' + vm.data.title)
        console.log('getNextTitle : title exists in default: ' + (vm.viewTitles.includes(vm.data.title) ? 'yes' : 'no'))

        if (!vm.data.title || vm.data.title === '' || vm.viewTitles.includes(vm.data.title)) {
          console.log('a')
          vm.data.title = vm.viewTitleMapping[viewType]
        }
        console.log('getNextTitle : vm.data.title = ' + vm.data.title)
      },
      loadDefaultTitles () {
        const vm = this
        for (let viewType in vm.labelTags) {
          const labelTag = vm.labelTags[viewType]
          vm.defaultTitles[labelTag] = vm.viewTitleMapping[viewType]
        }
      },
      getNextViewTitle (labelTag, existingTitles, viewNo) {
        const vm = this
/*          console.log('getNextViewTitle :: labelTag = ' + labelTag)
        console.log('getNextViewTitle :: existingTitles.length = ' + existingTitles.length)
        console.log('getNextViewTitle :: viewNo = ' + viewNo)
        console.log('getNextViewTitle :: existingTitles: ', existingTitles)
 */
        // console.log('existingTitles: ', existingTitles)
        var title = vm.$t(labelTag) + viewNo
        // console.log('check title = ' + title)
        while (existingTitles.includes(title)) {
          viewNo++
          title = vm.$t(labelTag) + viewNo
          // console.log('included. viewNo++  check title = ' + title)
        }
        return title
      },
      // getNextViewTitle(viewType, views, labelTag) {
      //   const vm = this
      //   const seqNo = 1
      //   if (vm.hasViewOfType(viewType)) {
      //     seqNo = 2
      //   }

      // },
      onFocus (evt) {
        evt.target.select()
      },
      open (tabInfo) {
        const vm = this
        // console.log('open (tabInfo): ', tabInfo)

        if (typeof tabInfo === 'undefined') {
          vm.isReadOnly = false
          vm.data = {
            _id: '',
            title: '',
            viewType: 'table'
          }
          vm.getNextTitle('table')

          // console.log('vm.data: ' + JSON.stringify(vm.data))
        } else {
          vm.isReadOnly = true
          vm.data = {
            _id: tabInfo._id,
            title: tabInfo.title,
            viewType: tabInfo.viewType
          }
        }
        vm.showing = true
      },
      onSubmit () {
        const vm = this
        // console.log('EditTabDialog ::onSubmit :: command: ' + vm.command)
        vm.$emit('onCommand', {
          command: vm.command,
          id: vm.data._id,
          title: vm.data.title,
          viewType: vm.data.viewType
        })
        vm.showing = false
        return false
      },
      closeDialog () {
        const vm = this
        vm.showing = false
      }
    }
  }
</script>
