<template>
    <div class="edit-department-modal fill-height" style="width:100%">
      <div @click="(!readonly && params.row.canEdit)? showingDialog = true:''" style="height:inherit">
        <v-input
          hide-details
          :class="((readonly || !params.row.canEdit) && params.row[params.column.property].length==0)? 'd-none':''"
        >
          <div class="d-flex flex-column" style="width:100%">
            <selected-department-tag
              style="width:100%"
              v-for="(departmentInfo, index) in params.row[params.column.property]"
              :key="departmentInfo._id"
              :departmentId="departmentInfo._id"
              :name="departmentInfo.label"
              :index="index"
              :hasCloseButton="!readonly && params.row.canEdit && true"
              :cssName="'department-chip-list'"
              color="light"
              deleteCommand="onCloseClicked"
              @onCommand="onCommandHandler($event, params.row)"
              :readonly="readonly|| !params.row.canEdit"
            ></selected-department-tag>
          </div>
          </v-input>
        </div>
        <DepartmentsDialog
          v-if="showingDialog"
          v-model="showingDialog"
          :attach="'tv-r' + params.rowIndex + 'c' + params.columnIndex"
          @confirm="params.row[params.column.property] = $event"
          :selectedDepts="params.row[params.column.property]"
          :isMultiple="fieldType"
          hideOverlay
          :cssSelect="cssSelect"
        />
    </div>
</template>

<script>
import selectedDepartmentTag from "@/components/dialogs/comps/SelectedDepartmentTag";
import DepartmentsDialog from "@/components/dialogs/DepartmentsDialog";
export default {
  name: 'EditDepartmentModal',
  components: {
    selectedDepartmentTag,
    DepartmentsDialog,
  },
  props: {
    params: Object,
    readonly: Boolean,
    fieldType: Boolean
  },
  data () {
    return {
      showingDialog: false,
      row: null,
      column: null,
      modalVisible: false,
      loading: false,
    }
  },
  watch:{
    showingDialog(val){
      this.$emit('updateTdClass', val)
    }
  },
  computed:{
    cssSelect(){
      if(this.params.fixed!= undefined){
        if(document.querySelector('.vxe-table--fixed-left-wrapper')){
          var path = '#tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex
          return '.vxe-table--fixed-left-wrapper' + ' ' + path
        }
      }
      return ''
    },
  },
  methods: {
    updateVal(val) {
      const vm = this;
      vm.$emit("onCommand", {
        command: "updateFieldValue",
        fieldId: vm.params.column.property,
        fieldValue: val,
      });
    },
    onCommandHandler(payload, row) {
      console.log(payload,row)
      switch (payload.command) {
        case "onCloseClicked":
          this.onCloseClicked(payload.index, row);
          break;
      }
    },
    onCloseClicked(index, row) {
      row[this.params.column.property].splice(index, 1);
    },
  }
}
</script>

<style lang="scss">
  .edit-department-modal  {
    background-color: white;
    .v-input.vxe-table-input-box{
    border: 1px #dcdfe6 solid !important;
  }
  }
</style>

