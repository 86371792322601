<template>
  <v-row dense>
    <v-col cols="4" class="d-flex flex-row align-center">
      <label>{{ $t(labelTag) }}</label>
    </v-col>
    <v-col cols="8" class="d-flex flex-row align-center">
      <input-yes-no
          v-model="valueStr"
          :disabled="disabled"
          ref="inputYesNo"></input-yes-no>
      <!--<input-yes-no-->
          <!--:value="valueStr"-->
          <!--ref="inputYesNo"-->
          <!--@input="val=>updateUserValue(val)"></input-yes-no>-->
    </v-col>
  </v-row>
</template>

<script>
  import inputYesNo from '@/components/formComps/InputYesNo'

  export default {
    name: 'settingsYesNoRow',
    components: {
      inputYesNo
    },
    // data () {
    //   return {
    //     userValue: ''
    //   }
    // },
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      labelTag: String,
      value: String,
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      valueStr: {
        get () {
          return this.value ? 'true' : 'false'
        },
        set (val) {
          console.log('SettingsYesNoRow :: computed(valueStr) :: set(val) val=' + val)
          this.$emit('input', val==='true')
          //updateUserValue(val)
        }
      }
    },
    methods: {
      checkIsValid (state) {
        return this.$refs.inputYesNo.checkIsValid(state)
      }
      // ,
      //
      // updateUserValue (val) {
      //   console.log('SettingsYesNoRow :: val = ' + val)
      //   this.$emit('input', val)
      // }
    }
    // ,
    // watch: {
    //   userValue: function (newVal, oldVal) {
    //     const vm = this
    //     if (newVal !== oldVal) {
    //       vm.$emit('input', newVal)
    //     }
    //   },
    //   value: function (newVal, oldVal) {
    //     const vm = this
    //     vm.userValue = newVal
    //   }
    // }
  }
</script>
