<template>
  <div style="margin-bottom: 20px;" class="wit-fields-editor">
    <div class="d-flex flex-row align-center">
      <label>{{ label }}</label>
      <v-spacer></v-spacer>
    </div>

    <!-- Widget List for child table -->
    <div>
      <v-tooltip top
                 v-for="widget in childWidgets" :key="widget._id">
        <template v-slot:activator="{on, attrs}">
          <v-btn v-bind="attrs" small
                 @click="newChildField(widget)"
                 class="px-1"
                 min-width="28px"
                 min-height="28px"
                 v-on="on">
            <font-awesome-icon :icon="widget.icon" class="fa-fw mr-1"></font-awesome-icon>
          </v-btn>
        </template>
        <span>{{ $t('widgets.' + widget.labelTag)}}</span>
      </v-tooltip>
    </div>

    <!-- field list -->
    <div class="grey lighten-4 mt-1">
      <wit-table-field-list
          :title="$t('widgets.properties.fixedColumn')"
          id="fixedFields"
          fieldType="fixedFields"
          :fieldInfoId="fieldInfoId"
          :titleFieldInfoId="titleFieldInfoId"
          v-model="fixedFieldInfos"
          color="rgba(0,0,200,.05)"
          @onCommand="onCommandHandler"
          :inputItem="inputItem">
      </wit-table-field-list>

      <div class="d-flex flex-column justify-center white" style="height:10px;">
      <v-divider class="my-2"></v-divider>
      </div>

      <wit-table-field-list
          :title="$t('widgets.properties.nonFixedColumn')"
          id="nonFixedFields"
          fieldType="nonFixedFields"
          :fieldInfoId="fieldInfoId"
          :titleFieldInfoId="titleFieldInfoId"
          v-model="nonFixedFieldInfos"
          @onCommand="onCommandHandler"
          :inputItem="inputItem">
      </wit-table-field-list>

      <div class="d-flex flex-row align-center" style="background-color:white;">
        <child-table-widget-menu-button
            :label="$t('widgets.properties.newField')"
            @newItem="newChildField"></child-table-widget-menu-button>
      </div>
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </div>
</template>

<script>
import {VueNestable, VueNestableHandle} from 'vue-nestable'
import inputRadioToggle from '@/components/formComps/InputRadioToggle'
import childTableWidgetMenuButton from '@/components/ChildTableWidgetMenuButton'
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import witTableFieldList from './WitTableFieldList'

import mixin from './mixin'
import widgetHelperMixin from '@/mixins/widgetHelperMixin'

import helpers from '@/helpers'

import {getWidgetPropertyValues} from '@/helpers/FormHelpers'

// property value:
// fieldsEditor:
//
// {
//    fields: [
//        {
//            id: // start from 1
//            fieldName:
//            properties: [
//            ]
//        },
//        {
//            id:
//            fieldName:
//            properties: [
//            ]
//        },
//        {
//            id:
//            fieldName:
//            properties: [
//            ]
//        }
//    ]
// }
export default {
  name: 'witFieldsEditor',
  mixins: [
    mixin,
    widgetHelperMixin
  ],
  components: {
    VueNestable,
    VueNestableHandle,
    childTableWidgetMenuButton,
    inputRadioToggle,
    confirmDeleteDialog,
    witTableFieldList
  },
  data () {
    return {
      inputItem: null,
      hooks: {
        // 'beforeMove': this.beforeMove
      },
      // customPropertyValue: {
      //   fields: [],
      //   fixedFields: []
      // },
      lastFieldType: 'nonFixedFields'
    }
  },
  mounted () {
    const vm = this
    // vm.parseValue()
  },
  watch: {
    // 'value.fields': function (newVal) {
    //   this.parseValue()
    // },
    inputItem: function (newVal, oldVal) {
      const vm = this
      if (newVal) {
        vm.updateLastFieldType()
      }
    }
  },
  computed: {
    titleFieldInfoId () {
      return this.propertyValue.titleFieldInfoId
    },
    childFieldInfos () {
      const vm = this
      var result = []
      if (vm.propertyValue.fieldInfos) {
        result = vm.propertyValue.fieldInfos
      }
      return result
    },
    fixedFieldIds () {
      return this.propertyValue.fixedFieldIds
    },
    nonFixedFieldIds () {
      return this.propertyValue.nonFixedFieldIds
    },
    fixedFieldInfos: {
      get() {
        const vm = this
        var result = []
        if (vm.fixedFieldIds) {
          result = vm.fixedFieldIds.map(fieldId => {
            return vm.childFieldInfos.find(info => info.fieldId === fieldId)
          })
        }
        return result
        // var result = []
        // if (vm.propertyValue) {
        //   result = vm.propertyValue.fixedFieldIds
        //   result = vm.childFieldInfos.filter(
        //     field => vm.propertyValue.fixedFieldIds.indexOf(
        //       field.fieldId) >= 0)
        // }
        // return result
      },
      set (val) {
        const vm = this
        const fieldIds = val.map(v => v.fieldId)
        console.log('WitFieldsEditor computed(fixedFieldIds) : set: ' +
          (val.map(v => v.label).join(',')))
        vm.propertyValue.fixedFieldIds = fieldIds
      }
    },
    nonFixedFieldInfos: {
      get () {
        const vm = this
        var result = []
        if (vm.nonFixedFieldIds) {
          result = vm.nonFixedFieldIds.map(fieldId => {
            return vm.childFieldInfos.find(info => info.fieldId === fieldId)
          })
        }
        return result
        // var result = []
        // if (vm.propertyValue) {
        //   result = vm.childFieldInfos.filter(
        //     field => vm.propertyValue.nonFixedFieldIds.indexOf(
        //       field.fieldId) >= 0)
        // }
        // return result
      },
      set (val) {
        const vm = this
        const fieldIds = val.map(v => v.fieldId)
        console.log('WitFieldsEditor computed(nonFixedFieldIds) : set: ' +
          (val.map(v => v.label).join(',')))

        console.log('WitFieldsEditor computed(nonFixedFieldIds) : nonFixedFieldIds = ' +
          vm.propertyValue.nonFixedFieldIds.join(','))

        vm.propertyValue.nonFixedFieldIds = fieldIds

        console.log('WitFieldsEditor computed(nonFixedFieldIds) : nonFixedFieldIds = ' +
          vm.propertyValue.nonFixedFieldIds.join(','))

      }
    },
    childWidgets () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.widgetGroups.length; i++) {
        const loopGroup = vm.widgetGroups[i]
        for (let j = 0; j < loopGroup.widgets.length; j++) {
          const loopWidget = loopGroup.widgets[j]
          if (loopWidget.commonForChildTable && !loopWidget.disabled) {
            result.push(loopWidget)
          }
        }
      }
      return result
    },

    // inputItemFieldType () {
    //   const vm = this
    //   var result = vm.lastFieldType
    //   if (vm.inputItem) {
    //     const index = vm.customPropertyValue.fixedFields.findIndex(
    //       field => field.id === vm.inputItem.id)
    //
    //     vm.lastFieldType = index >= 0 ? 'fixedFields' : 'fields'
    //   }
    //   return result
    // },
    widgetProperties () {
      return this.$store.getters.widgetProperties
    },
    widgetGroups () {
      return this.$store.getters.widgetGroups
    }
  },
  methods: {
    updateLastFieldType () {
      const vm = this
      if (vm.inputItem) {
        const index = vm.propertyValue.fieldInfos.findIndex(info => info.fieldId === vm.inputItem.fieldId)
        vm.lastFieldType = index >= 0 ? 'fixedFields' : 'nonFixedFields'
      }
    },
    onCommandHandler (payload) {
      const vm = this
      console.log('WidgetInputTypeFieldsEditor.vue :: onCommandHandler (' +
        payload.command + ') :: payload: ', payload)
      switch (payload.command) {
        case 'setTitle':
          vm.$set(vm.propertyValue, 'titleFieldInfoId', payload.field._id)
          break
        case 'onItemDropped':
          vm.onItemDropped(payload.fieldType, payload.fields, payload.item)
          break
        // case 'updatePropertyValue':
        //   vm.updatePropertyValue(payload.fieldType, payload.newFields)
        //   break
        case 'updateInputItem':
          vm.inputItem = payload.inputItem
          break
        case 'updateField':
          vm.updateField(
            payload.fieldType,
            // payload.fieldIndex,
            payload.field,
            payload.attributeName,
            payload.fieldValue)
          break
        case 'editField':
          vm.editField(payload.item)
          break
        case 'removeField':
          vm.removeField(payload.fieldType, payload.item)
          break
        case 'updateChildField':
          vm.updateChildField(payload)
      }
    },


    editField (field) {

      const vm = this
      // console.log('WidgetInputTypeFieldsEditor :: editField :: field: ', field)
      const widget = getWidget(field.widgetId, vm.widgetGroups)
      vm.$emit('onCommand', {
        command: 'showChildTableFieldProperty',
        fieldInfoId: vm.fieldInfoId,
        parentLabel: vm.parentLabel,
        childFieldId: field.fieldId,
        childFieldInfoId: field.fieldId,
        childWidgetId: field.widgetId,
        childProperties: field.properties,
        childType: field.type
      })
    },

    updateField (fieldType, /*fieldIndex,*/ field, attributeName, fieldValue) {
      // e.g. 'fixedFields',
      const vm = this


      var index = vm.propertyValue.fieldInfos.findIndex(info => info.fieldId === field.fieldId)


      if (index >= 0) {
        if (attributeName === 'fieldName') {
          vm.propertyValue.fieldInfos[index]['label'] = fieldValue
          vm.propertyValue.fieldInfos[index]['properties']['fieldName'] = fieldValue
        } else {
          vm.propertyValue.fieldInfos[index][attributeName] = fieldValue
        }
      }
    },

    removeField (fieldType, item) {
      const vm = this
      var index = 0
      if (vm.propertyValue) {
        index = vm.propertyValue.fieldInfos.findIndex(info => info.fieldId === item.fieldId)
        if (index >= 0) {
          vm.propertyValue.fieldInfos.splice(index, 1)
        }

        index = vm.propertyValue.nonFixedFieldIds.findIndex(fieldId => fieldId === item.fieldId)
        if (index >= 0) {
          vm.propertyValue.nonFixedFieldIds.splice(index, 1)
        }

        index = vm.propertyValue.fixedFieldIds.findIndex(fieldId => fieldId === item.fieldId)
        if (index >= 0) {
          vm.propertyValue.fixedFieldIds.splice(index, 1)
        }
      }
    },

    reindexInputOptions () {
      const vm = this
      for (var i = 0; i < vm.inputOptions.length; i++) {
        vm.inputOptions[i].index = i
      }
    },

    checkGetNextWidgetLabel (label) {
      const vm = this
      var count = 1
      var result = label

      const fieldNames = vm.propertyValue.fieldInfos.map(info => info.fieldName)
      while (fieldNames.indexOf(result) >= 0) {
        count++
        result = label + count.toString()
      }
      return result
    },

    getNewId () {
      return '_' + Date.now().toString()
    },

    newChildField (widget) {
      const vm = this
      console.log('newChildField : widget: ', widget)
      var newTempId = vm.getNewId() + '1'
      var newTempFieldId = widget.isSystem? widget.key:newTempId
      console.log('newChildField :: newTempId = ' + newTempId)
      console.log('newChildField :: newTempFieldId = ' + newTempFieldId)

      var widgetLabel = vm.$t('widgets.' + widget.labelTag)
      widgetLabel = vm.checkGetNextWidgetLabel(widgetLabel)
      const customPropertyValues = getWidgetPropertyValues(
        widget,
        widgetLabel,
        vm.widgetProperties)

      console.log('newChildField :: newTempFieldId = ' + newTempFieldId)
      console.log('newChildField :: vm.lastFieldType = ' + vm.lastFieldType)

      // Check last added field's fieldtype,
      // fixedFields (~ fixed columns)
      //
      var newFieldInfo = null
      var isFixed = false
      switch (vm.lastFieldType) {
        case 'fixedFields':
          newFieldInfo = {
            _id: newTempId,
            fieldId: newTempFieldId,
            label: widgetLabel,
            widgetId: widget._id,
            properties: customPropertyValues,
            type: widget.key
          }
          isFixed = true
          break
        default: // 'nonFixedFields'
          newFieldInfo = {
            _id: newTempId,
            fieldId: newTempFieldId,
            label: widgetLabel,
            widgetId: widget._id,
            properties: customPropertyValues,
            type: widget.key
          }
          isFixed = false
      }


      vm.$store.dispatch('ADD_CHILD_TABLE_FIELD_INFO', {
        fieldInfoId: vm.fieldInfoId,
        fieldInfo: newFieldInfo,
        isFixed: isFixed
      })
      // const newVal = vm.buildValue()
      // vm.$emit('input', newVal)
    },

    onItemDropped (fieldType, fields, item) {
      const vm = this
      // alert('onItemDropped')

      switch (fieldType) {
        case 'fixedFields':
          vm.propertyValue.fixedFieldIds = fields.map(field => field.fieldId)
          break
        case 'nonFixedFields':
          vm.propertyValue.nonFixedFieldIds = fields.map(field => field.fieldId)
          break
        default:
          alert('unknown fields')
      }
    }
  }

}
</script>

<style>
  .field-label-input:active,
  .field-label-input:focus {
    outline: skyblue auto 1px;
    background-color: white;
  }

  .field-label-input:hover {
    border-color: lightgray;
  }

  .field-label-input {
    font-size: 10px;
    padding: 1px 2px;
    border: 1px solid transparent;
  }

  .nestable-handle .v-icon {
    cursor: pointer;
  }

  .color-circle {
    height: 16px !important;
    width: 16px !important;
  }

  .wit-fields-editor .nestable-list-empty {
    display: none;
  }

  .wit-field-editor .nestable-list .nestable-item {
    margin-bottom: 1px;
  }
</style>
