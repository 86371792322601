<template>
            <!-- :attach="cssSelect?cssSelect:(attach)?`#${attach}`:undefined" -->

  <v-dialog v-model="showing"
            v-if="showing"
            :width="width"
            persistent
            scrollable
            :height="height"
            class="d-flex flex-column">
    <v-card class="selectRelatedRecordsDialog d-flex flex-column flex-grow-1 vxe-table--ignore-clear">
      <dialog-header
          icon="mdi-window-restore"
          :label="title"
          @close="close">
        </dialog-header>
      <v-card-text class="d-flex flex-column flex-grow-1 py-1" style="height:620px;">
        <selection-table-view
            ref="selectionTableView"
            v-model="value"
            :multiple="multiple"
            :exclusion="exclusion"
            :recordId="recordId"
            :fieldId="fieldId"
            :parentFormId="parentFormId"
            :dataSource="dataSource"
            :formInfo="formInfo"
            :valType="valType"  
            :parentRecordInfo="parentRecordInfo"
            :selectionSettings="selectionSettings"></selection-table-view>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <dialog-actions :actionDisabled="!actionEnabled"
                      :loading="loading"
                      @onCommand="onCommandHandler">
        <!-- <template>
          <v-btn x-small fab color="primary"
                 @click="addRelatedRecord">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template> -->
      </dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogHeader from '@/components/dialogs/comps/DialogHeader'
import dialogActions from '@/components/dialogs/comps/DialogActions'
import selectionTableView from '@/components/SelectionTableView'

export default {
  name: 'selectRelatedRecordsDialog',
  components: {
    dialogHeader,
    dialogActions,
    selectionTableView
  },
  props: {
    width: {
      type: String,
      default: '1200'
    },
    height: {
      type: String,
      default: '90%'
    },
    valType:{
      type: String,
      default: 'string'
    },
    normalPopup: Boolean,
    popTitle: String,
  },
  data () {
    return {
      showing: false,

      // selectionTableConfig: null,
      dataSource: null,
      formInfo: null,
      selectionSettings: null,
      fieldId:'',
      parentFormId:'',
      // in case selection for embedded table
      // all data update occur instantly
      // exclusion requires access to database      
      // recordId: '',
      parentRecordInfo: null,
      recordId: '',

      callback: null,
      onSelected: null,
      onCancelled: null,

      multiple: false,
      exclusion: [],
      value: [], // ensure it is array

      callbackBundle: {
        id: '',
        onSelected: null,
        onCancelled: null
      },

      // for attachment to vue-table cell
      attach: '',
      cssSelect: ''
    }
  },
  // watch: {
  //   showing: function(newVal) {
  //     alert('showing: ' + (newVal ? 'yes' : 'no'))
  //   }
  // },
  computed: {
    // if not multiple
    // there must be one item selected
    actionEnabled () {
      const vm = this
      var result = true
      if (!vm.multiple) {
        result = vm.value.length > 0
      }
      return result
    },
    title () {
      if (this.normalPopup) {
        return this.poptitle;
      }
      return this.$t('messages.relationSelection') + ' - ' + 
        this.relatedFormLabel
    },
    relatedFormLabel () {
      const vm = this
      return (vm.formInfo||{}).label
    }
  },
  methods: {
    // open (alue, selectionConfig, callback, multiple) {
    open (payload, onSelected, onCancelled) {
      const vm = this

      vm.attach = payload.attach
      vm.cssSelect = payload.cssSelect
      //
      // handle exclusion
      //
      vm.value = []
      // exclude aleady selected items      
      vm.exclusion = payload.value && !payload.inclusive ?  payload.value:[]
      // extra exclusion
      if (Array.isArray(payload.exclusion) && !payload.inclusive) {
        vm.exclusion = vm.exclusion.concat(payload.exclusion)
      }
      // for parent/child records selection during exclusion processed in server


      //
      // relation parameters
      //
      vm.multiple =  payload.multiple || false
      vm.dataSource = payload.dataSource
      vm.formInfo = payload.formInfo
      vm.selectionSettings = payload.selectionSettings
      this.recordId = payload.recordId
      this.fieldId = payload.fieldId
      this.parentFormId = payload.parentFormId
      // if (payload.recordId) vm.recordId = payload.recordId
      // if (payload.fieldId) vm.fieldId = payload.fieldId

      //
      // callback handling
      //
      if (payload.callbackBundle) vm.callbackBundle = payload.callbackBundle
      if (onSelected) vm.onSelected = onSelected
      if (onCancelled) vm.onCancelled = onCancelled

      vm.showing = true

    },
    
    close () {
      const vm = this
      console.log('selectRelatedRecordsDialog.close')
      vm.showing = false
      console.log('selectRelatedRecordsDialog vm.showing = ' + (vm.showing ? 'yes' : 'no'))
      if (typeof vm.onCancelled === 'function') {
        vm.onCancelled(vm.callbackBundle)
      }
    },

    okHandler () {
      const vm = this
      console.log('selectRelatedRecordsDialog.okHandler :: vm.value = ' + JSON.stringify(vm.value))
      if (typeof vm.onSelected === 'function') {
        vm.onSelected(vm.value, vm.callbackBundle)
        this.showing = false
      }
      vm.$emit('input')
    },

    onCommandHandler (payload) {
      console.log("payload", payload)
      const vm = this
      switch (payload.command) {
        case 'ok':
          vm.okHandler()
          break
        case 'cancel':
          vm.close()
          break
      }
    },
    addRelatedRecord () {
      this.close()
      this.$emit('click:new')
      vm.showing = false
    }
  }
}
</script>
