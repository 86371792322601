<template>
  <div>
    <label>{{ label }}</label>
    <v-select
      solo
      dense
      :items="items"
      item-value="fieldId"
      item-text="label"
      outlined
      v-model="propertyValue"
      hide-details="true"
      :placeholder="$t('messages.selectImage')"
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
    ></v-select>
    <!--<v-text-field-->
        <!--label=""-->
        <!--v-model="propertyValue"-->
        <!--dense-->
        <!--hide-details="auto"-->
        <!--outlined></v-text-field>-->
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  watch: {
    dataTableId: function(newVal) {
      const vm = this
      vm.propertyValue = ''
    }
  },
  computed: {
    items () {
      const vm = this
      var result = [
        {
          fieldId: '',
          label: vm.$t('messages.noImage')
        }
      ]
      for (let i = 0; i < vm.dataSourceFieldInfos.length; i++) {
        const loopFieldInfo = vm.dataSourceFieldInfos[i]
        if (loopFieldInfo.type === 'attachments') {
          result.push(loopFieldInfo)
        }
      }
      return result
    }
  },
  methods: {
    loadDataSource () {
      const vm = this
      return this.$store.dispatch('FETCH_DATASOURCE_FIELDS', {
        dataSource: vm.dataSource
      })
    }
  }
}
</script>
