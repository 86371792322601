<template>
  <v-navigation-drawer
    v-model="openPanel"
    temporary
    right
    absolute
    style="z-index: 9999;"
    width="750"
  >
    <v-card tile style="height:100%" elevation="0" class="">
      <v-layout
        column
        display="flex"
        fill-height
        class="global-panel-notification-drawer"
      >
        <v-layout
          justify-space-between
          align-center
          class="global-panel-notification-title"
        >
          <div>
            {{ $t("notification.panel.title") }}
          </div>
          <div class="global-panel-notification-header">
            <v-tabs centered height="55px" v-model="filterType">
              <v-tab v-for="tab in tabs" :key="tab.key">{{ tab.label }}</v-tab>
            </v-tabs>
          </div>
          <div>
            <v-btn icon @click="showFilter = !showFilter" size="17">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
            <v-btn icon @click="openPanel = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-layout>
        <div class="global-panel-notification-content">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <NotificationFilter
            @hide="showFilter = false"
            v-if="showFilter"
            @search="search"
          />
          <v-layout
            column
            class="g-content-row-notFound"
            align-center
            justify-center
            v-if="currentPage === 1 && !list.length && fetched"
          >
            <Lottie :options="defaultOptions" :height="400" :width="400" />
            <b
              >{{
                $t("notification.panel.noFound", {
                  type: $t(`notification.panel.tabs.${tabs[filterType].key}`),
                })
              }}
            </b>
          </v-layout>
          <perfect-scrollbar @ps-y-reach-end="loadMore">
            <div>
              <component
                :is="target(item.info.type)"
                v-for="(item, index) in list"
                :item="item"
                :index="index"
                :key="item._id"
              />
            </div>
          </perfect-scrollbar>
        </div>
      </v-layout>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {
  TOGGLE_PANEL,
  FETCH_NOTIFICATION_LIST,
  // UNBOOKMARK_NOTIFICATION,
  // BOOKMARK_NOTIFICATION,
} from "@/store/modules/notification/action_types";
import Lottie from "vue-lottie";
import NotFoundAnimation from "@/pages/admin/workflow/assets/not_found.json";
import { forEach } from "lodash";
import WorkflowMessage from "./message/workflow";
import ApplicationMessage from "./message/application";
import NotificationFilter from "./filter";
export default {
  components: {
    WorkflowMessage,
    Lottie,
    NotificationFilter,
    ApplicationMessage,
  },
  data() {
    return {
      defaultOptions: {
        animationData: NotFoundAnimation,
      },
      animationSpeed: 0.5,
      open: false,
      showFilter: false,
      filter: {},
      filterType: 0,
      tabs: [
        {
          label: this.$t("notification.panel.tabs.workflow"),
          key: "workflow",
        },
        {
          label: this.$t("notification.panel.tabs.system"),
          key: "system",
        },
        {
          label: this.$t("notification.panel.tabs.application"),
          key: "application",
        },
        {
          label: this.$t("notification.panel.tabs.bookmark"),
          key: "bookmark",
        },
      ],
      ...this.initialState(),
    };
  },
  methods: {
    hideIt() {
      if (this.openPanel) {
        this.openPanel = false;
      }
    },
    initialState() {
      return {
        limit: 20,
        offset: 0,
      };
    },
    async getList() {
      let payload = {
        type: this.tabs[this.filterType].key,
        appId: this.app._id,
        limit: this.limit,
        offset: this.offset,
      };
      if (this.filter.date) {
        payload = {
          ...payload,
          from: this.filter.date[0],
          to: this.filter.date[1],
        };
      }
      await this.$store.dispatch(FETCH_NOTIFICATION_LIST, payload);
      this.offset += this.limit;
    },
    async loadMore() {
      if (this.hasNextPage && !this.loading && this.openPanel) {
        // console.log('')
        await this.getList();
      }
      // this.$store.dispatch(TOGGLE_PANEL);
    },
    search(filters) {
      this.reset();
      this.filter = filters;
      this.$nextTick(() => {
        this.getList();
      });
    },
    // bookmarkIt({ bookmark, _id }, index) {
    //   this.$store.dispatch(bookmark ? UNBOOKMARK_NOTIFICATION : BOOKMARK_NOTIFICATION, {
    //     notificationId: _id,
    //     appId: this.app._id,
    //     index,
    //   });
    // },
    target(type) {
      const lookup = {
        workflow: "WorkflowMessage",
        application: "ApplicationMessage",
      };
      return lookup[type];
    },
    uid({ _id, read, bookmark }) {
      // keyExtrator for the status change cases
      return `${_id}_${read ? 1 : 0}_${bookmark ? 1 : 0}`;
    },
    reset() {
      const state = this.initialState();
      forEach(state, (v, k) => {
        this[k] = v;
      });
    },
  },
  computed: {
    app() {
      return this.$store.getters.currentApp;
    },
    list() {
      return this.$store.getters.getAllNotifications;
    },
    fetched() {
      return this.$store.getters.isInitialFethedNotifications;
    },
    loading() {
      return this.$store.getters.fetchingAllNotifications;
    },
    currentPage() {
      return this.$store.getters.getNotificationsCurrentPage;
    },
    hasNextPage() {
      return this.$store.getters.hasNextNotificationPage;
    },
    openPanel: {
      get() {
        return this.$store.getters.isOpenNotificationPanel;
      },
      set(v) {
        this.$store.dispatch(TOGGLE_PANEL, v);
        if (!v) {
          this.reset();
        }
      },
    },
  },
  watch: {
    filterType: function(type) {
      this.reset();
      this.$nextTick(() => {
        this.getList();
      });
    },
    $route: function(route) {
      if (route.name === "myApps") {
        this.openPanel = false;
      }
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer__content {
  overflow: hidden !important;
}
</style>
<style>
.global-panel-notification-content .ps {
  height: 100%;
}
</style>
