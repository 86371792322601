<template>
<div v-resize="onResize" class="d-flex flex-column fill-height position-relative">
  <!-- :admin="panelSettings && panelSettings.canEdit" -->
  <panel-base
    :admin="false"
    :settingButton="true"
    class="calendar-main-page"
    :title="title"
    :subTitle="subTitle"
    :loading="loading"
    icon="mdi-refresh"
    @click:admin="openCalendarPanelSettingsDialog"
    @click:submit="onRefreshClicked"
    :submitButtonLabel="$t('buttons.refresh')">
    <template v-slot:content>
      <!-- <h1>{{ loadingEventId }}</h1> -->
      <div class="d-flex flex-column calendar-wrapper w-100 fill-height pr-6 pt-1 position-relative">
        <div class="flex-grow-0 d-flex flex-row align-center mb-1">
          <v-btn large
            style="height:24px;"
            class="min-width-50 elevation-0"
            :class="{'primary':allSelected,'':!allSelected}"
            @click="toggleAll"><v-icon>mdi-check-all</v-icon></v-btn>
          <v-spacer></v-spacer>
          <div class="pb-2">
            <v-btn class="elevation-0"
              :class="allFormsSelected ? 'primary white--text':'muted'"
              style="height:24px; border: 1px solid darkgrey; border-radius: 0.2rem;"
              large
              @click="toggleAllForms"
              >
              <v-icon>mdi-check-all</v-icon></v-btn>
          </div>            
          <form-toggle
            :useFormColor="isColorBasedOnForm"
            :options="formConfigOptions"
            :value="selectedFormIds"
            @input="ids=>updateSelectedFormIds(ids)"/>

          <!-- <div small min-height="16" class="text-caption hover-grey round cursor-pointer border event-form-tag line-height-1 py-1 px-2 min-width-100 text-center"
            :class="{'active primary':activeFormIds.includes(form.formInfo._id)}"
            v-for="(form,index) in formConfigs"
            :key="index">
            {{ form.formInfo.label }}
            <div class="hover-mask"></div>
          </div> -->
          <v-spacer></v-spacer>
        </div>
        <div class="d-flex flex-row justify-start flex-grow-1 position-relative">
          <div class="d-flex flex-column justify-start flex-grow-0 fill-height pr-4">
            
            <!-- pending -->
            <v-tabs v-if="false"
              v-model="activeSidePanelIndex" activeClass="selected"
              style="color:lightgray;"
              :hide-slider="true"
              class="side-menu-selection rounded">
              <v-tab>{{ $t('calendar.category') }}</v-tab>
              <v-tab>{{ $t('calendar.data') }}</v-tab>
            </v-tabs>


            <form-record-list            
              v-if="sideMenuMode==='data'"
              class="flex-grow-1 mr-4"
              :activeFormId="activeFormId"
              @onRecordClicked="recordId=>assignDateToRecord(recordId)"></form-record-list>
            <category-selection
              v-else
              class="flex-grow-1 mr-4"
              :formConfigs="formConfigs"
              :sections="categorySections"
              :value="selectedCategoryIds"
              :selectedFormIds="selectedFormIds"
              @input="updateSelectedCategoryIds">
            </category-selection>
          </div>
          <!-- <div style="height:0;min-height:100%;overflow-y:auto;"> -->
          <full-calendar v-if="true"
            ref="fullCalendar"
            class="flex-grow-1"
            :options="config"
            @eventDestroy="eventDestroy">
            <template v-slot:eventContent="arg">             
              <event-line
                :event="arg.event"
                :loading="loadingEventId===arg.event._id"
                class="pa-0 v-event-draggable"
                @onCommand="onCommandHandler"></event-line>
              <!-- <b>{{ arg.timeText }}</b>
              <v-btn color="primary" @click="showEvent(arg.event)">{{ arg.event.title }}</v-btn> -->
            </template>
          </full-calendar>
          <!-- </div> -->

          <div v-if="loadingData"
            class="loading-mask position-absolute d-flex flex-column justify-center">
            <v-icon class="text-h1 white--text">mdi-spin mdi-loading</v-icon>
          </div>
        </div>
      </div>
    </template>
  </panel-base>  

  <form-selection-dialog    
    v-model="showingNewMenu"
    :menu="formItemsWithAdd"
    @click="newEvent">
  </form-selection-dialog>

  <confirm-delete-dialog 
    ref="confirmDeleteDialog"></confirm-delete-dialog>

  <calendar-panel-setup-dialog
    ref="calendarPanelSetupDialog" @confirm="onSettingsUpdated"></calendar-panel-setup-dialog>

  <popup-record-dialog 
    ref="popupRecordDialog"
    @onCommand="onCommandHandler"></popup-record-dialog>
  <edit-template-dialog
    v-if="showTemplateDialog"
    v-model="showTemplateDialog"
    :templateId="selectedPrintTemplate"
    :recordId="selectedRecordId"
    :isEdit="isEditPrint"
    :viewId="currentViewId"
    :isPrint="true"
  />
  <div v-if="loading" class="calendar-main-page-mask"></div>
</div>
</template>

<script>
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'
import panelMixin from '@/mixins/panelMixin'
import dialogMixin from '@/mixins/dialogMixin'
import appColorMixin from '@/mixins/appColorMixin'
import printTemplateDialogMixin from "@/mixins/printTemplateDialogMixin";
import panelBase from '@/components/PanelBase'

import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import calendarPanelSetupDialog from '@/components/dialogs/CalendarPanelSetupDialog'
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import EditTemplateDialog from "@/pages/admin/form/tableSetup/print/EditTemplateDialog";
// import menuButton from './comps/MenuButton'
import formSelectionDialog from './comps/FormSelectionDialog'
// import formSelectionMenu from './comps/FormSelectionMenu'
// import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
import formToggle from './comps/FormToggle'
// import eventMenuButton from './comps/EventMenuButton'
// import eventStripe from './comps/EventStripe'
import eventLine from './comps/EventLine'
// import testMenu from './comps/TestMenu'

import '@fullcalendar/core/vdom'
import fullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import categorySelection from './comps/CategorySelection'

const SIDE_PANELS = ['category','data']
// const SIDE_PANEL_CATEGORY = 0
// const SIDE_PANEL_DATA = 1

export default {
  name: 'calendarMainPage',
  mixins: [panelMixin, dateTimeHelperMixin, dialogMixin, appColorMixin,printTemplateDialogMixin],
  components: {
    panelBase,

    confirmDeleteDialog,
    calendarPanelSetupDialog,
    popupRecordDialog,
    EditTemplateDialog,
    // menuButton,
    // inputRadioToggle,
    formToggle,
    // eventMenuButton,
    // eventStripe,
    eventLine,
    // testMenu,

    fullCalendar,
    formSelectionDialog,
    // formSelectionMenu,

    categorySelection,
  },
  data () {
    return {
      activeSidePanelIndex: 0,
      activeFormId: '',
      sideMenuMode: 'category', // [form|category]
      menuValue: false,
      loading: false,
      loadingData: false,
      loadingEventId: '',
      value: '',
      type: 'month',
      forms: [],
      // events: [],
      formDataCollection: [],
      monthIndex: -1,
      lastStartDate: '',
      selectedFormIds: [],
      events: [],
      showingNewMenu: false,
      selectedDate: null,
      selectedCategoryIds: [],
      displayStart: '', // in format YYYY-MM-DD
      displayEnd: '' // in format YYYY-MM-DD
    }
  },
  props: {
    panelSettings: null
  },
  mounted () {    
    const vm = this
    vm.$forceUpdate()
    // console.log('mounted: displayMonthStart: ', vm.displayMonthStart)
    // console.log('mounted: displayMonthEnd: ', vm.displayMonthEnd)
    // console.log('mounted: vm.panelSettings: ', vm.panelSettings)
    if (vm.panelSettings) {
      vm.refreshAll()
    }
  },
  watch: {
    menuMode: function() {
      const vm = this
      // console.log('watch(menuMode) newval: ', newVal)
      setTimeout(() => {
        vm.renderCalendar()
      },500)
    },
    panelSettings: function() {
      // console.log('CalendarMainPage.panelSettings newVal: ', newVal)
      const vm = this
      vm.selectedFormIds = []
      // console.log('vm.formConfigs.length = ' + vm.formConfigs.length)
      for (let i = 0; i < vm.formConfigs.length; i++) {
        const loopFormConfig = vm.formConfigs[i]
        // console.log('loopformConfig: ' + JSON.stringify(loopFormConfig))
        vm.selectedFormIds.push(loopFormConfig.formId)
        if (loopFormConfig.categoryField && loopFormConfig.categoryField !== '') {
          const fieldInfo = vm.getFieldInfo(loopFormConfig.formInfo, loopFormConfig.categoryField)
          // in case the field (single-selection) is removed, may not exists any more
          // 
          if (fieldInfo) {
            const options = fieldInfo.properties.inputOptionsSingle.options
            const optionIds = options.map(option => option._id)
            vm.addCategoryIds(optionIds)
          }
        }
      }
      // console.log('vm.selectedFormIds: ' + vm.selectedFormIds.join(', '))
      this.refreshData()
    }
  },
  computed: {
    activeSidePanel() {
      return SIDE_PANELS[this.activeSidePanelIndex]
    },

    menuMode () {
      return this.$store.getters.menuMode
    },

    allSelected() {
      return this.allFormsSelected && this.allCategoriesSelected
    },

    allCategoriesSelected() {
      const vm = this
      let result = true
      for (let i = 0; i < vm.categorySections.length; i++) {
        const section = vm.categorySections[i]
        if (!vm.getIsAllCategoriesInSectionSelected(section)) {
          result = false
          break
        }
      }
      return result
    },

    isColorBasedOnForm () {
      return this.panelSettings && this.panelSettings.colorBasedOn ?
        this.panelSettings.colorBasedOn==='byForm' :
        'random'
    },
    isColorBasedOnCategory () {
      return this.panelSettings && this.panelSettings.colorBasedOn ?
        this.panelSettings.colorBasedOn==='byCategory' :
        'random'
    },
    allFormsSelected () {
      let result = true
      for (let i = 0; i < this.formConfigs.length; i++) {        
        const loopFormConfig = this.formConfigs[i]
        if (!this.selectedFormIds.includes(loopFormConfig.formId)) {
          result = false
          break
        }
      }
      return result
    },
    categorySections () {
      const vm = this
      let result = []
      for (let i = 0; i < vm.formConfigs.length; i++) {
        const loopFormConfig = vm.formConfigs[i]
        if (loopFormConfig && loopFormConfig.categoryField) {
          const categoryFieldInfo = vm.getFieldInfo(loopFormConfig.formInfo, loopFormConfig.categoryField)
          if (categoryFieldInfo) {
            const {selectionOptions, useColor, globalSelectionDataSource} = vm.getCategoryOptions(loopFormConfig)

            // if globalSelectionDataSource, check existence
            if (globalSelectionDataSource !== '') {
              const found = result.find(item => item.globalSelectionDataSource === globalSelectionDataSource)
              if (found) {
                found.sectionInfos.push({
                  formId: loopFormConfig.formInfo._id,
                  formName: loopFormConfig.formInfo.label,
                  fieldName: categoryFieldInfo.label
                })
              } else {
                result.push({
                  sectionInfos: [{
                    formId: loopFormConfig.formInfo._id,
                    formName: loopFormConfig.formInfo.label,
                    fieldName: categoryFieldInfo.label
                  }],
                  options: selectionOptions,
                  globalSelectionDataSource: globalSelectionDataSource,
                  useCategoryColor: vm.panelSettings.colorBasedOn === 'byCategoryField' && useColor
                })
              }
            } else {
              result.push({
                sectionInfos: [{
                  formId: loopFormConfig.formInfo._id,
                  formName: loopFormConfig.formInfo.label,
                  fieldName: categoryFieldInfo.label
                }],
                options: selectionOptions,
                globalSelectionDataSource: globalSelectionDataSource,
                useCategoryColor: vm.panelSettings.colorBasedOn === 'byCategoryField' && useColor
              })
            }
          }
        }
      }
      return result
    },

    currentMonthStart () {
      return this.getMonthStartDate()
    },

    currentMonthEnd () {
      return this.getMonthEndDate()
    },

    config () {
      return {
        ...this.configOptions,
        ...this.eventHandlers
      }
    },

    configOptions () {
      const vm = this
      return {
        locale: vm.$i18n.locale,
        editable: true,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        height: '100%',
        events: this.events,
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        intialView: 'dayGridMonth',
        headerToolbar: {
          left: 'title',
          center: 'dayGridMonth,timeGridWeek,listWeek',
          right: 'today,prev,next'
        },
        buttonText: {
          'month': vm.$t('calendar.month'),
          'week': vm.$t('calendar.week'),
          'list': vm.$t('calendar.list')
        },
        timeFormat: 'HH:mm'
      }
    },
    eventHandlers () {
      return {
        dateClick: this.onDateClick,
        // eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
        select: this.onDateSelect,
        // viewRender: this.onViewRender,
        datesSet: this.onMonthChange,
        dayCellClassNames: this.formItemsWithAdd.length > 0
          ? 'can-add'
          : '',
      }
    },
    monthName () {
      const vm = this
      const moment = vm.$locale.m(vm.lastStartDate)
      return moment.format('MMMM')
    },


    displayTypes () {
      return [
        {label: 'Month', value: 'month'},
        {label: 'Week', value: 'week'},
        {label: 'Day', value: 'day'},
      ]
    },
    formConfigOptions () {
      const vm = this
      let result = []
      if (vm.formConfigs.length > 0) {
        for (let i = 0; i < vm.formConfigs.length; i++) {
          const loopConfig = vm.formConfigs[i]
          result.push({
            label: loopConfig.formInfo.label,
            value: loopConfig.formId,
            color: loopConfig.color
          })
        }
      }
      return result
    },
    formConfigs () {
      const vm = this
      let result = []
      if (vm.panelSettings && vm.panelSettings.formConfigs) {
        for (let i = 0; i < vm.panelSettings.formConfigs.length; i++) {
          const loopConfig = vm.panelSettings.formConfigs[i]
          if (loopConfig.formInfo.canAccess) {
            result.push(loopConfig)
          }
        }
      }
      return result
    },
    formItemsWithAdd () {
      const vm = this
      let result = []
      if (this.formConfigs && this.formConfigs.length > 0) {
        for (let i = 0; i < vm.formConfigs.length; i++) {
          const loopConfig = vm.formConfigs[i]
          if (loopConfig.formInfo.canAdd) {
            result.push({
              label: loopConfig.formInfo.label,
              value: loopConfig.formId
            })
          }
        }
      }
      return result
    },
    title () {
      const vm = this
      return this.panelSettings ? 
        this.panelSettings.title : 
        '(' + vm.$t('general.title') + ')'
    },
    subTitle () {
      const vm = this
      return this.panelSettings ?
        this.panelSettings.subTitle : 
        '(' + vm.$t('general.subTitle') + ')'
    }

  },
  methods: {
    onResize () {
      this.renderCalendar()
    },
    toggleAll () {
      if (this.allSelected) {
        this.clearAll()
      } else {
        this.showAll()
      }
    },
    getIsAllCategoriesInSectionSelected(section) {
      const vm = this
      const allOptionIds = section.options.map(option => option._id)
      let result = true
      allOptionIds.forEach(id => (result = result && vm.selectedCategoryIds.includes(id)))
      return result
    },
    showAll () {
      this.setAllForms(true)
      this.setAllCategory(true)
    },
    clearAll () {
      this.selectedFormIds = []
      this.selectedCategoryIds = []
      this.showFormRows()
    },
    setAllCategory (state) {
      const vm = this
      let result = []
      if (state) {
        for (let i = 0; i < vm.categorySections.length; i++) {
          const loopSection = vm.categorySections[i]
          for (let j = 0; j < loopSection.options.length; j++) {
            result.push(loopSection.options[j]._id)
          }
        }
      }
      vm.selectedCategoryIds = result
    },
    onMonthChange(payload) {
      const vm = this
      // console.log('onMonthChange vm.displayStart = ' + vm.displayStart)
      // console.log('onMonthChange payload.startStr = ' + payload.startStr)
      
      const displayStart = payload.startStr.substr(0,10) 
      if (vm.displayStart !== displayStart) {
        vm.displayStart = displayStart
        vm.displayEnd = payload.endStr.substr(0,10)
        vm.getEvents()
      }
    },
    // onViewRender (view, element) {
    //   alert('onViewRender')
    // },
    // onChangeMonth (start, end, currentMonthStartDate) {      
    //   console.log('onChangeMonth: start: ', start)
    //   console.log('onChangeMonth: end: ', end)
    //   console.log('onChangeMonth: stcurrentMonthStartDateart: ', currentMonthStartDate)
    // },
    addCategoryIds (optionIds) {
      const vm = this
      for (let i = 0; i < optionIds.length; i++) {
        const loopOptionId = optionIds[i]
        if (vm.selectedCategoryIds.indexOf(loopOptionId) === -1) {
          vm.selectedCategoryIds.push(loopOptionId)
        }
      }
    },
    updateSelectedCategoryIds (ids) {
      this.selectedCategoryIds = ids
      this.showFormRows()
    },
    updateSelectedFormIds (ids) {
      this.selectedFormIds = ids
      this.showFormRows()
    },
    getCategoryOptions (formConfig) {
      let result = {
        selectionOptions: [],
        useColor: false,
        globalSelectionDataSource: ''
      }
      const categoryField = formConfig.categoryField
      if (categoryField) {
        const fieldInfo = formConfig.formInfo.fieldInfos.find(info => info.fieldId === categoryField)
        if (fieldInfo) {
          switch (fieldInfo.type) {
            case 'singleSelection':
              result.selectionOptions = fieldInfo.properties.inputOptionsSingle.options
              result.useColor = fieldInfo.properties.inputOptionsSingle.useColor
              result.globalSelectionDataSource = fieldInfo.properties.selectionDataSource
              break
            case 'multipleSelection':
              result.selectionOptions = fieldInfo.properties.inputOptionsMultiple.options
              result.useColor = fieldInfo.properties.inputOptionsMultiple.useColor
              result.globalSelectionDataSource = fieldInfo.properties.selectionDataSource
              break
          }
        }
      }
      return result
    },
    toggleAllForms () {
      this.setAllForms()
    },
    setAllForms(state) {      
      if (typeof state === 'undefined') {
        // console.log('toggleAllForms :: state is undefined')
        if (this.allFormsSelected) {
          this.selectedFormIds = []
        } else {
          this.selectedFormIds = this.formConfigs.map(config => config.formId)
        }
      } else {
        // console.log('toggleAllForms :: state = ' + (state ? 'yes' : 'no'))
        if (state) {
          this.selectedFormIds = this.formConfigs.map(config => config.formId)
        } else {
          this.selectedFormIds = []
        }
      }
      this.$nextTick(() => {
        this.showFormRows()
      })
      
    },
    getFieldInfo (formInfo, fieldId) {
      return formInfo ?
        formInfo.fieldInfos.find(info => info.fieldId === fieldId) :
        []
    },


    renderCalendar() {
      // console.log('fullCalendar: ', this.$refs.fullCalendar)
      // console.log('fullCalendar.getApi(): ', this.$refs.fullCalendar.getApi())
      const calendarApi = this.$refs.fullCalendar.getApi()
      this.$forceUpdate()
      calendarApi.render()
      calendarApi.refetchEvents()
    },



    getDisplayMonthStartEnd () {
      return {
        start: this.displayStart,
        end: this.displayEnd
      }
    },

    onDateClick(arg) {
      const vm = this
      // console.log('onDateClick : arg: ', arg)
      vm.selectedDate = arg.dateStr
      if (vm.formConfigs.length === 1) {
        const formConfig = vm.formConfigs[0];
        if (formConfig.formInfo.canAdd) {          
          this.newEvent(vm.formConfigs[0].formId);
        }
      } else {
        // console.log('vm.panelSettings.formConfigs: ', vm.formConfigs);
        if (vm.formItemsWithAdd.length > 0) {
        // const formConfigsWithAdd = vm.panelSettings.formConfigs.filter(config => {
        //   return config.formInfo.canAdd
        // });
        // if (formConfigsWithAdd.length > 0) {
          this.showingNewMenu = true;
        }
      }
    },

    // onEventClick (arg) {
    //   console.log('onEventClick: arg: ', arg)
    // },
    onEventDrop (arg) {
      const vm = this
      const delta =arg.delta
      if (delta.days !== 0 ||
        delta.milliseconds !== 0 ||
        delta.months !== 0 ||
        delta.years !== 0) {
        const extendedProps = arg.event.extendedProps
        const formId = extendedProps.formId
        const formConfig = vm.getFormConfig(formId)
        let record = arg.event.extendedProps.data

        let updatedStart = arg.event.start
        let updatedEnd = arg.event.end
        vm.updateRecord(record, updatedStart, updatedEnd, formConfig)
        vm.updateEvent(arg.event.id, {
          start: updatedStart, //vm.getSimpleDate(updatedStart),
          end: updatedEnd // vm.getSimpleDate(updatedEnd)
        })

        vm.saveEvent(record)
      }
    },

    updateEvent (eventId, data) {
      const vm = this
      for (let i = 0; i < vm.events.length; i++) {
        if (vm.events[i].id === eventId) {
          for (let key in data) {
            vm.events[i][key] = data[key]
          }
        }
      }
    },

    updateRecord(record, updatedStart, updatedEnd, formConfig) {
      const vm = this
      const startField = formConfig.startField
      const endField = formConfig.endField

      const isStartDateTimeField = vm.isDateTimeField(formConfig.formInfo, startField)
      const isEndDateTimeField = vm.isDateTimeField(formConfig.formInfo, endField)

      record[startField] = isStartDateTimeField ?
        vm.dateTimeToYPStr(updatedStart) :
        vm.getSimpleDate(updatedStart)

      if (endField && endField !== '') {
        if (record[endField] && record[endField] !== null) {
          if (!updatedEnd) {
            updatedEnd = updatedStart
          }
          let adjustedEnd = new Date(updatedEnd.toString())
          adjustedEnd.setDate(adjustedEnd.getDate()-1)
          record[endField] = isEndDateTimeField ?
            vm.dateTimeToYPStr(updatedEnd) :
            vm.getSimpleDate(adjustedEnd)
        }
      }
    },



    async saveEvent (record) {
      const vm = this
      const postData = {
        urlCommand: '/data',
        data: {
          data: record,
          isNew: false      
        }
      }
      await vm.$store.dispatch('AUTH_POST', postData)
    },


    onDateSelect (arg) {
      console.log('onDateSelect: arg: ', arg)
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'deleteEvent':
          vm.deleteEvent(payload.event)
          break
        case 'editEvent':
          vm.editEvent(payload.event)
          break
        case 'print':
        this.print(payload)
        break;
      }
    },
    editEvent (event) {
      const vm = this
      const extendedProps = event.extendedProps
      vm.loadingData = true;
      vm.loadingEventId = event.id
      vm.loadForm(extendedProps.formId)
      .then(formInfo => {

        vm.$refs.popupRecordDialog.open({
          defaultValues: {},
          fieldLabel: '',
          formInfo: formInfo,
          onSubmitted: vm.onEditRecordSubmitted,
          recordId: event.id,
          viewId:extendedProps.viewId
        })
        vm.loadingData = false;
        vm.loadingEventId = ''
      })
    },

    onEditRecordSubmitted () {    
      this.refreshData()
    },

    deleteEvent (event) {
      const vm = this
      vm.$refs.confirmDeleteDialog.confirm(() => {
        vm.$store.dispatch('DELETE_FORM_RECORD', {
          appId: vm.$store.getters.currentApp._id,
          formId: event.extendedProps.formId,
          include: [event.id]
        })
        .then(() => {
          vm.showSuccess('messages.deletedSuccessfully')

          const eventIndex = vm.events.findIndex(item => item.id === event.id)
          if (eventIndex >= 0) {
            vm.events.splice(eventIndex, 1)
          }
        })
      })
    },
    getEventColorPair (event) {
      return {
        color: event.color,
        textTheme: event.textTheme
      }
    },

    setEventColor(event) {
      let color = 'primary'
      let textTheme = 'light'

      event.color = color
      event.textTheme = textTheme
    },
    getDateFilters (formConfig, startDate, endDate) {

      let result = []

      if (formConfig.startField && formConfig.startField !== '') {
        result.push({
          fieldId: formConfig.startField,
          filterValue1: endDate,
          filterValue2: '',
          relation: 'earlierThan'
        })
      }

      if (formConfig.endField && formConfig.endField !== '') {
        result.push({
          fieldId: formConfig.endField,
          filterValue1: startDate,
          filterValue2: '',
          relation: 'laterThan'
        })
      } else {
        result.push({
          fieldId: formConfig.startField,
          filterValue1: startDate,
          filterValue2: '',
          relation: 'laterThan'
        })
      }

      console.log('getDateFilters: result: ', result)

      return result
    },


    getEvents () {
      const vm = this
      const {start, end} = vm.getDisplayMonthStartEnd()

      // vm.lastStartDate = start.date
      vm.loadingData = true
      let allPromises = []
      for (let i = 0; i < vm.formConfigs.length; i++) {
        const loopFormConfig = vm.formConfigs[i]
        const formId = loopFormConfig.formId


        const dateFilters = vm.getDateFilters(loopFormConfig, start, end)

        // console.log('dateFilters: ', dateFilters)

        if (dateFilters.length > 0) {
          const params = {
            appId: vm.$store.getters.currentApp._id,
            formId: formId,
            viewId: '_',
            filter: JSON.stringify(dateFilters)
          }
          allPromises.push(vm.$store.dispatch('FETCH_DATA_LIST', params))
        }
      }


      Promise.all(allPromises).then(responses => {
        let formRows = []      
        for (let i = 0; i < responses.length; i++) {
          // console.log('responses[' + i + ']: ', responses[i])
          const formConfig = vm.formConfigs[i]
          // console.log('formConfig: ', formConfig)
          const rows = responses[i].result
          formRows.push({
            formId: formConfig.formId,
            rows: rows
          })
        }
        vm.formDataCollection = formRows
        vm.showFormRows()
        vm.loadingData = false
      })
    },

    showFormRows () {
      const vm = this
      let events = []
      for (let i = 0; i < vm.formConfigs.length; i++) {
        const formConfig = vm.formConfigs[i]
        if (vm.selectedFormIds.includes(formConfig.formId)) {        
          const formData = vm.formDataCollection.find(collection => collection.formId === formConfig.formId)

          let color = 'primary';
          let textTheme = "";
          switch (vm.panelSettings.colorBasedOn) {
            case 'byForm':
              color = formConfig.color
              textTheme = vm.getTextTheme(formConfig.color)
              break
            case 'byCategoryField':                            
              break
            default:
              color = vm.getRandomColor()
              textTheme = vm.getTextTheme(color)
          }
          // console.log('formData: ', JSON.stringify(formData))
          for (let i = 0; i < formData.rows.length; i++) {
            const loopRow = formData.rows[i]    

            if (vm.panelSettings.colorBasedOn === 'random') {
              color = vm.getRandomColor()
              textTheme = vm.getTextTheme(color)
            }
            const startField = formConfig.startField
            const endField = formConfig.endField

            let start = null
            let end = null
            let isStartDateTimeField = false
            let isEndDateTimeField = false

            if (startField !== '') {
              const startValue = loopRow[startField]
              const endValue = loopRow[endField]

              isStartDateTimeField = vm.isDateTimeField(formConfig.formInfo, startField)
              isEndDateTimeField = vm.isDateTimeField(formConfig.formInfo, endField)


              if (startValue) {
                start = isStartDateTimeField ? 
                  new Date(startValue+':00') : new Date(vm.extractDate(startValue)) // new Date(vm.extractDate(startValue)+' 00:00:00')
              }

              if (endValue) {
                if (isEndDateTimeField) {
                  end = new Date(endValue + ':00')
                } else {
                  end = //vm.extractDate(endValue) 
                  // new Date(vm.extractDate(endValue) + ' 23:59:59')
                    new Date(vm.extractDate(endValue))
                  // console.log('end: ', end)
                  // console.log('end.getDay() = ' + end.getDay())
                  // console.log('end.getDate() = ' + end.getDate())
                  end.setDate(end.getDate() + 1)
                }
              }
            }
            // console.log('showFormRows :: start: ', start)
            // console.log('showFormRows :: end: ', end)

           
            let textColor = textTheme === 'dark' ? 'white' : 'black'

            // console.log('formConfig.categoryField = ' + formConfig.categoryField)
            if (formConfig.categoryField && 
              formConfig.categoryField !== '') {
              
              const categoryId = loopRow[formConfig.categoryField]
              const categoryFieldInfo = vm.getFieldInfo(formConfig.formInfo, formConfig.categoryField)

              if (categoryFieldInfo) {
                if (!vm.selectedCategoryIds.includes(categoryId)) continue

                const inputOptionsInfo = categoryFieldInfo.properties.inputOptionsSingle // vm.getInputOptionsInfo(formConfig.formInfo, formConfig.categoryField)


                // console.log('categoryValue: ', categoryOptionInfo)

                if (vm.panelSettings.colorBasedOn==='byCategoryField') {

                  if (inputOptionsInfo.useColor) {
                    const option = inputOptionsInfo.options.find(option => option._id === categoryId)
                    
                    if (option) {
                      const colorPair = option.colorSet.split('/')
                      color = colorPair[1]
                      textColor = colorPair[0]
                      textTheme = textColor === '#000000' ? 'light' : 'dark'
                    }
                  }
                }
              }
            }

            let title = loopRow[formConfig.titleField]
            const titleFieldInfo = formConfig.formInfo.fieldInfos.find(
              info => info.fieldId === formConfig.titleField
            )
            if (!title) {
              if (titleFieldInfo) {
                title = '(' + titleFieldInfo.label + ')'
              } else {
                title = '(' + vm.$t('general.noTitle') + ')'
              }
            }
            const event = {
              id: loopRow._id,
              title: title,
              name: loopRow[formConfig.titleField],
              // date: vm.extractDate(loopRow[formConfig.startField]),
              start: start,
              end: end,
              allDay: !isStartDateTimeField,
              timed: vm.isDateTimeField(formConfig.formInfo, formConfig.startField),
              category: loopRow[formConfig.categoryField],
              color: color,
              backgroundColor: color,
              textColor: textTheme === 'dark' ? 'white' : 'black',
              textTheme: textTheme,
              formId: formConfig.formId,
              viewId: formConfig.viewId,
              data: loopRow,
              startEditable: loopRow.canEdit
            }

            events.push(event)
          }
        }
      }
      vm.events = events
    },

    getInputOptionsInfo (formInfo, fieldId) {
      let result = null
      const fieldInfo = formInfo.fieldInfos.find(info => info.fieldId === fieldId)
      if (fieldInfo && fieldInfo.type === 'singleSelection') {
        result = fieldInfo.properties.inputOptionsSingle
      }
      return result
    },
    getCategoryValue (formInfo, fieldId, optionId) {

      let result = null      
      const fieldInfo = formInfo.fieldInfos.find(info => info.fieldId === fieldId)
      if (fieldInfo && fieldInfo.type === 'singleSelection') {
        const options = fieldInfo.properties.inputOptionsSingle.options
        result = options.find(option => option._id === optionId)
      }
      return result
    },

    isDateTimeField (formInfo, fieldId) {
      // console.log('isDateTimeField : fieldId = ' + fieldId);
      // console.log('isDateTimeField : formInfo._id = ' + formInfo._id);

      let result = false;
      if (fieldId && formInfo && formInfo.fieldInfos) {
        // console.log('formInfo.fieldInfos: ', formInfo.fieldInfos);

        const fieldInfo = formInfo.fieldInfos.find(info => info.fieldId === fieldId);
        result = fieldInfo.properties.fieldType === 'datetime';
      }
      return result;
    },

    getFormConfig (formId) {
      return this.panelSettings.formConfigs.find(config => config.formId === formId)
    },

    getDateTimeDefault(formInfo, fieldId, defaultDate, defaultDateTimeHHMM) {
      const fieldInfo = formInfo.fieldInfos.find(info => info.fieldId === fieldId)
      if (fieldInfo.properties.fieldType === 'date') {
        return defaultDate
      } else {
        return defaultDateTimeHHMM
      } 
    },

    newEvent (formId) {
      const vm = this
      vm.showingNewMenu = false;
      vm.loadingData = true;
      const formConfig = vm.getFormConfig(formId)
      let defaultValues = {}

      const selectedDate = vm.selectedDate
      const todayTimeHHMM = vm.getNow()
      const timeHHMM = todayTimeHHMM.substr(10)
      const defaultDate = selectedDate
      const defaultDateTimeHHMM = selectedDate + timeHHMM

      const startField = formConfig.startField
      if (startField && startField !== '') {
        defaultValues[startField] = vm.getDateTimeDefault(formConfig.formInfo, startField, defaultDate, defaultDateTimeHHMM)
      }

      const endField = formConfig.endField
      if (endField && endField !== '') {
        defaultValues[endField] = vm.getDateTimeDefault(formConfig.formInfo, endField, defaultDate, defaultDateTimeHHMM)
      }

      vm.loadForm(formId)
      .then(formInfo => {
        vm.loadingData = false;
        vm.$refs.popupRecordDialog.open({
          defaultValues: defaultValues,
          fieldLabel: '',
          formInfo: formInfo,
          onSubmitted: vm.onNewRecordSubmitted,
          recordId: ''
        })
      })
    },

    onNewRecordSubmitted () {
      this.refreshData()
    },

    refreshAll () {
      const vm = this
      vm.getEvents()
    },

    async loadForm (formId) {
      const vm = this
      if (formId) {
        return await vm.$store.dispatch('FETCH_FORM', {
          formId: formId,
          appId: vm.$store.getters.currentApp._id
        })
      }
    },


    refreshData () {  
      this.getEvents()
    },
    onRefreshClicked () {
      this.$emit('onCommand', {command: 'refresh'});
      // this.refreshData()
    },
    openCalendarPanelSettingsDialog () {
      const vm = this
      vm.$refs.calendarPanelSetupDialog.open({
        panelSettings: vm.panelSettings
      })
    },
    onSettingsUpdated (panelSettings) {
      this.panelSettings = panelSettings;
    }
  }

}
</script>

<style lang="scss">
.calendar-wrapper:hover .new-event-button {
  display: block;
}
.calendar-wrapper .new-event-button {
  display: none;  
}
.event-form-tag {
  background-color: transparent;
  color:grey;
}
.event-form-tag.active {
  color: white;
}
.hover-grey {
  position: relative;
}
.hover-grey:hover .hover-mask {
  background-color:rgba(0,0,0,.05);
  display: block;
}
.hover-grey .hover-mask {
  position:absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.fc-button.fc-button-primary {
  background-color: lightgray !important;
  color: black;
  border-color: lightgrey !important;
  box-shadow: none !important;
  margin:0 3px;
  min-width: 56px;
}

.fc .fc-button .fc-icon {
  vertical-align: top;
}
.fc .fc-button-primary:disabled {
    color: #fff;
    color: darkgray;
    background-color: #2C3E50;
    background-color: let(--fc-button-bg-color, #2C3E50);
    border-color: #2C3E50;
    border-color: let(--fc-button-border-color, #2C3E50);
}


.fc-button.fc-button-primary.fc-button-active {
  background-color: $primary !important;
  color: white;
}
.height-16 {
  min-height: 16px !important;
}

.fc-header-toolbar .fc-toolbar-chunk:first-child,
.fc-header-toolbar .fc-toolbar-chunk:last-child {
  width: 34%;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  text-align: center;
  width: 32%;
}
.fc-header-toolbar .fc-toolbar-chunk:last-child {
  text-align: right;
}

.side-menu-selection .v-tab {
  background-color: rgba(200,200,200,1);
  color: rgba(100,100,100,.2) !important;
  border: 1px solid rgba(200,200,200,0.5);
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}
.side-menu-selection .v-tab.selected {
  background-color: transparent;
  color: black !important;
  border-bottom-color: transparent !important;
}

.fc-media-screen .fc-view-harness {
  overflow-y: scroll;
}
.calendar-main-page .can-edit .menu-button {
  display: block;
}
.fc-daygrid-body .fc-day:hover {
  background-color: rgba(128,128,128,.1);
}
.fc-daygrid-body .fc-day.fc-day-today:hover {
  background-color: rgba(255, 220, 40, 0.25)
}
.fc-daygrid-body .fc-day.can-add {
  cursor: pointer;
}
</style>
