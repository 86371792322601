<template>
  <div class="workflow-customized-button-popup">
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small class="workflow-customized-button-popup-edit" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </template>
      <v-card>
        <v-card-title class="text-h5 workflow-customized-action-alert-title">
          {{$t('workflow.modules.actionProcess.label.confirmTitle')}}
        </v-card-title>
        <v-card-text class="workflow-customized-action-alert-text">
          <FormSetting :popup="popup" :buttonName="buttonName" />
        </v-card-text>
        <v-card-actions class="workflow-customized-action-alert-action">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="cancel"
          >
            {{
              $t('workflow.label.popupCustomimzedAction.cancelLabel')
            }}
          </v-btn>
          <v-btn
            color="primary"
            class="workflow-customized-action-alert-submit"
            @click="submit"
          >
            {{
              $t('workflow.label.popupCustomimzedAction.confirmLabel')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout align-center class="w-customized-button-popup-row">
      <div class="w-customized-button-popup-prefix">{{$t('workflow.modules.actionProcess.label.popup.title')}}:</div>
      <div class="w-customized-button-popup-label"
        v-html="htmlTitle || $t('workflow.label.popupCustomimzedAction.title', { 
          label: this.buttonName,
        })"
      />
    </v-layout>
    <v-layout align-center class="w-customized-button-popup-row">
      <div class="w-customized-button-popup-prefix">{{$t('workflow.modules.actionProcess.label.popup.confirm')}}:</div>
      <div class="w-customized-button-popup-label">{{
        popup.confirmLabel || $t('workflow.label.popupCustomimzedAction.confirmLabel')
      }}</div>
    </v-layout>
    <v-layout align-center class="w-customized-button-popup-row">
      <div class="w-customized-button-popup-prefix">{{$t('workflow.modules.actionProcess.label.popup.cancel')}}:</div>
      <div class="w-customized-button-popup-label">{{
        popup.cancelLabel || $t('workflow.label.popupCustomimzedAction.cancelLabel')
      }}</div>
    </v-layout>
  </div>
</template>

<script>
import FormSetting from './form';
export default {
  components: {
    FormSetting,
  },
  props: {
    buttonName: String,
    popup: {
      type: Object,
      default: {
        title: '',
        confirmLabel: '',
        cancelLabel: '',
      },
    },
  }, 
  computed: {
    htmlTitle() {
      const { title } = this.popup;
      return title && title.replace(/(?:\r\n|\r|\n)/g, '<br />');
    },
  },
  mounted() {
    this.wasPopup = { ...this.popup };
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.popup = { ...this.wasPopup };
    },
    submit() {
      this.dialog = false;
      this.wasPopup = { ...this.popup };
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style scoped>

  .w-customized-button-popup-label {
    margin-left: 10px;
    font-weight: 900;
  }
  .w-customized-button-popup-prefix {
    width: 20%;
  }
  .w-customized-button-popup-row {
    margin: 10px 0;
  }
</style>