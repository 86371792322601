const mixin = {
    methods:{
        setOptionYAxis(){
            this.option.yAxis[0] = {
                type: 'value'
            }
        },
        setOptionXAxis(x){
            this.option.xAxis[0] = {
                type: 'category',
                data: x
            }
        },
        setOptionSeries(y, g, y2){
            let vm = this
            if(vm.selectedChart == 'COMBO'){
                y2.forEach((item)=>{
                    let obj = {}
                    obj.data = item
                    obj.type = 'line'
                    let formatter = '{c}'
                    obj.label = {
                        show: true,
                        position: 'outside',
                        formatter: formatter
                    }
                    vm.option.series.push(obj)
                })
            }
            y.forEach((item, index)=>{
                let obj = {}
                if(vm.selectedChart != 'RADAR'){
                    obj.data  = item
                    if(vm.dataSetups.arrY[index].type!=="recordCount")
                        obj.name  = vm.dataSetups.arrY[index].label
                    else
                        obj.name  = this.$t("chart.recordCount")
                }
                else{
                    obj.data = [{
                        name: vm.dataSetups.arrY[index].type!=="recordCount"?
                              vm.dataSetups.arrY[index].label : this.$t("chart.recordCount"),
                        value: item
                    }]
                }
                if(vm.selectedChart=='PIE' || vm.selectedChart=='FUNNEL'){
                    obj.data.forEach((item,index,array)=>{
                        array[index] = {
                            value: item,
                            name: vm.dataSetups.dataX[index]
                        }
                    })
                }
                obj.type = vm.selectedChart == 'COMBO'? 'bar':vm.selectedChart.toLowerCase()
                if(vm.dataSetups.arrG.length){
                    obj.name = g[index]
                }else{
                    // obj.name = vm.dataSetups.arrY[index].label
                }
                let formatter = '{b}'
                if(vm.selectedChart == 'RADAR' || vm.selectedChart == 'BAR' || vm.selectedChart == 'LINE' || vm.selectedChart == 'COMBO'){
                    formatter = '{c}'
                    obj.areaStyle = {}
                }
                obj.label = {
                    show: true,
                    position: 'outside',
                    formatter: formatter
                }
                obj.labelLine = {
                    show: vm.selectedChart=='PIE'||vm.selectedChart=='FUNNEL'?true:false
                }
                vm.option.series.push(obj)
            })
            console.log(vm.option.series)
        },
        setOptionRadar(y){
            let vm = this
            let max = null
            y.forEach(item=>{
                let localMax = Math.max(...item)
                if(max==null || max<localMax)
                    max = localMax
            })
            vm.option.radar={
                indicator: []
            }
            vm.dataSetups.dataX.forEach(item=>{
                let obj = {
                    name: item,
                    max: max
                }
                vm.option.radar.indicator.push(obj)
            })
            console.log('setOptionRadar', this.option)
        },
        async setChartType(type, oldType){
            if(!oldType) return
            this.dataSetups.dataX = []
            this.dataSetups.dataY = []
            this.dataSetups.data2Y = []
            this.dataSetups.dataG = []
            if(this.option.series && this.option.series.length>1){
                this.option.series = [this.option.series.shift()]
            }
            if(type=='TABLE'){
                // this.arrX = []
                // this.arrY = []
                // this.arrG = []
            }
            if(oldType=='TABLE'){
                this.dataSetups.arrX = []
                this.dataSetups.arrY = []
                this.dataSetups.arrG = []
            }
            if(type != "BAR" && type!="LINE" && type!='TABLE')
                this.dataSetups.arrG = []
            if(type != "COMBO")
                this.dataSetups.arr2Y = []
            if(type == "PIE" || type=="FUNNEL"){
                if(this.dataSetups.arrY.length>1){
                    this.dataSetups.arrY = [this.dataSetups.arrY[0]]
                }
                this.displayCol = 'all'
                // var res = this.arrY.find(item=>item.fieldId==this.displayCol)
                // if(!res) this.displayCol = 'all'
            }
            switch(type){
                case 'BAR':
                    this.styleSetups.chartFigure = "vertical"
                    break
                case 'LINE':
                    this.styleSetups.chartFigure = "broken"
                    break
                case 'PIE':
                    this.chartFigure = "pie"
                    break
                case 'RADAR':
                    // this.chartFigure = "radar"
                    break
                case 'FUNNEL':
                    this.styleSetups.chartFigure = 'vertical'
                    break
            }
            await this.FetchData()
        },
        setChartFigure(figure, bl_reset = true){
            if(!this.option || !this.chart)return
            let vm = this
            switch(figure){
                case 'vertical':
                    if(this.selectedChart!='FUNNEL'){
                        if(this.option.xAxis && this.option.yAxis && this.option.xAxis.length && this.option.yAxis.length){
                            if(!this.option.xAxis[0].hasOwnProperty("data")){
                                let tempAxis = JSON.parse(JSON.stringify(this.option.xAxis))
                                this.option.xAxis = JSON.parse(JSON.stringify(this.option.yAxis))
                                this.option.yAxis = tempAxis
                            }
                        }
                    }else{
                        this.option.series.forEach((item,index,array)=>{
                            array[index].orient = 'vertical'
                            array[index].label={
                                show: true,
                                position: 'outside',
                                formatter: '{b}: {c}',
                                rich:{
                                    a:{
                                        fontSize: '15px'
                                    }
                                },
                            }
                            delete array[index].emphasis
                        })
                    }
                break
                case 'horizontal':
                    if(this.selectedChart!='FUNNEL'){
                        if(this.option.xAxis && this.option.yAxis && this.option.xAxis.length && this.option.yAxis.length){
                            if(this.option.xAxis[0].hasOwnProperty("data")){
                                let tempAxis = JSON.parse(JSON.stringify(this.option.xAxis))
                                this.option.xAxis = JSON.parse(JSON.stringify(this.option.yAxis))
                                this.option.yAxis = tempAxis
                            }
                        }
                    }else{
                        this.option.series.forEach((item,index,array)=>{
                            array[index].orient = 'horizontal'
                            array[index].label={
                                show: true,
                                position: 'outside',
                                formatter: '{b}: {c}',
                                rich:{
                                    a:{
                                        fontSize: '15px'
                                    }
                                },
                            }
                            delete array[index].emphasis
                        })
                    }
                break
                case 'broken':
                    this.option.series.forEach((item,index, array)=>{
                        array[index].smooth = false
                        array[index].areaStyle = null
                    })
                break
                case 'curve':
                    this.option.series.forEach((item,index,array)=>{
                        array[index].smooth = true
                        array[index].areaStyle = null
                    })
                break
                case 'area':
                    this.option.series.forEach((item,index, array)=>{
                        array[index].smooth = true
                        array[index].areaStyle = {}
                    })
                break
                case 'pie':
                    if(this.option.series.length>1)
                        this.option.series.pop()
                    this.option.series.forEach((item, index, array)=>{
                        array[index].radius =  ['0%', '75%']
                        console.log(item.data)
                        array[index].data = item.data.map((item2, index2, array2)=>{            
                            return {
                                value: typeof item2 == 'object'? item2.value: item2,
                                name: vm.dataSetups.dataX[index2]
                            }
                        })
                    })
                    this.option.tooltip = {
                        trigger: 'item'
                    }
                break
                case 'doughnut':
                    this.option.series.forEach((item, index, array)=>{
                        array[index].radius =  ['45%', '75%']
                        console.log('item', item)
                        array[index].data = item.data.map((item2, index2, array2)=>{            
                            return {
                                value: typeof item2 == 'object'? item2.value: item2,
                                name: vm.dataSetups.dataX[index2]
                            }
                        })
                    })
                    // if(this.option.series.length){
                    //     var serieClone = JSON.parse(JSON.stringify(this.option.series[0]))
                    //     this.option.series.push(serieClone)
                    //     this.option.series[1].label={
                    //         show: true,
                    //         position: 'center',
                    //         formatter: '{a|總計}\n \n' + '{a|' + 100 + '}',
                    //         rich:{
                    //         a:{
                    //             fontSize: '15px'
                    //         }
                    //         },
                    //     }
                    //     this.option.series[1].emphasis = {
                    //         label: {
                    //             align: 'center',
                    //             show: true,
                    //             fontSize: '20',
                    //             fontWeight: 'bold',
                    //             formatter: '{a|{b}}\n\n{a|{c}}',
                    //             backgroundColor: 'white',
                    //             rich:{
                    //                 a:{
                    //                     fontSize: '15px',
                    //                     backgroundColor: "white",
                    //                     width:150
                    //                 },
                    //             },
                    //         },
                    //     }
                    // }
                break
            }
            this.setXAxisRotate(this.styleSetups.xAxis.bl_xRotate)
            if(bl_reset)
                this.option && this.chart.setOption(this.option, true)
        }
    }
}

export default mixin