import { getRelativeTime } from "@/helpers/DataHelpers";
const mixin = {
  props: {
    parentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,

      //pagination
      limit: 10,
      offset: 0,
      hasNextPage: false,

      items: [],
    };
  },
  computed: {
    appId() {
      return (
        (this.$store.getters.currentApp &&
          this.$store.getters.currentApp._id) ||
        ""
      );
    },
    formId() {
      return this.$store.getters.currentForm
        ? this.$store.getters.currentForm._id
        : "";
    },
  },
  methods: {
    resetRelativeTime() {
      this.items.forEach((item) => {
        Object.assign(item, { relativeTime: this.calculateRelativeTime(item) });
      });
    },
    calculateRelativeTime(item) {
      return getRelativeTime(item.createdAt);
    },
  },
};

export default mixin;
