<template>
  <div class="flex-grow-1 d-flex flex-row" style="position:relative;">
    <field-drag-list
        class="mr-1"
        :title="sourceListTitle"
        style="width:50%;"
        v-model="sourceFieldIds"
        buttonColor="cyan"
        buttonIcon="mdi-playlist-plus"
        :button="useAddAllButton"
        @onButtonClicked="addAll"
        @onItemClicked="addField"
        :excludeFieldIds="excludeFieldIds"
    >
      <template v-slot="slotProps">
        {{ fieldIdLabelMap[slotProps.id] }}
      </template>
    </field-drag-list>

      <field-drag-list
        :title="targetListTitle"
        style="width:50%;"
        v-model="targetFieldIds"
        buttonColor="cyan"
        buttonIcon="mdi-playlist-remove"
        :button="useRemoveAllButton"
        @onButtonClicked="removeAll"
        @onItemClicked="removeField"
        :excludeFieldIds="excludeFieldIds"
      >
      <template v-slot="slotProps">
        {{ fieldIdLabelMap[slotProps.id] }}
      </template>
      <template v-slot:option="slotProps">
        <v-tooltip top
                   v-for="option in options"
                   :key="option-id">
          <template v-slot:activator="{on, attrs}">
            <v-btn x-small
                  elevation="0"
                   fab
                   :ripple="false"
                   class="ml-1"
                   v-bind="attrs"
                   v-on="on"
                   @click.stop.prevent="setFieldOption(slotProps.id, option)"
                   :color="getOptionColor(slotProps.id, option)">
              <v-icon>{{ option.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t(option.tooltipTag) }}</span>
        </v-tooltip>
      </template>
    </field-drag-list>
  </div>
</template>

<script>
import fieldDragList from '@/components/dialogs/comps/FieldDragList'

export default {
  name: 'fieldSelectionDualList',
  components: {
    fieldDragList
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      default () {
        return {
          fieldIds: [],
          values: []
        }
      }
    },
    type:{
      type: String,
      default: ''
    },

    sourceListTitle: String,
    targetListTitle: String,
    useAddAllButton: Boolean,
    useRemoveAllButton: Boolean,

    optionExclusive: Boolean,
    options: {
      type: Array,
      default () {
        return []
      }
    },

    fieldInfos: {
      type: Array,
      default () {
        return []
      }
    }
  },
  // watch: {
  //   targetFieldIds: {
  //     handler: function(newVal) {
  //       console.log('watch(targetFieldId): newVal: ', newVal)
  //     },
  //     deep: true
  //   },
  //   targetFieldIdsStr: function(newVal) {
  //     const vm = this
  //     console.log('watch(targetFieldIdsStr) : newVal = ' + newVal)
  //   }
  // },
  // data () {
  //   return {
  //     targetFieldIds: []
  //   }
  // },
  mounted(){
  },
  computed: {
    fieldOptionMap () {
      const vm = this
      var result = {}
      const defaultOptionValue = vm.options[0].value
      for (let i = 0; i < vm.allFieldIds.length; i++) {
        const loopFieldId = vm.allFieldIds[i]
        const targetFieldIndex = vm.targetFieldIds.findIndex(
          fieldId => fieldId === loopFieldId)

        result[loopFieldId] = defaultOptionValue
        if (targetFieldIndex >= 0) {
          if(vm.selectedOptions[targetFieldIndex])
            result[loopFieldId] = vm.selectedOptions[targetFieldIndex]
        } 
        // else {
        //   result[loopFieldId] = defaultOptionValue
        // }
      }
      return result
    },
    selectedOptions () {
      const vm = this
      var result = []
      if (vm.value.values) {
        result = vm.value.values
      }
      return result
    },
    // targetFieldIdsStr () {
    //   const vm = this
    //   var result = JSON.stringify(vm.targetFieldIds)
    //   console.log('computed: targetFieldIdsStr = ' + result)
    //   return result
    // },
    targetFieldIds: {
      get () {
        const vm = this
        var result = []
        if (vm.value.fieldIds) {
          result = vm.value.fieldIds
        }
        return result
      },
      set (val) {
        console.log('set(targetFieldIds): val: ', val)
        const vm = this
        var optionValues = []
        for (let i = 0; i < val.length; i++) {
          const fieldId = val[i]
          console.log("vm.fieldOptionMap", vm.fieldOptionMap)
          console.log("vm.fieldOptionMap[fieldId]", vm.fieldOptionMap[fieldId])
          optionValues.push(vm.fieldOptionMap[fieldId])
        }
        vm.$emit('input', {
          fieldIds: val,
          values: optionValues
        })
        vm.$forceUpdate()
      }
    },
    excludeFieldIds(){
      if(this.type=='sorting')
        return this.fieldInfos.filter(item=>item.type=='multipleSelection').map(info => info.fieldId)
      return []
    },
    allFieldIds () {
      const vm = this
      return vm.fieldInfos.map(info => info.fieldId)
    },
    sourceFieldIds () {
      const vm = this
      return vm.allFieldIds.filter(fieldId => !vm.targetFieldIds.includes(fieldId))
    },
    fieldIdLabelMap () {
      // mappings: {
      //    {fieldId: {fieldName}},
      //    {fieldId: {fieldName}},
      //    {fieldId: {fieldName}}
      // }
      const vm = this
      var result = {}
      for (var i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i]
        result[loopFieldInfo.fieldId] = Object.keys(loopFieldInfo.properties).indexOf('fieldName') >= 0 ?
          loopFieldInfo.properties['fieldName'] :
          '(' + vm.$t('general.unspecified') + ')'
        if (Object.keys(loopFieldInfo.properties).indexOf('fieldNameTag') >= 0) {
          result[loopFieldInfo.fieldId] = vm.$t(loopFieldInfo.properties['fieldNameTag'])
        }
      }
      return result
    }
  },

  methods: {
    setFieldOption (fieldId, option) {
      const vm = this
      const fieldIndex = vm.targetFieldIds.findIndex(id => id === fieldId)
      const newOptionValues = JSON.parse(JSON.stringify(vm.value.values))
      console.log('setFieldOption :: fieldIndex = ' + fieldIndex)
      console.log('setFieldOption :: newOptionValues: ', newOptionValues)
      newOptionValues[fieldIndex] = option.value
      vm.$emit('input', {
        fieldIds: vm.targetFieldIds,
        values: newOptionValues
      })
    },
    getOptionColor (fieldId, option) {
      const vm = this
      const optionValue = vm.fieldOptionMap[fieldId]
      if (vm.optionExclusive) {
        return option.value===optionValue ? 'primary' : ''
      } else {
        return optionValue.includes(option.value) ? 'primary' : ''
      }
    },
    // getOptionValue (fieldId) {
    //   const vm = this
    //   var result = vm.options[0].value
    //   const index = vm.targetFieldIds.findIndex(loopFieldId => loopFieldId === fieldId)
    //   if (index < vm.selectedOptions.length) {
    //     result = vm.selectedOptions[index]
    //   }
    //   return result
    // },
    addAll () {
      console.log('FieldSelectionDualList :: allAll')
      const vm = this
      var newTargetFieldIds = JSON.parse(JSON.stringify(vm.targetFieldIds))
      var remainingFieldIds = vm.sourceFieldIds
      remainingFieldIds = remainingFieldIds.filter(item=>!this.excludeFieldIds.includes(item))
      for (let i = 0; i < remainingFieldIds.length; i++) {
        const loopFieldId = remainingFieldIds[i]
        newTargetFieldIds.push(loopFieldId)
      }
      vm.targetFieldIds = newTargetFieldIds
    },
    addField (fieldId) {
      console.log('FieldSelectionDualList :: addField(fieldId = ' + fieldId + ')')
      this.targetFieldIds = [...this.targetFieldIds, fieldId]
      console.log('FieldSelectionDualList :: this.targetFieldIds: ', this.targetFieldIds)
    },
    removeAll () {
      console.log('FieldSelectionDualList :: removeAll')
      this.targetFieldIds = []
    },
    removeField (fieldId) {
      console.log('FieldSelectionDualList :: removeField(fieldId = ' + fieldId + ')')
      this.targetFieldIds = this.targetFieldIds.filter(id => id !== fieldId)
    }
  }
}
</script>
