<template>
  <div class="tab-filters flex-grow-1 d-flex flex-column pa-2 fill-height">
    <div class="rounded blue darken-3 white--text px-3 py-1"><h4>{{ $t(`view.${type}`) }}</h4></div>
    <div class="flex-grow-1 d-flex flex-column" style="position:relative;">
      <div class="px-1 my-1 d-flex flex-row align-center justify-space-between">
        <field-selection-button
            ref="fieldSelectionButton"
            :fieldInfos="filteredFieldInfos"
            @click="addFilter"></field-selection-button>
        <!--<field-selection-button-->
            <!--ref="fieldSelectionButton"-->
            <!--:fields="fields"-->
            <!--@onCommand="onCommandHandler"></field-selection-button>-->

        <div v-if="filters.length > 0">
           <input-radio-toggle :options="andOrOptions"
                               v-model="filterAndOr"/>
        </div>
      </div>
      <div ref="filterListContainer" class="flex-grow-1" style="height:0;;min-height:100px;overflow-y:auto;">
        <v-list ref="filterList">
          <filter-widget
            :ref="'filter_'+index"
            v-for="(filter, index) in filters"
            :key="index"
            :andOrOptionLabel="getAndOrOptionLabel(filterAndOr)"
            :fields="fields"
            :filterIndex="index"
            :filter="filter"
            :dataSourceInfo="dataSourceInfo" 
            @input="val=>updateFilter(index,val)"
            @onCommand="onCommandHandler"></filter-widget>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import filterWidget from '@/components/filterWidgets/FilterWidget'

// import filterWidgets from './filterWidgets'
import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
import fieldSelectionButton from '@/components/buttons/FieldSelectionButton'

// import adminFormMixin from '@/mixins/adminFormMixin'

export default {
  name: 'tabFilters',
  mixins: [mixin],
  components: {
    filterWidget,
    inputRadioToggle,
    fieldSelectionButton
  },
  methods: {
    deleteAllFilters () {
      const vm = this
      vm.tabData[vm.tabKey].filters = []
    },
    updateFilter (index, val) {
      const vm = this
      alert('updateFilter')
      vm.tabData[vm.tabKey].filters[index] = val
    },
    getAndOrOptionLabel (andOr) {
      const vm = this
      var result = 'and'
      const andOrOption = vm.andOrOptions.find(item => item.value === andOr)
      if (andOrOption) {
        result = vm.$t(andOrOption.labelTag)
      }
      return result
    },

    _checkErrorCount (state) {
      const vm = this
      var errorCount = 0
      if (vm.tabId && vm.tabId !== -1) {
        const filterFields = Object.keys(vm.$refs).filter(item => item.indexOf('filter_') === 0)

        console.log('tabFilters :: checkErrorCount :: count = ' + filterFields.length)
        for (var i = 0; i < filterFields.length; i++) {
          const loopFilterFieldName = filterFields[i]
          var refComp = vm.$refs[loopFilterFieldName]
          console.log('_checkErrorCount :: loopFilterFieldName = ' + loopFilterFieldName)
          if (Array.isArray(refComp)) {
            console.log('tabFilters :: checkErrorCount :: validate :: refComp is instance of array')
            refComp = refComp[0]
          } else {
            console.log('tabFilters :: checkErrorCount :: validate :: refComp is not array: refComp: ', refComp)
          }
          if (refComp) {
            console.log('tabFilters :: checkErrorCount :: refComp: ', refComp)
            if (!refComp.checkErrorCount(true)) {
              errorCount++
            }
          }
        }
      }
      return errorCount
    },
    // getFilterWidgetByFieldId (fieldId) {
    //   const vm = this
    //   return 'filterWidgetString'
    // },
    scrollToLastFilter () {
      const vm = this
      vm.$nextTick(() => {
        const container = vm.$refs.filterListContainer
        if (container) 
          container.scrollTop = container.scrollHeight
      })
    },
    onCommandHandler (payload) {
      // console.log('ViewSettingsFilter :: onCommandHandler :; payload: ', payload)
      const vm = this
      switch (payload.command) {
        case 'deleteAllFilters':
          vm.deleteAllFilters()
          break
        case 'deleteFilter':
          vm.deleteFilter(payload.fieldIndex)
          break
        // case 'addFilter':
        //   vm.addFilter(payload.fieldId)
        //   break
        default:
          vm.$emit('onCommand', payload)
      }
    },
    deleteFilter (fieldIndex) {
      const vm = this
      vm.tabData[vm.tabKey].filters.splice(fieldIndex, 1)
    },
    addFilter (fieldInfo) {
      const vm = this
      const fieldId = fieldInfo.fieldId
      
      // console.log('tabFilters :: addFilter : fieldInfo: ', fieldInfo)
      // console.log('tabFilters :: addFilter : fieldId = ' + fieldId)

      // console.log('tabFilter :: vm.tabData: ', vm.tabData)
      // console.log('tabFilter :: vm.tabData.fields: ', vm.tabData.fields)

      // console.log('tabFilters :: vm.widgetMap: ', vm.widgetMap)
      // console.log('tabFilters :: vm.filterConfigs: ', vm.filterConfigs)

      const relation = vm.getRelationByFieldId(fieldId)
      if(!vm.tabData[vm.tabKey]){
        vm.$set(vm.tabData, vm.tabKey, {
          filters: [],
          filterAndOr: 'and'
        })
      }
      vm.tabData[vm.tabKey].filters.push({
        fieldId: fieldId,
        relation: relation,
        filterValue1: null,
        filterValue2: null
      })
    },
    // addFilter (fieldId) {
    //   const vm = this
    //   console.log('tabFilters :: addFilter : fieldId = ' + fieldId)
    //   console.log('tabFilter :: vm.tabData: ', vm.tabData)
    //   console.log('tabFilter :: vm.tabData.fields: ', vm.tabData.fields)
    //   // console.log('tabFilters :: vm.form.fieldInfos: ', vm.form.fieldInfos)
    //   console.log('tabFilters :: vm.widgetMap: ', vm.widgetMap)
    //   console.log('tabFilters :: vm.filterConfigs: ', vm.filterConfigs)
    //   // const fieldInfo = vm.form.fieldInfos.find(info => info.fieldId === fieldId)
    //
    //   const relation = vm.getRelationByFieldId(fieldId)
    //   vm.tabData[vm.tabKey]['filterConfigs'].filters.push({
    //     fieldId: fieldId,
    //     relation: relation,
    //     filterValue1: null,
    //     filterValue2: null
    //   })
    // },
  },
  computed: {
    fields () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopInfo = vm.fieldInfos[i]
        const filterType = vm.getFilterTypeByFieldInfo(loopInfo)
        result.push({
          id: loopInfo.fieldId,
          label: loopInfo.label,
          filterType: filterType,
          fieldInfo: loopInfo
        })
      }
      return result
    },
    filterConfigs () {
      return this.tabData && 
        this.tabData[this.tabKey] ?
        this.tabData[this.tabKey] : null
        // ['filterConfigs'] ?
        // this.tabData[this.tabKey]['filterConfigs'] : null
    },
    filters: {
      get () {
        return this.filterConfigs && this.filterConfigs['filters'] ?
          this.filterConfigs['filters'] :
          []
        // const vm = this
        // var result = []
        // if (vm.filterConfigs) {
        //   return vm.filterConfigs.filterstabData && vm.tabData[vm.tabKey]) {
        //   result = vm.tabData[vm.tabKey]['filterConfigs'].filters
        // }
        // return result
      },
      set (val) {
        const vm = this        
        vm.tabData[vm.tabKey].filters = val
      }
    },
    filterAndOr: {
      get () {
        return this.filterConfigs ? this.filterConfigs.filterAndOr :
         'and'
        // const vm = this
        // var result = 'and'
        // if (vm.tabData && vm.tabData[vm.tabKey]) {
        //   result = vm.tabData[vm.tabKey]['filterConfigs'].filterAndOr
        // }
        // return result
      },
      set (val) {
        const vm = this
        vm.tabData[vm.tabKey].filterAndOr = val
      }
    },
    filteredFieldInfos(){
      return this.fieldInfos.filter(field=>![
        'region',
        'location',
        'richText',
        'capitalizedAmount',
        'otherTableField',
        'childTable'
      ].includes(field.type))
    }
  },
  data () {
    return {
      andOrOptions: [
        {labelTag: 'general.and', value: 'and'},
        {labelTag: 'general.or', value: 'or'}
      ]
    }
  }
}
</script>
