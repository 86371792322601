<template>
  <v-dialog
    v-model="showConfirm"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
        v-bind="attrs"
        v-on="on"
        elevation="0"
      >
        <v-icon
          :color="$vuetify.theme.themes.light.primary"
          size="15"
          :disabled="loading"
          dark
        >
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
    <v-card class="workform-confirm-box">
      <v-layout justify-space-between align-center>
        <v-card-title class="workform-confirm-box-title w-control-remove-title">
          {{ $t('workflow.modules.actionProcess.label.removeButton', { name: item.name }) }}
        </v-card-title>
        <v-btn icon @click="closeDialog()">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <v-card-text>
        <v-radio-group v-model="fully">
          <v-radio :value="false">
            <template v-slot:label>
              <div>
                <div class="w-control-merge-label">
                  {{ $t('workflow.modules.actionProcess.remark.onlyRemoveButton') }}
                </div>
              </div>
            </template>
          </v-radio>
          <v-radio :value="true">
            <template v-slot:label>
              <div>
                <div class="w-control-merge-label">
                  {{ $t('workflow.modules.actionProcess.remark.fullyRemoveButton') }}
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="workform-confirm-box-btn"
          :disabled="loading"
          @click="closeDialog()"
        >
          {{ $t('workflow.btn.cancel') }}
        </v-btn>
        <v-btn
          color="#f44336"
          class="workform-confirm-box-btn w-control-remove-btn-confirm"
          :disabled="loading"
          @click="remove"
        >
          {{ $t('workflow.btn.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from "@/event-bus.js";
import {
  REMOVE_ACTION_BUTTON,
} from '@/store/modules/customizedAction/action_types';
export default {
  data() {
    return {
      showConfirm: false,
      fully: false,
    };
  },
  props: {
    item: Object,
    form: Object,
  },
  computed: {
    loading() {
      return this.$store.getters.loadingAnyAction;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
  },
  methods: {
    closeDialog() {
      this.showConfirm = false;
    },
    remove() {
      const { viewId } = this.$route.params;
      this.$store.dispatch(REMOVE_ACTION_BUTTON, {
        buttonId: this.item._id,
        formId: this.form._id,
        appId: this.currentApp._id,
        viewId,
        fully: this.fully,
      }).then(res=>{
        EventBus.$emit('removeActionButton', res)
      });
      this.closeDialog();
    },
  },
};

</script>

<style scoped>
  .w-control-remove-title {
    color: rgb(244, 67, 54);
  }
  .w-control-remove-btn-confirm {
    color: white;
  }
</style>
