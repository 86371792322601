<template>
  <input-widget-container
    style="position:relative;"
    :isLayout="isLayout"
    :widget="widget"
    :isHidden="isHidden"
    :required="required"
    :hideLabel="hideLabel"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :labelClass="offset - label"
    :stretch="stretch"
    :class="{
      'mb-2 mt-3': stretch === 'auto',
      'd-flex flex-column fill-height': stretch === 'true',
    }"
    :tooltipText="description"
  >
    <!-- @click:new="insertRow"
        @click:edit="editRow" -->

    <div :class="stretch === 'true' ? 'd-flex flex-column fill-height' : ''">
      <common-table-view
        ref="commonTableView"
        @openChildRecordDialog="openChildRecordDialog"
        :tableConfig="tableConfig"
        :loading="refreshing"
        :fieldInfos="(childTableFormInfo || {}).fieldInfos"
        :isLayout="isLayout"
        :readonly="isReadOnly"
        v-model="inputValue"
        :parentFieldInfo="fieldInfo"
        :relationData="relationData"
        :tableFormInfo="childTableFormInfo"
        :hasControl="!isReadOnly"
        :relatedTableInfos="relatedTableInfos"
        @onCommand="onCommandHandler"
      />
      <v-input
        v-if="!isReadOnly"
        class="w-100 flex-grow-0"
        :hide-details="stretch === 'true'"
        :value="inputValue"
        :error="hasError"
        :error-messages="errorMessage"
      >
      <!-- :error-messages="errorMessage" -->
        <div class="my-1">
          <v-btn
            :disabled="refreshing"
            @click="insertRow({ recordData: createBlankRow(), isNew: true })"
            :elevation="0"
            fab
            x-small
            color="primary mr-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            :disabled="refreshing"
            @click="popupNew"
            :elevation="0"
            fab
            x-small
            color="primary mr-1"
          >
            <v-icon>mdi-text-box-plus</v-icon>
          </v-btn>
        </div>
      </v-input>
    </div>

    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <!--<child-record-dialog ref="childRecordDialog"-->
    <!--@submit="childRecordSubmitted"></child-record-dialog>-->
  </input-widget-container>
</template>

<script>
import inputWidgetContainer from "./comps/InputWidgetContainer";
import mixin from "./mixin";
import formFieldMixin from "@/mixins/formFieldMixin";
import helperMixin from "@/mixins/helpersMixin";
import commonTableView from "@/components/CommonTableView";
import ObjectID from "bson-objectid";

import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";

export default {
  name: "inputWidgetChildTable",
  mixins: [mixin, formFieldMixin, helperMixin],
  components: {
    inputWidgetContainer,
    confirmDeleteDialog,
    commonTableView,
  },
  data() {
    return {
      refreshing: false,
      RULE_REQUIRED: (v) => {
        return this.value.length > 0 || this.$t("messages.thisFieldIsRequired");
      },
      relationData: {
        idDataMapping: {},
      },
    };
  },
  async mounted() {
    if (!this.isNew && !this.isLayout) {
      await this.fetchData();
    }
  },
  computed: {
    recordId() {
      return this.recordData ? this.recordData._id : "";
    },
    tableDataSource() {
      return (this.fieldsEditor && this.fieldsEditor.formId) || "";
    },
    relationFormId() {
      return this.fieldsEditor ? this.fieldsEditor.relationFormId : "";
    },
    childTableFormInfo() {
      let result = null;
      if (
        this.relatedTableInfos &&
        this.childTableFormId &&
        !this.isLayout
      ) {
        result = this.relatedTableInfos[this.childTableFormId] ;
      } else {
        result = {
          fieldInfos: this.assignViewPermission(this.fieldsEditor.fieldInfos),
        };
      }
      return result;
    },
    childTableAppId() {
      return this.activeFormInfo.appId;
    },
    allTableDisplayFieldIds() {
      if (this.fieldsEditor) {
        return this.fieldsEditor.fixedFieldIds.concat(
          this.fieldsEditor.nonFixedFieldIds
        );
      } else return [];
    },
    fieldsEditor() {
      const vm = this;
      var result = null;
      if (
        vm.fieldInfo &&
        vm.fieldInfo.properties &&
        vm.fieldInfo.properties.fieldsEditor
      ) {
        result = vm.fieldInfo.properties.fieldsEditor;
      }
      return result;
    },

    tableConfig() {
      const vm = this;
      var result = {
        fixedFieldIds: [],
        nonFixedFieldIds: [],
      };
      if (vm.fieldsEditor) {
        result.fixedFieldIds = vm.fieldsEditor.fixedFieldIds;
        result.nonFixedFieldIds = vm.fieldsEditor.nonFixedFieldIds;
      }
      return result;
    },

    childTableFormId() {
      const vm = this;
      var result = "";
      if (vm.fieldsEditor) {
        result = vm.fieldsEditor.formId;
      }
      return result;
    },
    childTableTitleFieldInfoId() {
      return this.childTableFormInfo
        ? this.childTableFormInfo.titleFieldInfoId
        : "";
      // return this.fieldsEditor ? this.fieldsEditor.titleFieldInfoId : "";
    },
    childTableFieldInfos() {
      return this.childTableFormInfo ? this.childTableFormInfo.fieldInfos : [];
    },
  },
  watch:{
    value(newVal){ //for detecting record refresh
      if (newVal.length > 0 && typeof newVal[0] === "string"){
        this.fetchData()
      }
    }
  },
  methods: {
    validate(){
      if (this.isReadOnly) return true;

      this.errorMessage = ''
      var valid = !this.rules.some((rule) => rule(this.value) !== true);
      if(!valid){
        let msg = ""
        for (let i = 0; i < this.rules.length && msg === ""; i++) {
          const result = this.rules[i](this.value);
          msg = typeof result === "string" ? result : "";
        }
        this.errorMessage = msg;
      }
      this.hasError = !valid
      return valid
    },
    fetchData() {
      this.$store
        .dispatch("FETCH_RELATED_DATA_LIST", {
          formId: this.activeFormInfo._id,
          recordId: this.recordId,
          fieldId: this.fieldId,
          dataSource: this.childTableFormId,
          isPublic: this.$store.getters.isPublicRoute,
        })
        .then((response) => {
          const {canDelete, canEdit} = this.recordData;
          this.inputValue = [].concat(response.result.map(childRecord => ({
            ...childRecord,
            canDelete, canEdit
          })));
          this.fetchRelationDetails(response.result);
        });
    },
    fetchRelationDetails(rows) {
      fetchRelatedDataFieldsInRows(
        this,
        rows,
        null,
        this.isPublic,
        this.childTableFieldInfos,
        this.allTableDisplayFieldIds,
      ).then((response) => {
          this.relationData.idDataMapping = response;
        })
    },
    newRelatedRecord(payload) {
      this.$emit("onCommand", {
        command: "newRelatedRecord",
        ...payload,
      });
    },
    insertRow({ recordData, isNew }) {
      this.$refs.commonTableView.insertEvent(recordData, isNew);
      this.validate()
    },
    popupNew() {
      this.$refs.commonTableView.popupNew({
        record: this.createBlankRow(),
        isNew: true,
        fieldInfos: this.childTableFieldInfos.map((info) => ({
          ...info,
          canEdit: true,
          canCreate: true,
        })),
        relatedTableInfos: this.relatedTableInfos,
        fieldInfo: this.fieldInfo,
        formInfo: this.childTableFormInfo,
        onSubmitted: this.insertRow,
        allChildRecords: this.value,
      });
    },
    addRow({ recordData }) {
      this.inputValue.push(recordData);
      this.validate()
    },
    assignViewPermission(fieldInfos) {
      const vm = this;
      var result = [];
      if (fieldInfos) {
        for (let i = 0; i < fieldInfos.length; i++) {
          const loopInfo = fieldInfos[i];
          result.push({
            ...loopInfo,
            canView: true,
          });
        }
      }
      return result;
    },

    openChildRecordDialog(payload) {
      this.$emit("onCommand", {
        command: "openChildRecordDialog",
        ...payload,
      });
    },
    updateSummaryField(payload) {
      const vm = this;

      var recordIds = [];

      const relatedFormId =
        payload && payload.formId ? payload.formId : vm.childTableFormId;
      const updateDb =
        payload && !!payload.updateDb ? !!payload.updateDb : !vm.isNew;

      // Get all summary fields related to this related record field
      var summaryFieldInfos = vm.fieldInfos.filter(
        (info) => info.properties.tableField === vm.fieldInfo._id
      );
      var summaryConfigs = [];

      for (let i = 0; i < summaryFieldInfos.length; i++) {
        const loopInfo = summaryFieldInfos[i];
        summaryConfigs.push({
          fieldId: loopInfo.fieldId,
          summaryFieldId: loopInfo.properties.summaryField,
          summaryFunction: loopInfo.properties.summaryFunction,
        });
      }

      // pass to server for summary calculation
      const params = {
        id: vm.recordData._id,
        formId: vm.formInfo._id,
        summaryFormId: relatedFormId,
        summaryRecordIds: recordIds, // empty array to indicate ALL
        summaryConfigs: summaryConfigs,
        updateDb: updateDb,
        fieldType: "childTable",
        isTable: true,
        relationFormId: vm.fieldsEditor.relationFormId,
      };
      vm.$store.dispatch("UPDATE_SUMMARY_FIELDS", params).then((response) => {
        if (response) {
          for (let fieldId in response) {
            const fieldValue = response[fieldId] || 0;
            const wasValue = vm.recordData[fieldId] || 0;
            vm.$emit("onCommand", {
              command: "updateFieldValue",
              fieldName: fieldId,
              fieldValue: fieldValue.toString(),
              retainKey: String(fieldValue) !== String(wasValue),
            });
          }
          vm.$nextTick(() => {
            vm.$store
              .dispatch("UPDATE_CASCADE_SUMMARY", {
                formId: vm.formInfo._id,
                recordId: vm.recordData._id,
              })
          });
        }
      });
    },
    onChildTableUpdated(payload) {
      this.updateSummaryField(payload);
    },
    childRecordSubmitted(payload) {
      this.updateRow(payload.recordData, payload.recordIndex);
    },

    assignAuthorization(fieldInfos, permission) {
      const vm = this;
      var result = [];
      for (let i = 0; i < fieldInfos.length; i++) {
        const loopInfo = fieldInfos[i];
        result.push({
          ...loopInfo,
          ...permission,
        });
      }
      return result;
    },

    saveRow(row, index) {
      const vm = this;
      for (let i = 0; i < vm.inputValue.length; i++) {
        const loopRow = vm.inputValue[i];
        if (loopRow._id === row._id) {
          vm.updateRow(row, i);
        }
      }
    },
    deleteRow(row, index) {
      const vm = this;
      vm.inputValue.splice(index, 1);
      this.validate()
    },

    updateRow(row, index) {
      const vm = this;
      for (let fieldId in vm.inputValue[index]) {
        if (fieldId !== "_id") {
          vm.inputValue[index][fieldId] = row[fieldId];
        }
      }
    },

    createBlankRow() {
      const vm = this;
      var result = {
        _id: "_" + ObjectID().toHexString(),
        canEdit: true,
      };
      for (let i = 0; i < vm.childTableFieldInfos.length; i++) {
        const loopFieldInfo = vm.childTableFieldInfos[i];
        result[loopFieldInfo.fieldId] = vm.getFieldDefault(loopFieldInfo);
      }
      this.validate()
      return result;
    },

    getDefaultValue() {
      return [];
    },

    onCommandHandler(payload) {
      this.$emit("onCommand", payload);
    },
    clear(){
      this.inputValue = []
    }
  },
};
</script>

<style>
.child-table .vxe-body--row.is--new {
  background-color: #f1fdf1;
}
</style>
