<template>
  <div class="drop-zone"></div>
</template>

<script>
export default {
}
</script>
<style>
    .drop-zone {
      width:100%;
      height:5px;
      /*display: none;*/
    }

  .drop-list.drop-allowed .drop-zone:hover {
    background-color: blue;
  }
  .drop-list.drop-allowed .drop-zone {
    display: block;
    background-color: rgba(0, 0, 0, .05);
  }
</style>
