import Vue from "vue";

export default {
  FETCH_PUBLIC_WIDGETS: ({ rootGetters, dispatch, commit }) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/widgets";

      Vue.axios
        .get(url)
        .then((res) => {
          if (res.data) {
            commit("setWidgetProperties", res.data.widgetProperty);
            commit("setWidgetGroups", res.data.widgets);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  FETCH_PUBLIC_EDIT_STATUS: ({rootGetters}, payload) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/edit/" + payload.urlId + '/status';
      Vue.axios
        .get(url)
        .then((res) => {
          if (res.data) {
            const valid = res.data.result.valid;
            resolve(valid);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  FETCH_PUBLIC_FORM_STATUS: ({rootGetters}, payload) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/form/" + payload.urlId + '/status';
      Vue.axios
        .get(url)
        .then((res) => {
          if (res.data) {
            const valid = res.data.result.valid;
            resolve(valid);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  FETCH_PUBLIC_QUERY_STATUS: ({rootGetters}, payload) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/query/" + payload.urlId + '/status';
      Vue.axios
        .get(url)
        .then((res) => {
          if (res.data) {
            const valid = res.data.result.valid;
            resolve(valid);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  FETCH_PUBLIC_FORM: ({ rootGetters, commit }, payload) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/form/" + payload.urlId;
      Vue.axios
        .get(url)
        .then((res) => {
          if (res.data) {
            commit("SET_CURRENT_FORM", res.data.result && res.data.result.form) || null;
            commit("SET_RELATED_TABLE_INFO", res.data.relatedTableInfos)
          }
          // resolve(res.data.result);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
  FETCH_PUBLIC_QUERY: ({rootGetters, commit}, payload) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/query/" + payload.urlId;
      Vue.axios
      .get(url)
      .then((res) => {
        if (res.data) {
          commit("SET_CURRENT_FORM", res.data.result.form);
          commit("SET_RELATED_TABLE_INFO", res.data.relatedTableInfos)
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });     

    })
  },
  FETCH_PUBLIC_EDIT_ACCESS: ({rootGetters, commit}, payload) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/edit/" + payload.urlId;
      Vue.axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });     

    })
  },
  SUBMIT_PUBLIC_EDIT_PASSWORD: ({rootGetters, commit}, payload) => {
    return new Promise((resolve, reject) => {
      const url = rootGetters.appHost + "/public/edit/" + payload.urlId;    
      const data = payload;
      const options = {
        headers: {
          Authorization: "Bearer " + rootGetters.accessToken,
        },
      };
      Vue.axios.post(url, data, options).then(
        (res) => {
          if (res.data) {
            console.log('SUBMIT_PUBLIC_EDIT_PASSWORD::res', res.data)
            commit("SET_CURRENT_FORM", res.data.editTemplate && res.data.editTemplate.form) || null;
            commit("SET_RELATED_TABLE_INFO", res.data.relatedTableInfos)
          }
          resolve(res.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  FETCH_RELATED_FORM: ({rootGetters, dispatch}, formId) => {
    return new Promise((resolve, reject) => {
      const getParams = {
        url: rootGetters.appHost + "/public/getFormInfo/" + formId
      }
      dispatch('COMMON_GET', getParams).then((res)=> {
        resolve(res);
      },
      (err)=> {
        reject(err);
      })
    })
  },
  FETCH_RELATION_RECORD: ({rootGetters, dispatch}, payload) => {
    return new Promise((resolve, reject) => {
      const getParams = {
        url: rootGetters.appHost + "/public/getRecordById?id="+ payload.id + "&formId="+ payload.formId
      }
      dispatch('COMMON_GET', getParams).then((res)=> {
        resolve(res);
      },
      (err)=> {
        reject(err);
      })
    })    
  }
};
