<template>
  <v-layout v-if="!!info.type && info.index !== 1 && info.index !== 2" column align-center justify-space-between class="w-side-bar">
    <div class="w-side-bar-wrapper">
      
    </div>
    <div class="w-side-bar-shortcut">
      <v-tooltip
        right
        transition="slide-x-reverse-transition"
        content-class="w-arrow-control-tool fromRight"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <div 
            class="w-side-bar-shortcut-icon"
            v-bind="attrs"
            v-on="on"
            @click="openModules"
          >
            <Lottie
              :options="defaultOptions"
              :height="100"
              :width="100"
            />
          </div> -->
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            fab
            color="primary"
            class="w-side-bar-shortcut-icon"
            :disabled="isDisabled"
            @click="openModules"
          >
            <v-icon
              color="white"
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('workflow.label.selectModule') }}</span>
      </v-tooltip>
    </div>
  </v-layout>
</template>

<script>
import Lottie from 'vue-lottie';
import AddModuleAnimation from '@/pages/admin/workflow/assets/module_add.json';
import {
  TOGGLE_MODULE_MENU,
  PICKING_MODULE,
  PICK_MODULE,
} from '@/store/modules/workflow/action_types';
export default {
  inject: ['info'],
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData: AddModuleAnimation,
      },
      animationSpeed: 0.5
    }
  },
  methods: {
    openModules() {
      this.$store.dispatch(TOGGLE_MODULE_MENU)
      this.$store.dispatch(PICKING_MODULE, false);
      this.$store.dispatch(PICK_MODULE, null);
    },
  },
  computed: {
    workflow() {
      return this.$store.getters.getWorkflowInfo || {};
    },
    isDisabled() {
      return this.$store.getters.isUIBlocked || (this.workflow && this.workflow.notReady);
    }
  },
};

</script>

<style>
  .w-side-bar {
    position: fixed;
    height: calc(100% - 56px);
    left: 0;
    padding: 0 40px 10px 10px;
    top: 56px;
    z-index: 996;
    overflow: hidden;
    /* border-right: 1px solid #e0e0e0; */
    background: transparent;
  }
  .w-side-bar-shortcut-icon {
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 20px;
    /* width: 90px;
    height: 90px;
    padding: 25px;
    border-radius: 50%;
    transition: transform .3s,-webkit-transform .3s;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%); */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  }
  .w-side-bar-shortcut-icon:hover {
    /* transform: scale(1.2); */
    /* color: #1e88e5 !important; */
  }
</style>
