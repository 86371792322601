<template>
  <vxe-table-column v-bind="colDef">
    <template v-slot="{row}">
      <div v-if="row[colDef.field]!==''"
           class="text-center line-height-1">
        <span class="blue--text">{{ row[colDef.field] | moment('timezone', 'Asia/Hong_Kong', 'YYYY-MM-DD') }}</span>
        <!--<span v-else></span>-->
        <br/>
        <span class="gray--text">{{ row[colDef.field] | moment('timezone', 'Asia/Hong_Kong', 'HH:mm:ss') }}</span>
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  components: {
    summaryCell
  }
}
</script>
