<template>
  <v-row no-gutters>
    <div class="d-flex" style="width: 100%">
      <div class="flex-shrink-0" style="width: 100px" v-if="showCountryCode">
        <country-code-select
          :commonCountryCodes="commonCountryCodes"
          :items="items"
          :readonly="readonly"
          v-model="countryCode"
          item-text="dialCode"
        />
      </div>
      <div class="flex-grow-1">
        <v-text-field
          :outlined="!readonly"
          dense
          height="34px"
          :ref="fieldId"
          v-model="phone"
          :readonly="readonly"
          :disabled="readonly"
          :error-messages="errorMsg"
          :error="error"
          class="telInput"
          :class="{
            withRadius: !showCountryCode,
            'workflow-customized-form-record-element': isWorkflow,
          }"
          @blur="onBlur"
          :hide-details="hideDetails"
          background-color="white"
          :placeholder="placeholder"
        >
        </v-text-field>
      </div>
    </div>
  </v-row>
</template>
<script>
import PhoneNumber from "awesome-phonenumber";
import CountryCodeSelect from "@/components/form/CountryCodeSelect";
import { VInput } from "vuetify/lib";

export default {
  name: "TelInput",
  components: {
    CountryCodeSelect,
  },
  extends: VInput,
  props: {
    readonly: Boolean,
    fieldId: String,
    value: String,
    errorMsg: String,
    hasError: { type: Boolean, default: false },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: Array,
    showCountryCode: Boolean,
    defaultCountryCode: {
      type: String,
      default: "hk",
    },
    commonCountryCodes: Array,
    isWorkflow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countryCode: this.defaultCountryCode,
      isValid: true,
    };
  },
  methods: {
    onBlur() {
      this.$emit("blur", this.value);
      // if (!this.hasError) {
      //   this.$emit("blur", this.phone);
      // }
    },
    // validate() {
    //   if (this.rules && this.rules.length > 0) {
    //     return this.rules.some((rule) => rule(this.value) !== true);
    //   }
    // },
  },
  watch: {
    countryCode: function (val) {
      //force validate
      this.phone = this.phone;
    },
    defaultCountryCode: function (val) {
      //isLayout
      this.countryCode = val;
    },
  },
  computed: {
    phone: {
      set(val) {
        if (this.showCountryCode && val.startsWith("+")) {
          let phone = new PhoneNumber(val);
          if (phone.isValid()) {
            this.countryCode = phone.getRegionCode().toLowerCase();
            this.$emit("input", phone.getNumber("international"));
          } else this.$emit("input", val);
        } else {
          let phone = new PhoneNumber(val, this.countryCode.toUpperCase());
          if (phone.getNumber()) {
            this.$emit("input", phone.getNumber("international"));
          } else this.$emit("input", val);
        }
      },
      get() {
        if (this.value) {
          let phone = new PhoneNumber(this.value);
          if (phone.getNumber()) {
            this.countryCode =
              this.showCountryCode && phone.getRegionCode()
                ? phone.getRegionCode().toLowerCase()
                : this.countryCode;
            return this.showCountryCode
              ? phone.getNumber("national")
              : phone.getNumber("international");
          }
        }

        return this.value;
      },
    },
  },
};
</script>
<style lang="scss">
div.telInput.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__prepend-outer {
  margin: 0px !important;
  margin-top: 0px !important;
}

div.telInput.workflow-customized-form-record-element:not(.withRadius)
  .v-select
  fieldset {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

div.telInput.workflow-customized-form-record-element:not(.withRadius)
  > .v-input__control
  fieldset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.countryName {
  font-size: 12px;
}

.dialCode {
  color: #9e9e9e;
  font-size: 10px;
}
</style>
