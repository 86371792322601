<template>
  <v-layout align-center justify-space-between class="workform-clip-dropdown-option">
    <div class="workform-clip-dropdown-source">
      <v-icon small class="workform-clip-dropdown-icon">
        {{ item.icon || 'mdi-sitemap' }}
      </v-icon>
      <span class="workform-clip-dropdown-branch-label">
        {{  renderLabel ? renderLabel(item) : item.label }}
        <span
          class="workform-clip-readyLevel"
          :style="{
            color: !item.released ? 'red' : $vuetify.theme.themes.light.primary
          }"
        >
          ({{ item.released ? 
            $t('workflow.modules.subWorkflow.label.released') : 
            $t('workflow.modules.subWorkflow.label.pendingRelease') }})
        </span>
      </span>
    </div>
  </v-layout>
</template>

<script>
  export default {
    props: {
      item: Object,
      onlyPrefix: Boolean,
      showField: Boolean,
      prefixIcon: Boolean,
      shouldShowCount: Boolean,
      count: Number,
      isBranch: Boolean,
      renderLabel: Function,
    },
  }
</script>

<style>
</style>
