import { panels as types } from "../../mutation-types";
import Vue from "vue";
import defaultState from './state'

export default {
  [types.SET_CURRENT_PANEL ](state, payload) {
    console.log('SET_CURRENT_PANEL: payload: ', payload)
    state.currentPanel = payload
  },

  [types.SET_CURRENT_PANEL_PROPERTY](state, payload){
    if (payload.category && payload.setting){
        payload.key
        ? Vue.set(state.currentPanel[payload.category][payload.setting], payload.key, payload.value)
        : Vue.set(state.currentPanel[payload.category], payload.setting, payload.value)
    }else {
      Vue.set(state.currentPanel, payload.propertyKey, payload.propertyValue)
    }
  },

  [types.UPDATE_PANEL_CONFIG_CHANGED] (state, payload) {
    state.configChanged = payload.configChanged
  }
}