export const FETCH_ACTION_BUTTON = 'FETCH_ACTION_BUTTON';
export const FETCH_ACTION_BUTTON_OPTIONS = 'FETCH_ACTION_BUTTON_OPTIONS';
export const RESET_ACTION_BUTTON = 'RESET_ACTION_BUTTON';
export const INTERACT_ACTION_BUTTOIN = 'INTERACT_ACTION_BUTTOIN';
export const VIEW_ACTION_BUTTON_STATUS = 'VIEW_ACTION_BUTTON_STATUS';
export const CREATE_ACTION_BUTTON = 'CREATE_ACTION_BUTTON';
export const UPDATE_ACTION_BUTTON = 'UPDATE_ACTION_BUTTON';
export const REMOVE_ACTION_BUTTON = 'REMOVE_ACTION_BUTTON';
export const FETCH_ACTION_BUTTON_INFO = 'FETCH_ACTION_BUTTON_INFO';
export const UPDATE_ACTION_BUTTON_INFO = 'UPDATE_ACTION_BUTTON_INFO';
export const REORDER_ACTION_BUTTON = 'REORDER_ACTION_BUTTON';
export const APPEND_ACTION_BUTTON = 'APPEND_ACTION_BUTTON';

export const POPUP_ACTION_BUTTON_POPUP = 'POPUP_ACTION_BUTTON_POPUP';

export const SET_GLOBAL_ACTION = 'SET_GLOBAL_ACTION';
export const RESET_GLOBAL_ACTION = 'RESET_GLOBAL_ACTION';