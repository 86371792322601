export default {
  widgetProperty: {
    singleLine: '單行',
    multipleLine: '多行',
    required: '必須',
    noDuplication: '不可重複',
    readOnly: '唯讀',
    hiddenDuringCreation: '創建記錄時隱藏',
    useScannerInput: '啟用掃碼輸入'
  },
  messages: {
    areYouSure: '是否確定',
    pleaseInput: '請輸入',
    menuExistsCannotDelete: '此模組包含菜單，不能刪除！',
    thisFolderNotEmpty: '此菜單包含其他項目',
    noNameYet: '未有名稱',
    unsavedChange: '你有未儲存的更改，是否儲存?',
  },
  // menu: {
  //   renameAndUpdateIcon: '修改名稱/圖示',
  //   edit: '編輯',
  //   rename: '修改名稱',
  //   duplicate: '複制',
  //   moveTo: '移動到',
  //   hiddenFromNavigation: '從導航中隐藏',
  //   delete: '冊除',
  //   submenu: '下層菜單',
  //   panel: '面板',
  //   form: '表格',
  //   report: '報表'
  // },
  dialogs: {
    'warning': '注意',
    deleteMenu: '刪除菜單',
    unsavedChange: '你有未儲存的更改'
  },
  app: {
    starApplication: '星級應用',
    newMenuItem: '新建菜單項目',
    myFavourite: '我的最愛',
    module: '模組',
  },
  form: {
    view: '視圖'
  },
  general: {
    accountName: '帳戶名稱',
    belongingToTeams: '所屬公司',
    color: '顏色',
    confirmation: '確認',
    continue: '繼續',
    create: '建立',
    delete: '刪除',
    edit: '編輯',
    email: '電郵',
    firstName: '名字',
    tagType: '圖示',
    input: '輸入',
    lastName: '姓氏',
    login: '登入',
    logout: '登出',
    logo: '圖像標識',
    name: '名稱',
    new: '新增',
    noContent: '未有資料',
    notNamed: '未命名',
    profile: '個人檔案',
    random: '隨機',
    role: '身份',
    save: '儲存',
    select: '選擇',
    type: '類別'
  },
  buttons: {
    select: '選擇',
    cancel: '取消',
    ok: '確定',
    save: '儲存',
    delete: '刪除',
    close: '關閉',
    noSaveAndExit: '不儲存並離開'
  },
  badge: '徽章',
  close: '關閉',
  dataIterator: {
    noResultsText: '沒有符合條件的結果',
    loadingText: '讀取中...',
  },
  dataTable: {
    itemsPerPageText: '每頁列數：',
    ariaLabel: {
      sortDescending: '：降序排列。',
      sortAscending: '：升序排列。',
      sortNone: '無排序方式。點擊以升序排列。',
      activateNone: '點擊以移除排序方式。',
      activateDescending: '點擊以降序排列。',
      activateAscending: '點擊以移除排序方式。',
    },
    sortBy: '排序方式',
  },
  dataFooter: {
    itemsPerPageText: '每頁項目：',
    itemsPerPageAll: '全部',
    nextPage: '下一頁',
    prevPage: '上一頁',
    firstPage: '第一頁',
    lastPage: '最後頁',
    pageText: '{2} 條中的 {0}~{1} 條',
  },
  datePicker: {
    itemsSelected: '已選擇 {0}',
    nextMonthAriaLabel: '下個月',
    nextYearAriaLabel: '明年',
    prevMonthAriaLabel: '前一個月',
    prevYearAriaLabel: '前一年',
  },
  noDataText: '沒有資料',
  carousel: {
    prev: '上一張',
    next: '下一張',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '還有其他 {0} 項',
  },
  fileInput: {
    counter: '{0} 個檔案',
    counterSize: '{0} 個檔案（共 {1}）',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: '分頁導航',
      next: '下一頁',
      previous: '上一頁',
      page: '轉到頁面 {0}',
      currentPage: '當前頁 {0}',
    },
  },
  module: {
    moduleSetting: '模組設定'
  }
}
