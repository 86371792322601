<template>
  <div :style="bkgdStyle">
    <v-row>
      <v-col>
        <div class="text-center text-h3" style="margin-top:10%;">
          {{ $t('messages.accessDenied') }}<br/>
          <h4>{{ errorCode }}</h4>
          <slot>
          <v-btn x-large color="error"
                 @click="onLoginClicked"
                 class="my-5 min-width-100">{{ $t('messages.backToHomePage') }}</v-btn>
          </slot>
        </div>
      </v-col>
    </v-row>
    <!--<v-card style="height:100%;width:100%;" >-->
      <!--<v-card-text class="text-center"></v-card-text>-->
    <!--</v-card>-->
  </div>
</template>

<script>
  export default {
    // created () {
    //   alert('created :: errorCode = ' + this.$route.params.errorCode)
    // },
    // mounted () {
    //   alert('mounted :: errorCode = ' + this.$route.params.errorCode)
    // },
    computed: {
      bkgdStyle () {
        const vm = this
        return {
          height: '100%',
          width: '100%',
          backgroundPosition: 'center',
          backgroundColor: '#f9f9f9',
          backgroundSize: 'cover',
          // backgroundImage: 'url(' + require('../../assets/page403.png') + ')'
        }
      },
      errorCode () {
        return this.$route.params.errorCode
      }
    },
    methods: {
      onLoginClicked () {
        const vm = this
        // console.log('onLoginClicked')
        vm.$router.push('/my_apps')
        return
        vm.$store.dispatch('LOGOUT').then(
          () => {
            // console.log('after logout')
            vm.$store.dispatch('RESET_APPS')
            vm.$router.push('/home')
          }
        )
      }
    }
  }
</script>
