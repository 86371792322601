<template>
<v-dialog
    v-model="showingDialog"
    v-if="showingDialog"
    persistent
    width="600"
    max-width="90%">
    <v-card>
        <dialog-header
            :label="$t('general.cardDisplaySetup')"
            @close="showingDialog=false"></dialog-header>
        <v-card-text style="height:540px;"
            class="card-display-setup-content d-flex flex-column fill-height">
            <div style="overflow-y:auto;">
                <relation-card-config
                    cardType="formCard"
                    v-model="cardConfig"
                    :formFieldInfos="fieldInfos"
                    :titleFieldInfoId="titleFieldInfoId"></relation-card-config>
            </div>
        </v-card-text>
        <!-- <v-divider>
        </v-divider> -->
        <dialog-actions @onCommand="onCommandHandler"></dialog-actions>
    </v-card>
</v-dialog>
</template>

<script>
import dialogHeader from './comps/DialogHeader'
import dialogActions from './comps/DialogActions'
import relationCardConfig from '@/components/dialogs/viewSettingsTabs/comps/RelationCardConfig'

export default {
    name: 'cardDisplaySetupDialog',
    components: {
        dialogHeader,
        dialogActions,
        relationCardConfig
    },
    data () {
        return {
            showingDialog: false,
            cardConfig: null,
            callback: null,
            fieldInfos: []
        }
    },
    methods: {
        open( payload, callback) {
            const vm = this
            vm.cardConfig = payload.cardConfig
            vm.fieldInfos = payload.fieldInfos
            vm.titleFieldInfoId = payload.titleFieldInfoId
            vm.callback = callback
            vm.showingDialog = true
        },
        onCommandHandler (payload) {
            const vm = this
            switch (payload.command) {
                case 'ok':
                    if(typeof vm.callback === 'function') {
                        vm.callback(vm.cardConfig)                        
                    }
                    vm.showingDialog = false
                break
                default:
                    vm.showingDialog = false
            }
        }
    }
}
</script>

<style>
.card-display-setup-content .row:nth-child(2n) {
    background-color: #e2f7ef;
}
</style>