const mixin = {
    props:{
        label: String,
        value: {
            type: Object,
            default:{
                code: '',
                type: 'COPY'
            }
        },
        fieldInfo: Object
    },
    created(){
        if(typeof this.value!=='object' ||  this.value==null){
            var defaultObject = this.setDefaultObject()
            this.$emit('input', defaultObject)
        }
    },
    methods:{
        setDefaultObject(){
            switch(this.fieldInfo.type){
                case 'text':
                case 'number':
                case 'amount':
                case 'rating':
                    return {
                        code: '',
                        type: 'COPY'
                    }
                case 'date':
                    return {
                        type: 'COPY',
                        code: '',
                    }
                case 'members':
                    return {
                        type: 'COPY',
                        code: '',
                        members:[]
                    }
                case 'departments':
                    return {
                        type: 'COPY',
                        code: '',
                        departments:[]
                    }
                case 'relatedRecord':
                    return {
                        type: 'COPY',
                        code: '',
                        records:[]
                    }
                
            }
        },
        updateDefaultCode(val){
            this.value.code = val
        },
        updateDefaultValue(val){
            this.value = val
            this.$emit('input', val)
        }
    }
    
}

export default mixin