<template>
  <FormElement
    :label="$t('workflow.modules.retrieveRecord.label.quotaType')"
    name="outputMultiple"
  >
    <v-layout justify-space-between align-center class="w-e-ordering-selector">
      <div>
        <ComboBox
          :value="outputMultiple || null"
          :options="typeList"
          :placeholder="$t('workflow.modules.retrieveRecord.label.selectQuotaType')"
          valueFormat="id"
          @changed="changed"
        />
      </div>
    </v-layout>
  </FormElement>
</template>

<script>
// import Validator from 'validator';
import FormElement from './../shared/formElement';
import ComboBox from './../../../form/comboBox/';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import Mixin from './../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ComboBox,
    FormElement,
  },
  props: {
    outputMultiple: {
      type: String,
      default: 'single',
    },
  },
  methods: {
    changed(outputMultiple) {
      this.$store.dispatch(SET_SETTINGS, {
        outputMultiple,
      });
    },
  },
  data() {
    return {
      typeList: [
        {
          id: 'single',
          label: this.$t('workflow.modules.retrieveRecord.label.setting.signlePick')
        },
        {
          id: 'multiple',
          label: this.$t('workflow.modules.retrieveRecord.label.setting.multiplePick')
        }
      ],
    }
  },

};

</script>

<style scoped>
</style>
