/*
* Preload all of static images
* Terry Chan
* 12/05/2021
*/
const images = {
    comingSoon: require('@/assets/images/coming-soon.png'),
}

export default images
