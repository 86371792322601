<template>
  <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on">
          {{ getHumanDate(startTime, status) }}
        </span>
      </template>
      <div>
        <div>{{ $t('workflow.table.startTime') }}: {{ getFullDate(startTime, status) }}</div>
        <div v-if="isCompleteState(status) || isFailState(status)">
          {{ $t('workflow.table.finishedTime') }}: {{ getFullDate(finishedTime, status) }}
        </div>
      </div>
    </v-tooltip>
</template>

<script>
import TableMixin from './mixin';
export default {
  mixins: [TableMixin],
  props: {
    status: String,
    startTime: String,
    finishedTime: String,
  },
};

</script>

<style>

</style>
