<template>
  <div>
    <div class="w-e-datasource-link" @click="goWorkflow" :style="{ color: $vuetify.theme.themes.light.primary }">
      {{ parentWorkflow.name }}
    </div>
    <span class="w-e-source">
      {{ $t('workflow.modules.subWorkflow.label.when', {
          source: parentWorkflowNode.name
        })
      }}
      <b>
        {{ $t('workflow.modules.subWorkflow.label.sourcePoint', {
            source: parentWorkflowNode.name
          })
        }}
      </b>
      {{ $t('workflow.modules.subWorkflow.label.trigger')
      }}
    </span>
  </div>
</template>

<script>
import { get } from 'lodash';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    node: Object,
  },
  methods: {
    goWorkflow() {
      WorkflowSettingUtils.goBlankWindowWorkflow(this.$router, this.parentWorkflow._id);
    },
  },
  computed: {
    parentWorkflow() {
      const { properties } = this.node;
      return get(properties, 'parentWorkflow', '');
    },
    parentWorkflowNode() {
      const { properties } = this.node;
      return get(properties, 'parentWorkflowNode', '');
    },
  },
};

</script>

<style scoped>
  .w-e-datasource-link {
    cursor: pointer; 
  }
  .w-e-source {
    margin: 2px 0;
    font-weight: normal;
    color: #666;
  }
</style>
