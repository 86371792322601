<template>
  <vxe-table-column v-bind="colDef" sortable>
    <template v-slot="{row}">
      <div v-if="isNonNumeric">
         <span>{{ row[fieldId] }}</span>
      </div>
      <div v-else>
        <span v-if="prefix" class="decorative-text">{{prefix}}</span>
        <span >{{formatNumber(row[fieldId])}} {{isPercentage? "%":""}}</span>
        <span v-if="suffix" class="decorative-text" >{{ suffix }}</span>
      </div>

    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>    
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  components: {
    summaryCell
  },
  methods: {

  },
  computed: {
    prefix(){
      return this.getPropertyValue('prefix', '')
    },
    suffix(){
      return this.getPropertyValue('suffix', '')
    },
    decimals () {
      return this.getPropertyValue('decimals', '')
    },
    isPercentage () {
      return this.displayFormat === 'percentage'
    },
    displayFormat () {
      return this.getPropertyValue('displayFormat', 'number')
    },
    summaryFunction () {
      return this.getPropertyValue('summaryFunction', '')
    },
    isNonNumeric() {
      return ['latest', 'earliest'].includes(this.summaryFunction)
    },
    isNumeric() {
      return  !this.isNonNumeric
    }
  }
}

</script>
<style lang="scss" scoped>
.decorative-text {
  color: #b5b5b5;
  padding: 4px;
}
</style>
