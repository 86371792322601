import { v4 as uuidv4 } from 'uuid';
import {
  WorkflowNodeIdentify,
} from './../../constants/node';
import { map } from 'lodash';
import NodeUtils from './../../utils/node';

const {
  condition,
} = WorkflowNodeIdentify;
/*
* the basic tree node manipulation for the unstructured tree
* Terry Chan
* 10/05/2021
*/
class TreeControl {
  createBranchGroup(parent, closestParentTree, config, langPack) {
    const forkGroupNode = {
      id: uuidv4(),
      depth: parent ? parent.depth : 1, // must be create with fork node same level, no needs to increase 1
      parent,
      children: [],
      tree: closestParentTree,
      ...config,
      type: config.nodeType,
      module: config._id,
      // type: WorkflowNodeIdentify.fork,
    };
    const conditionConfig = { ...config };
    conditionConfig.name = langPack.label[config.type];
    // create at least two branch nodes under the fork node
    const leftCondition = this.createBasicNode(forkGroupNode, { ...conditionConfig, nodeType: condition, type: condition });
    const rightCondition = this.createBasicNode(forkGroupNode, { ...conditionConfig, nodeType: condition, type: condition });
    forkGroupNode.children.push(leftCondition);
    forkGroupNode.children.push(rightCondition);
    return forkGroupNode;
  }
  createBasicNode(parent, config) {
    const forkBranchGroup = {
      id: uuidv4(),
      depth: parent ? parent.depth + 1 : 1,
      parent,
      children: [],
      // type,
      ...config,
      module: config._id,
    };
    return forkBranchGroup;
  }
  needToMergeToForking(tree, node, mergeType) {
    if (NodeUtils.isFork(node.nodeType) && (mergeType === 0 || mergeType === 1)) {
      // pick the either left branch node or right branch node
      const child = node.children && node.children.length > mergeType ? node.children[mergeType] : null;
      return child;
    }
    // return the original one
    return null;
  }
  isFork(type) {
    return NodeUtils.isFork(type);
  }
  isBranch (type) {
    return NodeUtils.isBranch(type);
  }
  /*
  * It is only one branch left in the fork node tree
  * 
  * [Arguments]
  * @tree (Array): Tree / SubTree
  * 
  * [Returns]
  * @Boolean
  * 
  * Terry Chan
  * 21/05/2021
  */
  hasOneBranchLeft(tree, node) {
    return NodeUtils.isBranch(node.nodeType) && tree.length <= 2;
  }
  /*
  * Find out the next fork node tree except the current fork node
  * Usecase: remove a branch node and needs to remove the whole fork node tree, and keeps the next fork node 
  * [IMPORTANT] linear search does not have any concern in this iteration case
  *  |------------------------------------------------------------------
  *  |                                                                 |
  *  |       remove      start from this node and link the parent to --|                   
  *  |         |                         |
  *  V         V                         V
  * node > fork node > node > node > fork node > node
  * 
  * [Arguments]
  * @tree (Array): Tree / SubTree
  * @index (Number): starting offset for searching
  * 
  * [Returns]
  * @next (Number): Node for the next fork branch offset
  * @parent (Number): New Parent Node of the next fork branch offset
  * 
  * Terry Chan
  * 21/05/2021
  */
  findParentAndNextBranchPair(tree, startOffset = 0) {
    let next = -1;
    let parent = -1;
    // let i;
    // get the previous node as a parent node for the next fork node
    if (startOffset > 0) {
      parent = startOffset - 1;
    }
    if (startOffset + 1 <= tree.length - 1) {
      next = startOffset + 1;
    }
    // [DEPRECATED] no need to remove all of nodes until to the next fork node
        // it is not makesense for this operation in MingDao?
    // for (i = startOffset + 1 ; i < tree.length; i++) {
      
    //   if (this.isFork(tree[i].nodeType)) {
    //     next = i
    //     break;
    //   }
    // }
    return {
      next,
      parent,
    };
  }
  /*
  * Find out the most parent node for the branch node
  * 
  * [Arguments]
  * @node (Object): Node to be searched to the parent
  * 
  * [Returns]
  * @parentTree (Object): Parent horizontal tree before forking branch
  * @parentOffset (Number): Offset of Parent horizontal tree
  * @next (Object): Node for the next fork branch
  * @parent (Object): New Parent Node of the next fork branch
  * 
  * Terry Chan
  * 21/05/2021
  */
  findBranchMostParent(node) {
    let parentOffset = -1;
    let parentTree;
    if (NodeUtils.isBranch(node.nodeType)) {
      const { parent } = node; // [IMPORTANT] the parent node of the branch node is a fork node, which is not a correct new parent for the next fork node
      // the horizontal level tree before forked the branch
      const { tree } = parent;
      if (tree && tree.length) {
        parentTree = tree;
        for (let i = 0 ; i < parentTree.length ; i++) {
          if (parentTree[i].id === parent.id) {
            parentOffset = i;
            break;
          }
        }
        
        // start for searching the next forking node
        if (parentOffset !== -1) {
          const {
            next,
            parent,
          } = this.findParentAndNextBranchPair(tree, parentOffset);
          return {
            parentTree,
            parentOffset,
            next,
            parent,
          }
        }
      }
    }
    return {
      parentTree,
      parentOffset,
    };
  }
  copy(tree, node, offset, translator) {
    const newNode = { ...node };
    // dummy id, for testing only
    newNode.id = uuidv4();
    newNode.copied = true;
    if (translator) {
      newNode.name = `${node.name} - ${translator.label.copy}`
    }
    // newNode.initial = false;  // involve ui logic (e.g. fade in)
    // the branch node parent must be the same with the node to be cloned 
    if (!NodeUtils.isBranch(newNode.nodeType)) {
      newNode.parent = tree[offset];
    }
    // allow clone children node
    // newNode.children = [];
    this.insertNode(tree, newNode, offset);
  }
  prepareNode(tree, parent, config, translator) {
    let node = this.createBasicNode(parent, config);
    // special for fork node with different node structure
    if (NodeUtils.isFork(config.nodeType)) {
      node = this.createBranchGroup(parent, tree, config, translator);
    }
    return node;
  }
  nextNearNodes(tree, node, index) {
    const result = {};
    const nextIndex = index + 1;
    const nextNearIndex = index + 2;
    if (tree.length > nextIndex) {
      result.nextNode = tree[nextIndex];
    }
    if (tree.length > nextNearIndex) {
      result.nearNode = tree[nextNearIndex];
    }
    return result;
  }
  insertNode(tree, node, offset, mergeType, dependsOnParent) {
    let nextOffset = offset + 1;
    // if parent is branch, must be switch to children array as a tree and insert a new node to the head of children
    if (NodeUtils.isBranch(node.parent.nodeType)) {
      nextOffset = 0;
    }

    // there is any next node, update the next node's parent to a new node
    if (tree.length - 1 > offset) {
      const branchNodeToBeMerged = this.needToMergeToForking(tree, node, mergeType);
      const nextNode = tree[nextOffset];
      if (branchNodeToBeMerged) {
        nextNode.parent = branchNodeToBeMerged;
        // move the node from the next node to the end node under the branch node
        branchNodeToBeMerged.children = tree.slice(nextOffset, tree.length);
        // remove the moved nodes from the tree
        tree.splice(nextOffset, tree.length);
      } else {
        // update the nextnode latest information
        tree.splice(nextOffset, 1, nextNode);
      }
    }
    
    /*
    * [IMPORTANT] before fork the tree, reference the current tree
    * Usecase: trace back the parent horizontal level tree once remove node from the branch tree
    */
    if (NodeUtils.isFork(node.nodeType)) {
      node.tree = tree; 
    }

    if (dependsOnParent) {
      node.dependsNode = node.parent;
      console.log(node.parent);
      node.children = map(node.children, n => {
        n.dependsNode = node.parent;
        return n;
      });
    }
    // just push to the end of tree
    tree.splice(nextOffset, 0, node);
  }
  /*
  * Insert a new node to the Tree / SubTree
  * update the next node parent also
  * 
  * [Arguments]
  * @tree (Array): Tree / SubTree
  * @node (Object): Node to be added
  * @offset (Number): Index of the current node and add to there
  * 
  * [Returns]
  * @void
  * 
  * Terry Chan
  * 21/05/2021
  */
  insert(tree, parent, config, offset) {
    const node = this.prepareNode(tree, parent, config);
    this.insertNode(tree, node, offset);
  }
  /*
  * Remove a existin tree node from the Tree/SubTree
  * [IMPORTANT] special handle for branch remove, at least two branch node should be kept in the fork node
  * 
  * [Arguments]
  * @tree (Array): Tree / SubTree
  * @node (Object): Node to be remove
  * @offset (Number): Index of the current node and remove from there
  * 
  * [Returns]
  * @void
  * 
  * Terry Chan
  * 21/05/2021
  */
  remove(tree, node, index) {
    // once remove a branch node, check the whole tree for the next fork node
    if (this.hasOneBranchLeft(tree, node)) {
      const {
        parentTree,
        // parentOffset,
        next, 
        parent,
      } = this.findBranchMostParent(node);
      // if there is a next fork node
      if (next > -1) {
        if (parent > -1) {
          // console.log(parentTree, next);
          // re-linkup the parent node
          parentTree[next].parent = parentTree[parent];
        }
        
        // console.log(parent + 1, next - 1 - parent);
        // add back the next fork node to there
        // [DEPRECATED] no need to remove all of nodes until to the next fork node
        // it is not makesense for this operation in MingDao?
        // parentTree.splice(parent + 1, next - 1 - parent); // remove the rest of node util to the next fork node
        parentTree.splice(parent + 1, 1);
      } else {
        // console.log(index);
        // remove the rest of tree from the index to be removed
        parentTree.splice(parent + 1);
      }
    // } 
    // else if (NodeUtils.isBranch(node.nodeType)){
    //   // remove the rest of tree from the index to be removed
    //   tree.splice(index, 1);
    } else {
      const { nextNode, nearNode } = this.nextNearNodes(tree, node, index);
      if (NodeUtils.isDenpendencyRelied(nextNode, node)) {
        // dependency relationship, remove the same index again
        tree.splice(index, 2);
        if (nearNode) {
          nearNode.parent = node.parent;
        }
      } else {
        tree.splice(index, 1);
      }
      if (nextNode && node.parent) {
        nextNode.parent = node.parent;
      }
    }
  }

}

export default new TreeControl();
