import { get } from 'lodash';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import {
  FORM_SELECTIONS_FIELDS,
} from '@/store/modules/workflow/action_types';
const mixin = {
  computed: {
    node() {
      return this.$store.state.workflowModules.editingNode || {};
    },
    dataSource() {
      return this.setting.dataSource || {};
    },
    workflow() {
      return this.$store.getters.getWorkflowInfo;
    },
    data: {
      get: function () {
        return this.setting.data;
      },
      // setter
      set: function (newValue) {
        this.setting.data = newValue;
      }
    },
    setting() {
      return this.$store.state.workflowTree.setting;
    },
    appId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getWorksheet() {
      const { dataSource, worksheet } = this.setting;
      return worksheet || get(dataSource, 'form') || get(dataSource, 'properties.worksheet');
    },
    // server response format
    async fetchFieldInfo() {
      const formId = this.getWorksheet();
      if (formId) {
        await this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
          appId: this.appId,
          formId
        });
      }
    },
    errorReason({ label, errorKey, field, message }) {
      // console.log(this.node);
      const localeKey = `workflow.message.dynamicValue.${errorKey}`;
      // const targetField = field && field;
      if (message) {
        return message;
      } 
      // else if (this.$te(localeKey)) {
      
      return this.$t(localeKey, { field: label });
      // }
      // return this.$t('workflow.message.dynamicValue.INFORMATIONMISTAKE');
    },
  },
}

export default mixin
