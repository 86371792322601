const mixin = {

  methods: {
    checkCurrentApp () {
      const vm = this
      
      // console.log('admin/form/mixin :: checkCurrentApp')
      return new Promise((resolve, reject) => {
        if (vm.$store.getters.currentApp) {
          // console.log('checkCurrentApp currentApp exists')
          resolve(vm.$store.getters.currentApp)
        } else {
          const appId = vm.$route.params.id
          console.log('checkCurrentApp appId = ' + appId)
          vm.$store.dispatch('FETCH_APP', {appId: appId, include: 'menu'}).then(
            response => {
              resolve(response)
            }
          ).catch(
            err => {
              console.log('err: ', err)
            }
          )
        }
      })
    },

    async createFormBuffer (formId, callback) {
      const vm = this
      // console.log('createFormBuffer :: formId = ' + formId)
      if (vm.$store.getters.currentForm === null || 
          (vm.$store.getters.currentForm && 
            vm.$store.getters.currentForm._id != formId) 
        ){
        await vm.loadForm(formId, async () => {
          vm.doCreateFormBuffer(callback)
        })
      } else {
          vm.doCreateFormBuffer(callback)
      }
      
    },

    async doCreateFormBuffer (callback) {
      const vm = this
      await vm.$store.dispatch('SET_FORM_BUFFER', {form: vm.$store.getters.currentForm})
      if (typeof callback === 'function') {
        callback()
      }
    },

    async loadForm(formId, callback) {
      const vm = this
      await vm.$store.dispatch('FETCH_FORM', {formId: formId, appId: this.$route.params.id}).then(
        form => {
          vm.currentViewIndex = 0
          vm.updateDefaultViewName(form)
          if (typeof callback === 'function') {
            callback(form)
          }
        }
      )
    },

    loadPanel (panelId, callback) {
      const vm = this
      vm.$store.dispatch('FETCH_PANEL', {panelId: panelId, appId: this.$route.params.id}).then(
        panel => {
          console.log('mixin.loadPanel: panel: ', panel)
          console.log('mixin.loadPanel: this.$store.getters.currentPanel: ', this.$store.getters.currentPanel)
          
          if (typeof callback === 'function') {
            callback(panel)
          }
        }
      )      
    },

    updateDefaultViewName (form) {
      const vm = this
      if (form.views.length === 1) {
        var view = form.views[0]
        if (view.title[0] === '_') {
          view.title = vm.$t(view.title.substr(1))
          vm.$store.dispatch('UPDATE_FORM_VIEW', {
            id: form._id,
            viewId: view._id,
            data: view
          })
        }
      }
    }

  }
}

export default mixin
