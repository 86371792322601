import { render, staticRenderFns } from "./inBrowser.vue?vue&type=template&id=c59be736&scoped=true&"
import script from "./inBrowser.vue?vue&type=script&lang=js&"
export * from "./inBrowser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c59be736",
  null
  
)

export default component.exports