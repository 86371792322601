<template>
  <div>
    <component :is="preview[data.nodeType]" :color="data.color" :icon="data.icon" />
  </div>
</template>

<script>
import BasicNode from './basic';
import ForkNode from './group';
// import StaticNode from './static';
import {
  WorkflowNodeIdentify,
} from './../../../../constants/node';
export default {
  data() {
    return {
      preview: {
        [WorkflowNodeIdentify.common]: 'BasicNode',
        [WorkflowNodeIdentify.fork]: 'ForkNode',
        // static: 'StaticNode',
      }
    };
  },
  props: {
    data: Object,
  },
  components: {
    BasicNode,
    ForkNode,
    // StaticNode,
  }
};

</script>

<style scoped>
  
</style>
