<template>
  <div>
    <div
      v-if="!editing" 
      @click="$emit('edit', inline)" 
      :class="{ 'w-editable-node-name-branch': !isNode }"
      class="w-editable-node-name w-ellipsis" 
      :style="displayStyle()"
    >
      {{ name }}
    </div>
    <input
      v-else
      v-on-clickaway="update"
      v-model="name"
      :disabled="disabled"
      ref="editableInput"
      class="w-editable-node-name-input"
      :class="{ 'w-editable-node-name-branch': !isNode }"
    />
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import InputMixin from './inputMixin';

export default {
  mixins: [clickaway, InputMixin],
  props: {
    name: String,
    isNode: {
      type: Boolean,
      default: true,
    },
    inline: {   // inline display like drawer area
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.initialName = this.name;
  },
  methods: {
    update() {
      if (this.initialName !== this.name) {
        // update name
        this.$emit('edited', this.name);
        this.initialName = this.name;
      } else {
        this.$emit('edited', null);
      }
    },
    displayStyle() {
      if (this.inline) {
        return {
          textAlign: 'left',
        }
      }
      return {};
    }
  },
};

</script>

<style scoped>
  .w-editable-node-name {
    padding: 0 6px;
    height: 28px;
    width: 100%;
    line-height: 28px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 3px;
    display: inline-block;
    max-width: 100%;
    font-weight: 700;
    font-size: 15px;
    color: white;
  }
  .w-editable-node-name-branch {
    color: #757575 !important;
    text-align: left;
  }
  .w-editable-node-name:hover {
    background: hsla(0,0%,100%,.08);
    cursor: pointer;
  }
  .w-editable-node-name-branch:hover {
    background: #f5f5f5;
  }
  .w-editable-node-name-input {
    border-radius: 4px;
    font-size: 15px;
    width: 100%;
    background: white;
    padding: 0 5px;
    outline: none;
    color: #333;
    font-weight: normal;
  }
  .w-editable-node-name-branch.w-editable-node-name-input {
    background: #f5f5f5 !important;
  } 
  .w-editable-node-name-input:focus,
  .w-editable-node-name-input:active,
  .w-editable-node-name-input:hover,
  .w-editable-node-name-input:visited {
    outline: none;
  }
</style>
