<template>
  <background color="#f9f9f9" v-if="!selectedResult && !searched">
    <template v-slot:header><div></div></template>
    <template v-slot:card-content>
      <v-col style="text-align:center" v-if="loading">
        <loading-icon />
      </v-col>
      <v-card v-else-if="!publicQuery">
        <v-row
          class="pa-4"
          align="center"
          justify="center"
          style="min-height:540px"
        >
          <v-col style="text-align:center">
            <div>
              <v-icon size="108" color="error">mdi-information-outline</v-icon>
            </div>
            {{ $t("publicQuery.notActive") }}
          </v-col>
        </v-row>
      </v-card>
      <div v-else-if="publicQuery && !searched">
        <v-row align="center" justify="center" no-gutters>
          {{ publicQuery.title }}
        </v-row>

        <v-row no-gutters v-for="fieldInfo in fieldInfos" :key="fieldInfo._id">
          <input-widget
            :ref="fieldInfo._id"
            :key="fieldInfo._id"
            class="py-3"
            :recordData="data"
            :fieldInfo="fieldInfo"
            @onCommand="onCommandHandler"
          >
          </input-widget>
        </v-row>
        <v-row align="center" justify="center" no-gutters>
          <v-btn color="primary" rounded @click="submit">Submit</v-btn>
        </v-row>
      </div>
    </template>
  </background>

  <v-container
    v-else-if="searched && !selectedResult"
    style="background-color:#f9f9f9;"
    fluid
    class="pa-0"
  >
    <v-toolbar class="toolbar" absolute style="width:100%;z-index:999;">
      <v-toolbar-title>{{ publicQuery.title }}</v-toolbar-title>
      <v-spacer />
      <v-btn color="primary" @click="reset">Continue Search</v-btn>
    </v-toolbar>
    <div class="toolbar fill-height" style="margin-top:70px">
      <search-results-table
        :headers="displayFieldInfos"
        :rows="searchResult"
        :total="totalResult"
        @select="selectedResultId = $event"
      />
    </div>
  </v-container>
  <background color="#f9f9f9" v-else-if="selectedResult">
    <template v-slot:header><div></div></template>
    <v-toolbar class="toolbar" absolute style="width:100%;z-index:999;">
      <v-toolbar-title>{{ publicQuery.title }}</v-toolbar-title>
      <v-spacer />
      <v-btn @click="back">Back</v-btn>
    </v-toolbar>
    <template v-slot:card-content>
      <record
        v-if="selectedRelatedRecordId !== null"
        key="relatedRecord"
        :publicQuery="publicQuery"
        :recordId="selectedRelatedRecordId"
        :form="selectedRelatedForm"
        :relatedRecords="relatedRecords"
        :relatedTableInfos="relatedTableInfos"
        @onCommand="onCommandHandler"
      />
      <record
        v-else
        key="parentRecord"
        :publicQuery="publicQuery"
        :recordId="selectedResult._id"
        :form="form"
        :relatedRecords="relatedRecords"
        :relatedTableInfos="relatedTableInfos"
        @onCommand="onCommandHandler"
      />
    </template>
    <FsLightbox
      :toggler="toggler"
      :sources="imageSource"
      :onClose="onLightBoxClose"
      :exitFullscreenOnClose="true"
    />
  </background>
</template>
<script>
import FsLightbox from "fslightbox-vue";

import inputWidget from "@/components/inputWidgets/InputWidget";
import background from "@/components/public/background";
import searchResultsTable from "./comp/searchResultsTable";
import record from "./comp/record";
import LoadingIcon from "@/components/global/loadingIcon";

import { systemFieldIds } from "@/const/fieldConstants";

const CONVERT_TO_TEXT_FIELD_TYPE = ["textCombination", "autoNumbering"];
export default {
  components: {
    inputWidget,
    background,
    searchResultsTable,

    FsLightbox,
    LoadingIcon,
    record,
  },
  data() {
    return {
      publicQuery: null,
      searched: false,
      data: {},
      totalResult: 1,
      searchResult: [],
      selectedResultId: "",
      relatedRecords: {},
      relatedTableInfos: {},
      loading: false,

      selectedRelatedRecordId: null,
      selectedRelatedForm: null,
    };
  },
  async mounted() {
    this.loading = true;
    const result = await this.$store.dispatch("FETCH_PUBLIC_QUERY", {
      urlId: this.$route.params.urlId,
    });

    if (result) {
      this.publicQuery = result.result;
      this.relatedTableInfos = result.relatedTableInfos;
    }
    this.loading = false;
  },
  computed: {
    fieldInfos() {
      if (!this.publicQuery) return [];
      else {
        const fieldInfos = this.publicQuery.form.fieldInfos;
        return fieldInfos
          .filter((info) => this.publicQuery.queryFields.includes(info.fieldId))
          .map((info) => {
            const result = JSON.parse(JSON.stringify(info));
            if (CONVERT_TO_TEXT_FIELD_TYPE.includes(result.type))
              result.type = "text";
            result.properties.width = 12;
            result.properties.placeholder = "";
            return result;
          });
      }
    },
    form() {
      return this.$store.getters.currentForm;
    },
    view() {
      if (!this.form || !this.publicQuery) return null;
      else
        return this.form.views.find(
          (view) => view._id === this.publicQuery.viewId
        );
    },
    displayFieldIds() {
      if (!this.form || !this.view) return [];
      else {
        let result = []; //this.form.titleFieldInfoId
        switch (this.view.viewType) {
          case "table":
            {
              let displayFields = this.view.fixedFieldIds.concat(
                this.view.nonFixedFieldIds
              );

              displayFields = displayFields.filter(
                (id) => id !== this.form.titleFieldInfoId
              );
              result = result.concat(displayFields);
            }
            break;
          case "panel":
            {
              const displayFields = this.view.cardDisplayFieldIds.filter(
                (id) => id !== this.form.titleFieldInfoId
              );
              result = result.concat(displayFields);
            }
            break;
        }
        if (result.length === 0) {
          result = this.form.fieldInfos
            .filter(
              (info) =>
                !systemFieldIds.includes(info.type) &&
                info._id !== this.form.titleFieldInfoId
            )
            .map((info) => info._id);
        }
        result.unshift(this.form.titleFieldInfoId);
        return result;
      }
    },
    displayFieldInfos() {
      return this.displayFieldIds.map((fieldId) => {
        return this.form.fieldInfos.find((info) => info._id === fieldId);
      });
    },
    layout() {
      if (!this.form) return [];
      else {
        let result = [];
        this.form.layout.rows.forEach((row) => {
          let filteredRow = row.filter(
            (field) =>
              this.displayFieldIds.includes(field._id) ||
              field.controlType === "static"
          );
          if (filteredRow.length > 0) result.push(filteredRow);
        });
        return result;
      }
    },
    selectedResult() {
      if (!this.selectedResultId) return null;
      else
        return this.searchResult.find(
          (result) => result._id === this.selectedResultId
        );
    },
    titleFieldInfoId() {
      if (!this.form) return "";
      else return this.form.titleFieldInfoId;
    },
    toggler() {
      return this.$store.state.media.toggle;
    },
    imageSource() {
      return this.$store.state.media.imageUrls;
    },
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "updateFieldValue":
          this.updateFieldValue(payload);
          break;
        case "setRelatedData":
          this.setRelatedData(payload.fieldId, payload.value);
          break;
        case "setRelatedTable":
          this.setRelatedTable(payload.form);
          if (typeof payload.cb === 'function'){
            payload.cb()
          }
          break;
        case "selectRecord":
          if (!this.selectedRelatedRecordId ){
            this.selectedRelatedRecordId = payload.recordId;
            this.selectedRelatedForm = payload.form;
          }
          break;
      }
    },
    setRelatedData(fieldId, result) {
      if (
        this.relatedRecords[fieldId] &&
        Array.isArray(this.relatedRecords[fieldId])
      ) {
        this.relatedRecords[fieldId].push(...result);
      } else {
        this.$set(this.relatedRecords, fieldId, result);
      }
    },
    setRelatedTable(form){
      this.$set(this.relatedTableInfos, form._id, form)
    },
    updateFieldValue(payload) {
      this.$set(this.data, payload.fieldName, payload.fieldValue);
    },
    async validateAll() {
      const vm = this;
      const fieldIds = vm.publicQuery.queryFields;
      var errors = 0;
      // console.log("save :: fieldIds: ", fieldIds);

      let promises = [];
      fieldIds.forEach((f) => {
        // temp for debugging
        //const fieldName = vm.getFieldNameByFieldId(f)
        if (vm.$refs[f] && vm.$refs[f][0]) {
          promises.push(() => vm.$refs[f][0].validate());
          //const result = vm.$refs[f][0].validate()
          //console.log(f, result)
          //if (!result) errors++;
        }
      });

      const arrayOfPromises = promises.map((promise) => promise());
      const results = await Promise.all(arrayOfPromises);
      errors = results.filter((result) => !result).length;
      vm.errors = errors;
      return errors;
    },
    async submit() {
      const errors = await this.validateAll();

      if (errors === 0) {
        const url = this.$store.getters.appHost + "/public/query/search";

        const { data } = await this.axios.post(url, {
          searchFields: this.data,
          urlId: this.$route.params.urlId,
        });
        if (data && data.result) {
          this.searchResult = data.result;
          this.searched = true;
        }
      } else {
        return;
      }
    },
    getFieldInfosInRow(row) {
      const result = row.map((item) => {
        let result =
          item.controlType === "static"
            ? this.form.uiElementInfos.find((info) => info._id === item._id)
            : this.form.fieldInfos.find((info) => info._id === item._id);
        return result;
      });
      return result;
    },
    reset() {
      this.selectedResultId = "";
      this.searched = false;
      this.data = {};
    },
    onLightBoxClose() {
      this.$store.dispatch("RESET_MEDIA");
    },
    back() {
      if (this.selectedRelatedRecordId) {
        this.selectedRelatedRecordId = null;
        this.selectedRelatedForm = null;
      } else {
        this.selectedResultId = "";
        this.relatedRecords = {}

      }
    },
  },
};
</script>
<style scoped>
.toolbar {
  padding: 0px 20%;
}
</style>
