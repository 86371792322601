<template>
  <div class="fill-height tab-display-fields flex-grow-1 d-flex flex-column pa-2">
    <field-selection-dual-list
        :sourceListTitle="$t('view.availableFields')"
        :targetListTitle="$t('view.selectedFields')"

        :useAddAllButton="true"
        :useRemoveAllButton="true"

        :optionExclusive="true"
        :options="options"

        :fieldInfos="fieldInfos"
        v-model="selection">
    </field-selection-dual-list>
  </div>
</template>

<script>
  import mixin from './mixin'
  import fieldSelectionDualList from './comps/FieldSelectionDualList'

  export default {
    name: 'tabSorting',
    mixins: [
      mixin
    ],
    components: {
      fieldSelectionDualList
    },
    data () {
      return {
        options: [
          {value: 'asc', icon: 'mdi-sort-ascending'},
          {value: 'desc', icon: 'mdi-sort-descending'}
        ]
      }
    },
    computed: {
      sortingConfigs: {
        get () {
          const vm = this
          var result = []
          if (vm.value &&
            vm.value[vm.tabKey] &&
            vm.value[vm.tabKey]) {
            result = vm.value[vm.tabKey]
          }
          return result
        },
        set (val) {
          const vm = this
          var updatedValue = JSON.parse(JSON.stringify(vm.value))
          updatedValue[vm.tabKey] = val
          vm.$emit('input', updatedValue)
        }
      },
      selection: {
        get () {
          // view.sortingConfigs = [
          //    {fieldId: fieldId, order: [asc|desc]},
          //    {fieldId: fieldId, order: [asc|desc]},
          //    {fieldId: fieldId, order: [asc|desc]},
          //    ...
          // ]
          //
          const vm = this
          return {
            fieldIds: vm.sortingConfigs.map(config => config.fieldId),
            values: vm.sortingConfigs.map(config => config.order)
          }
        },
        set (val) {
          const vm = this
          var result = []
          for (let i = 0; i < val.fieldIds.length; i++) {
            result.push({
              fieldId: val.fieldIds[i],
              order: val.values[i]
            })
          }
          vm.sortingConfigs = result
        }
      },
      remainingFieldIds () {
        const vm = this
        return vm.allTableDisplayFieldIds.filter(fieldId => (
          vm.displayFieldIds.indexOf(fieldId) === -1
        ))
      },
      // fieldInfos () {
      //   return this.$store.getters.currentForm.fieldInfos
      // },
      fieldIdLabelMap () {
        const vm = this
        var result = {}
        for (var i = 0; i < vm.fieldInfos.length; i++) {
          const loopFieldInfo = vm.fieldInfos[i]
          result[loopFieldInfo.fieldId] = Object.keys(loopFieldInfo.properties).indexOf('fieldName') >= 0 ?
            loopFieldInfo.properties['fieldName'] :
            '(' + vm.$t('general.unspecified') + ')'
          if (Object.keys(loopFieldInfo.properties).indexOf('fieldNameTag') >= 0) {
            result[loopFieldInfo.fieldId] = vm.$t(loopFieldInfo.properties['fieldNameTag'])
          }
        }
        return result
      }
    },
    watch: {
      view: {
        handler: function (newVal, oldVal) {
          const vm = this
          console.log('ViewSettingsDisplayFields :: watch(view): newVal: ', newVal)
          vm.updateFields()
        },
        deep: true
      }
    },
    mounted () {
      const vm = this
      vm.loadAllFieldIds()
      vm.updateFields()
    },
    methods: {
      addAll () {
        const vm = this
        var i
        var ids = JSON.parse(JSON.stringify(vm.remainingFieldIds))
        for (i = 0; i < ids.length; i++) {
          if (vm.displayFieldIds.indexOf(ids[i]) === -1) {
            vm.displayFieldIds.push(ids[i])
          }
        }
        vm.updateTableSettings(vm.displayFieldIds)
      },
      removeField (fieldId) {
        const vm = this
        var idx = vm.displayFieldIds.findIndex(id => id === fieldId)
        if (idx >= 0) {
          vm.displayFieldIds.splice(idx, 1);
        }
        vm.updateTableSettings(vm.displayFieldIds)
      },
      removeAll () {
        const vm = this
        vm.updateTableSettings([])
      },
      addField (fieldId) {
        const vm = this
        vm.displayFieldIds.push(fieldId)
        vm.updateTableSettings(vm.displayFieldIds)
      },

      updateFields () {
        const vm = this
        // console.log('ViewSettingDisplayFields :: vm.view.cardDisplayFieldIds: ',
        //   vm.view.cardDisplayFieldIds)
        //
        // if (vm.view) {
        //   vm.displayFieldIds = vm.view.cardDisplayFieldIds
        // }
        // console.log('ViewSettingDisplayFields :: vm.displayFieldIds: ', vm.displayFieldIds)

      },
      loadAllFieldIds () {
        const vm = this
        vm.allTableDisplayFieldIds = vm.fieldInfos.map(
          fieldInfo => fieldInfo.fieldId)
      },
      updateTableSettings (displayFieldIds) {
        const vm = this
        console.log('updateTableSettings :: displayFieldIds: ', displayFieldIds)
        vm.$emit('onCommand', {
          command: 'updatePanelCardDisplayFieldsSettings',
          displayFieldIds: displayFieldIds
        })
      },
      onDropped (items, pathTo) {
        const vm = this
        vm.updateTableSettings(vm.displayFieldIds)
        // vm.$emit('onCommand', {
        //   command: 'updateTableSettings',
        //   fixedFieldIds: vm.fixedFieldIds,
        //   scrollableFieldIds: vm.scrollableFieldIds
        // })
      },
      log: function (evt) {
        window.console.log(evt);
      }
    }
  }
</script>


<style>
  /*.view-settings-table-settings .field-list {*/
  /*min-height: 200px;*/
  /*}*/
  .field-list .v-list-item:nth-child(even) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>
