<template>
     <img v-if="mediaSrc" class="signature-image" :src="mediaSrc">
     <div v-else class="fs-text text-no-wrap overflow-hidden"></div>
</template>
<script>
import MediaHelper from '@/helpers/MediaHelper';
import mixin from "./mixin";

export default {
    name: "fsSignature",
    mixins: [mixin],
    data() {
        return {
            mediaSrc: ""
        }
    },
    async mounted() {
        this.mediaSrc = await this.getMediaSrc()
    },
    methods: {
        async getMediaSrc() {
            let result = ""
            const { objectKey } = this.dataValue || {};
            if (objectKey) {
                result = await MediaHelper.getSignedUrl(
                    objectKey,
                    { isPublic: true })
            }
            return result
        }
    }
};
</script>
