<template>
  <div v-if="!loading">
    <div v-for="fieldInfo in getIt(specifyFieldInfos || fieldInfos)" :key="fieldInfo._id" class="py-1">
      <div v-if="notSystemField(fieldInfo)">
        <Wrapper
          :initialRecord="recordData"
          :value="recordData[fieldInfo._id] || null"
          :fieldInfo="fieldInfo"
          :optionOutput="optionOutput"
          :dependsOnSource="dependsOnSource"
          :canSwitch="notRelationshipField(fieldInfo)"
          :widgetTypeMapField="widgetTypeMapField"
          :withOperation="withOperation"
          :initialDependency="!notRelationshipField(fieldInfo)"
          :addOnInfo="addOnInfo"
          :showRelationManagement="showRelationManagement"
          @onCommand="onCommandHandler"
        >
          <template v-slot="{ dependency }">
            <component
              :is="useDelegated(fieldInfo)"
              :recordData="recordData"
              :dependency="dependency"
              :idFieldLookup="idFieldLookup"
              :withHideFields="withHideFields"
              :involveRelationshipLabel="involveRelationshipLabel"
              :customizedFormInfo="{ _id: worksheet && worksheet._id, appId }"
              :widgetTypeMapField="widgetTypeMapField"
              :relatedTableData="relatedTableData"
              :fieldInfo="normalize(fieldInfo)"
            />
          </template>
        </Wrapper>
      </div>
    </div>
    <members-menu
      :fullWidth="true"
      v-if="showingMembersMenu"
      v-model="showingMembersMenu"
      :attachId="membersMenuAttachId"
      ref="workflowMembersMenu"
      @memberClick="handleMemberClick"
    />
  </div>
</template>

<script>

import DelegatedWidget from './widgets/delegated';
import RelationshipWidget from './widgets/relationship';
import ObjectArrayWidget from './widgets/objectArray';
import NumericWidget from './widgets/numeric';
import DateWidget from './widgets/date';
import MembersWidget from './widgets/members';
import InputWidget from './widgets/input';
import Wrapper from './widgets/wrapper';
import { has } from 'lodash';
import Mixin from './../../mixin';
import RecordMxiin from '@/mixins/recordMixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';

import FormElementWidget from "@/components/inputWidgets/InputWidget";
import SettingUtils from "@/pages/admin/workflow/utils/setting";
import membersMenu from '@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu'
import EventBus from '@/event-bus.js'
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin, RecordMxiin],
  props: {
    worksheet: [String, Object],
    dataSource: [String, Object],
    specifyFieldInfos: Array,
    widgetTypeMapField: String,
    optionOutput: String,
    fieldId: String,
    dependsOnSource: String,
    idFieldLookup: Object,
    fetchByNode: Boolean,
    withOperation: Boolean,
    involveRelationshipLabel: Boolean,
    showRelationManagement: Boolean,
    node: Object,
    withHideFields: Boolean,
  },
  data() {
    return {
      loading: false,
      showingMembersMenu: false,
      membersMenuAttachId: null
    };
  },
  computed: {
    recordData() {
      return this.data;
    },
    addOnInfo() {
      const { dataSource, worksheet } = this;
      const node = dataSource && (dataSource._id || dataSource);
      const form = dataSource && dataSource.properties ? dataSource.properties.worksheet : worksheet;
      return {
        node,
        form,
        fieldId: this.fieldId,
      };
      // const properties = n
      // {
      //       node: dataSource && (dataSource._id || dataSource),
      //       form: worksheet ? (worksheet._id || worksheet) : dataSource ? 
      //       dataSource.properties : '',
      //     }
    },
  },
  methods: {
    handleMemberClick(val){
      let vm = this
      var fieldId = vm.$refs.workflowMembersMenu.fieldId
      var type = vm.$refs.workflowMembersMenu.type

      if(fieldId){
        if(vm.recordData[fieldId]){
          var pos = vm.recordData[fieldId].findIndex(item=>item._id == val._id)
          if(pos!=-1)
            vm.recordData[fieldId].splice(pos,1)
          else{
            if(type=='multiple' && vm.recordData[fieldId])
              vm.recordData[fieldId].push(val)
            else
              vm.recordData[fieldId] = [val]
          }
        }else{
          vm.$set(vm.recordData, fieldId, [val])
        }
      }
    },
    // debug use
    getIt(v) {
      return v;
      // return v && v.length ? [v[6]] : [];
    },
    log(v) {
      console.log(v);
    },
    notSystemField(field) {
      return !SettingUtils.systemFields(field);
    },
    notRelationshipField(field) {
      return !SettingUtils.relationshipFields(field) && !SettingUtils.notSupportDynamicReferenceFields(field);
    },
    useDelegated({ fieldType, type }) {
      return SettingUtils.useDelegated(fieldType || type);
    },
    normalize(info) {
      const newInfo = { ...info };
      if (has(newInfo, 'properties.width')) {
        newInfo.properties.width = 12;
      }
      // if (!has(newInfo, 'widgetType')) {
      //   newInfo.widgetType = newInfo.fieldType || newInfo.type;
      // }
      return newInfo;
    },
    onChangeHookEvent(v) {
      this.$store.dispatch(SET_SETTINGS, { hookEvent: v });
    },
    init (formInfo, defaultValues={}) {
      const vm = this
      vm.initNewRecord(defaultValues, () => {
        vm.prepare()
      });
    },
    loadFormAndChild(worksheet) {
      const formId = worksheet._id || worksheet;
      this.$store.dispatch("FETCH_FORM", {
        formId, 
        appId: this.appId,
      });
    },
    prepareForm() {
      if (this.worksheet) {
        // const { worksheet } = this;
        this.loadFormAndChild(this.worksheet);
        this.resetDependencies();
      }
    },
    openMembersMenu(payload){
      var attachedEle = payload.attachedEle
      var fieldInfo = payload.fieldInfo
      let vm = this
      vm.membersMenuAttachId = attachedEle.id
      vm.showingMembersMenu = true
      vm.$nextTick(()=>{
        vm.$refs.workflowMembersMenu.type= fieldInfo.properties.fieldType
        vm.$refs.workflowMembersMenu.selectedValue= vm.recordData[fieldInfo.fieldId]||[]
        vm.$refs.workflowMembersMenu.fieldId= fieldInfo.fieldId
        attachedEle.id = ""
      })
    }
  },
  mounted() {
    this.prepareForm();
    EventBus.$on('openMembersMenu', this.openMembersMenu)
  },
  beforeDestroy(){
    EventBus.$off('openMembersMenu', this.openMembersMenu)
  },
  watch: {
    worksheet: {
      handler(newSheet) {
        this.loadFormAndChild(newSheet);
        this.resetDependencies();
      },
      deep: true,
    },
  },
  components: {
    FormElementWidget,
    DelegatedWidget,
    RelationshipWidget,
    NumericWidget,
    InputWidget,
    ObjectArrayWidget,
    Wrapper,
    DateWidget,
    MembersWidget,
    membersMenu
  },
};

</script>

<style scoped>
  
</style>
