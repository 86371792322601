var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-table-column',_vm._b({attrs:{"edit-render":{
    name: _vm.rowType === 'single' ? 'input' : 'textarea',
    autoselect: true,
    enabled: !_vm.readonly
  },"sortable":"","show-overflow":_vm.rowType === 'single' ? 'ellipsis' : false,"class-name":"fullCell vxeCellText"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var row = ref.row;
return [_c('div',{staticClass:"px-2 d-flex fill-height",class:_vm.rowHeightClass + (_vm.rowType==='single' ? ' align-center' : ' align-top'),staticStyle:{"overflow":"hidden"}},[(row[_vm.fieldId] && _vm.prefix)?_c('span',{staticClass:"pa-2",staticStyle:{"color":"#b5b5b5"}},[_vm._v(_vm._s(_vm.prefix)+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(row[_vm.fieldId]))]),(row[_vm.fieldId] && _vm.suffix)?_c('span',{staticClass:"pa-2",staticStyle:{"color":"#b5b5b5"}},[_vm._v(_vm._s(_vm.suffix)+" ")]):_vm._e(),(_vm.debugging)?_c('v-chip',{attrs:{"x-small":"","color":"primary"}},[_vm._v(" "+_vm._s(row._id)+" ")]):_vm._e()],1),(_vm.quickInsert)?_c('quick-action',{attrs:{"rowId":row['_id'],"fieldId":_vm.fieldId}}):_vm._e()]}},{key:"edit",fn:function(ref){
  var row = ref.row;
return [_c('div',{staticStyle:{"height":"100%"}},[(_vm.rowType == 'single')?_c('vxe-input',{staticStyle:{"height":"100%"},attrs:{"readonly":_vm.readonly,"type":"text"},on:{"blur":function($event){return _vm.onBlur(row, _vm.fieldId)}},model:{value:(row[_vm.fieldId]),callback:function ($$v) {_vm.$set(row, _vm.fieldId, $$v)},expression:"row[fieldId]"}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(row[_vm.fieldId]),expression:"row[fieldId]"}],staticClass:"white pa-2",staticStyle:{"width":"100%","height":"99%","color":"#606266","font-size":"14px"},attrs:{"outlined":"","rows":_vm.rows,"readonly":_vm.readonly},domProps:{"value":(row[_vm.fieldId])},on:{"blur":function($event){return _vm.onBlur(row, _vm.fieldId)},"input":function($event){if($event.target.composing){ return; }_vm.$set(row, _vm.fieldId, $event.target.value)}}})],1)]}},{key:"footer",fn:function(ref){
  var column = ref.column;
return [_c('summary-cell',{attrs:{"decimals":_vm.decimals,"column":column,"labelValuePairs":_vm.getSummaryLabelValuePairs(column)},on:{"click":_vm.selectCalcMethod}})]}}])},'vxe-table-column',_vm.colDef,false))}
var staticRenderFns = []

export { render, staticRenderFns }