<template>
  <div class="tab-custom-buttons flex-grow-1 d-flex flex-column px-2">
    <div class="flex-grow-1 d-flex flex-row" style="position:relative;">
      <h1>tabCustomButtons</h1>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'tabCustomButtons',
  mixins: [
    mixin
  ],
  components: {
  },
  props: {
  },
  data () {
    return {
      allTableDisplayFieldIds: [],
      displayFieldIds: [],
      currentSection: 'scrollable' // [fixed|scrollable]
    }
  },
  computed: {
    remainingFieldIds () {
      const vm = this
      return vm.allTableDisplayFieldIds.filter(fieldId => (
        vm.displayFieldIds.indexOf(fieldId) === -1
      ))
    },
    fieldInfos () {
      return this.$store.getters.currentForm.fieldInfos
    },
    fieldIdLabelMap () {
      const vm = this
      var result = {}
      for (var i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i]
        result[loopFieldInfo.fieldId] = Object.keys(loopFieldInfo.properties).indexOf('fieldName') >= 0 ?
          loopFieldInfo.properties['fieldName'] :
          '(' + vm.$t('general.unspecified') + ')'
        if (Object.keys(loopFieldInfo.properties).indexOf('fieldNameTag') >= 0) {
          result[loopFieldInfo.fieldId] = vm.$t(loopFieldInfo.properties['fieldNameTag'])
        }
      }
      return result
    }
  },
  watch: {
    view: {
      handler: function (newVal, oldVal) {
        const vm = this
        console.log('ViewSettingsDisplayFields :: watch(view): newVal: ', newVal)
        vm.updateFields()
      },
      deep: true
    }
  },
  mounted () {
    const vm = this
    // vm.loadAllFields()
    // vm.updateFields()
  },
  methods: {
    addAll () {
      const vm = this
      var i
      var ids = JSON.parse(JSON.stringify(vm.remainingFieldIds))
      for (i = 0; i < ids.length; i++) {
        if (vm.displayFieldIds.indexOf(ids[i]) === -1) {
          vm.displayFieldIds.push(ids[i])
        }
      }
      vm.updateTableSettings(vm.displayFieldIds)
    },
    removeField (fieldId) {
      const vm = this
      var idx = vm.displayFieldIds.findIndex(id => id === fieldId)
      if (idx >= 0) {
        vm.displayFieldIds.splice(idx, 1);
      }
      vm.updateTableSettings(vm.displayFieldIds)
    },
    removeAll () {
      const vm = this
      vm.updateTableSettings([])
    },
    addField (fieldId) {
      const vm = this
      vm.displayFieldIds.push(fieldId)
      vm.updateTableSettings(vm.displayFieldIds)
    },

    updateFields () {
      const vm = this
      console.log('ViewSettingDisplayFields :: vm.view.cardDisplayFieldIds: ',
        vm.view.cardDisplayFieldIds)

      if (vm.view) {
        vm.displayFieldIds = vm.view.cardDisplayFieldIds
      }
      console.log('ViewSettingDisplayFields :: vm.displayFieldIds: ', vm.displayFieldIds)

    },
    loadAllFieldInfos () {
      const vm = this
      vm.allTableDisplayFieldIds = vm.fieldInfos.map(
        fieldInfo => fieldInfo.fieldId)
    },
    updateTableSettings (displayFieldIds) {
      const vm = this
      console.log('updateTableSettings :: displayFieldIds: ', displayFieldIds)
      vm.$emit('onCommand', {
        command: 'updatePanelCardDisplayFieldsSettings',
        displayFieldIds: displayFieldIds
      })
    },
    onDropped (items, pathTo) {
      const vm = this
      vm.updateTableSettings(vm.displayFieldIds)
      // vm.$emit('onCommand', {
      //   command: 'updateTableSettings',
      //   fixedFieldIds: vm.fixedFieldIds,
      //   scrollableFieldIds: vm.scrollableFieldIds
      // })
    },
    log: function (evt) {
      window.console.log(evt);
    }
  }
}
</script>


<style>
  /*.view-settings-table-settings .field-list {*/
  /*min-height: 200px;*/
  /*}*/
  .field-list .v-list-item:nth-child(even) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>
