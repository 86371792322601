<template>
  <component
    ref="inputWidget"
    :key="`_${recordData._id}_${fieldInfo[idFieldLookup]}`"
    :is="componentName"
    :alias="alias"
    :customDescription="customDescription"
    :fieldInfo="fieldInfo"
    :activeForm="formInfo"
    :customizedFormInfo="customizedFormInfo"
    :customizedProperties="customizedProperties"
    :isLayout="isLayout"
    :isNew="isNew"
    :customizedPlaceholder="placeholder"
    :recordData="recordData"
    :value="dataValue"
    :fullWidth="fullWidth"
    :isReadonly="isReadonly"
    :hideInput="hideInput"
    :editAnyway="editAnyway"
    @onCommand="onCommandHandler"
    @input="(val) => updateValue(val)"
    :relatedTableData="relatedTableData"
    :relatedTableInfos="relatedTableInfos"
    :enableBranchSelect="enableBranchSelect"
    :supportJobTitleSelection="supportJobTitleSelection"
    :multiple="multiple"
    :isHidden="isHidden"
    :childRecords="childRecords"
  ></component>
  <!-- {{authorization}} -->
  <!--<div class="ml-2 grey&#45;&#45;text" style="font-size: 12px" v-text="fieldInfo.properties.description||''"></div>-->
  <!--:rules="rules"-->
</template>

<script>
import inputWidgetAmount from "./InputWidgetAmount";
import inputWidgetAttachments from "./InputWidgetAttachments";
import inputWidgetAutoNumbering from "./InputWidgetAutoNumbering";
import inputWidgetChildTable from "./InputWidgetChildTable";
import inputWidgetDate from "./InputWidgetDate";
import inputWidgetDepartments from "./InputWidgetDepartments";
import inputWidgetEmail from "./InputWidgetEmail";
import inputWidgetExpression from "./InputWidgetExpression";
import inputWidgetIdCard from "./InputWidgetIdCard";
import inputWidgetLocation from "./InputWidgetLocation";
import inputWidgetMembers from "./InputWidgetMembers";
import inputWidgetMultipleSelection from "./InputWidgetMultipleSelection";
import inputWidgetNumber from "./InputWidgetNumber";
import inputWidgetOtherTableField from "./InputWidgetOtherTableField";
import inputWidgetPhone from "./InputWidgetPhone";
import inputWidgetRating from "./InputWidgetRating";
import inputWidgetRelatedRecord from "./InputWidgetRelatedRecord";
// import inputWidgetRelatedMultipleRecords from './InputWidgetRelatedMultipleRecords'
import inputWidgetRelatedSelection from "./InputWidgetRelatedSelection";
import inputWidgetRichText from "./InputWidgetRichText";
import inputWidgetSectionLine from "./InputWidgetSectionLine";
import inputWidgetSignature from "./InputWidgetSignature";
import inputWidgetSingleSelection from "./InputWidgetSingleSelection";
import inputWidgetSummaryField from "./InputWidgetSummaryField";
import inputWidgetText from "./InputWidgetText";
import inputWidgetTextCombination from "./InputWidgetTextCombination";
import inputWidgetYesNo from "./InputWidgetYesNo";
import inputWidgetRegion from "./InputWidgetRegion";
import inputWidgetRemark from "./InputWidgetRemark";
import inputWidgetCapitalizedAmount from "./InputWidgetCapitalizedAmount";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";

export default {
  name: "InputWidget",
  components: {
    // ...inputWidgets

    inputWidgetAmount,
    inputWidgetAttachments,
    inputWidgetAutoNumbering,
    inputWidgetChildTable,
    inputWidgetDate,
    inputWidgetDepartments,
    inputWidgetEmail,
    inputWidgetExpression,
    inputWidgetIdCard,
    inputWidgetLocation,
    inputWidgetMembers,
    inputWidgetMultipleSelection,
    inputWidgetNumber,
    inputWidgetOtherTableField,
    inputWidgetPhone,
    inputWidgetRating,
    inputWidgetRelatedRecord,
    inputWidgetRelatedSelection,
    inputWidgetRichText,
    inputWidgetSectionLine,
    inputWidgetSignature,
    inputWidgetSingleSelection,
    inputWidgetSummaryField,
    inputWidgetText,
    inputWidgetTextCombination,
    inputWidgetYesNo,
    inputWidgetRegion,
    inputWidgetRemark,
    inputWidgetCapitalizedAmount,
  },
  props: {
    isNew: Boolean,
    formInfo: Object,
    fieldInfo: Object,
    customizedFormInfo: Object,
    customizedProperties: Object,
    placeholder: String,
    // currentForm: Object,
    // fieldName: String,
    // fieldInfoId: String,
    recordData: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    specialType: String,
    alias: String,
    customDescription: String,
    relatedTableData: {
      type: Object,
      default: null,
    },
    relatedTableInfos: {
      type: Object,
      default: {},
    },
    hideInput: Boolean,
    idFieldLookup: {
      type: String,
      default: "fieldId",
    },
    editAnyway: {
      type: Boolean,
      default: false,
    },
    // dynamic lookup field name for the component name
    widgetTypeMapField: {
      type: String,
      default: "type",
    },
    fullWidth: Boolean,

    supportJobTitleSelection: {
      type: Boolean,
      default: false,
    },
    enableBranchSelect: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    childRecords: Array,
  },
  computed: {
    // isNew() {
    //   const vm = this
    //   var result = true
    //   if (vm.recordData && vm.recordData._id && vm.recordData._id !== '_') {
    //     result = false
    //   }
    //   return result
    // },
    isUIElement() {
      return this.fieldInfo.type.startsWith("_");
    },
    authorization() {
      const vm = this;
      var result = [];
      if (vm.fieldInfo.properties && vm.fieldInfo.properties.authorization) {
        var auth = this.fieldInfo.properties.authorization.split("||");
        if (auth.length > 0 && auth[0] !== "") {
          result = auth;
        }
      }
      return result;
    },
    fieldName() {
      return this.fieldInfo[this.idFieldLookup];
    },

    dataValue() {
      const vm = this;
      let result = vm.recordData === null ? null : vm.recordData[vm.fieldName];
      if (vm.isOtherTableField) {
        result = vm.relatedTableData[vm.otherTableFieldId];
      }
      return result;
    },

    isLayout() {
      const vm = this;
      return vm.recordData === null;
    },

    widgetType() {
      // non-data widget has '_' prefixa at type
      const vm = this;
      const type = vm.fieldInfo[this.widgetTypeMapField];
      if (type.indexOf("_") >= 0) {
        return type.substr(1);
      }
      return type;
    },

    componentName() {
      const vm = this;
      var result =
        "inputWidget" + this.ucFirst(this.specialType || this.widgetType);
      if (vm.widgetType === "otherTableField") {
        if (vm.otherTableFieldInfo) {
          const relatedFormId = this.otherTableFieldInfo.properties.dataTableId;
          const relatedFormInfo = vm.relatedTableInfos.find(
            (info) => info._id === relatedFormId
          );
          const relatedFieldInfo = relatedFormInfo.fieldInfos.find(
            (info) => info._id === vm.otherTableFieldId
          );
          if (relatedFieldInfo) {
            result =
              "inputWidget" +
              vm.ucFirst(relatedFieldInfo[this.widgetTypeMapField]);
          }
        }
      } else if (this.widgetType == "owner") {
        result = "inputWidgetMembers";
      }
      return result;
    },

    // for otherTableField widget
    isOtherTableField() {
      return this.fieldInfo[this.widgetTypeMapField] === "otherTableField";
    },
    otherTableFieldId() {
      return getFieldPropertyValue(this, "relatedFieldId", "");
    },
    fieldInfoForOtherTableField() {
      const vm = this;
      var result = null;
      const fieldForRelatedTable = getFieldPropertyValue(
        vm.fieldInfo,
        "fieldForRelatedTable",
        ""
      );
      if (fieldForRelatedTable && vm.fieldInfos) {
        const fieldInfo = vm.fieldInfos.find(
          (info) => info._id === fieldForRelatedTable
        );
        const relatedFormId = getFieldPropertyValue(
          fieldInfo,
          "dataSource",
          null
        );
        if (relatedFormId && vm.relatedTableInfos) {
          result = vm.relatedTableInfos.find(
            (info) => info._id === relatedFormId
          );
        }
      }
      return result;
    },

    // for otherTableField widget - ends

    isHidden() {
      if (this.authorization.includes("hidden")) return true;
      else if (this.$store.getters.isPublicRoute) return this.authorization.includes("hidden")
      else {
        return (
          (!this.isNew && !this.fieldInfo.canView) ||
          (this.isNew &&
            (this.authorization.includes("hiddenDuringCreation") ||
              !this.fieldInfo.canCreate))
        );
      }
    },
    displayRule() {
      return this.$store.getters.displayRule;
    },
    isReadonly() {
      return (
        this.readonly ||
        (![
          "PublicQuery",
          "PublicForm",
          "PreviewPublicForm",
          "PublicEdit",
        ].includes(this.$route.name) &&
          !this.isNew &&
          (!this.recordData.canEdit || !this.fieldInfo.canEdit) &&
          !this.editAnyway) ||
        (this.$route.name === "PublicEdit" &&
          this.customizedProperties &&
          !(
            this.customizedProperties.editable ||
            this.customizedProperties.deletable ||
            this.customizedProperties.uploadable
          ))
      );
    },
  },
  methods: {
    onCommandHandler(payload) {
      const vm = this;
      vm.$emit("onCommand", payload);
    },
    ucFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    updateFieldValue(fieldName, fieldValue) {
      const vm = this;
      vm.$emit("onCommand", {
        command: "updateFieldValue",
        fieldName,
        fieldValue,
      });
    },
    updateValue(value) {
      const vm = this;
      const {
        fieldInfo: { type },
      } = this;
      vm.$emit("onCommand", {
        command: "updateFieldValue",
        fieldName: vm.fieldName,
        fieldValue: value,
        // retain: includes([
        //   'expression',
        //   'summaryField',
        // ], type),
      });
    },
    validate(state) {
      if (
        this.$refs.inputWidget &&
        !this.authorization.includes("hidden") &&
        (!this.isNew || !this.authorization.includes("hiddenDuringCreation"))
      )
        return this.$refs.inputWidget.validate(state);
      else return true;
    },
    resetValidation() {
      const vm = this;
      if (vm.$refs.inputWidget) {
        this.$refs.inputWidget.resetValidation();
      }
    },
  },
};
</script>