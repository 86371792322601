// import tabCardFields from './tabCardFields'
import tabCardDisplaySetup from './tabCardDisplaySetup'
import tabCopyRelatedFields from './tabCopyRelatedFields'
import tabCustomButtons from './tabCustomButtons'
import tabCardDisplayFields from './tabCardDisplayFields'
import tabFilters from './tabFilters'
import tabSorting from './tabSorting'
import tabTableDisplaySetting from './tabTableDisplaySetting'
import tabTableDisplayWithFreezing from './tabTableDisplayWithFreezing'
import tabTableDisplayWithoutFreezing from './tabTableDisplayWithoutFreezing'

export default {
  // tabCardFields,
  tabCardDisplaySetup,
  tabCopyRelatedFields,
  tabCustomButtons,
  tabCardDisplayFields,
  tabFilters,
  tabSorting,
  tabTableDisplaySetting,
  tabTableDisplayWithFreezing,
  tabTableDisplayWithoutFreezing
}
