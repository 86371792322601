const WorkflowVersionInfo = {
    version: '2.0.0',
};
const WorkflowButtonColorCode = [
    '#B71C1CFF',
    '#4A148CFF',
    '#0D47A1FF',
    '#006064FF',
    '#1B5E20FF',
    '#827717FF',
    '#F57F17FF',
    '#E65100FF',
];
const WorkflowButtonIcons = [
    'check',
    'delete',
    'content-copy',
    'account',
    'plus',
    'minus',
    'pencil',
    'help-circle',
    'email',
    'phone',
    'link',
    'cloud',
    'download',
    'upload',
    'star',
    'dots-horizontal',
    'thumb-up',
    'thumb-down',
    'camera',
    'printer',
    'image',
];
const WidgetSelectionAcceptance = {
    text: [
        'text',
        'number',
        'amount',
        'email',
        'date',
        'phone',
        'singleSelection',
        'multipleSelection',
        'yesNo',
        'rating',
        'textCombination',
        'autoNumbering',
        'members',
        'departments',
        'expression',
        'summaryField',
        'richText',
        'idCard',
        'createdAt',
        'updatedAt',
        'CURRENTDATE',
        'capitalizedAmount',
        'shareableTemplate'
    ],
    members: [
        'createdBy',
        'members',
        'WORKFLOWCREATOR',
        'WORKFLOWTRIGGER',
        'owner',
    ],
    owner: [
        'createdBy',
        'members',
        'WORKFLOWCREATOR',
        'WORKFLOWTRIGGER',
        'owner',
    ],
    createdBy: [
        'createdBy',
        'members',
        'WORKFLOWCREATOR',
        'WORKFLOWTRIGGER',
        'owner',
    ],
    number: [
        'number',
        'amount',
        'rating',
        'autoNumbering',
        'expression',
        'summaryField',
    ],
    autoNumbering: [
        'number',
        'amount',
        'rating',
        'autoNumbering',
        // 'expression',
        'summaryField',
    ],
    expression: [
        'number',
        'amount',
        'rating',
        'autoNumbering',
        'expression',
        'summaryField',
    ],
    summaryField: [
        'number',
        'amount',
        'rating',
        'autoNumbering',
        'expression',
        'summaryField',
    ],
    // capitalizedAmount: [
    //     'number',
    //     'amount',
    //     'phone',
    //     'rating',
    //     'autoNumbering',
    //     'capitalizedAmount',
    // ],
    amount: [
        'number',
        'amount',
        'rating',
        'autoNumbering',
        'expression',
        'summaryField',
    ],
    // email: [
    //     'email',
    //     'text',
    //     'richText',
    //     // 'phone',
    // ],
    date: [
        'date',
        'createdAt',
        'updatedAt',
        'CURRENTDATE'
    ],
    rating: [
        'rating',
        'amount',
        'number',
        // 'capitalizedAmount',
    ],
    textCombination: [
        'text',
        'number',
        'amount',
        'email',
        'date',
        'phone',
        'capitalizedAmount',
        'singleSelection',
        'multipleSelection',
        'yesNo',
        'rating',
        'textCombination',
        'autoNumbering',
        'members',
        'departments',
        'richText',
        'idCard',
        'createdAt',
        'updatedAt',
        'CURRENTDATE'
    ],
    // expression: [
    //     'text',
    //     'number',
    //     'amount',
    //     'email',
    //     'date',
    //     'phone',
    //     'capitalizedAmount',
    //     'singleSelection',
    //     'multipleSelection',
    //     'yesNo',
    //     'rating',
    //     'textCombination',
    //     'autoNumbering',
    //     'richText',
    //     'idCard',
    //     'createdAt',
    //     'updatedAt',
    //     'CURRENTDATE'
    // ],
    richText: [
        'text',
        'number',
        'amount',
        'email',
        'date',
        'phone',
        'singleSelection',
        'multipleSelection',
        'yesNo',
        'rating',
        'capitalizedAmount',
        'textCombination',
        'autoNumbering',
        'richText',
        'idCard',
        'createdAt',
        'updatedAt',
        'CURRENTDATE'
    ],
    idCard: [
        'text',
        'number',
        'textCombination',
        'autoNumbering',
        'richText',
        'idCard',
        'capitalizedAmount',
    ],
    relatedRecord: [
        'relatedRecord',
        'REMOVERELATION',
    ],
    childTable: [
        'childTable',
        'REMOVERELATION',
    ],
}

export {
    WidgetSelectionAcceptance,
    WorkflowVersionInfo,
    WorkflowButtonColorCode,
    WorkflowButtonIcons,
};
