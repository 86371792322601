export default [
    {
      text: 'English',
      value: "en",
    },
    {
      text: '简体中文',
      value: "zh-Hans",
    },
    {
      text: '繁體中文' ,
      value:"zh-Hant",
    },
  ]