<template>
  <v-col class="card-field d-flex flex-row align-center pl-0">
     <label v-if="!valueOnly" 
      class="caption field-label grey--text text--lighten-1 mr-2">{{ fieldName }}</label>
  <!--<label v-if="view.cardDisplayFieldName">{{ fieldName }}</label>-->
  <!--<component ref="cardFieldComp"-->
             <!--v-bind:is="componentName"-->
             <!--:cardFieldValue="card[fieldInfo.fieldId]"-->
             <!--:fieldInfo="fieldInfo"></component>-->             
    <field-string
      height="auto"
      :textOnly="false"
      :fieldInfo="fieldInfo"
      :recordData="card"></field-string>
</v-col>

</template>

<script>
import fieldHelperMixin from '@/mixins/fieldHelperMixin'
import fieldString from '@/components/fieldStrings/Fs'

export default {
  name: 'cardField',
  components: {
    // ...cardFields,
    fieldString
  },
  mixins: [fieldHelperMixin],
  props: {
    card: Object,
    fieldInfo: null,
    view: Object,
    valueOnly: Boolean
  },
  computed: {
    fieldId () {
      return this.fieldInfo ? this.fieldInfo.fieldId : ''
    },
    fieldName () {
      return this.fieldInfo ? this.getFieldNameByInfo(this.fieldInfo) : ''
    },
    componentName () {
      const vm = this
      var result = 'cardFieldCommon'
      switch (vm.fieldInfo.type) {
        case 'singleSelection':
        case 'multipleSelection':
          result = 'cardFieldSingleSelection'
          break
        case 'attachments':
          result = 'cardFieldAttachments'
          break
        case 'members':
          result = 'cardFieldMembers'
          break
        case 'owner':
        case 'createdBy':
          result = 'cardFieldPerson'
          break
        case 'createdAt':
        case 'updatedAt':
          result = 'cardFieldDateTime'
          break

        // case 'system':
        //   const value = vm.card[vm.fieldId]
        //   switch (vm.fieldId) {
        //     case 'f9999': // owner
        //     case 'f9901': // created by
        //       result = 'cardFieldPerson'
        //       break
        //     case 'f9902': // created at
        //     case 'f9903': // updated at
        //       result = 'cardFieldDateTime'
        //       break
        //   }
        //   break
      }
      return result
    }
  }
}
</script>

<style>

  .card-field .col .card-value {
    color: black;
    line-height: 1;
  }

  .card-field .col label {
    color: rgb(128,128,128,.5);
    margin-right: 5px;
  }

  .card-field .col {
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-field .field-label {
    white-space: nowrap;
  }
</style>
