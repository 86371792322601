<template>
  <div class="workflow-del-box" v-on-clickaway="outOfBoxCancel">
    <div>
      <div class="workflow-del-box-title">
        {{ $t(`workflow.message.${type}`) }}
      </div>
      <v-layout justify-space-between align-center class="workflow-del-box-control">
        <v-btn elevation="0" class="workflow-del-box-btn" @click="$emit('cancel')">
          {{ $t('workflow.btn.cancel') }}
        </v-btn>
        <v-btn elevation="0" class="workflow-del-box-btn sure" @click="$emit('confirm')">
          {{ $t('workflow.btn.sureRemove') }}
        </v-btn>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
export default {
  mixins: [clickaway],
  props: {
    confirm: Function,
    cancel: Function,
    type: {
      type: String,
      default: 'removeNode',
    },
  },
  methods: {
    outOfBoxCancel() {
      this.$emit('cancel');
    },
  }
};

</script>

<style scoped>

</style>
