var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-12 pt-2 ",staticStyle:{"overflow":"auto"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"tabeSetupTitle"},[_vm._v(_vm._s(_vm.$t("tableSetup.functionToggle")))]),(_vm.formSettings)?_c('v-card-text',_vm._l((Object.entries(_vm.formSettings)),function(ref){
var category = ref[0];
var settings = ref[1];
return _c('div',{key:category},[_c('v-subheader',{staticClass:"pa-0 tabeSetupSubtitle font-weight-bold"},[_vm._v(_vm._s(_vm.$t(("functionToggle." + category))))]),_c('v-list',{staticClass:"mb-10 pa-0 bottom-border-none",attrs:{"max-width":"50%","outlined":""}},_vm._l((Object.entries(settings)),function(ref){
var setting = ref[0];
var value = ref[1];
return _c('FunctionRow',{key:setting,attrs:{"setting":setting,"category":category,"value":value},on:{"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"description",fn:function(){return [(_vm.descriptionMap[setting])?_c('v-tooltip',{attrs:{"top":"","max-width":"240"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.descriptionMap[setting]))])]):_vm._e()]},proxy:true}],null,true)})}),1)],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }