<template>
  <base-tabs-dialog
    v-if="showingDialog"
    v-model="showingDialog"
    :tabs="tabs"
    contentClass="teamSettingDialog"
    noFooter
  >
    <template>
      <v-tab-item
        v-for="(tab, index) in tabs"
        :key="index"
        :eager="true"
        :transition="false"
        :reverse-transition="false"
        class="fill-height pa-0"
        style="overflow: hidden"
      >
        <component :is="tab.component"></component>
      </v-tab-item>
    </template>
  </base-tabs-dialog>
</template> 

<script>
import baseTabsDialog from "@/components/dialogs/BaseTabsDialog";

import bankApiTabContent from "./bankApi/BankApiTabContent";
import mailSettingsTabContent from "@/components/dialogs/mail/MailSettingsTabContent";
import shareAppSettingTabContent from "./shareAppSetting/ShareAppSettingTabContent";
import sharedAppTabContent from "./sharedApp/SharedAppTabContent";

export default {
  name: "teamSettingsDialog",
  components: {
    baseTabsDialog,

    bankApiTabContent,
    mailSettingsTabContent,
    shareAppSettingTabContent,
    sharedAppTabContent
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    activeTeam() {
      return this.$store.getters.activeTeam;
    },
    tabs() {
      var result = [];
      if (false) {
        result.push({
          icon: "mdi-bank",
          labelTag: "settings.bankApi",
          component: bankApiTabContent
        });
      }
      if(this.isActiveTeamAdmin)
        result.push({
          icon: "mdi-mail",
          labelTag: "mailSetting.mailServerSetup",
          component: mailSettingsTabContent
        });
      
      if (this.activeTeam.allowDeployment) {
        result.push({
          icon: "mdi-account-settings",
          labelTag: "settings.shareAppSetting",
          component: shareAppSettingTabContent
        });
        result.push({
          icon: "mdi-text-box-multiple",
          labelTag: "settings.sharedApp",
          component: sharedAppTabContent
        });
      }
      return result;
    },
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    activeEmployee(){
      return this.$store.getters.activeEmployee
    },
    isActiveTeamAdmin(){
      return (this.activeEmployee && 
        this.activeEmployee.role && 
        this.activeEmployee.role.type === "ADMINISTRATOR")
    }
  }
};
</script>

<style lang="scss">
.teamSettingDialog .base-tabs-dialog-content .v-tabs-items {
  padding-left: 0px !important;
}
</style>