<template>
  <div 
    class="pa-0 text-no-wrap overflow-hidden d-flex flex-row align-center">
    <label v-if="useLabel"
          class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    <div>
        <v-chip small label>
            <v-icon small>mdi-link-variant</v-icon>
            <!-- {{relatedRecordLength}} -->
            {{relatedRecordContent}}
        </v-chip>
    </div>
  </div>    
</template>
<script>
import mixin from "./mixin";
export default {
    mixins: [mixin],
    computed: {
        relatedRecordLength(){
            const datum = this.recordData[this.fieldInfo._id];
            return Array.isArray(datum) && datum.length > 0? datum.length: ""
        },
        relatedRecordContent(){
            const datum = this.recordData[this.fieldInfo._id]
            if(Array.isArray(datum) && datum.length > 0){
                if(typeof datum[0] === 'object'){
                    return datum.reduce((a,c,index)=>{
                        if(index!=0) a+=","
                        a+=c.title
                        return a
                    },"")
                }else return datum.length
            }else return datum
        }
    }
}
</script>