<template>
  <div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.startDate')"
        name="source"
      >
        <DateSelect
          :value="source"
          fieldType="date"
          @valueChanged="v => dateChanged('source', v)"
        />
      </FormElement>
    </div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.endDate')"
        name="otherSource"
      >
        <DateSelect
          :value="otherSource"
          fieldType="date"
          @valueChanged="v => dateChanged('otherSource', v)"
        />
      </FormElement>
    </div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.format')"
        name="format"
      >
        <ComboBox
          :value="format || null"
          :options="formatOptions"
          :placeholder="$t('workflow.modules.operation.placeholder.selectFormat')"
          valueFormat="id"
          @changed="formatChanged"
        />
      </FormElement>
    </div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.outputDateUnit')"
        name="unit"
      >
        <ComboBox
          :value="unit || null"
          :options="unitOptions"
          :placeholder="$t('workflow.modules.operation.label.outputDateUnit')"
          valueFormat="id"
          @changed="unitChanged"
        />
      </FormElement>
    </div>
  </div>
</template>

<script>
import ComboBox from './../../../form/comboBox/';
import FormElement from './../shared/formElement';
import FreeInput from './../shared/freeInput';
import DateSelect from './../shared/dateSelect';
import Mixin from '../mixin';
import SettingMixin from './mixin';
// import {
//   SET_SETTINGS,
// } from '@/store/modules/workflow/action_types';

export default {
  mixins: [SettingMixin, Mixin],
  props: {
    expression: String,
    fields: Array,
    format: String,
    unit: String,
    otherSource: [String, Object],
    source: [String, Object]
  },
  data() {
    return {
      formatOptions: [
        {
          id: '24',
          label: this.$t('workflow.modules.operation.label.formatUnit.24')
        },
        {
          id: '12',
          label: this.$t('workflow.modules.operation.label.formatUnit.12')
        },
      ],
      unitOptions: [
        {
          id: 'years',
          label: this.$t('workflow.modules.operation.label.dateUnit.years')
        },
        {
          id: 'months',
          label: this.$t('workflow.modules.operation.label.dateUnit.months')
        },
        {
          id: 'days',
          label: this.$t('workflow.modules.operation.label.dateUnit.days')
        },
        {
          id: 'hours',
          label: this.$t('workflow.modules.operation.label.dateUnit.hours')
        },
        {
          id: 'minutes',
          label: this.$t('workflow.modules.operation.label.dateUnit.minutes')
        },
        {
          id: 'seconds',
          label: this.$t('workflow.modules.operation.label.dateUnit.seconds')
        },
      ],
    };
  },
  components: {
    ComboBox,
    FormElement,
    FreeInput,
    DateSelect,
  },
  // watch: {
  //   unit: {
  //     handler(v) {
  //       this.changed('unit', parseInt(v));
  //     },
  //     deep: true
  //   },
  // },
};

</script>

<style scoped>

</style>
