<template>
  <div class="field-string-attachments pa-0 text-no-wrap overflow-hidden d-flex flex-row align-center fill-height"
    :style="containerStyle">
    <label v-if="useLabel"
           class="field-label grey--text text--lighten-1 mr-2">{{ label }}</label>
    <div v-if="isLayout">
        <fs-attachment-item
            :media="media"></fs-attachment-item>
    </div>
    <div v-else-if="attachmentCount>0" class="fill-height w-100">
      <div v-if="textOnly">
        {{ label }}
      </div>
      <div v-else class="w-100 fill-height d-flex flex-row flex-wrap" 
        :style="attachmentWrapperStyle">
        <fs-attachment-item
            v-for="(media, index) in attachments"
            :key="index"
            :canPreview="canPreview"
            :inline="inline"
            :recordMode="recordMode"
            :rowHeightClass="rowHeightClass"
            class="mr-1"
            :media="media"></fs-attachment-item>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import fsAttachmentItem from './FsAttachmentItem'

export default {
  name: 'fsAttachments',
  mixins: [mixin],
  props: {
    isLayout: Boolean,
    rowHeightClass: {
      type: String,
      default: 'regular' // large, x-large
    },
    inline: Boolean,
    canPreview: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  components: {
    fsAttachmentItem
  },
  computed: {
    attachmentWrapperStyle () {
      return this.recordMode 
        ? {'padding': '8px 8px 4px 8px'}
        : {'padding-top': '1px'};
    },
    containerStyle () {
      var result = {};
      if (this.recordMode || this.border) {
        result['border'] ='1px solid #EEE';
        result['border-radius'] = '0.5rem';
        if (this.recordMode) {
          result['background-color'] = '#EEE';
        }
      }
      return result;
    },
    // imageClass () {
    //   var result = 'image-regular'; // imageSize==='regular'
    //   switch (this.imageSize) {
    //     case 'small':
    //       result = 'image-small';
    //       break;
    //     case 'large':
    //       result = 'image-large';
    //       break;
    //     case 'x-large':
    //       result = 'image-x-large';
    //       break;
    //   }
    //   return result;
    // },
    attachments () {
      const vm = this
      var result = []
      if (vm.dataValue && Array.isArray(vm.dataValue) && vm.dataValue.length > 0) {
        result = vm.dataValue
      }
      return result
    },
    attachmentCount () {
      return this.attachments.length
    },
    label () {
      const vm = this
      var result = ''
      if (vm.fieldInfo && vm.fieldInfo.properties && vm.fieldInfo.properties.fieldName) {
        result = vm.fieldInfo.properties.fieldName
      }
      return result
    }
  }
}
</script>
