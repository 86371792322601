<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="!isReadonly && required"
      :isHidden="isHidden"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      :noIcon="noIcon"
      :tooltipText="description">
    <v-text-field
      v-if="isReadOnly"
      :value="inputValue"
      disabled
      dense
      hide-details
      class="disabled"
    ></v-text-field>
    <v-text-field
        v-else
        v-model="inputValue"
        :class="{ 'workflow-customize-form-record-hide': hideInput }"
        class="workflow-customized-form-record-element"
        single-line
        clear-icon="mdi-close"
        :readonly="isReadOnly"
        :ref="fieldInfo.fieldId"
        :rules="rules"
        background-color="white"
        clearable
        :hide-details="isLayout"
        dense
        outlined
        :placeholder="placeholder"
        :error-messages="errorMessage"
        :error="hasError"
        @blur="onBlur"
        @input="errorMessage = ''"></v-text-field>
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'

export default {
  name: 'inputWidgetEmail',
  mixins: [mixin],
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  components: {
    inputWidgetContainer
  },
  methods: {
    validate(){
      return new Promise(async (resolve, reject) => {
        let valid = true;
        let duplicated = false
        if (!this.isReadOnly && this.$refs[this.fieldInfo.fieldId]) {
          valid = this.$refs[this.fieldInfo.fieldId].validate()
          this.hasError = !valid
          if (this.noDuplicate){
            duplicated = await this.checkDuplicate()
          }
        }
        resolve(valid && !duplicated)
      })
    },
    getRules () {
      return this.RULE_EMAIL
    },
    onBlur(){
      this.focusing = false
      this.checkDuplicate()
    }
  }
}
</script>
