<template>
  <v-dialog
    v-model="showingDialog"
    scrollable
    persistent
    width="800"
    max-width="90%">
    <v-card min-height="480">
      <!-- <v-card-title class="headline grey lighten-2"> 
        {{ $t('myapps.availableTeams') }}
      </v-card-title> -->
      <dialog-header
        :label="$t('myapps.availableTeams')"
        @close="showingDialog = false"
        color="#E0E0E0"
        toolbarClass="grey--text"
      />
      <div class="pt-2 px-3">
        <v-simple-table class="">
            <thead>
              <tr>
                <th class="text-center blue darken-3 white--text">{{ $t('general.team') }}</th>
                <th class="text-center blue darken-3 white--text">{{ $t('messages.setupAccessibleTeamsFurtherAuthorizationRequiredForDataAccess') }}</th>
              </tr>
            </thead>
        </v-simple-table>
      </div>
      <v-card-text class="team-authorization-setup-dialog-body px-3">
        <v-simple-table>
          <tbody>
            <tr v-for="team in teams" :key="team._id">            
              <td class="py-2 pr-2">
                <div class="d-flex flex-row justify-start align-center">
                  <v-img contain 
                    max-width="32" 
                    max-height="32"
                    class="team-logo mr-2" :src="team.logo"></v-img>
                    <div class="team-name">{{ team.name }}</div>
                </div>
              </td>
              <td class="py-2 w-100">
                <v-chip v-if="team.terminated"
                    class="justify-center"
                    color="error"
                    :style="{opacity:0.8, minWidth:'100%'}"
                    label
                    large>{{ $t('messages.terminated') }}</v-chip> 
                </v-chip>
               <accessible-team-selection
                  v-else
                  class="accessible-team-selection"
                  :selection="accessibleTeamDict[team._id]"
                  :teams="teams.filter(t => t._id!==team._id && !terminatedTeamIds.includes(t._id))"
                  @update="val=>{updateAccessibleTeams(team._id,val)}"></accessible-team-selection>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <!-- <v-card-title>
      </v-card-title> -->
        <!-- <v-divider class=""></v-divider> -->
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="noChange"
          class="min-width-80 mr-1" @click="save">{{ $t('buttons.ok') }}</v-btn>
        <v-btn class="min-width-80 mr-1" @click="close">{{ $t('buttons.cancel') }}</v-btn>
      </v-card-actions> -->
      <dialog-actions
        :actionDisabled="noChange"
        :buttons="['cancel','ok']"
        @onCommand="onCommandHandler($event)">
      </dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import accessibleTeamSelection from './comps/AccessibleTeamSelection';

export default {
  name: 'teamAuthorizationSetupDialog',
  components:{
    dialogActions,
    dialogHeader,
    accessibleTeamSelection
  },
  data () {
    return {
      showingDialog: false,
      accessibleTeamDict: {},
      oldAccessibleTeamDictStr: ''
      // e.g.
      // accessibleTeamsDict[{team id1}] = [{team id2}, {iteam id3}]
      // accessibleTeamsDict[{team id2}] = [{team id1}]
      // accessibleTeamsDict[{team id3}] = [{team id1}, {iteam id2}]
    }
  },
  computed: {
    dummyTeams () {
      const vm = this
      var result = []
      for (let i = 0; i < 300; i++) {
        result.push({
          _id: 'team_' + i,
          name: 'team ' + i
        })
      }
      return result
    },
    teams () {
      return this.$store.getters.allTeamsIncludingTerminated.filter(team => this.userTeamIds.includes(team._id))
    },
    userTeamIds () {
      return this.$store.getters.user ?
        this.$store.getters.user.teams.map(team => team._id) :
        []
    },
    targetTeams() {
      const vm = this
      let result = {}
      for (let i = 0; i < vm.teams.length; i++) {
        const loopTeam = vm.teams[i]
        result[loopTeam._id] = vm.teams.filter(t => t._id !== loopTeam._id)
      }
      return result
    },
    noChange () {
      const vm = this
      return JSON.stringify(vm.accessibleTeamDict) === vm.oldAccessibleTeamDictStr
    },
    terminatedTeamIds () {
      return this.$store.getters.allTeamsIncludingTerminated.filter(team => !!team.terminated).map(team => team._id);
    }
  },
  methods: {
    updateAccessibleTeams (teamId, updatedAccessibleTeamIds) {
      const vm = this
      // notes: 
      //
      // local teams = the team, to which current user belongs.
      //
      const original = vm.accessibleTeamDict[teamId]
      const keepNonLocalAccessibleTeamIds = original.filter(id => !vm.userTeamIds.includes(id))
      const updatedLocalAccessibleTeamIds = updatedAccessibleTeamIds.filter(
        id => !keepNonLocalAccessibleTeamIds.includes(id))

console.log('udpateAccessibleTeams:  teamId = ' + teamId);
console.log('udpateAccessibleTeams:  updateAccessibleTeamIds: ', updatedAccessibleTeamIds);
console.log('udpateAccessibleTeams:  original: ', original);
console.log('udpateAccessibleTeams: keepNonLocalAccessibleTeamIds: ', keepNonLocalAccessibleTeamIds);
console.log('udpateAccessibleTeams: updatedLocalAccessibleTeamIds: ', updatedLocalAccessibleTeamIds);
console.log('udpateAcccessibleTeams: terminatedTeamIds: ', vm.terminatedTeamIds);
      const accessibleTeamIds =  [
        ...keepNonLocalAccessibleTeamIds,
        ...updatedLocalAccessibleTeamIds
      ];
console.log('udpateAccessibleTeams: accessibleTeamIds: ', accessibleTeamIds);
      vm.accessibleTeamDict[teamId] = accessibleTeamIds.filter(teamId => !this.terminatedTeamIds.includes(teamId));

console.log('udpateAccessibleTeams: vm.accessibleTeamDict[' + teamId + ']: ', vm.accessibleTeamDict[teamId]);
console.log('udpateAccessibleTeams: vm.accessibleTeamDict: ', vm.accessibleTeamDict);

    },
    onCommandHandler(payload){
      if(payload.command=="ok")
        this.save()
      else
        this.close()
    },
    open () {
      const vm = this
      vm.init()
      vm.showingDialog = true
    },
    close () {
      this.showingDialog = false
    },
    save () {
      const vm = this
      vm.$store.dispatch('UPDATE_ACCESSIBLE_TEAMS', {
        accessibleTeamDict: JSON.stringify(vm.accessibleTeamDict)        
      }).then(
        response => {
          vm.$toast.success(vm.$t('myapps.teamAuthorizationUpdated'))
          vm.showingDialog = false
        }
      )
    },
    init () {
      this.accessibleTeamDict = this.getAccessibleTeamDict(this.teams)
      this.oldAccessibleTeamDictStr = JSON.stringify(this.accessibleTeamDict)
    },
    getAccessibleTeamDict (teams) {
      const vm = this
      let result = {}
      console.log('getAccessibleTeamDict teams.length = ' + teams.length);
      console.log('getAccessibleTeamDict this.terminatedTeamIds: ', this.terminatedTeamIds);
      for (let i = 0; i < vm.teams.length; i++) {
        const loopTeam = vm.teams[i]
        console.log('i= ' + i + ': loopTeam.accessibleTeams: ', loopTeam.accessibleTeams);
        if (loopTeam.accessibleTeams && Array.isArray(loopTeam.accessibleTeams)) {
          result[loopTeam._id] = loopTeam.accessibleTeams.filter(teamId => !this.terminatedTeamIds.includes(teamId));
        } else {
          result[loopTeam._id] = []
        }
      }
      console.log('getAccessibleTeamDict result: ', result);
      return result
    },
    isAccessible (team, targetTeam) {
      const vm = this
      let result = false

      if (vm.accessibleTeamDict && vm.accessibleTeamDict[team._id]) {
        result = this.accessibleTeamDict[team._id].includes(targetTeam._id)
      }
      return result
    },
    toggleAccessibleTeam (team, targetTeam) {
      const vm = this
      const index = vm.accessibleTeamDict[team._id].indexOf(targetTeam._id)
      if (index >= 0) {
        vm.accessibleTeamDict[team._id].splice(index, 1)
      } else {
        vm.accessibleTeamDict[team._id].push(targetTeam._id)
      }
    }
  }
}
</script>

<style>

.team-authorization-setup-dialog-body .v-data-table tr:nth-child(2n) {
  background-color: rgba(244,244,244);
}

.team-authorization-setup-dialog-body .team-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.team-authorization-setup-dialog-body .team-logo {
  width: 32px;
  height: 32px;  
}

.team-authorization-setup-dialog-body .accessible-team-selection .v-select__selections {
  padding-top: 3px;
  padding-bottom: 3px;
}

.team-authorization-setup-dialog-body .accessible-team-selection {

}
</style>