<template>
  <h1>Related Summary Filter Criteria</h1>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin]
}
</script>
