export default {
    methods: {
        uploadFiles_old(files) {
            const vm = this;
            const postPayload = {
              appId: vm.$store.getters.currentApp._id,
              files: files,
            };
            vm.$store.dispatch("UPLOAD_MEDIA", postPayload).then((medias) => {
              this.files = [...this.files, ...medias];
            });
          },
          // removeAttachment(fileId){
          //   const postData = {
          //     urlCommand: '/medias/deleteAttachment',
          //     data : {
          //       fileId : fileId,
          //       appId: this.appId
          //     }
          //   }
          //   return this.$store.dispatch("AUTH_POST", postData).then(res => res);
          // },        
    }
}