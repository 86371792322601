<template>
  <div class="d-flex flex-column team-config" style="height:100%; overflow:hidden">
    <div class="py-3 d-flex align-center grey lighten-4">
      <v-btn class="ml-3" icon @click="closeConfig()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span
        v-if="!configId"
        class="ml-3"
        style="font-size:15px"
      >{{ $t('settings.newTeamConnection') }}</span>
      <span v-else class="ml-3" style="font-size:15px">{{$t('setting.viewTeamConnection')}}</span>
    </div>
    <v-form ref="teamConfigForm" style="overflow: auto" class>
      <div class="my-4 px-5">
        <div class="d-flex align-center" style="width:100%;">
          <table class="flex-grow-0">
            <!-- team code -->
            <tr>
              <td style="vertical-align:middle;width:90px;">{{ $t('settings.teamCode') }}</td>
              <td style="vertical-align:middle;width:320px;">
                <v-text-field
                  :rules="[RULE_TEXT]"
                  outlined
                  tile
                  height="20"
                  :hide-details="true"
                  class="rounded-0 mailTextField"
                  dense
                  @keypress="onEnterTeamCode"
                  v-model="teamSetting['teamCode']"
                  :placeholder="$t('settings.pleaseInputTeamCode')"
                  style="font-size:12px;"
                />
              </td>
              <td class="pl-2" style="vertical-align:middle;">
                <v-btn small color="primary" class="min-width-100" @click="checkTeamValidity">Search</v-btn>
              </td>
            </tr>
            <tr v-if="searched" class="search-result"
              :class="searched ? 'searched' : ''">
              <td colspan="3" class="py-5">
                <div v-if="!validTeam">
                  <h3>{{ $t('settings.teamNotFoundPleaseTryAgain') }}</h3>
                </div>
                <div v-else style="position:relative;">
                  <h4>{{ $t('settings.searchResult') }}</h4>
                  <div class="d-flex flex-row align-start">
                    <div class="me-5 no-wrap">{{ $t('settings.companyNameRegisteredAtWork') }}:</div>
                    <div class="font-weight-bold black--text">{{ searchResult.teamName }}</div>
                  </div>
                  <div class="d-flex flex-row align-center">
                    <div class="me-5">{{ $t('settings.emailRegisteredAtWork') }}:</div>
                    <div class="font-weight-bold black--text">{{ searchResult.registeredEmail }}</div>
                  </div>
                  <div v-if="alreadyAdded" color="error" class="restriction-notification">
                    <h2>{{ $t('settings.alreadyAdded') }}</h2>
                  </div>
                  <div v-else-if="isSelf" color="error" class="restriction-notification">
                    <h2>{{ $t('settings.actionDenied') }}</h2>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-if="searched && validTeam && !alreadyAdded">
              <td colspan="2">
                <h3 class="mt-2">{{ $t('settings.optionalRefInfoBelow') }}</h3>
                <hr class="theme-light mt-1 mb-5" style="border-color:rgba(100,100,100,.1);" />
              </td>
              <td></td>
            </tr>

            <template v-if="searched && validTeam && !alreadyAdded">
              <tr v-for="(row) in optionalRows" :key="row.key">
                <td class="pe-1" style="text-align:right;vertical-align:top;">{{ $t(row.labelTag) }}</td>
                <td :style="row.width?'width:'+row.width+'px':''">
                  <v-text-field
                    :rules="getRules(row)"
                    outlined
                    tile
                    height="20"
                    class="rounded-0 mailTextField"
                    :class="fieldIndex!=0? '':''"
                    dense
                    v-model="teamSetting[row.key]"
                    :placeholder="$t(row.placeholderTag)"
                    style="font-size:12px;"
                  />
                </td>
                <td :rowspan="optionalRows.length"></td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </v-form>
    <div class="mt-auto grey lighten-4 d-flex justify-end">
      <div class="d-flex mr-5">
        <div>
          <v-btn text @click="closeConfig">{{$t('buttons.cancel')}}</v-btn>
        </div>
        <div>
          <v-btn text :disabled="!validTeam || alreadyAdded || isSelf" color="teal" @click="saveConfig">
            <span v-if="configId==0">{{$t('general.new')}}</span>
            <span v-else>{{$t('buttons.save')}}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searched: false,
      teamSetting: {
        // companyName: 'API DEMO 2',
        // registeredEmail: 'joa@emcoo.com',
        _id: "", // auto-filled by searching
        teamCode: "",
        contact: "",
        contactTelNo: ""
        // contactEmail: ''
      },
      searchResult: {
        teamName: "",
        registeredEmail: ""
      },
      lastTeamSetting: {
        companyName: "",
        registeredEmail: "",
        teamCode: ""
      },
      rows: [
        {
          labelTag: "settings.companyNameRegisteredAtWork",
          key: "companyName",
          value: "",
          type: "text",
          placeholderTag: "settings.companyNameRegisteredAtWork",
          required: true,
          inputWidth: "auto"
        },
        {
          labelTag: "settings.emailRegisteredAtWork",
          key: "registeredEmail",
          value: "",
          type: "email",
          placeholderTag: "settings.emailRegisteredAtWork",
          required: true,
          inputWidth: "auto"
        },
        {
          labelTag: "settings.teamCode",
          key: "teamCode",
          value: "",
          type: "text",
          placeholderTag: "settings.teamCode",
          required: true,
          inputWidth: "320"
        }
      ],
      optionalRows: [
        {
          labelTag: "settings.contact",
          key: "contact",
          value: "",
          type: "text",
          placeholderTag: "settings.contact",
          required: false,
          inputWidth: 450
        },
        {
          labelTag: "settings.contactTelNo",
          key: "contactTelNo",
          value: "",
          type: "text",
          placeholderTag: "settings.contactTelNo",
          required: false,
          inputWidth: 320
        }
        // {
        //     labelTag: 'settings.contactEmail',
        //     key: 'contactEmail',
        //     value: '',
        //     type: 'email',
        //     placeholderTag: 'settings.contactEmail',
        //     required: false,
        //     inputWidth: 320
        // }
      ],
      visible: true,
      verifying: false,
      validInput: false,
      validTeam: false
    };
  },

  props: {
    teams: Array
  },
  computed: {
    isSelf() {
      return this.teamSetting._id === this.activeTeamId;
    },

    alreadyAdded() {
      var result = false;
      if (this.teams) {
        for (let i = 0; i < this.teams.length; i++) {
          const loopTeam = this.teams[i];
          if (loopTeam._id === this.teamSetting._id) {
            result = true;
            break;
          }
        }
      }
      return result;
    },

    activeTeamId() {
      return this.$store.getters.activeTeamId;
    },
    RULE_NUMBER() {
      return v =>
        Number.isInteger(Number(v)) || this.$t("mailSetting.ruleInteger");
    },

    RULE_TEXT() {
      return v =>
        (v && v.trim().length <= 50) || this.$t("mailSetting.ruleText");
    },

    RULE_EMAIL() {
      return v => /.+@.+\..+/.test(v) || !v || this.$t("mailSetting.ruleEmail");
    },

    requiredAuth() {
      return this.rows
        .flat()
        .find(field => field.key == "requireAuthentication").value;
    },
    inputChanged() {
      return (
        this.teamSetting.companyName !== this.lastTeamSetting.companyName ||
        this.teamSetting.registeredEmail !==
          this.lastTeamSetting.registeredEmail ||
        this.teamSetting.teamCode !== this.lastTeamSetting.teamCode
      );
    }
  },
  methods: {
    onEnterTeamCode(e) {
      if (e.code === "Enter") {
        e.preventDefault();
        this.checkTeamValidity();
      }
    },
    // onLeaveInput () {
    //   this.validInput = this.$refs.teamConfigForm.validate();
    //   if (this.validInput) {
    //     if (this.inputChanged) {
    //       this.checkTeamValidity();
    //       this.lastTeamSetting = {
    //         companyName: this.teamSetting.companyName,
    //         registeredEmail: this.teamSetting.registeredEmail,
    //         teamCode: this.teamSetting.teamCode
    //       };
    //     }
    //   }
    // },
    getOwnerInfo(teamInfo) {
      var result = null;
      if (teamInfo.employees && teamInfo.employees.length > 0) {
        result = teamInfo.employees[0];
      }
      return result;
    },

    // getContactEmail (employee) {
    //   return employee ? employee.email : '';
    // },

    // getContactName(employee) {
    //   let resultSegs = [];
    //   console.log(
    //     "getContactName: employee.employeePersonalInfo: ",
    //     employee.employeePersonalInfo
    //   );
    //   if (employee.employeePersonalInfo.firstName)
    //     resultSegs.push(employee.employeePersonalInfo.firstName.trim());

    //   if (employee.employeePersonalInfo.lastName)
    //     resultSegs.push(employee.employeePersonalInfo.lastName.trim());

    //   console.log("getContactName: resultSegs: ", resultSegs);
    //   const result = resultSegs.join(" ");
    //   console.log("getContactName result: ", result);

    //   return result;
    // },

    // getContactTelNo(employee) {
    //   var result = "";
    //   if (employee.officePhone && employee.officePhone !== "") {
    //     result = employee.officePhone;
    //   } else if (employee.mobile && employee.mobile !== "") {
    //     result = employee.mobile;
    //   }
    //   console.log("getContactTelNo: result: ", result);
    //   return result;
    // },

    checkTeamValidity() {
      this.verifying = true;
      var postData = {
        urlCommand: "/teams/checkValidity",
        data: {
          // companyName: this.teamSetting.companyName,
          // registeredEmail: this.teamSetting.registeredEmail,
          teamCode: this.teamSetting.teamCode
        }
      };
      this.$store.dispatch("AUTH_POST", postData).then(response => {
        this.validTeam = !!response.result;
        this.verifying = false;
        const ownerInfo = this.getOwnerInfo(response.result);
        this.searchResult = {
          teamName: response.result.name,
          registeredEmail: ownerInfo ? ownerInfo.email : ""
        };
        this.teamSetting = {
          ...this.teamSetting,
          _id: response.result._id,
          contact: response.result.contact ? response.result.contact : '',
          contactTelNo: response.result.contactInfo ? response.result.contactInfo : '',
        };
        this.searched = true;
      });
    },
    closeConfig() {
      this.$emit("onCommand", {
        command: "cancel"
      });
    },
    saveConfig() {
      console.log("saveConfig");
      var form = this.rows.flat().reduce((result, field) => {
        result[field.key] =
          field.type == "text" || field.type == "email"
            ? field.value.trim()
            : field.value;
        return result;
      }, {});
      console.log("saveConfig: form: ", form);

      this.$emit("onCommand", {
        command: "save",
        data: {
          _id: this.teamSetting._id,
          contact: this.teamSetting.contact,
          contactTelNo: this.teamSetting.contactTelNo
        }
      });
    },
    getRules(field) {
      var rules = [];
      let vm = this;
      if (field.required) {
        rules.push(v => !!v || vm.$t("mailSetting.ruleRequired"));
      }
      switch (field.type) {
        case "number":
          rules.push(this.RULE_NUMBER);
          break;
        case "text":
          rules.push(this.RULE_TEXT);
          break;
        case "email":
          rules.push(this.RULE_EMAIL);
          break;
      }
      return rules;
    }
  }
};
</script>

<style lang="scss">
.team-config .switch {
  position: relative;
  display: inline-block;
}

/* Hide default HTML checkbox */
.team-config .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.team-config .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 22px;
  width: 58px;
}

.team-config .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 25px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-config input:checked + .slider {
  background-color: $success;
}

.team-config input:focus + .slider {
  box-shadow: 0 0 1px $success;
}

.team-config input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.team-config .mailTextField .v-input__control .v-input__slot {
  min-height: 22px !important;
}

.restriction-notification {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  background-color: rgba(255, 100, 100, 0.5);
  border: 2px solid transparent;
  border-radius: 0.5rem;
  padding: 5px 10px;
}

.search-result .no-wrap {
  white-space: nowrap;
}
.search-result {
  transition: opacity 2s;
}
.search-result.searched {
  opacity: 1;
}
</style>
