<template>
    <div class="gantt-left-container" :style="`border-left: 5px solid ${groupColor}`">
        <v-treeview
            :items="slotData.gtArray"
            dense
            hoverable
            :open="openArr"
            :open-all="!initialized"
            @update:open="handleOpenEvent"
            item-key="_id"
        >
            <template v-slot:label="{item}">
                <v-hover v-slot:default="{ hover }" :id="`contextMenuIcon_${item._id}`">
                    <div class="d-flex align-center">
                        <div>
                            {{item.name}}
                        </div>
                        <!-- && !item.children.length -->
                        <v-icon v-if="hover" style="cursor:pointer" @click="showContextMenu(item._id)" size="18" class="flex-grow-0 ml-auto">
                            mdi-dots-horizontal
                        </v-icon>
                    </div>
                </v-hover>
            </template>
            <template v-slot:prepend="{item}">
                <!-- @input="item.color=$event" -->
                <color-picker-menu
                    v-if="!item.parent && isDefaultGroupBy"
                    v-model="groupColor"
                    class="mr-1"
                    @input="handleColorInput(item._id,$event)"
                    :showText="false"
                />
            </template>
        </v-treeview>
    </div>
</template>

<script>
    import colorPickerMenu from '@/components/inputWidgets/comps/ColorPickerMenu'
    export default {
        name: 'GanttLeft',
        props:{
            slotData: Object,
            groupStyle: Object,
            projectCascade: [String, Array],
            groupBy: [String, Array]
        },
        components:{
            colorPickerMenu
        },
        data(){
          return{
            ganttBlockTree: null,
            openArr: [],
            initialized: false,
            // groupColor: undefined
          }
        },
        computed:{
            isDefaultGroupBy(){
                var equalCol = false
                equalCol = true
                // if(Array.isArray(this.groupBy) && Array.isArray(this.col_projectCascade)){
                //     equalCol = this.groupBy.every(item=> this.col_projectCascade.includes(item))
                // }else
                // {
                //     equalCol = this.groupBy ==this.col_projectCascade
                // }
                return equalCol
            },
            groupColor(){
                if(this.slotData._id == 'empty') return
                // console.log('this.slotData.gtArray[0]._id', this.slotData.gtArray[0]._id)
                // console.log('this.groupStyle', this.groupStyle)
                if(this.isDefaultGroupBy){
                    if(this.groupStyle && this.groupStyle.hasOwnProperty(this.slotData.gtArray[0]._id))
                        return this.groupStyle[this.slotData.gtArray[0]._id]
                    else 
                        return '#b2dfdb'
                }else{
                    if(this.slotData.gtArray[0].color)
                        return this.slotData.gtArray[0].color
                    else 
                        return '#b2dfdb'
                }
            }
        },
        methods:{
            handleColorInput(id,event){
                this.$emit('updateSetting', {command: 'update_groupStyle', val: {_id:id, event}})
            },
            showContextMenu(itemId){
                this.$emit('showContextMenu', itemId)
            },
            handleOpenEvent(e){
                this.openArr = e
                console.log("ganttBlockTree", this.ganttBlockTree)
                if(this.ganttBlockTree){
                    this.ganttBlockTree.openArr = e
                }
            },
            setGanttBlockTree(obj){
                this.ganttBlockTree  = obj
                console.log({
                    'slotData_id': this.slotData._id, 
                    'ganttBlockTree': this.ganttBlockTree})
            }
        },
        mounted(){
            this.$nextTick(()=>{
                this.$emit('getGanttBlockTree', {
                    _id: this.slotData._id,
                    callback:this.setGanttBlockTree
                })
                this.initialized=true
            })
        }
    }
</script>

<style>
    .gantt-leftbar-item{
       /* height: 100px !important */
        height: auto !important
    }
    .gantt-left-container{
        
    }
    .gantt-leftbar-wrapper{
        /* height: 700px !important; */
        /* overflow: scroll !important; */
    }
    .gantt-block{
        height: 100px !important
    }
    .v-treeview-node__prepend{
        margin-right: 2px !important
    }
    .v-treeview-node__content{
        margin-left: 0px !important
    }
</style>