<template>
  <v-menu
    :rounded="true"
    offset-y>
    <template v-slot:activator="{attrs, on}">
      <div
        v-bind="attrs"
        v-on="on">
      <v-btn
        outlined
        :color="hasError ? 'error' : ''"
        class="w-100 justify-start text-body-2"
        small>
        {{ selectedMenuItem ? selectedMenuItem.label : $t('inputPanel.pleaseSelect') }}
      </v-btn>
      <error-message
        v-if="hasError"
        :message="errorMessage"></error-message>
      </div>
    </template>
    <v-list subheader>

      <template v-for="(moduleMenu, index) in moduleMenus">
        <v-subheader :key="index">{{ moduleMenu.label }}</v-subheader>
        <v-list-item-group v-if="moduleMenu.menus.length>0" :key="index">
          <v-list-item v-for="(menu, index) in moduleMenu.menus"
            @click="selectMenu(menu)"
            :key="index">
            {{ menu.label }}
          </v-list-item>
        </v-list-item-group>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import errorMessage from './ErrorMessage'

export default {
  name: 'formSelectionMenu',
  components: {
    errorMessage
  },
  model: {
    prop: 'selectedMenuId',
    event: 'input'
  },
  props: {
    selectedMenuId: String
  },
  data () {
    return {
      hasError: false,
      validated: false
    }
  },
  computed: {
    selectedMenuItem () {
      const vm = this
      var result = null
      if (vm.selectedMenuId) {
        for (let i = 0; i < vm.moduleMenus.length; i++) {
          const loopModuleMenu = vm.moduleMenus[i]
          for (let j = 0; j < loopModuleMenu.menus.length; j++) {
            const loopMenu = loopModuleMenu.menus[j]
            if (loopMenu._id === vm.selectedMenuId) {
              result = loopMenu
              break
            }
          }
          if (result) break
        }
      }
      return result
    },
    modules () {
      return this.$store.getters.currentApp ?
        this.$store.getters.currentApp.modules :
        []
    },
    moduleMenus () {
      const vm = this
      // [
      //    {
      //      type: ['module'|'form'],
      //      label: '...',
      //      formId: '...'
      //    }
      //  ]
      var result = [
        // { label: 'module 1', menus: [...] }
      ]
      for (let i = 0; i < vm.modules.length; i++) {
        const loopModule = vm.modules[i]
        var module = {}
        module = {
           label: loopModule.title,
           menus: vm.getModuleMenu(loopModule.menu),
           type: 'module'
        }
        result.push(module)
    
      }
      return result
    },
    errorMessage () {
      return this.hasError ? this.$t('messages.thisFieldIsRequired') : ''
    }
  },
  methods: {
    validate () {
      this.hasError = this.selectedMenuId === ''
      return !this.hasError
    },
    resetValidation() {
      this.hasError = false
    },
    selectMenu (menu) {
      this.$emit('input', menu._id)
      this.$nextTick(() => {
        this.validate()
      })      
    },
    getModuleMenu (menuItems) {
      const vm = this
      var menus = []
      for (let i = 0; i < menuItems.length; i++) {
        const loopMenu = menuItems[i]
        if (loopMenu.type === 'form') {
          menus.push({
            _id: loopMenu._id,
            label: loopMenu.title,
            formId: loopMenu._id,
            type: 'form'
          })
        } else if (loopMenu.type==='submenu') {
          if (loopMenu.children && loopMenu.children.length > 0) {
            const childMenu = vm.getModuleMenu(loopMenu.children)
            console.log('childMenu ' + loopMenu.title + ' : ', childMenu)
            if (childMenu.length > 0) {
              menus = menus.concat(childMenu)
            }
          }
        }
      }
      return menus
    }
  }
}
</script>