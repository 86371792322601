<template>
    <grid-layout 
        :layout.sync="elements"
        :col-num="12"
        :row-height="30"
        :is-draggable="draggable"
        :is-resizable="resizable"
        :vertical-compact="true"
        :use-css-transforms="true"
        @layout-ready="layoutReadyEvent"
        style="min-height:100%;"
        :style="`background-color:${bgColor}`"
        class="pa-0 ma-0"
        :key="gridLayoutKey"
    >

        <grid-item v-for="(item,index) in elements"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item._id"
            @resize="resizeEvent"
            @resized="resizedEvent"
            class="touch-action-none"
            style="border: 0px; overflow:auto; background-color: #eeee"
        >
            <div class="d-flex flex-column" style="height:100%">
                <div class="pa-0 py-1" style="background-color: #eeee;" v-show="item.bl_title">
                    <v-text-field v-if="isBuilder" v-model="item.title" :placeholder="$t('general.title')" hide-details dense height="30"/>
                    <div v-else class="font-weight-bold">{{item.title}}</div>
                </div>
                <component 
                    :is="'comp'+ucFirst(item.type)"
                    :blHover="bl_hover"
                    :item="item"
                    @cloneItem="cloneItem"
                    :extraDateFilter="extraDateFilter"
                    @deleteItem="deleteItem"></component>
            <div v-if="false">
            </div>
            </div>
        </grid-item>
    </grid-layout>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout"
import panelComps from './panelComps'
import * as echarts from 'echarts';
export default {
    name: 'gridCanvas',
    components: {
        ...panelComps,
        GridLayout,
        GridItem
    },
    props:{
        elements: Array,
        draggable: {
            type: Boolean,
            default: true
        },
        resizable: {
            type: Boolean,
            default: true
        },
        bl_hover:{
            type: Boolean,
            default: true
        },
        bgColor:{
            type: String,
            default: '#eeeeee'
        },
        extraDateFilter: Object,
        isBuilder:{
            type: Boolean,
            default: true
        },
		isPanel:{
			type: Boolean,
            default: false
		}
    },
    watch:{
        elements(newVal, oldVal){
            if(!this.bl_ready) return
            this.$nextTick(function () {
                newVal.forEach((item, index)=>{
                    if(item.type == 'chart'){
                        let chartDom = this.isPanel?
                            document.getElementById(`chart-${item.i}`):
                            document.getElementById(`canvas_chart-${item.i}`)
                        if(chartDom){
                            echarts.dispose(chartDom)
                            let chart = echarts.init(chartDom)
                            chart.resize(500, 200)
                            item.chart.option && chart.setOption(item.chart.option);
                        }
                    }
                })
            })
        }
    },
    data() {
        return {
            gridLayoutKey:1,
            index: 0,
            bl_ready: false,
            layout: [
                {"x":0,"y":0,"w":2,"h":2,"i":"0"},
                {"x":2,"y":0,"w":2,"h":4,"i":"1"},
                {"x":4,"y":0,"w":2,"h":5,"i":"2"},
                {"x":6,"y":0,"w":2,"h":3,"i":"3"},
                {"x":8,"y":0,"w":2,"h":3,"i":"4"},
                {"x":10,"y":0,"w":2,"h":3,"i":"5"},
                {"x":0,"y":5,"w":2,"h":5,"i":"6"},
                {"x":2,"y":5,"w":2,"h":5,"i":"7"},
                {"x":4,"y":5,"w":2,"h":5,"i":"8"},
                {"x":6,"y":3,"w":2,"h":4,"i":"9"},
                {"x":8,"y":4,"w":2,"h":4,"i":"10"},
                {"x":10,"y":4,"w":2,"h":4,"i":"11"},
                {"x":0,"y":10,"w":2,"h":5,"i":"12"},
                {"x":2,"y":10,"w":2,"h":5,"i":"13"},
                {"x":4,"y":8,"w":2,"h":4,"i":"14"},
                {"x":6,"y":8,"w":2,"h":4,"i":"15"},
                {"x":8,"y":10,"w":2,"h":5,"i":"16"},
                {"x":10,"y":4,"w":2,"h":2,"i":"17"},
                {"x":0,"y":9,"w":2,"h":3,"i":"18"},
                {"x":2,"y":6,"w":2,"h":2,"i":"19"}
            ]
        }
    },
    methods: {
        ucFirst (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        layoutReadyEvent(){
            this.bl_ready = true
            this.elements.forEach((item, index)=>{
                if(item.type == 'chart'){
                    let chartDom = this.isPanel?
                        document.getElementById(`chart-${item.i}`):
                        document.getElementById(`canvas_chart-${item.i}`)
                    if(chartDom){
                        echarts.dispose(chartDom)
                        let chart = echarts.init(chartDom)
                        chart.resize(500, 200)
                        item.chart.option && chart.setOption(item.chart.option);
                    }
                }
            })
        },
        resizeEvent(i, newH, newW, newHPx, newWPx){
            // console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx)
            var element = this.elements.find(item=>item.i == i)
            var chartId = element.chart._id
            let chartDom = document.getElementById(`chart-${element.i}`)
            if(chartDom){
                let chart = echarts.getInstanceByDom(chartDom)
                chart.resize(newWPx, newHPx)
            }
        },
        resizedEvent: function(i, newH, newW, newHPx, newWPx){
            var element = this.elements.find(item=>item.i == i)
            var chartId = element.chart._id
            // console.log("RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx)
            let chartDom = document.getElementById(`chart-${element.i}`)
            if(chartDom){
                let chart = echarts.getInstanceByDom(chartDom)
                chart.resize(newWPx, newHPx)
            }
        },
        cloneItem(item){
            this.$emit('update_elements',{type: 'copy', val:JSON.parse(JSON.stringify(item))})
        },
        deleteItem(item){
            this.$emit('update_elements',{type: 'delete', i: item.i})
        }
    }
}
</script>

<style>
.vue-grid-item.vue-grid-placeholder {
    background: rgba(100, 195, 224, 0.667) !important;
}
.vue-grid-layout {
    background: #eee;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
</style>
