<template>
  <v-app-bar dark  class="w-100" clipped-left :color="(atAppPage || inAppView || inRecordPage || inChartPage) ? currentApp.color : '#2B425E'" >
    <div class="d-flex align-center justify-space-between w-100">
      <div id="hamburger-wrapper" class="text-h5">
        
        <!--    APP PAGE
                ** In app page
         -->
        <v-icon v-if="inRecordPage || inChartPage" @click="closeRecord()">mdi-close</v-icon>
        <v-icon v-if="inAppView" @click="closeFormView()">mdi-chevron-left</v-icon> 
         <!-- APP PAGE -->
        <!--    GENERAL
                ** Guest, not in app page
         -->
        <v-icon v-if="!inRecordPage && !inAppView && !inChartPage" @click="$store.dispatch('TOGGLE_NAVIGATION_DRAWER')">$vuetify.icons.menu</v-icon>
        <!-- GENERAL -->
      </div>
      <div id="caption-wrapper">
        <div class="d-flex align-center app-header-caption">
          <v-icon v-if="siteTitle.icon">{{ siteTitle.icon }}</v-icon> <div class="d-inline-block text-truncate">{{ siteTitle.title }}</div>
        </div>
      </div>
      <div id="action-wrapper">
        <!--  ALREADY SIGN IN -->
        <v-icon v-if="authUser && (!inRecordPage && !inFormPage && !inChartPage )" @click="TOGGLE_USER_DRAWER">$vuetify.icons.userProfile</v-icon>
        <!-- ALREADY SIGN IN -->

        <!-- ALREADY SIGN IN AND IN RECORD PAGE -->
        <v-icon  v-if="inRecordPage && recordMode === 'view'" @click="TOGGLE_RECORD_ACTION_DRAWER">$vuetify.icons.dotHorizontal</v-icon>
        <v-icon v-if="inFormPage" @click="openChartView">$vuetify.icons.barChart</v-icon>
        <!-- ALREADY SIGN IN AND IN RECORD PAGE -->

        <!--  NOT YET SIGN IN -->
        <v-icon v-if="!authUser" @click="userLogin()">$vuetify.icons.userProfile</v-icon>
        <!-- NOT YET SIGN IN -->

      </div>
    </div>
  </v-app-bar>
</template>

<script>
import EventBus from "@/event-bus.js";
export default {
  name: 'mAppHeader',
  computed: {
    authUser() {
      return this.$store.getters.user;
    },
    currentApp() {
      let app = {
        color: '#2B425E',
      };
      if (this.$store.getters.currentApp) {
        app = {...app, ...this.$store.getters.currentApp}
      }
      return app;
    },
    currentForm() {
      return this.$store.getters.currentForm;
    },
    currentView() {
      let result = undefined;
      const { viewId } = this.$route.params;
      if ( viewId ) result = viewId;
      else {
        result = this.currentForm.views[0]._id;
      }
      return result;
    },
    currentFormIcon() {
      return this.$store.getters.currentFormIcon;
    }, 
    inRecordPage() {
      const routerName = ['records'];
      return routerName.includes(this.$route.name);
    }, 
    inFormPage() {
      const routerName = ['forms'];
      return routerName.includes(this.$route.name);
    }, 
    inChartPage() {
      const routerName = ['charts'];
      return routerName.includes(this.$route.name);
    }, 
    inAppView() {
      const routerName = [ 'panels','backdrop','mapPanel','calendar','inputPanels','publicFormPanels','forms','reports' ];
      return routerName.includes( this.$route.name ); 
    },
    atAppPage() {
      const routerName = [ 'apps', 'appsModules' ];
      return routerName.includes( this.$route.name ); 
    }, 
    siteTitle() {
      let icon = '', title= '';
      switch ( this.$route.name ) {
        case 'myApps':
          icon = 'mdi-home';
          title = this.$t('general.workbench');
          break;
        case 'archivedFolder':
        case 'archivedFolderApps':
          icon = 'mdi-folder';
          title = this.$t('general.archivedFolder');
          break;
        case 'apps': 
          icon = this.currentApp.logoIcon;
          title = this.currentApp.title;
          break; 
        case 'panels':
        case 'backdrop':
        case 'mapPanel':
        case 'calendar':
        case 'inputPanels':
        case 'publicFormPanels':
        case 'forms':
        case 'reports':
          icon = this.currentFormIcon;
          title = ( this.currentForm && 'label' in this.currentForm ) ? this.currentForm.label : '';
          break;
        case 'records':
          icon = undefined;
          title = this.$store.getters.formTitledValue;
          break;
        case 'charts':
          icon = undefined;
          title = this.$t('chart.chart')          
          break;
      }

      if ( this.atAppPage ) {
         icon = this.currentApp.logoIcon;
          title = this.currentApp.title;
      }
      return {
        icon, title
      }
    },
    recordMode() {
      return this.$store.getters.recordPageMode;
    }
  },
  async mounted() {
    const currentAppId = this.$route.params.id;
    if ( !this.currentApp && currentAppId) {
      await this.$store.dispatch('FETCH_APP', { appId: this.$route.params.id } );
    }
  }, 
  methods: {
    userLogin() {
      const vm = this;
      EventBus.$emit("showSpinner");
      const returnUrl =
        window.location.protocol + "//" + window.location.host + "/dashboard";
      const url = `${vm.$store.getters.authUrl}/login?returnUrl=${returnUrl}`;
      window.location = url;
    },
    TOGGLE_USER_DRAWER() {
      this.$store.dispatch("TOGGLE_USER_DRAWER");
    },
    TOGGLE_RECORD_ACTION_DRAWER() {
      this.$store.dispatch("TOGGLE_RECORD_ACTION_DRAWER");
    }, 
    closeFormView() {
      const url = `/apps/${this.currentApp._id}/modules`
      this.$router.push(url)
    },
    closeRecord() {
      let url = '';
      switch( this.recordMode ) {
        case 'edit':
          this.$store.dispatch('SET_RECORD_PAGE_MODE', 'view');
          break;
        default:
          url = `/apps/${this.currentApp._id}/forms/${this.currentForm._id}/${this.currentView}`;
          this.$router.push(url);
      }
    },
    openChartView() {
      const params = { id: this.currentApp._id, itemId: this.currentForm._id, viewId: this.currentView };
      this.$router.push({ name: 'charts', params: params } )
    }
  },
};
</script>

<style lang="scss">

#caption-wrapper {
  max-width: 50vw
}
</style>