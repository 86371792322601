<template>
  <div class="pt-1">
    <div class="pl-1" v-for="(actionTimeItem, index) in actionTimeItems"
      :key="index">
      <action-record-item
        @onCommand="onCommandHandler"
        :index="index"
        :item="actionTimeItem"></action-record-item>
    </div>
  </div>
</template>

<script>
import actionRecordItem from './ActionRecordItem'

export default {
  name: 'inputPanelTimeList',
  components: {    
    actionRecordItem
  },
  props: {
    rowId: String,
    targetRecords: Array,
    inputPanel: Object,
    masterForm: Object,
    targetForm: Object
  },
  computed: {  
    //
    // actionTimeItems = [
    //    {
    //        _id: '..',
    //        createdAt: '..',
    //        label: '...',
    //        value: '...'
    //    },
    //    {
    //        ...
    //     }
    // ]
    actionTimeItems () {
      const vm = this
      var result = []
      if (vm.targetForm && vm.targetRecords) {
        const label = vm.getTargetFormFieldLabel(vm.inputPanel.targetFieldId)
        console.log('actionTimeItems :: label = ' + label)
        for (let i = 0; i < vm.targetRecords.length; i++) {
          const targetRecord = vm.targetRecords[i]
          const valueSegments = targetRecord[vm.inputPanel.targetFieldId].split(' ')
          result.push({
            _id: targetRecord._id,
            createdAt: targetRecord.createdAt,
            label: label,
            value: valueSegments.pop()
          })
        }
      }
      return result
    }  
  },
  methods: {
    getTargetFormFieldLabel (fieldId) {
      const vm = this
      var result = ''
      const fieldInfo = vm.targetForm.fieldInfos.find( info => info.fieldId === fieldId)
      if (fieldInfo) {
        result = fieldInfo.label
      }
      return result
    },
    
    onCommandHandler (payload) {
      const vm = this
      console.log('InputPanelTimeList.onCommandHandler :: payload: ', payload)
      switch (payload.command) {
        case 'deleteAction':
          vm.deleteTimeAction(payload._id)
          break
        case 'selectAction':
          vm.$emit('onClick', payload.index)
          break
      }
    },

    deleteTimeAction (targetRecordId) {
      const vm = this
      console.log('InputPanelTimeList.deleteTimeAction targetRecordId = ' + targetRecordId)
      vm.$emit('onCommand', {
        command: 'deleteTimeItem',
        masterRecordId: vm.rowId,
        targetRecordId: targetRecordId,
        targetFieldId: vm.inputPanel.targetFieldId
      })
    },
  }
}
</script>