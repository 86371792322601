<template>
    <div>
        <v-row no-gutters>
    <quill-editor class="editor"  v-model="data" :options="options">
    </quill-editor>
        </v-row>
        <v-row no-gutters class="mt-2">
            <v-spacer />
            <v-btn depressed class="mx-2" @click="showEditor=false">{{$t('buttons.cancel')}}</v-btn>
            <v-btn @click="confirm" color="primary">{{$t('buttons.confirm')}}</v-btn>
        </v-row>
    </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
export default {
    components:{
        quillEditor
    },
    props: {
        textValue: String,
        value:Boolean
    },
    data(){
        return {
            data: "",
            options: {
                placeholder: this.$t('messages.insertText')
            }
        }
    },
    computed: {
        showEditor:{
            set(val){
                this.$emit('input', val)
            },
            get(){
                return this.value
            }
        }
    },
    mounted(){
        this.data = this.textValue
    },
    methods: {
        confirm(){
            this.$emit('change', this.data);
            this.showEditor = false
        }
    }
}
</script>
<style>
.editor .ql-container {
    height: 280px;
}
</style>