<template>
  <div class="icon-selection d-flex flex-column align-center">
    <!-- prepend-inner-icon="mdi-magnify" -->
    <!-- :append-icon="searchText ? 'mdi-magnify' : ''" -->
    <v-text-field
      :placeholder="$t('messages.inputTextToSearch')"
      :hide-details="true"
      dense
      outlined
      v-model="searchText"
      clearable
      @click:clear="clearSearch"
      @keydown.enter="searchIcons"
      @click:append="searchIcons"
      :append-icon="searchText ? 'mdi-magnify' : ''"
      class="mr-auto mb-3 iconSearchTextField"
      style="font-size:12px"
      height="25"
    >
    </v-text-field>

    <div class="flex-grow-1 d-flex flex-column justify-start">
      <div style="overflow:hidden;vertical-align:top;">
        <div class="icon-wrapper pa-1 d-inline-block"
             :class="{'selected':inputIcon===icon}"
             :key="index"
             v-for="(icon,index) in icons"
             @click="selectIcon(icon)">
          <v-icon>{{ icon }}</v-icon>
        </div>
      </div>
    </div>

    <div class="text-center grey white--text align-self-stretch">
      <small color="gray lighten-1">{{$t('general.page')}} {{ iconPagination.page }} / {{ iconPagination.totalPages }}</small>
    </div>

    <div class="d-flex flex-row justify-space-between">
      <div class="btn-group mx-auto" style="width:100%;">
        <v-btn text @click="gotoPage(1)" :disabled="iconPagination.page===1">
          <v-icon>mdi-skip-previous</v-icon>
        </v-btn>
        <v-btn text @click="gotoPage(iconPagination.page - 5)" :disabled="iconPagination.page===1">
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-btn text @click="gotoPage(iconPagination.page - 1)" :disabled="iconPagination.page===1">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn text @click="gotoPage(iconPagination.page + 1)" :disabled="iconPagination.page===iconPagination.totalPages">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn text @click="gotoPage(iconPagination.page + 5)" :disabled="iconPagination.page===iconPagination.totalPages">
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
        <v-btn text @click="gotoPage(iconPagination.totalPages)" :disabled="iconPagination.page===iconPagination.totalPages">
          <v-icon>mdi-skip-next</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showingDialog: false,
      inputIcon: '',

      defaultIconIndex: 0,
      iconPagination: {
          page: 1,
          limit: 240,
          totalDocs: 0,
          totalPages: 0,
          pagingCounter: 0
      },
      icons: [],
      searchText: ''
    }
  },
  model: {
    prop: 'appIcon',
    event: 'input'
  },

  watch: {

    appIcon: function (newValue) {
      this.inputIcon = newValue
    }
  },
  mounted () {
    const vm = this
    vm.inputIcon = vm.appIcon
    vm.loadIcons()
  },
  props: {
    appIcon: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    gotoPage (pageNo) {
      const vm = this
      if (pageNo < 1) {
        pageNo = 1
      }
      if (pageNo > vm.iconPagination.totalPages) {
        pageNo = vm.iconPagination.totalPages
      }
      vm.iconPagination.page = pageNo
      vm.loadIcons()
    },
    loadIcons () {
      const vm = this
      const params = {
        page: vm.iconPagination.page,
        limit: vm.iconPagination.limit
      }

      if (this.searchText) Object.assign(params, {search: this.searchText})
      const getParams = {
        urlCommand: '/mdi_icons',
        options: {
          params
        }
      }
      vm.$store.dispatch('AUTH_GET', getParams).then(
        response => {
          console.log('AppsInfoDialog :: loadIcons :: response: ', response)
          vm.icons = response.docs.map(item => item.name)
          if (vm.icons.length > 0 && vm.inputIcon === '') {
            if (vm.defaultIconIndex < vm.icons.length) {
              vm.inputIcon = vm.icons[vm.defaultIconIndex]
            } else {
              vm.inputIcon = vm.icons[0]
            }
          }
          vm.iconPagination.totalDocs = response.totalDocs
          vm.iconPagination.totalPages = response.totalPages
          vm.iconPagination.pagingCounter = response.pagingCounter

        }
      )
    },
    selectIcon (icon) {
      const vm = this
      vm.$emit('input', icon)
      // vm.inputIcon = icon
    },
    searchIcons(){
      this.iconPagination.page = 1
      this.loadIcons()
    },
    clearSearch(){
      this.$nextTick().then(() => {
        this.searchText = "";
        this.searchIcons()
      });
    }

  }
}
</script>

<style>

.icon-selection .icon-wrapper {
  cursor: pointer;
}

.icon-selection .icon-wrapper:hover {
  background-color: lightgray;
}

.icon-selection .icon-wrapper.selected {
  background-color: lightskyblue;
}

.icon-selection .icon-wrapper.selected:hover {
  background-color: dodgerblue;
}
/* .iconSearchTextField .v-input__slot .v-input__append-inner{
  margin-top: 0px !important;
} */
.iconSearchTextField .v-input__slot  .v-input__append-inner{
  margin-top: 0px !important;
}

.iconSearchTextField .v-input__icon button{
  font-size : 18px !important;
  margin: 0px !important;

}
</style>
