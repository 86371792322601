<template>

  <vxe-table-column :class-name="tdClass + ' fullCell'" v-bind="colDef" :edit-render="{name: 'EditRichTextModal', props: { readonly, updateTdClass }, enabled: !readonly}" sortable>
    <template v-slot="{row}">
      <span>{{extractContent(row[fieldId])}}</span>
      <quick-action
        :rowId="row['_id']"
        :fieldId="fieldId"></quick-action>
    </template>
  
    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import EditRichTextModal from './comps/EditRichTextModal.vue'
import VXETable from 'vxe-table'
import Vue from 'vue'
import quickAction from './comps/QuickAction'
import summaryCell from './comps/SummaryCell'

Vue.component(EditRichTextModal.name, EditRichTextModal)
VXETable.renderer.add('EditRichTextModal', {
  renderEdit (h, renderOpts, params) {
    console.log(renderOpts)
    return [
      <edit-rich-text-modal params={ params } readonly={ renderOpts.props.readonly } v-on:updateTdClass={ renderOpts.props.updateTdClass }></edit-rich-text-modal>
    ]
  },
})
export default {
  name: 'vxeCellRichText',
  mixins: [mixin],
  components: {
    quickAction,
    summaryCell
  },
  data(){
    return{
      tdClass: ''
    }
  },
  methods:{
    extractContent(s) {
      if (!s) return ""
      var span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
  }
}
</script>

<style>
  .richTextModal.col--actived{
    z-index: 4 !important;
  }
  .edit-rich-text-modal .v-input{
    border: 1px #dcdfe6 solid !important;
  }
</style>
