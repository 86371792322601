export const TOGGLE_MODULE_MENU = 'TOGGLE_MODULE_MENU'
export const PICK_MODULE = 'PICK_MODULE'
export const PICK_EVENTTYPE = 'PICK_EVENTTYPE'
export const SET_MODULE_MENU_STATUS = 'SET_MODULE_MENU_STATUS'
export const PICKING_MODULE = 'PICKING_MODULE';
export const EDITING_NODE = 'EDITING_NODE';
export const PREVIEW_ORCHESTRATION = 'PREVIEW_ORCHESTRATION';
export const OFF_PREVIEW_ORCHESTRATION = 'OFF_PREVIEW_ORCHESTRATION';
export const COPYING_NODE = 'COPYING_NODE';
export const ADD_COPIED_NODE = 'ADD_COPIED_NODE';
// export const GET_COPIED_NODE = 'GET_COPIED_NODE';
export const REMOVE_COPIED_NODE = 'REMOVE_COPIED_NODE';
export const UPDATE_WORKFLOW_STATUS = 'UPDATE_WORKFLOW_STATUS';

export const RESET_SCALE_WORKFLOW_LAYOUT = 'RESET_SCALE_WORKFLOW_LAYOUT';
export const SCALE_WORKFLOW_LAYOUT = 'SCALE_WORKFLOW_LAYOUT';

export const FETCH_MODULES_LIST = 'FETCH_MODULES_LIST';
// export const FETCH_MODULES_LIST_SCUEED = 'FETCH_MODULES_LIST_SCUEED';
// export const FETCH_MODULES_LIST_FAILED = 'FETCH_MODULES_LIST_FAILED';

export const FETCH_WORKFLOW_LIST = 'FETCH_WORKFLOW_LIST';
export const FETCH_WORKFLOW_STATISTIC = 'FETCH_WORKFLOW_STATISTIC';
export const FETCH_WORKFLOW_STATISTIC_TABLE = 'FETCH_WORKFLOW_STATISTIC_TABLE';
export const REMOVE_WORKFLOW = 'REMOVE_WORKFLOW';
export const DUPLICATE_WORKFLOW = 'DUPLICATE_WORKFLOW';
export const CREATE_WORKFLOW = 'CREATE_WORKFLOW';
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';
export const FETCH_WORKFLOW_INFO = 'FETCH_WORKFLOW_INFO';
export const FETCH_WORKFLOW_TREE = 'FETCH_WORKFLOW_TREE';
export const RESET_EDITOR = 'RESET_EDITOR';

// node related
export const ADD_WORKFLOW_NODE = 'ADD_WORKFLOW_NODE';
export const REMOVE_WORKFLOW_NODE = 'REMOVE_WORKFLOW_NODE';
export const COPY_WORKFLOW_NODE = 'COPY_WORKFLOW_NODE';
export const UPDATE_WORKFLOW_NODE = 'UPDATE_WORKFLOW_NODE';
export const UPDATE_WORKFLOW_EDITING_NODE = 'UPDATE_WORKFLOW_EDITING_NODE';
export const UPDATE_WORKFLOW_TREE_SUBWORKFLOW = 'UPDATE_WORKFLOW_TREE_SUBWORKFLOW';
export const FETCH_WORKFLOW_NODE_INFO = 'FETCH_WORKFLOW_NODE_INFO';

// histories related
export const FETCH_WORKFLOW_RELEASE_HISTORY_LIST = 'FETCH_WORKFLOW_RELEASE_HISTORY_LIST';
export const FETCH_WORKFLOW_RELEASE_HISTORY_INFO = 'FETCH_WORKFLOW_RELEASE_HISTORY_INFO';

// form related
export const FORM_SELECTIONS = 'FORM_SELECTIONS';
export const FORM_SELECTIONS_FIELDS = 'FORM_SELECTIONS_FIELDS';
export const FORM_RELATIONSHIP_SELECTIONS_FIELDS = 'FORM_RELATIONSHIP_SELECTIONS_FIELDS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_PROPERTIES_SETTINGS = 'SET_PROPERTIES_SETTINGS';
export const FORM_RELATIONSHIP_FIELDS = 'FORM_RELATIONSHIP_FIELDS';

export const REPLACE_WORKFLOW_DEPENDENCIES = 'REPLACE_WORKFLOW_DEPENDENCIES';
export const FETCH_WORKFLOW_DEPENDENCIES = 'FETCH_WORKFLOW_DEPENDENCIES';
export const FETCH_SUBWORKFLOW = 'FETCH_SUBWORKFLOW';
export const FETCH_PARAMETERFIELDS = 'FETCH_PARAMETERFIELDS';

export const OFF_SHORTVIEW_ORCHESTRATION = 'OFF_SHORTVIEW_ORCHESTRATION';
export const SHORTVIEW_ORCHESTRATION = 'SHORTVIEW_ORCHESTRATION';

export const LOG_VALIDATE_WORKFLOW = 'LOG_VALIDATE_WORKFLOW'
export const VALIDATE_WORKFLOW = 'VALIDATE_WORKFLOW'
export const VALIDATION_UPDATE_EDIT_NODE = 'VALIDATION_UPDATE_EDIT_NODE';
export const RESET_WORKFLOW_VALIDATE = 'RESET_WORKFLOW_VALIDATE'

// socket
export const SOCKET_WALKTHROUGH = 'SOCKET_WALKTHROUGH';
export const SOCKET_WALKOUT = 'SOCKET_WALKOUT';

// parameter
export const FETCH_WORKFLOW_PARAMETER = 'FETCH_WORKFLOW_PARAMETER';
export const ADD_WORKFLOW_PARAMETER = 'ADD_WORKFLOW_PARAMETER';
export const REMOVE_WORKFLOW_PARAMETER = 'REMOVE_WORKFLOW_PARAMETER';

