<template>
<v-row dense>
    <v-col cols="4" class="d-flex flex-row align-center">
      <label>{{ $t(labelTag) }}</label>
    </v-col>
    <v-col :cols="cols" class="d-flex flex-row align-center">
      <v-text-field
      :value="value"
 
      @change="val=>updateValue(val)"
          v-model="value"
          dense
          solo
          :hideDetails="true"
          outlined
          :type="fieldType"
          small
          :disabled="disabled"
          ref="settingsInput"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        fieldType: {
            type: String,
            default: 'String'
        },
        labelTag: String,
        value: Number,
        cols: String
    },
    methods: {
        updateValue (val) {
            this.$emit('input', val)
        }
    }
}
</script>
