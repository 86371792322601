<template>
  <v-layout>
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-layout v-bind="attrs"
          v-on="on">
          <NormalButton :buttonProps="buttonProps" :button="button" :click="() => {}" />
        </v-layout>
      </template>
      <v-card>
        <v-card-title class="text-h5 workflow-customized-action-alert-title">
          <v-icon
            v-if="button.icon"
            :color="button.color"
            left
            size="25"
            dark
          >
            {{`mdi-${button.icon}`}}
          </v-icon>
          {{button.name}}
        </v-card-title>
        <v-card-text class="workflow-customized-action-alert-text" v-html="popupInfo.title" />
        <v-card-actions class="workflow-customized-action-alert-action">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            {{popupInfo.cancelLabel}}
          </v-btn>
          <v-btn
            :color="button.color"
            class="workflow-customized-action-alert-submit"
            @click="submit"
          >
            {{popupInfo.confirmLabel}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import NormalButton from './normal';
import Mixin from './mixin';
export default {
  mixins: [Mixin],
  props: {
    selected: {
      type: Array,
      default: [],
    },
    button: {
      type: Object,
      default: {},
    },
    buttonProps: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  components: {
    NormalButton,
  },
  methods: {
    onClick() {
      this.dialog = true;
    },
    submit() {
      this.dialog = false;
      this.onSubmit(this.selected);
      this.$emit('onSelected');
    }
  },
  computed: {
    popupInfo() {
      const defaultPopup = {
        title: this.$t('workflow.label.popupCustomimzedAction.title', { 
          label: this.button.name,
        }),
        confirmLabel: this.$t('workflow.label.popupCustomimzedAction.confirmLabel'),
        cancelLabel: this.$t('workflow.label.popupCustomimzedAction.cancelLabel'),
      }
      const { popup={} } = this.button;
      const lookups = ['title', 'confirmLabel', 'cancelLabel'];
      lookups.forEach(target => {
        if (!popup[target]) {
          popup[target] = defaultPopup[target];
        }
      });
      return popup;
    },
  }
}
</script>

<style scoped>
</style>