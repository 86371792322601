<template>
  <div class="workflow-node-box-validation-log" :class="{ 'workflow-node-box-validation-log-dark': darkMode }">
    <div class="workflow-node-box-validation-log-header">
      {{ messageLabel }}
    </div>
    <ol class="workflow-node-box-validation-log-reason">
      <li  v-for="(error, index) in list" :key="index">{{ errorReason(error) }}</li>
    </ol>
  </div>
</template>

<script>
import Mixin from './../../setting/mixin';
import NodeMixin from './../../../../mixin';
import WMixin from "@/pages/admin/workflow/mixin";
// mixins
export default {
  mixins: [Mixin, NodeMixin, WMixin],
  props: {
    list: {
      type: Array,
      default: [],
    },
    darkMode: {
      type: Boolean,
      defualt: false,
    },
    messageLabel: String,
  },
};

</script>

<style scoped>

</style>
