import layoutWidgetAmount from './LayoutWidgetAmount.vue'
import layoutWidgetEmail from './LayoutWidgetEmail.vue'
import layoutWidgetNumber from './LayoutWidgetNumber.vue'
import layoutWidgetText from './LayoutWidgetText.vue'
import layoutWidgetSingleSelection from './LayoutWidgetSingleSelection.vue'

export default {
  layoutWidgetAmount,
  layoutWidgetEmail,
  layoutWidgetNumber,
  layoutWidgetText,
  layoutWidgetSingleSelection
}
