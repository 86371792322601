import * as types from './action_types.js'

const state = {
  categories: [
    {id: 1, title: '必備', templates: [1,2,7,18]},
    {id: 2, title: '銷售', templates: [1,3,19,20]},
    {id: 3, title: '營銷', templates: [4,5,21,22]},
    {id: 4, title: '人事', templates: [6,7,23,24]},
    {id: 5, title: '財務', templates: [8,9,25,26]},
    {id: 6, title: '產品研發', templates: [10,11,27]},
    {id: 7, title: '客服', templates: [12,13,28]},
    {id: 8, title: '運營制造', templates: [2,14,29,30]},
    {id: 9, title: '項目管理', templates: [15,10,31,32]},
    {id: 10, title: '採購管理', templates: [16,17]}
  ],
  templates: [
    {
      id: 1,
      title: 'Salesforce克隆',
      subTitle: '完美复刻Salesforce的解决方案',
      description: 'Salesforce Sales Cloud是世界上最强大的CRM软件之一，明道云作为一个零代码应用搭建工具，为了证明自己的产品能力，完美复刻了Salesforce的产品架构，搭建了一个CRM管理系统，供大家学习和使用。\n' +
      '\n' +
      '此应用主要的功能是让您管理销售线索到发票的整个周期。这套经过了无数专业用户验证的CRM架构，让您在销售领域管理不会走弯路。',
      banner: 'salesforce.jpg'
    },
    {
      id: 2,
      title: 'ERP克隆',
      subTitle: '企业的标准进销存ERP解决方案',
      description: '进销存ERP是每个传统企业都用得上的工具，明道云作为一个零代码应用搭建工具，为了便于用户的使用体验，参考市面上一些现有ERP的原型，搭建了一个进销存ERP管理系统，供大家学习和使用。\n' +
      '\n' +
      '此应用功能主要包含库存、采购、销售等三大模块，以及他们所产生的账务活动，加强企业管理因素，更有根据不同角色制作的工作台，提高个人工作效率。',
      banner: 'erp_clone.png'
    },
    {
      id: 3,
      title: '简明客户管理',
      subTitle: '最简单，最实用的CRM',
      description: '对于销售流程简单，且客户数量不多的企业来说，可以紧紧抓住客户联系人信息记录，以及配套的销售行动记录。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E7%AE%80%E6%98%8E%E5%AE%A2%E6%88%B7%E7%AE%A1%E7%90%86,',
      banner: 'simple_crm.png'
    },
    {
      id: 4,
      title: '內容菅銷',
      subTitle: '内容营销是效果最持久的营销方式',
      description: '内容营销越来越受到企业的重视，原因之一是内容营销可以帮助教育客户、带来极高的转化率。据研究，内容营销比传统营销节省了62%的费用，并带来3倍以上的效果。\n' +
      '\n' +
      '制作一篇精品内容不然，难的是可以批量化、持续性生产精品内容，甚至要让一个市场新人也能掌握创作方法。想要达到这个目标，除了掌握内容营销的原理，你还可以通过这个模版规划内容生产计划。\n' +
      '\n' +
      '在模版中，你可以设定一个创意，然后创作相关内容，最后选择渠道发布，让整个内容生产流水化。',
      banner: 'cms_marketing.png'
    },
    {
      id: 5,
      title: '媒體關系管理',
      subTitle: '管理好媒体关系，就管理好了品牌资产',
      description: '通过管理媒体、作者，可以帮助企业与公众建立关系，建立所想要的形象。这个模版可以帮助你管理与企业相关的媒体和作者，通过追踪作者的文章来分析和定制想要的PR战略。\n' +
      '\n' +
      '在PR关系管理中，我们将要管理的信息分为：媒体、作者和相关文章。这三者的关系是：一个媒体机构包含多个作者，一个作者包含多篇文章。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E5%AA%92%E4%BD%93%E5%85%B3%E7%B3%BB%E7%AE%A1%E7%90%86',
      banner: 'media_marketing.png'
    },
    {
      id: 6,
      title: '績效考核',
      subTitle: '让每轮的绩效考核不再痛苦',
      description: '协助企业持续提升个人、部门和组织人绩效管理工具\n' +
      '灵活的绩效考核工具\n' +
      '通过专用的考核模版创建每位员工某周期内的考核内容，能够留存上一次考核内容，下一周期创建时只要在此基础上修改即可，有效提升考核指标创建效率；\n' +
      '员工考核情况，可以通过员工表分人员查看，也可通过考核周期表分周期查看；\n' +
      '被考核人评分后，会通过自动工作流发送评分通知给到考核人，实现整个考核过程的自动化；\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8',
      banner: 'appraisal.png'
    },
    {
      id: 7,
      title: '招聘管理',
      subTitle: '更少的时间，更适合的候选人',
      description: '为企业增长助力\n' +
      '\n' +
      '在企业发展中，招聘是不可或缺且尤为重要的一环，但常常因为数据繁多且不对称导致效率低下。\n' +
      '\n' +
      '通过管理岗位、候选人、面试记录简单的几个步骤就可以很好地帮助企业摆脱信息不对称，重复劳动，优化招聘流程，提升团队率。\n' +
      '\n' +
      '通过此模板，你可以随时查看不同节点候选人的情况，多维度报表全面分析，让招聘工作透明与规范化。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E6%8B%9B%E8%81%98%E7%AE%A1%E7%90%86',
      banner: 'recruitment.png'
    },
    {
      id: 8,
      title: '固定資產管理',
      subTitle: '帮助企业摆脱资产管理无序的状态',
      description: '在这个模版中，只需添加各项资产的相关信息，登记好每一条处置记录，就能实现资产入库、领用、归还、维修、报废等操作，让资产管理更系统！提供多个维度的数据统计，帮助企业随时了解资产分布，让资产统计更简单！\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E5%9B%BA%E5%AE%9A%E8%B5%84%E4%BA%A7%E7%AE%A1%E7%90%86',
      banner: 'asset.png'
    },
    {
      id: 9,
      title: '應收賬款管理',
      subTitle: '您身边最贴心的收款小帮手',
      description: '从客户、产品类别、金额、日期四大要素，对记账进行统计管理。\n' +
      '通过这个模版，简单的几个选项即可完成客户记账收款。自定义筛选收款状态，生成自己需要的视图报表。帮助用户更好的管理账目。\n' +
      '增加到期提醒，再也不用担心会错漏重要节点。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E5%BA%94%E6%94%B6%E8%B4%A6%E6%AC%BE%E7%AE%A1%E7%90%86',
      banner: 'receivable.png'
    },
    {
      id: 10,
      title: '研發進度跟踪',
      subTitle: '把握研发进度的每一步',
      description: '研发项目是企业技术创新过程中重要的一环，研发进度的把握同样也是研发中重要的一步。本应用可用于记录企业的研发项目，通过项目分配一级任务，进而将一级任务分解为二级任务。同时在整个研发过程中可以选择“已完成”、“进行中”、“未开始”和“逾期”作为完成情况，新建“日报”、“周报”、“变更报告”等类型的报告来记录研发的进度，详细且灵活。\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E7%A0%94%E5%8F%91%E8%BF%9B%E5%BA%A6%E8%B7%9F%E8%B8%AA',
      banner: 'r_and_d.png'
    },
    {
      id: 11,
      title: '缺陷管理',
      subTitle: '缺陷——快速汇报，快速解决！',
      description: '一款轻量的缺陷管理系统，为您收集内部和用户的产品缺陷反馈，帮研发团队快速高效的委托，跟进，解决缺陷。\n' +
      '在缺陷管理系统中，通过一张表收集记录缺陷反馈，通过不同的视图，向成员展现缺陷的进展情况\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E7%BC%BA%E9%99%B7%E7%AE%A1%E7%90%86',
      banner: 'fault.png'
    },
    {
      id: 12,
      title: '退換貨管理',
      subTitle: '助您轻松管理退换货订单',
      description: '从退换货申请开始，一条龙管理您的退货进度流程。\n' +
      '如您对此模板有任何意见，欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E9%80%80%E6%8D%A2%E8%B4%A7%E7%AE%A1%E7%90%86',
      banner: 'goods_return.png'
    },
    {
      id: 13,
      title: '客服工單',
      subTitle: '轻松记录客户反馈，高效跟进处理进度',
      description: '轻松记录客户反馈，高效跟进处理进度。\n' +
      '无论是技术支持，还是售后服务都能通过工单记录登记，工作流可以根据问题类型自动流转到指定的员工进行处理，视图帮您快速查看各状态下的工单，统计图表能够很好展示工单整体情况和趋势。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E5%AE%A2%E6%9C%8D%E5%B7%A5%E5%8D%95',
      banner: 'customer_service.png'
    },
    {
      id: 14,
      title: '設備外借',
      subTitle: '一个简洁、高效的设备工具外借管理工具',
      description: '这是一个简易的设备工具外借管理应用，您将能实时追踪和管理各个场地的设备的可用状态，并通过新建和管理与设备关联的外借记录，从而提高设备的管理效率。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E8%AE%BE%E5%A4%87%E5%A4%96%E5%80%9F',
      banner: 'equipment_consignment.png'
    },
    {
      id: 15,
      title: '項目管理',
      subTitle: '做一个高产出的项目经理',
      description: '项目管理千头百绪，从立项到任务分解、资源分配、进度把控，稍有不慎就会耽搁项目进度。如何控制好项目？你可以借助这个模版来实现。\n' +
      '\n' +
      '在这个模版中，不管是任务、收支、工时或活动都被严谨的关联起来。你还可以在此基础上自由拓展想要的功能，比如项目人员。有了它，即使是没有经验的项目管理者也能轻松管理项目。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E9%A1%B9%E7%9B%AE%E7%AE%A1%E7%90%86',
      banner: 'project.png'
    },
    {
      id: 16,
      title: '招投標記錄',
      subTitle: '有记录，更懂投标',
      description: '招标投标作为一种成熟的交易方式，其重要性和优越性得到广泛认可。每次的招标投标过程，无论是对投标方还是对招标方来说，都是十分重要的经历。本应用主要分为招标项目、投标记录、会议记录三部分，旨在将招投标过程进行详尽的记录，以便于回顾。一方面是了解不同招标机构、招标文件要求、资质要求和评标专家的特点，提升投标能力，提高工程质量；另一方面是从优秀竞争对手方学习。\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E6%8B%9B%E6%8A%95%E6%A0%87%E8%AE%B0%E5%BD%95',
      banner: 'ipo.png'
    },
    {
      id: 17,
      title: '採購管理',
      subTitle: '轻松管理采购环节全流程',
      description: '本应用包含采购环节的以下几个功能\n' +
      '1、BOM、SKU、供应商的管理。\n' +
      '2、采购申请审批、物流查询、入库、核减库存、出库、核减库存等流程。\n' +
      '3、最低库存提醒预警。\n' +
      '4、采购业务数据报表。\n' +
      '\n' +
      '应用使用视频，点此观看：https://learn.mingdao.net/course/68\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E9%87%87%E8%B4%AD%E7%94%B3%E8%AF%B7',
      banner: 'customer_service.png'
    },
    {
      id: 18,
      title: 'OKR管理',
      subTitle: '让你的团队聚焦到重要目标上',
      description: 'OKR工作法的实施工具，体现O与KR及任务间的关联关系，记录执行过程，协助您坚持复盘，随时发现问题并调整行动计划（任务）。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=OKR%E7%AE%A1%E7%90%86',
      banner: 'okr.png'
    },
    {
      id: 19,
      title: '訂單管理',
      subTitle: '订单管理',
      description: '了解订单从起草到签约的状态，并据此管理发票和收款流程。 对此模版有任何想法？欢迎留言：\n' +
      'https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86',
      banner: 'project.png'
    },
    {
      id: 20,
      title: '折扣申請',
      subTitle: '助你把握时机，现场签单',
      description: '销售人员随身携带的折扣申请和记录工具，协助你第一时间响应客户的折扣申请需求，抢占先机，大幅提升现场签单率。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E6%8A%98%E6%89%A3%E7%94%B3%E8%AF%B7',
      banner: 'receivable.png'
    },
    {
      id: 21,
      title: '營銷效果追踪',
      subTitle: '让你知道广告费浪费在了哪里',
      description: '随着技术的发展，现在的营销活动可以基于对过去活动的测量来洞察营销表现，得出每个活动的投资回报率。在这个模版中，你可以记录每个营销活动的阅读、转化和点击。通过统计分析，找到未来应该广告投放的最佳渠道及产品组合。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E8%90%A5%E9%94%80%E6%95%88%E6%9E%9C%E8%BF%BD%E8%B8%AA',
      banner: 'marketing_tracking.png'
    },
    {
      id: 22,
      title: '試用預約',
      subTitle: '给客户一个完美的预约体验',
      description: '当客户提交了预约，如何将信息传递给正确部门，并给用户一个好的体验呢？这个模版可以帮助你把必要信息管理起来。利用统计功能，还能总结出客户的特征，帮助优化未来的工作。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E8%AF%95%E7%94%A8%E9%A2%84%E7%BA%A6',
      banner: 'trial_reservation.png'
    },
    {
      id: 23,
      title: '員工檔案',
      subTitle: '每位员工都有一条数字化记录',
      description: '告别打字录入时代，实现员工信息无纸化管理\n' +
      '通过该模版，您可以发送给任何一位员工邀请填写员工信息，或是Excel一键批量导入已有数据，大大省去HR信息更新的时间。\n' +
      '视图、统计功能快速准确筛选出你想要的信息。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E5%91%98%E5%B7%A5%E6%A1%A3%E6%A1%88',
      banner: 'employee_record.png'
    },
    {
      id: 24,
      title: 'OA審批',
      subTitle: '简单的OA审批应用',
      description: '这是一个OA审批应用，其中包含财务类、人事类、行政类等不同审批，如请假单、报销单、采购申请等，确保每一份文件、每一份申请审批都传递到相关的人。\n' +
      '财务类：开票申请、费用报销、预支申请、付款申请；\n' +
      '人事类：请假单、加班加、调休单、离职申请；\n' +
      '行政类：合同审批、采购申请、出差申请；\n' +
      '其他：印名片申请、物品领用申请。\n'+
      '如果对此应用有所问题，请反馈：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=OA%E5%AE%A1%E6%89%B9',
      banner: 'oa_approval.png'
    },
    {
      id: 25,
      title: '簡單收支流水',
      subTitle: '随时随地快速记账',
      description: '简单易用，不需要专业财会知识，也不用计算机办公知识技巧，按实际需要设置好账务类别，在已有账务收支记录需求时添加记录即可。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E7%AE%80%E5%8D%95%E6%94%B6%E6%94%AF%E6%B5%81%E6%B0%B4',
      banner: 'simple_transaction.png'
    },
    {
      id: 26,
      title: '供應商管理',
      subTitle: '供应商的管理清单',
      description: '轻松管理您的供应商信息，从联系人信息，到付款信息，一应俱全\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%B0%E5%BD%95',
      banner: 'supplier.png'
    },
    {
      id: 27,
      title: '需求管理',
      subTitle: '用简明的需求管理来逐步兑现客户需',
      description: '将用户的反馈记录在需求池，为每个需求设置 优先级和处理状态，随时跟踪掌控需求处理情况。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E9%9C%80%E6%B1%82%E7%AE%A1%E7%90%86',
      banner: 'requirement_management.png'
    },
    {
      id: 28,
      title: '內部知識庫',
      subTitle: '给员工一个简单明了的知识库',
      description: '记录并分类管理产品知识和常见问题\n' +
      '知识库是团队成员的知识结晶，成员都可以添加分享相关知识，成为新成员加入团队后培训的材料。\n' +
      '应用管理员可以 查看并修改所有知识，普通成员只可修改自己发布的知识。\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E5%86%85%E9%83%A8%E7%9F%A5%E8%AF%86%E5%BA%93',
      banner: 'internal_knowledge_bank.png'
    },
    {
      id: 29,
      title: '設備巡檢',
      subTitle: '让每个设备都能正常运转',
      description: '这是一个简易的设备巡检管理应用，您将能实时追踪和管理各个场地的设备的运行状态。应用包含了设备的档案管理，巡检，保养，维修全流程。',
      banner: 'equipment_checking.png'
    },
    {
      id: 30,
      title: '維修工單管理',
      subTitle: '一个简洁、高效的维修工单管理助手',
      description: '这是一个简易的维修工单管理应用，您将能通过新建和管理维修记录并关联设备，实时追踪和管理各个场地的设备的运行状态，从而提高维修效率。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E7%BB%B4%E4%BF%AE%E5%B7%A5%E5%8D%95%E7%AE%A1%E7%90%86',
      banner: 'maintenance_note.png'
    },
    {
      id: 31,
      title: '招投標記錄',
      subTitle: '有记录，更懂投标',
      description: '招标投标作为一种成熟的交易方式，其重要性和优越性得到广泛认可。每次的招标投标过程，无论是对投标方还是对招标方来说，都是十分重要的经历。本应用主要分为招标项目、投标记录、会议记录三部分，旨在将招投标过程进行详尽的记录，以便于回顾。一方面是了解不同招标机构、招标文件要求、资质要求和评标专家的特点，提升投标能力，提高工程质量；另一方面是从优秀竞争对手方学习。\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E6%8B%9B%E6%8A%95%E6%A0%87%E8%AE%B0%E5%BD%95',
      banner: 'tender_records.png'
    },
    {
      id: 32,
      title: '項目收支',
      subTitle: '让每个项目都有一个贴身掌柜',
      description: '项目负责人都非常关心实施过程中预算的使用情况，运营的不好还有入不敷出的风险，客户项目收支应用，随时体现每个项目的收支及汇总情况，让你安心掌控项目执行的实时进展。\n' +
      '\n' +
      '对此模版有任何想法？欢迎留言：https://www.mingdao.com/form/925e33f6bfb945e29910658a1836c30c?source=%E9%A1%B9%E7%9B%AE%E6%94%B6%E6%94%AF',
      banner: 'project_expense.png'
    },


  ]
}

const getters = {
  categories: state => {
    return state.categories
  },
  templates: state => {
    return state.templates
  }
}

const mutations = {
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
