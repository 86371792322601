<template>
  <v-container fluid>

    <v-row
      no-gutters
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
    >
      <span style="white-space: pre-wrap;" class="username"
        >{{ item.user.employeeWorkInfo.displayName }} &nbsp;</span
      >
      <div v-if="item.replyTo">
        <span class="replyText">{{ $t("drawer.replyTo") }}</span>
        <span class="username">
          {{ item.replyTo.user.employeeWorkInfo.displayName }}
        </span>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="18" color="primary" v-on="on" v-bind="attrs"
              >mdi-message-text</v-icon
            >
          </template>
          <span style="white-space: break-spaces">{{ item.replyTo.content }}</span>
        </v-tooltip>
      </div>
      <v-spacer />
      <span v-if="!isHovering" class="comment-time">{{ item.relativeTime }}</span>
      <div v-else>
        <a v-if="deletable"  class="action-text red--text" @click="deleteComment">  {{ $t('buttons.delete') }}</a>
        <a
          
          class="action-text blue--text text--darken-1"
          @click="toggleReply"
          >{{$t('buttons.reply')}}</a
        >
      </div>
    </v-row>
    <v-row no-gutters>
      <span style="white-space: break-spaces">{{ item.content }}</span>
    </v-row>
    <v-row no-gutters>
      <file-table :files="item.medias" @onCommand="onCommandHandler"/>
    </v-row>
    <v-row no-gutters v-if="showReplyBox">
      <v-col cols="10">
        <comment-input
          v-model="comment"
          @onCommand="onReplyCommandHandler"
        />
        <file-table :files="files" @onCommand="onReplyCommandHandler"/>
      </v-col>
      <v-col align="end">
        <v-btn small @click="submit" :disabled="loading">{{ $t("buttons.submit") }}</v-btn>
      </v-col>
    </v-row>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </v-container>
</template>
<script>
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import commentInput from "./commentInput"
import FileTable from '../attachment/fileTable';
import CommentMixin from './commentMixin';
import uploadMixin from '../uploadMixin';

export default {
  mixins: [CommentMixin, uploadMixin],
  components: {
    confirmDeleteDialog,
    commentInput,
    FileTable
  },
  props: ["item"],
  data() {
    return {
      loading: false,
      isHovering: false,
      showReplyBox: false,
      comment: "",

      showDialog: false,
      title: this.$t('buttons.delete'),
      dialogMessage: this.$t('messages.areYouSure'),
      dialogOptions: {
          color: 'red',
            width: 290,
            zIndex: 200
      },

      files:[]
    };
  },
  watch: {
    showReplyBox : function (newVal, oldVal){
      if (newVal === false && this.files.length > 0){
      this.files.forEach((file)=>{
        this.removeAttachment(file._id);
      })
      this.files=[]
      }
    }
  },
  computed: {
    deletable(){
        return this.item.user._id === this.$store.getters.activeEmployeeId
    },
    createTime() {
      let time = new Date(this.item.createdAt);
      return time.toLocaleString();
    },
  },
  methods: {
    submit() {
    if (!this.comment && this.files.length === 0) return;
    let content = this.comment;
    if (!content) {
      const mediaNames = this.files.map(media => media.originalName).join(",")
      content = this.$t("drawer.uploadAttachment", {target: mediaNames});
    }
      this.loading = true;
      this.$emit("onCommand", {
        command: "submitReply",
        replyId:this.item._id,
        content: content,
        medias: [...this.files],
        callback: () => {
          this.loading = false;
          this.reset();
        }
      })
      // this.$emit("submit", {
      //   replyId: this.item._id,
      //   content: this.comment,
      //   callback: () => {
      //     this.loading = false;
      //     this.reset();
      //   },
      // });
    },
    deleteComment(){
        this.$refs.confirmDeleteDialog.confirm(()=> {
          this.$emit('delete', this.item._id)
        })
    },
    toggleReply(){
        this.showReplyBox = !this.showReplyBox;
        if (this.showReplyBox === false){
            this.reset();
        }
    },
    reset(){
        this.files = []
        this.showReplyBox = false;
        this.comment = '';
    },
    onCommandHandler(payload){
      console.log('commentRow.onCommandHandler payload: ', payload);
      switch (payload.command) {
         case "removeAttachmentFile": {
          this.$refs.confirmDeleteDialog.confirm(()=> {
          this.$emit("onCommand", {...payload, command: 'removeAttachmentInComment', commentId: this.item._id})
          })
          break;
        }
      }
    },
    onFilesUploaded (payload) {
      const vm = this
      vm.files = [];
      for (let i = 0; i < payload.mediaIds.length; i++) {
        vm.files.push({
          _id: payload.mediaIds[i],
          mediaType: payload.mediaTypes[i],
          objectKey: payload.objectKeys[i],
          originalName: payload.originalNames[i],
          thumbnailUrl: payload.thumbnailUrls[i]
        })
      }
    },
    onReplyCommandHandler(payload){
      switch(payload.command){
        case "uploadFiles": {
          this.uploadFiles(payload.files, this.onFilesUploaded);
          break;
        }
        case "removeAttachment": {
          if(this.removeAttachment(payload.id)){
          const index = this.files.findIndex((item) => item._id == payload.id);
          if (index > -1) {
            this.files.splice(index, 1);
          }
          }
          break;
        }
      }
    }
  },
};
</script>
<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";

.username {
  font-weight: 700;
}

.comment-time {
  font-size: 10px;
  color: map-get($grey, base);
}

.replyText {
  white-space: pre-wrap;
  font-size: 12px;
}

.action-text {
  margin-left: 4px;
  margin-right: 4px;
  font-size: 10px;
  text-decoration: underline;
}
</style>
