<template>
  <v-dialog persistent v-model="showingDialog" width="540px">
    <v-card>
      <dialog-header
        @close="showingDialog = false"
        :label="$t('workAdmin.newTitle')" />
      <v-card-text class="pa-2">
        <v-form v-model="valid" ref="jobTitleForm">
          <input-widget-container
            v-for="field in fields"
            :key="field.key"
            v-bind="field"
            required
          >
            <component
              :is="field.type"
              :ref="field.key"
              v-model="newValue[field.key]"
              dense
              v-bind="field"
              v-on="field.on"
              single-line
              outlined
              background-color="white"
              label=""
              :placeholder="$t('messages.pleaseInput', [])"
              required
            />
          </input-widget-container>
        </v-form>
      </v-card-text>
      <dialog-actions
        :buttons="['cancel', 'save']"
        @onCommand="onCommandHandler"
        :actionDisabled="loading"
    /></v-card>
  </v-dialog>
</template>
<script>
import InputWidgetContainer from "@/components/inputWidgets/comps/InputWidgetContainer";
import DialogHeader from "@/components/dialogs/comps/DialogHeader";
import DialogActions from "@/components/dialogs/comps/DialogActions";
import { VTextField } from "vuetify/lib";
import mixin from "./mixin";
export default {
  components: {
    DialogHeader,
    DialogActions,
    InputWidgetContainer,
    VTextField,
  },
  mixins: [mixin],
  props: {
    value: Boolean,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t("general.name"),
          type: "VTextField",
          rules: [
            (v) => this.RULE_REQUIRED(v),
            (v) => this.RULE_LENGTH_LIMIT(v, 150),
          ],
          counter: 150,
        },
      ],
      loading: false,
      commandMap: {
        cancel: () => (this.showingDialog = false),
        save: this.save,
      },

      newValue: {
        code: null,
        defaultJobLevelId: null,
        departmentId: null,
        description: null,
        duty: null,
        education: null,
        jobTitleCategoryId: null,
        maxJobLevelId: null,
        minJobLevelId: null,
        name: "",
        position: 0,
        qualification: null,
      },
      valid: false,
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onCommandHandler({ command, ...payload }) {
      return this.commandMap[command]
        ? this.commandMap[command](payload)
        : null;
    },
    save() {
      this.$refs.jobTitleForm.validate();
      if (this.valid) {
        this.loading = true;
        this.$emit("save", this.newValue);
      }
    },
  },
};
</script>
