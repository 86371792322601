<template>
<div class="tab-copy-related-fields flex-grow-1 d-flex flex-column px-2 fill-height">
  <table>
    <tbody>
    <tr v-for="(relatedFieldInfo, index) in params.relatedFieldInfos" :key="index">
      <td style="min-width:100px;"
          class="text-right"><v-chip color="primary">{{ relatedFieldInfo.label }}</v-chip></td>
      <td>
        <v-select outlined
          dense
          solo
          :value="tabData.relatedToLocalFields[relatedFieldInfo.fieldId]"
          @change="val=>update(relatedFieldInfo.fieldId, val)"
          small-chips
          deletable-chips
          clearable
          flat
          multiple
          hide-details="true"
          item-text="label"
          item-value="fieldId"
          :menu-props="{offsetY: true, contentClass: 'denseList'}"
          :items="params.localFieldInfos">
        </v-select>
      </td>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import mixin from './mixin'
import widgetHelperMixin from '@/mixins/widgetHelperMixin'

// input:
//
// :tabId
// :fieldInfos
// :tabKey // tabData related property
//    {
//      relatedToLocalFields: [
//        {related: 'f0001', local: 'f0001']
//        {related: 'f0002', local: 'f0002']
//        {related: 'f0003', local: 'f0003']
//        {related: 'f0004', local: 'f0004']
//        {related: 'f0005', local: 'f0005']
//      ]
//    }
// :errorCount
// v-model="tabData"
//
export default {
  name: 'tabCopyRelatedFields',
  mixins: [
    mixin,
    widgetHelperMixin
  ],
  // data () {
  //   return {
  //     items: [
  //       {id: 'f0001', label: 'field 1'},
  //       {id: 'f0002', label: 'field 2'},
  //       {id: 'f0003', label: 'field 3'},
  //       {id: 'f0004', label: 'field 4'},
  //       {id: 'f0005', label: 'field 5'},
  //       {id: 'f0006', label: 'field 6'},
  //       {id: 'f0007', label: 'field 7'},
  //       {id: 'f0008', label: 'field 8'}
  //     ]
  //
  //   }
  // },
  // computed: {
  //   includedRelatedFieldIds () {
  //     const vm = this
  //     return Object.keys(vm.tabData.relatedToLocalFields)
  //   }
  // },
  methods: {
    update(relatedFieldId, inputLocalFieldIds) {
      const vm = this
      var data = JSON.parse(JSON.stringify(vm.tabData))

      // remove local field id from other related field id
      for (let loopRelatedFieldId in vm.tabData.relatedToLocalFields) {
        const loopLocalFieldIds = vm.tabData.relatedToLocalFields[loopRelatedFieldId]
        if (loopRelatedFieldId !== relatedFieldId) {
          for (let i = loopLocalFieldIds.length - 1; i >= 0; i--) {
            const loopLocalFieldId = loopLocalFieldIds[i]
            if (inputLocalFieldIds.includes(loopLocalFieldId)) {
              vm.tabData.relatedToLocalFields[loopRelatedFieldId].splice(i, 1)
            }
          }
        }
      }
      vm.tabData.relatedToLocalFields[relatedFieldId] = inputLocalFieldIds
    }
  }
}
</script>
