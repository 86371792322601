<template>
    <div class="tab-table-display-settings flex-grow-1 d-flex flex-column px-2 fill-height">
      <relation-card-config 
        v-model="tabData.cardConfigs" 
        :formFieldInfos="fieldInfos" :titleFieldInfoId="titleFieldInfoId">
      </relation-card-config>
    
    </div>
</template>

<script>
import mixin from './mixin'
import relationCardConfig from '@/components/dialogs/viewSettingsTabs/comps/RelationCardConfig'


// INPUT:
// tabId
// fieldInfos
// tabKey
// options
// errorCount
// *tabData
// @onCommand
export default {
  name: 'tabCardDisplaySetup',
  mixins: [mixin],
  components: {
    relationCardConfig
  },
  computed: {
    titleFieldInfoId () {
      const vm = this
      var result = ''
      if (vm.dataSourceInfo) {
        result = vm.dataSourceInfo.titleFieldInfoId 
      }
      return result
      
    }
  }
}
</script>
