<template>
    <v-card class="" tile>
        <v-expansion-panels v-model="panel" class="chartStyleConfig-expansion">
            <ChartStyleTotalSetup 
                @onCommandHandler="onCommandHandler"
                :arrY="arrY" 
                :bl_displayTotal="styleSetupToggles.bl_displayTotal"
                :setup="styleSetups.total"
                v-show="selectedChart!='TABLE'"
            />
            <ChartStyleLegendSetup
                @onCommandHandler="onCommandHandler"
                :bl_legend="styleSetupToggles.bl_legend"
                :setup="styleSetups.legend"
                v-show="selectedChart!='TABLE'"
            />
            <ChartStyleXAxisSetup
                v-show="selectedChart!='TABLE' && selectedChart!='PIE' && selectedChart!='RADAR' && selectedChart!='FUNNEL'"
                :bl_xAxis="styleSetupToggles.bl_xAxis"
                :setup="styleSetups.xAxis"
                @onCommandHandler="onCommandHandler"
            />
            <ChartStyleYAxisSetup
                v-show="selectedChart!='TABLE' && selectedChart!='PIE' && selectedChart!='RADAR' && selectedChart!='FUNNEL'"
                :bl_yAxis="styleSetupToggles.bl_yAxis"
                :setup="styleSetups.yAxis"
                @onCommandHandler="onCommandHandler"
            />
            <ChartStyleLabelSetup
                :bl_label="styleSetupToggles.bl_label"
                :setup="styleSetups.label"
                @onCommandHandler="onCommandHandler"
                :selectedChart="selectedChart"
                v-show="selectedChart!='TABLE'"
            />
            <ChartStyleFilterSetup
                :bl_filter="styleSetupToggles.bl_filter"
                :setup="styleSetups.filter"
                @onCommandHandler="onCommandHandler"
                v-show="selectedChart!='TABLE'"
            />
            <!-- <ChartTableHideColumnSetup
                v-show="selectedChart=='TABLE'"
                :titleTree="titleTree"
                :selectedHidden="selectedHidden"
                @onCommandHandler="onCommandHandler"
            /> -->
            <ChartTableFooterSetup
                v-show="selectedChart=='TABLE'"
                @onCommandHandler="onCommandHandler"
                :setup="styleSetups.summary"
                :bl_summary="styleSetupToggles.bl_summary"
            />
            <!-- <v-expansion-panel
                v-for="(item,i) in sections"
                :key="i"
            >
                <v-expansion-panel-header class="py-0" hide-actions>
                    {{$t('chart.styleConfig.'+ item.label)}}
                </v-expansion-panel-header>
                  <v-switch
                    dense
                    style="position: absolute; right: 0px; top: -7px"
                    inset
                ></v-switch>
                <v-expansion-panel-content>
                </v-expansion-panel-content>
            </v-expansion-panel> -->
        </v-expansion-panels>
    </v-card>
</template>

<script>
    import ChartStyleTotalSetup from './style/ChartStyleTotalSetup'
    import ChartStyleLegendSetup from './style/ChartStyleLegendSetup'
    import ChartStyleXAxisSetup from './style/ChartStyleXAxisSetup'
    import ChartStyleYAxisSetup from './style/ChartStyleYAxisSetup'
    import ChartStyleLabelSetup from './style/ChartStyleLabelSetup'
    import ChartStyleFilterSetup from './style/ChartStyleFilterSetup'
    import ChartTableHideColumnSetup from './style/ChartTableHideColumnSetup'
    import ChartTableFooterSetup from './style/ChartTableFooterSetup'
    export default {
        components:{
            ChartStyleTotalSetup,
            ChartStyleLegendSetup,
            ChartStyleXAxisSetup,
            ChartStyleYAxisSetup,
            ChartStyleLabelSetup,
            ChartStyleFilterSetup,
            ChartTableHideColumnSetup,
            ChartTableFooterSetup
        },
        props:{
            styleSetupToggles: Object,
            styleSetups: Object,
            arrX: Array,
            arrY: Array,
            arr2Y: Array,
            selectedChart: String,
            titleTree: Array,
            selectedHidden: Array,
        },
        data(){
            return {
                panel: 0,
                sections: [
                    {
                        name: '總計',
                        label: 'total',
                        hidden: false
                    },
                    {
                        name: '圖例',
                        label: 'lookup',
                        hidden: false
                    },
                    {
                        name: 'X軸',
                        label: 'xAxis',
                        hidden: false
                    },
                    {
                        name: 'Y軸',
                        label: 'yAxis',
                        hidden: false
                    },
                    {
                        name: '資料標籤',
                        label: 'dataLabel',
                        hidden: false
                    },
                    {
                        name: '資料過濾',
                        label: 'dataFilter',
                        hidden: false
                    },
                    {
                        name: '顯示單位',
                        label: 'showUnit',
                        hidden: false
                    }
                ],
                calMethods: [
                    {
                        id: 1,
                        label: 'sum'
                    },
                    {
                        id: 2,
                        label: 'max'
                    },
                    {
                        id: 3,
                        label: 'min'
                    }, 
                    {
                        id: 4,
                        label: 'avg'
                    }
                ],
            }
        },
        computed:{
            colOpts(){
                let allOpt = {
                    id: 'all',
                    label: this.$t('chart.all')
                }
                if(this.arrY.length <=1){
                    return [allOpt]
                }else{
                    return [allOpt].concat(this.arrY)
                }
            }
        },
        methods:{
            onCommandHandler(payload){
                this.$emit('onCommandHandler', payload)
            }
        }
    }
</script>

<style>
.chartStyleConfig-expansion .v-expansion-panel--active .v-expansion-panel-header--active {
  background-color: white !important;
  color: black !important;
}
.chartStyleConfig-expansion  .v-list.v-select-list{
  padding: 2px !important;
}
.chartStyleConfig-expansion  .v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}
.chartStyleConfig-expansion  .v-select-list .v-list-item {
  min-height: 0 !important;
  padding: 0px 4px !important
}

.chartStyleConfig-expansion .v-select-list .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}
</style>