<template>
  <h4>Undefined</h4>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin]
}
</script>
