<template>
  <div v-if="widget" class="flex-grow-1 field-property-list py-0 px-3 white"
       style="border-left: 1px solid lightgrey;">
    <div class="py-2 d-flex flex-column fill-height">
      <div v-if="ofChildTable" class="blue lighten-4 pa-1 d-flex flex-row align-center">
        {{ label }}
        <v-spacer></v-spacer>
        <v-btn fab x-small @click="$emit('close')" color="primary">
          <v-icon>
            mdi-menu-right-outline
          </v-icon>
        </v-btn>
      </div>
      <widget-banner :widget="widget" class="mb-0" :appendChip="appendChip"></widget-banner>
      <perfect-scrollbar class="flex-grow-1 pr-3" style="height:0;">
        <div class="fixed-column">
          <div class="" v-if="propertyValues">
            <!-- {{approvedProperties}} -->
            <div v-for="(property, index) in approvedProperties" class="mb-2">
              <!--{{ property.propertyKey }}<br/>-->
              <!--{{ property.labelTag }}: {{ dependencyFulfilled(property, propertyValues) }}-->
              <!-- {{property}} -->
              <generic-widget-property
                  v-if="dependencyFulfilled(property, propertyValues)"
                  :parentLabel="properties.fieldName"
                  :property="property"
                  :ofChildTable="ofChildTable"
                  :childTableFieldInfoId="childTableFieldInfoId"
                  :fieldInfoId="fieldInfoId"
                  :fieldType="fieldType"
                  :values="propertyValues"
                  :masterFieldInfos="masterFieldInfos"
                  :currentFieldInfos="currentFieldInfos"
                  @onCommand="onCommandHandler"></generic-widget-property>
              <!--<generic-widget-property-->
              <!--:property="property"-->
              <!--:fieldInfoId="fieldInfoId"-->
              <!--:values="propertyValues"-->
              <!--@onCommand="onCommandHandler"></generic-widget-property>-->

              <!--<component :is="getComponentName(property)"-->
              <!--:property="property"-->
              <!--:values="propertyValues"-->
              <!--@onCommand="onCommandHandler"></component>-->
              <!--{{ property.type}}-->
              <!--{{ property.propertyName }}-->
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
  import adminFormMixin from '@/mixins/adminFormMixin'
  import widgetHelperMixin from '@/mixins/widgetHelperMixin'

  import widgetBanner from './comps/WidgetBanner'
  import genericWidgetProperty from './comps/properties/GenericWidgetProperty'
  // import {getWidget} from '@/helpers/FormHelpers'

  export default {
    mixins: [
      adminFormMixin,
      widgetHelperMixin
    ],
    components: {
      widgetBanner,
      genericWidgetProperty
    },
    data () {
      return {
        propertyValues: null
      }
    },
    props: {
      label: String,
      ofChildTable: {
        type: Boolean,
        default: false
      },
      childTableFieldInfoId: String,
      fieldId: String,
      fieldInfoId: String,
      fieldType: String,
      widgetId: String,
      properties: Array,

      masterFieldInfos: Array,
      currentFieldInfos: Array
      // fieldInfoId: {
      //   type: String,
      //   default: ''
      // }
    },
    mounted () {
      const vm = this
      vm.refresh()
      if (vm.widget) {
        // console.log('FieldPropertyList : mounted : vm.widget: ', vm.widget)
        switch (vm.widget.key) {
          case 'relatedSelection':
            // properties {
            //    copyRelatedRecordFields:""
            //    dataSource:Object
            //    displayFormat:""
            //    fieldName:"關聯選擇"
            //    relatedRecordAvatarField:""
            //    relatedRecordDisplayField:""
            //    relatedRecordView:""
            //    settingsMultiple:""
            //    twoWayRelated:""
            //    validation:""
            // }
            const dataTableId = vm.properties.dataSource
            vm.$store.dispatch('FETCH_CHILD_TABLE_INFO', {dataTableId: dataTableId})
            break
        }
      }
    },
    watch: {
      properties: function (newVal, oldVal) {
        const vm = this
        if (newVal !== oldVal) {
          vm.refresh()
        }
      }
      // ,
      // widgetId: function (newVal, oldVal) {
      //   const vm = this
      //   if (newVal !== oldVal) {
      //     vm.refresh()
      //   }
      // }
      // fieldInfoId: function (newValue, oldValue) {
      //   const vm = this
      //   console.log('FieldPropertyList :; watch(fieldInfoId)')
      //   vm.refresh()
      // }
    },
    computed: {
      parentChildRelationFieldIds () {
        return this.$store.getters.parentChildRelationFieldIds
      },
      appendChip () {
        const vm = this
        var result = ''
        if (vm.parentChildRelationFieldIds.includes(vm.fieldId)) {
          result = vm.$t('widgets.parentChildRelation')
        }
        return result
      },
      widget () {
        const vm = this
        return vm.getWidget(this.widgetId)
        // return getWidget(this.widgetId, this.widgetGroups)
      },
      // fieldInfo () {
      //   const vm = this
      //   var result = null
      //   if (vm.formFieldInfos) {
      //     result = vm.formFieldInfos.find(fieldInfo => fieldInfo._id === vm.fieldInfoId)
      //   }
      //   if (!result) {
      //     if (vm.formUIElementInfos) {
      //       result = vm.formUIElementInfos.find(info => info._id === vm.fieldInfoId)
      //     }
      //   }
      //   return result
      // },
      approvedProperties () {
        const vm = this
        const exceptions = ['width']
        var result = []
        for (var i = 0; i < vm.widget.properties.length; i++) {
          const p = vm.widget.properties[i]
          if (exceptions.indexOf(p.propertyKey) === -1) {
            result.push(p)
          }
        }
        return result
      },
      widgetGroups () {
        return this.$store.getters.widgetGroups
      }
    },
    methods: {
      showConditionFulfilled2 (propertyValues, propertyKey, propertyValue) {
        var result = true
        const neg = propertyValue.substr(0, 1) === '!'
        const multiple = propertyValue.indexOf('|') >= 0

        if (multiple) {
          const multipleValues = propertyValue.split('|')
          if (
            (neg && (multipleValues.indexOf(propertyValues[propertyKey]) >= 0)) ||
            (multipleValues.indexOf(propertyValues[propertyKey]) === -1)
          ) {
            result = false
          }
        } else {
          // check if dependent value is multiple, e.g. multiple checkbox property
          if (
            (neg && (propertyValues[propertyKey] === propertyValue)) ||
            (propertyValues[propertyKey] !== propertyValue)
          ) {
            result = false
          }
        }
        return result
      },

      checkSingleConditionFulfilled (propertyValues, propertyKey, pPropertyValue, property) {
        // console.log('checkSingleConditionFulFilled propertyValues: ', propertyValues)
        // console.log('checkSingleConditionFulFilled propertyKey: ', propertyKey)
        // console.log('checkSingleConditionFulFilled pPropertyValue: ', pPropertyValue)
        // console.log('checkSingleConditionFulFilled property: ', property)

        var result = true
        var neg = false
        var propertyValue = pPropertyValue
        var multiple = false
        var multipleValues = []
        if (Array.isArray(propertyValue)) {
          multiple = true
          multipleValues = propertyValue
        } else {
          if (pPropertyValue.substr(0, 1) === '!') {
            neg = true
            propertyValue = pPropertyValue.substr(1)
          }
          // console.log('neg = ' + (neg ? 'yes' : 'no'))

          multiple = propertyValue.indexOf('|') >= 0

          if (multiple) {
            multipleValues = propertyValue.split('|')          
          }
        }

        if (multiple) {
          if (
            (neg && (multipleValues.indexOf(propertyValues[propertyKey]) >= 0)) ||
            (multipleValues.indexOf(propertyValues[propertyKey]) === -1)
          ) {
            result = false
          }
        } else {
          // console.log('1 neg = ' + (neg ? 'yes' : 'no'))
          // console.log('checkSingleConditionFulFilled *** single')
          const dependentValue = propertyValues[propertyKey]
          
          //console.log('FieldPropertyList :: dependentValue: ', dependentValue)
          if (dependentValue) {
            // console.log('2 neg = ' + (neg ? 'yes' : 'no'))
            // if dependent value is multiple, e.g. multiple checkbox property
            if (Array.isArray(dependentValue)) {
              // console.log('dependentValue is array')
              if (neg) {
                result = dependentValue.indexOf(propertyValue) === -1
              } else {
                result = dependentValue.indexOf(propertyValue) >= 0
              }
            } else if (dependentValue.indexOf('||')>=0) {

              // console.log('checkSingleConditionFulFilled dependentValue contains ||')
              // if it is string contains '||'
              // convert to array for checking
              const arValues = dependentValue.split('||')
              if (neg) {

                result = arValues.indexOf(propertyValue) === -1
              } else {
                result = arValues.indexOf(propertyValue) >= 0
              }
            } else if (neg) {
              if (!propertyValue){ // check is empty
                  result = false
              } 
              else if (propertyValues[propertyKey] === propertyValue) {
                result = false
              }

            } else if(propertyValues[propertyKey] !== propertyValue) {
              // console.log('not neg, direct compare')
              result = false
            }
          } else if (!propertyValue){ //is not empty
              result = neg ? false:true
          }
          
          else {
            result = false
          }

          // if (
          //   (neg && (propertyValues[propertyKey] === propertyValue)) ||
          //   (propertyValues[propertyKey] !== propertyValue)
          // ) {
          //   result = false
          // }
        }
        return result
      },

      checkMultipleConditionFulfilled (propertyValues, multipleConditions, property) {
        const vm = this
        var result = true
        for (let i = 0; i < multipleConditions.length; i++) {
          const condition = multipleConditions[i]
          const propertyKey = condition[0]
          const propertyValue = condition[1]
          vm.log(property, 'checkMultipleConditionFulfilled: propertyKey', propertyKey)
          vm.log(property, 'checkMultipleConditionFulfilled: propertyValue', propertyValue)

          if (!vm.checkSingleConditionFulfilled(propertyValues, propertyKey, propertyValue)) {
            result = false
            break
          }
        }
        return result
      },

      showConditionFulfilled (propertyValues, conditionPair, property) {
const testing = false // property.propertyKey === 'selectFields'
        const vm = this
        var passed = true
        vm.log(property, 'showConditionFulfilled : conditionPair', conditionPair)
if(testing) console.log('showConditionFulfilled : conditionPair: ', conditionPair)
        const propertyKey = conditionPair[0]
        if (Array.isArray(propertyKey)) {
          vm.log(property, 'showConditionFulfilled : propertyKey is ', 'array')
          passed = vm.checkMultipleConditionFulfilled(propertyValues, conditionPair, property)
if (testing) console.log('showConditionFulfilled ; passed: ' + (passed ?'yes' : 'no'))
        } else {
          vm.log(property, 'showConditionFulfilled : propertyKey is ', propertyKey)
          const propertyValue = conditionPair[1]
          vm.log(property, 'showConditionFulfilled : propertyValue = ', propertyValue)
          passed = vm.checkSingleConditionFulfilled(propertyValues, propertyKey, propertyValue, property)
if(testing) {
  console.log('showConditionFulfilled: propertyValues[key]: ', propertyValues[propertyKey])
  console.log('showConditionFulfilled: propertyKey = ' + propertyKey)
  console.log('showConditionFulfilled: propertyValue = ' + propertyValue)
  console.log('showConditionFulfilled: passed = ' + (passed ? 'yes' : 'no'))
}
        }
        return passed
      },

      log (property, msg, obj) {
        const vm = this
        if (property) {
          if (property.propertyKey === 'xrelatedRecordDisplayField') {
            var output = msg
            if (typeof obj === 'number' || typeof obj === 'string') {
              console.log(output + ': ' + obj)
            } else {
              console.log(output + ': ', obj)
            }
          }
        }
      },

      dependencyFulfilled (property, propertyValues) {

        const checking = 'selectFields'
        const vm = this
        var result = true
        // console.log('dependencyFulfilled :: property: ', property)
        // console.log('dependencyFulfilled :: propertyValue: ', propertyValues)

        const hasShowIf = property.showIf &&
          Array.isArray(property.showIf) &&
          property.showIf.length > 0

        const hasShowIfEither = property.showIfEither &&
            Array.isArray(property.showIfEither) &&
            property.showIfEither.length > 0

        if (hasShowIf) {
          result = false
          vm.log(property, 'dependencyFulfilled :: showIf: ', property.showIf)
          const item0 = property.showIf[0]
          // [str, obj]
          if (typeof item0 === 'string') {
            // if element is just string
            const item1 = property.showIf.length > 1 ? property.showIf[1] : ''
            result = vm.showConditionFulfilled(
              propertyValues,
              [item0, item1],
              property
            )
          } else {
            // [ar, obj]
            result = true
            for (let i = 0; i < property.showIf.length; i++) {
              const andConditionPair = property.showIf[i]

// console.log('showIf: property.type = ' + property.propertyKey)
// console.log('showIf: andConditionPair: ', andConditionPair)
              if (!vm.showConditionFulfilled(propertyValues, andConditionPair, property)) {
                result = false
// console.log('showIf: false')
                vm.log(property, i + ': showConditionFulfilled: ', andConditionPair)
                break
              } else {
// console.log('showIf: true')
                vm.log(property, i + ': showConditionFulfilled not fulfilled: ', andConditionPair)
              }

              // if (typeof propertyKey !== 'undefined' && typeof propertyValue !== 'undefined') {
              //   if (!vm.showConditionFulfilled(propertyValues, propertyKey, propertyValue)) {
              //     result = false
              //   }
              // }
              // if (result) break
            }
          }
        }

        if (hasShowIfEither) {
// console.log('hasShowIfEither')
          result = false
          vm.log(property, 'dependencyFulfilled :: showIfEither: ',
            property.showIfEither)
          const item0 = property.showIfEither[0]
          // [str, obj]
          if (typeof item0 === 'string') {
            // if element is just string
            const item1 = property.showIfEither.length > 1 ?
              property.showIfEither[1] : ''

            result = vm.showConditionFulfilled(
              propertyValues,
              [item0, item1],
              property
            )
          } else {
            // [ar, obj]
            result = false
            for (let i = 0; i < property.showIfEither.length; i++) {
              const orConditionPair = property.showIfEither[i]

              if (vm.showConditionFulfilled(propertyValues, orConditionPair, property)) {
                result = true
                vm.log(property, i + ': showConditionFulfilled: ', orConditionPair)
                break
              } else {
                vm.log(property, i + ': showConditionFulfilled not fulfilled: ', orConditionPair)
              }
            }
          }
        }
        return result
      },
      onCommandHandler (payload) {
        const vm = this
        console.log('FieldPropertyList.vue :: onCommandHandler :: payload: ', payload)
        switch (payload.command) {
          // case 'updateWidgetPropertyValue':
          //   // propertyKey
          //   // value
          //   // const params = {
          //   //   fieldId: vm.fieldId,
          //   //   propertyKey: payload.propertyKey,
          //   //   propertyValue: payload.value
          //   // }
          //
          //   if (vm.childTable) {
          //     const childTableFieldInfo = vm.formFieldInfos.find(
          //       info => info._id === vm.childTableFieldInfoId
          //     )
          //     const fieldsEditorValue = childTableFieldInfo['properties']['fieldsEditor']
          //     for (let i = 0; i < fieldsEditorValue.fields.length; i++) {
          //       if (fieldsEditorValue.fields[i].id === vm.fieldInfoId) {
          //         fieldsEditorValue.fields[i]['properties'][payload.propertyKey] = payload.value
          //         break
          //       }
          //     }
          //
          //     vm.$emit('onCommand', {
          //       command: 'setFieldProperty',
          //       childTableFieldInfo: childTableFieldInfo,
          //       fieldInfoId: vm.fieldInfoId,
          //       propertyKey: 'fieldsEditor', // payload.propertyKey,
          //       propertyValue: fieldsEditorValue // payload.value
          //     })
          //   } else {
          //     vm.$emit('onCommand', {
          //       command: 'setFieldProperty',
          //       fieldInfoId: vm.fieldInfoId,
          //       propertyKey: payload.propertyKey,
          //       propertyValue: payload.value
          //     })
          //   }
          //   break
          default:
            vm.$emit('onCommand', payload)
        }
      },
      refresh () {
        const vm = this
        vm.propertyValues = vm.properties

      }
      // refresh () {
      //   const vm = this
      //   vm.fieldInfo = vm.getFieldInfo( vm.fieldInfoId)
      //   if (vm.fieldInfo) {
      //     vm.propertyValues = vm.fieldInfo.properties
      //     vm.widget = getWidget(vm.fieldInfo.widgetId, vm.widgetGroups)
      //   } else {
      //     vm.propertyValues = []
      //     vm.widget = null
      //   }
      // }
    }
  }

</script>

<style>
  /*.field-property-list .v-text-field__details {*/
  /*display: none;*/
  /*}*/

  .field-property-list label {
    font-size: 12px;
  }

  .fixed-column {
    height: 1px;
    min-height: 100%;
  }
</style>
