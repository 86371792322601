<template>
    <v-menu
        :content-class="`witDefaultValueMenuContent vxe-table--ignore-clear ${fullWidth? 'w-100':''}`"
        :close-on-content-click="false"
        v-model="showingMenu"
        offset-y
        nudge-top="14px"
        offset-overflow
    >
       <template v-slot:activator="{ on, attrs }">
       <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>
        <v-card
            tile
            style="overflow: hidden; border: 1px solid #d9d9d9;"
            class="membersMenuContent"
        >   
            <v-card-text class="pa-0 pt-0 d-flex flex-column">
                <!-- team page -->
                <div v-if="!currentTeamId">
                    <div class="d-flex align-start" style="height:25px">
                        <div class="flex-grow-1">
                            <div class="d-flex align-center elevation-2" style="height:23px">
                                <div class="pl-3">
                                    <div style="font-size:13px; color: rgba(0,0,0,0.6)">{{ $t('general.team') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <v-divider/>
                    <div style="max-height:200px; overflow: auto">
                        <v-list class="py-0">
                            <v-list-item
                                class="pa-1 pl-3 d-flex align-center" 
                                v-for="team in teams" 
                                :key="team._id"
                                @click="handleTeamChange(team._id)"
                                style="min-height:30px"
                            >
                                <!-- <v-icon>mdi-chevron-right</v-icon> -->
                                <v-avatar
                                    v-if="team.logo"
                                    size="32px"
                                >
                                    <img
                                        alt="Avatar"
                                        :src="team.logo"
                                    >
                                </v-avatar>
                                <v-avatar
                                    v-else
                                    size="32px"
                                    color="#BAD9FF"
                                >
                                    <span class="white--text" style="font-size:0.6vw">
                                        {{getShortName(team.name)}}
                                    </span>
                                </v-avatar>
                                
                                <span class="ml-3"
                                    style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                                >
                                    {{team.name}}
                                </span>
                            </v-list-item>
                        </v-list>
                    </div>
                </div>
                <!--member page-->
                <div v-else>
                    <div class="d-flex align-center" style="min-height:30px">
                        <div class="d-flex align-end flex-grow-1">
                            <div class="flex-grow-1">
                                <v-text-field
                                    solo
                                    height="23"
                                    hide-details
                                    dense
                                    style="font-size:13px"
                                    :placeholder="$t('settings.searchEmployee')"
                                    v-model="searchText"
                                >
                                    <template v-slot:prepend-inner>
                                        <div class="d-flex align-center">
                                            <div class="d-flex align-center">
                                                <v-icon @click="currentTeamId=null">mdi-chevron-left</v-icon>
                                                <div style="font-size:13px; color: rgba(0,0,0,0.6)">{{ $t('widgets.members') }}</div>
                                            </div>
                                            <div class="ml-2">
                                                <v-icon size="17" style="height:5px;width:15px">mdi-magnify</v-icon>
                                            </div>
                                        </div>
                                    </template>
                                </v-text-field>
                            </div>
                        </div>
                    </div>
                    <v-divider/>
                    <perfect-scrollbar
                        class="fill-height"
                        style="width:100%;"
                        @ps-y-reach-end="loadMoreEmployees"
                    >
                        <div style="max-height:250px">
                            <v-list class="py-0" v-if="employeeOptions.length">
                                <!-- v-bind="selectedMembers"
                                :multiple="type=='multiple'" -->
                                <!-- <v-list-item-group color="primary"> -->
                                    <v-list-item
                                        v-for="ee in employeeOptions" 
                                        :key="ee._id"
                                        class="pa-1 pl-3"
                                        :value="ee"
                                        @click="handleEmployeeClick(ee)"
                                        :class="checkSelected(ee)?'teal lighten-2 white--text':''"
                                    >
                                    <!-- :class="checkSelected(ee)?'teal lighten-2 white--text':''" -->
                                    
                                    <!-- :class="selectedMembers.includes(ee)?'teal lighten-2 white--text':''" -->
                                    <!-- @click="handleMemberChange"
                                    @change="handleMemberChange" -->
                                        <v-avatar
                                            v-if="ee.avatar"
                                            size="32px"
                                        >
                                            <img
                                                alt="Avatar"
                                                :src="ee.avatar"
                                            >
                                        </v-avatar>
                                        <v-avatar
                                            v-else
                                            size="32px"
                                            color="#BAD9FF"
                                        >
                                            <span class="white--text" style="font-size:0.6vw">
                                                {{getShortName(ee.employeeName)}}
                                            </span>
                                        </v-avatar>
                                        <span class="ml-3"
                                            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                                        >
                                            {{ee.employeeName}}
                                        </span>
                                    </v-list-item>
                                <!-- </v-list-item-group> -->
                            </v-list>
                            <div class="py-4 d-flex justify-center" v-else>
                                {{ $t('messages.noSearchResult') }}
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    import widgetHelperMixin from '@/mixins/widgetHelperMixin'
    export default {
        mixins: [widgetHelperMixin],
        props:{
            value: Boolean,
            attachId: {
                type: String,
                default: null
            },
            fullWidth:{
                type: Boolean,
                default: true
            },
            type: {
                type: String,
                default: 'single'     
            },
            selectedValue:{
                type: Array,
                default: () => []
            },
            fieldId:{
                type: String,
                default: null
            },
            nudgeBottom:{
                type: Number,
                default: 0
            },
            activeOnly: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return{
                searchText: '',
                currentTeamId: null,
                limit: 10,
                offset: 0,
                hasNextPage: false,
                filter: [],
                employeeOptions: [],
                typingTimeOut: null,
                recordId: null,
                showingMenu:false
            }
        },
        computed:{
            selectedMembers:{
              get(){
                return this.selectedValue
              }
            },
            selectedMembersIds(){
                return this.selectedValue.map(item=>item._id)
            },
            teams(){
                return this.$store.getters.user.teams
            },
        },
        watch:{
            searchText(val){
                let vm = this
                clearTimeout(this.typingTimeOut)
                this.typingTimeOut = setTimeout(()=>{
                    this.offset = 0
                    vm.fetchEmployees(true)
                    clearTimeout(this.typingTimeOut)
                }, 500)
            },
            showingMenu(val){
                if(!val){
                     this.currentTeamId = null
                }
            }
        },
        methods:{
            checkSelected(ee){
                return this.selectedMembersIds.includes(ee._id)
            },
            getShortName(employeeName){
                var nameArr = employeeName.split(' ')
                var shortName = nameArr.reduce((a,c)=>{
                    a = a+c[0]
                    return a
                }, '')
                return shortName
            },
            loadMoreEmployees() {
                if (this.hasNextPage) {
                    this.offset += this.limit;
                    this.fetchEmployees();
                }
            },
            fetchEmployees(isSearch) {
                const data = {
                    teamId: this.currentTeamId,
                    limit: this.limit,
                    offset:this.offset,
                    search : this.searchText.trim(),
                    filter: this.filter,
                    activeOnly: this.activeOnly
                }
                const postData = {
                    urlCommand: '/employees',
                    data
                }
                this.$store.dispatch('AUTH_POST', postData).then(res => {
                    var employees = res.docs
                    employees = employees.map((ee)=>{
                        var obj = {
                            _id: ee._id,
                            id: ee.id,
                            teamId: ee.teamId,
                            type: 'member',
                            avatar: ee.avatar,
                            name: ee.employeeWorkInfo.displayName,
                            employeeName: ee.employeeWorkInfo.displayName,
                        }
                        return obj
                    })
                    this.employeeOptions = isSearch ? employees:this.employeeOptions.concat(employees);
                    this.employeeOptions = JSON.parse(JSON.stringify(this.employeeOptions))
                    this.hasNextPage = res.hasNextPage;
                })
            },
            handleTeamChange(teamId){
                this.employeeOptions=[]
                this.currentTeamId = teamId
                this.hasNextPage = false
                this.offset = 0
                this.fetchEmployees()
            },
            handleEmployeeClick(employee){
                this.$emit('memberClick', employee)
            }
        }
    }
</script>

<style>
    .membersMenuContent .v-text-field.v-text-field--solo.v-input--dense > .v-input__control{
        height: 23px !important;
        min-height: 23px !important;
    }
    .membersMenuContent .v-input__slot{
        padding: 0px !important;
        box-shadow: 0 !important;
    }
    .membersMenuContent .v-text-field--solo{
        border-radius: 0 !important
    }
    .membersMenuContent .v-text-field__slot input{
        padding: 0px !important;
    }
    .membersMenuContent .v-input__prepend-inner{
        padding: 0px !important;
    }
 /* 
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
        box-shadow: 0 !important;
    } */
    /* .membersMenuContent .v-input__icon{
        width:15px !important;
        min-width: 15px !important;
        height: 15px !important;
    }
    .membersMenuContent .v-input__slot{
        height:23px !important;
        align-items: end;
    }
    .membersMenuContent .v-text-field__slot input{
        height: 13px !important;
        padding: 1px !important;
    }
    .membersMenuContent .v-input__slot .v-input__prepend-inner{
        align-self: unset !important;
        padding-right:2px !important
    } */
</style> 