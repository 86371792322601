import { newBlankRecord } from "@/helpers/FormHelpers";

import {
  FETCH_WORKFLOW_DEPENDENCIES,
  // FORM_SELECTIONS_FIELDS,
} from '@/store/modules/workflow/action_types';
import SettingUtils from '@/pages/admin/workflow/utils/setting';
import EventBus from '@/event-bus.js'
const recordMxiin = {
  data () {
    return {
      submitCommand: 'submit',
      loading: false,
      loadingRelationInfo: false,
      activeRecord: null,
      showingDialog: false,
      relatedTableData: {},
    };
  },
  computed: {
    currentForm() {
      return this.$store.getters.currentForm;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    // relatedTableInfos () {
    //   return this.$store.getters.relatedTableInfos
    // },
    fieldInfos() {
      // from workflow, hide fields must be shown for editing
      let infos = this.currentForm ? this.currentForm.fieldInfos : [];
      if (this.withHideFields) {
        infos = SettingUtils.normalizeHiddenFields(infos);
      }
      return infos;
    },
    editableFieldIds(){
      return this.fieldInfos.filter(info => info.canEdit).map(info=> info._id)
    },
    uiElementInfos() {
      return this.currentForm ? this.currentForm.uiElementInfos : [];
    },
    dataRelatedTableIds() {
      return this.$store.getters.relatedTableIds;
    },

    formData() {
      const vm = this;
      const SYSTEM_FIELD_IDS = [
        "owner", 
        "createdBy", 
        "createdAt", 
        "updatedAt"
      ];
      var result = {};
      if (vm.recordData) {
        // exclude _id
        const fieldIds = Object.keys(vm.recordData).filter(
          d => d.indexOf("_") < 0
        );

        for (var i = 0; i < fieldIds.length; i++) {
          const loopFieldId = fieldIds[i];
          if (!SYSTEM_FIELD_IDS.includes(loopFieldId)) {
            result[loopFieldId] = vm.recordData[loopFieldId];
          }
        }
      }
      return result;
    },

    relatedFormIds() {
      let result = []
      
      this.fieldInfos
      .filter(info=> info.type === "relatedRecord")
      .map(info => {
        if (info.type === "relatedRecord"){
          result.push(info.properties.dataSource);
        }
      })
      return result

    },

    titleFieldInfo () {
      const vm = this
      return vm.fieldInfos && vm.titleFieldInfoId ?
        vm.fieldInfos.find(info => info._id === vm.titleFieldInfoId) :
        null
    },
    editingNode() {
      return this.$store.state.workflowModules.editingNode;
    },
    workflow() {
      return this.$store.getters.getWorkflowInfo;
    },
    // dependencies() {
    //   const workflowOutputs = this.$store.getters.getDependencies;
    //   return WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));
    // },
    setting() {
      return this.$store.state.workflowTree.setting;
    },
    targetValue() {
      if(this.fieldInfo){
        const { _id } = this.fieldInfo;
        return this.recordData[_id]||null;
      }
      return null
    },
  },
  methods: {
    initialData() {
      return {
        _id: "",
      };
    },
    resetDependencies() {
      const { currentApp } = this;
      const { history: { current: { params } } } = this.$router;
      let options = {
        workflow: this.workflow._id, 
        node: this.editingNode._id,
        appId: currentApp ? currentApp._id : params.id,
      };
      if (this.fetchByNode) {
        options = { ...options,
          isBranch: true,
          noFieldFound: true,
        };
      }
      this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, options);
      
      // this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
      //   appId: this.appId,
      //   formId: v,
      // });
    },
    // resetData() {
      // this.recordData = this.initialData();
    // },
    _loadAppAndChild() {
      const vm = this;
      // console.log("Record.vue :: _loadAppAndChild");
      const appId = vm.$route.params.id;
      vm.$store.dispatch("FETCH_APP", { appId: appId }).then(() => {
        vm._loadFormAndChild();
      });
    },
    _loadFormAndChild() {
      const vm = this;
      // console.log("Record.vue :: _loadFormAndChild");
      const formId = vm.$route.params.itemId;
      vm.$store.dispatch("FETCH_FORM", { formId: formId, appId:this.currentApp._id }).then(() => {
        vm._loadData();
      });
    },
    checkLoadingRelatedFormInfos () {
      const vm = this
      console.log('checkLoadingRelatedformInfos :: vm.gs: ', vm.relatedFormIds)
      for (let i = 0; i < vm.relatedFormIds.length; i++) {
        const loopFormId = vm.relatedFormIds[i]
        if (!vm.dataRelatedTableIds.includes(loopFormId)) {
          vm.$store.dispatch("FETCH_RELATED_TABLE_INFO", {
            dataTableId: loopFormId,
            appId: vm.currentApp._id
          });
        }
      }
    },
    newRelatedRecord(payload) {
      console.log('recordMixin.newRelatedRecord : payload: ', payload)
      // this.$refs.recordDialog.open(payload);
      this.$emit('onCommand', {
        command: 'openRecordDialog',
        payload: payload
      })
    },

    updateFieldValue(payload) {
      const vm = this;
      vm.$set(vm.recordData, payload.fieldName, payload.fieldValue);
    },
    // initNewRecord (defaultValues, callback) {
    //   const vm = this
    //   const recordData = newBlankRecord(vm.fieldInfos);
    //   for (var key in recordData) {
    //     vm.$set(vm.recordData, key, recordData[key]);
    //   }
    //   vm.loading = false
    //   vm.$nextTick(() => {
    //     if (typeof callback === 'function') {
    //       callback()
    //     }          
    //   })
    // },
    getFieldInfosInRow(row) {
      const vm = this;
      var result = [];
      for (let i = 0; i < row.length; i++) {
        const loopId = row[i]._id;
        const controlType = row[i].controlType;
        var fieldInfo = null;
        if (controlType === "static") {
          fieldInfo = vm.uiElementInfos.find((info) => info._id === loopId);
        } else {
          fieldInfo = vm.fieldInfos.find((info) => info._id === loopId);
        }
        if (fieldInfo) {
          result.push(fieldInfo);
          // console.log("result.length = " + result.length);
        }
      }
      // console.log("getFieldInfosInRow :: result: ", result);
      return result;
    },
    addChildRow(payload) {
      const vm = this;
      // console.log("addChildRow: payload: ", payload);
      const fieldInfoId = payload.fieldInfoId;
      const fieldId = vm.getFieldId(fieldInfoId);
      if (vm.recordData[fieldId] instanceof Array) {
        vm.recordData[fieldId].push(payload.row);
      } else {
        vm.recordData[fieldId] = [payload.row];
      }
    },
    getFieldId(fieldInfoId) {
      const vm = this;
      // console.log('Record.vue :: getFieldId :: fieldInfoId = ' + fieldInfoId)
      return vm.fieldInfoId2FieldIdMapping[fieldInfoId];
    },
    fieldInfoId2FieldIdMapping() {
      const vm = this;
      var result = {};
      for (var i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i];
        result[loopFieldInfo._id] = loopFieldInfo.fieldId;
      }
      // console.log("Record.vue :: fieldInfoId2FieldIdMapping :: result: ",result);
      // console.log('fieldInfo2FieldIdMapping :: recordData: ', vm.recordData)
      return result;
    },
    getFieldInfoById(fieldInfos, fieldInfoId) {
      const vm = this;
      var result = null;
      if (fieldInfos) {
        result = fieldInfos.find((info) => info._id === fieldInfoId);
      }
      return result;
    },
    editRelatedRecord (payload) {
      const vm = this
      vm.activeRecord = payload.record
      const fieldInfo = vm.getFieldInfoById(vm.fieldInfos, payload.fieldId)
      const relatedFormId = fieldInfo.properties.dataSource
      const relatedFormInfo = vm.relatedTableInfos[relatedFormId]
      vm.$refs.recordDialog.open({
        recordId: vm.activeRecord._id,
        formInfo: relatedFormInfo,
        onSubmitted: vm.onRelatedRecordUpdated
      })
    },
    unlinkRelatedRecord(payload) {
      const vm = this;
      const childData = payload.data
      console.log('unlinkRelatedRecord: child data id = ' + childData._id)
      const fieldId = payload.fieldId
      if (vm.recordData[fieldId] instanceof Array) {
        console.log('unlinkRelatedRecord :: local field with ids: ' + vm.recordData[fieldId].join(','))
        const idIndex = vm.recordData[fieldId].findIndex(id => id === childData._id)
        if (idIndex >= 0) {
          vm.recordData[fieldId] .splice(idIndex, 1)
        }
        // vm.recordData[fieldId].push(payload.row);
      } else {
        vm.recordData[fieldId] = []
      }
    },
    onSubmit() {
      const vm = this;
      // console.log("onSubmit");
      vm.save(() => {
        vm.returnToFormViewPage();
      });
      return false;
    },

    async save(callback) {
      const vm = this;
      vm.formHasErrors = false;
      const errors = await vm.validateAll();
      // console.log("save errors = " + errors);
      if (errors === 0) {
        const postData = JSON.parse(JSON.stringify(vm.recordData));
        postData["formId"] = vm.currentForm._id;
        postData["appId"] = vm.currentApp._id
        vm.$store.dispatch("SAVE_DATA", postData).then((response) => {
          vm.showSuccess("messages.savedSuccessfully");
          if (typeof callback === "function") {
            callback(postData);
          }
        }).catch(error=>{
          this.$toast.error(this.$t("errors.save"))
        });
        // vm.$emit('onCommand', {
        //   command: 'submitForm',
        //   id: vm.data._id,
        //   data: vm.data
        // })
      } else {
        console.log("save errors exists = " + errors);
      }
      vm.showingDialog = false;
    },

    getNewRecordUrl () {
      const vm = this
      const appId = vm.$route.params.appId
      const itemId = vm.$route.params.itemId
      const viewId = vm.$route.params.viewId
      const updatedUrl = vm.$router.resolve({
        name: 'records',
        params: {
          appId: appId,
          itemId: itemId,
          viewId: viewId,
          recordId: '_'
        }
      }).href
      return history.replaceState(null, null, updatedUrl)
    },

    saveAndNew() {
      const vm = this;
      console.log('RecordCard2 :: saveAndNew')
      vm.save(() => {
        // history.replaceState({},'xxx','http://localhost:8080/apps');
        vm._loadData(true /*FORCE_NEW*/)
        const updatedUrl = vm.getNewRecordUrl()
        history.replaceState(null, null, updatedUrl)
      });
    },

    returnToFormViewPage() {
      const vm = this;
      var url = "";
      // console.log("returnToFormViewPage");
      if (vm.prevRoute && vm.prevRoute.name in ["forms"]) {
        // console.log(
        //   "returnToFormViewPage :: vm.prevRoute.name = " + vm.prevRoute.name
        // );
        vm.$router.go(-1);
      } else {
        // console.log(
        //   "returnToFormViewPage :: vm.appItemRouteName = " + vm.appItemRouteName
        // );
        vm.$router.push({
          name: "forms",
          params: {
            id: vm.currentApp._id,
            itemId: vm.$route.params.itemId, // vm.currentItem._id
          },
        });
      }
    },

    saveAndKeep () {
      const vm = this;
      console.log('RecordCard2 :: saveAndNew')
      vm.save( () => {
        vm.$set(vm.recordData, '_id', '')
        const updatedUrl = vm.getNewRecordUrl()
        history.replaceState(null, null, updatedUrl)
      });

        // const postData = JSON.parse(JSON.stringify(vm.recordData));
        // postData["formId"] = vm.currentForm._id;
        // postData["appId"] = vm.currentApp._id
    },
    init (recordId, defaultValues = {}, forceNew = false) {
      const vm = this;
      // console.log('recordMixin :: init :: recordId = ' + recordId)
      if (recordId === '' || recordId ==='_' || recordId===undefined || forceNew) {
        vm.initNewRecord(defaultValues, () => {
          vm.prepare();
        });
      } else {
        vm.loadRecord(recordId, () => {
          vm.prepare();
        });
      }
    },

    prepare() {
      const vm = this;
      vm.loading = false;
      vm.$nextTick(() => {
        vm.resetValidationAll();
        // vm.checkLoadingRelatedFormInfos();
      });
    },
    
    reloadRelatedTableData(payload) {
      // payload = {
      //    fieldId: '',
      // }
      const vm = this
      console.log('reloadRelatedTableData: payload: ', payload)
      const fieldInfo = vm.fieldInfos.find(info => info._id === payload.fieldId)
      const recordIds = vm.recordData[payload.fieldId]
      if (fieldInfo) {
        switch (fieldInfo.type) {
          case 'relatedRecord':
            if (fieldInfo.properties.relatedRecordQuantity==='single' &&
              Array.isArray(recordIds) && recordIds.length > 0) {
              const ds = fieldInfo.properties.dataSource
              vm.$store.dispatch('FETCH_RELATED_RECORDS', {
                recordIds: recordIds,
                dataSource: ds
              }).then(
                response => {

                  console.log('reloadRelatedTableData :: payload.fieldId = ' + payload.fieldId)
                  console.log('reloadRelatedTableData :: response: ', response)
                  vm.relatedTableData[payload.fieldId] = response
                }
              )
            } 
            break
          case 'childTable':
            break
        }
      }      
    },

    loadRecord(recordId, callback) {
      this.loadRecordByAppForm(recordId, this.formId, this.appId, this.viewId, callback)
    },
    loadRecordByAppForm(recordId, formId, appId, viewId, callback) {
      const vm = this;
      vm.loading = true
      
      vm.$store
        .dispatch("FETCH_RECORD", {
          appId: appId,
          formId: formId,
          viewId: viewId,
          recordId: recordId,
          showTitle: true,
          skipRelatedTableInfos: !Array.isArray(vm.relatedFieldInfos)
        })
        .then(response => {
          vm.loading = false
          console.log('loadRecordByAppForm : response: ', response)
          for (var key in response.result) {
            var fieldInfo = vm.fieldInfos.find(info => info.fieldId === key)
            if(fieldInfo && fieldInfo.type=='relatedRecord'){
              if(response.result[key] && Array.isArray(response.result[key])){
                response.result[key] = response.result[key].map(item=>{
                  return typeof item === 'object'? item._id:item
                })
              }
            }
              vm.$set(vm.recordData, key, response.result[key]);
          }
          // console.log('recordMixin :: loadRecord : response: ', response)
          vm.setRelatedTableData(response.relatedTableData)
         // vm.setRelatedTableInfos(response.relatedTableInfos)

          vm.updateAllSummary()
          if (typeof callback === "function") {
            callback();
          }
        })
    },

    getTargetFieldInfo (fieldId) {
      const vm = this
      console.log('getTargetFieldInfo: fieldId = ' + fieldId)
      const fieldInfo = vm.fieldInfos.find(info => info.fieldId === fieldId)
      return fieldInfo
    },

    updateAllSummary () {
      const vm = this
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopInfo = vm.fieldInfos[i]
        if (loopInfo.type === 'summaryField') {
          const targetFieldInfo = vm.getTargetFieldInfo(loopInfo.properties.tableField)

          console.log('updateAllSummary :: targetFieldInfo: ', targetFieldInfo)
          if (targetFieldInfo) {
            var isTable = true
            var targetSummaryFormId = ''
            var relationFormId = ''
            var summaryConfigs = []

            var validSource = false
            if (targetFieldInfo.type === 'relatedRecord') {
              // isTable = targetFieldInfo.properties.displayFormat==='table'
              if (targetFieldInfo.properties.dataSource && targetFieldInfo.properties.dataSource) {
                console.log(2)
                validSource = true
                targetSummaryFormId = targetFieldInfo.properties.dataSource
                relationFormId = targetFieldInfo.properties.dataSource.relationVirtualFormId
              }
            } else {
              console.log(4)
              validSource = true
              targetSummaryFormId = targetFieldInfo.properties.fieldsEditor.formId 
              relationFormId = targetFieldInfo.properties.fieldsEditor.relationFormId 
            }

            if (validSource) {
              console.log(5)
              summaryConfigs = [{
                fieldId: loopInfo.fieldId,
                summaryFieldId: loopInfo.properties.summaryField,
                summaryFunction: loopInfo.properties.summaryFunction
              }]
              var putData = null
              try {      
                putData = {
                  urlCommand: '/data/' + vm.recordData._id + '/updateSummaryFields',
                  data: {
                    fieldType: targetFieldInfo.type,
                    formId: vm.formId,
                    isTable: isTable,
                    relationFormId: relationFormId,
                    summaryConfigs: JSON.stringify(summaryConfigs),
                    summaryFormId: targetSummaryFormId,
                    summaryRecordIds: [],
                    updateDb: isTable
                  }
                }
                console.log('updateSummaryFields: putData: ', putData)
              } catch(err) {
                console.log('err: ', err)
              }
              vm.$store.dispatch('AUTH_PUT', putData).then(
                response => {
                  for (let fieldId in response) {
                    vm.$set(vm.recordData, fieldId, response[fieldId])
                  }
                }
              )
            }
          }
        }
      }
    },

    setRelatedTableData (tableData) {
      const vm = this
      vm.relatedTableData = {}
      for (var key in tableData) {
        vm.$set(vm.relatedTableData, key, tableData[key]);
      }
    },

    setRelatedTableInfos (tableInfos) {
      const vm = this
      vm.relatedTableInfos = {}
      for (let tableInfoId in tableInfos) {
        vm.$set(vm.relatedTableInfos, tableInfoId, tableInfos[tableInfoId])
      }
    },

    initNewRecord(defaultValues, callback) {
      const vm = this;
      console.log('initNewRecord :: vm.fieldInfos: ', vm.fieldInfos)
      // let recordData = newBlankRecord(vm.fieldInfos);
      vm.$store.dispatch('GENERATE_TEMP_RECORD_ID', {
        appId: vm.appId,
        formId: vm.formId
      }).then(
        response => {
          console.log('initNewRecord GENERATE_TEMP_RECORD_ID.then response: ', response.result)
          vm.initRelatedTableDatas(vm.fieldInfos)
          vm.loadRelatedTableInfos(vm.fieldInfos)
          var defaultData = response.default
          // Object.assign(recordData, defaultData)
          // let recordData = newBlankRecord(vm.fieldInfos, defaultData);
          let recordData = defaultData
          recordData._id = response.result
          console.log('initNewRecord GENERATE_TEMP_RECORD_ID.then recordData: ', JSON.stringify(recordData))          
          for (let key in recordData) {
            const value = recordData[key]
            var field = vm.fieldInfos.find(item=>item.fieldId == key)
            if(field && field.type == 'relatedRecord'){
              var mappedRecordIds = value.map(item=>item._id)
              vm.$set(vm.recordData, key, mappedRecordIds)
              vm.$set(vm.relatedTableData, key, value)
              if(field.properties.displayFormat === "table" && !vm.$store.getters.isPublicRoute){
                const relatedRecordIds = Array.isArray(mappedRecordIds)
                ? mappedRecordIds
                : [mappedRecordIds];

                const params = {
                  id: recordData._id,
                  formId: vm.formId,
                  fieldId: field._id,
                  relatedRecordIds: relatedRecordIds,
                  linked: true,
                };
                vm.$store.dispatch("UPDATE_RELATED_RECORDS", params);
              }
            }else
              vm.$set(vm.recordData, key, value)
          }
          if(defaultValues) {
            for (let fieldId in defaultValues) {
              vm.set(vm.recordData, fieldId, defaultValues[fieldId])
            }
          }
          vm.$nextTick(() => {
            if (typeof callback === 'function') {
              callback()
            }
          })
        }
      ).catch(error=>{
        this.$toast.error(this.$t("errors.create"))
        vm.loading = false;
      });
      // for (var key in recordData) {
      //   vm.$set(vm.recordData, key, recordData[key]);
      // }
      // if (defaultValues) {
      //   for (let fieldId in defaultValues) {
      //     vm.recordData[fieldId] = defaultValues[fieldId]
      //   }
      // }
      // vm.loadRelatedTableInfos(vm.fieldInfos)
    
      // vm.loading = false;
      // vm.$nextTick(() => {
      //   if (typeof callback === "function") {
      //     callback();
      //   }
      // });
    },

    initRelatedTableDatas (fieldInfos) {
      const vm = this
      const relatedFieldInfos = fieldInfos.filter(info => ['relatedRecord','childTable'].includes(info.type))
      vm.relatedTableData = {}
      for (let i = 0; i < relatedFieldInfos.length; i++) {
        const loopInfo = relatedFieldInfos[i]
        vm.$set(vm.relatedTableData, loopInfo.fieldId, [])
      }
    },

    loadRelatedTableInfos (fieldInfos) {
      const vm = this
      vm.loadingRelationInfo = true
      const urlCommand = '/forms/' + vm.formId + '/relatedTableInfos'
      vm.$store.dispatch('AUTH_GET', urlCommand).then(
        response => {
          vm.loadingRelationInfo = false
          vm.setRelatedTableInfos(response.relatedTableInfos)
        }
      )
    },

    resetValidationAll() {
      const vm = this;
      const fieldIds = Object.keys(vm.formData);
      // console.log("save :: fieldIds: ", fieldIds.join(','));

      fieldIds.forEach(f => {
        if (vm.$refs[f]) {
          // console.log('vm.$refs[' + f + ']: ', vm.$refs[f])
          if (vm.$refs[f].length > 0) {
            if (typeof vm.$refs[f][0].resetValidation === "function") {
              // console.log('field Id #' + f + ': has resetValidation function')
              vm.$refs[f][0].resetValidation();
            } else {
              // console.log('field Id #' + f + ': has no resetValidation function')
            }
          }
        }
      });
    },
    async validateAll() {
      const vm = this;      
      const fieldIds = vm.editableFieldIds;

      var errors = 0;
      // console.log('validateAll :: fieldIds.length = ' + fieldIds.length)

      let promises = [];
      fieldIds.forEach(f => {
        // temp for debugging
        //const fieldName = vm.getFieldNameByFieldId(f)
        if (vm.$refs[f] && vm.$refs[f][0]) {
          promises.push(() => vm.$refs[f][0].validate());
          //const result = vm.$refs[f][0].validate()
          //console.log(f, result)
          //if (!result) errors++;
        }
      });

      const arrayOfPromises = promises.map(promise => promise());
      const results = await Promise.all(arrayOfPromises);
      // for (let i = 0; i < results.length; i++) {
      //   console.log('i=' + i + ': results[i]: ', results[i])
      // }
      errors = results.filter(result => !result).length;
      vm.errors = errors;
      return errors;
    },

    isParentChildRelationship (formId, fieldInfo) {
      const vm = this
      var result = false
      if (fieldInfo && fieldInfo.properties && fieldInfo.properties.dataSource) {
        result = formId === fieldInfo.properties.dataSource
      }
      return result
    },
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case 'newRelatedRecord':
          vm.newRelatedRecord(payload)
          // alert('onCommandHandler :: newRelatedRecord')
          break
        case "updateFieldValue":
          vm.updateFieldValue(payload);
          break;
        case "addChildRow":
          vm.addChildRow(payload);
          break;
        case 'editRelatedRecord':     
          vm.editRelatedRecord(payload)
          break
        case 'unlinkRelatedRecord':
          vm.unlinkRelatedRecord(payload);
          break
        case "refreshData":
          break;
        case "submit":
          vm.onSubmit()
          vm.submitCommand = 'submit'
          break
        case 'submitAndNew':
          vm.saveAndNew()
          vm.submitCommand = 'submitAndNew'
          break
        case 'submitAndKeep':
          vm.saveAndKeep()
          vm.submitCommand = 'submitAndKeep'
          break
        case "delete":
          break;
        case "back":
          vm.returnToFormViewPage();
          break;
        case "memberInputClick":
          EventBus.$emit('openMembersMenu', payload)
        break;
        default:
          if (typeof vm._onCommandHandler === 'function') {
            vm._onCommandHandler(payload)
          }

      }
    },
  },
  // watch: {
  //   recordData: {
  //     deep: true,
  //     handler(v) {
  //       console.log('>>>> ', v);
  //     }
  //   },
  // }
}

export default recordMxiin
