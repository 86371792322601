const chartTypes = [
    {id:'BAR', name: '棒形圖', label:'barChart', icon:'mdi-chart-bar', type: 'bar', hidden: false},
    {id:'LINE', name: '折線圖', label:'lineChart', icon:'mdi-chart-line', type: 'line', hidden: false},
    {id:'COMBO', name: '雙軸圖', label:'mixedChart', icon:'mdi-finance', type: 'combo', hidden: true},
    {id:'RADAR', name: '雷達圖', label:'radarChart', icon:'mdi-hexagon-slice-2', type: 'radar', hidden: false},
    {id:'PIE', name: '扇形圖', label:'pieChart', icon:'mdi-chart-pie', type: 'pie', hidden: false},
    {id:'FUNNEL', name: '漏斗圖', label:'funnelChart', icon:'mdi-filter-variant', type: 'funnel', hidden: false},
    {id:'TABLE', name: '透視圖', label:'tableChart', icon:'mdi-grid', type: 'table', hidden: false},
    // {id:'', name: '數值圖', label:'numberChart', icon:'mdi-numeric', type: '', hidden: true},
    // {id:'', name: '區域圖', label:'districtChart', icon:'mdi-plus', type: '', hidden: true},
]

export {
    chartTypes
}