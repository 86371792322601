<template>
  <div class="fill-height">
    <v-row no-gutters>found {{ rows.length }} results</v-row>
    <v-row no-gutters class="pa-4" v-if="rows.length > 0"> 
      <v-col
        v-for="header in tableHeaders"
        :key="header._id"
        class="header-text"
      >
        {{ header.label }}
      </v-col>
    </v-row>

    <div class="pa-0 table-container" v-if="rows.length > 0">
      <v-col class="pa-0" style="position:absolute;width:100%">
        <v-card
          v-for="(row, index) in rows"
          @click="selectCard(row._id)"
          :key="row._id"
          class="my-2"
          height="96"
        >
          <v-card-text class="fill-height">
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="fill-height"
              style="overflow:hidden"
            >
              <v-col
                v-for="header in tableHeaders"
                class="fill-height"
                :key="header._id"
                :style="`max-width: ${100 / tableHeaders.length}%;`"
              >
                <field-string
                  height="auto"
                  :textOnly="false"
                  :fieldInfo="header"
                  :canPreview="false"
                  :recordData="row"></field-string>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </div>
  </div>
</template>
<script>
import mediaHelperMixin from '@/mixins/mediaHelperMixin'
import fieldString from '@/components/fieldStrings/Fs'

export default {
  props: {
    headers: Array,
    rows: Array,
    total: Number,
  },
  mixins:[mediaHelperMixin],
  components: {
    fieldString
  },
  computed: {
    tableHeaders() {
      return this.headers.slice(0, 5);
    },
    fieldInfos(){
      return this.$store.getters.currentForm && this.$store.getters.currentForm.fieldInfos
    }
  },
  methods: {
      selectCard(id){
          this.$emit('select', id)
      },
      getFieldInfo(fieldId){
        return this.fieldInfos.find(info => info._id === fieldId)
      }
  }
};
</script>
<style scoped>
.header-text {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: bold;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-container {
  overflow: auto;
  position: relative;
  height: calc(100% - 144px);
}
</style>
