var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row align-center"},[_vm._t("label"),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 new-option",attrs:{"color":"primary","disabled":_vm.remaining <= 0,"x-small":""},on:{"click":_vm.newInputOption}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("general.new"))+" ")]),_c('v-spacer'),_c('v-switch',{staticClass:"ma-0",attrs:{"dense":"","small":"","hide-details":"","label":_vm.$t('widgets.properties.useColor')},on:{"change":function($event){return _vm.updateProperty('useColor', $event)}},model:{value:(_vm.useColor),callback:function ($$v) {_vm.useColor=$$v},expression:"useColor"}})],2),_c('div',{staticClass:"grey lighten-4 py-1"},[_c('vue-nestable',{attrs:{"keyProp":"_id","maxDepth":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("widgets.properties.noData"))+" ")]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('vue-nestable-handle',{staticStyle:{"width":"auto"},attrs:{"item":item}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-format-line-spacing")])],1),(_vm.useColor)?_c('color-menu-button',{attrs:{"value":item.colorSet},on:{"input":function (colorIndex) { return _vm.updateColorSetByIndex(item._id, colorIndex); }}}):_vm._e(),_c('input',{staticClass:"option-label-input ",domProps:{"value":item.label},on:{"input":function ($evt) { return _vm.updateOption(item, $evt.target.value, 'label'); }}}),_c('v-spacer'),_vm._t("prepend-delete",null,{"item":item}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.removeOption(item._id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-window-close")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("buttons.delete"))+" ")])],2)]}}],null,true),model:{value:(_vm.inputOptions),callback:function ($$v) {_vm.inputOptions=$$v},expression:"inputOptions"}}),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","x-small":"","disabled":_vm.remaining <= 0},on:{"click":function($event){_vm.showBulkCreateDialog = true}}},[_vm._v(_vm._s(_vm.$t("buttons.bulkCreate")))]),_c('v-btn',{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){_vm.showValueAssignmentDialog = true}}},[_vm._v(_vm._s(_vm.$t("optionGroup.valueAssignment")))])],1)],1),(_vm.showBulkCreateDialog)?_c('OptionsBulkCreateDialog',{attrs:{"remainingSpace":_vm.remaining},on:{"confirm":_vm.bulkAddOptions},model:{value:(_vm.showBulkCreateDialog),callback:function ($$v) {_vm.showBulkCreateDialog=$$v},expression:"showBulkCreateDialog"}}):_vm._e(),(_vm.showValueAssignmentDialog)?_c('OptionsValueAssignmentDialog',{attrs:{"options":_vm.options,"useValue":_vm.useValue},on:{"confirm":_vm.onValueAssignmentSubmit},model:{value:(_vm.showValueAssignmentDialog),callback:function ($$v) {_vm.showValueAssignmentDialog=$$v},expression:"showValueAssignmentDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }