<template>
 <v-row dense>
        <v-col cols="4" class="d-flex flex-row align-center">
          <label>{{ $t(labelTag) }}</label>
        </v-col>
        <v-col cols="8" class="d-flex flex-row align-center">
          <v-select ref="vSelect"
            :hide-details="true"
            dense
            :disabled="!enabled"
            required
            solo
            outlined
            :items="options"
            v-model="userValue"
            :menu-props="{offsetY: true, contentClass: 'denseList'}"
          ></v-select>
        </v-col>
      </v-row>
</template>

<script>
  export default {
    data () {
      return {
        userValue: ''
      }
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      labelTag: String,
      options: Array,
      value: String,
      enabled: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      checkIsValid (state) {
        const vm = this

        const result = vm.$refs.vSelect.validate(true)
        console.log('SettingsSelectRow :: checkIsInvalid : result: ', result)
        return result
      }
    },
    mounted () {
      const vm = this
      vm.userValue = vm.value
    },
    watch: {
      userValue: function (newVal, oldVal) {
        const vm = this
        if (newVal  !== oldVal) {
          vm.$emit('input', newVal)
          console.log('watch(userValue) newVal = ' + newVal)
        }
      },
      value: function (newVal, oldVal) {
        const vm = this
        vm.userValue = newVal
      }
    }
  }
</script>
