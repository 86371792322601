<template>
  <div>
    <FormElement
      :label="label || $t('workflow.modules.form.label.selectForm')"
      :hints="hints"
      name='worksheet'
    >
      <ComboBox
        :value="worksheet || null"
        :options="list"
        :placeholder="placeholder"
        @selecting="selecting"
        valueFormat="object"
        @changed="changed"
      />
      <div v-if="warning && !!worksheet" class="w-control-remove-title">
        {{ $t('workflow.modules.form.message.resetBeingChanged') }}
      </div>
    </FormElement>
    <!-- <v-dialog
      v-model="show"
      width="500"
    >
      <v-card class="workform-confirm-box">
        <v-layout justify-space-between align-center>
          <v-card-title class="workform-confirm-box-title w-control-remove-title">
            {{ $t('workflow.modules.form.hints.beingChanged') }}
          </v-card-title>
          <v-btn icon @click="cancelChange">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <v-card-text>
          <span class="workform-confirm-box-message">
            {{ $t('workflow.modules.form.message.resetBeingChanged') }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="workform-confirm-box-btn"
            @click="cancelChange"
          >
            {{ $t('workflow.btn.cancel') }}
          </v-btn>
          <v-btn
            color="rgb(244, 67, 54)"
            class="workform-confirm-box-btn w-control-remove-btn-confirm"
            @click="confirmChange"
          >
            {{ $t('workflow.btn.sureChange') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import Vue from 'vue';
import ComboBox from './../../../form/comboBox/';
import FormElement from './formElement';
import Mixin from './../mixin';
import {
  FORM_SELECTIONS,
  FORM_SELECTIONS_FIELDS,
  FETCH_WORKFLOW_DEPENDENCIES,
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';

import {
  map,
  omit,
  get,
} from 'lodash';
export default {
  mixins: [Mixin],
  // data() {
  //   return {
  //     show: false,
  //     selected: null,
  //     preSelected: null,
  //   };
  // },
  props: {
    // setting: Object,
    worksheet: Object,
    placeholder: String,
    hints: String,
    label: String,
    warning: Boolean,
    fetchByNode: Boolean, // fetch the dependencies from node level, instead of form level
    fetchForRelationship: Boolean,
  },
  computed: {
    list() {
      return this.$store.getters.getFormSelections;
    },
    dependencies() {
      const dependencies = get(this.$store.getters.getDependencies, '[0]');
      return dependencies ? dependencies.children : [];
    },
  },
  methods: {
    // changed(v) {
    //   // console.log('dfasf', v, this.setting.worksheet);
    //   this.selected = v;
    //   this.show = true;
    //   this.preSelected = this.setting.worksheet;
    // },
    // cancelChange() {
    //   this.show = false;
    //   if (this.preSelected) {
    //     this.$store.dispatch(SET_SETTINGS, {
    //       worksheet: this.preSelected,
    //       hookFields: [],
    //       rules: [],
    //     });
    //   }
    //   this.selected = null;
    //   this.preSelected = null;
    // },

    fetchWorkflowDependencies(worksheet) {
      if(this.workflow){
        if (!this.fetchByNode) {
          this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
            workflow: this.workflow._id,
            form: worksheet._id,
            appId: this.appId,
          });
        } else if (this.fetchByNode) {
          this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
            workflow: this.workflow._id,
            node: this.node._id,
            isBranch: true,
            noFieldFound: true,
            appId: this.appId,
          });
        }
      }
    },

    changed(v) {
      // this.worksheet = this.selected;
      this.$store.dispatch(SET_SETTINGS, {
        worksheet: v,
        hookFields: [],
        rules: [],
        sorting: {},
        data: {},
      });

      this.fetchWorkflowDependencies(v)

      if (v) {
        this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
          appId: this.appId,
          formId: v._id,
        });
      }
      // this.cancelChange();
    }
  },
  components: {
    ComboBox,
    FormElement,
  },
  // beforeCreate() {
  //   if (!this.dependencies || !this.dependencies.length) {
  //     this.$store.dispatch(FORM_SELECTIONS);
  //   }
  // },
  created() {
    if (this.setting.worksheet) {
      this.fetchWorkflowDependencies(this.setting.worksheet);

      this.$store.dispatch(FORM_SELECTIONS_FIELDS, { appId: this.appId, formId: this.setting.worksheet._id });
    }
  },
  // watch: {
  //   worksheet: {
  //     handler(wasId, id) {
  //       console.log('>>>>>');
  //     },
  //     deep: true,
  //   }
  // },
};

</script>

<style scoped>
  .w-control-remove-title {
    color: rgb(244, 67, 54);
    margin: 5px 0 30px 0;
    font-size: 11px;
  }
  .w-control-remove-btn-confirm {
    color: white;
  }
</style>
