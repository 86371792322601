<template>
  <div>
    <label>{{ label }}</label>
    <v-text-field
        label=""
        v-model="propertyValue"
        dense
        hide-details="auto"
        outlined></v-text-field>
  </div>
</template>

<script>
  import mixin from './mixin'

  export default {
    mixins: [mixin]
  }
</script>
