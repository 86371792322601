<template>
  <v-dialog v-model="value"
            v-if="value"
            :width="width"
            scrollable
            persistent
            :content-class="contentClass"
            >
    <v-card class="base-tabs-dialog">
        <!-- <dialog-header
          :icon="icon"
          :label="title"
          :saveButton="true"
          :saveDisabled="actionDisabled"
          :loading="loading"
          @save="save"
          @close="close"/> -->
        <dialog-header
          :icon="icon"
          :label="title"
          @close="close"
        />
        <v-card-text class="base-tabs-dialog-content pt-0 pa-0">
          <v-tabs 
            vertical 
            v-model="tabIndex"
            color="teal"
            height="150"
          >
            <v-tab ripple v-for="(tab, index) in tabs" :key="index"
                   :class="{'red--text': tab.errorCount > 0}" style="font-size:13px">
              <v-icon size="18" left v-if="tab.icon">{{ tab.icon }}</v-icon>
                {{ $t(tab.labelTag) }} 
                <span v-if="tab.suffix" class="base-tabs-suffix">
                  {{ getSuffix(tab.suffix) }}
                </span>
            </v-tab>
            <slot></slot>
          </v-tabs>
        </v-card-text>
        <!-- <v-divider/>
        <dialog-actions :actionDisabled="actionDisabled"
                        :loading="loading"
                        @onCommand="onCommandHandler"></dialog-actions> -->
        <!-- <div style="height:40px;" class="elevation-2">
          <div class="pr-3 d-flex align-center justify-end" style="height:100%">
            <v-hover v-slot="{hover}">
              <v-btn small class="elevation-0" :class="!hover?'grey--text':'error--text'" style="width:100px" outlined>
                <span v-if="!hover">取消</span>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>
            </v-hover>
            <v-hover v-slot="{hover}">
              <v-btn small class="primary elevation-0 ml-2" style="width:100px" :class="!hover?'':'success'">
                <span v-if="!hover">確定</span>
                <v-icon v-else>mdi-check</v-icon>
              </v-btn>
            </v-hover>
          </div>
        </div> -->
        <dialog-actions
          v-if="!noFooter"
          :actionDisabled="actionDisabled"
          :loading="loading"
          @onCommand="onCommandHandler"
          :buttons="['close', 'save']"
        />
      </v-card>
  </v-dialog>
</template>

<script>
  import dialogHeader from '@/components/dialogs/comps/DialogHeader'
  import dialogActions from '@/components/dialogs/comps/DialogActions'
  export default {
    name: 'baseTabsDialog',
    components: {
      dialogHeader,
      dialogActions,
    },
    watch: {
      tabIndex: function (newVal, oldVal) {
        const vm = this
        console.log('BaseTabsDialog :: watch(tabIndex)')
        vm.$emit('onCommand', {
          command: 'checkValidity',
          tabIndex: oldVal,
          newIndex: newVal,
        })
      },
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    // computed: {
    //   isValidData () {
    //     const vm = this
    //     var result = true
    //     for (let i = 0; i < vm.tabs.length; i++) {
    //       const loopTab = vm.tabs[i]
    //       if (vm.$refs[loopTab
    //     }
    //     return result
    //   }
    // },
    props: {
      title: String,
      icon: String,
      noFooter: Boolean,
      value: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: '900'
      },
      height: {
        type: String,
        default: '90%'
      },
      tabs: {
        type: Array,
        default () {
          return []
        }
      },
      initialIndex: {
        type: Number,
        default: 0,
      },
      loading: {
        type: Boolean,
        default: false
      },
      actionDisabled: {
        type: Boolean,
        default: false
      },
      contentClass:{
        type: String,
        default: ''
      }
    },
    data () {
      return {
        showing: false,
        tabIndex: 0
      }
    },
    created(){
      this.tabIndex = this.initialIndex;
    },
    computed: {
      // tabIndex() {
      //   return this.initialIndex;
      // },
      // isValidData () {
      //   const vm = this
      //   var result = true
      //   if (vm.$refs.form) {
      //     result = vm.$refs.form.validate()
      //   }
      //   return result
      // }
    },
    methods: {
      getSuffix (suffix) {
        switch(suffix) {
          case 'buttonCounter':
            const counter = this.$store.getters.totalActionButtons;
            return !!counter ? ` (${counter})` : '';
        }
        return '';
      },
      getTotalErrorCount () {
        const vm = this
        var result = 0
        for (let i = 0; i < vm.tabs.length; i++) {
          const loopTab = vm.tabs[i]
          const errorCount = loopTab.errorCount
          result += errorCount
          console.log('tab#' + i + ': ' + errorCount)
        }
        return result
      },
      save () {
        const vm = this
        vm.$parent.checkValidity()
        vm.$nextTick( () => {
          const totalErrorCount = vm.getTotalErrorCount()
          if (totalErrorCount === 0) {
            vm.onSubmit()
          } else {
            vm.$alert(
              vm.$t('messages.inputErrorPleaseFixItFirst'),
              vm.$t('general.warning'),
              'warning',
              {
                confirmButtonText: vm.$t('buttons.ok'),
                cancelButtonText: vm.$t('buttons.cancel')
              }
            )
          }
        })
      },

      tabChanged(index) {
        this.$emit('tabChange', index);
      },

      onCommandHandler (payload) {
        const vm = this
        switch (payload.command) {
          case 'save':
            vm.$parent.checkValidity()
            vm.$nextTick( () => {
              const totalErrorCount = vm.getTotalErrorCount()
              if (totalErrorCount === 0) {
                vm.onSubmit()
              } else {
                vm.$alert(
                  vm.$t('messages.inputErrorPleaseFixItFirst'),
                  vm.$t('general.warning'),
                  'warning',
                  {
                    confirmButtonText: vm.$t('buttons.ok'),
                    cancelButtonText: vm.$t('buttons.cancel')
                  }
                )
              }
              vm.close()
            })
           break
          default:
            vm.close()
        }
      },
      close () {
        this.$emit('input', false)
      },
      onSubmit () {
        this.$emit('submit')
      }
    }
  }
</script>

<style >
  .base-tabs-dialog .v-tab.v-tab--active {
    background-color: #cae4e2 !important;
    /* background-color: lightgray !important; */
    /*color: white !important;*/
    /*background-color: #1976d2 !important;*/
    /*color: white !important;*/
  }

  .base-tabs-dialog-content .base-tabs-suffix {
    margin-left: 5px;
    color: #666;
    font-size: 14px;
    font-weight: bolder;
  }

  .base-tabs-dialog-content {
    overflow-y: hidden !important;
  }

  .base-tabs-dialog-content .v-tabs-items {
    border-left: #e9e9e9 1px solid !important;
    padding-left: 5px !important;
    background-color: transparent;
    max-height: 540px;
    min-height: 540px;
    overflow-y: auto !important;
  }

  .base-tabs-dialog-content .v-tab {
    justify-content: start;
  }

</style>
