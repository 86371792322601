<template>
    <div>
        <div v-if="selectedChart!='TABLE'">
            <div class="pt-3">
                <span :class="{'error--text': setupIsUpdated.arrX}">
                    {{$t('chart.dimensionXAxis')}}
                </span>
                <chart-default-field-row
                    v-for="(field, index) in arrX" 
                    :key="index"
                    :field="field"
                    @remove="$emit('onCommandHandler',{
                        command: 'removeX',
                        value: index
                    })"
                /> 
                <div 
                    id="dataSourceX" 
                    v-show="showDataSourceX" 
                    class="droppable py-1 px-3 mt-1 rounded" 
                    style="border: #aaaa dashed 1px"
                >
                    <span style="color: #aaaa">{{$t('chart.dragToHere')}}</span>
                </div>
            </div>
            <div class="pt-3">
                <div :class="{'error--text': setupIsUpdated.arrY}">{{$t('chart.dimensionYAxis')}}</div>
                <chart-default-field-row
                    v-for="(field, index) in arrY"
                    :key="index"
                    :field="field"
                    @remove="$emit('onCommandHandler',{
                        command: 'removeY',
                        value: index
                    })"
                /> 
                <div 
                    id="dataSourceY" 
                    v-show="showDataSourceY" 
                    class="droppable py-1 px-3 mt-1 rounded"
                    style="border: #aaaa dashed 1px"
                >
                    <span style="color: #aaaa">{{$t('chart.dragToHere')}}</span>
                </div>
                <div 
                    class="mt-3"
                    :class="{'error--text': setupIsUpdated.arrG}"
                    v-if="(arrY.length == 1 && selectedChart!='PIE' && selectedChart!='RADAR' && selectedChart!='FUNNEL')"
                >
                    {{$t('chart.group')}}
                </div>
                <chart-default-field-row
                    v-for="(field, index) in arrG"
                    :key="index"
                    :field="field"
                    @remove="$emit('onCommandHandler',{
                        command: 'removeG',
                        value: index
                    })"
                /> 
                <div 
                    id="dataSourceG" 
                    v-show="showDataSourceG" 
                    class="py-1 px-3 mt-1 rounded droppable" 
                    style="border: #aaaa dashed 1px"
                >
                    <span style="color: #aaaa">{{$t('chart.dragToHere')}}</span>
                </div>
            </div>
        </div>
        <div class="pt-3" v-else>
            <span :class="{'error--text': setupIsUpdated.arrX}">{{$t('chart.dimensionRow')}}</span>
            <chart-default-field-row
                v-for="(field, index) in arrX"
                :key="index"
                :field="field"
                @remove="$emit('onCommandHandler',{
                    command: 'removeX',
                    value: index
                })"
            >
                <template v-slot:default v-if="['date', 'createdAt', 'updatedAt'].includes(field.type)">
                    <chart-grouping-date-menu
                        :field="field"
                    >    
                        <template v-slot:dateRow="{on, opening}">
                            <div 
                                v-on="on" 
                                class="d-flex py-1 px-3 align-center mx-0 mt-1"
                                style="cursor: pointer;"
                            >
                                <v-icon dark x-small :style="opening?'transform: rotate(0deg)':'transform: rotate(180deg)'">mdi-triangle</v-icon>
                                <span v-if="localeTypes.includes(field.type)">{{$t('chart.'+field.type)}}</span>
                                <span v-else>{{field.label}}</span>
                                <span v-if="field.dateGroupingOpt" class="ml-1">{{`(${field.dateGroupingOpt})`}}</span>
                                <v-icon 
                                    @click="$emit('onCommandHandler',{
                                        command: 'removeX',
                                        value: index
                                    })"
                                    class="ml-auto"
                                    color="white" 
                                    size="18"
                                >
                                    mdi-close
                                </v-icon>
                            </div>
                        </template>
                    </chart-grouping-date-menu>
                </template>
            </chart-default-field-row>

            <div 
                id="dataSourceTableX" 
                v-show="showDataSourceTableX" 
                class="droppable py-1 px-3 mt-1 rounded" 
                style="border: #aaaa dashed 1px"
            >
                <span style="color: #aaaa">{{$t('chart.dragToHere')}}</span>
            </div>
            <div class="pt-3" :class="{'error--text': setupIsUpdated.arrY}">{{$t('chart.dimensionColumn')}}</div>
            <chart-default-field-row
                v-for="(field, index) in arrY"
                :key="index"
                :field="field"
                @remove="$emit('onCommandHandler',{
                    command: 'removeY',
                    value: index
                })"
            >
                <template v-slot:default v-if="['date', 'createdAt', 'updatedAt'].includes(field.type)">
                    <chart-grouping-date-menu
                        :field="field"
                    >    
                        <template v-slot:dateRow="{on}">
                            <div 
                                v-on="on" 
                                class="d-flex py-1 px-3 align-center mx-0 mt-1"
                            >
                                <v-icon dark x-small style="transform: rotate(90deg)">mdi-triangle</v-icon>
                                <span v-if="localeTypes.includes(field.type)">{{$t('chart.'+field.type)}}</span>
                                <span v-else>{{field.label}}</span>
                                <span>{{field.dateGroupingOpt?` (${field.dateGroupingOpt})`:''}}</span>
                                <v-icon 
                                    @click="$emit('onCommandHandler',{
                                        command: 'removeY',
                                        value: index
                                    })"
                                    class="ml-auto"
                                    color="white" 
                                    size="18"
                                >
                                    mdi-close
                                </v-icon>
                            </div>
                        </template>
                    </chart-grouping-date-menu>
                </template>
            </chart-default-field-row>

            <div 
                id="dataSourceTableY" 
                class="py-1 px-3 mt-1 rounded droppable" 
                style="border: #aaaa dashed 1px"
            >
                <span style="color: #aaaa">{{$t('chart.dragToHere')}}</span>
            </div>
            <div class="mt-3" :class="{'error--text': setupIsUpdated.arrG}">{{$t('chart.numeric')}}</div>
            <chart-default-field-row
                v-for="(field, index) in arrG" 
                :key="index"
                :field="field"
                @remove="$emit('onCommandHandler',{
                    command: 'removeG',
                    value: index
                })"
            /> 
            <div 
                id="dataSourceTableData" 
                class="py-1 px-3 mt-1 rounded droppable" 
                style="border: #aaaa dashed 1px"
            >
                <span style="color: #aaaa">{{$t('chart.dragToHere')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import ChartDefaultFieldRow from './ChartDefaultFieldRow'
    import ChartGroupingDateMenu from './ChartGroupingDateMenu'
    export default {
        components:{
            ChartGroupingDateMenu,
            ChartDefaultFieldRow
        },
        props:{
            arrX: Array,
            arrY: Array,
            arr2Y: Array,
            arrG: Array,
            selectedChart: String,
            setupIsUpdated: Object
        },
        data(){
          return{
            localeTypes: ['recordCount', 'owner', 'createdBy', 'createdAt', 'updatedAt'],
            showingGroupByDateMenu: false,
            selectedDateField: null
          }  
        },
        computed:{
            relatedTableInfos(){
                return this.$store.getters.relatedTableInfos
            },
            fieldInfos(){
                return this.$store.getters.currentForm.fieldInfos
            },
            showDataSourceX(){
                return !this.arrX.length || this.selectedChart=='TABLE'
            },
            showDataSourceY(){
                return (this.arrG.length==0 &&
                    this.selectedChart!='PIE' &&
                    this.selectedChart!='FUNNEL') ||
                    (this.selectedChart=='PIE' && this.arrY.length==0) ||
                    (this.selectedChart=='RADAR' && this.arrY.length==0) ||
                    (this.selectedChart=='FUNNEL' && this.arrY.length==0)
            },
            showDataSourceG(){
                return this.arrY.length == 1 &&
                    this.arrG.length==0 &&
                    this.selectedChart!='PIE' &&
                    this.selectedChart!='RADAR' &&
                    this.selectedChart!='FUNNEL'
            },
            showDataSourceTableX(){
                return !this.arrX.length || this.selectedChart=='TABLE'
            },
            showDataSourceTableY(){
                return this.$store.getters.currentForm.fieldInfos
            },
            showDataSourceTableData(){
                return this.$store.getters.currentForm.fieldInfos
            }
        },
        methods:{
            getLabel(field){
                console.log('getLabel::field', field)
                if(field.type=='recordCount')
                    return 'Record Count'
                var res = this.fieldInfos.find(item=>item.fieldId==field._id)
                if(res){
                    if(res.type=='relatedRecord'){
                        var dataTableId = res.properties.dataSource
                        var relatedField = this.relatedTableInfos[dataTableId].fieldInfos.find(item=>item._id==field.relatedId)
                        if(relatedField)
                            return res.label + ' - ' + relatedField.label
                    }else{
                        if(res.type=='childTable'){
                            var childFieldInfos = res.properties.fieldsEditor.fieldInfos
                            var childField = childFieldInfos.find(item=>item._id==field.relatedId)
                            if(childField) return res.label + ' - ' + childField.label
                        }
                    }
                    return res.label   
                }
            }
        }
    }
</script>