<template>
  <div class="wit-field-selection">
    <label>{{ label }}</label>
    <v-select 
      dense
      outlined
      solo
      multiple
      small-chips
      cleararble
      v-model="propertyValue"
      deletable-chips
      item-color="primary"
      :items="items"
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
    >
      <template #selection="{item}">
        <v-chip small close class="mb-1 mr-1" color="primary" @click:close="removeSelection(item)">{{ item.text }}</v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      // items: [
      //    {text: 'aaa', value: 'f00001'},
      //    {text: 'bbb', value: 'f00002'},
      //    {text: 'ccc', value: 'f00003'},
      //    {text: 'ddd', value: 'f00004'}
      // ]
    }
  },
  computed: {
    selfFieldId () {
      const vm = this
      var result = ''
      for (let i = 0; i < vm.formFieldInfos.length; i++) {
        if (vm.formFieldInfos[i]._id === vm.fieldInfoId) {
          result = vm.formFieldInfos[i].fieldId
          break
        }
      }
      return result
    },
    usingNumberFields () {
      const vm = this
      var result = false
      if (vm.propertyValues['expressionType']) {
        result = vm.propertyValues['expressionType'] === 'number'
      }
      return result
    },
    usingDateFields () {
      const vm = this
      var result = false
      if (vm.propertyValues['expressionType']) {
        result = vm.propertyValues['expressionType'] === 'date'
      }
      return result
    },
    items () {
      const vm = this
      let result = []

      let fieldTypes = []

      if (vm.usingNumberFields) {
        fieldTypes = ['number', 'amount', 'expression', 'singleSelection', 'multipleSelection','summaryField']
      } else if (vm.usingDateFields) {
        fieldTypes = ['date', 'createdAt', 'updatedAt']
      }

      if (fieldTypes.length > 0) {
        for (let i = 0; i < vm.currentFieldInfos.length; i++) {
          const loopItem = vm.currentFieldInfos[i]
         
          if (loopItem.fieldId !== vm.fieldInfoId && fieldTypes.includes(loopItem.type)) {
            if (loopItem.type === 'singleSelection'){
              if (loopItem.properties.inputOptionsSingle.useValue)
              result.push({
                text: loopItem.label,
                value:loopItem._id
              })
            } else if (loopItem.type === 'multipleSelection' ){
              if (loopItem.properties.inputOptionsMultiple.useValue)
              result.push({
                text: loopItem.label,
                value:loopItem._id
              })
            } else {
              result.push({
                 text: loopItem.label,
                value:loopItem._id               
              })
            } 
          }
        }
      }

      // console.log('WidgetInputTypeFieldSelection :: result: ', result)
      return result
    }

  },
  methods: {
    removeSelection(item) {
      const index = this.propertyValue.findIndex(val => val ===item.value)
      if (index > -1) this.propertyValue.splice(index, 1)
    }
  }
}
</script>

<style>
  .wit-field-selection .v-select__selections {
    padding-top: 4px;
  }
</style>
