<template>
  <loading-icon v-if="loading"></loading-icon>
  <div v-else class="panel-builder flex-grow-1 d-flex flex-row fill-height">
    <!-- Column #0 - widget selection -->
    <div class="widget-list d-flex flex-column flex-grow-0">
      <widget-panel 
        command="widgetClicked"
        :itemsPerRow="1"
        :widgetGroups="panelWidgetGroups"
        @onCommand="onCommandHandler"></widget-panel>
    </div>
    <div style="height:0;min-height:100%;overflow:auto;" class="flex-grow-1">
      <grid-canvas
        :elements="elements"
        @update_elements="update_elements"
        :isPanel="true"
      />
    </div>
     <v-navigation-drawer
            v-model="drawer"
            absolute
            right
            width="650"
            class=''
            style="height:100%"
            temporary
        >
            <v-card tile style="height:100%" elevation="0">
            <v-card-subtitle class="px-5 py-4" style="border-bottom: 1px solid #eeee; font-size:16px">{{$t('dashboard.buildChart')}}</v-card-subtitle>
            <div class="d-flex flex-column">
                <div class="ml-10 mt-5" style="font-size:15px;">{{$t("dashboard.form")}}</div>
                <div class="mx-10">
                    <v-menu
                      offset-y
                      :close-on-content-click="false"
                    >
                        <template v-slot:activator="{on, value}">
                            <v-text-field v-on="on" 
                              hide-details outlined dense height="30" 
                              :placeholder="$t('dashboard.selectForm')" 
                              readonly 
                              class="cursor-pointer"
                              :value="selectedFormName">
                                <template>
                                </template>
                            </v-text-field>
                        </template>
                        <v-card class="" elevation="0">
                            <v-text-field
                              prepend-inner-icon="mdi-magnify"
                              :placeholder="$t('dashboard.searchForm')"
                              hide-details
                              height="30"
                              dense
                              v-model="searchString"
                            >
                                <tmeplate v-slot:>
                                </tmeplate>
                            </v-text-field>
                            <v-list class="pa-0" dense>
                                <v-list-item-group v-model="selectedForm" color="primary">
                                    <v-list-item :value="menuItem._id" v-for="menuItem in filteredModuleMenu" :key="menuItem._id">
                                        {{menuItem.title}}
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card>
                    </v-menu>
                </div>
                <div v-if="charts.length" class="ml-10 mt-6 line-height-1" style="font-size:15px;">{{$t('dashboard.selectChart')}}</div>
                <div 
                  v-else
                  class="d-flex justify-center grey--text mx-10 mt-6 line-height-1" 
                  style="font-size:14px; border-top: 1px dashed #d9d9d9">
                    <div class="px-2 white" style="position: absolute; transform: translate(0, -8px)">
                      {{$t('dashboard.emptyChart')}}
                    </div>
                </div>
                <div id="form-chartHook" class="px-8 pt-0 pb-12">
                    <v-hover @click.native="selectChart(chart)" v-slot="{ hover }" v-for="(chart,index) in charts" :key="index" class="d-flex flex-column">
                      <v-card outlined tile flat class="chartContainer d-flex flex-column"> 
                        <div class="pl-2 pt-1">{{chart.chartName}}</div>
                        <ChartCanvas
                          style="height:320px"
                          v-if="chart"
                          :ref="`formChart-${chart._id}`"
                          :dataSetups="chart.dataSetups"
                          :sourceSetups="chart.sourceSetups"
                          :styleSetupToggles="chart.styleSetupToggles"
                          :styleSetups="chart.styleSetups"
                          :selectedChart="chart.selectedChart"
                          :chartId="chart._id"
                          tab="private"
                          hookId="private-chartHook"
                          :bl_fullScreen="bl_fullScreen"
                          :appId="chart.appId"
                          :formId="chart.formId"
                        /> 
                      </v-card>
                    </v-hover>
                </div> 
            </div>
            </v-card>
        </v-navigation-drawer>
        <RichTextDialog
          v-model="bl_richTextDialog"
          :content="''"
          @update_elements="update_elements"
        />
        <ButtonDrawer
            v-model="bl_buttonDrawer"
            @update_elements="update_elements"
        />
        <EmbeddedUrlDialog
          v-if="bl_embeddedUrlDialog"
          v-model="bl_embeddedUrlDialog"
          @update_elements="update_elements" 
        />
  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import widgetPanel from '@/components/WidgetPanel'
import gridCanvas from '@/pages/panels/GridCanvas'
import RichTextDialog from '@/pages/panels/RichTextDialog'
import EmbeddedUrlDialog from '@/pages/panels/EmbeddedUrlDialog'
import ButtonDrawer from '@/pages/panels/ButtonDrawer'
import EventBus from "@/event-bus.js";
import ChartCanvas from '@/components/chart/panes/ChartCanvas'

export default {
  name: 'panelBuilder',
  mixins: [dialogMixin],
  components:{
    // panelWidgetList,
    widgetPanel,
    gridCanvas,
    RichTextDialog,
    EmbeddedUrlDialog,
    ButtonDrawer,
    ChartCanvas
  },
  data () {
    return {
      loading: false,

      // 4 drawers
      drawer: false,
      bl_embeddedUrlDialog: false,
      bl_richTextDialog: false,
      bl_buttonDrawer: false,

      elements: [],
      configs: null,

      isResizing: false,
      minWidth: 100,
      minHeight: 100,
      maxWidth: 900,
      maxHeight: 900,
      slots: [],
      activeEleIndex: null,
      searchString: '',
      selectedForm: null,
      charts: [],
      selectedApp: null,
      selectedCharts: []

    }
  },
  watch: {
    currentPanel () {
      this.fetchPanelInfo()
    },
    selectedForm (val){      
      var appId = this.$route.params.id
      this.charts = []
      this.fetchCharts(appId, val)
      this.showingChartMenu = false
      this.$nextTick(()=>{
        this.showingChartMenu = true
      })
    },
    hasChange (val) {
      this.$store.commit('UPDATE_PANEL_CONFIG_CHANGED', {
        configChanged: val
      })
    }
  },
  computed: {
    lastItemId () {
      const result = this.elements.reduce((max, item) => {
        return item.i > max ? item.i : max
      }, 0)
      console.log('PanelBuilder :: computed(lastItemId) result = ' + result)
      return result
    },
    panelWidgetGroups () {
      const vm = this
      return [
        // basic section
        {
          title: vm.$t('widgets.basic'),
          widgets: [
            {
              label: vm.$t('panel.statisticsChart'),
              mdiIcon: 'mdi-chart-bar',
              key: 'charts'
            },
            // {
            //   label: vm.$t('panel.buttons'),
            //   mdiIcon: 'mdi-alpha-b-box-outline',
            //   key: 'buttons'
            // },
            {
              label: vm.$t('panel.richText'),
              mdiIcon: 'mdi-format-font',
              key: 'richText'
            },
            {
              label: vm.$t('panel.embeddedUrl'),
              mdiIcon: 'mdi-code-tags',
              key: 'embeddedUrl'
            }
          ]
        },
      ]
    },
    currentModule () {
        const vm = this
        return this.$store.getters.currentModule
    },
    filteredModuleMenu(){
        if(this.searchString=='')
            return this.currentModule.menu.filter(item=>item.type=='form')
        else
            return this.currentModule.menu.filter(item=>item.type=='form' && (item.title.toUpperCase()).includes(this.searchString.toUpperCase()))
    },
    selectedFormName(){
        if(this.selectedForm){
            return this.currentModule.menu.find(item=>item._id == this.selectedForm).title
        }else{
            return ''
        }
    },
    currentPanel () {
      return this.$store.getters.currentPanel
    },
    hasChange () {
      const vm = this
      let result = false
      if (vm.currentPanel) {
        result = JSON.stringify(vm.currentPanel.elements) !== JSON.stringify(vm.elements) ||
        JSON.stringify(vm.currentPanel.configs) !== JSON.stringify(vm.configs)
      }
      return result
    }
  },
  created () {
    EventBus.$on('savePanel', this.savePanel)
  },
  beforeDestroy () {
    EventBus.$off('savePanel')
  },
  mounted() {
    const vm = this
    vm.fetchPanelInfo()

    for(var i = 1; i<=12; i++){
        var slot = document.querySelector('#slot-' + i)
        if(slot){
            let obj = {
                top: slot.offsetTop,
                left: slot.offsetLeft
            }
            
            this.slots.push(obj)
        }
    }
  },
  methods: {
    async savePanel() {
      const vm = this
      let elements = vm.elements.map(item=>{
        return {
          ...item,
          value: item.type==='chart'? item.value._id:item.value
        }
      })
      EventBus.$emit('showSpinner')
      const postData = {
        urlCommand: '/panels/save',
        data:{
            panelId: vm.currentPanel._id,
            elements: elements
        }
      }
      await vm.$store.dispatch('AUTH_POST', postData).then(
        () => {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_panel_mani",
            data: {
              module_name: this.currentPanel.label,
              func_name: this.$sensors.events.plus_panel_mani.SAVE_PANEL,
              form_id: this.currentPanel._id
            }
          });
          vm.$store.dispatch('SET_CURRENT_PANEL_PROPERTY', {
            propertyKey: 'elements',
            propertyValue: JSON.parse(JSON.stringify(vm.elements))
          })
          EventBus.$emit('hideSpinner')
          vm.showSuccess("messages.savedSuccessfully");
        }
      ).catch(err=>{
        this.$toast.error(this.$t("errors.savePanel"))
        EventBus.$emit('hideSpinner')
      })
    },
    fetchPanelInfo () {
      const vm = this
      if (vm.currentPanel) {
        console.log('fetchPanelInfo :: vm.currentPanel: ', vm.currentPanel)
        vm.elements = JSON.parse(JSON.stringify(vm.currentPanel.elements))
        vm.elements = vm.elements.filter(ele=>ele.type!="btn")
        vm.configs = JSON.parse(JSON.stringify(vm.currentPanel.configs))
      }
    },

    openDrawer (key) {
      console.log('openDrawer :: key = ' + key)
        switch(key){
            case 'charts':
                this.drawer = true
                break
            case 'buttons':
                this.bl_buttonDrawer = true
                break
            case 'richText':
                this.bl_richTextDialog = true
                break
            case 'embeddedUrl':
                this.bl_embeddedUrlDialog = true
                break
        }
    },

    onCommandHandler (payload) {
      const vm = this
      console.log('PanelBuilder.onCommandHandler :: payload: ', payload)
      switch (payload.command) {
        case 'widgetClicked':
          vm.openDrawer(payload.widget.key)
          break
      }
    },
    mousedown(index, e) {
      //get the initial mouse corrdinates and the position coordinates of the elementdiv = document.querySelector('.item'),
      let vm = this
      this.activeEleIndex = index
      const mousemove = function(e) {
        //get horizontal and vertical distance of the mouse move
        let newX = prevX - e.clientX, //negative to the right, positive to the left
            newY = prevY - e.clientY; //negative to the bottom, positive to the top
        let newWidth = div.offsetWidth
        let newHeight = div.offsetHeight
        let newStartX = prevLeft - newX
        let newStartY = prevTop - newY
        let newEndX = prevLeft - newX + newWidth
        let newEndY = prevTop - newY + newHeight

        var offsetX = 0
        var offsetY = 0
        vm.elements.forEach((item, index, array)=>{
          if(index == vm.activeEleIndex) return
          let startX = item.left
          let endX = item.left + item.width
          let startY = item.top
          let endY = item.top + item.height

          if((newStartX>=startX && newStartX<=endX || newEndX >=startX && newEndX<=endX)&&
            (newStartY>=startY && newStartY<=endY || newEndY >=startY && newEndY<=endY)
          ){
            console.log('hit')
          }
        })
        
        div.style.left = prevLeft - newX + 'px';
        div.style.top = prevTop - newY + 'px';
      }

      const mouseup = function(e) {
          window.removeEventListener('mousemove', mousemove);
          window.removeEventListener('mouseup', mouseup);
          var endLeft = div.offsetLeft
          var endTop = div.offsetTop
          
          console.log(endLeft)
          var lefts = vm.slots.map(item=>item.left)

          var closest = lefts.reduce(function(prev, curr) {
              return (Math.abs(curr - endLeft) < Math.abs(prev - endLeft) ? curr : prev);
          })
          
          console.log(closest)
          
          // check top area
          let left = div.offsetLeft
          let top = div.offsetTop
          let height = div.offsetHeight
          let width = div.offsetWidth

          let move2Top = vm.slots[0].top

          let SortedEles =  JSON.parse(JSON.stringify(vm.elements))
          SortedEles.splice(vm.activeEleIndex,1)
          SortedEles = SortedEles.sort((a, b) => a.top > b.top && 1 || -1)

          SortedEles.forEach((item, index, array)=>{
              if((closest>=item.left && closest<=item.left+item.width || closest+width >=item.left && closest+width<=item.left+item.width)
              ||(item.left>=closest && item.left+item.width<=closest+width)
              ){
                  if(move2Top>=item.top && move2Top<=item.top+height || move2Top+height >=item.top && move2Top+height<=item.top+height){
                      move2Top = item.top + item.height + 10
                  }
              }
          })
          
          console.log(move2Top)

          div.style.left = closest + 'px';
          div.style.top = move2Top + 'px';
          
          vm.elements[vm.activeEleIndex].left = div.offsetLeft
          vm.elements[vm.activeEleIndex].top = move2Top
          vm.elements[vm.activeEleIndex].height = div.offsetHeight
          vm.elements[vm.activeEleIndex].width = div.offsetWidth
      }


      var div =  e.target
      let prevX = e.clientX,
          prevY = e.clientY,
          rect = div.getBoundingClientRect(),
          prevLeft = rect.left,
          prevTop  = rect.top;

      if(!this.isResizing && !e.target.classList.contains('content')) {
          window.addEventListener('mousemove', mousemove);
          window.addEventListener('mouseup', mouseup);
      }
      
  },
    mousedown2(e){
        let vm =this
        var div = e.target.parentNode
        let prevX = e.clientX,
                prevY = e.clientY,
                currentResizer = e.target,
                rect = div.getBoundingClientRect(),
                prevLeft = rect.left,
                prevTop  = rect.top,
                newWidth,
                newHeight;
            
        this.isResizing = true;
        
        const mousemove = function(e){
            let newX = prevX - e.clientX,
            newY = prevY - e.clientY;
            if (currentResizer.classList.contains('bottom-right')) {
                newWidth = rect.width - newX;
                newHeight = rect.height - newY;
                if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                    div.style.width = newWidth + 'px';
                }
                if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                    div.style.height = newHeight + 'px';
                }
                
            }
            else if (currentResizer.classList.contains('bottom-left')) {
                newWidth = rect.width + newX;
                newHeight = rect.height - newY;

                if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                    div.style.left = prevLeft - newX + 'px';
                    div.style.width = newWidth + 'px';
                } 
                if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                    div.style.height = newHeight + 'px';
                }
            }
            else if (currentResizer.classList.contains('top-right')) {
                newWidth = rect.width - newX;
                newHeight = rect.height + newY;
                
                if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                    div.style.width = newWidth + 'px';
                }
                if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                    div.style.top = prevTop - newY + 'px';
                    div.style.height = newHeight + 'px';
                }
                
            }
            else if (currentResizer.classList.contains('top-left')) {
                newWidth = rect.width + newX;
                newHeight = rect.height + newY;
                
                if (newWidth > vm.minWidth && newWidth < vm.maxWidth) {
                    div.style.left = prevLeft - newX + 'px';
                    div.style.width = newWidth + 'px';
                }
                if (newHeight > vm.minHeight && newHeight < vm.maxHeight) {
                    div.style.top = prevTop - newY + 'px';
                    div.style.height = newHeight + 'px';
                }
            }
        }

        const mouseup = function(){
            vm.isResizing = false;
            window.removeEventListener('mousemove', mousemove);
            window.removeEventListener('mouseup', mouseup);
            
            var endLeft = div.offsetLeft
            var endRight = div.offsetLeft + div.offsetWidth

            var lefts = vm.slots.map(item=>item.left)

            var closestLeft = lefts.reduce(function(prev, curr) {
                return (Math.abs(curr - endLeft) < Math.abs(prev - endLeft) ? curr : prev);
            })
            var closestRight= lefts.reduce(function(prev, curr) {
                return (Math.abs(curr - endRight) < Math.abs(prev - endRight) ? curr : prev);
            })

            console.log('closestLeft', closestLeft)
            // console.log('closestRight', closestRight)

            
            let left = closestLeft
            let top = div.offsetTop
            let height = div.offsetHeight
            let width = closestRight - closestLeft

            div.style.left = left + 'px';
            div.style.top = top + 'px';
            div.style.height = height + 'px';
            div.style.width = width + 'px';
        }

        window.addEventListener('mousemove', mousemove);
        window.addEventListener('mouseup', mouseup);
          
    },

    addRect(type){
        let obj = {"x":0,"y":0,"w":2,"h":2,"i":type, static: false}
        this.elements.push(obj)
        this.drawer = false
    },
    async fetchCharts(appId, formId){
      const getParams = {
          urlCommand: '/chart/get/'+ 'private' +'/'+ appId + '/' + formId
      }
      await this.$store.dispatch('AUTH_GET', getParams)
          .then(response => {
              console.log(response)
              this.charts = response
          })
          .catch(err => {
              console.log(err)
      })
    },
    selectChart(chart){
      const nextItemId = this.lastItemId + 1
      console.log('selectChart :: nextItemId = ' + nextItemId)
      let obj = {
          "x":0,"y":0,"w":5,"h":8,"i":nextItemId,
          "type":"chart", 
          "formId":chart._id, 
          "value": chart, 
          "bl_title": false,
      }
      this.elements.push(obj)
      this.drawer = false
    },
    update_elements(payload){
      let obj
      if(payload.type == 'copy'){
        var ele = payload.val
        ele.i = this.lastItemId + 1
        this.elements.push(ele)
      }else if(payload.type == 'delete'){
        var i = payload.i
        const index = this.elements.findIndex(item=>item.i === i);
        if (index > -1) this.elements.splice(index, 1)
      }else if(payload.type == 'add'){
        switch(payload.itemType){
            case 'richText':
                obj = {
                    "x":0,"y":0,"w":5,"h":8,"i":this.lastItemId+1, 
                    "type":"richText",
                    "value": payload.val,
                    "bl_title": false,
                    "title": "",
                    static: false
                }
                this.elements.push(obj)
            break
            case 'btn':
                obj = {
                    "x":0,"y":0,"w":5,"h":8,"i":this.lastItemId+1, 
                    "type":"btn",
                    "btns": payload.btns,
                    "numPerRow": payload.numPerRow, 
                    "align": payload.align, 
                    "title": payload.title,
                    "bl_title": false,
                    "static": false,
                    "title": "",
                }
                this.elements.push(obj)
            break
            case 'embeddedUrl':
                obj = {
                    "x":0,"y":0,"w":5,"h":8,"i":this.lastItemId+1, 
                    "type":"embeddedUrl",
                    "value": payload.iframeUrl,
                    "bl_title": false,
                    "static": false,
                    "title": "",
                }
                this.elements.push(obj)
            break
        }
      }
    }
  }
}
</script>
<style>
  .panel-builder .widget-list {
    background-color: rgba(0, 0, 0, 0.05);
    width: 300px;
  }
  .panel-builder div.cursor-pointer input {
    cursor: pointer !important;
  }
</style>
