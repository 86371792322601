/*
* Preload all of static images
* Terry Chan
* 12/05/2021
*/
const WorkflowEvent = [
    {
        name: 'workflow.label.table',
        trigger: [
            {
                name: 'workflow.options.trigger.worksheet',
                color: '#ffa340',
                icon: 'mdi-table-large',
                type: 'form',
            },
        ],
    },
    {
        name: 'workflow.label.time',
        trigger: [
            {
                name: 'workflow.options.trigger.timer',
                color: '#2196f3',
                icon: 'mdi-alarm',
                type: 'timer',
            },
            {
                name: 'workflow.options.trigger.date',
                color: '#2196f3',
                icon: 'mdi-calendar-today',
                type: 'date'
            },
        ],
    }
]
export default WorkflowEvent;
