<template>
  <layout :title="title" :description="description" :card="step === 1">
    <template v-slot:header-content>
      <v-stepper :value="step" class="elevation-0">
        <v-stepper-header>
          <v-stepper-step step="1">
            {{ $t("createTeam.createTeam") }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2">
            {{ $t("createTeam.done") }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
    <template v-slot:card-content>
      <v-card-text class="pa-0">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="create-team-form text-start"
        >
          <h6>{{ $t("createTeam.personalInfo") }}</h6>
          <div>
            <label
              >{{ $t("createTeam.displayName")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-text-field
              v-model="teamInfo.displayName"
              :counter="100"
              :rules="[RULE_REQUIRED, RULE_TEXTLIMIT(100)]"
              :placeholder="
                $t('messages.pleaseInput', [
                  $t('createTeam.displayName').toLowerCase(),
                ])
              "
              validate-on-blur
              dense
              outlined
            ></v-text-field>
          </div>
          <h6>{{ $t("createTeam.teamInfo") }}</h6>
          <div>
            <label
              >{{ $t("createTeam.teamName")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-text-field
              v-model="teamInfo.name"
              :counter="100"
              :rules="[RULE_REQUIRED, RULE_TEXTLIMIT(100)]"
              :placeholder="
                $t('messages.pleaseInput', [
                  $t('createTeam.teamName').toLowerCase(),
                ])
              "
              validate-on-blur
              dense
              outlined
            ></v-text-field>
          </div>
          <div>
            <label
              >{{ $t("createTeam.employeeNumber")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-text-field
              v-model="teamInfo.employeeNumber"
              :counter="20"
              :rules="[RULE_REQUIRED, RULE_TEXTLIMIT(20)]"
              :placeholder="
                $t('messages.pleaseInput', [
                  $t('createTeam.employeeNumber').toLowerCase(),
                ])
              "
              validate-on-blur
              dense
              outlined
            ></v-text-field>
          </div>
          <div>
            <label
              >{{ $t("createTeam.countries")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-autocomplete
              v-model="teamInfo.countryId"
              :rules="[RULE_REQUIRED]"
              :items="countries"
              :placeholder="
                $t('messages.pleaseChoose', [
                  $t('createTeam.countries').toLowerCase(),
                ])
              "
              item-value="id"
              item-text="name"
              dense
              outlined
            ></v-autocomplete>
          </div>
          <div>
            <label
              >{{ $t("createTeam.timezone")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-autocomplete
              v-model="teamInfo.timezone"
              :rules="[RULE_REQUIRED]"
              :items="timezones"
              item-value="id"
              item-text="name"
              :placeholder="
                $t('messages.pleaseChoose', [
                  $t('createTeam.timezone').toLowerCase(),
                ])
              "
              validate-on-blur
              dense
              outlined
            ></v-autocomplete>
          </div>
          <div>
            <label
              >{{ $t("createTeam.currencies")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-autocomplete
              v-model="teamInfo.currencyCode"
              :rules="[RULE_REQUIRED]"
              :items="currencies"
              item-value="code"
              item-text="name"
              :placeholder="
                $t('messages.pleaseChoose', [
                  $t('createTeam.currencies').toLowerCase(),
                ])
              "
              validate-on-blur
              dense
              outlined
              :filter="filterCurrencies"
            >
              <template v-slot:selection="{ item }">
                {{ item.code }} - {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.code }} - {{ item.name }}
              </template>
            </v-autocomplete>
          </div>
          <div>
            <label
              >{{ $t("createTeam.industry")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-autocomplete
              v-model="teamInfo.industryId"
              :rules="[RULE_REQUIRED]"
              :items="industries"
              item-value="id"
              item-text="name"
              :placeholder="
                $t('messages.pleaseChoose', [
                  $t('createTeam.industry').toLowerCase(),
                ])
              "
              auto-select-first
              dense
              outlined
            >
            </v-autocomplete>
          </div>
          <div>
            <label
              >{{ $t("createTeam.scale")
              }}<span class="red--text mb-0">*</span></label
            >
            <v-select
              v-model="teamInfo.scale"
              :rules="[RULE_REQUIRED]"
              :items="scales"
              item-value="key"
              item-text="text"
              :placeholder="
                $t('messages.pleaseChoose', [
                  $t('createTeam.scale').toLowerCase(),
                ])
              "
              dense
              outlined
            >
            </v-select>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="#6B7785" @click="reset">{{
          $t("buttons.reset")
        }}</v-btn>
        <v-btn color="primary" depressed @click="onSubmit">{{
          $t("buttons.next")
        }}</v-btn>
      </v-card-actions>
    </template>
    <template v-slot:prepend-card v-if="step === 2">
      <h1>{{ $t("createTeam.completeSetting") }}</h1>
      <p>{{ $t("createTeam.finishMsg") }}</p>
      <p>{{ $t("createTeam.finishMsg2") }}</p>
      <v-btn color="primary" @click="goToHome">{{
        $t("createTeam.startUsing")
      }}</v-btn>
    </template>
  </layout>
</template>
<script>
import Layout from "./comps/Layout";
import moment from "moment-timezone";
import ValidationsHelper from "@/helpers/ValidationsHelper";
export default {
  components: {
    Layout,
  },

  async mounted() {
    await this.getOptions();
    this.teamInfo.countryId = this.getId(this.countries, "HKG");
    this.teamInfo.displayName = this.defaultDisplayName;
  },
  data() {
    return {
      valid: false,
      step: 1,
      teamInfo: {
        displayName: "",
        timezone: "Asia/Hong_Kong",
        currencyCode: "HKD",
        countryId: "",
        name: "",
        scale: "BETWEEN_10_TO_19",
        industryId: "",
        employeeNumber: "",
      },
      scales: [
        {
          key: "LESS_THAN_10",
          text: this.$t("createTeam.LESS_THAN_10"),
        },
        {
          key: "BETWEEN_10_TO_19",
          text: this.$t("createTeam.BETWEEN_10_TO_19"),
        },
        {
          key: "BETWEEN_20_TO_99",
          text: this.$t("createTeam.BETWEEN_20_TO_99"),
        },
        {
          key: "BETWEEN_100_TO_500",
          text: this.$t("createTeam.BETWEEN_100_TO_500"),
        },
        {
          key: "MORE_THAN_500",
          text: this.$t("createTeam.MORE_THAN_500"),
        },
      ],
      countries: [],
      currencies: [],
      industries: [],
      RULE_REQUIRED: (v) =>
        !ValidationsHelper.isEmpty(v) ||
        this.$t("messages.thisFieldIsRequired"),
      RULE_TEXTLIMIT: (limit) => (v) =>
        (typeof v === "string" &&
          ValidationsHelper.isInNumberRange(v.length, null, limit)) ||
        this.$t("messages.mustNotExceedWordLength", [limit]),
    };
  },
  methods: {
    async getOptions() {
      const getParams = {
        url: "/work/getInfo",
        method: "get",
        requireTeamId:false
      };

      const res = await this.$store.dispatch("YOOV_REQUEST", getParams);
      if (res.data) {
        this.countries = res.data.countries;
        this.currencies = res.data.currencies;
        this.industries = res.data.industries;
      }
    },
    filterCurrencies(item, queryText) {
      const textOne = item.code.toLowerCase();
      const textTwo = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    getId(arr, name) {
      return (arr.find((item) => item.code === name) || {}).id || null;
    },
    onSubmit() {
      this.$refs.form.validate();

      if (this.valid) {
        const postData = {
          url: "/work/createTeam",
          method: "post",
          data: {
            ...this.teamInfo,
          },
          requireTeamId:false
        };
        this.$store.dispatch("YOOV_REQUEST", postData).then((res) => {
          if (res.data.user && res.data.teams) {
            this.$store.dispatch("INIT_TEAM", res.data);
            this.step = 2;
          }
        });
      }
    },
    reset() {
      Object.assign(this.teamInfo, {
        displayName: "",
        timezone: "",
        currencyCode: "",
        countryId: "",
        teamName: "",
      });
      this.$refs.form.resetValidation();
    },
    goToHome() {
      this.$router.push({
        name: "myApps",
      });
    },
  },
  computed: {
    timezones() {
      let result = [];
      for (let timezone of moment.tz.names()) {
        result.push({
          id: timezone,
          name: "(GMT" + moment.tz(timezone).format("Z") + ") " + timezone,
        });
      }
      return result;
    },
    rules() {
      return [this.RULE_REQUIRED];
    },
    defaultDisplayName() {
      return this.$store.getters.user
        ? `${this.$store.getters.user.firstName} ${this.$store.getters.user.lastName}`
        : "";
    },
    title() {
      return this.step === 1 ? this.$t("createTeam.createTeam") : "";
    },
    description() {
      return this.step === 1 ? this.$t("createTeam.step1") : "";
    },
  },
};
</script>
<style lang="scss">
.create-team-form {
  h6 {
    font-size: 20px;
    text-align: left;
  }
  > div {
    margin: 24px 0px;
  }
}
</style>
