<template>
  <v-tooltip :disabled="isCorrect" bottom>
    <template v-slot:activator="{ on }">
      <v-layout v-on="on" align-center justify-space-between
        :class="{ 
          'workflow-customized-action-buttons-list-item-hide': hide,
          'workflow-customized-action-buttons-list-item-noRelease': !isCorrect,
        }"
        class="workflow-customized-action-buttons-list-item">
        <v-icon v-if="!isCorrect" size="60" class="workflow-customized-action-buttons-list-item-warning">
          mdi-alert-decagram
        </v-icon>
        <div class="workflow-customized-action-buttons-list-item-icon">
          <v-icon
            v-if="item.icon"
            :color="item.color"
            left
            size="20"
            dark
            v-text="`mdi-${item.icon}`"
          />
        </div>
        <div class="workflow-customized-action-buttons-list-item-name">
          <span :style="{ color: item.color || 'black' }">{{item.name}}</span>
          <div v-if="item.remark" 
            :style="{ color: item.color || 'black' }"
            class="workflow-customized-action-buttons-list-item-remark">
            {{item.remark}}
          </div>
        </div>
        <v-layout align-center justify-space-between class="workflow-customized-action-buttons-list-item-tools">
          <v-btn
            icon
          >
            <v-icon
              :color="$vuetify.theme.themes.light.primary"
              size="15"
              :disabled="loading"
              @click="updateStatus"
              dark
            >
              {{ !hide ? 'mdi-eye' : 'mdi-eye-off' }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
          >
            <v-icon
              :color="$vuetify.theme.themes.light.primary"
              size="15"
              :disabled="loading"
              @click="$emit('onSelect', item)"
              dark
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <RemoveButton
            :form="form"
            :item="item"
          />
        </v-layout>
      </v-layout>
    </template>
    <span>{{$t('workflow.modules.actionProcess.remark.workflowNotReady')}}</span>
  </v-tooltip>
</template>

<script>
import RemoveButton from './removeAsk';
import {
  VIEW_ACTION_BUTTON_STATUS,
} from '@/store/modules/customizedAction/action_types';
import EventBus from '@/event-bus.js'
export default {
  props: {
    item: Object,
    form: Object,
    view: Object
  },
  components: {
    RemoveButton,
  },
  computed: {
    loading() {
      return this.$store.getters.loadingAnyAction;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    hide () {
      const viewBtnSetting = this.view.customizedActionButtons.find(btn=>{
        return btn.buttonId == this.item._id
      })
      console.log("viewBtnSetting", viewBtnSetting)
      if(viewBtnSetting && viewBtnSetting.visible)
        return false
      else
        return true
      // const { display='' } = this.item;
      // return display === 'hide';
    },
    released() {
      const { workflow } = this.item;
      return !workflow || (workflow && workflow.released);
    },
    isCorrect() {
      return this.released || this.item.noWorkflow;
    },
  },
  methods: {
    updateStatus() {
      // const { hide } = this.item;
      this.$store.dispatch(VIEW_ACTION_BUTTON_STATUS, {
        appId: this.currentApp._id,
        formId: this.form._id,
        buttonId: this.item._id,
        viewId: this.view._id,
        // status: this.hide ? 'normal' : 'hide',
      }).then(res=>{
        EventBus.$emit('updateViewActionButton', res)
      });
    }
  },
}
</script>

<style scoped>
</style>