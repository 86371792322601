import {
  UPDATE_WORKFLOW_NODE,
  VALIDATE_WORKFLOW,
} from '@/store/modules/workflow/action_types';

const mixin = {
  data() {
    return {
      remarking: false,
    };
  },
  computed: {
    workflow() {
      return this.$store.getters.getWorkflowInfo;
    },
    appId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async onRemarked(remark) {
      this.remarking = false;
      this.node.remark = remark;
      await this.$store.dispatch(UPDATE_WORKFLOW_NODE, {
        info: { remark },
        node: this.node,
        workflow: this.workflow._id,
        workflowInfo: this.workflow,
        appId: this.appId,
      })
    },
    onRemark() {
      this.remarking = true;
    },
    offRemark() {
      this.remarking = false;
    }
  },
}

export default mixin
