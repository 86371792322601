<template>
  <div class="filter-box">
    <span class="triangle"></span>
    <v-row>
      <v-col>
        <label>{{$t('drawer.createdAt')}}</label>
        <v-menu min-width="auto" :close-on-content-click="false" left offset-y offset-overflow>
          <template v-slot:activator="{ on, attrs }">
            <v-input class="log-filter-input" hide-details dense
              ><div class="pa-0 v-text-field--outlined d-flex">
                <div v-on="on" v-bind="attrs" class="d-flex flex-grow-1">
                  {{ dateRange }}
                  <v-spacer />
                </div>
                <v-icon v-if="dateRange" @click="filters.date = []"
                  >mdi-close</v-icon
                >
                <v-icon v-else>mdi-calendar</v-icon>
              </div>
            </v-input>
          </template>
          <v-date-picker
            @change="assignDateRange"
            v-model="filters.date"
            no-title
            range
            show-adjacent-months
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mt-1" no-gutters>
      <v-spacer />
      <v-btn text class="mr-1" color="primary" @click="resetSearch"
        >{{$t('drawer.reset')}}</v-btn
      >
      <v-btn text color="primary" @click="onSearch"
        >{{$t('drawer.filter')}}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import {
  isArray,
  map,
} from 'lodash'
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        filters: {},
      };
    },
    methods: {
      assignDateRange(e) {
        this.$set(this.filters, "date", e.sort());
      },
      resetSearch() {
        this.filters = {};
        this.$emit('search', {});
        this.$emit('hide');

      },
      onSearch() {
        this.$emit('search', this.filters);
      },
    },
    computed: {
      dateRange() {
        return isArray(this.filters.date) && this.filters.date.length === 2
          ? map(this.filters.date, item => item.replaceAll("-", "/")).join(" - ")
          : "";
      },
    },
  }
</script>

<style scoped lang="scss">
  .filter-box {
    margin: 15px 10px 0px 10px;
    padding: 5px 10px;
    border: 1px solid $primary;

    position: relative;
    border-radius: 4px;

    .triangle {
      position: absolute;
      width: 14px;
      height: 14px;
      border-top: 1px solid $primary;
      border-left: 1px solid $primary;

      transform: rotate(45deg);
      top: -8px;
      right: 45px;
      background: #fff;
    }
  }
</style>
