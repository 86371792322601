<template>
    <div v-if="!isEditing">
        <div class="d-flex align-center my-4 mx-3">
            <div style="height:18px;width:4px;" class="teal lighten-2"></div>
            <div class="ml-2 font-weight-bold" style="font-size:14px">{{$t('mailSetting.basicSetting')}}</div>
        </div>

        <div style="overflow: scroll; height:500px">
            <senderCard
              :mailSenders="mailSenders"
              @onCommand="onCommandHandler"
            />

            <v-card class="mx-3 mt-3 elevation-0 mb-6">
                <v-btn
                    depressed
                    class="teal--text text--lighten-2"
                    style="width:100%;"
                    @click="editSender(0)"
                >
                    <v-icon small>
                        mdi-plus
                    </v-icon>
                    <span class="text--error">{{$t('mailSetting.addMailServer')}}</span>
                </v-btn>
            </v-card>
        </div>
    </div>
    <senderConfig 
      v-else
      :loading="isLoading"
      @onCommand="onCommandHandler"
      ref="senderConfig"
    />
</template>

<script>
import senderCard from './senderCard';
import senderConfig from './senderConfig';

export default {
  name: 'mailSettingsTabContent',
  components: {
    senderCard,
    senderConfig,
  },
  data () {
    return {
      isLoading: false,
      isEditing: false,
      mailSenders: []
    }
  },
  computed: {
    activeTeamId () {
      return this.$store.getters.activeTeamId
    }
  },
  created() {
    this.loadMailSenders()
  },
  methods: {
    loadMailSenders () {
        const getParams = {
            urlCommand: '/mail/all',
            options: {}
        }
        this.$store.dispatch('AUTH_GET', getParams).then(response=>{
            this.mailSenders = response
        })
    },
    editSender(id){
        this.isEditing = true
    },
    onCommandHandler(payload){
        switch(payload.command){
            case 'cancel':
                this.isEditing = false
                break
            case 'save':
                this.saveSenderConfig(payload.form, payload.configId, payload.visible)
                break
            case 'delete':
                this.deleteSenderConfig(payload.senderId)
                break
            case 'edit':
                this.editSenderConfig(payload.sender)
                break
            case 'setVisible':
                this.saveSenderConfig(payload.sender.configs, payload.sender._id, payload.sender.visible)
                break
            case 'testConnection':
                this.testSenderConfig({
                    form: payload.form,
                    configId: payload.configId,
                });
                break;
        }
    },
    saveSenderConfig(form, configId, visible){
        this.$store .dispatch('SAVE_SENDER_CONFIG', {
            form,
            teamId: this.activeTeamId,
            configId,
            visible
        }).then(res=>{
            const {_id} = res;
            const index = this.mailSenders.findIndex(sender=>sender._id === _id);
            if (index > -1){
                this.mailSenders.splice(index, 1, res);
            }else  this.mailSenders.push(res)
            this.isEditing = false
        })
    },
    deleteSenderConfig(senderId){
        this.$store .dispatch('DELETE_SENDER_CONFIG', {
            configId: senderId
        }).then(response=>{
            if(response.status){
                var pos = this.mailSenders.findIndex(item=>item._id == senderId)
                this.mailSenders.splice(pos, 1)
            }
        })
    },
    editSenderConfig(sender){
        this.isEditing = true
        this.$nextTick(()=>{
            this.$refs.senderConfig.rows.flat().forEach((field,index,array)=>{
                if(sender.configs.hasOwnProperty(field.key)){
                    array[index].value = sender.configs[field.key]
                }
            })
            this.$refs.senderConfig.mode = 'edit'
            this.$refs.senderConfig.configId = sender._id
            this.$refs.senderConfig.visible = sender.visible
        })            
    },
    testSenderConfig(payload) {
        this.isLoading = true;

        const vm = this;
        const title = vm.$t('buttons.testConnection');
        if (payload?.form?.host === '' || !payload?.form?.port) {
            this.isLoading = false;
            vm.$refs.senderConfig.errorDialog(title, vm.$t('messages.hostPortNotFound'));
        } else {
            this.$store.dispatch('TEST_SENDER_CONFIG', payload)
                .then(res => {
                    this.isLoading = false;
                    if (res.connected) {
                        vm.$refs.senderConfig.simpleDialog(title, vm.$t("messages.connectionSuccess"));
                    } else {
                        vm.$refs.senderConfig.errorDialog(title, vm.$t("messages.connectionError"));
                    }
                })
                .catch(err => {
                    this.isLoading = false;
                    vm.$refs.senderConfig.errorDialog(title, vm.$t("messages.connectionError"));
                })
        }
            
    },
  }
}
</script>
