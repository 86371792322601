<template>
  <layout :title="$t('landing.welcome')" :description="$t('landing.description')" card>
    <template v-slot:card-content>
      <Cell
        class="mb-4"
        :text="$t('createTeam.createTeam')"
        icon="mdi-account-group-outline"
        @click.native="goToRoute('CreateTeam')"
      />
      <!-- <Cell
        class="mb-4"
        text="以公司/團隊ID加入"
        icon="mdi-office-building-outline"
        @click.native="goToRoute('JoinTeam')"
      /> -->
    </template>
  </layout>
</template>
<script>
import Layout from "./comps/Layout.vue";
import Cell from "./comps/Cell.vue";
export default {
  components: {
    Layout,
    Cell,
  },
  methods:{
      goToRoute(route){
          this.$router.push({
              name:route
          })
      }
  }
};
</script>
