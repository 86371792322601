<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :required="required"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :isHidden="isHidden"
    :noIcon="noIcon"
    :tooltipText="description"
  >
    <v-text-field
      v-if="isLayout || editAnyway"
      dense
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      hide-details
      :value="inputValue"
      :readonly="isReadOnly"
      background-color="white"
      @input="(val) => updateValue(val)"
      class="workflow-customized-form-record-element"
      outlined
    />
    <pre
      v-else
      style="border-bottom: 1px dashed darkgray;overflow:auto;min-height:30px;white-space:normal"
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      >{{ value }}</pre
    >
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import textCombinationMixin from "@/mixins/textCombinationMixin";

export default {
  name: "inputWidgetTextCombination",
  mixins: [mixin, textCombinationMixin],
  components: {
    inputWidgetContainer,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data() {
    return {
      userValue: "",
    };
  },
  watch: {
    recordData: {
      handler() {
        this.inputValue = this.getDefaultValue();
      },
      deep: true,
    },
    relatedTableData: {
      handler() {
        this.inputValue = this.getDefaultValue();
      },
      deep: true,
    }
  },
  computed: {},
  mounted() {
    this.inputValue = this.getDefaultValue();
  },
  methods: {
    getDefaultValue() {
      return this.isLayout || this.editAnyway
        ? ""
        : this.getCombinedField(
            this.fieldInfo.properties.selectFieldCombination,
            this.recordData,
            this.fieldInfo,
            this.fieldInfos,
            this.relatedTableData,
            this.relatedTableInfos
          ) || "";
    },
  },
};
</script>

<style scoped>
.is-layout {
  padding-bottom: 0 !important;
}

.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
</style>
