<template>
  <vxe-table-column v-bind="colDef">
    <template v-slot="{row}">
      <v-icon left
        @click="showTableDrawer(row._id)">mdi-table</v-icon>
      <v-chip class="py-1" v-if="row[colDef.field] && row[colDef.field].length>0" small>
          {{ row[colDef.field].length }}</v-chip>

<!--      <div class="vxe-cell-child-table">
      row[colDef.fieldId].length
      </div> -->
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>

  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  components: {
    summaryCell
  },
  methods: {
    showTableDrawer (recordId) {
      const vm = this
      vm.$nextTick(() => {
        vm.$emit('onCommand', {
          command: 'showChildTableDrawer',
          formInfo: vm.formInfo,
          fieldInfo: vm.fieldInfo,
          recordId: recordId,
        })
      })
    }
  }
}
</script>
