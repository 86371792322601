<template>
  <v-dialog persistent width="540" v-model="showingDialog">
    <v-card>
      <dialog-header
        :label="$t('optionGroup.valueAssignment')"
        @close="showingDialog = false"
      ></dialog-header>
      <v-card-text class="pt-2">
        <span>
         <i18n path="optionGroup.valueDescription">
           <span class="font-weight-bold primary--text ">{{$t('widgets.expression')}}</span>
           <span class="font-weight-bold primary--text ">{{$t('optionGroup.valueDescriptionSlot1')}}</span>
         </i18n>
        </span>
        <v-switch
          hide-details
          dense
          :label="$t('optionGroup.activate')"
          v-model="active"
          style="width:150px"
        />
        <table class="option-value-table">
          <colgroup>
            <col />
            <col :class="active ? `` : `disabled-column`" />
          </colgroup>
          <tr>
            <th scope="col">{{$t('widgets.properties.option')}}</th>
            <th scope="col">{{$t('optionGroup.value')}}</th>
          </tr>
          <tr v-for="option in localOptions" :key="option._id">
            <td>{{ option.label }}</td>
            <td :class="active ? `` : `disabled`">
              <input
              v-if="active"
                class="option-value-input"
                v-model="option.value"
                :placeholder="$t('optionGroup.inputPlaceholder')"
                @blur="setValue(option, option.value)"
                autocomplete="false"

              />
            </td>
          </tr>
        </table>
      </v-card-text>
      <dialog-actions @onCommand="onCommandHandler"></dialog-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";

export default {
  components: {
    dialogHeader,
    dialogActions,
  },
  props: {
    value: Boolean,
    options: Array,
    useValue: Boolean
  },
  data() {
    return {
      active: this.useValue,
      localOptions: JSON.parse(JSON.stringify(this.options)),
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    setValue(option, initialValue) {

      if (typeof initialValue === 'number') return;
      let result = initialValue && initialValue.match(/^(-)?\d+(\.\d+)?$/);

      result = result && result.length > 0 ? parseFloat(result[0]) : null;

      this.$set(option, 'value', result)

    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "cancel":
          this.showingDialog = false;
          break;
        case 'ok':
          this.$emit("confirm", {
            useValue: this.active,
            options: this.localOptions
          })
          this.showingDialog = false;
          break;
        
      }
    },
  },
};
</script>
<style scoped lang="scss">
.option-value-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  td,
  th {
    border: 1px solid rgb(100, 100, 100);
    height: 36px;
    line-height: 36px;
  }

  td {
    text-align: center;
  }

  .option-value-input {
    width: 100%;
    border-radius: 0px;
    padding: 0px 10px;
    outline-color: $primary;
  }
  .disabled-column {
        background-color: #e9e9e9;
  }
  .disabled {

    cursor: not-allowed;
  }
}
</style>
