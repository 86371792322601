import moment from "moment";

const getStartEndDateByPeriod = (option) => {
    let d = new moment()
    let startDate, endDate
    switch(option){
      case 'TODAY':{
        startDate = d.clone().startOf('day')
        endDate = d.clone().endOf('day')
        break
      }
      case 'YESTERDAY':{
        startDate = d.clone().subtract(1, "d").startOf('day')
        endDate = d.clone().subtract(1, "d").endOf('day')
        break
      }
      case 'TOMORROW':{
        startDate = d.clone().add(1, "d").startOf('day')
        endDate = d.clone().add(1, "d").endOf('day')
        break
      }
      case 'THISWEEK':{
        startDate = d.clone().startOf('week')
        endDate = d.clone().endOf('week')
        break
      }
      case 'LASTWEEK':{
        startDate = d.clone().subtract(1, 'W').startOf('week')
        endDate = d.clone().subtract(1, 'W').endOf('week')
        break
      }
      case 'NEXTWEEK':{
        startDate = d.clone().add(1, 'W').startOf('week')
        endDate = d.clone().add(1, 'W').endOf('week')
        break
      }
      case 'THISMONTH':{
        startDate = d.clone().startOf('month')
        endDate = d.clone().endOf('month')
        break
      }
      case 'LASTMONTH':{
        startDate = d.clone().subtract(1, 'M').startOf('month')
        endDate = d.clone().subtract(1, 'M').endOf('month')
        break
      }
      case 'NEXTMONTH':{
        startDate = d.clone().add(1, 'M').startOf('month')
        endDate = d.clone().add(1, 'M').endOf('month')
        break
      }
      case 'THISQUARTER':{
        startDate = d.clone().startOf('quarter')
        endDate = d.clone().endOf('quarter')
        break
      }
      case 'LASTQUARTER':{
        startDate = d.clone().subtract(1, 'Q').startOf('quarter')
        endDate = d.clone().subtract(1, 'Q').endOf('quarter')
        break
      }
      case 'NEXTQUARTER':{
        startDate = d.clone().add(1, 'Q').startOf('quarter')
        endDate = d.clone().add(1, 'Q').endOf('quarter')
        break
      }
      case 'THISYEAR':{
        startDate = d.clone().startOf('year')
        endDate = d.clone().endOf('year')
        break
      }
      case 'LASTYEAR':{
        startDate = d.clone().subtract(1, "year").startOf('year')
        endDate = d.clone().subtract(1, "year").endOf('year')
        break
      }
      case 'NEXTYEAR':{
        startDate = d.clone().add(1, "year").startOf('year')
        endDate = d.clone().add(1, "year").endOf('year')
        break
      }
      default:{
        if(option.substring(0,6) === "WITHIN"){
          const [, period] = option.split('_')
          let [periodStart, periodEnd] = period.split(' - ')
          if(periodStart && periodEnd){
            if(periodStart>periodEnd){
              var temp = periodStart
              periodStart = periodEnd
              periodEnd = temp
            }
          }else if(!periodEnd){
            periodEnd = periodStart
          }
          startDate = new moment(periodStart).startOf('day')
          endDate = new moment(periodEnd).endOf('day')
        }
        switch(option.substring(0,4)){
            case "NEXT":{
                const [, days, includeToday] = option.split('_')
                endDate = d.clone().add(days, "d").endOf('day')
                if(includeToday === 'true'){
                  startDate = d.clone().startOf('day')
                }else{
                  startDate = d.clone().add(1, "d").startOf('day')
                }
              break
            }
            case "PAST":{
              const [, days, includeToday] = option.split('_')
                startDate = d.clone().subtract(days, "d").startOf('day')
                if(includeToday === 'true'){
                  endDate = d.clone().endOf('day')
                }else{
                  endDate = d.clone().subtract(1, "d").endOf('day')
                }
                break
            }
            case "DATE":{
              const [, specDate] = option.split('_')
              d = new moment(specDate)
              startDate = d.clone().startOf('day')
              endDate = d.clone().endOf('day')
              break
            }
        }
      }
    }
    startDate = startDate.format('YYYY-MM-DD HH:mm:ss')
    endDate = endDate.format('YYYY-MM-DD HH:mm:ss')
    return [startDate, endDate]
}

export {
    getStartEndDateByPeriod
}