<template>
  <div>
    <DataSourceSelect
      :value="setting.dataSource"
      :label="$t('workflow.modules.deleteRecord.label.selectDataSource')"
      name="dataSource"
      :placeholder="$t('workflow.modules.deleteRecord.placeholder.currentNodeOutput')"
    />
  </div>
</template>

<script>
import DataSourceSelect from './../shared/dataSourceSelect';
import Mixin from './../mixin';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    DataSourceSelect,
  },
};

</script>

<style scoped>

</style>
