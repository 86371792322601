<template>
  <vxe-grid border v-bind="attrs" ref="vxeTable">
    <template v-slot:icon="{ row }">
      <v-icon
        size="18"
        style="cursor: pointer"
        @click="$emit('onCommand', {command:'edit', row})"
      >
        mdi-square-edit-outline
      </v-icon>
    </template>
    <template v-slot:action="{ row }">
      <a v-if="row.register === 'UNREGISTERED'" :class="{
          'disabled-a-tag': (!row.user.email)
      }" @click="$emit('onCommand', {
        command:'invite',
        row
      })">{{$t('workAdmin.sendInvitation')}}</a>
      <a v-else  @click="$emit('onCommand', {
        command:'editBind',
        row
      })">{{$t('workAdmin.editBind')}}</a>
    </template>
  </vxe-grid>
</template>
<script>
export default {
  props: {
    attrs: Object,
  },
};
</script>
<style lang="scss" scoped>
.disabled-a-tag {
  opacity: .4;
  cursor: default !important;
  pointer-events: none;
}
</style>
