<template>
  <div class="widget-panel flex-grow-1 px-1 pt-1 d-flex flex-column">
    <perfect-scrollbar class="flex-grow-1" style="height:0;">
      <div style="background-color:white;">
        <v-expansion-panels
            light
            :accordion="accordion"
            :popout="popout"
            :inset="inset"
            :multiple="multiple"
            :focusable="focusable"
            :disabled="disabled"
            :readonly="readonly"
            :flat="flat"
            :value="[0,1,2,3]"
            :hover="hover"
            :tile="tile">
          <widget-panel-group v-for="(widgetGroup, i) in widgetGroups"
                              :widgetGroup="widgetGroup"
                              :key="i"
                              :itemsPerRow="itemsPerRow"
                              :command="command"
                              @onCommand="onCommandHandler"
                              :selected="selectedWidget"></widget-panel-group>
        <!--</v-expansion-panel>-->
      </v-expansion-panels>
    </div>
  </perfect-scrollbar>
</div>
</template>

<script>
import widgetPanelGroup from './WidgetPanelGroup'

export default {
  components: {
    widgetPanelGroup
  },
  props: {
    command: String,
    widgetGroups: Array,
    itemsPerRow: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      selectedWidget: null,

      // expansion panel
      accordion: true,
      popout: false,
      inset: false,
      multiple: true,
      focusable: false,
      disabled: false,
      readonly: false,
      flat: false,
      hover: true,
      tile: true
    }
  },
  methods: {
    onCommandHandler (payload) {
      this.$emit('onCommand', payload)
    }
  }
}
</script>