var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"height":"100vh","width":"100%","background-color":"white"},attrs:{"tile":"","text":""}},[_c('v-card-text',{},[(_vm.ganttSetting)?_c('div',{staticClass:"pa-12 d-flex flex-column"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-0",staticStyle:{"border-bottom":"2px solid teal"}},[_vm._v(_vm._s(_vm.$t('gantt.initSettings')))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"0"}},[_c('div',[_c('v-radio-group',{staticClass:"radio-margin-top-0 d-flex",attrs:{"row":"","dense":"","hide-details":""},model:{value:(_vm.ganttSetting.projectRelation),callback:function ($$v) {_vm.$set(_vm.ganttSetting, "projectRelation", $$v)},expression:"ganttSetting.projectRelation"}},[_c('v-radio',{staticClass:"ma-0",staticStyle:{"width":"50%"},attrs:{"label":_vm.$t('gantt.useCascade'),"color":"teal","value":"PARENT_CHILD"}}),_c('v-radio',{staticClass:"ma-0",staticStyle:{"width":"50%"},attrs:{"label":_vm.$t('gantt.useRelation'),"color":"teal","value":"MULTIPLE_RELATED"}})],1)],1),(_vm.ganttSetting.projectRelation=='PARENT_CHILD')?_c('div',{staticClass:"mt-2 ml-1"},[(!_vm.ganttSetting.projectCascade.length)?_c('v-select',{staticStyle:{"font-size":"13px"},attrs:{"items":_vm.cascadeFields,"item-text":"label","item-value":"fieldId","height":"27","dense":"","hide-details":"","menu-props":{offsetY: true, contentClass: 'denseList'},"filled":""},on:{"input":_vm.handleCascadeInput},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('gantt.parentLevel'))+" ")]),_c('span',[_vm._v(_vm._s(item[0].label)+" - ")]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('gantt.childLevel'))+" ")]),_c('span',[_vm._v(_vm._s(item[1].label))])])])]}}],null,false,2954319910),model:{value:(_vm.ganttSetting.projectCascade),callback:function ($$v) {_vm.$set(_vm.ganttSetting, "projectCascade", $$v)},expression:"ganttSetting.projectCascade"}}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"teal lighten-2 white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getLabelById(_vm.ganttSetting.projectCascade))+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-auto",attrs:{"small":""},on:{"click":function($event){_vm.ganttSetting.projectCascade=[]}}},[_vm._v(" mdi-close ")])],1)]}}],null,false,2904116154)})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ganttSetting.projectRelation=='MULTIPLE_RELATED'),expression:"ganttSetting.projectRelation=='MULTIPLE_RELATED'"}],staticClass:"pb-8 mt-6",staticStyle:{"border-bottom":"1px dashed #d9d9d9"}},[_c('level-select-cascade-relation-fields',{ref:"ganttSetupLevelCascade",attrs:{"view":_vm.view},model:{value:(_vm.ganttSetting.projectCascade),callback:function ($$v) {_vm.$set(_vm.ganttSetting, "projectCascade", $$v)},expression:"ganttSetting.projectCascade"}})],1)])],1),(_vm.ganttSetting.projectCascade.length)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.ganttSetting.projectRelation=='MULTIPLE_RELATED'),expression:"ganttSetting.projectRelation=='MULTIPLE_RELATED'"}],attrs:{"cols":"12"}},[(_vm.relatedFieldItems)?_c('div',{staticClass:"d-flex"},[_c('v-btn-toggle',{attrs:{"mandatory":"","color":"teal"},model:{value:(_vm.selectedForm),callback:function ($$v) {_vm.selectedForm=$$v},expression:"selectedForm"}},_vm._l((_vm.relatedFieldItems),function(item,formIndex){return _c('v-btn',{key:item.relatedFormId,staticClass:"d-flex align-center justify-center",staticStyle:{"height":"30px","width":"150px","border":"0px"},style:(formIndex==0? 'clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)':
                                            'clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%)'),attrs:{"value":formIndex}},[_c('span',{staticClass:"px-6",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(item.relatedFormName))])])}),1)],1):_vm._e()]),_c('v-col',{attrs:{"cols":"3","offset":"0"}},[_c('div',{staticStyle:{"width":"100px"}},[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('gantt.projectField'))+" ")]),(!_vm.ganttSetting.projectCascade[_vm.selectedForm].col_project)?_c('v-select',{staticStyle:{"font-size":"13px"},attrs:{"items":_vm.titleFields,"item-text":"label","item-value":"fieldId","height":"27","dense":"","hide-details":"","menu-props":{offsetY: true, contentClass: 'denseList'},"filled":""},on:{"input":function($event){return _vm.$emit('updateSetting', {command: 'update_col_project', val: $event, index: _vm.selectedForm})}},model:{value:(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_project),callback:function ($$v) {_vm.$set(_vm.ganttSetting.projectCascade[_vm.selectedForm], "col_project", $$v)},expression:"ganttSetting.projectCascade[selectedForm].col_project"}}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var hover = ref.hover;
return [_c('div',[_c('v-chip',{staticClass:"teal lighten-2 white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getLabelById(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_project))+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"small":""},on:{"click":function($event){_vm.ganttSetting.projectCascade[_vm.selectedForm].col_project=null}}},[_vm._v(" mdi-close ")])],1)]}}],null,false,3203454785)})],1)],1):_vm._e(),((_vm.ganttSetting.projectCascade.length && _vm.selectedForm==(_vm.ganttSetting.projectCascade.length-1)) || (_vm.ganttSetting.projectRelation=='PARENT_CHILD' && _vm.ganttSetting.projectCascade[_vm.selectedForm]))?_c('v-row',{staticClass:"mt-10",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"3","offset":"0"}},[_c('div',{staticStyle:{"width":"100px"}},[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('gantt.startField'))+" ")]),(!_vm.ganttSetting.projectCascade[_vm.selectedForm].col_start)?_c('v-select',{staticStyle:{"font-size":"13px","min-height":"20px"},attrs:{"items":_vm.dateFields,"item-text":"label","item-value":"fieldId","height":"27","dense":"","hide-details":"","menu-props":{offsetY: true, contentClass: 'denseList'},"filled":""},on:{"input":function($event){return _vm.$emit('updateSetting', {command: 'update_col_start', val: $event, index: _vm.selectedForm})}},model:{value:(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_start),callback:function ($$v) {_vm.$set(_vm.ganttSetting.projectCascade[_vm.selectedForm], "col_start", $$v)},expression:"ganttSetting.projectCascade[selectedForm].col_start"}}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var hover = ref.hover;
return [_c('div',[_c('v-chip',{staticClass:"teal lighten-2 white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getLabelById(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_start))+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"small":""},on:{"click":function($event){_vm.ganttSetting.projectCascade[_vm.selectedForm].col_start=null}}},[_vm._v(" mdi-close ")])],1)]}}],null,false,4197902945)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticStyle:{"width":"100px"}},[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('gantt.endField'))+" ")]),(!_vm.ganttSetting.projectCascade[_vm.selectedForm].col_end)?_c('v-select',{staticStyle:{"font-size":"13px"},attrs:{"items":_vm.dateFields,"item-text":"label","item-value":"fieldId","height":"27","dense":"","hide-details":"","menu-props":{offsetY: true, contentClass: 'denseList'},"filled":""},on:{"input":function($event){return _vm.$emit('updateSetting', {command: 'update_col_end', val: $event, index: _vm.selectedForm})}},model:{value:(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_end),callback:function ($$v) {_vm.$set(_vm.ganttSetting.projectCascade[_vm.selectedForm], "col_end", $$v)},expression:"ganttSetting.projectCascade[selectedForm].col_end"}}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var hover = ref.hover;
return [_c('div',[_c('v-chip',{staticClass:"teal lighten-2 white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getLabelById(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_end))+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"small":""},on:{"click":function($event){_vm.ganttSetting.projectCascade[_vm.selectedForm].col_end=null}}},[_vm._v(" mdi-close ")])],1)]}}],null,false,2498220289)})],1)],1):_vm._e(),(_vm.ganttSetting.projectCascade.length && _vm.selectedForm==(_vm.ganttSetting.projectCascade.length-1) || (_vm.ganttSetting.projectRelation=='PARENT_CHILD' && _vm.ganttSetting.projectCascade[_vm.selectedForm]))?_c('v-row',{staticClass:"mt-10",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"3","offset":"0"}},[_c('div',{staticStyle:{"width":"100px"}},[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('gantt.progressField'))+" ")]),(!_vm.ganttSetting.projectCascade[_vm.selectedForm].col_progress || _vm.hover)?_c('v-select',{staticStyle:{"font-size":"13px"},attrs:{"items":_vm.numericFields,"item-text":"label","item-value":"fieldId","height":"27","dense":"","hide-details":"","menu-props":{offsetY: true, contentClass: 'denseList'},"filled":""},on:{"input":function($event){return _vm.$emit('updateSetting', {command: 'update_col_progress', val: $event, index: _vm.selectedForm})}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                            var item = ref.item;
return [_c('v-chip',{staticClass:"teal lighten-2 white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getLabelById(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_progress))+" ")])]}}],null,false,1577826027),model:{value:(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_progress),callback:function ($$v) {_vm.$set(_vm.ganttSetting.projectCascade[_vm.selectedForm], "col_progress", $$v)},expression:"ganttSetting.projectCascade[selectedForm].col_progress"}}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var hover = ref.hover;
return [_c('div',[_c('v-chip',{staticClass:"teal lighten-2 white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getLabelById(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_progress))+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"small":""},on:{"click":function($event){_vm.ganttSetting.projectCascade[_vm.selectedForm].col_progress=null}}},[_vm._v(" mdi-close ")])],1)]}}],null,false,12167169)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.$t('gantt.milestoneField'))+" ")]),(!_vm.ganttSetting.projectCascade[_vm.selectedForm].col_milestone)?_c('v-select',{staticStyle:{"font-size":"13px"},attrs:{"items":_vm.dateFields,"item-text":"label","item-value":"fieldId","height":"27","dense":"","hide-details":"","menu-props":{offsetY: true, contentClass: 'denseList'},"filled":""},on:{"input":function($event){return _vm.$emit('updateSetting', {command: 'update_col_milestone', val: $event, index: _vm.selectedForm})}},model:{value:(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_milestone),callback:function ($$v) {_vm.$set(_vm.ganttSetting.projectCascade[_vm.selectedForm], "col_milestone", $$v)},expression:"ganttSetting.projectCascade[selectedForm].col_milestone"}}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var hover = ref.hover;
return [_c('div',[_c('v-chip',{staticClass:"teal lighten-2 white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getLabelById(_vm.ganttSetting.projectCascade[_vm.selectedForm].col_milestone))+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"small":""},on:{"click":function($event){_vm.ganttSetting.projectCascade[_vm.selectedForm].col_milestone=null}}},[_vm._v(" mdi-close ")])],1)]}}],null,false,3213196961)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right elevation-0 teal lighten-1 white--text",staticStyle:{"width":"100px"},attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$emit('updateSetting', {command: 'update_settingCompleted', val: true})}}},[_vm._v(" "+_vm._s(_vm.$t('gantt.completed'))+" ")])],1)],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }