<template>
<div class="d-flex flex-column fill-height">
  <panel-base
    admin
    class="input-panel-main-page"
    :title="inputPanelTitle"
    icon="mdi-refresh"
    @click:submit="onRefreshClicked"
    @click:admin="openInputPanelSettingsDialog"
    :submitButtonLabel="$t('buttons.refresh')">
    <template v-slot:content>
      <div class="flex-grow-1 mt-1">
        <div v-if="inputPanel" class="d-flex flex-row fill-height">
          <div class="pa-2 d-flex flex-column fill-height flex-grow-0 grey lighten-2" 
            style="min-width:300px;">
            <label class="flex-grow-0">{{ $t('inputPanel.input') }}</label>
            <v-textarea
              ref="userInput"
              height="100%"
              class="user-input flex-grow-1"
              v-model="userInput"
              :background-color="isInputActive?'green lighten-5':''"
              dense
              @focus="onUserInputFocus"
              @blur="onUserInputBlur"
              @keydown.enter="onEnter"
              :hide-details="true"
              outlined
              solo
              rows="10"></v-textarea>
          </div>
          <div class="flex-grow-1 px-2 d-flex flex-column">
            <div class="mb-3 d-flex flex-row align-center">

              <v-btn
                :class="{'primary':isTodaySelected}"
                @click="useToday()">
                {{ $t('inputPanel.today') }}
              </v-btn>

              <date-menu-button
                class="ml-1"
                :label="$t('inputPanel.select')"
                v-model="criteria.activeDate"></date-menu-button>

              <v-spacer></v-spacer>
              <v-btn small text width="20"
                style="min-width:20px"
                @click="setPrevDate"
                class="mr-3">
                <v-icon>mdi-chevron-left-circle-outline</v-icon></v-btn>

                {{ activeDateString }}
                <v-chip color="warning" 
                  style="min-width:48px;height:20px;" 
                  class="justify-center pa-2 ml-1">{{ activeDateWeekday }}</v-chip>

              <v-btn small text width="20" 
                style="min-width:20px;"
                @click="setNextDate"
                class="ml-3">
                <v-icon>mdi-chevron-right-circle-outline</v-icon></v-btn>
                
                
              <!-- <v-btn-toggle
                class="ml-2"
                :mandatory="true"
                color="primary"
                :value="isDateSpecified"
                @change="val=""
                dense>
                <v-btn
                  :class="{'primary':!isTodaySelected}"
                  @click="onDateSelect(index)"
                  :key="index">
                  {{ selectedDate }}
                </v-btn>
              </v-btn-toggle> -->
              <v-spacer></v-spacer>
              <v-btn-toggle
                class="ml-1"
                :mandatory="true"
                color="primary"
                :value="selectedDisplayTypeIndex"
                dense>
                <v-btn v-for="(displayType,index) in displayTypeOptions"
                  @click="onDisplayTypeSelected(index)"
                  :key="index">
                  {{ displayType.label }}
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle
                class="ml-2"
                :mandatory="true"
                color="primary"
                v-model="amPmFilterOptionIndex"
                dense>
                <v-btn v-for="(periodFilter,index) in amPmFilterOptions"
                  @click="onFilterSelected(index)"
                  :key="index">
                  {{ periodFilter.label }}
                </v-btn>
              </v-btn-toggle>

            </div>
            <input-panel-filters
              class="flex-grow-0 w-100">
            </input-panel-filters>
            <input-panel-data-cells
              class="flex-grow-1 w-100"
              ref="inputPanelDataCells"
              :inputPanel="inputPanel"
              :masterForm="masterForm"
              :targetForm="targetForm"
              :userInput="userInput"
              :criteria="criteria"
              @onCommand="onCommandHandler"
              v-if="inputPanel.displayType==='cells'"></input-panel-data-cells>
            <!-- <input-panel-two-columns
              class="flex-grow-1 w-100"
              :inputPanel="inputPanel"
              :masterForm="masterForm"
              :targetForm="targetForm"
              v-if="inputPanel.displayType==='two-column'"></input-panel-two-columns> -->
            <input-panel-data-column
              class="flex-grow-1 w-100"
              ref="inputPanelDataColumn"
              :inputPanel="inputPanel"
              :masterForm="masterForm"
              :targetForm="targetForm"
              :userInput="userInput"
              :criteria="criteria"
              v-if="inputPanel.displayType==='column'"></input-panel-data-column>
          </div>
        </div>        
      </div>
    </template>
    <!-- <date-dialog
      ref="dateDialog"
      :value="criteria.activeDate"
      @onCommand="onCommandHandler"></date-dialog> -->
  </panel-base>

  <confirm-delete-dialog 
    ref="confirmDeleteDialog"></confirm-delete-dialog>

  <input-panel-setup-dialog
    ref="inputPanelSetupDialog"></input-panel-setup-dialog>
</div>
</template>

<script>
import panelBase from '@/components/PanelBase'
import inputPanelDataCells from './comps/InputPanelDataCells'
import dateTimeHelperMixin from '@/mixins/dateTimeHelperMixin'
// import inputPanelTwoColumns from './comps/InputPanelTwoColumns'
import inputPanelDataColumn from './comps/InputPanelDataColumn'
import dateMenuButton from './comps/DateMenuButton'

import inputPanelSetupDialog from '@/components/dialogs/InputPanelSetupDialog'
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
// import dateDialog from '@/components/dialogs/DateDialog'

import helpers from '@/helpers'

export default {
  name: 'inputPanelMainPage',
  mixins: [dateTimeHelperMixin],
  components: {
    panelBase,
    inputPanelDataCells,
    // inputPanelTwoColumns,
    inputPanelDataColumn,
    dateMenuButton,

    inputPanelSetupDialog,
    confirmDeleteDialog,
    // dateDialog
  },
  data () {
    return {
      amPmFilterOptionIndex: 0,
      intervalInst: null,
      loading: false,

      masterForm: null,
      targetForm: null,
      timeoutInst: null,
      userInput: '',
      criteria: {
        activeDate: this.getToday(),
        amPmFilter: 'fullDay'
      },
      isInputActive: false
    }
  },
  props: {
    inputPanel: null
  },
  beforeDestroy() {
    if (this.intervalInst) {
      clearInterval(this.intervalInst)
    }
  },
  watch: {
    inputPanel () {
      console.log('watch(inputPanel)')
      this.refreshAll()
    },
    'criteria.activeDate': function() {
      console.log('watch(criteria.activeDate)')
      this.refreshData()
    },
    'criteria.amPmFilter': function() {
      console.log('watch(criteria.amPmFilter)')
      this.refreshData()
    }
  },
  computed: {
    selectedDisplayTypeIndex () {
      const vm = this
      return vm.displayTypeOptions.findIndex(
        option => option.value === vm.inputPanel.displayType
      )
   },
    activeDateString () {
      return this.criteria.activeDate
    },
    activeDateWeekday () {
      const WEEKDAYS = ['sun','mon','tue','wed','thu','fri','sat']
      const weekDayIndex = this.getWeekday(this.criteria.activeDate)
      return this.$t('inputPanel.weekday' + helpers.ucFirst(WEEKDAYS[weekDayIndex]))
    },
    isTodaySelected () {
      return this.getToday() === this.criteria.activeDate
    },
    amPmFilterOptions () {
      return [
        {label: this.$t('inputPanel.fullDay'), value: 'fullDay'},
        {label: this.$t('inputPanel.am'), value: 'am'},
        {label: this.$t('inputPanel.pm'), value: 'pm'}
      ]
    },
    displayTypeOptions () {
      return [
        {label: this.$t('inputPanel.byMasterRecord'), value: 'cells'},
        {label: this.$t('inputPanel.byAttendance'), value: 'column'}
      ]
    },
    inputPanelTitle () {
      return this.inputPanel ? this.inputPanel.title : ''
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    amPmFilter () {
      return this.amPmFilterOptions[this.amPmilterOptionIndex].value
    },
    appId () {
      return this.$store.getters.currentApp._id
    },
    teamId () {
      return this.$store.getters.activeTeam._id
    }
  },
  mounted () {
    const vm = this
    vm.criteria.activeDate = vm.getToday()
    console.log('InputPanelMainPage.mounted')
    if (vm.inputPanel) {
      vm.refreshAll()
    }
  },
  methods: {
    onUserInputFocus () {
      this.isInputActive = true
    },

    onUserInputBlur () {
      this.isInputActive = false
    },

    setPrevDate () {
      this.criteria.activeDate = this.getPrevDate(this.criteria.activeDate)
    },
    setNextDate() {
      this.criteria.activeDate = this.getNextDate(this.criteria.activeDate)
    },
    useToday () {
      this.criteria.activeDate = this.getToday()
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'setFocus':
          vm.startInputMode()
          break
        case 'updateDateValue':
          console.log('onCommandHandler : payload: ', payload)
          this.criteria.activeDate = payload
          console.log('onCommandHandler : this.criteria: ', JSON.stringify(this.criteria.activeDate))
          break
      }
    },
    refreshData () {
      console.log('refreshData')
      if (this.inputPanel && this.inputPanel.displayType) {
        switch (this.inputPanel.displayType) {
          case 'cells':
            this.$refs.inputPanelDataCells.refresh()
            break
          case 'column':
            
            this.$refs.inputPanelDataColumn.refresh()
            break
        }

      }
    }, 

    onRefreshClicked () {
      const vm = this
      console.log('onRefreshClicked')
      if (!vm.masterForm) {
        vm.loadMasterForm(() => {
          vm.loadTargetForm(() => {
            vm.refreshData()
          })
        })
      } else {
        if (!vm.targetForm) {
          vm.loadTargetForm(() => {
            vm.refreshData()
          })
        } else {
          vm.refreshData()
        }
      }
    },

    onDisplayTypeSelected (index) {
      const vm = this
      console.log('onDisplayTypeSelected : index = ' + index)
      console.log('onDisplayTypeSelected : selectedDisplayTypeIndex = ' + vm.selectedDisplayTypeIndex)
      
      if (index !== vm.selectedDisplayTypeIndex) {
        const selectedDisplayType = vm.displayTypeOptions[index]
        vm.$store.dispatch('UPDATE_INPUT_PANEL_PROPERTY', {
          inputPanelId: vm.inputPanel._id,
          displayType: selectedDisplayType.value,
          appId: vm.appId,
          teamId: vm.teamId
        }).then(
          () => {
            vm.inputPanel.displayType = selectedDisplayType.value
            vm.$nextTick(() => {
              vm.refreshData()
            })            
          }
        )
      }
    },

    onFilterSelected (index) {
      if (index !== this.amPmFilterOptionIndex) {
        this.criteria.amPmFilter = this.amPmFilterOptions[index].value
        this.refreshData()
      }
    },
    onEnter () {
      const vm = this
      vm.submitCode()
      // clearTimeout(vm.timeoutInst)
      // vm.timeoutInst = setTimeout(() => {
      //   vm.submit()
      // },1000)
    },
    submitCode() {
      const vm = this
      const codes = vm.userInput.split('\n').filter(code => code !== '')
      const params = {
        inputPanelId: vm.inputPanel._id,
        codes: codes,
        date: vm.criteria.activeDate,
        amPmFilter: vm.criteria.amPmFilter
      }
      vm.$store.dispatch('INPUT_PANEL_SUBMIT_CODE', params).then(
        response => {
          if (response.status) {
            vm.updateRecord(response.result) 
            vm.userInput = ''
          } else {
          }
        }
      )
      vm.userInput = ''
    },
    updateRecord (updatedRows) {
      const vm = this
      console.log('updateRecord :: updatedRows: ', updatedRows)
      console.log('updateRecord :: vm.inputPanel: ', vm.inputPanel)
      switch (vm.inputPanel.displayType) {
        case 'cells':
          console.log('cells')
          vm.$refs.inputPanelDataCells.updateRecord(updatedRows)
          break
        case 'column':
          if (updatedRows && Array.isArray(updatedRows) && updatedRows.length >0)
            vm.$refs.inputPanelDataColumn.prependRecord(updatedRows[0])
          break
      }
    },
 

    loadMasterForm (callback) {
      const vm = this
      console.log('loadMasterForm : vm.inputPanel.masterFormId = ' + vm.inputPanel.masterFormId)
      vm.loadForm(vm.inputPanel.masterFormId, response => {
        // console.log('laodMasterForm finished: response: ', response)
        // for (let key in  response) {
        //   vm.$set(vm.masterForm, key, response[key])
        // }
        vm.masterForm = response
        if (typeof callback === 'function') {
          callback()
        }
      })
    },

    getTargetFormId(masterForm, masterFieldForTarget) {
      const vm = this
      var result = ''
      const relatedFieldInfo = masterForm.fieldInfos.find(info => info.fieldId === masterFieldForTarget)      
      if (relatedFieldInfo) {
        const targetFormId = relatedFieldInfo.properties.dataSource
        if (targetFormId && targetFormId !== '') {
          result = targetFormId
        }
      }
      return result
    },

    loadTargetForm(callback) {
      const vm = this
      console.log('loadTargetForm')
      var result = null

      const targetFormId = vm.getTargetFormId( vm.masterForm, vm.inputPanel.masterFieldForTarget)
      if (targetFormId && targetFormId !== '') {
        vm.loadForm(targetFormId, response => {
          console.log('loadTargetForm finished: response: ', response)
          vm.targetForm = response
          if (typeof callback === 'function') {
            callback()
          }
        })
      }
    },

    loadForm (formId, callback) {
      const vm = this
      if (formId) {
        const payload = {
          formId: formId,
          appId: vm.$route.params.id
        }
        vm.$store.dispatch('FETCH_FORM', payload).then(
          response => {
            if (typeof callback === 'function') {
              callback(response)
            }
          }
        )
      }
    },

    refreshAll () {
      const vm = this
      console.log('refreshAll')
      vm.loadMasterForm(() => {
        vm.loadTargetForm()
      })
      
    },

    onSettingsUpdated (payload) {
      const vm = this
      console.log('openInputPanelSettignsDialog :: callback : payload: ', payload)
      if (payload.command === 'ok') {
        vm.inputPanel = JSON.parse(JSON.stringify(payload.inputPanel))
      }
      vm.startInputMode()
    },

    // saveSettings () {
    //   const vm = this
    //   console.log('InputPanelSetupPage.save')
    //   const hasErrors = vm.$refs.inputPanelSettingsForm.validateForm()
    //   console.log('InputPanelSetupPage :: hasErrors: ', hasErrors)
    //   if (!hasErrors) {
    //     vm.userInputPanel.appId = vm.$store.getters.currentApp._id
    //     vm.userInputPanel.teamId = vm.$store.getters.activeTeam._id
    //     console.log('InputPanelSetup')
    //     vm.$store.dispatch('UPDATE_INPUT_PANEL', {
    //       data: vm.userInputPanel
    //     }).then(
    //       response => {
    //         console.log('InputPanelSetup :: save :: response: ', response)
    //         vm.$emit('update', response)
    //       }
    //     )
    //   }
    // },

    openInputPanelSettingsDialog () {
      const vm = this
      vm.endInputMode()
      vm.$refs.inputPanelSetupDialog.open({
        inputPanel: vm.inputPanel
      }, vm.onSettingsUpdated)
    },
    startInputMode () {
      const vm = this
      vm.intervalInst = setInterval(() => {
        if (vm.$refs.userInput) {
          const textAreaEl = vm.$refs.userInput.$el.querySelector('textarea')
          if (textAreaEl) {
            textAreaEl.focus()
          }
          // 'input:not([type=hidden])')
          //         if (textFieldEl) {
          // const inputEl = textFieldEl
        }
        // vm.$refs.userInput.focus()
      },
      1000)
    },
    endInputMode () {
      clearInterval(this.intervalInst)
      this.intervalInst = null
    }
  }
}
</script>

<style>
.input-panel-main-page .v-textarea .v-input__control {
  height: 100%;
}
</style>