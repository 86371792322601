import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import moment from "moment";

import Root from "@/pages/Root.vue";
import Home from "../views/Home.vue";
// import Test from "../views/Test.vue";
import MyApps from "@/pages/myapps/MyApps";
// import TemplateList from "@/pages/templateList/TemplateList";
// import PublishApp from "@/pages/publishApp/PublishApp";
// import PublishAppToCompany from "@/pages/publishAppToCompany/PublishAppToCompany";
// import TestPage from "@/pages/testPage/TestPage";
import Login from "@/pages/auth/Login";
import Apps from "@/pages/apps/Apps";

import Admin from "@/pages/admin/Admin";

// import SuperAdmin from "@/pages/superAdmin/SuperAdmin";
// import SuperLogin from "@/pages/superLogin/SuperLogin";

// import SystemAdmin from "@/pages/systemAdmin/SystemAdmin";
// import SystemLogin from "@/pages/systemLogin/SystemLogin";

import AdminForm from "@/pages/admin/form/AdminForm";
import FormBuilder from "@/pages/admin/form/FormBuilder";
import PanelBuilder from "@/pages/admin/panel/PanelBuilder";
import TableSetup from "@/pages/admin/form/TableSetup";
import TableSetupFunctionToggle from "@/pages/admin/form/tableSetup/TableSetupFunctionToggle";
import TableSetupPrintTemplate from "@/pages/admin/form/tableSetup/TableSetupPrintTemplate";
import TableSetupDisplayRules from "@/pages/admin/form/tableSetup/TableSetupDisplayRules";

import WordTemplateInstruction from "@/pages/admin/form/tableSetup/print/WordTemplateInstruction";
import PrintDocx from "@/pages/admin/form/tableSetup/print/PrintDocx";

import PublicPublishing from "@/pages/admin/form/PublicPublishing";

import AdminPanel from "@/pages/admin/panel/AdminPanel";
import AdminReport from "@/pages/admin/report/AdminReport";
import AdminWorkflows from "@/pages/admin/workflow";
import AdminFormWorkflows from "@/pages/admin/workflow/listView";
import AdminWorkflowEditor from "@/pages/admin/workflow/editor";

// Menu Item => Canvas
import Form from "@/pages/forms/Form";
import Record from "@/pages/records/Record";
import Panel from "@/pages/panels/Panel";
import Backdrop from "@/pages/backdrop/Backdrop";
import MapPanel from "@/pages/mapPanel/MapPanel";
import Calendar from "@/pages/calendar/Calendar";
import PublicFormPanel from "@/pages/publicFormPanel/PublicFormPanel";
import InputPanel from "@/pages/inputPanel/InputPanel";
import Report from "@/pages/reports/Report";
import EmptyFormPage from "@/pages/forms/EmptyPage";

import Role from "@/pages/roles/Role";

import ErrorPage from "@/pages/errors/ErrorPage";
import ErrorDisabledPage from "@/pages/errors/DisabledPage";

import WorkflowRoutesName from "@/const/workflow/routes";
import PublicForm from "@/pages/public/Form";
import PublicQuery from "@/pages/public/PublicQuery";
import PublicEdit from "@/pages/public/PublicEdit";

import ApiDoc from "@/pages/api/ApiDoc";

import CreateTeam from "@/pages/createTeam/CreateTeam";
import Landing from "@/pages/createTeam/Landing";
import LandingHome from "@/pages/createTeam/index";

//import GuestInfoSetup from "@/pages/guestInfo/GuestInfoSetup";

import i18n from "../lang/lang";

import ArchivedFolder from "@/pages/archivedFolder";
import ArchivedFolderApps from "@/pages/archivedFolder/apps";

import yoovWork from "@/pages/yoovWork/index";
import WorkAdminEmployees from "@/pages/yoovWork/employees";
import WorkAdminNewEmployee from "@/pages/yoovWork/new";
import WorkAdminEditEmployee from "@/pages/yoovWork/edit";
/**
 *
 * MOBILE VIEW
 *
 */
import MRoot from "@/pages/M_Root.vue";
import MHome from "@/pages/M_Home.vue";
import MMyApps from "@/pages/myapps/mobile/MyApps";
import MApps from "@/pages/apps/mobile/Apps";
import MForm from "@/pages/forms/mobile/Form";
import MChart from "@/pages/charts/mobile/Chart";
import MAppModules from "@/pages/apps/mobile/Modules";
import {app} from '@/main'

Vue.use(VueRouter);

const isMobile = () => {
  return (
    /mobi/i.test(window.navigator.userAgent) &&
    !/ipad/i.test(window.navigator.userAgent)
  );
};

const loadEssentials = (force) => {
  return new Promise((resolve, reject) => {
    try {
      if (force) {
        Promise.all([
          store.dispatch("FETCH_WIDGET_GROUPS"),
          store.dispatch("FETCH_WIDGET_PROPERTIES"),
          store.dispatch("FETCH_ALL_TEAMS"),
        ]).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    } catch (err) {
      reject(err);
    }
  });
};

const loadUserProfile = () => {
  return new Promise((resolve, reject) => {
    try {
      store.dispatch("FETCH_USER_PROFILE").then((response) => {
        i18n.locale = response.locale;
        app.$vuetify.lang.current = response.locale;
        store.dispatch("FETCH_QUICK_FILTER_PRESET")
        resolve();
      });
    } catch (err) {
      reject(err);
    }
  });
};

const saveToken = (token, role) => {
  store.commit("setAccessToken", token);
  store.commit("setAccessRole", role);
};

const ifTeamTerminated = async (to, from, next) => {
  console.log('to.params.urlId = ' + to.params.urlId);
  console.log('to.name = ' + to.name);
  
  let action = 'FETCH_PUBLIC_QUERY_STATUS';
  switch (to.name) {
    case 'PublicForm':
      action = 'FETCH_PUBLIC_FORM_STATUS'
      break;
    case 'PublicEdit':
      action = 'FETCH_PUBLIC_EDIT_STATUS'
      break;
  }
 
  const valid = await store.dispatch(action, {
    urlId: to.params.urlId
  });

  console.log('valid: ', valid);
  if (valid) {
    next();
  } else {
    next('/errors/disabled');
  }
};

const ifUserAuthenticated = async (to, from, next) => {
  const accessToken = await store.dispatch("CHECK_LOCAL_TOKEN");
  if (!accessToken && !to.query.token) {
    //not logged in
    let returnUrl = "";
    if (to.fullPath == "/my_apps")
      returnUrl = document.location.origin + "/dashboard";
    else returnUrl = document.location.origin + (to.fullPath || "/dashboard");
    const url = store.getters.appHost + "/auth/login?returnUrl=" + returnUrl;
    window.location.href = url;
  } else {
    const nextOptions = {};
    if (!accessToken) {
      saveToken(to.query.token, "user");
      Object.assign(nextOptions, to, { query: {} });
    }
    const user = store.getters.user;
    const FORCE = true;
    if (user === null) {
      await loadUserProfile();
      await loadEssentials(FORCE);

      // interrupt if team terminated
      if (
        (to.fullPath !== "/my_apps") &&
        store.getters.activeTeam && 
        store.getters.activeTeam.terminated) {
        window.location.href = "/my_apps";
      }

      Vue.prototype.$sensors.registerPage({
        is_login: true,
        is_member: true,
        company_id: () => store.getters.activeTeamCode,
        company_name: () => store.getters.activeTeamName,
        platform_name: "YOOV+",
        language_preference: () => store.getters.locale,
      });
      Vue.prototype.$sensors.login(store.getters.yoovAccount);
      Vue.prototype.$sensors.setProfile({
        company_id: store.getters.activeTeamCode,
        company_name: store.getters.activeTeamName,
        recent_login_acti_time: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        phone_number:store.getters.passportMobile,
        work_email:store.getters.passportEmail
      });
      store.dispatch("SCHEDULE_REFRESH", true);
      if (
        store.getters.user.teams.length === 0 && 
        store.getters.user.terminatedTeamIds.length === 0) {

        //No team => Create team
        if (
          store.getters.user.role === "guest" &&
          to.name !== "GuestInfoSetup"
        ) {
          Object.assign(nextOptions, { name: "GuestInfoSetup" });
        } else if (to.name !== "LandingHome") {
          Object.assign(nextOptions, { name: "LandingHome" });
        }
      }
      next(nextOptions);
      // next();
    } else {
      if (
        store.getters.user.teams.length === 0 &&
        store.getters.user.terminatedTeamIds.length === 0) {
          
        if (to.name !== "LandingHome")
          Object.assign(nextOptions, { name: "LandingHome" });
      } else {
        console.log("teams.length > 0: next");
      }
      next(nextOptions);
    }
  }
};

const extractToken = (to) => {
  const token = to.query.token;
  if (token) {
    store.commit("setAccessToken", token);
  }
};

const ifTeamsEmpty = (to, from, next) => {
  ifUserAuthenticated(to, from, next);
};

const routes = [
  // {
  //   path: "/test",
  //   name: "test",
  //   component: Test,
  // },
  {
    path: "/public/form/:urlId/preview",
    name: "PreviewPublicForm",
    component: PublicForm,
    beforeEnter: ifUserAuthenticated,
  },
  {
    path: "/landing",
    name: "Landing",
    component: LandingHome,
    redirect: "/landing/",
    beforeEnter: ifUserAuthenticated,
    children: [
      {
        path: "",
        name: "LandingHome",
        component: Landing,
      },
      {
        path: "createTeam",
        name: "CreateTeam",
        component: CreateTeam,
      },
    ],
  },
  {
    path: "/public/edit/:urlId",
    name: "PublicEdit",
    component: PublicEdit,
    beforeEnter: ifTeamTerminated
  },
  {
    path: "/public/form/:urlId",
    name: "PublicForm",
    component: PublicForm,
    beforeEnter: ifTeamTerminated
  },
  {
    path: "/public/query/:urlId",
    name: "PublicQuery",
    component: PublicQuery,
    beforeEnter: ifTeamTerminated
  },
  {
    path: "/app/:appId/form/:formId/printTemplateInstruction",
    name: "printTemplateInstruction",
    component: WordTemplateInstruction,
    beforeEnter: ifUserAuthenticated,
  },
  {
    path: "/form/printWord/:id",
    name: "PrintDocx",
    component: PrintDocx,
    beforeEnter: ifUserAuthenticated,
  },
  {
    path: "/appApi/:appId",
    name: "ApiDoc",
    component: ApiDoc,
    beforeEnter: ifUserAuthenticated,
  },
  {
    path: "/",
    name: "root",
    redirect: "/home",
    beforeEnter: (to, from, next) => {
      store.dispatch("SCHEDULE_REFRESH", false);
      next();
    },
    component: isMobile() ? MRoot : Root,
    children: [
      {
        path: "/home",
        name: "home",
        component: isMobile() ? MHome : Home,
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      // {
      //   path: "/guest_info_setup",
      //   name: "GuestInfoSetup",
      //   component: GuestInfoSetup,
      //   beforeEnter: ifUserAuthenticated,
      // },
      {
        path: "/dashboard",
        name: "dashboard",
        redirect: (to) => {
          extractToken(to);
          return { name: "myApps", query: {} };
        },
        // beforeEnter:ifTeamsEmpty,
      },
      // {
      //   path: "/publish_app",
      //   name: "publishApp",
      //   component: PublishApp,
      //   beforeEnter: ifUserAuthenticated,
      // },
      // {
      //   path: "/publish_app/:id",
      //   name: "publishAppToCompany",
      //   component: PublishAppToCompany,
      //   beforeEnter: ifUserAuthenticated,
      // },
      // {
      //   path: "/template_list",
      //   name: "templateList",
      //   component: TemplateList,
      //   beforeEnter: ifUserAuthenticated,
      // },
      // {
      //   path: "/test_page",
      //   name: "testPage",
      //   component: TestPage,
      //   beforeEnter: ifUserAuthenticated,
      // },
      // {
      //   path: "/about",
      //   name: "About",
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
      // },
      // {
      //   path: '/dashboard',
      //   name: 'Dashboard',
      //   component: () => import( '../pages/dashboard/Dashboard')
      // }
      {
        path: "/my_apps",
        name: "myApps",
        component: isMobile() ? MMyApps : MyApps,
        beforeEnter: ifTeamsEmpty,
      },
      {
        path: "/work",
        name: "workAdminPage",
        component: yoovWork,
        beforeEnter: ifTeamsEmpty,
        redirect: "/work/employees",
        children: [
          {
            path: "/work/new",
            name: "workAdminNewEmployee",
            component: WorkAdminNewEmployee,
          },
          {
            path: "/work/employees/:id/detail",
            name: "workAdminEditEmployee",
            component: WorkAdminEditEmployee,
          },
          {
            path: "/work/employees",
            name: "workAdminEmployees",
            component: WorkAdminEmployees,
          },
        ],
      },
      {
        path: "/apps/:id/roles",
        name: "roles",
        component: Role,
        beforeEnter: ifUserAuthenticated,
      },

      {
        path: "/workflows/:id",
        name: WorkflowRoutesName.workflows,
        component: AdminWorkflows,
        beforeEnter: ifUserAuthenticated,
        redirect: "/apps/:id/workflows",
        children: [
          // just for development and demo only
          {
            path: "/apps/:id/workflows/:eventType?/edit/:workflowId",
            name: WorkflowRoutesName.formWorkflowsTree,
            component: AdminWorkflowEditor,
          },
          {
            path: "/apps/:id/workflows/:eventType?/edit/:workflowId/:tabIndex",
            name: WorkflowRoutesName.workflowEditor,
            component: AdminWorkflowEditor,
          },
          {
            path: "/apps/:id/workflows/:eventType?",
            name: WorkflowRoutesName.formWorkflows,
            component: AdminFormWorkflows,
          },
        ],
      },
      {
        path: "/apps/:id",
        name: "apps",
        component: isMobile() ? MApps : Apps,
        beforeEnter: ifUserAuthenticated,
        // redirect:  (to, from) => {
        //   if ( isMobile() ) return `/apps/:id/modules`
        // },
        children: [
          // User
          {
            path: "/apps/:id/modules",
            name: "appsModules",
            component: MAppModules,
            beforeEnter: ifUserAuthenticated,
          },
          {
            path: "panels/:itemId",
            name: "panels",
            component: Panel,
          },
          {
            path: "backdrop/:itemId",
            name: "backdrop",
            component: Backdrop,
          },
          {
            path: "mapPanel/:itemId",
            name: "mapPanel",
            component: MapPanel,
          },
          {
            path: "calendars/:itemId",
            name: "calendar",
            component: Calendar,
          },
          {
            path: "inputPanels/:itemId",
            name: "inputPanels",
            component: InputPanel,
          },
          {
            path: "publicFormPanels/:itemId",
            name: "publicFormPanels",
            component: PublicFormPanel,
          },
          {
            path: "forms/:itemId/:viewId/:recordId",
            name: "records",
            component: Record,
          },
          {
            path: "forms/:itemId/:viewId?",
            name: "forms",
            component: isMobile() ? MForm : Form,
          },
          {
            path: "reports/:itemId",
            name: "reports",
            component: Report,
          },
          {
            path: "forms/:itemId/:viewId/charts",
            name: "charts",
            component: MChart,
          },
          {
            path: "forms/empty",
            name: "forms-empty",
            component: EmptyFormPage,
          },
        ],
      },
      {
        path: "/my_apps/archived",
        name: "archivedFolder",
        component: ArchivedFolder,
        beforeEnter: ifUserAuthenticated,
      },
      {
        path: "/my_apps/archived/:id",
        name: "archivedFolderApps",
        component: ArchivedFolderApps,
        beforeEnter: ifUserAuthenticated,
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/my_apps",
    component: Admin,
    beforeEnter: ifUserAuthenticated,
    children: [
      {
        path: "apps/:id/panels/:itemId",
        name: "adminPanels",
        component: AdminPanel,
        redirect: "/admin/apps/:id/panels/:itemId/panelBuilder",
        // beforeEnter: ifUserAuthenticated
        children: [
          {
            path: "/admin/apps/:id/panels/:itemId/panelBuilder",
            name: "adminPanelBuilder",
            component: PanelBuilder,
          },
        ],
        // beforeEnter: ifUserAuthenticated
      },
      {
        path: "apps/:id/forms/:itemId",
        name: "adminForms",
        component: AdminForm,
        redirect: "/admin/apps/:id/forms/:itemId/formBuilder",
        // beforeEnter: ifUserAuthenticated,
        children: [
          {
            path: "/admin/apps/:id/forms/:itemId/formBuilder",
            name: "adminFormBuilder",
            component: FormBuilder,
            // beforeEnter: ifUserAuthenticated
          },
          {
            path: "/admin/apps/:id/forms/:itemId/tableSetup",
            name: "adminTableSetup",
            component: TableSetup,
            redirect: "/admin/apps/:id/forms/:itemId/tableSetup/functionToggle",
            children: [
              {
                path: "/admin/apps/:id/forms/:itemId/tableSetup/functionToggle",
                name: "adminTableSetupFunctionToggle",
                component: TableSetupFunctionToggle,
              },
              {
                path: "/admin/apps/:id/forms/:itemId/tableSetup/printTemplate",
                name: "adminTableSetupPrintTemplate",
                component: TableSetupPrintTemplate,
              },
              {
                path: "/admin/apps/:id/forms/:itemId/tableSetup/displayRules",
                name: "adminTableSetupDisplayRules",
                component: TableSetupDisplayRules,
              },
            ],
            // beforeEnter: ifUserAuthenticated
          },
          {
            path: "/admin/apps/:id/forms/:itemId/publicPublishing",
            name: "adminPublicPublishing",
            component: PublicPublishing,
            // beforeEnter: ifUserAuthenticated
          },
        ],
      },
      {
        path: "apps/:id/reports/:itemId",
        name: "adminReports",
        component: AdminReport,
        // beforeEnter: ifUserAuthenticated
      },
    ],
  },
  {
    path: "/errors/disabled",
    name: "errorsDisabled",
    component: ErrorDisabledPage
  },
  {
    path: "/errors/:errorCode",
    name: "errors",
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    store.commit("savePrevRoute", from);
  },
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to.name);
  next();
});
export default router;