<template>
<div class="d-flex flex-column fill-height w-100 panel-base pb-3">
  <div class="flex-grow-0 pa-0 d-flex flex-row align-center panel-base-header">
    <h3 v-if="subTitle===''">{{ title }}</h3>
    <div v-else class="d-flex flex-column">
      <h3 class="line-height-1 pa-0 ma-0">{{ title }}</h3>
      <div class="line-height-1 caption">{{ subTitle }}</div>
    </div>
    <v-btn
      v-if="(admin||isAdmin)&&settingButton"
      icon
      @click="$emit('click:admin')"
      class="ma-1 d-flex flex-column justify-center"
    >
      <v-icon size="25">{{ adminButtonIcon }}</v-icon>
    </v-btn>  
    <v-spacer></v-spacer>      
    <v-btn
      small
      color="primary"
      @click="$emit('click:submit')">
      <v-icon left>{{ icon }}</v-icon>{{ submitButtonLabel ? submitButtonLabel : $t('buttons.save') }}
    </v-btn>
  </div>     
  <v-divider></v-divider> 
  <v-row v-if="loading">
    <v-col cols="12" class="text-center">
      <v-icon x-large>mdi-spin mdi-loading</v-icon>
    </v-col>
  </v-row>
  <slot v-else name="content">
    <h1>no content</h1>
  </slot>
</div>
</template>

<script>

export default {
  name: 'panelBase',
  props: {
    title: String,
    subTitle: {
      type: String,
      default: ''
    },
    loading: Boolean,
    admin: Boolean,
    adminButtonIcon: {
      type: String,
      default: 'mdi-file-document-edit-outline'
    },
    icon: {
      type: String,
      default: 'mdi-send'
    },
    submitButtonLabel: {
      type: String,
      default: null
    },
    settingButton: Boolean
  },
  computed: {
    isAdmin () {      
      return this.$store.getters.isAdmin
    },
  }
}
</script>

<style>
.panel-base-header {
  min-height: 44px;
}
</style>