<template>
    <v-card tile>
        <ChartDataXY
            :arrX="arrX"
            :arrY="arrY"
            :arr2Y="arr2Y"
            :arrG="arrG"
            :selectedChart="selectedChart"
            @onCommandHandler="onCommandHandler"
            :setupIsUpdated="setupIsUpdated"
        />
        <ChartDataFigure
            :selectedChart="selectedChart"
            :chartFigure="chartFigure"
            @onCommandHandler="onCommandHandler"
        />
        <ChartDataFilter
            :filters="filters"
            @onCommandHandler="onCommandHandler"
            :setupIsUpdated="setupIsUpdated"
        />
    </v-card>
</template>

<script>
import ChartDataXY from './data/ChartDataXY'
import ChartDataFigure from './data/ChartDataFigure'
import ChartDataFilter from './data/ChartDataFilter'
export default {
    props:{
        arrX: Array,
        arrY: Array,
        arr2Y: Array,
        arrG: Array,
        filters: Array,
        chartFigure: String,
        selectedChart: String,
        setupIsUpdated: Object
    },
    components:{
        ChartDataXY,
        ChartDataFigure,
        ChartDataFilter
    },
    methods:{
        onCommandHandler(payload){
            this.$emit('onCommandHandler', payload)
        }
    }
}
</script>
