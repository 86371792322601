import { yoovWork } from "../../action-types";
import * as mutations from "./mutation-types";
import Vue from "vue";

export default {
  [yoovWork.SCHEDULE_REFRESH]({ commit, dispatch }, isActivate) {
    if (isActivate) {
      commit(
        mutations.SET_INTERVAL,
        setInterval(() => {
          dispatch(yoovWork.REFRESH_TOKEN);
        }, 60 * 1000 * 10)
      );
    } else {
      commit(mutations.SET_INTERVAL, null);
    }
  },
  [yoovWork.GET_YOOVWORK_EMPLOYEES](
    { dispatch, commit },
    { page, size, include, status, sorting, search }
  ) {
    return new Promise((resolve, reject) => {
      dispatch(yoovWork.YOOV_REQUEST, {
        url: "/work/employees",
        method: "get",
        params: {
          page,
          size,
          include,
          status,
          sorting,
          search,
        },
      })
        .then((res) => {
          commit("SET_EMPLOYEES", res.data.content);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  [yoovWork.REFRESH_TOKEN]({ state, commit, dispatch }) {
    const params = new URLSearchParams();
    params.append("refreshToken", state.refreshToken);
    const payload = {
      url: "/passport/refreshToken",
      method: "post",
      data: params,
    };

    dispatch(yoovWork.YOOV_REQUEST, payload).then((res) => {
      if (res.data) {
        const { accessToken, refreshToken } = res.data;
        commit(mutations.SET_TOKEN, { accessToken, refreshToken });
      }
    });
  },
  [yoovWork.SET_TOKEN]({ commit }, { accessToken, refreshToken }) {
    commit("SET_TOKEN", { accessToken, refreshToken });
  },
  [yoovWork.YOOV_REQUEST]({ state, getters }, {requireTeamId = true, ...payload }) {
    const baseURL = `${getters.appHost}/`;
    payload.baseURL = baseURL;

    const headers = {
      Authorization: `Bearer ${state.accessToken}`,
      "x-account-id": getters.user.sub,
      "accept-language": getters.locale,
    };
    if (!payload.headers) {
      payload.headers = headers;
    } else {
      Object.assign(payload.headers, headers);
    }
    if (getters.activeTeam && requireTeamId) {
      Object.assign(payload.headers, {
        "x-tenant-id": getters.activeTeam.id,
      });
    }
    return new Promise((resolve, reject) => {
      Vue.axios
        .request(payload)
        .then((res) => resolve(res))
        .catch((err) => {
          reject(err);
        });
    });
  },
  [yoovWork.RESET_YOOVWORK]({ commit }) {
    commit(mutations.SET_INTERVAL, null);
    commit(mutations.RESET_YOOVWORK);
  },
  [yoovWork.SET_YOOVWORK_EMPLOYEES]({commit}, payload){
    commit(mutations.SET_EMPLOYEES, payload)
  }
};
