<template>
  <div>
    <!--FIXME: hardcoded hint-->
    <v-text-field
      ref="date"
      v-model="userValue"
      :label="disabled ? '' : $t('widgets.properties.selectDate')"
      :prepend-inner-icon="disabled ? '' : 'mdi-calendar'"
      hint="e.g. 2020-12-15"
      :class="{
        'workflow-customized-form-record-element': isWorkflow,
      }"
      :hide-details="isLayout"
      dense
      flat
      :outlined="!disabled"
      :disabled="disabled"
      :readonly="isReadOnly || !isWorkflow"
      :rules="rules"
      :solo="!disabled"
      :error="error"
      @click:prepend-inner="showDialog"
      @click.stop="handleFieldClick"
      @focus="handleFieldClick"
      :tabindex="tabindex"
    />
    <v-dialog ref="dialog" v-model="showing" v-if="showing" width="290px">
      <v-date-picker
        v-if="showing && !isReadOnly"
        v-model="dialogValue"
        scrollable
        no-footer
        v-bind="attrs"
        :locale="$i18n.locale"
        :allowed-dates="allowedDates"
        :min="dateMin"
      >
        <v-spacer></v-spacer>
        <v-btn class="min-width-100" color="muted" @click="close">
          {{ $t("buttons.cancel") }}
        </v-btn>
        <v-btn class="min-width-100 ml-1" color="primary" @click="confirm">
          {{ $t("buttons.ok") }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <!--:return-value.sync="dialogValue"-->
  </div>
</template>

<script>
import mixin from "./_mixin";

export default {
  name: "dialogFieldDate",
  mixins: [mixin],
  props: {
    rules: Array,
    error: {
      type: Boolean,
      default: false,
    },
    isWorkflow: {
      type: Boolean,
      default: true,
    },

    attrs: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    _confirm() {
      this.$emit("input", this.dialogValue);
    },
    getDefaultValue() {
      return this.defaultType === "auto" ? this.formatDate() : "";
    },
  },
};
</script>
