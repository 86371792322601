<template>
    <v-container fluid>
      <v-row no-gutters class="grey lighten-2">
        <v-col cols="10" class="pa-0 ma-0">
          <v-row no-gutters class="pa-1">
            <v-col
              v-for="header in headers"
              :key="header.label"
              :cols="header.cols"
            >
              {{ header.label }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-row no-gutters class="pa-1">
          {{$t('roles.permission')}}
          </v-row>
        </v-col>
      </v-row>
      <panel-permission-table-row
        v-for="[panelId, panel] in Object.entries(panels)"
        :key="panelId"
        :panelId="panelId"
        :panel="panel"
        @onCommand="onCommandHandler"
      />
    </v-container>
  </template>
  <script>
  import PanelPermissionTableRow from "./PanelPermissionTableRow.vue";
  export default {
    components: { PanelPermissionTableRow },
    props: {
      panels: Object
    },
    data() {
      return {
        headers: [
          {
            label: this.$t('roles.panel'),
            cols: 6,
          },
          {
            label: this.$t('roles.view'),
            cols: 2,
          },
          {
            label: '', // this.$t('roles.edit'),
            cols: 2,
          },
          {          
            label: '',
            cols: 2,
          },
        ],
      };
    },
  
    methods: {
      onCommandHandler(payload) {
        const panel = this.panels[payload.panelId];      
        switch (payload.command) {
            case 'setReadPanel':
                if (panel) {
                    panel.read = payload.value;    
                }
            break;
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .cell-flex {
    flex: 1 0;
    overflow: hidden;
  }
  
  .bottom-border {
    border-bottom: 1px solid #9e9e9e;
  }
  </style>
  