<template>
  <div class="d-flex flex-column fill-height drawer" style="border-left: 1px solid #9e9e9e">
    <v-toolbar dense flat class="header">
      <v-tabs v-model="activeTab" grow>
        <v-tab class="tab-title" href="#comment" v-if="showComment">
          {{$t('drawer.comment')}}
        </v-tab>
        <v-tab class="tab-title" href="#upload" v-if="showComment"> {{$t('drawer.upload')}}</v-tab>
        <v-tab class="tab-title" href="#log" v-if="showLog">
          {{$t('drawer.log')}}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-divider />
    <v-tabs-items class="fill-height" v-model="activeTab">
      <v-tab-item value="comment" class="fill-height">
        <comment v-if="activeTab=='comment'" :parentId="parentId"/>
      </v-tab-item>
      <v-tab-item value="upload" class="fill-height">
        <attachment v-if="activeTab=='upload'" :parentId="parentId"/>
      </v-tab-item>
      <v-tab-item value="log" class="fill-height">
        <activity-log v-if="activeTab=='log'" :parentId="parentId"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Comment from "./comment/index";
import ActivityLog from "./log/index"
import Attachment from "./attachment/index"

export default {
  props: {
    parentId: {
      type: String,
      required: true
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  components: {
    Comment,
    ActivityLog,
    Attachment
  },
  computed: {
    showComment(){
      if (this.parentId === this.$route.params.itemId) { //form
        return this.$store.getters.showFormDiscussion
      } else { //record
      const form = this.$store.getters.currentForm;
      if (!form) return false;
        const setting = form.recordFunction.showDiscussion;
        if (!setting || !setting.mode) return false
        else if (setting.viewType === 1 && !setting.views.includes(this.$route.params.viewId)) return false;
        else if (setting.roleType === 1 && !this.$store.getters.isAdmin) return false
        return true
      }
      
    },
    showLog(){


      if (this.parentId === this.$route.params.itemId) { //form
        return this.$store.getters.showFormLog;
      }else { //record
            const form = this.$store.getters.currentForm;
      if (!form) return false;
        const setting = form.recordFunction.showLog;
        if (!setting || (setting.hasOwnProperty('mode') && !setting.mode)) return false
        else if (setting.viewType === 1 && !setting.views.includes(this.$route.params.viewId)) return false;
        else if (setting.roleType === 1 && !this.$store.getters.isAdmin) return false
        return true
      }
    }
  }
};
</script>
