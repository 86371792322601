<template>
  <v-list-item v-if="typeof value === 'object'" class="setting-row px-0">
    <v-list-item-action v-if="value.hasOwnProperty('mode')" class="pl-4">
      <v-switch
        dense
        :input-value="value.mode"
        @change="onChange($event, 'mode')"
      />
    </v-list-item-action>
    <v-menu
      offset-x
      offset-overflow
      :close-on-content-click="false"
      max-width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-content>
            <span>
              {{ $t(`functionToggle.${setting}`) }}
              <slot name="description"></slot>
              <v-icon v-if="value.roleType === 1" small color="primary"
                >mdi-eye-off-outline</v-icon
              >
            </span>
          </v-list-item-content>

          <v-list-item-action>
            <span class="grey--text">
              <span v-if="value.viewType === 0">{{
                $t("functionToggle.allViews")
              }}</span>
              <span v-else-if="value.viewType === 1">{{
                $t("functionToggle.someViews", { num: value.views.length })
              }}</span>
              <span v-else-if="value.roleType === 0">{{
                $t("functionToggle.allUsers")
              }}</span>
              <span v-else>{{ $t("functionToggle.admin") }}</span>
              <v-icon small>mdi-chevron-right</v-icon>
            </span>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>{{ $t("functionToggle.setting") }}</v-card-title>
        <v-divider />
        <v-card-subtitle v-if="value.hasOwnProperty('roleType')" class="pb-0">{{
          $t("functionToggle.user")
        }}</v-card-subtitle>
        <v-card-text v-if="value.hasOwnProperty('roleType')">
          <v-radio-group
            :value="value.roleType"
            dense
            row
            hide-details
            @change="onChange($event, 'roleType')"
          >
            <v-radio :value="0" :label="$t('functionToggle.allUsers')" />
            <v-radio :value="1" :label="$t('functionToggle.admin')" />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-subtitle v-if="value.hasOwnProperty('viewType')" class="pb-0">{{
          $t("functionToggle.view")
        }}</v-card-subtitle>
        <v-card-text v-if="value.hasOwnProperty('viewType')">
          <v-radio-group
            v-model="value.viewType"
            dense
            row
            hide-details
            @change="onChange($event, 'viewType')"
          >
            <v-radio :value="0" :label="$t('functionToggle.allViews')" />
            <v-radio :value="1" :label="$t('functionToggle.specificView')" />
          </v-radio-group>

          <div v-if="value.viewType === 1">
            <v-checkbox
              v-for="view in views"
              :key="view._id"
              :value="view._id"
              :label="view.title"
              hide-details
              dense
              v-model="normalizedViews"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-list-item>
  <v-list-item class="setting-row" v-else>
    <v-list-item-action>
      <v-switch dense :input-value="value" @change="onChange" />
    </v-list-item-action>
    <v-list-item-content>
      <span>
        {{ $t(`functionToggle.${setting}`) }}
        <slot name="description"></slot>
      </span>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: {
    setting: String,
    value: [Boolean, Object],
    category: String,
  },
  computed: {
    views() {
      return this.$store.getters.currentForm.views;
    },
    normalizedViews: {
      get() {
        return typeof this.value === "object" ? this.value.views : [];
      },
      set(val) {
        this.$emit("update", {
          value: val,
          setting: this.setting,
          category: this.category,
          key: "views",
        });
      },
    },
  },
  methods: {
    onChange(e, key = null) {
      const params = {
        value: e,
        setting: this.setting,
        category: this.category,
      };
      if (key) Object.assign(params, { key });

      this.$emit("update", params);
    },
  },
};
</script>
<style scoped>
.setting-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
