const fileTypeExtensions = {
    image:{
        value: 'image',
        exts: ['jpg','jpeg','png','gif','tiff','psd','eps','ai','indd','raw','bmp']
    },
    audio:{
        value: 'audio',
        exts: ['aac','ac3','aiff','flac','m4a','mp3','mp4','ogg','opus','ts','wav','wma']
    },
    video:{
        value: 'video',
        exts: ['mp4','mov','wmv','flv','avi','avchd','webM','mkv']
    },
    msWord:{
        value: 'msWord',
        exts: ['doc','docm','docx','dot','dotx'],
    },
    msExcel:{
        value: 'msExcel',
        exts: ['csv','xlsx','xls','xlsm','xml','xlt','xltm','xltx','xla','xlam','xll']
    },
    msPowerpoint:{
        value: 'msPowerpoint',
        exts: ['pot','potm','potx','ppam','pps','ppsm','ppsx','ppt','pptm','pptx'],
    },
    word:{
        value: 'word',
        exts: ['txt','xml','rtf','odt'],
    },
    compressed:{
        value: 'compressed',
        exts: ['zip','gz'],
    },
    pdf:{
        value: 'pdf',
        exts: ['pdf'],
    }
}

export default fileTypeExtensions