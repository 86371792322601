var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WorkflowConsumer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var info = ref.info;
return _c('div',{},[(_vm.infoLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!!_vm.workflowInfo && _vm.treeLoading)?_c('v-progress-circular',{staticClass:"w-e-tree-loader",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!!_vm.workflowInfo && !_vm.treeLoading)?_c('div',[_c('div',{staticClass:"w-e-scaler"},[_c('div',{staticClass:"w-e-versioning"},[_vm._v(_vm._s(_vm.$t('workflow.label.version', { version: _vm.version })))]),_c('v-layout',{attrs:{"justify-space-between":"","align-center":""}},[_c('div',[_c('v-btn',{staticClass:"w-e-scaler-btn scaler-add",class:{ active: _vm.canAdd },attrs:{"outlined":"","disabled":!_vm.canAdd},on:{"click":_vm.add}},[_vm._v("+")]),_c('v-btn',{staticClass:"w-e-scaler-btn scaler-reduce",class:{ active: _vm.canReduce },attrs:{"outlined":"","disabled":!_vm.canReduce},on:{"click":_vm.reduce}},[_vm._v("-")]),_c('span',{staticClass:"w-e-scaler-label"},[_vm._v(_vm._s(_vm.scale)+"%")])],1)]),(_vm.isPreviewMode)?_c('div',{staticClass:"w-e-preview-title"},[_vm._v(_vm._s(_vm.$t('workflow.label.previewMode')))]):_vm._e()],1),_c('div',{staticClass:"w-e-tools"},[_c('v-layout',{attrs:{"justify-space-between":"","align-center":""}},[_c('div',{staticClass:"w-e-tools-quick-view"},[_c('toggle-button',{attrs:{"value":_vm.isShortMode,"color":{
                checked: _vm.$vuetify.theme.themes.light.primary, 
                unchecked: '#CCCCCC', 
              },"width":"130","height":"34","labels":{
                unchecked: _vm.$t('workflow.btn.quickView'), 
                checked: _vm.$t('workflow.btn.quickView')
              }},on:{"change":_vm.onQuickView}})],1),(_vm.canPreview)?_c('div',[_c('toggle-button',{attrs:{"value":_vm.isPreviewMode,"color":{
                checked: _vm.$vuetify.theme.themes.light.primary, 
                unchecked: '#CCCCCC', 
              },"disabled":_vm.treeLoading || _vm.reviewLoading,"width":"170","height":"34","labels":{
                unchecked: _vm.$t('workflow.btn.preview'), 
                checked: _vm.$t('workflow.btn.preview')
              }},on:{"change":_vm.onPreview}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"w-e-preview-help-icon",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('workflow.message.previewDescription')))])])],1):_vm._e()])],1),_c('v-layout',{style:(_vm.canvasStyle),attrs:{"column":"","align-center":"","justify-center":""}},[_c('WorflowTree',{attrs:{"walkingNode":_vm.walkingNode,"node":_vm.isPreviewMode ? _vm.previewNodes : _vm.node,"tree":_vm.tree}}),(!_vm.isLoadingPreviewTree)?_c('WorkflowEndOfFlowNode'):_vm._e()],1),(!!_vm.workflowTree && !!_vm.workflowInfo)?_c('WorkflowModules'):_vm._e(),_c('WorkflowForm')],1):_c('div',[_c('EditorTriggerSetting')],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }