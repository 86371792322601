<template>
<v-dialog
  v-model="showingDialog"
  v-if="showingDialog"
  :activator="activator"
  width="480">
  <v-card>
    <v-form ref="form" @submit="onSubmit" onsubmit="return false;">
      <dialog-header
        class="flex-grow-0"
        :icon="dialogHeaderIcon"
        :label="dialogTitle"
        :actionDisabled="saving"
        @close="close"></dialog-header>
      <v-card-text class="pt-3 pb-1 add-menu-item-dialog d-flex flex-column">
        <radio-button-toggle
            :disabled="menuItemInfo._id!==''"
            v-model="menuItemInfo.type"
            :label="$t('general.type')"
            class="mb-3"
            :options="menuItemTypes"></radio-button-toggle>
        <label>{{ $t('general.title') }}</label>
        <!--onFocus($event)"-->
        <v-text-field
            ref="title"
            dense
            autofocus
            @focus="$event.target.select()"
            v-model="menuItemInfo.title"
            class="mb-3"
            :hide-details="true"
            single-line
            outlined
            height="30"
            style="font-size:14px"
            :label="$t('general.title')"
            :rules="[rules.required]"
            />
        <div class="d-flex flex-row align-center">
          <icon-input-field v-model="menuItemInfo.icon"
            :disabled="menuItemInfo.type==='submenu'"
            iconIfDisabled="mdi-folder"
            :vertical="true"></icon-input-field>
          <v-spacer></v-spacer>
          <v-switch
              dense
              v-if="menuItemInfo._id === ''"
              v-model="saveAndNew"
              :label="$t('messages.continueAddingAfterSaving')"></v-switch>
        </div>
      </v-card-text>
      <!-- <v-divider></v-divider> -->

      <dialog-actions
        class="dialog-actions flex-grow-0"
        :buttons="dialogButtons"
        :disabledButtons="disabledButtons"
        defaultButton="save"
        :actionDisabled="!menuItemInfo.title||saving"
        :loading="loading"
        @onCommand="onCommandHandler">
      </dialog-actions>
    </v-form>
  </v-card>
</v-dialog>
</template>

<script>
import baseMixin from '@/mixins/baseMixin'
import rulesMixin from '@/mixins/rulesMixin'

import dialogHeader from '@/components/dialogs/comps/DialogHeader'
import dialogActions from '@/components/dialogs/comps/DialogActions'

import radioButtonToggle from '../comps/RadioButtonToggle'
import iconInputField from '@/components/dialogs/IconInputField'


const TRACK_MAP = {
  panel:"面板",
  form:"表格",
  calendar:"日曆",
}

export default {
  mixins: [
    baseMixin,
    rulesMixin
  ],
  components: {
    dialogHeader,
    dialogActions,
    radioButtonToggle,
    iconInputField
  },
  props: {
    activator: {
      type: String,
      default: ''
    },
    saveCommand: String,
    module: Object
  },
  watch: {
    'menuItemInfo.type': function(newVal) {
      const vm = this
      if (Object.values(vm.defaultMenuTitles).includes(vm.menuItemInfo.title) ||
        vm.menuItemInfo.title === '') {
        vm.menuItemInfo.title = vm.defaultMenuTitles[newVal]
      }
    }
  },
  data () {
    return {
      saving: false,
      saveAndNew: false,
      showingDialog: false,
      DEFAULT_BLANK_MENU_ITEM: {
        _id: '',
        title: '',
        type: 'form',
        icon: ''
      },
      menuItemInfo: null,
      // menuItemTypes: [],
      menuFolder: null
    }
  },

  // created () {
  //   const vm = this
  //   vm.menuItemInfo = vm.getBlankMenuItem()
  // },

  computed: {
    defaultMenuTitles () {
      const vm = this
      let result = {}
      for (let i = 0; i < vm.allMenuItemTypes.length; i++) {
        const loopMenuItemType = vm.allMenuItemTypes[i]
        const menuTypeValue = loopMenuItemType.value
        result[menuTypeValue] = vm.generateDefaultMenuTitle(menuTypeValue)
      }
      return result
    },
    allMenuItemTypes () {
      const vm = this
      return [
        {caption: vm.$t('menu.panel'), value: 'panel'},
        {caption: vm.$t('menu.form'), value: 'form'},
        {caption: vm.$t('menu.calendar'), value: 'calendar'},
      ]
    },

    allMenuNames () {
      const vm = this
      let result = []
      if (vm.module) {
        const menu = vm.module.menu
        if (menu.length > 0) {
          result = vm.getMenuTitles(menu)
        }
      }
      console.log('MenuItemDialog :: allMenuNames: result: ', result)
      return result
    },
    menuItemTypes () {
      const vm = this
      let result = vm.allMenuItemTypes
      // if non-new item, type is fixed and non-editable
      if (vm.menuItemInfo._id !== '') {
        result = vm.allMenuItemTypes.filter(item => item.value === vm.menuItemInfo.type)
      }
      return result
    },
    dialogHeaderIcon () {
      const vm = this
      let result = 'mdi-checkbox-blank-circle-outline'
       if (vm.menuItemInfo.icon !== '') {
        result = vm.menuItemInfo.icon
      }
      return result
    },
    disabledButtons () {
      const vm = this
      let result = []
      if (vm.saveAndNew || (vm.menuItemInfo.type !== 'form')) {
        result = ['saveAndEdit']
      }
      return result
    },
    dialogTitle () {
      const vm = this
      let result = vm.$t('app.newMenuItem')
      if (vm.menuItemInfo.title !== '') {
        result = vm.menuItemInfo.title
      }
      return result
    },
    saveButtonTitle () {
      const vm = this
      return vm.$t('buttons.save')
    },
    dialogButtons () {
      return this.menuItemInfo._id === ''?
        [ 'cancel', 'saveAndEdit', 'save']:
        [ 'cancel','save']
    }
  },
  
  methods: {

    getMenuTitles(menu) {
      const vm = this
      let result = []
      for (let i = 0; i < menu.length; i++) {
        const loopMenu = menu[i]
        result.push(loopMenu.title)
        if (loopMenu.children && loopMenu.children.length > 0) {
          const subMenuTitles = vm.getMenuTitles(loopMenu.children)
          if (subMenuTitles.length > 0) {
            result = result.concat(subMenuTitles)
          }
        }
      }
      return result
    },
    async fetchRandomIcon () {
      const vm = this
      return new Promise((resolve, reject) => {
        vm.$store.dispatch('AUTH_GET', {
          urlCommand: '/mdi_icons/random'
        }).then(
          response => {
            resolve(response.result)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    getBlankMenuItem () {
      return JSON.parse(JSON.stringify(this.DEFAULT_BLANK_MENU_ITEM))
    },
    getRandomIcon () {
      return this.fetchRandomIcon()
    },
    initMenuItemInfo (type) {
      const vm = this
      vm.menuItemInfo = vm.getBlankMenuItem()
      if (typeof type !== 'undefined') {
        vm.menuItemInfo.type = type
      }
      vm.menuItemInfo.title = vm.defaultMenuTitles[vm.menuItemInfo.type]
      vm.getRandomIcon().then(
        icon => {
          vm.menuItemInfo.icon = icon
        }
      )
    },

    menuNameExists(menuName) {
      return this.allMenuNames.includes(menuName)
    },

    generateDefaultMenuTitle(menuTypeValue) {
      const vm = this

      const typeName = vm.allMenuItemTypes.find(item => item.value === menuTypeValue).caption
      let count = 1
      let menuName = typeName + ' #' + count
      while (vm.menuNameExists(menuName)) {
        count++
        menuName = typeName + ' #' + count
      }
      return menuName
    },
    open (menuItemInfo, menuFolder) {
      const vm = this
      
      vm.menuFolder = menuFolder
      if (typeof menuItemInfo === 'undefined' || menuItemInfo===null) {
        vm.initMenuItemInfo()
      } else {

        vm.menuItemInfo = JSON.parse(JSON.stringify(menuItemInfo))
      }
      vm.saving = false
      this.saveAndNew = false
      vm.showingDialog = true
    },
    close () {
      this.showingDialog = false
    },
    onCommandHandler (payload) {
      const vm = this
      console.log('onCommandHandler :: payload.command= ' + payload.command)
      switch (payload.command) {
        case 'save':
          vm.onSubmit()

          break
        case 'saveAndEdit':
          vm.checkAndSave( result => {
            if (result) {
              const formId = result
              console.log('MenuItemDialog :: onCommandHandler :: result: ', result)
              vm.$emit('onCommand', {
                command: 'editForm',
                formId: formId
              })
              // vm.editForm(formId)
            }
          })
          break
        case 'cancel':
          vm.close()
          break

      }
    },
    focus (refName) {
      const vm = this
      // console.log('focus(' + refName + ')')
      const refObj = vm.$refs[refName]
      if (refObj) {
        const el = vm.$refs[refName].$el
        // console.log('focus :: el: ', el)
        const input = el.querySelector('input:not([type=hidden])')
        console.log('focus : input: ', input)
        if (input) {
          vm.$nextTick(() => {
            input.focus()
            input.select()
          })
        }
      } else {
        vm.$alert('Internal Error: Ref "' + refName + '" not found!')
      }
    },

    onSubmit () {
      const vm = this
      vm.checkAndSave( result => {
        if (result) {
          if (!vm.saveAndNew) {
            vm.close()
          } else {
            const keepType = vm.menuItemInfo.type
            vm.initMenuItemInfo(keepType)
            vm.focus('title')
            vm.$refs.form.resetValidation()
          }

        }
      })
      // return false to stop dialog from closing
      return false
    },

    checkAndSave (callback) {
      const vm = this
      if (!vm.menuItemInfo.title){
        if (typeof callback === 'function') {
          callback(false)
        }
      } else {
        vm.saving = true
        vm.$emit('onCommand', {
          command: vm.saveCommand,
          menuItemInfo: {
            ...vm.menuItemInfo,
            trackId: TRACK_MAP[this.menuItemInfo.type] || ""
          },
          menuFolder: vm.menuFolder,
          callback: (result) => {
            if (typeof callback === 'function') {
              callback(result)
            }
            vm.saving = false
          }
        })
      }
    },
    closeDialog () {
      this.showingDialog = false
    }
  }
}
</script>
