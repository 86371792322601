<template>
  <v-dialog
    v-model="showingDialog"
    scrollable
    width="1280"
    max-width="90%">
    <v-card min-height="700">
      <dialog-header
        :label="$t('calendar.calendarPanelSetup')"
        @close="showingDialog=false"
        color="#E0E0E0"
        toolbarClass="grey--text"/>
      <v-card-text class="px-5 team-authorization-setup-dialog-body position-relative">
        <LoadingIcon v-if="loading"/>
        <div v-else class="d-flex flex-column fill-height justify-start">
          <div class="fill-height">
            <calendar-settings-form v-if="panelSettings"
              ref="calendarSettingsForm"
              class="fill-height"
              v-model="panelSettings"></calendar-settings-form> 
          </div>
        </div>
      </v-card-text>
      <dialog-actions 
        :buttons="['ok', 'cancel']"
        @onCommand="onCommandHandler">
      </dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import LoadingIcon from "@/components/global/loadingIcon";
import calendarSettingsForm from '@/pages/calendar/comps/CalendarSettingsForm'
import { cloneDeep } from "lodash"


export default {
  name: 'calendarPanelSetupDialog',
  components: {
    dialogActions,
    dialogHeader,
    LoadingIcon,
    calendarSettingsForm
  },
  data () {
    return {
      userInput: '',
      showingDialog: false,
      callback: null,
      panelSettings: null
    }
  },
  methods: {
    open (payload) {
      this.panelSettings = cloneDeep(payload.panelSettings);
      this.showingDialog = true      
    },
    close () {
      this.showingDialog = false
    },

    async save () {
      const vm = this
      const hasErrors = vm.$refs.calendarSettingsForm.validateForm()
      if (hasErrors === 0) {
        vm.panelSettings.appId = vm.$store.getters.currentApp._id
        vm.panelSettings.teamId = vm.$store.getters.activeTeam._id
        
        return await vm.$store.dispatch('UPDATE_CALENDAR_PANEL', {
          data: vm.panelSettings
        })
      } else return null
    },

    onCommandHandler (payload) {
      const vm = this
      if (payload.command === 'ok') {
        vm.save().then(res=> {
          if (res) {
            this.$emit("confirm", res)
            this.showingDialog = false
          }
        })
      } else {
        vm.showingDialog = false
      }
    }
  }
}
</script>