<template>
<div class="text-center">
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark x-small color="primary"
             v-bind="attrs"
             v-on="on">
        <v-icon x-small>mdi-plus</v-icon>
        {{ $t('widgets.properties.newField')}}
      </v-btn>
    </template>
    <div style="height:360px" class="menu-button-list">
      <perfect-scrollbar style="height:100%;">
        <v-list dense>
          <template v-for="widgetGroup in widgetGroups">
            <v-subheader inset :key="widgetGroup._id"
              class="ma-0 justify-center">
              {{ $t('widgets.' + widgetGroup.titleTag)}}
            </v-subheader>
            <v-list-item
                @click="selectItem(widget)"
              v-for="widget in filterWidgets(widgetGroup.widgets)"
              :key="widget._id">
              <v-list-item-icon>
                 <font-awesome-icon :icon="widget.icon" class="fa-fw"></font-awesome-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t('widgets.properties.' + widget.labelTag)">
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </perfect-scrollbar>
    </div>
  </v-menu>
</div>
</template>

<script>

import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  props: {
    label: String
  },
  computed: {
    widgetGroups () {
      return this.$store.getters.widgetGroups
    },
    childTableWidgets () {
      return []
    }
  },
  methods: {
    selectItem (widget) {
      this.$emit('newItem', widget)
    },
    filterWidgets(widgets){
      return widgets.filter(widget=> widget.commonForChildTable && !widget.disabled)
    }
  }
}
</script>

<style>
  .menu-button-list .v-list .v-subheader {
    background-color: rgba(0,0,0,.05);
    /*height: 18px;*/
    /*min-height: 24px;*/
    padding: 0 8px !important;
  }
  .menu-button-list .v-list .v-list-item {
    /*height: 24px;*/
    /*min-height: 24px;*/
    /*max-height: 24px;*/
    padding: 0 8px !important;
  }

  .menu-button-list .v-list .v-list-item:hover {
    background-color: rgba(0, 0, 0, .1);
  }
</style>
