const mixin = {
  methods: {
    loadData (urlCommand, params) {
      const vm = this
      const getData = {
        urlCommand: urlCommand,
        options: {
          params: params
        }
      }
      return new Promise((resolve, reject) => {
        vm.$store.dispatch('AUTH_GET', getData).then(
          response => {
            resolve(response)
          }
        ).catch(
          err => {
            reject(err)
          }
        )
      })
    }
  }
}

export default mixin
