const appsFormsMixin = {
  methods: {
    updateDefaultViewName (form) {
      const vm = this
      // console.log('updateDefaultViewName: form: ', form)
      for (let i = 0; i < form.views.length; i++) {
        const loopView = form.views[i]
        console.log('updateDefaultViewName : loopView: ', loopView)
        if (!loopView.title) {
          loopView.title = '(' + vm.$t('general.noName') + ')'
          vm.saveView(form, loopView)
        } else if (loopView.title[0] === '_') {
          loopView.title = vm.$t('view.' + loopView.title.substr(1))
          vm.saveView(form, loopView)
        }
      }
    },

    saveView (form, view) {
      const vm = this
      vm.$store.dispatch('UPDATE_FORM_VIEW', {
        id: form._id,
        viewId: view._id,
        data: view
      })
    },

    getFieldIdByLabel (fieldLabel) {
      const vm = this
      var result = null
      if (vm.form) {
        const fieldInfo = vm.form.fieldInfos.find(fieldInfo => fieldInfo.label === fieldLabel)
        if (fieldInfo) {
          result = fieldInfo.fieldId
        }
      }
      return result
    },
    loadForm (formId, callback) {
      const vm = this
      // console.log('appsFormsMixin :: loadForm  formId = ' + formId)
      if (formId) {
        vm.$store.dispatch('FETCH_FORM', {formId: formId, appId: this.$route.params.id}).then(
          form => {
            console.log('appsFormsMixin: form loaded, ' + formId)
            vm.updateDefaultViewName(form)
            if (typeof callback === 'function') {
              callback(form)
            }
          }
        )
      }
    },

    updateViewUrl (viewId) {
      const vm = this
      vm.$router.replace({
        name: 'forms',
        params: {itemId: vm.form._id, viewId}
      })

    },
  }
}

export default appsFormsMixin
