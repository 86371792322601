export default {
        criteriaConfigs: {
          //**************
          // (Common)
          // Text, Date, Single-Selection,
          // Multiple-Selection, Member,
          // Region, Rating, Department
          //**************
          'empty': {t: 'isEmpty', type: 'none'},
          'notEmpty': {t: 'notEmpty', type: 'none'},

          //**************
          // Text
          //**************
          'includes': {t: 'includes', type: 'text', inputCount: 1},
          'notInclude': {t: 'exclude', type: 'text', inputCount: 1},
          'is': {t: 'is', type: 'text', inputCount: 1},
          'not': {t: 'isNot', type: 'text', inputCount: 1},
          'isTheRelation': {t: 'is', type: 'relatedRecord', inputCount: 1},
          'notTheRelation': {t: 'isNot', type: 'relatedRecord', inputCount: 1},
          'beginsWith': {t: 'beginsWith', type: 'text', inputCount: 1},
          'endsWith': {t: 'endsWith', type: 'text', inputCount: 1},

          //***********************
          // Checkbox / Signature
          //***********************
          // 'checked': {t: 'checked'},
          // 'notChecked': {t: 'notChecked'},
          'toggle': {t: 'toggle', type: 'radio', inputCount: 0},

          //**************
          // Attachment
          //**************
          'hasCount': {t: 'hasCount', type: 'number', inputCount: 1},
          'atLeast': {t: 'atLeast', type: 'number', inputCount: 1},
          'atMost': {t: 'atMost', type: 'number', inputCount: 1},
          //
          // 'hasAttachment': {t: 'has'},
          // 'noAttachment': {t: 'none'},

          //**************
          // Signature
          //**************
          // 'hasSignature': {t: 'has'},
          // 'noSignature': {t: 'none'},

          //**************
          // Date
          //**************
          'isTheDate': {t: 'is', type: 'date', inputCount: 1},
          'notTheDate': {t: 'isNot', type: 'date', inputCount: 1},
          'withinPeriod': {t: 'withinPeriod', type: 'date', inputCount: 2},
          'notWithinPeriod': {t: 'notWithinPeriod', type: 'date', inputCount: 2},
          'earlierThan': {t: 'earlierThan', type: 'date', inputCount: 1},
          'laterThan': {t: 'laterThan', type: 'date', inputCount: 1},
          'greaterThan': {t: 'greaterThan', type: 'number', inputCount: 1},
          'smallerThan': {t: 'smallerThan', type: 'number', inputCount: 1},
          'today': {t: 'today', type: 'label', inputCount: 0},
          'currentMonth': {t: 'currentMonth', type: 'label', inputCount: 0},
          'nextMonth': {t: 'nextMonth', type: 'label', inputCount: 0},
          'thisWeek': {t: 'thisWeek', type: 'label', inputCount: 0},
          'nextWeek': {t: 'nextWeek', type: 'label', inputCount: 0},

          //**************
          // Number
          //**************
          'inRange': {t: 'inRange', type: 'number', inputCount: 2},
          'equals': {t: 'equals', type: 'number', inputCount: 1},
          'notEqual': {t: 'notEqual', type: 'number', inputCount: 1},
          'greaterThan': {t: 'greaterThan', type: 'number', inputCount: 1},
          'smallerThan': {t: 'smallerThan', type: 'number', inputCount: 1},
          'greaterOrEqual': {t: 'greaterThanOrEqual', type: 'number', inputCount: 1},
          'smallerOrEqual': {t: 'smallerThanOrEqual', type: 'number', inputCount: 1},
          'notInRange': {t: 'notInRange', type: 'number', inputCount: 2},

          //**************
          // Single Selection
          //**************
          'isTheItem': {t: 'is', type: 'items', inputCount: 1},
          'notTheItem': {t: 'isNot', type: 'items', inputCount: 1},

          //**************
          // Multiple Selection
          //**************
          'includeAnItem': {t: 'includes', type: 'items'},
          'notIncludeTheItems': {t: 'exclude', type: 'items'},

          //**************
          // Member
          //**************
          'isTheMember': {t: 'is', type: 'member'},
          'notTheMember': {t: 'isNot', type: 'member'},

          //**************
          // Region
          //**************
          'isTheRegion': {t: 'is', type: 'region'},
          'notTheRegion': {t: 'isNot', type: 'region'},

          //**************
          // Rating
          //**************
          'isTheRating': {t: 'equals', type: 'rating'},
          'notTheRating': {t: 'notEqual', type: 'rating'},
          'isRatingTheLeast': {t: 'atLeast', type: 'number', inputCount: 1},
          'isRatingTheMost': {t: 'atMost', type: 'number', inputCount: 1},

          //**************
          // Department
          //**************
          'isTheDepartment': {t: 'is', type: 'department'},
          'notTheDepartment': {t: 'isNot', type: 'department'}

        },

        filterInfos: {
          // Text
          T: {options: ['includes', 'notInclude', 'is', 'not', 'beginsWith', 'endsWith', 'empty', 'notEmpty']},
          // CheckBox
          C: {options: ['toggle'], buttons: [{labelTag: 'buttons.yes', value: 'yes'}, {labelTag: 'buttons.no', value: 'no'}]},
          // Attachment
          // A: {options: ['hasCount','atLeast','atMost','empty','notEmpty']},
          // A: {options: ['empty','notEmpty']},
          A: {options: ['toggle'], buttons: [{labelTag: 'buttons.hasAttachment', value: 'yes'}, {labelTag: 'buttons.hasNoAttachment', value: 'no'}]},

// type: 'radioButton', buttons: ['hasAttachment', 'noAttachment']},
          // Signature
          SG: {options: ['toggle'], buttons: [{labelTag: 'buttons.hasSignature', value: 'yes'}, {labelTag: 'buttons.hasNoSignature', value: 'no'}]},
          // Date
          D: {options: ['isTheDate', 'notTheDate', 'withinPeriod', 'notWithinPeriod', 'earlierThan', 'laterThan', 'greaterThan', 'smallerThan', 'empty', 'notEmpty', 'today', 'thisWeek', 'nextWeek', 'currentMonth', 'nextMonth']},

          // Number
          N: {options: ['inRange', 'notInRange', 'equals', 'notEqual', 'greaterThan', 'smallerThan', 'greaterOrEqual', 'smallerOrEqual']},
          // Single Selection
          SI: {options: ['isTheItem', 'notTheItem', 'empty', 'notEmpty']},
          // Multiple Selection
          MI: {options: ['includeAnItem', 'notIncludeTheItems', 'empty', 'notEmpty']},
          // Member
          M: {options: ['isTheMember', 'notTheMember', 'empty', 'notEmpty']},
          // Region
          RE: {options: ['isTheRegion', 'notTheRegion', 'empty', 'notEmpty']},
          // Rating
          RA: {options: ['isTheRating', 'notTheRating', 'isRatingTheLeast', 'isRatingTheMost', 'empty', 'notEmpty']},
          // Department
          DP: {options: ['isTheDepartment', 'notTheDepartment', 'empty', 'notEmpty']},
          RLA: {options: ['isTheRelation', 'notTheRelation', 'empty', 'notEmpty']}
        }
}
