<template>
  <vxe-table-column v-bind="colDef" :edit-render="{name: 'EditPulldownRelatedRecordModal', props: {readonly, dataSource, relatedTitleFieldId, idDataMapping}, enabled:!readonly}"
    sortable>
    <template v-slot="{row}">
      <div>{{valueString(row[colDef.field])}}</div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>

  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import EditPulldownRelatedRecordModal from './comps/EditPulldownRelatedRecordModal'
import VXETable from 'vxe-table'
import summaryCell from './comps/SummaryCell'
import Vue from 'vue'

Vue.component(EditPulldownRelatedRecordModal.name, EditPulldownRelatedRecordModal)
VXETable.renderer.add('EditPulldownRelatedRecordModal', {
  renderEdit (h, renderOpts, params) {
    console.log(renderOpts)
    return [
      <edit-pulldown-related-record-modal params={ params } readonly={ renderOpts.props.readonly } dataSource={renderOpts.props.dataSource} relatedTitleFieldId={renderOpts.props.relatedTitleFieldId}
      idDataMapping={renderOpts.props.idDataMapping}></edit-pulldown-related-record-modal>
    ]
  },
})

export default {
  mixins: [mixin],  
  components: {
    summaryCell
  },
  props: ['rowHeightClass'],
  computed: {
    dataSource () {
      return this.fieldInfo.properties.dataSource
    },
    relatedFormId () {
      return this.dataSource ? this.dataSource : ''
    },
    relatedTableInfos () {
      return this.$store.getters.relatedTableInfos
    },
    relatedFormInfo() {
      return this.relatedFormId && this.relatedTableInfos ?
        this.relatedTableInfos[this.relatedFormId] :
        null
    },
    relatedFieldInfos () {
      return this.relatedFormInfo ? 
        this.relatedFormInfo.fieldInfos : 
        []
    },
    relatedTitleFieldId () {
      const vm = this
      var result = ''
      if (vm.relatedFieldInfos.length > 0) {
        const titleFieldInfo = vm.relatedFieldInfos.find(info => info._id === vm.relatedFormInfo.titleFieldInfoId)
        if (titleFieldInfo) {
          result = titleFieldInfo.fieldId
        }
      }
      return result
    }
  },
  methods: {
    // getLabel (idArray) {
    //   const vm = this
    //   var result = '???'
    //   console.log('getLabel: idArray: ', idArray)
    //   if (Array.isArray(idArray)) {
    //     console.log('getLabel: is array')
    //     const selected = vm.items.find(item => item.value === idArray[0])
    //     console.log('getLabel: selected: ', JSON.stringify(selected))
    //     if (selected) {
    //       result = selected.label
    //       console.log('getLabel: from selected result = ' + result)
    //     }
    //   }
    //   console.log('getLabel: result = ' + result)
    //   return result
    // },
     valueString (fieldValue) { // for dislayFormat === 'pulldown'
      const vm = this
      var values = []
      if (fieldValue && vm.idDataMapping) {
        for (let i = 0; i < fieldValue.length; i++) {
          const loopId = fieldValue[i]
          if (vm.idDataMapping[loopId]) {
            values.push(vm.idDataMapping[loopId].title)
          }
        }
      }
      return values.length > 0 ? values.join(', ') : ''
    }
  }
  // ,
  
  // methods: {
  //   idsToTitles (idArray) {
  //     const vm = this
  //     var result = []
      
  //     // console.log('getRelatedRecordTitles: idArray: ', idArray)
  //     if (vm.idDataMappingExists && idArray && idArray.length > 0) {
  //       for (let i = 0; i < idArray.length; i++) {
  //         const loopId = idArray[i]
  //         if (vm.relationData.idDataMapping[loopId]) {
  //           result.push(vm.relationData.idDataMapping[loopId])
  //         } 
  //         // else {
  //         //   result.push(loopId)
  //         // }
  //       }
  //     }
  //     return result
  //   }
  // }

}

</script>

<style>
   .page-left {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
        }

 .my-downpanel1 {
          background-color: #fff;
          border: 1px solid #e8eaec;
        }
</style>