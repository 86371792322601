<template>
<div class="d-flex category-name flex-row align-center justify-start">
  {{ name }}
  <v-chip v-if="appCount>0"
    class="px-2 ml-1" x-small>{{ appCount }}</v-chip>
</div>  
</template>

<script>
export default {
  name: 'categoryName',
  props: {
    index: Number,
    name: String,
    archives: Array
  },
  computed: {
    appCount () {
      var result = 0
      if (this.archives[this.index].orderedAppIds) {
        result = this.archives[this.index].orderedAppIds.length
      }
      return result
    }
  }
}
</script>