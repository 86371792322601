const mixin = {
    data(){
        return {
            selectedPrintTemplate: "",
            selectedRecordId:"",
            isEditPrint: false,
            showTemplateDialog:false            
        }
    },
    computed: {
        form(){
            return this.$store.getters.currentForm
        },
        currentViewId(){
            return this.viewId|| (this.view && this.view._id) || 
            (this.currentView && this.currentView._id) || 
            this.$route.params.viewId || 
            ""
        },
        canPrint(){
            return this.getFunctionPermission('recordFunction', 'allowPrint')
        },
        printTemplates(){
            let result = 
            this.$store.getters.printTemplates.filter(template => template.viewType === 0 || template.views.includes(this.currentViewId))
              .map(template => ({
                label:template.templateName,
                templateId: template._id,
                type: template.type || 0
              }))

              return this.canPrint ? result.concat({label:this.$t('printTemplate.systemPrint'), templateId:'', type:0}) : result
        },
        isAdmin(){
            return this.$store.getters.isAdmin
        }      
    },
    methods: {
        print({templateId, recordId, type, appId, formId, viewId, templateName}){
            if (type === 0) {
                this.selectedPrintTemplate = templateId
                this.selectedRecordId = recordId
                this.isEditPrint = !templateId
                this.showTemplateDialog = true  
            } else if (type === 1) {
                this.$store.dispatch('SET_DOCX_CACHE', {
                    templateId,
                    recordId,
                    appId,
                    formId,
                    viewId,
                    templateName
                }).then(id=> {
                    const routeData = this.$router.resolve({
                        name: 'PrintDocx',
                        params: {id}
                    })
                    window.open(routeData.href, '_blank')
                })
            }
          
        },
        getFunctionPermission(category, key){
            if (!this.form) return false;
        
            const setting = this.form[category][key];
            if (!setting || !setting.mode) return false;
            else if (
              setting.viewType === 1 &&
              !setting.views.includes(this.currentViewId)
            )
              return false;
            else if (setting.roleType === 1 && !this.isAdmin)
              return false;
            else return true;
        }
    }
}

export default mixin