<template>
  <!--<div class="level-block-list mt-5">-->
                 <!--group="levelItem"-->
  <div class="level-major-row">
    <draggable :list="listItems"
               handle=".handle"
               ghost-class="ghost"
               tag="div"
               class="pa-1 d-flex flex-column justify-start level-block-list"
               :class="{'non-parent':level>1}"
              @start="onDragStarted"
               @change="log"
               group="levelItem"
               @end="onDragEnded"
               :key="id">
      <level-block-item
          v-for="(item, index) in listItems"
          :level="level+1"
          :key="index"
          :value="item"
          :dragging="dragging"
          @input="val=>updateItem(index, val)"
          @onCommand="onCommandHandler">
      </level-block-item>
    </draggable>
  </div>
  <!--</div>-->
</template>

<script>
import draggable from 'vuedraggable'
import levelBlockItem from './LevelBlockItem'

export default {
  name: 'levelBlockList',
  components: {
    draggable,
    levelBlockItem
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      level: 0
    }
  },
  computed: {
    listItems: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    dragging: Boolean
  },
  methods: {
    updateItem (index, updatedItem) {
      const vm = this
      const updated = JSON.parse(JSON.stringify(vm.value))
      updated[index] = updatedItem
      console.log('LevelBlockList :: updateItem: index = ' + index)
      console.log('LevelBlockList :: updateItem: updatedItem: ', updatedItem)
      this.$emit('input', updated)
    },
    log: function (evt) {
      const vm = this
      window.console.log(evt);
      // vm.$emit('input', vm.items)
      // vm.$emit('onCommand', {
      //   command: 'detachFromPanZoom'
      // })
    },
    onDragStarted (evt) {
      this.$emit('onCommand', {
        command: 'onDraggingItemStarted'})
    },
    onDragEnded (evt) {
      console.log('LevelBlockList :: onDragEnded :: evt: ', evt)
      this.$emit('onCommand', {
         command: 'onDraggingItemEnded'})
    },
    onCommandHandler (payload) {
      // console.log('LevelBlockList :: payload: ', payload)
      this.$emit('onCommand', payload)
    }

  }
}
</script>

<style>
  .block-card {
    width: 280px;
  }
  .ghost.level-block-item .level-block-list {
    display: none;
  }
  .ghost.level-block-item {
    background-color: blue !important;
  }

  .level-major-row {
    display: block;
  }

  .level-major-row > .level-block-list:hover {
    background-color: transparent;
    border-color: transparent;
  }
  .level-block-list:hover {
    /*background-color:blue;*/
  /*rgba(0,0,0,.05);*/
    border-color: darkgrey;
  }
  .level-block-list {
    background-color: transparent;
    display: inline-block;
    min-width: 200px;
    border:2px dotted transparent;
  }
  .level-block-list .sortable-chosen {
    background-color: transparent !important;
  }
</style>
