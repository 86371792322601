<template>
  <div class="pa-0 text-no-wrap overflow-hidden d-flex flex-row align-center">
    <div v-if="textOnly">
      <label v-if="useLabel" class="grey--text text--lighten-1 mr-2">{{ label }}</label>
      {{ displayValue }}
    </div>
    <v-chip v-else
            x-small color="primary" class="pa-1">
      <v-avatar left class="mr-0" style="margin-left:-8px;">
        <v-icon small>mdi-account-circle</v-icon>
      </v-avatar>
      {{ displayValue }}
    </v-chip>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsUser',
  mixins: [mixin],
  computed: {
    displayValue () {
      return this.dataValue ? this.dataValue.employeeName : this.titleValue
    }
  }
}
</script>
