import {
  RELEASE_TO_ORCHESTRATION,
  STOP_ORCHESTRATION,
  REVIEW_ORCHESTRATION,
  RESET_ORCHESTRATION_REVIEW,
} from './action_types';
import {
  UPDATE_WORKFLOW_STATUS,
  RESET_EDITOR,
  LOG_VALIDATE_WORKFLOW,
} from './../workflow/action_types';
import { get, pick } from 'lodash';


export const PickWorkflowStatus = workflow => pick(workflow, [
  'released',
  'releasedAt',
  'beingReleased',
]);
const initialState = () => ({
  orchestration: {
    loading: false,
    list: [],
    error: null,
  },
  release: {
    loading: false,
    info: {},
    error: null,
    failed: false,
  },
  stop: {
    loading: false,
    info: {},
    error: null,
  },
});

const getters = {
  getPreviewOrchestration: state => state.orchestration.list,
  fetchingOrchestration: state => state.orchestration.loading,

  getReleasingInfo: state => state.release.info,
  releasing: state => state.release.loading,
  releasedError: state => state.release.error,
  isReleaseError: state => state.release.failed,

  getStoppingInfo: state => state.stop.list,
  stopping: state => state.stop.loading,

  isBlockRelease: state => state.stop.loading || state.release.loading,
}

const mutations = {
  resetOrchestrationState(state) {
    const wasState = initialState();
    state.orchestration = { ...wasState.orchestration };
    state.release = { ...wasState.release };
    state.stop = { ...wasState.stop };
  },
  resetPreviewOrchestration(state) {
    state.orchestration.list = [];
    state.orchestration.loading = false;
    state.orchestration.error = null;
  },
  requestPreviewOrchestration(state) {
    state.orchestration.loading = true;
  },
  fetchedOrchestration(state, list) {
    state.orchestration.loading = false;
    state.orchestration.list = list;
  },
  fetchOrchestrationFailed(state, error) {
    state.orchestration.loading = false;
    state.orchestration.error = error;
  },

  requestReleasingOrchestration(state) {
    state.release.loading = true;
    state.release.error = null;
    state.release.failed = false;
  },
  releasedOrchestration(state, info) {
    state.release.loading = false;
    state.release.info = info;
  },
  releaseOrchestrationFailed(state, error) {
    state.release.loading = false;
    state.release.error = error;
    state.release.failed = true;
  },

  requestStoppingOrchestration(state) {
    state.stop.loading = true;
  },
  stoppedOrchestration(state) {
    state.stop.loading = false;
    // state.stop.info = info;
  },
  stopOrchestrationFailed(state, error) {
    state.stop.loading = false;
    state.stop.error = error;
  },
}

const actions = {
  [RESET_EDITOR]({commit}) {
    // console.log('Reset Workflow Tree State');
    commit('resetOrchestrationState');  
  },
  [RESET_ORCHESTRATION_REVIEW]({commit}) {
    commit('resetPreviewOrchestration');
  },
  [REVIEW_ORCHESTRATION] ({dispatch, commit}, workflowId) {
    commit('requestPreviewOrchestration');
    const options = {
      urlCommand: `/workflow/tree/${workflowId}/review`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedOrchestration', response);
    }).catch(err => {
      commit('fetchOrchestrationFailed', err.message);
    })
  },
  [RELEASE_TO_ORCHESTRATION] ({dispatch, commit}, workflowId) {
    commit('requestReleasingOrchestration');
    const options = {
      urlCommand: `/workflow/tree/${workflowId}/release`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('releasedOrchestration', response);
      const { workflow } = response;
      if (workflow) {
        dispatch(UPDATE_WORKFLOW_STATUS, {
          workflow: workflowId,
          info: PickWorkflowStatus(workflow)
        });
      }
    }).catch(err => {
      const { response: { data } } = err;
      commit('releaseOrchestrationFailed', data);
      // dispatch(LOG_VALIDATE_WORKFLOW, data);
    })
  },
  [STOP_ORCHESTRATION] ({dispatch, commit}, workflowId) {
    commit('requestStoppingOrchestration');
    const options = {
      urlCommand: `/workflow/tree/${workflowId}/release`,
    };
    dispatch('AUTH_DELETE', options).then(response => {
      commit('stoppedOrchestration', response);
      dispatch(UPDATE_WORKFLOW_STATUS, {
        workflow: workflowId,
        info: PickWorkflowStatus(response.data),
      });
    }).catch(err => {
      commit('stopOrchestrationFailed', err.message);
    })
  },
}

export default {
  state: initialState(),
  getters,
  mutations,
  actions
}
