<template>  
<v-list-item
  dense
  class="rounded min-height-24"
  style="margin-bottom:1px;"
  :style="itemStyle"
  :dark="isDarkTheme"
  :class="itemClass"
  @click="onItemClicked"
  :input-value="selected">
  <!-- <v-icon v-if="selected" left>mdi-check-box-outline</v-icon>
  <v-icon v-else left>mdi-square-outline</v-icon> -->
  {{ item.label }}
</v-list-item>
</template>

<script>
export default {
  name: 'categorySectionItem',
  props: {
    item: Object,
    selected: Boolean,
    useColor: Boolean
  },
  computed: {  
    itemClass () {
      const vm = this
      var result = ''
      if (vm.selected) {
        if (vm.useColor) {
          // if use color, use itemStyle to specify color
        } else {
          result = 'white--text'
        }
      }
      return result
    },
    isDarkTheme () {
      const vm = this
      var result = false
      if (vm.selected) {
        if (vm.useColor) {
          result = vm.textTheme === 'dark'
        } else {
          result = true
        }
      }
      return result
    },
    textTheme () {
      return this.itemStyle.color === '#000000' ? 'light' : 'dark'
    },
    itemStyle () {
      const vm = this
      var result = {}
      if (vm.selected) {
        if (vm.useColor) {
          const colorPair = vm.item.colorSet.split('/')
          result = {
            color: colorPair[0],
            backgroundColor: colorPair[1]
          }
        } else {
          result = {
            color: 'white',
            backgroundColor: '#3788d8'
          }
        }
      }
      return result
    }
  },
  methods: {
    onItemClicked () {
      console.log('CategorySectionItem.onItemClicked')
      this.$emit('toggle')
    }
  }

}
</script>