<template>
  <div class="w-list-filter-root">
    <slot v-bind="{ filterInfo, setTable, setKeyword }" />
  </div>
</template>

<script>
export default {
  inject: ["filterInfo", "setKeyword", "setTable"]
};
</script>

<style>
  .w-list-filter-root {
    position: sticky;
    top: 0;
    z-index: 9998;
    background: white;
    left: 0;
  }
</style>
