<template>
<v-menu
    :disabled="relationFieldItems.length===0"
    bottom
    :close-on-content-click="true">
    <template v-slot:activator="{ on, attrs }">
        <div class="d-flex flex-row align-center">
        <v-btn x-small
            v-on="on"
            v-bind="attrs"
            color="primary"
            fab>
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <div v-if="relationFieldItems.length===0"
            class="ml-2 caption grey--text">
            {{ $t('messages.noChildRelationMultipleField')}}
        </div>
        </div>
    </template>
    <v-list dense class="parent-child-selection-list">
        <v-list-item
        @click="selectItem(item)"
        v-for="(item, index) in relationFieldItems"
        :key="index">
        <v-chip class="child-relation-item pr-2" outlined pill :ripple="false">
            <font-awesome-icon icon="compress-alt" class="fa-fw mr-1"></font-awesome-icon>
            {{ item.fieldLabel }}
            <v-chip class="ml-2" small color="warning">
            {{ $t('general.form') }}:&nbsp;
            {{ item.relatedFormName }}
            </v-chip>
        </v-chip>
        </v-list-item>
    </v-list>
</v-menu>
</template>

<script>
export default {
    name: 'parentChildSelectionMenu',
    props: {
        relationFieldItems: {
            type: Array,
            default () {
                return []
            }
        }
    },
    methods: {
        selectItem (item) {
            this.$emit('onSelect', item)
        }
    }
}
</script>


<style>
.parent-child-selection-list .child-relation-item {
    cursor: pointer;
}

</style>