<template>
    <div>
        <v-dialog v-model="bl_dialog" v-if="bl_dialog" width="1800">
            <v-card>
                <v-card-title>
                    {{$t('dashboard.embedUrl')}}
                     <v-btn @click="saveEmbeddedUrl" icon small class="ml-auto">
                        <v-icon>
                            mdi-content-save-outline
                        </v-icon>
                    </v-btn>
                    <v-btn @click="bl_dialog=false" icon small class="ml-5">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text style="height:800px; background-color: #eeeeee" class="pa-0">
                    <div class="d-flex" style="height:100%">
                        <div style="width:80%; height:100%" class="d-flex flex-column justify-center">
                            <div v-if="bl_parseError" class="text-center">{{$t('dashboard.invalidEmbeddedLink')}}</div>
                            <div v-if="iframeUrl=='' && !bl_parseError" class="text-center">{{$t('dashboard.embedDescription')}}</div>
                            <iframe v-if="iframeUrl!=''" id="ifrm" :src="iframeUrl" style="height: 100%; width:100%"></iframe>
                        </div>
                        <v-divider vertical />
                        <div style="width:20%; height:100%; background-color: #f8f8f8">
                            <div class="px-8 mt-5">
                                <span style="font-size:16px">{{$t('dashboard.inputUrl')}}</span>
                                <v-text-field @keydown="handleUrlInputKeydown" v-model="urlInput" hide-details class="mt-3 white url-input" outlined/>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        value: Boolean
    },
    computed:{
        bl_dialog:{
            get(){
                return this.value
            },
            set(val){
                this.$emit('input', val)
            }
        }
    },
    data(){
        return{
            iframeUrl: '',
            urlInput: '',
            bl_parseError: false,
            timeoutID: undefined
        }
    },
    methods:{
        handleUrlInputKeydown(){
            if(this.timeoutID){
                clearTimeout(this.timeoutID)
            }
            this.timeoutID = setTimeout(() => {
                this.set_iframeUrl()
            }, 2000);
        },
        validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        },
        set_iframeUrl(){
            if(this.validURL(this.urlInput)){
                this.iframeUrl = this.urlInput
                this.bl_parseError = false
            }
            else{
                this.iframeUrl = ""
                if(this.urlInput!="")
                    this.bl_parseError = true
                else
                    this.bl_parseError = false
            }
        },
        saveEmbeddedUrl(){
            this.set_iframeUrl()
            this.$emit('update_elements', {
                type: 'add',
                itemType: 'embeddedUrl',
                iframeUrl: this.iframeUrl
            })
            this.bl_dialog = false
        }
    }
}
</script>

<style>
    .url-input .v-input__control .v-input__slot{
        min-height: 30px !important;
    }
</style>