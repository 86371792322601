<template>
  <div>
    <label>{{ label }}</label>
    <dialog-field-date-time
      v-model="propertyValue"
      :allowedDays="allowedDays"></dialog-field-date-time>
  </div>
</template>

<script>
  import mixin from './mixin'
  import dialogFieldDateTime from '@/components/dialogFields/DialogFieldDateTime'

  export default {
    mixins: [mixin],
    components: {
      dialogFieldDateTime
    },
    computed: {
      validations() {
      if (this.fieldInfo.properties && this.fieldInfo.properties.validation) {
        return this.fieldInfo.properties.validation.split("||");
      } else return [];
    },
    allowedDays() {
      if (this.validations.includes("limitWeekday")) {
        return this.fieldInfo.properties.validation_weekday.split("||");
      }
      else return ["0", "1", "2", "3", "4", "5", "6"];
    }    
    }
  }
</script>

