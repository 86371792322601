<template>
  <vxe-table-column
    v-bind="colDef"
    :edit-render="{
      name: rowType === 'single' ? 'input' : 'textarea',
      autoselect: true,
      enabled: !readonly
    }"
    sortable
    :show-overflow="rowType === 'single' ? 'ellipsis' : false"
    class-name="fullCell vxeCellText"
  >
    <template v-slot="{ row }">
      <!-- :class="rowType === 'single' ? '' : rowHeightClass" -->
      <!-- :style="rowType === 'single' ? '' : 'overflow:hidden'" -->
      <div
        style="overflow:hidden;"
        class="px-2 d-flex fill-height"
        :class="rowHeightClass + (rowType==='single' ? ' align-center' : ' align-top')"   
      >
        <span
          v-if="row[fieldId] && prefix"
          class="pa-2"
          style="color: #b5b5b5"
          >{{ prefix }}
        </span>
        <span>{{ row[fieldId] }}</span>
        <span
          v-if="row[fieldId] && suffix"
          class="pa-2"
          style="color: #b5b5b5"
          >{{ suffix }}
      </span>
        <v-chip x-small v-if="debugging" color="primary">
          {{ row._id }}
        </v-chip>
      </div>
      <quick-action v-if="quickInsert" :rowId="row['_id']" :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{ row }">
      <div style="height:100%" class="">
        <vxe-input
          v-if="rowType == 'single'"
          style="height:100%"
          :readonly="readonly"
          type="text"
          v-model="row[fieldId]"
          @blur="onBlur(row, fieldId)"
        />
        <!-- <textarea v-else v-model="row[fieldId]" :rows="rows" class="white" :style="`width:${column.renderWidth - 20}px;`"></textarea> -->
        <textarea
          v-else
          outlined
          v-model="row[fieldId]"
          @blur="onBlur(row, fieldId)"
          :rows="rows"
          :readonly="readonly"
          class="white pa-2"
          style="width:100%; height:99%; color: #606266; font-size:14px"
        ></textarea>
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import quickAction from "./comps/QuickAction";
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  components: { 
    quickAction,
    summaryCell
  },
  props: {
    rowHeightClass:{
      type: String,
      default: 'vxe-table-row-xs'
    }
  },

  computed: {
    debugging() {
      return this.$store.getters.debugging;
    },
    rowType() {
      return this.getPropertyValue("rowType", "single");
    },
    rows() {
      return this.getPropertyValue("rowCount", 5);
    },
    height() {
      return this.rowHeightMap[this.rowHeight];
    },
  },
  methods:{
    onBlur(row, fieldId){
      row[fieldId] =row[fieldId].trim()
    }
  }
};
</script>

<style>
.vxeCellText .vxe-cell {
  width: 100%;
  height: 100%;
  padding: 0;
}

.vxeCellText .auto-adjust-height {
  position: absolute;
  overflow: hidden;
  width: 100%; 
  height: 100% !important;
}

.vxeCellText .vxe-input input{
  padding: 8px !important;
}
</style>
