<template>
  <div>
    <div v-if="title" class="caption grey--text" :style="colorStyle">
      <small>{{ title }}</small>
    </div>
        <vue-nestable keyProp="_id"
                      @change="onDropped"
                      v-model="childFieldInfos"
                      group="fields"
                      :style="colorStyle"
                      class="d-flex flex-column"
                      placeholder=""
                      :hooks="hooks">
          <!--<div style="height:50px;width:100%;"-->
               <!--:style="colorStyle"></div>-->
          <template v-slot:placeholder>
            <div class="text-center caption">-&nbsp;{{ $t('general.none')}}&nbsp;-</div>
          </template>
        <vue-nestable-handle
            slot-scope="{item,index}"
            :draggable="inputItem!==item && !inputing"
            class="d-flex flex-row align-center"
            :item="item">
          <v-icon class="mr-1">mdi-format-line-spacing</v-icon>
           <font-awesome-icon :icon="getFieldWidgetIcon(item)" class="fa-fw mr-1"></font-awesome-icon>
          <input :value="item.label"
                 @focus="inputing=true"
                 @blur="inputing=false"
                 class="field-label-input flex-grow-1"
                 @input="$evt=>updateField(index, $evt.target.value, 'label')"/>
                 <!--@focus="$evt=>onInputFocus($evt,item)"-->
                 <!--@blur="$evt=>onInputBlur($evt,item)"-->

          <v-btn x-small
                 :disabled="!isNumericField(item)"
                 min-width="20px"
                 class="pa-0"
                 :class="calcSumClass(item)"
                 @click="toggleField(item, 'calcSum')">
            <v-icon x-small v-if="isNumericField(item)">mdi-sigma</v-icon>
            <v-icon x-small v-else>mdi-minus</v-icon>
          </v-btn>

          <v-btn x-small icon class="ml-1 muted"
            :class="titleButtonClass(item)"
             @click="setTitle(item)">
            T
          </v-btn>

          <v-btn x-small icon color="primary" @click="editField(item)">
            <v-icon>mdi-file-document-edit</v-icon>
          </v-btn>

          <v-btn x-small icon color="error" @click="removeField(item)">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </vue-nestable-handle>
      </vue-nestable>
</div>
</template>

<script>
import widgetHelperMixin from '@/mixins/widgetHelperMixin'
import {VueNestable, VueNestableHandle} from 'vue-nestable'

export default {
  mixins: [
    widgetHelperMixin
  ],
  components: {
    VueNestable,
    VueNestableHandle
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {
    childFieldInfos: {
      get () {
        return this.value
      },
      set (val) {
        console.log('WitTableFieldList :: computed(childFieldInfos) set: val: ', val)
        this.$emit('input', val)
      }
    },
    colorStyle () {
      const vm = this
      return {'backgroundColor': vm.color}
    }
  },
  data () {
    return {
      inputing: false
    }
  },
  props: {
    id: String,
    title: String,
    fieldType: String,
    fieldInfoId: String,
    titleFieldInfoId: String,
    inputItem: Object,
    value: {
      type: Array,
      default () {
        return []
      }
    },
    color: {
      type: String,
      default: 'transparent'
    }
  },
  watch: {
    // value: function(newVal) {
    //   const vm = this
    //   vm.theFields = newVal
    // }
    // ,
    // theFields: function(newVal) {
    //   const vm = this
    //   console.log('FieldList (' + vm.fieldType + '): theFields changed: ' +
    //     vm.value.length + ' => ' + newVal.length)
    //
    //   console.log('FieldList (' + vm.fieldType + '): JSON(vm.value): ' + JSON.stringify(vm.value))
    //   console.log('FieldList (' + vm.fieldType + '): JSON(newVal): ' + JSON.stringify(newVal))
    //
    //   // if (JSON.stringify(newVal) != JSON.stringify(vm.value)) {
    //   //   vm.$emit('onCommand', {
    //   //     command: 'updatePropertyValue',
    //   //     fieldType: vm.fieldType,
    //   //     newFields: vm.theFields
    //   //   })
    //   // }
    // }
  },
  // mounted () {
  //   const vm = this
  //   vm.theFields = vm.value
  // },
  methods: {
    setTitle (item) {
      this.$emit('onCommand', {
        command: 'setTitle',
        field: item,
      })
    },
    titleButtonClass (item) {
      const vm = this
      return item._id === vm.titleFieldInfoId ?
        'primary white--text' : 
        'muted'
    },

    calcSumClass (item) {
      const vm = this
      var result = 'lightgray grey--text'
      if (vm.isNumericField(item) && item.calcSum) {
        result = 'primary white--text'
      }
      return result
    },
    isNumericField (item) {
      const result = ['number','amount'].indexOf(item.type) >= 0
      return result
    },
    toggleField (item, fieldName) {
      const vm = this
      const newVal = item[fieldName] ? false : true
      console.log('toggleField (' + vm.fieldType + '): newVal = ' + newVal)
      vm.$emit('onCommand', {
        command: 'updateField',
        fieldType: vm.fieldType,
        field: item,
        fieldName: fieldName,
        fieldValue: newVal
      })
    },
    // onDropped (value, option) {
    //   const vm = this
    //   console.log('onDropped: value: ', value)
    //   console.log('onDropped: option.items: ', option.items.map(a => a.label).join(','))
    //   vm.$emit('onCommand', {
    //     command: 'onItemDropped',
    //     fieldType: vm.fieldType,
    //     fields: vm.childFieldInfos,
    //     item: value
    //   })
    // },
    updateInput (val) {
      const vm = this
      vm.$emit('input', val)
    },
    getFieldWidgetIcon (item) {
      const vm = this
      // console.log('WitTableFieldList :: getFieldWidgetIcon :: item: ', item)
      const widget = vm.getWidget(item.widgetId)
      // console.log('getFieldwidgetIcon :: item.widgetId = ' + item.widgetId)
      // console.log('getFieldwidgetIcon :: vm.widgetGroups.length = ' + vm.widgetGroups.length)
      return widget.icon
    },
    onInputFocus (evt, item) {
      const vm = this
      vm.$emit('onCommand', {
        command: 'updateInputItem',
        fieldInfoId: vm.fieldInfoId,
        childInputItem: item
      })
    },

    onInputBlur (evt, item) {
      const vm = this
      if (vm.inputItem === item) {
        vm.$emit('onCommand', {
          command: 'updateInputItem',
          inputItem: null
        })
      }
    },

    updateField (index, val, fieldName) {
      const vm = this
      console.log('WidgetInputTypeTableFieldList :: updateField index = ' + index + ', val=' + val + ', fieldName = ' + fieldName)
      if (typeof fieldName === 'undefined') {
        fieldName = 'fieldName'
      }
      const onCommandParams = {
        command: 'updateField',
        fieldType: vm.fieldType,
        fieldIndex: index,
        field: vm.childFieldInfos[index],
        attributeName: 'fieldName',
        fieldValue: val
      }
      console.log('updateField :: onCommandParams: ', onCommandParams)
      vm.$emit('onCommand', onCommandParams)
    },

    editField (item) {
      const vm = this
      // console.log('witTableFieldList.editField :: item: ', item)
      vm.$store.dispatch('EDIT_CHILD_TABLE_FIELD', {
        childTableInfoId: vm.fieldInfoId,
        childTableFieldInfoId: item.fieldId
      })
      // console.log('widgetInputTypeTableFieldList :: editField :: item: ', item)
      // this.$emit('onCommand', {
      //   command: 'editField',
      //   fieldType: vm.fieldType,
      //   item: item
      // })
    },

    removeField (item) {
      const vm = this
      // console.log('widgetInputTypeTableFieldList :: removeField :: item: ', item)
      this.$emit('onCommand', {
        command: 'removeField',
        fieldType: vm.fieldType,
        item: item
      })
    }

  }
}
</script>

<style>
  .nestable > .nestable-list {
    flex-grow: 1;
  }

  .nestable-list-empty {
    display: block !important;
  }

  input.field-label-input {
    width: 140px;
  }

</style>
