<template>
  <div class="d-flex flex-column fill-height">
    <div class="flex-grow-1 d-flex flex-column" style="position:relative;">
      <div class="flex-grow-0 px-1 my-1 d-flex flex-row align-center justify-space-between">
        <div class="d-flex flex-row align-center">

          <!-- NEW FILTER -->
          <new-menu-filter-criteria-field
            :fieldInfos="fieldInfos"
              ref="menuFilterCriteriaField"
              @addFilter="addFilterHandler">
          </new-menu-filter-criteria-field>

          <!-- DELETE ALL FILTERS -->
          <v-btn  v-if="userFilters.length > 0"
                  class="mb-1 mx-1 my-0 elevation-0"
                  dark x-small fab
                  color="error"
                  @click.native="deleteAllFilters()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

        </div>

        <div v-if="userFilters.length > 0">
           <input-radio-toggle :options="andOrOptions"            
                               v-model="userFilterAndOr"/>
        </div>
      </div>
      <div ref="filterListContainer"
           class="flex-grow-1" style="height:0;;min-height:100px;overflow-y:auto;">
        <v-list ref="filterList">
          <filter-widget
              :ref="'filter_'+index"
              v-for="(filter, index) in userFilters"
              :key="index"
              :andOrOptionLabel="getAndOrOptionLabel(userFilterAndOr)"
              :fields="fieldIdsWithProperties"
              :filterIndex="index"
              :filter="filter"
              :form="form"
              @input="val=>updateFilter(index,val)"
              @onCommand="onCommandHandler"></filter-widget>
        </v-list>
      </div>
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </div>
</template>

<script>
import filterWidget from '@/components/filterWidgets/FilterWidget'
// import filterWidgets from './filterWidgets'
import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
import newMenuFilterCriteriaField from '@/components/NewMenuFilterCriteriaField'

import adminFormMixin from '@/mixins/adminFormMixin'
import filterHelperMixin from '@/mixins/filterHelperMixin'
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'

export default {
  mixins: [adminFormMixin, filterHelperMixin],
  components: {
    filterWidget,
    inputRadioToggle,
    newMenuFilterCriteriaField,
    confirmDeleteDialog
  },
  props: {
    filters: {
      type: Array,
      default () {
        return []
      }
    },
    filterAndOr: {
      type: String,
      default: 'and'
    },
    fieldInfos: {
      type: Array,
      default () {
        return []
      }
    },
    withAndOrOption: {
      type: Boolean,
      default: true
    }

  },
  methods: {
    deleteAllFilters () {
      const vm = this
      vm.$refs.confirmDeleteDialog.confirm(() => {
        vm.$emit('deleteAllFilters')
      }, {
        title: vm.$t('view.deleteAllFilterConditions')
      })
    },

    _checkErrorCount (state) {
      const vm = this
      var errorCount = 0
      const filterFields = Object.keys(vm.$refs).filter(item => item.indexOf('filter_') === 0)

      // console.log('ViewSettingsFilters :: filterFields count = ' + filterFields.length)
      for (var i = 0; i < filterFields.length; i++) {
        const loopFilterFieldName = filterFields[i]
        var refComp = vm.$refs[loopFilterFieldName]
        if (refComp instanceof Array) {
          // console.log('ViewSettingsFilters :: validate :: refComp is instance of array')
          refComp = refComp[0]
        }
        if (refComp) {
          // console.log('checkErrorCount :: refComp: ', refComp)
          if (!refComp.checkIsValid(true)) {
            errorCount++
          }
        }
      }
      // console.log('ViewSettingsFilters :: errorCount = ' + errorCount)
      return errorCount
    },
    getAndOrOptionLabel (andOr) {
      const vm = this
      var result = 'and'
      const andOrOption = vm.andOrOptions.find(item => item.value === andOr)
      if (andOrOption) {
        result = vm.$t(andOrOption.labelTag)
      }
      return result
    },
    getFilterWidgetByFieldId (fieldId) {
      const vm = this
      return 'filterWidgetString'
    },
    scrollToLastFilter () {
      const vm = this
      vm.$nextTick(() => {
        const container = vm.$refs.filterListContainer
        container.scrollTop = container.scrollHeight
      })
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'deleteFilter':
          vm.$emit('deleteFilter', payload.filterIndex)
          break
        // case 'updateFilter':
        //   vm.view.filters[payload.filterIndex] = payload.filter
        //   break
        // case 'updateAndOrOption':
        //   vm.view.filterAndOr = payload.andOrOption
        //   break
        default:
          vm.$emit('onCommand', payload)
      }
    },
    addFilterHandler (fieldId) {
      const vm = this
      vm.$emit('addFilter', {
        fieldId: fieldId,
        callback: () => {
          vm.scrollToLastFilter()
        }
      })
    }
  },
  computed: {
    userFilters: {
      get () {
        const vm = this
        var result = []
        if (vm.filters) {
          result = vm.filters
        }
        return result
      },
      set (val) {
        const vm = this
        vm.$emit('updateFilters', val)
      }
    },
    userFilterAndOr: {
      get () {
        return this.filterAndOr
      },
      set (val) {
        this.$emit('updateFilterAndOr', val)
      }
    },
    fieldIdsWithProperties () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i]
        // console.log('ViewSettingsFilters :: loopFieldInfo = ' + loopFieldInfo.label)
        const filterType = vm.getFilterKeyByFieldInfo(
          vm.$store.getters.widgetMap,
          loopFieldInfo)

        if (filterType) {
          result.push({
            id: loopFieldInfo.fieldId,
            label: loopFieldInfo.label,
            filterType: filterType,
            fieldInfo: loopFieldInfo
          })
        }
      }
      return result
    },

  },
  data () {
    return {
      andOrOptions: [
        {labelTag: 'general.and', value: 'and'},
        {labelTag: 'general.or', value: 'or'}
      ]
    }
  }

}
</script>
