<template>
<!-- d-flex -->
  <div 
    class="fs-text text-no-wrap overflow-hidden" style="text-overflow: ellipsis">
    <label v-if="useLabel"
          class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    <span>{{ prefix }}&nbsp;</span>{{ titleValue }}
    <span>&nbsp;{{ suffix }}</span>
  </div>
</template>

<script>
import mixin from './mixin'
import {getFieldPropertyValue} from "../../helpers/ObjectHelpers";

export default {
  name: 'fsText',
  mixins: [mixin],
  computed: {
    label () {
      const vm = this
      var result = ''
      if (vm.fieldInfo && vm.fieldInfo.properties && vm.fieldInfo.properties.fieldName) {
        result = vm.fieldInfo.properties.fieldName
      }
      return result
    },
    prefix () {
      return getFieldPropertyValue(this.fieldInfo, 'prefix', '')
    },
    suffix () {
      return getFieldPropertyValue(this.fieldInfo, 'suffix', '')
    }
  }
}
</script>
