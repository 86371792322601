<template>
  <div class="d-flex flex-row fill-height">
    <!-- LEFT -->
    <div class="d-flex flex-column flex-shrink-0"  v-if="!bl_hide_menu">
      <table-setup-menu
        :menuSections="menuSections"
        :selectedMenuItem="selectedMenuItem"
        @onCommand="onCommandHandler"></table-setup-menu>
    </div>

    <!-- CONTENT -->
    <div v-if="selectedMenuItem" style="min-width:0px;flex:1">
      <TableSetupFunctionToggle v-if="selectedMenuItem.key==='setFunctionToggle'"/>
      <TableSetupDisplayRules 
        :formFieldInfos="formFieldInfos" 
        :formUIElementInfos="formUIElementInfos"
        v-else-if="selectedMenuItem.key==='setDisplayRules'"
      />
      <TableSetupPrintTemplate v-else-if="selectedMenuItem.key==='setPrintTemplate'" />
    </div>

    <!-- PROPERTY -->
    <div class="d-flex flex-column" style="border-left:1px solid rbga(0,0,0,.05);">

    </div>
  </div>
</template>

<script>
import tableSetupMenu from './tableSetup/TableSetupMenu'
import TableSetupFunctionToggle from './tableSetup/TableSetupFunctionToggle'
import TableSetupPrintTemplate from './tableSetup/TableSetupPrintTemplate'
import TableSetupDisplayRules from './tableSetup/TableSetupDisplayRules'

export default {
  // mixins: [mixin],
  components: {
    tableSetupMenu,
    TableSetupFunctionToggle,
    TableSetupPrintTemplate,
    TableSetupDisplayRules,
  },
  data () {
    return {
      selectedMenuItem: null,
      bl_hide_menu: false,
      menuSections: [
        {
          titleTag: 'tableSetup.basicSettings',
          menu: [
            {
              labelTag: 'tableSetup.functionToggle',
              key: 'setFunctionToggle',
              footNote: '',
              icon: 'mdi-table-settings',
              route: 'functionToggle',
              routeName: 'adminTableSetupFunctionToggle'
            }
          ]
        },
        {
          titleTag: 'tableSetup.advancedSettings',
          menu: [
            {
              labelTag: 'tableSetup.displayRules',
              key: 'setDisplayRules',
              footNote: '',
              icon: 'mdi-format-list-bulleted',
              route: 'displayRules',
              routeName: 'adminTableSetupDisplayRules'
            },
            {
              labelTag: 'tableSetup.printTemplate',
              key: 'setPrintTemplate',
              footNote: '',
              icon: 'mdi-printer',
              route: 'printTemplate',
              routeName: 'adminTableSetupPrintTemplate'
            },
          ]
        }
      ]

    }
  },
  created(){
    let vm = this
    var BreakException = {}
    try{
      this.menuSections.forEach(section=>{
        var res = section.menu.find(menu=>{
          return menu.routeName == vm.$route.name
        })
        if(res) {
          vm.selectedMenuItem = res
          throw BreakException
        }
      })
    }catch(e){
      if (e !== BreakException) throw e;
    }
  },
  mounted () {
    const vm = this
    if (vm.selectedMenuItem === null) {
      vm.selectedMenuItem = vm.menuSections[0].menu[0]
    }
  },
  computed: {
    form () {
      return this.$store.getters.currentForm
    },
    formInfo () {
      return this.$store.getters.formInfo
    },
    titleFieldInfoId () {
      return this.$store.getters.formInfo.titleFieldInfoId
    },
    formUIElementInfos() {
      return this.$store.getters.formUIElementInfos
    },
    formFieldInfos () {
      return this.$store.getters.formFieldInfos
    },
    fields () {
      const vm = this
      var result = []
      if (vm.formFieldInfos) {
        for (var i = 0; i < vm.formFieldInfos.length; i++) {
          const loopInfo = vm.formFieldInfos[i]
          result.push({
            text: loopInfo.label,
            value: loopInfo._id
          })
        }
      }
      return result
    }
  },
  methods: {
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'selectMenuItem':
          vm.selectedMenuItem = payload.menuItem
          break
        default:
          this.$emit('onCommand', payload)
          break;
      }
    }
  }
}
</script>
      <!-- <v-row  v-if="selectedMenuItem.key==='setRecordTitle'">
      </v-row> -->
