<template>
  <div class="fs-amount pa-0 text-no-wrap overflow-hidden d-flex flex-row align-center">
    <label v-if="useLabel"
           class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    <div class="flex-grow-1">
      <span v-if="prefix" class="pa-2" style="color: #b5b5b5">{{prefix}}</span>
      <span>{{ formattedValue }}</span>
      <span v-if="suffix" class="pa-2" style="color: #b5b5b5">{{ suffix }}</span>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsNumber',
  mixins: [mixin]
}
</script>
