<template>
  <div class="pa-2" style="height:100%; width:100%; overflow: auto;">
    <v-btn @click="exportTable" :data-caption="chartName" :id="`export-${htmlDomId}`" style="display: none;">export</v-btn>
    <table :id="`table-${htmlDomId}`" class="chartVxeTable" style="transform-origin: 0 0;" :style="'transform: scale('+scale+')'">
      <thead class="" :id="`${tableHeaderId}${(tableIndex==0)?'':'-'+tableIndex}`" style="white-space: nowrap;">
      </thead>
      <tbody :id="`${tableBodyId}${(tableIndex==0)?'':'-'+tableIndex}`" style="white-space: nowrap;">
      </tbody>
      <tfoot :id="`${tableFooterId}${(tableIndex==0)?'':'-'+tableIndex}`">
        <tr>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import TableToExcel from "@linways/table-to-excel";
import EventBus from '@/event-bus.js'
import UUID from 'uuid'
export default {
  props:{
    titleTree:{
        type: Array,
        default: ()=> []
    },
    tableData: {
        type: Array,
        default:()=> []
    },
    mergeCells:{
        type: Array,
        default: ()=>[]
    },
    arrX: Array,
    arrY: Array,
    arrG: Array,
    bl_fetch: {
      type: Boolean,
      default: false
    },
    tableIndex:{
      type: Number,
      default: 0
    },
    scale:{
      type: Number,
      default:1
    },
    tableFooters: Array,
    chartId:{
      type: String,
      default: null
    },
    appId: String,
    formId: String,
    bl_summary: Boolean,
    chartName: String
  },
  data () {
    return {
      searchName: '',
      gridOptions: {
        border: true,
        resizable: true,
        keepSource: true,
        showOverflow: true,
        loading: false,
        height: 530,
        id: 'toolbar_demo_2',
        editConfig: {
          trigger: 'click',
          mode: 'row',
          showStatus: true
        },
        customConfig: {
          storage: true
        },
        toolbarConfig: {
          custom: true,
          slots: {
            buttons: 'toolbar_buttons'
          }
        },
        data: []
      },
      instanceId: UUID.v4()
    }
  },
  computed:{
    // fields(){
    //   return this.$store.getters.currentForm.fieldInfos
    // },
    relatedTableInfos(){
      return this.$store.getters.relatedTableInfos
    },
    tableBodyId(){
      return 'chartVxeTableBody' + (this.chartId? '_'+this.htmlDomId: '') 
    },
    tableHeaderId(){
      return 'chartVxeTableHeader' + (this.chartId? '_'+this.htmlDomId: '') 
    },
    tableFooterId(){
      return 'chartVxeTableFooter' + (this.chartId? '_'+this.htmlDomId: '') 
    },
    htmlDomId(){
      return `${this.chartId}_${this.instanceId}`
    }
  },
  beforeDestroy() {
    EventBus.$off(`exportTable-${this.htmlDomId}`) 
  },
  mounted(){
    EventBus.$on(`exportTable-${this.htmlDomId}`, this.exportTable)
    console.log('this.tableData', JSON.stringify(this.tableData))
    const loopFooterTree = (titleArr,count, trObjs)=>{
      let sum = 0
      for(var i=0; i<titleArr.length; i++){
        var currSum = 0
        if(titleArr[i].hasOwnProperty("children")){
          currSum = loopFooterTree(titleArr[i].children,count+1, trObjs)
          sum+= currSum
        }else{
          sum+= this.arrG.length
          this.arrG.forEach(item=>{
            this.tableFooters.forEach((footer,index)=>{
              var dataTh = document.createElement("th")
              var summaryTitleCellColSpan = 0 
              if(trObjs[index].firstChild)
                summaryTitleCellColSpan = trObjs[index].firstChild.colSpan
              var tarCol = trObjs[index].childElementCount+summaryTitleCellColSpan
              var dataArr = this.tableData.map(d=>d[tarCol-1])
              
              var total = dataArr.reduce((a,c)=>{
                if(c) a+= parseFloat(c)
                return a
              },0)
              dataTh.innerText = total
              trObjs[index].append(dataTh)
            })
          })
        }
        // trObjs[count].append(thEle)
      }
      return sum
    }

    const loopTitleTree = (titleArr,count, trObjs)=>{
      let sum = 0
      for(var i=0; i<titleArr.length; i++){
        var currSum = 0
        var thEle = document.createElement("th")
        thEle.innerText = titleArr[i].title
        thEle.style.minWidth = "200px"
        if(titleArr[i].hasOwnProperty("children")){
          currSum = loopTitleTree(titleArr[i].children,count+1, trObjs)
          sum+= currSum
          thEle.setAttribute("colspan", currSum)
        }else{
          // sum+=1
          thEle.setAttribute("colspan", this.arrG.length)
          sum+= this.arrG.length
          this.arrG.forEach(item=>{
            var dataTh = document.createElement("th")
            // dataTh.innerText = this.getFieldName(item)
            dataTh.innerText = (item.type === "recordCount")? this.$t("chart.recordCount"):item.label
            trObjs[count+1].append(dataTh)
          })
          
        }
        trObjs[count].append(thEle)
      }
      return sum
    }
    this.$nextTick(() => {
      var query = "#"+ this.tableHeaderId + ((this.tableIndex==0)?'':'-'+this.tableIndex)
      var query2 = "#" + this.tableFooterId + ((this.tableIndex==0)?'':'-'+this.tableIndex)
      var headerHook = document.querySelector(query)
      var footerHook = document.querySelector(query2)

      // console.log("headerHook", headerHook)
      var trObjs = this.arrY.map(item=>{
        return document.createElement("tr")
      })
      trObjs.push(document.createElement("tr"))
      
      var trObjs2 = this.tableFooters.map(item=>{
        return document.createElement("tr")
      })
      // var trObjs2 = [document.createElement("tr")]

      // console.log('trObjs---', trObjs)
      // console.log('trObjs2---', trObjs2)
      
      this.arrX.forEach(item=>{
        var thEle = document.createElement("th")
        // thEle.innerText = this.getFieldName(item)
        thEle.innerText = item.label
        thEle.setAttribute("rowspan", this.arrY.length+1)
        trObjs[0].append(thEle)
        
        this.tableFooters.forEach((footer,index)=>{
          if(trObjs2[index].childElementCount==0){
            var thEle2 = document.createElement("th")
            thEle2.innerText = footer.name
            trObjs2[index].append(thEle2)
          }else{
            var summaryTitleCell = trObjs2[index].firstChild
            summaryTitleCell.setAttribute("colspan", summaryTitleCell.colSpan+1)
          }
        })
      })

      if(this.arrY.length){
        loopTitleTree(this.titleTree, 0, trObjs)
        loopFooterTree(this.titleTree, 0, trObjs2)
      }else{
        this.arrG.forEach(item=>{
          var dataTh = document.createElement("th")
          // dataTh.innerText = this.getFieldName(item)
          dataTh.innerText = (item.type === "recordCount")? this.$t("chart.recordCount"):item.label
          trObjs[0].append(dataTh)
          
          this.tableFooters.forEach((footer, index)=>{
            var dataTh2 = document.createElement("th")
            var summaryTitleCellColSpan = 0 
            if(trObjs2[index].firstChild)
              summaryTitleCellColSpan = trObjs2[index].firstChild.colSpan
            var tarCol = trObjs2[index].childElementCount+summaryTitleCellColSpan
            var dataArr = this.tableData.map(d=>d[tarCol-1])
            
            switch(footer.value){
              case 'SUM':
                var total = dataArr.reduce((a,c)=>{
                  if(c) a+= parseFloat(c)
                  return a
                },0)
                dataTh2.innerText = total.toFixed(2)
              break
              case 'AVG':
                var avg = dataArr.reduce((a,c)=>{
                  if(c) a+= parseFloat(c)
                  return a
                },0)
                if(avg>0)
                  avg = avg/dataArr.length
                dataTh2.innerText = avg.toFixed(2)
                break
              case 'MAX':
                dataArr = dataArr.filter(data=>{
                  return parseFloat(data)
                })
                dataArr = dataArr.map(data=>parseFloat(data))
                var max = Math.max(...dataArr)
                dataTh2.innerText = max.toFixed(2)
                break
              case 'MIN':
                dataArr = dataArr.filter(data=>{
                  return parseFloat(data)
                })
                dataArr = dataArr.map(data=>parseFloat(data))
                var min = Math.min(...dataArr)
                dataTh2.innerText = min.toFixed(2)
                break
            }
            
            // var total = dataArr.reduce((a,c)=>{
            //   if(c) a+= parseFloat(c)
            //   return a
            // },0)
            // dataTh2.innerText = total
            trObjs2[index].append(dataTh2)
          })
        })
      }
      console.log('footerHook', footerHook)
      trObjs2.forEach(item=>{
        footerHook.appendChild(item)
      })
      console.log('headerHook', headerHook)
      trObjs.forEach(item=>{
        headerHook.appendChild(item)
      })
      
      // query = "#chartVxeTableFooter" + ((this.tableIndex==0)?'':this.tableIndex)
      // var footerHook = document.querySelector(query)
      
      // var trObjs = this.arrY.map(item=>{
      //   return document.createElement("tr")
      // })
      // trObjs.push(document.createElement("tr"))
      // this.arrX.forEach(item=>{
      //   var thEle = document.createElement("th")
        
      //   thEle.innerText = this.getFieldName(item)
      //   thEle.setAttribute("rowspan", this.arrY.length+1)
      //   trObjs[0].append(thEle)
      // })
      // if(this.arrY.length)
      //   loopTitleTree(this.titleTree, 0, trObjs)
      // else{
      //   this.arrG.forEach(item=>{
      //     var dataTh = document.createElement("th")
      //     dataTh.innerText = this.getFieldName(item)
      //     trObjs[0].append(dataTh)
      //   })
      // }
      // trObjs.forEach(item=>{
      //   console.log('trObjs 1')
      //   footerHook.appendChild(item)
      //   console.log('trObjs 2')
      // })
      
      query = "#" + this.tableBodyId + ((this.tableIndex==0)?'':'-'+this.tableIndex)
      var bodyHook = document.querySelector(query)
      this.tableData.forEach((item,array)=>{
        var tr = document.createElement("tr")
        tr.classList.add("bodyRow")
        item.forEach((item2,index)=>{
          var td = document.createElement("td")
          if(index<this.arrX.length)
            td.classList.add("x_" +(index+1))
            td.innerText = item2?((index+1>this.arrX.length)?(Number(item2)?Number(item2).toFixed(2):item2):item2):'-'
          td.style.textAlign = "center"
          td.setAttribute('data-a-h', 'center')
          // console.log('tr.appendChild 1')
          tr.appendChild(td)
          // console.log('tr.appendChild 2')
        })
        // console.log('bodyhook.appendChild 1')
        bodyHook.appendChild(tr)
        // console.log('bodyhook.appendChild 2')
      })
      
      var trs = document.querySelector('#'+this.tableBodyId).childNodes
      this.mergeCells.forEach(item=>{
        var tarRow = trs[item.row]
        var tarCell = tarRow.querySelector('.x_'+(item.col+1))
        if(tarCell){
          tarCell.setAttribute('rowspan', item.rowspan)
          for(var i = 1; i<=item.rowspan-1; i++){
            tarRow = trs[item.row+i]
            tarCell = tarRow.querySelector('.x_'+(item.col+1))
            tarCell.remove()
          }
        }
      })
        
      // })
      // this.arrX.forEach((item, index)=>{
      //   if(index != (this.arrX.length-1)){
      //     var className = 'x_' + (index+1)
      //     var first_row = '<tr class="bodyRow"><td class="' + className+ '">NULL</td></tr>';
      //     var rowCount = 0;
      //     var rowSum = 0;
      //     $.each($('.bodyRow'), function (index, curRow) {
      //         console.log(index)
      //         if ($(first_row).find('.' + className).text() != $(curRow).find('.'+className).text()) {
      //             if (rowCount > 1) {
      //                 $(first_row).find('.' + className).attr('rowspan', rowCount).css('background-color','silver');
      //                 console.log(rowCount)
      //                 for (var i = 0; i < rowCount-1; i++) {
      //                   $(first_row).next('.bodyRow').find('.' + className).remove()
      //                   first_row = $(first_row).next('.bodyRow')
      //                 }
      //             }
      //             first_row = $(curRow);
      //             rowCount = 0;
      //         }
      //         rowCount += 1;
      //     });
      //     if (rowCount > 1) {
      //         $(first_row).find('.' + className).attr('rowspan', rowCount).css('background-color','silver');
      //         for (var i = 0; i < rowCount; i++) {
      //           $(first_row).next('.bodyRow').find('.' + className).remove();
      //           first_row = $(first_row).next('.bodyRow')
      //         }
      //     }
      //   }
      // })
    })
    // this.gridOptions.columns = this.titleTree
  },
  methods:{
    exportTable(){
      const tableId = `table-${this.htmlDomId}`
      TableToExcel.convert(document.getElementById(tableId), {
        name: `${this.chartName}.xlsx`,
        sheet: {
          name: "Sheet 1"
        }
      });
    },
    getFieldName(field){
      var res = this.fields.find(item=>item.fieldId==field._id)
      if(field.type=='recordCount')
        return 'Record Count'
      if(res){
          if(res.type=='relatedRecord'){
              var dataTableId = res.properties.dataSource
              var relatedField = this.relatedTableInfos[dataTableId].fieldInfos.find(item=>item._id==field.relatedId)
              if(relatedField){
                return res.label + ' - ' + relatedField.label
              }
          }else if(res.type=='childTable'){
            var childFieldInfos = res.properties.fieldsEditor.fieldInfos
            var childField = childFieldInfos.find(item=>item._id==field.relatedId)
            if(childField) return res.label + ' - ' + childField.label
          }
          return res.label   
      }
      // return this.fields.find(item=>item._id == field._id).label
    }
  }
}
</script>


<style>
    .chartTable-scrollbar::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-track {
        background-color: #FFFFFF !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-thumb {
        background-color: #bfbfbf !important;
        border-radius: 5px !important;
        border: 1px solid #F1F1F1 !important;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: #A8A8A8 !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-thumb:active {
        background-color: #787878 !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-corner {
        background-color: #FFFFFF !important;
    }
    .chartVxeTable{
      border: 1px solid black;
      border-collapse: collapse;
      table-layout: auto;

    }
    .chartVxeTable th, .chartVxeTable td{
      border: 1px solid black;
      padding: 1px 10px;
    }
    /* border-collapse: collapse; */
</style>