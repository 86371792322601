<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :isHidden="isHidden"
    :required="!isReadOnly && required"
    :fieldWidth="fieldWidth"
    :label="fieldName"
    :noIcon="noIcon"
    :tooltipText="description"
  >
    <v-text-field
      v-if="isReadOnly"
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
      :value="RegionText"
      class="disabled"
      disabled
      hide-details
      dense
    ></v-text-field>
    <div
      v-else
      :id="`iw-${fieldId}`"
      :class="{ 'workflow-customize-form-record-hide': hideInput }"
    >
      <RegionMenu
        :fieldInfo="fieldInfo"
        :value="value"
        :regions="regions"
        :readonly="isReadOnly"
        :required="required"
        ref="regionMenu"
        :rules="rules"
        :hasError="hasError"
      />
    </div>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import RegionMenu from "./comps/RegionMenu";

export default {
  name: "inputWidgetRegion",
  mixins: [mixin],
  components: {
    inputWidgetContainer,
    RegionMenu,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data() {
    return {
      regions: [],
      RULE_REQUIRED: (v) => !v ? this.$t("messages.thisFieldIsRequired"): true
        // !v || !v.province ? this.$t("messages.thisFieldIsRequired") : true
    };
  },
  computed: {
    RegionText() {
      var str = "";
      let vm = this;
      if (vm.value) {
        if (vm.value.province) {
          var area = vm.regions.find((item) => item._id == vm.value.province);
          if (area) {
            str = area.name;
            var district = area.districts.find(
              (item) => item._id == vm.value.city
            );
            if (district) {
              str += " - " + district.name;
              var subDistrict = district.subDistricts.find(
                (item) => item._id == vm.value.state
              );
              if (subDistrict) str += " - " + subDistrict.name;
            }
          }
        }
        vm.value.regionText = str;
      }
      return str;
    },
  },
  mounted() {
    if (!this.isLayout)  this.fetchRegions();
   
    if (!this.inputValue) this.inputValue = this._getDefaultValue();
  },
  watch: {
    "$i18n.locale"() {
      this.fetchRegions();
    },
    RegionText(val){
      this.$nextTick(()=>{
        this.validate()
      })
    }
  },
  methods: {
    resetValidation() {
      this.$refs.regionMenu.resetValidation();
    },
    validate(){
      if (this.isReadOnly) return true;
      const valid = this.$refs.regionMenu.validate()
      this.hasError = !valid
      return valid
    },
    async fetchRegions(){
        const vm = this
        //TODO: implement locale
        let locale = this.$i18n.locale
        if (this.$route.name === 'PublicForm' || 
            this.$route.name === 'PublicQuery' || 
            this.$route.name === 'PublicEdit'){
          await this.axios.get(this.$store.getters.appHost + '/public/getRegions/' + locale)
            .then(response => {
                this.regions = response.data.options
            })
            .catch(err => {
                console.log(err)
            })
        }else {
        const getParams = {
          urlCommand: "/widgets/getRegions/" + locale,
        };
        await vm.$store
          .dispatch("AUTH_GET", getParams)
          .then((response) => {
            this.regions = response.options;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    _getDefaultValue() {
      return { province: null, city: null, state: null, regionText: "" };
    },
  },
};
</script>

<style>
.message-line {
  min-height: 14px;
  color: lightskyblue;
}
.region-textfield input[readonly="readonly"] {
  /* background-color: yellow !important; */
  /* padding: 0px !important */
}
</style>
