<template>
    <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :isHidden="isHidden"
      :required="required"
      :fieldWidth="fieldWidth"
      :label="fieldName"
      class="mt-3 input-widget-color-picker"
      :tooltipText="description"
    >
        <color-picker-menu
            v-model="inputValue"
            @input="inputValue=$event"
        >
        </color-picker-menu>
    </input-widget-container>
</template>
<script>
import inputWidgetContainer from './comps/InputWidgetContainer'
import mixin from './mixin'
import formFieldMixin from '@/mixins/formFieldMixin'
import helperMixin from '@/mixins/helpersMixin'
import colorPickerMenu from './comps/ColorPickerMenu'
export default {
    name: 'inputWidgetColorPicker',
    mixins: [
        mixin,
        formFieldMixin,
        helperMixin
    ],
    components: {
        inputWidgetContainer,
        colorPickerMenu
    },
    methods:{
        _getDefaultValue(){
            return "#000000"
        }
    }
}
</script>

<style>
.widget-color-picker .v-input__control > .v-input__slot{
  min-height: 0px !important;
}
</style>