<template>
    <div>
      <div class="workflow-shortview-worksheet">
        <span v-if="ruleSize < 1">
          {{ $t('workflow.modules.branch.defaultRemark') }}
        </span>
        <div v-else>
          {{ $t('workflow.modules.branch.label.ruleset', { ruleSize }) }}
        </div>
      </div>
      <div v-if="rules && rules.length" class="workflow-setting-view-condition-info">
        <div v-for="(rule, row) in rules" :key="row">
          <ul v-for="(r, col) in rule" :key="col">
            <li v-if="r.content">
              <component
                :is="targetComponent(r.fieldType)"
                :setting="r"
              />
            </li>
            <!-- <div v-if="col >= 0 && col < rule.length - 1" class="workflow-setting-view-condition-gate">
              {{ $t('general.and') }}
            </div> -->
          </ul>
          <div v-if="row >= 0 && row < rules.length - 1" class="workflow-setting-view-condition-gate">
            {{ $t('general.or') }}
          </div>
        </div>
      </div>
      <PendingView
        v-if="ruleSize < 1"
        :node="node"
        :pendingLabel="$t('workflow.modules.branch.button.addConditionRoot')"
      />
    </div>
</template>

<script>
import { get, upperFirst } from 'lodash';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import PendingView from './../pending';
import TypeText from './type/text';
import TypeSelection from './type/selection';
import TypeRadio from './type/radio';
import TypeNumber from './type/number';
import TypeMembers from './type/members';
import TypeDepartments from './type/departments';
import TypeDate from './type/date';
import Mixin from './mixin';
/*
* Target Module Sub-Forms Copncrete View Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
  },
  components: {
    PendingView,
    TypeText,
    TypeSelection,
    TypeRadio,
    TypeNumber,
    TypeMembers,
    TypeDepartments,
    TypeDate,
  },
  methods: {
    targetComponent(type) {
      const target = WorkflowSettingUtils.getFilteringFieldTypeComponent(type);
      return `Type${upperFirst(target)}`;
    },
  },
  computed: {
    ruleSize() {
      return get(this.node, 'properties.rules', []).length;
    },
  }
};

</script>

<style scoped lang="scss">
  .workflow-setting-view-condition-info {
    font-size: 12px;
    padding: 6px 20px 0 20px;
    div.workflow-setting-view-condition-info-line {
      margin: 3px 0 0 0;
      color: #666;
    }
    .workflow-setting-view-condition-gate {
      width: 100%;
      text-align: center;
    }
  }
</style>
