import { render, staticRenderFns } from "./wrapper.vue?vue&type=template&id=524d7481&"
import script from "./wrapper.vue?vue&type=script&lang=js&"
export * from "./wrapper.vue?vue&type=script&lang=js&"
import style0 from "./wrapper.vue?vue&type=style&index=0&id=524d7481&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VLayout,VSelect,VSpacer})
