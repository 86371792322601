<template>
  <div>
    <div class="workflow-node-box workflow-node-box-preview">
      <div
        class="workflow-node-item"
      >
        <div class="workflow-node-item-name" :style="cardStyle()">
          <div class="workflow-node-item-avatar">
            <v-icon class="workflow-node-item-avatar-icon">
              {{ icon }}
            </v-icon>
          </div>
          <div class="workflow-node-item-nameText">
            <v-skeleton-loader
              v-bind="attrs"
              type="text"
            >
            </v-skeleton-loader>
          </div>
        </div>
        <div class="workflow-node-item-content">
           <v-skeleton-loader
              v-bind="attrs"
              type="card-heading"
            ></v-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    color: String,
    icon: String,
  },
  methods: {
    cardStyle() {
      return {
        'background-color': this.color,
      };
    },
  },
};

</script>

<style scoped>
  
</style>
