<template>
    <!--day-->
    <div v-if="timeView=='DAY'" class="d-flex flex-column secondary--text">
        <div style="height:25px" :style="slotProps.day.$W == 1?'border-left:1px solid #e9e9e9':''">
            <div 
                class="d-flex justify-center align-center"
                v-if="slotProps.day.$W == 4" 
                style="position: absolute; font-size:14px; top:2px;"
                :style="cellWidth<=20?'transform: translateX(-38px)':cellWidth<=30?'transform: translateX(-60px)':'transform: translateX(-50px)'"
            >
                <div v-if="cellWidth>20" class="pr-1">Week {{week}}</div>
                <div v-if="weekStart && weekEnd" class="float-right">{{weekStart.format("DD MMM")}} - {{weekEnd.format("DD MMM")}}</div>
            </div>
        </div>
        <div
            class="d-flex align-center justify-center" 
            style="height:25px; font-size:13px;border:1px solid #e9e9e9; border-right:0">
            {{slotProps.day.format("D")}}
        </div>
    </div>

    <!--week-->
    <div v-else-if="timeView=='WEEK'" class="d-flex flex-column secondary--text">
        <div  class="d-flex align-center justify-center"  style="height:25px; font-size:14px;" 
        :style="slotProps.day.format('D')==1?'border-left:1px solid #e9e9e9':''">
            <div
                v-if="slotProps.day.format('D')=='15'"
                class="pa-1 d-flex justify-center"
            >
                <span class="pr-1">{{slotProps.day.format("MMM")}}</span>
                <span class="">{{slotProps.day.format("YYYY")}}</span>
            </div>
        </div>
        <div class="d-flex align-center justify-center"  style="height:25px; font-size:13px; border-top:1px solid #e9e9e9; border-bottom:1px solid #e9e9e9" 
            :style="slotProps.day.$W == 1?'border-left:1px solid #e9e9e9':''">
            <div
                class="pa-1 d-flex justify-center"
                v-if="slotProps.day.$W == 4"
                style="position: absolute; top:25px"
            >   
               <div class="pr-1">Week {{week}}</div>
               <div v-if="weekStart && weekEnd" class="float-right">{{weekStart.format("DD")}}-{{weekEnd.format("DD")}}</div>
            </div>
        </div>
    </div>


    <!--month-->
    <div v-else-if="timeView=='MONTH'" class="d-flex flex-column secondary--text">
        <div  class="d-flex align-center justify-center"  style="height:25px; font-size:14px;" 
        :style="slotProps.day.format('D')==1&& ['01', '04', '07', '10'].includes(slotProps.day.format('MM'))?'border-left:1px solid #e9e9e9':''">
            <div
                v-if="slotProps.day.format('D')=='15'&& ['02', '05', '08', '11'].includes(slotProps.day.format('MM'))"
                class="pa-1 d-flex justify-center"
            >
                <span class="pr-1">{{slotProps.day.format("YYYY")}}</span>
                <span v-if="slotProps.day.format('MM')=='02'">Q1</span>
                <span v-else-if="slotProps.day.format('MM')=='05'">Q2</span>
                <span v-else-if="slotProps.day.format('MM')=='08'">Q3</span>
                <span v-else-if="slotProps.day.format('MM')=='11'">Q4</span>
            </div>
        </div>
        <div
            class="d-flex align-center justify-center" 
            style="height:25px; font-size:13px; border:1px solid #e9e9e9; border-left:0; border-right:0" 
            :style="slotProps.day.format('D')=='1'?'border-left:1px solid #e9e9e9':''">
            <div
                v-if="slotProps.day.format('D')==15" 
                class="pa-1"
                style="font-size:13px;"
            >
                {{slotProps.day.format("MMM")}}
            </div>
        </div>
    </div>

    <!--quarter-->
    <div v-else-if="timeView=='QUARTER'" class="d-flex flex-column secondary--text">
        <div style="height:25px" :style="slotProps.day.format('D')==1 && slotProps.day.format('M')==1?'border-left:1px solid #e9e9e9':''">
            <span
                v-if="slotProps.day.format('D')=='15' && slotProps.day.format('M')=='6'" 
                style="position: absolute; font-size:13px; top:2px"
            >
                {{slotProps.day.format("YYYY")}}
            </span>
        </div>
        <div
            class="d-flex align-center justify-center" 
            style="height:25px; font-size:13px; border-top:1px solid #e9e9e9; border-bottom:1px solid #e9e9e9" 
            :style="
                    (slotProps.day.format('MM')=='01' && slotProps.day.format('D')=='1'?
                    'border-left:1px solid #e9e9e9':'') + 
                    (['04', '07', '10'].includes(slotProps.day.format('MM')) && slotProps.day.format('D')=='1'? 
                    'border-left:1px solid #e9e9e9':'')
                ">
            <span
                v-if="slotProps.day.format('D')==1 && ['02', '05', '08', '11'].includes(slotProps.day.format('MM'))" 
                style="font-size:13px; transform: translate(16px,0px)"
            >
                <span v-if="slotProps.day.format('MM')=='02'">Q1</span>
                <span v-else-if="slotProps.day.format('MM')=='05'">Q2</span>
                <span v-else-if="slotProps.day.format('MM')=='08'">Q3</span>
                <span v-else-if="slotProps.day.format('MM')=='11'">Q4</span>
            </span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment' 
    export default {
        props:{
            slotProps: Object,
            timeView: String,
            cellWidth: Number
        },
        data(){
          return{
            week:'',
            weekStart:'',
            weekEnd: ''
          }  
        },
        mounted(){
            if(this.slotProps.day.$W == 4){
                this.week = moment(this.slotProps.day.format("MM-DD-YYYY"), "MM-DD-YYYY").isoWeek()
                // this.weekStart = moment(this.slotProps.day.format("MM-DD-YYYY"), "MM-DD-YYYY").add(-3, 'day')
                this.weekStart = this.slotProps.day.add(-3, 'day')
                this.weekEnd = this.slotProps.day.add(3, 'day')
                // console.log("weekStart", this.weekStart.format('mmm'))
                // console.log("weekEnd", this.weekEnd.format(""))
            }
        }
    }
</script>

<style>
    .gantt-timeline{
        margin-left: 0 !important
    }
</style>