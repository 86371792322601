<template>
    <div class="">
        <v-overlay
            absolute
            :value="overlayChart==chart._id"
        >
            <v-btn class="error" @click="$emit('handleDeleteChart',chart)">
                {{$t('buttons.confirm')}}
            </v-btn>
            <v-btn class="ml-2" outlined @click="overlayChart=null">
                {{$t('buttons.cancel')}}
            </v-btn>
        </v-overlay>
        <div class="">
            <div class="d-inline pl-3" style="font-size: 15px">{{chart.chartName}}</div> 
            <div class="chartActions" style="position: relative; float: right; z-index: 99; height:0px">
                <v-btn v-if="hover && (group=='private')" small icon :ripple="false" plain><v-icon size="18" @click="$emit('editChart',chart)">mdi-cog</v-icon></v-btn>
                <v-btn v-if="hover" small icon :ripple="false" plain><v-icon size="19" @click="$emit('refreshChart', chart)">mdi-refresh</v-icon></v-btn>
                <v-btn v-if="hover && (group=='private')" v-on="on" small icon :ripple="false" plain><v-icon size="17" @click="overlayChart = chart._id">mdi-delete</v-icon></v-btn>
                <!-- <v-btn v-show="hover" small icon :ripple="false" plain><v-icon size="16" @click="bl_chartExpand = true">mdi-arrow-expand</v-icon></v-btn> -->
                <v-btn v-if="hover && (group=='private'||isAdmin) && chart.selectedChart=='TABLE'" small icon :ripple="false" plain @click="$emit('exportChart')">
                    <v-icon size="18">mdi-export-variant</v-icon>
                </v-btn>
                <v-btn v-if="hover && (group=='private'||isAdmin)" small icon :ripple="false" plain><v-icon size="22" class="handle">mdi-drag</v-icon></v-btn>
            </div>
        </div>
        <v-menu offset-x bottom>
            <template v-slot:activator="{on}">
                <div v-on="on"
                    v-show="hover"
                    style="writing-mode: vertical-rl; font-size:12px; position: absolute; margin-top:165px; relative: left; z-index: 1; cursor: pointer" 
                    class="warning white--text py-2 px-0">
                    {{group=='private'? $t('chart.share'):$t('chart.unShare')}}
                </div>
            </template>
            <v-card tile flat class="pa-2">
                <div style="font-size:12px">{{group=='private'?  $t('chart.confirmShare'):$t('chart.confirmUnshare')}}</div>
                <div class="d-flex mt-2">
                    <v-btn v-if="group=='public'" @click="$emit('modifyGroup', {group:'private', chart: chart})" style="height:25px" small elevation="0">{{$t('buttons.confirm')}}</v-btn>
                    <v-btn v-if="group=='private'" @click="$emit('modifyGroup', {group:'public', chart: chart})" style="height:25px" small elevation="0">{{$t('buttons.confirm')}}</v-btn>
                    <v-btn style="height:25px" small elevation="0" class="ml-2">{{$t('buttons.cancel')}}</v-btn>
                </div>
            </v-card>
        </v-menu>  
    </div>
</template>

<script>
    export default {
        props:{ 
            chart: Object,
            hover: Boolean,
            group: String
        },
        data(){
            return{
                overlayChart: null
            }   
        },
        computed:{
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
        }
    }
</script>
