<template>
  <FormElement
    :label="$t('workflow.modules.form.label.triggerMethod')"
    name='hookEvent'
  >
    <v-radio-group v-model="setting.hookEvent" @change="onChangeHookEvent">
      <v-radio
        v-for="item in selections"
        :key="item.key"
        :value="item.key"
        class="w-f-radio-field"
      >
        <template v-slot:label>
          <component
            :is="item.component"
            :list="list"
            :value="hookFields"
            :label="item.label"
            :selected="isSelected(item)"
            :hints="isSelected(item) ? item.hints : null"
            @changed="changed"
          />
        </template>
      </v-radio>
    </v-radio-group>
  </FormElement>
</template>

<script>
import ComboBox from './../../../../form/comboBox/';
import OnCreateOrUpdate from './onCreateOrUpdate';
import FormElement from './../formElement';
import OnUpdate from './onUpdate';
import OnCreate from './onCreate';
import OnRemove from './onRemove';
import Mixin from './../../mixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
// import {
//   map,
//   omit,
// } from 'lodash';
export default {
  mixins: [Mixin],
  props: {
    // hookEvent: String,
    // hookFields: Array,
  },
  computed: {
    hookEvent() {
      return this.setting.hookEvent;
    },
    hookFields() {
      return this.setting.hookFields;
    },
    list() {
      return this.$store.getters.getFormSelectionsFields.children;
    },
  },
  data() {
    return {
      selections: [
        {
          key: 'onCreateOrUpdate',
          label: this.$t('workflow.modules.form.method.onCreateOrUpdate'),
          hints: this.$t('workflow.modules.form.hints.onCreateOrUpdate'),
          placeholder: this.$t('workflow.modules.form.placeholder.fieldTrigger'),
          list: this.list,
          component: 'OnCreateOrUpdate',
        },
        {
          key: 'onCreate',
          label: this.$t('workflow.modules.form.method.onCreate'),
          hints: this.$t('workflow.modules.form.hints.onCreate'),
          component: 'OnCreate',
        },
        {
          key: 'onUpdate',
          label: this.$t('workflow.modules.form.method.onUpdate'),
          hints: this.$t('workflow.modules.form.hints.onUpdate'),
          placeholder: this.$t('workflow.modules.form.placeholder.fieldTrigger'),
          list: this.list,
          component: 'OnUpdate',
        },
        {
          key: 'onRemove',
          label: this.$t('workflow.modules.form.method.onRemove'),
          hints: this.$t('workflow.modules.form.hints.onRemove'),
          component: 'OnRemove',
        }
      ],
    };
  },
  methods: {
    isSelected({ key }) {
      return this.hookEvent === key;
    },
    onChangeHookEvent() {
      this.$store.dispatch(SET_SETTINGS, { hookFields: [] });
    },
    changed(v) {
      this.$store.dispatch(SET_SETTINGS, { hookFields: v });
    }
  },
  components: {
    ComboBox,
    OnCreate,
    OnCreateOrUpdate,
    OnUpdate,
    FormElement,
    OnRemove,
  },
};

</script>

<style scoped>
  .w-f-radio-field {
    margin-bottom: 0 !important;
    align-items: flex-start;
  }
</style>
