<template>
  <drop-list
    :items="items"
    class="row pa-0 ma-0 fieldRow"
    @insert="onInsertToRow"
    @reorder="$event.apply(items)"
    :accepts-data="(d) => acceptData(d)"
    mode="cut"
    :row="true"
  >
    <template v-slot:item="{ item }">
      <drag

        :key="item._id"
        :data="item"
        @cut="removeItem(item)"
        :class="`field col col-${item.width}`"
      >
        <field

          :fieldInfo="getFieldInfo(item._id)"
          :item="item"
          :value="record[item._id]"
          :childTableData="childTableData"
          :colIndex="getIndex(item._id)"
          :printColIndex ="getFilteredColIndex(item._id)"
          :remainingWidth="remainingWidth"

          :childTableFields="childTableFields"
          :relatedRecords="relatedRecords"
          :childRelatedRecords="childRelatedRecords"
          :relatedTableInfos="relatedTableInfos"
          :fontSize="fontSize"
          @commandHandler="onCommandHandler"
        />
      </drag>
    </template>
    <template v-slot:feedback>
      <div class="feedback" key="feedback"></div>
    </template>
  </drop-list>
</template>
<script>
import { Drag, Drop, DropList } from "vue-easy-dnd";
import Field from "./Field";
import ValidationsHelper from "@/helpers/ValidationsHelper"


export default {
  components: {
    Drag,
    Drop,
    DropList,
    Field,
  },
  props: {
    rowIndex: Number,
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    record: Object,
    childTableData:Array,
    fieldInfos: {
      type: Array,
    },
    disabled:Boolean,

    childTableFields:Object,
    fontSize:Number,
    relatedRecords: Object,
    childRelatedRecords:Object,
    relatedTableInfos:Object,
  },
  computed: {
    totalOccupiedWidth() {
      return this.items.reduce((total, item) => {
        return total + item.width;
      }, 0);
    },
    remainingWidth() {
      return 12 - this.totalOccupiedWidth;
    },
  },
  methods: {
    getFieldInfo(fieldId) {
      return this.fieldInfos.find((info) => info.fieldId === fieldId);
    },
    onCommandHandler(payload) {
      switch (payload.command) {
          default:
            this.$emit("commandHandler", {
              ...payload,
              rowIndex: this.rowIndex,
            });            
            break;
      }
    },

    getIndex(fieldId) {
      return this.items.findIndex((item) => item._id === fieldId);
    },
    onInsertToRow(event) {
      let dataWidth = event.data.width;
      if (dataWidth > this.remainingWidth) dataWidth = this.remainingWidth;

      this.items.splice(event.index, 0, { ...event.data, width: dataWidth });
    },
    acceptData(data) {
      if (this.remainingWidth === 0) return false;
      else {
        const fieldInfo = this.fieldInfos.find(
          (info) => info.fieldId === data._id
        );
        return fieldInfo ? fieldInfo.properties.hasOwnProperty("width") : false;
      }
    },
    removeItem(item) {
      this.$emit("commandHandler", {
        command: "removeItem",
        item,
        rowIndex: this.rowIndex,
      });
    },
    getFilteredColIndex(fieldId) {
      const row = this.items.filter(item => item.controlType === 'static' || 
      !ValidationsHelper.isEmpty(this.record[item._id]))

      const index = row.findIndex(item => item._id === fieldId)
      return index
    }
  },
};
</script>
<style scoped>
.col.col-1,
.col.col-1,
.col.col-2,
.col.col-3,
.col.col-4,
.col.col-5,
.col.col-6,
.col.col-7,
.col.col-8,
.col.col-9,
.col.col-10,
.col.col-11,
.col.col-12 {
  padding: 0px !important;
}

.field {
  border-left: 1px solid #ddd
}

.field:first-child {
  border-left:none
}

</style>
