<template>
    <div style="border-left: 1px solid #eeee; border-right: 1px solid #eeee;" class="px-3 d-flex flex-column">
        <div style="font-size: 16px" class="font-weight-bold pa-2">{{$t('chart.dataSource')}}</div>
        <div class="mx-2 mt-2">
            <div>{{$t('chart.filterByPeriod')}}</div>
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{on, value}">
                    <div v-on="on">
                        <v-hover v-slot="{ hover }">
                            <v-input
                                class="rounded d-flex dateSelectionInput" 
                                :style="value? 'border: 2px solid #39aba7':hover?'border-color:black':''"
                                style="height:100%; padding: 0 6px; border: 1px solid #0000006b; height: 40px;">
                                <div class="flex-grow-1">
                                    <div style="padding: 10px 0;">{{getDateOptString()}}</div>
                                </div>
                                <div :class="value?'':'v-select--is-menu-active'" class="v-select">
                                    <div class="v-input__icon--append">
                                        <span class="v-icon theme--light">
                                            <font-awesome-icon
                                                :class="value? 'primary--text':''"
                                                icon="caret-up" 
                                                size="sm" 
                                                transform="grow-2"
                                            />
                                            </font-awesome-icon>
                                        </span>
                                    </div>
                                </div>
                            </v-input>
                        </v-hover>
                    </div>
                    <!-- <v-text-field :value="getDateOptString()" readonly v-on="on" outlined height="30" dense hide-details></v-text-field> -->
                </template>
                <v-card class="d-flex flex-column" style="overflow: hidden;">
                    <div class="px-3 pt-2 pb-3 d-flex flex-column">
                        <div style="font-size: 13px; color: grey;" class="pb-1">{{$t('chart.time')}}</div>
                        <div>
                            <v-select
                                class="dateRangeSelection"
                                style="width:100%"
                                v-model="sourceSetups.selectedDateField"
                                @change="$emit('onCommandHandler', {
                                    command: 'update_selectedDateField',
                                    value: $event
                                })"
                                item-value="fieldId" 
                                :items="dateWidgets"
                                outlined dense hide-details height="30"
                                :menu-props="{offsetY: true}"
                            >
                                <template v-slot:item="{item}">
                                    <div>
                                        {{ ["updatedAt", "createdAt"].includes(item.fieldId)?$t(`widgets.${item.fieldId}`):item.label }}
                                    </div>
                                </template>
                                <template v-slot:selection="{item}">
                                    <div>
                                        {{ ["updatedAt", "createdAt"].includes(item.fieldId)?$t(`widgets.${item.fieldId}`):item.label }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div style="max-height:300px; overflow: auto">
                        <div style="font-size: 13px; color: grey;" class=" ml-2 pt-2 pb-1">{{$t('chart.selectRange')}}</div>
                        <v-list dense class="pt-0">
                            <v-list-item-group 
                                mandatory 
                                v-model="sourceSetups.dateOpt" 
                                color="primary" 
                                @change="$emit('onCommandHandler', {
                                    command: 'update_dateOpt',
                                    value: $event
                                })"
                                
                            >
                                <div :value="opt.id" v-for="opt in dateOpts" :key="opt.value">
                                    <v-list-item :value="opt.id" class="d-flex align-center" style="min-height:25px;">
                                        <div style="font-size:13px">{{opt.name}}</div>
                                    </v-list-item>
                                    <div v-if="opt.value=='PAST_' && sourceSetups.dateOpt==16" class="black--text px-4 my-2">
                                        <v-text-field v-model="b4_afterDate" outlined dense height="30" hide-details>
                                            <template v-slot:append>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <div v-if="opt.value=='NEXT_' && sourceSetups.dateOpt==17" class="black--text px-4 my-2">
                                        <v-text-field v-model="b4_afterDate" outlined dense height="30" hide-details/>
                                    </div>
                                    <div v-if="opt.value=='DATE_' && sourceSetups.dateOpt==18" class="black--text px-4 my-2">
                                        <v-menu top offset-x nudge-right="16" :close-on-content-click="false">
                                            <template v-slot:activator="{on}">
                                                <v-text-field readonly v-model="dateRangeText" outlined dense height="30" hide-details v-on="on"/>
                                            </template>
                                            <v-date-picker v-model="specifiedDates" range tile elevation="0"/>
                                        </v-menu>
                                    </div>
                                </div>
                            </v-list-item-group>
                        </v-list>
                    </div>
                </v-card>
            </v-menu>
        </div>
        <div class="mx-2">
            <div>{{$t('chart.selectFieldFrom')}}</div>
            <v-select 
                v-model="sourceSetups.selectedView" 
                @change="$emit('onCommandHandler',{
                    command: 'update_selectedView',
                    value: $event
                })" 
                :item-text="item=> item.title|| $t('chart.all')" 
                item-value="_id" :items="views2" 
                outlined 
                height="30" 
                dense 
                hide-details 
                :menu-props="{offsetY: true}">
            </v-select>
        </div>
        <div class="mx-2 mt-4 flex-grow-0">
            {{$t('chart.field')}}
        </div> 
        <div class="flex-grow-1 mb-2" style="overflow: auto">
            <div
                class="draggablee d-flex dragdemo align-center"
                v-for="(item, i) in fields"
                :key="i"
                @dragstart="startDragging(item, $event)"
                @dragend="endDragging(item, $event)"
                draggable="true"
                :class="selected_id.includes(item._id)? 'info--text':''"
            >
                <font-awesome-icon :icon="getWidgetIcon(item.widgetId)" class="fa-fw mr-2"></font-awesome-icon>    
                <div v-if="!localeTypes.includes(item.type)" style="font-size:14px">{{item.label}}</div>
                <div v-else style="font-size:14px">{{$t('chart.'+item.type)}}</div>
            </div>
        </div>
        <v-snackbar
            class=""
            v-model="showAlert"
            :timeout="3000"
            color="error"
            right
            min-width="200"
            top
        >
            <v-icon>mdi-alert-circle-outline</v-icon>
            <span class="ml-2">{{$t('chart.unSupportedType')}}</span>
        </v-snackbar>
        <select-field-dialog 
            v-model="showSelectFieldDialog"
            ref="selectFieldDialog"
        >
        </select-field-dialog>
    </div>
</template>

<script>
    import mixin from './mixin'
    import SelectFieldDialog from './dialog/SelectFieldDialog'
    export default {
        mixins: [mixin],
        components:{
            SelectFieldDialog 
        },
        props:{
            views: Array,
            // dateOpt: Number,
            // dateOptVal: String,
            // selectedDateField: String,
            // selectedView: String,
            sourceSetups: Object,
            // dateOptVal2: String,
            selectedChart: String
        },
        watch:{
            dateOptVal2(val){
                this.sourceSetups.dateOptVal = val
                this.$emit('onCommandHandler',{
                    command: 'update_dateOptVal',
                    value: val
                })
            },
            selectedView(val){
                if(val!="all"){
                    var view = this.views.find(item=>item._id == val)
                    if(view){
                        var fixedIds = view.fixedFieldIds
                        var nonFixedFieldIds = view.nonFixedFieldIds

                        if(fixedIds.length || nonFixedFieldIds.length){
                            this.arrX.forEach((item,index,array)=>{
                                if(!(fixedIds.includes(item._id)||nonFixedFieldIds.includes(item._id))){
                                    array.splice(index,1)
                                }
                            })
                            this.arrY.forEach((item,index,array)=>{
                                if(!(fixedIds.includes(item._id)||nonFixedFieldIds.includes(item._id))){
                                    array.splice(index,1)
                                }
                            })
                            this.arrG.forEach((item,index,array)=>{
                                if(!(fixedIds.includes(item._id)||nonFixedFieldIds.includes(item._id))){
                                    array.splice(index,1)
                                }
                            })
                        }
                        // return this.$store.getters.currentForm.fieldInfos.filter(item=>{
                        //         return fixedIds.includes(item.fieldId)||nonFixedFieldIds.includes(item.fieldId)
                        //     })
                    }
                }
            }
        },
        data(){
            return {
                numericFields: ['number', 'amount', 'recordCount', 'expression', 'summaryField'],
                titleFields: ['text','members','singleSelection','multipleSelection', 'departments', 'date', 'createdBy', 'owner', 'createdAt', 'updatedAt', 'autoNumbering'],
                localeTypes: ['recordCount', 'owner', 'createdBy', 'createdAt', 'updatedAt'],
                dragged: [],
                mousex: [],
                mousey: [],
                coordinates: [],
                views2: [],
                showAlert: false,
                showSelectFieldDialog: false,
                dateOpts: [{
                    id: 99,
                    name: this.$t('chart.all'),
                    value: 'all'
                },
                {
                    id: 1,
                    name: this.$t('calendar.TODAY'),
                    value: "TODAY"
                },{
                    id: 2,
                    name: this.$t('calendar.YESTERDAY'),
                    value: "YESTERDAY"               
                },{
                    id: 3,
                    name: this.$t('calendar.TOMORROW'),
                    value: "TOMORROW"       
                },{
                    id: 4,
                    name: this.$t('calendar.THISWEEK'),
                    value: "THISWEEK"
                },{
                    id: 5,
                    name: this.$t('calendar.LASTWEEK'),
                    value: "LASTWEEK"               
                },{
                    id: 6,
                    name: this.$t('calendar.NEXTWEEK'),
                    value: "NEXTWEEK"            
                },{
                    id: 7,
                    name: this.$t('calendar.THISMONTH'),
                    value: "THISMONTH"            
                },{
                    id: 8,
                    name: this.$t('calendar.LASTMONTH'),
                    value: "LASTMONTH"             
                },{
                    id: 9,
                    name: this.$t('calendar.NEXTMONTH'),
                    value: "NEXTMONTH"            
                },{
                    id: 10,
                    name: this.$t('calendar.THISQUARTER'),
                    value: "THISQUARTER"                
                },{
                    id: 11,
                    name: this.$t('calendar.LASTQUARTER'),
                    value: "LASTQUARTER"         
                },{
                    id: 12,
                    name: this.$t('calendar.NEXTQUARTER'),
                    value: "NEXTQUARTER"          
                },{
                    id: 13,
                    name: this.$t('calendar.THISYEAR'),
                    value: "THISYEAR"             
                },{
                    id: 14,
                    name: this.$t('calendar.LASTYEAR'),
                    value: "LASTYEAR"
                },{
                    id: 15,
                    name: this.$t('calendar.NEXTYEAR'),
                    value: "NEXTYEAR"
                },{
                    id: 16,
                    name: this.$t('calendar.PAST_'),
                    value: "PAST_"                
                },{
                    id: 17,
                    name: this.$t('calendar.NEXT_'),
                    value: "NEXT_"              
                },{
                    id: 18,
                    name: this.$t('calendar.DATE_'),
                    value: "DATE_"            
                }],
                specifiedDates: [],
                b4_afterDate:  0,
                recordCountField: {
                    _id: 'recordCount', 
                    label: 'recordCount', 
                    type: 'recordCount', 
                    fieldId: 'recordCount',
                    widgetId: 'recordCount'
                }
            }
        },
        mounted(){
            this.views2 = JSON.parse(JSON.stringify(this.views))
            this.views2.unshift({
                _id: 'all',
                title: this.$t('chart.allRecords')
            })
            // this.selectedDateField = this.fields.find(item=>item.type=='createdAt')._id
            // this.$emit('update_selectedDateField', this.selectedDateField)
        },
        computed:{
            arrX(){
                return this.dataSetups.arrX
            },
            arrY(){
                return this.dataSetups.arrY
            },
            arr2Y(){
                return this.dataSetups.arr2Y
            },
            arrG(){
                return this.dataSetups.arrG
            },
            filters(){
                return this.dataSetups.filters
            },
            relatedTableInfos () {
                return this.$store.getters.relatedTableInfos
            },
            fields(){
                if(!this.$store.getters.currentForm?.fieldInfos) return []
                let fields = [...this.$store.getters.currentForm.fieldInfos.filter(field=>field.canView), {...this.recordCountField}]
                fields = fields.sort((a,b) =>{
                    const aTitle = this.localeTypes.includes(a.type)? this.$t(`chart.${a.type}`):a.label
                    const bTitle = this.localeTypes.includes(b.type)? this.$t(`chart.${b.type}`):b.label
                    if (aTitle < bTitle) {
                        return -1;
                    }
                    if (aTitle > bTitle) {
                        return 1;
                    }
                    return 0;
                })
                
                if(this.sourceSetups.selectedView !== "all"){
                    var view = this.views.find(item=>item._id == this.sourceSetups.selectedView)
                    if(view){
                        var fixedIds = view.fixedFieldIds
                        var nonFixedFieldIds = view.nonFixedFieldIds
                        if(fixedIds.length || nonFixedFieldIds.length){
                            return fields.filter(item=>{
                                return fixedIds.includes(item._id)||nonFixedFieldIds.includes(item._id)
                            })
                        }
                    }
                }
                return fields
            },
            allWidgets () {
                return this.$store.getters.allWidgets
            },
            dateWidgets(){
                return this.$store.getters.currentForm.fieldInfos.filter(item=> item.type=='date'|| item.type=='createdAt' || item.type=='updatedAt')
            },
            selected_id(){
                var arr = this.arrX.concat(this.arrY).concat(this.arrG)
                return arr.map(item=>item._id)         
            },
            dateRangeText () {
                if(this.specifiedDates.length == 2)
                    if(this.specifiedDates[0]>this.specifiedDates[1]){
                        let temp = this.specifiedDates[0]
                        this.specifiedDates[0] = this.specifiedDates[1]
                        this.specifiedDates[1] = temp
                    }
                return this.specifiedDates.join(' ~ ')
            },
            dateOptVal2(){
                let vm = this  
                var OptVal =  vm.dateOpts.find(item=>item.id == vm.sourceSetups.dateOpt).value
                switch(OptVal){
                    case "NEXT_": 
                    case "PAST_":
                        OptVal = OptVal + this.b4_afterDate
                        break
                    case "DATE_":
                        OptVal = OptVal + this.dateRangeText.replace(' ~ ', '_')
                        break  
                }
                return OptVal
            }
        },
        methods:{
            checkType(i, type, disableAlert=false){
                if(type=='relatedRecord' || type=='childTable') return false
                var typeValid  = false
                var validArr = []
                if(i=='3'){
                    if(['text',  'number', 'singleSelection', 'multipleSelection', 'members'].includes(type))
                        typeValid = true
                } 
                switch(this.selectedChart){
                    case 'BAR':
                    case 'LINE':
                    case 'RADAR':
                    case 'PIE':
                    case 'FUNNEL':
                        if(i === '0' || i === '2'){
                            if(this.titleFields.includes(type))
                                typeValid=true
                        }else{
                            if(this.numericFields.includes(type))
                                typeValid=true
                        }
                        break
                    case 'TABLE':
                        if(i === '0' || i === '1'){
                            if(this.titleFields.includes(type))
                                    typeValid=true
                        }else{
                            if(this.numericFields.includes(type))
                                typeValid=true
                        }
                        break
                }
                if(!typeValid && !disableAlert)
                    this.showAlert = true
                return typeValid
            },
            getDateOptString(){
                let vm = this  
                return vm.dateOpts.find(item=>item.id == vm.sourceSetups.dateOpt).name
            },
            getDateOptValue(){
                let vm = this  
                return vm.dateOpts.find(item=>item.id == vm.sourceSetups.dateOpt).value
            },
            getWidgetIcon (widgetId) {
                if(widgetId=='recordCount') return 'registered'
                var result = this.allWidgets.find(widget=> widget._id===widgetId)
                return result?.icon??'square'
            },
            continueDragging(e) {
                // Change the location of the draggable object
                let vm = this
                // console.log("move",e)
                vm.dragged.style.left = e.pageX - (vm.dragged.offsetWidth / 2),
                vm.dragged.style.top = e.pageY - (vm.dragged.offsetHeight / 2)
                for (var i in vm.coordinates) {
                    vm.coordinates[i].dom.removeClass("somethingover")
                    if (vm.mousex >= vm.coordinates[i].left && vm.mousex <= vm.coordinates[i].right) {
                        if (vm.mousey >= vm.coordinates[i].top && vm.mousey <= vm.coordinates[i].bottom) {
                            vm.coordinates[i].dom.addClass("somethingover");
                        }
                    } else {
                        // vm.coordinates[i].dom.removeClass("somethingover");
                    }
                }
                vm.mousex = e.pageX;
                vm.mousey = e.pageY;
                e.preventDefault()
            },

            endDragging(item, e) {
                var type = item.type
                this.drag = false
                let vm =this
                var slot = 'x'
                $(document).unbind("dragover", vm.continueDragging);
                $(document).unbind("dragend", vm.endDragging);
                var targetSlotIndex
                for (var i in vm.coordinates) {
                    if (vm.mousex >= vm.coordinates[i].left && vm.mousex <= vm.coordinates[i].right) {
                        if (vm.mousey >= vm.coordinates[i].top && vm.mousey <= vm.coordinates[i].bottom) {
                            targetSlotIndex = i
                            switch(vm.coordinates[i].id){
                                case 'dataSourceTableX':
                                    slot = 'x'
                                    if(this.checkType(i,item.type)){
                                        vm.arrX.push({
                                            _id: item.fieldId,
                                            type: item.type,
                                            label: item.label
                                        })
                                        this.$emit('onCommandHandler', {
                                            command: 'FetchData',
                                        })
                                    }
                                break
                                case 'dataSourceTableY':
                                    slot = 'y'
                                    if(this.checkType(i,item.type)){
                                        vm.arrY.push({
                                            _id: item.fieldId,
                                            type: item.type,
                                            label: item.label
                                        })
                                        this.$emit('onCommandHandler', {
                                            command: 'FetchData',
                                        })
                                    }
                                    break
                                break
                                case 'dataSourceTableData':
                                    slot = 'g'
                                    if(this.checkType(i,item.type)){
                                        if(this.selectedChart!='COMBO')
                                            vm.arrG.push({
                                                _id: item.fieldId,
                                                type: item.type,
                                                label: item.label
                                            })
                                        else
                                            vm.arr2Y.push({
                                                _id: item.fieldId,
                                                type: item.type,
                                                label: item.label
                                            })
                                            this.$emit('onCommandHandler', {
                                                command: 'FetchData',
                                            })
                                    }
                                break
                                case 'dataSourceX':
                                    slot = 'x'
                                    if(this.checkType(i,item.type)){
                                        vm.arrX.push({
                                            _id: item.fieldId,
                                            type: item.type,
                                            label: item.label
                                        })
                                        this.$emit('onCommandHandler', {
                                            command: 'FetchData',
                                        })
                                    }
                                    break
                                case 'dataSourceY':
                                    slot = 'y'
                                    if(this.checkType(i,item.type)){
                                        vm.arrY.push({
                                            _id: item.fieldId,
                                            type: item.type,
                                            label: item.label
                                        })
                                        this.$emit('onCommandHandler', {
                                            command: 'FetchData',
                                        })
                                    }
                                    break
                                case 'dataSourceG':
                                    slot = 'g'
                                    if(this.checkType(i,item.type)){
                                        if(this.selectedChart!='COMBO')
                                            vm.arrG.push({
                                                _id: item.fieldId,
                                                type: item.type,
                                                label: item.label
                                            })
                                        else
                                            vm.arr2Y.push({
                                                _id: item.fieldId,
                                                type: item.type,
                                                label: item.label
                                            })
                                            this.$emit('onCommandHandler', {
                                                command: 'FetchData',
                                            })
                                    }
                                    break
                                case 'dataFilter':
                                    slot = "filter"
                                    if(this.checkType(i,item.type)){
                                        [item.operator, item.filterVal] = this.getDefaultFilterSetting(item.type)
                                        if(this.filters.length==0)
                                            this.filters[0] = []
                                            this.filters[this.filters.length-1].push({
                                                fieldId: item.fieldId,
                                                filterVal: item.filterVal,
                                                label: item.label,
                                                operator: item.operator,
                                                type: item.type,
                                                properties: item.properties
                                            })
                                        this.$emit('onCommandHandler', {
                                            command: 'update_filters',
                                            value: this.filters
                                        })
                                    }
                                    break
                            }
                            var droptarget = vm.coordinates[i].dom
                            console.log('droptarget', droptarget)
                            droptarget.removeClass("somethingover")
                        }
                    }
                }
                vm.mousex = 0;
                vm.mousey = 0;
                vm.dragged = null;
                vm.coordinates = [];
                if(type == 'relatedRecord'){
                    var relatedTableId = item.properties.dataSource
                    var label = this.relatedTableInfos[relatedTableId].label
                    var relatedTableFields = this.relatedTableInfos[relatedTableId].fieldInfos
                    this.showSelectFieldDialog = true
                    this.$refs.selectFieldDialog.fields = relatedTableFields.filter(field=>vm.checkType(targetSlotIndex, field.type, true))
                    this.$refs.selectFieldDialog.field = item
                    this.$refs.selectFieldDialog.label = label
                    this.$refs.selectFieldDialog.slot = slot
                    this.$refs.selectFieldDialog.type = type
                    this.$refs.selectFieldDialog.selected = []
                    this.$refs.selectFieldDialog.callback = this.onCommandHandler
                }
                if(type == 'childTable'){
                    this.showSelectFieldDialog = true
                    this.$refs.selectFieldDialog.fields = item.properties.fieldsEditor.fieldInfos.filter(field=>vm.checkType(targetSlotIndex, field.type, true))
                    this.$refs.selectFieldDialog.field = item
                    this.$refs.selectFieldDialog.label = item.label
                    this.$refs.selectFieldDialog.slot = slot
                    this.$refs.selectFieldDialog.type = type
                    this.$refs.selectFieldDialog.selected = []
                    this.$refs.selectFieldDialog.callback = this.onCommandHandler
                }
            },

            startDragging(item, e) {
                this.drag = true
                console.log('dragstart', e)
                
                let vm =this
                vm.coordinates = []
                $(".droppable").each(function() {
                    var lefttop = $(this).offset();
                    vm.coordinates.push({
                        id: $(this).attr('id'),
                        dom: $(this),
                        left: lefttop.left,
                        top: lefttop.top,
                        right: lefttop.left + $(this).width(),
                        bottom: lefttop.top + $(this).height()
                    });
                });
                
                console.log(vm.coordinates)
                if (e.type == "dragstart") {
                    vm.dragged = e.target
                    vm.dragged.style.left = e.pageX - (vm.dragged.offsetWidth / 2)
                    vm.dragged.style.top = e.pageY - (vm.dragged.offsetHeight / 2)
                    $(document).bind("dragover", this.continueDragging);
                    $(document).bind("dragend", this.endDragging);
                }
            },
            onCommandHandler(payload){
                var arrMap = {
                    'x': this.arrX,
                    'y': this.arrY,
                    'g': this.arrG,
                    'filter': this.filters
                }
                switch(payload.command){
                    case 'x':
                    case 'y':
                    case 'g':
                    case 'filter':
                        payload.items.forEach(item=>{
                            var relatedTableId
                            if(payload.field.type=='relatedRecord')
                                relatedTableId = payload.field.properties.dataSource
                            else if(payload.field.type=='childTable')
                                relatedTableId = payload.field.properties.fieldsEditor.formId
                            
                            if(payload.command==='filter'){
                                [item.operator, item.filterVal] = this.getDefaultFilterSetting(item.type)
                                if(this.filters.length==0)
                                    this.filters[0] = []
                                    this.filters[this.filters.length-1].push({
                                        fieldId: payload.field.fieldId,
                                        filterVal: item.filterVal,
                                        label: item.label,
                                        operator: item.operator,
                                        type: payload.field.type,
                                        relatedId: item.fieldId,
                                        relatedType: item.type,
                                        relatedTableId,
                                        properties: item.properties
                                    })
                                    this.$emit('onCommandHandler', {
                                        command: 'update_filters',
                                        value: this.filters
                                    })
                            }else{
                                arrMap[payload.command].push({
                                    _id: payload.field._id,
                                    relatedId: item._id,
                                    type: payload.field.type,
                                    label: payload.field.label + ' - ' + item.label,
                                    relatedType: item.type,
                                    relatedTableId
                                })
                            }
                        })
                    break
                }
                this.$emit('onCommandHandler', {
                    command: 'FetchData',
                })
            },
            getDefaultFilterSetting(type){
                let operator, filterVal
                switch (type){
                    case 'text':
                    case 'email':
                    case 'phone':
                    case 'idCard':
                    case 'autoNumbering':
                    case 'textCombination':
                        operator = 'equal'
                        filterVal = ''
                        break
                    case 'number':
                    case 'amount':
                    case 'expression':
                    case 'capitalizedAmount':
                    case 'rating':
                        operator = 'equal'
                        filterVal = {
                            minVal: null,
                            maxVal: null,
                        }
                        break
                    case 'date':
                    case 'createdAt':
                    case 'updatedAt':
                        operator = 'is'
                        filterVal = ''
                        break
                    case 'singleSelection':
                    case 'multipleSelection':
                        operator = 'is'
                        filterVal = []
                        break
                    case 'members':
                    case 'createdBy':
                        operator = 'is'
                        filterVal = []
                        break
                    case 'departments':
                        operator = 'is'
                        filterVal = []
                        break
                    case 'attachments':
                    case 'signature':
                    case 'yesNo':
                        operator = 'is'
                        filterVal = ''
                        break
                }
                return [operator, filterVal]
            }
        }
    }
</script>

<style>
    .dateRangeSelection .v-select__selections input {
        display: none;
    }
</style>