<template>
  <div class="pa-0 text-no-wrap overflow-hidden d-flex flex-row align-center">
    <div v-if="textOnly">
      <label v-if="useLabel" class="grey--text text--lighten-1 mr-2">{{ label }}</label>
      {{getDisplayText (dataValue) }}
    </div>
    <div v-else>
    <v-chip v-for="member in dataValue" :key="member._id"
            x-small color="primary" class="pa-1">
      <v-avatar left class="mr-0" style="margin-left:-8px;">
        <v-icon small>mdi-account-circle</v-icon>
      </v-avatar>
      {{ getDisplayValue(member) }}
    </v-chip>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsMembers',
  mixins: [mixin],
  methods: {
    getDisplayValue(dataValue){
      return dataValue ? dataValue.employeeName : ""
    },
    getDisplayText(dataValues) {
      return (dataValues || []).map(value=> value.employeeName || "").join(", ")
    }
  }
}
</script>
