<template>
  <v-container class="wrapper w-list-table-root" slot-scope="{ navigatorInfo }">
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
      height="3"
    ></v-progress-linear>
    <TableFilter :list="tables" :loading="loadingStatistic" />
    <div v-if="!loading">
      <RecordList v-if="records.length > 0" :type="type" :headers="heading" :list="records" />
      <div v-else-if="records.length < 1" class="w-table-no-data">
        <ComingSoon title='workflow.message.noOption.workflow' subTitle='' icon='' />
        <v-btn color="primary" elevation="0" @click.stop="$emit('edit')">
          <v-icon small medium>mdi-plus</v-icon>
          <span>{{ $t('workflow.btn.create') }}</span>
        </v-btn>
      </div>
      <div v-else>
        <ComingSoon />
      </div>
    </div>
  </v-container>
</template>

<script>
import { map, debounce } from 'lodash';
import RecordList from './../components/tree/table/recordTable';
import TableFilter from "./../components/tree/table/filter";
import ComingSoon from "./../components/coming";
import Mixin from "@/pages/admin/workflow/mixin";
import {
  FETCH_WORKFLOW_LIST,
  FETCH_WORKFLOW_STATISTIC_TABLE,
} from '@/store/modules/workflow/action_types';
export default {
  mixins: [Mixin],
  inject: ['navigatorInfo', 'getEventType', 'filterInfo', 'setTable'],
  data() {
    const lookup = ["name", "type", "status", "creator"];
    return {
      heading: map(lookup, field => ({
        label: this.$t(`workflow.table.${field}`),
        field,
      })),
      defaultOption: {
        value: "all",
        label: this.$t('workflow.options.all'),
      },
    };
  },
  methods: {
    loadList() {
      this.$store.dispatch(FETCH_WORKFLOW_LIST, {
        type: this.type,
        keyword: this.keyword,
        table: this.table,
        appId: this.params.appId,
      });
    },
    loadFilterList() {
      this.$store.dispatch(FETCH_WORKFLOW_STATISTIC_TABLE, {
        type: this.type,
        params: {
          appId: this.params.appId,
        }
      });
    },
  },
  computed: {
    records() {
      const list = this.$store.getters.getWorkflowList;
      return map(list, l => ({
        ...l,
        mode: 'span',
      }));
    },
    loading() {
      return this.$store.getters.fetchingWorkflowList;
    },
    type() {
      return this.getEventType();
    },
    keyword() {
      return this.filterInfo.keyword;
    },
    table() {
      return this.filterInfo.table;
    },
    tables() {
      const list = this.$store.getters.getWorkflowStatisticTable || [];
      return [
        this.defaultOption,
        ...list,
      ];
    },
    loadingStatistic() {
      return this.$store.getters.fetchingWorkflowStatisticTable;
    },
  },
  components: {
    RecordList,
    TableFilter,
    ComingSoon,
  },
  created() {
    this.loadList();
    this.loadFilterList();
  },
  watch: {
    type: {
      handler(type, wasType) {
        if (type !== wasType) {
          // reset to all handling when tab is being changed
          if (this.table === 'all') {
            this.loadList();
          } else {
            this.setTable('all');
          }
          // this.$nextTick().then(() => {
          //   this.loadList();
          // });
          // this.loadList();
          this.loadFilterList();
        }
      },
      deep: true,
    },
    keyword: debounce(function() {
      this.loadList();
    }, 500),
    table: function() {
      this.loadList();
    },
  }
}
</script>

<style>
  .w-table-no-data {
    margin-top: 70px;
    text-align: center;
  }
  .w-table-no-data h1 {
    margin-bottom: 20px !important;
  }
  .w-list-table-root {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>