<template>
    <v-dialog v-model="showingDialog" scrollable width="640">
        <v-card height="90vh">
        <dialog-header @close="showingDialog=false" closable :label="$t('importErrorDialog.title')"/>
        <v-card-text>
            <loading-icon v-if="loading"/>
            <div v-else class="pt-2 fill-height">
                <v-row no-gutters>
                    <v-col cols="2">{{$t("importErrorDialog.form")}}</v-col>
                    <v-col cols="4">{{importInfo.form}}</v-col>
                    <v-col cols="2">{{$t("importErrorDialog.user")}}</v-col>
                    <v-col cols="4">{{importInfo.user}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="2">{{$t("importErrorDialog.startTime")}}</v-col>
                    <v-col cols="4">{{formatRawDateString(importInfo.startTime)}}</v-col>
                    <v-col cols="2">{{$t("importErrorDialog.endTime")}}</v-col>
                    <v-col cols="4">{{formatRawDateString(importInfo.endTime)}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="2">{{$t("importErrorDialog.insertCount")}}</v-col>
                    <v-col cols="4">{{importInfo.insertCount}}</v-col>
                    <v-col cols="2">{{$t("importErrorDialog.updateCount")}}</v-col>
                    <v-col cols="4">{{importInfo.updateCount}}</v-col>
                </v-row>
                <v-row no-gutters>
                     <v-col cols="2">{{$t("importErrorDialog.errorCount")}}</v-col>
                    <v-col cols="4">{{importInfo.errors.length}}</v-col>
                    <v-col cols="6">
                        <v-btn text small @click="downloadErrorReport" color="primary">{{$t('importErrorDialog.download')}}</v-btn>
                    </v-col>
                </v-row>
                <v-list dense outlined tile class="mt-2">
                    <v-virtual-scroll
                        :items="importInfo.errors"
                            height="540"
                            item-height="24"
                        >
                        <template v-slot:default="{item}">
                            <v-list-item inactive>
                                <v-list-item-content>
                            <i18n path="importErrorDialog.importError" :places="item">
                                <span place="detail">{{ $t(`importErrorDialog.${item.detail}`)}}</span>
                            </i18n>
                        </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-virtual-scroll>
                    <!-- <v-list-item v-for="(error, i) in importInfo.errors" :key="i" inactive>
                        <v-list-item-content>
                            <i18n path="importErrorDialog.importError" :places="error">
                                <span place="detail">{{ $t(`importErrorDialog.${error.detail}`)}}</span>
                            </i18n>
                        </v-list-item-content>
                    </v-list-item> -->
                </v-list>
                <!-- <v-row v-for="(errorInfo, i) in errorInfos" no-gutters :key="i">
                    {{$t('messages.importError', errorInfo)}}

                </v-row> -->
            </div>
        </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import DialogHeader from "@/components/dialogs/comps/DialogHeader"
import LoadingIcon from "@/components/global/loadingIcon";
import {formatDateTime} from "@/helpers/DataHelpers"
import MediaHelper from "@/helpers/MediaHelper";

export default {
    props: {
        value:Boolean,
        importId: String
    },
    components: {
        DialogHeader,
        LoadingIcon
    },
    data(){
        return {
            loading: false,
            importInfo:{}
        }
    },
    mounted(){
        if (this.importId){
            this.loading = true;
            this.$store.dispatch('AUTH_GET', {
                urlCommand: '/import/getErrorReport?id=' + this.importId
            }).then((res)=> {
                this.importInfo = res;
                this.loading = false
            })
        }
    },
    computed: {
        showingDialog:{
            set(val){
                this.$emit('input', val)
            },
            get(){
                return this.value
            }
        }
    },
    methods: {
        formatRawDateString(val){
            let date = new Date(val)
            return date.valueOf()? formatDateTime(date) : ""
        },
        downloadErrorReport(){
            this.$store.dispatch('AUTH_GET', {
                urlCommand: '/import/downloadErrorReport?id=' + this.importId
            }).then((res)=> {
                MediaHelper.downloadFile(document,[res], `${this.importInfo.form}.txt`)
            })
        }
    }
}
</script>
<style scoped>

</style>