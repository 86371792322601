<template>
  <div class="view-settings-sorting fill-height flex-grow-1 d-flex flex-column">
    <v-row no-gutters class="flex-grow-0 px-3 py-1">
      <div class="mr-3 teal lighten-2" style="padding: 2px"/>
      <h4>{{ $t("view.sorting") }}</h4>
    </v-row>
    <field-selection-dual-list
        class="mt-3 field-selection-dual-list flex-grow-1 px-3"
        :sourceListTitle="$t('view.availableFields')"
        :targetListTitle="$t('view.selectedFields')"

        :useAddAllButton="true"
        :useRemoveAllButton="true"

        :optionExclusive="true"
        :options="options"

        :fieldInfos="sortableFieldInfos"
        v-model="selection"
        type="sorting"
      >
    </field-selection-dual-list>
    <!-- <hr class="my-2"/> -->

    <!-- PENDING -->
    <!-- Allow custom ordering by user -->
    <!--<div class="d-flex flex-row align-center">-->
      <!--<label class="mr-2">{{ $t('view.allowCustomOrdering') }}</label>-->
      <!--<input-yes-no-->
          <!--:value="view.allowCustomOrdering?'true':'false'"-->
          <!--@input="val=>updateSettings('allowCustomOrdering', val==='true')"-->
          <!--ref="inputYesNo"></input-yes-no>-->
        <!--&lt;!&ndash;<input-yes-no&ndash;&gt;-->
    <!--</div>-->
  </div>
</template>

<script>
import mixin from './mixin'
import adminFormMixin from '@/mixins/adminFormMixin'
import widgetHelperMixin from '@/mixins/widgetHelperMixin'
import fieldSelectionDualList from '@/components/dialogs/tabs/comps/FieldSelectionDualList'
// import settingsYesNoRow from './comps/SettingsYesNoRow'
import inputYesNo from '@/components/formComps/InputYesNo'

export default {
  name: 'viewSettingsSorting',
  mixins: [
    mixin,
    adminFormMixin,
    widgetHelperMixin
  ],
  components: {
    fieldSelectionDualList,
    inputYesNo
    // settingsYesNoRow
  },
  props: {
    view: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      // headers: [],
      // sortOrderMap: [],
      options: [
        {value: 'asc', icon: 'mdi-sort-ascending', tooltipTag: 'general.increasing'},
        {value: 'desc', icon: 'mdi-sort-descending', tooltipTag: 'general.decreasing'}
      ]
    }
  },
  // mounted () {
  //   const vm = this
  //   vm.headers = [
  //     {text: '#', align: 'center', sortable: false, value: 'index'},
  //     {text: vm.$t('sorting.fieldName'), align: 'left', sortable: true, value: 'fieldName'},
  //     {text: vm.$t('sorting.sortable'), align: 'center', sortable: false, value: 'userSortable'},
  //     {text: vm.$t('sorting.default'), align: 'center', sortable: false, value: 'default'}
  //   ]
  //   vm.loadFieldData()
  // },
  computed: {
    form () {
      const vm = this
      return vm.$store.getters.currentForm
    },
    fieldInfos () {
      const vm = this
      var result = []
      if (vm.form) {
        result = vm.form.fieldInfos
      }
      return result
    },
    sortableFieldInfos () {
      return this.fieldInfos
    },
    selection: {
      get () {
        const vm = this
        console.log('ViewSettingsSorting :: selection.get')
        var result = {
          fieldIds: [],
          values: []
        }
        if (vm.sortingConfigs) {
          result.fieldIds = vm.sortingConfigs.map(config => config.fieldId)
          result.values = vm.sortingConfigs.map(config => config.order)
        }
        return result
      },
      set (val) {
        console.log('ViewSettingsSorting :: selection.set')
        var updated = []
        for (let i = 0; i < val.fieldIds.length; i++) {
          updated.push({
            fieldId: val.fieldIds[i],
            order: val.values[i]
          })
        }
        console.log('ViewSettingsSorting :: selection.set => updated: ', updated)
        this.$emit('onCommand', {
          command: 'updateSorting',
          sortingConfigs: updated
        })
      }
    },
    sortingConfigs () {
      const vm = this
      var result = []
      if (vm.view.sortingConfigs) {
        result = vm.view.sortingConfigs
      }
      return result
    }
  },
  methods: {
    updateSettings (settingName, settingValue) {
      this.$emit('onCommand', {
        command: 'updateViewSetting',
        settingName: settingName,
        val: settingValue
      })
    }
    // toggleUserSortable (item) {
    //   const vm = this
    //   const targetItem = vm.sortOrderMap.find(loopItem => loopItem.fieldId === item.fieldId)
    //   if (targetItem) {
    //     targetItem.userSortable = !targetItem.userSortable
    //   }
    // },
    // setDefaultSortable (item) {
    //   const vm = this
    //   const fieldId = item.fieldId
    //   vm.sortOrderMap.forEach(loopItem => {
    //      loopItem.default = (loopItem.fieldId===fieldId)
    //   })
    // }
    // ,
    // loadFieldData () {
    //   const vm = this
    //   vm.sortOrderMap = []
    //   for (var i = 0; i < vm.form.fieldInfos.length; i++) {
    //     const loopFieldInfo = vm.form.fieldInfos[i]
    //     var sortable = false
    //
    //     const widget = vm.getWidget(loopFieldInfo.widgetId)
    //     if (widget) {
    //       sortable = widget.sortable
    //     } else {
    //       sortable = true
    //     }
    //
    //     // fieldName
    //     var fieldName = loopFieldInfo.properties['fieldName']
    //     if (loopFieldInfo.properties['fieldNameTag']) {
    //       fieldName = vm.$t(loopFieldInfo.properties['fieldNameTag'])
    //     }
    //
    //     vm.sortOrderMap.push({
    //       index: i + 1,
    //       fieldId: loopFieldInfo.fieldId,
    //       fieldName: fieldName,
    //       sortable: sortable,
    //       userSortable: false,
    //       default: loopFieldInfo.fieldId === 'createdAt'
    //     })
    //   }
    // }
  }
}
</script>
