<template>
    <div>
        <v-navigation-drawer
            v-model="bl_drawer"
            absolute
            right
            width="650"
            class=''
            style="height:100%"
            temporary
        >
            <v-card tile style="height:100%" elevation="0">
                <v-card-subtitle class="px-5 py-4" style="border-bottom: 1px solid #eeee; font-size:16px">{{$t('dashboard.buttonStyle')}}</v-card-subtitle>
                <v-card-text class="pa-0 d-flex flex-column" style="background-color: #eeee; height:100%;">
                    <v-card @click="btnTemplate(1)" outlined elevation="" tile style="height:100px; width:100%" class="white mt-8 d-flex align-center justify-center">
                        <v-btn class="info rounded-sm">
                            {{$t('dashboard.addNewOrder')}}
                        </v-btn>
                    </v-card>
                    <v-card @click="btnTemplate(2)" outlined elevation="0" tile style="height:100px; width:100%" class="white mt-4 d-flex align-center justify-center">
                        <v-btn class="info rounded-pill">
                            {{$t('dashboard.addNewClient')}}
                        </v-btn>
                    </v-card>
                    <v-card @click="btnTemplate(3)" outlined elevation="0" tile style="height:120px; width:100%" class="d-flex flex-column white mt-4 d-flex align-center justify-center">
                        <div> {{$t('dashboard.client')}}</div>
                        <div class="d-flex mt-2">
                            <v-btn class="success rounded-sm">
                                {{$t('dashboard.addClient')}}
                            </v-btn>
                            <v-btn class="success rounded-sm ml-5">
                                {{$t('dashboard.addVisitHistory')}}
                            </v-btn>
                            <v-btn class="info rounded-sm ml-5">
                                {{$t('dashboard.addForm')}}
                            </v-btn>
                        </div>
                    </v-card>
                    <v-card @click="btnTemplate(4)" outlined elevation="0" tile style="height:150px; width:100%" class="d-flex flex-column white mt-4 d-flex align-center justify-center">
                        <div>{{$t('dashboard.quickMode')}}</div>
                        <div class="d-flex mt-2">
                            <v-btn elevation="0" class="info--text rounded-sm ml-5" style="width:200px; background-color: #eeee">
                                {{$t('dashboard.addNewOrder')}}
                            </v-btn>
                            <v-btn elevation="0" class="info--text rounded-sm ml-5" style="width:200px; background-color: #eeee">
                                {{$t('dashboard.addNewClient')}}
                            </v-btn>
                        </div>
                        <div class="d-flex mt-3">
                            <v-btn elevation="0" class="info--text rounded-sm ml-5" style="width:200px; background-color: #eeee">
                                {{$t('dashboard.viewMyOrder')}}
                            </v-btn>
                            <v-btn elevation="0" class="info--text rounded-sm ml-5" style="width:200px; background-color: #eeee">
                                {{$t('dashboard.viewMyStat')}}
                            </v-btn>
                        </div>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
        <ButtonDialog 
            v-if="bl_buttonDialog" 
            v-model="bl_buttonDialog" 
            :btns="btns"
            :btnCount="btnCount"
            @addbtnCount="addbtnCount"
            :numPerRow="numPerRow"
            @update_numPerRow="(val) => numPerRow=val"
            :align="align"
            @update_align="(val) => align=val"
            :title="title"
            @update_title="(val) => title=val"
            @saveBtn="saveBtn"
        />
    </div>
</template>

<script>
    import ButtonDialog from './ButtonDialog'
    export default {
        props:{
            value:{ 
                type: Boolean,
                default: false
            }
        },
        components:{
            ButtonDialog
        },
        computed:{
            bl_drawer:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val) 
                }
            }
        },
        data(){
            return{
                bl_buttonDialog: false,
                btns: [],
                btnCount: 0,
                numPerRow:3,
                align: 'justify',
                title: this.$t('general.title'),
            }
        },
        watch:{
            numPerRow(newVal, oldVal){
                if(newVal>8){
                    this.numPerRow = 8               
                }
                if(newVal<1){
                    this.numPerRow = 1
                }
            }
        },
        methods:{
            saveBtn(){
                this.bl_buttonDialog = false
                this.$emit('update_elements', {type:'add',itemType:'btn', btns: this.btns, numPerRow: this.numPerRow, align: this.align, title: this.title})
            },
            addbtnCount(){
              this.btnCount++  
            },
            btnTemplate(index){
                this.btns = []
                switch(index){
                    case 1:
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#42A5F5",
                            text: this.$t('dashboard.addNewOrder'),
                            type:"rounded-sm",
                            whiteText:true
                        })
                        this.align="center"
                        this.title=""
                        this.numPerRow=1
                        break
                    case 2:
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#42A5F5",
                            text: this.$t('dashboard.addNewClient'),
                            type:"rounded-pill",
                            whiteText:true
                        })
                        this.align="center"
                        this.title=""
                        this.numPerRow=1
                        break
                    case 3:
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#4CAF50",
                            text: this.$t('dashboard.addClient'),
                            type:"rounded-sm",
                            whiteText:true
                        })
                        this.align="center"
                        this.title= this.$t('dashboard.client'),
                        this.numPerRow=3
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#4CAF50",
                            text: this.$t('dashboard.addVisitHistory'),
                            type:"rounded-sm",
                            whiteText:true
                        })
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#42A5F5",
                            text: this.$t('dashboard.addForm'),
                            type:"rounded-sm",
                            whiteText:true
                        })
                        break
                    case 4:
                        this.btnCount++
                        this.align="justify"
                        this.title=this.$t('dashboard.quickMode')
                        this.numPerRow=2
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#42A5F5",
                            text: this.$t('dashboard.addNewOrder'),
                            type:"outlined",
                            whiteText:false
                        })
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#42A5F5",
                            text: this.$t('dashboard.addNewClient'),
                            type:"outlined",
                            whiteText:false
                        })
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#42A5F5",
                            text: this.$t('dashboard.viewMyOrder'),
                            type:"outlined",
                            whiteText:false
                        })
                        this.btnCount++
                        this.btns.push({
                            id: this.btnCount,
                            bgColor:"#42A5F5",
                            text: this.$t('dashboard.viewMyStat'),
                            type:"outlined",
                            whiteText:false
                        })
                        break
                }
                this.bl_drawer = false
                this.bl_buttonDialog = true
            }
        }
    }
</script>