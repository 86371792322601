<template>
 <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :fieldWidth="fieldWidth"
      :isHidden="isHidden"
      :label="fieldName"
      :noIcon="noIcon"
      :tooltipText="description">
      <v-input :ref="fieldInfo.fieldId" :class="{ 'workflow-customize-form-record-hide': hideInput }" :value="inputValue" :rules="rules" :error="hasError" class="workflow-customized-form-record-element">
        <v-rating size="25" clearable :length="ratingRange" dense hover v-model="inputValue"  :readonly="isReadOnly"
        full-icon="mdi-star" empty-icon="mdi-star-outline" :background-color="(isReadOnly)? 'grey lighten-1': 'primary'" :color="(isReadOnly)? 'grey lighten-1': 'primary'"/>
      </v-input>

    <!-- <v-btn icon
             v-for="i in ratingRange"
             :key="i"
             :class="levelButtonClass(i)"
             @click="toggleRating(i)"
             @enter="onMouseEnter(i)"
             @leave="onMouseLeave(i)">
        <v-icon class="star-on">mdi-star</v-icon>
        <v-icon class="star-off">mdi-star-outline</v-icon>
      </v-btn> -->
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import inputWidgetContainer from "./comps/InputWidgetContainer";

export default {
  name: "inputWidgetRating",
  mixins: [mixin],
  components: {
    inputWidgetContainer,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data() {
    return {
      userValue: 0,
      MAX_RATING: 10,
      hovering: false,
      hoveringLevel: 0,
      RULE_REQUIRED: (v) =>
        !!v || v > 0 || this.$t("messages.thisFieldIsRequired"),
    };
  },
  methods: {
    validate(){
      if (this.isReadOnly) return true;
      if (this.$refs[this.fieldInfo.fieldId]) {
        const valid = this.$refs[this.fieldInfo.fieldId].validate()
        this.hasError = !valid
        return valid
      } else return true
    },
    _onParseValue(value) {
      var result = 0;
      if (value) {
        result = parseInt(value);
      }
      return result;
    },

    onMouseEnter(level) {
      vm.hovering = true;
      vm.hoveringLevel = level;
    },

    onMouseLeave() {
      vm.hovering = false;
    },

    toggleRating(level) {},

    levelButtonClass(level) {
      const vm = this;
      var result = "";
      if (vm.isLayout) {
        var defaultValue = getFieldPropertyValue(vm.fieldInfo, "default", "");
        if (defaultValue === "") {
          result = level <= vm.ratingRange / 2 ? "level-on" : "level-off";
        } else {
          result = level <= defaultValue ? "level-on" : "level-off";
        }
      } else {
        if (vm.hovering) {
          result = level <= vm.hoveringLevel ? "level-on" : "level-off";
        } else {
          result = level <= vm.inputValue ? "level-on" : "level-off";
        }
      }
      console.log("levelButtonClass :: level = " + level);
      console.log("levelButtonClass :: result: ", result);
      return result;
    },
  },
  computed: {
    ratingRange() {
      const vm = this;
      const ratingRange = getFieldPropertyValue(
        this.fieldInfo,
        "ratingLevel",
        "5stars"
      );
      return ratingRange === "5stars" ? 5 : 10;
    },
  },
};
</script>

<style>
.is-layout {
  padding-bottom: 0 !important;
}

.level-on .star-on,
.level-off .star-off {
  display: inline-block;
}

.level-on .star-off,
.level-off .star-on {
  display: none;
}
</style>
