<template>
  <v-dialog v-model="showDialog" scrollable width="540" persistent>
    <v-card max-height="800" width="540">
      <DialogHeader
        @close="showDialog = false"
        :label="selectedOption ? $t('app.editOptions') : $t('app.newOptions')"
      />

      <v-card-text class="py-2">
        <label>{{ $t("general.title") }}</label>
        <v-text-field
          autofocus
          dense
          v-model="option.title"
          outlined
          :rules="[required]"
        ></v-text-field>
        <SelectionOptionsSetting
          :useColor="option.useColor"
          :options="options"
          :useValue="option.useValue"
          @onCommand="onCommandHandler"
        >
          <template v-slot:label>
            <label
              >{{ $t("widgets.properties.option") }} ({{
                options && options.length
              }})</label
            >
          </template>
          <template v-slot:prepend-delete="{ item }">
          <v-btn small icon color="success" v-if="item.active" @click="item.active=!item.active">
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn small icon v-else @click="item.active=!item.active">
              <v-icon small>mdi-eye-off</v-icon>
          </v-btn>

          </template>
        </SelectionOptionsSetting>
      </v-card-text>
      <DialogActions
        @onCommand="onCommandHandler"
        :actionDisabled="!option.title"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import SelectionOptionsSetting from "@/components/form/SelectionOptionsSetting";
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";
import ObjectID from "bson-objectid";

import selectionOptionMixin from "@/mixins/selectionOptionMixin";

export default {
  components: {
    SelectionOptionsSetting,
    DialogHeader,
    DialogActions,
  },
  mixins: [selectionOptionMixin],
  props: {
    value: Boolean,
    selectedOption: Object,
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    fullOptions: {
      get() {
        return this.option.options || [];
      },
      set(val) {
        this.option.options = val;
      },
    },
  },
  data() {
    return {
      option: {},

      required: (v) =>
        (v && Boolean(v.trim())) || this.$t("messages.thisFieldIsRequired"),
    };
  },
  mounted() {
    if (this.selectedOption) {
      this.option = JSON.parse(JSON.stringify(this.selectedOption));
    } else {
      this.option = {
        title: "",
        useColor: false,
        options: [
          {
            _id: ObjectID().toHexString(),
            label: this.$t("widgets.properties.active"),
            order: 0,
            colorSet: "#000000/#C9E6FC",
            isDeleted: false,
            active: true,
          },
          {
            _id: ObjectID().toHexString(),
            label: this.$t("widgets.properties.inactive"),
            order: 1,
            colorSet: "#000000/#C3F2F2",
            isDeleted: false,
            active: true,
          },
        ],
      };

      this.newOptionIds = this.option.options.map((option) => option._id);
    }

    this.nextColorIndex = this.option.options.length;
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "new":
          this.addNewOption();
          break;
        case "bulkAddOptions":
          this.bulkAddOptions(payload.options);
          break;
        case "reorder":
          this.reorder(payload.options);
          break;
        case "remove":
          this.removeOption(payload._id);
          break;
        case "updateProperty":
          this.option[payload.key] = payload.val;
          break;
        case "updateOption":
          this.updateOption(payload._id, payload.val, payload.key);
          break;
        case "updateColorSet":
          this.updateColorSet(payload._id, payload.colorIndex);
          break;
        case "updateValueAssignment":
          this.updateValueAssignment(payload)
          break;
        case "cancel":
          this.showDialog = false;
          break;
        case "ok":
          this.$emit("submit", this.option);
          this.showDialog = false;
          break;
      }
    },
    updateValueAssignment({val:option}){
      this.$set(this.option, 'useValue', option.useValue)
      this.fullOptions = option.options
    }
  },
};
</script>
<style scoped></style>
