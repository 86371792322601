<template>
<!-- "{name: 'MyInput'}" -->
  <vxe-table-column class-name="fullCell" v-bind="colDef" :edit-render="{name: 'input', autoselect: true, enabled: !readonly}">
    <template v-slot="{row}">
      <div class="d-flex">
        <div v-if="row[fieldId]">
          <!-- <span>{{row[fieldId].regionText}}</span> -->
          <RegionMenu
            style="height:100%"
            :bl_tableCell="true"
            :fieldInfo="colDef.fieldInfo"
            :value="row[fieldId]"
            :regions="regions"
            textFieldClass="edit-region-textfield"
            @input="regionChangeHandler(row, $event)"
            readonly="true"
          />
        </div>
      </div>
      <quick-action
        :rowId="row['_id']"
        :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{row}">
      <div 
        class="edit-region-modal" 
        style="width:100%; height:100%; cursor: pointer" 
        @click="showMenu=true"
      >
        <div style="width:100%; height:100%">
          <RegionMenu
            style="height:100%"
            :bl_tableCell="true"
            :fieldInfo="colDef.fieldInfo"
            :value="row[fieldId]"
            :regions="regions"
            textFieldClass="edit-region-textfield"
            @input="regionChangeHandler(row, $event)"
          />
        </div>
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import RegionMenu from '../inputWidgets/comps/RegionMenu'
import VXETable from 'vxe-table'
import Vue from 'vue'
import quickAction from './comps/QuickAction'
import summaryCell from './comps/SummaryCell'

export default {
    mixins: [mixin],
    components:{
      RegionMenu,
      quickAction,
      summaryCell
    },
    data(){
      return {
        value: {province: null, city: null, state: null, regionText: ''},
        regions: [],
        showMenu: false
      }
    },
    computed:{
      isPublic(){
        return ["PublicForm", "PublicQuery", "PublicEdit"].includes(this.$route.name);
      }
    },
  watch: {
    '$i18n.locale'(){
      this.fetchRegions()
    }
  },
    methods: {
    regionChangeHandler(row, event){
      this.$set(row, this.fieldId, event)
    },
    async fetchRegions(){
        const vm = this
        //TODO: implement locale
        let locale = this.$i18n.locale
        //if(locale=="zh-TW") locale = "hk"
        if (this.isPublic){
          await this.axios.get(this.$store.getters.appHost + '/public/getRegions/' + locale)
            .then(response => {
                this.regions = response.data.options
            })
            .catch(err => {
                console.log(err)
            })
        }else {
        const getParams = {
            urlCommand: '/widgets/getRegions/' + locale
        }
        await vm.$store.dispatch('AUTH_GET', getParams)
            .then(response => {
                console.log(response)
                this.regions = response.options
            })
            .catch(err => {
                console.log(err)
            })
        }

    },
    },
    mounted(){
      if (this.editable){
        this.fetchRegions()
      }
    },
}
</script>


<style lang="scss" scoped>
  .edit-region-modal {
    display: flex;
    align-items: center;
  }
  .edit-region-input {
    width: auto;
    flex-grow: 1;
  }
  .edit-region-button {
    flex-shrink: 0;
  }
</style>
