<template>
  <base-tabs-dialog
      ref="baseTabsDialog"
      v-model="showing"
      icon="mdi-window-restore"
      :title="$t('messages.dataSelectionSetup')"
      @submit="onSubmitHandler"
      @onCommand="onCommandHandler"
      :loading="loading"
      :actionDisabled="!isValidData"
      :tabs="tabs">
    <!--:isValidData="isValidData"-->
    <template>
      <v-tab-item v-for="(tab, index) in tabs" 
                  :key="index"
                  :eager="true"
                  :transition="false"
                  :reverse-transition="false"
                  class="fill-height">
        <component :is="tab.componentName"
                   :tabId="tab.id"
                   ref="tabSheets"
                   :fieldInfos="rearrangedFieldInfos"
                   :dataSourceInfo="dataSourceInfo"
                   :tabKey="tab.key"
                   :options="options"
                   :errorCount="tab.errorCount"
                   v-model="tabData"
                   :type="tab.type"
                   @onCommand="onCommandHandler"></component>
      </v-tab-item>
    </template>
  </base-tabs-dialog>
</template>

<script>
import widgetHelperMixin from '@/mixins/widgetHelperMixin'
import baseTabsDialog from './BaseTabsDialog'
import dialogTabs from '@/components/dialogs/tabs'

export default {
  name: 'multipleTabDialog',
  mixins: [
    widgetHelperMixin
  ],
  components: {
    baseTabsDialog,
    // ,
    ...dialogTabs
  },
  data () {
    return {
      showing: false,
      options: {},
      tabData: {
        cardFields: [],
        displayFields: {
          fixedFieldIds: [],
          nonFixedFieldIds: []
        },
        selectionTable: {
          fixedFieldIds: [],
          nonFixedFieldIds: [],
          filterConfigs: {},
          sortingConfigs: []
        },
        displayTable: {
          fixedFieldIds: [],
          nonFixedFieldIds: [],
          filterConfig: {},
          sortingConfig: []
        }
      },
      loading: false,
      tabs: [],
      dataSource: null,
      dataSourceInfo: null // = form
      // dataSourceTitleFieldId: ''
    }
  },
  watch: {
    showing: function (newVal) {
      if (newVal === false) {
        this.loading = false
      }
    }
  },
  computed: {
    rearrangedFieldInfos () {
      // Place owner, createdBy, createdAt, updatedAt at the end of list
      const vm = this
      const systemFieldIds = ['owner', 'createdBy', 'createdAt', 'updatedAt']
      var result = vm.fieldInfos.filter(info => !systemFieldIds.includes(info.fieldId))
      systemFieldIds.forEach(fieldId => {
        vm.appendFieldInfo(result, fieldId, vm.fieldInfos)
      })
      console.log('MultipleTabDialog :: rearrangedFieldInfos: ', result)
      return result
    },


    fieldInfos () {
      const vm = this
      var result =[]
      if (vm.dataSourceInfo) {
        result = vm.dataSourceInfo.fieldInfos
      }
      return result
    },
    dataSourceTitleFieldId () {
      const vm = this
      var result = ''
      if (vm.dataSourceInfo) {
        const titleFieldInfoId = vm.dataSourceInfo.titleFieldInfoId
        const fieldInfo = vm.fieldInfos.find(
          info => info._id === titleFieldInfoId)

        if (fieldInfo) {
          result = fieldInfo.fieldId
        }
      }
      return result
    },
    isValidData () {
      const vm = this
      return vm.checkValidity()
    },
    cardNonDisplayFieldTypes () {
      return this.$store.getters.cardNonDisplayFieldTypes
    },
    allWidgets () {
      return this.$store.getters.allWidgets
    }
  },
  methods: {
    appendFieldInfo (result, fieldId, fieldInfos) {
      const vm = this
      const fieldInfo = fieldInfos.find(info => info.fieldId === fieldId)
      if (fieldInfo) {
        result.push(fieldInfo)
      }
      return fieldInfo
    },
    // getAndAppend (fieldId, fieldInfos) {
    //   const vm = this
    //   const index = fieldInfos.findIndex(info => info.fieldId === fieldId)
    //   const fieldInfo = fieldInfos.splice(index, 1)
    //   fieldInfos.push(fieldInfo[0])
    // },
    checkValidity () {
      const vm = this
      var result = true
      // console.log('checkValidity :: vm.$refs.tabSheets: ', vm.$refs.tabSheets)
      if (vm.$refs.tabSheets) {
        for (let i = 0; i < vm.$refs.tabSheets.length; i++) {
          const tabRef = vm.$refs.tabSheets[i]
          console.log('i=' + i + ': ', tabRef)
          if (tabRef) {
            // console.log('checkValidity :: tabRef: ', tabRef)
            if (!tabRef.checkErrorCount(true)) {
              result = false
            }
          }
        }
      }
      return result
    },
    onCommandHandler (payload) {
      const vm = this
      // console.log('relatedRecordSelectionSetupDialog :: onCommandHandler :: payload: ', payload)
      var tabIndex = 0
      switch (payload.command) {
        case 'updateErrorCount':
          // console.log('onCommandHandler :: updateErrorCount tabId=' + payload.tabId + ', count=' + payload.errorCount)
          tabIndex = vm.tabs.findIndex(tab => tab.id === payload.tabId)
          if (tabIndex >= 0) {
            vm.tabs[tabIndex].errorCount = payload.errorCount
          }
          break
        case 'checkValidity':
          tabIndex = payload.tabIndex
          const refObj = vm.getTabRefByIndex(tabIndex)
          if (refObj) {
            // console.log('relatedRecordSelectionSetupDialog :: onCommandHandler command=checkValidity :: refObj: ', refObj)
            // console.log('relatedRecordSelectionSetupDialog :: onCommandHandler command=checkValidity :: tabIndex = ' + tabIndex)

            if (typeof refObj.checkIsValid === 'function') {
              refObj.checkIsValid(true)
            } else {
              console.log('*** checkIsValid undefined: ', vm.tabs[tabIndex])
            }
          }
          break
      }
    },

    getTabRefByIndex (tabIndex) {
      const vm = this
      var result = null
      if (vm.$refs['tabSheets']) {
        result = vm.$refs['tabSheets'][tabIndex]
      }
      // console.log('getTabRefByIndex: vm.$refs: ', vm.$refs)
      // console.log('getTabRefByIndex: vm.$refs[tabSheets]: ', vm.$refs['tabSheets'])
      return result
    },

    open (propertyValue, dataSource, tabs, dataSourceInfo) {
      // dataSourceInfo is defined for loopBack relation
      //
      const vm = this
      vm.tabs = tabs
      vm.tabData = JSON.parse(JSON.stringify(propertyValue))

      // assign full list of fields
      // attribute 'active' indicating if active
      vm.dataSource = dataSource
      vm.dataSourceInfo = dataSourceInfo


      vm.$refs.baseTabsDialog.tabIndex = 0
      vm.showing = true
    },

    // initDisplayFields () {
    //   const vm = this
    //   if (vm.tabData.displayFields.fixedFieldIds.length === 0 &&
    //       vm.tabData.displayFields.nonFixedFieldIds.length === 0) {
    //     if (vm.dataSourceTitleFieldId !== '') {
    //       vm.tabData.displayFields.fixedFieldIds.push(
    //         vm.dataSourceTitleFieldId
    //       )
    //     }
    //   }
    // },

    getDataSourceInfo (dataSource) {
      const vm = this
      const ds = {

        formId: dataSource
      }
      vm.$store.dispatch('FETCH_DATASOURCE_INFO', ds).then(
        response => {
          // console.log('getDataSourceInfo: response: ', response)
          vm.dataSourceInfo = response
          // vm.initDisplayFields()
        },
        error => {
          console.log('relatedRecordSelectionSetupDialog :: getDataSourceInfo :: error: ', error)
        }
      )
    },
    // getFieldsFromDataSource (dataSource) {
    //   const vm = this
    //   // var result = []
    //   if (vm.dataSourceInfo) {
    //     vm.relatedFieldInfos = vm.dataSourceInfo.fieldInfos
    //     // result = vm.updateFields(vm.relatedFieldInfos)
    //   }
    //   // return result
    // },

    updateFields (fieldInfos) {
      const vm = this
      // console.log('udpateFields')
      const displayableFieldInfos = fieldInfos.filter(info => {
        return vm.cardNonDisplayFieldTypes.indexOf(info.type) === -1
      })
      // console.log('updateFields: displayableFieldInfos: ', displayableFieldInfos)
      const updatedFieldIds = displayableFieldInfos.map(field => field.fieldId)
      const existingFieldIds = vm.tabData.cardFields.map(field => field.id)
      const newFieldInfos = displayableFieldInfos.filter(field => existingFieldIds.indexOf(field.fieldId) === -1)

      // console.log('updateFields: updatedFieldIds: ', updatedFieldIds)
      // console.log('updateFields: existingFieldIds: ', existingFieldIds)
      // console.log('updateFields: newFieldInfos: ', newFieldInfos)
      // console.log('titleFieldId = ' + vm.dataSourceTitleFieldId)

      var updatedFields = []
      for (let i = 0; i < vm.tabData.cardFields.length; i++) {
        const loopField = vm.tabData.cardFields[i]
        if (updatedFieldIds.indexOf(loopField.id) >= 0) {
          const fieldInfo = displayableFieldInfos.find(info => info.fieldId === loopField.id)
          updatedFields.push({
            id: loopField.id,
            label: fieldInfo.label,
            active: loopField.active,
            fieldInfo: fieldInfo,
            isTitleField: loopField.id === vm.dataSourceTitleFieldId
          })
        }
      }
      for (let i = 0; i < newFieldInfos.length; i++) {
        const loopFieldInfo = newFieldInfos[i]
        updatedFields.push({
          id: loopFieldInfo.fieldId,
          label: loopFieldInfo.label,
          active: false,
          fieldInfo: loopFieldInfo,
          isTitleField: loopFieldInfo.fieldId === vm.dataSourceTitleFieldId
        })
      }
      return updatedFields
    },

    onSubmitHandler () {
      const vm = this
      vm.$emit('confirm', vm.tabData)
      vm.showing = false
    }
  }
}
</script>
