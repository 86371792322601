<template>
  <div class="fs-text text-no-wrap overflow-hidden">
    <label v-if="useLabel"
           class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    {{ titleValue }}
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsLocation',
  mixins: [mixin],
  computed: {
    titleValue () {
      const vm = this
      return vm.getDataValueAttribute('name')
    }
  }
}
</script>
