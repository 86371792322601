<template>
  <div v-if="fieldInfo.type === '_sectionLine'" class="sectionLine">
    <span class="fieldLabel "> {{ fieldInfo.properties.title }}</span>
  </div>
  <div v-else-if="fieldInfo.type === '_remark'" class="remark">
    <div
      class="ql-editor fieldValue"
      v-html="fieldInfo.properties.remark"
    ></div>
  </div>
  <div v-else-if="fieldInfo.type === 'childTable'" class="childTableWrapper">
    <div class="fieldLabel">{{ label }}</div>
    <slot name="child-table-control"></slot>
    
    <child-table-panel
      v-if="childTableFields[fieldInfo._id].type === 'panel'"
      :records="value"
      :fontSize="fontSize"
      :fieldInfos="fieldInfo.properties.fieldsEditor.fieldInfos"
      :visibleFields="childTableFields[fieldInfo._id].fields || []"
    />
    <child-table
      v-else
      :records="value"
      :fontSize="fontSize"
      :fieldInfos="fieldInfo.properties.fieldsEditor.fieldInfos"
      :visibleFields="childTableFields[fieldInfo._id].fields || []"
    />
  </div>
  <div v-else>
    <div class="fieldLabel">
      {{ label }}
    </div>
    <div
      class="fieldValue ql-editor"
      style="min-height:24px"
      v-if="fieldInfo.type == 'richText'"
      v-html="displayValue"
    ></div>
    <div v-else-if="fieldInfo.type === 'yesNo'" class="fieldValue">{{displayValue ? '&#10003;':''}}</div>
    <div v-else class="fieldValue">
      {{ displayValue }}
    </div>
  </div>
</template>
<script>
import { getDisplayValue } from "@/helpers/DataHelpers";
import ChildTable from "./ChildTable";
import ChildTablePanel from "./ChildTablePanel";
export default {
  components: {
    ChildTable,
    ChildTablePanel,
  },
  props: {
    fieldInfo: Object,
    item: Object,
    value: String,
    childTableFields: Object,

    fontSize: Number,


  },
  computed: {
    label() {
      return (this.fieldInfo && this.fieldInfo.label) || "";
    },
    displayValue() {
      return getDisplayValue(
        this.value,
        this.fieldInfo,
        this
      );
    },
    isUIElement() {
      return this.item.controlType === "static";
    },
  },
};
</script>
