import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import {formatNumber} from "../../helpers/DataHelpers"

const mixin = {
  props: {
    colDef: Object,
    editable: {
      type: Boolean,
      default: true
    },
    relationData: Object,
    footerFunc: Array,
    quickInsert: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data(){
    return{
      tdClass: ''
    }
  },
  methods: {
    selectCalcMethod (
      payload, 
      // columnIndex, 
      // $columnIndex, 
      // _columnIndex, 
      // $rowIndex, 
      // itemsData
      ) {
     
      const column = payload.column
      const target = payload.target


      this.$emit('onCommand', {
        command: 'selectCalcMethod',
        data: {
          column,
          target
        }
      })
    },
    updateTdClass(val){
      if(val){
        this.tdClass = 'popModal'
      }
      else
        this.tdClass = ''
    },
    getPropertyValue (propertyName, defaultValue) {
      const vm = this
      var result = defaultValue
      if (vm.fieldInfo && vm.fieldInfo.properties && vm.fieldInfo.properties[propertyName]) {
        result = vm.fieldInfo.properties[propertyName]
      }
      return result
    },
    getSummaryLabelValuePairs (column) {
      const vm = this
      // console.log('getSummaryLabelValuePairs: column: ', column)
      var result = []
      if (vm.colDef.options && vm.colDef.options.summaryData) {
        // console.log('getSummaryLabelValuePairs: vm.colDef.options.summaryData: ', vm.colDef.options.summaryData)
        const summaryData = vm.colDef.options.summaryData.find(data => data.fieldId === column.property)
        if (summaryData) {
          result = summaryData.labelValuePairs
        }
      }
      // console.log('getSummaryLabelValuePairs :: result: ', result)
      return result
    },
    formatNumber(val) {
      return formatNumber(val || 0, this.decimals || 0)
    },
  },
  computed: {
    prefix () {
      return this.getPropertyValue('prefix', null)
    },
    suffix () {
      return this.getPropertyValue('suffix', null)
    },
    decimals () {
      return this.getPropertyValue('decimals', '')
    },

    idDataMapping () {
      return this.relationData &&  this.relationData.idDataMapping || {}
    },
    readonly(){
      return Boolean(
        this.fieldInfo && 
        (!this.fieldInfo.canEdit || 
          (this.fieldInfo.properties.authorization && this.fieldInfo.properties.authorization.includes('readonly'))
        )
      )
    },
    currentApp () {
      return this.$store.getters.currentApp
    },
    dataSource () {
      const vm = this
      var result = null
      if (vm.fieldInfo && vm.fieldInfo.properties && vm.fieldInfo.properties['dataSource']) {
        result = vm.fieldInfo.properties['dataSource']
      }
      return result
    },
    propertyKey () {
      return this.fieldInfo.type === 'singleSelection' ? 'inputOptionsSingle' : 'inputOptionsMultiple'
    },
    propertyValue () {
      return this.fieldInfo.properties[this.propertyKey]
    },
    options () {
      return this.propertyValue ? this.propertyValue.options : []
    },
    useColor () {
      return this.propertyValue ? this.propertyValue.useColor : false
    },
    selection () {
      return this.options.filter(option => !option.isDeleted && option.active)
    },
    tableSummationFieldIds () {
      const vm = this
      var result = []
      if (vm.colDef.options && vm.colDef.options.tableSummationFieldIds) {
        result = vm.colDef.options.tableSummationFieldIds
      }
      return result
    },

    inSummationFieldIds () {
      const vm = this
      return vm.tableSummationFieldIds.includes(vm.fieldId)
    },
    isNumeric () {
      const vm = this
      const NUMERIC_TYPES = ['number', 'amount', 'expression']
      return NUMERIC_TYPES.includes(vm.fieldInfo.type)
    },
    currentFormInfo () {
      return this.formInfo
    },
    formInfo () {
      return this.$store.getters.currentForm
    },
    fieldInfos () {
      const vm = this
      var result = []
      if (vm.colDef.fieldInfos) {
        result = vm.colDef.fieldInfos
      } else if(vm.formInfo) {
        result = vm.formInfo.fieldInfos
      }
      return result
    },

    fieldInfo () {
      return this.colDef ? this.colDef.fieldInfo : null      
    },

    fieldId () {
      return this.fieldInfo ? this.fieldInfo.fieldId : null
    },

    relatedTableInfos () {
      return this.colDef.relatedTableInfos
    },

    relatedTableData () {
      return this.$store.getters.relatedTableData
    },

    // for relation summary field
    // tableField => linkedFieldInfoId
    linkedFieldInfoId () {
      return getFieldPropertyValue(this.fieldInfo, 'tableField', '')
    },
    linkedFieldInfo () {
      const vm = this
      var result = null
      if (this.linkedFieldInfoId !== '') {
        result = this.fieldInfos.find(info => info._id === this.linkedFieldInfoIdd)
      }
      return result
    },
    summaryFormId () {
      return this.linkedFieldInfo ? 
        this.linkedFieldInfo.properties.dataSource :
        ''        
    },
    summaryFieldId () {
      return getFieldPropertyValue(this.fieldInfo, 'summaryField', '')
    },
    summaryFormInfo () {
      const vm = this
      return vm.relatedTableInfos && vm.summaryFormId !== '' ?
        vm.relatedTableInfos.find(info => info._id === vm.summaryFormId) :
        null
    },
    summaryFieldInfo () {
      const vm = this
      return vm.summaryFormInfo && vm.summaryFieldId !== '' ?
        vm.summaryFormInfo.fieldInfos.find(info => info.fieldId === vm.summaryFieldId) : 
        null
    },
    isPublic(){
      return this.$store.getters.isPublicRoute
    }

  }
}

export default mixin
