<template>
  <v-container>
    <vue-select ref="selectRef"
      :multiple="multiple"
      outlined
      solo
      :clearable="false"
      :options="list"
      class="w-table-selection-box"
      :value="filterInfo.table"
      :placeholder="$t('workflow.label.search')"
      @input="setSelected"
      :reduce="item => item.value"
    >
      <div slot="no-options" class="no-option">{{ $t('workflow.message.noOption.table') }}</div>
      <template slot="option" slot-scope="option">
        <div class="item">{{ option.label }}</div>
      </template>
      <template slot="select-option" slot-scope="option">
        <div class="selected">{{ option.label }}</div>
      </template>
    </vue-select>
  </v-container>
</template>

<script>

export default {
  inject: ['filterInfo'],
  props: {
    list: Array,
    onChange: Function,
    // value: String,
    taggable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSelected(v) {
      this.filterInfo.table = v;
    }
  },
  components: {
  },
};

</script>

<style>
  .w-table-selection-box {
    width: 180px;
    height: 36px;
  }

  .w-table-selection-box .item {
    border-bottom: 1px solid #ccc;
    margin: 0 -20px;
    padding: 10px;
    font-size: 13px;
  }

  .w-table-selection-box .vs__selected,
  .w-table-selection-box .no-option {
    font-size: 13px !important;
  }


  .w-table-selection-box .vs__dropdown-toggle {
    border: 1px solid #ddd !important;
    border-radius: 4px;
  }
</style>
