<template>
  <v-layout
    align-start
    justify-space-between
    class="global-panel-notification-content-row g-content-row-workflow"
  >
    <div
      class="global-panel-notification-content-row-icon"
      :style="{ background: options.iconBackgroundColor }"
    >
      <slot name="icon">
        <v-icon small color="white">{{ options.icon }}</v-icon>
      </slot>
    </div>
    <div class="global-panel-notification-content-row-content">
      <div class="g-content-row-prefix">
        <slot name="content-prefix"></slot>
      </div>
      <div>
        <slot name="content-body"></slot>
      </div>
      <div class="g-content-row-link">
        <slot name="content-link"></slot>
      </div>
      <div class="global-panel-notification-content-row-suffix">
        <slot name="content-suffix">
          <span v-if="appInfo">
            {{$t('notification.panel.fromApp')}}:
            <router-link
              :to="appParams()"
              target="_blank"
              class="g-content-row-linker"
              >{{ appInfo.name }}</router-link
            >
          </span>
        </slot>
        <slot name="content-createTime" :createTime="item.createdAt">
          <span>{{ $locale.m(item.createdAt).fromNow() }}</span>
        </slot>
      </div>
    </div>
    <div class="global-panel-notification-content-row-bookmark">
      <v-btn icon>
        <v-icon size="20" color="#1565c0" @click="bookmarkIt">
          {{ item.bookmark ? "mdi-bookmark" : "mdi-bookmark-outline" }}
        </v-icon>
      </v-btn>
    </div>
  </v-layout>
</template>
<script>
import {
  UNBOOKMARK_NOTIFICATION,
  BOOKMARK_NOTIFICATION,
} from "@/store/modules/notification/action_types";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    options: {
      type: Object,
      default: () => ({
        iconBackgroundColor: "rgb(255, 163, 64)",
        icon: "mdi-sitemap",
      }),
    },
  },
  computed: {
    appInfo() {
      return this.item.appInfo || null;
    },
  },
  methods: {
    bookmarkIt() {
      const { bookmark, _id } = this.item;
      this.$store.dispatch(
        bookmark ? UNBOOKMARK_NOTIFICATION : BOOKMARK_NOTIFICATION,
        {
          notificationId: _id,
          appId: this.appInfo._id,
          index: this.index,
        }
      );
    },
    appParams() {
      return {
        name: "apps",
        params: {
          id: this.appInfo._id,
        },
      };
    },
  },
};
</script>
