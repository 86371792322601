<template>
  <v-dialog width="540" v-model="showingDialog" persistent scrollable>
    <v-card>
      <DialogHeader @close="showingDialog = false" :label="label" />
      <v-card-text style="height: 540px;" class="pt-2">
        <div>
          <v-row v-if="showAllFields" no-gutters align="center" class="mb-1">
            <v-btn-toggle
              v-model="selectAll"
              mandatory
              color="primary"
              dense
              @change="onSelectAllChanged"
            >
              <v-btn :value="false" text color="primary">
                {{ $t("view.displayFields") }}
              </v-btn>
              <v-btn :value="true" text color="primary">
                {{ $t("view.allFields") }}
              </v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row no-gutters align="center">
            <label>{{ $t("settings.dataFields") }}</label>
            <v-btn
              class="ml-2"
              text
              outlined
              color="primary"
              small
              @click="toggleAll(dataFields.map((field) => field._id))"
              >{{ $t("settings.selectAll") }}</v-btn
            >
          </v-row>
          <v-row
            no-gutters
            v-for="(row, index) in getRows(dataFields)"
            :key="'row' + index"
            class="my-0"
          >
            <v-col v-for="field in row" :key="field._id" cols="4">
              <v-checkbox
                dense
                :label="field.label"
                :value="field._id"
                class="export-dialog-field-checkbox"
                v-model="selectedFields"
              >
                <template v-slot:label>
                  <label>{{ field.label }}</label>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
        <div v-if="associateFields.length > 0">
          <v-row no-gutters align="center">
            <label>{{ $t("settings.associateFields") }}</label>
            <v-btn
              class="ml-2"
              text
              outlined
              color="primary"
              small
              @click="toggleAll(associateFields.map((field) => field._id))"
              >{{ $t("settings.selectAll") }}</v-btn
            >
          </v-row>
          <v-row
            no-gutters
            v-for="(row, index) in getRows(associateFields)"
            :key="'row' + index"
            class="my-0"
          >
            <v-col v-for="field in row" :key="field._id" cols="4">
              <v-checkbox
                dense
                :label="field.label"
                :value="field._id"
                class="export-dialog-field-checkbox"
                v-model="selectedFields"
              >
                <template v-slot:label>
                  <label>{{ field.label }}</label>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <DialogActions @onCommand="onCommand" :actionDisabled="disableButton" />
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";

export default {
  props: {
    value: Boolean,
    fields: Array,
    selectAllAtMounted: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedValue: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: function() {
        return this.$t("setting.export");
      },
    },
    showAllFields: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DialogHeader,
    DialogActions,
  },
  data() {
    return {
      selectedFields: [],
      selectAll: false,
    };
  },
  computed: {
    showingDialog: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
    visibleFields() {
      if (this.selectAll) {
        return this.fields;
      } else if (this.order.length > 0) {
        const result = []
        this.order.forEach((fieldId) => {
         const field = this.fields.find((field) => field._id === fieldId);
         if (field) result.push(field)
        });
        return result
      } else {
        return this.fields.filter((field) => field.visible);
      }
    },
    associateFields() {
      return this.visibleFields.filter((field) => field.type === "associate");
    },
    dataFields() {
      return this.visibleFields.filter((field) => field.type === "data");
    },
    orderedSelectedFields() {
      return this.dataFields
        .concat(this.associateFields)
        .map((field) => field._id)
        .filter((field) => this.selectedFields.includes(field));
    },
    disableButton() {
      return this.selectedFields.length === 0;
    },
  },
  mounted() {
    if (this.selectAllAtMounted) {
      this.selectedFields = [...this.visibleFields.map((field) => field._id)];
    } else {
      this.selectedFields = [...this.selectedValue];
    }
  },
  methods: {
    toggleAll(fieldIds) {
      const hasFalse =
        this.selectedFields.length === 0 ||
        fieldIds.some((key) => {
          return !this.selectedFields.includes(key);
        });
      if (hasFalse) {
        fieldIds.forEach((key) => {
          if (!this.selectedFields.includes(key)) this.selectedFields.push(key);
        });
      } else {
        fieldIds.forEach((key) => {
          const index = this.selectedFields.findIndex((id) => id === key);
          if (index > -1) this.selectedFields.splice(index, 1);
        });
      }
    },
    onSelectAllChanged(e) {
      if (e) {
        this.selectedFields = [...this.fields.map((field) => field._id)];
      } else {
        this.selectedFields = [...this.visibleFields.map((field) => field._id)];
      }
    },
    onCommand(payload) {
      switch (payload.command) {
        case "cancel":
          this.showingDialog = false;
          break;
        case "ok":
          if (this.disableButton) {
            return;
          }
          this.showingDialog = false;
          this.$emit("confirm", this.orderedSelectedFields);

          break;
      }
    },
    getRows(fields) {
      let result = [];
      const numOfRows = Math.ceil(fields.length / 3);
      for (let i = 0; i < numOfRows; i++) {
        result.push(fields.slice(i * 3, i * 3 + 3));
      }
      return result;
    },
  },
};
</script>
