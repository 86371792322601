<template>
  <v-dialog
    fullscreen
    v-model="showDialog"
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card class="d-flex flex-column" style="height:100%" tile>
      <!--toolbar-->
      <v-toolbar style="z-index:999;position:fixed;width:100%" dense>
        <v-btn @click="onDialogClose" small icon
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >

        <div class="d-flex align-center ml-2" v-if="isEdit">
          {{ $t("printTemplate.createTemplates") }}
          <v-text-field
            v-if="!isPrint"
            class="ml-3 mb-1"
            dense
            v-model="templateData.templateName"
            hide-details
            :disabled="loading"
            :placeholder="$t('messages.pleaseInput', [])"
          />
        </div>
        <div class="d-flex align-center ml-2" v-else>
          {{
            $t("printTemplate.previewTitle", {
              name: templateData.templateName,
            })
          }}
        </div>
        <v-spacer />
        <div v-if="isEdit" class="d-flex align-center px-2">
          <v-switch
            :label="$t('printTemplate.hideEmpty')"
            v-model="templateData.hideEmptyFields"
            dense
            hide-details
          />
          <v-tooltip bottom max-width="150px">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-on="on" v-bind="attrs" color="#dddddd"
                >mdi-alert-circle</v-icon
              >
            </template>
            {{ $t("printTemplate.hideEmptyDescription") }}
          </v-tooltip>
        </div>
        <div class="d-flex align-center" v-if="isEdit">
          {{ $t("printTemplate.fontSize") }}
          <v-select
            v-model="templateData.fontSize"
            outlined
            dense
            :menu-props="{offsetY: true, contentClass: 'denseList'}"
            hide-details
            :disabled="loading"
            :items="fontSizeOptions"
            style="max-width:100px"
            class="mx-2"
          />
        </div>
        <v-btn
          class="primary"
          small
          @click="save"
          v-if="isEdit && !isPrint"
          :disabled="loading"
        >
          {{ $t("buttons.save") }}
        </v-btn>
        <v-btn
          class="primary"
          small
          @click="print"
          v-else-if="isPrint"
          :disabled="loading"
        >
          {{ $t("buttons.print") }}
        </v-btn>
      </v-toolbar>

      <loading-icon v-if="loading" />
      <div v-else class=" d-flex pt-10 fill-height">
        <PrintTemplateSystemLeftBar
          v-if="isEdit"
          :template="templateData"
          :fieldOptions="fieldOptions"
          :signatureFields="signatureOptions"
          @commandHandler="onCommandHandler"
          :isAllSelected="isAllSelected"
          :isPrint="isPrint"
        />
        <v-container fluid class="pa-0 grey lighten-3 " style="height:100%">
          <v-sheet
            class="d-flex py-8 align-start justify-center"
            color="grey lighten-3"
            height="auto"
          >
            <!--edit print div-->
            <div v-if="isEdit" class="printContent flex clearfix pt-20">
              <Header :templateData="templateData" :record="record" />

              <v-container
                fluid
                class="tableContent mt-18 pt-0"
                :style="`font-size:${templateData.fontSize}px !important;`"
              >
                <drop-list
                  :items="templateData.layout"
                  :column="true"
                  @insert="onInsertToCol"
                  mode="cut"
                  @reorder="$event.apply(templateData.layout)"
                >
                  <drop-zone
                    style="background-color:transparent;height:50px;"
                  ></drop-zone>
                  <template v-slot:item="{ item }">
                    <drag

                      class="flex-row align-center "
                      style="display:flex"
                      :id="'row' + getRowIndex(item)"
                      :key="getRowKey(item)"
                      :data="item"
                      @cut="removeRow(item)"
                    >
                      <div class="not-visible row-holder px-3">
                        <v-icon class="justify-self-start">
                          mdi-format-line-spacing
                        </v-icon>
                      </div>

                      <v-row
                        align="center"
                        no-gutters
                        style="border-bottom:1px solid #ddd;border-top:0px;min-height:24px"
                      >
                        <field-row
                          :items="item"
                          :fieldInfos="allFieldInfos"
                          :rowIndex="getRowIndex(item)"
                          :record="record"
                          :childTableFields="templateData.childTableFields"
                          :fontSize="templateData.fontSize"
                          @commandHandler="onCommandHandler"
                        />
                      </v-row>
                    </drag>
                  </template>

                  <template v-slot:feedback>
                    <div class="feedback" key="feedback2"></div>
                  </template>
                </drop-list>
              </v-container>

              <drop-list
                :row="true"
                class="row justify-end flex-wrap mt-5 drop-list-row"
                style="flex-basis:168px"
                :items="templateData.signatures"
                @reorder="$event.apply(templateData.signatures)"
              >
                <template v-slot:item="{ item }">
                  <drag
                    v-if="record[item]"
                    :key="item"
                    :data="item"
                    class="pa-4 mx-2 drag-item justify-end"
                  >
                    <v-hover class="justify-end">
                      <template v-slot:default="{ hover }">
                        <div class="signature-box">
                          <div
                            class="font-weight-bold"
                            :class="hover ? `row` : ``"
                          >
                            {{ getLabel(item) }}

                            <v-spacer />
                            <v-btn
                              v-if="hover"
                              color="error"
                              x-small
                              icon
                              @click="removeSignature(item)"
                              ><v-icon x-small>mdi-delete</v-icon></v-btn
                            >
                          </div>
                          <img
                            v-if="record[item][0]"
                            class="signature-image"
                            :src="record[item][0]['thumbnailUrl']"
                          />
                        </div>
                      </template>
                    </v-hover>
                  </drag>
                </template>
              </drop-list>
              <div class="mt-3" v-if="templateData.showPrintTime">
                <span style="font-size:12px;float:right">
                  {{ $t("printTemplate.printTime", { time: printTime }) }}
                </span>
              </div>
            </div>
            <!--real print div-->
            <print-layout
              v-if="templateData.layout"
              :class="isEdit ? 'd-none' : ''"
              :templateData="templateData"
              :record="record"


              :printTime="printTime"
              :allFieldInfos="allFieldInfos"
            />
          </v-sheet>
        </v-container>
      </div>
    </v-card>
    <export-dialog
      v-if="showExportDialog"
      v-model="showExportDialog"
      :fields="selectedChildTableFieldInfos"
      :label="$t('widgets.childTable')"
      :selectAllAtMounted="false"

      :selectedValue="visibleChildTableFields"
      @confirm="
        templateData.childTableFields[selectedChildTable].fields = $event
      "
    />
  </v-dialog>
</template>

<script>
import PrintTemplateSystemLeftBar from "./PrintTemplateSystemLeftBar";
import FieldRow from "./FieldRow";
import ExportDialog from "@/components/dialogs/ExportDialog";
import PrintLayout from "./printLayout/PrintLayout";
import Header from "./printLayout/Header";

import { systemFieldIds } from "@/const/fieldConstants";
import { formatDateTime } from "@/helpers/DataHelpers";
import { Drag,  DropList } from "vue-easy-dnd";
import loadingIcon from "@/components/global/loadingIcon";
import ValidationsHelper from "@/helpers/ValidationsHelper";


export default {
  props: {
    value: Boolean,
    templateId: String,
    recordId: String,
    isEdit: Boolean,
    isPrint: Boolean,
    viewId: {
      type: String,
      default: "",
    },
  },
  components: {
    PrintTemplateSystemLeftBar,
    Drag,
    DropList,
    FieldRow,
    loadingIcon,
    ExportDialog,
    PrintLayout,
    Header,
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    form() {
      return this.$store.getters.currentForm;
    },
    allFieldInfos() {
      const fieldInfos = this.form.fieldInfos.concat(this.form.uiElementInfos)
      return fieldInfos;
    },
    signatureFields() {
      return this.allFieldInfos.filter((info) => info.type === "signature");
    },
    childTableFields() {
      return this.$store.getters.currentForm.fieldInfos
        .filter((info) => info.type === "childTable")
        .map((info) => info.fieldId);
    },
    signatureOptions() {
      return this.templateData.signatures && this.signatureFields.filter(
        (info) => !this.templateData.signatures.includes(info.fieldId)
      ) || [];
    },
    fieldOptions() {
      if (!this.templateData.layout) return [];
      else {
        const displayedFieldIds = this.templateData.layout
          .flat(2)
          .map((field) => field._id);
        const excludedFieldIds = this.signatureFields
          .map((info) => info._id)
          .concat(displayedFieldIds)
          .concat(systemFieldIds);

        const filteredField = this.allFieldInfos.filter(
          (info) => !excludedFieldIds.includes(info.fieldId)
        );

        return filteredField.map((field) => ({
          _id: field._id,
          controlType: field.type.startsWith("_") ? "static" : "data",
          dataType: field.type,
          label: field.label,
          width: 12,
        }));
      }
    },
    fieldInfos() {
      return this.$store.getters.currentForm.fieldInfos;
    },
    printTime() {
      return formatDateTime(new Date());
    },
    isAllSelected() {
      return (
        this.fieldOptions.length + this.signatureOptions.length === 0 &&
        !Object.keys(this.templateData).some(
          (key) =>
            typeof this.templateData[key] === "boolean" &&
            key !== "hideEmptyFields" &&
            this.templateData[key] === false
        )
      );
    },
    selectedChildTableFieldInfos() {
      const childTableFieldInfo = this.fieldInfos.find(
        (info) =>
          info._id === this.selectedChildTable && info.type === "childTable"
      );
      const fieldInfos =  (childTableFieldInfo &&
          childTableFieldInfo.properties.fieldsEditor.fieldInfos) || []
      let result = [];

      fieldInfos.forEach((fieldInfo) => {
          result.push({
            _id: fieldInfo.fieldId,
            label: fieldInfo.label,
            type: 'data',
            visible:true
          });
      });
      result.unshift({ _id: "sequence", label: this.$t("printTemplate.sequence"), type:'data', visible:true })
      return result;
    },
    visibleChildTableFields() {
      return (
        (this.templateData &&
          this.templateData.childTableFields[this.selectedChildTable] &&
          this.templateData.childTableFields[this.selectedChildTable].fields) ||
        []
      );
    },
  },
  data() {
    return {
      templateData: {},
      record: {},

      fontSizeOptions: [12, 16, 18],

      showExportDialog: false,
      selectedChildTable: "",
      loading: false,
    };
  },
  mounted() {
    this.getTemplate(this.templateId, this.recordId);
  },
  methods: {
    checkRowEmpty(row) {
      const result = !row.some((field) => {
        if (
          typeof this.record[field._id] === "object" &&
          this.record[field._id].hasOwnProperty("regionText")
        )
          return this.record[field._id].regionText;
        else
          return (
            this.childTableFields.includes(field._id) ||
            field.controlType === "static" ||
            !ValidationsHelper.isEmpty(this.record[field._id])
          );
      });

      return result;
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "setWidth":
          this.setWidth(payload.fieldId, payload.rowIndex, payload.width);
          break;
        case "removeItem":
          this.removeItem(payload.item, payload.rowIndex, payload.colIndex);
          break;
        case "appendToLayout":
          this.appendToLayout(payload.field);
          break;
        case "addSignature":
          this.addSignature(payload.field);
          break;
        case "selectAll":
          this.selectAll();
          break;
        case "removeAll":
          this.removeAll();
          break;
        case "editChildTable":
          this.selectedChildTable = payload.fieldId;
          this.showExportDialog = true;
      }
    },
    removeAll() {
      Object.keys(this.templateData).forEach((key) => {
        if (
          typeof this.templateData[key] === "boolean" &&
          key !== "hideEmptyFields"
        ) {
          this.templateData[key] = false;
        }
      });
      this.templateData.layout = [];
      this.templateData.signatures = [];
    },
    selectAll() {
      Object.keys(this.templateData).forEach((key) => {
        if (
          typeof this.templateData[key] === "boolean" &&
          key !== "hideEmptyFields"
        ) {
          this.templateData[key] = true;
        }
      });
      this.fieldOptions.forEach((option) => {
        this.appendToLayout(option);
      });

      this.signatureOptions.forEach((option) => {
        this.addSignature(option.fieldId);
      });
    },
    addSignature(fieldId) {
      this.templateData.signatures.push(fieldId);
    },
    appendToLayout({ _id, controlType }) {
      let item = [
        {
          _id,
          controlType,
          width: 12,
        },
      ];
      this.templateData.layout.push(item);
    },
    removeItem(item, rowIndex, colIndex) {
      this.templateData.layout[rowIndex].splice(colIndex, 1);
      if (this.templateData.layout[rowIndex].length === 0)
        this.templateData.layout.splice(rowIndex, 1);
    },
    onInsertToCol(event) {
      this.templateData.layout.splice(event.index, 0, [
        {
          _id: event.data._id,
          controlType: event.data.controlType,
          width: 12,
        },
      ]);
    },
    setWidth(fieldId, rowIndex, width) {
      let row = this.templateData.layout[rowIndex];
      let field = row.find((item) => item._id === fieldId);
      if (field) {
        field.width = width;
      }
    },

    print() {
      const contents = document.getElementById("section-to-print").outerHTML;

      let styles = "";

      for (const node of [...document.querySelectorAll("link[rel='stylesheet']")]) {
        styles += node.outerHTML;
      }

      const frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.srcdoc = "<html><head>" +
          styles +
          "</head><body>".concat(contents, "</body>") +
          "</html>"
      document.body.appendChild(frame1);
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
      setTimeout(function() {
        document.body.removeChild(frame1);
      }, 1000);
      return false;
    },
    getRowIndex(item) {
      const vm = this;
      // var result = 0
      // if ()
      return vm.templateData.layout.indexOf(item);
    },
    getRowKey(row) {
      const index = this.templateData.layout.indexOf(row);
      let result = `row_${index}`;
      if (this.templateData.layout[index].length > 0) {
        result = `row_${this.templateData.layout[index][0]._id}`;
      }
      return result;
    },
    getFieldLabel(fieldId) {
      const info = this.fieldInfos.find((info) => info.fieldId === fieldId);
      return (info && info.label) || "";
    },
    onDialogClose() {
      this.showDialog = false;
    },
    getLabel(fieldId) {
      const fieldInfo = this.allFieldInfos.find(
        (info) => info.fieldId === fieldId
      );
      return (fieldInfo && fieldInfo.label) || "";
    },
    removeSignature(fieldId) {
      const index = this.templateData.signatures.findIndex(
        (id) => id === fieldId
      );
      if (index > -1) this.templateData.signatures.splice(index, 1);
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getTemplate(templateId, recordId) {
      this.loading = true;
      const postData = {
        urlCommand: "/printTemplate/getTemplate",
        data: {
          formId: this.form._id,
          appId: this.form.appId,
          templateId: templateId || "",
          recordId: recordId || "",
          viewId: this.viewId || "",
        },
      };

      this.$store
        .dispatch("AUTH_POST", postData)
        .then((response) => {
          response.template.childTableFields = this.checkChildTableFields(
            response.template.childTableFields
          );
          this.templateData = response.template;
          if (this.templateData && this.templateData.templateLogoMedia ) {
            this.templateData.logoUrl = this.templateData.templateLogoMedia.imageUrl
          }
          this.record = response.record;

        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkChildTableFields(childTableFields) {
      if (!childTableFields) childTableFields = {};
      const childTable = this.fieldInfos.filter(
        (info) => info.type === "childTable"
      );
      childTable.forEach((info) => {
        if (!childTableFields[info._id]) {
          let fieldIds =
            info &&
            info.properties.fieldsEditor.fieldInfos.map((info) => info._id);

          fieldIds.unshift("sequence");
          Object.assign(childTableFields, {
            [info.fieldId]: {
              type: "table",
              fields: fieldIds,
            },
          });
        }
      });
      return childTableFields;
    },
    save() {
      if (!this.templateData.templateName) {
        this.$toast.error("title cannot be empty");
        return;
      }
      this.loading = true;
      const postData = {
        urlCommand: "/printTemplate/save",
        data: {
          template: this.templateData,
        },
      };

      this.$store
        .dispatch("AUTH_POST", postData)
        .then((res) => {
          this.$emit("update", res);
        })
        .catch((err) => {
          console.error(err);
        }).finally(()=> {
          this.loading = false;
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.templateData.formName,
              func_name: this.$sensors.events.plus_sheet_mani.print_template[`${this.templateId? 'UPDATE':'CREATE'}_PRINT_TEMPLATE_NORMAL`],
            }
          });
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_table_setup_menu",
            data: {
              function_button: this.$sensors.events.plus_sheet_mani.print_template[`${this.templateId? 'UPDATE':'CREATE'}_PRINT_TEMPLATE_NORMAL`],
            }
          });
        });
    },
  },
};
</script>

<style scoped>
.feedback {
  flex: 0 0 0;
  margin: 0 1px;
  outline: 5px solid blue;
  align-self: stretch;
}

.drop-list-col .row {
  border: none;
}
</style>
<style lang="scss" src="@/assets/scss/printTemplate.scss" >
</style>
