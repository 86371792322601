<template>
  <loading-icon v-if="loading" />
  <div v-else>
    <form-title
      class="mx-2"
      :title="employee.workInfo.displayName || $t('general.name')"
      :buttons="['back']"
      @onCommand="onCommandHandler"
    >
    </form-title>
    <v-container v-for="section in sections" :key="section.key">
      <v-form
        v-model="valid[section.key]"
        class="employee-form"
        :ref="section.key"
      >
        <v-card-title class="employee-section-title"
          ><v-toolbar elevation="0" dense
            ><span>{{ section.label }}</span
            ><v-btn
              v-if="!isEditing"
              icon
              color="primary"
              text
              @click="enableEdit(section.key)"
              ><v-icon>mdi-pencil-outline</v-icon></v-btn
            ></v-toolbar
          ></v-card-title
        >
        <v-row class="py-4" no-gutters>
          <v-col
            v-for="field in section.fields"
            :key="field.key"
            class="pa-2"
            cols="12"
            sm="6"
            ><input-widget-container v-bind="field" required>
              <component
                :is="field.type"
                :ref="field.key"
                v-model="employee[section.key][field.key]"
                dense
                single-line
                :outlined="!section.readonly"
                background-color="white"
                :placeholder="
                  section.readonly ? '' : $t('messages.pleaseInput', [])
                "
                required
                :readonly="section.readonly"
                :disabled="section.readonly"
                @newDepartment="showingDeptDialog = true"
                v-bind="field"
                v-on="field.on"
              />
            </input-widget-container>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="!section.readonly"
          align="center"
          justify="center"
        >
          <v-btn
            color="error"
            @click="cancel(section.key)"
            class="mx-2"
            depressed
            >{{ $t("buttons.cancel") }}</v-btn
          >
          <v-btn color="primary" @click="submit(section.key)" depressed>{{
            $t("buttons.submit")
          }}</v-btn>
        </v-row>
      </v-form>
    </v-container>
    <department-dialog
      v-if="showingDeptDialog"
      v-model="showingDeptDialog"
      :items="departments"
      :flattenItems="departmentList"
      @save="addDept"
    />
    <job-title-dialog
      v-if="showingJobTitleDialog"
      v-model="showingJobTitleDialog"
      @save="addJobTitle"
    />

  </div>
</template>
<script>
import InputWidgetContainer from "@/components/inputWidgets/comps/InputWidgetContainer";
import FormTitle from "@/components/FormTitle";
import DepartmentDialog from "./components/formInput/departmentDialog";
import JobTitleDialog from "./components/formInput/jobTitleDialog";
import LoadingIcon from "@/components/global/loadingIcon";

import mixin from "./editMixin";

export default {
  name: "workAdminEditEmployee",
  mixins: [mixin],
  components: {
    InputWidgetContainer,
    FormTitle,
    DepartmentDialog,
    JobTitleDialog,
    LoadingIcon,
  },
  mounted() {
    this.fetchDepartments();
    this.fetchJobTitles();
    this.fetchEmployee();
  },
  data() {
    return {
      commandMap: {
        back: this.back,
      },

      apiMap: {
        workInfo: "work_info",
        personalInfo: "personal_info",
        contactInfo: "contact_info",
      },

      readonly: {
        workInfo: true,
        personalInfo: true,
        contactInfo: true,
      },
      valid: {
        workInfo: false,
        personalInfo: false,
        contactInfo: false,
      },
      employee: {
        workInfo: { departmentIds: [] },
        personalInfo: {},
        contactInfo: {},
        active: false,
      },

      initialData: {},
      loading: false,
    };
  },
  computed: {
    sections() {
      return [
        {
          label: this.$t("workAdmin.workInfo"),
          key: "workInfo",
          readonly: this.readonly["workInfo"],
          fields: [
            this.attributes.get("displayName"),
            this.attributes.get("employeeNumber"),
            this.attributes.get("joinedDate"),
            {
              ...this.attributes.get("departmentIds"),
              hasAdd: !this.readonly["workInfo"],
            },
            {
              ...this.attributes.get("departmentId"),
              items: this.departmentList.filter((dept) =>
                (this.employee.workInfo.departmentIds || []).includes(dept.id)
              ),
            },
            {
              ...this.attributes.get("jobTitleId"),
              hasAdd: !this.readonly["workInfo"],
            },
          ],
        },
        {
          label: this.$t("workAdmin.personalInfo"),
          key: "personalInfo",
          readonly: this.readonly["personalInfo"],
          fields: [
            this.attributes.get("lastName"),
            this.attributes.get("firstName"),
            this.attributes.get("birth"),
            this.attributes.get("gender"),
          ],
        },
        {
          label: this.$t("workAdmin.contactInfo"),
          key: "contactInfo",
          readonly: this.readonly["contactInfo"],
          fields: [this.attributes.get("email"), this.attributes.get("mobile")],
        },
      ];
    },
    employeeId() {
      return this.$route.params.id;
    },
    isEditing() {
      return Object.values(this.readonly).some((status) => !status);
    },
  },
  methods: {
    onCommandHandler({ command, ...payload }) {
      return this.commandMap[command]
        ? this.commandMap[command](payload)
        : null;
    },
    fetchEmployee() {
      this.loading = true;
      const payload = {
        url: `/work/employees/${this.employeeId}`,
        method: "get",
      };
      this.$store
        .dispatch("YOOV_REQUEST", payload)
        .then((res) => {
          if (res.data) {
            const {
              employeeWorkInfo,
              employeePersonalInfo,
              employeeContactInfo,
              department,
              departments,
              jobTitleId,
              bindingUser,
            } = res.data;
            this.employee.workInfo = {
              ...employeeWorkInfo,
              departmentId: department.id,
              departmentIds: departments.map((dept) => dept.id),
              jobTitleId,
            };
            this.employee.personalInfo = employeePersonalInfo;
            this.employee.contactInfo = {
              ...employeeContactInfo,
              mobile: this.combinePhoneNumber(
                employeeContactInfo.mobilePrefix,
                employeeContactInfo.mobile
              ),
            };
            this.employee.bindingUser = bindingUser;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    combinePhoneNumber(mobilePrefix, mobile) {
      return mobile ? `+${mobilePrefix}${mobile}` : "";
    },

    enableEdit(section) {
      this.readonly[section] = false;
      this.initialData = {
        ...this.employee[section],
      };
    },
    cancel(section) {
      this.employee[section] = { ...this.initialData };
      this.readonly[section] = true;
      this.resetEmail();
      this.resetPhone()
    },
    /**
     * @todo Fix async validation. Vuetify native validation does not support async function. Recommend handling validation with 3rd party library
     */
    async submit(section) {
      let refresh = false;
      //trigger vuetify native validation
      this.$refs[section][0].validate();
      
      //vuetify native validation does not support async; force checking before submit
      if (section === "contactInfo") {
        await this.checkExistEmail(this.employee["contactInfo"]["email"])
      }
      
      if (this.valid[section]) {
        let data = {
          ...this.employee[section],
          employeeId: this.employeeId.toString(),
          emailFlag: "PERSON",
        };
        if (section === "contactInfo") {
          data = {
            ...data,
            ...this.extractPhoneInfo(this.employee[section].mobile),
          };
          refresh = true;
        }
        const payload = {
          url: `/work/employees/${this.employeeId}/${this.apiMap[section]}`,
          method: "put",
          data,
        };

        this.$store
          .dispatch("YOOV_REQUEST", payload)
          .then(() => {
            if (refresh) {
              this.fetchEmployee();
            }
            this.readonly[section] = true;
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.employee-section-title {
  border-left: 3px solid #5fc5ce;
  padding: 0px 10px;
}
</style>
<style lang="scss">
.employee-form {
  .v-input--is-readonly .v-input__control > .v-input__slot {
    background-color: white !important;
  }
  .v-input--is-disabled input {
    color: rgba(0, 0, 0, 0.87);
  }
  .v-select .v-select__selection--disabled {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
