<template>
  <vxe-table-column v-bind="colDef" :edit-render="{name: 'input', autoselect: true}" class-name="fullCell vxeCellIdCard">
    <template v-slot:edit="{row}">
      <vxe-input type="text"
        v-model="row[fieldId]"
        style="height:100%"
      />
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>

  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import dialogButtonSignature from '@/components/dialogButtons/DialogButtonSignature'
import summaryCell from './comps/SummaryCell'

export default {
  mixins: [mixin],
  components : {
    dialogButtonSignature,
    summaryCell
  },
}
</script>

<style>
  .vxeCellIdCard .vxe-input input{
    padding: 8px !important;
  }
</style>