<template>
  <v-tooltip :disabled="!button.remark" bottom>
    <template v-slot:activator="{ attr, on }">
      <v-btn
        v-bind="{ ...attr, ...buttonProps }"
        v-on="on"
        class="workflow-customized-action-button-label"
        :disabled="processing"
        @click="onClick"
      >
        <v-layout align-center>
          <v-icon
            v-if="button.icon"
            :color="buttonProps.iconColor || button.color"
            left
            :size="buttonProps.iconSize || 17"
            dark
          >
            {{`mdi-${button.icon}`}}
          </v-icon>
          <div>
              <div :style="{ color: buttonProps.iconColor || button.color }">
                {{button.name}}
              </div>
              <!-- <div v-if="button.remark" class="workflow-customized-action-button-remark">
                {{button.remark}}
              </div> -->
          </div>
        </v-layout>
      </v-btn>
    </template>
    <div class="workflow-customized-action-button-remark">
      {{button.remark}}
    </div>
  </v-tooltip>
</template>

<script>
import Mixin from './mixin';
export default {
  mixins: [Mixin],
  props: {
    click: Function,
    selected: {
      type: Array,
      default: [],
    },
    button: {
      type: Object,
      default: {},
    },
    buttonProps: {
      type: Object,
      default: {
      },
    }
  },
  methods: {
    onClick() {
      if (this.click) {
        this.click();
      } else {
        this.onSubmit(this.selected);
        this.$emit('onSelected');
      }
    },
  },
}
</script>

<style scoped>

</style>