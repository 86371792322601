<template>
  <div>
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.operation.label.quation')"
      >
        <div>
          <div
            class="workflow-setting-form-waiting-set-remark"
            v-html="$t('workflow.modules.operation.remark.quation')"
          />
          <FreeInput
            :value="expression"
            :fields="fields"
            fieldType="number"
            noNewLine
            mathSupport
            @itemsChanged="itemsChanged"
            @valueChanged="valueChanged"
          />
        </div>
      </FormElement>
      <div class="workflow-setting-form-numeric">
        <v-layout align-center justify-start>
          <span v-html="$t('workflow.modules.operation.label.resultRemainPoint')" />
          <VueNumberInput
            v-model="unit"
            class="workflow-setting-form-numeric-input"
            :min="0"
          ></VueNumberInput>
          <span v-html="$t('workflow.modules.operation.label.floatingPoint')" />
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumberInput from '@smartweb/vue-number-input';
import ComboBox from './../../../form/comboBox/';
import FormElement from './../shared/formElement';
import FreeInput from './../shared/freeInput';
import Mixin from '../mixin';
import SettingMixin from './mixin';
import SettingUtils from '@/pages/admin/workflow/utils/setting';
import {
  FETCH_WORKFLOW_DEPENDENCIES,
} from '@/store/modules/workflow/action_types';

export default {
  mixins: [SettingMixin, Mixin],
  props: {
    unit: Number,
    expression: String,
    fields: Array,
  },
  data() {
    return {
      unit: 1,
    };
  },
  components: {
    ComboBox,
    FormElement,
    FreeInput,
    VueNumberInput,
  },
  mounted() {
    this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
      workflow: this.workflow._id,
      node: this.node._id,
      isBranch: true,
      noFieldFound: true,
      appId: this.appId,
    });
  },
  watch: {
    expression: {
      handler(v) {
        const fields = SettingUtils.normalizeFormulaFields(this.fields, this.expression);
        this.changed('fields', fields);
      },
      deep: true
    },
    unit: {
      handler(v) {
        this.changed('unit', parseInt(v));
      },
      deep: true
    },
  },
};

</script>

<style scoped>

</style>
