<template>
  <div class="pa-0 d-flex flex-row align-center"
       :class="getFieldClass">
    <label v-if="useLabel"
           class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    <div v-if="textOnly">{{ titleValue }}</div>
    <div v-else>
      <v-chip x-small v-for="(option, index) in userOptions" :key="index"
              :style="getOptionStyle(option)"
        class="option-label-chip">
        {{ option.label }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
// import {getFieldPropertyValue} from "../../helpers/ObjectHelpers";

export default {
  name: 'fsMultipleSelection',
  mixins: [mixin],
  computed: {
    userOptions () {
      const vm = this
      var result = []
      const options = vm.getPropertyOptions('inputOptionsMultiple')
      for (let i = 0; i < options.length; i++) {
        const loopOption = options[i]
        if (vm.dataValue.includes(loopOption.id)) {
          result.push(loopOption)
        }
      }
      return result
    },
    titleValue () {
      const vm = this
      var result = vm.UNSPECIFIED
      const options = vm.getPropertyOptions('inputOptionsMultiple')
      if (vm.dataValue && options.length > 0) {
        if (vm.dataValue.length > 0) {
          const selection = []
          for (let i = 0; i < options.length; i++) {
            const loopOption = options[i]
            if (vm.dataValue.includes(loopOption.id)) {
              selection.push(loopOption.label)
            }
          }
          if (selection.length > 0) {
            result = selection.join(',')
          }
        }
      }
      return result
    }
  },
  methods: {
    getFieldClass () {
      const vm = this
      var result = ''
      if (vm.fieldInfo.type === 'multipleSelection') {

      } else {
        result = 'overflow-hidden text-no-wrap'
      }
      return result
    },
    getOptionStyle (option) {
      const vm = this
      var result = {}
      const useColor = vm.getPropertyUseColor('inputOptionsMultiple')
      if (useColor) {
        const colorSetPair = option.colorSet.split('/')
        result = {
          color: colorSetPair[0],
          backgroundColor: colorSetPair[1]
        }
      }
      return result
    }
  }
}
</script>

<style>
  .option-label-chip{
    margin-right: 1px;
    margin-bottom: 1px;
  }
</style>
