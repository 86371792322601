<template>
<!-- <div class="fs-attachment-item card-value ma-0 position-relative pa-0 cursor-pointer" @click="preview(media.mediaId)"> -->
<div class="fs-attachment-item card-value ma-0 position-relative pa-0 cursor-pointer fill-height "
  :class="recordMode ? 'record-mode mb-1' : 'py-1'">
  <div v-if="!media"
    class="d-inline-block" style="width:36px;height:36px;background-color:rgba(200,200,200,.8)">
  </div>
  <img v-else-if="isImage"
    class="attachment-image"
    :class="imageSizeClass"
      :src="media.thumbnailUrl"/>
  <div v-else class="media-type-icon">
    <media-type-icon
      :media="media"
      :size="recordMode ? 100 : 35"></media-type-icon>
  </div>
  <!-- <v-icon v-else-if="mediaInfo" :color="mediaInfo.color" size="35">{{ mediaInfo.icon }}</v-icon>
  <v-icon v-else color="grey" size="35">mdi-file</v-icon> -->
  <v-btn v-if="isImage && canPreview && !recordMode"
    fab
    x-small
    color="error"
    class="position-absolute d-none white--text preview-button"
    @click="preview(media)">
    <v-icon size="" small>mdi-magnify</v-icon>
  </v-btn>
  <v-btn v-else-if="canPreview && !recordMode"
    fab
    x-small
    color="error"
    class="position-absolute d-none white--text preview-button"
    @click="preview(media)">
    <v-icon size="" small>mdi-download</v-icon>
  </v-btn>
  <div v-if="recordMode" class="attachment-mask align-start flex-column"
    @click="preview(media)">
    <div class="pa-1 file-name mb-auto" style="width:100%;">{{media.originalName}}</div>
  </div>
</div>
</template>

<script>
import mediaHelperMixin from '@/mixins/mediaHelperMixin'
import MediaHelper from '@/helpers/MediaHelper'
import mediaTypeIcon from '@/components/MediaTypeIcon';

export default {
  name: 'fsAttachmentItem',
  mixins: [mediaHelperMixin],
  components: {
    mediaTypeIcon
  },
  data () {
    return {
      imageSrc: ''
    }
  },
  props: {
    media: Object,
    inline: Boolean,
    canPreview: {
      type: Boolean,
      default:true
    },
    recordMode: {
      type: Boolean,
      default: false
    },
    imageSizeInTableView: {
      type: String,
      default: 'image-regular'
    }
  },
  watch: {
    media: function(val) {

    }
  },
  mounted () {
    // this.loadImageSrc()
  },
  computed: {
    isImage () {
      return MediaHelper.isAccessibleImage(this.media);
      // const vm = this
      // var result = false
      // if (vm.media && vm.media.mediaType.indexOf('image') !== -1) {
      //   result = true
      // }
      // return result
    },
    mediaInfo () {
      const vm = this
      const MEDIA_TYPE_ICONS = [
        {icon: 'mdi-file-word-outline', types: ['.document', 'msword'], color: 'primary'},
        {icon: 'mdi-file-excel-outline', types: ['.sheet', 'ms-excel'], color: 'success'},
        {icon: 'mdi-file-powerpoint-outline', types: ['.presentatioin', 'ms-powerful'], color: 'red'},
        {icon: 'mdi-file-document-outline', types: ['/plain'], color: 'grey'},
        {icon: 'mdi-file-pdf-outline', types: ['/pdf'], color: 'red'},
        {icon: 'mdi-folder-zip-outline', types: ['compressed'], color: 'orange darken-4'},
        {icon: 'mdi-video-outline', types: ['video/'], color: 'purple darken-2'},
        {icon: 'mdi-volume-high', types: ['audio/'], color: 'purple darken-2'},
        {icon: 'mdi-file-code-outline', types: ['xml'], color: 'grey'}
      ]
      var result = null
      MEDIA_TYPE_ICONS.every(item => {
        item.types.every(type => {
          if (vm.media.mediaType.indexOf(type) >= 0) {
            result = item
            return false
          }
          return true
        })
        return result === null
      })
      return result
    },
    imageHeightClass () {
      const vm = this
      var result = ['fs-attachment-image']
      switch (vm.imageSize) {
        case 'large':
          result.push('large-image')
          break
        case 'x-large':
          result.push('x-large-image')
          break
        case 'small': 
          result.push('small-image')
          break;
        case 'regular':
          result.push('regular-image');
          break;
      }
      return result.join(' ')
    }
  },
  methods: {
    // loadImageSrc () {
    //   if (this.media) {
    //     MediaHelper.getThumbnailUrl(this.media.objectKey).then(
    //       response => {
    //         this.imageUrl = response.url;
    //       }
    //     )
    //   }
    // },
    preview (media) {
      console.log('preview: media: ', media);
      if (media && media.objectKey) {
        MediaHelper.previewMedia(media)
      } else {
        this.$toast.error(this.$t('messages.fileIsMissing'), {duration: 0})
      }
      // MediaHelper.previewMedia(media)
    }
  }
}
</script>

<style>
.fs-attachment-item .preview-button {
  opacity: 0.8
}

.fs-attachment-item:hover .preview-button {
  display: block !important;
  top: 3px;
  left: 3px;
}

.fs-attachment-item .attachment-image {
  height:100%;
  width:auto;
  max-width:100%;
  object-fit:contain;
}

.fs-attachment-item.record-mode {
  width: 160px !important;
  height: 120px !important;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fs-attachment-item.record-mode .media-type-icon {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
}

.fs-attachment-item.record-mode .attachment-image {
  height:100%;
  width: 100%;
  max-width:100%;
  object-fit:cover;;
}
.fs-attachment-item.record-mode .attachment-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.fs-attachment-item.record-mode .attachment-mask {
  display: flex;
  background-color: transparent;
  transition: 0.5s;
  color: transparent;
}
.fs-attachment-item.record-mode:hover .attachment-mask {
  display: flex;
  background-color: rgba(50,50,50,.5); 
  color: white;
}
</style>
