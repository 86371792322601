<template>
  <div class="d-flex flex-column category-section">
    <div class="d-flex grey lighten-3 flex-row align-end">
      <div class="d-flex py-1 flex-column justify-start"
        v-for="(sectionInfo,index) in section.sectionInfos"
        :key="index">
        <h4 class="px-2 my-0 line-height-1"
          :class="{'black--text':isFormActive(sectionInfo.formId),'grey--text':!isFormActive(sectionInfo.formId)}">{{ sectionInfo.formName }}</h4>
        <v-chip small class="pa-1 ml-1 my-0" style="height:16px;" :color="isFormActive(sectionInfo.formId) ? 'primary' : 'muted'">
          {{ sectionInfo.fieldName }}
        </v-chip>
      </div>
      <v-spacer></v-spacer>
      <v-btn x-small min-height="16" min-width="24"
        class="mx-1 mb-1"
        :class="{'primary white--text':isAllSelected}"
        @click="toggleAll">
        <v-icon>mdi-check-all</v-icon>
      </v-btn>
    </div>    
    <v-list class="px-1">
      <v-list-item-group>
        <category-section-item
          v-for="option in section.options"
          :key="option._id"
          :useColor="section.useCategoryColor"
          @toggle="toggle(option._id)"
          :selected="selectedIds.includes(option._id)"
          :item="option"></category-section-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import categorySectionItem from './CategorySectionItem'

export default {
  name: 'categorySection',
  props: {
    section: Object,
    selectedIds: Array,
    selectedFormIds: Array
  },
  components: {
    categorySectionItem
  },
  data () {
    return {
      selected: -1
    }
  },
  computed: {
    isAllSelected () {
      const vm = this
      const allOptionIds = vm.section.options.map(option => option._id)
      var result = true
      allOptionIds.forEach(id => (result = result && vm.selectedIds.includes(id)))
      return result
    }
  },
  methods: {
    isFormActive (formId) {
      return this.selectedFormIds.includes(formId)
    },
    toggleAll () {
      if (this.isAllSelected) {
        this.clearAll()
      } else {
        this.setAll()
      }
    },
    clearAll () {
      this.$emit('clearAll')
    },
    setAll () {
      this.$emit('setAll')
    },


    //   for (let i = 0; i < vm.section.options.length; i++) {
    //     const loopOption = vm.section.options[i]
    //     if (vm.selectedIds.)
    //   }
    // },
    toggle (id) {
      console.log('CategorySection.toggle id = ' + id)
      this.$emit('toggle', id)
      this.selected = -1
    }
  }
}
</script>

<style>
.category-section .v-list-item {
  min-height: 24px;
}
</style>