<template>
    <v-menu
        offsetY 
        :close-on-content-click="false" 
        content-class="color-picker-menu-content"
    >
        <template v-slot:activator="{on}">
            <div
                v-on="!disabled?on:null"
                class="py-1 rounded-sm d-flex justify-center font-weight-bold align-center" 
                :style="`background-color:${value}; ${!showText?'width:20px; height:13px':''};`"
                style="cursor:pointer;"
            >
                <div v-if="showText" :style="`color: ${fontColor}`">
                    T
                </div>
            </div>
        </template>
        <v-card class="vxe-table--ignore-clear">
            <v-card-text class="pa-0" style="height:100%">
                <v-color-picker
                    :dark="fontColor=='#FFFFFF'"
                    v-model="value"
                    @input="$emit('input', $event)"
                    show-swatches
                    hide-canvas
                    hide-inputs
                    hide-sliders
                >
                </v-color-picker>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        props:{
            value:{
                type: String,
                default: '#000000'
            },
            showText:{
                type: Boolean,
                default: true
            },
            disabled: Boolean
        },
        computed:{
            fontColor(){
                var c = this.value.substring(1); 
                var rgb = parseInt(c, 16);
                var r = (rgb >> 16) & 0xff;
                var g = (rgb >>  8) & 0xff;
                var b = (rgb >>  0) & 0xff;
            
                var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                if (luma < 150) {
                    return "#FFFFFF"
                }else{
                    return "#000000"   
                }
            }
        },
    }
</script>


<style>
    .color-picker-menu-content .theme--dark.v-sheet{
        background-color: white !important;
    }
</style>