<template>
<div class="d-flex flex-column align-start menu-filter-criteria-field">
  <div class="d-flex flex-row justify-start align-center mb-1">
    <v-menu offset-y
      color="white"
      max-height="90%"
      open-on-click>
      <template v-slot:activator="{ on, attrs }">
            <v-btn dark x-small fab color="primary" v-on="on" class="elevation-0">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
      </template>
      <v-list class="menu-sm">
        <v-list-item
            @click="onListItemClicked(item.fieldId)"
            v-for="(item, index) in fieldLabelAndIds"
            :key="index">
              <v-list-item-title class="text-body1">{{ item.fieldLabel }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</div>
</template>

<script>
import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
import appsFormsMixin from '@/mixins/appsFormsMixin'

export default {
  mixins: [appsFormsMixin],
  components: {inputRadioToggle},
  props: {
    fieldInfos: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    onListItemClicked (fieldId) {
      // console.log('onListItemClicked :; fieldId = ' + fieldId)
      const vm = this
      vm.$emit('addFilter', fieldId)
    }
  },
  mounted () {
    const vm = this
    if (vm.filter) {
      vm.userFilter = vm.filter
    }
  },
  computed: {
    // form () {
    //   return this.$store.getters.currentForm
    // },


    fieldLabelAndIds () {
      const vm = this
      var result = []
      const IGNORED_TYPE = ['location', 'region', 'expression','richText', 'parentChildRelation', 'childTable', 'otherTableField']
      const filteredInfos = this.fieldInfos.filter(info => !IGNORED_TYPE.includes(info.type))
      result = filteredInfos.map(info => ({
        fieldLabel: info.properties['fieldNameTag'] ? vm.$t(info.properties['fieldNameTag']) : info.properties['fieldName'],
        fieldId: info.fieldId
      }))

      return result
    },
    currentAndOrOptions () {
      const vm = this
      // console.log('currentAndOrOptions :: vm.andOrOption = ' + vm.andOrOption)
      const currentOptions = vm.andOrOptions.filter(item => item.value === vm.andOrOption)
      // console.log('currentOptions: ', currentOptions)
      return currentOptions
    },
    relationOptionLabel () {
      const vm = this
      var result = ''
      const selectedOption = vm.relationOptions.find(item => item.value === vm.userFilter.relation)
      if (selectedOption) {
        result = vm.$t(selectedOption.labelTag)
      }
      return result
    },
    // adjustedAndOrOptions () {
    //   const vm = this
    //   var result = []
    //   for (var i = 0; i < vm.andOrOptions.length; i++) {
    //     const option = vm.andOrOptions[i]
    //     result.push({
    //       text: vm.$t(option.labelTag),
    //       value: option.value
    //     })
    //   }
    //   return result
    // },

    adjustedRelationOptions () {
      const vm = this
      var result = []
      for (var i = 0; i < vm.relationOptions.length; i++) {
        const option = vm.relationOptions[i]
        result.push({
          text: vm.$t(option.labelTag),
          value: option.value
        })
      }
      return result
    },

    fieldInfo () {
      return {
        fieldName: 'Field Name'
      }
    }
  },
  data () {
    return {
      callback: null
    }
  }
}
</script>

<style>
  .filter-widget-string {
    min-height: auto;
    margin-bottom: 10px;
  }
</style>
