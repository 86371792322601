import store from '@/store'
import Vue from 'vue'
import EventBus from '@/event-bus.js';

// const toFileUrl = async (url) => {
//   return url.replace('-thumbnail', '');  
// }

// const getThumbnailUrl = async (objectKey) => {
//   return new Promise((resolve, reject) => {
//     const url = store.getters.appHost + '/medias/gen-thumbnail-url'
//     const data = {objectKey}
//     Vue.axios.post(url, data)
//       .then(
//         response => {
//           resolve(response.data);
//         },
//         err => {
//           reject(err);
//         }
//       )
//   })
// }
// for stored media
// not for newly created signature or newly added media 
// const getMediaUrl = async (objectKey, mediaType) => {
//   return new Promise((resolve, reject) => {
//     getMediaUrlInfo({objectKey: objectKey}).then(
//       response => {
//         resolve(response.fileUrl);
//       }
//     ).catch(
//       err => {
//         console.log('err: ', err);
//       }
//     )
//   })
// }

const getSignedUrl = async (objectKey, options={}) => {
  return new Promise((resolve, reject) => {
    const url = store.getters.appHost + '/medias/gen-signed-url'
    const data = {
      ...options,
      objectKey
    };
    Vue.axios.post(url, data).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    )
  })
}

const getMediaUrlInfo = async (payload) => {
  console.log('getMediaUrlInfo: payload: ', payload);
  const media = payload.media;
  const isPublic = payload.isPublic;
  const isAttachment = payload.isAttachment;
  const useOriginalName = payload.useOriginalName;
  return new Promise((resolve, reject) => {
    const url =  store.getters.appHost + '/medias/gen-file-url'
    const data = {
      media,
      isAttachment,
      useOriginalName
    };
    Vue.axios.post(url, data)
      .then(
        response => {
          console.log('response.data: ', response.data);
          resolve(response.data);
        },
        err => {
          reject(err)
        }
      )
  });
}

// const getMediaAccess_old = (mediaId, isPublic) => {
//   return new Promise((resolve, reject) => {

//     if (isPublic){
//       const url = store.getters.appHost + '/medias/show/' + mediaId;
//       Vue.axios.get(url)
//       .then(
//         response => {
//           resolve(response.data.result)
//         },
//         err => {
//           reject(response)
//         }
//       )
//     }else {
//       store.dispatch('AUTH_GET', '/medias/show/' + mediaId).then(
//         response => {
//           resolve(response.result)
//         },
//         err => {
//           reject(response)
//         }
//       )
//     }

//   })
// }

// const download_old = (arg) => {
//   output(arg, 'download')
// }
// const show_old = (arg, lightboxRef) => {
//   output(arg, 'show', lightboxRef)
// }

const showDocument = (url) => {
  window.open(url, '_blank');
}

// const getMediaAccessUrl = (accessInfo, outputType) => {
//   if (typeof outputType === 'undefined') {
//     outputType = 'show'
//   }
//   var accessId = accessInfo.accessId
//   return store.getters.appHost + '/media_access/' + accessId + '/' + outputType
// }

// const output = (accessInfo, outputType, lightboxRef) => {
//   const url = getMediaAccessUrl(accessInfo, outputType)
//   if (outputType === 'show') {
//     if (accessInfo.mediaType === 'images') {
//       lightboxRef.sources = [url]
//     } else {
//       window.open(url, '_blank')
//     }
//   } else {
//     window.location = url
//   }
// }

const getTempId = () => {
  return "_" + (Math.random()*Date.now()).toString();
}

const confirmMedias = async (medias, callback) => {
  // const vm = this
  // const url = store.getters.apiUrl + '/medias/confirm';

  const postData = {
    urlCommand: '/medias/confirm',
    data: {
      medias
    }
  }
  store.dispatch('AUTH_POST', postData).then(
    response => {
      console.log('confirmMedias: response: ', response);
      if (typeof callback === 'function') {
        callback(response.result);
      }
    }
  )
}

const removeMedia = (payload, callback) => {
  const mediaId = payload.mediaId
  const objectKey = payload.objectKey

  // const url = store.getters.apiUrl + '/medias/remove';  

  // console.log('removeMedia: url = ' + url);
  // const axios = Vue.axios.create();

  const postData = {
    urlCommand: '/medias/remove',
    data: {
      mediaId,
      objectKey
    }
  };
  store.dispatch('AUTH_POST', postData).then(
    response => {
      console.log('MediaHelper.removeMedia AUTH_POST: response: ', response);

  //   }
  // )

  // axios.post(url, postData).then(
  //   response => {
      if (typeof callback === 'function') {
        callback(payload);
      }
    }
  )
  // notes:
  // if mediaId starts with '_' => temporary, remove S3 files thru YOOV+ API
  // if mediaId not starts with '_' => permanent, remove S3 files, together with media record
}

const uploadFile = (payload, callback) => {
  console.log('uploadFile: payload: ', payload);
  uploadFiles({
    appId: payload.appId,
    formId: payload.formId,
    files: [payload.file],
    useUploadBucket: payload.useUploadBucket,
    isPublic: payload.isPublic ? payload.isPublic : false
  }, result => {
    console.log('uploadFile: result: ', result);
    if (typeof callback === 'function') {
      callback(result[0]);
    }
  })
}

const createMedia = (mediaInfo, callback) => {
  const postData = {
    urlCommand: '/medias/create',
    data: {
      media: mediaInfo
    }
  };
  console.log('MediaHelper.createMedia: mediaInfo: ', mediaInfo);
  console.log('MediaHelper.createMedia: postData: ', postData)
  ;
  store.dispatch('AUTH_POST', postData).then(
    response => {
      console.log('createMedia AUTH_POST response: ', response);
      if (typeof callback === 'function') {
        const media = response.result.media;
        const imageUrl = response.result.imageUrl;
        const payload = {
          ...media, 
          imageUrl};
        callback(payload);
      }
    }
  );
}

const uploadTempFile = (payload, callback) => {
  // payload = {
  //    appId: string;
  //    formId: string;
  //    file: object;
  //    isPublic: boolean;
  //    expires: number // in seconds
  // }
  //
  // callback(result):
  //
  // result: {
  //    _id: string; // temporary media id
  //    mediaType: string;
  //    originalName: string;
  //    objectKey: string;
  //    thumbnailUrl: string;
  // }
  // 
  
  const appId = payload.appId;
  const formId = payload.formId;
  const file = payload.file;
  const isPublic = payload.isPublic;
  const expires = payload.expires;
  const useUploadBucket = true;

  const fileInfo = {
    name: encodeURIComponent(file.name),
    size: file.size,
    type: file.type,    
    expires: expires,
    useUploadBucket: useUploadBucket,
    isPublic
  };
  
  // console.log('fileInfo: ', fileInfo);
  const url = store.getters.appHost + '/medias/get-temp-upload-url';
  const postData = {
    appId,
    formId,
    fileInfo
  };
  // console.log('postData: ', postData);
  const axios = Vue.axios.create();
  axios.post(url, postData).then(
  // store.dispatch("AUTH_POST", postData).then(
    response => {
      // response = {
      //     result: true,
      //     fileInfo:{
      //         objectKey: '...',
      //         size: '...',
      //         type: '...'
      //         uploadUrl: '...',
      //         thumbnailUrl: '...'
      //     },
      // }  
      // console.log('get-upload-urls: response: ', response);
      if (response.data.result) {
        const uploadFileInfo = response.data.fileInfo;
        const url = uploadFileInfo.uploadUrl;
        const data = file;
        const options = {
          headers: {
            'accept': 'application/json',
            'Content-Type': file.type
          }
        };

        // console.log('url = ' + url);
        // console.log('Content-Type = ' + file.type);
        
        axios.put(url, data, options).then(() => {
          // var mediaIds = [];
          // var mediaTypes = [];
          // var originalNames = [];
          // var thumbnailUrls = [];
          var result = {
            _id: getTempId(),
            mediaType: file.type,
            originalName: file.name,
            objectKey: uploadFileInfo.key,
            thumbnailUrl: uploadFileInfo.thumbnailUrl
          }
          // console.log('MediaHelper.uploadTempFile :: ready to return result: ', result);

          callback(result)
          // console.log('Promise.all responses: ', responses);
        })
      } else {
        throw new Error('internal error');
      }
      // console.log('get_upload_urls ends: response: ', response);
    }, 
    err => {
      throw new Error(err.toString());
    }
  );
}
  

const uploadFiles = (payload, callback) => {
  console.log('uploadFiles: appId = ' + payload.appId);
  console.log('uploadFiles : files: ', payload.files);

  const appId = payload.appId;
  const formId = payload.formId;
  const files = payload.files;
  const isPublic = payload.isPublic;
  const useUploadBucket = (typeof payload.useUploadBucket === 'undefined') 
    ? true
    : payload.useUploadBucket;

  const fileInfos = files.map(file => {
    return {
      name: encodeURIComponent(file.name) ,
      size: file.size,
      type: file.type,
      useUploadBucket: useUploadBucket,
      isPublic
    }
  })
  const url = store.getters.appHost + '/medias/get-upload-urls';
  const postData = {
    appId,
    formId,
    fileInfos
  };
  const axios = Vue.axios.create();
  axios.post(url, postData).then(
  // store.dispatch("AUTH_POST", postData).then(
    response => {
      // response = {
      //     result: true,
      //     quota: 1000,
      //     used: 900,
      //     remains: 100,
      //     fileInfos: [
      //        {
      //            objectKey: '...',
      //            size: '...',
      //            type: '...'
      //            uploadUrl: '...',
      //            thumbnailUrl: '...'
      //        },
      //        ...
      //      ]
      // }  
      console.log('get-upload-urls: response: ', response);
      if (response.data.result) {
        const resultFileInfos = response.data.fileInfos;
        var promises = []
        for (let i = 0; i < resultFileInfos.length; i++) {
          const loopInfo = resultFileInfos[i]
          const url = loopInfo.uploadUrl;
          const data = files[i];
          console.log('resultFileInfos => axios i=' + i + ': data: ', data);
          const options = {
            headers: {
              'accept': 'application/json',
              'Content-Type': files[i].type
            }
          };

          console.log('i=' + i + ': prepare post file url = ' + url);
          console.log('i=' + i + ': prepare post Content-Type = ' + files[i].type);

          promises.push(            
            axios.put(url, data, options)
          )
        }
        Promise.all(promises).then(responses => {
          // var mediaIds = [];
          // var mediaTypes = [];
          // var originalNames = [];
          // var thumbnailUrls = [];
          var result = [];
          for (let i = 0; i < responses.length; i++ ) {
            const loopRes = responses[i];
            const loopFile = files[i];
            const loopResultFileInfo = resultFileInfos[i];
            
            console.log('i=' + i + ': loopFile: ', loopFile);
            if (loopRes.status === 200) {
              result.push({
                _id: getTempId(),
                mediaType: loopFile.type,
                originalName: loopFile.name,
                objectKey: loopResultFileInfo.key,
                thumbnailUrl: loopResultFileInfo.thumbnailUrl
              })
            }
          }
          console.log('MediaHelper.uploadFiles :: result: ', result);

          callback(result)
          // console.log('Promise.all responses: ', responses);
        })
      } else {
        callback({result: false});
      }
      console.log('get_upload_urls ends: response: ', response);
    }
  ).catch(
    err => {
      // console.log('err.response: ', err.response);
      // console.log('err.data: ', err.data);
      callback(err.response.data);
    }
  );
  // const postPayload = {
  //   appId: appId,
  //   files: files,
  //   isPublic
  // }
  // store.dispatch('UPLOAD_MEDIA', postPayload).then(medias => {
  //   if (typeof callback === 'function') {
  //     callback(medias)
  //   }
  // })
}

/**
 * Download media
* @param {Object} context Required
* @param {Object} media Required. {mediaId, mediaType, objectKey}
* @param {Object} option Otional. {isPublic, isAttachment, useOriginalName} 
*/
const downloadMedia = (media, options) => {
  console.log('MediaHelper.downloadMedia options: ', options);
  getMediaUrlInfo({
    media,
    isPublic: options && !!options.isPublic,
    isAttachment: true,
    useOriginalName: options && !!options.useOriginalName
  })
  .then(
    urlInfo => {
      showDocument(urlInfo.fileUrl);
    })
  .catch(
    err => {
      console.log('err: ', err);
    }
  )
}

const isAccessibleImage = (media) => {
  const ACCESSIBLE_IMAGE_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif'
  ];
  return ACCESSIBLE_IMAGE_TYPES.includes(media.mediaType);
}
/**
 * Preview media

 * @param {Object} media Required. {mediaId, mediaType, objectKey}
 * @param {Boolean} isPublic Optional. Is public link required
 */
const previewMedia = (media, options) => {
  console.log('previewMedia  options: ', options);
  EventBus.$emit('onLoading');
  getMediaUrlInfo({
    media,
    isPublic: options && !!options.isPublic,
    isAttachment: false
  })
  .then(
    urlInfo => {
      // urlInfo: {
      //    isPublic: boolean,
      //    fileUrl: string
      // }      
      // console.log('urlInfo: ', urlInfo);
      // const isImage = media.mediaType.startsWith('image/');
      // console.log('preview isImage = ' + (isImage ? 'yes' : 'no'));
      EventBus.$emit('offLoading');
      if (isAccessibleImage(media)) {
        // console.log('preview : fileUrl = ' + urlInfo.fileUrl);
        store.dispatch("SET_IMAGES_URLS", [urlInfo.fileUrl]);
        store.dispatch("TOGGLE_FS_LIGHTBOX");
      } else 
        showDocument(urlInfo.fileUrl);
    })
  .catch(
    err => {
      console.log('err: ', err);
    }
  )
}

// const preview_old = (context, mediaId, isPublic) => {
//   getMediaAccess(mediaId, isPublic).then(result => {
//     const accessInfo = {
//       accessId: result.accessId
//     }
//     if (result.mediaType.startsWith('image/')) {
//       const url = getMediaAccessUrl(accessInfo, 'show');
//       context.$store.dispatch('SET_IMAGES_URLS', [url]);
//       context.$store.dispatch('TOGGLE_FS_LIGHTBOX');
//     } else
//       show(accessInfo)
//   })
// }

const downloadFile = (document, buffer, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob(buffer)
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName)
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link)
}


export default {
  getSignedUrl,
  // getMediaAccess_old,
  // getMediaAccessUrl,
  // download,
//  show,
  // showDocument,
  uploadFile,
  uploadFiles,
  uploadTempFile,
  previewMedia,
  downloadMedia,
  // getThumbnailUrl,
  removeMedia,
  confirmMedias,
  // toFileUrl,
  createMedia,
  downloadFile,
  isAccessibleImage
}
