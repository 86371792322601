<template>
  <FormElement
    :label="label"
    :hints="hints"
    :subItem="true"
    :noContent="true"
  />
</template>

<script>
import FormElement from './../../shared/formElement';
export default {
  props: {
    label: String,
  },
  components: {
    FormElement,
  },
};

</script>

<style scoped>
  
</style>
