<template>
    <div v-if="ganttSetting" class="flex-grow-1 my-0 ma-0 d-flex flex-column">
        <div v-if="!ganttSetting.initialized">
            <setup-page
                :form="form"
                :ganttSetting="ganttSetting"
                @updateSetting="updateSetting"
                :view="view"
            />
        </div>
        <div 
            v-else 
            class="gantt-view flex-grow-1"
            style="width:0;min-width:100%; max-height: 100%;"
        >
            <column-setting
                :form="form"
                class="ganttColumSetting"
                :ganttSetting="ganttSetting"
                @updateSetting="updateSetting"
                @doScrollToTime="doScrollToTime"
                @openSettingDrawer="openSettingDrawer"
                :searchText="searchText"
                :isMilestoneSet="isMilestoneSet"
                :isProgressSet="isProgressSet"

            />
            <gantt-chart
                ref="ganttChart"
                :datas="datas"
                :currentApp="currentApp"
                :form="form"
                :view="view"
                :showingDrawer="showingDrawer"
                :ganttSetting="ganttSetting"
                @update_datas="update_datas"
                @updateSetting="updateSetting"
                @doScrollToTime="doScrollToTime"
                :searchText="searchText"
                :isMilestoneSet="isMilestoneSet"
            />
        </div>
    </div>
</template>

<script>
import SetupPage from './SetupPage'
import ColumnSetting from './components/ColumnSetting'
import GanttChart from './components/GanttChart'
import moment from 'moment' 
    export default {
        props:{
            view: Object
        },
        components:{
            SetupPage,
            ColumnSetting,
            GanttChart
        },
        watch:{
          async 'view._id'(){
            this.initGanttSetting()
          }
        },
        data(){
            return {
                datas:[],
                // settingCompleted: false,
                ganttSetting: null,
                showingDrawer: false,
                isFullScreen: false,
                searchText: ''
            }
        },
        computed:{
            currentApp() {
                return this.$store.getters.currentApp;
            },
            form() {
                return this.$store.getters.currentForm;
            },
            cascadeFields(){
                var fields = []
                this.form.linkedFields.forEach(item=>{
                    var mappedFields = item.fieldIds.map(item2=>{
                        return this.form.fieldInfos.find(item3=>item3.fieldId==item2)
                    })
                    fields.push(mappedFields)
                })
                return fields
            },
            isMilestoneSet(){
                if(this.ganttSetting.projectRelation=='PARENT_CHILD'){
                    // parent_child
                    return this.ganttSetting.projectCascade[0].col_milestone!=null
                }else{
                    // multiple_related
                    return this.ganttSetting.projectCascade[this.ganttSetting.projectCascade.length-1].col_milestone!=null
                }
                
            },
            isProgressSet(){
                if(this.ganttSetting.projectRelation=='PARENT_CHILD'){
                    // parent_child
                    return this.ganttSetting.projectCascade[0].col_progress!=null
                }else{
                    // multiple_related
                    return this.ganttSetting.projectCascade[this.ganttSetting.projectCascade.length-1].col_progress!=null
                }
            }
        },
        mounted(){
            this.initGanttSetting()
        },
        methods:{
            // openFullscreen() {
            //     this.isFullScreen = !this.isFullScreen
            //     var elem = document.getElementsByClassName('gantt-view')[0]
            //     if(this.isFullScreen)
            //         elem.className += " gantt-view-fullscreen"
            //     else
            //         elem.className = elem.className.replace(" gantt-view-fullscreen","")
            // },
            openSettingDrawer(){
                this.showingDrawer = !this.showingDrawer
            },
            doScrollToTime(){
                var blockWrapper = document.querySelector('.gantt-blocks-wrapper')
                var centerDayOffset = Math.round(blockWrapper.getClientRects()[0].width/this.ganttSetting.cellWidth/2)*-1
                var toTime = moment().add(centerDayOffset, 'days').set('hour', 23).set('minute', 59).set('second', 59).toString()
                this.$nextTick(()=>{
                    this.$refs.ganttChart.$refs.gantt.scrollToTimehandle(toTime)
                })
               
            },
            initGanttSetting(){
                var savedSetting = this.view.ganttSetting
                if(savedSetting){
                    var obj = {
                        timeView: savedSetting.timeView, 
                        cellWidth: savedSetting.cellWidth,
                        fillBy: savedSetting.fillBy,
                        showGroupSummary: savedSetting.showGroupSummary,
                        showTodayIndication: savedSetting.showTodayIndication,
                        hoverInfos: savedSetting.hoverInfos || [],
                        customHover: savedSetting.customHover,
                        description: savedSetting.description,
                        labelBy: savedSetting.labelBy,
                        groupBy: savedSetting.groupBy,
                        initialized: savedSetting.initialized || false,
                        groupStyle: savedSetting.groupStyle || {},
                        projectCascade: savedSetting.projectCascade || []
                    }
                    this.ganttSetting = {...JSON.parse(JSON.stringify(this.view.ganttSetting)),...obj}
                }else{
                    this.ganttSetting = {
                        col_project: null,
                        col_start: null,
                        col_end: null,
                        col_milestone: null,
                        col_progress: null,
                        projectCascade: [],
                        col_color: null,
                        projectRelation: 'PARENT_CHILD',
                        timeView: 'DAY',
                        cellWidth: 80,
                        fillBy: 'TIME',
                        showGroupSummary: true,
                        showTodayIndication: true,
                        hoverInfos: [],
                        customHover: false,
                        description:'',
                        labelBy: '',
                        groupBy: '',
                        groupStyle:{},
                        initialized: false
                    }
                }
                let vm = this
                // vm.ganttSetting.initialized = false
                // console.log("this.ganttSetting", this.ganttSetting)
                // if(vm.ganttSetting)
                //     vm.ganttSetting.initialized = (vm.ganttSetting.col_project && vm.ganttSetting.col_start && vm.ganttSetting.col_end && vm.ganttSetting.col_projectCascade.length)
                if(vm.ganttSetting.initialized){
                    this.$nextTick(()=>{
                        this.$refs.ganttChart.fetchData()
                    })
                }
            },
            updateSetting(payload){
                var vm = this
                console.log('updateSetting', payload)
                switch(payload.command){
                    case 'update_col_project':
                        this.ganttSetting.projectCascade[payload.index].col_project = payload.val
                        break
                    case 'update_col_start':
                        this.ganttSetting.projectCascade[payload.index].col_start = payload.val
                        break
                    case 'update_col_end':
                        this.ganttSetting.projectCascade[payload.index].col_end = payload.val
                        break
                    case 'update_col_milestone':
                        this.ganttSetting.projectCascade[payload.index].col_milestone = payload.val
                        break
                    case 'update_col_progress':
                        this.ganttSetting.projectCascade[payload.index].col_progress = payload.val
                        break
                    case 'update_projectCascade': 
                        this.ganttSetting.projectCascade = payload.val
                        break
                    case 'update_col_color':
                        this.ganttSetting.col_color = payload.val
                        break
                    case 'update_settingCompleted':
                        this.ganttSetting.initialized = payload.val
                        this.ganttSetting.groupBy = this.ganttSetting.projectCascade
                        this.ganttSetting.labelBy = this.ganttSetting.col_project
                        this.$nextTick(()=>{
                            this.$refs.ganttChart.fetchData()
                        })
                        // vm.view.ganttSetting = vm.ganttSetting
                        // vm.$store.dispatch("UPDATE_FORM_VIEW", {
                        //     id: vm.form._id,
                        //     viewId: vm.view._id,
                        //     data: vm.view,
                        // })
                        break
                    case 'update_timeView':
                        this.ganttSetting.timeView = payload.val

                        switch (payload.val){
                            case 'DAY':
                                this.ganttSetting.cellWidth = 80
                                break
                            case 'WEEK':
                                this.ganttSetting.cellWidth = 50
                                break
                            case 'MONTH':
                                this.ganttSetting.cellWidth = 20
                                break
                        }
                        this.doScrollToTime()
                        // vm.view.ganttSetting = vm.ganttSetting
                        // vm.$store.dispatch("UPDATE_FORM_VIEW", {
                        //     id: vm.form._id,
                        //     viewId: vm.view._id,
                        //     data: vm.view,
                        // })
                    break
                    case 'update_groupBy':
                        this.ganttSetting.groupBy = payload.val
                        this.$nextTick(()=>{
                            this.$refs.ganttChart.fetchData()
                        })
                    break
                    case 'update_labelBy':
                        this.ganttSetting.labelBy = payload.val
                        this.$refs.ganttChart.updateLabelBy([payload.val])
                    break
                    case 'update_showGroupSummary':
                        this.ganttSetting.showGroupSummary = payload.val
                    break
                    case 'update_showTodayIndication':
                        this.ganttSetting.showTodayIndication = payload.val
                    break
                    case 'update_hoverInfos':
                        // console.log('payload', payload)
                        switch(payload.action){
                            case 'add':
                                this.ganttSetting.hoverInfos.push({
                                    fieldId: payload.val.fieldId,
                                    order:  this.ganttSetting.hoverInfos.length+1
                                })
                            break
                            case 'update':
                                this.ganttSetting.hoverInfos[payload.index] = {
                                    fieldId: payload.val.fieldId,
                                    order: payload.val.order
                                }
                            break
                            case 'delete':
                                this.ganttSetting.hoverInfos.splice(payload.index,1)
                            break
                            // case 'drag':
                                // var newIndex = payload.val.moved.newIndex
                                // var oldIndex = payload.val.moved.oldIndex
                                // var temp = payload.val.moved.element
                                // this.$set(this.ganttSetting, 'hoverInfos', this.ganttSetting.hoverInfos)    
                            // break                            
                        }
                        this.$refs.ganttChart.updateHoverInfos(this.ganttSetting.hoverInfos.map(item=>item.fieldId))
                    break
                    case 'update_searchText':
                        this.searchText = payload.val
                    break
                    case 'update_cellWidth':
                        this.ganttSetting.cellWidth = payload.val
                        this.doScrollToTime()
                    break
                    case 'update_fillBy':
                        this.ganttSetting.fillBy = payload.val
                    break
                    case 'update_description':
                        this.ganttSetting.description = payload.val
                    break
                    case 'update_customHover':
                        this.ganttSetting.customHover = payload.val
                    break
                    case 'update_groupStyle':
                        this.$set(this.ganttSetting.groupStyle,payload.val._id,payload.val.event)
                        // this.ganttSetting.groupStyle[payload.val._id] = payload.val.event
                    break
                }
                if(payload.command!='update_searchText'){
                    vm.view.ganttSetting = vm.ganttSetting
                    vm.$store.dispatch("UPDATE_FORM_VIEW", {
                        id: vm.form._id,
                        viewId: vm.view._id,
                        data: vm.view,
                    })
                }

            },
            updateLabelBy(){
                
            },
            update_datas(val){
                this.datas = val
            },
            handleCascadeInput(val){
                this.ganttSetting.projectCascade = val.map(item=>item.fieldId)
            },
            getLabelById(fieldId){
                if(Array.isArray(fieldId)){
                    return fieldId.map(item=>{
                        var res = this.form.fieldInfos.find(item2=>{
                            return item2.fieldId == item
                        })
                        if (res) 
                            return res.label
                        else return
                    }).join(' - ')
                }else{
                    var res = this.form.fieldInfos.find(item=>{
                        return item.fieldId == fieldId
                    })
                    if(res) return res.label
                }
            }
        }
    
    }
</script>

<style>
    .gantt-timeline-wrapper{
        height: 100% !important;
    }
    .gantt-timeline{
        height:100% !important;
        /* margin-left: 0px !important; */
    }
    .ganttColumSetting .v-input__icon .v-icon{
        font-size: 17px!important
    }
    /* .ganttColumSetting .v-select__selection--comma{
        margin: 17px 4px 7px 0 !important
    } */

    .ganttColumSetting .gantt-view .v-select__selections div{
        position:  absolute;
        bottom: 4px;
    }


    .ganttColumSetting .v-text-field > .v-input__control > .v-input__slot:before{
        border: 0px !important;
    }
    .ganttColumSetting .v-text-field .v-input__slot{
        border-radius: 0px !important;
    }
</style>
