<template>
<v-expansion-panels dense style="max-height:20px;">
    <v-expansion-panel
        v-for="(item, index) in userParentChildInfos"
        :key="index">
        <v-expansion-panel-header class="py-2">
            <parent-child-settings-title
            :levelType="view.levelType"
            :levelNo="index+1"
            :outlined="index>0"
            :deletable="index>0"
            @onDelete="onDeleteHandler"
            :item="item"></parent-child-settings-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <relation-card-config
                :cardConfig="item.cardConfig"
                @input="val=>updateCardConfig(index,val)"
                :formInfo="getFormInfo(index)"></relation-card-config>
        </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel style="border:none;" v-if="view.levelType==='multipleTables'">
        <div class="pa-3">
            <parent-child-selection-menu
                :levelType="view.levelType"
                :relationFieldItems="childRelationFieldItems"
                @onSelect="selectItem">    
            </parent-child-selection-menu>
        </div>
    </v-expansion-panel>
</v-expansion-panels>
</template>

<script>
import parentChildFieldFormMixin from '@/mixins/parentChildFieldFormMixin'

import parentChildSettingsTitle from './ParentChildSettingsTitle'
import relationCardConfig from './RelationCardConfig'
import parentChildSelectionMenu from './ParentChildSelectionMenu'
import {LEVEL_DIAGRAM_CARD_CONFIG} from '@/const/defaults'

export default {
    name: 'parentChildRelationSelection',
    mixins: [parentChildFieldFormMixin],
    components: {
        parentChildSettingsTitle,
        relationCardConfig,
        parentChildSelectionMenu
    },
    model: {
        prop: 'parentChildInfos',
        event: 'input'
    },
    props: {
        parentChildInfos: {
            // levelType === 'multipleTables':
            // parentChildInfo => {
            //      fieldId
            //      relatedFormId
            //      cardConfig
            // }
            //
            // LevelType === 'currentTable'
            // parentChildInfo => {
            //      parentFieldId
            //      childFieldId
            //      cardConfig
            // }
            type: Array,
            default () {
                return []
            }
        },
        view: Object
    },
    computed: {
        parentChildFieldFormList () {
            return this.view.parentChildFieldFormList
        },
        levelType () {
            const vm = this
            var result = 'currentTable'
            if (vm.view) {
                result = vm.view.levelType
            }
            return result
        },
        userParentChildInfos: {
            get () {
                // parentChildInfos
                const vm = this
                var result = []
                var first = vm.parentChildInfos[0]
                switch (vm.view.levelType) {
                    case 'currentTable':
                        result.push({
                            fieldId: '',
                            fieldLabel: '',
                            relatedFormId: vm.form._id,
                            relatedFormName: vm.form.label,
                            cardConfig: first.cardConfig
                        })
                        break
                    case 'multipleTables':
                        console.log('computed(userParentChildInfosz)')
                        result = vm.relatedFieldItems.map(item => {
                            var cardConfig = JSON.parse(JSON.stringify(LEVEL_DIAGRAM_CARD_CONFIG))
                            const parentChildInfo = vm.parentChildInfos.find(info => info.fieldId === item.fieldId)
                            if (parentChildInfo && parentChildInfo.cardConfig) {
                                cardConfig = parentChildInfo.cardConfig
                            }
                            return {
                                fieldId: item.fieldId,
                                fieldLabel: item.fieldLabel,
                                relatedFormId: item.relatedFormId,
                                relatedFormName: item.relatedFormName,
                                cardConfig: cardConfig
                            }
                        })
                        break
                }
                return result
            },
            set (val) {
                alert('userParentChildInfos')
            }
        },
        form () {
            return this.$store.getters.currentForm
        },
        // for levelType === 'currentTable'
        currentTableLabel () {
            return this.form.label
        }
        // ,
        // userCardConfigs () {
        //     const vm = this

        //     // first one
        //     var result = [JSON.parse(JSON.stringify(LEVEL_DIAGRAM_CARD_CONFIG))]

        //     // relation tables
        //     const configCount = vm.relatedFieldItems.length + 1
        //     for (let i = 0; i < configCount; i++) {
        //         var cardConfig = {
        //             cardSummaryFieldId: '',
        //             cardImageFieldId: '',
        //             cardImagePosition: '',
        //             cardImageMode: '',
        //             cardDisplayFieldName: true,
        //             cardDisplayFieldIds: []
        //         }
        //         result.push(cardConfig)
        //     }


        //     // for (let j = 0; j < result.length, j++) {
        //     //     const existingCardConfig = vm.cardConfigs.find(cfg => cfg.fieldId && cfg.relatedFormId)
                
        //     //     formId && cfg.real)
        //     //     result[j] = {...result[j], vm.cardConfigs = {...vm.card}                
        //     // }
        //     //     if (vm.cardConfigs[i]) {
        //     //         cardConfig = { ...cardConfig, ...vm.cardConfigs[i]}
        //     //     }
        //     // }
        //     return result
        // }
    },
    methods: {
        setCardConfig (index, val) {
            this.$emit('updateCardConfig')
        },
        onDeleteHandler () {

        },
        updateCardConfig (index, val) {
            console.log('udpateCardConfig: val: ', val)
            this.$emit('onCommand', {
                command: 'updateCardConfig',
                index: index,
                val: val
            })
        }
    }
}
</script>