<template>
    <div>
        <!--FIXME: hardcoded hint-->
        <v-text-field
            outlined
            height="25"
            :value="formattedDateRange"
            hide-details
            class="ml-2 periodInputBox"
            style="font-size:12px"
            clearable
            prepend-icon="mdi-calendar"
            @click:clear="clear"
            @click="showDialog"
            readonly
        />
        <v-dialog
            ref="dialog"
            v-model="showing"
            v-if="showing"
            width="800"
            persistent
        >
        <div class="py-1 px-2 teal white--text d-flex align-center">
            <v-icon color="white">mdi-calendar</v-icon>
            <div class="ml-2">{{$t('panel.setDates')}}</div>
        </div>
        <div class="d-flex">
            <v-card tile class="d-flex" style="border-right: 1px solid #d9d9d9">
                <v-card-text class="d-flex flex-column ma-0 pt-1 px-2">
                    <div>{{$t('general.quickSelect')}}</div>
                    <div class="d-flex flex-wrap">
                        <div v-for="option in dateOptions" style="width:50%" class="px-1 pt-1">
                            <v-btn
                                elevation="0"
                                class="w-100 periodQuickBtn"
                                small
                                style="font-size:12px"
                                @click="getQuickPeriod(option.value)"
                            >
                                {{$t(`calendar.${option.label}`)}}
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <div class="d-flex d-column" style="height:300px">
                <v-date-picker
                    class="rounded-0 period-picker-f"
                    range
                    v-if="showing"
                    v-model="dateRange"
                    scrollable
                    no-footer
                    no-title
                    :picker-date.sync="fPickerDate"
                    @update:picker-date="update_fPickerDate"
                    @change="handleDatePickerChange"
                >
                    <v-spacer></v-spacer>
                    <div style="height:40px"></div>
                </v-date-picker>
                <v-date-picker
                    no-title
                    class="rounded-0 period-picker-s"
                    range
                    v-if="showing"
                    v-model="dateRange"
                    :picker-date.sync="sPickerDate"
                    scrollable
                    no-footer
                    @update:picker-date="update_sPickerDate"
                    @change="handleDatePickerChange"
                >
                    <v-spacer></v-spacer>
                    <div style="height:40px" class="d-flex align-center mr-2">
                        <v-btn
                            class="min-width-80 elevation-0"
                            color="muted"
                            small
                            @click="close">
                            {{ $t('buttons.cancel') }}
                        </v-btn>
                        <v-btn
                            class="min-width-80 ml-1 elevation-0"
                            color="primary"
                            small
                            @click="confirm">
                            {{ $t('buttons.ok') }}
                        </v-btn> 
                    </div>
                </v-date-picker>
            </div>
        </div>
        </v-dialog>
    </div>
</template>
    
<script>
    import mixin from './_mixin'
    import {dateOptions} from '@/const/dateFilter'
    import moment from "moment";

    export default {
    name: 'dialogPeriodDate',
    mixins: [mixin],
    props: {
        rules:Array,
        hasError: Boolean,
        value: Array
    },
    data(){
        return{
            sPickerDate: null,
            fPickerDate: null,
            isMilestone: false,
            dateRange: [],
            dateOptions: dateOptions,
        }
    },
    computed: {
        dateText () {
            return ''
        },
        formattedDateRange () {
            return this.value.join(' to ')
        }
    },
    methods: {
        handleDatePickerChange(value){
            if(value[0]<value[1])
            this.dateRange = [value[0],value[1]]
            else
            this.dateRange = [value[1],value[0]]
        },
        showDialog () {
            this.dateRange = this.value 
            var today = this.dateRange.length>0? new Date(this.dateRange[0]):new Date()
            this.fPickerDate = today.toISOString().substring(0,7)
            this.sPickerDate = new Date(today.setMonth(today.getMonth()+1)).toISOString().substring(0,7)
            this.showing = true
        },
        update_fPickerDate(){
            var newPickerDate = new Date(this.fPickerDate)
            this.sPickerDate = new Date(newPickerDate.setMonth(newPickerDate.getMonth()+1)).toISOString().substring(0,7)
        },
        update_sPickerDate(){
            var newPickerDate = new Date(this.sPickerDate)
            this.fPickerDate = new Date(newPickerDate.setMonth(newPickerDate.getMonth()-1)).toISOString().substring(0,7)
        },
        getQuickPeriod(period){
            let d = new moment()
            let startDate, endDate
            switch(period){
                case 'TODAY':{
                    var today = d.clone().format('YYYY-MM-DD')
                    this.dateRange = [today]
                    break
                }
                case 'YESTERDAY':{
                    var yesterday = d.clone().subtract(1, "d").format('YYYY-MM-DD')
                    this.dateRange = [yesterday]
                    break
                }
                case 'THISWEEK':{
                    startDate = d.clone().startOf('week').format('YYYY-MM-DD')
                    endDate = d.clone().endOf('week').format('YYYY-MM-DD')
                    this.dateRange = [startDate, endDate]
                    break
                }
                case 'LASTWEEK':{
                    startDate = d.clone().subtract(1, 'W').startOf('week').format('YYYY-MM-DD')
                    endDate = d.clone().subtract(1, 'W').endOf('week').format('YYYY-MM-DD')
                    this.dateRange = [startDate, endDate]
                    break
                }
                break
                case 'THISMONTH':{
                    startDate = d.clone().startOf('month').format('YYYY-MM-DD')
                    endDate = d.clone().endOf('month').format('YYYY-MM-DD')
                    this.dateRange = [startDate, endDate]
                    break
                }
                break
                case 'LASTMONTH':{
                    startDate = d.clone().subtract(1, 'M').startOf('month').format('YYYY-MM-DD')
                    endDate = d.clone().subtract(1, 'M').endOf('month').format('YYYY-MM-DD')
                    this.dateRange = [startDate, endDate]
                    break
                }
                case 'THISQUARTER':{
                    startDate = d.clone().startOf('quarter').format('YYYY-MM-DD')
                    endDate = d.clone().endOf('quarter').format('YYYY-MM-DD')
                    this.dateRange = [startDate, endDate]
                    break
                }
                case 'LASTQUARTER':{
                    startDate = d.clone().subtract(1, 'Q').startOf('quarter').format('YYYY-MM-DD')
                    endDate = d.clone().subtract(1, 'Q').endOf('quarter').format('YYYY-MM-DD')
                    this.dateRange = [startDate, endDate]
                    break
                }
                case 'THISYEAR':{
                    startDate = d.clone().startOf('year').format('YYYY-MM-DD');
                    endDate = d.clone().endOf('year').format('YYYY-MM-DD');
                    this.dateRange = [startDate, endDate]
                    break
                }
                case 'LASTYEAR':{
                    startDate = d.clone().subtract(1, "year").startOf('year').format('YYYY-MM-DD');
                    endDate = d.clone().subtract(1, "year").endOf('year').format('YYYY-MM-DD');
                    this.dateRange = [startDate, endDate]
                    break
                }
            }
            this.fPickerDate = moment(startDate).format('YYYY-MM')
            this.sPickerDate = moment(endDate).format('YYYY-MM')
        },
        confirm(){
            this.$emit('input', this.dateRange)
            this.showing = false
        },
        close(){
            this.dateRange = this.value
            this.showing = false
        },
        clear(){
            this.$emit('input', [])
        }
    }
    }
</script>
<style>
    .period-picker-s .v-date-picker-header .v-btn:nth-child(1){
        display: none;
    }
    .period-picker-f .v-date-picker-header .v-btn:nth-child(3){
        display: none;
    }
    .periodInputBox {
        display: flex;
        align-items: center;
    }
    .periodInputBox.v-input--is-readonly .v-input__control > .v-input__slot{
        background-color: white !important;
    }
</style>