<template>
  <!-- <div class="cell-attachment px-1 w-100"
       :class="{'d-flex flex-row align-center':mode==='view','white':mode==='edit'}"
    :style="editModeStyle"> -->
  <div class="cell-attachment w-100 d-flex align-center" :style="editModeStyle">
    <v-tooltip
      top
      v-for="(media, index) in attachments"
      :key="index"
      nudge-bottom="9"
    >
      <template v-slot:activator="{ on }">
        <div
          v-if="media"
          v-on="on"
          class="image-wrapper d-flex align-center"
          :class="index != 0 ? 'ml-0' : ''"
          style="max-width:100%;display:inline-block; yellow; width:40px"
        >
          <img
            v-if="isAccessibleImage(media)"
            :height="35"
            max-height="35"
            contain
            max-width="100%"
            position="left"
            style="width:100%"
            @error="evt=>reloadImage(evt)"
            class="attachment-image"
            :class="{ white: mode === 'edit' }"
            :src="media.thumbnailUrl"
          />
                      <!-- :src="media.thumbnailUrl" -->

          <div v-else style="width:100%;" class="d-flex justify-center">
            <media-type-icon
              :media="media"></media-type-icon>
          </div>
          <v-btn
            v-if="mode === 'edit'"
            fab
            x-small
            color="error"
            class="d-none white--text preview-button"
            @click="deleteImage(media.mediaId)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            v-else
            fab
            x-small
            color="error"
            class="d-none white--text preview-button"
            @click="preview(media)"
          >
            <v-icon v-if="isAccessibleImage(media)" size="" small>mdi-magnify</v-icon>
            <v-icon v-else small>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{ media ? media.originalName : '' }}</span>
    </v-tooltip>
    <v-btn
      v-if="canAdd"
      fab
      x-small
      color="primary"
      class="white--text add-button"
      @click="addImage"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import mixin from "../mixin";
import MediaHelper from "@/helpers/MediaHelper";
import mediaTypeIcon from '@/components/MediaTypeIcon';

export default {
  mixins: [mixin],
  components: {
    mediaTypeIcon
  },
  data () {
    return {
      count: 0,
      maxCount: 10
    }
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "view",
    },
    minAttachmentCount: {
      type: Number,
      default: 0,
    },
    maxAttachmentCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    attachments() {
      const vm = this;
      var result = [];
      if (vm.value && Array.isArray(vm.value)) {
        result = vm.value;
      }
      return result;
    },
    editModeStyle() {
      const vm = this;
      var result = "";
      if (vm.mode === "edit") {
        result = {
          height: "34px",
          position: "relative",
        };
      }
      return result;
    },
    imageHeightInCell() {
      return this.getPropertyValue("imageHeightInCell", "40");
    },
    canAdd() {
      let maxAttachmentCount = parseInt(this.maxAttachmentCount);
      if (isNaN(maxAttachmentCount)) maxAttachmentCount = 0;
      return (
        this.mode === "edit" &&
        (!maxAttachmentCount || this.attachments.length < maxAttachmentCount)
      );
    },
  },
  methods: {
    isAccessibleImage(media) {
      return MediaHelper.isAccessibleImage(media);
    },
    //TODO: figure out why assign self
    reloadImage(evt) {
      if (this.count < this.maxCount) {
        evt.target.src = evt.target.src;
        this.count++;
      }
    },
    addImage() {
      this.$emit("click:add");
    },
    deleteImage(mediaId) {
      this.$emit("click:delete", mediaId);
    },

    preview(media) {
      MediaHelper.previewMedia(media);
    },
  },
};
</script>
<style lang="scss">
.image-wrapper {
  position: relative;
}
.image-wrapper .delete-button {
  left: 0;
  background-color: rgba(255, 0, 0, 0.7) !important;
}
.image-wrapper .delete-button {
  display: none;
  position: absolute;
  top: 1px;
  left: 2px;
}

/*// .cell-attachment:hover .delete-button {*/
/*//   display: inline-block !important;*/
/*// }*/

/*.cell-attachment .preview-button,*/
/*.cell-attachment .add-button,*/
/*.cell-attachment .delete-button {*/
/*opacity: 0.7;*/
/*}*/

.image-wrapper .preview-button {
  left: 0;
  background-color: rgba(255, 0, 0) !important;
}
.image-wrapper .preview-button {
  display: none;
  position: absolute;
  left: 10%;
  /* top: 1px; */
  /* left: 2px; */
}

.image-wrapper:hover .preview-button {
  display: inline-block !important;
}
.cell-attachment .add-button {
  display: none;
  /* position: absolute; */
  /* top: 1px; */
  left: 5px;
  /*background-color: rgba(0,255,0,0.5) !important;*/
}

.cell-attachment .add-button {
  display: inline-block !important;
}

/*.cell-attachment {*/
/*border: 1px solid transparent;*/
/*}*/

/*.col--valid-error .cell-attachment {*/
/*border-color: red;*/
/*}*/
</style>
