<template>
  <table class="childTable" :style="`font-size:${fontSize}px`">
    <colgroup>
      <col
        v-for="(col, index) in colGroup"
        :key="index"
        :style="`width: ${col.width}px; min-width: ${col.minWidth}px;`"
      />
    </colgroup>
    <thead class="table-header">
      <tr>
        <th v-for="fieldInfo in headers" :key="fieldInfo._id">
          {{ fieldInfo.label }}
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="(record, index) in records" :key="record._id">
        <td v-for="fieldInfo in headers" :key="fieldInfo._id">
          <div
            v-if="fieldInfo.type === 'richText'"
            class="ql-editor pa-1"
            v-html="record[fieldInfo._id]"
          ></div>
          <div v-else-if="fieldInfo.type === 'signature'">
            <img
              v-if="record[fieldInfo._id]"
              class="signature-image"
              style="width:168px;height:100px"
              :src="record[fieldInfo._id]"
            />
          </div>
          <div v-else>
            {{
              fieldInfo._id === "sequence"
                ? index + 1
                : getDisplayValue(record._id, record[fieldInfo._id], fieldInfo)
            }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { getDisplayValue } from "@/helpers/DataHelpers";

export default {
  props: {
    fieldInfos: Array,
    records: Array,
    visibleFields: Array,
    fontSize: Number,
    relatedRecords: Object,
    childRelatedRecords:Object,
    relatedTableInfos:Object
  },
  computed: {
    colGroup() {
      const width = Math.max(728 / this.headers.length, 33);
      return this.headers.map((info) => {
        return {
          width,
          minWidth: 33,
          maxWidth: width,
        };
      });
    },
    headers() {
      let result = this.fieldInfos.filter((info) =>
        this.visibleFields.includes(info._id)
      );
      if (this.visibleFields.includes("sequence")) {
        result = [
          { _id: "sequence", label: this.$t("printTemplate.sequence") },
        ].concat(result);
      }
      return result;
    },
  },
  methods: {
    getDisplayValue(recordId, value, fieldInfo) {
      return getDisplayValue(value, fieldInfo, this);
    },
  },
};
</script>
<style scoped>
.childTable {
  margin-bottom: 5px;
  margin-top: 5px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  border-left: none !important;
  border-right: none !important;
  table-layout: auto;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
}

.table-header {
  padding: 5px;

  white-space: pre-wrap;
  word-break: break-word;
  background-color: #fafafa;
}

.table-header > tr > th {
  padding: 2px;
  border: 1px solid #ddd;
  border-right: none;
  border-top: 0;
}

.table-header > tr > th:first-child {
  border-left: none !important;
}

.childTable > tbody > tr > td {
  border: 1px solid #ddd;
  border-right: none;
  border-top: none;
  padding: 2px;
  white-space: pre-wrap;
  word-break: break-word;
}

.childTable > tbody > tr > td:first-child {
  border-left: none;
}

.childTable > tbody > tr:last-child > td {
  border-bottom: none;
}
</style>
