<template>
  <div>
    <PendingSetup
      v-if="isPending"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <div class="workflow-shortview-remark">
        {{
          $t('workflow.modules.whatsappNotification.label.informTargetNormal')
        }}: {{ totalRecipient }}
      </div>
      <div class="workflow-shortview-remark">
        <b>{{
          $t('workflow.modules.whatsappNotification.label.content')
        }}</b> <div v-html="expressionDisplay" />
      </div>
    </div>
    
  </div>
</template>

<script>
import PendingSetup from './../pending';
import Mixin from '../mixin';
import NotificationMixin from './mixin';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin, NotificationMixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  components: {
    PendingSetup,
  },
};


</script>

<style scoped>
  
</style>
