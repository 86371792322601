import Vue from 'vue'
import * as types from './action_types'

const state = {
  allTeams: [],
  allTeamsLoaded: false
}

const getters = {
  allTeamsLoaded: state => {
    return state.allTeamsLoaded
  },
  allTeamsIncludingTerminated: state => {
    return state.allTeams;
  },
  allTeams: state => {
    return state.allTeams.filter(team => !team.terminated);
  },
  terminatedTeams: state => {
    return state.allTeams.filter(team => team.terminated);
  },
  activeTeam: (state, getters) => {
    return (state.allTeams && getters.user) ? state.allTeams.find(team => team._id === getters.user.lastTeam): null
  },
  activeTeamId: (state,getters) => {
    return getters.activeTeam ? getters.activeTeam._id : ""
  },
  activeTeamCode: (_, getters)=> getters.activeTeam? getters.activeTeam.code:"",
  activeTeamName:(state, getters) => {
    return getters.activeTeam ? getters.activeTeam.name : ""
  },
  canAccessWorkAdminPage:(_, getters) => getters.isTeamAdmin && getters.activeTeam && !getters.activeTeam.terminated
}

const mutations = {
  setAllTeams (state, allTeams) {
    state.allTeams = allTeams
    // console.log('teams.js :: setAllTeams :; state.allTeams: ', state.allTeams)
  },
  updateAccessibleTeams (state, accessibleTeamDict) {
    for (let teamId in accessibleTeamDict) {
      const teamIndex = state.allTeams.findIndex(team => team._id === teamId)
      state.allTeams[teamIndex].accessibleTeams = accessibleTeamDict[teamId]
    }
  }
}

const actions = {
  [types.FETCH_ALL_TEAMS] ({commit, dispatch}) {
    return new Promise((resolve) => {
      const getData = {
        urlCommand: '/teams'
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          commit('setAllTeams', response)
          resolve(response)
        }
      ).catch(
        err => {
          console.log('FETCH_ALL_TEAMS_AND_MEMBERS :: err: ', err)
        }
      )
    })
  },

  [types.UPDATE_ACCESSIBLE_TEAMS] ({commit, dispatch}, payload) {
    return new Promise( resolve => {
      const postData = {
        urlCommand: '/teams/updateAuthorization',
        data: payload
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          commit('updateAccessibleTeams', response)
          resolve(response)
        }
      ).catch(
        err => {
          console.log('UPDATE_ACCESSIBLE_TEAMS: err: ', err)
        }
      )
    })
  },

  [types.FETCH_TEAM_SUMMARY] ({commit,dispatch}, payload) {
    return new Promise( resolve => {
      const postData = {
        urlCommand: '/teams/summary'
        // urlCommand: '/teams/' + payload.teamId + '/summary'
      }
      dispatch('AUTH_POST', postData).then(
        response => {
          resolve(response)
        }
      ).catch(
        err => {
          console.log('FETCH_TEAM_SUMMARY: err: ', err)
        }
      )
    })
  },

  [types.CHECK_TEAM_EXPIRY_STATUS] ({dispatch}, payload) {
    return new Promise( resolve => {
      const getData = {
        urlCommand: '/teams/' + payload.teamId + '/expiryInfo'        
      }
      dispatch('AUTH_GET', getData).then(
        response => {
          resolve(response);
        }
      ).catch(
        err => {
          console.log('CHECK_TEAM_EXPIRY_STATUS: err: ', err);
        }
      )
    })
  }
}


export default {
  state,
  getters,
  mutations,
  actions
}
