<template>
  <v-dialog
      v-model="showingDialog"
      v-if="showingDialog"
      scrollable
      height="600"
      width="480">
    <v-card class="">
      <!-- <v-card-title class="headline grey lighten-2">
        {{ user.name }}
      </v-card-title> -->
      <dialog-header
        :label="user.name"
        @close="showingDialog = false"
        color="#E0E0E0"
        toolbarClass="grey--text"
      />
      <v-card-text class="pt-3">
        <v-container fluid>
          <v-row>
            <v-col>
              <label>{{ $t('general.name') }}</label>
              <v-text-field
                outlined
                dense
                :hide-details="true"
                readonly
                disabled
                placeholder="" 
                :value="user.name"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>{{ $t('general.firstName') }}</label>
              <v-text-field
                outlined
                dense
                :hide-details="true"
                readonly
                disabled
                placeholder=""
                :value="user.firstName"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label>{{ $t('general.lastName') }}</label>
              <v-text-field
                outlined
                dense
                :hide-details="true"
                readonly
                disabled
                placeholder=""
                :value="user.lastName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label>{{ $t('general.accountName') }}</label>
              <v-text-field
                outlined
                dense
                :hide-details="true"
                readonly
                disabled
                placeholder=""
                :value="user.username?user.username:' '"></v-text-field>
            </v-col>
            <v-col>
              <label>{{ $t('general.email') }}</label>
              <v-text-field
                outlined
                dense
                :hide-details="true"
                readonly
                disabled
                placeholder=""
                :value="user.email"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <label>
                  {{ $t('general.belongingToTeams') }}
                  <v-chip x-small color="warning">{{ user.teams.length }}</v-chip>
                </label><br/>
                <div class="d-flex flex-column"
                     style="max-height:300px;border: 1px solid lightgray;">
                  <perfect-scrollbar class="d-flex flex-column flex-grow-1 pa-0" style="height:100%;position:relative;">
                    <div class="flex-grow-1 d-flex flex-column white">
                      <v-list two-line class="team-list">
                        <template v-for="(team, index) in user.teams">
                          <v-list-item :key="team._id">
                            <v-list-item-avatar>
                              <v-img :src="team && team.logo ? team.logo : ''"></v-img></v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ team.name }}</v-list-item-title>
                              <v-list-item-subtitle>
                                <v-chip small color="primary">{{$t(`industry.${team.industry.id}`)}}</v-chip>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </template>
                      </v-list>
                    </div>
                  </perfect-scrollbar>
                </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary"
               class="min-width-80"
               @click="showingDialog=false">
          <v-icon>mdi-close</v-icon>{{ $t('buttons.close')}}</v-btn>
      </v-card-actions> -->
      <dialog-actions 
        :buttons="['close']"
        @onCommand="showingDialog=false">
      </dialog-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
  components:{
    dialogActions,
    dialogHeader
  },
  data () {
    return {
      showingDialog: false
    }
  },
  methods: {
    open () {
      const vm = this
      console.log('UserProfileDialog :: methods :: open')
      console.log('UserProfileDialog :: showingDialog: ' + (vm.showingDialog ? 'yes' : 'no'))

      vm.showingDialog = true
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  }
}
</script>

<style>
.user-profile-dialog-card .v-input {

}

.user-profile-dialog-card .col {
  padding: 6px;
}

.user-profile-dialog-card .ps {
  height: 240px;
}

.user-profile-dialog-card .v-list.team-list {
  padding: 0;
}

.user-profile-dialog-card .v-list.team-list div.v-list-item:nth-child(4n+1) {
  background-color: rgba(0, 0, 0, .05) !important;
}
</style>
