<template>
  <div>
    <WorksheetSelect
      :worksheet="setting.worksheet"
      :label="$t('workflow.modules.createRecord.label.selectWorksheet')"
      :placeholder="$t('workflow.modules.createRecord.placeholder.selectWorksheet')"
      :fetchByNode="true"
    />
    <div class="w-setting-createRecord-source">
      <CreateFromSourceSelect
        :hookEvent="setting.hookEvent"
      />
    </div>
    <div class="w-setting-createRecord-form">
      <FormElement
        :label="$t('workflow.label.createRecord')"
      >
        <div v-if="ready" class="w-setting-createRecord-form-root">
          <CreateRecordForm
            :worksheet="setting.worksheet"
            :withHideFields="withHideFields"
            v-bind="comboProperties"
          />
        </div>
        <v-layout v-else align-center justify-start class="workflow-setting-form-waiting-set">
          <v-icon small color="#cccccc">mdi-information</v-icon>
          {{ $t('workflow.modules.createRecord.placeholder.how') }}
        </v-layout>
      </FormElement>
    </div>
  </div>
</template>

<script>
import FormElement from './../shared/formElement';
import SettingHeader from './../shared/header';
import WorksheetSelect from './../shared/worksheetSelect';
import CreateFromSourceSelect from './createFromSourceSelect/';
import CreateRecordForm from './form/';
import Mixin from './../mixin';
import SettingUtils from "@/pages/admin/workflow/utils/setting";
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    withHideFields: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    list() {
      return this.$store.getters.getFormSelectionsFields.children;
    },
    loading() {
      return this.$store.getters.fetchingFormSelectionsFields;
    },
    ready() {
      return this.setting.hookEvent && this.setting.worksheet;
    },
    dataSource() {
      return this.setting.dataSource;
    },
    comboProperties() {
      return SettingUtils.comboBoxDataSourceProperties(this.dataSource);
    }
  },
  components: {
    SettingHeader,
    WorksheetSelect,
    CreateFromSourceSelect,
    CreateRecordForm,
    FormElement,
  },
};

</script>

<style scoped>
  .w-setting-createRecord-source {
    margin: 25px 0 5px 0;
  }
  .w-setting-createRecord-form-root {
    margin: 0 -8px;
  }
</style>
