var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-grid',_vm._b({ref:"vxeTable",attrs:{"border":""},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var row = ref.row;
return [_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"size":"18"},on:{"click":function($event){return _vm.$emit('onCommand', {command:'edit', row: row})}}},[_vm._v(" mdi-square-edit-outline ")])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.register === 'UNREGISTERED')?_c('a',{class:{
        'disabled-a-tag': (!row.user.email)
    },on:{"click":function($event){return _vm.$emit('onCommand', {
      command:'invite',
      row: row
    })}}},[_vm._v(_vm._s(_vm.$t('workAdmin.sendInvitation')))]):_c('a',{on:{"click":function($event){return _vm.$emit('onCommand', {
      command:'editBind',
      row: row
    })}}},[_vm._v(_vm._s(_vm.$t('workAdmin.editBind')))])]}}])},'vxe-grid',_vm.attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }