<template>
  <div>
    <v-row no-gutters>
      <label>{{ label }}</label>
    </v-row>
    <v-row
      v-if="expressionType === 'date'"
      class=" mt-1 formulaGuide"
      no-gutters
    >
      <span>{{ $t("messages.dateGuide") }}</span>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span class="unitTooltip" v-bind="attrs" v-on="on">{{
            $t("messages.unit")
          }}</span>
        </template>
        <span v-html="$t('messages.unitMap')"></span>
      </v-tooltip>
    </v-row>
    <v-row
      no-gutters
      v-else-if="expressionType === 'number'"
      class=" mt-1 formulaGuide"
    >
      <span>{{ $t("messages.numGuide") }}</span>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="unitTooltip"
            >{{ $t("messages.addFormula") }}
          </span>
        </template>
        <v-card outlined>
          <v-card-text class="pa-0">
            <v-list dense class="pa-0">
              <template v-for="(formula, index) in formulaOptions">
                <v-list-item
                  class="white"
                  active-class="white"
                  dense
                  :ripple="false"
                  @click="addFormula(formula)"
                  :key="index"
                  style="min-height: 30px"
                >
                  <template v-slot:default="{}">
                    <v-list-item-content>
                      <div>{{ formula }}</div>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < formFieldInfos.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-row>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <codemirror
            class="CodeMirror"
            ref="cmEditor"
            @ready="onCmReady"
            v-model="propertyValue"
            :options="cmOptions"
            style="border: 1px solid grey; height: auto"
          />
        </div>
      </template>
      <v-card outlined>
        <v-card-text class="pa-0">
          <v-list dense class="pa-0" max-height="350px">
            <template v-for="(field, index) in filteredFields">
              <v-list-item
                class="white"
                active-class="white"
                dense
                :ripple="false"
                @click="handleListItemClick(field)"
                :key="field.fieldId"
                style="min-height: 30px"
              >
                <template v-slot:default="{}">
                  <v-list-item-content>
                    <div>{{ field.properties.fieldName }}</div>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider
                v-if="index < formFieldInfos.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import mixin from "./mixin";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/base16-dark.css";
import "codemirror/addon/search/search.js";
import "codemirror/addon/search/searchcursor.js";
export default {
  mixins: [mixin],
  data() {
    return {
      cmOptions: {
        tabSize: 1,
        mode: "text",
        // theme: 'base16-dark',
        lineNumbers: false,
        line: true,
        lineWrapping: true,
      },
      formulaOptions: [
        "SUM()",
        "AVERAGE()",
        "MAX()",
        "MIN()",
        "SQRT()",
        "ABS()",
        "ROUND()",
        "MEDIAN()",
      ],
      bl_init: false,
    };
  },
  computed: {
    filteredFields() {
      return this.currentFieldInfos.filter(
        (item) =>
          ["number", "amount", "summaryField"].includes(item.type) ||
          (item.type === "singleSelection" &&
            item.properties.inputOptionsSingle.useValue) ||
          (item.type === "multipleSelection" &&
            item.properties.inputOptionsMultiple.useValue) ||
          (item.type === "expression" &&
            item.properties.expressionType === "number" &&
            item.fieldId !== this.fieldInfo.fieldId) ||
          (item.type === "expression" &&
            item.properties.expressionType === "date" &&
            item.fieldId !== this.fieldInfo.fieldId &&
            item.properties.calculationMethodDate !== "addSubtractTimeFromDate")
      );
    },
    expressionType() {
      return this.getPropertyValue("expressionType", "");
    },
    codemirror() {
      return this.$refs.cmEditor.codemirror;
    },
  },
  // mounted() {
  //   this.CodeMapping();
  // },

  methods: {
    onCmReady() {
      this.codemirror.setValue(this.propertyValue);
      this.CodeMapping();
    },
    addWidget(id, name, bl_replace = 1) {
      this.count++;
      var cm = this.$refs.cmEditor.codemirror;
      var cursor = cm.getCursor();

      var lineNumber = cursor.line;
      var charNumber = cursor.ch;
      // var snippet = "[[tag" + this.count + "]]"
      var snippet = "${" + id + "}";
      if (bl_replace)
        cm.replaceRange(
          snippet,
          { line: lineNumber, ch: charNumber },
          { line: lineNumber, ch: charNumber }
        );
      if (bl_replace) {
        const div = document.createElement("div");
        div.innerHTML = name;
        div.className =
          "rounded-pill info white--text pa-2 py-0 d-inline-block";
        cm.markText(
          { line: lineNumber, ch: charNumber },
          { line: lineNumber, ch: charNumber + snippet.length },
          {
            replacedWith: div,
          }
        );
      } else {
        var cursor = cm.getSearchCursor(snippet);
        var first, from, to;
        while (cursor.findNext()) {
          this.bl_init = true;
          const div = document.createElement("div");
          div.innerHTML = name;
          div.className =
            "rounded-pill info white--text pa-2 py-0 d-inline-block";
          from = cursor.from();
          to = cursor.to();
          cm.markText(from, to, {
            replacedWith: div,
          });
          if (first === undefined) {
            first = from;
          }
        }
      }
      // cm.setBookmark({line: lineNumber,ch: charNumber}, div)
    },
    handleListItemClick(field) {
      this.addWidget(field.fieldId, field.properties.fieldName);
    },
    addFormula(formula) {
      let cm = this.$refs.cmEditor.codemirror;
      let cursor = cm.getCursor();
      let lineNumber = cursor.line;
      let charNumber = cursor.ch;
      cm.replaceRange(formula, { line: lineNumber, ch: charNumber });
    },
    CodeMapping() {
      let vm = this;
      this.currentFieldInfos.forEach((item) => {
        if (vm.propertyValue.includes(item.fieldId)) {
          vm.addWidget(item.fieldId, item.properties.fieldName, 0);
        }
      });
    },
  },
};
</script>
<style>
.CodeMirror {
  height: auto !important;
}
.formulaGuide {
  font-size: 10px;
  color: grey;
}
.unitTooltip {
  color: #4a90e2;
}
</style>
