<template>
  <v-dialog
      v-model="showingDialog"
      scrollable
      width="480"
      max-width="90%">
    <v-card min-height="200">
        <dialog-header
           color="primary"
           class="flex-grow-0"
           :icon="icon"
           :label="dialogTitle"
           @close="close">
        </dialog-header>
        <v-divider></v-divider>
        <v-card-text style="height:100%;" class="pb-0">
            <div class="d-flex flex-column justify-center fill-height">
            <div>
                <h4>{{ $t('messages.currentGroupIncludesMenu') }}</h4>
                <label class="caption">{{ $t('messages.moveTo') }}</label>
                <v-select
                autofocus
                height="30"
                dense
                :hide-details="false"
                outlined
                single
                flat
                :items="targetModules"
                item-text="title"
                item-value="_id"
                v-model="targetModuleId"
                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                >
                </v-select>
            </div>
            </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <dialog-actions class="dialog-actions flex-grow-0"

        :actionDisabled="!targetModuleId"
        :loading="loading"
        @onCommand="onCommandHandler"></dialog-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogHeader from './comps/DialogHeader'
import dialogActions from './comps/DialogActions'

export default {
  components: {
    dialogHeader,
    dialogActions
  },
  data () {
    return {
      showingDialog: false,
      targetModuleId: '',
      menuItem: null
    }
  },
  computed: {
    app () {
      return this.$store.getters.currentApp
    },
    modules () {
      const vm = this
      return vm.app && vm.app.modules ?
        vm.app.modules :
        []
    },
    dialogTitle () {
      return this.$t('menu.moveTo')
    },
    currentModule () {
      return this.$store.getters.currentModule
    },
    targetModules () {
      const vm = this
      var result = []
      if (vm.modules && vm.currentModule) {
        // console.log('ModuleMoveDialog.computed(targetMdules):: vm.modules: ', vm.modules)
        result = vm.modules.filter(m => m._id !== vm.currentModule._id)
      }
      return result
    }
  },
  methods: {
    open (payload) {
      const vm = this
      // console.log('ModuleMoveDialog.open')
      vm.menuItem = payload.menuItem
      vm.onConfirmHandler = payload.onConfirm
      vm.targetModuleId = ''
      vm.showingDialog = true
    },
    close () {
      this.showingDialog = false
    },
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'ok':
          if (typeof vm.onConfirmHandler === 'function') {
            vm.onConfirmHandler( {
              menuItem: vm.menuItem,
              currentModule: vm.currentModule,
              targetModuleId: vm.targetModuleId
            })
          }
          vm.close()
          break
        case 'cancel':
          vm.close()
          break
      }
    }
  }
}
</script>
