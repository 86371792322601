<template>
<div class="d-flex flex-column align-start menu-filter-criteria-field">
  <div class="d-flex flex-row justify-start align-center mb-1">
    <v-menu offset-y
      color="white"
      max-height="90%"
      open-on-click>
      <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab color="primary" v-on="on"
              :disabled="disabled">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
      </template>
      <v-list class="menu-sm">
        <v-list-item
            v-for="fieldInfo in filterableFieldInfos"
            :key="fieldInfo[idField]"
            @click="onListItemClicked(fieldInfo)">
              <v-list-item-title class="text-body1">{{ fieldInfo[labelField] }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</div>
</template>

<script>
import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
import appsFormsMixin from '@/mixins/appsFormsMixin'

export default {
  name: 'fieldSelectionButton',
  mixins: [appsFormsMixin],
  components: {inputRadioToggle},
  props: {
    fieldInfos: {
      type: Array,
      default () {
        return []
      }
    },
    idField: {
      type: String,
      default: '_id'
    },
    labelField: {
      type: String,
      default: 'label'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      callback: null
    }
  },
  methods: {
    onListItemClicked (fieldInfo) {
      const vm = this
      console.log('FieldSelectionButton: onListItemClicked : fieldInfo: ', fieldInfo)
      vm.$emit('click', fieldInfo)
    //   Id
    // }) {
    //   console.log('onListItemClicked :; fieldId = ' + fieldId)
    //   const vm = this
    //   vm.$emit('click', {
    //     fieldId: fieldId
    //   })
    }
    // onListItemClicked (fieldId) {
    //   console.log('onListItemClicked :; fieldId = ' + fieldId)
    //   const vm = this
    //   vm.$emit('click', {
    //     fieldId: fieldId
    //   })
    // }
  },
  mounted () {
    const vm = this
    if (vm.filter) {
      vm.userFilter = vm.filter
    }
  },
  computed: {
    filterableFieldInfos () {
      return this.fieldInfos ?
        this.fieldInfos :
        []
    }
  },
  // ,
  // computed: {
  //   filterInfos () {
  //     return this.$store.getters.filterInfos
  //   }
    // fieldLabelAndIds () {
    //   const vm = this
    //   var result = []
    //   for (var i = 0; i < vm.form.fieldInfos.length; i++) {
    //     const loopFieldInfo = vm.form.fieldInfos[i]
    //     var fieldLabel = loopFieldInfo.properties['fieldName']
    //     if (loopFieldInfo.properties['fieldNameTag']) {
    //       fieldLabel = vm.$t(loopFieldInfo.properties['fieldNameTag'])
    //     }
    //     console.log('i=' + i + ': fieldLabel = ' + fieldLabel)
    //     result.push({
    //       fieldLabel: fieldLabel,
    //       fieldId: loopFieldInfo.fieldId
    //     })
    //   }
    //   return result
    // },
    // currentAndOrOptions () {
    //   const vm = this
    //   console.log('currentAndOrOptions :: vm.andOrOption = ' + vm.andOrOption)
    //   const currentOptions = vm.andOrOptions.filter(item => item.value === vm.andOrOption)
    //   console.log('currentOptions: ', currentOptions)
    //   return currentOptions
    // },
    // relationOptionLabel () {
    //   const vm = this
    //   var result = ''
    //   const selectedOption = vm.relationOptions.find(item => item.value === vm.userFilter.relation)
    //   if (selectedOption) {
    //     result = vm.$t(selectedOption.labelTag)
    //   }
    //   return result
    // },
    // adjustedAndOrOptions () {
    //   const vm = this
    //   var result = []
    //   for (var i = 0; i < vm.andOrOptions.length; i++) {
    //     const option = vm.andOrOptions[i]
    //     result.push({
    //       text: vm.$t(option.labelTag),
    //       value: option.value
    //     })
    //   }
    //   return result
    // },

    // adjustedRelationOptions () {
    //   const vm = this
    //   var result = []
    //   for (var i = 0; i < vm.relationOptions.length; i++) {
    //     const option = vm.relationOptions[i]
    //     result.push({
    //       text: vm.$t(option.labelTag),
    //       value: option.value
    //     })
    //   }
    //   return result
    // },

    // fieldInfo () {
    //   return {
    //     fieldName: 'Field Name'
    //   }
    // }
  // }
}
</script>

<style>
  .filter-widget-string {
    min-height: auto;
    margin-bottom: 10px;
  }
</style>
