<template>
  <div class="d-flex justify-center align-center flex-column">
    <v-alert type="warning" color="orange" text dismissible max-width="fit-content" class="align-self-center mt-3" v-if="tokenExpired">
      {{ $t('messages.tokenExpired') }}
    </v-alert>
    <yoovPlusLogo v-if="!initializing" />
  </div>
</template>

<script>
import yoovPlusLogo from "@/components/YoovPlusLogo.vue";

export default {
  components: {
    yoovPlusLogo,
  },
  data() {
    return {
      initializing: true,
    };
  },
  computed: {
    tokenExpired() {
      return ( this.$route.query.tokenExpired ) ? true : false;
    }
  }, 
  async beforeMount() {
    try {
      const accessToken = await this.$store.dispatch('CHECK_LOCAL_TOKEN');
      if ( accessToken ) {
        this.$router.push({name : 'myApps' })
      } else {
        this.initializing = false;
      }
    } catch (err) {
      this.initializing = false;
      return false;
    }
  }
};
</script>
