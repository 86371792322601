<template>
<div class="d-flex flex-row align-start time-action">
  <input-radio-toggle :options="assigableOptions"
    v-model="userDataType"
    class="flex-grow-0 ml-0 mr-2 text-no-wrap"></input-radio-toggle>
  <div class="flex-grow-1">
    <field-selection-menu
      ref="fieldSelectionMenu"
      class="mb-1 flex-grow-1"
      :items="availableFields"
      v-model="userFieldId"></field-selection-menu>
  </div>    
  <v-btn class="flex-grow-0 ml-2" 
    fab x-small color="error"
    height="28"
    width="28"
    :disabled="index===0"
    @click="deleteAction">
    <v-icon>mdi-close</v-icon>
  </v-btn>


  <!-- <v-select
    solo
    :hide-details="true"
    dense
    v-model="userFieldId"
    outlined
    :items="dateTimeFields"></v-select> -->
</div>
</template>

<script>
import inputRadioToggle from '@/components/formComps/InputRadioToggle'
import fieldSelectionMenu from './FieldSelectionMenu'

export default {
  name: 'timeAction',
  components: {
    inputRadioToggle,
    fieldSelectionMenu
  },
  props: {
    index: Number,
    fieldInfos: Array,
    action: null
    // action = {
    //    dataType: ['date'|'time'|'datetime'],
    //    fieldId: '...'
    // }
  },
  // watch: {
  //   action: function (newVal) {
  //     if (action.dataType==='') {
  //       alert('action.dataType is blank')
  //     }
  //   }
  // },
  data () {
    return {
      assigableOptions: [
        {label: 'Date', value: 'date'},
        {label: 'Time', value: 'time'},
        {label: 'Date/Time', value: 'datetime'}
      ],
      dateTimeFields: [
        {label: 'Date/Time #1', value: 'datetime1'},
        {label: 'Date/Time #2', value: 'datetime2'},
        {label: 'Date/Time #3', value: 'datetime3'},
        {label: 'come-in', value: 'comeIn'},
        {label: 'leave-out', value: 'leaveOut'},
      ]
    }
  },
  computed: {
    availableFields () {
      const vm = this
      return vm.fieldInfos.filter(info => info.properties.fieldType === vm.action.dataType)
    },
    // dateFieldInfos () {
    //   return this.targetForm ? this.targetForm.fieldInfos.filter(info => info.type === 'date') : []
    // },
    userDataType: {
      get () {
        console.log('get userDataType')
        return this.action.dataType
      },
      set (val) {
        console.log('set userDataType val: ', val)
        this.$emit('update', {
          dataType: val,
          fieldId: this.userFieldId
        })
      }
    },
    userFieldId: {
      get () {
        console.log('get userFieldId')
        return this.action.fieldId
      },
      set (val) {
        console.log('set userFieldId val: ', val)
        this.$emit('update', {
          dataType: this.userDataType,
          fieldId: val
        })
      }
    }
  },
  methods: {
    deleteAction () {
      this.$emit('delete')
    },
    validate() {
      return this.$refs.fieldSelectionMenu.validate()
    },
    resetValidation() {
      this.$refs.fieldSelectionMenu.resetValidation()
    }
  }
}
</script>