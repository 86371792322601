<template>
  <vxe-table-column :class-name="tdClass+' fullCell'" v-bind="colDef" 
    :edit-render="{name: 'EditRelatedRecordModal', props: { fieldInfo, currentFormInfo, relatedTableInfos, readonly, idDataMapping, updateTdClass, onCommandHandler }, enabled:!readonly}">
    <!-- VIEW: for table -->
    <template v-slot="{row}">
      <div v-if="isTable">
        <v-icon @click="showTableDrawer(row._id)">mdi-table</v-icon>
        <v-chip v-if="row[colDef.field] && row[colDef.field].length>0" small>
          {{ row[colDef.field] ? row[colDef.field].length : 0 }}</v-chip>
        <!-- <v-chip v-else small>
          {{ row[colDef.field] }}
        </v-chip> -->
      </div>
      <div v-else-if="!isParentChildRelationship">
        <div       
          v-for="(recordId, index) in row[colDef.field]"
          :key="index"
          class="cursor-pointer blue--text d-inline-block"
          @click="gotoRelatedRecord(recordId, row, colDef.field)">
          <v-chip class="cursor-pointer px-1 mr-1" 
            style="height:20px !important;">
            <field-string
              :textOnly="false"
              height="100%"
              :imageSize="imageSize"
              :fieldInfo="finalFieldInfo"
              :recordData="getRelatedRecordData(recordId, colDef.field)"></field-string>
          </v-chip>
          <quick-action
            :rowId="row['_id']"
            :fieldId="fieldId"></quick-action>
        </div>
      </div>
      <div v-else-if="row[colDef.field] && Array.isArray(row[colDef.field]) && isParentChildRelationship">
        <v-chip
          @click="gotoRelatedRecord(id, row, colDef.field)"
          v-for="(id, index) in row[colDef.field]"
          :key="index"
          class="ma-0 mr-1" small color="warning">
          {{ (idDataMapping[id] && idDataMapping[id].title) ? idDataMapping[id].title : '(' + $t('general.noTitle') + ')' }}
        </v-chip> 
        <quick-action
          :rowId="row['_id']"
          :fieldId="fieldId"></quick-action>
      </div>
    </template>
    <!-- EDIT: for table -->
    <template v-if="isTable" v-slot:edit="{row}">
      <div>
        <v-icon left>mdi-table</v-icon>
        <v-chip v-if="row[colDef.field] && row[colDef.field].length>0" small>
          {{ row[colDef.field] ? row[colDef.field].length : 0 }}</v-chip>
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import VXETable from 'vxe-table'
import EditRelatedRecordModal from './comps/EditRelatedRecordModal.vue'
import Vue from 'vue'
import quickAction from './comps/QuickAction'
import summaryCell from './comps/SummaryCell'
import fieldString from '@/components/fieldStrings/Fs'

Vue.component(EditRelatedRecordModal.name, EditRelatedRecordModal)

VXETable.renderer.add('EditRelatedRecordModal', {
  renderEdit (h, renderOpts, params) {
    return [
      <edit-related-record-modal params={ params } fieldInfo={renderOpts.props.fieldInfo} relatedTableInfos={renderOpts.props.relatedTableInfos} currentFormInfo={renderOpts.props.currentFormInfo} idDataMapping={renderOpts.props.idDataMapping} readonly={renderOpts.props.readonly } v-on:updateTdClass={renderOpts.props.updateTdClass} v-on:onCommand={renderOpts.props.onCommandHandler}></edit-related-record-modal>      
    ]
  }
})

export default {
  mixins: [mixin],  
  components: {
    quickAction,
    summaryCell,
    fieldString
  },
  props: ['rowHeightClass'],


  computed: {
    finalFieldInfo () {
      return this.relatedTableFieldInfo ? 
        this.relatedTableFieldInfo : 
        this.fieldInfo
    },
    relatedTableFieldInfo () {
      const vm = this
      var result = null
      if (vm.relatedFormInfo && vm.relatedFormInfo.fieldInfos) {
        result = vm.relatedFormInfo.fieldInfos.find(info => info._id === vm.relatedFormInfo.titleFieldInfoId)
      }
      return result
    },
    isTable () {
      
      return this.fieldInfo.properties.displayFormat === 'table'
    },
    isPullDown () {
      return this.fieldInfo.properties.displayFormat === 'pulldown'
    },
    relatedFormId () {
      return this.fieldInfo.properties.dataSource
    },
    relatedTableInfos () {
      return this.colDef.relatedTableInfos ? 
        this.colDef.relatedTableInfos :
        this.$store.getters.relatedTableInfos
    },
    isParentChildRelationship () {
      const vm = this
      let result = false
      if (vm.formInfo) {
        result = this.formInfo._id === this.relatedFormId
      }
      return  result
    },

    relatedFormInfo() {
      const vm = this
      var result = null
      if (vm.relatedFormId && vm.relatedTableInfos) {
        result = vm.relatedTableInfos[vm.relatedFormId]
      }

      return result
    }
  },
  methods: {
    showTableDrawer (recordId) {
      const vm = this
      vm.$emit('onCommand', {
        command: 'showChildTableDrawer',
        formInfo: vm.formInfo,
        fieldInfo: vm.fieldInfo,
        recordId: recordId,
      })
    },
     getRelatedRecordData (recordId) {      
      const vm = this
      let relatedRecord = {}

      if (vm.relatedTableFieldInfo) {
        relatedRecord = typeof recordId==='object'?
        vm.idDataMapping[recordId._id]:vm.idDataMapping[recordId]
      }

      return relatedRecord
    },
    gotoRelatedRecord (relatedRecordId, row, fieldId) {
      this.$emit('onCommand', {
        command: 'gotoRelatedRecord',
        recordId: row._id,
        fieldId: fieldId,
        relatedRecordId: relatedRecordId
      })
    },

    onCommandHandler (payload) {
      const vm = this
      console.log('VxeCellRelatedRecord.onCommandHandler: payload: ', payload)
      switch (payload.command) {
        case 'refreshRelationData':
          payload.dataSource = vm.relatedFormId
          vm.$emit('onCommand', payload)
          break
        default:
          vm.$emit('onCommand', payload)
      }

    }
  }


}

</script>
