const mixin = {
  methods: {
    focusInput() {
      this.$nextTick(() => {
        this.$refs.editableInput.focus();
      });
    }
  },
  computed: {
    appId() {
      return this.$route.params.id;
    },
  },
  watch: {
    editing: {
      handler(remarking, wasRemarking) {
        if (remarking !== wasRemarking && remarking) {
          this.focusInput();
        }
      },
      deep: true,
    }
  }
}

export default mixin
