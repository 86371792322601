<template>
  <v-dialog
    ref="dialog"
    v-model="showing"
    v-if="showing"
    @keydown.esc="cancel"
    max-width="480px"
    persistent
  >
    <v-card class="simpleDialog d-flex flex-column">
      <dialog-header icon="mdi-window-restore" :label="title" @close="close"></dialog-header>
      <v-card-text style="padding:20px 24px;">
        <slot></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
          <v-btn class="min-width-100 muted" color="muted" @click="close">{{ $t('buttons.cancel') }}</v-btn>
          <v-btn class="min-width-100" color="primary" @click="ok">{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogHeader from './comps/DialogHeader'

export default {
  name: "simpleDialog",
  components: {dialogHeader},
  props: {
    title: String
  },
  data() {
    return {
      showing: false
    };
  },
  methods: {
    ok() {
      this.$emit("click:ok");
    },
    close() {
      this.showing = false;
    },
    open() {
      this.showing = true;
    }
  }
};
</script>