<template>
  <div class="zoom-preview fill-height w-100"
       style="opacity:0.5;"
    :class="{'d-none':mediaId===''}">
    <!--<v-btn @click="preview" plain style="background-color:transparent;">-->
      <!--<v-icon>mdi-zoom</v-icon>-->
    <!--</v-btn>-->
      <v-btn fab
             style="position:absolute;left: 3px;bottom:3px;"
             x-small
             color="error"
             class="d-none white--text zoom-button"
             @click="preview">
            <v-icon>mdi-magnify</v-icon>
      </v-btn>
  </div>
</template>

<script>
import MediaHelper from '@/helpers/MediaHelper'

export default {
  props: {
    mediaId: {
      type: String,
      default: '',
    },
    objectKey: {
      type: String,
      default: '',
    },
    mediaType: {
      type: String,
      default: '',
    }
  },
  methods: {
    preview () {
      const payload = {
        mediaId: this.mediaId,
        mediaType: this.mediaType,
        objectKey: this.objectKey,
      };
      MediaHelper.previewMedia(payload)
    }
  }
}
</script>

<style>
.zoom-preview:hover .zoom-button {
  display: inline-block !important;
}
</style>
