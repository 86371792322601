<template>
  <div>
    <RecipientSelect />
    <div class="workflow-setting-form-each-item">
      <FormElement
        :label="$t('workflow.modules.inBrowserNotification.label.content')"
        name="content"
      >
        <FreeInput
          :value="notice.content"
          :fields="setting.fields"
          fieldType="text"
          noNewLine
          large
          @itemsChanged="itemsChanged"
          @valueChanged="contentChanged"
        />
      </FormElement>
    </div>
    <div class="workflow-setting-form-each-item">
      <v-checkbox
        :value="forwardRecord"
        @change="onForwardRecord"
        :label="$t('workflow.modules.inBrowserNotification.label.sendRecord')"
      />
      <FormElement v-if="forwardRecord" :hints="$t('workflow.modules.inBrowserNotification.remark.sendRecordHints')">
        <DataSourceSelect
          :value="setting.dataSource"
          name="dataSource"
          :normalize="normalizer"
          :placeholder="$t('workflow.modules.inBrowserNotification.placeholder.existingWorkflow')"
        />
      </FormElement>
    </div>
  </div>
</template>

<script>
import FreeInput from './../shared/freeInput';
import Mixin from '../mixin';
import NoticeMixin from './mixin';
import FormElement from './../shared/formElement';
import RecipientSelect from './recipient';
import OperationSettingMixin from './../operation/mixin';
import DataSourceSelect from './../shared/dataSourceSelect';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module View Wrapper Components
* Terry Chan
* 28/05/2021
*/
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';

export default {
  name: 'InAppNotificationSetting',
  mixins: [OperationSettingMixin, NoticeMixin, Mixin],
  components: {
    FreeInput,
    FormElement,
    RecipientSelect,
    DataSourceSelect,
  },
  data() {
    return {
      forwardRecord: !!this.dataSource,
    };
  },
  created(){
    const { notice={} } = this;
    this.changed(`notice.employee`, notice.employee||[], { notice })
    this.$set( this.setting.notice, 'employee', [] );
  },
  mounted() {
    this.forwardRecord = !!this.dataSource;
  },
  methods: {
    onForwardRecord(v) {
      if (!v) {
        this.$store.dispatch(SET_SETTINGS, {
          dataSource: null,
        });
      }
      this.forwardRecord = v;
    },
    contentChanged(v) {
      const { notice={} } = this;
      this.changed('notice.content', v, { notice })
    },
  },
  // watch: {
  //   setting: {
  //     handler(v) {
  //       console.log(v);
  //     },
  //     deep: true,
  //   }
  // },
};

</script>

<style scoped>

</style>
