<template>
    <v-card tile text style="height:100vh; width:100%; background-color:white">
        <v-card-text class="">
            <div class="pa-12 d-flex flex-column" v-if="ganttSetting">
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <h3 class="mb-0" style="border-bottom: 2px solid teal">{{$t('gantt.initSettings')}}</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="6" 
                            offset="0"
                        >
                            <!-- <div style="width:100px;">
                                專案層級欄位
                            </div> -->
                            <div>
                                <v-radio-group
                                    v-model="ganttSetting.projectRelation"
                                    row
                                    dense
                                    hide-details
                                    class="radio-margin-top-0 d-flex"
                                >
                                    <v-radio
                                        :label="$t('gantt.useCascade')"
                                        color="teal"
                                        value="PARENT_CHILD"
                                        class="ma-0"
                                        style="width:50%"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('gantt.useRelation')"
                                        color="teal"
                                        value="MULTIPLE_RELATED"
                                        style="width:50%"
                                        class="ma-0"
                                    ></v-radio>
                                </v-radio-group>
                            </div> 
                            <div
                                class="mt-2 ml-1"
                                v-if="ganttSetting.projectRelation=='PARENT_CHILD'"
                            >
                                <v-select
                                    :items="cascadeFields"
                                    item-text="label"
                                    item-value="fieldId"
                                    height="27"
                                    dense
                                    hide-details
                                    style="font-size:13px;"
                                    :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                    filled
                                    v-model="ganttSetting.projectCascade"
                                    v-if="!ganttSetting.projectCascade.length"
                                    @input="handleCascadeInput"
                                >
                                    <template v-slot:item="{item}">
                                        <div>
                                            <span style="font-size:12px">
                                                <span class="grey--text">{{$t('gantt.parentLevel')}} </span>
                                                <span>{{item[0].label}} - </span>
                                                <span class="grey--text">{{$t('gantt.childLevel')}} </span>
                                                <span>{{item[1].label}}</span>
                                            </span>
                                        </div>
                                    </template>
                                </v-select>
                                <v-hover v-else v-slot="{hover}">
                                    <div class="d-flex">
                                        <v-chip small class="teal lighten-2 white--text">
                                            {{getLabelById(ganttSetting.projectCascade)}}
                                        </v-chip>
                                        <v-icon v-show="hover" @click="ganttSetting.projectCascade=[]" small class="ml-auto">
                                            mdi-close
                                        </v-icon>
                                    </div>
                                </v-hover>
                            </div>
                            <div 
                                v-show="ganttSetting.projectRelation=='MULTIPLE_RELATED'"
                                style="border-bottom: 1px dashed #d9d9d9"
                                class="pb-8 mt-6"
                            >
                                <level-select-cascade-relation-fields
                                    v-model="ganttSetting.projectCascade"
                                    ref="ganttSetupLevelCascade"
                                    :view="view"
                                ></level-select-cascade-relation-fields>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" v-if="ganttSetting.projectCascade.length">
                       <v-col cols="12" v-show="ganttSetting.projectRelation=='MULTIPLE_RELATED'">
                          <div class="d-flex" v-if="relatedFieldItems">
                                <v-btn-toggle
                                    v-model="selectedForm"
                                    mandatory
                                    color="teal"
                                >
                                    <v-btn 
                                        v-for="(item, formIndex) in relatedFieldItems"
                                        :key="item.relatedFormId"
                                        :value="formIndex"
                                        class="d-flex align-center justify-center"
                                        style="height:30px; width:150px; border: 0px"
                                        :style="formIndex==0? 'clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)':
                                                'clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%)'"
                                    >
                                        <span class="px-6" style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{item.relatedFormName}}</span>
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                       </v-col>
                        <v-col cols="3" offset="0">
                            <div style="width:100px;">
                                <span class="red--text">*</span>
                                {{$t('gantt.projectField')}}
                            </div>
                            <v-select 
                                :items="titleFields"
                                item-text="label"
                                item-value="fieldId"
                                height="27"
                                dense
                                hide-details
                                style="font-size:13px;"
                                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                filled
                                v-model="ganttSetting.projectCascade[selectedForm].col_project"
                                @input="$emit('updateSetting', {command: 'update_col_project', val: $event, index: selectedForm})"
                                v-if="!ganttSetting.projectCascade[selectedForm].col_project"
                            >
                            </v-select>
                            <v-hover v-else v-slot="{hover}">
                                <div>
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.projectCascade[selectedForm].col_project)}}
                                    </v-chip>
                                    <v-icon v-show="hover" @click="ganttSetting.projectCascade[selectedForm].col_project=null" small class="float-right">
                                        mdi-close
                                    </v-icon>
                                </div>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-row 
                        class="mt-10" 
                        justify="start" 
                        v-if="(ganttSetting.projectCascade.length && selectedForm==(ganttSetting.projectCascade.length-1)) || (ganttSetting.projectRelation=='PARENT_CHILD' && ganttSetting.projectCascade[selectedForm])"
                    >
                        <v-col cols="3"  offset="0">
                            <div style="width:100px">
                                <span class="red--text">*</span>
                                {{$t('gantt.startField')}}
                            </div>
                            <v-select 
                                :items="dateFields"
                                item-text="label"
                                item-value="fieldId"
                                height="27"
                                dense
                                hide-details
                                style="font-size:13px;min-height:20px"
                                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                filled
                                v-if="!ganttSetting.projectCascade[selectedForm].col_start"
                                v-model="ganttSetting.projectCascade[selectedForm].col_start"
                                @input="$emit('updateSetting', {command: 'update_col_start', val: $event, index: selectedForm})"
                            >
                            </v-select>
                            <v-hover v-else v-slot="{hover}">
                                <div>
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.projectCascade[selectedForm].col_start)}}
                                    </v-chip>
                                    <v-icon v-show="hover" @click="ganttSetting.projectCascade[selectedForm].col_start=null" small class="float-right">
                                        mdi-close
                                    </v-icon>
                                </div>
                            </v-hover>
                        </v-col>
                        <v-col cols="3">
                            <div style="width:100px">
                                <span class="red--text">*</span>
                                {{$t('gantt.endField')}}
                            </div>
                            <v-select 
                                :items="dateFields"
                                item-text="label"
                                item-value="fieldId"
                                height="27"
                                dense
                                hide-details
                                style="font-size:13px;"
                                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                filled
                                v-model="ganttSetting.projectCascade[selectedForm].col_end"
                                @input="$emit('updateSetting', {command: 'update_col_end', val: $event, index: selectedForm})"
                                v-if="!ganttSetting.projectCascade[selectedForm].col_end"
                            >
                            </v-select>
                            <v-hover v-else v-slot="{hover}">
                                <div>
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.projectCascade[selectedForm].col_end)}}
                                    </v-chip>
                                    <v-icon v-show="hover" @click="ganttSetting.projectCascade[selectedForm].col_end=null" small class="float-right">
                                        mdi-close
                                    </v-icon>
                                </div>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-row 
                        class="mt-10" 
                        justify="start" 
                        v-if="ganttSetting.projectCascade.length && selectedForm==(ganttSetting.projectCascade.length-1) || (ganttSetting.projectRelation=='PARENT_CHILD' && ganttSetting.projectCascade[selectedForm])">
                        <v-col cols="3"  offset="0">
                            <div style="width:100px">
                                <span class="red--text">*</span>
                                {{$t('gantt.progressField')}}
                            </div>
                            <v-select 
                                :items="numericFields"
                                item-text="label"
                                item-value="fieldId"
                                height="27"
                                dense
                                hide-details
                                style="font-size:13px;"
                                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                filled
                                @input="$emit('updateSetting', {command: 'update_col_progress', val: $event, index: selectedForm})"
                                v-model="ganttSetting.projectCascade[selectedForm].col_progress"
                                v-if="!ganttSetting.projectCascade[selectedForm].col_progress || hover"
                            >
                                <template v-slot:selection="{item}">
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.projectCascade[selectedForm].col_progress)}}
                                    </v-chip>
                                </template>
                            </v-select>
                            <v-hover v-else v-slot="{hover}">
                                <div>
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.projectCascade[selectedForm].col_progress)}}
                                    </v-chip>
                                    <v-icon v-show="hover" @click="ganttSetting.projectCascade[selectedForm].col_progress=null" small class="float-right">
                                        mdi-close
                                    </v-icon>
                                </div>
                            </v-hover>
                        </v-col>
                        <v-col cols="3">
                            <div style="width:100px">
                                {{$t('gantt.milestoneField')}}
                            </div>
                            <v-select 
                                :items="dateFields"
                                item-text="label"
                                item-value="fieldId"
                                height="27"
                                dense
                                hide-details
                                style="font-size:13px;"
                                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                filled
                                @input="$emit('updateSetting', {command: 'update_col_milestone', val: $event, index: selectedForm})"
                                v-model="ganttSetting.projectCascade[selectedForm].col_milestone"
                                v-if="!ganttSetting.projectCascade[selectedForm].col_milestone"
                            >
                            </v-select>
                            <v-hover v-else v-slot="{hover}">
                                <div>
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.projectCascade[selectedForm].col_milestone)}}
                                    </v-chip>
                                    <v-icon v-show="hover" @click="ganttSetting.projectCascade[selectedForm].col_milestone=null" small class="float-right">
                                        mdi-close
                                    </v-icon>
                                </div>
                            </v-hover>
                        </v-col>
                    </v-row> 


                    <!-- <v-row class="mt-10" justify="start">
                        <v-col cols="3"  offset="0">
                            <div style="width:100px">
                                標示顏色欄位
                            </div>
                            <v-select 
                                :items="form.fieldInfos"
                                item-text="label"
                                item-value="fieldId"
                                height="27"
                                hide-details
                                style="font-size:13px;"
                                :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                filled
                                @input="$emit('updateSetting', {command: 'update_col_color', val: $event})"
                                v-model="ganttSetting.col_color"
                                v-if="!ganttSetting.col_color || hover"
                            >
                                <template v-slot:selection="{item}">
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.col_color)}}
                                    </v-chip>
                                </template>
                            </v-select>
                            <v-hover v-else v-slot="{hover}">
                                <div>
                                    <v-chip small class="teal lighten-2 white--text">
                                        {{getLabelById(ganttSetting.col_color)}}
                                    </v-chip>
                                    <v-icon v-show="hover" @click="ganttSetting.col_color=null" small class="float-right">
                                        mdi-close
                                    </v-icon>
                                </div>
                            </v-hover>
                        </v-col>
                    </v-row> -->


                    <v-row class="mt-10">
                        <v-col cols="6">
                            <v-btn @click="$emit('updateSetting', {command: 'update_settingCompleted', val: true})" style="width:100px" small text class="float-right elevation-0 teal lighten-1 white--text">
                                {{$t('gantt.completed')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import levelSelectCascadeRelationFields from '@/pages/forms/views/comps/LevelSelectCascadeRelationFields'
    export default {
        props:{
            form: Object,
            ganttSetting: Object,
            view: Object
        },
        components:{
            levelSelectCascadeRelationFields
        },
        mounted(){
           this.isMounted = true
           this.fieldInfos = this.form.fieldInfos
        },
        data(){
            return{
                parentChildFieldIdPair: null,
                parentChildFieldFormList: [],
                selectedForm: 0,
                isMounted: false,
                fieldInfos: []
            }
        },
        watch:{
            'ganttSetting.projectRelation'(val){
                let vm = this
                vm.selectedForm = 0
                if(val=='PARENT_CHILD'){
                    this.$emit('updateSetting', {command: 'update_projectCascade', val:[]})
                }else{
                    this.$emit('updateSetting', {command: 'update_projectCascade', val:[{
                            fieldId:"",
                            relatedAppId: vm.form.appId,
                            relatedFormId: vm.form._id,
                            relatedTeamId: vm.form.teamId,
                            col_project: null,
                            col_start: null,
                            col_end: null,
                            col_milestone: null,
                            col_progress: null,
                        }]
                    })
                    this.$refs.ganttSetupLevelCascade.relatedFieldItems = [this.$refs.ganttSetupLevelCascade.relatedFieldItems[0]]
                    this.$refs.ganttSetupLevelCascade.lastFormInfo =  this.form
                }
            },
            'ganttSetting.projectCascade'(val){
                console.log('ganttSetting.projectCascade', this.ganttSetting.projectCascade)
                if(this.ganttSetting.projectRelation=='MULTIPLE_RELATED'){
                    this.$emit('updateSetting', {
                        command: 'update_projectCascade', 
                        val
                    })
                }
            },
            selectedForm(){
                var currentFormId = this.relatedFieldItems[this.selectedForm].relatedFormId
                this.fieldInfos = this.$refs.ganttSetupLevelCascade.formInfos[currentFormId].fieldInfos
            }
        },
        
        computed:{
            relatedFieldItems:{
                get(){
                    if(!this.isMounted){
                        return []
                    }else{
                        return this.$refs.ganttSetupLevelCascade.relatedFieldItems
                    }    
                }
            },
            cascadeFields(){
                var fields = []
                this.form.linkedFields.forEach(item=>{
                    var mappedFields = item.fieldIds.map(item2=>{
                        return this.form.fieldInfos.find(item3=>item3.fieldId==item2)
                    })
                    fields.push(mappedFields)
                })
                return fields
            },
            multiRelatedFields(){
                let vm = this
                return this.form.fieldInfos.filter(fieldInfo=>{
                    return fieldInfo.type == 'relatedRecord' && fieldInfo.properties.dataSource!=vm.form._id
                })
            },
            titleFields(){
                return this.fieldInfos.filter(fieldInfo=>{
                    return ["text"].includes(fieldInfo.type)
                })
            },
            dateFields(){
                return this.fieldInfos.filter(fieldInfo=>{
                    return fieldInfo.type == 'date' && fieldInfo.properties.fieldType == 'date'
                })
            },
            numericFields(){
                return this.fieldInfos.filter(fieldInfo=>{
                    return ["number"].includes(fieldInfo.type)
                })
            }
        },
        methods:{
            handleCascadeInput(cascade){
                // var val = cascade.map(item=>item.fieldId)
                let vm = this
                var val = cascade.map(item=>{
                    return {
                        fieldId: item.fieldId,
                        relatedTeamId: vm.form.teamId,
                        relatedAppId: vm.form.appId,
                        relatedFormId: vm.form._id,
                        col_project: null,
                        col_start: null,
                        col_end: null,
                        col_milestone: null,
                        col_progress: null, 
                    }
                })
                this.$emit('updateSetting', {command: 'update_projectCascade', val})
            },
            getLabelById(field){
                console.log('getLabelById---', field)
                if(Array.isArray(field)){
                    return field.map(item=>{
                        var res = this.fieldInfos.find(item2=>{
                            return item2.fieldId == item.fieldId
                        })
                        if (res) 
                            return res.label
                        else return
                    }).join(' - ')
                }else{
                    var res = this.fieldInfos.find(item=>{
                        return item.fieldId == field
                    })
                    if(res) return res.label
                }
            },
        }
    }
</script>

<style> 

</style>
