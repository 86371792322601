<template>
  <div
    class="card-board-view flex-grow-1 d-flex flex-column panel-view align-content-stretch"
    style="position:relative;background-color:#fffce1;"
  >
    <!--<h4>groupingFieldInfo: {{ groupingFieldInfo ? 'yes' : 'no' }}</h4>-->
    <!--<div v-if="!groupingFieldInfo">-->
    <div v-if="!hasValidGroupingFieldInfo">
      <card-board-view-initial-setup
        :view="view"
        :groupingFieldInfos="validGroupingFieldInfos"
        @confirm="onConfirmHandler"
      ></card-board-view-initial-setup>
    </div>

    <div v-else class="flex-grow-1 d-flex flex-column">
      <div
        class="solid-scrollbar card-scene pa-1 fill-height d-flex flex-row justify-start"
        style="width:0;min-width:100%;white-space:nowrap;overflow-x:auto;flex-wrap:nowrap;"
      >
        <!--
                // if (vm.view.groupingIncludeNoDataGroup) {
                unspecifiedColumn
        //   result.push({
        //     id: '',
        //     label: vm.$t('view.unspecified'),
        //     color: null,
        //     backgroundColor: null,
        //     pageNo: 1,
        //     atLastPage: false,
        //     loading: false,
        //     cards: []
        //   })
        // }
                    if (vm.view.groupingIncludeNoDataGroup) {
            result.push({
            id: '',
            label: vm.$t('view.unspecified'),
            color: null,
            backgroundColor: null,
            pageNo: 1,
            atLastPage: false,
            loading: false,
            cards: []
          })
        }


        -->

        <card-board-column
          v-if="view.groupingIncludeNoDataGroup"
          ref="cardBoardColumns"
          :column="unspecifiedColumn"
          :hasAddButton="!isRequired && form.canAdd"
          :view="view"
          :printTemplates="printTemplates"
          :isActive="unspecifiedColumn===hoveringColumn"
          @onHovering="onHoveringHandler"
          @onCommand="onCommandHandler"
          @onCardDrop="onCardDropHandler"
        ></card-board-column>

        <Container
          class="fill-height"
          orientation="horizontal"
          drag-class="position-absolute"
          @drop="onColumnDrop($event)"
          drag-handle-selector=".column-drag-handle"
          @drag-start="dragStart"
          :drop-placeholder="upperDropPlaceholderOptions"
        >
          <Draggable
            v-for="(column, index) in columns"
            :key="column.id"
            class="flex-grow-1 fill-height"
          >
            <card-board-column
              ref="cardBoardColumns"
              :columns="columns"
              :column="column"
              :view="view"
              :printTemplates="printTemplates"
              :hasAddButton="form.canAdd"
              :isActive="column===hoveringColumn"
              @onHovering="onHoveringHandler"
              @onCommand="onCommandHandler"
              @onCardDrop="onCardDropHandler"
            ></card-board-column>
          </Draggable>
        </Container>
      </div>
    </div>
    <popup-record-dialog
      ref="popupRecordDialog"
      @ready="loading=false"
      @onCommand="onCommandHandler"></popup-record-dialog>

    <v-btn fab color="primary" small 
      @click="refresh"
      class="refresh-button">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    <v-overlay v-if="loading">
      <font-awesome-icon 
        icon="spinner"
        class="spinner fa-5x fa-spin"></font-awesome-icon>
    </v-overlay>
  </div>
</template>

<script>
import mixin from "./mixin";
import { Container, Draggable } from "vue-smooth-dnd";
import cardBoardColumn from "./cardBoard/CardBoardColumn";
import cardBoardViewInitialSetup from "./cardBoard/CardBoardViewInitialSetup";
// import recordDialog from "@/components/dialogs/CardViewRecordDialog";
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import { getFieldPropertyValue } from "@/helpers/ObjectHelpers";

export default {
  name: "CardBoardView",
  mixins: [mixin],
  components: {
    Container,
    Draggable,
    cardBoardColumn,
    cardBoardViewInitialSetup,
    // recordDialog,
    popupRecordDialog
  },
  data() {
    return {
      columns: [],
      unspecifiedColumn: null,
      loading: false,
      dragging: false,
      draggingStatusId: "",

      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      cardColors: [
        "azure",
        "beige",
        "bisque",
        "blanchedalmond",
        "burlywood",
        "cornsilk",
        "gainsboro",
        "ghostwhite",
        "ivory",
        "khaki",
      ],
      perLoading: 20,
      filterValue: "",
      keyword: '',
      groupingOptions: [],

      selectedRecordId: "",
      selectedColumn: null,
      hoveringColumn: null,
      // defaultValue: {},
      showDialog: false,
    };
  },
  watch: {
    groupingFieldId: function() {
      const vm = this;
      vm.keyword = ''
      vm.refresh()
      // vm.initGroupingOptions(() => {
      //   vm.refreshAllColumns();
      // });
    },
  },
  mounted() {
    const vm = this;
    vm.$emit("onCommand", {
      command: "viewReady",
      viewName: "cardBoard",
    });
    vm.refresh()
  },
  computed: {
    isSingleSelection() {
      return this.groupingFieldInfo.type === "singleSelection";
    },
    // hasGroupingField () {
    //   return this.groupingFieldInfo
    //   // const vm = this
    //   // var result = false
    //   //
    //   // if (vm.groupingFieldId && vm.groupingFieldId !== '') result = true
    //   // return result
    // },
    groupingFieldId() {
      const vm = this;
      var result = "";
      if (vm.view && vm.view.groupingFieldId) {
        result = vm.view.groupingFieldId;
      }
      return result;
    },
    groupingSeq() {
      const vm = this;
      var result = [];
      if (vm.view && vm.view.groupingSeq) {
        result = vm.view.groupingSeq;
      }
      return result;
    },
    // groupingOptions () {
    //   const vm = this
    //   var result = []
    //   if (vm.groupingFieldInfo) {
    //     const options = vm.groupingFieldInfo.properties['inputOptionsSingle'].options
    //     for (let i = 0; i < vm.groupingSeq.length; i++) {
    //       const loopId = vm.groupingSeq[i]
    //       const option = options.find(item => item.id === loopId)
    //       if (option) {
    //         result.push(option)
    //       }
    //     }
    //     const remainingOptions = options.filter(item => !vm.groupingSeq.includes(item.id))
    //     for (let j = 0; j < remainingOptions.length; j++) {
    //       result.push(remainingOptions[j])
    //     }
    //   }
    //   return result
    // },

    validGroupingFieldInfos() {
      const vm = this;
      var result = [];
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopInfo = vm.fieldInfos[i];
        switch (loopInfo.type) {
          case "singleSelection":
            result.push(loopInfo);
            break;
          case "members":
          case "departments":
            const singleOrMultiple = getFieldPropertyValue(
              loopInfo,
              "fieldType",
              ""
            );
            // console.log(
            //   "members or departments  loopInfo.label = " + loopInfo.label
            // );
            // console.log(
            //   "members or departments  loopInfo.properties.fieldType = " +
            //     loopInfo.properties.fieldType
            // );

            if (singleOrMultiple === "single") {
              result.push(loopInfo);
            }
            break;
          // const
        }
      }
      return result;
    },

    hasValidGroupingFieldInfo() {
      const vm = this;
      var result = false;
      var validGroupingFieldInfoIds = vm.validGroupingFieldInfos.map(
        (info) => info.fieldId
      );
      return validGroupingFieldInfoIds.includes(vm.groupingFieldId);
    },

    groupingFieldInfo() {
      const vm = this;
      var result = null;
      if (vm.groupingFieldId) {
        result = vm.fieldInfos.find(
          (info) => info.fieldId === vm.groupingFieldId
        );
      }
      return result;
    },
    isRequired(){
      if (this.groupingFieldInfo && this.groupingFieldInfo.properties.validation){
        return this.groupingFieldInfo.properties.validation.includes('required')
      } else return false
    }
  },
  methods: {
    onHoveringHandler (column) {
      this.hoveringColumn = column
    },
    getSingleSelectionOptions(callback) {
      const vm = this;
      var result = [];
      const options =
        vm.groupingFieldInfo.properties["inputOptionsSingle"].options;
      for (let i = 0; i < vm.groupingSeq.length; i++) {
        const loopId = vm.groupingSeq[i];
        const option = options.find((item) => item.id === loopId);
        if (option) {
          result.push(option);
        }
      }
      const remainingOptions = options.filter(
        (item) => !vm.groupingSeq.includes(item.id)
      );
      for (let j = 0; j < remainingOptions.length; j++) {
        result.push(remainingOptions[j]);
      }
      vm.groupingOptions = result;
      callback();
    },

    getUsedDepartmentOptions(callback) {
      this.getUsedMemberOptions(callback);
    },

    getUsedMemberOptions(callback) {
      const vm = this;
      const params = {
        appId: vm.currentApp._id,
        formId: vm.form._id,
        viewId: vm.view._id,
        groupingFieldId: vm.groupingFieldId,
      };
      vm.loading = true;
      vm.$store.dispatch("FETCH_DATA_GROUPING", params).then((response) => {
        console.log("getUserMemberOptions :: response: ", response);
        vm.groupingOptions = response;
        vm.loading = false
        callback();
      });
    },


    search(payload) {
      const vm = this;
      vm.keyword = payload
      vm.refresh();
    },
    getColumnById(columnId) {
      const vm = this;
      return columnId === ""
        ? vm.unspecifiedColumn
        : vm.columns.find((col) => col.id === columnId);
    },

    onCommandHandler(payload) {
      const vm = this;
      console.log("CardBoardView :: onCommandHandler :: payload: ", payload);
      var column = null;

      switch (payload.command) {
        case "loadMore":
          column = vm.getColumnById(payload.column.id);
          column.pageNo = column.pageNo + 1;
          vm.loadColumnData(column);
          // console.log("onCommandHandler :: loadMore");
          break;
        case "refreshColumn":
          column = vm.getColumnById(payload.column.id);
          column.pageNo = 1;
          vm.loadColumnData(column);
          break;
        case "newCard":
          this.onNewCard(payload);
          break;
        case 'viewCard':
          this.onViewCard(payload)
          break
        case "editCard":
          this.onEditCard(payload)

          break;
        case "deleteCard":
          const data = {
            recordId: payload.card._id,
            column: payload.column
          }
          this.deleteRecord(data)
          break;
        default:
          this.$emit('onCommand', {...payload}) 
          break;
      }
    },

    onViewCard (payload) {
      const vm = this
      vm.loading = true
      vm.$refs.popupRecordDialog.open({
        recordId: payload.card._id,
        formInfo: vm.form,
        onSubmitted: vm.updateRecord,
        metaData: {
          column: vm.hoveringColumn
        }
      })
    },

    onEditCard (payload) {
      const vm = this
      vm.loading = true
      vm.$refs.popupRecordDialog.open({
        recordId: payload.card._id,
        formInfo: vm.form,
        onSubmitted: vm.updateRecord,
        metaData: {
          column: vm.hoveringColumn
        }
      })
    },

    onEditCard0 (payload) {
      this.selectedRecordId = payload.card._id;
      this.selectedColumn = payload.column
      this.showDialog = true;
    },

    onNewCard(payload) {
      const vm = this
      // prepare default values
      this.selectedRecordId = "";
      this.selectedColumn = payload.column

      let defaultVal = null
      switch(this.groupingFieldInfo.type) {
        case "members":
          defaultVal = [{
            _id: payload.column.id,
            employeeName: payload.column.label,
            teamId: vm.getTeamIdFromGroupingOptions(payload.column.id)
          }]
        break;
        case "departments":
        defaultVal = [{
          _id: payload.column.id,
          label: payload.column.label
        }]            
          break;
        default:
          defaultVal = payload.column.id
          break;
      }

      const defaultValue = {
        [this.view.groupingFieldId]: defaultVal,
      };

      vm.loading = true
      vm.$refs.popupRecordDialog.open({
        recordId: '',
        formInfo: vm.form,
        defaultValues: defaultValue,
        onSubmitted: vm.onCardCreated
      })
//      this.showDialog = true;
    },

    getTeamIdFromGroupingOptions (employeeId) {
      const vm = this
      var result = ''
      console.log('getTeamIdFromGroupingOptions :: employeeID = ' + employeeId)
      console.log('getTeamIdFromGroupingOptions :: groupingOptions: ', vm.groupingOptions)
      const option = vm.groupingOptions.find(option => option._id === employeeId)
      result = option.teamId
      return result
    },

    onCardCreated(newCard) {
      const vm = this;
      const columnId = newCard[vm.groupingFieldId];
      
      console.log('onCardCreated :: columnId = ' + columnId)
      console.log('onCardCreated :: newCard: ', newCard)

      switch (columnId) {
        case "":
          vm.unspecifiedColumn.cards.splice(0, 0, newCard);
          break;
        default:
          const columnIndex = vm.columns.findIndex(
            (col) => col.id === columnId
          );
          if (columnIndex >= 0) {
            vm.columns[columnIndex].cards.splice(0, 0, newCard);
          } else {
            vm.refresh()
          }
      }
      // console.log("onCardCreated :: newCard: ", newCard);
    },
    onCardSaved(updatedCard) {
      const vm = this;
      const columnId = updatedCard[vm.groupingFieldId];
      var cardIndex = 0;
      switch (columnId) {
        case "":
          cardIndex = vm.unspecifiedColumn.cards.findIndex(
            (card) => card._id === updatedCard._id
          );
          vm.unspecifiedColumn.cards[cardIndex] = updatedCard;
          break;
        default:
          const columnIndex = vm.columns.findIndex(
            (col) => JSON.stringify(col.id) === JSON.stringify(columnId)
          );
          cardIndex = vm.columns[columnIndex].cards.findIndex(
            (card) => card._id === updatedCard._id
          );
          vm.columns[columnIndex].cards[cardIndex] = updatedCard;
      }
      // console.log("onCardSaved :: updatedCard: ", updatedCard);
    },
    onConfirmHandler(payload) {
      const vm = this;
      // console.log('cardBoardView :: onConfirmHandler :: payload: ', payload)
      const view = JSON.parse(JSON.stringify(vm.view));
      // console.log('cardBoardView :: onConfirmHandler :: view: ', view)
      view.groupingFieldId = payload;
      vm.$emit("onCommand", {
        command: "updateView",
        view: view,
      });
    },

    refresh () {
      const vm = this
      vm.initGroupingOptions(() => {
        vm.refreshAllColumns();
      });
    },

    initGroupingOptions(callback) {
      const vm = this;
      if (vm.groupingFieldInfo) {
        switch (vm.groupingFieldInfo.type) {
          case "singleSelection":
            vm.getSingleSelectionOptions(callback);
            break;
          case "members":
            vm.getUsedMemberOptions(callback);
            break;
          case "departments":
            vm.getUsedDepartmentOptions(callback);
            break;
        }
      }
    },
    // refresh() {
    //   this.refreshAllColumns();
    // },
    refreshAllColumns() {
      const vm = this;
      vm.configColumns();
      vm.loadColumnData(vm.unspecifiedColumn);
      for (var i = 0; i < vm.columns.length; i++) {
        const loopColumn = vm.columns[i];
        vm.loadColumnData(loopColumn, i);
      }
      // vm.loadColumnData()
      // if (vm.$refs.cardBoardColumns) {
      //   for (var i = 0; i < vm.$refs.cardBoardColumns.length; i++) {
      //     vm.$refs.cardBoardColumns[i].refresh()
      //   }
      // }
    },
    configColumns() {
      const vm = this;
      let result = [];
      vm.unspecifiedColumn = {
        id: "",
        label: vm.$t("view.unspecified"),
        color: null,
        backgroundColor: null,
        pageNo: 1,
        atLastPage: false,
        loading: false,
        cards: [],
      };

      if (vm.groupingFieldInfo) {
        const options = vm.groupingOptions; // vm.groupingFieldInfo.properties['inputOptionsSingle'].options
        for (let i = 0; i < options.length; i++) {

          const loopOption = options[i];
                    if (!loopOption.isDeleted && loopOption.active){
          let colorPairs = ["", ""];
          if (loopOption.colorSet) {
            colorPairs = loopOption.colorSet.split("/");
          }
          result.push({
            id: loopOption._id,
            label: loopOption.label,
            color: colorPairs[0],
            backgroundColor: colorPairs[1],
            pageNo: 1,
            atLastPage: false,
            loading: false,
            cards: [],
          });
          }

        }
      }
      vm.columns = result;
    },
    loadColumnData(column) {
      const vm = this;
      const filter = [];
      var fieldValue = column.id;

      if (!vm.isSingleSelection) {
        fieldValue = fieldValue === "" ? [] : fieldValue;
      }
      // switch (vm.groupingFieldInfo.type) {
      //   case 'members':
      //   case 'departments':x`
      //     fieldValue = [column.id]
      //     break
      // }

      // card board grouping field
      filter.push({
        fieldId: vm.groupingFieldId,
        value: fieldValue,
      });

      // Search field
      // console.log("vm.filterValue: ", vm.filterValue);
      if (vm.filterValue && vm.filterValue.value !== "") {
        filter.push({
          fieldId: vm.filterValue.fieldId,
          value: vm.filterValue.value,
        });
      }

      // console.log("loadColumnData :: column: ", column);
      // console.log("loadColumnData :: filter: ", filter);
      // console.log("loadColumnData :: column: ", column);

      const params = {
        appId: vm.currentApp._id,
        formId: vm.form._id,
        viewId: vm.view._id,
        filter: JSON.stringify(filter),
        currentPage: column.pageNo,
        count: /*column.pageNo * */ vm.perLoading,
      };

      if (vm.keyword){
        Object.assign(params, {
          keyword: vm.keyword
        })
      }

      column.loading = true;
      vm.$store.dispatch("FETCH_DATA_LIST", params).then(
        (response) => {
          // console.log("CardBoardView :: FETCH_DATA_LIST response: ", response);

          if (response.pagination.currentPage === 1) {
            column.cards = response.result;
          } else {
            column.cards = column.cards.concat(response.result);
          }
          // console.log("response: pagination: ", response.pagination);
          // console.log("column.cards.length = " + column.cards.length);
          // console.log(
          //   "response.pagination.totalResult = " +
          //     response.pagination.totalResult
          // );
          column.atLastPage =
            column.cards.length >= response.pagination.totalResult;
          // console.log('1')
          column.loading = false;
          // console.log('2')
        }
        // ,
        // error => {
        //   console.log('error: ', error)
        //   column.loading = false
        // }
      );
      // console.log('3')
    },
    getRandomCardList() {
      const vm = this;
      const cardCount = Math.floor(Math.random() * 8) + 1;
      var result = [];
      for (var i = 0; i < cardCount; i++) {
        result.push({
          id: (100 + i).toString(),
          className: "card",
          style: { backgroundColor: vm.pickColor() },
          data: vm.getRandomLabel(),
        });
      }
      return result;
    },
    getRandomLabel() {
      const vm = this;
      return vm.lorem.slice(0, Math.floor(Math.random() * 150) + 30);
    },
    pickColor() {
      const vm = this;
      const rand = Math.floor(Math.random() * 10);
      return vm.cardColors[rand];
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;

      // console.log("applyDrag :: dragResult: ", dragResult);
      // console.log("applyDrag :: arr: ", arr);

      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },

    generateItems(count, creator) {
      const result = [];
      for (let i = 0; i < count; i++) {
        result.push(creator(i));
      }
      return result;
    },

    onColumnDrop(dropResult) {
      const vm = this;
      // const scene = Object.assign({}, this.scene)
      vm.columns = vm.applyDrag(vm.columns, dropResult);
      // console.log('onColumnDrop :: dropResult: ', dropResult)

      const groupingSeq = vm.columns.map((col) => col.id);
      // console.log("onColumnDrop :: groupingSeq: ", groupingSeq);
      vm.$store
        .dispatch("UPDATE_FORM_VIEW", {
          id: vm.form._id,
          viewId: vm.view._id,
          data: {
            groupingSeq: groupingSeq,
          },
        })
        .then((vm.view.groupingSeq = groupingSeq));

      // this.scene = scene
    },
    onCardDropHandler(payload) {
      const vm = this;
      // console.log("onCardDropHandler :: payload: ", payload);

      const dropResult = payload.dropResult;
      const columnId = payload.columnId;

      // console.log("onCardDropHandler :: columnId = " + columnId);
      // update db
      if (dropResult.addedIndex !== null) {
        const updatedCard = Object.assign({}, dropResult.payload);

        updatedCard[vm.groupingFieldId] = columnId;
        if (!vm.isSingleSelection) {
          if (columnId === "") {
            updatedCard[vm.groupingFieldId] = [];
          } else {
            updatedCard[vm.groupingFieldId] = [{ _id: columnId }];
          }
        }
        vm.$store.dispatch("SAVE_DATA", {data: updatedCard}).then((response) => {
          console.log(
            "CardBoardView :: SAVE_DATA :: updatedCard: ",
            updatedCard
          );
        }).catch(error=>{
          this.$toast.error(this.$t("errors.save"))
        });;
      }

      // console.log("onCardDropHandler :: update_ui");

      // update ui
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        // console.log(
        //   "dropResult.removedIndex not null or addedIndex not null :: columnId = " +
        //     columnId
        // );
        // for unspecified column
        if (columnId === "") {
          console.log("onCardDropHandler :: columnId = " + columnId);
          const newColumn = JSON.parse(JSON.stringify(vm.unspecifiedColumn));
          console.log("onCardDropHandler :: newColumn: ", newColumn);
          newColumn.cards = vm.applyDrag(newColumn.cards, dropResult);
          vm.unspecifiedColumn = newColumn;
        } else {
          const column = vm.columns.filter((p) => p.id === columnId)[0];
          const columnIndex = vm.columns.indexOf(column);
          const newColumn = Object.assign({}, column);
          newColumn.cards = vm.applyDrag(newColumn.cards, dropResult);
          vm.columns.splice(columnIndex, 1, newColumn);
        }
      }
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.columns.filter((p) => p.id === columnId)[0].cards[index];
      };
    },
    dragStart() {
      console.log("drag started");
    },
    deleteRecord(payload){
      this.$store.dispatch('DELETE_FORM_RECORD', {
        appId: this.form.appId,
        formId: this.form._id,
        include: [payload.recordId]
      }).then((res) => {
        if (res.deleted) {
          const index = payload.column.cards.findIndex(card => card._id === payload.recordId);
          if (index > -1) {
            payload.column.cards.splice(index, 1)
          }
        }
      })
    },
    updateRecord(payload, metaData){
      const vm = this
      console.log('CardBoardView :: updateRecord: payload: ', payload);
      const index = metaData.column.cards.findIndex(card => card._id === payload._id);
      if (index > -1) {
        const oldRecord = metaData.column.cards[index]
        if (vm.groupingFieldChanged(oldRecord, payload)) {
          vm.refresh();
        } else {
          metaData.column.cards.splice(index, 1, payload)
        }
      } else {
        metaData.column.cards.push(payload)
      }
    },
    groupingFieldChanged (oldRecord, payload) {
      const vm = this
      const groupingFieldId = vm.groupingFieldInfo.fieldId
      var result = JSON.stringify(payload[groupingFieldId]) !== 
        JSON.stringify(oldRecord[groupingFieldId])
      return result
    },
    updateRecord0(payload){
      console.log('CardBoardView :: updateRecord: payload: ', payload);
      const index = payload.column.cards.findIndex(card => card._id === payload.result._id);
      if (index > -1) {
        payload.column.cards.splice(index, 1, payload.result)
      } else {
        payload.column.cards.push(payload.result)
      }
    }
  },
};
</script>

<style>
/*@import './css/form.css';*/
/*@import './css/header.css';*/

.card-board-view .draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: default;
  user-select: none;
}

.card-board-view .draggable-item-horizontal {
  height: 300px;
  padding: 10px;
  line-height: 100px;
  text-align: center;
  /* width : 200px; */
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 4px;
  cursor: default;
}

.card-board-view .dragging {
  background-color: yellow;
}

.card-board-view .card-scene > div {
  /*border-right: rgba(0, 0, 0, .1) solid 1px;*/
}

.card-board-view .card {
  overflow: hidden;
  white-space: normal;
  margin: 5px;
  /* border: 1px solid #ccc; */
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.card-board-view .card-container {
  width: 320px;
  padding: 10px;
  margin: 5px;
  margin-right: 5px;
  /*background-color: #f3f3f3;*/
  /*background-color: #fffdf3;*/
  background-color: #dbf0fd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
}

.card-board-view .card-column-header {
  font-size: 18px;
}

.card-board-view .column-drag-handle {
  cursor: move;
  padding: 5px;
}

.card-board-view .card-ghost {
  width: 100%;
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-board-view .card-ghost-drop {
  width: 100%;
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.card-board-view .opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  /* transform: rotateZ(5deg); */
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.card-board-view .opacity-ghost-drop {
  opacity: 1;
  /* transform: rotateZ(0deg); */
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}

.card-board-view .form-demo {
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  display: flex;
}

.card-board-view .form {
  flex: 3;
  /* width: 500px; */
  /* background-color: #f3f3f3; */
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08), 0px 3px 3px rgba(0, 0, 0, 0.08);
}

.card-board-view .form-fields-panel {
  flex: 1;
  margin-right: 50px;
}

.card-board-view .form-ghost {
  transition: 0.18s ease;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
}

.card-board-view .form-ghost-drop {
  box-shadow: 0 0 2px 5px rgba(0, 0, 0, 0);
}

.card-board-view .drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 1px;
}

.card-board-view .cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 15px 5px 5px;
}

.card-board-view .smooth-dnd-draggable-wrapper:not(.smooth-dnd-ghost) {
  position: relative !important;
}

.card-board-view .refresh-button {
  position:absolute;
  right:5px;
  top:-20px;
  opacity: 0.4;
}

.card-board-view .refresh-button:hover {
  opacity: 1;
}
</style>
