<template>
  <div class="create-team">
    <Header style="position:fixed;width:100%;z-index:999">
      <slot name="header-content"></slot>
    </Header>
    <div style="margin-top:120px">
      <slot name="prepend-card">
        <h1>{{ title }}</h1>
        <p class="pt-4 mb-10">{{ description }}</p>
      </slot>
        <v-card class="main-card" v-if="card">
            <slot name="card-content"></slot>
        </v-card>
         <slot name="append-card"></slot>
    </div>
  </div>
</template>
<script>
import Header from "./Header.vue"
export default {
  props: {
    title: String,
    description: String,
    card:Boolean
  },
  components:{
      Header
  }
};
</script>
<style lang="scss" scoped>
div.create-team {
  text-align: center;
  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #4e5969;
  }
}

.main-card {
  width: 564px;
  margin: 0 auto 143px auto;
  box-shadow: 0px 10px 10px -5px #0000000a;
  box-shadow: 0px 20px 25px -5px #0000001a;
  padding: 24px;
}
</style>
