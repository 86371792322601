import {getFieldPropertyValue} from "../../helpers/ObjectHelpers";
import {formatNumber} from "../../helpers/DataHelpers"

const mixin = {
  props: {
    fieldInfo: Object,
    recordData: Object,
    useLabel: Boolean,
    textOnly: Boolean,
    recordMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    UNSPECIFIED () {
      return '{' + this.fieldInfo.label + '}'
    },
    label () {
      const vm = this
      if (['createdBy','owner','createdAt','updatedAt'].includes(vm.fieldInfo.type)) {
        return vm.fieldInfo.label
      } else {
        return getFieldPropertyValue(vm.fieldInfo,'fieldName','')
      }
    },
    formattedValue () {
      return this.dataValue ?
        formatNumber(this.dataValue,parseInt(
          getFieldPropertyValue(
            this.fieldInfo, 'decimals', 0))) :
        this.UNSPECIFIED
    },
    dataValue () {
      const vm = this
      var result = null
      if (vm.recordData && vm.fieldInfo) {
        result = vm.recordData[vm.fieldInfo.fieldId]
      }
      return result
    },
    // BLANK_FIELD_NAME () {
    //   const vm = this
    //   var result = '{' + vm.UNSPECIFIED + '}'
    //   if (vm.fieldInfo) {
    //     result = '{' + vm.fieldInfo.label + '}'
    //   }
    //   return result
    // },
    titleValue () {
      const vm = this
      var result = ''
      if (vm.recordData && vm.fieldInfo) {
        result = vm.recordData[vm.fieldInfo.fieldId]
        if (typeof vm.formatValue === 'function') {
          result = vm.formatValue(result)
        }
        if (Array.isArray(result) && result.length === 0) {
          result = vm.UNSPECIFIED
        } else if (!result) {
          result = vm.UNSPECIFIED
        }
      } else if (vm.fieldInfo) {
        result = '{' + vm.fieldInfo.label + '}'
      } else {
        result = '{' + vm.$t('general.unspecified') + '}'
      }
      return result
    }
  },
  methods: {
    getPropertyUseColor (propertyName) {
      const vm = this
      var result = false
      const property = getFieldPropertyValue(
        vm.fieldInfo,
        propertyName,
        null
      )
      if (property) {
        result = property.useColor
      }
      return result
    },
    getPropertyOptions (propertyName) {
      const vm = this
      var result = []
      const property = getFieldPropertyValue(
        vm.fieldInfo,
        propertyName,
        null
      )
      if (property) {
        result = property.options
      }
      return result
    },
    getDataValueAttribute (attributeName) {
      const vm = this
      var result = vm.UNSPECIFIED
      if (vm.dataValue &&
        vm.dataValue.hasOwnProperty(attributeName) &&
        vm.dataValue[attributeName] !== '') {
        result = vm.dataValue[attributeName]
      }
      return result
    }
  }
  // getRecordData (fieldId, index) {
  //   const vm = this
  //   var result = ''
  //   if (vm.recordData) {
  //     result = vm.recordData[fieldId]
  //   } else {
  //     const fieldInfo = vm.fieldInfos.find(info => info.fieldId === fieldId)
  //     if (fieldInfo) {
  //       result = '{' + fieldInfo.label + '}'
  //     }
  //   }
  //   return result
  // },

}

export default mixin
