<template>
  <div>
    <div v-if="isCompleteState(status)" class="workflow-execution-history-status-completed">
      <v-icon small color="#01ca83">mdi-timer</v-icon>
      {{ getInterval(interval) }}
    </div>
    <div v-else-if="isFailState(status)" class="workflow-execution-history-status-failed">
      <v-icon small color="red">mdi-timer-off</v-icon>
      {{ getInterval(interval) }}
    </div>
    <div v-else-if="isWaitingState(status)" class="workflow-execution-history-status-waiting">
      <v-icon small color="#666666">mdi-timer-sand</v-icon>
      {{ $t('workflow.label.waitingNextProcess') }}
    </div>
    <div v-else class="workflow-execution-history-status-running">
      <v-progress-circular size="25" indeterminate color="primary"></v-progress-circular>
      <div class="workflow-execution-history-status-running-label">{{ $t('workflow.label.runningProcess') }}</div>
    </div>
  </div>
</template>

<script>
import TableMixin from './mixin';
export default {
  mixins: [TableMixin],
  props: {
    status: String,
    interval: Number,
  },
};

</script>

<style>

</style>
