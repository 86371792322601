<template>
  <div>
    <PendingSetup
      v-if="!executionDate"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <div class="workflow-shortview-remark">
        <b>{{$t('workflow.modules.timer.label.startDate')}}:</b> {{
          $locale.m(executionDate).format('LLLL')
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import PendingSetup from './../pending';
import Mixin from './../mixin';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  computed: {
    executionDate() {
      const { properties } = this.node;
      return get(properties, 'timer.executionDate', '');
    },
  },
  components: {
    PendingSetup,
  },
};

</script>

<style scoped>
  
</style>
