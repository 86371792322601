const filterHelperMixin = {
  methods: {
    getFilterKeyByFieldInfo (widgetMap, fieldInfo) {
      const vm = this
      var result = null
      if (fieldInfo) {
        var widgetId = fieldInfo.widgetId
        if (widgetMap[widgetId]) {
          result = widgetMap[widgetId].filterType
        }
      }
      return result
    },

  }
}

export default filterHelperMixin
