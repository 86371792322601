<template>
   <div
       :id="item._id===''?'data_new':'data_'+item._id"
       class="handle d-flex flex-row justify-start data-block"
       @mouseover="hovering=item._id!==''"
       @mouseleave="hovering=false">
      <div class="block-prepend d-flex flex-column">
          <div class="block-prepend-top flex-grow-1"></div>
          <div class="block-prepend-bottom flex-grow-1" style="z-index:0;position:relative;">
            <div v-if="!atRootLevel"
              class="unlink-button"
              @click="unlinkItem">X</div>
          </div>          
      </div>
      <div class="d-flex flex-row content-block-wrapper">
          <div class="content-block" :style="contentBlockStyle">
            <div>
<!--            
              <v-btn v-if="hovering" transition="block-fade"
                    x-small class="pa-0 delete-button circle-badge"
                    fab
                    color="error"
                    @click="deleteNode">
                 <v-icon>mdi-close</v-icon>
              </v-btn>
-->              
              <!-- <div v-if="true||hovering&&haveMore"  -->
              <div v-if="hovering&&haveMore" 
                class="action-bar append-block d-flex flex-column align-center">
                <v-btn transition="fade-transition"
                      x-small class="pa-0 add-button circle-badge mb-1"
                      fab
                      color="primary"
                      @click="addChildNode">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn transition="fade-transition"
                      x-small class="pa-0 link-button circle-badge mb-1"
                      fab
                      color="primary"
                      @click="selectChildNode">
                  <v-icon>mdi-link-variant-plus</v-icon>
                </v-btn>
              </div>

              <!-- Child Count -->
              <template v-if="item._id===''">
                <!--                   @blur="onBlur"
 -->
                <v-text-field
                  ref="userInput"
                  dense
                  solo
                  @blur="onBlur"
                  @keydown.esc="onCancel"
                  @keydown.enter="onSubmit"
                  :hide-details="true"
                  outlined></v-text-field>
              </template>
              <template v-else>
                <!-- Parent child count -->
                <div v-if="childCount>0 && childrenCount>0"
                    style="width:24px;height:24px;"
                    class="append-block parent-child-count">
                    <v-chip style="width:100%;height:100%;display:inline-block;cursor:pointer;"
                        @click="toggleChild"
                        :class="{'white--text blue lighten-2':!open,'grey lighten-2':open}"
                        class="text-center pa-0" fab>{{ childCount }}</v-chip>
                </div>
                <!-- child count -->
                <div v-if="childCount>0 && childrenCount===0"
                    style="width:24px;height:24px;"
                    class="append-block child-count">
                    <v-chip style="width:100%;height:100%;display:inline-block;cursor:pointer;"
                        @click="toggleChild"
                        :class="{'white--text blue lighten-2':!open,'grey lighten-2':open}"
                        class="text-center pa-0" fab>{{ childCount }}</v-chip>
                </div>

                <data-block-card
                  :dragging="dragging"
                  :card="item"
                  :titleFieldId="titleFieldId"
                  :fieldInfos="fieldInfos"
                  :cardConfig="cardConfig"
                  @onCommand="onCommandHandler"></data-block-card>

                  <!-- formId: {{ item.formId }}<br/>
                  currentFormId: {{ currentParentChildInfo.currentFormId }}
-->
<div v-if="debugging" class="d-flex flex-column caption line-height-1"
  style="font-family:source code;">
  <div class="d-flex flex-row align-center">
    <div class="flex-grow-1" style="text-overflow:ellipsis;overflow-x:hidden;white-space:nowrap;">
      {{ item._id }}
    </div>
    <div class="flex-grow-0">
      child ({{item.children ? item.children.length : 0 }})
    </div>
  </div>
  <div class="d-flex flex-row align-center">
    <div class="mr-5">
      pFId: ...{{ parentFieldId }}
    </div>
    <div>
      cFId: ...{{ childFieldId }}
    </div>
  </div>
  <div class="d-flex flex-row align-center">
  pIds: {{ currentParentChildInfo ? item[currentParentChildInfo.fieldContainsParentRecordId] : '(no info)'}}
  </div>
  <div class="d-flex flex-row align-center">
  cIds: {{ currentParentChildInfo ? item[currentParentChildInfo.fieldContainsChildRecordIds] : '(no info)'}}
  </div>
  <div class="d-flex flex-row align-center">
  parentCount: {{ parentCount }} root: {{ atRootLevel ? '1' : '0'}}
  parentChildCount: {{ parentChildCount }}
  </div>
                <div style="white-space:nowrap;">
                </div>

</div>

<!--
                </div>-->
              </template>
            </div>

          </div>
      </div>
      <div class="block-append d-flex flex-column">
          <div class="block-append-top flex-grow-1"></div>
          <div class="block-append-bottom flex-grow-1"></div>
      </div>
  </div>
</template>

<script>
import dataBlockCard from './DataBlockCard'
import EventBus from '@/event-bus.js'

export default {
  name: 'dataBlock',
  components: {
    dataBlockCard
  },
  props: {
    dragging: Boolean,
    levelType: String,
    item: Object,
    parent: Object,
    parentChildInfos: {
      type: Array,
      default () {
        return []
      }
    },
    formInfoDict: Object
  },
  data () {
    return {
      show: true,
      hovering: false,
      saving: false
    }
  },
  computed: {
    debugging () {
      return this.$store.getters.debugging
    },
    isMultiple () {
      return this.levelType === 'multipleTables'
    },
    parentFieldId () {
      const vm = this
      var result = ''
      if (vm.currentParentChildInfo && vm.currentParentChildInfo.fieldContainsParentRecordId) {
        result = vm.currentParentChildInfo.fieldContainsParentRecordId.substr(-5)
      }
      return result
    },
    childFieldId () {
      const vm = this
      var result = ''      
      if (vm.currentParentChildInfo && vm.currentParentChildInfo.fieldContainsChildRecordIds) {
        result = vm.currentParentChildInfo.fieldContainsChildRecordIds.substr(-5)
      }
      return result
    },
    currentFormInfo () {
      const vm = this
      var result = null
      if (vm.formInfoDict) {
        result = vm.formInfoDict[vm.item.formId]
      }
      return result
    },
    lastInfo () {
      // console.log('lastInfo :: this.parentChildnfos: ' + JSON.stringify(this.parentChildInfos))
      return this.parentChildInfos[this.parentChildInfos.length-1]
    },
    haveMore () {
      return (this.levelType==='currentTable') // for levelType===currentTable
        ||  (this.lastInfo.currentFormId !== this.item.formId) // for levelType===multipleTables
    },
    issLeaf () {
      // console.log('issLeaf :: this.parentChildnfos: ' + JSON.stringify(this.parentChildInfos))
      return this.parentChildInfos[this.parentChildInfos.length-1].currentFormId === vm.item.formId
    },
    atRootLevel () {
      const vm = this
      return vm.parent === null
    },
    parentChildCount () {
        const vm = this
        var result = 0
        if (vm.currentParentChildInfo) {
          const fieldContainsChildRecordIds = vm.currentParentChildInfo.fieldContainsChildRecordIds

          if (vm.parent && fieldContainsChildRecordIds &&
            Array.isArray(vm.item[fieldContainsChildRecordIds])) {
            result = vm.parent[fieldContainsChildRecordIds] ?
              vm.parent[fieldContainsChildRecordIds].length :
              0
          }
        }
        return result
 
    },
    parentCount () {
        const vm = this
        var result = 0
        if (vm.currentParentChildInfo) {
          const fieldContainsParentRecordIds = vm.currentParentChildInfo.fieldContainsParentRecordIds
          // fieldContainsParentRecordId can be empty if it is not two-way-relation
          // child has no field linking to parent        

          // console.log('item._id = ' + vm.item._id)
          // console.log('vm.item: ', vm.item)
          // console.log('fieldContainsParentRecordIds = ' + fieldContainsParentRecordIds)
          
          // console.log('vm.item[fieldContainsParentRecordIds]: ', vm.item[fieldContainsParentRecordIds])

          if (fieldContainsParentRecordIds) {
            // console.log('fieldContainsParentRecordIds not null')
            if (vm.item[fieldContainsParentRecordIds]) {
              // console.log('vm.item[fieldContainsParentRecordIds] not null')
              if (Array.isArray(vm.item[fieldContainsParentRecordIds])) {
                // console.log('vm.item[fieldContainsParentRecordIds] is array')
                result = vm.item[fieldContainsParentRecordIds].length
              } else {
                // console.log('not array')
              }
            } else {
              // console.log('is null')
            }
          } else {
            // console.log('fieldContainsParentRecordIds is null')
          }
        }
        return result
    },
    contentBlockStyle () {
      const vm = this
      var result = {}
      if (vm.item._id === '') {
        result['min-height'] = 'auto'
      }
      return result
    },
    // itemData () {
    //   const vm = this
    //   var result = null
    //   if (vm.itemsData && vm.item) {
    //     result = vm.itemsData[vm.item._id]
    //   }
    //   return result
    // },
    open () {
      return this.item ?
        this.item.open :
        true
    },
    currentParentChildInfo () {
      // console.log('currentParentChildInfo :: this.parentChildnfos: ' + JSON.stringify(this.parentChildInfos))
      
      const vm = this
      var result = null
      switch (vm.levelType) {
        case 'currentTable':
          result = vm.parentChildInfos.length > 0 ? vm.parentChildInfos[0] : null
          break
        case 'multipleTables':
          const info = vm.parentChildInfos.find(info => info.currentFormId === vm.item.formId)
          result = info
          break
      }
      return result
    },

    // Physically loaded children
    childrenCount () {
      const vm = this
      var result = 0
      if (vm.currentParentChildInfo && vm.item.children) {
        result = vm.item.children.length
      }
      return result
    },

    childCount () {
      // return this.item.children.length
      const vm = this
      var result = 0
      if (vm.currentParentChildInfo && vm.item.children) {
        // console.log('childCount : vm.currentParentChildInfo: ', vm.currentParentChildInfo)
        const fieldId = vm.currentParentChildInfo.fieldContainsChildRecordIds
                                                  
        // console.log('computed(childCount): item #' + vm.item._id + ': childCount :: field (contains child ids): ' + fieldId)
        // console.log('computed(childCount): item #' + vm.item._id + ': vm.item[fieldId]: ', vm.item[fieldId])
        if (vm.item[fieldId] && Array.isArray(vm.item[fieldId])) {        
          console.log("vm.item[fieldId]", vm.item[fieldId])
          result = vm.item[fieldId].length
          // console.log('item has the field and is array  result: ', result)
        }
      }
      return result
    },

    form () {
      // return this.$store.getters.currentForm
      return this.currentFormInfo
    },
    fieldInfos () {
      return this.form ?
        this.form.fieldInfos :
        []
    },    
    titleFieldInfo () {
      return this.form && this.form.titleFieldInfoId && this.fieldInfos ?
        this.fieldInfos.find(info => info._id === this.form.titleFieldInfoId) :
        null
    },
    titleFieldId () {
      return this.titleFieldInfo ? 
        this.titleFieldInfo.fieldId : 
        ''
    },
    cardConfig () {
      return this.currentParentChildInfo ? this.currentParentChildInfo.cardConfig : null
    }
  },
  methods: {
    unlinkItem () {
      // console.log('DataBlock :: unlinkItem')
      // console.log('DataBlock :: unlinkItem parent: ', this.parent)
      // console.log('DataBlock :: unlinkItem item: ', this.item)
      this.$emit('unlinkItem', {
        parent: this.parent,
        item: this.item
      })
    },

    onSubmit (evt) {
      const vm = this
      vm.saving = true
      vm.item.userInput = evt.target.value
      // console.log('onSubmit => checkSubmit')
      EventBus.$emit('submitNewItem', {
        item: vm.item,
        continueInput: true,
        callback: () => {
          vm.saving = false
        }
      })
    },

    onBlur (evt) {
      const vm = this
      if (!vm.saving) {
        vm.item.userInput = evt.target.value
        // console.log('onBlur => checkSubmit')
        EventBus.$emit('submitNewItem', {
          item: vm.item,
          continueInput: true
        })
      }

      // vm.$emit('checkSubmit', {
      //   item: vm.item,
      //   continueInput: false
      // })    
    },
    onCancel (evt) {
      const vm = this
      // console.log('onCancel: evt: ', evt)
      EventBus.$emit('cancelNewItem', {
        item: vm.item
      })
    },

    setFocus (refName) {
      const vm = this
      // console.log('DataBlock.vue :: setFocus :: refName = ' + refName)
      // console.log('DataBlock.vue :: setFocus :: item._id = ' + vm.item._id)
      var result = false
      if (vm.$refs[refName]) {
        const el = vm.$refs[refName].$el
        // console.log('DataBlock :: setFocus :: el: ', el)
        if (el) {
          // console.log('DataBlock :: setFocus found.')
          const input = el.querySelector('input:not([type=hidden])')
          if (input) {
            vm.$nextTick(() => {
              input.focus()
              input.select()
            })
          }
          result = true
        }
      }
      return result
    },
    toggleChild () {
      this.$emit('toggle', !this.open)
    },
    selectChildNode () {
      const vm = this
      // console.log('DataBlock :: addChildNode')
      vm.$emit('click:selectNode', {
        parentChildInfo: vm.currentParentChildInfo,
      })
    },
    addChildNode () {
      const vm = this
      // console.log('DataBlock :: addChildNode')
      vm.$emit('click:newNode', {
        parentChildInfo: vm.currentParentChildInfo,
      })
    },
    onCommandHandler (payload) {
      const vm = this
      console.log('DataBlock :: payload: ', payload)
      switch (payload.command) {
        case 'deleteRecord':
          vm.$emit('click:deleteNode')
          break
        case 'viewRecord':
          vm.$emit('openRecord',  {record: payload.card})
          // EventBus.$emit('viewRecord', {record: payload.card})
          break
      }
    }
  }
}
</script>

<style>
.content-block-wrapper {
  padding: 10px 0;
}

.data-block .content-block {
  border: 1px solid blue;
  background-color: lightskyblue;
  width: 18rem; /* 280px; */
  min-height: 4.8rem;
  padding: 5px;
  position: relative;
}
.content-block h2,
.content-block p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.data-block .content-block .circle-badge {
  background-color: transparent;
}

.data-block:hover .content-block .delete-button,
.data-block:hover .content-block .action-bar {
  display: block;
}

.data-block .content-block .append-block {
  position: absolute;
  top: 2px;
  left: 100%;
  margin-left: 2px;
  display: none;
}

.content-block .delete-button {
  top: auto;
  bottom: 2px;
  left: 100%;
  margin-left: 2px;
  display: none;
}

.content-block .parent-child-count {
  margin: -12px 0 0 30px  !important;
  top: 50% !important;
  left: 100% !important;
  display: block !important;
  z-index: 1 !important;
}
.content-block .child-count {
  margin: -12px 0 0 30px !important;
  top: 50% !important;
  left: 100% !important;
  display: block !important;
}

.block-prepend {
  width: 40px;
}

.block-append {
  width: 40px;
}

.block-prepend-top {
  border: solid 1px transparent;
  width: 100%;
}

.block-prepend-bottom {
  width: 100%;
  border: solid 1px transparent;
  border-top-color: black;
}

.block-append-top {
  border: solid 1px transparent;
  width: 100%;
}

.block-append-bottom {
  width: 100%;
  border: solid 1px transparent;
  border-top-color: black;
}

  /*.bounce-enter-active {*/
    /*animation: bounce-in 2s;*/
  /*}*/

  /*.bounce-leave-active {*/
    /*animation: bounce-in 2s reverse;*/
  /*}*/

/*.bounce-transition {*/
  /*display: inline-block;*/
/*}*/
/*.bounce-enter {*/
  /*animation: bounce-in 2s;*/
/*}*/
/*.bounce-leave {*/
  /*animation: bounce-out 2s;*/
/*}*/

/*@keyframes bounce-in {*/
  /*0% {*/
    /*transform: scale(0);*/
  /*}*/
  /*50% {*/
    /*transform: scale(1.5);*/
  /*}*/
  /*100% {*/
    /*transform: scale(1);*/
  /*}*/
/*}*/
/*@keyframes bounce-out {*/
  /*0% {*/
    /*transform: scale(1);*/
  /*}*/
  /*50% {*/
    /*transform: scale(1.5);*/
  /*}*/
  /*100% {*/
    /*transform: scale(0);*/
  /*}*/
/*}*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.data-block:hover .block-prepend .unlink-button {
  display: inline-block;
}
.data-block .block-prepend .unlink-button {
  position:absolute;
  top:-12px;
  right:5px;
  color:red;
  display: none;
  cursor:pointer;
}
</style>
