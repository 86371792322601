// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';

// import SettingUtils from '@/pages/admin/workflow/utils/setting';
import { get, filter } from 'lodash';
const mixin = {
  computed: {
    email() {
      const { setting } = this;
      return (setting && setting.email) || {};
    },
    recipient() {
      const value = this.email;
      return { recipient: [], ...value, _id: 1 };
    },
    otherForwardRecipient() {
      const value = this.email;
      return value.otherForwardRecipient || [];
    },
    forwardEmailRecipient() {
      const value = this.email;
      return value.forwardEmailRecipient || [];
    },
    forwardRecipient() {
      const value = this.email;
      return { forwardRecipient: [], ...value, _id: 1 };
    },
    otherRecipient() {
      const value = this.email;
      return value.otherRecipient || [];
    },
    emailRecipient() {
      const value = this.email;
      return value.emailRecipient || [];
    },
    replyRecipient() { // user select members
      return {
        ...this.email,
        _id: 1
      };
    },
    replyTypeRecipient() { // user type emails
      const value = this.email;
      return value.replyTypeRecipient || [];
    },
    replyOtherRecipient() { // user select workflow fields
      const value = this.email;
      return value.replyOtherRecipient || [];
    },
    contentType() {
      const value = this.email;
      return value.contentType || 'plain';
    },
    isHtml() {
      return this.contentType === 'html';
    },
    // worksheet() {
    //   const { dataSource, worksheet } = this.setting;
    //   return worksheet || get(dataSource, 'form') || get(dataSource, 'properties.worksheet');
    // },
  },
  created(){
    this.fetchSenders()
  },
  methods: {
    normalizer(list) {
      return filter(list, l => !l.virtual)
    },
    async fetchSenders(){
      const getParams = {
        urlCommand: '/mail/settings',
        options: {}
      }
      await this.$store.dispatch('AUTH_GET', getParams).then(response=>{
        var senders = response
        .map(sender=>{
          return{
            id: sender._id,
            label: sender.configs.profileName
          }
        })
        this.emailSenders = [ {id: 'default', label: this.$t('workflow.modules.email.label.yoovSenderOption')}].concat(senders)
      })
    }
  },
}

export default mixin