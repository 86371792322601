// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
// import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import IconUserProfile from '@/components/icons/userProfile'
import IconMenu from '@/components/icons/menu'
import IconBarChart from '@/components/icons/barChart'
import IconMultipleSelect from '@/components/icons/multipleSelect'
import IconDotHorizontal from '@/components/icons/dotsHorizontal'
import IconCustomizedAction from '@/components/icons/customizedAction'
import IconSorting from '@/components/icons/sorting'
import IconFilter from '@/components/icons/filter'
import IconTtash from '@/components/icons/trash'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas) // Include needed icons
library.add(far)

Vue.use(Vuetify);

import en from '../i18n/vuetify/en'
import zhHans from '../i18n/vuetify/zh-Hans'
import zhHant from '../i18n/vuetify/zh-Hant'

export default new Vuetify({
  theme: {
    disable: false,
    themes: {
      light: {
        // theme colors
        primary: '#39aba7',
        secondary: '#424242',
        accent: '#39aba7',
        error: '#d55b3e',
        info: '#2196F3',
        success: '#52c41a',
        warning: '#ff9900',

        // special colors
        color: {
          system: '#8065C9',
        },
      },
    },
  },
  icons: {
    iconfont: 'faSvg',
    values: {
      userProfile: {
        component: IconUserProfile
      },
      menu: {
        component: IconMenu
      },
      barChart: {
        component: IconBarChart
      },
      multipleSelect: {
        component: IconMultipleSelect
      },
      dotHorizontal: {
        component: IconDotHorizontal
      },
      customizedAction: {
        component: IconCustomizedAction
      },
      sorting: {
        component: IconSorting
      },
      filter: {
        component: IconFilter
      },
      trash: {
        component: IconTtash
      }
    }
  },
  lang: {
    locales: {"zh-Hant":{...zhHant}, en, "zh-Hans":{...zhHans}},
    current: 'zhHant'
  }
});
