<template>
  <v-card class="w-100 border pa-1 pb-0"
          style="border-color:darkgrey;box-shadow:0 2px 1px lightgrey;"
          outlined
          min-height="32"
          max-width="100%"
          min-width="200px"
          :ripple="false"
          :style="{borderColor: hasError ? 'red': ''}"
          @click="showingDialog=true">
    <selected-department-tag
        v-for="(departmentInfo,index) in selectedDepartments"
        :key="departmentInfo._id"
        :index="index"
        :hasCloseButton="true"
        :departmentId="departmentInfo._id"
        :name="departmentInfo.label"
        deleteCommand="removeDepartmentByIndex"
        clickCommand="removeDepartmentByIndex"
        @onCommand="onCommandHandler($event, departmentInfo)"
        color="light"
    ></selected-department-tag>
    <departments-dialog
        v-model="showingDialog"
        @confirm="onSubmit($event)"
        :selectedDepts="selectedDepartments"
        :isMultiple="true"
        hideOverlay
    />
  </v-card>
</template>

<script>
import selectedDepartmentTag from "@/components/dialogs/comps/SelectedDepartmentTag";
import departmentsDialog from "@/components/dialogs/DepartmentsDialog";

export default {
  name: 'multipleDepartmentSelectionDialog',
  components: {
    selectedDepartmentTag,
    departmentsDialog
  },
  model: {
    prop: 'filterValue',
    event: 'input'
  },
  props: {
    filterValue: String,
    fieldInfo: Object
  },
  data () {
    return {
      showingDialog: false,
      hasError: false
    }
  },
  computed: {
    selectedDepartments: {
      get () {
        const vm = this
        var result = []
        if (vm.filterValue && vm.filterValue !== '') {
          result = vm.getDepartmentArrayFromFilterStr(vm.filterValue)
        }
        return result
      },
      set (val) {
        const vm = this
        if (val) {
          if (Array.isArray(val)) {
            const filterValue = vm.getFilterStrFromDepartmentArray(val)
            vm.$emit('input', filterValue)
            this.$nextTick(()=> {
              vm.validate()
            })

          }
        }
      }
    },
    activeTeam () {
      const vm = this;
      var result = null;
      if (vm.allTeamInfos) {
        result = vm.allTeamInfos.find((info) => info._id === vm.selectedTeamId);
      }
      return result;
    },
    allTeamInfos () {
      return this.$store.getters.allTeamInfos;
    }
  },
  methods: {
    validate (state) {
      const vm = this
      vm.hasError = this.selectedDepartments.length === 0
      return !vm.hasError
    },
    getDepartmentArrayFromFilterStr (s) {
      var result = []
      const departmentInfos = s.split('||')
      for (let i = 0; i < departmentInfos.length; i++) {
        const loopInfo = departmentInfos[i]
        const dataItems = loopInfo.split('|')
        // '{employeeId}|{employeeName}|{teamId}|{teamName}'
        result.push({
          _id: dataItems[0],
          label: dataItems[1]
        })
      }
      return result
    },

    getFilterStrFromDepartmentArray (ar) {
      const vm = this
      const strSegs = []
      for (let i = 0; i < ar.length; i++) {
        const loopItem = ar[i]
        const itemStr = loopItem._id + '|' + loopItem.label
        strSegs.push(itemStr)
      }
      return strSegs.join('||')
    },

    onCommandHandler (payload, row) {
      const vm = this
      switch (payload.command) {
        case 'removeDepartmentByIndex':
          vm.removeDepartmentByIndex(payload.index, row)
          break
      }
    },
    onSubmit (departmentInfos) {
      const vm = this
      console.log('MultipleDepartmentSelectionDialog :: departmentInfos: ', departmentInfos)
      vm.selectedDepartments = departmentInfos
      vm.$emit('change')
    },

    removeDepartmentByIndex (index, row) {
      const vm = this
      console.log('removeDepartmentByIndex index = ' + index)
      console.log('removeDepartmentByIndex row: ', row)

      const updatedDepartments = this.selectedDepartments.filter((_, i) => i !== index)
      vm.selectedDepartments = updatedDepartments
    }
  }
}

</script>
