export default {
  dataSources: (state) => {
    return state.dataSources
  },
  editingChildTableField: (state) => {
    return state.editingChildTableField
  },
  editingChildTableInfoId: (state) => {
    return state.editingChildTableInfoId
  },
  editingChildTableInfo: (state) => {
    return state.formFieldInfos.find(fieldInfo => fieldInfo._id === state.editingChildTableInfoId)
  },
  editingChildTableFieldInfoId: (state) => {
    return state.editingChildTableFieldInfoId
  },
  editingChildTableFieldInfo: (state, getters) => {
    var result;
    // console.log('editingChildTableFieldInfo :: getters.editingChildTableInfo: ', getters.editingChildTableInfo)
    // console.log('editingChildTableFieldInfo :: getters.editingChildTableInfoId = ' + getters.editingChildTableInfoId)
    // console.log('editingChildTableFieldInfo :: getters.editingChildTableFieldInfoId = ' + getters.editingChildTableFieldInfoId)
    if (getters.editingChildTableInfo) {
      if (getters.editingChildTableInfo['properties']['fieldsEditor']) {
        const fieldInfos = getters.editingChildTableInfo['properties']['fieldsEditor'].fieldInfos
        // const fixedFieldIds = getters.editingChildTableInfo['properties']['fieldsEditor'].fixedFieldIds
        // const allFields = fields.concat(fixedFields);
        result = fieldInfos.find(info => info.fieldId === state.editingChildTableFieldInfoId);
        // result = allFields.find(element => element.id === state.editingChildTableFieldInfoId);
        // console.log('formConfigs :: fields: ', fields)
      } else {
        console.log('fieldsEditor not found')
      }
    }
    return result
  },
  selectedFormFieldId: (state) => {
    return state.selectedFormFieldId
  },

  formFieldInfos: (state) => {
    return state.formFieldInfos
  },
  formChildTableInfos: (state) => {
    return state.formChildTableInfos
  },
  formUIElementInfos: (state) => {
    return state.formUIElementInfos
  },
  formLayout: (state) => {
    return state.formLayout
  },
  formLayoutItems: (state) => {
    //console.time('formLayoutItems')
    var result = []
    if (state.formLayout) {
      if (state.formLayout.rows.length > 0) {
        result = state.formLayout.rows.flat();
      }
      if (state.formLayout.topPagingTables.length > 0) {
        result = result.concat(state.formLayout.topPagingTables.flat())
      }
      if (state.formLayout.embeddedPagingTables.length > 0) {
        result = result.concat(state.formLayout.embeddedPagingTables.flat())
      }
    }
    //console.timeEnd('formLayoutItems')
    return result
  },
  formInfo: (state) => {
    return state.formInfo
  },
  formOptions: (state) => {
    return state.formOptions
  },

  titleFieldName: (state) => {
    var result = ''
    if (state.formInfo && state.formFieldInfos) {
      const titleFieldInfoId = state.formInfo.titleFieldInfoId
      const fieldInfo = state.formFieldInfos.find(info => info._id === titleFieldInfoId)
      result = fieldInfo ? fieldInfo.properties['fieldName'] : ''
    }
    return result
  },
  parentChildRelationFieldIds: (state) => {
    var result = []
    if (state.formInfo && state.formInfo.linkedFields) {
      for (let i = 0; i < state.formInfo.linkedFields.length; i++) {
        const loopFieldPair = state.formInfo.linkedFields[i].fieldIds
        result = result.concat(loopFieldPair)
      }
    }
    return result
  }
}
