<template>
  <div>
    <label>{{ label }}</label>
    <dialog-field-date
      v-model="propertyValue"
      :allowedDays="allowedDays"
    ></dialog-field-date>
  </div>
</template>

<script>
import mixin from "./mixin";
import dialogFieldDate from "@/components/dialogFields/DialogFieldDate";

export default {
  mixins: [mixin],
  components: {
    dialogFieldDate,
  },
  computed: {
    validations: function() {
      if (this.fieldInfo.properties && this.fieldInfo.properties.validation) {
        return this.fieldInfo.properties.validation.split("||");
      } else return [];
    },
    allowedDays: function() {
      if (this.validations.includes("limitWeekday")) {
        return this.fieldInfo.properties.validation_weekday.split("||");
      }
      else return ["0", "1", "2", "3", "4", "5", "6"];
    },
  },
};
</script>
