// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';

import SettingUtils from '@/pages/admin/workflow/utils/setting';
import { get, filter } from 'lodash';
const mixin = {
  computed: {
    notice() {
      const { setting } = this;
      return (setting && setting.notice) || {};
    },
    employee() {
      const value = this.notice || {};
      return { recipient: [], ...value, _id: 1 };
    },
    otherRecipient() {
      const value = this.notice || {};
      return value.otherRecipient || [];
    },
    phoneRecipient() {
      const value = this.notice || {};
      return value.phoneRecipient || [];
    },
    worksheet() {
      const { dataSource, worksheet } = this.setting;
      return worksheet || get(dataSource, 'form') || get(dataSource, 'properties.worksheet');
    },
  },
  watch: {
    // expression: {
    //   handler() {
    //     const fields = SettingUtils.normalizeFormulaFields(this.fields, this.expression);
    //     this.changed('fields', fields);
    //   },
    //   deep: true
    // },
  },
  method: {
    normalizer(list) {
      return filter(list, l => !l.virtual)
    },
    validation() {
      let { setting } = this;
      let notice = this.notice || {};
      const fields = SettingUtils.expressionContractFields(setting.fields, notice.content);
      setting = { ...setting, fields };
      return setting;
    },
  },
}

export default mixin
