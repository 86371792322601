var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.showTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 employee-chip",class:{'multiple-teams':_vm.withMultipleTeams, 'pr-1':_vm.bl_list},attrs:{"small":"","close":_vm.hasCloseButton,"color":[
          // '630430e7c001cd8e8eb0ab7a', // Public Form User
          '62b29ac9e5965b8574cf6244' ].includes(_vm.employeeId) ?
        _vm.$vuetify.theme.themes.light.color.system :
        _vm.$vuetify.theme.themes.light.primary,"text-color":"white"},on:{"click:close":_vm.onCloseClicked}},'v-chip',attrs,false),on),[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex flex-column line-height-1"},[_c('span',[_vm._v(_vm._s(_vm.displayName)),(_vm.withTitle)?_c('b',[_vm._v(" ("+_vm._s(_vm.displayNameType)+")")]):_vm._e()]),(_vm.showingTeamName)?_c('small',{staticClass:"text-ellipsis",staticStyle:{"color":"rgba(250,250,250,.7)"}},[_vm._v(_vm._s(_vm.teamName))]):_vm._e()])])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.teamName))])])}
var staticRenderFns = []

export { render, staticRenderFns }