import Vue from 'vue'
import * as types from './action_types'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  [types.FETCH_DISPLAY_RULE] (payload) {
  },
  [types.UPDATE_DISPLAY_RULE] ({dispatch}, payload) {
    console.log('UPDATE_DISPLAY_RULE :: payload: ', payload)
    const formId = payload.dataTableId
    const postData = {
        urlCommand: '/displayRule/' + formId,
        data: {
            formId,
            content: payload.content
        },
    };
    this.$store.dispatch("AUTH_POST", postData)
    .then(result => {
       
      })
      .catch(err=> {

      })
  }
}


export default {
  state,
  getters,
  mutations,
  actions
}
