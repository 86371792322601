import { common as types } from "../../action-types";
import helpers from "@/helpers.js";
import Vue from "vue";
import router from "../../../router/index";
import { app } from '@/main';
import { get } from "lodash";

export default {
  [types.AUTH_DELETE]({ rootGetters, dispatch }, payload) {
    const token = rootGetters.accessToken;
    if (typeof payload !== "object") {
      payload = {
        urlCommand: payload,
      };
    }
    if (!payload.options) {
      payload.options = {};
    }
    payload.options.headers = { Authorization: "Bearer " + token };
    if (rootGetters.currentApp)
      payload.options.headers['appId'] = rootGetters.currentApp._id;

    console.log('AUTH_DELETE :: payload: ', payload)
    return dispatch("COMMON_DELETE", payload);
  },

  [types.COMMON_DELETE]({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let urlCommand;
      let options = {};
      if (typeof payload === "object") {
        urlCommand = payload.urlCommand;
      } else {
        urlCommand = payload;
      }

      if (payload.options) {
        options = payload.options;
      }

      const url = rootGetters.apiUrl + urlCommand;
      Vue.axios.delete(url, options).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error.response.data.result);
        }
      );
    });
  },
  [types.REFRESH_AUTH_GET]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('AUTH_GET :: payload: ', payload)
      dispatch("AUTH_REFRESH").then(() => {
        // console.log('AUTH_GET :: payload: ', payload)
        if (typeof payload !== "object") {
          payload = {
            urlCommand: payload,
          };
        }
        if (!payload.options) {
          payload.options = {};
        }
        dispatch("AUTH_GET", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },

  [types.AUTH_GET]({ dispatch, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('AUTH_GET::payload: ', payload)
      const token = rootGetters.accessToken;
      if (typeof payload !== "object") {
        payload = {
          urlCommand: payload,
        };
      }
      if (!payload.options) {
        payload.options = {};
      }
      payload.options.headers = { Authorization: "Bearer " + token };
      if (rootGetters.currentApp)
        payload.options.headers['appId'] = rootGetters.currentApp._id;

      // console.log('BEFORE COMMON_GET: payload: ', payload)
      dispatch("COMMON_GET", payload)
        .then((response) => {
          // console.log('AUTH_GET > COMMON_GET.then: response: ', response)
          resolve(response);
        })
        .catch((error) => {
           if (error.response && error.response.status === 401){
            router.push({
              name: 'errors',
              params: {
                errorCode: error.response.status
              }
            })
           }
        
          reject(error);
        });
    });
  },

  [types.COMMON_GET]({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('COMMON_GET::payload: ', payload)
      // console.log('COMMON_GET :: payload: ', payload)
      let urlCommand;
      let getPayload = {};
      if (typeof payload === "object") {
        urlCommand = payload.urlCommand;
        payload.urlCommand = null;
        if (payload.options) {
          getPayload = {
            ...payload.options,
          };
        }
        if (payload.query) {
          const paginationConfig = helpers.addPagination(payload.query);
          if (!getPayload.params) {
            getPayload.params = {};
          }
          getPayload.params = {
            ...getPayload.params,
            ...paginationConfig.params,
          };
        }
      } else {
        urlCommand = payload;
      }
      // console.log('COMMON_GET :: getPayload: ', getPayload)
      var url = rootGetters.apiUrl + urlCommand;
      if (payload.url) {
        url = payload.url;
      }

      // console.log('COMMON_GET url=' + url)
      // console.log('COMMON_GET config: Vue.axios: ', Vue.axios)
      Vue.axios
        .get(url, getPayload)
        .then((response) => {
          // console.log('COMMON_GET: response: ', response)
          if (response) {
            resolve(response.data);
          }
          reject(response);
          // Error('Get Record Fails! (url=' + url + ')'))
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [types.AUTH_REFRESH]({ rootGetters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('AUTH_REFRESH')
      let url = rootGetters.constants.apiUrl + "/auth/refresh";
      if (rootGetters.accessToken) {
        let options = {
          headers: {
            Authorization: "Bearer " + rootGetters.accessToken,
          },
        };
        Vue.axios
          .post(url, {}, options)
          .then((response) => {
            const accessToken = response.data.result.access_token;
            dispatch("SET_TOKEN", accessToken).then(() => {
              resolve(accessToken);
            });
          })
          .catch((error) => {
            reject(error.response.data);
          });
      } else {
        reject(new Error("no access token"));
      }
    });
  },

  [types.REFRESH_AUTH_POST]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("AUTH_REFRESH").then(() => {
        // console.log('AUTH_GET :: payload: ', payload)
        if (typeof payload !== "object") {
          payload = {
            urlCommand: payload,
          };
        }
        if (!payload.options) {
          payload.options = {};
        }
        dispatch("AUTH_POST", payload)
          .then((response) => {
            console.log("AUTH_POST :: response: ", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
        // payload.options.headers = {Authorization: 'Bearer ' + token}
        // dispatch('COMMON_POST', payload)
        //   .then(response => {
        //     resolve(response)
        //   })
        //   .catch(error => {
        //     reject(error)
        //   })
      });
    });
  },

  [types.AUTH_POST]({ dispatch, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('AUTH_GET :: payload: ', payload)
      if (typeof payload !== "object") {
        payload = {
          urlCommand: payload,
        };
      }
      if (!payload.options) {
        payload.options = {};
      }
      // console.log('AUTH_POST :: payload: ', payload)
      if (payload.options.headers) {
        payload.options.headers = Object.assign(payload.options.headers, {
          Authorization: "Bearer " + rootGetters.accessToken,
        });
      } else {
        payload.options.headers = {
          Authorization: "Bearer " + rootGetters.accessToken,
        };
      }
      if (rootGetters.currentApp)
        payload.options.headers['appId'] = rootGetters.currentApp._id;

      // console.log('common :: AUTH_POST :: payload: ', payload)
      dispatch("COMMON_POST", payload)
        .then((response) => {
          // console.log('COMMON_POST.then response: ', response)
          // console.log('AUTH_POST :: response: ', response)
          resolve(response);
        })
        .catch((error) => {
          if(error.errCode){
            Vue.$toast.error(`${error.errCode}:${error.message}`, {
              duration: 0
            })
          }

          console.log("AUTH_POST error: ", error);
          reject(error);
        });
    });
  },

  [types.AUTH_POST + "0"]({ dispatch, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('AUTH_GET :: payload: ', payload)
      if (typeof payload !== "object") {
        payload = {
          urlCommand: payload,
        };
      }
      if (!payload.options) {
        payload.options = {};
      }
      // console.log('AUTH_POST :: payload: ', payload)
      payload.options.headers = {
        Authorization: "Bearer " + rootGetters.accessToken,
      };
      if (rootGetters.currentApp)
        payload.options.headers['appId'] = rootGetters.currentApp._id;

      dispatch("COMMON_POST", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("AUTH_POST error: ", error);
          reject(error);
        });
    });
  },

  [types.AUTH_UPLOAD_IMAGE]({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('AUTH_GET :: payload: ', payload)
      const url = rootGetters.apiUrl + "/media/upload_image";
      const data = payload;
      const options = {
        headers: {
          Authorization: "Bearer " + rootGetters.accessToken,
        },
      };
      Vue.axios.post(url, data, options).then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  [types.COMMON_POST]({ rootGetters }, payload) {
    // payload = {
    //    data: {..},
    //    options: {
    //      headers: {..}
    //    }
    // }
    return new Promise((resolve, reject) => {
      let urlCommand;
      let data;
      let options = { timeout: 300000 };  // up to 5 mins for timeout

      // get url
      if (typeof payload === "object") {
        urlCommand = payload.urlCommand;
        data = payload.data;
      } else {
        urlCommand = payload;
      }

      if (payload.options) {
        options = payload.options;
      }
      // console.log('COMMON_POST options: ', options)
      var url
      if(urlCommand!==undefined){
        if (payload.type === "auth")
          url = rootGetters.authUrl + urlCommand;
        else
          url  = rootGetters.apiUrl + urlCommand;
      }else
        url = payload.url;
      //      console.log('logout :: url = ' + url)
      //       console.log('COMMON_POST :: data: ', data)
      //       console.log('COMMON_POST :: options: ', options)
      Vue.axios
        .post(url, data, options)
        .then((response) => {
          resolve(response.data);
          //
          // console.log('COMMON_POST axios.then response: ', response)
          // return response.data
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.result) {
              reject(error.response.data.result);
            }
            reject(error.response.data);
          }else {
            reject(error)
          }
        });
    });

  },

  [types.USER_GET]({ rootGetters }, payload) {
    // payload = {
    //    data: {..},
    //    options: {
    //      headers: {..}
    //    }
    // }
    return new Promise((resolve, reject) => {
      let urlCommand;
      let data;
      let options = {};

      // get url
      if (typeof payload === "object") {
        urlCommand = payload.urlCommand;
        data = payload.data;
      } else {
        urlCommand = payload;
      }

      if (payload.options) {
        options = payload.options;
      }
      const url = rootGetters.constants.appHost + urlCommand;
      Vue.axios
        .get(url, data, options)
        .then(function(response) {
          if (response.data.status) {
            resolve(response.data.result);
          } else {
            // console.log('response.data.status = false')
            reject(response.data.result);
            // if (response.data.result.message) {
            //   reject(response.data.result.message)
            // } else {
            //   reject(response.data.result)
            // }
          }
        })
        .catch((error) => {
          if (error.response.data) {
            if (error.response.data.result) {
              reject(error.response.data.result);
            }
          }
          reject(error.response.data);
        });
    });
  },

  [types.REFRESH_AUTH_PUT]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("AUTH_REFRESH").then(() => {
        // console.log('AUTH_GET :: payload: ', payload)
        if (typeof payload !== "object") {
          payload = {
            urlCommand: payload,
          };
        }
        if (!payload.options) {
          payload.options = {};
        }
        dispatch("AUTH_PUT", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },

  [types.AUTH_PUT]({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (typeof payload !== "object") {
        payload = {
          urlCommand: payload,
        };
      }
      if (!payload.options) {
        payload.options = {};
      }
      payload.options.headers = {
        Authorization: "Bearer " + rootGetters.accessToken,
      };
      if (rootGetters.currentApp)
        payload.options.headers['appId'] = rootGetters.currentApp._id;

      dispatch("COMMON_PUT", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [types.PUT] ({}, payload) {
    return new Promise((resolve, reject) => {
      let url = payload.url;
      let data = payload.data;
      let options = payload.options;

      Vue.axios.put(url, data, options).then(
        (response) => {
          if (response.result) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        },
        (error) => {
          reject(error.response);
        }
      );
    });

  },

  [types.COMMON_PUT]({ rootGetters }, payload) {
    // payload = {
    //    urlCommand: {...},
    //    data: {...},
    //    options: {
    //      headers: { ... }
    //    }
    // }
    //
    return new Promise((resolve, reject) => {
      let urlCommand;
      let data;
      let options = { timeout: (+process.env.VUE_APP_APPS_TRANSFER_TIMEOUT || 300000) };  // duplicate concern, increase to 5 mins temp

      // get url
      if (typeof payload === "object") {
        urlCommand = payload.urlCommand;
        data = payload.data;
      } else {
        urlCommand = payload;
      }

      if (payload.options) {
        options = payload.options;
      }
      const url = rootGetters.apiUrl + urlCommand;
      Vue.axios.put(url, data, options).then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error.response);
        }
      );
    });
  },
  [types.SAVE_LOCAL_STORAGE]({ dispatch }) {
    return new Promise((resolve, reject) => {
      const data = JSON.parse(
        localStorage.getItem("VXE_TABLE_CUSTOM_COLUMN_WIDTH")
      );
      const postData = {
        urlCommand: `/users/update-view-preference`,
        data: data,
      };
      if (data !== null) {
        dispatch(types.AUTH_POST, postData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
  [types.FETCH_LOCAL_STORAGE]({ dispatch }) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: `/users/get-view-preference`,
      };
      dispatch(types.AUTH_GET, getData)
        .then((res) => {
          if (res && res.views) {
            localStorage.setItem('VXE_TABLE_CUSTOM_COLUMN_WIDTH', JSON.stringify(res.views));
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [types.SAVE_PAGE_SIZE]({ dispatch }, payload){
    return new Promise((resolve, reject) => {
      const postData = {
        urlCommand: '/users/update-page-size-preference/' + payload.key,
        data: {
          value: payload.value
        }
      };
      dispatch(types.AUTH_POST, postData)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });    
  },
  [types.FETCH_PAGE_SIZE]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const getData = {
        urlCommand: '/users/get-page-size-preference/' + payload.key
      };
      dispatch(types.AUTH_GET, getData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [types.TRACK_WITH_APPID_FORMID]({ rootGetters }, { key, data }){
   const appId =  get(rootGetters, "currentApp._id");
   const formId =  get(rootGetters, "currentForm._id")
    if (appId && formId) {
      app.$sensors.track(key, {
        app_id: appId,
        form_id: formId,
        ...data
      });
    }
  }
};
