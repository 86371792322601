<template>
  <v-container fluid>
    <v-row no-gutters class="grey lighten-2">
      <v-col cols="10" class="pa-0 ma-0">
        <v-row no-gutters class="pa-1">
          <v-col
            v-for="header in headers"
            :key="header.label"
            :cols="header.cols"
          >
            {{ header.label }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row no-gutters class="pa-1">
        {{$t('roles.permission')}}
        </v-row>
      </v-col>
    </v-row>
    <calendar-permission-table-row
      v-for="[calendarId, calendar] in Object.entries(calendars)"
      :key="calendarId"
      :calendarId="calendarId"
      :calendar="calendar"
      @onCommand="onCommandHandler"
    />
  </v-container>
</template>
<script>
import CalendarPermissionTableRow from "./CalendarPermissionTableRow.vue";
export default {
  components: { CalendarPermissionTableRow },
  props: {
    calendars: Array,
  },

  data() {
    return {
      headers: [
        {
          label: this.$t('roles.calendar'),
          cols: 6,
        },
        {
          label: this.$t('roles.view'),
          cols: 2,
        },
        {
          label: '', // this.$t('roles.edit'),
          cols: 2,
        },
        {          
          label: '',
          cols: 2,
        },
      ],
    };
  },

  methods: {
    onCommandHandler(payload) {
      console.log('CalendarPermissionTable.onCommandHandler: payload: ', payload);
      const calendar = this.calendars[payload.calendarId];      
      switch (payload.command) {
        case 'setReadCalendar':
          if (calendar) {
            calendar.read = payload.value;    
            console.log('CalendarPermissionTable.onCommandHandler: this.calendars: ', this.calendars);
          }
          break;
        case 'setEditCalendar':
          if (calendar) {
            calendar.edit = payload.value;
            console.log('CalendarPermissionTable.onCommandHandler: this.calendars: ', this.calendars);
          }
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cell-flex {
  flex: 1 0;
  overflow: hidden;
}

.bottom-border {
  border-bottom: 1px solid #9e9e9e;
}
</style>
