var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.bl_newRuleDialog),callback:function ($$v) {_vm.bl_newRuleDialog=$$v},expression:"bl_newRuleDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"primary white--text px-3 py-1",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.$t("displayRule.addNewRule")))]),_c('perfect-scrollbar',{staticClass:"flex-grow-1",staticStyle:{"height":"500px"}},[_c('v-card-text',{staticClass:"py-6 px-8",staticStyle:{"color":"#414141"}},[_c('div',{staticClass:"d-flex align-start",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"font-weight-bold",staticStyle:{"width":"100px","font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t("displayRule.RuleName"))+" ")]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"250px"}},[_c('v-form',{ref:"ruleName"},[_c('v-text-field',{staticClass:"d-inline-block rounded-0",staticStyle:{"font-size":"12px","width":"200px"},attrs:{"placeholder":_vm.$t('messages.pleaseInput', []),"rules":[
                    function (value) { return !!value || _vm.$t('displayRule.ruleNameRequired'); } ],"tile":"","height":"20","outlined":"","dense":""},model:{value:(_vm.ruleName),callback:function ($$v) {_vm.ruleName=$$v},expression:"ruleName"}})],1)],1)]),_c('v-divider',{staticClass:"mb-5 mt-2"}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.$t("displayRule.FulfillConditions")))]),(_vm.is_noCond)?_c('span',{staticClass:"ml-5 error--text",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t("displayRule.missingCondition")))]):_vm._e()]),_c('v-container',{staticClass:"mt-5 mx-3"},[_c('v-row',{staticStyle:{"font-size":"13px"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(!_vm.conditions.length)?_c('v-row',[_c('v-col',{staticClass:"py-0 mt-1 px-0",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","rounded":"","color":"info","outlined":""}},on),[_vm._v(" "+_vm._s(_vm.$t("displayRule.setCondition"))+" ")])]}}],null,false,3141689873)},[_c('v-card',{staticClass:"pa-3",staticStyle:{"max-height":"250px","overflow":"scroll"},attrs:{"width":"200","tile":"","outlined":""}},[_c('v-list',{staticClass:"pa-0 align-center",attrs:{"dense":""}},_vm._l((_vm.filteredFormFieldInfos),function(item,index){return _c('v-list-item',{key:item._id,staticClass:"pa-0 py-1",staticStyle:{"min-height":"20px","font-size":"12px"},style:(index != _vm.filteredFormFieldInfos.length - 1
                                  ? 'border-bottom: 1px solid #eeee'
                                  : ''),attrs:{"dense":"","ripple":true},on:{"click":function($event){return _vm.addCond(item)}}},[_c('font-awesome-icon',{staticClass:"fa-fw mr-2",attrs:{"icon":_vm.getWidget(item.widgetId).icon}}),_c('span',{},[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1)],1),_c('v-col',{staticClass:"py-0 mt-1 px-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t("displayRule.description")))])])],1):_c('v-row',{staticStyle:{"font-size":"13px"}},[_c('v-col',{staticClass:"pr-8",attrs:{"cols":"12"}},_vm._l((_vm.conditions),function(items,index){return _c('v-row',{key:index},[_vm._l((items),function(cond,index2){return _c('v-col',{key:index2,staticClass:"py-0 start-node-drawer-filter",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var hover = ref.hover;
return [_c('div',{staticClass:"d-flex flex-column pl-4",class:hover ? 'grey lighten-4' : '',staticStyle:{"border-left":"#2296f3 1px solid"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticStyle:{"height":"17px","width":"17px","position":"absolute","right":"50px","transform":"translate(5px,-7px)"},attrs:{"small":"","color":"error","fab":""}},[_c('v-icon',{staticClass:"start-node-drawer-icon-bin",attrs:{"size":"13","fab":""},on:{"click":function($event){return _vm.deleteCond(cond.id, index, index2)}}},[_vm._v(" mdi-close ")])],1),_c('div',{staticClass:"pl-3 pt-2"},[_vm._v(" "+_vm._s(_vm.getFieldById(cond.fieldType, cond.fieldId) .label)+" ")]),_c('div',{staticClass:"d-flex align-center pb-3 px-3"},[_c('div',{staticClass:"flex-grow-1"},[_c(_vm.getFieldTypeComponent(
                                        cond.fieldType,
                                        cond.fieldId
                                      ),{ref:"conditions",refInFor:true,tag:"component",attrs:{"content":cond.content,"fieldType":cond.fieldType,"formFieldInfos":_vm.formFieldInfos,"rules":_vm.getFieldRules(cond.fieldId),"fieldProperties":_vm.getFieldById(
                                        cond.fieldType,
                                        cond.fieldId
                                      ).properties,"formInfo":_vm.getRelatedTableInfo(
                                        cond.fieldType,
                                        cond.fieldId
                                      ),"types":_vm.types},on:{"updateType":function($event){return _vm.$set(cond.content, 'type', $event)},"updateRule":function($event){return _vm.$set(cond.content, 'rule', $event)},"updateValue":function($event){return _vm.$set(cond.content, 'value', $event)}}})],1)])],1)]}}],null,true)}),(index2 != items.length - 1)?_c('v-btn',{staticClass:"white--text info",staticStyle:{"height":"25px","width":"25px","font-size":"13px","left":"-12px"},attrs:{"fab":"","outlined":""},on:{"click":function($event){return _vm.toOrHandler(index, index2)}}},[_vm._v(" "+_vm._s(_vm.$t("displayRule.and"))+" ")]):_vm._e(),(index2 == items.length - 1)?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline py-2 px-3 ml-5",staticStyle:{"cursor":"pointer"}},on),[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("+ "+_vm._s(_vm.$t("displayRule.addCondition")))])])]}}],null,true)},[_c('v-card',{staticClass:"pa-3",staticStyle:{"max-height":"250px","overflow":"scroll"},attrs:{"outlined":"","width":"200"}},[_c('v-list',{staticClass:"pa-0 align-center",attrs:{"dense":""}},_vm._l((_vm.filteredFormFieldInfos),function(item){return _c('v-list-item',{key:item._id,staticClass:"pa-0 py-1",staticStyle:{"min-height":"20px","font-size":"12px"},style:(index !=
                                      _vm.filteredFormFieldInfos.length - 1
                                        ? 'border-bottom: 1px solid #eeee'
                                        : ''),attrs:{"dense":"","ripple":true},on:{"click":function($event){return _vm.addCond(item, index)}}},[_c('font-awesome-icon',{staticClass:"fa-fw mr-2",attrs:{"icon":_vm.getWidget(item.widgetId).icon}}),_c('span',{},[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1)],1):_vm._e()],1)}),(index != _vm.conditions.length - 1)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"flex-grow-1",staticStyle:{"border-top":"1px solid #d9d9d9"}}),_c('div',{},[_c('div',{staticClass:"px-4 mt-1",staticStyle:{"font-size":"14px","cursor":"pointer"},on:{"click":function($event){return _vm.toAndHandler(index)}}},[_c('v-btn',{staticClass:"warning white--text",staticStyle:{"width":"25px","height":"25px","font-size":"13px"},attrs:{"outlined":"","fab":""}},[_vm._v(_vm._s(_vm.$t("displayRule.or")))])],1)]),_c('div',{staticClass:"flex-grow-1",staticStyle:{"border-top":"1px solid #d9d9d9"}})]):_vm._e()],2)}),1)],1)],1)],1)],1)],1),_c('div',{staticClass:"mt-12"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.$t("displayRule.takeActions")))]),(_vm.is_noAction)?_c('span',{staticClass:"ml-5 error--text",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t("displayRule.missingAction")))]):_vm._e(),_c('div',{staticClass:"mt-2 px-4"},_vm._l((_vm.actions),function(action,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var hover = ref.hover;
return [_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticStyle:{"height":"17px","width":"17px","position":"absolute","right":"50px","transform":"translate(10px,-7px)"},attrs:{"small":"","color":"error","fab":""}},[_c('v-icon',{staticClass:"start-node-drawer-icon-bin",attrs:{"size":"13","fab":""},on:{"click":function($event){return _vm.deleteAction(index)}}},[_vm._v(" mdi-close ")])],1),_c('div',{staticClass:"d-flex align-center py-3 px-4",class:hover ? 'grey lighten-4' : '',staticStyle:{"cursor":"pointer"}},[_c('ActionSelectField',{attrs:{"action":action,"formFieldInfos":_vm.formFieldInfos,"actionFields":action.fields,"actionId":action.actionId,"actionValue":action.value,"formUIElementInfos":_vm.formUIElementInfos,"types":_vm.types},on:{"updateAction":function($event){return _vm.$set(action, 'actionId', $event)},"updateActionFields":function($event){return _vm.$set(action, 'fields', $event)},"updateActionValue":function($event){return _vm.$set(action, 'value', $event)}}})],1)],1)]}}],null,true)})}),1),(!_vm.actions.length)?_c('v-btn',{staticClass:"d-block mt-2 ml-3",staticStyle:{"padding":"0px !important","border":"1px #bbbb dashed","width":"100px","border-radius":"8px"},attrs:{"small":"","text":""},on:{"click":function($event){return _vm.addAction()}}},[_c('v-icon',{staticStyle:{"min-width":"10px","width":"10px","min-height":"10px","height":"10px"},attrs:{"size":"15","color":"#bbbb"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"ml-1",staticStyle:{"color":"#bbbb","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("displayRule.addAction"))+" ")])],1):_c('div',{staticClass:"ml-5 d-inline py-2 px-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.addAction()}}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("+ "+_vm._s(_vm.$t("displayRule.addAction")))])])],1)],1)],1),_c('dialog-actions',{attrs:{"buttons":['close', 'save']},on:{"onCommand":_vm.onCommandHandler}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }