<template>
  <v-card width="100%" flat tile>
    <v-card-title class="elevation-4" v-if="!expired">
      {{
        $t("printTemplate.previewTitle", {
          name: info.templateName,
        })
      }}
      <v-spacer />
      <slot name="toolbar-control"></slot>
    </v-card-title>
    <v-card-text class="fill-height">
      <div v-if="expired" class="fill-height printDocxContainer">
        <div class="downloadWrapper">
          <v-icon size="128" color="error">mdi-information-outline</v-icon>
          <p>{{ $t("printTemplate.cacheExpired") }}</p>
        </div>
      </div>
      <div class="fill-height printDocxContainer" v-else-if="isWaiting">
        <div>
          <v-icon size="128" color="primary">mdi-file-document-outline</v-icon>
          <p>{{ $t("printTemplate.generating") }}</p>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
      </div>
      <div class="fill-height printDocxContainer" v-else-if="pdfUrl">
        <div class="downloadWrapper">
          <v-icon size="128" color="primary">mdi-file-document</v-icon>
          <p>{{ $t("printTemplate.generated") }}</p>
          <v-btn
            v-if="docxUrl"
            block
            depressed
            outlined
            color="primary"
            width="250"
            @click="downloadAttachment($event, docxUrl, `${fileName}.docx`)"
            >{{ $t("buttons.download") }} Docx</v-btn
          >
          <v-btn
            block
            depressed
            outlined
            color="primary"
            class="mt-1"
            width="250"
            :disabled="loading"
            @click="downloadAttachment($event, pdfUrl, `${fileName}.pdf`)"
          >
            <span v-if="loading"
              ><v-progress-circular indeterminate></v-progress-circular
            ></span>
            <span v-else>{{ $t("buttons.download") }} PDF</span>
          </v-btn>
        </div>
      </div>

      <div class="fill-height printDocxContainer" v-else>
        <div class="downloadWrapper">
          <v-icon size="128" color="error">mdi-information-outline</v-icon>
          <p>{{ $t("printTemplate.printDocxError") }}</p>
          <v-btn block depressed outlined color="primary" @click="getDocx">{{
            $t("printTemplate.retry")
          }}</v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    infoFromProps: Object,
  },
  data() {
    return {
      info: {
        appId: "",
        templateId: "",
        templateName: "",
        formId: "",
        recordId: "",
        viewId: "",
      },
      expired: false,
      isWaiting: true,
      loading: false,

      mediaId: "",
      fileName: "",
      docxUrl: "",
      pdfUrl: "",
    };
  },
  async mounted() {
    if (this.infoFromProps) {
      Object.assign(this.info, {
        ...this.info,
        ...this.infoFromProps,
      });
    } else {
      const cacheId = this.$route.params.id;
      const result = await this.$store.dispatch("GET_DOCX_CACHE", {
        type: 0,
        id: cacheId,
      });

      if (result) {
        Object.assign(this.info, {
          ...this.info,
          ...result,
        });
      } else this.expired = true;
    }
    if (!this.expired) {
      await this.getDocx();
    }
  },
  methods: {
    async getDocx() {
      this.isWaiting = true;
      const postData = {
        urlCommand: "/printTemplate/printDocx",
        data: {
          ...this.info,
        },
      };
      try {
        const res = await this.$store.dispatch("AUTH_POST", postData);
        if (res) {
          this.docxUrl = res.docxDestination;
          this.pdfUrl = res.pdfDestination;
          this.fileName = res.documentName;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.isWaiting = false;
      }
    },
    downloadAttachment(event, url, fileName) {
      event.preventDefault();
      let ajax = new XMLHttpRequest();
      ajax.open("GET", url, true);
      ajax.responseType = "blob";
      ajax.onload = function (e) {
        if (url) {
          const url = window.URL.createObjectURL(
            new Blob([e.target.response])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName)
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
        }
      };
      setTimeout(function () {
        ajax.send();
      }, 0); // allows setting custom ajax headers using the return:
      return ajax;
    },
  },
};
</script>
<style scoped>
.printDocxContainer {
  display: grid;
  text-align: center;
  place-items: center;
  /* text-align: center;
  text-align: -webkit-center;
  align-items: center;
  padding-top: 150px; */
}
</style>
