<template>
  <div slot-scope="{ info }" class="workflow-setting-form">
    <div v-if="!loading && isDependenciesFetched">
      <div class="workflow-setting-form-root">
        <component :is="lookup[node.type]" ref="setting" />
      </div>
      <v-layout align-center
        align-start
        v-if="show"
        class="workflow-setting-form-footer"
      >
        <v-btn elevation="0" color="primary" :disabled="loading || !info.canSubmit" class="workflow-setting-form-btn workflow-setting-form-btn-submit" @click="onSave">
          {{ $t('workflow.btn.save') }}
        </v-btn>
        <v-btn elevation="0" color="primary" :disabled="loading" class="workflow-setting-form-btn" outlined @click="toggle">
          {{ $t('workflow.btn.cancel') }}
        </v-btn>
      </v-layout>
    </div>
    <v-progress-linear
      v-else
      indeterminate
      color="primary"
      class="w-setting-form-top-loading"
    ></v-progress-linear>
  </div>
</template>

<script>
import { get } from 'lodash';
import FormSettingView from './form';
import TimerSettingView from './timer/';
import DateSettingView from './date/';
import ConditionSettingView from './condition';
import CreateRecordView from './createRecord/';
import DeleteRecordView from './deleteRecord/';
import UpdateRecordView from './updateRecord/';
import EmailNotificationView from './emailNotification';
import WhatsappNotificationView from './whatsappNotification';
import SMSNotificationView from './smsNotification';
import RetrieveRecordView from './retrieveRecord/';
import OperationView from './operation/';
import ChildProcessView from './childProcess';
import SubWorkflowView from './subWorkflow/';
import ActionProcessView from './actionProcess';
import UpdateArgumentView from './updateArgument';
import InBrowserNotificationView from './inBrowserNotification';
import GenerateFile from "./generateFile";
import Mixin from './mixin';
// import SettingConsumer from './contexts/consumer';
import {
  EDITING_NODE,
  FORM_SELECTIONS,
  FETCH_WORKFLOW_NODE_INFO,
  UPDATE_WORKFLOW_EDITING_NODE,
  // REPLACE_WORKFLOW_DEPENDENCIES,
  FETCH_WORKFLOW_DEPENDENCIES,
  VALIDATION_UPDATE_EDIT_NODE,
} from '@/store/modules/workflow/action_types';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
// import WorkflowNodeUtils from '@/pages/admin/workflow/utils/node';
/*
* Target Module Forms Setting Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  inject: ['saveSetting', 'info'],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false,
      validating: false,
      lookup: {
        timer: 'TimerSettingView',
        date: 'DateSettingView', 
        form: 'FormSettingView',
        branch: 'ConditionSettingView',
        createRecord: 'CreateRecordView',
        deleteRecord: 'DeleteRecordView',
        updateRecord: 'UpdateRecordView',
        retrieveRecord: 'RetrieveRecordView',
        operation: 'OperationView',
        subWorkflow: 'SubWorkflowView',
        updateArgument: 'UpdateArgumentView',
        childProcess: 'ChildProcessView',
        actionProcess: 'ActionProcessView',
        inBrowserNotification: 'InBrowserNotificationView',
        smsNotification: 'SMSNotificationView',
        whatsappNotification: 'WhatsappNotificationView',
        email: 'EmailNotificationView',
        generateFile:"GenerateFile"
      },
      isDependenciesFetched: false
    }
  },
  computed: {
    loading() {
      return this.validating || this.$store.getters.fetchingNode || this.$store.getters.fetchedFormSelections;
    },

  },
  methods: {
    toggle() {
      this.$store.dispatch(EDITING_NODE, null);
    },
    track(node){
      const formId = get(node, "properties.worksheet._id")
      const trackInfo = {
          app_id:node.appId,
          workflow_component_type:node.type,
        }
      if (formId){
        trackInfo["form_id"] = formId
      }
      this.$sensors.track("plus_workflow_edit", trackInfo);
    },
    async onSave() {
      let { setting } = this;
      if (this.$refs.setting.validation) {
        this.validating = true;
        setting = this.$refs.setting.validation();
      }
      this.validating = false;
      this.$set(this.node, 'properties', setting);
      const info = await this.saveSetting(this.node, this.workflow, setting);
      if (info) {
        this.$set(this.node, 'properties', info.properties);
        this.track(this.node);
        this.$store.dispatch(UPDATE_WORKFLOW_EDITING_NODE, info);
        this.$store.dispatch(VALIDATION_UPDATE_EDIT_NODE);
        
      }
    },
    fetch() {
      this.info.canSubmit = true;
      const { _id: workflow } = this.workflow;
      const form = get(this.node, 'properties.worksheet._id');
      this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES,  {
        workflow,
        form,
        appId: this.appId,
        node: this.node._id
      }).then(response=>{
        this.isDependenciesFetched = true
      });
      const { id } = this.$route.params;
      this.$store.dispatch(FORM_SELECTIONS, id);
      // load the node info
      if (this.node && this.node._id) {
        this.$store.dispatch(FETCH_WORKFLOW_NODE_INFO, { workflow, node: this.node._id });
      }
    },
  },
  components: {
    FormSettingView,
    ConditionSettingView,
    CreateRecordView,
    DeleteRecordView,
    UpdateRecordView,
    RetrieveRecordView,
    OperationView,
    SubWorkflowView,
    ActionProcessView,
    ChildProcessView,
    UpdateArgumentView,
    InBrowserNotificationView,
    WhatsappNotificationView,
    SMSNotificationView,
    TimerSettingView,
    DateSettingView,
    EmailNotificationView,
    GenerateFile
  },

  created() {
    this.fetch();
  },
  watch: {
    node: {
      handler(nextNode, currentNode) {
        // once node change
        if (nextNode && currentNode && currentNode._id !== nextNode._id) {
          this.fetch();
        }
      },
      deep: true,
    },
  }
};

</script>

<style scoped>
  .w-setting-form-top-loading {
    width: 110%;
  }
  .wsettingFade-enter-active, .wsettingFade-leave-active {
    transition: opacity 6s;
  }
  .wsettingFade-enter, .wsettingFade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
