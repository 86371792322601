<template>
  <v-layout align-center justify-space-between class="workform-clip-dropdown-option">
    <div class="workform-clip-dropdown-source">
      <v-icon v-if="icon" small class="workform-clip-dropdown-icon">
        {{ icon }}
      </v-icon>
      <span v-if="isBranch" class="workform-clip-dropdown-branch-label">
        {{ item.event }}
      </span>
      
      <span v-else class="workform-clip-dropdown-label">
        <span class="workform-clip-dropdown-label-prefix">
          <v-chip v-if="!!item.fieldType" small class="workform-clip-dropdown-chip">
            {{ $t(`workflow.widgets.${item.fieldType}`) }}
          </v-chip>
        </span> {{ item.virtual ? $t(item.label) : item.label }}
      </span>
      <!-- <span class="workform-clip-dropdown-count" v-if="shouldShowCount">
        ({{ count }})
      </span> -->
      <span class="workform-clip-dropdown-output-count" v-if="shouldShowOutputNumber && !!item.output">
        [{{ $t(`workflow.label.output.${item.output}`) }}]
      </span>
    </div>
    <div v-if="(isBranch || relationship) && !item.virtual" class="workform-clip-dropdown-table-label">
      {{ $t('workflow.label.table') }} {{ tableName }}
    </div>
    <div v-if="childTable" class="workform-clip-dropdown-table-label">
      {{ $t('workflow.label.childTable') }}
    </div>
  </v-layout>
</template>

<script>

import SettingUtils from "@/pages/admin/workflow/utils/setting";
export default {
  props: {
    item: Object,
    prefixIcon: Boolean,
    shouldShowCount: Boolean,
    count: Number,
    shouldShowOutputNumber: Boolean,
    isBranch: Boolean,
    renderLabel: Function,
    involveRelationshipLabel: Boolean,
  },
  computed: {
    icon() {
      return this.item.icon;
      // const { eventType } = this.item;
      // return eventType === 'eventset' ? 'mdi-sitemap' : 'mdi-truck-delivery';
    },
    relationship() {
      const { properties } = this.item;
      return (this.involveRelationshipLabel && 
        
        SettingUtils.relationshipFields(this.item) && 
        properties && 
        properties.dataSource
      ) || this.item.fullRelationOutput;  // the output is the relationship itself
    },
    tableName() {
      const { table, properties, fullRelationOutput } = this.item; 
      if (this.relationship && !fullRelationOutput) {
        return properties.dataSource.dataTableName;
      } else if (fullRelationOutput) {
        return this.item.table;
      }
      return table;
    },
    childTable() {
      const { properties } = this.item;
      return (this.involveRelationshipLabel && 
        SettingUtils.relationshipFields(this.item) && 
        properties && 
        properties.fieldsEditor
      );
    },
  },
}
</script>

<style>
</style>
