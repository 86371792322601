var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-0 d-flex pl-2",attrs:{"hide-actions":""}},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t('chart.styleConfig.xAxis'))+" ")])]),_c('v-switch',{staticStyle:{"position":"absolute","right":"0px","top":"-7px"},attrs:{"dense":"","inset":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
            command: 'update_bl_xAxis',
            value: $event
        })}},model:{value:(_vm.bl_xAxis),callback:function ($$v) {_vm.bl_xAxis=$$v},expression:"bl_xAxis"}}),_c('v-expansion-panel-content',{staticClass:"px-0"},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"ripple":false,"dense":"","hide-details":"","label":_vm.$t('chart.showScaleLabel')},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_bl_xLabel',
                value: $event
            })}},model:{value:(_vm.setup.bl_xLabel),callback:function ($$v) {_vm.$set(_vm.setup, "bl_xLabel", $$v)},expression:"setup.bl_xLabel"}}),_c('v-checkbox',{staticClass:"ma-0",attrs:{"ripple":false,"dense":"","hide-details":"","label":_vm.$t('chart.xAutoTilt')},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                command: 'update_bl_xRotate',
                value: $event
            })}},model:{value:(_vm.setup.bl_xRotate),callback:function ($$v) {_vm.$set(_vm.setup, "bl_xRotate", $$v)},expression:"setup.bl_xRotate"}}),_c('v-checkbox',{staticClass:"ma-0",attrs:{"ripple":false,"dense":"","hide-details":"","label":_vm.$t('chart.showTitle')},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                command: 'update_bl_xTitle',
                value: $event
            })}},model:{value:(_vm.setup.bl_xTitle),callback:function ($$v) {_vm.$set(_vm.setup, "bl_xTitle", $$v)},expression:"setup.bl_xTitle"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.setup.bl_xTitle),expression:"setup.bl_xTitle"}],attrs:{"outlined":"","height":"30"},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                command: 'update_xTitle',
                value: $event
            })}},model:{value:(_vm.setup.xTitle),callback:function ($$v) {_vm.$set(_vm.setup, "xTitle", $$v)},expression:"setup.xTitle"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }