<template>
  <div>
    <div class="workflow-setting-form-each-item">
      <FormElement name="email.emailSender" :label="$t('workflow.modules.email.label.selectEmailSender')">
        <ComboBox
          :options="emailSenders" 
          :value="emailSender || null"
          @changed="selectEmailSender"
        >
          <div v-if="$store.getters.isTeamAdmin" slot="after-list">
            <v-btn @click="showingMailSetting=true" class="pa-0 d-flex justify-start" style="width:100%" color="teal" text tile>
              <v-icon right small class="mr-3">mdi-plus</v-icon>
                {{$t('mailSetting.addMailServer')}}
            </v-btn>
          </div>
        </ComboBox>
      </FormElement>
      <v-layout v-if="emailSender=='default'" align-center justify-start class="workflow-setting-form-waiting-set">
        {{ $t(`workflow.modules.email.remark.informHints`) }}
      </v-layout>
    </div>

    <div v-if="emailSender">
      <div class="workflow-setting-form-each-item">
        <RecipientSelect 
          noHints 
          withTypeSelect='email' 
          notificationType="email"
          :limit="emailToLimit"
          :limitErrorMessage="$t('workflow.modules.email.message.toLimitError', { limit: emailToLimit })"
          :limitHint="$t('workflow.modules.email.remark.to', { limit: emailToLimit })" 
          @onLimitError="onEmailToError"
        />
        <RecipientSelect
          withTypeSelect='email'
          notificationType="email"
          noHints
          notificationCounterLabelType="informForwardTarget"
          notificationCounterLabelNormalType="informForwardTargetNormal"
          typeRecipientField='forwardEmailRecipient'
          otherRecipientField='otherForwardRecipient'
          recipientField='forwardRecipient'
        />
      </div>
      <div class="workflow-setting-form-each-item">
        <FormElement
          :label="$t('workflow.modules.email.label.senderName')"
          name="senderName"
        >
          <FreeInput
            :value="email.senderName"
            :fields="setting.fields"
            fieldType="text"
            noNewLine
            @itemsChanged="itemsChanged"
            @valueChanged="v => contentChanged('senderName', v)"
          />
        </FormElement>
      </div>
      <div class="workflow-setting-form-each-item">
        <FormElement
          :label="$t('workflow.modules.email.label.subject')"
          name="subject"
        >
          <FreeInput
            :value="email.subject"
            :fields="setting.fields"
            fieldType="text"
            noNewLine
            @itemsChanged="itemsChanged"
            @valueChanged="v => contentChanged('subject', v)"
          />
        </FormElement>
      </div>
      <div class="workflow-setting-form-each-item">
        <FormElement
          :label="$t('workflow.modules.email.label.content')"
          name="content"
          id="contentInput"
        >
          <v-layout align-start justify-space-between>
            <v-radio-group
              row
              :value="contentType"
              @change="v => contentChanged('contentType', v)"
              dense
              hide-details
            >
              <v-radio
                v-for="option in ['plain', 'html']"
                :key="option"
                :value="option"
                :label="$t(`workflow.modules.email.label.contentType.${option}`)"
              />
            </v-radio-group>
          </v-layout>
          <FreeInput
            :value="email.content"
            v-if="!email.contentType || !isHtml"
            :fields="setting.fields"
            fieldType="text"
            large
            @itemsChanged="itemsChanged"
            @valueChanged="v => contentChanged('content', v)"
          />
          <div v-if="isHtml" id="generalQuillWrapper" class="workflow-free-input general-quill-wrapper position-relative">
            <!-- showingFieldSelection -->
            <GeneralQuill
              ref="generalQuill"
              class="general-quil mr-8"              
              :appId="appId"
              :isWorkflow="true"
              formId="workflow.emailNotification"
              fieldId="-1"
              v-model="htmlContent"
              @onCommand="onCommandHandler"
              @input="v => { this.htmlContent = v.html; }"
            >
            <!-- <GeneralQuill
              ref="generalQuill"
              class="general-quil mr-8"              
              :appId="appId"
              :isWorkflow="true"
              formId="workflow.emailNotification"
              fieldId="-1"
              v-model="htmlContentInput"
              @onCommand="onCommandHandler"
              @input="v => { this.htmlContentInput = v.html }"
            > -->
            </GeneralQuill>
            <ComboBox
              :options="fieldSelectionList"
              :value="null"
              :onlyPrefix="true"
              dependsOnType="text"
              :disabledOnDependencies="true"
              :onlySuffix="true"
              :isMultiple="false"
              :otherProps="{ zIndex: 9999, limit: 1 }"
              :style="{width: '100%', height: 0}"
              placeholder=""
              @selected="onFieldSelected"
              valueFormat="object"
              :disableBranchNode="true"
              />        
          </div>
        </FormElement>
      </div>
      <div class="workflow-setting-form-each-item">
        <!--
        reserve for easily fallback
        <FormElement
          :label="$t('workflow.modules.email.label.replyEmail')"
          name="replyEmail"
        >
          <FreeInput
            :value="email.replyEmail"
            :fields="setting.fields"
            fieldType="text"
            noNewLine
            :withOperation="false"
            :exclusiveDelegated="true"
            @itemsChanged="itemsChanged"
            :isMultipleDynamic="false"
            @valueChanged="v => contentChanged('replyEmail', v)"
          />
        </FormElement>
        -->
        <RecipientSelect
          name="replyEmail"
          withTypeSelect='email'
          notificationType="email"
          notificationCounterLabelType="replyEmail"
          notificationCounterLabelNormalType="replyEmail"
          recipientField='replyRecipient'
          typeRecipientField='replyTypeRecipient'
          otherRecipientField='replyOtherRecipient'
          noHints
          :limit=1
          :limitErrorMessage="$t('workflow.modules.email.message.toLimitError', { limit: emailToLimit })"
          @onLimitError="onEmailToError"
        />
      </div>
            <div class="workflow-setting-form-each-item">
        <FormElement
          :label="$t('workflow.modules.email.label.attachment')"
          name="attachment"
          :hints="$t('workflow.modules.email.remark.attachment')"
        >
          <!-- <FreeInput
            :value="email.attachment"
            :fields="setting.fields"
            fieldType="text"
            :isMultipleDynamic="false"
            noNewLine
            @itemsChanged="itemsChanged"
            readOnly
            @valueChanged="v => contentChanged('attachment', v)"
          /> -->
          <ComboBox
            :options="list"
            :value="email.attachment || null"
            :onlyPrefix="true"
            dependsOnType="attachments"
            :disabledOnDependencies="true"
            :onlySuffix="true"
            :isMultiple="false"
            placeholder=""
            @selected="v => contentChanged('attachment', v)"
            valueFormat="object"
            :disableBranchNode="true"
          />
        </FormElement>
      </div>

    </div>
    <mailSettingsDialog
      v-if="showingMailSetting"
      v-model="showingMailSetting"
      @input="handleSenderDialogInput"
    />
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>

import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import GeneralQuill from '@/components/public/GeneralQuill'
import RecipientSelect from './recipient';
import FreeInput from './../shared/freeInput';
import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import NodeUtils from '@/pages/admin/workflow/utils/node';
import Mixin from './../mixin';
import EmailMixin from './mixin';
import FormElement from './../shared/formElement';
import OperationSettingMixin from './../operation/mixin';
import SettingUtils from '@/pages/admin/workflow/utils/setting';
import mailSettingsDialog from '@/components/dialogs/mail/MailSettingsDialog'
import { findIndex } from 'lodash';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
// import RecordMxiin from '@/mixins/recordMixin';
// import {
//   FORM_SELECTIONS_FIELDS,
// } from '@/store/modules/workflow/action_types';
/*
* Target Module View Wrapper Components
* Terry Chan
* 28/05/2021
*/
// import {
//   SET_SETTINGS,
// } from '@/store/modules/workflow/action_types';

export default {
  name: 'EmailNotificationSetting',
  mixins: [OperationSettingMixin, EmailMixin, Mixin],
  components: {
    FreeInput,
    FormElement,
    RecipientSelect,
    ComboBox,
    GeneralQuill,
    mailSettingsDialog,
    ConfirmDialog,
  },
  mounted() {
    if (!this.setting.email) {
      this.setting.email = {};
    }
    
    if ( 'htmlContent' in this.email ) {
      this.htmlContent = this.convertToFieldName(this.email.htmlContent);
    }
    else if ( this.isHtml ) {
      this.htmlContent = this.isHtml ? this.convertToFieldName(this.email.content) : '';
      this.email.content = '';
    }
  },
  data() {
    return {
      htmlContent: '',
      htmlEditor: null,
      emailSenders:[],
      emailTypeList: [
        { label: this.$t('workflow.modules.email.options.forward'), id: 'forward' },
        { label: this.$t('workflow.modules.email.options.direct'), id: 'direct' },
      ],
      emailToLimit: 20,
      showingMailSetting: false,
      showingFieldSelection: false,
      showingFieldSelectionCallback: null
    };
  },
  methods: {
    getNameTagFromField (field) {
      let nodeLabel = this.nodeIdNameMap[field.node];
      let fieldLabel = field.isSystem ? field.fieldId : field.label;
      return '{' + nodeLabel + ' | ' + fieldLabel + '}';
    },
    convertToFieldName (str) {
      let result = str;
      if (str) {
        const regexp = /(\${[a-fA-F0-9]+_[a-fA-F0-9]+})/g;       
        const matches = [...str.matchAll(regexp)];
        for (let i = 0; i < matches.length; i++) {
          const loopMatch = matches[i];
          if (loopMatch && loopMatch[1]) {
            const nameTag = this.fieldIdNameMap[loopMatch[1]];
            result = result.replaceAll(loopMatch[1], nameTag);
          }
        }
      }
      return result;
    },
    convertToFieldId(str) {
      let result = str;
      if (str) {
        const regexp = /({[a-zA-Z0-9_!$%^&*()_+|~=`\[\]:";'<>?,.\/@#-\s]*\|\s*[a-zA-Z0-9_!$%^&*()_+|~=`\[\]:";'<>?,.\/@#-\s]*})/g;
        const matches = [...str.matchAll(regexp)];
        for (let i = 0; i < matches.length; i++) {
          const loopMatch = matches[i];
          if (loopMatch && loopMatch[1]) {
            const nameTag = loopMatch[1].split('|').map(s => s.trim()).join(' | ');
            const idTagIndex = Object.values(this.fieldIdNameMap).indexOf(nameTag);
            if (idTagIndex >= 0 ) {
              const idTag = Object.keys(this.fieldIdNameMap)[idTagIndex];
              result = result.replaceAll(loopMatch[1], idTag);
            }
          }
        }
      }
      return result;
    },
    onFieldSelected(field, v) {
      const tag = this.getNameTagFromField(field);
      this.$refs.generalQuill.insertText(tag);
      const index = findIndex(this.setting.fields, ({ id }) => field.id === id);
      if (index === -1) {
        this.setting.fields.push(field);
      }
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case 'showFieldSelection':
          this.showingFieldSelection = true;
          this.showingFieldSelectionCallback = payload.onSuccess;
          break;
      }
    },
    onEmailToError({ limit, values }) {
      const vm = this;
      vm.$refs.confirmDialog.open(vm.$t('general.errorMessage'), vm.$t('workflow.modules.email.message.toLimitError', { limit }), {
        color: 'error',
        command: 'close',
        cancelMsg: this.$t("buttons.close"),
        buttons: null,
      });
    },
    contentChanged(field, v) {
      const { email={} } = this;
      this.changed(`email.${field}`, v, { email })
    },
    selectEmailSender( val ) {
      const { email={} } = this;

      const fields = ["emailRecipient",  "forwardEmailRecipient", "replyTypeRecipient"];
      if (val === "default"){
        fields.forEach(field =>  {
          this.$set( this.setting.email, field, [] )
        })
      }
      this.changed(`email.emailSender`, val, { email })
      this.$set( this.setting.email, 'emailSender', val );
    },
    validation() {
      let { setting } = this;
      const {
        content = '',
        senderName = '',
        subject = '',
        replyEmail: existsReplyEmail = '',
        replyRecipient = [],
        replyTypeRecipient = [],
        replyOtherRecipient = [],
      } = setting.email;
      const htmlContent = this.convertToFieldId(this.htmlContent)

      const replies = [].concat(replyRecipient, replyTypeRecipient, replyOtherRecipient)?.[0] // extract first reply member or workflow member
      setting.email.replyEmail = (replies?.id ?? replies?._id) || // workflow use id and member use _id
                                 existsReplyEmail ||
                                 ''; // default empty string

      // const fields = [
      //   {
      //     field: 'senderName',
      //     targetField: 'senderNameFields'
      //   },
      //   {
      //     field: 'subject',
      //     targetField: 'subjectFields'
      //   },
      //   {
      //     field: 'content',
      //     targetField: 'contentFields'
      //   }
      // ];
      // fields.forEach(f => {
      //   // find out all of dynamic replacement field from text content fields
      //   const fields = SettingUtils.expressionContractFields(setting[f.targetField], this.email[f.field]);
      //   setting = { ...setting, email: { ...setting.email, [f.targetField]: fields } };
      // });
      const fields = SettingUtils.expressionContractFields(setting.fields, `${content} ${senderName} ${subject}`);
      setting = { ...setting, fields };
      if (this.isHtml) {
        setting = { ...setting, email: { ...setting.email, htmlContent: htmlContent}}        
      }
      return setting;
    },
    handleSenderDialogInput(val){
      this.showingMailSetting = val
      this.fetchSenders()
    }
  },

  computed: {
    nodeIdNameMap () {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      let result = {}
      workflowOutputs.forEach(node => {
        result[node._id] = node.label;
      }) 
      return result;
    },
    fieldIdNameMap () {
       let workflowOutputs = this.$store.getters.getDependencies || [];
       let result = {}
       workflowOutputs.forEach(node => {
        node.children.forEach(field => {
          const fieldLabel = field.isSystem ? field.fieldId : field.label
          const idTag = '${' + node._id + '_' + field.fieldId + '}';
          const nameTag = '{' + node.label + ' | ' + fieldLabel + '}';
          result[idTag] = nameTag;
        })
       })
       return result;
    },
    htmlContentInput: {
      get() {
        const result = this.convertToFieldName(this.htmlContent);
        return result;
      },
      set(val) {
        const convertedContent = this.convertToFieldId(val);
        this.htmlContent = convertedContent
        this.contentChanged('htmlContent', convertedContent);
      }
    },
    emailSender(){
      return this.setting.email && this.setting.email.emailSender
    },
    contentHtmlField() {
      return {
        fieldType: "richText",
        type: "dataset",
        fieldId: 'content',
        label: ' ',
        canCreate: true,
        canEdit: true,
        canView: true
      }
    },
    fieldSelectionList() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      const options = {
        withOperation: false,
        exclusiveDelegated: true,
      };
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'), options);
      return NodeUtils.withAcceptanceOptions({
        dependsOnType: 'text',
        // this.mathSupport ? this.fieldType : null, 
        list: [...workflowOutputs],
      });
    },
    list() {
      let workflowOutputs = this.$store.getters.getDependencies || [];
      workflowOutputs = WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));
      return NodeUtils.withAcceptanceOptions({
        dependsOnType: 'attachments',
        list: workflowOutputs,
      });
    },
    appId() {
      return this.$store.getters.currentApp._id.toString();
    }
  }
};

</script>

<style>
.ql-formats .vue-treeselect__control .vue-treeselect__value-container {
  display: none;
}
.ql-formats .vue-treeselect__control {
  height: auto !important;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
}

.general-quill-wrapper .v-menu__content {
  left: 0 !important;
  max-width: 100%;
  width: 100%;
}

.general-quill-wrapper > div:nth-child(2) {
  width: 100%;
  height: 0;
}

.general-quill-wrapper .vue-treeselect {
  top: 0;
  right: 0;
  position: absolute;
  width: 30px;
}

.general-quill-wrapper .vue-treeselect .vue-treeselect__control .vue-treeselect__x-container {
  display: none;
}

.general-quill-wrapper .vue-treeselect .vue-treeselect__control {
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.general-quill-wrapper .vue-treeselect .vue-treeselect__control .vue-treeselect__value-container {
  display: none;
}

.general-quill-wrapper .vue-treeselect__menu-container .vue-treeselect__menu {
  max-height: 300px;
  width: 577px;
  z-index: 2;
  left: -577px;
  border-top: 1px solid #2196f3;
}
</style>
