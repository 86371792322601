<template>
  <vxe-table-column
    v-bind="colDef"
    :edit-render="{ name: 'number', autoselect: true, enabled: !readonly}"
    sortable
    class-name="fullCell"
  >
    <template v-slot="{ row }">
      <v-rating
        :class="ratingLength == 5 ? 'fiveStars' : 'tenStars'"
        readonly
        :value="row[fieldId]"
        :length="ratingLength"
        x-small
        dense
      />
      <quick-action v-if="quickInsert" :rowId="row['_id']" :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{ row }">
      <div
        class="py-1 w-100 mb-0"
        style="min-height: 32px;background-color:white; height:100%; border: 1px solid #dcdfe6;"
      >
        <v-rating
          :readonly="readonly"
          :class="ratingLength == 5 ? 'fiveStars' : 'tenStars'"
          :value="row[fieldId]||0"
          @input="row[fieldId]=$event"
          :length="ratingLength"
          x-small
          dense
          clearable
          :background-color="readonly ? 'grey lighten-1' : 'primary'"
          :color="readonly ? 'grey lighten-1' : 'primary'"
        />
      </div>
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>
<script>
import mixin from "./mixin";
import quickAction from "./comps/QuickAction";
import summaryCell from './comps/SummaryCell'

export default {
  name: "vxeCellRating",
  mixins: [mixin],
  components: {
    quickAction,
    summaryCell
  },
  computed: {
    ratingLength() {
      return this.fieldInfo.properties.ratingLevel === "5stars" ? 5 : 10;
    },
  },
};
</script>
<style>
.fiveStars .svg-inline--fa.fa-star,
.tenStars .svg-inline--fa.fa-star {
  height: 100% !important;
  width: 100% !important;
}
.fiveStars .v-icon {
  width: 20% !important;
  height: 100% !important;
  min-width: 5px !important;
  min-height: 5px !important;
}
.tenStars .v-icon {
  width: 10% !important;
  height: 80% !important;
  min-width: 5px !important;
  min-height: 5px !important;
}
.fiveStars,
.tenStars {
  width: 100% !important;
  height: 100% !important;
  line-height: 1.5 !important;
}
</style>
