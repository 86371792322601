<template>
  <v-layout align-center>
    <v-avatar
      color="primary"
      :size="size"
    >
      <span :style="capNameStyle">{{ capName }}</span>
    </v-avatar>
    <span v-if="!noLabel">
      <span class="name">{{ user.name }}</span>
    </span>
  </v-layout>
</template>

<script>
import StringUtil from './../../utils/string';

export default {
  // data: {
  //   capNameStyle: {
  //     'font-size': '10px',
  //     color: 'white',
  //   },
  // },
  props: {
    user: Object,
    'no-label': {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 28,
    },
  },
  computed: {
    capName () {
      return StringUtil.pickFirstCharFromName(this.user.name);
    },
    capNameStyle() {
      return {
        'font-size': this.size * 0.4,
        color: 'white',
      }
    }
  }
};

</script>

<style scoped>
  .name {
    color: #757575;
    margin-left: 10px;
    display: block;
  }
</style>