<template>
  <div>
    <div class="d-flex flex-row align-center">
      <slot name="label"></slot>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="ml-1 new-option"
              color="primary"
              @click="newInputOption"
              :disabled="remaining <= 0"
              x-small
              ><v-icon small>mdi-plus</v-icon></v-btn
            >
          </template>
          {{ $t("general.new") }}
        </v-tooltip>

      <v-spacer></v-spacer>
      <v-switch
        class="ma-0"
        dense
        small
        hide-details
        v-model="useColor"
        @change="updateProperty('useColor', $event)"
        :label="$t('widgets.properties.useColor')"
      ></v-switch>
    </div>

    <div class="grey lighten-4 py-1">
      <vue-nestable keyProp="_id" v-model="inputOptions" maxDepth="1">
        <template v-slot:placeholder>
          {{ $t("widgets.properties.noData") }}
        </template>
        <template slot-scope="{ item }">
          <div class="d-flex flex-row align-center">
          <vue-nestable-handle :item="item" style="width:auto">
            <v-icon class="mr-2">mdi-format-line-spacing</v-icon>
          </vue-nestable-handle>
          <color-menu-button
            v-if="useColor"
            :value="item.colorSet"
            @input="(colorIndex) => updateColorSetByIndex(item._id, colorIndex)"
          ></color-menu-button>
          <input
            :value="item.label"
            class="option-label-input "
            @input="($evt) => updateOption(item, $evt.target.value, 'label')"
          />
          <v-spacer />
          <slot name="prepend-delete" :item="item"></slot>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                small
                color="red"
                @click="removeOption(item._id)"
                >mdi-window-close</v-icon
              >
            </template>
            {{ $t("buttons.delete") }}
          </v-tooltip>
          </div>
        </template>
      </vue-nestable>
      <div class="d-flex flex-row align-center">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="ml-1 new-option"
              color="primary"
              @click="newInputOption"
              :disabled="remaining <= 0"
              x-small
              ><v-icon small>mdi-plus</v-icon></v-btn
            >
          </template>
          {{ $t("general.new") }}
        </v-tooltip> -->
        <v-spacer />
        <v-btn
          color="primary"
          text
          x-small
          :disabled="remaining <= 0"
          @click="showBulkCreateDialog = true"
          >{{ $t("buttons.bulkCreate") }}</v-btn
        >
        <v-btn
          color="primary"
          text
          x-small
          @click="showValueAssignmentDialog = true"
          >{{ $t("optionGroup.valueAssignment") }}</v-btn
        >
      </div>
    </div>
    <OptionsBulkCreateDialog
      v-if="showBulkCreateDialog"
      v-model="showBulkCreateDialog"
      :remainingSpace="remaining"
      @confirm="bulkAddOptions"
    />
    <OptionsValueAssignmentDialog
      v-if="showValueAssignmentDialog"
      v-model="showValueAssignmentDialog"
      :options="options"
      :useValue="useValue"
      @confirm="onValueAssignmentSubmit"
    />
  </div>
</template>
<script>
import { VueNestable, VueNestableHandle } from "vue-nestable";
import colorMenuButton from "./comps/ColorMenuButton.vue";
import OptionsBulkCreateDialog from "./comps/OptionsBulkCreateDialog";
import OptionsValueAssignmentDialog from "./comps/OptionsValueAssignmentDialog";

export default {
  components: {
    VueNestable,
    VueNestableHandle,
    colorMenuButton,
    OptionsBulkCreateDialog,
    OptionsValueAssignmentDialog,
  },
  props: {
    useValue: Boolean,
    useColor: Boolean,
    options: Array,
  },
  data() {
    return {
      inputItem: null,
      showBulkCreateDialog: false,
      showValueAssignmentDialog: false,
    };
  },
  computed: {
    inputOptions: {
      get() {
        return this.options;
      },
      set(val) {
        this.$emit("onCommand", {
          command: "reorder",
          options: val,
        });
      },
    },
    remaining(){
      return 50 - this.inputOptions.length
    }
  },
  methods: {
    newInputOption() {
      if (this.remaining > 0)
      this.$emit("onCommand", {
        command: "new",
      });
    },
    bulkAddOptions(options) {
      if (this.remaining > 0)
      this.$emit("onCommand", {
        command: "bulkAddOptions",
        options,
      });
    },

    updateColorSetByIndex(_id, colorIndex) {
      this.$emit("onCommand", {
        command: "updateColorSet",
        _id,
        colorIndex,
      });
    },
    onInputFocus(item) {
      const vm = this;
      // console.log('onInputFocus ::item: ', item)
      vm.inputItem = item;
    },

    onInputBlur(item) {
      const vm = this;

      if (vm.inputItem === item) {
        vm.inputItem = null;
      }
    },
    updateOption(item, val, key) {
      item[key] = val;
      this.$emit("onCommand", {
        command: "updateOption",
        _id: item._id,
        val,
        key,
      });
    },
    removeOption(_id) {
      this.$emit("onCommand", {
        command: "remove",

        _id,
      });
    },
    updateProperty(key, val) {
      this.$emit("onCommand", {
        command: "updateProperty",
        key,
        val,
      });
    },
    onValueAssignmentSubmit(value) {
      this.$emit("onCommand", {
        command: "updateValueAssignment",
        val: value,
      });
    },
  },
};
</script>
<style lang="scss">
.option-label-input:active,
.option-label-input:focus {
  outline: $primary auto 1px;
  background-color: white;
}

.option-label-input:hover {
  border-color: lightgray;
}

.option-label-input {
  font-size: 13px;
  padding: 1px 2px;
  width: 100%;
  border: 1px solid transparent;
}

.nestable-handle .v-icon {
  cursor: pointer;
}

.color-circle {
  height: 16px !important;
  width: 16px !important;
}
</style>
