<template>
  <div class="d-flex flex-column justify-start mb-3 form-color-field">
    <label>{{ $t('general.iconColor') }}</label>
    <div class="flex-grow-0">
      <!--<v-btn small class="color-input text-center" relative-->
      <!--ref="colorInputButton"-->
      <!--style="min-width:100px !important;"-->
      <!--@click="showingDialog = true">{{ $vuetify.lang.t('$vuetify.buttons.select') }}</v-btn>-->
      <v-btn x-small height="24" class="elevation-0 color-dice d-inline-block mb-1"
           style="float:left;cursor:pointer;"
           v-for="(color, index) in BASIC_COLORS"
           :key="index"
           :class="{'white--text':darkThemeColors.includes(color), 'black--text':lightThemeColors.includes(color)}"
           :style="{backgroundColor:color}"
            @click="setColor(color)">
        <v-icon v-if="inputColor===color">mdi-check</v-icon>
      </v-btn>
    </div>
    <div class="d-flex flex-row justify-start">
      <v-btn small class="elevation-0 mt-1 color-input text-center" @click="showDialog">
        {{ $t('general.more')}}
      </v-btn>
      <v-btn small class="elevation-0 mt-1 ml-1 color-input text-center" @click="generate">
        {{ $t('general.random')}}
      </v-btn>
    </div>
    <v-dialog
        v-model="showingDialog"
        v-if="showingDialog"
        width="600">
      <v-card>
        <!-- <v-card-title class="headline grey lighten-2">
          {{ $t('general.colorSelection')}}
        </v-card-title> -->
        <dialog-header
          :label="$t('general.colorSelection')"
          @close="showingDialog = false"
          color="#E0E0E0"
          toolbarClass="grey--text"
        />
        <v-card-text class="pa-5">
          <!--<div class="mb-2 d-flex flex-row">-->
          <!--<div class="text-h6 d-inline mr-2">Text</div>-->
          <!--<v-btn-toggle v-model="textThemeNo" mandatory>-->
          <!--<v-btn small class="black min-width-50" style="border:1px solid dark-grey;">-->
          <!--<v-icon v-if="textThemeNo===0" class="grey&#45;&#45;text">mdi-check</v-icon>-->
          <!--</v-btn>-->
          <!--<v-btn small class="white min-width-50" style="border:1px solid dark-grey;">-->
          <!--<v-icon v-if="textThemeNo===1" class="grey&#45;&#45;text">mdi-check</v-icon>-->
          <!--</v-btn>-->
          <!--</v-btn-toggle>-->
          <!--</div>-->
          <!--<div class="text-h6">Background</div>-->
          <div class="d-flex flex-row align-start"
               :class="'theme--'+inputTextTheme">
            <!--<sketch-picker :value="inputColor" @input="color=>updateColor(color)" />-->
            <swatches-picker :value="inputColor"
                             @input="color=>updateColor(color)"
                             class="ml-3 color-picker"
                             style="height:316px;width:100%;"/>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions><v-spacer></v-spacer>
          <v-btn class="min-width-80" color="muted" @click="showingDialog = false">
            {{ $t('buttons.cancel') }}</v-btn>
          <v-btn class="min-width-80" color="primary" @click="confirm">
            {{ $t('buttons.ok') }}</v-btn>
        </v-card-actions> -->
        <dialog-actions 
          :buttons="['cancel', 'ok']"
          @onCommand="onCommandHandler($event)">
        </dialog-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import appColorMixin from '@/mixins/appColorMixin'
import helpers from '@/helpers'
import vuetifyColors from 'vuetify/lib/util/colors'
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
import {
  Photoshop,
  Material,
  Compact,
  Swatches,
  Slider,
  Sketch,
  Chrome } from 'vue-color'

export default {
  mixins: [appColorMixin],
  components: {
    'material-picker': Material,
    'compact-picker': Compact,
    'swatches-picker': Swatches,
    'slider-picker': Slider,
    'sketch-picker': Sketch,
    'chrome-picker': Chrome,
    'photoshop-picker': Photoshop,
    dialogActions,
    dialogHeader
  },
  data () {
    return {
      showingDialog: false,
      inputColor: '',
      inputTextTheme: 'dark',
      inputColor2: '',
      // textThemeNo: 1,
      color: ''
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  mounted () {
    const vm = this
    // console.log('mounted :: vm.value.color = ' + vm.value.color)
    vm.assignInputColor(vm.value.color)
    // vm.textThemeNo = vm.value.textTheme === 'light' ? 0 : 1
  },
  watch: {
    value: function(newValue) {
      const vm = this
      console.log('ColorInputField :: watch(value) color = ' + newValue.color)
      // console.log('ColorInputField :: watch(value) textTheme = ' + newValue.textTheme)
      if (newValue) {
        vm.assignInputColor(newValue.color)
        // vm.textThemeNo = newValue.textTheme === 'light' ? 0 : 1
      }
    },
    inputColor: function(newValue) {
      const vm = this
      console.log('watch(inputColor) newValue = ' + newValue)
      vm.inputTextTheme = vm.getTextThemeByColor(newValue)
      // if (newValue && vm.inputColor2 !== newValue) {
      //   vm.inputColor2 = newValue
      // }
    }


    // inputColor2: function(newValue) {
    //   const vm = this
    //   if (vm.inputColor !== newValue) {
    //     vm.inputColor = newValue
    //   }
    // }
  },
  props: {
    value: {
      type: Object,
      default () {
        return {
          color: ''
          // ,
          // textTheme: 'light'
        }
      }
    }
  },
  // computed: {
  //   darkThemeColors () {
  //     return this.TEXT_THEMES.dark
  //   },
  //   lightThemeColors () {
  //     return this.TEXT_THEMES.light
  //   }
  //   // textThemes () {
  //   //   const vm = this
  //   //   var result = {}
  //   //   vm.availableColors.forEach(item => {
  //   //     result[item.color] = item.textTheme
  //   //   })
  //   //   return result
  //   // }
  // },
  methods: {
    onCommandHandler(payload){
      if(payload.command == 'ok')
        this.confirm()
      this.showingDialog = false
    },
    getTextThemeByColor (color) {
      const vm = this
      return vm.darkThemeColors.includes(color) ? 'dark' : 'light'
    },
    setColor (color) {
      const vm = this
      vm.inputColor = color
      vm.$emit('input', {
        color: color,
        textTheme: vm.getTextThemeByColor(color)
      })
    },
    showDialog () {
      this.showingDialog = true
    },
    // updateTextTheme (val) {
    //   alert('updateTextTheme val = ' + val)
    //   this.$emit('input',val)
    // },
    updateColor (color) {
      const vm = this
      vm.inputColor = color.hex8
    },

    assignInputColor (color) {
      const vm = this
      if (color && color !== '') {
        if (color.indexOf(' ') >= 0) {
          const colorSegs = color.split(' ')
          const seg0 = colorSegs[0]
          const seg1 = colorSegs[1].replace('-', '')
          vm.inputColor = vuetifyColors[seg0][seg1.replace('-','')]+'FF'
          // console.log('assignInputColor :; seg0 = ' + seg0)
          // console.log('assignInputColor :; seg1 = ' + seg1)
          // console.log('assignInputColor = ' + vm.inputColor)
        } else {
          if (color.indexOf('#') === 0) {
            vm.inputColor = color.length <= 7 ? color + 'FF' : color
          } else {
            const colorCode = helpers.colourNameToHex(color) + 'FF'
            vm.inputColor = colorCode
          }
        }
        vm.inputColor = vm.inputColor.toUpperCase()
        vm.color = color
      }
    },
    generate () {
      const vm = this
      const colorAndTheme = vm.getRandomColorAndTheme()
      vm.$emit('input', colorAndTheme)
    },
    confirm () {
      const vm = this

      var result = ''
      if (typeof vm.inputColor === 'object') {
        result = vm.inputColor.hex8
      } else {
        result = vm.inputColor
      }
      vm.setColor(result)
      //
      // vm.$emit('input', {
      //   color: result,
      //   textTheme: vm.textThemes[result]
      // })
      vm.showingDialog = false
    }
  }
}
</script>

<style>

.color-picker [role=listbox] {
  padding: 8px 0 8px 8px;
  margin: 0;
}
/*.swatches-col .v-color-picker__controls {*/
/*display: none;*/
/*}*/
.form-color-field .color-dice {
  width: 36px;
  height: 24px;
  margin-right: 1px;
  margin-bottom: 1px;
}

.theme--light .vc-swatches-pick {
  fill: black;
}

.theme--dark .vc-swatches-pick {
  fill: white;
}
</style>
