import countryCodes from "@/const/countryCodes";

const mixin = {
    computed: {
        phoneCountrySelection(){
            return  this.fieldInfo.properties && this.fieldInfo.properties.phoneCountrySelection
        },
        countries(){

          if (!this.phoneCountrySelection || this.phoneCountrySelection.allowedCountries.length === 0) return countryCodes
          else {
            return countryCodes.filter(item => this.phoneCountrySelection.allowedCountries.includes(item.iso2))
          }
        },
        showCountryCode(){

          return this.phoneCountrySelection && this.phoneCountrySelection.enabled || false
        },
        defaultCountryCode(){
          return this.showCountryCode && this.phoneCountrySelection && this.phoneCountrySelection.default || 'hk'
        },
        commonCountryCodes(){

          const favourites = this.phoneCountrySelection && this.phoneCountrySelection.favourites || []
          return this.countries.filter(item => favourites.includes(item.iso2))
        }
      },
}

export default mixin
