
<template>
    <div class="pt-3">
        <div :class="{'error--text': setupIsUpdated.filters}">{{$t('chart.filter')}}</div>
        <div class="rounded droppable" style="border: #aaaa dashed 1px; min-height: 80px;" id="dataFilter">
            <div v-for="(filter, index1) in filters" class="">
                <v-hover v-slot="{ hover }" v-for="(field, index2) in filter" :key="index2">
                    <div :class="index2==0 && index1==0?'pt-7':'pt-7'" class="" :style="`border-left: 3px ${colorArr[index1]} solid`">
                            <div v-show="hover" @click="deleteFilter(index1, index2)" class="" style="position: absolute; right:10px;transform: translate(-1px, -30px); cursor: pointer">
                                <v-icon small style="width:0px; min-width:0px">mdi-close</v-icon>
                            </div>
                            <component 
                                :is="getFieldTypeComponent(field)" 
                                :field="field" 
                                class="px-5"
                            />
                            <div class="d-flex mt-10 align-center">
                                <v-divider/>
                                    <div style="position: absolute; left:41%" class="white">
                                        <v-btn @click="toOr(index1, index2)" small v-if="index2!=filter.length-1" style="font-size:12px;" rounded outlined class="px-2 info--text">
                                            {{$t('chart.and')}}
                                        </v-btn>
                                        <v-btn @click="toAnd(index1, index2)" small v-if="index2==filter.length-1 && index1!=filters.length-1" style="font-size:12px;" rounded outlined class="px-2 info--text">
                                            {{$t('chart.or')}}
                                        </v-btn>
                                    </div>
                                <v-divider/>
                            </div>
                    </div>
                </v-hover>
            </div>
            <div style="color: #aaaa;" class="text-center py-5">{{$t('chart.dragToHere')}}</div>
        </div>
    </div>
</template>

<script>
    import FilterText from '../../../filter/FilterText'
    import FilterNumber from '../../../filter/FilterNumber'
    import FilterDate from '../../../filter/FilterDate'
    import FilterSelection from '../../../filter/FilterSelection'
    import FilterMembers from '../../../filter/FilterMembers'
    import FilterDepartments from '../../../filter/FilterDepartments'
    import FilterRadio from '../../../filter/FilterRadio'
    export default {
        props:{
            filters: Array,
            setupIsUpdated: Object
        },
        components:{
            FilterText,
            FilterNumber,
            FilterDate,
            FilterSelection,
            FilterMembers,
            FilterDepartments,
            FilterRadio
        },
        data(){
            return{
                colorArr: []
            }
        },
        watch:{
            filters:{
                handler(val){
                    this.$emit('onCommandHandler', {
                        command: 'FetchData'
                    })
                    while(this.colorArr.length<val.length){
                        this.colorArr.push(this.getColor())
                    }
                    while(this.colorArr.length>val.length){
                        this.colorArr.pop()
                    }   
                },
                deep: true
            }
        },
        mounted(){
            let vm = this
            this.filters.forEach(item=>{
                vm.colorArr.push(vm.getColor())
            })
        },
        methods:{
            getColor(){
                var color = ''
                do{
                    color = '#'+(Math.random()*0xEEEEEE<<0).toString(16)
                }while (color.length !=7)
                return color
            },
            getFieldTypeComponent(field){
                let type = field.type
                if(type==="childTable" || type==="relatedRecord"){
                    type = field.relatedType
                }
                switch(type){
                    case 'text':
                    case 'email':
                    case 'phone':
                    case 'idCard':
                    case 'autoNumbering':
                    case 'textCombination':
                        return 'FilterText'
                    case 'number':
                    case 'amount':
                    case 'expression':
                    case 'capitalizedAmount':
                    case 'rating':
                        return 'FilterNumber'
                    case 'date':
                    case 'createdAt':
                    case 'updatedAt':
                        return 'FilterDate'
                    case 'singleSelection':
                    case 'multipleSelection':
                        return 'FilterSelection'
                    case 'members':
                    case 'createdBy':
                        return 'FilterMembers'
                    case 'departments':
                        return 'FilterDepartments'
                    case 'attachments':
                    case 'signature':
                    case 'yesNo':
                        return 'FilterRadio'
                    default: return 'FilterText'
                }
            },
            toAnd(index1, index2){
                var arrToJoin = this.filters[index1+1]
                this.filters.splice(index1+1, 1)
                this.filters[index1] = this.filters[index1].concat(arrToJoin)
                // this.$emit('update_filters', this.filters) 
                this.$emit('onCommandHandler', {
                    command: 'update_filters',
                    value: this.filters
                })
            },
            toOr(index1, index2){
                var secondHalf = this.filters[index1].splice(index2+1)
                this.filters.splice(index1+1, 0, secondHalf)
                this.$emit('onCommandHandler', {
                    command: 'update_filters',
                    value: this.filters
                })
            },
            deleteFilter(index1, index2){
                if(this.filters[index1].length==1){
                    this.filters.splice(index1, 1)
                }
                else{                    
                    this.filters[index1].splice(index2, 1)
                }
                this.$emit('onCommandHandler', {
                    command: 'update_filters',
                    value: this.filters
                })
            }
        }

    }
    
</script>