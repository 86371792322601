<template>
  <div>
    <v-layout align-start justify-space-between>
      <div class="workflow-setting-condition-select-root">
        <ComboBox
          :value="rule || null"
          :options="rules.options"
          :placeholder="$t('messages.pleaseChoose', [])"
          :renderLabel="renderConditionLabel"
          @changed="(v) => (rule = v)"
        />
      </div>
      <div class="workflow-setting-columnType-select-root">
        <ComboBox
          :value="type || null"
          :options="types"
          :placeholder="$t('messages.pleaseChoose', [])"
          @changed="(v) => (type = v)"
        />
      </div>
    </v-layout>
            <div class="workflow-setting-columnValue-select-root">
            <div v-if="content.type===1">
                <v-text-field
                  class="workflow-setting-input-root"
                  v-model="value"
                  v-if="!disableInput"
                  :placeholder="$t('messages.pleaseInput', [])"
                  outlined
                  height="36"
                ></v-text-field>
                <SelectableText
                  v-else
                  :value="value"
                  :placeholder="$t('messages.pleaseInput', [])"
                  @changed="v => value = v"
                  @remove="remove(row, column)"
                  :selectInputProps="selectInputProps"
                  :fieldProperties="fieldProperties"
                />

            </div>
            <RuleSelectField
                v-else
                :value="value"
                :dependsOnType="fieldType"
                :comboxProps="comboxProps"
                :formFieldInfos="formFieldInfos"
                @changed="v => value = v"
            />
        </div>
  </div>
</template>

<script>
import RuleSelectField from "../units/RuleSelectField";
import ComboBox from "@/pages/admin/workflow/components/form/comboBox/";
import SelectableText from './SelectableText';
import mixin from "./mixin";
import PhoneNumber from 'awesome-phonenumber';
import { get } from "lodash";

export default {
  mixins: [mixin],
  components: {
    RuleSelectField,
    ComboBox,
    SelectableText
  },
  computed: {
      value:{
        get(){
          return this.content.value
        },
        set(val){
            if (Array.isArray(val)){
                val = val.map(elem => {
                    const label = get(elem, "label", "");
                    const phone = new PhoneNumber(label, label.startsWith("+")? undefined:"hk");
                    if (phone.isValid()){
                        return {
                            ...elem, 
                            label:phone.getNumber("international")
                        }
                    } else return elem
                })
            }
          this.$emit('updateValue', val)
         
        }
      },
  },
};
</script>
