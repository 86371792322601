<template>
  <div>
    <label>{{ label }}</label>
    <v-select
      :items="items"
      dense
      outlined
      solo
      :placeholder="placeholder"
      :hide-details="true"
      v-model="propertyValue"
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
    ></v-select>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  // property key: summaryField
  name: 'witRelatedTableField',
  mixins: [mixin],
  computed: {
    items () {
      const vm = this
      var result = []
      if (vm.selectableFieldInfos.length > 0) {
        for (let i = 0; i < vm.otherOptions.length; i++) {
          if(!(loopFieldInfo.type == "otherTableField" && this.fieldInfo.type=="otherTableField"))
            result.push({
              text: '{' + vm.$t('widgets.properties.' + vm.otherOptions[i].labelTag) + '}',
              value: vm.otherOptions[i].value
            })
        }
        for (let i = 0; i < vm.selectableFieldInfos.length; i++) {
          const loopFieldInfo = vm.selectableFieldInfos[i]
          if(!(loopFieldInfo.type == "otherTableField" && this.fieldInfo.type=="otherTableField"))
            result.push({
              text: loopFieldInfo.label,
              value: loopFieldInfo.fieldId
            })
        }
      }
      return result
    },
    fieldInfoForRelatedTable () {
      const vm = this
      var result = ''
      let fieldInfoId = ''
      let fieldInfo = null
      if (vm.fieldInfo.properties.fieldForRelatedTable) { // may be obsolate
        fieldInfoId = vm.fieldInfo.properties.fieldForRelatedTable
        fieldInfo = vm.formFieldInfos.find(info => info._id === fieldInfoId)
        result = fieldInfo
      } else if (vm.fieldInfo.properties.fieldForRelatedTable) {
        fieldInfoId = vm.fieldInfo.properties.fieldForRelatedTable
        fieldInfo = vm.formFieldInfos.find(info => info._id === fieldInfoId)
        result = fieldInfo
      }
      return result
    },
    selectableFieldInfos () {
      const vm = this
      var result = []
      if (vm.fieldInfoForRelatedTable) {
        const relatedFormId = vm.fieldInfoForRelatedTable.properties.dataSource
        const relatedFormInfo = vm.getFormInfo(relatedFormId)
        result = relatedFormInfo.fieldInfos
      }
      return result
    },
    placeholder () {
      const vm = this
      if (vm.relatedTable === '') {
        return vm.$t('messages.pleaseSelectRelatedTableFirst')
      } else {
        return vm.$t('messages.pleaseSelectField')
      }
    },
    relatedTable () {
      return this.getPropertyValue('relatedTable', '')
    }
  }
}
</script>
