<template>
<div class="v-messages theme--light error--text"
  role="alert">
  <div class="v-messages__wrapper">
    <div class="v-messages__message">
      {{ message }}
    </div>    
  </div>
</div>        
</template>

<script>
export default {
  name: 'errorMessage',
  props: {
    message: String
  }
}
</script>