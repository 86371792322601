<template>
  <div class="view-settings-parent-child-settings fill-height flex-grow-1 d-flex flex-column px-2">
<!--  {{ view.parentChildFieldFormList }} -->
    <parent-child-relation-selection
        v-model="parentChildInfos"
        @onCommand="onCommandHandler"
        :view="view"></parent-child-relation-selection>
  </div>
</template>

<script>
  import mixin from './mixin'
import parentChildRelationSelection from './comps/ParentChildRelationSelection'
import {LEVEL_DIAGRAM_CARD_CONFIG} from '@/const/defaults'

  export default {
    name: 'viewSettingsParentChildSettings',
    mixins: [mixin],
    components: {
        parentChildRelationSelection
    },
    props: {
      view: null
    },
    computed: {     
        // DEFAULT_CARD_CONFIG () {
        //   return {
        //     cardDisplayFieldIds: [],
        //     cardDisplayFieldName: true,
        //     cardImageFieldId: '',
        //     cardImageMode: 'cover',
        //     cardImagePosition: 'left',
        //     cardSummaryFieldId: ''
        //   }
        // },
        form () {
          return this.$store.getters.currentForm
        },
        parentChildInfos: {
            get () {                
              const vm = this
              var result = []
              if (vm.view) {                 
                switch (vm.view.levelType) {
                  case 'multipleTables':
                    if (vm.view.parentChildFieldFormList) {                        
                      result = vm.view.parentChildFieldFormList
                      
                      // result = {
                      //    fieldId,
                      //    relatedFormId
                      //    cardConfig
                      // }                      
                    }
                    break
                  case 'currentTable':
                  
                    var cardConfig = JSON.parse(JSON.stringify(LEVEL_DIAGRAM_CARD_CONFIG))
                    if (vm.view.parentChildFieldIdPair.cardConfig) {
                      cardConfig = vm.view.parentChildFieldIdPair.cardConfig
                    }
                    result = [{
                      fieldId: '',
                      fieldName: '',
                      relatedFormId: vm.form._id,
                      relatedFormName: vm.form.label,
                      cardConfig: cardConfig
                    }]
                    break
                }
              }
              return result
            },
            set (val) {
              if (this.view) {
                switch (vm.view.levelType) {
                  case 'multipleTables':
                    vm.view.parentChildFieldFormList = val
                    break
                  case 'currentTable':
                    vm.view.parentChildFieldIdPair = val[0]
                    break
                }
              }
            }
        }
        // ,
        // parentChildFieldFormList2: {
        //     get () {                
        //         const vm = this
        //         var result = []
        //         if (vm.view) {                 
        //           switch (vm.view.levelType) {
        //             case 'multipleTables':
        //               if (vm.view.parentChildFieldFormList) {
        //                 result = this.view.parentChildFieldFormList
        //               }
        //               break
        //             case 'currentTable':
        //               result = [{
        //                 fieldId: 
        //                 relatedFormId:
        //                 cardConfig: 
        //                 fieldName: 

        //               }]

        //          result = 
        //        }
        //         return this.view ?  : []
        //     },
        //     set (val) {
        //         alert('computed(parentChildFieldFormList).set')
        //         if (this.view) {
        //             this.view.parentChildFieldFormList = val
        //         }
        //     }
        // }
    },
    methods: {
      onCommandHandler (payload) {
        const vm = this
        switch (payload.command) {
          case 'updateCardConfig':
            vm.updateCardConfig(payload.index, payload.val)
            break
        }
      },
      updateCardConfig(index, cardConfig) {
        const vm = this
        console.log('udpateCardConfig :; cardConfig: ', cardConfig)
        switch (vm.view.levelType) {
          case 'currentTable':
            console.log('view.parentChildFieldIdPair: ', vm.view.parentChildFieldIdPair)
            vm.$set(vm.view.parentChildFieldIdPair, 'cardConfig', cardConfig)
            // vm.view.parentChildFieldIdPair['cardConfig'] = cardConfig
            break
          case 'multipleTables':
            console.log('view.parentChildFieldFormList: ', vm.view.parentChildFieldFormList)
            vm.$set(vm.view.parentChildFieldFormList[index], 'cardConfig', cardConfig)

            //vm.view.parentChildFieldFormList[index]['cardConfig'] = cardConfig
            break
        }
      }
    }

  }
</script>


<style>

  .view-settings-panel-settings .v-data-table table > tbody > tr > td:first-child {
    text-align: right;
  }

  .view-settings-panel-settings .v-data-table table > tbody > tr > td {
    vertical-align: middle;
    border: none !important;
  }

  /*.view-settings-table-settings .field-list {*/
  /*min-height: 200px;*/
  /*}*/
  .field-list .v-list-item:nth-child(even) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>
