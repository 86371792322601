<template>
    <v-menu
        v-model="showingMenu"
        :attach="`#${attachId}`"
        offset-y
        :content-class="fullWidth?'witDefaultValueMenuContent menuFullWidth':'witDefaultValueMenuContent'"
        :close-on-content-click="false"
    >
        <v-card class="elevation-1">
            <v-card-text class="pa-0">
                <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                    :day-format="getDay"
                    v-if="type!='time'"
                >
                    <div class="my-1 d-flex ml-6 align-center py-1 w-100" v-if="type!='date'">
                        <span v-if="type=='datetime'" style="font-size:13px">時間</span>
                        <v-input v-if="type=='datetime'" hide-details class="ml-2">
                            <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{on}">
                                    <div
                                        v-on="on"
                                        style="font-size:13px; border: 1px solid #d9d9d9; cursor: pointer"
                                        class="px-4"
                                    >
                                        {{hour.toString().padStart(2,0)}} : {{minute.toString().padStart(2,0)}}
                                    </div>
                                </template>
                                <v-card style="cursor: pointer pa-2">
                                    <v-card-text class="pa-0" style="max-height:200px; overflow: hidden;">
                                        <div class="d-flex" style="overflow: hidden;">
                                            <perfect-scrollbar
                                                style="border-right: 1px solid #d9d9d9; width:50%; max-height:200px;"
                                                class="d-flex flex-column"
                                            >
                                                <div 
                                                    v-for="i in 24" 
                                                    :key="i"
                                                    class="text-left pl-2" 
                                                    :class="i-1==hour?'teal lighten-2 white--text':''"
                                                    @click="hour=(i-1)"
                                                >
                                                    {{(i-1).toString().padStart(2, "0")}}
                                                </div>
                                            </perfect-scrollbar>
                                            <perfect-scrollbar
                                                style="border-right: 1px solid #d9d9d9; width:50%; max-height:200px;"
                                                class="d-flex flex-column"
                                            >
                                                <div 
                                                    v-for="i in 60" 
                                                    :key="i" 
                                                    class="text-left pl-2" 
                                                    :class="i-1==minute?'teal lighten-2 white--text':''"
                                                    @click="minute=(i-1)"
                                                >
                                                    {{(i-1).toString().padStart(2, "0")}}
                                                </div>
                                            </perfect-scrollbar>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </v-input>
                    </div>
                </v-date-picker>
                <div v-else style="width: 264px">
                    <div class="d-flex" style="overflow: hidden; width:100%">
                        <perfect-scrollbar
                            style="border-right: 1px solid #d9d9d9; width:50%; max-height:200px;"
                            class="d-flex flex-column"
                        >
                            <div
                                v-for="i in 24" 
                                :key="i" 
                                class="text-left pl-2" 
                                :class="i-1==hour?'teal lighten-2 white--text':''"
                                @click="hour=(i-1)"
                            >
                                {{(i-1).toString().padStart(2, "0")}}
                            </div>
                        </perfect-scrollbar>
                        <perfect-scrollbar
                            style="border-right: 1px solid #d9d9d9; width:50%; max-height:200px;"
                            class="d-flex flex-column"
                        >
                            <div 
                                v-for="i in 60" 
                                :key="i" 
                                class="text-left pl-2" 
                                :class="i-1==minute?'teal lighten-2 white--text':''"
                                @click="minute=(i-1)"
                            >
                                {{(i-1).toString().padStart(2, "0")}}
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        props:{
            value: Boolean,
            attachId: String,
            fullWidth:{
                type: Boolean,
                default: true
            },
            type: String,
            defaultValue: String
        },
        data(){
            return {
                date: new Date().toISOString().substr(0, 10),
                hour: 0,
                minute: 0,
            }
        },
        computed:{
            showingMenu:{
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            dateStr:{
                get(){
                    switch(this.type){
                        case 'date':
                            return this.date
                        break
                        case 'time':
                            return this.hour.toString().padStart(2,0) + ":" + this.minute.toString().padStart(2,0)
                        break
                        case 'datetime':
                            return this.date + " " +  this.hour.toString().padStart(2,0) + ":" + this.minute.toString().padStart(2,0)
                        break
                    }
                }
            }
        },
        watch:{
            dateStr(val){
                this.$emit('updateDefaultValue', val)
            }
        },
        created(){
            if(this.defaultValue){
                var strLen = this.defaultValue.length
                if(strLen<=5){
                    var time = this.defaultValue.substring(this.defaultValue.length-5).split(':')
                    this.hour = time[0]
                    this.minute = time[1]
                }else if(strLen<=10){
                    console.log('bp-2')
                    this.date = this.defaultValue
                }else if(strLen<=16){
                    console.log('bp-3')
                    this.date = this.defaultValue.substring(0, 10)
                    var time = this.defaultValue.substring(this.defaultValue.length-5).split(':')
                    this.hour = time[0]
                    this.minute = time[1]
                }
            }
        },
        methods:{
            getDay(date){
                let i = new Date(date).getDate(date)
                return i
            },
            resetDate(){
                this.date = null
                this.hour = null
                this.minute = null
            }
        }
    }
</script>