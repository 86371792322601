<template>
  <div>
    <div class="workflow-shortview-remark">
      {{
        $t('workflow.modules.operation.label.quation')
      }}: {{ expressionDisplay }}
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import Mixin from './../mixin';
// import SettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
  },
  computed: {
    source() {
      const { properties } = this.node;
      return get(properties, 'source');
    },
    otherSource() {
      const { properties } = this.node;
      return get(properties, 'otherSource');
    },
    propertiesFields() {
      return get(this.node, 'propertiesFields') || [];
    },
    expressionDisplay() {
      let { otherSource, source } = this;
      otherSource = typeof otherSource === 'object' ? otherSource.label : otherSource;
      source = typeof source === 'object' ? source.label : source;
      return `${otherSource} - ${source}`;
    }
  },
};

</script>

<style scoped>
  
</style>
