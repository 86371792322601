import {
  UPDATE_WORKFLOW_NODE,
} from '@/store/modules/workflow/action_types';
import { debounce } from 'lodash';

const mixin = {
  computed: {
    workflow() {
      return this.$store.getters.getWorkflowInfo;
    },
    appId() {
      return this.$route.params.id;
    },
  },
  methods: {
    onColor: debounce(function(color) {
      this.$store.dispatch(UPDATE_WORKFLOW_NODE, {
        info: { color },
        node: this.node,
        workflow: this.workflow._id,
        workflowInfo: this.workflow,
        appId: this.appId,
        // locale: this.$t('workflow.events')
      })
      this.node.color = color;
    }, 500),
  },
}

export default mixin
