import {
  findIndex,
  forEach,
  assign,
  add,
  // map,
} from 'lodash';
import moment from 'moment';
import {
  FETCH_WORKFLOW_LIST,
  FETCH_WORKFLOW_STATISTIC,
  FETCH_WORKFLOW_STATISTIC_TABLE,
  LOG_VALIDATE_WORKFLOW,
  REMOVE_WORKFLOW,
  CREATE_WORKFLOW,
  DUPLICATE_WORKFLOW,
  FETCH_WORKFLOW_TREE,
  FETCH_WORKFLOW_INFO,
  ADD_WORKFLOW_NODE,
  REMOVE_WORKFLOW_NODE,
  COPY_WORKFLOW_NODE,
  UPDATE_WORKFLOW,
  UPDATE_WORKFLOW_NODE,
  UPDATE_WORKFLOW_STATUS,
  FETCH_WORKFLOW_NODE_INFO,
  SET_SETTINGS,
  RESET_EDITOR,
  FETCH_WORKFLOW_RELEASE_HISTORY_LIST,
  FETCH_WORKFLOW_RELEASE_HISTORY_INFO,
  RESET_WORKFLOW_VALIDATE,
  VALIDATE_WORKFLOW,
  VALIDATION_UPDATE_EDIT_NODE,
} from './action_types';
import {
  PickWorkflowStatus,
} from './../orchestration/';
import NodeUtils from './../../../pages/admin/workflow/utils/node';
import { app } from "@/main"


export const ReplaceItemForIndex = (list, id) => {
  let offset;
  forEach(list, ({ children=[] }, row) => {
    const col = findIndex(children, ({ _id }) => _id === id);
    if (col !== -1 && !offset) {
      offset = { row, col };
    }
  });
  return offset;
};

export const RealTimePickReleaseStatus = workflow => ({
  released: workflow.released,
  releasedAt: moment().toDate(),
  beingReleased: workflow.released,
});

const initialState = () => ({
  workflows: {
    loading: false,
    list: [],
    error: null,
  },
  workflow: {
    loading: false,
    info: null,
    error: null,
  },
  validation: {
    loading: false,
    info: {},
    error: null,
  },
  eventHistories: {
    loading: false,
    list: [],
    total: 0,
    error: null,
    firstLoading: false,
  },
  eventHistoriyDetail: {
    loading: false,
    info: {},
    error: null,
  },
  tree: {
    loading: false,
    items: [],
    fields: {},
    error: null,
  },
  statistic: {
    loading: false,
    info: {},
    error: null,
  },
  node: {
    loading: false,
    info: {},
    error: null,
  },
  setting: {
    data: {},
    shareableTemplates: []
  },
  statisticTable: { // used to filter
    loading: false,
    list: [],
    error: null,
  },
  workflowToBeCreated: {
    loading: false,
    error: null,
  },
  workflowToBeRemoved: {
    item: null,
    loading: false,
    error: null,
  },
  workflowToBeUpdated: {
    loading: false,
    error: null,
  },
  workflowToBeDuplicated: {
    item: null,
    loading: false,
    error: null,
  },
  nodeAdd: {
    node: null,
    loading: false,
    error: null,
  },
  nodeUpdate: {
    node: null,
    loading: false,
    error: null,
  },
  nodeRemove: {
    node: null,
    loading: false,
    error: null,
  },
  nodeCopy: {
    nodes: [],
    loading: false,
    error: null,
  },
});

const getters = {
  getAddedNode: state => state.nodeAdd.node,
  addingNode: state => state.nodeAdd.loading,

  validationInfo: state => state.validation.info.errorLog,
  validationNodes: state => state.validation.info.errorNodes,
  validatingWorkflow: state => state.validation.loading,

  getEventHistoriesList: state => state.eventHistories.list,
  getEventHistoriesListTotal: state => state.eventHistories.total,
  loadingEventHistories: state => state.eventHistories.loading,
  isWorkflowHistoryFirstLoading: state => state.eventHistories.firstLoading,

  getEventHistoryInfo: state => state.eventHistoriyDetail.info,
  loadingEventHistory: state => state.eventHistoriyDetail.loading,

  getNode: state => state.node.info,
  getNodeProperties: state => state.setting,
  // get(state, 'node.info.properties') || {},
  fetchingNode: state => state.node.loading,

  getRemovedNode: state => state.nodeRemove.node,
  removingNode: state => state.nodeRemove.loading,

  getCopiedNodes: state => state.nodeCopy.nodes,
  getCopiedNode: state => {
    if (state.nodeCopy.nodes.length > 0) {
      return state.nodeCopy.nodes[0];
    }
    return null;
  },
  copyingNode: state => state.nodeCopy.loading,
  updatingNode: state => state.nodeUpdate.loading,
  updatedNode: state => state.nodeUpdate.node,

  getWorkflowList: state => state.workflows.list,
  fetchingWorkflowList: state => state.workflows.loading,
  
  getWorkflowStatistic: state => state.statistic.info,
  fetchingWorkflowStatistic: state => state.statistic.loading,
  
  getWorkflowStatisticTable: state => state.statisticTable.list,
  fetchingWorkflowStatisticTable: state => state.statisticTable.loading,

  removedWorkflow: state => state.workflowToBeRemoved.item,
  removingWorkflow: state => state.workflowToBeRemoved.loading,

  duplicatedWorkflow: state => state.workflowToBeDuplicated.item,
  duplicatingWorkflow: state => state.workflowToBeDuplicated.loading,

  getWorkflowInfo: state => state.workflow.info,
  fetchingWorkflowInfo: state => state.workflow.loading,

  getWorkflowTree: state => state.tree.items,
  getTreeFields: state => state.tree.fields,
  fetchingWorkflowTree: state => state.tree.loading,

  creatingWorkflow: state => state.workflowToBeCreated.loading,

  editorLoading: state => state.workflow.loading || state.tree.loading,
}

const mutations = {
  resetTreeState(state) {
    const wasState = initialState();
    state.workflow = { ...wasState.workflow };
    state.tree = { ...wasState.tree };
    state.node = { ...wasState.node };
    state.setting = { ...wasState.setting };
    state.validation = { ...wasState.validation };
    state.eventHistoriyDetail = { ...wasState.eventHistoriyDetail };
    state.eventHistories = { ...wasState.eventHistories };
  },
  // assignSubWorkflow(state, { info }) {
  //   if (info.subWorkflow) {
  //     const currentIndex = findIndex(state.tree.items, ({ _id }) => _id === info._id);
  //     if (currentIndex !== -1) {
  //       const { items=[] } = state.tree;
  //       const node = items[currentIndex];
  //       items[currentIndex] = {
  //         ...node,
  //         properties: {
  //           ...node.properties,
  //           ...info.properties,
  //           subWorkflow: info.subWorkflow,
  //         }
  //       }
  //       state.tree.items = [ ...items ];
  //     }
  //   }
  // },
  resetValidation(state) {
    const wasState = initialState();
    state.validation = { ...wasState.validation };
  },
  requestValidation(state) {
    state.validation.loading = true;
    // state.eventHistories.total = 0;
    // state.eventHistories.list = [];
  },
  updateEditingNodeChecking(state) {
    // const { items=[] } = state.tree;
    // state.tree.items = [ ...items ];
  },
  clearValidationResult(state) {
    state.validation.info = {};
  },
  loadedValidation(state, info = {}) {
    state.validation.loading = false;
    state.validation.info = info;
    // const { items=[] } = state.tree;
    
    // [IMPORTANT] async process, the iteration can be done here
    // assign the lates
    // if (info.errorNodes.length > 0) {
    // const { errorNodes } = info;
    // let nodeFound;
    // let currentInfo;
    // forEach(items, (node, index) => {
    //   nodeFound = errorNodes[node._id];
    //   currentInfo = currentNode && currentNode._id === node._id ? currentNode : items[index];
    //   // nodeIndex = findIndex(errorNodes, ({ checkingLog=[] }, _id) => _id === node._id);
    //   if (nodeFound) {
    //     items[index] = {
    //       ...currentInfo,
    //       ready: false,
    //       checkingLog: nodeFound.checkingLog,
    //     };
    //   } else {
    //     items[index] = {
    //       ...currentInfo,
    //       ready: true,
    //       checkingLog: [],
    //     };
    //   }
    // });
    // state.tree.items = [ ...items ];
    // }
  },
  loadValidationFailed(state, error) {
    state.validation.loading = false;
    state.validation.error = error;
  },

  requestEventHistories(state, { page }) {
    state.eventHistories.loading = true;
    state.eventHistories.firstLoading = page === 1;
    // state.eventHistories.total = 0;
    // state.eventHistories.list = [];
  },
  loadedEventHistories(state, { total, list }) {
    state.eventHistories.loading = false;
    state.eventHistories.total = total;
    state.eventHistories.list = list;
    state.eventHistories.firstLoading = false;
  },
  loadEventHistoriesFailed(state, error) {
    state.eventHistories.loading = false;
    state.eventHistories.error = error;
  },

  requestEventHistoryDetail(state) {
    state.eventHistoriyDetail.loading = true;
  },
  loadedEventHistoryDetails(state, info) {
    state.eventHistoriyDetail.loading = false;
    state.eventHistoriyDetail.info = info;
  },
  loadEventHistoryDetailsFailed(state, error) {
    state.eventHistoriyDetail.loading = false;
    state.eventHistoriyDetail.error = error;
  },

  requestCopyNode(state) {
    state.nodeCopy.loading = true;
  },
  copiedNode(state, nodes) {
    state.nodeCopy.loading = false;
    state.nodeCopy.nodes = nodes;
  },
  copyNodeFailed(state, error) {
    state.nodeCopy.loading = false;
    state.nodeCopy.error = error;
  },

  setSetting(state, info) {
    const { setting } = state;
    NodeUtils.referenceSettingUpdate(setting, info);
    state.setting = { ...setting };
  },

  requestUpdateNode(state) {
    state.nodeUpdate.loading = true;
  },
  updatedNode(state, { node, info }) {
    state.nodeUpdate.loading = false;
    NodeUtils.referenceUpdate(node, info);
    state.nodeUpdate.node = node;
  },
  updateNodeFailed(state, error) {
    state.nodeUpdate.loading = false;
    state.nodeUpdate.error = error;
  },

  requestAddNode(state) {
    state.nodeAdd.loading = true;
  },
  addedNode(state, node) {
    state.nodeAdd.loading = false;
    state.nodeAdd.node = node;
  },
  addNodeFailed(state, error) {
    state.nodeAdd.loading = false;
    state.nodeAdd.error = error;
  },

  requestRemoveNode(state) {
    state.nodeRemove.loading = true;
  },
  removedNode(state, node) {
    state.nodeRemove.loading = false;
    state.nodeRemove.node = node;
  },
  removeNodeFailed(state, error) {
    state.nodeRemove.loading = false;
    state.nodeRemove.error = error;
  },

  requestFetchingWorkflowList(state) {
    state.workflows.loading = true;
  },
  fetchedWorkflowList(state, list) {
    state.workflows.loading = false;
    state.workflows.list = list;
  },
  fetchWorkflowListFailed(state, error) {
    state.workflows.loading = false;
    state.workflows.error = error;
  },

  requestCreatingWorkflow(state) {
    state.workflowToBeCreated.loading = true;
    state.workflow.info = null;
  },
  createdWorkflow(state, info={}) {
    state.workflowToBeCreated.loading = false;
    state.workflow.info = info;
  },
  createWorkflowFailed(state, error) {
    state.workflow.loading = false;
    state.workflow.error = error;
  },

  requestFetchingWorkflowInfo(state) {
    state.workflow.loading = true;
    state.workflow.info = null;
  },
  fetchedWorkflowInfo(state, info={}) {
    state.workflow.loading = false;
    state.workflow.info = info;
  },
  fetchWorkflowInfoFailed(state, error) {
    state.workflow.loading = false;
    state.workflow.error = error;
  },

  requestFetchingWorkflowTree(state) {
    state.tree.loading = true;
  },
  fetchedWorkflowTree(state, { list: items=[], fields={} }) {
    state.tree.loading = false;
    state.tree.items = items;
    state.tree.fields = fields;
  },
  fetchWorkflowTreeFailed(state, error) {
    state.tree.loading = false;
    state.tree.error = error;
  },

  requestFetchingWorkflowNodeInfo(state) {
    state.node.loading = true;
  },
  fetchedWorkflowNodeInfo(state, info) {
    state.node.loading = false;
    state.node.info = info;
    state.setting = { data: {}, shareableTemplates:[], ...info.properties };
  },
  fetchWorkflowNodeInfoFailed(state, error) {
    state.tree.loading = false;
    state.tree.error = error;
  },

  requestFetchingWorkflowStatistic(state) {
    state.statistic.loading = true;
  },
  fetchedWorkflowStatistic(state, info={}) {
    state.statistic.loading = false;
    state.statistic.info = info;
  },
  fetchWorkflowStatisticFailed(state, error) {
    state.statistic.loading = false;
    state.statistic.error = error;
  },

  requestFetchingWorkflowStatisticTable(state) {
    state.statisticTable.loading = true;
  },
  fetchedWorkflowStatisticTable(state, list=[]) {
    state.statisticTable.loading = false;
    state.statisticTable.list = list;
  },
  fetchWorkflowStatisticTableFailed(state, error) {
    state.statisticTable.loading = false;
    state.statisticTable.error = error;
  },

  requestUpdateWorkflow(state) {
    state.workflowToBeUpdated.loading = true;
  },
  workflowUpdated(state, info) {
    const { info: currentInfo={} } = state.workflow;
    state.workflowToBeUpdated.loading = false;
    // NodeUtils.referenceUpdatge(state.workflow.info, info);
    state.workflow.info = { ...currentInfo, ...info };
  },
  updateWorkflowStatus(state, info) {
    const { info: currentInfo={} } = state.workflow;
    // NodeUtils.referenceUpdatge(state.workflow.info, info);
    state.workflow.info = { ...currentInfo, ...info };
  },
  updateWorkflowsRecordList(state, { workflow, info }) {
    let { list: currentList=[] } = state.workflows;
    if (currentList.length) {
      const offset = ReplaceItemForIndex(currentList, workflow);
      if (offset) {
        assign(currentList[offset.row].children[offset.col], info);
      }
    }
    state.workflow.list = [ ...currentList ];
  },
  workflowUpdateFailed(state, error) {
    state.workflowToBeUpdated.loading = false;
    state.workflowToBeUpdated.error = error;
  },

  requestRemoveWorkflow(state) {
    state.workflowToBeRemoved.loading = true;
  },
  workflowRemoved(state, { workflow, index, type }) {
    state.workflowToBeRemoved.loading = false;
    state.workflowToBeRemoved.item = workflow;
    const { list=[] } = state.workflows;
    const statistic = { ...state.statistic.info };
    // console.log(statistic, type);
    const workflows = [ ...list ];
    // the workflow list is children structure, so need to find the most parent index first according to the child index
    forEach(list, ({ children }, row) => {
      // only check for the item id in the specific children index
      if (children.length > index && children[index]._id === workflow._id) {
        workflows[row].children.splice(index, 1); // remove the item in children list for the current row
        if (!workflows[row].children.length) {
          workflows.splice(row, 1);
        }
        return;
      }
    });
    // real time increasment
    statistic[type] = add(statistic[type], -1);
    state.statistic.info = statistic;
    state.workflows.list = workflows;
  },
  workflowRemovedFailed(state, error) {
    state.workflowToBeRemoved.loading = false;
    state.workflowToBeRemoved.error = error;
  },


  requestDuplicateWorkflow(state) {
    state.workflowToBeDuplicated.loading = true;
  },
  workflowDuplicated(state, { newWorkflow, workflow, index, type }) {
    state.workflowToBeDuplicated.loading = false;
    state.workflowToBeDuplicated.item = workflow;
    // const fields = [
    //   'createdAt',
    //   'updatedAt',
    //   'name',
    //   'status',
    //   '_id',
    //   'sourceForm',
    // ];
    // const info = pick(newWorkflow, fields);
    // const cloned = { ...workflow, ...info, id: info._id };
    const { list=[] } = state.workflows;
    // const statistic = { ...state.statistic.info };
    const workflows = [ ...list ];
    forEach(list, ({ _id }, row) => {
      if (_id === newWorkflow.sourceForm) {
        if (!workflows[row].children) {
         workflows[row].children = [];
        }
        workflows[row].children.splice(index + 1, 0, newWorkflow); // insert after the workflow row selected
        return;
      }
    });
    // real time increasment
    // statistic[type] = add(statistic[type], 1);
    // state.statistic.info = statistic;
    state.workflows.list = workflows;
  },
  workflowDuplicatedFailed(state, error) {
    state.workflowToBeDuplicated.loading = false;
    state.workflowToBeDuplicated.error = error;
  },
}

const actions = {
  [VALIDATION_UPDATE_EDIT_NODE] ({commit}) {
    commit('updateEditingNodeChecking');
  },
  [UPDATE_WORKFLOW_STATUS]({dispatch, commit}, payload) {
    commit('updateWorkflowStatus', payload.info);
    // if current list view on workflow record table page
    commit('updateWorkflowsRecordList', payload);
    commit('clearValidationResult');
  },
  [RESET_EDITOR]({commit}) {
    // console.log('Reset Workflow Tree State');
    commit('resetTreeState');  
  },
  [SET_SETTINGS]({commit}, info) {
    commit('setSetting', info);
  },
  [FETCH_WORKFLOW_RELEASE_HISTORY_LIST]({dispatch, commit}, { workflow, params={} }) {
    commit('requestEventHistories', params);  
    const options = {
      urlCommand: `/workflow/tree/${workflow}/release/trigger/histories`,
      options: {
        params,
      },
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('loadedEventHistories', response);
    }).catch(err => {
      commit('loadEventHistoriesFailed', err.message);
    })
  },
  [FETCH_WORKFLOW_RELEASE_HISTORY_INFO]({dispatch, commit}, { workflow, log }) {
    commit('requestEventHistoryDetail');  
    const options = {
      urlCommand: `/workflow/tree/${workflow}/release/trigger/histories/${log}`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('loadedEventHistoryDetails', response);
    }).catch(err => {
      commit('loadEventHistoryDetailsFailed', err.message);
    })
  },
  [FETCH_WORKFLOW_NODE_INFO]({dispatch, commit}, { workflow, node }) {
    commit('requestFetchingWorkflowNodeInfo');  
    const options = {
      urlCommand: `/workflow/node/${workflow}/${node}`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedWorkflowNodeInfo', response);
    }).catch(err => {
      commit('fetchWorkflowNodeInfoFailed', err.message);
    })
  },
  [COPY_WORKFLOW_NODE]({dispatch, commit}, { nodes, parent, workflowInfo, workflow }) {
    return new Promise((done, reject) => {
      commit('requestCopyNode');
      const options = {
        urlCommand: `/workflow/node/${workflow}/${parent}/copy`,
        data: {
          nodes,
        },
      };
      dispatch('AUTH_POST', options).then(response => {
        commit('copiedNode', response);
        commit('updateWorkflowStatus', RealTimePickReleaseStatus(workflowInfo));
        // 1.0 performance issue, no validation in starting editing mode
        // dispatch(VALIDATE_WORKFLOW, workflow);
        done();
      }).catch(err => {
        commit('copyNodeFailed', err.message);
        reject();
      })
    });
  },
  [REMOVE_WORKFLOW_NODE]({dispatch, commit}, { node, workflowInfo, workflow }) {
    return new Promise((done, reject) => {
      commit('requestRemoveNode');
      const options = {
        urlCommand: `/workflow/node/${workflow}/${node}`,
        data: node,
      };
      dispatch('AUTH_DELETE', options).then(response => {
        commit('removedNode', response);
        commit('updateWorkflowStatus', RealTimePickReleaseStatus(workflowInfo));
        // 1.0 performance issue, no validation in starting editing mode
        // dispatch(VALIDATE_WORKFLOW, workflow);
        done();
      }).catch(err => {
        commit('removeNodeFailed', err.message);
        reject();
      })
    });
  },
  [UPDATE_WORKFLOW_NODE]({dispatch, commit, state}, { node, workflow, workflowInfo, info, appId }) {
    return new Promise((done, reject) => {
      commit('requestUpdateNode');
      const options = {
        urlCommand: `/workflow/node/${workflow}/${node._id}/update`,
        data: {
          ...info,
          appId,
        },
      };
      dispatch('AUTH_PUT', options).then(response => {
        // console.log();
        commit('updatedNode', { node, info });
        commit('updateWorkflowStatus', RealTimePickReleaseStatus(workflowInfo)); 
        // console.log(dataSource, response);
        // commit('assignSubWorkflow', { items: state.tree.items, info: response });
        // dispatch(VALIDATE_WORKFLOW, workflow);
        // commit('loadedValidation', response.validationResult);  
        done({ info, response });
      }).catch(err => {
        commit('updateNodeFailed', err.message);
        reject(err.message);
      })
    });
  },
  [ADD_WORKFLOW_NODE]({dispatch, commit}, { node, workflow, workflowInfo, parent, mergeToBranch, dependsOnParent, appId }) {
    commit('requestAddNode');
    const options = {
      urlCommand: `/workflow/node/${workflow}/${parent}/add`,
      data: {
        ...node,
        mergeToBranch,
        requiredDependsOn: dependsOnParent,
        appId,
      },
    };
    dispatch('AUTH_POST', options).then(response => {
      commit('addedNode', response);
      commit('updateWorkflowStatus', RealTimePickReleaseStatus(workflowInfo));
      // dispatch(VALIDATE_WORKFLOW, workflow);
    }).catch(err => {
      commit('addNodeFailed', err.message);
    })
  },
  [RESET_WORKFLOW_VALIDATE] ({commit}) {
    commit('resetValidation');
  },
  [LOG_VALIDATE_WORKFLOW] ({commit}, response) {
    commit('loadedValidation', response);
  },
  [VALIDATE_WORKFLOW] ({dispatch, commit}, workflow, node) {
    commit('requestValidation');
    return new Promise((done, reject) => {
      const options = {
        urlCommand: `/workflow/tree/${workflow}/release/mock/validate`,
      };
      dispatch('AUTH_GET', options).then(response => {
        commit('loadedValidation', response);  
        // dispatch(VALIDATION_UPDATE_EDIT_NODE, response)
        done(response);
      }).catch(err => {
        commit('loadValidationFailed', err.message);
        reject(err.message);
      })
    });
  },
  [UPDATE_WORKFLOW] ({dispatch, commit}, { info, workflow }) {
    commit('requestUpdateWorkflow');
    const options = {
      urlCommand: `/workflow/tree/${workflow}`,
      data: info,
    };
    dispatch('AUTH_PUT', options).then(response => {
      // no need to anything
      commit('workflowUpdated', info);
      commit('updateWorkflowStatus', PickWorkflowStatus(response));  
    }).catch(err => {
      commit('workflowUpdateFailed', err.message);
    })
  },
  [CREATE_WORKFLOW] ({dispatch, commit}, data, params) {
    commit('requestCreatingWorkflow');
    return new Promise((done, reject) => {
      const options = {
        urlCommand: `/workflow/tree`,
        data,
      };
      dispatch('AUTH_POST', options).then(response => {
        commit('createdWorkflow', response);
        try {
          dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_workflow_add",
            data: {
              workflow_name: data.info.name,
              workflow_type:data.info.type === "timer" ? "自動-定時型":"自動-觸發型",
              app_id:data.appId
            }
          });
          app.$sensors.track("plus_workflow_add",{
            workflow_name: data.info.name,
            workflow_type:data.info.type === "timer" ? "自動-定時型":"自動-觸發型"
          })
        } catch(err){
          console.error("workflow-tree::CREATE_WORKFLOW, cannot send track info, err=", err)
        }

        dispatch(FETCH_WORKFLOW_INFO, response._id);
        dispatch(FETCH_WORKFLOW_TREE, response._id);
        done(response._id);
      }).catch(err => {
        commit('createWorkflowFailed', err.message);
        reject(err.message);
      })
    });
  },
  [DUPLICATE_WORKFLOW] ({dispatch, commit}, { workflow, type, appId, name, index }) {
    commit('requestDuplicateWorkflow');
    const options = {
      urlCommand: `/workflow/tree/${workflow._id}/duplicate`,
      data: {
        name,
        appId
      },
    };
    dispatch('AUTH_PUT', options).then(response => {
      commit('workflowDuplicated', { newWorkflow: response, type, workflow, index });
      dispatch(FETCH_WORKFLOW_STATISTIC, {
        params: { appId },
      });
        // , { type, params: { appId } });
    }).catch(err => {
      commit('workflowDuplicatedFailed', err.message);
    })
  },
  [REMOVE_WORKFLOW] ({dispatch, commit}, { workflow, index, type }) {
    commit('requestRemoveWorkflow');
    const options = {
      urlCommand: `/workflow/tree/${workflow._id}`,
    };
    dispatch('AUTH_DELETE', options).then(response => {
      commit('workflowRemoved', { workflow, index, type });
    }).catch(err => {
      commit('workflowRemovedFailed', err.message);
    })
  },
  [FETCH_WORKFLOW_INFO] ({dispatch, commit}, workflowId) {
    commit('requestFetchingWorkflowInfo');
    const options = {
      urlCommand: `/workflow/info/${workflowId}`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedWorkflowInfo', response);
    }).catch(err => {
      commit('fetchWorkflowInfoFailed', err.message);
    })
  },
  [FETCH_WORKFLOW_TREE] ({dispatch, commit}, workflowId) {
    commit('requestFetchingWorkflowTree');
    const options = {
      urlCommand: `/workflow/tree/${workflowId}`,
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedWorkflowTree', response);
    }).catch(err => {
      commit('fetchWorkflowTreeFailed', err.message);
    })
  },
  [FETCH_WORKFLOW_STATISTIC_TABLE] ({dispatch, commit}, { type='form', params={} }) {
    commit('requestFetchingWorkflowStatisticTable');
    const options = {
      urlCommand: `/workflow/tree/statistic/table/${type}`,
      options: {
        params,
      },
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedWorkflowStatisticTable', response);
    }).catch(err => {
      commit('fetchWorkflowStatisticTableFailed', err.message);
    })
  },
  [FETCH_WORKFLOW_STATISTIC] ({dispatch, commit}, options={}) {
    commit('requestFetchingWorkflowStatistic');
    console.log('> options: ', options);
    const payload = {
      urlCommand: '/workflow/tree/statistic',
      options,
    };
    dispatch('AUTH_GET', payload).then(response => {
      commit('fetchedWorkflowStatistic', response);
    }).catch(err => {
      commit('fetchWorkflowStatisticFailed', err.message);
    })
  },
  [FETCH_WORKFLOW_LIST] ({dispatch, commit}, { type='form', keyword='', table, ...params }) {
    commit('requestFetchingWorkflowList');
    const options = {
      urlCommand: `/workflow/tree/list/${type}`,
      options: {
        params: {
          keyword,
          type: table,
          ...params,
        },
      },
    };
    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedWorkflowList', response);
    }).catch(err => {
      commit('fetchWorkflowListFailed', err.message);
    })
  },
}

export default {
  state: initialState(),
  getters,
  mutations,
  actions
}
