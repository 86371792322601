<template>
  <div class="home d-flex flex-column justify-center">
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    <v-alert type="warning" color="orange" text dismissible max-width="fit-content" class="align-self-center mt-3" v-if="tokenExpired">
      {{ $t('messages.tokenExpired') }}
    </v-alert>
    <yoovPlusLogo v-if="!initializing" />
  </div>
</template>

<script>
// @ is an alias to /src
import yoovPlusLogo from '@/components/YoovPlusLogo.vue'

export default {
  name: 'Home',
  data() {
    return {
      initializing: true,
    }
  },
  computed: {
    tokenExpired() {
      return ( this.$route.query.tokenExpired ) ? true : false;
    }
  }, 
  components: {
    yoovPlusLogo
  },
  async beforeMount() {
    try {
      const accessToken = await this.$store.dispatch('CHECK_LOCAL_TOKEN');
      if ( accessToken ) {
        this.$router.push({name : 'myApps' })
      } else {
        this.initializing = false;

      }
    } catch (err) {
      //console.error( err)
      this.initializing = false;
      return false;
    }
  },
  mounted(){
        this.$sensors.registerPage({
        is_login:false,
        is_member:false,
        company_id:"",
        company_name:"",
        platform_name:"YOOV+",
        language_preference: ""
      });
  }
}
</script>
