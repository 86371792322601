<template>
  <v-dialog width="360" 
    v-model="showingDialog">
    <v-card>
      <v-card-title class="px-0 justify-center">
        <div>{{ mode==='login' ? $t('guest.guestLogin') : $t('guest.guestRegister') }}</div>
      </v-card-title>
      <v-card-text :style="{height: mode==='login'?'370px':'450px'}" class="pt-2">
        <div>
          <v-form ref="form1" v-model="isFormValid">
            <v-row no-gutters class="mb-1">
              <div class="d-flex flex-column w-100 align-items-stretch">
                <label>{{ $t('guest.email') }}*</label>
                <v-text-field 
                  v-model="credential.userId"
                  :rules="[RULE_REQUIRED]"
                  prepend-inner-icon="mdi-account"
                  solo dense outlined></v-text-field>
              </div>
            </v-row>
            <v-row no-gutters>
              <div class="d-flex flex-column w-100 align-items-stretch">
                <label>{{ $t('guest.password') }}*</label>
                <v-text-field
                  :append-icon="showPassword?'mdi-eye':'mdi-eye-off'"
                  :rules="[RULE_REQUIRED]"
                  prepend-inner-icon="mdi-key"
                  solo dense outlined
                  :type="showPassword?'text':'password'"
                  v-model="credential.password"
                  @click:append="showPassword=!showPassword"
                  ></v-text-field>
              </div>
            </v-row>
            <v-row v-if="mode==='register'" no-gutters>
              <div class="d-flex flex-column w-100 align-items-stretch">
                <label>{{ $t('guest.passwordConfirm') }}*</label>
                <v-text-field
                  :append-icon="showPassword?'mdi-eye':'mdi-eye-off'"
                  :rules="[RULE_REQUIRED, passwordConfirmationRule]"
                  prepend-inner-icon="mdi-key"
                  solo dense outlined
                  :type="showPasswordConfirm?'text':'password'"
                  v-model="credential.passwordConfirm"
                  @click:append="showPasswordConfirm=!showPasswordConfirm"
                  ></v-text-field>
              </div>
            </v-row>

            <v-row no-gutters class="mt-3">
              <!-- <vue-recaptcha sitekey="recaptchaSiteKey"></vue-recaptcha> -->
            </v-row>

            <v-row no-gutters>
              <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    @render="onRender"
                    @onload="onload"
                    @error="onError"
                    :loadRecaptchaScript="true"
                    sitekey="6LeFEUEcAAAAAOYbTYpLzYDc_VW-nhTGvbQ0E_mR"></vue-recaptcha>
            </v-row>

            <v-row no-gutters class="mt-5 mb-1">
              <div class="w-100">
                  <!-- :disabled="buttonDisabled || !captchaVerified" -->
                <v-btn
                  :disabled="buttonDisabled || (validHost && !captchaVerified)"
                  class="elevation-0 w-100"
                  color="primary"
                  @click="submit">{{ mode==='login' ? $t('guest.login') : $t('guest.register') }}</v-btn>
  
              </div>
            </v-row>
            <v-row no-gutters class="my-3">
              <div class="d-flex flex-row w-100 justify-space-between">
                <v-btn text
                  height="24"
                  style="min-width:auto !important;"
                  class="px-0"
                  @click="onSwitchModeClicked">{{ mode==='login' ? $t('guest.register') : $t('guest.login') }}</v-btn>
                <!-- <v-btn small
                  class=""
                  @click="fillForTest">Fill (test)</v-btn> -->
              </div>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
   
    </v-card>   
  </v-dialog>
</template>

<script>
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";
import ValidationsHelper from "@/helpers/ValidationsHelper";
import { VueRecaptcha } from 'vue-recaptcha';

// import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'guestLoginDialog',
  components: {
    DialogHeader,
    DialogActions,
    VueRecaptcha,
    // VueRecaptcha
  },
  data () {
    return {
      captchaVerified: false,
      showingDialog: false,
      showPassword: false,
      callback: null,
      credential: {
        userId: '',
        password: '',
        passwordConfirm: ''
      },
      mode: 'login', // ['login'|'register']
      RULE_REQUIRED: (v) =>
        !ValidationsHelper.isEmpty(v) ||
        this.$t("messages.thisFieldIsRequired"),
      isFormValid: false
    }
  },
  watch: {
    'credential.userId': function(newVal) {
      this.checkValidity()
    },
    'credential.password': function(newVal) {
      this.checkValidity()
    },
    'credential.passwordConfirm': function(newVal) {
      this.checkValidity()
    }
  },
  computed: {
    buttonDisabled () {
      return !this.isFormValid
    },
    recaptchaSiteKey () {
      return process.env.GOOGLE_RECAPTCHA_SITE_KEY
    },
    passwordConfirmationRule() {
      return () => (this.credential.password === this.credential.passwordConfirm) || this.$t('guest.passwordMustMatch')
    },
    validHost () {
      const hostName = window.location.hostname
      const VALID_HOST = 'yoov.com'
      return hostName.substr(hostName.length - VALID_HOST.length) === VALID_HOST
    }
  },
  methods: {
    resetCaptcha () {
      this.$refs.recaptcha.reset()
      this.captchaVerified = false
    },

    onload () {
      console.log('onload')
    },
    onRender () {
      this.resetCaptcha()
      console.log('onRender')
    },
    onVerify () {
      console.log('onVerify')
      this.captchaVerified = true
    },
    onExpired () {
      console.log('onExpired')
      this.captchaVerified = false
    },
    onError () {
      console.log('onError')
      this.captchaVerified = false
    },
    fillForTest () {
      this.credential.userId = 'peterchan@gmail.com'
      this.credential.password = 'abc12345'
      this.credential.passwordConfirm = 'abc12345'
    },
    checkValidity () {
      this.$refs.form1.validate()
    },
    emptyInput() {
      this.credential.userId = ''
      this.credential.password = ''
      this.credential.passwordConfirm = ''
    },
    resetCredential () {
      this.credential.userId = ''
      this.credential.password = ''
      this.credential.passwordConfirm = ''
    },
    mounted () {
      this.resetCredential()
      this.resetValidation()
    },
    submit() {
      switch (this.mode) {
        case 'login': 
          this.login()
          break
        case 'register':
          this.register()
          break
      }
    },
    // setupTest () {
    //   this.credential.userId = 'peterchan@gmail.com'
    //   this.credential.password = 'asdf1234'
    //   this.credential.passwordConfirm = 'asdf1234'
    // },
    onSwitchModeClicked () {
      this.switchMode()
    },
    switchMode (mode) {
      this.emptyInput()

      if (typeof mode === 'undefined') {
        mode = this.mode === 'login' ?
          'register' :
          'login'
      }
      // console.log('switchMode mode = ' + mode)
      console.log('switchMode mode = ' + mode)
      try {
        console.log('mode = ' + mode)
        this.mode = mode
        console.log('mode1 = ' + mode)
        this.$refs.form1.resetValidation()
        console.log('mode2 = ' + mode)
        this.resetCaptcha()
        console.log('mode3 = ' + mode)
      } catch (err) {
        console.log('switchMode :: err: ', err)
      }

      // this.setupTest()
      // if (this.mode === 'login') {
      //   this.mode = 'register'        
      // } else {
      //   this.mode = 'login'
      // }
    },
    open (callback) {
      this.showingDialog = true
      this.callback = callback
      this.resetCredential()
      this.resetValidation()
    },
    close (payload) {
      this.showingDialog = false
    },
    gotoSignup () {
      console.log('gotoSignup')
      if (typeof this.callback === 'function') {
        this.callback({command: 'gotoSignup'})
      }
    },
    login () {
      if (typeof this.callback === 'function') {
        this.callback({
          command: 'loginAsGuest',
          payload: {
            userId: this.credential.userId,
            password: this.credential.password
          }
        })
      }
    },
    register () {
      if (typeof this.callback === 'function') {
        this.callback({
          command: 'registerAsGuest',
          payload: {
            userId: this.credential.userId,
            password: this.credential.password
          }
        })
      }
    }
  }

}
</script>