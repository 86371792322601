<template>
<v-container v-if="widget" class="widget-wrapper" style="float:left;"
  :style="itemWidthStyle">
  <v-tooltip bottom>
    <template v-slot:activator="{on, attrs}">
      <div class="widget-type-item d-flex flex-row justify-start align-center"
        v-on="on"
        v-bind="attrs"
          @click="onWidgetClicked"
          :class="{'selected':selected===widget}">
        <v-icon left v-if="widget.mdiIcon">{{ widget.mdiIcon }}</v-icon>
        <font-awesome-icon v-else-if="widget.icon" :icon="widget.icon" class="fa-fw mr-3"></font-awesome-icon>
        <small class="flex-grow-1 nowrap-ellipsis">{{ widgetLabel }}</small>
        <div v-if="appendChip">
          <v-chip small color="grey lighten-1">
            <div class="d-flex flex-column line-height-1">
              {{ appendChip }}
            </div>
          </v-chip>
        </div>
      </div>
    </template>
    <span>{{ widgetLabel }}</span>
  </v-tooltip>
</v-container>
</template>

<script>
export default {
  computed: {
    widgetLabel () {
      const vm = this
      var result = vm.widget.label
      if (vm.widget.labelTag && vm.widget.labelTag !== '') {
        result = vm.$t('widgets.' + vm.widget.labelTag)
      }
      return result
    },
    itemWidthStyle () {
      const vm = this
      const percent = Math.floor(100 / vm.itemsPerRow)
      return {
        width: percent + '%'
      }
    }
  },
  props: {
    selected: {
      type: Object,
      default: null
    },
    appendChip: String,
    widget: {
      type: Object
    },
    itemsPerRow: {
      type: Number,
      default: 2
    },
    command: {
      type: String,
      default: 'addWidget'
    }
  },
  methods: {
    onWidgetClicked () {
      const vm = this
      // console.log('WidgetTypeItem :: addWidget')
      vm.$emit('onCommand', {
        command: vm.command,
        widget: vm.widget
      })
    }
  }
}
</script>

<style>
  .widget-type-item {
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, .05);
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 0.2rem;
    cursor: pointer;
    min-width: 130px;
  }

  .widget-type-item:hover {
    background-color: rgba(0,0,0,.1);
  }



  .widget-panel .widget-type-item.selected {
    border-color: #1565C0;
    color: #1565C0;
  }

  .widget-panel .widget-wrapper {
    padding: 1px;
    display: inline-block;
  }

</style>
