<template>
  <v-chip
      :color="disabled?color:''"
      class="mr-1 mb-1 person-tag employee-chip w-100 d-inline-block cursor-pointer d-flex flex-row align-center"
      :close="showingClose"
      :small="small"
      :x-small="xSmall"
      :class="{'disabled': disabled, 'hover-close': hoverClose, 'multiple-teams': multipleTeams}"
      @click:close="onCloseClicked"
      @click="onClicked">
        <v-avatar v-if="value.avatar" style="left:-5px" size="18px" class="mr-1 rounded-pill white">
          <img :src="value.avatar" width="18px" />
        </v-avatar>
        <v-icon v-else style="left:-5px" class="mr-1 rounded-pill white" :color="disabled?'teal':'grey'" size="18">{{ personIcon }}</v-icon>
        <div class="d-inline-block">{{ name }}</div>
        <v-avatar color="info lighten-1" class=" ml-2" x-small v-if="badgeNo"
          :style="avatarWidth">{{ badgeNo }}</v-avatar>

        <!--<v-spacer></v-spacer>-->
        <!--<v-icon v-if="closeIcon">-->
          <!--mdi-close-->
        <!--</v-icon>-->

        <!--&lt;!&ndash;<v-btn v-if="deleteCommand"&ndash;&gt;-->
        <!--<v-btn v-if="deleteCommand"-->
               <!--class="ml-2 delete-button d-inline-block"-->
               <!--fab x-small-->
               <!--dark-->
               <!--color="red"-->
              <!--@click.stop="onDeleteClicked">-->
          <!--<v-icon>mdi-close</v-icon>-->
        <!--</v-btn>-->
    </v-chip>
</template>

<script>
export default {
  props: {
    name: String,
    value: [String, Number, Object],
    size: {
      type: String,
      default: ''
    },
    tagType: String,
    // deletable: {
    //   type: Boolean,
    //   default: false
    // },
    color: String,
    clickCommand: String,
    deleteCommand: String,
    hoverClose: Boolean,
    badgeNo: Number,
    disabled: Boolean,
    closeIcon: Boolean
  },
  computed: {
    small () {
      return this.size === 'small'
    },
    xSmall () {
      return this.size === 'xSmall'
    },
    multipleTeams () {
      return this.$store.getters.allTeams.length > 1
    },
    showingClose () {
      return this.hoverClose || this.deleteCommand
    },
    // deletable () {
    //   return this.deleteCommand !== ''
    // },
    avatarWidth () {
      const vm = this
      if (vm.badgeNo>=1000) {
        return {width: '34px !important'}
      } if (vm.badgeNo>=100) {
        return {width: '28px !important'}
      } else {
        return {}
      }
    },
    personIcon () {
      const vm = this
      switch (vm.tagType) {
        case 'admin':
          return 'mdi-account-tie'
        case 'role':
          return 'mdi-account-multiple'
        case 'department':
          return 'mdi-account-group-outline'
        default:
          return 'mdi-account'
      }
    }
  },

  methods: {
    onCloseClicked () {
      const vm = this
      console.log('PersonTag.vue :: onCloseClicked')
      vm.$emit('onCommand', {
        command: vm.deleteCommand,
        tagType: vm.tagType,
        value: vm.value
      })
    },

    onClicked () {
      const vm = this
      console.log('PersonTag.vue :: onClicked')
      if (!vm.disabled) {
        if (vm.clickCommand !== '') {
          vm.$emit('onCommand', {
            command: vm.clickCommand,
            tagType: vm.tagType,
            value: vm.value
          })
        }
      }
    }
  }
}
</script>

<style>
  .person-tag .delete-button {
    visibility: hidden;
  }
  .person-tag:hover .delete-button {
    visibility: visible;
  }
  .person-tag .disabled {
    pointer: default;
  }
  .person-tag.hover-close [data-icon=times-circle] {
    visibility: hidden;
  }
  .person-tag.hover-close:hover [data-icon=times-circle] {
    visibility: visible;
  }
</style>
