<template>
  <FormElement :name="name" :label="label" :hints="hints">
    <ComboBox
      v-bind="boxProps"
      :value="value || null"
      :options="normalizer(dataSources)"
      :onlyPrefix="true"
      :onlySuffix="true"
      :isMultiple="false"
      :placeholder="placeholder"
      :optionOutput="optionOutput"
      :shouldShowOutputNumber="shouldShowOutputNumber"
      valueFormat="object"
      :disableBranchNode="false"
      @changed="changed"
      :isBranch="true"
    />
  </FormElement>
</template>

<script>
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';

import Mixin from './../mixin';
import FormElement from './formElement';
import {
  FETCH_WORKFLOW_DEPENDENCIES,
  SET_SETTINGS,
  FORM_SELECTIONS_FIELDS,
  FORM_RELATIONSHIP_FIELDS,
} from '@/store/modules/workflow/action_types';
import { map, omit } from 'lodash'
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    value: Object,
    placeholder: String,
    shouldShowOutputNumber: Boolean,
    label: String,
    worksheet: [String, Object],
    optionOutput: String,
    name: String,
    fetchForRelationship: Boolean,
    boxProps: Object,
    hints: String,
    normalize: Function,
    noFetch: Boolean,
    fetchByNode: Boolean,
    // optionProps: Object,
  },
  // data() {
  //   return {
      
  //   }
  // },
  mounted() {
    if (!this.noFetch) {
      this.$store.dispatch(FETCH_WORKFLOW_DEPENDENCIES, {
        workflow: this.workflow._id,
        node: this.node._id,
        isBranch: true,
        noFieldFound: true,
        appId: this.appId,
      });
      this.fetchFields();
    }
  },
  computed: {
    dataSources() {
      return this.$store.getters.getDependencies || [];
    },
  },
  components: {
    ComboBox,
    FormElement,
  },
  methods: {
    fetchFields() {
      if (this.worksheet) {
        const { worksheet } = this;
        const formId = worksheet._id || worksheet;
        this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
          appId: this.appId,
          formId,
        });
        if (this.fetchForRelationship) {
          this.$store.dispatch(FORM_RELATIONSHIP_FIELDS, {
            appId: this.appId,
            formId,
          });
        }
      }
    },
    changed(v) {
      let setting = {
        dataSource: v,
        hookFields: [],
        rules: [],
        sorting: {},
        data: {},
        shareableTemplates:[]
      };
      if (this.fetchForRelationship) {
        setting = { ...setting, worksheet: null, relationshipField: null };
      }
      this.$store.dispatch(SET_SETTINGS, setting);
    },
    normalizer(list) {
      let normalized = map(list, l => omit(l, ['children']));
      if (this.normalize) {
        normalized = this.normalize(normalized);
      }
      return normalized;
    },
  },
  watch: {
    value() {
      this.fetchFields();
    },
    deep: true,
  }
};

</script>

<style scoped>
</style>
