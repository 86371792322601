<template>
  <div>
    <div v-if="copying" class="workflow-node-box-block-copy" />
    <div v-if="copying && !copyless()" class="workflow-node-item-to-copy">
      <v-btn
        icon
        @click="copy"
        class="workflow-node-item-avatar-to-copy-icon"
      >
        <v-icon small :style="toCopyStyle()">
          {{ numberOfCopy() || 'mdi-check' }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  ADD_COPIED_NODE,
} from '@/store/modules/workflow/action_types';
import {
  WorkflowNodeIdentify,
} from './../../../../constants/node';
import {
  includes,
} from 'lodash';
/*
* Single node copy overlay
* Terry Chan
* 26/05/2021
*/
export default {
  // , ToggleAddingMixin],
  props: {
    node: Object,
  },
  methods: {
    copy() {
      this.$store.dispatch(ADD_COPIED_NODE, this.node);
    },
    toCopyStyle() {
      if (this.copiedNodes.has(this.node.id))  {
        return {
          background: '#3ea0ff',
          color: 'white',
        };
      }
      return null;
    },
    numberOfCopy() {
      if (this.copiedNodes.has(this.node.id))  {
        const keys = Array.from(this.copiedNodes.keys());
        return keys.indexOf(this.node.id) + 1;
      }
      return 0;
    },
    copyless() {
      // the root node cannot be copy
      return !this.node.parent || !includes([
        WorkflowNodeIdentify.common
      ], this.node.nodeType);
    },
  },
  computed: {
    copying() {
      return this.$store.state.workflowModules.copyingNode;
    },
    copiedNodes() {
      return this.$store.state.workflowModules.copiedNode;
    }
  },
};

</script>

<style scoped>
  
</style>
