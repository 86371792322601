<template>
  <v-card-title class="px-0 py-0">
    <div class="d-flex flex-column w-100">
      <v-toolbar
        dark
        elevation="0"
        height="32"
        class="dialog-header px-0 w-100"
        :color="color"
        :class="toolbarClass"
      >
        <v-icon v-if="icon && icon !== ''" class="mr-2">{{ icon }}</v-icon>

        <click-input v-if="value !== null" v-model="value"></click-input>
        <span v-else class="nowrap-ellipsis" style="font-size: 15px">{{
          label
        }}</span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="closable"
          closeicon
          small
          :disabled="actionDisabled"
          icon
          plain
          :ripple="false"
          @click="$emit('close')"
          class="ma-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <slot></slot>
    </div>
  </v-card-title>
</template>

<script>
import clickInput from "@/components/formComps/ClickInput";

export default {
  components: {
    clickInput,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    hasInput: {
      type: Boolean,
      default: false,
    },
    icon: String,
    label: String,
    value: {
      type: String,
      default: null,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    actionDisabled: {
      type: Boolean,
      default: false,
    },
    saveButton: {
      type: Boolean,
      default: false,
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    toolbarClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.dialog-header .v-toolbar__content {
  padding: 0 4px 0 16px !important;
}
</style>
