const moment = require("moment");
const mixin = {
  computed:{
    locale(){
      return this.$store.getters.locale
    }
  },
  methods: {
    getObjectValue(
      fieldId,
      obj,
      fieldInfo,
      fieldInfos,
      relatedTableData = {},
      relatedTableInfos = {}
    ) {
      let result = obj
      let field = fieldInfos.find((item) => item.fieldId == fieldId);
      if (field) {
        let type = field.type;
        switch (type) {
          case "region":
            result = obj && obj.regionText || ""
            break;
          case "departments":
            result = Array.isArray(obj) && obj.map((item) => item.label) || []
            break;
          case "createdBy":
          case "owner":
            result = (obj && obj.employeeName) || "";
            break;
          case "members":
            result = Array.isArray(obj) && obj.map((item) => item.employeeName) || [];
            break;
          case "singleSelection": {
            const option = field.properties.inputOptionsSingle.options.find(
              (item) => item._id === obj
            );
            result =  (option && option.label) || "";
            break;
          }
          case "multipleSelection":
            result = Array.isArray(obj) && field.properties.inputOptionsMultiple.options
            .filter((item) => obj.includes(item._id))
            .map((item) => item.label) || []
            break;
          case "location":
            if (obj && obj.formatted_address)
              result =  (obj.name ? obj.name + ", " : "") + obj.formatted_address;
            else result =  "";
            break;
          case "relatedRecord": {
            let relatedRecord = relatedTableData[fieldId];
            let relatedDataTable =
              relatedTableInfos[field.properties.dataSource];
            if (
              Array.isArray(relatedRecord) &&
              relatedRecord.length > 0 &&
              relatedDataTable
            ) {
              let titleFieldInfoId = relatedDataTable.titleFieldInfoId;
              const relatedFieldInfo = relatedDataTable.fieldInfos.find(info => info.fieldId === titleFieldInfoId)

              result =
              this.getObjectValue(
                titleFieldInfoId,
                relatedRecord[0][titleFieldInfoId],
                relatedFieldInfo,
                relatedDataTable.fieldInfos,
                relatedTableData,
                relatedTableInfos
              ) || "";
            } else result =  "";
            break;
          }
          case "otherTableField": {
            const {
              fieldForRelatedTable,
              relatedTableField,
            } = field.properties;
            const relatedRecord = relatedTableData[fieldForRelatedTable];
            if (Array.isArray(relatedRecord) && relatedRecord.length > 0) {
              result =  relatedRecord[0][relatedTableField] || "";
            } else result = "";
            break;
          }
          
          case "number":
          case "amount":
          case 'expression':
          case "summaryField": {
            if(type === "expression" && field.properties.expressionType ==="date"){
              const {dateFormatSettings} = fieldInfo.properties || {};
              const format = 
              (dateFormatSettings &&
                dateFormatSettings[fieldId]) || "";
              result = result ? (format ? new moment(result).format(format): result):"";
            }
            else if (!isNaN(obj)) {
              const decimals = parseInt(field.properties.decimals || "0");
              if (decimals > 0) {
                result = parseFloat(obj || "0")
                .toFixed(decimals)
                .toString();
              } else result = parseInt(obj || "0").toString();
            }

            const { fixedLengthSettings } = fieldInfo.properties;
            if (field.properties.expressionType !=="date" && fixedLengthSettings && fixedLengthSettings[fieldId]) {
              result = result.padStart(parseInt(fixedLengthSettings[fieldId]), "0");
            }
           break;
          }
          case "date":{
            if (
              obj &&
              fieldInfo.properties.dateFormatSettings &&
              fieldInfo.properties.dateFormatSettings[fieldId]
            ) {
              let format = fieldInfo.properties.dateFormatSettings[fieldId];
              result = new moment(result).locale(this.locale).format(format);
            }
            break;
          }
          case "createdAt":
          case "updatedAt":{
            if (
              obj &&
              fieldInfo.properties.dateFormatSettings &&
              fieldInfo.properties.dateFormatSettings[fieldId]
            ) {
              let format = fieldInfo.properties.dateFormatSettings[fieldId];
              result = new moment(result).tz("Asia/Hong_Kong").locale(this.locale).format(format);
            }else
              result = new moment(result).tz("Asia/Hong_Kong").locale(this.locale)
            break;
          }
        }
      }
      return result
    },
    getCombinedField(combineText, recordData, fieldInfo, fieldInfos, relatedTableData = {}, relatedTableInfos={}) {
      const regex = /\$\{\w+\}/;
      while (regex.test(combineText)) {
        let tag = combineText.match(regex)[0];
        let key = tag.match(/\w+/)[0];
        let replaceString = recordData[key];
        combineText = combineText.replace(
          tag,
          this.getObjectValue(
            key,
            replaceString,
            fieldInfo,
            fieldInfos,
            relatedTableData ,
            relatedTableInfos
          )|| ""
        );
        
        combineText = combineText.trim();
      }
      return combineText;
    },
  },
};

export default mixin;
