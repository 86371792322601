<template>
  <div style="background-color:blue;height:100%;width:100%;">

  </div>
</template>

<script>
export default {
  

}
</script>