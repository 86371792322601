<template>
  <div class="d-flex flex-row">
        <div class="d-flex mb-3 justify-start"
          :class="{'flex-row align-center':!vertical,'flex-column align-start':vertical}">
          <label class="mr-3 input-label" style="width:40px;">
            {{ $t('general.icon') }}</label>
          <v-avatar v-if="disabled" style="width:28px;height:28px;min-width:28px;">
            <v-icon class="black--text">{{ iconIfDisabled }}</v-icon>
          </v-avatar>
          <v-btn v-else-if="appIcon && appIcon !== ''"
                 small
                 :disabled="disabled"
                 icon
                 class="color-input black--text text-center"
                 relative
                 ref="iconInputButton"
                 @click="showingDialog=true">
            <v-icon>{{ appIcon }}</v-icon>
          </v-btn>
          <v-btn v-else
                 small
                 class="elevation-0 color-input black--text text-center"
                 relative
                 v-bind="attrs"
                 style="min-width:80px !important;"
                 @click="showingDialog=true">
            {{ $t('general.select') }}
          </v-btn>
        </div>
    <v-dialog
        v-model="showingDialog"
        v-if="showingDialog"
        width="500">
      <v-card class="">
        <!-- <v-card-title class="headline grey lighten-2">{{ $t('messages.selectIcon') }}
          <v-spacer></v-spacer> 
            { inputIcon }}
        </v-card-title> -->
        <dialog-header
          :label="$t('messages.selectIcon')"
          @close="showingDialog = false"
          color="#E0E0E0"
          toolbarClass="grey--text"
        />
        <v-card-text class="pt-2 pb-0 icon-input-dialog-body">
          <div class="flex-grow-1 d-flex flex-column justify-start">
            <div style="overflow:hidden;vertical-align:top;">
              <div class="icon-wrapper pa-1 d-inline-block"
                  :class="{'selected':inputIcon===icon}"
                  v-for="(icon,index) in icons"
                  @click="selectIcon(icon)">
                <v-icon>{{ icon }}</v-icon>
              </div>
            </div>
          </div>

          <div class="mb-1 text-center grey white--text">
            <small color="gray lighten-1">{{ $t('general.page') }} {{ iconPagination.page }} / {{ iconPagination.totalPages }}</small>
          </div>

          <div class="d-flex flex-row justify-space-between mb-2">
            <div class="btn-group mx-auto align-self-center">
              <v-btn text @click="gotoFirstPage" :disabled="iconPagination.page===1">
                <v-icon>mdi-skip-previous</v-icon>
              </v-btn>
              
              <v-btn text @click="gotoPreviousPageBy5" :disabled="iconPagination.page===1">
                <v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>

              <v-btn text @click="gotoPreviousPage" :disabled="iconPagination.page===1">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

              <v-btn text @click="gotoNextPage" :disabled="iconPagination.page===iconPagination.totalPages">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>

              <v-btn text @click="gotoNextPageBy5" :disabled="iconPagination.page===iconPagination.totalPages">
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>

              <v-btn text @click="gotoLastPage" :disabled="iconPagination.page===iconPagination.totalPages">
                <v-icon>mdi-skip-next</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="min-width-80"
                 color="muted"
                 @click="showingDialog=false">{{ $t('buttons.cancel') }}</v-btn>
          <v-btn class="min-width-80"
                 color="primary"
                 @click="confirm">{{ $t('buttons.ok') }}</v-btn>
        </v-card-actions> -->
        <dialog-actions 
          :buttons="['cancel', 'ok']"
          @onCommand="onCommandHandler($event)"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
  name: 'iconInputField',
  components:{
    dialogHeader,
    dialogActions
  },
  data () {
    return {
      showingDialog: false,
      inputIcon: '',

      defaultIconIndex: 0,
      iconPagination:
        {
          page: 1,
          limit:
            140,
          totalDocs:
            0,
          totalPages:
            0,
          pagingCounter:
            0
        }
      ,
      icons: []
    }
  },
  model: {
    prop: 'appIcon',
    event: 'input'
  },

  watch: {
    'iconPagination.page': function (newValue) {
      const vm = this
      vm.loadIcons()
    },
    appIcon: function(newValue) {
      this.inputIcon = newValue

    }
  },
  mounted () {
    const vm = this
    vm.loadIcons()
    if (vm.appIcon) {
      console.log('mounted: vm.appIcon exists')
      vm.inputIcon = vm.appIcon
    }
    // } else {
    //   console.log('mounted: vm.appIcon not exist')
    //   vm.fetchRandomIcon().then(
    //     icon => {
    //       vm.inputIcon = icon
    //       console.log('vm.inputIcon = ' + vm.inputIcon)
    //     }
    //   )
    // }
  },
  props: {
    appIcon: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconIfDisabled: {
      type: String,
      default: 'mdi-folder'
    }
  },
  computed: {},
  methods: {
    onCommandHandler(payload){
      if(payload.command=='ok')
        this.confirm()
      else
        this.showingDialog = false
    },
    gotoFirstPage () {
      const vm = this
      vm.gotoPage(1)
    },
    gotoPreviousPage () {
      const vm = this
      vm.gotoPage(vm.iconPagination.page - 1)
    },
    gotoPreviousPageBy5 () {
      const vm = this
      vm.gotoPage(vm.iconPagination.page - 5)
    },
    gotoNextPageBy5 () {
      const vm = this
      vm.gotoPage(vm.iconPagination.page + 5)
    },
    gotoNextPage () {
      const vm = this
      vm.gotoPage(vm.iconPagination.page + 1)
    },
    gotoLastPage () {
      const vm = this
      vm.gotoPage(vm.iconPagination.totalPages)
    },
    gotoPage (pageNo) {
      const vm = this
      if (pageNo < 1) {
        pageNo = 1
      }
      if (pageNo > vm.iconPagination.totalPages) {
        pageNo = vm.iconPagination.totalPages
      }
      vm.iconPagination.page = pageNo
    },
    loadIcons () {
      const vm = this
      const getParams = {
        urlCommand: '/mdi_icons',
        options: {
          params: {
            page: vm.iconPagination.page,
            limit: vm.iconPagination.limit
          }
        }
      }
      vm.$store.dispatch('AUTH_GET', getParams).then(
        response => {
          console.log('AppsInfoDialog :: loadIcons :: response: ', response)
          vm.icons = response.docs.map(item => item.name)
          if (vm.icons.length > 0 && vm.inputIcon === '') {
            if (vm.defaultIconIndex < vm.icons.length) {
              vm.inputIcon = vm.icons[vm.defaultIconIndex]
            } else {
              vm.inputIcon = vm.icons[0]
            }
          }
          vm.iconPagination.totalDocs = response.totalDocs
          vm.iconPagination.totalPages = response.totalPages
          vm.iconPagination.pagingCounter = response.pagingCounter
          vm.pages = []
          for (var i = 0; i < vm.iconPagination.totalPages; i++) {
            vm.pages.push(i + 1)
          }
        }
      )
    },
    selectIcon (icon) {
      const vm = this
      vm.inputIcon = icon
    },

    confirm () {
      const vm = this
      vm.$emit('input', vm.inputIcon)
      vm.showingDialog = false
    }
  }
}
</script>

<style>

.icon-input-dialog-body .icon-wrapper {
  cursor: pointer;
}

.icon-input-dialog-body .icon-wrapper:hover {
  background-color: lightgray;
}

.icon-input-dialog-body .icon-wrapper.selected {
  background-color: lightskyblue;
}

.icon-input-dialog-body .icon-wrapper.selected:hover {
  background-color: dodgerblue;
}
</style>
