<template>
    <div class="secondary--text">
        <!-- <v-btn small icon plain :ripple="false" class="mr-auto" style="position: absolute; left:2px; top:10px">
            <v-icon size="20">mdi-arrow-collapse-left</v-icon>
        </v-btn> -->
        <div class="white d-flex justify-start align-center">
            <v-icon class="pl-3">mdi-folder-text-outline</v-icon>
            <b class="pl-1">{{title}}</b>
            <!-- <v-btn 
                icon
                x-small
                color="white"
                class="mr-3 teal lighten-2 ml-auto"
                @click="$emit('addRecord')"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn> -->
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            title: String
        }
    }

</script>