<template>
    <v-dialog v-model="show" @click:outside="closeDialog" style="z-index: 1010 !important;">
        <v-card class="rounded">
            <div v-if="selectedTeam._id === ''">
                <v-card-title>Teams</v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="team in availableTeams" :key="team._id"  @click="selectTeam(team)">
                            <v-list-item-avatar>
                                <v-img :src="team.logo" width="40" class="rounded-circle"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-text>{{ team.name }}</v-list-item-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </div>
            <div v-else>
                <v-card-title>
                    <div class="d-flex flex-column w-100">
                        <div>Members</div>
                        <div>
                            <v-text-field outlined small v-model="searchText" dense hide-details="" append-icon="mdi-magnify" @keypress="search" @click:append"search"></v-text-field>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <font-awesome-icon icon="spinner" class="fa-spin text-h5 ma-auto" v-if="isLoadingMember"></font-awesome-icon>
                    <v-list rounded>
                        <v-list-item v-for="member in members" :key="member._id" @click="selectMember( member )">
                            <v-list-item-avatar>
                                <v-img v-if="member.avatar" :src="member.avatar" class="rounded-circle"></v-img>
                                <v-avatar v-else color="primary">
                                    <v-icon dark>mdi-account</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                {{ member.employeeWorkInfo.displayName }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: [ 'show' ],
        data() {
            return {
                selectedTeam: {
                    _id: '', teamName: ''
                },
                isLoadingMember: false,
                members: [],
                searchText: ''
            }
        }, 
        computed: {
            availableTeams() {
                return this.$store.getters.user.teams;
            }
        },
        methods: {
            selectTeam(team) {
                this.selectedTeam = {
                    _id: team._id, 
                    teamName: team.name
                };
                this.fetchTeamMembers();
            },
            search(e) {
                if ( e.key === 'Enter' ) {
                    this.fetchTeamMembers();
                }
            }, 
            fetchTeamMembers() {
                this.isLoadingMember = true;
                const data = {
                    teamId: this.selectedTeam._id,
                    limit: 20, 
                    offset: 0, 
                    search: this.searchText.trim()
                };

                const postData = { urlCommand: '/employees', data };
                this.$store.dispatch('AUTH_POST', postData).then( res => {
                    this.members = res.docs;
                })
                this.isLoadingMember = false;
            },
            closeDialog() {
                this.show = false; 
                this.selectedTeam = { _id: '', name: '' };
                this.members = [];
                this.searchText = '';
                this.$parent.isShowMemberSelector = false;
            }, 
            selectMember( member ) {
                this.$parent.appendMember({
                    _id: member._id, 
                    employeeName: member.employeeWorkInfo.displayName,
                    teamId: this.selectedTeam._id,
                    teamName: this.selectedTeam.teamName
                });
                this.closeDialog();
            }
        }
    }
</script>