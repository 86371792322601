const mixin= {
    props: {
      publicForm: Object,
      },
    methods: {
        save(value, setting) {

            this.$emit("commandHandler", {
              command: "saveSetting",
              setting,
              value,
            });
        },
    }
}

export default mixin