<template>
  <v-dialog v-model="showing" v-if="showing" max-width="480">
    <v-card>
      <v-card-title class="headline">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-card :class="{'primary white--text': selected==='newFromBlank'}">
          <h4>{{ $t('widgets.properties.newFromBlank') }}</h4>
        </v-card>
        <v-card class="pa-3" :class="{'muted': selected==='convertCurrentTable'}">
          <h4>{{ $t('widgets.properties.takeExistingTable') }}</h4>
          <label>{{ $t('general.application') }}</label>
          <v-select dense outlined single hide-details="auto"
                    :items="applicationNames"
                    :menu-props="{offsetY: true, contentClass: 'denseList'}"
                    v-model="selectedApplicationName"></v-select>
          <label>{{ $t('general.table') }}</label>
          <v-select dense outlined single hide-details="auto"
                    :items="tableNames"
                    :menu-props="{offsetY: true, contentClass: 'denseList'}"
                    v-model="selectedTableName"></v-select>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      showing: false,
      selected: 'newFromBlank',
      selectedApplicationName: '',
      selectedTableName: '',
      applications: [
        {label: 'Salesforce克隆', tables: ['customers', 'quotations', 'invoices']},
        {label: 'CRM', tables: ['customers', 'quotations', 'invoices']},
        {label: 'PM', tables: ['projects', 'customers']},
        {label: 'ERP', tables: ['customres', 'suppliers', 'products']},
        {label: 'HR', tables: ['employees', 'customers', 'holidays']}
      ]
    }
  },
  computed: {
    applicationNames () {
      const vm = this
      var result = []
      for (var i = 0; i < vm.applications.length; i++) {
        result.push(vm.applications[i].label)
      }
      return result
    },
    tableNames () {
      const vm = this
      var result = []
      if (vm.selectedApplicationName !== '') {

      }
      return result
    },
    dialogTitle () {
      const vm = this
      return vm.$t('widgets.properties.newChildTable')
    }
  },
  methods: {
    open (fieldInfo) {
      const vm = this
      vm.showing = true
    }
  }
}
</script>
