<template>
<v-icon
  :color="fileTypeIcon.color"
  :size="size">{{ fileTypeIcon.icon }}</v-icon
>
</template>

<script>
import FILE_ICONS from '@/const/fileIcons';

export default {
  name: 'mediaTypeIcon',
  props: {
    media: Object,
    size: {
      type: String,
      default: '35'
    }
  },
  computed: {
    fileTypeIcon () {
      var result = FILE_ICONS.find(item => item.type==='');
      if (this.media) {
        const reg = /\.[a-zA-Z0-9]*$/;
        console.log('MediaTypeIcon originalName = ' + this.media.originalName);
        const matched = this.media.originalName.match(reg);
        console.log('MediaTypeIcon fileTypeIcon matched: ', matched);
        if (matched) {
          const ext = matched[0].substring(1).toLowerCase();
          console.log('MediaTypeIcon ext = ' + ext);
          for (let i = 0; i < FILE_ICONS.length; i++) {
            console.log('i=' + i + ': FILE_ICONS[i].exts: ', FILE_ICONS[i].exts);
            if (FILE_ICONS[i].exts.includes(ext)) {
              result = FILE_ICONS[i];
              break
            }
          }
        }
      }
      return result;
   }
  }
  
}
</script>