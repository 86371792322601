<template>
  <div>
    <label>{{ label }}</label>
    <v-text-field
      label=""
      :value="propertyValue"
      :type="inputType"
      @input="val=>{propertyValue=val}"
      :min="min"
      :max="max"
      dense
      hide-details="auto"
      outlined></v-text-field>
  </div>
      <!--v-model="propertyValue"-->
</template>

<script>
import mixin from './mixin'

export default {
  name: 'witString',
  mixins: [mixin],
  methods: {
    onBlur (evt) {
      console.log('onBlur evt: ', evt)
    },
    onInput (evt) {
      console.log('onINput evt: ', evt)
    },
    onChange (evt) {
      console.log('onChange evt: ', evt)
    }
  },
  computed: {
    inputType(){
      return 'text'
      // return this.fieldType === 'rating' ? 'number' : 'text'
    },
    min(){
      return this.fieldType === 'rating' ? '0': ''
    },
    max(){
      return this.propertyValues.ratingLevel === '5stars' ? '5': '10'
    }
  }
}
</script>
