<template>
    <div class="default-box">
        <slot name='title'>
            <label>{{ label }}</label>
        </slot>
        <template>
            <div class="d-flex" style="height:100%">
                <slot name="inputAppend">
                </slot>
                <slot name="inputBox">
                    <div class="d-flex flex-column" style="width:100%">
                        <div class="d-flex" style="position: relative; width:100%">
                            <div @click="handleMixedBoxClicked" style="width:100%">
                                <mixed-box
                                    :value="value"
                                    @input="updateDefaultCode($event)"
                                    ref="mixedBox"
                                    v-show="value.type=='COPY' && 
                                            !(value.members && value.members.length) && 
                                            !(value.departments && value.departments.length) &&
                                            !(value.records && value.records.length)"
                                >
                                </mixed-box>
                                <div 
                                    v-if="value.type=='QUERY'" 
                                    class="w-100" 
                                    style="border: 1px solid grey; height:100%"
                                >
                                    <v-btn 
                                        @click="editQuery"
                                        :ripple="false" 
                                        small 
                                        class="rounded-1 elevation-0 flex-grow-1 d-flex align-center w-100" 
                                        style="height:100%"
                                    >
                                        <span style="font-size: 12px">Query: </span>
                                        <v-icon size="18px">mdi-table</v-icon>
                                        <span class="text-left flex-grow-1" style="font-size: 12px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{getWorksheetName(value.worksheet)}}</span>
                                    </v-btn>
                                </div>
                                <div 
                                    v-if="value.members && value.members.length"
                                    class="pb-1 pr-1"
                                    style="width:100%; height:100%; border: 1px solid grey"
                                >
                                    <v-chip 
                                        small 
                                        class="mt-1 ml-1" 
                                        v-for="ee in value.members" 
                                        :key="ee._id"
                                        style="max-width:210px;"
                                        
                                    >
                                        <span
                                            style="overflow: hidden; 
                                                white-space: nowrap; 
                                                text-overflow: ellipsis"
                                        >
                                            {{ee.employeeName}}
                                        </span>
                                    </v-chip>
                                </div>
                                <div 
                                    v-if="value.departments && value.departments.length"
                                    class="pb-1 pr-1"
                                    style="width:100%; height:100%; border: 1px solid grey"
                                >
                                    <v-chip 
                                        small 
                                        class="mt-1 ml-1" 
                                        v-for="dept in value.departments" 
                                        :key="dept._id"
                                        style="max-width:210px;"
                                        
                                    >
                                        <span
                                            style="overflow: hidden; 
                                                white-space: nowrap; 
                                                text-overflow: ellipsis"
                                        >
                                            {{dept.label}}
                                        </span>
                                    </v-chip>
                                </div>
                                <div 
                                    v-if="value.records && value.records.length"
                                    class="pb-1 pr-1"
                                    style="width:100%; height:100%; border: 1px solid grey"
                                >
                                    <v-chip 
                                        small 
                                        class="mt-1 ml-1" 
                                        v-for="record in value.records" 
                                        :key="record"
                                        style="max-width:210px;"
                                        
                                    >
                                        <span
                                            style="overflow: hidden; 
                                                white-space: nowrap; 
                                                text-overflow: ellipsis"
                                        >
                                            {{record.title}}
                                        </span>
                                    </v-chip>
                                </div>
                            </div>
                            <v-btn
                                v-if="haveDefaultValue"
                                class="elevation-0 ml-auto append-button"
                                icon
                                :ripple="false"
                                @click="clearDefaultValue"
                            >
                                <v-icon size="20" style="height:32px">mdi-close</v-icon>
                            </v-btn>
                            <v-btn
                                class="elevation-0 ml-auto append-button"
                                icon
                                :ripple="false"
                                @click="showingDefaultOptionsMenu=!showingDefaultOptionsMenu"
                            >
                                <v-icon size="20" style="height:32px">mdi-plus</v-icon>
                            </v-btn>
                        </div>
                        <div id="menuAttPt" style="position: relative"></div>
                    </div>
                </slot>
                <slot name='inputAppend'>
                    <default-options-menu
                        v-if="showingDefaultOptionsMenu"
                        v-model="showingDefaultOptionsMenu"
                        @openFieldSelectionMenu="showingFieldSelectionMenu=true"
                        @openQueryDialog="openQueryDialog"
                        :fieldType="fieldInfo.type"
                        attachId="menuAttPt"
                        @updateDefaultCode="updateDefaultCode"
                    />
                    <field-selection-menu
                        v-if="showingFieldSelectionMenu"
                        v-model="showingFieldSelectionMenu"
                        :formFieldInfos="availableFormFieldInfos"
                        attachId="menuAttPt"                        
                        @fieldClick="handleFieldClick"
                        :fieldType="fieldInfo.type"
                    />
                    <defaultQueryDialog
                        ref="defaultQueryDialog"
                        v-if="showingQueryDialog"
                        v-model="showingQueryDialog"
                        :formData="queryFormData"
                        @submit="updateDefaultValue"
                        @setWorkSheet="setWorkSheet"
                    />
                    <!-- :content="value" -->
                    <!-- :conditions="JSON.parse(JSON.stringify(conditions))"
                    :copyField="copyField"
                    :type="value.queryType"
                    @submit="updateDefaultValue"
                    :bl_lastRecord="bl_lastRecord"
                    :defaultFieldInfos="formFieldInfos" -->

                    <dateTimeMenu
                        :fullWidth="false"
                        v-if="showingDateTimeMenu"
                        v-model="showingDateTimeMenu"
                        attachId="menuAttPt"
                        :type="fieldInfo.properties.fieldType"
                        :defaultValue="value.code=='CURRENT'?'':value.code"
                        @updateDefaultValue="updateDefaultCode"
                    />
                    <members-menu
                        :fullWidth="false"
                        v-if="showingMemberDialog"
                        v-model="showingMemberDialog"
                        attachId="menuAttPt"
                        :type="fieldInfo.properties.fieldType"
                        :selectedValue="value.members"
                        @memberClick="memberClick"
                    />
                    <!-- <dialog-button-select-employees
                        ref="dialogButtonSelectEmployees"
                        v-model="showingMemberDialog"
                        :multiple="fieldInfo.properties.fieldType=='multiple'"
                        :addButton="false"
                        @submit="updateMembers"
                        :selectedValue="value.members"
                    /> -->
                    <departments-dialog
                        v-if="showingDepartmentDialog"
                        v-model="showingDepartmentDialog"
                        @confirm="value.departments=$event"
                        :selectedDepts="value.departments"
                        :isMultiple="fieldInfo.properties.fieldType=='multiple'"
                    />
                    <select-related-records-dialog
                        valType="object"
                        ref="selectRelatedRecordsDialog"
                    >
                    </select-related-records-dialog>
                </slot>
            </div>
        </template>
    </div>
</template>

<script>
    import mixin from './mixin'
    import mixedBox from './components/mixedBox'
    import defaultOptionsMenu from './components/menus/defaultOptionsMenu'
    import fieldSelectionMenu from './components/menus/fieldSelectionMenu'
    import membersMenu from './components/menus/membersMenu'
    import defaultQueryDialog from './components/dialogs/defaultQueryDialog'
    import dateTimeMenu from './components/menus/dateTimeMenu'
    import dialogButtonSelectEmployees from '@/components/dialogButtons/DialogButtonSelectEmployees'
    import selectedPersonTag from '@/components/dialogs/comps/SelectedPersonTag'
    import DepartmentsDialog from "@/components/dialogs/DepartmentsDialog";
    import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
    // import 
    import {
        FORM_SELECTIONS,
        SET_SETTINGS,
        FORM_SELECTIONS_FIELDS,
    } from '@/store/modules/workflow/action_types';
    export default {
        mixins: [mixin],
        name: 'defaultBox',
        components:{
            mixedBox,
            defaultOptionsMenu,
            fieldSelectionMenu,
            defaultQueryDialog,
            dateTimeMenu,
            dialogButtonSelectEmployees,
            selectedPersonTag,
            DepartmentsDialog,
            selectRelatedRecordsDialog,
            membersMenu
        },
        data(){
            return {
                // defaultOptions:[{
                //     id: "OTHERFIELD",
                //     label: 'otherField',
                //     icon: 'mdi-playlist-plus'
                // },
                // {
                //     id: "QUERYFORM",
                //     label: 'queryForm',
                //     icon: 'mdi-text-search'
                // }],
                showingMenu: false,
                showOtherFieldsMenu: false,

                
                showingQueryDialog: false,
                showingDefaultOptionsMenu: false,
                showingFieldSelectionMenu: false,
                showingDateTimeMenu: false,
                showingMemberDialog: false,
                showingDepartmentDialog: false,
                copyField: null,
                conditions: [],
                bl_lastRecord: false,
                queryFormData: null
            }
        },
        computed:{    
            // fieldIdsOfSameDataSource () {
            //     const currentDataSource = vm.fieldInfo.properties.dataSource
            //     var result = [];
            //     for (let i = 0; i < this.formFieldInfos.length; i++) {
            //         const loopFieldInfo = this.formFieldInfos[i];
            //         if (loopFieldInfo.type === 'relatedRecord' &&
            //             loopFieldInfo.properties.dataSource === currentDataSource) {
            //             result.push(loopFieldInfo.fieldId);
            //         }
            //     }
            //     return result;
            // },
            availableFormFieldInfos () {
                const currentId = this.fieldInfo._id;
                if (this.fieldInfo && (this.fieldInfo.type === 'relatedRecord')) {
                    const pairedId = this.getLinkedId(currentId);
                    const currentDataSource = this.fieldInfo.properties.dataSource
                    const formFieldInfosWithSameDataSource = this.formFieldInfos.filter(
                        fieldInfo => {
                            return fieldInfo.properties.dataSource === currentDataSource;
                        }
                    );
                    return formFieldInfosWithSameDataSource.filter(info => {
                        return (info._id !== currentId) && 
                            (info._id !== pairedId);                        
                    }) ;                 
                } else {                    
                    return this.formFieldInfos 
                        ? this.formFieldInfos.filter(info => info._id !== currentId)
                        : [];
                }
            },
            relatedTableInfos(){
                return this.$store.getters.relatedTableInfos
            },
            dataSources(){
                return this.$store.getters.dataSources
            },
            list() {
                return this.$store.getters.getFormSelectionsFields.children || []
            },
            formFieldInfos(){
                return this.$store.getters.formFieldInfos
            },
            currentApp(){
                return this.$store.getters.currentApp
            },
            formList() {
                return this.$store.getters.getFormSelections;
            },
            formId(){
                return this.$store.getters.currentForm._id
            },
            formInfo() {
                return this.$store.getters.formInfo;
            },
            linkedFields() {
                return this.formInfo
                    ? this.formInfo.linkedFields
                    : [];
            },

            haveDefaultValue1 () {
                return ((this.value.type==='COPY') && !!this.value.code && (this.value.code!==''));             
            },

            haveDefaultValue2 () {
                return ((this.value.type==='COPY') && !!this.value.records && (this.value.records.length>0))
            },

            haveDefaultValue3 () {
                return ((this.value.type==='QUERY') && this.value.queryType==='FORM' && !!this.value.worksheet)        
            },

            haveDefaultValue () {
                return (this.value && (
                    this.haveDefaultValue1 || this.haveDefaultValue2 || this.haveDefaultValue3
                ));
            }
        },
        watch:{
            'fieldInfo.properties.fieldType'(){
                if(this.fieldInfo.type=='date'){
                    this.value.code = ''
                }
                if(this.fieldInfo.type=='members'){
                    this.value.members = []   
                }
                if(this.fieldInfo.type=='departments'){
                    this.value.departments = []
                }
                if(this.fieldInfo.type=='relatedRecord'){
                    this.value.records = []
                }
            }
        },
        methods:{
            clearDefaultValue() {
                if (this.value) {
                    switch (this.value.type) {
                        case 'COPY':
                            this.$set(this.value, 'code', '');
                            this.$set(this.value, 'records', []);
                            break;
                        case 'QUERY':
                            this.$set(this.value, 'type', 'COPY');
                            this.$set(this.value, 'code', '');
                            this.$set(this.value, 'worksheet', '');
                            break;
                    }
                }
            },
            getLinkedId (id) {
                var result = '';
                for (let i = 0; i < this.linkedFields.length; i++) {
                    const loopFieldIds = this.linkedFields[i].fieldIds;
                    const index = loopFieldIds.indexOf(id);
                    if (index >= 0) {
                        result = loopFieldIds[1 - index];
                        break;
                    }
                }
                return result;
            },
            // handleListItemClick(optionId){
            //     if(optionId=='OTHERFIELD'){
            //         this.showOtherFieldsMenu = true
            //     }else if(optionId=='QUERYFORM'){
            //         this.showingMenu = false
            //         this.showingQueryDialog = true
            //     }
            // },
            openRelatedRecordDialog(){
                var payload
                const vm = this;
                var selectionMode = vm.fieldInfo.properties.displayFormat
                var dataSource = vm.fieldInfo.properties.dataSource
                var relatedRecordQuantity = vm.fieldInfo.properties.relatedRecordQuantity
                var relatedFormInfo = null
                
                // if (Object.keys(vm.relatedTableInfos)
                // .includes(dataSource)) {
                //     relatedFormInfo = vm.relatedTableInfos[dataSource]
                // }
                relatedFormInfo = vm.dataSources.find(item=>item._id==dataSource)
                if(!relatedFormInfo) return
                const selectionSettings =
                    selectionMode === "table"
                    ? vm.fieldInfo.properties.tableSelectionSettings
                    : vm.fieldInfo.properties.cardSelectionSettings;
                payload = {
                    value: selectionMode=='table' ? [] : [],
                    dataSource: dataSource,
                    formInfo: relatedFormInfo,
                    // selectionSettings: selectionSettings,
                    multiple: relatedRecordQuantity=='multiple',
                    exclusion: [],

                    callbackBundle: {
                        id: "",
                        onSelected: null,
                    },

                    checkDbForExclusion: selectionMode === "table",
                    parentRecordInfo: {
                        formId: vm.formId,
                        recordId: null,
                        fieldId: vm.fieldInfo._id,
                    },
                    fieldId: vm.fieldInfo._id,
                    parentFormId: vm.formId
                };
                vm.$refs.selectRelatedRecordsDialog.open(payload,(res)=>{
                    vm.$set(vm.value,'records', res)
                })
                // console.log('selectRelatedRecordsDialog', this.$refs.selectRelatedRecordsDialog)
                // multiple
                // attach
                // cssSelect
                // value
                // inclusive
                // recordId
                // isParentChild
                // this.$refs.selectRelatedRecordsDialog.open(,()=>{
                //     alert('select')
                // },()=>{
                //     alert('close')
                // })
            },
            memberClick(val){
                var pos = this.value.members.findIndex(item=>item._id == val._id)
                if(pos!=-1){
                    this.value.members.splice(pos,1)
                }else{
                    if(this.fieldInfo.properties.fieldType=='multiple')
                        this.value.members.push(val)
                    else
                        this.value.members = [val]
                }
            },
            setWorkSheet(formId=null){
                if(!formId) formId = this.formId
                const worksheet = this.formList.find(form=>form._id == formId)
                var setting = {
                    worksheet,
                    hookFields: [],
                    rules: [],
                    sorting: {},
                    data: {},
                    shareableTemplates:[]
                }
                this.$store.dispatch(FORM_SELECTIONS_FIELDS, {
                    appId: this.currentApp._id,
                    formId: worksheet._id,
                });
                const promise = new Promise((resolve, reject)=>{
                    this.$store.dispatch(SET_SETTINGS,setting).then((res)=>{
                        resolve(res)
                    })
                })
                return promise
            },
            openQueryDialog(){
                let vm = this
                vm.setWorkSheet(vm.formId).then(res=>{
                    vm.queryFormData = {
                        type: 'FORM',
                        conditions: [],
                        copyField: null,
                        bl_lastRecord: false,
                        code: ''
                    } 
                    vm.showingQueryDialog = true
                })
            },
            editQuery(){
                let vm = this
                vm.setWorkSheet(vm.value.worksheet).then(res=>{
                    vm.queryFormData = {
                        type: vm.value.queryType,
                        conditions: JSON.parse(JSON.stringify(vm.value.conditions)),
                        copyField: JSON.parse(JSON.stringify(vm.value.copyField)),
                        bl_lastRecord: vm.value.bl_lastRecord,
                        code: ''
                    } 
                    vm.showingQueryDialog = true
                })
            
            },
            getWorksheetName(formId){
                var workSheetName = 'UNDEFINED WORKSHEET'
                var BreakException = {}
                try{
                    this.currentApp.modules.forEach(module=>{
                        var res = module.menu.find(form=>form._id == formId)
                        if(res){
                            workSheetName = res.title
                            throw BreakException
                        }
                    })
                }catch(e){
                    if (e !== BreakException) throw e;
                }
                return workSheetName
            },
            updateMembers(val){
                this.value.type = "COPY"
                this.value.members = val
                this.value.code = ''
            },
            handleMixedBoxClicked(){
                if(this.fieldInfo.type=='date'){
                    this.showingDateTimeMenu = true
                }
                if(this.fieldInfo.type=='members' && this.value.type=='COPY'){
                    this.showingMemberDialog = true
                } 
                if(this.fieldInfo.type=='departments' && this.value.type=='COPY'){
                    this.showingDepartmentDialog = true
                }
                if(this.fieldInfo.type=='relatedRecord' && this.value.type=='COPY'){
                    this.openRelatedRecordDialog()
                }
            },
            reRenderMixedBox(){
                this.$refs.mixedBox.onCmReady()
            },
            handleFieldClick(field){
                let vm = this
                console.log('handleFieldClick::field', field)
                // this.$emit('input',{
                //     type: 'COPY',
                //     code: '${' + field._id + '}'
                // })
                // var emitData = {}
                if(vm.value.type!='COPY') {
                    vm.value.type = 'COPY'
                    vm.value.code = ''
                }
                if(vm.fieldInfo.type!='text'){
                    vm.value.code = ''
                }
                if(vm.fieldInfo.type=='members'){
                    vm.value.members = []
                }else if(vm.fieldInfo.type=='departments'){
                    vm.value.departments = []
                }
                
                
                this.$nextTick(()=>{
                    vm.$refs.mixedBox.insertField(field)
                })
                // {
                //     data:{
                //         type: 'COPY',
                //         code: '${' + field._id + '}'
                //     },
                //     callback: vm.reRenderMixedBox()
                // }
                // console.log('this.$refs.mixedBox', this.$refs.mixedBox)
            },
            handleMenuInput(val){
                if(!val){
                    this.showingMenu = false
                    this.showOtherFieldsMenu = false
                }
            },
        }
    }
</script>


<style lang="scss">
    .witDefaultValue .v-input__append-outer{
        margin: 0 !important;
    }
    .witDefaultValue .v-input .v-input__control .v-input__slot{
        padding: 0 !important;
    }
    .witDefaultValue .v-text-field .v-input__slot .v-text-field__slot{
        padding-left:6px
    }
    /* .witDefaultValueMenuContent .v-text-field .v-input__slot .v-text-field__slot{
        padding-left:6px
    } */
    .witDefaultValueMenuContent .v-list-item:hover{
        background-color: $primary;
        span{
            color: white;
        }
        .v-icon{
            color: white;
        }
        svg{
            color: white;
        }
    }
    .menuFullWidth{
        width:100% !important;
    }
    .witDefaultValueMenuContent{
        max-width:100% !important;
        /* width:100% !important; */
        /* transform: translate(-11px); */
    }
    .witDefaultValueMenuContent .v-list-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height:10px;
        height: auto;
        /* padding: 0px 15px !important; */
    }

    .witDefaultValueMenuContent .v-picker__body{
        padding-bottom:8px !important;
        border-bottom: 1px solid #d9d9d9;
    }
    .witDefaultValueMenuContent .v-picker__actions{
        padding: 0px !important;
    }
    .witDefaultValueMenuContent .v-date-picker-table{
        height: auto !important;
    }
    .witDefaultValueMenuContent .v-date-picker-table--date .v-btn{
        height: 25px !important
    }
    .witDefaultValueMenuContent .v-date-picker-table--date th{
        padding: 0px !important;
    }
    .ps__rail-y.ps--clicking .ps__thumb-y{
        width: 6px !important
    }
    .ps__rail-y .ps--clicking{
        background-color: transparent !important;
    }
    .witDefaultValueMenuContent .v-picker__body{
        width: auto !important;
    }
    .default-box .append-button {
        border: 1px solid grey;
        border-left: none;
        border-radius: 0px;
        height: 100%;
    }

</style>