<template>
  <div class="fs-unspecified pa-0 text-no-wrap overflow-hidden d-flex flex-row align-center">
    <label v-if="useLabel"
           class="grey--text text--lighten-1 mr-2">{{ label }}</label>
    <span>{</span>{{ label }}<span>}</span>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'fsUnspecified',
  mixins: [mixin],
  computed: {
    label () {
      const vm = this
      var result = ''
      if (vm.fieldInfo && vm.fieldInfo.properties && vm.fieldInfo.properties.fieldName) {
        result = vm.fieldInfo.properties.fieldName
      }
      return result
    }
  }
}
</script>

<style>
  .fs-unspecified {
    color: rgba(255,0,0,.5);
  }
</style>
