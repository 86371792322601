<template>
  <v-container fluid>
    <v-row align="center" justify="center" class="my-2">
      <PublishForm/>
    </v-row>
    <v-row align="center" justify="center" class="my-2">
      <PublicQuery />
    </v-row>
    <v-row align="center" justify="center" class="my-2">
      <PublicEdit />
    </v-row>
 </v-container>
 
</template>

<script>
import PublishForm from "./publicPublishing/publishForm"
import PublicQuery from "./publicPublishing/publicQuery"
import PublicEdit from "./publicPublishing/publicEdit"

export default {
  components: {
    PublishForm,
    PublicQuery,
    PublicEdit
  }
}
</script>
