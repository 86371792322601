<template>
  <div class="w-retrieveRecord-container">
    <PendingSetup
      v-if="!dataSource && !worksheet"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <TableSourceView :node="node" />
      <div class="workflow-shortview-remark">
        {{ format }}
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import PendingSetup from '../pending';
import Mixin from '../mixin';
import TableSourceView from '../tableSource';

export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  computed: {
    dataSource() {
      const { properties } = this.node;
      return get(properties, 'dataSource', '');
    },
    worksheet() {
      const { properties } = this.node;
      return get(properties, 'worksheet', '');
    },
    sourceType() {
      const { properties } = this.node;
      return get(properties, 'sourceType', '');
    },

    format() {
      const { properties } = this.node;
      const format = get(properties, 'format', '');
      return this.$t(`workflow.modules.generateFile.label.remark`, {
        format
      });
    },
  },
  components: {
    PendingSetup,
    TableSourceView,
  },
};

</script>

<style scoped>
  .w-retrieveRecord-container {
    position: relative;
  }
  .w-retrieveRecord-lockerIcon {

    position: absolute;
    right: -30px;
    top: 0;
  }
</style>
