<template>
    <div class="">
        <div class="d-flex align-end flex-wrap">
            <div style="font-size:12px" class="d-flex align-center">
                <div>{{field.label}}</div>
                <div class="ml-2 grey--text" style="border-bottom: 1px dashed #c5c5c5; line-height:11px">{{field.type}}</div>
            </div>
            <div class="ml-auto">
                <v-menu offsetY>
                    <template v-slot:activator="{on}">
                        <div v-on="on" :class="operatorColor" class="white--text rounded-sm d-inline" style="padding: 2px 10px;cursor: pointer; border: 1px dashed #c5c5c5; font-size: 12px">
                            {{operator}}
                            <v-icon dark style="min-width:0px; width:0px" class="pl-2">
                                mdi-chevron-down
                            </v-icon>
                        </div>
                    </template>
                    <v-card style="" tile flat outlined>
                        <v-list dense class="pa-0" style="font-size: 12px;">
                            <v-list-item-group v-model="operator" color="info" mandatory>
                                <v-list-item :value="opt.id" v-for="opt in operators" :key="opt.id" dense style="border-bottom: #eeeeee 1px solid; min-height: 22px">
                                    {{opt.label}}
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
        </div>
        <div class="mt-2" v-if="operator!='empty' && operator!='notEmpty'">
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{on}">
                    <v-text-field style="font-size:13px" :value="getDateOptString()" readonly v-on="on" outlined height="25" dense hide-details></v-text-field>
                </template>
                <v-card class="d-flex flex-column" style="height: 300px">
                    <div class="">
                        <div style="font-size: 13px; color: grey;" class=" ml-2 pt-2 pb-1">{{$t('chart.selectRange')}}</div>
                        <v-list dense class="pt-0">
                            <v-list-item-group mandatory v-model="dateOpt" color="info" @change="$emit('update_dateOpt', $event)">
                                <div :value="opt.id" v-for="opt in dateOpts" :key="opt.value">
                                    <v-list-item :value="opt.id" class="d-flex align-center" style="min-height:25px;">
                                        <div style="font-size:13px">{{opt.name}}</div>
                                    </v-list-item>
                                    <div v-if="opt.value=='PAST_' && dateOpt==16" class="black--text px-4 my-2">
                                        <v-text-field v-model="b4_afterDate" outlined dense height="30" hide-details>
                                            <template v-slot:append>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <div v-if="opt.value=='NEXT_' && dateOpt==17" class="black--text px-4 my-2">
                                        <v-text-field v-model="b4_afterDate" outlined dense height="30" hide-details/>
                                    </div>
                                    <div v-if="opt.value=='DATE_' && dateOpt==18" class="black--text px-4 my-2">
                                        <v-menu top offset-x nudge-right="16" :close-on-content-click="false">
                                            <template v-slot:activator="{on}">
                                                <v-text-field readonly v-model="dateRangeText" outlined dense height="30" hide-details v-on="on"/>
                                            </template>
                                            <v-date-picker v-model="specifiedDates" range tile elevation="0"/>
                                        </v-menu>
                                    </div>
                                </div>
                            </v-list-item-group>
                        </v-list>
                    </div>
                </v-card>
            </v-menu>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            field: Object
        },
        computed:{
            dateRangeText () {
                if(this.specifiedDates.length == 2)
                    if(this.specifiedDates[0]>this.specifiedDates[1]){
                        let temp = this.specifiedDates[0]
                        this.specifiedDates[0] = this.specifiedDates[1]
                        this.specifiedDates[1] = temp
                    }
                return this.specifiedDates.join(' ~ ')
            },
            operatorColor(){
                return this.operators.find(item=>item.id==this.operator).color
            }
        },
        data(){
            return{
                dateOpt: 99,
                b4_afterDate: 0,
                specifiedDates: [],
                operator:'is',
                operators: [
                    {
                        id: 'is',
                        label: 'is',
                        color: 'success'
                    },
                    {
                        id: 'not',
                        label: 'not',
                        color: 'error'
                    },
                    {
                        id: 'before',
                        label: 'before',
                        color: 'success'
                    },
                    {
                        id: 'after',
                        label: 'after',
                        color: 'error' 
                    },
                    {
                        id: 'inRange',
                        label: 'inRange',
                        color: 'success'
                    },
                    {
                        id: 'notInRange',
                        label: 'notInRange',
                        color: 'error'
                    },
                    {
                        id: 'empty',
                        label: 'empty',
                        color: 'secondary'
                    },
                    {
                        id: 'notEmpty',
                        label: 'notEmpty',
                        color: 'secondary'
                    }
                ],
                dateOpts: [{
                    id: 99,
                    name: this.$t('chart.all'),
                    value: 'all'
                },
                {
                    id: 1,
                    name: this.$t('calendar.TODAY'),
                    value: "TODAY"
                },{
                    id: 2,
                    name: this.$t('calendar.YESTERDAY'),
                    value: "YESTERDAY"               
                },{
                    id: 3,
                    name: this.$t('calendar.TOMORROW'),
                    value: "TOMORROW"       
                },{
                    id: 4,
                    name: this.$t('calendar.THISWEEK'),
                    value: "THISWEEK"
                },{
                    id: 5,
                    name: this.$t('calendar.LASTWEEK'),
                    value: "LASTWEEK"               
                },{
                    id: 6,
                    name: this.$t('calendar.NEXTWEEK'),
                    value: "NEXTWEEK"            
                },{
                    id: 7,
                    name: this.$t('calendar.THISMONTH'),
                    value: "THISMONTH"            
                },{
                    id: 8,
                    name: this.$t('calendar.LASTMONTH'),
                    value: "LASTMONTH"             
                },{
                    id: 9,
                    name: this.$t('calendar.NEXTMONTH'),
                    value: "NEXTMONTH"            
                },{
                    id: 10,
                    name: this.$t('calendar.THISQUARTER'),
                    value: "THISQUARTER"                
                },{
                    id: 11,
                    name: this.$t('calendar.LASTQUARTER'),
                    value: "LASTQUARTER"         
                },{
                    id: 12,
                    name: this.$t('calendar.NEXTQUARTER'),
                    value: "NEXTQUARTER"          
                },{
                    id: 13,
                    name: this.$t('calendar.THISYEAR'),
                    value: "THISYEAR"             
                },{
                    id: 14,
                    name: this.$t('calendar.LASTYEAR'),
                    value: "LASTYEAR"
                },{
                    id: 15,
                    name: this.$t('calendar.NEXTYEAR'),
                    value: "NEXTYEAR"
                },{
                    id: 16,
                    name: this.$t('calendar.PAST_'),
                    value: "PAST_"                
                },{
                    id: 17,
                    name: this.$t('calendar.NEXT_'),
                    value: "NEXT_"              
                },{
                    id: 18,
                    name: this.$t('calendar.DATE_'),
                    value: "DATE_"            
                }],
            }
        },
        methods:{
            getDateOptString(){
                let vm = this  
                return vm.dateOpts.find(item=>item.id == vm .dateOpt).name
            },
            getDateOptValue(){
                let vm = this  
                return vm.dateOpts.find(item=>item.id == vm .dateOpt).value
            },
        }
    }
</script>