export const systemFieldIds =  [
  'owner',
  'createdBy',
  'createdAt',
  'updatedAt'
]

export const checkDupFieldType = [
  'text', 'email', 'phone', 'idCard'
]

export const importRelatedRecordFieldType = [
  'text', 'email', 'phone', 'idCard', 
  'textCombination', 'autoNumbering'
]

export const autoNumberingFieldType = [
  "text",
  "textCombination",
  "date",
  "expression",
  "number",
  "amount"
]