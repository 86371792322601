import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { get } from 'lodash'
import moment from 'moment'
// import VXETable from 'vxe-table'

import cn from './langs/zh-Hans.json'
import en from './langs/en.json'
import hk from './langs/zh-Hant.json'
import workflowLocale from './langs/hk/workflow'
import workflowCNLocale from './langs/cn/workflow'
import workflowENLocale from './langs/en/workflow'
import notificationLocale from './langs/hk/notification'
import notificationCNLocale from './langs/cn/notification'
import notificationENLocale from './langs/en/notification'

import VxeCN from 'vxe-table/lib/locale/lang/zh-CN'
import VxeHK from 'vxe-table/lib/locale/lang/zh-HK'
import VxeEN from 'vxe-table/lib/locale/lang/en-US'

Vue.use(VueI18n)
const locale = 'en'
const messages = {
  'zh-Hans': {
    ...cn,
    ...VxeCN,
    ...workflowCNLocale,
    ...notificationCNLocale,
  },
  'zh-Hant': {
    ...hk,
    ...VxeHK,
    ...workflowLocale,
    ...notificationLocale,
  },
  en: {
    ...en,
    ...VxeEN,
    ...workflowENLocale,
    ...notificationENLocale,
  }
}
const i18n = new VueI18n({
  /** 默认值 */
  locale,
  fallBackLocale: 'en',
  messages,
  silentTranslationWarn: true
})


Vue.prototype.$locale = {
  change (lang) {
    i18n.locale = lang;
    this.locale();
  },
  current () {
    return i18n.locale
  },
  // TODO: use the better way to locale the moment
  m (date=moment()) {
    const lookup = {
      hk: 'zh-hk',
      'zh-hk': 'zh-hk',
      cn: 'zh-cn',
      'zh-cn': 'zh-cn',
      'zh-Hant': 'zh-hk',
      'zh-Hans': 'zh-cn',
      en: 'en',
    }
    const target = get(lookup, i18n.locale, 'hk');
    return moment(date).clone().locale(target);
  }
}

export default i18n
