<template>
  <div>
    <router-view></router-view>
    <FsLightbox
      class="fs-lightbox w-100"
      :toggler="toggler"
      type="image"
      :sources="imageSource"
      :onClose="onLightBoxClose"
    />
  </div>
</template>
<script>
import FsLightbox from "fslightbox-vue";

export default {
  name: 'mobileApps',
  components: {
    FsLightbox
  },
  computed: {
    modules() {
      return this.$store.getters.currentApp?.modules || [];
    },
    app(){
      return this.$store.getters.currentApp
    },
    toggler() {
      return this.$store.state.media.toggle;
    },
    imageSource() {
      return this.$store.state.media.imageUrls;
    },
  },
  async mounted() {
    const appId = this.$route.params.id;
    const menuItemId = this.$route.params.itemId;
    await this.loadApp(appId, menuItemId);
    if ( !menuItemId ) this.$router.push({ name: 'appsModules' })
  },
  methods: {
    async loadApp(appId, menuItemId) {
      console.log( `SIMON TRY TO LOAD APP: ${ appId } WITH :: ${ menuItemId }` );
      await this.$store.dispatch('FETCH_APP', { appId });
    },
    // getActiveModuleIndex() {}, 
    selectItem(item) {
      this.$store.dispatch("SET_CURRENT_FORM_ICON", item.icon);
      this.$router.push( { path: `/apps/${this.app._id}/${item.type}s/${item._id}` } )
    },
    onLightBoxClose() {
      this.$store.dispatch("RESET_MEDIA");
    },
  },
};
</script>
