<template>
  <div class="fill-height d-flex flex-column">
    <div class="pb-1">
      <div
        class="log-action-button-container w-100 d-flex justify-space-between"
      >
        <v-btn
          outlined
          depressed
          class="ml-5 mt-2"
          color="primary"
          rounded
          @click="showFilter = !showFilter"
          >{{ $t("drawer.filter") }}</v-btn
        >
        <v-btn
          outlined
          depressed
          class="mr-5 mt-2"
          color="primary"
          rounded
          @click="exportLog"
          :loading="isExporting"
          >{{ $t("drawer.export") }}</v-btn
        >
      </div>

      <div v-show="showFilter" class="filter-box">
        <span class="triangle"></span>
        <v-row>
          <v-col>
            <label>{{ $t("drawer.member") }}</label>
            <v-input class="log-filter-input" hide-details dense>
              <div class="pa-0 v-text-field--outlined d-flex">
                <div
                  class="d-flex flex-grow-1"
                  @click="showMemberDialog = true"
                >
                  <selected-person-tag
                    v-for="(memberInfo, index) in displayUsers"
                    :key="memberInfo._id"
                    :index="index"
                    :employeeId="memberInfo._id"
                    :name="memberInfo.employeeName"
                    :teamId="memberInfo.teamId"
                    :info="memberInfo"
                    :hasCloseButton="true"
                    deleteCommand="removeEmployeeByIndex"
                    clickCommand="removeEmployeeByIndex"
                    @onCommand="onCommandHandler($event, memberInfo)"
                  ></selected-person-tag>
                  <v-chip v-if="remainingUsersLength > 0">
                    +{{ remainingUsersLength }}</v-chip
                  >
                  <v-spacer />
                </div>

                <v-icon>mdi-pencil</v-icon>
              </div>
            </v-input>
          </v-col>
          <v-col>
            <label>{{ $t("drawer.createdAt") }}</label>
            <v-menu
              min-width="auto"
              :close-on-content-click="false"
              left
              offset-y
              offset-overflow
            >
              <template v-slot:activator="{ on, attrs }">
                <v-input class="log-filter-input" hide-details dense
                  ><div class="pa-0 v-text-field--outlined d-flex">
                    <div v-on="on" v-bind="attrs" class="d-flex flex-grow-1">
                      {{ dateRange }}
                      <v-spacer />
                    </div>
                    <v-icon v-if="dateRange" @click="filters.date = []"
                      >mdi-close</v-icon
                    >
                    <v-icon v-else>mdi-calendar</v-icon>
                  </div>
                </v-input>
              </template>
              <v-date-picker
                @change="assignDateRange"
                v-model="filters.date"
                no-title
                range
                show-adjacent-months
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-1" no-gutters>
          <v-spacer />
          <v-btn text class="mr-1" color="success" @click="resetSearch">{{
            $t("drawer.reset")
          }}</v-btn>
          <v-btn text color="primary" @click="search">{{
            $t("drawer.filter")
          }}</v-btn>
        </v-row>
      </div>
    </div>
    <v-divider />
    <loading-icon v-if="loading" />
    <div v-else class="fill-height d-flex flex-column pb-2 overflow-auto">
      <perfect-scrollbar @ps-y-reach-end="loadMore">
        <div>
          <log-row
            v-for="item in items"
            :item="item"
            :key="item._id"
            @openImportErrorDialog="openImportErrorDialog"
          />
        </div>
      </perfect-scrollbar>
      <import-error-dialog
        v-if="showingDialog"
        v-model="showingDialog"
        :importId="importId"
      />
    </div>
    <dialog-button-select-employees
      v-if="showMemberDialog"
      v-model="showMemberDialog"
      :selectedValue="filters.users || []"
      :multiple="true"
      :addButton="false"
      @submit="onSubmit"
    ></dialog-button-select-employees>
  </div>
</template>
<script>
import { get } from "lodash";
import LoadingIcon from "@/components/global/loadingIcon";
import importErrorDialog from "./importErrorDialog";
import LogRow from "./logRow";
import dialogButtonSelectEmployees from "@/components/dialogButtons/DialogButtonSelectEmployees";
import selectedPersonTag from "@/components/dialogs/comps/SelectedPersonTag";

import mixin from "../mixin.js";


export default {
  mixins: [mixin],
  components: {
    importErrorDialog,
    LoadingIcon,
    LogRow,
    dialogButtonSelectEmployees,
    selectedPersonTag,
  },
  data() {
    return {
      limit: 20,
      showingDialog: false,
      showMemberDialog: false,
      importId: "",

      showFilter: false,
      filters: {},
      isExporting: false,
    };
  },
  computed: {
    appId() {
      return get(this.currentApp, "_id", "")
    },
    formId(){
      return get(this.$store.getters.currentForm, "_id", "");
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    currentViews() {
      return this.$store.getters.currentForm.views;
    },
    displayUsers() {
      return (this.filters.users && this.filters.users.slice(0, 1)) || [];
    },
    remainingUsersLength() {
      return (
        (this.filters.users &&
          this.filters.users.length - this.displayUsers.length) ||
        0
      );
    },
    dateRange() {
      return Array.isArray(this.filters.date) && this.filters.date.length === 2
        ? this.filters.date.map((item) => item.replaceAll("-", "/")).join(" - ")
        : "";
    },
  },
  async mounted() {
    this.loading = true;
    await this.getLogs();
    this.loading = false;
  },
  methods: {
    openImportErrorDialog(importId) {
      this.importId = importId;
      this.showingDialog = true;
    },
    loadMore() {
      if (this.hasNextPage) {
        // console.log('')
        this.offset += this.limit;
        this.getLogs();
      }
    },
    async getLogs() {
      const params = this.preparingGetParams({ isExport: false });
      const urlCommand = `/activity?${params}`;

      const getParams = {
        urlCommand,
      };
      let { docs: results, hasNextPage } = await this.$store.dispatch(
        "AUTH_GET",
        getParams
      );
      results.map((item) => {
        if (item.viewId) {
          if (item.mergeTag) {
            Object.entries(item.mergeTag).map(([key, value]) => {
              Object.assign(item.mergeTag, {
                [key]: this.$t(value),
              });
            });
          }
          const view = this.currentViews.find(
            (view) => view._id === item.viewId
          );
          if (view) Object.assign(item, { viewId: view.title });
        }
      });
      this.items = this.items.concat(results);

      this.resetRelativeTime();
      this.hasNextPage = hasNextPage;
    },
    onSubmit(e) {
      this.$set(this.filters, "users", e);
    },
    assignDateRange(e) {
      this.$set(this.filters, "date", e.sort());
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "removeEmployeeByIndex":
          this.filters.users.shift();
          break;
      }
    },
    resetSearch() {
      this.filters = {};
      this.search();
    },
    search() {
      this.hasNextPage = false;
      this.offset = 0;
      this.items = [];
      this.getLogs();
    },
    preparingGetParams({ isExport }) {
      isExport = isExport || false;
      let _params = {
        parentId: this.parentId,
        appId: this.appId,
      };

      if (this.dateRange) {
        _params["from"] = this.filters.date[0];
        _params["to"] = this.filters.date[1];
      }

      if (this.displayUsers.length > 0) {
        const ids = this.filters.users.map((user) => user._id);
        _params["users"] = ids.join(",");
      }

      if (!isExport) {
        _params["limit"] = this.limit;
        _params["offset"] = this.offset;
      }

      return new URLSearchParams(_params).toString();
    },
    async exportLog() {
      this.isExporting = true;
      const params = this.preparingGetParams({ isExport: true });
      const urlCommand = `/activity/export?format=excel&${params}`;

      let response = await this.$store.dispatch("AUTH_GET", { urlCommand });
      this.isExporting = false;
      const bytes = Buffer.from(response, "base64"); // pass your byte response to this constructor

      const contentBlob = new Blob([bytes], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const encodeUri = window.URL.createObjectURL(contentBlob);
      const link = document.createElement("a");
      link.href = encodeUri;
      link.download = `logs-${this.currentApp.title}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
        key: "plus_sheet_mani",
        data: {
          module_name: this.$store.getters.formLabel,
          func_name: this.$sensors.events.plus_sheet_mani.discussion.EXPORT_LOGS,
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.filter-box {
  margin: 20px;
  padding: 5px;
  border: 1px solid $primary;

  position: relative;
  border-radius: 4px;

  .triangle {
    position: absolute;
    width: 14px;
    height: 14px;
    border-top: 1px solid $primary;
    border-left: 1px solid $primary;

    transform: rotate(45deg);
    top: -8px;
    left: 30px;
    background: #fff;
  }
}
</style>
<style lang="scss">
.drawer-container .ps {
  height: 0px;
  flex: auto;
}
</style>
