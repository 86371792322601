<template>
  <div class="workflow-node-root">
    <div class="workflow-node-col">
      <div>
        <div class="workflow-node-branch">
          <v-icon class="workflow-node-branch-btn">
            mdi-plus-circle
          </v-icon>
          <div class="workflow-node-root">
            <div class="workflow-node-col">
              <div>
                <div class="workflow-clear-left-border preview" />
                <div class="workflow-node-box">
                  <div
                    class="workflow-node-item"
                  >
                    <div class="workflow-node-item-name workflow-node-item-branchName">
                      <div class="workflow-node-item-nameText">
                        <v-skeleton-loader
                          v-bind="attrs"
                          type="text"
                        >
                        </v-skeleton-loader>
                      </div>
                    </div>
                    <div class="workflow-node-item-content">
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="card-heading"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                  <div class="workflow-node-item-addnode">
                    <v-icon class="workflow-node-item-add-icon">
                      mdi-plus-circle
                    </v-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="workflow-node-col">
              <div>
                <div class="workflow-clear-right-border preview" />
                <div class="workflow-node-box">
                  <div class="workflow-node-item">
                    <div class="workflow-node-item-name workflow-node-item-branchName">
                      <div class="workflow-node-item-nameText">
                        <v-skeleton-loader
                          v-bind="attrs"
                          type="text"
                        >
                        </v-skeleton-loader>
                      </div>
                    </div>
                    <div class="workflow-node-item-content">
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="card-heading"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                  <div class="workflow-node-item-addnode">
                    <v-icon class="workflow-node-item-add-icon">
                      mdi-plus-circle
                    </v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="workflow-node-branch-line-btn">
          <div class="workflow-node-item-addnode">
            <v-icon class="workflow-node-item-add-icon">
              mdi-plus-circle
            </v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
};

</script>

<style scoped>
</style>
