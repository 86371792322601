
import { get, replace } from 'lodash';
import SettingUtils from '@/pages/admin/workflow/utils/setting';
const mixin = {
  computed: {
    notice() {
      const { properties } = this.node;
      return get(properties, 'notice', {});
    },
    totalRecipient() {
      const { notice: { otherRecipient=[], recipient=[], phoneRecipient=[] } } = this;
      return otherRecipient.length + recipient.length + phoneRecipient.length;
    },
    isPending() {
      const { notice: { content }, totalRecipient } = this;
      return totalRecipient > 0 && !content;
    },
    parameters() {
      return this.$store.getters.getWorkflowParameters;
    },
    content() {
      const { notice } = this;
      return get(notice, 'content') || '';
    },
    propertiesFields() {
      return get(this.node, 'propertiesFields') || [];
    },
    regularFields() {
      return get(this.node, 'properties.fields') || [];
    },
    // propertiesParemeters() {
    //   return get(this.node, 'propertiesParemeters') || [];
    // },
    expressionDisplay() {
      const { propertiesFields=[], regularFields=[] } = this;
      console.log(regularFields);
      const display = SettingUtils.normalizeFormulaExpression({
        fields: [...regularFields, ...propertiesFields, ...this.parameters],
        node: this.node,
        expression: this.content,
        locale: this.$t('workflow')
      });
      return replace(display, /(?:\r\n|\r|\n)/g, '<br />');
    }
  },
}

export default mixin
