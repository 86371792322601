const mixin = {
  props: {
    view: Object,
    printTemplates:Array
  },
  computed: {
    currentApp () {
      return this.$store.getters.currentApp
    },
    form () {
      return this.$store.getters.currentForm
    },
    titleFieldId () {
      const vm = this
      var result = ''
      if (vm.fieldInfos && vm.form.titleFieldInfoId) {
        const titleFieldInfo = vm.fieldInfos.find(info => info._id === vm.form.titleFieldInfoId)
        if (titleFieldInfo) {
          result = titleFieldInfo.fieldId
        }
      }
      return result
    },
    fieldInfos () {
      return this.form.fieldInfos
    },
    fieldInfoFieldIds () {
      return this.fieldInfos.map(info => info.fieldId)
    }
  },
  methods: {
    getWidgetByKey (key) {
      const vm = this
      const result = vm.$store.getters.allWidgets.find(w => w.key === key)
      return result
    },
    fieldIdExists(fieldId) {
      return this.fieldInfos.find(info => info.fieldId === fieldId)
    }
  }
}

export default mixin
