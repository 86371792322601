var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"width":"200px"}},[(!_vm.editing)?_c('url-box',{staticClass:"px-1",attrs:{"url":_vm.publicEdit.identifier,"canCopy":false,"maxWidth":"200px","noQRCode":""},nativeOn:{"dblclick":function($event){_vm.editing = true}},scopedSlots:_vm._u([{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.editing = true}}},[_vm._v("mdi-pencil-outline")])]},proxy:true}],null,false,967688470)}):_c('v-text-field',{staticClass:"px-1",staticStyle:{"maxWidth":"200px","font-size":"13px"},attrs:{"hide-details":"","height":"36","solo":"","autofocus":"","filled":"","flat":"","color":"white","background-color":"grey lighten-2"},on:{"blur":function($event){_vm.saveSetting('identifier', _vm.publicEdit.identifier);
            _vm.editing = false;}},model:{value:(_vm.publicEdit.identifier),callback:function ($$v) {_vm.$set(_vm.publicEdit, "identifier", $$v)},expression:"publicEdit.identifier"}})],1),_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attr = ref.attr;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.deleteForm}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("buttons.delete"))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attr = ref.attr;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":_vm.openBuilder}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cog-outline ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("general.settings"))+" ")])],1),(_vm.showingFieldSettingDialog)?_c('PublicEditFieldSettingDialog',{ref:"publicEditFieldSettingDialog",attrs:{"form":_vm.form},on:{"saveFieldsSetting":_vm.saveFieldsSetting},model:{value:(_vm.showingFieldSettingDialog),callback:function ($$v) {_vm.showingFieldSettingDialog=$$v},expression:"showingFieldSettingDialog"}}):_vm._e(),(_vm.showFormBuilder)?_c('form-builder',{attrs:{"publicForm":_vm.publicEdit,"isPublicEdit":true},on:{"change":_vm.saveForm,"commandHandler":_vm.onCommandHandler},scopedSlots:_vm._u([{key:"settings",fn:function(){return [_c('div')]},proxy:true}],null,false,2141057942),model:{value:(_vm.showFormBuilder),callback:function ($$v) {_vm.showFormBuilder=$$v},expression:"showFormBuilder"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }