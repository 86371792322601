const ObjectID = require("bson-objectid");

const mixin = {
  data() {
    return {
      nextColorIndex: 0,
      newOptionIds: [],
    };
  },
  computed: {
    colorSets() {
      return this.$store.getters.colorSets;
    },
    options() {
      return this.fullOptions.filter((item) => !item.isDeleted);
    },
    deletedOptions(){
      return this.fullOptions.filter((item) => item.isDeleted);
    }
  },
  methods: {
    addNewOption() {
      const _id = ObjectID().toHexString();

      const newOption = {
        _id,
        order: this.fullOptions.length,
        label: this.$t("widgets.properties.option") + (this.options.length + 1),
        colorSet: this.getNextColor(),
        isDeleted: false,
        active: true,
      };

      this.fullOptions.push(newOption);

      this.newOptionIds.push(_id);

      this.$nextTick(() => {
        const inputOptionElements = document.getElementsByClassName(
          "option-label-input"
        );

        inputOptionElements[inputOptionElements.length - 1].select();
      });
    },
    getNextColor() {
      const result = this.colorSets[this.nextColorIndex];
      this.nextColorIndex = (this.nextColorIndex + 1) % this.colorSets.length;
      return result;
    },
    bulkAddOptions(options) {
      const newOptions = options.map((label, index) => ({
        _id: ObjectID().toHexString(),
        order: this.fullOptions.length + index,
        label,
        colorSet: this.getNextColor(),
        isDeleted: false,
        active: true,
      }));

      this.fullOptions = this.fullOptions.concat(newOptions);
      this.newOptionIds = this.newOptionIds.concat(
        newOptions.map((item) => item._id)
      );
    },
    removeOption(_id) {
      const index = this.fullOptions.findIndex((item) => item._id === _id);
      const indexInNewIdArr = this.newOptionIds.findIndex(
        (newId) => newId === _id
      );

      if (
        (this.fieldInfoId && this.fieldInfoId.startsWith("_")) ||
        (index > -1 && indexInNewIdArr > -1)
      ) {
        //newly created option. Not on server => hard delete
        this.fullOptions.splice(index, 1);
        this.newOptionIds.splice(indexInNewIdArr, 1);
        this.reindexInputOptions();
      } else if (index > -1) {
        //old option. Soft delete
        this.fullOptions[index].isDeleted = true;
      }

      if(this.fieldType=='singleSelection'){
        if(this.propertyValue.defaultSelection==_id)
          this.propertyValue.defaultSelection = ""
      }else if(this.fieldType=='multipleSelection'){
        if(this.propertyValue.defaultSelection.includes(_id)){
          var pos = this.propertyValue.defaultSelection.indexOf(_id)
          this.propertyValue.defaultSelection.splice(pos, 1);
        }
      }
      //this.updatePropertyValue();
    },
    reindexInputOptions() {
      this.fullOptions.map((item, index) => {
        this.$set(item, "order", index);
      });
    },

    updateOption(_id, val, key) {
      const option = this.fullOptions.find((item) => item._id === _id);

      if (option) this.$set(option, key, val);

    },
    updateColorSet(_id, colorIndex) {
      const vm = this;
      const colorSet = vm.colorSets[colorIndex];
      const option = this.fullOptions.find((item) => item._id === _id);
      if (option) this.$set(option, "colorSet", colorSet);
    },
    initNextColorIndex(){
        this.nextColorIndex = this.fullOptions.length
    },
    reorder(options) {

        this.fullOptions = options.concat(this.deletedOptions)
        this.reindexInputOptions();
      }
  },
};

export default mixin;
