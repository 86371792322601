import NodeUtils from './../../../utils/node';
import {
  SET_MODULE_MENU_STATUS,
  REMOVE_WORKFLOW_NODE,
  COPY_WORKFLOW_NODE,
  ADD_WORKFLOW_NODE,
  PICKING_MODULE,
  VALIDATION_UPDATE_EDIT_NODE,
} from '@/store/modules/workflow/action_types';
const mixin = {
  data() {
    return {
      pendingNode: null,
      mergeInfo: null,
    };
  },
  methods: {
    closeMerge() {
      this.mergeInfo = null;
      this.$store.dispatch(PICKING_MODULE, null);
      this.$store.dispatch(SET_MODULE_MENU_STATUS, false);
    },
    async executePreInsertNode(info, mergeToBranch, dependsOnParent) {
      const { node, offset, config, tree } = info;
      // put to the node to be added
      const newNode = this.tree.prepareNode(tree || this.node, node, config, this.$t('workflow'));
      newNode.name = this.$t(`workflow.label.${config.name}`);
      this.pendingNode = { tree: tree || this.node, node: newNode, offset, mergeToBranch, dependsOnParent };
      const formated = NodeUtils.normalizeNewNodeToBeSaved(newNode);
      // console.log(JSON.stringify(formated));
      this.$store.dispatch(ADD_WORKFLOW_NODE, {
        node: formated,  // normalization
        parent: formated.parent,  // added to 
        workflow: this.workflowInfo._id,
        mergeToBranch,
        dependsOnParent,
        workflowInfo: this.workflowInfo,
        appId: this.params.appId,
      });
    },
    confirmMerge(type, dependsOn) {
      this.executePreInsertNode(this.mergeInfo, type, dependsOn);
      // this.closeMerge();
    },
    async cloneNode ({ node, offset }) {
      this.pendingNode = { node, offset };
      const formated = NodeUtils.normalizeCopiedNodeToBeSaved(node, this.$t('workflow'));
      await this.$store.dispatch(COPY_WORKFLOW_NODE, {
        nodes: [ formated ],  // normalization
        parent: node._id,
        workflow: this.workflowInfo._id,
        workflowInfo: this.workflowInfo,
      });
      this.$store.dispatch(VALIDATION_UPDATE_EDIT_NODE);
    },
    async removeNode ({ node, offset }) {
      this.pendingNode = { node, offset };
      await this.$store.dispatch(REMOVE_WORKFLOW_NODE, {
        node: node._id,  // normalization
        workflow: this.workflowInfo._id,
        workflowInfo: this.workflowInfo,
      });
      this.$store.dispatch(VALIDATION_UPDATE_EDIT_NODE);
    },
    // executeAddNode(node) {
    //   if (!!node && this.pendingNode) {
    //     let { tree } = this.pendingNode;
    //     tree = tree || this.node;
    //     // merge the properties from new node responsed from api
    //     NodeUtils.mergeNewNodeToBeSaved(node, this.pendingNode.node);
    //     this.tree.insertNode(tree, this.pendingNode.node, this.pendingNode.offset);
    //     this.$store.dispatch(SET_MODULE_MENU_STATUS, false);
    //     this.pendingNode = null;
    //   }
    // }
  },
  computed: {
    workflowInfo() {
      return this.$store.getters.getWorkflowInfo;
    },
    nodeToBeAdded() {
      return this.$store.getters.getAddedNode;
    },
    nodeToBeRemoved() {
      return this.$store.getters.getRemovedNode;
    },
    nodeToBeCopied() {
      return this.$store.getters.getCopiedNode;
    },
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
  },
  beforeUnmount() {
    this.pendingNode = null;
  },
  // watching the side-effect action
  watch: {
    nodeToBeAdded: {
      handler(node) {
        if (!!node && this.pendingNode) {
          let { tree } = this.pendingNode;
          tree = tree || this.node;
          // merge the properties from new node responsed from api
          NodeUtils.mergeNewNodeToBeSaved(node, this.pendingNode.node);
          this.tree.insertNode(tree, this.pendingNode.node, this.pendingNode.offset, this.pendingNode.mergeToBranch, this.pendingNode.dependsOnParent);
          this.$store.dispatch(SET_MODULE_MENU_STATUS, false);
          this.pendingNode = null;
        }
        this.closeMerge();
        // let { tree, offset } = this.pendingNode;
        // tree = tree || this.node;
        // // has the next node and the current node to be inserted is forking node
        // if (tree.length - 1 > offset && NodeUtils.isFork(node.nodeType)) {
        //   this.showMerge = node;  // ask for way to merge
        // } else {
        //   this.executeAddNode(node);
        // }
        // this.executeAddNode(node);
      },
      deep: true,
    },
    nodeToBeRemoved: {
      handler(node) {
        if (!!node && this.pendingNode) {
          this.tree.remove(this.node, this.pendingNode.node, this.pendingNode.offset);
          this.pendingNode = null;
        }
      },
      deep: true,
    },
    nodeToBeCopied: {
      handler(node) {
        if (!!node && this.pendingNode) {
          // NodeUtils.mergeCopiedNodeToBeSaved(node, this.pendingNode.node);
          const newNode = { ...this.pendingNode.node, _id: node._id };
          this.tree.copy(this.node, newNode, this.pendingNode.offset, this.$t('workflow'));
          this.pendingNode = null;
        }
      },
      deep: true,
    },
  }
}

export default mixin
