<template>
  <v-tooltip bottom :disabled="!showTooltip">
    <template v-slot:activator="{ on, attrs }" :disabled="!withMultipleTeams || info.type === 'team'">
      <v-chip class="mr-1 employee-chip"
        v-bind="attrs"
        v-on="on"
        :class="{'multiple-teams':withMultipleTeams, 'pr-1':bl_list}"
        small
        :close="hasCloseButton"
        :color="
          [
            // '630430e7c001cd8e8eb0ab7a', // Public Form User
            '62b29ac9e5965b8574cf6244', // Workflow Trigger
          ].includes(employeeId) ?
          $vuetify.theme.themes.light.color.system :
          $vuetify.theme.themes.light.primary
        "
        text-color="white"
        @click:close="onCloseClicked"
      >
        <div class="w-100">
          <div class="d-flex flex-column line-height-1">
            <span>{{ displayName }}<b v-if="withTitle"> ({{ displayNameType }})</b></span>
            <small v-if="showingTeamName" class="text-ellipsis" style="color:rgba(250,250,250,.7)">{{teamName}}</small>
          </div>
        </div>
      </v-chip>
    </template>
    <span>{{ teamName }}</span>
  </v-tooltip>
</template>

<script>
import SettingUtils from '@/pages/admin/workflow/utils/setting';
export default {
  name: 'selectedPersonTag',
  props: {
    clickCommand: String,
    deleteCommand: String,
    name: String,
    info: {
      type: Object,
      default: ()=>  {},
    },
    employeeId: String,
    teamId: String,
    index: Number,
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    bl_list:{
      type: Boolean,
      default: false
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    readonly:{
      type: Boolean,
      default: false
    },
    teamShowing: {
      type: String,
      default: 'showTooltip'
    }
  },
  computed: {
    showingTeamName () {
      return this.showAlways || (this.showIfDifferent && (this.activeTeamId!==this.teamId))
    },
    showAlways () {
      return this.teamShowing ? this.teamShowing==='showAlways' : false
    },
    showIfDifferent () {
      return this.teamShowing ? this.teamShowing==='showIfDifferent' : false
    },
    showTooltip () {
      return this.teamShowing ? this.teamShowing==='showTooltip' : false
    },
    displayIcon(){
      return SettingUtils.userPopupDisplayIcon(this.info);
    },
    displayName(){
      return SettingUtils.userPopupDisplayName(this.info);
    },
    displayNameType(){
      return SettingUtils.userPopupDisplayNameType(this.info, this.$t('workflow.label'));
    },
    withMultipleTeams () {
      return this.$store.getters.allTeams.length > 1
    },
    teamName(){
      const teams = this.$store.getters.allTeams;
      const team = teams.find(team => team._id === this.teamId);
      return team ? team.name: '';
    },
    activeTeamId() {
      return this.$store.getters.activeTeamId
    }
  },
  methods: {

    onCloseClicked () {
      const vm = this
      if (vm.deleteCommand) {
        vm.$emit('onCommand', {
          command: vm.deleteCommand,
          index: vm.index
        })
      }
    },

  }
}
</script>

<style>
  .employee-chip-list > .v-chip__content{
    width:100% !important;
  }
  .selected-person-tag-text {
    color: white !important;
  }
</style>