<template>
<v-row dense>
    <v-col cols="4" class="d-flex flex-row align-center">
      <label>{{ $t(labelTag) }}</label>
    </v-col>
    <v-col cols="8" class="d-flex flex-row align-center">
      <input-radio-toggle
        :value="value"
        @input="val=>updateValue(val)"
          :options="options"
          :disabled="disabled"
          ref="inputToggle"></input-radio-toggle>
      <!--<input-yes-no-->
          <!--:value="valueStr"-->
          <!--ref="inputYesNo"-->
          <!--@input="val=>updateUserValue(val)"></input-yes-no>-->
    </v-col>
  </v-row>
</template>

<script>
  import inputRadioToggle from '@/components/formComps/InputRadioToggle'

  export default {
    name: 'settingsYesNoRow',
    components: {
      inputRadioToggle
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      labelTag: String,
      value: String,
      disabled: {
        type: Boolean,
        default: false
      },
      options: {
          type: Array,
          default () {
              return []
          }
      }
    },
    methods: {
        updateValue (val) {
            const vm = this
            console.log('updateValue: val: ', val)
            vm.$emit('input', val)
        }
    }
    //   valueStr: {
    //     get () {
    //       return this.value ? 'true' : 'false'
    //     },
    //     set (val) {
    //       console.log('SettingsRadioToggle : val: ', val)
    //       this.$emit('input', val)
    //       //updateUserValue(val)
    //     }
    //   }
    // },
    // methods: {
    //   checkIsValid (state) {
    //     return this.$refs.inputYesNo.checkIsValid(state)
    //   }
    // }

  }
</script>
