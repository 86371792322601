<template>
    <v-navigation-drawer
        class="elevation-1 gantt-setting-drawer"
        v-model="showingDrawer"
        right
        floating
        style="position: relative; border: #d9d9d9 1px solid"
        width="350"
        absolute
        transition="scroll-x-transition"
    >
    <v-card tile style="height:100%" class="elevation-0">
        <v-card-title class="py-1 elevation-1">
            <v-icon size="20">mdi-cog</v-icon>
            <span class="ml-1" style="font-size:14px">{{$t('gantt.setting')}}</span>
        </v-card-title>
        <v-card-text>
            <!-- <div class="mt-2 d-flex flex-column"> -->
                <!-- <div class="d-flex align-center">
                    <div class="teal lighten-3" style="width:4px; height:12px"></div>
                    <div class="ml-2" style="font-size:14px">Hover Infos</div>
                    <v-btn-toggle 
                        v-model="ganttSetting.customHover"
                        color="teal"
                        dense
                        class="ml-auto"
                        mandatory
                        @change="$emit('updateSetting', {command:'update_customHover', val:$event})"
                    >
                        <v-btn :value="false" style="width:55px; font-size:12px" x-small>default</v-btn>
                        <v-btn :value="true" style="width:55px; font-size:12px" x-small>custom</v-btn>
                    </v-btn-toggle>
                </div> -->
                <!-- <div class="mt-2" v-if="ganttSetting.customHover">
                        <draggable
                            v-if="ganttSetting.hoverInfos.length"
                            class="list-group"
                            tag="ul"
                            v-model="ganttSetting.hoverInfos"
                            v-bind="dragOptions"
                            @start="drag = true"
                            @end="drag = false"
                            handle=".gantt-setting-drawer-handle"
                            @change="$emit('updateSetting', {command: 'update_hoverInfos', action:'drag'})"
                        >
                            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                <li
                                    class="list-group-item my-1"
                                    v-for="(element,index) in ganttSetting.hoverInfos"
                                    :key="element.order"
                                >
                                    <v-hover v-slot="{hover}">
                                        <div class="d-flex align-end">
                                            <v-icon size="20" class="gantt-setting-drawer-handle">mdi-format-line-spacing</v-icon>
                                                <v-select
                                                    v-model="element.fieldId"
                                                    :items="form.fieldInfos"
                                                    filled
                                                    dense
                                                    hide-details
                                                    @change="$emit('updateSetting', {command: 'update_hoverInfos',  action:'update', val: {fieldId: $event, order: element.order}, index })"
                                                    height="25"
                                                    item-text="label"
                                                    item-value="fieldId"
                                                    style="font-size:13px"
                                                    :menu-props="{offsetY: true, contentClass: 'denseList'}"
                                                ></v-select>
                                            <v-icon :style="`visibility: ${hover?'visible':'hidden'}`" size="20" @click="$emit('updateSetting', {command: 'update_hoverInfos',  action:'delete', index })" class="gantt-setting-drawer-handle">mdi-close</v-icon>
                                        </div>
                                    </v-hover>
                                </li>
                            </transition-group>
                        </draggable>
                    <div class="mt-1">
                        <v-btn 
                            color="teal lighten-3 elevation-0" 
                            x-small 
                            style="width:25px;min-width:25px"
                            @click="addInfo()"
                        >
                            <v-icon small style="color: white">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </div>
                </div> -->
            <!-- </div> -->
            <div class="mt-2 d-flex flex-column">
                <div class="d-flex align-center">
                    <div class="teal lighten-3" style="width:4px; height:12px"></div>
                    <div class="ml-2" style="font-size:14px">{{$t('gantt.groupBy')}}</div>
                </div>
                <div class="mt-2">
                    <div>
                        <v-select
                            :items="filteredGroupByFields"
                            filled
                            dense
                            hide-details
                            height="25"
                            item-text="label"
                            item-value="fieldId"
                            style="font-size:13px"
                            :menu-props="{offsetY: true, contentClass: 'denseList'}"
                            @input="$emit('updateSetting', {command: 'update_groupBy', 'val': $event})"
                            v-model="ganttSetting.groupBy"
                        ></v-select>
                    </div>
                </div>
            </div>
            <div class="mt-2 d-flex flex-column">
                <div class="d-flex align-center">
                    <div class="teal lighten-3" style="width:4px; height:12px"></div>
                    <div class="ml-2" style="font-size:14px">{{$t('gantt.labelBy')}}</div>
                </div>
                <div class="mt-2">
                    <div>
                        <v-select
                            :items="filteredLabelByFields"
                            filled
                            dense
                            hide-details
                            height="25"
                            item-text="label"
                            item-value="fieldId"
                            style="font-size:13px"
                            :menu-props="{offsetY: true, contentClass: 'denseList'}"
                            @input="$emit('updateSetting', {command: 'update_labelBy', 'val': $event})"
                            v-model="ganttSetting.labelBy"
                        ></v-select>
                    </div>
                </div>
            </div>
            <div class="mt-2 d-flex flex-column">
                <v-checkbox
                    v-model="ganttSetting.showGroupSummary"
                    @change="$emit('updateSetting', {command: 'update_showGroupSummary', 'val': $event})"
                    :label="$t('gantt.showGroupSummary')"
                    dense
                    :ripple="false"
                ></v-checkbox>
                <v-checkbox
                    v-model="ganttSetting.showTodayIndication"
                    @change="$emit('updateSetting', {command: 'update_showTodayIndication', 'val': $event})"
                    :label="$t('gantt.showToday')"
                    dense
                    :ripple="false"
                ></v-checkbox>
                
                <!-- <div class="d-flex align-center">
                    <div class="teal lighten-3" style="width:4px; height:12px"></div>
                    <div class="ml-2" style="font-size:14px">Label By</div>
                </div> -->
                <!-- <div class="mt-2">
                    <div>
                        <v-select
                            :items="filteredLabelByFields"
                            filled
                            dense
                            hide-details
                            height="25"
                            item-text="label"
                            item-value="fieldId"
                            style="font-size:13px"
                            :menu-props="{offsetY: true, contentClass: 'denseList'}"
                            @input="$emit('updateSetting', {command: 'update_labelBy', 'val': $event})"
                        ></v-select>
                    </div>
                </div> -->
            </div>
        </v-card-text>
    </v-card>
    </v-btn>
    </v-navigation-drawer>
</template>


<script>
import draggable from "vuedraggable";
  export default {
    props:{
        value: Boolean,
        form: Object,
        ganttSetting: Object,
        formInfoDict: Object
    },
    components: {
        draggable
    },
    computed:{
        showingDrawer:{
            get(){
                return this.value
            },
            set(val){
                this.$emit('showingDrawer', val)
            },
        },
        dragOptions() {
            return {
                animation: 200,
                group: "hoverInfos",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        filteredGroupByFields(){
            var filtered
            if(this.ganttSetting.projectRelation=="MULTIPLE_RELATED"){
                var leafFormId = this.ganttSetting.projectCascade[this.ganttSetting.projectCascade.length-1].relatedFormId
                var leafForm = this.formInfoDict[leafFormId]
                filtered = leafForm.fieldInfos.filter(field=>this.typesCanGroup.includes(field.type))
            }else{
                filtered = this.form.fieldInfos.filter(field=>this.typesCanGroup.includes(field.type))
            }
            filtered.push({label: 'default', fieldId: this.ganttSetting.projectCascade})
            return filtered
        },
        filteredLabelByFields(){
            if(this.ganttSetting.projectRelation=="MULTIPLE_RELATED"){
                var leafFormId = this.ganttSetting.projectCascade[this.ganttSetting.projectCascade.length-1].relatedFormId
                var leafForm = this.formInfoDict[leafFormId]
                return leafForm.fieldInfos.filter(field=>this.typesCanLabel.includes(field.type))
            }else{
                return this.form.fieldInfos.filter(field=>this.typesCanLabel.includes(field.type))
            }
        }
    },
    data: () => ({
        drawer: false,
        group: null,
        typesCanGroup:['singleSelection', 'members', 'owner', 'createdBy'],
        drag: false,
        typesCanLabel: ['text', 'number', 'amount', 'date', 'members', 
                        'departments', 'singleSelection', 'multipleSelection',
                        'createdBy', 'owner', 'createdAt', 'updatedAt']
    }),

    watch: {
        group () {
            this.drawer = false
        }
    },
    methods:{
        addInfo(index){
            this.$emit('updateSetting',
                {
                    command: 'update_hoverInfos', 
                    action:'add',
                    val: {fieldId: null},
                }
            )
            // this.ganttSetting.hoverInfos.push([
            //     {
            //         fieldId: null
            //     }
            // ])
        }
    }
  }
</script>

<style>
.gantt-setting-drawer .flip-list-move {
  transition: transform 0.5s;
}
.gantt-setting-drawer .no-move {
  transition: transform 0s;
}
.gantt-setting-drawer .ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.gantt-setting-drawer .list-group {
  min-height: 20px;
}
.gantt-setting-drawer .list-group-item {
  cursor: move;
}
.gantt-setting-drawer .list-group-item i {
  cursor: pointer;
}
.gantt-setting-drawer ul {
  list-style-type: none;
}
</style>