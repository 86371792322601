<template>
  <div class="workflow-node-item-addnode" :style="layoutStyle && layoutStyle()">
    <v-tooltip bottom v-if="showAddNode && !dropTarget && !picking() && !$store.state.workflowModules.copyingNode">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
          @click="$emit('insert', { node, offset })"
          :disabled="(node && node.notReady) || $store.getters.isUIBlocked"
        >
          <v-icon class="workflow-node-item-add-icon">
            mdi-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>
        {{ 
          fromGroup ? 
          $t('workflow.message.addNodeUnderForking') : 
          $t("workflow.message.addNodeUnder", { name: node.name }) 
        }}
      </span>
    </v-tooltip>
    <PreviewNode v-if="!!dropTarget" :data="dropTarget" />
    <CloneNode v-if="picking()" />
  </div>
  
</template>

<script>
/*
* Single node footer with preview and code node mode
* Terry Chan
* 26/05/2021
*/

import PreviewNode from './../preview/';
import CloneNode from './../clone';

export default {
  props: {
    node: Object,
    offset: Number,
    dropTarget: Number,
    fromGroup: Boolean,
    picking: Function,
    showAddNode: Boolean,
    // copying: Boolean,
    insert: Function,
    layoutStyle: Function,
  },
  components: {
    PreviewNode,
    CloneNode,
  },
};

</script>

<style scoped>
  
</style>
