<template>
  <div class="time-segment-comp">
    <label>{{ $t('inputPanel.timeSegment') }}</label>
    <v-row>
      <v-col>
        <dialog-field-date-time
          ref="startTime"
          :value="timeSegment.startTime"
          @input="val=>{updateStartTime(val)}"></dialog-field-date-time>
      </v-col>
      <v-col>
        <dialog-field-date-time
          ref="endTime"
          :value="timeSegment.endTime"
          @input="val=>{updateEndTime(val)}"></dialog-field-date-time>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" class="py-0 my-0">
        <label>{{ $t('inputPanel.changeField') }}</label>
      </v-col>

      <v-col cols="12" 
        class="py-0"
        v-for="(action, index) in timeSegment.actions"
        :key="index"
        >
        <time-action 
          ref="timeAction"
          :action="action"
          :fieldInfos="fieldInfos"
          :index="index"
          @delete="index=>{deleteAction(index)}"
          @update="val=>{updateAction(index,val)}"></time-action>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col cols="12">
        <v-btn fab x-small color="secondary"
          @click="addTimeAction">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import timeAction from './TimeAction'
import DialogFieldDateTime from '@/components/dialogFields/DialogFieldDateTime'

export default {
  name: 'timeSegmentComp',
  components: {    
    timeAction,
    DialogFieldDateTime
    // {
    //    startTime: '...',
    //    endTime: '...',
    //    actions: [
    //      {
    //        dataType: ['date','time','datetime'],
    //        fieldId: '...'
    //      },
    //      {
    //        dataType: ['date','time','datetime'],
    //        fieldId: '...'
    //      },
    //      ...
    //    ]
    // }
  },
  model: {
    prop: 'timeSegment',
    event: 'input'
  },
  props: {
    targetForm: Object,
    timeSegment: Object
  },
  computed: {
    timeSegmentStr () {
      return this.timeSegment.startTime + ' - ' + this.timeSegment.endTime
    },
    fieldInfos () {
      return this.targetForm ? 
        this.targetForm.fieldInfos.filter(info => info.type === 'date') :
        []
    }
  },
  data () {
    return {
      inputValue: '',
      actions: [
        {
          dataType: 'date',
          fieldId: ''
        },
        {
          dataType: 'time',
          fieldId: ''
        },
        {
          dataType: 'datetime',
          fieldId: ''
        }
      ]
    }
  },
  methods: {
    addTimeAction () {
      const updated = JSON.parse(JSON.stringify(this.timeSegment))
      updated.actions.push({
        dataType: 'time',
        fieldId: ''
      })
      this.$emit('update', updated)
    },
    deleteAction (index) {
      const updated = JSON.parse(JSON.stringify(this.timeSegment))
      updated.actions.splice(index, 1)
      this.$emit('update', updated)
    },
    updateAction (index, action) {
      const updated = JSON.parse(JSON.stringify(this.timeSegment))
      updated.actions[index] = action
      this.$emit('update', updated)
    },
    updateStartTime (val) {
      const updated = JSON.parse(JSON.stringify(this.timeSegment))
      updated.startTime = val
      this.$emit('update', updated)
    },
    updateEndTime (val) {
      const updated = JSON.parse(JSON.stringify(this.timeSegment))
      updated.endTime = val
      this.$emit('update', updated)
    },
    validate () {
      const vm = this
      var result = 
        vm.$refs.startTime.validate() && 
        vm.$refs.endTime.validate()
      result = result && (this.timeSegment.actions.length > 0)
      vm.$refs.timeAction.forEach(f => {
        result = result && f.validate()
      })
      return result
    }
  }
}
</script>

<style>
.time-segment-comp .v-text-field__details {
  display: none !important;
}
</style>