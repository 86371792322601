<template>
  <FormElement name="hookEvent" :label="$t('workflow.modules.createRecord.label.how')">
    <v-radio-group v-model="setting.hookEvent" @change="onChangeHookEvent">
      <v-radio
        v-for="item in selections"
        :key="item.key"
        :value="item.key"
        :disabled="item.disabled"
        class="workflow-field-radio-element"
      >
        <template v-slot:label>
          <component
            :is="item.component"
            :list="list"
            :label="item.label"
            :selected="isSelected(item)"
            @changed="changed"
          />
        </template>
      </v-radio>
    </v-radio-group>
  </FormElement>
</template>

<script>
import FormElement from './../../shared/formElement';
import OnCreateFromSource from './createFromSource';
import OnCreateOne from './createOne';

import Mixin from './../../mixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  computed: {
    hookEvent() {
      return this.setting.hookEvent;
    },
  },
  data() {
    return {
      selections: [
        {
          key: 'createOne',
          label: this.$t('workflow.modules.createRecord.label.createOne'),
          component: 'OnCreateOne',
        },
        {
          key: 'createFromSource',
          label: this.$t('workflow.modules.createRecord.label.createFromSource'),
          // list: this.list,
          component: 'OnCreateFromSource',
        },
      ],
    };
  },
  methods: {
    isSelected({ key }) {
      return this.hookEvent === key;
    },
    onChangeHookEvent(v) {
      let setting = { hookEvent: v };
      if (v === 'createOne') {
        setting = { dataSource: null };
      }
      this.$store.dispatch(SET_SETTINGS, setting);
    },
  },
  components: {
    FormElement,
    OnCreateFromSource,
    OnCreateOne,
  },
};

</script>

<style scoped>
  
</style>
