const { includes } = require('lodash');
const formRoutes = 'forms';
const mixin = {
    computed: {
        prevRoute() {
            return this.$store.getters.prevRoute;
        },
        currentApp () {
            return this.$store.getters.currentApp;
        },
        params() {
            const { id, itemId, viewId } = this.$route.params;
            return {
                appId: id,
                itemId,
                viewId,
            };
        },
        currentRouteParams () {
            const { id, itemId, viewId } = this.$route.params;
            let params = {
                id,
                itemId,
            }
            if (!!viewId) {
                params = { ...params, viewId };
            }
            return params;
        },
        errorLog() {
            return this.$store.getters.validationInfo;
        },
        workflow() {
            return this.$store.getters.getWorkflowInfo;
        },
        validatingWorkflow() {
            return this.$store.getters.validatingWorkflow;
        },
        releasedAt() {
            const { workflow={} } = this;
            const { releasedAt } = workflow;
            return releasedAt && this.$locale.m(releasedAt).fromNow();
        },
        isReleaseError() {
            return !!this.$store.getters.releasedError;
        },
        releasedError() {
            const { releasedError={} } = this.$store.getters;
            return (releasedError && releasedError.errorLog) || [];
        }
    },
    methods: {
        returnToAppFormPage() {
            const self = this;
            if (self.prevRoute  && includes(["forms", "records"], self.prevRoute.name)) {
                self.$router.go(-1);
            } else {
                self.$router.push({
                    name: 'apps',
                    params: {
                        id: self.currentApp._id,
                    },
                });
            }
        },
    },
}

export default mixin
