<template>
  <v-layout align-center column>
     <v-img v-if="icon !== ''" :src="icon" class="w-coming-img"></v-img>
     <h1 v-if="title !== ''" class="w-coming-title">{{ warning }}</h1>
     <h2 v-if="subTitle !== ''" class="w-coming-subTitle">{{ $t(subTitle) }}</h2>
  </v-layout>
</template>

<script>
import StaticImage from './../constants/image';

export default {
  inject: ['getEventType'],
  props: {
    icon: {
      type: String,
      default: StaticImage.comingSoon,
    },
    title: {
      title: String,
      default: 'workflow.message.comingSoonEvent',
    },
    subTitle: {
      type: String,
      default: 'workflow.message.comingSoonWaiting',
    },
  },
  computed: {
    warning() {
      const eventType = this.$t(`workflow.events.${this.getEventType()}`);
      const sentense = this.$t(this.title, { eventType });
      return sentense;
    }
  },
};

</script>

<style scoped>
  .w-coming-img {
    width: 250px;
    margin: 50px 0;
  }
  .w-coming-title {
    font-size: 23px;
    color: #757575;
    margin: 10px 0;
  }
  .w-coming-subTitle {
    font-size: 15px;
    color: #757575;
    margin: 10px 0 30px 0;
  }
</style>
