<template>
  <div class="workflow-customized-action-main-button">
    <v-btn
      elevation="0"
      class="warning"
      height="32"
      style="border-radius: 46px;"
      @click="newCustomBtnClicked"
      v-if="size==0 && isAdmin"
    >
      <v-icon size="20">mdi-sitemap</v-icon>
      <span 
        class="ml-1" 
        style="font-size:12px"
      >
        {{$t("view.newCustomButtons")}}
      </span>
    </v-btn>
    <ListInline v-if="size>0" :size="size" @onSelected="onSelected" :selected="selected" />
  </div>
</template>

<script>
import ListMenu from './menu';
import ListInline from './list';
import Lottie from 'vue-lottie';
import PressAnimation from '@/pages/admin/workflow/assets/tap_hold.json';
import {
    POPUP_ACTION_BUTTON_POPUP,
} from '@/store/modules/customizedAction/action_types';

export default {
  components: {
    Lottie,
    ListMenu,
    ListInline,
  },
  props: {
    size: Number,
    selected: Array,
    displayType: {
      type: String,
      default: 'menu',
    },
  },
  data() {
    return {
      defaultOptions: {
        animationData: PressAnimation,
      },
      animationSpeed: 0.5,
    }
  },
  computed:{
    isAdmin() {
      return this.$store.getters.isAdmin;
    }
  },
  methods: {
    onSelected() {
      this.$emit('onSelected');
    },
    newCustomBtnClicked(){
      this.$store.dispatch(POPUP_ACTION_BUTTON_POPUP, 5)
    }
  }
}
</script>

<style scoped>
</style>