<template>
  <div class="my-apps overflow-auto d-flex flex-row fill-height w-100">
    <div
      class="apps-main d-flex fill-height flex-column align-stretch flex-grow-1"
      style="position:relative;"
    >
      <div
        style="height:0;min-height:100%;"
        class="fill-height flex-grow-1 d-flex flex-column"
      >
      
          <!--<v-card-my-app-menu></v-card-my-app-menu>-->
          <v-card flat class="my-apps-content flex-grow-1 px-2 w-100">
            <v-container fluid>
              <v-row v-if="!isTerminated">
                <v-col cols="12" class="py-0">
                  <!--
                  **************
                  My Favourite
                  **************
                   -->
                  <my-apps-section
                    :label="$t('myapps.myFavourite')"
                    :appInfos="isTerminated ? [] : uiFavAppInfos"
                    :isFavApp="true"
                    :dragging="dragging"
                    :draggingSection="draggingSection"
                    :showTeamName="true"
                    :withMenu="true"
                    @startDrag="startDragging('favourite')"
                    @endDrag="endDragging"
                    @change="(payload) => onDragged(payload, 'favApp')"
                    @click="onAppLogoClicked"
                    @click:menu="onAppMenuClicked"
                    @click:toggle="onToggleIcon"
                    section="favourite"
                  >
                  </my-apps-section>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <!--
                  ********************
                  My Current Team Apps
                  ********************
                   -->
                  <my-apps-section
                    :label="$t('myapps.myFavourite')"
                    :appInfos="isTerminated?[]:uiCurrTeamAppInfos"
                    :dragging="dragging"
                    :draggingSection="draggingSection"
                    :withMenu="true"
                    :showingMenu="true"
                    @startDrag="startDragging('team')"
                    @endDrag="endDragging"
                    @change="(payload) => onDragged(payload, 'currTeamApp')"
                    @click="onAppLogoClicked"
                    @click:menu="onAppMenuClicked"
                    @click:toggle="onToggleIcon"
                    section="team"
                  >
                    <template v-slot:header>
                      <div
                        class="d-flex flex-row align-end justify-space-between"
                      >
                        <select-team-header></select-team-header>
                        <v-btn
                          v-if="!isHidden('myapps_summary_button') && isOwner && !isTerminated"
                          small
                          text
                          @click="showAppsSummaryDialog = true"
                          ><v-icon>mdi-chart-bar</v-icon></v-btn
                        >
                      </div>
                      <hr style="border-color:rgba(0,0,0,.03);" />
                    </template>
                    <template v-if="!isTerminated" v-slot:firstCard>
                      <!-- new app -->
                      <v-card-app
                        v-if="atSelfTeam"
                        @click="onAppLogoClicked"
                        style="float:left;"
                        :withMenu="false"              
                        :appInfo="newAppCard"
                      ></v-card-app>
                    </template>
                  </my-apps-section>
                </v-col>
              </v-row>

              <v-row v-if="isTerminated">
                <div class="w-100 text-center">
                  <img class="termination-icon" alt="Vue logo" src="../../assets/fa6-solid_link-slash.png">
                  <p>This team has been disabled, please contact your account manager to resume the service</p>
                  <p>此團隊已經停用, 請聯繫你的客戶經理重啟服務</p>
                </div>
              </v-row>

              <v-row v-else>
                <v-col cols="12">
                  <!--
                  ********************
                  Other Team Apps
                  ********************
                   -->
                  <my-apps-section
                    :label="$t('myapps.otherApps')"
                    :appInfos="uiOtherTeamAppInfos"
                    :dragging="dragging"
                    :draggingSection="draggingSection"
                    :showTeamName="true"
                    @startDrag="startDragging('other')"
                    @endDrag="endDragging"
                    @change="(payload) => onDragged(payload, 'otherTeamApp')"
                    @click="onAppLogoClicked"
                    @click:menu="onAppMenuClicked"
                    @click:toggle="onToggleIcon"
                    section="other"
                  >
                  </my-apps-section>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        
      </div>
      <confirm-delete-with-text-dialog
        ref="confirmDeleteWithTextDialog"
      ></confirm-delete-with-text-dialog>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>
      <apps-summary-dialog
        v-if="showAppsSummaryDialog"
        v-model="showAppsSummaryDialog"
      ></apps-summary-dialog>
      <v-btn
        v-if="!isTerminated"
        fab
        color="primary"
        height="32"
        width="32"
        @click="updateLayoutStatus('archiveDrawerOpened', !archiveDrawerOpened)"
        style="position:absolute;top:5px;right:5px;"
      >
        <v-icon v-if="archiveDrawerOpened">mdi-chevron-right</v-icon>
        <v-icon v-else>mdi-folder-table</v-icon>
      </v-btn>
    </div>
    <!--
      Archives
    -->
    <transition name="slide-fade">
      <div
        v-if="archiveDrawerOpened"
        class="archive-collection fill-height d-flex flex-column grey lighten-5"
      >
        <div class="d-flex flex-row align-start fill-height">
          <div class="apps-group pa-2 fill-height d-flex flex-column flex-grow-0">
          
            <div class="flex-grow-1">
              <div style="height:0;min-height:100%;overflow-y:auto;">
                <v-list rounded class="transparent"
                  :class="{'app-card-dragging':dragging}"
                  style="max-height:100%;">
                  <v-list-item-group
                    color="primary"
                    v-model="selectedCategoryIndex"
                  >
                    <v-list-item
                      v-for="(categoryName, index) in appsCategoryNames"
                      :key="index"
                      class="category-item"
                    >
                      <category-name
                        :index="index"
                        :name="categoryName"
                        :archives="archives"></category-name>
                      
                      <div style="position:absolute;left:0;top:0;width:100%;height:100%;">
                        <draggable
                          v-model="dummy"
                          group="apps"
                          class="w-100 fill-height ma-0 pa-0"
                          ghostClass="ghost-class"
                          dragClass="dragging-on-name"
                          @change="val=>{onDroppedOnCollection(index, val)}"
                        >
                        </draggable>
                      </div>
                      <div
                        class="flex-row align-center pr-2 justify-end fill-height w-100 text-right pa-0 category-info-buttons"
                        style="position:absolute;top:0;left:0;">
                        <v-btn
                          fab
                          x-small
                          color="primary"
                          @click="editCategory(index)">
                          <v-icon>mdi-file-document-edit</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          x-small
                          color="error"
                          class="ml-1"
                          @click="deleteCategory(index)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
            <div class="flex-grow-0">
              <hr class="mt-2" 
                style="border: 2px solid rgba(0,0,0,.02);"/>
              <v-list class="py-0 border transparent" >
                <v-list-item class="py-0 px-1 collection-list-buttons justify-end align-center">
                  <v-switch 
                    :inputValue="alwaysShowAppCard"
                    @change="updateLayoutStatus('alwaysShowAppCard',!alwaysShowAppCard)"></v-switch>
                  <v-spacer></v-spacer>
                  <v-btn x-small fab color="primary" @click="newAppsCategory">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </div>
          </div>
          <div class="px-2 pb-2 apps-group-items fill-height d-flex flex-column flex-grow-1">
            <div style="height:0;min-height:100%;overflow-y:auto;">
              <my-apps-section
                v-if="selectedCategoryAppIdSet"
                :appInfos="selectedCategoryAppInfos"
                :isFavApp="false"
                :dragging="dragging"
                :draggingSection="draggingSection"
                :showTeamName="true"
                :withMenu="true"
                @startDrag="startDragging('group')"
                @endDrag="endDragging"
                @change="(payload) => onDragged(payload, 'archivedApp')"
                @click="onAppLogoClicked"
                @click:menu="onAppMenuClicked"
                @click:toggle="onToggleIcon"
                section="group"
              >
              </my-apps-section>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <app-info-dialog
      class="flex-grow-0"
      ref="appInfoDialog"
      v-model="showingAppsInfoDialog"
      v-if="showingAppsInfoDialog"
      @onCommand="onCommandHandler"
    ></app-info-dialog>

    <apps-category-info-dialog
      ref="appsCategoryInfoDialog"
    ></apps-category-info-dialog>

    <sendAppDialog
      v-if="showingSendAppDialog"
      v-model="showingSendAppDialog"
      @input="v=>showingSendAppDialog = v"
      ref="sendAppDialog"
      @onCommand="onCommandHandler"
    />
    <v-snackbar
      :color="snackbarColor"
      v-model="showingSnackbar"
      center
      top
      elevation="0"
      timeout="2000"
      ref="myAppSnackbar"
    >
      <div class="d-flex justify-center" style="font-size:14px"> {{snackbarMsg}} </div>
    </v-snackbar>
  </div>
</template>

<script>
import selectTeamHeader from './comps/SelectTeamHeader'
import myAppsSection from './comps/MyAppsSection'
import categoryName from './comps/CategoryName'

import vCardApp from './comps/VCardApp'
// import vCardApp2 from './comps/VCardApp2'
import appInfoDialog from './dialogs/AppInfoDialog'
import confirmDeleteWithTextDialog from '@/components/dialogs/ConfirmDialogWithTextfield'
import confirmDialog from '@/components/dialogs/ConfirmDialog'
import appsSummaryDialog from '@/components/dialogs/AppsSummaryDialog'
import draggable from 'vuedraggable'
import appsCategoryInfoDialog from './comps/AppsCategoryInfoDialog'
import sendAppDialog from './dialogs/SendAppDialog'

export default {
  name: 'myApps',
  components: {
    selectTeamHeader,
    myAppsSection,
    categoryName,

    vCardApp,
    // vCardApp2,
    appInfoDialog,
    confirmDeleteWithTextDialog,
    appsSummaryDialog,
    confirmDialog,
    draggable,
    appsCategoryInfoDialog,
    sendAppDialog
  },
  watch: {
    archives: function() {
      this.checkSelectedCategoryIndex()
    },
  },
  data() {
    return {
      dummy: [],
      dragging: false,
      draggingSection: null,
      // uiFavAppInfos: [],
      // uiUserCurrTeamAppInfos: [],
      showingAppsInfoDialog: false,
      showingSendAppDialog: false,
      singleSelect: false,
      selected: [],
      apps: [],
      newAppCard: {
        _id: '',
        title: this.$t('app.addApp'),
        logoType: 'icon',
        logoIcon: 'mdi-plus',
        logoMediaId: '',
        themeType: 'light',
        color: 'grey lighten-3',
        link: '/apps/0',
        favourite: false,
      },
      showAppsSummaryDialog: false,
      selectedCategoryIndex: -1,
      showingSnackbar: false,
      snackbarMsg: '',
      snackbarColor: ''
    }
  },

  computed: {
    alwaysShowAppCard () {
      return this.userAppsLayout && this.userAppsLayout.alwaysShowAppCard
    },
    archiveDrawerOpened () {
      return this.userAppsLayout && this.userAppsLayout.archiveDrawerOpened
    },
    selectedCategoryAppIdSet() {
      return this.selectedCategoryIndex >= 0 &&
        this.selectedCategoryIndex < this.archives.length
        ? this.archives[this.selectedCategoryIndex]
        : null
    },

    selectedCategoryAppIds() {
      return this.selectedCategoryAppIdSet
        ? this.selectedCategoryAppIdSet.orderedAppIds
        : []
    },

    selectedCategoryAppInfos() {
      return this.appendType(this.getAppInfos(this.selectedCategoryAppIds), 'archivedApp')
    },

    appsCategoryNames() {
      return this.archives.map((set) => set.label)
    },

    atSelfTeam() {
      return this.userTeamIds.includes(this.currTeamId)
    },

    isOwner() {
      const vm = this
      var result = false
      if (vm.user) {
        if (this.user.teamEmployeeIds && Object.keys(this.user.teamEmployeeIds).length > 0) {
          const currEmployeeId = this.user.teamEmployeeIds[this.currTeamId]
          const currEmployeeInfo = this.user.employees.find(
            (employee) => employee._id === currEmployeeId
          )
          result = currEmployeeInfo &&
            currEmployeeInfo.role &&
            currEmployeeInfo.role.type === 'ADMINISTRATOR'
        }
      }
      return result
    },

    user() {
      return this.$store.getters.user
    },

    appIds() {
      return this.apps.map((app) => app._id)
    },

    archives() {
      const vm = this
      var result =
        vm.preference &&
        vm.preference.appsLayout &&
        vm.preference.appsLayout.archives
          ? vm.preference.appsLayout.archives
          : []
      return result
    },

    uiFavAppIds() {
      const vm = this
      // console.log(
      //   'orderedFavAppIds: ' +
      //     vm.preference.appsLayout.orderedFavAppIds.join('\n')
      // )
      // console.log('uiFavAppIds :: vm.appIds: ' + vm.appIds.join('\n'))
      return vm.preference &&
        vm.preference.appsLayout &&
        vm.preference.appsLayout.orderedFavAppIds
        ? vm.preference.appsLayout.orderedFavAppIds.filter((id) =>
            vm.appIds.includes(id)
          )
        : []
    },

    uiFavAppInfos() {
      return this.appendType(this.getAppInfos(this.uiFavAppIds), 'favApp')
    },

    allArchiveAppIds() {
      return this.archives.reduce((idList, item) => {
        const appIds = item.orderedAppIds
        return appIds ? idList.concat(appIds) : idList
      }, [])
    },

    uiCurrTeamAppIds() {
      const vm = this
      // console.log('uiCurrTeamAppIds')
      const currTeamAppIds = this.currTeamAppInfos.map((info) => info._id)

      // ordered app ids: except those in fav and archived collection
      var result = this.userCurrTeamOrderedAppIds.filter((id) => {
        return (
          currTeamAppIds.includes(id) &&
          !this.uiFavAppIds.includes(id) &&
          (this.alwaysShowAppCard || !this.allArchiveAppIds.includes(id))
        )
      })
      // console.log(
      //   'computed(uiCurrTeamAppIds): filter fav & archived: result: ',
      //   result
      // )

      // append newly apps in current team
      const currTeamNewlyAppIds = currTeamAppIds.filter(
        (id) =>
          !this.userCurrTeamOrderedAppIds.includes(id) &&
          !this.uiFavAppIds.includes(id) &&
          !this.allArchiveAppIds.includes(id)
      )
      // console.log(
      //   'computed(uiCurrTeamAppIds): currTeamNewlyAppIds: ',
      //   currTeamNewlyAppIds
      // )

      var changed = false
      if (currTeamNewlyAppIds.length > 0) {
        result = result.concat(currTeamNewlyAppIds)
        changed = true
      }
      // console.log('computed(uiCurrTeamAppIds): append new: result: ', result)

      // reduce duplication
      result = result.filter((appId, index) => result.indexOf(appId) === index)

      // update if changed
      if (changed) {
        this.updateUserAppsLayoutByAppIds(this.uiFavAppIds, result)
      }
      // console.log('computed(uiCurrTeamAppIds): result: ', result)
      return result.filter((id) => vm.appIds.includes(id))
    },

    uiOtherTeamAppInfos() {
      const vm = this
      const apps = vm.apps.filter(
        appInfo => (
          appInfo.teamId !== this.currTeamId && // not current team           
          !this.uiFavAppIds.includes(appInfo._id) && // not in my-favourite
          (this.alwaysShowAppCard || !this.allArchiveAppIds.includes(appInfo._id))
        )
      )
      return vm.appendType(apps, 'otherTeamApp')
    },

    uiCurrTeamAppInfos() {
      return this.appendType(this.getAppInfos(this.uiCurrTeamAppIds), 'currTeamApp')
    },

    currTeamAppInfos() {
      const vm = this
      return this.apps.filter((item) => item && item.teamId === this.currTeamId)
    },

    userAppsLayout() {
      return this.preference ? this.preference.appsLayout : {}
    },

    // user's app ids for current team in preference
    // (not include all app ids)
    userCurrTeamOrderedAppIds() {
      const vm = this
      var result = []
      if (this.currTeamId && this.userAppsLayout) {
        const currTeamAppIdSet = this.userAppsLayout.allTeamsAppIdSets.find(
          (e) => e.teamId === this.currTeamId
        )
        if (currTeamAppIdSet) {
          result = currTeamAppIdSet.orderedAppIds
        }
      }
      return result
    },

    userTeamIds() {
      return this.user && this.user.teams ?
        this.user.teams.map((team) => team._id) : 
        []
    },

    allTeams () {
      return this.$store.getters.allTeamsIncludingTerminated;
    },

    isTerminated () {
      return this.currTeam && !!this.currTeam.terminated;
    },

    currTeam() {
      return this.allTeams.find(team => team._id === this.currTeamId);
    },

    currTeamId() {
      var result = ''
      if (this.user) {
        result = this.$store.getters.user.lastTeam
      }
      return result
    },
    preference() {
      const vm = this
      var result = null
      if (this.user && this.user.preference) {
        result = this.user.preference
      }
      return result
    },
  },

  created() {
    document.title = '[' + this.$t('general.dashboards') + ']'
  },

  mounted() {
    this.$store.dispatch('RESET_APPS')
    this.$store.dispatch('RESET_FORMS')
    this.loadApps()
    this.checkSelectedCategoryIndex()
    this.$sensors.track("plus_myapps_view");
  },

  methods: {
    updateLayoutStatus (property, value) {
      const keyValue = {}
      keyValue[property] = value
      this.$store.dispatch('UPDATE_USER_APPS_LAYOUT', keyValue)
    },

    // Archive collection functions
    newAppsCategory() {
      this.$refs.appsCategoryInfoDialog.open(null, this.onCategoryInfoUpdated)
    },

    editCategory (index) {
      const archive = this.archives[index]
      const categoryInfo = {
        index: index,
        label: archive.label
      }
      this.$refs.appsCategoryInfoDialog.open(categoryInfo, this.onCategoryInfoUpdated)
    },

    deleteCategory(index) {
      // console.log('deleteCategory')
      var updatedArchives = []
      updatedArchives.push({
        index: index,
        archive: null
      })
      this.updateUserAppsLayoutByAppIds(null, null, updatedArchives)
    },

    checkAndRemoveAppIdInArchives (appId) {
      const vm = this
      var result = {
        index: -1,
        archive: null
      }      
      for (let i = 0; i < vm.archives.length; i++) {
        const loopArchive = vm.archives[i]
        if (loopArchive && loopArchive.orderedAppIds) {
          const appIndex = loopArchive.orderedAppIds.findIndex(id => id === appId)
          if (appIndex >= 0) {
            result.index = i
            result.archive = loopArchive
            result.archive.orderedAppIds.splice(appIndex, 1)
            break
          }
        }
      }
      return result
    },

    onDroppedOnCollection(index, payload) {
      const vm = this
      // console.log('onDroppedOnCollection : payload: ', payload)
      if (payload.added) {
        const appInfo = payload.added.element
        const appId = appInfo._id
        const archive = this.archives[index]
        var updatedArchiveInfos = []
        if (archive) {
          if (archive.orderedAppIds) {
            if (!archive.orderedAppIds.includes(appId)) {
              // check if appId appear in another archcve, remov eit

              console.log('archive.orderedAppIds not include')
              const {archiveIndex, archiveWithAppId} = vm.checkAndRemoveAppIdInArchives(appId)
              if (archiveWithAppId) {
                updatedArchiveInfos.push({
                  index: archiveIndex,
                  archive: archiveWithAppId
                })
              }
              
              archive.orderedAppIds.push(appId)
              updatedArchiveInfos.push({
                index: index,
                archive: archive
              })
              
              this.updateUserAppsLayoutByAppIds(null, null, updatedArchiveInfos)
            }
          } else {
            // check if appId appear 
            const {archiveIndex, archiveWithAppId} = vm.checkAndRemoveAppIdInArchives(appId)
            if (archiveWithAppId) {
              updatedArchiveInfos.push({
                index: archiveIndex,
                archive: archiveWithAppId
              })
            }

            archive.orderedAppIds = [appInfo._id]
            updatedArchiveInfos.push({
              index: index,
              archive: archive
            })

            this.updateUserAppsLayoutByAppIds(null, null, updatedArchiveInfos)
          }
        }
      }
      // console.log('onDroppedCollection :: payload: ', payload)
    },

    checkSelectedCategoryIndex() {
      if (this.selectedCategoryIndex === -1) {
        if (this.archives.length > 0) {
          this.selectedCategoryIndex = 0
        }
      } else if (this.selectedCategoryIndex >= this.archives.length) {
        this.selectedCategoryIndex = this.archives.length - 1
      }
    },

    getAppInfos(givenAppIds) {
      const vm = this
      return givenAppIds.map(id => {
        return vm.apps.find(app => app._id === id)
      })
    },

    appendType (appInfos, appSectionType) {
      return appInfos.map(info => {
        return {
          ...info,
          type: appSectionType
        }
      })
    },

    onCategoryInfoUpdated(categoryInfo) {
      const vm = this
      // console.log('onCategoryInfoUpdated: categoryInfo: ', categoryInfo)
      // categoryInfo = {
      //    index: -1, // -1 means new category
      //    label: ''
      // ]
      if (categoryInfo !== null) {
        var archive = null
        // if new
        if (categoryInfo.index === -1) {
          archive = {
            label: categoryInfo.label,
            orderedAppIds: []
          }
        } else {
          archive = vm.archives[categoryInfo.index]
          archive.label = categoryInfo.label
        }
        const updatedArchives = [
          {
            index: categoryInfo.index,
            archive: archive
          }
        ]
        vm.updateUserAppsLayoutByAppIds(
          null,
          null,
          updatedArchives,
          () => {
            if (categoryInfo.index === -1) {
              vm.selectedCategoryIndex = vm.archives.length - 1
            }
          }
        )
      } else {
        // cancel is pressed
      }
    },
  
    appendAppToCurrTeam(app) {
      const vm = this
      this.apps.push(app)
      const teamIndex = this.userAppsLayout.allTeamsAppIdSets.findIndex(
        (e) => e.teamId === this.currTeamId
      )
      if (teamIndex >= 0) {
        this.userAppsLayout.allTeamsAppIdSets[teamIndex].orderedAppIds.push(
          app._id
        )
      }
    },

    isHidden(tag) {
      return this.$store.getters.hiddenItems.includes(tag)
    },

    moveToApps(index, theAppInfo) {
      const vm = this
      // added to app
      var appInfos = JSON.parse(JSON.stringify(this.uiCurrTeamAppInfos))
      appInfos.splice(index, 0, theAppInfo)

      // remove from fav
      var favAppInfos = JSON.parse(JSON.stringify(this.uiFavAppInfos))
      favAppInfos = favAppInfos.filter((info) => info._id !== theAppInfo._id)

      // console.log('moveToFav :: favAppInfos: ', favAppInfos)
      // console.log('moveToFav :: appInfos: ', appInfos)
      return {
        favInfos: favAppInfos,
        appInfos: appInfos,
      }
    },

    moveToFav(index, theAppInfo) {
      const vm = this
      // added to fav

      // console.log('moveToFav: this.uiFavAppInfos: ', this.uiFavAppInfos)
      var favAppInfos = JSON.parse(JSON.stringify(this.uiFavAppInfos))
      favAppInfos.splice(index, 0, theAppInfo)

      // remove from app
      // console.log(
      //   'moveToFav: this.uiCurrTeamAppInfos: ',
      //   this.uiCurrTeamAppInfos
      // )

      var appInfos = JSON.parse(JSON.stringify(this.uiCurrTeamAppInfos))
      appInfos = appInfos.filter((info) => info._id !== theAppInfo._id)
      return {
        favInfos: favAppInfos,
        appInfos: appInfos,
      }
    },
    moveInApps(oldIndex, newIndex) {
      const vm = this
      var appInfos = JSON.parse(JSON.stringify(this.uiCurrTeamAppInfos))
      const movedItem = appInfos.splice(oldIndex, 1)
      appInfos.splice(newIndex, 0, movedItem[0])
      return appInfos
    },

    moveInFav(oldIndex, newIndex) {
      const vm = this
      var favAppInfos = JSON.parse(JSON.stringify(this.uiFavAppInfos))
      const movedItem = favAppInfos.splice(oldIndex, 1)
      favAppInfos.splice(newIndex, 0, movedItem[0])
      return favAppInfos
    },
    startDragging(section){
      this.dragging = true
      this.draggingSection = section
    },
    endDragging(){
      this.dragging = false
      this.draggingSection = null
    },
    onDragged(payload, targetType) {
      const vm = this
      var movedItem = null

      var allInfos = {
        favAppInfos: null,
        currTeamAppInfos: null,
        archiveAppInfos: null,
      }
      if (payload.added) {
        const sourceType = payload.added.element.type
        const targetIndex = payload.added.newIndex
        const appInfo = payload.added.element

        if (targetType === 'currTeamApp' && sourceType === 'favApp') {
          if (appInfo.teamId !== vm.currTeamId) {
            alert('not curr team')
            return
          }
        }

        if (targetType === 'otherTeamApp' && sourceType === 'favApp') {
          if (appInfo.teamId === vm.currTeamId) {
            alert('it belongs to curr team')
            return
          }
        }

        switch (sourceType) {
          case 'favApp':
            allInfos.favAppInfos = JSON.parse(
              JSON.stringify(this.uiFavAppInfos)
            )
            allInfos.favAppInfos = allInfos.favAppInfos.filter(
              (info) => info._id !== appInfo._id
            )
            break

          case 'currTeamApp':
            allInfos.currTeamAppInfos = JSON.parse(
              JSON.stringify(vm.uiCurrTeamAppInfos)
            )
            // allInfos.currTeamAppInfos = allInfos.currTeamAppInfos.filter(
            //   (info) => info._id !== appInfo._id
            // )
            break

          case 'archivedApp':
            allInfos.archiveAppInfos = JSON.parse(
              JSON.stringify(this.selectedCategoryAppInfos)
            )
            allInfos.archiveAppInfos = allInfos.archiveAppInfos.filter(
              (info) => info._id !== appInfo._id
            )
            break
        }
        // console.log('added: targetType = ' + targetType)
        switch (targetType) {
          case 'favApp':
            allInfos.favAppInfos = JSON.parse(
              JSON.stringify(this.uiFavAppInfos)
            )
            allInfos.favAppInfos.splice(targetIndex, 0, appInfo)
            break

          case 'currTeamApp':
            // console.log('moved: vm.uiCurrTeamAppInfos: ', vm.uiCurrTeamAppInfos)
            allInfos.currTeamAppInfos = JSON.parse(
              JSON.stringify(vm.uiCurrTeamAppInfos)
            )
            // console.log(
            //   'moved: allInfos.currTeamAppInfos: ',
            //   allInfos.currTeamAppInfos
            // )
            // allInfos.currTeamAppInfos.splice(targetIndex, 0, appInfo)
            break

          case 'archivedApp':
            allInfos.archiveAppInfos = JSON.parse(
              JSON.stringify(this.selectedCategoryAppInfos)
            )
            if (!allInfos.archiveAppInfos.find(info => info._id === appInfo._id))
              allInfos.archiveAppInfos.splice(targetIndex, 0, appInfo)
            break
        }
        vm.updateAppsLayout(allInfos)
      } else if (payload.moved) {
        const oldIndex = payload.moved.oldIndex
        const newIndex = payload.moved.newIndex
        switch (targetType) {
          case 'favApp':
            allInfos.favAppInfos = JSON.parse(
              JSON.stringify(this.uiFavAppInfos)
            )
            // console.log(allInfos.favAppInfos.map((info) => info._id).join(','))
            movedItem = allInfos.favAppInfos.splice(oldIndex, 1)
            allInfos.favAppInfos.splice(newIndex, 0, movedItem[0])
            // console.log(allInfos.favAppInfos.map((info) => info._id).join(','))
            break

          case 'currTeamApp':
            allInfos.currTeamAppInfos = JSON.parse(
              JSON.stringify(vm.uiCurrTeamAppInfos)
            )
            movedItem = allInfos.currTeamAppInfos.splice(oldIndex, 1)
            allInfos.currTeamAppInfos.splice(newIndex, 0, movedItem[0])
            break

          case 'archivedApp':
            allInfos.archiveAppInfos = JSON.parse(
              JSON.stringify(this.selectedCategoryAppInfos)
            )
            // console.log('0: ' + allInfos.archiveAppInfos.map(info => info._id))
            movedItem = allInfos.archiveAppInfos.splice(oldIndex, 1)
            allInfos.archiveAppInfos.splice(newIndex, 0, movedItem[0])
            // console.log('9: ' + allInfos.archiveAppInfos.map(info => info._id))
            break
        }
        vm.updateAppsLayout(allInfos)
      }
    },

    updateAppsLayout(allInfos) {
      const vm = this
      // console.log('allInfos: ', allInfos)
      // if (allInfos.currTeamAppInfos) {
      //   console.log('moved: 0-th: ' + allInfos.currTeamAppInfos[0].title + ' (' + allInfos.currTeamAppInfos[0]._id + ')')
      //   console.log('moved: 1-th: ' + allInfos.currTeamAppInfos[1].title + ' (' + allInfos.currTeamAppInfos[1]._id + ')')
      //   console.log('moved: 2-th: ' + allInfos.currTeamAppInfos[2].title + ' (' + allInfos.currTeamAppInfos[2]._id + ')')
      //   console.log('moved: 3-th: ' + allInfos.currTeamAppInfos[3].title + ' (' + allInfos.currTeamAppInfos[3]._id + ')')
      // } else {
      //   console.log('moved: 0-th: ' + allInfos.archiveAppInfos[0].title + ' (' + allInfos.archiveAppInfos[0]._id + ')')
      //   console.log('moved: 1-th: ' + allInfos.archiveAppInfos[1].title + ' (' + allInfos.archiveAppInfos[1]._id + ')')
      //   console.log('moved: 2-th: ' + allInfos.archiveAppInfos[2].title + ' (' + allInfos.archiveAppInfos[2]._id + ')')
      // }

// console.log('updateAppsLayout')
      // allIfnos = {
      //    favAppInfos: null,
      //    currTeamAppInfos: null,
      //    archiveAppInfos: null
      // }
      //
      const favAppIds = allInfos.favAppInfos
        ? allInfos.favAppInfos.map((info) => info._id)
        : vm.uiFavAppIds

      const userCurrTeamAppIds = allInfos.currTeamAppInfos // userCurrTeamAppInfos
        ? allInfos.currTeamAppInfos.map((info) => info._id)
        : vm.userCurrTeamOrderedAppIds
        // : vm.uiCurrTeamAppIds

      var selectedCategoryAppIdSet = null
      if (vm.selectedCategoryIndex >= 0) {
        const selectedCategoryOrderedAppIds = allInfos.archiveAppInfos
          ? allInfos.archiveAppInfos.map((info) => info._id)
          : vm.archives[vm.selectedCategoryIndex].orderedAppIds
        selectedCategoryAppIdSet = {
          label: vm.selectedCategoryAppIdSet.label,
          orderedAppIds: selectedCategoryOrderedAppIds,
        }
      }

      //console.log('updateAppsLayout :: vm.selectedCategoryIndex = ' + vm.selectedCategoryIndex)
      const updatedArchives = [
        {
          index: vm.selectedCategoryIndex,
          archive: selectedCategoryAppIdSet
        }
      ]

      vm.updateUserAppsLayoutByAppIds(
        favAppIds,
        userCurrTeamAppIds,
        updatedArchives
      )
    },

    onOtherAppChange(payload) {
      // console.log('onOtherAppChange')
    },

    onFavChange(payload) {
      const vm = this
      if (payload.added) {
        // console.log(
        //   'onFavChange :: added (' +
        //     payload.added.element.type +
        //     ') : payload: ',
        //   payload
        // )
        const { favInfos, appInfos } = this.moveToFav(
          payload.added.newIndex,
          payload.added.element
        )

        this.updateUserAppsLayout(favInfos, appInfos)
      } else if (payload.moved) {
        const favInfos = this.moveInFav(
          payload.moved.oldIndex,
          payload.moved.newIndex
        )
        this.updateUserAppsLayout(favInfos, this.uiCurrTeamAppInfos)
      }
    },

    onToggleIcon(payload) {
      this.toggleIcon(payload)
    },

    onAppLogoClicked(payload) {
      const vm = this
      switch (payload.command) {
        case 'openOrNewApp':
          if (payload.appInfo._id === '') {
            this.showingAppsInfoDialog = true
            this.$nextTick(()=>{
              this.$refs.appInfoDialog.init()
            })
          } else {
            this.openApp(payload.appInfo)
          }
          break
      }
    },

    onAppMenuClicked(payload) {
      const vm = this
      switch (payload.command) {
        case 'showAppsInfoDialog':
          this.showingAppsInfoDialog = true
          this.$nextTick(()=>{
            this.$refs.appInfoDialog.init(payload.appInfo)
          })
          break
        case 'duplicateApp':
          this.duplicateApp(payload.appInfo)
          break
        case 'deleteApp':
          this.$refs.confirmDeleteWithTextDialog.open({
            color: 'red darken-2',
            requiredWord: 'delete',
            callback: () => {
              this.deleteApp(payload.appInfo)
            },
          })
          break
        case 'showSendAppDialog':
          this.showingSendAppDialog = true
          this.$nextTick(()=>{
            const sendAppDialogRef = this.$refs.sendAppDialog
            sendAppDialogRef.appInfo = payload.appInfo
            sendAppDialogRef.loadShareAppSetting()
          })
          break
      }
    },

    onUpdate(arg) {
      const vm = this
      this.updateUserAppsLayout(this.uiFavAppInfos, this.uiCurrTeamAppInfos)
    },

    onRemove(arg) {
      const vm = this
      this.updateUserAppsLayout(this.uiFavAppInfos, this.uiCurrTeamAppInfos)
    },

    updateUserAppsLayout(favAppInfos, uiCurrTeamAppInfos) {
      const vm = this

      const favAppIds = favAppInfos
        ? favAppInfos.map((info) => info._id)
        : this.uiFavAppInfos.map((info) => info._id)

      const userCurrTeamAppIds = uiCurrTeamAppInfos
        ? uiCurrTeamAppInfos.map((info) => info._id)
        : this.uiCurrTeamAppInfos.map((info) => info._id)

      this.updateUserAppsLayoutByAppIds(favAppIds, userCurrTeamAppIds)
    },

    updateArchives (archiveInfos) {
      const vm = this
      // console.log('updateArchives :: archiveInfos: ', archiveInfos)
      var archives = JSON.parse(JSON.stringify(vm.archives))
      if (archiveInfos) {
        for (let i = 0; i < archiveInfos.length; i++) {
          const loopArchiveInfo = archiveInfos[i]
          // console.log('updateArchives :: i = ' + i + ': loopArchiveInfo: ', loopArchiveInfo)
          if (loopArchiveInfo.index === -1) {
            archives.push({
              label: loopArchiveInfo.archive.label,
              orderedAppIds: []
            })
          } else {
            if (loopArchiveInfo.archive) {
              archives[loopArchiveInfo.index].label = loopArchiveInfo.archive.label
              archives[loopArchiveInfo.index].orderedAppIds = loopArchiveInfo.archive.orderedAppIds
            } else {
              archives.splice(loopArchiveInfo.index, 1)
            }
          }
        }
      }
      return archives
    },

    updateUserAppsLayoutByAppIds(
      favAppIds,
      userCurrTeamAppIds,
      updatedArchives,
      // archiveIndex = -1,
      // archive = null,
      callback
    ) {
      const vm = this

      // console.log('updateUserAppsLayoutByAppIds: updatedArchives: ', updatedArchives)
      if (favAppIds === null) {
        favAppIds = this.uiFavAppInfos.map((info) => info._id)
      }

      if (userCurrTeamAppIds === null) {
        // userCurrTeamAppIds = this.uiCurrTeamAppInfos.map((info) => info._id)
        userCurrTeamAppIds = this.userCurrTeamOrderedAppIds
      }
      // userCurrTeamAppIds = this.userCurrTeamOrderedAppIds

      const allTeamsAppIdSets = vm.getUpdatedAllTeamsAppIdSets(
        userCurrTeamAppIds
      )
      const archives = vm.updateArchives(updatedArchives)
      // const archives = vm.getUpdatedArchives(
      //   archiveIndex,
      //   archive
      // )
  
      vm.$store
        .dispatch('UPDATE_USER_APPS_LAYOUT', {
          orderedFavAppIds: favAppIds,
          allTeamsAppIdSets: allTeamsAppIdSets,
          archives: archives,
        })
        .then(() => {
          if (typeof callback === 'function') {
            // console.log('updateUserAppsLayoutByAppIds.callback')
            callback(favAppIds, allTeamsAppIdSets, archives)
          }
        })
    },

    getUpdatedArchives(archiveIndex, archive) {
      const vm = this

      var result = []
      if (vm.userAppsLayout && vm.userAppsLayout.archives) {
        result = JSON.parse(JSON.stringify(vm.userAppsLayout.archives))
      }

      // console.log('result: ', result)
      if (archive) {
        if (archiveIndex === -1) {
          // new category
          result.push({
            label: archive.label,
            orderedAppIds: archive.orderedAppIds,
          })
        } else if (archiveIndex < result.length) {
          // update category
          result[archiveIndex].label = archive.label
          result[archiveIndex].orderedAppIds = archive.orderedAppIds
        }
      } else {
        if (archiveIndex >= 0) {
          result.splice(archiveIndex, 1)
        }
      }

      return result
    },

    getUpdatedAllTeamsAppIdSets(userCurrTeamAppIds) {
      const vm = this
      var result = JSON.parse(
        JSON.stringify(this.userAppsLayout.allTeamsAppIdSets)
      )
      const teamIndex = this.userAppsLayout.allTeamsAppIdSets.findIndex(
        (e) => e.teamId === this.currTeamId
      )
      if (teamIndex >= 0) {
        result[teamIndex]['orderedAppIds'] = userCurrTeamAppIds
      } else {
        result.push({
          teamId: this.currTeamId,
          orderedAppIds: userCurrTeamAppIds,
        })
      }
      return result
    },

    loadApps() {

      if (this.user) {
        this.$store.dispatch('FETCH_APPS').then((response) => {
          this.apps = response
        })
      }
    },
    onCommandHandler(payload) {
      const vm = this
      switch (payload.command) {
        case 'saveAppsInfo':
          this.saveAppsInfo(payload.appInfo)
          break
        case 'sharedSuccess':
          this.snackbarColor = 'primary'
          this.snackbarMsg = 'Shared Successfully'
          const myAppSnackbarRef = this.$refs.myAppSnackbar
          const myAppSnackbarEl = myAppSnackbarRef.$el
          const appEl = document.getElementById('app')
          appEl.appendChild(myAppSnackbarEl)
          this.showingSnackbar = true
        break
      }
    },

    async openApp(appInfo) {
      if (this.currTeamId !== appInfo.teamId) { //switch team
        const dialogResult = await this.$refs.confirmDialog
          .open(
            this.$t('general.confirmation'),
            this.$t('messages.theAppInAnotherTeamAndMoveToIt')
            
          );
          if (dialogResult){
            await  this.$store.dispatch("UPDATE_TEAM", appInfo.teamId)
          } else return;
      } else {
        this.$sensors.setProfile({
          company_id:this.$store.getters.activeTeamCode,
          company_name: this.$store.getters.activeTeamName
        });
      }
      this.$sensors.track("plus_myapps_click", {
        btn_name:appInfo.title
      });
      this.$router.push('/apps/' + appInfo._id);
    },
    duplicateApp(appInfo) {
      this.$refs.confirmDialog
        .open(this.$t('general.copy'), this.$t('messages.copyMessage'))
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch('DUPLICATE_APP', {
                appId: appInfo._id,
                appName: appInfo.title,
              })
              .then((response) => {
                if (response)
                this.appendAppToCurrTeam(response)
                // this.uiUserCurrTeamAppInfos.push(response);
              })
          }
        })
    },

    deleteApp(appInfo) {
      const data = {
        appsId: appInfo._id,
      }
      const appsTitle = appInfo.title
      this.$store.dispatch('DELETE_APPS', data).then((res) => {

        if (res.deleted){
        this.$toast.success(this.$t('messages.appDeleted', {title:appsTitle}), {
          position: 'top-right',
          dismissible: true,
          pauseOnHover: true,
        })
        let index = this.apps.findIndex(
          (app) => app._id === appInfo._id
        )

        if (index > -1) {
          this.apps.splice(index, 1)
        }
        }

      })
    },

    getArchiveInfoIncludingAppId (appId) {
      const vm = this
      // console.log('getArchiveInfoIncludingAppId: appId = ' + appId)
      var result = {
        archiveIndex: -1,
        archive: null
      }
      for (let i = 0; i < vm.archives.length; i++) {
        const archive = vm.archives[i]
        // console.log('i=' + i + ': archive: ', archive)
        if (archive.orderedAppIds && archive.orderedAppIds.includes(appId)) {
          result.archiveIndex = i
          result.archive = archive
          break
        }
      }
      // console.log('getArchiveInfoIncludingAppId: result: ', result)
      return result
    },

    toggleIcon(payload) {
      const vm = this
      const appId = payload.appId

      // console.log('toggleIcon: payload: ', payload)
      if (payload.isArchivedApp) {
        var {archiveIndex, archive} = vm.getArchiveInfoIncludingAppId(appId)
        const appIndex = archive.orderedAppIds.findIndex( id => id === appId)
        if (appIndex >= 0) {
          archive.orderedAppIds.splice(appIndex, 1)
          const updatedArchives = [
            {
              index: archiveIndex,
              archive: archive
            }
          ]
          this.updateUserAppsLayoutByAppIds(null, null, updatedArchives)
        }
      } else {
        const isFavApp = payload.isFavApp
        // console.log('toggleIcon  :: appId = ' + appId)
        // const isFavApp = this.uiFavAppIds.includes(appId)

        // console.log('toggleIcon :: appId = ' + appId + ': isFavApp = ' + (isFavApp ? 'yes' : 'no'))
        var uiFavAppIds = JSON.parse(JSON.stringify(this.uiFavAppIds))
        var userCurrTeamAppIds = JSON.parse(JSON.stringify(this.uiCurrTeamAppIds))

        if (isFavApp) {
          const index = uiFavAppIds.indexOf(appId)
          uiFavAppIds.splice(index, 1)
          userCurrTeamAppIds.push(appId)
        } else {
          const index = userCurrTeamAppIds.indexOf(appId)
          userCurrTeamAppIds.splice(index, 1)
          uiFavAppIds.push(appId)
        }
        this.updateUserAppsLayoutByAppIds(uiFavAppIds, userCurrTeamAppIds)
      }

    },
    saveAppsInfo(appInfo) {
      const vm = this
      if (appInfo._id === '') {
        this.createApp(appInfo)
      } else {
        this.updateApp(appInfo)
      }
    },
    updateApp(appInfo) {
      const vm = this
      const putData = {
        urlCommand: '/apps/' + appInfo._id,
        data: {
          logoType: appInfo.logoType,
          logoIcon: appInfo.logoIcon,
          logoMediaId: appInfo.logoMediaId,
          logoMediaImageUrl: appInfo.logoMediaImageUrl,
          textTheme: appInfo.textTheme,
          color: appInfo.color,
          title: appInfo.title,
          favourite: appInfo.favourite,
        },
      }
      // console.log('MyApps.updateApp :: putData: ', putData)
      this.$store.dispatch('AUTH_PUT', putData).then((res) => {
        // console.log('MyApps.updateApp :: putData: res: ', res)
        if (res.result) {
          // console.log('auth_put.then appInfo: ', appInfo)
          const app = this.apps.find((app) => app._id === appInfo._id)
          // console.log('auth_put.then app: ', app)

          if (app) {
            // console.log('MyApps.updateApp updated')
            app.logoType = appInfo.logoType
            app.logoIcon = appInfo.logoIcon
            app.logoMediaId = appInfo.logoMediaId
            app.logoMediaImageUrl = appInfo.logoMediaImageUrl
            app.textTheme = appInfo.textTheme
            app.color = appInfo.color
            app.title = appInfo.title
            app.favourite = appInfo.favourite
          }
        }
      })
    },

    createApp(appInfo) {
      const postData = {
        urlCommand: '/apps',
        data: {
          logoType: appInfo.logoType,
          logoIcon: appInfo.logoIcon,
          logoMediaId: appInfo.logoMediaId,
          logoMediaImageUrl: appInfo.logoMediaImageUrl,
          textTheme: appInfo.textTheme,
          color: appInfo.color,
          title: appInfo.title,
          favourite: appInfo.favourite,
        },
      }
      this.$store.dispatch('AUTH_POST', postData).then((response) => {
        this.apps.push(response)
        this.$sensors.track("plus_myapps_add")
      })
    },
  },
}
</script>

<style>


.my-apps {
  z-index: 4;
}

.draggable > span {
  display: block;
  min-height: 50px;
}


.apps-main {
  flex-basis: 0;
}
.archive-collection {
  flex-basis: 0;
}
.apps-group {
  min-width: 200px;
  /* background-color: rgba(0,0,255,.05); */
}
.apps-group-items {
  background-color: rgba(200,200,255,.1);
}
.archive-collection {
  min-width: 50%;
  /* width: 50%; */
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: min-width 1s;
}
.slide-fade-enter-to,
.slide-fade-leave {
  min-width: 50%;
}
.slide-fade-enter,
.slide-fade-leave-to {
  min-width: 0;
}
.category-item .category-info-buttons {
  display: none;
}
.category-item:hover .category-info-buttons {
  display: flex;
}

.app-card-dragging .ghost-class
 {
  width: 100%;
  height: 100%;
  background-color: rgba(	57,171,167,.5) !important;
  color: white;
  left: 0;
  top: 0;
  border-radius: 32px;
  margin-left: -2px !important;
  margin-top: -2px !important;
}

.termination-icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
</style>
