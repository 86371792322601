<template>
<div class="filter-setup flex-grow-1 d-flex flex-column pa-2 fill-height">
    <div class="rounded blue darken-3 white--text px-3 py-1"><h4>{{ $t('view.filter') }}</h4></div>
    <div class="flex-grow-1 d-flex flex-column" style="position:relative;">
      <div class="px-1 my-1 d-flex flex-row align-center justify-space-between">
        <field-selection-button
            ref="fieldSelectionButton"
            :fieldInfos="fieldInfos"
            @click="addFilter"></field-selection-button>
        <!--<field-selection-button-->
            <!--ref="fieldSelectionButton"-->
            <!--:fields="fields"-->
            <!--@onCommand="onCommandHandler"></field-selection-button>-->

        <div v-if="filters.length > 0">
           <input-radio-toggle :options="andOrOptions"
                               v-model="filterAndOr"/>
        </div>
      </div>
      <div ref="filterListContainer" class="flex-grow-1" style="height:0;;min-height:100px;overflow-y:auto;">
        <v-list ref="filterList">
          <filter-widget
              :ref="'filter_'+index"
              v-for="(filter, index) in filters"
              :key="index"
              :andOrOptionLabel="getAndOrOptionLabel(filterAndOr)"
              :fields="fields"
              :filterIndex="index"
              :filter="filter"
              :form="form"
              :fieldInfos="fieldInfos"
              @input="val=>updateFilter(index,val)"
              @onCommand="onCommandHandler"></filter-widget>
          <!--:filter="filter"-->
        </v-list>
      </div>
    </div>
  </div>  
</template>

<script>
import fieldSelectionButton from '@/components/buttons/FieldSelectionButton'
import inputRadioToggle from '@/components/form/controls/InputRadioToggle'
import filterWidget from '@/components/filterWidgets/FilterWidget'

export default {
  name: 'filterSetup',
  components: {
    fieldSelectionButton,
    inputRadioToggle,
    filterWidget
  },
  data () {
    return {
      andOrOptions: [
        {labelTag: 'general.and', value: 'and'},
        {labelTag: 'general.or', value: 'or'}     
      ]
    }
  },
  model: {
    prop: 'filterConfigs',
    event: 'input'
  },
  props: {
    filterConfigs: Object,
    fieldInfos: {
      type: Array,
      default () {
        return []
      }
    },
    errorCount: {
      type: Number,
      default: 0
    },
    form: Object
  },
  computed: {
    fields () {
      const vm = this
      var result = []
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopInfo = vm.fieldInfos[i]
        const filterType = vm.getFilterTypeByFieldInfo(loopInfo)
        result.push({
          id: loopInfo.fieldId,
          label: loopInfo.label,
          filterType: filterType,
          fieldInfo: loopInfo
        })
      }
      return result
    },

    filters: {
      get () {
        return this.filterConfigs && this.filterConfigs['filters'] ?
          this.filterConfigs['filters'] :
          []
        // const vm = this
        // var result = []
        // if (vm.filterConfigs) {
        //   return vm.filterConfigs.filterstabData && vm.tabData[vm.tabKey]) {
        //   result = vm.tabData[vm.tabKey]['filterConfigs'].filters
        // }
        // return result
      },
      set (val) {
        const vm = this      
        vm.filterConfigs['filters'] = val
      }
    },

    filterAndOr: {
      get () {
        return this.filterConfigs ? this.filterConfigs.filterAndOr :
         'and'
        // const vm = this
        // var result = 'and'
        // if (vm.tabData && vm.tabData[vm.tabKey]) {
        //   result = vm.tabData[vm.tabKey]['filterConfigs'].filterAndOr
        // }
        // return result
      },
      set (val) {
        const vm = this
        vm.filterConfigs['filterAndOr'] = val
      }
    },

    allWidgets () {
      return this.$store.getters.allWidgets
    },

    filterInfos () {
      return this.$store.getters.filterInfos
    },

  },
  methods: {
    updateFilter (index, val) {
      const vm = this
      vm.filters[index] = val
    },
    addFilter (fieldInfo) {
      const vm = this
      const fieldId = fieldInfo.fieldId
      const relation = vm.getRelationByFieldId(fieldId)
      vm.filters.push({
        fieldId: fieldId,
        relation: relation,
        filterValue1: null,
        filterValue2: null
      })
    },

    getFilterTypeByFieldInfo (fieldInfo) {
      const vm = this
      var result = ''
      const widget = vm.allWidgets.find(item => item._id === fieldInfo.widgetId)
      if (widget) {
        result = widget.filterType
      } else {
        console.log('*** widget of id: ' + fieldInfo.widgetId + ' (label: ' + fieldInfo.label + ') not found.')
      }

      return result
    },

    getRelationByFieldId (fieldId) {
      const vm = this
      const fieldInfo = vm.fieldInfos.find(info => info.fieldId === fieldId)
      const filterType = vm.getFilterTypeByFieldInfo(fieldInfo)
      const filterInfo = vm.filterInfos[filterType]



      var relation = ''
      if (filterInfo && filterInfo.options && filterInfo.options.length > 0) {
        relation = filterInfo.options[0]
      }
      return relation
    },

    getAndOrOptionLabel (andOr) {
      const vm = this
      var result = 'and'
      const andOrOption = vm.andOrOptions.find(item => item.value === andOr)
      if (andOrOption) {
        result = vm.$t(andOrOption.labelTag)
      }
      return result
    },

    deleteAllFilters () {
      this.filterConfigs = JSON.parse(JSON.stringify(this.filterConfigs))
      this.fillterConfigs.filters = []
      this.$emit('input', this.filterConfigs)
    },

    deleteFilter (index) {
      this.filterConfigs = JSON.parse(JSON.stringify(this.filterConfigs))
      this.filterConfigs.filters.splice(index, 1)
      this.$emit('input', this.filterConfigs)
    },

    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'deleteAllFilters':
          vm.deleteAllFilters()
          break
        case 'deleteFilter':
          vm.deleteFilter(payload.filterIndex)
          break
        default:
          vm.$emit('onCommand', payload)
      }
    },
  }
}
</script>