export default {
    selectedFormFieldId: '',
    formChildTableInfos: [],
  
    formFieldInfos: [],
    formUIElementInfos: [],
    formLayout: null,
    formInfo: {
      titleFieldInfoId: ''
    },
    formOptions: {},
  
    dataSources: [], 
  
    //isEditingChildTableField: false,
    editingChildTableInfoId: '',
    editingChildTableFieldInfoId: ''  
}