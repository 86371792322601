const mixin = {
  computed: {
    setting() {
      return this.$store.state.workflowTree.setting;
    },
    // worksheet() {
    //   return this.$store.state.workflowTree.setting.workseet;
    // },
    appId() {
      return this.$route.params.id;
    }
  },
}

export default mixin
