var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedChart!='TABLE')?_c('div',[_c('div',{staticClass:"pt-3"},[_c('span',{class:{'error--text': _vm.setupIsUpdated.arrX}},[_vm._v(" "+_vm._s(_vm.$t('chart.dimensionXAxis'))+" ")]),_vm._l((_vm.arrX),function(field,index){return _c('chart-default-field-row',{key:index,attrs:{"field":field},on:{"remove":function($event){return _vm.$emit('onCommandHandler',{
                    command: 'removeX',
                    value: index
                })}}})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataSourceX),expression:"showDataSourceX"}],staticClass:"droppable py-1 px-3 mt-1 rounded",staticStyle:{"border":"#aaaa dashed 1px"},attrs:{"id":"dataSourceX"}},[_c('span',{staticStyle:{"color":"#aaaa"}},[_vm._v(_vm._s(_vm.$t('chart.dragToHere')))])])],2),_c('div',{staticClass:"pt-3"},[_c('div',{class:{'error--text': _vm.setupIsUpdated.arrY}},[_vm._v(_vm._s(_vm.$t('chart.dimensionYAxis')))]),_vm._l((_vm.arrY),function(field,index){return _c('chart-default-field-row',{key:index,attrs:{"field":field},on:{"remove":function($event){return _vm.$emit('onCommandHandler',{
                    command: 'removeY',
                    value: index
                })}}})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataSourceY),expression:"showDataSourceY"}],staticClass:"droppable py-1 px-3 mt-1 rounded",staticStyle:{"border":"#aaaa dashed 1px"},attrs:{"id":"dataSourceY"}},[_c('span',{staticStyle:{"color":"#aaaa"}},[_vm._v(_vm._s(_vm.$t('chart.dragToHere')))])]),((_vm.arrY.length == 1 && _vm.selectedChart!='PIE' && _vm.selectedChart!='RADAR' && _vm.selectedChart!='FUNNEL'))?_c('div',{staticClass:"mt-3",class:{'error--text': _vm.setupIsUpdated.arrG}},[_vm._v(" "+_vm._s(_vm.$t('chart.group'))+" ")]):_vm._e(),_vm._l((_vm.arrG),function(field,index){return _c('chart-default-field-row',{key:index,attrs:{"field":field},on:{"remove":function($event){return _vm.$emit('onCommandHandler',{
                    command: 'removeG',
                    value: index
                })}}})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataSourceG),expression:"showDataSourceG"}],staticClass:"py-1 px-3 mt-1 rounded droppable",staticStyle:{"border":"#aaaa dashed 1px"},attrs:{"id":"dataSourceG"}},[_c('span',{staticStyle:{"color":"#aaaa"}},[_vm._v(_vm._s(_vm.$t('chart.dragToHere')))])])],2)]):_c('div',{staticClass:"pt-3"},[_c('span',{class:{'error--text': _vm.setupIsUpdated.arrX}},[_vm._v(_vm._s(_vm.$t('chart.dimensionRow')))]),_vm._l((_vm.arrX),function(field,index){return _c('chart-default-field-row',{key:index,attrs:{"field":field},on:{"remove":function($event){return _vm.$emit('onCommandHandler',{
                command: 'removeX',
                value: index
            })}},scopedSlots:_vm._u([(['date', 'createdAt', 'updatedAt'].includes(field.type))?{key:"default",fn:function(){return [_c('chart-grouping-date-menu',{attrs:{"field":field},scopedSlots:_vm._u([{key:"dateRow",fn:function(ref){
            var on = ref.on;
            var opening = ref.opening;
return [_c('div',_vm._g({staticClass:"d-flex py-1 px-3 align-center mx-0 mt-1",staticStyle:{"cursor":"pointer"}},on),[_c('v-icon',{style:(opening?'transform: rotate(0deg)':'transform: rotate(180deg)'),attrs:{"dark":"","x-small":""}},[_vm._v("mdi-triangle")]),(_vm.localeTypes.includes(field.type))?_c('span',[_vm._v(_vm._s(_vm.$t('chart.'+field.type)))]):_c('span',[_vm._v(_vm._s(field.label))]),(field.dateGroupingOpt)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(("(" + (field.dateGroupingOpt) + ")")))]):_vm._e(),_c('v-icon',{staticClass:"ml-auto",attrs:{"color":"white","size":"18"},on:{"click":function($event){return _vm.$emit('onCommandHandler',{
                                    command: 'removeX',
                                    value: index
                                })}}},[_vm._v(" mdi-close ")])],1)]}}],null,true)})]},proxy:true}:null],null,true)})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataSourceTableX),expression:"showDataSourceTableX"}],staticClass:"droppable py-1 px-3 mt-1 rounded",staticStyle:{"border":"#aaaa dashed 1px"},attrs:{"id":"dataSourceTableX"}},[_c('span',{staticStyle:{"color":"#aaaa"}},[_vm._v(_vm._s(_vm.$t('chart.dragToHere')))])]),_c('div',{staticClass:"pt-3",class:{'error--text': _vm.setupIsUpdated.arrY}},[_vm._v(_vm._s(_vm.$t('chart.dimensionColumn')))]),_vm._l((_vm.arrY),function(field,index){return _c('chart-default-field-row',{key:index,attrs:{"field":field},on:{"remove":function($event){return _vm.$emit('onCommandHandler',{
                command: 'removeY',
                value: index
            })}},scopedSlots:_vm._u([(['date', 'createdAt', 'updatedAt'].includes(field.type))?{key:"default",fn:function(){return [_c('chart-grouping-date-menu',{attrs:{"field":field},scopedSlots:_vm._u([{key:"dateRow",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex py-1 px-3 align-center mx-0 mt-1"},on),[_c('v-icon',{staticStyle:{"transform":"rotate(90deg)"},attrs:{"dark":"","x-small":""}},[_vm._v("mdi-triangle")]),(_vm.localeTypes.includes(field.type))?_c('span',[_vm._v(_vm._s(_vm.$t('chart.'+field.type)))]):_c('span',[_vm._v(_vm._s(field.label))]),_c('span',[_vm._v(_vm._s(field.dateGroupingOpt?(" (" + (field.dateGroupingOpt) + ")"):''))]),_c('v-icon',{staticClass:"ml-auto",attrs:{"color":"white","size":"18"},on:{"click":function($event){return _vm.$emit('onCommandHandler',{
                                    command: 'removeY',
                                    value: index
                                })}}},[_vm._v(" mdi-close ")])],1)]}}],null,true)})]},proxy:true}:null],null,true)})}),_c('div',{staticClass:"py-1 px-3 mt-1 rounded droppable",staticStyle:{"border":"#aaaa dashed 1px"},attrs:{"id":"dataSourceTableY"}},[_c('span',{staticStyle:{"color":"#aaaa"}},[_vm._v(_vm._s(_vm.$t('chart.dragToHere')))])]),_c('div',{staticClass:"mt-3",class:{'error--text': _vm.setupIsUpdated.arrG}},[_vm._v(_vm._s(_vm.$t('chart.numeric')))]),_vm._l((_vm.arrG),function(field,index){return _c('chart-default-field-row',{key:index,attrs:{"field":field},on:{"remove":function($event){return _vm.$emit('onCommandHandler',{
                command: 'removeG',
                value: index
            })}}})}),_c('div',{staticClass:"py-1 px-3 mt-1 rounded droppable",staticStyle:{"border":"#aaaa dashed 1px"},attrs:{"id":"dataSourceTableData"}},[_c('span',{staticStyle:{"color":"#aaaa"}},[_vm._v(_vm._s(_vm.$t('chart.dragToHere')))])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }