import {app} from '@/main'
import {FORM_CARD_SELECTION_CONFIG} from '@/const/defaults'

const getCardConfigString = (cardConfigs, fieldIds=null) => {
    var totalFields = 1
    var included =[app.$i18n.t('general.title')]

    console.log('CardHelpers::getCardConfigString :: cardConfigs: ', cardConfigs)
    if (cardConfigs.cardSummaryFieldId !== '') {
        if (fieldIds) {
            if (fieldIds.includes(cardConfigs.cardSummaryFieldId)) {
                included.push(app.$i18n.t('general.summary'))
                totalFields++    
            }
        } else {
            included.push(app.$i18n.t('general.summary'))
            totalFields++
        }
    }

    if (cardConfigs) {
        if (cardConfigs.cardDisplayFieldIds) {
            if (cardConfigs.cardDisplayFieldIds.length > 0) {         
                if (fieldIds) {
                    totalFields += cardConfigs.cardDisplayFieldIds.filter( id => fieldIds.includes(id)).length
                } else {    
                    totalFields += cardConfigs.cardDisplayFieldIds.length
                }
            }
        } else {
            console.log('CardHelpers::getCardConfigString :: cardConfigs.cardDisplayFieldIds undefined  cardConfigs: ', cardConfigs)

        }
    } else {
        console.log('CardHelpers::getCardConfigString :: cardConfigs not defined')
    }
                    
    var result = [app.$i18n.t('general.title')]
    if (totalFields > 1) {
        result = app.$i18n.t('general.field') + ' x ' + totalFields + ' (' +
            app.$i18n.t('filters.includes') + ' ' + included.join(',') + ')'
    }
    return result
}

const getDefaultCardSettings = (formInfo) => {
    return {
        cardConfigs: FORM_CARD_SELECTION_CONFIG,
        sortingConfigs: [],
        filterConfigs: {}
    }
}

export {
    getCardConfigString,
    getDefaultCardSettings
}