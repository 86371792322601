<template>
<div :class="{'d-inline-block':!isVertical}">
  <v-tooltip top v-if="propertyKey=='fileType' && fileTypeExtensions" max-width="210">
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        dense
        v-on="on"
        class="rounded-sm min-width-80 elevation-0 px-3 py-3 white--text"
        style="font-size:12px; border: 1px solid #d9d9d9"
        @click="onClick"
        :class="checked?'primary white--text':'grey lighten-1'"
      >
        {{ getLabel(option) }}
      </v-btn>
    </template>
    <!-- <span>檔案格式: {{option.value.replace(/\|/g, ', ')}}</span> -->
    <span>{{$t('widgets.properties.fileFormat')}}: {{fileTypeExtensions[option.value].exts.join(' / ')}}</span>
  </v-tooltip>
  <v-btn 
    dense
    v-else
    class="rounded-sm  min-width-80 elevation-0 px-3 py-3 white--text"
    style="font-size:12px; border: 1px solid #d9d9d9"
    :class="checked?'primary':'grey lighten-1rey lighten-1'"
    @click="onClick"
  >
    {{ getLabel(option) }}
  </v-btn>
  <div v-if="inputFields.length>0" v-for="(labelTag,index) in inputFields"
       :key="index">
    <label class="caption">{{ $t('widgets.properties.' + labelTag) }}</label>
    <v-text-field dense
                  outlined
                  solo
                  hide-details="true"
                  :value="inputValues[index]"
                  @change="val=>updateUserInput(index, val)">
    </v-text-field>
  </div>
</div>
</template>

<script>
import fileTypeExtensions from "@/const/fileTypeExtensions"
export default {
  props: {
    option: Object,
    checked: {
      type: Boolean,
      default: false
    },
    inputValues: Array,
    isVertical: {
      type: Boolean,
      default: false
    },
    propertyKey:{
      type: String,
      default: undefined
    }
  },
  data(){
    return{
      fileTypeExtensions: fileTypeExtensions
    }
  },
  methods: {
    getLabel (option) {
      const vm = this
      var result = option.label
      if (option.labelTag) {
        result = vm.$t('widgets.properties.' + option.labelTag)
      }
      return result
    },
    onClick () {
      const vm = this
      console.log('InputCheckboxToggleBtn :: onClick :: vm.inputValues (' + (typeof vm.inputValues) + ': ', vm.inputValues)
      console.log('InputCheckboxToggleBtn :: onClick :: vm.inputValues instanceof Array: ' +
        (vm.inputValues instanceof Array))

      var checked = vm.checked ? false : true
      var optionInputs = ''

      if (checked) {
        optionInputs = vm.inputValues.join('|')
      }
      vm.$emit('onCommand', {
        command: 'updateUserOptionAndInput',
        checked: checked,
        optionValue: vm.option.value,
        optionInputs: vm.inputValues.join('|')
      })
    },
    updateUserInput (index, val) {
      const vm = this
      console.log('InputCheckboxToggleBtn :: updateUserInput : val: ', val)
      console.log('InputCheckboxToggleBtn :: updateUserInput : vm.inputValues: ', vm.inputValues)
      // const optionAndInput = vm.getOptionAndInput()
      const userInputValues = vm.inputValues
      console.log('InputCheckboxToggleBtn :: updateUserInput : userInputValues: ', userInputValues)
      userInputValues[index] = val
      vm.$emit('onCommand', {
        command: 'updateUserOptionAndInput',
        checked: true,
        optionValue: vm.option.value,
        optionInputs: userInputValues.join('|')

      })
    }
  },
  computed: {
    inputFields () {
      const vm = this
      var result = []
      if (vm.checked) {
        if (vm.option.inputFields && vm.option.inputFields.length > 0) {
          result = vm.option.inputFields
        }
      }
      return result
    }
  }
}
</script>
