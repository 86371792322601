const fieldHelperMixin = {
  methods: {
    getFieldNameByInfo (fieldInfo) {
      const vm = this
      var result = ''
      if (fieldInfo && fieldInfo.properties) {
        const properties = fieldInfo.properties
        const propertyKeys = Object.keys(properties)
        result = propertyKeys.indexOf('fieldNameTag') >= 0 ?
          vm.$t(properties['fieldNameTag']) :
          properties['fieldName']
      }
      return result
    },

    singleSelectionValueToString (fieldInfo, value) {
      const vm = this
      console.log('cpasteCellValueSingleSelection')
      let result = ''
      console.log('pasteCellValueSingleSelection :: values: ', value)

      if (value) {
        const options = fieldInfo.properties.inputOptionsSingle.options
        const selectedOption = options.find(option => option._id === value)
        result = selectedOption.label
      }
      return result
    },

    multipleSelectionValueToString (fieldInfo, value) {
      const vm = this
      console.log('pasteCellValueMultipleSelection: fieldInfo = ' + JSON.stringify(fieldInfo))
      console.log('pasteCellValueMultipleSelection: value = ' + JSON.stringify(value))
      let result = value
      if (Array.isArray(result) && result.length > 0) {
        let labels = []
        const options = fieldInfo.properties.inputOptionsMultiple.options
        console.log('multipleSelectionValueToString :: options: ', options)
        labels = value.map(valueItem => {
          console.log('map: valueItem =' + valueItem)
          const option = options.find(option => option._id === valueItem)
          console.log('map: option: ', option)
          return option.label
        })
        result = labels.length > 0 ? labels.join(', ') : ''
        console.log('multipleSelectionValueToString: result: ', result)
        // for (let i = 0; i < result.length; i++) {
        //   const loopItem = result[i]
        //   const selectedOption = options.find(option => option._id === loopItem)
        //   result.push(selectedOption.label)
        // }
      }
      return result
    },

    attachmentsValueToString (fieldInfo, value) {
      const vm = this
      console.log('pasteCellValueAttachments')
      let result = []
      const values = value
      if (Array.isArray(values)) {
        for (let i = 0; i < values.length; i++) {
          const loopValue = values[i]
          result.push(loopValue.originalName)
        }
      } else if(values.originalName) {
        result.push(values.originalName)
      }
      return result.length > 0 ? result.join(',') : ''
    },

    regionValueToString (fieldInfo, value) {
      const vm = this
      console.log('cpasteCellValueRegion')
      let result = value
      if (result && result.regionText) {
        result = result.regionText
      }
      return result
    },

    locationValueToString (fieldInfo, value) {
      const vm = this
      console.log('pasteCellValueLocation')
      let result = value
      //
      //
      return result
    },

    membersValueToString (fieldInfo, value) {
      const vm = this
      console.log('pasteCellValueMembers')
      let result = []
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const loopValue = value[i]
          result.push(loopValue.employeeName)
        }
      }
      return result.length > 0 ? result.join(',') : ''
    },

    departmentsValueToString (fieldInfo, value) {
      const vm = this
      console.log('pasteCellValueDepartments')
      let result = []
      for (let i = 0; i < value.length; i++) {
        const loopValue = value[i]
        result.push(loopValue.label)
      }
      return result.length > 0 ? result.join(',') : ''
    },

    getFieldString(fieldInfo, value) {
      const vm = this
      let result = ''
      switch (fieldInfo.type) {
        case 'singleSelection':
          result = vm.singleSelectionValueToString(fieldInfo, value)
          break
        case 'multipleSelection':
          result = vm.multipleSelectionValueToString(fieldInfo, value)
          break
        case 'attachments':
          result = vm.attachmentsValueToString(fieldInfo, value)
          break
        case 'region':
          result = vm.regionValueToString(fieldInfo, value)
          break
        case 'location':
          result = vm.locationValueToString(fieldInfo, value)
          break
        case 'members':
          result = vm.membersValueToString(fieldInfo, value)
          break
        case 'departments':
          result = vm.departmentsValueToString(fieldInfo, value)
          break
      }
      return result
    }
  }
}

export default fieldHelperMixin
