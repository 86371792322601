<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :required="required"
    :fieldWidth="fieldWidth"
    :isHidden="isHidden"
    :label="fieldName"
    :noIcon="noIcon"
    :tooltipText="description"
  >

    <v-text-field
      :class="{ 'workflow-customize-form-record-hide': hideInput, 'number-field': expressionType==='number' }"
      v-model="computedValue"
      disabled
      dense
      class="disabled"
    ></v-text-field>  

  </input-widget-container>
</template>

<script>
import inputWidgetContainer from "./comps/InputWidgetContainer";
import mixin from "./mixin";
import { getFieldPropertyValue } from "../../helpers/ObjectHelpers";
import expressionMixin from "../../mixins/expressionMixin";

export default {
  name: "inputWidgetExpression",
  mixins: [mixin, expressionMixin],
  components: {
    inputWidgetContainer,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
    editAnyway: Boolean,
  },
  data() {
    return {
      userValue: "",
    };
  },
  computed: {
    computedValue() {
      let result = "";
      if (!this.isLayout) {
        result = this.calculateValue();
      }     
      const floatValue = parseFloat(this.value.toString().replaceAll(',', ''));
      if (result !== floatValue) {
        this.updateValue(result);
      }
      return (typeof result === 'number')? this.formatNumber(result): (result || "");
    },
    expressionType () {
      return getFieldPropertyValue(
        this.fieldInfo,
        "expressionType",
        "number"
      )
    },
    selectFields () {
      const propertyValue =  getFieldPropertyValue(
        this.fieldInfo,
        "selectFields",
        []
      )
      return propertyValue.filter(fieldId => this.fieldInfos.find(info=>info.fieldId ===fieldId))
    }
  },
  methods: {
    validate() {
      return true;
    },

    calculateValue() {
      const vm = this;
      let result;

      if (vm.expressionType === "number") {
        result = vm.calculateNumber(vm.fieldInfo, vm.selectFields, vm.recordData);
        
      } else {
        result = vm.calculateDate(vm.fieldInfo, vm.recordData);
      }
      return result;
    },
    getTranslation(unit) {
      return this.$t(`widgets.properties.${unit}`);
    },
  },
};
</script>

<style scoped>
.is-layout {
  padding-bottom: 0 !important;
}

.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
</style>

<style>
.v-text-field.number-field input[disabled=disabled] {
  text-align: right !important;
}
</style>