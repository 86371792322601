<template>
  <div id="section-to-print" class="printContent flex clearfix pt-20">
    <Header :templateData="templateData" :record="record" />
    <div
      class="tableContent mt-18"
      :style="`font-size:${templateData.fontSize}px !important;`"
    >
      <div
        v-for="(row, index) in templateData.layout"
        :key="index"
        class="tableRow"
        :class="
          templateData.hideEmptyFields && checkRowEmpty(row) ? 'hide' : ''
        "
      >
        <div
          v-for="item in row"
          :key="item._id"
          class="tableCell"
          :class="
            templateData.hideEmptyFields &&
            checkIsEmpty(record[item._id], item.controlType, item._id)
              ? 'hide'
              : ''
          "
          :style="`width: ${getWidth(item.width)}px;max-width:${getWidth(item.width)}px;min-width:${getWidth(item.width)}px`"
        >
          <field
            :item="item"
            :fieldInfo="getFieldInfo(item._id)"
            :value="record[item._id]"
            :fontSize="templateData.fontSize"
            :childTableFields="templateData.childTableFields"

          />
        </div>
      </div>

      <div v-if="templateData.signatures.length > 0" class="mt-50 pb-30 signatures flex-row">
        <div
          v-for="signature in templateData.signatures"
          :key="signature"
          class="signature-box"
        >
          <div class="font-weight-bold">
            {{ getLabel(signature) }}
          </div>
          <img
            v-if="record[signature][0]"
            class="signature-image"
            :src="record[signature][0]['thumbnailUrl']"
          />
        </div>
      </div>
      <div style="margin-top:10px" v-if="templateData.showPrintTime">
        <span style="font-size:12px;float:right">
          {{ $t("printTemplate.printTime", { time: printTime }) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationsHelper from "@/helpers/ValidationsHelper";
import Field from "./printField";
import Header from "./Header";

export default {
  props: {
    templateData: Object,
    record: Object,


    printTime: String,
    allFieldInfos:Array,

  },
  components: {
    Field,
    Header,
  },
  methods: {
    getLabel(fieldId) {
      const fieldInfo = this.allFieldInfos.find(
        (info) => info.fieldId === fieldId
      );
      return (fieldInfo && fieldInfo.label) || "";
    },

    getFieldInfo(fieldId) {
      return this.allFieldInfos.find((info) => info.fieldId === fieldId);
    },
    getWidth(width) {
      return width ? 728 * (width / 12) : 728;
    },
    checkIsEmpty(value, type, fieldId) {
      const fieldInfo = this.getFieldInfo(fieldId);
      if (fieldInfo.type === "region") return !value || !value.regionText;
      else
        return (
          fieldInfo.type !== "childTable" &&
          type !== "static" &&
          ValidationsHelper.isEmpty(value)
        );
    },
    checkRowEmpty(row) {
      const result = !row.some((field) => {
        return !this.checkIsEmpty(
          this.record[field._id],
          field.controlType,
          field._id
        );
      });

      return result;
    },
  },
};
</script>
