<template>
  <div>
    <v-progress-linear 
      v-if="loading"
      indeterminate
      color="primary"
    />
    <div v-else-if="!loading" class="w-customized-button-form">
      <div class="w-customized-button-form-user-input">
        <div>
          {{ $t('workflow.modules.actionProcess.label.buttonName') }}
        </div>
        <v-text-field
          class="workflow-setting-input-root"
          v-model="buttonInfo.name"
          :placeholder="$t('messages.pleaseInput', [])"
          outlined
          height="36"
        ></v-text-field>
      </div>
      <div class="w-customized-button-form-user-input">
        <div>
          {{ $t('workflow.modules.actionProcess.label.buttonRemark') }}
        </div>
        <v-textarea
          class="workflow-setting-input-root"
          v-model="buttonInfo.remark"
          :placeholder="$t('messages.pleaseInput', [])"
          outlined
          no-resize
          rows="5"
        ></v-textarea>
      </div>
      <v-divider />
      
      <div class="w-customized-button-form-user-input w-customized-button-form-user-input-external">
        <div>
          {{ $t('workflow.modules.actionProcess.label.buttonDisplay') }}
        </div>
        <DisplaySetting :display="buttonInfo.display" @change="onChanged" />
      </div>
      <div class="w-customized-button-form-user-input w-customized-button-form-user-input-external">
        <div>
          {{ $t('workflow.modules.actionProcess.label.selectAfter') }}
        </div>
        <TriggerSetting
          :buttonName="buttonInfo.name"
          :popup="buttonInfo.popup"
          :type="buttonInfo.type"
        />
      </div>
      <div class="w-customized-button-form-user-input">
        <div>
          {{ $t('workflow.modules.actionProcess.label.workflowTarget') }}
        </div>
        <WorkflowSelect
          :buttonFlow="buttonInfo.workflow"
          :noWorkflow="buttonInfo.noWorkflow"
          
        />
      </div>
      <v-divider />
      <div class="w-customized-button-form-user-input w-customized-button-form-user-input-external">
        <div>
          {{ $t('workflow.modules.actionProcess.label.buttonColor') }}
        </div>
        <ColorPicker :color="buttonInfo.color" @change="onChanged" />
      </div>
      <div class="w-customized-button-form-user-input w-customized-button-form-user-input-external">
        <div>
          {{ $t('workflow.modules.actionProcess.label.buttonIcon') }}
        </div>
        <IconPicker :icon="buttonInfo.icon" :color="buttonInfo.color" @change="onChanged" />
      </div>
    </div>
    <v-layout v-if="!loading" align-center justify-end class="workflow-customized-action-button-tools">
      <v-btn @click="$emit('back')" text>
        {{$t('workflow.label.popupCustomimzedAction.cancelLabel')}}
      </v-btn>
      <v-btn
        color="primary"
        @click="submit"
        :disabled="!validSubmit"
        :loading="loading || processing"
      >
        {{ !!item ? 
          $t('workflow.modules.actionProcess.label.updateLabel'):
          $t('workflow.modules.actionProcess.label.createLabel')
        }}
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import {
  FETCH_ACTION_BUTTON_INFO,
  UPDATE_ACTION_BUTTON,
  CREATE_ACTION_BUTTON,
} from '@/store/modules/customizedAction/action_types';
import WorkflowRoutesName from '@/const/workflow/routes';
import { get } from 'lodash';
import AlertSetting from './alert/';
import ColorPicker from './color';
import IconPicker from './icon';
import DisplaySetting from './display';
import TriggerSetting from './trigger';
import WorkflowSelect from './workflow';

export default {
  props: {
    form: Object,
    item: Object,
    onBack: Function,
  }, 
  components: {
    ColorPicker,
    IconPicker,
    TriggerSetting,
    WorkflowSelect,
    DisplaySetting,
    AlertSetting,
  },
  methods: {
    fetch() {
      this.$store.dispatch(FETCH_ACTION_BUTTON_INFO, {
        appId: this.currentApp._id,
        formId: this.form._id,
        buttonId: this.item && this.item._id,
      });
    },
    submit() {
      const { viewId } = this.$route.params;
      const workflow = get(this.buttonInfo, 'workflow');
      const payload = {
        appId: this.currentApp._id,
        formId: this.form._id,
        viewId,
        data: {
          ...this.buttonInfo,
          workflow: workflow && workflow._id,
        }
      };
      if (this.item) {
        this.$store.dispatch(UPDATE_ACTION_BUTTON, {
          ...payload,
          buttonId: this.item._id,
        });
      } else {
        this.$store.dispatch(CREATE_ACTION_BUTTON, payload);
      }
      this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
        key: "plus_click_functional_button",
        data:{
          function_button:this.$sensors.events.plus_click_functional_button.ADD_BUTTON_SAVE
        }
      });
    },
    onChanged(info) {
      this.buttonInfo = info;
    },
    // onChangeWorkflow(workflow) {
    //   this.$set(this.buttonInfo, 'workflow', workflow);
    // },
  },
  computed: {
    validSubmit() {
      const { name } = this.buttonInfo;
      return !!name;
    },
    loading() {
      return this.$store.getters.fetchingActionButton;
    },
    createdButton() {
      return this.$store.getters.createdButton;
    },
    

    processing() {
      return this.$store.getters.processAnyAction;
    },
    buttonInfo() {
      return this.$store.getters.getActionButton;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    buttons() {
      return this.$store.getters.getActionButtons;
    },
  },
  created() {
    this.fetch();
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.fetch();
      },
    },
    createdButton: {
      deep: true,
      handler(v) {
        if (v && v.workflow) {
          this.$router.push({
            name: WorkflowRoutesName.formWorkflowsTree, 
            params: {
              workflowId: v.workflow._id,
              id: this.currentApp._id,
            },
          });
        }
      },
    },
    processing: {
      handler(processed, wasProcessing) {
        if (!processed && wasProcessing) {
          this.$emit('back');
        }
      },
      deep: true,
    }
  },
}
</script>

<style scoped>
  .w-customized-button-form {
    padding: 0px 25px;
  }
  .w-customized-button-form-user-input {
    margin: 0 0 20px 0;
  }
  .w-customized-button-form-user-input > div:first-child {
    margin: 20px 0 10px 0;
    font-weight: 900;
    font-size: 15px;
    color: black;
  }
  .w-customized-button-form-user-input-external {
    margin-bottom: 40px;
  }
</style>