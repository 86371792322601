<template>
    <v-card rounded outlined
    :class="cardClass"
    :ripple="edit"
    :color="color"
    class="fill-height record-card panel-card d-flex align-stretch flex-row justify-start cursor-move data-block-card" 
    @click="onClicked"
    @mouseenter="onMouseEnter" 
    @mouseleave="onMouseLeave" >
        <!-- LEFT IMAGE FOR WHOLE COLUMN -->
        <card-image v-if="leftImage && stretchImage" 
            :cardConfig="adjustedCardConfig" 
            :imageSrc="imageSrc"
            :mediaId="mediaId"></card-image>

        <div class="d-flex flex-column flex-grow-1" style="overflow-x: hidden; position: relative; width:100%">
          <!-- TOP IMAGE FOR WHOLE ROW -->    
          <card-image v-if="topImage" 
            :cardConfig="adjustedCardConfig" 
            :imageSrc="imageSrc"
            :mediaId="mediaId"></card-image>

          <v-card-title class="line-height-1 px-2 pt-2 mb-1 pb-0">
                <div class="d-flex flex-row w-100 flex-start">
                    <card-image v-if="leftImage&&!stretchImage" 
                        :cardConfig="adjustedCardConfig" 
                        :imageSrc="imageSrc"
                        :mediaId="mediaId"></card-image>
                    <div class="d-flex w-100 flex-column">    
                        <div class="d-flex flex-row align-center">
                            <field-string v-if="titleFieldInfo" 
                                style="height:auto;" 
                                :fieldInfo="titleFieldInfo" 
                                class="pb-1 flex-grow-1"
                                :recordData="card"></field-string>
                            <div class="flex-grow-1" v-else>{{ title }}</div>
                        </div>
                        <!-- Summary Field -->  
                        <v-row v-if="summaryContent" class="my-1 text-caption" :class="isSummaryOptionSelection?'':'card-summary-height-restricted'">
                            <v-col class="py-0">
                                <field-string height="auto" :fieldInfo="summaryFieldInfo" :textOnly="false" :recordData="card"></field-string>  
                            </v-col>
                        </v-row>
                    </div>
                    <card-image 
                        v-if="rightImage&&!stretchImage" 
                        :imageSrc="imageSrc"
                        :cardConfig="adjustedCardConfig"
                        :mediaId="mediaId"></card-image>
                </div>    
          </v-card-title>
    
          <v-card-text class="px-2 d-flex flex-column">
                <hr v-if="adjustedCardConfig.cardDisplayFieldIds.length>0" 
                    class="section-line" />
                <v-row class="card-field">    
                    <card-field
                     v-for="fieldId in adjustedCardConfig.cardDisplayFieldIds" :key="fieldId"
                     :view="view" :card="card" 
                    :class="cardFieldClass"
                    :valueOnly="valueOnly"
                    :fieldInfo="getFieldInfo(fieldId)"></card-field>
                </v-row>
                <slot name="bottomNote">

                </slot>
            </v-card-text>
            <slot name="card-control">
                <card-buttons 
                    v-if="hovering && edit"
                    :buttons="buttons"
                    class="panel-card-buttons" 
                    @onCommand="onCommandHandler"></card-buttons>
            </slot>

        </div>
        <card-image v-if="rightImage&&stretchImage"
          :cardConfig="adjustedCardConfig"
          :imageSrc="imageSrc"
          :mediaId="mediaId"></card-image>
   
        <confirm-delete-dialog ref="confirmDeleteDialog">          
        </confirm-delete-dialog>
        <slot name="rightNote"></slot>
    </v-card>
</template>
<script>
import mediaHelperMixin from '@/mixins/mediaHelperMixin'
import fieldHelperMixin from '@/mixins/fieldHelperMixin'
import cardButtons from './CardButtons'
import confirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog'
import cardField from './CardField'
import fieldString from '@/components/fieldStrings/Fs'
import zoomPreview from '@/components/zoomPreview'
import cardImage from './CardImage'
import {FORM_CARD_DISPLAY_CONFIG} from '@/const/defaults'

export default {
    name: 'recordCard',
    mixins: [
        mediaHelperMixin,
        fieldHelperMixin
    ],
    components: {
        cardButtons,
        confirmDeleteDialog,
        cardField,
        zoomPreview,
        fieldString,
        cardImage
    },
    props: {
        cardConfig: Object,
        titleFieldId: String,
        fieldInfos: {
            type: Array,
            default () {
                return []
            }
        },
        card: Object,
        isLayout: {
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        buttons: {
            type: Array,
            default () {
                return [
                    {key:'edit', command: 'edit'},
                    {key:'delete', command: 'delete'}
                ]
            }
        },
        selected: {
            type: Boolean,
            default: false
        },
        selectedClass: {
            type: String,
            default: 'primary white--text'
        },
        colorClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hovering: false
        }
    },
    methods: {
        onClicked () {
            // console.log('RecordCard :: onClicked')
            this.$emit('click')
        },
        getFieldInfo(fieldId) {
            const vm = this
            const fieldInfo = vm.fieldInfos.find(info => info.fieldId ===
                fieldId)
            return fieldInfo ? fieldInfo : null
        },
        onMouseEnter() {
            this.hovering = true
        },
        onMouseLeave() {
            this.hovering = false
        },
        onCommandHandler(payload) {
            const vm = this
            // console.log('RecordCard :: onCommandHandler :: payload: ', payload)
            if (payload.button.requiresConfirm) {
                const options = payload.button.key === 'unlink' ? 
                    {title: vm.$t('buttons.cancelLinking')} : {}

                vm.$refs.confirmDeleteDialog.confirm(() => {
                    vm.$emit('onCommand', {
                        command: payload.command,
                        record: vm.card
                    })
                }, options)
            } else {
                vm.$emit('onCommand', {
                    command: payload.command,
                    record: vm.card
                })
            }
        },
        getFieldName(id, idType) {
            const vm = this
            var result = ''
            if (typeof idType === 'undefined') {
                idType === 'fieldId'
            }
            const fieldInfo = idType === 'fieldId' ?
                vm.fieldInfos.find(info => info.fieldId === id) :
                vm.fieldInfos.find(info => info._id === id)
            if (fieldInfo) {
                result = vm.getFieldNameByInfo(fieldInfo)
            }
            return result
        },
        getFieldNameByInfo(fieldInfo) {
            const vm = this
            var result = ''
            const properties = fieldInfo.properties
            const propertyKeys = Object.keys(properties)
            result = propertyKeys.indexOf('fieldNameTag') >= 0 ?
                vm.$t(properties['fieldNameTag']) :
                properties['fieldName']
            return result
        },
        getFieldNameByInfoId(infoId) {
            return this.getFieldName(infoId, 'infoId')
        },
        getFieldNameByFieldId(fieldId) {
            return this.getFieldName(fieldId, 'fieldId')
        },
    },

    computed: {
        valueOnly () {
            const vm = this
            var result = true
            if (vm.cardConfig &&
                vm.cardConfig.cardDisplayFieldName) {
                result = false
            }
            return result
        },
    /*  
    :class="{'align-stretch':cardImageMode==='contain' || cardImageMode==='cover'}"
    */
    cardClass () {
        const vm = this
        var result = []
        if (vm.cardImageMode === 'contain' || vm.cardImageMode === 'cover') {
            result.push('align-stretch')
        }
        if (vm.selected) {
            result.push(vm.selectedClass)
        } else {
            if (vm.colorClass) {
                result.push(vm.colorClass)
            }
        }
        return result==='' ? '' : result.join(' ')
    },
      adjustedCardConfig () {
        const vm = this
        var result = FORM_CARD_DISPLAY_CONFIG
        return {...result, ...vm.cardConfig}
      },
      cardFieldClass () {
        const vm = this
        var result = ''        
        if (vm.adjustedCardConfig.cardContentColumns) {
          result = 'col-' + (12 / vm.adjustedCardConfig.cardContentColumns)
        }
        return result
      },

        imageStyle() {
            const vm = this
            var result = {}
            result['width'] = vm.adjustedCardConfig.cardImageSize + 'px'

            if (vm.adjustedCardConfig.cardImagePadding && vm.adjustedCardConfig.cardImagePadding > 0) {
                result['padding'] = vm.adjustedCardConfig.cardImagePadding + 'px'
            }
            return result
        },
        isSummaryOptionSelection() {
            const vm = this
            var result = false
            if (vm.summaryFieldInfo) {
                result = vm.summaryFieldInfo.type === 'singleSelection' || vm.summaryFieldInfo.type === 'multipleSelection'
            }
            return result
        },
        summaryFieldInfo() {
            const vm = this
            var result = null
            if (vm.adjustedCardConfig.cardSummaryFieldId && vm.adjustedCardConfig.cardSummaryFieldId !== '') {
                return this.fieldInfos.find(info => info.fieldId === vm.adjustedCardConfig.cardSummaryFieldId)
            }
            return result
        },
        summaryContent() {
            const vm = this
            var result = ''
            if (vm.adjustedCardConfig.cardSummaryFieldId !== '') {
                result = vm.card[vm.adjustedCardConfig.cardSummaryFieldId]
            }
            return result
        },
        titleFieldInfo() {
            const vm = this
            var result = null
            if (vm.titleFieldId !== '') {
                return vm.fieldInfos.find(info => info.fieldId === vm.titleFieldId)
            }
            return result
        },
        title() {
            const vm = this
            var result = ''
            if (vm.card && vm.titleFieldInfo) {
                return this.card[this.titleFieldInfo.fieldId]
            }
            return result
        },
        media(){
            const vm = this
            var result = ''
            if (vm.card &&
              vm.adjustedCardConfig.cardImageFieldId &&
              (
                vm.adjustedCardConfig.cardImageFieldId !== '' && 
                vm.card[vm.adjustedCardConfig.cardImageFieldId]) &&                
                (vm.card[vm.adjustedCardConfig.cardImageFieldId].length > 0)
            ) {
                result = vm.card[vm.adjustedCardConfig.cardImageFieldId][0]
            }
            return result
        },
        mediaId() {
            return this.media && this.media.mediaId || ""
        },

        imageSrc() {
            return this.media && this.media.thumbnailUrl || ""
        },

        hasCardImage() {
            return this.adjustedCardConfig.cardImageFieldId && this.adjustedCardConfig.cardImageFieldId !== ''
        },

        topImage() {
            return this.hasCardImage && this.adjustedCardConfig.cardImagePosition === 'top'
        },

        leftImage() {
            return this.hasCardImage && this.adjustedCardConfig.cardImagePosition === 'left'
        },

        rightImage() {
            return this.hasCardImage && this.adjustedCardConfig.cardImagePosition === 'right'
        },

        stretchImage() {
            return this.hasCardImage && this.adjustedCardConfig.cardImageStretch
        }

    }
}
</script>

<style>
/*.panel-card:hover .panel-card-buttons {*/

/*display: block;*/

/*}*/

.panel-card {
    width: 100%;
}

.panel-card .panel-card-buttons {
    position: absolute;
    top: 10px;
    right: 2px;
    /*display: none;*/
}

/* .col-image {
     width: 80px;
     max-width: 80px;
   }*/

.card-summary-height-restricted {
    max-height: 36px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    line-height: 1 !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 3s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active below version 2.1.8 */

    {
    opacity: 0;
}

.card-field.row {
    margin: 0;
}

.panel-card .section-line {
    margin-bottom: 5px;
    border-color: rgba(0, 0, 0, .05);
}

.panel-card .v-card__text {
    padding-bottom: 4px;
}

.data-block-card .field-string {
    height: auto;
}

.card-image {
     background-color: rgba(0, 0, 0, .05); 
}

.circle-image {
    /*     width: 80px !important;
    height: 80px !important; */
    border-radius: 50% !important;
}

.square-image {
    /*     width: 80px !important;
    height: 80px !important;
 */
    border-radius: 0 !important;
}
.v-card.record-card:hover {
    background-color: rgba(229,245,251,.5);
}
</style>
