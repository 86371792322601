const mixin = {
  methods: {
    onRemove() {
      this.$emit('remove');
      // , { node: this.node, offset: this.offset });
    },
  },
  computed: {
    appId() {
      return this.$route.params.id;
    },
  }
}

export default mixin
