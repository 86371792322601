<template>
  <v-container>
    <url-box :url="publicUrl">
      <template v-slot:prepend-outer>
        <v-switch
          class="mt-0 px-2"
          :label="$t('publicForm.activate')"
          v-model="publicForm.active"
          @change="saveSetting('active', $event)"
          dense
          hide-details
        />
        <url-box
        class="px-1"
          v-if="!editing"
          :url="publicForm.identifier"
          :canCopy="false"
          maxWidth="100px"
          noQRCode
          @dblclick.native="editing = true"
          @copy="onCommandHandler({command:'copyUrl'})"
          @download="onCommandHandler({command:'downloadUrl'})"
        >
          <template v-slot:append-inner>
            <v-icon small @click="editing = true">mdi-pencil-outline</v-icon>
          </template>
        </url-box>
        <v-text-field
          v-else
          hide-details
          height="36"
          solo
          autofocus
          v-model="publicForm.identifier"
          style="maxWidth:100px;font-size: 13px"
          filled
          flat
          class="px-1"
          color="white"
          background-color="grey lighten-2"
          @blur="
            saveSetting('identifier', publicForm.identifier);
            editing = false;
          "
        />
      </template>
      <template v-slot:prepend-copy>
        <div class="px-2">
          <v-tooltip top>
            <template v-slot:activator="{ attr, on }">
              <v-btn icon small @click="openURL(false)" v-on="on" v-bind="attr">
                <v-icon small>
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </template>
            {{ $t("publicForm.openInNew") }}
          </v-tooltip>
        </div>
      </template>
      <template v-slot:append-copy>
        <div class="px-2">
          <v-tooltip top>
            <template v-slot:activator="{ attr, on }">
              <v-btn
                icon
                small
                color="primary"
                @click="openBuilder"
                v-on="on"
                v-bind="attr"
              >
                <v-icon small>
                  mdi-cog-outline
                </v-icon>
              </v-btn>
            </template>
            {{ $t("general.settings") }}
          </v-tooltip>
        </div>
        <div class="px-2">
          <v-tooltip top>
            <template v-slot:activator="{ attr, on }">
              <v-btn
                icon
                small
                color="error"
                @click="deleteForm"
                v-on="on"
                v-bind="attr"
              >
                <v-icon small>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </template>
            {{ $t("buttons.delete") }}
          </v-tooltip>
        </div>
      </template>
    </url-box>
    <form-builder
      v-if="showFormBuilder"
      v-model="showFormBuilder"
      :publicForm="publicForm"
      @change="saveForm"
      @commandHandler="onCommandHandler"
    />
  </v-container>
</template>
<script>
import urlBox from "./urlBox";
import FormBuilder from "../formBuilder";
import publicFormMixin from "@/mixins/publicFormMixin";

export default {
  components: {
    urlBox,
    FormBuilder,
  },
  mixins: [publicFormMixin],
  props: {
    publicForm: Object,
  },
  data() {
    return {
      showFormBuilder: false,
      editing: false,
    };
  },
  methods: {
    onCommandHandler(payload) {
      this.$emit("commandHandler", payload);
    },
    saveSetting(setting, value) {
      this.$emit("commandHandler", {
        command: "saveSetting",
        publicForm: this.publicForm,
        setting,
        value,
      });
    },
    saveForm() {
      this.$emit("commandHandler", {
        command: "saveForm",
        publicForm: this.publicForm,
      });
    },
    deleteForm() {
      this.$emit("commandHandler", {
        command: "deleteForm",
        publicForm: this.publicForm,
      });
    },
    openBuilder(){
      this.showFormBuilder = true;
      this.onCommandHandler({
        command:"openSettings"
      })
    }
  },
};
</script>
