<template>
  <div>
    <label>{{ label }}</label>
    <v-text-field
        label=""
        :value="displayValue"
        append-outer-icon="mdi-window-restore"
        dense
        readonly
        :placeholder="placeholder"
        @click:append-outer="openDialog"
        hide-details="auto"
        outlined></v-text-field>
    <related-record-selection-setup-dialog
        ref="relatedRecordSelectionSetupDialog"
        @confirm="confirmHandler"></related-record-selection-setup-dialog>
  </div>
</template>

<script>
  //******************************
  // WitRelatedRecordDisplayFields
  //******************************
  // propertyValue =
  // {
  //    fields: [...], // obsolate
  //    displayFields: {
  //      avatarFieldId: ...,
  //      fixedFieldIds: [...],
  //      nonFixedFieldIds: [...]
  //    }
  //    cardFields: [
  //        {active: true, ... }
  //    ],
  //    displayTable: {
  //    },
  //    selectionCardList: {  <########
  //        fixedFieldIds: [...],
  //        nonFixedFieldIds: [...]
  //    },
  //    selectionTable: {   <########
  //        fixedFieldIds: [...],
  //        nonFixedFieldIds: [...]
  //    },
  //    filters: [
  //    ],
  //    copyFields: [
  //    ]
  //    sortingFields: {
  //      {field1}:1,
  //      {field2}:2
  //    }
  // }
  //
  import mixin from './mixin'
  import relatedRecordSelectionSetupDialog from '@/components/dialogs/RelatedRecordSelectionSetupDialog'
  import TABS from '@/components/dialogs/tabs/constants.js'
  import {getDefaultPropertyValue} from '@/helpers/FormHelpers'

  export default {
    mixins: [mixin],
    components: {
      relatedRecordSelectionSetupDialog
    },
    data () {
      return {
        // TABS_MULTIPLE_RELATED_RECORD: [
        //   TABS.DISPLAY_TABLE_SETTING,
        //   TABS.DISPLAY_TABLE_SORTING,
        //   TABS.SELECTION_TABLE,
        //   TABS.SELECTION_FILTERS,
        //   TABS.SELECTION_SORTING
        // ],
        TABS_SELECTION_CARD: [
          // TABS.CARD_DISPLAY_FIELDS,
          TABS.CARD_CONFIG,
          TABS.CARD_SELECTION_LIST,
          TABS.CARD_SELECTION_FILTERS,
          TABS.CARD_SELECTION_SORTING
          // ,
          // TABS.COPY_RELATED_FIELDS
        ],
        TABS_SELECTION_TABLE: [
          // TABS.CARD_DISPLAY_FIELDS,
          TABS.SELECTION_TABLE,
          TABS.SELECTION_FILTERS,
          TABS.SELECTION_SORTING
          // ,
          // TABS.COPY_RELATED_FIELDS
        ],
        // TABS_SINGLE_FIELD_SELECTION_POPUP: [
        //   TABS.SELECTION_TABLE,
        //   TABS.SELECTION_FILTERS,
        //   TABS.SELECTION_SORTING
        //   // ,
        //   // TABS.COPY_RELATED_FIELDS
        // ],
        // TABS_SINGLE_FIELD_SELECTION_PULLDOWN: [
        //   TABS.CARD_SELECTION_LIST,
        //   TABS.CARD_SELECTION_FILTERS,
        //   TABS.CARD_SELECTION_SORTING
        //   // ,
        //   // TABS.COPY_RELATED_FIELDS
        // ]
      }
    },
    watch: {
      dataTableId: function(newVal) {
        const vm = this
        vm.propertyValue = getDefaultPropertyValue(vm.propertyKey)
        vm.propertyValue.displayFields.fixedFieldIds = [newVal]
        vm.propertyValue.selectionTable.fixedFieldIds = [newVal]
        vm.propertyValue.cardSelectionList.fixedFieldIds = [newVal]
      },
      relatedTitleFieldId: function(newVal) {
        const vm = this
        // console.log('witRelatedrecordDisplayFields :: watch(relatedTitleFieldId): newVal = ' + newVal)
        if (!vm.propertyValue) {
          vm.propertyValue = getDefaultPropertyValue(vm.propertyKey)
        }
        vm.propertyValue.displayFields.fixedFieldIds = [newVal]
        vm.propertyValue.selectionTable.fixedFieldIds = [newVal]
        vm.propertyValue.cardSelectionList.fixedFieldIds = [newVal]
        // console.log('watch(relatedTitleFieldId): vm.propertyValue: ', vm.propertyValue)
      }
    },
    computed: {
      relatedTitleFieldInfo () {
        const vm = this
        var result = null
        if (vm.relatedFormInfo && vm.relatedFormInfo.titleFieldInfoId !== '') {
          const fieldInfo = vm.relatedFormInfo.fieldInfos.find(
            info => info._id === vm.relatedFormInfo.titleFieldInfoId
          )
          if (fieldInfo) {
            result = fieldInfo
          }
        }
        return result
      },

      relatedTitleFieldId () {
        const vm = this
        var result = ''

        if (vm.relatedTitleFieldInfo) {
          result = vm.relatedTitleFieldInfo.fieldId
        }
        return result
      },

      dataSources () {
        return this.$store.getters.dataSources
      },

      relatedFormInfo () {
        const vm = this
        var result = null
        if (vm.isParentChildRelation) {
          result = {
            ...vm.formInfo,
            fieldInfos: vm.formFieldInfos
          }
        } else {
          if (vm.dataSource && vm.dataSources) {
            result = this.dataSources.find(ds => ds._id === vm.dataSource)
          }
        }
        // console.log('WitRelatedRecordDisplayFields :: computed(relatedFormInfo) :: result: ', result)
        return result
      },

      isRelatedMultiple () {
        return this.relatedRecordQuantity === 'multiple'
      },

      fieldCountDuringSelection () {
        const vm = this
        var result = 0
        if (vm.propertyValue) {
          if (vm.propertyValue.selectionTable) {
            result = vm.propertyValue.selectionTable.fixedFieldIds.length +
              vm.propertyValue.selectionTable.nonFixedFieldIds.length
          }
        }
        return result
      },

      displayTableFieldCount () {
        const vm = this
        var result = 0
        if (vm.propertyValue) {
          if (vm.propertyValue.displayTable) {
            result = vm.propertyValue.displayTable.fixedFieldIds.length +
              vm.propertyValue.displayTable.nonFixedFieldIds.length
          }
        }
        return result
      },

      cardFieldCount () {
        const vm = this
        var result = 0
        if (vm.propertyValue) {
          if (vm.propertyValue.cardFields) {
            for (let i = 0; i < vm.propertyValue.cardFields.length; i++) {
              if (vm.propertyValue.cardFields[i].active) {
                result++
              }
            }
          }
        }
        return result
      },

      placeholder () {
        return this.$t('messages.pleaseSelectFields')
      },

      isCardSelection () {
        return ['card','pulldown'].includes(this.selectionMode)
      },

      isTableSelection () {
        return this.selectionMode==='table'
      },

      displayValue () {
         const vm = this
         var result = []
          if (vm.fieldCountDuringSelection > 0) {
            result.push(
              vm.$t('general.duringSelection') +
              vm.$t('general.display') + ' ' +
              vm.fieldCountDuringSelection + ' ' + vm.$t('general.field')
            )
          }
          return result.join(', ')
      },

      displayValue2 () {
      //   const vm = this
      //   return vm.isRelatedMultiple ?
      //     vm.displayValueForMultipleRecords :
      //     vm.displayValueForSingleRecord
      // },
      // displayValueForMultipleRecords () {
        const vm = this
        var result = ''
        var arMsg = []
        if (vm.displayTableFieldCount > 0 || vm.fieldCountDuringSelection > 0) {
          if (vm.displayTableFieldCount > 0) {
            arMsg.push(
              vm.$t('general.display') + ' ' +
              vm.displayTableFieldCount + ' ' + vm.$t('general.field')
            )
          }
          if (vm.fieldCountDuringSelection > 0) {
            arMsg.push(
              vm.$t('general.duringSelection') +
              vm.$t('general.display') + ' ' +
              vm.fieldCountDuringSelection + ' ' + vm.$t('general.field')
            )
          }
        }
        result = arMsg.length > 0 ? arMsg.join(', ') : ''
        return result
      },

      // displayValueForSingleRecord () {
      //   const vm = this
      //   var result = ''
      //   var arMsg = []
      //   if (vm.relatedTitleFieldInfo) {
      //     arMsg.push(vm.$t('general.titleField'))
      //   }
      //   if (vm.propertyValue &&
      //     vm.propertyValue.displayFields &&
      //     vm.propertyValue.displayFields.nonFixedFieldIds &&
      //     vm.propertyValue.displayFields.nonFixedFieldIds.length >0) {
      //     arMsg.push(
      //       vm.$t('widgets.properties.extraFields') + 'x' +
      //       vm.propertyValue.displayFields.nonFixedFieldIds.length
      //     )
      //   }
      //   result = arMsg.length > 0 ? arMsg.join(' + ') : ''
      //   return result
      // },

      relatedRecordQuantity () {
        return this.getPropertyValue('relatedRecordQuantity', 'single')
      },
      displayFormat () { // [card|singleField], default:card
        return this.getPropertyValue('displayFormat', 'card')
      },

      multipleDisplayFormat () {
        return this.getPropertyValue('multipleDisplayFormat', 'card')        
      },

      selectionMode () { // [popup|pulldown], default: popup
        return this.getPropertyValue('selectionMode', 'popup')
      },

      cardSelectionMode () { // [table|card], default:table
        return this.getPropertyValue('cardSelectionMode', 'table')
      },

      isParentChildRelation () {
        return this.dataSource && this.dataSource === this.formId
      }

    },
    methods: {
      openDialog () {
        const vm = this
        if (vm.dataSource) {
          var tabList = []
          switch (vm.selectionMode) {
              case 'table':
                tabList = vm.TABS_SELECTION_TABLE
                break
              case 'card':
              case 'pulldown':
                tabList = vm.TABS_SELECTION_CARD
                break
          }
          // if (vm.isRelatedMultiple) {
          //   switch (vm.multipleDisplayFormat) {
          //   }
          //   // alert('isRelatedMultiple')
          //   // tabList = vm.TABS_MULTIPLE_RELATED_RECORD
          //   // [
          //   //   TABS.DISPLAY_TABLE,
          //   //   TABS.POPUP_MULTIPLE_SELECTION_TABLE,
          //   //   TABS.SELECTION_FILTERS,
          //   //   // TABS_DISPLAY_FILTERS,
          //   //   // TABS.CUSTOM_BUTTONS,
          //   //   TABS.SORTING
          //   // ]
          // } else {
          //   // to single record
          //   if (vm.displayFormat === 'card') {
          //     if (vm.cardSelectionMode === 'table') {
          //       alert('CARD_SELECTION_TABLE')
          //       tabList = vm.TABS_SELECTION_TABLE
          //     } else {
          //       alert('CARD_SELECTION_CARD')
          //       tabList = vm.TABS_SELECTION_CARD
          //     }
          //   } else { // vm.displayFormat === 'pulldown'
          //     if (vm.pulldownSelectionMode !== 'simple') {
          //        tabList = vm.TABS_SELECTION_CARD
          //     }
          //     // if (vm.selectionMode === 'popup') {
          //     //   tabList = vm.TABS_SELECTION_TABLE
          //     // } else {
          //     //   tabList = vm.TABS_SELECTION_CARD
          //     // }
          //   }
          // }

          var dataSourceInfo = null
          console.log('WitRelatedRecordDisplayFields :: vm.widget: ', vm.widget)
          if (vm.isParentChildRelation) {
            dataSourceInfo = {
              fieldInfos: vm.formFieldInfos,
              titleFieldInfoId: vm.formInfo.titleFieldInfoId
            }
          }

          this.$refs.relatedRecordSelectionSetupDialog.open(
            vm.propertyValue,
            vm.dataSource,
            tabList,
            dataSourceInfo

            /* control display of different tabs in dialog */
            /* For single related record: for selection display only */
            /* For multiple related record: for display in form */

            /*
            tabCardDisplayFields
            tabCustomButtons
            tabFilters
            tabSorting
            tabTableDisplayWithFreezing
            tabTableDisplayWithoutFreezing
             */
          )
        } else {
          vm.$alert(
            vm.$t('messages.pleaseSpecifyDataSourceFirst'),
            vm.$t('general.warning'),
            'warning',
            {
              confirmButtonText: vm.$t('buttons.ok'),
              cancelButtonText: vm.$t('buttons.cancel')
            }
          )
        }
      },
      confirmHandler (newPropertyValue) {
        // console.log('WidgetInputTypeRelatedRecordDisplayFields :: confirmHandler: newPropertyValue: ', newPropertyValue)
        this.propertyValue = newPropertyValue
      }
    }
  }
</script>
