<template>
    <div>
        <v-layout align-start justify-space-between>
            <div :class="{ 'workflow-setting-condition-select-root': rule!='empty' && rule!='notEmpty' }">
                <ComboBox
                    :value="rule || null"
                    :options="rules.options"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    :renderLabel="renderConditionLabel"
                    @changed="v => rule = v"
                />
            </div>
            <div class="workflow-setting-columnType-select-root" v-if="rule!='empty' && rule!='notEmpty'">
                <ComboBox
                    :value="type || null"
                    :options="types"
                    :placeholder="$t('messages.pleaseChoose', [])"
                    @changed="v => type = v"
                />
            </div>
        </v-layout>
        <div v-if="rule!='isEmpty' && rule!='notEmpty'">
            <v-input
                @click="showingDialog=true"
                ref="ruleMembers"
                :value="value"
                class="workflow-setting-fake-input-root"
                v-if="content.type==1"
            >
                <div v-if="content.value && content.value.length">
                    <selected-department-tag
                        v-for="(departmentInfo,index) in content.value"
                        :key="departmentInfo._id"
                        :index="index"
                        :hasCloseButton="true"
                        :departmentId="departmentInfo._id"
                        :name="departmentInfo.label"
                        deleteCommand="removeDepartmentByIndex"
                        clickCommand="removeDepartmentByIndex"
                        @onCommand="onCommandHandler($event, departmentInfo)"
                    ></selected-department-tag>
                </div>
                <div v-else class="w-placeholder">{{ $t('messages.pleaseChoose', []) }}</div>
            </v-input>
            <div v-else class="workflow-setting-columnValue-select-root">
                <RuleSelectField
                    :value="value"
                    :comboxProps="comboxProps"
                    :dependsOnType="fieldType"
                    :formFieldInfos="formFieldInfos"
                    @changed="v => value = v"
                />
            </div>
        </div>
        <DepartmentsDialog
            v-if="showingDialog"
            v-model="showingDialog"
            @confirm="value = $event"
            :selectedDepts="value"
            :isMultiple="true"
        />
    <!-- <div class=" d-flex mt-1">
        <div style="width: 120px; min-width:120px">
            <v-select
                v-model="rule"
                class="vselect-small"
                style="font-size:12px"
                height="30"
                dense
                outlined
                :items="rules.options"
                :menu-props="{offsetY: true}"
                hide-details
            >
                <template v-slot:item="{item}">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
                <template v-slot:selection="{item}">
                    {{$t('filters.' + criteriaConfigs[item].t)}}
                </template>
            </v-select>
        </div>
        <div style="width:120px; min-width:120px" class="ml-2" v-if="rule!='empty' && rule!='notEmpty'">
            <v-select
                v-model="type"
                class="vselect-small"
                height="30"
                style="font-size:12px"
                dense
                outlined
                :items="types"
                item-text="name"
                item-value="id"
                :menu-props="{offsetY: true}"
                single-line
                hide-details
            />
        </div>

        <div v-if="rule!='isEmpty' && rule!='notEmpty'" style="width:100%" class="flex-grow-1">    
            <v-input
                @click="showingDialog=true"
                ref="ruleMembers"
                :value="value"
                class="ml-2"
                v-if="type==1"
                hide-details
                style="overflow: hidden; white-space: nowrap;max-height:400px"
            >
                <div class="d-flex flex-wrap align-start pa-1 v-text-field--outlined w-100 mb-0" style="border: 1px solid darkgray;min-height: 30px; max-height:400px; overflow: hidden; ">
                    <selected-department-tag
                        style=""
                        v-for="(departmentInfo,index) in value"
                        :key="departmentInfo._id"
                        :index="index"
                        :hasCloseButton="true"
                        :departmentId="departmentInfo._id"
                        :name="departmentInfo.label"
                        deleteCommand="removeDepartmentByIndex"
                        clickCommand="removeDepartmentByIndex"
                        @onCommand="onCommandHandler($event, departmentInfo)"
                        color="light"
                    ></selected-department-tag>
                </div>
            </v-input>
            <RuleSelectField v-else :value="value" :formFieldInfos="formFieldInfos" @input="val=>value=val" class="ml-2"/>
            <DepartmentsDialog
            v-if="showingDialog"
            v-model="showingDialog"
            @confirm="value = $event"
            :selectedDepts="value"
            :isMultiple="true"
            />
        </div> -->
    </div>
</template>

<script>
import selectedDepartmentTag from "@/components/dialogs/comps/SelectedDepartmentTag";
import RuleSelectField from '../units/RuleSelectField'
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import DepartmentsDialog from "@/components/dialogs/DepartmentsDialog";
import mixin from './mixin'

export default { 
    mixins: [mixin],
    components:{
        selectedDepartmentTag,
        DepartmentsDialog,
        RuleSelectField,
        ComboBox,
    },
    computed:{
        items(){
            return []
        }
    },
    data(){
        return {
            showingDialog: false
        }
    },
    methods:{
        onSubmit(event){
            this.value = event
        },
        onCommandHandler(payload, row) {
            // console.log(payload,row)
            switch (payload.command) {
                case "removeDepartmentByIndex":
                    this.value.splice(payload.index, 1)
                break;
            }
        }
    },
}
</script>

<style>
/* .v-chip.v-size--default */
.rule-selection-opts .v-chip {
    height: unset !important;
}
.w-spacing {
    margin: 5px 0;
}
</style>