<!--FIXME: To be implemented-->
<template>
<div class="ml-1">
    <v-btn
        x-small
        style="height:24px;width:24px;"
        icon
        fab
        @click="showingDialog=true">
      <v-icon>mdi-tools</v-icon>
    </v-btn>
  <v-dialog
      v-model="showingDialog"
      v-if="showingDialog"
      scrollable
      max-width="480px">
    <v-card>
      <v-card-title>{{ $t('modules.moduleSetting') }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height:480px;">
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="gray darken-1"
            text
            class="min-width-100"
            @click="showingDialog=false">Cancel</v-btn>
        <v-btn
            primary
            text
            class="min-width-100"
            @click="confirm">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import VCardApp from "../../pages/myapps/comps/VCardApp"
import appStyleMixin from '@/mixins/appStyleMixin'

export default {
  mixins: [appStyleMixin],
  components: {VCardApp},
  data () {
    return {
      showingDialog: false
    }
  },
  props: {
    app: Object
  },
  methods: {
    confirm () {
      const vm = this
      vm.showingDialog = false
    }
  }
}
</script>
