<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions style="height:30px; min-height:30px">
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.columnSummary')}}
            </div>
        </v-expansion-panel-header>
        <!-- <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
            hide-details
            @change="$emit('onCommandHandler',{
                command: 'update_bl_summary',
                value: $event
            })"
            v-model="bl_summary"
        ></v-switch> -->
        <v-expansion-panel-content>
            <!-- {{tableFooters}} -->
            <div class="">
                <div class="d-flex px-2 mb-1">
                    <v-btn outlined @click="addFooter" plain icon small class="" color="teal">
                        <v-icon small>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="d-flex flex-column">
                    <draggable
                        class="list-group"
                        tag="ul"
                        v-model="setup.tableFooters"
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = false"
                        handle=".ChartTableFooterSetupHandle"
                        @change="$emit('onCommandHandler',{
                            command: 'update_tableFooters',
                            value: setup.tableFooters
                        })"
                    >
                    <!-- @change="$emit('update_tableFooters', 'test')" -->
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <div class="list-group-item" v-for="(footer,index) in setup.tableFooters" :key="footer.order">
                               <v-hover v-slot="{hover}">
                                    <div class="d-flex py-1">
                                        <div class="ChartTableFooterSetupHandle">
                                            <v-icon>
                                                mdi-drag
                                            </v-icon>
                                        </div>
                                        <v-text-field
                                            hide-details
                                            class="footerSummarySetup"
                                            style="font-size:13px"
                                            height="25"
                                            v-model="footer.name"
                                            @change="$emit('onCommandHandler',{
                                                command: 'update_tableFooters',
                                                value: setup.tableFooters
                                            })"
                                            outlined
                                        >
                                        </v-text-field>
                                        <v-select
                                            style="width:80px; font-size:13px"
                                            outlined
                                            :items="calMethods"
                                            item-text="label"
                                            :menu-props="{offsetY: true, contentClass:'denseList'}"
                                            height="25"
                                            v-model="footer.value"
                                            hide-details
                                            @change="$emit('onCommandHandler',{
                                                command: 'update_tableFooters',
                                                value: setup.tableFooters
                                            })"
                                        >
                                        </v-select>
                                        <div style="width:20px">
                                            <v-icon @click="deleteFooter(index)" v-if="hover" color="red" size="20">
                                                mdi-close
                                            </v-icon>
                                        </div>
                                    </div>
                                </v-hover>
                            </div>
                        </transition-group>
                    </draggable>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import draggable from "vuedraggable";
    export default {
        name: 'ChartTableFooterSetup',
        props:{
            setup: Object,
            bl_summary: Boolean
        },
        components: {
            draggable
        },
        data(){
            return{
                drag: false,
                calMethods:[
                    {
                        id: 1,
                        label: 'sum',
                        value: 'SUM'
                    },
                    {
                        id: 2,
                        label: 'max',
                        value: 'MAX'
                    },
                    {
                        id: 3,
                        label: 'min',
                        value: 'MIN'
                    }, 
                    {
                        id: 4,
                        label: 'avg',
                        value: 'AVG'
                    }
                ],
            }
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
        },
        methods:{
            addFooter(){
                let vm = this
                vm.setup.tableFooters.push({
                    order: vm.setup.tableFooters.length + 1,
                    name: '',
                    value: 'SUM',
                })
                vm.$emit('onCommandHandler',{command: 'update_tableFooters',value: vm.setup.tableFooters})
            },
            deleteFooter(index){
                let vm = this
                vm.setup.tableFooters.splice(index, 1)
                vm.$emit('onCommandHandler',{command: 'update_tableFooters',value: vm.setup.tableFooters})
            }
        }
    }
    
</script>

<style scoped>
    .footerSummarySetup{
        margin-top: 0px!important;
        padding-top: 0px !important;
    }
    .button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>