<template>
  <div class="workflow-notification-content-root">
    <v-btn
      icon
      class="notification-content-close-icon"
      @click="close"
    >
      <v-icon small>
        mdi-close-thick
      </v-icon>
    </v-btn>
    <v-layout justify-space-between align-center  >
      <Lottie
        :options="defaultOptions"
        class="notification-content-icon"
        :height="100"
        :width="85"
      />
      <div class="notification-content-root">
        <div
          class="notification-content-title"
          v-text="$t('notification.customAction.batchTriggerTitle')"
        />
        <div
          class="notification-content-remark"
          v-text="$t('notification.customAction.batchTriggerRemark')"
        />
        <div class="notification-content-view-more">
          <v-btn
            text
            small
            @click="checkMore"
            color="primary"
          >
            {{ $t('notification.customAction.checkMore') }}
          </v-btn>
        </div>
      </div>
    </v-layout>
  </div>
</template>

<script>
import ProcessingAnimation from '@/pages/admin/workflow/assets/batch_process.json';
import Lottie from 'vue-lottie';
import {
  SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION,
  SHOW_AUTOMATETASK_POPUP,
} from '@/store/modules/notification/action_types';
export default {
  props: {
    messageId: Number,
  },
  data(){
    return {
      defaultOptions: {
        animationData: ProcessingAnimation,
      },
    }
  },
  // computed: {
  //   popupShow() {
  //     return this.$store.getters.shouldShowAutomatedTaskPopup;
  //   },
  //   // click from which notification box 
  //   sourcePopFromId() {
  //     return this.$store.getters.automatedTaskPopupTargetId;
  //   },
  // },
  methods: {
    close() {
      this.flashMessage.deleteMessage(this.messageId);
      this.$store.dispatch(SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION, false);
    },
    checkMore() {
      this.$store.dispatch(SHOW_AUTOMATETASK_POPUP, {
        show: true,
      });
      // this.close();
    },
  },
  // watch: {
  //   popupShow: function(toShow) {
  //     const { messageId, sourcePopFromId } = this;
  //     // it the same notification clicked from
  //     if (!toShow && messageId && sourcePopFromId && sourcePopFromId === messageId) {
  //       // hide the popup
  //       this.close();
  //     }
  //   }
  // },
  components: {
    Lottie,
  },
};

</script>

<style scoped lang="scss">
  .workflow-notification-content-root {
    padding: 0 ;
    position: relative;
    .notification-content-title {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
      color: #666;
      flex: 1;
      line-height: 24px;
      margin: 0 3px 0 10px;
      padding-right: 10px;
    }
    .notification-content-remark {
      font-size: 11px;
      margin: 5px 3px 5px 10px;
      color: #666;
    }
    .notification-content-root {
      padding: 10px 10px 10px 0;
    }
    .notification-content-icon {
      background:#f1f1f1;
      margin-right: 10px;
    }
    .notification-content-close-icon {
      // margin-top: -6px;
      // margin-right: -12px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .notification-content-view-more {
      justify-content: flex-end;
      display: flex;
      margin-top: 0;
    }
  }
</style>