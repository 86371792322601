<template>
  <v-form ref="form"
        class="d-flex flex-column fill-height"
        v-if="formLayout"
        @submit="onSubmit"
  >
  <!--<div class="headline my-2"-->
       <!--style="border-bottom:1px solid rgba(255,255,255,100);">-->
    <!--{{ recordTitle }}-->
  <!--</div>-->

  <!-- RECORD TITLE -->
  <div class="px-0 mb-2">
    <div class="row py-0">
      <div class="col pb-0">
        <div
          class="headline mt-2 mb-3 px-2"
          style="border-bottom:1px solid rgba(255,255,255,100);"
        >
          <field-string
            :fieldInfo="titleFieldInfo"
            :recordData="recordData"
          ></field-string>
        </div>
      </div>
    </div>
  </div>

  <module-tab-bar
      v-if="topPagingTableFieldInfos.length>0"
      class="rounded"
      v-model="topPagingIndex"
      type="topPagingTab"
      labelField="label"
      :hideButtons="true"
      :availableTabs="topPagingTabs"
      @onCommand="onCommandHandler"></module-tab-bar>
  <div class="d-flex flex-column flex-grow-1 fill-height">
    <splitpanes v-if="topPagingIndex===0"
                horizontal
                class="default-theme flex-grow-1 fill-height"
                style="overflow-y:auto;height:0;width:100%;">
      <pane class="white d-flex flex-column"
            size="60"
            min-size="20">
        <perfect-scrollbar ref="perfectScrollbar" class="perfect-scrollbar flex-grow-1 d-flex flex-column pr-3">
          <!--<v-container class="grey lighten-5">-->
            <!--<v-row no-gutters>-->
              <!--<v-col-->
                  <!--v-for="n in 3"-->
                  <!--:key="n"-->
                  <!--cols="12"-->
                  <!--sm="4"-->
              <!--&gt;-->
                <!--<v-card-->
                    <!--class="pa-2"-->
                    <!--outlined-->
                    <!--tile-->
                <!--&gt;-->
                  <!--One of three columns-->
                <!--</v-card>-->
              <!--</v-col>-->
            <!--</v-row>-->
          <!--</v-container>-->

          <v-container fluid class="px-0 pt-0" v-if="formLayout && (formLayout.rows.length > 0)">
            <v-row v-for="(row, index) in formLayout.rows" :key="index" no-gutters>
              <template v-for="(fieldInfo) in getFieldInfosInRow(row)">
  <!--<input-widget-related-multiple-records
                  :key="fieldInfo.key"
                    v-if="fieldInfo.type==='relatedMultipleRecords'"
                    :ref="fieldInfo.fieldId"
                    :value="recordData[fieldInfo.fieldId]"
                    :recordData="recordData"
                    :fieldInfo="fieldInfo"
                    @onCommand="onCommandHandler"
                    @input="value=>updateValue(value)"></input-widget-related-multiple-records> -->
                            <!-- v-else -->
                  <input-widget
                    :ref="fieldInfo.fieldId"
                    :key="fieldInfo._id"
                    :isNew="isNew"
                    :recordData="recordData"
                    :relatedTableData="relatedTableData"
                    :relatedTableInfos="relatedTableInfos"
                    :formInfo="formInfo"
                    :fieldInfo="fieldInfo"
                    @onCommand="onCommandHandler"></input-widget>
              </template>
            </v-row>
          </v-container>
        </perfect-scrollbar>
      </pane>
      <pane v-if="embeddedPagingTableFieldInfos.length>0"
            class="d-flex flex-column fill-height"
            size="40"
            min-size="20">
        <div
            class="drop-container px-0"
            style="background-color:white;position:relative;">
              <module-tab-bar
                  style="height:24px;"
                  class="flex-grow-0 rounded"
                  v-model="embeddedPagingIndex"
                  labelField="label"
                  type="embeddedPagingTab"
                  :hideButtons="true"
                  :availableTabs="embeddedPagingTabs"
                  @onCommand="onCommandHandler"></module-tab-bar>

              <div v-for="(fieldInfo, index) in embeddedPagingTableFieldInfos"
                  :key="fieldInfo._id"
                  v-if="embeddedPagingIndex===index"
                  class="fill-height py-3">
                <!-- <inputWidgetRelatedMultipleRecords
                    v-if="componentName==='inputWidgetRelatedMultipleRecords'"
                    ref="inputWidget"
                    :value="recordData[fieldInfo.fieldId]"
                    :recordData="recordData"
                    :formInfo="formInfo"
                    :fieldInfo="fieldInfo"
                    @onCommand="onCommandHandler"
                    @input="value=>updateValue(value)"></inputWidgetRelatedMultipleRecords> -->

                    <input-widget-related-record
                          :ref="fieldInfo.fieldId"
                          :isNew="isNew"
                          :fieldInfo="fieldInfo"
                          :recordData="recordData"
                          :relatedTableData="relatedTableData"
                          :relatedTableInfos="relatedTableInfos"
                          :value="recordData[fieldInfo.fieldId]"
                          :isReadonly="getIsReadOnly(fieldInfo)"
                          :activeForm="formInfo"
                          @onCommand="onCommandHandler"
                          @input="
                            (val) =>
                              updateFieldValue({
                                fieldName: fieldInfo.fieldId,
                                fieldValue: val,
                              })
                          "
                        ></input-widget-related-record>

                <!--<input-widget-->
                <!--class="fill-height"-->
                <!--:ref="fieldInfo.fieldId"-->
                <!--:key="fieldInfo._id"-->
                <!--:data="data"-->
                <!--:fieldInfo="fieldInfo"-->
                <!--@onCommand="onCommandHandler"></input-widget>-->
              </div>      
        </div>
      </pane>
    </splitpanes>

     <div v-for="(fieldInfo,index) in topPagingTableFieldInfos"
        v-if="topPagingIndex===index+1"
        :key="fieldInfo._id"
        class="d-flex flex-column fill-height py-2">
        <perfect-scrollbar
          class="flex-grow-1 d-flex flex-column pb-12"
          style="overflow-y:scroll;height:0;min-height:100%;">
          <div class="mr-3 mb-3 px-0">
            <input-widget-related-record
                :ref="fieldInfo.fieldId"                        
                :isNew="isNew"
                :recordData="recordData"
                :relatedTableData="relatedTableData"
                :relatedTableInfos="relatedTableInfos"
                :fieldInfo="fieldInfo"
                :activeForm="formInfo"
                :value="recordData[fieldInfo.fieldId]"
                :isReadonly="getIsReadOnly(fieldInfo)"
                @onCommand="onCommandHandler"
                @input="val=>updateFieldValue({fieldName:fieldInfo.fieldId,fieldValue:val})"></input-widget-related-record>
          </div>
        </perfect-scrollbar>
      </div>
      <members-menu
        :fullWidth="true"
        v-if="showingMembersMenu"
        v-model="showingMembersMenu"
        :attachId="membersMenuAttachId"
        ref="membersMenu"
        @memberClick="handleMemberClick"
      />
  <!-- <record-dialog ref="recordDialog"></record-dialog> -->
  </div>
</v-form>
</template>

<script>
import relatedTableLayoutMixin from '@/mixins/relatedTableLayoutMixin'
import recordMixin from '@/mixins/recordMixin'

import inputWidget from '@/components/inputWidgets/InputWidget'
import inputWidgetRelatedRecord from "@/components/inputWidgets/InputWidgetRelatedRecord";

// import inputWidgetRelatedMultipleRecords from '@/components/inputWidgets/InputWidgetRelatedMultipleRecords'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'


import {getFieldPropertyValue} from '@/helpers/ObjectHelpers'
import fieldString from '@/components/fieldStrings/Fs'
import moduleTabBar from '@/components/ModuleTabBar'
import {ValidateRule} from "@/helpers/RuleHelper" 
import membersMenu from '@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu'
// import recordDialog from '@/components/dialogs/RecordDialog'

export default {
  name: 'popupRecordForm',
  mixins: [
    relatedTableLayoutMixin,
    recordMixin
  ],
  components: {
    fieldString,
    inputWidget,
    inputWidgetRelatedRecord,
    Splitpanes,
    Pane,
    moduleTabBar,
    membersMenu
    // ,
    // recordDialog
  },
  // components: {
  //   relatedTabContent
  // },
  data () {
    return {
      showRecordDialog: false,
      topPagingIndex: 0, // if no top related tables, it is always 0
      embeddedPagingIndex: 0,
      showingMembersMenu: false,
      membersMenuAttachId: null
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    formInfo: Object,
    value: Object,
    relatedTableData: Object,
    relatedTableInfos: {},
    isNew: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    recordData:{
      handler(){
        // console.log(this.recordData)
        let vm = this
        if(this.displayRule)
          this.displayRule.filter(item=>item.active).forEach(rule=>{
            let bl_pass = false
            var conditions = rule.conditions
            // check conditions
            bl_pass = conditions.some(orConds=>{
              var res = orConds.every(andCond=>{
                var hostField = vm.recordData[andCond.fieldId]
                var compareField = (andCond.content.type==1)? andCond.content.value:vm.recordData[andCond.content.value]
                // console.log(ValidateRule(hostField, compareField, andCond.content.rule, andCond.fieldType))            
                return ValidateRule(hostField, compareField, andCond.content.rule, andCond.fieldType)
              })
              if(res) return true
            })
            // action implement if rule return true
            rule.actions.forEach(action=>{
              switch(action.actionId){
                case "1":
                  vm.handleActionVisible(action.fields, bl_pass)
                  break 
                case "2":
                  vm.handleActionHidden(action.fields, bl_pass)
                  break
                case "3":
                  vm.handleActionAlert(action.fields, bl_pass)
                  break
              }
            })
          })

      },
      deep: true
    }
  },
  computed: {
    recordData: {
      get () {
        const vm = this
        return vm.value
      },
      set(val) {
        // console.log('computed(recordData) val: ', val)
        this.$emit('input', val)
      }
    },

    // relatedTableInfos () {
    //   return this.$store.getters.relatedTableInfos
    // },


    currentForm () {
      return this.formInfo
    },

    appId () {
      return this.currentForm ? this.currentForm.appId : ''
    },

    formId () {
      return this.currentForm ? this.currentForm._id : ''
    },

    fieldInfos () {
      return this.currentForm ? this.currentForm.fieldInfos : []
    },
    uiElementInfos() {
      return this.currentForm ? this.currentForm.uiElementInfos : [];
    },
    formLayout () {
      return this.currentForm ? this.currentForm.layout : null
    },

    canEditRecord() {
      return this.recordData.canEdit || false;
    },
    ///////////////////////////

    formData () {
      const vm = this
      const SYSTEM_FIELD_IDS = [
        'owner',
        'createdBy',
        'createdAt',
        'updatedAt'
      ]
      var result = {}
      if (vm.recordData) {
        // exclude _id
        const fieldIds = Object.keys(vm.recordData).filter(
          d => d.indexOf('_') < 0)

        for (var i = 0; i < fieldIds.length; i++) {
          const loopFieldId = fieldIds[i]
          if (!SYSTEM_FIELD_IDS.includes(loopFieldId)) {
            result[loopFieldId] = vm.recordData[loopFieldId]
          }
        }
      }
      return result
    },
    // relatedFormIds() {},

    titleFieldInfoId () {
      return this.formInfo ? this.formInfo.titleFieldInfoId : ''
    },

    titleFieldInfo () {
      return this.titleFieldInfoId ? 
      this.formInfo.fieldInfos.find(info => info._id === this.titleFieldInfoId) :
      null
    },

    recordTitle () {
      const vm = this
      var result = ''
      const fieldInfoId = vm.formInfo.titleFieldInfoId
      const fieldInfo = vm.getFieldInfoById(vm.fieldInfos, fieldInfoId)
      if (fieldInfo) {
        result = '{' + fieldInfo.label + '}'
        const fieldId = fieldInfo['fieldId']
        if (vm.recordData[fieldId] && !(vm.recordData[fieldId] instanceof Object)) {
          if (vm.recordData[fieldId] !== '') {
            result = vm.recordData[fieldId]
          }
        }
      } else {
        result = '(' + vm.$t('messages.titleFieldNotSpecified') + ')'
      }
      return result
    },

    formInfoIdFieldIdMapping () {
      const vm = this
      var result = {}
      for (var i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i]
        result[loopFieldInfo._id] = loopFieldInfo.fieldId
      }
      return result
    },
    displayRule(){
      return this.currentForm.displayRule
    },
  },

  methods: {
    handleMemberClick(val){
      if(this.$refs.membersMenu.fieldId){
        var fieldId = this.$refs.membersMenu.fieldId
        var type = this.$refs.membersMenu.type
        var pos = this.recordData[fieldId].findIndex(item=>item._id == val._id)
        if(pos!=-1){
          this.recordData[fieldId].splice(pos,1)
        }else{
          if(type=='multiple')
            this.recordData[fieldId].push(val)
          else
            this.recordData[fieldId] = [val]
        }
      }
    },
    openMembersMenu(attachedEle, fieldInfo){
      let vm = this
      vm.membersMenuAttachId = attachedEle.id
      vm.showingMembersMenu = true
      vm.$nextTick(()=>{
        vm.$refs.membersMenu.type= fieldInfo.properties.fieldType
        vm.$refs.membersMenu.selectedValue= vm.recordData[fieldInfo.fieldId]
        vm.$refs.membersMenu.fieldId= fieldInfo.fieldId
        attachedEle.id = ""
      })
    },
    updateFieldValue(payload) {
      const vm = this;
      console.log('Record: updateFieldValue: payload: ', payload)
      console.log('Record: vm.recordData: ', vm.recordData)
      vm.$set(vm.recordData, payload.fieldName, payload.fieldValue);
      console.log('Record: vm.recordData: ', vm.recordData)
    },
    getIsReadOnly (fieldInfo) {
      const vm = this
      return !vm.isNew && (!vm.recordData.canEdit || !fieldInfo.canEdit)
    },

    newChildRecord () {
      // alert('RecordForm :: newChildRecordf')
    },
    scrollToTop () {
      this.$refs.perfectScrollbar.$el.scrollTop = 0
    },

    // resetValidation () {
    //   this.$refs.form.resetValidation()
    // },
    async validateAll () {
      const vm = this
      const fieldIds = Object.keys(vm.formData)
      var errors = 0
      let promises = [];
      fieldIds.forEach(f => {
        if (vm.$refs[f] && vm.$refs[f][0]){
          promises.push(() => {
            const validationResult = vm.$refs[f][0].validate()
            return validationResult
          })
        }
      })

      const arrayOfPromises = promises.map((promise) => promise());
      const results = await Promise.all(arrayOfPromises);
      errors = results.filter((result) => !result).length;
      vm.errors = errors
      return errors
    },

    newRelatedRecord (payload) {
      this.$emit('onCommand', {
        command: 'newRelatedRecord',
        payload: payload
      })
      // this.$refs.recordDialog.open(payload);
      // alert('newRelatedRecord')
    },

    editRelatedRecord (payload) {
      this.$emit('onCommand', {
        command: 'editRelatedRecord',
        payload: payload
      })
    },

    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'newRelatedRecord':
          vm.newRelatedRecord(payload)
          break
        // case 'updateFieldValue':
        //   vm.$set(vm.recordData, payload.fieldName, payload.fieldValue)
        //   break
        case 'editRelatedRecord':
          vm.editRelatedRecord(payload)
          break
        case 'unlinkRelatedRecord':
          vm.unlinkRelatedRecord(payload);
          break
        case "memberInputClick":
          if(!this.showingMembersMenu){
            vm.openMembersMenu(payload.attachedEle, payload.fieldInfo)
          }
        default:
          console.log('PopupRecordForm : onCommandHandler :; default: payload: ', payload)
          vm.$emit('onCommand', payload)
          break
      }
      // this.$emit('onCommand', payload)
    },

    unlinkRelatedRecord(payload) {
      const vm = this;
      // Notes:
      // payload: {
      //    data: {remote data record},
      //    fieldId: {local field id}
      // }
      const childData = payload.data
      const fieldId = payload.fieldId
      if (vm.recordData[fieldId] instanceof Array) {
        const idIndex = vm.recordData[fieldId].findIndex(id => id === childData._id)
        if (idIndex >= 0) {
          vm.recordData[fieldId] .splice(idIndex, 1)
        }
        // vm.recordData[fieldId].push(payload.row);
      } else {
        vm.recordData[fieldId] = []
      }
    },

    itemWidth (fieldInfo) {
      return getFieldPropertyValue(fieldInfo, 'width', '12')
    },
    getFieldInfosInRow (row) {
      const vm = this
      var result = []
      for (let i = 0; i < row.length; i++) {
        const loopId = row[i]._id
        const fieldInfo = vm.fieldInfos.find(info => info._id === loopId)
        if (fieldInfo)
        result.push(fieldInfo)
      }
      //  console.log('RecordForm :: getFieldInfosInRow :: result: ', result)
      return result
    },

    onSubmit () {
      // alert('onSubmit')
    },
    getFieldId (fieldInfoId) {
      const vm = this
      // console.log('Record.vue :: getFieldId :: fieldInfoId = ' + fieldInfoId)
      return vm.formInfoIdFieldIdMapping[fieldInfoId]
    },
    getFieldInfoById (fieldInfos, fieldInfoId) {
      var result = null
      if (fieldInfos) {
        result = fieldInfos.find(info => info._id === fieldInfoId)
      }
      return result
    },
    getFieldIdByLabel (fieldLabel) {
      const vm = this
      var result = null
      if (vm.fieldInfos) {
        const fieldInfo = vm.fieldInfos.find(info => info.label === fieldLabel)
        if (fieldInfo) {
          result = fieldInfo.fieldId
        }
      }
      return result
    },
    handleActionVisible(fields, bl_pass){
      let vm = this
      fields.forEach(fieldId=>{
        var res = vm.fieldInfos.concat(vm.uiElementInfos).find(field=> field._id == fieldId)
        if (res){
          var authorization = []
          if(res.properties.authorization)
            authorization = res.properties.authorization.split("||")
          if(authorization.length == 1 && authorization[0] == "") authorization = []
          if(bl_pass){
            var pos = authorization.indexOf('hidden')
            if(pos!=-1){
              authorization.splice(pos,1)
            }
          }else{
            if(!authorization.includes('hidden'))
              authorization.push('hidden')
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, 'authorization', authorization.join("||"))
        }
      })
    },
    handleActionHidden(fields, bl_pass){
      let vm = this
      fields.forEach(fieldId=>{

        console.log(fieldId)
        var res = vm.fieldInfos.concat(vm.uiElementInfos).find(field=> field._id == fieldId)
        if (res){
          var authorization = []
          if(res.properties.authorization)
            authorization = res.properties.authorization.split("||")
          // var authorization = res.properties.authorization.split("||")
          if(authorization.length == 1 && authorization[0] == "") authorization = []
          if(bl_pass){
            if(!authorization.includes('hidden'))
              authorization.push('hidden')
          }else{
            var pos = authorization.indexOf('hidden')
            if(pos!=-1){
              authorization.splice(pos,1)
            }
          }
          // res.properties.authorization = authorization.join("||")
          this.$set(res.properties, 'authorization', authorization.join("||"))
        }
      })
    },
    handleActionAlert(fields){
      console.log('handleActionAlert')
      console.log(fields)
    },
  }
}
</script>
