<template>
  <!-- just using number template temporary -->
  <vxe-table-column v-bind="colDef"
    class="text-right" sortable>
    <template v-slot="{row}">
      <!-- {{calculate(row)}} -->
        <span>{{row[fieldId]}}</span>
      <!--  -->
    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from './mixin'
import expressionMixin from '@/mixins/expressionMixin'
import summaryCell from './comps/SummaryCell'

export default {
  name: 'vxeCellCapitalizedAmount',
  data(){
    return {
        combinedText: ''
    }
  },
  mixins: [mixin,expressionMixin],
  components: {
    summaryCell
  }
}

</script>
