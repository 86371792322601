const appColorMixin = {
  data () {
    return {
      BASIC_COLORS: [
        '#B71C1CFF',
        '#4A148CFF',
        '#0D47A1FF',
        '#006064FF',
        '#1B5E20FF',
        '#827717FF',
        '#F57F17FF',
        '#E65100FF',

        '#FFCDD2FF',
        '#E1BEE7FF',
        '#BBDEFBFF',
        '#B2DFDBFF',
        '#F0F4C3FF',
        '#FFECB3FF',
        '#D7CCC8FF',
        '#CFD8DCFF'
      ],
      TEXT_THEMES: {
        dark: [
          '#B71C1CFF','#880E4FFF','#4A148CFF','#311B92FF','#1A237EFF','#0D47A1FF','#01579BFF','#006064FF','#004D40FF','#1B5E20FF',
          '#D32F2FFF','#C2185BFF','#7B1FA2FF','#512DA8FF','#303F9FFF','#1976D2FF','#0288D1FF','#0097A7FF','#00796BFF','#388E3CFF',
          '#F44336FF','#E91E63FF','#9C27B0FF','#673AB7FF','#3F51B5FF','#2196F3FF','#03A9F4FF','#00BCD4FF','#009688FF','#4CAF50FF',
          '#33691EFF','#827717FF','#BA68C8FF','#FF6F00FF','#E65100FF','#BF360CFF','#3E2723FF','#263238FF','#000000FF',
          '#689F38FF','#AFB42BFF','#F57F17FF','#FFA000FF','#F57C00FF','#E64A19FF','#5D4037FF','#455A64FF',
          '#8BC34AFF',            '#FBC02DFF','#FFC107FF','#FF9800FF','#FF5722FF','#795548FF','#607D8BFF'
        ],
        light: [
          '#E57373FF','#F06292FF',            '#9575CDFF','#7986CBFF','#64B5F6FF','#4FC3F7FF','#4DD0E1FF','#4DB6ACFF','#81C784FF',
          '#FFCDD2FF','#F8BBD0FF','#E1BEE7FF','#D1C4E9FF','#C5CAE9FF','#BBDEFBFF','#B3E5FCFF','#B2EBF2FF','#B2DFDBFF','#C8E6C9FF',
                      '#CDDC39FF','#FFEB3BFF',
          '#AED581FF','#DCE775FF','#FFF176FF','#FFD54FFF','#FFB74DFF','#FF8A65FF','#A1887FFF','#90A4AEFF','#FFFFFFFF',
          '#DCEDC8FF','#F0F4C3FF','#FFF9C4FF','#FFECB3FF','#FFE0B2FF','#FFCCBCFF','#D7CCC8FF','#CFD8DCFF'
        ]
      }
    }
  },
  computed: {
    darkThemeColors () {
      return this.TEXT_THEMES.dark
    },
    lightThemeColors () {
      return this.TEXT_THEMES.light
    },
    allColors () {
      return [...this.TEXT_THEMES['dark'], ...this.TEXT_THEMES['light']]
    },
    isColorClass () {
      const vm = this
      var result = ''
      if (vm.color) {
        result = vm.color.indexOf('-') >= 0
      }
      return result
    },
    colorClass () {
      const vm = this
      var result = ''
      if (vm.isColorClass) {
        result = vm.color
      }
      if (vm.textTheme) {
        result += ' ' + (vm.textTheme === 'dark' ? 'white--text' : 'black--text')
      }
      return result
    },
    colorStyle () {
      const vm = this
      var result = {}
      if (!vm.isColorClass && vm.color) {
        result['background-color'] = vm.color
      }
      return result
    }
  },
  methods: {
    getRandomColorAndTheme() {
      const color = this.getRandomColor()
      const textTheme = this.getTextTheme(color)
      return {color, textTheme}
    },

    getRandomColor () {
      const vm = this
      const index = Math.round(Math.random()*vm.allColors.length+0.5)-1
      console.log('index = ' + index)
      return vm.allColors[index]
    },

    getTextColor (colorHex) {
      return this.getTextTheme(colorHex) === 'dark' ? 'white' : 'black'
    },

    getTextTheme (colorHex) {
      return this.darkThemeColors.includes(colorHex) ?
        'dark' :
        'light'
    },
    getColorClass (colorHex) {
      const vm = this
      var result = ''
      var textTheme = vm.getTextTheme(colorHex)
      return textTheme === 'dark' ? 'white--text' : 'black--text'
    },
    getColorStyle (colorHex) {
      return {
        backgroundColor: colorHex
      }
    },
    // getRandomColor () {
    //   const vm = this
    //   const n = Math.floor(Math.random() * vm.allColors.length)
    //   return vm.allColors[n].substr(1)
    // },


    getTextThemeFromBkgd(color)
    {
      const colorHexStr = color.replace('#', '')
      var colors = [
        parseInt(colorHexStr.substr(0, 2), 16),
        parseInt(colorHexStr.substr(2, 2), 16),
        parseInt(colorHexStr.substr(4, 2), 16)
      ]
      const colorSum = colors[0] + colors[1] + colors[2]
      return colorSum > 384 ? 'light' : 'dark'
    }
  }
}

export default appColorMixin
