const mixin = {
  computed: {
    allWidgets () {
      return this.$store.getters.allWidgets
    }
  },
  methods: {
    getWidget (widgetId) {
      const vm = this
      var result = null
      var index = vm.allWidgets.findIndex(item=>{
        return item._id == widgetId
      })
      if(index!=-1) return vm.allWidgets[index]
      else return null
      // for (let i = 0; i < vm.allWidgets.length; i++) {
      //   const loopWidget = vm.allWidgets[i]
      //   console.log('loopWidget' + i, loopWidget)
      //   if (loopWidget._id === widgetId) {
      //     result = loopWidget
      //     console.log('result1', result) 
      //     break
      //   }
      // }
      // console.log('result2', result) 
      // return result
    },
    getWidgetFilterType (widgetId) {
      const vm = this
      var result = ''
      var widget = null
      if (widgetId && widgetId !== '') {
        const widget = vm.getWidget(widgetId)
        if (widget !== null) {
          result = widget.filterType
        }
      }
      return result
    }

  }
}

export default mixin
