<template>
    <v-navigation-drawer app temporary v-model="$store.state.mobile.showCustomActionDrawer" height="auto" bottom style="z-index:1002" class="rounded-t-xl pa-3">
        <v-list rounded v-if="actionButtons.length > 0">
            <v-list-item v-for="btn in actionButtons" :key="btn._id" @click="triggerAction(btn)">
                <component
                    :is="targetComponent(btn)"
                    :button="btn"
                    :ref="btn._id"
                    :selected="selected"
                    @onSelected="onSelected"
                    :buttonProps="{
                        depressed: true,
                        rounded: false,
                    }"
                />
                <!-- <v-list-item-icon>
                    <v-icon>mdi-{{ btn.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ btn.name }}
                </v-list-item-content> -->
            </v-list-item>
        </v-list>
        <div v-else class="pa-5">
            {{ $t('view.noCustomizedActionsFound') }}
        </div>
    </v-navigation-drawer>
</template>

<script>
import NormalButton from '@/pages/admin/workflow/manualActions/buttons/normal';
import AlertButton from '@/pages/admin/workflow/manualActions/buttons/alert';
import Mixin from '@/pages/admin/workflow/manualActions/buttons/mixin';
import ButtonsMixin from '@/pages/admin/workflow/manualActions/tools/mixin';
export default {
    mixins: [Mixin, ButtonsMixin],
    props: [ 'formId', 'appId', 'selected', 'cb', 'viewId'],
    components: { NormalButton,  AlertButton }, 
    computed: {
        showCustomActionDrawer() {
            return this.$store.getters.showCustomActionDrawer;
        },
        actionButtons() {
            return this.$store.getters.getActionButtons;
        }
    },
    async mounted() {
        const viewId = this.viewId || this.$route.params.viewId;
        await this.$store.dispatch('FETCH_ACTION_BUTTON', {
            formId: this.formId, 
            appId: this.appId,
            viewId,
            withHide: false
        })
    },
    methods: {
        onSelected() {
            this.$store.dispatch('TOGGLE_CUSTOM_ACTION_DRAWER');
            this.cb();
        }
    }
}
</script>
