var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"bulk-edit-dialog",attrs:{"width":"700","persistent":"","content-class":"bulk-edit-dialog-content","scrollable":""},model:{value:(_vm.showingDialog),callback:function ($$v) {_vm.showingDialog=$$v},expression:"showingDialog"}},[_c('v-card',[_c('dialog-header',{attrs:{"label":_vm.$t('bulkEdit.bulkEdit')},on:{"close":function($event){_vm.showingDialog = false}}}),_c('v-card-subtitle',{staticClass:"mt-2 pl-5"},[_vm._v(" "+_vm._s(_vm.$t("bulkEdit.bulkEditDescription"))+" ")]),_c('v-card-text',{staticClass:"pt-5",staticStyle:{"min-height":"220px"}},[_c('div',{staticClass:"d-flex align-end"},[_c('div',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.$t("bulkEdit.bulkEditField"))+" ")]),_c('div',{staticClass:"flex-grow-1"},[_c('v-select',{staticStyle:{"font-size":"13px"},attrs:{"items":_vm.filteredFieldInfos,"hide-details":"","dense":"","outlined":"","height":"25","item-text":"label","item-value":"fieldId","menu-props":{ offsetY: true, contentClass: 'denseList' }},model:{value:(_vm.selectedField),callback:function ($$v) {_vm.selectedField=$$v},expression:"selectedField"}})],1)]),_c('div',{staticClass:"d-flex align-top mt-5"},[_c('div',{staticStyle:{"width":"100px"}},[_vm._v(_vm._s(_vm.$t("bulkEdit.bulkEditValue")))]),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-radio-group',{staticClass:"bulkEditRadio",attrs:{"mandatory":"","dense":"","row":""},model:{value:(_vm.editType),callback:function ($$v) {_vm.editType=$$v},expression:"editType"}},[_c('v-radio',{attrs:{"ripple":false,"label":_vm.$t('bulkEdit.newVal'),"value":"NEW_VALUE"}}),(
                _vm.fieldInfo &&
                ['multipleSelection', 'members', 'departments'].includes(
                  _vm.fieldInfo.type
                )
              )?_c('v-radio',{staticClass:"ml-5",attrs:{"ripple":false,"label":_vm.$t('bulkEdit.append'),"value":"APPEND","disabled":(_vm.fieldInfo &&
                  _vm.fieldInfo.properties.validation &&
                  _vm.fieldInfo.properties.validation.includes('required')) ||
                (_vm.fieldInfo && _vm.fieldInfo.type == 'owner')}}):_vm._e(),_c('v-radio',{staticClass:"ml-5",attrs:{"ripple":false,"label":_vm.$t('bulkEdit.clear'),"value":"CLEAR","disabled":(_vm.fieldInfo &&
                  _vm.fieldInfo.properties.validation &&
                  _vm.fieldInfo.properties.validation.includes('required')) ||
                (_vm.fieldInfo && _vm.fieldInfo.type == 'owner')}})],1),(['NEW_VALUE', 'APPEND'].includes(_vm.editType) && _vm.fieldInfo)?_c('div',[_c('input-widget',{key:_vm.fieldInfo._id,ref:_vm.fieldInfo.fieldId,attrs:{"id":"bulkEditInputWidget","isNew":false,"editAnyway":true,"fullWidth":true,"recordData":_vm.recordData,"relatedTableData":_vm.relatedTableData,"relatedTableInfos":_vm.relatedTableInfos,"fieldInfo":_vm.fieldInfo,"formInfo":_vm.currentForm},on:{"onCommand":_vm.onCommandHandler}})],1):_vm._e()],1)])]),_c('dialog-actions',{attrs:{"buttons":['ok', 'cancel']},on:{"onCommand":_vm.onCommandHandler}}),_c('popup-record-dialog',{ref:"popupRecordDialog",on:{"onCommand":_vm.onCommandHandler}}),(_vm.showingMembersMenu)?_c('members-menu',{ref:"membersMenu",attrs:{"fullWidth":true,"attachId":"bulkEditInputWidget","nudgeBottom":_vm.rowHeight},on:{"memberClick":_vm.handleMemberClick},model:{value:(_vm.showingMembersMenu),callback:function ($$v) {_vm.showingMembersMenu=$$v},expression:"showingMembersMenu"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }