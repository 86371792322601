<template>
    <PrintTemplateDashboard/>    
</template>

<script>
import PrintTemplateDashboard from './print/PrintTemplateDashboard'


export default {
    components:{
        PrintTemplateDashboard,
    },
}
</script>
