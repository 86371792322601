<template>
  <v-expansion-panel :class="{ 'role-panel-my-role-border': showMyRoleBorder }">
    <v-expansion-panel-header disable-icon-rotate hide-actions>
      <template v-slot:default="{ open }">
        <span class="nowrap-ellipsis">{{ label }}</span>
        <v-chip
          small
          class="ml-2"
          style="flex:none"
          v-if="showMyRoleBorder"
          color="primary"
          >{{$t('roles.myRole')}}</v-chip
        >
        <span v-if="!open && numOfEntities" class="pl-2 grey--text caption">
          {{ numOfEntities }}
        </span>
        <v-spacer />
        <v-menu v-if="open && menuItems.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="menu-button"
              >mdi-dots-vertical</v-icon
            >
          </template>
          <v-list dense>
            <v-list-item
              v-for="item in menuItems"
              :key="item.label"
              link
              @click="handleCommand(item)"
            >
              <v-list-item-title :class="item.class ? item.class : ''">{{
                item.label
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon v-else class="menu-button">
          mdi-menu-down
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-divider />
    <v-expansion-panel-content class="pa-5" eager>
      <v-row no-gutters><span class="description">{{
        role.description || $t(`roles.PERMISSION_LEVEL_${role.permission}`)
      }}</span></v-row>
      <v-row no-gutters v-if="roleNotEmpty">
        <v-chip-group
          v-if="isAdmin"
          multiple
          column
          active-class="primary--text"
          v-model="selectedChips"
        >
          <v-chip
            v-for="user in role.users"
            @input="toggleMember($event, user)"
            :key="user._id"
            label
            :disabled="user._id === ownerId && role.permission === 100"
            class="py-1 role-chip"
          >
            <div 
              class="d-flex flex-column line-height-1 align-items-stretch">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-flex flex-row line-height-1 align-center justify-center"
                style="height:16px;">
                <!-- <div 
                  class="text-caption warning secondary-chip">
                  {{ $t("roles.owner") }}
                </div> -->
                <v-icon 
                  v-if="user._id === ownerId"
                  style="width:16px;height:16px;"
                  left small
                  color="warning">mdi-account-tie</v-icon>
                <v-icon 
                  v-else
                  style="width:16px;height:16px;"
                  left small
                  color="warning">mdi-account</v-icon>
                <span>{{ user.employeeName }}</span>
              </div>
              <div
                class="grey--text py-0 ma-0 text-center text-ellipsis w-100">
                <small class="primary--text line-height-1 text-ellipsis">{{ getTeamName(user.teamId) }}</small>
              </div>                
            </div>
          </v-chip>
          
          <v-chip
            v-for="dept in role.departments"
            @input="toggleDept($event, dept)"
            :value="dept._id"
            :key="dept._id"
            label
            class="role-chip"
          >
            <div class="d-flex flex-column line-height-1 align-items-stretch">
              <div class="d-flex flex-row line-height-1 align-center justify-center"
                style="height:16px;">
                <v-icon
                  style="width:16px;height:16px;"
                  left small
                  color="info">mdi-account-multiple</v-icon>
                <span>{{ dept.label }}</span>
                <!-- <div class="text-caption warning secondary-chip" disabled>
                  {{ $t("roles.department") }} -->
              </div>
              <div 
                class="grey--text py-0 ma-0 text-center text-ellipsis w-100">
                <small class="primary--text line-height-1 text-ellipsis">{{ getTeamName(dept.teamId) }}</small>
              </div>
            </div>
          </v-chip>
        </v-chip-group>
        <v-chip-group v-else column>
          <v-chip
            v-for="user in role.users"
            :key="user._id"
            label
            active-class=""
            class="role-chip"
          >
            <div class="d-flex flex-column">
              <span>{{ user.employeeName }}</span>
              <div
                v-if="user._id === ownerId"
                class="text-caption warning secondary-chip"
              >
                {{ $t("roles.owner") }}
              </div>
            </div>
          </v-chip>
          <v-chip
            v-for="dept in role.departments"
            :value="dept._id"
            :key="dept._id"
            label
            class="role-chip"
          >
            <div class="d-flex flex-column">
              <span>{{ dept.label }}</span>
              <div class="text-caption warning secondary-chip" disabled>
                {{ $t("roles.department") }}
              </div>
            </div>
          </v-chip>
        </v-chip-group>
      </v-row>
      <v-row v-else>
        <div class="empty-box">
          {{ $t("roles.emptyRole") }}
        </div>
      </v-row>
      <v-row no-gutters>
        <div
          v-if="selectedDepartments.length > 0 || selectedMembers.length > 0"
        >
          {{
            $t("roles.selected", {
              num: selectedDepartments.length + selectedMembers.length,
            })
          }}

          <v-btn
            small
            text
            class="mr-2 primary--text"
            @click="cancelSelections"
            >{{ $t("roles.cancel") }}</v-btn
          >
          <v-btn small text class="mr-2 red--text" @click="removeMember">{{
            $t("roles.delete")
          }}</v-btn>
          <v-btn
            v-if="canMove"
            small
            text
            class="primary--text"
            @click="moveMember"
            >{{ $t("roles.moveToOther") }}</v-btn
          >
        </div>
        <v-spacer />
        <v-btn
          v-if="isAdmin"
          small
          text
          class="mr-2 primary--text"
          @click="showDeptDialog = true;track('dept');"
          >{{ $t("roles.addDept") }}</v-btn
        >
        <v-btn
          v-if="isAdmin"
          small
          text
          class="primary--text"
          @click="showMemDialog = true;track('member');"
          >{{ $t("roles.addMember") }}</v-btn
        >
      </v-row>
    </v-expansion-panel-content>
    <DepartmentsDialog
      v-model="showDeptDialog"
      v-if="showDeptDialog"
      isMultiple
      @confirm="addDepartment"
    />
    <DialogButtonSelectEmployees
      v-model="showMemDialog"
      v-if="showMemDialog"
      :addButton="false"
      multiple
      :filter="memberIds"
      @submit="addMember"
    />
    <DialogButtonSelectEmployees
      v-model="showTransferOwnerDialog"
      v-if="showTransferOwnerDialog"
      :addButton="false"
      :filter="[ownerId]"
      @submit="transferOwner"
    />
    <TransferOwnerWarningDialog
      v-model="showTransferOwnerWarningDialog"
      v-if="showTransferOwnerWarningDialog"
      @submit="confirmTransferOwner"/>

    <RoleSettingDialog
      :roleId="role._id"
      v-model="showSettingDialog"
      v-if="showSettingDialog"
      @update="onUpdate"
    />
  </v-expansion-panel>
</template>
<script>
import DepartmentsDialog from "@/components/dialogs/DepartmentsDialog";
import DialogButtonSelectEmployees from "@/components/dialogButtons/DialogButtonSelectEmployees";
import RoleSettingDialog from "../dialogs/RoleSettingDialog";
import TransferOwnerWarningDialog from '../dialogs/TransferOwnerWarningDialog';

export default {
  name: 'rolePanel',
  props: {
    role: Object,
    canMove: Boolean,
  },
  components: {
    DepartmentsDialog,
    DialogButtonSelectEmployees,
    RoleSettingDialog,
    TransferOwnerWarningDialog
  },
  data() {
    return {
      selectedMembers: [],
      selectedDepartments: [],
      selectedChips: [],

      showDeptDialog: false,
      showMemDialog: false,
      showSettingDialog: false,
      showTransferOwnerDialog: false,
      showTransferOwnerWarningDialog: false,
      transferPayload: [],

      sensorsMap:{
        member: this.$sensors.events.plus_click_authorization.ADD_USER,
        dept: this.$sensors.events.plus_click_authorization.ADD_DEPT,
      }
    };
  },
  methods: {
    track(type){
      this.$sensors.track("plus_click_authorization", {
        action:this.sensorsMap[type],
        app_id: this.appId
      });
    },
    getTeamName (teamId) {
      const team = this.allTeams.find(team => team._id === teamId)
      return team ? team.name : '***'
    },
    handleCommand(item) {
      switch (item.command) {
        case "openSetting":
          this.showSettingDialog = true;
          break;
        case "leaveRole":
        case "deleteRole":
        case "copyRole":
          this.emitCommand(item.command, { roleId: this.role._id });
          break;
        case "transferOwner":
          this.showTransferOwnerDialog = true;
          break;
      }
    },
    emitCommand(command, params) {
      this.$emit("commandHandler", {
        command,
        params,
      });
    },
    toggleMember(val, user) {
      if (val === true && !this.selectedMembers.includes(user._id)) {
        this.selectedMembers.push(user._id);
      } else {
        const index = this.selectedMembers.findIndex((id) => id === user._id);
        if (index > -1) this.selectedMembers.splice(index, 1);
      }
    },
    toggleDept(val, dept) {
      if (val === true && !this.selectedDepartments.includes(dept._id)) {
        this.selectedDepartments.push(dept._id);
      } else {
        const index = this.selectedDepartments.findIndex(
          (id) => id === dept._id
        );
        index > -1 ? this.selectedDepartments.splice(index, 1) : "";
      }
    },
    cancelSelections() {
      this.selectedMembers = [];
      this.selectedDepartments = [];
      this.selectedChips = [];
    },
    addMember(payload) {
      this.$emit("commandHandler", {
        command: "addMember",
        params: {
          role: this.role,
          users: payload,
        },
      });
    },
    transferOwner(payload) {
      if (payload.length === 0) return;
      this.transferPayload = payload;
      this.showTransferOwnerWarningDialog = true;
    },
    confirmTransferOwner() {
      this.$emit("commandHandler", {
        command: "transferOwner",
        params: {
          role: this.role,
          newOwner: this.transferPayload[0],
        },
      });
    },
    addDepartment(payload) {
      this.$emit("commandHandler", {
        command: "addMember",
        params: {
          role: this.role,
          departments: payload,
        },
      });
    },
    removeMember() {
      this.$emit("commandHandler", {
        command: "removeMember",
        params: {
          role: this.role,
          users: this.selectedMembers,
          departments: this.selectedDepartments,
        },
        callback: () => {
          this.cancelSelections();
        },
      });
    },
    moveMember() {
      this.$emit("commandHandler", {
        command: "moveMember",
        params: {
          roleId: this.role._id,
          users: this.selectedMembers,
          departments: this.selectedDepartments,
        },
      });
    },
    onUpdate(payload) {
      this.$emit("commandHandler", {
        command: "updateRole",
        params: {
          data: payload,
        },
      });
    },
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    allTeams () {
      return this.$store.getters.allTeams
    },
    activeTeamId () {
      return this.$store.getters.activeTeamId
    },
    label() {
      if (this.role.label) return this.role.label;
      else if (this.role.permission === 100) return this.$t("roles.admin");
      else return this.$t("roles.noName");
    },
    menuItems() {
      let items = [];
      if (this.role.permission === 100 && this.isOwner) {
        if (this.user.canTransferOwnership) {
          items.push({
            label: this.$t("roles.transferOwner"),
            command: "transferOwner",
          });
        }
      } else {
        if (this.isMyRole) {
          items.push({
            label: this.$t("roles.leaveRole"),
            command: "leaveRole",
            class: "red--text",
          });
        }

        if (this.role.permission < 100 && this.isAdmin) {
          items = items.concat([
            {
              label: this.$t("roles.copy"),
              command: "copyRole",
            },

            {
              label: this.$t("roles.setting"),
              command: "openSetting",
            },
            {
              label: this.$t("roles.delete"),
              command: "deleteRole",
              class: "red--text",
            },
          ]);
        }
      }
      return items;
    },
    ownerId() {
      return this.$store.getters.currentApp
        ? this.$store.getters.currentApp.ownerId
        : "";
    },
    isOwner() {
      return this.ownerId === this.$store.getters.activeEmployeeId;
    },

    deptIds() {
      return this.role.departments.map((dept) => dept._id);
    },
    memberIds() {
      return this.role.users.map((user) => user._id);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    roleNotEmpty() {
      return this.role && this.role.users && this.role.departments
        ? this.role.users.length + this.role.departments.length > 0
        : false;
    },
    numOfEntities() {
      let result = [];
      if (this.role.users.length > 0)
        result.push(
          this.$t("roles.numOfUser", { num: this.role.users.length })
        );
      if (this.role.departments.length > 0)
        result.push(
          this.$t("roles.numOfDept", { num: this.role.departments.length })
        );
      return result.join(", ");
    },
    isMyRole() {
      return this.role.users.some(
        (user) => user._id === this.$store.getters.activeEmployeeId
      );
    },
    showMyRoleBorder() {
      return this.isMyRole && !this.isAdmin;
    },
    appId(){
      return get(this.$store.getters.currentApp, "_id", "");
    }
  },
};
</script>
<style scoped lang="scss">
.role-panel-my-role-border {
  border: 3px solid $primary !important;
}

.v-expansion-panel--active .v-expansion-panel-header--active {
  background-color: white !important;
  color: black !important;
}

.role-chip {
  height: 36px;
  line-height: 1;
  padding-top: 1px;
}

.secondary-chip {
  border-radius: 4px;
  color: white;
  padding: 0 4px 0 4px;
  text-align: center;
}

.menu-button {
  flex: 0 0 auto;
}
.v-expansion-panel--active button {
  min-height: auto !important;
}

.empty-box {
  border: 2px dashed #e9e9e9;
  margin: 0px 0 10px 0;
  width: 100%;
  text-align: center;
  height: 200px;
  line-height: 200px;
  color: #9e9e9e;
}

.description {
  overflow-wrap: anywhere;
  text-align: start;
}
</style>
