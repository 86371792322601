<template>
  <v-dialog v-model="showDialog" persistent width="1080">
    <v-card>
      <dialog-header
        :label="$t('widgets.properties.dataSourceSelection')"
        @close="showDialog = false"
      ></dialog-header>
      <v-card-text style="height: 640px;" class="d-flex flex-column">
        <v-row no-gutters>
          <v-col cols="3" class="d-flex flex-column rounded">
            <div class="caption">{{ $t("general.team") }}</div>
            <v-card
              outlined
              class="fill-height flex-grow-1"
              style="overflow-y:auto;height:0px"
            >
              <v-list dense class="pa-0">
                <v-list-item-group
                  v-model="selectedTeamIndex"
                  color="primary"
                  mandatory
                >
                  <v-list-item
                    v-for="team in options"
                    :key="team._id"
                    class="px-2"
                    
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ team.teamName }}
                      </v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="3" class="d-flex flex-column rounded">
            <div class="caption">{{ $t("general.apps") }}</div>
            <v-card
              outlined
              class="fill-height flex-grow-1"
              style="overflow-y:auto;height:0px"
            >
              <v-list dense class="pa-0">
                <v-list-item-group
                  v-model="selectedAppIndex"
                  color="primary"
                  mandatory
                >
                  <v-list-item v-for="app in apps" :key="app._id" class="px-2">
                    <v-list-item-content>
                      <v-list-item-title>{{ app.appTitle }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon v-if="app.optionGroups.length > 0" class="align-center">
                      <v-chip x-small color="primary">{{
                        app.optionGroups.length
                      }}</v-chip>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="3" class="d-flex flex-column rounded">
            <div class="caption">{{ $t("optionGroup.optionGroup") }}</div>
            <v-card
              outlined
              class="fill-height flex-grow-1"
              style="overflow-y:auto;height:0px"
            >
              <v-list dense class="pa-0">
                <v-list-item-group
                  v-model="selectedOptionGroup"
                  color="primary"
                  mandatory
                >
                  <v-list-item
                    v-for="optionGroup in selectedAppOptions"
                    :key="optionGroup._id"
                    :value="optionGroup"
                    class="px-2"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        optionGroup.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon v-if="optionGroup.options.length > 0" class="align-center">
                      <v-chip x-small color="primary">{{
                        optionGroup.options.length
                      }}</v-chip>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="3" class="d-flex flex-column rounded">
            <div class="caption">{{ $t("publicForm.preview") }}</div>
            <OptionCard
              :option="selectedOptionGroup || {}"
              outlined
              flat
              width="100%"
              class="fill-height flex-grow-1"
              no-toolbar
              max-height="600"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <dialog-actions
        @onCommand="onCommandHandler"
        :actionDisabled="!selectedOptionGroup"
      ></dialog-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogHeader from "./comps/DialogHeader";
import dialogActions from "./comps/DialogActions";
import OptionCard from "@/pages/apps/comps/OptionsCard";

export default {
  components: {
    dialogHeader,
    dialogActions,
    OptionCard,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      options: [],
      selectedTeamIndex: 0,
      selectedAppIndex: 0,
      selectedOptionGroup: null,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    apps() {
      return (

        this.options[this.selectedTeamIndex] &&
        this.options[this.selectedTeamIndex]["apps"] || []
      );
    },
    selectedAppOptions() {
      return (
        this.apps[this.selectedAppIndex] &&
        this.apps[this.selectedAppIndex]["optionGroups"] || []
      );
    },
    selectedOptions() {
      return this.selectedOptionGroup && this.selectedOptionGroup.options;
    },
  },
 async mounted() {
    await this.getOptions();
    this.getCurrentAppFirstOptions()
  },
  methods: {
    async getOptions() {
      const getParams = {
        urlCommand:
          "/optionGroup/getAll?appId=" + this.$store.getters.currentApp._id,
      };
      try {
      const res = await this.$store.dispatch("AUTH_GET", getParams)
      this.options = res;
      } catch(err){
        console.error(err)
      }

    },
    getCurrentAppFirstOptions(){
      const teamId = this.$store.getters.activeTeamId
      const currentTeamIndex = this.options.findIndex(team => team._id === teamId)
      if (currentTeamIndex > -1){
        this.selectedTeamIndex = currentTeamIndex
        const appId = this.$store.getters.currentApp._id
        const currentAppIndex = this.options[currentTeamIndex]['apps'].findIndex(app => app._id === appId)
        if (currentAppIndex > -1) this.selectedAppIndex = currentAppIndex
      }
    },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "cancel":
          this.showDialog = false;
          break;
        case "ok":
          this.$emit("confirm", this.selectedOptionGroup);
          this.showDialog = false;
          break;
      }
    },
    getOptionsLength(team) {
      return team.apps.reduce((acc, app) => {
        acc += app.optionGroups.length;
        return acc;
      }, 0);
    },
  },
};
</script>
