<template>
    <v-container fluid class="relation-card-config">
    
        <settings-select-row ref="cardSummaryFieldId" labelTag="view.summaryField" 
            :options="allEntryFields" :value="cardConfig.cardSummaryFieldId" 
            @input="val=>updateSetting('cardSummaryFieldId', val)"></settings-select-row>

        <settings-select-row :enabled="imageFields.length>1" ref="cardImageFieldId" 
            labelTag="view.imageField" :options="imageFields" 
            :value="cardConfig.cardImageFieldId" @input="val=>updateSetting('cardImageFieldId', val)"></settings-select-row>   
    
        <settings-radio-toggle :enabled="enableImageOptions" ref="cardImagePosition" 
            labelTag="view.imagePosition" :options="imagePositionOptions" 
            :value="userCardConfig.cardImagePosition" @input="val=>updateSetting('cardImagePosition', val)"></settings-radio-toggle>
    
        <settings-yes-no-row v-if="userCardConfig.cardImagePosition!=='top'" 
            ref="cardImageStretch" labelTag="view.occupyFullColumn" 
            :value="userCardConfig.cardImageStretch" @input="val=>updateSetting('cardImageStretch', val)"></settings-yes-no-row>   
    
        <settings-radio-toggle :enabled="enableImageOptions" ref="cardImageMode" 
            labelTag="view.imageDisplayMode" :options="displayModeOptions" 
            :value="userCardConfig.cardImageMode" @input="val=>updateSetting('cardImageMode', val)"></settings-radio-toggle>   
    
        <settings-radio-toggle ref="cardImageSize" labelTag="view.imageSize" 
            :options="imageSizeOptions" :value="userCardImageSize" 
            @input="val=>updateSetting('cardImageSize', val)"></settings-radio-toggle>
   
        <settings-input cols="4" ref="cardImageSizeInput" labelTag="view.imageSizeInPixels" 
            :fieldType="number" v-model="customCardImageSize"></settings-input>
    
        <settings-slider ref="cardImagePadding" labelTag="view.imageWhiteBorder" 
            :max="10" :value="userCardConfig.cardImagePadding" @input="val=>updateSetting('cardImagePadding', val)"></settings-slider>
    
        <settings-yes-no-row ref="cardDisplayFieldName" labelTag="view.displayFieldName" 
            :value="userCardConfig.cardDisplayFieldName" @input="val=>updateSetting('cardDisplayFieldName', val)"></settings-yes-no-row>
    
        <settings-field-selection ref="cardDisplayFieldIds" labelTag="view.displayFields" 
            :options="displayableFields" :value="userCardConfig.cardDisplayFieldIds" 
            @input="val=>updateSetting('cardDisplayFieldIds', val)"></settings-field-selection>    
    
        <settings-radio-toggle v-if="cardType==='formCard'" ref="cardDisplaySize" 
            labelTag="view.cardWidth" :options="CARD_WIDTH_OPTIONS" 
            :value="userCardConfig.cardWidthSize" @input="val=>updateSetting('cardWidthSize', val)"></settings-radio-toggle>
    
        <settings-radio-toggle v-if="cardType==='formCard'" ref="cardContentColumns" 
            labelTag="view.cardContentColumns" :options="cardContentColumnOptions" 
            v-model="userCardContentColumns"></settings-radio-toggle>

    </v-container>
</template>

<script>
import settingsYesNoRow from './SettingsYesNoRow'
import settingsSelectRow from './SettingsSelectRow'
import settingsRadioToggle from './SettingsRadioToggle'
import settingsSlider from './SettingsSlider'
import settingsInput from './SettingsInput'
import settingsFieldSelection from './SettingsFieldSelection'
import { getFieldPropertyValue } from "@/helpers/ObjectHelpers";
import { LEVEL_DIAGRAM_CARD_CONFIG } from '@/const/defaults'

export default {
    name: 'relationCardConfig',
    components: {
        settingsYesNoRow,
        settingsSelectRow,
        settingsRadioToggle,
        settingsSlider,
        settingsInput,
        settingsFieldSelection
    },
    model: {
        prop: 'cardConfig',
        event: 'input'
    },
    props: {
        cardConfig: Object,
        formFieldInfos: {
            type: Array,
            default () {
                return []
            }
        },
        titleFieldInfoId: {
            type: String,
            default: ''
        },
        formInfo: Object,
        cardType: {
            type: String,
            default: 'levelDiagramCard'
            // [levelDiagramCard|formCard|ColumnCard]

        }
    },
    data() {
        return {
            selection: [0, 2, 4, 6],
            count: '0'
        }
    },
    computed: {
        // userCardContentColumns: {
        //   get () {
        //     return this.userCardConfig.cardContentColumns.toString()
        //   },
        //   set (val) {
        //     this.updateSetting('cardContentColumns', parseInt(val))
        //   }
        // },

        userCardContentColumns: {
            get() {
                const vm = this
                var result = 0
                if (vm.userCardConfig && vm.userCardConfig.cardContentColumns) {
                  var result = vm.userCardConfig.cardContentColumns.toString()
                  const values = vm.cardContentColumnOptions.map(opt => opt.value)
                  console.log('result: ' + result + ': values: ', values)

                  if (!values.includes(result.toString())) {
                      result = values[0]
                      vm.updateSetting('cardContentColumns', parseInt(result))
                  }
                }
                return result
            },
            set(val) {
                const vm = this
                vm.updateSetting('cardContentColumns', parseInt(val))
            }
        },

        cardContentColumnOptions() {
            const vm = this
            switch (vm.userCardConfig.cardWidthSize) {
                case 'x-small':
                    return [{ label: '1', value: '1' }]
                    break
                case 'small':
                    return [{ label: '1', value: '1' }]
                    break
                case 'middle':
                    return [
                        { label: '1', value: '1' },
                        { label: '2', value: '2' }
                    ]
                    break
                case 'large':
                    return [
                        { label: '1', value: '1' },
                        { label: '2', value: '2' },
                        { label: '3', value: '3' }
                    ]
                    break
            }
        },

        VALID_IMAGE_SIZES() {
            const vm = this
            return [
                { label: vm.$t('general.large'), value: '80' },
                { label: vm.$t('general.middle'), value: '64' },
                { label: vm.$t('general.small'), value: '48' }
            ]
        },

        CARD_WIDTH_OPTIONS() {
            const vm = this
            return [
                { label: vm.$t('general.large'), value: 'large' },
                { label: vm.$t('general.middle'), value: 'middle' },
                { label: vm.$t('general.small'), value: 'small' },
                { label: vm.$t('general.smallest'), value: 'x-small'}
            ]
        },
        userCardImageSize() {
            // large, middle, small, custom
            const vm = this
            var result = '0'
            const validImageSizes = vm.VALID_IMAGE_SIZES.map(imageSize => imageSize.value)
            if (validImageSizes.includes(vm.cardConfig.cardImageSize)) {
                result = vm.cardConfig.cardImageSize
            }
            return result
        },
        userCardConfig() {
            const vm = this
            var result = JSON.parse(JSON.stringify(LEVEL_DIAGRAM_CARD_CONFIG))
            return { ...result, ...vm.cardConfig }
        },
        fieldInfos() {
            return this.formFieldInfos.length? this.formFieldInfos : this.formInfo.fieldInfos
        },
        titleFieldId() {
            const vm = this
            var titleFieldInfoId = vm.titleFieldInfoId
            if (vm.formInfo && titleFieldInfoId === '') {
                titleFieldInfoId = vm.formInfo.titleFieldInfoId
            }
            const titleFieldInfo = vm.fieldInfos.find(info => info._id === titleFieldInfoId)
            return titleFieldInfo ? titleFieldInfo.fieldId : ''
        },
        displayableFields() {
            const vm = this
            var result = []
            for (let i = 0; i < vm.fieldInfos.length; i++) {
                const loopInfo = vm.fieldInfos[i]
                result.push({
                    label: loopInfo.label,
                    value: loopInfo.fieldId,
                    isTitle: vm.titleFieldId === loopInfo.fieldId
                })
            }
            return result
        },
        customCardImageSize: {
            get() {
                return parseFloat(this.userCardConfig.cardImageSize)
            },
            set(val) {
                this.updateSetting('cardImageSize', val.toString())
            }
        },

        imageSizeOptions() {
            const vm = this
            return [
                ...vm.VALID_IMAGE_SIZES,
                { label: vm.$t('general.custom'), value: '0' }
            ]
        },
        enableImageOptions() {
            return this.cardConfig['cardImageFieldId'] !== ''
        },
        // fieldInfos () {
        //     const vm = this
        //     var result = []
        //     if (vm.formInfo) {
        //         result = vm.formInfo.fieldInfos
        //     }
        //     return result
        // },
        allEntryFields() {
            const vm = this
            var result = [{
                text: '(' + vm.$t('view.noNeed') + ')',
                value: ''
            }]
            for (var i = 0; i < vm.fieldInfos.length; i++) {
                const loopFieldInfo = vm.fieldInfos[i]
                if (loopFieldInfo.widgetId && loopFieldInfo.widgetId !== '') {
                    result.push({
                        text: loopFieldInfo.label,
                        value: loopFieldInfo.fieldId
                    })
                }
            }
            return result
        },
        imageFields() {
            const vm = this
            var result = []
            for (var i = 0; i < vm.fieldInfos.length; i++) {
                const loopFieldInfo = vm.fieldInfos[i]
                if (loopFieldInfo.type === 'attachments') {
                    result.push({
                        text: loopFieldInfo.properties['fieldName'],
                        value: loopFieldInfo.fieldId
                    })
                }
            }
            result.unshift({
                text: '(' +
                    (result.length > 0 ? vm.$t('view.noNeed') : vm.$t('view.none')) +
                    ')',
                value: ''
            })
            console.log('RelationCard :: imageFields: result: ', result)
            return result
        },
        imagePositionOptions() {
            const vm = this
            return [
                { label: vm.$t('view.top'), value: 'top' },
                { label: vm.$t('view.left'), value: 'left' },
                { label: vm.$t('view.right'), value: 'right' }
            ]
        },
        displayModeOptions() {
            const vm = this
            const pos = vm.userCardConfig.cardImagePosition
            const stretch = vm.userCardConfig.cardImageStretch
            var result = []

            if (
                (pos === 'top') ||
                (pos === 'left' && stretch) ||
                (pos === 'right' && stretch)) {
                result = [
                    { label: vm.$t('view.cover'), value: 'cover' },
                    { label: vm.$t('view.contain'), value: 'contain' }
                ]
            } else {
                result = [
                    { label: vm.$t('view.cover'), value: 'cover' },
                    { label: vm.$t('view.contain'), value: 'contain' },
                    { label: vm.$t('view.circle'), value: 'circle' },
                    { label: vm.$t('view.square'), value: 'square' }
                ]
            }
            return result
        },
        imagePositionOptions2() {
            const vm = this
            return [{
                    label: vm.$t('view.top'),
                    value: 'top',
                    modes: [
                        { label: vm.$t('view.cover'), value: 'cover' },
                        { label: vm.$t('view.contain'), value: 'contain' }
                    ]
                },
                {
                    label: vm.$t('view.left'),
                    value: 'left',
                    modes: [
                        { label: vm.$t('view.cover'), value: 'cover' },
                        { label: vm.$t('view.contain'), value: 'contain' },
                        { label: vm.$t('view.circle'), value: 'circle' },
                        { label: vm.$t('view.square'), value: 'square' }
                    ]
                },
                {
                    label: vm.$t('view.right'),
                    value: 'right',
                    modes: [
                        { label: vm.$t('view.cover'), value: 'cover' },
                        { label: vm.$t('view.contain'), value: 'contain' },
                        { label: vm.$t('view.circle'), value: 'circle' },
                        { label: vm.$t('view.square'), value: 'square' }
                    ]
                }
            ]
        },

        displayModeOptions2() {
            const vm = this
            var result = []
            console.log('displayModeOptions :: vm.imagePositionOptions: ', vm.imagePositionOptions)

            const selectedImagePosition = vm.imagePositionOptions.find(
                option => option.value === vm.userCardConfig.cardImagePosition
            )
            console.log('displayModeOptions :: selectedImagePosition: ', selectedImagePosition)

            if (selectedImagePosition) {
                result = selectedImagePosition.modes
            }


            return result
        },

    },
    methods: {
        onChange(val) {
            const vm = this
            console.log('onChange: val: ', val)
            console.log('onChange: selection: ', vm.selection)
            vm.selection = val
        },
        updateSetting(fieldName, fieldValue) {
            const vm = this
            console.log('updateSetting fieldName = ' + fieldName)
            console.log('updateSetting fieldValu(' + (typeof fieldValue) + ') = ', fieldValue)
            console.log('updateSetting fieldValue = ' + (fieldValue ? 'yes' : 'no'))
            const updatedCardConfig = JSON.parse(JSON.stringify(vm.userCardConfig))
            updatedCardConfig[fieldName] = fieldValue
            // vm.cardConfig[fieldName] = fieldValue
            vm.$emit('input', updatedCardConfig) // vm.cardConfig)
        }
    }
}
</script>

<style>
.relation-card-config .col {
    padding: 6px;
}
</style>