<template>
  <div>
    <v-radio-group 
      hide-details
      dense
      :column="false"
      v-model="display"
      @change="onChanged"
    >
      <v-radio 
        dense 
        v-for="option in options"
        :key="option.id" 
        :label="$t(option.label)"
        :value="option.id"
        class="w-v-display-option"
      />
  </v-radio-group>
  </div>
</template>

<script>
import {
  UPDATE_ACTION_BUTTON_INFO,
} from '@/store/modules/customizedAction/action_types';
export default {
  props: {
    display: {
      type: String,
      default: 'normal',
    },
  }, 
  data() {
    return {
      options: [
        {
          id: 'normal',
          label: 'workflow.modules.actionProcess.label.buttonDisplayOption.show',
        },
        {
          id: 'hide',
          label: 'workflow.modules.actionProcess.label.buttonDisplayOption.hide',
        }
      ],
    }
  },
  // computed: {
  //   // icons() {
  //   //   return WorkflowButtonIcons;
  //   // },
  // },
  methods: {
    onChanged(content) {
      this.$store.dispatch(UPDATE_ACTION_BUTTON_INFO, {
        field: 'display',
        content,
      });
    }
  },
}
</script>

<style scoped>
  .w-v-display-option {
    margin-right: 25px;
  }
</style>