<template>
  <div>
    <label>{{ label }}</label>
    <v-select
      dense
      ref="dataSourceSelect"
      hide-details
      outlined
      :value="selectValue"
      :items="items"
      :disabled="!fieldInfoId.startsWith('_')"
      @input="onInput"
      @change="onChange"
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
    >
      <template v-slot:item="{ item }">
        <v-list-item @click.prevent="onClick(item.value)"
          >{{ item.text }}
        </v-list-item>
      </template>
    </v-select>
    <OptionsCard
      v-if="value && optionGroup"
      :option="optionGroup"
      :hasDelete="false"
      flat
      outlined
      width="100%"
      maxHeight="250"
      @edit="onEdit"
    />

    <label v-if="value && optionGroup">{{ $t("widgets.properties.default") }}</label>
    <v-select
      v-if="value && optionGroup"
      dense
      ref="dataSourceSelect"
      hide-details
      outlined
      :menu-props="{offsetY: true, contentClass: 'denseList'}"
      v-model="defaultValue"
      :multiple="isMultiple"
      :items="selectableOptions"
      item-text="label"
      item-value="_id"
      clearable
    ></v-select>
    <SelectionDataSourceDialog
      v-if="showDialog"
      v-model="showDialog"
      @confirm="onDialogConfirm"
    />
    <EditOptionsGroupDialog
      :selectedOption="optionGroup"
      v-if="showEditDialog"
      v-model="showEditDialog"
      @submit="onSubmit"
    />
  </div>
</template>
<script>
import mixin from "./mixin";

import SelectionDataSourceDialog from "@/components/dialogs/SelectionDataSourceDialog";
import EditOptionsGroupDialog from "@/components/dialogs/EditOptionsGroupDialog";
import OptionsCard from "@/pages/apps/comps/OptionsCard";

export default {
  components: {
    SelectionDataSourceDialog,
    EditOptionsGroupDialog,
    OptionsCard,
  },
  mixins: [mixin],
  data() {
    return {
      input: 1,
      items: [
        {
          text: this.$t("widgets.properties.customSelection"),
          value: 1,
        },
        {
          text: this.$t("widgets.properties.usePresetSelection"),
          value: 2,
        },
      ],

      showDialog: false,
      showEditDialog: false,
      optionGroup: null,
    };
  },
  computed: {
    selectValue() {
      return this.value ? 2 : 1;
    },
    options() {
      return this.value? this.optionGroup && this.optionGroup.options:
      (this.isMultiple
        ? this.propertyValues.inputOptionsMultiple.options
        : this.propertyValues.inputOptionsSingle.options)
    },
    selectableOptions(){
      return this.options.filter(option => !option.isDeleted && option.active)
    },
    defaultValue: {
      get() {
        return this.isMultiple
          ? this.propertyValues.inputOptionsMultiple.defaultSelection
          : this.propertyValues.inputOptionsSingle.defaultSelection;
      },
      set(val) {
        this.updateSelectionPropertyValue({
          defaultSelection: val,
          options: this.optionGroup.options,
          useColor: this.optionGroup.useColor,
        });
      },
    },
    isMultiple() {
      return this.fieldType === "multipleSelection";
    },
  },
  watch: {
    value: {
      handler(){
        if (this.value){
          this.getOptionGroup();
        }
      },
      immediate:true
    }
  },

  methods: {
    getOptionGroup() {
      const getParams = {
        urlCommand:
          "/optionGroup/getById?appId=" +
          this.$store.getters.currentApp._id +
          "&id=" +
          this.value,
      };

      this.$store.dispatch("AUTH_GET", getParams).then((res) => {
        this.optionGroup = res;
      });
    },
    getColor(colorSets) {
      return colorSets.split("/")[1];
    },
    onClick(val) {
      if (val === 1) {
        this.propertyValue = "";
      } else {
        this.showDialog = true;
      }

      this.$refs.dataSourceSelect.isMenuActive = false;
    },
    onDialogConfirm(optionGroup) {
      console.log('onDialogConfirm', optionGroup)
      this.propertyValue = optionGroup._id;
      this.optionGroup = optionGroup;

      this.updateSelectionPropertyValue({
        defaultSelection: this.isMultiple ? []:"",
        options: optionGroup.options,
        useColor: optionGroup.useColor,
      });
    },
    onEdit() {
      this.showEditDialog = true;
    },
    onSubmit(data) {
      const postData = {
        urlCommand: "/optionGroup/save",
        data: {
          ...data,
          appId: this.$store.getters.currentApp._id,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        

        this.updateSelectionPropertyValue({
        defaultSelection: this.defaultValue,
        options: res.options,
        useColor: res.useColor,
      });
      
      this.optionGroup = res;
      });
    },
    updateSelectionPropertyValue(propertyValue) {
      console.log('updateSelectionPropertyValue', propertyValue)
      this.$store.dispatch("SET_FIELD_INFO_PROPERTY", {
        fieldInfoId: this.fieldInfoId,
        propertyKey: this.isMultiple
          ? "inputOptionsMultiple"
          : "inputOptionsSingle",
        propertyValue,
      });
    },
  },
};
</script>
