<template>
  <v-container fluid>
    <v-row no-gutters class="grey lighten-2">
      <v-col cols="10" class="pa-0 ma-0">
        <v-row no-gutters class="pa-1">
          <v-col
            v-for="header in headers"
            :key="header.label"
            :cols="header.cols"
          >
            {{ header.label }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row no-gutters class="pa-1">
        {{$t('roles.permission')}}
        </v-row>
      </v-col>
    </v-row>
    <permission-table-row
      v-for="[formId, form] in Object.entries(forms)"
      :key="formId"
      :form="form"
      @commandHandler="onCommandHandler"
    />
  </v-container>
</template>
<script>
import PermissionTableRow from "./PermissionTableRow.vue";
export default {
  components: { PermissionTableRow },
  props: {
    forms: Array,
  },

  data() {
    return {
      headers: [
        {
          label: this.$t('roles.form'),
          cols: 4,
        },
        {
          label:this.$t('roles.view'),
          cols: 2,
        },
        {
          label: this.$t('roles.edit'),
          cols: 2,
        },
        {
          label: this.$t('roles.delete'),
          cols: 2,
        },
        {
          label: this.$t('roles.share'),
          cols: 2,
        },
      ],
    };
  },

  methods: {
    onCommandHandler({ command, params }) {
      switch (command) {
        case "checkAllReadViews":
          Object.values(params.form.views).forEach((view) => {
            view.read = true;
          });
          break;
        case "checkAllEditViews":
          Object.values(params.form.views).forEach((view) => {
            view.read = true;
            view.edit = true;
          });
          break;
        case "checkAllDeleteViews":
          Object.values(params.form.views).forEach((view) => {
            view.read = true;
            view.delete = true;
          });
          break;
        case "resetView":
          params.view.edit = false;
          params.view.delete = false;
          break;
        case "checkReadView":
          params.view.read = true;
          break;
        case "checkShareForm":
          params.form.canShare = params.value;
          break;
        case "checkDefaultRead":
          params.form.defaultRead = true;
          break;
        case "resetDefault":
          params.form.defaultEdit = false;
          params.form.defaultDelete = false;
          break;
        case "setCanAccess":
          params.form.canAccess = params.value;
          break;
        case "updateFields":
          params.form.fields = params.fields;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cell-flex {
  flex: 1 0;
  overflow: hidden;
}

.bottom-border {
  border-bottom: 1px solid #9e9e9e;
}
</style>
