<template>
    <div class="workflow-filter-selectable-input">
        <ComboBox
            :value="value || null"
            :placeholder="$t('messages.pleaseInput', [])"
            @changed="v => $emit('changed', v)"
            v-bind="selectInputProps"
        />
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="workflow-filter-icon-hints"
                    small>
                    mdi-exclamation-thick
                </v-icon>
            </template>
            <span>{{ $t('workflow.modules.form.hints.inputOrGate') }}</span>
        </v-tooltip>
    </div>
</template>

<script>


import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
export default {
    props: {
        value: [Object, String],
        selectInputProps: Object,
        fieldProperties: Object,
    },
    components:{
        ComboBox,
    },
}
</script>
