<template>
<div>
  <v-text-field
    ref="time"
    v-model="userValue"
    class="workflow-customized-form-record-element"
    :label="$t('widgets.properties.selectTime')"
    prepend-inner-icon="mdi-clock-time-four-outline"
    hint="e.g. 13:15"
    :hide-details="isLayout"
    dense
    flat
    outlined
    :readonly="isReadOnly"
    :rules="rules"
    solo
    @click:prepend-inner="showDialog"
    :error="error" 
  />
  <v-dialog
      ref="dialog"
      v-model="showing"
      v-if="showing"
      :return-value.sync="dialogValue"
      width="290px">

    <v-time-picker
      v-if="showing&&!isReadOnly"
      v-model="dialogValue"
      scrollable
      no-footer
      :locale="$i18n.locale"
      :min="minTime"
      :max="maxTime">
      <v-spacer></v-spacer>
      <v-btn
          class="min-width-100"
          color="muted"
          @click="close">
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
          class="min-width-100 ml-1"
          color="primary"
          @click="confirm">
        {{ $t('buttons.ok') }}
      </v-btn>
    </v-time-picker>
  </v-dialog>
</div>
</template>

<script>
  import mixin from './_mixin'

  export default {
    name: 'dialogFieldTime',
    mixins: [mixin],
    props: {
      rules: Array,
      minTime: String,
      maxTime: String,
      error: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      _confirm () {
        const vm = this
        this.$emit('input', this.dialogValue)
      },
      getDefaultValue () {
        return this.defaultType==='auto' ? this.formatTime() : ''
      }
    }
  }
</script>
