<template>
  <div>
    <FormElementWidget
      :ref="fieldInfo.fieldId"
      :recordData="recordData"
      :idFieldLookup="idFieldLookup"
      :relatedTableData="relatedTableData"
      :fieldInfo="fieldInfo"
      specialType="text"
      :ignoreRecordId="true"
      :involveRelationshipLabel="involveRelationshipLabel"
      :customizedFormInfo="customizedFormInfo"
      :noIcon="true"
      :optionOutput="optionOutput"
      :editAnyway="true"
      :hideInput="dependency"
      :withHideFields="withHideFields"
      :widgetTypeMapField="widgetTypeMapField"
      @onCommand="onCommandHandler"
    />
  </div>
</template>

<script>
import Wrapper from './wrapper';
import RecordMxiin from '@/mixins/recordMixin'
import FormElementWidget from "@/components/inputWidgets/InputWidget";
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [RecordMxiin],
  props: {
    worksheet: String,
    optionOutput: String,
    fieldInfo: Object,
    idFieldLookup: String,
    recordData: Object,
    involveRelationshipLabel: Boolean,
    customizedFormInfo: Object,
    dependency: Boolean,
    withHideFields: Boolean,
    relatedTableData: Object,
    widgetTypeMapField: String,
  },
  components: {
    FormElementWidget,
    Wrapper,
  },
};

</script>

<style scoped>
  
</style>
