<template>
    <v-app >
        <div class="d-flex flex-column fill-height w-100" style="overflow: hidden">
            <app-header style="z-index: 1001" />
            <v-main 
                class="flex-grow-1" 
                :style="scrollable?
                    'overflow-y: auto !important':
                    'overflow-y: hidden !important'" 
                style="height: calc(100vh - 60px); overflow-x: hidden;"
            >
                <router-view class="fill-height" />
            </v-main>
            <v-overlay v-if="showSpinner">
                <font-awesome-icon icon="spinner" class="spinner fa-spin text-h4" />
            </v-overlay>
            <AppDrawer/>

            <user-profile-drawer />
        </div>
    </v-app>
</template>

<script>
import EventBus from '@/event-bus.js';
import AppHeader from '@/components/M_AppHeader'
import AppDrawer from "@/components/appDrawer/M_AppDrawer";
import UserProfileDrawer from '@/components/M_UserProfileDrawer'
export default {
    components: { AppHeader,AppDrawer, UserProfileDrawer },
    data() { 
        return {
            showSpinner: false,
        }
    },
    computed: {
        scrollable(){
            return this.$route.name !== 'forms'
        },
        teams() {
            let teams = [];
            const user = this.$store.getters.user; 
            if ( user ) {
                teams = user?.teams;
            }
            return teams;
        }
    }, 
    created() {
        EventBus.$on( 'showSpinner', this.toggleSpinner );
        EventBus.$on( 'hideSpinner', this.hideSpinner );
    },
    beforeDestroy() {
        EventBus.$off( 'showSpinner' );
        EventBus.$off( 'hideSpinner' );
    },
    methods: { 
        toggleSpinner() {
            this.showSpinner = !this.showSpinner;
        }, 
        hideSpinner() {
            this.showSpinner = false;
        }, 
    }
}
</script>

<style lang="scss">
:root {
    font-size: 13px;
    box-sizing: border-box;
}
</style>