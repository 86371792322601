<template>
  <div class="edit-related-record-modal border" style="width:100%; height:100%">
      <!-- <div> below is obsolate at all.
          for pulldown selection,
          VxeCellRelatedRecordSimple is made to handle it -->
      <div v-if="false" style="height:100%">
          <relation-record-select
            :relatedFormInfo="relatedFormInfo"
            :fieldInfo="fieldInfo"
            :relatedTitleFieldId="relatedTitleFieldId"
            v-model="inputValue"></relation-record-select>
      </div>
            <!-- :item-text="relatedTitleFieldId" -->
      <div class="white px-1 d-flex flex-row align-center justify-center" v-else style="height:100%">   
        <relation-buttons
            v-if="showButtons"
            :fieldInfo="fieldInfo"
            :selection="selection"
            class="d-inline-block flex-grow-0"
            @select="selectRelatedRecord"
            @create="newRelatedRecord"></relation-buttons>
        <div class="flex-grow-1 overflow-hidden">
            <v-chip v-for="(id, index) in inputValue"
                style="display:inline-block;"
                :key="index"
                :close="editable"
                @click:close="removeItem(id, index)"
                class="ma-0 mr-1 mb-1" small color="warning">
                {{ (idDataMapping[id] && idDataMapping[id].title) ? idDataMapping[id].title : '(' + $t('general.noTitle') + ')'}}</v-chip>
        </div>
      </div>
      <!-- <v-overlay
        :absolute="false"
        :value="showOverlay"
        :id="attach">
      </v-overlay> -->
<!--    <select-related-records-dialog
      ref="selectRelatedRecordsDialog" 
      @click:new="newRelatedRecord"></select-related-records-dialog> -->


  </div>
</template>

<script>
import relatedRecordSelectionMixin from '@/mixins/relatedRecordSelectionMixin'
import relationButtons from '@/components/inputWidgets/comps/RelationButtons'
import relationRecordSelect from '@/components/RelationRecordSelect'
// import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";

// functions
import { getFieldPropertyValue } from "@/helpers/ObjectHelpers";


export default {
    mixins: [relatedRecordSelectionMixin],
    name: 'EditRelatedRecordModal',
    components: {
        relationButtons,
        relationRecordSelect,
        // selectRelatedRecordsDialog
    },
    props: {
        params: Object,
        fieldInfo: Object,
        currentFormInfo: Object,
        idDataMapping: Object,
        readonly: Boolean,
        relatedTableInfos:Object
    },
    data () {
        return {
            showOverlay: false,
            relatedData: []
        }
    },
    computed: {
        recordData () {
            return this.params.row
        },

        exclusion () {

            const vm = this
            var result = []
            if(vm.inputValue.length > 0) result = [...vm.inputValue]

            return result
        },
        fieldId () {
            return this.fieldInfo ? this.fieldInfo.fieldId : ''
        },

        isPulldown () {
            return this.displayFormat === 'pulldown'
        },
        showButtons () {
            const vm = this
            var result = true
            if (vm.isParentChildRelationship) {
                if (vm.isParentField && vm.inputValue.length>=1) {
                    result = false
                }
            } else if (!vm.isMultiple && vm.inputValue.length>=1) {
                result = false
            }
            return result
        },
        inputValue: {
            get () {

                const value = this.params.row[this.params.column.property]
                return (value && Array.isArray(value)) ?
                    value : 
                    ''            
            },
            set (val) {
                if (val) {
                    this.$set(this.params.row, this.params.column.property, val)
                } else {
                    this.$set(this.params.row, this.params.column.property, [])
                }
            }
        },

        selection () {
            const vm = this
            let result = []
            const value = vm.params.row[vm.params.columnProperty]
            return (value && Array.isArray(value)) ?
                value :
                []               
            // if (vm.params.row[this.params.column.property] &&
            //     Array.isArray(?
            //     this.params.row[this.params.column.property] : 
            //     []
            //     return result
        },
        editable () {
            const vm = this
            return (!vm.readonly && vm.params.row.canEdit)
        },
        attach () {
            return 'tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex        
        },
        cssSelect () {
            const vm = this
            if(this.params.fixed!= undefined) {
                if(document.querySelector('.vxe-table--fixed-left-wrapper')) {
                    return '.vxe-table--fixed-left-wrapper' + ' #' + vm.attach
                }
            }
              return ''
        },
        twoWayRelated () {
            const vm = this
            let result = false
            if (vm.fieldInfo) {
                result = vm.fieldInfo.properties.twoWayRelated
            }
            return result
        }
    },
    mounted () {
        this.loadRelatedData()
    },
    methods: {
        removeItem (id, index) {
            const vm = this            
            vm.inputValue.splice(index, 1)
        },

        // from buttons
        selectRelatedRecord () {
            const vm = this;
            switch (vm.selectionMode) {
                case "table":
                    vm.popupSelectRelatedTable();
                    break;
                case 'simple':
                case "card":
                    vm.popupSelectRelatedCards();
                    break;
            
            }
        },
        loadRelatedData () {
            const vm = this
            vm.relatedData = []
        },
        getDefaultValues() {
            const vm = this
            let result = {}
            if (vm.twoWayRelated[0] === '1') {
                const relatedFieldId = vm.twoWayRelated.split('|')[1]
                result[relatedFieldId] = [vm.params.row['_id']]
            } else if (vm.isParentChildRelationship) {
                result[vm.parentChildRelatedFieldId] = [vm.recordData._id]
            }
            return result
        },
        newRelatedRecord () {
            const vm = this
            var defaultValues = vm.getDefaultValues()
            vm.showOverlay = true
            const payload = {
                fieldLabel: vm.fieldInfo.label,
                fieldId:this.fieldId,
                recordId: '',
                formInfo: vm.relatedFormInfo,
                defaultValues: defaultValues,
                onSubmitted: vm.onNewRecordSubmitted,
                onCancelled: vm.onNewRecordCancelled,
            }
            vm.$emit('updateTdClass', true)
            vm.$emit('onCommand', {
                command: 'newRelatedRecord',
                ...payload
            })
        },

        onNewRecordCancelled () {
            const vm = this
            vm.showOverlay = false
        },

        ucFirst (val) {
            return val.length > 0 ? 
                val[0].toUpperCase() + val.substr(1) : 
                ''
        },

        onNewRecordSubmitted (newRecord) {
            const vm = this
            vm.showOverlay = false
            vm.saveValueHandler([newRecord._id], vm.onSaved)
            this.$emit('onCommand', {
                command: 'refreshRelationData',
                addition: {
                    fieldId: this.fieldId,
                    relatedRecordIds: [newRecord._id]
                }
            })
        },

        popupSelectRelatedCards() {
            const vm = this;
            vm.showOverlay = true
            const payload = {
                value: vm.inputValue,
                dataSource: vm.dataSource,
                formInfo: vm.relatedFormInfo,
                selectionSettings: vm.cardSelectionSettings,
                multiple: vm.isMultiple,
                exclusion: vm.exclusion,
                
                // parent/child relationship
                isParentChild: vm.isParentChildRelationship,
                parentChildFields: vm.parentChildFields,
                parentFormId: vm.currentFormInfo._id,
                callbackBundle: {
                    id: '',
                    onSelected: vm.onSelected,
                    onCancelled: vm.onCancelled
                },

                attach: vm.attach,
                cssSelect: vm.cssSelect,

                recordId: vm.recordData._id,
                checkDbForExclusion: vm.isParentChildRelationship || 
                    vm.displayFormat === 'table',
            }
            vm.$emit('updateTdClass', true)
            vm.$emit('onCommand', {
                command: 'selectRelatedCards',
                ...payload
            })
        },        

        popupSelectRelatedTable() {
            const vm = this;
            vm.showOverlay = true
            
            const payload = {
                value: vm.inputValue,
                dataSource: vm.dataSource,
                formInfo: vm.relatedFormInfo,
                selectionSettings: vm.tableSelectionSettings,
                multiple: vm.isMultiple,
                exclusion: vm.exclusion,

                callbackBundle: {
                    id: '',
                    onSelected: vm.onSelected,
                    onCancelled: vm.onCancelled
                },

                attach: vm.attach,
                cssSelect: vm.cssSelect,

                recordId: vm.recordData._id,
                fieldId : this.fieldId,
                parentFormId : this.currentFormInfo._id
            }
            vm.$emit('updateTdClass', true)
            // vm.$refs.selectRelatedRecordsDialog.open(payload, vm.onSelected, vm.onCancelled)
            console.log('popupSelectRelatedTable: emit(selectRelatedReocrds): payload: ', payload)
            vm.$emit('onCommand', {
                command: 'selectRelatedRecords', 
                ...payload
            })
        },

        onCancelled () {
            const vm = this
            console.log('EditRelatedRecordModal.onCancelled')
            vm.$emit('updateTdClass', false)
            vm.showOverlay = false
        },

        onSelected (payload) {
            const vm = this
            vm.$emit('updateTdClass', false)
            vm.showOverlay = false
            vm.saveValueHandler(payload, vm.onSaved)
        },

        onSaved(dataSource, newSelection) {
            const vm = this

            // update fie
            // vm.$emit('onCommand', {
            //     command: 'refreshRelationData',
            //     addition: {
            //         fieldId: vm.fieldInfo.fieldId,
            //         relatedRecordIds: newSelection
            //     }
            // })
            vm.$emit('onCommand', {
                command: 'closeSelectRelatedRecordsDialog'
            })
        },

        copyRelatedFields (relatedRecordData) {
            const vm = this

            console.log('copyRelatedFields: relatedRecordData: ', relatedRecordData)
            console.log('copyRelatedFields: vm.params.row: ', vm.params.row)
            // get related record id
            const currFieldValue = vm.params.row[vm.params.column.property]
            console.log('copyRelatedFields: currFieldValue: ', currFieldValue)
            console.log('copyRelatedFields: vm.params.column.property: ', vm.params.column.property)

            if (currFieldValue.length > 0) {
                const relatedRecordId = currFieldValue[0]
                // const relatedRecordData = vm.relatedRecordData.find(record => record._id === relatedRecordId)

                const fieldCopyingItems = getFieldPropertyValue(
                    vm.fieldInfo,
                    "relatedRecordCopyFields",
                    {}
                )
                console.log('EditRelatedRecordModal fieldCopyingItems: ', fieldCopyingItems)
                const forceCopy = getFieldPropertyValue(
                    vm.fieldInfo,
                    'forceCopy',
                    '') === 'true'

                console.log('EditRelatedRecordModal forceCopy = ' + (forceCopy ? 'yes' : 'no'))

                for (let relatedFieldId in fieldCopyingItems) {
                    const targetFieldIds = fieldCopyingItems[relatedFieldId];
                    for (let i = 0; i < targetFieldIds.length; i++) {
                        var localFieldId = targetFieldIds[i];
                        if (forceCopy ||
                            !vm.params.row[localFieldId] ||
                            vm.params.row[localFieldId]===null) {
                            vm.params.row[localFieldId] = relatedRecordData[relatedFieldId];
                        }
                    }
                }   
            }
        }

    }
}
</script>
