import { set } from 'lodash';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
const mixin = {
  methods: {
    valueChanged(v) {
      this.changed('expression', v)
    },
    itemsChanged(v) {
      this.changed('fields', v)
    },
    dateChanged(field, v) {
      this.changed(field, v)
    },
    formatChanged(v) {
      this.changed('format', v)
    },
    unitChanged(v) {
      this.changed('unit', v)
    },
    changed(path, v, current={}) {
      const payload = { ...current };
      set(payload, path, v);
      this.$store.dispatch(SET_SETTINGS, payload);
    },
  },
}

export default mixin
