export const CONNECT_TO_SERVER = 'CONNECT_TO_SERVER';
export const JOIN_ROOMS = 'JOIN_ROOMS';
export const LEAVE_ROOMS = 'LEAVE_ROOMS';

// send message
export const ON_TO_MESSAGE = 'ON_TO_MESSAGE';
// receive message
export const EVENT_SENDMESSAGE = 'EVENT_SENDMESSAGE';
export const CONNECT_TO_SERVER_ERROR = 'CONNECT_TO_SERVER_ERROR';
export const DISCONNECT_FROM_SERVER = 'DISCONNECT_FROM_SERVER';

export const EVENT_CUSTOMACTIONBUTTONSHOWPROCESS = 'EVENT_CUSTOMACTIONBUTTONSHOWPROCESS';
export const EVENT_WORKFLOWINSITEMESSAGE = 'EVENT_WORKFLOWINSITEMESSAGE';
export const OFF_EVENT_CUSTOMACTIONBUTTONSHOWPROCESS = 'OFF_EVENT_CUSTOMACTIONBUTTONSHOWPROCESS';
export const SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION = 'SHOW_CUSTOMACTIONBUTTONSHOWPROCESS_NOTIFICATION';

export const OUTBOUND_EVENT_JOINROOMS = 'OUTBOUND_EVENT_JOINROOMS';
export const OUTBOUND_EVENT_LEAVEROOMS = 'OUTBOUND_EVENT_LEAVEROOMS';
export const EVENT_JOINROOMS = 'EVENT_JOINROOMS';
export const EVENT_LEAVEROOMS = 'EVENT_LEAVEROOMS';

export const SHOW_AUTOMATETASK_POPUP = 'SHOW_AUTOMATETASK_POPUP';
export const HIDE_BELL = 'HIDE_BELL';
export const HIDE_POPUP_ALERT = 'HIDE_POPUP_ALERT';
export const UPDATE_COUNTER = 'UPDATE_COUNTER';
// export const CLEAR_COUNTER = 'CLEAR_COUNTER';
export const TOGGLE_PANEL = 'TOGGLE_PANEL';


export const FETCH_NOTIFICATION_LIST = 'FETCH_NOTIFICATION_LIST';
export const BOOKMARK_NOTIFICATION = 'BOOKMARK_NOTIFICATION';
export const UNBOOKMARK_NOTIFICATION = 'UNBOOKMARK_NOTIFICATION';
