<template>
  <team-config-new v-if="isAdding" 
    :teams="shareAppSettingTeams"
    @onCommand="onCommandHandler" ref="teamConfigNew" />
  <team-config-edit v-else-if="isEditing" :team="teamToEdit" @onCommand="onCommandHandler" ref="teamConfigEdit" />
  <div v-else>
    <div class="d-flex flex-row align-center my-4 mx-3 justify-space-between">
      <div class="d-flex flex-row align-center">
        <div style="height:18px;width:4px;" class="teal lighten-2"></div>
        <div class="ml-2 font-weight-bold" style="font-size:14px">{{$t('settings.shareAppSetting')}}</div>
      </div>
      <v-btn icon small @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>
    <div class style="overflow: scroll; height:500px">
      <team-config-item :teams="shareAppSettingTeams" @onCommand="onCommandHandler"></team-config-item>
      <v-card class="mx-3 mt-3 elevation-0 mb-6">
        <v-btn depressed class="teal--text text--lighten-2" style="width:100%;" @click="add()">
          <v-icon small>mdi-plus</v-icon>
          <span class="text--error">{{$t('settings.newTeamConnection')}}</span>
        </v-btn>
      </v-card>
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </div>
</template>

<script>
import teamConfigItem from './TeamConfigItem';
import teamConfigNew from './TeamConfigNew';
import teamConfigEdit from './TeamConfigEdit';
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";

export default {
  name: "shareAppSettingTabContent",
  components: {
    teamConfigItem,
    teamConfigNew,
    teamConfigEdit,
    confirmDeleteDialog,
  },
  data() {
    return {
      isAdding: false,
      shareAppSetting: null,
      teamToEdit: null,
    };
  },
  computed: {
    isEditing () {
      return !!this.teamToEdit;
    },
    activeTeamId() {
      return this.$store.getters.activeTeamId;
    },
    shareAppSettingTeams() {
      return this.shareAppSetting && this.shareAppSetting.teams
        ? this.shareAppSetting.teams
        : [];
    }
  },
  created() {
    this.loadShareAppSetting();
  },
  methods: {
    loadShareAppSetting() {
      const getParams = {
        urlCommand: "/teams/" + this.activeTeamId + "/shareAppSetting"
      };
      this.$store.dispatch("AUTH_GET", getParams).then(response => {
        this.shareAppSetting = response.shareAppSetting;
        console.log(
          "loadShareAppSetting: shareAppSetting: ",
          this.shareAppSetting
        );
      });
    },
    refresh() {
      this.loadShareAppSetting();
    },
    add() {
      this.isAdding = true;
    },
    edit(id) {
      this.teamToEdit = null;
    },
    updateSahreAppSettingTeam(data) {
      const putData = {
        urlCommand: "/teams/" + this.activeTeamId + "/shareAppSetting/teams/" + data._id,
        data
      };
      this.$store.dispatch("AUTH_PUT", putData).then(response => {
        console.log("saveShareAppSettingTeam: response: ", response);
        this.refresh();
        this.teamToEdit = null;
        this.isAdding = false;
      });
    },
    saveShareAppSettingTeam(data) {
      const params = {
        _id: this.activeTeamId,
        ...data
      };

      const postData = {
        urlCommand: "/teams/" + this.activeTeamId + "/shareAppSetting/teams",
        data: params
      };
      this.$store.dispatch("AUTH_POST", postData).then(response => {
        console.log("saveShareAppSettingTeam: response: ", response);
        this.refresh();
        this.teamToEdit = null;
        this.isAdding = false;
      });
    },

    editTeamConfig (team) {
      this.teamToEdit = team;      
    },

    deleteTeamConfig (team) {
      this.$refs.confirmDeleteDialog.confirm(() => {
        this.doDeleteTeamConfig(team);
      });
    },

    doDeleteTeamConfig (team) {
      const deleteData = {
        urlCommand: '/teams/' + this.activeTeamId + '/shareAppSetting/teams/' + team._id
      }
      this.$store.dispatch('AUTH_DELETE', deleteData).then(
        response => {
          console.log('response: ', response);
          this.refresh();
        }
      );
    },

    onCommandHandler(payload) {
      switch (payload.command) {
        case "cancel":
          console.log('onCommandHandler cancel');
          this.teamToEdit = false;
          this.isAdding = false;
          break;
        case "save":
          this.saveShareAppSettingTeam(payload.data);
          break;
        case "delete":
          this.deleteTeamConfig(payload.team);
          break;
        case "edit":
          this.editTeamConfig(payload.team);
          break;
        case 'updateContact':
          this.updateSahreAppSettingTeam(payload.data);
          break;
        case "setVisible":
          // this.saveSenderConfig(payload.sender.configs, payload.sender._id, payload.sender.visible)
          break;
      }
    }
  }
};
</script>
