<template>
  <div :style="`background-color:${color}`" class="fill-height">
    <slot></slot>
    <div style="overflow:auto;position:relative;height:100vh">
      <div style="width:100%">
        <slot name="header">
        <div
          v-if="showHeader"
          class="background-header"
          :style="`${imageUrl? `height:auto;max-width:880px`:`height:330px;width:100%;background-color:${headerColor};`}`"
        >
          <img
            v-if="imageUrl"
            :src="imageUrl"
            class="header-image"
          />
            <!-- :src="getThumbnailPath(image)" -->
        </div>
        <slot name="header-control"></slot>
        </slot>
        <div
          :style="!headerColor ? `margin: 50px auto 50px;` : ``"
          class="form-content"
        >
          <slot name="card-content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headerColor: String,
    color: String,
    isEditMode: Boolean,
    // image: String,
    imageUrl: String,
    showHeader:Boolean
  },
  // methods: {
  //   getThumbnailPath(media) {
  //     const vm = this;
  //     return vm.$store.getters.appHost + "/medias/" + media + "/thumbnail";
  //   },
  // },
};
</script>
<style scoped>
.background-header {
  background-size: cover;
  background-position: center center;
  margin:0px auto;
}

.form-content {
  margin: auto auto 50px;
  max-width: 880px;
  padding: 10px 0px;
  padding-bottom: 50px
}

.header-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
