var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-0 d-flex pl-2",attrs:{"hide-actions":""}},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t('chart.styleConfig.label'))+" ")])]),_c('v-switch',{staticStyle:{"position":"absolute","right":"0px","top":"-7px"},attrs:{"dense":"","inset":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
            command: 'update_bl_label',
            value: $event
        })}},model:{value:(_vm.bl_label),callback:function ($$v) {_vm.bl_label=$$v},expression:"bl_label"}}),(_vm.selectedChart=='LINE'||_vm.selectedChart=='BAR'||_vm.selectedChart=='RADAR')?_c('v-expansion-panel-content',[_c('v-checkbox',{staticClass:"pb-1",attrs:{"hide-details":"","dense":"","ripple":false,"label":_vm.$t('chart.showInfo')},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_bl_showData',
                value: $event
            })}},model:{value:(_vm.setup.bl_showData),callback:function ($$v) {_vm.$set(_vm.setup, "bl_showData", $$v)},expression:"setup.bl_showData"}})],1):_vm._e(),(_vm.selectedChart=='PIE')?_c('v-expansion-panel-content',[_c('v-checkbox',{staticClass:"pb-1",attrs:{"hide-details":"","dense":"","ripple":false,"label":_vm.$t('chart.showDimensionLabels')},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                command: 'update_bl_showLabel',
                value: $event
            })}},model:{value:(_vm.setup.bl_showLabel),callback:function ($$v) {_vm.$set(_vm.setup, "bl_showLabel", $$v)},expression:"setup.bl_showLabel"}}),_c('v-checkbox',{staticClass:"pb-1",attrs:{"hide-details":"","dense":"","ripple":false,"label":_vm.$t('chart.showValues')},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                command: 'update_bl_showData',
                value: $event
            })}},model:{value:(_vm.setup.bl_showData),callback:function ($$v) {_vm.$set(_vm.setup, "bl_showData", $$v)},expression:"setup.bl_showData"}}),_c('v-checkbox',{staticClass:"pb-1",attrs:{"hide-details":"","dense":"","ripple":false,"label":_vm.$t('chart.showPercentage')},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                command: 'update_bl_showPercent',
                value: $event
            })}},model:{value:(_vm.setup.bl_showPercent),callback:function ($$v) {_vm.$set(_vm.setup, "bl_showPercent", $$v)},expression:"setup.bl_showPercent"}})],1):_vm._e(),(_vm.selectedChart=='FUNNEL')?_c('v-expansion-panel-content',[_c('v-checkbox',{staticClass:"pb-1",attrs:{"hide-details":"","dense":"","ripple":false,"label":_vm.$t('chart.showDimensionLabels')},on:{"change":function($event){return _vm.$emit('onCommandHandler', {
                command: 'update_bl_showLabel',
                value: $event
            })}},model:{value:(_vm.setup.bl_showLabel),callback:function ($$v) {_vm.$set(_vm.setup, "bl_showLabel", $$v)},expression:"setup.bl_showLabel"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }