<template>
  <div class="widget-panel flex-grow-1 px-1 pt-1 d-flex flex-column">
    <perfect-scrollbar class="flex-grow-1" style="height:0;">
      <div>
        <v-expansion-panels
            light
            :accordion="accordion"
            :popout="popout"
            :inset="inset"
            :multiple="multiple"
            :focusable="focusable"
            :disabled="disabled"
            :readonly="readonly"
            :flat="flat"
            :value="[0,1,2,3]"
            :hover="hover"
            :tile="tile">
          <v-expansion-panel
              v-for="(menuSection, i) in menuSections"
              :key="i">
            <v-expansion-panel-header dense class="pl-3 py-1">
              {{ $t(menuSection.titleTag) }}
              <template v-slot:actions>
                <v-icon>mdi-menu-down</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">

              <table-setup-menu-item
                  v-for="(menuItem, index) in menuSection.menu"
                  :key="index"
                  :selected="selectedMenuItem"
                  @onCommand="onCommandHandler"
                  :menuItem="menuItem"></table-setup-menu-item>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </perfect-scrollbar>
</div>
</template>

<script>
// import {Drag} from 'vue-easy-dnd'
import tableSetupMenuItem from './TableSetupMenuItem'
// import Vue2Filters from 'vue2-filters'

export default {
  // mixins: [Vue2Filters.mixin],
  components: {
    tableSetupMenuItem
  },
  props: {
    menuSections: Array,
    selectedMenuItem: Object
  },
  data () {
    return {
      // expansion panel
      accordion: true,
      popout: false,
      inset: false,
      multiple: true,
      focusable: false,
      disabled: false,
      readonly: false,
      flat: false,
      hover: true,
      tile: true,

    }
  },

  methods: {
    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        default:
          this.$emit('onCommand', payload)
      }
    },
    addWidget (widget) {
      this.selectedWidget = widget
      this.$emit('onCommand', {
        command: 'addWidget',
        widget: widget
      })
    }
  }
}
</script>

<style>


</style>
