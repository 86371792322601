<template>
<panel-base
  :title="$t('menu.inputPanel')"
  @click:submit="save"
  :loading="loading">
  <template v-slot:content>
    <input-panel-settings-form v-if="!loading && userPanelSettings"
      class="flex-grow-1"
      ref="inputPanelSettingsForm"
      v-model="userPanelSettings"></input-panel-settings-form>
  </template>
</panel-base>
</template>

<script>
import panelMixin from '@/mixins/panelMixin'
import panelBase from '@/components/PanelBase'
import inputPanelSettingsForm from './comps/InputPanelSettingsForm'

export default {
  name: 'inputPanelSetupPage',
  mixins: [panelMixin],
  components: {
    panelBase,
    inputPanelSettingsForm
  },
  data () {
    return {
      loading: false,
      userPanelSettings: null
    }
  },
  props: {
    panelSettings: Object
  },
  methods: {
    save () {
      const vm = this
      console.log('InputPanelSetupPage.save')
      const hasErrors = vm.$refs.inputPanelSettingsForm.validateForm()
      console.log('InputPanelSetupPage :: hasErrors: ', hasErrors)
      if (!hasErrors) {
        vm.userPanelSettings.appId = vm.$store.getters.currentApp._id
        vm.userPanelSettings.teamId = vm.$store.getters.activeTeam._id
        console.log('InputPanelSetup')
        vm.$store.dispatch('UPDATE_INPUT_PANEL', {
          data: vm.userPanelSettings
        }).then(
          response => {
            this.$sensors.track("plus_inputpanel_mani", {
              module_name: this.userPanelSettings.label,
              func_name: this.$sensors.events.plus_inputpanel_mani.SAVE_INPUT_PANEL,
            });      
            vm.$emit('update', response)
          }
        )
      }
    }
  }
}
</script>