<template>
<div class="app-logo"
     ref="logoWrapper"
     :class="{'inside-bouncing': bouncing}"
     style="position:relative;">
  <!-- shadow -->
  <div class="shadow-container">
    <div class="shadow"></div>
  </div>
  <div style="width:100%;height:110px;">
    <div class="bouncing-icon">
      <div v-if="appInfo && (appInfo.logoType==='icon' || !appInfo.logoMediaId)"
           @click="openApps()"
           class="mt-6 mx-auto pa-3 d-flex flex-column justify-center mb-1 app-icon"
           :style="appInfoColorStyle"
           :class="appInfoColorClass">
        <!--<v-icon :class="iconClass" x-large>mdi-ab-testing</v-icon>-->
        <v-icon :class="iconClass" x-large>{{ appInfo.logoIcon ? appInfo.logoIcon : 'mdi-file-question' }}</v-icon>
      </div>
      <div v-else
           @click="openApps()"
           class="mt-6 mx-auto pa-3 d-flex flex-column justify-center mb-1 app-icon"
           style="">
        <!--<v-icon :class="iconClass" x-large>mdi-ab-testing</v-icon>-->
        <img :src="logoImgSrc"></img>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  data () {
    return {
      bouncing: false
    }
  },
  props: {
    appInfo: {
      type: Object,
      default: null
    },
    hover: {
      type: Boolean,
      default: false
    },
    autoBounce: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    hover: function(newVal, oldVal) {
      const vm = this
      if ( (vm.autoBounce || (newVal && !oldVal)) && !vm.bouncing) {
        vm.start()
      }
    }
  },
  mounted () {
    const vm = this
    if (vm.autoBounce) {
      vm.start()
    }
    vm.$refs.logoWrapper.addEventListener('animationend', vm.onAnimationEnded)
  },
  computed: {
    isColorClass () {
      const vm = this
      var result = false
      if (vm.appInfo.color) {
        result = vm.appInfo.color.indexOf('-')>=0
      }
      return result
    },
    appInfoColorClass () {
      const vm = this
      var result = ''
      if (vm.isColorClass) {
        result = vm.appInfo.color
      }
      return result
    },
    appInfoColorStyle () {
      const vm =this
      var result = {
        'background-color': ''
      }
      if (!vm.isColorClass) {
        result['background-color'] = vm.appInfo.color ? vm.appInfo.color : 'rgba(128,128,128,.2)'
      }
      result['color'] = vm.appInfo.textTheme==='dark' ? 'white' : 'black'
      return result
    },
    iconClass () {
      const vm = this
      var result = 'theme--dark'
      result = 'theme--' + vm.appInfo.textTheme
      return result
    },
    logoImgSrc () {
      const vm = this
      return vm.appInfo.logoMediaImageUrl
      // var url = vm.$store.getters.appHost + '/medias/'
      // let result = ''
      // if (vm.appInfo && vm.appInfo.logoMediaId) {
      //   result = url + vm.appInfo.logoMediaId
      // }
      // return result
    }
  },
  methods: {
    onAnimationEnded () {
      const vm = this
      vm.bouncing = false
      if (vm.hover) {
        setTimeout(() => {
          vm.bouncing = true
        }, 10)
      }
    },
    start () {
      const vm = this
      vm.bouncing = true
    },
    openApps () {
      this.$emit('onCommand', {command: 'openApps'})
    }
  }
}
</script>

<style>
  .app-logo {
    z-index: 30;
  }

  .app-logo .app-icon {
    width:80px;
    height:80px;
    border-radius:5rem;
  }

  .app-logo .bouncing-icon {
    z-index: 20;
    position: absolute;
    width: 100%;
  }
  .app-logo.inside-bouncing .bouncing-icon {
    animation: logoBounce 0.3s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.05, 0.5, 0.5, 1);
    /*animation-timing-function:cubic-bezier(1,.5,.5,0.05);*/
    animation-iteration-count: 2;
  }

  @keyframes logoBounce {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, -24px, 0);
    }
  }

  .app-logo .shadow-container {
    z-index: 10;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:12px;
    background-color: transparent;
  }

  .app-logo .shadow-container .shadow {
    top: 50%;
    /*background-color: blue;*/
    background: radial-gradient(lightgray, transparent);
    width: 60%;
    height: 12px;
    margin: 0 auto;

  }

  .app-logo.inside-bouncing .shadow-container  .shadow {
    animation: resizing 0.3s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.05, 0.5, 0.5, 1);
    /*animation-timing-function:cubic-bezier(1,.5,.5,0.05);*/
    animation-iteration-count: 2;
  }

  @keyframes resizing {
    from {
      width: 60%;;
      height: 12px;
    }
    to {
      width: 30%;
      height: 10px;
    }
  }



</style>
