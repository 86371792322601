<template>
  <component
    :is="target"
    :node="node"
    :tree="tree"
    :offset="offset"
    :root="true"
    :workflowName="workflowName"
    :pendingLabel="$t('workflow.modules.form.label.trigger')"
  />
</template>

<script>
import { get } from 'lodash';
import CreateOrUpdateView from './createOrUpdate';
import CreateOnlyView from './createOnly';
import UpdateOnlyView from './updateOnly';
import RemoveOnlyView from './removeOnly';
import PendingSetup from './../pending';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  data() {
    return {
      lookup: {
        onCreateOrUpdate: 'CreateOrUpdateView',
        onCreate: 'CreateOnlyView',
        onUpdate: 'UpdateOnlyView',
        onRemove: 'RemoveOnlyView',
        empty: 'PendingSetup',
      },
    }
  },
  computed: {
    workflowName() {
      const { properties } = this.node;
      return get(properties, 'worksheet.label', '');
    },
    target() {
      const hookEvent = get(this, 'node.properties.hookEvent');
      return hookEvent ? this.lookup[hookEvent] : this.lookup.empty;
    }
  },
  components: {
    CreateOrUpdateView,
    CreateOnlyView,
    UpdateOnlyView,
    RemoveOnlyView,
    PendingSetup,
  },
};

</script>

<style scoped>
  
</style>
