import { split, capitalize, includes, chain } from 'lodash';
import events from './../constants/event';
/*
* String related untilities helper
* Terry Chan
* 12/05/2021
*/
class StringUtils {
    pickFirstCharFromName(fullName='') {
        // for english handling, chinese only get first chinese letter
        const segement = split(fullName, ' ');
        let name = '';
        const { length } = segement;
        if (!!length) {
            name = segement.shift().charAt(0);
            if (length > 1) {
                name = `${name}${segement.pop().charAt(0)}`;
            }
        }
        return capitalize(name);
    }
    isEnableWorkflow(type) {
        return includes([
            'onGoing',
            'terminated',
            'failed',
        ], type);
    }
    getEvent(t) {
        const event = chain(events).map('trigger').flattenDeep().find(({ type }) => type === t).value();
        return event;
    }
}

export default new StringUtils();
