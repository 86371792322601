<template>
  <div>
    <v-dialog v-model="bl_newRuleDialog" width="800">
      <v-card tile>
        <v-card-title
          class="primary white--text px-3 py-1"
          style=" font-size: 15px"
          >{{ $t("displayRule.addNewRule") }}</v-card-title
        >
        <perfect-scrollbar class="flex-grow-1" style="height:500px;">
          <v-card-text class="py-6 px-8" style="color: #414141">
            <div class="d-flex align-start" style="width:100%">
              <div
                style="width: 100px; font-size: 13px"
                class="font-weight-bold"
              >
                {{ $t("displayRule.RuleName") }}
              </div>
              <div style="width: 250px" class="d-flex align-center">
                <v-form ref="ruleName">
                  <v-text-field
                    :placeholder="$t('messages.pleaseInput', [])"
                    :rules="[
                      (value) => !!value || $t('displayRule.ruleNameRequired'),
                    ]"
                    tile
                    style="font-size: 12px; width:200px"
                    height="20"
                    v-model="ruleName"
                    class="d-inline-block rounded-0"
                    outlined
                    dense
                  />
                </v-form>
              </div>
            </div>
            <v-divider class="mb-5 mt-2" />
            <div class="d-flex flex-column">
              <div class="">
                <span style="font-size: 13px" class="font-weight-bold">{{
                  $t("displayRule.FulfillConditions")
                }}</span>
                <span
                  class="ml-5 error--text"
                  style="font-size:12px"
                  v-if="is_noCond"
                  >{{ $t("displayRule.missingCondition") }}</span
                >
              </div>
              <v-container class="mt-5 mx-3">
                <v-row style="font-size: 13px">
                  <v-col cols="12" class="py-0">
                    <v-row v-if="!conditions.length">
                      <v-col cols="12" class="py-0 mt-1 px-0">
                        <!-- :disabled="!table" -->
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              v-on="on"
                              rounded
                              color="info"
                              outlined
                            >
                              {{ $t("displayRule.setCondition") }}
                            </v-btn>
                          </template>
                          <v-card
                            width="200"
                            tile
                            outlined
                            class="pa-3"
                            style="max-height:250px; overflow:scroll"
                          >
                            <v-list dense class="pa-0 align-center">
                              <v-list-item
                                class="pa-0 py-1"
                                dense
                                :ripple="true"
                                v-for="(item, index) in filteredFormFieldInfos"
                                :key="item._id"
                                style="min-height:20px; font-size:12px"
                                :style="
                                  index != filteredFormFieldInfos.length - 1
                                    ? 'border-bottom: 1px solid #eeee'
                                    : ''
                                "
                                @click="addCond(item)"
                              >
                                <!-- @click="addCond(0, '', item)" -->

                                <!-- <v-list-item-content class="px-2"> -->
                                <!-- <div class="d-flex flex-row justify-start align-center"> -->
                                <font-awesome-icon
                                  :icon="getWidget(item.widgetId).icon"
                                  class="fa-fw mr-2"
                                ></font-awesome-icon>
                                <span class="">{{ item.label }}</span>
                                <!-- <v-list-item-title style="font-size:12px" v-text="item.label"></v-list-item-title> -->
                                <!-- </div> -->
                                <!-- </v-list-item-content> -->
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" class="py-0 mt-1 px-0">
                        <span style="font-size:12px" class="grey--text">{{
                          $t("displayRule.description")
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row v-else style="font-size: 13px">
                      <v-col cols="12" class="pr-8">
                        <v-row
                          v-for="(items, index) in conditions"
                          :key="index"
                          class=""
                        >
                          <v-col
                            class="py-0 start-node-drawer-filter"
                            cols="12"
                            v-for="(cond, index2) in items"
                            :key="index2"
                          >
                            <v-hover v-slot="{ hover }">
                              <div
                                class="d-flex flex-column pl-4"
                                :class="hover ? 'grey lighten-4' : ''"
                                style="border-left: #2296f3 1px solid"
                              >
                                <v-btn
                                  small
                                  color="error"
                                  fab
                                  class=""
                                  v-show="hover"
                                  style="height:17px; width:17px; position: absolute; right:50px; transform: translate(5px,-7px)"
                                >
                                  <v-icon
                                    @click="deleteCond(cond.id, index, index2)"
                                    class="start-node-drawer-icon-bin"
                                    size="13"
                                    fab
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                                <div class="pl-3 pt-2">
                                  {{
                                    getFieldById(cond.fieldType, cond.fieldId)
                                      .label
                                  }}
                                </div>
                                <div class="d-flex align-center pb-3 px-3">
                                  <div class="flex-grow-1">
                                    <component
                                      ref="conditions"
                                      :content="cond.content"
                                      :fieldType="cond.fieldType"
                                      :formFieldInfos="formFieldInfos"
                                      :rules="getFieldRules(cond.fieldId)"
                                      :is="
                                        getFieldTypeComponent(
                                          cond.fieldType,
                                          cond.fieldId
                                        )
                                      "
                                      :fieldProperties="
                                        getFieldById(
                                          cond.fieldType,
                                          cond.fieldId
                                        ).properties
                                      "
                                      :formInfo="
                                        getRelatedTableInfo(
                                          cond.fieldType,
                                          cond.fieldId
                                        )
                                      "
                                      @updateType="
                                        $set(cond.content, 'type', $event)
                                      "
                                      @updateRule="
                                        $set(cond.content, 'rule', $event)
                                      "
                                      @updateValue="
                                        $set(cond.content, 'value', $event)
                                      "
                                      :types="types"
                                    >
                                    </component>
                                  </div>
                                </div>
                              </div>
                            </v-hover>

                            <v-btn
                              fab
                              outlined
                              v-if="index2 != items.length - 1"
                              @click="toOrHandler(index, index2)"
                              class="white--text info"
                              style="height: 25px; width:25px; font-size:13px;left:-12px"
                            >
                              {{ $t("displayRule.and") }}
                            </v-btn>
                            <div v-if="index2 == items.length - 1">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                  <div
                                    v-on="on"
                                    class="d-inline py-2 px-3 ml-5"
                                    style="cursor: pointer;"
                                  >
                                    <span style="font-size: 12px;"
                                      >+
                                      {{ $t("displayRule.addCondition") }}</span
                                    >
                                  </div>
                                </template>
                                <v-card
                                  outlined
                                  width="200"
                                  class="pa-3"
                                  style="max-height:250px; overflow:scroll"
                                >
                                  <v-list dense class="pa-0 align-center">
                                    <v-list-item
                                      class="pa-0 py-1"
                                      dense
                                      :ripple="true"
                                      v-for="item in filteredFormFieldInfos"
                                      :key="item._id"
                                      style="min-height:20px; font-size:12px"
                                      :style="
                                        index !=
                                        filteredFormFieldInfos.length - 1
                                          ? 'border-bottom: 1px solid #eeee'
                                          : ''
                                      "
                                      @click="addCond(item, index)"
                                    >
                                      <!-- @click="addCond(cond.id, 'AND', item)" -->
                                      <!-- <v-list-item-content class="px-2">
                                                                            <div class="d-flex flex-row justify-start align-center">
                                                                                <font-awesome-icon :icon="getWidget(item.widgetId).icon" class="fa-fw mr-2"></font-awesome-icon>
                                                                                <v-list-item-title style="font-size:12px" v-text="item.label"></v-list-item-title>
                                                                            </div>
                                                                        </v-list-item-content> -->
                                      <font-awesome-icon
                                        :icon="getWidget(item.widgetId).icon"
                                        class="fa-fw mr-2"
                                      ></font-awesome-icon>
                                      <span class="">{{ item.label }}</span>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-menu>
                            </div>
                          </v-col>
                          <!-- <v-col v-if="index!=conditions.length-1" cols="12">
                                                    <div @click="toAndHandler(index)" style="font-size:14px; position: absolute; left:45%; cursor: pointer" class="white px-4 mt-1">
                                                        <v-btn outlined class="warning white--text" fab style="width: 25px; height: 25px; font-size: 13px">{{$t('displayRule.or')}}</v-btn>
                                                    </div>
                                                    <v-divider class="my-4"/>
                                                </v-col> -->
                          <v-col
                            v-if="index != conditions.length - 1"
                            cols="12"
                            class="d-flex align-center"
                          >
                            <div
                              style="border-top:1px solid #d9d9d9;"
                              class="flex-grow-1"
                            />
                            <div style="">
                              <div
                                @click="toAndHandler(index)"
                                style="font-size:14px; cursor: pointer"
                                class="px-4 mt-1"
                              >
                                <v-btn
                                  outlined
                                  class="warning white--text"
                                  fab
                                  style="width: 25px; height: 25px; font-size: 13px"
                                  >{{ $t("displayRule.or") }}</v-btn
                                >
                              </div>
                            </div>
                            <div
                              style="border-top:1px solid #d9d9d9;"
                              class="flex-grow-1"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="mt-12">
              <span class="font-weight-bold" style="font-size:13px">{{
                $t("displayRule.takeActions")
              }}</span>
              <span
                class="ml-5 error--text"
                style="font-size:12px"
                v-if="is_noAction"
                >{{ $t("displayRule.missingAction") }}</span
              >
              <div class="mt-2 px-4">
                <v-hover
                  v-for="(action, index) in actions"
                  :key="index"
                  v-slot="{ hover }"
                  class=""
                >
                  <div>
                    <v-btn
                      small
                      color="error"
                      fab
                      class=""
                      v-show="hover"
                      style="height:17px; width:17px; position: absolute; right:50px; transform: translate(10px,-7px)"
                    >
                      <v-icon
                        @click="deleteAction(index)"
                        class="start-node-drawer-icon-bin"
                        size="13"
                        style=""
                        fab
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <div
                      class="d-flex align-center py-3 px-4"
                      :class="hover ? 'grey lighten-4' : ''"
                      style=" cursor: pointer"
                    >
                      <ActionSelectField
                        :action="action"
                        :formFieldInfos="formFieldInfos"
                        :actionFields="action.fields"
                        :actionId="action.actionId"
                        :actionValue="action.value"
                        @updateAction="$set(action, 'actionId', $event)"
                        @updateActionFields="$set(action, 'fields', $event)"
                        @updateActionValue="$set(action, 'value', $event)"
                        :formUIElementInfos="formUIElementInfos"
                        :types="types"
                      />
                    </div>
                  </div>
                </v-hover>
              </div>
              <v-btn
                small
                v-if="!actions.length"
                @click="addAction()"
                text
                class="d-block mt-2 ml-3"
                style="padding:0px !important; border: 1px #bbbb dashed; width: 100px; border-radius: 8px"
              >
                <v-icon
                  size="15"
                  color="#bbbb"
                  class=""
                  style="min-width:10px; width:10px; min-height:10px; height:10px"
                  >mdi-plus</v-icon
                >
                <span style="color: #bbbb; font-size:12px;" class="ml-1">
                  {{ $t("displayRule.addAction") }}
                </span>
              </v-btn>
              <div
                v-else
                class="ml-5 d-inline py-2 px-2"
                style="cursor: pointer"
                @click="addAction()"
              >
                <span style="font-size: 12px;"
                  >+ {{ $t("displayRule.addAction") }}</span
                >
              </div>
            </div>
          </v-card-text>
        </perfect-scrollbar>

        <!-- <v-card-actions class="pa-0 d-flex justify-end mr-5">
                <v-btn class="elevation-0" color="success" small @click="saveRule">確定</v-btn>
                <v-btn small class="elevation-0" color="error" @click="bl_newRuleDialog=false">取消</v-btn>
            </v-card-actions> -->

        <dialog-actions
          @onCommand="onCommandHandler"
          :buttons="['close', 'save']"
        ></dialog-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { upperFirst } from "lodash";

import RuleText from "../rules/RuleText";
import RuleNumber from "../rules/RuleNumber";
import RuleDate from "../rules/RuleDate";
import RuleSelection from "../rules/RuleSelection";
import RuleMembers from "../rules/RuleMembers";
import RuleDepartments from "../rules/RuleDepartments";
import RuleRadio from "../rules/RuleRadio";
import RuleRelatedRecord from "../rules/RuleRelatedRecord";
import RuleChildTable from "../rules/RuleChildTable";
import RulePhone from "../rules/RulePhone"
import widgetHelperMixin from "@/mixins/widgetHelperMixin";
import filterHelperMixin from "@/mixins/filterHelperMixin";
import ActionSelectField from "../units/ActionSelectField";
import dialogActions from "@/components/dialogs/comps/DialogActions";
import WorkflowSettingUtils from "@/pages/admin/workflow/utils/setting";


export default {
  name: "EditRuleDialog",
  mixins: [widgetHelperMixin, filterHelperMixin],
  props: {
    formFieldInfos: Array,
    value: Boolean,
    ruleIdArr: Array,
    formData: Object,
    formUIElementInfos: Array,
  },
  components: {
    RuleText,
    RuleNumber,
    RuleDate,
    RuleSelection,
    RuleMembers,
    RuleDepartments,
    RuleRadio,
    RuleRelatedRecord,
    RuleChildTable,
    RulePhone,
    ActionSelectField,
    dialogActions,
  },
  data() {
    return {
      bl_showConDialog: false,
      selectedItem: null,
      condArr: [],
      table: null,
      conditions: [],
      actions: [],
      currentAction: "or",
      temp: {},
      condCount: 0,
      ruleName: "",
      is_noCond: false,
      is_noAction: false,
      relatedTableInfos: [],
      active: true,
      types: [
        {
          id: 1,
          label: this.$t("widgets.properties.fixedColumnValue"),
          type: "label",
        },
        {
          id: 2,
          label: this.$t("widgets.properties.columnValue"),
          type: "label",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.loadRelatedTableInfos();
      if (this.formData) {
        this.ruleName = this.formData.name;
        this.conditions = this.formData.conditions;
        this.actions = this.formData.actions;
        this.active = this.formData.active;
      }
    });
  },
  watch: {
    bl_showConDialog(val) {
      if (!val) {
        this.selectedItem = null;
      }
    },
  },
  computed: {
    criteriaConfigs() {
      return this.$store.getters.criteriaConfigs;
    },
    filteredFormFieldInfos() {
      var excludeArr = [
        "owner",
        "updatedAt",
        "createdAt",
        "createdBy",
        "capitalizedAmount",
        "autoNumbering",
        "location",
        "richText",
        "region",
      ];
      return this.formFieldInfos.filter(
        (item) => !excludeArr.includes(item.type)
      );
    },
    bl_newRuleDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    fieldIdsWithProperties() {
      const vm = this;
      var result = [];
      for (let i = 0; i < vm.formFieldInfos.length; i++) {
        var loopFieldInfo = vm.formFieldInfos[i];

        if (loopFieldInfo.type != "otherTableField")
          var filterType = vm.getFilterKeyByFieldInfo(
            vm.$store.getters.widgetMap,
            loopFieldInfo
          );
        else
          var filterType = vm.getFilterKeyByFieldInfo(
            vm.$store.getters.widgetMap,
            loopFieldInfo.relatedField
          );

        if (filterType) {
          result.push({
            id: loopFieldInfo.fieldId,
            label: loopFieldInfo.label,
            filterType: filterType,
            fieldInfo: loopFieldInfo,
          });
        }
      }
      return result;
    },
    filterInfos() {
      return this.$store.getters.filterInfos;
    },
  },
  methods: {
    // updateContentValue(event){
    //     console .log('updateContentValue::event',event)

    //     // cond.content.value=$event
    // },
    onCommandHandler(payload) {
      switch (payload.command) {
        case "save":
          this.saveRule();
          break;
        default:
          this.bl_newRuleDialog = false;
      }
    },
    getRelatedTableInfo(fieldType, fieldId) {
      var field = this.getFieldById(fieldType, fieldId);
      if (field.type == "relatedRecord")
        return this.relatedTableInfos[field.properties.dataSource] || {};
    },
    async loadRelatedTableInfos(fieldInfos, callback) {
      const vm = this;
      var formId = this.$route.params.itemId;
      const getParams = {
        urlCommand: "/forms/" + formId + "/relatedTableInfos",
      };
      await vm.$store.dispatch("AUTH_GET", getParams).then((response) => {
        vm.relatedTableInfos = response.relatedTableInfos;
        vm.formFieldInfos.forEach((item, index, array) => {
          if (item.type == "otherTableField") {
            var relatedRecordId = item.properties.fieldForRelatedTable;
            var relatedFieldId = item.properties.relatedTableField;
            var relatedRecord = vm.formFieldInfos.find(
              (item) => item._id == relatedRecordId
            );
            if (relatedRecord) {
              var relatedTableId = relatedRecord.properties.dataSource;
              var relatedTable = vm.relatedTableInfos[relatedTableId];
              var relatedField = relatedTable.fieldInfos.find(
                (item) => item._id == relatedFieldId
              );
              array[index]["relatedField"] = relatedField;
            }
          }
        });
      });
    },
    getFieldRules(fieldId) {
      let vm = this;
      var type = this.fieldIdsWithProperties.find((item) => item.id == fieldId)
        .filterType;
      var filterInfos = {...this.filterInfos[type]};
      if(type==='D'){
        filterInfos.options = ['isTheDate', 'notTheDate', 'withinPeriod', 'notWithinPeriod', 'earlierThan', 'laterThan', 'empty', 'notEmpty']
      }
      filterInfos.options = filterInfos.options.map((item, index) => {
        return {
          id: item,
          label: item,
          type: "label",
          value: item,
        };
      });
      return filterInfos;
    },
    async saveRule() {
      this.is_noCond = this.conditions.length == 0;
      this.is_noAction = this.actions.length == 0;
      if (!this.$refs.ruleName.validate() || this.is_noCond || this.is_noAction)
        return;
      let vm = this;
      var id = "0";
      if (this.formData) id = this.formData._id;
      // var id = this.ndStr(10)
      // if(vm.ruleIdArr.length)
      //     while(vm.ruleIdArr.find(item=>item==id)){id = this.ndStr(10)}
      var obj = {
        id: id,
        name: vm.ruleName,
        conditions: vm.conditions,
        actions: vm.actions,
        active: vm.active,
      };
      var formId = this.$route.params.itemId;
      const postData = {
        urlCommand: "/displayRule/" + formId,
        data: {
          content: obj,
        },
      };
      //return
      await this.$store.dispatch("AUTH_POST", postData).then((response) => {
        this.$emit("updateRules", response.rules);
        if (response.success) {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.display_rule.UPDATE_DISPLAY_RULE,
            }
          });
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_table_setup_menu",
            data: {
              function_button: this.$sensors.events.plus_sheet_mani.display_rule.UPDATE_DISPLAY_RULE,
            }
          });
          this.$emit("setSnackbar", {
            val: true,
            text: this.$t("displayRule.successSave"),
            color: "success",
          });
          this.bl_newRuleDialog = false;
          this.$store.dispatch("SET_DISPLAY_RULE", {
            rules: response.rules,
          });
        } else {
          this.$emit("setSnackbar", {
            val: true,
            text: this.$t("displayRule.FailSave"),
            color: "error",
          });
        }
        //  this.$emit('newRules', response)
        // if(!this.formData)
        //     this.$emit('newRule', obj)
        // else
        //     this.$emit('updateRule', obj)
      });
    },
    deleteAction(index) {
      this.actions.splice(index, 1);
    },
    addAction() {
      var obj = {
        actionId: "1",
        fields: [],
        value: null,
      };
      this.actions.push(obj);
    },
    toAndHandler(index1) {
      this.conditions[index1 + 1].forEach((item) => {
        this.conditions[index1].push(item);
      });
      this.conditions.splice(index1 + 1, 1);
    },
    toOrHandler(index1, index2) {
      var arr2 = this.conditions[index1];
      var arr1 = [];
      arr1 = arr2.splice(0, index2 + 1);
      this.conditions.splice(index1, 0, arr1);
    },
    getFieldById(fieldType, fieldId) {
      if (fieldType == "otherTableField") {
        var field = this.formFieldInfos.find((item) => item.fieldId == fieldId);
        var relatedField = field.relatedField;
        relatedField.label = field.label;
        return relatedField;
      } else return this.formFieldInfos.find((item) => item.fieldId == fieldId);
    },
    getFieldTypeComponent(type, fieldId) {
      if (type == "otherTableField") {
        var relatedField = this.formFieldInfos.find(
          (item) => item.fieldId == fieldId
        ).relatedField;
        type = relatedField.type;
      }
      // switch(type){
      //     case 'text':
      //     case 'email':
      //     case 'phone':
      //     case 'idCard':
      //     case 'autoNumbering':
      //     case 'textCombination':
      //         return 'RuleText'
      //     case 'number':
      //     case 'amount':
      //     case 'expression':
      //     case 'capitalizedAmount':
      //     case 'rating':
      //     case 'summaryField':
      //         return 'RuleNumber'
      //     case 'date':
      //     case 'createdAt':
      //     case 'updatedAt':
      //         return 'RuleDate'
      //     case 'singleSelection':
      //     case 'multipleSelection':
      //         return 'RuleSelection'
      //     case 'members':
      //         return 'RuleMembers'
      //     case 'departments':
      //         return 'RuleDepartments'
      //     case 'attachments':
      //     case 'signature':
      //     case 'yesNo':
      //         return 'RuleRadio'
      //     case 'relatedRecord':
      //         return 'RuleRelatedRecord'
      //     case 'childTable':
      //         return 'RuleChildTable'
      //     default: return 'RuleText'
      // }
      const target = WorkflowSettingUtils.getFilteringFieldTypeComponent(type);
      return `Rule${upperFirst(target)}`;
    },
    submitCond() {
      this.bl_showConDialog = false;
      this.condArr.push(this.selectedItem);
    },
    columnChange(cond) {
      cond.value1 = null;
      cond.value2 = null;
      cond.ruleId = null;
    },
    ruleChange(cond) {
      cond.value1 = null;
      cond.value2 = null;
    },

    deleteCond(id, pos, pos2) {
      console.log("id: " + id + "~~~ pos: " + pos + "~~~ pos2: " + pos2);
      if (this.conditions[pos].length == 1) {
        this.conditions.splice(pos, 1);
      } else {
        this.conditions[pos].splice(pos2, 1);
      }
    },
    getTempId() {
      return "_" + Date.now().toString();
    },
    addCond(item, index = 0) {
      console.log({ item, index });
      var rules = this.getFieldRules(item.fieldId);
      console.log("rules", rules);
      var cond = {
        id: this.getTempId(),
        fieldType: item.type,
        fieldId: item.fieldId,
        content: {
          rule: rules.options[0].value,
          value: null,
          type: 1,
        },
      };
      var length = this.conditions.length;
      if (length == 0) {
        this.conditions.splice(0, 0, [cond]);
      } else {
        this.conditions[index].push(cond);
      }
    },
    addCond2(id, type, item) {
      console.log("addCond::", { id, type, item });
      // console.log(this.conditions)
      console.log({ id, type, item });
      this.condCount++;
      var pos;
      this.conditions.forEach((item, index) => {
        let res = item.find((item2) => item2.id == id);
        if (res) {
          pos = index;
          return;
        }
      });
      var cond = {
        id: this.getTempId(),
        fieldType: item.type,
        fieldId: item.fieldId,
        content: {
          rule: null,
          value: null,
          type: 1,
        },
      };
      var length = this.conditions.length;
      if (length == 0) {
        this.conditions.splice(length, 0, [cond]);
      } else {
        this.conditions[pos].push(cond);
      }
      // this.conditions = JSON.parse(JSON.stringify(this.conditions))
    },
    saveDialog() {
      var tableObj = this.tables.find((item) => item.id == this.table);
      this.node.properties = {};
      this.node.properties.trigger = {};
      var tar = this.node.properties.trigger;
      tar.type = "trigger";
      tar.table = tableObj;
      console.log(this.node);
    },
    closeDialog() {
      this.$emit("update:dialog", false);
    },
    ndStr(len) {
      let text = " ";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  box-shadow: unset !important;
}
</style>
