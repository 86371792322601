<template>
  <v-card class="category-selection fill-height">
    <category-section
      v-for="(section,index) in sections"
      :key="index"
      :selectedIds="value"
      :selectedFormIds="selectedFormIds"
      @setAll="setAll(section)"
      @clearAll="clearAll(section)"
      @toggle="val=>toggle(val)"
      :section="section">
    </category-section>
  </v-card>
</template>

<script>
import categorySection from './CategorySection'

export default {
  name: 'categorySelection',
  components: {
    categorySection
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    formConfigs: Array,
    sections: Array,
    selectedFormIds: {
      type: Array,
      default () {
        return []
      }
    },
    /* [section]
    
    section = {
      sectionName: String,
      options: Array,
          [
            {
              _id: ObjectId,
              active: Boolean,
              colorSet: String, // '#000000/#C9E6FC',
              isDeleted: Boolean,
              label: String,
              order: Number
            } 
          ]
      useCategoryColor: Boolean
    }
    
    */
    value: [], // selectedCategoryIds
  },
  methods: {
    setAll (section) {
      const vm = this
      var selection = JSON.parse(JSON.stringify(this.value))
      for (let i = 0; i < section.options.length; i++) {        
        const loopOption = section.options[i]
        const index = selection.indexOf(loopOption._id)
        if (index === -1) {
          selection.push(loopOption._id)
        }
      }
      vm.$emit('input', selection)
    },
    clearAll (section) {
      const vm = this
      var selection = JSON.parse(JSON.stringify(this.value))
      for (let i = 0; i < section.options.length; i++) {        
        const loopOption = section.options[i]
        const index = selection.indexOf(loopOption._id)
        if (index >= 0) {
          selection.splice(index, 1)
        }
      }
      vm.$emit('input', selection)
    },
    toggle (categoryId) {
      var selection = JSON.parse(JSON.stringify(this.value))
      const index = selection.findIndex(id => id === categoryId)
      if (index >= 0) {
        selection.splice(index, 1)
      } else if (index < 0) {
        selection.push(categoryId)
      }
      this.$emit('input', selection)    
    }
  }
}
</script>

<style>
.category-selection {
  min-width: 200px;
}
</style>