<template>
  <v-menu offset-y @input="validate" :disabled="disabled">
    <template v-slot:activator="{ on }">
      <div class="d-flex flex-column" :ripple="!disabled">
        <div class="d-flex">
          <v-card
            :disabled="disabled"
            rounded
            flat
            outlined
            class="pa-1 department-pulldown flex-grow-1"
            :class="{
              'error-border': validationState === 'error',
            }"
            style="min-height: 40px"
            v-on="on"
          >
            <v-chip
              v-for="dept in inputValue"
              color="primary"
              :key="dept"
              class="mx-1"
            >
              {{ labelMap[dept] }}
            </v-chip>
          </v-card>
          <v-btn
            v-if="hasAdd"
            fab
            x-small
            depressed
            color="primary"
            class="ma-1"
            @click="$emit('newDepartment')"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </div>
        <div
          v-if="validationState === 'error' && errorBucket.length > 0"
          class="v-messages theme--light error--text"
        >
          {{ errorBucket[0] }}
        </div>
      </div>
    </template>
    <v-card>
      <v-treeview
        open-all
        class="department-tree"
        dense
        v-model="inputValue"
        :items="items"
        item-key="id"
        item-text="name"
        selectable
        selection-type="independent"
      />
    </v-card>
  </v-menu>
</template>
<script>
import { VInput } from "vuetify/lib";

export default {
  name: "Department",
  extends: VInput,
  props: {
    items: Array,
    value: Array,
    hasAdd: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        if (!this.value) return [];
        else if (!Array.isArray(this.value)) return [this.value];
        else return this.value;
      },
      set(val) {
        if (this.multiple) {
          this.$emit("input", val);
        } else {
          this.$emit("input", val[val.length - 1] || "");
        }
      },
    },
    labelMap() {
      const result = {};
      this.items.forEach((dept) => {
        this.getChildrenRecursion(dept, result);
      });
      return result;
    },
  },
  methods: {
    getChildrenRecursion(dept, result = {}) {
      Object.assign(result, { [dept.id]: dept.name });
      if (dept.children.length > 0) {
        dept.children.forEach((child) => {
          this.getChildrenRecursion(child, result);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.department-pulldown {
  border: 1px rgba(0, 0, 0, 0.42) solid;
}
.error-border {
  border: 2px #d55b3e solid;
}
</style>
