import parentChildSettingsTitle from '@/components/dialogs/viewSettingsTabs/comps/ParentChildSettingsTitle'
import parentChildSelectionMenu from '@/components/dialogs/viewSettingsTabs/comps/ParentChildSelectionMenu'

import {getFirstAttachmentsFieldId} from '@/helpers/FormHelpers'
import {LEVEL_DIAGRAM_CARD_CONFIG} from '@/const/defaults'

const mixin = {
    components: {
        parentChildSettingsTitle,
        parentChildSelectionMenu
    },

    data () {
      return {
        activeFormInfo: null,
        formInfos: {},
        // formInfos = {
        //    {formId1}: {..},
        //    {formId2}: {...}
        // }
        relatedFieldItems: [],
        lastFormInfo: null,
        childRelationFieldItems: [],
        lastFormId: null
      }
    },
    // watch: {
    //     'parentChildFieldFormList': function() {
    //         alert('watch(parentChildFieldFormList')
    //         this.loadRelationItems()
    //     }
    // },
    mounted () {
      // console.log('parentChildFieldFormMixin :: mounted :: levelType = ' + this.levelType)
      // console.log('parentChildFieldFormMixin :: mounted: vm.form: ', this.form)
      this.storeFormInfo(this.form)
      // console.log('parentChildFieldFormMixin :: mounted starts')
      if (this.levelType === 'multipleTables') {        
        this.initMultipleTableSelection()
      }
      // console.log('parentChildFieldFormMixin :: mounted ends')
    },
    watch: {
      levelType: function(newVal) {
        if (newVal === 'multipleTables') {
          this.initMultipleTableSelection()
        }
      },
      lastFormId(val){
        const vm = this
        // var result = []
        vm.childRelationFieldItems = []
        if (val) {
          for (let i = 0; i < vm.lastFormInfo.fieldInfos.length; i++) {
            const loopFieldInfo = vm.lastFormInfo.fieldInfos[i]
            if (vm.isValidRelatedField(loopFieldInfo)) {
              if (loopFieldInfo.properties.dataSource) {
                const dataSource = loopFieldInfo.properties.dataSource
                // result.push({
                //   fieldId: loopFieldInfo.fieldId,                
                //   fieldLabel: loopFieldInfo.label,
                //   relatedFormId: dataSource,
                //   relatedFormName: dataSource.dataTableName||''
                // })
                const ds = {
                  teamId: vm.form.teamId,
                  appId: vm.form.appId,
                  formId: dataSource
                }
                vm.loadFormInfo(ds, null, {
                  fieldId: loopFieldInfo.fieldId,                
                  label: loopFieldInfo.label
                })
              }
            }
          }
        }
      }
    },
    computed: {
//         lastFormInfo () {
//           const vm = this

//           var result = vm.form
//           if (vm.parentChildFieldFormList && vm.parentChildFieldFormList.length > 0) {
//             const lastFormId = vm.parentChildFieldFormList[vm.parentChildFieldFormList.length-1].relatedFormId
// console.log('computed(lastFormInfo) :: lastFormId = ' + lastFormId)
//             if (vm.formInfoExists(lastFormId)) {
//               return vm.formInfos[lastFormId]
//             }
//           }
//           return result
//         },
        dummyItem () {
            return {
                fieldId: '',
                fieldLabel: this.form.label,
                relatedFormId: this.form._id,
                relatedFormName: this.form.label
            }              
        },
      allWidgets () {
        return this.$store.getters.allWidgets
      },
      relationWidgetIcon () {
        const vm = this
        const relatedRecordWidget = vm.allWidgets.find(widget => widget.key === 'relatedRecord')
        return relatedRecordWidget ? relatedRecordWidget.icon : ''
      },
      fieldId: {
        get () {
          return this.parentChildFieldFormList
        },
        set (val) {
          this.$emit('input', val)
        }
      },
      formIdsInPath () {
        const vm = this
        var result = []
        for (let i = 0; i < vm.parentChildFieldFormList.length; i++) {
          const loopItem = vm.parentChildFieldFormList[i]
          result.push(loopItem.relatedFormId)
        }
        return result
      },
      // childRelationFieldItems () {
      //   const vm = this
      //   var result = []
      //   if (vm.lastFormInfo) {
      //     for (let i = 0; i < vm.lastFormInfo.fieldInfos.length; i++) {
      //       const loopFieldInfo = vm.lastFormInfo.fieldInfos[i]
      //       if (vm.isValidRelatedField(loopFieldInfo)) {
      //         if (loopFieldInfo.properties.dataSource) {
      //           const dataSource = loopFieldInfo.properties.dataSource
      //           result.push({
      //             fieldId: loopFieldInfo.fieldId,                
      //             fieldLabel: loopFieldInfo.label,
      //             relatedFormId: dataSource,
      //             relatedFormName: dataSource.dataTableName||''
      //           })
      //           const ds = {
      //             teamId: vm.form.teamId,
      //             appId: vm.form.appId,
      //             formId: dataSource
      //           }
      //           vm.loadFormInfo(ds)
      //         }
      //       }
      //     }
      //   }
      //   return result
      // },
  
      form () {
        return this.$store.getters.currentForm
      },
  
      fieldInfos () {
        const vm = this
        var result = []
        if (vm.form) {
          result = vm.form.fieldInfos
        }
        return result
      }
  
    },
    methods: {
      initMultipleTableSelection() {
        const vm = this
        // console.log(1)
        vm.loadRelationItems()
        // console.log(2)
        
        // It is concerned with childRelation generation
        vm.lastFormInfo = vm.form
        vm.lastFormId = vm.lastFormInfo._id
        // console.log(3)
        // vm.fetchLastFormInfo()
      },
      isValidRelatedField (fieldInfo) {
        const vm = this
        return fieldInfo.type === 'relatedRecord' &&
        fieldInfo.properties.relatedRecordQuantity==='multiple' 
        // && !vm.formIdsInPath.includes(fieldInfo.properties.dataSource)
      },

      getFormInfo (index) {
        const vm = this
        if (index === 0) {
          return vm.form
        } else {
          const parentChildFieldFormItem = vm.parentChildFieldFormList[index]
          const relatedFormId = parentChildFieldFormItem.relatedFormId
          const formInfo = vm.formInfos[relatedFormId] ? vm.formInfos[relatedFormId] : null
          // console.log('getFormInfo :: formInfo: ', formInfo)
          return formInfo
        }
      },
      storeFormInfo (formInfo) {
          const vm = this
          // console.log('storeFormmInfo :: formInfo: ', formInfo)
          vm.formInfos[formInfo._id] = formInfo
          // console.log('storeFormmInfo :: formInfos: ', vm.formInfos)
      },
        getFieldName (fieldInfos, fieldId) {
            const vm = this
            const fieldInfo = fieldInfos.find(info => info.fieldId === fieldId)
            return fieldInfo ? fieldInfo.label : ''
        },
      onDeleteHandler (item) {
        const vm = this
        console.log('removeItem(..) item: ', item)
        // console.log('removeItem(..) parentChildFieldFormList: ', vm.parentChildFieldFormList)
        const index = vm.parentChildFieldFormList.findIndex(
          v => v.fieldId === item.fieldId && v.formId === item.relatedFormId
        )
        vm.parentChildFieldFormList.splice(index)
        vm.relatedFieldItems.splice(index)
        console.log('onDeleteHandler::item', item)
        if(vm.formInfos[item.relatedFormId]){
          console.log('indel', item.relatedFormId)
          delete vm.formInfos[item.relatedFormId]
          console.log('vm.formInfos----', Object.keys(vm.formInfos))
        }
        vm.fetchLastFormInfo()
        // console.log('removeItem(..) index = ' + index)
      },
      selectItem (item) {
        const vm = this

        // Update data
        // console.log('selectItem: vm.parentChildFieldFormList: ', vm.parentChildFieldFormList)
        // console.log('selectItem: item: ', item)
        // console.log('selectItem: vm.formInfos: ', vm.formInfos)
        var cardConfig = JSON.parse(JSON.stringify(LEVEL_DIAGRAM_CARD_CONFIG))
        var formInfo = vm.formInfos[item.relatedFormId]
        cardConfig.cardImageFieldId = getFirstAttachmentsFieldId(formInfo.fieldInfos)
        
        if(vm.view.viewType=='project'){
          vm.parentChildFieldFormList.push({
            fieldId: item.fieldId,
            relatedTeamId: formInfo.teamId,
            relatedAppId: formInfo.appId,
            relatedFormId: item.relatedFormId,
            col_project: null,
            col_start: null,
            col_end: null,
            col_milestone: null,
            col_progress: null,
          })
        }else{
          vm.parentChildFieldFormList.push({
            fieldId: item.fieldId,
            relatedTeamId: formInfo.teamId,
            relatedAppId: formInfo.appId,
            relatedFormId: item.relatedFormId,
            cardConfig: cardConfig
          })
        }
  
        // update UI
        vm.relatedFieldItems.push(item)
        // vm.lastFormId = item.relatedFormId
        
        vm.fetchLastFormInfo()
        // vm.loadChildRelationFieldItems(item.formId)
        // console.log('selectItem: item: ', item)
      },
  
      fetchLastFormInfo () {
        console.log('fetchLastFormInfo')
        const vm = this
        var ds = {
          teamId: vm.form.teamId,
          appId: vm.form.appId,
          formId: vm.form._id
        }
        if (vm.parentChildFieldFormList && vm.parentChildFieldFormList.length > 0) {
          if (vm.parentChildFieldFormList[vm.parentChildFieldFormList.length-1].relatedTeamId &&
            vm.parentChildFieldFormList[vm.parentChildFieldFormList.length-1].relatedAppId &&
            vm.parentChildFieldFormList[vm.parentChildFieldFormList.length-1].relatedFormId) {
            ds = {
              teamId: vm.parentChildFieldFormList[vm.parentChildFieldFormList.length-1].relatedTeamId,
              appId: vm.parentChildFieldFormList[vm.parentChildFieldFormList.length-1].relatedAppId,
              formId: vm.parentChildFieldFormList[vm.parentChildFieldFormList.length-1].relatedFormId 
            }
          } else {
            vm.parentChildFieldFormList = []          
          }
        }
        // console.log('fetchLastFormInfo :: formId = ' + formId)
        // console.log('formInfos ids (before): ' + Object.keys(vm.formInfos).join(','))
        if (vm.formInfoExists(ds.formId)) {
          vm.lastFormInfo = vm.formInfos[ds.formId]
        }
        vm.lastFormId = ds.formId
        // vm.loadFormInfo(ds,formInfo => {
        //   vm.lastFormInfo = formInfo
        // })
      },

      loadFormInfo (ds, callback, fieldInfo) {
        const vm = this
        // console.log('parentChildFieldFormMixin.loadFormInfo: dataSource: ', dataSource)
        // if (!vm.formInfoExists(ds.formId)) {
          // console.log('form id not exists in forminfos')
          vm.$store.dispatch('FETCH_DATASOURCE_INFO', ds).then(
            response => {
              console.log('loadFormInfo::response', response)
              console.log('vm.lastFormId', vm.lastFormId)
              vm.childRelationFieldItems.push({
                  fieldId: fieldInfo.fieldId,                
                  fieldLabel: fieldInfo.label,
                  relatedFormId: response._id,
                  relatedFormName: response.label
              })
              if(vm.lastFormId==response._id){
                vm.lastFormInfo = response
              }
              vm.formInfos[ds.formId] = response
              if (typeof callback === 'function') {
                callback(response)
              }
              // console.log('formInfos ids (after): ' + Object.keys(vm.formInfos).join(','))
            }
          )
        // } else {
          // if (typeof callback === 'function') {
          //   callback(vm.formInfos[ds.formId])
          // }
        // }  
      },

      formInfoExists (formId) {
        return Object.keys(this.formInfos).includes(formId)
      },

      loadRelationItems () {
        const vm = this
        var result = [{
          fieldId: '',
          fieldLabel: '',
          relatedFormId: vm.form._id,
          relatedFormName: vm.form.label,
        }]

        // console.log('loadRelationITems :: parentChildFieldFormList = ' + vm.parentChildFieldFormList)
        console.log('loadRelationITems :: parentChildFieldFormList.length = ' + vm.parentChildFieldFormList.length)
        if (vm.parentChildFieldFormList.length > 1) {
          var promises = []
          for (let i = 1; i < vm.parentChildFieldFormList.length; i++) {
            const loopItem = vm.parentChildFieldFormList[i]
            const ds = {
              teamId:  loopItem.relatedTeamId,
              appId: loopItem.relatedAppId,
              formId: loopItem.relatedFormId,
            }
            console.log('parentChildFieldFormMixin.loadRelationItems: ds: ', ds)
            // console.log('i=' + i + ': formId = ' + formId)
            promises.push(vm.$store.dispatch('FETCH_DATASOURCE_INFO', ds))
          }
          var lastFormInfo = vm.form
          // console.log('lastFormInfo: ', lastFormInfo)
          // console.log('promises.length = ' + promises.length)

          // fetch child info
          Promise.all(promises).then(response => {
            // console.log('loadRelationItems :: lastFormInfo: ', lastFormInfo)
            // console.log('loadRelationItems :: response: ', response)
            for (let j = 1; j < vm.parentChildFieldFormList.length; j++) {
              const loopItem = vm.parentChildFieldFormList[j]
              // console.log('j=' + j + ': ' , loopItem)
              const relatedFormInfo = response[j-1]
              // console.log('relatedFormInfoi: ', relatedFormInfo)

              // field
              const fieldId = loopItem.fieldId
              // console.log('finding fieldId = ' + fieldId)
              const fieldName = vm.getFieldName(lastFormInfo.fieldInfos, fieldId)
              // console.log('loadRelationItems: fieldName = ' + fieldName)
                            // form
              const relatedFormId = loopItem.relatedFormId
              const relatedFormName = relatedFormInfo.label
              // console.log('loadRelationItems: relatedFormInfo: ', relatedFormInfo)
              vm.storeFormInfo(relatedFormInfo)

              result.push({
                fieldId: fieldId,
                fieldLabel: fieldName,
                relatedFormId: relatedFormId,
                relatedFormName: relatedFormName
              })
              vm.lastFormInfo = relatedFormInfo
              vm.lastFormId = vm.lastFormInfo._id
            }
            // console.log('loadRelatedItems.then result: ', result)
            vm.relatedFieldItems = result    
          })
        } else {
          vm.relatedFieldItems = result
        }
      },
  
    //   async getRelationInfo (fieldId, formInfo) {
    //     const vm = this
    //     const fieldInfo = formInfo.fieldInfos.find(info => info.fieldId === fieldId)
    //     const childFormInfo = await vm.getChildFormInfo(fieldInfo.properties.datatableId)
    //     return {
    //       icon: 'mdi-account',
    //       fieldLabel: fieldInfo.label,
    //       dataSourceLabel: childFormInfo.label,
    //       dataSourceInfo: childFormInfo
    //     }
    //   },
      async getChildFormInfo (formId) {
        // alert('getChildForminfo')
        const formInfo = await vm.$store.dispatch('AUTH_GET', '/forms/' + formId)
        // console.log('getChildFormInfo :; formInfo: ', formInfo)
        return formInfo
      }
    }
}

export default mixin
