<template>
  <v-dialog
    persistent
    v-model="dialog"
    v-if="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <!-- <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar> -->
      <dialog-header
        :label="title"
        @close="cancel"
        :color="options.color"
      />
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      <v-checkbox v-if="options.checkbox"
        v-model="checked"
        :label="checkboxMessage"></v-checkbox>
      <!-- <v-divider></v-divider> -->
      <v-card-actions class="pa-2 elevation-2">
        <v-spacer></v-spacer>
        <v-btn
          class="min-width-100 muted elevation-0"
          :color="options.cancelBtnColor"
          @click.native="cancel"
          small
          >{{ options.cancelMsg }}</v-btn>

        <v-btn
          v-if="options.buttons && options.buttons.length === 0"
          class="min-width-100 elevation-0"
          :color="confirmBtnColor"
          small
          @click.native="onClick(true)">{{ options.confirmMsg }}</v-btn>

        <v-btn 
          v-else
          v-for="button in options.buttons"
          :key="button.value"
          class="min-width-100 elevation-0"
          small
          :color="button.color ? button.color : options.confirmBtnColor"
          @click.native="onClick(button.value)">{{ button.label }}</v-btn>
      </v-card-actions>
     
    </v-card>
  </v-dialog>
</template>
<script>

import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
  components:{
    dialogHeader
  },
  data() {
    return {
      DEFAULT_CONFIRM_COLOR: 'danger',
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "primary",
        confirmBtnColor: "primary",
        cancelBtnColor: "muted",
        checkbox: false,
        checkboxMessage: '',
        confirmMsg: this.$t("buttons.confirm"),
        cancelMsg: this.$t("buttons.cancel"),
        buttons: [
          {label: this.$t('buttons.confirm'), value: 'confirm'}
        ],
        width: 340,
        zIndex: 200,
        meta: null,
        type: ''
      },
      checked: false,
      yesNoOption: {
        cancelBtnColor: 'muted',
        cancelMsg: this.$t('buttons.no'),
        confirmBtnColor: 'primary',
        confirmMsg: this.$t('buttons.yes'),
        buttons: []
      }
    };
  },
  methods: {
    onCommandHandler(payload){
      if(!['close','cancel'].includes(payload.command))
        this.onClick(payload.command)
      else this.cancel()
    },
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      if (options && options.color) this.options.color = options.color
      if (this.options.type === 'yesNo') {
        this.options = {...this.options, ...this.yesNoOption}
      }
      try {
        this.options = {...this.options, ...options}
      } catch(err) {
        console.log('err: ', err)
      }
      this.$set(this.options, 'confirmMsg', this.options.confirmMsg)

      // console.log('confirmDialog :: options: ', options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onClick (value) {
      const vm = this
      // console.log('ConfirmDialog :: onClick value=' + value)
      const res = {
        result: value,
        checked: vm.checked
      }
      vm.resolve(res)
      vm.dialog = false
    },
    // agree() {
    //   const vm = this
    //   const res = {
    //     result: true,
    //     checked: vm.checked
    //   }
    //   vm.resolve(res);
    //   vm.dialog = false;
    // },
    cancel() {
      const vm = this
      vm.resolve(null);
      vm.dialog = false;
    },
  },
};
</script>
