<template>
  <!--<div class="level-block-list mt-5">-->
  <!--group="levelItem"-->
    <draggable :list="listItems"
               handle=".handle"
               ghost-class="ghost"
               class="d-flex flex-column justify-start level-block-list mt-5"

               @change="log"
               @end="onDragEnded"
               :key="id">
                          <TreeNode
                              v-for="(item, index) in listItems"
                              class="handle mb-5"
                              :key="item.id"
                              :value="item"
                              :group="group"
                              @input="val=>listItems[index]=val"
                              :rowKey="rowKey"
                          ></TreeNode>
        </draggable>

</template>

<script>
    import draggable from "vuedraggable";
    import levelBlockItem from './LevelBlockItem'
    import TreeNode from "./Treenode.vue";

    export default {
      name: 'tree',
      components: {
        draggable,
        levelBlockItem,
        TreeNode
      },
      model: {
        prop: 'value',
        event: 'input'
      },
      computed: {
        listItems: {
          get() {
            return this.value
          },
          set(val) {
            this.$emit('input',val)
          }
        }
      },
      props: {
        value: {
          type: Array,
          default: () => {
            return [];
          }
        }
      },
      // watch: {
      //   value(value) {
      //     this.localValue = [...value];
      //   }
      // },
      methods: {
        log: function (evt) {
          const vm = this
          window.console.log(evt);
          // vm.$emit('input', vm.items)
          vm.$emit('onCommand', {
            command: 'detachFromPanZoom'
          })
        },
        onDragEnded (evt) {
          console.log('onDragEnded :: evt: ', evt)
          this.$emit('onCommand', {
            command: 'detachFromPanZoom'})
        }
      }
    };
</script>

<style scoped>


.cmp-node {
  display: flex;
  align-items: center;
}

.cmp-node:hover {
  background-color: #ecf0f1;
}
.cmp-drag-node {
  background-color: #1abc9c;
  opacity: 0.7;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
  background: #bdc3c7;
}

.arrow-right {
  width: 0;
  height: 0;
  margin-left: 5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #666;
  transition: 0.3s ease-in-out;
}

.active {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
  .handle {
    cursor: pointer;
  }
</style>
