import { render, staticRenderFns } from "./WitCustomFormula.vue?vue&type=template&id=cb3b48da&"
import script from "./WitCustomFormula.vue?vue&type=script&lang=js&"
export * from "./WitCustomFormula.vue?vue&type=script&lang=js&"
import style0 from "./WitCustomFormula.vue?vue&type=style&index=0&id=cb3b48da&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VDivider,VList,VListItem,VListItemContent,VMenu,VRow,VTooltip})
