<template>
  <FormElement
    :label="$t('workflow.modules.retrieveRecord.label.ifNoData')"
    name='proceedCondition'
  >
    <v-radio-group v-model="proceedCondition" @change="onChange">
      <v-radio
        v-for="item in selections"
        :key="item.key"
        :value="item.key"
        class="w-f-radio-field"
      >
        <template v-slot:label>
          <FormElement
            :hints="item.hints"
            subItem
            hintsPosition="bottom"
          >
            <span class="w-f-radio-field-text">{{ item.label }}</span>
          </FormElement>
        </template>
      </v-radio>
    </v-radio-group>
  </FormElement>
</template>

<script>
// import Validator from 'validator';
import FormElement from './../shared/formElement';
import ComboBox from './../../../form/comboBox/';
// import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import Mixin from '../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  components: {
    ComboBox,
    FormElement,
  },
  props: {
    proceedCondition: String,
    noCreate: Boolean,
    worksheet: [String, Object],
  },
  data() {
    let selections = [
      {
        key: 'continue',
        label: this.$t('workflow.modules.retrieveRecord.label.setting.continueRun'),
        hints: this.$t('workflow.modules.retrieveRecord.placeholder.continueRun'),
      },
      {
        key: 'terminate',
        label: this.$t('workflow.modules.retrieveRecord.label.setting.quitFlow'),
      },
    ];
    if (!this.noCreate) {
      selections = [
        ...selections,
        {
          key: 'createAndContinue',
          label: this.$t('workflow.modules.retrieveRecord.label.setting.createAndCondinue'),
        },
      ];
    }
    return {
      selections,
    }
  },
  methods: {
    onChange(v) {
      this.$store.dispatch(SET_SETTINGS, {
        proceedCondition: v,
        data: {},
      });
    },
  }, 
};

</script>

<style scoped>
  .w-f-radio-field {
    padding: 5px 0 !important;
    align-items: flex-start;
  }
  .w-f-radio-field-text {
    font-size: 14px;
  }
</style>
