<template>
  <div>
    <div class="workflow-shortview-worksheet">
      <span>{{ !!workflowName ? $t('workflow.modules.form.label.worksheet', { label: workflowName }) : $t('workflow.modules.form.label.404Worksheet') }}</span>
    </div>
    <div class="workflow-shortview-remark">
      {{ $t('workflow.modules.form.method.onCreateOrUpdate') }}
    </div>
  </div>
</template>

<script>


/*
* Target Module Sub-Forms Copncrete View Components
* Terry Chan
* 28/05/2021
*/
export default {
  props: {
    workflowName: String
  },
};

</script>

<style scoped>
  
</style>
