<template>
    <v-dialog
        v-model="bl_dialog_idRule"
        width="500px"
    >
        <v-card>
            <v-card-title class="">
                {{$t('widgets.properties.numberSettings')}}
            </v-card-title>
            <v-icon style="position: absolute; right:5px; top: 5px" @click="bl_dialog_idRule=false">mdi-close</v-icon>
            <v-card-text>
                <div class="d-flex align-end">
                    <div style="width:100px">{{$t('widgets.properties.autoNumberingMethod')}}</div>
                    <div>
                        <v-radio-group class="" mandatory hide-details dense v-model="setting.numberingOption" :column="false">
                            <v-radio 
                                dense 
                                :ripple="false" 
                                v-for="(option,index) in numberingOptions"
                                :key="option.value" 
                                :label="$t('widgets.properties.' + option.labelTag)"
                                :class="(index>0)? 'ml-12 pl-12':''"
                                :value="option.value"
                            />
                        </v-radio-group>
                    </div>
                </div>
                <div class="d-flex align-end mt-6" v-if="setting.numberingOption=='formatted'">
                    <div style="width:100px">{{$t('widgets.properties.digits')}}</div>
                    <div style="width:350px">
                            <v-text-field
                            type="number"
                            min="0"
                            max="9"
                            @keypress="isNumber"
                            v-model="setting.length"
                            @input="onInput"
                            dense
                            hide-details="auto"
                            outlined/>
                    </div>
                </div>
                <div class="d-flex align-end mt-6">
                    <div style="width:100px">{{$t('widgets.properties.initialValue')}}</div>
                    <div style="width:350px">
                        <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="setting.start"
                        >
                        </v-text-field>
                    </div>
                </div>
                <div class="grey--text" style="margin-left:100px; font-size:13px">{{$t('widgets.properties.initialReminder')}}</div>
                <div class="d-flex align-end mt-4">
                    <div style="width:100px">{{$t('widgets.properties.periodicReset')}}</div>
                    <div style="width:350px">
                        <v-select
                            :menu-props="{top: false, offsetY: true, contentClass: 'denseList'}"
                            hide-details
                            dense
                            outlined
                            :items="periodOptions"
                            item-value="value"
                            v-model="setting.periodOption"
                        >
                            <template v-slot:item="{item}">
                                {{$t('widgets.properties.' + item.labelTag)}}
                            </template>
                            <template v-slot:selection="{item}">
                                {{$t('widgets.properties.' + item.labelTag)}}
                            </template>
                        </v-select>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-2 pb-6 mr-3">
                <v-btn @click="bl_dialog_idRule=false" text class="px-8 py-5 grey--text">
                    <span>{{$t('buttons.cancel')}}</span>
                </v-btn>
                <v-btn @click="$emit('updateIdRule', setting)" class="px-8 py-5 primary white--text">
                    <span>{{$t('buttons.confirm')}}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props:{
        setting: Object,
        value: Boolean,
        periodOptions: Array,
        numberingOptions: Array,
    },
    computed:{
        bl_dialog_idRule:{
            get(){
                return this.value
            },
            set(value){
                this.$emit('input', value)
            }
        }
    },
    methods:{
        onInput(val){
            if (val < 0 || val > 9 || val.length > 1){
                        this.$nextTick(() => {
                this.propertyValue = val[val.length-1]
                })
                
            } else {
                this.propertyValue = val
            }
        },
        isNumber(e){
            const charCode = e.charCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                e.preventDefault();
            } else {
                return true;
            }
        }
    }
}
</script>
