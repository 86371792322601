<template>
  <v-container fluid class="w-editor-root" ref="backdrawerRef">
    <WorkflowProvider>
      <EditorHeader :ready="ready" />
      <Editor />
      <EditorSideBar v-if="ready" />
      <NodeCopyer />
    </WorkflowProvider>
  </v-container>
</template>

<script>
import WorkflowProvider from './contexts/workflowProvider';
import EditorHeader from './header';
import EditorSideBar from './sidebar';
import { OutbroundCommands } from './../constants/socket'
import Editor from './editor';
import NodeCopyer from './../components/tree/control/nodeCopy/';
import {
  RESET_WORKFLOW_VALIDATE,
} from '@/store/modules/workflow/action_types';
const {
  joinWorkflow,
  leaveWorkflow,
} = OutbroundCommands;
export default {
  name: 'WorkflowEditor',
  components: {
    WorkflowProvider,
    EditorHeader,
    Editor,
    EditorSideBar,
    NodeCopyer,
  },
  // data() {
  //   return {
  //     blurWindow: false,
  //   };
  // },
  computed: {
    ready() {
      return !!this.workflow && !!this.$store.getters.getWorkflowTree;
    },
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
    scale() {
      return this.$store.getters.layoutScale;
    },
    workflow() {
      return this.$store.getters.getWorkflowInfo;
    },
  },
  mounted() {
    // window.addEventListener('focus', this.onFocus);
    // window.addEventListener('blur', this.onBlur);
    // if (this.isPreviewMode) {
    //   this.$socket.emit(leaveWorkflow);
    // }
    
  },
  destroyed() {
    // window.removeEventListener('focus', this.onFocus);
    // window.removeEventListener('blur', this.onBlur);
    // if (this.isPreviewMode) {
    //   this.$socket.emit();
    // }
    this.$store.dispatch(RESET_WORKFLOW_VALIDATE);
  },
  methods: {
    scrollToCenter() {
      const { backdrawerRef } = this.$refs;
      if (backdrawerRef) {
        const { scrollWidth, scrollHeight } = backdrawerRef;
        // console.log(scrollHeight);
        backdrawerRef.scrollTo({ behavior: 'smooth', top: 0, left: scrollWidth / 2 - 960 });
      }
    },
  },
  watch: {
    ready(r) {
      if (r) {
        setTimeout(() => {
          this.scrollToCenter();
        }, 1000);
      }
    },
    // scale(size) {
    //   this.scrollToCenter();
    // },
    isPreviewMode(preview) {
      if (preview) {
        this.$socket.emit(joinWorkflow);
      } else {
        this.$socket.emit(leaveWorkflow);
      }
      setTimeout(() => {
        this.scrollToCenter();
      }, 1000);
    },
  },
}
</script>

<style scoped>
  .w-editor-root {
    overflow: scroll; 
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    /* padding: 22px 12px 12px 12px; */
    flex-direction: column;
    padding-bottom: 80px;
  }
</style>