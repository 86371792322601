import { render, staticRenderFns } from "./CategorySection.vue?vue&type=template&id=647e2b52&"
import script from "./CategorySection.vue?vue&type=script&lang=js&"
export * from "./CategorySection.vue?vue&type=script&lang=js&"
import style0 from "./CategorySection.vue?vue&type=style&index=0&id=647e2b52&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VChip,VIcon,VList,VListItemGroup,VSpacer})
