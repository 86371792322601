// Basic
import fsText from './FsText'
import fsNumber from './FsNumber'
import fsAmount from './FsAmount'
import fsEmail from './FsEmail'
import fsDate from './FsDate'
import fsPhone from './FsPhone'
import fsSingleSelection from './FsSingleSelection'
import fsMultipleSelection from './FsMultipleSelection'
import fsAttachments from './FsAttachments'
import fsRegion from './FsRegion'
import fsLocation from './FsLocation'

// Advanced
import fsExpression from './FsExpression'
import fsYesNo from './FsYesNo'
import fsRating from './FsRating'
import fsTextCombination from './FsTextCombination'
import fsAutoNumbering from './FsAutoNumbering'
import fsRichText from './FsRichText'
import fsIdCard from './FsIdCard'
import fsMembers from './FsMembers'
import fsDepartments from './FsDepartments'
import fsSignature from './FsSignature'

// Relation
import fsRelatedSelection from './FsRelatedSelection'
import fsRelatedRecord from './FsRelatedRecord'
import fsRelatedMultipleRecords from './FsRelatedMultipleRecords'
import fsParentChildRelation from './FsParentChildRelation'
import fsChildTable from './FsChildTable'
import fsCascadeSelection from './FsCascadeSelection'
import fsOtherTableField from './FsOtherTableField'
import fsSummaryField from './FsSummaryField'


// Special
import fsCapitalizedAmount from './FsCapitalizedAmount'

// System
import fsUser from './FsUser'
import fsSystemDateTime from './FsSystemDateTime'
import fsUnspecified from './FsUnspecified'

export default {
  // Basic
  fsText,
  fsNumber,
  fsAmount,
  fsEmail,
  fsDate,
  fsPhone,
  fsSingleSelection,
  fsMultipleSelection,
  fsAttachments,
  fsRegion,
  fsLocation,

  // Advanced
  fsExpression,
  fsYesNo,
  fsRating,
  fsTextCombination,
  fsAutoNumbering,
  fsRichText,
  fsIdCard,
  fsMembers,
  fsDepartments,
  fsSignature,

  // Relation
  fsRelatedSelection,
  fsRelatedRecord,
  fsRelatedMultipleRecords,
  fsParentChildRelation,
  fsChildTable,
  fsCascadeSelection,
  fsOtherTableField,
  fsSummaryField,

  // Special
  fsCapitalizedAmount,

  // System
  fsUser,
  fsSystemDateTime,
  fsUnspecified
}
