<template>
  <div class="d-flex flex-row align-center pa-0 ma-0 position-relative form-title-search"
       :class="{'searching':searching}"
       style="min-height:32px;">
    <v-text-field
        autofocus
        ref="searchField"
        style="width:188px;"
        class="mx-0"
        outlined
        hide-details
        height="40"
        dense
        plain
        placeholder="Search text content"
        @blur="onBlurred"
        append-icon="mdi-magnify"
        :placeholder="$t('placeholder.searchTextContent')"
        v-model="inputSearchValue"
        clearable
        @click:append="onClickAppend">
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'formTitleSearch',
  data () {
    return {
      searching: false,
      oldSearchValue: '',
      searchValue: '',

      timeOutInst: null
    }
  },
  props: {
    disabled: Boolean
  },
  computed: {
    inputSearchValue: {
      get () {
        // console.log('computed(inputSearchValue) :: get vm.searchValue = ' + this.searchValue)
        return this.searchValue
      },
      set (val) {
        const vm = this
        console.log('computed(inputSearchValue) :: set(' + val + ') ')
        vm.searchValue = val
        if (vm.timeoutInst) {
          vm.timeoutInst = null
        }
        vm.timeoutInst = setTimeout(
          () => {
            vm.startSearch()
          },
          1500
        )
      }
    }
  },
  // watch: {
  //   searchValue: function(newVal) {
  //     const vm = this
  //     if (vm.timeoutInst) {
  //       vm.timeoutInst = null
  //     }
  //     vm.timeoutInst = setTimeout(
  //       () => {
  //         vm.startSearch()
  //       },
  //       1500
  //     )
  //   }
  // },
  methods: {
    reset () {
      this.searchValue = ''
      this.searching = false

    },
    showSearchInput () {
      this.searching = true
    },
    onBlurred () {
      // const vm = this
      // if (vm.searchValue === null || vm.searchValue === '') {
      //   vm.searching = false
      // }
      this.searching = (this.searchValue !== null && this.searchValue !== '')
    },
    startSearch () {
      const vm = this
      vm.timeoutInst = null
      console.log('startSearch: vm.searchValue = (' + vm.searchValue + ')')
      console.log('startSearch: vm.oldSearchValue = (' + vm.oldSearchValue + ')')
      if (vm.searchValue !== vm.oldSearchValue) {
        vm.oldSearchValue = vm.searchValue        
        vm.$emit('onSearch', 
          // Ensure vm.searchValue return string
          // * click [clear] will have searchValue changed to null, instead of empty string
          vm.searchValue ? vm.searchValue : ''
        )
      }
    },
    onClickAppend () {
      this.startSearch()
    }
  }
}
</script>

<style>
  .form-title-search .v-text-field {
    -webkit-transition: width 3s;
    transition: width 3s;
    width: 32px;
  }
  .form-title-search.searching .v-text-field {
    -webkit-transition: width 3s;
    transition: width 3s;
    width: 200px !important;
  }
</style>
