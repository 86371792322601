<template>
  <v-input hide-details class="edit-location-modal pl-1" style="height: 100%; border: 1px solid #dcdfe6; background-color: white">
    <v-overlay :absolute="false" :value="showingDialog" :id="'tv-r' + params.rowIndex + 'c' + params.columnIndex">
    </v-overlay>
    <dialog-button-map
        v-if="!readonly && params.row.canEdit"
        :value="params.row[params.column.property]"
        @input="val=>(params.row[params.column.property] = val)"
        ref="dialogButtonMap"
        :attach="'tv-r' + params.rowIndex + 'c' + params.columnIndex"
        @updateShowingDialog="updateShowingDialog"
        :showingDialog="showingDialog"
        class="mr-2"
        :cssSelect="cssSelect"
    ></dialog-button-map>
    <span v-if="params.row[params.column.property]" style="font-size:14px; color: #606266; text-overflow: ellipsis; overflow: hidden; white-space:nowrap">
      {{(params.row[params.column.property].name)? params.row[params.column.property].name + ',':''}} {{params.row[params.column.property].formatted_address}}
    </span>
    <!-- <v-btn @click="test">test</v-btn> -->
  </v-input>
</template>

<script>
import dialogButtonMap from '@/components/dialogButtons/DialogButtonMap'
export default {
  name: 'EditLocationModal',
  components:{
    dialogButtonMap
  },
  props: {
    params: Object,
    readonly: Boolean,
  },
  data () {
    return {
      showingDialog: false,
      row: null,
      column: null,
      modalVisible: false,
      loading: false,
    }
  },
  computed:{
    cssSelect(){
      if(this.params.fixed!= undefined){
        if(document.querySelector('.vxe-table--fixed-left-wrapper')){
          var path = '#tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex
          return '.vxe-table--fixed-left-wrapper' + ' ' + path
        }
      }
      return ''
    },
  },
  created () {
    console.log(this.params)
  },
  mounted(){
    console.log(this.$refs.dialogButtonMap)
  },
  watch:{
    showingDialog(val){
      this.$emit('updateTdClass', val)
    }
  },
  methods: {
    popupEvent () {
      this.modalVisible = true
    },
    updateShowingDialog(val){
      this.showingDialog = val
    }
  }
}
</script>

<style lang="scss">
.edit-location-modal {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}
.edit-location-input {
  width: auto;
  flex-grow: 1;
}
.edit-location-button {
  flex-shrink: 0;
}
</style>
