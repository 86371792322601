<template>
  <div class="d-flex flex-column" v-if="isFormLoaded">
    <ErrorIcon v-if="!isAdmin && !isAllowEditForm"/>
    <router-view v-else></router-view>
    <!--<form-builder :form="form" class="flex-grow-1" v-if="currentSection===0"></form-builder>-->
    <!--<table-setup :form="form" class="flex-grow-1" v-else-if="currentSection===1"></table-setup>-->
    <!--<public-publishing :form="form" class="flex-grow-1" v-else></public-publishing>-->
  </div>
</template>

<script>
// import adminFormMixin from '@/mixins/adminFormMixin'
import mixin from '../mixin'
// import formBuilder from './FormBuilder'
// import tableSetup from './TableSetup'
// import publicPublishing from './PublicPublishing'
import ErrorIcon from '@/components/global/403Error'

export default {
  mixins: [mixin],
  name: 'AdminForm',
  data () {
    return {
      isFormLoaded: false
    }
  },
  components: {
    // formBuilder,
    // tableSetup,
    // publicPublishing,
    ErrorIcon
  },
  // mixins: [adminFormMixin],
  computed: {
    currentApp () {
      return this.$store.getters.currentApp
    },
    isAllowEditForm() {
      let result = false;
      const currentForm = this.$store.getters.currentForm;
      result = currentForm.canEditForm;
      return result;
    }, 
    // currentSection () {
    //   return this.$store.getters.currentEditingSection
    // },
    isAdmin(){
      return this.$store.getters.isAdmin
    }
  },
  created() {
    const vm = this
    vm.checkCurrentApp().then(() => {
      vm.loadForm(vm.$route.params.itemId)
      vm.isFormLoaded = true
    })
  }
}
</script>
