<template>
  <v-dialog persistent width="540" v-model="showingDialog">
    <v-card>
      <dialog-header
        :label="$t('buttons.bulkCreate')"
        @close="showingDialog = false"
      ></dialog-header>
      <v-card-text class="pt-2">
        <v-textarea
          persistent-hint
          counter
          :hint="$t('widgets.properties.bulkCreateOptionsHint')"
          outlined
          :rules="rules"
          no-resize
          v-model="optionText"
          rows="10"
        >
        <template v-slot:counter>
          {{50 - remainingSpace + options.length}} / 50
        </template>
        </v-textarea>
      </v-card-text>
      <dialog-actions :actionDisabled="options.length > remainingSpace" @onCommand="onCommandHandler"></dialog-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";

export default {
  components: {
    dialogHeader,
    dialogActions,
  },
  props: {
    value: Boolean,
    remainingSpace:Number
  },
  data() {
    return {
      optionText: "",
      rules: [
        ()=> (this.options.length <= this.remainingSpace || this.$t('messages.exceedOptionsLength')) 
      ]
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    options() {
      return [...new Set(this.optionText
          .split("\n")
          .filter((option) => Boolean(option.trim()))
          .map(option => option.trim())
        )
        ];
    },
  },
  methods: {
    onCommandHandler(payload) {
      switch (payload.command) {
        case "cancel":
          this.showingDialog = false;
          break;
        case "ok":
          if (this.options.length <= this.remainingSpace) {
          this.$emit("confirm", this.options);
          this.showingDialog = false;
          }
          break;
      }
    },
  },
};
</script>
