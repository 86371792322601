import { childTable as types} from "../../action-types";
import {childTable as mutations} from "../../mutation-types"
export default {
    [types.FETCH_CHILD_TABLE_INFO]({commit, dispatch}, payload){
        return new Promise((resolve, reject) => {
            console.log('childTable.js :: FETCH_CHILD_TABLE_INFO')
            const getParams = {
              urlCommand: '/forms/' + payload.dataTableId
            }
            dispatch('AUTH_GET', getParams).then(
              response => {
                commit(mutations.SET_TABLE_INFO, response)
                resolve(response)
              }
            ).catch(
              err => {
                reject(err)
              }
            )
          })        
    },
    [types.RESET_CHILD_TABLE]({commit}){
      commit(mutations.RESET_CHILD_TABLE)
    }
}