import witCardDisplaySetup from './WitCardDisplaySetup'
import witTableDisplaySetup from './WitTableDisplaySetup'

import witRelatedSelectionSetup from './WitRelatedSelectionSetup'

// import witCardSelectionSetup from './WitCardSelectionSetup'
// import witTableSelectionSetup from './WitTableSelectionSetup'

import witDataSource from './WitDataSource'
import witDefaultMember from './WitDefaultMember'
import witDefaultDepartment from './WitDefaultDepartment'

import witDisplayFields from './WitDisplayFields'
import witFieldForRelatedTable from './WitFieldForRelatedTable'
import witFieldsEditor from './WitFieldsEditor'
import witFieldSelection from './WitFieldSelection'

import witFunctionSelection from './WitFunctionSelection'

import witInputOptions from './WitInputOptions'
import witSelectionDataSource from './WitSelectionDataSource'
import witInteger from './WitInteger'

import witRelatedSummaryFilterCriteria from './WitRelatedSummaryFilterCriteria'

import witRelatedRecord from './WitRelatedRecord'
import witRelatedRecordCopyFields from './WitRelatedRecordCopyFields'
import witRelatedRecordDisplayFields from './WitRelatedRecordDisplayFields'
import witRelatedRecordAvatarField from './WitRelatedRecordAvatarField'
import witRelatedRecordView from './WitRelatedRecordView'
import witRelatedTableField from './WitRelatedTableField'
// import witRelatedTableFields from './WitRelatedTableFields'
// import witRelatedTableFieldSelection from './WitRelatedTableFieldSelection'
import witTableFieldSelection from './WitTableFieldSelection'
import witRowType from './WitRowType'

import witOtherFieldCriteria from './WitOtherFieldCriteria'

import witSelectFieldCombination from './WitSelectFieldCombination'
import witSelectionMultiple from './WitSelectionMultiple'
import witSelectionSingle from './WitSelectionSingle'
import witShowInTab from './WitShowInTab'
import witString from './WitString'

import witSummaryFieldSelection from './WitSummaryFieldSelection'
import witSummaryFunctionSelection from './WitSummaryFunctionSelection'

import witTableSelection from './WitTableSelection'
import witText from './WitText'
import witTwoWayRelated from './WitTwoWayRelated'
import witUndefined from './WitUndefined'
// import witWidthAdjustment from './WitWidthAdjustment'

import witDateDialog from './WitDateDialog'
import witTimeDialog from './WitTimeDialog'
import witDateTimeDialog from './WitDateTimeDialog'
import witDateSelection from './WitDateSelection'
import witCustomFormula from './WitCustomFormula'
import witAmountFieldSelection from './WitAmountFieldSelection'
import witRichText from './WitRichText'
import witNumberRule from './WitNumberRule'
import witTextFieldSelection from './WitTextFieldSelection'
import witDateFieldSelection from './WitDateFieldSelection'
import witCurrencySelection from './WitCurrencySelection'

import witPhoneCountrySelection from './WitPhoneCountrySelection'
import witDefaultValue from './WitDefaultValue'

export default {
  witCardDisplaySetup,
  witTableDisplaySetup,

  witRelatedSelectionSetup,
  
  // witCardSelectionSetup,
  // witTableSelectionSetup,

  witDataSource,
  witDefaultMember,
  witDefaultDepartment,
  witDisplayFields,

  witFieldForRelatedTable,
  witFieldsEditor,
  witFieldSelection,
  witFunctionSelection,

  witInputOptions,
  witSelectionDataSource,
  // witInputOptionsMultiple,
  // witInputOptionsSingle,
  witInteger,

  witRelatedSummaryFilterCriteria,

  witRelatedRecord,
  witRelatedRecordCopyFields,
  witRelatedRecordDisplayFields,
  witRelatedRecordAvatarField,
  witRelatedRecordView,
  witRelatedTableField,
  // witRelatedTableFields,
  witRowType,

  witOtherFieldCriteria,
  
  witSelectFieldCombination,
  witSelectionMultiple,
  witSelectionSingle,
  witShowInTab,
  witString,
  witSummaryFieldSelection,
  witSummaryFunctionSelection,

  witTableFieldSelection,
  witTableSelection,
  witText,
  witTwoWayRelated,
  witRichText,
  witUndefined,

  witDateDialog,
  witTimeDialog,
  witDateTimeDialog,
  witDateSelection,
  witCustomFormula,

  witAmountFieldSelection,
  witNumberRule,
  witPhoneCountrySelection,
  witTextFieldSelection,
  witDateFieldSelection,
  witCurrencySelection,
  witDefaultValue  // ,
  // witWidthAdjustment

}
