<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="py-0 d-flex pl-2" hide-actions>
            <div class="flex-grow-1">
                {{$t('chart.styleConfig.hideColumn')}}
            </div>
        </v-expansion-panel-header>
        <v-switch
            dense
            style="position: absolute; right: 0px; top: -7px"
            inset
        ></v-switch>
        <v-expansion-panel-content>
            <div v-if="titleTree">
                <v-treeview
                    v-model="selectedHidden"
                    @input="$emit('onCommandHandler',{
                        command: 'update_selectedHidden',
                        value: $event
                    })"
                    dense
                    :items="titleTree"
                    :item-children="'childs'"
                    :item-text="'mapped'"
                    selectable
                    selection-type="leaf"
                    :item-key="'field'"
                ></v-treeview>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        props:{
            titleTree: Array,
            selectedHidden: Array
        }
    }
</script>