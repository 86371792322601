<template>
  <Consumer>
    <div slot-scope="{ navigatorInfo }">
      <v-row no-gutters class="wrapper">
        <v-col
          cols="12"
          sm="3"
        >
          <Backward :onClick="returnToAppFormPage" :title="navigatorInfo.title" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <EventBar :list="options" />
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-row justify="end">
            <v-btn color="primary" elevation="0" small @click.stop="$emit('edit')" class="create-btn">
              <v-icon small medium>mdi-plus</v-icon>
              <span v-if="!loading">{{ $t('workflow.btn.create') }}</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </Consumer>
</template>

<script>
import { map } from 'lodash';
import Consumer from "./contexts/consumer";
import EventBar from './barContent';
import Backward from './../header/backward'
import Mixin from './../../mixin';
// import Routes from '@/const/workflow/routes';
import {
  FETCH_WORKFLOW_STATISTIC,
} from '@/store/modules/workflow/action_types';

export default {
  mixins: [Mixin],
  props: {
    edit: Function,
  },
  // methods: {
  //   goToEdit () {
  //     this.$store.commit('savePrevRoute', this.$route);
  //     this.$router.push({
  //       name: Routes.workflowEditor,
  //       params: this.currentRouteParams,
  //     });
  //   }
  // },
  computed: {
    statistic() {
      return this.$store.getters.getWorkflowStatistic;
    },
    options() {
      return map(this.$t('workflow.events'), (label, key) => ({
        label,
        key,
        total: this.statistic[key] || 0,
      }));
    },
  },
  components: {
    Consumer,
    EventBar,
    Backward,
  },
  beforeCreate() {
    this.$store.dispatch(FETCH_WORKFLOW_STATISTIC, { params: { appId: this.$route.params.id } });
  },
};

</script>

<style scoped>
  .wrapper {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
  }
  .create-btn {
    line-height: 30px;
    border-radius: 30px;
    padding: 0 16px;
    opacity: .87;
  }
</style>
