<template>
    <div class="" style="height:100%; overflow: auto;">
        <div v-if="!arrG.length || (!arrX.length && !arrY.length)" style="font-size:16px; position: absolute; top:50%; left: 27%;">{{$t('chart.dragToAdd')}}</div>
        <div v-if="bl_fetching==false && arrG.length && (arrY.length||arrX.length)" class="pa-0" style="max-width:100%">
            <ChartVxeTable
                :titleTree="titleTree"
                :tableData="tableData"
                :mergeCells="mergeCells"
                :arrX="arrX"
                :arrY="arrY"
                :arrG="arrG"
                :tableFooters="tableFooters"
                :chartId="chartId"
                :bl_summary="bl_summary"
                :chartName="chartName"
                :key="chartVxeTableUpdateKey"
            />
        </div>
        <div v-else style="position:absolute; top:46%; left:33%">
            <v-progress-circular
                v-if="arrX.length && arrY.length && bl_fetching"
                indeterminate
                color="grey"
            >
            </v-progress-circular>
        </div>
    </div>    
</template>

<script>
import mixin from './mixin' 
import ChartVxeTable from './ChartVxeTable'
    export default {
        mixins: [mixin],
        props:{
            titleTree:{
                type: Array,
                default: ()=> []
            },
            tableData: {
                type: Array,
                default: ()=> []
            },
            mergeCells:{
                type: Array,
                default: ()=> []
            },
            bl_fetching: Number,
            tableFooters: {
                type: Array,
                default: ()=> []
            },
            chartId:{
                type: String,
                default: null
            },
            bl_summary: Boolean,
            chartName: String
        },
        data(){
            return{
                chartVxeTableUpdateKey: 1
            }
        },
        components:{
            ChartVxeTable
        },
        computed:{
            arrX(){
                return this.dataSetups.arrX
            },
            arrY(){
                return this.dataSetups.arrY
            },
            arrG(){
                return this.dataSetups.arrG
            }
        },
        watch:{
            tableData(val){
                this.chartVxeTableUpdateKey++
            }
        }
    }
        
</script>


<style>
    .chartTable-scrollbar::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-track {
        background-color: #FFFFFF !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-thumb {
        background-color: #bfbfbf !important;
        border-radius: 5px !important;
        border: 1px solid #F1F1F1 !important;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: #A8A8A8 !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-thumb:active {
        background-color: #787878 !important;
    }
    .chartTable-scrollbar::-webkit-scrollbar-corner {
        background-color: #FFFFFF !important;
    }
</style>