<template>
  <div class="d-flex flex-column">
    <ErrorIcon v-if="!isAdmin"/>
    <router-view v-else></router-view>
    <!--<form-builder :form="form" class="flex-grow-1" v-if="currentSection===0"></form-builder>-->
    <!--<table-setup :form="form" class="flex-grow-1" v-else-if="currentSection===1"></table-setup>-->
    <!--<public-publishing :form="form" class="flex-grow-1" v-else></public-publishing>-->
  </div>
</template>

<script>
import mixin from '../mixin'
import ErrorIcon from '@/components/global/403Error'
// import appsFormsMixin from '@/mixins/appsFormsMixin'

export default {
  // mixins: [appsFormsMixin],
  mixins: [mixin],
  components: {
    ErrorIcon
  },
  computed: {
    currentApp () {
      return this.$store.getters.currentApp
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  created () {
    const vm = this
    vm.checkCurrentApp().then(() => {
      vm.loadPanel(vm.$route.params.itemId)
    })
  }
  // mounted () {
  //   const vm = this
  //   const formId = vm.$route.params.formId
  //   vm.loadForm(formId)
  // },
  // watch: {
  //   '$route.params.formId': function (newVal) {
  //     const vm = this
  //     vm.loadForm(newVal)
  //   }
  // }
}
</script>
