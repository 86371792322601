<template>
  <v-tooltip
    bottom
    v-if="!!remark"
    transition="slide-y-reverse-transition"
    content-class="w-arrow-control-tool"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        small
        :disabled="$store.getters.isUIBlocked"
        @click="$emit('onRemark')"
      >
        <v-icon
          small
          :color="isNode ? 'white' : 'rgb(158, 158, 158)'"
        >
          mdi-note-outline 
        </v-icon>
      </v-btn>
    </template>
    <pre>{{ remark }}</pre>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    isNode: {
      type: Boolean,
      default: false,
    },
    remark: String,
    onRemark: Function,
  },
};

</script>

<style>
  
</style>
