<template>
<div class="w-100 wit-other-field-criteria">
  <label>{{ label }}</label>
  <div class="input-field d-flex flex-row align-top w-100">
    <v-card text outlined 
      color="grey lighten-2" 
      class="flex-grow-1" 
      elevation="0"
      style="border-color:black!important;min-height:28px;padding:2px;">
      <v-chip
        color="warning"
        class="line-height-1"
        style="margin-right:2px;margin-bottom:2px;height:auto;min-height:28px;"
        v-for="(item,index) in filters"
        :key="index">
        <div class="d-flex flex-row align-center flex-wrap">
          {{ item.fieldName }}
          <v-chip x-small class="mx-1">
            {{ $t('filters.'+item.details.relation) }}
          </v-chip>
          <!-- <div v-if="item.details.filterValue1"
            class="px-1 py-0 ml-1"> -->
            <div v-for="(value,index) in filterValues(item)"
              :key="index"
              class="option-value">
              {{ value }}
            </div>
          <!-- </div> -->
          <!-- <div v-if="item.details.filterValue2"
            class="py-0 ml-1">
            {{ item.details.filterValue2 }}
          </div> -->
        </div>
      </v-chip>
    </v-card>
    <v-btn small text
      class="flex-grow-0 pa-0"
      style="min-width:32px;"
      width="16"
      @click="openDialog"
      tile
      plain
      ><v-icon>mdi-tools</v-icon>
    </v-btn>
  </div>
  <!-- <div class="w-100 b-black rounded grey lighten-4" style="min-height:24px;">
  </div> -->
  <!-- <v-text-field
        label=""
        :value="displayValue"
        dense
        readonly
        :placeholder="placeholder"
        append-outer-icon="mdi-tools"
        @click:append-outer="openDialog"
        hide-details="auto"
        outlined></v-text-field> -->
    <table-filter-setup-dialog
      ref="tableFilterSetupDialog"
      @confirm="confirmHandler"></table-filter-setup-dialog>
</div>
</template>

<script>
import mixin from './mixin'
import tableFilterSetupDialog from '@/components/dialogs/TableFilterSetupDialog'
import { getDefaultPropertyValue } from "@/helpers/FormHelpers";

export default {
  name: 'witOtherFieldCriteria',
  mixins: [mixin],
  components: {
    tableFilterSetupDialog
  },
  watch: {
    dataTableId: function (newVal, oldVal) {
      const vm = this
      if (vm.displayFieldCount === 0) {
        vm.propertyValue = getDefaultPropertyValue(vm.propertyKey)
      }
    }
  },
  data () {
    return {
      BLANK_FILTER_CONFIGS: {
        filters: [],
        filterAndOr: 'and'
      }
    }
  },
  computed: {
    filters () {
      const vm = this
      var result = []
      if (vm.propertyValue && vm.propertyValue.filters) {
        if (vm.targetTableFieldInfos.length > 0) {
          for (let i = 0; i < vm.propertyValue.filters.length; i++) {
            const loopFilter = vm.propertyValue.filters[i]
            result.push({
              fieldName: vm.getFieldName(loopFilter.fieldId),
              details: loopFilter
            })          
          }
        }
      }
      return result
    },
    tableFieldInfoId () {
      return this.getPropertyValue('tableField', '')
    },

    tableFieldInfo () {
      const vm = this
      var result = null
      if (this.tableFieldInfoId !== '') {
        const fieldInfo =  vm.formFieldInfos.find(info => info._id === vm.tableFieldInfoId)
        if (fieldInfo) {
          result = fieldInfo
        }
      }
      return result
    },

    relationTableId () {
      const vm = this
      var result = ''
      if (vm.tableFieldInfo && vm.tableFieldInfo.properties.dataSource) {
        result = this.tableFieldInfo.properties.dataSource
      }
      return result
    },


    // targetFieldId () {
    //   return this.propertyValues.tableField
    // },

    // targetFieldInfo () {
    //   return this.formFieldInfos.find(info => info.fieldId === this.targetFieldId)
    // },

    // targetTableFormId () {
    //   const vm = this
    //   var result = ''
    //   switch (vm.targetFieldInfo.type) {
    //     case 'childTable':
    //       result = vm.targetFieldInfo.properties.fieldsEditor.formId
    //       break
    //     case 'relatedRecord':
    //       result = vm.targetFieldInfo.properties.dataSource.dataTableId
    //       break
    //   }
    //   return result
    // },

    // targetTableFormInfo () {
    //   return this.targetTableFormId ?
    //     this.getFormInfo(this.targetTableFormId) :
    //     null
    // },

    targetTableFormId () {
      const vm = this
      var result = []
      if (vm.tableFieldInfo) {
        switch (vm.tableFieldInfo.type) {
          case 'childTable':
            result = vm.tableFieldInfo.properties.fieldsEditor.formId
            break
          case 'relatedRecord':
            result = vm.tableFieldInfo.properties.dataSource
            break
        }
      }
      return result
    },

    targetTableFieldInfos () {
      const vm = this
      var result = []
      if (vm.tableFieldInfo) {
        switch (vm.tableFieldInfo.type) {
          case 'childTable':
            result = vm.tableFieldInfo.properties.fieldsEditor.fieldInfos
            break
          case 'relatedRecord':
            if (vm.tableFieldInfo.properties.dataSource) {
              const dataTableId = vm.tableFieldInfo.properties.dataSource
              const formInfo = vm.dataSources.find(ds => ds._id === dataTableId)
              if (formInfo) {
                result = formInfo.fieldInfos
              }
            }
            break
        }
      }
      return result
      // return this.targetTableFormInfo ?
      //   this.targetTableFormInfo.fieldInfos :
      //   []
      // const vm = this
      // var result = []
      // switch (vm.targetFieldInfo.type) {
      //   case 'childTable':
      //     result = vm.targetFieldInfo.properties.fieldsEditor.fieldInfos
      //     break
      //   case 'relatedRecord':
      //     result = vm.relatedFieldInfos
      //     break
      // }
      // return result
    },

    targetTableTitleFieldInfoId () {
      const vm = this
      var result = ''
      if (vm.tableFieldInfo) {
        switch (vm.tableFieldInfo.type) {
          case 'childTable':
            result = vm.tableFieldInfo.properties.fieldsEditor.titleFieldInfoId
            break
          case 'relatedRecord':
            result = vm.relatedTitleFieldInfoId
            break
        }
      }
      return result
    },

    selectedFieldIds () {
      const vm = this
      var result = []
      if (vm.propertyValue) {
          result = [
              ...vm.propertyValue.fixedFieldIds,
              ...vm.propertyValue.nonFixedFieldIds
          ]
      }
      return result
    },
    selectedFieldCount () {
      return this.selectedFieldIds ? this.selectedFieldIds.length : 0
    },

    displayValue () {
      return this.propertyValue
    },

  },
  mounted () {
    this.initPropertyValue()
  },
  methods: {
    getFilterFieldInfo (fieldId) {
      const vm = this
      var result = null
      if (vm.targetTableFieldInfos && vm.targetTableFieldInfos.length > 0) {
        result = vm.targetTableFieldInfos.find(info => info.fieldId === fieldId)
      }
      return result
    },
    filterValues (item) {
      var result = []
      const filter = item.details
      const fieldInfo = this.getFilterFieldInfo(filter.fieldId)
      if (fieldInfo) {
        switch (filter.relation) {
          case 'isTheMember':
          case 'isTheDepartment': {
            const members = filter.filterValue1.split('||')
            const memberNames = members.map(memberInfo => {
              const filterValue = memberInfo.split('|')
              return filterValue[1]
            })
            result = memberNames
          }        
            break
          case 'isTheItem':
          case 'notIncludeTheItems': {
            const fieldInfo = this.getFilterFieldInfo(filter.fieldId)
            switch (fieldInfo.type) {
              case 'singleSelection':
              case 'multipleSelection':
                if (filter.filterValue1 && fieldInfo.properties.inputOptionsSingle) {
                  const filterValues = filter.filterValue1.split('|')                  
                  result = filterValues.map( filterValue => {
                    var option = null
                    if (fieldInfo.type==='singleSelection') {
                      option = fieldInfo.properties.inputOptionsSingle.options.find(
                        option => option._id === filterValue)
                    } else {
                      option = fieldInfo.properties.inputOptionsMultiple.options.find(
                        option => option._id === filterValue)
                    }
                    return option.label
                  })
                }                   
                break
            }
          }

            break
          case 'includeAnItem':
            break
          case 'isTheRelation':
            if (filter.filterValue1) {
              if (Array.isArray(filter.filterValue1)) {
                const titles = filter.filterValue1.map(item => item.title)
                result = result.concat(titles)
              }
            }
            // if (item.details.filterValue2) {
            //   result.push(item.details.filterValue2.title)
            // }
            break
          default:
            if (filter.filterValue1) {
              result.push(filter.filterValue1)
            }
            if (filter.filterValue2) {
              result.push(filter.filterValue2)
            }
            break;
        }
      }
      return result.length > 0 ? result : []
    },
    getFieldName (fieldId) {
      const vm = this
      const fieldInfo = vm.targetTableFieldInfos.find(info => info.fieldId === fieldId)
      return fieldInfo ? fieldInfo.label : '(Unknown)'
    },
    confirmHandler (updatedSettings) {
      console.log('WitTableDisplaySetup :: confirmHandler :: updateSettings: ', updatedSettings)
    },

    _parseValue (val) {
      const vm = this

      if (val) {
        return val
      } else {
        return JSON.parse(JSON.stringify(vm.BLANK_FILTER_CONFIGS))
      }
    },

    initPropertyValue () {
      const vm = this
      if (!vm.propertyValue) {
        vm.propertyValue = JSON.parse(JSON.stringify(vm.BLANK_FILTER_CONFIGS))        
      }
    },
    openDialog () {
      const vm = this
      vm.$refs.tableFilterSetupDialog.open(
        {
          form: {...this.formInfo, _id:this.formId},
          filterConfigs: vm.propertyValue,
          fieldInfos: vm.targetTableFieldInfos,
          titleFieldInfoId: vm.targetTableTitleFieldInfoId
        },
        (result) => {
          vm.propertyValue = result
        }
      )
    }
  }
}
</script>

<style scoped>

.wit-other-field-criteria .input-field {
  min-height: 40px;
}
.option-value {
  padding: 0 3px;
  margin-right: 2px;

  margin-top:1px;
  display: inline-block;
  background-color: rgba(100,100,100,.5);
}
</style>
