<template>
<v-dialog
      persistent
      v-model="showingDialog"
      v-if="showingDialog"
      width="400">
    <v-card>
      <form @submit.prevent="handleSubmitForm">
        <dialog-header
          :label="dialogTitle"
          color="error"
          @close="showingDialog = false"
        />
        <!-- <v-toolbar :color="color" dark>
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
        </v-toolbar> -->
        <!--<v-card-title color="danger" class="headline lighten-2">-->
        <!--{{ title }}-->
        <!--</v-card-title>-->
        <v-card-text class="pt-3 pb-5 d-flex flex-column justify-space-between" style="min-height:50px; color:rgba(0,0,0,0.6)">
          <div class="">{{ message }}?</div>
          <div v-if="requiredWord!==''" class="user-input">
            <div>
              {{ $t('messages.pleaseInput', [`"${requiredWord}"`]) }} {{ $t('buttons.continue') }}.
            </div>
            <v-text-field hide-details style="font-size:12px;" dense height="20" ref="inputWord" class="input-word" v-model="inputWord"></v-text-field>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="min-width-100"
              color="muted"
              @click.native="closeDialog">
            {{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn class="min-width-100"
                 :disabled="inputWord.toUpperCase()!==requiredWord"
                 color="error"
                 @click.native="onOkButtonClicked">
              {{ $t('buttons.ok') }}
          </v-btn>
        </v-card-actions> -->
        <dialog-actions
          :actionDisabled="inputWord.toUpperCase()!==requiredWord"
          :buttons="['cancel', 'ok']"
          @onCommand="onCommandHandler">
        </dialog-actions>

      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
  data () {
    return {
      showingDialog: false,
      requiredWord: '',
      callback: null,
      inputWord: '',
      color: 'primary',
      message: '',
      customTitle: ''
    }
  },
  components:{
    dialogActions,
    dialogHeader
  },
  mounted () {
    const vm = this
    vm.message = vm.$t('messages.areYouSure')
  },
  model: {
    prop: 'showingDialog',
    input: 'input'
  },
  computed: {
    dialogTitle () {
      const vm = this
      var result = vm.$t('general.confirmation')
      if (vm.customTitle !== '') {
        result = vm.customTitle
      } else if (vm.title && vm.title !== '') {
        result = vm.title
      }
      return result
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    onCommandHandler(payload){
      switch(payload.command){
        case 'ok':
          this.onOkButtonClicked()
        break
        default: this.closeDialog()
      }
    },
    onOkButtonClicked () {
      const vm = this
      if (typeof vm.callback === 'function') {
        vm.callback()
      }
      vm.closeDialog()
    },
    open (payload) {
      const vm = this
      vm.inputWord = ''
      vm.requiredWord = payload.requiredWord.toUpperCase()
      vm.callback = payload.callback
      vm.color = payload.color
      if (payload.message) {
        vm.message = payload.message
      }
      if (payload.title) {
        if (typeof payload.title === 'object') {
          const key = Object.keys(payload.title)[0]
          var prefix = ''
          switch (key) {
            case 'deleteMenu':
              vm.customTitle = vm.$t('messages.deleteMenu') + ': ' + payload.title[key]
              break
          }
        } else {
          vm.customTitle = payload.title
        }
      }
      vm.showingDialog = true
      if (vm.requiredWord && vm.requiredWord !== '') {
        vm.$nextTick(() => {
          vm.$nextTick(() => {
            // console.log('ConfirmDialog2.open vm.$refs: ', vm.$refs)
            // vm.$refs.inputWord.$refs.input.focus()
          })
        })
      }

    },
    closeDialog () {
      const vm = this
      vm.showingDialog = false
    }
  }
}
</script>

<style>
  .input-word input {
    text-transform: uppercase;
  }
</style>
