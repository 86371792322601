export default {
    data(){
        return {
            type:""
        }
    },
    methods:{
        track({ key, data = {} }){
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: key,
                data: {
                    public_publishing_form_type: this.$sensors.events.plus_add_public_form.type[this.type],
                    ...data
                }
            });
        }
    }
}