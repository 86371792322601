import { pick, get } from "lodash";
import * as types from './action_types'
import {
  UPDATE_COUNTER,
} from './../notification/action_types'
import { app } from "@/main"

const state = {
  accessToken: '',
  user: null,
  locale:'en',
  menuMode: 'expanded',
  quickFilterSettings: new Map()
}

const getters = {
  debugging: () => {
    return process.env.VUE_APP_DEBUGGING==='true'
  },

  user: state => {
    return state.user
  },
  yoovAccount: (state) => state.user ? (state.user.username ):"",
  userFieldValue: (state, getters) => {
    return {
      "_id": getters.activeEmployeeId,
      "teamId": state.user && state.user.lastTeam || "",
      "employeeName": getters.activeEmployeeName
    }
  },
  activeEmployeeId: (state, getters, rootGetters) => {
    const allTeams = rootGetters.teams.allTeams
    let result = ''
    if (getters.user) {
      const userEmployeeId = getters.user.teamEmployeeIds[state.user.lastTeam];
      if (userEmployeeId) {
        result = userEmployeeId
      } else {
        for (let i = 0; i < allTeams.length; i++) {
          const loopTeam = allTeams[i]
          if (loopTeam.accessibleTeams && loopTeam.accessibleTeams.includes(state.user.lastTeam)) {
            result = getters.user.teamEmployeeIds[loopTeam._id]
            break
          }
        }
      }
    }
    return result
  },
  locale: state => {
    return state.locale
  },
  activeEmployee: (state, getters) => {
    return state.user.employees.find(employee => employee._id === getters.activeEmployeeId);
  },
  activeEmployeeName: (state, getters) => {
    if (!getters.activeEmployee || !getters.activeEmployee.employeeWorkInfo) return "";
   return getters.activeEmployee.employeeWorkInfo.displayName || ''
  },
  passportMobile: (state, getters) => {
    const {mobilePrefix = "", mobile = ""} = pick(getters.activeEmployee);
    return `${mobilePrefix}${mobile}`;
  },
  passportEmail: (state) => {
    return get(state.user, "email", "");
  },
  accessToken: state => {
    return state.accessToken
  },
  
  hiddenItems: () => {
    return process.env.VUE_APP_HIDDEN_ITEMS ?
      process.env.VUE_APP_HIDDEN_ITEMS.split(',') :
      []
  },
  menuMode: state => {
    return state.menuMode
  },
  isTeamAdmin: (_, getters) => {
    const role = getters.activeEmployee.role;
    if (role){
      return role.type === "ADMINISTRATOR"
    }
    return false
  },
  quickFilterSettings:(state) => {
    return state.quickFilterSettings
  },
  MAX_QUICK_FILTER_STORAGE_SIZE:()=> {
    return 10;
  } 
}

const mutations = {
  setAccessToken (state, payload) {
    // console.log('setAccessToken  payload = ' + payload)
    const localToken = localStorage.getItem('accessToken')
    if (localToken !== payload) {
      if (payload) {
        localStorage.setItem('accessToken', payload)
      } else {
        localStorage.removeItem('accessToken')
      }
    }
    state.accessToken = payload
  },
  setUser (state, payload) {
    state.user = payload
  },
  setLocale(state, payload){
    state.locale = payload
  },
  setUserCounter (state, notificationCounter) {
    state.user.notificationCounter = notificationCounter;
  },

  setUserField (state, payload) {
    state.user[payload.fieldName] = payload.fieldValue
  },
  updateUserAppsLayoutPref (state, payload) {
    // console.log('updateUserAppsLayoutPref: payload: ', payload)
    state.user.preference.appsLayout = {
      ...state.user.preference.appsLayout,
      ...payload
    }
  },

  updateMenuMode (state, payload) {
    state.menuMode = payload
  },
  assignPropsToUser(state, payload){
    Object.assign(state.user, payload)
  },
  setQuickFilterSettings(state, payload){
    state.quickFilterSettings = payload;
  },
  addQuickFilter(state, {viewId, value}){
    state.quickFilterSettings.set(viewId, value);
  },
  deleteQuickFilter(state, key){
    state.quickFilterSettings.delete(key);
  }
}

const actions = {
  [types.UPDATE_USER_APPS_LAYOUT] ({getters, commit, dispatch}, payload) {
    const postData = {
      urlCommand: '/users/update-apps-layout',
      data: payload
    }

    // console.log('system.js :: postData: ', postData)
    commit('updateUserAppsLayoutPref', payload)
    return new Promise((resolve, reject) => {
      dispatch('AUTH_POST', postData).then(
        response => {
          // console.log('UPDATE_USER_APP_LAYOUT: response: ', response)
          resolve(response)
        }
      )
    })
  },

  [types.LOGOUT] ({getters, commit, dispatch}, payload) {
    const accessToken = getters.accessToken
    const postData = {
      urlCommand: '/logout',
      data: {
          token: accessToken
      },
      type: 'auth'
    }
    return new Promise((resolve, reject) => {
      dispatch('COMMON_POST', postData).then(
        response => {
          app.$sensors.logout(getters.yoovAccount);
          commit('setAccessToken', null)
          commit('setUser', null)
          dispatch('DISCONNECT_FROM_SERVER')
          dispatch("RESET_YOOVWORK");
          resolve(response)
        }
      )
    })
  },

  [types.CHECK_LOCAL_TOKEN] ({getters, commit, dispatch}, role) {
    if (typeof role === 'undefined') {
      role = 'user'
    }
    return new Promise(resolve => {
      var accessToken = ''
      switch (role) {
        case 'user':
          accessToken = localStorage.getItem('accessToken') || ''
          break
        case 'super':
          accessToken = localStorage.getItem('superAccessToken') || ''
          break
        case 'system':
          accessToken = localStorage.getItem('systemAccessToken') || ''
          break
      }
      commit('setAccessToken', accessToken)
      resolve(accessToken)
    })
  },
  [types.CLEAR_PROFILE_COUNTER] ({commit, dispatch}) {
    const getData = {
      urlCommand: '/users/reset/counter'
    }
    dispatch('AUTH_GET', getData).then(response => {
      const { notificationCounter } = response;
      commit('setUserCounter', notificationCounter);
      dispatch(UPDATE_COUNTER, notificationCounter);
    }).catch(err => {
      
    })
  },
  [types.FETCH_USER_PROFILE] ({getters, commit, dispatch}) {
    const getData = {
      urlCommand: '/users/self'
    }
    return new Promise((resolve, reject) => {
      dispatch('AUTH_GET', getData).then(
        response => {
          // console.log('FETCH_USER_PROFILE => response: ', response)
          // console.log('FETCH_USER_PROFILE :: response: ', response)
          commit('setUser', response)
          commit('setLocale', response.locale)
          dispatch(UPDATE_COUNTER, response.notificationCounter || 0);
          dispatch("SET_TOKEN", {accessToken : response.yoovAccessToken, refreshToken: response.yoovRefreshToken})
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })

  },
  [types.INIT_TEAM]({commit}, {user, teams}){
    commit('assignPropsToUser', user)
    commit('setAllTeams', teams)
  },
  [types.UPDATE_TEAM]({commit, dispatch,getters }, teamId){
    return new Promise((resolve, reject)=> {
      const postData = {
        urlCommand: '/system/select_team',
        data: {
          teamId: teamId
        }
      }
      dispatch('AUTH_POST', postData).then(
        () => {
          commit('setUserField', {
            fieldName: 'lastTeam',
            fieldValue: teamId
          })
          app.$sensors.setProfile({
            company_id:getters.activeTeamCode,
            company_name: getters.activeTeamName
          })
          app.$sensors.track("plus_team_change", {
            btn_name:getters.activeTeamName
          });

          resolve()
        },
        error => {
          console.log('UPDATE_TEAM:: error: ', error)
          reject(error)
        }
      )
    })

  },
  [types.FETCH_QUICK_FILTER_PRESET]({ commit }) {
    const preset = localStorage.getItem("FORM_QUICK_FILTERS_PRESET");
    if (preset) {
      try {
        const map = new Map(JSON.parse(preset));
        commit("setQuickFilterSettings", map);
      } catch {
        console.warn(
          "FETCH_QUICK_FILTER_PRESET:: invalid format, ignoring value",
          { value: preset }
        );
      }
    }
  },
  [types.GET_QUICK_FILTER_BY_VIEWID]({ getters  }, viewId) {
    const { quickFilterSettings } = getters;
    return quickFilterSettings.get(viewId);
  },
  [types.SET_QUICK_FILTER]({ commit, getters }, { viewId, value }) {
    const { quickFilterSettings } = getters;
    if (
      !quickFilterSettings.has(viewId) &&
      quickFilterSettings.size >= getters.MAX_QUICK_FILTER_STORAGE_SIZE
    ) {
      const key = quickFilterSettings.keys().next().value;
      commit("deleteQuickFilter", key);
    }
    commit("addQuickFilter", { viewId, value });
    //update localStorage
    localStorage.setItem(
      "FORM_QUICK_FILTERS_PRESET",
      JSON.stringify(Array.from(quickFilterSettings.entries()))
    );
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

