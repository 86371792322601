<template>
  <div class="d-flex flex-column logo-input-field mb-3">
    <label class="input-label">{{ $t('general.logo') }}</label>
    <div class="d-flex flex-row align-center">
      <v-btn small class="elevation-0 logo-input black--text text-center" relative
              ref="logoInputButton"
              @click="showingDialog = true">{{ $t('general.select') }}</v-btn>

      <v-btn small class="elevation-0 ml-1 logo-input text-center" @click="generate">
        {{ $t('general.random') }}
      </v-btn>
    </div>
    <v-dialog
        class="logo-input-dialog"
        v-if="showingDialog"
        v-model="showingDialog"
        scrollable
        width="820">
      <v-card class="d-flex flex-column logo-input-dialog-content" height="600px">
        <dialog-header
          :label="$t('general.logoSelection')"
          @close="showingDialog = false"
        />
        <v-card-text class="pa-0">
          <v-card flat class="pa-0" tile>
              <v-card-text>
                <icon-selection :appIcon="inputLogoIcon"
                                @input="val=>updateLogoIcon(val)"></icon-selection>
              </v-card-text>
            </v-card>
        </v-card-text>
      <dialog-actions 
        :buttons="['cancel','ok']"
        @onCommand="onCommandHandler($event)">
      </dialog-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import iconSelection from './comps/IconSelection'
import dialogActions from "@/components/dialogs/comps/DialogActions";
import dialogHeader from "@/components/dialogs/comps/DialogHeader";
export default {
  components: {
    iconSelection,
    dialogActions,
    dialogHeader
  },
  data () {
    return {
      showingDialog: false,
      inputLogoIcon: '',
      inputLogoType: 'icon',
    }
  },
  model: {
    prop: 'appInfo',
    event: 'input'
  },

  watch: {
    appInfo: function (newVal) {
      const vm = this
      if (newVal && newVal!==null) {
        vm.updateInput(newVal)
      }
    },

  },
  mounted () {
    const vm = this
    vm.updateInput(vm.appInfo)

  },
  props: {
    appInfo: {
      type: Object,
      default: null
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    onCommandHandler(payload){
      if(payload.command == 'ok')
        this.confirm()
      this.showingDialog = false
    },
    updateLogoIcon (val) {
      const vm = this
      vm.inputLogoIcon = val
      vm.inputLogoType = 'icon'
    },
    updateInput (val) {
      const vm = this
      if (typeof val === 'undefined') {
        val = vm.appInfo
      }
      if (val !== null) {
        console.log('LogoInputField :: updateInput :: val: ', val)
        vm.inputLogoIcon = val.logoIcon
        vm.inputLogoType = val.logoType
      }
    },

    confirm () {
      const vm = this
      const result = {
        logoType: vm.inputLogoType,
        logoIcon: vm.inputLogoIcon
      }
      vm.$emit('input', result)
      vm.showingDialog = false
    },

    async getRandomIcon() {
      const vm = this
      return await vm.$store.dispatch('GET_RANDOM_LOGO')
    },

    generate () {
      const vm = this
      // iconInfo = {
      //      logoType,
      //      logoIcon,
      //      logoMediaId
      // }
      vm.getRandomIcon().then(iconInfo => {
        vm.$emit('input', iconInfo)
      })
    },


  }
}
</script>
