export const sensorsMap = {
  plus_myapps_view: "PLUS_主頁瀏覽",
  plus_team_change: "PLUS_團隊切換",
  plus_myapps_click: "PLUS_應用點擊",
  plus_myapps_add: "PLUS_新建應用",
  plus_menu_click: "PLUS_目錄點擊",
  plus_navi_click: "PLUS_導航欄點擊",
  plus_modules_add: "PLUS_模組新增",
  plus_block_add: "PLUS_模塊新增",
  plus_sheet_mani: "PLUS_表格操作",
  plus_panel_mani: "PLUS_面板操作",
  plus_calender_mani: "PLUS_日曆操作", 
  plus_inputpanel_mani: "PLUS_輸入面板操作",
  plus_workflow_add: "PLUS_工作流新增",
  plus_workflow_trigger: "PLUS_工作流觸發", //TBC
  plus_form_edit:"PLUS表格編輯",
  plus_click_functional_button:"PLUS觸發功能按鈕",
  plus_sheet_view:"PLUS面版瀏覽",
  plus_add_public_form:"PLUS_新增公開表格",
  plus_public_form_activiate:"PLUS_啟用公開表格",
  plus_public_publishing_function:"PLUS_公開表格功能按鈕",
  plus_table_setup_menu:"PLUS_表單設定目錄",
  plus_click_authorization:"PLUS_點擊權限",
  plus_authorization_add_role:"PLUS_權限新増身份",
  plus_add_authorization: "PLUS_權限新増使用者部門",
  plus_workflow_release_execute:"PLUS_工作流發佈及執行",
  plus_view_add:"PLUS_視圖新增",
  plus_add_module_block_view_button:"PLUS_視圖,模組,模塊按鈕",
  plus_form_editor_save:"PLUS_表格編輯儲存",
  plus_workflow_edit:"PLUS_工作流修改"
};

export const EVENTS = {
  plus_sheet_mani: {
    print_template: {
      CREATE_PRINT_TEMPLATE_NORMAL: "新建HTML列印模板",
      UPDATE_PRINT_TEMPLATE_NORMAL: "更新HTML列印模板",
      CREATE_PRINT_TEMPLATE_DOCX: "新建DOCX列印模板",
      UPDATE_PRINT_TEMPLATE_DOCX: "更新DOCX列印模板",
      DELETE_PRINT_TEMPLATE: "刪除列印模板",
      UPDATE_PRINT_TEMPLATE_SETTING: "更新列印模板設定",
    },
    display_rule: {
      UPDATE_DISPLAY_RULE: "更新顯示規則",
      DELETE_DISPLAY_RULE: "刪除顯示規則",
    },
    table_view: {
      EXPORT_RECORD: "匯出記錄",
      IMPORT_RECORD: "匯入記錄",
      SAVE_AND_NEW: "儲存記錄＆繼續",
      SAVE_AND_CONTINUE: "儲存記錄＆保留",
      SAVE_AND_EXIT: "儲存記錄＆離開",
      UPDATE_RECORD: "更新記錄",
      COPY_RECORD: "複製記錄",
      DELETE_RECORD: "刪除記錄",
      UPDATE_CALC_METHOD: "更新表格欄位匯總設定",
      UPDATE_RECORD_INLINE: "行內更新記錄",
      QUICK_CREATE_RECORDS: "快速新建記錄",
      PRINT_RECORD_NORMAL: "列印HTML記錄",
      PRINT_RECORD_DOCX: "列印DOCX記錄",
      BULK_EDIT: "批量編輯記錄",
      CREATE_VIEW: "新建視圖",
      DELETE_VIEW: "刪除視圖",
      UPDATE_VIEW: "更新視圖",
      COPY_VIEW: "複製視圖",
    },
    discussion: {
      CREATE_COMMENT: "新建討論區留言",
      DELETE_COMMENT: "刪除討論區留言",
      CREATE_ATTACHMENT: "新建討論區附件",
      DELETE_ATTACHMENT: "刪除討論區附件",
      EXPORT_LOGS: "匯出表格活動記錄",
    },
    chart:{
      CREATE_CHART:"新建統計圖",
      DELETE_CHART:"刪除統計圖"
    },
    general:{
      DELETE_FORM: "刪除表格",
      SAVE_FORM:"編輯表格"
    },
    function_toggle:{
      EDIT_FUNCTION:"更新功能開關"
    }
  },
  plus_panel_mani:{
    SAVE_PANEL:"編輯面板",
    EXPORT_PANEL:"匯出面板",
    DELETE_PANEL:"刪除面板"
  },
  plus_inputpanel_mani:{
    SAVE_INPUT_PANEL:"編輯輸入面板",
    DELETE_INPUT_PANEL:"刪除輸入面板"
  },
  plus_calender_mani:{
    SAVE_CALENDER:"編輯日曆",
    DELETE_CALENDER:"刪除日曆"
  },
  plus_click_functional_button:{
    TOOL:"工具",
    GENERAL:"基本設定",
    FILTER:"篩選",
    QUICK_FILTER:"快速篩選",
    SORTING:"排序",
    BUTTON:"自定義動作",
    ADD_BUTTON:"新増自訂按鈕",
    ADD_BUTTON_SAVE:"新増自訂按鈕(儲存)"
  },
  plus_add_public_form:{
    type:{
      FORM:"Publish form",
      QUERY:"Query for public form",
      RECORD:"Publish editable form"
    }
  },
  plus_public_publishing_function:{
    OPEN_LINK:"open link",
    COPY_LINK:"copy links",
    SETTINGS:"settings",
    DELETE:"delete",
    DOWNLOAD:"download QR code"
  },
  plus_click_authorization:{
    ADD_USER:"add user",
    ADD_DEPT:"add department",
    ADD_ROLE:"add role"
  },
  plus_authorization_add_role:{
    UNAPPLICATE:"un-applicate to all",
    APPLICATE:"applicate to all",
    EDIT:"edit the authorization form",
    SAVE:"save"
  },
  plus_add_module_block_view_button:{
    MODULE:"module",
    BLOCK:"block",
    VIEW:"view"
  },
};

export const Sensors = {
  keys: sensorsMap,
  events:EVENTS,
  track: function(key, attrs = {}) {
    try {
      if (this.keys[key]) {
        sensors.track(key, {
          ...attrs,
          evt_name: this.keys[key],
        });
      } else {
        console.error("sensors::track, trackEventKey is not defined");
      }
    } catch (err) {
      console.error("sensors::track, err=", err);
    }
  },
  setProfile: function(attrs = {}) {
    try {
      sensors.setProfile(attrs);
    } catch (err) {
      console.error("sensors::setProfile, err=", err);
    }
  },
  login: function(userId) {
    try {
      sensors.login(userId);
    } catch (err) {
      console.error("sensors::login, err=", err);
    }
  },
  registerPage: function(attrs = {}) {
    try {
      sensors.registerPage(attrs);
    } catch (err) {
      console.error("sensors::registerPage, err=", err);
    }
  },
  logout: function(userId) {
    try {
      sensors.logout(userId);
    } catch (err) {
      console.error("sensors::logout, err=", err);
    }
  }
};
