<template>
  <div ref="draggableDiv" style="position:relative;height: 200px;background-color:lightskyblue;width:100%;">
    <div ref="draggableSplitter" id="draggable-splitter">

      <div id="draggable-header" @mousedown="dragMouseDown">
        <slot name="header" style="height:100px;background-color:lightcoral;"></slot>
      </div>
      <slot name="main"></slot>
      <slot name="footer"></slot>
    </div>
    <div class="ma-2" style="position: absolute;left:0;bottom:0;">
        vm.originLeft: {{originLeft}}<br/>
      movementX: {{ positions.movementX }}<br/>
      clientX: {{ positions.clientX }}<br/>
      draggable-div.x: {{ $refs.draggableDiv}}<br/>
      splitter left: {{ splitterLeft }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DraggableDiv',
  data: function () {
    return {
      originLeft: 0,
      parentClientX: 0,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  computed: {
    splitterLeft () {
      const vm = this
      var result = 0
      if (vm.$refs.draggableSplitter) {
        result = vm.$refs.draggableSplitter.style.left
      }
      return result
    }
  },
  mounted () {
    const vm = this
    vm.originLeft = vm.$refs.draggableDiv.getBoundingClientRect().left
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault()
      // console.log('dragMouseDown :: event: ', event)
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
   //   this.$refs.draggableSplitter.style.top = (this.$refs.draggableSplitter.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableSplitter.style.left = (this.$refs.draggableSplitter.offsetLeft - this.positions.movementX) + 'px'

      // console.log('elementDrag :: this.$refs.draggableSplitter.style.left = ' + this.$refs.draggableSplitter.style.left)
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
      this.$refs.draggableSplitter.style.left = '500px'
    }
  }
}
</script>

<style>
#draggable-splitter {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 7px;
  margin-left: -1px;
  border-left: 1px solid #eee;
  background-color: white;
  box-sizing: border-box;
  cursor: col-resize;
  touch-action: none;
}
#draggable-header {
  z-index: 10;
}

#draggable-splitter:before {
  margin-left: -2px;
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
  top: 50%;
  left: 50%;
  background-color: rgba(0,0,0,.15);
  /*color: lightgray;*/
  transition: background-color .3s;
}
#draggable-splitter:after {
  margin-left: 1px;
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
  top: 50%;
  left: 50%;
  /*color: lightgray;*/
  background-color: rgba(0,0,0,.15);
  transition: background-color .3s;
}
</style>
