<template>
  <vxe-table-column
    v-bind="colDef"
    :edit-render="{
      name: '$select',
      options: selection,
      enabled: !readonly
    }"
    sortable
    class-name="fullCell"
  >
    <template v-slot="{ row }">
      <option-chip-list
        :value="row[fieldId]"
        :useColor="useColor"
        toggleType="single"
        :options="options"
      ></option-chip-list>
      <quick-action v-if="quickInsert" :rowId="row['_id']" :fieldId="fieldId"></quick-action>
    </template>
    <template v-slot:edit="{ row }">
      <option-chip-list
      v-if="readonly"
        :value="row[fieldId]"
        :useColor="useColor"
        toggleType="single"
        :options="options"
      ></option-chip-list>
    <pulldown-chip-selection
    v-else
        class="vxecellSelection"
        :readonly="readonly"
        :items="selection"
        :fullListOptions="options"
        :useColor="useColor"
        item-text="label"
        item-value="_id"
        toggleType="single"
        @onDelete="onDeleteHandler($event, row[colDef.field])"
        v-model="row[colDef.field]"
        :allowAdd="allowAdd"
        :fieldId="fieldId"
        :multiple="false"
    ></pulldown-chip-selection>

    </template>

    <template v-slot:footer="{column}">
      <summary-cell :decimals="decimals" 
        @click="selectCalcMethod"
        :column="column"
        :labelValuePairs="getSummaryLabelValuePairs(column)"></summary-cell>
    </template>
  </vxe-table-column>
</template>

<script>
import mixin from "./mixin";
import optionChipList from "./comps/OptionChipList";

import pulldownChipSelection from "@/components/inputWidgets/comps/PulldownChipSelection";

import quickAction from "./comps/QuickAction";
import summaryCell from './comps/SummaryCell'

export default {
  name: "vxeCellSingleSelection",
  mixins: [mixin],
  components: {
    optionChipList,

    pulldownChipSelection,

    quickAction,
    summaryCell
  },
  mounted() {
    // console.log(this.selection)
    this.selection = this.selection.map((item) => {
      return {
        label: item.label,
        value: item._id,
      };
    });
  },
  methods: {
    onToggleOption(item, row) {
      row[this.colDef.field] = item._id;
      //   if (this.$refs.xDown1) {
      //     this.$refs.xDown1.hidePanel()
      //   }
      // }
    },
    clickDownEvent() {
      if (this.$refs.xDown1) {
        this.$refs.xDown1.showPanel();
      }
    },
    onChange(payload) {
      this.updateVal(payload.value);
    },
    updateVal(val) {
      const vm = this;
      vm.$emit("onCommand", {
        command: "updateFieldValue",
        rowId: row.id,
        fieldId: vm.fieldId,
        fieldValue: val,
      });
      // console.log('val = ' + JSON.stringify(val))
    },
    getOption(value) {
      return this.options.filter(
        (option) =>
          (!option.isDeleted && option.active) || option._id === value
      );
    },
    getOptionLabel(itemId) {
      const item = this.options.find((option) => option._id === itemId);
      return item ? item.label : "";
    },
  },
  computed: {
    allowAdd() {
      return (
        this.fieldInfo &&
        this.fieldInfo.properties.settingsMultiple &&
        this.fieldInfo.properties.settingsMultiple.includes("allowUserAdd")
      );
    },    
  },
};
</script>


<style lang="scss">

.vxecellSelection, .vxecellSelection .v-input__control, .vxecellSelection .v-input__control .v-input__slot {
  height:100%;
}

</style>
