export default {
  constants: {
    appHost: process.env.VUE_APP_API_HOST,
    // 'https://plus-api.yoov.com'
  },
  colorSets: [
    '#000000/#C9E6FC',
    '#000000/#C3F2F2',
    '#000000/#C2F1D2',
    '#000000/#FEF6C6',
    '#000000/#FFE5C2',

    '#000000/#FDCACA',
    '#000000/#FACDE6',
    '#000000/#DEC2FA',
    '#000000/#CCD2F1',
    '#000000/#D3D3D3',

    '#FFFFFF/#2196F3',
    '#FFFFFF/#08C9C9',
    '#FFFFFF/#00C345',
    '#FFFFFF/#FAD714',
    '#FFFFFF/#FF9300',

    '#FFFFFF/#F52222',
    '#FFFFFF/#EB2F96',
    '#FFFFFF/#7500EA',
    '#FFFFFF/#2D46C4',
    '#FFFFFF/#484848'
  ],
  copyableWidgets: {
    // string
    'text': ['text'],
    'richText': ['richText','text'],
    'idCard': ['idCard','text'],
    'autoNumbering': ['text'],
    'textCombination': ['text'],
    'phone': ['phone','text'],
    'email': ['email','text'],

    // number
    'amount': ['amount','number'],
    'number': ['amount','number'],
    'summaryField': ['amount','number'],
    'expression': ['amount','number'],

    // yesNo
    'yesNo': ['yesNo'],

    // single selection
    'singleSelection': ['singleSelection'],
    'relatedRecord': ['relatedRecord'],

    //
    // 'departments': [],
    // 'createdAt': [],
    // 'createdBy': [],
    // 'capitalizedAmount': [],
    // 'freeLink': [],
    // 'owner': [],

    // 'remark':[],
    // 'otherTableField':[],
    // 'cascadeSelection'
    // 'childTable':[],
    // 'relatedMultipleRecords':[],
    // 'relatedRecord':[],
    // 'relatedSelection':[],
    // 'signature':[],
    // 'updatedAt': [],
    // 'members': [],
    // 'rating': [],
    // 'yesNo': [],
    // 'location': [],
    // 'region': [],
    'attachments': ['attachments'],
    // 'multipleSelection': [],
    // 'singleSelection': [],
    // 'date': [],
  },
  cardNonDisplayFieldTypes: [
    "location",
    "richText",
    "relatedSelection",
    "relatedRecord",
    "relatedMultipleRecords",
    "childTable",
    "cascadeSelection",
    "otherTableField",
    "_sectionLine",
    "remark",
    "freeLink",
  ],
  defaultViewSettings: {
    _id: '',
    title: '',
    viewType: 'table',

    filterAndOr: 'and',
    filters: [],

    fixedFieldIds: [],
    nonFixedFieldIds: [],

    // defaultSortingFieldId: '',
    sortableConfigs: [],

    editableFieldIds: [],
    buttons: [],

    groupingFieldId: '',
    groupingIncludeNoDataGroup: true,

    cardSummaryFieldId: '',
    cardDisplayFieldIds: [],
    cardDisplayFieldName: true,
    cardImageFieldId: '',
    cardImagePosition: 'top',
    cardImageMode: 'cover',

    allowCustomOrdering: true
  },

  industries: [ {
    "id" : 1,
    "name" : "製造業",
    "depth" : 1,
    "position" : 0,
    "description" : "manufacturing"
  }, {
    "id" : 2,
    "name" : "會計服務",
    "depth" : 1,
    "position" : 1,
    "description" : "accounting"
  }, {
    "id" : 3,
    "name" : "廣告服務",
    "depth" : 1,
    "position" : 2,
    "description" : "advertising"
  }, {
    "id" : 4,
    "name" : "建築及規劃",
    "depth" : 1,
    "position" : 3,
    "description" : "architecture"
  }, {
    "id" : 5,
    "name" : "商會服務及政府機構",
    "depth" : 1,
    "position" : 4,
    "description" : "chamber"
  }, {
    "id" : 6,
    "name" : "汽車及零部件",
    "depth" : 1,
    "position" : 5,
    "description" : "automobile"
  }, {
    "id" : 7,
    "name" : "嬰兒產品",
    "depth" : 1,
    "position" : 6,
    "description" : "baby"
  }, {
    "id" : 8,
    "name" : "銀行服務",
    "depth" : 1,
    "position" : 7,
    "description" : "banking"
  }, {
    "id" : 9,
    "name" : "書刊及印刷品",
    "depth" : 1,
    "position" : 8,
    "description" : "books"
  }, {
    "id" : 10,
    "name" : "建造服務",
    "depth" : 1,
    "position" : 9,
    "description" : "construction"
  }, {
    "id" : 11,
    "name" : "建築材料、五金及機械",
    "depth" : 1,
    "position" : 10,
    "description" : "building"
  }, {
    "id" : 12,
    "name" : "商業管理及顧問服務",
    "depth" : 1,
    "position" : 11,
    "description" : "business"
  }, {
    "id" : 13,
    "name" : "化學品及原材料",
    "depth" : 1,
    "position" : 12,
    "description" : "chemicals"
  }, {
    "id" : 14,
    "name" : "設計服務",
    "depth" : 1,
    "position" : 13,
    "description" : "design"
  }, {
    "id" : 15,
    "name" : "教育及培訓",
    "depth" : 1,
    "position" : 14,
    "description" : "education"
  }, {
    "id" : 16,
    "name" : "電子產品及電器",
    "depth" : 1,
    "position" : 15,
    "description" : "electronic"
  }, {
    "id" : 17,
    "name" : "工程服務",
    "depth" : 1,
    "position" : 16,
    "description" : "engineering"
  }, {
    "id" : 18,
    "name" : "環保用品",
    "depth" : 1,
    "position" : 17,
    "description" : "environmentalProducts"
  }, {
    "id" : 19,
    "name" : "環保服務",
    "depth" : 1,
    "position" : 18,
    "description" : "environmentalServices"
  }, {
    "id" : 20,
    "name" : "金融及投資",
    "depth" : 1,
    "position" : 20,
    "description" : "finance"
  }, {
    "id" : 21,
    "name" : "食品及飲料",
    "depth" : 1,
    "position" : 21,
    "description" : "foodAndBeverage"
  }, {
    "id" : 22,
    "name" : "鞋類",
    "depth" : 1,
    "position" : 22,
    "description" : "footwear"
  }, {
    "id" : 23,
    "name" : "家具及布置用品",
    "depth" : 1,
    "position" : 23,
    "description" : "furniture"
  }, {
    "id" : 24,
    "name" : "成衣、紡織及配件",
    "depth" : 1,
    "position" : 24,
    "description" : "wears"
  }, {
    "id" : 25,
    "name" : "手袋及旅行用品",
    "depth" : 1,
    "position" : 25,
    "description" : "handbags"
  }, {
    "id" : 26,
    "name" : "家庭用品",
    "depth" : 1,
    "position" : 26,
    "description" : "household"
  }, {
    "id" : 27,
    "name" : "資訊科技服務",
    "depth" : 1,
    "position" : 27,
    "description" : "it"
  }, {
    "id" : 28,
    "name" : "保險",
    "depth" : 1,
    "position" : 28,
    "description" : "insurance"
  }, {
    "id" : 29,
    "name" : "室內設計服務",
    "depth" : 1,
    "position" : 29,
    "description" : "interiorDesign"
  }, {
    "id" : 30,
    "name" : "珠寶",
    "depth" : 1,
    "position" : 30,
    "description" : "jewelry"
  }, {
    "id" : 31,
    "name" : "法律服務",
    "depth" : 1,
    "position" : 31,
    "description" : "legal"
  }, {
    "id" : 32,
    "name" : "物流管理及運輸服務",
    "depth" : 1,
    "position" : 32,
    "description" : "logistics"
  }, {
    "id" : 33,
    "name" : "醫療及保健服務",
    "depth" : 1,
    "position" : 33,
    "description" : "healthServices"
  }, {
    "id" : 34,
    "name" : "醫療用品及醫藥",
    "depth" : 1,
    "position" : 34,
    "description" : "medicine"
  }, {
    "id" : 35,
    "name" : "包裝材料",
    "depth" : 1,
    "position" : 35,
    "description" : "packaging"
  }, {
    "id" : 36,
    "name" : "攝影器材",
    "depth" : 1,
    "position" : 36,
    "description" : "photographic"
  }, {
    "id" : 37,
    "name" : "印刷及出版服務",
    "depth" : 1,
    "position" : 37,
    "description" : "publishing"
  }, {
    "id" : 38,
    "name" : "公共關系",
    "depth" : 1,
    "position" : 38,
    "description" : "publicRelations"
  }, {
    "id" : 39,
    "name" : "品質檢查及測試",
    "depth" : 1,
    "position" : 39,
    "description" : "qualityInspection"
  }, {
    "id" : 40,
    "name" : "房地產服務",
    "depth" : 1,
    "position" : 40,
    "description" : "realEstate"
  }, {
    "id" : 41,
    "name" : "文具及辦公室設備",
    "depth" : 1,
    "position" : 41,
    "description" : "stationery"
  }, {
    "id" : 42,
    "name" : "科技",
    "depth" : 1,
    "position" : 42,
    "description" : "scienceAndTechnology"
  }, {
    "id" : 43,
    "name" : "電訊",
    "depth" : 1,
    "position" : 43,
    "description" : "telecommunication"
  }, {
    "id" : 44,
    "name" : "旅遊",
    "depth" : 1,
    "position" : 44,
    "description" : "tourism"
  }, {
    "id" : 45,
    "name" : "玩具及遊戲",
    "depth" : 1,
    "position" : 5,
    "description" : "toysAndGames"
  }, {
    "id" : 46,
    "name" : "鐘錶",
    "depth" : 1,
    "position" : 46,
    "description" : "watches"
  }, {
    "id" : 47,
    "name" : "其他",
    "depth" : 1,
    "position" : 34,
    "description" : "other"
  } ],

  currencies: [ {
    "id" : 152,
    "code" : "AED",
    "name" : "阿聯迪拉姆",
    "symbol" : "د.إ"
  }, {
    "id" : 153,
    "code" : "AFN",
    "name" : "阿富汗尼",
    "symbol" : "Af"
  }, {
    "id" : 154,
    "code" : "ALL",
    "name" : "阿爾巴尼列克",
    "symbol" : "L"
  }, {
    "id" : 155,
    "code" : "AMD",
    "name" : "亞美尼亞德拉姆幣",
    "symbol" : "Դ"
  }, {
    "id" : 156,
    "code" : "AOA",
    "name" : "安哥拉寬紮",
    "symbol" : "Kz"
  }, {
    "id" : 157,
    "code" : "ARS",
    "name" : "阿根廷披索",
    "symbol" : "$"
  }, {
    "id" : 158,
    "code" : "AUD",
    "name" : "澳幣",
    "symbol" : "$"
  }, {
    "id" : 159,
    "code" : "AWG",
    "name" : "阿魯巴弗羅林",
    "symbol" : "ƒ"
  }, {
    "id" : 160,
    "code" : "AZN",
    "name" : "亞塞拜然馬納特幣",
    "symbol" : "ман"
  }, {
    "id" : 161,
    "code" : "BBD",
    "name" : "巴巴多斯元",
    "symbol" : "$"
  }, {
    "id" : 162,
    "code" : "BDT",
    "name" : "孟加拉塔卡",
    "symbol" : "৳"
  }, {
    "id" : 163,
    "code" : "BGN",
    "name" : "保加利亞列弗",
    "symbol" : "лв"
  }, {
    "id" : 164,
    "code" : "BND",
    "name" : "汶萊幣",
    "symbol" : "$"
  }, {
    "id" : 165,
    "code" : "BOB",
    "name" : "玻利維亞諾",
    "symbol" : "Bs."
  }, {
    "id" : 166,
    "code" : "BRL",
    "name" : "巴西雷亞爾幣",
    "symbol" : "R$"
  }, {
    "id" : 167,
    "code" : "BSD",
    "name" : "巴哈馬幣",
    "symbol" : "$"
  }, {
    "id" : 168,
    "code" : "BZD",
    "name" : "貝里斯幣",
    "symbol" : "$"
  }, {
    "id" : 169,
    "code" : "CLP",
    "name" : "智利披索",
    "symbol" : "$"
  }, {
    "id" : 170,
    "code" : "CNY",
    "name" : "中國人民幣",
    "symbol" : "¥"
  }, {
    "id" : 171,
    "code" : "COP",
    "name" : "哥倫比亞披索",
    "symbol" : "$"
  }, {
    "id" : 172,
    "code" : "CRC",
    "name" : "哥斯大黎加科朗幣",
    "symbol" : "₡"
  }, {
    "id" : 173,
    "code" : "DOP",
    "name" : "多明尼加披索",
    "symbol" : "$"
  }, {
    "id" : 174,
    "code" : "DZD",
    "name" : "阿爾及利亞第納爾",
    "symbol" : "د.ج"
  }, {
    "id" : 175,
    "code" : "EGP",
    "name" : "埃及鎊",
    "symbol" : "£"
  }, {
    "id" : 176,
    "code" : "EUR",
    "name" : "歐元",
    "symbol" : "€"
  }, {
    "id" : 177,
    "code" : "GBP",
    "name" : "磅",
    "symbol" : "£"
  }, {
    "id" : 178,
    "code" : "GHS",
    "name" : "迦納塞地",
    "symbol" : "₵"
  }, {
    "id" : 179,
    "code" : "GTQ",
    "name" : "瓜地馬拉格查爾幣",
    "symbol" : "Q"
  }, {
    "id" : 180,
    "code" : "HKD",
    "name" : "港幣",
    "symbol" : "$"
  }, {
    "id" : 181,
    "code" : "HNL",
    "name" : "宏都拉斯倫皮拉幣",
    "symbol" : "L"
  }, {
    "id" : 182,
    "code" : "HRK",
    "name" : "克羅地亞庫納",
    "symbol" : "Kn"
  }, {
    "id" : 183,
    "code" : "HUF",
    "name" : "匈牙利福林",
    "symbol" : "Ft"
  }, {
    "id" : 184,
    "code" : "IDR",
    "name" : "印尼盾",
    "symbol" : "Rp"
  }, {
    "id" : 185,
    "code" : "ILS",
    "name" : "以色列新謝克爾",
    "symbol" : "₪"
  }, {
    "id" : 186,
    "code" : "INR",
    "name" : "印度盧比",
    "symbol" : "₹"
  }, {
    "id" : 187,
    "code" : "JMD",
    "name" : "牙買加幣",
    "symbol" : "$"
  }, {
    "id" : 188,
    "code" : "JPY",
    "name" : "日圓",
    "symbol" : "¥"
  }, {
    "id" : 189,
    "code" : "KES",
    "name" : "肯亞先令",
    "symbol" : "Sh"
  }, {
    "id" : 190,
    "code" : "KHR",
    "name" : "柬埔寨瑞爾",
    "symbol" : "៛"
  }, {
    "id" : 191,
    "code" : "KRW",
    "name" : "韓圜",
    "symbol" : "₩"
  }, {
    "id" : 192,
    "code" : "KYD",
    "name" : "開曼群島幣",
    "symbol" : "$"
  }, {
    "id" : 193,
    "code" : "KZT",
    "name" : "哈薩克堅戈",
    "symbol" : "〒"
  }, {
    "id" : 194,
    "code" : "LBP",
    "name" : "黎巴嫩鎊",
    "symbol" : "ل.ل"
  }, {
    "id" : 195,
    "code" : "MAD",
    "name" : "摩洛哥迪拉姆",
    "symbol" : "د.م."
  }, {
    "id" : 196,
    "code" : "MNT",
    "name" : "蒙古圖格里克幣",
    "symbol" : "₮"
  }, {
    "id" : 197,
    "code" : "MOP",
    "name" : "澳門幣",
    "symbol" : "P"
  }, {
    "id" : 198,
    "code" : "MUR",
    "name" : "模里西斯盧比幣",
    "symbol" : "₨"
  }, {
    "id" : 199,
    "code" : "MXN",
    "name" : "墨西哥披索",
    "symbol" : "$"
  }, {
    "id" : 200,
    "code" : "MYR",
    "name" : "馬來西亞令吉",
    "symbol" : "RM"
  }, {
    "id" : 201,
    "code" : "NAD",
    "name" : "納米比亞幣",
    "symbol" : "$"
  }, {
    "id" : 202,
    "code" : "NGN",
    "name" : "奈及利亞奈拉",
    "symbol" : "₦"
  }, {
    "id" : 203,
    "code" : "NOK",
    "name" : "挪威克朗",
    "symbol" : "kr"
  }, {
    "id" : 204,
    "code" : "NZD",
    "name" : "紐西蘭幣",
    "symbol" : "$"
  }, {
    "id" : 205,
    "code" : "PAB",
    "name" : "巴拿馬巴波亞",
    "symbol" : "B/."
  }, {
    "id" : 206,
    "code" : "PEN",
    "name" : "秘魯新索爾幣",
    "symbol" : "S/."
  }, {
    "id" : 207,
    "code" : "PHP",
    "name" : "菲律賓披索",
    "symbol" : "₱"
  }, {
    "id" : 208,
    "code" : "PYG",
    "name" : "巴拉圭瓜拉尼幣",
    "symbol" : "₲"
  }, {
    "id" : 209,
    "code" : "QAR",
    "name" : "卡達里亞爾幣",
    "symbol" : "ر.ق"
  }, {
    "id" : 210,
    "code" : "RUB",
    "name" : "俄羅斯盧布",
    "symbol" : "р."
  }, {
    "id" : 211,
    "code" : "SAR",
    "name" : "沙烏地里亞爾幣",
    "symbol" : "ر.س"
  }, {
    "id" : 212,
    "code" : "SGD",
    "name" : "新加坡幣",
    "symbol" : "$"
  }, {
    "id" : 213,
    "code" : "THB",
    "name" : "泰銖",
    "symbol" : "฿"
  }, {
    "id" : 214,
    "code" : "TRY",
    "name" : "土耳其里拉",
    "symbol" : "₤"
  }, {
    "id" : 215,
    "code" : "TTD",
    "name" : "特立尼達和多巴哥幣",
    "symbol" : "$"
  }, {
    "id" : 216,
    "code" : "TWD",
    "name" : "臺灣新臺幣",
    "symbol" : "$"
  }, {
    "id" : 217,
    "code" : "TZS",
    "name" : "坦尚尼亞先令",
    "symbol" : "Sh"
  }, {
    "id" : 218,
    "code" : "USD",
    "name" : "美元",
    "symbol" : "$"
  }, {
    "id" : 219,
    "code" : "UYU",
    "name" : "烏拉圭披索",
    "symbol" : "$"
  }, {
    "id" : 220,
    "code" : "VND",
    "name" : "越南盾",
    "symbol" : "₫"
  }, {
    "id" : 221,
    "code" : "XCD",
    "name" : "東加勒比和西印度群島元",
    "symbol" : "$"
  }, {
    "id" : 222,
    "code" : "XPF",
    "name" : "太平洋法郎",
    "symbol" : "₣"
  }, {
    "id" : 223,
    "code" : "ZAR",
    "name" : "南非鍰",
    "symbol" : "R"
  } ],

  countries: [ {
    "id" : 42,
    "name" : "安道爾",
    "code" : "AND",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 43,
    "name" : "阿聯酋",
    "code" : "ARE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 44,
    "name" : "阿富汗",
    "code" : "AFG",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 45,
    "name" : "安提瓜和巴布達",
    "code" : "ATG",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 46,
    "name" : "安圭拉",
    "code" : "AIA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 47,
    "name" : "阿爾巴尼亞",
    "code" : "ALB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 48,
    "name" : "亞美尼亞",
    "code" : "ARM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 49,
    "name" : "安哥拉",
    "code" : "AGO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 50,
    "name" : "南極洲",
    "code" : "ATA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 51,
    "name" : "阿根廷",
    "code" : "ARG",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 52,
    "name" : "美屬薩摩亞",
    "code" : "ASM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 41,
    "name" : "尼泊爾",
    "code" : "NPL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 53,
    "name" : "奧地利",
    "code" : "AUT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 54,
    "name" : "澳大利亞",
    "code" : "AUS",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 55,
    "name" : "阿魯巴",
    "code" : "ABW",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 56,
    "name" : "奧蘭群島",
    "code" : "ALA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 57,
    "name" : "阿塞拜疆",
    "code" : "AZE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 58,
    "name" : "波黑",
    "code" : "BIH",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 59,
    "name" : "巴巴多斯",
    "code" : "BRB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 60,
    "name" : "孟加拉",
    "code" : "BGD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 61,
    "name" : "比利時",
    "code" : "BEL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 62,
    "name" : "布基納法索",
    "code" : "BFA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 63,
    "name" : "保加利亞",
    "code" : "BGR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 64,
    "name" : "巴林",
    "code" : "BHR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 65,
    "name" : "布隆迪",
    "code" : "BDI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 66,
    "name" : "貝寧",
    "code" : "BEN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 67,
    "name" : "聖巴泰勒米島",
    "code" : "BLM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 68,
    "name" : "百慕大",
    "code" : "BMU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 69,
    "name" : "文萊",
    "code" : "BRN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 70,
    "name" : "玻利維亞",
    "code" : "BOL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 71,
    "name" : "荷蘭加勒比區",
    "code" : "BES",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 72,
    "name" : "巴西",
    "code" : "BRA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 73,
    "name" : "巴哈馬",
    "code" : "BHS",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 74,
    "name" : "不丹",
    "code" : "BTN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 75,
    "name" : "布韋島",
    "code" : "BVT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 76,
    "name" : "博茨瓦納",
    "code" : "BWA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 77,
    "name" : "白俄羅斯",
    "code" : "BLR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 78,
    "name" : "伯利茲",
    "code" : "BLZ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 79,
    "name" : "加拿大",
    "code" : "CAN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 80,
    "name" : "科科斯群島",
    "code" : "CCK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 81,
    "name" : "中非",
    "code" : "CAF",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 82,
    "name" : "瑞士",
    "code" : "CHE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 83,
    "name" : "智利",
    "code" : "CHL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 84,
    "name" : "喀麥隆",
    "code" : "CMR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 85,
    "name" : "哥倫比亞",
    "code" : "COL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 86,
    "name" : "哥斯達黎加",
    "code" : "CRI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 87,
    "name" : "古巴",
    "code" : "CUB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 88,
    "name" : "佛得角",
    "code" : "CPV",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 89,
    "name" : "聖誕島",
    "code" : "CXR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 90,
    "name" : "塞浦路斯",
    "code" : "CYP",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 91,
    "name" : "捷克",
    "code" : "CZE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 92,
    "name" : "德國",
    "code" : "DEU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 93,
    "name" : "吉布提",
    "code" : "DJI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 94,
    "name" : "丹麥",
    "code" : "DNK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 95,
    "name" : "多米尼克",
    "code" : "DMA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 96,
    "name" : "多米尼加",
    "code" : "DOM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 97,
    "name" : "阿爾及利亞",
    "code" : "DZA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 98,
    "name" : "厄瓜多爾",
    "code" : "ECU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 99,
    "name" : "愛沙尼亞",
    "code" : "EST",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 100,
    "name" : "埃及",
    "code" : "EGY",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 101,
    "name" : "西撒哈拉",
    "code" : "ESH",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 102,
    "name" : "厄立特裏亞",
    "code" : "ERI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 103,
    "name" : "西班牙",
    "code" : "ESP",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 104,
    "name" : "芬蘭",
    "code" : "FIN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 105,
    "name" : "斐濟群島",
    "code" : "FJI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 106,
    "name" : "馬爾維納斯群島（福克蘭）",
    "code" : "FLK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 107,
    "name" : "密克羅尼西亞聯邦",
    "code" : "FSM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 108,
    "name" : "法羅群島",
    "code" : "FRO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 109,
    "name" : "法國",
    "code" : "FRA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 110,
    "name" : "加蓬",
    "code" : "GAB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 111,
    "name" : "格林納達",
    "code" : "GRD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 112,
    "name" : "格魯吉亞",
    "code" : "GEO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 113,
    "name" : "法屬圭亞那",
    "code" : "GUF",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 114,
    "name" : "加納",
    "code" : "GHA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 115,
    "name" : "直布羅陀",
    "code" : "GIB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 116,
    "name" : "格陵蘭",
    "code" : "GRL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 117,
    "name" : "幾內亞",
    "code" : "GIN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 118,
    "name" : "瓜德羅普",
    "code" : "GLP",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 119,
    "name" : "赤道幾內亞",
    "code" : "GNQ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 120,
    "name" : "希臘",
    "code" : "GRC",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 121,
    "name" : "南喬治亞島和南桑威奇群島",
    "code" : "SGS",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 122,
    "name" : "危地馬拉",
    "code" : "GTM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 123,
    "name" : "關島",
    "code" : "GUM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 124,
    "name" : "幾內亞比紹",
    "code" : "GNB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 125,
    "name" : "圭亞那",
    "code" : "GUY",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 2,
    "name" : "香港",
    "code" : "HKG",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 126,
    "name" : "赫德島和麥克唐納群島",
    "code" : "HMD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 127,
    "name" : "洪都拉斯",
    "code" : "HND",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 128,
    "name" : "克羅地亞",
    "code" : "HRV",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 129,
    "name" : "海地",
    "code" : "HTI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 130,
    "name" : "匈牙利",
    "code" : "HUN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 10,
    "name" : "印尼",
    "code" : "IDN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 131,
    "name" : "愛爾蘭",
    "code" : "IRL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 132,
    "name" : "以色列",
    "code" : "ISR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 133,
    "name" : "馬恩島",
    "code" : "IMN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 134,
    "name" : "印度",
    "code" : "IND",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 135,
    "name" : "英屬印度洋領地",
    "code" : "IOT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 136,
    "name" : "伊拉克",
    "code" : "IRQ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 137,
    "name" : "伊朗",
    "code" : "IRN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 138,
    "name" : "冰島",
    "code" : "ISL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 139,
    "name" : "意大利",
    "code" : "ITA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 140,
    "name" : "澤西島",
    "code" : "JEY",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 141,
    "name" : "牙買加",
    "code" : "JAM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 142,
    "name" : "約旦",
    "code" : "JOR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 40,
    "name" : "日本",
    "code" : "JPN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 143,
    "name" : "柬埔寨",
    "code" : "KHM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 144,
    "name" : "基裏巴斯",
    "code" : "KIR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 145,
    "name" : "科摩羅",
    "code" : "COM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 146,
    "name" : "科威特",
    "code" : "KWT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 147,
    "name" : "開曼群島",
    "code" : "CYM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 148,
    "name" : "黎巴嫩",
    "code" : "LBN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 149,
    "name" : "列支敦士登",
    "code" : "LIE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 150,
    "name" : "斯裏蘭卡",
    "code" : "LKA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 151,
    "name" : "利比裏亞",
    "code" : "LBR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 152,
    "name" : "萊索托",
    "code" : "LSO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 153,
    "name" : "立陶宛",
    "code" : "LTU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 154,
    "name" : "盧森堡",
    "code" : "LUX",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 155,
    "name" : "拉脫維亞",
    "code" : "LVA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 156,
    "name" : "利比亞",
    "code" : "LBY",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 157,
    "name" : "摩洛哥",
    "code" : "MAR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 158,
    "name" : "摩納哥",
    "code" : "MCO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 159,
    "name" : "摩爾多瓦",
    "code" : "MDA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 160,
    "name" : "黑山",
    "code" : "MNE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 161,
    "name" : "法屬聖馬丁",
    "code" : "MAF",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 162,
    "name" : "馬達加斯加",
    "code" : "MDG",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 163,
    "name" : "馬紹爾群島",
    "code" : "MHL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 164,
    "name" : "馬其頓",
    "code" : "MKD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 165,
    "name" : "馬裏",
    "code" : "MLI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 166,
    "name" : "緬甸",
    "code" : "MMR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 3,
    "name" : "澳門",
    "code" : "MAC",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 167,
    "name" : "馬提尼克",
    "code" : "MTQ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 168,
    "name" : "毛裏塔尼亞",
    "code" : "MRT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 169,
    "name" : "蒙塞拉特島",
    "code" : "MSR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 170,
    "name" : "馬耳他",
    "code" : "MLT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 171,
    "name" : "馬爾代夫",
    "code" : "MDV",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 172,
    "name" : "馬拉維",
    "code" : "MWI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 173,
    "name" : "墨西哥",
    "code" : "MEX",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 5,
    "name" : "馬來西亞",
    "code" : "MYS",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 174,
    "name" : "納米比亞",
    "code" : "NAM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 175,
    "name" : "尼日爾",
    "code" : "NER",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 176,
    "name" : "諾福克島",
    "code" : "NFK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 177,
    "name" : "尼日利亞",
    "code" : "NGA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 178,
    "name" : "尼加拉瓜",
    "code" : "NIC",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 179,
    "name" : "荷蘭",
    "code" : "NLD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 180,
    "name" : "挪威",
    "code" : "NOR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 182,
    "name" : "瑙魯",
    "code" : "NRU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 183,
    "name" : "阿曼",
    "code" : "OMN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 184,
    "name" : "巴拿馬",
    "code" : "PAN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 185,
    "name" : "秘魯",
    "code" : "PER",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 186,
    "name" : "法屬波利尼西亞",
    "code" : "PYF",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 187,
    "name" : "巴布亞新幾內亞",
    "code" : "PNG",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 8,
    "name" : "菲律賓",
    "code" : "PHL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 188,
    "name" : "巴基斯坦",
    "code" : "PAK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 189,
    "name" : "波蘭",
    "code" : "POL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 190,
    "name" : "皮特凱恩群島",
    "code" : "PCN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 191,
    "name" : "波多黎各",
    "code" : "PRI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 192,
    "name" : "巴勒斯坦",
    "code" : "PSE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 193,
    "name" : "帕勞",
    "code" : "PLW",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 194,
    "name" : "巴拉圭",
    "code" : "PRY",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 195,
    "name" : "卡塔爾",
    "code" : "QAT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 196,
    "name" : "留尼汪",
    "code" : "REU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 197,
    "name" : "羅馬尼亞",
    "code" : "ROU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 198,
    "name" : "塞爾維亞",
    "code" : "SRB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 199,
    "name" : "俄羅斯",
    "code" : "RUS",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 200,
    "name" : "盧旺達",
    "code" : "RWA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 201,
    "name" : "所羅門群島",
    "code" : "SLB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 202,
    "name" : "塞舌爾",
    "code" : "SYC",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 203,
    "name" : "蘇丹",
    "code" : "SDN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 204,
    "name" : "瑞典",
    "code" : "SWE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 7,
    "name" : "新加坡",
    "code" : "SGP",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 205,
    "name" : "斯洛文尼亞",
    "code" : "SVN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 206,
    "name" : "斯瓦爾巴群島和揚馬延島",
    "code" : "SJM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 207,
    "name" : "斯洛伐克",
    "code" : "SVK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 208,
    "name" : "塞拉利昂",
    "code" : "SLE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 209,
    "name" : "聖馬力諾",
    "code" : "SMR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 210,
    "name" : "塞內加爾",
    "code" : "SEN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 211,
    "name" : "索馬裏",
    "code" : "SOM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 212,
    "name" : "蘇裏南",
    "code" : "SUR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 213,
    "name" : "南蘇丹",
    "code" : "SSD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 214,
    "name" : "聖多美和普林西比",
    "code" : "STP",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 215,
    "name" : "薩爾瓦多",
    "code" : "SLV",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 216,
    "name" : "敘利亞",
    "code" : "SYR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 217,
    "name" : "斯威士蘭",
    "code" : "SWZ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 218,
    "name" : "特克斯和凱科斯群島",
    "code" : "TCA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 219,
    "name" : "乍得",
    "code" : "TCD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 220,
    "name" : "多哥",
    "code" : "TGO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 6,
    "name" : "泰國",
    "code" : "THA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 221,
    "name" : "托克勞",
    "code" : "TKL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 222,
    "name" : "東帝汶",
    "code" : "TLS",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 223,
    "name" : "突尼斯",
    "code" : "TUN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 224,
    "name" : "湯加",
    "code" : "TON",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 225,
    "name" : "土耳其",
    "code" : "TUR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 226,
    "name" : "圖瓦盧",
    "code" : "TUV",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 227,
    "name" : "坦桑尼亞",
    "code" : "TZA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 228,
    "name" : "烏克蘭",
    "code" : "UKR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 229,
    "name" : "烏幹達",
    "code" : "UGA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 230,
    "name" : "美國",
    "code" : "USA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 231,
    "name" : "烏拉圭",
    "code" : "URY",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 232,
    "name" : "梵蒂岡",
    "code" : "VAT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 233,
    "name" : "委內瑞拉",
    "code" : "VEN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 234,
    "name" : "英屬維爾京群島",
    "code" : "VGB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 235,
    "name" : "美屬維爾京群島",
    "code" : "VIR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 9,
    "name" : "越南",
    "code" : "VNM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 236,
    "name" : "瓦利斯和富圖納",
    "code" : "WLF",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 237,
    "name" : "薩摩亞",
    "code" : "WSM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 238,
    "name" : "也門",
    "code" : "YEM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 239,
    "name" : "馬約特",
    "code" : "MYT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 240,
    "name" : "南非",
    "code" : "ZAF",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 241,
    "name" : "贊比亞",
    "code" : "ZMB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 242,
    "name" : "津巴布韋",
    "code" : "ZWE",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 1,
    "name" : "中國",
    "code" : "CHN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 243,
    "name" : "剛果（布）",
    "code" : "COG",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 244,
    "name" : "剛果（金）",
    "code" : "COD",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 245,
    "name" : "莫桑比克",
    "code" : "MOZ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 246,
    "name" : "根西島",
    "code" : "GGY",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 247,
    "name" : "岡比亞",
    "code" : "GMB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 248,
    "name" : "北馬裏亞納群島",
    "code" : "MNP",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 249,
    "name" : "埃塞俄比亞",
    "code" : "ETH",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 250,
    "name" : "新喀裏多尼亞",
    "code" : "NCL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 251,
    "name" : "瓦努阿圖",
    "code" : "VUT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 252,
    "name" : "法屬南部領地",
    "code" : "ATF",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 253,
    "name" : "紐埃",
    "code" : "NIU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 254,
    "name" : "美國本土外小島嶼",
    "code" : "UMI",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 255,
    "name" : "庫克群島",
    "code" : "COK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 256,
    "name" : "英國",
    "code" : "GBR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 257,
    "name" : "特立尼達和多巴哥",
    "code" : "TTO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 258,
    "name" : "聖文森特和格林納丁斯",
    "code" : "VCT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 4,
    "name" : "台灣",
    "code" : "TWN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 259,
    "name" : "新西蘭",
    "code" : "NZL",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 260,
    "name" : "沙特阿拉伯",
    "code" : "SAU",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 261,
    "name" : "老撾",
    "code" : "LAO",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 262,
    "name" : "朝鮮",
    "code" : "PRK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 11,
    "name" : "韓國",
    "code" : "KOR",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 263,
    "name" : "葡萄牙",
    "code" : "PRT",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 264,
    "name" : "吉爾吉斯斯坦",
    "code" : "KGZ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 265,
    "name" : "哈薩克斯坦",
    "code" : "KAZ",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 266,
    "name" : "塔吉克斯坦",
    "code" : "TJK",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 267,
    "name" : "土庫曼斯坦",
    "code" : "TKM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 268,
    "name" : "烏茲別克斯坦",
    "code" : "UZB",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 269,
    "name" : "聖基茨和尼維斯",
    "code" : "KNA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 270,
    "name" : "聖皮埃爾和密克隆",
    "code" : "SPM",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 271,
    "name" : "聖赫勒拿",
    "code" : "SHN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 272,
    "name" : "聖盧西亞",
    "code" : "LCA",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 273,
    "name" : "毛裏求斯",
    "code" : "MUS",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 274,
    "name" : "科特迪瓦",
    "code" : "CIV",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 275,
    "name" : "肯尼亞",
    "code" : "KEN",
    "depth" : 0,
    "parentId" : null
  }, {
    "id" : 276,
    "name" : "蒙古國",
    "code" : "MNG",
    "depth" : 0,
    "parentId" : null
  } ]
};
