<template>
    <div>
        <vxe-grid
          ref="xTable"
          :edit-config="{
            trigger: 'click',
            mode: 'cell',
            activeMethod: activeRowMethod,
            showStatus: false,
            showAsterisk: true
          }"
          :menu-config="tableMenu"
          @menu-click="contextMenuClickEvent"
          :cell-class-name="rowClasses"
          v-bind="gridOptions"
          :id="formId"
          :loading="loading"
          @edit-actived="editActiveEvent"
          :edit-rules="validRules"
          keep-source
          show-header-overflow
          class="batchInserttableViewGrid"
          :data="tableData"
          auto-resize
          min-width="100%"
          style="height:0; overflow: auto; min-height: 100%;"
        >
            <vxe-table-column
                type="seq"
                min-width="40"
                width="40"
                fixed="left"
                class-name="text-center position-relative vxeCellEdit"
                header-class-name="text-center vxeCellEdit"
            >
            </vxe-table-column>
            <template v-for="(colDef, index) in colDefs">
                <component
                  v-if="colDef.componentName !== ''"
                  :is="'vxe' + colDef.componentName"
                  :colDef="colDef"
                  :relationData="relationData"
                  :key="index"
                  @onCommand="onCommandHandler"
                ></component>
                <vxe-table-column
                  v-else
                  v-bind="colDef"
                  :key="index"
                ></vxe-table-column>
                <vxe-table-column
                    v-else
                    v-bind="colDef"
                    :key="index"
              ></vxe-table-column>
            </template>
            <vxe-table-column
                :title="$t('general.operation')"
                :width="100"
                fixed="right"
                :show-overflow="false"
            >
                <template v-slot="{ row, rowIndex }">
                    <slot name="control" :row="row" :rowIndex="rowIndex">
                        <v-btn
                            class="mx-1"
                            dark
                            :elevation="false"
                            x-small
                            fab
                            color="error"
                            @click.native="deleteRow(row, rowIndex)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </slot>
                </template>
            </vxe-table-column>
        </vxe-grid>
      <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>
  
      <popup-record-dialog 
        ref="popupRecordDialog"
        @onCommand="onCommandHandler"
      ></popup-record-dialog>
  
      <select-related-records-dialog
        ref="selectRelatedRecordsDialog"
        @click:new="newRelatedRecord"
      ></select-related-records-dialog>
  
      <select-related-cards-dialog
        ref="selectRelatedCardsDialog"
        @click:new="newRelatedRecord"
      ></select-related-cards-dialog>
  
      <dialog-button-select-employees
        class="pa-0"
        :addButton="false"
        ref="tableEesDialog"
        v-model="showEesDialog"
      ></dialog-button-select-employees>
  
      <popupRecordDialog
        @ready="loading=false"
        ref="popupRecordDialog">
      </popupRecordDialog>
  
      <select-calc-method-menu
        ref="selectCalcMethodMenu"></select-calc-method-menu>    
        <v-snackbar
        v-model="showingSnackbar"
        top
        centered
        elevation="0"
        timeout="2000"
        content-class="d-flex justify-center"
        :color="snackbarColor"
        >
        <div class="d-flex justify-center" style="font-size:14px"> {{snackbarContent}} </div>
        </v-snackbar>
      <members-menu
        :fullWidth="true"
        v-if="showingMembersMenu"
        v-model="showingMembersMenu"
        :attachId="membersMenuPayload.attachedId"
        ref="membersMenu"
        @memberClick="handleMemberClick"
    /> 
  </div>
</template>
  
  <script>
  import baseMixin from "@/mixins/baseMixin";
  import appsFormsMixin from "@/mixins/appsFormsMixin";
  import propertyHelperMixin from "@/mixins/propertyHelperMixin";
  import vxeTableHelperMixin from "@/mixins/vxeTableHelperMixin";
  import validationsMixin from "@/pages/forms/views/validationsMixin";
  
  import fieldHelperMixin from "@/mixins/fieldHelperMixin";
  import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
  import selectCalcMethodMenu from '@/components/dialogs/SelectCalcMethodMenu';
  
  // for quick insert
  import recordMixin from "@/mixins/recordMixin";
  import quickInsertUndoMixin from "@/mixins/quickInsertUndoMixin";
  
  import ListMenu from '@/pages/admin/workflow/manualActions/tools/menu';
  
  import vxeTableCells from "@/components/vxeTableCells";
  import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
  import confirmDialog from "@/components/dialogs/ConfirmDialog";
  import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
  import selectRelatedCardsDialog from "@/components/dialogs/SelectRelatedCardsDialog";
  import membersMenu from '@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu'
  
  import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";
  
  import EventBus from "@/event-bus.js";
  import { newBlankRecord } from "@/helpers/FormHelpers";
  import dialogButtonSelectEmployees from '@/components/dialogButtons/DialogButtonSelectEmployees'
  
  
  const TRANSFERRABLE_MAP = {
    text: ["text", "richText"],
    number: ["text", "number", "amount"],
    amount: ["text", "number", "amount"],
    email: ["text", "email"],
    date: ["text", "date"],
    phone: ["text", "phone"],
    singleSelection: ["text"],
    multipleSelection: ["text"],
    attachments: ["text", "attachments"],
    region: ["text", "region"],
    location: ["text", "location"],
    expression: ["text"],
    checkbox: ["text", "checkbox"],
    rating: ["text", "rating"],
    textCombination: [],
    autoNumbering: [],
    richText: ["text", "richText"],
    idCard: ["text", "idCard"],
    members: ["text", "members"],
    departments: ["text", "departments"],
    signature: [],
  };
  
  const MENUS = [
    {
      code: "copyRow",
      nameTag: "contextMenu.duplicateRow",
      prefixIcon: "mdi mdi-content-copy tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "deleteRow",
      nameTag: "contextMenu.deleteRow",
      prefixIcon: "mdi mdi-table-row-remove tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
  
    {
      code: "moveToTop",
      nameTag: "contextMenu.moveToTop",
      prefixIcon: "mdi mdi-arrow-collapse-up tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "moveUp",
      nameTag: "contextMenu.moveUp",
      prefixIcon: "mdi mdi-arrow-up tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "moveDown",
      nameTag: "contextMenu.moveDown",
      prefixIcon: "mdi mdi-arrow-down tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "moveToBottom",
      nameTag: "contextMenu.moveToBottom",
      prefixIcon: "mdi mdi-arrow-collapse-down tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
  
    {
      code: "insertOneRowAbove",
      nameTag: "contextMenu.insertOneRowAbove",
      prefixIcon: "mdi mdi-numeric-1-box tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "insertFiveRowsAbove",
      nameTag: "contextMenu.insertFiveRowsAbove",
      prefixIcon: "mdi mdi-numeric-5-box tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "insertTenRowsAbove",
      nameTag: "contextMenu.insertTenRowsAbove",
      prefixIcon: "mdi mdi-numeric-10-box tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
  
    {
      code: "addOneRowBelow",
      nameTag: "contextMenu.addOneRowBelow",
      prefixIcon: "mdi mdi-numeric-1-box tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "addFiveRowsBelow",
      nameTag: "contextMenu.addFiveRowsBelow",
      prefixIcon: "mdi mdi-numeric-5-box tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "addTenRowsBelow",
      nameTag: "contextMenu.addTenRowsBelow",
      prefixIcon: "mdi mdi-numeric-10-box tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
  
    {
      code: "undo",
      nameTag: "contextMenu.undo",
      prefixIcon: "mdi mdi-undo tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "redo",
      nameTag: "contextMenu.redo",
      prefixIcon: "mdi mdi-redo tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
  
    {
      code: "resetAllRows",
      nameTag: "contextMenu.resetAllRows",
      prefixIcon: "mdi mdi-alert-remove tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
    {
      code: "resetCurrentRow",
      nameTag: "contextMenu.resetCurrentRow",
      prefixIcon: "mdi mdi-alert-remove tableMenuItemIcon",
      visible: true,
      disabled: false,
      className: "tableMenuItem",
    },
  ];
  
  const ADD_BELOW = true;
  const INSERT_ABOVE = false;
  import {
    FETCH_ACTION_BUTTON_OPTIONS,
    SET_GLOBAL_ACTION,
    INTERACT_ACTION_BUTTOIN,
  } from '@/store/modules/customizedAction/action_types';
  export default {
    name: "batchInsertTableView",
    mixins: [
      appsFormsMixin,
      baseMixin,
      propertyHelperMixin,
      vxeTableHelperMixin,
      validationsMixin,
      recordMixin,
      fieldHelperMixin,
      quickInsertUndoMixin
    ],
    components: {
      ...vxeTableCells,
      confirmDeleteDialog,
      confirmDialog,
      selectRelatedRecordsDialog,
      selectRelatedCardsDialog,
      popupRecordDialog,
      selectCalcMethodMenu,
      // selectCalcMethodDialog,
      dialogButtonSelectEmployees,
      membersMenu,
      ListMenu,
    },
    data () {
      return {
        showingMenu: false,
        attach: '',
        showingTestingDialog: false,
        tableType: "master", // as identifier for vxeTableHelperMixin to handle table height differently
        renderComponent: true,
        tableHeight: 280,
        loading: false,
  
        tableData: [],
        displayValues: null,
  
        showOverlay: false,
        isAllRecords: false,
        active: true,
        includeRecords: [],
        excludeRecords: [],
        filterValue: "",
        selectedAction: [],
        keyword: "",
        relationData: {
          idDataMapping: {},
        },
        attach: "",
        savedPageSize: 20,
        loadingPageSize: false,
  
  
        // for quick insert
        recordData: {
          _id: "",
        },
        quickInsertModeRowHeight: "s",
        blankRecordDataStr: "",
        // clipboardRowId: '',
        // clipboardFieldId: '',
        clipboardValue: null,
        clipboardFieldInfo: null,
  
  
        savedCellInfo: null,
        disableContextMenu: false,
  
        showEesDialog: false,
        pageSizeAvailable: false,
        summaryData: [],
        
        quickFilters: [],
        quickFilterAndOr: 'and',
        showBulkEditDialog: false,
        snackbarContent: '',
        showingSnackbar: false,
        snackbarColor: "",
        showingMembersMenu: false,
        membersMenuPayload: null,
        shareLinks: [],
        formId: null
      };
    },
    props: {
        view: Object,
        printTemplates: Array,
        canImport: Boolean,
        canExport: Boolean,
        canCopy: Boolean,
        canInlineEdit: Boolean,
        canPrint: Boolean,
        isQuickInsertMode: Boolean,
        settings: Object,
        rowCount: Number
    },
    created(){
        delete this.gridOptions.proxyConfig
        delete this.gridOptions.pagerConfig
        delete this.gridOptions.showFooter
        this.isQuickInsertMode = true
        this.formId = this.settings.dataSource
        
    },
    beforeDestroy() {
      EventBus.$off("selectRelatedCards");
      EventBus.$off("selectRelatedRecords");
  
      EventBus.$off('copyToClipboard')
      EventBus.$off('pasteFromClipboard')
    },
  
    async mounted() {
      const vm = this;
      EventBus.$on("selectRelatedCards", vm.selectRelatedCards);
      EventBus.$on("selectRelatedRecords", vm.selectRelatedRecords);
  
      EventBus.$on("copyToClipboard", vm.copyToClipboard);
      EventBus.$on("pasteFromClipboard", vm.pasteFromClipboard);
      
      this.createRows(this.rowCount);
      vm.$refs.xTable.resetColumn();
      vm.onWindowResized()
    },
    computed: {
      buttonOptions() {
        return this.$store.getters.getActionButtonOptions;
      },
      hasCustomizedButtons() {
        return this.$store.getters.anyButtons;
      },
      criteriaConfigs () {
        return this.$store.getters.criteriaConfigs
      },
  
      filteredColDefs(){
        return this.colDefs.filter(colDef=>{
          return !['createdBy', 'createdAt', 'updatedAt', 'owner'].includes(colDef.fieldInfo.type)
        })
      },
      haveParentChildFields () {
        const vm = this
        var result = false
        for (let i = 0; i < vm.effectiveFieldIds.length; i++) {
          if (vm.allLinkedFieldIds.includes(vm.effectiveFieldIds[i])) {
            result = true
            break
          }
        }
        return result
      },
  
      allLinkedFieldIds () {
        const vm = this
        var result = []
        if (vm.form && vm.form.linkedFields) {
          for (let i = 0; i < vm.form.linkedFields.length; i++) {
            const loopLinkedFields = vm.form.linkedFields[i]
            result = result.concat(loopLinkedFields.fieldIds)
          }
        }
        return result
      },
      clipboardValueString() {
        return this.getFieldString(this.clipboardFieldInfo, this.clipboardValue);
      },
      quickInsertUnusedRecordIds() {
        const vm = this;
        let result = [];
        for (let i = 0; i < vm.tableData.length; i++) {
          const loopRow = vm.tableData[i];
          const rowId = loopRow._id;
          const rowWithoutId = { ...loopRow, _id: "" };
          const rowStr = JSON.stringify(rowWithoutId);
          if (rowStr === vm.blankRecordDataStr) {
            result.push(rowId);
          }
        }
        return result;
      },
      quickInsertDataRowCount() {
        const vm = this;
  
        return (
          vm.tableData.length - vm.quickInsertUnusedRecordIds.length
        );
      },
      quickInsertTotalRowCount() {
        return this.tableData.length;
      },
      appId() {
        return this.currentApp._id;
      },
      relatedTableInfos() {
        return this.$store.getters.relatedTableInfos;
      },
      menuSection1() {
        const vm = this;
        let result = vm.createMenu(["copyRow", "deleteRow"]);
        return result;
      },
      menuSectionQuickInsert() {
        return [
          this.createMenu(["moveToTop", "moveUp", "moveDown", "moveToBottom"]),
          this.createMenu([
            "insertOneRowAbove",
            "insertFiveRowsAbove",
            "insertTenRowsAbove",
            "addOneRowBelow",
            "addFiveRowsBelow",
            "addTenRowsBelow",
          ]),
          this.createMenu(["resetCurrentRow", "resetAllRows"]),
        ];
      },
  
      tableMenuOptions() {
        const vm = this;
        let menuSections = [];
        if (vm.isQuickInsertMode) {
          menuSections = [...vm.menuSectionQuickInsert];
        }
        return menuSections;
      },
  
  
      tableMenu() {
        const vm = this;
        return {
          body: {
            options: vm.tableMenuOptions,
          },
          className: "tableMenu",
          visibleMethod: this.visibleMethod,
        };
      },
  
      isIndeterminate() {
        return (
          this.numberOfSelections > 0 &&
          this.numberOfSelections < this.$refs.xTable.tablePage.total
        );
      },
      isAllChecked() {
        return (
          this.numberOfSelections > 0 &&
          this.numberOfSelections === this.$refs.xTable.tablePage.total
        );
      },
      currentApp() {
        return this.$store.getters.currentApp;
      },
      form(){
        return this.relatedTableInfos[this.formId]
      },
      isAdmin(){
        return this.$store.getters.isAdmin
      },
      titleFieldId() {
        const vm = this;
        var result = "";
        if (vm.fieldInfos && vm.form.titleFieldInfoId) {
          const titleFieldInfo = vm.fieldInfos.find(
            (info) => info._id === vm.form.titleFieldInfoId
          );
          if (titleFieldInfo) {
            result = titleFieldInfo.fieldId;
          }
        }
        return result;
      },
      fieldInfos() {
        return this.form.fieldInfos;
      },
      fieldInfoFieldIds() {
        return this.fieldInfos.map((info) => info.fieldId);
      },
      effectiveFieldInfos () {
        return this.getFieldInfos(this.effectiveFieldIds)
      },
      effectiveFieldIds() {
        return this.fieldInfoFieldIds
      },
      displayFieldCount() {
        if (this.view.fixedFieldIds && this.view.nonFixedFieldIds) {
          return (
            this.view.fixedFieldIds.length + this.view.nonFixedFieldIds.length
          );
        } else return 0;
      },
      tableActions() {
        const vm = this;
        return [
          {
            label: "Copy",
            action: "copy",
            enabled: true,
            icon: "mdi-content-copy",
            color: "warning",
            tooltip: vm.$t("buttons.copy"),
            submenu: [],
          },
          {
            label: "Other",
            action: "other",
            enabled: true,
            icon: "mdi-shape-plus",
            color: "primary",
            tooltip: "Other Buttons",
            submenu: [
              { label: "item 1", value: "111" },
              { label: "item 2", value: "112" },
              { label: "item 3", value: "113" },
              { label: "item 4", value: "114" },
            ],
          },
        ];
      },
        colDefs() {
            let nonFixed = []
            this.setColDefsGroup(nonFixed, this.fieldInfos, true)
            nonFixed=nonFixed
                .filter(item=>!item.fieldInfo.isSystem)
                .map(item=>{
                    delete item.pagerConfig
                    delete item.formConfig
                    delete item.fixed
                    console.log("item", {...item})
                    return {...item}
                })
            return nonFixed
        },
    },
    methods: {
      deleteRow(row, rowIndex) {
        this.tableData.splice(rowIndex, 1);
      },
      saveQuickInsertData() {
        this.loading = true
        this.$emit("onDataLoading")
        const vm = this;
        const tableData = vm.tableData;
        const obsolateIds = vm.quickInsertUnusedRecordIds;
        const dataRows = tableData.filter(
          (row) => !obsolateIds.includes(row._id)
        );
  
        EventBus.$emit('showSpinner')
        vm.$store
          .dispatch("SAVE_DATA_BATCH", {
            obsolateIds: obsolateIds,
            data: dataRows,
          })
          .then((response) => {
            const savedCount = response.length;
            const msg = vm.$t("messages.nRecordsSavedSuccessfully", {
              count: savedCount,
            });
            vm.$XModal.message({
              message: msg,
              status: "success",
            }).then(
              () => {
                vm.loading = false
                vm.$emit("onDataLoaded")
                vm.$emit("close")
                vm.$emit("batchInsert", dataRows.map(item=>item._id))
              }
            )
            EventBus.$emit('hideSpinner')
          }).catch(error=>{
            vm.$toast.error(this.$t("errors.save"))
            EventBus.$emit('hideSpinner')
          }).finally(()=> {
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
              key: "plus_sheet_mani",
              data: {
                module_name: this.form.label,
                func_name: this.$sensors.events.plus_sheet_mani.table_view.QUICK_CREATE_RECORDS
              }
            });
          });
      },
  
      checkAndSaveQuickInsertData() {
        const vm = this;
        if (vm.quickInsertDataRowCount === 0) {
          this.$XModal.message({
            content: vm.$t("messages.noData"),
            status: "warning",
          });
        } else {
          this.fullValidEvent(() => {
            vm.saveQuickInsertData();
          });
        }
        // alert('saveAllQuickInsertData')
      },
  
      getColDuplications (fieldId) {
        const vm = this
        const rows = vm.tableData
        let result = []
        // console.log('getColDuplications : fieldId = ' + fieldId)
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
          const loopRow = rows[rowIndex]
          // console.log('rowIndex = ' + rowIndex + ': loopRow[fieldId] = ' + loopRow[fieldId])
          if (loopRow[fieldId]) {
            const index = result.findIndex(item => item.value === loopRow[fieldId])
            if (index >= 0) {
              result[index].rowIndices.push(rowIndex)
            } else {
              result.push({
                rowIndices: [rowIndex],
                value: loopRow[fieldId]
              })
              // console.log('add to result: rowIndex = ' + rowIndex)
              // console.log('add to result: value = ' + loopRow[fieldId])
            }
          }
        }
        // console.log('getColDuplications: result: ', JSON.stringify(result))
        return result.filter(item => item.rowIndices.length > 1)
      },
  
      getLocalDuplication () {
        const vm = this
        vm.loadingPageSize = true
        const colDefsWithNoDup = vm.colDefs.filter(colDef => {
          const properties = colDef.fieldInfo.properties
          return properties.validation && properties.validation.split('||').includes('noDuplication')
        })
        // console.log('getLocalDuplication: colDefsWithNoDup: ', colDefsWithNoDup)
        let result = {}
        for (let i = 0; i < colDefsWithNoDup.length; i++) {
          const loopColDef = colDefsWithNoDup[i]
          const fieldId = loopColDef.fieldInfo.fieldId
          const duplications = vm.getColDuplications(fieldId)
          // [
          //    {
          //      rowIndices: [],
          //      value: ''
          //    }
          // ]
          for (let j = 0; j < duplications.length; j++) {
            const loopDup = duplications[j]
            for (let k = 0; k < loopDup.rowIndices.length; k++) {
              const dummyKey = j + '-' + k
              result[dummyKey] = [{
                rowIndex: loopDup.rowIndices[k],
                column: loopColDef,
                rules: [{
                  message: vm.$t('messages.hasDuplicate') + ': ' + loopDup.value
                }]
              }]
            }
          }
        }
        return Object.keys(result).length === 0 ? null : result
      },
  
      async fullValidEvent (successHandler) {
        const vm = this
        let errMap = null
        const tableData = vm.tableData;
        const obsolateIds = vm.quickInsertUnusedRecordIds;
        const dataRows = tableData.filter(
          (row) => !obsolateIds.includes(row._id)
        );
        let errMap1 = await this.$refs.xTable.fullValidate(dataRows).catch(errMap => errMap)
        let errMapNoDup = vm.getLocalDuplication()
  
        if (errMap1) {
          errMap = {...errMap1}
          if (errMapNoDup) {
            errMap = Object.assign(errMap, errMapNoDup)
          }
        } else {
          if (errMapNoDup) {
            errMap = {...errMapNoDup}
          }
        }
        if (errMap) {
          let msgList = [];
          Object.values(errMap).forEach((errList) => {
            errList.forEach((params) => {
              let { rowIndex, column, rules } = params;
              rules.forEach((rule) => {
                // msgList.push(`第 ${rowIndex+1} 行: ${column.title} 校验错误：${rule.message}`)
                msgList.push(
                  vm.$t("messages.vxeError", {
                    rowNo: rowIndex + 1,
                    fieldName: column.title,
                    message: rule.message,
                  })
                );
              });
            });
          });
          vm.showMessagePanel(msgList);
  
        } else {
          vm.hideMessagePanel();
          if (typeof successHandler === "function") {
            successHandler();
          } else {
            //this.$XModal.message({ status: "success", message: "校验成功！" });
          }
        }
      },
  
      showMessagePanel(msgList) {
        EventBus.$emit("showMessagePanel", {
          messages: msgList,
        });
      },
  
      hideMessagePanel() {
        EventBus.$emit("showMessagePanel", {
          messages: null,
        });
      },
  
      copyToClipboard(payload) {
        const vm = this;
        vm.$refs.xTable.clearActived()
        const row = vm.tableData.find((d) => d._id === payload.rowId);
        console.log("copyToClipboard::payload", payload)
        console.log("copyToClipboard::row", row)
        if (row) {
          this.clipboardValue = row[payload.fieldId];
          this.clipboardFieldInfo = vm.getFieldInfo(payload.fieldId);
        } else {
          this.clipboardValue = null;
          this.clipboardFieldInfo = null;
        }
      },
  
      checkCanTransfer(sourceFieldInfo, targetFieldInfo) {
        let result = sourceFieldInfo.fieldId === targetFieldInfo.fieldId;
        if (!result) {
          const validTargetTypes = TRANSFERRABLE_MAP[sourceFieldInfo.type];
          result = validTargetTypes.includes(targetFieldInfo.type);
        }
        return result;
      },
  
      pasteFromClipboard(payload) {
        const vm = this;

        vm.$refs.xTable.clearActived()
        
        const sourceFieldInfo = vm.clipboardFieldInfo;
        const targetFieldInfo = vm.getFieldInfo(payload.fieldId);
  
        const transferrable = vm.checkCanTransfer(
          sourceFieldInfo,
          targetFieldInfo
        );

        if (transferrable) {

          const targetRowIndex = vm.tableData.findIndex(
            (row) => row._id === payload.rowId
          );
  
          const target = {
            rowIndex: targetRowIndex,
            fieldInfo: targetFieldInfo,
          };
          console.log("pasteFromClipboard :: target: ", target);
          vm.pasteCellValue(vm.tableData, target);
        }

      },
  
      pasteCellValue(tableData, target) {
        console.log("pasteCellValue", {tableData, target})
        const vm = this;
        if (vm.clipboardFieldInfo.fieldId === target.fieldInfo.fieldId) {
          console.log("same field id");

          vm.directCopy(tableData, target);
        } else {
          if (target.rowIndex >= 0) {  
            console.log('rowIndex > 0')      
            if (target.fieldInfo.type === 'text') {
              console.log('type is text')
              vm.directCopy(tableData, target, vm.clipboardValueString)
            } else {
              console.log("type is not text");
              vm.directCopy(tableData, target);
            }
          }
        }
      },

      directCopy(tableData, target, value) {
        const vm = this;
        if (typeof value === "undefined") {
          value = vm.clipboardValue;
        }
        console.log("directCopy: target.rowIndex = " + target.rowIndex);
        console.log(
          "directCopy: target.fieldInfo.fieldId = " +
            target.fieldInfo.fieldId
        );
        console.log("directCopy: value = " + value);
        tableData[target.rowIndex][target.fieldInfo.fieldId] = JSON.parse(
          JSON.stringify(value)
        );
      },
  
      getFieldInfo(fieldId) {
        const vm = this;
        const fieldInfo = vm.fieldInfos.find((info) => info.fieldId === fieldId);
        return fieldInfo;
      },
       activeRowMethod({ row }) {
        return this.canInlineEdit && row.canEdit || false;
      },
      visibleMethod({ options, column }) {
        let isDisabled = !column;
        options.forEach((list) => {
          list.forEach((item) => {
            item.disabled = isDisabled;
          });
        });
        return !this.disableContextMenu;
      },
      moveToTop(tableData, rowIndex) {
        if (rowIndex > 0) {
          const rows = tableData.splice(rowIndex, 1);
          tableData.unshift(rows[0]);
        }
      },
  
      moveUp(tableData, rowIndex) {
        if (rowIndex > 0) {
          const rows = tableData.splice(rowIndex, 1);
          tableData.splice(rowIndex - 1, 0, rows[0]);
        }
      },
  
      moveDown(tableData, rowIndex) {
        if (rowIndex < tableData.length - 1) {
          const rows = tableData.splice(rowIndex, 1);
          tableData.splice(rowIndex + 1, 0, rows[0]);
        }
      },
  
      moveToBottom(tableData, rowIndex) {
        if (rowIndex < tableData.length - 1) {
          const rows = tableData.splice(rowIndex, 1);
          tableData.push(rows[0]);
        }
      },
  
      addBelow(tableData, rowIndex, newRecord) {
        if (rowIndex < tableData.length) {
          tableData.splice(rowIndex + 1, 0, newRecord);
        } else {
          tableData.push(newRecord);
        }
      },
  
      insertAbove(tableData, rowIndex, newRecord) {
        if (rowIndex < tableData.length) {
          tableData.splice(rowIndex, 0, newRecord);
        } else {
          tableData.push(newRecord);
        }
      },
  
      resetAllRows(xTable, tableData) {
        for (let i = 0; i < tableData.length; i++) {
          xTable.revertData(tableData[i]);
        }
      },
  
      resetCurrentRow(xTable, rowIndex) {
        const vm = this;
        console.log("resetCurrentRow", {
            xTable, rowIndex
        })
        const rowId = this.tableData[rowIndex]._id
        this.tableData[rowIndex] = Object.assign(
          this.tableData[rowIndex],
          JSON.parse(vm.blankRecordDataStr),
          {_id: rowId}
        )
      },
      newRelatedRecord(payload) {
        const vm = this;
        vm.showOverlay = true;
        if (payload) {
          vm.attach = payload.attach;
          this.$refs.popupRecordDialog.open(payload, () => {        
            vm.disableContextMenu = false;
            vm.showOverlay = false;
          })
        }
      },
      selectRelatedCards(payload) {
        const vm = this;
        vm.showOverlay = true;
        vm.attach = payload.attach;
        // console.log('TableView :: selectRelatedCards :: payload: ', payload)
        vm.disableContextMenu = true;
        vm.$refs.selectRelatedCardsDialog.open(
          payload,
          vm.onRelatedRecordSelected,
          vm.onRelatedRecordCancelled
        );
      },
  
      selectRelatedRecords(payload) {
        const vm = this;
        vm.showOverlay = true;
        vm.attach = payload.attach;
        // console.log('TableView :: selectRelatedRecords :: payload: ', payload)
        vm.disableContextMenu = true;
        vm.$refs.selectRelatedRecordsDialog.open(
          payload,
          vm.onRelatedRecordSelected,
          vm.onRelatedRecordCancelled
        );
      },
  
      onRelatedRecordSelected(payload, callbackBundle) {
        const vm = this;
        vm.disableContextMenu = false;
        vm.showOverlay = false;
        var referedDialog, selectionTableData
        if(vm.$refs.selectRelatedCardsDialog.showing){
          referedDialog = vm.$refs.selectRelatedCardsDialog
          selectionTableData = referedDialog.$refs.selectionCardView.tableData
        }else if(vm.$refs.selectRelatedRecordsDialog.showing){
          referedDialog = vm.$refs.selectRelatedRecordsDialog
          selectionTableData = referedDialog.$refs.selectionTableView.tableData
        }
        
        const selectedData = selectionTableData.filter(item=>payload.includes(item._id))
        selectedData.forEach((val)=>{
          vm.relationData.idDataMapping[val._id] = val;
        });
        if (typeof callbackBundle.onSelected === "function") {
          callbackBundle.onSelected(payload);
        }
      },
  
      onRelatedRecordCancelled(callbackBundle) {
        const vm = this;
        vm.showOverlay = false;
        vm.disableContextMenu = false;
        if (typeof callbackBundle.onCancelled === "function") {
          callbackBundle.onCancelled();
        }
      },
      isEditing(row) {
        return this.$refs.xTable.isActiveByRow(row);
      },
      isUpdated(row) {
        return this.$refs.xTable.isUpdateByRow(row);
      },
      editDisabledEvent() {
        return true;
      },
      resizableChanged({ column, $table }, e) {
        e.preventDefault();
        const renderWidth = column.renderWidth;
        const fieldId = column.property
          ? column.property
          : column.type
          ? `type=${column.type}`
          : undefined;
        const tableId = $table.id;
        let localstor = JSON.parse(
          localStorage.getItem("VXE_TABLE_CUSTOM_COLUMN_WIDTH")
        );
        if (localstor !== null) {
          //update existing value
          Object.assign(localstor[tableId], {
            [fieldId]: renderWidth,
          });
        } else {
          //create new localStorage item
          localstor = {
            [tableId]: {
              [fieldId]: renderWidth,
            },
            _v: 0,
          };
        }
        localStorage.setItem(
          "VXE_TABLE_CUSTOM_COLUMN_WIDTH",
          JSON.stringify(localstor)
        );
      },
    async createRows(count, addBelow = true, rowIndex = -1, callback) {
      const vm = this;
      count = (this.tableData.length+count>50)?50-this.tableData.length:count
      if(count==0) return
      console.log('createRows count = ' + count)
      const blankRecordData = newBlankRecord(vm.fieldInfos);
      vm.loading = true
      blankRecordData["formId"] = vm.formId;
      blankRecordData["canEdit"] = true;
      blankRecordData["canDelete"] = true;
      blankRecordData["owner"] = vm.$store.getters.userFieldValue;
      blankRecordData["createdBy"] = vm.$store.getters.userFieldValue;

      vm.blankRecordDataStr = JSON.stringify(blankRecordData);
      
      await vm.$store
        .dispatch("GENERATE_TEMP_RECORD_ID", {
          appId: vm.appId,
          formId: vm.formId,
          count: count,
        })
        .then((response) => {
          const newRecordIds = response.result
          const tableData = vm.tableData;

          for (let i = 0; i < newRecordIds.length; i++) {
            const loopId = newRecordIds[i];
            const newRecord = JSON.parse(vm.blankRecordDataStr);
            newRecord._id = loopId;

            if (rowIndex === -1) {
              vm.tableData.push(newRecord);
            } else {
              if (addBelow) {
                vm.addBelow(tableData, rowIndex, newRecord);
              } else {
                vm.insertAbove(tableData, rowIndex, newRecord);
              }
            }
          }
          if (typeof callback === "function") {
            callback();
          }
          vm.loading = false;
        }).catch(error=>{
          vm.$toast.error(this.$t("errors.create"))
          vm.loading = false;
        });
    },

    newRecord() {
        const vm = this;
        var result = {
            _id: "",
            canEdit: true,
        };

        if (vm.fieldInfos) {
            for (let i = 0; i < vm.fieldInfoss.length; i++) {
            const loopFieldInfo = vm.fieldInfos[i];
            result[loopFieldInfo.fieldId] = vm.getFieldDefault(loopFieldInfo);
            }
        }
        return result;
    },
    refreshRelationData(payload) {
        const vm = this;
        const extra = payload.addition;
        if (vm.$refs.xTable) {
          // console.log('refreshRelationData :: vm.$refs.xTable.tableData: ', vm.$refs.xTable.tableData)
          vm.fetchRelationDetails(vm.tableData, extra);
        }
      },
      fetchRelationDetails(rows, extra = null) {
        const vm = this;
        if (typeof rows === "undefined") rows = vm.tableData;
        try {
          fetchRelatedDataFieldsInRows(
            vm,
            rows,
            extra,
            false,
            vm.fieldInfos,
            vm.effectiveFieldIds
          ).then((response) => {
              vm.relationData.idDataMapping = response;
            })
        } catch (err) {
          console.log("fetchRelationDetails :: err: ", err);
        }
      },
      reloadData() {
        this.$refs.xTable.reloadData();
      },
      updateStatus() {
        const vm = this;
        vm.$refs.xTable.updateStatus();
      },
      hasRowChanged() {
        return;
      },
      editActiveEvent({
        row,
        rowIndex,
        $rowIndex,
        column,
        columnIndex,
        $columnIndex,
      }) {
        const vm = this;
        vm.savedCellInfo = {
          rowId: row._id,
          fieldId: column.prop,
          value: row[column.prop],
        };
      },
      editClosedEvent({ row }) {
        const vm = this;
        if (vm.isQuickInsertMode) return;
        if (!vm.loading) {
          const xTable = vm.$refs.xTable;
          if (xTable.isUpdateByRow(row)) {
            vm.saveRowEvent(row);
          } else {
            console.log("editClosedEvent :: not isUpdatedByRow(row)");
          }
        }
      },
      async cancelRowEvent(row) {
        const xTable = this.$refs.xTable;
        await xTable.revertData(row);
      },
  
  
      confirmAbandon(confirmHandler, cancelHandler) {
        const vm = this
  
        vm.confirmEither({
          title: vm.$t('general.warning'),
          message: vm.$t('messages.errorsOccurred'),
          color: 'error',
  
          confirmBtnColor: 'error',
          confirmMsg: vm.$t('buttons.abandon'),
          confirmHandler: confirmHandler,
          
          cancelBtnColor: 'muted',
          cancelMsg: vm.$t('buttons.continueEditing'),
          cancelHandler: cancelHandler
        })
      },
  
      confirmEither(option) {
        const vm = this;
  
        console.log('confirmEither: option: ', option)
        vm.$refs.confirmDialog
          .open(option.title, option.message, {
            color: option.color,
            confirmBtnColor: option.confirmBtnColor,
            confirmMsg: option.confirmMsg,
            cancelBtnColor: option.cancelBtnColor,
            cancelMsg: option.cancelMsg,
            buttons: []
          })
          .then((result) => {
            if (result) {
              // console.log('confirm')
              option.confirmHandler()
            } else {
              // console.log('cancel')
              if (typeof option.cancelHandler === "function") {
                option.cancelHandler()
              }
            }
          });
      },
    
      saveRowEvent(row) {
        // console.log('this.$refs.xTable', this.$refs.xTable)
        const vm = this;
        const xTable = vm.$refs.xTable;
        // console.log(row)
        if (xTable.isUpdateByRow(row)) {
          vm.loading = true;
          xTable.validate(row, (errMap) => {
            if (errMap) {
              xTable.clearValidate();
              vm.confirmAbandon(
                () => {
                  // console.log('confirmAbandon')
                  xTable.revertData(row);
                  // Object.keys(errMap).forEach(fieldId => {
                  //   xTable.revertData(row, fieldId)
                  // })
                  // console.log('clearActived')
                  xTable.clearActived().then(() => {
                    vm.$nextTick(() => {
                      // console.log("clearValidate");
                      xTable.clearValidate();
                      xTable.clostTooltip();
                    });
                  });
                  vm.loading = false;
                },
                () => {
                  vm.$nextTick(() => {
                    // console.log('clearValidate')
                    xTable.clearValidate();
                    vm.setActiveRow(row);
                    // console.log('cancelAbandon')
                    vm.loading = false;
                  });
                  // alert('cancelAbandon')
                }
              );
              return;
            } else {
              row["appId"] = vm.currentApp._id;
              const postData = {
                data: row,
              };
              // console.log("TableView => save_data: postData: ", postData);
              vm.$store.dispatch("SAVE_DATA", postData).then((response) => {
                vm.loading = false;
                // console.log("TableView :: SAVE_DATA: response: ", response);
                Object.keys(row).forEach((fieldId) => {
                  this.$set(row, fieldId, response[fieldId]);
                });
                if (vm.haveParentChildFields) {
                  vm.refresh()
                } else {
                  xTable.reloadRow(row);
                  //xTable.reloadRow(row, response);
                  xTable.clearActived().then(() => {
                    vm.$XModal.message({
                      message: vm.$t("messages.savedSuccessfully"),
                      status: "success",
                    });
                  });
                  vm.fetchRelationDetails();
                }
                vm.calcSummaryData()
              }).catch(error=>{
                vm.$toast.error(this.$t("errors.save"))
                this.loading = false
              }).finally(()=>{
                this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                  key: "plus_sheet_mani",
                  data: {
                    module_name: this.form.label,
                    func_name: this.$sensors.events.plus_sheet_mani.table_view.UPDATE_RECORD_INLINE
                  }
                });
              });
            }
  
          });
        } else {
          xTable.clearActived();
        }
      },

      onCommandHandler(payload) {
        const vm = this;
        switch (payload.command) {
          case 'showChildTableDrawer':
            vm.$emit('onCommand', {
              ...payload,
            })
            break
          case 'selectRelatedCards':
            vm.selectRelatedCards(payload)
            break
          case 'selectRelatedRecords':
            vm.selectRelatedRecords(payload)
            break
          case 'gotoRelatedRecord':
            vm.gotoRelatedRecord(payload.recordId, payload.fieldId, payload.relatedRecordId)
            break
          case 'gotoRelatedRecordFromOtherTableField':
            vm.gotoRelatedRecordFromOtherTableField(payload.recordId, payload.fieldId)
            break
          case "updateFieldValue":
            vm.updateFieldValue(
              payload.rowId,
              payload.fieldId,
              payload.fieldValue
            );
            break;
          case "selectUploadFile": {
            const xTable = vm.$refs.xTable;
            if (payload.opts.types.length == 0) payload.opts.types = null;
            xTable.readFile(payload.opts).then((params) => {
              const { files } = params
              const fileObjs = Array.from(files);
  
              if (typeof payload.callback === "function") {
                payload.callback(fileObjs);
              }
            });
          }
  
            break;
          case "showEmployeesDialog":
            vm.$refs.tableEesDialog.addButton = payload.addButton
            vm.$refs.tableEesDialog.multiple = payload.multiple
            vm.$refs.tableEesDialog.selectedValue = payload.selectedValue
            vm.$refs.tableEesDialog.callback = payload.onSubmit
            vm.$refs.tableEesDialog.row = payload.row
            // console.log("payload.row", payload.row)
            this.showEesDialog = true
            // console.log(vm.$refs.tableEesDialog)
            break;
          case "showMembersMenu":
            var attachedEle = payload.attachedEle
            var fieldInfo = payload.fieldInfo
            vm.membersMenuPayload = payload
            vm.showingMembersMenu = true
            vm.$nextTick(()=>{
              vm.$refs.membersMenu.type= fieldInfo.properties.fieldType
              vm.$refs.membersMenu.selectedValue= vm.recordData[fieldInfo.fieldId]
              vm.$refs.membersMenu.fieldId= fieldInfo.fieldId
              attachedEle.id = ""
            })
          break
          case 'newRelatedRecord':
            vm.newRelatedRecord(payload)
            break
          case 'refreshRelationData':
            vm.refreshRelationData(payload)
            break
          case 'refreshData':
            console.log( 'TABLE VIEW - REFRESH DATA' )
            this.refresh();
            break
        }
      },
      updateFieldValue(rowId, fieldId, value) {
        const vm = this;
        for (let i = 0; i < vm.tableData.length; i++) {
          const loopRow = vm.tableData[i];
          if (loopRow._id === rowId) {
            vm.tableData[i][fieldId] = value;
            vm.updateRow(vm.tableData[i]);
            break;
          }
        }
      },
  
      updateRow(row) {
        const vm = this;
        vm.$store.dispatch("SAVE_DATA", row).then(() => {
          vm.fetchRelationDetails();
        }).catch(error=>{
          this.$toast.error(this.$t("errors.update"))
        });
      },
  
      editRow(row) {
        const vm = this;
        vm.$emit("onCommand", {
          command: "editRow",
          record: row,
        });
      },
      deleteRow(row) {
        const vm = this;
        if (vm.isQuickInsertMode) {
          vm.deleteInQuickInsertMode(row);
        } else {
          vm.deleteFromDb(row);
        }
      },
      deleteInQuickInsertMode(row) {
        const vm = this;
        const rowIndex = vm.tableData.findIndex(
          (data) => data._id === row._id
        );
        // console.log("deleteInQuickInsertMode :: row._id = " + row._id);
        // console.log("deleteInQuickInsertMode :: rowIndex = " + rowIndex);
  
        if (rowIndex >= 0) {
          vm.tableData.splice(rowIndex, 1);
        }
      },
      deleteFromDb(row) {
        const vm = this;
        vm.$refs.confirmDeleteDialog.confirm(() => {
          vm.$store
            .dispatch("DELETE_FORM_RECORD", {
              appId: vm.currentApp._id,
              formId: this.form._id,
              include: [row._id],
            })
            .then(() => {
              this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: "plus_sheet_mani",
                data: {
                  module_name: this.form.label,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.DELETE_RECORD
                }
              });
              vm.refresh();
            });
        });
      },
      copyRow(row, rowIndex) {
        const vm = this;
        if (vm.isQuickInsertMode) {
          vm.createRows(1, ADD_BELOW, rowIndex, () => {
            const tableData = vm.tableData;
            // console.log("form.copyRow :: .... rowIndex = " + rowIndex);
            for (let key in row) {
              if (key !== "_id") {
                tableData[rowIndex + 1][key] = row[key];
              }
            }
          });
        } else {
          const postData = {
            urlCommand: "/data/duplicateRecord",
            data: {
              recordId: row._id,
              formId: this.form._id,
              viewId: this.view._id,
            },
          };
          this.$store.dispatch("AUTH_POST", postData).then((res) => {
            if (res.copied) {
              this.$toast.success(this.$t('messages.recordCopied'));
              this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: "plus_sheet_mani",
                data: {
                  module_name: this.form.label,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.COPY_RECORD
                }
              });
              this.tableData.splice(rowIndex + 1, 0, res.data);
  
              this.$refs.xTable.tablePage.total++;
              this.$refs.xTable.setCurrentRow(res.data);
              this.$refs.xTable.setActiveRow(res.data);
              if (this.isAllRecords){
                this.$refs.xTable.setCheckboxRow(res.data, true)
              }
            } else if (res.problematicField) {
              this.$toast.error(this.$t("messages.noDupFieldExist"), {
                duration: 0,
              });
            } else {
              this.$toast.error(this.$t("messages.cannotCopy"), {
                duration: 0,
              });
            }
          });
        }
      },
      clearSelection() {
        this.includeRecords = [];
        this.excludeRecords = [];
        this.isAllRecords = false;
      },
  
      onCellClick({ row, column }) {
        const vm = this;
        vm.selectRow = row;
        vm.selectColumn = column;
      },
  
      refresh() {
        const vm = this;
        console.log("TableView.refresh :: vm.isQuickInsertMode = " + vm.isQuickInsertMode);
        if (!this.isQuickInsertMode) this.$refs.xTable.commitProxy("query");
      },
  
      getFieldInfos(fieldIds) {
        const vm = this;
        var result = [];
        for (var i = 0; i < fieldIds.length; i++) {
          const loopFieldId = fieldIds[i];
          const fieldInfo = vm.fieldInfos.find(
            (info) => info.fieldId === loopFieldId
          );
          if (fieldInfo) {
            result.push(fieldInfo);
          }
        }
        return result;
      },
      async setActiveRow(row) {
        var scrollObj = this.$refs.xTable.getScroll();
        await this.$refs.xTable.setActiveRow(row);
        this.$refs.xTable.scrollTo(scrollObj.scrollLeft, scrollObj.scrollTop);
        this.$refs.xTable.refreshScroll();
      },
  
      async fetchRegions() {
        const vm = this;
        var locale = this.$store.getters.user.locale;
        const getParams = {
          urlCommand: "/widgets/getRegions/" + locale,
        };
        await vm.$store
          .dispatch("AUTH_GET", getParams)
          .then((response) => {
            console.log(response);
            this.regions = response.options;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handleMemberClick(val){
        var type = this.membersMenuPayload.fieldInfo.properties.fieldType
        var fieldId = this.membersMenuPayload.fieldInfo.fieldId
        var recordData = this.membersMenuPayload.row
        if(!recordData[fieldId]){
          this.$set(recordData, fieldId, [val])
        }else{
          if(type=='single'){
            recordData[fieldId] = [val]
          }else{
            var pos = recordData[fieldId].findIndex(ee=>ee._id==val._id)
            if(pos==-1){
              recordData[fieldId].push(val)
            }else{
              recordData[fieldId].splice(pos,1)
            } 
          }
        }
      },
    createMenu(menuItemCodeList) {
        const vm = this;
        let result = [];
        for (let i = 0; i < menuItemCodeList.length; i++) {
            const loopCode = menuItemCodeList[i];
            const MENU = MENUS.find((m) => m.code === loopCode);
            if (MENU) {
            let menu = Object.assign(
                {
                name: vm.$t(MENU.nameTag),
                },
                MENU
            );
            delete menu.nameTag;
            result.push(menu);
            }
        }
        // console.log('createeMeu :: result: ', result)

        return result;
    },
    contextMenuClickEvent({ menu, row, rowIndex }) {
        let xTable = this.$refs.xTable;
        let tableData = this.tableData
        // let tableData = xTable.tableData
        switch (menu.code) {
            case "customizedAction": 
            this.executeGlobalActionToCustomizedButton(menu.templateId, row);
            break;
            case "copyRow":
            this.copyRow(row, rowIndex);
            break;
            case "deleteRow":
            this.deleteRow(row);
            break;
            case "moveToTop":
            this.moveToTop(tableData, rowIndex);
            break;
            case "moveToBottom":
            this.moveToBottom(tableData, rowIndex);
            break;
            case "moveUp":
            this.moveUp(tableData, rowIndex);
            break;
            case "moveDown":
            this.moveDown(tableData, rowIndex);
            break;
            case "insertOneRowAbove":
            this.createRows(1, INSERT_ABOVE, rowIndex);
            break;
            case "insertFiveRowsAbove":
            this.createRows(5, INSERT_ABOVE, rowIndex);
            break;
            case "insertTenRowsAbove":
            this.createRows(10, INSERT_ABOVE, rowIndex);
            break;
            case "addOneRowBelow":
            this.createRows(1, ADD_BELOW, rowIndex);
            break;
            case "resetAllRows":
            this.resetAllRows(xTable, tableData);
            break;
            case "resetCurrentRow":
            this.resetCurrentRow(xTable, rowIndex);
            break;
            case "addFiveRowsBelow":
            this.createRows(5, ADD_BELOW, rowIndex);
            break;
            case "addTenRowsBelow":
            this.createRows(10, ADD_BELOW, rowIndex);
            break;
        }
    },

    },
  };
  </script>
  
  <style>
    .popModal.col--actived {
      z-index: 4 !important;
    }
    .vxe-modal--wrapper{
      z-index: 999 !important
    }
    .batchInserttableViewGrid .vxe-table--render-default .vxe-header--column.col--ellipsis{
      height:23px
    }
  </style>
  
