var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"workflow-table-record-main-table"},[_c('vue-good-table',{attrs:{"columns":_vm.headers,"rows":_vm.list,"fixed-header":true,"styleClass":"vgt-table","sort-options":{
      enabled: false
    },"group-options":{
      enabled: true
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('div',[_c('div',[_c('v-layout',{staticClass:"record name link link-space",attrs:{"align-center":""},on:{"click":function($event){return _vm.goRow(props.row)}}},[_c('span',{staticClass:"table-icon",style:(_vm.iconStyle(props.row))},[_c('v-icon',{attrs:{"color":"white","meidum":""}},[_vm._v(_vm._s(props.row.icon || 'mdi-sitemap'))])],1),_c('div',[_vm._v(" "+_vm._s(props.row.name || _vm.$t('workflow.unknown'))+" "),(props.row.remark)?_c('div',{staticClass:"table-remark-desc"},[_vm._v(" "+_vm._s(props.row.remark)+" ")]):_vm._e()])])],1)]):(props.column.field == 'type')?_c('div',[(!_vm.unClassified(props.row.targetFormId))?_c('div',{staticClass:"record link",on:{"click":function($event){return _vm.goDetail(props.row)}}},[(!!props.row.parentWorkflow && props.row.type === 'childProcess')?_c('span',[_c('span',{style:({
                color: _vm.$vuetify.theme.themes.light.primary,
                marginRight: '5px',
              })},[_vm._v(" "+_vm._s(_vm.$t("workflow.trigger.parentWorkflow"))+" ")]),_vm._v(" "+_vm._s(props.row.parentWorkflow && props.row.parentWorkflow.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t(("workflow.trigger." + (props.row.type))))+" ")])]):_c('div',{staticClass:"record"},[_vm._v(" ---- ")])]):(props.column.field == 'creator')?_c('v-layout',{attrs:{"justify-space-between":""}},[_c('AvatarWithPopover',{attrs:{"user":props.row.user}}),_c('RowControl',{attrs:{"item":props.row,"notReady":_vm.unClassified(props.row.targetFormId),"index":props.index,"type":_vm.type}})],1):(props.column.field == 'status')?_c('div',[_c('div',{staticClass:"record"},[_c('toggle-button',{attrs:{"value":_vm.isEnableWorkflow(props.row),"color":{
              checked: _vm.switchStyle(props.row), 
              unchecked: '#CCCCCC', 
              disabled: '#666666',
            },"disabled":_vm.unClassified(props.row.targetFormId),"width":"120","height":"35","labels":{
              checked: _vm.$t('workflow.btn.released'), 
              unchecked: _vm.$t('workflow.btn.unRelease')
            }},on:{"change":function (v) { return _vm.onSwitchMode(v, props.row); }}}),(!props.row.released && !_vm.unClassified(props.row.targetFormId))?_c('span',{staticClass:"workflow-table-record-main-table-remark"},[_vm._v(" "+_vm._s(_vm.$t(("workflow.message.rolloutNot." + (props.row.status)), { date: _vm.date(props.row), }))+" ")]):_vm._e(),(!!props.row.beingReleased && !_vm.unClassified(props.row.targetFormId))?_c('span',{staticClass:"workflow-table-record-main-table-warning",domProps:{"innerHTML":_vm._s(
              _vm.$t("workflow.message.beingReleased", {
                date: _vm.lastSettingAt(props.row),
              })
            )}}):(!!props.row.released)?_c('span',{staticClass:"workflow-table-record-main-table-remark",style:({ color: _vm.$vuetify.theme.themes.light.primary, fontStyle: 'italic' }),domProps:{"innerHTML":_vm._s(_vm.$t("workflow.message.rollout.lastUpdate", {
              date: _vm.lastSettingAt(props.row),
            }))}}):_vm._e()],1)]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"table-header-row",fn:function(props){return [_c('div',{staticClass:"workflow-table-list-header-clip"},[(_vm.unClassified(props.row._id))?_c('v-layout',{staticClass:"workflow-table-list-header-noClassified",attrs:{"align-center":""}},[_vm._v(" "+_vm._s(_vm.$t('workflow.label.unClassified'))+" ")]):(_vm.isClassified(props.row._id, props.row))?_c('v-layout',{staticClass:"workflow-table-list-header-classified",attrs:{"align-center":""}},[_c('v-icon',{attrs:{"meidum":""}},[_vm._v(_vm._s(_vm.classifiedIcon(props.row)))]),_c('span',[_vm._v(" "+_vm._s(_vm.classifiedLabel(props.row, props.column))+" ")])],1):_vm._e()],1)]}}])}),_c('ReleaseDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }