export default {
  DISPLAY_TABLE_SETTING: {
    icon: 'mdi-table-settings',
    labelTag: 'view.tableSetting',
    componentName: 'viewSettingsTableGeneralSetting',
    refName: 'viewSettingsTableGeneralSetting'
  },
  TABLE_DISPLAY_FIELDS: {
    icon: 'mdi-focus-field-horizontal',
    labelTag: 'view.fieldDisplay',
    componentName: 'view-settings-table-display-fields',
    refName: 'viewSettingsTableDisplayFields'
  },
  PARENT_CHILD_SETTINGS: {
    icon: 'mdi-stairs',
    labelTag: 'view.levelSettings',
    componentName: 'view-settings-parent-child-settings',
    refName: 'viewSettingsParentChildSettings'
  },
  PANEL_SETTINGS: {
    icon: 'mdi-view-dashboard-variant-outline',
    labelTag: 'view.panelSettings',
    componentName: 'view-settings-panel-settings',
    refName: 'viewSettingsPanelSettings'
  },
  PANEL_DISPLAY_FIELDS: {
    icon: 'mdi-focus-field-horizontal',
    labelTag: 'view.fieldDisplay',
    componentName: 'view-settings-panel-display-fields',
    refName: 'viewSettingsPanelDisplayFields'
  },
  FILTERS: {
    icon: 'mdi-filter',
    labelTag: 'view.filter',
    componentName: 'view-settings-filters',
    refName: 'viewSettingsFilters'
  },
  QUICK_FILTERS: {
    icon: 'mdi-filter',
    labelTag: 'view.quickFilter',
    componentName: 'view-settings-filters',
    refName: 'viewSettingsQuickFilters',
    settingNames: ['quickFilters','quickFilterAndOr'],
    noValidation: true 
    // notes: 
    // no value required to sepcify or as default value for quck-filter
    // filter withy empty argument with be ignored.
  },
  SORTING: {
    icon: 'mdi-order-alphabetical-ascending',
    labelTag: 'view.sorting',
    componentName: 'viewSettingsSorting',
    refName: 'viewSettingsSorting'
  },
  CUSTOMIZEDACTION: {
    icon: 'mdi-gesture-tap-button',
    labelTag: 'view.customizedAction',
    componentName: 'viewSettingCustomizedAction',
    suffix: 'buttonCounter',
    refName: 'viewSettingCustomizedAction'
  },
  // SORTING: {
  //   icon: 'mdi-order-alphabetical-ascending',
  //   labelTag: 'view.sorting',
  //   componentName: 'view-settings-sorting',
  //   refName: 'viewSettingsSorting'
  // },
  CUSTOM_BUTTONS: {
    icon: 'mdi-gesture-tap',
    labelTag: 'view.customButtons',
    componentName: 'view-settings-custom-buttons',
    refName: 'viewSettingsCustomButtons'
  }
}
