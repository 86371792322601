import { data as types } from "../../mutation-types";
import defaultState from './state'
export default {
    [types.SET_RECORD_DATA](state, payload){
        state.recordData = payload
    },
    [types.SET_RELATED_TABLE_DATA](state, payload) {
        // console.log('commit(SET_RELATED_TABLE_DATA):: payload: ', payload)
        state.relatedTableData = payload
    },
    [types.UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID](state, payload){
        const fieldId = payload.fieldId
        const data = payload.data

        state.relatedTableData[fieldId] = data

        // console.log('commit(UPDATE_RELATED_TABLE_DATA_BY_FIELD_ID) ' +
        //     '(fieldId=' + fieldId + '): state.relatedTableData: ', 
        //     state.relatedTableData)
    },
    [types.RESET_DATA](state){
        Object.assign(state, defaultState)
    }
    // ,
    // [types.SET_DATA_FIELD_VALUE] (state, payload) {
    //
    //     if (state.recordData._id === payload._id) {
    //       console.log('SET_DATA-FIELD_VALUE correct id')
    //       state.recordData[payload.fieldName] = paylaod.fieldValue
    //     } else {
    //       console.log('SET_DATA-FIELD_VALUE incorrect id')
    //     }
    // }

}
