import ValidationsHelper from "@/helpers/ValidationsHelper";

const validid = require('validid')
export default {
  data() {
    return {
      hkidValid: ({cellValue}) => {
        // RULE_HKID: (v) => !v.trim() || validid.default.hkid(v) || this.$t("messages.invalidHkId"),
        // RULE_CNID: (v) => !v.trim() || validid.default.cnid(v) || this.$t("messages.invalidCnId"),
        // RULE_TWID: (v) => !v.trim() || validid.default.twid(v) || this.$t("messages.invalidTwId"),
        // RULE_PASSPORT: (v) => !v.trim() || v.trim().length<=20 || this.$t("messages.invalidPassport"),
        if (cellValue && !validid.default.hkid(cellValue)) {
          return new Error(this.$t("messages.invalidHkId"));
        }
      },
      cnidValid: ({ cellValue }) => {
        if (cellValue && !validid.default.cnid(cellValue)) {
          return new Error(this.$t("messages.invalidCnId"));
        }
      },
      twidValid: ({ cellValue }) => {
        if (cellValue && !validid.default.twid(cellValue)) {
          return new Error(this.$t("messages.invalidTwId"));
        }
      },
      passportValid: ({ cellValue }) => {
        if (cellValue && cellValue.trim().length>20) {
          return new Error(this.$t("messages.invalidPassport"));
        }
      },
      emailValid: ({ cellValue }) => {
        if (cellValue && !ValidationsHelper.isValidEmail(cellValue)) {
          return new Error(this.$t("messages.invalidEmail"));
        }
      },
      idCardValid: ({cellValue,column}) => {
        var fieldId = column.property
        var idCardType = this.fieldInfos.find(item=> item.fieldId == fieldId).properties.idCardType
        if (cellValue && !ValidationsHelper.isValidIdCard(cellValue, idCardType)){
          return new Error(this.$t("messages.invalidIdCard"))
        }
      },
      phoneValid: ({cellValue}) => {
        if (cellValue && !ValidationsHelper.isValidPhone(cellValue)){
          return new Error(this.$t("messages.invalidPhone"))
        }
      },
      dateValid: ({ cellValue }) => {
        if (cellValue && !ValidationsHelper.isValidDate(cellValue)) {
          return new Error(this.$t("messages.invalidDate"));
        }
      },
      timeValid: ({ cellValue }) => {
        if (cellValue && !ValidationsHelper.isValidTimeString(cellValue)) {
          return new Error(this.$t("messages.invalidTime"));
        }
      },
      dateTimeValid: ({ cellValue }) => {
        if (cellValue && !ValidationsHelper.isValidDateTimeString(cellValue)) {
          return new Error(this.$t("messages.invalidDateTime"));
        }
      },
      timeRangeValid: ({ cellValue, rule }) => {
        let testValue = cellValue;
        if (ValidationsHelper.isValidDateTimeString(testValue))
          testValue = cellValue.split(" ")[1];

        if (
          testValue &&
          !ValidationsHelper.isInTimeRange(
            testValue,
            rule.minTime,
            rule.maxTime
          )
        ) {
          if (rule.minTime && rule.maxTime)
            return new Error(
              this.$t("messages.mustBeWithinTime", {
                min: rule.minTime,
                max: rule.maxTime,
              })
            );
          else if (rule.minTime) {
            return new Error(
              this.$t("messages.mustBeLater", { min: rule.minTime })
            );
          } else {
            return new Error(
              this.$t("messages.mustBeEarlier", { max: rule.maxTime })
            );
          }
        }
      },
      dayValid: ({ cellValue, rule }) => {
        if (
          cellValue &&
          !ValidationsHelper.isAllowedDay(cellValue, rule.allowedDays)
        ) {
          return new Error(this.$t("messages.dayNotAllowed"));
        }
      },
      attachmentCountValid: ({cellValue, rule}) => {
        if (cellValue && Array.isArray(cellValue)) {
          const count = cellValue.length
       
          if (!ValidationsHelper.isInNumberRange(
            count,
            rule.minCount,
            rule.maxCount
          )) {
            if (rule.minCount && rule.maxCount)
              return new Error(
                this.$t('messages.attachmentCountMustBeInRange', {
                  min: rule.minCount,
                  max: rule.maxCount
                })
              );
            else if (rule.minCount)
              return new Error(
                this.$t('messages.attachmentCountAtLeast', {min: rule.minCount})
              )
            else {
              return new Error(
                this.$t('messages.attachmentCountAtMost', {max: rule.maxCount})
              )
            }
          } else {
            console.log('attachmentCountValid ValidationHelper.isInNumberRange - valid')
          }
        }
      },
      numRangeValid: ({ cellValue, rule }) => {
        if (
          cellValue &&
          !ValidationsHelper.isInNumberRange(
            cellValue,
            rule.minValue,
            rule.maxValue
          )
        ) {
          if (rule.minValue && rule.maxValue)
            return new Error(
              this.$t("messages.outOfRange", {
                min: rule.minValue,
                max: rule.maxValue,
              })
            );
          else if (rule.minValue)
            return new Error(
              this.$t("messages.mustBeAtLeast", { min: rule.minValue })
            );
          else {
            return new Error(
              this.$t("messages.mustNotExceed", { max: rule.maxValue })
            );
          }
        }
      },
      uniqueValid: ({cellValue, column, row}) => {
        return new Promise((resolve, reject) => {
          if (!cellValue) resolve()
          const formId =this.form._id;
          const postData = {
            urlCommand:'/data/checkDuplicate',
            data: {
              formId,
              recordId: row._id,
              field : {
                id: column['property'],
                value: cellValue
              }
            }
          }
          this.$store.dispatch('AUTH_POST', postData).then(res => {
            if (res === true || res.result === true){
              reject(new Error(this.$t('messages.hasDuplicate')))
            } else {
              resolve()
            }
          })
        })
      },
      requiredValid: ({cellValue}) => {
        // console.log({type: typeof cellValue, value: cellValue})
        // if (typeof cellValue == 'number')
        //   cellValue = cellValue.toString()
        if (ValidationsHelper.isEmpty(cellValue)){
          return new Error(this.$t("messages.thisFieldIsRequired"))
        }
      },
      isProvinceEmpty: ({cellValue}) => {
        if(ValidationsHelper.isProvinceEmpty(cellValue)){
          return new Error(this.$t("messages.thisFieldIsRequired"))
        }
      },
      isCityEmpty: ({cellValue}) => {
        if(ValidationsHelper.isCityEmpty(cellValue)){
          return new Error(this.$t("messages.thisFieldIsRequired"))
        }
      },
      isStateEmpty: ({cellValue}) => {
        if(ValidationsHelper.isStateEmpty(cellValue)){
          return new Error(this.$t("messages.thisFieldIsRequired"))
        }
      }
    }
  },
  computed: {
    validRules() {
      // console.log('validRule', this.fieldInfos)
      let result = {};
      this.fieldInfos.forEach((fieldInfo) => {
        let rules = [];

        let validationString;
        //default format validation
        switch (fieldInfo.type) {
          case "attachments":
            var maxStr = fieldInfo.properties.maxAttachmentCount
            var minStr = fieldInfo.properties.minAttachmentCount
            if (minStr !== '' || maxStr !== '') {
              rules.push({
                validator: this.attachmentCountValid,
                minCount: minStr === '' ? null : parseInt(minStr),
                maxCount: maxStr === '' ? null : parseInt(maxStr)
              })
            }
            break
          case "idCard":
            var idCardType = fieldInfo.properties.idCardType
            rules.push({
              validator: this.idCardValid
            })
            // switch(type){
            //     case 'hkId':
            //       rules.push({validator: this.hkidValid});
            //       break
            //     case 'passport':
            //       rules.push({validator: this.passportValid});
            //       break
            //     case 'cnId':
            //       rules.push({validator: this.cnidValid});
            //       break
            //     case 'twId':
            //       rules.push({validator: this.twidValid});
            //       break
            //   }
            //   console.log(rules)
            break;
          case "email":
            rules.push({
              validator: this.emailValid,
            });
            break;
          case "phone": {
            rules.push({
              validator: this.phoneValid
            })
            break;
          }
          case "date": {
            const fieldType = fieldInfo.properties.fieldType;
            switch (fieldType) {
              case "date":
                rules.push({
                  validator: this.dateValid,
                });
                validationString = fieldInfo.properties.validation_date
                break;
              case "time":
                rules.push({
                  validator: this.timeValid,
                });
                validationString = fieldInfo.properties.validation_time
                break;
              case "datetime": {
                rules.push({
                  validator: this.dateTimeValid,
                });
                validationString = fieldInfo.properties.validation_datetime
                break;
              }
            }
            break;
          }
        }
        if(validationString===undefined){
          validationString = fieldInfo.properties.validation;
        }
        if (validationString) {
          const validations = validationString.split("||");
          validations.forEach((validation) => {
            switch (validation) {
              case "required":
                if (fieldInfo.type === 'rating'){
                  rules.push({
                    min: 1,
                    type:'number',
                    message: this.$t("messages.thisFieldIsRequired"),
                  });
                } else if(fieldInfo.type === 'region'){
                  if(fieldInfo.properties.regionType==="province"){
                    rules.push({
                      validator: this.isProvinceEmpty,
                      message: this.$t("messages.thisFieldIsRequired"),
                    })
                  }else if(fieldInfo.properties.regionType==="provinceCity"){
                    rules.push({
                      validator: this.isCityEmpty,
                      message: this.$t("messages.thisFieldIsRequired"),
                    })
                  }else if(fieldInfo.properties.regionType==="provinceCityState"){
                    rules.push({
                      validator: this.isStateEmpty,
                      message: this.$t("messages.thisFieldIsRequired"),
                    })
                  }
                }else{
                  rules.push({
                    validator: this.requiredValid,
                    message: this.$t("messages.thisFieldIsRequired"),
                  });
                }
                break;
              case "timeRange":
                rules.push({
                  validator: this.timeRangeValid,
                  minTime: fieldInfo.properties.validation_minTime,
                  maxTime: fieldInfo.properties.validation_maxTime,
                });
                break;
              case "limitWeekday":
                rules.push({
                  validator: this.dayValid,
                  allowedDays: fieldInfo.properties.validation_weekday.split(
                    "||"
                  ),
                });
                break;
              case "withinRange":
                rules.push({
                  validator: this.numRangeValid,
                  minValue: fieldInfo.properties.minValue,
                  maxValue: fieldInfo.properties.maxValue,
                });
                break;
              case 'noDuplication':
                rules.push({
                  validator: this.uniqueValid,
                  trigger: 'blur'
                })
                break;
            }
          });
        }
        if (rules.length > 0)
          Object.assign(result, {
            [fieldInfo.fieldId]: rules,
          });
      });
      // console.log('validRules: ', result)
      return result;
    },
  },
};
