<template>
    <div class="w-100 font-weight-bold info rounded-pill white--text">
        <slot>
            <div class="d-flex py-1 pl-5 pr-3 align-center mx-0 mt-1">
                <span v-if="localeTypes.includes(field.type)">{{$t('chart.'+field.type)}}</span>
                <span v-else>{{field.label}}</span>
                <v-icon 
                    @click="$emit('remove')"
                    class="ml-auto" 
                    color="white" 
                    size="18"
                >
                    mdi-close
                </v-icon>
            </div>
        </slot>
    </div>
</template>

<script>
    export default {
        props:{
            field: Object
        },
        data(){
            return{
                localeTypes: ['recordCount', 'owner', 'createdBy', 'createdAt', 'updatedAt'],
            }
        }
    }
</script>