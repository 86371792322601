<template>

  <component
    :is="getInputTypeName(propertyConfig)"
    :key="`${property.propertyKey}_${fieldInfoId}`"
    :parentLabel="parentLabel"
    :label="label"
    :propertyConfig="propertyConfig"
    :propertyKey="property.propertyKey"
    :propertyValues="values"
    :propertyDefault="property.default"
    :fieldInfoId="fieldInfoId"
    :fieldType="fieldType"
    :value="propertyValue"
    :masterFieldInfos="masterFieldInfos"
    :currentFieldInfos="currentFieldInfos"
    @onCommand="onCommandHandler"
    @input="val=>updateValue(val)"
    :ofChildTable="ofChildTable"
  >
  </component>

   <!--<component :is="getInputTypeName(propertyConfig)"-->
              <!--:label="label"-->
              <!--:fieldInfoId="fieldInfoId"-->
              <!--:propertyConfig="propertyConfig"-->
              <!--:propertyKey="property.propertyKey"-->
              <!--:values="values"-->
              <!--:value="propertyValue"-->
              <!--@onCommand="onCommandHandler"-->
              <!--@input="val=>updateValue(val)">-->
   <!--</component>-->
</template>

<script>
import widgetInputTypes from '../widgetInputTypes'
import helpers from '@/helpers'

export default {
  components: {
    ...widgetInputTypes
  },
  props: {
    fieldInfoId: {
      type: String,
      default: ''
    },
    fieldType: {
      type: String,
      default: ''
    },
    parentLabel: {
      type: String,
      default: ''
    },
    property: {
      type: Object,
      default: null
    },
    values: {
      type: Object,
      default: null
    },
    ofChildTable: Boolean,
    childTableFieldInfoId: String,
    masterFieldInfos: {
      type: Array,
      default () {
        return []
      }
    },
    currentFieldInfos: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    label () {
      const vm = this
      var result = vm.propertyConfig.label ? vm.propertyConfig.label : ''
      if (vm.propertyConfig.labelTag) {
        result = vm.$t('widgets.properties.' + vm.propertyConfig.labelTag)
      }
      return result
    },
    // label () {
    //   const vm = this
    //   var result = vm.property.label ? vm.property.label : ''
    //   if (vm.property.labelTag) {
    //     result = vm.$t('widgets.properties.' + vm.property.labelTag)
    //   }
    //   return result
    // },
    propertyValue () {
      const vm = this
      var result = ''
      if (vm.values && vm.values.hasOwnProperty(vm.property.propertyKey)) {
        result = vm.values[vm.property.propertyKey]
      }
      // if (vm.values && vm.values[vm.property.propertyKey] && vm.values[vm.property.propertyKey] !== '') {
      //   result = vm.values[vm.property.propertyKey]
      // } else {
      //   if (vm.property.default && vm.property.default !== '') {
      //     result = vm.property.default
      //   }
      // }
      return result
    },
    propertyConfig () {
      const vm = this
      var result = {
        order: 0,
        key: '',
        labelTag: '',
        inputType: 'text',
        noteTag: '',
        default: '',
        attributes: [],
        options: [],
        otherOptions: [],
        customOptions: [],
        showIf: [],
        noteTag: ''
      }

      // update from widgetProperties table
      // console.log(vm.defaultWidgetProperty)
      if (vm.defaultWidgetProperty) {
        // result['order'] = vm.defaultWidgetProperty.order
        result['key'] = vm.defaultWidgetProperty.key
        result['labelTag'] = vm.defaultWidgetProperty.labelTag
        result['noteTag'] = vm.defaultWidgetProperty.noteTag
        result['default'] = vm.defaultWidgetProperty.default ?
          vm.defaultWidgetProperty.default : ''
        result['inputType'] = vm.defaultWidgetProperty.inputType
        result['attributes'] = vm.toAttributeObj(vm.defaultWidgetProperty.attributes)
        result['options'] = vm.defaultWidgetProperty.options
        result['otherOptions'] = vm.defaultWidgetProperty.otherOptions ?
          vm.defaultWidgetProperty.otherOptions : []
        result['customOptions'] = vm.defaultWidgetProperty.customOptions ?
          vm.defaultWidgetProperty.customOptions : []
        result['showIf'] = vm.defaultWidgetProperty.showIf ?
          vm.defaultWidgetProperty.showIf : []
        result['noteTag'] = vm.defaultWidgetProperty.noteTag ?
          vm.defaultWidgetProperty.noteTag : ''
      }
      // console.log('GenericWidgetProperty :: propertyConfig :: key = ' + result['key'])
      // console.log('GenericWidgetProperty :: propertyConfig :: defaultWidgetProperty: ', vm.defaultWidgetProperty)
      // console.log('GenericWidgetProperty :: propertyConfig :: result: ', result)

      // update from widget table
      if (vm.property) {
        if (vm.property.labelTag) result['labelTag'] = vm.property.labelTag
        if (vm.property.options && vm.property.options.length > 0) result['options'] = vm.property.options
        if (vm.property.default) result['default'] = vm.property.default
        if (vm.property.attributes && vm.property.attributes.length > 0) {
          const propertyAttrs = vm.toAttributeObj(vm.property.attributes)
          Object.assign(result['attributes'], propertyAttrs)
        }
        if (vm.property.otherOptions) result['otherOptions'] = vm.property.otherOptions
        if (vm.property.customOptions) result['customOptions'] = vm.property.customOptions
        if (vm.property.showIf) result['showIf'] = vm.property.showIf
        if (vm.property.noteTag) result['noteTag'] = vm.property.noteTag
        if (vm.property.inputType) result['inputType'] = vm.property.inputType
      }

      // console.log('GenericWidgetProperty :: propertyConfig :: checking vm.property: ', vm.property)
      // console.log('GenericWidgetProperty :: propertyConfig :: result: ', result)

      // switch (vm.property.key) {
      //   case 'selectionSingle':
      //     if (vm.property.default)
      // }
      return result
    },

    defaultWidgetProperty () {
      const vm = this
      var result = null
      for (var i = 0; i < vm.$store.getters.widgetProperties.length; i++) {
        var loopProperty = vm.$store.getters.widgetProperties[i]
        if (loopProperty.key === vm.property.propertyKey) {
          result = loopProperty
          break
        }
      }
      // console.log(result)
      return result
    },

    formFieldInfos () {
      return this.$store.getters.formFieldInfos
    }


  },
  methods: {
    toAttributeObj (arAttributes) {
      const vm = this
      var result = {}
      for (var i = 0; i < arAttributes.length; i++) {
        const loopAttr = arAttributes[i]
        result[loopAttr.key] = loopAttr.value
      }
      return result
    },

    getWidgetByInputType (inputType) {
      // console.log('getWidgetByInputType :: inputType = ' + inputType)
      switch (inputType) {
        // case 'string':
        //   return 'widgetInputTypeString'
        // case 'integer':
        //   return 'widgetInputTypeInteger'
        // case 'fields-editor':
        //   return 'widgetInputTypeFieldsEditor'
        // case 'formatted-text':
        //   return 'widgetInputTypeFormattedText'
        // case 'selection-single':
        //   return 'widgetInputTypeSelectionSingle'
        // case 'selection-multiple':
        //   return 'widgetInputTypeSelectionMultiple'
        case 'copy-related-record-fields':
          return 'witRelatedRecordCopyFields'
        case 'input-options-single':
          return 'witInputOptions'
        case 'input-options-multiple':
          return 'witInputOptions'
        case 'table-selection-setup':
        case 'card-selection-setup':
          return 'witRelatedSelectionSetup'
        // case 'field-selection':
        //   return 'widgetInputTypeFieldSelection'
        // case 'table-selection':
        //   return 'widgetInputTypeTableSelection'
        // case 'data-source':
        //   return 'widgetInputTypeDataSource'
        // case 'related-record':
        //   return 'widgetInputTypeRelatedRecord'
        // case 'display-fields':
        //   return 'widgetInputTypeDisplayFields'
        // case 'related-table-selection':
        //   return 'widgetInputTypeRelatedTableSelection'
        // case 'related-table-fields-selection':
        //   return 'widgetInputTypeRelatedTableFieldsSelection'
        // case 'function-selection':
        //   return 'widgetInputTypeFunctionSelection'
        // case 'other-field-criteria':
        //   return 'widgetInputTypeOtherFieldCriteria'
        // case 'default-member':
        //   return 'widgetInputTypeDefaultMember'
        // case 'select-field-combination':
        //   return 'widgetInputTypeSelectFieldCombination'
        // case 'related-record-display-field':
        //   return 'widgetInputTypeRelatedRecordDisplayField'
        // case 'related-record-avatar-field':
        //   return 'widgetInputTypeRelatedRecordAvatarField'
        // case 'related-record-view':
        //   return 'widgetInputTypeRelatedRecordView'
        // case 'copy-related-record-fields':
        //   return 'widgetInputTypeCopyRelatedRecordFields'
        // case 'width-adjustment':
        //   return 'widgetInputTypeWidthAdjustment'
        // case 'text':
        //   return 'widgetInputTypeText'
        // case 'date-dialog':
        //   return 'widgetInputTypeDateDialog'
        // case 'time-dialog':
        //   return 'widgetInputTypeTimeDialog'
         case 'datetime-dialog':
           return 'witDateTimeDialog'
        default:
          const segs = inputType.split('-')
          const result = 'wit' + segs.map(seg => helpers.ucFirst(seg)).join('')
          // console.log('GenericWidgetProperty componentName = ' + result)
          return result
      }
    },

    getInputTypeName (propertyConfig) {
      const vm = this
      var widget = 'witUndefined'
      const inputType = propertyConfig['inputType']
      
      if(propertyConfig.key.includes('default')){
        widget = 'witDefaultValue'
      }else if (inputType) {
        widget = vm.getWidgetByInputType(inputType)
      }
      // console.log('getInputTypeName: ', widget)
      return widget
    },


    updateValue (val) {
      const vm = this
      console.log('GenericWidgetProperty :: updateValue val: ', val)
      console.log('GenericWidgetProperty :: vm.ofChildTable: ' + (vm.ofChildTable ? 'yes' : 'no'))

      if (vm.ofChildTable) {
        console.log('GenericWidgetProperty :: updateValue val: ', val)
        console.log('genericWidgetProperty :: updateValue vm.childTableFieldInfoId = ' + vm.childTableFieldInfoId)
        const childTableFieldInfo = vm.formFieldInfos.find(
          info => info._id === vm.childTableFieldInfoId
        )

        console.log('GenericWidgetProperty :: updateValue :; childTableFieldInfo: ', childTableFieldInfo)
        const fieldsEditorValue = childTableFieldInfo['properties']['fieldsEditor']

        console.log('GenericWidgetProperty :: updateValue :: fieldsEditorValue:  ', fieldsEditorValue)

        for (let i = 0; i < fieldsEditorValue.fieldInfos.length; i++) {
          if (fieldsEditorValue.fieldInfos[i].fieldId === vm.fieldInfoId) {
            fieldsEditorValue.fieldInfos[i]['properties'][vm.property.propertyKey] = val
            // update field name in need
            if (vm.property.propertyKey === 'fieldName') {
              fieldsEditorValue.fieldInfos[i]['label'] = val
            }
            break
          }
        }
        const onCommandParams = {
          fieldInfoId: vm.fieldInfoId,
          propertyKey: 'fieldsEditor', // payload.propertyKey,
          propertyValue: fieldsEditorValue // payload.value
        }

        // console.log('GenericWidgetProperty :: updateValue :: onCommandParams: ', onCommandParams)

        // console.log('updateValue :: onCommandParams: ', onCommandParams)
        vm.$store.dispatch('SET_FIELD_INFO_PROPERTY', onCommandParams)
      } else {
        // console.log('GenericWidgetProperty :: updateValue :: not child table')

        const onCommandParams = {
          fieldInfoId: vm.fieldInfoId,
          propertyKey: vm.property.propertyKey,
          propertyValue: val
        }
        vm.$store.dispatch('SET_FIELD_INFO_PROPERTY', onCommandParams)
      }
    },

    // updateValue2 (val) {
    //   const vm = this
    //   console.log('GenericWidgetProperty :: updatevalue :: val: ', val)
    //   vm.$emit('onCommand', {
    //     command: 'updateWidgetPropertyValue',
    //     propertyKey: vm.property.propertyKey,
    //     value: val
    //   })
    // }
    // ,
    //
    // setValue (newValue) {
    //   const vm = this
    //   this.$emit('onCommand', {
    //     command: 'setWidgetPropertyValue',
    //     propertyKey: vm.property.propertyKey,
    //     value: newValue
    //   })
    // },

    onCommandHandler (payload) {
      const vm = this
      // console.log('GenericWidgetProperty :: onCommandHandler :: payload: ', payload)
      switch (payload.command) {
        case 'updateWidgetPropertyValue':
          // if (vm.childTable) {
          //   const childTableFieldInfo = vm.formFieldInfos.find(
          //     info => info._id === vm.childTableFieldInfoId
          //   )
          //   const fieldsEditorValue = childTableFieldInfo['properties']['fieldsEditor']
          //   for (let i = 0; i < fieldsEditorValue.fields.length; i++) {
          //     if (fieldsEditorValue.fields[i].id === vm.fieldInfoId) {
          //       fieldsEditorValue.fields[i]['properties'][payload.propertyKey] = payload.value
          //       break
          //     }
          //   }
          //   const onCommandParams = {
          //     fieldInfoId: vm.fieldInfoId,
          //     propertyKey: 'fieldsEditor', // payload.propertyKey,
          //     propertyValue: fieldsEditorValue // payload.value
          //   }
          //
          //   vm.$store.commit('setFieldInfoProperty', onCommandParams)
          // } else {
          //   const onCommandParams = {
          //     fieldInfoId: vm.fieldInfoId,
          //     propertyKey: payload.propertyKey,
          //     propertyValue: payload.value
          //   }
          //   vm.$store.commit('setFieldInfoProperty', onCommandParams)
          // }
          break
        default:
          this.$emit('onCommand', payload)
      }

    }
  }

}
</script>
