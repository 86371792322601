<template>
  <div class="w-retrieveRecord-container">
    <PendingSetup
      v-if="(!dataSource && !worksheet) || !sourceType"
      :pendingLabel="$t('workflow.label.setNodeNow')"
      :node="node"
    />
    <div v-else>
      <div v-if="isLockedModeForData">
        <v-tooltip bottom center>
          <template v-slot:activator="{ on, attrs }">
            <div 
              v-on="on"
              v-bind="attrs" class="w-retrieveRecord-lockerIcon">
              <v-icon size="40" :color="node.color">mdi-lock</v-icon>
            </div>
          </template>
          <span>{{$t('workflow.modules.retrieveRecord.placeholder.lockData')}}</span>
        </v-tooltip>
      </div>
      <TableSourceView :node="node" />
      <div class="workflow-shortview-remark">
        {{ type }}
      </div>
    </div>
  </div>
</template>

<script>
import { get, keys } from 'lodash';
import PendingSetup from './pending';
import Mixin from './mixin';
import TableSourceView from './tableSource';
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    node: Object,
    tree: Object,
    offset: Number,
  },
  computed: {
    dataSource() {
      const { properties } = this.node;
      return get(properties, 'dataSource', '');
    },
    worksheet() {
      const { properties } = this.node;
      return get(properties, 'worksheet', '');
    },
    sourceType() {
      const { properties } = this.node;
      return get(properties, 'sourceType', '');
    },
    isLockedModeForData() {
      const { properties } = this.node;
      return get(properties, 'lockData', '');
    },
    type() {
      const { properties } = this.node;
      const sourceType = get(properties, 'sourceType', '');
      return this.$t(`workflow.label.${sourceType}`);
    },
    // dataSize() {
    //   return keys(get(this.node, 'properties.data', {})).length;
    // }
  },
  components: {
    PendingSetup,
    TableSourceView,
  },
};

</script>

<style scoped>
  .w-retrieveRecord-container {
    position: relative;
  }
  .w-retrieveRecord-lockerIcon {

    position: absolute;
    right: -30px;
    top: 0;
  }
</style>
