<template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <v-card class="workform-confirm-box">
      <v-layout justify-space-between align-center>
        <v-card-title class="workform-confirm-box-title">
          {{ title }}
        </v-card-title>
        <v-btn icon @click="$emit('close')">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <div v-if="(dependsOn === -1 && shouldAskDependsOn) || !shouldAskMerge">
        <v-card-text class="w-control-merge-root">
          <v-radio-group v-model="dependsOn">
            <v-radio :value="1">
              <template v-slot:label>
                <div>
                  <div class="w-control-merge-label">
                    {{ $t('workflow.modules.branch.label.addNormalBranch') }}
                  </div>
                  <div class="w-control-merge-hints">
                    {{ dependsOnLabels && dependsOnLabels.label.normalHints }}
                  </div>
                </div>
              </template>
            </v-radio>
            <v-radio :value="2">
              <template v-slot:label>
                <div>
                  <div class="w-control-merge-label">
                    {{ dependsOnLabels && dependsOnLabels.label.addSpecialBarnch }}
                  </div>
                  <div class="w-control-merge-hints">
                    {{ dependsOnLabels && dependsOnLabels.label.specialHints }}
                  </div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="workform-confirm-box-btn"
            @click="$emit('close')"
          >
            {{ $t('workflow.btn.cancel') }}
          </v-btn>
          <v-btn
            v-if="!shouldAskMerge"
            color="#1e88e5"
            class="workform-confirm-box-btn w-control-remove-btn-confirm"
            :disabled="dependsOn === -1"
            @click="save"
          >
            {{ $t('workflow.btn.confirm') }}
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else-if="shouldAskMerge">
        <v-card-text class="w-control-merge-root">
          <v-radio-group v-model="mergeType">
            <v-radio :value="-1">
              <template v-slot:label>
                <div>
                  <div class="w-control-merge-label">{{ $t('workflow.label.noMergeToBranch') }}</div>
                  <div class="w-control-merge-hints">
                    {{ $t('workflow.label.noMergeToBranchHints') }}
                  </div>
                </div>
              </template>
            </v-radio>
            <v-radio :value="0">
              <template v-slot:label>
                <div class="w-control-merge-label">
                {{ $t('workflow.label.mergeToLeftBranch') }} {{ leftLabel }}
              </div>
              </template>
            </v-radio>
            <v-radio :value="1">
              <template v-slot:label>
                <div class="w-control-merge-label">
                  {{ $t('workflow.label.mergeToRightBranch') }} {{ rightLabel }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="workform-confirm-box-btn"
            @click="$emit('close')"
          >
            {{ $t('workflow.btn.cancel') }}
          </v-btn>
          <v-btn
            color="#1e88e5"
            class="workform-confirm-box-btn w-control-remove-btn-confirm"
            :disabled="mergeType === null"
            @click="save"
          >
            {{ $t('workflow.btn.confirm') }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NodeUtils from './../../../utils/node';
export default {
  data() {
    return {
      mergeType: null,
      dependsOn: -1,
    };
  },
  props: {
    info: {
      type: Object,
      default: {},
    },
    show: Boolean,
  }, 
  computed: {
    dependsOnLabels() {
      return this.shouldAskDependsOn && this.info.node && this.$t(`workflow.modules.${this.info.node.type}`);
    },
    shouldAskDependsOn() {
      return this.info && this.info.askDependsOn !== -1;
    },
    shouldAskMerge() {
      return this.info && this.info.askMerge;
    },
    askDependsOnEmpty() { // user does not response
      return this.shouldAskDependsOn && this.shouldAskDependsOn && this.dependsOn === -1;
    },
    askMergeOnEmpty() { // user does not response
      return this.info.askMerge && this.mergeType === -1;
    },
    title() {
      if (this.askDependsOnEmpty) {
        return this.dependsOnLabels && this.dependsOnLabels.label.conditionalHeader;
      }
      return this.$t('workflow.message.mergeToBranchTitle');
    },
    leftLabel() {
      if (this.dependsOn !== 2) return '';
      return `(${this.dependsOnLabels.label.pass})`;
    },
    rightLabel() {
      if (this.dependsOn !== 2) return '';
      return `(${this.dependsOnLabels.label.fail})`;
    },
    
  },
  methods: {
    save() {
      let { mergeType, dependsOn } = this;
      if (dependsOn === 1) {
        if (mergeType === -1) {
          mergeType = 0;
        }
      }
      if (!this.shouldAskMerge) {
        mergeType = -1;
      }
      dependsOn = dependsOn === 2;
      // console.log(dependsOn, mergeType);
      this.$emit('merge', mergeType, dependsOn);
    }
  },
  mounted() {
    // console.log(this.info && this.info.askDependsOn !== -1 && this.$t(`workflow.modules.${this.info.node.type}`));
  }
};

</script>

<style scoped>
  .w-control-remove-btn-confirm {
    color: white;
  }
  .w-control-merge-label {
    font-size: 15px;
    padding-top: 5px;
    color: #333;
  }
  .w-control-merge-hints {
    font-size: 13px;
    color: #757575;
  }
  .w-control-merge-root {
    padding: 0 16px;
  }
  .w-control-merge-root .v-input--radio-group {
    margin-top: 0;
  }
</style>
