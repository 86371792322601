<template>
  <div class="">
    <v-menu v-if="user" right offset-y bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          style="max-width: 250px"
        >
          <div
            class="d-flex flex-column line-height-1 align-start"
            :class="{ 'mt-2': showTeamName }"
          >
            <span
              class="ma-0 pa-0"
              style="
                max-width: 200px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              "
            >
              {{ employeeName || user.name || user.username }}
            </span>
            <span
              v-if="showTeamName"
              class="caption line-height-1"
              style="
                margin-top: 2px;
                max-width: 200px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                opacity: 0.5;
              "
            >
              {{ teamName }}
            </span>
          </div>

          <v-icon class="ml-3">mdi-account-settings</v-icon>
          <!--<v-icon>mdi-dots-vertical</v-icon>-->
        </v-btn>
      </template>
      <v-list dense class="denseList popup-menu-list" tile>
        <v-list-item
          v-for="(menuItem, index) in userMenu"
          icon
          :key="index"
          @click="onMenuItemClick(menuItem.command)"
        >
          <v-icon class="mr-2">{{ menuItem.tagType }}</v-icon>
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item>

        <!-- Guest Only -->
        <!-- <v-list-item
          v-if="guest && !hasTeam"
          :class="'error--text'"
          @click="setupGuestTeam"
        >
          <v-icon :class="hasTeam ? '' : 'error--text'" left
            >mdi-account-group</v-icon
          >
          <v-list-item-title>
            {{ $t("guest.settings") }}
            {{ !hasTeam ? " ***" + $t("guest.teamNotSetupYet") + "***" : "" }}
          </v-list-item-title>
        </v-list-item> -->

        <v-list-item
          v-if="hasTeam"
          dark
          class="primary white--text text-caption"
          disabled
        >
          {{ $store.getters.activeTeamName }}
        </v-list-item>
      </v-list>
    </v-menu>
    <user-profile-dialog
      v-if="user"
      ref="userProfileDialog"
    ></user-profile-dialog>
    <guest-change-password-dialog
      v-if="guest"
      ref="guestChangePasswordDialog"
    ></guest-change-password-dialog>
    <preference-dialog
      v-if="showPreferenceDialog"
      v-model="showPreferenceDialog"
      @submit="updateUserSetting"
    />
  </div>
</template>

<script>
import userProfileDialog from "./dialogs/UserProfileDialog";
import guestChangePasswordDialog from "./dialogs/GuestChangePasswordDialog";
import PreferenceDialog from "./dialogs/PreferenceDialog";
import EventBus from "@/event-bus.js";

export default {
  components: {
    userProfileDialog,
    guestChangePasswordDialog,
    PreferenceDialog,
  },
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      showTeamName: false,

      showPreferenceDialog: false,
    };
  },
  computed: {
    userMenu() {
      let result = [
        {
          title: this.$t("general.profile"),
          tagType: "mdi-account-edit",
          command: "profile",
        },
        {
          title: this.$t("general.locale"),
          tagType: "mdi-translate",
          command: "locale",
        },
      ];
      if (this.$store.getters.canAccessWorkAdminPage) {
        result.push({
          title: this.$t('general.employeeList'),
          tagType: "mdi-family-tree",
          command: "employees",
        });
      }
      // if (this.guest && this.hasTeam) {
      //   result.push({
      //     title: this.$t('guest.changePassword'),
      //     tagType: 'mdi-lock',
      //     command: 'guestChangePassword'
      //   })
      //   result.push({
      //     title: this.$t('guest.settings'),
      //     tagType: 'mdi-account-group',
      //     command: 'guestSetup'
      //   })
      // }
      result.push({
        title: this.$t("general.logout"),
        tagType: "mdi-logout",
        command: "logout",
      });
      return result;
    },
    user() {
      return this.$store.getters.user;
    },
    employeeName() {
      return this.$store.getters.activeEmployeeName;
    },
    teamName() {
      return this.$store.getters.activeTeamName;
    },
    hasTeam() {
      return this.user && this.user.teams.length > 0;
    },
    // guest() {
    //   return this.user && this.user.role === "guest";
    // },
  },

  methods: {
    // changeGuestPassword() {
    //   this.$refs.guestChangePasswordDialog.open();
    // },
    // setupGuestTeam() {
    //   console.log(
    //     "UserMenu: this.user.teamEmployeeIds: " +
    //       JSON.stringify(this.$store.getters.user.teamEmployeeIds)
    //   );
    //   this.$router.push("/guest_info_setup");
    // },
    onMenuItemClick(command) {
      const vm = this;
      switch (command) {
        // case "guestSetup":
        //   vm.setupGuestTeam();
        //   break;
        // case "guestChangePassword":
        //   vm.changeGuestPassword();
        //   break;
        case "profile":
          vm.$refs.userProfileDialog.open();
          break;
        case "locale":
          this.showPreferenceDialog = true;
          break;
        case "logout":
          EventBus.$emit("disableToolbarButtons");
          EventBus.$emit("showSpinner");
          vm.$store.dispatch("LOGOUT").then(() => {
            EventBus.$emit("enableToolbarButtons");
            EventBus.$emit("hideSpinner");
            vm.$store.dispatch("RESET_APPS");

            vm.$router.push("/home");
          }).catch(err=>{
            EventBus.$emit("hideSpinner");
            vm.$toast.error(err)
          });
          break;
          case "employees":
            this.$router.push({name:"workAdminPage"})
            break;
      }
    },
    updateUserSetting(locale) {
      const postData = {
        urlCommand: "/users/updateGlobalPreference",
        data: {
          locale,
        },
      };

      this.$store.dispatch("AUTH_POST", postData).then((res) => {
        if (res.result) {
          location.reload();
        }
      });
    },
  },
};
</script>