<template>
  <div class="childTableList">
    <div
      v-for="(record, index) in records"
      :key="record._id"
      class="childRecord"
      :style="`font-size:${fontSize}px !important`"
    >
      <div v-for="fieldInfo in headers" :key="fieldInfo._id" class="child-field">
        <div v-if="fieldInfo._id === 'sequence'" class="field-title">
          {{ index + 1 }}
        </div>
        <div v-else>
          <span class="field-title">{{ fieldInfo.label }}</span>
          <div v-if="fieldInfo.type === 'richText'" 
          class="field-value ql-editor py-1" 
          v-html="record[fieldInfo._id]" />
          <div v-else-if="fieldInfo.type === 'signature'" class="field-value pa-1"><img
                              v-if="record[fieldInfo._id]"
                              class="signature-image"
                              style="width:168px;height:100px"
                              :src="record[fieldInfo._id]"
                            /></div>
          <div v-else class="field-value">
            {{ getDisplayValue(record._id, record[fieldInfo._id], fieldInfo) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDisplayValue } from "@/helpers/DataHelpers";

export default {
  props: {
    fieldInfos: Array,
    records: Array,
    visibleFields: Array,
    relatedRecords: Object,
    childRelatedRecords:Object,
    relatedTableInfos:Object,    
    fontSize: Number,
  },
  computed: {
    colGroup() {
      const width = Math.max(728 / this.headers.length, 33);
      return this.headers.map((info) => {
        return {
          width,
          minWidth: 33,
        };
      });
    },
    headers() {
      let result = this.fieldInfos.filter((info) =>
        this.visibleFields.includes(info._id)
      );
      if (this.visibleFields.includes("sequence")) {
        result = [
          { _id: "sequence", label: this.$t("printTemplate.sequence") },
        ].concat(result);
      }
      return result;
    },
  },
  methods: {
    getDisplayValue(recordId, value, fieldInfo) {
      return getDisplayValue(value, fieldInfo, this);
    },
  },
};
</script>
<style scoped>
.childTableList {
  margin-bottom: 5px;
  margin-top: 5px;
  border-top: 1px solid #ddd;
}

.childTableList > .childRecord {
  border-bottom: 1px solid #ddd;
}

.childTableList > .childRecord:last-child {
  border-bottom: none;
}

.field-title {
  padding-left: 5px;
  display: inline-block;
  width: 78px;
  box-sizing: border-box;
  vertical-align: top;
  word-break: break-word;
}

.field-value {
  display: inline-block;
  padding-left: 24px;
  padding-right: 5px;
  box-sizing: border-box;
  width: calc(100% - 78px);
  word-break: break-word;
  white-space: pre-wrap;
}

.child-field {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
