const mixin = {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    tabId: {
      type: Number,
      default: -1
    },
    fieldInfos: {
      type: Array,
      default () {
        return []
      }
    },
    dataSourceInfo: {
      type: Object,
      default () {
        return {
          fieldInfos: [],
          titleFieldInfoId: ''
        }
      }
    },
    value: Object,
    tabKey: String,
    errorCount: {
      type: Number,
      default: 0
    },
    params: Object,
    type:{
      type: String,
      default: ''
    }
  },
  computed: {
    tabData: {
      get () {
        return this.value
      },
      set (val) {
        const vm = this
        vm.$emit('input', val)
      }
    },
    filterInfos () {
      return this.$store.getters.filterInfos
    },
    allWidgets () {
      return this.$store.getters.allWidgets
    }
  },
  methods: {
    checkErrorCount(state) {
      const vm = this
      console.log('mixin: checkErrorCount')
      var result = 0
      if (typeof vm._checkErrorCount === 'function') {
        result = vm._checkErrorCount(state)
      }
      return result
    },

    // checkIsValid (state) {
    //   const vm = this
    //   console.log('mixin: checkIsvalid :; vm.tabId = ' + vm.tabId)
    //   var errorCount = 0
    //   if (vm.tabId && (vm.tabId !== -1)) {
    //     if (typeof vm._checkIsValid === 'function') {
    //       console.log('mixin: _checkIsvalid is function')
    //       errorCount = vm._checkIsValid(state)
    //     }
    //     // console.log('mixin :: checkIsvalid vm.tabId = ' + vm.tabId)
    //     vm.$emit('onCommand', {
    //       command: 'updateErrorCount',
    //       tabId: vm.tabId,
    //       errorCount: errorCount
    //     })
    //   }
    //   return errorCount === 0
    // },
    getFilterTypeByFieldInfo (fieldInfo) {
      const vm = this
      var result = ''
      const widget = vm.allWidgets.find(item => item._id === fieldInfo.widgetId)
      if (widget) {
        result = widget.filterType
      } else {
        console.log('*** widget of id: ' + fieldInfo.widgetId + ' (label: ' + fieldInfo.label + ') not found.')
      }
      if (fieldInfo.label === '擁有者') {
        console.log('getFilterTypeByFieldInfo fieldInfo: ', fieldInfo)
        console.log('getFilterTypeByFieldInfo widget: ', widget)
        // console.log('getFilterTypeByFieldInfo fieldInfo.label=' + fieldInfo.label + ': filterType=' + result)
      }
      return result
    },
    getRelationByFieldId (fieldId) {
      const vm = this
      console.log('getRelationByFieldId fieldId = ' + fieldId)
      const fieldInfo = vm.fieldInfos.find(info => info.fieldId === fieldId)
      const filterType = vm.getFilterTypeByFieldInfo(fieldInfo)
      const filterInfo = vm.filterInfos[filterType]

      console.log('getRelationByFieldId :: fieldInfo: ', fieldInfo)
      console.log('getRelationByFieldId :: filterType: ', filterType)
      console.log('tabFilters :; filterInfo: ', filterInfo)

      var relation = ''
      if (filterInfo && filterInfo.options && filterInfo.options.length > 0) {
        relation = filterInfo.options[0]
      }
      console.log('tabFilters :: addFilter :: relation = ' + relation)
      return relation
    }
  }
}

export default mixin

