<template>
  <div class="workflow-node-root">
    <div class="workflow-node-col" v-for="(n, index) in node" :key="n._id">
      <TreeNode
        :node="n"
        v-if="n.nodeType === nodeType"
        :offset="index"
        :tree="tree"
        :walkingNode="walkingNode"
        :isYourChildDependency="myNextIsDependsOn({ tree: node, node: n, offset: index })"
        @insert="askForNode"
        @drop="insertNode"
        @remove="removeNode"
        @clone="cloneNode"
      />
      <TreeGroup
        :parent="node"
        :tree="tree"
        :forkNode="n"
        :walkingNode="walkingNode"
        :node="n.children"
        v-if="n.nodeType === groupType"
        :offset="index"
        @insert="askForNode"
        @drop="insertNode"
        @insertCondition="insertCondition"
      />
    </div>
    <MergeBox
      :show="true"
      :info="mergeInfo"
      v-if="!!mergeInfo"
      @close="closeMerge"
      @merge="confirmMerge"
    />
  </div>
</template>

<script>
import TreeNode from './node/basic';
import TreeGroup from './node/group';
import MergeBox from './node/mergeToBranch';
import {
  WorkflowNodeIdentify,
} from './../../constants/node';
import NodeMixin from './node/nodeMixin';
import WMixin from "@/pages/admin/workflow/mixin";
import {
  PICKING_MODULE,
  SET_MODULE_MENU_STATUS,
  ADD_WORKFLOW_NODE,
} from '@/store/modules/workflow/action_types';
import NodeUtils from './../../utils/node';
const {
  condition,
} = WorkflowNodeIdentify;
export default {
  name: 'worflow-tree',
  mixins: [NodeMixin, WMixin],
  props: {
    node: Array,
    parent: Array,
    tree: Object,
    walkingNode: Array,
  },
  components: {
    TreeGroup,
    TreeNode,
    MergeBox,
  },
  computed: {
    nodeType() {
      return WorkflowNodeIdentify.common;
    },
    groupType() {
      return WorkflowNodeIdentify.fork;
    },
    isPreviewMode() {
      return this.$store.getters.isPreviewMode;
    },
  },
  // TODO: revamp for this part? @Terry
  methods: {
    askForNode (payload) {
      this.$store.dispatch(PICKING_MODULE, {
        ...payload,
        tree: this.node,
      });
      this.$store.dispatch(SET_MODULE_MENU_STATUS, true);
    },
    insertNode ({ node, offset }, config) {
      // consider drag and drop for the repositioning
      if (!NodeUtils.rejectRePosition(node, config) &&
        !config._REPOS_
      ) {
        // ask for merge if it is a new forking node
        if (NodeUtils.isFork(config.nodeType)) {
          const askMerge = this.node.length - 1 > offset;
          const askDependsOn = NodeUtils.hasResultCondition(node.type);
          if (askMerge || askDependsOn !== -1) {
            // askMerge = true;
            this.mergeInfo = { node, offset, config, askMerge, askDependsOn };
          } else {
            this.executePreInsertNode({ node, offset, config });
          }
        } else {
          // direct execute the insert behavior
          this.executePreInsertNode({ node, offset, config });
        }
      } else if (config._REPOS_) {
        // re-positioning the node
        // const { node: currentNode } = config;
        // console.log(config.node.tree);
        // this.tree.insertNode(node.tree, { ...config.node }, offset, false, false);
        // this.tree.remove(config.node.tree, {...config.node}, config.offset);

        // this.$store.dispatch(REPOSITION_WORKFLOW_NODE, {
        //   node: current._id,
        //   parent: node._id, // move to that parent node
        //   workflow: this.workflowInfo._id,
        //   workflowInfo: this.workflowInfo,
        //   appId: this.params.appId,
        // });
      }
    },
    myNextIsDependsOn({ tree, node, offset }) {
      return NodeUtils.nextHasResultCondition(tree, node, offset);
    },
    insertCondition ({ node, offset }) {
      const { children=[] } = this.node[offset];
      const newNode = this.tree.prepareNode(children, node, { 
        nodeType: condition, 
        type: condition,
        color: 'rgb(76, 125, 158)', // default, TODO: from server?
      }, this.$t('workflow'));
      newNode.name = this.$t('workflow.modules.branch.defaultName');
      this.pendingNode = { tree: children, node: newNode, offset: children.length };
      const formated = NodeUtils.normalizeNewNodeToBeSaved(newNode);
      // console.log(formated);
      this.$store.dispatch(ADD_WORKFLOW_NODE, {
        node: formated,  // normalization
        parent: formated.parent,  // added to 
        workflow: this.workflowInfo._id,
        workflowInfo: this.workflowInfo,
        appId: this.params.appId,
      });
    },
  },
  // watch: {
  //   isPreviewMode(preview) {
      
  //   },
  // },
};
</script>

<style scoped>
</style>
