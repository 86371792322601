const dateTimeHelperMixin = {
  methods: {
    extractDate(dayStr) {
      return dayStr.substr(0,10)
    },
    getSimpleDate (d) {
      let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d)
      let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d)
      let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d)
      return `${ye}-${mo}-${da}`
    },
    // getHourMin2 (d) {
    //   if (typeof d === 'undefined') {
    //     d = new Date()
    //   }
    //   let hr = new Intl.DateTimeFormat('en', {hourCycle:'h24', hour: '2-digit'}).format(d)
    //   let mi = new Intl.DateTimeFormat('en', {minute: '2-digit'}).format(d)
    //   return `${hr}:${mi}`
    // },
    getHourMin (dt) {
      if (typeof dt === 'undefined') {
        dt = new Date()
      }
      return dt.toTimeString().substr(0,5)
    },
    getHourMinSec (dt) {
      if (typeof dt === 'undefined') {
        dt = new Date()
      }
      return dt.toTimeString().substr(0,8)
    },
    dateTimeToYPStr (dt) {
      // YOOV+ format: 
      // (str) yyyy-mm-dd hh:mm
      //
      console.log('dateTimeToYPStr: dt: ', dt)
      return this.getSimpleDate(dt) + ' ' + this.getHourMin(dt)
    },
    getNow () {
      let dt = new Date()
      return this.getSimpleDate(dt) + ' ' + this.getHourMin(dt)
    },
    getToday () {
      let d = new Date();
      return this.getSimpleDate(d)
    },
    getWeekday(dayStr) {
      let result = new Date(dayStr)
      return result.getDay()
    },
    getPrevDate(dayStr) {
      let result = new Date(dayStr)
      result.setDate(result.getDate() - 1)
      return this.getSimpleDate(result)
    },
    getNextDate(dayStr) {
      let result = new Date(dayStr)
      result.setDate(result.getDate() + 1)
      return this.getSimpleDate(result)
    },
    getYesterday () {
      let today = new Date();
      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      return this.getSimpleDate(yesterday)
    },
    getMonthStartDate () {
      let d = new Date();
      let monthStartDate = new Date(d.getFullYear(), d.getMonth(), 1)
      return this.getSimpleDate(monthStartDate)
    },
    getMonthEndDate() {
      let today = new Date();
      let monthEndDate = new Date(today.getFullYear(), today.getMonth()+1, 0)
      return this.getSimpleDate(monthEndDate)
    },
    getNextMonthStartDate () {
      let d = this.$moment().tz('Asia/Hong_Kong').add(1, "months").startOf('month')
      return d.format('YYYY-MM-DD')
    },
    getNextMonthEndDate() {
      let d = this.$moment().tz('Asia/Hong_Kong').add(1, "months").endOf('month')
      return d.format('YYYY-MM-DD')
    },
    getWeekStartDate () {
      let d = this.$moment().tz('Asia/Hong_Kong').startOf('week')
      return d.format('YYYY-MM-DD')
    },
    getWeekEndDate() {
      let d = this.$moment().tz('Asia/Hong_Kong').endOf('week')
      return d.format('YYYY-MM-DD')
    },
    getNextWeekStartDate () {
      let d = this.$moment().tz('Asia/Hong_Kong').add(1, "weeks").startOf('week')
      return d.format('YYYY-MM-DD')
    },
    getNextWeekEndDate() {
      let d = this.$moment().tz('Asia/Hong_Kong').add(1, "weeks").endOf('week')
      return d.format('YYYY-MM-DD')
    },
    todayTime (format) {
      if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD hh:mm:ss'
      }
      return this.formatDateTime(new Date(), format)
    },
    formatDateTime (date, format) {
      const vm = this
      if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD hh:mm:ss'
      }
      // console.log('formatDateTime :: date: ', date)
      const m = vm.$moment(date)
      return m.format(format)
    }
  }
}

export default dateTimeHelperMixin
