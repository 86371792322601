import helpers from '@/helpers'
import {app} from '@/main'
import {getWidgetPropertyValues} from '@/helpers/FormHelpers'
import {formConfig as mutations} from "../../mutation-types";

const addWidgetByIndex = (commit, state, payload) => {
  const {newFieldInfo, newLayoutItem} = createFieldObjects(state, payload);
  const index = payload.index

  let params = {
    type: payload.type,
    data: {
      newFieldInfo,
      newLayoutItem
    },
    options: {
      index: index
    }
  };
  // console.log("PARAM", params)
  commit(mutations.INSERT_LAYOUT_ITEM, params);
  console.log('addWidgetByIndex :; newFieldInfo: ', newFieldInfo)
  console.log('addWidgetByIndex :; newLayoutItem: ', newLayoutItem)
  return {
    fieldInfo: newFieldInfo,
    layoutItem: newLayoutItem
  }
}

export const fillParentChildDataInfo = (dispatch, fieldInfoId, payload) => {

  
  dispatch('SET_FIELD_INFO_PROPERTY', {
    fieldInfoId: fieldInfoId,
    propertyKey: 'dataSource',
    propertyValue: payload.dataTable._id
  })
  dispatch('SET_FIELD_INFO_PROPERTY', {
    fieldInfoId: fieldInfoId,
    propertyKey: 'fieldName',
    propertyValue: payload.label
  })

  if (payload.relationship === 'parent') {

    dispatch('SET_FIELD_INFO_PROPERTY', {
      fieldInfoId: fieldInfoId,
      propertyKey: 'relatedRecordQuantity',
      propertyValue: 'single'
    })

  } else {

    dispatch('SET_FIELD_INFO_PROPERTY', {
      fieldInfoId: fieldInfoId,
      propertyKey: 'relatedRecordQuantity',
      propertyValue: 'multiple'
    })

  }
}

export const addParentChildRelation = (dispatch, commit, state, rootGetters, payload) => {
  return new Promise((resolve, reject) => {
    console.log('addParentChildRelation :: allWidgets: ', rootGetters.allWidgets)
    const relatedRecord = rootGetters.allWidgets.find(w => w.key === 'relatedRecord')
    const relatedRecordWidgetParent = JSON.parse(JSON.stringify(relatedRecord))
    const relatedRecordWidgetChild = JSON.parse(JSON.stringify(relatedRecord))
    const index = payload.index

    // parent
    const result1 = addWidgetByIndex(commit, state,
      {
        ...payload,
        data: relatedRecordWidgetParent,
        index: index
      }
    )
    fillParentChildDataInfo(dispatch,
      result1.fieldInfo._id, {
        team: rootGetters.activeTeam,
        app: rootGetters.currentApp,
        dataTable: rootGetters.currentForm,
        label: rootGetters.currentForm.label + ' (' + app.$i18n.t('widgets.parent') + ')',
        relationship: 'parent'
      }
    )

    // child
    const result2 = addWidgetByIndex(commit, state,
      {
        ...payload,
        data: relatedRecordWidgetChild,
        index: index + 1
      }
    )
    fillParentChildDataInfo(dispatch,
      result2.fieldInfo._id, {
        team: rootGetters.activeTeam,
        app: rootGetters.currentApp,
        dataTable: rootGetters.currentForm,
        label: rootGetters.currentForm.label + ' (' + app.$i18n.t('widgets.child') + ')',
        relationship: 'child'
      }
    )
    console.log('after fillParentChildDataInfo :: result1.fieldInfo: ', result1.fieldInfo)
    console.log('after fillParentChildDataInfo :: result2.fieldInfo: ', result2.fieldInfo)

    const fieldPair = [
      result1.fieldInfo.fieldId,
      result2.fieldInfo.fieldId
    ]
    commit('ADD_FORM_INFO_LINKED_FIELDS', fieldPair)

    resolve(
      result1.fieldInfo,
      result1.layoutItem)
  })
};

export const getDataSourceTableIds = (formFieldInfos = []) => {


  return Array.from(new Set(formFieldInfos.filter(info => info.properties.dataSource || (info.properties.fieldsEditor && info.properties.fieldsEditor.formId))
  .map(info => info.properties.dataSource || info.properties.fieldsEditor.formId)))
};

export const getNextFieldId = (formFieldInfos) => {
  const prefix = "f";
  var count = 1;
  var fieldId = prefix + helpers.zeroPad(count, 4);
  var fieldIds = formFieldInfos.map((fieldInfo) => fieldInfo.fieldId);
  while (fieldIds.indexOf(fieldId) >= 0) {
    count++;
    fieldId = prefix + helpers.zeroPad(count, 4);
  }
  return fieldId;
};

export const getFieldInfoById = (fieldInfos, fieldInfoId) => {
  return fieldInfos.find((info) => info._id === fieldInfoId);
};
export const findFormItemIndex = (arr, fieldInfoId) => {
  var result = null;
  for (let i = 0; i < arr.length; i++) {
    const loopRow = arr[i];
    for (let j = 0; j < loopRow.length; j++) {
      const layoutItem = loopRow[j];
      if (layoutItem._id === fieldInfoId) {
        result = {i: i, j: j};
        break;
      }
    }
  }
  return result;
};

export const getTempId = () => {
  return '_' + Date.now().toString()
}

export const createFieldObjects = (state, payload) => {
  const widget = payload.data
  const widgetProperties = payload.widgetProperties

  // console.log('createFieldObjects: payload: ', payload)
  const isUIElement = widget.key.indexOf("_") === 0;
  // const fieldId = isUIElement ? "" : getNextFieldId(state.formFieldInfos);
  //const fieldId = getTempId(); //  isUIElement ? "" : getNextFieldId(state.formFieldInfos);
  const tempId = getTempId(); // "_" + Date.now().toString();
  const label = app.$i18n.t("widgets." + widget.labelTag);
  let propertyValues = getWidgetPropertyValues(
    widget,
    label,
    widgetProperties
  );
  // console.log('label=' + label)
  // console.log('propertyValues: ', propertyValues)

  if (payload.hasOwnProperty('width') && propertyValues.hasOwnProperty('width')) {
    propertyValues['width'] = payload['width']
  }
  const newFieldInfo = {
    _id: tempId,
    type: widget.key,
    label: label,
    labelTag: widget.labelTag,
    fieldId: tempId,
    widgetId: widget._id,
    isSytem: widget.isSystem ? widget.isSystem : false,
    properties: propertyValues,
  };

  const newLayoutItem = {
    _id: tempId,
    controlType: isUIElement ? "static" : "data",
  };

  return {newFieldInfo, newLayoutItem};
}

export const getWidgetWidth = (state, fieldInfoId) => {
  const fieldInfo = state.formFieldInfos.find(info => info._id === fieldInfoId)
  var result = 12

  if (fieldInfo && fieldInfo.properties && fieldInfo.properties.width) {
    result = parseInt(fieldInfo.properties.width)
  }
  return result
}
