<template>
  <input-widget-container
    :isLayout="isLayout"
    :widget="widget"
    :required="!isReadonly && required"
    :fieldWidth="fieldWidth"
    :isHidden="isHidden"
    :label="fieldName"
    :noIcon="noIcon"
    :tooltipText="description"
  >
    <v-text-field
      v-if="isReadOnly"
      :value="inputValue"
      disabled
      dense
      
    ></v-text-field>  
    <div v-else :class="{ 'workflow-customize-form-record-hide': hideInput }">
      <tel-input-field
        v-model="inputValue"
        :ref="fieldId"
        :readonly="isReadOnly"
        :fieldId="fieldInfo.fieldId"
        :hasError="hasError"
        :errorMsg="errorMessage"
        @blur="checkDuplicate"
        :placeholder="placeholder"
        :items="countries"
        :showCountryCode="showCountryCode"
        :defaultCountryCode="defaultCountryCode"
        :commonCountryCodes="commonCountryCodes"
      />
    </div>
  </input-widget-container>
</template>

<script>
import mixin from "./mixin";
import inputWidgetContainer from "./comps/InputWidgetContainer";
import TelInputField from "@/components/form/TelInputField";

import phoneInputMixin from "@/mixins/phoneInputMixin"
export default {
  name: "inputWidgetPhone",
  mixins: [mixin, phoneInputMixin],
  components: {
    inputWidgetContainer,
    TelInputField,
  },
  props: {
    noIcon: Boolean,
    hideInput: Boolean,
  },
  data() {
    return {};
  },
  computed: {

  },
  watch: {
    value(oldVal, newVal) {
      this.hasError = false
      this.errorMessage = "";
      this.validateOnInput(newVal);
    },
  },
  methods: {
    validateOnInput() {
        if (this.rules.length > 0) {
          if (
            this.rules.some((rule) => {
              const result = rule(this.value);
              if (result !== true) {
                this.errorMessage = result;
                return true;
              }
              return false;
            })
          ) {
            this.hasError = true;
            return;
          }
        }
    },
    validate() {
      return new Promise(async (resolve, reject) => {
        if (this.isReadonly) {
          resolve(true)
          return
        }
        if (this.rules.length > 0) {
          if (
            this.rules.some((rule) => {
              const result = rule(this.value);
              if (result !== true) {
                this.errorMessage = result;
                return true;
              }
              return false;
            })
          ) {
            this.hasError = true;
            resolve(false);
            return;
          }
        }

        if (this.noDuplicate){
          let result = await this.checkDuplicate()
          if (result) {
            this.hasError = true;
            resolve(false);
            return;
            }
        }
         resolve(true)
         return
      });
    },
  },
};
</script>

<style scoped>
.is-layout {
  padding-bottom: 0 !important;
}
.v-textarea textarea {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1.2;
}
</style>
