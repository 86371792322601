<template>
    <div class="d-flex flex-column sender-config" style="height:100%; overflow:hidden">
        <div class="py-3 d-flex align-center grey lighten-4">
            <v-btn
                class="ml-3"
                icon
                @click="closeConfig()"
            >
                <v-icon>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
            <span v-if="!configId" class="ml-3" style="font-size:15px">
                {{$t('mailSetting.addMailServer')}}
            </span>
            <span v-else class="ml-3" style="font-size:15px">
                {{$t('mailSetting.editMailServer')}}
            </span>
        </div>
        <v-form ref="mailConfigForm" style="overflow: auto" class="">
            <div class="my-4 px-5">
                <div class="d-flex align-center" v-for="(row, rowIndex) in rows">
                    <div v-for="(field, fieldIndex) in row" class="d-flex align-start" style="width:100%"
                        v-if="(field.key!='authUser' && field.key!='authPassword')||requiredAuth"
                    >
                        <div class="" style="text-align:right;" :style="field.labelWidth?'width:'+field.labelWidth+'px':'width:150px'">
                            <span v-if="field.required" class="red--text">*</span>
                            {{$t(`mailSetting.${field.label}`)}}
                        </div>
                        <div v-if="['text', 'number', 'email'].includes(field.type)" :style="field.inputWidth?'width:'+field.inputWidth+'px':''">
                            <v-text-field
                                :rules="getRules(field)"
                                :type="field.password?'password':''"
                                outlined
                                tile
                                height="20"
                                class="rounded-0 mailTextField ml-5"
                                :class="fieldIndex!=0? '':''"
                                dense
                                v-model="field.value"
                                :placeholder="$t('mailSetting.'+field.placeholder)"
                                style="font-size:12px;"
                                @input="handleTextInput(field)"
                            />
                        </div>
                        <div class="ml-5 mr-auto" v-if="field.type=='switch'">
                            <v-switch
                                v-model="field.value"
                                @change="handleSwtichChange($event, field)"
                                dense
                                class="mt-0 pt-0"
                            >
                            </v-switch>
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="mt-auto grey lighten-4 d-flex justify-end">
            <div class="d-flex mr-5">
                <div>
                    <v-btn 
                        text
                        color="teal"
                        @click="testConfig"
                    >
                        {{$t('buttons.testConnection')}}
                    </v-btn>
                </div>
                <div>
                    <v-btn 
                        text
                        @click="closeConfig"
                    >
                        {{$t('buttons.cancel')}}
                    </v-btn>
                </div>
                <div>
                    <v-btn 
                        text 
                        color="teal"
                        @click="saveConfig"                    >
                        <span v-if="configId==0">{{$t('general.new')}}</span>
                        <span v-else>{{$t('buttons.save')}}</span>
                    </v-btn>
                </div>
            </div>
        </div>

        <v-overlay v-if="loading" class="loader">
            <font-awesome-icon icon="spinner" class="spinner fa-5x fa-spin"></font-awesome-icon>
        </v-overlay>

        <general-dialog ref="generalDialog" />
        <confirm-dialog ref="confirmDialog" />
    </div>
</template>
<script>
    import generalDialog from "@/components/dialogs/GeneralDialog";
    import confirmDialog from "@/components/dialogs/ConfirmDialog";

    export default {
        props: {
            loading: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            generalDialog,
            confirmDialog,
        },
        data(){
          return{
            configId: 0,
            mode: 'new',
            isPasswordEdited: false,
            rows:[
                [{
                    label: 'profileName',
                    key: 'profileName',
                    value: '',
                    type: 'text',
                    placeholder: 'profileNamePlaceHolder',
                    required: true,
                    inputWidth: 470
                }],
                [{
                    label: 'host',
                    key: 'host',
                    value: '',
                    type: 'text',
                    placeholder: 'hostPlaceHolder',
                    required: true,
                    inputWidth: 320
                    
                },{
                    label: 'port',
                    key: 'port',
                    value: 25,
                    type: 'number',
                    placeholder: 'portPlaceHolder',
                    required: false,
                    inputWidth: 100,
                    labelWidth: 50
                }],
                [{
                    label: 'fromDisplayName',
                    key: 'fromDisplayName',
                    value: '',
                    type: 'text',
                    placeholder: 'fromDisplayNamePlaceHolder',
                    required: false,
                    inputWidth: 470
                }],
                [{
                    label: 'from',
                    key: 'from',
                    value: '',
                    type: 'email',
                    placeholder: 'fromPlaceHolder',
                    required: true,
                    inputWidth: 470
                }],
                [{
                    label: 'replyToDisplayName',
                    key: 'replyToDisplayName',
                    value: '',
                    type: 'text',
                    placeholder: 'replyToDisplayNamePlaceHolder',
                    required: false,
                    inputWidth: 470
                }],
                [{
                    label: 'replyTo',
                    key: 'replyTo',
                    value: '',
                    type: 'email',
                    placeholder: 'replyToPlaceHolder',
                    required: false,
                    inputWidth: 470
                }],
                [{
                    label: 'enableSSL',
                    key: 'enableSSL',
                    value: false,
                    type: 'switch',
                    required: false
                }],
                [{
                    label: 'reqireAuthentication',
                    key: 'requireAuthentication',
                    value: false,
                    type: 'switch',
                    required: false
                }],
                [{
                    label: 'authUser',
                    key: 'authUser',
                    value: "",
                    type: 'text',
                    required: true,
                    placeholder: 'authUserPlaceHolder',
                    inputWidth: 470
                }],
                [{
                    label: 'authPassword',
                    key: 'authPassword',
                    value: "",
                    type: 'text',
                    required: true,
                    placeholder: 'authPasswordPlaceHolder',
                    inputWidth: 470,
                    password: true
                }]
            ],
            visible: true
          }  
        },
        computed:{
            requiredAuth(){
                return this.rows.flat().find(field=>field.key=='requireAuthentication').value
            }
        },
        methods:{
            handleTextInput(field){
                if(field.key === 'authPassword'){
                    this.isPasswordEdited = true
                }
            },
            closeConfig(){
                this.$emit('onCommand' , {
                    command: 'cancel'
                })
            },
            getForm() {
                return this.rows.flat().reduce((result, field)=>{
                    result[field.key] = (field.type=='text' || field.type=='email')?
                    field.value.trim():field.value
                    return result
                }, {})
            },
            saveConfig(){
                if(!this.$refs.mailConfigForm.validate()) return
                this.$emit('onCommand', {
                    command: 'save',
                    form: { ...this.getForm() },
                    configId: this.configId,
                    visible: this.visible
                })
            },
            testConfig() {
                this.$emit('onCommand' , {
                    command: 'testConnection',
                    form: { ...this.getForm() },
                    configId: this.configId,
                })
            },
            getRules(field){
                var rules = []
                let vm = this
                if(field.key==='authPassword'){
                    if(
                        field.key==='authPassword' &&
                        this.mode === 'edit' &&
                        !this.isPasswordEdited
                    ){
                        return []
                    }
                }
                if(field.required){
                    rules.push(v => !!v || vm.$t('mailSetting.ruleRequired'))
                }
                switch(field.type){
                    case 'number':
                        rules.push(v => Number.isInteger(Number(v)) || vm.$t('mailSetting.ruleInteger'))
                    break
                    case 'text':
                        rules.push(v => (v.trim().length <= 255) || vm.$t('mailSetting.ruleText'))
                    break
                    case 'email':
                        rules.push(v => (/.+@.+\..+/.test(v)|| !v) || vm.$t('mailSetting.ruleEmail'))
                    break
                        
                } 
                return rules
            },
            handleSwtichChange(e, field){
                if(field.key == "enableSSL"){
                    const portField = this.rows.flat().find(item=>item.key=="port")
                    if(e)
                        portField.value = 465
                    else
                        portField.value = 25
                }
            },
            simpleDialog(title, message) {
                let vm = this
                vm.$refs.generalDialog.open(title, message, ['close']);
            },
            errorDialog(title, message) {
                let vm = this
                vm.$refs.confirmDialog.open(title, message, {
                    color: 'error',
                    cancelMsg: vm.$t('buttons.close'),
                    buttons: null,
                });
            },
        }
    }
</script>

<style lang="scss">
 .sender-config .switch {
        position: relative;
        display: inline-block;
    }

    /* Hide default HTML checkbox */
    .sender-config .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .sender-config .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        height: 22px;
        width:58px;
    }

    .sender-config .slider:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 25px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .sender-config input:checked + .slider {
        background-color: $success;
    }

    .sender-config input:focus + .slider {
        box-shadow: 0 0 1px $success;
    }

    .sender-config input:checked + .slider:before {
        -webkit-transform: translateX(25px);
        -ms-transform: translateX(25px);
        transform: translateX(25px);
    }

    .sender-config .mailTextField .v-input__control .v-input__slot{
        min-height: 22px !important;
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
    }
</style>