<template>
  <div class="workflow-execution-history-info">
    <!-- <div v-if="loading" class="workflow-execution-history-info-summary">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else> -->
      <v-layout justify-space-between align-center class="workflow-execution-history-info-summary">
        <div class="workflow-execution-history-info-date">
          <div>{{ $t('workflow.label.triggerDate') }}</div>
          <DateBlock v-bind="detailInfo" />
        </div>
        <div v-if="isFailState(detailInfo.status)" class="workflow-execution-history-info-message">
          <div>{{ $t('workflow.table.message') }}</div>
          <MessageBlock v-bind="detailInfo" />
        </div>
        <div class="workflow-execution-history-info-interval">
          <div>{{ $t('workflow.label.totalInterval') }}</div>
          <IntervalBlock v-bind="detailInfo" />
        </div>
      </v-layout>
      <v-divider />
      <div class="workflow-execution-history-info-log-header">
        {{ $t('workflow.label.executionLog') }}
      </div>
      <v-container class="workflow-table-record-main-table workflow-execution-histories">
        <vue-good-table
          :columns="headers"
          :rows="nodes"
          styleClass="vgt-table"
          :fixed-header="true"
          :sort-options="{
            enabled: false,
          }"
        >
          <div slot="emptystate" class="workflow-execution-history-status-loading-data">
            <div class="workflow-execution-history-status-loading-label">
              {{ $t('workflow.label.noRecord') }}
            </div>
          </div>
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'node'" class="workflow-execution-history-info-log-node">
              <v-icon small class="workflow-execution-history-info-log-icon" :style="iconStyle(props.row)">{{ nodeIcon(props.row.nodeType) }}</v-icon>
              {{ props.row.name || $t('workflow.label.noWorkflowNodeName') }}
            </div>
            <div v-if="props.column.field == 'status'" class="workflow-execution-history-status-step">
              <StatusBlock v-bind="props.row" set="nodeStep" />
            </div>
            <div v-if="props.column.field == 'startTime'" class="workflow-execution-history-status-time">
              <DateBlock v-bind="props.row" />
            </div>
            <div v-if="props.column.field == 'message'" class="workflow-execution-history-status-message">
            <MessageBlock v-bind="props.row" />
          </div>
            <div v-if="props.column.field == 'currentInterval'">
              <IntervalBlock v-bind="props.row" />
            </div>
          </template>
        </vue-good-table>
      </v-container>
    <!-- </div> -->
  </div>
</template>

<script>
import { map } from 'lodash';
import Mixin from './../../../../mixin';
import TableMixin from './mixin';
import InfoContent from './info';
import StatusBlock from './status';
import DateBlock from './date';
import IntervalBlock from './interval';
import MessageBlock from './message';
export default {
  mixins: [TableMixin, Mixin],
  props: {
  },
  data() {
    const lookup = ["status", "node", "message", "startTime", "currentInterval"];
    return {
      headers: map(lookup, field => ({
        label: this.$t(`workflow.table.${field}`),
        field,
      })),
    };
  },
  computed: {
    detailInfo() {
      return this.$store.getters.getEventHistoryInfo;
    },
    nodes() {
      const { items } = this.detailInfo;
      return items || [];
    }
  },

  components: {
    DateBlock,
    StatusBlock,
    InfoContent,
    IntervalBlock,
    MessageBlock,
  },
  methods: {
    loading() {
      return this.$store.getters.loadingEventHistory;
    },
    nodeIcon(nodeType) {
      return (nodeType && nodeType.icon) || 'mdi-table-large';
    },
    iconStyle({ color, nodeType={} }) {
      return {
        color: 'white',
        background: color || (nodeType && nodeType.color) || 'rgb(255, 163, 64)',
      };
    },
  },
};

</script>

<style>

</style>
