<template>
  <v-layout column align-center justify-center class="workflow-setting-form-banner" :style="bannerStyle()">
    <div class="workflow-setting-form-banner-icon">
      <v-icon :color="node.color" large>
        {{ node.icon }}
      </v-icon>
    </div>
    <div class="workflow-setting-form-banner-label">
      {{ label }}
    </div>
  </v-layout>
</template>

<script>

export default {
  props: {
    node: Object,
    label: String,
  },
  methods: {
    bannerStyle() {
      return {
        backgroundColor: this.node.color,
      };
    },
  },
};

</script>

<style scoped>
  
</style>
