<template>
    <v-card elevation="0" outlined style="border-style: dashed;">
        <v-card-text class="pb-5">
            <div class="d-flex align-center">
                <div style="font-size:14px">{{sharableTemplate.identifier}}</div>
                    <v-switch
                        v-if="!isWorkflowSetting"
                        class="mt-0 pt-0 ml-auto"
                        dense
                        hide-details
                        @change="handleActiveChanged"
                        v-model="shareLink.active"
                    >
                    </v-switch>
                    <v-switch
                        v-else="!isWorkflowSetting"
                        class="mt-0 pt-0 ml-auto"
                        dense
                        hide-details
                        @change="handleActiveChanged"
                        :input-value="enabledTemplates.includes(sharableTemplate._id)"
                    >
                    </v-switch>
            </div>
            <div class="d-flex align-center" v-if="!isWorkflowSetting">
                <div v-if="shareLink.url" style="font-size:13px; margin-left:2px" :id="`shared-link-${sharableTemplate._id}`">{{publicUrl + shareLink.url}}</div>
                <v-btn v-if="shareLink.url" @click="copyLink" id="copy-link-btn" x-small class="ml-3 mb-2" color="primary" :outlined="!copying" elevation="0">
                    <div :id="`copy-link-btn-content-${sharableTemplate._id}`">{{$t('publicEdit.copyLink')}}</div>
                </v-btn>
                <div v-else style="height:28px" class="ml-2 grey--text">一</div>
            </div>
            <div class="d-flex flex-column" v-if="!isWorkflowSetting">
                <v-checkbox
                    class="mt-2 pa-0"
                    :label="$t('publicEdit.passwordProtection')"
                    hide-details
                    dense
                    :ripple="false"
                    v-model="shareLink.havePassword"
                    @change="handleHavePasswordChanged"
                    :disabled="!shareLink.active"
                ></v-checkbox>
                <div class="ml-1" v-show="shareLink.havePassword" style="color: #b6b6b6; width:100%;font-size:12px">({{$t('publicEdit.securityReason')}})</div>
                    <div class="d-flex">
                        <div style="width:200px; padding-left:3px" v-show="shareLink.havePassword">
                            <v-text-field
                                v-show="editingPassword"
                                outlined
                                dense
                                height="29"
                                class="passwordInput"
                                style="font-size:12px"
                                v-model="passwordCache"
                                @change="handlePasswordChanged"
                                :type="passwordCache?'text':'password'"
                                :rules="editingPassword?[rules.minLength, rules.format]:[]"
                                @focus="handlePasswordFocused"
                                :disabled="!editingPassword"
                                ref="passwordInput"
                                @blur="editingPassword=false; passwordCache=''"
                            >
                            </v-text-field>
                            <div class="mt-1 d-flex align-end" style="height:30px; width:200px;border: 1px solid black" v-if="!editingPassword">
                                <div class="px-1 d-flex align-center" style="font-size: 20px;"> 
                                    <span v-for="(count,index) in shareLink.passwordLength" :key="index">
                                        *
                                    </span>
                                </div> 
                            </div>
                        </div>
                        <div class="mt-1" style="height:30px; border: 1px solid" v-if="shareLink.havePassword">
                            <div class="d-flex" style="height: 100%">
                                <div style="border-right: 1px solid black; height: 100%">
                                    <v-btn tile style="height: 100%; width:20px" elevation="0" dense x-small @click="editingPassword=true; $refs.passwordInput.$refs.input.focus()" class="">
                                        <v-icon small>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </div>
                                <div style="height: 100%">
                                    <v-btn tile style="height: 100%; width:20px" elevation="0" dense x-small @click="genPassword" class="">
                                        <v-icon small>
                                            mdi-refresh
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </v-card-text>
        <confirm-dialog ref="confirmDialog"></confirm-dialog>

        <v-snackbar
            color="primary"
            v-model="showingSnackbar"
            center
            top
            elevation="0"
            timeout="2000"
        >
            <div class="d-flex justify-center" style="font-size:14px"> {{snackbarMsg}} </div>
        </v-snackbar>
    </v-card>
</template>


<script>
    import RandExp from 'randexp'
    import confirmDialog from "@/components/dialogs/ConfirmDialog";
    const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,32}$/;
    export default {
        components:{
            confirmDialog
        },
        props:{
            sharableTemplate: Object,
            shareLink: {
                Type: Object,
                default: {}
            },
            isWorkflowSetting:{
                type: Boolean,
                default: false
            },
            enabledTemplates:{
                type: Array,
                default: []
            }
        },
        data(){
            return {
                copying: false,
                showPassword:false,
                passwordCache: null,
                showingConfirmDialog: false,
                rules: {
                    minLength: value => value.length >= 8 && value.length <= 32 || this.$t('publicEdit.passwordLength'),
                    format: value => PASSWORD_REGEX.test(value)===true || this.$t('publicEdit.passwordRule')
                },
                showingSnackbar: false,
                snackbarMsg: '',
                editingPassword: false
            }
        },
        computed:{
            publicUrl(){
                return window.location.origin + "/public/edit/"
            }
        },
        methods:{
            copyLink(){
                this.copyToClipboard()
                const copyBtnId = `copy-link-btn-content-${this.sharableTemplate._id}`
                document.getElementById(copyBtnId).innerHTML = this.$t('publicEdit.copied') + " !"
                this.copying = true
                setTimeout(()=>{
                    document.getElementById(copyBtnId).innerHTML = this.$t('publicEdit.copyLink')
                    this.copying = false
                }, 2000)
            },
            copyToClipboard(){
                const range = document.createRange()
                const shareLinkDivId = `shared-link-${this.sharableTemplate._id}`
                range.selectNode(document.getElementById(shareLinkDivId))
                window.getSelection().removeAllRanges()
                window.getSelection().addRange(range)
                document.execCommand("copy")
                window.getSelection().removeAllRanges()
            },
            genPassword(){
                this.shareLink.password  = this.getNewPassword()
                this.editingPassword = true
                this.$nextTick(()=>{
                    this.$refs.passwordInput.$refs.input.focus()
                })
                this.showPassword = true
                this.passwordCache = this.shareLink.password
                this.handlePasswordChanged(this.shareLink.password)
            },
            handleActiveChanged(val){
                if(this.isWorkflowSetting){
                    this.$emit('updateShareableSettings', this.sharableTemplate._id)
                    return
                }
                let vm = this
                if(val===false){
                    vm.$refs.confirmDialog
                        .open(vm.$t("general.warning"), vm.$t("messages.shareLinkWillBeInvalid"), {
                            color: "error",
                            confirmBtnColor: "error",
                            cancelBtnColor: "muted",
                            confirmMsg: this.$t("buttons.abandon"),
                            cancelMsg: this.$t("buttons.cancel"),
                        })
                        .then((result) => {
                        if (result) {
                            this.$emit('onCommand', {
                                command: 'save',
                                data: {
                                    templateId: this.sharableTemplate._id,
                                    key: 'active',
                                    value: val
                                }
                            })
                        } else {
                            this.shareLink.active = true
                        }
                    });
                }else{
                    this.$emit('onCommand', {
                        command: 'save',
                        data: {
                            templateId: this.sharableTemplate._id,
                            key: 'active',
                            value: val
                        }
                    })
                }
            },
            getNewPassword(){
                var newPassword = new RandExp(PASSWORD_REGEX).gen()
                while(!PASSWORD_REGEX.test(newPassword)){
                    newPassword = new RandExp(PASSWORD_REGEX).gen()
                }
                return newPassword
            },
            handleHavePasswordChanged(val){
                if(val){
                    this.shareLink.password  = this.getNewPassword()
                    this.editingPassword = true
                    this.$nextTick(()=>{
                        this.$refs.passwordInput.$refs.input.focus()
                    })
                    this.passwordCache = this.shareLink.password
                    this.handlePasswordChanged(this.shareLink.password)
                }
                this.$emit('onCommand', {
                    command: 'save',
                    data: {
                        templateId: this.sharableTemplate._id,
                        key: 'havePassword',
                        value: val
                    }
                })
            },
            handlePasswordChanged(val){
                console.log('handlePasswordChanged::val', val)
                console.log('handlePasswordChanged::passwordCache', this.passwordCache)
                let vm = this
                if(PASSWORD_REGEX.test(val)){
                    vm.passwordCache =  val
                    vm.$emit('onCommand', {
                        command: 'save',
                        data: {
                            templateId: vm.sharableTemplate._id,
                            key: 'password',
                            value: val,
                            cb: vm.handleSavedCallBack()
                        }
                    })
                }
            },
            handleSavedCallBack(){
                this.snackbarMsg = this.$t('messages.passwordSaved')
                this.showingSnackbar = true
            }
            
        }
    }
</script>
