<template>
  <div>
    <v-navigation-drawer
      :disable-resize-watcher="true"
      ref="recordDrawer"
      v-model="showDrawer"
      @input="$emit('close', $event)"
      absolute
      right
      :temporary="!showingDialog && !showingBatchInsertDialog"
      :style="[{ width: bl_fullScreen ? '100%' : drawerWidthPercent }]"
      class="elevation-0 record-drawer fill-height"
      transition="scroll-x-transition"
    >
      <!-- <div v-if="showDrawer" class="d-flex flex-row fill-height">
        <div
          class="flex-grow-0 grey lighten-3 align-center justify-center" 
          style="width:30px;cursor:col-resize;"
          @mousedown="startDrag">
          @mousemove="doDrag">
            Center
        </div> -->

      <div
        v-if="showDrawer"
        class="flex-grow-1 d-flex flex-column fill-height"
        style
      >
        <div
          class="flex-grow-0 d-flex flex-row justify-space-between primary align-center pa-2"
        >
          <div class="white--text" style="font-size:16px;">
            <v-btn icon plain @click="changeDrawerWidth">
              <v-icon v-if="!bl_fullScreen" color="white"
                >mdi-arrow-expand-left</v-icon
              >
              <v-icon v-else color="white">mdi-arrow-expand-right</v-icon>
            </v-btn>
          </div>

          <div
            class="d-flex align-end white--text font-weight-bold"
            style="font-size:16px"
          >
            <v-icon dark>{{ drawerIcon }}</v-icon>
            <span class="ml-2">{{ drawerTitle }}</span>
          </div>
          <!-- <div style="mx-5">
              <v-slider
                v-model="panelWidth"
                thumb-label
              ></v-slider>
            </div> -->
          <div class="white--text" style="font-size:16px;">
            <!-- <v-btn @click="bl_addChartDialog = true" icon plain color="white" v-if="isAdmin">
                        <v-icon>mdi-plus</v-icon>
              </v-btn>-->
            <v-btn icon plain @click="bl_fullScreen = !bl_fullScreen">
              <v-icon v-if="bl_fullScreen" color="white"
                >mdi-arrow-collapse-all</v-icon
              >
              <v-icon v-else color="white">mdi-arrow-expand-all</v-icon>
            </v-btn>
            <v-btn icon plain @click="close">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="flex-grow-0">
          <div
            class="d-flex flex-row justify-start align-center grey lighten-2"
          >
            <div class="flex-grow-0">
              <v-tabs
                :hide-slider="true"
                active-class="active-tab"
                class="grey lighten-2 cf-table-tabs"
                v-model="selectedTabIndex"
              >
                <v-tab
                  v-for="fieldInfo in crTableFieldInfos"
                  :key="fieldInfo.fieldId"
                >
                  {{ fieldInfo.label }}
                </v-tab>
              </v-tabs>
            </div>
            <tab-menu class="flex-grow-0" :tabs="crTableFieldInfos"></tab-menu>
          </div>
        </div>
        <div class="flex-grow-1 px-3 pb-3">
          <div style="height:0;min-height:100%;overflow-y:auto;">
            <div v-if="recordData._id && isChildTable">
              <v-row no-gutters class="mt-2">
                <v-spacer />
                <v-btn color="primary" v-if="!getIsReadOnly(activeFieldInfo)" rounded @click="save">Save</v-btn>
              </v-row>
              <input-widget-child-table
                ref="childTable"
                :isNew="false"
                :hideLabel="true"
                stretch="true"
                :recordData="recordData"
                :relatedTableData="relatedTableData"
                :relatedTableInfos="relatedTableInfos"
                :fieldInfo="activeFieldInfo"
                :activeForm="formInfo"
                v-model="recordData[activeFieldInfo.fieldId]"
                :isReadonly="getIsReadOnly(activeFieldInfo)"
                :rowHeightClass="rowHeightClass"
                @onCommand="onCommandHandler"
              ></input-widget-child-table>
            </div>
            <input-widget-related-record
              v-else-if="recordData._id && isRelatedRecord"
              ref="relatedRecord"
              :isNew="false"
              :hideLabel="true"
              stretch="true"
              :inRecordDrawer="true"
              :recordData="recordData"
              :relatedTableData="relatedTableData"
              :relatedTableInfos="relatedTableInfos"
              :fieldInfo="activeFieldInfo"
              :activeForm="formInfo"
              :value="recordData ? recordData[fieldInfo.fieldId] : []"
              :rowHeightClass="rowHeightClass"
              :isReadonly="getIsReadOnly(activeFieldInfo)"
              @onCommand="onCommandHandler"
            ></input-widget-related-record>
          </div>
        </div>
        <div
          v-if="displayableFieldInfos.length > 0"
          class="flex-grow-0 mb-1"
          style="max-height:200px;overflow-y:auto;"
        >
          <v-container fluid>
            <v-row justify="left">
              <v-col cols="12" class="grey lighten-4 py-1">
                {{ $t("view.summaryField") }}
              </v-col>
            </v-row>
            <v-row class="my-0" justify="center">
              <v-col
                :cols="colWidth"
                v-for="fieldInfo in displayableFieldInfos"
                :key="fieldInfo._id"
              >
                <input-widget
                  :ref="fieldInfo.fieldId"
                  :key="fieldInfo._id"
                  :fullWidth="true"
                  :recordData="recordData"
                  :relatedTableData="relatedTableData"
                  :relatedTableInfos="relatedTableInfos"
                  :fieldInfo="fieldInfo"
                  :formInfo="formInfo"
                  @onCommand="onCommandHandler"
                ></input-widget>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <!-- </div> -->
    </v-navigation-drawer>
    <!-- <v-overlay v-if="dragging" style="z-index:1000;background-color:transparent;`">
      <div style="position:fixed;left:0;top:0;width:100%;height:100%;background-color:transparent;"
            @mousemove="doDrag">sfdsfdsfsd</div></v-overlay> -->

    <popup-record-dialog
      ref="popupRecordDialog"
      @onCommand="onCommandHandler"
    ></popup-record-dialog>
    <child-record-dialog
      ref="childRecordDialog"
      @onCommand="onCommandHandler"
    ></child-record-dialog>
    <batch-insert-dialog  
      v-model="showingBatchInsertDialog"
      v-if="showingBatchInsertDialog"
      :settings="batchInsertSettings"
      @onCommand="onCommandHandler"
    />
  </div>
</template>

<script>
import recordMixin from "@/mixins/recordMixin";
import inputWidget from "@/components/inputWidgets/InputWidget";
import inputWidgetRelatedRecord from "@/components/inputWidgets/InputWidgetRelatedRecord";
import inputWidgetChildTable from "@/components/inputWidgets/InputWidgetChildTable";
import popupRecordDialog from "@/components/dialogs/PopupRecordDialog";
import childRecordDialog from "@/components/dialogs/ChildRecordDialogPublic";
import tabMenu from "@/components/TabMenu";
import batchInsertDialog from "@/components/dialogs/BatchInsertDialog";

export default {
  name: "recordDrawer",
  mixins: [recordMixin],
  components: {
    inputWidget,
    inputWidgetRelatedRecord,
    inputWidgetChildTable,
    popupRecordDialog,
    childRecordDialog,
    tabMenu,
    batchInsertDialog
  },
  props: {
    showDrawer: Boolean,
    formInfo: Object,
    recordId: String,
    fieldInfo: Object,
    rowHeightClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tab: undefined,
      bl_fullScreen: false,
      bottom: false,
      drag: false,
      recordData: {},
      selectedTabIndex: 0,
      drawerWidth: 40,
      showingBatchInsertDialog: false,
      batchInsertSettings: {}
    };
  },
  computed: {
    relatedTableInfos() {
      return this.$store.getters.relatedTableInfos;
    },
    viewableFieldInfos() {
      const vm = this;
      var result = [];
      for (let i = 0; i < vm.fieldInfos.length; i++) {
        const loopFieldInfo = vm.fieldInfos[i];
        if (loopFieldInfo.canView) {
          result.push(loopFieldInfo);
        }
      }
      return result;
    },
    activeTableFieldInfo() {
      const vm = this;
      return vm.crTableFieldInfos[vm.selectedTabIndex];
    },
    relatedSummaryFieldInfos() {
      const vm = this;
      var result = [];
      for (let i = 0; i < vm.viewableFieldInfos.length; i++) {
        const loopFieldInfo = vm.viewableFieldInfos[i];
        if (
          loopFieldInfo.type === "summaryField" &&
          loopFieldInfo.properties.tableField ===
            vm.activeTableFieldInfo.fieldId
        ) {
          result.push(loopFieldInfo);
        }
      }
      return result;
    },

    relatedExpressionFieldInfos() {
      const vm = this;
      var result = [];

      const summaryFieldIds = vm.relatedSummaryFieldInfos.map(
        (info) => info.fieldId
      );

      for (let i = 0; i < vm.viewableFieldInfos.length; i++) {
        const loopFieldInfo = vm.viewableFieldInfos[i];

        if (loopFieldInfo.type === "expression") {
          switch (loopFieldInfo.properties.calculationMethodNumber) {
            case "custom":
              const str = loopFieldInfo.properties.customNumberFormula;

              if (vm.doesStringIncludeSomeIds(str, summaryFieldIds)) {
                result.push(loopFieldInfo);
              }
              break;
            default:
              if (
                vm.doesFieldIdsInlcudeSomeIds(
                  loopFieldInfo.properties.selectFields,
                  summaryFieldIds
                )
              ) {
                result.push(loopFieldInfo);
              }
          }
        }
      }
      return result;
    },

    displayableFieldInfos() {
      return [
        ...this.relatedSummaryFieldInfos,
        ...this.relatedExpressionFieldInfos,
      ];
    },

    colWidth() {
      const vm = this;
      var result = 12;
      switch (vm.drawerWidth) {
        case 40:
          result = 6;
          break;
        case 80:
          result = 3;
          break;
      }
      return result;
    },
    drawerWidthPercent() {
      return this.drawerWidth + "%";
    },
    // child table / related-record table
    //
    activeFieldInfo() {
      return this.crTableFieldInfos
        ? this.crTableFieldInfos[this.selectedTabIndex]
        : null;
    },

    crTableFieldInfos() {
      const vm = this;
      return this.fieldInfos.filter(
        (info) =>
          info.type === "childTable" ||
          info.properties.displayFormat === "table"
      );
    },
    fieldInfos() {
      return this.formInfo ? this.formInfo.fieldInfos : [];
    },
    isChildTable() {
      return this.activeFieldInfo
        ? this.activeFieldInfo.type === "childTable"
        : false;
    },
    isRelatedRecord() {
      return this.activeFieldInfo
        ? this.activeFieldInfo.type === "relatedRecord"
        : false;
    },
    drawerIcon() {
      return this.isChildTable ? "mdi-table" : "mdi-link-variant";
    },
    titleFieldInfo() {
      return this.formInfo
        ? this.formInfo.fieldInfos.find(
            (info) => info._id === this.formInfo.titleFieldInfoId
          )
        : null;
    },
    titleFieldId() {
      return this.titleFieldInfo ? this.titleFieldInfo.fieldId : "";
    },
    drawerTitle() {
      const vm = this;
      var result = "";
      if (vm.titleFieldId) {
        result = vm.recordData[vm.titleFieldId];
      }
      return result;
    },
    userId() {
      return this.$store.getters.activeEmployeeId;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },

    appId() {
      return this.$store.getters.currentForm.appId;
    },
    formId() {
      return this.formInfo._id;
    },
  },
  watch: {
    showDrawer(val) {
      const vm = this;
      if (val) {
        vm.init();
        vm.loadRecord(vm.recordId, vm.formInfo._id, vm.formInfo.appId, "");
      } else {
        if (vm.isChildTable) vm.$refs.childTable.clear();
        if (vm.relatedRecord) vm.$refs.relatedRecord.clear();
        vm.recordData = {};
        vm.drawerWidth = 40;
        vm.bl_fullScreen = false;
      }
    },
  },

  methods: {
    doesFieldIdsInlcudeSomeIds(fieldIds, findFieldIds) {
      console.log("doesFieldIdsInlcudeSomeIds: fieldIds: ", fieldIds);
      console.log("doesFieldIdsInlcudeSomeIds: findFieldIds: ", findFieldIds);
      var result = false;
      for (let i = 0; i < findFieldIds; i++) {
        const targetFieldId = findFieldIds[i];
        if (fieldIds.includes(targetFieldId)) {
          result = true;
          break;
        }
      }
      return result;
    },

    doesStringIncludeSomeIds(str, fieldIds) {
      var result = false;
      console.log("doesStringIncludeSomeIds: str = ", str);
      console.log("doesStringIncludeSomeIds: fieldIds: " + fieldIds);

      for (let i = 0; i < fieldIds.length; i++) {
        const loopFieldId = fieldIds[i];
        if (str.indexOf(loopFieldId) >= 0) {
          result = true;
          break;
        }
      }
      return result;
    },

    getFieldIdsFromExpression(str) {
      let regex = /\$\{\w+\}/g;
      var result = [];
      let arr = [...customNumberFormula.matchAll(regex)];
      arr.forEach((item) => {
        console.log("getFieldIdsFromExpress: item: ", item);
        result.push(item);
        // let id = item[0].match(/\w+/)[0];
        // if (idMapping.hasOwnProperty(id))
        //   customNumberFormula = customNumberFormula.replace(
        //     id,
        //     idMapping[id]
        //   );
      });
      console.log("getFieldIdsFromExpress: result:", result);
      return result;
    },
    changeDrawerWidth() {
      const vm = this;
      console.log("changeDrawerWidth : vm.bl_fullScreen = " + vm.bl_fullScreen);
      console.log("1 changeDrawerWidth : vm.drawerWidth = " + vm.drawerWidth);
      if (vm.bl_fullScreen) {
        vm.bl_fullScreen = false;
      } else {
        vm.drawerWidth += 40;
        console.log("2 changeDrawerWidth : vm.drawerWidth = " + vm.drawerWidth);

        if (vm.drawerWidth > 100) {
          vm.drawerWidth = 40;
          vm.bl_fullScreen = true;
        }
      }
      console.log("changeDrawerWidth : vm.bl_fullScreen = " + vm.bl_fullScreen);
      console.log("3 changeDrawerWidth : vm.drawerWidth = " + vm.drawerWidth);
    },

    openChildRecordDialog(payload) {
      this.showingDialog = true;
      this.$refs.childRecordDialog.open(payload);
    },

    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "newRelatedRecord":
          vm.newRelatedRecord(payload);
          // alert('onCommandHandler :: newRelatedRecord')
          break;
        case "selectRecord":
        vm.editRelatedRecord({
          fieldLabel:this.fieldInfo.label,
          recordId: payload.recordId,
          formInfo: payload.form
        });
          break;
        case "editRelatedRecord":
          vm.editRelatedRecord(payload);
          break;
        case "updateFieldValue":
          vm.updateFieldValue(payload);
          break;
        case "addChildRow":
          vm.addChildRow(payload);
          break;
        case "unlinkRelatedRecord":
          vm.unlinkRelatedRecord(payload);
          break;
        case "openChildRecordDialog":
          vm.openChildRecordDialog(payload);
          break;
        case "closeDialog":
          vm.showingDialog = false;
          break;
        case "openBatchInsertDialog":
          this.batchInsertSettings = payload.data
          this.showingBatchInsertDialog = true
        break;
        case "batchInsert":
          var updateFieldComponent = this.$refs.relatedRecord
          this.$set(this.recordData, payload.fieldId, (this.recordData[payload.fieldId]||[]).concat(payload.newSelection))
          updateFieldComponent.onSaved(payload.dataSource, payload.newSelection, null)
        break;
      }
    },
    init() {
      const vm = this;
      vm.selectedTabIndex = vm.crTableFieldInfos.findIndex(
        (info) => info.fieldId === vm.fieldInfo.fieldId
      );
    },

    newRelatedRecord(payload) {
      this.showingDialog = true;
      this.$refs.popupRecordDialog.open(payload);
    },
    editRelatedRecord(payload) {
      this.showingDialog = true;

      this.$refs.popupRecordDialog.open(payload);
    },
    getIsReadOnly(fieldInfo) {
      const vm = this;
      var result = true;
      if (
        vm.recordData &&
        vm.fieldInfo &&
        vm.recordData.canEdit &&
        fieldInfo.canEdit
      ) {
        result = false;
      }
      return result;
    },

    checkMove({ relatedContext, draggedContext }) {
      this.swapHandler({
        group: this.tab,
        from: draggedContext.element._id,
        to: relatedContext.element._id,
      });
    },
    onScroll(e) {
      // console.log('scroll', e)
      const { target } = e;
      if (
        Math.ceil(target.scrollTop) >=
        target.scrollHeight - target.offsetHeight
      ) {
        this.bottom = true;
      }
    },
    refresh() {
      this.loadRecord(this.recordId);
    },

    close() {
      this.showDrawer = false;
    },
    save() {
      this.$refs.childTable.refreshing = true;
      const postData = {
        data: this.recordData,
        isNew: false,
      };
      this.$store.dispatch("SAVE_DATA", postData).then(() => {
        this.$toast.success(this.$t("messages.savedSuccessfully"), {
          position: "top-right",
          dismissible: true,
        });
        this.$refs.childTable.refreshing = false;
      }).catch(error=>{
        this.$toast.error(this.$t("errors.save"))
        this.$refs.childTable.refreshing = false;
      });
    },
  },
};
</script>

<style lang="scss">
.record-drawer {
  z-index: 4;
  top: 48px !important;
  bottom: 0px !important;
  height: auto !important;
}
.formchart-tabs.v-tabs {
  flex: unset;
}

.chartdrawer-ghost {
  opacity: 0 !important;
}
.theme--light.sortable-chosen[draggable="true"] {
  background-color: white !important;
}
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.cf-table-tabs .v-tab.active-tab {
  background-color: white;
  color: black;
}
.cf-table-tabs .v-tab:not(.active-tab) {
  color: lightgrey;
}
</style>
