<template>
    <div class="edit-signature-modal">
        <div class="d-flex justify-start align-center">
            <img 
              style="height: 100%; width:80px" 
              v-if="params.row[params.column.property]" 
              :src="mediaSrc" 
              class="signature-image"
            />
            <div :class="(params.row[params.column.property])?'ml-auto':''" class="d-flex" v-if="!readonly && params.row.canEdit" style="overflow: hidden">
                <dialog-button-signature
                  :value="params.row[params.column.property]"
                  @input="val=>params.row[params.column.property]= val"
                  ref="dialogButtonMap"
                  :attach="attach"
                  :cssSelect="cssSelect"
                  :readonly="readonly"
                  v-on:updateShowingDialog="updateShowingDialog"
                  :formInfo="formInfo"
                >
                </dialog-button-signature>
                <v-btn
                  class="elevation-0"
                  :disabled="readonly"
                  x-small
                  fab
                  color="error"
                  @click="params.row[params.column.property]=''"
                  v-if="params.row[params.column.property]"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </div>
        </div>
    </div> 
</template>

<script>
import dialogButtonSignature from '@/components/dialogButtons/DialogButtonSignature'
export default {
  name: 'EditSignatureModal',
  components : {
    dialogButtonSignature
  },
  props:{
    params: Object,
    readonly: Boolean,
    formInfo: Object
  },
  methods:{
    updateShowingDialog(val){
      this.$emit('updateTdClass', val)
    }
  },
  computed:{
    attach(){
        return 'tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex
    },
    cssSelect(){
      var path = '#tv-r' + this.params.rowIndex + 'c' + this.params.columnIndex
      if(this.params.fixed!= undefined){
        if(document.querySelector('.vxe-table--fixed-left-wrapper')){
          return '.vxe-table--fixed-left-wrapper' + ' ' + path
        }
      }
      return path
    },
    mediaSrc(){
      if(this.params.row[this.params.column.property]){
        return this.params.row[this.params.column.property].thumbnailUrl;
        // return this.$store.getters.appHost + "/medias/" + this.params.row[this.params.column.property] + "/thumbnail"
      }else return null
    }
  },
  data(){
    return {
      showingDialog: false
    }
  },
};
</script>
<style lang="scss">
.edit-signature-modal {
  .signature-wrapper {
    height: auto;
    padding: 0;
  }
}
</style>
