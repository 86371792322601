<template>
<div class="text-left line-height-1">
  <v-chip small
          class="ma-2"
          color="primary">
    <v-avatar left>
      <v-icon>mdi-account-circle</v-icon>
    </v-avatar>
    {{ name }}
  </v-chip>
</div>
</template>

<script>
export default {
  props: {
    name: String
  }
}
</script>
