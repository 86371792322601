const mixin = {
  data () {
    return {
      loading: false,
      userPanelSettings: null
    }
  },
  model: {
    prop: 'panelSettings',
    event: 'input'  
  },
  props: {
    panelSettings: Object
  },
  watch: {    
    'panelSettings._id': function(newVal) {
      this.init()
    },
    appId: function(newVal) {
      if (typeof this.loadSettings === 'function') {
        this.loadSettings()
      }      
    },
    teamId: function (newVal) {
      if (typeof this.loadSettings === 'function') {
        this.loadSettings()
      }
    }
  },
  computed: {
    appId () {
      return this.$store.getters.currentApp ?
        this.$store.getters.currentApp._id :
        ''
    },
    teamId () {
      return this.$store.getters.activeTeam ?
        this.$store.getters.activeTeam._id :
        ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.userPanelSettings = JSON.parse(JSON.stringify(this.panelSettings))
    },
  }
}

export default mixin