<template>
  <div>
    <h5 v-if="templateData.showTeamName" class="teamName">{{ teamName }}</h5>
    <div
      v-if="templateData.showLogo || templateData.showFormName"
      class="titleContent clearfix mb-25"
    >
      <span class="logo">
        <div v-if="templateData.showLogo">
          <img
            v-if="templateData.logoUrl && templateData.logoUrl !== ''"
            :src="templateData.logoUrl"
            class="templateLogo"
          />
          <img v-else-if="teamLogo" :src="teamLogo" class="templateLogo" />
          <div
            v-else
            class="hide"
            style="height: 60px; width: 127px; border: 1px dotted grey"
          >
            <h2 class="grey--text">LOGO</h2>
          </div>
        </div>
      </span>
      <span class="formName">
        <div v-if="templateData.showFormName">
          {{ templateData.formName }}
        </div>
      </span>
      <span class="logo"></span>
    </div>
    <div v-if="hasHeaderInfo">
      <span class="font-weight-bold" v-if="templateData.showTitle">
        {{ record[titleFieldId] }}
      </span>
      <div class="systemInfo mt-10">
        <span v-if="templateData.showOwner">
          {{ $t("widgets.owner") }}: {{ record.owner || "" }}</span
        >
        <span v-if="templateData.showCreatedBy">
          {{ $t("widgets.createdBy") }}:
          {{ record.createdBy || $t("publicForm.publicForm") }}</span
        >
        <span v-if="templateData.showCreatedAt">
          {{ $t("widgets.createdAt") }}: {{ record.createdAt }}</span
        >
        <span v-if="templateData.showUpdatedAt">
          {{ $t("widgets.updatedAt") }}: {{ record.updatedAt }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { formatDateTime } from "@/helpers/DataHelpers";
export default {
  props: {
    templateData: Object,
    record: Object,
  },
  computed: {
    form() {
      return this.$store.getters.currentForm;
    },
    teamName() {
      return (this.form && this.form.team && this.form.team.name) || "";
    },
    teamLogo() {
      return (this.form && this.form.team && this.form.team.logo) || "";
    },
    hasHeaderInfo() {
      return (
        this.templateData.showTitle ||
        this.templateData.showOwner ||
        this.templateData.showUpdatedAt ||
        this.templateData.showCreatedBy ||
        this.templateData.showCreatedAt
      );
    },
    titleFieldId() {
      return this.form.titleFieldInfoId;
    },
  },
  methods: {
    formatDateTime(date) {
      return formatDateTime(date);
    },
  },
};
</script>

<style>
img.templateLogo {
  height: 60px;
  /* aspect-ratio: 1; */
}
</style>
