<template>
  <div class="workform-clip-card-container">
    <v-layout align-center class="workform-clip-card">
      <div v-if="onlyPrefix" class="workform-clip-source">
        <v-icon small class="workform-clip-source-icon">
         {{ item.icon || 'mdi-tune-vertical' }}
        </v-icon>
        <span>
          {{ label }}
        </span>
      </div>
      <div v-if="onlyPrefix && onlySuffix" class="workform-clip-arrow1" />
      <div v-if="onlyPrefix && onlySuffix" class="workform-clip-arrow2"
      />
      <div v-if="onlySuffix" class="workform-clip-card-label workform-clip-card-blue" :class="{
        'workform-clip-card-label-signle': !onlyPrefix && onlySuffix,
      }" :style="{ background: $vuetify.theme.themes.light.primary }">
        <span v-if="item.isSystem">
          {{  renderLabel ? renderLabel(item, true) : selectionLabel }}
        </span>
        <span v-else>
          {{ $t(`widgets.${item.fieldId}`) }}
        </span>
      </div>
    </v-layout>
  </div>
</template>

<script>

  import SettingUtils from '@/pages/admin/workflow/utils/setting';
  export default {
    props: {
      item: Object,
      onlyPrefix: Boolean,
      onlySuffix: Boolean,
      renderLabel: Function,
    },
    computed: {
      label() {
        return SettingUtils.delegatedComboLabel(this.item, this.$t('workflow'));
      },
      selectionLabel() {
        return SettingUtils.delegatedComboSelectionLabel(this.item, this.$t('workflow'));
      },
    }
  }
</script>

<style>
</style>
