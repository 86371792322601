var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-0 d-flex pl-2",staticStyle:{"height":"30px","min-height":"30px"},attrs:{"hide-actions":""}},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t('chart.styleConfig.columnSummary'))+" ")])]),_c('v-expansion-panel-content',[_c('div',{},[_c('div',{staticClass:"d-flex px-2 mb-1"},[_c('v-btn',{attrs:{"outlined":"","plain":"","icon":"","small":"","color":"teal"},on:{"click":_vm.addFooter}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)],1)]),_c('div',{staticClass:"d-flex flex-column"},[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"tag":"ul","handle":".ChartTableFooterSetupHandle"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.$emit('onCommandHandler',{
                        command: 'update_tableFooters',
                        value: _vm.setup.tableFooters
                    })}},model:{value:(_vm.setup.tableFooters),callback:function ($$v) {_vm.$set(_vm.setup, "tableFooters", $$v)},expression:"setup.tableFooters"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.setup.tableFooters),function(footer,index){return _c('div',{key:footer.order,staticClass:"list-group-item"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('div',{staticClass:"d-flex py-1"},[_c('div',{staticClass:"ChartTableFooterSetupHandle"},[_c('v-icon',[_vm._v(" mdi-drag ")])],1),_c('v-text-field',{staticClass:"footerSummarySetup",staticStyle:{"font-size":"13px"},attrs:{"hide-details":"","height":"25","outlined":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                                            command: 'update_tableFooters',
                                            value: _vm.setup.tableFooters
                                        })}},model:{value:(footer.name),callback:function ($$v) {_vm.$set(footer, "name", $$v)},expression:"footer.name"}}),_c('v-select',{staticStyle:{"width":"80px","font-size":"13px"},attrs:{"outlined":"","items":_vm.calMethods,"item-text":"label","menu-props":{offsetY: true, contentClass:'denseList'},"height":"25","hide-details":""},on:{"change":function($event){return _vm.$emit('onCommandHandler',{
                                            command: 'update_tableFooters',
                                            value: _vm.setup.tableFooters
                                        })}},model:{value:(footer.value),callback:function ($$v) {_vm.$set(footer, "value", $$v)},expression:"footer.value"}}),_c('div',{staticStyle:{"width":"20px"}},[(hover)?_c('v-icon',{attrs:{"color":"red","size":"20"},on:{"click":function($event){return _vm.deleteFooter(index)}}},[_vm._v(" mdi-close ")]):_vm._e()],1)],1)]}}],null,true)})],1)}),0)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }