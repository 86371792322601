const mixin = {
  data () {
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0
      },
      searchValue: '',
      selectRow: null,
      selectColumn: null,  
    }
  },
  computed: {
    proxyConfig () {
      const vm = this
      let result = {
        seq: true,
        sort: true,
        filter: true,
        form: false,
        autoLoad: true,
        props: {
          result: 'result',
          total: 'page.total'
        },
        ajax: {
          query: ({page, sort}) => this.ajaxLoadData(page, sort),
          delete: this.ajaxDeleteData,
          save: this.ajaxSaveData
        }
      }
      if (vm.isQuickInsertMode) {
        result = {}
      }
      return result
    },
    gridOptions () {
      const vm = this
      return {
        // id: 'vxeGrid',
        round: true,
        height: 'auto',
        autoResize: true,
        border: true,
        resizable: true,
        
        showHeaderOverflow: false,
        showOverflow: false,
        showFooter: true,
        highlightHoverRow: true,
        highlightHoverColumn: true,
        highlightCurrentRow: true,

        keepSource: true,
        stripe: true,
        rowId: '_id',
        customConfig: {
          storage: true,
          checkMethod: this.checkColumnMethod
        },
        sortConfig: {
          trigger: 'cell',
          remote: true
        },
        filterConfig: {
          remote: true
        },
        pagerConfig: {
          border: true,
          background: true,
          perfect:true,
          size: 'small',
          pageSize: 20,
          pageSizes: [10, 15, 20, 50, 100],
          layouts: ['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total'],
          slots : {
            left: 'pager_left'
          }
        },
        formConfig: {
          titleWidth: 100,
          titleAlign: 'center',
          items: []
        },        
        proxyConfig: vm.proxyConfig,
        importConfig: {},
        exportConfig: {},
        checkboxConfig: {},
      }
    },
    colDefs () {
      const vm = this
      var result = []
      // console.log('computed : colDefs')
      // console.log('computed(colDefs) fixedFieldInfos: ', vm.fixedFieldInfos)
      // console.log('computed(colDefs) nonFixedFieldInfos: ', vm.nonFixedFieldInfos)

      vm.setColDefsGroup(result, vm.fixedFieldInfos, true /* fixed */)
      vm.setColDefsGroup(result, vm.nonFixedFieldInfos, false /* not fixed */)
      return result
    }
  },
  created () {
    const vm = this
    if (vm.tableType === 'master') {
      window.addEventListener('resize', vm.onWindowResized)
    }
  },
  beforeDestroy () {
    const vm = this
    if (vm.tableType === 'master') 
      window.removeEventListener('resize', vm.onWindowResized)
  },
  methods: {
    emptyTable (vxeTable, callback) {
      const vm = this
      vm.tableData.length = 0
      vm.colDefs.length = 0
      vm.$nextTick(() => {
        vxeTable.refreshColumn().then(() => {
          vxeTable.refreshScroll()
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
    },

    getFieldTitle (fieldInfo) {
      const vm = this
      var result = '(' + vm.$t('general.unspecified') + ')'
      if (fieldInfo.properties) {
        if (fieldInfo.properties.fieldNameTag && fieldInfo.properties.fieldNameTag !== '') {
          result = vm.$t(fieldInfo.properties.fieldNameTag)
        } else if (fieldInfo.properties.fieldName && fieldInfo.properties.fieldName !== '') {
          result = fieldInfo.properties.fieldName
        }
      }
      return result
    },  

    onWindowResized () {
      // const vm = this
      // if (vm.tableType === 'master'){
      //   vm.gridOptions.height = window.innerHeight - 214
      // }
    },

    ucFirst (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    setColDefsGroup (result, fieldInfos, isFixed) {
      const vm = this
      if (fieldInfos) {
        var options = {}
        if (typeof vm.getTableOptions === 'function') {
          options = vm.getTableOptions()
        }      
        for (var i = 0; i < fieldInfos.length; i++) {
          var componentName = ''
          var align = 'left'
          var width = 0
          var editRender = ''
          const loopFieldInfo = fieldInfos[i]
          if (this.$route.name !== 'PublicQuery' && !loopFieldInfo.canView) continue;
          // console.log('setColDefsGroup :: loopFieldInfo.type = ' + loopFieldInfo.type)
          switch (loopFieldInfo.type) {
            case 'members':
            case 'departments':
            case 'relatedRecord':
               componentName = 'Cell' + vm.ucFirst(loopFieldInfo.type);
              break
            case 'amount':
              align = 'right'
              componentName = 'Cell' + vm.ucFirst(loopFieldInfo.type)
              break
            case 'number':
              align = (loopFieldInfo.decimals === '') ? 'center' : 'right'
              componentName = 'Cell' + vm.ucFirst(loopFieldInfo.type)
              break
            case 'date':
            case 'yesNo':
              align = 'center'
              componentName = 'Cell' + vm.ucFirst(loopFieldInfo.type)
              break
            case 'attachments':
            case 'singleSelection':
            case 'multipleSelection':
              componentName = 'Cell' + vm.ucFirst(loopFieldInfo.type)
              break
            case 'expression':
              if (loopFieldInfo.properties.expressionType === 'number') {
                align = 'right'
               }
               componentName = 'Cell' + vm.ucFirst(loopFieldInfo.type)
              break
            case 'text':
            case 'email':
            case 'phone':
            case 'region':
            case 'rating':
            case 'richText':              
            case 'location':
            case 'signature':
            case 'idCard':
            case 'textCombination':
            case 'otherTableField':
            case 'capitalizedAmount':
              componentName = 'Cell' + vm.ucFirst(loopFieldInfo.type)
              break
            case 'owner':
            case 'createdBy':
              align = 'center'
              componentName = 'CellPerson'
              break
            case 'createdAt':
            case 'updatedAt':
              align = 'center'
              componentName = 'CellDateTime'
              break
            case 'childTable':
              align = 'left'
              componentName = 'CellChildTable'
              break  
            case 'summaryField':
              componentName = 'CellSummaryField'
              align = 'right'
              break
            case 'colorPicker':
              componentName = 'CellColorPicker'
              break
            case 'period':
              componentName = 'CellPeriod'
              break
          }
          width = 200
          const newColDef = {
            title: vm.getFieldTitle(loopFieldInfo),
            width: width,
            minWidth: 100,
            align: align,
            fixed: isFixed ? 'left' : null,
            componentName: componentName,
            editRender: editRender,
            field: loopFieldInfo.fieldId,
            titleHelp:loopFieldInfo.properties.description? {message:loopFieldInfo.properties.description}:null,
            showOverflow: 'ellipsis',
            tableType: vm.tableType, // master for tableView, child for childtable
            formId: vm.formId,
            formInfo: vm.tableFormInfo,
            fieldInfos: vm.fieldInfos,
            relatedTableInfos: vm.relatedTableInfos,
            fieldInfo: loopFieldInfo,
            options: options
          }
          result.push(newColDef)
        }
      }
    }
  }
}

export default mixin
