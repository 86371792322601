<template>
  <div v-if="conditions[0].length < 1">
    <v-btn
      elevation="0"
      outlined
      color="primary"
      @click="addCond(0)"
      class="w-filter-condition-add-first"
    >
      {{ $t('workflow.modules.form.button.addCondition') }}
    </v-btn>
    <div class="w-filter-condition-hints">
      {{ $t(hints || 'workflow.modules.branch.description') }}
    </div>
  </div>
  <div v-else>
    <v-layout column>
      <FormElement
        :label="!noLabel && $t('workflow.modules.form.label.condition')"
      >
        <div class="w-filter-condition-hints">
          {{ $t(hints || 'workflow.modules.branch.description') }}
        </div>
        <div v-for="(andConditions, row) in conditions" :key="row">
          <div v-if="row > 0" class="w-filter-or-condition-row">
            <div>{{ $t('workflow.label.orGate') }}</div>
            <v-divider class="w-filter-or-condition-line" />
          </div>
          <div class="w-filter-gate-block">
            <div class="w-filter-gate-block-title">{{ $t('workflow.modules.branch.label.noOfCondition', { row: row + 1 }) }}</div>
            <div class="w-filter-condition-row" v-for="(condition, column) in andConditions" :key="condition.id">
              <div v-if="column > 0" class="w-filter-and-condition-row">
                <div>{{ $t('workflow.label.andGate') }}</div>
              </div>
              <FormElement :name="`rule.${row}.${column}`">
                <v-layout>
                  <div class="w-filter-left">
                    <ComboBox
                      :value="condition && condition.id ? condition : null"
                      :options="list"
                      v-if="!noListSelection"
                      :placeholder="$t('messages.pleaseChoose', [])"
                      @changed="v => changed(condition, v, row, column)"
                      valueFormat="object"
                      v-bind="rootComboxProps"
                      class="w-filter-fields-selector"
                      :involveRelationshipLabel="true"
                    />
                    <component
                      v-if="!!condition.id"
                      :content="condition.content"
                      :fieldType="condition.fieldType"
                      :dependsOnType="condition.fieldType"
                      :formFieldInfos="dependencies"
                      :defaultRule="condition.content && condition.content.rule"
                      :defaultType="condition.content && condition.content.type"
                      :defaultValue="condition.content && condition.content.value"
                      :rules="getFieldRules(condition.fieldId, condition)"
                      :is="getFieldTypeComponent(condition.fieldType)"
                      :fieldProperties="getFieldById(condition.fieldId).properties"
                      @updateType="v => setType(condition, v, row, column)"
                      @updateRule="v => setRule(condition, v, row, column)"
                      @updateValue="v => setValue(condition, v, row, column)"
                      :comboxProps="comboxProps"
                      :selectInputProps="selectInputProps"
                      disableInput
                      :types="types"
                    ></component>
                  </div>
                  <div class="w-filter-right">
                    <v-btn icon @click="remove(row, column)" class="w-filter-icon">
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-layout>
              </FormElement>
            </div>
            <v-layout>
              <v-btn
                elevation="0"
                outlined
                @click="addCond(row)"
                class="w-filter-addOn w-filter-addOn-and"
              >
                {{ $t('buttons.andGate') }}
              </v-btn>
              <v-btn
                elevation="0"
                outlined
                @click="addCond(row, true)"
                class="w-filter-addOn"
              >
                {{ $t('buttons.orGate') }}
              </v-btn>
            </v-layout>
          </div>
        </div>
      </FormElement>
    </v-layout>
  </div>
</template>

<script>
import ComboBox from './../../../form/comboBox/';
import FormElement from './formElement';
import UUID from 'uuid';
// rules components
import RuleText from '@/pages/admin/form/tableSetup/rules/RuleText'
import RuleNumber from '@/pages/admin/form/tableSetup/rules/RuleNumber'
import RuleDate from '@/pages/admin/form/tableSetup/rules/RuleDate'
import RuleSelection from '@/pages/admin/form/tableSetup/rules/RuleSelection'
import RuleMembers from '@/pages/admin/form/tableSetup/rules/RuleMembers'
import RuleDepartments from '@/pages/admin/form/tableSetup/rules/RuleDepartments'
import RuleRadio from '@/pages/admin/form/tableSetup/rules/RuleRadio'
import RulePhone from "@/pages/admin/form/tableSetup/rules/RulePhone"

import WorkflowSettingUtils from '@/pages/admin/workflow/utils/setting';
import widgetHelperMixin from '@/mixins/widgetHelperMixin'
import filterHelperMixin from '@/mixins/filterHelperMixin'
import settingMixin from './../mixin';

import {
  SET_SETTINGS,
} from '@/store/modules/workflow/action_types';

import {
  map,
  forEach,
  get,
  find,
  compact,
  upperFirst,
} from 'lodash';
export default {
  mixins: [widgetHelperMixin, filterHelperMixin, settingMixin],
  props: {
    setting: Object,
    hints: String,
    list: Array,
    noLabel: Boolean,
    tableList: Array,
    directListSearch: Boolean,
    worksheet: String,
    rootProps: {
      type: Object,
      default: {
        disableBranchNode: false,
      }
    },
    noListSelection: {
      type: Boolean,
      default: false,
    },
    rootComboxProps: {
      type: Object,
      default: {
        onlyPrefix: false,
        onlySuffix: true,
      },
    }
  },
  data() {
    return {
      fieldCache: new Map(),
      // row: 0,
      conditions: [[]],
    };
  },
  mounted() {
    this.prepareOptionSelectedRef();
      // return rules.length < 1 ? [ [] ] : rules;
  },
  computed: {
    // list() {
    //   return this.$store.getters.getFormSelectionsFields.children;
    // },
    // conditions() {
    //   return this.setting.rules;
      // const { list, setting={} } = this;
      // // console.log(list, setting.rules);
      // let content;
      // const rules = map(setting.rules, row => map(row, col => {
      //   // [IMPORTANT] cache the selected field
      //   const selected = this.fieldCache.get(col.fieldId) || find(list, field => field.fieldId === col.fieldId);
      //   this.fieldCache.set(col.fieldId, selected);
      //   // content = get(col, 'content.value.[0].type');
      //   return {
      //     ...col,
      //     selected,
      //     // defaultSelectableInput: content === 'label' ? 1 : 0,
      //   }
      // }));

      // return rules.length < 1 ? [ [] ] : rules;
    // },
    dependencies() {
      const workflowOutputs = this.$store.getters.getDependencies;
      return WorkflowSettingUtils.getFullyDependenciesList(workflowOutputs, this.$t('workflow'));
    },
    comboxProps() {
      return {
        onlyPrefix: true,
        onlySuffix: true,
        disableBranchNode: true,
      }
    },
    selectInputProps() {
      return {
        addable: true,
        isMultiple: true,
        valueFormat: "object",
      };
    },
    filterInfos () {
      return this.$store.getters.filterInfos
    },
    
    types () {
      return [{
        id: 1,
        label: this.$t('widgets.properties.fixedColumnValue'),
        type: 'label',
      },{
        id: 2,
        label: this.$t('widgets.properties.columnValue'),
        type: 'label',
      }];
    }
    // isEmptyConditions() {
    //   return this.conditions[0].length < 1;
    // }
  },
  methods: {
    fieldIdsWithProperties (targetForm) {
      let tableList = this.list;
      if (!this.directListSearch) {
        tableList = find(this.list, ({ form }) => form === targetForm);
        tableList = tableList && tableList.children;
      }
      const result = map(tableList, fieldInfo => {
        const filterType = this.getFilterKeyByFieldInfo(
          this.$store.getters.widgetMap,
          fieldInfo,
        );
        if (filterType) {
          return {
            id: fieldInfo.fieldId,
            label: fieldInfo.label,
            filterType,
            fieldInfo,
          }
        }
        return null;
      });
      return compact(result);
    },
    prepareOptionSelectedRef() {
      const { list, setting={} } = this;
      const rules = map(setting.rules, row => map(row, col => {
        // [IMPORTANT] cache the selected field
        const selected = this.fieldCache.get(col.fieldId) || find(list, field => {
          // consider sub children structure
          if (field.children && field.children.length) {
            return find(field.children, c => c.fieldId === col.fieldId);
          }
          return field.fieldId === col.fieldId;
        });
        this.fieldCache.set(col.fieldId, selected);
        // content = get(col, 'content.value.[0].type');
        return {
          ...col,
          selected,
          // defaultSelectableInput: content === 'label' ? 1 : 0,
        }
      }));
      // this.conditions = rules.length ?[[]];
      if (rules.length > 0) {
        this.conditions = rules;
      } 
      // else {
      //   this.conditions = rules;
      // }
      // console.log(rules);
    },
    setType(condition, v, x, y) {
      if (!condition.content) {
        condition.content = { type: v };
      }
      condition.content.type = v
      // indicate which node output as a result to be searched
      if (v === 2) {
        condition.node = this.workflow.rootNode._id;
      } else {
        condition.node = null;
      }
      this.$forceUpdate();
    },
    setValue(condition, v, x, y) {
      if (!condition.content) {
        condition.content = {};
      }
      condition.content.value = v
      this.$forceUpdate();
    },
    setRule(condition, v, x, y) {
      if (!condition.content) {
        condition.content = {};
      }
      condition.content.rule = v
      this.$forceUpdate();
    },
    // changeValue(v) {
    //   console.log('>>>>>>');
    // },
    addCond(row, append) {
      const cond = {
        uid: UUID(),
        // fieldType: item.type,
        // fieldId: item.fieldId,
        content: {},
      }
      const { conditions } = this;
      if (!conditions) {
        this.conditions = [ [] ];
      }
      if (append) {
        // add to the next of row
        this.conditions.splice(row + 1, 0, [cond]);
      } else {
        this.conditions[row].push(cond);
      }
    },
    getFieldRules(fieldId, condition) {
      const isVirtual = get(condition, 'selected.virtual');
      return WorkflowSettingUtils.getFieldRules(
        this.fieldIdsWithProperties(condition.form), 
        this.filterInfos, 
        fieldId,
        condition.fieldType,  // special for delegated feld (e.g. updated_by, created_by)
        isVirtual,
      );
    },
    changed(current, v, row, column) {
      const gateRow = get(this.conditions, `[${row}]`);
      if (gateRow) {
        let condition = get(gateRow, `[${column}]`);
        if (!!condition) {
          // [IMPORTANT] solution is hiding the input AFTER vue-treeselect has processed all the events it has listened to
          // Reference: https://github.com/riophae/vue-treeselect/issues/272
          setTimeout(() => {
            condition = current;
            if (v) {
              condition.fieldId = v.fieldId;
              condition.fieldType = v.fieldType;
              condition.node = v.node;
              condition.nodeId = v.node;
              condition.id = v.id;
              condition.form = v.form;
              condition.label = v.label;
              condition.selected = v;
              // condition.reliedOutput = v.reliedOutput && this.workflow ? this.workflow._id : null;
              condition.content = {};
            }
            gateRow.splice(column, 1, { ...condition });
            // replace the exitsing one
            this.conditions.splice(row, 1, gateRow);
          }, 200);
        }
      }
    },
    remove(row, column) {
      const gateRow = get(this.conditions, `[${row}]`);
      if (gateRow) {
        gateRow.splice(column, 1);
        // only left empty array for first child
        if (gateRow.length < 1 && row > 0) {
          this.conditions.splice(row, 1);
        }
        if (this.conditions.length > 1 && this.conditions[0].length < 1) {
          this.conditions.splice(0, 1);
        }
        // this.conditions.splice(row, 1);
      }
    },
    getFieldById(fieldId){
      let field = {};
      forEach(this.list, l => {
        if (l.children) {
          const exists = find(l.children, c => c.fieldId === fieldId);
          if (exists) {
            field = exists;
            return;
          }
        } else if (l.fieldId === fieldId) {
          field = l;
          return;
        }
      });
      // console.log(find(this.list, item => item.fieldId === fieldId).properties);
      return field || {};
    },
    getFieldTypeComponent(type) {
      const target = WorkflowSettingUtils.getFilteringFieldTypeComponent(type);
      return `Rule${upperFirst(target)}`;
    },
  },
  watch: {
    conditions: {
      handler(c) {
        this.$store.dispatch(SET_SETTINGS, { rules: c });
      },
      deep: true,
    },
    worksheet: {
      handler(was) {
        this.prepareOptionSelectedRef();
        this.conditions = [[]];
      },
      deep: true,
    },
    list: {
      handler(v) {
        this.prepareOptionSelectedRef();
      },
      deep: true,
    },
  },
  components: {
    FormElement,
    RuleText,
    RuleNumber,
    RuleDate,
    RuleSelection,
    RuleMembers,
    RuleDepartments,
    RuleRadio,
    RulePhone,
    ComboBox,
  },
};

</script>

<style scoped>
  .w-filter-or-condition-row,
  .w-filter-and-condition-row {
    position: relative;
    height: 30px;
    margin: 10px 0;
    text-align: center;
  }
  .w-filter-and-condition-row > div {
    background: transparent !important;
  }

  .w-filter-or-condition-row > div,
  .w-filter-and-condition-row > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 16px;
    background: white;
    width: fit-content;
    line-height: 30px;
    z-index: 3;
  }
  .w-filter-or-condition-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
  .w-filter-gate-block {
    background: #f7f7f7;
    padding: 10px 20px 20px 20px;
    margin-top: 10px;
  }
  .w-filter-gate-block-title {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  .w-filter-condition-add-first {
    height: 36px;
    line-height: 36px;
    box-sizing: border-box;
    font-size: 13px;
    border-radius: 18px;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    padding: 0 18px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  /* .w-filter-condition-add-first:hover {
    color: #1565c0;
    border-color: #1565c0;
  } */
  .w-filter-condition-hints {
    color: #9e9e9e;
    font-size: 13px;
  }
  .w-filter-condition-row {
    /* margin: 15px 0 0 0; */
  }
  .w-filter-condition-row:hover .w-filter-right .w-filter-icon{
    display: block;
  }
  .w-filter-fields-selector {
    margin-bottom: 5px;
  }
  .w-filter-left {
    flex: 1;
  }
  .w-filter-right {
    width: 30px;
    padding-left: 5px;
  }
  .w-filter-icon {
    display: none;
  }
  .w-filter-addOn {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 0 20px;
    background: #f5f5f5;
    cursor: pointer;
    margin-right: 10px;
    box-sizing: border-box;
    border-color: #ddd;
  }
  .w-filter-addOn:hover {
    border-color: #1e88e5;
  }
  .w-filter-addOn-and {
    margin-right: 5px;
  }
</style>
