
<template>
  <div class="flex-grow-1 d-flex" style="width:100%">
    <div class="flex-grow-1 page-form d-flex flex-column justify-start" style="width:100%">
      <v-card flat tile class="flex-grow-1 ma-0 d-flex flex-column">
        <form-title
          v-if="form && views"
          ref="formTitle"
          :title="form.label"
          :builderButton="true"
          builderRouteName="adminForms"
          :loading="loading"
          :size="selections && selections.length"
          :selectedItems="selections"
          @onSelected="afterCustomizedAction"
          :selected="numberOfSelections"
          :isQuickInsertMode="isQuickInsertMode"
          @onCommand="onCommandHandler"
          :buttons="canAddManually ? ['new'] : []"
          @enableQuickInsert="gotoQuickInsertMode"
        >
          <template v-slot:prepend-action-button-set>
            <vxe-toolbar
              size="small"
              style="height:36px;"
              v-if="
                currentView &&
                currentView.viewType == 'table' &&
                isQuickInsertMode
              "
            >
              <template v-slot:tools>
                <v-btn
                  :disabled="loading"
                  x-small
                  fab
                  color="muted"
                  @click="closeQuickInsertMode"
                  class="ml-1"
                >
                  <v-icon>mdi-reply</v-icon></v-btn
                >
                <v-btn
                  :disabled="loading"
                  x-small
                  fab
                  color="primary"
                  @click="saveQuickInsertData"
                  class="ml-1"
                >
                  <v-icon>mdi-send</v-icon></v-btn
                >
              </template>
            </vxe-toolbar>

            <vxe-toolbar
              size="small"
              style="height:36px;"
              v-else-if="currentView && currentView.viewType == 'table'"
            >
              <template v-slot:tools>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-if="canImport"
                      :disabled="loading"
                      small
                      color="error"
                      @click="getTableRef('openImportDialog')"
                      class="ml-1"
                      fab
                      height="32"
                      width="32"
                      depressed
                      style="transform: rotate(90deg)"
                    >
                      <v-icon size="20">mdi-import</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("buttons.import") }}
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      :disabled="loading"
                      small
                      color="primary"
                      @click="getTableRef('onRefreshClicked')"
                      class="ml-1"
                      fab
                      height="32"
                      width="32"
                      depressed
                    >
                      <v-icon size="20">mdi-refresh</v-icon>
                      <!-- {{ $t("buttons.refresh") }} -->
                    </v-btn>
                  </template>
                  {{ $t("buttons.refresh") }}
                </v-tooltip>
                <v-tooltip bottom v-if="drawerNotEmpty && !isQuickInsertMode">
                  <template v-slot:activator="{on}">
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      dark
                      depressed
                      x-small
                      fab
                      :disabled="loading"
                      color="warning"
                      @click="setDrawerWithActiveTab('upload')"
                    >
                      <v-icon style="transform:rotate(45deg);">mdi-paperclip</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("buttons.attachment") }}
                </v-tooltip>
              
              
                <v-tooltip bottom v-if="drawerNotEmpty && !isQuickInsertMode">
                  <template v-slot:activator="{on}">
                    <div>
                      <v-btn
                        class="ml-1"
                        v-on="on"
                        dark
                        depressed
                        x-small
                        fab
                        :disabled="loading"
                        color="#26A83B"
                        @click="setDrawerWithActiveTab('comment')"
                    >
                      <v-icon>mdi-comment</v-icon>
                    </v-btn>
                    </div>
                  </template>
                  {{$t('buttons.comment')}}
                </v-tooltip>
    
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                
                <v-btn
                  v-if="!isQuickInsertMode"
                  class="ml-1"
                  v-on="on"
                  dark
                  depressed
                  x-small
                  fab
                  :disabled="loading"
                  color="info"
                  @click="showChartDrawer = true"
                >
                  <v-icon>mdi-chart-bar</v-icon>
                </v-btn>
                  </template>
                  {{$t('chart.chart')}}
                </v-tooltip>
                
                <v-tooltip bottom v-if="drawerNotEmpty && !isQuickInsertMode">
                  <template v-slot:activator="{on}">
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      dark
                      depressed
                      x-small
                      fab
                      :disabled="loading"
                      color="primary"
                      @click="setDrawerWithActiveTab('log')"
                    >
                      <v-icon>mdi-history</v-icon>
                    </v-btn>
                  </template>
                  {{$t('buttons.history')}}
                </v-tooltip>
              
              
              
              
              </template>
            </vxe-toolbar>
          </template>

        </form-title>

        <quick-insert-mode-toolbar
          v-if="isQuickInsertMode"
          :tabBarStyle="tabBarStyle"
          :rowHeight="$refs.tableView.quickInsertModeRowHeight || 's'"
          @updateRowHeight="setQIModeRowHeight"
          :dataRowCount="quickInsertDataRows"
          :totalRowCount="quickInsertTotalRows"
        ></quick-insert-mode-toolbar>

        <div v-else-if="views" class="position-relative" style="max-width:100%; border-top: 1px solid #F2F3F5; border-bottom: 1px solid #F2F3F5">
          <module-tab-bar
            v-model="currentViewIndex"
            :itemDialogTitle="$t('app.view')"
            type="view"
            :menu="viewMenu"
            :addBtnClass="'squareBtn'"
            :useIcon="true"
            :availableTabs="isQuickInsertMode ? [] : views"
            :hideButtons="!isAdmin || isQuickInsertMode"
            @onCommand="onCommandHandler"
            :optional="moduleOptional"
            tabsHeight="40"
            class="flex-grow-1"
            :useAppColorInFont="true"
            ref="viewTabBar"
          >
          </module-tab-bar>
        </div>
    
        <div class="pt-2 pb-1 px-3 d-flex align-center" v-if="currentView && currentView.viewType!='project'">
          <v-btn
            v-if="numberOfSelections > 0"
            :disabled="loading"
            small
            color="error"
            @click="getTableRef('deleteItems')"
            class="ml-1"
            height="32"
            depressed
          >
            <v-icon size="20">mdi-delete</v-icon>
            {{ $t("buttons.delete") }}
          </v-btn>
          <v-btn
            v-if="numberOfSelections > 0 && editableFieldIds.length>0"
            :disabled="loading"
            small
            @click="getTableRef('openBulkEditDialog')"
            color="info"
            class="ml-2"
            height="32"
            depressed
          >
            <v-icon size="20">mdi-pencil</v-icon>
            {{ $t("buttons.edit") }}
          </v-btn>
          <v-menu
            offset-y
            right
            v-if="isAdmin && !(numberOfSelections > 0 && editableFieldIds.length>0)"
            v-model="showingToolsMenu"
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                dark 
                v-on="on"
                @click="track('TOOL')"
                style="width:100px;height:32px;border-radius:46px;padding:8px;background-color:#F07F3C;"
                class="elevation-0"
              >
                <v-icon size="20">mdi-tools</v-icon>
                <span class="ml-1">{{ $t("buttons.tools") }}</span>
                <v-icon v-if="!showingToolsMenu" size="22" >mdi-chevron-down</v-icon>
                <v-icon v-else size="22" class="">mdi-chevron-up</v-icon>
              </v-btn>
            </template>
            <v-card style="width:100%; font-size: 16px;">
              <v-list class="pa-0">
                <v-hover v-for="action in actionMenu" :key="action.trackId" v-slot="{ hover }">
                  <v-list-item 
                    class="px-3"
                    :style="hover?'background-color: #F9F9F9;':''"
                    @click="showViewSettingsDialog({ ...currentView, tabIndex: action.tabIndex, trackId:action.trackId })"
                  >
                    <v-icon color="#272E3B">{{ action.icon }}</v-icon>
                    <span class="ml-2">{{ action.label }}</span>
                  </v-list-item>                  
                </v-hover>
              </v-list>
            </v-card>
          </v-menu>
          <v-tooltip 
            bottom 
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                fab 
                class="elevation-0 ml-2"
                dark 
                color="#AC3E79"
                width="32"
                height="32"
                @click="getTableRef('openExportDialog')"
              > 
                <v-icon size="18" style="margin-bottom:2px;">mdi-export-variant</v-icon>
              </v-btn>
            </template>
            {{$t("buttons.export")}}
          </v-tooltip>
          <CustomizedActionButton
            :size="numberOfSelections"
            :selected="selections"
            @onSelected="afterCustomizedAction"
            :isAllChecked="isAllChecked"
            @click="track('ADD_BUTTON')"
          />
          <div class="ml-auto">
            <div class="d-flex">
              <v-btn
                v-if="isAdmin"
                class="elevation-0 mx-2 px-1 pr-2"
                dark 
                color="#AC3E79"
                height="40"
                style="border-radius: 4px"
                @click="showViewSettingsDialog({...currentView, tabIndex:1, })"
              > 
                <v-icon size="18">mdi-playlist-plus</v-icon>
                <span style='font-size:14px"'>{{ $t("buttons.fieldDisplay") }}</span>
              </v-btn>

              <form-title-search
                ref="formTitleSearch"
                :auto="true"
                :disabled="loading"
                :offsetTime="2"
                @onSearch="searchForm"
              ></form-title-search>                  
              </v-text-field>
            </div>
          </div>
        </div> 
        <template v-if="currentView || isQuickInsertMode">
          <table-view
            :key="tableViewKey"
            style="height: 100%; max-height: 100%;"
            ref="tableView"
            v-if="currentView.viewType === 'table' || isQuickInsertMode"
            :view="currentView"
            :canImport="canImport"
            :canExport="canExport"
            :isQuickInsertMode="isQuickInsertMode"
            :printTemplates="printTemplates"
            :canCopy="canCopy"
            :canInlineEdit="canInlineEdit"
            :canPrint="canPrint"
            @onDataLoading="onDataLoadingHandler"
            @onDataLoaded="onDataLoadedHandler"
            @onCommand="onCommandHandler"
            @update_numberOfSelections="update_numberOfSelections"
            @update_ofSelections="update_ofSelections"
            @update_isAllChecked="update_isAllChecked"
            @update_totalRecordNumber="update_totalRecordNumber"
          ></table-view>
          <card-board-view
            ref="cardBoardView"
            v-else-if="currentView.viewType === 'panel'"
            :view="currentView"
            :printTemplates="printTemplates"
            @onCommand="onCommandHandler"
          ></card-board-view>
          <level-diagram-view
            ref="levelDiagramView"
            v-else-if="currentView.viewType === 'levelDiagram'"
            :view="currentView"
            :printTemplates="printTemplates"
            @onCommand="onCommandHandler"
          ></level-diagram-view>
          <project-view
            ref="projectView"
            class="projectView"
            v-else-if="currentView.viewType === 'project'"
            :view="currentView"
            @onCommand="onCommandHandler"
          ></project-view>
        </template>
        <!-- <template v-if="true">
          <div style="height:100%" class="red">
            <div class="green"  style="height:100%">trewads</div>
          </div>
        </template> -->
        <div v-else class="text-center">
          <div class="d-inline-block ma-4">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </v-card>
      <view-basic-info-dialog
        v-if="isAdmin"
        ref="viewBasicInfoDialog"
        @onCommand="onCommandHandler"
        type="view"
        command="saveTab"
        :dialogTitle="$t('app.view')"
      ></view-basic-info-dialog>
      <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
      
      <view-settings-dialog
        ref="viewSettingsDialog"
        @onCommand="onCommandHandler"
        :initialTabIndex="initialTabIndex"
      ></view-settings-dialog>
      <edit-template-dialog
        v-if="showTemplateDialog"
        v-model="showTemplateDialog"
        :templateId="selectedPrintTemplate"
        :recordId="selectedRecordId"
        :isEdit="isEditPrint"
        :viewId="currentView._id"
        :isPrint="true"
      />
    </div>

    <!-- Navigation drawer -->
    <v-overlay v-if="showDrawer" :dark="false">
      <transition name="slide">
        <div v-if="showDrawer" style="width:100vw; height:100vh" class="existLayer">
          <v-navigation-drawer
            ref="commentDrawer"
            class="right-drawer"
            clipped
            width="800"
            v-model="showDrawer"
            right
            absolute
            permanent
          >
            <drawer 
              v-if="showDrawer" 
              :parentId="itemId" 
              :activeTab="drawerActiveTab"
            />
          </v-navigation-drawer>
        </div>
      </transition>
    </v-overlay>
    <!-- Chart Drawer -->
    <form-chart
      ref="formChart"
      :views="views"
      :showChartDrawer="showChartDrawer"
      @update_showChartDrawer="update_showChartDrawer"
    ></form-chart>

    <!-- Child/Relation Table drawer -->
    <record-drawer
      ref="recordDrawer"
      :formInfo="selectedFormInfo"
      :fieldInfo="selectedFieldInfo"
      :recordId="selectedRecordId"
      :rowHeightClass="rowHeightClass"
      :showDrawer="showRecordDrawer"
      @close="val=>toggleRecordDrawer(val)"
    ></record-drawer>

    <general-dialog ref="atLeastOneViewDialog"/>
  </div>
</template>

<script>
import FormChart from "@/components/FormChart";
import recordDrawer from '@/components/RecordDrawer'

import moduleTabBar from "@/components/ModuleTabBar";
import formControls from "@/components/form/controls";

import appsFormsMixin from "@/mixins/appsFormsMixin";
import dialogMixin from "@/mixins/dialogMixin";
import printTemplateDialogMixin from "@/mixins/printTemplateDialogMixin";
import appStyleMixin from "@/mixins/appStyleMixin";

import formTitle from "@/components/FormTitle";
import formTitleSearch from "@/components/FormTitleSearch";

import ViewMenu from "@/menus/ViewMenu";
import tableView from "./views/TableView";
import cardBoardView from "./views/CardBoardView";
import levelDiagramView from "./views/LevelDiagramView";
import projectView from "./views/project/ProjectView";

import viewBasicInfoDialog from "./dialogs/ViewBasicInfoDialog";
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import viewSettingsDialog from "@/components/dialogs/ViewSettingsDialog";
import EditTemplateDialog from "@/pages/admin/form/tableSetup/print/EditTemplateDialog";

import Drawer from "@/components/discussion/drawer";
import quickInsertModeToolbar from "./comps/QuickInsertModeToolbar";
import GeneralDialog from "@/components/dialogs/GeneralDialog";

import CustomizedActionButton from "@/pages/admin/workflow/manualActions/tools";
import {
  FETCH_ACTION_BUTTON_OPTIONS,
} from '@/store/modules/customizedAction/action_types';
import { cloneDeep } from "lodash";

export default {
  name: "form",
  mixins: [
    appsFormsMixin,
    dialogMixin,
    printTemplateDialogMixin,
    appStyleMixin,
  ],
  components: {
    Drawer,
    CustomizedActionButton,
    moduleTabBar,
    ...formControls,
    viewBasicInfoDialog,
    EditTemplateDialog,
    formTitleSearch,
    FormChart,
    recordDrawer,

    tableView,
    cardBoardView,
    levelDiagramView,
    projectView,
    confirmDeleteDialog,
    viewSettingsDialog,
    formTitle,

    quickInsertModeToolbar,
    GeneralDialog
  },
  data() {
    return {
      showingToolsMenu: false,
      show: false,
      viewMenu: ViewMenu,
      viewTypeMap:{
        "table": "Table",
        "panel": "Panel",
        "levelDiagram": "Cascade",
        "project": "Project"
      },
      actionMenu:[{
        trackId: "GENERAL",
        label: this.$t("buttons.tableSetting"),
        tabIndex:0,
        icon:"mdi-table-settings"
      },{
        trackId: "FILTER",
        label: this.$t("buttons.filter"),
        tabIndex:2,
        icon:"mdi-filter"
      },{
        trackId: "QUICK_FILTER",
        label: this.$t("buttons.quickFilter"),
        tabIndex:3,
        icon:"mdi-filter"
      },{
        trackId: "SORTING",
        label: this.$t("buttons.sorting"),
        tabIndex:4,
        icon:"mdi-sort-alphabetical-variant"
      },{
        trackId: "BUTTON",
        label: this.$t("buttons.customAction"),
        tabIndex:5,
        icon:"mdi-sitemap"
      }],
      dataTableSize: "small",
      currentViewIndex: -1,
      loading: false,
      selections: [],

      showDrawer: false,
      showChartDrawer: false,
      showRecordDrawer: false,

      // used for child/relation table drawer
      selectedFormInfo: null,
      selectedRecordId: '',
      selectedFieldInfo: null,
      rowHeightClass: '',

      numberOfSelections: 0,
      totalNumberOfItems: 0, 

      isQuickInsertMode: false,
      moduleOptional:true,
      drawerActiveTab:0,
      isAllChecked: false,
      tableViewKey: 1
    };
  },
  computed: {
    initialTabIndex() {
      const { isPopupActionButton } = this;
      return isPopupActionButton !== -1 ? isPopupActionButton : 0;
    },
    isPopupActionButton() {
      return this.$store.getters.isPopupActionButton;
    },
    quickInsertDataRows() {
      return this.$refs.tableView.quickInsertDataRowCount;
    },

    quickInsertTotalRows() {
      return this.$refs.tableView.quickInsertTotalRowCount;
    },

    tabBarStyle() {
      const vm = this;
      var result = {};
      result["border-bottom"] = "2px solid " + vm.currentApp.color;
      result["background-color"] = vm.appColor(vm.currentApp.color, 0.8);
      return result;
    },

    drawerNotEmpty() {
      return (
        this.$store.getters.showFormLog ||
        this.$store.getters.showFormDiscussion
      );
    },
    views() {
      const vm = this;
      var result = [];
      if (vm.form && vm.form.views) {
        result = vm.form.views;
        for (var i = 0; i < result.length; i++) {
          switch (result[i].viewType) {
            case "table":
              result[i].icon = "mdi-view-list-outline";
              break;
            case "panel":
              result[i].icon = "mdi-view-dashboard-variant-outline";
              break;
            case "levelDiagram":
              result[i].icon = "mdi-animation-outline";
              break
            case "project":
              result[i].icon = "mdi-alpha-p-circle-outline";
              break;
          }
        }
      }
      return result;
    },
    currentAppId() {
      const vm = this;
      var result = "";
      if (vm.currentApp) {
        result = vm.currentApp._id;
      }
      return result;
    },
    currentApp() {
      return this.$store.getters.currentApp;
    },
    currentView() {
      const vm = this;
      var result = null;
      /*       console.log('Form.vue computed(currentView) :: vm.form: ', vm.form)
      console.log('Form.vue computed(currentView) :: vm.currentViewIndex = ' + vm.currentViewIndex)
 */
      if (vm.views.length > vm.currentViewIndex) {
        result = vm.views[vm.currentViewIndex];
      }
      /*       console.log('Form.vue computed(currentView) :: currentView: ', result)
      console.log('Form.vue computed(currentView) = currentView.viewType = ' +
        (result ? result.viewType : 'no current view'))
 */

      return result;
    },
    form() {
      return this.$store.getters.currentForm;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    itemId() {
      return this.$route.params.itemId;
    },
    canExport() {
      return this.getFunctionPermission("viewFunction", "allowExport");
    },
    canImport() {
      if (!this.form || !this.form.canAdd) return false;
      else if (this.form.formFunction.allowImport.roleType === 1)
        return this.isAdmin;
      else return true;
    },
    canCopy() {
      return this.getFunctionPermission("recordFunction", "allowCopy");
    },
    canInlineEdit() {
      return this.getFunctionPermission("viewFunction", "allowInline");
    },
    canAddManually() {
      return (
        (this.form.canAdd &&
          this.form.formFunction &&
          this.form.formFunction.showAddButton) ||
        false
      );
    },
    editableFieldIds() {
      var exclusion = ['createdAt','createdBy','updatedAt']
      return this.form.fieldInfos
        .filter((info) => info.canEdit&&!exclusion.includes(info.type))
        .map((info) => info._id);
    }
  },
  mounted() {
    const vm = this;
      vm.loadForm(vm.$route.params.itemId, ()=>{
        if (this.$route.params.viewId){
          const index = this.views.findIndex(view=> view._id === this.$route.params.viewId)
          if (index > -1){
            this.currentViewIndex = index
          }
        }else this.currentViewIndex = 0

        this.moduleOptional = false
      });
    this.isAllChecked = false;
  },
  watch: {
    showDrawer(val){
      this.$nextTick(()=>{
        if(val){
          var commentDrawer = this.$refs.commentDrawer.$el
          var overlay = commentDrawer.closest('.v-overlay')
          let vm = this
          if(overlay){
            overlay.addEventListener('click', function(e){
              var srcEle = e.srcElement
              if(srcEle.className.includes('existLayer'))
                vm.showDrawer=false
            })
          }
        }
      })
    },
    isPopupActionButton: function(v){
      if (v !== -1) {
        this.showViewSettingsDialog(this.currentView);
      }
    },
    numberOfSelections: function(v) {
      if (v > 0) {
        const { viewId } = this.$route.params;
        this.$store.dispatch(FETCH_ACTION_BUTTON_OPTIONS, {
          appId: this.currentApp._id,
          formId: this.form._id,
          viewId
        });
      }
    },
    "form._id": function(){
      this.isQuickInsertMode = false
    },
    // it is form id or panel id
    "$route.params.itemId": function(newVal) {
      const vm = this;
      if(vm.currentView && vm.currentView.viewType=='project')
        this.currentViewIndex=0;
      vm.resetForm(newVal);
    },
    isAllChecked: async function ( val ) {
      if ( val ) {
        try {
          await this.fetchAllDataIds();
        } catch (err) {
          console.error( err );
        }
      }
    },
    "currentView._id": {
      handler: function(val){
        this.isQuickInsertNode = false;
          if (val){
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
                key: "plus_sheet_view",
                data: {
                    view_type:this.viewTypeMap[this.currentView.viewType]
                }
            });
                
            this.$router.push({
                  name: "forms",
                  params: {
                    itemId: this.$route.params.itemId,
                    viewId: val,
                  },
                })
          }
      }
    }
  },
  methods: {
    toggleRecordDrawer (show) {
      const vm = this
      vm.showRecordDrawer = show
      if (!vm.showRecordDrawer) {
        vm.$refs.tableView.refresh()
      }
    },
    afterCustomizedAction() {
      if(this.numberOfSelections > 0 && this.editableFieldIds.length>0){
        this.$refs.tableView.refresh();
        this.$refs.tableView.clearSelection();
      }else{
        var configs = {
          headerClass: 'error'
        }
        this.$refs['atLeastOneViewDialog'].open(
            this.$t('general.alert'), 
            this.$t('messages.pleaseSelectAtLeastOneRecord'), 
            ['close'], 
            configs
        )
      }
      console.log('afterCustomizedAction =>< refresh')
    },

    onDataLoadingHandler() {
      this.loading = true;
    },
    onDataLoadedHandler() {
      this.loading = false;
    },
    setQIModeRowHeight(height) {
      this.$refs.tableView.setQIModeRowHeight(height);
    },
    update_numberOfSelections(val) {
      this.numberOfSelections = val;
    },
    update_ofSelections(val) {
      if ( this.isAllChecked ) {
        this.selections = this.selections.filter( item =>!(val.includes(item)))
      } else {
        this.selections = val;
      }
    },
    getTableRef(command) {
      this.$refs.tableView[command]();
    },
    searchForm(searchText) {
      const vm = this;
      switch (vm.currentView.viewType) {
        case "table":
          vm.$refs.tableView.search(searchText);
          break;
        case "panel":
          vm.$refs.cardBoardView.search(searchText);
          break;
      }
    },
    resetForm(formId) {
      const vm = this;
      this.isQuickInsertNode = false;
      vm.$store.dispatch("SET_SELECTED_MENU_ITEM_ID", formId);
      vm.loadForm(formId, () => {
        vm.reloadData();
      });
    },
    reloadData() {
      if (this.$refs.tableView) {
        this.tableViewKey++
        // this.$refs.tableView.onRefreshClicked();
      }
    },
    updateStatus() {
      const vm = this;
      vm.$refs.tableView.updateStatus();
    },
    showViewSettingsDialog(view) {
      const vm = this;
      this.$refs.viewSettingsDialog.open(cloneDeep(view), vm.onSaveViewSettings);
      if (view.trackId){
        this.track(view.trackId);
      }
    },

    onSaveViewSettings(view, onSaved) {
      const vm = this;
      if(vm.$refs.tableView)
        var keyword = vm.$refs.tableView.keyword || null
      vm.$store
        .dispatch("UPDATE_FORM_VIEW", {
          id: vm.form._id,
          viewId: view._id,
          data: view,
        })
        .then(
          () => {
            // console.log('onSaveViewSettings : response: ', response)
            // console.log('onSaveSettings => refreshData')
            // console.log('onSaveSettings :: typeof onSaved = ' + (typeof onSaved))
            if (typeof onSaved === "function") {
              onSaved();
            }
            if(vm.$refs.tableView)
              vm.$refs.tableView.keyword = keyword
            vm.refreshData();
          },
          (error) => {
            console.log("error: ", error);
          }
        );
    },

    refreshData() {
      const vm = this;
      switch (vm.currentView.viewType) {
        case "table":
          vm.$refs.tableView.onRefreshClicked();
          break;
        case "panel":
          vm.$refs.cardBoardView.refresh();
          break;
      }
    },

    savePagination() {
      const vm = this;
      if (vm.$refs.tableView) {
        var paginations = JSON.parse(localStorage.getItem("LAST_PAGINATIONS"));
        if (paginations !== null && paginations[vm.form._id]) {
          Object.assign(
            paginations[vm.form._id],
            vm.$refs.tableView.pagination
          );
        } else {
          paginations = {
            [vm.form._id]: vm.$refs.tableView.pagination,
          };
        }
        // console.log('editRecord :: paginations: ', paginations)
        localStorage.setItem("LAST_PAGINATIONS", JSON.stringify(paginations));
      }
    },

    newRecord() {
      const vm = this;
      vm.savePagination();
      vm.$router.push({
        name: "records",
        params: {
          appId: vm.currentApp._id,
          viewId: vm.currentView._id,
          itemId: vm.form._id,
          recordId: "_",
        },
      });
    },
    gotoRelatedRecord (payload) {
      const vm = this
      console.log('Form.gotoRelatedRecord :: payload: ', payload)
      vm.$store.dispatch('SET_SELECTED_MENU_ITEM_ID', payload.params.itemId)
      vm.$router.push({
        name: 'records',
        params: payload.params
      })
    },
    editRecord(payload) {
      const vm = this;
      vm.savePagination();
      vm.$router.push({
        name: "records",
        params: {
          appId: vm.currentApp._id,
          formId: vm.form._id,
          viewId: vm.currentView._id,
          recordId: payload.record._id,
        },
      });
    },

    updateLang(locale) {
      const vm = this;
      // console.log('Form.vue :: updateLang :: locale = ' + locale)
      vm.$locale.change(locale);
      // (val)val=>$locale.change(val)
    },

    onCommandHandler(payload) {
      const vm = this;
      switch(payload.command){
        case 'showChildTableDrawer':
          vm.selectedRecordId = payload.recordId
          vm.selectedFormInfo = payload.formInfo
          vm.selectedFieldInfo = payload.fieldInfo
          vm.rowHeightClass = payload.rowHeightClass
          vm.showRecordDrawer = true
          break
        case "gotoRelatedRecord":
          vm.gotoRelatedRecord(payload)
          break
        case "viewReady":
          vm.$refs.formTitleSearch.reset();
          break;

        case "updatePanelCardDisplayFieldIds":
          // console.log('Form.vue :: udpatePanelCardDisplayFieldIds  vm.form.views[vm.currentViewIndex] = payload.value')
          vm.form.views[vm.currentViewIndex] = payload.value;
          break;
        case "refreshData":
          vm.refreshData();
          break;
        case "newRecord":
          vm.newRecord();
          break;
        case "editRow":
          vm.editRecord(payload);
          break;

        // Tab menu handler
        case "newTab":
          vm.$refs.viewBasicInfoDialog.open();
          break;

        // view menu implementation
        case "renameTab":
          vm.updateViewIndexFromId(payload.data._id);

          vm.$refs.viewBasicInfoDialog.open(payload.data);
          break;
        case "hideTab":
          // vm.updateViewIndexFromId(payload.data._id)
          vm.updateHidden(
            payload.data._id,
            payload.data.title,
            payload.data.viewType,
            payload.data.hidden
          );
          // this.$nextTick(()=>{
          //   this.$refs.viewTabBar.$refs.tabs && this.$refs.viewTabBar.$refs.tabs.onResize()
          // })
          break;
        case "settingsTab":
          // console.log('Form.onCommandHandler :: case "settingsTab": payload: ' + JSON.stringify(payload.data))
          vm.updateViewIndexFromId(payload.data._id);
          // payload.data = view
          // console.log(
          //   "Form.vue :: settingsTab :: payload.data: ",
          //   payload.data
          // );
          vm.showViewSettingsDialog(payload.data);
          break;
        case "deleteTab":
          if(vm.views.length==1){
            var configs = {
              headerClass: 'error'
            }
            this.$refs['atLeastOneViewDialog'].open(
              vm.$t('general.alert'), 
              vm.$t('messages.keepAtLeastOneView'), 
              ['close'], 
              configs
            )
            return
          }
          vm.updateViewIndexFromId(payload.data._id);
          vm.$refs.confirmDeleteDialog.confirm(() => {
            vm.deleteViewInfo(payload);
          });
          break;
        case "moveTabs":
          vm.moveTabsOfView(payload.oldIndex, payload.newIndex);
          break;
        case "saveTab":
          vm.saveViewInfo(payload);
          break;
        case "copyTab":
          this.copyView(payload.data._id);
          break;
        case "updateView":
          vm.onSaveViewSettings(payload.view);
          break;
        case "print":
          this.print(payload);
          break;
      }
    },
    saveQuickInsertData() {
      this.$refs.tableView.checkAndSaveQuickInsertData();
    },
    closeQuickInsertMode() {
      this.isQuickInsertMode = false;
      this.$nextTick(() => {
        this.refreshData();
      });
    },
    gotoQuickInsertMode() {
      this.isQuickInsertMode = true;
    },
    copyView(viewId) {
      this.$store
        .dispatch("COPY_FORM_VIEW", {
          appId: this.currentAppId,
          formId: this.form._id,
          viewId,
        })
        .then((newView) => {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.table_view.COPY_VIEW,
            }
          });
          this.updateViewIndexFromId(newView._id);
        });
    },
    updateHidden(id, title, viewType, hidden = false) {
      let vm = this;
      vm.$store
        .dispatch("UPDATE_FORM_VIEW", {
          id: vm.form._id,
          viewId: id,
          data: {
            title: title,
            viewType: viewType,
            hidden: !hidden,
          },
        })
        .then(() => {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
                module_name: this.$store.getters.formLabel,
                func_name: this.$sensors.events.plus_sheet_mani.table_view.UPDATE_VIEW,
            }
          });
        });
    },
    updateViewIndexFromId(viewId) {
      const vm = this;
      // console.log('Form.updateViewIndexFromId : viewId = ' + viewId)
      vm.currentViewIndex = vm.views.findIndex((view) => view._id === viewId);
      // console.log('Form.updateViewIndexFromId : viewId = ' + viewId + ': vm.currentViewIndex = ' + vm.currentViewIndex)
    },

    deleteViewInfo(payload) {
      const vm = this;
      const view = payload.data;

      vm.$store
        .dispatch("REMOVE_FORM_VIEW", {
          formId: vm.form._id,
          viewId: view._id,
        })
        .then(() => {
          this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: "plus_sheet_mani",
            data: {
              module_name: this.$store.getters.formLabel,
              func_name: this.$sensors.events.plus_sheet_mani.table_view.DELETE_VIEW,
            }
          });
          vm.showSuccess("messages.deletedSuccessfully");
        });
    },

    saveViewInfo(payload) {
      const vm = this;
      // console.log('Form.vue :: saveViewInfo :: payload: ', payload)
      if (payload.id === "") {
        // console.log('Form.vue :: saveViewInfo :: payload: ', payload)
        // console.log('view count (before insert) = ' + vm.views.length)
        vm.insertViewTab({
          title: payload.title,
          viewType: payload.viewType,
          callback: (res) => {
            // console.log('Form :: saveViewInfo : res: ', res)
            // console.log('Form :: views.length = ' + vm.views.length)
            vm.views[vm.views.length - 1] = res;
            vm.currentViewIndex = vm.views.length - 1;
            this.$sensors.track("plus_view_add", {
              app_id:this.currentAppId,
              view_type: this.viewTypeMap[payload.viewType]
            })
          },
        });
        // console.log('view count (after insert) = ' + vm.views.length)
      } else {
        vm.updateViewTab({
          id: payload.id,
          title: payload.title,
          viewType: payload.viewType,
        });
        // console.log('Form.vue :: saveViewInfo :: currentViewIndex => findIndex')
        vm.currentViewIndex = vm.views.findIndex(
          (view) => view._id === payload.id
        );
      }
    },

    insertViewTab(payload) {
      const vm = this;
      // console.log('Form.vue :: insertViewTab :: payload: ', payload)
      vm.$store
        .dispatch("INSERT_FORM_VIEW", {
          id: vm.form._id,
          appId: this.currentAppId,
          title: payload.title,
          viewType: payload.viewType,
        })
        .then(
          (response) => {
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
              key: "plus_sheet_mani",
              data: {
                  module_name: this.$store.getters.formLabel,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.CREATE_VIEW,
              }
            });
            if (typeof payload.callback === "function") {
              payload.callback(response);
            }
          }
        );
    },

    updateViewTab(payload) {
      const vm = this;
      vm.$store
        .dispatch("UPDATE_FORM_VIEW", {
          id: vm.form._id,
          viewId: payload.id,
          data: {
            title: payload.title,
            viewType: payload.viewType,
          },
        })
        .then(
          () => {
            this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
              key: "plus_sheet_mani",
              data: {
                  module_name: this.$store.getters.formLabel,
                  func_name: this.$sensors.events.plus_sheet_mani.table_view.UPDATE_VIEW,
              }
            });
          }
        );
    },

    moveTabsOfView(oldIndex, newIndex) {
      const vm = this;
      vm.$store.dispatch("REORDER_VIEWS", {
        form: vm.form,
        // vm.$store.commit('swapViews', {
        oldIndex: oldIndex,
        newIndex: newIndex,
      });
    },
    update_showChartDrawer(val) {
      this.showChartDrawer = val;
    },
    setDrawerWithActiveTab(activeTab){
      this.drawerActiveTab = activeTab
      this.showDrawer = true
    },
    update_isAllChecked(val) {
      this.isAllChecked = val;
    },
    async fetchAllDataIds(){
      let items = [];
      try {
        let response = await this.$store.dispatch('FETCH_DATA_LIST', {
          appId: this.currentAppId,
          formId: this.form._id,
          viewId: this.currentView._id,
          skipDependantFields: true,
          pagination: {
            currentPage: 1, 
            pageSize: this.totalNumberOfItems
          }
        });
        this.selections = response.result.map( item => item._id );
      } catch (err) {
        console.error( err );
      }
      
      return items;
    },
    update_totalRecordNumber(val) {
      this.totalNumberOfItems = val;
    },
    track(key){
      this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
          key: "plus_click_functional_button",
          data: {
              function_button: this.$sensors.events.plus_click_functional_button[key],
          }
      });      
    }
  },
};
</script>

<style lang="scss" scoped>  
@import "~vuetify/src/styles/styles.sass";

/*$blockquote-font-size: 24px;*/
/*$my-color: #ff0000;*/
.slide-enter-to{
  width:100%;
}
.slide-enter-active{
  transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
}
.slide-enter{
  opacity: 0;
  width: 0px !important;
}

.slide-leave{
  opacity: 1;
  width: 100% !important;
}

.slide-leave-active {
  transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
}
.slide-leave-to {
  opacity: 0;
  width: 0% !important;
}
.right-drawer {
  top: 48px !important;
  bottom: 0px !important;
  height: auto !important;
  z-index: 8;
}

.height-button {
  width: 32px !important;
}

</style>
