var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-grid',_vm._b({ref:"xTable",staticClass:"batchInserttableViewGrid",staticStyle:{"height":"0","overflow":"auto","min-height":"100%"},attrs:{"edit-config":{
          trigger: 'click',
          mode: 'cell',
          activeMethod: _vm.activeRowMethod,
          showStatus: false,
          showAsterisk: true
        },"menu-config":_vm.tableMenu,"cell-class-name":_vm.rowClasses,"id":_vm.formId,"loading":_vm.loading,"edit-rules":_vm.validRules,"keep-source":"","show-header-overflow":"","data":_vm.tableData,"auto-resize":"","min-width":"100%"},on:{"menu-click":_vm.contextMenuClickEvent,"edit-actived":_vm.editActiveEvent}},'vxe-grid',_vm.gridOptions,false),[_c('vxe-table-column',{attrs:{"type":"seq","min-width":"40","width":"40","fixed":"left","class-name":"text-center position-relative vxeCellEdit","header-class-name":"text-center vxeCellEdit"}}),_vm._l((_vm.colDefs),function(colDef,index){return [(colDef.componentName !== '')?_c('vxe' + colDef.componentName,{key:index,tag:"component",attrs:{"colDef":colDef,"relationData":_vm.relationData},on:{"onCommand":_vm.onCommandHandler}}):_c('vxe-table-column',_vm._b({key:index},'vxe-table-column',colDef,false))]}),_c('vxe-table-column',{attrs:{"title":_vm.$t('general.operation'),"width":100,"fixed":"right","show-overflow":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var rowIndex = ref.rowIndex;
return [_vm._t("control",function(){return [_c('v-btn',{staticClass:"mx-1",attrs:{"dark":"","elevation":false,"x-small":"","fab":"","color":"error"},nativeOn:{"click":function($event){return _vm.deleteRow(row, rowIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]},{"row":row,"rowIndex":rowIndex})]}}],null,true)})],2),_c('confirm-delete-dialog',{ref:"confirmDeleteDialog"}),_c('confirm-dialog',{ref:"confirmDialog"}),_c('popup-record-dialog',{ref:"popupRecordDialog",on:{"onCommand":_vm.onCommandHandler}}),_c('select-related-records-dialog',{ref:"selectRelatedRecordsDialog",on:{"click:new":_vm.newRelatedRecord}}),_c('select-related-cards-dialog',{ref:"selectRelatedCardsDialog",on:{"click:new":_vm.newRelatedRecord}}),_c('dialog-button-select-employees',{ref:"tableEesDialog",staticClass:"pa-0",attrs:{"addButton":false},model:{value:(_vm.showEesDialog),callback:function ($$v) {_vm.showEesDialog=$$v},expression:"showEesDialog"}}),_c('popupRecordDialog',{ref:"popupRecordDialog",on:{"ready":function($event){_vm.loading=false}}}),_c('select-calc-method-menu',{ref:"selectCalcMethodMenu"}),_c('v-snackbar',{attrs:{"top":"","centered":"","elevation":"0","timeout":"2000","content-class":"d-flex justify-center","color":_vm.snackbarColor},model:{value:(_vm.showingSnackbar),callback:function ($$v) {_vm.showingSnackbar=$$v},expression:"showingSnackbar"}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.snackbarContent)+" ")])]),(_vm.showingMembersMenu)?_c('members-menu',{ref:"membersMenu",attrs:{"fullWidth":true,"attachId":_vm.membersMenuPayload.attachedId},on:{"memberClick":_vm.handleMemberClick},model:{value:(_vm.showingMembersMenu),callback:function ($$v) {_vm.showingMembersMenu=$$v},expression:"showingMembersMenu"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }