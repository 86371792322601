<template>
<div class="flex-grow-1 d-flex flex-column fill-height mt-0 w-100" ref="form">
  <div class="w-100 pb-0 flex-grow-0 justify-start">
    <label>{{ $t('inputPanel.title') }}</label>
    <v-text-field 
      ref="title"
      solo
      dense
      :rules="[()=>!!inputPanel.title || $t('messages.thisFieldIsRequired')]"
      outlined
      v-model="inputPanel.title"/>
  </div>
  <div class="d-flex flex-row flex-grow-0">
    <!------------------
    Master Form Column
    ------------------->
    <div style="width:50%;" class="mr-2 fill-height">
      <div class="d-flex fill-height flex-column">
        <h3 class="w-100">{{ $t('inputPanel.masterForm') }}</h3>

        <label>{{ $t('inputPanel.form') }}</label>
        <div>
          <form-selection-menu
            ref="masterFormId"
            v-model="inputPanel.masterFormId"></form-selection-menu>    
        </div>

        <label>{{ $t('inputPanel.codeField') }}</label>
        <div class="w-100">
          <field-selection-menu
            ref="codeFieldId"
            :items="textFields"
            class="w-100"
            :hasError="!inputPanel.codeFieldId || inputPanel.codeFieldId===''"
            :errorMessage="$t('messages.thisFieldIsRequired')"
            v-model="inputPanel.codeFieldId"></field-selection-menu>
        </div>

        <!-- <h3 class="mt-5 w-100">{{ $t('inputPanel.panelDisplay') }}</h3></h3> -->

        <!-- Cell or Column -->
        <!-- <div class="d-flex flex-row align-top">
          <div class="mr-4">
            <label>{{ $t('inputPanel.displayType') }} {{ inputPanel.displayType }}</label>
            <input-radio-toggle
              v-model="inputPanel.displayType"
              :options="displayTypeOptions"></input-radio-toggle>
          </div>
          <div v-if="inputPanel.displayType==='cells'">
            <label>{{ $t('inputPanel.columnWidth') }}</label>
            <input-radio-toggle
              v-model="inputPanel.columnWidthType"
              :options="columnWidthTypes"></input-radio-toggle>
          </div>
        </div> -->

        <template v-if="false">
          <div class="d-flex flex-row align-top">
            <div class="mr-4">
              <label>{{ $t('inputPanel.hideIfNoRecordYet') }}</label>
              <input-yes-no
                :value="inputPanel.hideIfNoRecord?'true':'false'"
                @input="val=>{inputPanel.hideIfNoRecord=(val==='true')}"></input-yes-no>
            </div>              
            <div class="mr-4">
              <label>{{ $t('inputPanel.useColorIndication') }}</label>
              <input-yes-no
                :value="inputPanel.useColorIndicator?'true':'false'"
                @input="val=>{inputPanel.useColorIndicator=(val==='true')}"></input-yes-no>
            </div>
          </div>

          <label>{{ $t('inputPanel.filters') }}</label>
          <input-panel-filter-setting
            v-if="masterFormFieldInfos.length>0"
            :inputPanel="inputPanel"
            :fieldInfos="masterFormFieldInfos"
            @onCommand="onCommandHandler"></input-panel-filter-setting>
          <div v-else>
            <v-btn x-small disabled fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>

      </div>
    </div>

    <!------------------
    Target Form Column
    ------------------->
    <div style="width:50%;" class="ml-2 fill-height">
      <div class="d-flex fill-height flex-column">
        <h3 class="w-100">{{ $t('inputPanel.targetForm') }}</h3>
        <label>{{ $t('inputPanel.form') }} ({{ $t('inputPanel.relatedWithMasterForm') }})</label>
        <related-form-selection-menu
              ref="targetFormId"
              :masterForm="masterForm"
              v-model="selectedTargetFormId"></related-form-selection-menu>


        <div class="d-flex flex-row align-center">
          <label>{{ $t('inputPanel.targetField') }}</label>      
          <div class="ml-3" style="color:rgba(128,128,128,.8);">({{ $t('inputPanel.fieldWithDateTimeType') }})</div>
        </div>
        <field-selection-menu
          ref="targetFieldId"
          :items="targetFormDateTimeFields"
          class="w-100"
          :hasError="!inputPanel.targetFieldId || inputPanel.targetFieldId===''"
          :errorMessage="$t('messages.thisFieldIsRequired')"
          v-model="inputPanel.targetFieldId"></field-selection-menu>

            <!-- <time-segment-list
              ref="timeSegments"
              :targetForm="targetForm"
              v-model="inputPanel.timeSegments"></time-segment-list> --> 
      </div>
    </div>
  </div>
  <div class="d-flex flex-row flex-grow-1">
    <div style="width:50%;" class="d-flex flex-column mr-2 fill-height">
      <label class="flex-grow-0">{{ $t('inputPanel.cardDisplaySetup') }}</label>
      <div class="d-flex flex-grow-1 flex-column border py-2">
        <div style="height:0;min-height:100%;overflow-y:scroll;">
          <relation-card-config 
            v-if="masterForm"
            cardType="formCard"
            v-model="inputPanel.cardConfig" 
            :formFieldInfos="masterFormFieldInfos"
            :titleFieldInfoId="masterForm.titleFieldInfoId">
          </relation-card-config>
        </div>
      </div>
    </div>
    <div style="width:50%;" class="mr-2 fill-height">
      <div class="d-flex fill-height flex-column">
        
      </div>
    </div>
  </div>
</div>
</template>

<script>
import filterHelperMixin from '@/mixins/filterHelperMixin' 
import formTitle from '@/components/FormTitle'
import inputRadioToggle from '@/components/formComps/InputRadioToggle'
import inputYesNo from '@/components/formComps/InputYesNo'
import timeSegmentList from './TimeSegmentList'
import formSelectionMenu from './FormSelectionMenu'
import fieldSelectionMenu from './FieldSelectionMenu'
import relatedFormSelectionMenu from './RelatedFormSelectionMenu'
import inputPanelFilterSetting from './InputPanelFilterSetting'
import relationCardConfig from '@/components/dialogs/viewSettingsTabs/comps/RelationCardConfig'
export default {
  name: 'inputPanelSettingsForm',
  mixins: [filterHelperMixin],
  components:{
    formTitle,
    inputRadioToggle,
    inputYesNo,
    timeSegmentList,
    formSelectionMenu,
    fieldSelectionMenu,
    relatedFormSelectionMenu,
    inputPanelFilterSetting,
    relationCardConfig
  },
  model: {
    prop: 'inputPanel',
    event: 'input'
  },
  props: {
    inputPanel: Object
  },
  data () {
    return {
      masterForm: null,
      targetForm: null,
      displayTypeOptions: [
        {label: this.$t('inputPanel.dataCell'), value: 'cells'},
        {label: this.$t('inputPanel.column'), value: 'column'}
      ],
      columnWidthTypes: [
        {label: this.$t('inputPanel.smallest'), value: 'x-small'},
        {label: this.$t('inputPanel.small'), value: 'small'},
        {label: this.$t('inputPanel.normal'), value: 'normal'},
        {label: this.$t('inputPanel.large'), value: 'large'},        
        {label: this.$t('inputPanel.largest'), value: 'x-large'},        
        {label: this.$t('inputPanel.auto'), value: 'auto'},        
      ],
      displayFieldIds: [],
      loading: false,
      optionValues: [
        {label: ''}
      ],
      formHasErrors: false,
      errorMessages: []
    }
  },

  computed:{
    selectedTargetFormId: {
      get () {
        return this.targetFormId
      },
      set (val) {
        console.log('computed(selectedTargetFormId): val: ', val)        
        const fieldId = this.getRelatedFieldIdByFormId(val)
        console.log('computed(selectedTargetFormId): fieldId = ' + fieldId)
        this.inputPanel.masterFieldForTarget = fieldId
        this.$nextTick(() => {
          this.loadTargetForm()
        })
      }
    },
    hasError () {
      return this.formHasErrors 
      // this.validateForm()
    },    
    targetFormId () {
      const vm = this
      var result = ''
      if (vm.masterForm && vm.inputPanel.masterFieldForTarget) {
        const fieldInfo = vm.masterForm.fieldInfos.find(info => {
          return (info.type === 'relatedRecord') &&
            (info.fieldId === vm.inputPanel.masterFieldForTarget)
        })
        if (fieldInfo) {
          result = fieldInfo.properties.dataSource
        }
      }
      return result
    },



      // const vm = this
      // var result = ''
      // if (vm.inputPanel && vm.inputPanel.masterFieldForTarget && vm.masterForm) {

      //   const relatedFieldInfo = vm.masterForm.fieldInfos.find(info => info.fieldId === vm.inputPanel.masterFieldForTarget)
      //   if (relatedFieldInfo) {
      //     result = relatedFieldInfo._id
      //   }
      // }
      // return result      

    inputForm () {
      return {
        // 
        // {ref} : {related field}
        title: this.inputPanel.title,
        masterFormId: this.inputPanel.masterFormId,
        codeFieldId: this.inputPanel.codeFieldId,
        targetFormId: this.inputPanel.targetFormId,
        targetFieldId: this.inputPanel.targetFieldId
        // timeSegments: this.inputPanel.timeSegments
      }
    },
    selectedMenuItem () {
      return this.$store.getters.selectedMenuItem
    },
    selectedMenuItemId () {
      return this.$store.getters.selectedMenuItemId
    },
    modules () {
      return this.$store.getters.currentApp.modules
    },
    masterFormFieldInfos () {
      const vm = this
      var result = []
      if (vm.masterForm) {
        result = vm.masterForm.fieldInfos
      }
      return result
    },
    textFields () {
      const vm = this
      var result = []
      if (vm.masterFormFieldInfos) {
        const fieldInfos = vm.masterFormFieldInfos.filter(info => info.type === 'text')
        for (let i = 0; i < fieldInfos.length; i++) {
          const loopInfo = fieldInfos[i]
          result.push({
            _id: loopInfo.fieldId,
            label: loopInfo.label
          })
        }
      }
      return result
    },
    filterInfos () {
      return this.$store.getters.filterInfos
    },
    targetFormDateTimeFields () {
      const vm = this
      var result = []
      if (vm.targetForm && vm.targetForm.fieldInfos) {
        const fieldInfos = vm.targetForm.fieldInfos.filter(info => {
          return (info.type === 'date') &&
            (info.properties.fieldType === 'datetime')
        })
        for (let i = 0; i < fieldInfos.length; i++) {
          const loopInfo = fieldInfos[i]
          result.push({
            _id: loopInfo.fieldId,
            label: loopInfo.label
          })
        }
      }
      return result
    }
  },
  watch: {
    'inputPanel.masterFormId': function(newVal) {
      this.loadMasterForm()
    },
  },
  async mounted () {
    const vm = this
    console.log('InputPanelSettingsForm.mounted')
    vm.loadMasterForm(() => {
      vm.loadTargetForm()
    })
  },
  methods: {
    getRelatedFieldIdByFormId (formId) {
      const vm = this
      var result = ''
      if (vm.masterForm) {
        const fieldInfo = vm.masterForm.fieldInfos.find(info => {
          return (info.type === 'relatedRecord') &&
            (info.properties.dataSource === formId)
        })
        if (fieldInfo) {
          result = fieldInfo.fieldId
        }
      }
      return result
    },
    // getFormIdFromFieldId (fieldId) {
    //   const vm = this
    //   var result = ''
    //   if (vm.masterForm) {
    //     const fieldInfo = vm.masterForm.fieldInfos.find(info => {
    //       return (info.type === 'relatedRecord') &&
    //         (info.fieldId === fieldId)
    //     })
    //     if (fieldInfo) {
    //       result = fieldInfo.properties.dataSource
    //     }
    //   }
    //   return result
    // },

    resetValidation () {
      this.errorMessages = []
      this.formHasErrors = false
      Object.keys(this.inputForm).forEach(f => {
        this.$refs[f].resetValidation()
      })
    },

    validateForm () {
      const vm = this
      this.formHasErrors = false
      Object.keys(this.inputForm).forEach(f => {
        // if (!this.form[f]) {
        //   console.log('form: f=' + f + ' has error')
        //   this.formHasErrors = true
        // }
        console.log('validateForm f = ' + f)
        if (!vm.$refs[f].validate(true)) {
          this.formHasErrors = true
        }

        console.log('f: ', f)
        console.log('formHasErrors = ' + (this.formHasErrors))
        // if (!this.form[f]) {
        //   console.log('form: f=' + f + ' has error')
        //   this.formHasErrors = true
        // }
        // this.$refs[f].validate(true)
      })
      return this.formHasErrors
    },

    loadMasterForm (callback) {
      const vm = this
      vm.loadForm(vm.inputPanel.masterFormId, response => {
        vm.masterForm = response
        if (typeof callback === 'function') {
          callback()
        }
      })
    },

    loadTargetForm() {
      const vm = this
      console.log('loadTargetForm vm.targetFormId = ' + vm.targetFormId)
      vm.loadForm(vm.targetFormId, response => {
        vm.targetForm = response
      })
    },
    
    loadForm (formId, callback) {
      const vm = this
      if (formId) {
        const payload = {
          formId: formId,
          appId: vm.$route.params.id
        }
        vm.$store.dispatch('FETCH_FORM', payload).then(
          response => {
            if (typeof callback === 'function') {
              callback(response)
            }
          }
        )
      }
    },
    addFilter (fieldId) {
      const vm = this
      const widgetMap = vm.$store.getters.widgetMap
      const fieldInfo = vm.masterFormFieldInfos.find(info => info.fieldId === fieldId)
      var filterKey = vm.getFilterKeyByFieldInfo(widgetMap, fieldInfo)
      const filterInfo = vm.filterInfos[filterKey]
      var relation = ''
      if (filterInfo && filterInfo.options && filterInfo.options.length>0) {
        relation = filterInfo.options[0]
      }
      var filterValue1 = ''
      if (relation === 'toggle') {
        filterValue1 = 'yes'
      }
      vm.inputPanel.filterConfig.filters.push({
        fieldId: fieldId,
        relation: relation,
        filterValue1: filterValue1,
        filterValue2: ''
      })
    },

    deleteFilter (fieldIndex) {
      this.inputPanel.filterConfig.filters.splice(fieldIndex, 1)
    },

    deleteAllFilters () {
      this.inputPanel.filterConfig.filters = []
    },

    onCommandHandler (payload) {
      const vm = this
      switch (payload.command) {
        case 'addFilter':
          vm.addFilter(payload.fieldId)
          if (typeof payload.callback === 'function') {
            payload.callback()
          }
          break
        case 'deleteFilter':
          vm.deleteFilter(payload.filterIndex)
          break
        case 'deleteAllFilters':
          vm.deleteAllFilters()
          break
      }
    },

  }
}
</script>