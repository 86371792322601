<template>
  <div class="landing-option-cell row align-center">
    <v-avatar v-if="src || icon" color="#e8fffe" class="mr-5" size="40">
      <img v-if="src" />
      <v-icon v-else-if="icon" color="#207a8e">{{ icon }}</v-icon>
    </v-avatar>
    {{ text }}
    <v-spacer/>
    <v-icon>mdi-chevron-right</v-icon>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    icon: String,
    src: String,
  },
};
</script>
<style lang="scss">
.landing-option-cell {
  border: 1px solid #e5e6eb;
  border-radius: 4px;
  text-align: start;
  padding: 20px;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
  }
  
}

.landing-option-cell:hover {
      background-color: #F7F8FA;
      cursor: pointer;
  }
</style>
