<template>
    <div style="padding-bottom:0;position:relative;"
        @mouseenter="onMouseEnter"
        :class="{'active-column':isActive}"
         class="d-flex flex-column fill-height card-board-column">
              <div v-if="column" class="fill-height d-flex flex-column card-container">
                <div class="card-column-header flex-grow-0 d-flex flex-row align-center mb-1">
                  <span v-if="!!column.id" class="mr-1 column-drag-handle">&#x2630;</span>
                  <!-- <div class="d-flex flex-column"> -->
                    {{ column ? column.label : ''}}<br/>
                    <!-- <v-chip x-small color="primary">{{ column.id }}</v-chip>
                  </div> -->
                  <!--({{ column.id }})-->
                  <v-spacer></v-spacer>
                  <v-btn color="success" x-small fab
                         class="mr-1"
                         @click="refresh">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn color="primary" x-small fab v-if="hasAddButton"
                         @click="newCard">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                  <div style="height:0;min-height:100%;">
                    <perfect-scrollbar class="fill-height" @ps-y-reach-end="loadMore">

                      <Container
                          v-if="column"
                          class="flex-grow-1"
                          group-name="col"
                          @drop="(e) => onCardDrop(column.id, e)"
                          @drag-start="(e) => log('drag start', e)"
                          @drag-end="(e) => log('drag end', e)"
                          :get-child-payload="getCardPayload(column.id)"
                          drag-class="card-ghost"
                          drop-class="card-ghost-drop"
                          :drop-placeholder="dropPlaceholderOptions"
                      >
                      <Draggable v-for="card in column.cards" :key="card._id"
                                 class="fill-height">
                        <!--<div class="card">-->
                        <!--<span class="mr-1 cursor-move">&#x2630;</span>-->
                        <!--<p>{{ card._id }}sfsdfdsfsdf</p>-->
                        <!--</div>-->
                        <card
                          :view="view"
                          :card="card"
                          :printTemplates="printTemplates"
                          @onCommand="onCommandHandler">
                        </card>
                      </Draggable>
                      <div style="height: 10vh"></div>
                      </Container>
                    </perfect-scrollbar>
                  </div>
                </div>
                <!--<div v-if="true || column.loading" class="spinner-mask fill-height text-center w-100 align-center justify-center position-absolute"-->
                <!--style="background-color:rgba(0,0,0,.5);">-->
                <!--<v-icon color="white"-->
                <!--style="top:50%;margin-top:-30px;"-->
                <!--class="text-h1"x-large>mdi-rotate-right mdi-spin</v-icon>-->
                <!--</div>-->
              </div>
              <div v-if="column && column.loading"
                   class="spinner-mask fill-height text-center w-100 align-center justify-center position-absolute"
                   style="background-color:rgba(0,0,0,.5);">
          <v-icon color="white"
                  style="top:50%;margin-top:-30px;"
                  class="text-h1" x-large>mdi-rotate-right mdi-spin</v-icon>
        </div>
    </div>
</template>

<script>
import {Container, Draggable} from 'vue-smooth-dnd'
import card from './Card'

export default {
  name: 'cardBoardColumn',
  components: {
    Container,
    Draggable,
    card
  },
  props: {
    columns: Array,
    column: Object,
    printTemplates:Array,
    view: Object,
    hasAddButton: {
      type: Boolean,
      default: true
    },
    isActive: Boolean
  },
  data () {
    return {
      // cards: [],
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      }
    }
  },
  computed: {
    groupingFieldId () {
      const vm = this
      var result = ''
      if (vm.view && vm.view.groupingFieldId) {
        result = vm.view.groupingFieldId
      }
      return result
    },
    currentApp () {
      return this.$store.getters.currentApp
    },
    form () {
      return this.$store.getters.currentForm
    },
  },
  // mounted () {
  //   console.log('CardBoardColumn :: mounted')
  //   const vm = this
  //   vm.loadCards()
  // },
  methods: {
    onMouseEnter (evt) {
      const vm = this

      vm.$emit('onHovering', vm.column)
    },
    loadMore (evt) {
      const vm = this
      //console.log('touch bottom: evt: ', evt)
      if (!vm.column.atLastPage) {
        vm.$emit('onCommand', {
          command: 'loadMore',
          column: vm.column
        })
      }
    },
    refresh () {
      const vm = this
      vm.column.loading = true
      vm.$emit('onCommand', {
        command: 'refreshColumn',
        column: vm.column
      })
    },
    newCard () {
      const vm = this
      vm.$emit('onCommand', {
        command: 'newCard',
        column: vm.column
      })
    },
    onCommandHandler (payload) {
      this.$emit('onCommand', {...payload, column: this.column })
    },
    // loadCards () {
    //   const vm = this
    //   const filterValue = vm.column.id
    //   const statusFilter = {}
    //   statusFilter[vm.groupingFieldId] = filterValue
    //   const params = {
    //     appId: vm.currentApp._id,
    //     formId: vm.form._id,
    //     viewId: vm.view._id,
    //     filter: statusFilter,
    //     count: vm.pageNo*vm.perLoading,
    //     sortingFieldId: 'createdAt',
    //     sortingOrder: 'desc'
    //   }
    //   vm.$store.dispatch('FETCH_DATA_LIST', params).then(
    //     response => {
    //       vm.cards = response.result
    //     }
    //   )
    // },
    onCardDrop (columnId, dropResult) {
      const vm = this
      console.log('CardBoardColumn.vue onCardDrop :: columnId = ' + columnId)
      vm.$emit('onCardDrop', {
        columnId: columnId,
        dropResult: dropResult
      })
    },
    getCardPayload (columnId) {
      const vm = this
      if (vm.columns) {
        return index => {
          console.log('getCardPayload function index = ' + index)
          // return vm.cards[index]
          return this.columns.filter(p => p.id === columnId)[0].cards[index]
        }
      } else {
        return index => {
          return this.column.cards[index]
        }
      }
    },
    log (...params) {
      console.log(...params)
    }
  }
}
</script>

<style>
.card-board-column.active-column {
  background-color: lightblue !important;
}
</style>
