<template>
  <input-widget-container
      :isLayout="isLayout"
      :widget="widget"
      :required="required"
      :isHidden="isHidden"
      :fieldWidth="fieldWidth"
      :label="' '"
  >
  <div class="ql-editor inputRemark">
    <div :class="(fieldInfo.properties.remark)?'':'grey--text py-4 pa-2'" 
        style="word-wrap: break-word; width: 100%"
        v-html="(isLayout)?fieldInfo.properties.remark || $t('widgets.properties.defaultRemark'):fieldInfo.properties.remark"
    >
    </div>
  </div>
  </input-widget-container>
</template>

<script>
import mixin from './mixin'
import inputWidgetContainer from './comps/InputWidgetContainer'

export default {
  name: 'inputWidgetText',
  mixins: [mixin],
  components: {
    inputWidgetContainer
  },

}
</script>

<style>
.inputRemark img {
  max-width: 100%;
  object-fit: contain;
}
</style>

<style lang="scss" scoped>
.inputRemark {
  margin-bottom: 0px !important;
  padding: 0px
}
</style>
