import {
  FETCH_ACTION_BUTTON,
  RESET_ACTION_BUTTON,
  INTERACT_ACTION_BUTTOIN,
  VIEW_ACTION_BUTTON_STATUS,
  UPDATE_ACTION_BUTTON,
  REMOVE_ACTION_BUTTON,
  CREATE_ACTION_BUTTON,
  FETCH_ACTION_BUTTON_INFO,
  UPDATE_ACTION_BUTTON_INFO,
  POPUP_ACTION_BUTTON_POPUP,
  REORDER_ACTION_BUTTON,
  FETCH_ACTION_BUTTON_OPTIONS,
  SET_GLOBAL_ACTION,
  RESET_GLOBAL_ACTION,
  APPEND_ACTION_BUTTON
} from './action_types';
import { pick, map, isArray } from 'lodash';
import moment from 'moment';
import {
  RESET_EDITOR, RESET_WORKFLOW_VALIDATE,
} from './../workflow/action_types';
import NodeUtils from '@/pages/admin/workflow/utils/node';
import { app } from "@/main"

const initialState = () => ({
  popup: -1,
  action: {
    type: null,
    selected: [],
    ts: null,
  },
  buttons: {
    loading: false,
    list: [],
    count: 0,
    error: null,
    all: [],
    allCount: 0
  },
  buttonOptions: {
    loading: false,
    list: [],
    error: null,
  },
  button: {
    loading: false,
    info: {},
    error: null
  },
  interactButton: {
    loading: false,
    info: {},
    error: null,
  },
  reorderButtons: {
    loading: false,
    error: null,
  },
  buttonStatus: {
    loading: false,
    info: {},
    error: null,
  },
  updateButton: {
    loading: false,
    info: {},
    error: null,
  },
  createButton: {
    loading: false,
    info: {},
    error: null,
  },
  appendButton:{
    loading: false,
    info: {},
    error: null
  },
  removeButton: {
    loading: false,
    info: {},
    error: null,
  },
});

const getters = {
  isPopupActionButton: state => state.popup,

  actionType: state => state.action.type,
  actionClickTs: state => state.action.ts,
  actionSelectedData: state => state.action.selected,

  getActionButtons: state => state.buttons.list,
  getAllActionButtons: state => state.buttons.all,
  totalActionButtons: state => state.buttons.count,
  totalAllActionButtons: state => state.buttons.allCount,
  fetchingActionButtons: state => state.buttons.loading,

  getActionButtonOptions: state => state.buttonOptions.list,
  fetchingActionButtonOptions: state => state.buttonOptions.loading,

  getActionButton: state => state.button.info,
  fetchingActionButton: state => state.button.loading,

  loadingAnyAction: ({ buttonStatus, removeButton }) => 
    buttonStatus.loading || removeButton.loading, 

  interactedButton: state => state.interactButton.info,
  interacting: state => state.interactButton.loading,

  createdButton: state => state.createButton.info,
  creatingButton: state => state.createButton.loading,

  updatedButton: state => state.updateButton.info,
  updatingButton: state => state.updateButton.loading,

  processAnyAction: ({ updateButton, createButton }) => 
    updateButton.loading || createButton.loading, 

  anyButtons: state => {
    const { list=[] } = state.buttonOptions;
    return list && list.length > 0;
  },
}

const withListItem = (list, info) => {
  return NodeUtils.findStoreListItem(list, info, (current, i) => (
    current._id === i._id
  ));
}

const mutations = {
  resetActionButtons(state) {
    const wasState = initialState();
    state.buttons = { ...wasState.buttons };
    state.interactButton = { ...wasState.interactButton };
    state.buttonStatus = { ...wasState.buttonStatus };
    state.createButton = { ...wasState.createButton };
    state.updateButton = { ...wasState.updateButton };
    state.buttonOptions = { ...wasState.buttonOptions };
    state.removeButton = { ...wasState.removeButton };
    state.reorderButtons = { ...wasState.reorderButtons };
    state.popup = wasState.popup;
    state.button = { ...wasState.button };
  },

  setGlobalAction (state, { type, data }) {
    state.action.type = type;
    state.action.selected = data ? isArray(data) ? data : [data] : [];
    state.action.ts = {
      s: moment().valueOf(),
      data: state.action.selected,
    };
  },

  turnPopup (state, tabIndex) {
    state.popup = tabIndex;
  },
  requestActionButtons(state) {
    state.buttons.loading = true;
  },
  fetchedActionButtons(state, list) {
    state.buttons.loading = false;
    state.buttons.list = [ ...list ];
    state.buttons.count = list.length;
  },
  fetchedAllActionButtons(state, list) {
    console.log('fetchedAllActionButtons::list', list)
    state.buttons.loading = false;
    state.buttons.all = [ ...list ];
    console.log('state.buttons.all',  state.buttons.all)
    state.buttons.allCount = list.length;
  },
  fetchActionButtonsFailed(state, error) {
    state.buttons.loading = false;
    state.buttons.error = error;
  },

  requestActionButtonOptions(state) {
    state.buttonOptions.loading = true;
  },
  fetchedActionButtonOptions(state, list) {
    state.buttonOptions.loading = false;
    state.buttonOptions.list = [ ...list ];
  },
  fetchActionButtonOptionsFailed(state, error) {
    state.buttonOptions.loading = false;
    state.buttonOptions.error = error;
  },

  requestActionButton(state) {
    state.button.loading = true;
  },
  fetchedActionButton(state, info) {
    state.button.loading = false;
    state.button.info = info;
    state.button.info.popup = state.button.info.popup||{
      popup:{
        title: '',
        confirmLabel: '',
        cancelLabel: ''
      }
    }
  },
  fetchActionButtonFailed(state, error) {
    state.button.loading = false;
    state.button.error = error;
  },

  requestReorderActionButton(state) {
    state.reorderButtons.loading = true;
  },
  fetchedReorderActionButton(state) {
    state.reorderButtons.loading = false;
  },
  fetchReorderActionButtonFailed(state, error) {
    state.reorderButtons.loading = false;
    state.reorderButtons.error = error;
  },

  updateActionButtonInfo(state, { field, content }) {
    const { info={} } = state.button;
    state.button.info = { ...info, [field]: content };
  },

  requestButtonStatus(state) {
    state.buttonStatus.loading = true;
    state.buttonStatus.error = null;
  },
  statusTheButton(state, info) {
    state.buttonStatus.loading = false;
    // state.buttonStatus.info = info;
    // const { list=[] } = state.buttons;
    // // update the state realtime
    // const { index, item } = withListItem(list, info);
    // if (item) {
    //   list[index] = { ...item, display: info.display };
    //   state.buttons.list = [ ...list ];
    // }
  },
  statusTheButtonFailed(state, error) {
    state.buttonStatus.loading = false;
    state.buttonStatus.error = error;
  },

  requestRemoveButton(state) {
    state.removeButton.loading = true;
    state.removeButton.error = null;
  },
  removingButton(state, info) {
    state.removeButton.loading = false;
    state.removeButton.info = info;
    // remove the button item realtime
    const { list=[] } = state.buttons;
    const { index, item } = withListItem(list, info);
    if (item) {
      list.splice(index, 1);
      state.buttons.list = [ ...list ];
      state.buttons.count = list.length;
    }
  },
  removeButtonFailed(state, error) {
    state.removeButton.loading = false;
    state.removeButton.error = error;
  },

  requestCreateActionButton(state) {
    state.createButton.loading = true;
    state.createButton.error = null;
  },
  createdActionButton(state, info) {
    state.createButton.loading = false;
    state.createButton.info = info;
    const { list=[] } = state.buttons;
    list.splice(list.length, 0, info);
    state.buttons.list = [ ...list ];
    state.buttons.count = list.length;
  },
  createActionButtonFailed(state, error) {
    state.createButton.loading = false;
    state.createButton.error = error;
  },

  requestAppendActionButton(state) {
    state.appendButton.loading = true;
    state.appendButton.error = null;
  },
  appendedActionButton(state, info){
    state.buttons.list.push(info)
    state.buttons.count = state.buttons.list.length;
    state.appendButton.loading = false
    state.appendButton.info = info;
  },
  appendActionButtonFailed(state, error){
    state.appendButton.loading = false
    state.appendButton.error = error;
  },

  requestUpdateActionButton(state) {
    state.updateButton.loading = true;
    state.updateButton.error = null;
  },
  updatedActionButton(state, info) {
    state.updateButton.loading = false;
    state.updateButton.info = info;
    const { list=[] } = state.buttons;
    // update the state realtime
    const { index, item } = withListItem(list, info);
    if (item) {
      const updatedInfo = pick(info, [
        'display', 'color', 
        'name', 'type', 
        'remark', 'icon', 
        'noWorkflow',
        'workflow', 'popup', 
        'ordering',
      ]);
      list[index] = { ...item, ...updatedInfo };
      state.buttons.list = [ ...list ];
    }
  },
  updateActionButtonFailed(state, error) {
    state.updateButton.loading = false;
    state.updateButton.error = error;
  },


  requestInteraction(state) {
    state.interactButton.loading = true;
    state.interactButton.error = null;
  },
  interactedButton(state, info) {
    state.interactButton.loading = false;
    state.interactButton.info = info;
  },
  interactButtonFailed(state, error) {
    state.interactButton.loading = false;
    state.interactButton.error = error;
  },

  requestInteraction(state) {
    state.interactButton.loading = true;
    state.interactButton.error = null;
  },
  interactedButton(state, info) {
    state.interactButton.loading = false;
    state.interactButton.info = info;
  },
  interactButtonFailed(state, error) {
    state.interactButton.loading = false;
    state.interactButton.error = error;
  },
}
const actions = {
  [RESET_EDITOR]({commit}) {
    commit('resetActionButtons');  
  },
  [RESET_ACTION_BUTTON]({commit}) {
    commit('resetActionButtonsInfo');  
  },
  [UPDATE_ACTION_BUTTON_INFO]({commit}, info) {
    commit('updateActionButtonInfo', info);  
  },
  [POPUP_ACTION_BUTTON_POPUP]({commit}, index=4) {
    commit('turnPopup', index);  
  },

  [SET_GLOBAL_ACTION]({commit}, { type, data }) {
    commit('setGlobalAction', { type, data });  
  },
  [RESET_GLOBAL_ACTION]({commit}) {
    commit('setGlobalAction', { type: null, data: null });  
  },
  [FETCH_ACTION_BUTTON_INFO] ({dispatch, commit}, { formId, buttonId, appId }) {
    commit('requestActionButton');
    if (buttonId) {
      const options = {
        urlCommand: `/customized/action/${formId}/${buttonId}?appId=${appId}`,
      };
      dispatch('AUTH_GET', options).then(response => {
        commit('fetchedActionButton', response);
      }).catch(err => {
        commit('statusTheButtonFailed', err.message);
      })
    } else {
      commit('fetchedActionButton', {});
    }
  },
  [VIEW_ACTION_BUTTON_STATUS] ({dispatch, commit}, { formId, buttonId, appId, viewId, status='normal' }) {
    commit('requestButtonStatus');
    let options = {
      urlCommand: `/customized/action/${formId}/${buttonId}/status?appId=${appId}&viewId=${viewId}`,
      data: {
        status,
      }
    };
    return new Promise((resolve , reject)=>{
      dispatch('AUTH_POST', options).then(response => {
        commit('statusTheButton', response);
        resolve(response)
      }).catch(err => {
        commit('statusTheButtonFailed', err.message);
      })
    })
  },
  [REORDER_ACTION_BUTTON] ({dispatch, commit}, { formId, appId, buttons }) {
    commit('requestReorderActionButton');
    const options = {
      urlCommand: `/customized/action/${formId}/reorder?appId=${appId}`,
      data: {
        orderings: map(buttons, '_id'),
      }
    };
    dispatch('AUTH_POST', options).then(() => {
      commit('fetchedReorderActionButton');
    }).catch(err => {
      commit('fetchReorderActionButtonFailed', err.message);
    })
  },
  [REMOVE_ACTION_BUTTON] ({dispatch, commit, state}, { formId, buttonId, appId, viewId, fully=false }) {
    commit('requestRemoveButton');
    let urlCommand = `/customized/action/${formId}/${buttonId}?appId=${appId}&viewId=${viewId}`;
    if (fully) {
      urlCommand = `${urlCommand}&fully=true`;
    }
    const options = {
      urlCommand,
    };
    return new Promise((resolve , reject)=>{
      dispatch('AUTH_DELETE', options).then(response => {
        commit('removingButton', {_id: buttonId});
        resolve(response.data)
      }).catch(err => {
        commit('removeButtonFailed', err.message);
        reject(err)
      })
    })
    // return targetView.customizedActionButtons
  },
  [FETCH_ACTION_BUTTON_OPTIONS] ({dispatch, commit}, { formId, appId, viewId, withHide=false }) {
    commit('requestActionButtonOptions');
    let urlCommand = `/customized/action/${formId}?appId=${appId}&viewId=${viewId}`;
    if (withHide) {
      urlCommand = `${urlCommand}&withHide=true`;
    }
    const options = {
      urlCommand,
    };

    dispatch('AUTH_GET', options).then(response => {
      commit('fetchedActionButtonOptions', response);
    }).catch(err => {
      commit('fetchActionButtonOptionsFailed', err.message);
    })
  },
  [FETCH_ACTION_BUTTON] ({dispatch, commit}, { formId, appId, viewId, withHide=false }) {
    commit('requestActionButtons');
    let urlCommand = `/customized/action/${formId}?appId=${appId}&viewId=${viewId}`;
    if (withHide) {
      urlCommand = `${urlCommand}&withHide=true`;
    }
    const options = {
      urlCommand,
    };

    dispatch('AUTH_GET', options).then(response => {
      if(viewId !== null)
        commit('fetchedActionButtons', response);
      else
        commit('fetchedAllActionButtons', response);
    }).catch(err => {
      commit('fetchActionButtonFailed', err.message);
    })
  },
  [INTERACT_ACTION_BUTTOIN] ({dispatch, commit, rootState}, { buttonId, appId, formId, data }) {
    commit('requestInteraction');
    const options = {
      urlCommand: `/customized/action/${formId}/${buttonId}?appId=${appId}&socketId=${rootState.socketService.connectionId}`,
      data: { data }
    };
    dispatch('AUTH_POST', options).then(response => {
      commit('interactedButton', response);
    }).catch(err => {
      commit('interactButtonFailed', err.message);
      // dispatch(LOG_VALIDATE_WORKFLOW, data);
    })
  },

  async [CREATE_ACTION_BUTTON] ({dispatch, commit}, { appId, formId, viewId, data }) {
    commit('requestCreateActionButton');
    const options = {
      urlCommand: `/customized/action/${formId}?appId=${appId}&viewId=${viewId}`,
      data,
    };
  
    dispatch('AUTH_POST', options).then(response => {
      commit('createdActionButton', response);
      if (!data.noWorkflow && !data.workflow){
        app.$sensors.track("plus_workflow_add",{
          workflow_name: data.info.name,
          workflow_type: "手動",
          form_id:formId,
          app_id:appId
        })
      }
    }).catch(err => {
      commit('createActionButtonFailed', err.message);
    })
  },
  
  async [APPEND_ACTION_BUTTON] ({dispatch, commit, state}, { appId, formId, viewId, button }){
    commit('requestAppendActionButton');
    const options = {
      urlCommand: `/customized/action/${formId}/${button._id}/append?appId=${appId}&viewId=${viewId}`
    };
  
    return new Promise((resolve, reject)=>{
      dispatch('AUTH_POST', options).then(response => {
        commit('appendedActionButton', button);
        // RESET_WORKFLOW_VALIDATE()
        resolve(response)
      }).catch(err => {
        commit('appendActionButtonFailed', err.message);
        reject(err)
      })
    })
    // dispatch('AUTH_POST', options).then(response => {
    //   commit('appendedActionButton', button);
    //   console.log('state.buttons.list', state.buttons.list)
    //   RESET_WORKFLOW_VALIDATE() 
    // state.buttons.list
    // }).catch(err => {
    //   commit('appendActionButtonFailed', err.message);
    // })
  },

  [UPDATE_ACTION_BUTTON] ({dispatch, commit}, { appId, buttonId, formId, data }) {
    commit('requestUpdateActionButton');
    const options = {
      urlCommand: `/customized/action/${formId}/${buttonId}?appId=${appId}`,
      data,
    };
    dispatch('AUTH_PUT', options).then(response => {
      commit('updatedActionButton', response);
    }).catch(err => {
      commit('updateActionButtonFailed', err.message);
    })
  },
}

export default {
  state: initialState(),
  getters,
  mutations,
  actions
}
