<template>
  <v-dialog v-model="showingDialog"  content-class="auto-width">
    <v-card class="auto-width">
      <v-card-text class="px-0">
        <v-row  no-gutters>
          <v-date-picker
            class="no_radius"
            v-model="dialogDateValue"
            scrollable
            flat
            no-footer
            :locale="$i18n.locale"
          />    
          <v-time-picker
            class="no_radius"
            v-model="dialogTimeValue"
            scrollable
            no-footer
            :locale="$i18n.locale"
          >
          </v-time-picker>      
        </v-row>
      </v-card-text>

      <dialog-actions @onCommand="onCommand" />
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from "./comps/DialogHeader";
import DialogActions from "./comps/DialogActions";
export default {
  components: {
    DialogHeader,
    DialogActions,
  },
  props: {
    value: Boolean,
    selectedDate: {
        type: String,
        default: ''
    },
    selectedTime: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
      activeTab: 0,
      dialogDateValue: "",
      dialogTimeValue: "",
    };
  },
  computed: {
    showingDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    selectedDateTime(){
      return this.dialogDateValue + ' ' + this.dialogTimeValue
    }
  },
  methods: {
    formatTime () {
      var dt = new Date(),
        hour = '' + dt.getHours(),
        min = '' + dt.getMinutes()
      if (hour.length < 2) hour = '0' + hour
      if (min.length < 2) min = '0' + min
      return hour + ':' + min
    },
    formatDate () {
      var dt = new Date(),
        month = '' + (dt.getMonth() + 1),
        day = '' + dt.getDate(),
        year = dt.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    onCommand(payload) {
      switch (payload.command) {
        case "cancel":
          this.showingDialog = false;
          break;
        case 'ok':
            this.$emit("confirm", this.selectedDateTime)
            break;
      }
    },
  },
  mounted() {
    if (!this.selectedDate || !this.selectedTime) {
      this.dialogDateValue = this.formatDate();
      this.dialogTimeValue = this.formatTime();
    }
  },
};
</script>

