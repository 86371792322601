<template>
  <FormElement :name="fieldId">
    <div>
      <div class="workflow-setting-form-update-field-from">
        {{ $t(`workflow.modules.${labelType}.label.letField`) }}
      </div>
      <v-layout justify-space-between>
        <div>
          <ComboBox
            :value="fieldInfo || null"
            :options="fields"
            :onlyPrefix="false"
            :onlySuffix="true"
            :isMultiple="false"
            :disabledOnSelect="false"
            :disabledLookup="disabledLookup"
            :placeholder="$t(`workflow.modules.${labelType}.placeholder.chooseField`)"
            valueFormat="object"
            involveRelationshipLabel
            :disableBranchNode="true"
            @changed="v => $emit('fieldChanged', fieldId, v)"
            v-bind="optionProps"
          />
          <div v-if="!!fieldInfo" class="workflow-setting-form-update-field-from-ele">
            <div class="workflow-setting-form-update-field-from">
              {{ $t(`workflow.modules.${labelType}.label.setTo`) }}
            </div>
            <div class="workflow-setting-form-update-field-from-dep">
              <CreateDataForm
                :specifyFieldInfos="fieldInfo && [fieldInfo]"
                :isNew="true"
                :withHideFields="true"
                :withOperation="true"
                showRelationManagement
                idFieldLookup="_id"
                :fieldId="fieldId"
                :worksheet="worksheet"
                widgetTypeMapField="fieldType"
                v-bind="comboProperties"
              />
            </div>
            <v-divider></v-divider>
          </div>
        </div>
        <div class="w-u-remove-icon">
          <v-btn icon @click="remove">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </div>
  </FormElement>
</template>

<script>

import FormElement from './../shared/formElement';
import ComboBox from '@/pages/admin/workflow/components/form/comboBox/';
import CreateDataForm from './../createRecord/form/';
import Mixin from '../mixin';
// import RecordMxiin from '@/mixins/recordMixin';
// import FormElementWidget from "@/components/inputWidgets/InputWidget";
import SettingUtils from "@/pages/admin/workflow/utils/setting";
/*
* Target Module Sub-Forms View Wrapper Components
* Terry Chan
* 28/05/2021
*/
export default {
  mixins: [Mixin],
  props: {
    labelType: String,
    fieldInfo: {
      type: Object,
      default: {},
    },
    worksheet: String,
    dataSource: Object,
    fieldId: String,
    value: Object,
    disabledLookup: {
      type: Object,
      default: {},
    },
    optionProps: {
      type: Object,
      default: {},
    },
    valueProps: {
      type: Object,
      default: {},
    },
    fields: {
      type: Array,
      defualt: [],  
    }
  },
  computed: {
    comboProperties() {
      return {
        ...SettingUtils.comboBoxDataSourceProperties(this.dataSource),
        ...this.valueProps,
      };
    }
  },
  methods: {
    remove() {
      this.$emit('removed', this.fieldId);
    }
  },
  components: {
    ComboBox,
    FormElement,
    CreateDataForm,
  },
};

</script>

<style scoped>
  .w-u-remove-icon {
    width: 50px;
    margin-left: 10px;
  }
</style>
