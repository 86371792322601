<template>
    <div v-if="!isEditing">
      <div class="d-flex align-center my-4 mx-3">
          <div style="height:18px;width:4px;" class="teal lighten-2"></div>
          <div class="ml-2 font-weight-bold" style="font-size:14px">{{$t('settings.bankApi')}}</div>
      </div>
      <div class="" style="overflow: scroll; height:500px">
          <senderCard
              :mailSenders="mailSenders"
              @onCommand="onCommandHandler"
          ></senderCard>
          <v-card class="mx-3 mt-3 elevation-0 mb-6">
              <v-btn
                  depressed
                  class="teal--text text--lighten-2"
                  style="width:100%;"
                  @click="editSender(0)"
              >
                  <v-icon small>
                      mdi-plus
                  </v-icon>
                  <span class="text--error">{{$t('mailSetting.addMailServer')}}</span>
              </v-btn>
          </v-card>
      </div>
  </div>
  <senderConfig 
      v-else
      @onCommand="onCommandHandler"
      ref="senderConfig"
  />
</template>

<script>
export default {
  name: 'bankApiTabContent'  
}
</script>
