<template>
<!--  <div class="flex-grow-1 my-0 ma-2 d-flex flex-column"> -->
  <div class="relation-table-view d-flex flex-column"
    :class="stretch==='true'?'flex-grow-1 pt-1':'mt-1 height-auto'">
    <div style="width:0;min-width:100%;" class="d-flex flex-column flex-grow-1">

      <vxe-toolbar size="small" 
        style="height:40px;"
        :style="{'margin-top':(stretch==='true'?'-32px':'-40px')}"
        :class="stretch==='true'?'flex-grow-0':''">
        <template v-slot:tools v-if="hasControl">
           <v-btn
            v-if="allowDeletion && numberOfSelections > 0"
            small
            color="error"
            @click="deleteItems"
            class="ml-1">
            <v-icon left>mdi-delete</v-icon>{{ $t("buttons.delete") }}</v-btn>
           <v-btn
            v-if="allowUnlinking && numberOfSelections > 0"
            small
            color="error"
            @click="unlinkItems"
            class="ml-1">
            <v-icon left>mdi-link-variant-remove</v-icon>
            {{ $t("buttons.unlink") }}</v-btn>
          <v-btn 
            class="ml-1" 
            small 
            color="primary" 
            @click="onRefreshClicked()">
            <v-icon left>mdi-refresh</v-icon>
            {{ $t('buttons.refresh') }}</v-btn>
        </template>
        <template v-slot:pager>
          <vxe-pager
            perfect
            :layouts="[
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'FullJump',
              'Sizes',
              'Total',
            ]"
            :current-page.sync="pagination.currentPage"
            :page-size.sync="pagination.pageSize"
            :total="pagination.totalResult"
            @page-change="handlePageChange"
          ></vxe-pager>
        </template>
      </vxe-toolbar>
      <div v-if="formInfo" class="flex-grow-1">
        <vxe-grid
          ref="xTable"
          title="relationTableView"
          :edit-config="editConfig"
          :cell-class-name="rowHeightClass"
          v-bind="gridOptions"
          id="relationTableView"
          :loading="loading"
          @edit-closed="editClosedEvent"
          @edit-actived="editActiveEvent"
          keep-source
          :show-header-overflow="true"
          :show-overflow="true"
          size="small"
        >
          <vxe-table-column
            type="seq"
            width="60"
            fixed="left"
            class-name="text-center"
            header-class-name="text-center"
          ></vxe-table-column>
          <!--            :rowHeightClass="rowHeightClass" -->
          <template v-for="(colDef, index) in colDefs">
            <component
              v-if="colDef.componentName !== ''"
              :is="'vxe' + colDef.componentName"
              :colDef="colDef"
              :relationData="relationData"
              :key="index"
              @onCommand="onCommandHandler"
            ></component>
            <!--@input="value=>updateFieldValue(row._id,colDef,value)"></component>-->
            <vxe-table-column
              v-else
              v-bind="colDef"
              :key="index"
            ></vxe-table-column>
          </template>
          <!--
          <table-action-button-column
                :buttons="actionButtons"
                :hiddenButtonList="hiddenButtonList"
                @onClick="onActionButtonClicked"></table-action-button-column>
  -->
          <vxe-table-column
            :title="$t('general.operation')"
            min-width="220"
            fixed="right"
            v-if="hasControl"
          >
            <template v-slot="{ row }">
              <!-- isUpdatedByRow: {{ $refs.xTable ? $refs.xTable.isUpdateByRow(row) : false }}  -->
              <!-- Inline-Edit -->
              <v-btn
                v-if="!isEditing(row) && !isReadOnly"
                class="ml-1"
                dark
                x-small
                :elevation="false"
                fab
                color="primary"
                @click.native="setActiveRow(row)"
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <!-- Save -->
              <v-btn
                v-if="isEditing(row) && !isReadOnly"
                class="ml-1"
                dark
                :elevation="false"
                x-small
                fab
                color="success"
                @click.native="saveRowEvent(row)"
              >
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>

              <!-- Popup-Edit -->
              <v-btn
                v-if="!isReadOnly"
                class="ml-1"
                dark
                x-small
                :elevation="false"
                fab
                color="primary"
                @click.native="editRow(row)"
              >
                <v-icon>mdi-file-document-edit</v-icon>
              </v-btn>

              <!-- <v-btn class="ml-1" dark x-small fab color="warning" @click.native="copyRow(row, rowIndex)">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn> -->

              <!-- Delete -->
              <v-btn
                v-if="allowDeletion && !isReadOnly"
                class="ml-1"
                dark
                x-small
                :elevation="false"
                fab
                color="error"
                @click.native="deleteRow(row)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <!-- Unlink -->
              <v-btn
                v-if="allowUnlinking && !isReadOnly"
                class="ml-1"
                dark
                x-small
                :elevation="false"
                fab
                color="error"
                @click.native="unlinkRow(row)"
              >
                <v-icon>mdi-link-variant-remove</v-icon>
              </v-btn>

              <!-- pending restore - should remove the following button -->
              <!-- <v-btn
                :elevation="false"
                x-small
                fab
                class="ml-1"
                color="error"
                @click.native="updateCascadeSummary(row)">S</v-btn> -->
            </template>
          </vxe-table-column>
          <vxe-table-column :title="$t('general.operation')" min-width="220" fixed="right" v-else>
            <template v-slot="{ row }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-1"
                    v-on="on"
                    v-bind="attrs"
                    dark
                    x-small
                    :elevation="false"
                    fab
                    color="primary"
                    @click="selectRecord(row)"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("buttons.detail") }}</span>
              </v-tooltip>
            </template>
          </vxe-table-column>
          <template #pager_left>
            <!-- <div class="pager-left" v-if="hasControl">
              <vxe-checkbox
                :value="isAllChecked"
                :indeterminate="isIndeterminate"
                @change="toggleSelectAllRows"
              />
              <span>{{
                $t("messages.selectedRecords", { num: numberOfSelections })
              }}</span>
            </div> -->
          </template>
        </vxe-grid>
      </div>      
    </div>    
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>

    <!-- Dialog: record selection -->
    <select-related-records-dialog ref="selectRelatedRecordsDialog"></select-related-records-dialog>

    <!-- Dialog: Card Selection -->
    <select-related-cards-dialog ref="selectRelatedCardsDialog"></select-related-cards-dialog>
   
    <!-- Dialog: Employee Selection -->
    <dialog-button-select-employees
      class="pa-0"
      :addButton="false"
      ref="tableEesDialog"
      v-model="showEesDialog"
    ></dialog-button-select-employees>   
    <members-menu
      :fullWidth="true"
      v-if="showingMembersMenu"
      v-model="showingMembersMenu"
      :attachId="membersMenuPayload.attachedId"
      ref="membersMenu"
      @memberClick="handleMemberClick"
      :nudgeBottom="rowHeight"
    />
  </div>
</template>

<script>
// mixins
import baseMixin from "@/mixins/baseMixin";
import propertyHelperMixin from "@/mixins/propertyHelperMixin";
import vxeTableHelperMixin from "@/mixins/vxeTableHelperMixin";
import relationValidationsMixin from "./relationValidationsMixin";
// components
import vxeTableCells from "@/components/vxeTableCells";
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import confirmDialog from "@/components/dialogs/ConfirmDialog";
import selectRelatedRecordsDialog from "@/components/dialogs/SelectRelatedRecordsDialog";
import selectRelatedCardsDialog from "@/components/dialogs/SelectRelatedCardsDialog";
// helpers
import { fetchRelatedDataFieldsInRows } from "@/helpers/DataHelpers";
import dialogButtonSelectEmployees from '@/components/dialogButtons/DialogButtonSelectEmployees'
import membersMenu from '@/pages/admin/form/formBuilder/comps/widgetDefaults/components/menus/membersMenu'

export default {
  name: "relationTableView",
  mixins: [
    baseMixin,
    propertyHelperMixin,
    vxeTableHelperMixin,
    relationValidationsMixin,
  ],
  components: {
    ...vxeTableCells,
    confirmDeleteDialog,
    confirmDialog,
    selectRelatedRecordsDialog,
    selectRelatedCardsDialog,
    dialogButtonSelectEmployees,
    membersMenu
  },
  props: {
    isLayout: Boolean,
    isReadOnly: Boolean,
    relatedTableInfos:Object,
    parentFormInfo: Object,
    parentFieldInfo: Object,
    parentFieldId: String,
    parentRecordId: String,
    childFormInfo: Object,
    tableDisplaySettings: {
      type: Object,
      default() {
        return {
          fixedFieldIds: [],
          nonFixedFieldIds: [],
        };
      },
    },
    dataSource: Object,
    hasControl: {
      type: Boolean,
      default: true,
    },
    // rowHeightClass: {
    //   type: String,
    //   default: ''
    // },
    rowClasses: {
      type: String, 
      default: ''
    }, 
    stretch: {

      type: String,
      default: 'auto' // ['auto'|'true']
    }
  },
  data() {
    return {
      editConfig: this.hasControl
        ? {
            trigger: "dblclick",
            mode: "row",
            showStatus: true,
            showAsterisk: true,
          }
        : null,
      loading: false,
      saving: false,
      relationData: {
        idDataMapping: {},
      },

      recalcSummary: false,
      showEesDialog: false,
      relatedTableInfos: {},
      showingMembersMenu: false,
      membersMenuPayload: null,
    };
  },

  watch: {
    parentFieldId () {
      this.refresh()
    },

    parentRecordId(newVal) {
      this.refresh()
    }    
  },

  computed: { 
    fixedFieldInfos() {      
      const vm = this
      var result = []
      if (vm.effectiveFixedFieldIds) {
        for (var i = 0; i < this.effectiveFixedFieldIds.length; i++) {
          const loopFieldId = this.effectiveFixedFieldIds[i]
          const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopFieldId)
          if (fieldInfo) result.push(fieldInfo)
        }
      }
      return result
    },
    nonFixedFieldInfos() {      
      const vm = this
      var result = []
      if (vm.effectiveNonFixedFieldIds) {
        for (var i = 0; i < this.effectiveNonFixedFieldIds.length; i++) {
          const loopFieldId = this.effectiveNonFixedFieldIds[i]
          const fieldInfo = vm.fieldInfos.find(info => info.fieldId === loopFieldId)
          if (fieldInfo) result.push(fieldInfo)
        }
      }
      return result
    },
    relatedFormId() {
      return this.dataSource;
    },
    effectiveFieldIds() {
      return [
        ...this.effectiveFixedFieldIds,
        ...this.effectiveNonFixedFieldIds,
      ];
    },

    currentApp() {
      return this.$store.getters.currentApp;
    },

    settings() {
      const vm = this;
      var result = [];
      if (vm.parentFieldInfo.properties.settingsMultiple !== "") {
        result = vm.parentFieldInfo.properties.settingsMultiple.split("||");
      }
      return result;
    },

    allowUnlinking() {
      return this.settings.includes("allowUnlinking");
    },

    allowDeletion() {
      return this.settings.includes("allowDeletion");
    },

    formInfo() {
      return this.childFormInfo;
    },
    titleFieldInfoId() {
      return this.formInfo.titleFieldInfoId;
    },
    titleFieldId() {
      return this.titleFieldInfo ? this.titleFieldInfo.fieldId : "";
    },
    titleFieldInfo() {
      const vm = this;
      return vm.fieldInfos && vm.titleFieldInfoId
        ? vm.fieldInfos.find((info) => info._id === vm.titleFieldInfoId)
        : null;
    },
    fieldInfos() {
      return this.formInfo ? this.formInfo.fieldInfos : [];
    },
    fieldInfoFieldIds() {
      return this.fieldInfos ? this.fieldInfos.map((info) => info.fieldId) : [];
    },
    dataCount () {
      return this.pagination.totalResult
    },
    displayFieldCount() {
      const vm = this;
      var result = 0;
      if (
        vm.tableDisplaySettings &&
        vm.tableDisplaySettings.fixedFieldIds &&
        vm.tableDisplaySettings.nonFixedFieldIds
      ) {
        result =
          this.tableDisplaySettings.fixedFieldIds.length +
          this.tableDisplaySettings.nonFixedFieldIds.length;
      }
      return result;
    },

    //********************************************
    // vm.fixedFieldInfos & vm.nonFixedFieldInfos
    // used for:
    // generation of colDefs
    //********************************************

    // effectiveFixedFieldIds => fixedFieldInfos
    effectiveFixedFieldIds() {
      const vm = this;
      var result = [];
      if (vm.displayFieldCount && vm.displayFieldCount === 0) {
        if (vm.titleFieldId) {
          result.push(vm.titleFieldId);
        }
      } else {
        result = vm.tableDisplaySettings.fixedFieldIds;
      }
      return result;
    },

    // effectiveNonFixedFieldIds => nonFixedFieldInfos
    effectiveNonFixedFieldIds() {
      const vm = this;
      var result = [];
      if (vm.displayFieldCount && vm.displayFieldCount === 0) {
        if (vm.titleFieldId) {
          result = vm.fieldInfoFieldIds.filter((id) => id !== vm.titleFieldId);
        } else {
          result = vm.fieldInfoFieldIds;
        }
      } else {
        result = vm.tableDisplaySettings.nonFixedFieldIds;
      }
      return result;
    },

    isPublic() {
      return this.$store.getters.isPublicRoute
    },
    rowHeightClass() {
      let height = 'xs';
      const currentViewId = this.$route.params.viewId;
      const currentView = this.$store.getters.currentForm.views.find( view => view._id === currentViewId );
      if (currentView) {
        try{
          height = currentView.tableRowHeight || "xs";
        }catch(e) {
          console.error( e.message );
        }
      }
      return `vxe-table-row-${height}`
    }
  },

  mounted () {
    // fetch related table infos
    this.fetchRelatedTableInfos()
  },
  methods: {
    updateCascadeSummary (row) {
      const formId = this.dataSource
      this.$store.dispatch('UPDATE_CASCADE_SUMMARY', {
        recordId: row._id,
        formId: formId
      }).then(
        response => {
          // alert('sent requrest for updateCascadeSummary')
        }
      )
    },
    onRefreshClicked () {
      this.refresh()
    },
    refresh(payload) {
      const vm = this;
      var recalcSummary
      if (typeof payload === 'undefined') {
        recalcSummary = true
      } else {
        recalcSummary = payload.recalcSummary
      }
      vm.recalcSummary = recalcSummary      
      
      vm.$refs.xTable.commitProxy("query");
    },

    moveVxeTableUiConflictBack() {
      this.$refs.xTable.$el.classList.add("move-conflict-back");
    },

    moveVxeTableUiConflictFront() {
      this.$refs.xTable.$el.classList.remove("move-conflict-back");
    },

    resetColumn() {
      try {
        this.$refs.xTable.resetColumn();
      } catch (err) {
        console.log("resetColumn :: err: ", err);
      }
    },
    // rowClasses() {
    //   const height = "s";
    //   return `vxe-table-row-${height}`;
    // },
    editClosedEvent({ row, column }) {
      const vm = this;
      if (vm.saving) return;
      if (!vm.loading) {
        const xTable = vm.$refs.xTable;
        if (xTable.isUpdateByRow(row)) {
          vm.saveRowEvent(row);
        } else {
          console.log("editClosedEvent :: not isUpdatedByRow(row)");
        }
      } 
    },
    editActiveEvent({
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
    }) {},
    ajaxLoadData(page, sort) {
      const vm = this;
      if (vm.isLayout) return;

      if (vm.formInfo) {
        vm.loading = true;
        vm.$emit('onCommand', {command: 'startRefreshing'})
        this.clearSelection();
        return new Promise((resolve, reject) => {
          if (vm.parentRecordId === "" || vm.parentRecordId.includes("_")) {
            vm.loading = false;
            resolve({
              page: {
                total:0,
              },
              result: [],
            });
            if(vm.parentRecordId.includes("_")) 
              vm.$emit('onCommand', {command: 'stopRefreshing'})
          } else {
            var params = {
              formId: vm.parentFormInfo._id,
              recordId: vm.parentRecordId,
              fieldId: this.parentFieldId,
              dataSource: this.dataSource,
              page:page.currentPage,
              size:page.pageSize,
              isPublic:this.$store.getters.isPublicRoute
            };

            if (vm.filterValue) {
              Object.assign(params, {
                filter: JSON.stringify(vm.filterValue),
              });
            }

            if (vm.keyword) {
              Object.assign(params, {
                keyword: vm.keyword,
              });
            }

            if (Object.keys(sort).length > 0) {
              Object.assign(params, {
                sortingFieldId: sort.field,
                sortingOrder: sort.order,
              });
            }

            var tableFieldIds = this.colDefs.map(item=>item.fieldInfo.fieldId)
            var tableFields = this.fieldInfos.filter(field=>{
              return tableFieldIds.includes(field.fieldId)
            })
            vm.$store
              .dispatch("FETCH_RELATED_DATA_LIST", params)
              .then((response) => {
                vm.pagination = response.pagination;
                if (vm.$refs.xTable) vm.$refs.xTable.resetColumn();

                response.result = response.result.map(row=>{
                  var obj = {
                    ...row
                  }
                  tableFields.forEach(field=>{
                    if(!row.hasOwnProperty(field.fieldId)||row[field.fieldId]==null){
                      obj[field.fieldId] = null
                      switch(field.type){
                        case 'region':
                          obj[field.fieldId] = {province: null, city: null, state: null, regionText: ""}
                          break

                        default:
                          obj[field.fieldId] = null
                      }
                    }
                  })
                  return obj
                })
                resolve({
                  page: {
                    total: response.pagination.totalResult,
                  },
                  result: response.result,
                });

               

                vm.fetchRelationDetails(response.result);
                if (vm.recalcSummary) {
                  vm.$emit("updateSummary");
                  vm.recalcSummary = false;
                }
                vm.loading = false;
                vm.$emit('onCommand', {command: 'stopRefreshing'})
              }).catch(error=>{
                this.loading = false
                vm.$emit('onCommand', {command: 'stopRefreshing'})
                this.$toast.error(this.$t("errors.load"))
                resolve({
                  page: {
                    total: 0,
                  },
                  result: [],
                });
              });
          }
        });
        vm.loading = false
      }
    },

    fetchRelatedTableInfos (callback) {
      const vm = this

      const effectiveFieldInfos = vm.fieldInfos.filter(info => vm.effectiveFieldIds.includes(info.fieldId))
      const effectiveRelatedFieldInfos = effectiveFieldInfos.filter(info => info.type === 'relatedRecord')

      var allPromises = []


      const appId = vm.$store.getters.currentApp._id
      for (let i = 0; i < effectiveRelatedFieldInfos.length; i++) {
        const loopInfo = effectiveRelatedFieldInfos[i]

        if (loopInfo.properties && 
          loopInfo.properties.dataSource) {

          const getData = {
            urlCommand: '/forms/' + 
              loopInfo.properties.dataSource + '?appId=' + appId
          }
          allPromises.push(
            vm.$store.dispatch('AUTH_GET', getData)
          )
        }
      }

      Promise.all(allPromises).then(responses => {
        for (let i = 0; i < responses.length; i++) {
          const form = responses[i].result
          // alert('form._id = ' + form._id)
          const formInfo = {
            _id: form._id,
            label: form._label,
            fieldInfos: form.fieldInfos,
            titleFieldInfoId: form.titleFieldInfoId,
            linkedFields: form.linkedFields,
            appId: form.appId,
            teamId: form.teamId
          }
          vm.$set(vm.relatedTableInfos, form._id, form)
        }
        if (typeof callback === 'function') {
          callback()
        }
    })
    },

    fetchRelationDetails(rows, extra = null) {
      const vm = this;
      if (typeof rows === "undefined" || rows === null) {
        rows = vm.$refs.xTable.tableData;
      }
      fetchRelatedDataFieldsInRows(
        vm,
        rows,
        extra,
        this.isPublic,
        vm.fieldInfos,
        vm.effectiveFieldIds,
      ).then((response) => {
          vm.relationData.idDataMapping = response;
        })
    },

    confirmAbandon(confirmHandler, cancelHandler) {
      const vm = this;
      vm.$refs.confirmDialog
        .open(vm.$t("general.warning"), vm.$t("messages.errorsOccurred"), {
          color: "error",
          confirmBtnColor: "error",
          cancelBtnColor: "muted",
          confirmMsg: this.$t("buttons.abandon"),
          cancelMsg: this.$t("buttons.continueEditing"),
        })
        .then((result) => {
          if (result) {
            // console.log('confirm')
            confirmHandler();
          } else {
            // console.log('cancel')
            if (typeof cancelHandler === "function") {
              cancelHandler();
            }
          }
        });
    },
    saveRowEvent(row) {
      const vm = this;
      const xTable = vm.$refs.xTable;
      // console.log(row)
      if (vm.isUpdated(row)) {
        vm.loading = true;
        xTable.validate(row, (errMap) => {
          if (errMap) {
            xTable.clearValidate();
            vm.confirmAbandon(
              () => {
                // console.log('confirmAbandon')
                xTable.revertData(row);
                // console.log('clearActived')
                xTable.clearActived().then(() => {
                  vm.$nextTick(() => {
                    xTable.clearValidate();
                    xTable.closeTooltip();
                  });
                });
                vm.loading = false;
              },
              () => {
                vm.$nextTick(() => {
                  // console.log('clearValidate')
                  xTable.clearValidate();
                  vm.setActiveRow(row);
                  // console.log('cancelAbandon')
                  vm.loading = false;
                });
                // alert('cancelAbandon')
              }
            );
            return;
          } else {

            const updatedData = JSON.parse(JSON.stringify(row));
            updatedData["appId"] = vm.relatedAppId;

            const postData = {
              data: row,
              isNew: false,
            };
            vm.$store.dispatch("SAVE_DATA", postData).then((response) => {
              vm.loading = false;
              Object.keys(row).forEach((fieldId) => {
                this.$set(row, fieldId, response[fieldId]);
              });
              // console.log("SAVE_DATA :: => udpateSummary");
              xTable.reloadRow(row);
              //xTable.reloadRow(row, response);
              xTable.clearActived().then(() => {
                // console.log("clearActived :: => udpateSummary");
                vm.$XModal.message({
                  message: vm.$t("messages.savedSuccessfully"),
                  status: "success",
                });

                vm.$emit("updateSummary");
              });
              vm.$nextTick(() => {
                vm.refresh({recalcSummary:false})
                // console.log('nextTick : xtable.tableData: ' + JSON.stringify(xTable.tableData))
                // vm.fetchRelationDetails(xTable.tableData)
              })
            }).catch(error=>{
              this.$toast.error(this.$t("errors.save"))
              this.loading = false
            });
          }
        });
      } else {
        xTable.clearActived();
      }
    },
    isEditing(row) {
      return this.$refs.xTable.isActiveByRow(row);
    },

    isUpdated(row) {
      return this.$refs.xTable.isUpdateByRow(row);
    },

    selectRelatedCards(payload) {
      const vm = this;
      vm.showOverlay = true;
      vm.attach = payload.attach;
      vm.moveVxeTableUiConflictBack();
      vm.$refs.selectRelatedCardsDialog.open(
        payload,
        vm.onRelatedRecordSelected,
        vm.onRelatedRecordCancelled
      );
    },
    selectRelatedRecords(payload) {
      const vm = this;
      vm.showOverlay = true;
      vm.attach = payload.attach;
      vm.moveVxeTableUiConflictBack();
     
      vm.$refs.selectRelatedRecordsDialog.open(
        payload,
        vm.onRelatedRecordSelected,
        vm.onRelatedRecordCancelled
      );
    },

    onRelatedRecordSelected(payload, callbackBundle, callback) {
      const vm = this;

      vm.moveVxeTableUiConflictFront();
      vm.showOverlay = false;
      if (typeof callbackBundle.onSelected === "function") {
        callbackBundle.onSelected(payload, callback);
      }
    },

    onRelatedRecordCancelled(callbackBundle) {
      const vm = this;
      // console.log('TableView :: onRelatedRecordCancelled: callbackBundle: ', callbackBundle)
      // console.log('onRelatedReocrdCancelled :: callbackBundle: ', callbackBundle)
      vm.moveVxeTableUiConflictFront();
      this.showOverlay = false;
      if (typeof callbackBundle.onCancelled === "function") {
        callbackBundle.onCancelled();
      }
    },

    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case 'selectRelatedCards':
          vm.selectRelatedCards(payload)
          break
        case 'selectRelatedRecords':
          vm.selectRelatedRecords(payload)
          break
        case "showEmployeesDialog":
          vm.$refs.tableEesDialog.addButton = payload.addButton
          vm.$refs.tableEesDialog.multiple = payload.multiple
          vm.$refs.tableEesDialog.selectedValue = payload.selectedValue
          vm.$refs.tableEesDialog.callback = payload.onSubmit
          vm.$refs.tableEesDialog.row = payload.row
          this.showEesDialog = true
          break
        case "showMembersMenu":
          var attachedEle = payload.attachedEle
          var fieldInfo = payload.fieldInfo
          vm.membersMenuPayload = payload
          vm.showingMembersMenu = true
          vm.$nextTick(()=>{
            vm.$refs.membersMenu.type= fieldInfo.properties.fieldType
            vm.$refs.membersMenu.selectedValue= vm.recordData[fieldInfo.fieldId]
            vm.$refs.membersMenu.fieldId= fieldInfo.fieldId
            attachedEle.id = ""
          })
          break
        case 'closeSelectRelatedRecordsDialog':
          vm.$refs.selectRelatedRecordsDialog.close()
          break          
        case 'refreshRelationData':
          vm.refresh({recalcSummary:false})
          break
        case "updateFieldValue":
          vm.updateFieldValue(
            payload.rowId,
            payload.fieldId,
            payload.fieldValue
          );
          break;
        case "selectUploadFile":
          // console.log('TableView :: onCommandHandler :: selectUploadFile', payload)
          const xTable = vm.$refs.xTable;
          if (payload.opts.types.length == 0) payload.opts.types = null;
          xTable.readFile(payload.opts).then((params) => {
            // console.log('TableView :: params: ', params)
            if (typeof payload.callback === "function") {
              payload.callback(params);
            }
          });
          break;
          default:
            this.$emit('onCommand', payload)
            break;
      }
    },
    updateFieldValue(rowId, fieldId, value) {
      const vm = this;

      for (let i = 0; i < vm.tableData.length; i++) {
        const loopRow = vm.tableData[i];
        if (loopRow._id === rowId) {
          vm.tableData[i][fieldId] = value;
          vm.updateRow(vm.tableData[i]);
          break;
        }
      }
    },

    updateRow(row) {
      const vm = this;
      vm.$store.dispatch("SAVE_DATA", row).then((response) => {
        vm.$emit('updateSummary')
      }).catch(error=>{
        this.$toast.error(this.$t("errors.update"))
      });
    },

    editRow(row) {
      const vm = this;
      // console.log("RelationTableView :: editRow: row: ", row);
      vm.$emit("onCommand", {
        command: "editRelatedRecord",
        record: row,
      });
    },

    deleteRow(row) {
      const vm = this;
      vm.$refs.confirmDeleteDialog.confirm(() => {
        vm.$emit('onCommand', {
          command: 'deleteRelatedRecordsForTable',
          recordIds: [row._id],
          callback: () => {
            vm.refresh()
          }
        })
      });
    },

    unlinkRow (row) {
      const vm = this
      vm.$refs.confirmDeleteDialog.confirm(() => {
        this.$emit('onCommand', {
          command: 'unlinkRelatedRecordsForTable',
          recordIds: [row._id],
          callback: () => {
            vm.refresh()
          }
        })
      },{
        title: vm.$t('messages.cancelRelation')
      })
    },

    copyRow(row, rowIndex) {
      this.$refs.confirmDialog
        .open(this.$t("general.copy"), this.$t("messages.areYouSure") + "?")
        .then((confirm) => {
          if (confirm) {
            this.$emit("onCommand", {
              command: "duplicateRecordForTable",
              recordId: row._id,
              callback: () => {
                this.refresh();
              },
            });
          }
        });
    },

    deleteItems () {
      const vm = this
      this.$refs.confirmDeleteDialog.confirm(() => {
        vm.$emit('onCommand', {
          command: 'deleteRelatedRecordsForTable',
          recordIds: vm.includeRecords,
          callback: () => {
            vm.refresh()
          }
        })

      });
    },

    unlinkItems () {
      const vm = this
      vm.$refs.confirmDeleteDialog.confirm(() => {
        this.$emit('onCommand', {
          command: 'unlinkRelatedRecordsForTable',
          recordIds: vm.includeRecords,
          callback: () => {
            vm.refresh()
          }
        })
      },{
        title: vm.$t('messages.cancelRelation')
      })
    },

    clearSelection() {
      this.includeRecords = [];
      this.excludeRecords = [];
    },
    handlePageChange({ currentPage, pageSize }) {
      this.clearSelection();
    },


    async setActiveRow(row) {
      var scrollObj = this.$refs.xTable.getScroll();
      await this.$refs.xTable.setActiveRow(row);
      this.$refs.xTable.scrollTo(scrollObj.scrollLeft, scrollObj.scrollTop);
      this.$refs.xTable.refreshScroll();
    },

    resetTableState() {
      this.keyword = "";
      this.$refs.xTable.clearAll();
      Object.keys(this.$refs.xTable.sortData).forEach((key) => {
        delete this.$refs.xTable.sortData[key];
      });
    },


    selectRecord(record) {
   
      this.$emit("onCommand", {
        command: "selectRecord",
        record:record,
      });
    },
    handleMemberClick(val){
      var type = this.membersMenuPayload.fieldInfo.properties.fieldType
      var fieldId = this.membersMenuPayload.fieldInfo.fieldId
      var recordData = this.membersMenuPayload.row
      if(!recordData[fieldId]){
        this.$set(recordData, fieldId, [val])
      }else{
        if(type=='single'){
          recordData[fieldId] = [val]
        }else{
          var pos = recordData[fieldId].findIndex(ee=>ee._id==val._id)
          if(pos==-1){
            recordData[fieldId].push(val)
          }else{
            recordData[fieldId].splice(pos,1)
          } 
        }
      }
    },
  },
};
</script>

<style>
.relation-table-view .vxe-tabvle--border-line {
  height: auto !important;
}

/* .record .relation-table-view.height-auto .vxe-table--body-wrapper,
.record-drawer .relation-table-view.height-auto .vxe-table--body-wrapper { */
.relation-table-view.height-auto .vxe-table--body-wrapper {
    height: auto !important;
}

.relation-table-view .vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell{
  max-height: unset !important;
}
</style>
