

export const getMenuItem = (menuItems, itemId) => {
    return menuItems.find(item => item._id === itemId) || null
  }


export const getModuleFirstMenuItemId = (menuItems) => {
  return menuItems[0] &&  menuItems[0]._id || ""
}

export const getAppModule = (app,moduleId) => {
  const module = app && app.modules.find(mod => mod._id === moduleId)
  return module
}

export const getAllMenuItemIds = (menuItems = []) => {
  return menuItems.map(item => item._id)
}