<template>
  <div
    class="apps fill-height d-flex flex-column justify-space-between"
    style="width:100vw"
  >
    <div
      v-if="app && currentModuleIndex != null"
      class="d-flex"
      :style="`background-color: ${app.color}`"
      style="width:100%"
    >
      <module-tab-bar
        class="app-module-bar"
        :tabsHeight="48"
        :addBtnClass="'squareBtn'"
        :value="currentModuleIndex"
        @input="onModuleIndexChange"
        :hideButtons="!isAdmin"
        type="module"
        :menu="tabBarMenu"
        :availableTabs="app ? app.modules : []"
        @onCommand="onCommandHandler"
        :inFont="true"
        activeClass="active-module-tab"
      >
      </module-tab-bar>
    </div>
    <splitpanes
      vertical
      v-if="app"
      class="fill-height flex-grow-0 d-flex fit-width default-theme"
      @resize="onResize"
      @resized="onResized"
      style="overflow-y:hidden;"
      :dbl-click-splitter="false"
    >
      <pane
        class="px-0 d-flex flex-column flex-grow-0"
        :class="{ 'small-menu': smallMenu }"
        size="20"
        style="min-width:60px;"
        ref="itemMenu"
      >
        <div class="fill-height flex-grow-1">
          <!-- Left Menu -->
          <div
            class="fill-height menu-box d-flex flex-column justify-space-between"
            style="position:relative;"
          >
            <perfect-scrollbar
              class="d-flex flex-column flex-grow-1 pa-0"
              style="height:0;position:relative;"
            >
              <div
                v-if="currentModule && currentModule.menu"
                class="pb-12 flex-grow-1 d-flex flex-column white pt-1"
              >
                <draggable
                  v-model="currentModule.menu"
                  @update="onDropped"
                  class="px-2"
                >
                  <menu-item-line
                    v-for="form in currentModule.menu"
                    :key="form._id"
                    :item="form"
                    :selectedItem="selectedMenuItem"
                    @onCommand="onCommandHandler"
                  >
                  </menu-item-line>
                </draggable>
                <v-btn
                  v-if="isAdmin"
                  @click="newMenuItem"
                  text
                  class="elevation-0 rounded-1 justify-start px-2"
                  :class="foldedMenu ? '' : 'mx-2'"
                  :style="
                    `background-color: ${app.color.substring(0, 7) + '1A'}; `
                  "
                  style="border-radius: 4px;"
                >
                  <v-icon size="18">mdi-plus</v-icon>
                  <span
                    v-if="!foldedMenu"
                    class="ml-2"
                    style="font-size:12px"
                    >{{ $t("buttons.addNewModule") }}</span
                  >
                </v-btn>
              </div>
            </perfect-scrollbar>
            <div
              class="root-new-menu-item"
              style="z-index:200;position:absolute;bottom:8px;right: 0px;"
            >
              <div class="text-right new-menu-button-wrapper">
                <v-btn
                  small
                  :disabled="!currentModule"
                  @click="toggleFoldMenu"
                  class="mx-2 my-0 primary"
                  fab
                  icon
                  dark
                  height="36"
                  width="36"
                >
                  <v-icon style="margin-left:2px" v-if="!foldedMenu" size="20"
                    >mdi-arrow-collapse-left</v-icon
                  >
                  <v-icon style="margin-right:2px" v-else size="20"
                    >mdi-arrow-collapse-right</v-icon
                  >
                </v-btn>
              </div>
            </div>
            <!--selectedMenuItem: {{ selectedMenuItem }}-->
            <!-- <div class="grey" @click="toggleFoldMenu" style="cursor: pointer">
            <v-icon class="d-flex" dark :class="'justify-end'">
                {{foldedMenu?'mdi-chevron-right':'mdi-chevron-left'}}
            </v-icon>
          </div> -->
          </div>
        </div>
      </pane>
      <pane class="d-flex flex-column flex-grow-1">
        <!-- Content -->
        <div class="flex-grow-1 d-flex flex-column">
          <router-view v-if="selectedMenuItem"></router-view>
          <EmptyPage v-else-if="currentModule.menu.length === 0" />
        </div>
      </pane>
    </splitpanes>
    <div v-else class="d-flex align-center justify-center fill-height">
      <v-progress-circular v-if="loading" indeterminate color="primary" />
      <div class="font-weight-black" v-else>
        <v-row class="py-4" no-gutters justify="center">
          <img
            width="90px"
            :src="require('@/assets/file-lock-20220516103424.svg')"
          />
        </v-row>
        <v-row
          no-gutters
          style="white-space: pre-line;text-align:center;font-size:16px"
        >
          <span>{{ $t("messages.noAppPermission") }}</span>
        </v-row>
      </div>
    </div>
    <confirm-dialog-with-text-field
      ref="confirmDialogWithTextField"
    ></confirm-dialog-with-text-field>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <info-dialog ref="infoDialog"></info-dialog>
    <menu-item-dialog
      saveCommand="saveMenuItemInfo"
      :module="currentModule"
      @onCommand="onCommandHandler"
      ref="menuItemDialog"
    ></menu-item-dialog>
    <edit-tab-dialog
      v-if="isAdmin"
      ref="editTabDialog"
      @onCommand="onCommandHandler"
      type="module"
      command="saveTab"
      :dialogName="$t('app.module')"
    ></edit-tab-dialog>
    <module-delete-dialog ref="moduleDeleteDialog"></module-delete-dialog>
    <module-move-dialog ref="moduleMoveDialog"></module-move-dialog>
    <general-dialog ref="atLeastOneModuleDialog" />
    <FsLightbox
      class="fs-lightbox"
      :toggler="toggler"
      type="image"
      :sources="imageSource"
      :onClose="onLightBoxClose"
    />
    <!-- <v-overlay v-if="loading">
    <font-awesome-icon 
      icon="spinner"
      class="spinner fa-5x fa-spin"></font-awesome-icon>
  </v-overlay> -->
  </div>
</template>

<script>
import appStyleMixin from "@/mixins/appStyleMixin";

import menuItemDialog from "./dialogs/MenuItemDialog";
import ModuleMenu from "@/menus/ModuleMenu";
import menuItemLine from "./comps/MenuItemLine";
import {
  getMenuItem,
  getModuleFirstMenuItemId,
} from "@/store/modules/apps/helpers";
// dialogs
import confirmDialogWithTextField from "@/components/dialogs/ConfirmDialogWithTextfield";
import infoDialog from "@/components/dialogs/InfoDialog";
import editTabDialog from "@/components/dialogs/EditTabDialog";
import moduleDeleteDialog from "@/components/dialogs/ModuleDeleteDialog";
import moduleMoveDialog from "@/components/dialogs/ModuleMoveDialog";
import confirmDialog from "@/components/dialogs/ConfirmDialog";
import GeneralDialog from "@/components/dialogs/GeneralDialog";

import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import moduleTabBar from "@/components/ModuleTabBar";

import FsLightbox from "fslightbox-vue";
import EventBus from "@/event-bus.js";
import draggable from "vuedraggable";

import EmptyPage from "@/pages/forms/EmptyPage";

export default {
  mixins: [appStyleMixin],
  name: "Apps",
  components: {
    Splitpanes,
    Pane,
    menuItemDialog,
    menuItemLine,
    // Dialogs
    confirmDialogWithTextField,
    infoDialog,
    editTabDialog,
    moduleDeleteDialog,
    moduleMoveDialog,
    confirmDialog,
    FsLightbox,
    draggable,
    moduleTabBar,
    EmptyPage,
    GeneralDialog,
  },
  computed: {
    tabBarMenu() {
      if (this.isAdmin) {
        return ModuleMenu;
      } else {
        return ModuleMenu.filter((item) => item.admin === false);
      }
    },
    appName() {
      return this.app ? this.app.title : "YOOV+";
    },
    menuItemTitle() {
      return this.selectedMenuItem ? this.selectedMenuItem.title : "";
    },
    app() {
      return this.$store.getters.currentApp;
    },
    currentModuleIndex: {
      get() {
        return this.$store.getters.currentModuleIndex;
      },
      set(newVal) {
        this.$store.dispatch("SET_CURRENT_MODULE_INDEX", newVal);
      },
    },
    currentModule() {
      return (this.app && this.app.modules[this.currentModuleIndex]) || null;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    currentForm() {
      return this.$store.getters.currentForm;
    },
    currentTeamName() {
      return this.$store.getters.activeTeamName;
    },
    selectedMenuItem() {
      return this.$store.getters.selectedMenuItem;
    },
    selectedMenuItemId() {
      return this.$store.getters.selectedMenuItemId;
    },
    toggler() {
      return this.$store.state.media.toggle;
    },
    imageSource() {
      return this.$store.state.media.imageUrls;
    },
  },

  data() {
    return {
      loading: false,
      smallMenu: false,

      foldedMenu: false,
      errorMessages: null,
    };
  },
  created() {
    EventBus.$on("showMessagePanel", this.showMessagePanel);
    EventBus.$on("hideMessagePanel", this.hideMessagePanel);
    EventBus.$on("openNewMenuItemDialog", this.newMenuItem);
  },
  beforeDestroy() {
    EventBus.$off("showMessagePanel");
    EventBus.$off("hideMessagePanel");
    EventBus.$off("openNewMenuItemDialog");
  },

  async mounted() {
    const vm = this;
    const appId = vm.$route.params.id;
    let menuItemId = vm.$route.params.itemId || "";

    // console.log('Apps mounted')
    this.loading = true;
    try {
      const result = await this.loadApp(appId);
      if (result.locked || (result.status && result.status.length > 0)) {
        vm.$router.push('/my_apps');
        return;
      }
      console.log('Apps.vue mounted: loadApp: result: ', result);
      if (result) {
        let moduleIndex = 0;
        if (menuItemId) {
          moduleIndex = vm.getActiveModuleIndex(result, menuItemId);
        } else {
          menuItemId = getModuleFirstMenuItemId(
            result.modules[moduleIndex].menu
          );
          // if (!menuItemId){
          //     this.$router.push({
          //       name: 'forms-empty'
          //    })
          // }
        }
        vm.$store.dispatch("SET_CURRENT_MODULE_INDEX", moduleIndex);
        await this.loadModule(menuItemId);
      }
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
    // console.log('Apps mounted>loadApp')
  },
  methods: {
    showMessagePanel(payload) {
      this.errorMessages = payload.messages;
    },
    hideMessagePanel() {
      this.errorMessages = null;
    },

    onLightBoxClose() {
      this.$store.dispatch("RESET_MEDIA");
    },
    onResized(event) {
      if (event[0].size < 8) {
        this.$refs.itemMenu.style.width = "62px";
        this.foldedMenu = true;
      } else {
        this.foldedMenu = false;
      }
    },
    onResize(paneSizes) {
      const vm = this;
      const isSmallMenu = paneSizes[0].size < 5;

      vm.smallMenu = isSmallMenu;
    },

    onDropped(evt) {
      const vm = this;
      let newIndex = evt.newIndex;
      const item = this.currentModule.menu[newIndex];
      vm.$store.dispatch("STORE_MODULE_MENU", {
        appId: vm.app._id,
        module: vm.currentModule,
        menu: this.currentModule.menu,
        item,
      });
    },
    async loadApp(appId) {
      const result = await this.$store.dispatch("FETCH_APP", {
        appId: appId,
        include: "menu",
      });
      if (result) {
        document.title = this.appName;
      }
      return result;
    },
    loadFirstMenuItem(module) {
      const itemId = getModuleFirstMenuItemId(module.menu);
      if (!itemId) {
        this.$store.dispatch("SET_SELECTED_MENU_ITEM_ID", "");
        this.$store.dispatch("RESET_FORMS");
      } else this.loadModule(itemId);
    },
    onModuleIndexChange(index) {
      if (this.currentModuleIndex !== index) {
        this.currentModuleIndex = index;
        this.loadFirstMenuItem(this.currentModule);
      }
    },
    getActiveModuleIndex(app, menuItemId) {
      let found = false;
      let result = 0;
      for (let i = 0; i < app.modules.length; i++) {
        const loopModule = app.modules[i];
        const menu = loopModule.menu;
        found = Boolean(this.getMenuItem(menu, menuItemId));
        if (found) {
          result = i;
          break;
        }
      }
      return result;
    },
    getMenuItem(menuItems, menuItemId) {
      return getMenuItem(menuItems, menuItemId);
    },
    editMenuItem(item) {
      const vm = this;
      // console.log('editMenuItem : item: ', item)
      vm.gotoItemBuilder(item);
    },
    gotoItemBuilder(item) {
      const vm = this;
      // console.log('editModule :: item: ', item)
      let url = "";
      switch (item.type) {
        case "panel":
          url = "/admin/apps/" + vm.app._id + "/panels/" + item._id;
          // console.log('gotoItemBuilder :: url = ' + url)
          // console.log('gotoItemBuilder :: vm.$router.path = ' + vm.$router.path)
          // console.log('editModule :: url = ' + url)
          break;
        case "form":
          url = "/admin/apps/" + vm.app._id + "/forms/" + item._id;
          // console.log('editModule :: url = ' + url)
          break;
        case "report":
          url = "/admin/apps/" + vm.app._id + "/reports/" + item._id;
          // console.log('editModule :: url = ' + url)
          break;
      }

      if (url && url !== vm.$route.fullPath) {
        vm.$router.push(url);
      }
    },

    newMenuItem() {
      const vm = this;
      this.$sensors.track("plus_add_module_block_view_button", {
        app_id: this.app._id,
        function_button:"block"
      })
      vm.$refs.menuItemDialog.open();
    },
    updateMenuItem(item) {
      const vm = this;
      console.log("updateMenuItem: item: ", item);
      vm.$refs.menuItemDialog.open(item);
    },
    selectMenuItem(item) {
      const vm = this;
      if (vm.selectedMenuItem !== item) {
        vm.$store.dispatch("SET_SELECTED_MENU_ITEM_ID", item._id);
        // vm.selectedMenuItem = item
        vm.loadModule(item._id);
      }
    },
    loadModule(menuItemId) {
      const vm = this;
      const item = this.getMenuItem(this.currentModule.menu, menuItemId);
      if (!item) {
        return;
      } else {
        document.title = `${this.appName} - ${item.title}`;
        vm.$store.dispatch("SET_SELECTED_MENU_ITEM_ID", menuItemId);
        const currentUrl = vm.$route.fullPath;
        let url = "";
        // console.log('currentUrl = ' + currentUrl)
        const typeMap = {
          panel: "panels",
          form: "forms",
          calendar: "calendars",
        };
        const type = typeMap[item.type];
        if (type) {
          url = `/apps/${this.app._id}/${type}/${item._id}`;
          if (currentUrl.indexOf(url) === -1) {
            vm.$router.push(url);
          }
        } else {
          console.error("Apps.vue:: loadModule, type does not exist");
        }
      }
    },
    getDefaultTabName() {
      const vm = this;
      const defaultModuleName = vm.$t("general.modules");
      let n = 1;
      let found = false;
      let loopModuleName = "";
      do {
        loopModuleName = defaultModuleName + " #" + n;
        found = vm.app.modules.find(
          (module) => module.title === loopModuleName
        );
        n++;
      } while (found);
      return loopModuleName;
    },
    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        // Tab Menu
        case "newTab":
          {
            const defaultTabName = vm.getDefaultTabName();
            vm.$refs.editTabDialog.open({
              _id: "",
              title: defaultTabName,
            });
          }
          break;
        case "saveTab":
          vm.saveModuleInfo(payload);
          break;
        case "deleteTab":
          vm.deleteModule(payload.data);
          break;
        case "renameTab":
          vm.$refs.editTabDialog.open(payload.data);
          break;
        case "moveTabs":
          vm.moveTabsOfModule(payload.oldIndex, payload.newIndex);
          break;
        case "hideTab":
          if (payload.type == "module") vm.hideTabsOfModule(payload.data._id);
          break;
        //*********************
        // menu item operation
        //*********************
        case "saveMenuItemInfo":
          {
            const isNew = payload.menuItemInfo._id === "";
            vm.saveMenuItemInfo(
              payload.menuItemInfo,
              payload.menuFolder,
              (newItem) => {
                if (newItem.type !== "submenu" && isNew) {
                  vm.selectMenuItem(newItem);
                }
                console.log("saveMenuItemInfo => callback newItem : ", newItem);
                payload.callback(newItem._id);
              }
            );
          }

          break;
        case "updateMenuItem":
          vm.updateMenuItem(payload.item);
          break;
        case "onMenuItemClicked":
        case "selectMenuItem":
          vm.selectMenuItem(payload.item);
          break;
        case "editMenuItem":
          vm.editMenuItem(payload.item);
          break;
        case "duplicateMenuItem":
          vm.duplicateMenuItem(payload.item);
          break;
        case "moveMenuItem":
          vm.moveMenuItem(payload.item);
          break;
        case "hideMenuItem":
          vm.hideMenuItem(payload.moduleId, payload.item);
          break;
        case "deleteMenuItem":
          vm.deleteMenuItem(payload.item);
          break;

        // Tab manipulation
        case "editForm":
          vm.$router.push({
            name: "adminForms",
            params: {
              id: vm.$route.params.id,
              itemId: payload.formId,
            },
          });
          break;
        default:
          console.log(
            "Apps :: onCommandHandler :: no handler: payload: ",
            payload
          );
      }
    },
    duplicateMenuItem(item) {
      switch (item.type) {
        case "form":
          this.duplicateForm(item);
          break;
        case "panel":
          this.duplicatePanel(item) 
          break
        case "calendar":
          this.duplicateCalendar(item) 
          break 
        default:
          break;
      }
    },
    duplicateCalendar(item){
      let vm = this;
      this.$refs.confirmDialog
        .open(this.$t("general.copy"), this.$t("messages.copyMessage"))
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("DUPLICATE_MENU_ITEM", {
                calendarId: item._id,
                type: "calendar",
              })
              .then((newMenuItem) => {
                vm.onCommandHandler({
                  command: "selectMenuItem",
                  item: newMenuItem,
                });
              });
          }
        });
    },
    duplicatePanel(item) {
      let vm = this;
      this.$refs.confirmDialog
        .open(this.$t("general.copy"), this.$t("messages.copyMessage"))
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("DUPLICATE_MENU_ITEM", {
                panelId: item._id,
                type: "panel",
              })
              .then((newMenuItem) => {
                vm.onCommandHandler({
                  command: "selectMenuItem",
                  item: newMenuItem,
                });
              });
          }
        });
    },
    duplicateForm(item) {
      let vm = this;
      this.$refs.confirmDialog
        .open(this.$t("general.copy"), this.$t("messages.copyMessage"))
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("DUPLICATE_MENU_ITEM", {
                formId: item._id,
                type: "form",
              })
              .then((newMenuItem) => {
                vm.onCommandHandler({
                  command: "selectMenuItem",
                  item: newMenuItem,
                });
              });
          }
        });
    },
    hideMenuItem(moduleId, menuItem) {
      this.$store.dispatch("TOGGLE_CURRENT_MODULE_MENU_VISIBILITY", {
        menuItem,
        hidden: !menuItem.hidden,
      });
    },

    hideTabsOfModule(moduleId) {
      let vm = this;
      let _module = vm.app.modules.find((item) => {
        return item._id == moduleId;
      });
      if (_module) {
        if (_module.hasOwnProperty("hidden")) _module.hidden = !_module.hidden;
        else _module.hidden = true;

        vm.$store.dispatch("UPDATE_APP_MODULE", {
          id: vm.app._id,
          moduleId: moduleId,
          title: _module.title,
          menu: _module.menu,
          hidden: _module.hidden,
        });
      }
    },

    moveMenuItem(item) {
      let vm = this;
      if (vm.app.modules.length > 1) {
        vm.$refs.moduleMoveDialog.open({
          menuItem: item,
          onConfirm: (payload) => {
            this.$store.dispatch("MOVE_CURRENT_MODULE_MENU", payload);
          },
        });
      }
    },
    moveTabsOfModule(oldIndex, newIndex) {
      const vm = this;
      vm.$store
        .dispatch("REORDER_MODULES", {
          app: vm.app,
          oldIndex: oldIndex,
          newIndex: newIndex,
        })
        .then(() => {
          this.loadFirstMenuItem(this.currentModule);
        });
    },
    saveModuleInfo(payload) {
      const vm = this;
      // console.log('saveModuleInfo :: payload: ', payload)
      if (payload.id === "") {
        vm.insertModuleTab({ title: payload.title });
      } else {
        vm.updateModuleTab({ id: payload.id, title: payload.title });
      }
    },

    insertModuleTab(payload) {
      // console.log('addModuleTab :: payload: ', payload)
      const vm = this;
      vm.$store.dispatch("INSERT_APP_MODULE", {
        id: vm.app._id,
        title: payload.title,
      }).then(()=> {
        this.$sensors.track("plus_modules_add",{
          module_name: payload.title,
          appId:this.app._id
        });
      });
    },

    updateModuleTab(payload) {
      const vm = this;
      // console.log('updateModuleTab :: payload: ', payload)
      vm.$store.dispatch("UPDATE_APP_MODULE", {
        id: vm.app._id,
        moduleId: payload.id,
        title: payload.title,
      });
    },
    deleteModule(module) {
      const vm = this;
      if (vm.app.modules.length === 1) {
        let configs = {
          headerClass: "error",
        };
        this.$refs["atLeastOneModuleDialog"].open(
          vm.$t("general.alert"),
          vm.$t("messages.keepAtLeastOneModule"),
          ["close"],
          configs
        );
        return;
      } else if (module.menu.length > 0) {
        vm.$refs.moduleDeleteDialog.open({
          currentModule: module,
          onConfirm: (payload) => {
            vm.doDeleteModule(payload);
          },
        });
      } else {
        vm.doDeleteModule({ module: module });
      }
    },
    doDeleteModule(payload) {
      const vm = this;
      vm.$store.dispatch("REMOVE_APP_MODULE", payload);
    },
    saveMenuItemInfo(menuItemInfo, menuFolder, callback) {
      const vm = this;
      let folderItem = null;
      let sibling = null;

      if (!menuFolder) {
        sibling = vm.selectedMenuItem;
      } else {
        folderItem = menuFolder;
      }
      if (menuItemInfo._id === "") {
        vm.addMenuItemInfo(folderItem, sibling, menuItemInfo, callback);
      } else {
        vm.updateMenuItemInfo(folderItem, sibling, menuItemInfo, callback);
      }
    },

    deleteMenuItem(item) {
      const vm = this;

      let message = "";


      vm.$refs.confirmDialogWithTextField.open({
        title: { deleteMenu: item.title },
        color: "error",
        requiredWord: "",
        message: message,
        callback: () => {
          vm.doDeleteMenuItem(item);
          this.sensorTrackDelete(item);
        },
      });
    },

    doDeleteMenuItem(item) {
      const vm = this;
      vm.$store.dispatch("REMOVE_CURRENT_MODULE_MENU", item).then(() => {
        if (item._id === vm.$route.params.itemId) {
          const firstItem = this.currentModule.menu[0];
          this.selectMenuItem(firstItem);
        }
      });
    },
    sensorTrackDelete(item){
      const map = {
        inputPanel: {
          category: "plus_inputpanel_mani",
          func_name: this.$sensors.events.plus_inputpanel_mani.DELETE_INPUT_PANEL
        },
        panel:{
          category: "plus_panel_mani",
          func_name: this.$sensors.events.plus_panel_mani.DELETE_PANEL          
        },
        calendar:{
          category: "plus_calender_mani",
          func_name: this.$sensors.events.plus_calender_mani.DELETE_CALENDER        
        },
        form: {
          category: "plus_sheet_mani",
          func_name: this.$sensors.events.plus_sheet_mani.general.DELETE_FORM               
        }
      }
      const sensorValue = map[item.type];
      if (sensorValue){
        this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
            key: sensorValue.category,
            data: {
              module_name:item.title,
              func_name:sensorValue.func_name,
              form_id: item._id
            }
        });
      }
      
    },
    updateMenuItemInfo(parent, sibling, menuItemInfo, callback) {
      const vm = this;
      const parentId = parent ? parent._id : null;
      const siblingId = sibling ? sibling._id : null;
      // console.log('updateMenuItemInfo :: menuItemInfo: ', menuItemInfo)
      vm.$store
        .dispatch("UPDATE_CURRENT_MODULE_MENU", {
          menuItemInfo,
          parentId,
          siblingId,
          moduleId: this.currentModule._id,
        })
        .then(() => {
          // console.log(response)
          console.log("menuItemInfo: ", menuItemInfo);
          console.log("vm.currentForm: ", vm.currentForm);
          if (menuItemInfo._id === vm.currentForm._id) {
            // console.log('updateMenuItemInfo :: menuItemInfo: ', menuItemInfo)
            if (vm.selectedMenuItem) {
              const menuItemType = vm.selectedMenuItem.type;
              switch (menuItemType) {
                case "form":
                  vm.$store.dispatch("SET_CURRENT_FORM_PROPERTY", {
                    propertyKey: "label",
                    propertyValue: menuItemInfo.title,
                  });
                  vm.$store.dispatch("SET_CURRENT_FORM_PROPERTY", {
                    propertyKey: "icon",
                    propertyValue: menuItemInfo.icon,
                  });
                  break;
                case "panel":
                  vm.$store.dispatch("SET_CURRENT_PANEL_PROPERTY", {
                    propertyKey: "label",
                    propertyValue: menuItemInfo.title,
                  });
                  break;
                case "backdrop":
                  break;
                case "mapPanel":
                  break;
                case "calendar":
                  break;
              }
            } else {
              alert("no menu item selected. (selectedMenuItem is null)");
            }
          }
          if (typeof callback === "function") {
            callback(menuItemInfo);
          }
        });
    },

    addMenuItemInfo(folderItem, sibling, menuItemInfo, callback) {
      const vm = this;
      const folderItemId = folderItem ? folderItem._id : null;
      // console.log('addMenuItemInfo :: menuItemInfo: ', menuItemInfo)
      vm.$store
        .dispatch("APPEND_CURRENT_MODULE_MENU", {
          menuItemInfo: menuItemInfo,
          parent: folderItemId,
          sibling,
        })
        .then((response) => {
          this.$sensors.track("plus_block_add", {
            block_type: menuItemInfo.trackId,
            appId: this.app._id
          })
          // console.log('response: ', response)
          if (typeof callback === "function") {
            // console.log('callback is function')
            callback(response);
          }
        });
    },
    toggleFoldMenu() {
      this.foldedMenu = !this.foldedMenu;
      if (this.foldedMenu) {
        this.$refs.itemMenu.style.width = "45px";
        this.$store.commit("updateMenuMode", "collapsed");
      } else {
        this.$refs.itemMenu.style.width = "20%";
        this.$store.commit("updateMenuMode", "expanded");
      }
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  font-family: "Roboto";
}

.list1 {
  height: 100%;
}

.list2 {
  display: flex;
  height: 100%;
}

.chip {
  margin: 10px;
}

.drop-allowed.drop-in * {
  cursor: inherit !important;
}

/*.item-container .col:first-child {*/
/*border: 1px solid lightgray;*/
/*}*/

.device-row {
  border-bottom: white 1px solid;
}

html {
  overflow-y: auto;
}

.menu-box {
  background-color: #f1f2f3;
}

/* .menu-box .root-new-menu-item {
      display: none !important;
    }

    .menu-box:hover .root-new-menu-item {
      display: block !important;
    } */

/*.widget-item {*/
/*width: 130px;*/
/*padding: 5px 10px;*/
/*white-space: nowrap;*/
/*text-overflow:ellipsis;*/
/*overflow-x: hidden;*/
/*margin: 0 5px 2px 0;*/
/*display: inline-block;*/
/*}*/

.v-expansion-panel {
  background-color: transparent !important;
  outline: none;
  border-top-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-content > div {
  padding: 0;
}

.v-expansion-panel-header--active .v-expansion-panel-header__icon > svg {
  color: white !important;
}

.nestable-handle {
  display: inline-block !important;
  width: 100%;
}

.apps .v-tabs .v-tabs-bar {
  height: 24px;
}

.v-list-item__title {
  font-size: 1rem !important;
}

.v-list-item__icon {
  margin-right: 16px !important;
}

.v-list-group .v-list-group__header {
  padding-left: 16px !important;
}

.module-tab {
  position: relative;
}

.cursor-pointer .menu-item-context {
  padding: 1px 5px 1px 1px;
}

.tabbar-team-name {
  white-space: nowrap;
}

.small-menu .new-menu-item {
  position: relative !important;
}

.small-menu .new-menu-button-wrapper {
  text-align: left !important;
}
.splitpanes--vertical > .splitpanes__splitter {
  width: 1px !important;
  background: #c4c4c4;
}
.splitpanes__splitter:before {
  content: unset !important;
}

.active-module-tab {
  background-color: white;
  color: #272e3b !important;
}
.active-module-tab i.v-icon {
  background-color: white;
  color: #272e3b !important;
}
.active-module-tab .arrow-down {
  border-top-color: #272e3b !important;
}
.active-module-tab .arrow-up {
  border-bottom-color: #272e3b !important;
}
.app-module-bar .v-tab {
  border-radius: 12px 12px 0 0;
}
.app-module-bar .v-tab::before {
  border-radius: 12px 12px 0 0;
}
</style>

<style src="@/assets/scss/vue-nestable.scss" lang="scss"></style>
