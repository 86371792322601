<template>
  <v-menu offset-y
    open-on-hover>
    <template v-slot:activator="{on, attrs}">
      <v-btn text      
        class="px-0"
        height="18"
        v-bind="attrs"
        v-on="on"
        :dark="event.extendedProps.textTheme==='dark'"
        style="right:0;top:0;min-width:24px!important;">        
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group flat v-model="selectedIndex">
        <v-list-item
          style="min-height:36px;"
          v-for="(item, index) in menuItems"
          @click="onItemClicked(item)"
          :key="index">
          <v-list-item-title :color="color">{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'eventMenuButton',
  props: {
    event: Object,
    color: String
  },
  data () {
    return {
      selectedIndex: -1
    }
  },
  computed: {
    canDelete () {
      return this.event.extendedProps.data.canDelete
    },
    // canDelete () {
    //   return this.event.data.canDelete;
    // },
    menuItems() {
      var result = [];
      if (this.canDelete) {
        result.push({label: this.$t('buttons.delete'), value: 'delete'});
      }
      return result;
    }
  },
  methods: {
    onItemClicked (item) {
      console.log('EventMenuButton.onItemClicked : item: ', item)
      const vm = this
      vm.$emit('onCommand', {
        command: item.value,
        event: vm.event
      })
      this.selectedIndex = -1
    }
  }
}
</script>