<template>
    <div class="d-flex flex-column columnSetting" style="font-size:12px;">
        <div class="d-flex pt-0 pb-3 align-start ">
            <!-- <div class="d-flex pt-3 pl-1 font-weight-bold" style="font-size:15px">Description:</div> -->
            <div class="flex-grow-1" style="padding: 14px 12px 0px">
                <div v-if="!isEditingDescription" class="d-flex align-end">
                    <pre :class="!ganttSetting.description?'grey--text':''">{{ganttSetting.description||$t('gantt.typeSth')}}</pre>
                    <v-btn 
                        plain
                        :ripple="false"
                        icon 
                        @click="isEditingDescription=!isEditingDescription"
                        x-small
                        class="ml-5"
                    >
                        <v-icon 
                            plain
                            v-if="!isEditingDescription" 
                            small
                        >
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </div>
                <div v-if="isEditingDescription">
                    <v-textarea 
                        autofocus
                        @change="$emit('updateSetting',{command:'update_description', val:$event})"
                        @blur="isEditingDescription=false" 
                        style="font-size:13px" 
                        outlined 
                        hide-details
                        v-model="ganttSetting.description"
                    />
                </div>
            </div>
        </div>
        <div class="d-flex justify-start" style="width:100%">
            <div class="mb-1 d-flex align-end">
                <div class="pa-0 ma-0">
                    <v-btn tile @click="showSearchTextField=!showSearchTextField" small style="min-width:40px; height:25px" class="elevation-0 teal lighten-2">
                        <v-icon left style="" color="white" class="ma-0">mdi-magnify</v-icon>
                    </v-btn>
                </div>
                <!-- <v-slide-x-transition  v-if="showSearchTextField"> -->
                    <v-text-field
                        hide-details
                        dense
                        height="25"
                        style="font-size:13px; border:2px; width:271px"
                        v-bind="searchText"
                        outlined
                        class="search-box"
                        @change="$emit('updateSetting', {command: 'update_searchText', val: $event})"
                    />
                <!-- </v-slide-x-transition> -->
            </div>
            <div class="d-flex align-end ml-5 mb-1">
                <div class="pa-0 ma-0">
                    <v-btn tile @click="showSearchTextField=!showSearchTextField" small style="min-width:40px; height:25px" class="elevation-0 teal lighten-2">
                        <v-icon left color="white" class="ma-0">mdi-calendar</v-icon>
                    </v-btn>
                </div>
                <v-select
                    :items="timeViews"
                    item-text="label"
                    height="25"
                    outlined
                    hide-details
                    style="font-size:13px;  width:100px"
                    item-value="id"
                    class="timeView-box"
                    v-model="ganttSetting.timeView"
                    @input="$emit('updateSetting', {command: 'update_timeView', val: $event})"
                    :menu-props="{offsetY: true, contentClass: 'denseList'}"
                >  
                    <template v-slot:selection="{item}">
                        <div class="px-2">
                            {{item.label}}
                        </div>
                    </template>
                </v-select>
            </div>
            <div v-if="isProgressSet" class="d-flex align-end mb-1 ml-5">
                <v-icon small class="teal white--text lighten-2" style="width:40px; height:25px">mdi-percent-outline</v-icon>
                <v-btn-toggle 
                    mandatory 
                    class="toggles-fillBy"
                    color="teal"
                    dense 
                    v-model="ganttSetting.fillBy"
                    @change="$emit('updateSetting', {command: 'update_fillBy', val: $event})"
                >
                    <v-btn class="rounded-0" value="PROGRESS" small style="width:75px; height:25px">
                        {{$t("gantt.progress")}}
                    </v-btn>
                    <v-btn class="rounded-0" value="TIME" small style="width:75px; height:25px">
                        {{$t("gantt.time")}}         
                    </v-btn>
                </v-btn-toggle>
            </div>
            <div class="d-flex align-end">
                <!-- <v-btn 
                    tile 
                    text
                    small
                    class="my-1 elevation-0 ml-5"
                    style="height:25px;width:60px"
                    outlined
                    color="indigo"
                    @click="$emit('doScrollToTime')"
                >
                    Auto
                </v-btn> -->
                <v-btn 
                    tile 
                    text
                    small
                    class="my-1 elevation-0 ml-5"
                    style="height:25px;width:60px"
                    outlined
                    color="indigo"
                    @click="$emit('doScrollToTime')"
                >
                    {{$t("gantt.today")}}
                </v-btn>
            </div>
            <div class="ml-auto d-flex align-end mb-1">
                <div class="d-flex">
                    <v-btn @click="setCellWidth(10)" class="elevation-0 teal lighten-2 white--text" tile x-small style="min-width:15px; width:20px; height:20px">
                        <v-icon size="20">mdi-plus</v-icon>
                    </v-btn>
                        <div class="d-flex align-center justify-center" style="border:#4cb6ac 1px solid; width:50px;font-size:14px; height:20px">
                            <div>{{ganttSetting.cellWidth}}</div>
                        </div>
                    <v-btn @click="setCellWidth(-10)" class="elevation-0 teal lighten-2 white--text" tile x-small style="min-width:15px; width:20px; height:20px">
                        <v-icon size="20">mdi-minus</v-icon>
                    </v-btn>
                </div>
                <div class="ml-2" style="width:30px">
                    <v-btn class="" icon @click="$emit('openSettingDrawer')" plain :ripple="false" style="height:0px; width:30px">
                        <v-icon size="20">
                            mdi-cog
                        </v-icon>
                    </v-btn>
                </div>
                <div class="" style="width:30px">
                    <v-btn class="" icon @click="openFullscreen" plain :ripple="false" style="height:0px; width:30px">
                        <v-icon size="22">mdi-fullscreen</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            form: Object,
            ganttSetting: Object,
            searchText: String,
            isMilestoneSet: Boolean,
            isProgressSet: Boolean
        },
        data(){
            return{
                timeViews: [
                    {id: 'DAY', label: this.$t("gantt.day"), val: 80},
                    {id: 'WEEK', label: this.$t("gantt.week"), val: 50},
                    {id: 'MONTH', label: this.$t("gantt.month"), val: 20},
                    // {id: 'QUARTER', label: 'quarter'}
                ],
                showSearchTextField: false,
                searchText: '',
                isEditingDescription: false
            }
        },
        computed:{
            cascadeFields(){
                var fields = []
                this.form.linkedFields.forEach(item=>{
                    var mappedFields = item.fieldIds.map(item2=>{
                        return this.form.fieldInfos.find(item3=>item3.fieldId==item2)
                    })
                    fields.push(mappedFields)
                })
                return fields
            }
        },
        mounted(){
        },
        methods:{
            openFullscreen() {
                this.isFullScreen = !this.isFullScreen
                var viewEle = document.getElementsByClassName('gantt-view')[0]
                var containerEle = document.getElementsByClassName('gantt-chart-container')[0]
                if(this.isFullScreen){
                    viewEle.className += " gantt-view-fullscreen"
                    containerEle.style.setProperty("height", "90%", "important")
                }else{
                    viewEle.className = viewEle.className.replace(" gantt-view-fullscreen","")
                    containerEle.style.setProperty("height", "75%", "important")
                }
                
            },
            setCellWidth(val){
                var newVal = this.ganttSetting.cellWidth+val
                var res
                if(newVal>100)
                    res = 100
                else if(newVal<20)
                    res = 20
                else
                    res = newVal
                this.$emit('updateSetting', {command: 'update_cellWidth', val: res})
            },
            handleCascadeInput(cascade){
                var val = cascade.map(item=>item.fieldId)
                this.$emit('updateSetting', {command: 'update_projectCascade', val})
            },
            getLabelById(fieldId){
                if(Array.isArray(fieldId)){
                    return fieldId.map(item=>{
                        var res = this.form.fieldInfos.find(item2=>{
                            return item2.fieldId == item
                        })
                        if (res) 
                            return res.label
                        else return
                    }).join(' - ')
                }else{
                    var res = this.form.fieldInfos.find(item=>{
                        return item.fieldId == fieldId
                    })
                    if(res) return res.label
                }
            },
        }
    }
</script>

<style>
    .columnSetting .v-slider{
        margin: 0 !important;
    }
    .columnSetting .v-text-field{
        padding-top: 0!important;
        margin-top: 0!important;
    }
    .columnSetting .v-select > .v-input__control > .v-input__slot{
        padding: 0 !important
    }
    .columnSetting .v-input__append-inner{
        margin-top: 0px !important
    }
    .columnSetting .v-input__control > .v-input__slot{
        min-height: 10px!important;
        margin-bottom: 0 !important;
    }
    .columnSetting .cellWidthInput.v-text-field input{
        text-align: center
    }
    .columnSetting .search-box.v-text-field fieldset,
    .columnSetting .timeView-box.v-text-field fieldset{
        border: 2px solid #4cb6ac;
        margin: 0 !important;
    }
    .columnSetting .search-box.v-text-field .v-input__append-inner{
        margin-bottom: 0px !important
    }
    .columnSetting .toggles-fillBy .v-btn{
        border: 0 !important;
    }
    .gantt-view-fullscreen{
        position: fixed;
        left: 0;
        top:0;
        height:100%;
        width:100% !important;
        z-index: 999 !important;
        background-color: white;
        padding:10px
    }
    pre{
        font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
        font-size: 14px
        /* margin-bottom: 10px;
        overflow: auto;
        width: auto;
        padding: 5px;
        background-color: #eee;
        width: 650px!ie7;
        padding-bottom: 20px!ie7;
        max-height: 600px; */
    }

</style>