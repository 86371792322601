<template>
  <loading-icon v-if="loading" />
  <div v-else class="d-flex flex-column fill-height">
    <file-drop
      :fileIds="files"
      :isLayout="false"
      @onCommand="onCommandHandler"
      :hasError="hasError"
      :fullHeight="true"
      class="d-flex fill-height "
    >
      <template>
        <file-table :files="files" @onCommand="onCommandHandler" />
      </template>
    </file-drop>
    <div class="d-flex flex-column">
      <v-btn @click="onUploadClick" :loading="isSelecting">
        <v-icon>mdi-upload</v-icon>
        {{ $t("buttons.upload") }}
      </v-btn>
      <input
        type="file"
        ref="uploader"
        :multiple="true"
        @change="onInputFileChanged"
        class="d-none"
      />
    </div>
    <confirm-delete-dialog ref="confirmDeleteDialog"></confirm-delete-dialog>
  </div>
</template>
<script>
import fileDrop from "@/components/dropUpload/FileDrop";
import LoadingIcon from "@/components/global/loadingIcon";
import confirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import FileTable from "./fileTable";
import MediaHelper from "@/helpers/MediaHelper";
import uploadMixin from "../uploadMixin";

export default {
  mixins: [uploadMixin],
  components: {
    fileDrop,
    LoadingIcon,
    confirmDeleteDialog,
    FileTable,
  },
  props: ["parentId"],
  data() {
    return {
      message: "",
      files: [],
      hasError: false,
      isSelecting: false,
      multiple: false,
      loading: false,
      uploading: false,
    };
  },
  methods: {
    onUploadClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFilesUploaded(payload) {
      const vm = this;
      // payload: {
      //    mediaIds: Array<string>;
      //    mediaTypes: Array<string>;
      //    objectKeys: Array<string>;
      //    originalNames: Array<string>;
      //    thumbnailUrls: Array<string>;
      // }
      // notes:
      //     _id: payload.mediaIds[i],
      //     mediaType: payload.mediaTypes[i],
      //     objectKey: payload.objectKeys[i],
      //     originalName: payload.originalNames[i],
      //     thumbnailUrl: payload.thumbnailUrls[i]
      //
      var medias = [];
      for (let i = 0; i < payload.mediaIds.length; i++) {
        medias.push({
          mediaId: payload.mediaIds[i],
          mediaType: payload.mediaTypes[i],
          objectKey: payload.objectKeys[i],
          originalName: payload.originalNames[i],
          thumbnailUrl: payload.thumbnailUrls[i],
          parentId: vm.parentId,
        });
      }
      MediaHelper.confirmMedias(medias, vm.onMediaConfirmed);
      //      this.createComment(medias);
    },

    onMediaConfirmed(medias) {
      this.files = [...this.files, ...medias];
      this.createComment(medias);
      this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
        key: "plus_sheet_mani",
        data: {
          module_name: this.$store.getters.formLabel,
          func_name: this.$sensors.events.plus_sheet_mani.discussion.CREATE_ATTACHMENT,
        }
      });
    },

    onCommandHandler(payload) {
      const vm = this;
      switch (payload.command) {
        case "triggerUpload":
          vm.onUploadClick();
          break;
        case "uploadFiles":
          vm.uploadFiles(payload.files, vm.onFilesUploaded);
          break;
        case "removeAttachmentFile":
          vm.removeAttachmentFile(payload.file, vm.onFileRemoved);
          break;
        default:
          vm.$emit("onCommand", payload);
          break;
      }
    },
    onFileRemoved(file) {
      const index = this.files.findIndex((item) => item._id == file._id);
      if (index > -1) {
        this.files.splice(index, 1);
      }
      this.$store.dispatch("TRACK_WITH_APPID_FORMID", {
        key: "plus_sheet_mani",
        data: {
          module_name: this.$store.getters.formLabel,
          func_name: this.$sensors.events.plus_sheet_mani.discussion.DELETE_ATTACHMENT,
        }
      });
    },
    onInputFileChanged(e) {
      const vm = this;
      var files = [];
      for (var i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }
      vm.uploadFiles(files, this.onFilesUploaded);
    },
    createComment(medias) {
      // const mediaIds = medias.map(media => media._id)
      const mediaNames = medias.map((media) => media.originalName).join(",");
      const postData = {
        urlCommand: "/comment",
        data: {
          appId: this.appId,
          parentId: this.parentId,
          content: this.$t("drawer.uploadAttachment", { target: mediaNames }),
          medias,
        },
      };

      this.$store.dispatch("AUTH_POST", postData);
    },
    async getAttachments() {
      const urlCommand = `/medias/getUploads?parentId=${this.parentId}`;
      const response = await this.$store.dispatch("AUTH_GET", { urlCommand });
      this.files = response;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getAttachments();
    this.loading = false;
  },
};
</script>
