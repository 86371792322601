<template>
  <div>
    <v-dialog
      v-model="remarking"
      persistent
      max-width="350"
      content-class="w-editable-node-remark-dialog"
    >
      <v-layout justify-space-between align-center class="w-editable-node-remark-head">
        <h1 class="w-editable-node-name-title">{{ $t('workflow.control.editRemark') }}</h1>
        <v-btn
          icon
          @click="$emit('offRemark')"
        >
          <v-icon color="#bdbdbd" small>mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <textarea
        v-model="remark"
        ref="editableInput"
        rows="8"
        class="w-editable-node-input"></textarea>
      <v-card-actions class="w-editable-node-footer">
        <v-btn
          text
          class="w-editable-node-cancel"
          @click="$emit('offRemark')"
        >
          {{ $t('workflow.btn.cancel') }}
        </v-btn>
        <v-btn
          color="#1e88e5"
          class="w-editable-node-submit"
          @click="$emit('onRemarked', remark)"
        >
          {{ $t('workflow.btn.save') }}
        </v-btn>
      </v-card-actions>
    </v-dialog>
  </div>
</template>

<script>
import InputMixin from './inputMixin';
export default {
  mixins: [InputMixin],
  props: {
    remark: String,
    offRemark: Function,
    onRemarked: Function,
    remarking: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.initialRemark = this.remark;
  },
  methods: {
    update() {
      if (this.initialRemark !== this.remark) {
        // update name
        this.$emit('remarked', this.remark);
        this.initialRemark = this.remark;
      } else {
        this.$emit('remarked', null);
      }
    },
  },
};

</script>

<style>
  .w-editable-node-remark-dialog {
    background: white !important;
    padding: 20px;
  }
  .w-editable-node-name-title {
    color: #333;
    font-weight: 400;
    font-size: 17px;
  }
  .w-editable-node-input {
    margin-top: 20px;
    font-size: 14px;
    color: #757575;
    padding: 10px;
    word-break: break-all;
    resize: none;
    line-height: 20px;
    width: 100%;
    border: 1px solid rgba(187, 187, 187, 0.8);
    border-radius: 6px;
  }

  .w-editable-node-input:focus,
  .w-editable-node-input:active,
  .w-editable-node-input:hover,
  .w-editable-node-input:visited {
    outline: none;
  }

  .w-editable-node-footer {
    justify-content: flex-end;
    padding: 0;
    padding-top: 15px;
  }
  .w-editable-node-submit {
    padding: 0 30px !important;
    margin-left: 20px !important;
  }
  .w-editable-node-submit span {
    color: white;
  }
</style>
