<template>
  <h1>RelatedRecord</h1>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin]
}
</script>
