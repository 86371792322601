<template>
<v-list dense class="py-0">
  <v-list-item            
    link
    dense
    small
    height="24"
    min-height="24"
    v-for="item in menu"
    :key="item.label"
    @click="$emit('click', item)">
    <v-icon left>{{ item.icon }}</v-icon
    >{{ $t(item.label) }}
  </v-list-item>
</v-list>
</template>

<script>
export default {
  name: 'listMenu',
  props: {
    menu: Array
  }
}
</script>
