<template>
  <div>
    <label>{{ label }}</label>
  <v-text-field
        label=""
        :value="displayValue"
        dense
        readonly
        :placeholder="placeholder"
        append-outer-icon="mdi-tools"
        @click:append-outer="openDialog"
        hide-details="auto"
        outlined></v-text-field>
    <card-display-setup-dialog
        ref="cardDisplaySetupDialog"
        @confirm="confirmHandler"></card-display-setup-dialog>
  </div>
</template>

<script>
import mixin from './mixin'
import cardDisplaySetupDialog from '@/components/dialogs/CardDisplaySetupDialog'
import { getCardConfigString } from '@/helpers/CardHelpers'
import { getDefaultPropertyValue } from '@/helpers/FormHelpers'

export default {
  name: 'witCardDisplaySetup',
  mixins: [mixin],
  components: {
    cardDisplaySetupDialog
  },
  watch: {
    dataTableId: function (newVal) {
      const vm = this
      vm.propertyValue = getDefaultPropertyValue(vm.propertyKey)
    }
  },
  computed: {
    displayValue () {
      const cardConfigs = this.propertyValue      
      console.log('WitCardDisplaySetup::computed(displayValue): cardConfigs: ', cardConfigs)
      var result = getCardConfigString(cardConfigs, this.relatedFieldIds)
      return result
    },

    //   const vm = this
    //   var totalFields = 1
    //   var included = [vm.$t('general.title')]

    //   const cardConfigs = vm.propertyValue

    //   if ()
    //   if (vm.propertyValue.cardDisplayFieldIds.length > 0) {
    //     totalFields += vm.propertyValue.cardDisplayFieldIds.length
    //   }
    //   var result =  vm.$t('general.titleField')
    //   if (totalFields > 1) {        
    //     result = vm.$t('general.field') + ' x ' + totalFields + ' (' + 
    //       vm.$t('filters.includes') + vm.$t('general.titleField') + ')'
    //   }
    //   return result
    // },
   
  },
  methods: {
    openDialog () {
      const vm = this
      console.log('openDialog : propertyValue: ', vm.propertyValue)
      vm.$refs.cardDisplaySetupDialog.open(
        {
          cardConfig: vm.propertyValue,
          fieldInfos: vm.relatedFieldInfos,
          titleFieldInfoId: vm.relatedTitleFieldInfoId
        },
        (result) => {
          console.log('openDialog result: ', result)
          vm.propertyValue = result
        }
      )
    }
  }
}
</script>
