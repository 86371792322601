<template>
  <v-card-title class="py-0 pl-3 pr-1 pt-1 white form-title-wrapper" style="z-index:1;height:62px">
    <span v-if="isTitleString" class="mr-3">{{ title }}</span>
    <span v-else class="mr-3">
      <div class="d-flex flex-row align-center">
        <div
          v-for="(titleInfo, index) in title"
          class="line-height-1 my-1 d-flex flex-row align-center"
          :key="index"
        >
          <!-- <v-btn v-if="index>0" 
            fab x-small> -->
          <v-icon v-if="index > 0">mdi-share</v-icon>
          <!-- </v-btn> -->
          <div
            v-if="titleInfo.fieldName && titleInfo.fieldName !== ''"
            class="d-flex flex-column align-center"
          >
            <h5 :class="{ 'primary--text': index === title.length - 1 }">
              {{ titleInfo.fieldName }}
            </h5>
            <v-chip
              x-small
              :color="
                index === title.length - 1 ? 'primary' : 'grey white--text'
              "
              >{{ titleInfo.formLabel }}</v-chip
            >
          </div>
          <div v-else class="d-flex flex-column align-center justify-center">
            {{ titleInfo.formLabel }}
          </div>
        </div>
      </div>
    </span>
    <v-btn
      v-if="(isAllowEditForm || isAdmin) && builderButton"
      :to="{
        name: builderRouteName,
        params: { id: $route.params.id, itemId: $route.params.itemId },
      }"
      class="mr-2 d-flex flex-column justify-center success"
      :ripple="false"
      text
      x-small
      style="height:38px;border-radius: 4px"
    >
      <v-icon left size="16" class="mr-1">mdi-pencil</v-icon>
      <span style="font-size:16px">{{$t('buttons.formEditor')}}</span>
    </v-btn>
    <slot name="append-title">
    <v-menu
      offset-y
      right
      v-if="buttons.includes('new')"
      v-model="showingInsertMenu"
    >
      <template v-slot:activator="{ on }">
        <div id="formInsertButton" class="d-flex">
            <v-btn
              class="d-flex flex-column justify-center info"
              :ripple="false"
              text
              x-small
              style="height:38px;border-radius: 4px 0 0 4px;"
              @click="$emit('onCommand', {command: 'newRecord'})"
            >
              <v-icon left size="18" class="mr-1">mdi-plus</v-icon>
              <span style="font-size:16px" class="">{{$t('buttons.insertRecord')}}</span>
            </v-btn>
            <v-btn
              v-on="on"
              class="pa-0 info" 
              text
              style="border-radius: 0 4px 4px 0; height:38px; width:28px"
            >
              <v-icon v-if="!showingInsertMenu" size="20">mdi-chevron-down</v-icon>
              <v-icon v-else size="20">mdi-chevron-up</v-icon>
            </v-btn>
        </div>
      </template>
      <v-card class="" style="width:100%; font-size: 16px;">
        <v-list class="pa-0">
          <v-hover v-slot="{ hover }">
            <v-list-item 
              class="px-3" 
              :style="hover?'background-color: #F9F9F9;':''"
              @click="$emit('onCommand', {command: 'newRecord'})"
            >
              <v-icon color="#272E3B">mdi-dock-top</v-icon>
              <span class="ml-2">{{$t('buttons.singleRecordInsert')}}</span>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item 
              class="px-3" 
              :style="hover?'background-color: #F9F9F9;':''"
              @click="$emit('enableQuickInsert')"
            >
              <v-icon color="#272E3B">mdi-table</v-icon>
              <span class="ml-2">{{$t('buttons.quickRecordInsert')}}</span>
            </v-list-item>
          </v-hover>
        </v-list>
      </v-card>
    </v-menu>
    </slot>
    <!-- <CustomizedActionButton
      :size="size"
      :selected="selectedItems"
      @onSelected="afterCustomizedAction"
      v-if="size > 0 && hasCustomizedButtons"
    /> -->

    <v-spacer/>
    <slot name="prepend-action-button-set">
    </slot>

    <div
      class="d-flex flex-row align-center"
      style="margin-left:4px;"
      v-if="!isQuickInsertMode"
    >
      <form-action-button-set
        ref="formActionButtonSet"
        v-for="(buttonSet, index) in activeButtonSets"
        :key="index"
        :disabled="loading"
        :buttonSet="buttonSet"
        @onCommand="onCommandHandler"
      >
      </form-action-button-set>
    </div>
        <slot name="append-action-button-set">
    </slot>
  </v-card-title>
</template>

<script>
import formActionButtonSet from "./FormActionButtonSet";
export default {
  name: "formTitle",
  components: {

    formActionButtonSet,
  },
  data() {
    return {
      showingInsertMenu: false,
      searching: false,
      pressingShift: false,
      BUTTONS: {
        cancel: {
          color: "error",
          command: "cancel",
          tooltipTag: "buttons.cancel",
          icon: "mdi-close-thick",
        },
        delete: {
          color: "error",
          command: "delete",
          tooltipTag: "buttons.delete",
          icon: "mdi-trash-can",
        },
        refresh: {
          color: "info",
          command: "refreshData",
          tooltipTag: "buttons.refresh",
          icon: "mdi-refresh",
        },
        // new: {
        //   color: "primary",
        //   command: "newRecord",
        //   tooltipTag: "buttons.newRecord",
        //   icon: "mdi-plus",
        // },
        submit: {
          new:{
            commands: [
              {
                color: "primary",
                command: "submit",
                tooltipTag: "buttons.saveAndLeave",
                icon: "mdi-send",
              },
              {
                color: "primary",
                command: "submitAndNew",
                tooltipTag: "buttons.saveAndContinue",
                icon: "mdi-send",
                appendIcons: ["mdi-plus"],
              },
              {
                color: "primary",
                command: "submitAndKeep",
                tooltipTag: "buttons.saveAndKeep",
                icon: "mdi-send",
                appendIcons: ["mdi-plus"],
                footIcons: ["mdi-recycle"],
              }
            ]
          },
          edit:{
            commands: [{
              color: "primary",
              command: "submitAndRefresh",
              tooltipTag: "buttons.submitAndRefresh",
              icon: "mdi-send-check"
            },
            {
              color: "primary",
              command: "submit",
              tooltipTag: "buttons.saveAndLeave",
              icon: "mdi-send"
            }],
          }
        },
        back: {
          color: "grey",
          command: "back",
          tooltipTag: "buttons.back",
          icon: "mdi-reply",
        },
        search: {
          color: "warning",
          command: "search",
          tooltipTag: "buttons.search",
          icon: "mdi-magnify",
        },
      },
    };
  },
  props: {
    title: String,
    buttons: {
      type: Array,
      default: ()=>["new"],
    },

    defaultSubmitCommand: {
      type: String,
      default: "submit",
    },
    builderButton: {
      type: Boolean,
      default: false,
    },
    onSelected: Function,
    size: Number,
    selectedItems: Array,
    builderRouteName: {
      type: String,
      default: "",
    },

    isQuickInsertMode: {
      type: Boolean,
      default: false,
    },

    loading: Boolean,
    mode: {
      type: String,
      default: "new",
    }, // [edit|new]
  },
  computed: {
    hasCustomizedButtons() {
      return this.$store.getters.anyButtons;
    },
    atPanelPage() {
      return this.$route.name === "panels";
    },

    isTitleString() {
      return typeof this.title === "string";
    },
    activeButtonSets() {
      const vm = this;
      var result = [];
      // const buttonsWithToggleOption = vm.buttonToggleOption.map(
      //   option => option.button
      // );
      for (var i = 0; i < vm.buttons.length; i++) {
        const loopButton = vm.buttons[i];
        if (loopButton in vm.BUTTONS) {
          const loopButtonInfo = JSON.parse(
            JSON.stringify(vm.BUTTONS[loopButton])
          );

          var buttonSet = [loopButtonInfo];
          switch (loopButton) {
            case "submit": {
              var commandBtns = loopButtonInfo[vm.mode].commands;
              if (vm.mode === "edit")
                this.defaultSubmitCommand = "submitAndRefresh"
    
              const primaryIndex = commandBtns.findIndex(
                (cmd) => cmd.command === vm.defaultSubmitCommand
              );
              const primaryCommand = commandBtns.splice(
                primaryIndex,
                1
              );              
              buttonSet = [primaryCommand[0]];
              buttonSet = buttonSet.concat(commandBtns);
            }

              break;
          }

          result.push(buttonSet);
          // console.log('activeButtonSets: result.length = ' + result.length)
        }
      }
      // console.log('activeButtonSets: result: ', result)
      return result;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isAllowEditForm() {
      return this.$store.getters.currentForm ? this.$store.getters.currentForm.canEditForm : false
    },
    recordAlias() {
      return this.$store.getters.currentForm
        ? this.$store.getters.currentForm.recordAlias
        : "";
    },
  },
  mounted() {
    //FIXME: record alias not implemented
    // if (this.recordAlias) {
    //   this.$set(this.BUTTONS.new, "text", this.recordAlias);
    // }
  },
  methods: {
    afterCustomizedAction() {
      this.$emit('onSelected');
    },
    reset() {
      const vm = this;
      // console.log('FormtTitle.reset :: vm.$refs: ', vm.$refs)
      vm.$refs.formActionButtonSet.forEach((item) => {
        item.showingDropDown = false;
      });
    },

    onCommandHandler(payload) {
      this.$emit("onCommand", payload);
    },
  },
};
</script>
