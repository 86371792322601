const appMixin = {
  methods: {
    inverseStyle () {
      const vm = this
      var result = {}
      if (vm.app) {
        result = {
          'color': vm.appColor(vm.app.color, 0.8)+'!important',
          'background-color': vm.app.textTheme === 'dark' ? 'white!important' : 'black!important'
        }
      }
      return result
    },

    appTextColor () {
      return this.app.textTheme === 'dark' ? 'white' : 'black'
    },

    appTextStyle () {
      const vm = this
      var result = {}
      if (vm.app) {
        result['color'] = vm.app.textTheme === 'dark' ? 'white!important' : 'black!important'
      }
      return result
    },
    appStyle (ratio) {
      if (typeof ratio === 'undefined') {
        ratio = 0.6
      }
      const vm = this
      var result = {}
      if (vm.app) {
        result = {
          'color': vm.app.textTheme === 'dark' ? 'white!important' : 'black!important',
          'background-color': vm.appColor(vm.app.color, 0.8)+'!important'
        }
      }
      return result
    },
    appColor (color, ratio) {
      var result = ''
      if (color) {
        var h = color
        if (h.substr(0, 1) === '#') {
          h = h.substr(1)
        }
        if (h.length > 6) {
          h = h.substr(0, 6)
        }
        var r = '0x' + h[0] + h[1]
        var g = '0x' + h[2] + h[3]
        var b = '0x' + h[4] + h[5]

        var a = ratio.toFixed(3)

        result = 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')'
      }
      return result
    }
  },
  computed: {
    isAppDark () {
      const vm = this
      let result = false
      if (vm.app) {
        result = vm.app.textTheme === 'dark'
      }
      return result
    },
  }
}

export default appMixin
