<template>
<drag
    style="display: inline;"
    :type="widgetType"
    @cut="dummyCut"
    :data="widget">
    <widget-type-item
        v-if="widget"
        :widget="widget"
        :command="command"
        :itemsPerRow="itemsPerRow"
        @onCommand="onCommandHandler"
        :selected="selected"></widget-type-item>
</drag>

</template>

<script>
import {Drag} from 'vue-easy-dnd'
import widgetTypeItem from './WidgetTypeItem'

export default {
  components: {
    drag: Drag,
    widgetTypeItem
  },
  props: {
    command: String,
    widget: Object,
    widgetGroup: Object,
    itemsPerRow: {
      type: Number,
      default: 2
    },
    selected: Boolean
  },
  computed: {
    widgetType () {
      return 'widget'

      // const vm = this
      // var result = 'widget'
      // if (vm.widgetGroup.titleTag === 'relation') {
      //   result = result + '-relation'
      // }
      // return result
    }
  },
  methods: {
    dummyCut (event) {
      console.log('WidgetPanel :: dummyCut :: event: ', event)
    },
    onCommandHandler (payload) {
      const vm = this
      vm.$emit('onCommand', payload)
    }

  }
}
</script>
