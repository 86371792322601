import { childTable as types} from '../../mutation-types'
import defaultState from './state'

export default {
    [types.SET_TABLE_INFO](state, payload){
        state.tableInfo = payload
    },
    [types.RESET_CHILD_TABLE](state){
        Object.assign(state, defaultState);
    }
}
    